import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {DICTIONARY} from "../dictionary";
import {GeneralService} from "../services/generalService";
import {IAngularMyDpOptions} from "angular-mydatepicker";

@Component({
	selector: 'date-picker-c',
	template: `
		<div class="date-picker-container bs-enabled" style="position: relative;">
			<input type="text" class="date-picker-input w-100 m-0"
				   readonly
				   angular-mydatepicker
				   #dp="angular-mydatepicker"
				   [placeholder]="isRange ? \'Select date range\' : \'N/A\'"
				   [options]="datePickerOptions"
				   (click)="datePickerDirective.toggleCalendar()"
				   [ngModel]="model"
				   (ngModelChange)="modelChanged($event)"/>
			<i class="fa fa-chevron-down" (click)="datePickerDirective.toggleCalendar()"></i>

			<!-- Confirm Button -->
			<div class="flxRow date-picker-button-container"
				 *ngIf="datePickerDirective?.cRef"
				 [ngStyle]="{	top: (40 + (datePickerDirective?.cRef?.instance?.selectorEl?.nativeElement?.offsetHeight || 260)) + 'px',
				  				width: (datePickerDirective?.cRef?.instance?.selectorEl?.nativeElement?.offsetWidth || 266) + 'px' }">
				<btn-c (action)="datePickerDirective.closeCalendar()">Done</btn-c>
			</div>
		</div>
	`
})
export class DatePickerComponent implements OnInit, OnChanges {
	@ViewChild('dp', { static: false }) datePickerDirective: any;

	@Input() dateModel;
	@Output() dateModelChange = new EventEmitter<any>();

	@Input() isRange = false;
	@Input() allowFuture = false;
	@Input() dateFormat: string;
	@Input() dateMinLimit;
	@Input() dateMaxLimit;

	constructor(public gs: GeneralService) { }

	dic = DICTIONARY;
	model;
	datePickerOptions: Partial<IAngularMyDpOptions>;

	ngOnChanges(changes: SimpleChanges) {
		this.updateDatePickerOptions();
	}

	ngOnInit() {
		this.updateDatePickerOptions();
	}

	updateDatePickerOptions() {
		let disableDateRanges = [];

		if (this.dateMinLimit) {
			const minLimitDate = new Date(this.dateMinLimit);
			minLimitDate.setDate(minLimitDate.getDate() - 1);
			disableDateRanges.push({
				begin: { year: minLimitDate.getFullYear() - 1000, month: 1, day: 1 },
				end: { year: minLimitDate.getFullYear(), month: minLimitDate.getMonth() + 1, day: minLimitDate.getDate() }
			});
		}

		if (this.dateMaxLimit) {
			const maxLimitDate = new Date(this.dateMaxLimit);
			maxLimitDate.setDate(maxLimitDate.getDate() + 1);
			disableDateRanges.push({
				begin: { year: maxLimitDate.getFullYear(), month: maxLimitDate.getMonth() + 1, day: maxLimitDate.getDate() },
				end: { year: (new Date()).getFullYear() + 1000, month: 12, day: 31 }
			});
		}

		this.datePickerOptions = {
			closeSelectorOnDateSelect: false,
			dateFormat: this.dateFormat || 'mm/dd/yyyy',
			dateRange: this.isRange,
			disableDateRanges
		};

		if (!this.allowFuture) {
			const today = new Date();
			this.datePickerOptions.disableSince = {
				year: today.getFullYear(),
				month: today.getMonth() + 1,
				day: today.getDate() + 1
			};
		}

		// **Type Checking and Conversion Logic**
		if (this.isRange) {
			// Initialize dateModel if undefined
			this.dateModel = this.dateModel || { start: new Date(), end: new Date() };

			// Convert dateModel.start to Date if it's a string
			if (typeof this.dateModel.start === 'string') {
				this.dateModel.start = new Date(this.dateModel.start);
			}
			// Convert dateModel.end to Date if it's a string
			if (typeof this.dateModel.end === 'string') {
				this.dateModel.end = new Date(this.dateModel.end);
			}

			// Validate the dates
			if (isNaN(this.dateModel.start.getTime())) {
				console.warn('Invalid start date in dateModel. Using default date.');
				this.dateModel.start = new Date();
			}
			if (isNaN(this.dateModel.end.getTime())) {
				console.warn('Invalid end date in dateModel. Using default date.');
				this.dateModel.end = new Date();
			}

			this.model = {
				isRange: true,
				dateRange: {
					beginJsDate: this.dateModel.start,
					endJsDate: this.dateModel.end
				}
			};
		} else {
			// Convert dateModel to Date if it's a string
			if (typeof this.dateModel === 'string') {
				this.dateModel = new Date(this.dateModel);
			}

			// Validate the date
			if (isNaN(this.dateModel.getTime())) {
				console.warn('Invalid date in dateModel. Using default date.');
				this.dateModel = new Date();
			}

			this.model = {
				isRange: false,
				singleDate: { jsDate: this.dateModel }
			};
		}
	}

	modelChanged = (dateObj) => {
		if (this.isRange) {
			this.dateModelChange.emit({
				start: dateObj.dateRange.beginJsDate,
				end: dateObj.dateRange.endJsDate
			});
		}
		else {
			this.dateModelChange.emit(dateObj.singleDate.jsDate);
		}
	}
}
