<div class="flxClmn bs-enabled">
	<div class="page-title-container flex-row">
		<div class="flex-column justify-content-center f-1-0">
			<div class="page-title-inner-container">
				<span class="page-title">Email Templates</span>
			</div>
			<div class="page-subtitle-container">
				<span class="page-subtitle">Create and manage email templates and customize your outgoing emails' look</span>
			</div>
		</div>
	</div>
	<div class="page-inner-content-holder flex-column" style="min-height: calc(100vh - 50px - 94px);">
		<div class="flex-row" style="flex: 1 0; flex-wrap: wrap; overflow: auto;">
			<!--list of templates (left side)-->
			<div class="flex-column ms-0" style="flex: 3 0; margin: 7px; padding: 5px; min-width: 515px; min-height: 350px; background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent);">
				<div class="flex-column" style="flex: 1 0; overflow: auto;" >
					<div class="w100 flex-row justify-content-between" style="background: #677184; padding: calc(3px + 0.4vw); align-items: center; z-index: 1; min-height: 42px;">
						<div class="flex-row gap-2" style="align-items: center;">
							<!--Search-->
							<search-box [(model)]="searchTemplateTxt"
										(modelChange)="triggerSearch()"
										placeholder="Search template"
										style="min-width: 200px;">
							</search-box>
							<span *ngIf="_.reject(templates, 'hide').length" style="color: white; padding: 5px;">
                                {{_.reject(templates, 'hide').length}} record{{_.reject(templates, 'hide').length === 1 ? '' : 's'}}
                            </span>
						</div>
						<div style="padding-left: 20px; width: clamp(170px,10vw,210px);">
							<btn-c class="w100" style="background: white;"
								   variant="outlined" endIcon="fas fa-plus"
								   [isDisabled]="getTemplatesInProcess || (activeTemplate && !activeTemplate._id)"
								   (action)="startAddNewTemplate();">
								Create Template
							</btn-c>
						</div>
					</div>
					<!--lists-->
					<trustifi-table-c
						class="trustifi-table" style="flex: 1 0; z-index: 5; background: white;"
						[list]="templates"
						[headers]="[
                                {text: 'Name', sortKey: 'name'},
                                {text: 'Type', sortKey: 'role', width: '80px'},
                            ]"
						[cellsPrototype]="[
                                        {textKey: 'name', onClick: selectActiveTemplate},
                                        {textKey: 'role', onClick: selectActiveTemplate}
                                    ]"
						[bulkActions]="{
										enable: true,
										selectBulkActionCb:	selectMultipleTemplatesAction,
										initBulkActionsFunction: showTemplateBulkActions
									}"
						[cellActions]="{
                                        enable: true,
                                        selectItemActionCb:	selectTemplateAction,
                                        initActionsFunction: showTemplateActions
                                    }"
						(searchFunction)="searchTemplates()"
						[options]="{
                                loadingTableFlag: getTemplatesInProcess,
                                soloSelected: true,
                                defaultSortKey: '-role',
                                hideContentAboveTable: true,
                                itemsNameSingular: 'template',
                            }"
					>
					</trustifi-table-c>
				</div>
			</div>

			<!--list details area (right side)-->
			<div class="flex-column gap-4 me-0" style="flex: 7 0; margin: 7px; min-width: 600px; align-items: center; background: #f7f7f7; position: relative; overflow: auto; padding: 10px;">
				<div class="flex-column gap-3 w100" style="flex:1 0; overflow: auto; background: white; padding: 15px;" *ngIf="activeTemplate">
					<div class="flex-row">
						<div class="flex-row gap-1" style="flex-basis: 140px;">
							<span>Name</span>
							<i class="fas fa-question-circle" style="font-weight: 400;"
							   *ngIf="activeTemplate.role === dic.CONSTANTS.templateTypes.system"
							   [tooltip]="dic.CONSTANTS.systemTemplatesDescription[activeTemplate.name]" placement="right" ></i>
						</div>
						<input type="text" id="templateNameInput" class="f-1-0 bg-white"
							   (keyup)="isMissingName = false;"
							   [ngClass]="{'validation-error' : isMissingName, 'disabled' : activeTemplate.role === dic.CONSTANTS.templateTypes.system}"
							   [(ngModel)]="activeTemplate.name"
							   [disabled]="activeTemplate.role === dic.CONSTANTS.templateTypes.system">
					</div>
					<div class="flex-row">
						<span style="flex-basis: 140px;">Subject</span>
						<input type="text" id="templateSubjectInput" class="f-1-0 bg-white"
							   [(ngModel)]="activeTemplate.title">
					</div>
					<div class="flex-row show-on-hover-container-1">
						<div class="flex-column gap-4" style="flex-basis: 140px;">
							<span>Message</span>
							<div class="flex-column gap-2 w-100 p-2 ps-0 align-items-stretch show-on-hover-item-1">
								<div class="w100" id="templatesLoadHTML"
									 [(file)]="htmlFile"
									 ngfSelect
									 accept=".txt, .htm, .html, text/plain, text/html"
									 (fileChange)="loadHtml()">
									<btn-c class="w100" variant="outlined" color="#595959">Load HTML</btn-c>
								</div>
								<btn-c class="w100" id="templatesAddUnsubscribe" variant="outlined" color="#595959" (action)="addUnsubscribe()">Add Unsubscribe</btn-c>
								<btn-c class="w100" id="templatesSendTemplate" variant="outlined" color="#595959" (action)="openComposeMessage()">Send Template</btn-c>
								<btn-c class="w100" id="templatesPreview" variant="outlined" color="#595959" (action)="previewTemplate()">Preview</btn-c>
								<btn-c class="w100" id="templatesExport" variant="outlined" color="#595959" (action)="exportTemplate()">Export</btn-c>
								<btn-c class="w100" id="templatesReset" variant="outlined" color="#595959" (action)="resetSystemTemplate()" *ngIf="activeTemplate.role === dic.CONSTANTS.templateTypes.system">Reset</btn-c>
							</div>
						</div>
						<div class="f-1-0 flxClmn" id="summernoteContainer"
							 (dragenter)="toggleSummernoteDragState(true, $event)"
							 (dragleave)="toggleSummernoteDragState(false, $event)"
							 (dragend)="toggleSummernoteDragState(false, $event)">
							<div class="f-1-0 summernote-editor" id="summernote"
								 [ngxSummernote]="summernoteOptions"
								 [(ngModel)]="activeTemplate.html">
							</div>
						</div>
					</div>
					<div class="flex-row">
						<!--padder-->
						<div style="flex-basis: 140px;"></div>
						<!---->

						<div class="flex-column gap-3 f-1-0">
							<div class="attachment-wrap">
								<div class="attachment-items">
									<ul class="attachment-list m-0 ps-0">
										<li *ngFor="let attachment of activeTemplate.attachments"
											[ngClass]="{'finished': attachment.finished || attachment.finished === undefined}">
											<div class="item">
												<span class="name attachment-itemName">{{attachment.name}}</span>
												<span class="size"
													  style="font-size: 11px;">{{attachment.size | FormatBytes: 2 }}</span>
												<span class="after" [ngStyle]="{'width' : attachment.progressPercentage + '% !important'}">
                                        						</span>
											</div>
											<a class="close1 closeRed" *ngIf="attachment.finished" style="padding-left: 10px;"
											   (click)="deleteAttachment(attachment)"><i
												class="zmdi zmdi-close"></i></a>

											<a class="close1 closeRed" *ngIf="!attachment.finished" style="padding-left: 10px;"
											   (click)="attachment.abortUploadFlag = true; deleteAttachment(attachment);"><i
												class="zmdi zmdi-close"></i></a>
										</li>
									</ul>
								</div>
								<div id="upload" class="attachment-row-alt flex-row justify-content-between"
									 ngfSelect ngfDrop
									 [(files)]="attachmentFiles"
									 (fileChange)="uploadAttachment()"
									 [(validDrag)]="validDrag"
									 [(invalidDrag)]="invalidDrag"
									 multiple="1"
									 [ngClass]="{'dragOverClassObj': validDrag || invalidDrag}">
									<div class="attachment-popup" click-outside="openPopUp = false">
										<a id="templatesAddAttachments"
										   class="opener"
										   (click)="showAttachmentFileSourcesModal = !showAttachmentFileSourcesModal; $event.stopPropagation()">
											<i style="font-size: 14px; transition: all 0.2s; margin-right: 5px;" class="icon zmdi" [ngClass]="showAttachmentFileSourcesModal ? 'zmdi-chevron-up': 'zmdi-chevron-down'"></i>
											<i class="zmdi zmdi-attachment-alt">&nbsp;</i>
											Attach Encrypted Files
										</a>
										<div class="popup" style="z-index: 80;" *ngIf="showAttachmentFileSourcesModal">
											<ul class="popup-list m-0 p-0">
												<li>
													<a (click)="showAttachmentFileSourcesModal = false;">
														<i class="fas fa-laptop"></i>
														From My Computer
													</a>
												</li>
												<li (click)="showAttachmentsManager = true; showAttachmentFileSourcesModal = false; $event.stopPropagation()">
													<a>
														<i class="fas fa-cloud"></i>
														From My {{corpname}} Library
													</a>
												</li>
											</ul>
										</div>
									</div>
									<span class="title">Drag &amp; drop attachments</span>
								</div>
							</div>

							<div class="flex-row gap-2">
								<btn-c id="templatesSaveTemplate"
									   [loading]="{flag: uploading || updateTemplateInProcess, text: 'Uploading'}"
									   (action)="saveTemplate()">
									{{activeTemplate._id ? "Update": "Save"}}
								</btn-c>
								<btn-c id="templatesCancelTemplate" variant="text"
									   *ngIf="!activeTemplate._id"
									   [loading]="{flag: uploading || updateTemplateInProcess, text: 'Uploading'}"
									   (action)="cancelAddNewTemplate()">
									Cancel
								</btn-c>
							</div>


							<!--System templates parameters-->
							<div class="template-dynamic-fields-wrapper mt-3"
								 (ngInit)="showDynamicFields = false">
								<div class="template-dynamic-fields-general-description">
									<div class="header" style="margin-bottom: 5px;">Templates dynamic fields</div>
									<ul>
										<li>Words surrounded by double curly brackets (i.e. - {{dynamicFieldExample}} ) are dynamic fields.</li>
										<li>When the email is sent, the content inside these fields will be changed according to your settings.</li>
									</ul>
									<a class="more-information" style="cursor: pointer;"
									   (click)="showDynamicFields = !showDynamicFields">
										More information
										<i class="zmdi" style="margin-left: 2px;"
										   [ngClass]="showDynamicFields ? 'zmdi-chevron-up': 'zmdi-chevron-down'"></i>
									</a>
								</div>
								<div class="template-dynamic-fields" *ngIf="showDynamicFields" style="max-height: 200px;">
									<div class="template-dynamic-field"
										 *ngFor="let field of dynamicFields | keyvalue: gs.returnZero">
										<a (click)="gs.copyToClipboard('{{'+field.key+'}}');" style="padding-right: 3px;">
											<i class="far fa-copy copy-link" tooltip="Copy field"></i>
										</a>
										<span class="name">{{field.key}}</span>:
										<span class="field-description" *ngIf="field.value.includes('Settings')" [innerHTML]="util.format(field.value, lfPageUrl)"></span>
										<span *ngIf="!field.value.includes('Settings')">{{field.value}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="!activeTemplate" class="flex-column justify-content-center" style="align-items: center; flex: 1 0;">
					<i class="fas hvr-opacity fa-list-alt" style="font-size: 4vw;  opacity: 0.1;"></i>
				</div>
			</div>
		</div>
	</div>
	<attachments-manager-c
		*ngIf="showAttachmentsManager"
		(doneCb)="attachmentFilterDone($event.attachments)"
		(closeCb)="showAttachmentsManager = false;"
		[preselectedAttachments]="activeTemplate.attachments">
	</attachments-manager-c>
	<popup-c *ngIf="previewTemplatePopup?.show"
		   head="Preview Email"
		   (closeCb)="previewTemplatePopup = null"
		   [buttons]="{cancel: 'OK'}" size="large">
		<div class="flex-column f-1-0" style="overflow: auto;">
			<email-viewer [content]="previewTemplatePopup.emailContent" style="overflow: auto; flex: 1 0; width: 100%; min-width: 220px; border: 1px solid #ccc;"></email-viewer>
		</div>
	</popup-c>
</div>
