<div class="flex-column f-1-0 w-100">
	<div class="flex-column f-1-0 w-100 centralize gap-3" *ngIf="signUpState !== 'finalState'">
		<span class="fw-500 text-muted" style="font-size: 1rem; margin: -25px 0 12px;" *ngIf="!isTrialSignupPage && !isPurchasePage">Create a new account</span>
		<div class="position-relative w-100">
			<input id="signupEmail" type="text" placeholder="Email address" class="form-control"
				   name="{{'accountDetailsEmail' + random}}"
				   [ngClass]="{'validation-error' : emailError}"
				   (keydown)="emailError = null;"
				   [(ngModel)]="signupData.accountDetails.email">
			<span class="animated fadeIn" style="position: absolute; bottom: 1px; right: 0; transform: translateY(calc(100% + 3px)); font-size: 11.5px; color: red;"
				  *ngIf="emailError">
            {{emailError}}
        </span>
		</div>

		<div class="flex-row gap-1 w-100">
			<div class="position-relative f-1-0">
				<input id="signupFirstName" type="text" class="form-control"
					   placeholder="First name" aria-label="First name"
					   [ngClass]="{'validation-error' : firstNameError}"
					   (keydown)="firstNameError = null;"
					   [(ngModel)]="signupData.accountDetails.firstName">
				<span class="animated fadeIn" style="position: absolute; bottom: 1px; right: 0; transform: translateY(calc(100% + 3px)); font-size: 11.5px; color: red;"
					  *ngIf="firstNameError">
                Required
            </span>
			</div>
			<div class="position-relative f-1-0">
				<input id="signupLastName" type="text" class="form-control"
					   placeholder="Last name" aria-label="Last name"
					   [ngClass]="{'validation-error' : lastNameError}"
					   (keydown)="lastNameError = null;"
					   [(ngModel)]="signupData.accountDetails.lastName">
				<span class="animated fadeIn" style="position: absolute; bottom: 1px; right: 0; transform: translateY(calc(100% + 3px)); font-size: 11.5px; color: red;"
					  *ngIf="lastNameError">
                Required
            </span>
			</div>
		</div>
		<div class="flex-row gap-1 position-relative w-100">
			<country-code-select [(model)]="signupData.accountDetails.phone.country_code" ></country-code-select>
			<input id="contactsAddPhone" class="form-control"
				   type="text" placeholder="Phone number (optional)"
				   style="flex: 1 0; height: 100%; min-height: 35px; margin-right: 0; margin-bottom: 0;"
				   pattern="/^[0-9]*$/"
				   maxlength="11"
				   [(ngModel)]="signupData.accountDetails.phone.phone_number">
			<i style="font-weight: 400; position: absolute; transform: translateY(-50%); right: -20px; top: 50%;" class="fas fa-question-circle" tooltip="Can be used for user authentication"></i>
		</div>
		<div class="position-relative w-100">
			<input id="signupPassword" required class="form-control w-100" style="padding-right: 27px;"
				   type="{{!showPassword && !hasWKTextSecurity ? 'password' : 'text'}}"
				   [ngClass]="{'validation-error' : passwordError, 'nofill': !showPassword}"
				   placeholder="Password"
				   (keydown)="passwordError = null;"
				   minlength="6"
				   [(ngModel)]="signupData.accountDetails.password">
			<label style="opacity: 0.35; position: absolute; top: 50%; right: 0; transform: translate(-60%,-50%);" class="hidepwd cursor-pointer">
				<input type="checkbox" [(ngModel)]="showPassword"/>
				<i class="fa fa-eye unchecked"></i>
				<i class="fa fa-eye-slash checked"></i>
			</label>
			<span class="animated fadeIn" style="position: absolute; bottom: 1px; right: 0; transform: translateY(calc(100% + 3px)); color: red;"
				  [ngStyle]="{'font-size' : signupData.accountDetails.password ? '10.3px' : '11.5px'}"
				  *ngIf="passwordError">
            {{passwordError}}
        </span>
		</div>
		<div class="position-relative w-100">
			<input id="signupPassword2" required class="form-control w-100" style="padding-right: 27px;"
				   type="{{!showReTypePassword && !hasWKTextSecurity ? 'password' : 'text'}}"
				   [ngClass]="{'validation-error' : reTypePasswordError, 'nofill': !showReTypePassword}"
				   placeholder="Retype password"
				   (keydown)="reTypePasswordError = null;"
				   minlength="6"
				   [(ngModel)]="this.signupData.accountDetails.reTypePassword">
			<label style="opacity: 0.35; position: absolute; top: 50%; right: 0; transform: translate(-60%,-50%);" class="hidepwd cursor-pointer">
				<input type="checkbox" [(ngModel)]="showReTypePassword"/>
				<i class="fa fa-eye unchecked"></i>
				<i class="fa fa-eye-slash checked"></i>
			</label>
			<span class="animated fadeIn" style="position: absolute; bottom: 1px; right: 0; transform: translateY(calc(100% + 3px)); font-size: 11.5px; color: red;"
				  *ngIf="reTypePasswordError">
            {{reTypePasswordError}}
        </span>
		</div>
		<btn-c class="w-100 mt-2" *ngIf="!isPurchasePage && !partner"
			 [loading]="signingUpInProcess"
			 (action)="error = false; validateSignUpForm() && continue();">
			Create account
		</btn-c>
		<div class="flex-column gap-1 centralize my-3" *ngIf="!isTrialSignupPage && !isPurchasePage">
			<span>Already have an account?</span>
			<a class="blue-link no-underline" (click)="updateLoginStatus(loginStatuses.emailOrSSOForm)">
				Log in
			</a>
		</div>
		<span class="opacity-75 text-center" style="font-size: 0.7rem; margin-bottom: -45px;"
			  *ngIf="!isTrialSignupPage"
			  [ngClass]="{'text-nowrap' : !isPurchasePage}">
            By signing up, you agree to our <a class="blue-link fw-500 no-underline hvr-underline" target="_blank" rel="noopener noreferrer nofollow" href="{{eula}}">Terms of Use</a>, <a class="blue-link fw-500 no-underline hvr-underline" target="_blank" rel="noopener noreferrer nofollow" href="{{privacy}}">Privacy</a>, and <a class="blue-link fw-500 no-underline hvr-underline" target="_blank" rel="noopener noreferrer nofollow" href="{{antiSpam}}">Anti-Spam Policy</a>
        </span>
	</div>
	<div class="flex-column w-100 f-1-0 justify-content-evenly" style="max-height: 450px;" *ngIf="signUpState === 'finalState' && !isPurchasePage">
		<div class="successm">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
				<circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6"
						stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"></circle>
				<polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6"
						  stroke-linecap="round" stroke-miterlimit="10"
						  points="100.2,40.2 51.5,88.8 29.8,67.5 "></polyline>
			</svg>
			<p class="success my-2">Successfully signed up!</p>
		</div>
		<p style="font-size:14px; line-height: 1.5em; text-align: center;">
			Congratulations!
			<br/><br/>
			One step left in order to enjoy our services,
			<br/>
			To verify your account for security reasons, we have sent you a verification email.
			<br/>
			Please verify to use our app.
		</p>
		<btn-c class="w-100 mt-1" *ngIf="!isTrialSignupPage"
			 [loading]="navigateToAppAfterCompleteInProcess"
			 (action)="navigateToAppAfterComplete();">
			Go to app
		</btn-c>
	</div>
</div>







