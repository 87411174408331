import {Component, EventEmitter, Output} from "@angular/core";
import _ from 'lodash';
import {DICTIONARY} from '../../../dictionary';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {GeneralService} from '../../../services/generalService';
import {AccountCompromisedService} from '../../../services/accountCompromisedService';
import {ClipboardService} from "ngx-clipboard";

@Component({
	selector: 'user-incidents-info-component',
	templateUrl: './user-incidents-info.component.html',
})
export class AccountCompromisedUserIncidentsInfoComponent {
    dic = DICTIONARY;
	incidentInfo;
	_ = _;
	getEmailContentInProcess;
	previewAttachment;
	reviewerSearchTxt;
	showMore = {
		to: false,
		cc: false,
		bcc: false
	};

	incidentsActionsDic = {
		block24: { display: 'User was blocked for 24 hours' },
		block: { display: 'User was blocked' },
		resetPassword: { display: 'User password was reset' },
		disableAccount: { display: 'User account was disabled' },
		removeRestrictions: { display: 'Removed restrictions for user' },
		risky: { display: 'User marked as risky for 24 hours' },
		[this.dic.CONSTANTS.compromisedIncidentStatus.approved]: { display: "Updated incident status to \"Approved\"" },
		[this.dic.CONSTANTS.compromisedIncidentStatus.suspicious]: { display: "Updated incident status to \"Suspicious\"" },
		[this.dic.CONSTANTS.compromisedIncidentStatus.compromised]: { display: "Updated incident status to \"Compromised\"" },
		note: { display: "note" },
		securityReviewerActivity: {display: "Security reviewer"}
	};

	conditionDescriptors = [
		{ propKey: 'bodyContains',              label: 'Body contains',              type: 'array_of_strings' },
		{ propKey: 'bodyOrSubjectContains',     label: 'Body or Subject contains',   type: 'array_of_strings' },
		{ propKey: 'categories',               label: 'Categories',                  type: 'array_of_strings' },
		{ propKey: 'fromAddresses',            label: 'From addresses',              type: 'array_of_recipients' },
		{ propKey: 'hasAttachments',           label: 'Has attachments',             type: 'boolean' },
		{ propKey: 'headerContains',           label: 'Header contains',             type: 'array_of_strings' },
		{ propKey: 'importance',               label: 'Importance',                  type: 'string' },
		{ propKey: 'isApprovalRequest',        label: 'Is approval request',         type: 'boolean' },
		{ propKey: 'isAutomaticForward',       label: 'Is automatic forward',        type: 'boolean' },
		{ propKey: 'isAutomaticReply',         label: 'Is automatic reply',          type: 'boolean' },
		{ propKey: 'isEncrypted',              label: 'Is encrypted',                type: 'boolean' },
		{ propKey: 'isMeetingRequest',         label: 'Is meeting request',          type: 'boolean' },
		{ propKey: 'isMeetingResponse',        label: 'Is meeting response',         type: 'boolean' },
		{ propKey: 'isNonDeliveryReport',      label: 'Is non-delivery report',      type: 'boolean' },
		{ propKey: 'isPermissionControlled',   label: 'Is permission controlled',    type: 'boolean' },
		{ propKey: 'isReadReceipt',            label: 'Is read receipt',             type: 'boolean' },
		{ propKey: 'isSigned',                 label: 'Is signed',                   type: 'boolean' },
		{ propKey: 'isVoicemail',              label: 'Is voicemail',                type: 'boolean' },
		{ propKey: 'messageActionFlag',        label: 'Message action flag',         type: 'string' },
		{ propKey: 'notSentToMe',              label: 'Not sent to me',             type: 'boolean' },
		{ propKey: 'recipientContains',        label: 'Recipient contains',          type: 'array_of_strings' },
		{ propKey: 'senderContains',           label: 'Sender contains',             type: 'array_of_strings' },
		{ propKey: 'sensitivity',              label: 'Sensitivity',                 type: 'string' },
		{ propKey: 'sentCcMe',                 label: 'Sent CC me',                  type: 'boolean' },
		{ propKey: 'sentOnlyToMe',             label: 'Sent only to me',             type: 'boolean' },
		{ propKey: 'sentToAddresses',          label: 'Sent to addresses',           type: 'array_of_recipients' },
		{ propKey: 'sentToMe',                 label: 'Sent to me',                  type: 'boolean' },
		{ propKey: 'sentToOrCcMe',             label: 'Sent to or CC me',            type: 'boolean' },
		{ propKey: 'subjectContains',          label: 'Subject contains',            type: 'array_of_strings' },
		{ propKey: 'withinSizeRange',          label: 'Within size range',           type: 'sizeRange' },
	];

	actionDescriptors = [
		{ propKey: 'assignCategories',          label: 'Assign categories',           type: 'array_of_strings' },
		{ propKey: 'copyToFolder',             label: 'Copy to folder',              type: 'string' },
		{ propKey: 'delete',                   label: 'Delete',                       type: 'boolean' },
		{ propKey: 'forwardAsAttachmentTo',    label: 'Forward as attachment to',     type: 'array_of_recipients' },
		{ propKey: 'forwardTo',                label: 'Forward to',                   type: 'array_of_recipients' },
		{ propKey: 'markAsRead',               label: 'Mark as read',                 type: 'boolean' },
		{ propKey: 'markImportance',           label: 'Mark importance',              type: 'string' },
		{ propKey: 'moveToFolder',             label: 'Move to folder',               type: 'string' },
		{ propKey: 'permanentDelete',          label: 'Permanent delete',             type: 'boolean' },
		{ propKey: 'redirectTo',               label: 'Redirect to',                  type: 'array_of_recipients' },
		{ propKey: 'stopProcessingRules',      label: 'Stop processing rules',        type: 'boolean' },
	];

	incidentActions = [
		this.dic.CONSTANTS.accountCompromisedIncidentActions.status,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.reviewerNote
	];

    constructor(
		public accountCompromisedService:AccountCompromisedService,
		private rs:RouteService,
		public clipboard: ClipboardService,
		private ns:NotificationService,
		public gs:GeneralService) {
    }

    openLocation(ll) {
        this.gs.openLocation(ll);
    }

	selectAction = (incidentObj, action) => {
		switch (action) {
			case this.dic.CONSTANTS.accountCompromisedIncidentActions.status.display:
				this.accountCompromisedService.changeIncidentStatus(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser.display:
				this.accountCompromisedService.blockUser(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours.display:
				this.accountCompromisedService.blockUser24hoursExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours.display:
				this.accountCompromisedService.riskyUser24hoursExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer.display:
				this.accountCompromisedService.disableUserMailServerExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer.display:
				this.accountCompromisedService.resetPasswordMailServerExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions.display:
				this.accountCompromisedService.removeRestrictionsUser(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.reviewerNote.display:
				this.accountCompromisedService.openReviewerNotePopup(incidentObj);
				break;

			case this.dic.CONSTANTS.quarantinedActions.createIncident.display:
				this.accountCompromisedService.createIncidentPopup(incidentObj);
				break;
		}
	}

    selectIncidentAction = (incidentObj, action) => {
        switch (action) {
            case this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display:
                this.accountCompromisedService.getUserIncidentInfo(incidentObj);
                break;

            case this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display:
                this.accountCompromisedService.deleteIncident(incidentObj, () => {
                    _.remove<any>(this.accountCompromisedService.userIncidentsPopup.userIncidentsInfo, (incidentItem:any) => incidentItem._id === incidentObj._id);
                });
                break;
        }
    }

	setStatusConfirm = (incidentObj, newStatus) => {
		const incidentInfo = {
			incidentId: incidentObj._id,
			status: newStatus.toLowerCase(),
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.status.value
		};

		this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
			this.accountCompromisedService.setStatusPopup = null;
			incidentObj.status = incidentInfo.status;
			this.ns.showInfoMessage('Incident updated successfully');

			this.accountCompromisedService.addIncidentAction(newStatus.toLowerCase(), incidentObj);
		});
	}

    showIncidentInfoActions = () => {
        return [
			this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display
		];
    }

    searchIncidents = (searchTerm, activeFilters) => {
		this.accountCompromisedService.userIncidentsPopup.userIncidentsInfo.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = this.searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			// // no filters for this table

			record.hide = false;
		});
    }

    searchTextExecute(incident, searchTerm) {
        searchTerm = searchTerm.toLowerCase();
        return ((incident.incident_type && this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type] &&
                this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name &&
                this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name.toLowerCase().indexOf(searchTerm) > -1));
    }

	getIncidentEmailContent = () => {
		this.getEmailContentInProcess = true;

		const actionInfo = {
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.viewContent.value,
			incidentId: this.accountCompromisedService.incidentInfoPopup.IncidentInfo._id
		};

		this.rs.incidentAction(this.accountCompromisedService.incidentInfoPopup.IncidentInfo.user_email, actionInfo).then(response => {
			this.incidentInfo['emailInfo'] = response;
			this.accountCompromisedService.incidentInfoPopup.emailContent = '<hr/><br/>' + response.content;
			this.getEmailContentInProcess = false;
		}, () => {
			this.getEmailContentInProcess = undefined;
		});
	}

	downloadAttachment = (attachmentObj) => {
		if (attachmentObj.inProcess) return;

		const url = `/admin/compromised/incidents/attachment/${this.accountCompromisedService.incidentInfoPopup.IncidentInfo.user_email}/${this.accountCompromisedService.incidentInfoPopup.IncidentInfo._id}/${attachmentObj._id}`;

		this.gs.downloadFile(url, attachmentObj, false, () => {});
	};

	searchReviewerActivity =  () => {
		this.incidentInfo.actions.forEach(record => {
			// search
			if (this.reviewerSearchTxt) {
				const isFound = this.searchReviewerActivityExecute(record, this.reviewerSearchTxt);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			// // no filters for reviewers table

			record.hide = false;
		});
	};

	searchReviewerActivityExecute(obj, searchTerm) {
		searchTerm = searchTerm.toLowerCase();
		return ((obj.admin?.toLowerCase().indexOf(searchTerm) > -1) ||
				(obj.name && this.incidentsActionsDic[obj.name] && this.incidentsActionsDic[obj.name]?.display.toLowerCase().indexOf(searchTerm) > -1)) ||
			(obj.action && obj.action.toLowerCase().indexOf(searchTerm) > -1);
	}
}
