<div class="flex-column gap-4 f-1-0 overflow-auto">
    <div class="flex-row align-items-center" style="padding-top: 10px;">
        <span>Add and manage domains that can be used to send and receive emails with Trustifi</span>
        <!--Information modal-->
        <more-info-c guideTitle="How to verify my domains" guideUrl="https://docs.trustifi.com/docs/domain-verification" class="domains-page-more-info">
            <div class="flxClmn vSpace05" style="flex: 1 0;">
                <span style="font-weight: bold;">It is recommended to verify your domains and enable DKIM signature:</span>
                <ul class="vSpace1" style="margin-top: 0;">
                    <li style="color:#484848;">
                        <span>When you verify an entire domain, you are verifying all email addresses from that domain automatically</span>
                    </li>
                    <li style="color:#484848;">
                        <span>Using a DKIM signature enhance deliverability with DKIM-compliant ISPs. An email message that is sent using DKIM includes a DKIM-Signature header field that contains a cryptographically signed representation of all, or part, of the message</span>
                    </li>
                    <li style="color:#484848;">
                        <span>MX records added here do not affect the domain's regular email flow</span>
                    </li>
                </ul>
            </div>
        </more-info-c>
    </div>

    <!--domains table-->
    <trustifi-table-c
            class="trustifi-table" style="flex: 1 0; z-index: 5;"
            [list]="userDomains"
            [headers]="[
				{text: 'Domain', sortKey: 'domain'},
				{text: 'Status', sortKey: 'verified', tooltip: 'Indicates whether emails can be sent on behalf of this domain'},
				{text: 'DKIM', sortKey: 'dkim_verified', tooltip: 'Indicates whether emails sent on behalf of this domain will conform with the domain\'s DKIM policy'},
				{text: 'MAIL FROM', sortKey: 'mail_from_verified', tooltip: 'The emails you send will be marked as originating from your domain and bounce/feedback can reach back to you. You need to publish an SPF record in order to prove that Trustifi, via Amazon SES, is authorized to send email from your domain'},
				{text: 'Email Feedback Forwarding', sortKey: 'email_feedback_forwarding', tooltip: 'Notify senders by email whenever a message sent from this domain produces bounce or complaint feedback (those emails are sent from mailer-daemon@amazonses.com)'},
				{text: 'SPF', sortKey: 'spf_verified', tooltip: 'Ensure that all Trustifi related services for Inbound and Outbound will be authenticate'},
				{text: 'Assigned To', sortKey: 'assigned_to'},
			]"
            [cellsPrototype]="[
				{textKey: 'domain'},
				{html: 'statusCell'},
				{html: 'dkimCell'},
				{html: 'mailFromCell'},
				{html: 'emailFeedbackCell'},
				{html: 'spfCell'},
				{html: 'assignedToCell'},
			]"
            [bulkActions] = "{
				enable: true,
				selectBulkActionCb:	selectMultipleDomainsAction,
				initBulkActionsFunction: showBulkDomainsActions,
				showInRed: {enable: true, terms: ['delete']}
			}"
            [cellActions] = "{
				enable: true,
				selectItemActionCb:	selectDomainAction,
				initActionsFunction: showDomainActions,
				showInRed: {enable: true, terms: ['delete']}
			}"
            (searchFunction)="searchDomain($event.searchTerm, $event.activeFilters)"
            [filterData]="filterData"
            [options]="{
				exportToCsvFunction: exportToCsv,
				loadingTableFlag: loadingDomainsInProcess,
				itemsNameSingular: 'domain',
				refreshFunction: getDomains,
				defaultSortKey: '-created'
			}"
    >
		<ng-template #customCells let-item let-cellId="cellId">
			<div *ngIf="cellId === 'statusCell'">
				<span [ngStyle]="item.verified ? {'color': 'green'} : {'color': 'darkorange'}">{{item.verified ? 'Can send' : 'Cannot send'}}</span>
			</div>
			<div *ngIf="cellId === 'dkimCell'">
				<span [ngStyle]="item.dkim_verified ? {'color': 'green'} : {'color': 'darkorange'}">{{item.dkim_verified ? 'Verified' : 'Unverified'}}</span>
			</div>
			<div *ngIf="cellId === 'mailFromCell'">
				<span [ngStyle]="item.mail_from_verified ? {'color': 'green'} : {'color': 'darkorange'}">{{item.mail_from_verified ? 'Verified' : 'Unverified'}}</span>
			</div>
			<div *ngIf="cellId === 'emailFeedbackCell'">
				<span [ngStyle]="item.email_feedback_forwarding ? {'color': 'green'} : {'color': 'darkorange'}">{{item.email_feedback_forwarding ? 'Enabled' : 'Disabled'}}</span>
			</div>
			<div *ngIf="cellId === 'spfCell'">
				<span [ngStyle]="item.spf_verified ? {'color': 'green'} : {'color': 'darkorange'}">{{item.spf_verified ? 'Verified' : 'Unverified'}}</span>
			</div>
			<div *ngIf="cellId === 'assignedToCell'">
				<span>{{item.assigned_to || 'Your plan'}}</span>
			</div>
		</ng-template>

        <div tableButtons>
            <div class="flxRow hSpace1" style="align-items: center;">
                <btn-c endIcon="fa fa-plus"
                     (action)="addActiveDomain();">
                    Add Domain
                </btn-c>
            </div>
        </div>
    </trustifi-table-c>

	<popup-c class="bs-enabled" *ngIf="addActiveDomainPopup?.show"
			 head="Add Domain" size="dynamic"
			 (closeCb)="addActiveDomainPopup.show = false;"
			 (confirmCb)="addDomain(addActiveDomainPopup.domainsName)"
			 [buttons]="{cancel: 'Cancel', confirm: {text: 'Add Domain', disabled: addActiveDomainPopup.applyInProcess || !addActiveDomainPopup.domainsName.length}}">
		<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
			<p style="font-size: 14px;">Please fill in the domain name</p>

			<div class="align-items-center flex-row gap-3">
				<div>Domain:</div>
				<input type="text" placeholder="Domain's name (required)"
					   style="width:330px;" [required]="true"
					   [(ngModel)]="addActiveDomainPopup.domainsName"
					   [ngStyle]="!addActiveDomainPopup.domainsName && addActiveDomainPopup.errMsg ? {'border-color':'red'} : {}"/>
			</div>
			<p>You will be asked to verify the DNS records that will be provided.</p>
			<p class="text-danger" *ngIf="addActiveDomainPopup.errMsg">{{addActiveDomainPopup.errMsg}}</p>
		</div>

	</popup-c>

    <popup-c *ngIf="dnsDetailsPopup?.show"
           head="Verify Your Domain {{dnsDetailsPopup.domainObj && dnsDetailsPopup.domainObj.domain ? '(' + dnsDetailsPopup.domainObj.domain + ')' : ''}}"
			 subTitle="In order to verify your domain you must add the following records to your DNS records"
           width="min(100%, 1300px)" height="auto"
           (closeCb)="dnsDetailsPopup = null;"
           [buttons]="{cancel: 'OK'}">
        <div class="flxClmn f-1-0 vSpace1" style="overflow: auto;">
            <!--Identity Verification-->
            <div class="flxRow hSpace2">
                <div class="flxClmn-center vSpace2" style="flex-basis: 100px;">
                    <div class="flxRow hSpace05">
                        <span style="font-weight: bold;">Identity</span>
                        <i tooltip="Indicates whether emails can be sent on behalf of this domain" placement="right" style="font-weight: 400;" class="fas fa-question-circle"></i>
                    </div>
                    <span [ngStyle]="dnsDetailsPopup.domainObj.verified ? {'color': 'green'} : {'color': 'darkorange'}">{{dnsDetailsPopup.domainObj.verified ? 'Verified' : 'Unverified'}}</span>
                </div>
                <div style="flex: 1 0; background: #f9f9f9; padding: 15px;">
                    <div style="background: white; padding: 10px;">
                        <table class="w100 simpleDataTable">
                            <thead><tr>
                                <th style="width:10%;">Type</th>
                                <th style="width:15%;">Host</th>
                                <th style="width:75%;">Value</th>
                            </tr></thead>
                            <tbody>
                            <tr>
                                <td>
                                    <span>TXT</span>
                                </td>
                                <td>
                                    <a (click)="copyText('_amazonses');">
                                        <span class="copy-link">{{"_amazonses"}}</span>
                                    </a>
                                </td>
                                <td>
                                    <a (click)="copyText(dnsDetailsPopup.domainObj.data.verify_token);">
                                        <span class="copy-link">
                                            {{dnsDetailsPopup.domainObj.data.verify_token}}
                                        </span>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!--DKIM Verification-->
            <div class="flxRow hSpace2">
                <div class="flxClmn-center vSpace2" style="flex-basis: 100px;">
                    <div class="flxRow hSpace05">
                        <span style="font-weight: bold;">DKIM</span>
                        <i tooltip="Indicates whether emails sent on behalf of this domain will conform with the domain\'s DKIM policy" placement="right" style="font-weight: 400;" class="fas fa-question-circle"></i>
                    </div>
                    <span [ngStyle]="dnsDetailsPopup.domainObj.dkim_verified ? {'color': 'green'} : {'color': 'darkorange'}">{{dnsDetailsPopup.domainObj.dkim_verified ? 'Verified' : 'Unverified'}}</span>
                </div>
                <div style="flex: 1 0; background: #f9f9f9; padding: 15px;">
                    <div style="background: white; padding: 10px;">
                        <table class="w100 simpleDataTable">
                            <thead><tr>
                                <th style="width:10%;">Type</th>
                                <th style="width:45%;">Host</th>
                                <th style="width:45%;">Pointer</th>
                            </tr></thead>
                            <tbody>
                            <tr>
                                <td>
                                    <span>CNAME</span>
                                </td>
                                <td>
                                    <a (click)="copyText(dnsDetailsPopup.domainObj.data.dkim[0] + '._domainkey');">
                                                <span class="copy-link">
                                                    {{dnsDetailsPopup.domainObj.data.dkim[0] + "._domainkey"}}
                                                </span>
                                    </a>
                                </td>
                                <td>
                                    <a (click)="copyText(dnsDetailsPopup.domainObj.data.dkim[0] + '.dkim.amazonses.com');">
                                                <span class="copy-link">
                                                    {{dnsDetailsPopup.domainObj.data.dkim[0] + ".dkim.amazonses.com"}}
                                                </span>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>CNAME</span>
                                </td>
                                <td>
                                    <a (click)="copyText(dnsDetailsPopup.domainObj.data.dkim[1] + '._domainkey');">
                                                <span class="copy-link">{{dnsDetailsPopup.domainObj.data.dkim[1] + "._domainkey"}}
                                                </span>
                                    </a>
                                </td>
                                <td>
                                    <a (click)="copyText(dnsDetailsPopup.domainObj.data.dkim[1] + '.dkim.amazonses.com');"><span class="copy-link">
                                                {{dnsDetailsPopup.domainObj.data.dkim[1] + ".dkim.amazonses.com"}}
                                            </span>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>CNAME</span>
                                </td>
                                <td>
                                    <a (click)="copyText(dnsDetailsPopup.domainObj.data.dkim[2] + '._domainkey');">
                                                <span class="copy-link">{{dnsDetailsPopup.domainObj.data.dkim[2] + "._domainkey"}}
                                                </span>
                                    </a>
                                </td>
                                <td>
                                    <a (click)="copyText(dnsDetailsPopup.domainObj.data.dkim[2] + '.dkim.amazonses.com');">
                                                <span class="copy-link">{{dnsDetailsPopup.domainObj.data.dkim[2] + ".dkim.amazonses.com"}}
                                                </span>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!--"Mail From" Verification-->
            <div class="flxRow hSpace2" *ngIf="dnsDetailsPopup.domainObj?.data?.from_record">
                <div class="flxClmn-center vSpace2" style="flex-basis: 100px;">
                    <div class="flxRow hSpace05">
                        <span style="font-weight: bold;">MAIL FROM</span>
                        <i tooltip="The emails you send will be marked as originating from your domain and bounce/feedback can reach back to you. You need to publish an SPF record in order to prove that Trustifi, via Amazon SES, is authorized to send email from your domain" placement="right" style="font-weight: 400;" class="fas fa-question-circle"></i>
                    </div>
                    <span [ngStyle]="dnsDetailsPopup.domainObj.mail_from_verified ? {'color': 'green'} : {'color': 'darkorange'}">{{dnsDetailsPopup.domainObj.mail_from_verified ? 'Verified' : 'Unverified'}}</span>
                </div>
                <div style="flex: 1 0; background: #f9f9f9; padding: 15px;">
                    <div style="background: white; padding: 10px;">
                        <table class="w100 simpleDataTable">
                            <thead><tr>
                                <th style="width:10%;">Type</th>
                                <th style="width:15%;">Host</th>
                                <th style="width:50%;">Value / Pointer</th>
                                <th style="width:25%;">Priority</th>
                            </tr></thead>
                            <tbody>
                            <tr>
                                <td>
                                    <span>TXT</span>
                                </td>
                                <td>
                                    <a (click)="copyText('trustifi');">
                                        <span class="copy-link">
                                            {{"trustifi"}}
                                        </span>
                                    </a>
                                </td>
                                <td>
                                    <a (click)="copyText('v=spf1 include:amazonses.com ~all');">
                                        <span class="copy-link">
                                            v=spf1 include:amazonses.com ~all
                                        </span>
                                    </a>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <span>MX</span>
                                </td>
                                <td>
                                    <a (click)="copyText('trustifi');">
                                        <span class="copy-link">
                                            {{"trustifi"}}
                                        </span>
                                    </a>
                                </td>
                                <td>
                                    <a (click)="copyText('feedback-smtp.us-east-1.amazonses.com');">
                                        <span class="copy-link">
                                            feedback-smtp.us-east-1.amazonses.com
                                        </span>
                                    </a>
                                </td>
                                <td>
                                    <a (click)="copyText('10');">
                                        <span class="copy-link">
                                            10
                                        </span>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

			<div class="flxRow hSpace2">
				<div class="flxClmn-center vSpace2" style="flex-basis: 100px;">
					<div class="flxRow hSpace05">
						<span style="font-weight: bold;">SPF</span>
						<i tooltip="Recommended: Adding Trustifi’s SPF record to your existing SPF record is recommended because it will ensure that all Trustifi related services for Inbound and Outbound will be authenticated" placement="right" style="font-weight: 400;" class="fas fa-question-circle"></i>
					</div>
					<span [ngStyle]="dnsDetailsPopup.domainObj.spf_verified ? {'color': 'green'} : {'color': 'darkorange'}">{{dnsDetailsPopup.domainObj.spf_verified ? 'Verified' : 'Unverified'}}</span>
				</div>
				<div style="flex: 1 0; background: #f9f9f9; padding: 15px;">
					<div style="background: white; padding: 10px;">
						<span class="copy-link" (click)="copyText('include:_spf.trustifi.com');">include:_spf.trustifi.com</span>
					</div>
				</div>
			</div>
        </div>
    </popup-c>

    <popup-c class="overflownV" *ngIf="assignDomainToAdminPopup?.show"
           head="Assign Domain to Admin"
           size="dynamic"
           (closeCb)="assignDomainToAdminPopup = null"
           (confirmCb)="assignDomainToAdmin()"
           [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: assignToAdminInProcess}}">
        <div class="flex-column f-1-0 gap-1" style="overflow: visible;">
            <span>Assign the domain <b>{{assignDomainToAdminPopup.domainToAssign.domain}}</b> to another admin plan</span>
            <br/>
            <div class="flex-column gap-2" *ngIf="assignDomainToAdminPopup?.adminsList">
                <span style="color: black;">Select the new admin from the following list:</span>
                <app-dropdown-c style="width: 320px;"
                              text="{{assignDomainToAdminPopup?.selectedAdmin?.formattedDisplay || 'Select'}}"
                              [items]="assignDomainToAdminPopup.adminsList"
                              itemDisplayKey="formattedDisplay" [enableSearch]="true"
                              (onItemClicked)="assignDomainToAdminPopup.selectedAdmin = $event.item;"></app-dropdown-c>
            </div>
        </div>
    </popup-c>

    <!--Verification with Amazon popup-->
    <popup-c *ngIf="amazonVerificationPopup?.show"
           head="Amazon Route 53 domain verification" size="medium"
           (closeCb)="amazonVerificationPopup = null;"
           [buttons]="{cancel: 'OK'}">
        <div class="f-1-0" style="color: #3c4043; font-size: 14px; overflow: auto;">
            <span style="font-size: 16px;">Manually add Trustifi DNS records to the Amazon Route 53 DNS Provider</span>
            <br/><br/>
            <div style="max-height: 40vh; overflow: auto; background: rgba(0,0,65,0.007);">
                <p style="line-height: 1.75rem; margin-block-end: 0;">
                    Please follow instructions below :<br/>
                    1. Go to <a style="color: cornflowerblue; font-weight: bold;" href="https://us-east-1.console.aws.amazon.com/route53/v2/hostedzones#" target="_blank">AWS Route53 Hosted Zones page</a> (Login to AWS will be required).<br/>
                    2. Select the required hosted zone.<br/>
                    3. Click on the button <strong>Import zone file</strong>.<br/>
                    5. Copy the following DNS data and paste it in the input area :
                </p>
                <div style="align-items: center; margin:15px;" class="flxRow-start hSpace2">
                    <div style="font-size: 16px; width: 85%; background: #bac9d642; height: 130px; align-items: center; padding: 12px;" class="flxClmn-center">
                        <span style="font-weight:500; overflow: auto; width: 100%; word-break: break-all;" class="hide-scrollbar click-select-all">
                            {{getAmazonDomainConfigString(amazonVerificationPopup.domainObj)}}
                        </span>
                    </div>
                    <a (click)="copyAmazonDomainConfigString();">
                        <i class="fas fa-copy copy-link" tooltip="Copy DNS data"></i>
                    </a>
                </div>
                <p style="line-height: 1.75rem; margin: 0;">
                    6. Click on <strong>Import</strong> to complete the process.
                </p>
            </div>
        </div>
    </popup-c>

	<popup-c class="bs-enabled" *ngIf="unassignFromAdminPopup?.show"
			 head="Unassign domain" size="dynamic"
			 (closeCb)="unassignFromAdminPopup.show = false;"
			 (confirmCb)="unassignFromAdminPopup?.doneCb()"
			 [buttons]="{cancel: 'Cancel', confirm: {text: 'Unassign', disabled: assignToAdminInProcess || unassignFromAdminPopup.verifyDomain.model !== unassignFromAdminPopup.verifyDomain.equalsTo}}">
		<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
			<div class="d-flex gap-1 text-danger pt-2" style="font-size: 14px;">
				<i class="zmdi zmdi-alert-circle-o" style="font-size: 1.5rem; margin-top: -6px;"></i>
				{{unassignFromAdminPopup.subTitle}}
			</div>

			<ul>
				<li *ngFor="let text of unassignFromAdminPopup.bodyDetails; trackBy: gs.trackByIndex">{{text}}</li>
			</ul>

			<div class="align-items-center flex-row gap-3">
				<div>Domain:</div>
				<input type="text" placeholder="Please enter domain you want to unassign"
					   style="width:330px;" [required]="true"
					   [(ngModel)]="unassignFromAdminPopup.verifyDomain.model"
					   [ngStyle]="unassignFromAdminPopup.verifyDomain.model && unassignFromAdminPopup.verifyDomain.model !== unassignFromAdminPopup.verifyDomain.equalsTo ? {'border-color':'red'} : {}"/>
			</div>
		</div>
	</popup-c>
</div>

