<div class="flxClmn">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container">
			<span class="page-title">Sent Emails</span>
		</div>
		<div class="page-subtitle-container">
            <span class="page-subtitle">
				Browse your sent items and perform actions on emails
			</span>
		</div>
	</div>

	<div class="page-inner-content-holder no-scroll flxClmn vSpace2">
		<div class="flxRow" style="flex-wrap: wrap-reverse; margin-bottom: 3px;">
			<!--query-->
			<div class="flxRow" (clickOutside)="showQueryModal = false;" style="position:relative; align-items: stretch;">
				<div class="flxRow" style="flex: 1 0; border: solid 1px #cccccc; border-radius: 3px; overflow: visible; position: relative; min-width: 460px; height: 32px;"
					 [ngStyle]="{'border-color' : isQueryError ? 'red' : '#cccccc'}">
					<div class="flxRow hSpace05" style="align-items: center; padding: 10px; border-right: solid 1px #cccccc; cursor: pointer;"
						 (click)="showQueryModal = !showQueryModal"
						 [ngStyle]="{'background' : showQueryModal ? '#f7f7f7' : 'white'}">
						<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 22px; height: 22px; vertical-align: middle; fill: currentColor; overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M881.4 250.9H141.5c-23.9 0-43.5-19.6-43.5-43.5s19.6-43.5 43.5-43.5h739.9c23.9 0 43.5 19.6 43.5 43.5s-19.6 43.5-43.5 43.5zM272.1 454H141.5c-23.9 0-43.5-19.6-43.5-43.5s19.6-43.5 43.5-43.5h130.6c23.9 0 43.5 19.6 43.5 43.5S296 454 272.1 454zM272.1 657.1H141.5c-23.9 0-43.5-19.6-43.5-43.5s19.6-43.5 43.5-43.5h130.6c23.9 0 43.5 19.6 43.5 43.5s-19.6 43.5-43.5 43.5zM853.8 847.5L715.3 709c-16.9-16.9-16.9-44.6 0-61.5s44.6-16.9 61.5 0L915.3 786c16.9 16.9 16.9 44.6 0 61.5s-44.6 16.9-61.5 0zM467.9 860.2H141.5c-23.9 0-43.5-19.6-43.5-43.5s19.6-43.5 43.5-43.5h326.4c23.9 0 43.5 19.6 43.5 43.5 0.1 23.9-19.5 43.5-43.5 43.5z"/><path d="M619.4 290.5C493.3 290.5 391 392.8 391 519s102.3 228.5 228.5 228.5S847.9 645.2 847.9 519 745.6 290.5 619.4 290.5z m0 388.4c-88.3 0-159.9-71.6-159.9-159.9 0-88.3 71.6-159.9 159.9-159.9s160 71.5 160 159.9c0 88.3-71.6 159.9-160 159.9z"/></svg>
						<i class="fa fa-chevron-down" style="font-size: smaller;"></i>
					</div>

					<div class="flxRow hSpace1 animated fadeIn hvr-shadow" style="flex: 1 0; align-items: center; padding: 0 10px; cursor: pointer;"
						 (click)="showQueryModal = !showQueryModal">
						<a class="diamond-indent" style="font-weight: bold; height: auto; position: relative; padding-left: 12px; text-transform: capitalize;"
						   *ngFor="let query of emailQueryDisplay;"
						   title="{{query.tooltip}}">
							{{query.title}}
						</a>
					</div>

					<!--query modal-->
					<div class="" style="z-index: 2000; position: absolute; bottom: -3px; transform: translateY(100%); line-height: 15px; left: 0; background: white; box-shadow: 0 1px 5px grey; min-width: 537px;"
						 *ngIf="showQueryModal">
						<div class="flxClmn vSpace2" style="padding: 20px;">
							<div class="flxRow hSpace2">
								<label class="radio-container" style="display: block; width: fit-content; height: 30px; margin: 0; padding: 0; transform: scale(0.85); margin-top: 4px;">
									<input type="radio" name="normalQuery" [value]="queryTypes.normal"
										   [(ngModel)]="queryType"
										   [checked]="queryType === queryTypes.normal"
										   (change)="queryValidationErrors = [];">
									<span class="checkmark"></span>
								</label>
								<div class="flxClmn vSpace2" style="padding-left: 15px; flex: 1 0;"
									 [ngStyle]="queryType === queryTypes.messageIdOnly ? {'opacity' : '0.5', 'cursor' : 'pointer'} : {'opacity' : '1' , 'cursor' : 'default'}"
									 (click)="queryType = queryTypes.normal">
									<div class="flxRow hSpace2">
										<span style="font-weight: bold; flex-basis: 78px; margin-top: 5px;">Date:</span>
										<div class="flxClmn" style="margin-left: 0; flex: 1 0;">
											<app-dropdown-c class="capitalize-box" style="height: 27px; min-height: 27px; flex: 1 0; max-width: 280px;"
															[ngStyle]="{'margin-bottom' : resultsPeriod === dic.CONSTANTS.trendsPeriod.range ? '10px' : '0'}"
															[text]="resultsPeriod.display">
												<li *ngFor="let period of searchPeriod | keyvalue: gs.returnZero"
													(click)="changePeriod(period.value)">
													{{period.value.display}}
												</li>
											</app-dropdown-c>
											<div class="flxRow hSpace1" style="align-items: center; margin-top: -4px; margin-left: 6px;" *ngIf="resultsPeriod === dic.CONSTANTS.trendsPeriod.range">
												<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
													<span>From:</span>
													<date-picker-c class="f-1-0"
																   [(dateModel)]="range.start"
																   [dateMaxLimit]="range.end"></date-picker-c>
												</div>
												<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
													<span>Until:</span>
													<date-picker-c class="f-1-0"
																   [(dateModel)]="range.end"
																   [dateMinLimit]="range.start"></date-picker-c>
												</div>
											</div>
										</div>
									</div>

									<div class="flxRow hSpace3" style="align-items: center; position: relative;">
										<span style="font-weight: bold; flex-basis: 73px;">Subject:</span>
										<input type="text" style="margin: 0; flex: 1 0; max-width: 280px; height: 27px;"
											   placeholder="Email's subject (exact or partial)"
											   [(ngModel)]="emailQuery.subject">
									</div>

									<div class="flxRow hSpace3" style="align-items: center; position: relative;">
										<span style="font-weight: bold; flex-basis: 73px;">Recipient:</span>
										<input type="text" style="margin: 0; flex: 1 0; max-width: 280px; height: 27px;"
											   placeholder="recipient's email (exact or partial)"
											   [(ngModel)]="emailQuery.recipient">
									</div>
								</div>
							</div>

							<div class="flxRow hSpace2" style="align-items: flex-start;">
								<label class="radio-container" style="display: block; width: fit-content; height: 30px; margin: 0; padding: 0; transform: scale(0.85); margin-top: 4px;">
									<input type="radio" name="byIdQuery" [value]="queryTypes.messageIdOnly"
										   [(ngModel)]="queryType"
										   [checked]="queryType === queryTypes.messageIdOnly"
										   (change)="queryValidationErrors = [];">
									<span class="checkmark"></span>
								</label>
								<div class="flxRow hSpace3" style="align-items: center; padding-left: 15px; flex: 1 0; position: relative;"
									 [ngStyle]="queryType === queryTypes.normal ? {'opacity' : '0.5', 'cursor' : 'pointer'} : {'opacity' : '1' , 'cursor' : 'default'}"
									 (click)="queryType = queryTypes.messageIdOnly">
									<span style="font-weight: bold; flex-basis: 73px;">Message ID:</span>
									<input type="text" style="margin: 0; flex: 1 0; max-width: 280px; height: 27px;"
										   placeholder="Exact message ID"
										   [ngClass]="{'validation-error' : queryValidationErrors.includes('message_id')}"
										   [(ngModel)]="emailQuery.message_id">
									<span style="position: absolute; bottom: -1px; right: calc(20% + 7px); transform: translateY(15px); font-size: 12px; color: red;"
										  *ngIf="queryValidationErrors.includes('message_id')">
                                            required field
                                        </span>
								</div>
							</div>

							<div class="flex-row justify-content-between">
								<div class="flexRow-start hSpace1 md-checkbox"
									 *ngIf="(userInfo.user_role === dic.CONSTANTS.userRole.admin || userInfo.user_role === dic.CONSTANTS.userRole.subAdmin)
          								&& userInfo.plan.outbound_allow_admin_view_users_sent_emails">
									<input id="checkbox-blockFilesUserPreferences" type="checkbox" [(ngModel)]="getAllEmailsInPlan">
									<label for="checkbox-blockFilesUserPreferences">
										<span style="vertical-align: middle;">Search emails from all users</span>
									</label>
								</div>

								<div style="margin-left: auto;">
									<btn-c id="getMyEmailsBtnInner" style="align-self: flex-end; height: 32px;"
										   [loading]="getEmailsInProcess"
										   (action)="applyQuery();">
										Get Emails
									</btn-c>
								</div>
							</div>

						</div>
					</div>
				</div>

				<!--abort loading label-->
				<span class="flxClmn-center animated fadeIn" style="cursor: pointer; height: calc(100% - 4px); position: absolute; right: 16%; top: 2px; padding: 0 6px; background: white; font-weight: 300; color: #85000d; font-size: 0.75rem;"
					  *ngIf="showAbortLoadingEmailsLabel"
					  (click)="abortLoadingEmails()">
                        <b>Abort</b>
				</span>

				<btn-c id="getMyEmailsBtn" class="connected-to-left" style="align-self: flex-end; margin-left: -6px; height: 32px; z-index: 3;"
					   [loading]="getEmailsInProcess"
					   (action)="applyQuery();">
					Get Emails
				</btn-c>
			</div>
		</div>

		<!--main content area-->
		<div class="flxRow" style="flex: 1 0; border-top: solid 1px #e3e4e4; padding-top: 10px; overflow: auto;">
			<!--case panel / email list area (left side)-->
			<div class="flxClmn" style="flex: 1 0; padding: 5px; min-width: 450px; background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent);"
				 [ngClass]="getEmailsInProcess && !myEmailsService.currentFilteredEmailsList && 'shinyFX'">

				<!--case panel - placeholder-->
				<div class="flxClmn" style="position: relative; flex: 1 0; overflow: auto;" *ngIf="!myEmailsService.currentFilteredEmailsList">
					<span style="position: absolute; z-index: 10; top: 50%; left: 50%; transform: translate(-50%, -50%); white-space: nowrap; opacity: 0.4; font-weight: bold;">{{getEmailsInProcess ? 'Loading emails list' : 'Select query and click on "Get Emails"'}}</span>
					<div class="flxClmn fading-overlay" style="flex: 1 0; padding: 5px; background: white; overflow: auto;">
						<div class="flxClmn" style="overflow: hidden; background: #f7f7f7; align-items: center; flex: 1 0;">
							<div class="flxRow hSpace1 w100" style="height: 75px; min-height: 75px; padding: 10px; opacity: 0.6; "
								 *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]; let last = last;"
								 [ngStyle]="!last && {'border-bottom': 'solid 1px #e3e4e4'}">
								<div class="flxClmn-center" style="align-items: center;">
									<div style="height: 15px; width: 15px; border-radius: 50%; background: #c4c4c4; opacity: 0.15;"></div>
								</div>
								<div class="flxClmn-between" style="flex: 3 0;">
									<div style="height:15px; max-width: 50%;  background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
									<div style="height: 20px; max-width: 73%; background: #c4c4c4; border-radius: 50px; opacity: 0.2;"></div>
								</div>
								<div class="flxClmn-between" style="flex: 1 0; padding: 3px 0;">
									<div style="height:15px; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
									<div class="flxRow-between">
										<div style="height:15px; width: 25%; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
										<div style="height:15px; width: 25%; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
										<div style="height:15px; width: 25%; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--Email list-->
				<div class="flxClmn" style="flex: 1 0; overflow: auto;" *ngIf="myEmailsService.currentFilteredEmailsList">
					<div class="w100 flxRow animated fadeIn" style="background: #677184; padding: calc(3px + 0.4vw); align-items: center; z-index: 1; min-height: 60px;">
						<div class="flxRow-between hSpace2 w100" style="position: relative; align-items: center;">
							<!--search results input and label-->
							<div class="flxRow hSpace1 f-1-0">
								<div style="width: 20px; align-self: flex-end; padding-bottom: 7px;">
									<label class="policyCheckbox"
										   (click)="$event.stopPropagation()"
										   style="width: 16px; display: block; vertical-align: unset; position: unset; margin: 0; filter: invert(1) brightness(3);">
										<input type="checkbox" (change)="toggleAllEmails()"
											   [(ngModel)]="isSelectedAll">
										<span style="font-size: 12px;">&nbsp;</span>
									</label>
								</div>
								<div class="flxRow-between f-1-0 hSpace1" style="align-items: center;">
									<app-dropdown-c class="animated fadeIn" style="width: 150px; animation-duration: 0.1s;"
													*ngIf="!selectedEmails.length"
													[isDisabled]="!myEmailsService.currentEmailsList.length"
													[text]="selectedFilter">
										<li *ngFor="let filter of dic.CONSTANTS.mailBoxFilters | keyvalue: gs.returnZero"
											(click)="selectFilter(filter.value);"
											id="{{filter.key}}">
											{{filter.value}}
										</li>
									</app-dropdown-c>
									<app-dropdown-c class="animated fadeIn lf-dropdown-alt" style="width: 150px; animation-duration: 0.1s;"
													*ngIf="selectedEmails.length"
													[isDisabled]="bulkEmailActionInProcess"
													[isLf]="true"
													text="Actions for {{selectedEmails.length}} item{{selectedEmails.length > 1 ? 's' : ''}}">
										<li (click)="deleteMessages(selectedEmails)"
											id="deleteMultipleEmails">
											<span style="color: red;">Delete</span>
										</li>
									</app-dropdown-c>
									<div class="flxRow-start hSpace1" style="align-items: center;">
										<span style="color: white; text-align: center;" *ngIf="!getEmailsInProcess && myEmailsService.currentFilteredEmailsList.length">
											{{myEmailsService.currentFilteredEmailsList.length}} email{{myEmailsService.currentFilteredEmailsList.length > 1 ? 's' : ''}}
										</span>
										<div class="loading-spinner" style="position: relative; top: 0; left: 0; border: 2px solid transparent; border-top-color: white !important; border-left-color: white !important; opacity: 1; height: 15px; width: 15px;"
											 *ngIf="myEmailsService.currentEmailsList.length && getEmailsInProcess"></div>
										<!--Search-->
										<search-box [isDisabled]="!myEmailsService.currentEmailsList.length"
													[(model)]="searchSentEmailTxt"
													(modelChange)="searchEmailInResults(searchSentEmailTxt)"
													placeholder="Find in results" style="min-width: 185px;">
										</search-box>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--results table-->
					<div class="animated fadeIn flxClmn" style="flex: 1 0; overflow: auto; background: white;">
						<virtual-scroller class="f-1-0" style="border: solid 1px #cccccc;"
										  #archiveEmailsListScroll
										  [items]="myEmailsService.currentFilteredEmailsList"
										  [enableUnequalChildrenSizes]="true"
										  [ngClass]="{'is-loading-or-empty' : !myEmailsService.currentFilteredEmailsList.length}">
							<table class="w100 mailsListTable">
								<tbody #container>
								<ng-container *ngFor="let email of archiveEmailsListScroll.viewPortItems">
									<!--parent email-->
									<ng-container
										[ngTemplateOutlet]="emailRecordTemplate"
										[ngTemplateOutletContext]="{$implicit: email}">
									</ng-container>

									<!--(record template)-->
									<ng-template #emailRecordTemplate let-email>
										<tr (click)="getSentEmailInfo(email)"
											[ngClass]="{'selected': !email.depth && email.selected, 'is-active': email.show}">
											<td style="width: 20px; padding-left: 8px;">
												<label *ngIf="!email.depth && !email.isCampaignTitle"
													   class="policyCheckbox"
													   (click)="$event.stopPropagation()"
													   style="width: 16px; display: block; vertical-align: unset; position: unset; margin: 0; ">
													<input type="checkbox"
														   (change)="checkSelectAllEmails()"
														   [(ngModel)]="email.selected"/>
													<span style="font-size: 12px;">&nbsp;</span>
												</label>
											</td>
											<td [ngStyle]="{'padding-left' : email.depth ? email.depth * 15 + 'px' : email.methods?.secure_mass && !email.isCampaignTitle ? '15px' : ''}">
												<div class="flxClmn vSpace1 h100">
													<div class="ellipsis" style="height: 16px;">
														<!--regular email-->
														<span style="opacity: 0.85;" *ngIf="!email.methods?.secure_mass && !email.isCampaignTitle">{{email.recipientsNamesStr}}</span>
														<!--email of mass campaign-->
														<span *ngIf="email.methods?.secure_mass && !email.isCampaignTitle" style="width: auto;">{{email.recipients_count}} recipients</span>
														<!--campaign title record-->
														<span *ngIf="email.isCampaignTitle">{{email.total_emails_count}} email{{email.total_emails_count > 1 ? 's' : ''}} ({{email.total_recipients_count}} recipients)</span>
														<!--Draft-->
														<span style="font-weight: 500; color: dimgrey;" *ngIf="email.is_draft">[Draft]</span>
													</div>

													<div style="font-weight: 500; font-size: 0.95rem;" (click)="(email.replies?.length || email.has_replies) && getAndToggleReplies(email); email.isCampaignTitle && showOrHideCampaignItems(email); (email.replies?.length || email.has_replies || email.isCampaignTitle) && $event.stopPropagation();">
														<i class="fa fa-chevron-right" style="color: royalblue; transition: transform 0.15s ease-out; padding: 0 5px; margin-right: 5px;"
														   *ngIf="email.has_replies || email.replies?.length || email.depth || email.isCampaignTitle"
														   [ngStyle]="{'transform' : email.isExpanded ? 'rotate(90deg)' : 'rotate(0deg)', 'visibility' : email.has_replies || email.replies?.length || email.isCampaignTitle ? 'visible' : 'hidden'}"></i>
														<span *ngIf="email.sent.title">{{email.sent.title}}</span>
														<span *ngIf="!email.sent.title" style="opacity: 0.5;">No subject</span>
													</div>
												</div>
											</td>
											<td style="width: 150px;">
												<div class="flxClmn-between vSpace05 h100" style="align-items: flex-end;">
													<div style="flex: 1 0;">
														<span>{{ email.created | date:'MM/dd/yyyy HH:mm' }}</span>
													</div>
													<div class="flxRow hSpace05 w100 f-1-0" style="align-items: flex-end; flex-direction: row-reverse;">
														<div style=" color: darkslategrey;" *ngIf="email.sent?.attachments?.length">
															<span style="font-weight: 400;" >{{email.sent?.attachments?.length}}</span>
															<i class="fas fa-paperclip" style=" font-size: 14px; -webkit-text-stroke: 0.3px #f7f6f9;"></i>
														</div>

														<span style="font-size: 11px; font-family: 'Roboto Condensed', 'Helvetica Neue','Helvetica', 'sans-serif'; color: darkred; opacity:0.5;"
															  *ngIf="email.numberRecipientsBlocked && email.numberRecipientsBlocked === email.recipients_count" tooltip="Email is blocked for all recipients">
															Blocked
														</span>
														<span style="font-size: 11px; font-family: 'Roboto Condensed', 'Helvetica Neue','Helvetica', 'sans-serif'; color: darkorange; opacity:0.5;"
															  *ngIf="email.numberRecipientsBlocked && email.numberRecipientsBlocked !== email.recipients_count" tooltip="Email is blocked for {{email.numberRecipientsBlocked}} recipients">
															Partially Blocked
														</span>
														<span style="font-size: 11px; font-family: 'Roboto Condensed', 'Helvetica Neue','Helvetica', 'sans-serif'; color: darkred; opacity:0.5;"
															  *ngIf="email.isExpired" tooltip='You can change the email expiration date from "Advanced"'>
															Expired
														</span>
														<span style="font-size: 11px; font-family: 'Roboto Condensed', 'Helvetica Neue','Helvetica', 'sans-serif'; color: darkred; opacity:0.5;"
															  *ngIf="email.quarantined_id && !email.start_sending_time">
															Quarantined by Admin
														</span>
														<span style="font-size: 12px;" *ngIf="email.numOfReplies">
															{{email.numOfReplies}}
																<i class="fas fa-reply"></i>
														</span>
													</div>
												</div>
											</td>
										</tr>
										<!--replies-->
										<ng-container *ngIf="email.isExpanded && email.has_replies">
											<ng-container
												*ngFor="let reply of email.replies; trackBy: gs.trackByIndex"
												[ngTemplateOutlet]="emailRecordTemplate"
												[ngTemplateOutletContext]="{$implicit: reply}">
											</ng-container>
										</ng-container>
									</ng-template>
								</ng-container>
								</tbody>
							</table>
						</virtual-scroller>
						<div *ngIf="!myEmailsService.currentFilteredEmailsList.length" class="flxClmn-center"
							 style="align-items: center; flex: 1 0; background: white;">
							<div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 0.7;" *ngIf="getEmailsInProcess"></div>
							<span *ngIf="!getEmailsInProcess && myEmailsService.currentEmailsList.length" style="color: darkgrey; font-size: 18px;">No results for this search and/or filter</span>
							<span *ngIf="!getEmailsInProcess && !myEmailsService.currentEmailsList.length" style="color: darkgrey; font-size: 18px;">No emails for this query</span>
						</div>
					</div>
				</div>
			</div>

			<!--Email display area (right side)-->
			<div class="vertical-separator" style="border-color: #e3e4e4; border-left: none;"></div>
			<div class="flxClmn" style="flex: 2 0; align-items: center; margin-left: 20px; position: relative; overflow: auto;"
				 [ngStyle]="{'background': myEmailsService.currentMessage ? 'transparent' : '#f7f7f7'} ">
				<!--loading overlay-->
				<div class="flxClmn-center animated fadeIn" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 5; background: rgba(255,255,255,0.6); align-items: center;"
					 *ngIf="previewEmailInProcess">
					<div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 1;"></div>
				</div>
				<!----->
				<div class="flxClmn-center vSpace2 w100" style="flex:1 0; overflow: auto;"
					 *ngIf="myEmailsService.currentMessage">
					<!--tabs-->
					<div class="simple-tab-row w100 flxRow" (ngInit)="activeEmailTab = dic.CONSTANTS.mailBoxTabs.details">
						<ng-container *ngFor="let tab of dic.CONSTANTS.mailBoxTabs | keyvalue: gs.returnZero">
							<div class="tab-header"
								 *ngIf="(tab.value === dic.CONSTANTS.mailBoxTabs.details) ||
								 		(tab.value === dic.CONSTANTS.mailBoxTabs.tracking && myEmailsService.currentMessage.methods.track && !myEmailsService.currentMessage.methods.secure_mass && myEmailsService.currentMessage.start_sending_time) ||
								 		(tab.value === dic.CONSTANTS.mailBoxTabs.advanced && myEmailsService.currentMessage.methods.track && !myEmailsService.currentMessage.methods.secure_mass && myEmailsService.currentMessage.start_sending_time) ||
								 		(tab.value === dic.CONSTANTS.mailBoxTabs.postmark && myEmailsService.currentMessage.methods.postmark && !myEmailsService.currentMessage.methods.secure_mass && selectedFilter !== dic.CONSTANTS.mailBoxFilters.Draft) ||
								 		(tab.value === dic.CONSTANTS.mailBoxTabs.summary && myEmailsService.currentMessage.start_sending_time && myEmailsService.currentMessage.source !== 'journal')"
								 [ngClass]="{'chosen' : activeEmailTab === tab.value}"
								 (click)="activeEmailTab = tab.value">
								<span class="hvr-opacity">{{tab.value}}</span>
							</div>
						</ng-container>
					</div>

					<div class="flxClmn" style="flex: 1 0; overflow: auto;">
						<sent-email-details-component *ngIf="activeEmailTab === dic.CONSTANTS.mailBoxTabs.details"
													  (deleteMessages)="deleteMessages($event.messages);"
													  class="flxClmn f-1-0" style="overflow: auto;" ></sent-email-details-component>
						<sent-email-tracking-component *ngIf="activeEmailTab === dic.CONSTANTS.mailBoxTabs.tracking"
													   class="flxClmn" style="flex: 1 0; overflow: auto;"></sent-email-tracking-component>
						<sent-email-advanced-component *ngIf="activeEmailTab === dic.CONSTANTS.mailBoxTabs.advanced"
													   class="flxClmn" style="flex: 1 0; overflow: auto;"></sent-email-advanced-component>
						<sent-email-postmark-component *ngIf="activeEmailTab === dic.CONSTANTS.mailBoxTabs.postmark"
													   class="flxClmn" style="flex: 1 0; overflow: auto;"></sent-email-postmark-component>
						<sent-email-summary-component *ngIf="activeEmailTab === dic.CONSTANTS.mailBoxTabs.summary"
													   class="flxClmn" style="flex: 1 0; overflow: auto;"></sent-email-summary-component>
					</div>
				</div>

				<div *ngIf="!myEmailsService.currentMessage && !previewEmailInProcess" class="flxClmn-center"
					 style="align-items: center; flex: 1 0;">
					<i class="fas hvr-opacity fa-envelope-open-text" style="font-size: 4vw;  opacity: 0.1;"></i>
				</div>
			</div>
		</div>
	</div>
</div>

<!--Update Message popup-->
<popup-c *ngIf="myEmailsService.showEditEmailContent"
	   head="Update Message" size="medium"
	   (closeCb)="myEmailsService.currentMessage.sent.html = myEmailsService.origEmailContent; myEmailsService.showEditEmailContent = false;"
	   (confirmCb)="myEmailsService.updateEmailContent();"
	   [buttons]="{cancel: 'Cancel', confirm: {text: 'Update', disabled: myEmailsService.origEmailContent === myEmailsService.currentMessage.sent.html, loading: myEmailsService.updateEmailContentInProcess}}">
	<div class="flxClmn f-1-0" style="overflow: auto;">
		<div class="f-1-0 w100 summernote-editor" id="summernote"
			 [(ngModel)]="myEmailsService.currentMessage.sent.html"
			 [ngxSummernote]="summernoteOptions">
		</div>
	</div>
</popup-c>
