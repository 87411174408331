import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import {GeneralService} from "../../../../../services/generalService";
import {DICTIONARY} from "../../../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";


@Component({
	selector: 'threat-prevention-component',
	templateUrl: './threat-prevention-rules.component.html',
})
export class ThreatPreventionComponent implements OnInit {
    constructor(public gs:GeneralService,
				private sanitizer:DomSanitizer,
				private rs:RouteService,
				private ns:NotificationService) {
    }

    dic = DICTIONARY;
    corpname = this.gs.corpname;
    enforceKeepRecord: string = '';
	_=_;
    reviewerActions = [
        this.dic.CONSTANTS.reviewerActions.setAsPrimary,
        this.dic.CONSTANTS.reviewerActions.assignGroups,
        this.dic.CONSTANTS.inboundConfigurationsActions.remove
    ];

    releaseRequestSubjectDesc = 'Dynamic fields like {{RECIPIENT_EMAIL}}, {{EMAIL_SUBJECT}} and {{COMPANY_NAME}} can be used to show information about the email.\n' +
        'If this field is left empty, the default Trustifi subject will be used.';
    releaseRequestSubjectPlaceholder = '[Inbound Shield] ({{COMPANY_NAME}}) {{RECIPIENT_EMAIL}} release request for email "{{EMAIL_SUBJECT}}"';

	summernoteConfig = {
        height: 100,
        toolbar: [
            ['style', ['bold', 'italic', 'underline']],
            ['fontsize', ['fontsize']],
            ['view', ['codeview']]
        ],
        codemirror: {
            theme: 'monokai'
        },
		callbacks: {
			onKeydown: (e) => {
				if (e.keyCode !== 8 && e.keyCode !== 127 && document.querySelector(".note-editable").textContent.length >= 500){
					e.preventDefault();
				}
			}
		},
        placeholder: 'HTML/text'
    };

    rulesMainActions = {
        release: 'Release',
        quarantine: 'Quarantine'
    };
    rulesSubActions = {
        recipientControl: DICTIONARY.CONSTANTS.tpRule.action.recipientControl,
        addWarningLabel: DICTIONARY.CONSTANTS.tpRule.action.addWarningLabel,
        addSubjectText: DICTIONARY.CONSTANTS.tpRule.action.addSubjectText,
        notifyReviewer: DICTIONARY.CONSTANTS.tpRule.action.notifyReviewer,
        notifyRecipient: DICTIONARY.CONSTANTS.tpRule.action.notifyRecipient,
        keepRecord: DICTIONARY.CONSTANTS.tpRule.action.keepRecord
    };

    hexInputError;
    userInfo;
    config;
    modifyWarningLabelPopup;
    previewWarningLabelPopup;
    allowReport;
    modifySubjectPopup
    notifyOptionsPopup;
    excludeDomainsEmailsPopup;
    addExcludedEmailActive;
    newCustomizedFooterStr;
    retentionPeriodPopup;
	hoursArr = [ "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
	deleteQuarantinedEmailRecPopup;
	selectedRulePreset;
	mainRulePreset;
	rulePresets = [];
	addSubRulePopup;
	getThreatPreventionRulesInProcess;

	ruleTypesTooltips = {
		[this.dic.CONSTANTS.tpRule.name.malicious]: "<b style='text-transform: capitalize;'>Malicious</b> - Indicates that this email has been sent to you for malicious purposes and/or contains malicious content that may harm you such as phishing, spear-phishing, ransomware, spoofing, viruses, or a zero-day attack",
		[this.dic.CONSTANTS.tpRule.name.suspicious]: "<b style='text-transform: capitalize;'>Suspicious</b> - Indicates that this email is suspected of being incorrectly configured/registered, or that its information/content has not been properly verified (certificate issues, DNS issues, etc.). This kind of email is not necessarily malicious",
		[this.dic.CONSTANTS.tpRule.name.spam]: "<b style='text-transform: capitalize;'>Spam</b> - The sender and/or the message has been classified as spam",
		[this.dic.CONSTANTS.tpRule.name.graymail]: "<b style='text-transform: capitalize;'>Graymail</b> - Marketing emails or newsletter that may be useful for some, but could be considered spam by others",
		[this.dic.CONSTANTS.tpRule.name.external]: "<b style='text-transform: capitalize;'>External</b> - Emails that originate from outside of your organization or trusted network",
	}

	ngOnInit() {
        this.getThreatPreventionRules();

        this.gs.getUserInfo(false,  (userInfo) => {
            this.userInfo = userInfo;
        });
    };

    getThreatPreventionRules = () => {
		this.getThreatPreventionRulesInProcess = true;

        this.rs.getTPRules().then( (response) => {
            this.config = response.config;
			this.getThreatPreventionRulesInProcess = false;
			this.rulePresets = [
				{
					name: this.dic.CONSTANTS.lfMainCustomizationName,
					...response.config.threat_protection.rules
				},
				...response.config.threat_protection.sub_rules
			];
			this.selectedRulePreset = _.find(this.rulePresets, {name: this.selectedRulePreset?.name || ''}) || this.rulePresets[0];
			this.mainRulePreset = _.find(this.rulePresets, {name: this.dic.CONSTANTS.lfMainCustomizationName})

			if (this.config.threat_protection.security_awareness?.smart_banner?.enabled && this.config.threat_protection.security_awareness?.smart_banner?.allow_reporting) {
				this.enforceKeepRecord = 'Quarantine records will be kept for all external/non-safe emails since "Allow reporting" is enabled for smart banners';
			}
			else if (this.config.threat_protection.third_party_report?.microsoft?.mailbox) {
				this.enforceKeepRecord = 'Quarantine records will be kept for all external/non-safe emails since "Report from third-party client" is configured';
			}

			this.checkEnforceKeepRecord(this.rulePresets);
			}, (err) => {
			this.getThreatPreventionRulesInProcess = false;
		});
    }

	openAddSubRulePopup = () => {
		this.addSubRulePopup = {
			name: '',
			error: false,
			addSubRuleInProcess: false,
			show: true
		}
	}

	addSubRule = () => {
		if (!this.addSubRulePopup.name) {
			this.addSubRulePopup.error = true;
			this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emptyField, 'Name'));
			return;
		}

		if (_.map(this.rulePresets, 'name').includes(this.addSubRulePopup.name)) {
			this.addSubRulePopup.error = true;
			this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.XAlreadyExistInX, '"' + this.addSubRulePopup.name + '"', 'rules presets'));
			return;
		}

		const ruleData = {
			type: this.dic.CONSTANTS.rulesTpAction.subRules,
			action: this.dic.CONSTANTS.actions.add,
			name: this.addSubRulePopup.name,
		};

		this.addSubRulePopup.addSubRuleInProcess = true;

		this.rs.updateTpRules('', ruleData).then((response) => {
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);

			this.rulePresets.push(response.sub_rule);
			const newRuleObjInList = _.find(this.rulePresets, {name: this.addSubRulePopup.name});
			this.selectedRulePreset = newRuleObjInList;
			this.addSubRulePopup = null;

			this.checkEnforceKeepRecord([newRuleObjInList]);
		}, (err) => {
			this.addSubRulePopup.addSubRuleInProcess = false;
		});
	}

	// force add keep record to all rules if: 1. this.enforceKeepRecord. OR 2. it's "malicious" rule type. OR 3. the main action is quarantined.
    checkEnforceKeepRecord = (rulesToCheck) => {
		rulesToCheck.forEach(rulesObj => {
			Object.keys(this.dic.CONSTANTS.tpRule.name).forEach(ruleType => {
				if (this.enforceKeepRecord || ruleType === this.dic.CONSTANTS.tpRule.name.malicious || rulesObj[ruleType].actions.includes(this.rulesMainActions.quarantine)) {
					_.union(rulesObj[ruleType].actions, [this.rulesSubActions.keepRecord]);
				}
			});
		});
	};

    hexInputChanged =  () => {
        // validate hex code and if is valid - refresh preview
        const reg = /^(0[xX])?[A-Fa-f0-9]+$/i;
        if (reg.test(this.modifyWarningLabelPopup.color.replace("#",'')) && this.modifyWarningLabelPopup.color.replace("#",'').length === 6) {
            this.hexInputError = false;
        }
        else {
            this.hexInputError = true;
        }
    }

    resetInboundRules = () => {
        this.gs.showPopup({
            title: 'Reset Threat Prevention Rules',
            subTitle: `You are about to reset threat prevention rules to default settings`,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Reset',
            doneCb: () =>{
                const ruleData = {
                    type: this.dic.CONSTANTS.rulesTpAction.resetRules,
                };

				if (this.selectedRulePreset.name !== this.dic.CONSTANTS.lfMainCustomizationName) {
					ruleData['name'] = this.selectedRulePreset.name;
				}
                this.rs.updateTpRules(null, ruleData).then( (response) => {
                    this.getThreatPreventionRules();
                }, err => {
                });
            }
        });
    };

    monitorInboundRules = () => {
        this.gs.showPopup({
            title: 'Threat Prevention Monitor Mode',
            subTitle: `You are about to set the threat prevention rules on "Monitor mode"`,
            body: ['All threat types will be set to "Release" and a record will be kept for all non-internal emails'],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Apply',
            doneCb: () =>{
                const ruleData = {
                    type: this.dic.CONSTANTS.rulesTpAction.monitorRules
                };

				if (this.selectedRulePreset.name !== this.dic.CONSTANTS.lfMainCustomizationName) {
					ruleData['name'] = this.selectedRulePreset.name;
				}
                this.rs.updateTpRules(null, ruleData).then( (response) => {
                    this.getThreatPreventionRules();
                }, err => {
                });
            }
        });
    };

	deleteSubRule = () => {
		this.gs.showPopup({
			title: 'Delete Custom Threat Prevention Rules Preset\n',
			subTitle: ` You are about to delete the custom Threat Prevention Rules Preset: "${this.selectedRulePreset.name}"`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () =>{
				const ruleData = {
					type: this.dic.CONSTANTS.rulesTpAction.subRules,
					action: this.dic.CONSTANTS.actions.delete,
					name: this.selectedRulePreset.name
				};

				this.rs.updateTpRules(null, ruleData).then( (response) => {
					_.remove(this.rulePresets, {name: this.selectedRulePreset.name});
					this.selectedRulePreset = this.rulePresets[0];
				}, err => {
				});
			}
		});
	}

	forceLogicOnRuleMainActions = (actionsArr, actionClicked) => {
		switch (actionClicked) {
			case this.rulesMainActions.release:
				actionsArr = _.without(actionsArr, this.rulesMainActions.quarantine);
				break;

			case this.rulesMainActions.quarantine:
				actionsArr = _.without(actionsArr, this.rulesMainActions.release);
				actionsArr = _.without(actionsArr, this.rulesSubActions.recipientControl);
				actionsArr = _.union(actionsArr, [this.rulesSubActions.keepRecord]); // (_.union => add if not already exists)
				break;
		}

		return actionsArr;
	}

	// update rules main actions and secondary actions
    updateRulesActions = (ruleType, actionClicked) => {
		let originalActionsDataArr = _.clone(this.selectedRulePreset[ruleType].actions);
		let actionsDataArr = _.clone(this.selectedRulePreset[ruleType].actions);

		// main action clicked
		if (_.values(this.rulesMainActions).includes(actionClicked)) {
			if (actionsDataArr.includes(actionClicked)) {
				return;
			}

			actionsDataArr = this.forceLogicOnRuleMainActions(actionsDataArr, actionClicked);
		}

		actionsDataArr = this.gs.toggleItemInArray(actionsDataArr, actionClicked);

		this.selectedRulePreset[ruleType].actions = actionsDataArr;

        const ruleData = {
            type: this.dic.CONSTANTS.rulesTpAction.rule,
            action: 'actions',
            ruleName: ruleType,
            data: {
                actions: actionsDataArr
            }
        };

		if (this.selectedRulePreset.name !== this.dic.CONSTANTS.lfMainCustomizationName) {
			ruleData['name'] = this.selectedRulePreset.name;
		}

        this.rs.updateTpRules(null, ruleData).then( (response) => {
            if (ruleType === this.dic.CONSTANTS.tpRule.name.external && actionClicked === this.rulesMainActions.quarantine &&
				actionsDataArr.includes(this.rulesMainActions.quarantine)) {
                this.ns.showWarnMessage(this.dic.ERRORS.ruleExternalNoReleaseIgnore);
            }

            if (ruleType === this.dic.CONSTANTS.tpRule.name.malicious && actionClicked === this.rulesMainActions.release &&
				actionsDataArr.includes(this.rulesMainActions.release)) {
                this.ns.showWarnMessage(this.dic.ERRORS.ruleMaliciousRelease);
            }

            if (ruleType !== this.dic.CONSTANTS.tpRule.name.malicious && actionClicked === this.rulesSubActions.keepRecord &&
                !actionsDataArr.includes(this.rulesSubActions.keepRecord)) {

				this.deleteQuarantinedEmailRecPopup = {
					show: true,
					applyInProcess: false,
					subTitle: `Do you also want to delete all quarantined email records of type: ${ruleType}?`,
					verifyEmail: '',
					doneCb: () =>{
						this.deleteQuarantinedEmailRecPopup.applyInProcess = true;
						this.rs.deleteQuarantinedEmail(ruleType, {email: this.deleteQuarantinedEmailRecPopup.verifyEmail}).then( () => {
							this.ns.showInfoMessage('Quarantined emails were deleted successfully');
							this.deleteQuarantinedEmailRecPopup = null;
						}, (err) => {
							this.deleteQuarantinedEmailRecPopup.applyInProcess = false;
						})
					}
				};
            }

        }, err => {
			this.selectedRulePreset[ruleType].actions = originalActionsDataArr;
        });
    };

    getColorAndHeaderByType = (ruleType) => {
        if (!ruleType) return;

        let isCustomColor = false;
        let borderColor = '#ff8c00';
        let backgroundColor = '#ff8c00';
        let header;
        switch (ruleType) {
            case this.dic.CONSTANTS.tpRule.name.malicious:
                header = this.dic.CONSTANTS.emailScannedMaliciousHeader;
                borderColor = '#8b0000';
                backgroundColor = '#f08080';
                break;

            case this.dic.CONSTANTS.tpRule.name.suspicious:
                header = this.dic.CONSTANTS.emailScannedSuspiciousHeader;
                break;

            case this.dic.CONSTANTS.tpRule.name.spam:
                header = this.dic.CONSTANTS.emailScannedSpamHeader;
                break;

            case this.dic.CONSTANTS.tpRule.name.graymail:
                header = this.dic.CONSTANTS.emailScannedGraymailHeader;
                break;

            case this.dic.CONSTANTS.tpRule.name.external:
                header = this.dic.CONSTANTS.emailScannedExternalHeader;
                break;
        }

        if (this.selectedRulePreset[ruleType].options && this.selectedRulePreset[ruleType].options.warning_color) {
            backgroundColor = this.selectedRulePreset[ruleType].options && this.selectedRulePreset[ruleType].options.warning_color;
            borderColor = backgroundColor;
        }
        header = this.selectedRulePreset[ruleType].options && this.selectedRulePreset[ruleType].options.warning_text || header;
        return {header, borderColor, backgroundColor, isCustomColor};
    };

    showWarningPreview = (ruleType, tempColor, tempText, tempAllowReport) => {
        if (!ruleType) return;
        const options = this.getColorAndHeaderByType(ruleType);

        this.previewWarningLabelPopup = {
            ruleType,
            borderColor: tempColor || options.borderColor,
            backgroundColor: tempColor || options.backgroundColor,
            header: this.sanitizer.bypassSecurityTrustHtml(tempText) || options.header,
            show: true
        };

        this.allowReport = tempAllowReport || (this.selectedRulePreset[ruleType].options && this.selectedRulePreset[ruleType].options.allow_report && this.config.outbound_relay && this.config.outbound_relay.enabled) || false;
    };

    openModifyWarning = (ruleType) => {
        if (!ruleType) return;
        const options = this.getColorAndHeaderByType(ruleType);

        this.modifyWarningLabelPopup = {
            ruleType,
            color: options.backgroundColor,
            text: options.header,
			modifyWarningInProcess: false,
            show: true
        };

        this.allowReport = (this.selectedRulePreset[ruleType].options && this.selectedRulePreset[ruleType].options.allow_report && this.config.outbound_relay && this.config.outbound_relay.enabled) || false;
    };

    openModifySubject = (ruleType) => {
        if (!ruleType) return;

        this.modifySubjectPopup = {
            ruleType,
            text: (this.selectedRulePreset[ruleType].options && this.selectedRulePreset[ruleType].options.subject_text) || '',
			modifySubjectInProcess: false,
            show: true
        }
    };


    modifyWarning = (isReset) => {
        if (this.hexInputError) {
            this.ns.showWarnMessage('Please fix color input');
            return;
        }

        if ((!isReset && (!this.modifyWarningLabelPopup.text || !this.modifyWarningLabelPopup.color)) ||
            !this.modifyWarningLabelPopup.ruleType || this.modifyWarningLabelPopup.modifyWarningInProcess) {
            return;
        }

        const color = isReset ? '' : '#' + this.modifyWarningLabelPopup.color.replace('#','');
        const text = isReset ? '' : this.modifyWarningLabelPopup.text;
        const allowReport = isReset ? false : (this.allowReport || false);

        const ruleData = {
            type: this.dic.CONSTANTS.rulesTpAction.rule,
            action: 'options',
            ruleName: this.modifyWarningLabelPopup.ruleType,
            data: {
                options: {
                    label_type: 'warning',
                    warning_color: color,
                    warning_text: text,
                    allow_report: allowReport
                }
            }
        };

		if (this.selectedRulePreset.name !== this.dic.CONSTANTS.lfMainCustomizationName) {
			ruleData['name'] = this.selectedRulePreset.name;
		}

        this.modifyWarningLabelPopup.modifyWarningInProcess = true;

        this.rs.updateTpRules('', ruleData).then( (response) => {
            this.selectedRulePreset[this.modifyWarningLabelPopup.ruleType].options.warning_color = color;
            this.selectedRulePreset[this.modifyWarningLabelPopup.ruleType].options.warning_text = text;
            this.selectedRulePreset[this.modifyWarningLabelPopup.ruleType].options.allow_report = allowReport;

            if (allowReport) {
                if (!this.selectedRulePreset[this.modifyWarningLabelPopup.ruleType].actions.includes(this.dic.CONSTANTS.tpRule.action.keepRecord)) {
                    this.selectedRulePreset[this.modifyWarningLabelPopup.ruleType].actions.push(this.dic.CONSTANTS.tpRule.action.keepRecord);
                }
            }

            this.modifyWarningLabelPopup = null;
        }, err => {
            this.modifyWarningLabelPopup.modifyWarningInProcess = false;
        });
    };

    modifySubject = () => {
        if (this.modifySubjectPopup.modifySubjectInProcess) {
            return
        }

        const ruleData = {
            type: this.dic.CONSTANTS.rulesTpAction.rule,
            action: 'options',
            ruleName: this.modifySubjectPopup.ruleType,
            data: {
                options: {
                    label_type: 'subject',
                    subject_text: this.modifySubjectPopup.text
                }
            }
        };

		if (this.selectedRulePreset.name !== this.dic.CONSTANTS.lfMainCustomizationName) {
			ruleData['name'] = this.selectedRulePreset.name;
		}

        this.modifySubjectPopup.modifySubjectInProcess = true;

        this.rs.updateTpRules('', ruleData).then( (response) => {
            this.selectedRulePreset[this.modifySubjectPopup.ruleType].options.subject_text = this.modifySubjectPopup.text;
            this.modifySubjectPopup = null;
        }, err => {
            this.modifySubjectPopup.modifySubjectInProcess = false;
        });
    };

    openNotifyOptions = () => {
        // create model for notifyReviewerPeriods dropdown selection (initial values from this.config)
        const notifyReviewerPeriods = {};
        _.forEach(this.dic.CONSTANTS.tpRule.notifyOptions,(periodName, periodKey) => {
            notifyReviewerPeriods[periodName] = this.mainRulePreset.notify_reviewer_periods.includes(periodName);
        });
		let notifyReviewerDailyHours = [];
		if (this.mainRulePreset.notify_reviewer_daily_hour) {
			notifyReviewerDailyHours = this.mainRulePreset.notify_reviewer_daily_hour.split(', ');
			for (let i = 0; i < notifyReviewerDailyHours.length; i++) {
				let formattedHour:any = new Date();
				formattedHour.setUTCHours(notifyReviewerDailyHours[i]);
				formattedHour = (formattedHour.getHours() < 10 ? '0' : '') + formattedHour.getHours();
				notifyReviewerDailyHours[i] = formattedHour;
			}
		}
        // create model for notifyRecipientPeriods dropdown selection (initial values from this.config)
        const notifyRecipientPeriods = {};
		_.forEach(this.dic.CONSTANTS.tpRule.notifyOptions,(periodName, periodKey) => {
            notifyRecipientPeriods[periodName] = this.mainRulePreset.notify_recipient_periods.includes(periodName);
		});
		let notifyRecipientDailyHours = [];
		if (this.mainRulePreset.notify_recipient_daily_hour) {
			notifyRecipientDailyHours = this.mainRulePreset.notify_recipient_daily_hour.split(', ');
			for (let i = 0; i < notifyRecipientDailyHours.length; i++) {
				let formattedHour:any = new Date();
				formattedHour.setUTCHours(notifyRecipientDailyHours[i]);
				formattedHour = (formattedHour.getHours() < 10 ? '0' : '') + formattedHour.getHours();
				notifyRecipientDailyHours[i] = formattedHour;
			}
		}
        //

        this.notifyOptionsPopup = {
            notifyReviewerPeriods,
			notifyReviewerDailyHours,
            notifyRecipientPeriods,
			notifyRecipientDailyHours,
            notifyRecipientPeriodText: this.mainRulePreset.notify_recipient_period_text,
			notifyRecipientAllowChangeTime: this.mainRulePreset.notify_recipient_allow_change_time,
			notifyRecipientAllowRequestRelease: this.mainRulePreset.notify_recipient_allow_request_release,
			notifyRecipientAllowRequestReleaseFrom: this.mainRulePreset.notify_recipient_allow_request_release_email_change_from,
			notifyRecipientAllowRequestReleaseEmail: this.mainRulePreset.notify_recipient_allow_request_release_email,
            notifyRecipientAllowRequestSubject: this.mainRulePreset.notify_release_request_subject,
            isNotifyReviewerPeriodsError: false,
            isNotifyRecipientPeriodsError: false,
            show: true
        }
    };

    openExcludeDomainsEmailsPopup = () => {
		const excludeDomainsEmails = (this.mainRulePreset.skip_add_warning_banner || []).map(itm => ({value:itm}));
        this.excludeDomainsEmailsPopup = {
            emails: _.cloneDeep(excludeDomainsEmails),
            emailsBefore: excludeDomainsEmails,
            show: true,
        }

    };

    startAddExcludedEmail =  () => {
        if (this.addExcludedEmailActive) {
            return;
        }

        this.addExcludedEmailActive = true;

        const newEmail = {
            isEditMode: true,
            isNew: true,
            edit: {
                value: '',
            }
        };

        this.excludeDomainsEmailsPopup.emails.unshift(newEmail);
    };

    confirmAddExcludedEmail =  (email ,isApproved) => {
        // new email/domain process was declined
        if (!isApproved) {
            _.remove<any>(this.excludeDomainsEmailsPopup.emails, email);
            this.addExcludedEmailActive = false;
            return;
        }
        //

        // new email/domain approved
        if (!email.edit.value) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Email/Domain field'));
        }
        if (!this.gs.validateEmail(email.edit.value) && !this.gs.isValidDomain(email.edit.value)) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.invalidDomainOrAddress, email.edit.value));
        }
        if (_.find(this.excludeDomainsEmailsPopup.emails, e => {return !e.isNew && e.value && e.value === email.edit.value.toLowerCase()})) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailDomainAlreadyExist, email.edit.value));
        }

        email.value = email.edit.value.toLowerCase();

        email.edit = null;
        email.isEditMode = false;
        email.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit
        this.addExcludedEmailActive = false;
    };

    searchExcludedEmail =  (searchTerm, activeFilters) => {
        this.excludeDomainsEmailsPopup.emails.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = record.value && record.value.toLowerCase().includes(searchTerm.toLowerCase());
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    showExcludedEmailActions = () => {
        return [
            this.dic.CONSTANTS.inboundConfigurationsActions.remove
        ]
    }

    selectExcludedEmailAction = (emailOrDomain, action) => {
        switch (action) {
            case this.dic.CONSTANTS.inboundConfigurationsActions.remove:
                _.remove<any>(this.excludeDomainsEmailsPopup.emails, emailOrDomain);
                break;
        }
    };

    changeExcludeDomainsEmails = () => {
		if (this.addExcludedEmailActive) {
            _.remove<any>(this.excludeDomainsEmailsPopup.emails, email => {return email.isNew});
            this.addExcludedEmailActive = false;
        }
        const emailsInCurrentList = _.map(this.excludeDomainsEmailsPopup.emails, 'value');

        const ruleData = {
            type: this.dic.CONSTANTS.rulesTpAction.rule,
            action: 'options',
            data: {
                options: {
                    skip_add_warning_banner: emailsInCurrentList
                }
            }
        };

        this.excludeDomainsEmailsPopup.changeExcludeDomainsEmailsInProcess = true;

        this.rs.updateTpRules('', ruleData).then( (response) => {
			this.mainRulePreset.skip_add_warning_banner = emailsInCurrentList;

            this.excludeDomainsEmailsPopup.changeExcludeDomainsEmailsInProcess = false;
            this.excludeDomainsEmailsPopup = null;
        });
    };

    changeNotifyPeriod = () => {
        if (this.notifyOptionsPopup.changeNotifyPeriodInProcess) {
            return;
        }

		// periods validation
        const isOneReviewerPeriodEnabled = _.find(this.notifyOptionsPopup.notifyReviewerPeriods,period => {return !!period;});
        const isOneRecipientPeriodEnabled = _.find(this.notifyOptionsPopup.notifyRecipientPeriods,period => {return !!period;});
        if (!isOneReviewerPeriodEnabled) {
            this.notifyOptionsPopup.isNotifyReviewerPeriodsError = true;
        }
        if (!isOneRecipientPeriodEnabled) {
            this.notifyOptionsPopup.isNotifyRecipientPeriodsError = true;
        }
		this.notifyOptionsPopup.isNotifyReviewerDailyHoursError = this.notifyOptionsPopup.notifyReviewerPeriods[this.dic.CONSTANTS.tpRule.notifyOptions.everyDay] && !this.notifyOptionsPopup.notifyReviewerDailyHours.length;
		this.notifyOptionsPopup.isNotifyRecipientDailyHoursError = this.notifyOptionsPopup.notifyRecipientPeriods[this.dic.CONSTANTS.tpRule.notifyOptions.everyDay] && !this.notifyOptionsPopup.notifyRecipientDailyHours.length;

        if (!isOneRecipientPeriodEnabled || !isOneReviewerPeriodEnabled || this.notifyOptionsPopup.isNotifyReviewerDailyHoursError || this.notifyOptionsPopup.isNotifyRecipientDailyHoursError) {
            return;
        }
        if (this.notifyOptionsPopup.notifyRecipientAllowRequestSubject && this.notifyOptionsPopup.notifyRecipientAllowRequestSubject.length > 200) {
            this.ns.showErrorMessage('Email title must be under 200 characters');
            return;
        }
        //

        // make arrays of strings from the periods objects (pick only enabled periods)
        const notifyReviewerPeriodsArr = _.keys(_.pickBy(this.notifyOptionsPopup.notifyReviewerPeriods));
        const notifyRecipientPeriodsArr = _.keys(_.pickBy(this.notifyOptionsPopup.notifyRecipientPeriods));
        //
		// turn the local hours in the "daily_hour" models into UTC hours for the BE
		let notifyReviewerDailyHoursUTC = _.cloneDeep(this.notifyOptionsPopup.notifyReviewerDailyHours);
		for (let i =0 ; i < notifyReviewerDailyHoursUTC.length; i++) {
			let formattedHour:any = new Date();
			formattedHour.setHours(notifyReviewerDailyHoursUTC[i]);
			formattedHour = (formattedHour.getUTCHours() < 10 ? '0' : '') + formattedHour.getUTCHours();
			notifyReviewerDailyHoursUTC[i] = formattedHour;
		}

		let notifyRecipientDailyHoursUTC = _.cloneDeep(this.notifyOptionsPopup.notifyRecipientDailyHours);
		for (let i =0 ; i < notifyRecipientDailyHoursUTC.length; i++) {
			let formattedHour:any = new Date();
			formattedHour.setHours(notifyRecipientDailyHoursUTC[i]);
			formattedHour = (formattedHour.getUTCHours() < 10 ? '0' : '') + formattedHour.getUTCHours();
			notifyRecipientDailyHoursUTC[i] = formattedHour;
		}
		//

        const ruleData = {
            type: this.dic.CONSTANTS.rulesTpAction.rule,
            action: 'options',
            data: {
                options: {
                    notify_reviewer_periods: notifyReviewerPeriodsArr,
					notify_reviewer_daily_hour: notifyReviewerDailyHoursUTC.join(', '),
                    notify_recipient_periods: notifyRecipientPeriodsArr,
					notify_recipient_daily_hour: notifyRecipientDailyHoursUTC.join(', '),
                    notify_recipient_period_text: this.notifyOptionsPopup.notifyRecipientPeriodText,
                    notify_recipient_allow_request_release: this.notifyOptionsPopup.notifyRecipientAllowRequestRelease,
                    notify_recipient_allow_request_release_email: this.notifyOptionsPopup.notifyRecipientAllowRequestReleaseEmail,
					notify_recipient_allow_request_release_email_change_from: this.notifyOptionsPopup.notifyRecipientAllowRequestReleaseFrom,
					notify_recipient_allow_change_time: this.notifyOptionsPopup.notifyRecipientAllowChangeTime,
					notify_release_request_subject: this.notifyOptionsPopup.notifyRecipientAllowRequestSubject
                }
            }
        };

        this.notifyOptionsPopup.changeNotifyPeriodInProcess = true;

        this.rs.updateTpRules('', ruleData).then( (response) => {
            this.mainRulePreset.notify_reviewer_periods = notifyReviewerPeriodsArr;
            this.mainRulePreset.notify_reviewer_daily_hour = notifyReviewerDailyHoursUTC.join(', ');
            this.mainRulePreset.notify_recipient_periods = notifyRecipientPeriodsArr;
            this.mainRulePreset.notify_recipient_daily_hour = notifyRecipientDailyHoursUTC.join(', ');
            this.mainRulePreset.notify_recipient_period_text = this.notifyOptionsPopup.notifyRecipientPeriodText;
            this.mainRulePreset.notify_recipient_allow_request_release = this.notifyOptionsPopup.notifyRecipientAllowRequestRelease;
            this.mainRulePreset.notify_recipient_allow_request_release_email = this.notifyOptionsPopup.notifyRecipientAllowRequestReleaseEmail;
			this.mainRulePreset.notify_recipient_allow_request_release_email_change_from = this.notifyOptionsPopup.notifyRecipientAllowRequestReleaseFrom;
			this.mainRulePreset.notify_recipient_allow_change_time = this.notifyOptionsPopup.notifyRecipientAllowChangeTime;
			this.mainRulePreset.notify_release_request_subject = this.notifyOptionsPopup.notifyRecipientAllowRequestSubject;
            this.notifyOptionsPopup = null;
        }, (err) => {
            if (err && err.data && err.data.message) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.notifyOptionsPopup.changeNotifyPeriodInProcess = false;
        });
    };

	toggleNotificationDailyHour = (listOfHours, targetHour) => {
		if (listOfHours.includes(targetHour)) {
			_.remove(listOfHours, hour => hour === targetHour);
		}
		else {
			listOfHours.push(targetHour);
		}
	}

	getDailyHoursText = (listOfHours) => {
		return _.map(listOfHours, hourStr => hourStr + ':00');
	}

    updateFooterStatus = () => {
        const tpData = {
            type: this.dic.CONSTANTS.rulesTpAction.footer,
            action: 'status',
            enabled: this.config.threat_protection.add_footer,
        };

        this.rs.updateTpRules('', tpData).then(() => {});
    };

    updateContactImpersonationSuspicious = () => {
		const tpData = {
            type: this.dic.CONSTANTS.rulesTpAction.contactImpersonation,
            enabled: this.mainRulePreset.contact_impersonation_suspicious,
        };

        this.rs.updateTpRules('', tpData).then(() => {});
    };

	updateSendUserReportsToReviewers = () => {
		const tpData = {
			type: this.dic.CONSTANTS.rulesTpAction.userReportsReviewers,
			enabled: this.mainRulePreset.send_user_reports_to_reviewers,
		};

		this.rs.updateTpRules('', tpData).then(() => {});
	};

    updateFooterContent = () => {
        if (!this.config.threat_protection.add_footer) {
            return;
        }

        const tpData = {
            type: this.dic.CONSTANTS.rulesTpAction.footer,
            action: 'customization',
            customization_footer: this.newCustomizedFooterStr,
        };

        this.rs.updateTpRules('', tpData).then(() => {
            this.config.threat_protection.customization_footer = this.newCustomizedFooterStr;
        });
    };

    openRetentionPeriodPopup = (ruleType) => {
        this.retentionPeriodPopup = {
            type: ruleType,
            newRetentionPeriod: this.mainRulePreset[ruleType].retention_period,
            show: true,
			retentionPeriodInProcess: false,
            error: ''
        }
    }

    changeRetentionPeriod = (period) => {
        if (!period || period < 1 || period > 365) {
            this.ns.showErrorMessage(this.dic.ERRORS.retentionPeriodRange);
            return;
        }

        const ruleData = {
            type: this.dic.CONSTANTS.rulesTpAction.rule,
            action: 'retentionPeriod',
            ruleName: this.retentionPeriodPopup.type,
            data: {
                period: period
            }
        };

		this.retentionPeriodPopup.retentionPeriodInProcess = true;

        this.rs.updateTpRules('', ruleData).then( (response) => {
            this.mainRulePreset[this.retentionPeriodPopup.type].retention_period = period;
			this.retentionPeriodPopup.retentionPeriodInProcess = false;
			this.retentionPeriodPopup.show = false;
        }, (err) => {
            this.ns.showErrorMessage(err.data.message);
			this.retentionPeriodPopup.retentionPeriodInProcess = false;

		});
    };

}

