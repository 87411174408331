<div>
    <div class="tab-content-loader-container" *ngIf="getTPConfigInProcess && !block_files">
        <loader height="100" width="100" style="opacity: 0.8;"></loader>
    </div>
    <div class="flex-column gap-4 animated fadeIn" *ngIf="!getTPConfigInProcess && block_files">
        <div class="flex-column gap-2">
            <div>
                <div>"Block file types" settings will not apply to Allowlisted senders unless "Block malicious links and files" is selected under the allowlist advanced settings.</div>
                <div>"Blocked file types" can be allowed for certain Allowlisted senders in the "Advanced" section under "Actions" for senders in <a class="blue-link no-underline" [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.configuration, dic.CONSTANTS.inboundConfigurationPageTabs.senderLists]"> Sender Lists page</a>.</div>
            </div>
        </div>

        <div class="list-group list-group-flush settings-list-container">
            <!--Settings: Block Macros -->
            <div class="list-group-item settings-list-item animated fadeIn">
                <div class="title-section-container flex-column gap-2">
                    <b>Block Macros</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;"><b>Macros</b> - scripts which have been embedded in document files.<br/>(for example .docm or .xlsm files)</span>
                </div>
                <div class="content-container flex-column gap-2">
                    <toggle-switch-c id="blockMacrosToggle"
                                   [(model)]="block_files.block_macros.enabled"
                                   (onChange)="updateBlockFileTypeStatus('block_macros', 'macros')"></toggle-switch-c>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_macros.enabled}">
                        <input id="block_macros_only_external" type="checkbox"
							   [(ngModel)]="block_files.block_macros.only_external"
                               (ngModelChange)="updateBlockFileTypeStatus('block_macros', 'only_external')"/>
                        <label for="block_macros_only_external">For external sender only</label>
                    </div>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_macros.enabled}">
                        <input id="block_macros_only_contact" type="checkbox"
							   [(ngModel)]="block_files.block_macros.only_untrusted_contacts"
                               (ngModelChange)="updateBlockFileTypeStatus('block_macros', 'only_untrusted_contacts')"/>
                        <label for="block_macros_only_contact">For non-contacts only</label>
                    </div>
                </div>
            </div>

            <!--Settings: Block Scripts -->
            <div class="list-group-item settings-list-item animated fadeIn">
                <div class="title-section-container flex-column gap-2">
                    <b>Block Scripts</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;"><b>Scripts</b> - files written in a specific scripting language which can be run by certain programs.<br/>(for example .sh or .py files)</span>
                </div>
                <div class="content-container flex-column gap-2">
                    <toggle-switch-c id="blockScriptsToggle"
                                   [(model)]="block_files.block_scripts.enabled"
                                   (onChange)="updateBlockFileTypeStatus('block_scripts', 'scripts')"></toggle-switch-c>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_scripts.enabled}">
                        <input id="block_scripts_only_external" required="false" type="checkbox"
							   [(ngModel)]="block_files.block_scripts.only_external"
							   (ngModelChange)="updateBlockFileTypeStatus('block_scripts', 'only_external')"/>
                        <label for="block_scripts_only_external">For external sender only</label>
                    </div>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_scripts.enabled}">
                        <input id="block_scripts_only_contact" type="checkbox"
							   [(ngModel)]="block_files.block_scripts.only_untrusted_contacts"
							   (ngModelChange)="updateBlockFileTypeStatus('block_scripts', 'only_untrusted_contacts')"/>
                        <label for="block_scripts_only_contact">For non-contacts only</label>
                    </div>
                </div>
            </div>

            <!--Settings: Block Executables -->
            <div class="list-group-item settings-list-item animated fadeIn">
                <div class="title-section-container flex-column gap-2">
                    <b>Block Executables</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;"><b>Executables </b> - files that run programs when opened.<br/>(most commonly .exe files in Windows)</span>
                </div>
                <div class="content-container flex-column gap-2">
                    <toggle-switch-c id="blockExecutablesToggle"
                                   [(model)]="block_files.block_executables.enabled"
                                   (onChange)="updateBlockFileTypeStatus('block_executables', 'executables')"></toggle-switch-c>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_executables.enabled}">
                        <input id="block_executables_only_external" required="false" type="checkbox"
							   [(ngModel)]="block_files.block_executables.only_external"
							   (ngModelChange)="updateBlockFileTypeStatus('block_executables', 'only_external')"/>
                        <label for="block_executables_only_external">For external sender only</label>
                    </div>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_executables.enabled}">
                        <input id="block_executables_only_contact" type="checkbox"
							   [(ngModel)]="block_files.block_executables.only_untrusted_contacts"
							   (ngModelChange)="updateBlockFileTypeStatus('block_executables', 'only_untrusted_contacts')"/>
                        <label for="block_executables_only_contact">For non-contacts only</label>
                    </div>
                </div>
            </div>

            <!--Settings: Block Encrypted Attachments -->
            <div class="list-group-item settings-list-item animated fadeIn">
                <div class="title-section-container flex-column gap-2">
                    <b>Block Encrypted Attachments</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;"><b>Encrypted Attachments </b> - any kind of password-protected file that is not an archive.<br/>(for example .pdf files)</span>
                </div>
                <div class="content-container flex-column gap-2">
                    <toggle-switch-c id="blockEncryptedAttachmentsToggle"
                                   [(model)]="block_files.block_encrypted.enabled"
                                   (onChange)="updateBlockFileTypeStatus('block_encrypted', 'encrypted attachments')"></toggle-switch-c>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_encrypted.enabled}">
                        <input id="block_encrypted_only_external" required="false" type="checkbox"
							   [(ngModel)]="block_files.block_encrypted.only_external"
							   (ngModelChange)="updateBlockFileTypeStatus('block_encrypted', 'only_external')"/>
                        <label for="block_encrypted_only_external">For external sender only</label>
                    </div>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_encrypted.enabled}">
                        <input id="block_encrypted_only_contact" type="checkbox"
							   [(ngModel)]="block_files.block_encrypted.only_untrusted_contacts"
							   (ngModelChange)="updateBlockFileTypeStatus('block_encrypted', 'only_untrusted_contacts')"/>
                        <label for="block_encrypted_only_contact">For non-contacts only</label>
                    </div>
                </div>
            </div>

            <!--Settings: Block Encrypted Archives -->
            <div class="list-group-item settings-list-item animated fadeIn">
                <div class="title-section-container flex-column gap-2">
                    <b>Block Encrypted Archives</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;"><b>Encrypted Attachments </b> - archive files that are password-protected.<br/>(for example .zip files)</span>
                </div>
                <div class="content-container flex-column gap-2">
                    <toggle-switch-c id="blockEncryptedArchivesToggle"
                                   [(model)]="block_files.block_encrypted_archives.enabled"
                                   (onChange)="updateBlockFileTypeStatus('block_encrypted_archives', 'encrypted archives')"></toggle-switch-c>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_encrypted_archives.enabled}">
                        <input id="block_encrypted_archives_only_external" required="false" type="checkbox"
							   [(ngModel)]="block_files.block_encrypted_archives.only_external"
							   (ngModelChange)="updateBlockFileTypeStatus('block_encrypted_archives', 'only_external')"/>
                        <label for="block_encrypted_archives_only_external">For external sender only</label>
                    </div>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_encrypted_archives.enabled}">
                        <input id="block_encrypted_archives_only_contact" type="checkbox"
							   [(ngModel)]="block_files.block_encrypted_archives.only_untrusted_contacts"
							   (ngModelChange)="updateBlockFileTypeStatus('block_encrypted_archives', 'only_untrusted_contacts')"/>
                        <label for="block_encrypted_archives_only_contact">For non-contacts only</label>
                    </div>
                </div>
            </div>

            <!--Settings: Block Uncommon File Extensions -->
            <div class="list-group-item settings-list-item border-bottom-0 animated fadeIn">
                <div class="title-section-container flex-column gap-2">
                    <b>Block Uncommon File Extensions</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;">
                        Uncommon or suspicious file types may contain malware or exploits.<br/>
                        Enable this toggle to automatically block such file types, specific file types can be unblocked individually.
                    </span>
                </div>
                <div class="content-container flex-column gap-2">
                    <toggle-switch-c id="blockUncommonFileExtensionsToggle"
                                   [(model)]="block_files.block_uncommon_file_extensions.enabled"
                                   (onChange)="updateBlockFileTypeStatus('block_uncommon_file_extensions', 'uncommon file extensions')"></toggle-switch-c>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_uncommon_file_extensions.enabled}">
                        <input id="block_uncommon_file_extensions_only_external" required="false" type="checkbox"
							   [(ngModel)]="block_files.block_uncommon_file_extensions.only_external"
							   (ngModelChange)="updateBlockFileTypeStatus('block_uncommon_file_extensions', 'only_external')"/>
                        <label for="block_uncommon_file_extensions_only_external">For external sender only</label>
                    </div>
                    <div class="flxRow-start md-checkbox"
                         [ngClass]="{'disabled': !block_files.block_uncommon_file_extensions.enabled}">
                        <input id="block_uncommon_file_extensions_archives_only_contact" type="checkbox"
							   [(ngModel)]="block_files.block_uncommon_file_extensions.only_untrusted_contacts"
							   (ngModelChange)="updateBlockFileTypeStatus('block_uncommon_file_extensions', 'only_untrusted_contacts')"/>
                        <label for="block_uncommon_file_extensions_archives_only_contact">For non-contacts only</label>
                    </div>
                </div>
            </div>

            <div class="list-group-item settings-list-item pt-0 animated fadeIn">
                <!--extensions table-->
                <trustifi-table-c
                        class="trustifi-table" style="z-index: 5; height: max(40vh,330px);"
                        [ngStyle]="!block_files.block_uncommon_file_extensions.enabled && {'opacity': 0.5, 'pointer-events' : 'none'}"
                        [list]="block_files?.uncommon_file_extensions"
                        [headers]="[
							{text: 'Extension', sortKey: 'extension'},
							{text: 'Description', sortKey: 'description'},
							{text: 'Times received', sortKey: 'counter', width: '150px' },
							{text: 'Status', sortKey: 'block', width: '100px' },
						]"
                        [cellsPrototype]="[
							{html: 'extensionNameCell', edit: {modelKey: 'extension', placeholder:' Extension'}},
							{textKey: 'description', edit: {modelKey: 'description', placeholder:' Description'}},
							{textKey: 'counter'},
							{html: 'extensionStatusCell', overflowOutside: true, edit: {html: 'extensionStatusCellEdit'}},
						]"
                        [bulkActions] = "{
							enable: true,
							selectBulkActionCb:	selectMultipleExtensionAction,
							initBulkActionsFunction: showExtensionBulkActions,
							showInRed: {enable: true, terms: ['Remove']}
						}"
                        [cellActions] = "{
							enable: true,
							isSmaller: true,
							selectItemActionCb:	selectExtensionAction,
							initActionsFunction: showExtensionActions,
							showInRed: {enable: true, terms: ['Remove']}
						}"
                        (searchFunction)="searchExtension($event.searchTerm, $event.activeFilters)"
                        (confirmEditFunction)="confirmNewUncommonType($event.item, $event.isApproved)"
                        [options]="{
							itemsNameSingular: 'extension',
							defaultSortKey: '-extension',
							isShortRows: true,
							showCounter: true
						}"
                >
                    <!--extension name cells-->
					<ng-template #customCells let-item let-cellId="cellId">
						<!--action cells-->
						<div *ngIf="cellId === 'extensionNameCell'">
							<span>{{item.extension}}</span>
							<span style="opacity: 0.7;">{{item.unknown ? ' (Unknown File Type)' : ''}}</span>
						</div>
						<!--extension status cells-->
						<div *ngIf="cellId === 'extensionStatusCell'">
							<span [ngStyle]="{'color' : item.block ? 'darkred' : 'darkgreen'}">{{item.block ? 'Blocked' : 'Allowed'}}</span>
						</div>
						<!--extension status cells - EDIT-->
						<div class="w100 h100" *ngIf="cellId === 'extensionStatusCellEdit'">
							<app-dropdown-c class="w100 h100" [text]="item.edit.block ? 'Blocked' : 'Allowed'">
								<li (click)="item.edit.block = true">
									Blocked
								</li>
								<li (click)="item.edit.block = false">
									Allowed
								</li>
							</app-dropdown-c>
						</div>
					</ng-template>
                    <div tableButtons>
                        <div class="flxRow hSpace05">
                            <btn-c endIcon="fa fa-plus"
                                 [isDisabled]="isAddRecordActive"
                                 (action)="startAddRecordNewExtension();">
                                Add Extension
                            </btn-c>
                        </div>
                    </div>
                </trustifi-table-c>
            </div>
        </div>
    </div>
</div>

<popup-c class="bs-enabled" *ngIf="updateFileDescPopup?.show"
		 head="Update Extension Description" size="dynamic"
		 (closeCb)="updateFileDescPopup.show = false;"
		 (confirmCb)="updateFileDescPopup?.doneCb()"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Apply'}}">
	<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
		<div class="align-items-center flex-row gap-3">
			<div>Description:</div>
			<input type="text" placeholder="Enter description"
				   style="width:330px;"
				   [(ngModel)]="updateFileDescPopup.desc"/>
		</div>
	</div>

</popup-c>
