import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {DICTIONARY} from "../dictionary";
import {GeneralService} from "../services/generalService";
import {IAngularMyDpOptions} from "angular-mydatepicker";

@Component({
	selector: 'date-picker-c',
	template: '<div class="date-picker-container" (click)="dp.toggleCalendar()">\n' +
		'         <input type="text" placeholder="N/A" class="date-picker-input"\n' +
		'                style="width: 100%; margin: 0;"\n' +
		'                angular-mydatepicker\n' +
		'                onkeydown="return false"\n' +
		'                [ngModel]="model" [options]="datePickerOptions"\n' +
		'                [readonly]="true" \n' +
		'                (ngModelChange)="modelChanged($event)"\n' +
		'                #dp="angular-mydatepicker"/>\n' +
		'          <i class="fa fa-chevron-down"></i>\n' +
		'      </div>'
})
export class DatePickerComponent implements OnInit, OnChanges {

	@Input() dateModel;
	@Output() dateModelChange = new EventEmitter<any>;

	@Input() isRange = false;
	@Input() allowFuture = false;
	@Input() dateFormat:string;
	@Input() dateMinLimit;
	@Input() dateMaxLimit;

	constructor(public gs:GeneralService) {
	}

	dic = DICTIONARY;
	model;
	datePickerOptions:Partial<IAngularMyDpOptions>;

	ngOnChanges(changes: SimpleChanges) {
		this.updateDatePickerOptions();
	}

	ngOnInit() {
		this.updateDatePickerOptions();
	}

	updateDatePickerOptions() {
		let disableDateRanges = [];

		if (this.dateMinLimit) {
			const minLimitDate = new Date(this.dateMinLimit);
			minLimitDate.setDate(minLimitDate.getDate() - 1);
			disableDateRanges.push({
				begin: { year: minLimitDate.getFullYear() - 1000, month: 1, day: 1 },
				end: { year: minLimitDate.getFullYear(), month: minLimitDate.getMonth() + 1, day: minLimitDate.getDate() }
			});
		}

		if (this.dateMaxLimit) {
			const maxLimitDate = new Date(this.dateMaxLimit);
			maxLimitDate.setDate(maxLimitDate.getDate() + 1);
			disableDateRanges.push({
				begin: { year: maxLimitDate.getFullYear(), month: maxLimitDate.getMonth() + 1, day: maxLimitDate.getDate() },
				end: { year: (new Date()).getFullYear() + 1000, month: 12, day: 31 }
			});
		}

		this.datePickerOptions = {
			dateFormat: this.dateFormat || 'mm/dd/yyyy',
			dateRange: this.isRange,
			disableDateRanges
		};

		if (!this.allowFuture) {
			this.datePickerOptions.disableSince = { year: (new Date()).getFullYear(), month: (new Date()).getMonth() + 1, day: (new Date()).getDate() + 1 };
		}

		if (this.isRange) {
			this.dateModel.start = this.dateModel.start || new Date();
			this.dateModel.end = this.dateModel.end || new Date();
		}

		this.model = {
			isRange: this.isRange,
			singleDate: this.isRange ? null : { jsDate: new Date(this.dateModel) },
			dateRange: !this.isRange ? null : {
				beginJsDate: this.dateModel.start,
				endJsDate: this.dateModel.end
			}
		};
	}

	modelChanged = (dateObj) => {
		if (this.isRange) {
			this.dateModelChange.emit({start: dateObj.dateRange.beginJsDate, end: dateObj.dateRange.endJsDate});
		}
		else {
			this.dateModelChange.emit(dateObj.singleDate.jsDate);
		}
	};
}
