import _ from 'lodash';
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DICTIONARY} from "../../dictionary";
import {GeneralService} from "../../services/generalService";

@Component({
	selector: 'contact-address-book-c',
	templateUrl: './contact-address-book.component.html',
})
export class ContactAddressBookComponent implements OnInit {
	@Input() data;
	@Input() allContacts;
	@Input() contacts;
	@Output() addCb = new EventEmitter<any>;
	@Output() removeCb = new EventEmitter<any>;
	@Output() closeCb = new EventEmitter<any>;

	constructor(public gs:GeneralService) {
	}

	ngOnInit() {
		this.allContactsDisplay = this.allContacts;
		this.allContactsDisplay.forEach(contact => {
			contact.hide = false;
			contact.selected = false;
		});

		// mark existing contacts
		this.contacts.forEach(itm => {
			this.defaultContactsDict[itm._id || itm.email] = true
			let contactObj = this.allContacts.find(contact => contact.email === itm.email);
			if (contactObj) {
				contactObj.selected = true;
			}
		});
	}

	dic = DICTIONARY;
	defaultContactsDict = [];
	_ = _;
	allContactsDisplay;

	searchContacts = (searchTerm, activeFilters) => {
		this.allContactsDisplay.forEach(contact => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(contact, searchTerm);
				if (!isFound) {
					contact.hide = true;
					return;
				}
			}

			// filter
			// // currently no filters for cases table


			contact.hide = false;
		});
	};

	closeModal = () => {
		this.data.openContactsAddressBook = false;
		this.data.openContactsAddressBookForCC = false;
		this.data.openContactsAddressBookForBCC = false;
		if (this.closeCb.observed) {
			this.closeCb.emit();
		}
	};

	addressBookDone = () => {
		if (this.allContactsDisplay && this.contacts) {

			let contactsToAdd = [];
			let contactsToRemove = [];

			this.allContactsDisplay.forEach(itm => {
				if (itm.selected && !this.contacts.some(contact => contact.email === itm.email)) {
					contactsToAdd.push(itm);
				} else {
					if (!itm.selected && this.contacts.some(contact => contact.email === itm.email)) {
						contactsToRemove.push(itm);
					}
				}
			});

			if (this.addCb) {
				this.addCb.emit({contacts: contactsToAdd});
			}

			if (this.removeCb) {
				this.removeCb.emit({contacts: contactsToRemove});
			}
		}

		this.data.openContactsAddressBook = false;
		this.data.openContactsAddressBookForCC = false;
		this.data.openContactsAddressBookForBCC = false;
	};

	deselectContact = (contactObj) => {
		contactObj.selected = false;

		// trigger the trustifi-table search by changing the list. The directive detects changes and fires a search
		// needed to update the "select all" checkbox state
		this.allContactsDisplay = _.cloneDeep(this.allContactsDisplay);
	}
}

function searchTextExecute(contactObj, searchTerm) {
	return (contactObj.email && contactObj.email.toLowerCase().indexOf(searchTerm) > -1);
}
