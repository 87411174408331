import {Component} from "@angular/core";
import {TabsPageContainer} from "../../uiComponents/tabsPageContainer";

@Component({
	selector: 'inbound-configuration-tabs',
	templateUrl: './threat-simulation-campaigns-tabs.component.html',
})
export class ThreatSimulationCampaignsTabsComponent extends TabsPageContainer {
	tabWidth = 170;
	showComingSoonModal = false;
}
