<div class="flxClmn  animated fadeIn" style="flex: 1 0; overflow: auto; padding: 0 15px;">

    <span class="title" style="padding: 10px; height: 57px; flex-shrink: 0; margin: 0; font-size: 1.5rem; color: dimgrey;">Headers</span>

    <hr style="margin-top: 0;">

    <div class="flxClmn vSpace1 animated fadeIn" style="flex: 1 0; overflow: auto;">
        <!--headers list-->
        <trustifi-table-c
                class="trustifi-table" style="flex: 1 0; z-index: 5; background: white;"
                [list]="headersList"
                [headers]="[
                            {text: 'Header', width: '350px'},
                            {text: 'Value'},
                         ]"
                [cellsPrototype]="[
                                    {textKey: 'key'},
                                    {html: 'valueCell'},
                                 ]"
                (searchFunction)="searchHeader($event.searchTerm, $event.activeFilters)"
                [options]="{
                    exportToCsvFunction: exportListToCsv,
                    itemsNameSingular: 'header',
                    isEllipsis: false
                }"
        >
            <!--list status cells-->
			<ng-template #customCells let-item let-cellId="cellId">
				<!--outbound license cell-->
				<div *ngIf="cellId === 'valueCell'">
					<span style="word-break: break-word; padding-right: 1vw; display: block;">{{item.line.substring(item.line.indexOf(':') + 2)}}</span>
				</div>
			</ng-template>
            <!---->
        </trustifi-table-c>
</div>


</div>
