import {Component, Input, OnInit, ViewChild} from '@angular/core';
import _ from "lodash";
import {DICTIONARY} from "../../dictionary";
import {RouteService} from "../../services/routeService";
import {GeneralService} from "../../services/generalService";
import {NotificationService} from "../../services/notificationService";
import {ClipboardService} from "ngx-clipboard";
import {LookAndFeelService} from "../../services/lookAndFeelService";
import {ChartComponent} from "ng-apexcharts";

@Component({
	selector: 'outbound-rule-usage-c',
	templateUrl: './outbound-rule-usage.component.html',
})
export class OutboundRuleUsageComponent implements OnInit {

	@Input() data: any;

	constructor(private clipboard: ClipboardService,
				private rs:RouteService,
				public gs:GeneralService,
				public lfs:LookAndFeelService,
				private ns:NotificationService) { }

	@ViewChild('emailsDetectedChart', {static: false}) emailsDetectedChart: ChartComponent;
	@ViewChild('topSendersChart', {static: false}) topSendersChart: ChartComponent;
	@ViewChild('topSensitivityChart', {static: false}) topSensitivityChart: ChartComponent;


	ngOnInit(): void {
		this.getRuleUsage(this.data.ruleObj, this.data.period);
		this.scopeFunctions = {
			openSensitiveInfoPopup: this.openSensitiveInfoPopup,
			copyEmailIdToClipboard: this.copyEmailIdToClipboard
		};
	}

	dic = DICTIONARY;
	usagePeriod = DICTIONARY.CONSTANTS.trendsPeriod.lastWeek;
	viewTypes = {chart: 'Chart', list: 'List'};
	viewType = this.viewTypes.chart;
	getRuleUsageInProcess;
	ruleUsage;
	emails;
	sensitivityGraphOptions;
	senderGraphOptions;
	emailDetectedByRuleChartOptions;
	ruleEmailIdCopied;
	showSensitiveInfoPopup;
	scopeFunctions;

	trendsPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
	];

	closeModal = () => {
		this.data.show = false;
	};

	getRuleUsage = (ruleObj, period = null) => {
		this.getRuleUsageInProcess = true;
		const ruleAction:any = {
			action: this.dic.CONSTANTS.rules.actions.usage,
			period: this.usagePeriod.value,
			size: this.dic.CONSTANTS.quarantinedEmailsFirstBatchSize,
		};

		this.rs.updateRule(ruleObj._id, ruleAction).then(response => {
			this.emails = response?.emails || [];

			this.prepareGraphInfo(false);
			this.getNextEmailsBatch(ruleObj, response, () => {
				this.getRuleUsageInProcess = false;
			});
		}, (err) => {
			this.getRuleUsageInProcess = false;
		});
	};

	getNextEmailsBatch = (ruleObj, quarantineInfo, cb) => {
		if (!quarantineInfo.lastCreated) {
			return cb();
		}

		const ruleData = {
			action: this.dic.CONSTANTS.inboundEmailRule.actions.usage,
			period: this.usagePeriod.value,
			size: this.dic.CONSTANTS.quarantinedEmailsBatchSize,
			lastCreated: quarantineInfo.lastCreated
		};

		this.rs.updateRule(ruleObj._id.toString(), ruleData).then(response => {
			if (response.emails?.length) {
				this.emails = this.emails.concat(response.emails);
				this.prepareGraphInfo(true);
			}

			this.getNextEmailsBatch(ruleObj, response, cb);
		});
	}


	prepareGraphInfo = (isUpdate) => {
		this.getEmailCountChartInfo(this.data.ruleObj, this.emails, isUpdate);
		this.getEmailSenderChartInfo(this.emails, isUpdate);
		this.getEmailSensitivityChartInfo(this.emails, isUpdate);
	}

	getEmailSensitivityChartInfo = (emails, isUpdate) => {
		const sensitivityMap = {};
		emails?.forEach((emailObj) => {
			const sensitiveType = emailObj.sensitivity.sensitive_types?.length ?
				emailObj.sensitivity.sensitive_types[0].sensitive_type : emailObj.sensitivity.sensitive_sub_type;

			if (!sensitiveType) {
				return;
			}
			if (!sensitivityMap[sensitiveType]) {
				sensitivityMap[sensitiveType] = 0;
			}
			sensitivityMap[sensitiveType]++;
		});

		const sensitivityGraph = {
			data: [],
			labels: []
		};

		for (const [key, value] of Object.entries(sensitivityMap)) {
			sensitivityGraph.data.push(key);
			sensitivityGraph.labels.push(value);
		}

;		if (isUpdate && this.sensitivityGraphOptions?.labels?.length && this.gs.areArraysEqual(this.sensitivityGraphOptions.labels, sensitivityGraph.labels)) {
			this.topSensitivityChart.updateSeries(sensitivityGraph.data, true);
			return;
		}

		this.sensitivityGraphOptions = {
			series: sensitivityGraph.labels,
			chart: {
				type: 'donut',
				height: '100%',
			},
			plotOptions: {
				pie: {
					donut: {
						size: '45%',
					}
				}
			},
			dataLabels: {
				enabled: true,
				formatter: (val, opt) => {
					return opt.w.globals.series[opt.seriesIndex];
				}
			},
			legend: {
				width: 235
			},
			labels: sensitivityGraph.data,
			tooltip: {
				y: {
					formatter: (val) => {
						return val.toFixed(0);
					}
				}
			}
		}
	}


	getEmailSenderChartInfo = (emails, isUpdate) => {
		const senderMap = {};
		emails?.forEach((emailObj) => {
			if (!senderMap[emailObj.from.email]) {
				senderMap[emailObj.from.email] = 0;
			}
			senderMap[emailObj.from.email]++;
		});
		// @ts-ignore
		const sortedSenders = Object.entries(senderMap).sort((a, b) => b[1] - a[1]);
		const senderGraph = {
			data: [],
			labels: []
		};

		for (let i = 0; i < sortedSenders.length && i < 10; i++) {
			senderGraph.labels.push(sortedSenders[i][0]);
			senderGraph.data.push(sortedSenders[i][1]);
		}

		const series = [{
			name: 'Emails',
			data: senderGraph.data
		}];
		if (isUpdate && this.senderGraphOptions?.xaxis?.categories?.length &&
			this.gs.areArraysEqual(senderGraph.labels, this.senderGraphOptions.xaxis.categories)) {
			this.topSendersChart.updateSeries(series, true);
			return;
		}

		this.senderGraphOptions = {
			series: series,
			chart: {
				type: 'bar',
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					animateGradually: {
						enabled: false,
					},
				}
			},
			colors: [
				this.lfs.color
			],
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
					barHeight: this.gs.getOptimalBarWidth(senderGraph.labels.length) + '%',
				}
			},
			dataLabels: {
				total: {
					enabled: true,
					offsetX: 0,
					style: {
						fontSize: '13px',
						fontWeight: 900
					}
				}
			},
			xaxis: {
				categories: senderGraph.labels,
			},
			yaxis: {
				labels: {
					align: 'left',
					minWidth: 140,
					maxWidth: 140,
				}
			}
		}
	}

	getEmailCountChartInfo = (ruleObj, emails, isUpdate) => {
		const ruleUsage = {
			total: emails.length,
			labels: [],
			data: [[]],
			series: [ruleObj.name]
		};

		// @ts-ignore
		emails.sort((a, b) => new Date(a.created) - new Date(b.created));

		let rulesSourcePerWeek = {
			dateStart: null,
			dateEnd: null,
			total: 0,
		};

		for (let i = 0; i < emails.length; i++) {
			let currentSource = emails[i];

			if (!rulesSourcePerWeek.dateStart) {
				rulesSourcePerWeek = this.gs.calcDatesForPeriod(currentSource, this.usagePeriod.value);
				rulesSourcePerWeek.total = 0;
			}

			if (this.gs.isSamePeriod(this.usagePeriod.value, currentSource.created, rulesSourcePerWeek.dateEnd)) {
				rulesSourcePerWeek.total += 1;
			}
			else {
				this.addRuleGraphData(ruleUsage, rulesSourcePerWeek, this.usagePeriod.value);

				rulesSourcePerWeek = this.gs.calcDatesForPeriod(currentSource, this.usagePeriod.value);
				rulesSourcePerWeek.total = 0;

				rulesSourcePerWeek.total += 1;
			}
		}
		this.addRuleGraphData(ruleUsage, rulesSourcePerWeek, this.usagePeriod.value);

		const series = [];
		ruleUsage?.series?.forEach((categoryName, index) => {
			series.push({
				name: 'Emails',
				data: ruleUsage.data[index]
			});
		});

		if (isUpdate && this.emailDetectedByRuleChartOptions?.series?.length &&
			this.gs.areArraysEqual(ruleUsage.labels, this.emailDetectedByRuleChartOptions.xaxis.categories)) {
			this.emailsDetectedChart.updateSeries(series, true);
			return;
		}

		this.emailDetectedByRuleChartOptions = {
			series: series,
			chart: {
				type: ruleUsage.labels?.length === 1 ? 'bar' : 'area',
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					animateGradually: {
						enabled: false,
					},
				}
			},
			plotOptions: {
				bar: {
					borderRadius: 5,
					horizontal: false,
					columnWidth: this.gs.getOptimalBarWidth(ruleUsage.labels?.length) - 20 + '%',
				},
			},
			colors: ['#8177a3'],
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['transparent']
			},
			xaxis: {
				categories: ruleUsage.labels,
			},
			yaxis: {
				title: {
					text: 'Emails'
				},
				labels: {
					formatter: (val) => {
						return val.toFixed(0);
					}
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val) => {
						return val.toFixed(0);
					}
				}
			}
		};
	}

	addRuleGraphData(ruleUsage, rulesSourcePerWeek, period) {
		if (rulesSourcePerWeek.dateStart) {
			const dateLabel = this.gs.getPeriodLabel(period, rulesSourcePerWeek);

			ruleUsage.labels.push(dateLabel);
			ruleUsage.data[0].push(rulesSourcePerWeek.total);
		}
	}

	changePeriod = (period) => {
		if (this.usagePeriod === period) {
			return;
		}
		this.usagePeriod = period;
		this.getRuleUsage(this.data.ruleObj);
	};

	changeViewType = (type) => {
		this.viewType = type;
	}

	copyEmailIdToClipboard = (email) => {
		this.clipboard.copy(email.o_message_id);
		this.ruleEmailIdCopied = email.o_message_id;
	};

	searchRuleEmail = (searchTerm) => {
		this.ruleUsage.emails.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	exportRuleEmailsToCsv = (sortBy) => {
		if (!this.ruleUsage.emails || !this.ruleUsage.emails.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}
		let csvString = "Subject,From,Sent,Sensitivity Score,Message ID\n";

		let sortedTable = _.filter(this.ruleUsage.emails,log => {return !log.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((email) => {
			csvString += `"${(email.sent && email.sent.title) ? email.sent.title : ''}","${(email.from && email.from.email) ? email.from.email : ''}",${email.created || ''},${email.sensitivity && email.sensitivity.score || '0'},${email.o_message_id || ''}\n`;
		});

		this.gs.exportCsv(csvString, `outbound_rule_emails.csv`);
	};


	openSensitiveInfoPopup = (emailObj) => {
		this.showSensitiveInfoPopup = {
			email: emailObj,
			sensitivity: emailObj.sensitivity
		};
	};

	closeSensitiveInfoPopup = () => {
		this.showSensitiveInfoPopup = null;
	}
}

function searchTextExecute(ruleObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((ruleObj.sent.title && ruleObj.sent.title.toLowerCase().indexOf(searchTerm) > -1) ||
		(ruleObj.from.email && ruleObj.from.email.toLowerCase().indexOf(searchTerm) > -1));
}
