<popup-c
	head="Select Contacts"
	size="medium"
	class="bs-enabled"
	(closeCb)="closeModal();"
	(confirmCb)="addressBookDone();"
	[buttons]="{confirm: {text: 'Confirm'}}">
	<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
		<trustifi-table-c
				class="trustifi-table" style="flex: 1 0; overflow: auto; z-index: 5; background: #fdfdfd; padding: 5px;"
				[list]="allContactsDisplay"
				[headers]="[
					{text:'Email', sortKey:'email'},
				]"
				[cellsPrototype]="[
					{textKey:'email'},
				]"
				(searchFunction)="searchContacts($event.searchTerm, $event.activeFilters)"
				[options]="{
					itemsNameSingular: 'contact',
					defaultSortKey: '-email',
					showRadioButtons: true,
				}"
			>
		</trustifi-table-c>
		<div class="flex-row flex-wrap flex-shrink-0 overflow-auto justify-content-start gap-2 align-items-baseline" style="height: 62px; padding-left: 0.3rem; padding-right: 0.3rem;">
			<div *ngFor="let contact of _.filter(allContactsDisplay, 'selected'); trackBy: gs.trackByIndex"
				  class="p-1 d-flex hSpace05 align-items-center rounded-1 bg-dark"
				  (click)="deselectContact(contact)"
				  style="cursor: pointer; --bs-bg-opacity: .07;">
				<span>{{contact.email}}</span> <i class="zmdi zmdi-close"></i>
			</div>
		</div>
	</div>
</popup-c>

