import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {RouteService} from "../../services/routeService";
import {GeneralService} from "../../services/generalService";
import {NotificationService} from "../../services/notificationService";
import {DICTIONARY} from "../../dictionary";
import _ from "lodash";
import util from "util";

@Component({
	selector: 'sensitivity-type-c',
	templateUrl: './sensitivity-type.component.html',
})

export class SensitivityTypeComponent implements OnInit {
	@Output() cancelCb = new EventEmitter<any>;
	@Output() updateCb = new EventEmitter<any>;
	@Input() data;
	@Input() allTypes;
	@Input() doNotDeleteTypes;
	@Input() selectedTypes;

	constructor(private rs:RouteService,
				private gs:GeneralService,
				private ns:NotificationService) {
	}

	dic = DICTIONARY;
	types;
	addTypeInProcess;
	newTypes = [];
	_ = _;

	ngOnInit() {
		this.types = _.cloneDeep(this.allTypes);
		this.types.forEach((type) => {
			type.isEditMode = false;
			type.selected = this.selectedTypes.includes(type.name);
			// Hide action buttons for none custom types
			type.hideActions = type.type !== 'Custom';
		});
	}

	closeModal = () => {
		if (this.newTypes.length) {
			this.newTypes.forEach((sensType) => {
				this.allTypes.push(sensType)
			});
		}
		this.cancelCb.emit();
	};

	updateTypes = () => {
		this.searchType('', '');
		if (this.types) {
			this.types = _.map(_.filter(this.types, 'selected'), 'name');
			this.updateCb.emit({ types: this.types, newTypes: this.newTypes });
		}
		this.cancelCb.emit();
	};

	startAddType = () => {
		if (this.addTypeInProcess) {
			return;
		}
		this.addTypeInProcess = true;

		const newType = {
			isEditMode: true,
			isNew: true,
			sensitivityOptions: [1, 2, 3, 4, 5],
			edit: {
				name: '',
				regex: '',
				sensitivity: 1,
			}

		};

		this.types.unshift(newType);
	};

	confirmAddType = (sensitivityType ,isApproved) => {

		// new type process was declined
		if (!isApproved) {
			if (sensitivityType._id || sensitivityType.name) {
				sensitivityType.isEditMode = false;
			} else {
				_.remove<any>(this.types, sensitivityType);
				this.addTypeInProcess = false;
			}

			sensitivityType.edit = null;
			return;
		}
		//
		// new type approved
		if (!sensitivityType.edit.name) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Sensitivity Type name field'));
		}
		if (!sensitivityType.edit.regex) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Regex field'));
		}
		const match = sensitivityType.edit.regex.match(this.dic.CONSTANTS.PATTERN_REGEX);
		if (match && match.length >= 2) {
			sensitivityType.regex = match[2];
			sensitivityType.regexFlags = match[3] || '';
		}
		sensitivityType.sensitivity = sensitivityType.edit.sensitivity;


		if (sensitivityType._id || sensitivityType.name ) {
			this.updateSensitivityTypeExecute(sensitivityType);
		}
		else {
			this.addSensitivityTypeExecute(sensitivityType);
		}

		this.addTypeInProcess = false;
	};

	updateSensitivityTypeExecute = (newType) => {
		newType.confirmEditInProcess = true;
		this.rs.updateSensitivityType(newType).then(res => {
			this.ns.showInfoMessage(`Sensitivity type ${newType.name} updated successfully`);
			const type = _.find<any>(this.types, t => t.name === newType.name);
			if (type) {
				type.regex = newType.regex;
				type.regex_flags = newType.regexFlags || '';
				type.sensitivity = newType.sensitivity;
				type.selected = true;
				type.edit = null;
				type.isNew = undefined;
				type.confirmEditInProcess = false;
				type.isEditMode = false;
				type.min_score = newType.sensitivity;
				type.max_score = newType.sensitivity;
			}
			let typeToUpdate = _.find(this.allTypes, { name: type.name });
			if (typeToUpdate) {
				typeToUpdate.regex_flags = type.regexFlags;
				typeToUpdate.regex = type.regex;
				typeToUpdate.sensitivity = type.sensitivity;
				typeToUpdate.min_score = type.sensitivity;
				typeToUpdate.max_score = type.sensitivity;
			}
			this.addTypeInProcess = false;
		}, err => {
			const type = _.find<any>(this.types, t => t.name === newType.name);
			if (type) {
				type.confirmEditInProcess = false;
			}
			if (err && err.data && err.data.message) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.addTypeInProcess = false;
		});
	};

	addSensitivityTypeExecute = (newType) => {
		newType.confirmEditInProcess = true;
		this.rs.addSensitivityType(newType.edit).then(res => {
			this.ns.showInfoMessage(`Sensitivity type ${newType.edit.name} added successfully`);
			newType.confirmEditInProcess = false;
			newType.isEditMode = false;
			newType.name = newType.edit.name;
			newType.type = 'Custom';
			newType.displayName = `${newType.name} (custom)`;
			newType.selected = true;
			newType.min_score = newType.sensitivity;
			newType.max_score = newType.sensitivity;
			newType.isNew = undefined;
			this.newTypes.push(newType);
			this.addTypeInProcess = false;
		}, err => {
			newType.confirmEditInProcess = false;
			newType.isEditMode = false;
			this.types.shift();
			if (err && err.data && err.data.message) {
				this.ns.showErrorMessage(err.data.message);
			}
			newType.edit = null;
			this.addTypeInProcess = false;
		});
	};

	setTypeForEdit = (typeObj) => {
		typeObj.sensitivityOptions = [1, 2, 3, 4, 5];
		typeObj.edit = {
			name: typeObj.name,
			regex: typeObj.regex,
			sensitivity: typeObj.sensitivity,
		}
		typeObj.isNew = undefined;
		typeObj.isEditMode = true;
	};

	selectSensitivityTypeActions = (typeObj, action) => {
		switch (action) {
			case 'Edit':
				this.setTypeForEdit(typeObj);
				break;

			case 'Delete':
				this.deleteNewType(typeObj);
				break;
		};
	};

	showSensitivityTypeActions = (sensitivityTypeObj) => {
		const actions = [
			'Edit',
			'Delete'
		];

		return actions;
	};

	searchType = (searchTerm, activeFilters) => {
		this.types.forEach(type => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(type, searchTerm);
				if (!isFound) {
					type.hide = true;
					return;
				}
			}

			type.hide = false;
		});
	};

	deleteNewType = (typeObj) => {
		const index = _.findIndex(this.types, ['name', typeObj.name]);

		if (!this.types[index]) return;

		const typeName = _.get(this.types, [index, 'name']);
		if (this.doNotDeleteTypes.includes(typeName)) {
			this.ns.showErrorMessage(this.dic.ERRORS.cannotDeleteCustomTypeInUse);
			return;
		};

		this.gs.showPopup({
			title: 'Delete Sensitivity Type',
			subTitle: 'Please note - the selected type will be deleted.',
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				this.rs.deleteSensitivityType(typeName).then(res => {
					this.ns.showInfoMessage(`Sensitivity type ${typeName} deleted successfully`);
					const deleteType = this.types.splice(index, 1)[0];
					if (deleteType.selected) {
						deleteType.selected = false;
					};
					const deleteIndex = _.findIndex<any>(this.newTypes, t => t.name === deleteType.name);
					if (deleteIndex >= 0) {
						_.remove(this.newTypes, n => n === deleteType);
					};

					const deleteIndex2 = _.findIndex<any>(this.allTypes, t => t.name === deleteType.name);
					if (deleteIndex2 >= 0) {
						_.pullAt(this.allTypes, [deleteIndex2]);
					};
					this.addTypeInProcess = false;
				}, err => {
					if (err && err.data && err.data.message) {
						this.ns.showErrorMessage(err.data.message);
					};
					this.addTypeInProcess = false;
				});
			}
		});
	};

	deselectType = (typeObj) => {
		typeObj.selected = false;

		// trigger the trustifi-table search by changing the list. The directive detects changes and fires a search
		// needed to update the "select all" checkbox state
		this.types = _.cloneDeep(this.types);
	}

	exportToCsv = (sortBy) => {
		if (!this.types || !this.types.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Name,Type,Min Score,Max Score,Compliance\n";

		let sortedTable = _.filter(this.types,incident => {return !incident.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((record) => {
			csvString += `${record.name},${record.type},${record.min_score || 'N/A'},${record.max_score || 'N/A'},"${record.compliance?.length ? record.compliance.join(', ') : 'N/A'}"\n`;
		});

		this.gs.exportCsv(csvString, `sensitivity_types.csv`);
	}
}

function searchTextExecute(sensObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();

	return ((sensObj.name?.toLowerCase().indexOf(searchTerm) > -1) ||
		(sensObj.type?.toLowerCase().indexOf(searchTerm) > -1) ||
		(sensObj.type?.toLowerCase().indexOf(searchTerm) > -1) ||
		(sensObj.compliance?.includes(searchTerm.toUpperCase())));
}
