<!--exchange connector/rules popup-->
<popup-c class="no-content-padding bs-enabled" size="large"
	   head="Switch Connector Type for <b>{{isInboundConfiguration ? 'Inbound' : 'Outbound'}}</b>"
	   subTitle="Use this tool to configure connectors and rules to integrate Trustifi in Microsoft Exchange"
	   (closeCb)="closePopup();">
	<div class="flex-column overflow-auto f-1-0 pt-2 gap-2" style="padding: 20px;">
		<!--steps progress-->
		<div class="steps-container" style="font-size: 0.8rem;">
			<div class="step-tab"
				 *ngFor="let stepKey of validSteps"
				 [ngClass]="{'active' : step === stepKey}">
				{{stepKey}}
			</div>
		</div>

		<!--Steps content:-->
		<!--STEP: General instructions-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-2 pt-3"
			 style="line-height: 2;"
			 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation">
				<span style="position: relative; padding-left: 15px;" class="diamond-indent">
					This wizard will switch your Microsoft Exchange connector type from "Your organization's email server" to "Partner organization" and and vice versa.
				</span>
			<br/>
			<div class="flex-row gap-2 align-items-baseline">
				<i class="fa fa-exclamation-triangle opacity-25"></i>
				<span class="fw-bold">Before taking the next step:</span>
			</div>

			<div class="bg-light p-2">
				<ul style="list-style-type: number;">
					<li>
						<span>Make sure your PowerShell integration with Trustifi is active and valid</span>
					</li>
					<li>
						<span>Make sure remote PowerShell access is enabled in your Exchange server</span>
					</li>
				</ul>
			</div>

			<div class="flex-column gap-2 px-2 f-1-0 justify-content-end">
				<hr class="mt-0"/>
				<span class="diamond-indent position-relative ps-3">After clicking <b>Configure</b>, Trustifi will update existing connectors and rules in Exchange.</span>
			</div>
		</div>

		<!--STEP: Summary-->
		<div class="flxClmn f-1-0 animated fadeIn gap-3 p-2 pt-3" *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.summary">
                    <span>
                        The Trustifi connectors and rules are being configured on Exchange.
                        <br/><br/>
                        <span style="font-size: 13px;"><u>Note</u>: this process may take a couple of minutes to finish.</span>
                    </span>
			<br/><br/>
			<br/>
			<br/>
			<div class="border w-100 p-4 flex-column gap-3" style="max-width: 520px; background: white;"
				 [ngClass]="{'text-danger border-danger' : rulesStatus.status === 'failure', 'text-success border-success' : rulesStatus.status === 'success'}"
				 *ngIf="rulesStatus && rulesStatus.status">
                        <span class="fw-bold">
                            {{rulesStatus.status === 'failure' ? 'Configuration process failed' :
							rulesStatus.status === 'success' ? 'Configuration process finished successfully' : 'Looks like the configuration process hasn\'t finished yet.'}}
                        </span>
				<span *ngIf="rulesStatus.message">{{rulesStatus.message}}</span>
			</div>

			<div class="flxClmn centralize" style="flex: 1 0;" *ngIf="generateTokenInProcess">
				<loader height="100" width="100" style="opacity: 0.8;"></loader>
				<span class="text-primary mt-1 fw-500">Updating Trustifi connectors and rules</span>
				<span class="text-primary">Please wait. Process may take a while...</span>
			</div>
		</div>

		<!--error message and buttons-->
		<div class="flex-row justify-content-between align-items-end mb-3 px-4 w-100">
                    <span class="f-1-0 pe-3 text-danger" style="min-height: 40px;">
                        {{errorMsg || ' '}}
                    </span>

			<div class="flex-row gap-3" style="font-size: 13px;">
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					 variant="outlined"
					 (action)="closePopup();">
					Cancel
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step !== dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					 variant="outlined"
					 (action)="backStep();">
					Back
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					 (action)="nextStep();">
					Configure
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.summary"
					 (action)="closePopup();">
					OK
				</btn-c>
			</div>
		</div>
	</div>
</popup-c>
