<popup-c class="bs-enabled"
       head="{{popupTitle}}" size="large"
       [subTitle]="popupSubTitle || null"
	   (closeCb)="cancelCb.observed ? cancelCb.emit(): null"
	   (confirmCb)="doneCb.observed ? doneCb.emit({groups: _.reject(groups, 'parent_email')}) : null"
	   [buttons]="{cancel: doneCb.observed ? 'Cancel' : 'OK', confirm: doneCb.observed ? (doneBtnTxt || 'Confirm') : null}">
    <div class="flex-column f-1-0" style="overflow: auto;">
		<trustifi-table-c
			class="trustifi-table f-1-0" style="z-index: 5;"
			[list]="groups"
			[headers]="[
							{text:'Name', sortKey: 'displayName'},
							{text:'Email', sortKey: 'email'},
							{text:'Origin', sortKey: 'origin', width: '100px'},
						]"
			[cellsPrototype]="[
							  {html: 'nameCell'},
							  {textKey: 'email'},
							  {textKey: 'origin'},
							]"
			[cellActions]="{
								enable: showActions,
								selectItemActionCb:	selectGroupAction,
								initActionsFunction: showGroupActions,
								showInRed: {enable: true, terms: ['Delete']}
							}"
			(searchFunction)="searchGroup($event.searchTerm, $event.activeFilters)"
			[subRowOptions]="{
							recursionKey: 'members',
							allowActions: false
						}"
			[options]="{
							itemsNameSingular: 'group',
							loadingTableFlag: getGroupsInProcess,
							defaultSortKey: '-displayName',
							itemUniqueIdentifier: '_id',
							showRadioButtons: multipleSelection,
						}"
		>
			<ng-template #customCells let-item let-cellId="cellId">
				<!--name cell-->
				<span *ngIf="cellId === 'nameCell'">
					<div class="flxRow hSpace05" style="align-items: center; cursor: pointer;"
						 (click)="toggleExpandItem(item, $event)">
						<i class="fa fa-chevron-right" style="color: royalblue; transition: transform 0.15s ease-out; padding: 5px;"
						   *ngIf="item.members"
						   [ngStyle]="{'transform' : item.isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}"></i>
						<span style="padding: 7px 0;" [ngStyle]="{'margin-left' : item.members ? '' : '30px'}">
							{{item.displayName || item.name}}
						</span>
					</div>
				</span>
			</ng-template>

			<div tableButtons>
				<div class="flxRow">
					<btn-c style="width: 135px; margin: 0 5px 7px 0;"
						   endIcon="fas fa-file-csv"
						   [isDisabled]="uploadGroupsInProcess"
						   (action)="openUploadGroupPopup();">
						Import Groups
					</btn-c>
				</div>
			</div>
		</trustifi-table-c>
    </div>
</popup-c>
