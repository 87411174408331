<div class="flex-column gap-4 animated fadeIn" style="flex-grow: 1;">
    <span>Manage primary and alternative email addresses used by your account</span>
    <div class="info-banner animated fadeIn" style="font-weight: normal; background: transparent; opacity: 0.9; width: fit-content;" *ngIf="userInfo.user_role !== dic.CONSTANTS.userRole.user">
        Note: adding an email address here will add to your account another email address <b>that you own</b>, it will not create a new user.
        <br>To add new users, navigate to
        <a style="padding: 0; margin: 0; display: inline-block; color: #0000EE; font-weight: bold;"
		   [routerLink]="['/' + dic.CONSTANTS.appStates.adminOutbound, dic.CONSTANTS.adminPages.outbound.userManagement]">
            User Management
        </a>.
    </div>
    <div style="max-height: 600px; padding-left: 15px; max-width: 1200px; flex: 1 0;">
        <div class="flxClmn h100" style="overflow: auto;">
            <!--account addresses table-->
            <trustifi-table-c
                    class="trustifi-table" style="flex: 1 0; z-index: 5;"
                    [list]="accountFromEmails"
                    [headers]="[
						{text:'Email', sortKey:'email', width: '35%'},
						{text:'Name', sortKey:'name', width: 'clamp(200px,10vw,310px)'},
						{text:'Phone', width: 'clamp(220px,15vw,310px)'},
						{text:'Verified', sortKey:'email_verified', width: '14%'},
						{text:'Type', sortKey:'type', width: '19%'},
					]"
                    [cellsPrototype]="[
						{textKey: 'email', tooltip: true, edit: {html:'emailCellEdit'}},
						{textKey: 'name', edit: {html:'nameCellEdit'}},
						{html:'phoneNumberCell', overflowOutside: true, edit: {html:'phoneCellEdit'}},
						{html: 'verifiedCell'},
						{textKey: 'type_display'},
					]"
                    [cellActions] = "{
						enable: true,
						selectItemActionCb:	selectAddressAction,
						initActionsFunction: showAddressActions,
						showInRed: {enable: true, terms: ['remove']}
					}"
                    (confirmEditFunction)="confirmEditFromAddress($event.item, $event.isApproved)"
                    [options]="{
						itemsNameSingular: 'address',
						defaultSortKey: '-email',
					}"
            >
				<ng-template #customCells let-item let-cellId="cellId">
					<!--User name cells-->
					<span *ngIf="cellId === 'verifiedCell'" [ngStyle]="item.email_verified ? {'color':'green'} : {'color':'darkorange'}">
                    	{{item.email_verified ? 'Verified' : !item.isNew ? 'Pending' : ''}}
					</span>
					<!--phone number cells-->
					<div *ngIf="cellId === 'phoneNumberCell'" class="flxClmn vSpace05">
						<span *ngIf="item.phone && item.phone.phone_number">
                                    +{{ item.phone.country_code }}-{{item.phone.phone_number}}
						</span>
						<span *ngIf="!item.phone || !item.phone.phone_number">
                                    -
						</span>
					</div>
					<!--phone number cell - EDIT MODE-->
					<span *ngIf="cellId === 'phoneCellEdit'" class="flxRow hSpace05 h100" style="overflow: visible;">
						<country-code-select [(model)]="item.edit.phone.country_code" ></country-code-select>
						<input id="contactsAddPhone"
							   type="text" placeholder="Phone number"
							   style="flex: 1 0; height: 100%; margin-right: 0; margin-bottom: 0;"
							   pattern="/^[0-9]*$/"
							   maxlength="11"
							   [value]="item.edit.phone.phone_number"
							   [(ngModel)]="item.edit.phone.phone_number">
					</span>
					<!--name cell - EDIT MODE-->
					<div *ngIf="cellId === 'nameCellEdit'" class="h100">
						<div class="flxRow gap-1 h100" style="overflow: visible;" *ngIf="item.isNew">
							<input class="w100 h100"
								   placeholder="First Name"
								   style="margin-bottom: 0; flex: 1 0; background: white;"
								   [(ngModel)]="item.edit['firstName']" maxlength="100"
								   (keydown.enter) = "confirmEditFromAddress(item,true)" type="text">
							<input class="w100 h100"
								   placeholder="Last Name"
								   style="margin-right: 0; margin-bottom: 0; flex: 1 0; background: white;"
								   [(ngModel)]="item.edit['lastName']" maxlength="100"
								   (keydown.enter) = "confirmEditFromAddress(item,true);" type="text">
						</div>
						<div class="flxRow h100" style="overflow: visible;" *ngIf="!item.isNew">
							<input class="w100 h100"
								   placeholder="Name"
								   style="margin: 0; flex: 1 0; background: white;"
								   [(ngModel)]="item.edit['name']" maxlength="100"
								   (keydown.enter) = "confirmEditFromAddress(item,true);" type="text">
						</div>
					</div>
					<!--email cell - EDIT MODE-->
					<div *ngIf="cellId === 'emailCellEdit'" class="flxClmn h100 vSpace05">
						<span class="flxRow h100" style="overflow: visible;" *ngIf="item.isNew">
							<input class="w100 h100"
								   placeholder="Email"
								   style="margin: 0; flex: 1 0; background: white;"
								   [(ngModel)]="item.edit['email']" maxlength="100"
								   (keydown.enter) = "confirmEditFromAddress(item,true)" type="text">
                    	</span>
						<div title="{{item.email}}" class="flxClmn-center h100" *ngIf="!item.isNew">
							<span>
								{{item.email}}
							</span>
						</div>
					</div>
				</ng-template>
                <!---->
				<div tableButtons>
                    <div class="flex-row" style="align-items: baseline; padding: 5px 0;">
                        <btn-c endIcon="fa fa-plus"
                             [isDisabled]="isAddAddressActive"
                             (action)="startAddFromAddress();">
                            Add Email Address
                        </btn-c>
                    </div>
                </div>
            </trustifi-table-c>
        </div>
    </div>
</div>
