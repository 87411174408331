<div class="flxClmn">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Audit Log</span>
        </div>
		<div class="page-subtitle-container flxRow">
			<span class="page-subtitle">Review email traffic in Trustifi and actions performed by admins and reviewers</span>
		</div>
    </div>
    <div class="page-inner-content-holder flxClmn no-scroll vSpace3" style="min-height: 545px;">
		<!--Tabs-->
		<div class="md-tabs" [ngStyle]="{'--md-tabs-width': tabWidth + 'px'}">
			<div *ngFor="let tab of dic.CONSTANTS.auditLogsPageTabs | keyvalue: gs.returnZero"
				 [ngClass]="{'selected' : gs.getTabName() === tab.value}"
				 [routerLink]="tab.value">
				<input type="radio" name="tabs" id="{{tab.key}}Toggle" [checked]="gs.getTabName() === tab.value">
				<label for="{{tab.key}}Toggle">
					<span>{{tab.value | camelCaseToTitleCase}}</span>
				</label>
			</div>

			<div class="slide" [ngStyle]="{'left' : tabWidth * _.values(dic.CONSTANTS.auditLogsPageTabs).indexOf(gs.getTabName()) + 'px' || 0}"></div>
		</div>
		<!---->

		<router-outlet></router-outlet>
    </div>
</div>

