import * as util from 'util';
import _ from 'lodash';
import {DICTIONARY} from '../../../../../dictionary';
import {RouteService} from '../../../../../services/routeService';
import {NotificationService} from '../../../../../services/notificationService';
import {GeneralService} from '../../../../../services/generalService';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Router} from "@angular/router";
import {eachLimit} from 'async';

@Component({
	selector: 'inbound-allow-block-lists-component',
	templateUrl: './inbound-allow-block-lists.component.html',
})
export class InboundAllowBlockListsComponent implements OnInit, OnChanges {
	@Input() listType = DICTIONARY.CONSTANTS.inboundConfigurationPageTabs.senderLists;

	dic = DICTIONARY;
	_ = _;
    reviewerInfo = this.gs.reviewerInfo;
    getTPConfigInProcess = true;
    currentLists;
    userInfo;
    isPartnerAdmin = false;
    currentUser;
    planUsers = [];
    config;
	util = util;

    removeAEmailInProcess: boolean;
    adminSelectionTypeSelection: string;
    removeLinkInProcess: boolean;
    resultsToUpload: any[];
    resultFile: any;
    linksBlacklistSubmitted: boolean;
    exportAllListsToCsvInProcess: boolean;
    assignItemToAdminsInfo:any = {};
    sendersAllowlistAdvancedInfoPopup:any = {};
    adminSelectionType = {all: 'all', specific: 'specific'};
    activeListsTab = this.listType;
	private removeFromAllAdmins = false;
	whitelistFiles = null;
	blacklistFiles = null;
	validDrag;
	invalidDrag;
	validDrag2;
	invalidDrag2;
	recipientConfigPopup;
	whiteListConfigPopup;

    constructor(public router:Router,
				public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
    }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.listType) {
			this.activeListsTab = this.listType;
		}
	}

	ngOnInit() {
		this.getTPConfig();

        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
            this.isPartnerAdmin = !!this.reviewerInfo.plan.customer_id;
            this.currentUser = DICTIONARY.CONSTANTS.planDefaultUser;

            this.rs.getTPConfigUsers('').then((users) => {
                this.planUsers = users;
                this.planUsers = _.sortBy(this.planUsers, u => {
                    return u.email !== this.userInfo.email;
                });
                this.planUsers.unshift({email: DICTIONARY.CONSTANTS.planDefaultUser})
            }, (err) => {

			});
        });
    };

    getTPConfig = () => {
        this.getTPConfigInProcess = true;
        this.rs.getTPConfig().then((response) => {
            this.config = response;

            this.changeListsConfig(this.config.threat_protection);
            this.getTPConfigInProcess = false;

        }, (err) => {
            this.getTPConfigInProcess = false;
        });
    }

    getTpRoute = (updateData, additionalData=null) => {
        let promise;
        if (this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            promise = this.rs.updateTpConfig(updateData);
        }
        else {
            if (additionalData) {
                _.each(Object.keys(additionalData), key => {
                    updateData[key] = additionalData[key];
                });
            }
            updateData.userEmail = this.currentUser;
            promise = this.rs.updateTpConfigUsers(updateData);
        }

        return promise;
    };

    getTpRouteForCsv = (data) => {
        return this.getTpRoute(data);
    };

    startAddRecord = (list) => {
        if (list.isAddRecordActive) {
            return;
        }

        list.isAddRecordActive = true;

        const newRecord = {
            isEditMode: true,
            isNew: true,
            edit: {
                email: '', // for senders table
                link: '', // for links table
                matchType: DICTIONARY.CONSTANTS.ruleMatchType.exactMatch, // for links table
                name: '', // for attachments table
                hash: '', // for attachments table
                description: '',
                domain: '' // for domains table
            }
        };

        list.unshift(newRecord);
    };

    addAEmail = (email, type, description, matchType) => {
        if (!email) {
            return;
        }
		email = email.toLowerCase().trim();

        const data = {
            type: type,
            description: description || '',

			action: DICTIONARY.CONSTANTS.inboundConfigurationsActions.add,
            items: []
        };

		if (matchType === DICTIONARY.CONSTANTS.ruleMatchType.exactMatch &&
			!this.gs.validateEmail(email) && !this.gs.isValidDomain(email) && !this.gs.isValidIPAddress(email) && !this.gs.isCIDRAddress(email, 24)) {
			this.ns.showErrorMessage(DICTIONARY.ERRORS.invalidSender);
			return;
		}
        const item = {description: description || '', value: email, matchType: matchType};

        data.items.push(item);
        switch (type) {
            case DICTIONARY.CONSTANTS.configTpAction.whitelist:
                if (_.map(this.currentLists.senders_whitelist, 'email').includes(email)) {
                    this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emailDomainAlreadyExist, email));
                    return;
                }
                if (_.map(this.currentLists.senders_blacklist, 'email').includes(email)) {
                    this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.configWhiteBlack, email));
                    return;
                }
                break;
            case DICTIONARY.CONSTANTS.configTpAction.blacklist:
                if (_.map(this.currentLists.senders_blacklist, 'email').includes(email)) {
                    this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emailDomainAlreadyExist, email));
                    return;
                }
                if (_.map(this.currentLists.senders_whitelist, 'email').includes(email)) {
                    this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.configWhiteBlack, email));
                    return;
                }
                break;
        }

        if (type === DICTIONARY.CONSTANTS.configTpAction.whitelist && this.gs.isValidDomain(email)) {
            this.gs.showPopup({
                title: "Add domain to allowlist",
                subTitle: `You are about to add the domain ${item.value} to the allowlist`,
                body: ['WARNING: Do not add domains you own or popular domains (e.g. gmail.com) to the domain allowlist. This creates opportunities for attackers to send you email that would otherwise be blocked.'],
                type: DICTIONARY.CONSTANTS.popupWarning,
                doneBtnText: DICTIONARY.CONSTANTS.inboundConfigurationsActions.add,
                doneCb: () =>{
                    this.addAEmailExecute(email, type, data);
                }
            });
        }
        else {
            this.addAEmailExecute(email, type, data);
        }
    };

    addAEmailExecute = (email, type, data) => {
		const promise = this.getTpRoute(data);
		let newRecord, list;
		switch (type) {
			case DICTIONARY.CONSTANTS.configTpAction.whitelist:
				list = this.currentLists.senders_whitelist;
				newRecord = _.find(list, sender => {return sender.isNew});
				break;

			case DICTIONARY.CONSTANTS.configTpAction.blacklist:
				list = this.currentLists.senders_blacklist;
				newRecord = _.find(list, sender => {return sender.isNew});
				break;
		}
		if (newRecord) {
			newRecord.confirmEditInProcess = true;
		}
        promise.then(response => {
            if (newRecord) {
                newRecord.created = new Date().toISOString();
                newRecord.updated_by = this.reviewerInfo.email;
                _.merge(newRecord, newRecord.edit);
				newRecord.email = email;
				newRecord.edit = null;
				newRecord.confirmEditInProcess = false;
                newRecord.isEditMode = false;
                newRecord.isNew = undefined;
            }

            list.isAddRecordActive = false;
            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, email));
        }, err => {
			if (newRecord) {
				newRecord.confirmEditInProcess = false;
			}
		});
    };

    editEmail = (sender, type) => {
        if (!sender || !sender.edit) {
            return;
        }

        if (!sender.edit.email) {
            this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emptyField, 'Email/Domain/IP/CIDR'));
            return;
        }

		sender.edit.email = sender.edit.email.toLowerCase().trim();

		const data = {
            type: type,
            action: DICTIONARY.CONSTANTS.inboundConfigurationsActions.edit,
            items: []
        };

        const senderEditData = {
			description: sender.edit.description || '',
			matchType: sender.edit.matchType,
			oldEmail: sender.email, value: sender.edit.email,
		};
        if (senderEditData.matchType === DICTIONARY.CONSTANTS.ruleMatchType.exactMatch &&
			!this.gs.validateEmail(sender.edit.email) && !this.gs.isValidDomain(sender.edit.email) && !this.gs.isValidIPAddress(sender.edit.email) && !this.gs.isCIDRAddress(sender.edit.email, 24)) {
			this.ns.showErrorMessage(DICTIONARY.ERRORS.invalidSender);
			return;
        }
		sender.confirmEditInProcess = true;
        data.items.push(senderEditData);

        const promise = this.getTpRoute(data);
        promise.then(response => {
            sender = _.merge(sender,sender.edit);
            sender.updated_by = this.reviewerInfo.email;
            sender.created = new Date();
			sender.confirmEditInProcess = false;
            sender.isEditMode = false;
            sender.edit = null;
            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemUpdated, sender.email));
        }, err => {
			sender.confirmEditInProcess = false;
		});
    };

    removeEmail = (emails, type) => {
        if (!emails || !emails.length) {
            return;
        }

        let title = 'Remove ';
        switch (type) {
            case DICTIONARY.CONSTANTS.configTpAction.whitelist:
                title += ' from allowlist';
                break;

            case DICTIONARY.CONSTANTS.configTpAction.blacklist:
                title += ' from blocklist';
                break;
        }
        emails = _.map(emails, 'email');
        const emailsRemovedText = emails.length === 1 ? emails[0] : emails.length + ' emails';

		this.removeFromAllAdmins = false;
		const body = [];
		if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
			body.push({
				labelName: 'Remove from all admins',
				toggle: this.removeFromAllAdmins
			});
		}
        this.gs.showPopup({
            title: title,
            subTitle: `Are you sure you want to remove ${emailsRemovedText}?`,
            body: body,
            type: DICTIONARY.CONSTANTS.popupToggle,
            doneBtnText: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove,
            doneCb: (options) =>{
                const data = {
                    type: type,
                    action: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove,
                    items: [],
					removeFromAllAdmins: this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser && options[0].toggle,
                    emails: null
                };

                _.each(emails, email => {
                    const item = {value: email};
                    data.items.push(item);
                });

                const promise = this.getTpRoute(data, {sender: {emails: data.emails}});
                this.removeAEmailInProcess = true;

                promise.then(() => {
                    switch (type) {
                        case DICTIONARY.CONSTANTS.configTpAction.whitelist:
                            _.remove<any>(this.currentLists.senders_whitelist, (e:any) => emails.includes(e.email));
                            break;

                        case DICTIONARY.CONSTANTS.configTpAction.blacklist:
                            _.remove<any>(this.currentLists.senders_blacklist, (e:any) => emails.includes(e.email));
                            break;
                    }

                    this.removeAEmailInProcess = false;
                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemRemoved, emailsRemovedText));
                });
            }
        });
    };

    senderSkipKeepRecord = (senders, enabled, isWhiteList) => {
        this.gs.showPopup({
            title: `${enabled ? 'Skip' : 'Keep'} quarantine record for ${senders.length > 1 ? senders.length + ' senders' : senders[0].email}?`,
            subTitle: `Are you sure you want to update quarantined email settings?`,
            body: [`We will${enabled ? ' not' : ''} keep a record of quarantined emails for the selected senders`],
            type: DICTIONARY.CONSTANTS.popupWarning,
            doneBtnText: 'Confirm',
            doneCb: () =>{
                let data = {
                    type: DICTIONARY.CONSTANTS.configTpAction.skipKeepRecord,
                    items: senders.map(itm => itm.email),
                    enabled: enabled,
                    isWhiteList: isWhiteList
                };
                const promise = this.getTpRoute(data);
                promise.then(() => {
                    senders.forEach((senderObj) => {
                        senderObj.skip_keep_record = enabled;
                    });

                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemUpdated, `${senders.length > 1 ? senders.length + ' senders' : senders[0].email}`));
                });
            }
        });
    };


    // senders whitelist table control
    showWhitelistActions = (sender) => {
        let actions = [
            DICTIONARY.CONSTANTS.configListsActions.edit
        ];
        if (this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            if (sender.skip_keep_record) {
                actions.push(DICTIONARY.CONSTANTS.configListsActions.keepRecords);
            }
            else {
                actions.push(DICTIONARY.CONSTANTS.configListsActions.doNotKeepRecords);
            }
            if (this.isPartnerAdmin) {
                actions.push(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
            }
            actions.push(DICTIONARY.CONSTANTS.configListsActions.advancedSettings);
        }
        actions.push(DICTIONARY.CONSTANTS.configListsActions.remove);
        return actions;
    }

    showWhitelistBulkActions = () => {
        let actions:any = [
            DICTIONARY.CONSTANTS.configListsActions.remove
        ];
        if (this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.unshift({
                display: 'Skip/Keep Records',
                submenu: [
                    DICTIONARY.CONSTANTS.configListsActions.keepRecords,
                    DICTIONARY.CONSTANTS.configListsActions.doNotKeepRecords
                ]
            });

            if (this.isPartnerAdmin) {
                actions.unshift(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
            }
        }
        return actions;
    }

    selectWhitelistAction = (whitelist, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configListsActions.keepRecords:
                this.senderSkipKeepRecord([whitelist], false, true);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.doNotKeepRecords:
                this.senderSkipKeepRecord([whitelist], true, true);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.remove:
                this.removeEmail([whitelist], DICTIONARY.CONSTANTS.configTpAction.whitelist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.edit:
                this.startEditListRecord(whitelist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.advancedSettings:
                this.allowListSenderAdvanced(whitelist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins([whitelist.email], DICTIONARY.CONSTANTS.configTpAction.whitelist);
                break;
        }
    };

    allowListSenderAdvanced = (item) => {
        if (!item.file_types) {
            item.file_types = {};
        }
        this.sendersAllowlistAdvancedInfoPopup = {
            show: true,
            item,
        };
    }

    updateBlockFileTypeStatus = (type) => {
        const whitelistSettings = {
            type: 'sendersWhiteListFileTypes',
            fileType: type,
            email: this.sendersAllowlistAdvancedInfoPopup.item.email,
            enable: this.sendersAllowlistAdvancedInfoPopup.item.file_types[type],
        };
        this.rs.updateTpConfig(whitelistSettings).then(() => {
            this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
        });
    }

	updateTpSendersWhiteListAdvanced = (type) => {
		const whitelistSettings = {
			type: 'sendersWhiteListAdvanced',
			advancedType: type,
			email: this.sendersAllowlistAdvancedInfoPopup.item.email,
			enable: this.sendersAllowlistAdvancedInfoPopup.item[type],
		};
		this.rs.updateTpConfig(whitelistSettings).then(() => {
			this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
		});
	}

    assignItemToAdmins = (items, tableType) => {
        this.adminSelectionTypeSelection = this.adminSelectionType.specific;
        this.assignItemToAdminsInfo = {
            type: DICTIONARY.CONSTANTS.configTpAction.assignToAdmin,
            show: true,
            policy: {
                items: items,
                type: tableType,
            },
            planAdmins: [],
            admins: [],
        };

        this.gs.planAdmins.forEach((adminObj) => {
            if (adminObj.email !== this.gs.controlAdmin.email) {
                this.assignItemToAdminsInfo.planAdmins.push({email: adminObj.email, selected: false});
            }
        });
    }

    chooseInstallPolicyAdmins = () => {
        const admins = this.assignItemToAdminsInfo.planAdmins.filter(itm => itm.selected).map(itm => itm.email);
        this.assignItemToAdminsInfo.admins = admins;
    };

    addItemToAdminsExecute = () => {
        if (this.adminSelectionTypeSelection === this.adminSelectionType.all) {
            this.assignItemToAdminsInfo.admins = this.assignItemToAdminsInfo.planAdmins.map(itm => itm.email);
        }

        if (!this.assignItemToAdminsInfo.admins.length) {
            this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.selectAtLeastOneX, 'admin'));
            return;
        }

        const promise = this.getTpRoute(this.assignItemToAdminsInfo);
        promise.then(response => {
            this.assignItemToAdminsInfo.show = false;
            this.ns.showInfoMessage('Items added successfully');
        });
    }

    selectMultipleWhitelistAction = (selectedItems, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configListsActions.keepRecords:
                this.senderSkipKeepRecord(selectedItems, false, true);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.doNotKeepRecords:
                this.senderSkipKeepRecord(selectedItems, true, true);
                break;

            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeEmail(selectedItems, DICTIONARY.CONSTANTS.configTpAction.whitelist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins(selectedItems.map(itm => itm.email), DICTIONARY.CONSTANTS.configTpAction.whitelist);
                break;
        }
    };

    // senders blacklist table actions control
    showBlacklistActions = (sender) => {
        let actions = [
            DICTIONARY.CONSTANTS.configListsActions.edit
        ];
        if (this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            if (sender.skip_keep_record) {
                actions.push(DICTIONARY.CONSTANTS.configListsActions.keepRecords);
            }
            else {
                actions.push(DICTIONARY.CONSTANTS.configListsActions.doNotKeepRecords);
            }
            if (this.isPartnerAdmin) {
                actions.push(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
            }
        }
        actions.push(DICTIONARY.CONSTANTS.configListsActions.remove);
        return actions;
    }

    showBlacklistBulkActions = () => {
        let actions:any = [
            DICTIONARY.CONSTANTS.configListsActions.remove
        ];
        if (this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.unshift({
                display: 'Skip/Keep Records',
                submenu: [
                    DICTIONARY.CONSTANTS.configListsActions.keepRecords,
                    DICTIONARY.CONSTANTS.configListsActions.doNotKeepRecords
                ]
            });
            if (this.isPartnerAdmin) {
                actions.unshift(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
            }
        }
        return actions;
    }

    selectBlacklistAction = (blacklist, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configListsActions.keepRecords:
                this.senderSkipKeepRecord([blacklist], false, false);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.doNotKeepRecords:
                this.senderSkipKeepRecord([blacklist], true, false);
                break;

            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeEmail([blacklist], DICTIONARY.CONSTANTS.configTpAction.blacklist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.edit:
                this.startEditListRecord(blacklist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins([blacklist.email], DICTIONARY.CONSTANTS.configTpAction.blacklist);
                break;
        }
    };

    selectMultipleBlacklistAction = (selectedItems, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configListsActions.keepRecords:
                this.senderSkipKeepRecord(selectedItems, false, false);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.doNotKeepRecords:
                this.senderSkipKeepRecord(selectedItems, true, false);
                break;

            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeEmail(selectedItems, DICTIONARY.CONSTANTS.configTpAction.blacklist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins(selectedItems.map(itm => itm.email), DICTIONARY.CONSTANTS.configTpAction.blacklist);
                break;
        }
    };

    // links whitelist table actions control
    showLinksWhitelistActions = (sender) => {
        const actions = [
            DICTIONARY.CONSTANTS.configListsActions.edit,
            DICTIONARY.CONSTANTS.configListsActions.remove
        ]
        if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.push(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
        }
        return actions
    }

    showLinksWhitelistBulkActions = () => {
        const actions = [
            DICTIONARY.CONSTANTS.configListsActions.remove
        ]
        if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.push(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
        }
        return actions;
    }

    selectLinksWhitelistAction = (whitelistLink, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeLinks([whitelistLink], DICTIONARY.CONSTANTS.configTpAction.linksWhitelist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.edit:
                this.startEditListRecord(whitelistLink);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins([whitelistLink.link], DICTIONARY.CONSTANTS.configTpAction.linksWhitelist);
                break;
        }
    };

    selectMultipleLinksWhitelistAction = (selectedItems, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeLinks(selectedItems, DICTIONARY.CONSTANTS.configTpAction.linksWhitelist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins(selectedItems.map(itm => itm.link), DICTIONARY.CONSTANTS.configTpAction.linksWhitelist);
                break;
        }
    };

    // links blacklist table actions control
    showLinksBlacklistActions = (sender) => {
        const actions = [DICTIONARY.CONSTANTS.configListsActions.edit,
            DICTIONARY.CONSTANTS.configListsActions.remove,];

        if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.unshift(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
        }

        return actions;
    }

    showLinksBlacklistBulkActions = () => {
        const actions = [DICTIONARY.CONSTANTS.configListsActions.remove];

        if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.unshift(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
        }

        return actions;
    }

    selectLinksBlacklistAction = (blacklistLink, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeLinks([blacklistLink], DICTIONARY.CONSTANTS.configTpAction.linksBlacklist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.edit:
                this.startEditListRecord(blacklistLink);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins([blacklistLink.link], DICTIONARY.CONSTANTS.configTpAction.linksBlacklist);
                break;
        }
    };

    selectMultipleLinksBlacklistAction = (selectedItems, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeLinks(selectedItems, DICTIONARY.CONSTANTS.configTpAction.linksBlacklist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins(selectedItems.map(itm => itm.link), DICTIONARY.CONSTANTS.configTpAction.linksBlacklist);
                break;
        }
    };

    // attachments whitelist table actions control
    showAttachmentsWhitelistActions = (sender) => {
        const actions = [DICTIONARY.CONSTANTS.configListsActions.remove];

        if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.unshift(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
        }

        return actions;
    }

    showAttachmentsWhitelistBulkActions = () => {
        const actions = [DICTIONARY.CONSTANTS.configListsActions.remove];

        if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.unshift(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
        }

        return actions;
    }

    selectAttachmentsWhitelistAction = (whitelistAttachment, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeAttachments([whitelistAttachment], DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins([whitelistAttachment.hash], DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist);
                break;
        }
    };

    selectMultipleAttachmentsWhitelistAction = (selectedItems, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeAttachments(selectedItems, DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins(selectedItems.map(itm => itm.hash), DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist);
                break;
        }
    };

    // attachments blacklist table actions control
    showAttachmentsBlacklistActions = (sender) => {
        const actions = [DICTIONARY.CONSTANTS.configListsActions.remove];

        if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.unshift(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
        }

        return actions;
    }

    showAttachmentsBlacklistBulkActions = () => {
        const actions = [DICTIONARY.CONSTANTS.configListsActions.remove];

        if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            actions.unshift(DICTIONARY.CONSTANTS.configListsActions.assignAdmins);
        }

        return actions;
    }

    selectAttachmentsBlacklistAction = (blacklistAttachment, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeAttachments([blacklistAttachment], DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins([blacklistAttachment.hash], DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist);
                break;
        }
    };

    selectMultipleAttachmentsBlacklistAction = (selectedItems, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.removeAttachments(selectedItems, DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist);
                break;

            case DICTIONARY.CONSTANTS.configListsActions.assignAdmins:
                this.assignItemToAdmins(selectedItems.map(itm => itm.hash), DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist);
                break;
        }
    };

    startEditListRecord = (record) => {
        record.edit = {
            email: record.email, // for senders
            link: record.link, // for links
            matchType: record.matchType, // for links
            description: record.description,
        };
        record.isEditMode = true;
    }

    // handle edit confirm in senders
    confirmEditSendersWhitelist = (sender, isApproved) => {
        // edit declined
        if (!isApproved) {
            if (sender.isNew) {
                _.remove(this.currentLists.senders_whitelist, sender);
                this.currentLists.senders_whitelist.isAddRecordActive = false;
                return;
            }

            // empty the 'edit' property which contains the temp edit values and then close edit mode
            sender.edit = null;
            sender.isEditMode = false;
            return;
        }
        //

        if (!sender.email) { // add new sender approved
            this.addAEmail(sender.edit.email, DICTIONARY.CONSTANTS.configTpAction.whitelist, sender.edit.description, sender.edit.matchType);
        }
        else { // edit approved
            this.editEmail(sender, DICTIONARY.CONSTANTS.configTpAction.whitelist);
        }
    };

    confirmEditSendersBlacklist = (sender,isApproved) => {
        // edit declined
        if (!isApproved) {
            if (sender.isNew) {
                _.remove(this.currentLists.senders_blacklist, sender);
                this.currentLists.senders_blacklist.isAddRecordActive = false;
                return;
            }

            // empty the 'edit' property which contains the temp edit values and then close edit mode
            sender.edit = null;
            sender.isEditMode = false;
            return;
        }
        //

        if (!sender.email) { // add new sender approved
            this.addAEmail(sender.edit.email, DICTIONARY.CONSTANTS.configTpAction.blacklist, sender.edit.description, sender.edit.matchType)
        }
        else { // edit approved
            this.editEmail(sender, DICTIONARY.CONSTANTS.configTpAction.blacklist);
        }
    };

    // handle edit confirm in links
    confirmEditLinksWhitelist = (link,isApproved) => {
        // edit declined
        if (!isApproved) {
            if (link.isNew) {
                _.remove(this.currentLists.links_whitelist, link);
                this.currentLists.links_whitelist.isAddRecordActive = false;
                return;
            }

            // empty the 'edit' property which contains the temp edit values and then close edit mode
            link.edit = null;
            link.isEditMode = false;
            return;
        }
        //

        if (!link.link) { // add new sender approved
            this.addLink(link, DICTIONARY.CONSTANTS.configTpAction.linksWhitelist);
        }
        else { // edit approved
            this.editLink(link, DICTIONARY.CONSTANTS.configTpAction.linksWhitelist);
        }
    };

    confirmEditLinksBlacklist = (link,isApproved) => {
        // edit declined
        if (!isApproved) {
            if (link.isNew) {
                _.remove(this.currentLists.links_blacklist, link);
                this.currentLists.links_blacklist.isAddRecordActive = false;
                return;
            }

            // empty the 'edit' property which contains the temp edit values and then close edit mode
            link.edit = null;
            link.isEditMode = false;
            return;
        }
        //


        if (!link.link) { // add new sender approved
            this.addLink(link, DICTIONARY.CONSTANTS.configTpAction.linksBlacklist);
        }
        else { // edit approved
            this.editLink(link, DICTIONARY.CONSTANTS.configTpAction.linksBlacklist);
        }
    };

    addLink = (linkObj, type) => {
        if (!linkObj) {
            return;
        }

        if (!linkObj.edit.link) {
            return this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emptyField, 'Link'));
        }

        const linkItem = {
            link: linkObj.edit.link.toLowerCase().trim(),
            description: linkObj.edit.description,
            matchType: linkObj.edit.matchType,
            created: new Date().toISOString()
        };
        if (this.currentLists.links_blacklist.some(itm => itm.link === linkItem.link)) {
            this.ns.showErrorMessage(util.format(type === DICTIONARY.CONSTANTS.configTpAction.linksBlacklist ?
                DICTIONARY.ERRORS.linkAlreadyExist :
                DICTIONARY.ERRORS.configWhiteBlack, linkItem.link));
            return;
        }
        if (this.currentLists.links_whitelist.some(itm => itm.link === linkItem.link)) {
            this.ns.showErrorMessage(util.format(type === DICTIONARY.CONSTANTS.configTpAction.linksWhitelist ?
                DICTIONARY.ERRORS.linkAlreadyExist :
                DICTIONARY.ERRORS.configWhiteBlack, linkItem.link));
            return;
        }

        let data = {
            type: type,
            links: [linkItem],
            action: DICTIONARY.CONSTANTS.inboundConfigurationsActions.add
        };
        const promise = this.getTpRoute(data);
		linkObj.confirmEditInProcess = true;
        promise.then(response => {
            let newRecord, list;
            switch (type) {
                case DICTIONARY.CONSTANTS.configTpAction.linksWhitelist:
                    list = this.currentLists.links_whitelist;
                    break;
                case DICTIONARY.CONSTANTS.configTpAction.linksBlacklist:
                    list = this.currentLists.links_blacklist;
                    break;
            }

            newRecord = _.find(list, sender => {return sender.isNew});
            if (newRecord) {
                Object.assign(newRecord, linkItem);
                newRecord.updated_by = this.reviewerInfo.email;
				newRecord.confirmEditInProcess = false;
                newRecord.isEditMode = false;
                newRecord.isNew = undefined;
            }

            list.isAddRecordActive = false;

            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, linkItem.link));
        }, err => {
			let newRecord, list;
			switch (type) {
				case DICTIONARY.CONSTANTS.configTpAction.linksWhitelist:
					list = this.currentLists.links_whitelist;
					break;
				case DICTIONARY.CONSTANTS.configTpAction.linksBlacklist:
					list = this.currentLists.links_blacklist;
					break;
			}
			newRecord = _.find(list, sender => {return sender.isNew});
			newRecord.confirmEditInProcess = false;
		});
    };

    editLink = (linkObj, type) => {
        if (!linkObj || !linkObj.edit) {
            return;
        }

        if (!linkObj.edit.link) {
            return this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emptyField, 'Link'));
        }


        const linkItem = {
			link: linkObj.edit.link.toLowerCase().trim(),
			oldLink: linkObj.link,
			matchType: linkObj.edit.matchType,
			description: linkObj.edit.description,
			created: new Date()
		};
        if (this.currentLists.links_blacklist.some(itm => itm.link === linkObj.edit.link && itm.link !== linkObj.link)) {
            this.ns.showErrorMessage(util.format(type === DICTIONARY.CONSTANTS.configTpAction.linksBlacklist ?
                DICTIONARY.ERRORS.linkAlreadyExist :
                DICTIONARY.ERRORS.configWhiteBlack, linkObj.edit.link));
            return;
        }
        if (this.currentLists.links_whitelist.some(itm => itm.link === linkObj.edit.link && itm.link !== linkObj.link)) {
            this.ns.showErrorMessage(util.format(type === DICTIONARY.CONSTANTS.configTpAction.linksWhitelist ?
                DICTIONARY.ERRORS.linkAlreadyExist :
                DICTIONARY.ERRORS.configWhiteBlack, linkObj.edit.link));
            return;
        }

        let data = {
            type: type,
            links: [linkItem],
            action: DICTIONARY.CONSTANTS.inboundConfigurationsActions.edit
        };
		linkObj.confirmEditInProcess = true;
        const promise = this.getTpRoute(data);
        promise.then(response => {
            linkObj = Object.assign(linkObj,linkObj.edit);
            linkObj.created = new Date();
            linkObj.updated_by = this.reviewerInfo.email;
			linkObj.confirmEditInProcess = false;
            linkObj.isEditMode = false;
            linkObj.edit = null;
            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemUpdated, linkObj.link));
        }, err => {
			linkObj.confirmEditInProcess = false;
		});
    };

    removeLinks = (links, type) => {
        if (!links || !links.length) {
            return;
        }

        let title = 'Remove links';
        switch (type) {
            case DICTIONARY.CONSTANTS.configTpAction.linksWhitelist:
                title += ' from allowlist';
                break;

            case DICTIONARY.CONSTANTS.configTpAction.linksBlacklist:
                title += ' from blocklist';
                break;
        }

        let linksFlat = _.map(links, 'link');
        const linksRemovedText = linksFlat.length === 1 ? linksFlat[0] : linksFlat.length + ' links';

		this.removeFromAllAdmins = false;
		const body = [];
		if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
			body.push({
				labelName: 'Remove from all admins',
				toggle: this.removeFromAllAdmins
			});
		}

        this.gs.showPopup({
            title: title,
            subTitle: `Are you sure you want to remove ${linksRemovedText}?`,
			body: body,
            type: DICTIONARY.CONSTANTS.popupToggle,
            doneBtnText: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove,
            doneCb: (options) => {
                let data = {
                    type: type,
                    links: links,
					removeFromAllAdmins: this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser && options[0].toggle,
					action: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove
                };

                const promise = this.getTpRoute(data);
                this.removeLinkInProcess = true;

                promise.then(() => {
                    switch (type) {
                        case DICTIONARY.CONSTANTS.configTpAction.linksWhitelist:
                            _.remove<any>(this.currentLists.links_whitelist, l => linksFlat.includes(l.link));
                            break;

                        case DICTIONARY.CONSTANTS.configTpAction.linksBlacklist:
                            _.remove<any>(this.currentLists.links_blacklist, l => linksFlat.includes(l.link));
                            break;
                    }
                    this.removeLinkInProcess = false;
                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemRemoved, linksRemovedText));
                });
            }
        });
    };

    uploadSendersWhitelistFromFile = (file) => this.importItemsFromCSV(file,  DICTIONARY.CONSTANTS.configTpAction.whitelist);
    uploadSendersBlacklistFromFile = (file) => this.importItemsFromCSV(file, DICTIONARY.CONSTANTS.configTpAction.blacklist);
    uploadLinksWhitelistFromFile = (file) => this.importItemsFromCSV(file, DICTIONARY.CONSTANTS.configTpAction.linksWhitelist);
    uploadLinksBlacklistFromFile = (file) => this.importItemsFromCSV(file, DICTIONARY.CONSTANTS.configTpAction.linksBlacklist);
    uploadAttachmentsWhitelistFromFile = (file) => this.importItemsFromCSV(file, DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist);
    uploadAttachmentsBlacklistFromFile = (file) => this.importItemsFromCSV(file, DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist);


    importItemsFromCSV = (file, type) => {
        if (!file) return;
        let header, regex, originalList, oppositeList, optionalHeaders = null;
        const validationFuncAlwaysTrue = () => {return true};
        let validationFunctions = [];
        switch (type) {
            case DICTIONARY.CONSTANTS.configTpAction.whitelist:
                header = 'sender';
                optionalHeaders = ['match type', 'description'];
				validationFunctions.push(validationFuncAlwaysTrue);
				originalList = _.map(this.currentLists.senders_whitelist, 'email');
                oppositeList = _.map(this.currentLists.senders_blacklist, 'email');
                break;

            case DICTIONARY.CONSTANTS.configTpAction.blacklist:
                header = 'sender';
                optionalHeaders = ['match type', 'description'];
				validationFunctions.push(validationFuncAlwaysTrue);
				originalList = _.map(this.currentLists.senders_blacklist, 'email');
                oppositeList = _.map(this.currentLists.senders_whitelist, 'email');
                break;

            case DICTIONARY.CONSTANTS.configTpAction.linksWhitelist:
                header = 'link';
                optionalHeaders = ['match type', 'description'];
                validationFunctions.push(validationFuncAlwaysTrue);
                originalList = _.map(this.currentLists.links_whitelist, 'link');
                oppositeList = _.map(this.currentLists.links_blacklist, 'link');
                break;

            case DICTIONARY.CONSTANTS.configTpAction.linksBlacklist:
                header = 'link';
                optionalHeaders = ['match type', 'description'];
                validationFunctions.push(validationFuncAlwaysTrue);
                originalList = _.map(this.currentLists.links_blacklist, 'link');
                oppositeList = _.map(this.currentLists.links_whitelist, 'link');
                break;

            case DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist:
                header = 'hash';
                optionalHeaders = ['name'];
                validationFunctions.push(this.gs.isValidHash);
                originalList = _.map(this.currentLists.attachments_whitelist, 'hash');
                oppositeList = _.map(this.currentLists.attachments_blacklist, 'hash');
                break;

            case DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist:
                header = 'hash';
                optionalHeaders = ['name'];
                validationFunctions.push(this.gs.isValidHash);
                originalList = _.map(this.currentLists.attachments_blacklist, 'hash');
                oppositeList = _.map(this.currentLists.attachments_whitelist, 'hash');
                break;
        }
        this.gs.readCsv(file, [header], optionalHeaders, regex, validationFunctions, (err, results) => {
            if (err) {
                return;
            }
            if (!results || !results[header] || !results[header].length) {
                this.ns.showWarnMessage(DICTIONARY.ERRORS.noNewResult);
                return;
            }

            let data:any = {
                action: DICTIONARY.CONSTANTS.inboundConfigurationsActions.add,
                type: type,
                items: []
            };

            switch (type) {
                case DICTIONARY.CONSTANTS.configTpAction.whitelist:
                case DICTIONARY.CONSTANTS.configTpAction.blacklist:
                    data.items = [];
                    for (let i = 0; i < results[header].length; i++) {
                        let item:any = {updated_by: this.reviewerInfo.email, value: results[header][i]};

                        if (originalList.includes(item.value)) {
                            this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emailDomainAlreadyExist, item.value));
							continue;
                        }
                        if (oppositeList.includes(item.value)) {
                            this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.configWhiteBlack, item.value));
							continue;
                        }

						if (results['match type'] && results['match type'].length) {
							item.matchType = results['match type'][i];
						}
						if (results['description'] && results['description'].length) {
							item.description = results['description'][i];
						}

                        data.items.push(item);
                    }

                    if (!data.items.length) {
                        this.resultFile = null;
                        this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.noNewDomainsAddressesLinks, 'domains/addresses/ips'));
                        return;
                    }
                    break;

                case DICTIONARY.CONSTANTS.configTpAction.linksWhitelist:
                case DICTIONARY.CONSTANTS.configTpAction.linksBlacklist:
                    data.links = [];
                    for (let i = 0; i < results[header].length; i++) {
                        let item:any = {link: results['link'][i], matchType: DICTIONARY.CONSTANTS.ruleMatchType.exactMatch, created: new Date().toISOString(), updated_by: this.reviewerInfo.email};
                        item.link = item.link.toLowerCase();

                        if (results['description'] && results['description'].length) {
                            item.description = results['description'][i];
                        }

                        if (results['match type'] && results['match type'].length) {
                            item.matchType = results['match type'][i];
                        }

                        if (originalList.includes(item.link)) {
                            this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emailDomainAlreadyExist, item.link));
							continue;
                        }
                        if (oppositeList.includes(item.link)) {
                            this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.configWhiteBlack, item.link));
							continue;
                        }
                        data.links.push(item);
                    }

                    if (!data.links.length) {
                        this.resultFile = null;
                        this.linksBlacklistSubmitted = false;
                        this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.noNewDomainsAddressesLinks, 'links'));
                        return;
                    }
                    break;

                case DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist:
                case DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist:
                    data.attachments = [];
                    for (let i = 0; i < results[header].length; i++) {
                        let item:any = {hash: results['hash'][i], created: new Date().toISOString(), updated_by: this.reviewerInfo.email};

                        if (results['name'] && results['name'].length) {
                            item.name = results['name'][i];
                        }

                        if (originalList.includes(item.hash)) {
                            this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emailDomainAlreadyExist, item.hash));
							continue;
                        }
                        if (oppositeList.includes(item.hash)) {
                            this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.configWhiteBlack, item.hash));
							continue;
                        }
                        data.attachments.push(item);
                    }
                    break;

                default:
                    this.resultsToUpload = [];
                    for (let i = 0; i < results[header].length; i++) {
                        if (!originalList.includes(results[header][i])) {
                            this.resultsToUpload.push(results[header][i]);
                        }
                    }
            }

            this.importItemsFromCsvExecute(data);
        });
    };

    importItemsFromCsvExecute = (data) => {
        const promise = this.getTpRouteForCsv(data);

        promise.then(response => {
            switch (data.type) {
                case DICTIONARY.CONSTANTS.configTpAction.whitelist:
                    _.each(data.items, itemObj => {
                        this.currentLists.senders_whitelist.push({email: itemObj.value, description: itemObj.description, matchType: itemObj.matchType || DICTIONARY.CONSTANTS.ruleMatchType.exactMatch, created: new Date().toISOString(), updated_by: this.reviewerInfo.email});
                    });
                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, 'Domains/Addresses'));
                    break;

                case DICTIONARY.CONSTANTS.configTpAction.blacklist:
                    _.each(data.items, itemObj => {
                        this.currentLists.senders_blacklist.push({email: itemObj.value, description: itemObj.description, matchType: itemObj.matchType || DICTIONARY.CONSTANTS.ruleMatchType.exactMatch, created: new Date().toISOString(), updated_by: this.reviewerInfo.email});
                    });
                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, 'Domains/Addresses'));
                    break;

                case DICTIONARY.CONSTANTS.configTpAction.linksWhitelist:
                    _.each(data.links, linkObj => {
                        this.currentLists.links_whitelist.push(linkObj);
                    });
                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, 'Links'));
                    break;

                case DICTIONARY.CONSTANTS.configTpAction.linksBlacklist:
                    _.each(data.links, linkObj => {
                        this.currentLists.links_blacklist.push(linkObj);
                    });
                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, 'Links'));
                    break;

                case DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist:
                    _.each(data.attachments, attachmentObj => {
                        this.currentLists.attachments_whitelist.push(attachmentObj);
                    });
                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, 'Attachments'));
                    break;

                case DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist:
                    _.each(data.attachments, attachmentObj => {
                        this.currentLists.attachments_blacklist.push(attachmentObj);
                    });
                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, 'Attachments'));
                    break;
            }
            this.resultFile = null;

        }, err => {});
    };


    searchSendersWhitelist = (searchTerm, activeFilters) => this.searchThreatList(searchTerm,DICTIONARY.CONSTANTS.configTpAction.whitelist);
    searchSendersBlacklist = (searchTerm, activeFilters) => this.searchThreatList(searchTerm,DICTIONARY.CONSTANTS.configTpAction.blacklist);
    searchLinksWhitelist = (searchTerm, activeFilters) => this.searchThreatList(searchTerm,DICTIONARY.CONSTANTS.configTpAction.linksWhitelist);
    searchLinksBlacklist = (searchTerm, activeFilters) => this.searchThreatList(searchTerm,DICTIONARY.CONSTANTS.configTpAction.linksBlacklist);
    searchAttachmentsWhitelist = (searchTerm, activeFilters) => this.searchThreatList(searchTerm,DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist);
    searchAttachmentsBlacklist = (searchTerm, activeFilters) => this.searchThreatList(searchTerm,DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist);

    searchThreatList = (searchText, type) => {
        let list;
        switch (type) {
            case DICTIONARY.CONSTANTS.configTpAction.whitelist:
                list = this.currentLists.senders_whitelist;
                break;

            case DICTIONARY.CONSTANTS.configTpAction.blacklist:
                list = this.currentLists.senders_blacklist;
                break;

            case DICTIONARY.CONSTANTS.configTpAction.linksWhitelist:
                list = this.currentLists.links_whitelist;
                break;

            case DICTIONARY.CONSTANTS.configTpAction.linksBlacklist:
                list = this.currentLists.links_blacklist;
                break;

            case DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist:
                list = this.currentLists.attachments_whitelist;
                break;

            case DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist:
                list = this.currentLists.attachments_blacklist;
                break;
        }

		if (list) {
			list.forEach(record => {
				// search
				if (searchText) {
					const isFound = this.searchTextExecute(type, record, searchText);
					if (!isFound) {
						record.hide = true;
						return;
					}
				}

				// filter
				// // currently no filters for all tables in cfg page

				record.hide = false;
			});
		}
    };

    uploads = (files, isWhitelist) => {
        if (files && files.length) {
            let file = files[0];
            file.isValid = this.gs.checkUploadFileSize(file.size, file.name);
            if (file.isValid) {
                this.gs.fileHash(file, (fileHash) => {
                    file.hash = fileHash;
                    this.addNewAttachment(file, isWhitelist, (err) => {
                        files.splice(0, 1);
                        if (files.length) {
                            this.uploads(files, isWhitelist);
                        }
                    });
                });
            }
        }
    };

    confirmNewWhitelistAttachment = (attachment, isApproved) => this.confirmNewAttachment(attachment, isApproved, true);
    confirmNewBlacklistAttachment = (attachment, isApproved) => this.confirmNewAttachment(attachment, isApproved, false);

    confirmNewAttachment = (attachment, isApproved, isWhitelist) => {
        // new attachment process was declined
        if (!isApproved) {
            if (isWhitelist) {
                _.remove<any>(this.currentLists.attachments_whitelist, attachment);
                this.currentLists.attachments_whitelist.isAddRecordActive = false;
            }
            else {
                _.remove<any>(this.currentLists.attachments_blacklist, attachment);
                this.currentLists.attachments_blacklist.isAddRecordActive = false;
            }
            return;
        }
        //

        // new attachment process approved
        // validation
        if (!attachment.edit.hash) {
            return this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.emptyField, 'Hash'));
        }
        if (attachment.edit.hash.length !== 64 && attachment.edit.hash.length !== 32) {
            this.ns.showErrorMessage(DICTIONARY.ERRORS.attachmentHash256Invalid);
            return;
        }

        this.addNewAttachment(attachment, isWhitelist);
    };

    addNewAttachment = (attachmentObj, isWhitelist, cb=null) => {
        // (attachmentObj is "Object" when added manually (so it also has edit), but is "Blob" file when is added through uploading)
        if (attachmentObj.edit) {
            attachmentObj.name = attachmentObj.edit.name;
            attachmentObj.hash = attachmentObj.edit.hash;
            attachmentObj.size = attachmentObj.size || 0;
        }
        else {
            // make the Blob a readable Object
            attachmentObj = {name: attachmentObj.name, size: attachmentObj.size || 0, hash: attachmentObj.hash};
        }

        let type, list;
        if (isWhitelist) {
            if (this.currentLists.attachments_whitelist.some(itm => itm.hash === attachmentObj.hash && !itm.isNew)) {
                this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.attachmentExists, attachmentObj.name));
                return;
            }

            if (this.currentLists.attachments_blacklist.some(itm => itm.hash === attachmentObj.hash && !itm.isNew)) {
                this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.configWhiteBlack, attachmentObj.name));
                return;
            }
            type = DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist;
            list = this.currentLists.attachments_whitelist;
        }
        else {
            if (this.currentLists.attachments_blacklist.some(itm => itm.hash === attachmentObj.hash && !itm.isNew)) {
                this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.attachmentExists, attachmentObj.name));
                return;
            }

            if (this.currentLists.attachments_whitelist.some(itm => itm.hash === attachmentObj.hash && !itm.isNew)) {
                this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.configWhiteBlack, attachmentObj.name));
                return;
            }
            type = DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist;
            list = this.currentLists.attachments_blacklist;
        }

        const data = {
            type: type,
            attachments: [attachmentObj],
            action: DICTIONARY.CONSTANTS.inboundConfigurationsActions.add
        };
		attachmentObj.confirmEditInProcess = true;
        this.getTpRoute(data, {attachment: attachmentObj}).then(response => {
            attachmentObj.created = new Date().toISOString();
            attachmentObj.updated_by = this.reviewerInfo.email;
            attachmentObj.edit = null;
			attachmentObj.confirmEditInProcess = false;
            attachmentObj.isEditMode = false;
            attachmentObj.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit

            if (!_.find(list, attachmentObj)) {
                list.unshift(attachmentObj);
            }

            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, attachmentObj.name));
            list.isAddRecordActive = false;
            if (cb) {
                cb();
            }
        }, (err) => {
			attachmentObj.confirmEditInProcess = false;
            list.isAddRecordActive = false;
            if (cb) {
                cb(err);
            }
        });

    }

    removeAttachments = (attachments, type) => {
        if (!attachments || !attachments.length) {
            return;
        }

        let title = 'Remove attachments';
        switch (type) {
            case DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist:
                title += ' from allowlist';
                break;

            case DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist:
                title += ' from block';
                break;
        }

        const attachmentsRemovedText = attachments.length === 1 ? attachments[0].name : attachments.length + ' attachments';

		this.removeFromAllAdmins = false;
		const body = [];
		if (this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
			body.push({
				labelName: 'Remove from all admins',
				toggle: this.removeFromAllAdmins
			});
		}

        this.gs.showPopup({
            title: title,
            subTitle: `Are you sure you want to remove ${attachmentsRemovedText}?`,
			body: body,
            type: DICTIONARY.CONSTANTS.popupToggle,
            doneBtnText: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove,
            doneCb: (options) =>{
                let data = {
                    type: type,
                    attachments: attachments,
					removeFromAllAdmins: this.isPartnerAdmin && this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser && options[0].toggle,
					action: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove
                };

                attachments = _.map(attachments, 'hash');
                const promise = this.getTpRoute(data);

                promise.then(() => {
                    switch (type) {
                        case DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist:
                            _.remove<any>(this.currentLists.attachments_whitelist, t => attachments.includes(t.hash));
                            break;

                        case DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist:
                            _.remove<any>(this.currentLists.attachments_blacklist, t => attachments.includes(t.hash));
                            break;
                    }

                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemRemoved, attachmentsRemovedText));
                });
            }
        });
    };

    changeListsConfig = (configLists) => {
        //copy only these 6 lists because known_domains and rules are only for the Plan-Admin
        this.currentLists = {
            senders_whitelist: configLists?.senders_whitelist,
            senders_blacklist: configLists?.senders_blacklist,
            links_whitelist: configLists?.links_whitelist,
            links_blacklist: configLists?.links_blacklist,
            attachments_whitelist: configLists?.attachments_whitelist,
            attachments_blacklist: configLists?.attachments_blacklist
        };

    };

    changeUser = (userObj) => {
        if (this.currentUser === userObj.email) return;

        this.currentUser = userObj.email;
        if (this.currentUser === DICTIONARY.CONSTANTS.planDefaultUser) {
            this.changeListsConfig(this.config.threat_protection);

            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.displayConfigurationsForUser, this.currentUser));
            return;
        }


        if (userObj.misc) {
            this.changeListsConfig(userObj.misc.threats_personalization);
            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.displayConfigurationsForUser, this.currentUser));
            return;
        }

        this.rs.getTPConfigUsers(userObj.email).then((response) => {
            userObj.misc = response.misc;
            this.changeListsConfig(userObj?.misc?.threats_personalization);
            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.displayConfigurationsForUser, this.currentUser));
        });
    };

    exportAllListsToCsv = () => {
        if (this.exportAllListsToCsvInProcess) {
            return;
        }
        this.exportAllListsToCsvInProcess = true;

        let csvString = ["User, Type, Item, Description, Updated"];

        if (this.currentUser !== DICTIONARY.CONSTANTS.planDefaultUser) {
            this.prepareUserCsv(this.currentLists, {email: this.currentUser}, csvString);
            this.exportAllListsToCsvInProcess = false;
            this.gs.exportCsv(csvString.join('\n'), `configuration_lists_${this.currentUser}.csv`);
            return;
        }

        this.getAllMailboxesListData(csvString);
    };

    getAllMailboxesListData = (csvString) => {
		const notIdx = this.ns.showInfoMessage(`Going to generate user report for ${this.planUsers.length} users. This action can take a while`, {timeout: 0});

		let count = 0;
		eachLimit(this.planUsers, 100, (userObj, next) => {
			count++;
			this.ns.overwriteMessage(notIdx, `${count} / ${this.planUsers.length} user lists processed`);
			if (userObj.email === DICTIONARY.CONSTANTS.planDefaultUser) {
				this.prepareUserCsv(this.config.threat_protection, userObj, csvString);
				return next();
			}

			if (userObj.misc) {
				this.prepareUserCsv(userObj.misc.threats_personalization, userObj, csvString);
				return next();
			}

			this.rs.getTPConfigUsers(userObj.email).then((response) => {
				if (response.misc) {
					userObj.misc = response.misc;
					this.prepareUserCsv(userObj.misc.threats_personalization, userObj, csvString);
				}
				return next();
			});
		}, err => {
			this.exportAllListsToCsvInProcess = false;
			this.gs.exportCsv(csvString.join('\n'), `configuration_lists.csv`);
			this.ns.overwriteMessage(notIdx, `Report is ready`);
		});
    }

    prepareUserCsv = (userLists, userObj, csvString) => {
        userLists.senders_whitelist.forEach((itmObj) => {
            if (itmObj.isNew) {return;}
            csvString.push(`${userObj.email}, Senders Allowlist, ${itmObj.email || ''}, ${itmObj.description || ''}, ${itmObj.created || ''}`);
        });
        userLists.senders_blacklist.forEach((itmObj) => {
            if (itmObj.isNew) {return;}
            csvString.push(`${userObj.email}, Senders Blocklist, ${itmObj.email || ''}, ${itmObj.description || ''}, ${itmObj.created || ''}`);
        });

        userLists.links_whitelist.forEach((itmObj) => {
            if (itmObj.isNew) {return;}
            let link = itmObj.link;
            csvString.push(`${userObj.email}, Links Allowlist, ${link || ''}, ${itmObj.description || ''}, ${itmObj.created || ''}`);
        });
        userLists.links_blacklist.forEach((itmObj) => {
            if (itmObj.isNew) {return;}
            let link = itmObj.link;
            csvString.push(`${userObj.email}, Links Blocklist, ${link || ''}, ${itmObj.description || ''}, ${itmObj.created || ''}`);
        });

        userLists.attachments_whitelist.forEach((itmObj) => {
            if (itmObj.isNew) {return;}
            csvString.push(`${userObj.email}, Attachments Allowlist, ${itmObj.name || itmObj.hash}, ${itmObj.description || ''}, ${itmObj.created || ''}`);
        });
        userLists.attachments_blacklist.forEach((itmObj) => {
            if (itmObj.isNew) {return;}
            csvString.push(`${userObj.email}, Attachments Blocklist, ${itmObj.name || itmObj.hash}, ${itmObj.description || ''}, ${itmObj.created || ''}`);
        });
    }

	exportSendersWhitelistToCsv = (sortBy) => { this.exportSendersListToCsv(this.currentLists.senders_whitelist, true, sortBy); }
	exportSendersBlacklistToCsv = (sortBy) => { this.exportSendersListToCsv(this.currentLists.senders_blacklist, false, sortBy); }

	exportLinksWhitelistToCsv = (sortBy) => this.exportLinkslistToCsv(this.currentLists.links_whitelist, true, sortBy);
	exportLinksBlacklistToCsv = (sortBy) => this.exportLinkslistToCsv(this.currentLists.links_blacklist, false, sortBy);

	exportAttachmentsWhitelistToCsv = (sortBy) => { this.exportAttachmentListToCsv(this.currentLists.attachments_whitelist, true, sortBy); }
	exportAttachmentsBlacklistToCsv = (sortBy) => { this.exportAttachmentListToCsv(this.currentLists.attachments_blacklist, false, sortBy); }

	exportLinkslistToCsv = (list, isWhiteList, sortBy) => {
		if (!list.length) {
			return;
		}

		let sortedTable = _.filter(list,record => {return !record.hide && !record.isNew});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		let csvString = "link, match type, description, updated by, updated\n";

		sortedTable.forEach((item) => {
			if (item.description && /^[\r\t@+=-]/.test(item.description)) {
				item.description = ' ' + item.description + ' ';
			}
			csvString += `${item.link},"${item.matchType || ''}","${item.description || ''}",${item.updated_by || ''}, ${item.created || ''}\n`;
		});

		this.gs.exportCsv(csvString, `configuration_links_${isWhiteList ? 'allow': 'block'}list.csv`);
	}

	exportSendersListToCsv = (list, isWhiteList, sortBy) => {
		if (!list.length) {
			return;
		}

		let sortedTable = _.filter(list,record => {return !record.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		let csvString = "sender, match type, description, updated by, updated, last used\n";

		sortedTable.forEach((item) => {
			if (item.description && /^[\r\t@+=-]/.test(item.description)) {
				item.description = ' ' + item.description + ' ';
			}
			csvString += `${item.email},"${item.matchType || ''}",` + (item.description ? `"${item.description}"` : '') + `,${item.updated_by || ''}, ${item.created || ''}, ${item.last_used || ''}\n`;
		});

		this.gs.exportCsv(csvString, `configuration_senders_${isWhiteList ? 'allow' : 'block'}list.csv`);
	};

	exportAttachmentListToCsv = (list, isWhiteList, sortBy) => {
		if (!list.length) {
			return;
		}

		let sortedTable = _.filter(list,record => {return !record.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		let csvString = "name,hash,updated by,updated\n";

		sortedTable.forEach((item) => {
			csvString += `${item.name || ''},"${item.hash}",${item.updated_by || ''}, ${item.created || ''}\n`;
		});

		this.gs.exportCsv(csvString, `configuration_attachments_${isWhiteList ? 'allow' : 'block'}list.csv`);
	};


    showRecipientConfig = () => {
		this.recipientConfigPopup = {
			show: true,
			applyInProcess: false,
			allowRecipientToggle: this.config.threat_protection.whitelist_allow_recipient,
			doneCb: () => {
				this.recipientConfigPopup.applyInProcess = true;
				const whitelistSettings = {
					type: DICTIONARY.CONSTANTS.configTpAction.recipientSettings,
					whitelist_allow_recipient: this.recipientConfigPopup.allowRecipientToggle,
				};
				this.rs.updateTpConfig(whitelistSettings).then(() => {
					this.config.threat_protection.whitelist_allow_recipient = whitelistSettings.whitelist_allow_recipient;
					this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
					this.recipientConfigPopup.applyInProcess = false;
					this.recipientConfigPopup.show = false;
				}, (err) => {
					this.recipientConfigPopup.applyInProcess = false;
				});
			}
		};
    };

    showWhiteListConfig = () => {
		this.whiteListConfigPopup = {
			show: true,
			applyInProcess: false,

			blockMaliciousLinks: this.config.threat_protection.whitelist_block_malicious_links,
			blockMaliciousFiles: this.config.threat_protection.whitelist_block_malicious_files,
			blockFilesUserPreferences: this.config.threat_protection.whitelist_block_files_user_preferences,
			allSourcesWhitelist: this.config.threat_protection.allow_all_sources_whitelist,
			recipientEmailQuarantined: this.config.threat_protection.whitelist_notify_recipient_email_quarantined,

			doneCb: () => {
				this.whiteListConfigPopup.applyInProcess = true;
				const whitelistSettings = {
					type: DICTIONARY.CONSTANTS.configTpAction.sendersWhitelistAdvanced,
					whitelist_block_malicious_links: this.whiteListConfigPopup.blockMaliciousLinks,
					whitelist_block_malicious_files: this.whiteListConfigPopup.blockMaliciousFiles,
					whitelist_block_files_user_preferences: this.whiteListConfigPopup.blockFilesUserPreferences,
					allow_all_sources_whitelist: this.whiteListConfigPopup.allSourcesWhitelist,
					whitelist_notify_recipient_email_quarantined: this.whiteListConfigPopup.recipientEmailQuarantined
				};
				this.rs.updateTpConfig(whitelistSettings).then(() => {
					this.config.threat_protection.whitelist_block_malicious_links = whitelistSettings.whitelist_block_malicious_links;
					this.config.threat_protection.whitelist_block_malicious_files = whitelistSettings.whitelist_block_malicious_files;
					this.config.threat_protection.whitelist_block_files_user_preferences = whitelistSettings.whitelist_block_files_user_preferences;
					this.config.threat_protection.allow_all_sources_whitelist = whitelistSettings.allow_all_sources_whitelist;
					this.config.threat_protection.whitelist_notify_recipient_email_quarantined = whitelistSettings.whitelist_notify_recipient_email_quarantined;

					this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
					this.whiteListConfigPopup.applyInProcess = false;
					this.whiteListConfigPopup.show = false;
				}, (err) => {
					this.whiteListConfigPopup.applyInProcess = false;
				});
			}
		};
    };

    searchTextExecute(listType, record, searchTerm) {
        searchTerm = searchTerm.toLowerCase();

        if (record.description && record.description.toLowerCase().indexOf(searchTerm) >= 0) {
            return true;
        }

        switch (listType) {
            case DICTIONARY.CONSTANTS.configTpAction.whitelist:
                return (record.email && record.email.toLowerCase().indexOf(searchTerm) >= 0);

            case DICTIONARY.CONSTANTS.configTpAction.blacklist:
                return (record.email &&record.email.toLowerCase().indexOf(searchTerm) >= 0);

            case DICTIONARY.CONSTANTS.configTpAction.linksWhitelist:
                return ((record.link && record.link.toLowerCase().indexOf(searchTerm) >= 0) ||
                    (record.matchType && record.matchType.toLowerCase().indexOf(searchTerm) >= 0));

            case DICTIONARY.CONSTANTS.configTpAction.linksBlacklist:
                return ((record.link && record.link.toLowerCase().indexOf(searchTerm) >= 0) ||
                    (record.matchType && record.matchType.toLowerCase().indexOf(searchTerm) >= 0));

            case DICTIONARY.CONSTANTS.configTpAction.attachmentsWhitelist:
                return (record.name && record.name.toLowerCase().indexOf(searchTerm) >= 0) ||
					(record.hash && record.hash.toLowerCase().indexOf(searchTerm) >= 0 );

            case DICTIONARY.CONSTANTS.configTpAction.attachmentsBlacklist:
                return (record.name && record.name.toLowerCase().indexOf(searchTerm) >= 0) ||
					(record.hash && record.hash.toLowerCase().indexOf(searchTerm) >= 0 );

            case 'sources':
                return ((record.authenticationResults.headerFrom && record.authenticationResults.headerFrom.toLowerCase().indexOf(searchTerm) >= 0) ||
                    (record.authenticationResults.mailFrom && record.authenticationResults.mailFrom.toLowerCase().indexOf(searchTerm) >= 0) ||
                    (record.receivedSPF.domain && record.receivedSPF.domain.toLowerCase().indexOf(searchTerm) >= 0) ||
                    (record.sendingMTA.domain && record.sendingMTA.domain.toLowerCase().indexOf(searchTerm) >= 0) ||
                    (record.messageID.domain && record.messageID.domain.toLowerCase().indexOf(searchTerm) >= 0));
        }
    }
}
