<div class="flex-column f-1-0 gap-4">

	<div class="flex-column gap-2">
		<span>Customize the handling of incoming emails based on your predefined conditions. The inbound rules are subsequent to the threat prevention rules.</span>
		<span>Automated processes such as Auto Forward, Auto Reply, and Redirect functionalities are not applicable to API protection mailboxes.</span>
	</div>

	<div class="flex-column f-1-0">
		<!--Page loader-->
		<div class="flex-row justify-content-center f-1-0" *ngIf="getRulesDataInProcess">
			<loader height="100" width="100" style="opacity: 0.8;"></loader>
		</div>
		<!--Create first rule-->
		<div class="flex-row justify-content-center f-1-0" *ngIf="!getRulesDataInProcess && !createNewRulesInProcess && !config?.inbound_email_rules?.length">
			<div class="flex-column align-items-center f-1-0 text-center gap-3 px-4 py-5 bg-light" style="align-self: flex-start;">
				<h1 style="opacity:0.4; font-size: 36px;">Create your first rule</h1>
				<span>Rules choose what to do with sent emails according to conditions you set up.<br/> Rules can be turned off.</span>
				<br/>
				<btn-c id="newRule" class="popup-action-btn"
					   [isDisabled]="!config?.inbound_email_rules"
					   endIcon="fa fa-plus"
					   (action)="createNewRule();">
					Create New Rule
				</btn-c>
			</div>
		</div>

		<div class="flex-column gap-1 f-1-0 animated fadeIn" *ngIf="!getRulesDataInProcess && !createNewRulesInProcess && config?.inbound_email_rules?.length">
			<btn-c id="newRule"
				   [isDisabled]="!config?.inbound_email_rules"
				   endIcon="fa fa-plus"
				   (action)="createNewRule();">
				Create New Rule
			</btn-c>

			<!--rules-->
			<trustifi-table-c
				class="trustifi-table" style="flex: 1 0; z-index: 5; background: white;"
				[list]="config?.inbound_email_rules"
				[headers]="[
					{text: 'Priority', width: '80px'},
					{text: 'Name', width: '15%' },
					{text: 'Active', width: '140px' },
					{text: 'Condition', width: '30%'},
					{text: 'Action to perform', width: '20%'},
					{text: 'Last Modified', width: '135px'},
					{text: '', width: '70px'},
				]"
				[cellsPrototype]="[
					{textKey: 'priorityDisplay'},
					{html: 'nameCell'},
					{html: 'statusCell', overflowOutside: true},
					{html: 'conditionsCell'},
					{html: 'actionCell'},
					{textKey: 'created', textType: 'dateAndTime'},
					{html: 'usageCell'},
				]"
				[cellActions]="{
					enable: true,
					selectItemActionCb:	selectRuleAction,
					initActionsFunction: showRuleActions,
				}"
				[bulkActions]="{
									enable: true,
									selectBulkActionCb:	selectMultipleRulesAction,
									initBulkActionsFunction: showMultipleRulesActions,
								}"
				[options]="{
					hideContentAboveTable: true,
					itemsNameSingular: 'rule',
					itemUniqueIdentifier: '_id',
					isEllipsis: false
				}"
				(onDragAndDrop)="!$event.isDroppedOnNextItem && updateRulePriority($event.draggedItem, $event.draggedOntopItem)"
			>
				<ng-template #customCells let-item let-cellId="cellId">
					<!--name cells-->
					<span *ngIf="cellId === 'nameCell'">
						<span>{{item.name}}</span>
					</span>
					<!--active cells-->
					<div *ngIf="cellId === 'statusCell'">
						<toggle-switch-c [(model)]="item.enabled"
										 textTrue="Active" textFalse="Inactive"
										 (onChange)="updateRuleState([item], item.enabled)">
						</toggle-switch-c>
					</div>
					<!--condition details cells-->
					<span class="flex-column gap-2" *ngIf="cellId === 'conditionsCell'">
						<div class="flex-row w100 gap-2" *ngFor="let conditionArr of item.conditions; let first = first;">
							<span class="fw-bold flex-shrink-0" style="flex-basis: 65px; color: #486db5;">{{first ? 'IF' : 'AND'}}</span>
							<div class="flex-column gap-1" style="word-break: break-word;">
								<ng-container *ngFor="let condition of conditionArr; let last = last;">
									<span [innerHTML]="condition.text"></span>
									<span class="fw-bold opacity-75" style="color: #486db5; margin: -4px 0;" *ngIf="!last">OR</span>
								</ng-container>
							</div>
						</div>
						<ng-container *ngIf="item.exceptions?.length">
						<div class="flex-row w100 gap-1" *ngFor="let exceptionArr of item.exceptions; let first = first;">
							<span class="fw-bold flex-shrink-0" style="flex-basis: 65px; color: #906b24;">{{first ? 'EXCEPT IF' : 'AND'}}</span>
							<div class="flex-column gap-1" style="word-break: break-word;">
								<ng-container *ngFor="let exception of exceptionArr; let last = last;">
									<span [innerHTML]="exception.text"></span>
									<span class="fw-bold opacity-75" style="color: #486db5; margin: -4px 0;" *ngIf="!last">OR</span>
								</ng-container>
							</div>
						</div>
						</ng-container>
					</span>
					<!--action details cells-->
					<div class="flex-column gap-1" style="font-size: 12px;" *ngIf="cellId === 'actionCell'">
						<span [innerHTML]="item.resultShow.join('<br/>')"></span>
					</div>
					<!--usage cells-->
					<span *ngIf="cellId === 'usageCell'">
						<div class="flxRow-center app-dropdown" style="padding: 2px min(1vw, 15px); height: 30px; cursor: pointer; margin-left: 3px; align-items: center;"
							 (click)="showRulesUsage(item); $event.stopPropagation();" tooltip="Usage data">
							<i class="fas hvr-opacity fa-chart-pie"
							   style="font-size: 1.5rem; background-image: conic-gradient(green 0deg 90deg, purple 90deg 135deg, blue 162deg 365deg); color: transparent; background-clip: text; -webkit-background-clip: text;"></i>
						</div>
					</span>
				</ng-template>
			</trustifi-table-c>
		</div>

		<div class="flex-column w-100 gap-2 py-2 pe-2" *ngIf="createNewRulesInProcess">
			<b>{{!!newRule._id ? 'Edit Rule' : 'Create New Rule'}}</b>

			<!--Rule name-->
			<div class="flex-row w-100 gap-3 align-items-center my-3">
				<span class="flex-shrink-0" style="flex-basis: 100px; text-align: right;">Rule name:</span>
				<div class="f-1-0 flex-row gap-4">
					<input id="outboundRuleName" type="text" placeholder="Rule name" style="width: 235px;"
						   autocomplete="off"
						   [appAutoFocus]="true"
						   [ngClass]="(invalidRuleName) && 'validation-error'"
						   [(ngModel)]="newRule.name"
						   (ngModelChange)="isNameChanged();"/>
				</div>
			</div>

			<!--Rule conditions-->
			<div class="flex-column gap-2">
				<div class="flex-row w-100 gap-3" *ngFor="let condition of newRule.conditions; let index = index; let first = first">
					<span class="fw-bold flex-shrink-0" style="flex-basis: 100px; text-align: right; font-size: 17px; color: #486db5; padding-top: 20px;">
						{{first ? 'IF' : 'AND'}}
					</span>
					<div class="flex-row gap-2 f-1-0 px-4 flex-wrap align-items-end" style="border: dashed 1px #ccc; max-width: 1200px; padding-top: 15px; padding-bottom: 15px;">
						<new-rule-condition-inbound style="display: contents;"
													[conditionIndex]="index" [condition]="condition"
													[newRule]="newRule"
													[fileTypes]="fileTypes"
													[tpMetrics]="tpMetrics">
						</new-rule-condition-inbound>
						<a class="blue-link fw-bold flex-row align-items-center gap-1 flex-shrink-0" style="color: #486db5; padding: 8px 15px; margin: 8px -10px -15px auto;"
						   (click)="addORtoCondition(condition)">
							<i class="fa fa-plus" style="font-size: 9px;"></i>
							<span>Add 'OR' to condition</span>
						</a>
					</div>
				</div>
				<div class="flex-row w-100 gap-3 mt-2">
					<div class="flex-shrink-0" style="flex-basis: 100px;"></div><!--padder-->
					<div class="flex-row gap-2">
						<btn-c id="addRuleConditionBtn" class="rules-add-condition-btn" style="width: 130px;"
							   variant="outlined-dashed" endIcon="fa fa-plus"
							   (action)="addConditionToRule()">
							Add Condition
						</btn-c>
						<btn-c id="addRuleExceptionBtn" class="rules-add-condition-btn" style="width: 130px;"
							   *ngIf="!newRule.exceptions?.length"
							   variant="outlined-dashed" endIcon="fa fa-plus"
							   color="#906b24"
							   (action)="addExceptionToRule()">
							Add Exception
						</btn-c>
					</div>
				</div>
			</div>


			<!--Rule exceptions-->
			<div class="flex-column gap-2 mt-3" *ngIf="newRule.exceptions?.length">
				<div class="flex-row w-100 gap-3" *ngFor="let condition of newRule.exceptions; let index = index; let first = first">
					<span class="fw-bold flex-shrink-0" style="flex-basis: 100px; text-align: right; font-size: 17px; color: #906b24; padding-top: 20px;">
						{{first ? 'EXCEPT IF' : 'AND'}}
					</span>
					<div class="flex-row gap-2 f-1-0 px-4 flex-wrap align-items-end" style="border: dashed 1px #ccc; max-width: 1200px; padding-top: 15px; padding-bottom: 15px;">
						<new-rule-condition-inbound style="display: contents;"
													[conditionIndex]="index" [condition]="condition"
													[newRule]="newRule"
													[fileTypes]="fileTypes"
													[tpMetrics]="tpMetrics">
						</new-rule-condition-inbound>
						<a class="blue-link fw-bold flex-row align-items-center gap-1 flex-shrink-0" style="color: #486db5; padding: 8px 15px; margin: 8px -10px -15px auto;"
						   (click)="addORtoCondition(condition)">
							<i class="fa fa-plus" style="font-size: 9px;"></i>
							<span>Add 'OR' to condition</span>
						</a>
					</div>
				</div>
				<div class="flex-row w-100 gap-3 mt-2">
					<div class="flex-shrink-0" style="flex-basis: 100px;"></div><!--padder-->
					<div class="flex-row gap-2">
						<btn-c id="addRuleExceptionBtn" class="rules-add-condition-btn" style="width: 130px;"
							   variant="outlined-dashed" endIcon="fa fa-plus"
							   color="#906b24"
							   (action)="addExceptionToRule()">
							Add Exception
						</btn-c>
					</div>
				</div>
			</div>

			<div class="flex-row w-100 gap-3">
				<div class="flex-shrink-0" style="flex-basis: 100px;"></div><!--padder-->
				<hr class="f-1-0"/>
			</div>


			<!--results-->
			<div class="flex-column gap-2">
				<div class="flex-row w-100 gap-3">
					<span class="fw-bold flex-shrink-0" style="flex-basis: 100px; text-align: right; font-size: 17px; color: #486db5; padding-top: 20px;">
						THEN
					</span>
					<!--main result-->
					<div class="f-1-0 flex-row gap-2 px-4 align-items-center" style="border: dashed 1px #ccc; max-width: 1200px; padding-top: 10px; padding-bottom: 10px;">
						<app-dropdown-c style="width: 280px; --dropdown-menu-width: 600px;" id="{{'inboundEmailRulesDropdownResult'}}"
										(ngInit)="isResultChanged()"
										[showTitle]="true" [error]="invalidResults"
										text="{{newRule.resultShow.join(', ') || 'Choose'}}">
							<ul style="columns: 2;">
								<li *ngFor="let result of dic.CONSTANTS.inboundEmailRule.defaultResult | keyvalue: gs.returnZero"
									(click)="newRule.result[result.key].enabled = !newRule.result[result.key].enabled; isResultChanged(result);$event.stopPropagation();"
									[explanationC]='result.key === dic.CONSTANTS.inboundEmailRule.defaultResult.quarantine.name &&
																		(newRule.result.auto_forward.enabled || newRule.result.redirect.enabled) ? "You must disable \"Auto Forward\" and \"Redirect\" to enable this option" : dic.CONSTANTS.inboundEmailRule.defaultResult[result.key].explanation'
									[hidden]="result?.value?.hideInResultList"
									[ngClass]="{'disabled': result.key === dic.CONSTANTS.inboundEmailRule.defaultResult.quarantine.name &&
																		(newRule.result.auto_forward.enabled || newRule.result.redirect.enabled)}">
									<div class="gap-1 md-checkbox">
										<input type="checkbox" onclick="return false;" [checked]="newRule.result[result.key].enabled"/>
										<label><span>{{result.value.label}}</span></label>
									</div>
								</li>
							</ul>
						</app-dropdown-c>
						<a class="blue-link fw-bold" *ngIf="newRule?.result?.auto_reply?.enabled"
						   (click)="editMailboxReplySettings()"
						   [ngClass]="invalidAutoReply && 'validation-error'">
							Configure auto reply
						</a>
						<a class="blue-link fw-bold" *ngIf="newRule?.result?.add_warning_label?.enabled"
						   (click)="showWarningLabelPopup()">
							Configure warning label
						</a>
					</div>
				</div>

				<!--other options-->

				<div class="flex-row w-100 gap-3">
					<div class="flex-shrink-0" style="flex-basis: 100px;"></div><!--padder-->
					<div class="flex-column f-1-0 gap-2 py-1 hide-when-empty-force" style="border: dashed 1px #ccc; max-width: 1200px;">
						<!-- Notify reviewer/recipient checkbox -->
						<div class="f-1-0 flex-column gap-2 px-4 py-2" *ngIf="newRule.result.quarantine?.enabled">
							<span style="color: darkorange;" *ngIf="!newRule.result.keep_record?.enabled">
								<i class="fas fa-info-circle"></i> Enable the "Keep record" setting if you want to see quarantine records of emails blocked by this rule
							</span>
							<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
								<input id="NotifyReviewer" type="checkbox"
									   [(ngModel)]="newRule.result.notify_reviewer.enabled"
									   (ngModelChange)="isResultChanged(dic.CONSTANTS.inboundEmailRule.defaultResult.notify_reviewer)"/>
								<label id="inboundRulesResultNotifyReviewer" for="NotifyReviewer" class="m-0">{{newRule.result.notify_reviewer.label}}</label>
								<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
                                   tooltip="{{newRule.result.notify_reviewer.explanation}}">
								</i>
							</div>
							<!-- Notify Recipient checkbox -->
							<div class="f-1-0 flex-row gap-2 py-2 align-items-center">
								<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
									<input id="NotifyRecipient" type="checkbox"
										   [(ngModel)]="newRule.result.notify_recipient.enabled"
										   (ngModelChange)="isResultChanged(dic.CONSTANTS.inboundEmailRule.defaultResult.notify_recipient)"/>
									<label id="inboundRulesResultNotifyRecipient" for="NotifyRecipient" class="m-0">{{newRule.result.notify_recipient.label}}</label>
									<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
									   tooltip="{{newRule.result.notify_recipient.explanation}}">
									</i>
									<input id="inboundRulesResultNotifyMessage"
										   [ngClass]="{'disabled': !newRule.result.notify_recipient.enabled}"
										   type="text" placeholder="Recipient message (optional)" style="margin-left: 10px; width: 200px;"
										   [(ngModel)]="newRule.result.notify_recipient.message" />
								</div>
							</div>
							<div class="flex-row gap-4 align-items-center">
								<!-- recipient - allow release from notification checkbox -->
								<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;"
									 [ngClass]="{'disabled' : !newRule.result.notify_recipient?.enabled}">
									<input id="allowReleaseNotification" type="checkbox"
										   [disabled]="!newRule.result.notify_recipient?.enabled"
										   [(ngModel)]="newRule.result.recipient_allow_release_notification.enabled"
										   (ngModelChange)="isResultChanged(dic.CONSTANTS.inboundEmailRule.defaultResult.recipient_allow_release_notification)"/>
									<label id="inboundRulesResultAllowReleaseNotification" for="allowReleaseNotification" class="m-0">{{newRule.result.recipient_allow_release_notification.label}}</label>
									<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
                                       tooltip="{{newRule.result.recipient_allow_release_notification.explanation}}">
									</i>
								</div>
							</div>
						</div>

						<!-- auto_forward recipients input -->
						<div class="f-1-0 flex-column gap-2 px-4 py-3" *ngIf="newRule.result.auto_forward?.enabled">
							<div class="flex-row gap-2 align-items-center">
								<span>Auto Forward recipients:</span>
								<input type="text" placeholder="Auto Forward emails separated by commas" style="width: 60%;"
									   [(ngModel)]="newRule.result.auto_forward.emails"
									   [ngClass]="invalidAutoForwardEmails && 'validation-error'"
									   (ngModelChange)="isAutoForwardChanged();"/>
							</div>
							<div class="flex-row align-items-baseline gap-2">
								<i class="fa fa-info-circle text-info"></i>
								<span>Note: the forwarded recipient must know and approve your emails (otherwise they might be tagged as spam). You should also make sure the forwarded recipient's email server is secure and uses StartTLS, otherwise your emails will be vulnerable to attacks.</span>
							</div>

							<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
								<input id="auto_forward_change_sender_recipient" type="checkbox"
									   [(ngModel)]="newRule.result.auto_forward_change_sender_recipient.enabled"/>
								<label for="auto_forward_change_sender_recipient" class="m-0">{{newRule.result.auto_forward_change_sender_recipient.label}}</label>
								<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
                                   tooltip="{{newRule.result.auto_forward_change_sender_recipient.explanation}}">
								</i>
							</div>

							<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
								<input id="add_auto_forward_subject_prefix" type="checkbox"
									   [(ngModel)]="newRule.result.add_auto_forward_subject_prefix.enabled"/>
								<label for="add_auto_forward_subject_prefix" class="m-0">{{newRule.result.add_auto_forward_subject_prefix.label}}</label>
								<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
                                   tooltip="{{newRule.result.add_auto_forward_subject_prefix.explanation}}">
								</i>
							</div>
						</div>

						<!-- redirect recipients input -->
						<div class="f-1-0 flex-column gap-2 px-4 py-3" *ngIf="newRule.result.redirect?.enabled">
							<div class="flex-row gap-2 align-items-center">
								<span>Redirect recipients:</span>
								<input type="text" placeholder="Redirect emails separated by commas" style="width: 60%;"
									   [(ngModel)]="newRule.result.redirect.emails"
									   [ngClass]="invalidRedirectEmails && 'validation-error'"
									   (ngModelChange)="isRedirectChanged();"/>
							</div>
							<div class="flex-row align-items-baseline gap-2">
								<i class="fa fa-info-circle text-info"></i>
								<span>Note: the redirected recipient must know and approve your emails (otherwise they might be tagged as spam). You should also make sure the redirected recipient's email server is secure and uses StartTLS, otherwise your emails will be vulnerable to attacks.</span>
							</div>
						</div>

						<!-- Look & Feel customization -->
						<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule?.result?.apply_lf?.enabled">
							<span>Look & Feel customization:</span>
							<app-dropdown-c style="width: 110px;" id="{{'rulesResultSubCustomizations'}}"
											text="{{newRule.result.apply_lf.value}}">
								<ul>
									<li *ngFor="let customization of subCustomizationNames"
										(click)="newRule.result.apply_lf.value = customization">
										{{customization}}
									</li>
								</ul>
							</app-dropdown-c>
						</div>

						<!-- Subject input-->
						<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule.result.add_subject_text?.enabled">
							<span class="flex-shrink-0" style="flex-basis: 50px;">Subject:</span>
							<input type="text" class="m-0 f-1-0" placeholder="Enter subject" style="max-width: 60%;"
								   [ngClass]="invalidSubjectText && 'validation-error'"
								   (ngModelChange)="isSubjectChanged();"
								   [(ngModel)]="newRule.result.add_subject_text.text"/>
						</div>

						<!-- Footer input-->
						<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule.result.add_footer_text?.enabled">
							<span class="flex-shrink-0" style="flex-basis: 50px;">Footer:</span>
							<input type="text" class="m-0 f-1-0" placeholder="Enter footer" style="max-width: 60%;"
								   [ngClass]="invalidFooterText && 'validation-error'"
								   (ngModelChange)="isFooterChanged();"
								   [(ngModel)]="newRule.result.add_footer_text.text"/>
						</div>

						<!-- apply TP sub rule name select-->
						<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule.result.apply_sub_rules?.enabled">
							<span class="flex-shrink-0" style="flex-basis: 225px;">Custom Threat Prevention rules:</span>
							<app-dropdown-c style="width: 250px;"
											*ngIf="config.tpSubRules?.length"
											text="{{newRule.result.apply_sub_rules.ruleName}}"
											[enableSearch]="true"
											[alwaysDrop]="true" [error]="invalidSubRuleName"
											itemDisplayKey="name"
											[items]="config.tpSubRules"
											(onItemClicked)="invalidSubRuleName = false; newRule.result.apply_sub_rules.ruleName = $event.item.name"></app-dropdown-c>
							<div class="flex-row align-items-baseline gap-2" *ngIf="!config.tpSubRules?.length">
								<i class="fa fa-exclamation-triangle text-warning"></i>
								<span>No custom Threat Prevention rules. Add a custom new rule in Threat Prevention Rules tab.</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<br/>

			<div class="flex-row gap-2">
				<btn-c style="width: 120px;" id="outboundRulesCancelNewRule"
					   endIcon="fa fa-ban" variant="outlined"
					   (action)="cancelNewRule();">
					Cancel
				</btn-c>
				<btn-c style="width: 120px;" id="outboundRulesPrepareRule"
					   [isDisabled]="prepareRuleInProcess"
					   endIcon="{{!!newRule._id ? 'fa fa-check' : 'fa fa-plus'}}"
					   (action)="prepareRule();">
					{{!!newRule._id ? 'Update Rule' : 'Add Rule'}}
				</btn-c>
			</div>
		</div>
	</div>

	<!--Configure automatic reply popup-->
	<popup-c *ngIf="addMailboxReplyPopup?.show"
			 head="Configure Automatic Reply" size="medium"
			 subTitle="Create a message (subject and body) to be sent as an automatic reply"
			 (closeCb)="addMailboxReplyPopup = null;"
			 (confirmCb)="applyMailboxReplySettings()"
			 [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">
		<div class="flxClmn vSpace1 f-1-0">
			<div class="flxRow">
				<span style="flex-basis: 85px; font-weight: bold;">Subject</span>
				<input class="f-1-0" type="text" placeholder="Subject" style="margin: 0;"
					   [(ngModel)]="addMailboxReplyPopup.replyMailbox.subject"
					   (ngModelChange)="isAutoReplyChanged()" />
			</div>
			<div class="flxRow f-1-0">
				<span style="flex-basis: 85px; font-weight: bold;">Body</span>
				<div class="f-1-0 h100">
					<div [ngxSummernote]="summernoteConfig" [(ngModel)]="addMailboxReplyPopup.replyMailbox.body" (ngModelChange)="isAutoReplyChanged()"></div>
				</div>
			</div>
		</div>
	</popup-c>

	<!--Rule Usage popup-->
	<popup-c *ngIf="ruleUsagePopup?.show"
			 head="Inbound Rule Usage Data"
			 subTitle="<b>Rule name:</b> {{ruleUsagePopup.ruleObj.name}}"
			 (closeCb)="ruleUsagePopup = null"
			 width="min(calc(100vw - 5vh*2),1200px)">
		<div class="flex-column f-1-0 gap-4" [ngStyle]="{'overflow' : ruleUsagePopup.viewType === viewTypes.list ? 'auto' : ''}">
			<div class="flex-row gap-4 align-items-center">
				<div class="period-buttons-group bs-enabled flxRow hSpace1">
					<btn-c
						variant="{{ruleUsagePopup.usagePeriod.value !== periodObj.value ? 'outlined' : ''}}"
						*ngFor="let periodObj of trendsPeriod"
						[isDisabled]="getRuleUsageInProcess"
						(action)="changePeriod(periodObj);">
						{{periodObj.display}}
					</btn-c>
				</div>
				<div class="vertical-separator opacity-25"></div>
				<!--list/graph selection-->
				<div class="period-buttons-group align-self-center flex-row gap-2 animated fadeIn">
					<button class="period-button bs-btn bs-btn-light text-capitalize"
							*ngFor="let type of viewTypes | keyvalue: gs.returnZero"
							[ngClass]="{'chosen' : type.value === ruleUsagePopup.viewType}"
							(click)="changeViewType(type.value);"
							tooltip="{{type.value}}">
						<i style="height: 20px; width: 20px; font-size: 1.1rem; margin-left: 2px;"
						   [ngClass]="type.value === viewTypes.list ? 'fas fa-list' : 'fas fa-chart-pie'"></i>
					</button>
				</div>

				<div *ngIf="getRuleUsageInProcess" class="loading-spinner" style="width: 20px; height: 20px; border-width: 3px;"></div>
			</div>


			<div class="flex-column f-1-0 animated fadeIn" style="overflow: auto;"
				 *ngIf="ruleUsagePopup?.viewType === viewTypes?.list">
				<trustifi-table-c
					class="trustifi-table" style="flex: 1 0; z-index: 5;"
					[list]="ruleUsagePopup.emails"
					[headers]="[
							{text:'Subject', sortKey:'subject'},
							{text:'From', sortKey:'sender'},
							{text:'Mailbox', sortKey:'mailbox_email'},
							{text:'Sent', sortKey:'created'},
							{text:'Message ID'},
						]"
					[cellsPrototype]="[
							{textKey: 'subject'},
							{textKey: 'sender'},
							{textKey: 'mailbox_email'},
							{textKey: 'created', textType: 'dateAndTime'},
							{html: 'messageIdCell'},
						]"
					[scopeInstances]="[{copyEmailIdToClipboard: copyEmailIdToClipboard}]"
					(searchFunction)="searchRuleEmail($event.searchTerm)"
					[options]="{
							exportToCsvFunction: exportRuleEmailsToCsv,
							defaultSortKey: '-created',
							itemsNameSingular: 'email',
						}"
				>
					<ng-template #customCells let-item let-cellId="cellId">
						<!--action cells-->
						<div *ngIf="cellId === 'messageIdCell'">
							<div class="flex-row align-items-center cursor-pointer gap-1 copy-link"
								 (click)="copyEmailIdToClipboard(item)">
								<i class="fas fa-copy" style="cursor: pointer;"></i>
								<span style="font-size: 11px;">Copy ID</span>
							</div>
						</div>
					</ng-template>
					<!---->

					<div tableButtons>
						<span style="font-size: 0.95rem;">List of the emails detected by this rule in the {{ruleUsagePopup.usagePeriod.display | lowercase}}</span>
					</div>
				</trustifi-table-c>
			</div>

			<div class="flex-column f-1-0 position-relative animated fadeIn bg-light overflow-auto" *ngIf="ruleUsagePopup.viewType === viewTypes.chart">
				<div class="bs-container f-1-0 flex-column bg-light border overflow-auto">
					<div class="bs-row h-100 f-1-0 overflow-auto">
						<!--Emails-->
						<div class="bs-col-6 p-2 h-100">
							<div class="trends-data-card flex-column gap-1 p-3 w-100">
								<div class="flex-column align-items-start gap-1">
									<h6 class="m-0 fw-bold">Total amount of emails detected by this rule</h6>
								</div>
								<div class="flex-column centralize f-1-0 p-3">
									<apx-chart class="f-1-0 animated fadeIn align-self-stretch" style="z-index: 40;"
											   *ngIf="ruleUsagePopup?.emails?.length"
											   #emailsDetectedChart
											   [options]="{display: false}"
											   [series]="emailDetectedByRuleChartOptions.series"
											   [chart]="emailDetectedByRuleChartOptions.chart"
											   [plotOptions]="emailDetectedByRuleChartOptions.plotOptions"
											   [colors]="emailDetectedByRuleChartOptions.colors"
											   [dataLabels]="emailDetectedByRuleChartOptions.dataLabels"
											   [stroke]="emailDetectedByRuleChartOptions.stroke"
											   [yaxis]="emailDetectedByRuleChartOptions.yaxis"
											   [fill]="emailDetectedByRuleChartOptions.fill"
											   [tooltip]="emailDetectedByRuleChartOptions.tooltip"
											   [xaxis]="emailDetectedByRuleChartOptions.xaxis"></apx-chart>
									<!--loader / placeholder-->
									<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!ruleUsagePopup?.emails?.length">
										<div class="flex-column text-center centralize f-1-0">
											<span class="fw-500 opacity-75" *ngIf="!getRuleUsageInProcess">No data for selected time period</span>
											<loader class="opacity-75 align-self-center" *ngIf="getRuleUsageInProcess" height="100" width="100"></loader>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="bs-col-6 p-2 flex-column gap-2 h-100">
							<!--Senders-->
							<div class="trends-data-card flex-column gap-1 p-3 w-100 f-1-0">
								<div class="flex-column align-items-start gap-1">
									<h6 class="m-0 fw-bold">Results sorted by top senders</h6>
								</div>
								<div class="flex-column centralize f-1-0 p-3">
									<apx-chart class="f-1-0 animated fadeIn align-self-stretch"
											   *ngIf="ruleUsagePopup?.emails?.length"
											   #topSendersChart
											   [series]="senderGraphOptions.series"
											   [chart]="senderGraphOptions.chart"
											   [dataLabels]="senderGraphOptions.dataLabels"
											   [colors]="senderGraphOptions.colors"
											   [plotOptions]="senderGraphOptions.plotOptions"
											   [xaxis]="senderGraphOptions.xaxis"
											   [yaxis]="senderGraphOptions.yaxis"></apx-chart>
									<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!ruleUsagePopup?.emails?.length">
										<div class="flex-column text-center centralize f-1-0">
											<span class="fw-500 opacity-75" *ngIf="!getRuleUsageInProcess">No data for selected time period</span>
											<loader class="opacity-75 align-self-center" *ngIf="getRuleUsageInProcess" height="100" width="100"></loader>
										</div>
									</div>
								</div>
							</div>

							<!--Recipients-->
							<div class="trends-data-card flex-column gap-1 p-3 w-100 f-1-0">
								<div class="flex-column align-items-start gap-1">
									<h6 class="m-0 fw-bold">Results sorted by top recipient mailboxes</h6>
								</div>
								<div class="flex-column centralize f-1-0 p-3">
									<apx-chart class="f-1-0 animated fadeIn align-self-stretch"
											   *ngIf="ruleUsagePopup?.emails?.length"
											   #topRecipientMailboxesChart
											   [series]="recipientGraphOptions.series"
											   [chart]="recipientGraphOptions.chart"
											   [dataLabels]="recipientGraphOptions.dataLabels"
											   [colors]="recipientGraphOptions.colors"
											   [plotOptions]="recipientGraphOptions.plotOptions"
											   [xaxis]="recipientGraphOptions.xaxis"
											   [yaxis]="recipientGraphOptions.yaxis"></apx-chart>
									<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!ruleUsagePopup?.emails?.length">
										<div class="flex-column text-center centralize f-1-0">
											<span class="fw-500 opacity-75" *ngIf="!getRuleUsageInProcess">No data for selected time period</span>
											<loader class="opacity-75 align-self-center" *ngIf="getRuleUsageInProcess" height="100" width="100"></loader>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</popup-c>

	<!--Modify warning label popup-->
	<popup-c class="no-content-padding" *ngIf="warningLabelPopup?.show"
			 head="Configure Warning Label"
			 (closeCb)="warningLabelPopup = null"
			 size="large">
		<div class="flxClmn f-1-0 vSpace2" style="overflow: auto; padding: 25px;">
			<div class="flxRow">
				<div class="flxClmn vSpace1" style="flex-basis: 160px;">
					<label style="font-weight: bold;">
						Warning Text
					</label>
					<btn-c variant="outlined"
						   (action)="showWarningPreview()">
						Preview
					</btn-c>
				</div>
				<div class="flex-column gap-1">
					<div style="flex:1 0; overflow: auto; box-sizing: border-box; border: 1px solid #ccc; vertical-align: top; resize: none;"
						 [ngxSummernote]="summernoteConfigWarningLabel" [(ngModel)]="warningLabelPopup.text"></div>
					<div class="flex-row gap-1">
						<i class="fa fa-info-circle text-info"></i>
						<span>You can enhance your warning label by incorporating dynamic fields such as {{ '{{SENDER_EMAIL}}' }}, {{ '{{SENDER_NAME}}' }} and {{ '{{REPLY_TO_EMAIL}}' }}.</span>
					</div>
				</div>
			</div>

			<div class="flxRow" style="padding-top: 20px;">
				<div style="overflow: hidden; float: left; flex-basis:160px;">
					<label style="font-weight: bold;">
						Warning Color
					</label>
				</div>
				<div style="flex: 1 0;">
					<div class="flxRow hSpace1" style="align-items: baseline; margin-bottom: 15px; color: var(--bs-warning);" *ngIf="gs.userInfo?.dark_mode">
						<i class="fa fa-exclamation-triangle "></i>
						<span>While in "Dark Mode", color picker will show inaccurate colors.</span>
					</div>
					<div class="flxRow w100 hSpace3 color-picker-css" style="align-items: center;">
						<div class="flxClmn-center vSpace2" style="align-items: center;">
							<div style="width: 40px; height: 40px;">
								<div class="w100 h100 static-color" style="border-radius: 50%;"
									 [ngStyle]="{'background' : '#' + warningLabelPopup.color.replace('#','')}"
									 *ngIf="warningLabelPopup.color.replace('#','').length === 6">
								</div>
							</div>
							<div class="flxClmn vSpace05">
								<input type="text" style="width: 90px; margin: 0;"
									   [value]="'#' + warningLabelPopup.color.replace('#','')"
									   [(ngModel)]="warningLabelPopup.color">
								<span style="opacity: 0.65; font-family: 'Roboto Condensed', sans-serif; letter-spacing: 0.5px;">Color Hex<br/>(6 digits only)</span>
							</div>
						</div>
						<span [(colorPicker)]="warningLabelPopup.color"
							  [cpPosition]="'bottom'" [cpPositionOffset]="'50%'" [cpWidth]="'175px'"
							  [cpOutputFormat]="'hex'"
							  [cpDialogDisplay]="'inline'"
							  [cpDisableInput]="true"
							  [cpToggle]="true"
							  [style.position]="'relative'"></span>
					</div>
				</div>
			</div>

		</div>
		<div class="flxRow-end hSpace1" style="padding: 25px;">
			<btn-c class="popup-action-btn"
				   variant="outlined"
				   [isDisabled]="warningLabelPopup.inProcess"
				   (action)="warningLabelPopup = null;">
				Cancel
			</btn-c>
			<btn-c class="popup-action-btn"
				   [isDisabled]="warningLabelPopup.inProcess"
				   (action)="applyWarningLabel()">
				Apply
			</btn-c>
		</div>
	</popup-c>

	<!--Preview warning label popup-->
	<popup-c *ngIf="previewWarningLabelPopup?.show"
			 head="Preview Warning Label"
			 (closeCb)="previewWarningLabelPopup = null"
			 size="dynamic">
		<div class="flxClmn f-1-0" style="overflow: auto;">
			<div class="flex-column gap-3 py-2"
				 style="border: 0 solid white; border-left: 5px solid darkorange; vertical-align: middle; padding-right: 10px;"
				 [ngStyle]="{'background-color': previewWarningLabelPopup.backgroundColor, 'border-left-color': previewWarningLabelPopup.borderColor}">
				<div class="mb-1" [innerHTML]="previewWarningLabelPopup.text"></div>
			</div>
		</div>
	</popup-c>
</div>

