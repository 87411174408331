import {Injectable} from "@angular/core";
import {GeneralService} from "./generalService";
import {DICTIONARY} from "../dictionary";
import {Router} from "@angular/router";

const dic = DICTIONARY;

@Injectable({
    providedIn: 'root'
})
export class UserPermissionService {

	constructor(private gs:GeneralService,
				private router:Router) {
	}

	// updating variable that contains routes that are under construction and to be hidden in production
	hideInProductionRoutes = [

	];

	getPermission = (url) => {
		const userInfo = this.gs.userInfo;
		const urlSegments = url.split('/'); // => [section, page?, tab?]

		// global permission checks
		const globalCheckResult = this.runGlobalCheck(userInfo, url, urlSegments);
		if (globalCheckResult) {
			return globalCheckResult;
		}
		//

		// check per url
		switch (url) {

			// outbound
			case [dic.CONSTANTS.appStates.adminOutbound].join('/'):
				return userInfo.plan.plan === dic.CONSTANTS.planTypePro && (this.isAdminOrSubadmin() || !!userInfo.isOutboundReviewer);

			case [dic.CONSTANTS.appStates.adminOutbound, dic.CONSTANTS.adminPages.outbound.sharedPlan].join('/'):
			case [dic.CONSTANTS.appStates.adminOutbound, dic.CONSTANTS.adminPages.outbound.userManagement].join('/'):
			case [dic.CONSTANTS.appStates.adminOutbound, dic.CONSTANTS.adminPages.outbound.lf].join('/'):
				return this.isAdminOrSubadmin() || !!userInfo.global_reviewer?.outbound?.enabled;

			// inbound
			case [dic.CONSTANTS.appStates.adminInbound].join('/'):
				return userInfo.plan.plan === dic.CONSTANTS.planTypePro && (this.isAdminOrSubadmin() || !!userInfo.isInboundReviewer || !this.isBkash());

			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.inboundShield].join('/'):
			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.configuration].join('/'):
			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.urlTracking].join('/'):
			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.threatResponse].join('/'):
				return this.isAdminOrSubadmin() || (!!userInfo.isInboundReviewer && !!userInfo.inboundAllowChangeConfiguration);

			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.plan].join('/'):
			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.mailboxes].join('/'):
			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.trends].join('/'):
			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.discoveredServices].join('/'):
				return this.isAdminOrSubadmin() || (!!userInfo.isInboundReviewer && !!userInfo.inboundAllowChangeConfiguration && !!userInfo.global_reviewer?.inbound?.enabled);

			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.quarantined].join('/'):
			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.auditLog].join('/'):
			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.emailTrace].join('/'):
				return this.isAdminOrSubadmin() || !!userInfo.isInboundReviewer;

			// inbound (user)
			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.userInboundConf].join('/'):
				return !this.isAdminOrSubadmin() && !userInfo.isInboundReviewer && !!userInfo.plan.threat_protection?.whitelist_allow_recipient;

			case [dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.userQuarantinedInboundConf].join('/'):
				return !this.isAdminOrSubadmin() && !userInfo.isInboundReviewer;

			// Threat Simulation
			case [dic.CONSTANTS.appStates.threatSimulation].join('/'):
				return this.isAdminOrSubadmin() || !!userInfo.isThreatSimulationReviewer;

			// Domain Protection
			case [dic.CONSTANTS.appStates.domainProtection].join('/'):
				return this.isAdminOrSubadmin() || (!!userInfo.isInboundReviewer && !!userInfo.inboundAllowChangeConfiguration);

			// Account Takeover Protection
			case [dic.CONSTANTS.appStates.accountCompromised].join('/'):
				return !!userInfo.plan.account_compromised?.enabled && (this.isAdminOrSubadmin() || !!userInfo.isAccountCompromisedReviewer);

			// Archive
			case [dic.CONSTANTS.appStates.archive].join('/'):
				return this.isAdminOrSubadmin() || !!userInfo.isArchiveReviewer;

			// Partners
			case [dic.CONSTANTS.appStates.partner].join('/'):
				return !!userInfo.plan.customer_id && (this.isAdminOrSubadmin() || !!userInfo.isPartnerReviewer);

			// Personal Pages
			case [dic.CONSTANTS.appStates.personalPages].join('/'):
				return !!userInfo.plan.allow_personal_nav_wa;

			case [dic.CONSTANTS.appStates.personalPages, dic.CONSTANTS.personalPages.composeMessage].join('/'):
			case [dic.CONSTANTS.appStates.personalPages, dic.CONSTANTS.personalPages.mailbox].join('/'):
			case [dic.CONSTANTS.appStates.personalPages, dic.CONSTANTS.personalPages.storage].join('/'):
			case [dic.CONSTANTS.appStates.personalPages, dic.CONSTANTS.personalPages.contacts].join('/'):
			case [dic.CONSTANTS.appStates.personalPages, dic.CONSTANTS.personalPages.templates].join('/'):
				return !(this.isBkash() && !this.isAdminOrSubadmin()); // not non-admin user in Bkash

			case [dic.CONSTANTS.appStates.personalPages, dic.CONSTANTS.personalPages.massList].join('/'):
				return !!userInfo.plan.methods.secure_mass;

		}

		return true;
	}

	runGlobalCheck = (userInfo, fullUrl, urlSegments) => {
		// hide in production
		if (this.hideInProductionRoutes.includes(fullUrl) && this.gs.isProductionEnv()) {
			return false;
		}

		// dlp user
		if (userInfo.type === dic.CONSTANTS.userTypes.dlp) {
			if ((urlSegments.length === 1 && urlSegments[0] === dic.CONSTANTS.appStates.adminOutbound) || (urlSegments.length > 1 && urlSegments[0] === dic.CONSTANTS.appStates.adminOutbound && urlSegments[1] === dic.CONSTANTS.adminPages.outbound.dataClassification)) {
				return true;
			}
			else {
				return this.router.createUrlTree([dic.CONSTANTS.appStates.adminOutbound, dic.CONSTANTS.adminPages.outbound.dataClassification]);
			}
		}

		// heroku user
		if (userInfo.type === dic.CONSTANTS.userTypes.heroku) {
			const allowedStates = [
				dic.CONSTANTS.appStates.home,
				dic.CONSTANTS.appStates.personalPages,
				dic.CONSTANTS.appStates.accountDetails,
				dic.CONSTANTS.appStates.knowledge,
			];
			if (!allowedStates.includes(urlSegments[0])) {
				return this.router.createUrlTree([allowedStates[0]]);
			}
		}

		return null;
	}

	isAdminOrSubadmin = () => {
		return this.gs.userInfo.user_role === dic.CONSTANTS.userRole.admin || this.gs.userInfo.user_role === dic.CONSTANTS.userRole.subAdmin;
	}

	isBkash = () => {
		return this.gs.userInfo.plan._id.toString() === '650cc08cd74b1462095f319b';
	}
}
