import {DICTIONARY} from "../dictionary";
import {GeneralService} from './generalService';
import {NotificationService} from "./notificationService";
import {RouteService} from "./routeService";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class myEmailsService {
    dic = DICTIONARY;
    summernoteOptions;
	currentEmailsList:any = [];
	currentFilteredEmailsList = [];
	currentMessage:any = {};
    emailActionState;
    origEmailContent;
    showEditEmailContent;
    updateEmailContentInProcess;

	additionalCss = `<style>p {margin:0;padding:0;}</style>
					<style>
                        code.protect { color: darkred; visibility: hidden; }
                        code.protect:before { visibility: visible; content: '[PROTECTED] ' }
                        code.protect:hover { visibility: visible; color:limegreen; content: unset; }
                    </style>`;

    constructor(private gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
        this.summernoteOptions = this.gs.summernoteOptions;
    }

    // shared actions between tabs
    doEmailAction(action) {
        switch (action) {
            case 'updateContent':
                this.showEmailContent(this.currentMessage);
                break;

            case 'blockEmail':
                this.changeMessageStateForAllRecipients(this.currentMessage, 'block');
                break;

            case 'unblockEmail':
                this.changeMessageStateForAllRecipients(this.currentMessage, 'unblock');
                break;
        }
    }

    changeMessageStateForAllRecipients(currentMessage, action) {
        let actionButton = "";
        let title = "";
        let subtitle = "";
        if (action === this.dic.CONSTANTS.recipientStatus.unblock) {
            if (currentMessage.numberRecipientsBlocked === 0) {
                return;
            }

            title = "Unblock Email's Recipients";
            subtitle = "Please note - The following operations will be available for all recipients:";
            actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.unblock);
        }
        else {
            if (currentMessage.numberRecipientsBlocked === currentMessage.recipients_count) {
                return;
            }

            title = "Block Email's Recipients";
            subtitle = "Please note - The following operations will be blocked for all recipients:";
            actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.block);
        }
        this.emailActionState = action;

        const body = emailOperations(currentMessage);

        this.gs.showPopup({
            title: title,
            subTitle: subtitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: actionButton,
            doneCb: () => {
                this.rs.changeMessageStateForAllRecipients(currentMessage._id, {action: this.emailActionState}, this.getRouteParams(this.currentMessage)).then(() => {
                    this.emailActionState === this.dic.CONSTANTS.recipientStatus.block ? currentMessage.numberRecipientsBlocked = currentMessage.recipients_count : currentMessage.numberRecipientsBlocked = 0;
                });
            }
        });
    }

    showEmailContent(currentMessage) {
        if (currentMessage.methods.postmark) {
            this.gs.showPopup({
                title: 'Update Message',
                subTitle: 'Attention: this is a postmarked message.',
                body: ['Once you update the message, the Postmark will be lost and you won\'t be able to legally prove that you have sent this message.'],
                type: this.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Update',
                doneCb: () => {
                    this.origEmailContent = currentMessage.sent.html;
                    this.showEditEmailContent = true;
                }
            });
        }
        else {
            this.origEmailContent = currentMessage.sent.html;
            this.showEditEmailContent = true;
        }
    }

    updateEmailContent() {
        const emailContent = this.currentMessage.sent.html;
        this.updateEmailContentInProcess = true;

		const actionInfo = {
			type: this.dic.CONSTANTS.updateEmailTypes.content,
			html: emailContent
		}
        this.rs.updateEmailContent(this.currentMessage._id, actionInfo, this.getRouteParams(this.currentMessage)).then(data => {
            this.currentMessage.sent.edited_timestamp = data.edited_timestamp;
            this.ns.showInfoMessage(this.dic.MESSAGES.recallSuccess);
            this.updateEmailContentInProcess = false;
            this.showEditEmailContent = false;
        }, err => {
            this.updateEmailContentInProcess = false;
            if (err && err.data && err.data.message) {
                this.ns.showErrorMessage(err.data.message);
            }
        });
    }

	getRouteParams = (emailObj) => {
		if (!emailObj || emailObj.user_id === this.gs.userInfo.id) {
			return null;
		}
		return {userId: emailObj.user_id};
	}
}


function emailOperations(email) {
    let actions = [];
    if (email.methods.secure_reply) {
        actions.push('Encrypted reply');
    }
    if (email.sent.attachments && email.sent.attachments.length && !email.skip_attachments_encryption) {
        actions.push('Download attachments');
    }
    if (email.methods.encrypt_content) {
        actions.push('Read email content');
    }
    return actions;
}
