<!--exchange configuration popup-->
<popup-c class="no-content-padding bs-enabled" size="large"
	   head="View Exchange Server Configuration"
	   subTitle="Use this tool to view various configurations in your Exchange server"
	   (closeCb)="closePopup();">
	<div class="flex-column overflow-auto f-1-0 pt-2 gap-2" style="padding: 20px;">
		<!--steps progress-->
		<div class="steps-container" style="font-size: 0.8rem;">
			<div class="step-tab"
				 *ngFor="let stepKey of validSteps | keyvalue: gs.returnZero"
				 [ngClass]="{'active' : step === stepKey.value}">
				{{stepKey.value}}
			</div>
		</div>

		<!--loader-->
		<div class="f-1-0 flex-column centralize" *ngIf="checkIfDataAlreadyExistInProcess">
			<loader height="100" width="100" style="opacity: 0.8;"></loader>
			<span class="text-primary mt-1 fw-500">Retrieving Exchange configuration data</span>
			<span class="text-primary">Please wait. Process may take a while...</span>
		</div>

		<!--Steps content:-->
		<!--STEP: General instructions-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-2 pt-3"
			 style="line-height: 2;"
			 *ngIf="step === dic.CONSTANTS.reviewExchangeRulesSteps.generalInformation && !checkIfDataAlreadyExistInProcess">
			<span style="position: relative; padding-left: 15px;" class="diamond-indent">
				This wizard will retrieve information from your Exchange server that can be used to validate your configurations.
			</span>

			<br/>
			<div class="flex-row gap-2 align-items-baseline">
				<i class="fa fa-exclamation-triangle opacity-25"></i>
				<span class="fw-bold">Before taking the next step:</span>
			</div>

			<div class="bg-light p-2">
				<ul style="list-style-type: number;">
					<li>
						<span>Make sure your PowerShell integration with Trustifi is active and valid</span>
					</li>
					<li>
						<span>Make sure remote PowerShell access is enabled in your Exchange server</span>
					</li>
				</ul>
			</div>

			<div class="flex-column gap-2 px-2 f-1-0 justify-content-end">
				<hr class="mt-0"/>
				<span class="diamond-indent position-relative ps-3">After clicking <b>Configure</b>, Trustifi will retrieve the data of the existing configurations in Exchange and display them here.</span>
			</div>
		</div>

		<!--STEP: Results-->
		<div class="flxClmn f-1-0 animated fadeIn gap-3 p-2 pt-3 overflow-auto" *ngIf="step === dic.CONSTANTS.reviewExchangeRulesSteps.results">
			<span class="text-primary fw-500 mb-2 py-2 px-3 border-start border-4 border-primary" *ngIf="isDataRestored">Information below is current as of {{statusTimestamp | date:'MM/dd/yyyy HH:mm'}}</span>
			<div class="flex-column gap-1 f-1-0 centralize" *ngIf="getCfgDataInProcess">
				<loader height="100" width="100" style="opacity: 0.8;"></loader>
				<span class="text-primary mt-1 fw-500">Retrieving Exchange configuration data</span>
				<span class="text-primary">Please wait. Process may take a while...</span>
			</div>
			<div class="flex-column gap-5 f-1-0" *ngIf="!getCfgDataInProcess && finalResults">
				<!--<span class="fw-500">Your Exchange Server configuration:</span>-->
				<div class="flex-column gap-2" *ngFor="let result of finalResults">
					<!--title-->
					<span class="fw-bold" style="font-size: 15px;">{{beautifyTitles(result.title)}}</span>

					<!--null-->
					<span class="text-muted" *ngIf="!result.value">- Not configured -</span>
					<!--string-->
					<span *ngIf="result.type === valueTypes.string">{{result.value}}</span>
					<!--boolean-->
					<span *ngIf="result.type === valueTypes.boolean" [ngClass]="{'text-success' : result.value, 'text-warning' : !result.value}">{{result.value}}</span>
					<!--object-->
					<div class="flex-column bg-light py-2 px-4 w-100" *ngIf="result.type === valueTypes.object">
						<table class="simpleDataTableShrink" style="table-layout: fixed; min-width: 500px;">
							<tr *ngFor="let key of result.value | keyvalue: gs.returnZero">
								<td class="fw-500 pe-5">{{beautifyTitles(key.key)}}</td>
								<td *ngIf="!_.isArray(key.value)">{{key.value}}</td>
								<td *ngIf="_.isArray(key.value)">
									<div class="flex-column gap-1">
										<span *ngFor="let subValue of key.value">{{subValue}}</span>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<!--array-->
					<trustifi-table-c
						*ngIf="result.type === valueTypes.array && result.value"
						class="trustifi-table w-100" style="z-index: 5;" style="min-height: 200px;"
						[list]="result.value"
						[headers]="result.options.tableHeaders"
						[cellsPrototype]="result.options.tableCells"
						[options]="{
								hideContentAboveTable: true,
								}">
					</trustifi-table-c>
				</div>
				<br/>
			</div>
		</div>

		<!--error message and buttons-->
		<div class="flex-row justify-content-between align-items-end my-3 px-4 w-100">
			<span class="f-1-0 pe-3 text-danger" style="min-height: 40px;">
				{{errorMsg || ' '}}
			</span>

			<div class="flex-row gap-3" style="font-size: 13px;"
				 *ngIf="!getCfgDataInProcess">
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.reviewExchangeRulesSteps.results && isDataRestored"
					 variant="outlined" startIcon="fa fa-angle-double-left"
					 (action)="restart();">
					Restart Wizard
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.reviewExchangeRulesSteps.generalInformation"
					 variant="outlined"
					 (action)="closePopup();">
					Cancel
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.reviewExchangeRulesSteps.results && !isDataRestored"
					 variant="outlined"
					 (action)="backStep();">
					Back
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.reviewExchangeRulesSteps.generalInformation"
					 (action)="nextStep();">
					Configure
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.reviewExchangeRulesSteps.results"
					 (action)="closePopup();">
					OK
				</btn-c>
			</div>
		</div>
	</div>
</popup-c>
