import _ from 'lodash';
import {RouteService} from '../../../../services/routeService';
import {NotificationService} from '../../../../services/notificationService';
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'domain-protection-audit-logs-component',
	templateUrl: './domain-protection-audit-logs.component.html',
})
export class DomainProtectionAuditLogsComponent implements OnInit {
	constructor(public gs:GeneralService,
				// private ArchiveService,
				private rs:RouteService,
				private ns:NotificationService) {

	}

	dic = DICTIONARY;
	loadingLogsInProcess = true;
	filterData;
	logs;

	auditLogsPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.all
	];
	period = this.dic.CONSTANTS.trendsPeriod.last3Days;
	scheduleReportShow = false;

	ngOnInit() {
		this.initFilters();
		this.getDomainProtectionLogs();
	};

	changePeriod = (inputPeriod) => {
		this.period = inputPeriod;
		this.getDomainProtectionLogs();
	};

	getDomainProtectionLogs = () => {
		this.loadingLogsInProcess = true;
		const params = {period: this.period.value};
		this.rs.getPlanDomainProtectionAuditLogs(params).then((response) => {
			this.logs = response;
			this.loadingLogsInProcess = false;
		}, (err) => {
			this.loadingLogsInProcess = false;
		});
	};

	showScheduleReport = () => {
		this.scheduleReportShow = true;
	};

	searchLogs = (event) => {
		this.logs.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = searchTextExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			if (event.activeFilters) {
				// need to match all filter types
				let numFilterToMatch = Object.keys(event.activeFilters).length;

				if (event.activeFilters.category && event.activeFilters.category.length) {
					if (event.activeFilters.category.includes(record.category)) {
						numFilterToMatch--;
					}
				}

				if (numFilterToMatch) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	};

	exportToCsv = (sortBy) => {
		if (!this.logs || !this.logs.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Date,Category,Admin,Action\n";

		let sortedTable = _.filter(this.logs,incident => {return !incident.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((log) => {
			csvString += `${log.created},"${log.category}",${log.admin},"${log.action}"\n`;
		});

		this.gs.exportCsv(csvString, `audit_logs.csv`);
	};

	initFilters = () => {
		this.filterData = {
			filterType : this.dic.CONSTANTS.tableFilters.auditLogDomain,
			filters: {
				category: ['Domain Spoofing Control', 'DMARC Analyzer', 'Domain Metrics']
			},
			initFiltersFunction: this.initFilters
		};
	};

}
function searchTextExecute(log, searchTerm) {
	return ((log.action && log.action.toLowerCase().indexOf(searchTerm) > -1) ||
		(log.admin && log.admin.toLowerCase().indexOf(searchTerm) > -1) ||
		(log.category && log.category.toLowerCase().indexOf(searchTerm) > -1));
}
