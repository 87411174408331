import {Component, Input, OnInit} from "@angular/core";
import {GeneralService} from "src/app/services/generalService";
import {DICTIONARY} from "../../../../../dictionary";
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import _ from "lodash";
import util from "util";

@Component({
	selector: 'new-rule-condition',
	templateUrl: './new-rule-condition.component.html',
})
export class NewRuleConditionComponent implements OnInit {
	@Input('condition') conditionArr;
	@Input() conditionIndex;
	@Input() newRule;
	@Input() subCustomizationNames;
	@Input() fileTypes;
	@Input() sensitivityTypesData;
	@Input() attachmentsTypesData;

	constructor(private rs:RouteService,
				public gs:GeneralService,
				private ns:NotificationService) {
	}

	dic = DICTIONARY;
	rulesOptions = this.dic.CONSTANTS.rules;
	isInvalidRuleData;
	format = util.format;
	_=_;
	invalidDrag;
	validDrag;
	conditionInEdit;
	selectedGroupsRule;
	testSensitivityPopup;
	sensitivity_types;
	doNotDeleteTypes;
	testSensitivityInProcess;

	showGroups;
	valuesPopup;
	attachmentTypesPopup;
	modalData;
	mouseOverAttachment;
	hours = ['00','01','02','03','04','05','06','07','08','09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

	ngOnInit() {
		this.conditionArr.forEach(condition => {
			condition.subTypeShow = this.parseSubType(condition.subType);
		});
		this.sensitivity_types = this.sensitivityTypesData?.sensitivity_types;
		this.doNotDeleteTypes = this.sensitivityTypesData?.doNotDeleteTypes;
		this.fileTypes = this.attachmentsTypesData?.fileTypes;
	}

	showMatchActions(conditionObj) {
		conditionObj.showActions = !conditionObj.showActions;
		if (conditionObj.showActions) {
			switch (conditionObj.type) {
				case this.rulesOptions.types.addresses.name:
					conditionObj.actions = [
						this.dic.CONSTANTS.ruleMatchType.exactMatch,
						this.dic.CONSTANTS.ruleMatchType.startsWith,
						this.dic.CONSTANTS.ruleMatchType.endsWith,
						this.dic.CONSTANTS.ruleMatchType.internal,
						this.dic.CONSTANTS.ruleMatchType.external,
						this.dic.CONSTANTS.ruleMatchType.freeEmailDomain,
						this.dic.CONSTANTS.ruleMatchType.belongsTo,
						this.dic.CONSTANTS.ruleMatchType.contains
					];
					break;

				case this.rulesOptions.types.domains.name:
					conditionObj.actions = [
						this.dic.CONSTANTS.ruleMatchType.exactMatch,
						this.dic.CONSTANTS.ruleMatchType.internal,
						this.dic.CONSTANTS.ruleMatchType.external,
						this.dic.CONSTANTS.ruleMatchType.freeEmailDomain,
					];
					break;

				case this.rulesOptions.types.all_domains.name:
					conditionObj.actions = [
						this.dic.CONSTANTS.ruleMatchType.exactMatch,
						this.dic.CONSTANTS.ruleMatchType.internal,
						this.dic.CONSTANTS.ruleMatchType.external,
						this.dic.CONSTANTS.ruleMatchType.freeEmailDomain,
					];
					break;

				case this.rulesOptions.types.email_header.name:
					conditionObj.actions = [
						this.dic.CONSTANTS.ruleMatchType.exactMatch,
						this.dic.CONSTANTS.ruleMatchType.contains
					];
					break;

				case this.dic.CONSTANTS.inboundEmailRule.types.keywords.name:
					conditionObj.actions = [
						this.dic.CONSTANTS.ruleMatchType.exactMatch,
						this.dic.CONSTANTS.ruleMatchType.contains
					];
					break;
			}
		}
	};

	changeConditionType(condition, type) {
		condition.type = type;
		condition.isNewTypeSelected = true;
		condition.isNewSubTypeSelected = false;
		condition.is_negate = false;
		condition.subType = _.cloneDeep(this.rulesOptions.types[condition.type].subTypes);
		condition.singleSubType = _.clone(this.rulesOptions.types[condition.type].singleSubType);
		condition.placeholder = _.clone(this.rulesOptions.types[condition.type].placeholder);
		condition.values = _.clone(this.rulesOptions.types[condition.type].values);
		condition.data = _.cloneDeep(this.rulesOptions.defaultCondition.data);
		condition.subTypeShow = this.parseSubType(condition.subType);

		if (condition.type === this.rulesOptions.types.addresses.name ||
			condition.type === this.rulesOptions.types.domains.name ||
			condition.type === this.rulesOptions.types.keywords.name ||
			condition.type === this.rulesOptions.types.all_domains.name ||
			condition.type === this.rulesOptions.types.email_header.name) {
			condition.data.matchType = this.dic.CONSTANTS.ruleMatchType.exactMatch;
		}
		if (condition.type === this.rulesOptions.types.received_day.name) {
			condition.data.received_day = _.map(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], dayOfTheWeek => ({name: dayOfTheWeek, selected: false}));
		}
		this.checkRemoveKeywords();

		const dataSrc = condition.isException ? this.newRule.exceptions : this.newRule.conditions;
		this.isConditionDataChanged(dataSrc); // <-- condition validation check

	};

	parseSubType(subType) {
		let res = [];
		_.map(subType, (value, key) => {
			if (value) {
				res.push(key);
			}
		});
		return res;
	};

	checkRemoveKeywords() {
		this.newRule.showRemoveKeywords = _.some(this.newRule.conditions, conditionArr => {
			return _.some(conditionArr, c => c.type === this.rulesOptions.types.keywords.name && c.subType?.subject && !c.is_negate)
		});

		if (!this.newRule.showRemoveKeywords && this.newRule.result.remove_keyword.enabled) {
			this.newRule.result.remove_keyword.enabled = false;
		}
	};

	isConditionDataChanged(conditions) {
		this.isInvalidRuleData = false;
		for (let i = 0; i < conditions.length; i++) {

			const conditionArr = conditions[i];

			conditionArr.forEach(condition => {
				switch (condition.type) {
					case this.rulesOptions.types.keywords.name:
						if(!condition.data || !condition.data.keywords || (condition.data.keywords instanceof Array && !condition.data.keywords.length)){
							condition.invalidKeywords = true;
							this.isInvalidRuleData = true;
							return;
						}
						else {
							condition.invalidKeywords = false;
						}
						break;

					case this.rulesOptions.types.addresses.name:
						// regular emails match types validation
						if ((!condition.data || !condition.data.emails || (condition.data.emails instanceof Array && !condition.data.emails.length)) &&
							(condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch ||
								condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.startsWith ||
								condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.endsWith ||
								condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.contains)) {
							condition.invalidEmails = true;
							this.isInvalidRuleData = true;
							return;
						}
						// 'belongs to' match type validation
						else if ((!condition.data || !condition.data.groups || (condition.data.groups instanceof Array && !condition.data.groups.length)) && condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.belongsTo) {
							condition.invalidEmails = true;
							this.isInvalidRuleData = true;
							return;
						}
						else {
							condition.invalidEmails = false;
						}
						break;

					case this.rulesOptions.types.domains.name:
					case this.rulesOptions.types.all_domains.name:
						if((!condition.data || !condition.data.domains || (condition.data.domains instanceof Array && !condition.data.domains.length)) &&
							(condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch)){
							condition.invalidDomains = true;
							this.isInvalidRuleData = true;
						}
						else {
							condition.invalidDomains = false;
						}
						break;

					case this.rulesOptions.types.attachment_types.name:
						if (!condition.data || (!condition.data.attachment_types && !condition.data.all_types) || (condition.data.attachment_types instanceof Array && !condition.data.attachment_types.length && !condition.data.all_types)){
							condition.invalidRuleEmailsTypes = true;
							this.isInvalidRuleData = true;
						}
						else {
							condition.invalidRuleEmailsTypes = false;
						}
						break;
				}
			});
		}
	};


	confirmTestSensitivity() {
		if (this.testSensitivityInProcess) {
			return;
		}

		switch (this.testSensitivityPopup.type) {
			case this.dic.CONSTANTS.sensitivityAction.text:
				if (!this.testSensitivityPopup.text) {
					this.ns.showWarnMessage(util.format(this.dic.ERRORS.emptyField, 'Text'));
					return;
				}

				this.testSensitivityPopup.result = null;
				this.testSensitivityInProcess = true;
				this.rs.testTextSensitivity({text: this.testSensitivityPopup.text}).then(sensitivityData => {
					this.testSensitivityPopup.fileData = {};
					this.testSensitivityPopup.result = sensitivityData;

					this.testSensitivityInProcess = false;
				}, (err) => {
					this.testSensitivityInProcess = false;
				});
				break;

			case this.dic.CONSTANTS.sensitivityAction.file:
				if (!this.testSensitivityPopup.file) {
					this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'file'));
					return;
				}

				this.testSensitivityPopup.result = null;
				this.testSensitivityInProcess = true;
				this.gs.uploadFile('/admin/sensitivity/test/file', {file: this.testSensitivityPopup.file}, null, false).then((res:any) => {
					if (res && res.data) {
						this.testSensitivityPopup.fileData = {
							name: this.testSensitivityPopup.file.name,
							isScanned: true
						};
						this.testSensitivityPopup.result = res.data;

						this.testSensitivityInProcess = false;
					}
				}, err => {
					this.testSensitivityInProcess = false;
				});
				break;
		}
	};

	openTestSensitivityPopup() {
		this.testSensitivityPopup = {
			type: this.dic.CONSTANTS.sensitivityAction.file,
			text: '',
			file: null,
			result: null,
			show: true
		};
	};

	handleKeypress(event: KeyboardEvent, condition: any) {
		if (event.keyCode === 13 && this.valuesPopup.input && !condition.invalidInput) {
			this.isValueAddedToList(condition);
		}
	}

	// fires when a value is added to the table list in condition popup
	isValueAddedToList(condition) {
		// validation:
		if (!this.valuesPopup.input || condition.invalidInput)
			return;

		this.valuesPopup.input = this.valuesPopup.input.toLowerCase();
		if (_.includes(this.valuesPopup.currentList , this.valuesPopup.input)){
			this.ns.showErrorMessage(this.valuesPopup.input + " already exists in list");
			return;
		}
		// end

		this.valuesPopup.currentList.push(this.valuesPopup.input);
		this.valuesPopup.input = '';
	}

	isValueChanged(condition) {
		this.ns.closeMessage();
		switch (condition.type) {
			case this.rulesOptions.types.addresses.name:
				condition.invalidInput = !this.gs.validateEmail(this.valuesPopup.input) && condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch;
				break;
			case this.rulesOptions.types.domains.name:
			case this.rulesOptions.types.all_domains.name:
				condition.invalidInput = !this.gs.isValidDomain(this.valuesPopup.input);
				break;
			case this.rulesOptions.types.keywords.name:
				condition.invalidInput = false;
				break;
			case this.rulesOptions.types.mta.name:
				/*				if (condition.subType['IP']) {
									condition.invalidInput = !this.gs.isValidIPAddress(this.valuesPopup.input);
								}
								else if (condition.subType['CIDR']) {
									condition.invalidInput = !this.gs.isCIDRAddress(this.valuesPopup.input, 24);
								}
								else {
									condition.invalidInput = false;
								}*/
				condition.invalidInput = false;
				break;
		}
	};

	applyPopupCondition(condition) {
		let dataKey;
		switch (condition.type) {
			case this.rulesOptions.types.addresses.name:
				dataKey = 'emails';
				break;
			case this.rulesOptions.types.domains.name:
			case this.rulesOptions.types.all_domains.name:
				dataKey = 'domains';
				break;
			case this.rulesOptions.types.keywords.name:
				dataKey = 'keywords';
				break;
			case this.rulesOptions.types.mta.name:
				dataKey = 'value';
				break;
		}

		const dataSrc = condition.isException ? this.newRule.exceptions : this.newRule.conditions;

		if (condition.type !== this.rulesOptions.types.email_header.name){
			condition.data[dataKey] = _.cloneDeep(this.valuesPopup.currentList);
		}
		else {
			condition.values.value = _.cloneDeep(this.valuesPopup.currentList);
		}

		this.isConditionDataChanged(dataSrc); // <-- validation
		this.valuesPopup.show = false;
	}

	isValueRemoved(condition , value) {
		this.valuesPopup.currentList = _.filter(this.valuesPopup.currentList , v => {return v !== value});
	};

	exportConditionListToCsv(condition) {
		let csvString = "";
		const list = this.valuesPopup.currentList;

		switch (condition.type) {
			case this.rulesOptions.types.domains.name:
			case this.rulesOptions.types.all_domains.name:
				csvString = "Domain";
				break;

			case this.rulesOptions.types.keywords.name:
				csvString = "Keyword";
				break;

			case this.rulesOptions.types.addresses.name:
				csvString = "Email";
				break;

			case this.rulesOptions.types.email_header.name:
				csvString = "Header";
				break;

			case this.rulesOptions.types.mta.name:
				csvString = (condition.subType['IP'] ? 'IP Address' : condition.subType['CIDR'] ? 'CIDR Address' : 'Hostname');
				break;
		}

		if (!list.length) {
			return;
		}

		csvString += "\n";

		list.forEach((item) => {
			csvString += `${item}\n`;
		});

		this.gs.exportCsv(csvString, `Outbound_Rule_Condition_${condition.type}.csv`);
	};

	showAttachmentTypesPopup(condition) {
		const attachmentTypesList = {};
		let all_types;

		_.map(Object.keys(this.fileTypes),type => attachmentTypesList[type] = {enabled : false}); // "enabled" is false by default before all checks

		// if condition is in edit
		if (condition.data.attachment_types && (condition.data.attachment_types.length || condition.data.all_types)){
			if (condition.data.all_types) {
				all_types = true;
				_.map(Object.keys(this.fileTypes),type => attachmentTypesList[type] = {enabled : true});
			}
			else {
				all_types = false;
				_.map(Object.keys(this.fileTypes),type => attachmentTypesList[type] = {enabled : condition.data.attachment_types.includes(type.toString())});
			}
		}
		// if condition is new
		else {
			all_types = false;
		}

		this.conditionInEdit = condition;

		this.attachmentTypesPopup = {
			all_types,
			attachmentTypesList,
			attachmentTypesSearchTxt: '',
			fileTypesList: _.cloneDeep(this.fileTypes),
			show: true
		};
	}

	applyAttachmentTypesPopup() {
		const condition = this.conditionInEdit;

		// get the types that were enabled as an array of strings
		const enabledTypesArr = Object.keys(_.pickBy(this.attachmentTypesPopup.attachmentTypesList,type => type.enabled));
		condition.data["attachment_types"] = enabledTypesArr;
		condition.data["all_types"] = this.attachmentTypesPopup.all_types;

		const dataSrc = condition.isException ? this.newRule.exceptions : this.newRule.conditions;
		this.isConditionDataChanged(dataSrc); // <-- validation

		this.attachmentTypesPopup = null;
	}

	selectSingleSubType(condition, selectedSubTypeKey) {
		_.keys(condition.subType).forEach(subTypeKey => {
			condition.subType[subTypeKey] = subTypeKey === selectedSubTypeKey;
		});
	};

	subtypeChanged(condition) {
		this.isInvalidRuleData = false;
		condition.invalidSubTypes = false;
		condition.subTypeShow = this.parseSubType(condition.subType);
		this.checkRemoveKeywords();
	};

	changeMatchType(matchType, condition) {
		if (condition.data.matchType === matchType) return;
		condition.data.matchType = matchType;
		this.isInvalidRuleData = false;
	};

	showPopup(condition) {
		this.conditionInEdit = condition;
		this.valuesPopup = {name: '', placeholder: '', subtitle: '', currentList: [], loading: false, error: '', input: '', show: false, importFile: null, lateScroll: 30, loadMoreInProcessTS: null};
		switch (condition.type) {
			case this.rulesOptions.types.addresses.name:
				this.valuesPopup.name = 'emails';
				this.valuesPopup.singular = 'email';
				this.valuesPopup.placeholder = 'Email';
				if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
					this.valuesPopup.subtitle = 'This list accepts only valid and complete email addresses';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.startsWith) {
					this.valuesPopup.subtitle = 'This list accepts any value that an email address can start with';
					this.valuesPopup.placeholder = 'Email starts with';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.endsWith) {
					this.valuesPopup.subtitle = 'This list accepts any value that an email address can ends with';
					this.valuesPopup.placeholder = 'Email ends with';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.contains) {
					this.valuesPopup.subtitle = 'This list accepts any value that can be found in an email address';
					this.valuesPopup.placeholder = 'Email contains';
				}
				this.valuesPopup.currentList = _.cloneDeep(condition.data.emails) || [];
				break;

			case this.rulesOptions.types.domains.name:
			case this.rulesOptions.types.all_domains.name:
				this.valuesPopup.name = 'domains';
				this.valuesPopup.singular = 'domain';
				this.valuesPopup.placeholder = 'Domain';
				this.valuesPopup.subtitle = 'This list accepts only valid domains';
				this.valuesPopup.currentList = _.cloneDeep(condition.data.domains) || [];
				break;

			case this.rulesOptions.types.keywords.name:
				this.valuesPopup.name = 'keywords';
				this.valuesPopup.singular = 'keyword';
				this.valuesPopup.placeholder = 'Keyword';
				this.valuesPopup.subtitle = '';
				this.valuesPopup.currentList = _.cloneDeep(condition.data.keywords) || [];
				break;

			case this.rulesOptions.types.email_header.name:
				this.valuesPopup.name = 'headers';
				this.valuesPopup.singular = 'header';
				this.valuesPopup.placeholder = 'Header';
				if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
					this.valuesPopup.subtitle = 'This list accepts full email header values';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.contains) {
					this.valuesPopup.placeholder = 'Header contains';
					this.valuesPopup.subtitle = 'This list accepts any string that can be found in an email header value';
				}
				this.valuesPopup.currentList = _.cloneDeep(condition.values.value) || [];
				break;

			case this.rulesOptions.types.mta.name:
				this.valuesPopup.name = (condition.subType['IP'] ? 'IP addresses' : condition.subType['CIDR'] ? 'CIDR addresses' : 'hostnames');
				this.valuesPopup.singular = (condition.subType['IP'] ? 'IP address' : condition.subType['CIDR'] ? 'CIDR address' : 'Hostname');
				this.valuesPopup.placeholder = (condition.subType['IP'] ? 'IP address' : condition.subType['CIDR'] ? 'CIDR address' : 'Hostname');
				this.valuesPopup.subtitle = (condition.subType['IP'] ? 'This list accepts only valid IP addresses' : condition.subType['CIDR'] ? ' This list accepts only valid CIDR addresses' : '');
				this.valuesPopup.currentList = _.cloneDeep(condition.data.value) || [];
				break;
		}

		this.valuesPopup.input = '';
		this.valuesPopup.show = true;
	}

	openGroupsPopup(show, condition) {
		if (show) {
			this.conditionInEdit = condition;
			if (this.conditionInEdit.data && this.conditionInEdit.data.groups) {
				this.selectedGroupsRule = this.conditionInEdit.data.groups.map(itm => itm.email);
			}
		}
		this.showGroups = show;
	};

	cancelNewRuleCondition(conditionOrIndex, conditionArrIndexInRule) {
		if (this.conditionArr.length === 1) {
			const dataSrc = this.conditionArr[0].isException ? this.newRule.exceptions : this.newRule.conditions;
			dataSrc.splice(conditionArrIndexInRule, 1);
		}
		else {
			this.conditionArr.splice(conditionOrIndex, 1);
		}

		if (this.newRule.conditions.length && (this.newRule.conditions.length !== 1 || (this.newRule.conditions.length === 1 && !this.newRule.conditions[0].result))) {
			this.isConditionDataChanged(this.newRule.conditions);
		}
	};

	clearSearchAttachmentTypes(condition) {
		this.conditionInEdit = condition;
		this.attachmentTypesPopup.fileTypesList = _.cloneDeep(this.fileTypes);
		this.attachmentTypesPopup.attachmentTypesSearchTxt = '';
	};

	searchAttachmentTypes(searchTerm, condition) {
		if (!searchTerm || searchTerm === '') {
			this.clearSearchAttachmentTypes(condition);
			return;
		}
		searchTerm = searchTerm.toLowerCase();

		// filter:
		this.attachmentTypesPopup.fileTypesList = _.reduce(this.fileTypes,(res,typeVal,typeKey) => {
			if (typeVal.display && typeVal.display.toLowerCase().indexOf(searchTerm) >= 0){
				res[typeKey] = typeVal;
				return res;
			}
			else {
				return res;
			}
		},{});
	};

	importConditionListFromCsv(file, condition) {
		if (!file) return;
		this.valuesPopup.loading = true;
		let header, regex, optionalHeaders = null;
		const originalList = this.valuesPopup.currentList;
		const validationFuncAlwaysTrue = () => {return true};
		let validationFunctions = [];
		switch (condition.type) {
			case this.rulesOptions.types.domains.name:
			case this.rulesOptions.types.all_domains.name:
				header = 'domain';
				validationFunctions.push(this.gs.isValidDomain);
				regex = this.dic.CONSTANTS.DOMAIN_REGEX;
				break;

			case this.rulesOptions.types.keywords.name:
				header = 'keyword';
				validationFunctions.push(validationFuncAlwaysTrue);
				break;

			case this.rulesOptions.types.email_header.name:
				header = 'header';
				validationFunctions.push(validationFuncAlwaysTrue);
				break;

			case this.rulesOptions.types.addresses.name:
				header = 'email';
				if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch)
					validationFunctions.push(this.gs.validateEmail);
				else
					validationFunctions.push(validationFuncAlwaysTrue);
				break;

			case this.rulesOptions.types.mta.name:
				header = (condition.subType['IP'] ? 'ip address' : condition.subType['CIDR'] ? 'cidr address' : 'hostname');
				validationFunctions.push(validationFuncAlwaysTrue);
				break;
		}
		this.gs.readCsv(file, [header], optionalHeaders, regex, validationFunctions, (err, results) => {
			this.valuesPopup.file = null;

			if (err) {
				this.ns.showErrorMessage(err);
				this.valuesPopup.loading = false;
				return;
			}
			if (!results || !results[header] || !results[header].length) {
				this.ns.showErrorMessage(this.dic.ERRORS.noNewResult);
				this.valuesPopup.loading = false;
				return;
			}

			// Add values to list:
			let newList = _.cloneDeep(originalList);

			for (let i = 0; i < results[header].length; i++) {
				if (!_.includes(newList,results[header][i]))
					newList.push(results[header][i])
			}

			if (newList.length === originalList.length){
				this.ns.showErrorMessage(this.dic.ERRORS.noNewResult);
				return;
			}

			this.valuesPopup.currentList = _.cloneDeep(newList);
			this.valuesPopup.loading = false;
		});
	};

	openSensitivityTypesPopup(condition) {
		if (!this.sensitivity_types?.length) {
			return
		}

		_.map(this.sensitivity_types, t => {
			t.selected = false;
			if (!t.displayName) {
				t.displayName = `${t.name}${t.type === 'Custom' ? ' (Custom)' : ''}`
			}
			return t;
		});

		if (!condition.data.sensitivity_types) {
			condition.data.sensitivity_types = [];
		}

		this.conditionInEdit = condition;
		this.modalData = {
			openSensitivityTypesPopup: true
		};
	};

	updateTypes(types, newTypes) {
		this.conditionInEdit.data.sensitivity_types = types;
		if (newTypes && newTypes.length) {
			this.sensitivity_types = this.sensitivity_types.concat(newTypes);
		}
		this.isInvalidRuleData = false;
	};

	cancelSensitivityTypesPopup() {
		this.modalData.openSensitivityTypesPopup = false;
	}

	getDataGroupsNames(condition) {
		return _.map(condition.data.groups, g => {return g.displayName});
	}

	updateEmailMethodsShow(emailMethods) {
		emailMethods.emailMethodsShow = [];
		emailMethods.emailMethodsShow = _.chain(emailMethods)
			.filter(method => method.enable)
			.map(method => {

				return method.key;
			})
			.value();
	}

	updateComplianceShow(conditionData) {
		conditionData.complianceShow = [];
		_.each(Object.keys(conditionData.compliance), (key) => {
			if (conditionData.compliance[key] && conditionData.compliance[key].enabled) {
				conditionData.complianceShow.push(key);
			}
		});
		this.isInvalidRuleData = false;
	};

	applyGroupsPopup(groups) {
		let selectedGroups = [];
		_.each(groups, group => {
			if (group.selected) {
				selectedGroups.push({email: group.email, displayName: group.displayName});
			}
		});
		this.conditionInEdit.data.groups = selectedGroups;
		this.showGroups = false;
	};
}
