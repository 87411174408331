import { Component } from '@angular/core';

@Component({
  selector: 'settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.scss']
})
export class SettingsListComponent {

}
