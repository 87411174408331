import * as util from 'util';
import _ from 'lodash';
import {GeneralService} from '../../../../services/generalService';
import {RouteService} from '../../../../services/routeService';
import {NotificationService} from '../../../../services/notificationService';
import {DICTIONARY} from '../../../../dictionary';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {ComposeMessageService} from "../../../../services/composeMessageService";
import html2canvas from "html2canvas";
import {DropdownComponent} from "../../../uiComponents/dropdown/dropdown.component";

@Component({
	selector: 'threat-simulation-campaign-wizard',
	templateUrl: './threat-simulation-campaign-wizard.component.html',
})
export class ThreatSimulationCampaignWizardComponent implements OnInit {

	@Input() allCampaignGroups;
	@Input() verifiedDomains;
	@Input() relaunchCampaign: any;
	@Output() cancelCb = new EventEmitter<any>;
	@Output() doneCb = new EventEmitter<any>;
	@ViewChild('templatesDropdownEl') templatesDropdownEl : DropdownComponent;

	dic = DICTIONARY;
	step = this.dic.CONSTANTS.threatSimulationNewCampaignSteps.generalInformation;
	userInfo: any;
	templates;
	userLogoDetails;
	previewTemplateInProcess;
	previewTemplateData = {
		name: '',
		content: '',
		virtualContent: ''
	};
	loadCampaignDataInProcess;
	createNewCampaignInProcess;
	isMultipleTemplates;
	allPossibleRecipients;
	showGroups = true;
	showMailboxes = true;
	hours = ['00','01','02','03','04','05','06','07','08','09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
	minutes = ['00', '05','10','15','20','25','30','35','40','45','50','55'];
	_ = _;
	popupError = '';
	now = new Date();
	minScheduleTime = new Date(this.now.getTime() - (this.now.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
	data = {
		name: '',
		description: '',
		addToGroup: {
			method: 'disabled', // 'disabled', 'existing', 'new'
			groupName: '',
			newGroupName: ''
		},
		selectedTemplate: null,
		selectedRecipients: [],
		options: {
			expired_days: 7,
			notify_on_link_clicked: false,
			send_report_when_completed: false,
			send_training_when_completed: false,
			enable_security_awareness_for_mailbox: false,
			security_awareness_for_mailbox_expire_days: 7,
			scheduled_time_enabled: false,
			scheduled_time: this.minScheduleTime,
			enable_rate_email_delivery_hours_duration: false,
			rate_email_delivery_hours_duration: 7,
		},
	};

	recipientsLimitInSummary = 20;
	templatesScreenshots = {};
	launchedSuccessfully;
	createdCampaignId;
	showDynamicFieldsPopup;

	threatSimulationArray = Object.entries(this.dic.CONSTANTS.threatSimulationType).map(([key, value]) => ({
		key,
		...value
	}));

	constructor(public gs:GeneralService,
				private rs:RouteService,
				private composeMessageService:ComposeMessageService,
				private ns:NotificationService) {
	}

	ngOnInit() {
		this.gs.getUserInfo(false,  (userInfo) => {
			this.userInfo = userInfo;
			this.userLogoDetails = userInfo.plan.customization.lf;
		});

		this.getDataForWizard(); // templates, contacts
	};

	getDataForWizard = () => {
		this.loadCampaignDataInProcess = true;
		const promises = [this.getCampaignRecipients(), this.getTemplates()];
		if (this.relaunchCampaign) {
			promises.push(this.getRelaunchCampaignSummary());
		}
		Promise.all(promises)
			.then(results => {
				this.loadCampaignDataInProcess = false;

				if (this.relaunchCampaign) {
					this.getDataFromRelaunchedCampaign();
				}
			})
			.catch(error => {
				this.loadCampaignDataInProcess = false;
			});
	}

	getRelaunchCampaignSummary = () => {
		return new Promise(resolve => {
			this.rs.getCampaignSummary(this.relaunchCampaign._id).then((response) => {
				this.relaunchCampaign.recipients = response.recipients;
				resolve(response.recipients);
			}, (err) => {
			});
		});
	}

	getDataFromRelaunchedCampaign = () => {
		this.data.name = this.relaunchCampaign.name;
		this.data.description = this.relaunchCampaign.description;

		// set group
		if (this.relaunchCampaign.group_name) {
			this.data.addToGroup.method = 'existing';
			this.data.addToGroup.groupName = this.relaunchCampaign.group_name;
		}


		// set template
		if (this.relaunchCampaign.template_name && this.templates) {
			//get template from the list
			const template = this.templates.find(itm => itm.name === this.relaunchCampaign.template_name);
			this.data.selectedTemplate = template;
		}

		// set recipients
		if (this.relaunchCampaign.recipients_count && this.relaunchCampaign.recipients) {
			this.relaunchCampaign.recipients.forEach(recipient => {
				const recipientObj = _.find(this.allPossibleRecipients, {email: recipient.email});
				if (recipientObj) {
					recipientObj.selected = true;
				}
			});
		}

		// set configuration
		this.data.options.expired_days = this.relaunchCampaign.configuration.expired_days;
		this.data.options.notify_on_link_clicked = this.relaunchCampaign.configuration.notify_on_link_clicked;
		this.data.options.enable_security_awareness_for_mailbox = this.relaunchCampaign.configuration.enable_security_awareness_for_mailbox;
		this.data.options.security_awareness_for_mailbox_expire_days = this.relaunchCampaign.configuration.security_awareness_for_mailbox_expire_days;
		this.data.options.send_report_when_completed = this.relaunchCampaign.configuration.send_report_when_completed;
		this.data.options.send_training_when_completed = this.relaunchCampaign.configuration.send_training_when_completed;

		// set Campaign pace
		if (this.relaunchCampaign.configuration.rate_email_delivery_hours_duration) {
			this.data.options.enable_rate_email_delivery_hours_duration = true;
			this.data.options.rate_email_delivery_hours_duration = this.relaunchCampaign.configuration.rate_email_delivery_hours_duration;
		}

		// set schedule
		if (this.relaunchCampaign.configuration.scheduled_time_enabled) {
			this.data.options.scheduled_time_enabled = true;

			const date = new Date(this.relaunchCampaign.configuration.scheduled_time);
			this.data.options.scheduled_time = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
		}
	}

	getTemplates = (): Promise<any> => {
		return new Promise(resolve => {
			this.rs.getThreatSimulationTemplates().then((templates) => {
				this.templates = templates.filter(itm => !itm.upcoming);
				resolve(templates);
			}, (err) => {
			});
		});
	}

	getCampaignRecipients = (): Promise<any> => {
		return new Promise(resolve => {
			this.rs.getCampaignRecipients().then((recipients) => {
				this.allPossibleRecipients = recipients.groups.concat(recipients.mailboxes);
				resolve(recipients);
			}, (err) => {
			});
		});
	}

	backStep = () => {
		this.popupError = '';

		switch(this.step) {
			case this.dic.CONSTANTS.threatSimulationNewCampaignSteps.selectTemplate:
				this.step = this.dic.CONSTANTS.threatSimulationNewCampaignSteps.generalInformation;
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignSteps.selectRecipients:
				this.step = this.dic.CONSTANTS.threatSimulationNewCampaignSteps.selectTemplate;
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignSteps.configuration:
				this.step = this.dic.CONSTANTS.threatSimulationNewCampaignSteps.selectRecipients;
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignSteps.summary:
				this.step = this.dic.CONSTANTS.threatSimulationNewCampaignSteps.configuration;
				break;
		}
	}

	nextStep = () => {
		this.popupError = '';

		switch (this.step) {
			case this.dic.CONSTANTS.threatSimulationNewCampaignSteps.generalInformation:
				if (!this.data.name) {
					this.popupError = this.dic.ERRORS.fillAllRequired;
					return;
				}

				switch (this.data.addToGroup.method) {
					case "disabled":
						this.data.addToGroup.groupName = '';
						this.data.addToGroup.newGroupName = '';
						break;

					case "existing":
						if (!this.data.addToGroup.groupName) {
							this.popupError = this.dic.ERRORS.fillAllRequired;
							return;
						}
						this.data.addToGroup.newGroupName = '';
						break;

					case "new":
						if (!this.data.addToGroup.newGroupName) {
							this.popupError = this.dic.ERRORS.fillAllRequired;
							return;
						}
						if (_.some(this.allCampaignGroups, {name: this.data.addToGroup.newGroupName})){
							this.popupError = util.format(this.dic.ERRORS.itemAlreadyExist, 'Group with same name');
							return;
						}

						this.data.addToGroup.groupName = '';
						break;
				}

				this.step = this.dic.CONSTANTS.threatSimulationNewCampaignSteps.selectTemplate;

				if (this.data.selectedTemplate) {
					setTimeout(() => {
						this.selectTemplate(this.data.selectedTemplate, false);
					});
				}
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignSteps.selectTemplate:
				if ((!this.isMultipleTemplates && !this.data.selectedTemplate) || (this.isMultipleTemplates && !_.some(this.templates, 'selected'))) {
					this.popupError = util.format(this.dic.ERRORS.noSelected, 'template');
					return;
				}

				this.step = this.dic.CONSTANTS.threatSimulationNewCampaignSteps.selectRecipients;
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignSteps.selectRecipients:
				this.data.selectedRecipients = _.filter(this.allPossibleRecipients, 'selected');
				if (!this.data.selectedRecipients.length) {
					this.popupError = util.format(this.dic.ERRORS.selectAtLeastOneX, 'recipient');
					return;
				}

				// validate domains
				const notValidatedDomain = _.find(this.data.selectedRecipients, recipient => !this.verifiedDomains?.includes(this.gs.getEmailDomain(recipient.email)));
				if (notValidatedDomain) {
					this.popupError = util.format(this.dic.ERRORS.domainNotVerifiedForX, notValidatedDomain.email);
					return;
				}

				this.step = this.dic.CONSTANTS.threatSimulationNewCampaignSteps.configuration;
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignSteps.configuration:
				if (this.data.options.expired_days !== 0 && !this.data.options.expired_days) {
					this.popupError = this.dic.ERRORS.fillAllRequired;
					return;
				}
				if (this.data.options.expired_days < 1 || this.data.options.expired_days > 365) {
					this.popupError = this.dic.ERRORS.expiredInputInvalid;
					return;
				}
				if (this.data.options.enable_security_awareness_for_mailbox) {
					if (this.data.options.security_awareness_for_mailbox_expire_days < 1 || this.data.options.security_awareness_for_mailbox_expire_days > 365) {
						this.popupError = this.dic.ERRORS.expiredInputInvalid;
						return;
					}
				}

				this.step = this.dic.CONSTANTS.threatSimulationNewCampaignSteps.summary;
				break;
		}
	}

	drawTemplateScreenshot = (templateName) => {
		if (this.templatesScreenshots[templateName]) {
			document.getElementById('templateScreenshotContainer_' + templateName).appendChild(this.templatesScreenshots[templateName]);
		}
	}

	previewLandingPage = (templateObj) => {
		window.open(templateObj.lookup_links[0].link, '_blank');
	}

	toggleTemplatesDropdown = () => {
		this.popupError = '';

		setTimeout(() => {
			this.templatesDropdownEl.dropdownClicked();
		});
	}

	selectTemplate = (template, isItemSelectCheckboxClicked) => {
		// if user clicked on the checkbox to only select the template (when multiple selection), don't preview the template on screen
		if (isItemSelectCheckboxClicked) {
			this.popupError = '';
			if (template.selected) {
				this.previewTemplate(template, true);
			}

			return;
		}

		template.selected = !template.selected;
		this.data.selectedTemplate = template;
		this.previewTemplate(template);
	}

	testTemplate = (templateObj) => {
		this.gs.showPopup({
			title: `Test Template`,
			body: ['<a class="blue-link flex-row gap-1 align-items-center fw-bold" href="https://docs.trustifi.com/docs/defender-settings-in-office365" target="_blank">' +
			'<span style="font-size: 12px;">Review the full guide</span>' +
			'<i class="fas fa-external-link-alt" style="font-size: 10px;"></i>' +
			'</a>'],
			subTitle: 'A test email of this campaign will be sent to your mailbox to verify deliverability. If the email was not delivered, please check your configuration',
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'Test',
			doneCb: () => {
				const campaignTestObj = {
					name: 'test', description: 'test', rcptTo: [{email: this.userInfo.email}],
					emailData: templateObj
				};
				this.rs.testCampaignTemplate(campaignTestObj).then(response => {
					this.ns.showInfoMessage(`Template sent to ${this.userInfo.email}`);
				});
			}
		});
	}

	multipleTemplatesCampaignExecute = () => {
		const selectedTemplates = _.filter(this.templates, 'selected');
		if (selectedTemplates.length === 1) {
			this.newCampaignExecute(this.data);
			return;
		}

		const recipientsDistributedObj = this.distributeRecipientsInMultipleTemplates(selectedTemplates);
		const recipientsCount = _.flatten(_.values(recipientsDistributedObj)).length;

		const groupData = {
			method: null,
			newGroupName: null,
			groupName: this.data.addToGroup.groupName || this.data.addToGroup.newGroupName || (recipientsCount > 1 ? this.data.name : '')
		};
		selectedTemplates.forEach(template => {
			const newData = _.cloneDeep(this.data);

			newData.selectedTemplate = template;
			newData.selectedRecipients = recipientsDistributedObj[template.name];
			newData.addToGroup = groupData;
			newData.name = template.name;
			if (newData.selectedRecipients?.length) {
				this.newCampaignExecute(newData);
			}
		});
	}

	distributeRecipientsInMultipleTemplates(templates) {
		const recipients = _.uniqBy(_.flatMapDeep(this.data.selectedRecipients, recipientOrGroup => {
			if (recipientOrGroup.members?.length) {
				return _.map(recipientOrGroup.members, groupMember => {
					return {email: groupMember.email, name: groupMember.name}
				});
			}
			else {
				return {email: recipientOrGroup.email, name: recipientOrGroup.name};
			}
		}), 'email');

		const shuffledRecipients = _.shuffle(recipients);

		const assignments = {};

		templates.forEach(template => {
			assignments[template.name] = [];
		});

		let currentTemplateIndex = 0;
		shuffledRecipients.forEach(recipient => {
			assignments[templates[currentTemplateIndex].name].push(recipient);
			currentTemplateIndex = (currentTemplateIndex + 1) % templates.length;
		});

		return assignments;
	}

	newCampaignExecute = (data) => {
		this.createNewCampaignInProcess = true;

		const recipients = _.uniqBy(_.flatMapDeep(data.selectedRecipients, recipientOrGroup => {
			if (recipientOrGroup.members?.length) {
				return _.map(recipientOrGroup.members, groupMember => {
					return {email: groupMember.email, name: groupMember.name}
				});
			}
			else {
				return {email: recipientOrGroup.email, name: recipientOrGroup.name};
			}
		}), 'email');


		const campaignInfo = {
			name: data.name,
			description: data.description,
			emailData: data.selectedTemplate,
			rcptTo: recipients,
			group_name: data.addToGroup.groupName || data.addToGroup.newGroupName || '',
			options: {
				expired_days: data.options.expired_days,
				send_report_when_completed: data.options.send_report_when_completed,
				send_training_when_completed: data.options.send_training_when_completed,
				notify_on_link_clicked: data.options.notify_on_link_clicked,
				enable_security_awareness_for_mailbox: data.options.enable_security_awareness_for_mailbox,
				security_awareness_for_mailbox_expire_days: data.options.security_awareness_for_mailbox_expire_days,
				scheduled_time_enabled: data.options.scheduled_time_enabled,
				scheduled_time: new Date(data.options.scheduled_time).toISOString(),
				rate_email_delivery_hours_duration: data.options.enable_rate_email_delivery_hours_duration && data.options.rate_email_delivery_hours_duration,
			}
		};

		this.rs.createThreatSimulationCampaign(campaignInfo).then((response) => {
			this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemCreated, 'Campaign "<b>' + campaignInfo.name + '</b>"'));
			this.launchedSuccessfully = true;
			this.createdCampaignId = response._id;
			this.createNewCampaignInProcess = false;
		}, (err) => {
			this.createNewCampaignInProcess = false;
		});
	}

	selectLandingPage = (templateObj, landingPage) => {
		templateObj.ts_type = landingPage.display;

		if (templateObj.ts_type === this.dic.CONSTANTS.threatSimulationType.training.display) {
			templateObj.html = templateObj.html.replace(/login\.html/g, 'landing.html');
		}
		else {
			templateObj.html = templateObj.html.replace(/landing\.html/g, 'login.html');
		}

		templateObj.lookup_links.forEach((linkObj) => {
			if (templateObj.ts_type === this.dic.CONSTANTS.threatSimulationType.training.display) {
				linkObj.link = linkObj.link.replace('login.html', 'landing.html');
			}
			else {
				linkObj.link = linkObj.link.replace('landing.html', 'login.html');
			}
		});

		this.previewTemplate(templateObj);
	}

	previewTemplate = (templateObj, isVirtualOnly = false) => {
		if (!templateObj) {
			return;
		}

		this.previewTemplateData.name = templateObj.name;
		this.previewTemplateData.virtualContent = this.composeMessageService.getSummernoteContent(templateObj);
		if (!isVirtualOnly) {
			this.previewTemplateData.content = this.composeMessageService.getSummernoteContent(templateObj);
		}
	};

	captureTemplateScreenshot = (iframeDoc) => {
		if (!this.previewTemplateData.name) {
			return;
		}

		html2canvas(iframeDoc.body).then(canvas => {
			canvas.style.height = '100%';
			canvas.style.width = '100%';

			this.templatesScreenshots[this.previewTemplateData.name] = canvas;
		}, err => {
			console.error(err);
		});
	}

	// trigger the trustifi-table search by changing the list. The directive detects changes and fires a search
	triggerSearchContactsAndGroups = () => {
		this.allPossibleRecipients = _.cloneDeep(this.allPossibleRecipients);
	}

	toggleExpandItem = (item, event) => {
		if (!item.members?.length) {
			return;
		}

		item.isExpanded = !item.isExpanded;
		event.stopPropagation();

		// refresh the trustifi-table by changing the list. The directive detects changes and fires an update
		this.triggerSearchContactsAndGroups();
	}

	searchCampaignRecipients = (searchTerm, activeFilters) => {
		this.allPossibleRecipients.forEach(record => {
			if ((record.members?.length && !this.showGroups) || (!record.members?.length && !this.showMailboxes)) {
				record.isExpanded = false;
				record.hide = true;
				return;
			}

			// search
			let isFound = searchTextExecute(record, searchTerm);
			if (!isFound) {
				record.hide = true;
				return;
			}

			record.hide = false;
		});
	};

	deselectRecipient = (contactObj) => {
		contactObj.selected = false;

		// trigger the trustifi-table search by changing the list. The directive detects changes and fires a search
		// needed to update the "select all" checkbox state
		this.allPossibleRecipients = _.cloneDeep(this.allPossibleRecipients);
	}

	updateDynamicFields = () => {
		this.showDynamicFieldsPopup = false;

		this.loadCampaignDataInProcess = true;

		this.getTemplates().then(results => {
			this.loadCampaignDataInProcess = false;
			this.data.selectedTemplate = this.templates.find(itm => itm.name === this.data.selectedTemplate.name);
			this.previewTemplate(this.data.selectedTemplate);
		})
		.catch(error => {
			this.loadCampaignDataInProcess = false;
		});
	}
}


function searchTextExecute(record, searchTerm) {
	return ((record.name?.toLowerCase().indexOf(searchTerm) > -1) ||
		(record.email?.toLowerCase().indexOf(searchTerm) > -1))
}
