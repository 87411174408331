
import {DICTIONARY} from '../../../../dictionary';
import {RouteService} from '../../../../services/routeService';
import {NotificationService} from '../../../../services/notificationService';
import {GeneralService} from '../../../../services/generalService';
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'region-component',
	templateUrl: './region.component.html',
})
export class RegionComponent implements OnInit {
    dic = DICTIONARY;
    regions = this.gs.isProductionEnv() ?
        [
            "USA-West",
            "Europe-Ireland",
            "Europe-Frankfurt",
            "Asia-Pacific-Singapore",
            "Canada-Central",
            "South-America-Sao-Paulo",
			"Australia-Sydney"
        ] :
        ["Europe-Ireland", "Europe-Frankfurt", "Asia-Pacific-Singapore"];
    userInfo: any;

    constructor(private gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
    }

	ngOnInit() {
        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
        });
    };

    changeRegion = (regionName) => {
        if (this.userInfo.plan.use_region === regionName) {
            return;
        }

        this.gs.showPopup({
            title: 'Change Region',
            subTitle: `Please note - your new secure data region will be ${regionName}`,
            body: ['This will not affect secure data which was previously saved in another region.'],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Change',
            doneCb: () => {
                this.rs.changeRegion({region: regionName}).then(res => {
                    this.userInfo.plan.use_region = regionName;
                    this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
                });
            },
        });
    };
}
