<div class="flxClmn bs-enabled" >

	<div class="page-title-container flxClmn-center" style="z-index: 45;">
		<div class="page-title-inner-container">
			<span class="page-title">Domain Metrics</span>
		</div>
		<div class="page-subtitle-container">
			<span>Monitor potential impersonation attempts and potentially suspicious MX changes for your domains, and alert you to take actions.<br/>
			Trustifi will monitor these metrics for your verified domains, and domains from your email server tenant.</span>
		</div>
	</div>

	<div *ngIf="domainConfigurations" class="page-inner-content-holder flxClmn" [ngClass]="{'no-scroll' : selectedViewType === viewTypes.cards}">
		<div class="flxClmn f-1-0">
			<div class="flex-row align-items-center mt-2 mb-3 gap-3">
				<span style="flex-basis: 210px;">
					Domain impersonation protection
					<i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
					   tooltip='When an impersonation attempt is detected, all information about the suspicious domain will be displayed here along with actions you can take'></i>
					<i class="fas fa-arrow-to-left opacity-50"></i>
				</span>
				<toggle-switch-c id="enabled"
								 [(model)]="domainConfigurations.enabled"
								 [hideText]="loadingIncidentsInProcess"
								 (onChange)="updateDomainImpersonationStatus()"></toggle-switch-c>
			</div>

			<div class="flex-row align-items-center mt-2 mb-3 gap-3">
				<span style="flex-basis: 210px;">
					Domain MX change detection
					<i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
					   tooltip='Domain MX Change Detection monitors when mail exchange (MX) records of a domain are modified, helping prevent email service disruptions and security risks'></i>
					<i class="fas fa-arrow-to-left opacity-50"></i>
				</span>
				<toggle-switch-c id="mx_detect_changes"
								 [(model)]="domainConfigurations.mx_detect_changes.enabled"
								 [hideText]="loadingIncidentsInProcess"
								 (onChange)="updateDomainMxDetectionStatus()"></toggle-switch-c>

				<btn-c [isDisabled]="!domainConfigurations.mx_detect_changes.enabled || alertSettingsPopup?.actionInProcess" (action)="showAlertSettingsPopup()">
					<i class="far fa-bell"></i>
					Alert Settings
				</btn-c>
			</div>

			<!--Loader-->
			<div class="d-flex centralize f-1-0" *ngIf="!(domainConfigurations.enabled || domainImpersonations?.length) && loadingIncidentsInProcess">
				<loader class="opacity-75" height="100" width="100"></loader>
			</div>

			<!--Feature not enabled placeholder-->
			<div class="f-1-0 flex-column centralize animated fadeIn" style="background: linear-gradient(45deg, #ffffff99 , #ffffff00,  #ffffff), linear-gradient(-45deg, #ffffff , #ffffff00,  #ffffff), linear-gradient(#163f5d0d, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff , #ffffff00 51%, transparent);"
				 *ngIf="!domainConfigurations.enabled && !domainConfigurations.mx_detect_changes?.enabled && !domainImpersonations?.length && !loadingIncidentsInProcess">
				<div class="flex-column centralize bg-white create-new-box gap-3" style="width: 90vh; height: 60vh; box-shadow: 0 2px 16px lightgrey;">
					<img style="margin-top: -8vh; width: 25vh; margin-bottom: 3vh;" src="images/impersonation-icon.png">
					<span style="font-size: clamp(14px, 2.5vh, 25px); max-width: 85%; text-align: center; margin-bottom: 3vh; line-height: 1.5;">Enable Domain Metrics to monitor and detect any suspicious activity for your internal domains</span>
					<btn-c class="popup-action-btn"
						   (action)="domainConfigurations.enabled = true; updateDomainImpersonationStatus(); domainConfigurations.mx_detect_changes.enabled = true; updateDomainMxDetectionStatus();">
						Enable Feature
					</btn-c>
				</div>
			</div>

			<div class="flex-column f-1-0 animated fadeIn" *ngIf="domainConfigurations.enabled || domainConfigurations.mx_detect_changes?.enabled || domainImpersonations?.length">
				<hr class="mt-0"/>

				<div class="flxRow hSpace1" style="margin-bottom: 15px; z-index: 50;">
					<btn-c variant="{{selectedViewType === viewTypes.cards ? '' : 'outlined'}}"
						   [isDisabled]="loadingIncidentsInProcess"
						   (action)="changeViewType(viewTypes.cards)">
						Show Cards
					</btn-c>
					<btn-c variant="{{selectedViewType === viewTypes.list ? '' : 'outlined'}}"
						   [isDisabled]="loadingIncidentsInProcess"
						   (action)="changeViewType(viewTypes.list)">
						Show List
					</btn-c>
					<div class="vertical-separator" style="align-self: stretch; margin: 0 20px; border-color: lightgrey;"
						 *ngIf="selectedViewType === viewTypes.cards"></div>
					<app-dropdown-c style="width: 300px;"
									*ngIf="selectedViewType === viewTypes.cards"
									[text]="selectedDomainsForCards?.displayName"
									[enableSearch]="true"
									itemDisplayKey="displayName"
									[items]="domainsInIncidentsList"
									(onItemClicked)="selectDomainForCards($event.item)"></app-dropdown-c>
				</div>

				<!--VIEW: list-->
				<div class="flxClmn f-1-0 animated fadeIn mt-3" style="overflow: auto;" *ngIf="selectedViewType === viewTypes.list">
					<trustifi-table-c class="f-1-0 flxClmn overflow-auto" style="min-height: 400px;"
					  [list]="_.filter(domainImpersonations, {metric_type: dic.CONSTANTS.domainMetricTypes.impersonation})"
					  [headers]="[
					  {text: 'Detection Date', sortKey: 'created'},
					  {text: 'Your Domain', sortKey: 'original_domain'},
					  {text: 'Suspected Domain', sortKey: 'impersonation_domain'},
					  {text: 'Domain Creation Date', sortKey: 'domain_creation_date'},
					  {text: 'Status', sortKey: 'status'},
					  {text: 'Certificate Issuer', sortKey: 'cert_issuer'},
					  {text: 'Hosting Website', sortKey: 'hosting_record'},
					  {text: 'Receives Email', sortKey: 'mail_exchange'},
					  {text: 'Similarity', sortKey: 'domain_similarity'},
				   ]"
					[cellsPrototype]="[
					  {textKey: 'created', textType: 'dateAndTime'},
					  {textKey: 'original_domain'},
					  {textKey: 'impersonation_domain', style: {'word-break' : 'break-word'}},
					  {textKey: 'domain_creation_date'},
					  {html: 'statusCell'},
					  {textKey: 'cert_issuer', placeHolder: 'N/A'},
					  {html: 'hostingRecordCell'},
					  {html: 'mailExchangeCell'},
					  {html: 'similarityCell', overflowOutside: true}
					]"
					(searchFunction)="searchDomains($event.searchTerm, $event.activeFilters)"
					[cellActions] = "{
						enable: true,
						selectItemActionCb:	selectDomainAction,
						initActionsFunction: showDomainActions
					}"
					[options]="{
						loadingTableFlag: loadingIncidentsInProcess,
						refreshFunction: getDomainImpersonations,
						exportToCsvFunction: exportDomainsToCsv,
						itemUniqueIdentifier: '_id',
						isEllipsis: false,
						defaultSortKey: '-created'
					}"
					>
						<ng-template #customCells let-item let-cellId="cellId">
							<!--action cells-->
							<div *ngIf="cellId === 'statusCell'">
								<span [ngStyle]="{'color': item.status === 'Safe' ? 'green' : item.status === 'Suspicious' ? 'orange' : 'red'}">{{item.status}}</span>
							</div>
							<div *ngIf="cellId === 'hostingRecordCell'">
								<span [ngStyle]="{'color': item.hosting_record?.length ? 'red' : 'green'}">
									{{!!item.hosting_record?.length}}
								</span>
								<i *ngIf="item.hosting_record?.length" style="font-weight: 400; position: relative;" class="fas fa-question-circle opacity-75"
								   [tooltip]="'IPs: '+item.hosting_record?.join(', ')"></i>
							</div>
							<div *ngIf="cellId === 'mailExchangeCell'">
								<span [ngStyle]="{'color': item.mail_exchange?.length ? 'red' : 'green'}">
									{{!!item.mail_exchange?.length}}
								</span>
								<i *ngIf="item.mail_exchange?.length" style="font-weight: 400; position: relative;" class="fas fa-question-circle opacity-75"
								   [tooltip]="'MX: '+item.mail_exchange?.join(', ')"></i>
							</div>
							<div *ngIf="cellId === 'similarityCell'">
								<div *ngIf="item.scoreChartOptions" class="flxRow-center mh-100" style="align-items: center; width: 200px; height: 60px;">
									<apx-chart class="f-1-0 mh-100 score-chart"
											   [series]="item.scoreChartOptions.series"
											   [colors]="item.scoreChartOptions.fill.colors"
											   [chart]="item.scoreChartOptions.chart"
											   [labels]="item.scoreChartOptions.labels"
											   [plotOptions]="item.scoreChartOptions.plotOptions"></apx-chart>
								</div>
							</div>
						</ng-template>
						<div tableButtons>
							<h5 class="m-0">Domain Impersonations</h5>
						</div>
					</trustifi-table-c>

					<trustifi-table-c class="f-1-0 flxClmn overflow-auto mt-5" style="min-height: 400px;"
									  [list]="_.filter(domainImpersonations, {metric_type: dic.CONSTANTS.domainMetricTypes.mxChange})"
									  [headers]="[
										  {text: 'Detection Date', sortKey: 'created', width: '135px'},
										  {text: 'Your Domain', sortKey: 'original_domain'},
										  {text: 'Old MX', sortKey: 'old_mx'},
										  {text: 'New MX', sortKey: 'new_mx'},
										  {text: 'Status', sortKey: 'status', width: '110px'},
									   ]"
									  [cellsPrototype]="[
										  {textKey: 'created', textType: 'dateAndTime'},
										  {textKey: 'original_domain'},
										  {textKey: 'old_mx'},
										  {textKey: 'new_mx'},
										  {html: 'statusCell'},
										]"
									  (searchFunction)="searchDomains($event.searchTerm, $event.activeFilters)"
									  [cellActions] = "{
											enable: true,
											selectItemActionCb:	selectDomainAction,
											initActionsFunction: showDomainActions
										}"
									  [options]="{
											loadingTableFlag: loadingIncidentsInProcess,
											refreshFunction: getDomainImpersonations,
											exportToCsvFunction: exportMxIncidentsToCsv,
											itemUniqueIdentifier: '_id',
											isEllipsis: false,
											defaultSortKey: '-created'
										}"
					>
						<ng-template #customCells let-item let-cellId="cellId">
							<!--action cells-->
							<div *ngIf="cellId === 'statusCell'">
								<span [ngStyle]="{'color': item.status === 'Safe' ? 'green' : item.status === 'Suspicious' ? 'orange' : 'red'}">{{item.status}}</span>
							</div>
						</ng-template>
						<div tableButtons>
							<h5 class="m-0">MX Change Detections</h5>
						</div>
					</trustifi-table-c>
				</div>

				<!--VIEW: cards-->
				<div class="flxRow gap-4 f-1-0 animated fadeIn bs-enabled" #cardsContainer style="overflow: auto; background: #fbfbfb;" *ngIf="selectedViewType === viewTypes.cards">
					<div class="d-flex centralize f-1-0" style="flex-grow:1; display: flex; background: white;" *ngIf="loadingIncidentsInProcess">
						<loader height="100" width="100" style="opacity: 0.8;"></loader>
					</div>

					<div class="flex-column" style="flex: 3 0; padding: calc((20px + 3vw)/2) calc((15px + 4vw)/2); font-family: 'Roboto', sans-serif;" *ngIf="!loadingIncidentsInProcess">
						<virtual-scroller class="f-1-0" #scroll *ngIf="incidentCards?.length" [items]="incidentCards" [scrollAnimationTime]="0"  [parentScroll]="cardsContainer" [enableUnequalChildrenSizes]="true">
							<div class="flex-row gap-4 mb-5" style="max-width: 1200px;" *ngFor="let incident of scroll.viewPortItems; let last = last; trackBy:gs.trackByIndex">

								<div class="flex-column align-items-center gap-2 mt-3">
									<div style="border-radius: 50px; border: solid 6px; background: hsl(var(--bs-primary-h), 100%, 95%); height: 23px; width: 23px;"
										 [ngStyle]="{'border-color': incident.color}"></div>

									<div>
										Status:<span [ngStyle]="{'color': incident.color}">&nbsp;{{incident.status}}</span>
									</div>

									<span class="bg-white py-1 px-2 border border-1 rounded-pill align-self-start">
										{{incident.created | date: 'MM/dd/yyyy HH:mm'}}
									</span>
								</div>

								<div class="flex-column f-1-0 gap-4 p-4 bg-white shadow-sm" *ngIf="incident.metric_type === dic.CONSTANTS.domainMetricTypes.impersonation">
									<div class="flex-row gap-2 align-items-baseline flex-wrap align-self-start">
										<span class="fw-bold text-danger" style="font-size: 21px;">{{incident.impersonation_domain}}</span>
										<span style="font-size: 15px; flex: 1 0 185px">is suspected to impersonate</span>
										<span class="fw-bold text-primary" style="font-size: 21px;">{{incident.original_domain}}</span>
									</div>
									<div class="flex-row justify-content-between gap-4">
										<div class="flex-row flex-wrap gap-3 px-2" style="max-width: 720px;">
											<div class="flex-row gap-2 align-items-baseline bg-light border border-1 border-scondary rounded-1 px-3 py-2 f-1-0" style="font-size: 14px; flex-basis: 340px; line-height: 1;">
												<i class="fa fa-stamp" style="color: #840b66;"></i>
												<span>Certificate issuer:</span>
												<span class="animated fadeIn">{{incident.cert_issuer || 'N/A'}}</span>
											</div>
											<div class="flex-row gap-2 align-items-baseline bg-light border border-1 border-scondary rounded-1 px-3 py-2 f-1-0" style="font-size: 14px; flex-basis: 340px; line-height: 1;">
												<i class="far fa-calendar-alt" style="color: #840b66;"></i>
												<span>Creation date:</span>
												<span class="animated fadeIn">{{incident.domain_creation_date ? moment(incident.domain_creation_date).format('MMMM DD, YYYY') : 'N/A'}}</span>
											</div>
											<div class="flex-row gap-2 align-items-baseline bg-light border border-1 border-scondary rounded-1 px-3 py-2 f-1-0" style="font-size: 14px; flex-basis: 340px; line-height: 1;">
												<i class="fa fa-exchange-alt" style="color: #840b66;"></i>
												<span>Domain similarity:</span>
												<span class="fw-500 animated fadeIn" [style.color]="incident.domainSimilarityColor">{{((incident.domain_similarity || 0) / 10) | number: '1.0-0' * 100 }}/10</span>
												<i style="font-weight: 400; position: relative;" class="fas fa-question-circle opacity-75"
												   tooltip='Evaluates suspected domains with a similarity score from 0 to 10, measuring how closely they resemble your internal domains'></i>
											</div>

											<div class="flex-row gap-2 align-items-baseline bg-light border border-1 border-scondary rounded-1 px-3 py-2 f-1-0" style="font-size: 14px; flex-basis: 340px; line-height: 1;">
												<i class="fas fa-server" style="color: #840b66;"></i>
												<span class="animated fadeIn" *ngIf="!incident.retestMetricsInProcess">
													Domain <b [ngClass]="incident.hosting_record?.length ? 'text-danger' : 'text-success'">{{incident.hosting_record?.length ? 'is' : 'is not'}}</b> hosting a website
												</span>
												<i *ngIf="incident.hosting_record?.length" style="font-weight: 400; position: relative;" class="fas fa-question-circle opacity-75"
												   [tooltip]="'IPs: '+incident.hosting_record?.join(', ')"></i>
												<div class="loading-spinner opacity-50" *ngIf="incident.retestMetricsInProcess" style="position: relative; left: unset; border-width: 2px; height: 15px; width: 15px;"></div>
											</div>

											<div class="flex-row gap-2 align-items-baseline bg-light border border-1 border-scondary rounded-1 px-3 py-2 f-1-0" style="font-size: 14px; flex-basis: 340px; line-height: 1;">
												<i class="far fa-envelope-open" style="color: #840b66;"></i>
												<span class="animated fadeIn" *ngIf="!incident.retestMetricsInProcess">
													Domain <b [ngClass]="incident.mail_exchange?.length ? 'text-danger' : 'text-success'">{{incident.mail_exchange?.length ? 'can' : 'cannot'}}</b> receive emails
												</span>
												<i *ngIf="incident.mail_exchange?.length" style="font-weight: 400; position: relative;" class="fas fa-question-circle opacity-75"
												   [tooltip]="'MX: '+incident.mail_exchange?.join(', ')"></i>
												<div class="loading-spinner opacity-50" *ngIf="incident.retestMetricsInProcess" style="position: relative; left: unset; border-width: 2px; height: 15px; width: 15px;"></div>
											</div>
											<div class="flex-row gap-2 align-items-start bg-light border border-1 border-scondary rounded-1 p-3 f-1-0 pt-2" style="flex-basis: 100%;">
												<div style="margin-top: 6px; flex-shrink: 0; width: 16px; height: 16px; color: #840b66; background: #840b66; mask-size: auto 16px; -webkit-mask-size: auto 16px; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-image: url('images/double-hook.svg');"></div>
												<div class="flex-column gap-2">
													<div class="flex-row gap-2 align-items-center" style="font-size: 13px; height: 32px;">
														<span class="fw-bold">Phishing analysis:</span>
														<span *ngIf="!incident.retestMetricsInProcess && incident.phishing_heuristics" class="animated fadeIn fw-bold p-2 rounded-1 bg-white border border-1 border-warning" style="line-height: 1;">{{incident.phishing_heuristics | titlecase}}</span>
														<span class="animated fadeIn" *ngIf="!incident.retestMetricsInProcess && !incident.phishing_heuristics">None</span>
														<div class="loading-spinner opacity-50" *ngIf="incident.retestMetricsInProcess" style="position: relative; left: unset; border-width: 2px; height: 15px; width: 15px;"></div>
														<i style="font-weight: 400; position: relative;" class="fas fa-question-circle opacity-75"
														   tooltip='Analysis of potential phishing activity from the domain suspected of impersonation'></i>
													</div>
													<span>{{getPhishingHeuristicDesc(incident.phishing_heuristics || 'none')}}</span>
												</div>

											</div>
										</div>
										<app-dropdown-c style="width: 160px; flex-shrink: 0;" text="Take Actions">
											<li (click)="updateIncidentStatus(incident)">
												<span>{{dic.CONSTANTS.domainImpersonationsObjActions.status.display}}</span>
											</li>
											<li (click)="retestDomainObjMetrics(incident)">
												<span>{{dic.CONSTANTS.domainImpersonationsObjActions.retest.display}}</span>
											</li>
											<li (click)="reportDomainAbuse(incident)">
												<span>{{dic.CONSTANTS.domainImpersonationsObjActions.report.display}}</span>
											</li>
										</app-dropdown-c>
									</div>
								</div>

								<div class="flex-column f-1-0 gap-4 p-4 bg-white shadow-sm" *ngIf="incident.metric_type === dic.CONSTANTS.domainMetricTypes.mxChange">
									<div class="flex-row gap-1 align-items-baseline flex-wrap align-self-start">
										<span style="font-size: 15px; flex: 1 0 160px"><b class="text-danger">MX change</b> detected for</span>
										<span class="fw-bold text-primary" style="font-size: 21px;">{{incident.original_domain}}</span>
									</div>
									<div class="flex-row justify-content-between gap-4">
										<div class="flex-column gap-2 bg-light border border-1 border-scondary rounded-1 p-3 f-1-0" style="font-size: 14px; max-width: 720px; line-height: 1;">
											<div class="flex-row gap-2 align-items-center">
												<span style="flex-basis: 60px;">Old MX:</span>
												<b>{{incident.old_mx}}</b>
											</div>
											<i class="fa fa-exchange-alt" style="color: #840b66;"></i>
											<div class="flex-row gap-2 align-items-center">
												<span style="flex-basis: 60px;">New MX:</span>
												<b class="text-danger">{{incident.new_mx}}</b>
											</div>
										</div>
										<app-dropdown-c style="width: 160px; flex-shrink: 0;" text="Take Actions">
											<li (click)="updateIncidentStatus(incident)">
												<span>{{dic.CONSTANTS.domainImpersonationsObjActions.status.display}}</span>
											</li>
										</app-dropdown-c>
									</div>
								</div>

							</div>
						</virtual-scroller>

						<div class="d-flex centralize f-1-0" *ngIf="!incidentCards?.length">
							<span style="font-size: clamp(13px, 1.1vw, 16px); line-height: 2; text-align: center;">
								At this time, no suspicious activity has been identified.
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<popup-c class="overflownV" *ngIf="setDomainStatusPopup?.show"
		 head="Set Incident Status" size="dynamic"
		 (closeCb)="setDomainStatusPopup = null;"
		 (confirmCb)="updateIncidentStatusExecute();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: setDomainStatusPopup?.actionInProcess}}">
	<div class="flxClmn f-1-0" style="overflow: visible;">
		<div class="flxRow" style="align-items: center;">
			<div style="flex-basis: 70px;">Status:</div>
			<app-dropdown-c style="width: 150px;" text="{{setDomainStatusPopup.newStatus}}">
				<li *ngFor="let status of _.values(setDomainStatusPopup.statuses)"
					(click)="setDomainStatusPopup.newStatus = status.value;"
					id="{{status.value}}">
					{{status.value}}
					<i style="font-weight: 400; position: relative;" class="fas fa-question-circle opacity-75"
					   tooltip='{{status.tooltip}}'></i>
				</li>
			</app-dropdown-c>
		</div>
	</div>
</popup-c>

<popup-c class="overflownV" *ngIf="reportAbusePopup?.show"
		 head="Report Domain Abuse" size="dynamic"
		 subTitle="Domain <b>{{reportAbusePopup.domainObj.impersonation_domain}}</b> will be reported for abuse to its domain registrar"
		 (closeCb)="reportAbusePopup = null;"
		 (confirmCb)="this.reportAbusePopup.doneCb();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: reportAbusePopup?.actionInProcess}}">
	<div class="flxClmn f-1-0" style="overflow: visible;">
		<div class="flxClmn">
			<span>Report reason:</span>
			<textarea type="text" style="border: 1px solid #ccc; height: 75px;"
			  [(ngModel)]="reportAbusePopup.reason"
			  placeholder="Reason will be sent to the domain registrar">
			</textarea>
		</div>
	</div>
</popup-c>

<popup-c class="bs-enabled overflownV" *ngIf="alertSettingsPopup?.show"
		 head="Domain MX change detection - Alert Settings" size="dynamic"
		 subTitle="Send alerts for MX record changes to the configured email address and/or phone number"
		 (closeCb)="alertSettingsPopup = null;"
		 (confirmCb)="this.alertSettingsPopup.doneCb();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: alertSettingsPopup?.actionInProcess}}">
	<div class="flex-column gap-2 f-1-0" style="overflow: visible;">
		<div class="flex-row align-items-center gap-2">
			<span style="flex-shrink:0; flex-basis: 100px;">Email address:</span>
			<input type="text" style="flex: 1 0;" [(ngModel)]="alertSettingsPopup.email" placeholder="Email address">
		</div>
		<div class="flex-row align-items-center gap-2">
			<span style="flex-shrink:0; flex-basis: 100px;">Phone number:</span>
			<span class="flxRow override-li-height hSpace05 h100 animated fadeIn" style="overflow: visible; flex: 1 0; z-index: 10;">
				<country-code-select [(model)]="alertSettingsPopup.phone.country_code" ></country-code-select>
				<input id="accountEditPhone"
					   type="text" placeholder="Phone number"
					   style="flex: 1 0; margin-right: 0; margin-bottom: 0; padding-right: 5px;"
					   pattern="^[0-9]*$"
					   maxlength="11"
					   [(ngModel)]="alertSettingsPopup.phone.phone_number">
			</span>
		</div>
	</div>
</popup-c>
