import {DICTIONARY} from '../../../dictionary';
import {AccountCompromisedService} from '../../../services/accountCompromisedService';
import {RouteService} from '../../../services/routeService';
import _ from 'lodash';
import {NotificationService} from '../../../services/notificationService';
import {GeneralService} from '../../../services/generalService';
import L from 'leaflet';
import {Component, OnInit} from "@angular/core";


@Component({
	selector: 'account-compromised-incidents-component',
	templateUrl: './account-compromised-incidents.component.html',
})
export class AccountCompromisedIncidentsComponent implements OnInit {
	dic = DICTIONARY;
	loadingIncidentsInProcess;
	loadingUserStatusesInProcess;

	bulkActions = [
		this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display
	];

	incidents: any;
	isUserAllowedToViewEmailContent;
	incidentsByDates: any;
	filterData;
	incidentsDisplayedCounter = 0;

	usersStatuses: any;

	viewTypes = {
		timeline: 'timeline',
		list: 'list'
	};
	selectedViewType = this.viewTypes.timeline;
	sensitiveInfoPopup;
	usersInIncidentsList;
	selectedUserForTimeline;
	accountInfo: any;
	showAccountInfoPopup = false;
	searchUserStatusTxt = '';
	sortUser = 'email';
	sortStatus = 'status';
	orderUsersStatusesBy = this.sortUser;
	emailPreviewPopup;
	_ = _;

	incidentsActionsDic = {
		block24: {display: 'User was blocked for 24 hours', color: 'danger', icon: 'fa fa-ban', name: 'block24'},
		block: {display: 'User was blocked', color: 'danger', icon: 'fa fa-ban', name: 'block'},
		resetPassword: {display: 'User password was reset', color: 'warning', icon: 'fa fa-ban', name: 'resetPassword'},
		disableAccount: {display: 'User account was disabled', color: 'danger', icon: 'fa fa-ban', name: 'disableAccount'},
		removeRestrictions: {display: 'Removed restrictions for user', color: 'success', icon: 'fa fa-check-circle', name: 'removeRestrictions'},
		risky: {display: 'User marked as risky for 24 hours', color: 'warning', icon: 'fa fa-exclamation-triangle', name: 'risky'},
		approved: {display: "Updated incident status to \"Approved\"", color: 'success', icon: 'fa fa-check-circle', name: this.dic.CONSTANTS.compromisedIncidentStatus.approved},
		suspicious: {display: "Updated incident status to \"Suspicious\"", color: 'warning', icon: 'fa fa-exclamation-triangle', name: this.dic.CONSTANTS.compromisedIncidentStatus.suspicious},
		compromised: {display: "Updated incident status to \"Compromised\"", color: 'danger', icon: 'fa fa-times-circle', name: this.dic.CONSTANTS.compromisedIncidentStatus.compromised},
	};

	incidentActionsTimeline = [
		this.dic.CONSTANTS.accountCompromisedIncidentActions.info,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.status,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions
	];

	constructor(private accountCompromisedService:AccountCompromisedService,
				private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService) {
	}

	ngOnInit() {
		this.initFilters();
		this.getIncidents();
		this.getUsersStatuses();
	}

	getIncidents = () => {
		this.loadingIncidentsInProcess = true;
		this.rs.getAccountCompromisedIncidents('', null).then(response => {
			this.incidents = response.incidents;
			this.isUserAllowedToViewEmailContent = response.allowViewContent;
			this.incidents.forEach((incidentObj) => {
				this.colorForIncident(incidentObj);

				if (incidentObj.incident_info.agent?.raw && (typeof incidentObj.incident_info.agent?.raw === 'string')) {
					incidentObj.incident_info.agent.raw = JSON.parse(incidentObj.incident_info.agent.raw);
				}

			});
			this.incidents.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

			this.changeViewType(this.selectedViewType);
		}, (err) => {
			this.loadingIncidentsInProcess = false;
		});
	}

	getUsersStatuses = () => {
		this.loadingIncidentsInProcess = true;
		this.rs.getUsersStatuses().then(response => {
			this.usersStatuses = response;

			this.loadingUserStatusesInProcess = false;
		}, (err) => {
			this.loadingUserStatusesInProcess = false;
		});
	}

	changeViewType(selectedViewType, selectedUser=null) {
		switch (selectedViewType) {
			case this.viewTypes.timeline:
				this.prepareIncidentsTimeLine(selectedUser);
				break;

			case this.viewTypes.list:
				this.initFilters();
				this.searchIncident('', null);
				this.selectedViewType = this.viewTypes.list;
				this.loadingIncidentsInProcess = false;
				break;
		}
	}

	colorForIncident = (incidentObj) => {
		switch (incidentObj.incident_type) {
			case 'suspicious_location_change':
				incidentObj.color = 'green';
				break;

			case 'sending_unusual_amount_of_emails':
			case 'suspicious_device_change':
				incidentObj.color = 'orange';
				break;

			default:
				incidentObj.color = 'red';
				break;
		}
	}

	prepareIncidentsTimeLine(selectedUser = null) {
		// create users for the dropdown
		this.usersInIncidentsList = _.unionBy(
			_.map(this.incidents, incident => {
			return {
				displayName: incident.user_name ? incident.user_name + ' (' + incident.user_email + ')' : incident.user_email,
				email: incident.user_email
			}
		}), 'email');
		const allUsersObj = {displayName: 'All Users'};
		this.usersInIncidentsList.unshift(allUsersObj);
		this.selectedUserForTimeline = selectedUser || allUsersObj;
		//

		let incidentsClone = _.clone(this.incidents);
		this.incidentsByDates = [];

		if (selectedUser && selectedUser.displayName !== 'All Users') {
			incidentsClone = _.filter(incidentsClone, incident => incident.user_email === selectedUser.email);
		}

		// get a list of dates without the times out of the incidents list
		const dates = _.unionBy(_.map(incidentsClone, 'created'), (date:any) => new Date(date).getDate());

		// for each date create an array of incidents corresponding to this date
		dates.forEach(date => {
			const incidentsDayObj = {
				date: date,
				incidents: _.filter(incidentsClone, incident => areDatesEqualByYearMonthDay(incident.created, date))
			};
			this.incidentsByDates.push(incidentsDayObj);
		});

		this.updateIncidentsActionsFromLocalStorage();

		this.selectedViewType = this.viewTypes.timeline;
		this.loadingIncidentsInProcess = false;
	}

	showIncidentsActions = (incidentObj) => {
		return [
			this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.status.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display
		];
	}

	blockUser = (incidentObj) => {
		this.gs.showPopup({
			title: 'Block User',
			subTitle: `Block User ${incidentObj.user_email} - user will not be able to access their account or send emails`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Block',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(() => {
					this.ns.showInfoMessage(`${incidentObj.user_email} blocked successfully`);

					this.accountCompromisedService.addIncidentAction('block', incidentObj);
				}, (err) => {
				});
			}
		});
	}

	removeRestrictionsUser = (incidentObj) => {
		this.gs.showPopup({
			title: 'Remove Restrictions for User',
			subTitle: `Remove all restrictions for User ${incidentObj.user_email} - user will be unblocked and will not consider as risky`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Remove Restrictions',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(() => {
					this.ns.showInfoMessage(`removed restrictions successfully for ${incidentObj.user_email} `);

					this.accountCompromisedService.addIncidentAction('removeRestrictions', incidentObj);
				}, (err) => {
				});
			}
		});
	}

	blockUser24hoursExecute = (incidentObj) => {
		this.gs.showPopup({
			title: 'Block User',
			subTitle: `Block User ${incidentObj.user_email} - user will not be able to access their account or send emails for 24 hours`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(() => {
					this.ns.showInfoMessage(`${incidentObj.user_email} blocked for 24 hours successfully`);

					this.accountCompromisedService.addIncidentAction('block24', incidentObj);
				}, (err) => {
				});
			}
		});
	}

	riskyUser24hoursExecute = (incidentObj) => {
		this.gs.showPopup({
			title: 'Set User as Risky for 24 Hours',
			subTitle: `For 24 hours, emails sent from this user will undergo an extensive threat scan`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(() => {
					this.ns.showInfoMessage(`${incidentObj.user_email} set as risky successfully`);

					this.accountCompromisedService.addIncidentAction('risky', incidentObj);
				}, (err) => {
				});
			}
		});
	}

	disableUserMailServerExecute = (incidentObj) => {
		this.gs.showPopup({
			title: 'Disable User Email Account',
			subTitle: `Disable user ${incidentObj.user_email}`,
			body: ['User will not be able to login to their account on Exchange'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(data => {
					this.ns.showInfoMessage(`${incidentObj.user_email} account disabled successfully`);
				}, (err) => {});
			}
		});
	}

	resetPasswordMailServerExecute = (incidentObj) => {
		this.gs.showPopup({
			title: 'Reset User Email Password',
			subTitle: `Reset password for user ${incidentObj.user_email}`,
			body: ['User will have to reset their password on the next login to Exchange'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(data => {
					this.ns.showInfoMessage(`${incidentObj.user_email} password reset successfully. New temporary password: "${data.data.password}". Change password on next login.`);
				}, (err) => {});
			}
		});
	}

	getUserAccountInfo = (userEmail) => {
		this.rs.getAccountCompromisedUsers(userEmail).then(response => {
			this.parseUserInfo(response);
		}, (err) => {
		});
	}

	parseUserInfo(user) {
		if (!user.misc.account_compromised.devices.length) {
			return this.ns.showWarnMessage(this.dic.ERRORS.noDevicesData);
		}
		this.accountInfo = {
			email: user.email,
			devices: user.misc.account_compromised.devices
		};
		if (!user.isParsed) {
			this.accountInfo.devices = _.map(this.accountInfo.devices, device => {
				device.agent = device.agent || {};
				if (device.agent.raw) {
					device.agent.raw = JSON.parse(device.agent.raw);
				}
				return device;
			});
			user.isParsed = true;
		}
		if (this.accountInfo.devices){
			this.prepareDevicesLocationInfo(this.accountInfo.devices);
		}
		this.showAccountInfoPopup = true;
	}

	prepareDevicesLocationInfo(deviceList) {
		this.accountInfo.locations = [];
		let bounds = [];
		deviceList.forEach((deviceObj) => {
			deviceObj.location.sort((a, b) => new Date(b.last_used || b.created).getTime() - new Date(a.last_used || a.created).getTime());
			deviceObj.location.forEach((locationObj) => {
				if (locationObj.ll.length === 2) {
					const marker = {
						icon: this.gs.leafletDefaultIcon,
						lat: locationObj.ll[0],
						lng: locationObj.ll[1],
						title: this.parseDeviceClient(deviceObj),
						draggable: false
					};

					bounds.push([locationObj.ll[0],locationObj.ll[1]]);

					this.accountInfo.locations.push(marker);
				}
			})
		});

		const centroid = new L.LatLngBounds(bounds).getCenter();

		this.accountInfo.center_location = {
			lat: centroid.lat,
			lng: centroid.lng,
			zoom: 2
		};
	}

	parseDeviceClient(device) {
		if (device.agent.browser) {
			return `${device.agent.browser.name} (${device.agent.browser.version}), ${device.agent.os.name} (${device.agent.os.version})`;
		}
		if (device.agent.raw) {
			return `${device.agent.raw.ua || 'N/A'}`;
		}

		return 'N/A';
	}

	exportToCsv = (sortBy) => {
		if (!this.incidents || !this.incidents.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Date,Email,Name,Subject,Type\n";

		let sortedTable = _.filter(this.incidents,incident => {return !incident.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((incident) => {
			const incidentType = this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name;
			csvString += `${incident.created},"${incident.user_email}",${incident.user_name},"${incident.email_title}","${incidentType}"\n`;
		});

		this.gs.exportCsv(csvString, `suspicious_activity.csv`);
	}

	initFilters = () => {
		this.filterData = {
			filterType: this.dic.CONSTANTS.tableFilters.suspiciousActivity,
			filters: {
				type: ['Suspicious device change', 'Suspicious location change', 'Sensitive data sent to new domains', 'Unusual amount of emails sent', 'Sensitive data sent to free email domain', 'Increased recipient complaint rate', 'Increased recipient hard bounce rate', 'Mailbox email breach'],
				status: ['approved', 'suspicious', 'compromised']
			},
			initFiltersFunction: this.initFilters
		};
	}

	searchIncident = (searchTerm, activeFilters) => {
		this.incidents.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = this.searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			if (activeFilters) {
				// need to match all filter types
				let numFilterToMatch = Object.keys(activeFilters).length;

				if (activeFilters.type && activeFilters.type.length) {
					if (activeFilters.type.includes(this.dic.CONSTANTS.accountCompromisedIncidentTypes[record.incident_type].name)) {
						numFilterToMatch--;
					}
				}
				if (activeFilters.status && activeFilters.status.length) {
					if (activeFilters.status.includes(record.status)) {
						numFilterToMatch--;
					}
				}

				if (numFilterToMatch) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	selectAction = (incidentObj, action) => {
		switch (action) {
			case this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display:
				this.accountCompromisedService.getUserIncidentInfo(incidentObj, this.isUserAllowedToViewEmailContent);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.status.display:
				this.accountCompromisedService.changeIncidentStatus(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser.display:
				this.blockUser(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours.display:
				this.blockUser24hoursExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours.display:
				this.riskyUser24hoursExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer.display:
				this.disableUserMailServerExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer.display:
				this.resetPasswordMailServerExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display:
				this.deleteIncident([incidentObj]);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions.display:
				this.removeRestrictionsUser(incidentObj);
				break;
		}
	}

	openLocation(ll) {
		this.gs.openLocation(ll);
	}

	openSensitiveInfoPopup(incident) {
		this.sensitiveInfoPopup = {
			emailTitle: incident.email_title,
			sensitivity: incident.incident_info.sensitivity,
			show: true
		}
	}

	deleteIncident(incidentObjects) {
		let title, subTitle;
		if (incidentObjects.length > 1) {
			title = 'Delete incidents';
			subTitle = `Please note - you are about to delete ${incidentObjects.length} incidents`;
		}
		else {
			title = 'Delete incident';
			subTitle = `You are about to delete incident for ${incidentObjects[0].user_name}`;
		}

		this.gs.showPopup({
			title: title,
			subTitle: subTitle,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				incidentObjects.forEach((incidentObj) => {
					const incidentInfo = {incidentId: incidentObj._id, action: this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.value};
					this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
						_.remove<any>(this.incidents, (incidentItem:any) => incidentItem._id === incidentObj._id);
						this.ns.showInfoMessage('Incident deleted successfully');
						this.incidentsDisplayedCounter--;
					});
				});
			}
		});
	}

	showIncidentBulkActions = () => {
		return this.bulkActions;
	}

	selectMultipleIncidentsAction = (selectedRecords,action) => {
		switch (action) {
			case this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display:
				this.deleteIncident(selectedRecords);
				break;
		}
	}

	searchTextExecute(incident, searchTerm) {
		searchTerm = searchTerm.toLowerCase();
		return ((incident.user_email && incident.user_email.toLowerCase().indexOf(searchTerm) > -1) ||
			(incident.user_name && incident.user_name.toLowerCase().indexOf(searchTerm) > -1) ||
			this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name.toLowerCase().indexOf(searchTerm) > -1);
	}

	searchUserStatus() {
		if (!this.searchUserStatusTxt) {
			this.usersStatuses.forEach(userStatus => {
				userStatus.hide = false;
			});
			return;
		}

		const searchTerm = this.searchUserStatusTxt.toLowerCase();

		this.usersStatuses.forEach(userStatus => {
			const isFound = (userStatus.status && userStatus.status.toLowerCase().indexOf(searchTerm) > -1) ||
							(userStatus.email && userStatus.email.toLowerCase().indexOf(searchTerm) > -1) ;
			userStatus.hide = !isFound;
		})
	}

	updateIncidentsActionsFromLocalStorage() {
		// structure of data in localStorage:
		// localStorage.compromisedIncidentsActions = {
		// 		lastTimeStamp: Date,
		// 		actions: [
		// 			{
		//				incidentInfo: {created: Date, incident_type: string, user_email: string},
		//				actionInfo: {created: Date, name: string, admin: string},
		// 			}
		// 		]
		// }

		const actionsData = this.accountCompromisedService.getIncidentActionsDataFromLocalStorage();

		if (!actionsData) {
			return;
		}

		for (let i = 0; i < this.incidentsByDates.length; i++) {
			actionsData.actions.forEach(storedAction => {
				const relatedIncident = _.find(this.incidentsByDates[i].incidents, incident => {
					return incident.created === storedAction.incidentInfo.created && incident.user_email === storedAction.incidentInfo.user_email;
				});

				if (relatedIncident) {
					const isAlreadyExist = _.find(relatedIncident.actions, action => {
						const secondsDiff = Math.abs(Date.parse(action.created) - Date.parse(storedAction.actionInfo.created)) / 1000;

						return action.name === storedAction.actionInfo.name && action.admin === storedAction.actionInfo.admin && secondsDiff < 8;
					})

					if (!isAlreadyExist) {
						relatedIncident.actions.push(storedAction.actionInfo);
					}
				}
			});
		}

		setTimeout(() => {
			this.accountCompromisedService.checkIncidentActionsLocalStorageTimestamp();
		}, 60000);
	}
}

function areDatesEqualByYearMonthDay(date1, date2) {
	const date1Year = new Date(date1).getFullYear();
	const date1Month = new Date(date1).getMonth();
	const date1Day = new Date(date1).getDate();

	const date2Year = new Date(date2).getFullYear();
	const date2Month = new Date(date2).getMonth();
	const date2Day = new Date(date2).getDate();

	return date1Year === date2Year && date1Month === date2Month && date1Day === date2Day;
}


