import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {ComposeMessageService} from "../../../services/composeMessageService";
import {LookAndFeelService} from "../../../services/lookAndFeelService";


@Component({
	selector: 'threat-simulation-templates-component',
	templateUrl: './threat-simulation-templates.component.html',
})
export class ThreatSimulationTemplatesComponent implements OnInit {
	constructor(private rs:RouteService,
				private composeMessageService:ComposeMessageService,
				private ns:NotificationService,
				private lfs:LookAndFeelService,
				public gs:GeneralService) {
	}

	util = util;
	isThisFeatureSupported;
	getTemplatesInProcess;
	updateTemplateInProcess;
	dic = DICTIONARY;
	summernoteOptions = this.gs.summernoteOptions;
	corpname = this.gs.corpname;
	previewTemplatePopup;
	dynamicFieldExample = '{{COMPANY_NAME}}';
	attachmentsUploadingInProcessCount;
	activeTemplate;
	userInfo;
	userLogoDetails;
	selectedAll;
	searchTemplateTxt;
	showDynamicFields;
	templates;
	isMissingName;
	htmlFile;

	_ = _;

	ngOnInit() {
		this.attachmentsUploadingInProcessCount = 0;

		this.gs.getUserInfo(false, userInfo => {
			this.userInfo = userInfo;
			this.userLogoDetails = userInfo.plan.customization.lf;
		});

		this.getTemplatesInProcess = true;
		this.rs.getThreatSimulationTemplates().then( (response) => {
			this.isThisFeatureSupported = true;
			this.templates = response;

			this.templates.forEach(template => {
				template.isSystemTemplate = template.role === this.dic.CONSTANTS.threatSimulationTemplateTypes.default
				template.isSelectable = !template.isSystemTemplate;
			});

			this.getTemplatesInProcess = false;
		}, (err) => {
			if (err?.data?.message && err.data.message.includes('This feature is not supported')) {
				this.isThisFeatureSupported = false;
			}
			else if (err && err.data && err.data.message && !err.data.display_bar) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.getTemplatesInProcess = false;
		});
	};

	selectMultipleTemplatesAction = (selectedTemplates, action) => {
		switch (action) {
			case this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete):
				this.deleteTemplates(selectedTemplates);
				break;
		}
	}

	showTemplateBulkActions = () => {
		return [
			this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete)
		];
	}

	selectTemplateAction = (templateObj, action) => {
		switch (action) {
			case this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete):
				this.deleteTemplates([templateObj]);
				break;

			case 'Clone Template':
				this.cloneTemplate(templateObj);
				break;

			case 'Test Template':
				this.testTemplate(templateObj);

		}
	}

	showTemplateActions = (templateObj) => {
		const actions = ['Test Template', 'Clone Template'];
		if (templateObj.role !== this.dic.CONSTANTS.threatSimulationTemplateTypes.default) {
			actions.push(this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete))
		}
		return actions;
	}

	selectActiveTemplate = (template) => {
		this.templates.forEach(templateInList => {
			templateInList.soloSelected = false;
		});
		template.soloSelected = true;

		const res = this.composeMessageService.updateSummernoteContent(template.html, true);

		this.activeTemplate = _.cloneDeep(template);
		this.activeTemplate.html = res.html;
		this.activeTemplate.css = res.css;

		this.isMissingName = false;
	};

	startAddNewTemplate = () => {
		this.activeTemplate = {
			name: '',
			title: '',
			description: '',
			from: {
				name: '',
				address: ''
			},
			reply_to: {
				name: '',
				address: ''
			},
			category: this.dic.CONSTANTS.threatSimulationTemplateCategories.security.name,
			lookup_links: [],
			html: '',
			isSystemTemplate: false
		};

		this.templates.forEach(template => {
			template.soloSelected = false;
		});

		setTimeout(() => {
			document.getElementById('templateNameInput').focus();
		});
	};

	cancelAddNewTemplate = () =>{
		this.activeTemplate = null;
	};

	saveTemplate = () => {
		if (!this.activeTemplate.name){
			this.isMissingName = true;
			return;
		}

		// EDIT
		if (this.activeTemplate._id) {
			this.updateTemplate();
		}
		// NEW
		else {
			this.addNewTemplate();
		}
	};

	addNewTemplate = () => {
		let template = _.cloneDeep(this.activeTemplate);
		template.html = this.composeMessageService.getSummernoteContent(template);
		delete template.css;

		this.updateTemplateInProcess = true;

		this.rs.addThreatSimulationTemplate(template).then((response) => {
			this.templates.push(response);
			this.selectActiveTemplate(response);

			this.updateTemplateInProcess = false;
			this.ns.showInfoMessage(this.dic.MESSAGES.templateCreated);
		}, (err) => {
			if (err && err.data && err.data.message && !err.data.display_bar) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.updateTemplateInProcess = false;
		});
	}

	updateTemplate = () => {
		let template = _.cloneDeep(this.activeTemplate);
		template.html = this.composeMessageService.getSummernoteContent(template);
		delete template.css;

		this.updateTemplateInProcess = true;

		this.rs.updateThreatSimulationTemplate(template._id, template).then( (response) => {
			const templateInListIdx = _.findIndex(this.templates, {_id: this.activeTemplate._id});
			this.templates.splice(templateInListIdx, 1, _.cloneDeep(this.activeTemplate));

			this.updateTemplateInProcess = false;
			this.ns.showInfoMessage(this.dic.MESSAGES.templateUpdated);
		}, (err) => {

			this.updateTemplateInProcess = false;
		}, () => {
			this.updateTemplateInProcess = false;
		});
	};

	addNewTrackedLink = () => {
		const newLink = {
			edit: {
				name: '',
				url: ''
			},
			isEditMode: true
		}

		this.activeTemplate.lookup_links.push(newLink);
	}

	removeTrackedLink = (linkObj) => {
		_.remove(this.activeTemplate.lookup_links, linkObj);
	}

	startEditTrackedLink = (linkObj) => {
		linkObj.edit = _.cloneDeep(linkObj);
		linkObj.isEditMode = true;
	}

	confirmEditTrackedLink = (linkObj, isApproved, linkIndex=null) => {
		if (!isApproved) {
			if (linkObj.link) {
				linkObj.edit = null;
				linkObj.isEditMode = false;
			}
			else {
				this.activeTemplate.lookup_links.splice(linkIndex,1);
			}
			return;
		}

		// new link
		if (!linkObj.link) {
			if (_.some(this.activeTemplate.lookup_links, {link: linkObj.edit.link})) {
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.XAlreadyExistInX, 'URL', 'tracked links'));
				return;
			}
		}

		if (!linkObj.edit.link || !this.gs.isValidUrl(linkObj.edit.link)) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.invalidUrl, linkObj.edit.link))
			linkObj.linkError = true;
			return;
		}

		if (!linkObj.edit.name) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'Link name'))
			linkObj.nameError = true;
			return;
		}

		if (linkObj.link && linkObj.link !== linkObj.edit.link) {
			// check if the new link is not already in the template
			if (_.some(this.activeTemplate.lookup_links, {link: linkObj.edit.link})) {
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.XAlreadyExistInX, 'URL', 'tracked links'));
				return;
			}
			this.updateLinkInTemplate(linkObj);
		}

		Object.assign(linkObj, linkObj.edit);
		linkObj.isEditMode = false;
	}

	updateLinkInTemplate = (linkObj) => {
		const originalLink = linkObj.link.replace(/[.*+?^${}()|[\]\\/]/g, '\\$&');
		const replacementLink = linkObj.edit.link;

		this.activeTemplate.html = this.activeTemplate.html.replace(new RegExp(originalLink, 'gi'), replacementLink);
	}


	// since the search input is outside the trustifi-table component, we should trigger the directive's inner search mechanism from the outside.
	// we do it by changing the list so that the directive will detect changes and fire a search
	triggerSearch = () => {
		this.templates = _.cloneDeep(this.templates);
	}

	searchTemplates = () => {
		this.templates.forEach(record => {
			// search
			if (this.searchTemplateTxt) {
				const isFound = searchTextExecute(record, this.searchTemplateTxt);
				if (!isFound && !record.isNew) { // ignore contact in creation state
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	deleteTemplates = (templates) => {
		const isMultiple = templates.length > 1;

		this.gs.showPopup({
			title: `Delete Template${isMultiple ? 's' : ''}`,
			subTitle: isMultiple ? `Are you sure you want to delete ${templates.length} templates?` : `Are you sure you want to delete the selected template?`,
			body: ['You cannot recover a deleted template.'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			isFile: false,
			doneCb: () => {
				templates.forEach((templateObj) => {
					this.deleteTemplatesExecute(templateObj);
				});
			}
		});
	}

	deleteTemplatesExecute = (templateObj) => {

		this.rs.deleteThreatSimulationTemplate(templateObj._id).then(response => {
			_.remove<any>(this.templates, template => template._id?.toString() === templateObj._id.toString());

			if (this.activeTemplate?._id && templateObj._id === this.activeTemplate._id) {
				this.activeTemplate = null;
			}

			this.ns.showInfoMessage(this.dic.MESSAGES.templateDeleted)
		});
	};

	loadHtml = () => {
		if (!this.htmlFile) {
			console.error('No file provided');
			return;
		}

		const reader = new FileReader();
		reader.addEventListener("load",  () => {
			if (reader.result) {
				const res = this.composeMessageService.updateSummernoteContent(reader.result);
				this.activeTemplate.html = res.html;
				this.activeTemplate.css = res.css;
			}
		}, false);

		reader.readAsText(this.htmlFile, 'uft8');
	};

	testTemplate = (templateObj) => {
		this.gs.showPopup({
			title: `Test Template`,
			body: ['<a class="blue-link flex-row gap-1 align-items-center fw-bold" href="https://docs.trustifi.com/docs/defender-settings-in-office365" target="_blank">' +
			'<span style="font-size: 12px;">Review the full guide</span>' +
			'<i class="fas fa-external-link-alt" style="font-size: 10px;"></i>' +
			'</a>'],
			subTitle: 'A test email of this campaign will be sent to your mailbox to verify deliverability. If the email was not delivered, please check your configuration',
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'Test',
			doneCb: () => {
				const campaignTestObj = {
					name: 'test', description: 'test', rcptTo: [{email: this.userInfo.email}],
					emailData: templateObj
				};
				this.rs.testCampaignTemplate(campaignTestObj).then(response => {
					this.ns.showInfoMessage(`Template sent to ${this.userInfo.email}`);
				});
			}
		});
	}

	addPhishingLink = () => {
		const url = 'https://gss.trustifi.com/threatSimulation/landing.html?template=custom_template';

		if (!_.some(this.activeTemplate.lookup_links, {link: url})) {
			this.activeTemplate.lookup_links.push({name: 'Phishing', link: url});
		}

		let summernote:any = this.gs.getSummernote();
		if (summernote && summernote.summernote('codeview.isActivated')) {
			summernote.summernote('codeview.deactivate');
		}

		const phishLink = `<a href="${url}">Phishing</a>`;
		this.activeTemplate.html = this.activeTemplate.html + phishLink;
	}

	previewTemplate = () => {
		if (!this.activeTemplate) {
			return;
		}

		let previewData = {
			html: this.composeMessageService.getSummernoteContent(this.activeTemplate),
			attachments: this.activeTemplate.attachments,
			role: this.activeTemplate.role,
			methods: {},
			page: 'template',
			recipients_display_only: {to: [], cc: []}
		};

		this.previewTemplatePopup = {
			getTemplatePreviewDataInProcess: true,
			emailContent: '',
			show: false
		};

		this.rs.previewEmail(previewData).then((response) => {
			this.previewTemplatePopup.show = true;
			setTimeout(() => {
				this.previewTemplatePopup.emailContent = response.html;
			})
		}, (err) => {
			this.previewTemplatePopup = null;
		});
	};

	exportTemplate = () => {
		let html = this.composeMessageService.getSummernoteContent(this.activeTemplate);
		this.gs.downloadClientFile(html, this.activeTemplate.name, 'text/html');
	};

	cloneTemplate = (templateObj) => {
		const template = _.cloneDeep(templateObj);
		delete template._id; // remove id so it will be treated as a new template
		delete template.role; // remove role so it will be treated as a new template
		template.isSystemTemplate = false;
		template.name += ' (copy)';
		this.selectActiveTemplate(template);
	}

	// BUG FIX : ngfDrop must be overriding the "dragover" event listener of summernote. so we handle it manually
	toggleSummernoteDragState = (setState, event) => {
		const summernoteContainer = document.getElementById('summernoteContainer');

		if (setState && event.target.className === 'note-editable') {
			summernoteContainer.classList.add('summernote-hovered');
		}

			// (a new layer is now inserted because of the last class change so the "drag" event now passes to this new layer)
		// in case of a drag leave without dropping
		else if (!setState && (event.target.className === 'note-dropzone-message')) {
			summernoteContainer.classList.remove('summernote-hovered');
		}
	}
	//

}

function searchTextExecute(templateObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return (templateObj.name && templateObj.name.toLowerCase().indexOf(searchTerm) > -1);
}
