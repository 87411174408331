import {Component, EventEmitter, Input, Output} from "@angular/core";
import {RouteService} from 'src/app/services/routeService';
import {AuthService} from "../../../services/authService";
import {DICTIONARY} from "../../../dictionary";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {Router} from "@angular/router";

@Component({
	selector: 'signup-form-component',
	templateUrl: './signup-form.component.html',
})
export class SignupFormComponent{
	@Output() loginStatusChange: EventEmitter<any> = new EventEmitter();
	@Output() doneCb: EventEmitter<any> = new EventEmitter();
	@Input() isTrialSignupPage = false;
	@Input() isPurchasePage = false;
	@Input() partner;
	@Input() error;
	@Input() signupData:any;

	constructor(private rs:RouteService,
				public gs:GeneralService,
				private ns:NotificationService,
				private router:Router,
				private authService:AuthService) {
		this.signupData = this.signupData || {
			accountDetails: {
				phone: {}
			}
		};
	}

	dic = DICTIONARY;
	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5);
	hasWKTextSecurity = this.gs.hasWKTextSecurity();
	name = this.gs.corpname.toUpperCase();
	signupWelcome = this.gs.signupWelcome;
	signupCompany = this.gs.signupCompany;
	validateEmail = this.gs.validateEmail;
	subDomainName = this.gs.company;
	country_code = this.gs.country_code;
	successMessage = false;
	signUpState = 'accountDetails';
	passwordError;
	lastNameError;
	firstNameError;
	emailError;
	reTypePasswordError;
	signingUpInProcess;
	loginStatus;
	eula = this.gs.eula;
	privacy = this.gs.privacy;
	antiSpam = this.gs.antiSpam;
	signupCompletionData;
	errMsg;
	navigateToAppAfterCompleteInProcess;
	states = ['accountDetails', 'additionalInformation', 'plan', 'paymentDetails', 'finalState'];
	showPassword;
	showReTypePassword;
	loginStatuses = {
		emailOrSSOForm: 'emailOrSSO',
		passwordLoginForm: 'passwordLoginForm',
		signupForm: 'signupForm',
		mfaCodeForm: 'mfaCodeForm'
	}

	resetAllFieldErrors() {
		this.passwordError = null;
		this.lastNameError = null;
		this.firstNameError = null;
		this.emailError = null;
		this.reTypePasswordError = null;
		this.passwordError = null;
	}

	validateSignUpForm() {
		let isPassed = true;
		this.resetAllFieldErrors();


		if (!this.signupData.accountDetails.lastName) {this.lastNameError = true; isPassed = false;}
		if (!this.signupData.accountDetails.firstName) {this.firstNameError = true; isPassed = false;}

		let passwordTextRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/; // At least 8 characters of 3 types: Lower case, Upper case and Number
		if (!this.signupData.accountDetails.password) {
			this.passwordError = 'Required';
			isPassed = false;
		}
		else if (!passwordTextRegex.test(this.signupData.accountDetails.password)) {
			this.passwordError = 'At least 8 characters of 3 types: Lower case, Upper case and Number';
			isPassed = false;
		}

		if (this.signupData.accountDetails.reTypePassword && this.signupData.accountDetails.password && this.signupData.accountDetails.reTypePassword !== this.signupData.accountDetails.password) {
			this.reTypePasswordError = 'Passwords are not matching';
			isPassed = false;
		}
		else if (!this.signupData.accountDetails.reTypePassword) {
			this.reTypePasswordError = 'Required';
			isPassed = false;
		}

		if (!this.signupData.accountDetails.email) {
			this.emailError = 'Required';
			isPassed = false;
		}
		else if (!this.gs.validateEmail(this.signupData.accountDetails.email)) {
			this.emailError = 'Invalid email address';
			isPassed = false;
		}

		return isPassed;
	}

	continue() {
		if (this.signUpState === 'accountDetails') {
			this.signingUpInProcess = true;
			this.signupData.accountDetails.company = this.signupCompany || this.subDomainName;
			this.rs.signupUser(this.signupData.accountDetails).then((response) => {
				if (response.error) {
					this.signingUpInProcess = false;
					this.errMsg = response.message;
					this.error = true;
				}
				else {
					this.signingUpInProcess = false;
					this.signUpState = 'finalState';
					this.signupCompletionData = {
						access_token: response.access_token,
						expires_in: response.expires_in,
					}
					this.doneCb.emit();
				}
			}, (error) => {
				console.error(error);
				this.signingUpInProcess = false;
				this.errMsg = error.data.message;
				this.error = true;
			});
			return;
		}
		this.signUpState = this.states[this.states.indexOf(this.signUpState) + 1];
	};

	navigateToAppAfterComplete() {
		if (this.signupCompletionData.access_token) {
			this.navigateToAppAfterCompleteInProcess = true;
			this.authService.remoteLogin({
				expiresIn: this.signupCompletionData.expires_in,
				accessToken: this.signupCompletionData.access_token
			}, err => {
				if(!err) {
					if (window.history.state.plan) {
						this.router.navigate([this.dic.CONSTANTS.appStates.accountDetails, this.dic.CONSTANTS.accountDetailsPages.myPlan]);
					}
					else {
						this.router.navigate([this.dic.CONSTANTS.appStates.home]);
					}
				}
			});
		}
		else {
			this.router.navigate([this.dic.CONSTANTS.appStates.login], {state: {plan: window.history.state.plan, email: this.signupData.accountDetails.email, gothrough: true}});
		}
	}

	login(email, access_token, expires_in) {
		if(access_token) {
			this.authService.remoteLogin({
				expiresIn: expires_in,
				accessToken: access_token
			}, err => {
				if(!err) {
					this.router.navigate([this.dic.CONSTANTS.appStates.home]);
				}
			});
		}
		else {
			this.router.navigate([this.dic.CONSTANTS.appStates.login], {state: {email: email, gothrough: true}});
		}
	};

	updateLoginStatus = (newStatus) => {
		this.error = false;
		this.successMessage = false;
		this.loginStatus = newStatus;
		this.loginStatusChange.emit({ loginStatus: this.loginStatus,
			successMessage: this.successMessage,
			error: this.error});
	}
}
