<div class="flex-column g-2">
	<span>Reviewers will have access to information regarding Account Takeover incidents and will receive notifications for such incidents.</span>

	<div class="flxRow-center" *ngIf="!isLoaded">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div *ngIf="isLoaded">
		<div class="flex-column">
			<br/>
			<div class="content-container flex-column gap-2" style="z-index: 1; width: 65%;">
					<!--reviewers table-->
					<trustifi-table-c
						class="trustifi-table" style="flex: 0 0 300px; z-index: 5; overflow: auto;"
						[list]="reviewers"
						[headers]="[
							{text:'Reviewer', sortKey:'email'},
							{text:'View Content', sortKey:'allow_view_content', tooltip: 'Allow reviewer to view the email content for incidents'}
						]"
						[cellsPrototype]="[
							{textKey: 'email', edit: {modelKey: 'email', placeholder: 'Reviewer email'}},
							{html: 'viewContentCell'},
						]"
						[bulkActions]="{
							enable: true,
							selectBulkActionCb:	selectMultipleReviewerAction,
							initBulkActionsFunction: showReviewerBulkActions,
							showInRed: {enable: true, terms: ['Remove']}
						}"
						[cellActions] = "{
							enable: true,
							selectItemActionCb:	selectReviewerAction,
							initActionsFunction: showReviewerActions,
							showInRed: {enable: true, terms: ['Remove']}
						}"
						(searchFunction)="searchReviewer($event.searchTerm, $event.activeFilters)"
						(confirmEditFunction)="confirmAddReviewer($event.item, $event.isApproved)"
						[options]="{
							itemsNameSingular: 'reviewer',
							defaultSortKey: '-email',
						}"
					>
						<ng-template #customCells let-item let-cellId="cellId">
							<!--action cells-->
							<div *ngIf="cellId === 'viewContentCell'">
								<span [ngStyle]="{color: item.allow_view_content ? 'green' : 'red'}">{{item.allow_view_content ? 'Enabled' : 'Disabled'}}</span>
							</div>
						</ng-template>
						<div tableButtons>
							<div class="btn-with-dropdown">
								<btn-c (action)="startAddReviewer();"
									   endIcon="fa fa-plus"
									   [isDisabled]="addReviewerInProcess">
									Add Reviewer
								</btn-c>
								<app-dropdown-c [isLf]="true">
									<li (click)="openUsersSelection();">
										Add from my users
									</li>
								</app-dropdown-c>
							</div>
						</div>
					</trustifi-table-c>
				</div>
		</div>

		<br/>

		<!--Addresses -->
		<div class="list-group list-group-flush settings-list-container">
			<div class="list-group-item settings-list-item">
				<div class="title-section-container flex-column gap-2" style="max-width: 17vw;">
					<b>Add Notification Recipients (optional)</b>
					<span class="lh-base" style="font-size: 0.9em;">Add the specific addresses to which you would like the suspicious activity notifications to be sent, in addition to reviewers.</span>
				</div>
				<div class="content-container flex-column gap-2">
					<div class="flex-row gap-1">
						<input type="text" style="width: 400px; margin: 0;"
							   placeholder="Add"
							   (keyup.enter)="addReportAddress(reportAddress);"
							   [(ngModel)]="reportAddress">
						<btn-c style="min-width: 0;"
							   [endIcon]="'zmdi zmdi-check'"
							   [isDisabled]="!reportAddress"
							   (action)="addReportAddress(reportAddress);">
						</btn-c>
					</div>
					<div *ngFor="let address of extraReviewers; trackBy: gs.trackByIndex" style="margin-bottom: 10px;">
						<div class="name ellipsis"
							 style="display: inline-block; width: 310px;">{{address}}</div>
						<a class="closeRed action"
						   (click)="deleteReportAddress(address)"
						   [ngClass]="actionInProcess ? 'closeRed disableAction' : 'approveGreen'">
							<i class="fa fa-times"></i>
						</a>
					</div>
				</div>
			</div>
		</div>

		<!--notification title-->
		<div class="list-group list-group-flush settings-list-container">
			<div class="list-group-item settings-list-item">
				<div class="title-section-container flex-column gap-2" style="max-width: 17vw;">
					<b>Notification email title</b>
					<span class="lh-base" style="font-size: 0.9em;">Edit the title of the automated notification email which is sent whenever suspicious activity is detected</span>
				</div>
				<div class="content-container flex-column gap-2">
					<div class="flxClmn vSpace05" style="flex: 1 0; margin: 0;">
						<div class="flxRow hSpace2">
							<input type="text" class="lowerPlaceholder" placeholder="{{notificationSubjectPlaceholder}}" style="margin: 0; width: 400px; box-sizing: inherit;"
								   [(ngModel)]="accountCompromisedReviewerConfigurations.notify_subject"
									(ngModelChange)="accountCompromisedReviewerConfigurations.changed = true;"/>
							<btn-c style="min-width: 0;"
								   [endIcon]="'zmdi zmdi-check'"
								   [isDisabled]="!accountCompromisedReviewerConfigurations.changed"
								   (action)="updateNotificationSubject(); accountCompromisedReviewerConfigurations.changed = false;">
							</btn-c>
						</div>

						<span class="text-muted" style="font-size: 0.8rem; line-height: 1.2; max-width: 92%;">{{notificationSubjectDesc}}</span>
					</div>

				</div>
			</div>
		</div>

	</div>

</div>

<users-selection-component
	*ngIf="usersSelectionPopup?.show"
	[data]="usersSelectionPopup"
	[selectedUsers]="selectedUsers"
	(addCb)="startAddFromUsersSelection($event.users)"
	(removeCb)="startRemoveFromUsersSelection($event.users)">
</users-selection-component>
