<div class="flxClmn">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Audit Log</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Review operations performed by the plan admins</span>
        </div>
    </div>
    <div class="page-inner-content-holder no-scroll flxClmn">
        <!--logs table-->
        <trustifi-table-c
                class="trustifi-table" style="flex: 1 0; z-index: 5;"
                [list]="logs"
                [headers]="[
                {text:'Date', sortKey:'created', width: '13%'},
                {text:'Category', sortKey:'category', width: '13%'},
                {text:'User', sortKey:'admin', width: '19%'},
                {text:'Action'},
            ]"
                [cellsPrototype]="[
                {textKey: 'created', textType: 'dateAndTime'},
                {textKey: 'category'},
                {textKey: 'admin', tooltip: true},
                {html: 'logActionCell'},
            ]"
                (searchFunction)="searchLogs($event.searchTerm, $event.activeFilters)"
                [filterData]="filterData"
                [options]="{
                exportToCsvFunction: exportToCsv,
                loadingTableFlag: loadingLogsInProcess,
                refreshFunction: getArchiveLogs,
                itemsNameSingular: 'log',
                defaultSortKey: '-created',
                itemUniqueIdentifier: '_id',
                isEllipsis: false,
                customButtons: [{icon: 'fas fa-calendar-day', function: showScheduleReport, tooltip: 'Schedule Report'}],
            }"
        >
			<ng-template #customCells let-item let-cellId="cellId">
				<!--action cells-->
				<div *ngIf="cellId === 'logActionCell'">
					<div class="flxRow-start hSpace1 expandable-record-row" style="max-width : 850px;" #expandableRecord
                         (click)="item.fullMode = !item.fullMode"
                         [ngStyle]="{'cursor': item.data ? 'pointer !important' : ''}">
						<i style="color : royalblue;" *ngIf="item.data" class="fas fa-external-link-alt"></i>
						<div class="ellipsis" style="word-break : break-word;"
							 (isElps)="gs.setExpandableRecord(expandableRecord, $event)"
							 [ngStyle]="item.fullMode ? { 'white-space' : 'normal' , 'color' : item.data ? 'royalblue' : ''} : item.data ? {'color' : 'royalblue' } : null ">
							<span>{{item.action}}</span>
						</div>
						<i *ngIf="!item.fullMode" class="fa fa-caret-down fa-sm"></i>
					</div>
				</div>
			</ng-template>
			<div tableButtons>
				<div class="period-buttons-group bs-enabled flxRow hSpace1">
					<btn-c
						variant="{{period.value !== periodObj.value ? 'outlined' : ''}}"
						*ngFor="let periodObj of auditLogsPeriod"
						[isDisabled]="loadingLogsInProcess"
						(action)="changePeriod(periodObj);">
						{{periodObj.display}}
					</btn-c>
				</div>
			</div>
        </trustifi-table-c>
    </div>
</div>

<audit-log-schedule-report-component [(show)]="scheduleReportShow"></audit-log-schedule-report-component>
