import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit, ViewChild} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {TrustifiTableComponent} from "../../../uiComponents/trustifi-table/trustifi-table.component";

const MAX_LOOPS = 100;

@Component({
	selector: 'email-trace-component',
	templateUrl: './email-trace.component.html',
})

export class EmailTraceComponent implements OnInit {

	@ViewChild('emailTraceTableEl') emailTraceTableEl: TrustifiTableComponent;

	constructor(private router:Router,
				public gs: GeneralService,
				private rs: RouteService,
				private ns: NotificationService,
				private clipboard: ClipboardService) {
	}

	dic = DICTIONARY;
	isQueryError;

	showAbortLoadingEmailsLabel: boolean;
	abortLoadingFlag: any;

	logsScope = this.router.url.includes(this.dic.CONSTANTS.appStates.adminInbound) ? 'inbound' : 'outbound';

	filterEmailTraceData;
	logs;
	period;
	range;
	tracingQueryError;
	tracingQuery;
	tracingQueryDisplay;
	queryValidationErrors;
	showQueryModal;
	queryError;
	currentActiveQueryData;
	getEmailTracingInProcess;
	tracingData;
	activeMessageIdAsFilter;
	sortLastDate;
	sortSubject;
	sortRecipient;
	sortSender;
	queryTypes = {
		lastEmails: 'lastEmails',
		period: 'period',
	}
	queryType;

	ngOnInit() {
		this.sortLastDate = '-lastDate';
		this.sortSubject = '-subject';
		this.sortRecipient = '-email';
		this.sortSender = '-from';
		// default query
		this.initTracingQuery();
		// last saved query (if there is any)
		this.restoreLastTracingQuery();
		this.initEmailTraceFilters();
	};

	ngOnDestroy(): void {
		this.abortLoadingFlag = true;
	}

	initTracingQuery = () => {
		this.period = this.dic.CONSTANTS.trendsPeriod.lastDay;
		this.queryType = this.queryTypes.lastEmails;
		this.range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()}; // (past one day)
		this.tracingQueryError = '';

		this.tracingQuery = {
			sender: '',
			recipient: '',
			subject: '',
			searchTerm: ''
		};

		this.tracingQueryDisplay = [{
			title: this.gs.toCapitalize(this.period.display),
			tooltip: this.gs.toCapitalize(this.period.display)
		}];
	}

	restoreLastTracingQuery = () => {
		// check previous query from localStorage and retrieve it
		let previousQuery;
		if (localStorage.tracingQuery) {
			try { // "try" in case the parameters won't parse correctly
				previousQuery = JSON.parse(localStorage.tracingQuery);
			}
			catch {}
		}
		// if last query saved to localstorage then apply the saved query
		if (previousQuery) {
			this.period = previousQuery.tracingPeriod;
			if (this.period.value && this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
				this.range = {start: previousQuery.start, end: previousQuery.end};
			}

			this.queryType = previousQuery.queryType || this.queryTypes.period;

			// remove unnecessary properties and assign query to UI
			previousQuery = _.pick(previousQuery, ['sender', 'searchTerm', 'recipient', 'subject']);
			this.tracingQuery = previousQuery;

			this.calculateQueryDisplay();
		}
	}

	initEmailTraceFilters = () => {
		this.filterEmailTraceData = {
			filterType: this.dic.CONSTANTS.tableFilters.emailTrace,
			filters: {
				status: ['Completed', 'Quarantined', 'In Process'],
			},
			initFiltersFunction: this.initEmailTraceFilters
		};
	};

	calculateQueryDisplay = () => {
		this.tracingQueryDisplay = [];

		if (this.queryType === this.queryTypes.lastEmails) {
			this.tracingQueryDisplay.push({
				title: 'Last Emails',
				tooltip: 'Last Emails'
			});
		}
		else {
			let periodTooltip = this.gs.toCapitalize(this.period.display);
			if (this.period.value && this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
				periodTooltip = 'From ' + (new DatePipe('en-US')).transform(this.range.start as Date, 'MM/dd/yyyy HH:mm') + ' until ' + (new DatePipe('en-US')).transform(this.range.end as Date, 'MM/dd/yyyy HH:mm');
			}
			this.tracingQueryDisplay.push({
				title: this.gs.toCapitalize(this.period.display),
				tooltip: periodTooltip
			});
			_.mapValues<any>(this.tracingQuery,(value, queryKey) => {
				if (value) {
					let title = queryKey;
					let tooltip = value;

					if (title === 'searchTerm') {
						title = 'Free text';
					}
					this.tracingQueryDisplay.push({title: title, tooltip: tooltip});
				}
			});
		}
	}

	applyQuery = () => {
		this.tracingQueryError = '';

		if (this.queryType !== this.queryTypes.lastEmails && !this.tracingQuery.searchTerm && !this.tracingQuery.sender && !this.tracingQuery.recipient && !this.tracingQuery.subject
			&& this.period.value !== this.dic.CONSTANTS.trendsPeriod.lastDay.value) {
			this.showQueryModal = true;
			this.tracingQueryError = 'Provide at least 1 query input field.'
			return;
		}

		if (!this.validateQuery()) {
			return;
		}

		this.tracingQuery.sender = this.tracingQuery.sender?.toLowerCase();

		this.calculateQueryDisplay();

		this.showQueryModal = false;
		this.queryError = '';

		this.getEmailTracing();
	}

	validateQuery = () => {
		this.queryValidationErrors = [];

		let isPassed = true;

		// period (range) check
		if (this.period.value && this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
			const range:any = {
				start: (this.range.start),
				end: (this.range.end)
			};
			if (range.start > Date.now() || range.start > range.end) {
				this.ns.showWarnMessage(this.dic.ERRORS.adminQuarantinedDate);
				isPassed = false;
			}
		}

		return isPassed;
	}

	getEmailTracing = () => {
		if (this.getEmailTracingInProcess) return;

		this.tracingData = [];

		let queryData: any;

		if (this.queryType === this.queryTypes.lastEmails) {
			queryData = {
				period: this.dic.CONSTANTS.trendsPeriod.lastDay.value,
				searchTerm: '',
				sender: '',
				recipient: '',
				subject: '',
				type: this.logsScope
			};
		} else {
			queryData = {
				period: this.period.value,
				searchTerm: this.tracingQuery.searchTerm,
				sender: this.tracingQuery.sender,
				recipient: this.tracingQuery.recipient,
				subject: this.tracingQuery.subject,
				type: this.logsScope
			};
		}

		if (this.period.value && this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
			queryData.start = this.range.start;
			queryData.end = this.range.end;

			if (queryData.start > Date.now() || queryData.start > queryData.end) {
				this.ns.showWarnMessage(this.dic.ERRORS.adminTrendsDate);
				return;
			}
			queryData.start = new Date(queryData.start).toISOString();
			queryData.end = new Date(queryData.end).toISOString();
		}

		const paramsToLocalStorage = _.clone(queryData);
		paramsToLocalStorage['tracingPeriod'] = this.period; // (the already existed 'period' property in 'params' is only the value, not the desired period object)
		localStorage['tracingQuery'] = JSON.stringify(paramsToLocalStorage); // Object

		this.getEmailTracingInProcess = true;
		this.showAbortLoadingEmailsLabel = true;
		this.abortLoadingFlag = false;

		this.getEmailTracingExecute(queryData, [], 0);
	};

	refreshTracingData = () => {
		if (this.currentActiveQueryData) {
			delete this.currentActiveQueryData.minID;
			this.tracingData = [];
			this.getEmailTracingExecute(this.currentActiveQueryData, [], 0);
		}
	}

	getEmailTracingExecute = (queryData, collectedLogs, loopCount) => {
		if (this.abortLoadingFlag) {
			this.abortLoadingFlag = false;
			return;
		}

		this.rs.getEmailTracingLog(queryData).then(data => {
			this.currentActiveQueryData = queryData;

			if (data?.logs?.length) {
				collectedLogs = collectedLogs.concat(data.logs);
				this.prepareTracingData(collectedLogs);
				this.getEmailTracingInProcess = false;
			}

			if (loopCount > MAX_LOOPS // break loop conditions
				|| !data?.nextCall?.minID) { // no more data to collect conditions
				console.log(`reached loop max`);
				this.getEmailTracingInProcess = false;
				this.showAbortLoadingEmailsLabel = false;
				return;
			}

			// send another query with next call data so backend continues collecting logs
			queryData.minID = data.nextCall.minID;
			this.getEmailTracingExecute(queryData, collectedLogs, ++loopCount);

		}, err => {
			this.getEmailTracingInProcess = false;
		});
	};


	prepareTracingData = (data) => {
		const displayableDataArr = [];
		data.forEach(emailObj => {
			if (emailObj.recipients && emailObj.recipients.length) {
				emailObj.recipients.forEach(recipient => {
					recipient['from'] = emailObj.from;
					recipient['messageId'] = emailObj.messageId;
					recipient['subject'] = emailObj.subject;
					// for "date" use the last message's date. If no messages - use the regular "created" property of the message
					recipient['lastDate'] = recipient.messages && recipient.messages.length && recipient.messages[recipient.messages.length - 1].created || emailObj.created;
					// recipient['to'] = emailObj.to;
					// fix messageId format: '<messageId>'
					if (recipient.messageId[0] === '<' && recipient.messageId[recipient.messageId.length - 1] === '>') {
						recipient.messageId = recipient.messageId.substring(1,recipient.messageId.length - 1);
					}
					displayableDataArr.push(recipient);
				});
			}
		});

		this.tracingData = displayableDataArr;
	}

	showLogsAction = (emailObj) => {
		emailObj.isExpanded = !emailObj.isExpanded;
		if (emailObj.isExpanded && !emailObj.tracking && this.logsScope === 'outbound') {
			emailObj.tracking = true;
			this.rs.getEmailEventsForRecipient(emailObj.email, emailObj.messageId).then((recipientObj) => {
				if (recipientObj?.track_events?.length) {
					recipientObj.track_events.forEach((trackingObj) => {
						if (trackingObj.note) {
							let addToNote = ''
							if (trackingObj.raw_data?.smtpResponse) {
								addToNote = ` (remote MTA IP: ${trackingObj.raw_data.remoteMtaIp} | smtp response: ${trackingObj.raw_data.smtpResponse})`;
							}
							if (trackingObj.raw_data?.complaintSubType) {
								addToNote = ` (${trackingObj.raw_data?.complaintSubType})`;
							}
							if (trackingObj.raw_data?.error) { // in case of send failed
								if (trackingObj.raw_data?.error.code) {
									addToNote = ` (${trackingObj.raw_data?.error.statusCode} ${trackingObj.raw_data?.error.code} ${trackingObj.raw_data?.error.message})`;
								}
								else {
									addToNote = ` (${trackingObj.raw_data?.error})`;
								}
							}

							const eventData:any = {
								created: trackingObj.created,
								text: trackingObj.note + addToNote
							};
							if (trackingObj.name === 'Read' && trackingObj.location) {
								eventData.showMapIcon = true;
								eventData.ll = trackingObj.location.ll;
							}
							emailObj.messages.push(eventData);
						}
					});
					if (recipientObj.canBlock) {
						emailObj.messages.push({
							action: true,
							recipientObj
						});
					}
				}
			}, err => {
			});
		}
	}

	changeRecipientStatus(recipientObj) {
		let actionButton = "";
		let title = "";
		let subtitle = "";
		let action;
		if (recipientObj.is_blocked) {
			title = "Unblock Recipient";
			subtitle = "Recipient will have access to this email";
			actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.unblock);
			action = this.dic.CONSTANTS.recipientStatus.unblock;
		}
		else {
			title = "Block Recipient";
			subtitle = "Recipient will not have access to this email";
			actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.block);
			action = this.dic.CONSTANTS.recipientStatus.block;
		}


		this.gs.showPopup({
			title: title,
			subTitle: subtitle,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: actionButton,
			doneCb: () => {
				this.rs.traceChangeRecipientStatus(recipientObj.user_id, recipientObj.email_id, recipientObj._id, {action: action}).then((response) => {
					recipientObj.is_blocked = !recipientObj.is_blocked;
				});
			}
		});
	};

	openLocation = (ll) => {
		this.gs.openLocation(ll);
	};

	setMessageIdAsFilter = (tagName) => {
		this.activeMessageIdAsFilter = tagName;
		this.emailTraceTableEl.searchItem();
	};

	abortLoadingEmails = () => {
		this.abortLoadingFlag = true;
		this.getEmailTracingInProcess = false;
		this.showAbortLoadingEmailsLabel = false;
	}

	searchTraceLog = (searchTerm, activeFilters) => {
		this.tracingData.forEach(record => {
			if (!this.searchTraceLogTextExecute(record, searchTerm)) {
				record.hide = true;
				return;
			}

			if (this.activeMessageIdAsFilter && record.messageId !== this.activeMessageIdAsFilter) {
				record.hide = true;
				return;
			}

			// Filter
			if (activeFilters && !this.searchEmailTraceFilterExecute(this.dic, record, activeFilters)) {
				record.hide = true;
				return;
			}

			record.hide = false;
		});

	};

	searchEmailTraceFilterExecute = (dic, record, filters) => {
		// need to match all filter types
		let numFilterToMatch = Object.keys(filters).length;

		if (filters.status && filters.status.length) {
			if (filters.status.includes(record.status)) {
				numFilterToMatch--;
			}
		}

		return !numFilterToMatch;
	}

	searchTraceLogTextExecute = (traceLog, searchTerm) => {
		searchTerm = searchTerm.toLowerCase();
		return ((traceLog.subject && traceLog.subject.toLowerCase().indexOf(searchTerm) > -1) ||
			(traceLog.from && traceLog.from.toLowerCase().indexOf(searchTerm) > -1) ||
			(traceLog.email && traceLog.email.toLowerCase().indexOf(searchTerm) > -1) ||
			(traceLog.messageId && traceLog.messageId.toLowerCase().indexOf(searchTerm) > -1) ||
			(traceLog.lastDate && (new DatePipe('en-US')).transform(traceLog.lastDate || '', 'MM/dd/yyyy HH:mm').indexOf(searchTerm) > -1));
	}

	exportEmailTraceCsv = (sortBy) => {
		if (!this.tracingData?.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Date,Subject,Recipient,Sender,Message ID,Status,Logs\n";

		let sortedTable = _.reject(this.tracingData,'hide');
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((emailTraceObj) => {
			const messages = [];
			emailTraceObj.messages && emailTraceObj.messages.forEach((msgObj) => {
				messages.push(msgObj.text);
			});
			csvString += `${emailTraceObj.lastDate},${emailTraceObj.subject},${emailTraceObj.email},${emailTraceObj.from},${emailTraceObj.messageId},${emailTraceObj.status}, ${messages.join(' | ')}\n`;
		});

		this.gs.exportCsv(csvString, `email_trace.csv`);
	}

	copyToClipboard = (textToCopy) => {
		this.clipboard.copy(textToCopy);
	}
}
