import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import _ from 'lodash';
import {GeneralService} from "../../services/generalService";
import {NotificationService} from "../../services/notificationService";
import {RouteService} from "../../services/routeService";
import util from "util";
import {DICTIONARY} from "../../dictionary";

@Component({
	selector: 'filter-table-c',
	templateUrl: './filter-table.component.html',
})
export class FilterTableComponent implements OnChanges{

	constructor(public gs:GeneralService,
				private ns:NotificationService,
				private rs:RouteService) {
	}

	@Input() filterData;
	@Output() applyCb = new EventEmitter<any>;
	@Output() clearCb = new EventEmitter<any>;

	dic = DICTIONARY;
	_ = _;

	savedTemplates;
	selectedFilterTemplate;
	updateTableFilterTemplateInProcess;
	saveAsTemplateInput;
	isSomeValueChanged;
	filtersDataObj:any = {};
	isDefaultFilterData;

	// the filters that are applied to the table
	currentAppliedFilters = {};

	ngOnChanges(changes: SimpleChanges) {
		if (changes.filterData) {
			this.currentAppliedFilters = {};

			this.filtersDataObj = _.mapValues(_.cloneDeep(this.filterData.filters), (filterOptions, filterKey) => {
				return {
					isNegative: false,
					allowNegative: !this.isYesOrNoFilter(filterOptions),
					values: _.map(filterOptions, option => {
								return {
									key: option,
									enabled: false,
									doNotCapitalize: this.gs.isValidDomain(option || '') || this.gs.isValidUrl(option || '') || this.gs.validateEmail(option || '')
								}
							})
				}
			});

			if (this.filterData.tableFilterTemplatesRefName) {
				if (!this.savedTemplates) {
					this.getTableFilterTemplates();
				}
			}
			else {
				const predefinedDefaultFilters = this.gs.getTableFilter(this.filterData.filterType);
				if (predefinedDefaultFilters) {
					this.loadActiveFilters(predefinedDefaultFilters);
					this.isDefaultFilterData = true;
					setTimeout(() => {
						this.apply();
					});
				}
			}
		}
	}

	updateOptions = (updateData) => {
		updateData.forEach(updateData => {
			const {key, option, newValue} = updateData;
			const optionObjToUpdate = _.find(this.filtersDataObj[key].values, {key: option});
			if (optionObjToUpdate) {
				optionObjToUpdate.enabled = newValue;
			}
		})

		this.apply();
	}

	getTableFilterTemplates = () => {
		this.rs.getTableFilterTemplates(this.filterData.tableFilterTemplatesRefName).then(response => {
			this.savedTemplates = response[this.filterData.tableFilterTemplatesRefName] || [];

			const defaultTemplate = _.find(this.savedTemplates, 'default');
			if (defaultTemplate) {
				this.selectFilterTemplate(defaultTemplate);
				setTimeout(() => {
					this.apply();
				});
			}
		});
	}

	saveTableFiltersTemplate = () => {
		// Validation
		if (!this.saveAsTemplateInput.name) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'Template name field'))
			return;
		}

		const selectedFiltersObj = this.getCurrentActiveFilters();

		if (_.isEmpty(selectedFiltersObj)) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.selectAtLeastOneX, 'filter option'))
			return;
		}

		const isExist = this.savedTemplates?.length && _.find(this.savedTemplates, {name: this.saveAsTemplateInput.name});

		const templateData = {
			name: this.saveAsTemplateInput.name,
			query: selectedFiltersObj
		}
		// end

		this.updateTableFilterTemplateInProcess = true;

		// update existed template
		if (isExist) {
			this.updateTemplateQuery(templateData);
		}
		// add new template
		else {
			this.rs.createTableFilterTemplate(this.filterData.tableFilterTemplatesRefName, templateData).then(response => {
				this.ns.showInfoMessage(this.dic.MESSAGES.templateCreated);
				this.savedTemplates.push(templateData);

				this.updateTableFilterTemplateInProcess = false;
				this.saveAsTemplateInput = null;

			}, err => {
				this.updateTableFilterTemplateInProcess = false;
			});
		}


		if (_.isEqual(this.getCurrentActiveFilters(), selectedFiltersObj)) { // make sure the user didn't click any other options while "save template" was in process
			this.selectedFilterTemplate = templateData;
			this.isSomeValueChanged = false;
		}
	}

	deleteFilterTemplate = (template) => {
		if (template.doActionInProcess || this.updateTableFilterTemplateInProcess) {
			return;
		}

		template.doActionInProcess = true;

		this.rs.deleteTableFilterTemplate(this.filterData.tableFilterTemplatesRefName, {templateName: template.name}).then(response => {
			this.ns.showInfoMessage(this.dic.MESSAGES.templateDeleted);
			_.remove(this.savedTemplates, {name: template.name});
		}, err => {
			template.doActionInProcess = false;
		});
	}

	updateTemplateQuery = (templateData) => {
		const templateToChange = _.find(this.savedTemplates, {name: templateData.name});
		const data = {
			action: 'query',
			templateData
		};

		this.rs.updateTableFilterTemplate(this.filterData.tableFilterTemplatesRefName, data).then(response => {
			this.ns.showInfoMessage(this.dic.MESSAGES.templateUpdated);

			templateToChange.query = templateData.query;

			this.updateTableFilterTemplateInProcess = false;
			this.saveAsTemplateInput = null;
		}, err => {
			this.updateTableFilterTemplateInProcess = false;
		});
	}

	updateTemplateDefault = (templateToChange=null) => {
		templateToChange = templateToChange || this.selectedFilterTemplate;
		const data = {
			action: 'default',
			templateData: templateToChange
		};

		this.rs.updateTableFilterTemplate(this.filterData.tableFilterTemplatesRefName, data).then(response => {
			this.ns.showInfoMessage(this.dic.MESSAGES.templateUpdated);

			this.savedTemplates.forEach(template => {
				if (template.name !== templateToChange.name) {
					template.default = false;
				}
			});
			templateToChange.default = !templateToChange.default;

			this.updateTableFilterTemplateInProcess = false;
		}, err => {
			this.updateTableFilterTemplateInProcess = false;
		});
	}

	updateFilterDefault = () => {
		if (_.isEmpty(this.getCurrentActiveFilters())) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.selectAtLeastOneX, 'filter option'))
			return;
		}

		this.isDefaultFilterData = !this.isDefaultFilterData;

		if (this.isDefaultFilterData) {
			this.gs.saveTableFilter(this.filterData.filterType, this.getCurrentActiveFilters());
		}
		else {
			this.gs.clearTableFilter(this.filterData.filterType);
		}

		this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
	}

	loadActiveFilters = (activeFiltersObj) => {
		_.each(this.filtersDataObj, (filter:any, filterKey) => {
			filter.values.forEach(option => {
				option.enabled = activeFiltersObj[filterKey] && (activeFiltersObj[filterKey].includes(option.key) || activeFiltersObj[filterKey].includes('-' + option.key));

				filter.isNegative = activeFiltersObj[filterKey]?.length && activeFiltersObj[filterKey][0].toString().startsWith('-');
			});
		});
	}

	getCurrentActiveFilters = () => {
		const selectedFiltersObj = {};
		_.each(this.filtersDataObj, (filter:any, filterKey) => {
			const selectedFilterOptions = _.map(_.filter(filter.values, 'enabled'), option => {
				return filter.isNegative ? '-' + option.key : option.key;
			});
			if (selectedFilterOptions.length) {
				selectedFiltersObj[filterKey] = selectedFilterOptions;
			}
			else {
				filter.isNegative = false;
			}
		});

		return _.isEmpty(selectedFiltersObj) ? null : selectedFiltersObj;
	}

	selectFilterTemplate = (templateObj) => {
		this.loadActiveFilters(templateObj.query);

		this.isSomeValueChanged = false;
		this.saveAsTemplateInput = null;
		this.selectedFilterTemplate = templateObj;
	}

	apply = () => {
		let activeFilters = this.getCurrentActiveFilters();

		if (!activeFilters) {
			this.clear();
			return;
		}

		this.currentAppliedFilters = activeFilters;

		this.applyCb.emit({activeFilters: activeFilters});
	};

	clear = () => {
		this.isDefaultFilterData = false;
		this.selectedFilterTemplate = null;
		this.currentAppliedFilters = {};

		this.gs.clearTableFilter(this.filterData.filterType);
		const defaultTemplate = this.savedTemplates && _.find(this.savedTemplates, 'default')
		if (defaultTemplate) {
			this.updateTemplateDefault(defaultTemplate);
		}

		this.filterData.initFiltersFunction();
		this.clearCb.emit({});
	};

	isYesOrNoFilter = (filterOptions) => {
		return !_.some(filterOptions, option => !['Yes', 'No', 'yes', 'no', 'True', 'False', 'true', 'false', true, false].includes(option))
	}
}
