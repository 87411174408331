import {Component, OnInit} from '@angular/core';
import {RouteService} from "../../../../services/routeService";
import {GeneralService} from "../../../../services/generalService";
import _ from "lodash";
import {DICTIONARY} from "../../../../dictionary";

@Component({
  selector: 'threat-simulation-config-component',
  templateUrl: './threat-simulation-config.component.html',
})
export class ThreatSimulationConfigComponent implements OnInit {

	constructor(private rs: RouteService,
				public gs: GeneralService) {}

	dic = DICTIONARY;
	_ = _;

	isLoaded;
	isThisFeatureSupported;
	addReviewerInProcess;
	threatSimulationConfig;

	ngOnInit() {

		this.rs.getThreatSimulationConfiguration().then((response) => {
			this.isThisFeatureSupported = true;
			this.isLoaded = true;
			this.threatSimulationConfig = response.threat_simulation;
		}, (err) => {
			this.isLoaded = true;
			if (err?.data?.message && err.data.message.includes('This feature is not supported')) {
				this.isThisFeatureSupported = false;
			}
		});
	}
}
