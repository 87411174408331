import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit, ViewChild} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {eachLimit} from 'async';
import {TrustifiTableComponent} from "../../../uiComponents/trustifi-table/trustifi-table.component";
import {Buffer} from 'safe-buffer';

@Component({
	selector: 'email-trace-component',
	templateUrl: './email-trace.component.html',
})

export class EmailTraceComponent implements OnInit {

	@ViewChild('emailTraceTableEl') emailTraceTableEl: TrustifiTableComponent;

	constructor(private router:Router,
				public gs: GeneralService,
				private rs: RouteService,
				private ns: NotificationService,
				private clipboard: ClipboardService) {
	}

	dic = DICTIONARY;
	isQueryError;

	showAbortLoadingEmailsLabel: boolean;
	abortLoadingFlag: any;

	logsScope = this.router.url.includes(this.dic.CONSTANTS.appStates.adminInbound) ? 'inbound' : 'outbound';

	filterEmailTraceData;
	logs;
	searchPeriods = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.range
	];
	tracingQueryError;
	tracingQuery;
	tracingQueryDisplay;
	queryValidationErrors;
	showQueryModal;
	getEmailTracingInProcess;
	tracingData;
	activeMessageIdAsFilter;
	queryTypes = {
		lastEmails: 'lastEmails',
		period: 'period',
	}
	queryType;
	previewEmailContentPopup;
	previewAttachment;

	ngOnInit() {
		// default query
		this.initTracingQuery();
		// last saved query (if there is any)
		this.restoreLastTracingQuery();
		this.initEmailTraceFilters();
	};

	ngOnDestroy(): void {
		this.abortLoadingFlag = true;
	}

	initTracingQuery = () => {
		this.queryType = this.queryTypes.lastEmails;
		this.tracingQueryError = '';

		this.tracingQuery = {
			period: this.dic.CONSTANTS.trendsPeriod.lastDay,
			range: {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()},
			from: '',
			rcptTo: '',
			subject: '',
			message_id: ''
		};

		this.tracingQueryDisplay = [{
			title: this.gs.toCapitalize(this.tracingQuery.period.display),
			tooltip: this.gs.toCapitalize(this.tracingQuery.period.display)
		}];
	}

	restoreLastTracingQuery = () => {
		// check previous query from localStorage and retrieve it
		let previousQuery;
		if (localStorage.emailTraceQuery) {
			try { // "try" in case the parameters won't parse correctly
				previousQuery = JSON.parse(localStorage.emailTraceQuery);
			}
			catch {}
		}
		if (previousQuery) {
			this.queryType = previousQuery.queryType || this.queryTypes.period;

			// remove unnecessary properties and assign query to UI
			previousQuery = _.pick(previousQuery, ['period', 'range', 'from', 'message_id', 'rcptTo', 'subject']);
			this.tracingQuery = previousQuery;

			this.calculateQueryDisplay();
		}
	}

	initEmailTraceFilters = () => {
		this.filterEmailTraceData = {
			filterType: this.dic.CONSTANTS.tableFilters.emailTrace,
			filters: {
				status: ['Completed', 'Quarantined', 'In Process', 'Failed'],
			},
			initFiltersFunction: this.initEmailTraceFilters
		};
	};

	calculateQueryDisplay = () => {
		this.tracingQueryDisplay = [];

		if (this.queryType === this.queryTypes.lastEmails) {
			this.tracingQueryDisplay.push({
				title: 'Last Emails',
				tooltip: 'Last Emails'
			});
		}
		else {
			_.mapValues<any>(this.tracingQuery,(value, queryKey) => {
				if (value) {
					switch (queryKey) {
						case 'message_id':
							queryKey = 'Message ID';
							break;

						case 'rcptTo':
							queryKey = 'Recipient';
							break;

						case 'period':
							queryKey = value.display;
							let periodTooltip = value.display;
							if (value.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
								periodTooltip = 'From ' + (new DatePipe('en-US')).transform(this.tracingQuery.range.start as Date, 'MM/dd/yyyy HH:mm') + ' until ' + (new DatePipe('en-US')).transform(this.tracingQuery.range.end as Date, 'MM/dd/yyyy HH:mm');
							}
							value = periodTooltip;
							break;

						case 'range':
							return;
					}
					this.tracingQueryDisplay.push({title: queryKey, tooltip: value});
				}
			});
		}
	}

	applyQuery = () => {
		this.tracingQueryError = '';

		if (this.queryType !== this.queryTypes.lastEmails && !this.tracingQuery.message_id && !this.tracingQuery.from && !this.tracingQuery.rcptTo && !this.tracingQuery.subject
			&& this.tracingQuery.period.value !== this.dic.CONSTANTS.trendsPeriod.lastDay.value) {
			this.showQueryModal = true;
			this.tracingQueryError = 'Provide at least 1 query input field.'
			return;
		}

		if (!this.validateQuery()) {
			return;
		}
		this.showQueryModal = false;

		this.calculateQueryDisplay();

		this.getEmailTracing();
	}

	validateQuery = () => {
		this.queryValidationErrors = [];

		let isPassed = true;

		if (this.tracingQuery.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
			const range:any = {
				start: (this.tracingQuery.range.start),
				end: (this.tracingQuery.range.end)
			};
			if (range.start > Date.now() || range.start > range.end) {
				this.ns.showWarnMessage(this.dic.ERRORS.adminQuarantinedDate);
				isPassed = false;
			}
		}

		return isPassed;
	}

	getQueryForLogs = () => {
		let queryData: any;

		if (this.queryType === this.queryTypes.lastEmails) {
			queryData = {
				period: this.dic.CONSTANTS.trendsPeriod.lastDay.value,
				message_id: '',
				from: '',
				rcptTo: '',
				subject: '',
			};
		}
		else {
			queryData = {
				period: this.tracingQuery.period.value,
				message_id: this.tracingQuery.message_id || '',
				from: this.tracingQuery.from || '',
				rcptTo: this.tracingQuery.rcptTo || '',
				subject: this.tracingQuery.subject || '',
			};

			if (this.tracingQuery.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
				queryData.range = {start: this.tracingQuery.range.start, end: this.tracingQuery.range.end};
				if (queryData.range.start > Date.now() || queryData.range.start > queryData.range.end) {
					this.ns.showWarnMessage(this.dic.ERRORS.adminTrendsDate);
					return;
				}
				queryData.range.start = new Date(queryData.range.start).toISOString();
				queryData.range.end = new Date(queryData.range.end).toISOString();
			}
		}

		const paramsToLocalStorage = _.clone(this.tracingQuery);
		localStorage['emailTraceQuery'] = JSON.stringify(paramsToLocalStorage);

		return queryData;
	}

	getEmailTracing = () => {
		if (this.getEmailTracingInProcess) return;

		const queryData = this.getQueryForLogs();
		queryData.direction = this.logsScope;

		this.getEmailTracingInProcess = true;
		this.showAbortLoadingEmailsLabel = true;
		this.abortLoadingFlag = false;

		this.tracingData = [];

		this.rs.getEmailTracingLog(queryData).then(data => {
			this.getEmailTracingInProcess = false;

			this.tracingData = data?.logs.map(itm => {
				return { ...itm._source, _id: itm._id };
			}) || [];

			if (data.scrollId) {
				this.getEmailTracingLogBatch(queryData, data.scrollId);
			}
			else {
				this.showAbortLoadingEmailsLabel = false;
			}
		}, err => {
			this.getEmailTracingInProcess = false;
		});
	};


	getEmailTracingLogBatch = (queryData, scrollId) => {
		if (this.abortLoadingFlag) {
			this.abortLoadingFlag = false;
			return;
		}

		const info = {query: queryData, scrollId};
		this.rs.getEmailTracingLogBatch(info).then(data => {
			this.getEmailTracingInProcess = false;

			this.tracingData = this.tracingData.concat(data?.logs.map(itm => {
				return { ...itm._source, _id: itm._id };
			}) || []);

			if (data.scrollId) {
				this.getEmailTracingLogBatch(queryData, data.scrollId);
			}
			else {
				this.showAbortLoadingEmailsLabel = false;
			}
		}, err => {
			this.getEmailTracingInProcess = false;
		});
	};


	showLogsAction = (emailObj) => {
		emailObj.isExpanded = !emailObj.isExpanded;
		if (emailObj.isExpanded && !emailObj.tracking && this.logsScope === 'outbound') {
			emailObj.tracking = true;
			this.rs.getEmailEventsForRecipient(emailObj.rcptTo, emailObj.message_id).then((recipientObj) => {
				if (recipientObj?.track_events?.length) {
					recipientObj.track_events.forEach((trackingObj) => {
						if (trackingObj.note) {
							let addToNote = ''
							if (trackingObj.raw_data?.smtpResponse) {
								addToNote = ` (remote MTA IP: ${trackingObj.raw_data.remoteMtaIp} | smtp response: ${trackingObj.raw_data.smtpResponse})`;
							}
							if (trackingObj.raw_data?.complaintSubType) {
								addToNote = ` (${trackingObj.raw_data?.complaintSubType})`;
							}
							if (trackingObj.raw_data?.error) { // in case of send failed
								if (trackingObj.raw_data?.error.code) {
									addToNote = ` (${trackingObj.raw_data?.error.statusCode} ${trackingObj.raw_data?.error.code} ${trackingObj.raw_data?.error.message})`;
								}
								else {
									addToNote = ` (${trackingObj.raw_data?.error})`;
								}
							}

							const eventData:any = {
								timestamp: trackingObj.created,
								log: trackingObj.note + addToNote
							};
							if (trackingObj.name === 'Read' && trackingObj.location) {
								eventData.showMapIcon = true;
								eventData.ll = trackingObj.location.ll;
							}
							emailObj.text.push(eventData);
						}
					});

					if (recipientObj.canBlock) {
						emailObj.text.push({
							action: true,
							recipientObj
						});
					}

					// @ts-ignore
					emailObj.text.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
				}
			}, err => {
			});
		}
	}

	changeRecipientStatus(recipientObj) {
		let actionButton = "";
		let title = "";
		let subtitle = "";
		let action;
		if (recipientObj.is_blocked) {
			title = "Unblock Recipient";
			subtitle = "Recipient will have access to this email";
			actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.unblock);
			action = this.dic.CONSTANTS.recipientStatus.unblock;
		}
		else {
			title = "Block Recipient";
			subtitle = "Recipient will not have access to this email";
			actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.block);
			action = this.dic.CONSTANTS.recipientStatus.block;
		}


		this.gs.showPopup({
			title: title,
			subTitle: subtitle,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: actionButton,
			doneCb: () => {
				this.rs.traceChangeRecipientStatus(recipientObj.user_id, recipientObj.email_id, recipientObj._id, {action: action}).then((response) => {
					recipientObj.is_blocked = !recipientObj.is_blocked;
				});
			}
		});
	};

	openLocation = (ll) => {
		this.gs.openLocation(ll);
	};

	setMessageIdAsFilter = (tagName) => {
		this.activeMessageIdAsFilter = tagName;
		this.emailTraceTableEl.searchItem();
	};

	abortLoadingEmails = () => {
		this.abortLoadingFlag = true;
		this.getEmailTracingInProcess = false;
		this.showAbortLoadingEmailsLabel = false;
	}

	viewEmailContent = (recipientObj) => {
		this.previewEmailContentPopup = {
			emailContent: null,
			rawEmailContent: null,
			getEmailContentInProcess: true,
			error: '',
			show: true,
		};

		const actionInfo = {action: 'viewContent', elkId: recipientObj._id};

		this.rs.emailTraceAction(actionInfo).then(response => {
			this.previewEmailContentPopup.emailContent = response?.parsedData || '';
			this.previewEmailContentPopup.rawEmailContent = response?.rawMail || '';
			this.previewEmailContentPopup.getEmailContentInProcess = false;
		}, err => {
			this.previewEmailContentPopup.getEmailContentInProcess = false;
			this.previewEmailContentPopup.error = err?.data?.message;
		});
	}

	downloadEml = () => {
		const attachment = {
			content: this.previewEmailContentPopup.rawEmailContent,
			name: `${this.previewEmailContentPopup.emailContent.subject}.eml`,
			contentType: 'message/rfc822'
		};
		this.gs.downloadData(attachment.content, attachment.name, attachment.contentType);
	}

	removeEmailFromRecipient = (recipients) => {
		this.gs.showPopup({
			title: `Remove email from ${recipients?.length > 1 ? `${recipients?.length} recipients` : recipients[0].rcptTo}`,
			subTitle: `Email will be removed from the selected recipients`,
			body: ['Note: To use this feature you need to be connected to the Exchange/Google API integration. This connection can be made from the Integrations page'],
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: `Confirm`,
			doneCb: () => {
				eachLimit(recipients, 3, (recipientObj, callback) => {
					const actionInfo = {action: 'removeEmail', elkId: recipientObj._id};
					this.rs.emailTraceAction(actionInfo).then(data => {
						if (data.activity) {
							recipientObj.text.push({timestamp: new Date().toISOString(), log: data.activity});
						}
						this.ns.showInfoMessage('Email remove operation called successfully');
						callback();
					}, err => {
					});
				}, () => {});
			}
		});
	}

	resendEmailToRecipient = (recipients) => {
		this.gs.showPopup({
			title: `Resend email to ${recipients?.length > 1 ? `${recipients?.length} recipients` : recipients[0].rcptTo}`,
			subTitle: `Email will be resent to the selected recipients`,
			body: ['Note: If the email already exists in the recipient\'s mailbox, the newly resent email might not be delivered due to de-duplication by the recipient MTA.'],
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: `Confirm`,
			doneCb: () => {
				recipients.forEach((emailObj) => {
					const actionInfo = {action: 'resendEmail', elkId: emailObj._id};
					this.rs.emailTraceAction(actionInfo).then(data => {
						this.ns.showInfoMessage('Email resend operation called successfully');
					}, err => {
					});
				});
			}
		});
	}

	selectEmailBulkAction = (selectedItems, action) => {
		switch (action) {
			case 'Resend email':
				this.resendEmailToRecipient(selectedItems);
				break;

			case 'Remove email':
				this.removeEmailFromRecipient(selectedItems);
				break;
		}
	};

	showEmailBulkActions = () => {
		return ['Resend email', 'Remove email'];
	}

	selectEmailAction = (emailObj, action) => {
		switch (action) {
			case 'Resend email':
				this.resendEmailToRecipient([emailObj]);
				break;

			case 'Remove email':
				this.removeEmailFromRecipient([emailObj]);
				break;

			case 'View email':
				this.viewEmailContent(emailObj);
				break;
		}
	};

	showEmailActions = () => {
		return ['View email', 'Resend email', 'Remove email'];
	}

	searchTraceLog = (searchTerm, activeFilters) => {
		this.tracingData.forEach(record => {
			if (!this.searchTraceLogTextExecute(record, searchTerm)) {
				record.hide = true;
				return;
			}

			if (this.activeMessageIdAsFilter && record.message_id !== this.activeMessageIdAsFilter) {
				record.hide = true;
				return;
			}

			// Filter
			if (activeFilters && !this.searchEmailTraceFilterExecute(this.dic, record, activeFilters)) {
				record.hide = true;
				return;
			}

			record.hide = false;
		});

	};

	searchEmailTraceFilterExecute = (dic, record, filters) => {
		// need to match all filter types
		let numFilterToMatch = Object.keys(filters).length;

		if (filters.status && filters.status.length) {
			if (filters.status.includes(record.status)) {
				numFilterToMatch--;
			}
		}

		return !numFilterToMatch;
	}

	searchTraceLogTextExecute = (traceLog, searchTerm) => {
		searchTerm = searchTerm.toLowerCase();
		return ((traceLog.subject && traceLog.subject.toLowerCase().indexOf(searchTerm) > -1) ||
			(traceLog.from && traceLog.from.toLowerCase().indexOf(searchTerm) > -1) ||
			(traceLog.rcptTo && traceLog.rcptTo.toLowerCase().indexOf(searchTerm) > -1) ||
			(traceLog.message_id && traceLog.message_id.toLowerCase().indexOf(searchTerm) > -1) ||
			(traceLog.lastDate && (new DatePipe('en-US')).transform(traceLog.lastDate || '', 'MM/dd/yyyy HH:mm').indexOf(searchTerm) > -1));
	}

	exportEmailTraceCsv = (sortBy) => {
		if (!this.tracingData?.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Date,Subject,Recipient,Sender,Message ID,Status,Logs\n";

		let sortedTable = _.reject(this.tracingData,'hide');
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((emailTraceObj) => {
			const messages = [];
			emailTraceObj.text?.forEach((msgObj) => {
				messages.push(msgObj.log);
			});
			csvString += `${emailTraceObj.created},${emailTraceObj.subject},${emailTraceObj.rcptTo},${emailTraceObj.from},${emailTraceObj.message_id},${emailTraceObj.status}, ${messages.join(' | ')}\n`;
		});

		this.gs.exportCsv(csvString, `email_trace.csv`);
	}

	copyToClipboard = (textToCopy) => {
		this.clipboard.copy(textToCopy);
	}

	downloadAttachment = (data, name, type) => {
		try {
			data = new Buffer(data, 'base64');
			this.gs.downloadData(data, name, type);
		} catch (err) {
			console.error(err);
		}
	};
}
