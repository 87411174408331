<div class="flxClmn">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Archive</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">
                Archive contains all emails that have passed through Trustifi's Email Relay service, inbound and outbound. Here you can display emails, perform tasks, and search the archive for specific emails
            </span>
        </div>
    </div>

    <div class="page-inner-content-holder no-scroll flxClmn vSpace2" style="min-height: 680px;">
        <!--choose case row-->
        <div class="flxRow" style="background: #f7f7f7; flex-wrap: wrap; min-height: 91px; ">
            <div class="flxRow hSpace2" style="align-items: center; flex:1 0; min-width: 605px; margin: 10px;">
                <btn-c endIcon="fas fa-plus"
                     (action)="openNewCasePopup();">
                    Create New Case
                </btn-c>
                <span style="font-weight: bold; font-style: italic;">OR</span>
                <div class="flxRow hSpace1" style="align-items: center;">
                    <span>Load an existing case:</span>

                    <app-dropdown-c style="width: 250px;"
                                  [text]="selectedCase?.name"
									[isDisabled]="getEmailsInProcess"
                                  [enableSearch]="true"
                                  itemDisplayKey="name"
                                  [items]="cases"
                                  (onItemClicked)="selectCase($event.item)"></app-dropdown-c>
                </div>
            </div>


            <!--case info row-->
            <div class="flxRow animated fadeIn" style="flex: 2 0; height: 65px; border-radius: 5px; color: black; margin: 10px; box-shadow: 0 0 2px #8b98b1ad; z-index: 6; position: relative; background: linear-gradient(#163f5d1a 2% , white 6% ,white 94% , #a0a0a02e);"
                 *ngIf="selectedCase">
                <div class="flxRow-between hSpace2 animated fadeIn h100" style="padding: 5px 15px; align-items: center; flex:1 0;">
					<div class="flxClmn gap-3 h100" style="flex-wrap: wrap;"
						 [ngStyle]="{'justify-content' : _.without([!!selectedCase.query.from, !!selectedCase.query.rcptTo, !!selectedCase.query.subject, selectedCase.query.hasAttachments, !!selectedCase.query.text, !!selectedCase.query.compliance?.length], false).length > 2 ? '' : 'center'}">
						<div *ngIf="selectedCase.query.from" class="flxRow hSpace05" style="margin: 1px 0; max-width: 260px;">
							<b>From:</b>
							<span class="ellipsis" [tooltip]="selectedCase.query.from" >{{selectedCase.query.from}}</span>
						</div>
						<div *ngIf="selectedCase.query.rcptTo" class="flxRow hSpace05" style="margin: 1px 0; max-width: 260px;">
							<b>Recipients:</b>
							<span class="ellipsis" [tooltip]="selectedCase.query.rcptTo">{{selectedCase.query.rcptTo}}</span>
						</div>
						<div *ngIf="selectedCase.query.subject" class="flxRow hSpace05" style="margin: 1px 0; max-width: 260px;">
							<b>Subject:</b>
							<span class="ellipsis" [tooltip]="selectedCase.query.subject">{{selectedCase.query.subject}}</span>
						</div>
						<div class="flxRow hSpace05" style="margin: 1px 0; max-width: 260px;">
							<b>Period:</b>
							<span class="ellipsis">{{dic.CONSTANTS.trendsPeriod[selectedCase.query.period]?.display}}</span>
						</div>
						<div *ngIf="selectedCase.query.hasAttachments" class="flxRow hSpace05" style="margin: 1px 0; max-width: 260px;">
							<b>Has Attachments:</b>
							<span>Yes</span>
						</div>
						<div *ngIf="selectedCase.query.text" class="flxRow hSpace05" style="margin: 1px 0; max-width: 260px;">
							<b>Contains:</b>
							<span class="ellipsis" [tooltip]="selectedCase.query.text">{{selectedCase.query.text}}</span>
						</div>
						<div *ngIf="selectedCase.query.compliance?.length" class="flxRow hSpace05" style="margin: 1px 0; max-width: 260px;">
							<b>Compliance:</b>
							<span class="ellipsis" [tooltip]="selectedCase.query.compliance.join(', ')">{{selectedCase.query.compliance.join(', ')}}</span>
						</div>
						<div *ngIf="selectedCase.query.scanStatus" class="flxRow hSpace05" style="margin: 1px 0; max-width: 260px;">
							<b>Scan Status:</b>
							<span class="ellipsis">{{selectedCase.query.scanStatus}}</span>
						</div>
					</div>
					<div class="flxRow" style="flex-direction: row-reverse; align-items: center;">
						<div class="btn-with-dropdown" style="width: 150px;">
							<btn-c (action)="applyCase(selectedCase);"
								   [isDisabled]="isApplyCaseEnabled && getEmailsInProcess">
								Apply Case
							</btn-c>
							<app-dropdown-c [isLf]="true">
								<ul>
									<li *ngFor="let action of caseActions"
										(click)="selectCaseAction(selectedCase, action)">
										{{action}}
									</li>
								</ul>
							</app-dropdown-c>
						</div>
						<span class="flxClmn-center animated fadeIn" style="cursor: pointer; height: calc(100% - 4px); padding: 10px; background: white; font-weight: 300; color: #85000d; font-size: 0.75rem;"
							  *ngIf="getEmailsInProcess"
							  (click)="abortLoadingEmails()">
								<b>Abort</b>
							</span>
					</div>
				</div>
                <span *ngIf="!selectedCase" style="text-align: center; margin: auto; color: grey;">Select a case from the list or create a new one</span>
            </div>
        </div>

        <!--main content area-->
        <div class="flxRow" style="flex: 1 0; border-top: solid 1px #e3e4e4; padding-top: 10px; overflow: auto;">
            <!--case panel / email list area (left side)-->
            <div class="flxClmn" style="flex: 1 0; padding: 5px; min-width: 450px; background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent);"
            [ngClass]="getEmailsInProcess && !activeCase && 'shinyFX'">

                <!--case panel - placeholder-->
                <div class="flxClmn" style="position: relative; flex: 1 0; overflow: auto;" *ngIf="!activeCase">
                    <span style=" position: absolute; z-index: 10; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.4; font-weight: bold;">{{getEmailsInProcess ? 'Loading emails list' : 'Select case to show emails'}}</span>
                    <div class="flxClmn fading-overlay" style="flex: 1 0; padding: 5px; background: white; overflow: auto;">
                        <div class="flxClmn" style="overflow: hidden; background:#f7f7f7; align-items: center; flex: 1 0;">
                            <div class="flxRow hSpace1 w100" style="height: 75px; min-height:75px; padding: 10px; opacity: 0.6; "
                                 [ngStyle]="!$last && {'border-bottom': 'solid 1px #e3e4e4'}"
                                 *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]">
                                <div class="flxClmn-center" style="align-items: center;">
                                    <div style="height: 15px; width: 15px; border-radius: 50%; background: #c4c4c4; opacity: 0.15;"></div>
                                </div>
                                <div class="flxClmn-between" style="flex: 3 0;">
                                    <div style="height:15px; max-width: 50%;  background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
                                    <div style="height: 20px; max-width: 73%; background: #c4c4c4; border-radius: 50px; opacity: 0.2;"></div>
                                </div>
                                <div class="flxClmn-between" style="flex: 1 0; padding: 3px 0;">
                                    <div style="height:15px; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
                                    <div class="flxRow-between">
                                        <div style="height:15px; width: 25%;  background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
                                        <div style="height:15px; width: 25%; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
                                        <div style="height:15px; width: 25%; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Email list-->
                <div class="flxClmn" style="flex: 1 0; overflow: auto;" *ngIf="activeCase">
                    <div class="w100 flxRow animated fadeIn" style="background: #677184; padding: calc(3px + 0.4vw); align-items: center; z-index: 1; min-height: 60px;">
                        <div class="flxRow-between hSpace2 w100" style="position: relative; align-items: center;">
                            <!--search results input and label-->
                            <div class="flxRow-start hSpace1" style="align-items: center; flex: 1 0; max-width: 350px;">
                                <div style="width: 20px; margin-left: 0;">
                                    <label class="policyCheckbox"
                                           (click)="$event.stopPropagation()"
                                           style="width: 16px; display: block; vertical-align: unset; position: unset; margin: 0; filter: invert(1) brightness(3);">
                                        <input type="checkbox" (change)="toggleAllEmails()"
                                               [(ngModel)]="selectedAll">
                                        <span style="font-size: 12px;">&nbsp;</span>
                                    </label>
                                </div>
								<search-box [(model)]="searchArchiveEmailTxt"
											(modelChange)="searchEmailInResults(searchArchiveEmailTxt)"
											[isDisabled]="!archiveService.currentEmailsList.length"
											placeholder="Find in results"
											style="width:100%;">
								</search-box>
                                <span style="color: white; flex-shrink: 0;" *ngIf="!getEmailsInProcess && !selectedEmails.length && archiveService.currentFilteredEmailsList.length">
									{{archiveService.currentFilteredEmailsList.length}} email{{archiveService.currentFilteredEmailsList.length > 1 ? 's' : ''}}
								</span>
                                <div class="loading-spinner" style="position: relative; top: 0; left: 0; border: 2px solid transparent; border-top-color: white !important; border-left-color: white !important; opacity: 1; height: 15px; width: 15px;"
                                     *ngIf="archiveService.currentEmailsList.length && getEmailsInProcess"></div>
                            </div>

                            <!--action for results-->
                            <div class="flxRow hSpace1"  style="color: white;">
                                <div class="flxRow hSpace1" *ngIf="selectedEmails.length">
                                    <div class="flxClmn fadeIn vSpace05" style="align-items: center; cursor: pointer;"
                                         (click)="deleteMultipleEmails()">
                                        <i class="far fa-trash-alt" style="font-size: 1.2rem;"></i>
                                        <span style="font-size: 0.7rem;">Delete emails</span>
                                    </div>
                                    <div class="vertical-separator fadeIn" style="border-color: white; border-left: none;"></div>

									<div class="flxClmn fadeIn vSpace05" style="align-items: center; cursor: pointer;"
										 (click)="downloadMultipleEmls()">
										<i class="far fa-save" style="font-size: 1.2rem;"></i>
										<span style="font-size: 0.7rem;">Download EMLs</span>
									</div>
									<div class="vertical-separator fadeIn" style="border-color: white; border-left: none;"></div>
								</div>

                                <div class="flxClmn fadeIn vSpace05" style="align-items: center; cursor: pointer;"
                                     *ngIf="archiveService.currentFilteredEmailsList.length"
                                     (click)="exportResultsToCsv()">
                                    <i class="fas fa-file-export" style="font-size: 1.2rem;"></i>
                                    <span style="font-size: 0.7rem;">Export to CSV</span>
                                </div>
                            </div>
                        </div>
                    </div>
					<!--results table-->
                    <div class="animated fadeIn flxClmn" style="flex: 1 0; overflow: auto; background: white;"
                         *ngIf="archiveService.currentEmailsList?.length || getEmailsInProcess">
						<virtual-scroller class="f-1-0" style="border: solid 1px #cccccc;"
										  #archiveEmailsListScroll
										  [items]="archiveService.currentFilteredEmailsList"
										  [ngClass]="{'is-loading-or-empty' : !archiveService.currentFilteredEmailsList.length}">
							<table class="w100 mailsListTable ellips">
								<tbody #container>
								<tr *ngFor="let email of archiveEmailsListScroll.viewPortItems"
									(click)="getArchiveEmailInfo(email)"
									[ngClass]="{'selected': email.selected, 'is-active': email.show}">

									<td style="width: 20px; padding-left: 8px;">
										<label class="policyCheckbox"
											   (click)="$event.stopPropagation()"
											   style="width: 16px; display: block; vertical-align: unset; position: unset; margin: 0; ">
											<input type="checkbox"
												   (change)="checkSelectAllEmails()"
												   [(ngModel)]="email.selected"/>
											<span style="font-size: 12px;">&nbsp;</span>
										</label>
									</td>
									<td>
										<div class="flxClmn vSpace1">
											<div class="flxRow-between">
												<div>
													<span style="opacity: 0.85;">{{email._source.from}}</span>
												</div>
												<span *ngIf="email._source.deleted" style="color: red; padding: 0 5px; flex-shrink: 0; opacity: 1; font-size: 0.7rem; font-family: 'Roboto Condensed', sans-serif;">[Deleted]</span>
											</div>

											<div style="font-weight: 500; font-size: 0.95rem;">
												<span >{{email._source.subject}}</span>
											</div>
										</div>
									</td>
									<td style="width: 150px;">
										<div class="flxClmn-between vSpace05" style="align-items: flex-end;">
											<div style="flex: 1 0;">
												<span>{{ email._source.created | date:'MM/dd/yyyy HH:mm' }}</span>
											</div>
											<div class="flxRow-between hSpace05 w100" style="flex:1 0; align-items: baseline;">
												<span style="visibility: hidden;"></span>
												<div style=" color: darkslategrey;" *ngIf="email._source.attachments.length">
													<span style="font-weight: 400;" >{{email._source.attachments.length}}</span>
													<i class="fas fa-paperclip" style=" font-size: 14px; -webkit-text-stroke: 0.3px #f7f6f9;"></i>
												</div>
												<span style="opacity: 0.5; font-size: 0.6rem;" >{{(email._source.size | FormatBytes: 2)}}</span>
											</div>
										</div>
									</td>
								</tr>
								</tbody>
							</table>
						</virtual-scroller>
						<div *ngIf="!archiveService.currentFilteredEmailsList.length" class="flxClmn-center"
							 style="align-items: center; flex: 1 0; background: white;">
							<div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 0.7;" *ngIf="getEmailsInProcess"></div>
							<span *ngIf="!getEmailsInProcess && archiveService.currentEmailsList.length" style="color: darkgrey; font-size: 18px;">No results for this search</span>
						</div>
                    </div>
					<!--placeholder for empty case-->
					<div class="f-1-0 flxClmn centralize" style="background: white;" *ngIf="!getEmailsInProcess && !archiveService.currentEmailsList.length">
						<span style="color: darkgrey; font-size: 18px;">No emails in this case</span>
					</div>
                </div>
            </div>

            <!--Email display area (right side)-->
            <div class="vertical-separator" style="border-color: #e3e4e4; border-left: none;"></div>
            <div class="flxClmn" style="flex: 2 0; align-items: center; background: #f7f7f7; margin-left: 20px; position: relative; overflow: auto;"
            [ngStyle]="archiveService.currentMessage?.content?.parsedContent && activeCase && {'background':' transparent'} ">
                <!--loading overlay-->
                <div class="flxClmn-center animated fadeIn" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 5; background: rgba(255,255,255,0.6); align-items: center;"
                     *ngIf="previewEmailInProcess">
                    <div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 1;"></div>
                </div>
                <!----->
                <div class="flxClmn-center vSpace2 w100" style="flex:1 0; overflow: auto;"
                     *ngIf="archiveService.currentMessage?.content?.parsedContent">
                    <!--tabs-->
                    <div class="simple-tab-row w100 flxRow">
                        <div class="tab-header"
                             [ngClass]="{'chosen' : archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.content}"
                             (click)="archiveService.tab = dic.CONSTANTS.archiveEmailViewPages.content">
                            <span class="hvr-opacity">Email Content</span>
                        </div>
                        <div class="tab-header" style="overflow: auto;"
                             [ngClass]="{'chosen' : archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.headers}"
                             (click)="archiveService.tab = dic.CONSTANTS.archiveEmailViewPages.headers">
                            <span class="hvr-opacity">Headers</span>
                        </div>
                        <div class="tab-header"
                             *ngIf="archiveService.currentMessage._source.emailId"
                             [ngClass]="{'chosen' : archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.tracking}"
                             (click)="archiveService.tab = dic.CONSTANTS.archiveEmailViewPages.tracking">
                            <span class="hvr-opacity">Outbound Tracking</span>
                        </div>
                        <div class="tab-header"
                             *ngIf="archiveService.currentMessage._source.quarantineIds && archiveService.currentMessage._source.quarantineIds.length"
                             [ngClass]="{'chosen' : archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.quarantined}"
                             (click)="archiveService.tab = dic.CONSTANTS.archiveEmailViewPages.quarantined">
                            <span class="hvr-opacity">Inbound Analysis</span>
                        </div>
                    </div>

                    <div class="flxClmn" style="flex: 1 0; overflow: auto;">
						<archive-email-details-component *ngIf="archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.content"
														 class="flxClmn f-1-0" style="overflow: auto;" ></archive-email-details-component>
                        <archive-email-headers-component *ngIf="archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.headers"
														 class="flxClmn" style="flex: 1 0; overflow: auto;"></archive-email-headers-component>
						<archive-email-tracking-component *ngIf="archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.tracking"
														  class="flxClmn" style="flex: 1 0; overflow: auto;"></archive-email-tracking-component>
                        <archive-email-quarantined-component *ngIf="archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.quarantined"
															 class="flxClmn" style="flex: 1 0; overflow: auto;"></archive-email-quarantined-component>
                    </div>
                </div>

                <div *ngIf="!archiveService.currentMessage && !previewEmailInProcess" class="flxClmn-center"
                     style="align-items: center; flex: 1 0;">
                    <i class="fas hvr-opacity fa-envelope-open-text" style="font-size: 4vw;  opacity: 0.1;"></i>
                </div>
            </div>
        </div>
		<archive-cases-operations-component #caseOperationsEl></archive-cases-operations-component>
    </div>

    <popup-c class="overflownV" *ngIf="createEditCasePopup?.show"
           [head]="createEditCasePopup.isEditCase ? 'Edit Case' : 'Create a new case for archive'"
           size="dynamic" width="650px"
           (closeCb)="createEditCasePopup = null;"
           (confirmCb)="applyCreateEditCasePopup()"
           [buttons]="{cancel: 'Cancel', confirm: {text: createEditCasePopup.isEditCase ? 'Confirm' : 'Create case'}}">
        <div class="flxClmn vSpace3 animated fadeIn" style="flex: 1 0; position: relative; z-index: 20; overflow: visible;">
            <div class="flxClmn vSpace1">
                <span style="font-size: 1.1rem; flex-shrink: 0; font-family: 'Roboto Condensed', sans-serif;">Case Name:</span>
                <div style=" background: #f9f9f9; padding: 10px 5px;">
                    <input class="one-lined-input w100" style="margin: 0; font-size: 0.9rem; max-width: 250px; height: auto; background: transparent;"
                           id="caseNameInput"
                           [ngStyle]="createEditCasePopup.isEditCase && {'border' : 'none !important', 'cursor' : 'not-allowed'}"
                           [(ngModel)]="createEditCasePopup.currentCase.name" maxlength="320"
                           [readOnly]="createEditCasePopup.isEditCase"
                           type="text">
                </div>
            </div>

            <div class="flxClmn vSpace1">
                <span style="font-size: 1.1rem; font-family: 'Roboto Condensed', sans-serif;">Archive Search Parameters:</span>
                <div style="flex: 1 0; padding: 0 10px 15px; background: #f9f9f9;" class="flxClmn-around">
                    <div class="flxClmn" style="max-width: 500px;">
                        <div class="vSpace2" style="flex: 1 0;">
                            <table style="border-collapse: unset; table-layout: fixed; border-spacing: 0 10px; width: 100%; color: #292929;">
                                <tbody>
                                <tr>
                                    <td style="width: 135px;"><span>From:</span></td>
                                    <td><input
                                            id="caseFromInput"
                                            class="one-lined-input w100"
                                            style="margin: 0; font-size: 0.9rem; background: transparent;"
                                            [(ngModel)]="createEditCasePopup.currentCase.query.from" maxlength="320" type="text">
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Recipients:</span></td>
                                    <td><input
                                            id="caseRecipientsInput"
                                            class="one-lined-input w100"
                                            style="margin: 0; font-size: 0.9rem; background: transparent;"
                                            [(ngModel)]="createEditCasePopup.currentCase.query.rcptTo"
                                            maxlength="320"
                                            type="text">
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Subject:</span></td>
                                    <td><input
                                            id="caseSubjectInput"
                                            class="one-lined-input w100"
                                            style="margin: 0; font-size: 0.9rem; background: transparent;"
                                            [(ngModel)]="createEditCasePopup.currentCase.query.subject" maxlength="320" type="text">
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 1px; white-space: nowrap; padding-right: 15px;"><span>Contains the words:</span></td>
                                    <td>
                                        <input
                                                id="caseContainWordsInput"
                                                class="one-lined-input w100"
                                                style="margin: 0; font-size: 0.9rem; background: transparent;"
                                                [(ngModel)]="createEditCasePopup.currentCase.query.text" maxlength="320" type="text">
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Date:</span></td>
                                    <td>
                                        <app-dropdown-c id="caseDateDropdownInput" style="width: 100%; height: auto;" [text]="dic.CONSTANTS.trendsPeriod[createEditCasePopup.currentCase.query.period].display">
                                            <ul style="max-height: 140px; overflow: auto;">
                                                <li *ngFor="let periodObj of casesPeriods | keyvalue: gs.returnZero"
                                                    (click)="createEditCasePopup.currentCase.query.period = periodObj.value.value;">
                                                    {{periodObj.value.display}}
                                                </li>
                                            </ul>
                                        </app-dropdown-c>
                                    </td>
                                </tr>
                                <tr class="animated fadeIn" style="position: relative; z-index: 50;"
									*ngIf="createEditCasePopup.currentCase.query.period === dic.CONSTANTS.trendsPeriod.range.value">
                                    <td>
                                    </td>
                                    <td>
										<div class="flxRow hSpace1" style="align-items: center;">
											<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
												<span>From:</span>
												<date-picker-c class="f-1-0"
															   [(dateModel)]="createEditCasePopup.currentCase.query.range.start"
															   [dateMaxLimit]="createEditCasePopup.currentCase.query.range.end"></date-picker-c>
											</div>
											<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
												<span>Until:</span>
												<date-picker-c class="f-1-0"
															   [(dateModel)]="createEditCasePopup.currentCase.query.range.end"
															   [dateMinLimit]="createEditCasePopup.currentCase.query.range.start"></date-picker-c>
											</div>
										</div>
                                    </td>
                                </tr>
								<tr>
									<td>
										<div class="flxRow-start md-checkbox" style="align-items: center;">
											<input id="hasAttachments" type="checkbox" [(ngModel)]="createEditCasePopup.currentCase.query.hasAttachments"/>
											<label for="hasAttachments">Has attached files</label>
										</div>
									</td>
								</tr>
								<tr>
									<td style="padding-top: 15px; padding-bottom: 10px;">
										<b class="cursor-pointer" (click)="showNewCaseAdvanced = !showNewCaseAdvanced;">
											Advanced
											<i class="fa fa-chevron-down" style="font-size: 9px;" *ngIf="!showNewCaseAdvanced"></i>
											<i class="fa fa-chevron-up" style="font-size: 9px;" *ngIf="showNewCaseAdvanced"></i>
										</b>
									</td>
								</tr>
								<ng-container *ngIf="showNewCaseAdvanced">
									<tr class="animated fadeIn">
										<td>
											<span>Compliance:</span>
										</td>
										<td>
											<app-dropdown-c id="compliance" style="width: 100%; height: auto;"
															[items]="compliances"
															[isMultipleSelection]="true"
															itemDisplayKey="name"></app-dropdown-c>
										</td>
									</tr>
									<tr>
										<td style="font-size: 12px;">Will apply only to outbound emails that were sent with Trustifi and scanned for sensitive content</td>
									</tr>

<!--									<tr class="animated fadeIn">
										<td style="padding-top: 15px;">
											<span>Scan Status:</span>
										</td>
										<td>
											<app-dropdown-c style="width: 100%;" text="{{createEditCasePopup.currentCase.query.scanStatus}}">
												<li *ngFor="let category of dic.CONSTANTS.quarantinedCategories"
													(click)="createEditCasePopup.currentCase.query.scanStatus = category;"
													id="{{category}}">
													{{category}}
												</li>
											</app-dropdown-c>
										</td>
									</tr>
									<tr>
										<td style="font-size: 12px;"  colspan="2">Will apply only to inbound emails that were scanned by Trustifi Inbound Shield</td>
									</tr>-->
								</ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </popup-c>
</div>
