
import * as util from 'util';
import _ from 'lodash';
import { RouteService } from '../../../../services/routeService';
import { GeneralService } from '../../../../services/generalService';
import { NotificationService } from '../../../../services/notificationService';
import { DICTIONARY } from '../../../../dictionary';
import {Component, OnInit} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";

@Component({
	selector: 'domain-spoofing-component',
	templateUrl: './domain-spoofing.component.html',
})
export class DomainSpoofingComponent implements OnInit {
    dic = DICTIONARY;
    getTPConfigInProcess = true;
    userInfo: any;
    config: any;
    internalPlanDomains: any;
    domainSourcesPopup: any;
    domainAnalyzePopup: any;
	addDomainPopup: any;
    sourceActionInProcess: boolean;
    searchSourceTxt: string;
	_=_;

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private clipboard: ClipboardService) {
    }

	ngOnInit() {
        this.getTpDomains();

        this.gs.getUserInfo(false,  (userInfo) => {
            this.userInfo = userInfo;
        });
    }

    getTpDomains = () => {
        this.rs.getTpDomains().then((response) => {
            this.config = response;
            this.internalPlanDomains = (
                (this.config.domains && this.config.domains.filter(itm => itm.verified).map(itm => itm.domain) || [])
                    .concat((this.config.exchange_server && this.config.exchange_server.verified_domains || [])
                        .concat(this.config.gsuite_server && this.config.gsuite_server.verified_domains || [])));

            if (this.config.threat_protection.known_domains && this.config.threat_protection.known_domains.length) {
                this.config.threat_protection.known_domains.forEach((domainObj) => {
                    this.checkDomainType(domainObj);

                    domainObj.signature = 0;
                    domainObj.signatureStrengthTotal = 0;
                    if (domainObj.signatures && domainObj.signatures.email) {
                        domainObj.signature += domainObj.signatures.email.length;
                        domainObj.signatures.email.forEach((signatureObj) => {
                            this.calculateScore(signatureObj);
                            domainObj.signatureStrengthTotal += signatureObj.strength.score;
                        });
                    }
                    if (domainObj.signatures && domainObj.signatures.ndr) {
                        domainObj.signature += domainObj.signatures.ndr.length;
                        domainObj.signatures.ndr.forEach((signatureObj) => {
                            this.calculateScore(signatureObj);
                            domainObj.signatureStrengthTotal += signatureObj.strength.score;
                        });
                    }
                    if (domainObj.signatures && domainObj.signatures.oof) {
                        domainObj.signature += domainObj.signatures.oof.length;
                        domainObj.signatures.oof.forEach((signatureObj) => {
                            this.calculateScore(signatureObj);
                            domainObj.signatureStrengthTotal += signatureObj.strength.score;
                        });
                    }
                    if (domainObj.signatures && domainObj.signatures.calendar) {
                        domainObj.signature += domainObj.signatures.calendar.length;
                        domainObj.signatures.calendar.forEach((signatureObj) => {
                            this.calculateScore(signatureObj);
                            domainObj.signatureStrengthTotal += signatureObj.strength.score;
                        });
                    }

                    domainObj.signatureStrengthAvg = domainObj.signature ? (domainObj.signatureStrengthTotal / domainObj.signature) : 0;
                    domainObj.scoreChartOptions = this.createScoreChartOptions(domainObj.signatureStrengthAvg);
                });
            }
            this.getTPConfigInProcess = false;
        }, (err) => {
            this.getTPConfigInProcess = false;
        });
    }

    checkDomainType = (domainObj) => {
        domainObj.type = this.internalPlanDomains.includes(domainObj.domain) ? 'Internal' : 'External';
    }

    showDomainActions = () => {
        return [
            DICTIONARY.CONSTANTS.inboundConfigurationsActions.sources,
            DICTIONARY.CONSTANTS.inboundConfigurationsActions.analyzeDomain,
            DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove
        ]
    }

    showDomainBulkActions = () => {
        return [
            DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove
        ]
    }

    selectDomainAction = (domain, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove:
                this.removeDomains([domain]);
                break;

            case DICTIONARY.CONSTANTS.inboundConfigurationsActions.sources:
                this.openSourcesPopup(domain);
                break;

            case DICTIONARY.CONSTANTS.inboundConfigurationsActions.analyzeDomain:
                this.openAnalyzeDomainPopup(domain);
                break;
        }
    };

    selectMultipleDomainAction = (selectedItems, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove:
                this.removeDomains(selectedItems);
                break;
        }
    };

	openAddDomainPopup = () => {
		this.addDomainPopup = {
			domain: '',
			addDomainInProcess: false,
			show: true
		}
            }

    confirmAddNewDomain = () => {
		const domain = this.addDomainPopup.domain;

		// validation
        if (!domain) {
            return this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.accountEmailMissingName, 'Domain field'));
        }
        if (!this.gs.isValidDomain(domain)) {
            return this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.invalidDomain, domain));
        }
        if (_.map(this.config.threat_protection.known_domains, 'domain').includes(domain)) {
            this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.domainAlreadyExist, domain));
            return;
        }
		//

        const data = {
            action: DICTIONARY.CONSTANTS.actions.add
        };

		this.addDomainPopup.addDomainInProcess = true;

        this.rs.updateTpDomains(domain, data).then((response) => {
			// build new domain object for table
			const newDomainObj = {
				signature: 0,
				created: (new Date()).toISOString(), // all other dates in table come in this format (important for sorting)
				domain,
				signatureStrengthAvg: 0,
				scoreChartOptions: this.createScoreChartOptions(0)
			}
			this.checkDomainType(newDomainObj);

			this.config.threat_protection.known_domains.push(newDomainObj);

            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, domain));
			// this.addDomainPopup.addDomainInProcess = false
			this.addDomainPopup = null;
        }, (err) => {
			this.addDomainPopup.addDomainInProcess = false;
        });
    };

    removeDomains = (domains) => {
        if (!domains || !domains.length) {
            return;
        }

        domains = _.map(domains, 'domain');
        const domainsRemovedText = domains.length === 1 ? domains[0] : domains.length + ' domains';
        this.gs.showPopup({
            title: 'Remove domains',
            subTitle: `Are you sure you want to remove ${domainsRemovedText}?`,
            type: DICTIONARY.CONSTANTS.popupWarning,
            doneBtnText: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove,
            doneCb: () => {
                domains.forEach((domain) => {
                    const data = {
                        action: DICTIONARY.CONSTANTS.actions.delete
                    };
                    this.rs.updateTpDomains(domain, data).then((response) => {
                        _.remove<any>(this.config.threat_protection.known_domains, d => domains.includes(d.domain));
                        this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemRemoved, domain));
                    }, err => {});
                });
            }
        });
    };

    searchDomainsList = (searchText, activeFilters) => {
        this.config.threat_protection.known_domains.forEach(record => {
            // search
            if (searchText) {
                const isFound = searchDomainsTextExecute(record, searchText);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter

            record.hide = false;
        });
    }

    openAnalyzeDomainPopup = (domainObj) => {
        this.domainAnalyzePopup = {
            domainObj: domainObj,
            show: true,
            loading: true,
            dataCategories: ['spf', 'dmarc', 'mx', 'mta-sts', 'server'],
            globalError: ''
        }

        const data = {
            action: DICTIONARY.CONSTANTS.configTpAction.knownDomainAnalyzer,
        };

        this.rs.updateTpDomains(domainObj.domain, data).then((response) => {
            this.domainAnalyzePopup.dataCategories.forEach(category => {
                this.domainAnalyzePopup[category] = {
                    error: response[category]?.error,
                    record: response[category]?.record,
                    data: [
                        {
                            title: 'syntax issues',
                            commentsIcon: 'fa fa-exclamation-triangle text-warning',
                            comments: response[category]?.errors
                        },
                        {
                            title: 'security concerns',
                            commentsIcon: 'fa fa-exclamation-triangle text-warning',
                            comments: response[category]?.security
                        },
                        {
                            title: 'recommendations',
                            commentsIcon: 'fa fa-exclamation-circle text-info',
                            comments: response[category]?.recommendations
                        },
                    ]
                }
            });
            this.domainAnalyzePopup.loading = false;
        }, (err) => {
            this.domainAnalyzePopup.loading = false;
            this.domainAnalyzePopup.globalError = this.dic.ERRORS.errorRetrievingData;
        });
    }

    copyToClipboard = (copyTxt) => {
        this.clipboard.copy(copyTxt);
    }

    exportDomainsListToCsv = (sortBy) => {
        let sortedTable = _.filter(this.config.threat_protection.known_domains,record => {return !record.hide && !record.isNew});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        let csvString = "Created, Domain, Type, Sources, Strength\n";

        sortedTable.forEach((item) => {
            csvString += `${item.created},${item.domain},${item.type},${item.signature || 0},${scoreToString(item.signatureStrengthAvg || 0)}\n`;
        });

        this.gs.exportCsv(csvString, `configuration_domains.csv`);
    }

    changeSourceType = (newType) => {
        this.domainSourcesPopup.activeType = newType;
        this.domainSourcesPopup.sources = this.domainSourcesPopup.domainObj.sources[this.domainSourcesPopup.activeType.toLowerCase()] || [];
    }

    showBulkSourcesActions = () => {
        return [DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove];
    }

    showSourceActions = () => {
        return [DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove];
    }

    selectMultipleSourcesAction = (selectedRecords,action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove:
                // delete sources
                break;
        }
    }

    selectSourceAction = (record,action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove:
                this.removeDomainSource(record)
                break;
        }
    }

    removeDomainSource = (sourceObj) => {
        if (!sourceObj) {
            return;
        }

        this.gs.showPopup({
            title: 'Remove domain source',
            subTitle: `You are about to remove a source for domain ${this.domainSourcesPopup.domainObj.domain}`,
            body: [],
            type: DICTIONARY.CONSTANTS.popupWarning,
            doneBtnText: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove,
            doneCb: () => {
                this.sourceActionInProcess = true;
                sourceObj.type = this.domainSourcesPopup.activeType.toLowerCase();

                const data = {
                    action: DICTIONARY.CONSTANTS.configTpAction.deleteDomainSignature,
                    signatureObj: sourceObj
                };

                this.rs.updateTpDomains(this.domainSourcesPopup.domainObj.domain, data).then((response) => {
                    this.sourceActionInProcess = false;
                    _.remove<any>(this.domainSourcesPopup.sources, source => {
                        return source.hash === sourceObj.hash;
                    });
                    this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemRemoved, 'Source'));
                }, (err) => {
                    this.sourceActionInProcess = false;
                    this.ns.showErrorMessage(err.data.message);
                });
            }
        });
    }

    openSourcesPopup = (domainObj) => {
        if (!domainObj || !domainObj.signatures || _.isEmpty(domainObj.signatures)) {
            this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.noDomainSources, domainObj.domain));
            return;
        }

        domainObj.sources = domainObj.signatures;

        this.searchSourceTxt = '';
        Object.keys(DICTIONARY.CONSTANTS.domainSourceTypes).forEach((key) => {
            const list = domainObj.sources[key];
            if (list) {
                list.forEach(signatureObj => {
                    signatureObj.selected = false;
                    signatureObj.hide = false;
                    signatureObj.scoreChartOptions = this.createScoreChartOptions(signatureObj.strength.score);
                });
            }
        });

        this.domainSourcesPopup = {
            domainObj: domainObj,
            sources: domainObj.sources[DICTIONARY.CONSTANTS.domainSourceTypes.email.toLowerCase()],
            activeType: DICTIONARY.CONSTANTS.domainSourceTypes.email,
            show: true,
            message: {
                type: null,
                text: ''
            }
        }
    }

    searchSource = (searchTerm, activeFilters) => {
        this.domainSourcesPopup.sources.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchSourceTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            // // currently no filters for all tables in cfg page

            record.hide = false;
        });
    }

    calculateScore = (signatureObj) => {
        signatureObj.strength = { score: 0, description: '' };

        let improveDescription = '';
        let improveDescriptionDic = 'Improve <b>email security</b> and <b>prevent spoofing</b> by fixing your <b>';

        let fixDescription = '';
        let fixDescriptionDic = 'Having trouble with <b>email authentication</b> or <b>deliverability</b>? Verify and update your <b>';

        const spfNoneOrPass = ((!signatureObj.authenticationResults.spf || ['none','pass','bestguesspass'].includes(signatureObj.authenticationResults.spf)) && (!signatureObj.receivedSPF.status || ['none','pass','bestguesspass'].includes(signatureObj.receivedSPF.status)));
        const dkimNoneOrPass = (!signatureObj.authenticationResults.dkim || ['none','pass','bestguesspass'].includes(signatureObj.authenticationResults.dkim));
        const dmarcNoneOrPass = (!signatureObj.authenticationResults.dmarc || ['none','pass','bestguesspass'].includes(signatureObj.authenticationResults.dmarc));

        if (signatureObj.internal && spfNoneOrPass && dkimNoneOrPass && dmarcNoneOrPass) {
            signatureObj.strength.score = 10;
            signatureObj.strength.description = 'Email authentication <b>verified</b> as an <b>internal</b> email source.';
            signatureObj.authenticationResults.spf = 'internal';
            signatureObj.authenticationResults.dkim = 'internal';
            signatureObj.authenticationResults.dmarc = 'internal';
            signatureObj.receivedSPF.status = 'internal';
            return true;
        } else {
            if (signatureObj.authenticationResults.spf) {
                switch (signatureObj.authenticationResults.spf) {
                    case '':
                    case 'none':
                        improveDescription = !improveDescription ? improveDescriptionDic + 'SPF' : improveDescription + ', SPF';
                        signatureObj.strength.score -= 1;
                        break;
                    case 'pass':
                    case 'bestguesspass':
                        signatureObj.strength.score += 3;
                        break;
                    default:
                        fixDescription = !fixDescription ? fixDescriptionDic + 'SPF' : fixDescription + ', SPF';
                        signatureObj.strength.score -= 2;
                        break;
                }
            } else if (signatureObj.receivedSPF.status) {
                switch (signatureObj.receivedSPF.status) {
                    case '':
                    case 'none':
                        improveDescription = !improveDescription ? improveDescriptionDic + 'SPF' : improveDescription + ', SPF';
                        signatureObj.strength.score -= 1;
                        break;
                    case 'pass':
                    case 'bestguesspass':
                        signatureObj.strength.score += 3;
                        break;
                    default:
                        fixDescription = !fixDescription ? fixDescriptionDic + 'SPF' : fixDescription + ', SPF';
                        signatureObj.strength.score -= 2;
                        break;
                }
            } else {
                improveDescription = !improveDescription ? improveDescriptionDic + 'SPF' : improveDescription + ', SPF';
                signatureObj.strength.score -= 1;
            }

            if (signatureObj.authenticationResults.dkim) {
                switch (signatureObj.authenticationResults.dkim) {
                    case '':
                    case 'none':
                        improveDescription = !improveDescription ? improveDescriptionDic + 'DKIM' : improveDescription + ', DKIM';
                        signatureObj.strength.score -= 1;
                        break;
                    case 'pass':
                    case 'bestguesspass':
                        signatureObj.strength.score += 3;
                        break;
                    default:
                        fixDescription = !fixDescription ? fixDescriptionDic + 'DKIM' : fixDescription + ', DKIM';
                        signatureObj.strength.score -= 2;
                        break;
                }
            } else {
                improveDescription = !improveDescription ? improveDescriptionDic + 'DKIM' : improveDescription + ', DKIM';
                signatureObj.strength.score -= 1;
            }

            if (signatureObj.authenticationResults.dmarc) {
                switch (signatureObj.authenticationResults.dmarc) {
                    case '':
                    case 'none':
                        improveDescription = !improveDescription ? improveDescriptionDic + 'DMARC' : improveDescription + ', DMARC';
                        break;
                    case 'pass':
                    case 'bestguesspass':
                        signatureObj.strength.score += 4;
                        break;
                    default:
                        fixDescription = !fixDescription ? fixDescriptionDic + 'DMARC' : fixDescription + ', DMARC';
                        signatureObj.strength.score -= 1;
                        break;
                }
            } else {
                improveDescription = !improveDescription ? improveDescriptionDic + 'DMARC' : improveDescription + ', DMARC';
            }
        }

        if (improveDescription) {
            improveDescription += '</b> record(s).';
            signatureObj.strength.description = improveDescription;
        }

        if (fixDescription) {
            fixDescription += '</b> record(s).';
            signatureObj.strength.description = !signatureObj.strength.description ? fixDescription : signatureObj.strength.description + '</br>' + fixDescription;
        }

        if (!signatureObj.strength.description) {
            signatureObj.strength.description = 'Email authentication <b>passed</b> and <b>verified</b> with SPF, DMARC, and DKIM standards.';
        }

        if (!signatureObj.strength.score || signatureObj.strength.score < 0) {
            signatureObj.strength.score = 1;
        }
    }

    createScoreChartOptions = (score) => {
        const minValue = 0;
        const maxValue = 10;

        const valueToPercent = (val) => ( (val - minValue) * 100 ) / (maxValue - minValue);
        const chartColor = score === 0 ? '#ff0000' :
            score <= 3 ? '#f34d50' :
                score <= 7 ? '#e3e030' : '#4be330';

        return {
            series: [valueToPercent(score)],
            chart: {
                type: "radialBar",
				animations: {
					dynamicAnimation: {
						enabled: false
					}
				}
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            formatter: (val) => scoreToString(score),
                            fontSize: "13px",
                            offsetY: -5
                        }
                    }
                }
            },
            fill: {
                colors: [chartColor],
            },
            stroke: {
                dashArray: 4
            },
            labels: ["risk"]
        };
    }
}

function searchDomainsTextExecute(record, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return (record.domain.toLowerCase().indexOf(searchTerm) >= 0);
}

function searchSourceTextExecute(record, searchTerm) {
    searchTerm = searchTerm.toLowerCase();

    return ((record.authenticationResults.headerFrom && record.authenticationResults.headerFrom.toLowerCase().indexOf(searchTerm) >= 0) ||
            (record.authenticationResults.mailFrom && record.authenticationResults.mailFrom.toLowerCase().indexOf(searchTerm) >= 0) ||
            (record.receivedSPF.domain && record.receivedSPF.domain.toLowerCase().indexOf(searchTerm) >= 0) ||
            (record.sendingMTA.domain && record.sendingMTA.domain.toLowerCase().indexOf(searchTerm) >= 0) ||
            (record.messageID.domain && record.messageID.domain.toLowerCase().indexOf(searchTerm) >= 0));
}

function scoreToString(score) {
    return score === 0 ? 'Insecure' :
        score <= 3 ? 'Weak' :
            score <= 7 ? 'Moderate' : 'Strong';
}
