import {Component, Input, OnInit} from '@angular/core';
import {GeneralService} from "../../../../services/generalService";
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {DICTIONARY} from "../../../../dictionary";
import html2pdf from 'html2pdf.js';
import _ from "lodash";
import util from "util";
import {LookAndFeelService} from "../../../../services/lookAndFeelService";

@Component({
  selector: 'threat-simulation-campaign-summary-component',
  templateUrl: './threat-simulation-campaign-summary.component.html',
})
export class ThreatSimulationCampaignSummaryComponent implements OnInit {

	@Input() currentCampaign;
	constructor(public gs:GeneralService,
				private rs:RouteService,
				public lfs:LookAndFeelService,
				private ns:NotificationService) {
	}

	dic = DICTIONARY
	isTrainingCampaign;
	campaignSummary: any;
	getSummaryInProcess: boolean = false;
	generatePdfInProcess: boolean = false;
	generalEventsChartOptions: any;
	linksEventsChartOptions: any;
	acknowledgeEventsChartOptions: any;
	attachmentEventsChartOptions: any;
	recipientsEventsTable: any;


	ngOnInit() {
		this.isTrainingCampaign = this.currentCampaign.ts_type === this.dic.CONSTANTS.threatSimulationType.materials.value;

		this.getSummaryData();
	}

	getSummaryData = () => {
		if (this.currentCampaign.campaigns?.length) {
			const campaignIDs = _.map(this.currentCampaign.campaigns, '_id');
			this.getCampaignGroupSummary(campaignIDs);
		}
		else {
			this.getCampaignSummary();
		}
	}

	getCampaignSummary = () => {
		this.getSummaryInProcess = true;
		this.rs.getCampaignSummary(this.currentCampaign._id).then((response) => {
			this.campaignSummary = response;

			this.prepareGeneralEventsGraph();
			this.prepareLinkEventsGraph();
			this.prepareAcknowledgeEventsGraph();
			this.prepareAttachmentsEventsGraph();
			this.prepareRecipientsTable();
			this.getSummaryInProcess = false;
		}, (err) => {
			this.getSummaryInProcess = false;
		});
	}

	getCampaignGroupSummary = (campaignIDs) => {
		this.getSummaryInProcess = true;

		const queryParams = {arr: campaignIDs.join(',')};

		this.rs.getCampaignGroupSummary(queryParams).then((response) => {
			this.campaignSummary = response;

			this.prepareGeneralEventsGraph();
			this.prepareLinkEventsGraph();
			this.prepareAcknowledgeEventsGraph();
			this.prepareAttachmentsEventsGraph();
			this.prepareRecipientsTable();
			this.getSummaryInProcess = false;
		}, (err) => {
			this.getSummaryInProcess = false;
		});
	}

	prepareRecipientsTable() {
		this.recipientsEventsTable = [];
		this.campaignSummary.recipients?.forEach((recipientObj) => {
			const recipientInfo: any = {
				email: recipientObj.email,
				sent: recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventSent.name),
				sentFailed: recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventSendFailed.name || itm.name === this.dic.EVENTS.eventHardBounceGeneral.name || itm.name === this.dic.EVENTS.eventSoftBounce.name),
				read: recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventRead.name),
				acknowledge: recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventAcknowledge.name),
				attachment: recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventDownloaded.name),
				link: recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventLinkClicked.name),
				userReported: recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventTsReport.name),
			};
			if (this.isCampaignType(this.currentCampaign, this.dic.CONSTANTS.threatSimulationType.materials.value)) {
				recipientInfo.status = recipientInfo.acknowledge ? 'Pass' : 'N/A';
			}
			else {
				recipientInfo.status = recipientInfo.sent ? (recipientInfo.link || recipientInfo.attachment) ? 'Fail' : 'Pass' : 'N/A';
			}

			this.recipientsEventsTable.push(recipientInfo);
		});
	}

	prepareGeneralEventsGraph() {

		const sentData = {label: 'Sent', value: 0, color: '#02b90b'};
		const readData = {label: 'Read', value: 0, color: '#3984e5'};
		const userReportedData = {label: 'User Reported', value: 0, color: '#e5bd39'};
		const linkClickData = {label: 'Link Clicked', value: 0, color: '#f50909'};
		const attachmentDownload = {label: 'Attachment Downloaded', value: 0, color: '#e76363'};
		const acknowledgeData = {label: 'Acknowledge', value: 0, color: '#2a2929'};
		const phishedLoginData = {label: 'Phished Login', value: 0, color: '#ff00de'};

		this.campaignSummary.recipients?.forEach((recipientObj) => {
			const recipientTracking = {sent: 0, read: 0, userReported: 0, link: 0, attachment: 0, acknowledge: 0, login: 0};
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventSent.name)) {
				recipientTracking.sent = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventRead.name)) {
				recipientTracking.read = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventTsReport.name)) {
				recipientTracking.userReported = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventLinkClicked.name)) {
				recipientTracking.link = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventDownloaded.name)) {
				recipientTracking.attachment = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventTsLogin.name)) {
				recipientTracking.login = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventAcknowledge.name)) {
				recipientTracking.acknowledge = 1;
			}

			sentData.value += recipientTracking.sent;
			readData.value += recipientTracking.read;
			userReportedData.value += recipientTracking.userReported;
			linkClickData.value += recipientTracking.link;
			attachmentDownload.value += recipientTracking.attachment;
			acknowledgeData.value += recipientTracking.acknowledge;
			phishedLoginData.value += recipientTracking.login;
		});

		let graphData = [sentData, readData, acknowledgeData];
		if (this.isCampaignType(this.currentCampaign, this.dic.CONSTANTS.threatSimulationType.materials.value)) {
			if (!graphData.find(itm => itm.label === linkClickData.label)) {
				graphData.push(linkClickData);
			}
		}
		if (this.isCampaignType(this.currentCampaign, this.dic.CONSTANTS.threatSimulationType.login.value)) {
			if (!graphData.find(itm => itm.label === phishedLoginData.label)) {
				graphData.push(phishedLoginData);
			}
			if (!graphData.find(itm => itm.label === userReportedData.label)) {
				graphData.push(userReportedData);
			}
		}
		if (this.isCampaignType(this.currentCampaign, this.dic.CONSTANTS.threatSimulationType.attachments.value)) {
			if (!graphData.find(itm => itm.label === attachmentDownload.label)) {
				graphData.push(attachmentDownload);
			}
			if (!graphData.find(itm => itm.label === userReportedData.label)) {
				graphData.push(userReportedData);
			}
		}
		if (this.isCampaignType(this.currentCampaign, this.dic.CONSTANTS.threatSimulationType.training.value)) {
			if (!graphData.find(itm => itm.label === linkClickData.label)) {
				graphData.push(linkClickData);
			}
			if (!graphData.find(itm => itm.label === userReportedData.label)) {
				graphData.push(userReportedData);
			}
		}

		graphData.sort((a, b) => b.value - a.value);

		const labels = graphData.map(item => item.label);
		const colors = graphData.map(item => item.color);
		const series = graphData.map(item => item.value);

		const maxValue = series[0];
		const gSeries = series.map((n) => (n / maxValue) * 100.0);

		this.generalEventsChartOptions = {
			maxValue: maxValue,
			series: gSeries,
			chart: {
				width: '100%',
				type: 'radialBar',
				animations: {
					enabled: true,
				},
			},
			plotOptions: {
				radialBar: {
					endAngle: 270,
					hollow: {
						size: '30%',
						background: 'transparent',
					},
					dataLabels: {
						name: {
							show: true,
							fontSize: '11px',
						},
						value: {
							show: true,
							offsetY: 3,
							fontSize: '12px',
							formatter: (seriesValue, opts) => {
								seriesValue = Number(seriesValue);
								for (let i = 0; i < gSeries.length; i++) {
									if (gSeries[i] === seriesValue) {
										seriesValue = series[i];
										break;
									}
								}
								return seriesValue;
							},
						}
					}
				}
			},
			colors: colors,
			labels: labels,
			legend: {
				show: true,
				floating: true,
				fontSize: '12px',
				position: 'left',
				offsetX: 0,
				offsetY: 0,
				labels: {
					useSeriesColors: true,
				},
				markers: {
					size: 8
				},
				formatter: (seriesName, opts) => {
					return `${seriesName}: ${series[opts.seriesIndex]}`;
				},
				onItemHover: {
					highlightDataSeries: false
				},
				itemMargin: {
					vertical: 3
				}
			}
		};
	}

	prepareLinkEventsGraph() {
		let sentEvents = 0;
		let linkClick = 0;
		this.campaignSummary.recipients?.forEach((recipientObj) => {
			const recipientTracking = {sent: 0, link: 0};
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventSent.name)) {
				recipientTracking.sent = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventLinkClicked.name)) {
				recipientTracking.link = 1;
			}

			sentEvents += recipientTracking.sent;
			linkClick += recipientTracking.link;
		});

		this.linksEventsChartOptions = {
			sentEvents,
			series: [linkClick, sentEvents - linkClick],
			chart: {
				width: '100%',
				type: "pie"
			},
			labels: ["Link Clicked", "Link not Clicked"],
			colors: ['#f50909', '#02b90b'],
		};
	}

	prepareAcknowledgeEventsGraph() {
		let sentEvents = 0;
		let acknowledgeEvent = 0;
		this.campaignSummary.recipients?.forEach((recipientObj) => {
			const recipientTracking = {sent: 0, acknowledge: 0};
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventSent.name)) {
				recipientTracking.sent = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventAcknowledge.name)) {
				recipientTracking.acknowledge = 1;
			}

			sentEvents += recipientTracking.sent;
			acknowledgeEvent += recipientTracking.acknowledge;
		});

		this.acknowledgeEventsChartOptions = {
			sentEvents,
			series: [acknowledgeEvent, sentEvents - acknowledgeEvent],
			chart: {
				width: '100%',
				type: "pie"
			},
			labels: ["Acknowledge", "Not Acknowledge"],
			colors: ['#02b90b', '#f50909'],
		};
	}

	prepareAttachmentsEventsGraph() {
		let sentEvents = 0;
		let attachmentDownload = 0;
		this.campaignSummary.recipients?.forEach((recipientObj) => {
			const recipientTracking = {sent: 0, attachment: 0};
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventSent.name)) {
				recipientTracking.sent = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventDownloaded.name)) {
				recipientTracking.attachment = 1;
			}

			sentEvents += recipientTracking.sent;
			attachmentDownload += recipientTracking.attachment;
		});

		this.attachmentEventsChartOptions = {
			sentEvents,
			series: [attachmentDownload, sentEvents - attachmentDownload],
			chart: {
				width: '100%',
				type: "pie"
			},
			labels: ["Attachment Downloaded", "Attachment not Downloaded"],
			colors: ['#f50909', '#02b90b'],
		}
	}

	isCampaignType = (campaignObj, type) => {
		if (campaignObj.ts_type === type) {
			return true;
		}

		if (campaignObj.campaigns?.length) {
			for (let i = 0; i < campaignObj.campaigns.length; i++) {
				if (campaignObj.campaigns[i].ts_type === type) {
					return true;
				}
			}
		}

		return false;
	}

	searchRecipient = (event) => {
		this.recipientsEventsTable.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = searchTextExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	};

	openLocation = (ll) => {
		this.gs.openLocation(ll);
	};

	exportRecipientEventsToCsv = (sortBy) => {
		if (!this.recipientsEventsTable.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Email,Sent,Read,User Reported,Link Clicked,Attachment Downloaded,Acknowledge,Status\n";

		let sortedTable = _.filter(this.recipientsEventsTable,incident => {return !incident.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((recipientObj) => {
			let linkEvent = '';
			if (recipientObj.link) {
				if (recipientObj.link.agent?.browser) {
					linkEvent = `${recipientObj.link.agent.browser.name}, ${recipientObj.link.agent.os.name} ${recipientObj.link.agent.os.version}`;
				}
				if (recipientObj.link.ip) {
					linkEvent += `, ${recipientObj.link.ip}`;
				}
			}
			else {
				linkEvent = 'False';
			}

			let attachmentEvent = '';
			if (recipientObj.attachment) {
				if (recipientObj.attachment.agent?.browser) {
					attachmentEvent = `${recipientObj.attachment.agent.browser.name}, ${recipientObj.attachment.agent.os.name} ${recipientObj.attachment.agent.os.version}`;
				}
				if (recipientObj.attachment.ip) {
					attachmentEvent += `, ${recipientObj.attachment.ip}`;
				}
			}
			else {
				attachmentEvent = 'False';
			}

			let sentCreated = 'Not sent';
			if (recipientObj.sent?.created) {
				const created = new Date(recipientObj.sent.created);
				sentCreated = `Sent: ${created.toLocaleDateString()} ${created.toLocaleTimeString('en-US', {
					hour: 'numeric',
					minute: 'numeric',
					hour12: false
				})}`;
			}

			let readCreated = 'Not read';
			if (recipientObj.read?.created) {
				const created = new Date(recipientObj.read.created);
				readCreated = `Read: ${created.toLocaleDateString()} ${created.toLocaleTimeString('en-US', {
					hour: 'numeric',
					minute: 'numeric',
					hour12: false
				})}`;
			}
			csvString += `${recipientObj.email},"${sentCreated}","${readCreated}","${recipientObj.userReported ? 'True' : 'False'}","${linkEvent}","${attachmentEvent}","${recipientObj.acknowledge ? 'True' : 'False'}", ${recipientObj.status}\n`;
		});

		this.gs.exportCsv(csvString, `recipients_events.csv`);
	};

	exportChartsToPdf =  () => {
		if (!this.campaignSummary) {
			return;
		}
		this.generatePdfInProcess = true;

		// set the relevant elements' visual properties to be ideal for the PDF page before copying the HTML
		let areaForPdf:any = document.getElementById('trendsChartsContainer');
		areaForPdf.classList.add('trends-pdf-layout');

		// timeout is needed to let the css enough time to update on screen and for the responsive charts to resize
		setTimeout(() => {
			const element = document.getElementById('trendsChartsContainer');
			const opt = {
				margin:       40,
				filename:     `${this.currentCampaign.name.replace(' ', '_')}_campaign_summary.pdf`,
				image:        { type: 'jpeg', quality: 1 },
				html2canvas:  { scale: 2 , width: 1300, windowWidth: 1550},
				jsPDF:        { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling']}
			};

			// New Promise-based usage:
			html2pdf().set(opt).from(element).then(() => {
				setTimeout(() => {
					this.exitFromPdfGeneration();
				})
			}).save();
		},800);
	}

	exitFromPdfGeneration = () => {
		this.ns.showInfoMessage(util.format(this.dic.MESSAGES.downloadSuccess, 'Trends and insights data'));

		document.getElementById('trendsChartsContainer').classList.remove('trends-pdf-layout');
		this.generatePdfInProcess = false;
		// rerender charts so they'll fit their container's size
		this.getSummaryInProcess = true;

		setTimeout(() => {
			this.getSummaryInProcess = false;
		});
	}
}

function searchTextExecute(recipientObj, searchTerm) {
	return (recipientObj.email.toLowerCase().indexOf(searchTerm) > -1);
}
