import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from "@angular/core";
import _ from 'lodash';
import ForceGraph from "force-graph";
import * as d3 from 'd3-force';
import {GeneralService} from "../../../services/generalService";

@Component({
	selector: 'connection-graph',
	template: '<div class="f-1-0 w100" style="overflow: hidden;" #graphEl></div>',
})
export class ConnectionGraphComponent implements OnChanges, AfterViewInit{

	@Input() rawGraphData: rawDataType;
	// array of nodes (by names) to focus on | null = no search. [] = no results.
	@Input() focusedNodesNames = null;
	@Input() nodeOptionsFn;
	@ViewChild('graphEl') graphEl: ElementRef;

	constructor(private gs:GeneralService) {
	}

	graphData;
	hoveredNode;
	graph;

	ngAfterViewInit() {
		const containerEl = this.graphEl.nativeElement;

		if (containerEl) { // sanity
			this.gs.observeResizeWithDebounce(containerEl, (test) => {
				this.graph.width(containerEl.clientWidth).height(containerEl.clientHeight).zoomToFit(50);
			}, 400);
		}
	}

	// rerender on init and on every change in graph data
	ngOnChanges(changes: SimpleChanges) {
		if (changes.rawGraphData && this.rawGraphData) {
			this.graphData = this.prepareGraphData(_.cloneDeep(this.rawGraphData));
			setTimeout(() => {
				this.drawDataToGraph();
			});
		}
	}

	prepareGraphData = (rawData:rawDataType) => {
		const modifiedData = {nodes: [], links: rawData.links};

		// add nodes from 'rawData.nodes' and 'rawData.links' and define nodes
		modifiedData.nodes = _.union(rawData.nodes, _.flatMapDeep(rawData.links, link => _.values(link)));

		const sources = _.map(modifiedData.links, 'source');
		modifiedData.nodes = _.map(modifiedData.nodes, nodeStr => {
			const nodeObj = {
				id: nodeStr,
				name: nodeStr,
				source: sources.includes(nodeStr),
				connectedNodesNames: _.union(_.flatMapDeep(rawData.links, link => _.values(link).includes(nodeStr) ? _.values(link) : []))
			}
			if (this.nodeOptionsFn) {
				this.nodeOptionsFn(nodeObj, modifiedData.links);
			}

			return nodeObj;
		});

		// remove duplicates
		modifiedData.links = _.uniqWith(modifiedData.links, _.isEqual);

		return modifiedData;
	}

	drawDataToGraph = () => {
		const Graph = ForceGraph();
		const containerEl = this.graphEl?.nativeElement;

		if (!containerEl) {
			return;
		}

		const height = containerEl.clientHeight;
		const width = containerEl.clientWidth;

		if (!height || !width) {
			return;
		}

		this.graph = Graph(containerEl)
			.cooldownTicks(50)
			.graphData(this.graphData)
			.width(width - 20)
			.height(height - 20)
			.backgroundColor('#f9f9f9')
			.nodeColor((n:any) => n.color || '')
			.nodeRelSize(40)
			.nodeLabel((n:any) => n.connectedNodesNames.length > 1 && !n.hideTooltip ? (n.connectedNodesNames.length - 1).toString() : null)
			.nodeId('id')
			.nodeCanvasObject(this.drawNodeText)
			.linkCanvasObject(this.drawLinks)
			.d3Force('charge', (d3.forceManyBody() as any).strength(-1500)) // reduce attraction force between linked nodes
			.d3Force('link', (d3.forceLink() as any).distance(250)) // increase link lines length

		this.graph.onNodeHover(node => {
			this.hoveredNode = node || null; // (Resets hoveredNode on mouse out)
		});

		// one time zoom-to-fit on start
		this.graph.onEngineStop(() => {
			Graph.zoomToFit(200);
			this.graph.onEngineStop(() => null);
		});
	}

	drawNodeText = (node, ctx, globalScale) => {
		let nodeAlpha = this.hoveredNode || this.focusedNodesNames ? 0.1 : 1;
		if (this.hoveredNode && (node === this.hoveredNode || this.hoveredNode.connectedNodesNames.includes(node.id))) {
			nodeAlpha = 1;
		}
		else if (this.focusedNodesNames?.includes(node.name)) {
			nodeAlpha = 1;
		}

		ctx.globalAlpha = nodeAlpha;

		ctx.font = `${14/globalScale}px Sans-Serif`;
		ctx.beginPath();

		// rectangle outline
		const textWidth = ctx.measureText(node.name).width;
		const textheight = 20/globalScale;
		const padding = {x: 6/globalScale, y: 4/globalScale}
		ctx.strokeStyle = node.color || 'darkblue';
		ctx.lineWidth = node.lineWidth ? node.lineWidth/globalScale : 1/globalScale;
		ctx.fillStyle = 'rgba(255,255,255,0.7)';
		ctx.rect(node.x - textWidth/2 - padding.x, node.y - textheight/2 - padding.y, textWidth + 2*padding.x, textheight + 2*padding.y); // place, center and pad
		ctx.fill();
		ctx.stroke();

		// text
		ctx.fillStyle = node.color || 'black';
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText(node.name, node.x, node.y);

		ctx.globalAlpha = 1; // reset alpha
	}

	drawLinks = (link, ctx, globalScale) => {
		const isConnectedToHoveredNode = this.hoveredNode && (link.source === this.hoveredNode || link.target === this.hoveredNode);

		ctx.globalAlpha = (this.hoveredNode || this.focusedNodesNames) && !isConnectedToHoveredNode ? 0.1 : 1;

		ctx.strokeStyle = 'rgba(0,0,0,0.4)';
		ctx.lineWidth = isConnectedToHoveredNode ? 2.5/globalScale : 1/globalScale;
		ctx.beginPath();
		ctx.moveTo(link.source.x, link.source.y);
		ctx.lineTo(link.target.x, link.target.y);
		ctx.stroke();

		ctx.globalAlpha = 1; // Reset opacity after each link
	}
}

type rawDataType = {
	links?: {source: string, target: string}[],
	nodes?: string[]
};
