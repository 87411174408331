import {Component, OnInit} from "@angular/core";
import {RouteService} from "../../../../../services/routeService";
import {GeneralService} from "../../../../../services/generalService";
import {NotificationService} from "../../../../../services/notificationService";
import {DICTIONARY} from "../../../../../dictionary";
import _ from "lodash";
import util from "util";
import {eachLimit} from 'async';

@Component({
	selector: 'outbound-personal-types',
	templateUrl: './outbound-personal-types.component.html',
})

export class OutboundPersonalTypesComponent implements OnInit {

	constructor(private rs:RouteService,
				private gs:GeneralService,
				private ns:NotificationService) {
	}

	dic = DICTIONARY;
	personalTypes  = [];
	addTypeInProcess;
	getSensitivityTypesInProcess;
	_ = _;

	ngOnInit() {
		this.getSensitivityTypesInProcess = true;

		this.rs.getSensitivityTypes().then((response) => {
			this.personalTypes = _.flatMap(response.plan.personal_sensitivity_types, type => {return [type, ...(type.sub_detector || [])]});
			this.findTypesInUse(response.plan?.rules || []);

			this.getSensitivityTypesInProcess = false;
		}, (err) => {
			this.getSensitivityTypesInProcess = false;
		});

	}

	startAddType = () => {
		if (this.addTypeInProcess) {
			return;
		}
		this.addTypeInProcess = true;

		const newType = {
			isEditMode: true,
			isNew: true,

			edit: {
				name: '',
				regex: '',
				sensitivity: 1,
			}

		};

		this.personalTypes.unshift(newType);
	};

	confirmEditType = (sensitivityType ,isApproved) => {
		// process was declined
		if (!isApproved) {
			if (!sensitivityType.isNew) {
				sensitivityType.isEditMode = false;
			} else {
				_.remove<any>(this.personalTypes, sensitivityType);
				this.addTypeInProcess = false;
			}

			sensitivityType.edit = null;
			return;
		}
		//

		// process approved
		if (!sensitivityType.edit.name) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Sensitivity Type name field'));
		}
		if (!sensitivityType.edit.regex) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Regex field'));
		}

		const match = sensitivityType.edit.regex.match(this.dic.CONSTANTS.PATTERN_REGEX);
		if (match && match.length >= 2) {
			sensitivityType.regex = match[2];
			sensitivityType.regexFlags = match[3] || '';
		}
		sensitivityType.sensitivity = sensitivityType.edit.sensitivity;


		if (!sensitivityType.isNew) {
			this.updateSensitivityTypeExecute(sensitivityType);
		}
		else {
			this.addSensitivityTypeExecute(sensitivityType);
		}

		this.addTypeInProcess = false;
	};

	updateSensitivityTypeExecute = (edittedType) => {
		const edittedTypeData = {
			...edittedType,
			...edittedType.edit
		}
		edittedType.confirmEditInProcess = true;
		this.rs.updateSensitivityType(edittedTypeData).then(res => {
			this.ns.showInfoMessage(`Sensitivity type ${edittedType.name} updated successfully`);
			Object.assign(edittedType, edittedTypeData);
			edittedType.isNew = undefined;
			edittedType.confirmEditInProcess = false;
			edittedType.isEditMode = false;
			edittedType.edit = null;

			}, err => {
			if (err && err.data && err.data.message) {
				edittedType.confirmEditInProcess = false;
				this.ns.showErrorMessage(err.data.message);
			}
		});
	};

	addSensitivityTypeExecute = (newType) => {
		newType.confirmEditInProcess = true;
		this.rs.addSensitivityType(newType.edit).then(res => {
			this.ns.showInfoMessage(`Sensitivity type ${newType.edit.name} added successfully`);
			Object.assign(newType, newType.edit);
			newType.confirmEditInProcess = false;
			newType.isEditMode = false;
			newType.isNew = undefined;

			this.addTypeInProcess = false;
		}, err => {
			if (err && err.data && err.data.message) {
				this.ns.showErrorMessage(err.data.message);
			}
			newType.confirmEditInProcess = false;
			this.addTypeInProcess = false;
		});
	};

	startEditType = (typeObj) => {
		typeObj.edit = {
			name: typeObj.name,
			regex: typeObj.regex,
			sensitivity: typeObj.sensitivity,
		}
		typeObj.isEditMode = true;
	};

	selectMultipleSensitivityTypeAction = (selectedItems, action) => {
		switch (action) {
			case _.capitalize(this.dic.CONSTANTS.actions.delete):
				this.deletePersonalType(selectedItems);
				break;
		}
	};

	showSensitivityTypeBulkActions = () => {
		return [
			_.capitalize(this.dic.CONSTANTS.actions.delete)
		]
	}

	selectSensitivityTypeActions = (typeObj, action) => {
		switch (action) {
			case _.capitalize(this.dic.CONSTANTS.actions.edit):
				this.startEditType(typeObj);
				break;

			case _.capitalize(this.dic.CONSTANTS.actions.delete):
				this.deletePersonalType([typeObj]);
				break;
		};
	};

	showSensitivityTypeActions = (sensitivityTypeObj) => {
		const actions = [
			_.capitalize(this.dic.CONSTANTS.actions.edit),
			_.capitalize(this.dic.CONSTANTS.actions.delete)
		];

		return actions;
	};

	searchType = (searchTerm, activeFilters) => {
		this.personalTypes.forEach(type => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(type, searchTerm);
				if (!isFound) {
					type.hide = true;
					return;
				}
			}

			type.hide = false;
		});
	};

	findTypesInUse(rules) {
		for (let i = 0; i < rules.length; i++) {
			if (rules[i].conditions?.length) {
				// make all conditions arrays of conditions
				rules[i].conditions = _.map(rules[i].conditions, condition => {
					if (condition.or_conditions?.length) {
						return condition.or_conditions;
					}
					else {
						return [condition];
					}
				});

				rules[i].conditions.forEach(conditionArr => {
					conditionArr.forEach(cond =>{
						if (cond.type === this.dic.CONSTANTS.rules.types.sensitivity_type.name) {
							cond.data.sensitivity_types.forEach(type => {
								const typeInList = _.find<any>(this.personalTypes, t => t.name === type && t.type === 'Custom');
								if (typeInList) {
									typeInList.isCurrentlyUsedInRule = true;
								}
							});
						}
					});
				});
			}

			if (rules[i].exceptions?.length) {
				// make all conditions arrays of conditions
				rules[i].exceptions = _.map(rules[i].exceptions, condition => {
					if (condition.or_conditions?.length) {
						return condition.or_conditions;
					}
					else {
						return [condition];
					}
				});

				rules[i].exceptions.forEach(conditionArr => {
					conditionArr.forEach(cond =>{
						if (cond.type === this.dic.CONSTANTS.rules.types.sensitivity_type.name) {
							cond.data.sensitivity_types.forEach(type => {
								const typeInList = _.find<any>(this.personalTypes, t => t.name === type && t.type === 'Custom');
								if (typeInList) {
									typeInList.isCurrentlyUsedInRule = true;
								}
							});
						}
					});
				});
			}
		}
	};


	deletePersonalType = (typeArr) => {
		if (!typeArr || !typeArr.length) return;

		// Partition the types into those in use and those not in use
		const [typesInUse, typesNotInUse] = _.partition(typeArr, type => type.isCurrentlyUsedInRule);

		// If any type is in use, show an error message
		if (typesInUse.length) {
			this.ns.showErrorMessage(this.dic.ERRORS.cannotDeleteCustomTypeInUse);
		}
		if (!typesNotInUse.length) return;
		typeArr = typesNotInUse;

		this.gs.showPopup({
			title: `Delete Sensitivity Type${typeArr.length > 1 ? 's' : ''}`,
			subTitle: `Please note - the selected type${typeArr.length > 1 ? 's' : ''} will be deleted.`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				eachLimit(typeArr, 1, (typeObj, callback) => {
					this.rs.deleteSensitivityType(typeObj.name).then(res => {
						this.ns.showInfoMessage(`Sensitivity type ${typeObj.name} deleted successfully`);
						_.remove(this.personalTypes, typeObj);

						this.addTypeInProcess = false;
						callback();
					}, err => {
						if (err && err.data && err.data.message) {
							this.ns.showErrorMessage(err.data.message);
						}
						this.addTypeInProcess = false;
					});
				});
			}
		});
	};

	exportToCsv = (sortBy) => {
		if (!this.personalTypes?.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Name,Regex,Score\n";

		let sortedTable = _.filter(this.personalTypes,incident => {return !incident.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((record) => {
			csvString += `"${record.name}","${record.regex}",${record.sensitivity}\n`;
		});

		this.gs.exportCsv(csvString, `personal_sensitivity_types.csv`);
	}
}

function searchTextExecute(sensObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();

	return ((sensObj.name?.toLowerCase().indexOf(searchTerm) > -1) ||
		(sensObj.type?.toLowerCase().indexOf(searchTerm) > -1) ||
		(sensObj.type?.toLowerCase().indexOf(searchTerm) > -1) ||
		(sensObj.compliance?.includes(searchTerm.toUpperCase())));
}
