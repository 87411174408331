import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component} from "@angular/core";
import {ArchiveService} from "../../../services/archiveService";

@Component({
	selector: 'archive-cases-operations-component',
	templateUrl: './archive-cases-operations.component.html',
})
export class ArchiveCasesOperationsComponent{
    constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService,
				public archiveService:ArchiveService){
    }

    dic = DICTIONARY;
    hasWKTextSecurity = this.gs.hasWKTextSecurity();
    addReviewerInProcess;

	rangeMinString = new Date().toString();

	logsPopup;


    startAddReviewer = () => {
        if (this.addReviewerInProcess) {
            return;
        }

        this.addReviewerInProcess = true;

        const newReviewer = {
            isEditMode: true,
            isNew: true,
            edit: {
                email: '',
            }
        };

        this.archiveService.currentCaseObj.edit.sharing.reviewers.unshift(newReviewer);
    };

    confirmAddReviewer = (reviewer ,isApproved) => {
        // new reviewer process was declined
        if (!isApproved) {
            _.remove<any>(this.archiveService.currentCaseObj.edit.sharing.reviewers, reviewer);
            this.addReviewerInProcess = false;
            return;
        }
        //

        // new reviewer approved
        if (!reviewer.edit.email) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Reviewer email field'));
        }
        if (!this.gs.validateEmail(reviewer.edit.email)) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.invalidEmail, reviewer.edit.email));
        }
        if (_.find(this.archiveService.currentCaseObj.edit.sharing.reviewers, rev => {return !rev.isNew && rev.email && rev.email === reviewer.edit.email})) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailAlreadyExist, reviewer.edit.email));
        }

        reviewer.email = reviewer.edit.email.toLowerCase();
        reviewer.new = true; // to differ from existing reviewers
        reviewer.noActionsToShow = true;
        reviewer.hideActions = true;

        reviewer.edit = null;
        reviewer.isEditMode = false;
        reviewer.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit
        this.addReviewerInProcess = false;
    };

    showSharingManagementExecute = () => {
        const sharingUpdateInfo = {
            action: 'sharing',
            expiration: this.archiveService.currentCaseObj.edit.sharing.expiration,
            method_2factor: this.archiveService.currentCaseObj.edit.sharing.method_2factor,
            method_2factor_password: this.archiveService.currentCaseObj.edit.sharing.method_2factor_password,
            method_2factor_password_hint: this.archiveService.currentCaseObj.edit.sharing.method_2factor_password_hint,

            reviewers: []
        };

        const newReviewers = this.archiveService.currentCaseObj.edit.sharing.reviewers.filter(itm => itm.new);
        if (newReviewers && newReviewers.length) {
            sharingUpdateInfo.reviewers = [];
            sharingUpdateInfo.reviewers = newReviewers.map(itm => itm.email);
        }

        this.rs.updateArchiveCase(this.archiveService.currentCaseObj._id, sharingUpdateInfo).then(res => {
            this.archiveService.currentCaseObj.sharing = res.caseInfo.sharing;
            this.archiveService.currentCaseObj.sharing.is_sharing_expired =
                this.archiveService.currentCaseObj.sharing.expiration && Date.now() > Date.parse(this.archiveService.currentCaseObj.sharing.expiration);
            this.ns.showInfoMessage("Case was updated successfully");
            this.archiveService.showSharingConfiguration = false;
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
        });
    }

    selectReviewerAction = (reviewerObj, action) => {
        switch (action) {
            case this.dic.CONSTANTS.archiveReviewerActions.block:
            case this.dic.CONSTANTS.archiveReviewerActions.unblock:
                this.changeReviewerStatus(this.archiveService.currentCaseObj, reviewerObj, action);
                break;

            case this.dic.CONSTANTS.archiveReviewerActions.resend:
                this.resendEmailToReviewer(this.archiveService.currentCaseObj, reviewerObj);
                break;

            case this.dic.CONSTANTS.archiveReviewerActions.showLogs:
                this.showLogsForCase(this.archiveService.currentCaseObj, reviewerObj);
                break;
        }
    }

    showLogsForCase = (caseObj, reviewerObj) => {
        if (!reviewerObj && !caseObj.sharing.reviewers.length) {
            this.ns.showErrorMessage("No reviewers");
            return
        }
        this.logsPopup = {
            selectedReviewer: reviewerObj || (caseObj.sharing.reviewers[0]),
            show: true
        };
    }

    selectReviewer = (reviewerObj) => {
        this.logsPopup.selectedReviewer = reviewerObj;
    }

    changeReviewerStatus = (caseObj, reviewerObj, action) => {
        const reviewerUpdateInfo = {
            reviewerId: reviewerObj._id.toString(),
            action: 'reviewerStatus',
            blocked: action === "Block"
        };

        this.rs.updateArchiveCase(this.archiveService.currentCaseObj._id, reviewerUpdateInfo).then(res => {
            reviewerObj.is_blocked = reviewerUpdateInfo.blocked;
            this.ns.showInfoMessage(`Reviewer was ${reviewerUpdateInfo.blocked ? 'blocked' : 'unblocked'} successfully`);
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
        });
    }

    resendEmailToReviewer = (caseObj, reviewerObj) => {
        const reviewerUpdateInfo = {
            reviewerId: reviewerObj._id.toString(),
            action: 'resendReviewer'
        };

        this.rs.updateArchiveCase(this.archiveService.currentCaseObj._id, reviewerUpdateInfo).then(res => {
            this.ns.showInfoMessage("Case was successfully resent to the reviewer");
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
        });
    }

    showReviewerActions = (reviewerObj) => {
        if (!reviewerObj._id) { // just-created reviewer (did not receive an invitation yet...)
            return [];
        }

        const actions = [
            this.dic.CONSTANTS.archiveReviewerActions.showLogs,
            this.dic.CONSTANTS.archiveReviewerActions.resend
        ];

        if (reviewerObj.is_blocked) {
            actions.push(this.dic.CONSTANTS.archiveReviewerActions.unblock);
        }
        else {
            actions.push(this.dic.CONSTANTS.archiveReviewerActions.block);
        }

        return actions;
    }

    searchReviewers = (searchTerm, activeFilters) => {
        this.archiveService.currentCaseObj.edit.sharing.reviewers.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            // // currently no filters for cases table


            record.hide = false;
        });
    };


}
function searchTextExecute(sharedReviewerObj, searchTerm) {
    return (sharedReviewerObj.email && sharedReviewerObj.email.toLowerCase().indexOf(searchTerm) > -1);
}

