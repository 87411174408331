<div class="flxClmn">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">New Secure Email</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Compose and send secure emails with encryption, MFA, and advanced security features</span>
        </div>
    </div>
    <div class="page-inner-content-holder no-scroll flxClmn bs-enabled compose-message-container" style="min-height: 615px;">
		<div class="flex-row gap-3 bg-light p-3 f-1-0 overflow-auto"
			 style="background: linear-gradient(90deg, #f0f5ff, #d4ddf1 58%, #ffffffed), url('images/popup-header-bg.png'); background-size: contain; background-repeat: round;">

			<!--email editor section (LEFT side)-->
			<div class="flex-column gap-3 bg-white rounded p-4 f-1-0 overflow-auto" style="box-shadow: 0 0 10px #cccccc;  min-width: 580px; overflow-x: hidden!important;"
				 ngfDrop
				 [(files)]="attachmentFiles"
				 (fileChange)="uploadAttachment()"
				 [(validDrag)]="validDrag"
				 [(invalidDrag)]="invalidDrag"
				 multiple="1"
				 [ngClass]="{'dragOverClassObj': validDrag || invalidDrag}">

				<div class="flex-column gap-2">
					<!--From-->
					<div class="flex-row align-items-center gap-3">
						<span class="f-1-0" style="font-size: 15px; max-width: 75px;">From:</span>
						<app-dropdown-c class="f-1-0 limit-height" style="height: 35px;"
										[text]="selectedFromAddress?.email || 'Loading...'">
							<li *ngFor="let item of fromAddresses" [id]="item.email"
								(click)="selectedFromAddress = item;">
								{{item.email}}
							</li>
							<li (click)="openNewAddressConfirmationPopup();">
								<div class="flex-row gap-1 align-items-center">
									<i class="fa fa-plus"></i>
									<span>Add new address</span>
								</div>
							</li>
						</app-dropdown-c>
					</div>

					<!--/ To / Cc / Bcc-->
					<ng-container *ngFor="let recipientScope of ['to', 'cc', 'bcc']; trackBy: gs.trackByIndex; let index = index;">
						<div class="flex-row align-items-center gap-3" *ngIf="recipientScope === 'to' || isCCOpened">
							<span class="f-1-0" style="font-size: 15px; max-width: 75px;">{{_.capitalize(recipientScope)}}:</span>
							<div class="flex-row align-items-center f-1-0" style="border: solid 1px; border-radius: 5px; min-height: 35px;"
								 [ngStyle]="{'border-color' : document.activeElement === recipientInputEl ? 'var(--bs-bold-color)' : '#cccccc'}">
								<div class="flex-row align-items-center gap-2 flex-wrap f-1-0 position-relative justify-content-start px-2">
									<!--contact mini card-->
									<div class="flex-row align-items-center rounded gap-2 cursor-pointer position-relative compose-contact-container"
										 style="background: hsl(var(--bs-primary-h), var(--bs-primary-s), max(var(--bs-primary-l), 95%));; padding: 5px 10px; border: solid 1px hsla(var(--bs-primary-h), var(--bs-primary-s), var(--bs-primary-l), 0.3);"
										 *ngFor="let contact of message[recipientScope].contacts; let index = index; trackBy: gs.trackByIndex;"
										 [tooltip]="contact.type === dic.CONSTANTS.listType.mass ? 'Mass list' : null"
										 (clickOutside)="contact.edit = null;"
										 (click)="$event.stopPropagation(); openEditContactModal(contact);">
										<span class="fw-500">{{contact.name || contact.email}}</span>
										<div></div> <!--padder-->
										<i class="fa fa-edit hvr-opacity" *ngIf="contact.type !== dic.CONSTANTS.listType.mass"></i>
										<i class="fa fa-trash-alt closeRed"
										   (click)="$event.stopPropagation(); removeFromRecipients([contact], recipientScope);"></i>

										<!--edit contact modal-->
										<div class="drop-menu rounded mt-1 p-0 cursor-default" [ngClass]="{'active' : !!contact.edit}" style="width: 450px;"
											 (click)="$event.stopPropagation()">
											<div class="flex-column gap-2 p-3" style="font-size: 12px;" *ngIf="contact.edit">
												<i class="fa fa-user-circle opacity-25" style="font-size: 2rem; position: absolute; top: 10px; right: 15px;"></i>

												<div class="flex-row align-items-center gap-2" style="max-width: 83%;">
													<span style="flex-basis: 80px;">Name:</span>
													<input type="text" placeholder="Name"
														   style="flex: 1 0; height: 25px;"
														   id="editContactNameInput"
														   [autofocus]="true"
														   [(ngModel)]="contact.edit.name">
												</div>
												<div class="flex-row align-items-center gap-2" style="max-width: 83%;">
													<span style="flex-basis: 80px;">Email:</span>
													<input type="text" placeholder="Email"
														   style="flex: 1 0; height: 25px;"
														   id="editContactEmailInput"
														   [(ngModel)]="contact.edit.email"
														   [ngClass]="{'validation-error' : contact.edit.emailError}"
														   (keyup)="contact.edit.emailError = false;">
												</div>
												<div class="flex-row align-items-center gap-2" style="max-width: 83%;">
													<span style="flex-basis: 80px;">Phone:</span>
													<div class="flex-row f-1-0 gap-1">
														<country-code-select class="compose-country-code" [(model)]="contact.edit.phone.country_code"></country-code-select>
														<input type="text" style="height: 25px; flex: 1 0; width: 100%;"
															   id="editContactPhoneNumberInput"
															   placeholder="Phone number"
															   pattern="^[0-9]*$"
															   maxlength="11"
															   [ngClass]="{'validation-error' : contact.edit.phoneNumberError}"
															   (keyup)="contact.edit.phoneNumberError = false;"
															   [(ngModel)]="contact.edit.phone.phone_number">
													</div>
												</div>
												<div class="flex-row align-items-center gap-2">
													<span style="flex-basis: 80px;">Password:</span>
													<input type="text"  placeholder="Password"
														   style="width: 32%; height: 25px;"
														   id="editContactPasswordInput"
														   [(ngModel)]="contact.edit.default_password.password"
														   [ngClass]="{'validation-error' : contact.edit.passwordError}"
														   (keyup)="contact.edit.passwordError = false;">
													<div></div><!--padder-->
													<span>Hint:</span>
													<input type="text" id="editContactHintInput"
														   style="width: 32%; height: 25px;"
														   placeholder="Hint"
														   [(ngModel)]="contact.edit.default_password.hint" />
												</div>
												<div class="flex-row mt-3 gap-2 align-items-center">
													<btn-c variant="outlined" (action)="contact.edit = null;">
														Cancel
													</btn-c>
													<btn-c (action)="updateContact(contact, message[recipientScope].contacts);">
														Save
													</btn-c>
												</div>
											</div>
										</div>
									</div>


									<input type="text" id="recipient{{_.capitalize(recipientScope)}}Input" #recipientInputEl
										   class="m-0 w-100 f-1-0"
										   [(ngModel)]="newRecipientInputTxt[recipientScope]"
										   [ngStyle]="{'border' : newRecipientInputError[recipientScope] ? 'solid 1px var(--bs-danger) !important' : 'none'}"
										   (keyup)="newRecipientInputError[recipientScope] = false;"
										   (clickOutside)="addRecipientByEmail(newRecipientInputTxt[recipientScope], recipientScope)"
										   [exclude]="'#' + recipientScope + 'AutoCompleteContainer'"
										   (keydown.backspace)="!newRecipientInputTxt[recipientScope] && removeFromRecipients([_.last(message[recipientScope].contacts)], recipientScope); newRecipientInputTxt[recipientScope] = newRecipientInputTxt[recipientScope].slice(0,-1);"
										   (keyup.enter)="contactAutoCompleteEl.filteredItems?.length ? addRecipientFromExistingContacts(contactAutoCompleteEl.filteredItems[0], recipientScope) : addRecipientByEmail(newRecipientInputTxt[recipientScope], recipientScope)" />
									<auto-complete-menu [id]="recipientScope + 'AutoCompleteContainer'"
														[autoCompleteItems]="allPossibleRecipents"
														[searchTerm]="newRecipientInputTxt[recipientScope]"
														[inputElementRef]="recipientInputEl"
														[showInputTextAsNewItemWhen]="gs.validateEmail"
														(onItemClick)="addRecipientByEmail($event.item, recipientScope)"
														searchByKeys="name,email"
														#contactAutoCompleteEl>
										<ng-template #customListTemplate let-filteredItems>
											<li class="flxClmn vSpace05"
												tabindex="0"
												*ngFor="let contact of filteredItems"
												(keyup.enter)="addRecipientFromExistingContacts(contact, recipientScope)"
												(click)="addRecipientFromExistingContacts(contact, recipientScope)">
												<span class="fw-500 ellipsis" *ngIf="contact.name !== contact.email"
													  [highlightText]="newRecipientInputTxt[recipientScope]"
													  [isHighlightOn]="true">
													{{contact.name}}
												</span>
												<span class="ellipsis" *ngIf="contact.type !== this.dic.CONSTANTS.listType.mass"
													  [highlightText]="newRecipientInputTxt[recipientScope]"
													  [isHighlightOn]="true">
													{{contact.email}}
												</span>
												<span class="ellipsis ignore-highlight-text" *ngIf="contact.type === this.dic.CONSTANTS.listType.mass">&lt;Mass list&gt;</span>
											</li>
										</ng-template>
									</auto-complete-menu>
								</div>
								<a class="blue-link text-decoration-underline px-2" (click)="isCCOpened = true;" *ngIf="!isCCOpened">Cc/Bcc</a>
							</div>
						</div>
					</ng-container>

					<!--Subject-->
					<div class="flex-row align-items-center gap-3">
						<span class="f-1-0" style="font-size: 15px; max-width: 75px;">Subject:</span>
						<input type="text" id="subjectInput" class="f-1-0" style="height: 35px; font-size: 15px;"
							   [(ngModel)]="message.title">
					</div>
				</div>

				<!--summernote editor-->
				<div class="f-1-0 flxClmn overflow-auto" id="summernoteContainer" style="min-height: 240px;"
					 (dragenter)="toggleSummernoteDragState(true, $event)"
					 (dragleave)="toggleSummernoteDragState(false, $event)"
					 (dragend)="toggleSummernoteDragState(false, $event)">
					<div class="f-1-0 summernote-editor overflow-auto sn-responsive" id="summernote" style="min-height: 400px;"
						 [ngxSummernote]="gs.summernoteOptions"
						 [(ngModel)]="message.html">
					</div>
					<div class="border border-top-0 p-2 py-1 animated fadeIn thinner-scrollbar flex-row flex-wrap gap-2 justify-content-start overflow-auto" style="max-height: 60px;"
						 *ngIf="message.attachments?.length">
						<div class="flex-row gap-1 border ellipsis p-0" style="font-size: 11px; align-self: flex-start; max-width: 800px;"
							 *ngFor="let attachment of message.attachments"
							 [tooltip]="attachment.finished ? null : 'Uploading ' + attachment.progressPercentage + '%...'">
							<div class="flex-row gap-1 p-1 ellipsis">
								<img [src]="attachment.picture || gs.getFileIcon(attachment.name)" style="height: 30px; width: 30px;"/>
								<div class="flex-column ellipsis gap-1">
									<span class="ellipsis" [ngClass]="{'loading-dots' : !attachment.finished}">{{attachment.name}}</span>
									<span class="text-muted">{{attachment.size | FormatBytes:0}}</span>
								</div>
								<div class="radial-progress-bar" style="width: 20px; height: 20px; align-self: center; animation-delay: 1s; transition: max-width 0.5s 1.5s; max-width: 20px;"
									 *ngIf="!attachment.isFromLibrary"
									 [ngClass]="{'animated fadeOut' : attachment.finished}"
									 [ngStyle]="attachment.finished && {'max-width' : '0'}">
									<div class="p-bar" [ngStyle]="{'background' : attachment.finished ? 'var(--bs-success)' : 'conic-gradient(royalblue 0deg ' + (attachment.progressPercentage / 100 * 365) +'deg, #d7d7d7 ' + (attachment.progressPercentage / 100 * 365) + 'deg 365deg)'}"></div>
									<i class="fa fa-check text-success" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 10; font-size: 8px;"
									   *ngIf="attachment.finished"></i>
								</div>
							</div>
							<div class="d-flex centralize closeRed bg-light border border-top-0 border-bottom-0 border-right-0 px-2 cursor-pointer"
								 tooltip="Delete attachment"
								 (click)="deleteAttachment(attachment);">
								<i class="fa fa-times"></i>
							</div>
						</div>
					</div>
				</div>

				<div class="flex-row gap-2 align-items-center text-info animated fadeIn" style="margin-top: -10px;" *ngIf="message.css?.length">
					<i class="fa fa-exclamation-circle"></i>
					<span>Pay attention: The email body contains custom styles that may not be visible here but will be visible to the end user.</span>
				</div>

				<!--action buttons-->
				<div class="flex-row gap-3 align-items-center justify-content-start flex-wrap">
					<div class="btn-with-dropdown compose-attach-files-button f-1-0" style="align-self: stretch; max-width: 250px;"
						 ngfSelect
						 [(files)]="attachmentFiles"
						 (fileChange)="uploadAttachment()"
						 multiple="1">
						<btn-c startIcon="fa fa-paperclip">
							Attach Secured Files
						</btn-c>
						<app-dropdown-c (click)="$event.stopPropagation();">
							<li style="text-transform: capitalize; height: auto;"
								(click)="showAttachmentsManager = true;">
								Add from my {{gs.corpname}} library
							</li>
						</app-dropdown-c>
					</div>

					<div class="flex-row px-2" style="font-size: 1rem; background: #ebebeb;">
						<div class="d-flex centralize hvr-darken-light cursor-pointer" style="height: 45px; width: 45px; background: #ebebeb;"
							 tooltip="Load HTML file"
							 [(file)]="htmlFile"
							 ngfSelect
							 accept=".txt, .htm, .html, text/plain, text/html"
							 (fileChange)="loadHtml()">
							<i class="fa fa-file-upload opacity-75"></i>
						</div>
						<div id="templatesBtn" class="position-relative compose-button-with-menu"
							 style="height: 45px; width: 45px; background: #ebebeb;">
							<div class="w-100 h-100 d-flex centralize cursor-pointer hvr-darken-light"
								 (click)="showTemplateButtonMenu = !showTemplateButtonMenu"
								 (clickOutside)="showTemplateButtonMenu = false;"
								 tooltip="Email template">
								<i class="fa fa-brush opacity-75"></i>
							</div>
							<div class="drop-menu " [ngClass]="{'active' : showTemplateButtonMenu}"
								 style="min-height: 0; min-width: 190px;">
								<ul>
									<li class="flex-row gap-2 align-items-center" id="loadTemplateBtn"
									(click)="openLoadTemplatePopup(); showTemplateButtonMenu = false;">
										<i class="fa fa-file-import"></i>
										<span>Load Template</span>
									</li>
									<li class="flex-row gap-2 align-items-center" id="saveTemplateBtn"
										(click)="openSaveTemplatePopup(); showTemplateButtonMenu = false;">
										<i class="fa fa-save"></i>
										<span>Save as Template</span>
									</li>
								</ul>
							</div>
						</div>
						<div id="signatureBtn" class="position-relative compose-button-with-menu"
							 style="height: 45px; width: 45px; background: #ebebeb;">
							<div class="w-100 h-100 d-flex centralize cursor-pointer hvr-darken-light"
								 (click)="showSignatureButtonMenu = !showSignatureButtonMenu"
								 (clickOutside)="showSignatureButtonMenu = false;"
								 tooltip="Signature">
								<i class="fa fa-signature opacity-75"></i>
							</div>
							<div class="drop-menu " [ngClass]="{'active' : showSignatureButtonMenu}"
								 style="min-height: 0; min-width: 215px;">
								<ul>
									<li class="flex-row gap-2 align-items-center" id="saveSignatureBtn"
										(click)="saveAsUserSignature(); showSignatureButtonMenu = false;">
										<i class="fa fa-save"></i>
										<span>Save as Signature</span>
									</li>
									<li class="flex-row gap-2 align-items-center" id="removeSignatureBtn"
										(click)="deleteCurrentUserSignature(); showSignatureButtonMenu = false;">
										<i class="fa fa-ban"></i>
										<span>Remove Default Signature</span>
									</li>
								</ul>
							</div>
						</div>
						<div class="d-flex centralize hvr-darken-light cursor-pointer" style="height: 45px; width: 45px; background: #ebebeb;"
							 [tooltip]="((message.html || '').includes(unsubscribeText) ? 'Remove' : 'Add') + ' &quot;Unsubscribe&quot; link phrase'"
							 (click)="toggleUnsubscribe();">
							<i class="fa fa-bell-slash opacity-75" [ngClass]="{'color-primary opacity-100' : (message.html || '').includes(unsubscribeText)}"></i>
						</div>
					</div>

					<div class="flex-row px-2" style="font-size: 1rem; background: #ebebeb;">
						<div class="d-flex centralize hvr-darken-light cursor-pointer" style="height: 45px; width: 45px; background: #ebebeb;"
							 tooltip='Save draft'
							 (click)="sendMessage(true);">
							<i class="fa fa-save opacity-75"></i>
						</div>
						<div class="d-flex centralize hvr-darken-light cursor-pointer closeRed" style="height: 45px; width: 45px; background: #ebebeb;"
							 tooltip='Clear all'
							 (click)="clearAll()">
							<i class="fa fa-trash-alt opacity-75"></i>
						</div>
					</div>

					<div class="flex-row px-2" style="font-size: 1rem; background: #ebebeb;">
						<div class="d-flex centralize hvr-darken-light cursor-pointer" style="height: 45px; width: 45px; background: #ebebeb;"
							 tooltip='Preview email'
							 (click)="previewEmail();">
							<i class="fa fa-search opacity-75"></i>
						</div>
					</div>
				</div>

				<!--lower buttons-->
				<div class="flex-row mt-4 justify-content-between">

					<div class="flex-row align-items-end gap-3">
						<btn-c class="popup-action-btn"
							   [endIcon]="message.scheduled_time ? 'fa fa-clock' : 'fa fa-paper-plane'"
							   [loading]="{flag: sendingMessageInProcess, text: 'Sending'}"
							   (action)="closeSummerCodeView(); sendMessage()">
							{{ message.scheduled_time ? 'Schedule' : 'Send' }}
						</btn-c>
						<div class="flex-row align-items-center text-success content-secured-label animated fadeIn cursor-default gap-2" *ngIf="isMessageSecured()">
							<i class="fa fa-shield-alt p-2 rounded-pill border border-1 border-success hvr-opacity"></i>
							<span class="text-nowrap" style="font-family: 'Roboto Condensed', sans-serif">Your content is secured!</span>
						</div>
					</div>

					<btn-c class="popup-action-btn align-self-start"
						   *ngIf="!showScheduler"
						   (click)="showSchedulerEvent();"
						   variant="outlined" endIcon="fa fa-clock">
						Schedule
					</btn-c>
					<!--scheduler-->
					<div class="flex-row align-items-center gap-1 animated fadeIn text-nowrap" style="align-items: center;" *ngIf="showScheduler">
						<span>Schedule message to:&nbsp;&nbsp;</span>

						<input type="datetime-local" [min]="minScheduleTime" [(ngModel)]="message.scheduled_time">

						<btn-c style="min-width: 0;"
							   endIcon="zmdi zmdi-close" color="danger"
							   variant="text"
							   btnTooltip="Cancel Schedule"
							   (action)="clearScheduleTime();">
						</btn-c>
					</div>

				</div>
			</div>


			<!--Advanced Section (Right Side)-->
			<div class="flex-column gap-3 f-1-0" style="max-width: 400px;">
				<div class="flex-column gap-4 bg-white rounded p-3 show-on-hover-container-1 overflow-hidden"
					 style="box-shadow: 0 0 10px #cccccc; transition: max-height 0.35s ease-out; max-height: 170px;"
					 [ngStyle]="{'max-height' : !isMassEmail() && checkHIPAA() && !isMessageSecured() && !userInfo.plan.policy.allow_admin_policy_only.value ? (showSecurityScoreSummary ? '380px' : '240px') : (showSecurityScoreSummary ? '315px' : '170px')}">
					<div class="fw-bold flex-row gap-2 align-items-center">
						<i class="fa fa-shield-alt"></i>
						<span>Protect Your Email</span>
					</div>
					<div class="flex-row justify-content-between align-items-center">
						<div class="flex-column gap-3 ms-2">
							<div class="flex-row gap-2 align-items-center ps-2 text-nowrap" style="color: black; font-size: 0.9rem;">
								<div class="md-checkbox transparent-box success-color" [ngClass]="{'disabled': userInfo.plan.policy.encrypt_content.strict || isMassEmail()}">
									<input id="encryptMessageCheckbox" type="checkbox"
										   [(ngModel)]="message.methods.encryptContent"
										   [disabled]="userInfo.plan.policy.encrypt_content.strict  || isMassEmail()">
									<label class="fw-500" for="encryptMessageCheckbox" [ngClass]="{'text-success' : message.methods.encryptContent}">
										Encrypt Message Content
									</label>
								</div>
								<i style="font-weight: 400; position: relative; bottom:5px;" class="fas fa-question-circle" tooltip="{{gs.corpname}} Encrypts Attachments by default. Use this option to encrypt the email message content as well, and gain more control options."></i>
								<i *ngIf="userInfo.plan.policy.encrypt_content.strict || isMassEmail()" style="color:#818181; padding-left:1px; bottom:5px; position: relative;" class="zmdi zmdi-lock-outline" [tooltip]="isMassEmail() ? 'This option is blocked for mass campaigns' : 'This option is locked by your plan admin'"></i>
							</div>
							<div class="flex-row gap-2 align-items-center ps-2 text-nowrap" style="color: black; font-size: 0.9rem;">
								<div class="md-checkbox transparent-box success-color" [ngClass]="{'disabled': userInfo.plan.policy.secure_send.strict || !userInfo.plan.methods.secure_send || isMassEmail()}">
									<input id="AuthenticateSenderCheckbox" type="checkbox"
										   [(ngModel)]="message.methods.secureSend"
										   [disabled]="userInfo.plan.policy.secure_send.strict || isMassEmail()">
									<label class="fw-500" for="AuthenticateSenderCheckbox" [ngClass]="{'text-success' : message.methods.secureSend}">
										Require Authentication
									</label>
								</div>
								<i style="font-weight: 400; position: relative; bottom:5px;" class="fas fa-question-circle" [tooltip]="message.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.password ? 'Use this option to require multi-factor authentication (by password) from your recipients before they will be allowed to access the email. You can change the authentication method from the advanced section.' : 'Use this option to require multi-factor authentication (phone) from your recipients before they will be allowed to access the email. You can change the authentication method from the advanced section.'" placement="bottom"></i>
								<i *ngIf="userInfo.plan.policy.secure_send.strict || !userInfo.plan.methods.secure_send || isMassEmail()" style="color:#818181; padding-left:1px; bottom:5px; position: relative;" class="zmdi zmdi-lock-outline" [tooltip]="isMassEmail() ? 'This option is blocked for mass campaigns' : !userInfo.plan.methods.secure_send ? 'Contact support to enable this feature' : 'This option is locked by your plan admin'" placement="bottom"></i>
							</div>
						</div>

						<div class="flex-column centralize" *ngIf="securityScoreChartOptions && !isMassEmail()">
							<apx-chart style="width: 8vw; max-width: 130px; height: 50px; transform: translateY(-42px);"
									   [series]="securityScoreChartOptions.series"
									   [chart]="securityScoreChartOptions.chart"
									   [plotOptions]="securityScoreChartOptions.plotOptions"
									   [colors]="securityScoreChartOptions.colors"
									   [labels]="securityScoreChartOptions.labels"></apx-chart>
							<span style="font-size: 12px; font-weight: 300;">Security Score</span>
						</div>
					</div>

					<div class="flex-row align-items-center gap-3 text-danger animated fadeIn"
						 style="animation-delay: 0.15s;"
						 *ngIf="!isMassEmail() && checkHIPAA() && !isMessageSecured() && !userInfo.plan.policy.allow_admin_policy_only.value">
						<i class="fa fa-exclamation-triangle" style="font-size: 1.2rem;"></i>
						<span>Sensitive content detected! Protect this email using both "Require Authentication" and "Encrypt Message Content".</span>
					</div>

					<a class="blue-link show-on-hover-item-1 align-self-end animated fadeIn"
					   style="margin-top: -11px; font-size: 12px; margin-bottom: -6px;"
					   [ngStyle]="{'visibility' : getSecurityScore() === 10 ? 'hidden' : ''}"
					   (click)="showSecurityScoreSummary = true;"
					   *ngIf="!showSecurityScoreSummary && !isMassEmail() && getSecurityScore() !== 10">
						How to improve security score?
					</a>

					<div class="animated fadeIn" style="animation-delay: 0.15s;"
						 *ngIf="showSecurityScoreSummary && !isMassEmail() && getSecurityScore() !== 10">
						<hr class="opacity-25 mt-0"/>

						<div class="flex-column gap-1">
							<div class="flex-row justify-content-between">
								<span class="fw-500 mb-1">Improve email's security score:</span>
								<i class="zmdi zmdi-close closeRed cursor-pointer px-2" (click)="showSecurityScoreSummary = false;"></i>
							</div>

							<span class="diamond-indent position-relative ps-3" *ngFor="let instruction of securityImproveInstructions">
								{{instruction}}
							</span>
						</div>
					</div>
				</div>
				<div class="flex-column gap-2 bg-white rounded f-1-0 overflow-hidden" style="box-shadow: 0 0 10px #cccccc; max-height: 48px; transition: max-height 0.15s;"
					 [ngStyle]="{'max-height' : isAdvancedExpanded ? '850px' : '48px'}">
					<div class="flex-row justify-content-between align-items-center cursor-pointer p-3"
						 (click)="isAdvancedExpanded = !isAdvancedExpanded;">
						<div class="fw-bold flex-row gap-2 align-items-center">
							<i class="fa fa-cog"></i>
							<span>Advanced</span>
						</div>
						<i class="fa fa-chevron-down opacity-75 hvr-opacity cursor-pointer" style="font-size: 1rem; transition: all 0.15s;"
						   [ngStyle]="{'transform' : isAdvancedExpanded ? 'rotate(180deg)' : ''}"></i>
					</div>

					<div class="flex-column gap-2 overflow-auto">
						<div class="overflow-auto">
							<div class="flex-column gap-2 p-3" style="min-height: fit-content;">

								<span class="fw-500 mb-2">Email Security</span>

								<div class="flex-row w-100 justify-content-between gap-2 align-items-center"
									 [ngClass]="{'disabled': !message.methods.secureSend}">
									<div class="flex-row f-1-0 align-items-center gap-2">
										<span [ngClass]="{'disabled-overlay': !message.methods.secureSend}">Authentication method:</span>
										<app-dropdown-c class="f-1-0" style="max-width: 120px; height: 22px;"
														[text]="_.startCase(message.advanced.secure.method_2factor)">
											<li *ngFor="let method of dic.CONSTANTS.secureMethods"
												(click)="updateAuthMethod(method);"
												[ngClass]="{'disabled': !allowedAuthMethods.includes(method)}"
												[id]="'AuthMethod' + method">
												{{_.startCase(method)}}
												<i *ngIf="!allowedAuthMethods.includes(method)"
												   style="color:#818181; padding-left:1px; position: relative;"
												   class="zmdi zmdi-lock-outline"
												   tooltip="This option is blocked by your plan admin">
												</i>
											</li>
										</app-dropdown-c>
									</div>

									<div class="flex-row align-items-center gap-2">
										<i *ngIf="!message.methods.secureSend" tooltip="Option can be enabled only for emails with &quot;Require Authentication&quot;" style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
										<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="<b>Phone</b> authentication via SMS/CALL PIN code (preferred).<br/><b>Password</b> authentication via setting a custom password.<br/><b>Email</b> authentication via email containing a PIN code.<br/><b>SSO</b> authentication using Google or Microsoft"></i>
									</div>
								</div>

								<div class="flex-column gap-2 ps-3" style="max-width: 264px; min-height: fit-content;"
									 [ngClass]="{'disabled': !message.methods.secureSend}"
									 *ngIf="message.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.password">
									<div class="flex-row f-1-0 align-items-center gap-2">
										<span>Password:</span>
										<div class="position-relative f-1-0">
											<input id="method_2factor_password"
												   [type]="!showpassword && !hasWKTextSecurity ? 'password' : 'text'"
												   class="w-100" style="height: 22px;"
												   placeholder="Use default password"
												   (keyup)="message.passwordError = false;"
												   [(ngModel)]="message.advanced.secure.method_2factor_password"
												   [ngClass]="{'validation-error' : message.passwordError ,
															   'nofill': message.advanced.secure.method_2factor_password && !showPassword}">
											<label style="opacity: 0.35; position: absolute; top: 50%; right: 0; transform: translate(-60%,-50%);" class="hidepwd cursor-pointer hvr-darken">
												<input type="checkbox" [(ngModel)]="showPassword"/>
												<i class="fa fa-eye unchecked"></i>
												<i class="fa fa-eye-slash checked"></i>
											</label>
										</div>
									</div>
									<div class="flex-row f-1-0 align-items-center gap-2" *ngIf="message.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.password">
										<span>Hint:</span>
										<div class="position-relative f-1-0">
											<input id="method_2factor_password"
												   type="text"
												   class="w-100" style="height: 22px;"
												   placeholder="Hint"
												   [(ngModel)]="message.advanced.secure.method_2factor_password_hint">
										</div>
									</div>
									<div class="flex-row hSpace1 align-items-center md-checkbox mb-2">
										<input id="useContact2factorPassword" type="checkbox" style="height: 0;"
											   [(ngModel)]="message.advanced.secure.use_contact_2factor_password"/>
										<label for="useContact2factorPassword" style="margin: 0; position: relative;">Use contact's password</label>
										<i class="fas fa-question-circle" style="font-weight: 400; position: relative;" tooltip="Use the contact's personal MFA password if one exists"></i>
									</div>
								</div>


								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.secure.enable_smart_authentication',
														id: 'smartAuthentication',
														text: 'Enable Smart Authentication',
														tooltip: 'We will use a unique fingerprint signature to authenticate the recipient without the MFA step when possible while maintaining maximum security.',
														isLocked: !message.methods.secureSend,
														lockedTooltip: 'Option can be enabled only for emails with &quot;Require Authentication&quot;',
														isDisabled: !message.methods.secureSend}">
								</ng-container>

								<div class="flex-row w-100 justify-content-between gap-2 align-items-center"
									 [ngClass]="{'disabled' : userInfo.plan.policy.expired_enable.strict || (!message.methods.secureSend && !message.methods.encryptContent)}">
									<div class="flex-row f-1-0 align-items-center hSpace1 md-checkbox">
										<input id="expiresInXdays" type="checkbox" style="height: 0;"
											   [ngClass]="{'disabled' : userInfo.plan.policy.expired_enable.strict}"
											   [(ngModel)]="message.advanced.secure.expired_enable"/>
										<label for="expiresInXdays" style="margin: 0; position: relative;">
											Expires in
										</label>
										<input id="expired_days" type="number"
											   [disabled]="userInfo.plan.policy.expired_enable.strict || (!message.methods.secureSend && !message.methods.encryptContent)"
											   min="1" max="365"
											   class="mx-1" style="height: 22px; width: 55px;"
											   [(ngModel)]="message.advanced.secure.expired_days"
											   placeholder="Days" />
										<label for="expiresInXdays" style="margin: 0; position: relative;">
											<span style="pointer-events: none;">
												days
											</span>
										</label>
									</div>

									<div class="flex-row align-items-center gap-2">
										<i *ngIf="userInfo.plan.policy.expired_enable.strict || (!message.methods.secureSend && !message.methods.encryptContent)" [tooltip]="userInfo.plan.policy.expired_enable.strict ? 'This option is locked by your plan admin' : 'Option can be enabled only for emails with &quot;Encrypt Message Content&quot; or &quot;Require Authentication&quot; enabled'" style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
										<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="<b>Enabled</b> - Sent email has access expiration.</br><b>Disabled</b> - Email never expires"></i>
									</div>
								</div>

								<div class="flex-row w-100 justify-content-between gap-2 align-items-center"
									 [ngClass]="{'disabled': userInfo.plan.policy.delete_attachment_enable.strict || !message.attachments?.length}">
									<div class="flex-row f-1-0 align-items-center hSpace1 md-checkbox">
										<input id="deleteAttachmentsInXdays" type="checkbox" style="height: 0;"
											   [ngClass]="{'disabled' : userInfo.plan.policy.delete_attachment_enable.strict}"
											   [(ngModel)]="message.advanced.secure.delete_attachment_enable"/>
										<label for="deleteAttachmentsInXdays" style="margin: 0; position: relative;">
											Delete attachments in
										</label>
										<input id="expired_days" type="number"
											   [disabled]="userInfo.plan.policy.delete_attachment_enable.strict || !message.attachments?.length"
											   min="1" max="365"
											   class="mx-1" style="height: 22px; width: 55px;"
											   [(ngModel)]="message.advanced.secure.delete_attachment_days"
											   placeholder="Days" />
										<label for="deleteAttachmentsInXdays" style="margin: 0; position: relative;">
											<span style="pointer-events: none;">
												days
											</span>
										</label>
									</div>

									<div class="flex-row align-items-center gap-2">
										<i *ngIf="userInfo.plan.policy.delete_attachment_enable.strict || (!message.attachments?.length)" [tooltip]="userInfo.plan.policy.delete_attachment_enable.strict ? 'This option is locked by your plan admin' : 'Option can be enabled only for emails with attachments'" style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
										<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="<b>Enabled</b> - Attachments will be deleted according to the expiration date</br><b>Disabled</b> - Attachments will never be deleted"></i>
									</div>
								</div>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.secure.enforce_attachment_encryption',
														id: 'enforceAttachmentEncryption',
														text: 'Enforce attachment encryption',
														tooltip: 'Attachments will be encrypted regardless of the configured policy',
														isLocked: !message.attachments?.length,
														lockedTooltip: 'Option can be enabled only for emails with attachments',
														isDisabled: !message.attachments?.length}">
								</ng-container>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.secure.attachments_preview_mode',
														id: 'attachments_preview_mode',
														text: 'Attachments preview only mode',
														tooltip: 'Recipients will only be able to preview attachments and will not be able to download them. Note that not all file types are supported for preview.',
														isLocked: !message.attachments?.length || (!message.methods.secureSend && !message.methods.encryptContent),
														lockedTooltip: 'Option can be enabled only for emails with attachments and &quot;Encrypt Message Content&quot; or &quot;Require Authentication&quot; enabled',
														isDisabled: !message.attachments?.length || (!message.methods.secureSend && !message.methods.encryptContent)}">
								</ng-container>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.secure.enable_print',
														id: 'allowPrint',
														text: 'Allow print',
														tooltip: '<b>Enabled</b> - Recipient can print email.</br><b>Disabled</b> - Recipient cannot print email',
														isLocked: !message.methods.secureSend && !message.methods.encryptContent,
														lockedTooltip: 'Option can be enabled only for emails with &quot;Encrypt Message Content&quot; or &quot;Require Authentication&quot; enabled',
														isDisabled: !message.methods.secureSend && !message.methods.encryptContent}">
								</ng-container>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.secure.secure_received',
														id: 'authOnReplies',
														text: 'Require Authentication on replies',
														tooltip: '<b>Enabled</b> - Opening recipient reply will require authentication from sender.</br><b>Disabled</b> - Sender authentication is not required',
														isLocked: (!message.methods.secureSend && !message.methods.encryptContent) || userInfo.plan.policy.secure_received?.strict,
														lockedTooltip: userInfo.plan.policy.secure_received?.strict ? 'This option is locked by your plan admin' : 'Option can be enabled only for emails with &quot;Encrypt Message Content&quot; or &quot;Require Authentication&quot; enabled',
														isDisabled: (!message.methods.secureSend && !message.methods.encryptContent) || userInfo.plan.policy.secure_received?.strict}">
								</ng-container>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.secure.open_only_once',
														id: 'accessOnlyOnce',
														text: 'Allow email access only once',
														tooltip: '<b>Enabled</b> - Recipient can access the decrypted data only once.</br><b>Disabled</b> - Recipient has access to encrypted the data until it reached the expiration date',
														isLocked: !message.attachments?.length && (!message.methods.secureSend && !message.methods.encryptContent),
														lockedTooltip: 'Option can be enabled only for emails with &quot;Encrypt Message Content&quot;, &quot;Require Authentication&quot; or attachments',
														isDisabled: !message.attachments?.length && (!message.methods.secureSend && !message.methods.encryptContent)}">
								</ng-container>

								<br/>
								<span class="fw-500 mb-2">General</span>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.email_me.on_any_opened',
														id: 'notifyEmailOpened',
														text: 'Notify me about emails opened',
														tooltip: '<b>Enabled</b> - Notification will be sent via email on any email opening.</br><b>Disabled</b> - Opening notification will be turned off.',
														isLocked: isMassEmail(),
														lockedTooltip: 'This option is blocked for mass campaigns',
														isDisabled: isMassEmail()}">
								</ng-container>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.email_me.on_link_clicked',
														id: 'notifyLinksClicked',
														text: 'Notify me about links clicked',
														tooltip: 'An email notification will be sent for any link clicked',
														isLocked: isMassEmail(),
														lockedTooltip: 'This option is blocked for mass campaigns',
														isDisabled: isMassEmail()}">
								</ng-container>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.email_me.on_attachment_download',
														id: 'notifyFilesDownloaded',
														text: 'Notify me about files downloaded',
														tooltip: 'An email notification will be sent for any file downloaded',
														isLocked: isMassEmail(),
														lockedTooltip: 'This option is blocked for mass campaigns',
														isDisabled: isMassEmail()}">
								</ng-container>

								<div class="flex-row w-100 justify-content-between gap-2 align-items-center">
									<div class="flxRow-start f-1-0 hSpace1 md-checkbox" style="align-items: center;" [ngClass]="{'disabled' : userInfo?.advanced?.general?.reply_to_enable?.strict}">
										<input id="reply_to_enable" type="checkbox" style="height: 0;"
											   [(ngModel)]="message.advanced.general.reply_to_enable"/>
										<label for="reply_to_enable" style="margin: 0; position: relative;">
											Reply to
										</label>
										<input id="reply_to_email" type="text" class="m-0 ms-2 f-1-0" style="max-width: 180px; height: 22px;"
											   *ngIf="message.advanced.general.reply_to_enable"
											   placeholder="My Email"
											   [ngClass]="{'validation-error' : message.advanced.general.reply_to_enable && message.advanced.general.reply_to_email ? !gs.validateEmail(message.advanced.general.reply_to_email) : false}"
											   [(ngModel)]="message.advanced.general.reply_to_email"/>
										<input type="text" class="m-0 ms-2 f-1-0" style="max-width: 180px; height: 22px;"
											   *ngIf="!message.advanced.general.reply_to_enable"
											   [disabled]="true"
											   [placeholder]="'do-not-reply@' + gs.domain"/>
									</div>
									<div class="flex-row align-items-center gap-2">
										<i *ngIf="userInfo?.advanced?.general?.reply_to_enable?.strict" tooltip="This option is locked by your plan admin" style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
										<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="<b>Enabled</b> - Allow replies to the sender email address or any other address.</br><b>Disabled</b> - Do not allow replies"></i>
									</div>
								</div>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.general.trustifi_wrapper',
														id: 'useTemplate',
														text: 'Use Default Template',
														isLocked: isMassEmail(),
														tooltip: '<b>Enabled</b> - Email uses default template.</br><b>Disabled</b> - No template used.',
														lockedTooltip: 'This option is blocked for mass campaigns',
														isDisabled: isMassEmail()}">
								</ng-container>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.general.track_links',
														id: 'trackLinks',
														text: 'Track links',
														tooltip: '<b>Enabled</b> - Track email content link clicks.</br><b>Disabled</b> - Don’t track email content link clicks.',
														isLocked: userInfo.plan.policy.track_links.strict || message.advanced.email_me.on_link_clicked,
														lockedTooltip: userInfo.plan.policy.track_links.strict ? 'This option is locked by your plan admin' : 'This option is locked when Notify about link clicked option is enabled',
														isDisabled: userInfo.plan.policy.track_links.strict || message.advanced.email_me.on_link_clicked}">
								</ng-container>


								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.general.multi_replies',
														id: 'unlimitedReplies',
														text: 'Unlimited replies',
														tooltip: '<b>Enabled</b> - Recipient will be able to send unlimited replies.</br><b>Disabled</b> - Recipient will be able to send only one reply.',
														isLocked: !message.methods.secureSend && !message.methods.encryptContent,
														lockedTooltip: 'Option can be enabled only for emails with &quot;Encrypt Message Content&quot; or &quot;Require Authentication&quot; enabled',
														isDisabled: !message.methods.secureSend && !message.methods.encryptContent}">
								</ng-container>

								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.general.enforce_reply_all',
														id: 'enforceReplyAll',
														text: 'Enforce reply all',
														tooltip: 'Ensures that when someone receives an email and chooses to reply, their response is sent to all the original recipients of the email',
														isLocked: !message.methods.secureSend && !message.methods.encryptContent,
														lockedTooltip: 'Option can be enabled only for emails with &quot;Encrypt Message Content&quot; or &quot;Require Authentication&quot; enabled',
														isDisabled: !message.methods.secureSend && !message.methods.encryptContent}">
								</ng-container>


								<ng-container *ngTemplateOutlet="advancedOptionTemplate;
											  context: {modelRef: 'advanced.general.allow_download_as_eml',
														id: 'allowDownloadEml',
														text: 'Allow downloading as EML',
														tooltip: 'When enabled, recipients will be able to download the email as EML.',
														isLocked: userInfo.plan.policy.allow_download_as_eml.strict || (!message.methods.secureSend && !message.methods.encryptContent),
														lockedTooltip: userInfo.plan.policy.allow_download_as_eml.strict ? 'This option is locked by your plan admin' : 'Option can be enabled only for emails with &quot;Encrypt Message Content&quot; or &quot;Require Authentication&quot; enabled',
														isDisabled: userInfo.plan.policy.allow_download_as_eml.strict || (!message.methods.secureSend && !message.methods.encryptContent)}">
								</ng-container>




								<ng-template #advancedOptionTemplate let-id="id" let-modelRef="modelRef" let-text="text" let-tooltip="tooltip" let-isLocked="isLocked" let-lockedTooltip="lockedTooltip" let-isDisabled="isDisabled">
									<div class="flex-row w-100 justify-content-between gap-2 align-items-center">
										<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;" [ngClass]="{'disabled' : isDisabled}">
											<input [id]="id" type="checkbox" style="height: 0;"
												   (click)="isLocked ? $event.preventDefault() : _.set(message, modelRef, !_.get(message, modelRef)); message.advanced.email_me.on_link_clicked ? (message.advanced.general.track_links = true) : null;"
												   [checked]="_.get(message, modelRef)"/>
											<label [for]="id" style="margin: 0; position: relative;">{{text}}</label>
										</div>
										<div class="flex-row align-items-center gap-2">
											<i *ngIf="isLocked" [tooltip]='lockedTooltip' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline animated fadeIn"></i>
											<i style="font-weight: 400;" class="fas fa-question-circle" [tooltip]="tooltip"></i>
										</div>
									</div>
								</ng-template>
							</div>
						</div>

						<div class="flex-row justify-content-end border border-0 border-top-0 border-bottom-0 p-1 gap-2" style="background: linear-gradient(45deg, #fbfbfb, white); min-height: fit-content;">
							<btn-c class="auto-height" style="min-width: 0;"
								   variant="text" endIcon="fa fa-sync"
								   btnTooltip="Reset advanced settings"
								   (action)="resetAdvanced();">
								Reset to Default
							</btn-c>
							<btn-c class="auto-height" style="min-width: 0;"
								   variant="text" endIcon="fa fa-save"
								   btnTooltip="Save advanced settings as default"
								   (action)="saveAdvanced();">
								Save as Default
							</btn-c>
						</div>
					</div>
				</div>
			</div>

		</div>
    </div>

    <!--Load Email Template popup-->
    <popup-c class="overflownV" *ngIf="loadTemplatePopup?.show"
           head="Load Email Template"
           (closeCb)="loadTemplatePopup = null;"
           (confirmCb)="loadTemplate()"
           [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}" size="dynamic">
        <div class="flxClmn f-1-0 vSpace1" style="overflow: visible;">
            <span>Select a template from the list:</span>
            <app-dropdown-c style="width: 320px;"
                          [alwaysDrop]="true"
                          [text]="loadTemplatePopup.selectedTemplate.name"
                          [items]="loadTemplatePopup.templatesList"
                          itemDisplayKey="name" [enableSearch]="true"
                          (onItemClicked)="loadTemplatePopup.selectedTemplate = $event.item;"></app-dropdown-c>
        </div>
    </popup-c>

	<attachments-manager-c
		*ngIf="showAttachmentsManager"
		(doneCb)="attachmentFilterDone($event.attachments)"
		(closeCb)="showAttachmentsManager = false;"
		[preselectedAttachments]="message.attachments">
	</attachments-manager-c>

    <!--Preview Email popup-->
    <popup-c *ngIf="previewMessagePopup?.show"
           head="Preview Email"
           (closeCb)="previewMessagePopup = null;"
           [buttons]="{cancel: 'OK'}" size="large">
        <div class="flxClmn vSpace2 f-1-0" style="overflow: auto;">
            <div *ngIf="previewMessagePopup.sensitivity">
                <div class="label-wrap" style="line-height: 20px; margin-right: 10px; display: inline;">
                    <label>Sensitivity score</label>
                </div>
                <div class="txt-wrap" style="display: inline;">
                    <input type="button" value="{{previewMessagePopup.sensitivity.score}}"
                           [ngStyle]="previewMessagePopup.sensitivity.score === 0 && {'background':'limegreen'} || previewMessagePopup.sensitivity.score < 4 && {'background':'darkorange'} || previewMessagePopup.sensitivity.score < 5 && {'background':'#FF851B'} || previewMessagePopup.sensitivity.score === 5 && {'background':'#FF4136'}"
                           (click)="openSensitiveInfoPopup(previewMessagePopup.sensitivity)"
                           class="redborder fa" style="background: orange; width:25px; margin: 0 10px; color: white !important; border: 0;"
                           placement="right"
                           tooltip="Click to review sensitivity details">
                </div>
            </div>

			<div style="overflow: auto; flex: 1 0; width: 100%; min-width:220px; border: 1px solid #ccc;">
				<email-viewer [content]="previewMessagePopup.emailContent" style="min-height: 100%;"></email-viewer>
			</div>
        </div>
    </popup-c>

	<sensitivity-details
		*ngIf="sensitivityInfoPopup?.show"
		type="email"
		(cancelCb)="sensitivityInfoPopup = null;"
		scanTitle="Email Sensitivity Details"
		[sensitivity]="sensitivityInfoPopup.sensitivity">
	</sensitivity-details>

	<popup-c *ngIf="sendMassEmailPopup?.show"
		   head="Mass Email Report Options"
		   (closeCb)="sendMassEmailPopup = null;"
		   (confirmCb)="sendMassEmailPopup.doneCb();"
		   [buttons]="{cancel: 'Cancel', confirm: {text: 'Send', loading: sendMassEmailPopup.applyInProcess}}" size="dynamic">
		<div class="flxClmn vSpace3 f-1-0">
			<p style="font-size: 14px;">You can enable one or both of the following</p>
			<div class="flexRow-start hSpace1 md-checkbox">
				<input id="checkbox-summaryReportDataToggle" type="checkbox" [(ngModel)]="sendMassEmailPopup.summaryReportDataToggle">
				<label for="checkbox-summaryReportDataToggle">
					<span style="vertical-align: middle;">Send me a summary report after: </span>
					<input type="number" [value]="sendMassEmailPopup.summaryReportDataInput" max="30" min="0"
						   [(ngModel)]="sendMassEmailPopup.summaryReportDataInput"
						   (keydown)="sendMassEmailPopup.preventAboveMax('summaryReportDataInput', 30, $event)"
						   style="max-width: 50px;">
					<span style="margin-left: 5px;" >day(s)</span>
				</label>
			</div>
			<div class="flexRow-start hSpace1 md-checkbox" style="margin-top: 1rem;">
				<input id="checkbox-fullReportDataToggle" type="checkbox" [(ngModel)]="sendMassEmailPopup.fullReportDataToggle">
				<label for="checkbox-fullReportDataToggle">
					<span style="vertical-align: middle;">Send me a full report after: </span>
					<input type="number" [value]="sendMassEmailPopup.fullReportDataInput" max="30" min="0"
						   [(ngModel)]="sendMassEmailPopup.fullReportDataInput"
						   (keydown)="sendMassEmailPopup.preventAboveMax('summaryReportDataToggle', 30, $event)"
						   style="max-width: 50px;">
					<span style="margin-left: 5px;" >day(s)</span>
				</label>
			</div>
		</div>
	</popup-c>

</div>
