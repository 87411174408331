<div class="bs-enabled flxRow">
	<app-dropdown-c class="country-code-dropdown" style="width: 50px; --dropdown-menu-width: 220px; --bs-dropdown-max-height: 180px; --bs-dropdown-overflow: auto;"
					[text]="selectedCountry ? '<i class=\'flag-icon flag-' + selectedCountry.iso2 + '\'></i>' : 'Select'" #dropdown>
		<li *ngFor="let country of countries"
			(click)="selectCountryCode(country)">
			<div class="flex-row justify-content-between align-items-center gap-3">
				<div class="flex-row align-items-center gap-2">
					<i [ngClass]="'flag-icon flag-' + country.iso2"></i>
					<span>{{country.name}}</span>
				</div>
				<span class="text-muted">+{{country.dialCode}}</span>
			</div>
		</li>
	</app-dropdown-c>
	<input type="text" style="width: 45px; border-left: none; border-bottom-left-radius: 0; border-top-left-radius: 0; margin-left: -2px; padding-left: 2px;"
		   [readOnly]="true"
		   value="+ {{model}}"/>
</div>
