<div class="flxClmn animated fadeIn message-tab-content" style="flex: 1 0; overflow: auto; padding: 0 15px;">

	<div class="actionRow flex-container" style="position: sticky; top: 0; background: white; z-index: 50;">
		<div class="actionLabel flex-item">Actions</div>
		<div class="actionItem flex-item"
			 (click)="doEmailAction('downloadPdf');">
			<a>Download as PDF</a>
		</div>
		<div class="actionItem flex-item"
			 (click)="downloadFile('/postmark/download/' + myEmailsService.currentMessage._id + '/' + recipientPostmark.recipient.id, 'postmark_' + recipientPostmark.id + '.zip', 0);">
			<a>Download data</a>
		</div>
		<div class="actionItem flex-item"
			 (click)="doEmailAction('verifyPostmark')">
			<a>Verify</a>
		</div>
	</div>

    <div class="row-alt" *ngIf="recipientPostmark && !recipientPostmark.recipient">
        <div style="font-weight: bold; padding:20px; text-transform: uppercase;">Postmark data is unavailable</div>
    </div>
    <div class="row alt" style="margin-bottom: 20px;" *ngIf="myEmailsService.currentMessage.postmarkData?.length">
        <div class="message-heading message-heading-flex" style="border-bottom: 0;" >
            <app-dropdown-c style="width: 260px;"
                          [items]="myEmailsService.currentMessage.postmarkData"
                          itemDisplayKey="recipientDisaplyName" [enableSearch]="true"
                          (onItemClicked)="changePostmarkRecipient($event.item)"
                          [text]="recipientPostmark?.recipientDisaplyName || 'Select'"></app-dropdown-c>
        </div>
    </div>

    <div class="row-alt" *ngIf="recipientPostmark?.recipient"
         style="border: 1px solid #ccc; overflow: auto; padding: 0 20px 20px;">
        <div class="signature" id="postmarksrc">
            <div style="background-color: rgb(111,111,111); text-align: center; color: antiquewhite;">
                SECURED POSTMARKED EMAIL PROVIDES LEGAL PROOF OF SENDING
            </div>
            <div class="header">
                <img alt="signature"
                     [src]="logo"
                     style="max-height: 75px;"
                     [ngStyle]="logo === dic.CONSTANTS.trustifiDefault.logo.svg && {'width': '120px'}" />
            </div>

            <div class="row">
                <strong>The email has been successfully processed and securely timestamped using {{corpName}} Postmarked Email®.</strong>
            </div>

            <div class="row trustifi-description">
                The email has been successfully processed and securely timestamped using {{corpName}} Postmarked Email®.
                {{corpName}} Postmarked Email® is a service that digitally signs the email with an electronic timestamp securely synchronized with the atomic clock at the National Institute of Science and Technology (NIST) an agency of the United States Department of Commerce. This HTML page is an official {{corpName}} Postmarked Email® receipt which contains the information needed to legally prove the contents, attachments, recipients, date, and time of the email sent.
            </div>

            <div>
                <div class="row">
                    <div style="width: 60%; height: 100%; float: left;">
                    <ul class="form-fields">
                        <li>
                            <ul>
                                <li>
                                    Message-ID:
                                </li>
                                <li>
                                    {{recipientPostmark.id}}
                                </li>
                            </ul>
                        </li>

                        <li>
                            <ul>
                                <li>
                                    Subject:
                                </li>
                                <li>
                                    {{recipientPostmark.title}}
                                </li>
                            </ul>
                        </li>

                        <li>
                            <ul>
                                <li>
                                    From:
                                </li>
                                <li>
                                    {{recipientPostmark.sender.name}} &#60;{{recipientPostmark.sender.email}}&#62;
                                </li>
                            </ul>
                        </li>

                        <li>
                            <ul>
                                <li>
                                    To:
                                </li>
                                <li>
                                    {{recipientPostmark.recipient.name}} &#60;{{recipientPostmark.recipient.email}}&#62;
                                </li>
                            </ul>
                        </li>

                        <li class="attachments">
                            <ul>
                                <li style="margin-bottom: 10px;">Attachments:</li>
                                <li>
                                    <table cellpadding="0" cellspacing="0" border="0"
                                           *ngFor="let attachment of recipientPostmark.attachments; trackBy: gs.trackByIndex"
                                           style="display: inline-block;">
                                        <tr>
                                            <td valign="top" style="word-break: keep-all; white-space: nowrap;">
                                                <div style="overflow: hidden; position: relative; width: 50px;">
                                                    <span style="font-weight: 500;">Name:</span>
                                                </div>
                                            </td>
                                            <td valign="middle" style="word-break: break-all; white-space: pre-wrap; padding-bottom: 5px;">
                                                <div style="overflow: hidden; position: relative;">
                                                    <span>{{attachment.name}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="word-break: keep-all; white-space: nowrap;">
                                                <div style="overflow: hidden; position: relative; width: 50px;">
                                                    <span style="font-weight: 500;">Hash:</span>
                                                </div>
                                            </td>
                                            <td valign="middle" style="word-break: keep-all; white-space: nowrap; padding-bottom: 5px;">
                                                <div style="overflow: hidden; position: relative;">
                                                    <span>{{attachment.hash}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="word-break: keep-all; white-space: nowrap;">
                                                <div style="overflow: hidden; position: relative; width: 50px;">
                                                    <span style="font-weight: 500;">ID:</span>
                                                </div>
                                            </td>
                                            <td valign="middle" style="word-break: keep-all; white-space: nowrap; padding-bottom: 5px;">
                                                <div style="overflow: hidden; position: relative;">
                                                    <span>{{attachment.id}}</span>
                                                </div>
                                                <hr *ngIf="recipientPostmark.attachments.length > 1 && !$last" style="margin-top: 5px; margin-bottom: 5px;"/>
                                            </td>
                                        </tr>
                                    </table>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    </div>
                    <div style="width: 38%;  height: 100%; float: right;" *ngIf="recipientPostmark.delivered_at">
                        <img style="float: right;" src="images/trustifi-stamp-delivered.png" alt="Stamp Delivered">
                        <div id="stamp1" style="font-family: roboto; font-size: 11px; color: #808080; float: right; margin-top: 90px; margin-right: -250px;">
                            {{recipientPostmark.delivered_at | date:'MMM dd, yyyy HH:mm:ss' : 'UTC'}}</div>
                    </div>
                </div>

                <div class="html2pdf__page-break" *ngIf="recipientPostmark.attachments.length>1"></div>

                <div class="row date-time">
                    <div style="width: 60%; height: 100%; float: left; margin-top:5px;">
                        <strong>Postmark Date &amp; Time:</strong>
                        <p style="margin-bottom: 5px; margin-top: 5px;">{{recipientPostmark.est | date:'MMM dd, yyyy hh:mm:ss a'}} - Eastern</p>
                        <p style="margin-bottom: 5px; margin-top: 5px;">{{recipientPostmark.cst | date:'MMM dd, yyyy hh:mm:ss a'}} - Central</p>
                        <p style="margin-bottom: 5px; margin-top: 5px;">{{recipientPostmark.mst | date:'MMM dd, yyyy hh:mm:ss a'}} - Mountain</p>
                        <p style="margin-bottom: 5px; margin-top: 5px;">{{recipientPostmark.pst | date:'MMM dd, yyyy hh:mm:ss a'}} - Pacific</p>
                        <p style="margin-bottom: 5px; margin-top: 5px;">{{recipientPostmark.timestamp | date:'MMM dd, yyyy hh:mm:ss a' : 'UTC'}} - UTC</p>
                        <br/>
                        <p style="margin-bottom: 6px; margin-top: 6px;"><strong>Data Fingerprint:</strong> <span style="word-break: break-all;">{{recipientPostmark.dataFingerPrint}}</span></p>
                        <p style="margin-bottom: 6px; margin-top: 6px;"><strong>RootCA Bundle Fingerprint:</strong> <span style="white-space: nowrap;">{{recipientPostmark.rootCABundleFingerprint}}</span></p>
                    </div>
                    <div style="width: 38%;  height: 100%; float: right;" *ngIf="recipientPostmark.opened_at">
                        <img style="float: right;" src="images/trustifi-stamp-opened.png"  alt="Stamp Opened">
                        <div id="stamp2" style="font-family: roboto; font-size: 11px; color: #808080; float: right; margin-top: 90px; margin-right: -250px;">
                            {{recipientPostmark.opened_at | date:'MMM dd, yyyy HH:mm:ss' : 'UTC'}}</div>
                    </div>
                </div>

                <div class="row">
                    <div style="width:100%; float: left;">
                        <hr style="border-top: dotted 1px;">
                        <h6>VERIFICATION&nbsp;&nbsp;INFORMATION</h6>
                    </div>
                </div>
                <div class="row trustifi-description">
                    <div style=" width:100%; float: left; padding-bottom: 25px;">
                        This {{corpName}} Postmarked Email® receipt, and a copy of the original email data, contains the information required to legally prove it was sent, including all attachments (if included), recipients, and the date and time. We are keeping this data safe and accessible to senders. Should there be a requirement to have the email verified, simply click the "VERIFY" link in this document and {{corpName}} will perform the verification calculations normally done by complex Public-Key Infrastructure solutions. Under certain extraordinary circumstances, independent verification may be required utilizing cryptographic procedures. If so, a sender can simply download the necessary data which will provide a complete chain of evidence for this record.
                    </div>
                </div>

                <!-- The original sent email that was timestamped (we keep it for you unmodified since the timestamp was created),
                    The actual timestamp that was returned from the DigiStamp server (the digital signature created by the TSA),
                    The public key to verify the authenticity of the signature in the timestamp (All DigiStamp timestamps include their public keys).
                    Your data will be authenticated by comparing its fingerprint to the signed fingerprint in the original timestamp.
                    The digital signature in the timestamp is now decrypted, using the public key, and it is confirmed that the hash signed by
                    the TSA is an exact match to the hash of the data being authenticated. A change to the protected data will prevent
                    it from resolving to the same hash and, consequently, from verifying against the signed information.
                    Any change to the timestamp signature will render it invalid and so the timestamp will not assert anything.
                    -->

                <div class="html2pdf__page-break"></div>
                <div class="row" style="width:100%; float: left; display: none;" id="htmldata"></div>

            </div>
        </div>
    </div>
</div>
