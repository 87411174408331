
<div class="boomerang" id="heroku-boomerang" *ngIf="gs.herokuapp.url">
	<a href="#" class="toggler logo">Heroku Add-ons</a>
	<ul>
		<li class="big"><a [href]="gs.herokuapp.url">{{gs.herokuapp.name}}</a></li>
		<li class="sub"><a href="https://dashboard.heroku.com/apps/{{gs.herokuapp.name}}/resources">Resources</a></li>
		<li class="sub"><a href="https://dashboard.heroku.com/apps/{{gs.herokuapp.name}}/activity">Activity</a></li>
		<li class="sub"><a href="https://dashboard.heroku.com/apps/{{gs.herokuapp.name}}/access">Access</a></li>
		<li class="sub"><a href="https://dashboard.heroku.com/apps/{{gs.herokuapp.name}}/settings">Settings</a></li>
		<li class="big"><a href="https://addons.heroku.com/trustifiapi">Trustifi</a></li>
		<li class="sub"><a href="https://devcenter.heroku.com/articles/trustifiapi">Docs</a></li>
	</ul>
</div>

<!-- Main view  -->
<div [ngClass]="!isVerified() && 'loading-blur'"
	 [ngStyle]="!isVerified() && {'pointer-events':'none'}">
	<router-outlet></router-outlet>
</div>

<popup-drv-component
	*ngIf="popupData?.isShow"
	[globalDataInput]="popupData.options">
</popup-drv-component>


<popup-c *ngIf="showTimeoutPopup" head="Session Expired" size="small" height="450px"
		 (closeCb)="showTimeoutPopup = false;"
		 [buttons]="{cancel: 'OK'}">
	<div class="content-container flxClmn-center" style="flex: 1 0 auto; align-items: center; text-align: center; font-family: 'Roboto', sans-serif;">
		<img src="images/sessionTimeout.png" style="width: 240px; margin: 0;">
		<h2 style="font-size: clamp(16px,2.2rem,7vw); font-weight: bold; color: darkslategrey; margin-bottom: 0; ">You session has expired</h2>
		<h3 style="font-size: clamp(14px,1.2rem,5vw); color: dimgrey;">Session has expired due to inactivity</h3>
		<h3 style="font-size: clamp(14px,1.2rem,5vw); color: dimgrey; font-weight: 400;">Please log in again</h3>
	</div>
</popup-c>


<cloudsponge-component></cloudsponge-component>
<ng-progress #progressBar></ng-progress>

<popup-c class="no-content-padding bs-enabled verify-email-popup" size="dynamic"
		 *ngIf="!isVerified()">
	<div class="flex-column gap-3 p-5 align-items-center text-center f-1-0" style="font-size: 0.95rem;">
		<br/>
		<div class="position-relative">
			<i class="fas fa-envelope-open-text" style="font-size: 4rem; color: #669dff;"></i>
			<i class="fas fa-check-circle position-absolute" style="font-size: 1.5rem; color: green; top: -3px; right: -19%;"></i>
		</div>
		<h4 class="m-3">Please Verify your Email</h4>
		<span>A verification email has been sent to the email account you provided.<br/>(make sure to check your Spam/Junk folder as well).</span>
		<span>Click on the link in the email to verify.</span>
		<br/>
		<div class="flex-row justify-content-center gap-4" style="font-size: 0.83rem;">
			<btn-c (action)="checkVerificationEmail()" color="success">
				Check Verification
			</btn-c>
			<btn-c variant="outlined"
				   [isDisabled]="verificationSent"
				   (action)="sendVerificationEmail()">
				Resend email
			</btn-c>
		</div>
		<hr class="align-self-stretch"/>
		<span>
			Wrong account? <a class="blue-link" (click)="logout()">Click here to logout</a>
		</span>
		<div class="p-2 mt-2 d-flex align-items-center" [ngClass]="verifyEmailPopupMessage && 'bg-warning'" style="min-height: 54px; font-size: 0.8rem; --bs-bg-opacity: 0.3;"><span>{{verifyEmailPopupMessage}}</span></div>
	</div>
</popup-c>

<popup-c *ngIf="showIsSupportedPopup"
		 head="Just to let you know" size="dynamic"
		 (closeCb)="isSupportedSkip();"
		 [buttons]="{cancel: 'I Understand'}">
	<div class="flxClmn f-1-0 vSpace2">
		<div class="flxRow hSpace2" style="align-items: center;">
			<i class="fa fa-exclamation-circle" style="color: var(--bs-danger); font-size: 1.1rem;"></i>
			<span style="color: var(--bs-danger);" *ngIf="isMobileSupported">
                Mobile isn't fully supported.
            </span>
		</div>
		<span>To get the best experience we recommend using Google Chrome, Edge, or Safari on Windows or Mac.</span>
	</div>
</popup-c>

