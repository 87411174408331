import * as util from 'util';
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {GeneralService} from "../../services/generalService";
import {AuthService} from "../../services/authService";
import {DICTIONARY} from "../../dictionary";
import _ from 'lodash';
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ENV_CONSTS} from "../../constants";
import {ComposeMessageService} from "../../services/composeMessageService";
import {ClioService} from "../../services/clioService";
import {DropboxService} from "../../services/dropboxService";
import {HttpClient, HttpEventType, HttpResponse} from "@angular/common/http";
import {LookAndFeelService} from "../../services/lookAndFeelService";
import {Router} from "@angular/router";


@Component({
	selector: 'compose-message-component',
	templateUrl: './compose-message.component.html',
})
export class ComposeMessageComponent implements OnInit, OnDestroy {

    constructor(private rs:RouteService,
				private authService:AuthService,
				private http:HttpClient,
				private router:Router,
				private ns:NotificationService,
				private clioService:ClioService,
				private dropboxService:DropboxService,
                private composeMessageService:ComposeMessageService,
                private lfs:LookAndFeelService,
				public gs:GeneralService) {
    }

    dic = DICTIONARY;
	_ = _;
    showAttachmentsManager;
    showScheduler = false;
    hasWKTextSecurity = this.gs.hasWKTextSecurity();
    massInContactsCounter = 0;
	minScheduleTime;

    uploadingAttachmentsInProcess = false;
	thumbnailsMemSize = 0;
	showpassword;

    securityImproveInstructions = [];
	securityScoreChartOptions;
	showSecurityScoreSummary;

    isCCOpened = false;
    isAdvancedExpanded = false;
	document = document;

	attachmentsUploadingInProcessCount = 0;
    userInfo;
	allowedAuthMethods;
    message;
    sendingMessageInProcess;
    fromAddresses;
    selectedFromAddress;
    userSignature;
    previewMessagePopup;
    sensitivityInfoPopup;
    htmlFile;
	attachmentFiles;
	validDrag;
	invalidDrag
    loadTemplatePopup;
    saveAsTemplateName;
    allPossibleRecipents = [];
	sendMassEmailPopup;
	newRecipientInputTxt = {
		to: '',
		cc: '',
		bcc: ''
	};
	newRecipientInputError = {
		to: false,
		cc: false,
		bcc: false
	};
	showSignatureButtonMenu;
	showTemplateButtonMenu;
	unsubscribeText;

    ngOnInit() {

        this.gs.getUserInfo(false, userInfo => {
            this.userInfo = userInfo;
			this.unsubscribeText = "<br/><br/><br/><div><span lang=\"EN\" style=\"font-size:11px;line-height:20px;font-family:Helvetica, Arial, sans-serif !important;color:#818181 !important;\">To unsubscribe from this list, please click on the following link: <a href=\"https://gss.trustifi.com/unsubscribe.html?e={{REC.email}}\" style=\"color:#818181\" title=\"Unsubscribe\">Unsubscribe</a></span></div>";

			this.prepareNewMessage();
			this.setSecurityScoreChart(this.getSecurityScore());

            this.getAllContacts();

            this.allowedAuthMethods = userInfo.plan.policy?.allowed_auth_methods || this.dic.CONSTANTS.secureMethods;
            if (this.allowedAuthMethods?.length && !this.allowedAuthMethods.includes(this.message.advanced.secure.method_2factor)) {
                this.message.advanced.secure.method_2factor = this.allowedAuthMethods[0];
            }

            this.sendingMessageInProcess = false;
            this.fromAddresses = userInfo.from;
            const defaultAddress = _.find<any>(this.fromAddresses, item => item.type === this.dic.CONSTANTS.fromTypes.default);
            this.selectedFromAddress =  defaultAddress || this.fromAddresses[0];

            if (window.history.state.data) {
                this.setPredefinedDataInMessage(window.history.state.data);

                if (window.history.state.data.template) {
                    setTimeout(() => { // wait for note-editor to load
                        this.loadTemplate(window.history.state.data.template);
                    });
                }
            }
        });
    };

	ngOnDestroy() {
		this.composeMessageService.autoSaveMessage = this.message;
	}

    prepareNewMessage = () => {
        this.message = getNewMessageObj();
		if (this.composeMessageService.autoSaveMessage) {
			this.message = this.composeMessageService.autoSaveMessage;
			this.composeMessageService.autoSaveMessage = null;
		}

        this.initMessageMethods();
        this.setUserAdvancedDefault();
        this.overrideDefaultByPolicy(this.userInfo.plan.policy);

		this.getUserSignature();
    };

	initMessageMethods = () => {
        this.message.methods.track = true;
        this.message.methods.secureReply = this.userInfo.plan.methods.secure_reply;
        this.message.methods.secureSend = this.userInfo.plan.policy.secure_send.value;
        this.message.methods.encryptContent = this.userInfo.plan.policy.encrypt_content.value;
        this.message.advanced.general.track_links = this.userInfo.plan.policy.track_links.value;
    }

    getUserSignature = () => {
        this.rs.getSignature().then(signature => {
            if (signature) {
				this.userSignature = signature;
				if (signature.html && !this.message.html) {
					this.message.html = signature.html;
				}
            }
        }, (err) => {

		});
    };

    checkHIPAA = () => {
		const isHIPAA = (this.message?.title || this.message?.html) && this.composeMessageService.checkHIPAA((this.message.title || '') + ' ' + (this.message.html || ''));
		return isHIPAA;
    };

    getSecurityScore = () => {
        const maxScore = 10;

        // results: start from max and reduce
        let score = maxScore;
        let summaryText = [];

		// secure send
		if (!this.message.methods.secureSend) {
			if (this.message.attachments.length > 0) {
				score -= 3;
				summaryText.push('Enable "Require Authentication"');
			}
			else {
				score -= 2;
				summaryText.push('Enable "Require Authentication"');
			}
		}

		// encrypt content:
		if (!this.message.methods.encryptContent) {
			score -= 3;
			summaryText.push('Enable "Encrypt Message Content"');
		}

		// notify me:
		if (!this.message.advanced.email_me.on_any_opened) {
			score -= 1;
			summaryText.push('Enable "Notify me"');
		}

		// security recieve:
		if (!this.message.advanced.secure.secure_received) {
			score -= 1;
			summaryText.push('Enable "Require Authentication on replies"');
		}

		// phone method:
		if (this.message.advanced.secure.method_2factor !== this.dic.CONSTANTS.authMethods.phone) {
			score -= 1;
			summaryText.push('Set the authentication method to "phone"');
		}

		// access once:
		if (!this.message.advanced.secure.open_only_once && !(this.message.advanced.secure.expired_enable && this.message.advanced.secure.expired_days <= 30)) {
			score -= 1;
			summaryText.push('Set an expiration date or enable one time access');
		}


        this.securityImproveInstructions = summaryText;

		if (this.securityScoreChartOptions && this.securityScoreChartOptions.series[0] !== score * 10) {
			this.securityScoreChartOptions.series = [score * 10];
		}

		return score;
    };

	setSecurityScoreChart = (score) => {
		this.securityScoreChartOptions = {
			series: [score * 10],
			chart: {
				type: 'radialBar',
				height: 130,
				width: 130,
			},
			plotOptions: {
				radialBar: {
					startAngle: -90,
					endAngle: 270,
					track: {
						background: "#cecece",
						strokeWidth: '100%',
						margin: 6, // margin is in pixels
					},
					dataLabels: {
						value: {
							offsetY: -10,
							fontSize: '18px',
							fontWeight: 'bold',
							formatter: function (val) {
								return Math.round(val / 10) + "/10";
							}
						}
					}
				}
			},
			colors: [function({ value }) {
				return value >= 80 ? '#006400' : value >= 50 ? '#e6cd4f' : '#d9355a';
			}],
			labels: [''],
		};
	}

    isMessageSecured = () => {
        return this.message && this.message.methods && this.message.methods.secureSend && this.message.methods.encryptContent;
    };

    toggleUnsubscribe = () => {
		// remove existing "unsubscribe" text
		if (this.message.html && this.message.html.includes(this.unsubscribeText)) {
			this.message.html = this.message.html.replace(this.unsubscribeText, '');
			return;
		}

		// add "unsubscribe" text
        let summernote:any = this.gs.getSummernote();
        if (summernote && summernote.summernote('codeview.isActivated'))
            summernote.summernote('codeview.deactivate');

        this.message.html = this.message.html + this.unsubscribeText;
    };

    closeSummerCodeView = () => {
        let summernote:any = this.gs.getSummernote();
        if (summernote && summernote.summernote('codeview.isActivated')) {
            summernote.summernote('codeview.deactivate');
            this.message.html = summernote.summernote('code');
        }
    };


    setPredefinedDataInMessage = (dataObj) => {
        if (dataObj && dataObj.message) {
            this.message.advanced = dataObj.message.advanced;

            if (dataObj.message.methods) {
                this.message.methods.secureSend = this.userInfo.plan.policy.secure_send.strict ? this.userInfo.plan.policy.secure_send.value : dataObj.message.methods.secure_send;

                this.message.methods.postmark = dataObj.message.methods.postmark;

                this.message.methods.encryptContent = this.userInfo.plan.policy.encrypt_content.strict ? this.userInfo.plan.policy.encrypt_content.value : dataObj.message.methods.encrypt_content;
            }


            if (dataObj.action === 'forward') {
                if (dataObj.message.sent.title) {
                    dataObj.message.sent.title = `FW: ${dataObj.message.sent.title}`;
                }
                if (dataObj.message.sent.html) {
                    dataObj.message.sent.html = `<br><br><br><br>${dataObj.message.sent.html}`;
                }
            }

            if (dataObj.message.sent) {
                this.message.title = dataObj.message.sent.title;

                if (dataObj.message.sent.attachments) {
                    for (let i = 0; i < dataObj.message.sent.attachments.length; i++) {
                        dataObj.message.sent.attachments[i].finished = true;
                        this.message.attachments.push(dataObj.message.sent.attachments[i]);
                    }
                }

				this.message.html = dataObj.message.sent.html;
            }


            if (dataObj.message.scheduled_time) {
                this.showScheduler = true;
            }
        }

		if (dataObj.attachments) {
			dataObj.attachments.forEach((attachmentObj) => {
				attachmentObj.finished = true;
			});
			this.message.attachments = dataObj.attachments;
		}
    }

    getAllContacts = () => {

		let promises = [];

		let contacts = [];
		let lists = [];
		promises.push(this.rs.getAllContacts().then(response => {
			contacts = response || []
		}));

		if (this.userInfo.plan.methods.secure_mass) {
			promises.push(this.rs.getAllLists().then(response => {
				lists = response || [];
			}));
		}

		Promise.all(promises).then(results => {
			this.allPossibleRecipents = contacts.concat(lists);
			if (window.history.state.data) {
				this.addPredefinedContactsToMessage(window.history.state.data);
			}

		}).catch(error => {
			console.error('Error in processing rule deletions:', error);
		});

    };
	addPredefinedContactsToMessage = (dataObj) => {
        if (!dataObj) {
            return;
        }

		if (dataObj.contacts) {
			if (dataObj.contacts.type) {
				this.addRecipientFromExistingContacts(dataObj.contacts, 'to');
				this.blockMassMethods();
			}
			else {
				dataObj.contacts.forEach(contact => {
					this.addRecipientFromExistingContacts(contact, 'to');
				});
			}
		}

		if (dataObj.message?.to?.length) {
			dataObj.message.to.forEach(contact => {
				if (contact.id) {
					contact._id = contact.id;
				}
				this.addRecipientFromExistingContacts(contact, 'to');
			});
		}
    }

    setUserAdvancedDefault = () => {
        if (this.userInfo && this.userInfo.advanced) {
            if (this.userInfo.advanced.general) {
                for (let key in this.userInfo.advanced.general) {
                    this.message.advanced.general[key] = this.userInfo.advanced.general[key].value;
                }
            }

            if (this.userInfo.advanced.email_me) {
                for (let key in this.userInfo.advanced.email_me) {
                    this.message.advanced.email_me[key] = this.userInfo.advanced.email_me[key].value;
                }
            }

            if (this.userInfo.advanced.secure) {
                for (let key in this.userInfo.advanced.secure) {
                    this.message.advanced.secure[key] = this.userInfo.advanced.secure[key].value;
                }
            }
        }
    };

    overrideDefaultByPolicy = (policy) => {
        if (policy.delete_attachment_enable.strict) {
            this.message.advanced.secure.delete_attachment_enable = policy.delete_attachment_enable.value;
            if (policy.delete_attachment_days.value) {
                this.message.advanced.secure.delete_attachment_days = policy.delete_attachment_days.value;
            }
        }

        if (policy.expired_enable.strict) {
            this.message.advanced.secure.expired_enable = policy.expired_enable.value;
            if (policy.expired_days.value) {
                this.message.advanced.secure.expired_days = policy.expired_days.value;
            }
        }

		if (policy.secure_received?.strict) {
			this.message.advanced.secure.secure_received = policy.secure_received.value;
		}

        if (policy.track_links.strict) {
            this.message.advanced.general.track_links = policy.track_links.value;
        }

		if (policy.allow_download_as_eml.strict) {
			this.message.advanced.general.allow_download_as_eml = policy.allow_download_as_eml.value;
		}

        this.message.methods.secureSend = this.userInfo.plan.policy.secure_send.value;
        this.message.methods.encryptContent = this.userInfo.plan.policy.encrypt_content.value;
    }

    loadHtml = () => {
        if (!this.htmlFile) {
            console.error('No file provided');
            return;
        }

        let reader:any = new FileReader();
        reader.addEventListener("load", () => {
            if (reader.result) {
                let res = this.composeMessageService.updateSummernoteContent(reader.result);
                this.message.html = res.html;
                this.message.css = res.css;
			}
        }, false);

        reader.readAsText(this.htmlFile, 'uft8');
    };

    saveAdvanced = () => {
        this.rs.updateUserAdvanced(this.message.advanced).then( (advancedObj) => {
            this.userInfo.advanced = advancedObj;
            this.message.advanced = flatAdvanced(advancedObj, this.userInfo.plan.policy);;
            this.ns.showInfoMessage(this.dic.MESSAGES.advancedSettingsSaved);
        });
    };

    resetAdvanced = () => {
        this.rs.updateUserAdvanced(null).then( (advancedObj) => {
            this.userInfo.advanced = advancedObj;
            this.message.advanced = flatAdvanced(advancedObj, this.userInfo.plan.policy);;
            this.ns.showInfoMessage(this.dic.MESSAGES.advancedResetSettingsSaved);

			this.overrideDefaultByPolicy(this.userInfo.plan.policy);
        });
    };

    previewEmail = () => {
        let message = this.prepareEmailMetaData();
        message.recipients_display_only = {
            to: _.map(this.message.to.contacts, c => {return {address: c.email}}),
            cc: _.map(this.message.cc.contacts, c => {return {address: c.email}}),
        }

        this.rs.previewEmail(message).then( (response) => {
            this.previewMessagePopup = {
                sensitivity: response.sensitivity,
				emailContent: response.html,
                show: true
            };

        }, (err) => {
            if (err && err.data && err.data.message) {
                this.ns.showErrorMessage(err.data.message);
            }
        });
    };

	openNewAddressConfirmationPopup() {
		this.gs.showPopup({
			title: "Add Account Address",
			subTitle: `You are about to exit the "New Secure Email" page`,
			body: ['Confirming this popup will transfer you to "Account Addresses" page.',
				   '<b class="fw-500">All modifications made to the current message will be preserved upon returning to this page</b>',],
			type: DICTIONARY.CONSTANTS.popupInfo,
			doneBtnText: 'Proceed',
			doneCb: () =>{
				this.router.navigate([this.dic.CONSTANTS.appStates.accountDetails, this.dic.CONSTANTS.accountDetailsPages.myAccount, this.dic.CONSTANTS.myProfilePageTabs.accountAddresses]);
			}
		});
	}

    openSensitiveInfoPopup(sensitivity) {
        this.sensitivityInfoPopup = {
            sensitivity,
            show: true
        }
    }

	addRecipientByEmail = (newUserEmail, recipientScope) => {
		if (!newUserEmail) {
			return;
		}

		if (!this.validateEmail(newUserEmail)) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.EnterValidX, 'email address'));
			this.newRecipientInputError[recipientScope] = true;
			return;
		}
		if (_.some(this.message[recipientScope].contacts, {email: newUserEmail.toLowerCase()})) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.contactExist, newUserEmail));
			this.newRecipientInputError[recipientScope] = true;
			return;
		}

		this.message[recipientScope].contacts.push({
			name: '',
			email: newUserEmail,
			phone: {country_code: '', phone_number: ''},
			default_password: {password: '', hint: ''},
		});

		this.newRecipientInputTxt[recipientScope] = '';
		this.newRecipientInputError[recipientScope] = false

		if (this.isMassEmail()) {
			this.blockMassMethods();
		}

		/*setTimeout(() => {
			this.openEditContactModal(_.find(this.message[recipientScope].contacts, {email: newUserEmail}));
		});*/
	}

	addRecipientFromExistingContacts = (contact, recipientScope) => {
		const isAlreadyExist = _.some(this.message[recipientScope].contacts, contactInMessage => {
			if (contact.type === this.dic.CONSTANTS.listType.mass) {
				return contact.name === contactInMessage.name
			}
			else {
				return contact.email === contactInMessage.email
			}
		});

		if (isAlreadyExist) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.contactExist, contact.name || contact.email));
			return;
		}

		if (this.isMassEmail()) {
			this.blockMassMethods();
		}

		this.message[recipientScope].contacts.push(contact);

		this.newRecipientInputTxt[recipientScope] = '';
		this.newRecipientInputError[recipientScope] = false
		document.getElementById(`recipient${this.gs.toCapitalize(recipientScope)}Input`).focus();
	}

    prepareEmailMetaData() {
        let message = _.cloneDeep(this.message);
        message.attachments = this.message.attachments.map(itm => itm._id);

        message.recipients = message.to.contacts.filter(itm => !itm._id);
        message.lists = message.to.contacts.filter(itm => itm._id && itm.type).map(itm => itm._id);
        message.contacts = message.to.contacts.filter(itm => itm._id && !itm.contacts).map(itm => itm._id);

        message.cc.recipients = message.cc.contacts.filter(itm => !itm._id);
        message.cc.contacts = message.cc.contacts.filter(itm => itm._id && !itm.contacts).map(itm => itm._id);

        message.bcc.recipients = message.bcc.contacts.filter(itm => !itm._id);
        message.bcc.contacts = message.bcc.contacts.filter(itm => itm._id && !itm.contacts).map(itm => itm._id);

        if (this.selectedFromAddress && this.selectedFromAddress.email) {
            message.from = {
                email: this.selectedFromAddress.email,
                name: this.selectedFromAddress.name
            };
        }

		message.source = 'webapp';
		message.html = this.composeMessageService.getSummernoteContent(message);
        delete message.css;

        return message;
    }

    openAutoReportPopup = (saveAsDraft) => {
		const summaryReportData = {
            enabled: false,
            value: 2
        };
        const fullReportData = {
            enabled: false,
            value: 2
        };
		this.sendMassEmailPopup = {
			show: true,
			applyInProcess: false,
			summaryReportDataToggle: summaryReportData.enabled,
			summaryReportDataInput: summaryReportData.value,
			fullReportDataToggle: fullReportData.enabled,
			fullReportDataInput: fullReportData.value,
			preventAboveMax: (inputName, max, event) => {
				if (event.key === '-') {
					event.preventDefault();
				}
				if (inputName === 'summaryReportDataInput' && summaryReportData.value > max) {
					this.sendMassEmailPopup.summaryReportDataInput = max;
				} else if (inputName === 'summaryReportDataToggle' && fullReportData.value > max) {
					this.sendMassEmailPopup.fullReportDataInput = max;
				}
			},
			doneCb: () => {
				if (this.sendMassEmailPopup.summaryReportDataToggle) {
					this.message.summaryReportScheduledDays = this.sendMassEmailPopup.summaryReportDataInput;
				}
				if (this.sendMassEmailPopup.fullReportDataToggle) {
					this.message.fullReportScheduledDays = this.sendMassEmailPopup.fullReportDataInput;
				}

				this.sendMassEmailPopup = null;
				this.sendMessageExecute(saveAsDraft);
			}
		};
    };

    sendMessage = (saveAsDraft=false) => {
		if (!this.validateMessageInputs(saveAsDraft)) {
			return;
		}

		if (this.isMassEmail()) {
			this.openAutoReportPopup(saveAsDraft);
		}
		else {
			this.sendMessageExecute(saveAsDraft);
		}
    };

	validateMessageInputs = (saveAsDraft) => {
		if (!(this.message.html || this.message.title || this.message.attachments.length > 0)) {
			this.ns.showWarnMessage(this.dic.ERRORS.emailNoData);
			return false;
		}

		// draft doesn't have to include recipients
		if (!saveAsDraft && !this.message.to.contacts.length && !this.message.cc.contacts.length && !this.message.bcc.contacts.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.emailNoRecipients);
			return false;
		}

		// don't send message if one contact's card is still open
		if (_.find(this.message.to.contacts, 'edit') || _.find(this.message.cc.contacts, 'edit') || _.find(this.message.bcc.contacts, 'edit')) {
			this.ns.showWarnMessage(this.dic.ERRORS.saveOrCancelContactEdit);
			return false;
		}

		if (this.message.scheduled_time && new Date(this.message.scheduled_time) < new Date()) {
			this.ns.showWarnMessage(this.dic.ERRORS.invalidScheduledTimePast);
			return false;
		}

		if (this.message.methods.secureSend && this.message.advanced.secure.method_2factor === this.dic.CONSTANTS.authMethods.password && !this.message.advanced.secure.method_2factor_password) {
			if (!this.message.advanced.secure.use_contact_2factor_password) {
				this.ns.showWarnMessage(this.dic.ERRORS.emailNoDefaultMFAPassword);
				this.message.passwordError = true;
				return false;
			}
			else if (this.message.advanced.secure.use_contact_2factor_password && !this.checkAllRecipientsHasPassword()) {
				this.ns.showWarnMessage(this.dic.ERRORS.cannotDeleteRecipientPassword);
				this.message.passwordError = true;
				return false;
			}
		}

		return true;
	}

    sendMessageExecute = (saveAsDraft) => {
        let message = this.prepareEmailMetaData();

        if (saveAsDraft) {
            this.rs.saveMessageAsDraft(message).then((response) => {
                this.clearAll();
            });
			return;
        }

		if (!message.methods.encrypt_content && (message.html.length + message.title.length) > this.dic.CONSTANTS.maxEmailSize) {
			this.ns.showWarnMessage(this.dic.ERRORS.emailLargeContent);
			return;
		}

		message.scheduled_time_offset = this.gs.getGMT();
		if (message.scheduled_time) {
			message.scheduled_time = new Date(message.scheduled_time).toISOString();
		}

		this.sendingMessageInProcess = true;

		this.rs.sendEmailMessage(message).then((response) => {
			this.clearAll();

			this.sendingMessageInProcess = false;
		}, () => {
			this.sendingMessageInProcess = false;
		});

    };

    validateEmail = (email) => {
        if (!email || email === '') return true;
        return this.gs.validateEmail(email);
    };


	uploadAttachment = () => {
		this.attachmentsUploadingInProcessCount += this.attachmentFiles.length;
		let isValid;
		for (let i = 0; i < this.attachmentFiles.length; i++) {
			// size validation
			isValid = this.gs.checkUploadFileSize(this.attachmentFiles[i].size, this.attachmentFiles[i].name);
			// already exists validation
			if (_.find(this.message.attachments, {name: this.attachmentFiles[i].name, size: this.attachmentFiles[i].size})) {
				this.attachmentsUploadingInProcessCount--;
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.attachmentExists, this.attachmentFiles[i].name));
				isValid = false;
			}

			if (isValid) {
				this.uploadAttachmentExecute(this.attachmentFiles[i]);
			}
			else {
				this.attachmentsUploadingInProcessCount--;
			}
		}

		// empty the model after iteration, so that it won't stack
		this.attachmentFiles = [];
	};

	uploadAttachmentExecute = (file) => {

		const currentAttachment = {
			name: file.name,
			size: file.size,
			finished: false,
			progressPercentage: 0,
			upload: {} as any,
			abortUploadFlag: false,
		};

		// if file is a picture than save picture's data for thumbnail (max for one: 5MB, max for all: 25MB)
		if (['image/gif', 'image/jpeg', 'image/png'].includes(file.type) && file.size < 5000000 && this.thumbnailsMemSize <= 25000000) {
			const reader = new FileReader();

			reader.onload = (e) => {
				currentAttachment['picture'] = e.target.result;

				this.thumbnailsMemSize += file.size;
			}

			reader.readAsDataURL(file);
		}
		//

		this.uploadingAttachmentsInProcess = true;
		this.message.attachments.push(currentAttachment);

		const formData: FormData = new FormData();
		formData.append('file', file, file.name);

		currentAttachment.upload = this.http.post(ENV_CONSTS.attachmentUrl + '/attachment', formData, {
			headers: this.authService.getHeaders(),
			reportProgress: true,
			observe: 'events'
		});

		currentAttachment.upload.subscribe(event => {
			const fileInAttachmentsListObj = _.find(this.message.attachments, {name: currentAttachment.name});

			if (event.type === HttpEventType.UploadProgress) {

				if (fileInAttachmentsListObj) {
					const uploadProgress = Math.trunc((event.loaded / event.total) * 100);
					// file upload completed but not getting response yet.
					if (uploadProgress >= 100) {
						this.attachmentsUploadingInProcessCount--;
						if (this.attachmentsUploadingInProcessCount === 0) {
							this.uploadingAttachmentsInProcess = false;
						}
					}
					// file upload in process
					//// abort upload
					else if (currentAttachment.abortUploadFlag) {
						_.remove(this.message.attachments, {name: currentAttachment.name});

						this.attachmentsUploadingInProcessCount--;
						if (this.attachmentsUploadingInProcessCount === 0) {
							this.uploadingAttachmentsInProcess = false;
						}
					}
					// continue upload
					else {
						fileInAttachmentsListObj.progressPercentage = uploadProgress;
					}
				}
			} else if (event instanceof HttpResponse) {
				// file handling has been handled by the server
				if (event.body) {
					const fileInAttachmentsListObj = _.find(this.message.attachments, {name: currentAttachment.name});
					fileInAttachmentsListObj._id = event.body[0];
				}
				fileInAttachmentsListObj.finished = true;
				fileInAttachmentsListObj.progressPercentage = 100;
			}
		}, (error) => {
			const isFileToRemoveInUploadingList = _.find(this.message.attachments, {name: currentAttachment.name});

			_.remove(this.message.attachments, {name: currentAttachment.name});

			if (isFileToRemoveInUploadingList) {
				this.attachmentsUploadingInProcessCount--;
				if (this.attachmentsUploadingInProcessCount === 0) {
					this.uploadingAttachmentsInProcess = false;
				}
			}

			console.error(error.message);
			this.ns.showErrorMessage('File upload failed');
		});

	};

	deleteAttachment = (attachment) => {
		_.remove(this.message.attachments, {name: attachment.name});
	};

    clearAll = () => {
        this.isCCOpened = false;
		this.isAdvancedExpanded = false;
        this.sendingMessageInProcess = false;
        this.massInContactsCounter = 0;

		this.clearScheduleTime();
        this.prepareNewMessage();
    };

    enableMassMethods = () => {
        if (this.userInfo.plan.policy.encrypt_content) {
            this.message.methods.encryptContent = this.userInfo.plan.policy.encrypt_content.value;
        }
        if (this.userInfo.plan.policy.secure_send) {
            this.message.methods.secureSend = this.userInfo.plan.policy.secure_send.value;
        }
    };

    blockMassMethods = () => {
        this.message.methods.encryptContent = false;
        this.message.methods.secureSend = false;
    };

	removeFromRecipients = (contacts, recipientScope) => {
        if (!contacts?.length) {
			return;
		}

        contacts.forEach(contact => {
            _.remove<any>(this.message[recipientScope].contacts, contact);
        });

		if (!this.isMassEmail()) {
			this.enableMassMethods();
		}
	};

    checkAllRecipientsHasPassword = () => {
		return _.every(this.message.to?.contacts || [], c => !!c.default_password?.password) &&
			   _.every(this.message.cc?.contacts || [], c => !!c.default_password?.password) &&
			   _.every(this.message.bcc?.contacts || [], c => !!c.default_password?.password);
    }

	isMassEmail = () => {
		return !!_.find(this.message.to?.contacts || [], {'type' : this.dic.CONSTANTS.listType.mass}) ||
			   !!_.find(this.message.cc?.contacts || [], {'type' : this.dic.CONSTANTS.listType.mass}) ||
			   !!_.find(this.message.bcc?.contacts || [], {'type' : this.dic.CONSTANTS.listType.mass});
	}

    addToMyContacts = (newContact) => {
        if (!newContact || !this.gs.validateEmail(newContact.edit.email)) {
            newContact.emailError = true;
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.EnterValidX, 'email address'));
			return;
        }

        if (newContact.default_password && !newContact.default_password.password && newContact.default_password.hint) {
            newContact.passwordError = true;
            return this.ns.showWarnMessage(this.dic.ERRORS.contactHintWithoutPassword);
        }

        this.rs.addNewContact({contacts: [newContact.edit]}).then((response) => {
			Object.assign(newContact, newContact.edit);

            //add id and name if not exists for this contact
            newContact._id = response[0];

			newContact.edit = null;

            if (!newContact.name) {
                newContact.name = newContact.email;
            }

			this.allPossibleRecipents.push(newContact);

			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.contactAdded, newContact.email));
		});
    };

    openEditContactModal = (contact) => {
		if (!contact || contact.edit || contact.type === this.dic.CONSTANTS.listType.mass) {
			return;
		}

		contact.edit = {
			name: contact.name,
			email: contact.email,
			phone: _.clone(contact.phone) || {country_code: '', phone_number: ''},
			default_password: _.clone(contact.default_password) || {password: '', hint: ''},
			passwordError: false,
			phoneNumberError: false,
			emailError: false
		}
    };

    recipientEmailAlreadyExist =(contactsList, id, email) => {
        return _.find<any>(contactsList, c => c._id !== id && c.email === email);
    };

    updateContact = (contact, contactsList, cb=null) => {
        if (!contact) {
            return;
        }

		// if contact is brand new then add it to my contacts
		if (!_.find(this.allPossibleRecipents, {email: contact.email})) {
			this.addToMyContacts(contact);
			return;
		}
		//

        if (!this.validateEmail(contact.email)) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.contactEmailInvalid, contact.email));
			contact.edit.emailError = true;
            if (cb) {
                cb(true);
            }
            return;
        }

        if (this.recipientEmailAlreadyExist(contactsList, contact._id, contact.email)) {
            contact.edit.emailError = true;
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.contactExist, contact.email));
            if (cb) {
                cb(true);
            }
            return;
        }

        if (contact.default_password && !contact.default_password.password && contact.default_password.hint) {
			contact.edit.passwordError = true;
            this.ns.showWarnMessage(this.dic.ERRORS.contactHintWithoutPassword);
			if (cb) {
				cb(true);
			}
			return;
        }

		const data = {
			...contact.edit,
			name: contact.edit.name || contact.edit.email
		}

        this.rs.updateContact(contact._id, data).then((response) => {
			Object.assign(contact, contact.edit);
            contact.edit = null;

			// info message of success returns from the BE here...

            if (cb) {
                cb();
            }
        },(err) =>{
            if (cb) {
                cb(err);
            }
        });
    };

	saveTemplate = (templateName) => {
        if (!templateName || (!this.message.html && !this.message.title && !this.message.attachments.length)) {
            return;
        }

        let html = this.composeMessageService.getSummernoteContent(this.message);
        this.rs.addNewTemplate({
            name: templateName,
            title: this.message.title,
            html: html,
            attachments: _.map(this.message.attachments, '_id')
        }).then((response) => {
            this.ns.showInfoMessage(this.dic.MESSAGES.templateCreated);
        });
    };

	// "template" parameter might be predefined when coming from another page (such as "my templates") to this page with a template object
	loadTemplate = (template=null) => {
        const selectedTemplate = this.loadTemplatePopup ? this.loadTemplatePopup.selectedTemplate : template;

        if (!selectedTemplate) {
            return;
        }

        let res = this.composeMessageService.updateSummernoteContent(selectedTemplate.html);
        this.message.html = res.html;
        this.message.css = res.css;

        this.message.title = selectedTemplate.title;

        if (!selectedTemplate.attachments)
            selectedTemplate.attachments = [];

        this.message.attachments = [];
        if (selectedTemplate.attachments) {
            selectedTemplate.attachments.forEach((attachment) => {
                attachment.finished = true;
                this.message.attachments.push(attachment);
            });
        }

        this.loadTemplatePopup = null;
        $('.note-editable').trigger('focus');
    };

    saveAsUserSignature = () => {
        if (!this.message.html) {
            this.ns.showWarnMessage(this.dic.ERRORS.contentEmpty);
            return;
        }

        let html = this.composeMessageService.getSummernoteContent(this.message);
        let div = '<br/><table border="0" cellpadding="0" cellspacing="0" width="auto"><tbody><tr><td border="0" cellpadding="0" cellspacing="0" width="auto">';
        let divEnd = '</tr></tbody></table>';
        let signature = div + html + divEnd;
        this.rs.saveSignature({html: signature}).then((response) => {
            this.userSignature = signature;
        }, (err) => {

		});
    };

    deleteCurrentUserSignature = () => {
        this.rs.deleteSignature().then((response) => {
            this.userSignature = null;

            this.ns.showInfoMessage(this.dic.MESSAGES.signatureDeleted);
        }, (err) => {

		});
    };

	attachmentFilterDone = (attachmentsOutput) =>{
		const selectedAttachments = _.filter(attachmentsOutput, 'selected');
		this.message.attachments = [];

		if (selectedAttachments.length) {

			selectedAttachments.forEach(attachmentObj => {
				attachmentObj.isFromLibrary = true;
				attachmentObj.finished = !attachmentObj.is_clio && !attachmentObj.is_dropbox;
			});

			this.message.attachments = selectedAttachments;

			const clioAttachments = _.uniq(_.map(_.filter(selectedAttachments, 'is_clio'), '_id'));
			if (clioAttachments.length) {
				this.clioService.attachdoc(clioAttachments, (err, data) => {
					if (err)
						console.error(err);
					else if (!data || !data[0])
						console.error('no files were uploaded from clio to Trustifi');
					else {
						data.forEach(itm => {
							itm.finished = true;
							itm.is_clio = true;
							try {
								itm.name = decodeURIComponent(itm.name);
							} catch (e) {
								console.log('Could not decode: ' + itm.name);
							}
						});
						this.message.attachments = this.message.attachments.concat(data);
					}
				});
			}

			const dropboxAttachments = _.uniq(_.map(_.filter(selectedAttachments, 'is_dropbox'), '_id'));
			if (dropboxAttachments.length) {
				this.dropboxService.attachdoc(dropboxAttachments, (err, data) => {
					if (err)
						console.error(err);
					else if (!data || !data[0])
						console.error('no files were uploaded from dropbox to Trustifi');
					else {
						data.forEach(itm => {
							itm.finished = true;
							itm.is_dropbox = true;
							try {
								itm.name = decodeURIComponent(itm.name);
							} catch (e) {
								console.log('Could not decode: ' + itm.name);
							}
						});
						this.message.attachments = this.message.attachments.concat(data);
					}
				});
			}
		}

		this.showAttachmentsManager = false;
		this.ns.closeMessage();
	};

    clearScheduleTime = () => {
        this.showScheduler = false;
        this.message.scheduled_time = null;
    };

    openLoadTemplatePopup =() => {
		this.rs.getTemplates().then((response) => {
			const noTemplatesMsg = `There are no saved templates. To create a template, create an email in this page and then click on "Save as Template", or go to <a class="blue-link fw-bold" style="font-style: italic;" target="_self" href="/${this.dic.CONSTANTS.appStates.personalPages}/${this.dic.CONSTANTS.personalPages.templates}">Templates Page</a>`
			const templatesList = response;
			if (!templatesList?.length) {
				this.ns.showWarnMessage(noTemplatesMsg)
			}

			this.loadTemplatePopup = {
				templatesList,
				selectedTemplate: templatesList[0],
				show: true
			}
		}, (err) => {

		});
    };

    openSaveTemplatePopup = () => {
        this.saveAsTemplateName = '';

        this.gs.showPopup({
            title: 'Save Template',
            subTitle: "Enter the template name:",
            body: [
                {
                    labelName: 'Name',
                    placeholder: "Template name (required)",
                    required: true,
                    model: this.saveAsTemplateName
                },
            ],
            type: this.dic.CONSTANTS.popupInfoConfirming,
            doneBtnText: 'Save',
            doneCb: (body) => {
                this.saveAsTemplateName = body[0].model;
                this.saveTemplate(this.saveAsTemplateName);
            }
        });
    };

    showSchedulerEvent =() => {
        this.showScheduler = !this.showScheduler;

		// get local time ISO string, rounded to the nearest five minutes
		const nowRounded = this.gs.roundToNearestFiveMinutes(new Date());
		const nowRoundedStr = new Date(nowRounded.getTime() - (nowRounded.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
		this.message.scheduled_time = nowRoundedStr;

		// min time is exactly now , in ISO string
		const now = new Date();
		const nowStr = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
		this.minScheduleTime = nowStr;

    };

    updateAuthMethod = (method) => {
		this.message.passwordError = false;

        this.message.advanced.secure.method_2factor = method;
    };

	// BUG FIX : ngfDrop must be overriding the "dragover" event listener of summernote. so we handle it manually
	toggleSummernoteDragState = (setState, event) => {
		const summernoteContainer = document.getElementById('summernoteContainer');

		if (setState && event.target.className === 'note-editable') {
			summernoteContainer.classList.add('summernote-hovered');
		}

			// (a new layer is now inserted because of the last class change so the "drag" event now passes to this new layer)
		// in case of a drag leave without dropping
		else if (!setState && (event.target.className === 'note-dropzone-message')) {
			summernoteContainer.classList.remove('summernote-hovered');
		}
	}
	//
}

function getNewMessageObj() {
    return {
        recipients: [],
        lists: [],
		to: {
			contacts: [],
		},
        cc: {
            contacts: [],
            recipients: [],
            lists: []
        },
        bcc: {
            contacts: [],
            recipients: [],
            lists: []
        },
        title: '',
        html: '',
        methods: {
            track: true,
            postmark: false,
            secureSend: false,
            encryptContent: false,
            secureReply: true
        },
        attachments: [],
        advanced: {
            general: {
                trustifi_wrapper: true,
                reply_to_enable: true,
                reply_to_email: '',
                track_links: true,
                multi_replies: true,
				enforce_reply_all: false,
				allow_download_as_eml: false
            },
            email_me: {
                on_any_opened: false,
                on_link_clicked: false,
                on_attachment_download: false
            },
            secure: {
                open_only_once: false,
                secure_received: false,
                require_2factor: true,
                method_2factor: 'phone',
                method_2factor_password: '',
                expired_enable: true,
                expired_days: 30,
                delete_attachment_enable: false,
                delete_attachment_days: 30,
				enforce_attachment_encryption: true,
				attachments_preview_mode: false,
                enable_print: true,
                enable_smart_authentication: false
            },
            postmark: {
                apply_on_send: true,
                apply_on_received: true,
                send_email_receipt: false,
                notify_recipient: false
            }
        }
    };
}

function flatAdvanced(defaultAdvanced, userPolicy=null) {
    let flatAdvancedObj = {
        general: {},
        email_me: {},
        secure: {},
        postmark: {}
    };

    Object.keys(defaultAdvanced.general).forEach((key) => {
        flatAdvancedObj.general[key] = defaultAdvanced.general[key].value;
    });
    Object.keys(defaultAdvanced.email_me).forEach((key) => {
        flatAdvancedObj.email_me[key] = defaultAdvanced.email_me[key].value;
    });
    Object.keys(defaultAdvanced.secure).forEach((key) => {
        flatAdvancedObj.secure[key] = defaultAdvanced.secure[key].value;
    });
    if (userPolicy) {
        if (userPolicy.expired_enable && userPolicy.expired_enable.strict) {
            flatAdvancedObj.secure['expired_enable'] = userPolicy.expired_enable.value;
        }
        if (userPolicy.expired_days && userPolicy.expired_days.strict) {
            flatAdvancedObj.secure['expired_days'] = userPolicy.expired_days.value;
        }
        if (userPolicy.delete_attachment_enable && userPolicy.delete_attachment_enable.strict) {
            flatAdvancedObj.secure['delete_attachment_enable'] = userPolicy.delete_attachment_enable.value;
        }
        if (userPolicy.delete_attachment_days && userPolicy.delete_attachment_days.strict) {
            flatAdvancedObj.secure['delete_attachment_days'] = userPolicy.delete_attachment_days.value;
        }
		if (userPolicy.allow_download_as_eml && userPolicy.allow_download_as_eml.strict) {
			flatAdvancedObj.general['allow_download_as_eml'] = userPolicy.allow_download_as_eml.value;
		}
    }
    Object.keys(defaultAdvanced.postmark).forEach((key) => {
        flatAdvancedObj.postmark[key] = defaultAdvanced.postmark[key].value;
    });

    return flatAdvancedObj;
}
