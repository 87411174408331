import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {myEmailsService} from "../../../services/myEmailsService";

@Component({
	selector: 'sent-email-advanced-component',
	templateUrl: './sent-email-advanced.component.html',
})
export class SentEmailAdvancedComponent implements OnInit {
    constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService,
				public myEmailsService: myEmailsService) {
    }

    dic = DICTIONARY;
    hasWKTextSecurity = this.gs.hasWKTextSecurity();
    secureMethods;
    userInfo;
    allowPolicyRecall;
    allow_admin_policy_only;
	domain = this.gs.domain;

	center = {
        lat: 36.505,
        lng: -115.09,
        zoom: 6
    };

    ngOnInit() {
        this.gs.getUserInfo(false,  (userInfo) => {
            this.userInfo = userInfo;
            this.allowPolicyRecall = (this.userInfo.plan.policy.allow_recall_message && this.userInfo.plan.policy.allow_recall_message.value);
            this.secureMethods = this.userInfo.plan.policy.allowed_auth_methods || this.dic.CONSTANTS.secureMethods;
            this.allow_admin_policy_only = this.userInfo.plan.allow_admin_policy_only && this.userInfo.plan.policy.allow_admin_policy_only.value;
        });
    };

    doEmailAction = (action) => {
        switch (action) {

            default:
                this.myEmailsService.doEmailAction(action);
        }
    }


    updateAdvancedMessage = () => {
        this.rs.updateAdvancedMessage(this.myEmailsService.currentMessage._id, this.myEmailsService.currentMessage.advanced, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then( () => {
            this.ns.showInfoMessage(this.dic.MESSAGES.advancedSettingsSaved);
            this.isEmailExpired(this.myEmailsService.currentMessage);
        });
    };

    updateAuthMethod = (method, event) => {
        if (!this.secureMethods.includes(method)) {
            event.stopPropagation();
            return;
        }
        this.myEmailsService.currentMessage.advanced.secure.method_2factor = method;
        this.updateAdvancedMessage();
    };

    isEmailExpired = (emailObj) => {
        if ((!emailObj.advanced.secure || !emailObj.advanced.secure.expired_enable) || (!emailObj.methods.encrypt_content && !emailObj.methods.secure_send && !emailObj.sent.attachments && !emailObj.sent.attachments.length)) {
            emailObj.isExpired = false;
            return;
        }
        let expiredDate = new Date(emailObj.created);
        expiredDate.setDate(expiredDate.getDate() + emailObj.advanced.secure.expired_days);
        let now = new Date();
        emailObj.isExpired = now > expiredDate;
    };
}

