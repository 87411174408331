import * as util from 'util';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Buffer} from 'safe-buffer';
import {Component, Input} from "@angular/core";
import {ArchiveService} from "../../../services/archiveService";

@Component({
	selector: 'archive-email-details-component',
	templateUrl: './archive-email-details.component.html',
})
export class ArchiveEmailDetailsComponent{
    constructor(private rs:RouteService,
				private ns:NotificationService,
				private gs:GeneralService,
				public archiveService:ArchiveService){
    }

	@Input() origin: string;

	dic = DICTIONARY;
	restoreEmailPopup;
	previewAttachment;

	showFullToStr;
	showFullCcStr;
	showFullBccStr;

    downloadEml = (email) => {
        if (!email) {
            return;
        }

        const attachment = {
            content: email.content.rawMail,
            name: `${email.content.parsedContent.subject}.eml`,
            contentType: 'message/rfc822'
        };
        this.gs.downloadData(attachment.content, attachment.name, attachment.contentType);
    };

    printEmail = () => {
        if (!this.archiveService.currentMessage) {
            return;
        }

        const emailData = this.archiveService.currentMessage.content.parsedContent;
        let getBasicHeadersHtml = () => {
            let headers = `<div><b>${emailData.from.text}</b></div>
                <hr style="border-width: 1px; border-color: black">
                <br><div>
                <span style="padding-right: 54px;">From: </span>${this.archiveService.currentMessage.content.parsedContent.from.text}</div><div>
                <span style="padding-right: 60px;">Sent: </span>${this.archiveService.currentMessage._source.created && (new Date(this.archiveService.currentMessage._source.created)).toUTCString()}</div><div>
                ${this.archiveService.currentMessage.content.parsedContent.to?.text ? `<span style="padding-right: 72px;">To:   </span>${this.archiveService.currentMessage.content.parsedContent.to.text}</div><div>` : ''}
                ${this.archiveService.currentMessage.content.parsedContent.cc?.text ? `<span style="padding-right: 72px;">Cc:   </span>${this.archiveService.currentMessage.content.parsedContent.cc.text}</div><div>` : ''}
                ${this.archiveService.currentMessage.content.parsedContent.recipientsBccStrFull ? `<span style="padding-right: 72px;">Bcc:   </span>${this.archiveService.currentMessage.content.parsedContent.recipientsBccStrFull}</div><div>` : ''}
                <span style="padding-right: 42px;">Subject: </span>${emailData.subject}</div>`;

            if (this.archiveService.currentMessage._source.attachments?.length) {
                headers += `<div><span style="padding-right: 10px;">Attachments: </span>${this.archiveService.currentMessage._source.attachments.join(', ')}</div>`;
            }
            headers += '<br><br>';
            return headers;
        };

        let printTab = window.open();
        printTab.document.write(getBasicHeadersHtml() + (emailData.html || emailData.text || ''));
        printTab.document.close();
        printTab.print();
        setTimeout(()=>{
            printTab.close();
        }, 1000);
    };

    deleteEmail = (emailObj) => {
        this.gs.showPopup({
            title: 'Delete Email',
            subTitle: `Are you sure you want to delete this email from archive?`,
            body:  [`Email's subject: <b>${emailObj._source.subject}</b>`],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                this.archiveService.deleteEmailFromArchive(emailObj, () => {
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.emailDeleted, emailObj._source.subject));
                });
            }
        });
    }

	restoreEmail(emailObj) {

		let mailboxes = [ ...new Set(emailObj._source.rcptTo) ];
		if (this.origin === 'user') {
			mailboxes = mailboxes.filter(email => email === this.gs.userInfo.email);
			if (!mailboxes?.length) {
				this.ns.showErrorMessage('You can only restore an email to your mailbox');
				return;
			}
		}
		this.restoreEmailPopup = {
			show: true,
			emailsSubject: `${emailObj._source.subject}`,
			mailboxes: mailboxes.map(email => ({
				mailbox: email,
				selected: emailObj._source.rcptTo.length === 1
			})),
			applyInProcess: false,
			recipientSelection: 'all',
			recipientSelectionTypes : {
				all: 'all',
				specific: 'specific'
			},
			errorMessage: '',
			doneCb: () => {
				this.restoreEmailPopup.applyInProcess = true;
				let mailboxes;

				if (this.restoreEmailPopup.recipientSelection === this.restoreEmailPopup.recipientSelectionTypes.all) {
					// Include all mailboxes
					mailboxes = this.restoreEmailPopup.mailboxes.map(item => item.mailbox);
				}
				else {
					// Include only selected mailboxes (this.restoreEmailPopup.recipientSelectionTypes.specific)
					mailboxes = this.restoreEmailPopup.mailboxes.filter(item => item.selected).map(item => item.mailbox);
					if (!mailboxes.length) {
						this.restoreEmailPopup.errorMessage = 'Please select at least one mailbox';
						this.ns.showErrorMessage(this.restoreEmailPopup.errorMessage);
						this.restoreEmailPopup.applyInProcess = false;
						return;
					}
				}

				this.rs.restoreEmailFromArchive(emailObj._id, {mailboxes}).then(()=>{
					this.restoreEmailPopup.applyInProcess = false;
					this.restoreEmailPopup.show = false;
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.emailRestored, emailObj._source.subject));
				}, () => {
					this.restoreEmailPopup.applyInProcess = false;
				});
			}
		}
    }

    downloadAttachment(data, name, type) {
        try {
            data = new Buffer(data, 'base64');
            this.gs.downloadData(data, name, type);
        } catch (err) {
            console.error(err);
        }
    };

}


