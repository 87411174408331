<popup-c *ngIf="show" (closeCb)="setShow(false);"
		 subTitle="Audit log reports will be sent to plan admins and to reviewers. Plan admins will receive reports for all audit log sections, and reviewers will only receive reports for modules they have been assigned to"
		 class="bs-enabled" head="Schedule Report" width="720px"
		 size="dynamic">
	<!--Scheduled reports-->
	<div style="display: flex; align-items: center;">
		<div class="flxRow h100" style="flex:1 0; align-items: center;">
			<div style="flex: 2 0; align-items: center;" class="flxRow hSpace05 h100">
				<span style="font-weight: 500;">Scheduled reports</span>
				<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Scheduled automatic reports that will be sent to reviewers on a daily, weekly, or monthly basis" tooltip-direction="right"></i>
			</div>
			<div style="flex: 5 0; align-items: flex-start; " class="flxClmn-center vSpace2 h100">
				<div *ngFor="let period of ['daily','weekly','monthly']" class="flxRow-start w100 hSpace2" style="align-items: center;">
					<div class="flxRow hSpace1" style="white-space: nowrap; align-items: center; flex-basis: 165px;">
						<toggle-switch-c id="{{period}}ReportToggle"
										 [hideText]="true"
										 [(model)]="scheduledReports[period].value"
										 (onChange)="updateReportSettings()"></toggle-switch-c>
						<span style="vertical-align: middle; text-transform: capitalize;">{{period}} report</span>
					</div>
					<div class="flxRow" style="flex-direction: column;">
						<span [ngClass]="{'text-success' : scheduledReports[period]?.value}">Report will be sent {{period === 'daily' ? 'each day' : period === 'weekly' ? 'each Monday' : period === "monthly" ? 'on the 1st of each month' : ''}}.</span>
						<span *ngIf="scheduledReports[period].last_report" style="color: green;">(Last report: {{scheduledReports[period].last_report | date:'MM/dd/yyyy HH:mm'}})</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</popup-c>
