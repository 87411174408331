import _ from "lodash";
import {DICTIONARY} from '../../../dictionary';
import {GeneralService} from '../../../services/generalService';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import util from "util";
import html2pdf from 'html2pdf.js';
import {Component, NgZone, OnInit, QueryList, ViewChildren} from "@angular/core";
import {TrustifiTableComponent} from "../../uiComponents/trustifi-table/trustifi-table.component";

@Component({
	selector: 'threat-simulation-trends-component',
	templateUrl: './threat-simulation-trends.component.html',
})
export class ThreatSimulationTrendsComponent implements OnInit {

	@ViewChildren('membersTableEl') membersTableEl : QueryList<TrustifiTableComponent>;

    dic = DICTIONARY;
	_ = _;
    dashboardPeriod = [
        this.dic.CONSTANTS.trendsPeriod.lastDay,
        this.dic.CONSTANTS.trendsPeriod.last3Days,
        this.dic.CONSTANTS.trendsPeriod.lastWeek,
        this.dic.CONSTANTS.trendsPeriod.lastMonth,
        this.dic.CONSTANTS.trendsPeriod.range
    ];

	isThisFeatureSupported;
	generalEventsChartOptions: any;
	topCompromisedUsersChart: any;
	topCompromisedGroupsChart: any;
	campaignsSuccessRateChart: any;
	showRiskyGroupsTips;

	period;
	range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()};
    getTrendsDataInProcess = false;
    users: any;
	groups: any;
	activeSpecificUsers: any;
    trendsData: any;
	campaignTrends;
    usersFilterTypes = {all: 'all', specific: 'specific'};
    usersPopup;
	groupMembersComperisonPopup;
    generatePdfInProcess: boolean;

    todayDate = new Date();
	showTrendsCustomPeriod: boolean;

	campaignLatestActvitiyMailboxes;

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ngZone:NgZone,
				private ns:NotificationService) {
    }

	ngOnInit() {
        this.period = this.dic.CONSTANTS.trendsPeriod.lastWeek;

		this.getData();
    }

	getData = () => {
		this.getTrendsDataInProcess = true;
		const promises = [this.getTrendsData(), this.getCampaignRecipients()];
		Promise.all(promises).then(res => {
			this.getTrendsDataInProcess = false;

			this.prepareTrendsDataWrapper();
		})
		.catch(err => {
			this.getTrendsDataInProcess = false;
		});
	}

	getCampaignRecipients = () => {
		if (this.users?.length) {
			return;
		}

		return new Promise(resolve => {
			this.rs.getCampaignRecipients().then((recipients) => {
				this.users = recipients.mailboxes;
				this.groups = recipients.groups;

				resolve(recipients);
			}, (err) => {
				resolve(err);
			});
		});
	}

	getTrendsData() {
		const params: any = {
			period: this.period.value,
		};
		if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
			if (this.range.start.getTime() > Date.now() || this.range.start > this.range.end) {
				this.ns.showWarnMessage(this.dic.ERRORS.adminTrendsDate);
				return;
			}

			params.range = {
				start: this.range.start,
				end: this.range.end
			};
		}

		return new Promise(resolve => {
			this.rs.getCampaignTrends(params).then(response => {
				this.isThisFeatureSupported = true;
				this.trendsData = response;

				resolve(response);
			}, (err) => {
				if (err?.data?.message && err.data.message.includes('This feature is not supported')) {
					this.isThisFeatureSupported = false;
				}
				resolve(err);
			});
		});
	}

	prepareCampaignActivity = (recipientEmails) => {
		let users = this.users;
		if (recipientEmails?.length) {
			users = this.users.filter(itm => recipientEmails.includes(itm.email));
		}
		this.campaignLatestActvitiyMailboxes = _.map(users, user => {
			// Find all campaigns where the user is a recipient
			let userCampaigns = _.filter(this.trendsData, trend => {
				// Check if any recipient in the campaign matches the user's email
				return _.some(trend.recipients, { email: user.email });
			});

			// Determine the last campaign based on the 'created' date
			let lastCampaign = null;
			if (userCampaigns.length) {
				userCampaigns = _.orderBy(
					userCampaigns,
					[trend => new Date(trend.campaign.created)],
					['desc']
				);

				lastCampaign = userCampaigns[0]; // The most recent campaign
			}
			else {
				// If no campaigns, create a dummy lastCampaign with 'created' date in 1970
				lastCampaign = { // TODO: hack! should be removed
					campaign: {
						created: new Date(0).toISOString() // '1970-01-01T00:00:00.000Z'
					}
				};
			}

			// Determine if the user clicked the link in the last campaign
			let clickedLinkInLastCampaign = false;
			if (lastCampaign?.recipients) {
				// Find the recipient object for the user in the last campaign
				const recipientObj = _.find(lastCampaign.recipients, { email: user.email });
				if (recipientObj) {
					clickedLinkInLastCampaign = _.some(recipientObj.track_events, { name: this.dic.EVENTS.eventLinkClicked.name });
				}
			}

			return {
				name: user.name || '',
				email: user.email,
				_id: user._id,
				last_campaign: lastCampaign,
				success: !clickedLinkInLastCampaign
			};
		});
	};



	openUsersFilterPopup = () => {
		this.usersPopup = {
			type: this.activeSpecificUsers ? this.usersFilterTypes.specific : this.usersFilterTypes.all,
			users: (this.users).concat(this.groups || []),
			show: true
		}

		this.usersPopup.users.forEach(userOrGroup => {
			userOrGroup.selected = _.some(this.activeSpecificUsers, item => _.isEqual(item,userOrGroup));
		});
    }

    applyUsersFilter = () => {
		if (this.usersPopup.type === this.usersFilterTypes.specific && !_.find(this.usersPopup.users, 'selected')) {
			this.ns.showErrorMessage('You must select at least one user');
			return;
		}

		if (this.usersPopup.type === this.usersFilterTypes.specific) {
			this.activeSpecificUsers = _.filter(this.usersPopup.users, 'selected');
		}
		else {
			this.activeSpecificUsers = null;
		}

        this.usersPopup = null;

		this.prepareTrendsDataWrapper();
    }

    searchUser = (searchTerm) => {
        searchTerm = searchTerm.toLowerCase();
        this.usersPopup.users.forEach(record => {
            if (searchTerm) {
                const isFound = (record.email.toLowerCase().indexOf(searchTerm) > -1);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    }

	toggleExpandItem = (item, event) => {
		if (!item.members?.length) {
			return;
		}

		item.isExpanded = !item.isExpanded;
		event.stopPropagation();
	}

	searchCampaign = (searchTerm, activeFilters) => {
		searchTerm = searchTerm.toLowerCase();
		this.campaignTrends.forEach(campaignObj => {
			if (searchTerm) {
				const isFound = searchTextExecute(campaignObj, searchTerm);
				if (!isFound) {
					campaignObj.hide = true;
					return;
				}
			}

			campaignObj.hide = false;
		});
	}

	searchMailbox = (searchTerm, activeFilters) => {
		searchTerm = searchTerm.toLowerCase();
		this.campaignLatestActvitiyMailboxes.forEach(mailbox => {
			if (searchTerm) {
				const isFound = searchTextExecute(mailbox, searchTerm);
				if (!isFound) {
					mailbox.hide = true;
					return;
				}
			}

			mailbox.hide = false;
		});
	}

	exportCampaignCsv = (sortBy) => {
		if (!this.campaignTrends.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Created,Name,Template Name,Attack Vector,Attack Techniques,Recipients,Success\n";

		let sortedTable = _.filter(this.campaignTrends,itm => {return !itm.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((campaignObj) => {
			csvString += `${(new Date(campaignObj.campaign.created)).toString()},${campaignObj.campaign.name},"${campaignObj.campaign.template_name}","${campaignObj.campaign.templateInfo?.attack_vector?.name || ''}","${campaignObj.campaign.templateInfo?.techniques?.length ? _.map(campaignObj.campaign.templateInfo.techniques, 'name').join(', ') : ''}",${campaignObj.campaign.recipients_count},${campaignObj.successRate}%\n`;
		});

		this.gs.exportCsv(csvString, `campaigns.csv`);
	}

	exportMailboxCsv = (sortBy) => {
		if (!this.campaignLatestActvitiyMailboxes.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Last Created,Mailbox,Campaign Name,Template Name,Success Rate\n";

		let sortedTable = _.filter(this.campaignLatestActvitiyMailboxes,itm => {return !itm.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((mailboxObj) => {
			const date = new Date(mailboxObj.last_campaign?.campaign?.created).toString().includes('1970') ? 'Not Sent' : new Date(mailboxObj.last_campaign?.campaign?.created).toString();
			const campaignName = mailboxObj.last_campaign?.campaign?.name ? (mailboxObj.last_campaign?.campaign?.group_name ? '('+mailboxObj.last_campaign?.campaign?.group_name+')' + ' ' + mailboxObj.last_campaign?.campaign?.name : mailboxObj.last_campaign?.campaign?.name) : 'N/A';
			csvString += `${date},${mailboxObj.email},"${campaignName}","${mailboxObj.last_campaign?.campaign?.template_name || 'N/A'}","${mailboxObj.last_campaign?.campaign?._id ? (mailboxObj.success ? 'True' : 'False') : 'N/A'}"\n`;
		});

		this.gs.exportCsv(csvString, `Campaign Latest Activity.csv`);
	}

    changePeriod(period) {
        this.period = period;
        this.getData();
    }

	prepareTrendsDataWrapper = () => {
		// clone it because we will filter users also
		this.campaignTrends = JSON.parse(JSON.stringify(this.trendsData));

		let allRecipientsEvents = [];
		this.campaignTrends.forEach((campaignObj) => {
			allRecipientsEvents = allRecipientsEvents.concat(campaignObj.recipients || []);
		});

		// groups chart contains all users events in chart (but shows only groups where the filtered recipients are members)
		this.prepareTopRiskyGroups(allRecipientsEvents);

		let recipientEmails = null;
		if (this.activeSpecificUsers?.length) {
			let flatSpecificUsersAndGroups = _.flatMapDeep(_.filter(this.activeSpecificUsers, 'selected'), userOrGroup => userOrGroup.members || [userOrGroup]);
			flatSpecificUsersAndGroups = _.uniqBy(flatSpecificUsersAndGroups ,'email');
			recipientEmails = flatSpecificUsersAndGroups.map(itm => itm.email);
			allRecipientsEvents = this.filterUsersFromTrends(this.campaignTrends, flatSpecificUsersAndGroups, allRecipientsEvents);
		}

		this.prepareGeneralEventsGraph(allRecipientsEvents);

		this.prepareTopRiskyRecipients(allRecipientsEvents);

		this.prepareCampaignsSuccessRateChart(this.campaignTrends);

		this.prepareCampaignActivity(recipientEmails);
	}

	filterUsersFromTrends = (campaignTrends, activeSpecificUsers, allRecipientsEvents) => {
		const recipientEmails = activeSpecificUsers.map(itm => itm.email);
		if (recipientEmails?.length) {
			allRecipientsEvents = allRecipientsEvents.filter(itm => recipientEmails.includes(itm.email));

			for (let i = campaignTrends.length - 1; i >= 0; i--) {
				const recipientObj = campaignTrends[i].recipients.some(itm => recipientEmails.includes(itm.email));
				if (!recipientObj) {
					// remove campaign since no recipients in filter
					campaignTrends.splice(i, 1);
				}
			}
		}

		return allRecipientsEvents;
	}

	prepareGeneralEventsGraph(allRecipientsEvents) {
		const data = [
			{label: 'Sent', value: 0, color: '#02b90b'},
			{label: 'Read', value: 0, color: '#3984e5'},
			{label: 'User Reported', value: 0, color: '#e5bd39'},
			{label: 'Link Clicked', value: 0, color: '#f50909'},
			{label: 'Phished Login', value: 0, color: '#ff00de'},
			{label: 'Acknowledge', value: 0, color: '#2a2929'},
		];

		allRecipientsEvents?.forEach((recipientObj) => {
			let recipientTracking = {sent: 0, read: 0, userReported: 0, link: 0, acknowledge: 0, login: 0};
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventSent.name)) {
				recipientTracking.sent = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventRead.name)) {
				recipientTracking.read = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventTsReport.name)) {
				recipientTracking.userReported = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventLinkClicked.name)) {
				recipientTracking.link = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventTsLogin.name)) {
				recipientTracking.login = 1;
			}
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventAcknowledge.name)) {
				recipientTracking.acknowledge = 1;
			}

			data[0].value += recipientTracking.sent || 0;
			data[1].value += recipientTracking.read || 0;
			data[2].value += recipientTracking.userReported;
			data[3].value += recipientTracking.link || 0;
			data[4].value += recipientTracking.login || 0;
			data[5].value += recipientTracking.acknowledge || 0;
		});

		data.sort((a, b) => b.value - a.value);

		const labels = data.map(item => item.label);
		const colors = data.map(item => item.color);
		const series = data.map(item => item.value);

		const maxValue = series[0];
		const gSeries = series.map((n) => (n / maxValue) * 100.0);

		this.generalEventsChartOptions = {
			maxValue: maxValue,
			series: gSeries,
			chart: {
				width: '100%',
				type: 'radialBar',
				animations: {
					enabled: true,
				},
			},
			plotOptions: {
				radialBar: {
					endAngle: 270,
					hollow: {
						size: '30%',
						background: 'transparent',
					},
					dataLabels: {
						name: {
							show: true,
							fontSize: '11px',
						},
						value: {
							show: true,
							offsetY: 3,
							fontSize: '12px',
							formatter: (seriesValue, opts) => {
								seriesValue = Number(seriesValue);
								for (let i = 0; i < gSeries.length; i++) {
									if (gSeries[i] === seriesValue) {
										seriesValue = series[i];
										break;
									}
								}
								return seriesValue;
							},
						}
					}
				}
			},
			colors: colors,
			labels: labels,
			legend: {
				show: true,
				floating: true,
				fontSize: '12px',
				position: 'left',
				offsetX: 0,
				offsetY: 0,
				labels: {
					useSeriesColors: true,
				},
				markers: {
					size: 8
				},
				formatter: (seriesName, opts) => {
					return `${seriesName}: ${series[opts.seriesIndex]}`;
				},
				onItemHover: {
					highlightDataSeries: false
				},
				itemMargin: {
					vertical: 3
				}
			}
		};
	}

	prepareDataForTopRiskyGraph(allRecipientsEvents) {
		const recipientsMap = {};
		allRecipientsEvents?.forEach((recipientObj) => {
			if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventLinkClicked.name)) {
				if (!recipientsMap[recipientObj.email]) {
					recipientsMap[recipientObj.email] = 0;
				}
				recipientsMap[recipientObj.email]++;
			}
		});

		const sortedRecipients = sortItems(recipientsMap);
		const emails = [];
		const clicksCount = [];
		sortedRecipients.forEach((type) => {
			emails.push(type);
			clicksCount.push(recipientsMap[type]);
		});

		return {emails, clicksCount};
	}

	prepareTopRiskyRecipients(allRecipientsEvents) {
		const { emails: labels, clicksCount: data } = this.prepareDataForTopRiskyGraph(allRecipientsEvents);

		this.topCompromisedUsersChart = {
			series: [{
				name: 'Link Clicks',
				data: data.slice(0,5)
			}],
			chart: {
				type: 'bar',
				height: '100%',
				toolbar: {
					show: false
				},
			},
			colors: ['#9a1e70'],
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
					barHeight: this.gs.getOptimalBarWidth(labels.length) - 10 + '%',
				}
			},
			dataLabels: {
				total: {
					enabled: true,
					offsetX: 0,
					style: {
						fontSize: '13px',
						fontWeight: 900
					}
				}
			},
			xaxis: {
				categories: labels.slice(0,5),
			}
		};
	}

	prepareTopRiskyGroups = (allRecipientsEvents) => {
		const { emails: clickingUsersEmails} = this.prepareDataForTopRiskyGraph(allRecipientsEvents);

		let groupsData:any = _.map(this.groups, group => {
			// do not show groups that don't overlap with 'activeSpecificUsers' in any member
			if (this.activeSpecificUsers && !_.some(this.activeSpecificUsers, userOrGroup => _.isEqual(group, userOrGroup) || _.some(group.members, {email: userOrGroup.email}))) {
				return null;
			}

			// get how many users ever clicked on a link or more in campaigns
			const clickingUsers = _.filter(group.members, member => clickingUsersEmails.includes(member.email));

			let clickersPercentage = clickingUsers.length / group.members.length * 100
			// enable two digits after dot only if between 0 to 1
			clickersPercentage = clickersPercentage > 1
				? parseFloat(clickersPercentage.toFixed(0))
				: parseFloat(clickersPercentage.toFixed(2));

			return {
				name: group.name,
				clickersPercentage: clickersPercentage,

				clickingUsers: clickingUsers , // for tooltip
				members: group.members, // for tooltip
			}
		});

		// remove empty values and order
		groupsData = _.filter(groupsData, 'clickersPercentage');
		groupsData = _.orderBy(groupsData, ['clickersPercentage'], ['desc']);

		const chartData = {
			labels: _.map(groupsData, 'name'),
			data: _.map(groupsData, 'clickersPercentage')
		}

		this.topCompromisedGroupsChart = {
			series: [{
				name: 'Link Clicks',
				data: chartData.data.slice(0,5)
			}],
			chart: {
				type: 'bar',
				height: '100%',
				toolbar: {
					show: false
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						const groupName = chartData.labels[config.dataPointIndex];
						const groupDataObj = _.find(groupsData, {name: groupName});

						this.ngZone.run(() => {
							this.groupMembersComperisonPopup = {
								clickingUsers: groupDataObj.clickingUsers,
								notClickingUsers: _.differenceBy(groupDataObj.members, groupDataObj.clickingUsers, 'email'),
								searchMemberTxt: '',
								searchMemberFn: () => {
									const listsToSeearchIn = [this.groupMembersComperisonPopup.clickingUsers, this.groupMembersComperisonPopup.notClickingUsers];
									listsToSeearchIn.forEach(list => {
										list.forEach(record => {
											// search
											const searchTerm = this.groupMembersComperisonPopup.searchMemberTxt.toLowerCase();
											if (searchTerm) {
												const isFound = searchGroupMemberTextExecute(record, searchTerm);
												if (!isFound) {
													record.hide = true;
													return;
												}
											}
											record.hide = false;
										});
									});

									this.membersTableEl.forEach(tableEl => {
										// update data inside table
										tableEl.searchItem();
									});
								},
								show: true
							};
						});
					}
				}
			},
			colors: ['#e13030'],
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
					barHeight: this.gs.getOptimalBarWidth(chartData.labels.length) - 10 + '%',
				}
			},
			dataLabels: {
				total: {
					enabled: true,
					offsetX: 0,
					style: {
						fontSize: '13px',
						fontWeight: 900
					}
				},
				formatter: (val) => val + '%'
			},
			tooltip: {
				y: {
					formatter: (val, opt) => {
						const groupName = opt.w.globals.labels[opt.dataPointIndex];
						const groupDataObj = _.find(groupsData, {name: groupName});
						return groupDataObj.clickingUsers.length + ' users clicked. Group members: ' + groupDataObj.members.length;
					},
					title: {
						formatter: () => '',
					},
				},
			},
			xaxis: {
				min: 1,
				max: 100,
				categories: chartData.labels.slice(0,5),
			}
		};
	}

	prepareCampaignsSuccessRateChart(allCampaigns) {
		allCampaigns.forEach((campaignObj) => {
			campaignObj.recipientsClick = 0;
			campaignObj.recipients.forEach((recipientObj) => {
				if (recipientObj.track_events.find(itm => itm.name === this.dic.EVENTS.eventLinkClicked.name)) {
					campaignObj.recipientsClick++
				}
			});
			campaignObj.successRate = Math.floor(((campaignObj.recipients.length - campaignObj.recipientsClick) / campaignObj.recipients.length) * 100);
		});

		const campaignSuccessDataTrends = {
			total: allCampaigns.length,
			labels: [],
			data: [[]],
			series: ['Success'],
		}

		// @ts-ignore
		allCampaigns.sort((a, b) => new Date(a.campaign.created) - new Date(b.campaign.created));

		let threatSimulationSourcePerPeriod = {dateStart: false, dateEnd: undefined, clickLink: 0, totalRecipients: 0};
		allCampaigns.forEach((campaignObj) => {

			if (!threatSimulationSourcePerPeriod.dateStart) {
				threatSimulationSourcePerPeriod = this.initEmailSourcePerPeriod(campaignObj.campaign, this.period.value);
			}
			if (this.gs.isSamePeriod(this.period.value, campaignObj.campaign.created, threatSimulationSourcePerPeriod.dateEnd)) {
				threatSimulationSourcePerPeriod.clickLink += campaignObj.recipientsClick;
				threatSimulationSourcePerPeriod.totalRecipients += campaignObj.recipients.length;
			}
			else {
				this.addThreatSimulationSuccessData(campaignSuccessDataTrends, threatSimulationSourcePerPeriod, this.period.value);

				threatSimulationSourcePerPeriod = this.initEmailSourcePerPeriod(campaignObj.campaign, this.period.value);
				threatSimulationSourcePerPeriod.clickLink++;
				threatSimulationSourcePerPeriod.totalRecipients += campaignObj.recipients.length;
			}
		});

		this.addThreatSimulationSuccessData(campaignSuccessDataTrends, threatSimulationSourcePerPeriod, this.period.value);


		const series = [];
		campaignSuccessDataTrends.series.forEach((categoryName, index) => {
			series.push({
				name: categoryName,
				data: campaignSuccessDataTrends.data[index]
			});
		});

		this.campaignsSuccessRateChart = {
			series: series,
			chart: {
				type: campaignSuccessDataTrends.labels?.length === 1 ? "bar" : "area",
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					enabled: true,
				},
			},
			plotOptions: {
				bar: {
					borderRadius: 5,
					horizontal: false,
				},
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['transparent']
			},
			xaxis: {
				tickAmount: 4, // Set the maximum number of ticks (labels) to be displayed on the x-axis
				categories: campaignSuccessDataTrends.labels,
			},
			yaxis: {
				min: 0,
				max: 100,
				title: {
					text: 'Success rate'
				}
			},
			fill: {
				opacity: 1
			},
		};
	}

	initEmailSourcePerPeriod(currentSource, period) {
		const threatSimulationSourcePerPeriod = this.gs.calcDatesForPeriod(currentSource, period);
		threatSimulationSourcePerPeriod.clickLink = 0;
		threatSimulationSourcePerPeriod.totalRecipients = 0;

		return threatSimulationSourcePerPeriod;
	}

	addThreatSimulationSuccessData(campaignSuccessDataTrends, threatSimulationSourcePerPeriod, period) {
		if (threatSimulationSourcePerPeriod.dateStart) {
			const dateLabel = this.gs.getPeriodLabel(period, threatSimulationSourcePerPeriod);

			const successRate = Math.floor(((threatSimulationSourcePerPeriod.totalRecipients - threatSimulationSourcePerPeriod.clickLink) / threatSimulationSourcePerPeriod.totalRecipients) * 100);


			campaignSuccessDataTrends.labels.push(dateLabel);
			campaignSuccessDataTrends.data[0].push(successRate);
		}
	}


	exportChartsToPdf = () => {
        if (!this.trendsData) {
            return;
        }
        this.generatePdfInProcess = true;

        // set the relevant elements' visual properties to be ideal for the PDF page before copying the HTML
        let areaForPdf:any = document.getElementById('trendsChartsContainer');
        areaForPdf.classList.add('trends-pdf-layout');

        // timeout is needed to let the css enough time to update on screen and for the responsive charts to resize
        setTimeout(() => {
            const element = document.getElementById('trendsChartsContainer');
            const opt = {
                margin:       40,
                filename:     'trends_and_insights.pdf',
                image:        { type: 'jpeg', quality: 1 },
                html2canvas:  { scale: 2 , width: 1300, windowWidth: 1550},
                jsPDF:        { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling']}
            };

            html2pdf().set(opt).from(element).then(() => {
					setTimeout(() => {
						this.exitFromPdfGeneration();
					});
            }).save();
			},800);
    }

    exitFromPdfGeneration = () => {
        this.ns.showInfoMessage(util.format(this.dic.MESSAGES.downloadSuccess, 'Trends and insights data'));

        document.getElementById('trendsChartsContainer').classList.remove('trends-pdf-layout');
        this.generatePdfInProcess = false;
        // rerender charts so they'll fit their container's size
        this.getTrendsDataInProcess = true;

		setTimeout(() => {
			this.ngZone.run(() => {
				this.getTrendsDataInProcess = false;
			});
		});
    }
}

function sortItems(list) {
    return Object.keys(list).sort((a,b) => {return list[b] - list[a]});
}

function searchTextExecute(campaignObj, searchTerm) {
	return (campaignObj.campaign?.name?.toLowerCase().indexOf(searchTerm) > -1 ||
		campaignObj.campaign?.template_name?.toLowerCase().indexOf(searchTerm) > -1
		|| campaignObj.last_campaign?.campaign?.name?.toLowerCase().indexOf(searchTerm) > -1
		|| campaignObj.last_campaign?.campaign?.template_name?.toLowerCase().indexOf(searchTerm) > -1
		|| campaignObj.name?.toLowerCase().indexOf(searchTerm) > -1
		|| campaignObj.email?.toLowerCase().indexOf(searchTerm) > -1);
}

function searchGroupMemberTextExecute(groupMember, searchTerm) {
	return (groupMember.name?.toLowerCase().indexOf(searchTerm) > -1
		|| groupMember.email?.toLowerCase().indexOf(searchTerm) > -1);
}

