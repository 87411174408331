<div class="flxClmn">
    <div class="page-title-container flxRow">
        <div class="flxClmn-center f-1-0">
            <div class="page-title-inner-container">
                <span class="page-title">Mass Email Lists</span>
            </div>
            <div class="page-subtitle-container">
                <span class="page-subtitle">Create and manage mass email projects. Send emails to massive amount of email addresses, track their delivery status and more</span>
            </div>
        </div>
        <div class="control-and-info-icons flxRow"></div>
    </div>
    <div class="page-inner-content-holder no-scroll flxClmn vSpace2" style="padding-top: 0;">

        <div class="flxRow animated fadeIn" style="flex: 1 0; flex-wrap: wrap; border-top: solid 1px #e3e4e4; padding-top: 10px; overflow: auto;">
            <!--list of mass email lists (left side)-->
            <div class="flxClmn" style="flex: 1 0; margin: 7px; padding: 5px; min-width: 515px; min-height: 350px; background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent);">
                <div class="flxClmn" style="flex: 1 0; overflow: auto;" >
                    <div class="w100 flxRow-between" style="background: #677184; padding: calc(3px + 0.4vw); align-items: center; z-index: 1; min-height: 42px;">
						<btn-c style="background: white;"
							   variant="outlined" endIcon="fas fa-plus"
							   [isDisabled]="loadingListsInProcess"
							   (action)="openUploadListPopup();">
							Import Mass List
						</btn-c>
                        <div class="flxRow hSpace1" style="align-items: center;">
							<span *ngIf="_.reject(lists, 'hide').length" style="color: white; padding: 5px;">
                                {{_.reject(lists, 'hide').length}} record{{_.reject(lists, 'hide').length === 1 ? '' : 's'}}
                            </span>
                            <!--Search-->
							<search-box [(model)]="searchListTxt"
										(modelChange)="triggerSearch()"
										placeholder="Search mass list"
										style="width: 30%; min-width: 250px;">
							</search-box>
                        </div>
                    </div>
                    <!--lists-->
                    <trustifi-table-c
                            class="trustifi-table" style="flex: 1 0; z-index: 5; background: white;"
                            [list]="lists"
                            [headers]="[
                                {text: 'List Name', sortKey: 'name', width: '40%', style: {'word-break': 'break-all'}},
                                {text: 'Contacts', sortKey: 'mass_list.length', width: '20%' },
                                {text: 'Status', sortKey: 'verification_status', width: '20%'},
                            ]"
                            [cellsPrototype]="[
                                        {textKey: 'name', edit: {modelKey: 'name', placeholder: 'Name'}},
                                        {html: 'contactsNumberCell'},
                                        {html: 'statusCell'},
                                    ]"
                            [cellActions]="{
                                        enable: true,
                                        selectItemActionCb:	selectAction,
                                        initActionsFunction: showListActions
                                    }"
                            (confirmEditFunction)="confirmEdit($event.item, $event.isApproved)"
                            (onAnyItemSelected)="!$event.isItemSelectCheckboxClicked && previewList($event.item)"
							(searchFunction)="searchLists()"
                            [options]="{
                                loadingTableFlag: loadingListsInProcess,
                                soloSelected: true,
                                hideContentAboveTable: true,
                                itemsNameSingular: 'mass list',
                            }"
                    >
						<ng-template #customCells let-item let-cellId="cellId">
							<!--list contacts number cells-->
							<span *ngIf="cellId === 'contactsNumberCell'">
								<span>{{item.mass_list.length}}</span>
							</span>
							<!--list status cells-->
							<span *ngIf="cellId === 'statusCell'">
								<span style="color: darkgreen; text-transform: capitalize;"
									  *ngIf="item.verification_status === dic.CONSTANTS.listVerStatus.verified">{{item.verification_status}}</span>
								<span style="color: darkorange; text-transform: capitalize;"
									  *ngIf="item.verification_status === dic.CONSTANTS.listVerStatus.pending">{{item.verification_status}}</span>
								<span style="color: darkorange; text-transform: capitalize;"
									  *ngIf="item.verification_status === dic.CONSTANTS.listVerStatus.unverified">{{item.verification_status}}</span>
								<span style="color: darkred; text-transform: capitalize;"
									  *ngIf="item.verification_status === dic.CONSTANTS.listVerStatus.rejected">{{item.verification_status}}</span>
							</span>
						</ng-template>
                    </trustifi-table-c>
                </div>
            </div>

            <!--list details area (right side)-->
            <div class="flxClmn" style="flex: 1.2 0; margin: 7px; min-width: 600px; align-items: center; background: #f7f7f7; position: relative; overflow: auto; padding: 10px;">
                <div class="flxClmn vSpace2 w100 animated fadeIn" style="flex:1 0; overflow: auto; background: white; padding: 15px;" *ngIf="activeMassList">
                    <div style="padding-left:15px; padding-top:15px; flex: 1 0; overflow: auto;" class="flxClmn">
                        <h5>General</h5>
                        <div>
                            <br/>
                            <label style="color: black; display: inline-block; float: left; width:250px; clear: left; text-align: left; padding-right:10px;">Name</label>
                            <span style="font-weight: bold;">{{activeMassList.name}}</span>
                        </div>
                        <div>
                            <br/>
                            <label style="color: black; display: inline-block; float: left; width:250px; clear: left; text-align: left; padding-right:10px;">Created</label>
                            <span>{{activeMassList.created | date: 'MM/dd/yyyy HH:mm:ss'}}</span>
                        </div>
                        <div>
                            <br/>
                            <label style="color: black; display: inline-block;  float: left; width:250px; clear: left; text-align: left; padding-right:10px;">Verification status</label>
                            <span style="margin-right:1.5em; font-size: 1em; font-family: 'Roboto Condensed', 'Helvetica Neue','Helvetica', 'sans-serif'; opacity:0.5;"
                                  *ngIf="activeMassList.verification_status == dic.CONSTANTS.listVerStatus.verified"
                                  [ngStyle]="{'color': activeMassList.mass_list && activeMassList.mass_list.is_risky_list ? 'darkorange' : 'darkgreen'}">
                                        Verified
                                        <span *ngIf="activeMassList.mass_list && activeMassList.mass_list.is_risky_list">(risky)</span>
                                    </span>
                            <span style="color: darkorange; margin-right:1.5em; font-size: 1em; font-family: 'Roboto Condensed', 'Helvetica Neue','Helvetica', 'sans-serif'; opacity:1.5;"
                                  *ngIf="activeMassList.verification_status == dic.CONSTANTS.listVerStatus.unverified">Unverified</span>
                            <span style="color: darkorange; margin-right:1.5em; font-size: 1em; font-family: 'Roboto Condensed', 'Helvetica Neue','Helvetica', 'sans-serif'; opacity:0.5;"
                                  *ngIf="activeMassList.verification_status == dic.CONSTANTS.listVerStatus.pending">Pending</span>
                            <span style="color: darkred; margin-right:1.5em; font-size: 1em; font-family: 'Roboto Condensed', 'Helvetica Neue','Helvetica', 'sans-serif'; opacity:0.5;"
                                  *ngIf="activeMassList.verification_status == dic.CONSTANTS.listVerStatus.rejected">Rejected</span>
                        </div>
                        <div *ngIf="activeMassList.status_timestamp">
                            <br/>
                            <label style="color: black; display: inline-block;  float: left; width:250px; clear: left; text-align: left; padding-right:10px;">Status
                                updated at</label>
                            <span>{{activeMassList.status_timestamp | date: 'MM/dd/yyyy HH:mm:ss'}}</span>
                        </div>

                        <div class="flxClmn" style="flex: 1 0; overflow: auto;">
                            <br/>
                            <br/>
                            <h5>Data</h5>
                            <div>
                                <br/>
                                <label style="color: black; display: inline-block; float: left; width:250px; clear: left; text-align: left; padding-right:10px;">Number of contacts</label>
                                <span>{{activeMassList.mass_list.length}}</span>
                            </div>

                            <div>
                                <br/>
                                <label style="margin-top: 7px; color: black; display: inline-block; float: left; width:250px; clear: left; text-align: left; padding-right:10px;">Preview contacts</label>
                                <form class="flxRow hSpace05" novalidate="novalidate">
                                    <span>
                                        <input type="number" name="test" [(ngModel)]="activeMassList.listRows" style="width: auto; padding-left: 5px;"
                                               maxlength="3" min="1" max="100"/>
                                    </span>
                                    <btn-c style="min-width: 0; width: 60px;"
                                         [isDisabled]="!activeMassList.listRows"
                                         (action)="previewMassEmail(activeMassList, activeMassList.listRows);">
                                        Get
                                    </btn-c>
                                </form>
                            </div>
                            <br/><br/>
							<div class="flxClmn f-1-0" style="border: solid 1px #cccccc; min-height: 210px;"
								 *ngIf="activeMassList.previewList?.contacts && activeMassList.previewList.contacts.length">
								<!--header template-->
								<ng-template #headerContainer>
									<thead #header style="background: white; z-index: 50; position: relative;">
									<tr>
										<th style="font-weight: 500; text-transform: capitalize;"
											*ngFor="let column of activeMassList.previewList.headers; trackBy: gs.trackByIndex">
											{{column}}
										</th>
									</tr>
									</thead>
								</ng-template>
								<!--sticky header clone (fixed)-->
								<table style="width: 100%; table-layout: fixed; font-size: 14px; border-bottom: 1px solid #cccccc; text-align: left;">
									<ng-template [ngTemplateOutlet]="headerContainer"></ng-template>
								</table>
								<!---->
								<virtual-scroller class="f-1-0" #previewListScroll [items]="activeMassList.previewList.contacts" [scrollAnimationTime]="0" [enableUnequalChildrenSizes]="true">
									<table style="width: 100%; table-layout: fixed;">
										<!--original header (hidden)-->
										<ng-template [ngTemplateOutlet]="headerContainer"></ng-template>
										<!---->
										<tbody #container>
										<tr *ngFor="let row of previewListScroll.viewPortItems">
											<td class="ellipsis" *ngFor="let column of activeMassList.previewList.headers" tooltip="Click to copy"
												(click)="copyKeyToClipboard(row[column])">{{column === 'mfa_password' ? row.default_password.password : column === 'mfa_hint' ? row.default_password.hint : row[column]}}</td>
										</tr>
										</tbody>
									</table>
								</virtual-scroller>
							</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!activeMassList" class="flxClmn-center" style="align-items: center; flex: 1 0;">
                    <i class="fas hvr-opacity fa-list-alt" style="font-size: 4vw;  opacity: 0.1;"></i>
                </div>
            </div>
        </div>
    </div>
</div>
