import _ from 'lodash';
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {GeneralService} from "../../services/generalService";
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {DICTIONARY} from "../../dictionary";

@Component({
	selector: 'users-selection-component',
	templateUrl: './users-selection.component.html',
})

export class UsersSelectionComponent implements OnInit{
	@Input() data;
	@Output() addCb = new EventEmitter<any>;
	@Output() removeCb = new EventEmitter<any>;
	@Output() closeCb = new EventEmitter<any>;

	@Input() selectedUsers: any[];

	constructor(public gs: GeneralService,
				private rs: RouteService,
				private ns: NotificationService) {
	}

	dic = DICTIONARY;
	_=_;
	applyUsersInProcess;
	planUsersOriginal;
	planUsers;
	usersToDisplay;

	ngOnInit() {
		this.applyUsersInProcess = true;
		this.rs.getUsersOfSharedPlan({onlyUsersAndSubAdmins: true}).then((users) => {
			this.planUsersOriginal = _.map(users, u => {
				u.selected = false;
				return u;
			});

			this.usersToDisplay = _.clone(this.planUsersOriginal);

			// if no users selected
			if (!this.selectedUsers.length) {
				_.map(this.usersToDisplay, f => {
					f.selected = false;
					return f;
				});
			}
			// if there is users selected - mark them
			else {
				_.map(this.usersToDisplay, f => {
					f.selected = _.includes(this.selectedUsers, f.email);
					return f;
				});
			}

			this.applyUsersInProcess = false;
		}, (err) => {
			this.applyUsersInProcess = false;
		});
	}

	applyUsersFromPopup() {
		this.applyUsersInProcess = true;
		let usersToAdd = [];
		let usersToRemove = [];
		this.usersToDisplay.forEach(itm => {
			if (itm.selected && !_.includes(this.selectedUsers, itm.email)) {
				usersToAdd.push(itm.email);
			}
			else {
				if (!itm.selected && _.includes(this.selectedUsers, itm.email)) {
					usersToRemove.push(itm.email);
				}
			}
		})

		if (!usersToAdd && !usersToRemove) {
			this.ns.showWarnMessage(this.dic.ERRORS.atLeastOneReportUser);
			return;
		}

		if (this.addCb && usersToAdd.length > 0) {
			this.addCb.emit({users: usersToAdd});
		}

		if (this.removeCb && usersToRemove.length > 0) {
			this.removeCb.emit({users: usersToRemove});
		}
		this.applyUsersInProcess = false;
		this.data.show = false;
	};

	closeUsersPopup() {
		this.data.show = false;

		if (this.closeCb.observed) {
			this.closeCb.emit();
		}
	};

	searchUsers = (searchTerm, activeFilters) => {
		this.usersToDisplay.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}
			record.hide = false;
		});
	};
}

function searchTextExecute(user, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((user.email && user.email.toLowerCase().indexOf(searchTerm) > -1));
}
