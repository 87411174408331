<div class="flxClmn" style="position: relative;">
	<div class="flxClmn-around" style="height: 35vh; position: sticky; transition: max-height 0.25s; background: linear-gradient(0deg, rgb(217,255,223), rgb(255,255,255)), url('images/supportBg.jpg'), linear-gradient(0deg, #ffffff, #ffffff); background-size: cover; align-items: center; padding: 3vh; z-index: 3; top: 0;"
		 [ngStyle]="pageModule === dic.CONSTANTS.supportPageModules.main ? {'max-height' : '265px'} : {'max-height' : '80px'}">
            <span style="opacity:0; transition: opacity 0.15s ease 0s; font-size: calc(1.2rem + 1vw); color: rgb(72, 109, 181); top: -2vh; position: relative;"
				  [ngStyle]="pageModule === dic.CONSTANTS.supportPageModules.main ? {'opacity' : '1'}: {'opacity' : '0'}">
                How can we help you?
            </span>
		<!--Search-->
		<div style="position: absolute; top: 50%; transform: translateY(-50%); z-index: 50;" (clickOutside)="showResultModal = false;">
			<input class="support-pages-search-input" style="padding: 0 20px 0 40px; margin: 0; height: calc(1.3rem + 1.3vw); min-height: calc(1.3rem + 1.3vw); width: 30vw; min-width: 350px; font-size: calc(0.5rem + 0.5vw); border: none; border-radius: 50px; box-shadow: 0 0 5px darkgrey;"
				   placeholder="Type keywords to find information"
				   maxlength="100"
				   type="search"
				   [(ngModel)]="searchQuery"
				   (keyup)="searchInLibraries(searchQuery)">
			<i class="fas fa-search" style="position: absolute; top:0; left: 0; float: left; transform: translate(15px,15px); font-size: calc(0.5rem + 0.5vw); color: #b5b3b3; opacity: 0.8;"></i>


			<div class="vSpace2 custom-fadeIn" style="overflow: auto; position: absolute; opacity: 1; transform: translateY(3px); background: white; width: 30vw; min-width: 350px; border-radius: 10px; height: 40vh; min-height: 270px; padding: 1vw; z-index: 200; box-shadow: 0 0 10px darkgrey;"
				 *ngIf="showResultModal">
				<div *ngFor="let result of searchResultsList"
					 (click)="clickOnSearchResult(result)"
					 style="border-bottom: solid 1px lightgrey; padding: 18px 0; white-space: normal; overflow: hidden; text-overflow: ellipsis; cursor: pointer;" class="flxClmn vSpace1">
                        <span style="color: grey;"
							  [highlightText]="searchQuery"
							  [isHighlightOn]="true">
							{{result.sourceLibrary === "What's New" ? "What's New" : ''}}
							{{result.sourceLibrary.title === "Frequently Asked Questions" ? 'FAQ' : result.sourceLibrary.title}}
                        </span>
					<span style="color: #486db5; font-weight: bold; font-size: 1rem;"
						  [innerHtml]="result.closestTitle || result.secondClosestTitle"
						  [highlightText]="searchQuery"
						  [isHighlightOn]="true">
						{{result.closestTitle || result.secondClosestTitle}}
					</span>
					<span *ngIf="result.endContent"
						  style="font-size: 1rem; white-space: nowrap; overflow: hidden; color: gray; text-overflow: ellipsis; pointer-events: none;"
						  [innerHTML]="result.endContent[0]"
						  [highlightText]="searchQuery"
						  [isHighlightOn]="true">
					</span>
				</div>
				<span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 1rem; opacity: 0.5;" *ngIf="!searchInProcess && !searchResultsList.length">No results</span>
			</div>
		</div>

		<div style="opacity:0; font-size: calc(0.6rem + 0.85vh); color: rgb(46, 69, 115); text-align: center; margin-top: 5vh; "
			 [ngStyle]="pageModule === dic.CONSTANTS.supportPageModules.main ? {'opacity' : '1' } : {'opacity' : '0'}">
			<span>
				Can't find what you're looking for? <a style="text-decoration: underline;" class="blueLink" target="_blank" href="https://docs.trustifi.com/docs">Click here</a> to visit our technical docs page
			</span>
		</div>
	</div>


	<div style="background: #f3f3f3; position: relative;" class="flxRow-center">
		<div style=" width: 70%; background: white; z-index: 2;">

			<!--Main Page Module-->
			<div style=" min-height: 60vh; padding: calc(20px + 3vw) calc(15px + 4vw);" class="flxRow"
				 *ngIf="pageModule === dic.CONSTANTS.supportPageModules.main">
				<div style=" flex: 1 0; margin-right: 25px;">
					<h3 style="cursor: pointer; font-weight: 500; color: #484848;"
						(click)="goToInfoPage(infoLibraries.FAQ, null)">
						FAQ
					</h3>
					<div *ngFor="let subject of faqLib | slice:0:3; let last = last;">
						<div style=" padding: calc(10px + 1vw); font-weight: 400;" class="vSpace5">
							<h4 style="width: fit-content; color: #486db5; font-weight: 400;">{{subject.title}}</h4>
							<div class="vSpace3">
								<h5 *ngFor="let question of subject.infoBlocks | slice:0:3"
									(click)="goToInfoPage(infoLibraries.FAQ, question.Q)"
									class="support-page-line" style=" color: #484848; font-weight: 400; cursor: pointer;">{{question.Q}}</h5>
								<h6 class="hvr-darken" style="color: #486db5; font-weight: 400; cursor: pointer; opacity: 0.6;"
									(click)="goToInfoPage(infoLibraries.FAQ, subject.title)">
									Read More &gt;
								</h6>
							</div>
						</div>
						<hr *ngIf="!last" style="width: 55%; opacity: 0.2;">
					</div>
					<btn-c class="popup-action-btn"
						   style="font-size: 1rem; align-self: center; margin-top: 35px; padding: 0 calc(10px + 1vw);"
						   (action)="goToInfoPage(infoLibraries.FAQ, null);">
						Go to FAQ page
					</btn-c>
				</div>
				<div class="vertical-separator" style=" margin: 0 15px; opacity: 0.2; height: auto;"></div>
				<div style=" flex: 1 0; margin-right: 25px;">
					<h3 style="cursor: pointer; font-weight: 500; color: #484848;"
						(click)="goToInfoPage(infoLibraries.knowledgeBase,null)">
						Knowledge Base
					</h3>
					<div *ngFor="let subject of kBase | slice:0:3; let last = last;">
						<div style=" padding: calc(10px + 1vw); font-weight: 400;" class="vSpace5">
							<h4 style=" color: #486db5; font-weight: 400;">{{subject.title}}</h4>
							<div class="vSpace3">
								<h5 *ngFor="let infoBlock of _.filter(subject.infoBlocks,'title') | slice:0:3"
									(click)="goToInfoPage(infoLibraries.knowledgeBase, infoBlock.title)"
									class="support-page-line" style=" color: #484848; font-weight: 400; cursor: pointer;">
									{{infoBlock.title}}
								</h5>
								<h6 class="hvr-darken" style="color: #486db5; font-weight: 400; cursor: pointer; opacity: 0.6;"
									(click)="goToInfoPage(infoLibraries.knowledgeBase, subject.title)">
									Read More &gt;
								</h6>
							</div>
						</div>
						<hr *ngIf="!last" style="width: 55%; opacity: 0.2;">
					</div>
					<btn-c class="popup-action-btn"
						   style="font-size: 1rem; align-self: center; margin-top: 35px; padding: 0 calc(10px + 1vw);"
						   (action)="goToInfoPage(infoLibraries.knowledgeBase, null);">
						Go to Knowledge Base
					</btn-c>
				</div>
			</div>

			<!--Info Page Module-->
			<div style="padding: calc((20px + 3vw)/2) calc((15px + 4vw)/2); line-height: 2; font-size: 1.05rem; font-weight: 400; min-height: 80vh; font-family: sans-serif;" class="w100 animated fadeIn"
				 *ngIf="pageModule === dic.CONSTANTS.supportPageModules.info">
				<!--breadcrumb-->
				<div class="hSpace05" style="font-size: 0.9rem;">
					<a (click)="pageModule = dic.CONSTANTS.supportPageModules.main">Support</a>
					<span>></span>
					<span>{{selectedLibrary.title}}</span>
				</div>
				<hr/>
				<div style="padding: calc((20px + 3vw)/2) calc((15px + 4vw)/2);">
					<div *ngFor="let subject of selectedLibrary.libraryObj; let first = first; let last = last;">
						<h3 id="{{subject.title.replaceAll(' ','_')}}" style="font-weight: 500; color: #484848;"
							[ngStyle]="!first && {'margin-top':'4.5rem'}"
							[highlightText]="highlightSearchText"
							[isHighlightOn]="highlightSearchResults">
							{{subject.title}}
						</h3>
						<div *ngFor="let infoBlock of subject.infoBlocks; let first = first; let last = last;">
							<h4 id="{{infoBlock.title?.replaceAll(' ','_') || infoBlock.Q?.replaceAll(' ','_') || infoBlock.subtitle?.replaceAll(' ','_')}}"
								*ngIf="infoBlock.title || infoBlock.Q || infoBlock.subtitle"
								style=" color: #486db5; font-weight: 400; margin: 4.5rem 0 1.25rem;"
								[ngStyle]="{'margin-top' : first ? '2.5rem' : '4.5rem'}"
								[ngClass]="infoBlock.subtitle && 'knowledge-page-subtitle'"
								[highlightText]="highlightSearchText"
								[isHighlightOn]="highlightSearchResults">
								{{infoBlock.title || infoBlock.subtitle || infoBlock.Q}}
							</h4>
							<p class="bs-enabled" *ngFor="let infoPhrase of (infoBlock.info || infoBlock.A)"
							   [innerHTML]="infoPhrase"
							   [highlightText]="highlightSearchText"
							   [isHighlightOn]="highlightSearchResults">
							</p>
						</div>
						<hr *ngIf="!last" style="width: 55%; opacity: 0.2;">
					</div>
				</div>
			</div>

			<!--What's New Page Module-->
			<div style="padding: calc((20px + 3vw)/2) calc((15px + 4vw)/2); line-height: 2; font-size: 1.05rem; font-weight: 400; min-height: 80vh; font-family: sans-serif;" class="w100 animated fadeIn"
				 *ngIf="pageModule === dic.CONSTANTS.supportPageModules.timeScale">
				<!--breadcrumb-->
				<div class="hSpace05" style="font-size: 0.9rem;">
					<a (click)="pageModule = dic.CONSTANTS.supportPageModules.main">Support</a>
					<span>></span>
					<span>What's New?</span>
				</div>
				<hr/>
				<span class="color-primary" style="font-weight: bold; font-size: 1.3rem; width: 100%; text-align: center; display: block; padding: 1vh 0;">What's New?</span>
				<div style="padding: calc((20px + 3vw)/2) calc((15px + 4vw)/2); font-family: 'Roboto', sans-serif;">
					<div class="flxRow" *ngFor="let newFeaturesBundle of whatsNewData"
						 id="{{newFeaturesBundle.date | date: 'MM/dd/yyyy'}}">
						<div style="flex-shrink: 0;">
							<span style="font-weight: bold; white-space: nowrap; margin-top: -4px; display: block;">{{newFeaturesBundle.date | date: 'MM/dd/yyyy'}}</span>
						</div>
						<div style="position: relative; padding: 0 2vw;">
							<div style="position: absolute; top: 0; left: 50%; transform: translateX(-50%); z-index: 50; border-radius: 50px; border: solid 6px var(--bs-primary); background: hsl(var(--bs-primary-h), 100%, 95%); height: 23px; width: 23px;"></div>
							<div class="h100" style="border-left: dotted 4px #e3e3e3;"></div>
						</div>
						<div style="flex: 1 0; padding-bottom: 100px;">
							<div class="flxClmn" style="padding: 25px; box-shadow: 0 4px 4px rgba(0,0,0,0.07); background: linear-gradient(-45deg, hsl(var(--bs-primary-h), var(--bs-primary-s), 98%), hsl(var(--bs-primary-h), var(--bs-primary-s), 99%) 80%, hsla(var(--bs-primary-h), var(--bs-primary-s), 95%, 0));">
								<span style="font-weight: bold;">{{newFeaturesBundle.date | date:'fullDate'}}</span>
								<ul>
									<li *ngFor="let newFeature of newFeaturesBundle.features; trackBy: gs.trackByIndex"
										[innerHTML]="newFeature"
										[highlightText]="highlightSearchText"
										[isHighlightOn]="highlightSearchResults"></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--scroll to top button-->
	<div (click)="scrollToTop()"
		 *ngIf="showScrollToTop"
		 class="animated fadeIn scroll-top-btn">
		<i class="fas fa-angle-up"></i>
	</div>
</div>


