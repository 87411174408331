import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import _ from 'lodash';
import util from 'util';
import {GeneralService} from "../../services/generalService";
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {DICTIONARY} from "../../dictionary";
import {DatePipe} from "@angular/common";

@Component({
	selector: 'exchange-quarantined-emails-wizard-c',
	templateUrl: './exchange-quarantined-emails-wizard.component.html',
})
export class ExchangeQuarantinedEmailsWizardComponent implements OnInit {
	@Input() isInboundConfiguration: boolean;
	@Output() closePopupFn: EventEmitter<any> = new EventEmitter<any>();

	constructor(private rs: RouteService,
				public gs: GeneralService,
				private ns:NotificationService) {}

	dic = DICTIONARY;
	_ = _;

	// environment
	getCfgDataInProcess = false;
	checkIfDataAlreadyExistInProcess = true;
	validSteps = [DICTIONARY.CONSTANTS.reviewExchangeRulesSteps.generalInformation, DICTIONARY.CONSTANTS.reviewExchangeRulesSteps.results];
	step = DICTIONARY.CONSTANTS.reviewExchangeRulesSteps.generalInformation;
	errorMsg = '';
	getCfgDataStatus;
	statusTimestamp;
	finalResults;
	isDataRestored;
	generateTokenInProcess;
	identifier;
	token;
	disconnected;

	closePopup = () => {
		let success = this.getCfgDataStatus && this.getCfgDataStatus.status === 'success';
		this.closePopupFn.emit({success, disconnected:this.disconnected});
	}

	ngOnInit() {
		this.checkIfDataAlreadyExistInProcess = true;
		this.rs.exchangeConfigurationStatus({type: this.isInboundConfiguration ? 'quarantine.inbound' : 'quarantine.outbound'}).then(response => {
			if (response.status !== 'N/A' && response.status !== 'failure') {
				this.getCfgDataStatus = response.status; // ="success"
				this.statusTimestamp = response.timestamp; // ="success"

				if (response.data) {
					this.finalResults = this.prepareFinalResults(response.data);
					this.isDataRestored = true;
					this.step = DICTIONARY.CONSTANTS.reviewExchangeRulesSteps.results;
					this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.viewExchangeLastDataFound, (new DatePipe('en-US')).transform(this.statusTimestamp || '', 'MM/dd/yyyy HH:mm')));
				}
				else {
					this.errorMsg = response.error;
				}
			}
			this.checkIfDataAlreadyExistInProcess = false;
		}, (err) => {
			this.checkIfDataAlreadyExistInProcess = false;
		});
	}

	exchangeGetQuarantinedEmails = () => {
		this.identifier = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5);
		this.generateTokenInProcess = true;
		this.getCfgDataInProcess = true;

		this.rs.exchangeGetQuarantinedEmails({isInboundConfiguration: this.isInboundConfiguration, state: this.identifier}).then(response => {
			this.generateTokenInProcess = false;

			this.getCfgDataInProcess = false;
			this.getCfgDataStatus = response.status; // ="success"
			this.statusTimestamp = response.timestamp; // ="success"

			if (response.data) {
				this.finalResults = this.prepareFinalResults(response.data);
			}
			else {
				this.finalResults = {title: 'Error', error: response.error};
				this.errorMsg = response.error;
			}
		}, err => {
			if (!err.data.display_bar) {
				this.errorMsg = err.data.message;
			}

			this.generateTokenInProcess = false;
			this.getCfgDataInProcess = false;
			this.getCfgDataStatus = err.data.status;
			this.disconnected = err.data.disconnected;
		});
	}

	backStep = () => {
		this.errorMsg = '';

		switch (this.step) {
			case DICTIONARY.CONSTANTS.reviewExchangeRulesSteps.results:
				if (this.generateTokenInProcess) {
					this.errorMsg = DICTIONARY.ERRORS.waitForTokenGenerate;
					return;
				}

				this.step = DICTIONARY.CONSTANTS.reviewExchangeRulesSteps.generalInformation;
				break;

			default:
				return;
		}
	}

	nextStep = () => {
		this.errorMsg = '';
		switch (this.step) {
			case DICTIONARY.CONSTANTS.reviewExchangeRulesSteps.generalInformation:
				this.step = DICTIONARY.CONSTANTS.reviewExchangeRulesSteps.results;
				this.exchangeGetQuarantinedEmails();
				break;

			default:
				return;
		}
	}

	restart = () => {
		this.isDataRestored = false;
		this.step = DICTIONARY.CONSTANTS.reviewExchangeRulesSteps.generalInformation;
		this.finalResults = null;
		this.getCfgDataStatus = null;
		this.statusTimestamp = null;
	}

	prepareFinalResults = (quarantinedEmails) => {
		const title = 'Quarantine';
		const trustifiTableHeadersArr = [];
		let trustifiTableCellsArr = [];

		if (quarantinedEmails && quarantinedEmails.length) {
			Object.keys(quarantinedEmails[0]).forEach(headerKey => {
				if (!trustifiTableHeadersArr.some(h => h.sortKey === headerKey)) {
					const formatted = this.beautifyTitles(headerKey);
					trustifiTableHeadersArr.push({text: formatted, sortKey: headerKey});
					trustifiTableCellsArr.push({textKey: headerKey});
				}
			});
		}

		let options = {tableHeaders: trustifiTableHeadersArr, tableCells: trustifiTableCellsArr};
		return {title, value:quarantinedEmails, options};
	}

	beautifyTitles = (title) => {
		let newTitle = title.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
		newTitle = newTitle.replace(/([A-Z])([A-Z])([a-z])/g, '$1 $2$3').trim();

		return newTitle;
	}

	showQuarantinedActions = (quarantinedObj) => {
		return Object.values(DICTIONARY.CONSTANTS.exchangeQuarantineActions);
	}

	doQuarantinedAction = (quarantinedObj, actionObj) => {

		//do actions:
		/*
		this.rs.exchangeGetQuarantinedEmails(params)
		this.rs.exchangeGetQuarantinedEmailData(quarantinedId, params)
		this.rs.exchangeGetQuarantinedEmailHeaders(quarantinedId, params)
		this.rs.exchangeDeleteQuarantinedEmail(quarantinedId, params)
		this.rs.exchangeDoActionOnQuarantinedEmail(quarantinedId, data, params)
		*/

		this.checkIfDataAlreadyExistInProcess = true;

		let data = {Identities: [quarantinedObj.Identity], Action: actionObj.action, ActionType: actionObj.actionType};

		this.rs.exchangeDoActionOnQuarantinedEmail(data).then(res => {
			this.checkIfDataAlreadyExistInProcess = false;
		}, err => {
			this.finalResults = err.data;
			this.checkIfDataAlreadyExistInProcess = false;
		});
	};
}
