import {
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {DICTIONARY} from '../../../dictionary';
import {GeneralService} from "../../../services/generalService";

@Component({
	selector: 'search-box',
	templateUrl: './search-box.component.html',
})
export class SearchBoxComponent {
	constructor(
		public gs:GeneralService,) {
	}
	@Input() isDisabled: boolean;
	@Input() model: any;
	@Input() placeholder?: string;
	@Output() modelChange = new EventEmitter();

	dic = DICTIONARY;
	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5);
}
