import { DICTIONARY } from '../../../../dictionary';
import { AccountCompromisedService } from '../../../../services/accountCompromisedService';
import { GeneralService } from '../../../../services/generalService';
import { NotificationService } from '../../../../services/notificationService';
import { RouteService } from '../../../../services/routeService';
import _ from 'lodash';
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'account-compromised-cfg-component',
	templateUrl: './account-compromised-configurations.component.html',
})
export class AccountCompromisedConfigurationsComponent implements OnInit {
	dic = DICTIONARY;
    usersSelectionTypes = {all: 'all', specific: 'specific'};
	users;
	accountCompromisedConfig;
	incidentTypes;
	currentIncidentTypes;
	selectUsersPopup;
	applyUsersInProcess = false;
	getAccountCompromisedInProcess;
	_=_;

	constructor(private accountCompromisedService:AccountCompromisedService,
				private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService) {
	}

	ngOnInit() {
		this.getAccountCompromisedInProcess = true;
		this.rs.getAccountCompromisedConfiguration().then(response => {
			this.users = response.users;
			this.accountCompromisedConfig = response.account_compromised;
			this.prepareIncidentTypes();
			this.getAccountCompromisedInProcess = false;
		}, () => {
			this.getAccountCompromisedInProcess = false;
		});
    }

	prepareIncidentTypes() {
		this.incidentTypes = {};

		// set selected for actions for incident types
		_.each(this.accountCompromisedConfig.incident_types, (typeObj, typeName) => {
			let incidentActions = [];
			typeObj.displayActions = [];

			// !!-- to activate/deactivate actions : note/un-note them in dictionary --!!
			_.each(this.dic.CONSTANTS.accountCompromisedRulesSubActions, (actionData) => {
				const actionObj = {name: actionData.name, display: actionData.display, selected: false, tooltip: actionData.tooltip};
				if (typeObj.actions.includes(actionData.name)) {
					actionObj.selected = true;
					typeObj.displayActions.push(actionData.display)
				}
				incidentActions.push(actionObj);
			});
			this.incidentTypes[typeName] = {users: [], actions: incidentActions, actionTxt: typeObj.displayActions };
		});

		// get users for each incident
		this.users.forEach((user) => {
			if (user.misc.account_compromised && user.misc.account_compromised.incident_types) {
				_.each(user.misc.account_compromised.incident_types, (typeObj, typeName) => {
					this.incidentTypes[typeName].users.push({
						name: user.name || '',
						email: user.email,
						selected: typeObj.enabled
					});
				});
			}
		});

		// set text for "users" boxes in UI
		_.each(this.accountCompromisedConfig.incident_types, (typeObj, typeName) => {
			this.setEnabledUsersText(typeName);
		})

		// Determine which incidents are displayed in this configuration page:
		this.currentIncidentTypes = _.pickBy(this.incidentTypes, (typeObj, typeName) => {
			return (["suspicious_device_change" , "suspicious_location_change" , "sending_unusual_amount_of_emails" , "sending_sensitive_to_new_domains", "sending_sensitive_to_personal_email", "email_breach", "high_recipient_compliant_rate", "high_recipient_hard_bounce_rate"].indexOf(typeName) !== -1);
		});
	};

	setEnabledUsersText(incidentKey) {
	    if (this.accountCompromisedConfig.incident_types[incidentKey].apply_all_users) {
			this.accountCompromisedConfig.incident_types[incidentKey].enabledUsersText = 'All users';
	        return;
        }

		const enabledUsers = _.filter(this.incidentTypes[incidentKey].users, 'selected');
		let text;
		if (!enabledUsers.length) {
			text = 'Select users';
		}
		else {
			text = _.map(enabledUsers, 'email').join(', ');
		}

		this.accountCompromisedConfig.incident_types[incidentKey].enabledUsersText = text;
	}

	openSelectUsersPopup(incidentType) {
		const popupUsers = _.cloneDeep(this.incidentTypes[incidentType].users);
        popupUsers.forEach(user => {
            user.hide = false;
        });

        const selectUsersType = this.accountCompromisedConfig.incident_types[incidentType].apply_all_users ? this.usersSelectionTypes.all : this.usersSelectionTypes.specific;

        this.selectUsersPopup = {
            incidentType: incidentType,
            selectUsersType: selectUsersType,
            users: popupUsers,
            show: true,
            errMsg: ''
        }
	}

	searchUser = (event) => {
		this.selectUsersPopup.users.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = this.searchUserTextExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	searchUserTextExecute(user, searchTerm) {
		searchTerm = searchTerm.toLowerCase();
		return ((user.name && user.name.toLowerCase().indexOf(searchTerm) > -1) ||
			(user.email && user.email.toLowerCase().indexOf(searchTerm) > -1));
	}

	applyUsersToIncidentType() {
		if (this.applyUsersInProcess) return;

        const enableEmails = _.map(_.filter(this.selectUsersPopup.users, user => {return user.selected}),'email');
        const disableEmails = _.map(_.filter(this.selectUsersPopup.users, user => {return !user.selected}), 'email');
        const isApplyAllUsers = this.selectUsersPopup.selectUsersType === this.usersSelectionTypes.all;

		const data = {
			action: this.dic.CONSTANTS.accountCompromisedUsersActions.incidentTypes,
			type: this.selectUsersPopup.incidentType,
            applyAllUsers: isApplyAllUsers,
			enableEmails: enableEmails,
			disableEmails: disableEmails
		};

		this.applyUsersInProcess = true;

		this.rs.updateAccountCompromisedUsers(data).then(response => {
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
			this.incidentTypes[this.selectUsersPopup.incidentType].users = _.cloneDeep(this.selectUsersPopup.users);
			const applyToAll = this.selectUsersPopup.selectUsersType === this.usersSelectionTypes.all;
            this.accountCompromisedConfig.incident_types[this.selectUsersPopup.incidentType].apply_all_users = applyToAll;
            if (applyToAll) {
                // in the next popup opening the users will be all selected for more understandable UX
                this.incidentTypes[this.selectUsersPopup.incidentType].users.forEach(user => {
                    user.selected = true;
                });
            }
			this.setEnabledUsersText(this.selectUsersPopup.incidentType);
			this.applyUsersInProcess = false;
            this.selectUsersPopup = null;
		});
	}

	changeIncidentActions(incidentType) {
		if (!incidentType) return;
		let actions = [];
		const displayActions = [];
		this.currentIncidentTypes[incidentType].actions.forEach((actionObj) => {
			if (actionObj.selected) {
				actions.push(actionObj.name);
				displayActions.push(actionObj.display);
			}
		});

		const data = {
			action: this.dic.CONSTANTS.accountCompromisedConfigActions.incidentTypesActions,
			type: incidentType,
			actions: actions
		};

		if (actions.includes(this.dic.CONSTANTS.accountCompromisedRulesSubActions.blockUser.name) &&
			actions.includes(this.dic.CONSTANTS.accountCompromisedRulesSubActions.blockUser24hours.name)) {
			this.ns.showErrorMessage('"Block User" and "Block for 24 hours" cannot be selected together');
			return;
		}

		this.rs.updateAccountCompromisedConfig(data).then(response => {
			this.incidentTypes[incidentType].actionTxt = displayActions;
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		});
	}
}
