<div class="flex-column f-1-0">
	<ng-container *ngFor="let condition of conditionArr; let first = first; let orIndex = index">
		<span class="fw-bold opacity-75" style="color: #486db5; margin: 8px 5px;" *ngIf="!first">OR</span>
		<ng-container *ngTemplateOutlet="conditionHtml; context: {$implicit: condition, orIndex}"></ng-container>
	</ng-container>
</div>

<ng-template #conditionHtml let-condition let-orIndex="orIndex">
	<div class="flex-row gap-4 align-items-center f-1-0">

		<!--Select condition type-->
		<app-dropdown-c style="width: 180px; --dropdown-menu-width: 430px;"
						[isDisabled]="condition.type === rulesOptions.types.keywords.name && condition._id && condition.data && condition.data.file"
						text="{{rulesOptions.types[condition.type].label}}">
			<ul style="columns: 2;">
				<li *ngFor="let item of rulesOptions.types | keyvalue: gs.returnZero"
					(click)="changeConditionType(condition, item.key)"
					[explanationC]="rulesOptions.types[item.key].tooltip">
					{{rulesOptions.types[item.key].label}}
				</li>
			</ul>
		</app-dropdown-c>

		<ng-container>
			<span class="text-nowrap">detected in</span>

			<!--Select condition subtype (single)-->
			<div *ngIf="condition.singleSubType">
				<app-dropdown-c class="animated fadeIn" style="width: 150px;"
								*ngIf="condition.subType[0] !== rulesOptions.subTypes.freeText"
								text="{{condition.subType[0]}}"></app-dropdown-c>
				<input type="text" class="lowerPlaceholder" maxlength="100"
					   style="width: 150px;"
					   *ngIf="condition.subType[0] === rulesOptions.subTypes.freeText"
					   placeholder="{{condition.placeholder}}"
					   [(ngModel)]="condition.values.key"
					   (ngModelChange)="isConditionDataChanged(condition);"
					   [ngClass]="condition.invalidHeaderKey && 'validation-error'"/>
			</div>
			<!--Select condition subtype (multiple)-->
			<div *ngIf="!condition.singleSubType">
				<app-dropdown-c style="width: 150px;" id="{{'rulesPoliciesDropdownSubType' + conditionIndex}}"
								[error]="condition.invalidSubTypes"
								text="{{condition?.subTypeShow?.join(', ') || 'Choose'}}">
					<ul>
						<li *ngFor="let item of rulesOptions.types[condition.type].subTypes| keyvalue: gs.returnZero"
							[explanationC]="condition.type === rulesOptions.types.keywords.name ? item.key === 'body' ? 'Email body - including file names' : 'Email subject' : ''"
							(click)="condition.subType[item.key] = !condition.subType[item.key]; subtypeChanged(condition); $event.stopPropagation();"
							id="{{'rulesPoliciesSubTypeValue' + item.key + conditionIndex}}">
							<div class="gap-1 md-checkbox">
								<input type="checkbox" onclick="return false;" [checked]="condition.subType[item.key]"/>
								<label><span>{{item.key}}</span></label>
							</div>
						</li>
					</ul>
				</app-dropdown-c>
			</div>
		</ng-container>

		<!--connection word(s)-->
		<a class="flex-row text-nowrap blue-link text-decoration-underline"
		   [ngClass]="{'text-danger' : condition.is_negate}"
		   (click)="condition.is_negate = !condition.is_negate;">
			<span>{{rulesOptions.types[condition.type].isPlural ? 'are' : 'is'}}</span>
			<span class="fw-500" *ngIf="condition.is_negate">&nbsp;NOT</span>
		</a>

		<!--match type dropdown-->
		<app-dropdown-c *ngIf="condition.type === rulesOptions?.types?.addresses?.name || condition.type === rulesOptions?.types?.keywords?.name ||
							   condition.type === rulesOptions?.types?.domains?.name || condition.type === rulesOptions?.types?.email_header?.name ||
							   condition.type === rulesOptions?.types?.email_scan_status?.name || condition.type === rulesOptions?.types?.names?.name"
						style="width: 127px;"
						(click)="showMatchActions(condition);"
						text="{{condition?.data?.matchType}}">
			<ul>
				<li *ngFor="let matchTypeVal of condition.actions"
					[explanationC]="dic.CONSTANTS.ruleMatchTypeTooltip[matchTypeVal]"
					(click)="changeMatchType(matchTypeVal, condition)">
					{{matchTypeVal}}
				</li>
			</ul>
		</app-dropdown-c>
		<!----><!---->

		<!--select values-->
		<ng-container [ngSwitch]="condition.type">

			<!--keywords-->
			<app-dropdown-c *ngIf="condition.type === rulesOptions.types.keywords.name && (condition?.data?.matchType === dic.CONSTANTS.ruleMatchType.exactMatch || condition?.data?.matchType === dic.CONSTANTS.ruleMatchType.contains)"
							style="width: 200px;" [ngStyle]="{'color' : condition?.data?.keywords?.length ? '' : 'darkgrey', 'font-size': condition?.data?.keywords?.length ? '' : '11.4px'}"
							(click)="showPopup(condition);" tooltip="Click to view and edit"
							[isDisabled]="condition.type === rulesOptions.types.keywords.name && condition?._id && condition?.data && condition?.data?.file"
							text="{{condition?.data?.keywords?.join(' , ') || 'Click to add Keywords'}}"
							[error]="condition?.invalidKeywords">
			</app-dropdown-c>

			<!--addresses-->
			<app-dropdown-c *ngIf="condition.type === rulesOptions.types.addresses.name &&
								   [dic.CONSTANTS.ruleMatchType.exactMatch, dic.CONSTANTS.ruleMatchType.startsWith, dic.CONSTANTS.ruleMatchType.endsWith, dic.CONSTANTS.ruleMatchType.contains].indexOf(condition?.data?.matchType) > -1"
							style="width: 200px;" tooltip="Click to view and edit"
							[ngStyle]="{'color' : condition?.data?.emails?.length ? '' : 'darkgrey', 'font-size': condition?.data?.emails?.length ? '' : '11.4px'}"
							(click)="showPopup(condition);"
							text="{{condition?.data?.emails?.join(' , ') || 'Click to add email addresses'}}"
							[error]="condition.invalidEmails"></app-dropdown-c>

			<!--names-->
			<app-dropdown-c *ngIf="condition.type === rulesOptions.types.names.name &&
								   [dic.CONSTANTS.ruleMatchType.exactMatch, dic.CONSTANTS.ruleMatchType.startsWith, dic.CONSTANTS.ruleMatchType.endsWith, dic.CONSTANTS.ruleMatchType.contains].indexOf(condition?.data?.matchType) > -1"
							style="width: 200px;" tooltip="Click to view and edit"
							[ngStyle]="{'color' : condition?.data?.names?.length ? '' : 'darkgrey', 'font-size': condition?.data?.names?.length ? '' : '11.4px'}"
							(click)="showPopup(condition);"
							text="{{condition?.data?.names?.join(' , ') || 'Click to add name'}}"
							[error]="condition.invalidNames"></app-dropdown-c>

			<!--domains-->
			<app-dropdown-c *ngIf="condition.type === rulesOptions.types.domains.name && condition.data.matchType === dic.CONSTANTS.ruleMatchType.exactMatch"
							style="width: 200px;" [ngStyle]="{'color' : condition?.data?.domains?.length ? '' : 'darkgrey', 'font-size': condition?.data?.domains?.length ? '' : '11.4px'}"
							(click)="showPopup(condition);" tooltip="Click to view and edit"
							text="{{condition?.data?.domains?.length ? condition?.data?.domains?.join(', ') : 'Click to add domains'}}"
							[error]="condition.invalidDomains"></app-dropdown-c>

			<!--email header-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.email_header.name"
							style="width: 200px;" [ngStyle]="{'color' : condition?.values?.value?.length ? '' : 'darkgrey', 'font-size': condition?.values?.value?.length ? '' : '11.4px'}"
							(click)="showPopup(condition);" tooltip="Click to view and edit"
							text="{{condition?.values?.value?.length ? condition?.values?.value?.join(', ') : 'Click to add header values'}}"
							[error]="condition?.invalidHeaderValue">
			</app-dropdown-c>

			<!--sender country TLD types-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.sender_country_tld.name"
							style="width: 200px;" [ngStyle]="{'color' : condition?.data?.tld?.length ? '' : 'darkgrey', 'font-size': condition?.data?.tld?.length ? '' : '11.4px'}"
							(click)="showSenderCountryTldPopup(condition);" tooltip="Click to view and edit"
							text="{{condition?.data?.tld?.length ? condition?.data?.tld?.join(', ') : 'Click to add sender country TLD'}}"
							[error]="condition?.invalidSenderCountryTld">
			</app-dropdown-c>

			<!--attachment types-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.attachment_types.name"
							style="width: 200px;" [ngStyle]="{'color' : condition?.data?.attachment_types?.length ? '' : 'darkgrey', 'font-size': condition?.data?.attachment_types?.length ? '' : '11.4px'}"
							(click)="showAttachmentTypesPopup(condition);" tooltip="Click to view and edit"
							text="{{condition?.data?.all_types ? 'any type' : condition?.data?.attachment_types?.length ? condition?.data?.attachment_types?.join(', ') : 'Click to add attachment types'}}"
							[error]="condition?.invalidAttachmentsTypes">
			</app-dropdown-c>

			<!--email_scan_sub_status-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.email_scan_sub_status.name"
							style="width: 200px;" [ngStyle]="{'color' : condition?.data?.tpMetrics?.length ? '' : 'darkgrey', 'font-size': condition?.data?.tpMetrics?.length ? '' : '11.4px'}"
							(click)="showEmailSubStatusScanPopup(condition);" tooltip="Click to view and edit"
							text="{{condition?.data?.tpMetrics?.length ? condition?.data?.tpMetrics.join(', ') : 'Click to add sub category'}}"
							[error]="condition?.invalidScanSubStatusTypes">
			</app-dropdown-c>

			<!--groups-->
			<a *ngSwitchCase="rulesOptions.types.groups.name"
			   class="blue-link"
			   (click)="openGroupsPopup(true, condition)"
			   [ngClass]="{'validation-error': condition.invalidGroups}">
				{{condition.data?.groups && condition.data.groups.length ? condition.data.groups.length + ' groups selected (click to edit)' : 'Select groups'}}
			</a>

			<!--email_size-->
			<div *ngSwitchCase="rulesOptions.types.email_size.name" class="flxRow gap-2" style="align-items: center;">
				<span>bigger than</span>
				<input *ngSwitchCase="rulesOptions.types.email_size.name" type="number"
					   placeholder="size" max="65535" min="1"
					   [(ngModel)]="condition.data.size"> KB
			</div>

			<!--received_time-->
			<div *ngSwitchCase="rulesOptions.types.received_time.name" class="flxRow gap-3" style="align-items: center;">
				<span>between</span>
				<app-dropdown-c style="width: 120px; --bs-dropdown-max-height: 210px; --bs-dropdown-overflow: auto;"
								[error]="condition.invalidReceivedTime && (!condition.data.received_time_start || _.toNumber(condition.data.received_time_start) > _.toNumber(condition.data.received_time_end || 24))"
								[text]="condition.data.received_time_start">
					<li *ngFor="let hour of hours"
						(click)="condition.data.received_time_start = hour; condition.invalidReceivedTime = false;"
						id="fromHour_{{hour}}">
						{{hour}}
					</li>
				</app-dropdown-c>
				<span>and</span>
				<app-dropdown-c style="width: 120px; --bs-dropdown-max-height: 210px; --bs-dropdown-overflow: auto;"
								[error]="condition.invalidReceivedTime && (!condition.data.received_time_end || _.toNumber(condition.data.received_time_start || 0) > _.toNumber(condition.data.received_time_end))"
								[text]="condition.data.received_time_end">
					<li *ngFor="let hour of hours"
						(click)="condition.data.received_time_end = hour; condition.invalidReceivedTime = false;"
						id="fromHour_{{hour}}">
						{{hour}}
					</li>
				</app-dropdown-c>
			</div>

			<!--received_day-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.received_day.name"
							style="width: 200px;"
							[items]="condition.data.received_day"
							(onItemClicked)="condition.invalidReceivedDay = false;"
							[isMultipleSelection]="true"
							itemDisplayKey="name"
							[error]="condition?.invalidReceivedDay">
			</app-dropdown-c>

			<!--regex-->
			<input *ngIf="(condition.type === rulesOptions.types.keywords.name || condition.type === rulesOptions.types.addresses.name) && condition?.data?.matchType === dic.CONSTANTS.ruleMatchType.regex"
				   type="text" maxlength="100" style="width: 150px;"
				   placeholder="regular expression"
				   [(ngModel)]="condition.data.regex"/>
		</ng-container>

		<i class="zmdi zmdi-close text-danger hvr-darken cursor-pointer px-3 py-2 animated fadeIn" tooltip="Remove condition"
		   *ngIf="(condition.isException ? true : newRule.conditions.length > 1) || conditionArr.length > 1"
		   (click)="cancelNewRuleCondition(orIndex, conditionIndex)"></i>
	</div>
</ng-template>

<!--condition values popup-->
<popup-c *ngIf="valuesPopup?.show"
		 head="Add or edit {{valuesPopup.name}}"
		 subTitle="{{valuesPopup.subtitle}}"
		 (closeCb)="valuesPopup.show = false"
		 (confirmCb)="applyPopupCondition(conditionInEdit)"
		 [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}" size="medium">
	<div class="flex-column f-1-0 vSpace2 add-action-and-list" style="overflow: auto;">
		<form novalidate >
			<div class="add-single-item flxRow hSpace1">
				<input class="emailType" style="margin: 0; width: 90%;" required
					   [(ngModel)]="valuesPopup.input"
					   [ngModelOptions]="{standalone: true}"
					   (ngModelChange)="isValueChanged(conditionInEdit);"
					   type="text"
					   placeholder="{{valuesPopup.placeholder}}"
					   (keypress)="handleKeypress($event, conditionInEdit)"
					   [ngClass]="{'disabled': conditionInEdit.type === rulesOptions.types.keywords.name && conditionInEdit._id && conditionInEdit.data && conditionInEdit.data.file}"
				/>
				<btn-c [isDisabled]="!valuesPopup.input || conditionInEdit.invalidInput"
					   (action)="isValueAddedToList(conditionInEdit);">
					Add
				</btn-c>
			</div>
		</form>
		<div class="listTable alternated flxClmn f-1-0">
			<table>
				<thead>
				<tr>
					<th>
						{{ valuesPopup.name.slice(0, -1) }}
					</th>
					<th style="width:50px;">
					</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let record of valuesPopup.currentList">
					<td>
						<div class="name ellipsis">
							<div class="name ellipsis">
								<div tooltip="{{record}}" placement="top">
									{{record}}
								</div>
							</div>
						</div>
					</td>
					<td>
						<a (click)="isValueRemoved(conditionInEdit, record)" class="opener closeRed" style="padding-left: 10px;">
								<span style="padding: 0;">
									<i class="zmdi zmdi-close" style="position: relative;"></i>
								</span>
						</a>
					</td>
				</tr>
				</tbody>
			</table>
			<!-- placeholder for empty table: -->
			<div class="w100 flxClmn-center" style="flex:1 0; text-align: center; color: darkgrey;"
				 *ngIf="!valuesPopup.currentList?.length">
				<span>No {{conditionInEdit?.data?.matchType === dic.CONSTANTS.ruleMatchType.exactMatch ? valuesPopup.name : "values"}} in list</span>
			</div>
			<!-- end -->
		</div>
		<div class="flxRow-start hSpace1 list-csv-action">
			<a style="margin: 0 8px 0 0; padding : 0;" class="hSpace05" (mouseover)="mouseOverAttachment = true;"
			   ngfDrop
			   ngfSelect
			   [(file)]="valuesPopup.file"
			   (fileChange)="importConditionListFromCsv(valuesPopup.file,conditionInEdit)"
			   [(validDrag)]="validDrag"
			   [(invalidDrag)]="invalidDrag"
			   [ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag, 'hover': mouseOverAttachment}"
			   accept='.csv'
			>
				<i class="fas fa-file-import"></i>
				<span class="action-name" tooltip='A column named "{{valuesPopup.name.slice(0,-1)}}" should contain the values'>Import from CSV</span>
			</a>
			<a class="hSpace05" style="margin: 0 0 0 8px; padding : 0;"
			   (click)="exportConditionListToCsv(conditionInEdit)"
			   [ngClass]="{'disabled': !valuesPopup.currentList.length}">
				<i class="fas fa-file-export"></i>
				<span class="action-name">Export to CSV</span>
			</a>
		</div>
	</div>
</popup-c>

<!--attachment type popup-->
<popup-c *ngIf="attachmentTypesPopup?.show"
		 head="Select Attachment Types"
		 width="700px" height="auto"
		 (closeCb)="attachmentTypesPopup = null"
		 (confirmCb)="applyAttachmentTypesPopup();"
		 [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">

	<div class="flex-column f-1-0 vSpace2 danger-message">
		<div style="margin-bottom: 20px;">Rule will be triggered if at least one of the selected attachment types is found in the email's attached files</div>
		<div class="flxRow-between">
			<search-box [isDisabled]="attachmentTypesPopup.all_types"
						[(model)]="attachmentTypesPopup.attachmentTypesSearchTxt"
						(modelChange)="searchAttachmentTypes(attachmentTypesPopup.attachmentTypesSearchTxt,conditionInEdit)"
						placeholder="Search type"
						style="min-width: 325px;">
			</search-box>
			<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
				<input id="aiiAttachmentTypes" type="checkbox"
					   [(ngModel)]="attachmentTypesPopup.all_types"/>
				<label class="m-0" for="aiiAttachmentTypes">All attachment types</label>
			</div>
		</div>

		<div style=" position: relative; display: flex; flex-wrap: wrap; font-size: 15px; max-height: 415px; overflow-y: auto; overflow-x: hidden;">
			<div *ngFor="let item of attachmentTypesPopup.fileTypesList | keyvalue: gs.returnZero"
				 style="width: 50%;">
				<label class="policyCheckbox"
					   (click)="$event.stopPropagation()"
					   style="margin-bottom: 5px;">
					<input type="checkbox"
						   [(ngModel)]="attachmentTypesPopup.attachmentTypesList[item.key].enabled"/>
					<span style="position: relative; font-size: 15px;">&nbsp;</span>
				</label>
				<span>{{item.value.display}}</span>
			</div>
			<div *ngIf="attachmentTypesPopup.all_types" style="position: absolute; top: -15px; left: -15px; width: calc(100% + 15px); height: calc(100% + 15px); background: white; opacity: 0.9; display: flex; justify-content: center; align-items: center;">
				<span style="font-weight: bold;">all attachment types are enabled</span>
			</div>
		</div>
	</div>
</popup-c>

<!--sender country tld popup-->
<popup-c *ngIf="senderCountryTldPopup?.show"
		 head="Sender Country TLD"
		 size="medium"
		 (closeCb)="senderCountryTldPopup.show = false"
		 (confirmCb)="applySenderCountryTldPopup();"
		 [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">

	<div class="flxClmn f-1-0" style="overflow: auto;">
		<trustifi-table-c
			class="trustifi-table" style="flex: 1 0; z-index: 5;"
			[list]="senderCountryTldPopup.senderCountryTlds"
			[headers]="[
				{text:'TLD', sortKey:'tld'},
				{text:'Country', sortKey: 'desc'},
			]"
			[cellsPrototype]="[
				{textKey: 'tld'},
				{textKey: 'desc'},
			]"
			[options]="{
				showRadioButtons: true
			}"
			(searchFunction)="searchSenderCountryTld($event.searchTerm, $event.activeFilters)"
		>
			<!---->
		</trustifi-table-c>
	</div>
</popup-c>

<!--sender country tld popup-->
<popup-c *ngIf="emailScanSubStatusPopup?.show"
		 head="Email Scan Sub Category"
		 size="medium"
		 (closeCb)="emailScanSubStatusPopup.show = false"
		 (confirmCb)="applyEmailScanSubStatusPopup();"
		 [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">

	<div class="flxClmn f-1-0" style="overflow: auto;">
		<trustifi-table-c
			class="trustifi-table" style="flex: 1 0; z-index: 5;"
			[list]="emailScanSubStatusPopup.tpMetrics"
			[headers]="[
				{text: 'Sub Category', sortKey: 'sub_status'},
				{text: 'Category', sortKey: 'status', width: '15%'},
				{text: 'Description', sortKey: 'description'},
			]"
			[cellsPrototype]="[
				{textKey: 'sub_status'},
				{textKey: 'status'},
				{html: 'descCell'},
			]"
			[options]="{
				showRadioButtons: true
			}"
			(searchFunction)="searchScanSubStatus($event.searchTerm, $event.activeFilters)"
		>
			<ng-template #customCells let-item let-cellId="cellId">
				<span style="text-overflow: unset; white-space: normal;" *ngIf="cellId === 'descCell'">
					{{item.description}}
				</span>
			</ng-template>
		</trustifi-table-c>
	</div>
</popup-c>

<groups-popup-c
	*ngIf="showRuleGroups"
	(cancelCb)="showRuleGroups = false"
	(doneCb)="applyGroupsPopup($event.groups)"
	[doneBtnTxt]="'Apply'"
	[selectedGroups]="selectedGroupsRule"
	[multipleSelection]="true"
	[popupTitle]="'Select Groups'"
	[popupSubTitle]="'The rule will be applied according to the selected groups'">
</groups-popup-c>
