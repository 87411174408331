import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {ClipboardService} from 'ngx-clipboard';
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'archive-configurations-general-component',
	templateUrl: './archive-configurations-general.component.html',
})
export class ArchiveConfigurationsGeneralComponent implements OnInit {
    constructor(private rs:RouteService,
				private ns:NotificationService,
				private clipboard: ClipboardService,
				public gs:GeneralService){
    }

    dic = DICTIONARY;

	isLoaded = false;
    config;
    usage;
    reviewers;
    addReviewerInProcess;
    userInfo;
	planInfo;

	selectedUsers: any[];
	usersSelectionPopup: any;
	journalEmailAddress;
	showJournalAddress: Boolean;

	ngOnInit() {
        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
        });

		this.rs.getArchiveConfiguration().then(response => {
            this.config = response.archive;
            this.usage = response.usage || {}
			this.journalEmailAddress = response.journalEmailAddress;
			this.planInfo = response.planInfo;

            this.reviewers = response.archive.reviewers;
			this.isLoaded = true;
        }, (err) => {
			this.isLoaded = true;
		});
    };

	openUsersSelection = () => {
		this.selectedUsers = _.map(this.reviewers, 'email');
		this.usersSelectionPopup = {
			show: true
		};
	}

	copyJournalEmailToClipboard() {
		this.clipboard.copy(this.journalEmailAddress);
		this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Journal email address"));
	};

    updateArchiveConfigurations = (actionType) => {
        switch (actionType) {
            case this.dic.CONSTANTS.archiveActions.state:
                this.activateArchive();
                break;

            case this.dic.CONSTANTS.archiveActions.retention:
                this.updateRetention();
                break;

            default:
                return;
        }
    }

	addExistUsersToReviewers(users) {
		// Step 1: Filter out existing reviewers
		const existingEmails = _.map(this.reviewers, 'email');
		const filteredUsers = users.filter(user => !existingEmails.includes(user));

		// Step 2: Map filtered users to new reviewers structure
		const newReviewers = filteredUsers.map(user => ({
			email: user,
			isEditMode: false,
			isNew: true,
			edit: {
				email: user,
				allow_view_content: true
			}
		}));

		if (newReviewers.length > 0) {
			newReviewers.forEach(user => {
				this.rs.addArchiveReviewer(user.email).then(res => {
					this.reviewers.push({email: user.email, allow_view_content: true});
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, user.email));
				});
			});
		}
	};

	startRemoveFromUsersSelection = (users) => {
		const reviewersToRemove = this.reviewers.filter(reviewer => users.includes(reviewer.email));

		if (reviewersToRemove.length > 0) {
			this.removeReviewers(reviewersToRemove);
		}
	}

    activateArchive = () => {
        const actionData = {
            type: this.dic.CONSTANTS.archiveActions.state,
            enabled: this.config.enabled
        }
         if (!actionData.enabled) {
             this.gs.showPopup({
                 title: 'Disable Archive',
                 subTitle: `Are you sure you want to disable archive?`,
                 body: ['Outbound and inbound emails will no longer be saved in the archive and you will not be able to access previously archived emails'],
                 type: this.dic.CONSTANTS.popupWarning,
                 doneBtnText: 'Disable',
                 doneCb: () => {
                     this.executeArchive(actionData);
                 },
                 cancelCb: () => {
                     this.config.enabled = !this.config.enabled;
                 }
             });
         }
         else {
             this.executeArchive(actionData);
         }


    }
    executeArchive(actionData) {
        this.rs.updateArchiveConfigurations(actionData).then((response) => {
            this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
        }, (err) => {
            this.config.enabled = !this.config.enabled;
        });
    }
    showReviewerActions = (reviewerObj) => {
        let actions = [
			reviewerObj.allow_view_content ? 'Disable view permission' : 'Enable view permission',
			this.dic.CONSTANTS.inboundConfigurationsActions.remove
		];

        return actions;
    }

    showReviewerBulkActions = () => {
        return [
            this.dic.CONSTANTS.inboundConfigurationsActions.remove
        ]
    }

    selectReviewerAction = (reviewerObj, action) => {
        reviewerObj.showActions = false;
        switch (action) {
            case this.dic.CONSTANTS.inboundConfigurationsActions.remove:
                this.removeReviewers([reviewerObj]);
                break;

			case 'Enable view permission':
			case 'Disable view permission':
				this.reviewerViewContent(reviewerObj);
				break;
        }
    };

    selectMultipleReviewerAction = (selectedItems, action) => {
        switch (action) {
            case this.dic.CONSTANTS.inboundConfigurationsActions.remove:
                this.removeReviewers(selectedItems);
                break;
        }
    };

	reviewerViewContent = (reviewerObj) => {
		this.gs.showPopup({
			title: `${reviewerObj.allow_view_content ? 'Disable' : 'Enable'} view permissions for reviewer ${reviewerObj.email}`,
			subTitle: `${reviewerObj.allow_view_content ? 'The reviewer will be unable to access archived email content.' : 'The reviewer will be able to access archived email content.'}`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Apply',
			doneCb: () => {
				const actionInfo = {
					action: 'viewContent',
					enabled: !reviewerObj.allow_view_content
				};
				this.rs.updateArchiveReviewer(reviewerObj.email, actionInfo).then(res => {
					reviewerObj.allow_view_content = !reviewerObj.allow_view_content;
				});
			}
		});
	}

    removeReviewers = (reviewers) => {
        if (!reviewers || !reviewers.length) {
            return;
        }

        let title, subTitle, body;
        if (reviewers.length === 1) {
            body = ['User will not have permission to review case anymore.'];
            title = 'Remove reviewing permission';
            subTitle = `Remove ${reviewers[0].email} permission as a reviewer from Archive cases`;
        }
        else {
            body = [`Review permission of ${reviewers.length} users will be removed from Archive cases.`];
            title = `Remove reviewing permission`;
            subTitle = `Remove reviewing permission - ${reviewers.length} users`;
        }

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Remove',
            doneCb: () => {
                _.map(reviewers, reviewer => {
                    const _reviewerObj = _.find<any>(this.reviewers, itm => itm.email === reviewer.email);
                    if (_reviewerObj) {
                        this.rs.deleteArchiveReviewer(reviewer.email).then(res => {
                            _.remove<any>(this.reviewers, _reviewerObj);

                            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', reviewer.email));
                        });
                    }
                });
            }
        });
    };

    startAddReviewer() {
        if (this.addReviewerInProcess) {
            return;
        }

        this.addReviewerInProcess = true;

        const newReviewer = {
            isEditMode: true,
            isNew: true,
            edit: {
                email: '',
				allow_view_content: true,
            }
        };

        this.reviewers.unshift(newReviewer);
    };

    confirmAddReviewer = (reviewer ,isApproved) => {
        // new reviewer process was declined
        if (!isApproved) {
            _.remove<any>(this.reviewers, reviewer);
            this.addReviewerInProcess = false;
            return;
        }
        //

        // new reviewer approved
        if (!reviewer.edit.email) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Reviewer email field'));
        }
        if (!this.gs.validateEmail(reviewer.edit.email)) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.invalidEmail, reviewer.edit.email));
        }
        if (_.find(this.reviewers, rev => {return !rev.isNew && rev.email && rev.email === reviewer.edit.email})) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailAlreadyExist, reviewer.edit.email));
        }
		reviewer.confirmEditInProcess = true;
        const emailFormatted = reviewer.edit.email.toLowerCase()
        this.rs.addArchiveReviewer(emailFormatted).then(res => {
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));

            reviewer.email = emailFormatted;
			reviewer.allow_view_content = true;
            reviewer.edit = null;
			reviewer.confirmEditInProcess = false;
            reviewer.isEditMode = false;
            reviewer.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit

            this.addReviewerInProcess = false;
        }, (err) => {
			reviewer.confirmEditInProcess = false;
            this.addReviewerInProcess = false;
        });
    };

    searchReviewer = (searchTerm, activeFilters) => {
        this.reviewers.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    updateRetention = () => {}
}

function searchTextExecute(reviewer, searchTerm) {
    return (reviewer.email && reviewer.email.toLowerCase().indexOf(searchTerm) > -1);
}
