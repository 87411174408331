import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {ArchiveService} from "../../../services/archiveService";

@Component({
	selector: 'archive-email-headers-component',
	templateUrl: './archive-email-headers.component.html',
})
export class ArchiveEmailHeadersComponent implements OnInit {
    constructor(private rs:RouteService,
				public archiveService:ArchiveService,
				private ns:NotificationService,
				private gs:GeneralService)  {
    }

    dic = DICTIONARY;
    headersList;

	ngOnInit() {
        this.headersList = _.clone(this.archiveService.currentMessage.content.parsedContent.headerLines);
    }

	exportListToCsv = (sortBy) => {
		let csvString = "Header,Value\n";

		let sortedTable = _.filter(this.headersList, record => { return !record.hide; });
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((item) => {
			// Enclose values in double quotes and replace newline characters
			const value = item.line.substring(item.line.indexOf(':') + 2).replace(/\r?\n|\r/g, ' ');
			csvString += `"${item.key}","${value}"\n`;
		});

		this.gs.exportCsv(csvString, `email_headers.csv`);
	};

    searchHeader = (searchTerm, activeFilters) => {
        this.headersList.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchTextExecute(record, searchTerm);
                if (!isFound && !record.isNew) { // ignore contact in creation state
                    record.hide = true;
                    return;
                }
            }

            // filter
            // // currently no filters for headers

            record.hide = false;
        });
    }


}
function searchTextExecute(header, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return (header.key.toLowerCase().indexOf(searchTerm) > -1 ||
        header.line.toLowerCase().indexOf(searchTerm) > -1);
}
