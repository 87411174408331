import {DICTIONARY} from "../../dictionary";
import {Router} from "@angular/router";
import {GeneralService} from "../../services/generalService";
import {Directive} from "@angular/core";
import _ from "lodash";

@Directive({
	selector: 'tabs-page-container',
})
export class TabsPageContainer {
	dic = DICTIONARY;
	_ = _;
	tabWidth = 150;

	constructor(public router: Router, public gs:GeneralService) {
	}
}
