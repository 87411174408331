import _ from 'lodash';
import L from 'leaflet';
import {DICTIONARY} from '../../dictionary';
import {RouteService} from '../../services/routeService';
import {InboundTrendsService} from '../../services/inboundTrendsService';
import {OutboundTrendsService} from '../../services/outboundTrendsService';
import {NotificationService} from '../../services/notificationService';
import {GeneralService} from '../../services/generalService';
import {Component, OnInit, ViewChild} from "@angular/core";
import {ChartComponent} from "ng-apexcharts";
import {Router} from "@angular/router";


@Component({
	selector: 'home-component',
	templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

    constructor(private rs:RouteService,
				private inboundTrendsService:InboundTrendsService,
				private outboundTrendsService:OutboundTrendsService,
				private router:Router,
				public gs:GeneralService,
				private ns:NotificationService) {
    }

	@ViewChild('inboundTrendsChart', {static: false}) inboundTrendsChart: ChartComponent;
	@ViewChild('threatDetectionTypeChart', {static: false}) threatDetectionTypeChart: ChartComponent;
	@ViewChild('outboundEmailsChart', {static: false}) outboundEmailsChart: ChartComponent;
	@ViewChild('outboundRulesChart', {static: false}) outboundRulesChart: ChartComponent;

	dic = DICTIONARY;
    corpname = this.gs.corpname;
    isLF = true;
    getInboundStatsInProcess;
	batchSize = this.gs.isProductionEnv() ? 3000 : 10;
	batchInProcess
	abortLoadingFlag: boolean;
	showAbortLoadingEmailsLabel: boolean;
    getOutboundStatsInProcess;
    getGeneralDataInProcess;
    getCompromisedStatsInProcess;
    userInfo;
    isAdmin;
    usageData;
    storageLeft;
    isExpiredSoon;
    usersMeterChartOptions;
    mailboxesMeterChartOptions;
    maxStorageCapacity;
    totalStorageUsage;
    storageUsageBarOptions;
    outboundTrendsChartOptions;
    outboundRulesChartOptions;
    compromisedIncidentsChartOptions;
    compromisedLocations;
    compromisedLayers;
    inboundTrendsChartOptions;
    detectionTypeChartOptions;
    ruleUsagePopupData;
	showLatestNotificationssMenu;
	leafletCenter = {
		last: {lat: 0, lng: 0, zoom: 2}
	};

    dashboardPeriod = [
        this.dic.CONSTANTS.trendsPeriod.last3Days,
        this.dic.CONSTANTS.trendsPeriod.lastWeek,
        this.dic.CONSTANTS.trendsPeriod.lastMonth
    ];
    period = this.dashboardPeriod[0];
	private inboundEmails: any;
	private outboundEmails: any;


	ngOnInit() {
        this.gs.getUserInfo(false, userInfo => {
            this.userInfo = userInfo;
            this.isAdmin = this.userInfo.user_role === this.dic.CONSTANTS.userRole.admin || this.userInfo.user_role === this.dic.CONSTANTS.userRole.subAdmin;
            this.isLF = userInfo.plan.customization?.lf?.enabled;
            if (this.isLF && userInfo.plan.customization?.lf?.company) {
                this.corpname = userInfo.plan.customization.lf.company;
            }
        });

        this.usageData = {};

		this.abortLoadingFlag = false;

        this.getGeneralUsageData(this.period);

        this.getOutboundStats(false);

        this.getInboundStats(false);

        this.getAccountCompromisedStats(this.period);
    }

    changePeriod(periodObj) {
        if (this.period.value === periodObj.value) {
            return;
        }
        this.period = periodObj;

		this.abortLoadingFlag = false;

		this.getGeneralUsageData(periodObj);
        this.getOutboundStats(false);
        this.getInboundStats(false);
        this.getAccountCompromisedStats(periodObj);
    }

    getGeneralUsageData(periodObj) {
        this.getGeneralDataInProcess = true;
		const params = {type: this.dic.CONSTANTS.dashboardTypes.general, period: periodObj.value};
		this.rs.getUsage(params).then(usageData => {
			this.usageData.general = usageData;
            if (this.isAdmin) {
                this.storageLeft = this.usageData.general.planInfo.storage - (this.usageData.general.planInfo.attachments_overall_size || 0);
                this.isExpiredSoon = this.gs.dateDiffInDays(new Date(this.usageData.general.planInfo.expired), new Date()) < 7;

                // users usage meter chart
                const usedUsersRatio = Math.round(this.usageData.general.planInfo.shared_plan.used_users / this.usageData.general.planInfo.shared_plan.allowed_users * 100);
                const usersBgColor = usedUsersRatio > 90 ? '#d9355a' : usedUsersRatio > 70 ? '#e6cd4f' : '#006400'; // (#006400 = darkgreen)
                this.usersMeterChartOptions = {
                    series: [usedUsersRatio],
                    chart: {
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#e7e7e7",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -12,
                                    fontSize: '100%'
                                }
                            }
                        }
                    },
                    colors: [usersBgColor],

                    labels: ['Users'],
                };

                // mailboxes usage meter chart
                const usedMailboxesRatio = Math.round(this.usageData.general.planInfo.threat_protection.used_users / this.usageData.general.planInfo.threat_protection.allowed_users * 100);
                const mailboxesBgColor = usedMailboxesRatio > 90 ? '#d9355a' : usedMailboxesRatio > 70 ? '#e6cd4f' : '#006400'; // (#006400 = darkgreen)
                this.mailboxesMeterChartOptions = {
                    series: [usedMailboxesRatio],
                    chart: {
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#e7e7e7",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -12,
                                    fontSize: '100%'
                                }
                            }
                        }
                    },
                    colors: [mailboxesBgColor],

                    labels: ['Users'],
                };

                // usage by category bar
                this.getStorageUsageChaOptions();
            }
            else {
                if (this.usageData.general.notifications.length > 4) {
                    this.usageData.general.notifications = this.usageData.general.notifications.slice(this.usageData.general.notifications.length - 4, this.usageData.general.notifications.length);
                }
                this.usageData.general.notifications = this.ns.parseNotificationsUI(this.usageData.general.notifications);

                if (this.usageData.general.emailsSentOpenStats && this.usageData.general.emailsSentOpenStats.data && this.usageData.general.emailsSentOpenStats.data.length) {
                    this.usageData.general.emailsSentOpenStats['sent'] = this.usageData.general.emailsSentOpenStats.data[this.usageData.general.emailsSentOpenStats.labels.indexOf('Sent')];
                    this.usageData.general.emailsSentOpenStats['opened'] = this.usageData.general.emailsSentOpenStats.data[this.usageData.general.emailsSentOpenStats.labels.indexOf('Opened')];
                }

            }

            this.getGeneralDataInProcess = false;
        }, (err) => {
			this.getGeneralDataInProcess = false;
		});
    }

    getStorageUsageChaOptions() {
        if (!this.usageData.general.planInfo.usage) {
            this.usageData.general.planInfo.usage = {};
        }
        // usage by category bar
        const series = [
            {name: 'Archived Emails', color: '#e0af00', data: [(this.usageData.general.planInfo.usage.archive || 0) * this.dic.CONSTANTS.usageStorageMultipliers.archive * 1024]},
            {name: 'Sent Attachments', color: '#34383f', data: [(this.usageData.general.planInfo.usage.attachments || 0) * this.dic.CONSTANTS.usageStorageMultipliers.attachments * 1024]},
            {name: 'Sent Emails', color: '#cb3e00', data: [(this.usageData.general.planInfo.usage.emails || 0) * this.dic.CONSTANTS.usageStorageMultipliers.emails * 1024]},
            {name: 'Inbound Quarantine', color: '#8300ec', data: [(this.usageData.general.planInfo.usage.quarantines || 0) * this.dic.CONSTANTS.usageStorageMultipliers.quarantines * 1024]},
        ];

        const userTotalCapacityBytes = this.dic.CONSTANTS.usageStorageMultipliers.userTotalCapacity * 1024 * 1024 * 1024;
        this.maxStorageCapacity = Math.max(this.usageData.general.planInfo.threat_protection.allowed_users, this.usageData.general.planInfo.shared_plan.allowed_users) * userTotalCapacityBytes;
        this.totalStorageUsage = _.sum(_.map(series, value => value.data[0]));

        this.storageUsageBarOptions = {
            series: series,
            chart: {
                type: 'bar',
                height: '100%',
                stacked: true,
                toolbar: {
                    show: false
                },
                legend: {
                    tooltip: {
                        enabled: true,
                    }
                }
            },
            grid: {
                show: false
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '100%',
                    colors: {
                        backgroundBarColors: ['#bdbdbd']
                    }
                },
            },
            colors: _.map(series, 'color'),
            dataLabels: {
                enabled: false
            },
            xaxis: {
                labels: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                categories: [''],
                max: (this.totalStorageUsage / this.maxStorageCapacity) < 0.10 ? this.totalStorageUsage * 10 : this.maxStorageCapacity,
                tickAmount: 1
            },
            yaxis: {
                title: {
                    text: undefined
                },
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: (val) => {
                        // val is a number that represents KB. Show it as GB when possible (0.01) is the lowest format, or else in MB
                        // first 'replace' is to remove unnecessary zeros after dot (XX.00). second 'replace' is to format number with commas (X,XXX)
                        if (val > 1000 * 1000 * 1000 * 1000) { // from 1000GB and above - display as 1TB
                            return Number(val/(1000 * 1000 * 1000 * 1000)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' TB';
                        }
                        else if (val > 100 * 1000 * 1000) {  // from 100MB and above - display as 0.1GB
                            return Number(val/(1000 * 1000 * 1000)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' GB';
                        }
                        else { // default - display in MB
                            return Number(val/(1000 * 1000)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' MB';
                        }
                    }
                }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                showForSingleSeries: true,
                showForZeroSeries: false,
            }
        };
    }

    getOutboundStats(isReload) {
        this.getOutboundStatsInProcess = true;

		const params: any = {type: this.dic.CONSTANTS.dashboardTypes.outbound, period: this.period.value};
		if (isReload) {
			if (this.isAdmin) {
				this.getOutboundTrendsBatch(this.period);
				return;
			}
			params.skipCache = true;
		}
		this.rs.getUsage(params).then(outboundUsageData => {
			if (outboundUsageData.created) {
				this.usageData.outbound = outboundUsageData;
				this.prepareOutboundTrendsGraph(false);
				this.prepareOutboundRulesGraph(false);
				this.initTrendsProcessParams('outbound');
				return;
			}

			if (this.isAdmin) {
				this.getOutboundTrendsBatch(this.period);
			}
			else {
				this.initTrendsProcessParams('outbound');
			}
        }, () => {
			this.getOutboundStatsInProcess = false;
		});
    }

	getOutboundTrendsBatch = (periodObj) => {
		const params = {period: periodObj.value, size: this.batchSize};
		this.rs.getOutboundTrendsBatch(params).then((response) => {

			this.outboundEmails = response.emails;
			this.gs.userInfo.internalDomains = response.domains;
			this.gs.userInfo.rules = response.rules;

			this.getOutboundStatsInProcess = false;
			this.showAbortLoadingEmailsLabel = true;

			this.usageData.outbound = this.outboundTrendsService.parseEmailSentData(this.userInfo, this.outboundEmails, {period: periodObj.value});

			this.prepareOutboundTrendsGraph(false);
			this.prepareOutboundRulesGraph(false);

			this.getNextOutboundEmailsBatch(periodObj, response.lastCreated, () => {});

		}, () => {
			this.initTrendsProcessParams('outbound');
		});
	}

	getNextOutboundEmailsBatch = (periodObj, lastCreated, cb) => {
		if (!lastCreated) {
			this.initTrendsProcessParams('outbound');
			return cb();
		}

		if (this.abortLoadingFlag) {
			this.initTrendsProcessParams('outbound');
			return cb();
		}
		this.batchInProcess = true;

		const params = {period: periodObj.value, lastCreated, size: this.batchSize};
		this.rs.getOutboundTrendsBatch(params).then((response) => {
			if (response.emails?.length) {
				this.outboundEmails = this.outboundEmails.concat(response.emails);
				this.usageData.outbound = this.outboundTrendsService.parseEmailSentData(this.userInfo, this.outboundEmails, {period: periodObj.value});
				this.prepareOutboundTrendsGraph(true);
				this.prepareOutboundRulesGraph(true);
			}
			this.getNextOutboundEmailsBatch(periodObj, response.lastCreated, cb);
		});
	}

	prepareOutboundTrendsGraph = (isUpdate) => {
		const series = [];
		this.usageData.outbound.general?.series.forEach((categoryName, index) => {
			series.push({
				name: categoryName,
				data: this.usageData.outbound.general.data[index]
			});
		});

		if (isUpdate && this.outboundTrendsChartOptions?.series?.length &&
			this.gs.areArraysEqual(this.usageData.outbound.general.labels, this.outboundTrendsChartOptions.xaxis.categories)) {
			this.outboundEmailsChart.updateSeries(series, true);
			return;
		}

		this.outboundTrendsChartOptions = {
			series: series,
			chart: {
				type: this.usageData.outbound.general?.labels?.length === 1 ? "bar" : "area",
				height: '100%',
				toolbar: {
					show: false
				},
			},

			colors: ['#1a4d99', '#2a9d38', '#d17e38'],
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['transparent']
			},
			xaxis: {
				tickAmount: 4, // Set the maximum number of ticks (labels) to be displayed on the x-axis
				categories: this.usageData.outbound.general?.labels,
			},
			yaxis: {
				title: {
					text: 'Emails'
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val) => {
						return val + ' emails';
					}
				}
			}
		};
	};


	prepareOutboundRulesGraph = (isUpdate) => {
		this.usageData.outbound.rules.total = this.usageData.outbound.rules.data.reduce((a, b) => a + b, 0);

		if (isUpdate && this.outboundRulesChartOptions?.series?.length && this.gs.areArraysEqual(this.outboundRulesChartOptions.labels, this.usageData.outbound.rules.labels)) {
			this.outboundRulesChart.updateSeries(this.usageData.outbound.rules.data, true);
			return;
		}

		this.outboundRulesChartOptions = {
			series: this.usageData.outbound.rules.data,
			chart: {
				type: 'donut',
				events: {
					dataPointSelection: (event, chartContext, config) => {
						if (!event || !config) {
							return;
						}

						this.ruleUsagePopupData = {
							show: true,
							period: this.period,
							ruleObj: {
								_id: this.usageData.outbound.rules.rulesIds[config.dataPointIndex]
								, name: this.usageData.outbound.rules.labels[config.dataPointIndex]
							}
						};
					}
				}
			},
			plotOptions: {
				pie: {
					donut: {
						size: '52%'
					}
				}
			},
			dataLabels: {
				enabled: true,
				formatter: (val, opt) => {
					return opt.w.globals.series[opt.seriesIndex];
				}
			},
			labels: this.usageData.outbound.rules.labels,
			tooltip: {
				y: {
					formatter: (val) => {
						return val + ' emails';
					}
				}
			}
		}
	};


	getAccountCompromisedStats(periodObj) {
		if (!this.userInfo.plan.account_compromised?.enabled) {
			return;
		}
        this.getCompromisedStatsInProcess = true;

		const params = {type: this.dic.CONSTANTS.dashboardTypes.accountCompromised, period: periodObj.value};
		this.rs.getUsage(params).then(usageData => {
			this.usageData.compromisedStats = usageData && usageData.accountCompromised;

            // compromised chart
            this.compromisedIncidentsChartOptions = null;
            if (usageData && usageData.accountCompromised) {

                this.prepareUsersLocationInfo(usageData.accountCompromised.locations || null);

                if (usageData.accountCompromised.incidentsTypes && !_.isEmpty(usageData.accountCompromised.incidentsTypes)) {

                    const sortedIncidentTypes = sortItems(usageData.accountCompromised.incidentsTypes);
                    usageData.accountCompromised.incidentsTypesGraph = {data: [], labels: []};
                    sortedIncidentTypes.forEach((type) => {
                        usageData.accountCompromised.incidentsTypesGraph.data.push(usageData.accountCompromised.incidentsTypes[type]);
                        usageData.accountCompromised.incidentsTypesGraph.labels.push(this.dic.CONSTANTS.accountCompromisedIncidentTypes[type].name);
                    });

                    this.compromisedIncidentsChartOptions = {
                        series: [{
                            name: 'Incidents',
                            data: usageData.accountCompromised.incidentsTypesGraph.data
                        }],
                        chart: {
                            type: 'bar',
                            height: '100%',
                            toolbar: {
                                show: false
                            },
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 5,
                                horizontal: true,
                                barHeight: this.gs.getOptimalBarWidth(Object.values(usageData.accountCompromised.incidentsTypes).length) + '%',
                            },
                        },
                        dataLabels: {
                            enabled: true
                        },
                        stroke: {
                            show: true,
                            width: 4,
                            colors: ['transparent']
                        },
                        xaxis: {
                            categories: usageData.accountCompromised.incidentsTypesGraph.labels,
                        },
                        fill: {
                            opacity: 1
                        },
                        tooltip: {
                            y: {
                                formatter: (val) => {
                                    return val;
                                }
                            }
                        }
                    };
                }
            }

            this.getCompromisedStatsInProcess = false;
        }, (err) => {
			this.getCompromisedStatsInProcess = false;
		});
    };

    prepareUsersLocationInfo(locationsObj) {
        this.compromisedLocations = null;
        this.compromisedLayers = null;

        if (!locationsObj) {
            return;
        }

        this.compromisedLocations = {last: []};
		let boundsLast = [];
        let maxCount = 0;
        if (locationsObj.allLastLocations.length) {
            locationsObj.allLastLocations.forEach((locationObj) => {
                if (locationObj.location.ll.length === 2) {
					const marker = {
						title: locationObj.email,
						icon: this.gs.leafletDefaultIcon,
						lat: locationObj.location.ll[0],
						lng: locationObj.location.ll[1],
						count: locationObj.count,
						draggable: false
					};

                    if (marker.count > maxCount) {
                        maxCount = marker.count;
                    }
					boundsLast.push([locationObj.location.ll[0],locationObj.location.ll[1]]);

                    this.compromisedLocations.last.push(marker);
                }
            });
			const centroidLast = boundsLast.length > 0 ? new L.LatLngBounds(boundsLast).getCenter() : { lat: 0, lng: 0 };
			this.leafletCenter.last = {
				lat: centroidLast.lat,
				lng: centroidLast.lng,
				zoom: 2
			};
		}

        const baseLayers = {
			osm: {
				name: 'OpenStreetMap',
				//http://otile{s}.mqcdn.com/tiles/1.0.0/map/{z}/{x}/{y}.jpg
				url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
				type: 'xyz'
			}
        };
        this.compromisedLayers = {last: {baseLayers}};

		maxCount = Math.max(...this.compromisedLocations.last.map(itm => itm.count)) + 1;
		this.compromisedLayers.last.overlays = {
			heatmap: {
				name: 'Last Heat Map',
				type: 'webGLHeatmap',
				data: this.compromisedLocations.last.map(itm => ([itm.lat, itm.lng, 1 - itm.count / maxCount])),  //intense can be always 1
				visible: true
			}
		};
    }

    getInboundStats(isReload) {
        this.getInboundStatsInProcess = true;

		const params: any = {type: this.dic.CONSTANTS.dashboardTypes.inbound, period: this.period.value};
		if (isReload) {
			if (this.isAdmin) {
				this.getInboundTrendsBatch(this.period);
				return;
			}
			params.skipCache = true;
		}
		this.rs.getUsage(params).then(inboundUsageData => {
			if (inboundUsageData.created) {
				this.usageData.inbound = inboundUsageData;
				this.prepareInboundTrendsGraph(false);
				this.prepareInboundDetectionTypeGraph(false);
				this.initTrendsProcessParams('inbound');
				return;
			}
			if (this.isAdmin) {
				this.getInboundTrendsBatch(this.period);
			}
			else {
				this.initTrendsProcessParams('inbound');
			}
        }, () => {
			this.getInboundStatsInProcess = false;
		});
    };

	getInboundTrendsBatch = (periodObj) => {
		const params = {period: periodObj.value, size: this.batchSize};
		this.rs.getInboundTrendsBatch(params).then((response) => {

			this.inboundEmails = response.emails;

			this.getInboundStatsInProcess = false;
			this.showAbortLoadingEmailsLabel = true;

			this.usageData.inbound = this.inboundTrendsService.parseTrendsData(this.userInfo, this.inboundEmails, {period: periodObj.value});

			this.prepareInboundTrendsGraph(false);
			this.prepareInboundDetectionTypeGraph(false);

			this.getNextInboundEmailsBatch(periodObj, response.lastCreated, () => {});

		}, () => {
			this.initTrendsProcessParams('inbound');
		});
	}

	getNextInboundEmailsBatch = (periodObj, lastCreated, cb) => {
		if (!lastCreated) {
			this.initTrendsProcessParams('inbound');
			return cb();
		}

		if (this.abortLoadingFlag) {
			this.initTrendsProcessParams('inbound');
			return cb();
		}
		this.batchInProcess = true;

		const params = {period: periodObj.value, lastCreated, size: this.batchSize};
		this.rs.getInboundTrendsBatch(params).then((response) => {
			if (response.emails?.length) {
				this.inboundEmails = this.inboundEmails.concat(response.emails);
				this.usageData.inbound = this.inboundTrendsService.parseTrendsData(this.userInfo, this.inboundEmails, {period: periodObj.value});
				this.prepareInboundTrendsGraph(true);
				this.prepareInboundDetectionTypeGraph(true);
			}
			this.getNextInboundEmailsBatch(periodObj, response.lastCreated, cb);
		});
	}

	initTrendsProcessParams = (type) => {
		this.batchInProcess = false;
		this.showAbortLoadingEmailsLabel = false;

		switch (type) {
			case 'inbound':
				this.getInboundStatsInProcess = false;
				break;

			case 'outbound':
				this.getOutboundStatsInProcess = false;
				break;
		}
	}

	abortLoadingEmails = () => {
		this.abortLoadingFlag = true;
		this.showAbortLoadingEmailsLabel = false;
		this.getInboundStatsInProcess = false;
		this.getOutboundStatsInProcess = false;
	}

	prepareInboundTrendsGraph = (isUpdate) => {
		const series = [];
		if (this.usageData.inbound.inboundStats.labels.length) {
			this.usageData.inbound.inboundStats?.series?.forEach((categoryName, index) => {
				series.push({
					name: categoryName,
					data: this.usageData.inbound.inboundStats.data[index]
				});
			});
		}

		if (isUpdate && this.inboundTrendsChartOptions?.series?.length &&
			this.gs.areArraysEqual(this.usageData.inbound.inboundStats.labels, this.inboundTrendsChartOptions.xaxis.categories)) {
			this.inboundTrendsChart.updateSeries(series, true);
			return;
		}

		this.inboundTrendsChartOptions = {
			series: series,
			chart: {
				type: this.usageData.inbound.inboundStats.labels?.length === 1 ? "bar" : "area",
				height: '100%',
				toolbar: {
					show: false
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						if (!event || !config || !this.isAdmin) {
							return;
						}

						const queryData = {
							filters: {
								category: [series[config.seriesIndex].name]
							},
							period: this.period
						};

						this.router.navigate([this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.quarantined], {state: {data: queryData}});
					}
				}
			},
			colors: ['#f1122d', '#e56f39', '#f5cb0d', '#bbbbbb', '#bbbbbb'],
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['transparent']
			},
			xaxis: {
				tickAmount: 4, // Set the maximum number of ticks (labels) to be displayed on the x-axis
				categories: this.usageData.inbound.inboundStats.labels,
			},
			yaxis: {
				title: {
					text: 'Threats'
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val) => {
						return val + ' threats';
					}
				}
			}
		};
	}

	prepareInboundDetectionTypeGraph = (isUpdate) => {
		this.usageData.inbound.detectionType.total = _.sum(this.usageData.inbound.detectionType.data);

		if (isUpdate && this.detectionTypeChartOptions?.series?.length &&
			this.gs.areArraysEqual(this.usageData.inbound.detectionType.labels, this.detectionTypeChartOptions.labels)) {
			this.threatDetectionTypeChart.updateSeries(this.usageData.inbound.detectionType.data, true);
			return;
		}

		this.detectionTypeChartOptions = {
			series: this.usageData.inbound.detectionType.data,
			chart: {
				type: 'donut',
				animations: {
					enabled: true,
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						if (!event || !config) {
							return;
						}

						const subCategory = this.usageData.inbound.detectionType.labels[config.dataPointIndex];
						if (subCategory === 'Misc') {
							return;
						}
						const options = {
							period: this.period,
							sub_category: [subCategory]
						};

						// Determine the path based on isAdmin
						const path = this.isAdmin ?
							[this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.quarantined] :
							[this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.userQuarantinedInboundConf];

						this.router.navigate(path, {state: {data: options}});
					}
				},
			},
			plotOptions: {
				pie: {
					donut: {
						size: '52%',
					}
				}
			},
			dataLabels: {
				enabled: true,
				formatter:  (val, opt) => {
					return opt.w.globals.series[opt.seriesIndex];
				}
			},
			colors: ['#d92e1d', '#e09096', '#a94caa', '#ff9f67', '#fad07d', '#77a380'],
			labels: this.usageData.inbound.detectionType.labels,
			tooltip: {
				y: {
					formatter:  (val) => {
						return val + ' threats';
					}
				}
			}
		};
	}

    openLocation(ll) {
        this.gs.openLocation(ll);
    };

    goToAuditLogs(type) {
        switch (type) {
			case 'Outbound':
				this.router.navigate([this.dic.CONSTANTS.appStates.adminOutbound, this.dic.CONSTANTS.adminPages.outbound.auditLog, this.dic.CONSTANTS.auditLogsPageTabs.auditLog]);
				break;

			case 'Inbound':
				this.router.navigate([this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.auditLog, this.dic.CONSTANTS.auditLogsPageTabs.auditLog]);
				break;

            case 'Archive':
				this.router.navigate([this.dic.CONSTANTS.appStates.archive, this.dic.CONSTANTS.archivePages.auditLog]);
                break;

            case 'Account Compromised':
				this.router.navigate([this.dic.CONSTANTS.appStates.accountCompromised, this.dic.CONSTANTS.accountCompromisedPages.auditLog]);
                break;

			case 'Domain':
				this.router.navigate([this.dic.CONSTANTS.appStates.domainProtection, this.dic.CONSTANTS.adminPages.domainProtection.auditLog]);
				break;

			case 'Threat Simulation':
				this.router.navigate([this.dic.CONSTANTS.appStates.threatSimulation, this.dic.CONSTANTS.adminPages.threatSimulation.auditLog]);
				break;
        }
    };

    goToMyPlan() {
		this.router.navigate([this.dic.CONSTANTS.appStates.accountDetails, this.dic.CONSTANTS.accountDetailsPages.myPlan]);
    };

    inboundDetails = () => {
        window.open('https://trustifi.com/inbound-shield','_blank');
    };

    outboundDetails = () => {
        window.open('https://trustifi.com/outbound-shield/','_blank');
    };

    accountDetectionDetails = () => {
        window.open('https://trustifi.com/account-compromise-detection/','_blank');
    };
}

function sortItems(list) {
    return Object.keys(list).sort((a,b) => {return list[b] - list[a]});
}
