import {importProvidersFrom, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {UpgradeModule} from '@angular/upgrade/static';
import {AppComponent} from './app.component';


import {HttpClientModule, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {AccountCompromisedService} from "./services/accountCompromisedService";
import {ArchiveService} from "./services/archiveService";
import {AuthService} from "./services/authService";
import {ClioService} from "./services/clioService";
import {ComposeMessageService} from "./services/composeMessageService";
import {DropboxService} from "./services/dropboxService";
import {GeneralService} from "./services/generalService";
import {myEmailsService} from "./services/myEmailsService";
import {RestangularConfigFactory, RouteService} from "./services/routeService";
import {NotificationService} from "./services/notificationService";
import {RestangularModule} from 'ngx-restangular';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PartnerContactInfoComponent} from "./components/partners/partnerContactInfo/partner-contact-info.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DropdownComponent} from './components/uiComponents/dropdown/dropdown.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {NgxUploaderModule} from 'ngx-uploader';
//import {TrustifiTableComponent} from './components/uiComponents/trustifi-table/trustifi-table.component';
import {BtnComponent} from './components/uiComponents/btn/btn.component';
import {LoaderComponent} from './components/uiComponents/loader/loader.component';
import {SettingsListComponent} from './components/uiComponents/settings-layout/settings-list/settings-list.component';
import {
	SettingsListItemComponent
} from './components/uiComponents/settings-layout/settings-list-item/settings-list-item.component';
import {CheckboxComponent} from './components/uiComponents/checkbox/checkbox.component';
import {RadioBtnComponent} from './components/uiComponents/radio-btn/radio-btn.component';
//import {PopupFrameComponent} from './components/uiComponents/popup-frame/popup-frame.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {PartnerRegistrationComponent} from "./components/partners/partnerRegistration/partner-registration.component";
import {PartnerResourcesComponent} from "./components/partners/partnerResources/partner-resources.component";
import {
	AmDateFormatPipe,
	CamelCasePipe,
	CamelCaseToTitleCasePipe,
	FormatBytes,
	GetTrackingEventPipe,
	LimitToPipe,
	NgForFilterPipe,
	OrderArrayBy,
	SafeHtml,
	SanitizeTextPipe,
	TitleizePipe,
	UniquePipe
} from './pipes';
import {NgApexchartsModule} from "ng-apexcharts";
import {PartnerUsageComponent} from "./components/partners/partnerUsage/partner-usage.component";
import {VirtualScrollerModule} from "ngx-virtual-scroller";
import {TrustifiTableComponent} from "./components/uiComponents/trustifi-table/trustifi-table.component";
import {PopupFrameComponent} from "./components/uiComponents/popup-frame/popup-frame.component";
import {ToggleSwitchComponent} from "./components/uiComponents/toggle-switch/toggle-switch.component";
import {
	AutoCompleteMenuComponent,
	AutoFocusDirective,
	ContentTooltipComponent,
	ContentTooltipDirective,
	DisableAutoCOmpleteDirective,
	DropdownPositionDirective,
	Explanation,
	ExplanationComponent,
	FocusMeDirective,
	HighlightTextDirective,
	inputNumberDirective,
	IsElps,
	MaskDirective,
	NgInit,
	ScrollToTopFlagDirective,
	SelectAllText,
	ShownDirective
} from './directives/directives';
import {ClickOutsideModule} from "ng-click-outside";
import {TooltipModule, TooltipOptions} from "ng2-tooltip-directive";
import {MyProfileComponent} from "./components/accountDetails/accountSettings/accountProfile/my-profile.component";
import {
	SharedPlanSettingsComponent
} from "./components/admin/outbound/planSettings/sharedPlan/shared-plan-settings.component";
import {ClipboardModule} from "ngx-clipboard";
import {IntegrationsComponent} from "./components/admin/planSettings/integrations/integrations.component";
import {
	SecurityAwarenessComponent
} from "./components/admin/inbound/inboundShield/securityAwareness/security-awareness.component";
import {ScanThreatsComponent} from "./components/admin/inbound/inboundShield/scanThreats/scan-threats.component";
import {ngfModule} from "angular-file";
import {RegionComponent} from "./components/admin/planSettings/region/region.component";
import {AliasesPopupComponent} from "./directives/aliasesPopup/aliases-popup.component";
import {GroupsPopupComponent} from "./directives/groupsPopup/groups-popup.component";
import {
	AccountManagementComponent
} from "./components/accountCompromised/accountManagement/account-management.component";
import {InboxComponent} from "./components/inbox/inbox.component";
import {LeafletComponent} from "./components/uiComponents/leaflet/leaflet.component";
import {FilterTableComponent} from "./directives/filterTable/filter-table.component";
import {
	AccountCompromisedConfigurationsComponent
} from "./components/accountCompromised/configurations/incidentsConfigurations/account-compromised-configurations.component";
import {
	AccountCompromisedReviewerConfigurationsComponent
} from './components/accountCompromised/configurations/reviewerConfigurations/account-compromised-reviewer-configurations.component';

import {
	AccountCompromisedAuditLogComponent
} from "./components/accountCompromised/accountCompromisedAuditLog/account-compromised-audit-log.component";
import {
	DomainProtectionAuditLogsComponent
} from "./components/admin/domainProtection/auditLog/domain-protection-audit-logs.component";
import {
	AccountDetailsNotificationsComponent
} from "./components/accountDetails/notifications/account-details-notifications.component";
import {ContactsComponent} from "./components/contactsAndTemplates/contacts/contacts.component";
import {
	InboundConfigurationComponent
} from "./components/admin/inbound/inboundShield/inboundConfiguration/inbound-configuration.componenet";
import {
	AccountCompromisedIncidentsComponent
} from "./components/accountCompromised/incidents/account-compromised-incidents.component";
import {SensitivityDetailsComponent} from "./directives/sensitivityDetails/sensitivity-details.component";
import {DiscoveredServicesComponent} from "./components/admin/inbound/discoveredServices/discovered-services.component";
import {AccountApiComponent} from "./components/accountDetails/accountSettings/accountApi/account-api.component";
import {
	AccountAddressesComponent
} from './components/accountDetails/accountSettings/accountAddresses/account-addresses.component';
import {PaypalButtonsComponent} from "./directives/paypal-buttons.component";
import {MyPlanComponent} from "./components/accountDetails/myPlan/my-plan.component";
import {
	AccountSecurityComponent
} from './components/accountDetails/accountSettings/accountSecurity/account-security.component';
import {ContactAddressBookComponent} from "./directives/contactAddressBook/contact-address-book.component";
import {ArchiveAuditLogComponent} from "./components/archive/archiveAuditLog/archive-audit-log.component";
import {
	ArchiveCasesOperationsComponent
} from "./components/archive/archiveCasesOperations/archive-cases-operations.component";
import {AngularMyDatePickerModule} from 'angular-mydatepicker';
import {DatePickerComponent} from "./directives/datePicker";
import {ArchiveCasesComponent} from "./components/archive/archiveCases/archive-cases.component";
import {ArchiveEmailDetailsComponent} from "./components/archive/archiveEmailDetails/archive-email-details.component";
import {
	ArchiveEmailTrackingComponent
} from "./components/archive/archiveEmailTracking/archive-email-tracking.component";
import {ArchiveEmailHeadersComponent} from './components/archive/archiveEmailHeaders/archive-email-headers.component';
import {
	ArchiveEmailQuarantinedComponent
} from "./components/archive/archiveEmailQuarantined/archive-email-quarantined.component";
import {ArchiveSearchComponent} from "./components/archive/archiveSearch/archive-search.component";
import {
	AccountIntegrationsComponent
} from "./components/accountDetails/accountSettings/accountIntegrations/account-integrations.component";
import {KnowledgeComponent} from "./components/knowledge/knowledge.component";
import {MassListPageComponent} from "./components/contactsAndTemplates/massList/mass-list-page.component";
import {DomainSpoofingComponent} from "./components/admin/domainProtection/domainSpoofing/domain-spoofing.component";
import {TemplatesPageComponent} from "./components/contactsAndTemplates/templates/templates-page.component";
import {AttachmentsManagerComponent} from "./directives/attachmentsManager/attachments-manager.component";
import {UrlTrackingComponent} from "./components/admin/inbound/urlTracking/url-tracking.component";
import {previewLinkComponent} from "./directives/previewLink/preview-link.component";
import {MoreInfoComponent} from "./directives/moreInfoDrv";
import {AttachmentComponent} from "./directives/attachment-download/attachment-download.component";
import {DmarcReportsComponent} from "./components/admin/domainProtection/dmarcReports/dmarc-reports.component";
import {GenerateReportComponent} from "./components/admin/outbound/reports/generateReport/generate-report.component";
import {ScheduleReportComponent} from "./components/admin/outbound/reports/scheduleReport/schedule-report.component";
import {AuditLogsComponent} from "./components/admin/auditLog/audit-logs.component"
import {EmailTraceComponent} from "./components/admin/auditLog/emailTrace/email-trace.component";
import {ActivityLogsComponent} from "./components/admin/auditLog/activityLogs/activity-logs.component";
import {ThreatResponseComponent} from "./components/admin/inbound/threatResponse/threat-response.component";
import {ApplyPolicyComponent} from "./components/applyPolicy/apply-policy.component";
import {
	OutboundQuarantinedComponent
} from "./components/admin/outbound/outboundQuarantined/outbound-quarantined.component";
import {PlanSettingsDomainComponent} from "./components/admin/planSettings/domains/plan-settings-domain.component";
import {
	EmailRelayIntegrationComponent
} from "./components/admin/outbound/planSettings/outboundEmailRelay/email-relay-integration.component";
import {ExchangeRulesWizardComponent} from "./directives/exchangeRulesWizardDrv/exchange-rules-wizard.component";
import {
	ExchangeProtectionWizardComponent
} from "./directives/exchangeProtectionWizardDrv/exchange-protection-wizard.component";
import {
	ExchangeUpdateRulesWizardComponent
} from "./directives/exchangeUpdateRulesWizardDrv/exchange-update-rules-wizard.component";
import {ViewExchangeCfgWizardComponent} from "./directives/viewExchangeCfgWizardDrv/view-exchange-cfg-wizard.component";
import {ExchangeLoginWizardComponent} from "./directives/exchangeLoginWizardDrv/exchange-login-wizard.component";
import {
	ExchangeQuarantinedEmailsWizardComponent
} from "./directives/exchangeQuarantinedEmailsWizardDrv/exchange-quarantined-emails-wizard.component";
import {
	InboundEmailRelayIntegrationComponent
} from "./components/admin/inbound/planSettings/inboundEmailRelay/inbound-email-relay-integration.component";
import {LookAndFeelComponent} from "./components/admin/outbound/lookAndFeel/look-and-feel.component";
import {ColorPickerModule} from "ngx-color-picker";
import {
	ThreatPreventionComponent
} from "./components/admin/inbound/inboundShield/threatPrevention/threat-prevention.component";
import {NgxSummernoteModule} from 'ngx-summernote';
import {
	OutboundReviewersComponent
} from './components/admin/outbound/rulesAndPolicies/outbound-reviewers/outbound-reviewers.component';
import {
	AccountCompromisedTrendsComponent
} from "./components/accountCompromised/accountCompromisedTrends/account-compromised-trends.component";
import {
	OutboundPoliciesComponent
} from "./components/admin/outbound/rulesAndPolicies/policies/outbound-policies.component";

import {InboundTrendsComponent} from "./components/admin/inbound/trends/inbound-trends.component";
import {OutboundTrendsComponent} from "./components/admin/outbound/outboundTrends/outbound-trends.component";
import {OutboundRuleUsageComponent} from "./directives/outbound-rule-usage/outbound-rule-usage.component";
import {HomeComponent} from "./components/home/home.component";
import {OutboundRulesComponent} from "./components/admin/outbound/rulesAndPolicies/rules/outbound-rules.component";
import {SensitivityTypeComponent} from "./directives/sensitivityTypeDrv/sensitivity-type.component";
import {
	NewRuleConditionComponent
} from "./components/admin/outbound/rulesAndPolicies/rules/new-rule-condition.component";
import {UserInboundConfComponent} from "./components/admin/inbound/userInboundConf/user-inbound-conf.component";
import {UserQuarantinedComponent} from './components/admin/inbound/userQuarantined/user-quarantined.component';
import {InboundMailboxesComponent} from "./components/admin/inbound/mailboxes/inbound-mailboxes.component";
import {
	InboundAllowBlockListsComponent
} from "./components/admin/inbound/configuration/allowBlockLists/inbound-allow-block-lists.component";
import {
	InboundBlockFileTypesComponent
} from "./components/admin/inbound/configuration/blockFileTypes/inbound-block-file-types.component";
import {
	InboundEmailRulesComponent
} from "./components/admin/inbound/inboundShield/inboundEmailRules/inbound-email-rules.component";
import {
	InboundNewRuleConditionComponent
} from "./components/admin/inbound/inboundShield/inboundEmailRules/inbound-new-rule-condition.component";
import {OnboardingPopupComponent} from "./components/onboarding/onboarding-popup.component";
import {OnboardingQuestionnaireComponent} from "./components/onboarding/onboarding-questionnaire.component";
import {OnboardingWizardStepsComponent} from "./components/onboarding/onboarding-wizard-steps.component";
import {LoginComponent} from "./components/login/login.component";
import {PasswordLoginComponent} from "./components/login/password-login.component";
import {EmailAndSsoComponent} from "./components/login/email-and-sso.component";
import {SignupFormComponent} from "./components/signup/signup-form/signup-form.component";
import {MfaComponent} from "./components/login/mfa.component";
import {PinCodeComponent} from "./directives/pin-code/pin-code.component";
import {InboundQuarantinedComponent,} from "./components/admin/inbound/quarantined/inbound-quarantined.component";
import {AttachmentsComponent} from "./components/attachments/attachments.component";
import {InboundTrendsService} from "./services/inboundTrendsService";
import {SentEmailsComponent} from "./components/sentEmails/sent-emails.component";
import {NotificationToasterComponent} from "./directives/notificationToaster/notification-toaster.component";
import {SentEmailTrackingComponent} from "./components/sentEmails/sentEmailTracking/sent-email-tracking.component";
import {SentEmailDetailsComponent} from "./components/sentEmails/sentEmailDetails/sent-email-details.component";
import {SentEmailSummaryComponent} from "./components/sentEmails/sentEmailSummary/sent-email-summary.component";
import {SentEmailAdvancedComponent} from "./components/sentEmails/sentEmailAdvanced/sent-email-advanced.component";
import {SentEmailPostmarkComponent} from "./components/sentEmails/sentEmailPostmark/sent-email-postmark.component";
import {PopupDrvComponent} from "./directives/popup-drv-component/popup-drv.component";
import {
	ThreatSimulationCampaignWizardComponent
} from "./components/threatSimulation/threatSimulationCampaigns/newCampaignWizard/threat-simulation-campaign-wizard.component";
import {UserManagementComponent} from "./components/admin/outbound/userManagement/user-management.component";
import {
	ThreatSimulationCampaignDetailsComponent
} from "./components/threatSimulation/threatSimulationCampaigns/campaignDetails/threat-simulation-campaign-details.component";
import {ComposeMessageComponent} from "./components/composeMessage/compose-message.component";
import {
	ThreatSimulationAuditLogsComponent
} from "./components/threatSimulation/threatSimulationAuditLogs/threat-simulation-audit-logs.component";
import {
	ThreatSimulationCampaignsComponent
} from "./components/threatSimulation/threatSimulationCampaigns/threat-simulation-campaigns.component";
import {
	ThreatSimulationNotSupportedComponent
} from "./components/threatSimulation/threatSimulationCampaigns/not-supported.component";
import {
	ThreatSimulationConfigComponent
} from "./components/threatSimulation/threatSimulationConfig/configuration/threat-simulation-config.component";
import {
	ThreatSimulationCampaignSummaryComponent
} from "./components/threatSimulation/threatSimulationCampaigns/campaignSummary/threat-simulation-campaign-summary.component";
import {
	ThreatSimulationTemplatesComponent
} from "./components/threatSimulation/threatSimulationTemplates/threat-simulation-templates.component";
import {
	ThreatSimulationTrendsComponent
} from "./components/threatSimulation/ThreatSimulationTrends/threat-simulation-trends.component";
import {
	ThreatSimulationReviewersComponent
} from "./components/threatSimulation/threatSimulationConfig/reviewers/threat-simulation-reviewers.component";
import {
	ThreatSimulationTemplateFieldsComponent
} from './components/threatSimulation/threatSimulationConfig/threat-simulation-template-fields/threat-simulation-template-fields.component';
import {
	AccountCompromisedUserIncidentsInfoComponent
} from "./components/accountCompromised/accountCompromisedUserIncidentsInfo/user-incidents-info.component";
import {SignupComponent} from "./components/signup/signup.component";
import {PurchasePageComponent} from "./components/signup/purchase/purchase-page.component";
import {SupportMenuComponent} from "./components/support/support-menu.component";
import {
	accountCompromisedCfgTabsComponent
} from "./components/accountCompromised/configurations/account-compromised-configurations-tabs.component";
import {TabsPageContainer} from "./components/uiComponents/tabsPageContainer";
import {OutboundPlanTabsComponent} from "./components/admin/outbound/planSettings/outbound-plan-tabs.component";
import {MyAccountTabsComponent} from "./components/accountDetails/accountSettings/myAccount/my-account-tabs.component";
import {OutboundReportTabsComponent} from "./components/admin/outbound/reports/outbound-report-tabs.component";
import {
	RulesAndPoliciesTabsComponent
} from "./components/admin/outbound/rulesAndPolicies/rules-and-policies-tabs.component";
import {
	InboundConfigurationTabsComponent
} from "./components/admin/inbound/configuration/inbound-configuration-tabs.component";
import {InboundShieldTabsComponent} from "./components/admin/inbound/inboundShield/inbound-shield-tabs.component";
import {
	ThreatSimulationsConfigTabsComponent
} from "./components/threatSimulation/threatSimulationConfig/threat-simulation-cfg-tabs.component";
import {
	InboundPlanSettingsTabsComponent
} from "./components/admin/inbound/planSettings/plan/inbound-plan-settings-tabs.component";
import {ContentComponent} from "./components/content/content.component";
import {AppRoutingModule, DummyComponent} from "./app-routing.module";
import {CloudspongeComponent} from "./directives/cloudspongeComponent";
import {NgProgressHttpModule} from "ngx-progressbar/http";
import {NgProgressModule} from "ngx-progressbar";
import {AttachmentPreviewComponent} from './components/attachments/attachment-preview/attachment-preview.component';
import {
	AuditLogScheduleReportComponent
} from "./components/audit-log-schedule-report/audit-log-schedule-report.component";
import {ReviewersComponent} from "./components/admin/inbound/inboundShield/reviewers/reviewers.component";
import {UsersSelectionComponent} from "./directives/users-selection/users-selection.component";
import {LookAndFeelService} from "./services/lookAndFeelService";
import {CountryCodeSelectComponent} from "./components/uiComponents/country-code-select/country-code-select.component";
import {SearchBoxComponent} from "./components/uiComponents/search-box/search-box.component";
import {TrackingComponent} from "./components/uiComponents/tracking/tracking.component";
import {DataClassificationTabs} from "./components/admin/outbound/dataClassification/data-classification-tabs";
import {
	OutboundSensitivityTypesComponent
} from "./components/admin/outbound/dataClassification/sensitivityTypes/outbound-sensitivity-types.component";
import {
	OutboundPersonalTypesComponent
} from "./components/admin/outbound/dataClassification/personalTypes/outbound-personal-types.component";
import {
	OutboundTestSensitivityComponent
} from "./components/admin/outbound/dataClassification/testSensitivity/outbound-test-sensitivity.component";
import {
	ClassificationTrendsComponent
} from './components/admin/outbound/dataClassification/classification-trends/classification-trends.component';
import {
	DomainImpersonationComponent
} from './components/admin/domainProtection/domain-impersonation/domain-impersonation.component';
import {UserPermissionService} from "./services/userPermissionService";
import {EmailViewerComponent} from "./components/uiComponents/email-viewer.component";
import {
	ThreatSimulationCampaignConfigurationComponent
} from "./components/threatSimulation/threatSimulationCampaigns/campaignConfiguration/threat-simulation-campaign-configuration.component";
import {ConnectionGraphComponent} from "./components/uiComponents/connectionGraph/connection-graph.component";
import {
	ArchiveConfigurationsGeneralComponent
} from "./components/archive/archiveConfigurations/archiveGeneral/archive-configurations-general.component";
import {
	ArchiveMigrationComponent
} from "./components/archive/archiveConfigurations/archiveMigration/archive-migration.component";
import {
	ArchiveConfigurationTabsComponent
} from "./components/archive/archiveConfigurations/archive-configuration-tabs.component";
import {
	ThreatSimulationTrainingMaterialsComponent
} from "./components/threatSimulation/threatSimulationTrainingMaterials/threat-simulation-training-materials.component";
import {
	ThreatSimulationCampaignsTabsComponent
} from "./components/threatSimulation/threatSimulationCampaigns/threat-simulation-campaigns-tabs.component";

const defaultTooltipOptions: TooltipOptions = {
	'hide-delay': 0,
	animationDuration: 150,
	tooltipClass: 'tooltip-drv'
};

@NgModule({
	declarations: [
		AppComponent,
		ContentComponent,
		PopupDrvComponent,
		TabsPageContainer,
		HomeComponent,
		LoginComponent,
		PasswordLoginComponent,
		UsersSelectionComponent,
		SignupFormComponent,
		MfaComponent,
		PinCodeComponent,
		NotificationToasterComponent,
		SupportMenuComponent,
		EmailAndSsoComponent,
		ReviewersComponent,
		PartnerContactInfoComponent,
		PartnerRegistrationComponent,
		PartnerResourcesComponent,
		GenerateReportComponent,
		OutboundQuarantinedComponent,
		PartnerUsageComponent,
		DummyComponent,
		InboundBlockFileTypesComponent,
		InboundConfigurationTabsComponent,
		InboundEmailRulesComponent,
		InboundShieldTabsComponent,
		OutboundReportTabsComponent,
		DataClassificationTabs,
		OutboundSensitivityTypesComponent,
		OutboundPersonalTypesComponent,
		OutboundTestSensitivityComponent,
		ScheduleReportComponent,
		InboundMailboxesComponent,
		UserManagementComponent,
		SentEmailsComponent,
		SentEmailDetailsComponent,
		SentEmailTrackingComponent,
		TrackingComponent,
		SentEmailSummaryComponent,
		SentEmailAdvancedComponent,
		SentEmailPostmarkComponent,
		AttachmentsComponent,
		InboundAllowBlockListsComponent,
		InboundNewRuleConditionComponent,
		AccountCompromisedTrendsComponent,
		DropdownComponent,
		InboundQuarantinedComponent,
		AuditLogsComponent,
		UrlTrackingComponent,
		MyProfileComponent,
		OutboundPoliciesComponent,
		RulesAndPoliciesTabsComponent,
		InboundPlanSettingsTabsComponent,
		InboundEmailRelayIntegrationComponent,
		UserInboundConfComponent,
		AuditLogScheduleReportComponent,
		UserQuarantinedComponent,
		ContentTooltipComponent,
		AutoCompleteMenuComponent,
		inputNumberDirective,
		DropdownPositionDirective,
		DisableAutoCOmpleteDirective,
		AccountIntegrationsComponent,
		SharedPlanSettingsComponent,
		ActivityLogsComponent,
		EmailRelayIntegrationComponent,
		OutboundPlanTabsComponent,
		ThreatResponseComponent,
		ApplyPolicyComponent,
		EmailTraceComponent,
		AccountApiComponent,
		LookAndFeelComponent,
		InboundTrendsComponent,
		ThreatPreventionComponent,
		ExchangeRulesWizardComponent,
		ExchangeProtectionWizardComponent,
		OutboundRulesComponent,
		ExchangeUpdateRulesWizardComponent,
		ViewExchangeCfgWizardComponent,
		ExchangeLoginWizardComponent,
		ExchangeQuarantinedEmailsWizardComponent,
		PlanSettingsDomainComponent,
		SecurityAwarenessComponent,
		DomainProtectionAuditLogsComponent,
		AccountDetailsNotificationsComponent,
		ScanThreatsComponent,
		OutboundTrendsComponent,
		SensitivityTypeComponent,
		NewRuleConditionComponent,
		OutboundRuleUsageComponent,
		ArchiveCasesComponent,
		ArchiveEmailHeadersComponent,
		ArchiveEmailTrackingComponent,
		ArchiveEmailQuarantinedComponent,
		AccountAddressesComponent,
		DomainSpoofingComponent,
		KnowledgeComponent,
		DmarcReportsComponent,
		AccountSecurityComponent,
		MyAccountTabsComponent,
		InboundConfigurationComponent,
		RegionComponent,
		IntegrationsComponent,
		ContactsComponent,
		ConnectionGraphComponent,
		BtnComponent,
		InboxComponent,
		AccountManagementComponent,
		AccountCompromisedConfigurationsComponent,
		AccountCompromisedReviewerConfigurationsComponent,
		AccountCompromisedAuditLogComponent,
		LoaderComponent,
		AliasesPopupComponent,
		GroupsPopupComponent,
		FormatBytes,
		SafeHtml,
		OrderArrayBy,
		CamelCasePipe,
		CamelCaseToTitleCasePipe,
		NgForFilterPipe,
		AmDateFormatPipe,
		LimitToPipe,
		UniquePipe,
		TitleizePipe,
		SanitizeTextPipe,
		GetTrackingEventPipe,
		SettingsListComponent,
		SettingsListItemComponent,
		CheckboxComponent,
		RadioBtnComponent,
		SearchBoxComponent,
		TrustifiTableComponent,
		PopupFrameComponent,
		ToggleSwitchComponent,
		ArchiveAuditLogComponent,
		ArchiveConfigurationTabsComponent,
		ArchiveConfigurationsGeneralComponent,
		ArchiveMigrationComponent,
		AutoFocusDirective,
		SelectAllText,
		Explanation,
		IsElps,
		HighlightTextDirective,
		FocusMeDirective,
		ContentTooltipDirective,
		NgInit,
		MaskDirective,
		ShownDirective,
		ScrollToTopFlagDirective,
		DropdownComponent,
		LeafletComponent,
		FilterTableComponent,
		ExplanationComponent,
		ExplanationComponent,
		DiscoveredServicesComponent,
		AccountCompromisedIncidentsComponent,
		AccountCompromisedUserIncidentsInfoComponent,
		accountCompromisedCfgTabsComponent,
		SensitivityDetailsComponent,
		DiscoveredServicesComponent,
		MyPlanComponent,
		PaypalButtonsComponent,
		DiscoveredServicesComponent,
		ArchiveCasesOperationsComponent,
		DatePickerComponent,
		ArchiveSearchComponent,
		ArchiveEmailDetailsComponent,
		MassListPageComponent,
		TemplatesPageComponent,
		AttachmentsManagerComponent,
		MassListPageComponent,
		ContactAddressBookComponent,
		previewLinkComponent,
		MoreInfoComponent,
		AttachmentComponent,
		OnboardingPopupComponent,
		OnboardingWizardStepsComponent,
		OnboardingQuestionnaireComponent,
  		OutboundReviewersComponent,
		ThreatSimulationTemplatesComponent,
		ThreatSimulationCampaignsComponent,
		ThreatSimulationCampaignsTabsComponent,
		ThreatSimulationAuditLogsComponent,
		ThreatSimulationCampaignWizardComponent,
		ThreatSimulationCampaignDetailsComponent,
		ThreatSimulationNotSupportedComponent,
		ThreatSimulationCampaignConfigurationComponent,
  		ThreatSimulationCampaignSummaryComponent,
		ThreatSimulationConfigComponent,
		ThreatSimulationsConfigTabsComponent,
		ThreatSimulationReviewersComponent,
		ThreatSimulationTrendsComponent,
		ThreatSimulationTemplateFieldsComponent,
		ThreatSimulationTrainingMaterialsComponent,
		ComposeMessageComponent,
		SignupComponent,
		PurchasePageComponent,
		CloudspongeComponent,
		AttachmentPreviewComponent,
		CountryCodeSelectComponent,
  		ClassificationTrendsComponent,
    	DomainImpersonationComponent,
		EmailViewerComponent
	],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        VirtualScrollerModule,
        TooltipModule.forRoot(defaultTooltipOptions as TooltipOptions),
        ClickOutsideModule,
        UpgradeModule,
        RestangularModule.forRoot(RestangularConfigFactory),
        HttpClientModule,
        NgApexchartsModule,
        ClipboardModule,
        ngfModule,
        AngularMyDatePickerModule,
        NgxSummernoteModule,
        DragDropModule,
        AppRoutingModule,
        ColorPickerModule,
        NgProgressModule,
        PdfViewerModule,
        NgxDocViewerModule,
        NgxUploaderModule,
        NgOptimizedImage
    ],
    providers: [
        RouteService,
        NotificationService,
		UserPermissionService,
        GeneralService,
		LookAndFeelService,
        AuthService,
        AccountCompromisedService,
        ArchiveService,
        ClioService,
        ComposeMessageService,
        DropboxService,
        myEmailsService,
		InboundTrendsService,

		// for loading bar
		importProvidersFrom(NgProgressHttpModule),
		provideHttpClient(withInterceptorsFromDi()),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
    }
}
