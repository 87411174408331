import {Injectable} from "@angular/core";
import {DICTIONARY} from "../dictionary";

@Injectable({
	providedIn: 'root'
})
export class LookAndFeelService {

	constructor() {
		this.setDefaultColorAndLogo();
		this.resetLfToDefault();
	}

	appDefaultColor;
	appDefaultLogo;

	hoverColor;
	textColor;
	opaqueColorLo;
	boldColor;
	color;
	logo;
	dic = DICTIONARY;

	resetLfToDefault = () => {
		this.applyLf(this.appDefaultColor, this.appDefaultLogo);
	}

	// set default color and logo depending on domain and subdomain value. this is happening only once.
	setDefaultColorAndLogo() {
		// subDomain is the original subdomain only for the domain name: 'trustifi'
		let subDomainName = this.getDomainAndSubdomain().domain === 'trustifi' ? this.getDomainAndSubdomain().subdomain :  this.getDomainAndSubdomain().domain;

		let subDomainObj = DICTIONARY.SUB_DOMAINS[subDomainName];
		if (subDomainObj) {
			this.appDefaultColor = subDomainObj.color || DICTIONARY.CONSTANTS.trustifiDefault.color;
			this.appDefaultLogo = subDomainObj.logo || DICTIONARY.CONSTANTS.trustifiDefault.logo.svg;
		}
		else {
			// default Trustifi settings
			this.appDefaultColor = DICTIONARY.CONSTANTS.trustifiDefault.color;
			this.appDefaultLogo = DICTIONARY.CONSTANTS.trustifiDefault.logo.svg;
		}
	};

	applyLf(lfColor, lfLogo) {
		const color = lfColor || this.appDefaultColor;
		const logo = lfLogo || this.appDefaultLogo;

		// change color
		this.applyColor(color);
		this.color = color;

		// change logo
		this.logo = logo;
	}

	private applyColor(color) {
		// get color HSL and RGB values
		const colorHSL = this.convertHexToHSLValues(color);
		const isDarkMode = document.body.getAttribute('data-theme') === 'dark';
		colorHSL[1] = isDarkMode ? Math.min(colorHSL[1], 33) : colorHSL[1];
		colorHSL[2] = isDarkMode ? Math.max(colorHSL[2], 55) : colorHSL[2];

		const colorRGB = parseHexColor(hslToHex(colorHSL[0], colorHSL[1], colorHSL[2]));

		this.color = color;

		// If the contrast value of white color and user color is 2 or above then apply text color: white. otherwise apply black
		// (NOTE: value of 2 is less then the minimum value of readability in the WCAG standard)
		const textColor = contrastValue(colorRGB,[255,255,255]) >= 2 ? "white" : "black";
		this.textColor = textColor;

		this.hoverColor = "hsl(" + colorHSL[0] + "," + colorHSL[1] + "%," + (colorHSL[2] + 15) + "%)";
		this.boldColor = "hsl(" + colorHSL[0] + "," + colorHSL[1] + "%," + (colorHSL[2] - 15) + "%)";
		this.opaqueColorLo = "hsl(" + colorHSL[0] + "," + colorHSL[1] + "%," + colorHSL[2] + "%,"+ 90+"%)"; // reduction of 10% in opacity

		document.documentElement.style.setProperty('--bs-primary-h', colorHSL[0] + '');
		document.documentElement.style.setProperty('--bs-primary-s', colorHSL[1] + '%');
		document.documentElement.style.setProperty('--bs-primary-l', colorHSL[2] + '%');
		document.documentElement.style.setProperty('--bs-primary-rgb', colorRGB[0] + ', ' + colorRGB[1] + ', ' + colorRGB[2]);
		document.documentElement.style.setProperty('--bs-text-color', textColor);
	}

	getDomainAndSubdomain() {
		const urlObj = new URL(location.origin);

		// Split hostname by dots
		const parts = urlObj.hostname.split('.');

		let domain = '';
		let subdomain = '';

		// Check if there is a subdomain
		if (parts.length > 2) {
			subdomain = parts.slice(0, -2).join('.');
			domain = parts.slice(-2, -1).join('.');
		} else {
			domain = parts.slice(-2, -1).join('.');
		}

		return { domain, subdomain };
	}

	convertHexToHSLValues(H) {
		// Convert hex to RGB first
		let r = 0, g = 0, b = 0;
		if (H.length === 4) {
			r = parseInt("0x" + H[1] + H[1], 16);
			g = parseInt("0x" + H[2] + H[2], 16);
			b = parseInt("0x" + H[3] + H[3], 16);
		} else if (H.length === 7) {
			r = parseInt("0x" + H[1] + H[2], 16);
			g = parseInt("0x" + H[3] + H[4], 16);
			b = parseInt("0x" + H[5] + H[6], 16);
		}
		// Then to HSL
		r /= 255;
		g /= 255;
		b /= 255;
		let cmin = Math.min(r,g,b),
			cmax = Math.max(r,g,b),
			delta = cmax - cmin,
			h = 0,
			s = 0,
			l = 0;

		if (delta === 0)
			h = 0;
		else if (cmax === r)
			h = ((g - b) / delta) % 6;
		else if (cmax === g)
			h = (b - r) / delta + 2;
		else
			h = (r - g) / delta + 4;

		h = Math.round(h * 60);

		if (h < 0)
			h += 360;

		l = (cmax + cmin) / 2;
		s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
		s = +(s * 100).toFixed(1);
		l = +(l * 100).toFixed(1);

		return  [h, s, l] ;
	}
}

// this function calculates the contrast value between two colors
// minimal recommended contrast ratio is 4.5, or 3 for larger font-sizes. Otherwise - readability will be bad
function contrastValue(BGcolor,textColor) {
	return contrast(BGcolor,textColor)
}

function luminance(r, g, b) {
	const a = [r, g, b].map((v) => {
		v /= 255;
		return v <= 0.03928
			? v / 12.92
			: Math.pow( (v + 0.055) / 1.055, 2.4 );
	});
	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
function contrast(rgb1, rgb2) {
	const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
	const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
	const brightest = Math.max(lum1, lum2);
	const darkest = Math.min(lum1, lum2);
	return (brightest + 0.05) / (darkest + 0.05);
}

function parseHexColor(col) {
	let num = parseInt(col.slice(1),16);
	return [num >> 16, (num >> 8) & 0x00FF, num & 0x0000FF];    //RGB
}

function hslToHex(h, s, l) {
	l /= 100;
	const a = s * Math.min(l, 1 - l) / 100;
	const f = n => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
	};
	return `#${f(0)}${f(8)}${f(4)}`;
}
