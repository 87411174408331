<div class="flxClmn">

	<div class="page-title-container flxClmn-center" style="z-index: 45;">
		<div class="page-title-inner-container">
			<span class="page-title">DMARC Analyzer</span>
		</div>
		<div class="page-subtitle-container">
			<span>Stay informed and secure with our DMARC aggregated report analysis tool. Identify DMARC failures, enhance email authentication, and improve delivery reliability to ensure safe and effective email communication.</span>
		</div>
	</div>

	<div class="page-inner-content-holder flxClmn bs-enabled" style="background: white; padding-top: 0; position: relative;"
		 [ngClass]="{'no-scroll' : !selectedDomain || (!trendsData?.total && !(dmarcDnsPopup && dmarcDnsPopup.connected))}"
		 [ngStyle]="{'overflow' : generatePdfInProcess ? 'hidden' : ''}">

		<!--dmarc premium ad-->
		<!--<ng-container *ngIf="dmarcSettings.dmarc_reports.type === dic.CONSTANTS.dmarcType.basic">
			<div class="flex-row align-items-center gap-2 p-2">
				<i class="fa fa-info-circle opacity-75"></i>
				<span class="fw-bold align-self-start">You are using the basic DMARC package</span>
			</div>
			<div class="flex-row align-items-center gap-5 mb-4 border border-2 border-end-0 border-start-0 px-3 py-2 align-self-start"
				 style="background: linear-gradient(-90deg, #f1fff4, white, #fbffe9); border-color: #6e5600;">
			<span class="fw-bold" style="font-size: 16px; filter: drop-shadow(-1px 1px 1px yellow) drop-shadow(-1px -1px 0px yellow); color: darkslateblue; letter-spacing: 1.4px;">
				DMARC Analyzer Premium
			</span>
				<div class="flex-column gap-1" style="font-size: 12px;">
					<div class="flex-row align-items-center gap-2">
						<i class="fa fa-star" style="font-size: 9px;"></i>
						<span>Analyze <b>unlimited</b> number of records (Basic: Maximum of 100)</span>
					</div>
					<div class="flex-row align-items-center gap-2">
						<i class="fa fa-star" style="font-size: 9px;"></i>
						<span>Analyze <b>unlimited</b> number of domains (Basic: Maximum of 3)</span>
					</div>
				</div>
				<div class="flex-column gap-1">
					<div class="flex-row align-items-center gap-2">
						<i class="fa fa-star" style="font-size: 9px;"></i>
						<span>Analyze by custom date</span>
					</div>
					<div class="flex-row align-items-center gap-2">
						<i class="fa fa-star" style="font-size: 9px;"></i>
						<span>Export to PDF</span>
					</div>
				</div>
				<a class="blue-link fw-bold ms-4">contact support for upgrade</a>
			</div>
		</ng-container>-->


		<div class="flex-column f-1-0 overflow-auto">
			<!--generate pdf loader-->
			<div *ngIf="generatePdfInProcess" class="pdf-generate-loader">
				<div class="animated flxClmn vSpace2 infinite"
					 style="position: absolute; top: calc(50vh - 200px - 40px); left: calc(50vw - 70px); transform: translate(-50%,0); font-size: 4.3rem; opacity: 0.5; align-items: center;">
					<div class="animated bounce infinite">
						<i class="far fa-file-pdf grey-to-redFX" style="filter: contrast(1.5) drop-shadow(2px -1px 1px lightgrey);"></i>
					</div>
					<span style="position: relative; font-size: 1.3rem;" class="loading-dots sss">Generating PDF. Please wait</span>
				</div>
			</div>
			<!---->

			<div class="flex-row align-items-center gap-2 bg-light p-2 ps-3 mb-3 pdf-excluded">
				<span class="fw-bold">Select Domain:&nbsp;</span>
				<app-dropdown-c style="width: 250px;"
							  [alwaysDrop]="true"
							  [isDisabled]="getTrendsDataInProcess || !configuredDomains.length"
							  text="{{selectedDomain || 'Select'}}"
							  [enableSearch]="true"
							  itemDisplayKey="domain"
							  [items]="configuredDomains"
							  (onItemClicked)="changeSelectedDomain($event.item)"></app-dropdown-c>

				<btn-c (action)="openAddNewDomainPopup();" endIcon="fa fa-plus">
					Add New Domain
				</btn-c>
				<btn-c *ngIf="selectedDomain"
					 [isDisabled]="getTrendsDataInProcess"
					 variant="outlined" [loading]="validateDmarcInProcess"
					 (action)="isDmarcDnsReportValidated(selectedDomain);">
					Validate DMARC DNS Record
				</btn-c>
				<div class="f-1-0"></div>
				<btn-c style="min-width: 0;"
					 *ngIf="selectedDomain"
					 variant="text" color="danger"
					 endIcon="far fa-trash-alt"
					 (action)="removeDomain(selectedDomain);">
					Remove Domain
				</btn-c>
			</div>

			<div class="flxRow-between gap-2 mt-0 animated fadeIn" style="position: relative; z-index: 45; align-items: center; margin: 20px 0;"
				 *ngIf="selectedDomain">
				<div class="period-buttons-group flxRow hSpace1">
					<ng-container *ngFor="let key of dashboardPeriod | keyvalue;">
						<button class="period-button bs-btn bs-btn-light h-100"
							   *ngIf="key.value.display !== dic.CONSTANTS.trendsPeriod.range.display"
								[ngClass]="{'chosen' : period === key.value, 'disabled' : !selectedDomain}"
								(click)="changePeriod(key.value);">
							{{key.value.display}}
						</button>
					</ng-container>
					<div class="position-relative range-button">
						<button class="period-button hSpace05 bs-btn bs-btn-light h-100" id="customPeriodBtn"
								(click)="showTrendsCustomPeriod = !showTrendsCustomPeriod;"
								[ngClass]="{'chosen' : period.display === dic.CONSTANTS.trendsPeriod.range.display, 'disabled' : !selectedDomain}">
							<span
								*ngIf="period.display === dic.CONSTANTS.trendsPeriod.range.display"
								style="font-weight: 300;">
								From <b class="px-2">{{range.start | date:'MM/dd/yyyy'}}</b> Until <b
								class="px-2">{{range.end | date:'MM/dd/yyyy'}}</b>
							</span>
								<span *ngIf="period.display !== dic.CONSTANTS.trendsPeriod.range.display">
									Custom
								</span>
								<i class="fa fa-sort-down" style="margin-top: -5px;"></i>
						</button>
						<!--custom period modal-->
						<div class="drop-menu mt-1 p-2"
							 style="width: fit-content; max-width: none; min-width: 500px; overflow: visible; min-height: 0;"
							 [ngClass]="{'active' : showTrendsCustomPeriod}">
							<div class="p-3 text-start m-0 flex-row align-items-center gap-2">
								<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
									<span>Date Range:</span>
									<date-picker-c class="f-1-0"
												   [(dateModel)]="range"
												   [isRange]="true"
												   [dateFormat]="'mm/dd/yyyy'">
									</date-picker-c>
								</div>
								<btn-c endIcon="zmdi zmdi-check"
									 [loading]="getTrendsDataInProcess"
									 (action)="changePeriod(dic.CONSTANTS.trendsPeriod.range);">
									Get Report
								</btn-c>
							</div>
						</div>
					</div>
				</div>

				<btn-c
					endIcon="fas fa-file-download" variant="outlined"
					 [isDisabled]="!trendsData || !trendsData.total || generatePdfInProcess || getTrendsDataInProcess || !selectedDomain"
					 (action)="exportChartsToPdf();">
					Export to PDF
				</btn-c>
			</div>

			<div class="bs-enabled" id="trendsChartsContainer" *ngIf="selectedDomain && (trendsData?.total || (dmarcDnsPopup && dmarcDnsPopup.connected))">
				<div class="bs-container dashboard-page pb-2 text-center" style="max-width: none !important;"
					 [ngClass]="{'w-100' : !generatePdfInProcess}">
					<div class="bs-row trends-pdf-page-title" style="display: none;">
						<img class="mb-3" [src]="lfs.logo" style="width: 200px;"/>
					</div>
					<div class="bs-row trends-pdf-page-title" style="display: none;">
						<div class="bs-col-12 mb-4 px-4" style="background: #fdfdfd; padding: 10px;">
							<div class="flxClmn vSpace05">
								<div class="flxRow hSpace05" style="font-size: 1.25rem;">
									<span>Domain:&nbsp;</span>
									<span>{{selectedDomain}}</span>
								</div>
								<div class="flxRow-between" style="align-items: flex-end;">
									<span style="font-weight: bold; font-size: 1.6rem; white-space: nowrap;">DMARC Report</span>
									<span style="font-size: 1.25rem; white-space: nowrap;">
									Date printed: &nbsp;
									<time>{{todayDate | date:'MM/dd/yyyy'}}</time>
								</span>
								</div>
								<div class="flxRow hSpace05" style="font-size: 1.25rem;">
									<span>Period:&nbsp;</span>
									<span *ngIf="period !== dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">{{period.display}}</span>
									<span *ngIf="period === dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">
									<time>{{range.start | date:'MM/dd/yyyy'}}</time>
									&nbsp;-&nbsp;
									<time>{{range.end | date:'MM/dd/yyyy'}}
									</time>
								</span>
								</div>
							</div>
						</div>
					</div>

					<div class="bs-row g-4">
						<!--DMARC Volume distribution-->
						<div class="bs-col-6 bs-col-lg-6 bs-col-xxl-5" style="height: clamp(350px,38vh,405px);">
							<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
								<div class="flex-column align-items-start gap-1">
									<h6 class="m-0 fw-bold text-nowrap">DMARC Email Volume Distribution</h6>
									<span class="opacity-75 fw-500 text-start">Displays the volume of emails sent on behalf of this domain for the selected time period, distributed by DMARC compliance.</span>
								</div>
								<apx-chart class="f-1-0 animated fadeIn"
										   *ngIf="dmarcEmailVolumeGraphOptions && trendsData?.total && !getTrendsDataInProcess"
										   [series]="dmarcEmailVolumeGraphOptions.series"
										   [chart]="dmarcEmailVolumeGraphOptions.chart"
										   [dataLabels]="dmarcEmailVolumeGraphOptions.dataLabels"
										   [stroke]="dmarcEmailVolumeGraphOptions.stroke"
										   [colors]="dmarcEmailVolumeGraphOptions.colors"
										   [xaxis]="dmarcEmailVolumeGraphOptions.xaxis"></apx-chart>
								<!--loader / placeholder-->
								<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.total || getTrendsDataInProcess">
									<div class="flex-column text-center centralize f-1-0">
										<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
										<loader class="opacity-75" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
									</div>
								</div>
							</div>
						</div>

						<!--Senders complaint-->
						<div class="bs-col-6 bs-col-lg-6 bs-col-xxl-5" style="height: clamp(350px,38vh,405px);" (ngInit)="senderCompliantViewType = 'non-compliant'">
							<div class="trends-data-card flex-column gap-1 p-3 animated fadeIn h-100 w-100">
								<div class="flex-row justify-content-between gap-3">
									<div class="flex-column align-items-start gap-1">
										<h6 class="m-0 fw-bold">Sender DMARC Compliance</h6>
										<span class="opacity-75 fw-500 text-start">Displays the top 10 senders that send the most emails on behalf of this domain, distributed by amount of emails sent and categorized by compliance.</span>
									</div>
									<div class="period-buttons-group align-self-center flex-row flex-shrink-0 gap-2 animated fadeIn"
										 *ngIf="trendsData?.total">
										<button class="period-button bs-btn bs-btn-light text-capitalize"
												*ngFor="let viewType of ['compliant','non-compliant']"
												[ngClass]="{'chosen' : viewType === senderCompliantViewType}"
												(click)="senderCompliantViewType = viewType;">
											{{viewType}}
										</button>
									</div>
								</div>
								<div class="flex-column f-1-0 centralize" *ngIf="trendsData?.total && !getTrendsDataInProcess && !(senderCompliantViewType === 'non-compliant' && !nonCompliantBySenderGraphOptions.series.length) && !(senderCompliantViewType === 'compliant' && !compliantBySenderGraphOptions.series.length)">
									<apx-chart class="pie-chart animated fadeIn" style="flex: 1 0; max-height: 270px; width: 100%; max-width: 700px;"
											   *ngIf="senderCompliantViewType === 'compliant'"
											   [series]="compliantBySenderGraphOptions.series"
											   [chart]="compliantBySenderGraphOptions.chart"
											   [plotOptions]="compliantBySenderGraphOptions.plotOptions"
											   [dataLabels]="compliantBySenderGraphOptions.dataLabels"
											   [labels]="compliantBySenderGraphOptions.labels"></apx-chart>
									<apx-chart class="pie-chart animated fadeIn" style="flex: 1 0; max-height: 270px; width: 100%; max-width: 700px;"
											   *ngIf="senderCompliantViewType === 'non-compliant'"
											   [series]="nonCompliantBySenderGraphOptions.series"
											   [chart]="nonCompliantBySenderGraphOptions.chart"
											   [plotOptions]="nonCompliantBySenderGraphOptions.plotOptions"
											   [dataLabels]="nonCompliantBySenderGraphOptions.dataLabels"
											   [labels]="nonCompliantBySenderGraphOptions.labels"></apx-chart>
								</div>
								<!--loader / placeholder-->
								<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.total || getTrendsDataInProcess || (senderCompliantViewType === 'non-compliant' && !nonCompliantBySenderGraphOptions.series.length) || (senderCompliantViewType === 'compliant' && !compliantBySenderGraphOptions.series.length)">
									<div class="flex-column text-center centralize f-1-0">
										<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
										<loader class="opacity-75" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
									</div>
								</div>
							</div>
						</div>

						<!--Email status-->
						<div class="bs-col-6 bs-col-lg-3 bs-col-xxl-2" style="height: clamp(350px,38vh,405px);">
							<div class="trends-data-card flex-column gap-1 p-3 animated fadeIn h-100 w-100">
								<div class="flex-row justify-content-between gap-3">
									<div class="flex-column align-items-start gap-1">
										<h6 class="m-0 fw-bold">Email Distribution</h6>
										<span class="opacity-75 fw-500 text-start" style="word-break: break-word;">Displays the distribution of DMARC email decision statuses.</span>
									</div>
								</div>
								<div *ngIf="trendsData?.total && !getTrendsDataInProcess">
									<br/>
									<table class="simpleDataTable" style="table-layout: fixed; font-size: 15px;">
										<tbody>
										<tr>
											<td>{{trendsData.emailStatus.labels[0]}}</td>
											<td style="color: green;">{{trendsData.emailStatus.data[0]}}</td>
										</tr>
										<tr>
											<td>{{trendsData.emailStatus.labels[1]}}</td>
											<td style="color: orange;">{{trendsData.emailStatus.data[1]}}</td>
										</tr>
										<tr>
											<td>{{trendsData.emailStatus.labels[2]}}</td>
											<td style="color: red;">{{trendsData.emailStatus.data[2]}}</td>
										</tr>
										</tbody>
									</table>
								</div>
								<!--loader / placeholder-->
								<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.total || getTrendsDataInProcess">
									<div class="flex-column text-center centralize f-1-0">
										<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
										<loader class="opacity-75" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
									</div>
								</div>
							</div>
						</div>

						<!--Senders table-->
						<div class="bs-col-12 bs-col-large-8" style="height: clamp(350px,50vh,405px);" [ngStyle]="{'order' : generatePdfInProcess ? '1' : ''}">
							<div class="trends-data-card flex-column align-items-start gap-1 p-3 animated fadeIn h-100 w-100">
								<h6 class="m-0 fw-bold">Sending Source Breakdown</h6>
								<div class="flex-column f-1-0 mt-2 p-2 pt-0 animated fadeIn overflow-auto" *ngIf="trendsData?.total && !getTrendsDataInProcess">
									<trustifi-table-c
										class="trustifi-table dmarc-sources-table" style="flex: 1 0; z-index: 5; overflow: auto; height: 100%;"
										[list]="allSenders"
										[headers]="[
											{text:'Sender', sortKey:'domain', width: '150px'},
											{text:'Volume', sortKey:'count', width: '100px'},
											{text:'Analysis', sortKey:'analysis', tooltip: 'Analysis based on the sender authentication results'},
											{text:'Delivery Rate', sortKey:'deliveryRate', tooltip: 'The total delivery rate from this source'},
											{text:'DMARC', width: '120px', sortKey:'dmarcPass', tooltip: 'DMARC status is achieved through SPF, DKIM, or both aligning with the email\'s origin, ensuring authentication'},
											{text:'SPF', width: '100px', sortKey:'spfPass', tooltip: 'SPF status is achieved by configuring DNS SPF records to verify your domain\'s legitimacy and by checking senders\' IP addresses to prevent email spoofing'},
											{text:'DKIM', width: '100px', sortKey:'dkimPass', tooltip: 'DKIM status is achieved by implementing DKIM signatures in your email authentication, which involves encrypting and verifying messages to enhance email security and prevent spoofing'},
											{text:'IP Count', sortKey:'ipCount', tooltip: 'The IP Count is the unique number of IP addresses linked to a specific source/sender in the report'}
										]"
										[cellsPrototype]="[
											{html: 'senderCell'},
											{textKey: 'count'},
											{html: 'analysisCell'},
											{html: 'deliveryRateCell'},
											{html: 'dmarcPassCell'},
											{html: 'spfPassCell'},
											{html: 'dkimPassCell'},
											{textKey: 'ipCount'},
										]"
										(searchFunction)="searchSenders($event.searchTerm, $event.activeFilters)"
										[filterData]="filterData"
										[scopeInstances]="[{getDmarcRecordsBySenderForDomain: getDmarcRecordsBySenderForDomain, analysisColorsDic: analysisColorsDic}]"
										[options]="{
											loadingTableFlag: getTrendsDataInProcess,
											exportToCsvFunction: exportToCsv,
											itemsNameSingular: 'Sender',
											defaultSortKey: '-count',
											hideCounter: true
										}">

										<ng-template #customCells let-item let-cellId="cellId">
											<!--action cells-->
											<div *ngIf="cellId === 'senderCell'">
												<span (click)="getDmarcRecordsBySenderForDomain(item.name)" class="hSpace05">
													<img *ngIf="item.domain" style="height: 20px;" [src]="'https://www.google.com/s2/favicons?domain=' + item.domain">
													<span style="color: blue; cursor: pointer; text-decoration: underline;">{{item.name}}</span>
												</span>
											</div>

											<div *ngIf="cellId === 'analysisCell'">
												<div class="flxRow threat-tag not-clickable hSpace05" style="align-items: center; width: 75px;"
													 *ngIf="item.analysis"
													 tooltip="{{item.unverifiedSender ? 'The sender is unverified and may not be legitimate' : item.onBehalfSender ? 'The sender appears to redirect/forward authentic emails on your behalf' : item.authenticSender ? 'The sender appears to be an authentic service' : ''}}"
													 [ngClass]="analysisColorsDic[item.analysis]"
													 [ngStyle]="{'color': analysisColorsDic[item.analysis]}">
													<div class="threat-tag-BG"></div>
													<span class="threat-tag-text f-1-0 m-0 text-center" style="font-size: inherit; text-transform: capitalize;">{{item.analysis}}</span>
												</div>
											</div>

											<span *ngIf="cellId === 'deliveryRateCell'">
												{{item.deliveryRate}}%
											</span>

											<span *ngIf="cellId === 'dmarcPassCell'">
												{{item.dmarcPass}}%
											</span>

											<span *ngIf="cellId === 'spfPassCell'">
												{{item.spfPass}}%
											</span>

											<span *ngIf="cellId === 'dkimPassCell'">
												{{item.dkimPass}}%
											</span>
										</ng-template>
										<!---->

										<div tableButtons class="d-flex">
											<span class="opacity-75 fw-500 text-start">List and display information about email sources by domain, including their delivery success rates and authentication results (SPF, DKIM, DMARC)</span>
										</div>

									</trustifi-table-c>
								</div>

								<!--loader / placeholder-->
								<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.total || getTrendsDataInProcess">
									<div class="flex-column text-center centralize f-1-0">
										<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data available</span>
										<loader class="opacity-75" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
									</div>
								</div>
							</div>
						</div>

						<!--Recipients of Unverified Emails-->
						<div class="bs-col-6 bs-col-large-4" style="height: clamp(350px,50vh,405px);">
							<div class="trends-data-card flex-column gap-1 p-3 animated fadeIn h-100 w-100">
								<div class="flex-row justify-content-between gap-3">
									<div class="flex-column align-items-start gap-1">
										<h6 class="m-0 fw-bold">Recipients of Unverified Emails</h6>
										<span class="opacity-75 fw-500 text-start">Recipient domains who received emails from your domain that were unverified based on DMARC reports.</span>
									</div>
								</div>
								<div class="flex-column f-1-0 centralize" *ngIf="trendsData?.total && !getTrendsDataInProcess">
									<apx-chart class="pie-chart animated fadeIn" style="flex: 1 0; max-height: 270px; width: 100%; max-width: 700px;"
											   [series]="topAttackedDomainsGraphOptions.series"
											   [chart]="topAttackedDomainsGraphOptions.chart"
											   [plotOptions]="topAttackedDomainsGraphOptions.plotOptions"
											   [dataLabels]="topAttackedDomainsGraphOptions.dataLabels"
											   [labels]="topAttackedDomainsGraphOptions.labels"></apx-chart>
								</div>
								<!--loader / placeholder-->
								<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.total || getTrendsDataInProcess">
									<div class="flex-column text-center centralize f-1-0">
										<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
										<loader class="opacity-75" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--Configure your DMARC screen-->
			<div class="flex-column centralize gap-3 f-1-0 overflow-auto" style="background: radial-gradient(#f1f1f1, white); font-size: 0.95rem;"
				 *ngIf="selectedDomain && !trendsData?.total && !getTrendsDataInProcess && !validateDmarcInProcess && dmarcDnsPopup && !dmarcDnsPopup.connected">
				<div class="flex-column centralize gap-3 overflow-auto">
					<span>Your DMARC DNS record is not configured to send us your reports.</span>
					<span>To receive DMARC reports, modify your DMARC DNS record as detailed below:</span>
					<table class="simpleDataTableShrink bg-light" style="color: darkslategrey; table-layout: fixed; width: 800px; max-width: 100%;">
						<tr>
							<th style="width: 90px;" class="border border-secondary p-2"><span>Type</span></th>
							<th class="border border-secondary p-2"><span>Host</span></th>
							<th class="border border-secondary p-2"><span>Record Value</span></th>
						</tr>
						<tr>
							<td class="border border-secondary p-2"><span>TXT</span></td>
							<td class="border border-secondary p-2">
								<div class="flex-row gap-2">
									<span style="word-break: break-word;">_dmarc.{{selectedDomain}}</span>
									<a (click)="copyToClipboard('_dmarc.'+selectedDomain);">
										<i class="far fa-copy copy-link" tooltip="Copy host"></i>
									</a>
								</div>
							</td>
							<td class="border border-secondary p-2">
								<div class="flex-row gap-2">
									<span style="word-break: break-word;">{{dmarcDnsPopup.trustifiDmarc}}</span>
									<a (click)="copyToClipboard(dmarcDnsPopup.trustifiDmarc);">
										<i class="far fa-copy copy-link" tooltip="Copy value"></i>
									</a>
								</div>
							</td>
						</tr>
					</table>
					<span>Then click this button to validate your record is configured correctly</span>
					<br/>
					<btn-c style="font-size: 0.83rem;"
						 [loading]="validateDmarcInProcess"
						 (action)="isDmarcDnsReportValidated(selectedDomain);">
						Validate DMARC DNS Record
					</btn-c>
				</div>
			</div>

			<!--CONTENT: select domain or add new one-->
			<div class="flex-column centralize text-center f-1-0 overflow-auto" style="font-size: 20px;" *ngIf="!selectedDomain && !getTrendsDataInProcess">
				<span *ngIf="!configuredDomains.length">
					Click on <b class="color-primary" style="cursor: pointer;" (click)="openAddNewDomainPopup();">Add New Domain</b> to configure new domains for DMARC report.
				</span>
				<span *ngIf="configuredDomains.length">
					Select a domain to view reports, or click on <b class="color-primary" style="cursor: pointer;" (click)="openAddNewDomainPopup();">Add New Domain</b> to configure new domains for DMARC reports.
				</span>
			</div>

			<!--CONTENT: select domain or add new one-->
			<div class="flex-column centralize text-center f-1-0 overflow-auto" *ngIf="getTrendsDataInProcess">
				<loader class="opacity-75" height="100" width="100"></loader>
			</div>
		</div>
	</div>

	<!--Configure New Domain popup-->
	<popup-c class="overflownV bs-enabled" *ngIf="addNewDomainPopup?.show"
		   head="Add New Domain" size="dynamic"
		   (closeCb)="addNewDomainPopup = null;"
		   (confirmCb)="addNewDomain();"
		   [buttons]="{cancel: 'Cancel', confirm: {text: 'Add', loading: doDmarcActionInProcess}}">
		<div class="flxClmn vSpace3 f-1-0" style="overflow: visible;">
			<span>Add a domain from the list below to configure DMARC reports for the domain.<br/>
				The DMARC reports will be sent to Trustifi to be analyzed and displayed on this page.
			</span>
			<br/>
			<div class="flex-row align-items-center gap-2">
				<span class="fw-bold">Select Domain:&nbsp;</span>
				<app-dropdown-c style="width: 250px;"
							  [isDisabled]="getTrendsDataInProcess"
							  text="{{addNewDomainPopup.selectedDomain.domain || 'Select'}}"
							  [enableSearch]="true"
							  [alwaysDrop]="true"
							  itemDisplayKey="domain"
							  [items]="addNewDomainPopup.allowedDomains"
							  (onItemClicked)="addNewDomainPopup.selectedDomain = $event.item"></app-dropdown-c>
			</div>
		</div>
	</popup-c>

	<!--DMARC Validation popup-->
	<popup-c class="overflownV bs-enabled" *ngIf="dmarcDnsPopup?.show"
		   head="DMARC Validation" size="dynamic" width="600px"
		   (closeCb)="dmarcDnsPopup.show = false;"
		   [buttons]="{cancel: 'OK'}">
		<div class="flxClmn vSpace3 f-1-0" style="overflow: visible;">
			<span class="text-danger fw-bold"  *ngIf="dmarcDnsPopup?.errMessage">
				<span style="font-size: 1rem;">Validation failed!</span>
				<br/><br/>
				{{dmarcDnsPopup.errMessage}}
			</span>
			<span class="text-success fw-bold" style="font-size: 1rem;" *ngIf="dmarcDnsPopup.connected && !dmarcDnsPopup.errMessage">
				Validation Succeeded!
			</span>
			<div class="flex-column gap-2" *ngIf="dmarcDnsPopup.record">
				<span class="fw-bold">Current record:</span>
				<table class="simpleDataTableShrink bg-light" style="color: darkslategrey; table-layout: fixed;">
					<tr>
						<th style="width: 50px;" class="border border-secondary p-2"><span>Type</span></th>
						<th style="width: 33%;" class="border border-secondary p-2"><span>Host</span></th>
						<th class="border border-secondary p-2"><span>Record Value</span></th>
					</tr>
					<tr>
						<td class="border border-secondary p-2"><span>TXT</span></td>
						<td class="border border-secondary p-2">
							<div class="flex-row gap-2">
								<span style="word-break: break-word;">_dmarc.{{selectedDomain}}</span>
								<a (click)="copyToClipboard(('_dmarc.'+selectedDomain))">
									<i class="far fa-copy copy-link" tooltip="Copy host"></i>
								</a>
							</div>
						</td>
						<td class="border border-secondary p-2">
							<div class="flex-row gap-2">
								<span style="word-break: break-word;">{{dmarcDnsPopup.record}}</span>
								<a (click)="copyToClipboard(dmarcDnsPopup.record);">
									<i class="far fa-copy copy-link" tooltip="Copy value"></i>
								</a>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<br/>
			<div class="flex-column gap-2" *ngIf="dmarcDnsPopup.errMessage">
				<span class="fw-bold" >Required record:</span>
				<table class="simpleDataTableShrink bg-light" style="color: darkslategrey; table-layout: fixed;">
					<tr>
						<th style="width: 50px;" class="border border-secondary p-2"><span>Type</span></th>
						<th style="width: 33%;" class="border border-secondary p-2"><span>Host</span></th>
						<th class="border border-secondary p-2"><span>Record Value</span></th>
					</tr>
					<tr>
						<td class="border border-secondary p-2"><span>TXT</span></td>
						<td class="border border-secondary p-2">
							<div class="flex-row gap-2">
								<span style="word-break: break-word;">_dmarc.{{selectedDomain}}</span>
								<a (click)="copyToClipboard(('_dmarc.'+selectedDomain))">
									<i class="far fa-copy copy-link" tooltip="Copy host"></i>
								</a>
							</div>
						</td>
						<td class="border border-secondary p-2">
							<div class="flex-row gap-2">
								<span style="word-break: break-word;">{{dmarcDnsPopup.trustifiDmarc}}</span>
								<a (click)="copyToClipboard(dmarcDnsPopup.trustifiDmarc);">
									<i class="far fa-copy copy-link" tooltip="Copy value"></i>
								</a>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</popup-c>

	<popup-c class="bs-enabled" *ngIf="dmarcSourceRecordsPopup?.show"
		   head="Records for {{dmarcSourceRecordsPopup.senderName}}"
		   size="large" width="1700px"
		   (closeCb)="dmarcSourceRecordsPopup.show = false;"
		   [buttons]="{cancel: 'OK'}">
		<div class="flxClmn f-1-0" style="overflow: auto;">
			<trustifi-table-c
			class="trustifi-table dmarc-sources-table" style="flex: 1 0; z-index: 5; overflow: auto; height: 100%;"
			[list]="dmarcSourceRecordsPopup.records"
			[headers]="[
				{text:'Report Created', sortKey:'created'},
				{text:'Sender IP', sortKey:'sender_ip'},
				{text:'Sender Domains', sortKey:'sender_header_from', tooltip: 'Sender domain identities (Header From, MAIL FROM)'},
				{text:'Volume', sortKey:'count', width: '100px'},
				{text:'Reporter', sortKey:'reporter_org_name'},
				{text:'Recipient Domain', sortKey:'recipient_domain'},
				{text:'Analysis', sortKey:'analysis', tooltip: 'Analysis based on the sender authentication results'},
				{text:'Disposition', sortKey:'policy_evaluated.disposition', tooltip: 'Determines what happens to your email: delivered, quarantined, or rejected, based on authentication results'},
				{text:'DMARC Result', sortKey:'dmarcPass', tooltip: 'DMARC status is achieved through SPF, DKIM, or both aligning with the email\'s origin, ensuring authentication'},
				{text:'SPF Result', sortKey:'spfPass', tooltip: 'SPF Status is achieved by configuring DNS SPF records to verify your domain\'s legitimacy and by checking senders\' IP addresses to prevent email spoofing'},
				{text:'DKIM Result', sortKey:'dkimPass', tooltip: 'DKIM Status is achieved by implementing DKIM signatures in your email authentication, which involves encrypting and verifying messages to enhance email security and prevent spoofing'},
			]"
			[cellsPrototype]="[
				{textKey: 'created', textType: 'dateAndTime'},
				{textKey: 'sender_ip'},
				{html: 'senderDomainCell'},
				{textKey: 'count'},
				{textKey: 'reporter_org_name'},
				{textKey: 'recipient_domain', placeHolder: 'N/A'},
				{html: 'analysisCell2'},
				{html: 'dispositionCell2'},
				{html: 'dmarcPassCell2'},
				{html: 'spfPassCell2'},
				{html: 'dkimPassCell2'},
			]"
			(searchFunction)="searchSenderRecords($event.searchTerm, $event.activeFilters)"
			[filterData]="filterDataRecords"
			[scopeInstances]="[{analysisColorsDic: analysisColorsDic, dispositionColorsDic: dispositionColorsDic}]"
			[options]="{
				loadingTableFlag: dmarcSourceRecordsPopup.inProcess,
				exportToCsvFunction: exportSenderRecordsToCsv,
				itemsNameSingular: 'Sender',
				defaultSortKey: '-created',
				hideCounter: true
			}">

			<ng-template #customCells let-item let-cellId="cellId">
				<!--action cells-->
				<div *ngIf="cellId === 'analysisCell2'">
					<div class="flxRow threat-tag not-clickable hSpace05" style="align-items: center; width: 75px;"
						 *ngIf="item.analysis"
						 tooltip="{{item.unverifiedSender ? 'The sender is unverified and may not be legitimate' : item.onBehalfSender ? 'The sender appears to redirect/forward authentic emails on your behalf' : item.authenticSender ? 'The sender appears to be an authentic service' : ''}}"
						 [ngClass]="analysisColorsDic[item.analysis]"
						 [ngStyle]="{'color': analysisColorsDic[item.analysis]}">
						<div class="threat-tag-BG"></div>
						<span class="threat-tag-text f-1-0 m-0 text-center" style="font-size: inherit; text-transform: capitalize;">{{item.analysis}}</span>
					</div>
				</div>

				<div *ngIf="cellId === 'senderDomainCell'">
					<span>{{item.sender_header_from}}</span><br/>
					<span *ngIf="item.sender_header_from !== item.sender_envelope_from && item.sender_envelope_from !== '<>'">{{item.sender_envelope_from}}</span>
				</div>

				<div *ngIf="cellId === 'dispositionCell2'">
					<div class="flxRow threat-tag not-clickable hSpace05" style="align-items: center;"
						 tooltip="{{item.policy_evaluated.disposition === 'none' ? 'The email was delivered successfully to the recipient\'s mailbox' : item.policy_evaluated.disposition === 'quarantine' ? 'The email was quarantined by the recipient\'s mail server' : item.policy_evaluated.disposition === 'reject' ? 'The email was rejected by the recipient\'s mail server' : 'The email status is unknown'}}"
						 [ngClass]="dispositionColorsDic[item.policy_evaluated.disposition]"
						 [ngStyle]="{'color': dispositionColorsDic[item.policy_evaluated.disposition]}">
						<div class="threat-tag-BG"></div>
						<span class="threat-tag-text f-1-0 m-0 text-center" style="font-size: inherit; text-transform: capitalize;">{{item.policy_evaluated.disposition === 'none' ? 'Delivery' : item.policy_evaluated.disposition || 'Unknown'}}</span>
					</div>
				</div>

				<div *ngIf="cellId === 'dmarcPassCell2'">
					<span [ngStyle]="{'color': item.dmarcPass ? 'green' : 'red'}">{{item.dmarcPass ? 'Pass' : 'Fail'}}</span>
				</div>

				<div *ngIf="cellId === 'spfPassCell2'">
					<span [ngStyle]="{'color': item.spfPass === 'none' ? 'gray' : item.spfPass === 'pass' ? 'green' : 'red'}" style="text-transform: capitalize;">{{item.spfPass}}</span>
				</div>

				<div *ngIf="cellId === 'dkimPassCell2'">
					<span [ngStyle]="{'color': item.dkimPass === 'none' ? 'gray' : item.dkimPass === 'pass' ? 'green' : 'red'}" style="text-transform: capitalize;">{{item.dkimPass}}</span>
				</div>
			</ng-template>
		</trustifi-table-c>
		</div>
	</popup-c>
</div>
