import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";

import 'leaflet-webgl-heatmap/dist/leaflet-webgl-heatmap.min.js';
import 'leaflet-webgl-heatmap/src/webgl-heatmap/webgl-heatmap.js';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
