<div class="flxClmn bs-enabled animated fadeIn" style="flex: 1 0; overflow: auto; padding: 0 15px;">

	<div class="flex-column gap-2 overflow-auto f-1-0" style="font-size: 14px;">
		<div class="flex-row threat-simulation-wizard-summary position-relative" style="background: linear-gradient(90deg, #486db51a, #486db500);">
			<div class="flex-column gap-2 f-1-0 py-2 px-3">
				<h4 style="color: black; margin-bottom: 15px;">
					{{currentCampaign.name}}
				</h4>

				<!--Created-->
				<div class="flex-row gap-1">
					<span class="fw-500" style="flex-basis: 92px; flex-shrink: 0;">Created: </span>
					<span>{{currentCampaign.created | date: 'MM/dd/yyyy HH:mm'}}</span>
				</div>

				<!--Send time and status-->
				<div class="flex-row align-items-center gap-1" style="height: 16px;" *ngIf="sendTime">
					<span class="fw-500" style="flex-basis: 92px; flex-shrink: 0;">{{campaignStatus === campaignStatuses.scheduled ? 'Scheduled for' : 'Sent at'}}: </span>
					<span>{{sendTime | date: 'MM/dd/yyyy HH:mm'}}</span>
					<span class="px-3 py-1 ms-2 rounded-pill text-white fw-500 text-capitalize"
						  [ngClass]="{'bg-success' : campaignStatus !== campaignStatuses.scheduled, 'bg-warning' : campaignStatus === campaignStatuses.scheduled}">
						{{campaignStatus}}
					</span>
				</div>

				<!--Description-->
				<div class="flex-row gap-1">
					<span class="fw-500" style="flex-basis: 92px; flex-shrink: 0;">Description: </span>
					<span>{{currentCampaign.description || '-'}}</span>
				</div>
			</div>
			<div class="flex-row hvr-darken gap-1 p-2 align-items-center cursor-pointer" style="align-self: flex-start;"
				 (click)="deleteCampaign.emit()">
				<i class="far fa-trash-alt" style="font-size: 1.2rem; cursor: pointer; color: red;"></i>
				<span style="font-size: 13px;">Delete</span>
			</div>
		</div>

		<br/>

		<ng-container *ngIf="!isTrainingCampaign">
			<!--Email Title-->
			<div class="flex-row gap-1">
				<span style="flex-basis: 92px; flex-shrink: 0;">Subject: </span>
				<span class="fw-500">{{currentCampaign.email_id.sent.title}}</span>
			</div>

			<!--From-->
			<div class="flex-row gap-1" *ngIf="currentCampaign.email_id.from">
				<span style="flex-basis: 92px; flex-shrink: 0;">From: </span>
				<div>
					<span *ngIf="currentCampaign.email_id.from.name && currentCampaign.email_id.from.email_display && currentCampaign.email_id.from.name !== currentCampaign.email_id.from.email_display">{{currentCampaign.email_id.from.name}} <{{currentCampaign.email_id.from.email_display}}></span>
					<span *ngIf="!currentCampaign.email_id.from.name || !currentCampaign.email_id.from.email_display || currentCampaign.email_id.from.name === currentCampaign.email_id.from.email_display">{{currentCampaign.email_id.from.name || currentCampaign.email_id.from.email_display}}</span>
				</div>
			</div>

			<!--Reply to-->
			<div class="flex-row gap-1" *ngIf="currentCampaign.email_id.advanced?.general?.reply_to_email">
				<span style="flex-basis: 92px; flex-shrink: 0;">Reply to: </span>
				<div>{{currentCampaign.email_id.advanced.general.reply_to_email}}</div>
			</div>

			<!--Recipients-->
			<!--<div class="flex-row gap-1">
				<span style="flex-basis: 92px; flex-shrink: 0;">Recipients: </span>
				<span *ngIf="!currentCampaign.methods?.secure_mass">
					{{showRecipientsStrFull ? recipientsStrFull : recipientsStr}}&nbsp;
					<a class="blue-link" style="text-decoration: underline;"
					   *ngIf="recipientsStr && recipientsStrFull !== recipientsStr"
					   (click)="showRecipientsStrFull = !showRecipientsStrFull">
						{{showRecipientsStrFull ? 'Close' : 'Show all (' + currentCampaign.recipients_display_only.to.length + ')'}}
					</a>
				</span>
				<span *ngIf="currentCampaign.methods?.secure_mass" style="width: auto">{{currentCampaign.total_recipients_count || currentCampaign.recipients_count}} recipients</span>
			</div>-->

			<!--Sent at-->
			<div class="flex-row gap-1" *ngIf="currentCampaign.email_id.start_sending_time">
				<span style="flex-basis: 92px; flex-shrink: 0;">Sent at: </span>
				<span>{{currentCampaign.email_id.start_sending_time | date:'MM/dd/yyyy HH:mm'}}</span>
			</div>

			<div class="flex-row gap-1" *ngIf="currentCampaign.email_id.scheduled_time && !currentCampaign.email_id.start_sending_time">
				<span style="flex-basis: 92px; flex-shrink: 0;">Scheduled for: </span>
				<span>{{currentCampaign.email_id.scheduled_time | date:'MM/dd/yyyy HH:mm'}}</span>
			</div>

			<br/>
			<div class="flxClmn" style="flex:1 0; margin:0; overflow: auto; min-height: 250px; border: solid 1px #cccccc;">
				<email-viewer [content]="currentCampaign.email_id.sent.html" style="min-height: 100%;"
							  *ngIf="!isTrainingCampaign"></email-viewer>
			</div>
		</ng-container>

		<ng-container *ngIf="isTrainingCampaign">
			<div class="flex-column gap-4 f-1-0">
				<div class="flex-column gap-2">
					<span class="fw-500">Material Details:</span>

					<!--Name-->
					<div class="flex-row gap-1">
						<span style="flex-basis: 92px; flex-shrink: 0;">Name: </span>
						<span class="fw-500">{{currentCampaign.material.name}}</span>
					</div>

					<!--URL-->
					<div class="flex-row gap-1" *ngIf="currentCampaign.material.type === 'link'">
						<span style="flex-basis: 92px; flex-shrink: 0;">URL: </span>
						<a class="blue-link text-decoration-underline" target="_blank" [href]="currentCampaign.material.link_url">{{currentCampaign.material.link_url}}</a>
					</div>

					<!--Reply to-->
					<div class="flex-row gap-1" *ngIf="currentCampaign.email_id.advanced?.general?.reply_to_email">
						<span style="flex-basis: 92px; flex-shrink: 0;">Description: </span>
						<div>{{currentCampaign.material.description}}</div>
					</div>
				</div>
				<div class="flex-column border border-1 border-light" style="width: 75%; aspect-ratio: 1/1.414; align-self: center; "
					 [ngClass]="{'centralize' : previewMaterialInProcess}"
					 *ngIf="currentCampaign.material.type !== 'link'">
					<attachment-preview
						*ngIf="previewMaterial && !previewMaterialInProcess"
						[attachment]="previewMaterial"
						[isHeadless]="true">
					</attachment-preview>
					<loader class="opacity-75" height="80" width="80" *ngIf="previewMaterialInProcess"></loader>
				</div>
				<div class="flex-column border border-1 border-light f-1-0 w-75 align-self-center"
					 *ngIf="currentCampaign.material.type === 'link'">
					<iframe class="w-100 f-1-0"
							[src]="sanitizer.bypassSecurityTrustResourceUrl(currentCampaign.material.link_url)"
							frameborder="0"
							allowfullscreen>
					</iframe>
				</div>
			</div>
		</ng-container>
	</div>

</div>
