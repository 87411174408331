import {TabsPageContainer} from "../../../../uiComponents/tabsPageContainer";
import {Component} from "@angular/core";

@Component({
	selector: 'inbound-plan-settings-tabs',
	templateUrl: './inbound-plan-settings-tabs.component.html',
})
export class InboundPlanSettingsTabsComponent extends TabsPageContainer {
	tabWidth = 180;
}
