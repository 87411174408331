import _ from "lodash";
import util from "util";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {RouteService} from "../../services/routeService";
import {GeneralService} from "../../services/generalService";
import {NotificationService} from "../../services/notificationService";
import {DICTIONARY} from "../../dictionary";

@Component({
	selector: 'groups-popup-c',
	templateUrl: './groups-popup.component.html',
})
export class GroupsPopupComponent implements OnInit{
	@Output() cancelCb? = new EventEmitter<any>;
	@Output() doneCb? = new EventEmitter<any>;
	@Input() doneBtnTxt;
	@Input() selectedGroups;
	@Input() popupTitle;
	@Input() popupSubTitle;
	@Input() multipleSelection;
	@Input() showActions;

	constructor(private rs:RouteService, public gs:GeneralService, private ns:NotificationService) {
	}

	dic = DICTIONARY;
	_ = _;
	getGroupsInProcess;
	uploadGroupsInProcess;
	doNotChangeIsExpandedStates;
	groups = [];

	ngOnInit() {
		this.getGroups();
	}

	getGroups = () => {
		this.getGroupsInProcess = true;

		this.rs.getGroups().then((response) => {
			this.groups = response;
			let selectedLocalCounter = 0;

			if (this.groups && this.selectedGroups && this.selectedGroups.length) {
				this.selectedGroups.forEach((groupEmail) => {
					const groupObj = this.groups.find(itm => itm.email === groupEmail);
					if (groupObj) {
						groupObj.selected = true;
						selectedLocalCounter++;
					}
				});
			}
			this.getGroupsInProcess = false;
		}, (err) => {
			this.getGroupsInProcess = false;
		});
	}

	toggleExpandItem = (item, event) => {
		if (!item.members?.length) {
			return;
		}

		item.isExpanded = !item.isExpanded;
		event.stopPropagation();
		this.doNotChangeIsExpandedStates = true;

		// refresh the trustifi-table by changing the list. The directive detects changes and fires an update
		this.groups = _.cloneDeep(this.groups);
	}

	searchGroup = (searchTerm, activeFilters) => {
		this.groups.forEach(record => {
			// search
			let isFound = this.searchGroupExecute(record, searchTerm);
			if (!isFound) {
				record.hide = true;
				return;
			}

			record.hide = false;
		});

		this.doNotChangeIsExpandedStates = false;
	};

	showGroupActions = (groupObj) => {
		return [
			this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete)
		];
	}

	selectGroupAction = (groupObj, action) => {
		switch (action) {
			case this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete):
				this.openDeleteGroupPopup(groupObj)
				break;
		}
	}

	openDeleteGroupPopup = (group) => {
		this.gs.showPopup({
			title: 'Delete Group',
			subTitle: `Please note - the group ${group.displayName} will be deleted`,
			body: ['Any rule created specifically for this group will no longer be relevant'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				this.rs.deleteGroup(group.email).then(res => {
					_.remove<any>(this.groups, g => g.email === group.email || g.parent_email === group.email);
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.groupDeleted, group.displayName))
				});
			}
		});
	};

	openUploadGroupPopup = () => {
		const body = [
			'File type must be a Comma Separated Value (.csv)',
			'The first row must contain these headers: "group", "email". You may also add a "members" column to specify group members.',
			`The "email" column should contain the group's email address`,
			'Group member addresses should be contained in double quote marks as such: "email1@domain.com, email2@domain.com"'
		];
		this.gs.showPopup({
			title: 'Import Groups',
			subTitle: `To ensure we can successfully handle your list, it should meet the following conditions:`,
			body: body,
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'OK',
			isFile: true,
			doneCb: (files) => {
				if (!files || !files.length) return;
				this.uploadGroupsFromCsv(files[0]);
			}
		});
	};

	uploadGroupsFromCsv = (file) => {
		if (!file) return;
		const validationFunctions = [this.gs.validateEmail];
		this.gs.readCsv(file, ['email', 'group'], ['members'], this.dic.CONSTANTS.EMAIL_REGEX, validationFunctions, (err, result) => {
			if (err) {
				return;
			}
			if (!result.email || !result.email.length || !result.group || !result.group.length  || result.email.length !== result.group.length) {
				this.ns.showWarnMessage(this.dic.ERRORS.groupsInvalidCsv);
				return;
			}
			const duplicateGroups = _.filter(result.group, (val, i, iteratee) => {
				let idx = parseInt(i, 10);
				return _.includes(iteratee, val, idx + 1)
			});
			if (duplicateGroups && duplicateGroups.length) {
				this.ns.showWarnMessage(this.dic.ERRORS.duplicateGroupsInvalid);
				return;
			}

			let groupsData = [], group;
			for (let i = 0; i < result.email.length; i++) {
				group = {};
				group.email = result.email[i];
				group.displayName = result.group[i];
				group.members = [];
				if (result.members) {
					let members = result.members[i] && result.members[i].split(',');
					for (let j = 0; j < members.length; j++) {
						members[j] = members[j].trim();
						if (!this.gs.validateEmail(members[j])) {
							this.ns.showWarnMessage(util.format(this.dic.ERRORS.contactEmailInvalid, members[j]));
							return;
						}
						if (!group.members.includes(members[j])) {
							group.members.push(members[j]);
						}
					}
				}
				groupsData.push(group);
			}
			this.uploadGroupsInProcess = true;

			this.rs.uploadGroups({groups: groupsData}).then(() => {
				this.ns.showInfoMessage(util.format(this.dic.MESSAGES.groupsUploaded, groupsData.length))
				this.uploadGroupsInProcess = false;
				this.getGroups();
			}, (err) => {
				this.uploadGroupsInProcess = false;
			});
		});
	};

	searchGroupExecute = (group, searchTerm) => {
		if (searchGroupOrUserTextExecute(group, searchTerm)) {
			showAllMembers(group);
			if (!this.doNotChangeIsExpandedStates) {
				group.isExpanded = false;
			}
			return true;
		}

		let isFound = false;
		if (group.members?.length) {
			for (let i = 0; i < group.members.length; i++) {
				const isSubUserFound = searchGroupOrUserTextExecute(group.members[i], searchTerm);
				group.members[i].hide = !isSubUserFound;
				if (isSubUserFound) {
					isFound = true;
					if (!this.doNotChangeIsExpandedStates) {
						group.isExpanded = !!searchTerm;
					}
				}
			}
		}

		return isFound;
	}
}

// when parent record is found by search and/or filter, remove hide flag from it's children recursively
function showAllMembers(group) {
	if (group.members?.length) {
		group.members.forEach(user => {
			user.hide = false;
		});
	}
}

function searchGroupOrUserTextExecute(group, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return ((group.displayName && group.displayName.toLowerCase().indexOf(searchTerm) > -1) ||
		(group.name && !group.members && group.name.toLowerCase().indexOf(searchTerm) > -1) ||
        (group.email && group.email.toLowerCase().indexOf(searchTerm) > -1) ||
        (group.origin && group.origin.toLowerCase().indexOf(searchTerm) > -1));
}
