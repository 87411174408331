import {DICTIONARY} from '../../../dictionary';
import {AccountCompromisedService} from '../../../services/accountCompromisedService';
import {RouteService} from '../../../services/routeService';
import _ from 'lodash';
import {NotificationService} from '../../../services/notificationService';
import {GeneralService} from '../../../services/generalService';
import L from 'leaflet';
import {Component, OnInit} from "@angular/core";


@Component({
	selector: 'account-compromised-incidents-component',
	templateUrl: './account-compromised-incidents.component.html',
})
export class AccountCompromisedIncidentsComponent implements OnInit {
	dic = DICTIONARY;
	loadingIncidentsInProcess;
	loadingUserStatusesInProcess;

	bulkActions = [
		this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display
	];

	incidents: any;
	isUserAllowedToViewEmailContent;
	incidentsByDates: any;
	filterData;
	incidentsDisplayedCounter = 0;

	usersStatuses: any;

	viewTypes = {
		timeline: 'timeline',
		list: 'list'
	};
	selectedViewType = this.viewTypes.timeline;
	sensitiveInfoPopup;
	usersInIncidentsList;
	selectedUserForTimeline;
	accountInfo: any;
	showAccountInfoPopup = false;
	searchUserStatusTxt = '';
	sortUser = 'email';
	sortStatus = 'status';
	orderUsersStatusesBy = this.sortUser;
	_ = _;

	incidentsActionsDic = {
		block24: {display: 'User was blocked for 24 hours', color: 'danger', icon: 'fa fa-ban', name: 'block24'},
		block: {display: 'User was blocked', color: 'danger', icon: 'fa fa-ban', name: 'block'},
		resetPassword: {display: 'User password was reset', color: 'warning', icon: 'fa fa-ban', name: 'resetPassword'},
		disableAccount: {display: 'User account was disabled', color: 'danger', icon: 'fa fa-ban', name: 'disableAccount'},
		removeRestrictions: {display: 'Removed restrictions for user', color: 'success', icon: 'fa fa-check-circle', name: 'removeRestrictions'},
		risky: {display: 'User marked as risky for 24 hours', color: 'warning', icon: 'fa fa-exclamation-triangle', name: 'risky'},
		approved: {display: "Updated incident status to \"Approved\"", color: 'success', icon: 'fa fa-check-circle', name: this.dic.CONSTANTS.compromisedIncidentStatus.approved},
		suspicious: {display: "Updated incident status to \"Suspicious\"", color: 'warning', icon: 'fa fa-exclamation-triangle', name: this.dic.CONSTANTS.compromisedIncidentStatus.suspicious},
		compromised: {display: "Updated incident status to \"Compromised\"", color: 'danger', icon: 'fa fa-times-circle', name: this.dic.CONSTANTS.compromisedIncidentStatus.compromised},
		note: {display: "note", color: 'success', icon: 'fas fa-info', name: 'note'},
		securityReviewerActivity: {display: "Security Reviewer activity", color: 'warning', icon: 'fas fa-user-shield', name: 'securityReviewerActivity'},
	};

	incidentActionsTimeline = [
		this.dic.CONSTANTS.accountCompromisedIncidentActions.info,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.status,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions,
		this.dic.CONSTANTS.accountCompromisedIncidentActions.reviewerNote
	];

	constructor(public accountCompromisedService:AccountCompromisedService,
				private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService) {
	}

	ngOnInit() {
		this.initFilters();
		this.getIncidents();
		this.getUsersStatuses();
	}

	getIncidents = () => {
		this.loadingIncidentsInProcess = true;
		this.rs.getAccountCompromisedIncidents('', null).then(response => {
			this.incidents = response.incidents;
			this.isUserAllowedToViewEmailContent = response.allowViewContent;
			this.incidents.forEach((incidentObj) => {
				this.colorForIncident(incidentObj);

				if (incidentObj.incident_info.agent?.raw && (typeof incidentObj.incident_info.agent?.raw === 'string')) {
					incidentObj.incident_info.agent.raw = JSON.parse(incidentObj.incident_info.agent.raw);
				}

			});
			this.incidents.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

			this.changeViewType(this.selectedViewType);
		}, (err) => {
			this.loadingIncidentsInProcess = false;
		});
	}

	getUsersStatuses = () => {
		this.loadingIncidentsInProcess = true;
		this.rs.getUsersStatuses().then(response => {
			this.usersStatuses = response;

			this.loadingUserStatusesInProcess = false;
		}, (err) => {
			this.loadingUserStatusesInProcess = false;
		});
	}

	changeViewType(selectedViewType, selectedUser=null) {
		switch (selectedViewType) {
			case this.viewTypes.timeline:
				this.prepareIncidentsTimeLine(selectedUser);
				break;

			case this.viewTypes.list:
				this.initFilters();
				this.searchIncident('', null);
				this.selectedViewType = this.viewTypes.list;
				this.loadingIncidentsInProcess = false;
				break;
		}
	}

	colorForIncident = (incidentObj) => {
		switch (incidentObj.incident_type) {
			case 'suspicious_location_change':
				incidentObj.color = 'green';
				break;

			case 'sending_unusual_amount_of_emails':
			case 'suspicious_device_change':
				incidentObj.color = 'orange';
				break;

			default:
				incidentObj.color = 'red';
				break;
		}
	}

	prepareIncidentsTimeLine(selectedUser = null) {
		// create users for the dropdown
		this.usersInIncidentsList = _.unionBy(
			_.map(this.incidents, incident => {
			return {
				displayName: incident.user_name ? incident.user_name + ' (' + incident.user_email + ')' : incident.user_email,
				email: incident.user_email
			}
		}), 'email');
		const allUsersObj = {displayName: 'All Users'};
		this.usersInIncidentsList.unshift(allUsersObj);
		this.selectedUserForTimeline = selectedUser || allUsersObj;
		//

		let incidentsClone = _.clone(this.incidents);
		this.incidentsByDates = [];

		if (selectedUser && selectedUser.displayName !== 'All Users') {
			incidentsClone = _.filter(incidentsClone, incident => incident.user_email === selectedUser.email);
		}

		// get a list of dates without the times out of the incidents list
		const dates = _.unionBy(_.map(incidentsClone, 'created'), (date:any) => new Date(date).getDate());

		// for each date create an array of incidents corresponding to this date
		dates.forEach(date => {
			const incidentsDayObj = {
				date: date,
				incidents: _.filter(incidentsClone, incident => areDatesEqualByYearMonthDay(incident.created, date))
			};
			this.incidentsByDates.push(incidentsDayObj);
		});

		this.selectedViewType = this.viewTypes.timeline;
		this.loadingIncidentsInProcess = false;
	}

	showIncidentsActions = () => {
		return [
			this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.status.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.reviewerNote.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display
		];
	}

	showDeviceAction = (deviceObj) => {
		return ['Delete'];
	}

	selectDeviceAction = (deviceObj, action) => {
		switch (action) {
			case 'Delete':
				this.rs.deleteUserDevice(this.accountInfo.email, deviceObj._id).then(() => {
					this.accountInfo.devices = this.accountInfo.devices.filter(itm => itm._id.toString() !== deviceObj._id.toString());
					this.prepareDevicesLocationInfo(this.accountInfo.devices);
					this.ns.showInfoMessage(this.dic.MESSAGES.operationCalled);
				}, () => {

				});
				break;
		}
	}

	getUserAccountInfo = (userEmail) => {
		this.rs.getAccountCompromisedUsers(userEmail).then(response => {
			this.parseUserInfo(response);
		}, (err) => {
		});
	}

	parseUserInfo(user) {
		if (!user.misc.account_compromised.devices.length) {
			return this.ns.showWarnMessage(this.dic.ERRORS.noDevicesData);
		}
		this.accountInfo = {
			email: user.email,
			devices: user.misc.account_compromised.devices
		};
		if (!user.isParsed) {
			this.accountInfo.devices = _.map(this.accountInfo.devices, device => {
				device.agent = device.agent || {};
				if (device.agent.raw) {
					device.agent.raw = JSON.parse(device.agent.raw);
				}
				return device;
			});
			user.isParsed = true;
		}
		if (this.accountInfo.devices){
			this.prepareDevicesLocationInfo(this.accountInfo.devices);
		}
		this.showAccountInfoPopup = true;
	}

	prepareDevicesLocationInfo(deviceList) {
		this.accountInfo.locations = [];
		let bounds = [];
		deviceList.forEach((deviceObj) => {
			deviceObj.location.sort((a, b) => new Date(b.last_used || b.created).getTime() - new Date(a.last_used || a.created).getTime());
			deviceObj.location.forEach((locationObj) => {
				if (locationObj.ll.length === 2) {
					const marker = {
						icon: this.gs.leafletDefaultIcon,
						lat: locationObj.ll[0],
						lng: locationObj.ll[1],
						title: this.parseDeviceClient(deviceObj),
						draggable: false
					};

					bounds.push([locationObj.ll[0],locationObj.ll[1]]);

					this.accountInfo.locations.push(marker);
				}
			})
		});

		const centroid = new L.LatLngBounds(bounds).getCenter();

		this.accountInfo.center_location = {
			lat: centroid.lat,
			lng: centroid.lng,
			zoom: 2
		};
	}

	parseDeviceClient(device) {
		if (device.agent.browser) {
			return `${device.agent.browser.name} (${device.agent.browser.version}), ${device.agent.os.name} (${device.agent.os.version})`;
		}
		if (device.agent.raw) {
			return `${device.agent.raw.ua || 'N/A'}`;
		}

		return 'N/A';
	}

	exportToCsv = (sortBy) => {
		if (!this.incidents || !this.incidents.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Date,Email,Name,Subject,Type\n";

		let sortedTable = _.filter(this.incidents,incident => {return !incident.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((incident) => {
			const incidentType = this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name;
			csvString += `${incident.created},"${incident.user_email}",${incident.user_name},"${incident.email_title}","${incidentType}"\n`;
		});

		this.gs.exportCsv(csvString, `suspicious_activity.csv`);
	}

	initFilters = () => {
		this.filterData = {
			filterType: this.dic.CONSTANTS.tableFilters.suspiciousActivity,
			filters: {
				type: ['Suspicious device change', 'Suspicious location change', 'Sensitive data sent to new domains', 'Unusual amount of emails sent', 'Sensitive data sent to free email domain', 'Increased recipient complaint rate', 'Increased recipient hard bounce rate', 'Mailbox email breach', 'Suspicious mailbox rules'],
				status: ['approved', 'suspicious', 'compromised']
			},
			initFiltersFunction: this.initFilters
		};
	}

	searchIncident = (searchTerm, activeFilters) => {
		this.incidents.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = this.searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			if (activeFilters) {
				// need to match all filter types
				let numFilterToMatch = Object.keys(activeFilters).length;

				if (activeFilters.type && activeFilters.type.length) {
					if (activeFilters.type.includes(this.dic.CONSTANTS.accountCompromisedIncidentTypes[record.incident_type].name)) {
						numFilterToMatch--;
					}
				}
				if (activeFilters.status && activeFilters.status.length) {
					if (activeFilters.status.includes(record.status)) {
						numFilterToMatch--;
					}
				}

				if (numFilterToMatch) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	selectAction = (incidentObj, action) => {
		switch (action) {
			case this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display:
				this.accountCompromisedService.getUserIncidentInfo(incidentObj, this.isUserAllowedToViewEmailContent);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.status.display:
				this.accountCompromisedService.changeIncidentStatus(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser.display:
				this.accountCompromisedService.blockUser(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours.display:
				this.accountCompromisedService.blockUser24hoursExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours.display:
				this.accountCompromisedService.riskyUser24hoursExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer.display:
				this.accountCompromisedService.disableUserMailServerExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer.display:
				this.accountCompromisedService.resetPasswordMailServerExecute(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display:
				this.deleteIncident([incidentObj]);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions.display:
				this.accountCompromisedService.removeRestrictionsUser(incidentObj);
				break;

			case this.dic.CONSTANTS.accountCompromisedIncidentActions.reviewerNote.display:
				this.accountCompromisedService.openReviewerNotePopup(incidentObj);
				break;

			case this.dic.CONSTANTS.quarantinedActions.createIncident.display:
				this.accountCompromisedService.createIncidentPopup(incidentObj);
				break;
		}
	}

	openLocation(ll) {
		this.gs.openLocation(ll);
	}

	openSensitiveInfoPopup(incident) {
		this.sensitiveInfoPopup = {
			emailTitle: incident.email_title,
			sensitivity: incident.incident_info.sensitivity,
			show: true
		};
	}

	deleteIncident(incidentObjects) {
		let title, subTitle;
		if (incidentObjects.length > 1) {
			title = 'Delete incidents';
			subTitle = `Please note - you are about to delete ${incidentObjects.length} incidents`;
		}
		else {
			title = 'Delete incident';
			subTitle = `You are about to delete incident for ${incidentObjects[0].user_name}`;
		}

		this.gs.showPopup({
			title: title,
			subTitle: subTitle,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				incidentObjects.forEach((incidentObj) => {
					const incidentInfo = {incidentId: incidentObj._id, action: this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.value};
					this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
						_.remove<any>(this.incidents, (incidentItem:any) => incidentItem._id === incidentObj._id);
						this.ns.showInfoMessage('Incident deleted successfully');
						this.incidentsDisplayedCounter--;
					});
				});
			}
		});
	}

	showIncidentBulkActions = () => {
		return this.bulkActions;
	}

	selectMultipleIncidentsAction = (selectedRecords,action) => {
		switch (action) {
			case this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display:
				this.deleteIncident(selectedRecords);
				break;
		}
	}

	searchTextExecute(incident, searchTerm) {
		searchTerm = searchTerm.toLowerCase();
		return ((incident.user_email && incident.user_email.toLowerCase().indexOf(searchTerm) > -1) ||
			(incident.user_name && incident.user_name.toLowerCase().indexOf(searchTerm) > -1) ||
			this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name.toLowerCase().indexOf(searchTerm) > -1);
	}

	searchUserStatus() {
		if (!this.searchUserStatusTxt) {
			this.usersStatuses.forEach(userStatus => {
				userStatus.hide = false;
			});
			return;
		}

		const searchTerm = this.searchUserStatusTxt.toLowerCase();

		this.usersStatuses.forEach(userStatus => {
			const isFound = (userStatus.status && userStatus.status.toLowerCase().indexOf(searchTerm) > -1) ||
							(userStatus.email && userStatus.email.toLowerCase().indexOf(searchTerm) > -1) ;
			userStatus.hide = !isFound;
		})
	}
}

function areDatesEqualByYearMonthDay(date1, date2) {
	const date1Year = new Date(date1).getFullYear();
	const date1Month = new Date(date1).getMonth();
	const date1Day = new Date(date1).getDate();

	const date2Year = new Date(date2).getFullYear();
	const date2Month = new Date(date2).getMonth();
	const date2Day = new Date(date2).getDate();

	return date1Year === date2Year && date1Month === date2Month && date1Day === date2Day;
}


