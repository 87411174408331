<div class="attachment-block-container hvr-opacity flxRow hSpace1">
	<i class="{{removeIcon}}" *ngIf="removeIcon" tooltip="Remove"
	   style="width: 14px; position: absolute; right: -15px; top: -7px; color: var(--bs-danger)"
	   (click)="remove()"></i>
	<div (click)="onDownload();"  class="file-icon-container flxRow-center">
		<img [src]="fileIcon" alt="file icon">
	</div>
	<div style="flex: 1 0; position: relative; gap: 3px;" class="flxClmn-center ellipsis">
		<div class="flxRow">
			<span (click)="onDownload();" tooltip="Download" class="file-name ellipsis">{{ attName || '' }}</span>
			<i (click)="previewAttachment();" class="fas fa-eye" style="margin-left: auto; opacity: 0.7;" tooltip="Preview"></i>
		</div>
		<div class="flxRow" style="height: 16px;" (click)="onDownload();">
			<span *ngIf="attSize" class="file-size">{{ attSize | FormatBytes: 2 }}</span>
			<i style="margin-left: auto;" *ngIf="(downloadProgress ? downloadProgress >= 100 : true)" class="fas fa-download" tooltip="Download"></i>
			<div style="height: 16px; margin-left: auto;" *ngIf="downloadProgress && downloadProgress < 100">
				<div class="radial-progress-bar" style="width: 14px; height: 14px; animation-delay: 1s; transition: max-width 0.5s 1.5s; right: 2px;">
					<div class="p-bar" [ngStyle]="{'background' : 'conic-gradient(royalblue 0deg ' + (downloadProgress / 100 * 365) +'deg, #d7d7d7 ' + (downloadProgress / 100 * 365) + 'deg 365deg)'}"></div>
				</div>
			</div>
		</div>
	</div>
</div>
