<div class="flxClmn bs-enabled">
	<div *ngIf="!isLoaded" class="centralize f-1-0 flxClmn">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div *ngIf="config" class="page-inner-content-holder gap-3 pt-0 flex-column animated fadeIn">
		<div *ngIf="!config.wa_enabled">
			<div style="text-align: center; margin: 0 auto; width:70%;">
				<i style="opacity:0.4;" class="zmdi zmdi-hc-4x"></i>
				<h1 style="opacity:0.4; font-size: 50px;">Archive is disabled</h1>
				<span>To activate the Archive feature, kindly reach out to our support team.</span>
				<br/>
				<hr/>
			</div>
		</div>

		<div *ngIf="config.wa_enabled">
			<div class="list-group list-group-flush settings-list-container">
				<div class="list-group-item settings-list-item py-5">
					<div class="title-section-container flex-column gap-2">
						<b>Enable Email Archive</b>
						<span class="lh-base" style="font-size: 0.9em;">Save all emails which go through your Email Relay (inbound and outbound) to an interactive archive. Archived emails can be searched, viewed, downloaded and more.</span>
					</div>
					<div class="content-container">
						<toggle-switch-c id="enableEmailArchiveToggle" *ngIf="config"
										 [(model)]="config.enabled"
										 (change)="updateArchiveConfigurations(dic.CONSTANTS.archiveActions.state)">
						</toggle-switch-c>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-5" *ngIf="journalEmailAddress && config?.enabled">
					<div class="title-section-container flex-column gap-2">
						<b>Journal Rule Settings (optional)</b>
						<span class="lh-base" style="font-size: 0.9em;">Configure the <a href="https://purview.microsoft.com/datalifecyclemanagement/exchange/journalrules" target="_blank" style="color: blue;">Journal Report email</a> settings if you want to archive your messages and some or all of your messages do not pass through an Email Relay/MX.</span>
					</div>
					<div class="content-container">
						<div class="flex-row align-items-center gap-3">
							<span class="fw-bold" style="flex-basis: 55px; font-size: 0.9em;">Email:</span>

							<input class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
								   type="{{!showJournalAddress ? 'password' : 'text'}}"
								   [ngStyle]="{'letter-spacing' : showJournalAddress ? 'normal' : '2.4px'}"
								   [ngClass]="{'nofill': !showJournalAddress}"
								   [(ngModel)]="journalEmailAddress" disabled>

							<label class="hidepwd hvr-opacity" style="opacity: 0.4; margin-bottom: 0; font-size: 1.2rem; cursor: pointer;"
								   tooltip="{{showJournalAddress ? 'Hide' : 'Show'}}">
								<input type="checkbox" [(ngModel)]="showJournalAddress"/>
								<i class="fa fa-eye unchecked" ></i>
								<i class="fa fa-eye-slash checked"></i>
							</label>

							<a style="font-size: 1rem;"
							   (click)="copyJournalEmailToClipboard();"
							   tooltip="Copy">
								<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
							</a>
						</div>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-5" *ngIf="config?.enabled">
					<div class="title-section-container flex-column gap-2">
						<b>Archive Usage</b>
						<span class="lh-base" style="font-size: 0.9em;">Amount of archived emails and storage used (calculated weekly).</span>
					</div>
					<div class="content-container flxClmn gap-2">
						<span>
							Archived emails: <b>{{usage.archive || 0}}</b>
						</span>
						<span>
							<!-- * 1000 is to convert to bytes -->
							Archived storage usage: <b>{{(usage.archive || 0) * 1024 * dic.CONSTANTS.usageStorageMultipliers.archive | FormatBytes:1}}</b>
						</span>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Reviewers</b>
						<span class="lh-base" style="font-size: 0.9em;">Reviewers which will have access to archive information</span>
					</div>

					<div class="content-container flex-column gap-2" style="z-index: 1;">
						<!--reviewers table-->
						<trustifi-table-c
							class="trustifi-table" style="flex: 0 0 300px; z-index: 5; overflow: auto;"
							[list]="reviewers"
							[headers]="[
								{text:'Reviewer', sortKey:'email'},
								{text:'View Content', sortKey:'allow_view_content', tooltip: 'Allow reviewer to view the email content for archived emails'}
							]"
							[cellsPrototype]="[
								{textKey: 'email', edit: {modelKey: 'email', placeholder: 'Reviewer email'}},
								{html: 'viewContentCell'},
							]"
							[bulkActions]="{
								enable: true,
								selectBulkActionCb:	selectMultipleReviewerAction,
								initBulkActionsFunction: showReviewerBulkActions,
								showInRed: {enable: true, terms: ['Remove']}
							}"
							[cellActions] = "{
								enable: true,
								selectItemActionCb:	selectReviewerAction,
								initActionsFunction: showReviewerActions,
								showInRed: {enable: true, terms: ['Remove']}
							}"
							(searchFunction)="searchReviewer($event.searchTerm, $event.activeFilters)"
							(confirmEditFunction)="confirmAddReviewer($event.item, $event.isApproved)"
							[options]="{
								itemsNameSingular: 'reviewer',
								defaultSortKey: '-email',
							}"
						>
							<ng-template #customCells let-item let-cellId="cellId">
								<!--action cells-->
								<div *ngIf="cellId === 'viewContentCell'">
									<span [ngStyle]="{color: item.allow_view_content || item.isNew ? 'green' : 'red'}">{{item.allow_view_content || item.isNew ? 'Enabled' : 'Disabled'}}</span>
								</div>
							</ng-template>
							<div tableButtons>
								<div class="btn-with-dropdown">
									<btn-c (action)="startAddReviewer();"
										   endIcon="fa fa-plus"
										   [isDisabled]="addReviewerInProcess">
										Add Reviewer
									</btn-c>
									<app-dropdown-c [isLf]="true">
										<li (click)="openUsersSelection()">
											Add from my users
										</li>
									</app-dropdown-c>
								</div>
							</div>
						</trustifi-table-c>
					</div>
				</div>
			</div>
		</div>
	</div>

	<users-selection-component
		*ngIf="usersSelectionPopup?.show"
		[data]="usersSelectionPopup"
		[selectedUsers]="selectedUsers"
		(addCb)="addExistUsersToReviewers($event.users)"
		(removeCb)="startRemoveFromUsersSelection($event.users)">
	</users-selection-component>
</div>
