<div class="flxClmn">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Usage</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Review information and statistics about your usage of inbound and outbound licenses</span>
        </div>
    </div>

    <div class="page-inner-content-holder bs-enabled flxClmn vSpace2">
        <div class="f-1-0">
            <!--Licenses Information-->
            <div class="bs-row mb-4">
                <div class="bs-col-12">
                    <div class="trends-data-card flex-column gap-1 p-3 w-100" style="height: clamp(400px,480px,70vh);">
                        <div class="flex-column align-items-start gap-1">
                            <div class="flex-row align-items-center gap-1">
                                <h6 class="m-0 fw-bold">Licenses Information</h6>
                            </div>
                        </div>
                        <div class="flex-column f-1-0 p-3 overflow-auto">
                            <trustifi-table-c
                                    class="trustifi-table f-1-0" style="z-index: 5;"
                                    [list]="usage?.general"
                                    [headers]="[
                                            {text:'Admin', sortKey: 'email', width: '17%'},
                                            {text:'Expiration', sortKey: 'expired', width: '115px'},
                                            {text:'Inbound Licenses', sortKey: 'inbound.allowed_users'},
                                            {text:'Outbound Licenses', sortKey: 'outbound.allowed_users'},
                                            {text:'Account Manager', sortKey: 'account_manager'},
                                            {text:'Plan Type', sortKey: 'plan_sub_type', width: '9%'},
                                            {text:'Plan Usage', tooltip: 'Usage data shows the total statistics of data retained in the system for this plan. Data is calculated once a week'}
                                        ]"
                                    [cellsPrototype]="[
                                      {textKey: 'email'},
                                      {html:'expiredCell'},
                                      {html:'inboundLicenseCell'},
                                      {html:'outboundLicenseCell'},
                                      {textKey: 'account_manager'},
                                      {textKey: 'plan_sub_type'},
                                      {html:'planUsageCell', overflowOutside: true},
                                    ]"
									(searchFunction)="searchLicenseExpiration($event)"
                                    [options]="{
                                        itemsNameSingular: 'plan',
                                        loadingTableFlag: getUsageDataInProcess,
                                        defaultSortKey: 'expired',
                                        exportToCsvFunction: exportLicensesToCsv.bind(this),
                                    }"
                            >
								<ng-template #customCells let-item let-cellId="cellId">
									<!--expired cell-->
									<span *ngIf="cellId === 'expiredCell'" [ngStyle]="{'color' : item.planExpired ? 'red' : item.aboutToExpire ? 'darkorange' : ''}">
                                        {{item.expired | date:'MM/dd/yyyy'}}
                                    </span>
									<!--inbound license cell-->
									<div *ngIf="cellId === 'inboundLicenseCell'" class="flxClmn vSpace05">
										<span style="display: block;" [ngStyle]="(item.inbound.used_users > item.inbound.allowed_users) && {'color' : 'red'}">Active licenses: {{item.inbound.used_users}}</span>
										<span *ngIf="item.plan_sub_type !== 'NFR'" style="display: block;">Allocated licenses: {{item.inbound.allowed_users}}</span>
									</div>
									<!--outbound license cell-->
									<div *ngIf="cellId === 'outboundLicenseCell'" class="flxClmn vSpace05">
										<span style="display: block;" [ngStyle]="(item.outbound.used_users > item.outbound.allowed_users) && {'color' : 'red'}">Active licenses: {{item.outbound.used_users}}</span>
										<span *ngIf="item.plan_sub_type !== 'NFR'" style="display: block;">Allocated licenses: {{item.outbound.allowed_users}}</span>
									</div>
									<!--plan usage cell-->
									<div *ngIf="cellId === 'planUsageCell'" class="flxClmn vSpace05">
										<div class="flex-row align-items-center gap-1">
											<div class="rounded-1 flex-shrink-0" style="background: #cb3e00; height: 12px; width: 12px;"></div>
											<div class="flex-row align-items-center f-1-0 gap-1">
												<span>Sent Emails:</span>
												<span>{{item.usage?.emails || 0}}</span>
											</div>
										</div>
										<div class="flex-row align-items-center gap-1">
											<div class="rounded-1 flex-shrink-0" style="background: #34383f; height: 12px; width: 12px;"></div>
											<div class="flex-row align-items-center f-1-0 gap-1">
												<span>Sent Attachments:</span>
												<span>{{item.usage?.attachments || 0}}</span>
											</div>
										</div>
										<div class="flex-row align-items-center gap-1">
											<div class="rounded-1 flex-shrink-0" style="background: #8300ec; height: 12px; width: 12px;"></div>
											<div class="flex-row align-items-center f-1-0 gap-1">
												<span>Inbound Quarantine:</span>
												<span>{{item.usage?.quarantines || 0}}</span>
											</div>
										</div>
										<div class="flex-row align-items-center gap-1">
											<div class="rounded-1 flex-shrink-0" style="background: #e0af00; height: 12px; width: 12px;"></div>
											<div class="flex-row align-items-center f-1-0 gap-1">
												<span>Archived Emails:</span>
												<span>{{item.usage?.archive || 0}}</span>
											</div>
										</div>

										<div class="flex-column my-2 justify-content-center position-relative">
											<apx-chart class="animated fadeIn partner-storage-usage-chart w-100" style="max-height: 25px;"
													   [options]="{display: false}"
													   *ngIf="item.storageUsageBarOptions"
													   [series]="item.storageUsageBarOptions.series"
													   [chart]="item.storageUsageBarOptions.chart"
													   [plotOptions]="item.storageUsageBarOptions.plotOptions"
													   [colors]="item.storageUsageBarOptions.colors"
													   [grid]="item.storageUsageBarOptions.grid"
													   [fill]="item.storageUsageBarOptions.fill"
													   [xaxis]="item.storageUsageBarOptions.xaxis"
													   [yaxis]="item.storageUsageBarOptions.yaxis"
													   [legend]="item.storageUsageBarOptions.legend"
													   [tooltip]="item.storageUsageBarOptions.tooltip"
													   [dataLabels]="item.storageUsageBarOptions.dataLabels"></apx-chart>
											<span [ngStyle]="{'color' : item.totalStorageUsage > item.maxStorageCapacity ? 'var(--bs-danger)' : '#939393'}">
												{{(item.totalStorageUsage) | FormatBytes:0}} / {{(item.maxStorageCapacity) | FormatBytes:0}}
											</span>
										</div>
									</div>
								</ng-template>
								<div tableButtons>
									<btn-c endIcon="fas fa-chart-pie" variant="outlined"
										   (action)="showGenerateReportPopup();">
										Schedule Report
									</btn-c>
								</div>
                            </trustifi-table-c>
                        </div>
                    </div>
                </div>
            </div>

            <!--inbound-->
            <div class="bs-row border-between g-4">
                <!--Inbound Licenses-->
                <div class="bs-col-6 flex-column gap-4">
                    <div class="trends-data-card flex-column p-3 w-100" style="height: clamp(450px,55vh,508px);">
                        <div class="flex-column align-items-start gap-1">
                            <div class="flex-row align-items-center gap-1">
                                <h6 class="m-0 fw-bold">Inbound Licenses</h6>
                            </div>
                            <span class="opacity-75 fw-500 text-start" style="height: 32px;">Shows the amount of inbound licenses (mailboxes) that are currently active (used) out of your total</span>
                        </div>
                        <div class="flex-column gap-3 f-1-0 animated fadeIn" *ngIf="usage">
                            <div class="flex-row px-2 py-4 justify-content-between align-items-center">
                                <div class="flex-column align-items-start gap-1" style="flex-basis: 180px;">
                                    <h4 class="m-0 fw-bold text-success">{{usage.inbound.used_users || '0'}}</h4>
                                    <span class="opacity-75 fw-500 text-start">Active licenses</span>
                                </div>
                                <div class="flex-column align-items-start gap-1" style="flex-basis: 180px;">
                                    <h4 class="m-0 fw-bold">{{usage.inbound.allowed_users || '0'}}</h4>
                                    <span class="opacity-75 fw-500 text-start">Allocated licenses</span>
                                </div>
                                <div class="flex-row f-1-0" style="max-width: 40%; max-height: 15vh;">
                                    <apx-chart style="max-width: 220px; width: 100%;"
											   [series]="inboundUsersMeterChartOptions.series"
											   [plotOptions]="inboundUsersMeterChartOptions.plotOptions"
											   [chart]="inboundUsersMeterChartOptions.chart"
											   [colors]="inboundUsersMeterChartOptions.colors"
											   [labels]="inboundUsersMeterChartOptions.labels">
									</apx-chart>
                                </div>
                            </div>
                            <hr class="w-100 mt-0">
                            <div class="flex-row w-100 justify-content-center f-1-0">
                                <div class="flex-row text-center f-1-0 justify-content-center gap-1 p-4" style="max-width: 45%;">
                                    <span class="fw-500">Inbound Plan Type Distribution</span>
                                    <i style="font-weight: 400;" class="fas fa-question-circle" title="Shows the distribution of plan types (PoC/MSP/NFR/PRO) between your active inbound licenses"></i>
                                </div>
                                <div class="flex-column centralize f-1-0" [ngClass]="{'border' : usage.inbound.used_users < 1}">
                                    <apx-chart class="pie-chart f-1-0" style="width: 100%; max-width: min(38vh,340px);"
											   *ngIf="usage.inbound.used_users > 0"
											   [series]="inboundPlansTypesChartOptions.series"
											   [dataLabels]="inboundPlansTypesChartOptions.dataLabels"
											   [plotOptions]="inboundPlansTypesChartOptions.plotOptions"
											   [chart]="inboundPlansTypesChartOptions.chart"
											   [colors]="inboundPlansTypesChartOptions.colors"
											   [labels]="inboundPlansTypesChartOptions.labels">
									</apx-chart>
                                    <span class="text-dark" *ngIf="usage.inbound.used_users < 1">No active licenses</span>
                                </div>
                            </div>
                        </div>
                        <!--loader / placeholder-->
                        <div class="flex-column p-3 centralize animated fadeIn h-100 w-100" *ngIf="getUsageDataInProcess">
                            <loader class="opacity-75" height="100" width="100"></loader>
                        </div>
                    </div>


                    <!--Top Inbound Plan Usage-->
                    <div class="trends-data-card gap-2 flex-column p-3 w-100" style="height: clamp(450px,55vh,508px);">
                        <div class="flex-column align-items-start gap-1">
                            <div class="flex-row align-items-center gap-1">
                                <h6 class="m-0 fw-bold">Top Inbound Plan Usage</h6>
                            </div>
                            <span class="opacity-75 fw-500 text-start">Shows the plans (by admin) which use the most inbound licenses.</span>
                        </div>
                        <div class="flex-column centralize f-1-0 animated fadeIn"
                             *ngIf="usage"
                             [ngClass]="{'border' : usage.inbound.used_users < 1}">
                            <span class="text-dark" *ngIf="usage.inbound.used_users < 1">No active licenses</span>
                            <apx-chart class="f-1-0 w-100" *ngIf="usage.inbound.used_users > 0"
									   [series]="inboundTopPlanUsageChartOptions.series"
									   [dataLabels]="inboundTopPlanUsageChartOptions.dataLabels"
									   [plotOptions]="inboundTopPlanUsageChartOptions.plotOptions"
									   [chart]="inboundTopPlanUsageChartOptions.chart"
									   [colors]="inboundTopPlanUsageChartOptions.colors"
									   [xaxis]="inboundTopPlanUsageChartOptions.xaxis">
                            </apx-chart>
                        </div>


                        <!--loader / placeholder-->
                        <div class="flex-column p-3 centralize animated fadeIn h-100 w-100" *ngIf="getUsageDataInProcess">
                            <loader class="opacity-75" height="100" width="100"></loader>
                        </div>
                    </div>
                </div>

                <!--outbound-->
                <!--Outbound Licenses-->
                <div class="bs-col-6 flex-column gap-4">
                    <div class="trends-data-card flex-column p-3 w-100" style="height: clamp(450px,55vh,508px);">
                        <div class="flex-column align-items-start gap-1">
                            <div class="flex-row align-items-center gap-1">
                                <h6 class="m-0 fw-bold">Outbound Licenses</h6>
                            </div>
                            <span class="opacity-75 fw-500 text-start" style="height: 32px;">Shows the amount of outbound licenses (users) that are currently active (used) out of your total</span>
                        </div>
                        <div class="flex-column gap-3 f-1-0 animated fadeIn" *ngIf="usage">
                            <div class="flex-row px-2 py-4 justify-content-between align-items-center">
                                <div class="flex-column align-items-start gap-1" style="flex-basis: 180px;">
                                    <h4 class="m-0 fw-bold text-success">{{usage.outbound.used_users || '0'}}</h4>
                                    <span class="opacity-75 fw-500 text-start">Active licenses</span>
                                </div>
                                <div class="flex-column align-items-start gap-1" style="flex-basis: 180px;">
                                    <h4 class="m-0 fw-bold">{{usage.outbound.allowed_users || '0'}}</h4>
                                    <span class="opacity-75 fw-500 text-start">Allocated licenses</span>
                                </div>
                                <div class="flex-row f-1-0" style="max-width: 40%; max-height: 15vh;">
                                    <apx-chart style="max-width: 220px; width: 100%;"
											   [series]="outboundUsersMeterChartOptions.series"
											   [plotOptions]="outboundUsersMeterChartOptions.plotOptions"
											   [chart]="outboundUsersMeterChartOptions.chart"
											   [colors]="outboundUsersMeterChartOptions.colors"
											   [labels]="outboundUsersMeterChartOptions.labels">
									</apx-chart>
                                </div>
                            </div>
                            <hr class="w-100 mt-0">
                            <div class="flex-row w-100 justify-content-center f-1-0">
                                <div class="flex-row text-center f-1-0 justify-content-center gap-1 p-4" style="max-width: 45%;">
                                    <span class="fw-500">Outbound Plan Type Distribution</span>
                                    <i style="font-weight: 400;" class="fas fa-question-circle" title="Shows the distribution of plan types (PoC/MSP/NFR/PRO) between your active outbound licenses"></i>
                                </div>
                                <div class="flex-column centralize f-1-0" [ngClass]="{'border' : usage.outbound.used_users < 1}">
                                    <apx-chart class="pie-chart f-1-0" style="width: 100%; max-width: min(38vh,340px);"
											   *ngIf="usage.outbound.used_users > 0"
											   [series]="outboundPlansTypesChartOptions.series"
											   [plotOptions]="outboundPlansTypesChartOptions.plotOptions"
											   [chart]="outboundPlansTypesChartOptions.chart"
											   [dataLabels]="outboundPlansTypesChartOptions.dataLabels"
											   [colors]="outboundPlansTypesChartOptions.colors"
											   [labels]="outboundPlansTypesChartOptions.labels">
									</apx-chart>
                                    <span class="text-dark" *ngIf="usage.outbound.used_users < 1">No active licenses</span>
                                </div>
                            </div>
                        </div>
                        <!--loader / placeholder-->
                        <div class="flex-column p-3 centralize animated fadeIn h-100 w-100" *ngIf="getUsageDataInProcess">
                            <loader class="opacity-75" height="100" width="100"></loader>
                        </div>
                    </div>

                    <!--Top Outbound Plan Usage-->
                    <div class="trends-data-card gap-2 flex-column p-3 w-100" style="height: clamp(450px,55vh,508px);">
                        <div class="flex-column align-items-start gap-1">
                            <div class="flex-row align-items-center gap-1">
                                <h6 class="m-0 fw-bold">Top Outbound Plan Usage</h6>
                            </div>
                            <span class="opacity-75 fw-500 text-start">Shows the plans (by admin) which use the most outbound licenses.</span>
                        </div>
                        <div class="flex-column centralize f-1-0 animated fadeIn"
                             *ngIf="usage"
                             [ngClass]="{'border' : usage.outbound.used_users < 1}">
                            <span class="text-dark" *ngIf="usage.outbound.used_users < 1">No active licenses</span>
                            <apx-chart class="f-1-0 w-100" *ngIf="usage.outbound.used_users > 0"
									   [series]="outboundTopPlanUsageChartOptions.series"
									   [plotOptions]="outboundTopPlanUsageChartOptions.plotOptions"
									   [chart]="outboundTopPlanUsageChartOptions.chart"
									   [dataLabels]="outboundTopPlanUsageChartOptions.dataLabels"
									   [colors]="outboundTopPlanUsageChartOptions.colors"
									   [xaxis]="outboundTopPlanUsageChartOptions.xaxis">
                            </apx-chart>
                        </div>

                        <!--loader / placeholder-->
                        <div class="flex-column p-3 centralize animated fadeIn h-100 w-100" *ngIf="getUsageDataInProcess">
                            <loader class="opacity-75" height="100" width="100"></loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Partners Report popup-->
    <popup-c class="bs-enabled" *ngIf="generateReportPopup?.show"
           head="Partners Report"
           subTitle="Scheduled reports of your usage of inbound and outbound licenses"
           (closeCb)="generateReportPopup = null"
           size="medium" width="710px">
        <div class="flex-column gap-5 f-1-0" style="overflow: auto;">
			<div class="f-1-0 d-flex centralize" *ngIf="generateReportPopup.getPartnerInfoInProcess">
				<loader height="100" width="100" style="opacity: 0.8;"></loader>
			</div>
			<ng-container *ngIf="!generateReportPopup.getPartnerInfoInProcess && partnerInfo">
				<div class="flex-column justify-content-center gap-2">
					<div class="flex-row w-100 gap-3" style="align-items: center; padding: 5px;"
						 *ngFor="let period of ['daily','weekly','monthly']; trackBy: gs.trackByIndex">
						<div class="flex-row gap-2" style="white-space: nowrap; align-items: center; flex-basis: 165px;">
							<toggle-switch-c id="{{period}}ReportToggle"
											 [hideText]="true"
											 [(model)]="partnerInfo.partners.scheduled_reports[period].value"
											 (onChange)="updateReportSettings()"></toggle-switch-c>
							<span style="vertical-align: middle; text-transform: capitalize;">{{period}} report</span>
						</div>
						<div style="display: inline-block; flex: 2;">
							<span>Report will be sent {{period === 'daily' ? 'each day' : period === 'weekly' ? 'each Monday' : period === "monthly" ? 'on the 1st of each month' : ''}}.</span>
							<span *ngIf="partnerInfo?.partners?.scheduled_reports[period]?.last_report"> (Last report : {{partnerInfo.partners.scheduled_reports[period].last_report | date:'MM/dd/yyyy HH:mm'}}).</span>
						</div>
					</div>
				</div>

				<!--Addresses -->
				<div class="flex-row gap-2">
					<div class="flex-column gap-2" style="max-width: 17vw;">
						<b>Add Report Recipients (optional)</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Add the specific addresses to which you would like the report to be sent, in addition to reviewers.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flex-row gap-1">
							<input type="text" style="width: 300px; margin: 0;"
								   placeholder="Add"
								   (keyup.enter)="addReportAddress(generateReportPopup.address);"
								   [(ngModel)]="generateReportPopup.address">
							<btn-c style="min-width: 0;"
								   [endIcon]="'zmdi zmdi-check'"
								   [isDisabled]="!generateReportPopup.address"
								   [loading]="generateReportPopup.actionInProcess"
								   (action)="addReportAddress(generateReportPopup.address);">
							</btn-c>
						</div>
						<div *ngFor="let address of partnerInfo.partners.scheduled_reports.report_to_emails; trackBy: gs.trackByIndex" style="margin-bottom: 10px;">
							<div class="name ellipsis"
								 style="display: inline-block; width: 310px;">{{address}}</div>
							<a class="closeRed action"
							   (click)="deleteReportAddress(address)"
							   [ngClass]="generateReportPopup.actionInProcess ? 'closeRed disableAction' : 'approveGreen'">
								<i class="fa fa-times"></i>
							</a>
						</div>
					</div>
				</div>
			</ng-container>
        </div>
    </popup-c>
</div>

