<popup-c [head]="popupTitle" size="large"
       [subTitle]="popupSubTitle || null"
       (closeCb)="cancelCb.observed ? cancelCb.emit(): null"
       (confirmCb)="doneCb.observed ? doneCb.emit({groups: this.groups}) : null"
	   [buttons]="{cancel: doneCb.observed ? 'Cancel' : 'OK', confirm: doneCb.observed ? (doneBtnTxt || 'Confirm') : null}">
    <div class="flxClmn f-1-0" style="overflow: auto;">
        <!--aliases table-->
        <trustifi-table-c
                class="trustifi-table" style="flex: 1 0; z-index: 5; overflow: auto;"
                [list]="aliases"
                [headers]="[
					{text:'Alias', sortKey:'alias'},
					{text:'Email', sortKey:'email'}
				]"
                [cellsPrototype]="[
					{textKey: 'alias'},
					{textKey: 'email'},
				]"
                [cellActions]="{
					enable: showActions,
					isSmaller: true,
					selectItemActionCb:	selectAliasAction.bind(this),
					initActionsFunction: showAliasActions.bind(this),
					showInRed: {enable: true, terms: ['Delete']}
				}"
                [options]="{
					loadingTableFlag: getAliasesInProcess,
					itemsNameSingular: 'alias',
					defaultSortKey: 'email',
					showRadioButtons: multipleSelection
				}"
                (searchFunction)="searchAlias($event)"
        >
			<div tableButtons>
				<div class="flxRow hSpace1" style="align-items: center;">
					<btn-c style="width: 135px; margin: 0 5px 7px 0;"
						 endIcon="fas fa-file-csv"
						 [isDisabled]="uploadAliasesInProcess || getAliasesInProcess"
						 (action)="openUploadAliasesPopup();">
						Import Aliases
					</btn-c>
				</div>
			</div>
        </trustifi-table-c>
    </div>
</popup-c>
