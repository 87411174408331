import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {Router} from "@angular/router";

@Component({
	selector: 'activity-logs-component',
	templateUrl: './activity-logs.component.html',
})

export class ActivityLogsComponent implements OnInit {
	constructor(private router:Router,
				public gs: GeneralService,
				private rs: RouteService,
				private ns: NotificationService,
				private clipboard: ClipboardService) {
	}

	dic = DICTIONARY;

	loadingLogsInProcess = true;
	logs;
	logsScope = this.router.url.includes(this.dic.CONSTANTS.appStates.adminInbound) ? 'inbound' : 'outbound';
	filterAuditLogData

	auditLogsPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.all
	];
	period = this.dic.CONSTANTS.trendsPeriod.last3Days;
	scheduleReportShow = false;

	ngOnInit() {
		this.initFilters();
		this.getAdminLogs();
	}

	changePeriod = (inputPeriod) => {
		this.period = inputPeriod;
		this.getAdminLogs();
	};

	initFilters = () => {
		if (this.logsScope === 'inbound') {
			this.filterAuditLogData = {
				filterType: this.dic.CONSTANTS.tableFilters.auditLogInbound,
				filters: {
					category: ['Plan Settings', 'Mailbox Management', 'Configuration', 'Inbound Shield', 'Quarantined Emails', 'Integrations']
				},
				initFiltersFunction: this.initFilters
			};
		}
		else {
			this.filterAuditLogData = {
				filterType: this.dic.CONSTANTS.tableFilters.auditLogOutbound,
				filters: {
					category: ['Look & Feel', 'Plan Settings', 'User Management', 'Rules & Policies', 'Domains', 'Quarantined Emails', 'Reports']
				},
				initFiltersFunction: this.initFilters
			};
		}
	};

	getAdminLogs = () => {
		this.loadingLogsInProcess = true;

		const params = {period: this.period.value};

		if (this.logsScope === 'inbound') {
			this.rs.getPlanInboundAuditLogs(params).then( (response) => {
				this.logs = response;
				this.loadingLogsInProcess = false;
			}, err => {
				this.loadingLogsInProcess = false;
			});
		}
		else {
			this.rs.getPlanOutboundAuditLogs(params).then( (response) => {
				this.logs = response;
				this.loadingLogsInProcess = false;
			}, err => {
				this.loadingLogsInProcess = false;
			});
		}
	};

	scheduleReport = () => {
		this.scheduleReportShow = true;
	};

	searchLogs =  (searchTerm, activeFilters) => {
		this.logs.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = this.searchAuditLogTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			if (activeFilters) {
				// need to match all filter types
				let numFilterToMatch = Object.keys(activeFilters).length;

				if (activeFilters.category && activeFilters.category.length) {
					if (activeFilters.category.includes(record.category)) {
						numFilterToMatch--;
					}
				}

				if (numFilterToMatch) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	};

	searchAuditLogTextExecute = (log, searchTerm) => {
		searchTerm = searchTerm.toLowerCase();
		return ((log.action && log.action.toLowerCase().indexOf(searchTerm) >= 0) ||
			(log.admin && log.admin.toLowerCase().indexOf(searchTerm) >= 0) ||
			(log.category && log.category.toLowerCase().indexOf(searchTerm) >= 0));
	}

	exportToCsv =  (sortBy) => {
		if (!this.logs || !this.logs.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Date,Category,User,Action\n";

		let sortedTable = _.filter(this.logs,log => {return !log.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((log) => {
			csvString += `${log.created},"${log.category}",${log.admin},"${log.action}"\n`;
		});

		this.gs.exportCsv(csvString, `audit_logs.csv`);
	};

	copyToClipboard = (textToCopy) => {
		this.clipboard.copy(textToCopy);
	}
}
