<div class="flxClmn dashboard-page" style="position: relative;"
	 [ngStyle]="generatePdfInProcess && {'overflow' : 'hidden'}">

	<div class="page-inner-content-holder bs-enabled" style="background: white; padding-top: 0; position: relative;"
		 [ngClass]="{'no-scroll' : generatePdfInProcess}"
		 [ngStyle]="{'overflow' : generatePdfInProcess ? 'hidden' : ''}">
		<!--generate pdf loader-->
		<div *ngIf="generatePdfInProcess" class="pdf-generate-loader">
			<div style=" position: absolute; top: calc(50vh - 200px - 40px); left: calc(50vw - 70px); transform: translate(-50%,0); font-size: 4.3rem; opacity: 0.5; align-items: center;"
				 class="animated flxClmn vSpace2 infinite">
				<div class="animated bounce infinite">
					<i class="far fa-file-pdf grey-to-redFX" style="filter: contrast(1.5) drop-shadow(2px -1px 1px lightgrey);"></i>
				</div>
				<span style="position: relative; font-size: 1.3rem;" class="loading-dots sss">Generating PDF. Please wait</span>
			</div>
		</div>

		<div class="flxRow-between gap-2 mt-0 animated fadeIn" style="position: relative; z-index: 45; align-items: center; margin: 20px 0;">
			<div class="period-buttons-group bs-enabled flxRow hSpace1" style="height: 30px;">
				<button class="period-button bs-btn bs-btn-light h-100"
						*ngFor="let item of dashboardPeriod | keyvalue: gs.returnZero"
						[ngClass]="{'chosen' : period === item.value, 'd-none' : item.value.display === dic.CONSTANTS.trendsPeriod.range.display}"
						(click)="changePeriod(item.value);">
					{{item.value.display}}
				</button>
				<div class="position-relative range-button">
					<button class="period-button hSpace05 bs-btn bs-btn-light h-100" id="customPeriodBtn"
							[ngClass]="{'chosen' : period?.display === dic.CONSTANTS.trendsPeriod.range.display}"
							(click)="showTrendsCustomPeriod = !showTrendsCustomPeriod;">
								<span *ngIf="period?.display === dic.CONSTANTS.trendsPeriod.range.display" style="font-weight: 300;">
									From <b class="px-2">{{range.start | date:'MM/dd/yyyy'}}</b> Until <b class="px-2">{{range.end | date:'MM/dd/yyyy'}}</b>
								</span>
						<span *ngIf="period?.display !== dic.CONSTANTS.trendsPeriod.range.display">
									Custom
								</span>
						<i class="fa fa-sort-down" style="margin-top: -5px;"></i>
					</button>
					<!--custom period modal-->
					<div class="drop-menu mt-1 p-2" style="width: fit-content; max-width: none; min-width: 300px; overflow: visible; min-height: 0;"
						 [ngClass]="{'active' : showTrendsCustomPeriod}">
						<div class="p-3 text-start m-0 flex-row align-items-center gap-2" (clickOutside)="showTrendsCustomPeriod = false;"
							 style="min-width: 490px;"
							 [exclude]="'#customPeriodBtn'">
							<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
								<span>Date Range:</span>
								<date-picker-c class="f-1-0"
											   [(dateModel)]="range"
											   [isRange]="true"
											   [dateFormat]="'mm/dd/yyyy'">
								</date-picker-c>
							</div>
							<btn-c endIcon="zmdi zmdi-check"
								   [loading]="getDataInProcess"
								   (action)="changePeriod(dic.CONSTANTS.trendsPeriod.range);">
								Get Trends
							</btn-c>
						</div>
					</div>
				</div>

				<div class="flxClmn-center animated fadeIn" style="cursor: pointer; background: white; font-weight: 300; color: #85000d; font-size: 0.75rem;"
					 *ngIf="showAbortLoadingEmailsLabel"
					 (click)="abortLoadingEmails()">
					<b>Abort</b>
				</div>
			</div>

			<div class="flxRow hSpace1" style="align-items: center;">
				<btn-c endIcon="fas fa-file-download" variant="outlined"
					   [loading]="generatePdfInProcess"
					   [isDisabled]="!emails?.length || getDataInProcess"
					   (action)="exportChartsToPdf();">
					Export to PDF
				</btn-c>
			</div>
		</div>

		<div *ngIf="sensitivityConfiguration && userInfo.type !== dic.CONSTANTS.userTypes.dlp" class="flex-row gap-3" style="align-items: center;">
			<span>Real-time email classification monitoring
				<i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
				   tooltip='When enabled, all classification types will be automatically scanned in emails, giving you a clear picture of the data being sent'></i>
				<i class="fas fa-arrow-to-left opacity-50"></i>
			</span>

			<div class="content-container">
				<toggle-switch-c id="sensitivity_monitor_mode"
								 [(model)]="sensitivityConfiguration.sensitivity_monitor_mode"
								 [hideText]="true"
								 (onChange)="updateMonitorMode()"></toggle-switch-c>
			</div>

			<div class="flex-row gap-2" *ngIf="!sensitivityConfiguration.sensitivity_monitor_mode">
				(<i class="fa fa-exclamation"></i>
				<span>When disabled, only the sensitivity types set by your DLP rules are scanned, not all types</span>)
			</div>
		</div>

		<hr/>

		<div class="bs-enabled" id="trendsChartsContainer" style="--chart-pdf-layout-width: 1450px;">
			<div class="bs-container text-center" style="max-width: none !important;"
				 [ngClass]="{'w-100' : !generatePdfInProcess}">
				<div class="bs-row trends-pdf-page-title" style="display: none;">
					<img class="mb-3" [src]="lfs.logo" style="width: 200px;"/>
				</div>
				<div class="bs-row trends-pdf-page-title" style="display: none;">
					<div class="bs-col-12 mb-4 px-4" style="background: #fdfdfd; padding: 10px;">
						<div class="flxClmn vSpace05">
							<div class="flxRow-between" style="align-items: flex-end;">
								<span style="font-weight: bold; font-size: 1.6rem; white-space: nowrap;">Classification Trends and Insights</span>
								<span style="font-size: 1.25rem; white-space: nowrap;">
									Date printed: &nbsp;
									<time>{{todayDate | date:'MM/dd/yyyy'}}</time>
								</span>
							</div>
							<div class="flxRow hSpace05" style="font-size: 1.25rem;">
								<span>Period:&nbsp;</span>
								<span *ngIf="period !== dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">{{period.display}}</span>
								<span *ngIf="period === dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">
								<time>{{range.start | date:'MM/dd/yyyy'}}</time>
								&nbsp;-&nbsp;
								<time>{{range.end | date:'MM/dd/yyyy'}}
								</time>
							</span>
							</div>
						</div>
					</div>
				</div>

				<div class="bs-row g-4">

					<!--Classification Types-->
					<div class="bs-col-6" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold text-nowrap">DLP Sensitivity Types</h6>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;">
									Most commonly detected classification types in your sent emails
								</span>
							</div>
							<div class="flex-column f-1-0 centralize animated fadeIn">
								<apx-chart class="pie-chart pie-chart-left" style="width: 100%; height: 100%;"
										   *ngIf="classificationTypesChartOptions?.series?.length && !getDataInProcess"
										   #classificationTypesChart
										   [series]="classificationTypesChartOptions.series"
										   [chart]="classificationTypesChartOptions.chart"
										   [plotOptions]="classificationTypesChartOptions.plotOptions"
										   [dataLabels]="classificationTypesChartOptions.dataLabels"
										   [colors]="classificationTypesChartOptions.colors"
										   [labels]="classificationTypesChartOptions.labels"></apx-chart>
							</div>

							<!--tips modal-->
							<div class="trends-card-actions-menu tips" style="right: 20px !important;"
								 *ngIf="classificationTypesChartOptions && userInfo.type !== dic.CONSTANTS.userTypes.dlp"
								 [ngClass]="{'active' : showClassificationTypesTips}">
								<i class="fa fa-lightbulb" id="showClassificationTypesTips" tooltip="Tips"
								   (click)="showClassificationTypesTips = !showClassificationTypesTips"></i>
								<div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
									 [ngClass]="{'active' : showClassificationTypesTips}">
									<ul style="cursor: default;" (clickOutside)="showClassificationTypesTips = false;"
										[exclude]="'#showClassificationTypesTips'" >
										<div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
											<i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
											<span class="fw-500">Click on any pie slice to view the emails with this type.</span>
										</div>
									</ul>
								</div>
							</div>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!classificationTypesChartOptions?.series?.length || getDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Compliance trends-->
					<div class="bs-col-6" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold text-nowrap">Compliance Violations</h6>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;">
									Overview of compliance violation trends over time
								</span>
							</div>
							<apx-chart class="f-1-0 animated fadeIn clickable-chart"
									   *ngIf="classificationComplianceTrendsOptions?.series?.length && !getDataInProcess"
									   #classificationComplianceTrendsChart
									   [series]="classificationComplianceTrendsOptions.series"
									   [chart]="classificationComplianceTrendsOptions.chart"
									   [plotOptions]="classificationComplianceTrendsOptions.plotOptions"
									   [xaxis]="classificationComplianceTrendsOptions.xaxis"
									   [yaxis]="classificationComplianceTrendsOptions.yaxis"
									   [fill]="classificationComplianceTrendsOptions.fill"
									   [stroke]="classificationComplianceTrendsOptions.stroke"
									   [dataLabels]="classificationComplianceTrendsOptions.dataLabels"></apx-chart>

							<!--tips modal-->
							<div class="trends-card-actions-menu tips" style="right: 20px !important;"
								 *ngIf="classificationComplianceTrendsOptions && userInfo.type !== dic.CONSTANTS.userTypes.dlp"
								 [ngClass]="{'active' : showComplianceTrendsTips}">
								<i class="fa fa-lightbulb" id="showComplianceTrendsTips" tooltip="Tips"
								   (click)="showComplianceTrendsTips = !showComplianceTrendsTips"></i>
								<div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
									 [ngClass]="{'active' : showComplianceTrendsTips}">
									<ul style="cursor: default;" (clickOutside)="showComplianceTrendsTips = false;"
										[exclude]="'#showComplianceTrendsTips'" >
										<div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
											<i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
											<span class="fw-500">Click on an area to view the emails with this type.</span>
										</div>
										<div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
											<i class="fas fa-info" style="flex: 0 0 16px;"></i>
											<span class="fw-500">Click any compliance label to filter it out of the chart</span>
										</div>
									</ul>
								</div>
							</div>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!classificationComplianceTrendsOptions?.series?.length || getDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Sensitive Emails per Domain-->
					<div class="bs-col-6 bs-col-large-4 " style="height: clamp(350px,50vh,405px);" *ngIf="userInfo.type !== dic.CONSTANTS.userTypes.dlp">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold text-nowrap">Top Domains Receiving Sensitive Emails</h6>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;">
									Domains that receive the highest volume of sensitive emails
								</span>
							</div>
							<div class="flex-column f-1-0 centralize animated fadeIn">
								<apx-chart class="pie-chart" style="width: 100%; flex: 1 0;"
										   *ngIf="classificationSensitivePerDomainOptions?.xaxis?.categories?.length && !getDataInProcess"
										   #classificationSensitivePerDomainChart
										   [series]="classificationSensitivePerDomainOptions.series"
										   [chart]="classificationSensitivePerDomainOptions.chart"
										   [plotOptions]="classificationSensitivePerDomainOptions.plotOptions"
										   [xaxis]="classificationSensitivePerDomainOptions.xaxis"
										   [colors]="classificationSensitivePerDomainOptions.colors"
										   [dataLabels]="classificationSensitivePerDomainOptions.dataLabels"></apx-chart>
							</div>

							<!--tips modal-->
							<div class="trends-card-actions-menu tips" style="right: 20px !important;"
								 *ngIf="classificationSensitivePerDomainOptions && userInfo.type !== dic.CONSTANTS.userTypes.dlp"
								 [ngClass]="{'active' : showSensitivePerDomainTips}">
								<i class="fa fa-lightbulb" id="showSensitivePerDomainTipsT" tooltip="Tips"
								   (click)="showSensitivePerDomainTips = !showSensitivePerDomainTips"></i>
								<div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
									 [ngClass]="{'active' : showSensitivePerDomainTips}">
									<ul style="cursor: default;" (clickOutside)="showSensitivePerDomainTips = false;"
										[exclude]="'#showSensitivePerDomainTipsT'" >
										<div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
											<i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
											<span class="fw-500">Click on any bar to view the emails with this type.</span>
										</div>
									</ul>
								</div>
							</div>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!classificationSensitivePerDomainOptions?.xaxis?.categories?.length || getDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Sensitive Emails per sender-->
					<div class="bs-col-6 bs-col-large-4 " style="height: clamp(350px,50vh,405px);" *ngIf="userInfo.type !== dic.CONSTANTS.userTypes.dlp">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold text-nowrap">Top Senders of Sensitive Emails</h6>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;">
									Senders who most frequently send sensitive emails
								</span>
							</div>
							<div class="flex-column f-1-0 centralize animated fadeIn">
								<apx-chart class="pie-chart" style="width: 100%; flex: 1 0;"
										   *ngIf="classificationSensitiveSenderOptions?.xaxis?.categories?.length && !getDataInProcess"
										   #classificationSensitiveSenderChart
										   [series]="classificationSensitiveSenderOptions.series"
										   [chart]="classificationSensitiveSenderOptions.chart"
										   [plotOptions]="classificationSensitiveSenderOptions.plotOptions"
										   [colors]="classificationSensitiveSenderOptions.colors"
										   [xaxis]="classificationSensitiveSenderOptions.xaxis"
										   [dataLabels]="classificationSensitiveSenderOptions.dataLabels"></apx-chart>
							</div>

							<!--tips modal-->
							<div class="trends-card-actions-menu tips" style="right: 20px !important;"
								 *ngIf="classificationSensitiveSenderOptions && userInfo.type !== dic.CONSTANTS.userTypes.dlp"
								 [ngClass]="{'active' : showSensitiveSenderTips}">
								<i class="fa fa-lightbulb" id="showSensitiveSenderTips" tooltip="Tips"
								   (click)="showSensitiveSenderTips = !showSensitiveSenderTips"></i>
								<div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
									 [ngClass]="{'active' : showSensitiveSenderTips}">
									<ul style="cursor: default;" (clickOutside)="showSensitiveSenderTips = false;"
										[exclude]="'#showSensitiveSenderTips'" >
										<div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
											<i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
											<span class="fw-500">Click on any bar to view the emails with this type.</span>
										</div>
									</ul>
								</div>
							</div>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!classificationSensitiveSenderOptions?.xaxis?.categories?.length || getDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Compliance types-->
					<div class="bs-col-6 bs-col-large-4 " style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold text-nowrap">Compliance Violation Breakdown</h6>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;">
									Breakdown of each violated compliance type
								</span>
							</div>
							<div class="flex-column f-1-0 centralize animated fadeIn">
								<apx-chart class="pie-chart pie-chart-left" style="width: 100%; height: 100%;"
										   *ngIf="classificationComplianceTypesOptions?.series?.length && !getDataInProcess"
										   #complianceTypesChart
										   [series]="classificationComplianceTypesOptions.series"
										   [chart]="classificationComplianceTypesOptions.chart"
										   [plotOptions]="classificationComplianceTypesOptions.plotOptions"
										   [dataLabels]="classificationComplianceTypesOptions.dataLabels"
										   [colors]="classificationComplianceTypesOptions.colors"
										   [labels]="classificationComplianceTypesOptions.labels"></apx-chart>
							</div>

							<!--tips modal-->
							<div class="trends-card-actions-menu tips" style="right: 20px !important;"
								 *ngIf="classificationComplianceTypesOptions && userInfo.type !== dic.CONSTANTS.userTypes.dlp"
								 [ngClass]="{'active' : showComplianceTypesTips}">
								<i class="fa fa-lightbulb" id="showComplianceTypesTips" tooltip="Tips"
								   (click)="showComplianceTypesTips = !showComplianceTypesTips"></i>
								<div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
									 [ngClass]="{'active' : showComplianceTypesTips}">
									<ul style="cursor: default;" (clickOutside)="showComplianceTypesTips = false;"
										[exclude]="'#showComplianceTypesTips'" >
										<div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
											<i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
											<span class="fw-500">Click on any pie slice to view the emails with this type.</span>
										</div>
									</ul>
								</div>
							</div>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!classificationComplianceTypesOptions?.series?.length || getDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div>
</div>


<popup-c *ngIf="emailsPopup?.show"
		 head="Emails" size="large"
		 (closeCb)="emailsPopup = null;"
		 [buttons]="{cancel: 'OK'}">
	<div class="flxClmn f-1-0" style="overflow: auto;">
		<trustifi-table-c
			class="trustifi-table" style="flex: 1 0; z-index: 5;"
			[list]="emailsPopup.emails"
			[headers]="[
				{text: 'Created', sortKey: 'created', width: '16%'},
				{text: 'Subject', sortKey: 'sent.title'},
				{text: 'From', sortKey: 'from.email'},
				{text: 'Recipients'},
				{text: 'Sensitivity', sortKey: 'sensitivity.score', width: '10%'},
			]"
			[cellsPrototype]="[
				{textKey: 'created', textType: 'dateAndTime'},
				{textKey: 'sent.title'},
				{html: 'fromCell'},
				{html: 'recipientsCell'},
				{html: 'sensitivityCell'},
			]"
			(searchFunction)="searchEmails($event)"
			[options]="{
				isEllipsis: false,
				itemsNameSingular: 'email',
				defaultSortKey: '-created'
			}"
		>
			<ng-template #customCells let-item let-cellId="cellId">
				<div class="ellipsis" *ngIf="cellId === 'recipientsCell'">
					<span *ngFor="let rcpt of item.recipients_display_only.to" tooltip="{{rcpt.address}}">
						{{rcpt.address}}<br/>
					</span>
					<span *ngFor="let rcpt of item.recipients_display_only.cc" tooltip="{{rcpt.address}}">
						{{rcpt.address}}<br/>
					</span>
				</div>

				<div class="ellipsis" *ngIf="cellId === 'fromCell'">
					<span tooltip="{{item.from.email}}">{{item.from.email}}</span>
				</div>

				<div *ngIf="cellId === 'sensitivityCell'">
					<input type="button" value="{{item.sensitivity.score}}"
						   [ngStyle]="item.sensitivity.score === 0 && {'background':'limegreen'} || item.sensitivity.score < 4 && {'background':'darkorange'} || item.sensitivity.score < 5 && {'background':'#FF851B'} || item.sensitivity.score === 5 && {'background':'#FF4136'}"
						   (click)="openSensitiveInfoPopup(item)"
						   class="redborder fa" style="background: orange; width: 25px; height: 25px; color: white !important; border: 0; margin-left: 3px; margin-top: -4px;"
						   placement="right"
						   tooltip="Sensitivity score is {{item.sensitivity.score}}. Click to review sensitivity details">
				</div>

			</ng-template>
		</trustifi-table-c>
	</div>
</popup-c>

<sensitivity-details
	*ngIf="showSensitiveInfoPopup?.show"
	type="email"
	[origin]="'rules'"
	[scanItemObj]="showSensitiveInfoPopup.email"
	(cancelCb)="showSensitiveInfoPopup = null;"
	[scanTitle]="showSensitiveInfoPopup.emailTitle"
	[sensitivity]="showSensitiveInfoPopup.sensitivity">
</sensitivity-details>

