import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import _ from 'lodash';

import {GeneralService} from "../../services/generalService";
import {RouteService} from "../../services/routeService";
import {DICTIONARY} from "../../dictionary";
import {ClipboardService} from "ngx-clipboard";
import util from 'util';
import {AuthService} from '../../services/authService';


@Component({
	selector: 'exchange-login-wizard-c',
	templateUrl: './exchange-login-wizard.component.html',
})
export class ExchangeLoginWizardComponent implements OnInit {

	@Input() planInfo;
	@Output() closePopupFn: EventEmitter<any> = new EventEmitter<any>();

	constructor(private rs: RouteService,
				public gs: GeneralService,
				private authService:AuthService,
				private clipboard: ClipboardService,
				private ngZone: NgZone) {}

	dic = DICTIONARY;
	_ = _;

	// environment
	validSteps = Object.entries(DICTIONARY.CONSTANTS.exchangeRulesSteps).filter(itm => itm[0] !== 'domains' && itm[0] !== 'configuration').map(itm => itm[1]);
	step = DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation;
	errorMsg = '';
	exchangeServerEnabled;
	refreshTokenEnabled;
	exchangeWindow;
	isExchangeWindowOpen;
	rulesStatus;
	generateTokenInProcess;
	token;
	inputs;
	tokenCopied;
	disconnected;
	userInfo;
	integrationMethods = {
		serviceAuthentication: {
			name: 'Graph API (recommended)',
			description: 'This connection is recommended for continuous actions like monitoring Exchange quarantine'
		},
		accessToken: {
			name: 'PowerShell Script',
			description: 'This connection is recommended for single-use actions like creating Exchange connectors and rules'
		}
	};
	integrationMethod = this.integrationMethods.serviceAuthentication.name;

	ngOnInit() {

		this.exchangeServerEnabled = this.planInfo.exchange_server?.enabled;
		this.refreshTokenEnabled = this.planInfo.powershell?.refresh_token;

		this.gs.getUserInfo(false, userInfo => {
			this.userInfo = userInfo;
		});
	}

	closePopup = () => {
		let success = this.rulesStatus && this.rulesStatus.status === 'success';
		this.closePopupFn.emit({success, disconnected:this.disconnected});
	}

	openExchangeWindow = () => {
		this.errorMsg = '';
		this.rulesStatus = null;

		if (this.generateTokenInProcess) {
			this.errorMsg = DICTIONARY.ERRORS.waitForTokenGenerate;
			return;
		}
		if (this.isExchangeWindowOpen) {
			if (this.exchangeWindow) {
				this.exchangeWindow.focus();
				return;
			}
			else {
				this.isExchangeWindowOpen = false;
			}
		}

		this.exchangeWindow = window.open('https://login.microsoftonline.com/common/oauth2/deviceauth', '_blank', 'location=no,width=750,height=600,scrollbars=no,resizable=no');
		if (this.exchangeWindow) {
			this.isExchangeWindowOpen = true;
			const timer = setInterval(() => { // this is not a hack! it's the popular and only way to handle a cross-origin popup
				if (this.exchangeWindow.closed) {
					clearInterval(timer);
					this.exchangeWindow = null;
					this.errorMsg = '';
					this.rulesStatus = null;

					this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.summary;

					this.generateTokenInProcess = true;
					this.rs.exchangeDeviceCodeToken({userCode: this.token}).then(response => {
						this.ngZone.run(() => {
							this.generateTokenInProcess = false;
							this.rulesStatus = response;
						});
					}, err => {
						if (err.data && err.data.message && err.data.message.match(/^AADSTS\d+:/)) {
							err.data.message = util.format(DICTIONARY.ERRORS.powershellAuthError, err.data.message.split(':')[0]);
						}
						this.ngZone.run(() => {
							this.generateTokenInProcess = false;
							this.rulesStatus = {status: 'failure', message: err.data.message};
							this.disconnected = err.data.disconnected;
						});
					});
				}
			}, 1000);
		}
	}

	getToken = () => {
		this.token = null;
		this.generateTokenInProcess = true;

		this.rs.exchangeDeviceCodeAuthenticate().then(response => {
			this.token = response.user_code;
			this.generateTokenInProcess = false;
		}, err => {
			if (!err.data.display_bar) {
				this.errorMsg = err.data.message;
			}
			this.generateTokenInProcess = false;
		});
	}

	copyToken = () => {
		this.clipboard.copy(this.token);
	}

	backStep = () => {
		this.errorMsg = '';
		switch (this.step) {
			case DICTIONARY.CONSTANTS.exchangeRulesSteps.authAndExecution:
				if (this.generateTokenInProcess) {
					this.errorMsg = DICTIONARY.ERRORS.waitForTokenGenerate;
					return;
				}

				this.getToken();
				this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation;
				break;

			case DICTIONARY.CONSTANTS.exchangeRulesSteps.summary:
				this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation;
				break;

			default:
				return;
		}
	}

	nextStep = () => {
		this.errorMsg = '';
		switch (this.step) {
			case DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation:
				if (this.exchangeServerEnabled && this.integrationMethod === this.integrationMethods.serviceAuthentication.name) {
					this.connectToEXOAdmin();
					return;
				}
				else {
					this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.authAndExecution;
					this.getToken();
				}
				break;

			default:
				return;
		}
	}

	connectToEXOAdmin() {
		if (!this.userInfo || !this.planInfo?.exchange_server?.enabled) {
			return;
		}

		this.isExchangeWindowOpen = true;
		const azEndpoint = DICTIONARY.CONSTANTS.exchangeServerEndpoints.Global.AzureAD || this.planInfo.exchange_server.azuread_endpoint;
		this.authService.authMicrosoftExchangeAdmin(this.userInfo.email, azEndpoint, this.planInfo.exchangeEXOAdminAppId, 'exoadmin', (err) => {
			if (err) {
				if (err !== true) {
					err = err.replace('Sign in failed', 'Powershell authentication failed');
					this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.summary;
					this.isExchangeWindowOpen = false;
					this.rulesStatus = {status: 'failure', message: err};
					this.disconnected = true;
				}
				return;
			}

			this.ngZone.run(() => {
				this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.summary;
				this.isExchangeWindowOpen = false;
				this.generateTokenInProcess = true;
			});

			this.rs.powershellServiceAuthenticationConnect().then(response => {
				this.ngZone.run(() => {
					this.generateTokenInProcess = false;
					this.rulesStatus = response;
				});
			}, err => {
				this.ngZone.run(() => {
					this.generateTokenInProcess = false;
					this.rulesStatus = {status: 'failure', message: err.data.message};
					this.disconnected = true;
				});
			});
		});
	};
}
