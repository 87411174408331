<div class="flex-column gap-4 animated fadeIn bs-enabled">
    <span>Integrate your account with 3rd party services</span>
    <div class="list-group list-group-flush settings-list-container">
        <div class="settings-list-item pt-3">
            <ul class="border border-1 border-light" style="list-style: none;">
                <li style="height: auto; padding: 20px;">
                    <div class="flxRow hSpace2 integration-icons" id="intgIconsContainer" *ngIf = "integrations">
                        <div [ngClass]="{'connected' : integrations.clio.enabled}"
                             (click)="clioLogin()"
                             style="height: 70px; width: 70px;"
                             tooltip="Clio">
                            <img style="height: 53px; width: 40px;" src="images/brand-img01.jpg">
                            <span style="transform: translate(150%,150%);" class="led-indicator"></span>
                        </div>

                        <div *ngIf="showDropboxIntegration"
                             style="height: 70px; width: 70px;"
                             [ngClass]="{'connected' : integrations.dropbox.enabled}"
                             (click)="dropboxLogin()"
                             tooltip="Dropbox">
                            <img style="height: 45px; width: 40px;"src="images/brand-img03.jpg">
                            <span style="transform: translate(150%,150%);" class="led-indicator"></span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
