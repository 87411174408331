import {Component} from "@angular/core";
import {TabsPageContainer} from "../../../uiComponents/tabsPageContainer";

@Component({
	selector: 'inbound-shield-tabs',
	templateUrl: './inbound-shield-tabs.component.html',
})
export class InboundShieldTabsComponent extends TabsPageContainer {
	tabWidth = 200;
}
