import {GeneralService} from "../../../services/generalService";
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'partner-registration-component',
	templateUrl: './partner-registration.component.html',
})
export class PartnerRegistrationComponent implements OnInit {
    constructor(public gs:GeneralService, private rs:RouteService, private ns:NotificationService) {
    }

    registrationInputs;
    chosenRegistrationIndex = 0;
    registrationInfoError = '';
    showUndoneFields;
    dic = DICTIONARY;

	ngOnInit() {
	}

	sendRegistration() {
        const registrationInputs = {
            action: this.dic.CONSTANTS.partnerAction.registration
        };

        for (let idx = 0; idx < this.registrationInfo.length; idx++) {
            let section = this.registrationInfo[idx];
            for (let j = 0; j < section.inputs.length; j++) {
                let input:any = section.inputs[j];
                if (input.model) {
                    registrationInputs[input.name] = input.model;
                }

                if (section.required && !input.model) {
                    this.chosenRegistrationIndex = idx;
                    this.showUndoneFields = true;
                    this.registrationInfoError = "Please fill all required fields";
                    return;
                }
            }
        }

        this.rs.doActionOnPartner(registrationInputs).then((response) => {
            this.registrationInputs = {};
            this.ns.showInfoMessage('Registration Form sent successfully');
            this.chosenRegistrationIndex = 0;
            this.registrationInfoError = '';
        });
    };

    registrationInfo = [
        {
            title: "End User Customer Information",
            required: true,
            inputs: [
                {text:"Company", name: 'company'},
                {text:"First Name" ,name: 'firstName'},
                {text:"Last Name" ,name: 'lastName'},
                {text:"Title" ,name: 'title'},
                {text:"Email" ,name: 'email'},
                {text:"Phone" ,name: 'phone'},
            ]
        },
        {
            title: "Address Information",
            required: false,
            inputs: [
                {text:"Street address" ,name: 'street'},
                {text:"City" ,name: 'city'},
                {text:"State / Province" ,name: 'state'},
                {text:"Country" ,name: 'country'},
                {text:"Zip/Postal code" ,name: 'zipCode', type: "number"},
            ]
        },
        {
            title: "Opportunity Overview",
            required: false,
            inputs: [
                {text:"# of Licenses" ,name: 'numberLicenses', type: "number"},
                {text:"Compliance" ,name: 'compliance'},
                {text:"Industry" ,name: 'industry'},
                {text:"Distributor" ,name: 'distributor'},
                {text:"Partner Contact" ,name: 'partnerContact'},
                {text:"Channel Manager" ,name: 'channelManager'}
            ]
        },
    ]
}
