import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import _ from 'lodash';
import {GeneralService} from "../../services/generalService";
import {RouteService} from "../../services/routeService";
import {DICTIONARY} from "../../dictionary";


@Component({
	selector: 'exchange-update-rules-wizard-c',
	templateUrl: './exchange-update-rules-wizard.component.html',
})
export class ExchangeUpdateRulesWizardComponent implements OnInit {

	@Input() isInboundConfiguration: boolean;
	@Output() closePopupFn: EventEmitter<any> = new EventEmitter<any>();

	constructor(private rs: RouteService,
				public gs: GeneralService) {}

	dic = DICTIONARY;
	_ = _;

	// environment
	validSteps = [DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation, DICTIONARY.CONSTANTS.exchangeRulesSteps.summary];
	step = DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation;
	errorMsg = '';
	rulesStatus;
	generateTokenInProcess;
	token;
	inputs;
	disconnected;

	ngOnInit() {
	}

	closePopup = () => {
		let success = this.rulesStatus && this.rulesStatus.status === 'success';
		this.closePopupFn.emit({success, disconnected:this.disconnected});
	}

	exchangeSwitchMTARules = () => {
		const data = {
			isInboundConfiguration: this.isInboundConfiguration || false
		}

		this.rulesStatus = null;
		this.generateTokenInProcess = true;

		this.rs.exchangeSwitchMTARules(data).then(response => {
			this.rulesStatus = response;
			this.generateTokenInProcess = false;
		}, err => {
			this.rulesStatus = err.data;
			this.generateTokenInProcess = false;
			this.disconnected = err.data.disconnected;
		});
	}

	backStep = () => {
		this.errorMsg = '';

		switch (this.step) {
			case DICTIONARY.CONSTANTS.exchangeRulesSteps.summary:
				if (this.generateTokenInProcess) {
					this.errorMsg = DICTIONARY.ERRORS.waitForTokenGenerate;
					return;
				}

				this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation;
				break;

			default:
				return;
		}
	}

	nextStep = () => {
		this.errorMsg = '';
		switch (this.step) {
			case DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation:
				this.exchangeSwitchMTARules();
				this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.summary;
				break;

			default:
				return;
		}
	}
}
