import * as util from 'util';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {eachLimit} from 'async';
import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit, ViewChild} from "@angular/core";
import {ArchiveService} from "../../../services/archiveService";
import {ArchiveCasesOperationsComponent} from "../archiveCasesOperations/archive-cases-operations.component";


@Component({
	selector: 'archive-search-component',
	templateUrl: './archive-search.component.html',
})
export class ArchiveSearchComponent implements OnInit {
	@ViewChild('caseOperationsEl') caseOperationsEl: ArchiveCasesOperationsComponent;

	constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService,
				public archiveService:ArchiveService){
    }

    dic = DICTIONARY;

	abortLoadingFlag: boolean = false;

    getEmailsInProcess = false;

	searchArchiveEmailTxt;
	_ = _;
    isApplyCaseEnabled = true;

    caseActions = [
        this.dic.CONSTANTS.archiveCaseActions.edit,
        this.dic.CONSTANTS.archiveCaseActions.manageSharing,
        this.dic.CONSTANTS.archiveCaseActions.showLogs
    ];
	casesPeriods = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.range,
		this.dic.CONSTANTS.trendsPeriod.all
	];
	showCaseActions = false;

    activeCase;
    selectedEmail;
    cases;
    range;
    createEditCasePopup;
    originalCaseBeforeEdit;
    actionInProcess;
    previewEmailInProcess;
    selectedAll;
    selectedEmails;
	showNewCaseAdvanced;
	defaultRange = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()}; // (past one day);

	compliances: any = [{name: "GDPR"}, {name: "FERPA"}, {name: "HIPAA"}, {name: "PCI"},
		{name: "CCPA"}, {name: "POPI"}, {name: "LGPD"}, {name: "PDPO"}, {name: "GLBA"}];

	ngOnInit() {
        this.activeCase = null;
        this.selectedEmail = null;
        this.archiveService.currentEmailsList = [];
        this.archiveService.currentFilteredEmailsList = [];
        this.cases = [];
        this.archiveService.setCurrentEmail(null);

        this.rs.getArchiveCases().then(response => {
            this.cases = response;
            if (this.cases.length === 1) {
                this.selectCase(this.cases[0]);
            }

            // autorun case:
            if (window.history.state.caseName) {
                // run case (autorun)
                const caseObj = _.find<any>(this.cases, _case => { return _case.name === window.history.state.caseName});
                if (!caseObj) {
                    this.ns.showWarnMessage("Case not found");
                    return;
                }

                this.selectedCase = caseObj;
                this.applyCase(caseObj);
            }
        }, (err) => {

		});
    };

	ngOnDestroy(): void {
		this.abortLoadingFlag = true;
	}


    selectCase = (selectedCase) => {
        this.selectedCase = selectedCase;

        // Change "Apply Case" button's state
        if (this.activeCase && this.activeCase.name === selectedCase.name) {
            this.isApplyCaseEnabled = false;
        }
        else {
            this.isApplyCaseEnabled = true;
        }
    }

	openNewCasePopup = () => {
		const currentNewOrEditCase = {
			query: {
				hasAttachments: false,
				period: this.dic.CONSTANTS.trendsPeriod.lastDay.value,
				range: _.cloneDeep(this.defaultRange)
			}
		};

		this.compliances.forEach((comp) => {
			comp.selected = false;
		});

		// show popup in 'new case' mode
		this.createEditCasePopup = {
			currentCase: currentNewOrEditCase,
			isEditCase : false,
			show: true
		};
	}

	openEditCasePopup = (caseToEdit) => {
		this.originalCaseBeforeEdit = caseToEdit; // to compare changes after edit only
		const currentNewOrEditCase = _.cloneDeep(caseToEdit); // the new model to manipulate

		currentNewOrEditCase.query['range'] = {
			start: currentNewOrEditCase.query.range?.start || this.defaultRange.start,
			end: currentNewOrEditCase.query.range?.end || this.defaultRange.end
		};

		currentNewOrEditCase.query['hasAttachments'] = currentNewOrEditCase.query.hasAttachments || false;

		this.compliances.forEach((comp) => {
			comp.selected = false;
		});

		if (currentNewOrEditCase.query.compliance?.length) {
			this.compliances.forEach((comp) => {
				if (currentNewOrEditCase.query.compliance.includes(comp.name)) {
					comp.selected = true;
				}
			});
		}

		// show popup in 'edit case' mode
		this.createEditCasePopup = {
			currentCase: currentNewOrEditCase,
			isEditCase : true,
			show: true
		};
	}

    selectCaseAction = (caseObj, action) => {
        switch (action) {
            case this.dic.CONSTANTS.archiveCaseActions.edit:
                this.openEditCasePopup(caseObj);
                break;

            case this.dic.CONSTANTS.archiveCaseActions.manageSharing:
                this.showSharingManagement(caseObj);
                break;

            case this.dic.CONSTANTS.archiveCaseActions.showLogs:
                this.showLogsForCase(caseObj);
                break;
        }
    }

    showLogsForCase = (caseObj) => {
        this.archiveService.setCurrentCase(caseObj);
		this.caseOperationsEl.showLogsForCase(this.archiveService.currentCaseObj, null)
    }

    showSharingManagement = (caseObj) => {
		this.archiveService.showSharingCasePopup(caseObj);
    }

    applyCreateEditCasePopup = () => {
        if (this.createEditCasePopup.isEditCase) {
            this.editCase(this.createEditCasePopup.currentCase);
        }
        else {
            if (!this.createEditCasePopup.currentCase.name) {
                this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'Case name field'))
                return;
            }
            this.createNewCase();
        }
    }

    editCase = (caseObj) => {
        if (this.actionInProcess) {
            return;
        }

        this.actionInProcess = true;
        const newValues = {
            action: 'query',
            name: caseObj.name,
            query: caseObj.query
        };
		newValues.query.compliance = [];
		this.compliances.forEach((comp) => {
			if (comp.selected) {
				newValues.query.compliance.push(comp.name);
			}
		});

        this.rs.updateArchiveCase(caseObj._id, newValues).then(res => {
            this.createEditCasePopup = null;
            const existingCaseObj = _.find<any>(this.cases, {_id: caseObj._id});
            if (existingCaseObj) {
                existingCaseObj.name = caseObj.name;
                existingCaseObj.query = caseObj.query;
            }

            this.actionInProcess = false;
            this.ns.showInfoMessage("Case was updated successfully");
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.actionInProcess = false;
        });
    }


    selectedCase = null;

    createNewCase = () => {
        if (this.actionInProcess) {
            return;
        }

        this.actionInProcess = true;
        const newValues = {
            name: this.createEditCasePopup.currentCase.name,
            query: this.createEditCasePopup.currentCase.query
        };
		newValues.query.compliance = [];
		this.compliances.forEach((comp) => {
			if (comp.selected) {
				newValues.query.compliance.push(comp.name);
			}
		});

        this.rs.addArchiveCase(newValues).then(response => {
            this.createEditCasePopup = null;

            this.cases.push(response.case);
            this.selectedCase = response.case;
            this.actionInProcess = false;
            this.ns.showInfoMessage("Case was created successfully");
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.actionInProcess = false;
        });
    }

	abortLoadingEmails = () => {
		this.abortLoadingFlag = true;
		this.getEmailsInProcess = false;
	}

    applyCase = (caseObj) => {
        if (this.getEmailsInProcess) {
            return;
        }

        this.getEmailsInProcess = true;
		this.abortLoadingFlag = false;

		this.archiveService.currentEmailsList = [];
		this.archiveService.currentFilteredEmailsList = [];
		this.selectedEmails = [];
		this.activeCase = true;

		this.rs.searchArchive(caseObj._id.toString()).then((response) => {
			this.archiveService.setCurrentEmail(null);

			const emails = this.archiveService.currentEmailsList.concat((response.emails || []));

			this.archiveService.currentEmailsList = _.orderBy(emails, ['_source.created'], ['desc']);
			this.searchEmailInResults(this.searchArchiveEmailTxt);

			if (response.scrollId) {
				this.applyCaseBatch(caseObj, response.scrollId);
			}
			else {
				this.getEmailsInProcess = false;
			}
		},(err)=>{
			this.getEmailsInProcess = false;
		});
    }

	applyCaseBatch = (caseObj, scrollId) => {
		if (this.abortLoadingFlag) {
			return;
		}

		this.rs.getArchiveCaseInfoWithScroll(caseObj._id.toString(), {scrollId}).then((response) => {

			if (response.emails?.length) {
				const emails = this.archiveService.currentEmailsList.concat(response.emails || []);

				this.archiveService.currentEmailsList = _.orderBy(emails, ['_source.created'], ['desc']);
				this.searchEmailInResults(this.searchArchiveEmailTxt);
			}

			if (response.scrollId) {
				this.applyCaseBatch(caseObj, response.scrollId);
			}
			else {
				this.getEmailsInProcess = false;
			}
		},(err)=>{
			this.getEmailsInProcess = false;
		});
	}

    getArchiveEmailInfo = (email) => {
        if (this.archiveService.currentMessage) {
            this.archiveService.currentMessage.show = false;
        }

        email.show = true;
        if (email.content) {
			this.archiveService.setCurrentEmail(email);
			this.archiveService.updateEmailFrame();
            return;
        }
        this.previewEmailInProcess = true;
        this.rs.getArchiveEmailInfo(encodeURIComponent(email._id)).then((response) => {
            email.content = response;

            if (email.content) {
                // calc Bcc according to rcptTo
                email.content.parsedContent.bcc = {value: []};
                email._source.rcptTo.forEach((recipientEmail) => {
                    // if not in To/Cc then it's Bcc
                    if (!((email.content.parsedContent.to?.text?.toLowerCase().indexOf(recipientEmail) > -1) ||
                        (email.content.parsedContent.cc?.text?.toLowerCase().indexOf(recipientEmail) > -1))) {

                        email.content.parsedContent.bcc.value.push({address: recipientEmail});
                    }
                });
            }
			this.archiveService.setCurrentEmail(email);
			this.archiveService.updateEmailFrame();

			this.previewEmailInProcess = false;
        }, (err)=>{
            this.previewEmailInProcess = false;
        });
    }


    validateEmail(email) {
        if (!email || email === '') return false;
        return this.gs.validateEmail(email);
    };

    clearSearchEmail() {
		this.archiveService.currentEmailsList.forEach(email => {
			email.hide = false;
		});
        this.searchArchiveEmailTxt = null;
		this.archiveService.currentFilteredEmailsList = _.reject(this.archiveService.currentEmailsList, 'hide');
		this.checkSelectAllEmails();
    };

    searchEmailInResults(searchTerm) {
        if (!searchTerm) {
			this.clearSearchEmail();
            return;
        }

        searchTerm = searchTerm.toLowerCase();

		this.archiveService.currentEmailsList.forEach(email => {
			const isFound = searchTextExecute(email, searchTerm);
			email.hide = !isFound;
		});
		this.archiveService.currentFilteredEmailsList = _.reject(this.archiveService.currentEmailsList, 'hide');
		this.checkSelectAllEmails();
	};

    toggleAllEmails = () => {
		this.archiveService.currentFilteredEmailsList.forEach(email => {
			email.selected = this.selectedAll;
		});
        this.filterSelectedEmails();
    };


    filterSelectedEmails = () => {
        this.selectedEmails = _.filter(this.archiveService.currentFilteredEmailsList, 'selected');
    };

    checkSelectAllEmails = () => {
        this.filterSelectedEmails();
		this.selectedAll = this.archiveService.currentFilteredEmailsList.length && (this.selectedEmails.length === this.archiveService.currentFilteredEmailsList.length);
    };

    exportResultsToCsv() {
        if (!this.archiveService.currentFilteredEmailsList.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Subject,Date,From,Recipients,Attachments,Message ID\n";
		this.archiveService.currentFilteredEmailsList.forEach((email) => {
            csvString += `${email._source.subject},"${email._source.created}","${email._source.from}","${email._source.rcptTo && email._source.rcptTo.join(', ')}","${email._source.attachments && email._source.attachments.join(', ')}","${email._source.messageId}"\n`;
        });

        this.gs.exportCsv(csvString, `archive_emails_list.csv`);
    };

    selectAction = (emailObj, action) => {
        switch (action) {
            case this.dic.CONSTANTS.archiveEmailActions.delete:
                this.archiveService.deleteEmailFromArchive(emailObj, () => {});
                break;
        }
    };

	downloadMultipleEmls = () => {
		if (!this.selectedEmails.length) {
			return;
		}

		if (this.selectedEmails.length === 1) {
			this.rs.getArchiveEmailInfo(encodeURIComponent(this.selectedEmails[0]._id)).then((response) => {
				const attachment = {
					content: response.rawMail,
					name: `${response.parsedContent.subject}.eml`,
					contentType: 'message/rfc822'
				};
				this.gs.downloadData(attachment.content, attachment.name, attachment.contentType);
				this.ns.showInfoMessage(`Downloaded EML`);
			});
			return;
		}

		this.ns.showInfoMessage(`Downloading ${this.selectedEmails.length} EMLs... This may take a moment. Please wait.`);

		const zip = new JSZip();

		let filenames = {};
		eachLimit(this.selectedEmails, 10, (emailObj, callback) => {
			this.rs.getArchiveEmailInfo(encodeURIComponent(emailObj._id)).then((response) => {
				let emlFileName = removeNonAlphaNumeric(response.parsedContent.subject);
				if (!emlFileName) {
					emlFileName = `(No Subject)`;
				}
				if (filenames.hasOwnProperty(emlFileName)) {
					filenames[emlFileName]++;
					emlFileName = `${emlFileName}(${filenames[emlFileName]})`;
				}
				else {
					filenames[emlFileName] = 0;
				}

				zip.file(`${emlFileName}.eml`, response.rawMail);
				callback();
			});
		}, (err) => {
			filenames = null;
			zip.generateAsync({ type: 'blob' }).then((content) => {
				// Save the zip file
				saveAs(content, `${this.selectedCase.name}.zip`);
				this.ns.showInfoMessage(`Download completed`);
			});
		})
	}

    deleteMultipleEmails = () => {
        if (!this.selectedEmails.length) {
            return;
        }
        else if (this.selectedEmails.length === 1) {
            this.selectAction(this.selectedEmails[0], this.dic.CONSTANTS.archiveEmailActions.delete);
			this.selectedEmails = [];
            return;
        }

        this.gs.showPopup({
            title: 'Delete Emails',
            subTitle: `Are you sure you want to delete ${this.selectedEmails.length} emails from archive?`,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                this.selectedEmails.forEach((emailObj) => {
                    this.archiveService.deleteEmailFromArchive(emailObj, () => {
                        this.ns.showInfoMessage('Email deleted successfully');
						_.remove(this.selectedEmails, (email) => email === emailObj);
                    })
                });
            }
        });
    }
}

function searchTextExecute(email, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    if (email._source.rcptTo) {
        for (let i = 0; i < email._source.rcptTo.length; i++) {
            if (email._source.rcptTo[i].indexOf(searchTerm) > -1) {
                return true;
            }
        }
    }
    return ((email._source.subject && email._source.subject.toLowerCase().indexOf(searchTerm) > -1) ||
		(email._source.from && email._source.from.toLowerCase().indexOf(searchTerm) > -1));
}

function removeNonAlphaNumeric(str) {
	return str?.replace(/[^a-z0-9\s_@]/gi, '');
}
