<!--exchange connector/rules popup-->
<popup-c class="no-content-padding bs-enabled" size="large"
	   head="Connect to Exchange Server using a PowerShell integration"
	   subTitle="Log in with your Exchange global admin credentials to create a PowerShell integration with Trustifi"
	   (closeCb)="closePopup();">
	<div class="flex-column overflow-auto f-1-0 pt-2 gap-2" style="padding: 20px;">
		<!--steps progress-->
		<div class="steps-container" style="font-size: 0.8rem;">
			<div class="step-tab"
				 *ngFor="let stepKey of validSteps"
				 [ngClass]="{'active' : step === stepKey}">
				{{stepKey}}
			</div>
		</div>

		<!--Steps content:-->
		<!--STEP: General instructions-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-2 pt-3"
			 style="line-height: 2;"
			 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation">
				<span style="position: relative; padding-left: 15px;" class="diamond-indent">
					This wizard will create an integration between your Exchange server and Trustifi using PowerShell.
				</span>
			<br/>

			<div class="flex-column gap-3 w-100" *ngIf="exchangeServerEnabled">
				<span>Choose how to connect:</span>
				<div class="flex-row justify-content-around gap-4 f-1-0 px-4 py-3 w-100">
					<ng-container *ngFor="let method of _.values(integrationMethods)">
						<ng-container *ngTemplateOutlet="integrationMethodCard; context: {$implicit: method}"></ng-container>
					</ng-container>

					<ng-template #integrationMethodCard let-method>
						<div class="flex-column centralize gap-3 border border-2 border-light rounded p-4 hvr-darken-light cursor-pointer position-relative text-center"
							 style="max-width: 40%; transition: all 0.15s;"
							 (click)="integrationMethod = method.name"
							 [ngClass]="{'border-primary shadow' : integrationMethod === method.name, 'border-light' : integrationMethod !== method.name}">
							<h5 class="fw-bold" style="transition: all 0.15s;" [ngClass]="{'text-primary' : integrationMethod === method.name}">
								{{method.name}}
							</h5>
							<span style="transition: all 0.15s;" [ngClass]="{'text-muted' : integrationMethod !== method.name}">
								{{method.description}}
						</span>
							<i class="fa fa-check-circle text-primary" style="position: absolute; top: 8px; left: 8px; font-size: 1rem;" *ngIf="integrationMethod === method.name"></i>
						</div>
					</ng-template>
				</div>
			</div>

			<br/>
			<div class="flex-row gap-2 align-items-baseline">
				<i class="fa fa-exclamation-triangle opacity-25"></i>
				<span class="fw-bold">Before taking the next steps:</span>
			</div>
			<div class="bg-light p-2">
				<ul style="list-style-type: number;">
					<li>
						<span>Make sure you have global admin credentials in your Exchange server</span>
					</li>
					<li>
						<span>Make sure remote PowerShell access is enabled in your Exchange server</span>
					</li>
				</ul>
			</div>

			<div class="flex-column gap-2 px-2 f-1-0 justify-content-end" *ngIf="!exchangeServerEnabled">
				<hr class="mt-0"/>
				<span class="diamond-indent position-relative ps-3">After clicking <b>Next</b>, a unique token will be generated for authentication in Exchange.</span>
			</div>
		</div>

		<!--STEP: Authentication in Exchange-->
		<div class="flxClmn f-1-0 animated fadeIn gap-3 p-2 pt-3 overflow-auto"
			 style="line-height: 2;"
			 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.authAndExecution">
			<span>Use the token below to login to Exchange Server remote PowerShell</span>
			<div style="align-items: center; margin:7px 15px;" class="flxRow-start hSpace2">
				<div style="font-size: 16px; line-height: 1.2; width: 45%; background: #bac9d642; height: 30px;" class="flex-column centralize position-relative">
                            <span class="hide-scrollbar click-select-all" style="font-weight:500; overflow: auto; width: 100%; text-align: center;"
								  (click)="!generateTokenInProcess && !token && getToken();"
								  [ngClass]="{'loading-dots' : generateTokenInProcess, 'text-decoration-underline blue-link cursor-pointer' : !generateTokenInProcess && !token}"
								  [ngStyle]="{'font-size' : token ? '16px' : '13px', 'font-weight' : token ? '500' : 'normal'}">
                                {{generateTokenInProcess ? 'Generating token' : token ? token : 'Click to generate token'}}
                            </span>
					<div class="loading-spinner" style="position: absolute; left: 5px; top: 8px; height: 15px; transform: translateY(-50%); width: 15px; border-width: 2px; opacity: 0.55; }"
						 *ngIf="generateTokenInProcess"></div>
				</div>
				<a (click)="copyToken(); tokenCopied = true;">
					<i class="far fa-copy copy-FX" title="Copy token"></i>
				</a>
			</div>
			<br/>
			<span>In the next step you need to sign in to Microsoft using your global admin credentials and provide the token above for authentication</span>
		</div>

		<!--STEP: Summary-->
		<div class="flxClmn f-1-0 animated fadeIn gap-3 p-2 pt-3" *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.summary">
			<div>
				<div>
					<span>{{integrationMethod === integrationMethods.accessToken.name ? 'The Exchange Server Remote PowerShell credentials were created' : ''}}</span>
				</div>
			</div>
			<br/>
			<br/>
			<div class="border w-100 p-4 flex-column gap-3" style="max-width: 520px; background: white;"
				 [ngClass]="{'text-danger border-danger' : rulesStatus.status === 'failure', 'text-success border-success' : rulesStatus.status === 'success'}"
				 *ngIf="rulesStatus && rulesStatus.status">
                        <span class="fw-bold">
                            {{rulesStatus.status === 'failure' ? 'Configuration process failed' :
							rulesStatus.status === 'success' ? 'Configuration process finished successfully' : 'Looks like the configuration process hasn\'t finished yet.'}}
                        </span>
				<span *ngIf="rulesStatus.message">{{rulesStatus.message}}</span>
			</div>

			<div class="flxClmn centralize" style="flex: 1 0;" *ngIf="generateTokenInProcess">
				<loader height="100" width="100" style="opacity: 0.8;"></loader>
				<span class="text-primary mt-1 fw-500">{{integrationMethod === integrationMethods.accessToken.name ? 'Retrieving': 'Validating'}} Exchange PowerShell credentials</span>
				<span class="text-primary">Please wait. Process may take a while...</span>
			</div>
		</div>

		<!--error message and buttons-->
		<div class="flex-row justify-content-between align-items-end mb-3 px-4 w-100">
			<span class="f-1-0 pe-3 text-danger" style="min-height: 40px;">
				{{errorMsg || ' '}}
			</span>

			<div class="flex-row gap-3" style="font-size: 13px;">
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					 variant="outlined"
					 (action)="closePopup();">
					Cancel
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step !== dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					 variant="outlined"
					 (action)="backStep();">
					Back
				</btn-c>
				<btn-c class="popup-action-btn"
					   *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					   (action)="nextStep();">
					Next
				</btn-c>
				<btn-c class="popup-action-btn"
					   *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.authAndExecution"
					   (action)="openExchangeWindow();">
					Configure
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.summary"
					 (action)="closePopup();">
					OK
				</btn-c>
			</div>
		</div>
	</div>
</popup-c>
