<div class="flxClmn animated fadeIn" style="flex: 1 0; overflow: auto; z-index: 15;">
	<div class="flxClmn-center" style="align-items: center; flex: 1 0; font-size: 1rem; overflow: auto; background: whitesmoke; border: solid 1px #e3e4e4;">
		<!--sent successfully msg-->
		<div class="flxClmn-center h100 w100 vSpace2" style="max-width: 900px; background: white; align-items: center; font-size: 1rem; padding: 2vw;"
			 *ngIf="isFormFilledSuccessfully">
			<i class="fas fa-check-circle" style=" color: forestgreen; font-size: max(6vw,5rem); opacity: 0.3;"></i>
			<span style="font-family: 'Roboto Condensed', sans-serif; font-size: 1.5rem; color: darkgreen;">
			Form has been sent successfully
		  </span>
			<span *ngIf="!canStartWizard">Our support team will review your request and initiate your integration process (our team will contact you shortly)</span>
			<span *ngIf="canStartWizard">Thank you for submitting this information. Click on the button below to begin the integration process with Trustifi</span>
		</div>

		<!--form-->
		<div class="flxClmn onboarding-questionnaire vSpace5"
			 *ngIf="!isFormFilledSuccessfully">

			<!--Organization-->
			<div class="flxClmn vSpace3">
				<span class="color-primary" style="font-size: 1.45rem; font-family: 'Roboto Condensed', sans-serif;">
					Organization
				</span>
				<!--Organization name-->
				<div class="flxClmn vSpace1">
					<label>Name of organization</label>
					<input type="text" id="organizationName"
						   (change)="questionnaireError = ''"
						   [ngClass]="{'validation-error' : wasSubmitted && !organizationName}"
						   [(ngModel)]="organizationName">
				</div>
				<!--employees no.-->
				<div class="flxClmn vSpace1">
					<label>Number of employees</label>
					<input type="number" min="1" pattern="^(?:[1-9]\d*)$" id="numOfEmployees"
						   (change)="questionnaireError = ''"
						   [ngClass]="{'validation-error' : wasSubmitted && !numOfEmployees}"
						   [(ngModel)]="numOfEmployees">
				</div>
				<!--inbound/outbound/both-->
				<div class="flxClmn vSpace1">
					<label>What will be the scope of this integration?</label>
					<div class="flxClmn vSpace05">
						<div class="flxRow-start hSpace1 md-checkbox" style="margin:10px 0;">
							<input id="integrationScopeOutbound" required="false" type="checkbox" (change)="questionnaireError = ''"
								   [(ngModel)]="integrationScope.outbound"/>
							<label for="integrationScopeOutbound">Outbound protection</label>
						</div>
						<div class="flxRow-start hSpace1 md-checkbox" style="margin:10px 0;">
							<input id="integrationScopeInbound" required="false" type="checkbox" (change)="questionnaireError = ''"
								   [(ngModel)]="integrationScope.inbound"/>
							<label for="integrationScopeInbound">Inbound protection</label>
						</div>
						<div class="flxRow-start hSpace1 md-checkbox" style="margin:10px 0;">
							<input id="integrationScopeArchiving" required="false" type="checkbox" (change)="questionnaireError = ''"
								   [(ngModel)]="integrationScope.archiving"/>
							<label for="integrationScopeArchiving">Archive</label>
						</div>
						<div class="flxRow-start hSpace1 md-checkbox" style="margin:10px 0;">
							<input id="integrationScopeThreatSimulation" required="false" type="checkbox" (change)="questionnaireError = ''"
								   [(ngModel)]="integrationScope.threat_simulation"/>
							<label for="integrationScopeThreatSimulation">Threat Simulation</label>
						</div>
					</div>
				</div>
			</div>

			<!--Current Set-up-->
			<div class="flxClmn vSpace3" style="flex-wrap: wrap;">
        <span class="color-primary" style="font-size: 1.45rem; font-family: 'Roboto Condensed', sans-serif;">
            Current Set-up
        </span>
				<span class="description-label">
            The questions below refer to the manner in which the organization’s mail-flow is configured. <br/>To avoid unnecessary complications during the Email Relay implementation process, these questions should be answered accurately.
        </span>
				<!--environment-->
				<div class="flxClmn vSpace1">
					<label [ngClass]="{'complete-input-warning' : wasSubmitted && !currentEnv}">
						What is your current email service environment?
					</label>
					<div class="flxClmn vSpace05">
						<label class="radio-container" style="display: block; width: auto;">
							Microsoft Exchange - Office365 (cloud)
							<input type="radio" [(ngModel)]="currentEnv" (change)="questionnaireError = ''"
								  [value]="dic.CONSTANTS.onboardingFormValues.currentEnv.exchangeCloud" name="currentEnv">
							<span class="checkmark"></span>
						</label>
						<label class="radio-container" style="display: block; width: auto;">
							Microsoft Exchange - On premises
							<input type="radio" [(ngModel)]="currentEnv" (change)="questionnaireError = ''"
								  [value]="dic.CONSTANTS.onboardingFormValues.currentEnv.onPermises" name="currentEnv">
							<span class="checkmark"></span>
						</label>
						<label class="radio-container" style="display: block; width: auto;">
							Microsoft Exchange - Hybrid
							<input type="radio" [(ngModel)]="currentEnv" (change)="questionnaireError = ''"
								  [value]="dic.CONSTANTS.onboardingFormValues.currentEnv.hybrid" name="currentEnv">
							<span class="checkmark"></span>
						</label>
						<label class="radio-container" style="display: block; width: auto;">
							Google Workspace (cloud)
							<input type="radio" [(ngModel)]="currentEnv" (change)="questionnaireError = ''"
								  [value]="dic.CONSTANTS.onboardingFormValues.currentEnv.gSuite" name="currentEnv">
							<span class="checkmark"></span>
						</label>
						<label class="radio-container" style="display: block; width: auto;">
							Other
							<input type="radio" [(ngModel)]="currentEnv" (change)="questionnaireError = ''"
								  [value]="dic.CONSTANTS.onboardingFormValues.currentEnv.other" name="currentEnv">
							<span class="checkmark"></span>
						</label>
					</div>
					<div class="flxClmn vSpace1 animated fadeIn" style="margin-top: 20px;"
						 *ngIf="currentEnv === dic.CONSTANTS.onboardingFormValues.currentEnv.onPermises">
						<label>Which version of Microsoft Exchange On Permises are you using?</label>
						<input type="text" id="onPermisesVersion"
							   (change)="questionnaireError = ''"
							   [ngClass]="{'validation-error' : wasSubmitted && !onPermisesVersion}"
							   [(ngModel)]="onPermisesVersion">
					</div>
					<div class="flxClmn vSpace1 animated fadeIn" style="margin-top: 20px;"
						 *ngIf="currentEnv === dic.CONSTANTS.onboardingFormValues.currentEnv.other">
						<label>Please specify your environment</label>
						<input type="text" id="otherEnvDesc"
							   (change)="questionnaireError = ''"
							   [ngClass]="{'validation-error' : wasSubmitted && !otherEnvDesc}"
							   [(ngModel)]="otherEnvDesc">
					</div>
				</div>
				<!--DNS-->
				<div class="flxClmn vSpace1">
					<label>Which DNS provider are you using?</label>
					<input type="text" id="DNSprovider"
						   (change)="questionnaireError = ''"
						   [ngClass]="{'validation-error' : wasSubmitted && !DNSprovider}"
						   [(ngModel)]="DNSprovider">
				</div>
				<!--domains no.-->
				<div class="flxClmn vSpace1">
					<label>How many domains are hosted on your email server?</label>
					<input type="number" min="1" pattern="^(?:[1-9]\d*)$" id="domainsNum"
						   (change)="questionnaireError = ''"
						   [ngClass]="{'validation-error' : wasSubmitted && !domainsNum}"
						   [(ngModel)]="domainsNum">
				</div>
				<!--mailboxes no.-->
				<div class="flxClmn vSpace1">
					<label>How many active mailboxes does your organization have?</label>
					<input type="number" min="1" pattern="^(?:[1-9]\d*)$" id="mailboxesNum"
						   (change)="questionnaireError = ''"
						   [ngClass]="{'validation-error' : wasSubmitted && !mailboxesNum}"
						   [(ngModel)]="mailboxesNum">
				</div>
				<span class="description-label">
            Active mailboxes are mailboxes which regularly send and receive email.
        </span>
				<!--is POC-->
				<div class="flxRow-start hSpace1 md-checkbox" style="margin:10px 0;">
					<input id="isPoc" required="false" type="checkbox"
						   [(ngModel)]="isPoc"/>
					<label for="isPoc">This account will initially be used for Proof of Concept</label>
				</div>
				<!--integrated mailboxes no.-->
				<div class="flxClmn vSpace1">
					<label [ngClass]="{'complete-input-warning' : wasSubmitted && !integratedMailboxesMode}">
						How many active mailboxes will be integrated with Trustifi?
					</label>
					<div class="flxClmn vSpace05">
						<label class="radio-container" style="display: block; width: auto;">
							All mailboxes
							<input type="radio" [(ngModel)]="integratedMailboxesMode" [value]="dic.CONSTANTS.onboardingFormValues.integratedMailboxes.all" name="integratedMailboxesMode" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
						<label class="radio-container" style="display: block; width: auto;">
							Specific mailboxes
							<input type="radio" [(ngModel)]="integratedMailboxesMode" [value]="dic.CONSTANTS.onboardingFormValues.integratedMailboxes.specific" name="integratedMailboxesMode" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<div class="flxClmn vSpace1 animated fadeIn" style="margin-top: 20px;"
					 *ngIf="integratedMailboxesMode === dic.CONSTANTS.onboardingFormValues.integratedMailboxes.specific">
					<label>
						Please specify how many mailboxes will be integrated at this point
					</label>
					<input type="number" min="1" pattern="^(?:[1-9]\d*)$" id="integratedMailboxesNum"
						   (change)="questionnaireError = ''"
						   [ngClass]="{'validation-error' : wasSubmitted && integratedMailboxesMode === dic.CONSTANTS.onboardingFormValues.integratedMailboxes.specific && !integratedMailboxesNum}"
						   [(ngModel)]="integratedMailboxesNum">
				</div>
				<div class="flxClmn vSpace1 animated fadeIn" style="margin-top: 20px;"
					 *ngIf="integratedMailboxesNum && mailboxesNum && integratedMailboxesNum < mailboxesNum
                    && integratedMailboxesMode === dic.CONSTANTS.onboardingFormValues.integratedMailboxes.specific">
					<label>
						Please specify how many mailboxes will be eventually integrated
					</label>
					<input type="number" min="1" pattern="^(?:[1-9]\d*)$" id="integratedMailboxesNumUltimately"
						   (change)="questionnaireError = ''"
						   [ngClass]="{'validation-error' : wasSubmitted && integratedMailboxesMode === dic.CONSTANTS.onboardingFormValues.integratedMailboxes.specific && !integratedMailboxesNumUltimately}"
						   [(ngModel)]="integratedMailboxesNumUltimately">
				</div>
				<!--mail-flow connectors (yes/no)-->
				<div class="flxClmn vSpace1">
					<label [ngClass]="{'complete-input-warning' : wasSubmitted && isNullUndefined(flowConnectors)}">
						Do you have any mail-flow connectors enabled?
					</label>
					<div class="flxClmn vSpace05">
						<label class="radio-container" style="display: block; width: auto;">
							Yes
							<input type="radio" [(ngModel)]="flowConnectors" [value]="true" name="flowConnectors" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
						<label class="radio-container" style="display: block; width: auto;">
							No
							<input type="radio" [(ngModel)]="flowConnectors" [value]="false" name="flowConnectors" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<span class="description-label">
                                        Connectors are a set of directions that can be used to restrict or change the way mail flows to and from your organization’s mail server. They can be seen under the “Connectors” tab in the “Mail flow” section of the Exchange Admin Center.
                                    </span>
				<textarea style="height: auto; min-height: 60px; resize: vertical;" class="animated fadeIn"
						  *ngIf="flowConnectors" placeholder="Describe the usage of the connectors here"
						  [ngClass]="{'validation-error' : wasSubmitted && !flowConnectorsDesc}"
						  [(ngModel)]="flowConnectorsDesc"></textarea>
				<!--flow rules (yes/no)-->
				<div class="flxClmn vSpace1">
					<label [ngClass]="{'complete-input-warning' : wasSubmitted && isNullUndefined(flowRules)}">
						Are there any mail flow rules configured?
					</label>
					<div class="flxClmn vSpace05">
						<label class="radio-container" style="display: block; width: auto;">
							Yes
							<input type="radio" [(ngModel)]="flowRules" [value]="true" name="flowRules" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
						<label class="radio-container" style="display: block; width: auto;">
							No
							<input type="radio" [(ngModel)]="flowRules" [value]="false" name="flowRules" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<span class="description-label">
            Mail-flow rules (also known as transport rules) are used to take actions on messages that flow through your mail server. They can be seen under the “Rules” tab in the “Mail flow” section of the Exchange Admin Center.
        </span>
				<textarea style="height: auto; min-height: 60px; resize: vertical;" class="animated fadeIn"
						  *ngIf="flowRules" placeholder="Describe the usage of the rules here"
						  (change)="questionnaireError = ''"
						  [ngClass]="{'validation-error' : wasSubmitted && !flowRulesDesc}"
						  [(ngModel)]="flowRulesDesc"></textarea>
				<!--3rd party integrations  (yes/no)-->
				<div class="flxClmn vSpace1">
					<label [ngClass]="{'complete-input-warning' : wasSubmitted && isNullUndefined(thirdPartyExist)}">
						Do you have any 3rd party integrations enabled?
					</label>
					<div class="flxClmn vSpace05">
						<label class="radio-container" style="display: block; width: auto;">
							Yes
							<input type="radio" [(ngModel)]="thirdPartyExist" [value]="true" name="thirdPartyExist" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
						<label class="radio-container" style="display: block; width: auto;">
							No
							<input type="radio" [(ngModel)]="thirdPartyExist" [value]="false" name="thirdPartyExist" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<textarea style="height: auto; min-height: 60px; resize: vertical;" class="animated fadeIn"
						  *ngIf="thirdPartyExist" placeholder="Describe the integrations here"
						  [ngClass]="{'validation-error' : wasSubmitted && !thirdPartysDesc}"
						  (change)="questionnaireError = ''"
						  [(ngModel)]="thirdPartysDesc"></textarea>
				<!--automated reporting system  (yes/no)-->
				<div class="flxClmn vSpace1">
					<label [ngClass]="{'complete-input-warning' : wasSubmitted && isNullUndefined(autoReportSys)}">
						Do you have an automated reporting system?
					</label>
					<div class="flxClmn vSpace05">
						<label class="radio-container" style="display: block; width: auto;">
							Yes
							<input type="radio" [(ngModel)]="autoReportSys" [value]="true" name="autoReportSys" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
						<label class="radio-container" style="display: block; width: auto;">
							No
							<input type="radio" [(ngModel)]="autoReportSys" [value]="false" name="autoReportSys" (change)="questionnaireError = ''">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<span class="description-label">
            This can refer to any system that logs information and sends automated reports or alerts via your mail server.
        </span>
				<!--other notes (free text)-->
				<div class="flxClmn vSpace1">
					<label>Any other unique configurations in your environment that need to be considered during integration?</label>
					<textarea style="height: auto; min-height: 60px; resize: vertical;" class="animated fadeIn"
							  placeholder="(optional)"
							  (change)="questionnaireError = ''"
							  [(ngModel)]="otherNotes"></textarea>
				</div>
			</div>
			<!--Current Set-up-->
			<div class="flxClmn vSpace3" style="flex-wrap: wrap;">
        <span class="color-primary" style="font-size: 1.45rem; font-family: 'Roboto Condensed', sans-serif;">
            Account Manager
        </span>
				<span class="description-label">
          Your account manager will be your dedicated point of contact in Trustifi and will be part of the onboarding process
        </span>
				<div class="flxRow">
					<app-dropdown-c style="flex: 1 0; font-weight: normal; height: 32px; margin: 0 0 270px; max-width: 95%;"
								  text="{{accountManager ? accountManager.name : 'Select'}}">
						<li class="flxClmn vSpace05" (click)="accountManager = {name: 'Partner'}">
							<span>Partner</span>
							<span style="opacity: 0.7; font-size: 0.75rem;"></span>
						</li>
						<li class="flxClmn vSpace05"
							*ngFor="let manager of dic.CONSTANTS.onboardingFormValues.accountManagers | keyvalue : gs.returnZero"
							(click)="accountManager = manager.value">
							<span>{{manager.value.name}}</span>
							<span style="opacity: 0.7; font-size: 0.75rem;">{{manager.value.email}}</span>
						</li>
					</app-dropdown-c>
					<a class="animated fadeIn" style="height: fit-content; padding: 7px; opacity: 0.7;"
					   *ngIf="!isNullUndefined(accountManager)"
					   (click)="accountManager = null">
						<i class="fa fa-times"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="flxRow-between" style="background: white; align-items: center; min-height: 100px;">
		<span style="color: red; width: 1px; white-space: nowrap; font-size: 0.95rem; align-self: flex-end;">{{questionnaireError || ''}}</span>
		<div class="flxRow hSpace1" *ngIf="!isFormFilledSuccessfully">
			<btn-c class="popup-action-btn"
				 variant="outlined"
				 (action)="cancelCb.emit();">
				Cancel
			</btn-c>
			<btn-c class="popup-action-btn"
			   loading="questionnaireSubmitInProcess"
			   [isDisabled] = "this.questionnaireSubmitInProcess"
			   (action)="wasSubmitted = true; submitQuestionnaire();">
				Send
			</btn-c>
		</div>
		<div class="flxRow hSpace1" *ngIf="isFormFilledSuccessfully">
			<btn-c class="popup-action-btn"
				 *ngIf="canStartWizard"
				 (action)="doneCb.emit(true);">
				Start
			</btn-c>
			<btn-c class="popup-action-btn"
				 *ngIf="!canStartWizard"
				 (action)="doneCb.emit()">
				Ok
			</btn-c>
		</div>
		<!--aligner-->
		<div></div>
		<!---->
	</div>
</div>
