<div class="flxClmn bs-enabled">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container flxRow">
			<span class="page-title">Allow/Block Lists</span>
			<!--Information modal-->
			<more-info-c>
				<div class="flxClmn vSpace1" style="flex: 1 0;">
					<span><b>Block Files Types</b> - configure how emails should be scanned by Inbound Shield according to files configuration</span>
					<span><b>Senders Allowlist</b> - emails arriving from allowlisted addresses will not be scanned by Inbound Shield</span>
					<span><b>Senders Blocklist</b> - emails arriving from blocklisted addresses will be automatically removed from the recipient's inbox</span>
					<span><b>Links Allowlist</b> - links from this list will always be considered as safe for your users</span>
					<span><b>Links Blocklist</b> - links from this list will always be considered as malicious for your users</span>
					<span><b>Attachments Allowlist</b> - attachments from this list will always be considered as safe</span>
					<span><b>Attachments Blocklist</b> - attachments from this list will always be considered as malicious</span>
					<span><b>Note</b> - emails/domains are checked against the allowlist before being checked against the blocklist</span>
				</div>
			</more-info-c>
		</div>
		<div class="page-subtitle-container">
			<span class="page-subtitle">Configure Inbound Shield by setting up customized lists and creating custom protections</span>
		</div>
	</div>
	<div class="page-inner-content-holder gap-3 pt-0 flex-column">
		<!--Tabs-->
		<div class="md-tabs" [ngStyle]="{'--md-tabs-width': tabWidth + 'px'}">
			<div *ngFor="let tab of dic.CONSTANTS.inboundConfigurationPageTabs | keyvalue: gs.returnZero"
				 [ngClass]="{'selected' : gs.getTabName() === tab.value}"
				 [routerLink]="tab.value">
				<input type="radio" name="tabs" id="{{tab.key}}Toggle" [checked]="gs.getTabName() === tab.value">
				<label for="{{tab.key}}Toggle">{{tab.value | camelCaseToTitleCase}}</label>
			</div>

			<div class="slide" [ngStyle]="{'left' : tabWidth * _.values(dic.CONSTANTS.inboundConfigurationPageTabs).indexOf(gs.getTabName()) + 'px' || 0}"></div>
		</div>
		<!---->

		<router-outlet></router-outlet>
	</div>
</div>


