<popup-c head="Users Selection"
		 subTitle="Select users from the list below"
		 (closeCb)="closeUsersPopup()"
		 (confirmCb)="applyUsersFromPopup()"
		 size="medium"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: applyUsersInProcess}}">
	<div class="flxClmn f-1-0" style="overflow: auto;">
		<loader class="d-flex centralize f-1-0 opacity-75" *ngIf="!usersToDisplay"></loader>
		<!--users table-->
		<trustifi-table-c *ngIf="usersToDisplay"
			class="trustifi-table" style="flex: 1 0; z-index: 5;"
			[list]="usersToDisplay"
			[headers]="[
				{text:'Email', sortKey:'email'},
			]"
			[cellsPrototype]="[
				{textKey: 'email'},
			]"
			[options]="{
				itemsNameSingular: 'user',
				defaultSortKey: '-email',
				showRadioButtons: true
			}"
			(searchFunction)="searchUsers($event.searchTerm, $event.activeFilters)"
		>
		</trustifi-table-c>
	</div>
</popup-c>
