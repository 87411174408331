<div class="flxClmn">
	<span>
		Reviewers will receive notifications about quarantined email threats sent by your protected mailboxes and will be able to take immediate actions.<br/>
		Reviewers will have access to the following pages: Quarantined Emails, Rules & Policies, Trends and Insights, Reports, and Audit Log.
	</span>

	<br/>
	<div class="content-container flex-column gap-2" style="z-index: 1; width: 65%;">
		<!--reviewers table-->
		<trustifi-table-c
			class="trustifi-table" style="flex: 0 0 300px; z-index: 5; overflow: auto;"
			[list]="reviewers"
			[headers]="[
				{text:'Reviewer', sortKey:'email'},
			]"
			[cellsPrototype]="[
				{textKey: 'email', edit: {modelKey: 'email', placeholder: 'Reviewer email'}},
			]"
			[bulkActions]="{
				enable: true,
				selectBulkActionCb:	selectMultipleReviewerAction,
				initBulkActionsFunction: showReviewerBulkActions,
				showInRed: {enable: true, terms: ['Remove']}
			}"
			[cellActions] = "{
				enable: true,
				selectItemActionCb:	selectReviewerAction,
				initActionsFunction: showReviewerActions,
				showInRed: {enable: true, terms: ['Remove']}
			}"
			(searchFunction)="searchReviewer($event.searchTerm, $event.activeFilters)"
			(confirmEditFunction)="confirmAddReviewer($event.item, $event.isApproved)"
			[options]="{
				itemsNameSingular: 'reviewer',
				defaultSortKey: '-email',
			}"
		>
			<div tableButtons>
				<div class="btn-with-dropdown">
					<btn-c (action)="startAddReviewer();"
						   endIcon="fa fa-plus"
						   [isDisabled]="addReviewerInProcess">
						Add Reviewer
					</btn-c>
					<app-dropdown-c [isLf]="true">
						<li style="text-transform: capitalize; height: auto;"
							(click)="openUsersSelection()">
							Add from my users
						</li>
					</app-dropdown-c>
				</div>
			</div>
		</trustifi-table-c>
	</div>
</div>

<users-selection-component
	*ngIf="usersSelectionPopup?.show"
	[data]="usersSelectionPopup"
	[selectedUsers]="selectedUsers"
	(addCb)="startAddFromUsersSelection($event.users)"
	(removeCb)="startRemoveFromUsersSelection($event.users)">
</users-selection-component>
