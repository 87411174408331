
import {ClioService} from '../../../../services/clioService';
import {DropboxService} from '../../../../services/dropboxService';
import {DICTIONARY} from '../../../../dictionary';
import {RouteService} from '../../../../services/routeService';
import {NotificationService} from '../../../../services/notificationService';
import {GeneralService} from '../../../../services/generalService';
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'account-integrations-component',
	templateUrl: './account-integrations.component.html',
})
export class AccountIntegrationsComponent implements OnInit {
    dic = DICTIONARY;
    showDropboxIntegration = this.gs.showDropboxIntegration;
    integrations: any;
    userInfo: any;

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private clioService:ClioService,
				private dropboxService:DropboxService) {

    }

	ngOnInit() {
        this.rs.getAccountInfo().then((accountInfo) => {
            this.integrations = accountInfo.integrations;
        });

        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
        });
    }

    clioLogin = () => {
        this.clioService.getloginurl((err, url) => {
            if (err) {
                this.ns.showMessage(err);
            }
            else {
                const authwnd = window.open(url, '_blank', 'location=no,width=600,height=600,scrollbars=yes,top=100,left=100,resizable=no');

                try {
                    authwnd.focus();
                }
                catch (e) {
                    this.ns.showWarnMessage(this.dic.ERRORS.popupBlock);
                }
            }
        });
    };

    dropboxLogin = () => {
        this.dropboxService.getloginurl((err, url) => {
            if (err) {
                this.ns.showMessage(err);
            }
            else {
                const authwnd = window.open(url, '_blank', 'location=no,width=600,height=600,scrollbars=yes,top=100,left=100,resizable=no');

                try {
                    authwnd.focus();
                }
                catch (e) {
                    this.ns.showWarnMessage(this.dic.ERRORS.popupBlock);
                }
            }
        });
    };
}
