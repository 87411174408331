import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {AuthService} from "../../../services/authService";
import {DICTIONARY} from "../../../dictionary";
import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {myEmailsService} from "../../../services/myEmailsService";
import {ENV_CONSTS} from "../../../constants";
import {HttpClient, HttpEventType, HttpResponse} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
	selector: 'sent-email-details-component',
	templateUrl: './sent-email-details.component.html',
})
export class SentEmailDetailsComponent implements OnInit {

	@Output() deleteMessages = new EventEmitter<any>;

    constructor(
				private rs:RouteService,
				private authService:AuthService,
				private ns:NotificationService,
				private router:Router,
				public gs:GeneralService,
				public myEmailsService:myEmailsService,
				private http:HttpClient) {
    }

    summernoteOptions = this.gs.summernoteOptions;
    domain = this.gs.domain;
	hours = ['00','01','02','03','04','05','06','07','08','09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
	minutes = ['00', '05','10','15','20','25','30','35','40','45','50','55'];

    dic = DICTIONARY;
	_ = _;
    showSensitiveInfoPopup;
    attachmentsUploadingInProcessCount = 0;
    attachmentFiles;
	validDrag;
	invalidDrag;
    uploading;
    userInfo;
    allowPolicyRecall;
	now = new Date();
	minScheduleTime = new Date(this.now.getTime() - (this.now.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
    showScheduler;
	editedScheduledTimeModel
	previewAttachment;

	showFullToStr;
	showFullCcStr;
	showFullBccStr;

    ngOnInit() {
        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
            this.allowPolicyRecall = this.userInfo.plan.policy.allow_recall_message && this.userInfo.plan.policy.allow_recall_message.value;
        });
    };

    doEmailAction = (action) => {
        switch (action) {
            case 'forward':
                this.forwardMessage(this.myEmailsService.currentMessage);
                break;

            case 'print':
                this.printEmail(this.myEmailsService.currentMessage);
                break;

            case 'sensitivity':
                this.openSensitiveInfoPopup();
                break;

            case 'deleteMassRecipients':
                this.openDeleteRecipientsPopup(this.myEmailsService.currentMessage);
                break;

			case 'recallMessage':
				this.openRecallMessagePopup(this.myEmailsService.currentMessage);
				break;

            default:
                this.myEmailsService.doEmailAction(action);
        }
    }

	openRecallMessagePopup = (currentMessage) => {
		this.gs.showPopup({
			title: 'Recall Email',
			subTitle: 'The email will be removed from the mailboxes of all internal recipients',
			body: ['Note: The email can only be recalled from recipients that are part of your organization'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				this.rs.recallEmail(currentMessage._id, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then( (response) => {
					this.ns.showInfoMessage(this.dic.MESSAGES.emailRecall);
				});
			}
		});
	}

    openDeleteRecipientsPopup = (currentMessage) => {
        const body = [
            'The tracking services for this campaign will stop',
            'If there are tracked links in the email, they will be redirected to a "404: page not found"',
            'Your last summary-report will be kept but you will not be able to generate a new one',
            'Your last full-report will be kept but you will not be able to generate a new one',
        ];
        this.gs.showPopup({
            title: 'Delete Campaign Records',
            subTitle: 'Please note - the records for the selected campaign will be deleted.',
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                this.rs.deleteMessageRecipients(currentMessage._id).then( (response) => {
                    currentMessage.recipients_deleted = true;
                    this.ns.showInfoMessage(this.dic.MESSAGES.emailRecipientsDelete);
                });
            }
        });
    };

    forwardMessage = (currentMessage) => {
        currentMessage.to = [];

        this.router.navigate([this.dic.CONSTANTS.appStates.personalPages, this.dic.CONSTANTS.personalPages.composeMessage], {state:
            {
                data: {
                    message: currentMessage,
                    action: 'forward'
                }
            }});
    };

    navToList = (list) => {
		this.router.navigate([this.dic.CONSTANTS.appStates.personalPages, this.dic.CONSTANTS.personalPages.massList], {state: {list: list._id}});
    };

    printEmail = (currentMessage) => {
        if (!currentMessage || !currentMessage.sent || !currentMessage.sent.html) {
            this.ns.showWarnMessage(this.dic.ERRORS.emailContentEmpty);
            return;
        }

        let getBasicHeadersHtml = () => {
            let headers = `<div><b>${currentMessage.from && (currentMessage.from.name || currentMessage.from.email)}</b></div>
                <hr style="border-width: 1px; border-color: black">
                <br><div>
                <span style="padding-right: 54px;">From: </span>${currentMessage.from.email_display || currentMessage.from.email}</div><div>
                <span style="padding-right: 60px;">Sent: </span>${currentMessage.created && (new Date(currentMessage.created)).toUTCString()}</div><div>
                ${currentMessage.recipientsIDs?.length ? `<span style="padding-right: 72px;">To:   </span>${currentMessage.recipientsIDs.map(itm => itm.name).reduce((itm1, itm2) => itm1 + ', ' + itm2)}</div><div>` : ''}
                <span style="padding-right: 42px;">Subject: </span>${currentMessage.sent.title}</div>`;

            if (currentMessage.sent.attachments?.length) {
                headers += `<div><span style="padding-right: 10px;">Attachments: </span>${_.map(currentMessage.sent.attachments, 'name').join(', ')}</div>`;
            }
            headers += '<br><br>';
            return headers;
        };

        let printTab = window.open();
        printTab.document.write(getBasicHeadersHtml() + currentMessage.sent.html);
        printTab.document.close();
        printTab.print();
        setTimeout(()=>{
            printTab.close();
        }, 1000);
    };

    openSensitiveInfoPopup = () => {
        this.showSensitiveInfoPopup = true;
    };

    openSchedSendNowPopup = (titleItem) => {

        let body = [`Your ${titleItem} will be sent`, `Your ${titleItem} will be moved to your sent folder.`];
        if (this.myEmailsService.currentMessage.methods.secure_mass) {
            body.push('Any scheduled reports will be sent according to the original dates')
        }

        this.gs.showPopup({
            title: `Send Scheduled Email Now`,
            subTitle: `Please note - your scheduled ${titleItem} will be sent now.`,
            body: body,
            type: this.dic.CONSTANTS.popupInfo,
            cancelBtnText: 'Cancel',
            doneBtnText: `Send Email`,
            doneCb: () => {
                this.rs.schedSendNow(this.myEmailsService.currentMessage._id, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then((res) => {
                    this.myEmailsService.currentMessage.scheduled_time = null;
                    this.myEmailsService.currentMessage.start_sending_time = new Date();
                });
            }
        });
    };

	startEditScheduleTime = () => {
		const date = new Date(this.myEmailsService.currentMessage.scheduled_time);
		this.editedScheduledTimeModel = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
		this.showScheduler = true;
	}

    openUpdateScheduledTimeConfirmPopup = () => {
        if (!this.editedScheduledTimeModel) {
            this.ns.showWarnMessage(this.dic.ERRORS.invalidScheduledTime);
            return;
        }

		let body = [];
        if (this.myEmailsService.currentMessage.methods.secure_mass) {
            body.push('Any scheduled reports will be sent according to the original dates')
        }

        this.gs.showPopup({
            title: 'Update Scheduled Time',
            subTitle: 'Please note - the scheduled time for this email will be updated.',
            body: body,
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Update',
            doneCb: () => {
				const actionInfo = {
					email_id: this.myEmailsService.currentMessage._id,
					scheduled_time: new Date(this.editedScheduledTimeModel).toISOString()
				};
                this.rs.updateScheduledTime(actionInfo, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then( (response) => {
                    this.myEmailsService.currentMessage.scheduled_time = actionInfo.scheduled_time;
					this.ns.showInfoMessage(this.dic.MESSAGES.scheduledEmailUpdated);
					this.showScheduler = false;
                });
            }
        });
    };

	uploadAttachment = () => {
		this.attachmentsUploadingInProcessCount += this.attachmentFiles.length;
		let isValid;
		for (let i = 0; i < this.attachmentFiles.length; i++) {
			isValid = this.gs.checkUploadFileSize(this.attachmentFiles[i].size, this.attachmentFiles[i].name);
			if (isValid) {
				this.uploadAttachmentExecute(this.attachmentFiles[i]);
			}
			else {
				this.attachmentsUploadingInProcessCount--;
			}
		}

		// empty the model after the iteration so it won't stack
		this.attachmentFiles = [];
	};

	uploadAttachmentExecute = (file) => {
		if (!file) {
			return;
		}

		if (this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)) {
			this.ns.showWarnMessage('Cannot upload attachments to email which is not yours');
			return;
		}

		if (_.find(this.myEmailsService.currentMessage.sent.attachments, {name: file.name})) {
			this.attachmentsUploadingInProcessCount--;
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.attachmentExists, file.name));
			return;
		}

		const currentAttachment = {
			name: file.name,
			size: file.size,
			finished: false,
			progressPercentage: 0,
			upload: {} as any,
			abortUploadFlag: false
		};

		this.uploading = true;
		this.myEmailsService.currentMessage.sent.attachments.push(currentAttachment);

		const formData: FormData = new FormData();
		formData.append('file', file, file.name);

		currentAttachment.upload = this.http.post(ENV_CONSTS.attachmentUrl + '/attachment', formData, {
			headers: this.authService.getHeaders(),
			reportProgress: true,
			observe: 'events'
		});

		currentAttachment.upload.subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {
				const fileInAttachmentsListObj = _.find(this.myEmailsService.currentMessage.sent.attachments, {name: currentAttachment.name});
				if (fileInAttachmentsListObj) {
					fileInAttachmentsListObj.progressPercentage = Math.trunc((event.loaded / event.total!) * 100);
				}

			} else if (event instanceof HttpResponse) {
				// file upload completed
				if (event.body) {
					const fileInAttachmentsListObj = _.find(this.myEmailsService.currentMessage.sent.attachments, {name: currentAttachment.name});
					fileInAttachmentsListObj.finished = true;
					fileInAttachmentsListObj._id = event.body[0];
					this.addAttachment(fileInAttachmentsListObj);

					this.attachmentsUploadingInProcessCount--;
					if (this.attachmentsUploadingInProcessCount === 0) {
						this.uploading = false;
					}
				}
			}
		}, (error) => {
			const isFileToRemoveInUploadingList = _.find(this.myEmailsService.currentMessage.sent.attachments, {name: currentAttachment.name});

			_.remove(this.myEmailsService.currentMessage.sent.attachments, {name: currentAttachment.name});

			if (isFileToRemoveInUploadingList) {
				this.attachmentsUploadingInProcessCount--;
				if (this.attachmentsUploadingInProcessCount === 0) {
					this.uploading = false;
				}
			}

			console.error(error.message);
			this.ns.showErrorMessage('File upload failed');
		});

	};

    addAttachment = (attachment) => {
        const data = {
            type: this.dic.CONSTANTS.updateEmailTypes.attachment,
            action: 'add',
            attachmentId: attachment._id
        };
        this.rs.updateEmailContent(this.myEmailsService.currentMessage._id, data, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then(() => {
            delete attachment['progressPercentage'];
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Attachment ' + attachment.name));
        }, (err) => {
            _.remove<any>(this.myEmailsService.currentMessage.sent.attachments, a => a._id === attachment._id);
        });
    };

	setPreviewAttachment = (file) => {
		this.previewAttachment = file;
		this.previewAttachment.url = `/email/attachment/${this.myEmailsService.currentMessage._id}/${file._id}`;
		const params = this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage);
		if (params?.userId) {
			this.previewAttachment.url += `?userId=${params.userId}`;
		}
	}

	downloadAttachment = (file) => {
		if (this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)) {
			this.ns.showWarnMessage('Cannot download attachments from email which is not yours');
			return;
		}

		if (file.finished === false || file.inProcess) { // in the middle of an upload, abort downloading || in the middle of a download
			return;
		}

		if (this.myEmailsService.currentMessage.sent.attachments.length) {
			file.type =  this.myEmailsService.currentMessage.sent.attachments[0].type;
		}
		else {
			file.type = 'application/csv';
		}

		this.gs.downloadFile(`/email/attachment/${this.myEmailsService.currentMessage._id}/${file._id}`, file , false, (err) => {});
    };

	downloadAllAttachments = () => {
		if (this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)) {
			this.ns.showWarnMessage('Cannot download attachments from email which is not yours');
			return;
		}

		const totalSize = _.sumBy(this.myEmailsService.currentMessage.sent.attachments , 'size');
		const fileObj = {name: 'secured_attachments.zip', type: 'application/zip', size: totalSize};

		this.gs.downloadFile('/attachment/email/' + this.myEmailsService.currentMessage._id, fileObj, false, (err) => {});
	};

    deleteAttachment = (attachment) => {
		if (this.myEmailsService.currentMessage.source === 'journal') {
			this.ns.showErrorMessage(`Cannot remove attachments from journal emails`);
			return;
		}
		if (attachment.finished === false) { // "delete attachment" in the middle of an upload - do not ask if sure
			attachment.abortUploadFlag = true;
			try {
				attachment.upload.unsubscribe();
			}
			catch {}

			_.remove(this.myEmailsService.currentMessage.sent.attachments, {name: attachment.name});
			return;
		}

        this.gs.showPopup({
            title: 'Recall attachment from email',
            subTitle: `You are about to recall attachment ${attachment.name}`,
            body: ['Recipients will not be able to open or download this attachment'],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                const data = {
                    type: this.dic.CONSTANTS.updateEmailTypes.attachment,
                    action: 'delete',
                    attachmentId: attachment._id
                };
                this.rs.updateEmailContent(this.myEmailsService.currentMessage._id, data, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then(() => {
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Attachment', attachment.name));
					_.remove(this.myEmailsService.currentMessage.sent.attachments, {_id : attachment._id})
                });
            }
        });
    };

    cancelAttachmentExpiration = () => {
        let attachmentSuffix = this.myEmailsService.currentMessage.sent.attachments.length > 1 ? 's' : '';
        this.gs.showPopup({
            title: 'Cancel expiration',
            subTitle: `Please note, you are about to cancel expiration of attachment${attachmentSuffix}`,
            body: [`Attachment${attachmentSuffix} will not be deleted after the expiration date`],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Confirm',
            doneCb: () => {
                this.myEmailsService.currentMessage.advanced.secure.delete_attachment_enable = false;
                this.rs.updateAdvancedMessage(this.myEmailsService.currentMessage._id, this.myEmailsService.currentMessage.advanced, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then( () => {
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.attachmentsExpirationModified, 'attachment' + attachmentSuffix));
                    this.myEmailsService.currentMessage.deleteAttachmentDays = null;
                });
            }
        });
    };

    modifyAttachmentExpiration = () => {
        if (!this.myEmailsService.currentMessage.deleteAttachmentDays) {
            this.ns.showErrorMessage(this.dic.ERRORS.expiredInputInvalid);
            return;
        }
        let attachmentSuffix = this.myEmailsService.currentMessage.sent.attachments.length > 1 ? 's' : '';
        this.myEmailsService.currentMessage.advanced.secure.delete_attachment_days = this.myEmailsService.currentMessage.deleteAttachmentDays;
        this.myEmailsService.currentMessage.advanced.secure.delete_attachment_enable = true;

        this.rs.updateAdvancedMessage(this.myEmailsService.currentMessage._id, this.myEmailsService.currentMessage.advanced, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then( () => {
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.attachmentsExpirationModified, 'attachment' + attachmentSuffix));
            this.myEmailsService.currentMessage.modifyExpirationMode = false;
            this.myEmailsService.currentMessage.advanced.secure.delete_attachment_enable = true;
        });
    };
}
