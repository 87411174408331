import * as util from 'util';
import {Buffer} from 'safe-buffer';
import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {eachLimit} from 'async';


@Component({
	selector: 'user-quarantined-component',
	templateUrl: './user-quarantined.component.html',
})
export class UserQuarantinedComponent implements OnInit {
    constructor(public gs:GeneralService,
				private ns:NotificationService,
				private rs:RouteService,
				private clipboard: ClipboardService) {
    }

    dic = DICTIONARY;
	_=_;

    batchesCounter = 0;
    groupEmails = [];
    openPreviewLinkPopup;
    corpname = this.gs.corpname;
	showHtml;
    allowReviewerShowContent;
	previewAttachment;

    searchPeriod = [
        this.dic.CONSTANTS.trendsPeriod.lastDay,
        this.dic.CONSTANTS.trendsPeriod.last3Days,
        this.dic.CONSTANTS.trendsPeriod.lastWeek,
        this.dic.CONSTANTS.trendsPeriod.lastMonth,
        this.dic.CONSTANTS.trendsPeriod.range
    ];

    multipleEmailsActions = [
        this.dic.CONSTANTS.quarantinedActions.release.display,
        this.dic.CONSTANTS.quarantinedActions.remove.display,
        this.dic.CONSTANTS.quarantinedActions.setCategory.display,
        this.dic.CONSTANTS.quarantinedActions.handled.display,
		this.dic.CONSTANTS.quarantinedActions.toggleKeepForever.display,
    ];

    actionForHeaders = {
        senderEmail: true,
        senderEmailAppearsAs: true,
        senderDomain: false,
        replyToEmail: false,
        replyToDomain: false,
        messageID: false,
        returnPath: false,
        returnPathEmail: false
    };

    statusPriority = {
        'Malicious': 1,
        'Suspicious': 2,
        'Spam': 3,
        'Graymail': 4,
        'Unsecured': 5,
        'External': 6,
        'Pending': 7,
        'Safe': 8
    };
	hoursArr = [ "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

	queryTypes = {
        normal: 'normal',
        messageIdOnly: 'messageIdOnly'
    }

	mailboxInfo;
    userInfo;
    companyName;
    tpMode;
    isUrlTrackingEnabled;
    allowRequestRelease;
    queryType;
    resultsPeriod;
    range;
    quarantinedQuery;
    quarantinedQueryDisplay;
    queryValidationErrors;
    showQueryModal;
    isQueryError;
    getEmailsInProcess;
    emails;
    filterData;
    isLoaded;
    activeTagAsFilter;
    curTPResults;
    setCategoryPopup;
    senderAuthMethod;
    authSenderInProcess;
    loadQuarantineData;
    curEmail;
    senderAuthPhone;
    showAuthSenderPopup;
    popupError;
    curAction;
    blockSenderAuthRequest;
    urlTrackingPopup;
    setCategoryInProcess;
    emailInfoTab;
    emailInfoPopup;
    reportToTrustifi;
    deleteRecord;
    addWarningLabel;
    addSubjectText;
	threat_body_conf_precentages;
	spam_body_conf_precentages;
	graymail_body_conf_precentages;
	defaultCountry;
	disableConfirmAuth;
	blocklistSenderPopup;
	allowlistSenderPopup;
	notifyOptionsPopup;
	isRecipientChangeNotifyTimeAllowed;
	tpMetrics;
	reviewerSearchTerm;
	headerSearchTerm;
	enableDefangeUrl;

	ngOnInit() {
        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
            this.companyName = userInfo.plan.company_name || DICTIONARY.CONSTANTS.trustifiDefault.name;
        });

        this.prepareFirstQuery();

        this.initFilters();

        this.rs.getUserQuarantinedPlanInfo().then((response) => {
            this.tpMode = response.tpMode;
            this.allowReviewerShowContent = true; // user can always see his data
            this.allowRequestRelease = response.allowRequestRelease;
            this.isUrlTrackingEnabled = response.urlTracking;
			this.isRecipientChangeNotifyTimeAllowed = response.isRecipientChangeNotifyTimeAllowed;
			this.enableDefangeUrl = response.client_defang_malicious_url;

			this.tpMetrics = [];
			Object.keys(response.tpMetrics).forEach(key => {
				if (response.tpMetrics[key]) {
					this.tpMetrics.push({sub_status: response.tpMetrics[key].sub_status, description: response.tpMetrics[key].description});
				}
			});
        });

		this.rs.getMailboxInfo().then((response) => {
			this.mailboxInfo = response;
		}, (err) => {

		});
    };

	prepareFirstQuery = () => {
		// default query
		this.initQuarantinedQuery();

		if (this.setQueryFromOutside()) {
			this.applyQuery();
			return;
		}

		// restore last saved query (only if not redirected to this page with predefined query)
		if (localStorage.quarantinedQuery) {
			this.restoreLastQuarantinedQuery();
		}

		this.calculateQueryDisplay();
	}


	restoreLastQuarantinedQuery = () => {
		// check previous query from localStorage and retrieve it
		let previousQuery;

		try { // "try" in case the parameters won't parse correctly
			previousQuery = JSON.parse(localStorage.quarantinedQuery);
		}
		catch {
			return;
		}

		// if last query saved to localstorage then apply the saved query
		this.queryType = previousQuery.queryType;

		if (this.queryType === this.queryTypes.messageIdOnly) {
			this.quarantinedQuery.message_id = previousQuery.message_id;
		}
		else {
			this.resultsPeriod = previousQuery.period;
			this.range = previousQuery.range;

			this.quarantinedQuery.mailbox_email = previousQuery.mailbox_email;
			this.quarantinedQuery.sender = previousQuery.sender;
			this.quarantinedQuery.subject = previousQuery.subject;

			previousQuery.category && previousQuery.category.split(',').forEach(enabledCategory => {
				this.quarantinedQuery.category[enabledCategory] = true;
			});
			previousQuery.sub_category && previousQuery.sub_category.split(',').forEach(enabledSubCategory => {
				this.quarantinedQuery.sub_category[enabledSubCategory] = true;
			});
			previousQuery.status && previousQuery.status.split(',').forEach(enabledStatus => {
				switch (enabledStatus) {
					case this.dic.CONSTANTS.quarantinedActions.remove.value:
						enabledStatus = 'removed';
						break;

					case this.dic.CONSTANTS.quarantinedActions.release.value:
						enabledStatus = 'released';
						break;
				}
				this.quarantinedQuery.status[`${enabledStatus[0].toUpperCase()}${enabledStatus.slice(1)}`] = true;
			});
		}

	}

    initQuarantinedQuery = () => {
        this.queryType = this.queryTypes.normal;
        this.resultsPeriod = this.dic.CONSTANTS.trendsPeriod.lastDay;
        this.range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()}; // (past one day)

        this.quarantinedQuery = {
            mailbox_email: '',
            sender: '',
            subject: '',
            message_id: '',
			category: {
				Malicious: true,
				Suspicious: true,
				Spam: true,
				Graymail: true,
				External: false,
				Safe: false
			},
			sub_category: {
				Allowlist: false,
				Blocklist: false,
				'Business Email Compromise': false,
				Graymail: false,
				Impersonation: false,
				Malware: false,
				Phishing: false,
				Spam: false,
				Spoofing: false,
				'User Preferences': false,
			},
			status: {
				Quarantined: false,
				Released: false,
				Removed: false,
				'Requested release': false
			}
        }

        this.quarantinedQueryDisplay = [
            {
                title: this.gs.toCapitalize(this.resultsPeriod.display),
                tooltip: this.gs.toCapitalize(this.resultsPeriod.display)
            },
            {
                title: 'Category',
                // => 1. get the truthy categories as array of objects. 2. make it array of keys of those objects (strings array). 3. join it with ', ' and capitalize all words.
                tooltip: this.gs.toCapitalize((_.keys(_.pickBy(this.quarantinedQuery.category))).join(', '))
            },
        ]
    }

    validateQuery = () => {
        this.queryValidationErrors = [];

        // validation for 'message ID only' query
        if (this.queryType === this.queryTypes.messageIdOnly) {
            if (!this.quarantinedQuery.message_id) {
                //this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Message ID'));
                this.queryValidationErrors.push('message_id');
                return false;
            }
            else {
                return true;
            }
        }
        //

        let isPassed = true;

        // period (range) check
        if (this.gs.equals(this.resultsPeriod, this.dic.CONSTANTS.trendsPeriod.range)) {
            const range:any = {
                start: this.range.start,
                end: this.range.end
            };
            if (range.start > Date.now() || range.start > range.end) {
                this.ns.showWarnMessage(this.dic.ERRORS.adminQuarantinedDate);
                isPassed = false;
            }
        }
        // category check
        const categoriesForSearch = this.getCategoriesForQuery();
        if (!categoriesForSearch) {
            this.queryValidationErrors.push('category');
            isPassed = false;
        }

        return isPassed;
    }

    // 1. send query to validation. 2. create query string for query box
    applyQuery = () => {
        if (!this.validateQuery()) {
            return;
        }

        this.quarantinedQuery.sender = this.quarantinedQuery.sender && this.quarantinedQuery.sender.toLowerCase();

        this.calculateQueryDisplay();

        this.showQueryModal = false;
        this.isQueryError = false;

        this.getQuarantinedEmails();
    }

	setQueryFromOutside = () => {
		// predefined message-id-based query
		const urlQuery = new URLSearchParams(location.search.substring(1) || '');
		let message_id = urlQuery.get['message_id'] || window.history.state.quarantinedMessageId;
		if (message_id) {
			this.queryType = this.queryTypes.messageIdOnly;
			this.quarantinedQuery.message_id = message_id;
			return true;
		}

		// predefined query from WA
		if (window.history.state.data) {

			this.resultsPeriod = window.history.state.data.period || this.dic.CONSTANTS.trendsPeriod.lastWeek;
			this.range = window.history.state.data.range || this.range;
			this.quarantinedQuery.mailbox_email = window.history.state.data.mailbox || this.quarantinedQuery.mailbox_email;
			this.quarantinedQuery.sender = window.history.state.data.sender || '';
			this.quarantinedQuery.subject = window.history.state.data.subject || '';

			(window.history.state.data.category || []).forEach((enabledCategory) => {
				this.quarantinedQuery.category[enabledCategory] = true;
			});
			(window.history.state.data.sub_category || []).forEach((enabledSubCategory) => {
				this.quarantinedQuery.sub_category[enabledSubCategory] = true;
			});
			(window.history.state.data.status || []).forEach((enabledStatus) => {
				this.quarantinedQuery.status[enabledStatus] = true;
			});

			return true;
		}
	}

	// determine how query properties look in the UI, in the query box
	calculateQueryDisplay = () => {
		this.quarantinedQueryDisplay = [
			{
				title: this.resultsPeriod.display,
				tooltip: this.resultsPeriod === this.dic.CONSTANTS.trendsPeriod.range ? 'From ' + String(this.range.start) + ' until ' + String(this.range.end) : this.resultsPeriod.display
			},
			..._.map(this.quarantinedQuery, (value, key) => {
				const formattedKey = key.replace('_', ' ').replace('id', 'ID');

				let truthyValuesList;
				if (['category', 'status', 'sub_category'].includes(key)) {
					truthyValuesList = _.keys(_.pickBy(value)).join(', ');
				}

				return {
					title: formattedKey,
					tooltip: _.isUndefined(truthyValuesList) ? value : truthyValuesList
				}
			})
		];


		// messageId or normal query
		if (this.queryType === this.queryTypes.messageIdOnly) {
			this.quarantinedQueryDisplay = _.filter(this.quarantinedQueryDisplay, {title: 'message ID'});
		}
		else {
			this.quarantinedQueryDisplay = _.reject(this.quarantinedQueryDisplay, {title: 'message ID'});
		}

		// clean empty parameters
		this.quarantinedQueryDisplay = _.filter(this.quarantinedQueryDisplay, parameter => !!parameter && !_.isEmpty(parameter.tooltip));
	}

    getQueryForQuarantine = () => {
        if (this.queryType === this.queryTypes.messageIdOnly) {
            return {
                message_id: this.quarantinedQuery.message_id || null
            }
        }
        else {
            return {
                size: this.dic.CONSTANTS.quarantinedEmailsFirstBatchSize,
                period: this.resultsPeriod.value,
                range: this.range,
                category: this.getCategoriesForQuery(),
				status: this.getStatusForQuery(),
				sub_category: this.getSubCategoryForQuery(),
                mailbox_email: this.quarantinedQuery.mailbox_email || '',
                sender: this.quarantinedQuery.sender || '',
                subject: this.quarantinedQuery.subject || '',
            };
        }
    }


    getQuarantinedEmails = () => {
        if (this.getEmailsInProcess) {
            return;
        }

        this.groupEmails = [];

        const params = this.getQueryForQuarantine();

        const paramsToLocalStorage = _.clone(params);
        paramsToLocalStorage['queryType'] = this.queryType;
        paramsToLocalStorage['period'] = this.resultsPeriod; // (the already existed 'period' property in 'params' is only the value, not the desired period object)
        localStorage['quarantinedQuery'] = JSON.stringify(paramsToLocalStorage); // Object

        this.getEmailsInProcess = true;

        this.rs.getUserQuarantinedEmails(params).then((quarantineInfo) => {
            this.batchesCounter++;
            this.emails = quarantineInfo.emails || [];

            this.prepareEmails(quarantineInfo.emails);

            this.getNextEmailsBatch(quarantineInfo, () => {

                this.getEmailsInProcess = false;
                this.isLoaded = true;
            });
        }, (err) => {
            this.getEmailsInProcess = false;
        });
    };

    getNextEmailsBatch = (quarantineInfo, cb) => {
        if (!quarantineInfo.lastCreated) {
            return cb();
        }

        this.batchesCounter++;

        const params:any = this.getQueryForQuarantine();
        params.size = this.dic.CONSTANTS.quarantinedEmailsBatchSize;
        params.lastCreated = quarantineInfo.lastCreated;

        this.rs.getUserQuarantinedEmails(params).then((quarantineInfo) => {
            if (quarantineInfo.emails && quarantineInfo.emails.length) {
                this.emails = this.emails.concat(quarantineInfo.emails);
                this.prepareEmails(quarantineInfo.emails);
            }

            this.getNextEmailsBatch(quarantineInfo, cb);
        });
    }

    prepareEmails = (emails) => {
        if (!emails || !emails.length) return;
        _.each(emails, email => {
            this.calculateTags(email);
            this.setEmailCategory(email);
        });
        this.prepareEmailGroups(emails);
    }

    prepareEmailGroups = (emails) => {
        emails = emails.map(itm => {if (!itm.message_id) itm.message_id = itm.subject; return itm;});
        const groupEmailsMessageId = _.groupBy(emails, 'message_id');
        let count = this.groupEmails ? this.groupEmails.length : 0;
        let currentGroup;

        let groupEmailsMessageIdKeys = Object.keys(groupEmailsMessageId);
        for (let i = 0; i < groupEmailsMessageIdKeys.length; i++) {
            let group = groupEmailsMessageId[groupEmailsMessageIdKeys[i]];

            let groupVal
            let closeGroup = (groupVal) => {
                if (groupVal.emails && groupVal.emails.length) {
                    // general data for showing the group as message in the UI
                    groupVal.created = groupVal.emails[0].created;
                    groupVal.subject = groupVal.emails[0].subject;
                    groupVal.source = groupVal.emails[0].source;
                    groupVal.sender = groupVal.emails[0].sender;
                    groupVal.attachments = groupVal.emails[0].attachments;
                    groupVal.links = groupVal.emails[0].links;
                    groupVal.message_id = groupVal.emails[0].message_id;
                    groupVal.malicious = groupVal.emails[0].malicious;

                    groupVal.reviewed_completed = true;

					this.calculateTags(groupVal);
                    calcParentEmailCategory(groupVal);

                    _.each(groupVal.emails, emailObj => {
                        emailObj.parent_id = groupVal._id;
                        groupVal.mailbox_email.push(emailObj.mailbox_email);
                        if (emailObj.auth_sender && emailObj.auth_sender.status) {
                            groupVal.auth_sender = emailObj.auth_sender;
                        }
                        groupVal.reviewed_completed = groupVal.reviewed_completed && emailObj.reviewed_completed;
                    });

                    this.groupEmails.push(groupVal);
                }
            }

            let isTheSameGroup = (currentGroup, group) => {
                return currentGroup[0].sender === group[0].sender && currentGroup[0].subject === group[0].subject
                    && diffMinutes(currentGroup[0].created, group[0].created) < 3;
            }

            // check if related to current group
            if (currentGroup && isTheSameGroup(currentGroup, group)) {
                currentGroup = currentGroup.concat(group);
                // last iteration - close the group
                groupVal = {mailbox_email: [], emails: currentGroup, isOpen: false, parent: true, _id: ++count};
                closeGroup(groupVal);
            }
            else {
                // not relates - push it and start new group
                currentGroup = group;
                groupVal = {mailbox_email: [], emails: currentGroup, isOpen: false, parent: true, _id: ++count};
                closeGroup(groupVal);
            }
        }
    }


    getCategoriesForQuery:any = () => {
        let categoriesForSearch = [];
        for (let category in this.quarantinedQuery.category) {
            if (this.quarantinedQuery.category[category]) {
                categoriesForSearch.push(category);
            }
        }

        return categoriesForSearch.join(',');
    }

	getStatusForQuery:any = () => {
		let statusForSearch = [];
		for (let status in this.quarantinedQuery.status) {
			if (this.quarantinedQuery.status[status]) {
				switch (status) {
					case 'Quarantined':
						statusForSearch.push(this.dic.CONSTANTS.quarantinedActions.quarantine.value);
						break;
					case 'Released':
						statusForSearch.push(this.dic.CONSTANTS.quarantinedActions.release.value);
						break;
					case 'Removed':
						statusForSearch.push(this.dic.CONSTANTS.quarantinedActions.remove.value);
						break;
					case 'Requested release':
						statusForSearch.push(this.dic.CONSTANTS.quarantinedActions.requestRelease.value);
						break;
				}
			}
		}

		return statusForSearch.join(',');
	}

	getSubCategoryForQuery:any = () => {
		let subCategoriesForSearch = [];
		for (let category in this.quarantinedQuery.sub_category) {
			if (this.quarantinedQuery.sub_category[category]) {
				subCategoriesForSearch.push(category);
			}
		}

		return subCategoriesForSearch.join(',');
	}

    changePeriod = (period) => {
        this.resultsPeriod = period;
    };

    filterDuplicateSafeLinks = () => {
        let linksMap = {};
        this.curTPResults.resUrl.list = _.filter(this.curTPResults.resUrl.list, link => {
            if (link.name) {
                if (linksMap[link.name]) {
                    if (link.status === this.dic.CONSTANTS.threatProtection.status.safe) {
                        return false;
                    }
                }
                else {
                    if (link.status !== this.dic.CONSTANTS.threatProtection.status.safe) {
                        linksMap[link.name] = link.url;
                    }
                    // safe duplicates
                    else {
                        if (link.name === link.url &&
                            _.find<any>(this.curTPResults.resUrl.list, l => l.status === this.dic.CONSTANTS.threatProtection.status.safe && l.name === link.name && l.url !== link.url)) {
                            return false;
                        }
                    }
                }
            }
            return true;
        });
    };

	showQuarantinedBulkActions = () => {
		return this.multipleEmailsActions;
	}

    showQuarantinedActions = (emailObj) => {
		let actions = [];
        emailObj.showActions = !emailObj.showActions;
        if (emailObj.showActions) {
            actions = [
                this.dic.CONSTANTS.quarantinedActions.viewInfo.display,
                this.dic.CONSTANTS.quarantinedActions.release.display,
                this.dic.CONSTANTS.quarantinedActions.setCategory.display,
                this.dic.CONSTANTS.quarantinedActions.eml.display,
				emailObj.keep_forever ? this.dic.CONSTANTS.quarantinedActions.toggleDoNotKeepForever.display : this.dic.CONSTANTS.quarantinedActions.toggleKeepForever.display
            ];

            switch (emailObj.status) {
                case this.dic.CONSTANTS.quarantinedActions.quarantine.value:
                    if (this.allowRequestRelease) {
                        actions.push(this.dic.CONSTANTS.quarantinedActions.requestRelease.display);
                    }
                    break;

                case this.dic.CONSTANTS.quarantinedActions.releaseFailure.value:
                case this.dic.CONSTANTS.quarantinedActions.release.value:
                    actions.push(this.dic.CONSTANTS.quarantinedActions.remove.display);
                    break;

                case this.dic.CONSTANTS.quarantinedActions.remove.value:
                    if (this.allowRequestRelease) {
                        actions.push(this.dic.CONSTANTS.quarantinedActions.requestRelease.display);
                    }
                    break;
            }
        }

		return actions;
    };


    selectAction = (email, action) => {
        switch (action) {
            case this.dic.CONSTANTS.quarantinedActions.viewInfo.display:
                this.viewQuarantineInfo(email);
                break;

            case this.dic.CONSTANTS.quarantinedActions.review.display:
                this.previewQuarantinedEmailContent(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.headers.display:
                this.previewQuarantinedEmailHeaders(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.whitelist.display:
                this.addSenderWhiteListPopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.blacklist.display:
                this.addSenderBlackListPopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.linksWhitelist.display:
                this.addLinksWhitelistPopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.eml.display:
                this.downloadEml(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.release.display:
                this.releaseQuarantinedEmailPopup([email]);
                return;

            case this.dic.CONSTANTS.quarantinedActions.requestRelease.display:
                this.requestReleaseQuarantinedEmailPopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.remove.display:
                this.removeQuarantinedEmailPopup([email]);
                return;

            case this.dic.CONSTANTS.quarantinedActions.delete.display:
                this.deleteQuarantinedEmailPopup([email]);
                return;

            case this.dic.CONSTANTS.quarantinedActions.authSender.display:
                this.authSenderPopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.signature.display:
                this.signaturePopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.setCategory.display:
                this.openSetCategoryPopup(email);
                return;

			case this.dic.CONSTANTS.quarantinedActions.toggleDoNotKeepForever.display:
			case this.dic.CONSTANTS.quarantinedActions.toggleKeepForever.display:
                this.keepForeverPopup([email]);
                return;

            case this.dic.CONSTANTS.quarantinedActions.handled.display:
                this.setHandledStatusExecute(email, !email.reviewed_completed, () => {});
                return;
        }
    };

    selectMultipleEmailsAction = (selectedEmails, action) => {
        if (!selectedEmails?.length) {
            return;
        }

        switch (action) {
            case this.dic.CONSTANTS.quarantinedActions.release.display:
                this.releaseQuarantinedEmailPopup(selectedEmails);
                return;

            case this.dic.CONSTANTS.quarantinedActions.remove.display:
                this.removeQuarantinedEmailPopup(selectedEmails);
                return;

            case this.dic.CONSTANTS.quarantinedActions.setCategory.display:
                this.openSetMultipleCategoryPopup(selectedEmails);
                return;

            case this.dic.CONSTANTS.quarantinedActions.handled.display:
                this.setMultipleHandledStatus(selectedEmails, 0, true, (err) => {
                    if (err) {
                        return;
                    }
                    this.ns.showInfoMessage(`${selectedEmails.length} emails were set as handled`);
                });
                return;

			case this.dic.CONSTANTS.quarantinedActions.toggleDoNotKeepForever.display:
			case this.dic.CONSTANTS.quarantinedActions.toggleKeepForever.display:
				this.keepForeverPopup(selectedEmails);
				return;

            case this.dic.CONSTANTS.quarantinedActions.delete.display:
                this.deleteQuarantinedEmailPopup(selectedEmails);
                return;
        }

    };

    openSetMultipleCategoryPopup = (selectedEmails) => {
        this.setCategoryPopup = {
            newCategory: selectedEmails[0].category,
			selectedEmails: selectedEmails,
            reportToTrustifi: true,
            reason: '',
            show: true
        };
    }

    copyItemToClipboard = (item) => {
        this.clipboard.copy(item);
    };

    setInboundStatusStyles = (status) => {
        switch (status) {
            case this.dic.CONSTANTS.threatProtection.status.malicious:
                return {color: 'darkred', icon: 'fa-bug', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.suspicious:
                return {color: 'darkred', icon: 'fa-bug', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.error:
                return {color: 'darkred', icon: 'fa-exclamation-circle', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.spam:
                return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.grayMail:
                return {color: 'dimgray', icon: 'fa-exclamation-circle', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.unsecured:
                return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.pending:
            case this.dic.CONSTANTS.threatProtection.status.analyzing:
            case this.dic.CONSTANTS.threatProtection.status.analyzingLinks:
                return {color: 'darkorange', icon: 'fa-clock', loading: true};

            case this.dic.CONSTANTS.threatProtection.status.safe:
                return {color: 'darkgreen', icon: 'fa-check-circle', loading: false};

            case 'External':
                return {color: 'darkorange', icon: 'fa-external-link-alt', loading: false}

            default:
				return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false};
        }
    };

    authenticateSender = () => {
        let actionData:any = {
            action: this.dic.CONSTANTS.quarantinedActions.authSender.value,
            authSenderMethod: this.senderAuthMethod
        };
        let notificationTxt = actionData.authSenderMethod;
        if (actionData.authSenderMethod === this.dic.CONSTANTS.authenticateSenderMethods.phone) {
            actionData.phone = this.senderAuthPhone;
            notificationTxt = 'SMS';
        }
        this.authSenderInProcess = true;
        this.loadQuarantineData = true;
        const emailId = getEmailId(this.curEmail);
        this.rs.doActionOnUserQuarantinedEmail(emailId, actionData).then((response) => {
            this.loadQuarantineData = false;
            this.curEmail.auth_sender = {
                updated: new Date(),
                method: this.senderAuthMethod
            };
            if (this.curEmail.auth_sender.method === this.dic.CONSTANTS.authenticateSenderMethods.phone) {
                this.curEmail.auth_sender.phone = this.senderAuthPhone;
            }

            this.authSenderInProcess = false;
            this.showAuthSenderPopup = false;
            this.addReviewerAction(this.curEmail, `Requested sender authentication`);
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.quarantinedAuthSender, notificationTxt));
            this.popupError = this.ns.getCurrentMessage();
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            this.authSenderInProcess = false;
            this.loadQuarantineData = false;
        });
    };

    setMultipleHandledStatus = (emails, idx, enabled, cb) => {
        if (idx < emails.length) {
            this.setHandledStatusExecute(emails[idx], enabled, (err) => {
                if (err) {
                    return cb(err);
                }
                this.setMultipleHandledStatus(emails, idx + 1, enabled, cb);
            });
        }
        else {
            cb();
        }
    };

    setHandledStatusExecute = (email, enabled, cb) => {
        if (!email) {
            return;
        }
        if (email.parent) {
            this.setMultipleHandledStatus(email.emails, 0, enabled, (err) => {
                if (err) {
                    return cb(err);
                }
                email.reviewed_completed = enabled;
                cb();
            });
        }
        else {
            const data = {
                action: this.dic.CONSTANTS.quarantinedActions.handled.value,
                enabled: enabled
            };
            const emailId = getEmailId(email);
            this.loadQuarantineData = true;
            this.rs.doActionOnUserQuarantinedEmail(emailId, data).then((response) => {
                this.addReviewerAction(email, `Set the email as ${data.enabled ? '' : 'not '}handled`);
                email.reviewed_completed = data.enabled;
                this.loadQuarantineData = false;
                cb();
            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                this.loadQuarantineData = false;
                cb(err);
            });
        }
    };


	keepForeverPopup = (emails) => {
		if (emails.length === 1) {
			const email = emails[0];
			let title, subTitle;
			if (email.keep_forever) {
				title = 'Do Not Preserve Quarantined Email Record';
				subTitle = `Please note - this email will be automatically deleted after the configured retention period.`;
			}
			else {
				title = 'Preserve Quarantined Email Record';
				subTitle = `Please note - this email will not be automatically deleted after the configured retention period.`;
			}

			this.gs.showPopup({
				title: title,
				subTitle: subTitle,
				body: [],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Confirm',
				doneCb: () => {
					this.keepForeverPopupExecute(email, !email.keep_forever, () => {});
				}
			});
		}
		else {
			this.gs.showPopup({
				title: 'Preserve Quarantined Email Records',
				subTitle: 'The emails will be kept in the system regardless the retention period',
				body: [],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Confirm',
				doneCb: () => {
					emails.forEach((emailObj) => {
						if (!emailObj.keep_forever) {
							this.keepForeverPopupExecute(emailObj, true, () => {});
						}
					});
				}
			});
		}
	};

    keepForeverPopupMultipleExecute = (emails, enabled, idx, cb) => {
        if (idx < emails.length) {
            this.keepForeverPopupExecute(emails[idx], enabled, () => {
                this.keepForeverPopupMultipleExecute(emails, enabled, idx + 1, cb);
            });
        }
        else {
            cb();
        }
    };

    keepForeverPopupExecute = (email, enabled, cb) => {
        if (email.parent) {
            this.keepForeverPopupMultipleExecute(email.emails, enabled, 0, () => {
                email.keep_forever = enabled;
                cb();
            });
        }
        else {
            const data = {
                action: this.dic.CONSTANTS.quarantinedActions.toggleKeepForever.value,
				enabled: enabled
            };
            const emailId = getEmailId(email);
            this.rs.doActionOnUserQuarantinedEmail(emailId, data).then((response) => {
                email.keep_forever = enabled;
                this.addReviewerAction(email, `Record will ${enabled ? '' : 'not'} be preserved`);
                cb();
            });
        }
    };

    authSenderPopup = (email) => {
        this.curAction = this.dic.CONSTANTS.quarantinedActions.authSender.value;
        this.senderAuthMethod = this.dic.CONSTANTS.authenticateSenderMethods.phone;

		this.defaultCountry = this.gs.getUserCountryCodeStr(this.userInfo.from[0]?.phone?.country_code || null);

		this.senderAuthPhone = {phone_number: '', country_code: ''};
        this.blockSenderAuthRequest = false;
        if (email.auth_sender && email.auth_sender.updated) {
            this.senderAuthMethod = email.auth_sender.method;
            if (email.auth_sender.method === this.dic.CONSTANTS.authenticateSenderMethods.phone) {
                this.senderAuthPhone = _.clone(email.auth_sender.phone);
            }
            email.auth_sender.minutes = this.gs.dateDiffInMinutes(new Date(email.auth_sender.updated), new Date());
            if (email.auth_sender.minutes <= this.dic.CONSTANTS.authSenderTtl) {
                this.blockSenderAuthRequest = true;
            }
        }

        this.curEmail = email;
        this.showAuthSenderPopup = true;
    };

    openClickTrackingPopup = (url) => {
        this.urlTrackingPopup = {
            urlAddress: url.redirect_url || url.url,
            clickInfo: url.clickInfo,
            show: true
        }
    }

    openLocation = (ll) => {
        this.gs.openLocation(ll);
    }

    openSetCategoryPopup = (email) => {
        this.setCategoryPopup = {
            email: email,
            newCategory: email.category,
            reportToTrustifi: true,
            reason: '',
            show: true
        };
    };

    setCategoryConfirm = () => {
        // check if changes made for single email
        if (this.setCategoryPopup.email && this.setCategoryPopup.email.category === this.setCategoryPopup.newCategory && !this.setCategoryPopup.reason) {
            this.ns.showWarnMessage(this.dic.ERRORS.noChanges);
            return;
        }
        // check if changes made for multiple emails
        if (this.setCategoryPopup.selectedEmails && !_.filter(_.map(this.setCategoryPopup.selectedEmails, 'category'),c => c !== this.setCategoryPopup.newCategory).length && !this.setCategoryPopup.reason) {
            this.ns.showWarnMessage(this.dic.ERRORS.noChanges);
            return;
        }

        const categoryInfo = {
            category: this.setCategoryPopup.newCategory,
            reason: this.setCategoryPopup.reason,
            reportToTrustifi: this.setCategoryPopup.reportToTrustifi
        }

        this.setCategoryInProcess = true;

        if (this.setCategoryPopup.selectedEmails) {
            this.setCategoryMultipleQuarantinedEmail(this.setCategoryPopup.selectedEmails, 0, categoryInfo, () => {
                if (categoryInfo.reportToTrustifi) {
                    this.ns.showInfoMessage(this.dic.MESSAGES.quarantineReport);
                    this.popupError = {error: {type: 'info', message: this.dic.MESSAGES.quarantineReport}};
                }
                else {
                    this.ns.showInfoMessage('Category was changed successfully');
                    this.popupError = {error: {type: 'info', message: 'Category was changed successfully'}};
                }
                this.setCategoryPopup = null;
                this.setCategoryInProcess = false;
            });
        }
        else {
            this.setCategoryExecute(this.setCategoryPopup.email, categoryInfo, () => {
                if (categoryInfo.reportToTrustifi) {
                    this.ns.showInfoMessage(this.dic.MESSAGES.quarantineReport);
                    this.popupError = {error: {type: 'info', message: this.dic.MESSAGES.quarantineReport}};
                }
                else {
                    this.ns.showInfoMessage('Category was changed successfully');
                    this.popupError = {error: {type: 'info', message: 'Category was changed successfully'}};
                }
                this.setCategoryPopup = null;
                this.setCategoryInProcess = false;
            });
        }
    };

    setCategoryMultipleQuarantinedEmail = (emails, idx, categoryInfo, cb) => {
        if (idx < emails.length) {
            this.setCategoryExecute(emails[idx], categoryInfo, () => {
                this.setCategoryMultipleQuarantinedEmail(emails, idx + 1, categoryInfo, cb);
            });
        }
        else {
            cb();
        }
    };

    setCategoryExecute = (email, categoryInfo, cb) => {
        if (email.parent) {
            this.setCategoryMultipleQuarantinedEmail(email.emails, 0, categoryInfo, () => {
                email.category = categoryInfo.category;
                email.reason = categoryInfo.reason;
                email.malicious.status_user_reported = email.category;

                email.diffCategory = false;
                this.setEmailCategory(email);
                cb();
            });
        }
        else {
            const data = {
                action: this.dic.CONSTANTS.quarantinedActions.setCategory.value,
                category: categoryInfo.category,
                reason: categoryInfo.reason,
                reportToTrustifi: categoryInfo.reportToTrustifi
            };
            const emailId = getEmailId(email);
            this.rs.doActionOnUserQuarantinedEmail(emailId, data).then(() => {
                email.category = categoryInfo.category;
                email.reason = categoryInfo.reason;
                email.malicious.status_user_reported = email.category;
                if (data.reportToTrustifi) {
                    email.report = {report_by: 'recipient'};
                }

                this.setEmailCategory(email);
                this.addReviewerAction(email, `Category changed to ${email.category}`);
                cb();
            }, (err) => {
                this.popupError = this.ns.getCurrentMessage();
				cb();
            });
        }
    };


    signaturePopup = (email) => {
        this.gs.showPopup({
            title: 'Sign Quarantined Email Source',
            subTitle: "Please note - you are about to sign source for this email",
            body:  [`The source will be signed and considered safe and valid for this domain`],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Sign Source',
            doneCb: () => {
                this.signatureExecute(email, () => {});
            }
        });
    };

    signatureExecute = (email, cb) => {
        const data = {action: this.dic.CONSTANTS.quarantinedActions.signature.value};
        const emailId = getEmailId(email);
        this.rs.doActionOnUserQuarantinedEmail(emailId, data).then((response) => {
            this.ns.showInfoMessage(this.dic.MESSAGES.quarantinedSignSource);
            this.popupError = this.ns.getCurrentMessage();
            this.addReviewerAction(email, `Domain ${email.sender} signature marked as trusted`);
            cb();
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            cb();
        });
    };

    viewQuarantineInfo =(email) => {
        // remove previous email data
        if (this.curEmail) {
            this.curEmail.content = null;
            this.curEmail.headers = null;
            this.curTPResults = null;
        }

        this.curEmail = email;
        this.curEmail.reviewer_actions = this.curEmail.emails[0].reviewer_actions;

        this.curEmail.isShowingContent = false;
        this.popupError = null;

        this.previewQuarantinedEmailThreats(email);
    };

    previewLink = (url) => {
        if (!url) {
            this.openPreviewLinkPopup = null;
            return;
        }

        this.openPreviewLinkPopup = {
            show: true,
            url: url
        };
    }

    previewQuarantinedEmailContent =(email) => {
        this.popupError = null;

        this.emailInfoTab = this.dic.CONSTANTS.quarantinedEmailInfoTabs.content;
        email.isShowingContent = true;

        if (email.content) {
            return;
        }
        email.loadingEmailContent = true;
        this.loadQuarantineData = true;
        const emailId = getEmailId(email);
        this.rs.getUserQuarantinedEmailData(emailId).then((data) => {
            if (!data) {
                return;
            }

			if (this.enableDefangeUrl) {
				if (data.content.html) {
					data.content.html = this.defangeUrlFromContent(data.content.html, true);
				} else {
					data.content.text = this.defangeUrlFromContent(data.content.html, false);
				}
			}

            const processedEmailContent = this.processEmailContent(data);
            if (emailId === getEmailId(this.curEmail)) {
                email.content = {
                    html: processedEmailContent,
                    attachments: data.content?.attachments
                };
            }
            this.addReviewerAction(this.curEmail, data.reviewerActivity);
            this.loadQuarantineData = false;
            email.loadingEmailContent = false;
        }, (err) => {
            this.loadQuarantineData = false;
            email.loadingEmailContent = false;
        });
    };

	defangeUrlFromContent(content, isHtml) {
		const maliciousLinks = _.filter(this.curTPResults.resUrl.list, (url) => {
			return url.status === this.dic.CONSTANTS.threatProtection.status.malicious;
		}).map((maliciousUrl) => {
			return maliciousUrl.original_url || maliciousUrl.url;
		});

		if (maliciousLinks.length) {
			try {
				return isHtml ? this.gs.defangeMaliciousLinksFromHtml(content, maliciousLinks) : this.gs.defangeMaliciousLinksFromText(content, maliciousLinks);
			}
			catch (error) {
				return content;
			}
		} else {
			return content;
		}
	}

    processEmailContent(contentData) {
        let html = `<div>`;

        if (contentData.content) {
            if (contentData.content.from && contentData.content.from.text) {
                contentData.content.from.text = contentData.content.from.text.replaceAll('<', '('); // problem to display <> in HTML
                contentData.content.from.text = contentData.content.from.text.replaceAll('>', ')');
                html += `<b>From:</b>  ${contentData.content.from.text}`;
            }

            if (contentData.content.to && contentData.content.to.text) {
                contentData.content.to.text = contentData.content.to.text.replaceAll('<', '('); // problem to display <> in HTML
                contentData.content.to.text = contentData.content.to.text.replaceAll('>', ')');
                html += `<br><b>To:</b>  ${contentData.content.to.text}`;
            }
            if (contentData.content.cc && contentData.content.cc.text) {
                contentData.content.cc.text = contentData.content.cc.text.replaceAll('<', '(');
                contentData.content.cc.text = contentData.content.cc.text.replaceAll('>', ')');
                html += `<br><b>Cc:</b>  ${contentData.content.cc.text}`;
            }

            html += `<br><b>Subject:</b>  ${this.curEmail.subject}<br><hr><br></div>`;
            html += contentData.content.html || contentData.content.text || '';
        }

        return html;
    }

	closeQuarantinedPopup = () => {
		this.headerSearchTerm = '';
		this.reviewerSearchTerm = '';
		this.searchHeader();
		this.searchReviewer();
		this.emailInfoPopup.show = false;
	}

    previewQuarantinedEmailHeaders = (email) => {
        this.emailInfoTab = this.dic.CONSTANTS.quarantinedEmailInfoTabs.headers;

        if (this.curEmail.headers) {
            return;
        }
        this.curEmail.loadingHeaders = true;
        const emailId = getEmailId(email);
        this.rs.getUserQuarantinedEmailHeaders(emailId).then((data) => {
            if (emailId === getEmailId(this.curEmail)) {
                this.curEmail.headers = data.headers;
            }
            this.curEmail.loadingHeaders = false;
        }, (err) => {
            this.curEmail.loadingHeaders = false;
        });
    };

    previewQuarantinedEmailThreats =(email) => {
        this.emailInfoPopup = {
            show: true
        };

        this.emailInfoTab = this.dic.CONSTANTS.quarantinedEmailInfoTabs.threats;

        this.loadQuarantineData = true;
        const emailId = getEmailId(email);
        this.rs.getUserQuarantinedThreats(emailId).then((data) => {
            if (emailId === getEmailId(this.curEmail)) {
                email.malicious = data.malicious;
                email.methods = data.methods;
                email.senderMethods = data.senderMethods;
                this.showEmailThreatsPopup(email);
            }
            this.addReviewerAction(email, data.reviewerActivity);
            this.curEmail.curRecipient = this.curEmail.emails[0];
            this.loadQuarantineData = false;
        });
    };

    changeRecipientThreats = (recipient) => {
        this.curEmail.curRecipient = recipient;
        this.loadQuarantineData = true;
        this.rs.getUserQuarantinedThreats(recipient._id).then((data) => {
            if (data) {
                recipient.malicious = data.malicious;
                recipient.methods = data.methods;
                this.curEmail.methods = data.methods;
                this.curEmail.malicious = data.malicious;
                this.showEmailThreatsPopup(this.curEmail);
            }
            this.loadQuarantineData = false;
        });
    };

    showEmailThreatsPopup = (email) => {
        this.curTPResults = email.malicious.tpResults;
        if (this.curTPResults) {
            if (this.curTPResults.resHead && this.curTPResults.resHead.aiConfidence) {
                this.curTPResults.resHead.aiConfidence = parseFloat(this.curTPResults.resHead.aiConfidence.toFixed(2))
            }
            if (this.curTPResults.resUrl && this.curTPResults.resUrl.list && this.curTPResults.resUrl.list.length) {
                this.curTPResults.resUrl.list = this.curTPResults.resUrl.list.sort((a, b) => this.statusPriority[a.status] - this.statusPriority[b.status]);
                this.filterDuplicateSafeLinks();
            }
            else {
                if (!this.curTPResults.resUrl) {
                    this.curTPResults.resUrl = {};
                }
                this.curTPResults.urlStatus = this.dic.CONSTANTS.threatProtection.status.safe;
                if (!this.curTPResults.resUrl.message) {
					this.curTPResults.resUrl.message = 'No malicious links detected.';
                }
            }
            if (this.curTPResults.resHash && this.curTPResults.resHash.list && this.curTPResults.resHash.list.length) {
                this.curTPResults.resHash.list = this.curTPResults.resHash.list.sort((a, b) => this.statusPriority[a.status] - this.statusPriority[b.status]);
            }
            else {
                if (!this.curTPResults.resHash) {
                    this.curTPResults.resHash = {};
                }
                this.curTPResults.hashStatus = this.dic.CONSTANTS.threatProtection.status.safe;
                if (!this.curTPResults.resHash.message) {
					this.curTPResults.resHash.message = 'No malicious attachments detected.';
                }
            }

			if (this.tpMetrics?.length) {
				if (this.curTPResults?.resHead?.sub_status) {
					this.curTPResults.resHead.sub_status_description = this.tpMetrics.find(itm => itm.sub_status && itm.sub_status === this.curTPResults.resHead.sub_status)?.description || '';
				}
				if (this.curTPResults?.resUrl?.sub_status) {
					this.curTPResults.resUrl.sub_status_description = this.tpMetrics.find(itm => itm.sub_status && itm.sub_status === this.curTPResults.resUrl.sub_status)?.description || '';
				}
				if (this.curTPResults?.resHash?.sub_status) {
					this.curTPResults.resHash.sub_status_description = this.tpMetrics.find(itm => itm.sub_status && itm.sub_status === this.curTPResults.resHash.sub_status)?.description || '';
				}
			}
        }
	}


    requestReleaseQuarantinedEmailPopup = (email) => {
        if (email.status === this.dic.CONSTANTS.quarantinedActions.pending.value
            || email.status === this.dic.CONSTANTS.quarantinedActions.release.value) {
            return;
        }

        this.gs.showPopup({
            title: 'Request release of quarantined email',
            body:  ['You are about to send a request to release this email from quarantine'],
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Request',
            doneCb: () => {
                const data = {
                    action: this.dic.CONSTANTS.quarantinedActions.requestRelease.value,
                };
                const emailId = getEmailId(email);
                this.rs.doActionOnUserQuarantinedEmail(emailId, data).then((response) => {
                    email.status = this.dic.CONSTANTS.quarantinedActions.requestRelease.value;

                    this.ns.showInfoMessage("Release request has been sent");
                });
            }
        });
    }

    releaseQuarantinedEmailPopup = (emails) => {
        if (!emails.length) {
            return;
        }

        this.addWarningLabel = false;
        this.addSubjectText = false;
        this.deleteRecord = false;
        this.reportToTrustifi = true;

        let subTitle, details;
        if (emails.length > 1) {
            subTitle = `Please note - you are about to release ${emails.length} emails back to the recipient's inbox`;
            details = ['Emails will be released', 'Release these emails only if you are sure they are safe'];
        }
        else {
            subTitle = `Please note - you are about to release this email back to ${emails[0].parent && emails[0].emails.length > 1 ? `${emails[0].emails.length} recipient inboxes` : 'the recipient\'s inbox'}`;
            details = [`Email ${emails[0].subject} will be released`, 'Release this email only if you are sure it is safe'];
            const releaseExplanation = this.dic.CONSTANTS.releaseExplanation[emails[0].category];
            if (releaseExplanation && releaseExplanation.length) {
                details = details.concat(releaseExplanation);
            }
        }

        // Do not send reports to Trustifi by default on Safe/External
        if (emails.every(emailObj => ['Safe', 'External'].includes(emailObj.malicious.status))) {
            this.reportToTrustifi = false;
        }

        // Do not send reports to Trustifi by default on Blocklist, User Preferences, Spoofing
        if (emails.every(emailObj => ['Blocklist', 'User Preferences', 'Spoofing'].includes(emailObj.malicious.tpResults.resHead.category))) {
            if (!emails.some(emailObj => ['Threat Intelligence'].includes(emailObj.malicious.tpResults.resHead.sub_status))) {
                this.reportToTrustifi = false;
            }
        }

        this.gs.showPopup({
            title: 'Release Quarantined Email',
            subTitle: subTitle,
            body:  [
                {
                    labelName: 'Add warning label',
                    tooltip: 'The relevant warning label will be added to the email when it is released.',
                    toggle: this.addWarningLabel
                },
                {
                    labelName: 'Add subject text',
                    tooltip: 'The relevant text will be added to the email subject when it is released.',
                    toggle: this.addSubjectText
                },
                {
                    labelName: 'Report to Trustifi',
                    tooltip: 'Our dedicated security team review each report daily and takes necessary actions to adjust our security features accordingly.\n' +
                        'Based on feedback, our team can adjust the AI, add malicious senders to the global blocklist, enhance our security metrics and more.',
                    toggle: this.reportToTrustifi
                }
            ],
            details: details,
            type: this.dic.CONSTANTS.popupToggle,
            doneBtnText: 'Release',
            doneCb: (options) => {
                let releaseOptions:any = {};
                if (options && options[0] && options[0].toggle) {
                    releaseOptions.addWarningLabel = options[0].toggle;
                }
                if (options && options[1] && options[1].toggle) {
                    releaseOptions.addSubjectText = options[1].toggle;
                }
                if (options && options[2] && options[2].toggle) {
                    releaseOptions.reportToTrustifi = options[2].toggle;
                }

                this.loadQuarantineData = true;
                this.releaseMultipleQuarantinedEmail(emails, 0, releaseOptions, (err) => {
                    this.loadQuarantineData = false;
                    if (err) {
                        return;
                    }
                    if (emails.length > 1) {
                        this.ns.showInfoMessage(`Operation completed successfully for ${emails.length} emails`);
                    }

                    if (this.curEmail && this.curEmail.methods) {
                        _.remove<any>(this.curEmail.methods, m => m === this.dic.CONSTANTS.quarantinedActions.release.value);
                        if (this.curEmail.status === this.dic.CONSTANTS.quarantinedActions.release.value) {
                            this.curEmail.methods.push(this.dic.CONSTANTS.quarantinedActions.remove.value);
                        }
                        // when deleted we cannot take anymore actions
                        if (releaseOptions.deleteRecord) {
                            this.curEmail.methods = [];
                            this.curEmail.senderMethods = [];
                        }
                    }

                    this.popupError = this.ns.getCurrentMessage();
                });
            }
        });
    };

    releaseQuarantinedEmailExecute = (email, releaseOptions, cb) => {
        if (email.parent) {
            this.releaseMultipleQuarantinedEmail(email.emails, 0, releaseOptions, (err) => {
                if (err) {
                    return cb(err);
                }
                email.status = email.emails[0].status;
                if (releaseOptions.deleteRecord) {
                    _.remove<any>(this.groupEmails, emailObj => emailObj._id === email._id);
                }
                this.addReviewerAction(email, null);
                cb();
            });
        }
        else {
            if (email.status === this.dic.CONSTANTS.quarantinedActions.pending.value) {
                return cb();
            }

            const data = {
                action: this.dic.CONSTANTS.quarantinedActions.release.value,
                skipWarningLabel: !releaseOptions.addWarningLabel,
                addSubjectText: releaseOptions.addSubjectText,
                reportToTrustifi: releaseOptions.reportToTrustifi,
                deleteRecord: releaseOptions.deleteRecord
            };
            const emailId = getEmailId(email);
            this.rs.doActionOnUserQuarantinedEmail(emailId, data).then((response) => {
                email.status = response.status;
                email.reason = response.reason || '';
                if (data.reportToTrustifi) {
                    email.report = {report_by: 'recipient'};
                }

                this.addReviewerAction(email, response.reviewerActivity);
                cb();

                if (email.status === this.dic.CONSTANTS.quarantinedActions.pending.value) {
                    this.addQuarantineStatusCheck(email);
                }
            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                cb(err);
            });
        }
    };

    pendingQuarantines = [];
    checkStatusTimeOut;
    addQuarantineStatusCheck = (email) => {
        this.pendingQuarantines.push(email._id.toString());

        if (this.checkStatusTimeOut) {
            return;
        }

        this.checkStatusTimeOut = setTimeout(() => {
            this.checkQuarantineStatus();
        }, 30000);
    };

    checkQuarantineStatus = () => {
        const slicedArray = this.pendingQuarantines.slice(0, 30); // limit the IDs in the query
        this.rs.checkUserQuarantineStatus(slicedArray.join(',')).then((response) => {
            response.forEach((statusObj) => {
                if (statusObj.status !== this.dic.CONSTANTS.quarantinedActions.pending.value) {
                    for (let idx = 0; idx < this.groupEmails.length; idx++) {
                        let emailObj = _.find<any>(this.groupEmails[idx].emails, {_id: statusObj._id});
                        if (emailObj) {
                            emailObj.status = statusObj.status;

                            calcParentEmailCategory(this.groupEmails[idx]);
                            break;
                        }
                    }
                    const index = this.pendingQuarantines.indexOf(statusObj._id);
                    if (index > -1) {
                        this.pendingQuarantines.splice(index, 1);
                    }
                }
            });

            if (this.pendingQuarantines.length) {
                this.checkStatusTimeOut = setTimeout(() => {
                    this.checkQuarantineStatus();
                }, 30000);
            }
            else {
                this.checkStatusTimeOut = null;
            }
        });
    }


    addSenderBlackListPopup = (email) => {
        if (!email) {
            return;
        }

        const blockListOptions = [
            {text : `Sender email: ${email.sender}`, model: this.actionForHeaders.senderEmail, value: email.sender, type: 'email', sender: true},
            {text : `Reply-to email: ${email.malicious.tpResults.resHead.from.replyTo}`, model: this.actionForHeaders.replyToEmail, value: email.malicious.tpResults.resHead.from.replyTo, type: 'email'},
            {text : `Return path email: ${email.malicious.tpResults.resHead.from.returnPath}`, model: this.actionForHeaders.returnPath, value: email.malicious.tpResults.resHead.from.returnPath, type: 'email'},
        ];
        if (email.malicious.tpResults.resHead.from.senderMail && email.malicious.tpResults.resHead.from.senderMail !== email.malicious.tpResults.resHead.from.senderMailFrom) {
            blockListOptions.unshift({text : `Sender appears as: ${email.malicious.tpResults.resHead.from.senderMail}`, model: this.actionForHeaders.senderEmailAppearsAs, value: email.malicious.tpResults.resHead.from.senderMail, type: 'email'});
        }

		this.blocklistSenderPopup = {
			show: true,
			applyInProcess: false,
			blacklistReason: '',
			items: blockListOptions,
			doneCb: () => {
				this.blocklistSenderPopup.applyInProcess = true;
				this.loadQuarantineData = true;

				const items = this.blocklistSenderPopup.items.filter(itm => itm.model);
				items.forEach((itemObj) => {
					itemObj.description = this.blocklistSenderPopup.blacklistReason || '';
				});
				if (!items.length) {
					this.blocklistSenderPopup.applyInProcess = false;
					this.loadQuarantineData = false;
					this.ns.showErrorMessage('You must select at least one header');
					return;
				}
				this.addSenderBlackListExecute(email, items, () => {
					this.loadQuarantineData = false;
					if (this.curEmail && this.curEmail.senderMethods) {
						_.remove<any>(this.curEmail.senderMethods, m => m === this.dic.CONSTANTS.quarantinedActions.blacklist.value);
						this.curEmail.senderMethods.push(this.dic.CONSTANTS.quarantinedActions.whitelist.value);
					}

					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'sender'));
					this.blocklistSenderPopup = null;
					this.popupError = this.ns.getCurrentMessage();
				});
			}
		};
    };

    addSenderBlackListExecute = (email, items, cb) => {
        const data = {
            action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
            type: this.dic.CONSTANTS.configTpAction.blacklist,
            items: items
        };
        this.rs.updateUserThreats(data).then((response) => {
            this.addReviewerAction(email, `Added sender to blocklist`);
            cb();

        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            cb();
        });
    };

    addLinksWhitelistPopup = (email) => {
        if (!email) {
            return;
        }

        let body = ["All non-safe links contained in this email will now be considered safe."];

        this.gs.showPopup({
            title: 'Add links to allowlist',
            subTitle: "Please note - you are about to add this email's links to allowlist",
            body:  body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Confirm',
            doneCb: () => {
                this.loadQuarantineData = true;
                this.addLinksWhiteListExecute(email, () => {
                    this.loadQuarantineData = false;
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Links'));
                    this.popupError = this.ns.getCurrentMessage();
                });
            }
        });
    };

    addLinksWhiteListExecute =(email, cb) => {
        const data = {
            action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
            type: this.dic.CONSTANTS.configTpAction.linksWhitelist,
            links: []
        };
        email.malicious.tpResults.resUrl.list.forEach((linkObj) => {
            if (linkObj.status !== "Safe") {
                data.links.push({link: linkObj.url});
            }
        });

        this.rs.updateUserThreats(data).then((response) => {
            this.addReviewerAction(email, `Added links to allowlist`);
            cb();
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            cb();
        });
    };

    addSenderWhiteListPopup = (email) => {
        if (!email) {
            return;
        }

		this.allowlistSenderPopup = {
			show: true,
			applyInProcess: false,
			items: [
				{text : `Sender email: ${email.sender}`, model: this.actionForHeaders.senderEmail, value: email.sender, type: 'email', sender: true},
				{text : `Reply-to email: ${email.malicious.tpResults.resHead.from.replyTo}`, model: this.actionForHeaders.replyToEmail, value: email.malicious.tpResults.resHead.from.replyTo, type: 'email'},
			],
			whitelistReason: '',
			doneCb: () => {
				this.allowlistSenderPopup.applyInProcess = true;
				this.loadQuarantineData = true;
				const items = this.allowlistSenderPopup.items.filter(itm => itm.model);
				items.forEach((itemObj) => {
					itemObj.description = this.allowlistSenderPopup.whitelistReason || '';
				});
				if (!items.length) {
					this.ns.showErrorMessage('You must select at least one header');
					this.allowlistSenderPopup.applyInProcess = false;
					this.loadQuarantineData = false;
					return;
				}

				this.addSenderWhiteListExecute(email, items, () => {
					this.loadQuarantineData = false;
					if (this.curEmail && this.curEmail.senderMethods) {
						_.remove<any>(this.curEmail.senderMethods, m => m === this.dic.CONSTANTS.quarantinedActions.whitelist.value);
						this.curEmail.senderMethods.push(this.dic.CONSTANTS.quarantinedActions.blacklist.value);
					}
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'sender'));
					this.allowlistSenderPopup = null;
					this.popupError = this.ns.getCurrentMessage();
				});
			}
		};
    };

    addSenderWhiteListExecute =(email, items, cb) => {
        const data = {
            action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
            type: this.dic.CONSTANTS.configTpAction.whitelist,
            items: items
        };
        this.rs.updateUserThreats(data).then((response) => {
            this.addReviewerAction(email, `Added sender to allowlist`);
            cb();

        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            cb();
        });
    };

    addReviewerAction = (email, action) => {
        email.reviewed_completed = true;

        if (!action) {
            return;
        }
        if (!email.reviewer_actions) {
            email.reviewer_actions = [];
        }

        const newAction = {
            created: new Date(),
            reviewer: this.userInfo.email,
            action: action
        };
        email.reviewer_actions.unshift(newAction);
    };

    downloadEml = (email) => {
        if (!email) {
            return;
        }

        const data = {action: this.dic.CONSTANTS.quarantinedActions.eml.value};
        const emailId = getEmailId(email);
        this.rs.doActionOnUserQuarantinedEmail(emailId, data).then((response) => {
            const attachment = {
                content: response.message,
                name: `${email.subject}.eml`,
                contentType: 'message/rfc822'
            };
            this.gs.downloadData(attachment.content, attachment.name, attachment.contentType);
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
        });
    };

    removeQuarantinedEmailPopup = (emails) => {
        if (!emails.length) {
            return;
        }
        let title, subTitle, body;
        if (emails.length > 1) {
            title = 'Remove Quarantined Emails';
            subTitle = `Please note - you are about to remove ${emails.length} emails from quarantine`;
            body = ['Emails will be removed'];
        }
        else {
            title = 'Remove Quarantined Email';
            subTitle = `Please note - you are about to remove this email from ${emails[0].parent && emails[0].emails.length > 1 ? `${emails[0].emails.length} recipient inboxes` : 'the recipient\'s inbox'}`;
            body = [`Email ${emails[0].subject} will be removed`];
        }

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Remove',
            doneCb: () => {
                this.loadQuarantineData = true;
                this.removeMultipleQuarantinedEmail(emails, 0, (err) => {
                    this.loadQuarantineData = false;
                    if (err) {
                        return;
                    }
                    if (emails.length > 1) {
                        this.ns.showInfoMessage(`${emails.length} quarantined email records were removed successfully`);
                    }

                    if (this.curEmail && this.curEmail.methods) {
                        _.remove<any>(this.curEmail.methods, m => m === this.dic.CONSTANTS.quarantinedActions.remove.value);
                        this.curEmail.methods.push(this.dic.CONSTANTS.quarantinedActions.release.value);
                    }

                    this.popupError = this.ns.getCurrentMessage();
                });
            }
        });
    };

    removeQuarantinedEmailExecute = (email, cb) => {
        if (email.parent) {
            this.removeMultipleQuarantinedEmail(email.emails, 0, (err) => {
                if (err) {
                    return cb(err);
                }
                // update parent email only if operation succeeded
                if (email.emails[0].status === this.dic.CONSTANTS.quarantinedActions.remove.value) {
                    email.status = this.dic.CONSTANTS.quarantinedActions.remove.value;
                    this.addReviewerAction(email, null);
                }
                cb();
            });
        }
        else {
            if (email.status !== this.dic.CONSTANTS.quarantinedActions.release.value || email.status === this.dic.CONSTANTS.quarantinedActions.pending.value) {
                return cb();
            }

            const data = {action: this.dic.CONSTANTS.quarantinedActions.remove.value};
            const emailId = getEmailId(email);
            this.rs.doActionOnUserQuarantinedEmail(emailId, data).then((response) => {
                email.status = response.status;
                email.reason = response.reason || '';

                this.addReviewerAction(email, response.reviewerActivity);
                cb();
            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                cb(err);
            });
        }
    };

    removeMultipleQuarantinedEmail = (emails, idx, cb) => {
        if (idx < emails.length) {
            this.removeQuarantinedEmailExecute(emails[idx], (err) => {
                if (err) {
                    return cb(err);
                }
                this.removeMultipleQuarantinedEmail(emails, idx + 1, cb);
            });
        }
        else {
            cb();
        }
    };

    releaseMultipleQuarantinedEmail = (emails, idx, releaseOptions, cb) => {
        if (idx < emails.length) {
            this.releaseQuarantinedEmailExecute(emails[idx], releaseOptions, (err) => {
                if (err) {
                    return cb(err);
                }
                this.releaseMultipleQuarantinedEmail(emails, idx + 1, releaseOptions, cb);
            });
        }
        else {
            cb();
        }
    };

    deleteQuarantinedEmailPopup = (emails) => {
        if (!emails.length) {
            return;
        }

        let title, subTitle, body;
        if (emails.length) {
            title = 'Delete Quarantined Email Records';
            subTitle = `Please note - you are about to delete ${emails.length} quarantined email's records`;
            body = ['You will no longer be able to apply actions to these emails'];
        }
        else {
            title = 'Delete Quarantined Email Record';
            subTitle = `Please note - you are about to delete this quarantined email's record`;
            body = [`You will no longer be able to apply actions to this email`];
        }

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body:  body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                this.deleteMultipleQuarantinedEmails(emails, () => {
                    if (emails.length > 1) {
                        this.ns.showInfoMessage('Quarantined email record was deleted successfully');
                    }
                    else {
                        this.ns.showInfoMessage(`${emails.length} quarantined email records were deleted successfully`);
                    }

                    this.popupError = this.ns.getCurrentMessage();
                });
            }
        });
    };

    deleteQuarantinedEmailExecute = (email, cb) => {
        if (email.parent) {
            this.deleteMultipleQuarantinedEmails(email.emails, () => {
                _.remove<any>(this.groupEmails, emailObj => emailObj._id === email._id);
                cb();
            });
        }
        else {

            if (email.status === this.dic.CONSTANTS.quarantinedActions.pending.value) {
                return cb();
            }

            this.rs.deleteUserQuarantinedEmail(email._id.toString(), '').then(() => {
                email.selected = false;

                cb();
            });
        }
    };

    deleteMultipleQuarantinedEmails = (emails, cb) => {
		eachLimit(emails, 10, (emailObj, asyncCb) => {
			this.deleteQuarantinedEmailExecute(emailObj, asyncCb);
		}, () => {
			cb();
		});
    };

    downloadAttachment = (data, name, type) => {
        try {
            const isFileMalicious = this.curTPResults.resHash.list &&
                this.curTPResults.resHash.list.filter(item => item.name === name && item.status === 'Malicious');
            if (isFileMalicious && isFileMalicious.length) {
                this.ns.showErrorMessage(this.dic.ERRORS.maliciousFileDownload);
                return;
            }

            data = new Buffer(data, 'base64');
            this.gs.downloadData(data, name, type);
        } catch (err) {
            console.error(err);
        }
    };

	setTagAsFilter = (tagName) => {
		this.activeTagAsFilter = tagName;
		this.triggerSearch();
	};

	// trigger the trustifi-table search by changing the list. The directive detects changes and fires a search
	triggerSearch = () => {
		this.groupEmails = _.cloneDeep(this.groupEmails);
	}

    searchFilterMailbox = (searchTerm, activeFilters) => {

		this.groupEmails.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			if (activeFilters) {
				const {positiveFilters, negativeFilters} = this.gs.splitTableFilters(activeFilters);

				// positive filters check
				if (searchFilterExecute(this.dic, record, positiveFilters)) {
					record.hide = true;
					return;
				}
				// negative filters check
				if (!_.isEmpty(negativeFilters) && searchFilterExecute(this.dic, record, negativeFilters) !== Object.keys(negativeFilters).length) {
					record.hide = true;
					return;
				}
			}

			// Filter (Tags)
			if (this.activeTagAsFilter) {
				const tags = [
					record.malicious.tpResults?.resHead?.sub_status?.length && record.malicious.tpResults.resHead.status !== this.dic.CONSTANTS.threatProtection.status.safe ? record.malicious.tpResults.resHead.sub_status : 'N/A',
					record.malicious.tpResults?.resUrl?.sub_status?.length && record.malicious.tpResults.resUrl.status !== this.dic.CONSTANTS.threatProtection.status.safe ? record.malicious.tpResults.resUrl.sub_status : 'N/A',
					record.malicious.tpResults?.resHash?.sub_status?.length && record.malicious.tpResults.resHash.status !== this.dic.CONSTANTS.threatProtection.status.safe ? record.malicious.tpResults.resHash.sub_status : 'N/A'
				];

				if (!tags.includes(this.activeTagAsFilter)) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
    };

    calculateTags = (email) => {
        let threatTags = [];
        if (email.malicious && email.malicious.tpResults) {
            if (email.malicious.tpResults.resHead && email.malicious.tpResults.resHead.sub_status && email.malicious.tpResults.resHead.sub_status.length && email.malicious.tpResults.resHead.status !== this.dic.CONSTANTS.threatProtection.status.safe) {
                const tagInfo = {
                    status: email.malicious.tpResults.resHead.status,
                    name: email.malicious.tpResults.resHead.sub_status,
                    colorObj: this.setInboundStatusStyles(email.malicious.tpResults.resHead.status)
                };
                threatTags.push(tagInfo);
            }
            if (email.malicious.tpResults.resUrl && email.malicious.tpResults.resUrl.sub_status && email.malicious.tpResults.resUrl.sub_status.length && email.malicious.tpResults.resUrl.status !== this.dic.CONSTANTS.threatProtection.status.safe) {
                const tagInfo = {
                    status: email.malicious.tpResults.resUrl.status,
                    name: email.malicious.tpResults.resUrl.sub_status,
                    colorObj: this.setInboundStatusStyles(email.malicious.tpResults.resUrl.status)
                };
                threatTags.push(tagInfo);
            }
            if (email.malicious.tpResults.resHash && email.malicious.tpResults.resHash.sub_status && email.malicious.tpResults.resHash.sub_status.length && email.malicious.tpResults.resHash.status !== this.dic.CONSTANTS.threatProtection.status.safe) {
                const tagInfo = {
                    status: email.malicious.tpResults.resHash.status,
                    name: email.malicious.tpResults.resHash.sub_status,
                    colorObj: this.setInboundStatusStyles(email.malicious.tpResults.resHash.status)
                };
                threatTags.push(tagInfo);
            }
        }

        // merge duplicates and assign to email object
        threatTags = _.uniqBy(threatTags, tag => {return tag.name;});
        email.threatTags = threatTags;
    }


    setEmailCategory = (email) => {
        if (email.malicious) {
            if (email.malicious.status_user_reported) {
                this.setEmailCategoryExecute(email, email.malicious.status_user_reported);
            }
            else {
                this.setEmailCategoryExecute(email, email.malicious.status);
            }
        }
    };

    setEmailCategoryExecute = (email, status) => {
        if (!email || !status) {
            return;
        }
        email.category = status;
        switch (status.toLowerCase()) {
            case this.dic.CONSTANTS.tpRule.name.malicious:
                email.color = 'darkred';
                email.category_priority = 1;
                break;

            case this.dic.CONSTANTS.tpRule.name.suspicious:
                email.color = 'darkred';
                email.category_priority = 2;
                break;

            case this.dic.CONSTANTS.tpRule.name.spam:
                email.color = 'darkorange';
                email.category_priority = 3;
                break;

            case this.dic.CONSTANTS.tpRule.name.graymail:
                email.color = 'dimgray';
                email.category_priority = 4;
                break;

            case this.dic.CONSTANTS.tpRule.name.external:
                email.color = 'darkorange';
                email.category_priority = 5;
                break;

            // safe
            default:
                email.color = 'darkgreen';
                email.category_priority = 6;
        }
    };


    exportQuarantinedCsv = (sortBy) => {
        if (!this.groupEmails.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Created,Mailbox,Sender,Subject,Attachments,Status,Category,Tags\n";

        let sortedTable = this.gs.sortTable(this.groupEmails, sortBy);
        sortedTable.forEach((email) => {
			if (email.hide) {
				return;
			}
            let tags = '';
            if (email.malicious && email.malicious.tpResults) {
                tags = (email.malicious.tpResults.resHead && email.malicious.tpResults.resHead.sub_status && email.malicious.tpResults.resHead.sub_status.length && email.malicious.tpResults.resHead.status !== this.dic.CONSTANTS.threatProtection.status.safe && email.malicious.tpResults.resHead.sub_status.toString() + '. ' || '') +
                    (email.malicious.tpResults.resUrl && email.malicious.tpResults.resUrl.sub_status && email.malicious.tpResults.resUrl.sub_status.length && email.malicious.tpResults.resUrl.status !== this.dic.CONSTANTS.threatProtection.status.safe && email.malicious.tpResults.resUrl.sub_status.toString() + '. ' || '') +
                    (email.malicious.tpResults.resHash && email.malicious.tpResults.resHash.sub_status && email.malicious.tpResults.resHash.sub_status.length && email.malicious.tpResults.resHash.status !== this.dic.CONSTANTS.threatProtection.status.safe && email.malicious.tpResults.resHash.sub_status.toString()|| '');
            }

			const created = new Date(email.created);
			const createdStr = `${created.toLocaleDateString()} ${created.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })}`;
            csvString += `${createdStr},${email.mailbox_email},${email.sender},"${email.subject}","${email.attachments || 0}","${email.reason ? this.gs.toCapitalize(email.status)+' ('+email.reason+')' : this.gs.toCapitalize(email.status)}","${email.category}", "${tags}"\n`;
        });

        this.gs.exportCsv(csvString, `quarantined_emails.csv`);
    };

    exportHeadersText = () => {
        if (!this.curEmail.headers) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "";
        this.curEmail.headers.forEach((header) => {
			if (!header.hide) {
            	csvString += `${header.name || header.key}: ${header.value}\n`;
			}
        });

        this.gs.exportCsv(csvString, `quarantined_email_headers.txt`);
    };

    initFilters = () => {
        this.filterData = {
			filterType: this.dic.CONSTANTS.tableFilters.userQuarantined,
            filters: {
                status: ['Quarantined', 'Released by rule', 'Released by reviewer/recipient', 'Pending release', 'Requested release', 'Release failure', 'Removed by reviewer'],
                category: ['Malicious', 'Suspicious', 'Spam', 'Graymail', 'External', 'Safe'],
                'sub category': ['Allowlist', 'Blocklist', 'Business Email Compromise', 'Graymail', 'Impersonation', 'Malware', 'Phishing', 'Spam', 'Spoofing', 'User Preferences'],
                'Sender Type': ['External sender', 'Internal sender', 'Free email domain', 'Top domain', 'Unknown sender'],
                'has links': ['Yes', 'No'],
                'has attachments': ['Yes', 'No'],
				'Preserve record': ['Yes', 'No'],
				subject: ['Finance', 'Account', 'Notification', 'Validation', 'RFQ'],
                handled: [true, false],
            },
			tableFilterTemplatesRefName: this.dic.CONSTANTS.tableFilterTemplatesRefNames.quarantine,
			enableNegativeOptions: true,
			initFiltersFunction: this.initFilters,
        };
    };

	openNotifyOptionsPopup = () => {
		// create model for notifyReviewerPeriods dropdown selection (initial values from this.config)
		const notifyReviewerPeriods = {};
		_.forEach(this.dic.CONSTANTS.tpRule.notifyOptions,(periodName, periodKey) => {
			notifyReviewerPeriods[periodName] = this.mailboxInfo.notify_reviewer_periods && this.mailboxInfo.notify_reviewer_periods.includes(periodName);
		});
		let notifyReviewerDailyHours = [];
		if (this.mailboxInfo.notify_reviewer_daily_hour) {
			notifyReviewerDailyHours = this.mailboxInfo.notify_reviewer_daily_hour.split(', ');
			for (let i = 0; i < notifyReviewerDailyHours.length; i++) {
				let formattedHour:any = new Date();
				formattedHour.setUTCHours(notifyReviewerDailyHours[i]);
				formattedHour = (formattedHour.getHours() < 10 ? '0' : '') + formattedHour.getHours();
				notifyReviewerDailyHours[i] = formattedHour;
			}
		}

		this.notifyOptionsPopup = {
			periods: notifyReviewerPeriods,
			dailyHours: notifyReviewerDailyHours,
			isNotifyReviewerPeriodsError: false,
			isNotifyReviewerDailyHoursError: false,
			changeNotifyPeriodInProcess: false,
			show: true,
		};
	}

	searchReviewer = () => {
		this.curEmail?.reviewer_actions?.forEach(actionObj => {
			// search
			if (this.reviewerSearchTerm) {
				const isFound = searchReviewersExecute(actionObj, this.reviewerSearchTerm);
				if (!isFound) {
					actionObj.hide = true;
					return;
				}

			}
			actionObj.hide = false;
		});
	}

	searchHeader = () => {
		this.curEmail?.headers?.forEach(headerObj => {
			// search
			if (this.headerSearchTerm) {
				const isFound = searchHeadersExecute(headerObj, this.headerSearchTerm);
				if (!isFound) {
					headerObj.hide = true;
					return;
				}

			}
			headerObj.hide = false;
		});
	}

	changeNotifyPeriod = () => {
		if (this.notifyOptionsPopup.changeNotifyPeriodInProcess) {
			return;
		}

		// periods validation
		const isOneReviewerPeriodEnabled = _.some(this.notifyOptionsPopup.periods, period => {return !!period});
		if (!isOneReviewerPeriodEnabled) {
			this.notifyOptionsPopup.isNotifyReviewerPeriodsError = true;
		}

		this.notifyOptionsPopup.isNotifyReviewerDailyHoursError = this.notifyOptionsPopup.periods[this.dic.CONSTANTS.tpRule.notifyOptions.everyDay] && !this.notifyOptionsPopup.dailyHours.length;

		if (!isOneReviewerPeriodEnabled || this.notifyOptionsPopup.isNotifyReviewerDailyHoursError) {
			return;
		}
		//

		// make arrays of strings from the periods objects (pick only enabled periods)
		const notifyReviewerPeriodsArr = _.keys(_.pickBy(this.notifyOptionsPopup.periods));

		// turn the local hours in the "daily_hour" models into UTC hours for the BE
		let notifyReviewerDailyHoursUTC = _.cloneDeep(this.notifyOptionsPopup.dailyHours);
		for (let i =0 ; i < notifyReviewerDailyHoursUTC.length; i++) {
			let formattedHour:any = new Date();
			formattedHour.setHours(notifyReviewerDailyHoursUTC[i]);
			formattedHour = (formattedHour.getUTCHours() < 10 ? '0' : '') + formattedHour.getUTCHours();
			notifyReviewerDailyHoursUTC[i] = formattedHour;
		}
		//

		const data = {
			notifyReviewerPeriods: notifyReviewerPeriodsArr,
			notify_reviewer_daily_hour: notifyReviewerDailyHoursUTC.join(', '),
		};

		this.notifyOptionsPopup.changeNotifyPeriodInProcess = true;

		this.rs.updateMailboxNotification(data).then( (response) => {
			this.mailboxInfo.notify_reviewer_periods = notifyReviewerPeriodsArr;
			this.mailboxInfo.notify_reviewer_daily_hour = notifyReviewerDailyHoursUTC.join(', ');
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);

			this.notifyOptionsPopup = null;
		}, (err) => {
			if (err && err.data && err.data.message) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.notifyOptionsPopup.changeNotifyPeriodInProcess = false;
		});
	};

	getDailyHoursText = (listOfHours) => {
		return _.map(listOfHours, hourStr => hourStr + ':00');
	}

	toggleNotificationDailyHour = (listOfHours, targetHour) => {
		if (listOfHours.includes(targetHour)) {
			_.remove(listOfHours, hour => hour === targetHour);
		}
		else {
			listOfHours.push(targetHour);
		}
	}
}

function getEmailId(email) {
    return email.parent ? email.emails[0]._id : email._id;
}

function calcParentEmailCategory(parentEmail) {
    let emailPriority = parentEmail.emails[0];
    parentEmail.keep_forever = parentEmail.emails[0].keep_forever;
    parentEmail.emails.forEach((emailObj) => {
        if (emailObj.category !== emailPriority.category) {
            parentEmail.diffCategory = true;
        }
        if (emailObj.category_priority < emailPriority.category_priority) {
            emailPriority = emailObj;
        }
        parentEmail.keep_forever = parentEmail.keep_forever && emailObj.keep_forever;
    });

    parentEmail.malicious = emailPriority.malicious;
    parentEmail.reason = emailPriority.reason;
    parentEmail.category = emailPriority.category;
    parentEmail.color = emailPriority.color;
    parentEmail.category_priority = emailPriority.category_priority;
    parentEmail.status = emailPriority.status;
}

function searchFilterExecute(dic, email, filters) {
    // need to match all filter types
    let numFilterToMatch = Object.keys(filters).length;
    if (filters.status && filters.status.length) {
        if (filters.status.includes('Quarantined') && email.status === dic.CONSTANTS.quarantinedActions.quarantine.value) {
            numFilterToMatch--;
        } else if (filters.status.includes('Released by rule') && email.status === dic.CONSTANTS.quarantinedActions.release.value &&
            email.reason === 'Rule') {
            numFilterToMatch--;
        } else if (filters.status.includes('Released by reviewer/recipient') && email.status === dic.CONSTANTS.quarantinedActions.release.value &&
            email.reason !== 'Rule') {
            numFilterToMatch--;
        } else if (filters.status.includes('Removed by reviewer') && email.status === dic.CONSTANTS.quarantinedActions.remove.value &&
            email.reason !== 'Rule') {
            numFilterToMatch--;
        } else if (filters.status.includes('Pending release') && email.status === dic.CONSTANTS.quarantinedActions.pending.value) {
            numFilterToMatch--;
        } else if (filters.status.includes('Release failure') && email.status === dic.CONSTANTS.quarantinedActions.releaseFailure.value) {
            numFilterToMatch--;
        } else if (filters.status.includes('Requested release') && email.status === dic.CONSTANTS.quarantinedActions.requestRelease.value) {
            numFilterToMatch--;
        }
    }

    if (filters.category && filters.category.length) {
        if (filters.category.includes(email.category)) {
            numFilterToMatch--;
        }
    }

    if (filters['sub category'] && filters['sub category'].length) {
        if ((email.malicious.tpResults.resHead && filters['sub category'].includes(email.malicious.tpResults.resHead.category)) ||
            (email.malicious.tpResults.resHash && filters['sub category'].includes(email.malicious.tpResults.resHash.category)) ||
            (email.malicious.tpResults.resUrl && filters['sub category'].includes(email.malicious.tpResults.resUrl.category))) {
            numFilterToMatch--;
        }
    }

    if (filters['has links'] && filters['has links'].length) {
        if ((filters['has links'].includes('Yes') && email.links) ||
            (filters['has links'].includes('No') && !email.links)) {
            numFilterToMatch--;
        }
    }

	if (filters['has attachments'] && filters['has attachments'].length) {
        if ((filters['has attachments'].includes('Yes') && email.attachments) ||
            (filters['has attachments'].includes('No') && !email.attachments)) {
            numFilterToMatch--;
        }
    }

	if (filters['Preserve record'] && filters['Preserve record'].length) {
		if ((filters['Preserve record'].includes('Yes') && email.keep_forever) ||
			(filters['Preserve record'].includes('No') && !email.keep_forever)) {
			numFilterToMatch--;
		}
	}

    if (filters['reported by'] && filters['reported by'].length) {
        if ((filters['reported by'].includes('Reviewer') && email.report && email.report.report_by === 'reviewer') ||
            (filters['reported by'].includes('Recipient') && email.report && email.report.report_by === 'recipient')) {
            numFilterToMatch--;
        }
    }

    if (filters.handled && filters.handled.length) {
        if (filters.handled.includes(email.reviewed_completed)) {
            numFilterToMatch--;
        }
    }

    if (filters.follow && filters.follow.length) {
        if (filters.follow.includes(email.mark_follow)) {
            numFilterToMatch--;
        }
    }

    if (filters['Sender Type'] && filters['Sender Type'].length) {
        if (email.malicious.tpResults.resHead) {
            if ((filters['Sender Type'].includes('External sender') && email.malicious.tpResults.resHead.isExternal) ||
                (filters['Sender Type'].includes('Internal sender') && !email.malicious.tpResults.resHead.isExternal) ||
                (filters['Sender Type'].includes('Free email domain') && email.malicious.tpResults.resHead.isFreeEmail) ||
                (filters['Sender Type'].includes('Top domain') && (email.malicious.tpResults.resHead.isTopDomain || email.malicious.tpResults.resHead.isBrandService)) ||
				(filters['Sender Type'].includes('Unknown sender') && email.malicious.tpResults.resHead.isUnknownSender)) {
                numFilterToMatch--;
            }
        }
    }

    if (filters.subject && filters.subject.length) {
        if (email.subject) {
            let isSubjectFound = false;
            const emailLowerCase = email.subject.toLowerCase();
            for (let i = 0; i < filters.subject.length; i++) {
                if ((filters.subject[i] === 'Finance' && ['invoice', 'receipt', 'payment', 'statement', 'bill', 'fund', 'loan', 'deposit'].some(itm => emailLowerCase.includes(itm))) ||
                    (filters.subject[i] === 'Account' && ['activate', 'activities', 'activation', 'license', 'licensing', 'password'].some(itm => emailLowerCase.includes(itm))) ||
                    (filters.subject[i] === 'Notification' &&  ['notification', 'alert', 'report', 'require'].some(itm => emailLowerCase.includes(itm))) ||
                    (filters.subject[i] === 'Validation' && ['pending', 'verify', 'verified', 'verification','confirm', 'certificate', 'certification'].some(itm => emailLowerCase.includes(itm))) ||
                    (filters.subject[i] === 'RFQ' && ['rfq', 'request', 'quote', 'quotation', 'inquiry'].some(itm => emailLowerCase.includes(itm)))) {
                    isSubjectFound = true;
                }
            }
            if (isSubjectFound) {
                numFilterToMatch--;
            }
        }
    }

    return numFilterToMatch;
}

function searchTextExecute(email, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return ((email.mailbox_email && email.mailbox_email && email.mailbox_email[0].toLowerCase().indexOf(searchTerm) > -1) ||
        (email.sender && email.sender.toLowerCase().indexOf(searchTerm) > -1) ||
        (email.subject && ((email.subject.toLowerCase().indexOf(searchTerm) > -1)
            || (email.malicious.tpResults.resHead && email.malicious.tpResults.resHead.from && email.malicious.tpResults.resHead.from.senderMailFrom.toLowerCase().indexOf(searchTerm) > -1)
            || (email.malicious.tpResults.resHead && email.malicious.tpResults.resHead.from && email.malicious.tpResults.resHead.from.ipAddresses.includes(searchTerm)))) ||
        (email.message_id && email.message_id.toLowerCase().indexOf(searchTerm) > -1) ||
        (email.threatTags && email.threatTags.length && email.threatTags.find(itm => itm.name.toLowerCase().indexOf(searchTerm) > -1)));
}

function diffMinutes(dt2, dt1) {
    let diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}

function searchHeadersExecute(obj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((obj.name?.toLowerCase().indexOf(searchTerm) > -1) ||
		((obj.key?.toLowerCase().indexOf(searchTerm) > -1)) ||
		(obj.value?.toLowerCase().indexOf(searchTerm) > -1));
}

function searchReviewersExecute(obj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((obj.reviewer?.toLowerCase().indexOf(searchTerm) > -1) ||
		(obj.action?.toLowerCase().indexOf(searchTerm) > -1));
}
