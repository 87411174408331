<div class="flxClmn bs-enabled">

    <div class="page-title-container flxRow">
        <div class="flxClmn-center justify-content-between f-1-0">
            <div class="page-title-inner-container flxRow">
                <span class="page-title">Discovered Services</span>
                <span style="margin-left: 20px; background-color: green; color: white; font-size: 15px; padding: 5px 10px;">Beta</span>
            </div>
            <div class="page-subtitle-container">
                <span class="page-subtitle">
                    This page provides visibility into the apps and services used within the company, allowing administrators to assess potential threats and unnecessary exposure.
                </span>
            </div>
        </div>
        <div class="control-and-info-icons flxRow"></div>
    </div>

    <div class="page-inner-content-holder no-scroll flxClmn">
        <trustifi-table-c
                class="trustifi-table" style="flex: 1 0; z-index: 5;"
                [list]="discoveredServices"
                [headers]="[
                        {text: 'Service', sortKey: 'name'},
                        {text: 'Exposure Level', sortKey: 'data.risk', tooltip: riskyTooltip('all'), toolTipHtml: true},
                        {text: '# Mailboxes', sortKey: 'mailboxesCounter', width: '170px', tooltip: 'Number of mailboxes in your organization that interact with this service'},
                        {text: 'Last Seen', sortKey: 'updated', width: '15%'},
                    ]"
                [cellsPrototype]="[
                        {html: 'websiteCell'},
                        {html: 'riskyCell', overflowOutside: true},
                        {html: 'mailboxesCounterCell'},
                        {textKey: 'updated', textType: 'dateAndTime'},

                    ]"
                [cellActions]="{
					enable: true,
					selectItemActionCb:	selectAction,
					initActionsFunction: showServicesActions
				}"
                [options]="{
                        exportToCsvFunction: exportToCsv,
                        itemsNameSingular: 'service',
                        loadingTableFlag: getServicesInProcess,
                        defaultSortKey: '-mailboxesCounter'
                    }"
                [scopeInstances]="[{showMailboxesByServiceFromCell: showMailboxesByServiceFromCell}]"
                (searchFunction)="searchService($event)"
                [filterData]="filterData"
        >
			<ng-template #customCells let-item let-cellId="cellId">
				<!--mailboxesCounter cells-->
				<span>
					<a class="blue-link text-decoration-underline fw-bold d-block text-center" style="font-size: 1rem; width: 80px;"
					   *ngIf="cellId === 'mailboxesCounterCell'"
					   (click)="showMailboxesByServiceFromCell(item)">
						<span>{{item.mailboxesCounter}}</span>
					</a>
				</span>
				<!--Website cells-->
				<span style="padding-right: 80px; display: block;">
                    <a class="blue-link flex-row gap-2 align-items-center" href="{{item.website}}" target="_blank"
					   *ngIf="cellId === 'websiteCell'">
                        <img alt="" style="height: 20px;" *ngIf="item.domain" src="https://www.google.com/s2/favicons?domain={{item.domain}}">
                        <span>{{item.name}}</span>
                    </a>
                </span>
				<!--risk cells-->
				<div class="flxClmn h100" style="width: clamp(150px,11vw,240px); transform: translateX(calc(35px - 25%)); position: relative;" *ngIf="cellId === 'riskyCell'">
					<a class="blue-link blue-link-hover-hide" style="font-weight: 500; transform: translate(-100%, -50%); text-decoration: underline; position: absolute; top: 50%; left: -10px;"
					[tooltip]="riskyTooltip(item.data.risk)">
						{{riskyToString(item.data.risk)}}
					</a>
					<apx-chart [series]="item.riskyChartOptions.series"
							   [chart]="item.riskyChartOptions.chart"
							   [plotOptions]="item.riskyChartOptions.plotOptions"
							   [grid]="item.riskyChartOptions.grid"
							   [dataLabels]="item.riskyChartOptions.dataLabels"
							   [fill]="item.riskyChartOptions.fill"
							   [yaxis]="item.riskyChartOptions.yaxis"
							   [tooltip]="item.riskyChartOptions.tooltip"
							   [options]="{display: false}"></apx-chart>
				</div>
				<!---->
			</ng-template>
			<div tableButtons>
				<div class="flex-row">
					<div class="info-banner animated fadeIn" style="font-weight: normal; background: transparent; opacity: 0.9; font-size: 12px; padding-left: 60px;">
						No immediate action is required or available from this page. The goal of the information displayed below is only to show which services are being used in your organization.
					</div>
				</div>
			</div>
        </trustifi-table-c>
    </div>

    <!--view mailboxes for service popup-->
    <popup-c *ngIf="showMailboxesByServicePopup?.show"
           head="Mailboxes receiving emails from {{showMailboxesByServicePopup.serviceObj.data.name}}"
           (closeCb)="showMailboxesByServicePopup = null;"
           [buttons]="{cancel: 'OK'}" size="medium">
        <div class="flex-column f-1-0 gap-2" style="overflow: auto;">
            <trustifi-table-c
                    class="trustifi-table" style="flex: 1 0; z-index: 5;"
                    [list]="showMailboxesByServicePopup?.serviceObj.mailboxes"
                    [headers]="[
                                    {text: 'Last Seen', sortKey: 'updated', width: '30%'},
                                    {text: 'Email', sortKey: 'email'},
                                ]"
                    [cellsPrototype]="[
                                    {textKey: 'updated', textType: 'dateAndTime'},
                                    {textKey: 'email'},
                                ]"
                    [options]="{
                                    itemsNameSingular: 'user',
                                    defaultSortKey: '-updated'
                                }"
                    (searchFunction)="searchUser($event)"
            >
            </trustifi-table-c>
        </div>
    </popup-c>
</div>
