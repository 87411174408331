import {Component, OnInit} from '@angular/core';
import {DICTIONARY} from '../../../../dictionary';
import {GeneralService} from '../../../../services/generalService';
import {NotificationService} from '../../../../services/notificationService';
import {RouteService} from '../../../../services/routeService';
import _ from 'lodash';
import util from "util";

@Component({
  selector: 'account-compromised-reviewer-configurations',
  templateUrl: './account-compromised-reviewer-configurations.component.html',
})
export class AccountCompromisedReviewerConfigurationsComponent implements OnInit {
	dic = DICTIONARY;

	isLoaded = false;
	reviewers = [];
	extraReviewers = []
	accountCompromisedReviewerConfigurations: any;
	addReviewerInProcess: boolean;
	actionInProcess: boolean;
	selectedUsers: any[];
	usersSelectionPopup: any;
	reportAddress: string = '';
	notificationSubjectPlaceholder = '({{COMPANY_NAME}}) detected suspicious activity';
	notificationSubjectDesc = 'Dynamic fields like {{COMPANY_NAME}} can be used to show information about the email.\n' +
		'If this field is left empty, the default Trustifi subject will be used.';

	constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService) {
	}

	ngOnInit() {
		this.rs.getAccountCompromisedConfiguration().then(response => {
			this.reviewers = response.account_compromised.reviewers;
			this.extraReviewers = response.account_compromised.report_to_emails || [];

			this.accountCompromisedReviewerConfigurations = response.account_compromised;
			this.isLoaded = true;
		}, (err) => {

		});
	}

	selectMultipleReviewerAction = (selectedItems, action) => {
		switch (action) {
			case this.dic.CONSTANTS.accountCompromisedReviewerActions.allowViewContent:
			case this.dic.CONSTANTS.accountCompromisedReviewerActions.disallowViewContent:
				this.updateReviewerReviewersContentPermission(selectedItems, action);
				break;

			case _.capitalize(DICTIONARY.CONSTANTS.actions.remove):
				this.removeReviewers(selectedItems);
				break;
		}
	};

	showReviewerBulkActions = () => {
		return [
			{
				display: 'Allow/Disallow viewing content',
				submenu: [
					this.dic.CONSTANTS.accountCompromisedReviewerActions.allowViewContent,
					this.dic.CONSTANTS.accountCompromisedReviewerActions.disallowViewContent
				]
			},
			_.capitalize(DICTIONARY.CONSTANTS.actions.remove)
		]
	}

	showReviewerActions = (reviewerObj) => {
		let actions = [
			reviewerObj.allow_view_content ? this.dic.CONSTANTS.accountCompromisedReviewerActions.disallowViewContent : this.dic.CONSTANTS.accountCompromisedReviewerActions.allowViewContent,
			_.capitalize(DICTIONARY.CONSTANTS.actions.remove)
		];

		return actions;
	}

	selectReviewerAction = (reviewerObj, action) => {
		switch (action) {
			case this.dic.CONSTANTS.accountCompromisedReviewerActions.allowViewContent:
			case this.dic.CONSTANTS.accountCompromisedReviewerActions.disallowViewContent:
				this.updateReviewerReviewersContentPermission([reviewerObj], action);
				break;
			case _.capitalize(DICTIONARY.CONSTANTS.actions.remove):
				this.removeReviewers([reviewerObj]);
				break;
		}
	};

	updateReviewerReviewersContentPermission = (reviewers, action) => { // action: allow/block
		let counter = 0; // indicator to know all tasks are done
		reviewers.forEach((reviewer) => {
			const actionInfo = {
				action: this.dic.CONSTANTS.accountCompromisedIncidentActions.viewContent.value,
				enabled: action === this.dic.CONSTANTS.accountCompromisedReviewerActions.allowViewContent
			};

			// change permission state for reviewer only if desired "allow_view_content" state is different from current
			if ((action === DICTIONARY.CONSTANTS.accountCompromisedReviewerActions.allowViewContent && !reviewer.allow_view_content)
				|| (action === DICTIONARY.CONSTANTS.accountCompromisedReviewerActions.disallowViewContent && reviewer.allow_view_content)) {
				this.rs.updateAccountCompromisedReviewer(reviewer.email, actionInfo).then(() => {
					reviewer.allow_view_content = !reviewer.allow_view_content;
					counter++;
					if (counter === reviewers.length) {
						this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
					}
				});
			} else {
				counter++;
				if (counter === reviewers.length) {
					this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
				}
			}
		});
	}

	removeReviewers = (reviewers) => {
		if (!reviewers || !reviewers.length) {
			return;
		}

		let title, subTitle, body;
		if (reviewers.length === 1) {
			body = ['User will not have permission to review Account Takeover incidents anymore.'];
			title = 'Remove reviewing permission';
			subTitle = `Remove ${reviewers[0].email} permission as a reviewer from incidents`;
		}
		else {
			body = [`Review permission of ${reviewers.length} users will be removed from this case.`];
			title = `Remove reviewing permission`;
			subTitle = `Remove reviewing permission - ${reviewers.length} users`;
		}

		this.gs.showPopup({
			title: title,
			subTitle: subTitle,
			body: body,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Remove',
			doneCb: () => {
				_.map(reviewers, reviewer => {
					const _reviewerObj = _.find<any>(this.reviewers, itm => itm.email === reviewer.email);
					if (_reviewerObj) {
						this.rs.deleteAccountCompromisedReviewer(reviewer.email).then(res => {
							_.remove<any>(this.reviewers, _reviewerObj);

							this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', reviewer.email));
						});
					}
				});
			}
		});
	};

	searchReviewer = (searchTerm, activeFilters) => {
		this.reviewers.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	};

	startAddReviewer() {
		if (this.addReviewerInProcess) {
			return;
		}

		this.addReviewerInProcess = true;

		const newReviewer = {
			isEditMode: true,
			isNew: true,
			allow_view_content: false,
			edit: {
				email: '',
			}
		};

		this.reviewers.unshift(newReviewer);
	};

	startAddFromUsersSelection = (users) => {
		// Step 1: Filter out existing reviewers
		const existingReviewerEmails = this.reviewers.map(reviewer => reviewer.email);
		const filteredUsers = users.filter(user => !existingReviewerEmails.includes(user));

		// Step 2: Map filtered users to new reviewers structure
		const newReviewers = filteredUsers.map(user => ({
			email: user,
			isEditMode: false,
			isNew: true,
			allow_view_content: false,
			edit: {
				email: user,
			}
		}));

		// Step 3: Add new reviewers if any
		if (newReviewers.length > 0) {
			newReviewers.forEach(user => {
				this.rs.addAccountCompromisedReviewer(user.email).then(res => {
					this.reviewers.push({email: user.email, allow_view_content: false});
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, user.email));
				});
			});
		}
	}

	startRemoveFromUsersSelection = (users) => {
		const reviewersToRemove = this.reviewers.filter(reviewer => users.includes(reviewer.email));

		if (reviewersToRemove.length > 0) {
			this.removeReviewers(reviewersToRemove);
		}
	}

	openUsersSelection = () => {
		this.selectedUsers = _.map(this.reviewers, 'email');
		this.usersSelectionPopup = {
			show: true
		};
	}

	confirmAddReviewer = (reviewer ,isApproved) => {
		// new reviewer process was declined
		if (!isApproved) {
			_.remove<any>(this.reviewers, reviewer);
			this.addReviewerInProcess = false;
			return;
		}
		//

		// new reviewer approved
		if (!reviewer.edit.email) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Reviewer email field'));
		}
		if (!this.gs.validateEmail(reviewer.edit.email)) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.invalidEmail, reviewer.edit.email));
		}
		if (_.find(this.reviewers, rev => {return !rev.isNew && rev.email && rev.email === reviewer.edit.email})) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailAlreadyExist, reviewer.edit.email));
		}

		reviewer.confirmEditInProcess = true;
		const emailFormatted = reviewer.edit.email.toLowerCase()
		this.rs.addAccountCompromisedReviewer(emailFormatted).then(res => {
			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));
			reviewer.email = emailFormatted;
			reviewer.edit = null;
			reviewer.confirmEditInProcess = false
			reviewer.isEditMode = false;
			reviewer.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit

			this.addReviewerInProcess = false;
		}, (err) => {
			reviewer.confirmEditInProcess = false
			this.addReviewerInProcess = false;
		});
	};


	addReportAddress(address) {
		if (!address) {
			return;
		}

		address = address.toLowerCase();
		if (this.extraReviewers.find(itm => itm === address)) {
			this.ns.showErrorMessage(util.format(this.dic.ERRORS.itemAlreadyExist, address));
			return;
		}

		const actionInfo = {
			type: this.dic.CONSTANTS.actions.add
		};
		this.rs.updateExtraReportReviewers(address, actionInfo).then(() => {
			this.extraReviewers.push(address);
			this.reportAddress = '';
		});
	}

	deleteReportAddress(address) {
		if (!address) {
			return;
		}

		address = address.toLowerCase();
		if (!this.extraReviewers.find(itm => itm === address)) {
			return;
		}

		const actionInfo = {
			type: this.dic.CONSTANTS.actions.delete
		};
		this.rs.updateExtraReportReviewers(address, actionInfo).then(() => {
			this.extraReviewers = this.extraReviewers.filter(item => item !== address);
		});
	}

	updateNotificationSubject = () => {
		const data = {
			action: this.dic.CONSTANTS.accountCompromisedConfigActions.notifySubject,
			title: this.accountCompromisedReviewerConfigurations.notify_subject
		};

		this.rs.updateAccountCompromisedConfig(data).then(response => {
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		});
	}
}


function searchTextExecute(reviewer, searchTerm) {
	return (reviewer.email && reviewer.email.toLowerCase().indexOf(searchTerm) > -1);
}
