<div class="flxClmn">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Contacts</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Review and manage your contacts</span>
        </div>
    </div>
    <div class="page-inner-content-holder no-scroll flxClmn">
        <!--contacts table-->
        <trustifi-table-c
                class="trustifi-table" style="flex: 1 0; z-index: 5;"
                [list]="contacts"
                [headers]="[
                {text:'Name', sortKey:'name'},
                {text:'Email', sortKey:'email'},
                {text:'Phone Number', width: '240px'},
                {text:'MFA Password', width: 'min(210px,10vw)' },
                {text:'MFA Hint'},
            ]"
                [cellsPrototype]="[
                {textKey: 'name', edit: {modelKey:'name', placeholder:'Name'}},
                {textKey: 'email', edit: {modelKey:'email', placeholder:'Email address'}},
                {html:'phoneNumberCell', overflowOutside: true, edit: {html:'mfaPhoneCellEdit'}},
                {html: 'mfaPasswordCell', edit: {modelKey:'mfaPassword', placeholder:'Password'}},
                {html: 'mfaHintCell', edit: {modelKey:'mfaHint', placeholder:'Hint'}},
            ]"
                [bulkActions] = "{
					enable: true,
					selectBulkActionCb:	selectMultipleAction,
					initBulkActionsFunction: showContactsBulkActions
				}"
                [cellActions] = "{
					enable: true,
					selectItemActionCb:	selectAction,
					initActionsFunction: showContactActions
				}"
                (searchFunction)="searchContacts($event)"
                (confirmEditFunction)="confirmEdit($event)"
                [options]="{
                exportToCsvFunction: exportToCsv,
                loadingTableFlag: loadingContactsInProcess,
                itemsNameSingular: 'contact',
                defaultSortKey: 'name',
            }"
        >
			<ng-template #customCells let-item let-cellId="cellId">
				<!--phone number cells-->
				<div *ngIf="cellId === 'phoneNumberCell'">
					<span *ngIf="item.phone && item.phone.phone_number">
                    	+{{ item.phone.country_code }}-{{item.phone.phone_number}}
					</span>
					<span *ngIf="!item.phone || !item.phone.phone_number">
						-
					</span>
				</div>
				<!--MFA password cells-->
				<div *ngIf="cellId === 'mfaPasswordCell'">
					<span>{{item.default_password && item.default_password.password ? '*'.repeat(item.default_password.password.length - 1) + item.default_password.password[item.default_password.password.length - 1] : '-'}}</span>
				</div>
				<!--MFA hint cells-->
				<div *ngIf="cellId === 'mfaHintCell'">
					<span>{{item.default_password?.hint || '-'}}</span>
				</div>
				<!--phone number cell - EDIT MODE-->
				<div *ngIf="cellId === 'mfaPhoneCellEdit'">
					<span class="flxRow hSpace05 h100" style="overflow: visible;">
						<country-code-select [(model)]="item.edit.phone.country_code" ></country-code-select>
						<input id="contactsAddPhone"
							   type="text" placeholder="Phone number"
							   style="flex: 1 0; align-self: stretch; margin-right: 0; margin-bottom: 0;"
							   pattern="/^[0-9]*$/"
							   maxlength="11"
							   [value]="item.edit.phone.phone_number"
							   [(ngModel)]="item.edit.phone.phone_number">
					</span>
				</div>
			</ng-template>

            <!---->
			<div tableButtons>
                <div class="flxRow hSpace05" style="align-items: center;">
                    <btn-c endIcon="fa fa-plus"
                         [isDisabled]="loadingContactsInProcess || isAddContactActive"
                         (action)="startAddContact();">
                        Add Contact
                    </btn-c>
                    <btn-c *ngIf="userInfo.user_role === dic.CONSTANTS.userRole.admin
                         || userInfo.user_role === dic.CONSTANTS.userRole.subAdmin"
                            endIcon="fa fa-sync"
                         [isDisabled]="loadingContactsInProcess"
                         (action)="syncContactsWithAllUsers();">
                        Sync Contacts
                    </btn-c>
                    <app-dropdown-c style="width: 150px;"
                                  [isDisabled]="loadingContactsInProcess"
                                  [isLf]="true"
                                  text="Import Contacts">
                        <li (click)="openUploadListPopup()">
                            Import From CSV
                        </li>
                        <li (click)="cloudspongeLaunch()">
                            Import From Address Book
                        </li>
                    </app-dropdown-c>
                </div>
            </div>
        </trustifi-table-c>
    </div>
</div>

