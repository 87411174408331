<popup-c class="bs-enabled no-content-padding threat-simulation-wizard-popup"
		 head="{{!relaunchCampaign ? 'Create ' : ''}}{{isTrainingCampaign ? 'Training' : 'Threat Simulation'}} Campaign"
		 size="large" width="1100px"
		 (closeCb)="createdCampaignId ? doneCb.emit({newCampaignId: createdCampaignId}) : cancelCb.emit()">
	<div class="flex-column f-1-0 pt-2 gap-2" style="padding: 20px; overflow: auto;">
		<!--steps progress-->
		<div class="steps-container" *ngIf="!launchedSuccessfully">
			<div class="step-tab"
				 *ngFor="let wizardStep of dic.CONSTANTS.threatSimulationNewCampaignSteps | keyvalue: gs.returnZero"
				 [ngClass]="{'active' : step === wizardStep.value}">
				{{isTrainingCampaign && wizardStep.value === dic.CONSTANTS.threatSimulationNewCampaignSteps.selectTemplate ?
				'Select Material' : wizardStep.value}}
			</div>
		</div>

		<!--Steps content:-->

		<!--STEP: General information-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-3 pt-4"
			 style="line-height: 2;"
			 *ngIf="step === dic.CONSTANTS.threatSimulationNewCampaignSteps.generalInformation">
			<div class="flex-column gap-3">
				<span style="position: relative; padding-left: 15px;" class="diamond-indent">
					{{relaunchCampaign ? 'Relaunch ': 'Create a new '}}
					{{isTrainingCampaign ?  'training campaign and provide knowledge and tools to help your users recognize and avoid phishing emails':
											'Threat Simulation campaign and test your organization\'s resilience by sending realistic fake phishing emails.'}}
				</span>
				<span style="position: relative; padding-left: 15px;" class="diamond-indent">
					{{isTrainingCampaign ?  'Analyze the results to evaluate employee engagement with the training materials.':
											'Analyze the results to evaluate employee response rates and assess the risk posed by phishing attacks.'}}
				</span>
			</div>

			<div class="list-group list-group-flush settings-list-container">
				<div class="list-group-item settings-list-item column-style gap-3 py-4 pt-0">
					<div class="title-section-container flex-column gap-2">
						<b>Campaign Name and Description</b>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flex-column gap-2 p-2">
							<div class="flex-row gap-2 align-items-center">
								<span style="flex-basis: 93px;">Name:</span>
								<input class="m-0" type="text" style="width: 100%; max-width: 450px;"
									   [(ngModel)]="data.name"
									   [ngClass]="{'validation-error' : popupError && !data.name}"
									   (keyup)="popupError = ''"
									   placeholder="Enter name for campaign">
							</div>
							<div class="flex-row gap-2 align-items-start">
								<span style="flex-basis: 93px;">Description:</span>
								<textarea class="m-0 color-equalized" style="width: 100%; max-width: 450px; height: 70px;"
										  placeholder="Enter description for campaign"
										  [(ngModel)]="data.description"></textarea>
							</div>
						</div>
					</div>
				</div>
				<div class="list-group-item settings-list-item column-style gap-3 py-4">
					<div class="title-section-container flex-column">
						<b>Assign Campaign to Group</b>
						<span class="lh-base" style="font-size: 0.9em;">
							Organize and analyze your {{isTrainingCampaign ? 'training' : 'phishing'}} campaigns efficiently by grouping them together.<br/>
							Create new groups or add this campaign to an existing one to easily track statistics and summaries for individual campaigns or the entire group.
						</span>
					</div>

					<div class="content-container flex-column gap-2" style="line-height: 1.2;">
						<div class="flex-row align-items-center w100">
							<label class="radio-container" style="flex-basis: 130px; margin: 0;">
								Do not assign
								<input type="radio" name="groupInput" [value]="'disabled'"
									   [(ngModel)]="data.addToGroup.method"
									   (ngModelChange)="popupError = ''">
								<span class="checkmark"></span>
							</label>
						</div>
						<div class="flex-row align-items-center w100" [ngClass]="{'disabled-overlay' : !allCampaignGroups.length}">
							<label class="radio-container" style="flex-basis: 130px; margin: 0;" (click)="!allCampaignGroups.length ? $event.preventDefault() : null;">
								Existing group:
								<input type="radio" name="groupInput" [value]="'existing'"
									   [(ngModel)]="data.addToGroup.method"
									   (ngModelChange)="popupError = ''">
								<span class="checkmark"></span>
							</label>
							<span *ngIf="!allCampaignGroups.length">No existing groups.</span>
							<app-dropdown-c style="width: 200px; --bs-dropdown-menu-max-height: 130px;"
											*ngIf="allCampaignGroups.length"
											[alwaysDrop]="true"
											[isDisabled]="data.addToGroup.method !== 'existing'"
											[text]="data.addToGroup.groupName || ''"
											[items]="allCampaignGroups"
											itemDisplayKey="name" [enableSearch]="true"
											[error]="popupError && data.addToGroup.method === 'existing' && !data.addToGroup.groupName"
											(onItemClicked)="data.addToGroup.groupName = $event.item.name; popupError = '';">
							</app-dropdown-c>
						</div>

						<div class="flex-row align-items-center w100">
							<label class="radio-container" style="flex-basis: 130px; margin: 0;">
								New group:
								<input type="radio" name="groupInput" [value]="'new'"
									   [(ngModel)]="data.addToGroup.method"
									   (ngModelChange)="popupError = ''">
								<span class="checkmark"></span>
							</label>
							<div class="flex-row align-items-center gap-2 f-1-0">
								<input type="text" style="width: 200px;"
									   (keyup)="popupError = '';"
									   [disabled]="data.addToGroup.method !== 'new'"
									   [ngClass]="{'disabled' : data.addToGroup.method !== 'new', 'validation-error' : popupError && data.addToGroup.method === 'new' && !data.addToGroup.newGroupName}"
									   [(ngModel)]="data.addToGroup.newGroupName">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--STEP: Select Material-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-3 pt-4" *ngIf="isTrainingCampaign"
			 [hidden]="step !== dic.CONSTANTS.threatSimulationNewCampaignSteps.selectTemplate">
			<div class="flex-row gap-2 align-items-center">
				<span style="flex-basis: 150px; flex-shrink: 0;">Select training material:</span>
				<app-dropdown-c style="width: 250px;"
								[items]="materials"
								[alwaysDrop]="true"
								[error]="popupError && !this.data.selectedTemplate"
								itemDisplayKey="name"
								[enableSearch]="true"
								(onItemClicked)="selectTemplate($event.item, false);"
								text="{{data.selectedTemplate?.name || 'Select'}}"></app-dropdown-c>
				<btn-c style="width: 110px;" *ngIf="data.selectedTemplate" (action)="testTemplate(data.selectedTemplate)">Test Material</btn-c>
				<div class="loading-spinner" style="position: relative; left: unset; top: unset; width: 18px; height: 18px; border-width: 3px;" *ngIf="loadCampaignDataInProcess"></div>
			</div>

			<div class="flex-row f-1-0 overflow-auto animated fadeIn ts-wizard-template-screen">
				<div class="flex-column f-1-0 overflow-auto"
					 [ngClass]="{'me-3' : data.selectedTemplate}"
					 [ngStyle]="{'max-width' : data.selectedTemplate ? 'calc(100% - 620px)' : '0',
					 			 'visibility' : data.selectedTemplate ? 'visible' : 'hidden',
					 			 'opacity' : data.selectedTemplate ? '1' : '0',
					 			 'transition' : data.selectedTemplate ? 'max-width 0.4s, margin 0.4s, visibility 0.1s 0.4s, opacity 0.2s 0.4s' : 'none'}">
					<div class="flex-column gap-1 f-1-0 pe-2" *ngIf="data.selectedTemplate" style="font-size: 13.5px; line-height: 1.5;">
						<span class="fw-bold mb-2" style="font-size: 16px;">{{data.selectedTemplate.name}}</span>
						<div class="flex-row gap-1" *ngIf="data.selectedTemplate.type === 'link'">
							<span style="flex-shrink: 0; flex-basis: 86px; font-weight: 500;">URL:</span>
							<a class="blue-link text-decoration-underline break-word" target="_blank" [href]="data.selectedTemplate.link_url">{{data.selectedTemplate.link_url}}</a>
						</div>
						<div class="flex-row gap-1">
							<span style="flex-shrink: 0; flex-basis: 86px; font-weight: 500;">Description:</span>
							<span>{{data.selectedTemplate.description || '-'}}</span>
						</div>
					</div>
				</div>
				<div class="flex-column centralize overflow-auto f-1-0 position-relative" style="border: 1px solid #ccc; transition: all 0.4s; min-width: 620px;"
					 [ngStyle]="{'max-width' : data.selectedTemplate ? '620px' : '100%'}">
					<div class="flxClmn f-1-0 w-100 animated fadeIn overflow-auto" *ngIf="data.selectedTemplate && data.selectedTemplate.type === 'application/pdf' && !previewTemplateInProcess">
						<attachment-preview
							[attachment]="previewTemplateData.content"
							[isHeadless]="true">
						</attachment-preview>
					</div>
					<span class="fw-bold text-nowrap text-center text-muted" *ngIf="!data.selectedTemplate">Select material to preview</span>
					<iframe class="w-100 f-1-0"
							*ngIf="data.selectedTemplate?.type === 'link'"
							[src]="previewLinkMaterial"
							frameborder="0"
							allowfullscreen>
					</iframe>
					<div class="flex-column w100 f-1-0 border border-light border-1 position-relative overflow-hidden"
						 *ngIf="isTrainingCampaign && previewTemplateData?.pptUrl">
						<iframe
							class="f-1-0" style="z-index: 50; margin: -1px;"
							[src]="previewTemplateData.pptUrl"
							frameborder="0"
							allowfullscreen>
						</iframe>
						<span class="absolute-centralize loading-dots" style="z-index: 20;">Loading presentation</span>
					</div>
					<loader class="opacity-75" height="80" width="80" *ngIf="previewTemplateInProcess && data.selectedTemplate?.type !== 'link'"></loader>
				</div>
			</div>
		</div>

		<!--STEP: Select Template-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-3 pt-4" *ngIf="!isTrainingCampaign"
			 [hidden]="step !== dic.CONSTANTS.threatSimulationNewCampaignSteps.selectTemplate">
			<div class="flex-row gap-2 align-items-center">
				<span style="flex-basis: 150px; flex-shrink: 0;">Select phishing template:</span>
				<app-dropdown-c style="width: 250px;"
								#templatesDropdownEl
								[items]="templates"
								[enableSearch]="true"
								[error]="popupError && ((!this.isMultipleTemplates && !this.data.selectedTemplate) || (this.isMultipleTemplates && !_.some(this.templates, 'selected')))"
								[isMultipleSelection]="isMultipleTemplates"
								itemDisplayKey="name"
								(onItemClicked)="selectTemplate($event.item, $event.isItemSelectCheckboxClicked);"
								text="{{isMultipleTemplates ? '' : (data.selectedTemplate?.name || 'Select')}}"></app-dropdown-c>
				<btn-c style="width: 110px;" *ngIf="data.selectedTemplate" (action)="testTemplate(data.selectedTemplate)">Test Template</btn-c>
				<div class="loading-spinner" style="position: relative; left: unset; top: unset; width: 18px; height: 18px; border-width: 3px;" *ngIf="loadCampaignDataInProcess"></div>
				<div class="f-1-0"></div> <!--padder-->
				<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
					<input id="useMultipleTemplates" type="checkbox" [(ngModel)]="isMultipleTemplates" (ngModelChange)="toggleTemplatesDropdown();"/>
					<label style="margin: 0;" for="useMultipleTemplates">Use multiple templates</label>
					<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Select multiple templates to make the campaign less predictable. Recipients will receive a random template from the selected list." placement="right"></i>
				</div>
			</div>

			<div class="flex-row gap-2 align-items-center" *ngIf="data.selectedTemplate && !data.selectedTemplate._id">
				<span style="flex-basis: 150px; flex-shrink: 0;">Select a landing page:
					<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="The page which the user will see after they click on the phishing link"></i>
				</span>
				<app-dropdown-c style="width: 250px;"
								[isDisabled]="data.selectedTemplate.ts_type === dic.CONSTANTS.threatSimulationType.attachments.value"
								[items]="threatSimulationLandingPages"
								[enableSearch]="true"
								itemDisplayKey="display"
								(onItemClicked)="selectLandingPage($event.item);"
								text="{{data.selectedTemplate.ts_type?.display || data.selectedTemplate.ts_type || 'Training'}}"></app-dropdown-c>
				<btn-c style="width: 110px;" *ngIf="data.selectedTemplate && data.selectedTemplate.ts_type !== dic.CONSTANTS.threatSimulationType.attachments.value" (action)="previewLandingPage(data.selectedTemplate)">Preview</btn-c>
			</div>

			<div class="flex-row f-1-0 overflow-auto animated fadeIn ts-wizard-template-screen">
				<div class="flex-column f-1-0 overflow-auto"
					 [ngClass]="{'me-3' : data.selectedTemplate}"
					 [ngStyle]="{'max-width' : data.selectedTemplate ? 'calc(100% - 620px)' : '0',
					 			 'visibility' : data.selectedTemplate ? 'visible' : 'hidden',
					 			 'opacity' : data.selectedTemplate ? '1' : '0',
					 			 'transition' : data.selectedTemplate ? 'max-width 0.4s, margin 0.4s, visibility 0.1s 0.4s, opacity 0.2s 0.4s' : 'none'}">
					<div class="flex-column gap-1 f-1-0 pe-2" *ngIf="data.selectedTemplate" style="font-size: 13.5px; line-height: 1.5;">
						<span class="fw-bold mb-2" style="font-size: 16px;">{{data.selectedTemplate.name}}</span>
						<div class="flex-row gap-1">
							<span style="flex-shrink: 0; flex-basis: 86px; font-weight: 500;">Description:</span>
							<span>{{data.selectedTemplate.description}}</span>
						</div>
						<div class="flex-row gap-1" *ngIf="data.selectedTemplate.goal">
							<span style="flex-shrink: 0; flex-basis: 86px; font-weight: 500;">Goal:</span>
							<span>{{data.selectedTemplate.goal}}</span>
						</div>

						<div class="flex-row gap-1 mt-1 align-items-center" *ngIf="data.selectedTemplate.attack_vector" >
							<span style="flex-shrink: 0; flex-basis: 86px; font-weight: 500;">Attack Vector:</span>
							<div class="flxRow gap-1" style="align-items: center;">
								<div class="flxRow threat-tag not-clickable darkred hvr-opacity m-0 hSpace05" style="align-items: center; color: darkred;">
									<div class="threat-tag-BG"></div>
									<i class="fa fa-bug threat-tag-icon"></i>
									<span class="threat-tag-text">{{data.selectedTemplate.attack_vector.name}}</span>
								</div>
								<i style="font-weight: 400;" class="fas fa-question-circle" [tooltip]="data.selectedTemplate.attack_vector.description"></i>
							</div>
						</div>

						<div class="flex-row gap-1 mt-1" *ngIf="data.selectedTemplate?.techniques">
							<span style="flex-shrink: 0; flex-basis: 86px; font-weight: 500; margin-top: 6px;">Techniques:</span>
							<div class="flxRow gap-2" style=" align-items: center; flex-wrap: wrap;">
								<div class="flxRow gap-1" style="align-items: center;" *ngFor="let technique of data.selectedTemplate.techniques">
									<div class="flxRow threat-tag not-clickable dimgray hvr-opacity m-0 hSpace05" style="align-items: center; color: dimgray;">
										<div class="threat-tag-BG"></div>
										<span class="threat-tag-text">{{technique.name}}</span>
									</div>
									<i style="font-weight: 400;" class="fas fa-question-circle" [tooltip]="technique.description"></i>
								</div>
							</div>
						</div>

						<br/>
						<span class="fw-500 mb-2 text-decoration-underline">Email Details:</span>
						<div class="flex-row gap-1">
							<span style="flex-shrink: 0; flex-basis: 86px; font-weight: 500;">Subject:</span>
							<span>{{data.selectedTemplate.title}}</span>
						</div>
						<div class="flex-row gap-1">
							<span style="flex-shrink: 0; flex-basis: 86px; font-weight: 500;">From:</span>
							<div class="flex-column gap-1" *ngIf="data.selectedTemplate.from.name && data.selectedTemplate.from.address && data.selectedTemplate.from.name !== data.selectedTemplate.from.address">
								<span>{{data.selectedTemplate.from.name}}</span>
								<span><{{data.selectedTemplate.from.address}}></span>
							</div>
							<span *ngIf="!data.selectedTemplate.from.name || !data.selectedTemplate.from.address || data.selectedTemplate.from.name === data.selectedTemplate.from.address">{{data.selectedTemplate.from.name || data.selectedTemplate.from.address}}</span>
						</div>
						<div class="flex-row gap-1">
							<span style="flex-shrink: 0; flex-basis: 86px; font-weight: 500;">Reply to:</span>
							<div class="flex-column gap-1" *ngIf="data.selectedTemplate.reply_to.name && data.selectedTemplate.reply_to.address && data.selectedTemplate.reply_to.name !== data.selectedTemplate.reply_to.address">
								<span>{{data.selectedTemplate.reply_to.name}}</span>
								<span><{{data.selectedTemplate.reply_to.address}}></span>
							</div>
							<span *ngIf="!data.selectedTemplate.reply_to.name || !data.selectedTemplate.reply_to.address || data.selectedTemplate.reply_to.name === data.selectedTemplate.reply_to.address">{{data.selectedTemplate.reply_to.name || data.selectedTemplate.reply_to.address}}</span>
						</div>
						<br/>
						<div class="flex-row mb-2 gap-2 align-items-center flex-shrink-0">
							<span class="fw-500 text-decoration-underline">Tracked Links</span>
							<i style="font-weight: 400;" class="fas fa-question-circle"
							   tooltip="These links will be tracked to detect which users have clicked on them. The links must be added to the template below, and only the links specified here will be tracked"></i>
						</div>
						<div class="flex-column gap-1">
							<div class="flex-row align-items-center gap-2" *ngFor="let trackedLink of data.selectedTemplate.lookup_links">
								<span class="fw-bold px-2 py-1 flex-shrink-0">{{trackedLink.name}}</span>
								<a class="fw-light ellipsis hvr-underline" [href]="trackedLink.link" target="_blank" style="font-size: 12px;">{{trackedLink.link}}</a>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-column centralize overflow-auto f-1-0 position-relative" style="border: 1px solid #ccc; transition: all 0.4s; min-width: 620px;"
					 [ngStyle]="{'max-width' : data.selectedTemplate ? '620px' : '100%'}">
					<div class="w-100" style="flex:1 0; overflow: auto;" [hidden]="!data.selectedTemplate || previewTemplateInProcess">
						<email-viewer [content]="previewTemplateData.content" style="min-height: 100%;"></email-viewer>
					</div>
					<!--hidden iframe for screenshots-->
					<div style="overflow: auto; width: 100%; max-width: 620px; position: absolute; height: 100%; visibility: hidden;"
						 [hidden]="previewTemplateInProcess">
						<email-viewer [content]="previewTemplateData.virtualContent" (onLoad)="captureTemplateScreenshot($event.iframeDoc)" style="min-height: 100%;"></email-viewer>
					</div>
					<!---->
					<span class="fw-bold text-nowrap text-center text-muted" *ngIf="!data.selectedTemplate">Select template to preview</span>
					<loader class="opacity-75" height="80" width="80" *ngIf="previewTemplateInProcess"></loader>

					<!--template's attachments-->
					<div class="flex-row flex-wrap overflow-auto f-1-0 align-self-stretch border-top" style="max-height: 66px;"
						 *ngIf="data.selectedTemplate?.attachments?.length">
						<ng-container *ngFor="let file of data.selectedTemplate.attachments">
							<attachment-c
								[attName]="file.name"
								[attSize]="file.size"
								(preview)="selectedTemplatePreviewAttachment = file;"
								(download)="downloadAttachment(file.content, file.name, file.type)">
							</attachment-c>
						</ng-container>
					</div>
				</div>
			</div>
		</div>

		<!--STEP: Select Recipients-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-2 p-3 pt-4"
			 *ngIf="step === dic.CONSTANTS.threatSimulationNewCampaignSteps.selectRecipients">
			<span>Select recipients for campaign:</span>
			<div class="flex-column f-1-0 gap-3 overflow-auto">
				<trustifi-table-c
					class="trustifi-table f-1-0" style="z-index: 5;"
					#recipientsTableEl
					[list]="allPossibleRecipients"
					[headers]="[
						{text:'Name', sortKey: 'name'},
						{text:'Email', sortKey: 'email'},
					]"
					[cellsPrototype]="[
					  {html: 'nameCell'},
					  {textKey: 'email'},
					]"
					(searchFunction)="searchCampaignRecipients($event.searchTerm, $event.activeFilters)"
					[subRowOptions]="{
							recursionKey: 'members',
							allowActions: false
						}"
					[options]="{
						loadingTableFlag: loadCampaignDataInProcess,
						defaultSortKey: '-name',
						itemUniqueIdentifier: '_id',
						showRadioButtons: true,
					}"
				>
					<ng-template #customCells let-item let-cellId="cellId">
						<!--name cell-->
						<span *ngIf="cellId === 'nameCell'">
							<div class="flxRow hSpace05" style="align-items: center; cursor: pointer;"
								 (click)="toggleExpandItem(item, $event);">
								<i class="fa fa-chevron-right" style="color: royalblue; transition: transform 0.15s ease-out; padding: 5px;"
								   *ngIf="item.members?.length"
								   [ngStyle]="{'transform' : item.isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}"></i>
								<span style="padding: 7px 0;" [ngStyle]="{'margin-left' : item.members?.length || !item.depthLevel ? '' : '30px'}">
									{{item.name || item.email}}
								</span>
							</div>
						</span>
					</ng-template>

					<div tableButtons>
						<div class="flex-row gap-2">
							<div class="flex-row align-items-center p-2 cursor-pointer hvr-opacity" style="border: solid 1px #cccccc; align-self: flex-end;"
								 (click)="showMailboxes = !showMailboxes; recipientsTableEl.searchItem();"
								 [ngClass]="{'color-text bg-primary' : showMailboxes}">
								<div class="gap-1 md-checkbox">
									<input type="checkbox" style="height: 0;" onclick="return false;" [checked]="showMailboxes"/>
									<label><span>Show mailboxes</span></label>
								</div>
							</div>
							<div class="flex-row align-items-center p-2 cursor-pointer hvr-opacity" style="border: solid 1px #cccccc; align-self: flex-end;"
								 (click)="showGroups = !showGroups; recipientsTableEl.searchItem();"
								 [ngClass]="{'color-text bg-primary' : showGroups}">
								<div class="gap-1 md-checkbox">
									<input type="checkbox" style="height: 0;" onclick="return false;" [checked]="showGroups"/>
									<label><span>Show groups</span></label>
								</div>
							</div>
						</div>
					</div>
				</trustifi-table-c>
				<div class="flex-row flex-wrap flex-shrink-0 overflow-auto justify-content-start gap-2 align-items-baseline" style="height: 62px; padding-left: 0.3rem; padding-right: 0.3rem;">
					<div *ngFor="let recipient of _.filter(allPossibleRecipients, 'selected'); trackBy: gs.trackByIndex"
						 class="p-1 d-flex hSpace05 align-items-center rounded-1 bg-dark"
						 (click)="deselectRecipient(recipient);"
						 style="cursor: pointer; --bs-bg-opacity: .07; line-height: 16px;">
						<span [ngStyle]="{'font-weight' : !recipient.members?.length ? '' : '500'}">{{recipient.email}}</span>
						<span style="font-weight: 300; font-size: 12px;" *ngIf="recipient.members?.length"> (group)</span>
						<i class="zmdi zmdi-close"></i>
					</div>
				</div>
			</div>
		</div>

		<!--STEP: Configuration-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-2 pt-3"
			 *ngIf="step === dic.CONSTANTS.threatSimulationNewCampaignSteps.configuration">
			<div class="list-group list-group-flush settings-list-container f-1-0 overflow-auto">

				<!--Notify me when campaign is over-->
				<div class="list-group-item settings-list-item py-3">
					<div class="title-section-container flex-column gap-2">
						<b>Campaign Duration</b>
						<span class="lh-base" style="font-size: 0.9em;">Run this campaign for a certain amount of days. Actions performed by recipients will be registered only while the campaign is active.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flex-row gap-2 align-items-center">
							<input type="number" min="1" max="365" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
								   style="width: 50px;"
								   (keyup)="popupError = ''"
								   [ngClass]="{'validation-error' : popupError && !data.options.expired_days || data.options.expired_days < 1 || data.options.expired_days > 365}"
								   [(ngModel)]="data.options.expired_days">
							<span>days</span>
						</div>
					</div>
				</div>

				<!--Campaign pace-->
				<div class="list-group-item settings-list-item py-3">
					<div class="title-section-container flex-column gap-2">
						<b>Progressive Dispatch</b>
						<span class="lh-base" style="font-size: 0.9em;">Spreads out the campaign delivery over several hours, optimizing recipient engagement by delivering emails at intervals for better impact</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="enable_rate_email_delivery_hours_duration" type="checkbox"
								   [(ngModel)]="data.options.enable_rate_email_delivery_hours_duration"/>
							<label for="enable_rate_email_delivery_hours_duration" style="margin: 0;">
								<div class="flxRow hSpace2" style="align-items: center; height: 16px;">
									<span>Enable for a duration of</span>
									<input style="text-align: center;" type="number" min="1" max="365" step="1" pattern="^\d*(\.\d{0,3})?$"
                                           [(ngModel)]="data.options.rate_email_delivery_hours_duration"
                                           (change)="data.options.enable_rate_email_delivery_hours_duration = true;">
									<span>hours</span>
								</div>
							</label>
						</div>
					</div>
				</div>

				<!--schedule-->
				<div class="list-group-item settings-list-item py-3">
					<div class="title-section-container flex-column gap-2">
						<b>Scheduled Campaign Launch</b>
						<span class="lh-base" style="font-size: 0.9em;">Send the campaign to all recipients in a scheduled future time, instead of immediately.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="enableDefault" type="checkbox"
								   [(ngModel)]="data.options.scheduled_time_enabled"/>
							<label for="enableDefault" style="margin: 0;">Enable</label>
						</div>

						<div class="flex-row align-items-center gap-1" [ngClass]="{'disabled-overlay' : !data.options.scheduled_time_enabled}">
							<input type="datetime-local" [min]="minScheduleTime" [(ngModel)]="data.options.scheduled_time">
						</div>
					</div>
				</div>

				<!--Notify me when link is clicked-->
				<div class="list-group-item settings-list-item py-3">
					<div class="title-section-container flex-column gap-2">
						<b>Notify Me When Link Is Clicked</b>
						<span class="lh-base" style="font-size: 0.9em;">Receive a notification email whenever any recipient clicks on a tracked link in the simulation email.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="notifyOnLinkClicked" type="checkbox"
								   [(ngModel)]="data.options.notify_on_link_clicked"/>
							<label for="notifyOnLinkClicked" style="margin: 0;">Enable</label>
						</div>
					</div>
				</div>

				<!--Notify me when link is clicked-->
				<div class="list-group-item settings-list-item py-3" *ngIf="!isTrainingCampaign">
					<div class="title-section-container flex-column gap-2">
						<b>Notify Me When Attachment Is Downloaded</b>
						<span class="lh-base" style="font-size: 0.9em;">Receive a notification email whenever any recipient download an attachment in the simulation email.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="notify_on_attachment_clicked" type="checkbox"
								   [(ngModel)]="data.options.notify_on_attachment_clicked"/>
							<label for="notify_on_attachment_clicked" style="margin: 0;">Enable</label>
						</div>
					</div>
				</div>

				<!--Enable smart banner for mailbox-->
				<div class="list-group-item settings-list-item py-3" *ngIf="!isTrainingCampaign">
					<div class="title-section-container flex-column gap-2">
						<b>Auto-Enable Smart Banner</b>
						<span class="lh-base" style="font-size: 0.9em;">For users who fail the campaign and click on the tracked links, Smart Banners will be applied to all of their incoming emails for a set duration of time.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="smartBannerClick" type="checkbox"
								   [(ngModel)]="data.options.enable_security_awareness_for_mailbox"/>
							<label for="smartBannerClick" style="margin: 0;">
								<div class="flxRow hSpace2" style="align-items: center; height: 16px;">
									<span>Enable for a duration of</span>
									<input style="text-align: center;" type="number" min="1" max="365" step="1" pattern="^\d*(\.\d{0,3})?$"
                                           [ngClass]="{'validation-error' : (popupError && data.options.enable_security_awareness_for_mailbox) && !data.options.security_awareness_for_mailbox_expire_days || data.options.security_awareness_for_mailbox_expire_days < 1 || data.options.security_awareness_for_mailbox_expire_days > 365}"
                                           [(ngModel)]="data.options.security_awareness_for_mailbox_expire_days"
                                           (change)="data.options.enable_security_awareness_for_mailbox = true;">
									<span>days</span>
								</div>
							</label>
						</div>
					</div>
				</div>

				<!--Notify me when campaign is over-->
				<div class="list-group-item settings-list-item py-3">
					<div class="title-section-container flex-column gap-2">
						<b>Send Campaign Summary</b>
						<span class="lh-base" style="font-size: 0.9em;">Receive a an email with a summary of the campaign results automatically when the campaign duration has ended.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="notifyOnCampaignEnd" type="checkbox"
								   [(ngModel)]="data.options.send_report_when_completed"/>
							<label for="notifyOnCampaignEnd" style="margin: 0;">Enable</label>
						</div>
					</div>
				</div>

				<!--Training-->
				<div class="list-group-item settings-list-item py-3" *ngIf="!isTrainingCampaign">
					<div class="title-section-container flex-column gap-2">
						<b>Distribute Training Materials</b>
						<span class="lh-base" style="font-size: 0.9em;">Following the completion of the campaign, automatically distribute the training page link to individuals who have not yet opened it.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="send_training_when_completed" type="checkbox"
								   [(ngModel)]="data.options.send_training_when_completed"/>
							<label for="send_training_when_completed" style="margin: 0;">Enable</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--STEP: Summary & Launch-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 p-2 pt-0 position-relative"
			 style="font-size: 14px;"
			 [ngClass]="{'threat-simulation-wizard-summary pt-3' : !launchedSuccessfully}"
			 *ngIf="step === dic.CONSTANTS.threatSimulationNewCampaignSteps.summary">
			<ng-container *ngIf="!launchedSuccessfully">
				<div class="flex-column gap-3 p-3 py-4" style="background: linear-gradient(90deg, #486db51a, #486db500);">
					<div class="flex-row gap-2 align-items-baseline">
						<span class="flex-shrink-0" style="flex-basis: 125px;">Campaign name:</span>
						<span class="fw-bold" style="font-size: 16px;">{{data.name}}</span>
					</div>
					<div class="flex-row gap-2" *ngIf="data.description">
						<span class="flex-shrink-0" style="flex-basis: 125px;">Description:</span>
						<span>{{data.description}}</span>
					</div>
					<div class="flex-row gap-2">
						<span class="flex-shrink-0" style="flex-basis: 125px;">Duration:</span>
						<span>{{data.options.expired_days}} days</span>
					</div>
				</div>
				<div class="flex-column p-3 f-1-0" style="background: linear-gradient(-90deg, #486db51a, #486db500);">
					<span><u>Campaign {{isTrainingCampaign ? 'Material' : 'Template'}}{{isMultipleTemplates && _.filter(templates, 'selected').length > 1 ? 's' : ''}}</u>:</span>
					<div class="flex-row gap-2 f-1-0 align-items-center" *ngFor="let template of (isMultipleTemplates && _.filter(templates, 'selected').length > 1 ? _.filter(templates, 'selected') : [data.selectedTemplate]); trackBy: gs.trackByIndex; let first = first;"
						 [ngClass]="{'mt-4' : isMultipleTemplates && _.filter(templates, 'selected').length > 1}">
						<i class="flex-shrink-0 fa" style="font-size: 40px; color: #486db5; flex-basis: 125px; text-align: center;"
						   [ngClass]="{'fa-brush' : !isTrainingCampaign, 'far fa-file-pdf text-danger' : isTrainingCampaign && template.type !== 'link', 'fa-link' : isTrainingCampaign && template.type === 'link'}"
						   [style.visibility]="first ? 'visible' : 'hidden'"></i>
						<div class="flex-column gap-2 f-1-0 pe-4">
							<span class="fw-bold">{{template.name}}</span>
							<div class="flex-row" *ngIf="!isTrainingCampaign">
								<span class="flex-shrink-0" style="flex-basis: 100px;">Category:</span>
								<span class="text-muted">{{template.category}}</span>
							</div>
							<div class="flex-row" *ngIf="isTrainingCampaign && template.type === 'link'">
								<span class="flex-shrink-0" style="flex-basis: 100px;">URL:</span>
								<a class="blue-link text-decoration-underline break-word" target="_blank" [href]="template.link_url">{{template.link_url}}</a>
							</div>
							<div class="flex-row">
								<span class="flex-shrink-0" style="flex-basis: 100px;">Description:</span>
								<span class="text-muted">{{template.description}}</span>
							</div>
							<div class="flex-row" *ngIf="!isTrainingCampaign && template.ts_type">
								<span class="flex-shrink-0" style="flex-basis: 100px;" >Landing Page:</span>
								<span class="text-muted">{{template.ts_type.display || template.ts_type}}</span>
							</div>
						</div>
						<!--preview (template)-->
						<div *ngIf="!isTrainingCampaign"
							 [id]="'templateScreenshotContainer_' + template.name" style="border: solid 1px #cccccc; box-shadow: 0 0 5px lightgrey;"
							 [style.width]="isMultipleTemplates && _.filter(templates, 'selected').length > 1 ? '120px' : '200px'"
							 (ngInit)="drawTemplateScreenshot(template.name)"></div>
						<!--preview (material)-->
						<div *ngIf="isTrainingCampaign && template.type === 'application/pdf'"
							 class="flxClmn" style="border: solid 1px #cccccc; box-shadow: 0 0 5px lightgrey; width: 220px; max-width: 220px; height: 290px;">
							<attachment-preview
								[attachment]="previewTemplateData.content"
								[isHeadless]="true">
							</attachment-preview>
						</div>
						<div class="flex-column border border-light border-1 position-relative overflow-hidden"
							 *ngIf="isTrainingCampaign && previewTemplateData?.pptUrl">
							<iframe
								class="f-1-0" style="z-index: 50; margin: -1px;"
								[src]="previewTemplateData.pptUrl"
								frameborder="0"
								allowfullscreen>
							</iframe>
							<span class="absolute-centralize loading-dots" style="z-index: 20;">Loading presentation</span>
						</div>
					</div>
				</div>
				<div class="flex-column gap-2 p-3 f-1-0" style="background: linear-gradient(90deg, #486db51a, #486db500);">
					<span><u>Send to Recipients</u>:</span>
					<div class="flex-row gap-2 f-1-0 align-items-center">
						<i class="fa fa-users flex-shrink-0" style="font-size: 40px; color: #486db5; flex-basis: 125px; text-align: center;"></i>
						<div class="flex-row justify-content-start f-1-0 flex-wrap" style="align-self: flex-start; line-height: 1.3; font-size: 13px;">
							<ng-container *ngFor="let recipientOrGroup of data.selectedRecipients | slice:0:recipientsLimitInSummary; trackBy: gs.trackByIndex; let last = last;">
								<span *ngIf="recipientOrGroup.members"><b>{{recipientOrGroup.displayName}}</b> (group)</span>
								<span *ngIf="!recipientOrGroup.members">{{recipientOrGroup.email}}</span>
								{{last ? '' : ',&nbsp;'}}
							</ng-container>
							<span class="fw-bold" *ngIf="data.selectedRecipients.length > recipientsLimitInSummary">
							(+{{data.selectedRecipients.length - recipientsLimitInSummary}})
						</span>
							<a class="blue-link" *ngIf="data.selectedRecipients.length > recipientsLimitInSummary" (click)="recipientsLimitInSummary = 10000000">
								show all
							</a>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="launchedSuccessfully">
				<div class="bubbles-animation d-flex centralize">
					<div id="bubbles" style="height: 1000px; width: 520px;">
						<div *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" [ngClass]="'bubble x'+ i"></div>
					</div>
					<div class="animation-content" style="height: 200px; width: 200px;">
						<img id="phishingHookString" src="images/hook-string.png"/>
						<img id="phishingHook" src="images/phishing-hook.png"/>
					</div>
				</div>
				<span class="text-success text-center" style="font-size: 4vh; margin: 10vh 0 5vh;">Campaign Launched Successfully!</span>
				<span class="text-center" style="font-size: 2.5vh;">View the campaign "Summary" tab to see recipient engagement metrics</span>
			</ng-container>
		</div>

		<!--error message and buttons-->
		<div class="flex-row justify-content-between align-items-end mb-3 px-4 w-100">
			<ng-container *ngIf="!launchedSuccessfully">
				<div class="flex-column gap-2 text-danger">
					<span class="f-1-0 pe-3">{{popupError || ''}}</span>
					<span class="f-1-0 pe-3" *ngIf="data.selectedTemplate?.missing_dynamic_fields?.length">This template uses <i class="text-black text-decoration-underline cursor-default hvr-opacity" [tooltip]="data.selectedTemplate.missing_dynamic_fields.join(', ')">Dynamic Fields</i> that have not yet been defined. <a class="blue-link text-decoration-underline" (click)="showDynamicFieldsPopup = true;">Click here</a> to define these fields</span>
				</div>


				<div class="flex-row gap-2">
					<btn-c class="popup-action-btn"
						   *ngIf="step === dic.CONSTANTS.threatSimulationNewCampaignSteps.generalInformation"
						   variant="outlined" (action)="cancelCb.emit();">
						Cancel
					</btn-c>
					<btn-c class="popup-action-btn"
						   [isDisabled]="createNewCampaignInProcess"
						   *ngIf="step !== dic.CONSTANTS.threatSimulationNewCampaignSteps.generalInformation"
						   variant="outlined" (action)="backStep();">
						Back
					</btn-c>
					<btn-c class="popup-action-btn"
						   *ngIf="step !== dic.CONSTANTS.threatSimulationNewCampaignSteps.summary"
						   (action)="nextStep();">
						Next
					</btn-c>
					<btn-c class="popup-action-btn"
						   [loading]="createNewCampaignInProcess"
						   *ngIf="step === dic.CONSTANTS.threatSimulationNewCampaignSteps.summary"
						   (action)="isMultipleTemplates ? multipleTemplatesCampaignExecute() : newCampaignExecute(data);">
						{{ relaunchCampaign ? 'Relaunch' : 'Launch' }}
					</btn-c>
				</div>
			</ng-container>
			<ng-container *ngIf="launchedSuccessfully">
				<btn-c class="popup-action-btn m-auto"
					   endIcon="fa fa-share"
					   *ngIf="launchedSuccessfully"
					   (action)="doneCb.emit({newCampaignId: createdCampaignId});">
					Review Campaign Details
				</btn-c>
			</ng-container>
		</div>
	</div>
</popup-c>

<popup-c *ngIf="showDynamicFieldsPopup"
		 head="Configure Dynamic fields"
		 size="large" width="1200px"
		 (closeCb)="showDynamicFieldsPopup = false;">
	<threat-simulation-template-fields-component [missingFields]="data.selectedTemplate?.missing_dynamic_fields" (onSuccessfulUpdate)="updateDynamicFields($event.filledFieldsNames);"></threat-simulation-template-fields-component>
</popup-c>

<popup-c *ngIf="previewLandingPagePopup?.show"
		 head="Preview Material"
		 [subTitle]="previewLandingPagePopup?.material?.name"
		 size="medium"
		 (closeCb)="previewLandingPagePopup = null;">
	<div class="flex-column f-1-0 centralize" style="overflow: auto;">
		<div class="flxClmn f-1-0 w-100 animated fadeIn overflow-auto" *ngIf="previewLandingPagePopup.pdfContent">
			<attachment-preview
				[attachment]="previewLandingPagePopup.pdfContent"
				[isHeadless]="true">
			</attachment-preview>
		</div>
		<iframe class="w-100 f-1-0"
				*ngIf="previewLandingPagePopup.linkUrl"
				[src]="previewLandingPagePopup.linkUrl"
				frameborder="0"
				allowfullscreen>
		</iframe>
		<div class="flex-column w100 f-1-0 border border-light border-1 position-relative overflow-hidden"
			 *ngIf="previewLandingPagePopup.pptUrl">
			<iframe
				class="f-1-0" style="z-index: 50; margin: -1px;"
				[src]="previewLandingPagePopup.pptUrl"
				frameborder="0"
				allowfullscreen>
			</iframe>
			<span class="absolute-centralize loading-dots" style="z-index: 20;">Loading presentation</span>
		</div>
		<loader class="opacity-75" height="80" width="80" *ngIf="!previewLandingPagePopup.pptUrl && !previewLandingPagePopup.linkUrl && !previewLandingPagePopup.pdfContent"></loader>
	</div>
</popup-c>

<attachment-preview
	*ngIf="selectedTemplatePreviewAttachment"
	[attachment]="selectedTemplatePreviewAttachment"
	(onClose)="selectedTemplatePreviewAttachment = null;">
</attachment-preview>
