import platform from 'platform';
import util from "util";
import {DICTIONARY} from "../../dictionary";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {NotificationService} from "../../services/notificationService";
import {RouteService} from "../../services/routeService";
import {GeneralService} from "../../services/generalService";
import {Router} from "@angular/router";

@Component({
	selector: 'support-menu-component',
	templateUrl: './support-menu.component.html',
})
export class SupportMenuComponent implements OnInit, OnDestroy{

	constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private router:Router) {
	}

	pageStyle = 0;
	dic = DICTIONARY;
	showContactUsForm = false;
	isPro;
	isEmdrSla;
	ddsOSSelectedType = platform.os.family.replace('Chromium', '').trim();
	ddsBrowserSelected = platform.name.replace('Microsoft', '').replace('Mini', '').replace('Mobile', '').replace('for iOS', '').trim();
	dssOSTypes = ['Linux','Windows 11', 'Windows 10','Mac','Android','iOS','Other'];
	dssBrowsers = ['Chrome','Internet Explorer','Edge','Firefox','Safari','Opera','Other'];
	dssReason = ["Encryption", "Deliverability Issue", "Outbound Rules", "Message Failure", "Inbound Relay", "False Positive/Negative", "Plan/Account-Related", "User Interface/ Add-in", "Account Takeover Protection", "Other"];
	messageText = '';
	messageTitle = '';
	ddsOtherBrowser;
	ddsOtherOS;
	ddsReasonSelected;
	supportTicketTextErr;
	triggerContactUsFormSubscription;

	ngOnInit() {
		if (location.hash.indexOf('plansignup') >= 0 || location.hash.indexOf('signup') >= 0 || location.hash.indexOf('trialsignup') >= 0) {
			this.pageStyle = 1;
		}
		else if (this.router.url.substring(1) !== this.dic.CONSTANTS.appStates.login) {
			this.pageStyle = 2;

			this.triggerContactUsFormSubscription = this.gs.triggerContactUsFormSubj.subscribe((data) => {
				if (!data) {return;}

				console.log('hello')
				this.showContactUsForm = true;
			});

			this.gs.getUserInfo(false, userInfo => {
				this.isPro = userInfo && userInfo.plan && userInfo.plan.plan === this.dic.CONSTANTS.planTypePro;
				if (userInfo.plan.threat_protection.security_reviewer) {
					this.isEmdrSla = true;
				}
			});
		}

		if (this.ddsOSSelectedType === 'OS X') {
			this.ddsOSSelectedType = 'Mac';
		}
		if (["Ubuntu", "Debian", "Fedora", "Red Hat", "SuSE"].indexOf(this.ddsOSSelectedType) >= 0) {
			this.ddsOSSelectedType = 'Linux';
		}
		if (this.ddsOSSelectedType.indexOf('Windows') >= 0) {
			this.ddsOSSelectedType += ' ' + platform.os.version;
		}
		if (this.dssOSTypes.indexOf(this.ddsOSSelectedType) < 0) {
			this.ddsOSSelectedType = this.ddsOSSelectedType.indexOf('Windows') >= 0 ? 'Windows Other' : 'Other';
		}
		if (this.dssBrowsers.indexOf(this.ddsBrowserSelected) < 0) {
			this.ddsBrowserSelected = 'Other';
		}
	};

	ngOnDestroy() {
		this.triggerContactUsFormSubscription.unsubscribe()
	}

	sendMessageToSupport = () => {
		this.supportTicketTextErr = false;
		if (!this.messageText) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'Message text'));
			this.supportTicketTextErr = true;
			return;
		}

		this.showContactUsForm = false;

		let ticketInfo = {
			title: this.messageTitle,
			type: this.ddsReasonSelected,
			message: this.messageText,
			OS: this.ddsOSSelectedType,
			browser: this.ddsBrowserSelected
		};

		if (this.ddsOSSelectedType === 'Other' && this.ddsOtherOS) {
			ticketInfo.OS = this.ddsOtherOS;
		}

		if (this.ddsBrowserSelected === 'Other' && this.ddsOtherBrowser) {
			ticketInfo.browser = this.ddsOtherBrowser;
		}

		this.rs.supportContact(ticketInfo).then((response) => {
			this.messageText = '';
			this.messageTitle = '';
			this.ddsReasonSelected = null;
		});
	};

	goToKnowledgeBase = (pageModel, anchorId=null) => {
		switch (pageModel) {
			case this.dic.CONSTANTS.supportPages.faq:
				if (this.gs.faq === this.dic.CONSTANTS.trustifiDefault.resources.faq) {
					this.router.navigate([this.dic.CONSTANTS.appStates.knowledge, this.dic.CONSTANTS.supportPages.faq]);
				}
				else {
					window.open(this.gs.faq,'_blank');
				}
				break;

			case this.dic.CONSTANTS.supportPages.knowledgeBase:
				if (anchorId === 'Sensitivity_Engine' && this.gs.sensitivityEngine !== this.dic.CONSTANTS.trustifiDefault.resources.sensitivityEngine) {
					window.open(this.gs.sensitivityEngine,'_blank');
				}
				else {
					this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
						this.router.navigate([this.dic.CONSTANTS.appStates.knowledge, this.dic.CONSTANTS.supportPages.knowledgeBase], {state: {anchorId}});
					});
				}
				break;

			default:
				this.router.navigate([this.dic.CONSTANTS.appStates.knowledge, pageModel]);
		}
	};
}



