import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {GeneralService} from "../../../../services/generalService";
import {NotificationService} from "../../../../services/notificationService";
import {DICTIONARY} from "../../../../dictionary";
import {ENV_CONSTS} from "../../../../constants";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
	selector: 'threat-simulation-campaign-details-component',
	templateUrl: './threat-simulation-campaign-details.component.html',
})
export class ThreatSimulationCampaignDetailsComponent implements OnInit {

	@Input() currentCampaign;
	@Output() deleteCampaign = new EventEmitter<any>;
	userLogoDetails;
	userInfo;
	sendTime;
	dic = DICTIONARY;
	isTrainingCampaign;
	previewMaterial;
	previewMaterialInProcess;

	campaignStatus;
	campaignStatuses = {
		completed: 'completed',
		scheduled: 'scheduled',
		sent: 'sent'
	};

	constructor(public gs:GeneralService,
				private sanitizer:DomSanitizer,
				private ns: NotificationService,
				private http: HttpClient) {
	}

	ngOnInit() {
		this.isTrainingCampaign = this.currentCampaign.ts_type === this.dic.CONSTANTS.threatSimulationType.materials.value;

		this.gs.getUserInfo(false,  (userInfo) => {
			this.userInfo = userInfo;
			this.userLogoDetails = userInfo.plan.customization.lf;
		});

		this.sendTime = new Date(this.currentCampaign.email_id.start_sending_time || this.currentCampaign.email_id.scheduled_time);
		if (this.sendTime) {
			if (new Date() < this.sendTime) {
				this.campaignStatus = this.campaignStatuses.scheduled;
			}

			if (new Date() > this.sendTime) {
				this.campaignStatus = this.campaignStatuses.sent;

				const expirationTime = new Date(this.currentCampaign.email_id.start_sending_time);
				expirationTime.setDate(this.sendTime.getDate() + (this.currentCampaign.email_id.advanced?.secure?.expired_days || 7));
				if (new Date() > expirationTime) {
					this.campaignStatus = this.campaignStatuses.completed;
				}
			}
		}

		if (this.isTrainingCampaign && this.currentCampaign.material.type !== 'link') {
			this.getMaterialContent(this.currentCampaign.material);
		}
	}

	getMaterialContent = (material) => {
		this.previewMaterialInProcess = true;

		this.http.get(ENV_CONSTS.attachmentUrl + '/admin/threatSimulation/material/content/' + material._id,{
			responseType: 'arraybuffer',
			headers: this.gs.getHeaders(),
			observe: 'response'
		}).subscribe((response) => {
			if (response && response.body.byteLength === 0) {
				this.ns.showErrorMessage(this.dic.ERRORS.fileDownloadFailed);
			}

			// first check if another item has been chosen while downloading the content
			if (this.currentCampaign.material.name === material.name) {
				const pdfFile = {
					name: material.name,
					type: 'application/pdf',
					content: response
				};
				this.previewMaterial = pdfFile;
			}

			this.previewMaterialInProcess = false;
		}, err => {
			console.error(err);
			this.ns.showErrorMessage(this.dic.ERRORS.fileDownloadFailed);
			this.previewMaterialInProcess = false;
		});
	}

}
