<div class="flex-column gap-4">
	<span>Test our DLP sensitivity engine using files or text to see how sensitive content is found and classified.</span>
	<div class="list-group list-group-flush settings-list-container">
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2" style="flex: 0 0; flex-basis: 250px;">
				<b>Scan text</b>
			</div>
			<div class="content-container flex-column gap-3">
				<div class="flex-row align-items-start gap-2">
					<textarea type="text" style="border: 1px solid #ccc; flex-basis: 400px; height: 75px;"
						   [(ngModel)]="textToScan"
						   placeholder="Enter text here"
						   (keyup.enter)="scanTextExecute(); scanTextErr = false;"
						   [ngClass]="{'validation-error' : scanTextErr, 'disabled' : scanTextInProcess}" >
					</textarea>
					<btn-c style="width: 120px;"
						   [loading]="{flag: scanTextInProcess, text: 'Scanning'}"
						   [isDisabled]="!textToScan || scanTextInProcess"
						   (action)="scanTextExecute();">
						Scan text
					</btn-c>
				</div>
			</div>
		</div>

		<div class="list-group-item settings-list-item border-bottom-0">
			<div class="title-section-container flex-column gap-2" style="flex: 0 0; flex-basis: 250px;">
				<b>Scan a file</b>
			</div>
			<div class="content-container flex-column gap-3">
				<div class="flex-row align-items-start gap-2">
					<input type="button" class="flex-column ellipsis centralize" style="height: 75px; flex-basis: 400px; max-width: 400px; background: white; border: dashed 1px #cccccc;"
						   [value]="file?.name && file?.name !== scanFileResults?.name ? _.truncate(file?.name, {'length': 50}) : 'Drag and drop here or click to select file'"
						   [ngStyle]="file?.name && file?.name !== scanFileResults?.name ?  {'color' : 'black' , 'font-weight' : 'normal' , 'font-size' : ''}: {'color' : 'gray' , 'font-weight' : '500' , 'font-size' : '1rem'}"
						   [(file)]="file"
						   ngfDrop
						   ngfSelect
						   [(validDrag)]="validDrag"
						   [(invalidDrag)]="invalidDrag"
						   [ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag}"
					/>
					<btn-c style="width: 120px;"
						   [loading]="{flag: scanFileInProcess, text: 'Scanning'}"
						   [isDisabled]="!file || scanFileInProcess"
						   (action)="scanFileExecute();">
						Scan File
					</btn-c>
				</div>

			</div>
		</div>
	</div>

	<div *ngIf="result && !scanFileInProcess && !scanTextInProcess" class="flex-column gap-2">
		<hr class="opacity-25"/>

		<trustifi-table-c
			class="trustifi-table" style="flex: 1 0; z-index: 5; min-height: 300px;"
			[list]="result.sensitive_types"
			[headers]="[
				{text: 'Type', sortKey: 'sensitive_type'},
				{text: 'Score', sortKey: 'score', width: '70px'},
				{text: 'Content'},
				{text: 'Compliance'},
			]"
			[cellsPrototype]="[
				{textKey: 'sensitive_type'},
				{textKey: 'score'},
				{html: 'contentCell'},
				{html: 'complianceCell'},
			]"
			[options]="{
				isEllipsis: false,
				itemsNameSingular: 'type',
			}"
		>
			<ng-template #customCells let-item let-cellId="cellId">
				<div *ngIf="cellId === 'contentCell'">
					<div class="px-2 py-1 border rounded-1 flex-row gap-2">
						<span class="f-1-0 break-word ellipsis">
							{{item.content}}
						</span>
						<i tooltip="Copy" class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;" (click)="clipboard.copy(item.content);"></i>
					</div>
				</div>

				<div *ngIf="cellId === 'complianceCell'">
					<div class="value flex-row gap-2 justify-content-start flex-wrap">
						<span class="p-1 rounded-1 bg-dark bg-opacity-10"
							  *ngFor="let compliance of item.compliance" >
							{{compliance}}
						</span>
					</div>
				</div>
			</ng-template>

			<div tableButtons>
				<div class="flex-row p-1 rounded-1 align-items-center gap-1" style="border: 1px solid; width: 150px;"
					 [ngStyle]="result.score === 0 && {'background-color':'#32cd324d', 'border-color':'limegreen'} || result.score < 4 && {'background-color':'#ff8c004d', 'border-color':'darkorange'} || result.score < 5 && {'background-color':'#FF851B4d', 'border-color':'#FF851B'} || result.score === 5 && {'background-color':'#FF41364d', 'border-color':'#FF4136' }">
					<div class="header">Sensitivity Score:</div>
					<input type="button" value="{{result.score}}"
						   [ngStyle]="result.score === 0 && {'background':'limegreen'} || result.score < 4 && {'background':'darkorange'} || result.score < 5 && {'background':'#FF851B'} || result.score === 5 && {'background':'#FF4136'}"
						   class="redborder fa" style="cursor: unset; width: 25px; margin: 0; padding-top: 4px; padding-bottom: 4px; border: 0; color: white !important;"
						   tooltip="Reflects how sensitive the content found in the email is on a scale of 0 (not sensitive) to 5 (extremely sensitive)" placement="right">
				</div>
			</div>
		</trustifi-table-c>
	</div>
</div>

