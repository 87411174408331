import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import {GeneralService} from "../../../../../services/generalService";
import {DICTIONARY} from "../../../../../dictionary";
import {Component, NgZone} from "@angular/core";


@Component({
	selector: 'scan-threats-component',
	templateUrl: './scan-threats.component.html',
})
export class ScanThreatsComponent {
    constructor(private gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private ngZone: NgZone) {
    }

    dic = DICTIONARY;
    scanLinksInProcess;
    scanLinkErr;
    linkToScan;
    scanLinkResults;
    testManualScanFile;
    scanFileInProcess;
    scanFileResults;
	invalidDrag;
	validDrag

    scanLinksExecute = () => {
        if (this.scanLinksInProcess) {
            return;
        }

        this.scanLinksInProcess = false;
        this.scanLinkErr = '';

        if (!this.linkToScan || !this.gs.isValidUrl(this.linkToScan)) {
            this.ns.showWarnMessage('Please provide a valid link.')
            this.scanLinkErr = true;
            return;
        }
        this.scanLinksInProcess = true;
        this.scanLinkResults = null;

        const linkScanObj = {
            value: [{href: this.linkToScan, value: "Link"}]
        };
        this.rs.scanLinks(linkScanObj).then(response => {
            this.scanLinkResults = response.url;
            this.scanLinkResults.url = this.linkToScan;
            this.scanLinksInProcess = false;
        }, (err) => {
            this.scanLinksInProcess = false;
        });
    }

    scanFilesExecute = () => {
        if (this.scanFileInProcess) {
            return;
        }

        if (!this.testManualScanFile) {
            this.ns.showWarnMessage('Please provide a file to scan')
            return;
        }

        if (this.testManualScanFile.size > 10000000) {
            this.ns.showWarnMessage('Max file size to scan is 10MB')
            return;
        }
        this.scanFileInProcess = true;
        this.scanFileResults = null;

        const reader = new FileReader();
        reader.onload = (e) => {
			this.ngZone.run(() => {
				const fileContent = reader.result.toString().split(',')[1];
				const fileScanObj = {
					value: [{content: fileContent, name: this.testManualScanFile.name, type: this.testManualScanFile.type}]
				};
				this.rs.scanFiles(fileScanObj).then(response => {
					this.scanFileResults = response.hash;
					this.scanFileResults.name = this.testManualScanFile.name;
					this.scanFileInProcess = false;
				}, (err) => {
					this.scanFileInProcess = false;
				});
			});
        };
        reader.readAsDataURL(this.testManualScanFile);
    }
}
