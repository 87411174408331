import * as util from 'util';
import _ from 'lodash';
import {RouteService} from '../../../../services/routeService';
import {GeneralService} from '../../../../services/generalService';
import {AuthService} from '../../../../services/authService';
import {DICTIONARY} from '../../../../dictionary';
import {NotificationService} from '../../../../services/notificationService';
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'account-security-component',
	templateUrl: './account-security.component.html',
})
export class AccountSecurityComponent implements OnInit {
    dic = DICTIONARY;
    random = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5);
    userInfo: any;
    require_2fa;
    changePasswordEnable: any;
    update2FaDiff: boolean;
	default2faState = {enabled: false};
    MfaQrPopup: { qrCodeUrl: any; password: string; show: boolean; error: string; success: boolean; };

	constructor(private gs:GeneralService,
				private rs:RouteService,
				private authService:AuthService,
				private ns:NotificationService) {

    }

	ngOnInit() {
        this.changePasswordEnable = true;

        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;

            if (!this.userInfo.require_2fa) {
                this.require_2fa = this.default2faState;
            }
            else {
                this.require_2fa = _.cloneDeep(this.userInfo.require_2fa);
            }
            if (!this.require_2fa.method) {
                this.require_2fa.method = this.dic.CONSTANTS.secure2faMethods.phone;
            }

            this.check2FaDiff();
        });
    }

    openChangePasswordPopup = () => {
        this.gs.showPopup({
            title: 'Change Account Password',
            subTitle: 'Please note - You are about to change your account password',
            body: [
                'You will receive an email with a link to start the process of changing your account password',
                'A password reset email will be sent from support@trustificorp.com. If you cannot find this email in your inbox, please check your junk/spam folder'],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Send',
            doneCb: () => {
                if (!this.changePasswordEnable) return;
                this.changePasswordEnable = false;
                this.authService.changePassword(this.userInfo.email, (err, message) => {
                    if (err) {
                        console.log(err);
                        return;
                    }
                    this.ns.showInfoMessage(this.dic.MESSAGES.passwordChange);
                });
            }
        });
    }

    check2FaDiff = () => {
        this.update2FaDiff = this.gs.equals(this.require_2fa, this.userInfo.require_2fa) || this.gs.equals(this.require_2fa, this.default2faState);
    };

    update2FAStatusPopup = () => {
        let title, subTitle, body = [], type;
        if (this.require_2fa.enabled) {
            title = 'Enable';
            subTitle = 'Multi-Factor Authentication will be required for logging into your account';
            type = this.dic.CONSTANTS.popupInfo;

            if (this.require_2fa.method === this.dic.CONSTANTS.secure2faMethods.phone) {
                if (this.userInfo.from[0].phone?.phone_number) {
                    body.push(`We will send you a PIN-code SMS to the number: +${this.userInfo.from[0].phone.country_code}-${this.userInfo.from[0].phone.phone_number}`,
                        'If the number is incorrect please update it under "My Profile"');
                }
                else {
                    body.push('We will send you an email containing a single-use PIN code');
                }
            }
            else if (this.require_2fa.method === this.dic.CONSTANTS.secure2faMethods.email) {
                body.push('We will send you an email containing a single-use PIN code');
            }
            //totp
            else {
                body.push('Please scan the QR code before logging out of the system');
            }
        }
        else {
            title = 'Disable';
            subTitle = 'Multi-Factor Authentication will not be required for logging into your account';
            body = [
                'We recommend enabling Multi-Factor Authentication for additional security'
            ];
            type = this.dic.CONSTANTS.popupWarning;
        }
        this.gs.showPopup({
            title: `${title} Multi-Factor Authentication`,
            subTitle: subTitle,
            body: body,
            type: type,
            doneBtnText: title,
            doneCb: this.update2FAStatus,
            cancelCb: () => {
                this.require_2fa.enabled = !this.require_2fa.enabled;
            }
        });
    };

    update2FAStatus = () => {
        this.rs.update2FaStatus({action:'enabled', enabled: this.require_2fa.enabled}).then(data => {
            if (data.fingerprint) {
                this.authService.addFingerprint(data.fingerprint);
            }
            else {
                this.authService.deleteFingerprint();
            }
            if (this.require_2fa.enabled && data.method) {
                this.require_2fa.method = data.method;
            }
			this.userInfo.require_2fa = _.cloneDeep(this.require_2fa);
            this.check2FaDiff();
        }, err => {
            this.require_2fa.enabled = !this.require_2fa.enabled;
        });
    };

    getQrCodeFor2fa = () => {
        this.rs.getQrCodeFor2fa().then(data => {
            this.MfaQrPopup = {
                qrCodeUrl: data,
                password: '',
                show: true,
                error: '',
                success: false
            };
        });
    };

    confirmMfaQrCode = () => {
        this.MfaQrPopup.error = '';
        this.MfaQrPopup.success = false;
        if (!this.MfaQrPopup.password) {
            this.MfaQrPopup.error = util.format(this.dic.ERRORS.accountEmailMissingName, 'Code');
            return;
        }

        this.require_2fa.password = this.MfaQrPopup.password;

        this.rs.update2FaStatus({action: 'validateTotp', data: this.require_2fa}).then(data => {
            this.MfaQrPopup.success = true;
        }, (err) => {
            this.MfaQrPopup.error = err.data.message;
        });
    }

    update2FAMethod = () => {
        if (this.require_2fa.method === this.dic.CONSTANTS.secure2faMethods.phone
            && (!this.userInfo.from[0].phone || !this.userInfo.from[0].phone.phone_number)) {
            this.ns.showWarnMessage(this.dic.ERRORS.missingPhoneNumber);
            return;
        }
        this.rs.update2FaStatus({action: 'methods', data: this.require_2fa}).then(data => {
			this.userInfo.require_2fa = _.cloneDeep(this.require_2fa);
            this.check2FaDiff();
        });
    };
}
