import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {LookAndFeelService} from "../../../../services/lookAndFeelService";

@Component({
	selector: 'user-management-component',
	templateUrl: './user-management.component.html',
})
export class UserManagementComponent implements OnInit {
    constructor(private router:Router,
				public gs:GeneralService,
				public lfs:LookAndFeelService,
				private rs:RouteService,
				private ns:NotificationService,) {
    }

    Math = Math;
    dic = DICTIONARY;
	_ = _;
    showUserSettings = false;
    stopActiveUserPopup = false;
    usersListView = this.dic.CONSTANTS.userMgmntListViews.all;
    orderBy = 'role_priority';
    sharedPlanInfo:any = { users:[], relay:{} };
    selectExpirationTextFlag = false;
    contactAutocompleteData = {
        showContacts:  false,
        emailInput: ''
    };
    expandMethods = {
        oneMonth: {display: 'one month', value: 'month'},
        oneYear: {display: 'one year', value: 'year'},
        twoYears: {display: '2 years', value: '2years'},
        threeYears: {display: '3 years', value: '3years'},
    }
    isCompanyView = false;
    summernoteOptions = {
        height: 210,
        maxHeight: 210,
        width: 555,
        disableResizeEditor: true,
        popover: false,
        toolbar: [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['color', ['color']],
            ['view', ['codeview']]
        ],
        codemirror: {
            theme: 'monokai'
        },
        placeholder: 'Your invitation to plan message'
    };

    isLoaded;
    isUnlimited;
	addNewUserInputTxt;
	doNotChangeIsExpandedStates;
    users;
    userInfo;
    userRole;
    isPartnerAdmin;
    userManagementLoaded;
    usersList;
    loadingUsersInProcess;
    isPartnerAllowedChangeClients;
    isUserDomaimVerified;
    isAnyDomaimVerified;
    accountPasswordExpiration;
    mainAdminUser;
    companyNamesExisting;
    rolesExisting;
    mainPlanAdmin;
    adminExpirationDateMinString;
    filterData;
    UserSharedPlanStatistics;
    showGroups;
    SharedPlanStatistics;
    addActiveUserPopup;
    template;
    addActiveAdminPopup;
    curSharedUser;
    currentAdminObj;
    updateAdminPlanPopup;
    setUserAsAdminPopup;
    setUserAsAdminInProcess;
    changeUserNamePopup;
	setUserTimezonePopup;
    personalReviewerPopup;
	userPermissionPopup;
    moveUserToAdminPopup;
    moveUserToAdminInProcess;
    allContacts;
    modalData;
    listAdded;
    autoEnforceMfaForNewUsers;
	loginCountriesMethods = {
		all: 'all',
		allowlist: 'allowlist',
		blocklist: 'blocklist'
	};
	loginOptionsPopup;
    adminExpirationDateMaxString;
	timeZones = this.gs.getAllTimeZones();


	ngOnInit() {
        this.isLoaded = false;
        this.isUnlimited = false;
        this.isCompanyView = false;
        this.users = [];

        this.sharedPlanInfo = { users:[], relay:{} };

        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
            this.userRole = userInfo.user_role;
            this.isPartnerAdmin = this.gs.isPartnerAdmin(this.userInfo);

            this.getUsersOfSharedPlan();

            this.isUnlimited = userInfo.plan.is_unlimited;

            this.userManagementLoaded = true;
        });
    };

    getUsersOfSharedPlan = () => {
        this.usersList = [];
        this.loadingUsersInProcess = true;

        this.rs.getUsersOfSharedPlan({}).then((response) => {
            this.sharedPlanInfo = response;
            this.isPartnerAllowedChangeClients = response.partners && response.partners.modify_client_plans

            const userDomain = this.gs.getDomain(this.userInfo.email);
            this.isUserDomaimVerified = response.domains && response.domains.length &&
                response.domains.find(d => d.domain === userDomain && d.verified) || false;
            this.isAnyDomaimVerified = response.domains && response.domains.length &&
                response.domains.some(d => d.verified) || false;
            this.accountPasswordExpiration = response.account_password_expiration || {
                enabled: false,
                expiration_days: 90
            };

			if (this.gs.isPartnerAdmin(this.userInfo)) {
				this.sharedPlanInfo.users[0].customer_user_role = this.sharedPlanInfo.customer_id+" Admin";
			}
			else {
				this.sharedPlanInfo.users[0].customer_user_role = "Admin";
			}
			this.mainAdminUser = this.sharedPlanInfo.users[0];

            this.companyNamesExisting = [];
            this.rolesExisting = ['User'];
            if (this.userInfo.plan.customer_id) {
                this.rolesExisting.push('Plan Admin', 'Managed Plan', 'Global Reviewer');
            }
            else {
                this.rolesExisting.push('Admin');
            }

            this.sharedPlanInfo.users.forEach(user => {
                user.user_role = user.user_role || user.customer_user_role;
				if (_.isUndefined(user.is_relay)) {
					user.is_relay = false;
				}
                if (user.user_role === this.dic.CONSTANTS.userRole.subAdmin) {
                    user.customer_user_role = this.sharedPlanInfo.users[0].customer_user_role;
                }

				user.isSelectable = true;

				this.setEmailAndRoleDisplay(user, this.sharedPlanInfo.users[0])
            });
            this.updateListView(this.usersListView);
            this.mainPlanAdmin = this.sharedPlanInfo.users[0];
            this.adminExpirationDateMinString = this.formatDateString(new Date());
            this.adminExpirationDateMaxString = this.formatDateString(this.getMainAdminExpirationDate());

            this.initFilters();
            this.createCompanyGroups();
            this.isLoaded = true;
            this.loadingUsersInProcess = false;
        }, err => {
            this.loadingUsersInProcess = false;
            this.isLoaded = true;
        });
    };

	setEmailAndRoleDisplay = (user, parentUser) => {
		this.setEmailDisplay(user);
		this.setRolePriority(user, parentUser);


		if (user.planUsers?.length) {
			user.planUsers.forEach(subUser => {
				this.setEmailAndRoleDisplay(subUser,user);
			});
		}
	}

    setEmailDisplay = (user) => {
        user.email_display = user.email;
        const emailParts = user.email.split('@');
        if (user.alloc_plan_id && user.alloc_plan_id.is_managed) {
            if (emailParts[1] === 'trustifi.com') {
                user.email_display = emailParts[0];
            }
            else {
                user.email_display = emailParts[1];
            }
        }
    }

    createCompanyGroups = () => {
		const companiesList = [];
		const companyGroups = _.groupBy(this.sharedPlanInfo.users, 'company_name_display');

        _.each(companyGroups, (companyUsers, companyName) => {
            const companyAdmin = companyUsers.find(itm => itm.user_role === this.dic.CONSTANTS.userRole.admin);
			companiesList.push({
				companyName,
				isCompanyTitleRecord: true,
				isSelectable: false,
				hideActions: true,
                expired: companyAdmin && companyAdmin.alloc_plan_id && companyAdmin.alloc_plan_id.expired,
                planUsers: companyUsers
            });
        });

		return companiesList;
    };

	showMultipleUsersActions = () => {
		return  _.compact([
			{
				display: 'Log-In MFA',
				submenu: [
					this.dic.CONSTANTS.userActions.enforceMFA,
					this.dic.CONSTANTS.userActions.disableMFA
				]
			},
			{
				display: 'Managed plan',
				submenu:[
					this.dic.CONSTANTS.userActions.enableManagedPlan,
					this.dic.CONSTANTS.userActions.disableManagedPlan
				]
			},
			{
				display: 'Block/Unblock users',
				submenu:[
					this.dic.CONSTANTS.userActions.blockUsers,
					this.dic.CONSTANTS.userActions.unblockUsers
				]
			},
			this.dic.CONSTANTS.userActions.sendWelcomeEmail,
			this.dic.CONSTANTS.userActions.setTimezone,
			this.dic.CONSTANTS.userActions.remove
		]);
	}

	selectMultipleUsersAction = (selectedItems, action) => {
		switch(action) {
			case this.dic.CONSTANTS.userActions.remove:
				this.removeUserFromSharedPlan(selectedItems);
				break;

			case this.dic.CONSTANTS.userActions.setTimezone:
				this.openSetUserTimezonePopup(selectedItems);
				break;

			case this.dic.CONSTANTS.userActions.enforceMFA:
				this.toggleMFAForPlanUsers(selectedItems, true);
				break;

			case this.dic.CONSTANTS.userActions.disableMFA:
				this.toggleMFAForPlanUsers(selectedItems, false);
				break;

			case this.dic.CONSTANTS.userActions.enableManagedPlan:
				this.changeManagedPlan(selectedItems, true);
				break;

			case this.dic.CONSTANTS.userActions.disableManagedPlan:
				this.changeManagedPlan(selectedItems, false);
				break;

			case this.dic.CONSTANTS.userActions.blockUsers:
			case this.dic.CONSTANTS.userActions.unblockUsers:
				this.changeUsersStatus(selectedItems, action === this.dic.CONSTANTS.userActions.blockUsers);
				break;

			case this.dic.CONSTANTS.userActions.sendWelcomeEmail:
				this.sendWelcomeEmail(selectedItems);
				break;
		}
	}


    showUserActions = (userObj) => {
		// sub row. user inside other's plan
		if (userObj.depth && !this.isPartnerAllowedChangeClients) {
			return [this.dic.CONSTANTS.userActions.moveToOtherAdmin];
		}

		// main admin
		if (userObj.customer_user_role && userObj.user_role === this.dic.CONSTANTS.userRole.admin) {
			return [
				this.dic.CONSTANTS.userActions.showStatistics,
				this.dic.CONSTANTS.userActions.rename,
				this.dic.CONSTANTS.userActions.setTimezone
			];
		}

		// invited user
		if (userObj.status === this.dic.CONSTANTS.userInPlanStatus.invitationSent) {
			return [
				this.dic.CONSTANTS.userActions.resendInvitation,
				this.dic.CONSTANTS.userActions.remove
			];
		}

		// admin
		if (userObj.user_role === this.dic.CONSTANTS.userRole.admin) {
			return [
				this.dic.CONSTANTS.userActions.showStatistics,
				(userObj.status === this.dic.CONSTANTS.userInPlanStatus.blocked ||
					userObj.status === this.dic.CONSTANTS.userInPlanStatus.planBlocked ||
					userObj.status === this.dic.CONSTANTS.userInPlanStatus.blockUser24hours ||
					userObj.status === this.dic.CONSTANTS.userInPlanStatus.riskyUser24hours) ?
					this.dic.CONSTANTS.userActions.unblock : this.dic.CONSTANTS.userActions.block,
				userObj.preferences && userObj.preferences.require_2fa.strict ? this.dic.CONSTANTS.userActions.disableMFA : this.dic.CONSTANTS.userActions.enforceMFA,
				userObj.alloc_plan_id.is_managed ? this.dic.CONSTANTS.userActions.disableManagedPlan : this.dic.CONSTANTS.userActions.enableManagedPlan,
				this.dic.CONSTANTS.userActions.sendWelcomeEmail,
				this.dic.CONSTANTS.userActions.modifyResources,
				this.dic.CONSTANTS.userActions.rename,
				this.dic.CONSTANTS.userActions.setTimezone,
				this.dic.CONSTANTS.userActions.removePlan
			];
		}

		// sub admin
		if (userObj.user_role === this.dic.CONSTANTS.userRole.subAdmin) {
			return [
				this.dic.CONSTANTS.userActions.showStatistics,
				this.dic.CONSTANTS.userActions.modifyUserPermissions,
				(userObj.status === this.dic.CONSTANTS.userInPlanStatus.blocked ||
					userObj.status === this.dic.CONSTANTS.userInPlanStatus.planBlocked ||
					userObj.status === this.dic.CONSTANTS.userInPlanStatus.blockUser24hours ||
					userObj.status === this.dic.CONSTANTS.userInPlanStatus.riskyUser24hours) ?
					this.dic.CONSTANTS.userActions.unblock : this.dic.CONSTANTS.userActions.block,
				userObj.preferences && userObj.preferences.require_2fa.strict ? this.dic.CONSTANTS.userActions.disableMFA : this.dic.CONSTANTS.userActions.enforceMFA,
				this.dic.CONSTANTS.userActions.remove,
				this.dic.CONSTANTS.userActions.rename,
				this.dic.CONSTANTS.userActions.setTimezone,
				this.dic.CONSTANTS.userActions.sendWelcomeEmail
			];
		}

		// user
		if (userObj.user_role === this.dic.CONSTANTS.userRole.user) {
			const actions = [
				this.dic.CONSTANTS.userActions.showStatistics,
				this.dic.CONSTANTS.userActions.modifyUserPermissions,
				(userObj.status === this.dic.CONSTANTS.userInPlanStatus.blocked ||
					userObj.status === this.dic.CONSTANTS.userInPlanStatus.planBlocked ||
					userObj.status === this.dic.CONSTANTS.userInPlanStatus.blockUser24hours ||
					userObj.status === this.dic.CONSTANTS.userInPlanStatus.riskyUser24hours) ?
					this.dic.CONSTANTS.userActions.unblock : this.dic.CONSTANTS.userActions.block,
				userObj.preferences && userObj.preferences.require_2fa.strict ? this.dic.CONSTANTS.userActions.disableMFA : this.dic.CONSTANTS.userActions.enforceMFA,
				this.dic.CONSTANTS.userActions.remove,
				this.dic.CONSTANTS.userActions.rename,
				this.dic.CONSTANTS.userActions.setTimezone,
				this.dic.CONSTANTS.userActions.sendWelcomeEmail
			];

			if (this.isPartnerAdmin) {
				actions.push(
					this.dic.CONSTANTS.userActions.moveToOtherAdmin,
					this.dic.CONSTANTS.userActions.allocatePlan);
				if (!userObj.depth) {
					actions.splice(2, 0, this.dic.CONSTANTS.userActions.editGlobalReviewer);
				}
			}
			return actions;
		}
    };

    togglePlanUsers = (shared, enforcedValue) => {
        shared.planShowUsers = enforcedValue || !shared.planShowUsers;
    };

	toggleExpandItem = (item, event) => {
		if (!item.planUsers?.length) {
			return;
		}

		item.isExpanded = !item.isExpanded;
		event.stopPropagation();
		this.doNotChangeIsExpandedStates = true;

		// refresh the trustifi-table by changing the list. The directive detects changes and fires an update
		this.usersList = _.cloneDeep(this.usersList);
	}

    getUserOfSharedPlanStatistics = (curSharedUser) => {
        this.UserSharedPlanStatistics = [];

        const actionInfo = {
            action: this.dic.CONSTANTS.planUsersActions.statistics,
            depth: curSharedUser.depth,
            parent: curSharedUser.parent
        }
        this.rs.doActionOnPlanUser(curSharedUser.email, actionInfo).then( (response) => {
            this.UserSharedPlanStatistics = response;
        });
    };

	searchUser = (searchTerm, activeFilters) => {
		this.usersList.forEach(record => {
			if (!searchTerm && !activeFilters) {
				showAllSubUsers(record);
			}

			// search + filter

			const isFound = this.searchUserExecute(record, searchTerm, activeFilters);
			if (!isFound) {
				record.hide = true;
				return;
			}

			record.displayedCounter = record.planUsers?.length && _.reject(record.planUsers, 'hide').length;

			record.hide = false;
        });

		this.doNotChangeIsExpandedStates = false;
    };

    getSharedPlanStatistics = (sharedUser) => {
        this.SharedPlanStatistics = [];

        const actionInfo = {
            action: this.dic.CONSTANTS.planAdminActions.statistics,
            depth: sharedUser.depth,
            parent: sharedUser.parent
        }
        this.rs.doActionOnPlanAdmin(sharedUser.email, actionInfo).then((response) => {
            this.SharedPlanStatistics = response;
        });
    };

    parseListsContactsBeforeSend =() => {
        let contacts = this.users
            .filter(u => u.email !== 'List')
            .map(c => ({name: c.name, email: c.email}));

        this.users
            .filter(u => u.email === 'List')
            .forEach(u => {
                contacts = contacts.concat(u.originalList.contacts.map(c => ({name: c.name, email: c.email})));
            });

        return contacts;
    };

    addUsersToSharedPlanExecute = () => {
        // parse list types to contacts
        const contacts = this.parseListsContactsBeforeSend();
        this.rs.inviteUserToSharedPlan({
            emails: contacts,
			adminPermissions: this.addActiveUserPopup.adminPermissions
        }).then(() => {
            this.stopActiveUserPopup = false;
            this.users = [];
            this.closePopupAndRemoveAllTags();

            contacts.forEach((contact) => {
                const newUser = {
                    name: contact.name, email: contact.email, user_role: this.addActiveUserPopup.adminPermissions ? this.dic.CONSTANTS.userRole.subAdmin : this.dic.CONSTANTS.userRole.user,
                    status: this.dic.CONSTANTS.userInPlanStatus.invitationSent, is_relay: false
                };
				this.setEmailAndRoleDisplay(newUser, this.mainPlanAdmin)
				this.sharedPlanInfo.users.push(newUser);
                this.updateListView(this.usersListView);
            });
            this.mainPlanAdmin.alloc_plan_id.shared_plan.used_users += contacts.length;
            this.ns.showInfoMessage(this.dic.MESSAGES.userMgmtInvite);
        }, (err) => {
            this.stopActiveUserPopup = false;
            if (err.data && err.data.message) {
                this.ns.showErrorMessage(err.data.message);
            }
        });
    };

    closePopupAndRemoveAllTags = () => {
        this.addActiveUserPopup.show = false;

        let userTags:any = document.getElementById('users-to-invite');
        if (!userTags) return;
        while (userTags.children.length > 1) {
            userTags.removeChild(userTags.firstChild);
        }
    };

    updateTemplateAndAddUsersToSharedPlan = () => {
        if (!this.template || !this.template.html || !this.template.html.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.userMgmtInviteNoMessage);
            return;
        }

		if (this.addNewUserInputTxt) {
			this.confirmNewUserEmail(this.addNewUserInputTxt);
		}

        if (this.users.length == 0) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.xAtLeastOneX, 'add', 'user'));
			return;
        }

        this.stopActiveUserPopup = true;

        if (this.template.html !== this.template.originalHtml) {
            this.rs.updateTemplate(this.template._id, this.template).then((response) => {
                let summernote:any = this.gs.getSummernote();
                if (summernote && summernote.summernote('codeview.isActivated')) {
                    summernote.summernote('codeview.deactivate');
                }
                this.addUsersToSharedPlanExecute();
            });
        }
        else {
            this.addUsersToSharedPlanExecute();
        }
    };

    closeAddAdminPopup = () => {
        this.addActiveAdminPopup = false;
        this.curSharedUser = null;
    };

    addAdminToSharedPlan = () => {
        if (!this.curSharedUser.isUnlimited && (!this.curSharedUser.emails || !this.curSharedUser.storage)) {
            this.ns.showWarnMessage(this.dic.ERRORS.sharedPlanInvalidData)
            return;
        }
        if (!this.curSharedUser.adminExpirationDate) {
            this.ns.showWarnMessage(this.dic.ERRORS.sharedPlanNoExpiration);
            return;
        }
        if (!this.curSharedUser.allowedUsers) {
            this.ns.showWarnMessage(this.dic.ERRORS.sharedPlanNoUsers);
            return;
        }

        if (typeof this.curSharedUser.allowedPostmark !== 'number' || this.curSharedUser.allowedPostmark < 0) {
            this.ns.showWarnMessage(this.dic.ERRORS.sharedPlanNoPostmark);
            return;
        }

        if (typeof this.curSharedUser.allowedLicenses !== 'number' || this.curSharedUser.allowedLicenses < 0) {
            this.ns.showWarnMessage(this.dic.ERRORS.sharedPlanNoInboundLicenses);
            return;
        }

        if (this.curSharedUser.setAsPartner && !this.curSharedUser.customerId) {
            this.ns.showWarnMessage(this.dic.ERRORS.sharedPlanNoPartnerId);
            return;
        }

        if (!this.curSharedUser.setAsPartner) {
            this.curSharedUser.customerId = '';
        }

        const expirationDate = this.getPlanExpirationDate(this.curSharedUser.adminExpirationDate, this.curSharedUser.extendExpirationMethod);
        const userDomain = this.gs.getDomain(this.curSharedUser.email);
        this.stopActiveUserPopup = true;

        const adminInfo = {
            resend: false,
            emails: [{email: this.curSharedUser.email, name: this.curSharedUser.name}],
            isAdmin: true,
            plan: {
                isManagedPlan: this.curSharedUser.planManagedBy === this.dic.CONSTANTS.planManagedBy.you,
                domain: this.curSharedUser.domain,
                is_unlimited: this.curSharedUser.isUnlimited,
                planSubType: this.curSharedUser.planSubType,
                emails: this.curSharedUser.emails,
                storage: this.curSharedUser.storage,
                postmark_left: this.curSharedUser.allowedPostmark,
                users: this.curSharedUser.allowedUsers,
                inboundLicenses: this.curSharedUser.allowedLicenses,
                tpType: this.curSharedUser.tpType,
                expired: expirationDate,
                customerId: this.curSharedUser.customerId,
                company_name: this.curSharedUser.companyName || this.curSharedUser.customerId || userDomain,
                extendExpiration: this.curSharedUser.extendExpirationMethod
            }
        };

        this.rs.inviteUserToSharedPlan(adminInfo).then(() => {
            if (!this.curSharedUser.isUnlimited) {
                this.mainPlanAdmin.alloc_plan_id.storage -= this.curSharedUser.storage;
                this.mainPlanAdmin.alloc_plan_id.emails_left -= this.curSharedUser.emails;
            }
            this.mainPlanAdmin.alloc_plan_id.postmark_left -= this.curSharedUser.allowedPostmark;
            this.mainPlanAdmin.alloc_plan_id.shared_plan.used_users += this.curSharedUser.allowedUsers;
            this.mainPlanAdmin.alloc_plan_id.threat_protection.used_users += this.curSharedUser.allowedLicenses;
            let newAdmin:any = {
                name: this.curSharedUser.name || this.curSharedUser.email,
                email: this.curSharedUser.email,
                user_role: this.dic.CONSTANTS.userRole.admin,
                status: this.dic.CONSTANTS.userInPlanStatus.invitationSent,
                is_relay: false,
                customer_id: this.curSharedUser.customerId,
                customer_admin: !!this.curSharedUser.customerId,
                alloc_plan_id: {
                    is_unlimited: this.curSharedUser.isUnlimited,
                    emails: this.curSharedUser.emails,
                    storage: this.curSharedUser.storage,
                    postmark_left: this.curSharedUser.allowedPostmark,
                    company_name: this.curSharedUser.companyName || this.curSharedUser.customerId || userDomain,
                    plan_sub_type: this.curSharedUser.planSubType,
                    shared_plan: {allowed_users: this.curSharedUser.allowedUsers},
                    expired: expirationDate,
                    threat_protection: {allowed_users: this.curSharedUser.allowedLicenses, mode: this.curSharedUser.tpType},
                }
            };

            if (adminInfo.plan.isManagedPlan) {
                newAdmin.status = this.dic.CONSTANTS.userInPlanStatus.active;
                newAdmin.email = `${this.curSharedUser.domain}@trustifi.com`;
                newAdmin.name = `${this.curSharedUser.domain} Admin`;
                newAdmin.preferences = {require_2fa: {value: false, strict: false}};
                newAdmin.planUsers = [];
                newAdmin.alloc_plan_id.is_managed = true;
                if (this.gs.planAdmins && this.gs.planAdmins.length) {
                    this.gs.planAdmins.push({email: newAdmin.email});
					this.gs.updatePlanAdminsSubj.next({planAdmins: this.gs.planAdmins, init: false});
                }
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.userMgmtCreatePlanDomain, this.curSharedUser.domain));
            }
            else {
                this.ns.showInfoMessage(this.dic.MESSAGES.userMgmtInvite);
            }

			this.setEmailAndRoleDisplay(newAdmin, this.mainPlanAdmin)
            this.sharedPlanInfo.users.push(newAdmin);
            this.updateListView(this.usersListView);
            this.stopActiveUserPopup = false;
            this.closeAddAdminPopup();
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.stopActiveUserPopup = false;
        });
    };

    removeUserFromSharedPlan = (sharedUser) => {
        if (!sharedUser.length) {
            return;
        }

        let title, subTitle, body;
        if (sharedUser.length === 1) {
            body = ['User will be removed from the plan.'];
            title = 'Remove user';
            subTitle = `Remove ${sharedUser[0].email} from the current plan`;
        }
        else {
            body = [`${sharedUser.length} users will be removed from the plan.`];
            title = `Remove ${sharedUser.length} users`;
            subTitle = `Remove ${sharedUser.length} users from the current plan`;
        }

        for (let idx = 0; idx < sharedUser.length; idx++) {
            if (sharedUser[idx].user_role === this.dic.CONSTANTS.userRole.admin && sharedUser[idx].status === this.dic.CONSTANTS.userInPlanStatus.active) {
                this.ns.showWarnMessage(util.format(this.dic.ERRORS.sharedPlanRemoveAdmin, sharedUser[idx].email));
                return;
            }
        }

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Remove',
            doneCb: () => {
                sharedUser.forEach((userObj) => {
                    this.rs.removeUserFromSharedPlan({userToRemove: userObj.email, action: 'remove'}).then(() => {
                        _.remove<any>(this.sharedPlanInfo.users, user => user.email === userObj.email);
                        this.updateListView(this.usersListView);

                        this.mainPlanAdmin.alloc_plan_id.shared_plan.used_users--;
                        if (userObj.alloc_plan_id) {
                            this.mainPlanAdmin.alloc_plan_id.threat_protection.used_users -= userObj.alloc_plan_id.threat_protection.allowed_users;
                        }
                    });
                });
            }
        });
    };

    updateAdminPlan = (sharedUser, isUpdate) => {
        if (isUpdate && sharedUser.user_role !== this.dic.CONSTANTS.userRole.admin) {
            return;
        }
        this.curSharedUser = sharedUser || {};
        this.curSharedUser.invoiceNotify = null;
        this.curSharedUser.errMsg = '';
        this.curSharedUser.allowedPostmark = 0;
        this.curSharedUser.isExtendExpirationMenuOpen = false;
        this.curSharedUser.extendExpirationMethod = '';
        this.curSharedUser.newExpirationDate = null;

        this.currentAdminObj = this.curSharedUser.depth && this.curSharedUser.parent ?
            this.sharedPlanInfo.users.find(itm => itm.email === this.curSharedUser.parent) :
            this.mainPlanAdmin;

        if (isUpdate) {
            if (!this.curSharedUser.alloc_plan_id.is_unlimited) {
                this.curSharedUser.emails = this.curSharedUser.alloc_plan_id.emails_left;
                this.curSharedUser.storage = this.curSharedUser.alloc_plan_id.storage;
            }
            this.curSharedUser.allowedPostmark = this.curSharedUser.alloc_plan_id.postmark_left || 0;
            this.curSharedUser.allowedUsers = this.curSharedUser.alloc_plan_id.shared_plan.allowed_users || 1;
            this.curSharedUser.allowedLicenses = this.curSharedUser.alloc_plan_id.threat_protection.allowed_users || 0;
            this.curSharedUser.tpType = this.curSharedUser.alloc_plan_id.threat_protection.mode || this.dic.CONSTANTS.planTpType.basic;
            this.curSharedUser.planSubType = this.curSharedUser.alloc_plan_id.plan_sub_type;
            this.curSharedUser.customerId = this.curSharedUser.alloc_plan_id.customer_id;
            if (this.curSharedUser.alloc_plan_id.customer_id) {
                this.curSharedUser.setAsPartner = true;
            }

            this.curSharedUser.companyName = this.curSharedUser.alloc_plan_id.company_name;
            this.curSharedUser.isUnlimited = this.curSharedUser.alloc_plan_id.is_unlimited;
            this.updateAdminPlanPopup = true;
        }
        else {
            this.curSharedUser.adminExpirationDate = new Date();
            this.updateExpirationTime(this.expandMethods.oneYear.value);
            this.setMspAnnualAdminValues(this.dic.CONSTANTS.planSubType.annual);
            this.setUserAsAdminPopup = true;
        }

        this.initExpirationDate();
        this.prepareInvoiceNotifyMessage();
    };

	modifyUserPermissionsPopup = (userObj) => {
		this.userPermissionPopup = {
			userObj: userObj,
			isSubAdmin: userObj.user_role === this.dic.CONSTANTS.userRole.subAdmin,
			isOutboundReviewer: this.sharedPlanInfo.reviewers?.outbound?.some(itm => itm.email === userObj.email),
			isInboundReviewer: this.sharedPlanInfo.reviewers?.inbound?.some(itm => itm.email === userObj.email),
			isThreatSimulationReviewer: this.sharedPlanInfo.reviewers?.threat_simulation?.some(itm => itm.email === userObj.email),
			isArchiveReviewer: this.sharedPlanInfo.reviewers?.archive?.some(itm => itm.email === userObj.email),
			isAcpReviewer: this.sharedPlanInfo.reviewers?.account_compromised?.some(itm => itm.email === userObj.email),
			isPartnerReviewer: this.sharedPlanInfo.reviewers?.partners?.some(itm => itm.email === userObj.email),
			readOnly: userObj.read_only,
			showMultiTenant: userObj.show_multi_tenant,
			show: true
		};
	};

	modifyUserPermissionsExecute = () => {
		if (this.userPermissionPopup.inProcess) {
			return;
		}
		this.userPermissionPopup.inProcess = true;

		const statusUpdate = {
			enabled: this.userPermissionPopup.isSubAdmin,
			readOnly: this.userPermissionPopup.readOnly,
			showMultiTenant: this.userPermissionPopup.showMultiTenant,
			action: this.dic.CONSTANTS.planAdminActions.subAdmin,
			depth: this.userPermissionPopup.userObj.depth,
			parent: this.userPermissionPopup.userObj.parent
		};
		this.rs.doActionOnPlanAdmin(this.userPermissionPopup.userObj.email, statusUpdate).then( (response) => {
			this.userPermissionPopup.userObj.user_role = statusUpdate.enabled ? this.dic.CONSTANTS.userRole.subAdmin : this.dic.CONSTANTS.userRole.user;
			this.userPermissionPopup.userObj.read_only = statusUpdate.readOnly;
			this.userPermissionPopup.userObj.show_multi_tenant = statusUpdate.showMultiTenant;

			if (!statusUpdate.enabled) {
				this.userPermissionPopup.userObj.customer_user_role = null;
			}

			const currentAdminObj = this.userPermissionPopup.userObj.depth && this.userPermissionPopup.userObj.parent ?
				this.sharedPlanInfo.users.find(itm => itm.email === this.userPermissionPopup.userObj.parent) :
				this.mainPlanAdmin;

			this.setRolePriority(this.userPermissionPopup.userObj, currentAdminObj);

			if (!statusUpdate.enabled && this.userPermissionPopup.userObj.email === this.userInfo.email) {
				this.router.navigate([this.dic.CONSTANTS.appStates.home]);
			}

			this.ns.showInfoMessage(response.log);
			this.userPermissionPopup.inProcess = false;
		}, () => {
			this.userPermissionPopup.inProcess = false;
		});
	};

	modifyUserReviewerExecute = (type) => {
		if (this.userPermissionPopup.inProcess) {
			return;
		}
		this.userPermissionPopup.inProcess = true;

		switch (type) {
			case 'outbound':
				if (this.userPermissionPopup.isOutboundReviewer) {
					this.rs.addOutboundReviewer(this.userPermissionPopup.userObj.email).then(res => {
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));
						this.sharedPlanInfo.reviewers?.outbound.push({email: this.userPermissionPopup.userObj.email});
						this.userPermissionPopup.inProcess = false;
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				else {
					this.rs.deleteOutboundReviewer(this.userPermissionPopup.userObj.email).then(res => {
						this.userPermissionPopup.inProcess = false;
						_.remove<any>(this.sharedPlanInfo.reviewers?.outbound, itm => itm.email === this.userPermissionPopup.userObj.email);
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', this.userPermissionPopup.userObj.email));
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				break;

			case 'inbound':
				const data = {
					type: this.dic.CONSTANTS.configTpAction.reviewers,
					action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
					emails: [this.userPermissionPopup.userObj.email]
				};
				if (this.userPermissionPopup.isInboundReviewer) {
					data.action = this.dic.CONSTANTS.inboundConfigurationsActions.add;
					this.rs.updateTpConfig(data).then(res => {
						this.sharedPlanInfo.reviewers?.inbound.push({email: this.userPermissionPopup.userObj.email});
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));
						this.userPermissionPopup.inProcess = false;
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				else {
					data.action = this.dic.CONSTANTS.inboundConfigurationsActions.remove;
					this.rs.updateTpConfig(data).then(res => {
						this.userPermissionPopup.inProcess = false;
						_.remove<any>(this.sharedPlanInfo.reviewers?.inbound, itm => itm.email === this.userPermissionPopup.userObj.email);
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', this.userPermissionPopup.userObj.email));
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				break;

			case 'threat_simulation':
				if (this.userPermissionPopup.isThreatSimulationReviewer) {
					this.rs.addThreatSimulationReviewer(this.userPermissionPopup.userObj.email).then(res => {
						this.sharedPlanInfo.reviewers?.threat_simulation.push({email: this.userPermissionPopup.userObj.email});
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));
						this.userPermissionPopup.inProcess = false;
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
					}, () => {
						this.userPermissionPopup.isThreatSimulationReviewer = false;
						this.userPermissionPopup.inProcess = false;
					});
				}
				else {
					this.rs.deleteThreatSimulationReviewer(this.userPermissionPopup.userObj.email).then(res => {
						this.userPermissionPopup.inProcess = false;
						_.remove<any>(this.sharedPlanInfo.reviewers?.threat_simulation, itm => itm.email === this.userPermissionPopup.userObj.email);
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', this.userPermissionPopup.userObj.email));
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				break;

			case 'archive':
				if (this.userPermissionPopup.isArchiveReviewer) {
					this.rs.addArchiveReviewer(this.userPermissionPopup.userObj.email).then(res => {
						this.sharedPlanInfo.reviewers?.archive.push({email: this.userPermissionPopup.userObj.email});
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));
						this.userPermissionPopup.inProcess = false;
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				else {
					this.rs.deleteArchiveReviewer(this.userPermissionPopup.userObj.email).then(res => {
						this.userPermissionPopup.inProcess = false;
						_.remove<any>(this.sharedPlanInfo.reviewers?.archive, itm => itm.email === this.userPermissionPopup.userObj.email);
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', this.userPermissionPopup.userObj.email));
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				break;

			case 'account_compromised':
				if (this.userPermissionPopup.isAcpReviewer) {
					this.rs.addAccountCompromisedReviewer(this.userPermissionPopup.userObj.email).then(res => {
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));
						this.sharedPlanInfo.reviewers?.account_compromised.push({email: this.userPermissionPopup.userObj.email});
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
						this.userPermissionPopup.inProcess = false;
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				else {
					this.rs.deleteAccountCompromisedReviewer(this.userPermissionPopup.userObj.email).then(res => {
						_.remove<any>(this.sharedPlanInfo.reviewers?.account_compromised, itm => itm.email === this.userPermissionPopup.userObj.email);
						this.userPermissionPopup.inProcess = false;
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', this.userPermissionPopup.userObj.email));
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				break;

			case 'partner':
				if (this.userPermissionPopup.isPartnerReviewer) {
					this.rs.addPartnerReviewer(this.userPermissionPopup.userObj.email).then(res => {
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));
						this.sharedPlanInfo.reviewers?.partners.push({email: this.userPermissionPopup.userObj.email});
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
						this.userPermissionPopup.inProcess = false;
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				else {
					this.rs.deletePartnerReviewer(this.userPermissionPopup.userObj.email).then(res => {
						_.remove<any>(this.sharedPlanInfo.reviewers?.partners, itm => itm.email === this.userPermissionPopup.userObj.email);
						this.userPermissionPopup.inProcess = false;
						this.setReviewerDisplay(this.userPermissionPopup.userObj);
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', this.userPermissionPopup.userObj.email));
					}, () => {
						this.userPermissionPopup.inProcess = false;
					});
				}
				break;
		}
	};



	setRolePriority =(user, admin) => {
        if (user.email === admin.email) {
            user.role_priority = 1;
            user.user_role_display = admin.alloc_plan_id.customer_id ? admin.alloc_plan_id.customer_id +" Admin" : "Admin";
            user.company_name_display = user.alloc_plan_id && user.alloc_plan_id.company_name || 'N/A';
            if (admin.alloc_plan_id.customer_id && !this.rolesExisting.includes(admin.alloc_plan_id.customer_id + ' Admin')) {
                this.rolesExisting.push(admin.alloc_plan_id.customer_id + ' Admin');
            }
            if (user.company_name_display && !this.companyNamesExisting.includes(user.company_name_display)) {
                this.companyNamesExisting.push(user.company_name_display);
            }
        }
        else {
            if (user.user_role === this.dic.CONSTANTS.userRole.admin) {
                const customerId = user.alloc_plan_id && user.alloc_plan_id.customer_id;
                if (customerId) {
                    user.role_priority = 3;
                    user.user_role_display = `${customerId} Admin`;
                    if (!this.rolesExisting.includes(customerId + ' Admin')) {
                        this.rolesExisting.push(customerId + ' Admin');
                    }
                }
                else {
                    user.role_priority = 4;
                    user.user_role_display = `Plan Admin`;
                }
                user.company_name_display = user.alloc_plan_id && user.alloc_plan_id.company_name || 'N/A';
                if (user.company_name_display && !this.companyNamesExisting.includes(user.company_name_display)) {
                    this.companyNamesExisting.push(user.company_name_display);
                }
                if (user.alloc_plan_id && user.alloc_plan_id.is_managed) {
                    user.user_role_display = 'Managed Plan';
                    user.role_priority = 5;
                }
            }
            else {
                if (user.user_role === this.dic.CONSTANTS.userRole.subAdmin) {
                    user.role_priority = 2;
                    user.user_role_display = admin.alloc_plan_id.customer_id ? admin.alloc_plan_id.customer_id + " Admin" : "Admin";
                }
                else {
                    user.role_priority = 6;
                    user.user_role_display = 'User';
                }
                user.company_name_display = admin.alloc_plan_id && admin.alloc_plan_id.company_name || 'N/A';
            }
        }

		if (user.global_reviewer?.inbound?.enabled || user.global_reviewer?.outbound?.enabled
			|| user.global_reviewer?.archive?.enabled || user.global_reviewer?.threat_simulation?.enabled || user.global_reviewer?.account_compromised?.enabled) {
			user.role_priority = 5.8; // before user
			user.user_role_display += ' (Global Reviewer)';
		}

		this.setReviewerDisplay(user);
    };

	setReviewerDisplay = (user) => {
		user.user_reviewer = '';
		// Archive, Inbound, Outbound, Account Takeover
		if (this.sharedPlanInfo.reviewers) {
			if (this.sharedPlanInfo.reviewers.outbound?.find(itm => itm.email === user.email)) {
				user.user_reviewer += `Outbound`;
			}
			if (this.sharedPlanInfo.reviewers.inbound?.find(itm => itm.email === user.email)) {
				user.user_reviewer += user.user_reviewer ? ', Inbound' : `Inbound`;
			}
			if (this.sharedPlanInfo.reviewers.archive?.find(itm => itm.email === user.email)) {
				user.user_reviewer += user.user_reviewer ? ', Archive' : `Archive`;
			}
			if (this.sharedPlanInfo.reviewers.account_compromised?.find(itm => itm.email === user.email)) {
				user.user_reviewer += user.user_reviewer ? ', Account Takeover' : `Account Takeover`;
			}
			if (this.sharedPlanInfo.reviewers.threat_simulation?.find(itm => itm.email === user.email)) {
				user.user_reviewer += user.user_reviewer ? ', Threat Simulation' : `Threat Simulation`;
			}
			if (this.sharedPlanInfo.reviewers.partners?.find(itm => itm.email === user.email)) {
				user.user_reviewer += user.user_reviewer ? ', Partner' : `Partner`;
			}
		}
	}


    isUpdateDisabled = () => {
        if (this.curSharedUser.planManagedBy === this.dic.CONSTANTS.planManagedBy.admin && !this.curSharedUser.email) {
            return true;
        }
        if (this.curSharedUser.planManagedBy === this.dic.CONSTANTS.planManagedBy.you && !this.curSharedUser.domain) {
            return true;
        }
        return (!this.curSharedUser.isUnlimited && (!this.curSharedUser.emails || !this.curSharedUser.storage))
            || !this.curSharedUser.allowedUsers
            || this.setUserAsAdminInProcess;
    };

    updateAdminPlanExecute = () => {
        if (!this.curSharedUser || (!this.curSharedUser.isUnlimited && (!this.curSharedUser.emails || !this.curSharedUser.storage))) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanInvalidData;
            return;
        }
        if (!this.curSharedUser.adminExpirationDate) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoExpiration;
            return;
        }
        if (!this.curSharedUser.allowedUsers) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoUsers;
            return;
        }

        if (typeof this.curSharedUser.allowedPostmark !== 'number' || this.curSharedUser.allowedPostmark < 0) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoPostmark;
            return;
        }

        if (typeof this.curSharedUser.allowedLicenses !== 'number' || this.curSharedUser.allowedLicenses < 0) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoInboundLicenses;
            return;
        }

        if (this.curSharedUser.setAsPartner && !this.curSharedUser.customerId) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoPartnerId;
            return;
        }

        if (!this.curSharedUser.setAsPartner) {
            this.curSharedUser.customerId = '';
        }

        const userDomain = this.gs.getDomain(this.curSharedUser.email);
        this.curSharedUser.errMsg = '';
        this.setUserAsAdminInProcess = true;
        const postmarkNewEmails = this.curSharedUser.allowedPostmark - this.curSharedUser.alloc_plan_id.postmark_left;
        const expirationDate = this.getPlanExpirationDate(this.curSharedUser.adminExpirationDate, this.curSharedUser.extendExpirationMethod);

        const actionInfo = {
            action: this.dic.CONSTANTS.planAdminActions.modifyPlan,
            depth: this.curSharedUser.depth,
            parent: this.curSharedUser.parent,
            plan: {
                is_unlimited: this.curSharedUser.isUnlimited,
                planSubType: this.curSharedUser.planSubType,
                emails: this.curSharedUser.emails,
                storage: this.curSharedUser.storage,
                postmark_left: this.curSharedUser.allowedPostmark,
                postmark_new_diff: postmarkNewEmails,
                users: this.curSharedUser.allowedUsers,
                inboundLicenses: this.curSharedUser.allowedLicenses,
                tpType: this.curSharedUser.tpType,
                expired: expirationDate,
                customerId: this.curSharedUser.customerId,
                company_name: this.curSharedUser.companyName || this.curSharedUser.customerId || userDomain,
                extendExpiration: this.curSharedUser.extendExpirationMethod
            }
        };

        this.rs.doActionOnPlanAdmin(this.curSharedUser.email, actionInfo).then(() => {
            this.setUserAsAdminInProcess = false;
            this.updateAdminPlanPopup = false;

            const currentAdminObj = this.curSharedUser.depth && this.curSharedUser.parent ?
                this.sharedPlanInfo.users.find(itm => itm.email === this.curSharedUser.parent) :
                this.mainPlanAdmin;

            currentAdminObj.alloc_plan_id.storage = currentAdminObj.alloc_plan_id.storage + this.curSharedUser.storage - this.curSharedUser.alloc_plan_id.storage;
            currentAdminObj.alloc_plan_id.emails_left = currentAdminObj.alloc_plan_id.emails_left + this.curSharedUser.emails - this.curSharedUser.alloc_plan_id.emails_left;
            currentAdminObj.alloc_plan_id.shared_plan.used_users = currentAdminObj.alloc_plan_id.shared_plan.used_users + this.curSharedUser.allowedUsers - this.curSharedUser.alloc_plan_id.shared_plan.allowed_users;
            currentAdminObj.alloc_plan_id.postmark_left = currentAdminObj.alloc_plan_id.postmark_left + this.curSharedUser.alloc_plan_id.postmark_left - this.curSharedUser.allowedPostmark;
            currentAdminObj.alloc_plan_id.threat_protection.used_users = currentAdminObj.alloc_plan_id.threat_protection.used_users + this.curSharedUser.allowedLicenses - this.curSharedUser.alloc_plan_id.threat_protection.allowed_users;

            this.curSharedUser.alloc_plan_id.storage = this.curSharedUser.storage;
            this.curSharedUser.alloc_plan_id.emails_left = this.curSharedUser.emails;
            this.curSharedUser.alloc_plan_id.shared_plan.allowed_users = this.curSharedUser.allowedUsers;
            this.curSharedUser.alloc_plan_id.postmark_left = this.curSharedUser.allowedPostmark;
            this.curSharedUser.alloc_plan_id.threat_protection.allowed_users = this.curSharedUser.allowedLicenses;
            this.curSharedUser.alloc_plan_id.threat_protection.mode = this.curSharedUser.tpType;
            this.curSharedUser.alloc_plan_id.is_unlimited = this.curSharedUser.isUnlimited;
            this.curSharedUser.alloc_plan_id.plan_sub_type = this.curSharedUser.planSubType;
            this.curSharedUser.alloc_plan_id.expired = expirationDate;
            this.curSharedUser.alloc_plan_id.customer_id = this.curSharedUser.customerId;
            this.curSharedUser.alloc_plan_id.company_name = this.curSharedUser.companyName || this.curSharedUser.customerId || userDomain;
            this.curSharedUser.customer_id = this.curSharedUser.customerId;
            this.setRolePriority(this.curSharedUser, this.mainPlanAdmin);
            if (this.curSharedUser.planUsers) {
                for (let i = 0; i < this.curSharedUser.planUsers.length; i++) {
                    this.curSharedUser.planUsers[i].customer_id = this.curSharedUser.customerId;
                    this.setRolePriority(this.curSharedUser.planUsers[i], this.mainPlanAdmin);
                }
            }
            if (this.isCompanyView) {
                this.createCompanyGroups();
            }
        }, (err) => {
            if (err && err.data && err.data.message) {
                this.curSharedUser.errMsg = err.data.message;
            }
            this.setUserAsAdminInProcess = false;
        });
    };

    resendInviteToSharedPlan = (sharedUser) => {
        this.gs.showPopup({
            title: 'Resend Invitation to Plan',
            subTitle: 'Resend the invitation email to join your plan',
            body: [`You are about to resend an invitation to ${sharedUser.name || sharedUser.email}`],
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Resend',
            doneCb: () => {
                this.rs.inviteUserToSharedPlan({
                    resend: true,
                    emails: [{email: sharedUser.email, name: sharedUser.name || sharedUser.email}],
                    isAdmin: sharedUser.user_role === this.dic.CONSTANTS.userRole.admin
                }).then(() => {
                    this.ns.showInfoMessage(this.dic.MESSAGES.userMgmtInvite);
                });
            }
        });
    };

    downgradeAdmin = (sharedUser) => {
        if (!sharedUser) {
            return;
        }

        this.curSharedUser = sharedUser;
        this.gs.showPopup({
            title: this.dic.CONSTANTS.userActions.removePlan,
            subTitle: `${sharedUser.name}'s plan will be removed`,
            body: [
                'Resources will be returned to your plan',
                'All users under the admin will be removed from their plan'
            ],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: this.dic.CONSTANTS.userActions.removePlan,
            doneCb: this.downgradeAdminExecute
        });
    };

    downgradeAdminExecute = () => {
        if (!this.curSharedUser) {
            return;
        }

        const actionInfo = {
            action: this.dic.CONSTANTS.planAdminActions.downgradePlan,
            depth: this.curSharedUser.depth,
            parent: this.curSharedUser.parent,
        }
        this.rs.doActionOnPlanAdmin(this.curSharedUser.email, actionInfo).then(() => {
            const currentAdminObj = this.curSharedUser.depth && this.curSharedUser.parent ?
                this.sharedPlanInfo.users.find(itm => itm.email === this.curSharedUser.parent) :
                this.mainPlanAdmin;

            if (this.curSharedUser.alloc_plan_id && this.curSharedUser.alloc_plan_id.is_managed) {
                _.remove<any>(this.sharedPlanInfo.users, user => user.email === this.curSharedUser.email);
                this.updateListView(this.usersListView);
            }
            else {
                this.curSharedUser.user_role = this.dic.CONSTANTS.userRole.user;
                this.setRolePriority(this.curSharedUser, currentAdminObj);
            }

            currentAdminObj.alloc_plan_id.storage += this.curSharedUser.alloc_plan_id.storage;
            currentAdminObj.alloc_plan_id.emails_left += this.curSharedUser.alloc_plan_id.emails_left;
            currentAdminObj.alloc_plan_id.shared_plan.used_users = currentAdminObj.alloc_plan_id.shared_plan.used_users - this.curSharedUser.alloc_plan_id.shared_plan.allowed_users + 1;
            currentAdminObj.alloc_plan_id.postmark_left += this.curSharedUser.alloc_plan_id.postmark_left;
            currentAdminObj.alloc_plan_id.threat_protection.used_users -= this.curSharedUser.alloc_plan_id.threat_protection.allowed_users;

            if (this.curSharedUser.planUsers && this.curSharedUser.planUsers.length) {
                this.curSharedUser.planUsers = [];
            }
            this.curSharedUser.alloc_plan_id = null;
            if (this.gs.planAdmins && this.gs.planAdmins.length) {
                _.remove<any>(this.gs.planAdmins, a => a.email === this.curSharedUser.email);
				this.gs.updatePlanAdminsSubj.next({planAdmins: this.gs.planAdmins, init: false});
			}
        });
    };

    updateUserStatusInPlan = (userObj, isBlocked) => {
        if (!userObj) {
            return;
        }

        const title = isBlocked ? this.dic.CONSTANTS.userActions.block : this.dic.CONSTANTS.userActions.unblock;
        const connector = isBlocked ? 'not ': '';
        let subTitle;
        if (userObj.user_role === this.dic.CONSTANTS.userRole.admin) {
            subTitle = `${title} ${userObj.name}'s plan - users under this plan will ${connector}be able to access their account`;
        }
        else {
            subTitle = `${title} ${userObj.name} - user will ${connector}be able to access their account`;
        }
        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: title,
            doneCb: () => {
                const actionInfo = {
                    action: this.dic.CONSTANTS.planUsersActions.status,
                    isAdmin: userObj.user_role === this.dic.CONSTANTS.userRole.admin,
                    block: isBlocked,
                    depth: userObj.depth,
                    parent: userObj.parent
                };
                this.rs.doActionOnPlanUser(userObj.email, actionInfo).then(() => {
                    userObj.status = isBlocked ? userObj.user_role === this.dic.CONSTANTS.userRole.admin ? this.dic.CONSTANTS.userInPlanStatus.planBlocked
                        : this.dic.CONSTANTS.userInPlanStatus.blocked : this.dic.CONSTANTS.userInPlanStatus.active;
                    this.ns.showInfoMessage(this.dic.MESSAGES.planUserUpdated);
                });
            }
        });
    };

    addActiveUser = () => {
        this.getAllContacts();
        if (!this.mainPlanAdmin) {
            return;
        }
        this.rs.getInviteCustomizedTemplate().then( (template) => {
			this.template = template;
			this.template.html = this.template.html.replace('{{COMPANY_COLOR}}', this.lfs.color);
			this.template.originalHtml = _.cloneDeep(this.template.html);
            this.users = [];

            this.addActiveUserPopup = {
				show: true,
				adminPermissions: false
			};
        });
    };

    addActiveAdmin = () => {
        this.currentAdminObj = this.mainPlanAdmin;

        this.curSharedUser = {
            emails: 1000,
            storage: 1,
            allowedPostmark: 0,
            setAsPartner: false,
            planManagedBy: this.dic.CONSTANTS.planManagedBy.admin,
        };

        this.curSharedUser.adminExpirationDate = new Date();
        this.curSharedUser.adminExpirationDateString = this.formatDateString(this.curSharedUser.adminExpirationDate);

        this.updateExpirationTime(this.expandMethods.oneYear.value);

        this.setMspAnnualAdminValues(this.dic.CONSTANTS.planSubType.annual);
        this.initExpirationDate();
        this.addActiveAdminPopup = true;
        this.prepareInvoiceNotifyMessage();
    };

    selectUserAction = (user, action) => {
        switch (action) {
            case this.dic.CONSTANTS.userActions.rename:
                this.openChangeUserNamePopup(user);
                break;

            case this.dic.CONSTANTS.userActions.setTimezone:
                this.openSetUserTimezonePopup([user]);
                break;

            case this.dic.CONSTANTS.userActions.showStatistics:
                this.changeUserSharedPlanSettings(user);
                break;

            case this.dic.CONSTANTS.userActions.modifyResources:
                this.updateAdminPlan(user, true);
                break;

            case this.dic.CONSTANTS.userActions.remove:
                this.removeUserFromSharedPlan([user]);
                break;

            case this.dic.CONSTANTS.userActions.removePlan:
                this.downgradeAdmin(user);
                break;

            case this.dic.CONSTANTS.userActions.block:
                this.updateUserStatusInPlan(user, true);
                break;

            case this.dic.CONSTANTS.userActions.unblock:
                this.updateUserStatusInPlan(user, false);
                break;

            case this.dic.CONSTANTS.userActions.resendInvitation:
                this.resendInviteToSharedPlan(user);
                break;

            case this.dic.CONSTANTS.userActions.allocatePlan:
                this.updateAdminPlan(user, false);
                break;

            case this.dic.CONSTANTS.userActions.editGlobalReviewer:
                this.editUserGlobalReviewerPopup(user);
                break;

            case this.dic.CONSTANTS.userActions.moveToOtherAdmin:
                this.openMoveUserToAdminPopup(user);
                break;

            case this.dic.CONSTANTS.userActions.modifyUserPermissions:
                this.modifyUserPermissionsPopup(user);
                break;

            case this.dic.CONSTANTS.userActions.enforceMFA:
                this.toggleMFAForPlanUsers([user], true);
                break;

            case this.dic.CONSTANTS.userActions.disableMFA:
                this.toggleMFAForPlanUsers([user], false);
                break;

            case this.dic.CONSTANTS.userActions.enableManagedPlan:
                this.changeManagedPlan([user], true);
                break;

            case this.dic.CONSTANTS.userActions.disableManagedPlan:
                this.changeManagedPlan([user], false);
                break;

            case this.dic.CONSTANTS.userActions.sendWelcomeEmail:
                this.sendWelcomeEmail([user]);
                break;
        }
    };

	openSetUserTimezonePopup = (users) => {
        this.setUserTimezonePopup = {
            users,
			currentTimezone: users.length > 1 ? null : this.timeZones.find(itm => itm.zone === users[0].timezone),
            newTimezone: '',
			setTimezoneInProcess: false,
            show: true,
			confirmCb: () => {
				if (!this.setUserTimezonePopup.newTimezone) {
					this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'time zone'));
					return;
				}

				this.setUserTimezonePopup.setTimezoneInProcess = true;
				const newTimeZone = this.setUserTimezonePopup.newTimezone.zone;

				users.forEach(user => {

					if (user.status === this.dic.CONSTANTS.userInPlanStatus.invitationSent) {
						this.ns.showWarnMessage(this.dic.ERRORS.cannotSetInvitedUserTimezone);
						return;
					}

					const actionInfo = {
						action: this.dic.CONSTANTS.planUsersActions.updateTimezone,
						timezone: newTimeZone,
						isAdmin: user.user_role === this.dic.CONSTANTS.userRole.admin,
						depth: user.depth,
						parent:user.parent
					};


					this.rs.doActionOnPlanUser(user.email, actionInfo).then((response) => {
						user.timezone = newTimeZone;

						// update local userInfo if timezone was changed for the current customer admin
						if (this.userInfo.id === user._id) {
							this.userInfo.timezone = newTimeZone;
						}

						this.ns.showInfoMessage(this.dic.MESSAGES.userDetailsAccount);
						this.setUserTimezonePopup = null;
					}, (err) => {
						if (this.setUserTimezonePopup) {
							this.setUserTimezonePopup.setTimezoneInProcess = false;
						}
					});
				});
			}
        }
    };

    openChangeUserNamePopup = (user) => {
        this.changeUserNamePopup = {
            user,
            newName: {first: '', last: ''},
            errMsg: '',
            show: true
        }
    };

    updateUserName = (userObj, firstName, lastName) => {
        // validation
        if (!this.changeUserNamePopup.newName.first || !this.changeUserNamePopup.newName.last) {
            this.ns.showErrorMessage(this.dic.ERRORS.profileNeededInfo)
            return;
        }

        const actionInfo = {
            action: this.dic.CONSTANTS.planUsersActions.updateName,
            firstName: firstName,
            lastName: lastName,
            isAdmin: userObj.user_role === this.dic.CONSTANTS.userRole.admin,
            depth: userObj.depth,
            parent: userObj.parent
        };
        this.rs.doActionOnPlanUser(userObj.email, actionInfo).then(() => {
            userObj.name = `${firstName} ${lastName}`;
            this.ns.showInfoMessage(this.dic.MESSAGES.userDetailsAccount);
            this.changeUserNamePopup.show = false;
        }, (err) => {
            if (err.data && err.data.message) {
                this.ns.showErrorMessage(err.data.message);
            }
        });
    }


    editUserGlobalReviewerPopup = (userObj) => {
        this.personalReviewerPopup = {
            userObj: userObj,
            show: true
        };
    };

    updateUserGlobalReviewerExecute = (type) => {
        const userObj = this.personalReviewerPopup.userObj;
        const actionInfo: any = {
			type: type,
            action: this.dic.CONSTANTS.planUsersActions.globalReviewer,
            isAdmin: userObj.user_role === this.dic.CONSTANTS.userRole.admin,
            depth: userObj.depth,
            parent: userObj.parent,
			enabled: userObj.global_reviewer[type] && userObj.global_reviewer[type].enabled
		};

		switch (type) {
			case 'inbound':
				actionInfo.reviewer_allow_release_malicious = userObj.global_reviewer[type].reviewer_allow_release_malicious;
				actionInfo.allow_view_content = userObj.global_reviewer[type].allow_view_content;
				break;

			case 'outbound':
				actionInfo.allow_view_content = userObj.global_reviewer[type].allow_view_content;
				break;

			case 'archive':
				actionInfo.allow_view_content = userObj.global_reviewer[type].allow_view_content;
				break;

			case 'general':
				actionInfo.allow_review_access_main_plan = userObj.global_reviewer.allow_review_access_main_plan
				break;
		}

        this.rs.doActionOnPlanUser(userObj.email, actionInfo).then((response) => {
            this.setRolePriority(userObj, this.mainPlanAdmin);
            this.ns.showInfoMessage(this.dic.MESSAGES.planUserUpdated);
		}, err => {
		});
    };


    changeUserSharedPlanSettings = (sharedUser) => {
        this.curSharedUser = sharedUser;
        if (!sharedUser) {
            return;
        }

        this.showUserSettings = true;
    };

    withoutTime = (date=null) => {
        let d;
        if (date) {
            d = new Date(date);
        }
        else {
            d = new Date();
        }
        d.setHours(0, 0, 0, 0);
        return d;
    };

    initExpirationDate = () => {
        if (this.curSharedUser && this.curSharedUser.alloc_plan_id && this.curSharedUser.alloc_plan_id.expired) {
            this.curSharedUser.adminExpirationDate = this.curSharedUser.alloc_plan_id.expired;
        }

        this.curSharedUser.adminExpirationDateString = this.formatDateString(this.curSharedUser.adminExpirationDate);

        if (new Date(this.curSharedUser.adminExpirationDate) < new Date()) {
            this.curSharedUser.showOldExpirationDate = true;
        }
    };

    extendExpirationDate = (expandMethod) => {
        const licenses = this.Math.max(this.curSharedUser.allowedUsers, this.curSharedUser.allowedLicenses);

        this.curSharedUser.extendExpirationMethod = expandMethod;
        switch (expandMethod) {
            case this.expandMethods.oneMonth.value:
                this.curSharedUser.invoiceNotify = `Extending plan expiration date by a month, for <b>${licenses}</b> licenses, will cost an additional <b>$${(licenses * this.sharedPlanInfo.partners.license_price.monthly).toFixed(2)}</b>.`;
                break;

            case this.expandMethods.oneYear.value:
                this.curSharedUser.invoiceNotify = `Extending plan expiration date by a year, for <b>${licenses}</b> licenses, will cost an additional <b>$${(licenses * this.sharedPlanInfo.partners.license_price.yearly).toFixed(2)}</b>.`;
                break;

            case this.expandMethods.twoYears.value:
                this.curSharedUser.invoiceNotify = `Extending plan expiration date by 2 years, for <b>${licenses}</b> licenses, will cost an additional <b>$${(licenses * 2 * 0.9 * this.sharedPlanInfo.partners.license_price.yearly).toFixed(2)}</b>.`;
                break;

            case this.expandMethods.threeYears.value:
                this.curSharedUser.invoiceNotify = `Extending plan expiration date by 3 years, for <b>${licenses}</b> licenses, will cost an additional <b>$${(licenses * 3 * 0.8 * this.sharedPlanInfo.partners.license_price.yearly).toFixed(2)}</b>.`;
                break;
        }
    }

    addMoreUsersPartnerInvoiceMessage = () => {
        // modify plan
        const currentLicenses = Math.max(this.curSharedUser.alloc_plan_id.shared_plan.allowed_users, this.curSharedUser.alloc_plan_id.threat_protection.allowed_users);
        const newLicenses = Math.max(this.curSharedUser.allowedUsers || 0, this.curSharedUser.allowedLicenses || 0);
        if (newLicenses <= currentLicenses) {
            this.curSharedUser.invoiceNotify = null;
            return;
        }

        const addUsers = newLicenses - currentLicenses;

        // @ts-ignore
        const monthsLeftUntilExpiration = Math.max(0, Math.ceil((new Date(this.curSharedUser.alloc_plan_id.expired) - new Date()) / (30 * 24 * 60 * 60 * 1000)));

        if (!monthsLeftUntilExpiration) {
            // means that the plan was expired
            return;
        }

        const unitPrice = monthsLeftUntilExpiration <= 12 ? this.sharedPlanInfo.partners.license_price.monthly * monthsLeftUntilExpiration : monthsLeftUntilExpiration <= 24 ?
            this.sharedPlanInfo.partners.license_price.monthly * monthsLeftUntilExpiration * 0.9 : this.sharedPlanInfo.partners.license_price.monthly * monthsLeftUntilExpiration * 0.8;

        this.curSharedUser.invoiceNotify = `Adding these licenses will cost an additional $${addUsers * unitPrice}`;
    }

    allocatePlanPartnerInvoiceMessage = () => {
        const newLicenses = Math.max(this.curSharedUser.allowedUsers || 0, this.curSharedUser.allowedLicenses || 0);
        if (!newLicenses) {
            this.curSharedUser.invoiceNotify = null;
            return;
        }

        // @ts-ignore
        const monthsLeftUntilExpiration = (Math.max(0, Math.ceil((new Date(this.curSharedUser.adminExpirationDate) - new Date()) / (30 * 24 * 60 * 60 * 1000))) || 1);
        const unitPrice = monthsLeftUntilExpiration <= 12 ? this.sharedPlanInfo.partners.license_price.monthly * monthsLeftUntilExpiration : monthsLeftUntilExpiration <= 24 ?
            this.sharedPlanInfo.partners.license_price.monthly * monthsLeftUntilExpiration * 0.9 : this.sharedPlanInfo.partners.license_price.monthly * monthsLeftUntilExpiration * 0.8;

        this.curSharedUser.invoiceNotify = `Allocate a plan with ${newLicenses} licenses will cost $${newLicenses * unitPrice}`;
    }

    prepareInvoiceNotifyMessage = () => {
        if (!this.sharedPlanInfo.partners || !this.sharedPlanInfo.partners.automatic_invoice) {
            this.curSharedUser.invoiceNotify = null;
            return;
        }
        if (this.curSharedUser.planSubType === this.dic.CONSTANTS.planSubType.poc) {
            this.curSharedUser.invoiceNotify = null;
            return;
        }

        if (this.curSharedUser.planSubType === this.dic.CONSTANTS.planSubType.msp) {
            this.curSharedUser.invoiceNotify = 'An invoice for the allocated MSP plan will be sent at the start of next month';
            return;
        }

        if (this.curSharedUser.extendExpirationMethod) {
            this.extendExpirationDate(this.curSharedUser.extendExpirationMethod);
            return;
        }

        if (this.setUserAsAdminPopup || this.addActiveAdminPopup) {
            return this.allocatePlanPartnerInvoiceMessage();
        }

        this.addMoreUsersPartnerInvoiceMessage();
    }

    updateExpirationTime = (expandMethod) => {
        this.curSharedUser.extendExpirationMethod = expandMethod;
        const currentDate = new Date(this.curSharedUser.adminExpirationDate);

        switch (expandMethod) {
            case this.expandMethods.oneMonth.value:
                this.curSharedUser.newExpirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
                break;

            case this.expandMethods.oneYear.value:
                this.curSharedUser.newExpirationDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
                break;

            case this.expandMethods.twoYears.value:
                this.curSharedUser.newExpirationDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 2));
                break;

            case this.expandMethods.threeYears.value:
                this.curSharedUser.newExpirationDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 3));
                break;
        }
    }

    cancelExtendExpirationDate = () => {
        this.curSharedUser.extendExpirationMethod = null;
        this.curSharedUser.newExpirationDate = null;
        this.curSharedUser.invoiceNotify = null;

        this.prepareInvoiceNotifyMessage();
    }

    formatDateString = (date) => {
        if (!date) {
            return
        }

        let formatDate = new Date(date);
        let formatMonth:any = formatDate.getMonth() + 1;
        let formatDay:any = formatDate.getDate();
        let formatYear = formatDate.getFullYear();

        if (formatMonth < 10) {
            formatMonth = "0" + formatMonth;
        }
        if (formatDay < 10) {
            formatDay = "0" + formatDay;
        }
        return String(formatMonth + "/" + formatDay + "/" + formatYear)
    };

    getMainAdminExpirationDate = () => {
        return new Date(this.mainPlanAdmin.alloc_plan_id.expired);
    };

    setExpirationDateForPoc = () => {
        const date = new Date();
        let pocDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 14);
        pocDate = _.min([pocDate, this.getMainAdminExpirationDate()]);
        if (this.curSharedUser.adminExpirationDate === this.formatDateString(pocDate)) {
            return;
        }
        this.curSharedUser.adminExpirationDate = pocDate;
        this.curSharedUser.adminExpirationDateString = this.formatDateString(this.curSharedUser.adminExpirationDate);
    };

    setExpirationDateForMsp = () => {
        const date = new Date();
        const mspDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        if (this.curSharedUser.adminExpirationDate === this.formatDateString(mspDate)) {
            return;
        }
        this.curSharedUser.adminExpirationDate = mspDate;
        this.curSharedUser.adminExpirationDateString = this.formatDateString(this.curSharedUser.adminExpirationDate);
    };

    fixAllowedUsers = () => {
        const availableUsers = this.mainPlanAdmin.alloc_plan_id.shared_plan.allowed_users - this.mainPlanAdmin.alloc_plan_id.shared_plan.used_users;
        if (this.curSharedUser.allowedUsers > availableUsers) {
            this.curSharedUser.allowedUsers = availableUsers;
        }
    };

    setMspAnnualAdminValues = (planSubType) => {
        // partner is unlimited -> use unlimited values
        if (this.isUnlimited) {
            this.curSharedUser.storage = null;
            this.curSharedUser.emails = null;
            this.curSharedUser.isUnlimited = true;
        }
        // partner is not unlimited -> take 10% of available
        else {
            this.curSharedUser.storage = this.mainPlanAdmin.alloc_plan_id.storage;
            this.curSharedUser.emails = this.mainPlanAdmin.alloc_plan_id.emails_left;
            if (this.curSharedUser && this.curSharedUser.alloc_plan_id) {
                this.curSharedUser.storage += this.curSharedUser.alloc_plan_id.storage;
                this.curSharedUser.emails += this.curSharedUser.alloc_plan_id.emails_left;
            }
            this.curSharedUser.storage = Math.floor(this.curSharedUser.storage * 0.1);
            this.curSharedUser.emails = Math.floor(this.curSharedUser.emails * 0.1);
        }
        this.curSharedUser.allowedUsers = 25;
        this.fixAllowedUsers();
        this.curSharedUser.allowedLicenses = 0;
        this.curSharedUser.tpType = this.sharedPlanInfo.threat_protection && this.sharedPlanInfo.threat_protection.mode || this.dic.CONSTANTS.planTpType.basic;
        this.curSharedUser.planSubType = planSubType;
    };

    clearPlanAnnual = () => {
        this.setMspAnnualAdminValues(this.dic.CONSTANTS.planSubType.annual);

        if (this.curSharedUser.alloc_plan_id && this.curSharedUser.alloc_plan_id.expired) {
            this.curSharedUser.adminExpirationDate = new Date(this.curSharedUser.alloc_plan_id.expired);
        }
        else {
            this.curSharedUser.adminExpirationDate = new Date();
        }
        this.updateExpirationTime(this.curSharedUser.extendExpirationMethod);
    };

    clearPlanPoc = () => {
        this.curSharedUser.emails = 1000;
        this.curSharedUser.storage = 100;
        this.curSharedUser.allowedUsers = 10;
        this.fixAllowedUsers();
        this.curSharedUser.isUnlimited = false;
        this.setExpirationDateForPoc();
    };

    clearPlanMsp = () => {
        this.setMspAnnualAdminValues(this.dic.CONSTANTS.planSubType.msp);
        this.setExpirationDateForMsp();
    };

    setUnlimitedValues = () => {
        if (!this.curSharedUser.isUnlimited) {
            if (this.curSharedUser.planSubType === this.dic.CONSTANTS.planSubType.poc) {
                this.clearPlanPoc();
            }
            else if (this.curSharedUser.planSubType === this.dic.CONSTANTS.planSubType.msp) {
                this.clearPlanMsp();
            }
            this.curSharedUser.isUnlimited = false;
        }
        else {
            this.curSharedUser.emails = null;
            this.curSharedUser.storage = null;
        }
    };

    closeAllocateModifyPopup = () => {
        this.setUserAsAdminPopup = false;
        this.updateAdminPlanPopup = false;

        this.curSharedUser = null;
    };

    changePlanSubType = (subType) => {
        this.curSharedUser.planSubType = subType;
        switch (this.curSharedUser.planSubType) {
            case this.dic.CONSTANTS.planSubType.annual:
                this.clearPlanAnnual();
                break;

            case this.dic.CONSTANTS.planSubType.poc:
                this.clearPlanPoc();
                break;

            case this.dic.CONSTANTS.planSubType.msp:
                this.clearPlanMsp();
                break;
        }

        this.prepareInvoiceNotifyMessage();
    };

    setUserAsAdmin = () => {
        if (this.setUserAsAdminInProcess) return;
        if (!this.curSharedUser || (!this.curSharedUser.isUnlimited && (!this.curSharedUser.emails || !this.curSharedUser.storage))) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanInvalidData;
            return;
        }
        if (!this.curSharedUser.adminExpirationDate) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoExpiration;
            return;
        }
        if (!this.curSharedUser.allowedUsers) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoUsers;
            return;
        }

        if (typeof this.curSharedUser.allowedPostmark !== 'number' || this.curSharedUser.allowedPostmark < 0) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoPostmark;
            return;
        }

        if (typeof this.curSharedUser.allowedLicenses !== 'number' || this.curSharedUser.allowedLicenses < 0) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoInboundLicenses;
            return;
        }

        if (this.curSharedUser.setAsPartner && !this.curSharedUser.customerId) {
            this.curSharedUser.errMsg = this.dic.ERRORS.sharedPlanNoPartnerId;
            return;
        }

        if (!this.curSharedUser.setAsPartner) {
            this.curSharedUser.customerId = '';
        }

        const expirationDate = this.getPlanExpirationDate(this.curSharedUser.adminExpirationDate, this.curSharedUser.extendExpirationMethod);

        const userDomain = this.gs.getDomain(this.curSharedUser.email);
        this.curSharedUser.errMsg = '';
        this.setUserAsAdminInProcess = true;
        const planData = {
            action: this.dic.CONSTANTS.planAdminActions.allocatePlan,
            depth: this.curSharedUser.depth,
            parent: this.curSharedUser.parent,
            plan: {
                is_unlimited: this.curSharedUser.isUnlimited,
                planSubType: this.curSharedUser.planSubType,
                emails: this.curSharedUser.emails,
                storage: this.curSharedUser.storage,
                users: this.curSharedUser.allowedUsers,
                postmark_left: this.curSharedUser.allowedPostmark,
                inboundLicenses: this.curSharedUser.allowedLicenses,
                tpType: this.curSharedUser.tpType,
                expired: expirationDate,
                customerId: this.curSharedUser.customerId,
                company_name: this.curSharedUser.companyName || this.curSharedUser.customerId || userDomain,
                extendExpiration: this.curSharedUser.extendExpirationMethod
            }
        };
        this.rs.doActionOnPlanAdmin(this.curSharedUser.email, planData).then(res => {
            this.setUserAsAdminPopup = false;
            this.setUserAsAdminInProcess = false;

            this.curSharedUser.alloc_plan_id = {
                _id: res.planId,
                shared_plan: {allowed_users: this.curSharedUser.allowedUsers},
                threat_protection: {
                    allowed_users: this.curSharedUser.allowedLicenses,
                    mode: this.curSharedUser.tpType
                },
                is_unlimited: this.curSharedUser.isUnlimited,
                plan_sub_type: this.curSharedUser.planSubType,
                emails_left: this.curSharedUser.emails || 0,
                storage: this.curSharedUser.storage,
                postmark_left: this.curSharedUser.allowedPostmark,
                expired: expirationDate,
                customer_id: this.curSharedUser.customerId,
                company_name: this.curSharedUser.companyName || this.curSharedUser.customerId || userDomain
            };

            const currentAdminObj = this.curSharedUser.depth && this.curSharedUser.parent ?
                this.sharedPlanInfo.users.find(itm => itm.email === this.curSharedUser.parent) :
                this.mainPlanAdmin;

            currentAdminObj.alloc_plan_id.shared_plan.used_users = currentAdminObj.alloc_plan_id.shared_plan.used_users + this.curSharedUser.allowedUsers - 1;
            currentAdminObj.alloc_plan_id.emails_left -= this.curSharedUser.emails;
            currentAdminObj.alloc_plan_id.storage -= this.curSharedUser.storage;
            currentAdminObj.alloc_plan_id.postmark_left -= this.curSharedUser.allowedPostmark;
            currentAdminObj.alloc_plan_id.threat_protection.used_users = currentAdminObj.alloc_plan_id.threat_protection.used_users + this.curSharedUser.allowedLicenses;
            this.curSharedUser.user_role = this.dic.CONSTANTS.userRole.admin;
            this.curSharedUser.customer_id = this.curSharedUser.customerId;
            this.curSharedUser.customer_admin = !!this.curSharedUser.customerId;
            this.curSharedUser.planUsers = [];
            this.setRolePriority(this.curSharedUser, currentAdminObj);
            if (this.gs.planAdmins && this.gs.planAdmins.length) {
                this.gs.planAdmins.push({email: this.curSharedUser.email});
				this.gs.updatePlanAdminsSubj.next({planAdmins: this.gs.planAdmins, init: false});
			}
        }, err => {
            if (err && err.data && err.data.message) {
                this.curSharedUser.errMsg = err.data.message;
            }
            this.setUserAsAdminInProcess = false;
        });
    };

    openMoveUserToAdminPopup = (userToMove) => {
        if (!this.sharedPlanInfo.users || this.sharedPlanInfo.users.length === 1) {
            this.ns.showWarnMessage(this.dic.ERRORS.noOtherAdmins);
            return;
        }

        const users = _.cloneDeep(this.sharedPlanInfo.users);
		users.forEach(user => user.hide = false);
        const oldAdmin = getCurrentAdminOfUser(userToMove._id, users);
        const adminsList = _.filter(users, user => {
            if (user.user_role === this.dic.CONSTANTS.userRole.admin && (user.status === this.dic.CONSTANTS.userInPlanStatus.active || user.status === this.dic.CONSTANTS.userInPlanStatus.inactive) && user.email !== oldAdmin.email) {
                user.displayName = user.name + ' (' + (user.alloc_plan_id.is_managed ? user.email.split('@')[0] : user.email) + ')';
                return true;
            }
            else {
                return false;
            }
        });

        if (!adminsList.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noOtherAdmins);
            return;
        }

        this.moveUserToAdminPopup = {
            userToMove,
            adminsList,
            oldAdmin,
            selectedAdmin: null,
            show: true
        };
    };

    confirmMoveUserToAdmin = () => {
        if (this.moveUserToAdminInProcess) {
            return;
        }
		if (!this.moveUserToAdminPopup.selectedAdmin) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'admin'));
			return;
		}

        this.moveUserToAdminInProcess = true;

        // for shortening variables text:
        const userToMove = this.moveUserToAdminPopup.userToMove;
        const oldAdmin = this.moveUserToAdminPopup.oldAdmin;
        const newAdmin = this.moveUserToAdminPopup.selectedAdmin;

        const moveData = {
            action: this.dic.CONSTANTS.planAdminActions.moveToAdmin,
            fromAdminEmail: oldAdmin.email,
            toAdminEmail: newAdmin.email
        };

        this.rs.doActionOnPlanAdmin(userToMove.email, moveData).then(res => {
            //remove user
            if (oldAdmin.planUsers) {
                for (let idx = 0; idx < oldAdmin.planUsers.length; idx++) {
                    if (oldAdmin.planUsers[idx].email === userToMove.email) {
                        oldAdmin.planUsers.splice(idx, 1);
                        break;
                    }
                }
            }
            else {
                for (let idx = 0; idx < this.sharedPlanInfo.users.length; idx++) {
                    if (this.sharedPlanInfo.users[idx].email === userToMove.email) {
                        this.sharedPlanInfo.users.splice(idx, 1);
                        break;
                    }
                }
            }

            userToMove.company_name_display = newAdmin.company_name_display || '';
            // add user
            if (newAdmin.planUsers) {
                newAdmin.planUsers.push(userToMove);
            }
            else {
                this.sharedPlanInfo.users.push(userToMove);
            }

            // in case the old admin was open to show it's users and now he dosent have any users - close it
            if (oldAdmin.planUsers && !oldAdmin.planUsers.length && oldAdmin.planShowUsers) {
                this.togglePlanUsers(oldAdmin, false);
            }

            this.updateListView(this.usersListView);

            this.moveUserToAdminPopup = null;
            this.moveUserToAdminInProcess = false;
        }, err => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.moveUserToAdminInProcess = false;
        });
    };

    removeUser =(usersToRemove) => {
        if (!usersToRemove || !usersToRemove.length) {
            return;
        }
        usersToRemove.forEach(userToRemove => {
            let user = _.find<any>(this.users, userToRemove);
            if (user) {
                user.checked = false;
				user.selected = false;
                _.remove<any>(this.users, user)

                let tag:any = document.getElementById(`user-tag-${user.email}`);
                if (tag) {
                    tag.remove();
                }
            }
        });
    };

	confirmNewUserEmail = (newUserEmail) => {
		if (!newUserEmail) {
			return;
		}

		if (!this.validateEmail(newUserEmail)) {
			// this.ns.showWarnMessage(util.format(this.dic.ERRORS.EnterValidX, 'email address'));
			return;
		}

		this.users.push({_id: -1, name: newUserEmail, email: newUserEmail});

		this.addNewUserInputTxt = '';
		document.getElementById('adminContacts').focus();
	}

	confirmNewUserFromContacts = (contact) => {
		if (!_.find<any>(this.users, {_id: contact._id, name: contact.name, email: contact.email})) {
			this.users.push({_id: contact._id, name: contact.name, email: contact.email});
		}

		this.addNewUserInputTxt = '';
		this.focusNewUserInput();
	}

	confirmAddNewUserFromMyContacts = (contactOrList) => {
		if (contactOrList.length && contactOrList.contacts && contactOrList.contacts.length) {
			this.users.push({_id: contactOrList._id, name: contactOrList.name, email: 'List', originalList: contactOrList});
		}
		else {
			contactOrList.forEach(contactObj => {
				// validate that user does not already exist in list before adding
				if (!_.find<any>(this.users, {email: contactObj.email})) {
					this.users.push({_id: contactObj._id, name: contactObj.name, email: contactObj.email});
				}
			});
		}
	}

	removeNewUser = (userIndex) => {
		this.users.splice(userIndex, 1);
	}

	focusNewUserInput = () => {
		document.getElementById('adminContacts').focus();
	}

    validateEmail = (email) => {
        if (!email || email === '') return false;
        return this.gs.validateEmail(email);
    };

    exportUsersCsv = (sortBy) => {
        if (!this.sharedPlanInfo.users || !this.sharedPlanInfo.users.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Name,Email,Job Role,Status,Role,Company Name,Email Relay,Reviewer\n";

		let sortedTable = _.filter(this.sharedPlanInfo.users,user => {return !user.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

        // flatten the users list from in-depth users
        sortedTable = this.flattenUsersList(sortedTable);

        sortedTable.forEach(user => {
			if (!user.hide) {
				csvString += `"${user.name}",${user.email},"${user.job_title || 'N/A'}",${user.status},${this.gs.toCapitalize(user.user_role_display)},${user.company_name_display},${user.is_relay || false},"${user.user_reviewer || ''}"\n`;
			}
        });

        this.gs.exportCsv(csvString, `outbound_mailboxes.csv`);
    };

    flattenUsersList =(list) => {
        let flatList = [];
        list.forEach(user => {
            if (!user.planUsers || !user.planUsers.length) {
                flatList.push(user);
            }
            else {
                flatList.push(user);
                flatList = flatList.concat(this.flattenUsersList(user.planUsers));
            }
        });
        return flatList;
    }

    getAllContacts = () => {
        if (this.allContacts) {
            return;
        }

        this.rs.getAllContacts().then((response) => {
            this.allContacts = response;
        });
    };

    openAddressBookModal = () => {
        this.listAdded = false;
        this.modalData = {
            openContactsAddressBook: true
        };
    };

	initFilters = () => {
		this.filterData = {
			filterType: this.dic.CONSTANTS.tableFilters.userManagement,
			filters: {
				status: Object.values(this.dic.CONSTANTS.userInPlanStatus),
				role: this.rolesExisting,
				reviewer: ['Outbound', 'Inbound', 'Archive', 'Account Takeover', 'Threat Simulation', 'Partner'],
				'email relay': [true, false],
				'executives': [true, false]
			},
			tableFilterTemplatesRefName: this.dic.CONSTANTS.tableFilterTemplatesRefNames.user_mgmt,
			initFiltersFunction: this.initFilters
		};
		if (this.isPartnerAdmin && this.companyNamesExisting.length) {
			this.filterData.filters['company name'] = this.companyNamesExisting;
		}
	}

    toggleMFAForPlanUsers = (selectedUsers, isEnabled) => {
        if (!selectedUsers || !selectedUsers.length) {
            return;
        }
        const action = isEnabled ? 'enforce' : 'disable';
        this.autoEnforceMfaForNewUsers = isEnabled;
        let body = [
            {
                labelName: 'Enforce log-in MFA for new users',
                toggle: this.autoEnforceMfaForNewUsers
            }];
        let subTitle = `The users will ${isEnabled ? 'not' : ''} have the option to disable Multi-Factor Authentication.`;
        if (isEnabled) {
            subTitle += ' The users will be required to perform Multi-Factor Authentication when logging in';
        }
        this.gs.showPopup({
            title: `${this.gs.toCapitalize(action)} Multi-Factor Authentication`,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupToggle,
            doneBtnText: this.gs.toCapitalize(action),
            doneCb: (options) => {
                selectedUsers.forEach((userObj) => {
                    const actionInfo = {
                        action: this.dic.CONSTANTS.planUsersActions.mfa,
                        enabled: isEnabled,
                        autoEnforceMfaForNewUsers: options[0].toggle,
                        isAdmin: userObj.user_role === this.dic.CONSTANTS.userRole.admin,
                        depth: userObj.depth,
                        parent: userObj.parent
                    };
                    this.rs.doActionOnPlanUser(userObj.email, actionInfo).then(() => {
                        userObj.preferences.require_2fa.strict = isEnabled;
                    }, (err) => {
                    });
                });

                this.ns.showInfoMessage(this.dic.MESSAGES.planUsersUpdated);
            }
        });
    };

    changeManagedPlan = (selectedUsers, isManaged) => {
        let admins = _.filter(selectedUsers, u => u.user_role === this.dic.CONSTANTS.userRole.admin && u._id !== this.mainPlanAdmin._id && (u.status === this.dic.CONSTANTS.userInPlanStatus.active || u.status === this.dic.CONSTANTS.userInPlanStatus.inactive));
        if (!admins || !admins.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.actionOnlyForAdmins);
            return;
        }

        let title, subTitle, body
        if (isManaged) {
            title = this.dic.CONSTANTS.userActions.enableManagedPlan;
            subTitle = 'You are about to change this plan to a "managed plan"';
            body = ['The plan will be managed directly by you and the previous plan admin will not receive any notifications for the plan'];
        }
        else {
            title = this.dic.CONSTANTS.userActions.disableManagedPlan;
            subTitle = 'You are about to change this plan to a regular plan';
            body = ['The previously assigned plan admin will manage the plan and will receive all notifications for the plan'];
        }

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Apply',
            doneCb: () => {
                admins.forEach((userObj) => {
                    this.rs.doActionOnPlanAdmin(userObj.email, {
                        action: this.dic.CONSTANTS.planAdminActions.setManaged,
                        depth: userObj.depth,
                        parent: userObj.parent,
                        enabled: isManaged
                    }).then(() => {
                        userObj.alloc_plan_id.is_managed = isManaged;
						this.setEmailAndRoleDisplay(userObj, this.mainPlanAdmin)
                    });
                });

                this.ns.showInfoMessage(this.dic.MESSAGES.planUsersUpdated);
            }
        });
    };

    changeUsersStatus = (selectedUsers, isBlocked) => {
        if (!selectedUsers || !selectedUsers.length) {
            return;
        }

        const title = isBlocked ? this.dic.CONSTANTS.userActions.blockUsers: this.dic.CONSTANTS.userActions.unblockUsers;
        const connector = isBlocked ? 'not ': '';
        let subTitle;

        subTitle = `${title} - users will ${connector}be able to access their account`;

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: title,
            doneCb: () => {
                selectedUsers.forEach((userObj) => {
                    const actionInfo = {
                        action: this.dic.CONSTANTS.planUsersActions.status,
                        block: isBlocked,
                        depth: userObj.depth,
                        parent: userObj.parent
                    };
                    this.rs.doActionOnPlanUser(userObj.email, actionInfo).then(() => {
                        userObj.status = isBlocked ? userObj.user_role === this.dic.CONSTANTS.userRole.admin ? this.dic.CONSTANTS.userInPlanStatus.planBlocked
                            : this.dic.CONSTANTS.userInPlanStatus.blocked : this.dic.CONSTANTS.userInPlanStatus.active;
                    }, (err) => {
                    });
                });

                this.ns.showInfoMessage(this.dic.MESSAGES.planUsersUpdated);            }
        });
    };

    sendWelcomeEmail = (selectedUsers) => {
        if (!selectedUsers || !selectedUsers.length) {
            return;
        }

        this.gs.showPopup({
            title: 'Send Welcome Email',
            subTitle: `You are about to send a welcome email to ${selectedUsers.length} users`,
            body: ['Recipients will be able to reset their account password by clicking on "Create New Password"'],
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Send',
            doneCb: () => {
                selectedUsers.forEach((userObj) => {
                    const actionInfo = {
                        action: this.dic.CONSTANTS.planUsersActions.welcomeEmail,
                        isAdmin: userObj.user_role === this.dic.CONSTANTS.userRole.admin,
                        depth: userObj.depth,
                        parent: userObj.parent
                    };
                    this.rs.doActionOnPlanUser(userObj.email, actionInfo).then(() => {
                        this.ns.showInfoMessage(this.dic.MESSAGES.planUserWelcomeSent);
                    }, (err) => {
                    });
                });
            }
        });
    };

    updateListView = (listView) => {
        this.usersListView = listView;
        switch (listView) {
            case this.dic.CONSTANTS.userMgmntListViews.all:
                this.usersList = this.sharedPlanInfo.users;
                break;

            case this.dic.CONSTANTS.userMgmntListViews.domains:
                this.usersList = this.createPerDomainList();
                break;

            case this.dic.CONSTANTS.userMgmntListViews.company:
                this.usersList = this.createCompanyGroups();
                break;
        }
        this.loadingUsersInProcess = false;
    };

	createPerDomainList = () => {
		const domainsList = [];
		const domainGroups = _.groupBy(this.sharedPlanInfo.users, user => {
			const emailParts = user.email.split('@');
			const userDomain = user.alloc_plan_id && user.alloc_plan_id.is_managed ? emailParts[0] : emailParts[1];
			return userDomain;
		});

		_.each(domainGroups, (domainUsers, domainName) => {
			domainsList.push({
				domainName,
				isCompanyTitleRecord: true,
				isSelectable: false,
				hideActions: true,
				planUsers: domainUsers
			});
		});

		return domainsList;
	};

    openLoginOptionsPopup = () => {
		this.loginOptionsPopup = {
			allowedLoginMethods: [{display: 'SSO', value: 'sso'}, {display: 'SAML/OIDC', value: 'openid'}, {display: 'Email + Password', value: 'password'}],
			enforceLoginMethod: !!this.sharedPlanInfo.policy.allow_login_methods.value.length,
			enforcePasswordExpiration: _.cloneDeep(this.accountPasswordExpiration),
			loginCountriesMethod: this.sharedPlanInfo.login_countries.type,
			countries: {
				allowOnly: this.initCountries(),
				blockOnly: this.initCountries()
			},
			error: false,
			show: false,
			applyInProcess: false,
			doneCb: () => {
				let isValid = this.applyLoginOptionsPopupValidation();
				if (!isValid) {
					this.loginOptionsPopup.error = true;
					return;
				}

				this.loginOptionsPopup.applyInProcess = true;
				Promise.all([this.updateAccountExpiration(), this.updateAllowedLoginMethods(), this.updateLoginCountries()])
					.then(results => {
						this.loginOptionsPopup = null;
					})
					.catch(error => {
						this.loginOptionsPopup.applyInProcess = false;
					});
			}
		}

		this.loginOptionsPopup.allowedLoginMethods.forEach((method) => {
			method.selected = this.sharedPlanInfo.policy.allow_login_methods.value.includes(method.value);
        });

		this.loginOptionsPopup.countries.allowOnly.forEach(country => {
			country.selected = this.sharedPlanInfo.login_countries.allowlist.includes(_.upperCase(country.iso2));
		});
		this.loginOptionsPopup.countries.blockOnly.forEach(country => {
			country.selected = this.sharedPlanInfo.login_countries.blocklist.includes(_.upperCase(country.iso2));
		});

		this.loginOptionsPopup.show = true;
    }

	initCountries = () => {
		const countries = _.cloneDeep($.fn['intlTelInput'].getCountryData());
		countries.forEach(countryObj => {
			countryObj.icon = 'flag-icon flag-' + countryObj.iso2;
		});

		return countries;
	}

	applyLoginOptionsPopupValidation = () => {
		let isPassed = true;

		// account expiration validation:
		const isEnabled = this.loginOptionsPopup.enforcePasswordExpiration.enabled;
		if (isEnabled && !this.loginOptionsPopup.enforcePasswordExpiration.expiration_days && this.loginOptionsPopup.enforcePasswordExpiration.expiration_days !== 0) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'Expiration days field'));
			isPassed = false;
		}
		else if (isEnabled && this.loginOptionsPopup.enforcePasswordExpiration.expiration_days < 10) {
			this.ns.showWarnMessage(this.dic.ERRORS.minAccountPasswordExpiration);
			isPassed = false;
		}
		else if (isEnabled && this.loginOptionsPopup.enforcePasswordExpiration.expiration_days > 365) {
			this.ns.showWarnMessage(this.dic.ERRORS.maxAccountPasswordExpiration);
			this.loginOptionsPopup.enforcePasswordExpiration.expiration_days = 365;
			this.selectExpirationTextFlag = true;
			isPassed = false;
		}
		//

		// account login methods validation:
		if (this.loginOptionsPopup.enforceLoginMethod && !_.some(this.loginOptionsPopup.allowedLoginMethods, 'selected')) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.xAtLeastOneX, 'select', 'log in method'));
			isPassed = false;
		}
		//

		// block user login by location validation:
		if (this.loginOptionsPopup.loginCountriesMethod === this.loginCountriesMethods.allowlist && !_.some(this.loginOptionsPopup.countries.allowOnly, 'selected') ||
			this.loginOptionsPopup.loginCountriesMethod === this.loginCountriesMethods.blocklist && !_.some(this.loginOptionsPopup.countries.blockOnly, 'selected')) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.xAtLeastOneX, 'select', 'country'));
			isPassed = false;
		}
		//

		return isPassed;
	}

    updateAllowedLoginMethods = () => {
		return new Promise(resolve => {
			// check if values were changed before calling API:
			const isAllowedLoginMethodChanged = this.loginOptionsPopup.enforceLoginMethod !== !!this.sharedPlanInfo.policy.allow_login_methods.value.length ||
				_.xor(_.map(_.filter(this.loginOptionsPopup.allowedLoginMethods, 'selected'), 'value'), this.sharedPlanInfo.policy.allow_login_methods.value).length;
			if (!isAllowedLoginMethodChanged) {
				return resolve({});
			}

			let methods = [];
			if (this.loginOptionsPopup.enforceLoginMethod) {
				methods = _.map(_.filter(this.loginOptionsPopup.allowedLoginMethods,'selected'), 'value');
			}

			this.rs.doActionOnOutboundPlan({
				action: this.dic.CONSTANTS.planOutboundAction.loginMethods,
				methods: methods
			}).then(() => {
				this.sharedPlanInfo.policy.allow_login_methods.value = methods;
				resolve({});
			}, (err) => {
				resolve({});
				if (err && err.data && err.data.message && !err.data.display_bar) {
					this.ns.showErrorMessage(err.data.message);
				}
			});
		});
    };

	updateLoginCountries = () => {
		return new Promise(resolve => {
			// check if values were changed before calling API:
			const allowedCountriesCodes = _.map(_.filter(this.loginOptionsPopup.countries.allowOnly, 'selected'), country => _.upperCase(country.iso2));
			const blockedCountriesCodes = _.map(_.filter(this.loginOptionsPopup.countries.blockOnly, 'selected'), country => _.upperCase(country.iso2));

			const isLoginCountriesChanged = this.loginOptionsPopup.loginCountriesMethod !== this.sharedPlanInfo.login_countries.type ||
				this.loginOptionsPopup.loginCountriesMethod === this.loginCountriesMethods.allowlist &&  _.xor(allowedCountriesCodes, this.sharedPlanInfo.login_countries.allowlist).length ||
				this.loginOptionsPopup.loginCountriesMethod === this.loginCountriesMethods.blocklist &&  _.xor(blockedCountriesCodes, this.sharedPlanInfo.login_countries.blocklist).length;
			if (!isLoginCountriesChanged) {
				return resolve({});
			}

			const data = {
				action: this.dic.CONSTANTS.planOutboundAction.loginCountries,
				type: this.loginOptionsPopup.loginCountriesMethod,
				allowlist : allowedCountriesCodes,
				blocklist : blockedCountriesCodes
			}

			this.rs.doActionOnOutboundPlan(data).then(() => {
				this.sharedPlanInfo.login_countries.type = this.loginOptionsPopup.loginCountriesMethod;
				this.sharedPlanInfo.login_countries.allowlist = data.allowlist || this.sharedPlanInfo.login_countries.allowlist;
				this.sharedPlanInfo.login_countries.blocklist = data.blocklist || this.sharedPlanInfo.login_countries.blocklist;
				resolve({});
			}, (err) => {
				resolve({});
				if (err && err.data && err.data.message && !err.data.display_bar) {
					this.ns.showErrorMessage(err.data.message);
				}
			});
		});

	};

    updateAccountExpiration = () => {
		return new Promise(resolve => {
			// check if values were changed before calling API:
			const isPasswordExpChanged = this.loginOptionsPopup.enforcePasswordExpiration.enabled !== this.accountPasswordExpiration.enabled ||
				(this.loginOptionsPopup.enforcePasswordExpiration.enabled && this.loginOptionsPopup.enforcePasswordExpiration.expiration_days !== this.accountPasswordExpiration.expiration_days);
			if (!isPasswordExpChanged) {
				return resolve({});
			}

			const isEnabled = this.loginOptionsPopup.enforcePasswordExpiration.enabled;

			this.rs.doActionOnOutboundPlan({
				action: this.dic.CONSTANTS.planOutboundAction.passwordExpiration,
				settings: {
					enabled: isEnabled,
					expiration_days: this.loginOptionsPopup.enforcePasswordExpiration.expiration_days
				}
			}).then(() => {
				this.ns.showInfoMessage('Password expiration updated successfully');

				if (!isEnabled) {
					// don't save the user's input for expiration days in the UI if it was not enabled (so it doesn't count anyway...). so reset it.
					this.loginOptionsPopup.enforcePasswordExpiration.expiration_days = this.accountPasswordExpiration.expiration_days;
				}
				this.accountPasswordExpiration = _.cloneDeep(this.loginOptionsPopup.enforcePasswordExpiration);
				resolve({});
			}, err => {
				if (err && err.data && err.data.message && !err.data.display_bar) {
					this.ns.showErrorMessage(err.data.message);
				}
				resolve({});
			});
		});
    };

    getPlanExpirationDate(planExpirationDate, extendExpirationMethod) {
        let expirationDate = new Date(planExpirationDate).toISOString();
        if (extendExpirationMethod && this.curSharedUser.planSubType === this.dic.CONSTANTS.planSubType.annual) {
            const myDate = new Date(planExpirationDate);

            switch (extendExpirationMethod) {
                case this.expandMethods.threeYears.value:
                    expirationDate = new Date(myDate.setDate(myDate.getDate() + 365 * 3)).toISOString();
                    break;

                case this.expandMethods.twoYears.value:
                    expirationDate = new Date(myDate.setDate(myDate.getDate() + 365 * 2)).toISOString();
                    break;

                case this.expandMethods.oneYear.value:
                    expirationDate = new Date(myDate.setDate(myDate.getDate() + 365)).toISOString();
                    break;

                case this.expandMethods.oneMonth.value:
                    expirationDate = new Date(myDate.setMonth(myDate.getMonth() + 1)).toISOString();
                    break;
            }
        }

        return expirationDate;
    }

	searchFilterExecute = (user, filters) => {
		// need to match all filter types
		let numFilterToMatch = Object.keys(filters).length;

		if (filters.status && filters.status.length) {
			const isExpired = user.alloc_plan_id && user.alloc_plan_id.expired && this.withoutTime(user.alloc_plan_id.expired) < this.withoutTime();
			if ((filters.status.includes(user.status) && !isExpired) ||
				(filters.status.includes('Expired') && isExpired)) {
				numFilterToMatch--;
			}
		}
		if (filters['executives'] && filters['executives'].length) {
			if (filters['executives'].includes(user.is_executive)) {
				numFilterToMatch--;
			}
		}
		if (filters.role && filters.role.length) {
			if (filters.role.includes(user.user_role) ||
				filters.role.includes(user.user_role_display) ||
				filters.role.includes(user.alloc_plan_id && user.alloc_plan_id.customer_id)) {
				numFilterToMatch--;
			}
			else {
				if (filters.role.includes('Global Reviewer') && (user.global_reviewer?.inbound?.enabled || user.global_reviewer?.outbound?.enabled
					|| user.global_reviewer?.archive?.enabled || user.global_reviewer?.threat_simulation?.enabled || user.global_reviewer?.account_compromised?.enabled)) {
					numFilterToMatch--;
				}
			}
		}
		if (filters['email relay'] && filters['email relay'].length) {
			if (filters['email relay'].includes(user.is_relay)) {
				numFilterToMatch--;
			}
		}
		if (filters.reviewer && filters.reviewer.length && this.sharedPlanInfo.reviewers) {
			if (filters.reviewer.includes('Outbound') && this.sharedPlanInfo.reviewers.outbound.find(itm => itm.email === user.email)) {
				numFilterToMatch--;
			}
			else if (filters.reviewer.includes('Inbound') && this.sharedPlanInfo.reviewers.inbound.find(itm => itm.email === user.email)) {
				numFilterToMatch--;
			}
			else if (filters.reviewer.includes('Archive') && this.sharedPlanInfo.reviewers.archive.find(itm => itm.email === user.email)) {
				numFilterToMatch--;
			}
			else if (filters.reviewer.includes('Account Takeover') && this.sharedPlanInfo.reviewers.account_compromised.find(itm => itm.email === user.email)) {
				numFilterToMatch--;
			}
			else if (filters.reviewer.includes('Threat Simulation') && this.sharedPlanInfo.reviewers.threat_simulation?.find(itm => itm.email === user.email)) {
				numFilterToMatch--;
			}
			else if (filters.reviewer.includes('Partner') && this.sharedPlanInfo.reviewers.partners?.find(itm => itm.email === user.email)) {
				numFilterToMatch--;
			}
		}

		if (filters['company name'] && filters['company name'].length) {
			if (filters['company name'].includes(user.company_name_display)) {
				numFilterToMatch--;
			}
		}

		return !numFilterToMatch;
	}

	searchUserExecute = (user, searchTerm, activeFilters) => {
		if (searchTextExecute(user, searchTerm)) {
			if (!activeFilters || this.searchFilterExecute(user, activeFilters)) {
				showAllSubUsers(user);
				if (!this.doNotChangeIsExpandedStates) {
					user.isExpanded = false;
				}

				return true;
			}
		}

		let isFound = false;
		if (user.planUsers?.length) {
			for (let i = 0; i < user.planUsers.length; i++) {
				const isSubUserFound = this.searchUserExecute(user.planUsers[i], searchTerm, activeFilters);
				user.planUsers[i].hide = !isSubUserFound;
				if (isSubUserFound) {
					isFound = true;
					if (!this.doNotChangeIsExpandedStates) {
						user.isExpanded = searchTerm || activeFilters;
					}
				}
			}
		}

		return isFound;
	}
}



// when parent record is found by search and/or filter, remove hide flag from it's children recursively
function showAllSubUsers(user) {
	if (user.planUsers?.length) {
		user.planUsers.forEach(subUser => {
			subUser.hide = false;
			showAllSubUsers(subUser);
		});
	}
}

function searchTextExecute(user, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
    return ((user.name && user.name.toLowerCase().indexOf(searchTerm) > -1) ||
		(user.companyName && user.companyName.toLowerCase().indexOf(searchTerm) > -1) ||
		(user.job_title?.toLowerCase().indexOf(searchTerm) > -1) ||
		(user.email && user.email.toLowerCase().indexOf(searchTerm) > -1) ||
        (user.company_name_display && ((user.company_name_display.toLowerCase().indexOf(searchTerm) > -1))));
}

function getCurrentAdminOfUser(userToSearchId, users) {
    let user;
    for (let i = 0; i < users.length; i++) {
        user = users[i];
        if (user._id === userToSearchId) {
            return users[0];
        }
        if (user.planUsers && user.planUsers.length) {
            let innerUser;
            for (let j = 0; j < user.planUsers.length; j++) {
                innerUser = user.planUsers[j];
                if (innerUser._id === userToSearchId) {
                    return user;
                }
            }
        }
    }
    return users[0];
}

