import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, SecurityContext} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
	selector: 'partner-resources-component',
	templateUrl: './partner-resources.component.html',
})
export class PartnerResourcesComponent{
    constructor(public gs:GeneralService,
				private sanitizer: DomSanitizer) {
    }

    dic = DICTIONARY;
	dropboxLink = 'https://www.dropbox.com/scl/fo/h9w71bkazudrgvtr6zmda/ANTDx75bSf4jbAg8wV8Wa0k?rlkey=ltbfoae6tto9wd661w68z8c2i&st=3mzijm4b&dl=0';

	navigateToFolder(link: string) {
		if (this.isDropboxUrl(link)) {
			// Sanitize and open the link safely
			const safeUrl = this.sanitizer.sanitize(SecurityContext.URL, link);
			if (safeUrl) {
				window.open(safeUrl, '_blank', 'noopener,noreferrer');
			} else {
				console.error('Failed to sanitize the URL');
			}
		} else {
			console.error('URL not from Dropbox');
		}
	}

	isDropboxUrl(link) {
		try {
			const url = new URL(link);
			return url.hostname === 'www.dropbox.com';
		} catch (error) {
			console.error('Error parsing URL:', error);
			return false;
		}
	}
}
