<div class="flxClmn bs-enabled">
    <div class="page-title-container flxRow">
        <div class="flxClmn-center f-1-0">
            <div class="page-title-inner-container">
                <span class="page-title">Secure Storage</span>
            </div>
            <div class="page-subtitle-container">
                <span class="page-subtitle">View, manage, and download your sent files in a secure storage</span>
            </div>
        </div>
        <div class="control-and-info-icons flxRow"></div>
    </div>
    <div class="page-inner-content-holder no-scroll flxClmn" style="padding-top: 0;">
        <div style="flex: 1 0; overflow: auto;" class="flxClmn vSpace1"
             ngfDrop
             multiple="1"
			 [fileDropDisabled]="!!attachmentsTableEl.draggedItem"
			 [(files)]="files"
             (fileChange)="uploads()"
			 [(validDrag)]="validDrag"
			 [(invalidDrag)]="invalidDrag"
			 [ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag}">
            <div style="border-top: solid 1px #ebebeb; padding-top: 10px;">
                <div class="flxClmn vSpace1" style="flex: 1 0; align-items: start;">
                    <!--Regular directories navigation-->
                    <div class="directory-navigation ellipsis" *ngIf="paths?.length <= dic.CONSTANTS.directoryNavigationMaxLinksToShow">
                        <div class="directory-to-navigate" *ngFor="let directory of paths; let index = index;">
                            <div class="directory-name ellipsis" tooltip="{{directory.name}}" style="font-size: 1.3rem; font-weight: 500;"
                                 [ngClass]="{'unselected-directory-name': directory.id !== currentDir._id}"
                                 [ngStyle]="directory.name === dic.CONSTANTS.directoryRecipientUpload && {'max-width': '230px'}"
                                 (click)="navToDirectory(directory.id, index)">{{directory.name}}</div>
                            <i class="fa fa-chevron-right directory-nav-arrow" *ngIf="index < paths.length -1"></i>
                        </div>
                    </div>
                    <!--Directories navigation with shortcut-->
                    <div class="directory-navigation ellipsis" *ngIf="paths?.length > dic.CONSTANTS.directoryNavigationMaxLinksToShow">
                        <div *ngFor="let directory of paths; let index1 = index;">
                            <div class="directory-to-navigate" *ngIf="[0,1,paths.length-2,paths.length-1].includes(index1)">
                                <div class="directory-name ellipsis" tooltip="{{directory.name}}"
                                     [ngClass]="{'unselected-directory-name': directory.id !== currentDir._id}"
                                     [ngStyle]="directory.name === dic.CONSTANTS.directoryRecipientUpload && {'max-width': '230px'}"
                                     (click)="navToDirectory(directory.id, index1)">{{directory.name}}</div>
                                <i class="fa fa-chevron-right directory-nav-arrow" *ngIf="index1 < paths.length -1"></i>
                            </div>
                            <!--3 dots and menu-->
                            <div class="directory-to-navigate" style="height: 100%; position: relative;" *ngIf="index1 === 2">
                                <i class="fas fa-ellipsis-h navigation-dots" style="cursor: pointer;" tooltip="Show more folders in path" placement="top"
								   (click)="showNavigationPathsModal = !showNavigationPathsModal;$event.stopPropagation()">
                                </i>
                                <i class="fa fa-chevron-right directory-nav-arrow"></i>
                                <div *ngIf="showNavigationPathsModal" class="navigation-paths-modal"
                                     (clickOutside)="showNavigationPathsModal = false" (keydown.escape)="showNavigationPathsModal = false" >
                                    <div class="unseen-directory-name ellipsis"
                                         *ngFor="let unseenDir of paths; let index2 = index;"
                                         [ngClass]="{'d-none': !([0,1,paths.length-2,paths.length-1].includes(index2))}"
                                         (click)="navToDirectory(unseenDir.id, index2)">
                                        {{unseenDir.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--placeholder (loader)-->
                    <div class="directory-navigation ellipsis" *ngIf="getStorageDataInProcess" style="margin-top: 0;">
                        <div class="directory-to-navigate">
                            <div class="directory-name unselected-directory-name loading-dots" style="font-size: 1.3rem; font-weight: 500;">
                                Loading
                            </div>
                        </div>
                    </div>
                    <!---->
                </div>
            </div>


            <div class="flxRow hSpace3" style="flex: 1 0; overflow: auto;">
                <!--storage files/folders table-->
				<trustifi-table-c
					#attachmentsTableEl
					class="trustifi-table directories-table short-action-column f-1-0" style="z-index: 5;"
					[list]="currentDir?.sons.concat(currentDir.attachments)"
					[headers]="[
						{text:'', width: '45px'},
						{text:'Name', sortKey: 'name', width: '35%'},
						{text:'Sent', sortKey: 'email_id.length', width: '65px'},
						{text:'Sensitivity', sortKey: 'sensitivity.score', width: '100px', centered: true},
						{text:'Expiration', sortKey: 'expiration_delete', width: '130px', centered: true},
						{text:'Uploaded', sortKey: 'created', width: '145px'},
						{text:'Size', sortKey: 'size', width: '70px'},
					]"
					[cellsPrototype]="[
					  {html: 'iconCell'},
					  {textKey: 'name', edit: {modelKey: 'name', autoFocus: true}},
					  {html: 'sentCell'},
					  {html: 'sensitivityCell'},
					  {html: 'expirationCell'},
					  {textKey: 'created', textType: 'dateAndTime'},
					  {html: 'sizeCell'},
					]"
					[bulkActions] = "{
						enable: true,
						selectBulkActionCb:	selectMultipleAttachmentsAction,
						initBulkActionsFunction: showMultipleAttachmentsActions,
						showInRed: {enable: true, terms: ['Remove']}
					}"
					[cellActions]="{
						enable: true,
						selectItemActionCb:	selectAttachmentAction,
						initActionsFunction: showAttachmentActions,
						showInRed: {enable: true, terms: ['Delete']}
					}"
					(searchFunction)="searchAttachment($event.searchTerm, $event.activeFilters)"
					(confirmEditFunction)="confirmEditDirectory($event.item, $event.isApproved)"
					(onAnyItemSelected)="!$event.isItemSelectCheckboxClicked && !$event.item.hasOwnProperty('size') && goIntoDirectory($event.item)"
					(onDragAndDrop)="dragToMove($event.draggedItem, $event.draggedOntopItem)"
					[options]="{
						loadingTableFlag: getStorageDataInProcess,
						defaultSortKey: '-created',
						itemUniqueIdentifier: '_id',
						hideCounter: true
					}"
				>
					<ng-template #customCells let-item let-cellId="cellId">
						<!--icon cell-->
						<ng-container *ngIf="cellId === 'iconCell'">
							<!--File-->
							<div class="d-flex centralize p-1" *ngIf="!isDirectory(item)">
								<img style="width: 100%" [src]="getFileIcon(item)" alt="file icon">
							</div>
							<!--Folder-->
							<div style="position: relative;"
								 *ngIf="isDirectory(item)"
								 [tooltip]="item.type === dic.CONSTANTS.directoryType.default ? 'System folder' : null">
								<i class="fas fa-folder" style="font-size: 2rem; color: var(--bs-bold-color);"></i>
								<i class="fas fa-lock" style="position: absolute; top: calc(50% + 2px); left: 50%; transform: translate(-50%,-50%); color: white; font-size: 10px;"
								   *ngIf="item.type === dic.CONSTANTS.directoryType.default"></i>
							</div>
						</ng-container>

						<!--sent in emails cells-->
						<ng-container *ngIf="cellId === 'sentCell'">
							<div class="flxRow hSpace05 hvr-opacity" style="color: #2e4575; font-weight: bold; align-items: center;"
								 tooltip="See emails relevant to this attachment ({{item.email_id.length}} email{{item.email_id.length > 1 ? 's' : ''}})"
								 *ngIf="item.hasOwnProperty('size') && item.email_id.length"
								 (click)="openEmailsWithAttachment(item)">
								<span>{{item.email_id.length}}</span>
								<i style="font-size: 1.35rem;" class="far fa-envelope"></i>
							</div>
						</ng-container>

						<!--sensitivity cells-->
						<div class="d-flex centralize" style="font-size: 13px;" *ngIf="cellId === 'sensitivityCell' && item.hasOwnProperty('size')">
							<input *ngIf="item.sensitivity && !item.sensitivity.score && !item.is_sensitive_checked" type="button" value="/" class="redborder fa" style="background: limegreen; height: 25px; width: 25px; margin: 0 auto; color: white !important; border: 0;" placement="left" tooltip="System is checking the attachment">

							<input *ngIf="item.sensitivity && item.sensitivity.score && item.sensitivity.score" type="button" value="{{item.sensitivity.score}}" [ngStyle]="item.sensitivity.score < 4 && {'background':'darkorange'} || item.sensitivity.score < 5 && {'background':'#FF851B'} || item.sensitivity.score === 5 && {'background':'#FF4136'}" class="redborder fa" style="background: orange; height: 25px; width: 25px; margin: 0 auto; color: white !important; border: 0;" placement="left" tooltip="Click to review sensitivity details" (click)="$event.stopPropagation(); openSensitiveInfoPopup(item)">

							<input *ngIf="!item.sensitivity || (!item.sensitivity.score && item.is_sensitive_checked)" type="button" value="0" class="redborder fa" style="background: limegreen; height: 25px; width: 25px; margin: 0 auto; color: white !important; border: 0;" placement="left" tooltip="Click to review sensitivity details" (click)="$event.stopPropagation(); openSensitiveInfoPopup(item)">
						</div>

						<!--expiration cells-->
						<div *ngIf="cellId === 'expirationCell' && item.hasOwnProperty('size')" (click)="$event.stopPropagation()">
							<div class="flxRow-center hSpace1" style="align-items: center;">
                                        <span class="a-link" tooltip="Click to edit"
											  *ngIf="item.expiration_delete"
											  (click)="openEditExpirationPopup(item)">
                                            After {{ item.delete_attachment_days }} days
                                        </span>
								<span class="a-link" tooltip="Click to edit"
									  (click)="openEditExpirationPopup(item)"
									  *ngIf="!item.expiration_delete">
                                            Never
                                        </span>
								<a class="closeRed" *ngIf="item.expiration_delete"
								   (click)="cancelExpiration(item)">
									<i class="zmdi zmdi-close" tooltip="Cancel expiration"></i>
								</a>
							</div>
						</div>

						<!--size cells-->
						<span *ngIf="cellId === 'sizeCell' && item.hasOwnProperty('size')">
							{{item.size | FormatBytes:1}}
						</span>

					</ng-template>

					<div tableButtons>
						<div style="min-width: 150px; font-size: 15px;">
							<div class="flxRow animated fadeIn" *ngIf="getStorageDataInProcess">
								<span class="loading-dots">Loading</span>
							</div>
							<div *ngIf="!getStorageDataInProcess" class="animated fadeIn">
								<span>(</span>
								<span><span [ngClass]="{'color-primary fw-bold' : currentDir.sons.length}">{{currentDir.sons.length}}</span> {{currentDir.sons.length === 1 ? 'folder' : 'folders'}}</span>
								,
								<span><span [ngClass]="{'color-primary fw-bold' : currentDir.attachments.length}">{{currentDir.attachments.length}}</span> {{currentDir.attachments.length === 1 ? 'file' : 'files'}}</span>
								<span>)</span>
							</div>
						</div>
					</div>
				</trustifi-table-c>

                <!--action buttons-->
                <div class="flxClmn vSpace3" style="flex-basis: clamp(160px,11vw,300px);">
                    <div class="flxClmn vSpace2" style="align-items: center;">
                        <!--create new folder-->
                        <btn-c style="width: 100%; min-width: 150px; max-width: 180px;"
                             variant="outlined"
                             [isDisabled]="addNewDirectoryInProcess || getStorageDataInProcess"
                             endIcon="fa fa-lg fa-folder"
                             (action)="startAddNewDirectory()">
                            Create New Folder
                        </btn-c>

                        <!--upload files-->
                        <div style="width: 100%; min-width: 150px; max-width: 180px;"
							 [ngStyle]="{'pointer-events' : addNewDirectoryInProcess || getStorageDataInProcess ? 'none' : ''}"
							 ngfSelect
							 [(files)]="files"
							 multiple="1"
							 (fileChange)="uploads()">
                            <btn-c [isDisabled]="addNewDirectoryInProcess || getStorageDataInProcess"
								   class="w100"
								   endIcon="fa fa-lg fa-file-medical">
                                Upload File(s)
                            </btn-c>
                        </div>

						<!--upload folder-->
						<input id="storageUploadFolder" ngFileSelect type="file" (uploadOutput)="onUploadOutput($event)" webkitdirectory [multiple]="true"
							   style="visibility: hidden; position: fixed; width: 1px; right: -8000px;">
						<label for="storageUploadFolder" style="align-items: center; width: 100%; min-width: 150px; max-width: 180px;">
							<btn-c style="width: 100%; min-width: 150px; max-width: 180px;"
								   [isDisabled]="addNewDirectoryInProcess || getStorageDataInProcess"
								   class="w100"
								   (action)="clickOnFolderInput()"
								   endIcon="fa fa-folder-plus">
								Upload Folder
							</btn-c>
						</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <popup-c class="bs-enabled" *ngIf="expirationEditPopup?.show"
           head="Edit Attachment Expiration" size="dynamic"
           (closeCb)="expirationEditPopup = null;"
           (confirmCb)="modifyExpiration()"
           [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">
        <div class="flxClmn f-1-0 vSpace3" style="font-size: 1rem;">
            <div class="flxClmn vSpace1">
                <span>Attachment:</span>
                <span style="word-break: break-word; font-family: 'Roboto Condensed', sans-serif; font-weight: bold;">{{expirationEditPopup.expirationEditFile.name}}</span>
            </div>
            <div class="flxClmn vSpace3" style="background: #f9f9f9; padding: 20px;">
                <div class="flxClmn vSpace1">
                    <span style="font-weight: 500;">Current:</span>
                    <span style="margin-left: 10px;" *ngIf="expirationEditPopup.expirationEditFile.expiration_delete">
                        After {{ expirationEditPopup.expirationEditFile.delete_attachment_days }} days
                    </span>
                    <span style="margin-left: 10px;" *ngIf="!expirationEditPopup.expirationEditFile.expiration_delete">
                        Never
                    </span>
                </div>
                <div class="flxClmn vSpace1" >
                    <span style="font-weight: 500;">New expiration date:</span>
                    <div class="hSpace1" style="margin-left: 10px;">
                        <label style="width: inherit;">After</label>
                        <input required
                               style="width:70px; height: inherit; margin-bottom:0; box-sizing: border-box; border-radius: 4px; border: 1px solid #ccc; padding: 2px 8px;"
                               type="number" min="1" max="365" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
							   (keydown)="onKeyDown($event)"
                               id="delete_attachment_days" name="delete_attachment_days"
                               [(ngModel)]="expirationEditPopup.expirationEditFile.deleteAttachmentDays" size="5"
                               placeholder="Days">
                        <label>days</label>
                    </div>
                </div>
            </div>
            <a class="a-link"
               *ngIf="expirationEditPopup.expirationEditFile.expiration_delete"
               (click)="cancelExpiration(expirationEditPopup.expirationEditFile)">
                Cancel expiration
            </a>
        </div>
    </popup-c>

    <popup-c class="bs-enabled" *ngIf="moveDirectoryPopup?.show"
           head="Move Files or Folders"
           (closeCb)="moveDirectoryPopup = null;"
           (confirmCb)="move(false)"
           [buttons]="{cancel: 'Cancel', confirm: {text: 'Move', loading: moveDirectoryPopup.moveInProcess, disabled: !moveDirectoryPopup.selectedMoveDir}}"
           size="medium">
        <div class="flex-column gap-2 f-1-0" style="overflow: auto;">
            <span><b>{{moveDirectoryPopup.itemsToMove.length}} item{{moveDirectoryPopup.itemsToMove.length > 1 ? 's': ''}} selected</b></span>
            <span>Select a directory to move item{{moveDirectoryPopup.itemsToMove.length > 1 ? 's': ''}} to:</span>
            <br/>
            <div class="flex-column gap-2 f-1-0 py-3 pe-4 border border-light border-3" style="overflow: auto;">
                <div class="tree w100" style="align-self: flex-start;">
                    <!--recursive template for displaying all tree-->
					<ng-template #sonInTree let-son>
                        <div class="flxRow hSpace1 p-1 hvr-opacity" style="font-size: 16px; align-items: center; cursor: pointer;"
                             *ngIf="!_.find(moveDirectoryPopup.itemsToMove, {_id: son._id})"
                             [ngStyle]="moveDirectoryPopup.selectedMoveDir && moveDirectoryPopup.selectedMoveDir._id === son._id && {'background-color':'lightblue'}"
                             (click)="son.sons.length ? son.isOpened = !son.isOpened : moveDirectoryPopup.selectedMoveDir = son">
                            <div class="color-primary" style="flex-basis: 14px;" *ngIf="son.sons.length">
                                <i class="fa fa-chevron-right" *ngIf="!son.isOpened"></i>
                                <i class="fa fa-chevron-down" *ngIf="son.isOpened"></i>
                            </div>
                            <i style="font-size: 19px; margin-top: -3px;" class="fas fa-folder color-primary"
                               (click)="moveDirectoryPopup.selectedMoveDir = son; $event.stopPropagation()"></i>
                            <div (click)="moveDirectoryPopup.selectedMoveDir = son; $event.stopPropagation()">
                                {{ son.name }}
                            </div>
                        </div>
                        <ul *ngIf="son.sons.length && son.isOpened" style="list-style: none;">
                            <li *ngFor="let son of son.sons">
								<ng-container *ngTemplateOutlet="sonInTree; context: {$implicit: son}"></ng-container>
							</li>
                        </ul>
                    </ng-template>
                    <ul style="list-style: none;">
                        <li *ngFor="let son of [directoriesTree]">
							<ng-container *ngTemplateOutlet="sonInTree; context: {$implicit: son}"></ng-container>
						</li>
                    </ul>
                </div>
            </div>
        </div>
    </popup-c>

    <popup-c class="bs-enabled" *ngIf="false"
           head="Attachment Usage"
           (closeCb)="showAttachmentUsagePopup = false;"
           [buttons]="{cancel: 'OK'}"
           size="large">
        <div class="flxClmn f-1-0">
            <div class="usage-headers message-block alt" [ngStyle]="attachmentUsageData.recipients.length > 10 ? {'width': 'calc(100% - 10px)'} : {}">
                <div style="width:25%; padding-right: 5px;">Recipient</div>
                <div style="width:21%; padding-right: 5px;">Email Title</div>
                <div style="width:23%; padding-right: 5px;">Sent At</div>
                <div style="width:23%; padding-right: 5px;">Last Download</div>
                <div style="width:9%; padding-right: 5px;">Access</div>
            </div>
            <div class="usage-rows">
                <div *ngFor="let recipientObj of attachmentUsageData.recipients">
                    <div style="margin-top:5px; padding-top:3px; font-weight: 300;">
                        <div class="message-block alt" style="min-height: 0;">
                            <div class="ellipsis" style="width:25%; padding-right: 5px;">
                                <span tooltip="{{recipientObj.recipientData.email}}">{{recipientObj.recipientData.name}}</span>
                            </div>
                            <a class="ellipsis" style="width: 21%; padding-right: 5px;" (click)="navigateToMailBox(recipientObj.email)">
                                <span class="attachment-link" tooltip="Navigate to this email">{{recipientObj.email.title}}</span>
                            </a>
                            <div class="ellipsis" style="width: 23%; padding-right: 5px;">
                                <span>{{recipientObj.email.created | date:'MM/dd/yyyy HH:mm'}}</span>
                            </div>
                            <div class="ellipsis" style="width: 23%; padding-right: 5px;">
                                <span *ngIf="recipientObj.downloadTime">{{recipientObj.downloadTime | date:'MM/dd/yyyy HH:mm'}}</span>
                                <span *ngIf="!recipientObj.downloadTime">not downloaded</span>
                            </div>
                            <div style="width: 9%; padding-right: 5px; text-transform: capitalize;">
                                <span>{{!recipientObj.recipientData.is_blocked}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </popup-c>

    <sensitivity-details
            *ngIf="showSensitiveInfoPopup"
            type="'attachment'"
            (cancelCb)="showSensitiveInfoPopup = null"
            [scanItemObj]="showSensitiveInfoPopup.attachment"
            [scanTitle]="showSensitiveInfoPopup.attachment.name"
            [sensitivity]="showSensitiveInfoPopup.sensitivity">
    </sensitivity-details>

	<attachment-preview
		*ngIf="previewAttachment"
		[attachment]="previewAttachment"
		(onClose)="previewAttachment = null;">
	</attachment-preview>
</div>
