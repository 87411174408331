<div class="flxClmn bs-enabled">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container flxRow">
			<span class="page-title">Rules & Policies</span>
			<!--Information modal-->
			<more-info-c guideTitle="DLP Rules and Policies Guide" guideUrl="https://docs.trustifi.com/docs/dlp-rules-policies-guide">
				<div class="flxRow hSpace4" style="flex: 1 0; flex-wrap: wrap;">
					<div class="flxClmn vSpace05" style="flex: 1 0;">
						<span style="font-weight: bold;">Rules are automatically applied to all emails that match the "IF" condition</span>
						<ul class="vSpace1" style="margin-top: 0;">
							<li style="color:#484848;">
								<span>Set up rules according to your needs and using your criteria</span>
							</li>
							<li style="color:#484848;">
								<span>Rules will be enforced in the same order they appear here - 1st, then 2nd and so on</span>
							</li>
							<li style="color:#484848;">
								<span>You can change the order of the rules by dragging & dropping them</span>
							</li>
							<li style="color:#484848;">
								<span>Exceptions can be set up for rules using the "Add rule exception" button</span>
							</li>
						</ul>
					</div>
				</div>
			</more-info-c>
		</div>
		<div class="page-subtitle-container">
			<span class="page-subtitle"></span>
		</div>
	</div>
	<div class="page-inner-content-holder gap-3 pt-0 flex-column" [ngClass]="{'no-scroll' : gs.getTabName() === dic.CONSTANTS.outboundRulesAndPoliciesPageTabs.rules}">
		<!--Tabs-->
		<div class="md-tabs" [ngStyle]="{'--md-tabs-width': tabWidth + 'px'}">
			<div *ngFor="let tab of dic.CONSTANTS.outboundRulesAndPoliciesPageTabs | keyvalue: gs.returnZero"
				 [ngClass]="{'selected' : gs.getTabName() === tab.value}"
				 [routerLink]="tab.value">
				<input type="radio" name="tabs" id="{{tab.key}}Toggle" [checked]="gs.getTabName() === tab.value">
				<label for="{{tab.key}}Toggle">{{tab.value | camelCaseToTitleCase}}</label>
			</div>

			<div class="slide" [ngStyle]="{'left' : tabWidth * _.values(dic.CONSTANTS.outboundRulesAndPoliciesPageTabs).indexOf(gs.getTabName()) + 'px' || 0}"></div>
		</div>
		<!---->

		<router-outlet></router-outlet>
	</div>
</div>


