<div class="flxClmn">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container">
			<span class="page-title">Backup Inbox</span>
		</div>
		<div class="page-subtitle-container">
            <span class="page-subtitle">
				Maintain seamless access to your sent and received emails, even if your primary email client is unavailable
			</span>
		</div>
	</div>

	<div *ngIf="isLoaded">
		<div *ngIf="archiveEnabled" class="page-inner-content-holder no-scroll flxClmn vSpace2" style="min-height: 680px;">
			<div class="flxRow" style="flex-wrap: wrap-reverse; margin-bottom: 3px;">
				<!--query-->
				<div class="flxRow" (clickOutside)="showQueryModal = false;" style="align-items: stretch;">
					<div class="flxRow" style="flex: 1 0; border: solid 1px #cccccc; border-radius: 3px; overflow: visible; position: relative; min-width: 460px; height: 32px;"
						 [ngStyle]="{'border-color' : isQueryError ? 'red' : '#cccccc'}">
						<div class="flxRow hSpace05" style="align-items: center; padding: 10px; border-right: solid 1px #cccccc; cursor: pointer;"
							 (click)="showQueryModal = !showQueryModal"
							 [ngStyle]="{'background' : showQueryModal ? '#f7f7f7' : 'white'}">
							<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 22px; height: 22px; vertical-align: middle; fill: currentColor; overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M881.4 250.9H141.5c-23.9 0-43.5-19.6-43.5-43.5s19.6-43.5 43.5-43.5h739.9c23.9 0 43.5 19.6 43.5 43.5s-19.6 43.5-43.5 43.5zM272.1 454H141.5c-23.9 0-43.5-19.6-43.5-43.5s19.6-43.5 43.5-43.5h130.6c23.9 0 43.5 19.6 43.5 43.5S296 454 272.1 454zM272.1 657.1H141.5c-23.9 0-43.5-19.6-43.5-43.5s19.6-43.5 43.5-43.5h130.6c23.9 0 43.5 19.6 43.5 43.5s-19.6 43.5-43.5 43.5zM853.8 847.5L715.3 709c-16.9-16.9-16.9-44.6 0-61.5s44.6-16.9 61.5 0L915.3 786c16.9 16.9 16.9 44.6 0 61.5s-44.6 16.9-61.5 0zM467.9 860.2H141.5c-23.9 0-43.5-19.6-43.5-43.5s19.6-43.5 43.5-43.5h326.4c23.9 0 43.5 19.6 43.5 43.5 0.1 23.9-19.5 43.5-43.5 43.5z"/><path d="M619.4 290.5C493.3 290.5 391 392.8 391 519s102.3 228.5 228.5 228.5S847.9 645.2 847.9 519 745.6 290.5 619.4 290.5z m0 388.4c-88.3 0-159.9-71.6-159.9-159.9 0-88.3 71.6-159.9 159.9-159.9s160 71.5 160 159.9c0 88.3-71.6 159.9-160 159.9z"/></svg>
							<i class="fa fa-chevron-down" style="font-size: smaller;"></i>
						</div>

						<div class="flxRow hSpace1 animated fadeIn hvr-shadow" style="flex: 1 0; align-items: center; padding: 0 10px; cursor: pointer;"
							 (click)="showQueryModal = !showQueryModal">
							<a class="diamond-indent" style="font-weight: bold; height: auto; position: relative; padding-left: 12px; text-transform: capitalize;"
							   *ngFor="let query of emailQueryDisplay;"
							   title="{{query.tooltip}}">
								{{query.title}}
							</a>
						</div>

						<!--query modal-->
						<div class="" style="z-index: 2000; position: absolute; bottom: -3px; transform: translateY(100%); line-height: 15px; left: 0; background: white; box-shadow: 0 1px 5px grey; min-width: 537px;"
							 *ngIf="showQueryModal">
							<div class="flxClmn vSpace2" style="padding: 20px;">
								<div class="flxRow hSpace2">
									<label class="radio-container" style="display: block; width: fit-content; height: 30px; margin: 0; padding: 0; transform: scale(0.85); margin-top: 4px;">
										<input type="radio" name="normalQuery" [value]="queryTypes.normal"
											   [(ngModel)]="queryType"
											   [checked]="queryType === queryTypes.normal">
										<span class="checkmark"></span>
									</label>
									<div class="flxClmn vSpace2" style="padding-left: 15px; flex: 1 0;"
										 [ngStyle]="queryType === queryTypes.messageIdOnly ? {'opacity' : '0.5', 'cursor' : 'pointer'} : {'opacity' : '1' , 'cursor' : 'default'}"
										 (click)="queryType = queryTypes.normal">
										<div class="flxRow hSpace2">
											<span style="font-weight: bold; flex-basis: 78px; margin-top: 5px;">Date:</span>
											<div class="flxClmn" style="margin-left: 0; flex: 1 0;">
												<app-dropdown-c class="capitalize-box" style="height: 27px; min-height: 27px; flex: 1 0; max-width: 280px;"
																[ngStyle]="{'margin-bottom' : resultsPeriod === dic.CONSTANTS.trendsPeriod.range ? '10px' : '0'}"
																text="{{resultsPeriod.display}}">
													<li *ngFor="let periodObj of searchPeriod;"
														(click)="changePeriod(periodObj)">
														{{periodObj.display}}
													</li>
												</app-dropdown-c>

												<div class="flxRow hSpace1" style="align-items: center;" *ngIf="resultsPeriod === dic.CONSTANTS.trendsPeriod.range">
													<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
														<span>From:</span>
														<date-picker-c class="f-1-0"
																	   [(dateModel)]="range.start"
																	   [dateMaxLimit]="range.end"></date-picker-c>
													</div>
													<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
														<span>Until:</span>
														<date-picker-c class="f-1-0"
																	   [(dateModel)]="range.end"
																	   [dateMinLimit]="range.start"></date-picker-c>
													</div>
												</div>
											</div>
										</div>

										<div class="flxRow hSpace3" style="align-items: center; position: relative;">
											<span style="font-weight: bold; flex-basis: 73px;">Subject:</span>
											<input type="text" style="margin: 0; flex: 1 0; max-width: 280px; height: 27px;"
												   placeholder="Email's subject (exact or partial)"
												   [(ngModel)]="emailQuery.subject">
										</div>
									</div>
								</div>

								<div class="flxRow hSpace2" style="align-items: flex-start;">
									<label class="radio-container" style="display: block; width: fit-content; height: 30px; margin: 0; padding: 0; transform: scale(0.85); margin-top: 4px;">
										<input type="radio" name="byIdQuery" [value]="queryTypes.messageIdOnly"
											   [(ngModel)]="queryType"
											   [checked]="queryType === queryTypes.messageIdOnly">
										<span class="checkmark"></span>
									</label>
									<div class="flxRow hSpace3" style="align-items: center; padding-left: 15px; flex: 1 0; position: relative;"
										 [ngStyle]="queryType === queryTypes.normal ? {'opacity' : '0.5', 'cursor' : 'pointer'} : {'opacity' : '1' , 'cursor' : 'default'}"
										 (click)="queryType = queryTypes.messageIdOnly">
										<span style="font-weight: bold; flex-basis: 73px;">Message ID:</span>
										<input type="text" style="margin: 0; flex: 1 0; max-width: 280px; height: 27px;"
											   placeholder="Exact message ID"
											   [(ngModel)]="emailQuery.message_id">
									</div>
								</div>

								<btn-c id="getMyEmailsBtnInner" style="align-self: flex-end; height: 32px;"
									   [isDisabled]="getEmailsInProcess"
									   (action)="applyQuery();">
									Get Emails
								</btn-c>
							</div>
						</div>
					</div>

					<div class="flxRow">
						<btn-c class="connected-to-left" style="margin-left: -2px;"
							   [isDisabled]="getEmailsInProcess"
							   (action)="applyQuery();">
							Get Emails
						</btn-c>
<!--						<span class="flxClmn-center animated fadeIn" style="cursor: pointer; height: calc(100% - 4px); padding: 10px; background: white; font-weight: 300; color: #85000d; font-size: 0.75rem;"
							  *ngIf="getEmailsInProcess"
							  (click)="abortLoadingEmails()">
							<b>Abort</b>
						</span>-->
					</div>
				</div>
			</div>

			<!--main content area-->
			<div class="flxRow" style="flex: 1 0; border-top: solid 1px #e3e4e4; padding-top: 10px; overflow: auto;">
				<!--case panel / email list area (left side)-->
				<div class="flxClmn" style="flex: 1 0; padding: 5px; min-width: 450px; background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent);"
					 [ngClass]="getEmailsInProcess && !activeCase && 'shinyFX'">

					<!--case panel - placeholder-->
					<div class="flxClmn" style="position: relative; flex: 1 0; overflow: auto;" *ngIf="!activeCase">
						<span style=" position: absolute; z-index: 10; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.4; font-weight: bold;">{{getEmailsInProcess ? 'Loading emails list' : 'Select case to show emails'}}</span>
						<div class="flxClmn fading-overlay" style="flex: 1 0; padding: 5px; background: white; overflow: auto;">
							<div class="flxClmn" style="overflow: hidden; background:#f7f7f7; align-items: center; flex: 1 0;">
								<div class="flxRow hSpace1 w100" style="height: 75px; min-height:75px; padding: 10px; opacity: 0.6; "
									 *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]; let last = last;"
									 [ngStyle]="!last && {'border-bottom': 'solid 1px #e3e4e4'}">
									<div class="flxClmn-center" style="align-items: center;">
										<div style="height: 15px; width: 15px; border-radius: 50%; background: #c4c4c4; opacity: 0.15;"></div>
									</div>
									<div class="flxClmn-between" style="flex: 3 0;">
										<div style="height:15px; max-width: 50%;  background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
										<div style="height: 20px; max-width: 73%; background: #c4c4c4; border-radius: 50px; opacity: 0.2;"></div>
									</div>
									<div class="flxClmn-between" style="flex: 1 0; padding: 3px 0;">
										<div style="height:15px; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
										<div class="flxRow-between">
											<div style="height:15px; width: 25%;  background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
											<div style="height:15px; width: 25%; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
											<div style="height:15px; width: 25%; background: #c4c4c4; border-radius: 50px; opacity: 0.15;"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!--Email list-->
					<div class="flxClmn" style="flex: 1 0; overflow: auto;" *ngIf="activeCase">
						<div class="w100 flxRow animated fadeIn" style="background: #677184; padding: calc(3px + 0.4vw); align-items: center; z-index: 1; min-height: 60px;">
							<div style="width: 20px;">
								<label class="policyCheckbox"
									   (click)="$event.stopPropagation()"
									   style="width: 16px; display: block; vertical-align: unset; position: unset; margin: 0; filter: invert(1) brightness(3);">
									<input type="checkbox" (change)="toggleAllEmails()" [(ngModel)]="isSelectedAll">
									<span style="font-size: 12px;">&nbsp;</span>
								</label>
							</div>

							<div class="flxRow-between hSpace2 w100" style="position: relative; align-items: center;">
								<!--search results input and label-->
								<div class="flxRow-start hSpace1" style="align-items: center; flex: 1 0; max-width: 280px;">
									<search-box [(model)]="searchInboxEmailTxt"
												(modelChange)="searchEmailInResults(searchInboxEmailTxt)"
												[isDisabled]="!archiveService.currentEmailsList.length"
												placeholder="Find in results"
												style="width: 100%;">
									</search-box>
									<span style="color: white; flex-shrink: 0;" *ngIf="!getEmailsInProcess && archiveService.currentFilteredEmailsList.length">
										{{archiveService.currentFilteredEmailsList.length}} email{{archiveService.currentFilteredEmailsList.length > 1 ? 's' : ''}}
									</span>
									<div class="loading-spinner" style="position: relative; top: 0; left: 0; border: 2px solid transparent; border-top-color: white !important; border-left-color: white !important; opacity: 1; height: 15px; width: 15px;"
										 *ngIf="archiveService.currentEmailsList.length && getEmailsInProcess"></div>
								</div>

								<!--action for results-->
								<div class="flxRow hSpace1" style="color: white;">
									<ng-container *ngIf="selectedEmails?.length">
										<div class="flxClmn fadeIn vSpace05" style="align-items: center; cursor: pointer;"
											 (click)="downloadMultipleEmls()">
											<i class="far fa-save" style="font-size: 1.2rem;"></i>
											<span style="font-size: 0.7rem;">Download EMLs</span>
										</div>
										<div class="vertical-separator fadeIn" style="border-color: white; border-left: none; height: unset;"></div>
									</ng-container>

									<div class="flxClmn fadeIn vSpace05" style="align-items: center; cursor: pointer;"
										 *ngIf="archiveService.currentFilteredEmailsList.length"
										 (click)="exportResultsToCsv()">
										<i class="fas fa-file-export" style="font-size: 1.2rem;"></i>
										<span style="font-size: 0.7rem;">Export to csv</span>
									</div>
								</div>
							</div>
						</div>
						<!--results table-->
						<div class="animated fadeIn flxClmn" style="flex: 1 0; overflow: auto; background: white;"
							 *ngIf="archiveService.currentEmailsList?.length || getEmailsInProcess">
							<virtual-scroller class="f-1-0" style="border: solid 1px #cccccc;"
											  #archiveEmailsListScroll
											  [items]="archiveService.currentFilteredEmailsList"
											  [ngClass]="{'is-loading-or-empty' : !archiveService.currentFilteredEmailsList.length}">
								<table class="w100 mailsListTable ellips">
									<tbody #container>
									<tr *ngFor="let email of archiveEmailsListScroll.viewPortItems"
										(click)="getArchiveEmailInfo(email)"
										[ngClass]="{'selected': email.selected, 'is-active': email.show}">
										<td style="width: 20px; padding-left: 8px;">
											<label class="policyCheckbox"
												   (click)="$event.stopPropagation()"
												   style="width: 16px; display: block; vertical-align: unset; position: unset; margin: 0; ">
												<input type="checkbox"
													   (change)="filterSelectedEmails()"
													   [(ngModel)]="email.selected"/>
												<span style="font-size: 12px;">&nbsp;</span>
											</label>
										</td>
										<td>
											<div class="flxClmn vSpace1">
												<div class="flxRow-between">
													<div>
														<span style="opacity: 0.85;">{{email._source.from}}</span>
													</div>
													<span *ngIf="email._source.deleted" style="color: red; padding: 0 5px; flex-shrink: 0; opacity: 1; font-size: 0.7rem; font-family: 'Roboto Condensed', sans-serif;">[Deleted]</span>
												</div>

												<div style="font-weight: 500; font-size: 0.95rem;">
													<span >{{email._source.subject}}</span>
												</div>
											</div>
										</td>
										<td style="width: 150px;">
											<div class="flxClmn-between vSpace05" style="align-items: flex-end;">
												<div style="flex: 1 0;">
													<span>{{ email._source.created | date:'MM/dd/yyyy HH:mm' }}</span>
												</div>
												<div class="flxRow-between hSpace05 w100" style="flex:1 0; align-items: baseline;">
													<span style="visibility: hidden;"></span>
													<div style=" color: darkslategrey;" *ngIf="email._source.attachments.length">
														<span style="font-weight: 400;" >{{email._source.attachments.length}}</span>
														<i class="fas fa-paperclip" style=" font-size: 14px; -webkit-text-stroke: 0.3px #f7f6f9;"></i>
													</div>
													<span style="opacity: 0.5; font-size: 0.6rem;" >{{(email._source.size | FormatBytes: 2)}}</span>
												</div>
											</div>
										</td>
									</tr>
									</tbody>
								</table>
							</virtual-scroller>
							<div *ngIf="!archiveService.currentFilteredEmailsList.length" class="flxClmn-center"
								 style="align-items: center; flex: 1 0; background: white;">
								<div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 0.7;" *ngIf="getEmailsInProcess"></div>
								<span *ngIf="!getEmailsInProcess && archiveService.currentEmailsList.length" style="color: darkgrey; font-size: 18px;">No results for this search</span>
							</div>
						</div>
						<!--placeholder for empty case-->
						<div class="f-1-0 flxClmn centralize" style="background: white;" *ngIf="!getEmailsInProcess && !archiveService.currentEmailsList.length">
							<span style="color: darkgrey; font-size: 18px;">No emails for this query</span>
						</div>
					</div>
				</div>

				<!--Email display area (right side)-->
				<div class="vertical-separator" style="border-color: #e3e4e4; border-left: none;"></div>
				<div class="flxClmn" style="flex: 2 0; align-items: center; background: #f7f7f7; margin-left: 20px; position: relative; overflow: auto;"
					 [ngStyle]="archiveService.currentMessage?.content?.parsedContent && activeCase && {'background':' transparent'} ">
					<!--loading overlay-->
					<div class="flxClmn-center animated fadeIn" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 5; background: rgba(255,255,255,0.6); align-items: center;"
						 *ngIf="previewEmailInProcess">
						<div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 1;"></div>
					</div>
					<!----->
					<div class="flxClmn-center vSpace2 w100" style="flex:1 0; overflow: auto;"
						 *ngIf="archiveService.currentMessage?.content?.parsedContent">
						<!--tabs-->
						<div class="simple-tab-row w100 flxRow">
							<div class="tab-header"
								 [ngClass]="{'chosen' : archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.content}"
								 (click)="archiveService.tab = dic.CONSTANTS.archiveEmailViewPages.content">
								<span class="hvr-opacity">Email Content</span>
							</div>
							<div class="tab-header" style="overflow: auto;"
								 [ngClass]="{'chosen' : archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.headers}"
								 (click)="archiveService.tab = dic.CONSTANTS.archiveEmailViewPages.headers">
								<span class="hvr-opacity">Headers</span>
							</div>
							<div class="tab-header"
								 *ngIf="archiveService.currentMessage._source.emailId"
								 [ngClass]="{'chosen' : archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.tracking}"
								 (click)="archiveService.tab = dic.CONSTANTS.archiveEmailViewPages.tracking">
								<span class="hvr-opacity">Outbound Tracking</span>
							</div>
							<div class="tab-header"
								 *ngIf="archiveService.currentMessage._source.quarantineIds && archiveService.currentMessage._source.quarantineIds.length"
								 [ngClass]="{'chosen' : archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.quarantined}"
								 (click)="archiveService.tab = dic.CONSTANTS.archiveEmailViewPages.quarantined">
								<span class="hvr-opacity">Inbound Analysis</span>
							</div>
						</div>

						<div class="flxClmn" style="flex: 1 0; overflow: auto;">
							<archive-email-details-component *ngIf="archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.content"
															 [origin]="'user'"
															 class="flxClmn f-1-0" style="overflow: auto;" ></archive-email-details-component>
							<archive-email-headers-component *ngIf="archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.headers"
															 class="flxClmn" style="flex: 1 0; overflow: auto;"></archive-email-headers-component>
							<archive-email-tracking-component *ngIf="archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.tracking"
															  [origin]="'user'"
															  class="flxClmn" style="flex: 1 0; overflow: auto;"></archive-email-tracking-component>
							<archive-email-quarantined-component *ngIf="archiveService.tab === dic.CONSTANTS.archiveEmailViewPages.quarantined"
																 [origin]="'user'"
																 class="flxClmn" style="flex: 1 0; overflow: auto;"></archive-email-quarantined-component>
						</div>
					</div>

					<div *ngIf="!archiveService.currentMessage && !previewEmailInProcess" class="flxClmn-center"
						 style="align-items: center; flex: 1 0;">
						<i class="fas hvr-opacity fa-envelope-open-text" style="font-size: 4vw;  opacity: 0.1;"></i>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="!archiveEnabled" class="page-inner-content-holder no-scroll flxClmn">
			<div>
				<div style="text-align: center; margin: 0 auto; width:70%;">
					<i style="opacity:0.4;" class="zmdi zmdi-hc-4x"></i>
					<h1 style="opacity:0.4; font-size: 50px;">Archive is disabled</h1>
					<span>Contact support or your plan admin for more details</span>
					<br/>
					<hr/>
				</div>
			</div>
		</div>
	</div>
</div>
