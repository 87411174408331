<div class="flxClmn" style="flex: 1 0; overflow: auto; padding: 0 15px;">
    <div class="flxRow">
        <div class="flxClmn vSpace05" style="font-size: calc(0.45rem + 0.4vw); color: grey; flex:1 0;">
            <div>
                <h2 style="color: black; margin: 0; font-size: calc(0.75rem + 0.7vw);">{{archiveService.currentMessage.content.parsedContent.subject}}
                    <span style="font-size: 1rem; color: red; font-weight: normal; padding: 0 10px; font-family: 'Roboto Condensed' ,sans-serif;" *ngIf="archiveService.currentMessage._source.deleted">[deleted]</span></h2>
            </div>

            <span>From: {{archiveService.currentMessage.content.parsedContent.from.text}}</span>
            <!--to-->
			<div class="flxRow hSpace05" *ngIf="archiveService.currentMessage.content.parsedContent.to?.value?.length">
				<span>To: </span>
				<span>
					{{showFullToStr ? archiveService.currentMessage.content.parsedContent.recipientsToStrFull : archiveService.currentMessage.content.parsedContent.recipientsToStr}}&nbsp;
					<a class="blue-link" style="text-decoration: underline;"
					   *ngIf="archiveService.currentMessage.content.parsedContent.recipientsToStr && archiveService.currentMessage.content.parsedContent.recipientsToStrFull !== archiveService.currentMessage.content.parsedContent.recipientsToStr"
					   (click)="showFullToStr = !showFullToStr">
						{{showFullToStr ? 'Hide' : 'Show all (' + archiveService.currentMessage.content.parsedContent.to.value.length + ')'}}
					</a>
				</span>
			</div>

            <!--Cc-->
			<div class="flxRow hSpace05" *ngIf="archiveService.currentMessage.content.parsedContent.cc?.value?.length">
				<span>Cc: </span>
				<span>
					{{showFullCcStr ? archiveService.currentMessage.content.parsedContent.recipientsCcStrFull : archiveService.currentMessage.content.parsedContent.recipientsCcStr}}&nbsp;
					<a class="blue-link" style="text-decoration: underline;"
					   *ngIf="archiveService.currentMessage.content.parsedContent.recipientsCcStr && archiveService.currentMessage.content.parsedContent.recipientsCcStrFull !== archiveService.currentMessage.content.parsedContent.recipientsCcStr"
					   (click)="showFullCcStr = !showFullCcStr">
						{{showFullCcStr ? 'Hide' : 'Show all (' + archiveService.currentMessage.content.parsedContent.cc.value.length + ')'}}
					</a>
				</span>
			</div>

			<!--Bcc-->
			<div class="flxRow hSpace05" *ngIf="archiveService.currentMessage.content.parsedContent.bcc?.value?.length">
				<span>Bcc: </span>
				<span>
					{{showFullBccStr ? archiveService.currentMessage.content.parsedContent.recipientsBccStrFull : archiveService.currentMessage.content.parsedContent.recipientsBccStr}}&nbsp;
					<a class="blue-link" style="text-decoration: underline;"
					   *ngIf="archiveService.currentMessage.content.parsedContent.recipientsBccStr && archiveService.currentMessage.content.parsedContent.recipientsBccStrFull !== archiveService.currentMessage.content.parsedContent.recipientsBccStr"
					   (click)="showFullBccStr = !showFullBccStr">
						{{showFullBccStr ? 'Hide' : 'Show all (' + archiveService.currentMessage.content.parsedContent.bcc.value.length + ')'}}
					</a>
				</span>
			</div>

            <!--Date-->
            <span>Sent: {{archiveService.currentMessage.content.parsedContent.date | date:'MM/dd/yyyy HH:mm'}}</span>
        </div>
        <div class="flxClmn vSpace1 h100" style="width: 150px; color: dimgrey; cursor: pointer;">
            <div class="flxRow-start hvr-darken hSpace05" style="align-items: center;"
                 (click)="downloadEml(archiveService.currentMessage)">
                <i class="far fa-save" style="font-size: 1.2rem; cursor: pointer;"></i>
                <span style="opacity: 0.7; font-size: 0.7rem;">Download as EML</span>
            </div>
            <div class="flxRow-start hvr-darken hSpace05" style="align-items: center;"
                 (click)="printEmail()">
                <i class="fas fa-print" style="font-size: 1.2rem; cursor: pointer;"></i>
                <span style="opacity: 0.7; font-size: 0.7rem;">Print</span>
            </div>
            <div class="flxRow-start hvr-darken hSpace05" style="align-items: center;"
                 *ngIf="!archiveService.currentMessage._source.deleted && origin !== 'user'"
                 (click)="deleteEmail(archiveService.currentMessage)">
                <i class="far fa-trash-alt" style="font-size: 1.2rem; cursor: pointer; color: red;"></i>
                <span style="opacity: 0.7; font-size: 0.7rem;">Delete</span>
            </div>
            <div class="flxRow-start hvr-darken hSpace05" style="align-items: center; color: steelblue;"
                 *ngIf="!archiveService.currentMessage._source.deleted"
                 (click)="restoreEmail(archiveService.currentMessage)">
                <i class="fas fa-recycle" style="font-size: 1.2rem; cursor: pointer;"></i>
                <span style="opacity: 0.7; font-size: 0.7rem;">Restore email</span>
            </div>
        </div>
    </div>
    <hr/>
    <div style="flex:1 0; overflow: auto; min-height: 250px;">
		<email-viewer [content]="archiveService.emailContent" style="min-height: 100%;"></email-viewer>
    </div>
    <hr/>
    <div class="flxRow hSpace2" style="margin: 0;"
         *ngIf="archiveService.currentMessage.content.parsedContent.attachments?.length">
        <div class="flxRow" style="flex:1 0; max-height: 66px; flex-wrap: wrap; overflow: auto;">
			<ng-container *ngFor="let file of archiveService.currentMessage.content.parsedContent.attachments">
				<attachment-c
					*ngIf="file.contentDisposition === 'attachment'"
					[attName]="file.filename"
					[attSize]="file.size"
					(preview)="previewAttachment = file;"
					(download)="downloadAttachment(file.content, file.filename, file.type)">
				</attachment-c>
			</ng-container>
        </div>
    </div>

	<popup-c *ngIf="restoreEmailPopup?.show" class="bs-enabled overflownV"
			 head="Restore Email" size="dynamic"
			 (closeCb)="restoreEmailPopup.show = false;"
			 (confirmCb)="restoreEmailPopup?.doneCb()"
			 [buttons]="{cancel: 'Cancel', confirm: {text: 'Restore', disabled: restoreEmailPopup.applyInProcess}}">
		<div class="flex-column f-1-0 gap-2" style="overflow: visible;">
			<div>
				The following email will be restored from archive: <br/><b>{{restoreEmailPopup.emailsSubject}}</b>
			</div>

			<div *ngIf="restoreEmailPopup.mailboxes.length === 1">
				Email will be restored to: <b>{{restoreEmailPopup.mailboxes[0].mailbox}}</b>
			</div>

			<div class="flex-column f-1-0 gap-2 p-2" *ngIf="restoreEmailPopup.mailboxes.length > 1">
				<p>Select which recipients this email should be restored to:</p>
				<label class="radio-container m-0" style="display: inline-block;">
					All recipients
					<input type="radio" name="recipientsSelection"
						   [(ngModel)]="restoreEmailPopup.recipientSelection"
						   [value]="restoreEmailPopup.recipientSelectionTypes.all"
						   [checked]="restoreEmailPopup.recipientSelection === restoreEmailPopup.recipientSelectionTypes.all">
					<span class="checkmark"></span>
				</label>
				<div class="flex-row gap-1 align-items-center" *ngIf="restoreEmailPopup.mailboxes.length > 1">
					<label class="radio-container m-0" style="display: block; width: fit-content;">
						Specific recipients:
						<input type="radio" name="recipientsSelection"
							   [(ngModel)]="restoreEmailPopup.recipientSelection"
							   [value]="restoreEmailPopup.recipientSelectionTypes.specific"
							   [checked]="restoreEmailPopup.recipientSelection === restoreEmailPopup.recipientSelectionTypes.specific">
						<span class="checkmark"></span>
					</label>
					<app-dropdown-c style="width: 260px; height: 35px;"
									[items]="restoreEmailPopup.mailboxes"
									[isMultipleSelection]="true" [isDisabled]="restoreEmailPopup.recipientSelection === restoreEmailPopup.recipientSelectionTypes.all"
									itemDisplayKey="mailbox" [error]="restoreEmailPopup.errorMessage && restoreEmailPopup.recipientSelection === restoreEmailPopup.recipientSelectionTypes.specific">
					</app-dropdown-c>
				</div>
			</div>
		</div>
	</popup-c>

	<attachment-preview
		*ngIf="previewAttachment"
		[attachment]="previewAttachment"
		(onClose)="previewAttachment = null;">
	</attachment-preview>
</div>

