import {DICTIONARY} from '../../dictionary';
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
	selector: 'onboarding-popup-component',
	templateUrl: './onboarding-popup.component.html',
})
export class OnboardingPopupComponent implements OnInit{
	@Input() navigateToStep;
	@Input() userOnboardingInfo:any = {};
	@Output() closeCb = new EventEmitter<any>;

	constructor() {
	}

	dic = DICTIONARY;
	currentSetupScope;
	state;


	ngOnInit() {
		// check if user navigated to page through onboarding step and now is opening it at the same page, expecting to continue with the same step
		if (this.navigateToStep) {
			this.updateCurrentState(this.dic.CONSTANTS.onboardingSteps.state.steps);
		}

		// questionnaire not done:
		else if (this.userOnboardingInfo.questionnaire_step === this.dic.CONSTANTS.onboardingProcessQuestionnaireStep.init) {
			this.updateCurrentState(this.dic.CONSTANTS.onboardingSteps.state.questionnaireNew);
		}
		// questionnaire is pending support:
		else if (this.userOnboardingInfo.questionnaire_step === this.dic.CONSTANTS.onboardingProcessQuestionnaireStep.pendingSupport){
			this.updateCurrentState(this.dic.CONSTANTS.onboardingSteps.state.questionnaire);
		}
		// questionnaire is completed:
		else {
			this.updateCurrentState(this.dic.CONSTANTS.onboardingSteps.state.welcome);
		}
	}

	questionnaireDone = (questionnaireStatusInfo=this.userOnboardingInfo.questionnaire_step) => {
		// questionnaire pending support:
		if (questionnaireStatusInfo === this.dic.CONSTANTS.onboardingProcessQuestionnaireStep.pendingSupport) {
			this.updateCurrentState(this.dic.CONSTANTS.onboardingSteps.state.questionnaireNew);
		}
		// questionnaire is done:
		else {
			this.updateCurrentState(this.dic.CONSTANTS.onboardingSteps.state.welcome);
		}
	}


	updateSetupScope = (scope) => {
		this.currentSetupScope = {
			name: scope,
			userStepsInfo: scope === this.dic.CONSTANTS.onboardingSetupScopes.outbound ?
				this.userOnboardingInfo.outbound_steps :
				this.userOnboardingInfo.inbound_steps
		};
		this.updateCurrentState(this.dic.CONSTANTS.onboardingSteps.state.steps)
	}

	updateCurrentState = (state) => {
		this.state = state;
	}

}
