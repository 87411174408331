<div class="flxClmn bs-enabled">

    <div class="page-title-container flxClmn-center" style="z-index: 45;">
		<div class="page-title-inner-container">
			<span class="page-title">Domain Spoofing Control</span>
		</div>
		<div class="page-subtitle-container">
			<span>For these domains, Trustifi will uniquely sign the source of received emails which will be used to overcome weaknesses of traditional email authentication standards and prevent spoofing and impersonation attacks</span>
		</div>
	</div>

	<div class="page-inner-content-holder no-scroll flxClmn" *ngIf="config">

		<!--strict check table-->
		<trustifi-table-c
			class="trustifi-table" style="flex: 1 0; z-index: 5;"
			[list]="config.threat_protection.known_domains"
			[headers]="[
                {text: 'Created', sortKey: 'created'},
                {text: 'Domain', sortKey: 'domain'},
                {text: 'Type', sortKey: 'type'},
                {text: 'Sources', sortKey: 'signature'},
                {text: 'Strength', sortKey: 'signatureStrengthAvg'},
            ]"
            [cellsPrototype]="[
                {textKey: 'created', textType: 'dateAndTime'},
                {textKey: 'domain', tooltip: true, edit: {modelKey:'domain', placeholder:'Domain'}},
                {textKey: 'type'},
                {textKey: 'signature'},
                {html: 'avgStrengthCell', overflowOutside: true},
            ]"
            [bulkActions] = "{
                enable: true,
                selectBulkActionCb:	selectMultipleDomainAction,
                initBulkActionsFunction: showDomainBulkActions,
                showInRed: {enable: true, terms: ['Remove']}
            }"
            [cellActions] = "{
                enable: true,
                isSmaller: true,
                selectItemActionCb:	selectDomainAction,
                initActionsFunction: showDomainActions,
                showInRed: {enable: true, terms: ['Remove']}
            }"
            (searchFunction)="searchDomainsList($event.searchTerm, $event.activeFilters)"
            [options]="{
                exportToCsvFunction: exportDomainsListToCsv,
                itemsNameSingular: 'domain',
                defaultSortKey: '-created',
                loadingTableFlag: getTPConfigInProcess,
                showCounter: true
            }"
    >
        <!--strength cells-->
		<ng-template #customCells let-item let-cellId="cellId">
			<!--action cells-->
			<div *ngIf="cellId === 'avgStrengthCell'">
				<div *ngIf="item.signatureStrengthAvg !== undefined" class="flxRow-center mh-100" style="align-items: center; width: 200px; height: 60px;">
					<apx-chart class="f-1-0 mh-100 score-chart"
							   [series]="item.scoreChartOptions.series"
							   [colors]="item.scoreChartOptions.fill.colors"
							   [chart]="item.scoreChartOptions.chart"
							   [labels]="item.scoreChartOptions.labels"
							   [plotOptions]="item.scoreChartOptions.plotOptions"></apx-chart>
				</div>
			</div>
		</ng-template>
        <!---->
        <div tableButtons>
            <div class="flxRow hSpace05">
                <btn-c endIcon="fa fa-plus"
						   (action)="openAddDomainPopup();">
                    Add Domain
                </btn-c>
            </div>
        </div>
    </trustifi-table-c>
    </div>

    <!--Domain Sources popup-->
    <popup-c *ngIf="domainSourcesPopup?.show"
           head="Domain Sources for <b>{{domainSourcesPopup.domainObj.domain}}</b>"
           (closeCb)="domainSourcesPopup.show = false"
           [buttons]="{cancel: {text: 'OK', loading: sourceActionInProcess}}" size="fullscreen">
        <div class="flxClmn vSpace1 f-1-0" style="overflow: auto;">
            <span>Listed below are all the sources that are considered verified for this domain. Each source will display information about it's origin and identity and a breakdown of the authentication standards.
              <br/>Sources are displayed according to the type of email - regular emails, calendar invites, Non-Delivery Reports (NDR) and Out of Office messages (OOF). Click on the relevant tab to view these sources.
            </span>
            <br/>
            <div class="flxRow hSpace5" style="align-items: flex-end;">
                <div class="md-tabs" [ngStyle]="{'--md-tabs-width': 100 + 'px'}">
                    <div *ngFor="let sourceObj of dic.CONSTANTS.domainSourceTypes | keyvalue: gs.returnZero"
                         [ngClass]="{'selected' : domainSourcesPopup.activeType === sourceObj.value}"
                         (click)="changeSourceType(sourceObj.value);">
                        <input type="radio" name="tabs" id="{{sourceObj.key}}Toggle" [checked]="domainSourcesPopup.activeType === sourceObj.value">
                        <label for="{{sourceObj.key}}Toggle">{{sourceObj.value}}</label>
                    </div>
                    <div class="slide" [ngStyle]="{'left' : 100 * _.values(dic.CONSTANTS.domainSourceTypes).indexOf(domainSourcesPopup.activeType) + 'px' || 0}"></div>
                </div>
            </div>

            <!--domain sources table-->
            <trustifi-table-c
                    class="trustifi-table" style="flex: 1 0; z-index: 5;"
                    [list]="domainSourcesPopup.sources"
                    [headers]="[
                                        {text: 'Authentication Standards'},
                                        {text: 'Header From Domain'},
                                        {text: 'Mail From Domain'},
                                        {text: 'Received SPF'},
                                        {text: 'Sending MTA'},
                                        {text: 'Message ID'},
                                        {text: 'Strength', sortKey: 'strength.score', tooltip: 'Source strength according to Authentication Standards'},
                                        {text: 'Last Seen', sortKey: 'last_seen'},
                                    ]"
                    [cellsPrototype]="[
                                                {html: 'authStandardsCell'},
                                                {html: 'headerFromCell'},
                                                {html: 'mailFromCell'},
                                                {html: 'receivedSpfCell'},
                                                {html: 'sendingMtaCell'},
                                                {html: 'messageIdCell'},
                                                {html: 'flaggedCell', overflowOutside: true},
                                                {textKey: 'last_seen', textType: 'dateAndTime'},
                                            ]"
                    [bulkActions] = "{
                                                enable: false,
                                                selectBulkActionCb:	selectMultipleSourcesAction,
                                                initBulkActionsFunction: showBulkSourcesActions,
                                                showInRed: {enable: true, terms: ['Remove']}
                                            }"
                    [cellActions] = "{
                                                enable: true,
                                                selectItemActionCb:	selectSourceAction,
                                                initActionsFunction: showSourceActions,
                                                showInRed: {enable: true, terms: ['Remove']}
                                            }"
                    (searchFunction)="searchSource($event.searchTerm, $event.activeFilters)"
                    [options]="{
                                        itemsNameSingular: 'source',
                                        defaultSortKey: '-last_seen',
                                        showCounter: true
                                    }"
            >
				<ng-template #customCells let-item let-cellId="cellId">
					<!--auth standards cells-->
					<div *ngIf="cellId === 'authStandardsCell'">
						<div class="flxClmn" style="font-size: 0.75rem; line-height: 1.36;">
							<div class="flxRow">
								<span style="flex-basis: 52px;">SPF:</span>
								<span [ngStyle]="{'color' : !item.authenticationResults.spf || item.authenticationResults.spf === 'none' ? 'darkorange' : (item.internal || ['pass','bestguesspass'].includes(item.authenticationResults.spf)) ? 'darkgreen' : 'darkred'}">{{item.authenticationResults.spf || 'none'}}</span>
							</div>
							<div class="flxRow">
								<span style="flex-basis: 52px;">DKIM:</span>
								<span [ngStyle]="{'color' : !item.authenticationResults.dkim || item.authenticationResults.dkim === 'none' ? 'darkorange' : (item.internal || ['pass','bestguesspass'].includes(item.authenticationResults.dkim)) ? 'darkgreen' : 'darkred'}">{{item.authenticationResults.dkim || 'none'}}</span>
							</div>
							<div class="flxRow">
								<span style="flex-basis: 52px;">DMARC:</span>
								<span [ngStyle]="{'color' : !item.authenticationResults.dmarc || item.authenticationResults.dmarc === 'none' ? 'darkorange' : (item.internal || ['pass','bestguesspass'].includes(item.authenticationResults.dmarc)) ? 'darkgreen' : 'darkred'}">{{item.authenticationResults.dmarc || 'none'}}</span>
							</div>
						</div>
					</div>

					<!--header from cells-->
					<div *ngIf="cellId === 'headerFromCell'">
						<span>{{item.authenticationResults.headerFrom || '-'}}</span>
					</div>

					<!--mail from cells-->
					<div *ngIf="cellId === 'mailFromCell'">
						<span>{{item.authenticationResults.mailFrom || '-'}}</span>
					</div>

					<!--received spf cells-->
					<div *ngIf="cellId === 'receivedSpfCell'" class="hSpace05">
						<span>{{item.receivedSPF.domain || '-'}}</span>
						<span *ngIf="item.receivedSPF.status">({{item.receivedSPF.status}})</span>
					</div>

					<!--sending mta cells-->
					<div *ngIf="cellId === 'sendingMtaCell'">
						<span>{{item.sendingMTA.domain || '-'}}</span>
						<span *ngIf="item.sendingMTA.status">({{item.sendingMTA.status}})</span>
					</div>

					<!--message id cells-->
					<div *ngIf="cellId === 'messageIdCell'">
						<span style="padding-right: clamp(5px,1vw,15px);">{{item.messageID.domain || '-'}}</span>
					</div>

					<!--strength cells-->
					<div *ngIf="cellId === 'flaggedCell'">
						<div class="flxRow-center mh-100" style="align-items: center; width: 200px; height: 60px;" tooltip="{{item.strength.description}}" [options]="{tooltipClass: 'score-chart-tooltip'}" *ngIf="item.scoreChartOptions">
							<apx-chart class="f-1-0 mh-100 animated fadeIn score-chart"
									   [series]="item.scoreChartOptions.series"
									   [fill]="item.scoreChartOptions.fill"
									   [stroke]="item.scoreChartOptions.stroke"
									   [chart]="item.scoreChartOptions.chart"
									   [labels]="item.scoreChartOptions.labels"
									   [plotOptions]="item.scoreChartOptions.plotOptions"></apx-chart>
						</div>
					</div>
				</ng-template>
            </trustifi-table-c>
		</div>
	</popup-c>

	<!--Domain Sources popup-->
	<popup-c *ngIf="domainAnalyzePopup?.show"
			 head="Analyze Domain"
			 subTitle="Results for the analysis of the domain: <b>{{domainAnalyzePopup.domainObj.domain}}</b>"
			 (closeCb)="domainAnalyzePopup.show = false"
			 size="large" width="1350px">
		<div class="flex-column gap-2 f-1-0" style="overflow: auto;" *ngIf="!domainAnalyzePopup.loading && !domainAnalyzePopup.globalError">
			<div class="flex-row w-100" style="height: auto;" *ngFor="let category of domainAnalyzePopup.dataCategories">
				<div class="flex-column centralize border bg-light" style="flex-basis: 90px;">
					<span class="fw-bold text-uppercase">{{category}}</span>
				</div>
				<div class="flex-column border f-1-0 px-4 py-3 gap-4 overflow-auto" style="margin-left: -1px;"
					 [ngClass]="{'border-danger' : domainAnalyzePopup[category]?.error}">
					<div class="border w-100 flex-row align-items-center gap-3 p-2" style="margin-top: -10px; background: #ebf9c8;"
						 *ngIf="domainAnalyzePopup[category]?.record">
						<span class="flex-shrink-0">DNS Record:</span>
						<span class="fw-500" style="word-break: break-word; white-space: normal;">{{domainAnalyzePopup[category]?.record}}</span>
						<a class="px-2" style="text-align: right;"
						   (click)="copyToClipboard(domainAnalyzePopup[category]?.record);" >
							<i class="fas fa-copy copy-link" style="opacity: 0.7; font-size: 1.1rem;" tooltip="Copy DNS record"></i>
						</a>
					</div>
					<div class="flex-column gap-3"
						 *ngIf="!domainAnalyzePopup[category]?.error">
						<ng-container *ngFor="let dataBlock of domainAnalyzePopup[category]?.data">
							<div class="flex-row align-items-center gap-2" style="font-size: 1rem; font-weight: bold;">
								<span class="text-capitalize">{{dataBlock.title}}</span>
							</div>
							<div class="flex-column gap-2"
								 *ngIf="dataBlock.comments && dataBlock.comments.length">
								<ng-container *ngFor="let comment of dataBlock.comments">
									<div class="flex-row gap-1">
										<i [ngClass]="dataBlock.commentsIcon" style="flex-basis: 17px;"></i>
										<span>{{comment}}</span>
									</div>
								</ng-container>
							</div>
							<span class="flex-row gap-1" *ngIf="!dataBlock.comments || !dataBlock.comments.length">
								<span class="fa fa-check text-success" style="flex-basis: 17px;"></span><!--PADDER-->
								<span>No {{dataBlock.title}} found.</span>
							</span>
						</ng-container>
                    </div>
                    <div class="flex-row align-items-center f-1-0 gap-2 py-2" *ngIf="domainAnalyzePopup[category]?.error">
                        <i class="fa fa-times-circle text-danger"></i>
                        <span class="fw-500">{{domainAnalyzePopup[category]?.error}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-column centralize f-1-0" *ngIf="domainAnalyzePopup.loading || domainAnalyzePopup.globalError">
            <loader class="opacity-75" *ngIf="domainAnalyzePopup.loading" height="100" width="100"></loader>
            <span *ngIf="domainAnalyzePopup.globalError">{{domainAnalyzePopup.globalError}}</span>
        </div>
    </popup-c>

	<popup-c *ngIf="addDomainPopup?.show"
			 head="Add Domain" size="dynamic"
			 (closeCb)="addDomainPopup = null"
			 (confirmCb)="confirmAddNewDomain();"
			 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: addDomainPopup.addDomainInProcess}}">
		<div class="flex-column gap-3 f-1-0">
			<span>Enter a new domain for Domain Spoofing Control</span>
			<input type="text" style="margin: 0; width: 250px;" placeholder="Domain" [(ngModel)]="addDomainPopup.domain"/>
		</div>
	</popup-c>
</div>


