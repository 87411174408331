<div class="flxClmn">
    <div class="page-title-container flxRow">
        <div class="flxClmn-center f-1-0">
            <div class="page-title-inner-container flxRow">
                <span class="page-title">Mailbox Management</span>
                <!--Information modal-->
                <more-info-c>
                    <div class="flxClmn vSpace05" style="flex: 1 0;">
                        <span style="font-weight: bold;">Actions</span>
                        <br/>
                        <span><b>Enable API Protection</b> - enable Inbound Shield protection for this mailbox</span>
                        <span style="vertical-align: middle;"><b>Disable API Protection</b> - disable Inbound Shield protection for this mailbox</span>
                    </div>
                    <div class="flxClmn vSpace05" style="flex: 1 0;">
                        <span style="font-weight: bold;">Status</span>
                        <br/>
                        <span><b style="color: green;">Protected</b> - Inbound Shield is protecting the mailbox from external threats</span>
                        <span style="vertical-align: middle;"><b style="color: red;">Exposed</b> - the mailbox is vulnerable to external threats and attacks</span>
                        <span style="vertical-align: middle;"><b style="color: red;">Inactive</b> - the mailbox has not received any emails in the past 40 days</span>
                    </div>
                </more-info-c>
            </div>
            <div class="page-subtitle-container" style="display: flex; gap: 5px;">
                <span class="page-subtitle">Protect and manage mailboxes under your Mail Server.</span>
                <span style="font-weight: bold; font-size: 1rem;"
                      [ngClass]="{'d-none' : protectedUsersCount || threatProtectionAllowedUsers}"
                      *ngIf="isLoaded">(you use {{protectedUsersCount || 0}} licenses out of {{threatProtectionAllowedUsers || 0}})
                </span>
            </div>
        </div>
        <div class="control-and-info-icons flxRow"></div>
    </div>

    <div class="page-inner-content-holder no-scroll flxClmn" id="sharedplanactiveMobile">
        <!-- CONTENT : Loader -->
        <div *ngIf="!isLoaded" style="align-items: center; flex:1 0;" class="animated fadeIn flxClmn-center">
            <loader height="100" width="100" style="opacity: 0.8;"></loader>
        </div>

        <div *ngIf="isLoaded && !mailServerConnected">
            <div style="text-align: center; margin: 0 auto; width:70%;">
                <i style="opacity:0.4;" class="zmdi zmdi-hc-4x"></i>
                <h1 style="opacity:0.4; font-size: 50px;">Inbound Shield is disabled</h1>
                <span>You can enable Inbound Shield using the Email Server API Connection or the Inbound Email Relay from the <a style="font-weight: bold;" (click)="goToPlanSettingsPage();">Plan Settings</a> page</span>
                <br/>
                <hr/>
                <btn-c style="margin: auto;"
                     (action)="goToPlanSettingsPage();">
                    Plan Settings
                </btn-c>
            </div>
        </div>

        <!-- CONTENT : Mailboxes table + buttons -->
        <div class="flxRow-between table-upper-row-container" style="z-index: 100; padding: 0 4px 3px 0; align-items: center;  border-bottom: none;"
             *ngIf="isLoaded && mailServerConnected">
            <!--buttons above table-->
            <div class="flxRow hSpace05" style="align-items: flex-end; height: 30px;">
                <app-dropdown-c style="width: 150px; height: 30px;" text="API Protection"
                              [isLf]="true"
                              [isDisabled]="!subscriptionUsers.length || !mailServerConnected">
                    <li (click)="selectMultipleEmailsAction(subscriptionUsers, dic.CONSTANTS.inboundMailboxAction.enable.value)">
						Protect All
                    </li>
                    <li (click)="openProtectGroupsPopup(true);">
						Protect Groups
                    </li>
					<li (click)="enableApiProtection(subscriptionUsers, {limitedMailboxes: true})">
						Protect Licensed Mailboxes
					</li>
                    <li ngfSelect
						[(file)]="mailboxesFile"
                        accept=".csv"
                        (fileChange)="protectMailboxesFromCsv(mailboxesFile)">
						Protect From CSV
                    </li>
                </app-dropdown-c>
                <btn-c style="width: 140px;"
                     [loading]="{flag: loadingInProcess, text: 'Sync Mailboxes'}"
                     [isDisabled]="loadingInProcess || !mailServerConnected"
                     (action)="getMailboxesRefresh();"
                     startIcon="fa fa-sync">
                    Sync Mailboxes
                </btn-c>
                <app-dropdown-c style="width: 150px; height: 30px;" text="More Actions" [isLf]="true">
                    <li (click)="getGroups(true)">
                        Show Groups
                    </li>
                    <li (click)="getMailboxesAliases(true)">
                        Show Aliases
                    </li>
                </app-dropdown-c>
            </div>

            <!--table actions & filtering-->
            <div class="flxRow gap-3 table-actions-container" style="gap: 1rem; align-items: flex-end; align-self: flex-end;">
                <!--counter-->
                <span *ngIf="subscriptionUsers.length" style="color: darkgrey; padding: 5px;">
                         {{displayedUsersCount}} record{{displayedUsersCount === 1 ? '' : 's'}}
                    </span>
                <div class="flxRow hide-when-empty" style="align-items: center; border: solid 1px #dedede; position: relative; height: fit-content; min-height: 28px;">
					<search-box [isDisabled]="!mailServerConnected"
								[(model)]="searchMailboxTxt"
								(modelChange)="searchMailbox()"
								placeholder="Find in results"
								style="width: 300px; height: 33px; border: none; border-radius: 0; border-right: 1px solid #ebebeb;">
					</search-box>
                    <div class="h100" style="position: relative;">
                        <!--change view-->
                        <a class="flxRow-center" style="align-items: center;  padding: 5px 7px; font-size: 16px;"
						   id="tableViewDropdownMenu"
                           tooltip="Change table view"
                           (ngInit)="showTableViewMenu = false;"
                           (click)="showTableViewMenu = !showTableViewMenu;">
                            <i class="fa fa-stream flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
                        </a>
                        <ul style="z-index: 1006; position: absolute; top: 36px; right: 0; background: white; box-shadow: 0 1px 4px grey; margin: 0; list-style: none; padding: 0; width: 180px;"
                            *ngIf="showTableViewMenu"
                            (clickOutside)="showTableViewMenu = false;" [exclude]="'#tableViewDropdownMenu'">
                            <li class="flxClmn-center" style="height: auto; min-height: 35px; border:0; text-transform: capitalize;"
                                id="viewPerCompanyMenuItem"
                                [ngStyle]="{'font-weight' : mailboxListView === dic.CONSTANTS.mailboxListViews.all ? 'bold' : '' , 'pointer-events' : mailboxListView === dic.CONSTANTS.mailboxListViews.all ? 'none' : ''}"
                                (click)="updateListView(dic.CONSTANTS.mailboxListViews.all); showTableViewMenu = false;">
                                <a class="h100 w100" style="align-items: center; padding: 0 15px;">
                                    View per mailbox
                                </a>
                            </li>
                            <li class="flxClmn-center" style="height: auto; min-height: 35px; border:0; text-transform: capitalize;"
                                id="viewPerDomainMenuItem"
                                [ngStyle]="{'font-weight' : mailboxListView === dic.CONSTANTS.mailboxListViews.domains ? 'bold' : '' , 'pointer-events' : mailboxListView === dic.CONSTANTS.mailboxListViews.domains ? 'none' : ''}"
                                (click)="updateListView(dic.CONSTANTS.mailboxListViews.domains); showTableViewMenu = false;">
                                <a class="h100 w100" style="align-items: center; padding: 0 15px;">
                                    View per domain
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--filter-->
                    <a style="align-items: center; font-size: 16px; padding: 5px 7px; position: relative;"
                       *ngIf="filterData"
                       tooltip="{{activeFilters ? 'Filter results - Active' : 'Filter results'}}"
                       (click)="toggleFilter()"
                       [ngStyle]="{'color': activeFilters ? 'var(--bs-primary)' : ''}">
                        <i class="fas fa-filter flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
                        <i class="fas fa-plus" style="position: absolute; bottom: 9px; right: 7px; font-size: 8px;"></i>
                        <i class="animated fadeIn fas fa-circle" style="position: absolute; top: 4px; right: 7px; font-size: 8px; filter: drop-shadow(-2px 1px 1px white); color: #00d100;"
                           *ngIf="activeFilters"></i>
                    </a>
                    <filter-table-c
                            style="position: absolute; bottom: -3px; transform: translateY(100%); right: 0; --table-filter-min-width: 825px;"
                            *ngIf="filterData"
                            [filterData]="filterData"
                            (applyCb)="applyFilters($event.activeFilters)"
                            (clearCb)="clearFilters()">
                    </filter-table-c>
                    <!--export csv-->
                    <a style="align-items: center;  padding: 5px 7px; font-size: 16px;"
                       [ngClass]="{'disabled' : displayedUsersCount === 0 || createSubscriptionInProcess}"
                       (click)="exportMailboxesCsv();"
                       tooltip="Export results to CSV">
                        <i class="fa fa-file-export flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
                    </a>
                </div>
            </div>
        </div>

        <!--table-->
        <div class="animated fadeIn"
             *ngIf="isLoaded && mailServerConnected"
             style="flex:1 0 0; overflow: auto; width: 100%; border: 1px solid #f9f9f9; background: #f5f5f5; border-radius: 5px; position: relative; z-index: 1;">
            <div class="listTable-c alternated flxClmn ellips" style="height: 100%; background: white;"
				 #mailboxesTable
                 id="mailboxes-table">
				<!--sticky header clone (fixed)-->
				<table><ng-template [ngTemplateOutlet]="headerContainer"></ng-template></table>
				<!---->
				<virtual-scroller class="f-1-0" #previewListScroll [items]="filteredActiveMailboxList" [parentScroll]="mailboxesTable" [scrollAnimationTime]="0" [enableUnequalChildrenSizes]="true">
					<table style="width: 100%; table-layout: fixed;">
						<!--original header (hidden)-->
						<ng-template [ngTemplateOutlet]="headerContainer"></ng-template>
						<!---->
						<ng-template #headerContainer>
							<thead #header style="z-index: 50; position: relative;">
							<tr>
								<!--(Radio button)-->
								<th style="position: relative; padding-left: 5px; width: 20px;">
									<div class="flxRow-start md-checkbox table-select-radio" style="max-height: 15px; position: relative;"
										 tooltip="{{selectedAll ? 'Deselect all' : 'Select all'}}"
										 (click)="selectedAll = !selectedAll; toggleAllUsers(); $event.stopPropagation();"
										 [hidden]="!subscriptionUsers.length || displayedUsersCount === 0">
										<input type="checkbox"
											   [checked]="selectedAll"/>
										<label style="position: absolute; left: 0; top: 0;"></label>
									</div>
								</th>
								<!--Domain (shows only on "view per domain")-->
								<th *ngIf="mailboxListView !== dic.CONSTANTS.mailboxListViews.all"
									(ngInit)="sortDomain = '-domainName';" style="position: relative;">
									Domain
									<a class="area" *ngIf="sortDomain === '-domainName'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortDomain='domainName'; sortUpdate('domainName', true)">
										<i class="zmdi zmdi-chevron-down" style="width:10px;"></i>
									</a>
									<a class="area" *ngIf="sortDomain === 'domainName'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortDomain='-domainName'; sortUpdate('-domainName', true)">
										<i class="zmdi zmdi-chevron-up" style="width:13px;"></i>
									</a>
								</th>
								<!--Name-->
								<th style="position: relative; " (ngInit)="sortName='name'">
									Name
									<a class="area" *ngIf="sortName === '-name'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortName='name'; sortUpdate('name');">
										<i class="zmdi zmdi-chevron-down" style="width:10px;"></i>
									</a>
									<a class="area" *ngIf="sortName === 'name'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortName='-name'; sortUpdate('-name');">
										<i class="zmdi zmdi-chevron-up" style="width:13px;"></i>
									</a>
								</th>
								<!--Email Address-->
								<th (ngInit)="sortEmail='email'" style="position: relative;">
									Email Address
									<a class="area" *ngIf="sortEmail == '-email'"
									   style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortEmail='email'; sortUpdate('email');">
										<i class="zmdi zmdi-chevron-down" style="width:10px;"></i>
									</a>
									<a class="area" *ngIf="sortEmail == 'email'"
									   style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortEmail='-email'; sortUpdate('-email');">
										<i class="zmdi zmdi-chevron-up" style="width:13px;"></i>
									</a>
								</th>
								<!--Job Role-->
								<th style="position: relative;" (ngInit)="sortJobRole='job_title'">
									Job Role
									<a class="area" *ngIf="sortJobRole === '-job_title'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortJobRole='job_title'; sortUpdate('job_title');">
										<i class="zmdi zmdi-chevron-down" style="width:10px;"></i>
									</a>
									<a class="area" *ngIf="sortJobRole === 'job_title'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortJobRole='-job_title'; sortUpdate('-job_title');">
										<i class="zmdi zmdi-chevron-up" style="width:13px;"></i>
									</a>
								</th>
								<!--Status-->
								<th style="position: relative; width: 11%;" (ngInit)="sortStatus='status'">
									Status
									<a class="area" *ngIf="sortStatus === '-status'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortStatus='status'; sortUpdate('status');">
										<i class="zmdi zmdi-chevron-down" style="width:10px;"></i>
									</a>
									<a class="area" *ngIf="sortStatus === 'status'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortStatus='-status'; sortUpdate('-status');">
										<i class="zmdi zmdi-chevron-up" style="width:13px;"></i>
									</a>
								</th>
								<!--Mail Server license-->
								<th style="position: relative; width: 11%;" (ngInit)="sortLicense='license'">
									<div class="flxRow hSpace05" style=" align-items: center;">
										<span style="white-space: pre-wrap; width: min-content; min-width: clamp(60px,71%,calc(100% - 50px));">Mail Server license</span>
										<div class="flxRow" style="flex: 1 0 39px;">
											<i style="font-weight: 400;" class="fas fa-question-circle ng-isolate-scope"
											   tooltip="The license type associated to this mailbox on O365/Exchange/Google. This column is not related to the Trustifi license."></i>
											<a class="area" style="margin-top:1px; padding-left:5px;"
											   *ngIf="sortLicense === '-license'" tooltip="Sort"
											   (click)="sortLicense='license'; sortUpdate('license');">
												<i class="zmdi zmdi-chevron-down" style="width:10px;"></i>
											</a>
											<a class="area" style="margin-top:1px; padding-left:5px;"
											   *ngIf="sortLicense === 'license'" tooltip="Sort"
											   (click)="sortLicense='-license'; sortUpdate('-license');">
												<i class="zmdi zmdi-chevron-up" style="width:13px;"></i>
											</a>
										</div>
									</div>
								</th>
								<!--Last Email-->
								<th style="position: relative; width: 11%;" (ngInit)="sortPriority = 'last_email_priority'">
									Last Email
									<a class="area" *ngIf="sortPriority === '-last_email_priority'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortPriority='last_email_priority'; sortUpdate('last_email_priority');">
										<i class="zmdi zmdi-chevron-down" style="width:10px;"></i>
									</a>
									<a class="area" *ngIf="sortPriority === 'last_email_priority'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortPriority='-last_email_priority'; sortUpdate('-last_email_priority');">
										<i class="zmdi zmdi-chevron-up" style="width:13px;"></i>
									</a>
								</th>
								<!--Threats-->
								<th style="position: relative; width: 8%;" (ngInit)="sortThreats = 'threats_priority'">
									Threats
									<a class="area" *ngIf="sortThreats === '-threats_priority'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortThreats='threats_priority'; sortUpdate('threats_priority');">
										<i class="zmdi zmdi-chevron-down" style="width:10px;"></i>
									</a>
									<a class="area" *ngIf="sortThreats === 'threats_priority'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
									   (click)="sortThreats='-threats_priority'; sortUpdate('-threats_priority');">
										<i class="zmdi zmdi-chevron-up" style="width:13px;"></i>
									</a>
								</th>
								<!--Actions-->
								<th class="overflownV" style="width: calc(130px + 55px); padding: 0 10px; position: relative;">
									<app-dropdown-c class="animated fadeIn" style="width: 100%; display: block; font-weight: normal;"
												  *ngIf="selectedUsers.length > 0"
												  text="{{selectedUsers.length === 1 ? 'Actions for 1 item' : 'Actions for ' + selectedUsers.length + ' items'}}"
												  [isLf]="true">
										<li *ngFor="let action of multipleEmailsActions;"
											[ngClass]="{'has-submenu' : [dic.CONSTANTS.inboundMailboxAction.importContacts, dic.CONSTANTS.inboundMailboxAction.checkImpersonation, dic.CONSTANTS.inboundMailboxAction.scanThreatAI].includes(action)}"
											(click)="![dic.CONSTANTS.inboundMailboxAction.importContacts, dic.CONSTANTS.inboundMailboxAction.checkImpersonation, dic.CONSTANTS.inboundMailboxAction.scanThreatAI].includes(action) && selectMultipleEmailsAction(selectedUsers, action.value);"
											id="{{action.display}}">
											{{action.display}}

											<ul class="submenu" *ngIf="action === dic.CONSTANTS.inboundMailboxAction.importContacts">
												<li (click)="importType = dic.CONSTANTS.importContactsType.exchange; selectMultipleEmailsAction(selectedUsers, dic.CONSTANTS.inboundMailboxAction.importContacts.value);">
													From Email Server
												</li>
												<li (click)="importType = dic.CONSTANTS.importContactsType.csv; selectMultipleEmailsAction(selectedUsers, dic.CONSTANTS.inboundMailboxAction.importContacts.value);">
													From CSV
												</li>
											</ul>

											<ul class="submenu" *ngIf="action === dic.CONSTANTS.inboundMailboxAction.checkImpersonation">
												<li (click)="importType = dic.CONSTANTS.importContactsType.exchange; selectMultipleEmailsAction(selectedUsers, dic.CONSTANTS.inboundMailboxAction.checkImpersonation.value, true);">
													Enable
												</li>
												<li (click)="importType = dic.CONSTANTS.importContactsType.csv; selectMultipleEmailsAction(selectedUsers, dic.CONSTANTS.inboundMailboxAction.checkImpersonation.value, false);">
													Disable
												</li>
											</ul>

											<ul class="submenu" *ngIf="action === dic.CONSTANTS.inboundMailboxAction.scanThreatAI">
												<li (click)="importType = dic.CONSTANTS.importContactsType.exchange; selectMultipleEmailsAction(selectedUsers, dic.CONSTANTS.inboundMailboxAction.scanThreatAI.value, true);">
													Enable
												</li>
												<li (click)="importType = dic.CONSTANTS.importContactsType.csv; selectMultipleEmailsAction(selectedUsers, dic.CONSTANTS.inboundMailboxAction.scanThreatAI.value, false);">
													Disable
												</li>
											</ul>
										</li>
									</app-dropdown-c>
								</th>
							</tr>
							</thead>
						</ng-template>
						<tbody #container>
						<tr *ngFor="let item of previewListScroll.viewPortItems"
							(click)="item.selected = !item.selected; selectMailboxItem();"
							[ngClass]="{'chosen' : item.selected, 'sub-row-1' : item.parent_domain, 'cursor-pointer' : item.users}">
							<td colspan="9" *ngIf="!item.parent_domain && mailboxListView === dic.CONSTANTS.mailboxListViews.domains"
								(click)="showHideDomainMailboxes(item); $event.stopPropagation();">
								<div class="flxRow" style="align-items: center; cursor: pointer;">
									<i class="fa fa-chevron-right" style="color: royalblue; transition: transform 0.15s ease-out; padding: 5px;"
									   [ngStyle]="{'transform' : item.showUsers ? 'rotate(90deg)' : 'rotate(0deg)'}"></i>
									<span>{{item.domainName}}</span>
									<span style="opacity: 0.75; padding-left: 10px;">({{_.reject(item.users, 'hide').length}} mailbox{{_.reject(item.users, 'hide').length > 1 ? 'es' : ''}})</span>
								</div>
							</td>
							<!--|(Radio button)|-->
							<td style="padding-left: 5px; width: 20px;" *ngIf="item.parent_domain || mailboxListView === dic.CONSTANTS.mailboxListViews.all">
								<div class="flxRow-start md-checkbox table-select-radio" style="max-height: 15px;">
									<input type="checkbox"
										   [(ngModel)]="item.selected"
										   (ngModelChange)="selectMailboxItem();"/>
									<label style="position: absolute; left: 0; top: 0;"></label>
								</div>
							</td>
							<!--|Domain column PADDER|-->
							<td *ngIf="item.parent_domain && mailboxListView !== dic.CONSTANTS.mailboxListViews.all"></td>
							<!--|Name|-->
							<td *ngIf="item._id || item.id" class="hSpace05">
								<img ngSrc="https://gravatar.com/avatar/{{this.gs.stringToSha256(item.email)}}?s=30&d=mp" style="border-radius: 50%;" width="30" height="30" alt="By Gravatar"/>
								<i class="fas fa-user-check" style="color: green;" tooltip="Personal reviewer" *ngIf="item.is_personal_reviewer"></i>
								<span style="vertical-align: middle;">{{item.name || 'N/A'}}</span>
							</td>
							<!--|Email Address|-->
							<td *ngIf="item._id || item.id">
								<span tooltip="{{item.email}}">{{item.email}}</span>
							</td>
							<!--|Job Role|-->
							<td *ngIf="item._id || item.id" class="hSpace05">
								<i class="fas fa-user-tie" style="color: green; padding-right: 3px;" tooltip="Executive Role" *ngIf="item.is_executive"></i>
								<span style="white-space: break-spaces;">{{item.job_title || 'N/A'}}</span>
							</td>
							<!--|Status|-->
							<td *ngIf="item._id || item.id">
								<span [ngStyle]="{'color': item.status === dic.CONSTANTS.inboundMailBoxStatus.protect ? 'darkgreen' : 'darkred'}">
									{{item.status + (item.is_protected && (item.is_fes ? planInfo.threat_protection.is_monitoring ? ' (Monitor)' : ' (Relay)' : ' (API)') || '')}}
								</span>
							</td>
							<!--|Mail Server license|-->
							<td *ngIf="item._id || item.id">
								<span>{{item.license || 'N/A'}}</span>
							</td>
							<!--|Last Email|-->
							<td *ngIf="item._id || item.id">
								<span [ngStyle]="{'color': item.last_email ? 'black' : '#808080'}">
									{{item.last_email ? (item.last_email | date:'MM/dd/yyyy HH:mm') : 'None'}}
								</span>
							</td>
							<!--|Threats|-->
							<td *ngIf="item._id || item.id">
								<span (click)="prepareThreatsGraph(item)"
									  [ngStyle]="item.threats ? {'color': 'black', 'cursor': 'pointer'} : {'color': '#808080'}"
									  tooltip="Threat Distribution">
									{{item.threats || 'None'}}
								</span>
							</td>
							<!--|Actions|-->
							<td class="overflownV"
								 *ngIf="item._id || item.id"
								 (click)="$event.stopPropagation()">
								<app-dropdown-c style="width: 165px;" text="Actions"
											  [isDisabled]="item.selected" appDropdownPosition
											  (click)="showMailboxActions(item);">
									<ul>
										<li *ngFor="let action of item.actions;"
											[ngClass]="{'has-submenu' : action === dic.CONSTANTS.inboundMailboxAction.importContacts}"
											(click)="action !== dic.CONSTANTS.inboundMailboxAction.importContacts && selectAction(item, action.value);"
											id="{{action.display}}">
											{{action.display}}
											<ul class="submenu" *ngIf="action === dic.CONSTANTS.inboundMailboxAction.importContacts">
												<li (click)="importType = dic.CONSTANTS.importContactsType.exchange; selectAction(item, dic.CONSTANTS.inboundMailboxAction.importContacts.value);">
													From Email Server
												</li>
												<li (click)="importType = dic.CONSTANTS.importContactsType.csv; selectAction(item, dic.CONSTANTS.inboundMailboxAction.importContacts.value);">
													From CSV
												</li>
											</ul>
										</li>
									</ul>
								</app-dropdown-c>
							</td>
						</tr>
						</tbody>
					</table>
				</virtual-scroller>
                <!-- placeholder for empty table: -->
                <div class="w100 flxClmn-center" style="flex:1 0; text-align: center; color: darkgrey;"
                     *ngIf="displayedUsersCount === 0">
                    <span *ngIf="loadingInProcess" class="loading-dots">Loading</span>
                    <span *ngIf="!loadingInProcess && (searchMailboxTxt || activeFilters)">No mailboxes found for this search or filter</span>
                    <span *ngIf="!loadingInProcess && !searchMailboxTxt && !activeFilters">No mailboxes in list</span>
                </div>
                <!-- end -->
            </div>
        </div>

    </div>


    <!-- popups -->

    <!--Edit personal reviewer popup-->
    <popup-c *ngIf="personalReviewerPopup?.show"
           head="Edit Personal Reviewer" class="overflownV"
           (closeCb)="personalReviewerPopup = null;"
           (confirmCb)="updatePersonalReviewer();"
           [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', disable: updatePersonalReviewerInProcess}}" size="medium">
        <div class="flxClmn vSpace3 f-1-0">
            <span>Personal reviewers will always be allowed to review and release/remove their quarantined emails</span>
            <div class="flxRow-start hSpace1 md-checkbox" style="padding: 15px 0; font-size: 0.85rem; font-weight: 500;">
                <input id="setPersonalReviewer" required="false" type="checkbox"
                       [(ngModel)]="personalReviewerPopup.isPersonalReviewer"
                />
                <label for="setPersonalReviewer" style="word-break: break-word;">
                    Set <span style="color: #365b93; margin: 0 3px;">{{personalReviewerPopup.activeUser.email}}</span> as personal reviewer
                </label>
            </div>

            <span style="margin-bottom: 0;">Options:</span>
            <div class="flxClmn vSpace3" style="background: #f9f9f9; padding: 30px 15px;"
                 [ngClass]="{'disabled greyed-out' : !personalReviewerPopup.isPersonalReviewer}">
                <div class="flxClmn vSpace3">
                    <span style="font-weight: bold;">Allow releasing</span>
                    <div class="flxRow-start hSpace1 md-checkbox" style="padding: 0 15px;">
                        <input id="allowRelease" required="false" type="checkbox"
                               [(ngModel)]="personalReviewerPopup.reviewerAllowReleaseMalicious"
                        />
                        <label for="allowRelease">Allow this reviewer to release malicious emails</label>
                    </div>
                </div>
                <div class="flxClmn vSpace1">
                    <span style="font-weight: bold;">Notify Reviewer</span>
                    <div style="padding: 0 15px;">
                        <div class="flxRow hSpace1" style="align-items: center;">
                            <span style="flex-basis: 150px;">Select notification timing:</span>
                            <div style="position: relative; flex: 1 0; max-width: 74%;">
                                <app-dropdown-c class="show-above" style="width: 100%;"
                                              [error]="personalReviewerPopup.periodsError"
                                              text="{{personalReviewerPopup.periods && _.keys(_.pickBy(personalReviewerPopup.periods)).join(', ').replace('Notify every day', 'Custom notification times') || 'Select'}}">
                                    <li *ngFor="let period of dic.CONSTANTS.tpRule.notifyOptions | keyvalue: gs.returnZero"
                                        id="{{'notifyPersonalReviewerPeriod_' + period.key}}"
                                        (click)="personalReviewerPopup.periods[period.value] = !personalReviewerPopup.periods[period.value]; $event.stopPropagation();">
                                        <div class="gap-1 md-checkbox">
                                            <input type="checkbox" onclick="return false;" [checked]="personalReviewerPopup.periods[period.value]"/>
											<label><span>{{period.value === dic.CONSTANTS.tpRule.notifyOptions.everyDay ? 'Custom notification times' : period.value}}</span></label>
                                        </div>
                                    </li>
                                </app-dropdown-c>
                                <span style="position: absolute; bottom: -20px; right: 0;  font-size: 12px; color: red;"
                                      *ngIf="personalReviewerPopup.periodsError">
                                    required field
                                </span>
                            </div>
                        </div>
						<div class="flxRow hSpace1 animated fadeIn" style="align-items: center; margin-top: 10px;" *ngIf="personalReviewerPopup.periods[dic.CONSTANTS.tpRule.notifyOptions.everyDay]">
							<span style="flex-basis: 150px;">Select notification hours:</span>
							<div style="position: relative;">
								<app-dropdown-c class="limit-height" style="width: 200px;"
												[error]="personalReviewerPopup.periodsHoursError"
												text="{{getDailyHoursText(personalReviewerPopup.notifyReviewerDailyHours).join(', ') || 'Select'}}">
									<li *ngFor="let twoDigitHour of hoursArr"
										(click)="toggleNotificationDailyHour(personalReviewerPopup.notifyReviewerDailyHours, twoDigitHour); $event.stopPropagation();"
										id="{{'notifyReviewerDailyHour_' + twoDigitHour}}">
										<div class="gap-1 md-checkbox">
											<input type="checkbox" onclick="return false;" [checked]="personalReviewerPopup.notifyReviewerDailyHours.includes(twoDigitHour)"/>
											<label><span>{{twoDigitHour + ':00'}}</span></label>
										</div>
									</li>
								</app-dropdown-c>
								<span style="position: absolute; bottom: -17px; right: 0;  font-size: 12px; color: red;"
									  *ngIf="personalReviewerPopup.periodsHoursError">
									required field
								</span>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </popup-c>

    <!--mailbox threats popup-->
    <popup-c *ngIf="mailboxThreatsPopup?.show"
           head="Threats Distribution for Mailbox"
		   subTitle="Mailbox: {{mailboxThreatsPopup.mailboxObj.email}}"
           (closeCb)="mailboxThreatsPopup = null;"
           [buttons]="{cancel: 'OK'}" size="dynamic">
        <div class="flxClmn vSpace3 f-1-0 bs-enabled">
            <div class="flex-column f-1-0 centralize border border-3 border-light">
				<apx-chart class="pie-chart" style="width: 100%; max-width: min(400px,65vh);"
						   [options]="{display: false}"
						   [series]="mailboxThreatsPopup.graph.series"
						   [chart]="mailboxThreatsPopup.graph.chart"
						   [plotOptions]="mailboxThreatsPopup.graph.plotOptions"
						   [dataLabels]="mailboxThreatsPopup.graph.dataLabels"
						   [colors]="mailboxThreatsPopup.graph.colors"
						   [labels]="mailboxThreatsPopup.graph.labels"
						   [tooltip]="mailboxThreatsPopup.graph.tooltip"></apx-chart>
            </div>
        </div>
    </popup-c>

    <!--Set Mailbox as Alias popup-->
    <popup-c *ngIf="mailboxAliasPopup?.show"
           head="Set Mailbox as Alias"
           (closeCb)="mailboxAliasPopup = null;"
           (confirmCb)="updateMailboxAlias();"
           [buttons]="{cancel: 'Cancel', confirm: 'Apply'}" size="dynamic">
        <div class="flxClmn vSpace3 f-1-0" style="overflow: auto;">
            <span>This mailbox will be removed, and set as an alias for a different mailbox.<br>All emails sent to this alias will arrive at the mailbox specified below.</span>
            <span>Please specify which mailbox this address should be an alias for:</span>
            <input id="mailboxAlias" type="text" placeholder="mailbox" style="margin: 0; width: 300px; box-sizing: inherit;"
                   [(ngModel)]="mailboxAliasPopup.aliasInput" />
        </div>
    </popup-c>

	<!--Enable API protection-->
	<popup-c *ngIf="showEnableApiProtectionPopup?.show"
			 head="Enable API Protection"
			 subTitle="Enable Inbound Shield API protection"
			 (closeCb)="showEnableApiProtectionPopup = null;"
			 (confirmCb)="enableApiProtectionExecute();"
			 [buttons]="{cancel: 'Cancel', confirm: 'Protect'}" size="dynamic">
		<div class="flxClmn vSpace3 f-1-0">
			<span>
				If you are connected through Email Relay or via MX record, there is no need to manually enable protection. The system will automatically activate protection when the mailbox receives an email.
			</span>

			<div *ngIf="!planInfo.exchange_server?.contacts_enabled">
				<div class="flxRow-start md-checkbox" style="align-items: center;">
					<input id="enableDefault" type="checkbox"
						   [(ngModel)]="showEnableApiProtectionPopup.importContacts"/>
					<label for="enableDefault" style="margin: 0;">Allow importing contacts</label>
				</div>
				<span>Importing user contacts from Exchange is highly recommended to help prevent contact impersonation attacks.</span>
			</div>

			<div *ngIf="showEnableApiProtectionPopup.showLimitedMailboxes" class="flxRow hSpace1" style="align-items: center;">
				<span>Enable protection for:</span>
				<input style="text-align: center; width: 60px;" type="number" min="1" step="1" pattern="^\d*(\.\d{0,10})?$" placeholder="Mailboxes"
					   [(ngModel)]="showEnableApiProtectionPopup.limitedMailboxes">
				<span><b>licensed</b> mailboxes.</span>
			</div>
		</div>
	</popup-c>


    <aliases-popup-c *ngIf="showAliases"
               (cancelCb)="getMailboxesAliases(false)"
               [popupTitle]="'Mailboxes Aliases'"
               [showActions]="true">
    </aliases-popup-c>

    <groups-popup-c *ngIf="showGroups"
                (cancelCb)="getGroups(false)"
                [popupTitle]="'Groups'"
                [showActions]="true">
    </groups-popup-c>

    <groups-popup-c
            *ngIf="showProtectGroups"
            (cancelCb)="openProtectGroupsPopup(false)"
            (doneCb)="applyProtectGroupsPopup($event.groups)"
            [doneBtnTxt]="'Protect'"
            [multipleSelection]="true"
            [popupTitle]="'Protect Groups'"
            [popupSubTitle]="'Select groups to enable API protection. the action will be applied for all group members'">
    </groups-popup-c>
</div>

<popup-c *ngIf="mailboxMetricsPopup?.show"
		 head="Configure Metrics"
		 class="bs-enabled"
		 subTitle="Configure metrics for {{mailboxMetricsPopup.mailboxObj.email}}"
		 (closeCb)="mailboxMetricsPopup = null;"
		 size="dynamic">

	<div class="flxClmn gap-4">
		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>Check Impersonation</b>
				<span>Check incoming emails for impersonation of this user's contacts</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="isOutboundReviewer"
								 [hideText]="true"
								 [(model)]="mailboxMetricsPopup.mailboxObj.check_impersonation"
								 (onChange)="setCheckImpersonationForMailbox(mailboxMetricsPopup.mailboxObj)"></toggle-switch-c>
			</div>
		</div>

		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>Scan for Threat AI</b>
				<span>Scan incoming emails using AI to detect Business Email Compromise (BEC) attacks. For support/IT mailboxes or any mailboxes that handle sensitive content from unknown senders it is recommended to disable this metric.</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="reviewer_allow_release_malicious"
								 [hideText]="true"
								 [(model)]="mailboxMetricsPopup.mailboxObj.scan_threat_ai"
								 (onChange)="setScanThreatAIForMailbox(mailboxMetricsPopup.mailboxObj)"></toggle-switch-c>
			</div>
		</div>

	</div>
</popup-c>

<popup-c *ngIf="viewContentPermissionsPopup?.show"
		 head="View Content Permissions"
		 class="bs-enabled"
		 subTitle="Configure permissions for {{viewContentPermissionsPopup.mailboxObj.email}}"
		 (closeCb)="viewContentPermissionsPopup = null;"
		 size="dynamic">

	<div class="flxClmn gap-4">
		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>Reviewers</b>
				<span>All inbound reviewers will be able to view quarantined email content for this mailbox</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="allow_reviewers"
								 [hideText]="true"
								 [(model)]="viewContentPermissionsPopup.mailboxObj.view_content.allow_reviewers"
								 (onChange)="setViewContentPermissionReviewers(viewContentPermissionsPopup.mailboxObj)"></toggle-switch-c>
			</div>
		</div>

		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>Admin</b>
				<span>All admins will be able to view quarantined email content for this mailbox</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="allow_admins"
								 [hideText]="true"
								 [(model)]="viewContentPermissionsPopup.mailboxObj.view_content.allow_admins"
								 (onChange)="setViewContentPermissionAdmins(viewContentPermissionsPopup.mailboxObj)"></toggle-switch-c>
			</div>
		</div>

	</div>
</popup-c>

<!--Change notification options popup-->
<popup-c class="overflownV" *ngIf="notifyOptionsPopup?.show"
		 head="Notification Options"
		 (closeCb)="notifyOptionsPopup = null"
		 (confirmCb)="changeNotifyPeriod()"
		 [buttons]="{confirm: {text: 'Confirm', loading: notifyOptionsPopup.changeNotifyPeriodInProcess}}"
		 size="dynamic">
	<div class="flxClmn vSpace2 f-1-0" style="overflow: visible; min-height: 130px;">
		<span>Select the notification timing on quarantined emails.</span>

		<div class="flxRow hSpace1" style="align-items: center; z-index: 50;">
			<span style="flex-basis: 150px; flex-shrink: 0;">Select notification timing:</span>
			<div style="flex: 1 0; position: relative;">
				<app-dropdown-c style="width: 300px; min-width: 100%;"
								[error]="notifyOptionsPopup.isNotifyReviewerPeriodsError"
								text="{{notifyOptionsPopup.periods && _.keys(_.pickBy(notifyOptionsPopup.periods)).join(', ').replace('Notify every day', 'Custom notification times') || 'Select'}}">
					<li *ngFor="let periodKey of dic.CONSTANTS.tpRule.notifyOptions | keyvalue: gs.returnZero"
						(click)="notifyOptionsPopup.periods[periodKey.value] = !notifyOptionsPopup.periods[periodKey.value]; notifyOptionsPopup.isNotifyReviewerPeriodsError = false; $event.stopPropagation();"
						id="{{'notifyReviewerPeriod_' + periodKey.key}}">
						<div class="gap-1 md-checkbox">
							<input type="checkbox" onclick="return false;" [checked]="notifyOptionsPopup.periods[periodKey.value]"/>
							<label><span>{{periodKey.value === dic.CONSTANTS.tpRule.notifyOptions.everyDay ? 'Custom notification times' : periodKey.value}}</span></label>
						</div>
					</li>
				</app-dropdown-c>
				<span style="position: absolute; bottom: -20px; right: 0;  font-size: 12px; color: red;"
					  *ngIf="notifyOptionsPopup.isNotifyReviewerPeriodsError">
                            required field
                        </span>
			</div>
		</div>
		<div class="flxRow hSpace1 animated fadeIn w100" style="align-items: center; margin-top: 10px; z-index: 40;" *ngIf="notifyOptionsPopup.periods[dic.CONSTANTS.tpRule.notifyOptions.everyDay]">
			<span style="flex-basis: 150px;">Select notification hours:</span>
			<div style="position: relative; flex: 1 0;">
				<app-dropdown-c class="limit-height" style="width: 300px; min-width: 100%;"
								[error]="notifyOptionsPopup.isNotifyReviewerDailyHoursError"
								text="{{getDailyHoursText(notifyOptionsPopup.dailyHours).join(', ') || 'Select'}}">
					<li *ngFor="let twoDigitHour of hoursArr"
						(click)="toggleNotificationDailyHour(notifyOptionsPopup.dailyHours, twoDigitHour); $event.stopPropagation();"
						id="{{'notifyReviewerDailyHour_' + twoDigitHour}}">
						<div class="gap-1 md-checkbox">
							<input type="checkbox" onclick="return false;" [checked]="notifyOptionsPopup.dailyHours.includes(twoDigitHour)"/>
							<label><span>{{twoDigitHour + ':00'}}</span></label>
						</div>
					</li>
				</app-dropdown-c>
				<span style="position: absolute; bottom: -17px; right: 0;  font-size: 12px; color: red;"
					  *ngIf="notifyOptionsPopup.isNotifyReviewerDailyHoursError">
						required field
					</span>
			</div>
		</div>

		<div *ngIf="!planInfo.threat_protection.rules.notify_recipient_allow_change_time">
			<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
				<input id="notify_recipient_allow_change_time" type="checkbox"
					   [(ngModel)]="notifyOptionsPopup.notifyRecipientAllowChangeTime"/>
				<label style="margin: 0;" for="notify_recipient_allow_change_time">Allow users to set their own custom notification times</label>
			</div>
		</div>
	</div>
</popup-c>
