<div class="flxClmn">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container">
			<span class="page-title">Audit Log</span>
		</div>
		<div class="page-subtitle-container">
			<span class="page-subtitle">Review operations performed by the plan admins</span>
		</div>
	</div>

	<!--loader-->
	<div class="f-1-0 flex-column centralize" *ngIf="_.isUndefined(isThisFeatureSupported)">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div class="page-inner-content-holder no-scroll flxClmn" *ngIf="isThisFeatureSupported">
		<!--logs table-->
		<trustifi-table-c
			class="trustifi-table" style="flex: 1 0; z-index: 5;"
			[list]="logs"
			[headers]="[
                {text:'Date', sortKey:'created', width: '13%'},
                {text:'Category', sortKey:'category', width: '13%'},
                {text:'User', sortKey:'admin', width: '19%'},
                {text:'Action'},
            ]"
			[cellsPrototype]="[
                {textKey: 'created', textType: 'dateAndTime'},
                {textKey: 'category'},
                {textKey: 'admin', tooltip: true},
                {html: 'logActionCell'},
            ]"
			(searchFunction)="searchLogs($event)"
			[filterData]="filterData"
			[options]="{
                exportToCsvFunction: exportToCsv,
                loadingTableFlag: loadingLogsInProcess,
                refreshFunction: getThreatSimulationLogs,
                itemsNameSingular: 'log',
                defaultSortKey: '-created',
                itemUniqueIdentifier: '_id',
                isEllipsis: false,
                customButtons: [{icon: 'fas fa-calendar-day', function: showScheduleReport, tooltip: 'Schedule Report'}],
            }"
		>
			<!--log action cells-->
			<ng-template #customCells let-item let-cellId="cellId">
				<div *ngIf="cellId === 'logActionCell'"
                     class="flxRow-start hSpace1 expandable-record-row" style="max-width : 850px;" #expandableRecord
                     (click)="item.fullMode = !item.fullMode"
                     [ngStyle]="{'cursor': item.data ? 'pointer !important' : ''}">
					<i style="color : royalblue;" *ngIf="item.data" class="fas fa-external-link-alt"></i>
					<div class="ellipsis" style="word-break : break-word;"
						 (isElps)="gs.setExpandableRecord(expandableRecord, $event)"
						 [ngStyle]="item.fullMode ? { 'white-space' : 'normal' , 'color' : item.data ? 'royalblue' : ''} : item.data ? {'color' : 'royalblue' } : null ">
						<span>{{item.action}}</span>
					</div>
					<i *ngIf="!item.fullMode" class="fa fa-caret-down fa-sm"></i>
				</div>
			</ng-template>
			<!---->
			<div tableButtons>
				<div class="period-buttons-group bs-enabled flxRow hSpace1">
					<btn-c
						variant="{{period.value !== periodObj.value ? 'outlined' : ''}}"
						*ngFor="let periodObj of auditLogsPeriod"
						[isDisabled]="loadingLogsInProcess"
						(action)="changePeriod(periodObj);">
						{{periodObj.display}}
					</btn-c>
				</div>
			</div>
		</trustifi-table-c>
	</div>

	<!--feature not supported-->
	<threat-simulation-not-supported style="display: contents;" *ngIf="isThisFeatureSupported === false"></threat-simulation-not-supported>
	<!---->
</div>

<audit-log-schedule-report-component [(show)]="scheduleReportShow"></audit-log-schedule-report-component>
