import * as util from 'util';
import _ from 'lodash';
import {Component} from "@angular/core";
import {DICTIONARY} from "../../dictionary";
import {NotificationService} from "../../services/notificationService";

@Component({
	selector: 'notification-toaster-component',
	templateUrl: './notification-toaster.component.html',
})
export class NotificationToasterComponent {

	constructor(private ns: NotificationService) {
		ns.userDetailsObs.subscribe((ntfData:any[]) => {
			if (!ntfData || !ntfData.length) {
				return
			}

			const backEndNotifications = _.filter(ntfData, n => n.error && n.error.system);

			if (backEndNotifications.length > this.dic.CONSTANTS.maxNotificationsToShow) {
				const notificationsHref = '<a href="/' + this.dic.CONSTANTS.appStates.accountDetails + '/' + this.dic.CONSTANTS.accountDetailsPages.notifications + '"><b><i>here</i></b></a>';
				let notificationLink = {
					counter: 1,
					index: 0,
					error: {
						message: util.format(this.dic.MESSAGES.notificationsLink, backEndNotifications.length, notificationsHref),
						type: _.some(backEndNotifications, n => n.error.type === this.dic.CONSTANTS.msgTypeWarn) ?
							this.dic.CONSTANTS.msgTypeWarn: this.dic.CONSTANTS.msgTypeInfo
					},
					timeout: 0
				};

				this.ntfData = [notificationLink];
				ns.setNotificationData(this.ntfData);
			}
			else {
				this.ntfData = ntfData;
			}
		})
	}

	dic = DICTIONARY;
	ntfData = [];

	closeNotification = (index) => {
		this.ns.closeMessage(index);
	}
}
