<div class="flxClmn">
    <div class="page-title-container flxRow">
        <div class="flxClmn-center f-1-0">
            <div class="page-title-inner-container flxRow">
                <span class="page-title">User Management</span>
                <!--Information modal-->
                <more-info-c>
                    <div class="flxClmn vSpace05" style="flex: 1 0;">
                        <span style="font-weight: bold;">Actions</span>
                        <br/>
                        <span><b>{{dic.CONSTANTS.userActions.showStatistics}}</b> - review plan/sending statistics for the user</span>
                        <span><b>{{dic.CONSTANTS.userActions.resendInvitation}}</b> - resend invitation email to the user</span>
                        <span><b>Block/Unblock User</b> - a blocked user will not able to access his account</span>
                        <span><b>{{dic.CONSTANTS.userActions.remove}}</b> - remove the user from your plan</span>
                        <span><b>Enable/Disable Log-in MFA</b> - When enabled, the user will be required to perform Multi-Factor Authentication when logging in</span>
                        <span><b>Allow/Disallow admin permissions</b> - allow/disallow admin permissions</span>
                        <span><b>{{dic.CONSTANTS.userActions.moveToOtherAdmin}}</b> - move the user to another admin's plan</span>
                        <span><b>{{dic.CONSTANTS.userActions.allocatePlan}}</b> - allocate a new plan to the user, who will become the plan's admin</span>
                        <span><b>{{dic.CONSTANTS.userActions.modifyResources}}</b> - update the admin plan's expiration,emails,storage and users</span>
                        <span><b>{{dic.CONSTANTS.userActions.removePlan}}</b> - remove the admin's plan and set him as a user</span>
                    </div>
                    <div class="flxClmn vSpace05" style="flex: 1 0;">
                        <span style="font-weight: bold;">Status</span>
                        <br/>
                        <span><b style="color: green;">Active</b> - the user is approved and active</span>
                        <span><b style="color: darkorange;">Invitation Sent</b> - waiting for the user to approve</span>
                        <span><b style="color: red;">Inactive</b> - the user has not sent any emails in the past 40 days</span>
                        <span><b style="color: red;">Blocked</b> - user has been blocked from the plan</span>
                        <span><b style="color: red;">Plan Blocked</b> - admin and user have been blocked from the plan</span>
                    </div>
                </more-info-c>
            </div>
            <div class="page-subtitle-container" style="display: flex; gap: 5px;">
                <span class="page-subtitle">Manage the users and admins under your shared plan.</span>
                <span style="font-weight: bold; font-size: 1rem;"
                      *ngIf="userManagementLoaded && mainPlanAdmin">
                    (you use {{mainPlanAdmin.alloc_plan_id?.shared_plan?.used_users || 0}} licenses out of {{mainPlanAdmin.alloc_plan_id?.shared_plan?.allowed_users  || 0}})
                </span>
            </div>
        </div>
        <div class="control-and-info-icons flxRow"></div>
    </div>
    <div class="page-inner-content-holder no-scroll flxClmn bs-enabled">
		<!--users table-->
		<trustifi-table-c
			class="trustifi-table" style="flex: 1 0; z-index: 5;"
			[list]="usersList"
			[headers]="_.compact([
							 usersListView === dic.CONSTANTS.userMgmntListViews.company && {text:'Company Name', sortKey:'companyName'},
							 usersListView === dic.CONSTANTS.userMgmntListViews.domains && {text:'Domain Name', sortKey:'domainName'},
							 {text:'Name', sortKey:'name'},
							 {text:'Email Address', sortKey:'email_display'},
							 {text:'Job Role', sortKey:'job_title'},
							 {text:'Status', sortKey:'status'},
							 {text:'Role', sortKey: 'user_role_display'},
							 isPartnerAdmin && {text:'Company Name', sortKey: 'company_name_display'},
							 {text:'Email Relay', sortKey: 'is_relay'},
						 ])"
			[cellsPrototype]="_.compact([
									(usersListView === dic.CONSTANTS.userMgmntListViews.company || usersListView === dic.CONSTANTS.userMgmntListViews.domains) && {html: 'companyOrDomainNameCell', overflowOutside: true},
									{html: 'nameCell'},
									{html: 'emailAddressCell'},
									{html: 'jobRoleCell'},
									{html: 'statusCell'},
									{html: 'RoleCell'},
									isPartnerAdmin && {html: 'companyNameCell'},
									{html: 'emailRelayCell'},
								])"
			[bulkActions] = "{
								enable: true,
								selectBulkActionCb:	selectMultipleUsersAction,
								initBulkActionsFunction: showMultipleUsersActions,
								showInRed: {enable: true, terms: ['Remove User']}
							}"
			[cellActions] = "{
								enable: true,
								selectItemActionCb:	selectUserAction,
								initActionsFunction: showUserActions,
							}"
			(searchFunction)="searchUser($event.searchTerm, $event.activeFilters)"
			[filterData]="filterData"
			[subRowOptions]="{
							recursionKey: 'planUsers',
							allowActions: true
						}"
			[options]="{
							itemsNameSingular: 'user',
							exportToCsvFunction: exportUsersCsv,
							refreshFunction: getUsersOfSharedPlan,
							defaultSortKey: 'role_priority',
							loadingTableFlag: loadingUsersInProcess,
							itemUniqueIdentifier: '_id',
							changeTableViewMenu: {
										all: {label: 'View Per User', action: updateListView},
										company: {label: 'View Per Company', action: updateListView},
										domains: {label: 'View Per Domain', action: updateListView},
										}
						}"
		>

			<ng-template #customCells let-item let-cellId="cellId">
				<!--company/domain name cell-->
				<span *ngIf="cellId === 'companyOrDomainNameCell'">
					<div class="flex-row gap-1" style="align-items: center; cursor: pointer; position: absolute; left: 10px; top: 50%; transform: translateY(-50%);"
						 *ngIf="item.isCompanyTitleRecord"
						 (click)="toggleExpandItem(item, $event);">
						<i class="fa fa-chevron-right" style="color: royalblue; transition: transform 0.15s ease-out; padding: 5px; padding-left: 0;"
						   *ngIf="item.planUsers && item.displayedCounter"
						   [ngStyle]="{'transform' : item.isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}"></i>
						<span>{{item.companyName || item.domainName}}</span>
						<span style="opacity: 0.75;">({{item.displayedCounter}} user{{item.displayedCounter > 1 ? 's': ''}})</span>
						<span style="opacity: 0.55;">{{usersListView === dic.CONSTANTS.userMgmntListViews.company && mainPlanAdmin.company_name_display === item.companyName ? ' (yours)' : ''}}</span>
						<span style="opacity: 0.75;" *ngIf="usersListView === dic.CONSTANTS.userMgmntListViews.company && item.expired">|&nbsp;&nbsp;Expiration: {{item.expired | date:'MM/dd/yyyy'}}</span>
					</div>
				</span>
				<!--name cell-->
				<span *ngIf="cellId === 'nameCell'">
					<!--regular-->
					<div class="flxRow hSpace05" style="align-items: center; cursor: pointer;"
						 *ngIf="!item.isCompanyTitleRecord"
						 (click)="toggleExpandItem(item, $event);"
						 [ngStyle]="{'padding-left' : item.depth ? 15 + item.depth * 15 + 'px' : ''}">
						<img ngSrc="https://gravatar.com/avatar/{{this.gs.stringToSha256(item.email_display)}}?s=30&d=mp" style="border-radius: 50%;" width="30" height="30" alt="By Gravatar"/>
						<i class="fa fa-chevron-right" style="color: royalblue; transition: transform 0.15s ease-out; padding: 5px; padding-left: 0;"
						   *ngIf="item.planUsers?.length"
						   [ngStyle]="{'transform' : item.isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}"></i>
						<span style="vertical-align: middle;">{{item.name || 'N/A'}}</span>
					</div>
				</span>
				<span *ngIf="cellId === 'emailAddressCell'">
					<div class="flex-row gap-1 align-items-center">
						<i class="fas fa-key" *ngIf="item.preferences?.require_2fa?.strict" tooltip="Log-in MFA is enforced on this user"></i>
						<span [tooltip]="item.email_display">{{item.email_display}}</span>
					</div>
				</span>
				<!--Job Role cell-->
				<span *ngIf="cellId === 'jobRoleCell'">
					<div class="flex-row gap-1 align-items-center">
						<i class="fas fa-user-tie" *ngIf="item.is_executive" style="color: green; padding-right: 3px;"  tooltip="Executive Role"></i>
						<span style="white-space: break-spaces;">{{item.job_title || 'N/A'}}</span>
					</div>
				</span>
				<span *ngIf="cellId === 'statusCell'">
					<span *ngIf="!item.alloc_plan_id?.expired || withoutTime(item.alloc_plan_id.expired) >= withoutTime()"
						  [ngStyle]="item.status === dic.CONSTANTS.userInPlanStatus.active && mainAdminUser.status !== dic.CONSTANTS.userInPlanStatus.blocked
						  ? {'color':'green'} : item.status === dic.CONSTANTS.userInPlanStatus.inactive || (item.status === dic.CONSTANTS.userInPlanStatus.invitationSent && mainAdminUser.status !== dic.CONSTANTS.userInPlanStatus.blocked)
						  ? {'color':'darkorange'} : {'color':'red'}">
						{{mainAdminUser.status === dic.CONSTANTS.userInPlanStatus.blocked || item.isParentPlanBlocked ? dic.CONSTANTS.userInPlanStatus.planBlocked : item.status}}
					</span>
					<span style="color: red;" *ngIf="item.alloc_plan_id?.expired && withoutTime(item.alloc_plan_id.expired) < withoutTime()">
						Expired
					</span>
				</span>
				<span *ngIf="cellId === 'RoleCell'">
					{{item.user_role_display}}
					<i style="padding-left: 5px;" class="fas fa-eye" *ngIf="item.read_only"
					   tooltip="Read only permissions"></i>
					<i style="padding-left: 5px;" class="fas fa-search" *ngIf="item.user_reviewer"
					   tooltip="Reviewer of: {{item.user_reviewer}}"></i>
				</span>
				<span *ngIf="cellId === 'companyNameCell'" >
					<span *ngIf="!item.isCompanyTitleRecord">{{item.company_name_display || 'N/A'}}</span>
				</span>
				<span *ngIf="cellId === 'emailRelayCell'">
      			    <span *ngIf="!item.isCompanyTitleRecord" style="text-transform: capitalize;">{{item.is_relay || false}}</span>
				</span>
			</ng-template>
			<div tableButtons>
				<div class="flex-row gap-1" *ngIf="userInfo">
					<btn-c style="width: 150px;" *ngIf="isPartnerAdmin"
						   endIcon="fa fa-plus"
						   [isDisabled]="loadingUsersInProcess"
						   (action)="addActiveAdmin();">
						Create New Plan
					</btn-c>
					<btn-c style="width: 150px;"
						   endIcon="fa fa-plus"
						   [isDisabled]="!mainPlanAdmin || loadingUsersInProcess"
						   (action)="addActiveUser();">
						Add Users
					</btn-c>
					<app-dropdown-c style="width: 150px;"
								  [isDisabled]="loadingUsersInProcess"
								  text="More Actions"
								  [isLf]="true">
						<li (click)="showGroups = true">
							Show Groups
						</li>
						<li (click)="openLoginOptionsPopup();">
							Log in Options
						</li>
					</app-dropdown-c>
				</div>
			</div>
		</trustifi-table-c>
	</div>

    <!--Add Users to Plan popup-->
    <popup-c *ngIf="addActiveUserPopup?.show"
           head="Add Users to Plan"
           (closeCb)="closePopupAndRemoveAllTags()"
           (confirmCb)="updateTemplateAndAddUsersToSharedPlan()"
           [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: stopActiveUserPopup}}"
           size="dynamic">
        <div class="flxClmn add-user-form f-1-0 vSpace3">
            <div class="admin-panel-row" (clickOutside)="contactAutocompleteData.showContacts = false">
                <label style="margin-right: 15px;">Email(s)</label>
                <div class="flxClmn vSpace05 w100 bs-enabled">
					<div class="flex-row gap-2 flex-wrap position-relative p-1 justify-content-start" style="border: solid 1px #cccccc;" (click)="focusNewUserInput()">
						<div class="flex-row align-items-center rounded-pill bg-light py-1 px-2 gap-2 cursor-pointer hvr-opacity"
							 style="font-size: 12px;" *ngFor="let newUser of users; let index = index; trackBy: gs.trackByIndex;"
							 (click)="$event.stopPropagation(); removeNewUser(index)">
							<span>{{newUser.name}}</span>
							<i class="zmdi zmdi-close-circle"></i>
						</div>
						<input type="text" id="adminContacts" #adminContacts
							   style="margin: 0; border: none; min-width: 200px;"
							   [(ngModel)]="addNewUserInputTxt"
							   autocomplete="off"
							   (keyup.enter)="confirmNewUserEmail(addNewUserInputTxt)">
						<auto-complete-menu [autoCompleteItems]="allContacts"
											[searchTerm]="addNewUserInputTxt"
											[inputElementRef]="adminContacts"
											searchByKeys="name,email"
											(onItemClick)="confirmNewUserFromContacts($event.item)">
							<ng-template #customListTemplate let-filteredItems>
								<li class="flxClmn vSpace05"
									tabindex="0"
									*ngFor="let contact of filteredItems"
									[highlightText]="addNewUserInputTxt"
									[isHighlightOn]="true"
									(keyup.enter)="confirmNewUserFromContacts(contact)"
									(click)="confirmNewUserFromContacts(contact)">
									<span class="fw-500 ellipsis" *ngIf="contact.name !== contact.email">{{contact.name}}</span>
									<span class="ellipsis">{{contact.email}}</span>
								</li>
							</ng-template>
						</auto-complete-menu>
					</div>

                    <a class="link-select" style="align-self: flex-start;" (click)="openAddressBookModal()">
                        Select from My Contacts
                    </a>
                </div>
            </div>
            <div class="flxClmn vSpace1">
                <div class="admin-panel-row">
                    <div class="message-note">This message is editable</div>
                    <label style="vertical-align: unset;">Message</label>
					<div class="summernote-editor" id="summernote"
						 [ngxSummernote]="summernoteOptions"
						 [(ngModel)]="template.html">
					</div>
                </div>
            </div>

			<div>
				<div class="flxRow-start md-checkbox">

					<input id="adminPermissions" type="checkbox"
						   [(ngModel)]="addActiveUserPopup.adminPermissions"/>

					<label for="adminPermissions">Admin Permissions</label>
					<i style="font-weight: 400; margin-left: 5px;" class="fas fa-question-circle" tooltip="Once users are connected to the plan, they will be granted admin permissions"></i>

				</div>
			</div>
        </div>
    </popup-c>

		<contact-address-book-c
			*ngIf="modalData?.openContactsAddressBook"
			[data]="modalData"
			[allContacts]="allContacts"
			[contacts]="users"
			(addCb)="confirmAddNewUserFromMyContacts($event.contacts)"
			(removeCb)="removeUser($event.contacts)">
		</contact-address-book-c>

    <!--Move user to Admin popup-->
    <popup-c class="overflownV" *ngIf="moveUserToAdminPopup && moveUserToAdminPopup.show"
           head="Move User to Admin"
           size="dynamic"
           (closeCb)="moveUserToAdminPopup = null"
           (confirmCb)="confirmMoveUserToAdmin()"
           [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: moveUserToAdminInProcess}}">
        <div class="flxClmn f-1-0 vSpace1" style="overflow: visible;">
            <span>Move <b>{{moveUserToAdminPopup.userToMove.name}}</b> from <b>{{moveUserToAdminPopup.oldAdmin.name}}</b> plan to another admin plan</span>
            <br/>
            <span>Select the new admin from the following list:</span>
            <app-dropdown-c style="width: 320px;"
                          [alwaysDrop]="true"
                          [text]="moveUserToAdminPopup.selectedAdmin && moveUserToAdminPopup.selectedAdmin.displayName || 'Select'"
                          [items]="moveUserToAdminPopup.adminsList"
                          itemDisplayKey="displayName" [enableSearch]="true"
                          (onItemClicked)="moveUserToAdminPopup.selectedAdmin = $event.item;"></app-dropdown-c>
        </div>
    </popup-c>

    <!--Modify/Allocate admin plan popup-->
    <popup-c class="no-content-padding form" *ngIf="setUserAsAdminPopup || (updateAdminPlanPopup && isPartnerAdmin)"
           [head]="setUserAsAdminPopup ? 'Allocate Admin Plan' : 'Modify Admin Plan Resources'"
           size="large"
           (closeCb)="closeAllocateModifyPopup();">
        <div class="flxClmn f-1-0 vSpace1" style="padding: 25px; overflow: auto;">
            <div class="txt-block w100" style="margin-left: 0;">
                <span class="w100" style="color: black;" *ngIf="setUserAsAdminPopup">Create a new plan and set {{curSharedUser.name}} to be its Admin</span>
                <span class="w100" style="color: black;" *ngIf="updateAdminPlanPopup">Modify {{curSharedUser.name}}'s plan resources</span>
            </div>
            <div class="input-wrap flxClmn f-1-0" style="background: #fcfcfc; overflow: auto; padding: 15px 20px;">
                <div class="input-wrap">
                    <div>
                        <label style="padding-top: 5px; vertical-align: unset; min-width: 104px;">Plan Type:</label>
                        <app-dropdown-c style="width: 230px; display: inline-flex; margin-left: 5px;" text="{{dic.CONSTANTS.planSubTypeMap[curSharedUser.planSubType]}}">
                            <li *ngFor="let type of dic.CONSTANTS.planSubType | keyvalue: gs.returnZero"
                                (click)="changePlanSubType(type.value)">
                                {{dic.CONSTANTS.planSubTypeMap[type.key]}}
                            </li>
                        </app-dropdown-c>
                        <i style="font-weight: 400; margin-left: 5px;" *ngIf="curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp" class="fas fa-question-circle" tooltip="<b>MSP</b> - The plan's expiration and allowed emails will refresh on a monthly basis, on the 1st of each month."></i>
                        <div>
                            <!--General-->
                            <!--expirationDate-->
                            <div style="min-width: 109px; display: inline-block;">Expires on:</div>
                            <label style="margin-bottom: 0; display: contents;"
                                   *ngIf="curSharedUser.planSubType === dic.CONSTANTS.planSubType.annual"
                                   [ngStyle]="curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp && {'pointer-events': 'none'}">
                                <div class="plan-expiration-extend-menu bs-enabled" style="position: relative; width: 230px; display: inline-block; cursor: unset; margin-bottom: 10px;">
                                    <input type="text" style="margin: 0;"
                                           [value]="curSharedUser.newExpirationDate && curSharedUser.planSubType === dic.CONSTANTS.planSubType.annual ? (curSharedUser.newExpirationDate | date: 'MM/dd/yyyy') : (curSharedUser.adminExpirationDate | date: 'MM/dd/yyyy')"
                                           [readOnly]="true"
                                           [ngClass]="{'text-success fw-bold' : curSharedUser.extendExpirationMethod}"
                                           [ngStyle]="{'background-color' : curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp ? '#ecf0f1' : ''}"
                                           readonly />
                                    <a class="blue-link" style="position: absolute; top: 50%; transform: translateY(-50%); right: 10px; font-size: 12px;"
                                       (click)="curSharedUser.isExtendExpirationMenuOpen = !curSharedUser.isExtendExpirationMenuOpen; $event.preventDefault();"
									   (clickOutside)="curSharedUser.isExtendExpirationMenuOpen = false;"
									   [exclude]="'#extendExpirationMenu'">
                                        Extend
                                    </a>
                                    <div class="drop-menu" style="padding: 0; min-width: 0; min-height: 0; max-width: none; max-height: none; width: 165px; height: auto; box-shadow: 1px 0 3px #cccccc; border-radius: 5px; border: solid 1px lightgrey; left: auto; top: 0; bottom: auto; right: -3px;"
                                         [ngClass]="{'active' : curSharedUser.isExtendExpirationMenuOpen}"
										 id="extendExpirationMenu">
                                        <ul>
                                            <li *ngFor="let period of expandMethods | keyvalue: gs.returnZero"
                                                (click)="updateExpirationTime(period.value.value); prepareInvoiceNotifyMessage();">
                                                <div class="flxRow hSpace05" style="align-items: center;"
                                                     [ngStyle]="{'font-weight' : curSharedUser.extendExpirationMethod === period.value.value ? 'bold' : 'normal'}">
                                                    <i class="fa fa-plus"></i>
                                                    <span>Add {{period.value.display}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <a class="closeRed" style="position: absolute; top: 50%; transform: translateY(-50%); right: -25px; padding: 5px; opacity: 0.5;"
                                       tooltip="Cancel extending"
                                       *ngIf="curSharedUser.extendExpirationMethod && updateAdminPlanPopup"
                                       (click)="cancelExtendExpirationDate()">
                                        <i class="fa fa-times" ></i>
                                    </a>
                                </div>
                            </label>

                            <!--<datepicker style="float: unset; position: relative" class="admin-datepicker"
                                        *ngIf="curSharedUser.planSubType !== dic.CONSTANTS.planSubType.annual"
                                        date-set="{{curSharedUser.adminExpirationDateString}}"
                                        date-min-limit="{{curSharedUser.showOldExpirationDate ? curSharedUser.adminExpirationDateString : adminExpirationDateMinString}}"
                                        date-max-limit="{{adminExpirationDateMaxString}}"
                                        date-format="MM/dd/yyyy">
                                <input type="text" style="width: 230px; margin-top: 6px; cursor: pointer"
                                       (change)="prepareInvoiceNotifyMessage()"
                                       [(ngModel)]="curSharedUser.adminExpirationDate"
                                       [ngStyle]="curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp && {'background-color': '#ecf0f1'}" readonly />
                                <i class="fa fa-chevron-down" style="position: absolute; top: 3px; right: 10px; font-size: 10px; cursor: pointer;"></i>
                            </datepicker>-->
							<date-picker-c
								*ngIf="curSharedUser.planSubType !== dic.CONSTANTS.planSubType.annual"
								[(dateModel)]="curSharedUser.adminExpirationDate"
								[dateMinLimit]="curSharedUser.showOldExpirationDate ? curSharedUser.adminExpirationDateString : adminExpirationDateMinString"
								[dateMaxLimit]="adminExpirationDateMaxString"
								[allowFuture]="true" style="width: 230px; display: inline-block; margin-bottom: 10px;"></date-picker-c>

                            <span style="color: #818181; margin-left: 5px; line-height: 41px;" *ngIf="curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp">Automatically renewed monthly</span>
                            <br/>

                            <label style="padding-top: 5px; vertical-align: unset; min-width: 106px;">Company Name:</label>
                            <input type="text"
                                   style="width:230px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                   maxlength="100"
                                   placeholder="Company Name (optional)"
                                   [(ngModel)]="curSharedUser.companyName" />
                            <br/>
                            <div class="flxRow hSpace1" style="align-items: center; width: fit-content;">
                                <div class="md-checkbox flxRow" style="align-items: center;">
                                    <input id="setAsPartnerId2" type="checkbox"
                                           [(ngModel)]="curSharedUser.setAsPartner"/>
                                    <label for="setAsPartnerId2">Set as Partner</label>
                                </div>
                                <input type="text"
                                       [disabled]="!curSharedUser.setAsPartner"
                                       style="width:230px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                       maxlength="100"
                                       placeholder="Partner ID"
                                       [(ngModel)]="curSharedUser.customerId"
                                       pattern="/^[a-zA-Z]*$/"/>
                            </div>
                            <span>Allocating a Partner-ID will allow this admin to create and allocate plans. Can contain letters only</span>

                            <br/><br/>
                            <div class="flxRow hSpace3" style="margin-top: 10px;">
                                <div class="">
                                    <!--Outbound-->
                                    <label style="padding-top: 5px; vertical-align: unset; min-width: 100px; font-weight: bold; font-size: 14px; text-decoration: underline; margin-bottom: 10px;">Outbound Resources</label>
                                    <br/>
                                    <label style="padding-top: 5px; width: 50px; vertical-align: unset; min-width: 100px;">Emails:</label>
                                    <input type="number" min="1"
                                           style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                           step="1" placeholder="{{curSharedUser.isUnlimited ? 'Unlimited':'Allowed Emails'}}"
                                           [disabled]="curSharedUser.isUnlimited"
                                           [(ngModel)]="curSharedUser.emails">
                                    <span style="color: #818181;"
                                          *ngIf="userManagementLoaded && !isUnlimited">
                                            &nbsp;Available: {{currentAdminObj.alloc_plan_id.emails_left + curSharedUser.alloc_plan_id?.emails_left - curSharedUser.emails}}
                                        </span>
                                    <div *ngIf="isUnlimited"
                                         class="flxRow-start hSpace1 md-checkbox" style="align-items: center; display: inline-flex;">
                                        <input id="unlimitedStorageCheck" required="false" type="checkbox"
                                               (change)="setUnlimitedValues()"
                                               [(ngModel)]="curSharedUser.isUnlimited"/>
                                        <label style="margin-bottom: -17px; margin-left: 5px;" for="unlimitedStorageCheck">Unlimited emails and storage</label>
                                    </div>
                                    <br/>
                                    <label style="padding-top: 5px; width: 50px; vertical-align: unset; min-width: 100px;">Storage:</label>
                                    <input type="number" min="0.5"
                                           style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                           step="0.5" placeholder="{{curSharedUser.isUnlimited ? 'Unlimited':'GB'}}"
                                           [disabled]="curSharedUser.isUnlimited"
                                           [(ngModel)]="curSharedUser.storage">
                                    <span style="color: #818181;"
                                          *ngIf="userManagementLoaded && !isUnlimited">&nbsp;
                                            Available: {{currentAdminObj.alloc_plan_id.storage + curSharedUser.alloc_plan_id?.storage - curSharedUser.storage}}
                                        </span>
                                    <br/>
                                    <label style="padding-top: 5px; width: 50px; vertical-align: unset; min-width: 100px;">Users:</label>
                                    <input type="number" min="1"
                                           style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                           step="1" placeholder="Num"
                                           [(ngModel)]="curSharedUser.allowedUsers"
                                           (change)="prepareInvoiceNotifyMessage();">
                                    <span style="color: #818181;"
                                          *ngIf="userManagementLoaded">
                                            Available: {{currentAdminObj.alloc_plan_id.shared_plan.allowed_users - currentAdminObj.alloc_plan_id.shared_plan.used_users + curSharedUser.alloc_plan_id?.shared_plan?.allowed_users - curSharedUser.allowedUsers}}
                                        </span>
                                    <br/>
                                    <label style="padding-top: 5px; width: 50px; vertical-align: unset; min-width: 100px;">Postmark:</label>
                                    <input type="number" min="0"
                                           style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                           step="1" placeholder="Num"
                                           [(ngModel)]="curSharedUser.allowedPostmark">
                                    <span style="color: #818181;"
                                          *ngIf="userManagementLoaded">
                                            Available: {{currentAdminObj.alloc_plan_id.postmark_left + curSharedUser.alloc_plan_id?.postmark_left - curSharedUser.allowedPostmark}}
                                        </span>
                                </div>
                                <div class="vertical-separator" style="height: auto; border-left: none; opacity: 0.5;"></div>
                                <div style="flex-shrink: 0;">
                                    <!--Inbound-->
                                    <label style="padding-top: 5px; vertical-align: unset; min-width: 100px; font-weight: bold; font-size: 14px; text-decoration: underline; margin-bottom: 10px;">Inbound Resources</label>
                                    <br/>
                                    <div *ngIf="currentAdminObj.alloc_plan_id.threat_protection.mode === dic.CONSTANTS.planTpType.advanced">
                                        <label style="padding-top: 5px; width: 50px; vertical-align: unset; min-width: 100px;">Type:</label>
										<app-dropdown-c style="width: 100px; display: inline-flex; margin-left: 5px;" [text]="curSharedUser.tpType">
											<li *ngFor="let type of dic.CONSTANTS.planTpType | keyvalue: gs.returnZero"
												(click)="curSharedUser.tpType = type.value">
												{{type.value}}
											</li>
										</app-dropdown-c>
                                        <i style="font-weight: 400;" class="fas fa-question-circle" tooltip="<b>Advanced</b> - includes all Trustifi inbound features and protections. Quarantined emails will be retained for 1 year<br><b>Basic</b> - does not include spam detection, threat response, and inbound email rules. Quarantined emails will be retained for 60 days"></i>
                                    </div>
                                    <label style="padding-top: 5px; width: 50px; vertical-align: unset; min-width: 100px;">Mailboxes:</label>
                                    <input type="number" min="0"
                                           style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                           step="1" placeholder="Num"
                                           [(ngModel)]="curSharedUser.allowedLicenses"
                                           (change)="prepareInvoiceNotifyMessage();">
                                    <span style="color: #818181;"
                                          *ngIf="userManagementLoaded">
                                            Available: {{currentAdminObj.alloc_plan_id.threat_protection.allowed_users - currentAdminObj.alloc_plan_id.threat_protection.used_users + curSharedUser.alloc_plan_id?.threat_protection?.allowed_users - curSharedUser.allowedLicenses}}
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flxRow-between hSpace4 bs-enabled" style="align-items: flex-end; margin-top: 15px; min-height: 85px;">
                <div class="flxClmn vSpace1">
                    <div class="text-danger" *ngIf="curSharedUser.errMsg">{{curSharedUser.errMsg}}</div>
                    <div class="flex-row align-items-center bg-warning border border-warning gap-3 p-2 animated fadeIn" style="--bs-bg-opacity: .15;"
                         *ngIf="curSharedUser.invoiceNotify">
                        <i class="fas fa-exclamation-circle text-warning" style="font-size: 1rem;"></i>
                        <div class="flex-column">
                            <div class="flex-row gap-1 align-items-center">
                                <i class="fas fa-circle" style="font-size:0.3em;"></i>
                                <span [innerHTML]="curSharedUser.invoiceNotify"></span>
                            </div>
                            <div class="flex-row gap-1 align-items-center">
                                <i class="fas fa-circle" style="font-size:0.3em;"></i>
                                <span>Action will take place only after clicking "Update"</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flxRow hSpace1">
                    <btn-c class="popup-action-btn" variant="outlined"
                         [loading]="setUserAsAdminInProcess"
                         (action)="closeAllocateModifyPopup();">
                        Cancel
                    </btn-c>
                    <button type="submit" style="display: contents;" [disabled]="isUpdateDisabled()">
                        <btn-c class="popup-action-btn"
                             [loading]="setUserAsAdminInProcess"
                             [isDisabled]="isUpdateDisabled()"
                             (action)="setUserAsAdminPopup ? setUserAsAdmin() : updateAdminPlanExecute()">
                            {{setUserAsAdminPopup ? 'Allocate' : 'Update'}}
                        </btn-c>
                    </button>
                </div>
            </div>
        </div>
    </popup-c>

    <!--Create New Plan popup-->
    <popup-c class="no-content-padding form" *ngIf="addActiveAdminPopup"
           (closeCb)="closeAddAdminPopup()"
           head="Create New Plan" size="large">
        <div class="flxClmn f-1-0 vSpace1" style="overflow: auto; padding: 25px;">
            <span class="w100" style="color: black;">Fill-in the new admin's plan details. we will invite them to sign up.</span>
            <div class="input-wrap flxClmn f-1-0" style="overflow: auto; background: #fcfcfc; padding: 20px;">
                <div>
                    <label class="plan-allocation-label" style="padding-top: 5px; vertical-align: unset; margin-left: 25px;">Managed by:</label>
                    <app-dropdown-c style="width: 227px; display: inline-flex; margin-left: 2px;" [text]="curSharedUser.planManagedBy.display">
						<li *ngFor="let type of dic.CONSTANTS.planManagedBy | keyvalue: gs.returnZero"
                            [explanationC]="type.value.tooltip"
                            (click)="curSharedUser.planManagedBy = type.value;">
                            {{type.value.display}}
                        </li>
                    </app-dropdown-c>
                    <span style="margin-left: 5px;">({{curSharedUser.planManagedBy.tooltip}})</span>
                </div>

                <div style="margin-left: 58px; padding-bottom: 10px;">
                    <div *ngIf="curSharedUser.planManagedBy === dic.CONSTANTS.planManagedBy.admin">
                        <label style="vertical-align: unset; width: 50px;">Email:</label>
                        <input type="text" placeholder="Admin's email (required)"
                               style="width:227px;"
                               [(ngModel)]="curSharedUser.email">
                        <br/>
                        <label style="vertical-align: unset; width: 50px;">Name:</label>
                        <input type="text" placeholder="Admin's name (optional)"
                               style="width:227px;"
                               [(ngModel)]="curSharedUser.name">
                    </div>

                    <div class="hSpace05" *ngIf="curSharedUser.planManagedBy === dic.CONSTANTS.planManagedBy.you">
                        <label style="vertical-align: unset; width: 50px;">Domain:</label>
                        <input type="text" placeholder="Plan domain (required)"
                               style="width:227px;"
                               [(ngModel)]="curSharedUser.domain">
                        <i style="font-weight: 400;" class="fas fa-question-circle" tooltip="This will be the main domain to which most of the plan users belong. It will be possible to add more domains to the plan after you create it"></i>

                    </div>
                </div>
                <div>
                    <label class="plan-allocation-label" style="padding-top: 5px; vertical-align: unset; margin-left: 37px;">Plan Type:</label>
                    <app-dropdown-c style="width: 229px; display: inline-flex; margin-left: 5px;" [text]="dic.CONSTANTS.planSubTypeMap[curSharedUser.planSubType]">
						<li *ngFor="let type of dic.CONSTANTS.planSubType | keyvalue: gs.returnZero"
							(click)="changePlanSubType(type.value)">
							{{dic.CONSTANTS.planSubTypeMap[type.value]}}
						</li>
                    </app-dropdown-c>
                    <i style="font-weight: 400;" *ngIf="curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp" class="fas fa-question-circle" tooltip="<b>MSP</b> - The plan's expiration and allowed emails will refresh on a monthly basis, on the 1st of each month."></i>
                    <div>
                        <!--General-->
                        <!--expirationDate-->
                        <label style="margin-bottom: 6px; margin-left: 19px;"
                               [ngStyle]="curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp && {'pointer-events': 'none'}">
                            <span class="plan-allocation-label" style="margin-left: 12px;">Expires on:</span>
                            <label style="margin-bottom: 0; display: contents;"
                                   *ngIf="curSharedUser.planSubType === dic.CONSTANTS.planSubType.annual"
                                   [ngStyle]="curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp && {'pointer-events': 'none'}">
                                <div class="plan-expiration-extend-menu bs-enabled" style="position: relative; width: 230px; display: inline-block; cursor: unset;">
                                    <input type="text" style="margin: 0;"
                                           [value]="curSharedUser.newExpirationDate && curSharedUser.planSubType === dic.CONSTANTS.planSubType.annual ? (curSharedUser.newExpirationDate | date: 'MM/dd/yyyy') : (curSharedUser.adminExpirationDate | date: 'MM/dd/yyyy')"
                                           [readOnly]="true"
                                           [ngClass]="{'text-success fw-bold' : curSharedUser.extendExpirationMethod}"
                                           [ngStyle]="{'background-color' : curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp ? '#ecf0f1' : ''}"
                                           readonly />
                                    <a class="blue-link" style="position: absolute; top: 50%; transform: translateY(-50%); right: 10px; font-size: 12px;"
                                       (click)="curSharedUser.isExtendExpirationMenuOpen = !curSharedUser.isExtendExpirationMenuOpen; $event.preventDefault();"
									   (clickOutside)="curSharedUser.isExtendExpirationMenuOpen = false;"
									   [exclude]="'#extendExpirationMenu1'">
                                        Extend
                                    </a>
                                    <div class="drop-menu" style="padding: 0; min-width: 0; min-height: 0; max-width: none; max-height: none; width: 165px; height: auto; box-shadow: 1px 0 3px #cccccc; border-radius: 5px; border: solid 1px lightgrey; left: auto; top: 0; bottom: auto; right: -3px;"
                                         [ngClass]="{'active' : curSharedUser.isExtendExpirationMenuOpen}"
										 id="extendExpirationMenu1">
                                        <ul>
											<li *ngFor="let period of expandMethods | keyvalue: gs.returnZero"
												(click)="updateExpirationTime(period.value.value); prepareInvoiceNotifyMessage();">
												<div class="flxRow hSpace05" style="align-items: center;"
													 [ngStyle]="{'font-weight' : curSharedUser.extendExpirationMethod === period.value.value ? 'bold' : 'normal'}">
													<i class="fa fa-plus"></i>
													<span>Add {{period.value.display}}</span>
												</div>
											</li>
                                        </ul>
                                    </div>
                                </div>
                            </label>

                            <!--<datepicker style="float: unset; position: relative" class="admin-datepicker"
                                        *ngIf="curSharedUser.planSubType !== dic.CONSTANTS.planSubType.annual"
                                        date-set="{{curSharedUser.adminExpirationDateString}}"
                                        date-min-limit="{{curSharedUser.showOldExpirationDate ? curSharedUser.adminExpirationDateString : adminExpirationDateMinString}}"
                                        date-max-limit="{{adminExpirationDateMaxString}}"
                                        date-format="MM/dd/yyyy">
                                <input type="text" style="width: 230px; margin-top: 6px; cursor: pointer"
                                       (change)="prepareInvoiceNotifyMessage()"
                                       [(ngModel)]="curSharedUser.adminExpirationDate"
                                       [ngStyle]="curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp && {'background-color': '#ecf0f1'}" readonly />
                                <i class="fa fa-chevron-down" style="position: absolute; top: 3px; right: 10px; font-size: 10px; cursor: pointer;"></i>
                            </datepicker>-->
							<date-picker-c
								*ngIf="curSharedUser.planSubType !== dic.CONSTANTS.planSubType.annual"
								[(dateModel)]="curSharedUser.adminExpirationDate"
								[dateMinLimit]="curSharedUser.showOldExpirationDate ? curSharedUser.adminExpirationDateString : adminExpirationDateMinString"
								[dateMaxLimit]="adminExpirationDateMaxString"
								[allowFuture]="true" style="width: 230px; display: inline-block;"></date-picker-c>
                        </label>
                        <span style="color: #818181; margin-left: 5px; line-height: 41px;" *ngIf="curSharedUser.planSubType === dic.CONSTANTS.planSubType.msp">Automatically renewed monthly</span>
                        <br/>
                        <label class="plan-allocation-label" style="padding-top: 5px; vertical-align: unset;">Company Name:</label>
                        <input type="text"
                               style="width:230px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                               maxlength="100"
                               placeholder="Company Name (optional)"
                               [(ngModel)]="curSharedUser.companyName" />
                        <br/>

                        <div class="flxRow hSpace1" style="align-items: center; width: fit-content;">
                            <div class="md-checkbox flxRow" style="align-items: center;">
                                <input id="setAsPartnerId" type="checkbox"
                                       [(ngModel)]="curSharedUser.setAsPartner"/>
                                <label for="setAsPartnerId">Set as Partner</label>
                            </div>
                            <input type="text"
                                   [disabled]="!curSharedUser.setAsPartner"
                                   style="width:230px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                   maxlength="100"
                                   placeholder="Partner ID"
                                   [(ngModel)]="curSharedUser.customerId"
                                   pattern="/^[a-zA-Z]*$/"/>
                        </div>
                        <span>Allocating a Partner-ID will allow this admin to create and allocate plans. Can contain letters only</span>

                        <br/><br/>
                        <div class="flxRow hSpace3" style="margin-top: 10px;">
                            <div>
                                <!--Outbound-->
                                <label style="padding-top: 5px; vertical-align: unset; min-width: 100px; font-weight: bold; font-size: 14px; text-decoration: underline; margin-bottom: 10px;">Outbound Resources</label>
                                <br/>
                                <label class="plan-allocation-label" style="padding-top: 5px; vertical-align: unset; margin-left: 43px;">Emails:</label>
                                <input type="number" min="1"
                                       style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                       step="1" placeholder="{{curSharedUser.isUnlimited ? 'Unlimited':'Allowed Emails'}}"
                                       [disabled]="curSharedUser.isUnlimited"
                                       [(ngModel)]="curSharedUser.emails">
                                <span style="color: #818181;"
                                      *ngIf="userManagementLoaded && !isUnlimited">
                                        &nbsp;Available: {{currentAdminObj.alloc_plan_id.emails_left - curSharedUser.emails}}
                                    </span>
                                <div *ngIf="isUnlimited"
                                     class="flxRow-start hSpace1 md-checkbox" style="align-items: center; display: inline-flex;">
                                    <input id="unlimitedStorageCheck" required="false" type="checkbox"
                                           (change)="setUnlimitedValues()"
                                           [(ngModel)]="curSharedUser.isUnlimited"/>
                                    <label style="margin-bottom: -17px; margin-left: 5px;" for="unlimitedStorageCheck">Unlimited emails and storage</label>
                                </div>
                                <br/>
                                <label class="plan-allocation-label" style="padding-top: 5px; vertical-align: unset; margin-left: 36px;">Storage:</label>
                                <input type="number" min="0.5"
                                       style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                       step="0.5" placeholder="{{curSharedUser.isUnlimited ? 'Unlimited': 'GB'}}"
                                       [disabled]="curSharedUser.isUnlimited"
                                       [(ngModel)]="curSharedUser.storage">
                                <span style="color: #818181;"
                                      *ngIf="userManagementLoaded && !isUnlimited">&nbsp;
                                        Available: {{currentAdminObj.alloc_plan_id.storage - curSharedUser.storage}}
                                    </span>
                                <br/>
                                <label class="plan-allocation-label" style="padding-top: 5px; vertical-align: unset; margin-left: 46px;">Users:</label>
                                <input type="number" min="1"
                                       style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                       step="1" placeholder="Num"
                                       (change)="prepareInvoiceNotifyMessage()"
                                       [(ngModel)]="curSharedUser.allowedUsers">
                                <span style="color: #818181;"
                                      *ngIf="userManagementLoaded">&nbsp;
                                        Available: {{currentAdminObj.alloc_plan_id.shared_plan.allowed_users - currentAdminObj.alloc_plan_id.shared_plan.used_users - curSharedUser.allowedUsers}}
                                    </span>
                                <br/>
                                <label class="plan-allocation-label" style="padding-top: 5px; vertical-align: unset; margin-left: 23px;">Postmark:</label>
                                <input type="number" min="1"
                                       style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                       step="1" placeholder="Num"
                                       [(ngModel)]="curSharedUser.allowedPostmark">
                                <span style="color: #818181;"
                                      *ngIf="userManagementLoaded">&nbsp;
                                        Available: {{currentAdminObj.alloc_plan_id.postmark_left - curSharedUser.allowedPostmark}}
                                    </span>
                            </div>
                            <div class="vertical-separator" style="height: auto; border-left: none; opacity: 0.5;"></div>
                            <div style="flex-shrink: 0;">
                                <!--Inbound-->
                                <label style="padding-top: 5px; vertical-align: unset; min-width: 100px; font-weight: bold; font-size: 14px; text-decoration: underline; margin-bottom: 10px;">Inbound Resources</label>
                                <br/>
                                <div *ngIf="currentAdminObj.alloc_plan_id.threat_protection.mode === dic.CONSTANTS.planTpType.advanced">
                                    <label class="plan-allocation-label" style="padding-top: 5px; vertical-align: unset; margin-left: 65px;">Type:</label>
									<app-dropdown-c style="width: 100px; display: inline-flex; margin-left: 5px;" [text]="curSharedUser.tpType">
										<li *ngFor="let type of dic.CONSTANTS.planTpType | keyvalue: gs.returnZero"
											(click)="curSharedUser.tpType = type.value">
											{{type.value}}
										</li>
									</app-dropdown-c>
                                    <i style="font-weight: 400; margin-left: 5px;" class="fas fa-question-circle" tooltip="<b>Advanced</b> - includes all Trustifi inbound features and protections. Quarantined emails will be retained for 1 year<br><b>Basic</b> - does not include spam detection, threat response, and inbound email rules. Quarantined emails will be retained for 60 days"></i>
                                </div>
                                <label class="plan-allocation-label" style="padding-top: 5px; vertical-align: unset; margin-left: 34px;">Mailboxes:</label>
                                <input type="number" min="0"
                                       style="width:170px; border-radius: 4px; box-sizing: border-box; border: 1px solid #ccc; padding: 2px 8px;"
                                       step="1" placeholder="Num"
                                       (change)="prepareInvoiceNotifyMessage()"
                                       [(ngModel)]="curSharedUser.allowedLicenses">
                                <span style="color: #818181;"
                                      *ngIf="userManagementLoaded">
                                        Available: {{currentAdminObj.alloc_plan_id.threat_protection.allowed_users - currentAdminObj.alloc_plan_id.threat_protection.used_users - curSharedUser.allowedLicenses}}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flxRow-between hSpace4 bs-enabled" style="align-items: flex-end; min-height: 85px;">
                <div>
                    <div class="flex-row align-items-center bg-warning border border-warning gap-3 p-2 animated fadeIn" style="--bs-bg-opacity: .15;"
                         *ngIf="curSharedUser.invoiceNotify">
                        <i class="fas fa-exclamation-circle text-warning" style="font-size: 1rem;"></i>
                        <div class="flex-column">
                            <div class="flex-row gap-1 align-items-center">
                                <i class="fas fa-circle" style="font-size:0.3em;"></i>
                                <span [innerHTML]="curSharedUser.invoiceNotify"></span>
                            </div>
                            <div class="flex-row gap-1 align-items-center">
                                <i class="fas fa-circle" style="font-size:0.3em;"></i>
                                <span>Action will take place only after clicking "Update"</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flxRow hSpace1">
                    <btn-c class="popup-action-btn" variant="outlined"
                         [loading]="stopActiveUserPopup"
                         (action)="closeAddAdminPopup();">
                        Cancel
                    </btn-c>
                    <btn-c class="popup-action-btn"
                         (action)="addAdminToSharedPlan()"
                         [loading]="stopActiveUserPopup"
                         [isDisabled]="isUpdateDisabled()">
                        Create
                    </btn-c>
                </div>
            </div>
        </div>
    </popup-c>

    <!--User statistics popup-->
    <popup-c *ngIf="showUserSettings" class="form"
           head="{{curSharedUser.user_role === dic.CONSTANTS.userRole.subAdmin ? gs.toCapitalize(dic.CONSTANTS.userRole.admin) : gs.toCapitalize(curSharedUser.user_role)}} statistics"
           (closeCb)="showUserSettings = false"
           size="medium" height="auto"
           [buttons]="{cancel: 'OK'}">
        <div class="flxClmn f-1-0 vSpace2 " style="line-height: 1;">
            <div style="margin-bottom: 10px; font-size: 14px;"><b>{{curSharedUser.name}}</b>'s {{curSharedUser.user_role === dic.CONSTANTS.userRole.admin || curSharedUser.user_role === dic.CONSTANTS.userRole.subAdmin ? 'plan' : ''}} details and sending statistics</div>
            <div class="txt-block">
                <span>Details:</span>
            </div>
            <div class="input-wrap" style="padding-top:5px; margin-left:12%; padding-bottom:15px;">
                <div class="input-wrap">
                    <div class="row" style="padding: 5px 0; margin-bottom: 0;">
                        <div style="width:85px; display: inline-block;">
                            <label>Email:</label>
                        </div>
                        <span>{{curSharedUser.email}}</span>
                        <br/>
                        <div *ngIf="curSharedUser.name">
                            <div style="width:85px; display: inline-block;">
                                <label>Name:</label>
                            </div>
                            <span>{{curSharedUser.name}}</span>
                            <br/>
                        </div>
                        <div *ngIf="curSharedUser.job_title">
                            <div style="width:85px; display: inline-block;">
                                <label>Job Title:</label>
                            </div>
                            <span>{{curSharedUser.job_title}}</span>
							<i class="fas fa-user-tie" style="padding-left: 5px; color: green;" tooltip="Executive Role" *ngIf="curSharedUser.is_executive"></i>
							<br/>
                        </div>
                        <div style="width: 85px; display: inline-block;">
                            <label>Role:</label>
                        </div>
                        <span style="text-transform: capitalize;">{{curSharedUser.user_role === dic.CONSTANTS.userRole.subAdmin ? sharedPlanInfo.users[0].customer_user_role : curSharedUser.user_role}}</span>
                        <br/>
                        <div style="width: 85px; display: inline-block;">
                            <label>Status:</label>
                        </div>
                        <span [ngStyle]="curSharedUser.status === dic.CONSTANTS.userInPlanStatus.active && curSharedUser.status !== dic.CONSTANTS.userInPlanStatus.blocked ? {'color':'green'} :curSharedUser.status === dic.CONSTANTS.userInPlanStatus.invitationSent ? {'color':'darkorange'} : {'color':'red'}">{{curSharedUser.status ? curSharedUser.status : dic.CONSTANTS.userInPlanStatus.active}}</span>
                    </div>
                </div>
            </div>
            <div class="txt-block" *ngIf="curSharedUser.alloc_plan_id">
                <span>Plan:</span>
            </div>
            <div class="input-wrap" style="padding-top:5px; margin-left:12%; padding-bottom:15px;"
                 *ngIf="curSharedUser.alloc_plan_id">
                <div class="input-wrap">
                    <div class="row" style="padding: 5px 0; margin-bottom: 0;">
                        <div style="width:85px; display: inline-block;">
                            <label>Emails:</label>
                        </div>
                        <span>{{curSharedUser.alloc_plan_id.is_unlimited ? 'Unlimited': curSharedUser.alloc_plan_id.emails_left}}</span>
                        <br/>
                        <div style="width: 85px; display: inline-block;">
                            <label>Storage:</label>
                        </div>
                        <span style="text-transform: capitalize;">{{curSharedUser.alloc_plan_id.is_unlimited ? 'Unlimited': curSharedUser.alloc_plan_id.storage}}</span>
                        <br/>
                        <div style="width: 85px; display: inline-block;">
                            <label>Postmark:</label>
                        </div>
                        <span style="text-transform: capitalize;">{{curSharedUser.alloc_plan_id.postmark_left}}</span>
                        <br/>
                        <div style="width: 85px; display: inline-block;">
                            <label>Max Users:</label>
                        </div>
                        <span style="text-transform: capitalize;">{{curSharedUser.alloc_plan_id.shared_plan.allowed_users}}</span>
                        <br/>
                        <div style="width: 82px; display: inline-block;">
                            <label>Expires:</label>
                        </div>
                        <span>
                                     <time>{{curSharedUser.alloc_plan_id.expired | date:'MM/dd/yyyy HH:mm' }}</time>
                                 </span>
                    </div>
                </div>
            </div>
            <div class="txt-block">
                <span>Total Sent:</span>
            </div>

            <div class="input-wrap" *ngIf="curSharedUser.user_role !== 'admin'"
                 style="padding-top:5px; margin-left:12%; padding-bottom:15px;"
                 (ngInit)="getUserOfSharedPlanStatistics(curSharedUser)">
                <div class="input-wrap">
                    <div class="row" style="padding: 5px 0; margin-bottom: 0;">
						<ng-container *ngIf="UserSharedPlanStatistics.length > 0">
							<div *ngFor="let stats of UserSharedPlanStatistics">
								<div style="width:294px; display: inline-block;">
									<label style="margin-bottom:10px;">{{stats.name}}:</label>
								</div>
								<span>{{stats.value}}</span>
							</div>
						</ng-container>
                        <div *ngIf="UserSharedPlanStatistics.length === 0">
                            <i style="color:#808080;">No data found</i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="input-wrap" *ngIf="curSharedUser.user_role === 'admin'"
                 style="padding-top:5px; margin-left:12%; padding-bottom:15px;"
                 (ngInit)="getSharedPlanStatistics(curSharedUser)">
                <div class="input-wrap">

                    <div class="row" style="padding: 5px 0; margin-bottom: 0;">
						<ng-container *ngIf="SharedPlanStatistics.length > 0">
							<div *ngFor="let stats of SharedPlanStatistics" (ngInit)="stats.opened = false;">
								<div (click)="stats.opened = !stats.opened" style="cursor: pointer;">
									<i class="zmdi zmdi-chevron-down"
									   style="position: absolute; margin-top: -2px; margin-left: -10px; color: #486db5; font-size: 17px;"
									   *ngIf="stats.opened"></i>
									<i class="zmdi zmdi-chevron-right"
									   style="position: absolute; margin-top: -2px; margin-left: -10px; color: #486db5; font-size: 17px;"
									   *ngIf="!stats.opened"></i>
									<label style="font-size:14px; color: black; cursor: pointer;">
										{{stats.userName ? stats.userEmail + ' (' + stats.userName + ')' : stats.userEmail}}
									</label>
								</div>
								<div *ngIf="stats.opened">
									<div *ngFor="let stat of stats.userStats">
										<div style="width:294px; display: inline-block;">
											<label id="userStatsTitle-{{stat.name}}">{{stat.name}}:</label>
										</div>
										<span id="userStatsInfo-{{stat.name}}">{{stat.value}}</span>
									</div>
									<div *ngIf="!stats.userStats || !stats.userStats.length" style="margin-bottom: 10px;">
										<i style="color:#808080;">No statistics available</i>
									</div>
								</div>
							</div>
						</ng-container>

                        <div *ngIf="SharedPlanStatistics.length === 0">
                            <i style="color:#808080;">No data found</i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </popup-c>

    <!--Manage Account Log-in Options popup-->
    <popup-c class="bs-enabled" *ngIf="loginOptionsPopup?.show"
           head="Manage Account Log-in Options"
           (closeCb)="loginOptionsPopup = null"
           (confirmCb)="loginOptionsPopup.doneCb()"
           [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: loginOptionsPopup.applyInProcess}}"
           size="large" height="auto">
        <div class="flxClmn f-1-0" style="overflow: auto;">
			<div class="list-group list-group-flush settings-list-container">
				<!--Settings: Password Expiration -->
				<div class="list-group-item settings-list-item">
					<div class="title-section-container flex-column gap-2">
						<b>Password Expiration</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Enforce expiration on your users' passwords. Expiration will be enforced only for users under your verified domains:</span>
						<div class="flex-column gap-1 text-muted" style="max-height: 15vh; min-height: 85px; overflow: auto; background: #ffffffa1; padding: 5px;"
							 *ngIf="sharedPlanInfo.domains?.length">
							<ng-container *ngFor="let domain of sharedPlanInfo.domains; trackBy: gs.trackByIndex">
								<span *ngIf="domain.verified"
									  style="margin-left: 10px;">
									- {{domain.domain}}
								</span>
							</ng-container>
						</div>
						<span class="text-muted" *ngIf="!isAnyDomaimVerified" style="margin-left: 5px; margin-top: 10px; font-style: italic;">No verified domains</span>
					</div>

					<div class="content-container flex-column gap-2">
						<label class="radio-container hSpace05 m-0" [ngClass]="{'disabled-overlay' : !isUserDomaimVerified}">
							<input type="radio" name="accountPasswordExpiration"
								   [value]="false"
								   (ngModelChange)="loginOptionsPopup.error = false;"
								   [(ngModel)]="loginOptionsPopup.enforcePasswordExpiration.enabled"
								   [disabled]="!isUserDomaimVerified">
							<span class="checkmark"></span>
							<span>Passwords never expire</span>
							<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Passwords will never require changing"></i>
						</label>

						<label class="radio-container hSpace05 m-0" [ngClass]="{'disabled-overlay' : !isUserDomaimVerified}">
							<input type="radio" name="accountPasswordExpiration"
								   [value]="true"
								   (ngModelChange)="loginOptionsPopup.error = false;"
								   [(ngModel)]="loginOptionsPopup.enforcePasswordExpiration.enabled"
								   [disabled]="!isUserDomaimVerified">
							<span class="checkmark"></span>
							<span>Passwords expire after</span>
							<input [selectAllText]="selectExpirationTextFlag"
								   style="width: 65px; height: inherit; margin-bottom:0; box-sizing: border-box; border-radius: 4px; border: 1px solid #ccc; padding: 2px 8px; position: relative; opacity: 1; cursor: auto; margin: 0 3px;"
								   type="number"
								   [disabled]="!loginOptionsPopup.enforcePasswordExpiration.enabled"
								   (ngModelChange)="loginOptionsPopup.error = false;"
								   [(ngModel)]="loginOptionsPopup.enforcePasswordExpiration.expiration_days" size="5"
								   placeholder="Days">
							<label style="vertical-align: unset;">Days</label>
							<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="After the set amount of days, passwords will expire and require changing to log in.<br/><b>Minimum expiration</b> is 10 days.<br/><b>Maximum expiration</b> is 365 days."></i>
						</label>

						<ng-container *ngIf="!isUserDomaimVerified">
							<br/>
							<span class="text-warning">{{dic.ERRORS.auth0RuleIdNoDomains}}</span>
						</ng-container>
					</div>
				</div>

				<!--Settings: Enforce log in methods -->
				<div class="list-group-item settings-list-item">
					<div class="title-section-container flex-column gap-2">
						<b>Enforce log in methods</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Select which log in methods should be allowed for your users</span>
					</div>
					<div class="content-container flex-column gap-1">
						<label style="margin-bottom: 7px;" class="radio-container hSpace05">
							<input type="radio" name="enforceSSo" style="height: 0;"
								   [value]="false"
								   (ngModelChange)="loginOptionsPopup.error = false;"
								   [(ngModel)]="loginOptionsPopup.enforceLoginMethod">
							<span class="checkmark"></span>
							<span>Do not enforce log in method</span>
							<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Users could use any authentication method to log in"></i>
						</label>

						<div class="flxRow hSpace2" style="align-items: center;">
							<label style="margin-bottom: 0; flex-basis: 240px; flex-shrink: 0;" class="radio-container hSpace05">
								<input type="radio" name="enforceSSo" style="height: 0;"
									   [value]="true"
									   (ngModelChange)="loginOptionsPopup.error = false;"
									   [(ngModel)]="loginOptionsPopup.enforceLoginMethod">
								<span class="checkmark"></span>
								<span>Allow only these log in methods:</span>
								<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Users will only be able to log in using the selected methods"></i>
							</label>
							<app-dropdown-c class="f-1-0" style="line-height: 1; max-width: 250px;"
											[error]="loginOptionsPopup.error && loginOptionsPopup.enforceLoginMethod && !_.some(loginOptionsPopup.allowedLoginMethods, 'selected')"
											[items]="loginOptionsPopup.allowedLoginMethods"
											[isDisabled]="!loginOptionsPopup.enforceLoginMethod"
											[isMultipleSelection]="true"
											(onItemClicked)="loginOptionsPopup.error = false;"
											itemDisplayKey="display">
							</app-dropdown-c>
						</div>
					</div>
				</div>

				<!--Settings: whitelist/blocklist coutries -->
				<div class="list-group-item settings-list-item">
					<div class="title-section-container flex-column gap-2">
						<b>Block user login by location</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Block access to the application according to the location from which the login is performed</span>
					</div>
					<div class="content-container flex-column gap-1">
						<label style="margin-bottom: 7px;" class="radio-container hSpace05">
							<input type="radio" name="loginCountriesMethod" style="height: 0;"
								   [value]="loginCountriesMethods.all"
								   (ngModelChange)="loginOptionsPopup.error = false;"
								   [(ngModel)]="loginOptionsPopup.loginCountriesMethod">
							<span class="checkmark"></span>
							<span>Allow all countries</span>
						</label>

						<div class="flxRow hSpace2" style="align-items: center;">
							<label style="margin-bottom: 0; flex-basis: 240px; flex-shrink: 0;" class="radio-container hSpace05">
								<input type="radio" name="loginCountriesMethod" style="height: 0;"
									   [value]="loginCountriesMethods.allowlist"
									   (ngModelChange)="loginOptionsPopup.error = false;"
									   [(ngModel)]="loginOptionsPopup.loginCountriesMethod">
								<span class="checkmark"></span>
								<span>Allow only these countries:</span>
								<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Users will only be able to log in from within the selected countries"></i>
							</label>
							<app-dropdown-c class="f-1-0 show-above" style="--bs-dropdown-menu-max-height: 200px; max-width: 250px;"
											[items]="loginOptionsPopup.countries.allowOnly" itemDisplayKey="name"
											[error]="loginOptionsPopup.error && loginOptionsPopup.loginCountriesMethod === loginCountriesMethods.allowlist && !_.some(loginOptionsPopup.countries.allowOnly, 'selected')"
											[isDisabled]="loginOptionsPopup.loginCountriesMethod !== loginCountriesMethods.allowlist"
											[isMultipleSelection]="true"
											(onItemClicked)="loginOptionsPopup.error = false;"
											[showTitle]="_.filter(loginOptionsPopup.countries.allowOnly, 'selected').length > 1"
											[enableSearch]="true">
							</app-dropdown-c>
						</div>

						<div class="flxRow hSpace2" style="align-items: center;">
							<label style="margin-bottom: 0; flex-basis: 240px;  flex-shrink: 0;" class="radio-container hSpace05">
								<input type="radio" name="loginCountriesMethod" style="height: 0;"
									   [value]="loginCountriesMethods.blocklist"
									   (ngModelChange)="loginOptionsPopup.error = false;"
									   [(ngModel)]="loginOptionsPopup.loginCountriesMethod">
								<span class="checkmark"></span>
								<span>Block only these countries:</span>
								<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Users will not be able to log in from within the selected countries, but from within every other country"></i>
							</label>
							<app-dropdown-c class="f-1-0 show-above" style="--bs-dropdown-menu-max-height: 200px; max-width: 250px;"
											[items]="loginOptionsPopup.countries.blockOnly" itemDisplayKey="name"
											[error]="loginOptionsPopup.error && loginOptionsPopup.loginCountriesMethod === loginCountriesMethods.blocklist && !_.some(loginOptionsPopup.countries.blockOnly, 'selected')"
											[isDisabled]="loginOptionsPopup.loginCountriesMethod !== loginCountriesMethods.blocklist"
											[isMultipleSelection]="true"
											(onItemClicked)="loginOptionsPopup.error = false;"
											[showTitle]="_.filter(loginOptionsPopup.countries.blockOnly, 'selected').length > 1"
											[enableSearch]="true">
							</app-dropdown-c>
						</div>
					</div>
				</div>
			</div>
        </div>
    </popup-c>

    <!--Change User Name popup-->
    <popup-c *ngIf="changeUserNamePopup?.show"
           head="Change User Name"
           (closeCb)="changeUserNamePopup.show = false"
           (confirmCb)="changeUserNamePopup.submitted = true;
                       updateUserName(changeUserNamePopup.user, changeUserNamePopup.newName.first, changeUserNamePopup.newName.last);"
           [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}"
           size="dynamic">
        <div class="flxClmn f-1-0">
            <table class="simpleDataTableShrink f-1-0" style="table-layout: fixed;">
                <tr>
                    <td style="width: 120px;">Current name:</td>
                    <td><b>{{changeUserNamePopup.user.name}}</b></td>
                </tr>
                <tr>
                    <td>New name:</td>
                    <td>
                        <div class="flxClmn">
                            <input type="text" placeholder="First Name" style="margin: 0; margin-bottom: 5px; width: 300px;"
                                   (change)="changeUserNamePopup.submitted = false;"
                                   [ngClass]="{'validation-error' : changeUserNamePopup.submitted && !changeUserNamePopup.newName.first}"
                                   [(ngModel)]="changeUserNamePopup.newName.first" autofocus/>
                            <input type="text" placeholder="Last Name" style="margin: 0; margin-bottom: 5px; width: 300px;"
                                   (change)="changeUserNamePopup.submitted = false;"
                                   [ngClass]="{'validation-error' : changeUserNamePopup.submitted && !changeUserNamePopup.newName.last}"
                                   [(ngModel)]="changeUserNamePopup.newName.last"/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </popup-c>

	<!--Set User Timezone popup-->
    <popup-c class="overflownV bs-enabled" *ngIf="setUserTimezonePopup?.show"
            head="Set User Time Zone"
			subTitle="Set a time zone for <b>{{setUserTimezonePopup.users.length > 1 ? setUserTimezonePopup.users.length + ' users' : (setUserTimezonePopup.users[0].name || setUserTimezonePopup.users[0].email)}}</b>"
            (closeCb)="setUserTimezonePopup.show = false"
            (confirmCb)="setUserTimezonePopup.confirmCb();"
            [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: setUserTimezonePopup.setTimezoneInProcess}}"
            size="dynamic">
        <div class="flxClmn f-1-0 vSpace2" style="overflow: visible;">
			<div class="flex-column gap-3">
				<div class="flex-row align-items-center gap-2 flex-shrink-0"
					 *ngIf="setUserTimezonePopup.currentTimezone">
					<span style="flex-basis: 120px; flex-shrink: 0;">Current time zone:</span>
					<span class="fw-bold">{{setUserTimezonePopup.currentTimezone.label}}</span>
				</div>
				<div class="flex-row align-items-center gap-2">
					<span style="flex-basis: 120px; flex-shrink: 0;">New time zone:</span>
					<app-dropdown-c style="width: 250px;"
									text="{{setUserTimezonePopup.newTimezone?.label || 'Select'}}"
									[enableSearch]="true"
									[alwaysDrop]="true"
									itemDisplayKey="label"
									[items]="timeZones"
									(onItemClicked)="setUserTimezonePopup.newTimezone = $event.item;"></app-dropdown-c>
				</div>
			</div>
			<br/>
			<div class="flex-row align-items-center gap-3">
				<i class="fa fa-info-circle text-info"></i>
				<span style="font-size: 12px;">This time zone will be used to show dates and times for reports sent by the system, such as quarantined email reports</span>
			</div>
        </div>
    </popup-c>


		<groups-popup-c
			*ngIf="showGroups"
			(cancelCb)="showGroups = false"
			[showActions]="true"
			[popupTitle]="'Groups'">
		</groups-popup-c>

    <!--Edit personal reviewer popup-->
    <popup-c class="bs-enabled" *ngIf="personalReviewerPopup?.show"
			 head="Global Reviewer"
			 subTitle="Manage permissions of <b>{{personalReviewerPopup?.userObj.name || personalReviewerPopup?.userObj.email}}</b> for plans under this partner-ID"
			 (closeCb)="personalReviewerPopup = null;"
			 size="large">
		<div class="flxClmn f-1-0" style="overflow: auto;">
			<div class="list-group list-group-flush settings-list-container">
				<div class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2" style="flex: 6 0;">
						<b>Allow reviewer permissions for the reviewer's own plan</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">The reviewer will have access to data and can perform actions on their own plan. If disabled, the reviewer can only access other plans under this partner-ID</span>
					</div>
					<div class="content-container">
						<toggle-switch-c id="allow_review_access_main_plan"
										 [hideText]="true"
										 [(model)]="personalReviewerPopup.userObj.global_reviewer.allow_review_access_main_plan"
										 (onChange)="updateUserGlobalReviewerExecute('general')"></toggle-switch-c>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2" style="flex: 6 0;">
						<b>Set as Inbound Shield reviewer</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Reviewers will can view quarantined emails for all of your managed plans and clients, as well as take actions</span>
					</div>
					<div class="content-container flex-column gap-2">
						<toggle-switch-c id="isGlobalInboundReviewer"
										 [hideText]="true"
										 [(model)]="personalReviewerPopup.userObj.global_reviewer.inbound.enabled"
										 (onChange)="updateUserGlobalReviewerExecute('inbound')"></toggle-switch-c>
						<div class="flex-column gap-2"
							 [ngClass]="{'disabled-overlay' : !personalReviewerPopup.userObj.global_reviewer.inbound.enabled}">
							<div class="flex-row gap-2 align-items-center justify-content-between">
								<span>Allow this reviewer to release malicious emails</span>
								<toggle-switch-c id="reviewer_allow_release_malicious"
												 [hideText]="true"
												 [isDisabled]="!personalReviewerPopup.userObj.global_reviewer.inbound.enabled"
												 [(model)]="personalReviewerPopup.userObj.global_reviewer.inbound.reviewer_allow_release_malicious"
												 (onChange)="updateUserGlobalReviewerExecute('inbound')"></toggle-switch-c>
							</div>
							<div class="flex-row gap-2 align-items-center justify-content-between">
								<span>Allow this reviewer to view quarantine email content</span>
								<toggle-switch-c id="allow_view_content"
												 [hideText]="true"
												 [isDisabled]="!personalReviewerPopup.userObj.global_reviewer.inbound.enabled"
												 [(model)]="personalReviewerPopup.userObj.global_reviewer.inbound.allow_view_content"
												 (onChange)="updateUserGlobalReviewerExecute('inbound')"></toggle-switch-c>
							</div>
						</div>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2" style="flex: 6 0;">
						<b>Set as Outbound reviewer</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Reviewers will have access to outbound setting, rules, and policies for all of your managed plans and clients</span>
					</div>
					<div class="content-container">
						<toggle-switch-c id="isGlobalOutboundReviewer"
										 [hideText]="true"
										 [(model)]="personalReviewerPopup.userObj.global_reviewer.outbound.enabled"
										 (onChange)="updateUserGlobalReviewerExecute('outbound')"></toggle-switch-c>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2" style="flex: 6 0;">
						<b>Set as Archive reviewer</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Reviewers which will have access to archive information</span>
					</div>
					<div class="content-container flex-column gap-2">
						<toggle-switch-c id="isGlobalArchiveReviewer"
										 [hideText]="true"
										 [(model)]="personalReviewerPopup.userObj.global_reviewer.archive.enabled"
										 (onChange)="updateUserGlobalReviewerExecute('archive')"></toggle-switch-c>
						<div class="flex-column gap-2"
							 [ngClass]="{'disabled-overlay' : !personalReviewerPopup.userObj.global_reviewer.archive.enabled}">
							<div class="flex-row gap-2 align-items-center justify-content-between">
								<span>Allow this reviewer to view archived email content</span>
								<toggle-switch-c id="allow_view_content_archive"
												 [hideText]="true"
												 [isDisabled]="!personalReviewerPopup.userObj.global_reviewer.archive.enabled"
												 [(model)]="personalReviewerPopup.userObj.global_reviewer.archive.allow_view_content"
												 (onChange)="updateUserGlobalReviewerExecute('archive')"></toggle-switch-c>
							</div>
						</div>

					</div>
				</div>

				<div class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2" style="flex: 6 0;">
						<b>Set as Threat Simulation reviewer</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Reviewers will have access to all Threat Simulation pages, and will be able to send Threat Simulation campaigns and view campaign metrics</span>
					</div>
					<div class="content-container">
						<toggle-switch-c id="isGlobalThreatSimulationReviewer"
										 [hideText]="true"
										 [(model)]="personalReviewerPopup.userObj.global_reviewer.threat_simulation.enabled"
										 (onChange)="updateUserGlobalReviewerExecute('threat_simulation')"></toggle-switch-c>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2" style="flex: 6 0;">
						<b>Set as Account Takeover reviewer</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Reviewers will have access to information regarding Account Takeover incidents and will receive notifications for such incidents</span>
					</div>
					<div class="content-container">
						<toggle-switch-c id="isGlobalAcpReviewer"
										 [hideText]="true"
										 [(model)]="personalReviewerPopup.userObj.global_reviewer.account_compromised.enabled"
										 (onChange)="updateUserGlobalReviewerExecute('account_compromised')"></toggle-switch-c>
					</div>
				</div>
			</div>
        </div>
    </popup-c>

	<popup-c *ngIf="userPermissionPopup?.show"
		   head="Modify Permissions"
		   class="bs-enabled"
		   subTitle="Configure read and write permissions for the user {{userPermissionPopup.userObj.email}}"
		   (closeCb)="userPermissionPopup = null;"
		   size="large">

		<div class="flxClmn gap-4">
			<div class="flxRow hSpace2">
				<div class="flxClmn gap-1" style="width: 450px;">
					<b>Grant administrative permissions to user</b>
					<span>Administrative permissions empower the user to navigate through and modify the complete plan configuration across all pages</span>
				</div>
				<div>
					<toggle-switch-c id="isSubAdmin"
								   [hideText]="true"
								   [(model)]="userPermissionPopup.isSubAdmin"
								   (onChange)="modifyUserPermissionsExecute()"></toggle-switch-c>
				</div>
			</div>

			<div class="flxRow hSpace2">
				<div class="flxClmn gap-1" style="width: 450px;">
					<b>Read only permissions</b>
					<span>Admin will have read-only access with no ability to make changes</span>
				</div>
				<div class="content-container flex-column gap-3">
					<toggle-switch-c id="readOnlyAdmin"
									 [hideText]="true"
									 [isDisabled]="!userPermissionPopup.isSubAdmin"
									 [(model)]="userPermissionPopup.readOnly"
									 (onChange)="modifyUserPermissionsExecute()"></toggle-switch-c>
				</div>
			</div>

			<div class="flxRow hSpace2" *ngIf="sharedPlanInfo.customer_id">
				<div class="flxClmn gap-1" style="width: 450px;">
					<b>Multi tenant view</b>
					<span>Admin will have access to the multi-tenant selector, which allows them to directly access managed plans and clients</span>
				</div>
				<div class="content-container flex-column gap-3">
					<toggle-switch-c id="show_multi_tenant"
									 [hideText]="true"
									 [isDisabled]="!userPermissionPopup.isSubAdmin"
									 [(model)]="userPermissionPopup.showMultiTenant"
									 (onChange)="modifyUserPermissionsExecute()"></toggle-switch-c>
				</div>
			</div>

			<hr class="mb-0 mt-0"/>

			<div class="info-banner">Please note: Setting "Reviewer" status for a user will grant "write" permissions for that specific module</div>

			<div class="flxRow hSpace2">
				<div class="flxClmn gap-1" style="width: 450px;">
					<b>Set as Outbound reviewer</b>
					<span>Reviewers will receive notifications about quarantined email threats sent by your protected mailboxes and will be able to take immediate actions</span>
				</div>
				<div class="content-container flex-column gap-3">
					<toggle-switch-c id="isOutboundReviewer"
								   [hideText]="true"
								   [(model)]="userPermissionPopup.isOutboundReviewer"
								   (onChange)="modifyUserReviewerExecute('outbound')"></toggle-switch-c>
				</div>
			</div>

			<div class="flxRow hSpace2">
				<div class="flxClmn gap-1" style="width: 450px;">
					<b>Set as Inbound Shield reviewer</b>
					<span>Reviewers will receive notifications about quarantined email threats sent to your protected mailboxes and will be able to take immediate actions</span>
				</div>
				<div class="content-container flex-column gap-3">
					<toggle-switch-c id="isInboundReviewer"
								   [hideText]="true"
								   [(model)]="userPermissionPopup.isInboundReviewer"
								   (onChange)="modifyUserReviewerExecute('inbound')"></toggle-switch-c>
				</div>
			</div>

			<div class="flxRow hSpace2">
				<div class="flxClmn gap-1" style="width: 450px;">
					<b>Set as Archive reviewer</b>
					<span>Reviewers which will have access to archive information</span>
				</div>
				<div class="content-container flex-column gap-3">
					<toggle-switch-c id="isArchiveReviewer"
								   [hideText]="true"
								   [(model)]="userPermissionPopup.isArchiveReviewer"
								   (onChange)="modifyUserReviewerExecute('archive')"></toggle-switch-c>
				</div>
			</div>

			<div class="flxRow hSpace2">
				<div class="flxClmn gap-1" style="width: 450px;">
					<b>Set as Threat Simulation reviewer</b>
					<span>Reviewers will have access to all Threat Simulation pages, and will be able to send Threat Simulation campaigns and view campaign metrics</span>
				</div>
				<div class="content-container flex-column gap-3">
					<toggle-switch-c id="isThreatSimulationReviewer"
									 [hideText]="true"
									 [(model)]="userPermissionPopup.isThreatSimulationReviewer"
									 (onChange)="modifyUserReviewerExecute('threat_simulation')"></toggle-switch-c>
				</div>
			</div>

			<div class="flxRow hSpace2">
				<div class="flxClmn gap-1" style="width: 450px;">
					<b>Set as Account Takeover reviewer</b>
					<span>Reviewers will have access to information regarding Account Takeover incidents and will receive notifications for such incidents</span>
				</div>
				<div class="content-container flex-column gap-3">
					<toggle-switch-c id="isAcpReviewer"
								   [hideText]="true"
								   [(model)]="userPermissionPopup.isAcpReviewer"
								   (onChange)="modifyUserReviewerExecute('account_compromised')"></toggle-switch-c>
				</div>
			</div>

			<div class="flxRow hSpace2" *ngIf="sharedPlanInfo.customer_id">
				<div class="flxClmn gap-1" style="width: 450px;">
					<b>Set as Partner reviewer</b>
					<span>Reviewers will have access to partner information</span>
				</div>
				<div class="content-container flex-column gap-3">
					<toggle-switch-c id="isPartnerReviewer"
									 [hideText]="true"
									 [(model)]="userPermissionPopup.isPartnerReviewer"
									 (onChange)="modifyUserReviewerExecute('partner')"></toggle-switch-c>
				</div>
			</div>

		</div>
	</popup-c>
</div>
