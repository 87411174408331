import {RouteService} from './routeService';
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ClioService {

    constructor(private rs:RouteService) {}

    getloginurl(callback) {
        this.rs.clioGetAuthorizationURL().then(function (res) {
            let url = res && res.url;
            if(!url) {
                callback('clio url is empty');
            }
            else {
                callback(null, url);
            }
        }, err => {
            callback(err);
        });
    }

    getalldocs(callback) {
        this.rs.clioGetAllDocs().then(function (res) {
            if(!res) {
                callback('no clio documents');
            }
            else {
                callback(null, res);
            }
        }, err => {
            callback(err);
        });
    }

    attachdoc(doc_ids, callback) {
        this.rs.clioUploadDoc(doc_ids).then(function (res) {
            if(!res) {
                callback('no clio documents');
            }
            else {
                callback(null, res);
            }
        }, err => {
            callback(err);
        });
    }
}
