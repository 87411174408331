<div class="popup-layer animated fadeIn" *ngIf="initiated" tabindex="0" [appAutoFocus]="true" (keydown.escape)="closeCb.emit()">
    <div class="popup-frame-outer-container"
         [ngClass]="size"
         [ngStyle]="{'width' : width, 'height' : height, 'max-width': width ? 'calc(100vw - 5vh*2)' : '', 'max-height': height ? 'calc(100vh - 5vh*2)' : ''}">
        <div class="popup-frame-inner-container flxClmn" >
            <i class="zmdi zmdi-close hvr-darken cursor-pointer" style="position: absolute; top: 10px; bottom: auto; right: 10px; left: auto; font-size: 1.5rem; color: dimgrey; padding: 5px 10px; z-index: 15;"
               *ngIf="closeCb.observed" (click)="closeCb.emit()"></i>
            <div class="popup-title-container flxClmn vSpace1" *ngIf="head || subTitle">
                <h5 style="font-weight: 500; font-size: 1.25rem; margin: 0;" *ngIf="head" [innerHTML]="head"></h5>
                <span style="font-weight: 500; color: #6c757d;" *ngIf="subTitle" [innerHTML]="subTitle"></span>
            </div>
            <div class="popup-content-container flxClmn f-1-0" [ngStyle]="{'max-height' : contentMaxHeight}">
                <ng-content></ng-content>
            </div>
            <div class="hSpace1" style="align-items: center;"
                 *ngIf="buttons && (buttons.cancel || buttons.confirm)"
                 [ngStyle]="{'margin' : _.reject(_.values(_.omitBy(buttons,_.isNull)), 'hide').length > 1 ? '25px' : '20px 0 35px'}"
                 [ngClass]="{'flxRow-end' : _.reject(_.values(_.omitBy(buttons,_.isNull)), 'hide').length > 1, 'flxRow-center' : _.reject(_.values(_.omitBy(buttons,_.isNull)), 'hide').length === 1}">
                <btn-c class="popup-action-btn"
                     [isDisabled]="!!(buttons.cancel && buttons.cancel.disabled) || !!(buttons.confirm && buttons.confirm.loading)"
                     [variant]="(buttons.cancel === 'Cancel' || buttons.cancel === 'cancel' || buttons.cancel.text === 'Cancel' || buttons.cancel.text === 'cancel') ? 'outlined' : buttons.cancel.variant || ''"
                     *ngIf="buttons.cancel && !buttons.cancel.hide"
                     (action)="closeCb.emit();">
                    {{buttons.cancel.text || buttons.cancel}}
                </btn-c>
                <btn-c class="popup-action-btn"
                     [loading]="buttons.confirm.loading"
                     [isDisabled]="!!(buttons.confirm && buttons.confirm.disabled)"
                     *ngIf="buttons.confirm && !buttons.confirm.hide"
                     [color]="buttons.confirm && buttons.confirm.color"
                     (action)="confirmCb.emit();">
                    {{buttons.confirm.text || buttons.confirm}}
                </btn-c>
            </div>
        </div>
    </div>
</div>
