import {Component} from "@angular/core";
import {GeneralService} from "../../../services/generalService";

@Component({
	selector: 'threat-simulation-not-supported',
	template: '<div class="flxClmn bs-enabled f-1-0" style="align-items: center;">' +
		'           <div class="flex-column align-items-center text-center gap-2" style="line-height: 1.6;">' +
		'                <div style="width: 270px; height: 270px; margin-bottom: -10px; margin-top: -30px;">' +
		'          	        <img src="images/feature-not-supported.png" style="width: 270px;"/>' +
		'                </div>' +
		'                <h1 class="m-0" style="font-size: 36px;">Threat Simulation</h1>' +
		'                <br/>' +
		'                <span class="fw-500 mb-2" style="font-size: 18px;">Coming Soon! hurry up and register to the <b>Alpha</b> version</span>' +
		'                <span style="font-size: 18px; max-width: 750px;">Create an email campaign simulating an email-based attack as a threat learning act for your team and manage the results from one space</span>' +
		'                <br/>' +
		'                <a class="blue-link text-decoration-underline" (click)="triggerContactUsForm();" style="font-size: 18px;">Contact our team to enable this feature in your plan</a>' +
		'           </div>' +
		'       </div>'
})
export class ThreatSimulationNotSupportedComponent {

	constructor(private gs:GeneralService) {
	}

	triggerContactUsForm = () => {
		this.gs.triggerContactUsForm();
	}
}

