<button class="simple-btn {{colorClass}}"
     [ngStyle]="{'--btn-special-clr' : specialColor || ''}"
     tooltip="{{btnTooltip}}" placement="{{btnTooltipDirection ? btnTooltipDirection : 'top'}}"
     [ngClass]="{'outlined' : variant === 'outlined',
     			 'outlined-dashed' : variant === 'outlined-dashed',
                 'text' : variant === 'text',
                 'disabled' : isDisabled || (loading && (loading === true || loading.flag))}"
     (click)="fireClick(); action.observed && $event.stopPropagation();">
    <i *ngIf="startIcon && !(loading && (loading === true || loading.flag))" [ngClass]="startIcon"></i>
    <span *ngIf="!(loading && (loading === true || loading.flag))">
        <ng-content></ng-content>
    </span>
    <i *ngIf="endIcon && !(loading && (loading === true || loading.flag))" [ngClass]="endIcon"></i>
    <div class="loading-spinner"
         [ngStyle]="loading && !loading.text && loading.flag && {'position' : 'absolute', 'left' : 'calc(50% - 15px)', 'top' : 'calc(50% - 7.5px)'}"
         *ngIf="(loading && (loading === true || loading.flag)) && !loading.dotAnimation">
    </div>
    <span *ngIf="loading && loading.text && loading.flag" [ngClass]="{'loading-dots' : loading.dotAnimation}">
        {{loading.text}}
    </span>
</button>
