import {GeneralService} from "../../services/generalService";
import {AuthService} from "../../services/authService";
import {DICTIONARY} from "../../dictionary";
import {Component, Input, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LookAndFeelService} from "../../services/lookAndFeelService";

@Component({
	selector: 'login-component',
	templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit  {
	@Input() stateParamsTest;
    constructor(private activatedRoute:ActivatedRoute,
				private router:Router,
				private authService:AuthService,
				private lfs:LookAndFeelService,
				public gs:GeneralService) {
    }
    dic = DICTIONARY;

    loginStatuses = {
        emailOrSSOForm: 'emailOrSSO',
        passwordLoginForm: 'passwordLoginForm',
        signupForm: 'signupForm',
        mfaCodeForm: 'mfaCodeForm'
    }

	htmlRetrieved = false; // indicates that the inner HTML in the ng-include was retrieved from server
    userEmailInput = '';
    password = '';
    plan;
    firstname;
    lastname;
    email;
    corpname = this.gs.corpname;
    //for login and signup read png logo as default
    logo = this.lfs.appDefaultLogo;

    loginStatus;
    show2FaCodeForm;
    error;
    loading;
    data2Fa;
    enableResend;
    successMessage;
    disableConfirmAuth;

	ngOnInit() {
		//native solution: urlQuery = Object.fromEntries(new URLSearchParams(window.location.search)) || {};
		//after reading urlQuery we can delete it: window.history.replaceState({}, document.title, window.location.pathname);
        const urlQuery = this.activatedRoute.snapshot.paramMap;
        if (!urlQuery.has('signup') && !window.history.state.signup) {
            this.loginStatus = this.loginStatuses.emailOrSSOForm;
        }
        this.plan = urlQuery.get('plan') || window.history.state.plan;
        this.firstname = urlQuery.get('firstname') || window.history.state.firstname;
        this.lastname = urlQuery.get('lastname') || window.history.state.lastname;
        this.email = urlQuery.get('email') || window.history.state.email;

        this.error = false;
        this.loading = false;

        if (window.history.state.otoken || urlQuery.has('saml_token')) {
            this.authService.remoteLogin({
                expiresIn: 7200,
                accessToken: window.history.state.otoken || urlQuery.get('saml_token')
            }, err => {
                if (!err) {
					this.router.navigate([this.dic.CONSTANTS.appStates.home]);
                }
            });
        }

        if (window.history.state.error || urlQuery.has('error')) {
            this.error = window.history.state.error || urlQuery.get('error');

            if (this.error === 'invalid_grant' || this.error === 'access_denied' || this.error === 'too_many_attempts') {
                this.error = DICTIONARY.ERRORS.authSignInError;
            }
        }

        this.show2FaCodeForm = window.history.state.show2FaCodeForm;
        if (window.history.state.show2FaCodeForm) {
            this.loginStatus = this.loginStatuses.mfaCodeForm;
            this.data2Fa = window.history.state.data2Fa;
            this.data2Fa.message = {
                text: '',
                type: ''
            };
            if (this.data2Fa.status === this.dic.CONSTANTS.authenticationStatus.blocked) {
                this.data2Fa.message.text = this.data2Fa.blockNote;
                return;
            }
            this.data2Fa.method = this.data2Fa.auth;
            this.data2Fa.emailAuth = this.data2Fa.recipientEmail;
            this.data2Fa.type = this.dic.CONSTANTS.pinType.text;
            this.data2Fa.resendType = this.dic.CONSTANTS.pinType.text;
            this.data2Fa.flagIcon = this.gs.getCountryCodeFlag(this.data2Fa.country_code);
            this.data2Fa.totpCode = '';

            if (!this.data2Fa.phone) {
                this.data2Fa.phone = {
                    country_code: this.data2Fa.country_code,
                    phone_number: ''
                };
            }
            this.data2Fa.phone.phone_number_after = '';
            if (this.data2Fa.phone.phone_number) {
                this.gs.formatPhone(this.data2Fa);
            }

            if (this.data2Fa.status === this.dic.CONSTANTS.authenticationStatus.code) {
                setTimeout(() => {
                    this.enableResend = true;
                }, this.dic.CONSTANTS.RESEND_TIMEOUT);
            }
        }
    };

	handleLoginStatusChange(data) {
		this.error = data.error ? data.error : false;
		this.successMessage = data.successMessage ? data.successMessage : '';
		this.loginStatus = data.loginStatus;
		this.userEmailInput = data.userEmailInput ? data.userEmailInput : '';
	}
}
