import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {LookAndFeelService} from "../../../../services/lookAndFeelService";

@Component({
	selector: 'look-and-feel-component',
	templateUrl: './look-and-feel.component.html',
})
export class LookAndFeelComponent implements OnInit{
    constructor(public gs:GeneralService,
				private lfs:LookAndFeelService,
				private rs:RouteService,
				private ns:NotificationService,) {
    }

    dic = DICTIONARY;
    corpname = this.gs.corpname;
    mySettingsLogoUpdated = false;
    // a flag for form validation
    wasSubmitted = false;
    previewEmailInProgress = true;
	previewEmailContent;
    headlineError = false;
    footerError = false;
    mySettingsLogoTrustifi;
    customHtmlPopup;
    logoFile;
    hexInputError;
    userLogoDiff;
    displayPolicyText;
    addCustomizationInProcess;
    footer_fix;
    headline_fix;
    mySettingsLogoOriginal;
	mySettingsLogo:any = {
		selectedCustomization: {
			name: this.dic.CONSTANTS.lfMainCustomizationName,
			color: ''
		}
	};
	HTMLcodeChanged = false;
	logoSizes = {
		medium: 'medium',
		large: 'large'
	}

	ngOnInit() {
        this.getCustomization();
    }

	colorChangedHandler() {
		this.initChanges(true);
		this.hexInputError = false;
	}

    getCustomization = () => {
        this.headlineError = false;
        this.footerError = false;

        this.rs.getCustomization().then(plan => {

            this.mySettingsLogo = plan.customization;
            if (!this.mySettingsLogo.sub_customizations) {
                this.mySettingsLogo.sub_customizations = [];
            }

            this.mySettingsLogo.sub_customizations.unshift({
                name: this.dic.CONSTANTS.lfMainCustomizationName,
                template_enabled: this.mySettingsLogo.lf.template_enabled,
                show_enc_help: this.mySettingsLogo.lf.show_enc_help,
				template_always_emphasize: this.mySettingsLogo.lf.template_always_emphasize,
				color: this.mySettingsLogo.lf.color,
                logo: this.mySettingsLogo.lf.logo,
				logo_template_size: this.mySettingsLogo.lf.logo_template_size,
                headline: this.mySettingsLogo.lf.headline,
                footer: this.mySettingsLogo.lf.footer
            });

            this.mySettingsLogo.selectedCustomization = this.mySettingsLogo.sub_customizations[0];

            this.mySettingsLogoTrustifi = this.dic.CONSTANTS.trustifiDefault.logo.png;

            if (this.mySettingsLogo.selectedCustomization && this.mySettingsLogo.selectedCustomization.logo) {
                let imageLogo = new Image();

                imageLogo.onload = () => {
                };

                imageLogo.src = "data:image/png;base64," + this.mySettingsLogo.selectedCustomization.logo;
            }
            this.mySettingsLogoOriginal = _.cloneDeep(this.mySettingsLogo);
            this.initChanges(true);
        }, (err) => {

		});
    }

    fixHtmlCode = () => {
        switch (this.customHtmlPopup.scope) {
            case this.dic.CONSTANTS.lfCustomHtmlScopes.header:
                this.customHtmlPopup.customHtmlString = this.headline_fix;
                this.headlineError = false;
                break;
            case this.dic.CONSTANTS.lfCustomHtmlScopes.footer:
                this.customHtmlPopup.customHtmlString = this.footer_fix;
                this.footerError = false;
                break;
        }
    }

    uploadLogo = () => {
        if (!this.logoFile || !this.logoFile.name) {
            return;
        }

        if (this.logoFile.type !== "image/png") {
            this.ns.showWarnMessage(this.dic.ERRORS.logoInvalidType);
            return;
        }

        //200 KB
        if (this.logoFile.size > 200000) {
            this.ns.showWarnMessage(this.dic.ERRORS.logoMaxSize);
            return;
        }


        const reader = new FileReader();
        reader.addEventListener("load", () => {
            if (reader.result) {
				this.mySettingsLogo.selectedCustomization.logo = reader.result.toString().split(',')[1];
				this.initChanges(true);
            }
        }, false);

        reader.readAsDataURL(this.logoFile);
    };

    hexInputChanged = () => {
        // validate hex code and if is valid - refresh preview
        const reg = /^(0[xX])?[A-Fa-f0-9]+$/i;
        if (reg.test(this.mySettingsLogo.selectedCustomization.color.replace("#",'')) && this.mySettingsLogo.selectedCustomization.color.replace("#",'').length === 6) {
            this.initChanges(true);
            this.hexInputError = false;
        }
        else {
            this.hexInputError = true;
        }
    }

    initChanges = (isRefreshPreview = null) => {
        this.wasSubmitted = false;
        this.headlineError = false;
        this.footerError = false;
        this.userLogoDiff = this.gs.equals(this.mySettingsLogo, this.mySettingsLogoOriginal);
        if (isRefreshPreview) {
            this.previewEmail();
        }
    };

    openUpdateLogoDetailsPopup = () => {
        if (this.mySettingsLogo.selectedCustomization.name !== this.dic.CONSTANTS.lfMainCustomizationName) {
            return this.saveSubCustomization(true);
        }

        if (this.mySettingsLogo.lf.enabled && !this.mySettingsLogo.lf.company) {
            this.ns.showWarnMessage(this.dic.ERRORS.missingCompanyName);
            return;
        }

        if (this.mySettingsLogo.lf.enabled !== this.mySettingsLogoOriginal.lf.enabled
            && !this.mySettingsLogo.lf.enabled) {
            let subTitle, body;

            subTitle = 'Custom settings will be reset to default';
            body = [
                'Your users will see the system with default logo and colors.',
                'Some changes will occur immediately and some will occur within 24 hours.'
            ];

            this.gs.showPopup({
                title: 'Custom Settings Update',
                subTitle: subTitle,
                body: body,
                type: this.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Update',
                doneCb: this.updateCustomizationDetails
            });
            return;
        }
        this.updateCustomizationDetails();
    };

    updateCustomizationDetails = () => {
        if (!this.mySettingsLogo.selectedCustomization.color.includes("#")) {
            this.mySettingsLogo.selectedCustomization.color = "#" + this.mySettingsLogo.selectedCustomization.color;
        }

        const updateLogoData = {
            action: this.dic.CONSTANTS.lfUpdateAction.setMainCustomization,
            lf: {
                logo: this.mySettingsLogo.selectedCustomization.logo,
				logo_template_size: this.mySettingsLogo.selectedCustomization.logo_template_size,

                template_enabled: this.mySettingsLogo.lf.template_enabled,
                show_enc_help: this.mySettingsLogo.selectedCustomization.show_enc_help,
				template_always_emphasize: this.mySettingsLogo.selectedCustomization.template_always_emphasize,
				headline: this.mySettingsLogo.selectedCustomization.headline,
                footer: this.mySettingsLogo.selectedCustomization.footer,
                color: this.mySettingsLogo.selectedCustomization.color,

                company: this.mySettingsLogo.lf.company,
                url: this.mySettingsLogo.lf.url,
                enabled: this.mySettingsLogo.lf.enabled,
            }
        };

        this.headlineError = false;
        this.footerError = false;
        this.displayPolicyText = true;
        this.rs.updateCustomizationDetails(updateLogoData).then((response) => {
            this.mySettingsLogo.selectedCustomization.footer = response.footer;
            this.mySettingsLogo.selectedCustomization.headline = response.headline;

            let color = this.dic.CONSTANTS.trustifiDefault.color;
            if (updateLogoData.lf.enabled) {
                color = this.mySettingsLogo.selectedCustomization.color;
            }

            this.mySettingsLogoUpdated = true;
            setTimeout(()  => {
                this.mySettingsLogoUpdated = false;
            }, 3000);
            this.initChanges(true);

			// assign changes to userInfo or to the current controlAdmin info
			if (!this.gs.controlAdmin || this.gs.controlAdmin.email === this.gs.userInfo.email) {
				// update userInfo
				this.gs.getUserInfo(true, (userInfo) => {});
			}
			else {
				if (this.gs.adminInfoMap[this.gs.controlAdmin.email]) { // should always be true (done in gs.getAdminInfo)
					this.gs.adminInfoMap[this.gs.controlAdmin.email].plan.customization.lf = updateLogoData.lf;
				}
			}

			if (updateLogoData.lf.enabled) {
				this.lfs.applyLf(updateLogoData.lf.color, updateLogoData.lf.logo ? "data:image/png;base64," + updateLogoData.lf.logo : '');
			}
			else {
				this.lfs.resetLfToDefault();
			}

            if (this.mySettingsLogo.lf.enabled) {
                let subTitle, bodyDetails;

                subTitle = 'Custom settings have been updated successfully';
                bodyDetails = [
                    'Congratulations! Your users will see the system with your logo and colors.',
                    'Some changes will occur immediately and some will occur within 24 hours.',
                    `To register the sub-domain "${this.mySettingsLogo.lf.company}.trustifi.com" please contact support`
                ];

                this.gs.showPopup({
                    title: 'Custom Settings Update',
                    subTitle: subTitle,
                    bodyDetails: bodyDetails,
                    formAlwaysValid: true,
                    type: this.dic.CONSTANTS.popupInfoConfirming,
                    doneBtnText: 'OK',
                    doneCb: () => {}
                });
            }
            else {
                this.ns.showInfoMessage(this.dic.MESSAGES.logoUpdated);
            }
            this.mySettingsLogoOriginal = _.cloneDeep(this.mySettingsLogo);
        }, err => {
            if (err.data) {
                if (err.data.header && err.data.newhtml) {
                    this.headlineError = true;
                    this.headline_fix = err.data.newhtml;
                }

                if (err.data.footer && err.data.newhtml) {
                    this.footerError = true;
                    this.footer_fix = err.data.newhtml;
                }
            }
        });
    };

    openDeleteLogoPopup = () => {
        const body = [
            'Your look and feel data(logo, color and name) will be deleted.',
        ];

        this.gs.showPopup({
            title: 'Reset Settings To Default',
            subTitle: 'Look and feel settings will be reset to default',
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Reset',
            doneCb: this.deleteLogo
        });
    };

    deleteLogo = () => {
        this.headlineError = false;
        this.footerError = false;
        this.rs.resetCustomization().then(() => {
			if (!this.gs.controlAdmin || this.gs.controlAdmin.email === this.gs.userInfo.email) {
				// update userInfo
				this.gs.getUserInfo(true, (userInfo) => {});
			}
			else {
				if (this.gs.adminInfoMap && this.gs.adminInfoMap[this.gs.controlAdmin.email]) {
					this.gs.adminInfoMap[this.gs.controlAdmin.email].plan.customization.lf = null;
				}
			}

			this.lfs.resetLfToDefault();

            this.getCustomization();
            this.ns.showInfoMessage(this.dic.MESSAGES.resetCustomSettings);
        });
    };

    applyCustomHtmlPopup = () => {
        switch (this.customHtmlPopup.scope) {
            case this.dic.CONSTANTS.lfCustomHtmlScopes.header:
                this.mySettingsLogo.selectedCustomization.headline = this.customHtmlPopup.customHtmlString;
                break;
            case this.dic.CONSTANTS.lfCustomHtmlScopes.footer:
                this.mySettingsLogo.selectedCustomization.footer = this.customHtmlPopup.customHtmlString;
                break;
        }

        this.customHtmlPopup = null;
        this.initChanges(true)
    }

    openCustomHtmlPopup = (popupScope) => { // popupScope : header / footer
        this.customHtmlPopup = {
            scope: popupScope,
            customHtmlString: popupScope === this.dic.CONSTANTS.lfCustomHtmlScopes.header ? this.mySettingsLogo.selectedCustomization.headline : this.mySettingsLogo.selectedCustomization.footer,
            show: true
        };
    }

	updateEmailFrame = (html) => {
        let lfColorForPreview = this.mySettingsLogo.color;
        let hoverColorForPreview = this.lfs.hoverColor;

        if (((this.mySettingsLogo.selectedCustomization.name === this.dic.CONSTANTS.lfMainCustomizationName && this.mySettingsLogo.lf.template_enabled) || this.mySettingsLogo.selectedCustomization.name !== this.dic.CONSTANTS.lfMainCustomizationName)
            && this.mySettingsLogo.selectedCustomization.color) {
            lfColorForPreview = this.mySettingsLogo.selectedCustomization.color;
            const selectedColorInHsl = this.lfs.convertHexToHSLValues(lfColorForPreview);
            // identical to the lightness calculation in generalService
            hoverColorForPreview = "hsl(" + selectedColorInHsl[0] + "," + selectedColorInHsl[1] + "%," + (selectedColorInHsl[2] + 15) + "%)";
        }

        let css = `::-webkit-scrollbar {
                      width: 10px;
                      height: 10px;
                    }
                    ::-webkit-scrollbar-button {
                        width: 0px;
                        height: 0px;
                    }
                    ::-webkit-scrollbar-thumb {
                        background: ${lfColorForPreview || '#486db5'};
                        border: 1px solid rgba(0, 0, 0, 0.5);
                        border-radius: 50px;
                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background: ${hoverColorForPreview};
                    }
                    ::-webkit-scrollbar-thumb:active {
                        background: ${lfColorForPreview || '#486db5'};
                    }
                    ::-webkit-scrollbar-track {
                        background: #ffffff;
                        border: 1px solid rgba(0,0,0,0.15);
                        border-radius: 50px;
                    }
                    ::-webkit-scrollbar-track:hover {
                        background: #ffffff;
                    }
                    ::-webkit-scrollbar-track:active {
                        background: #ffffff;
                    }
                    ::-webkit-scrollbar-corner {
                        background: transparent;
                    }
                    #trustifiMain {
                        width: 100% !important;
                    }`;

        this.previewEmailContent = {html, css};
    };

    previewEmail = () => {

        const updateLogoData = {
            headline: this.mySettingsLogo.selectedCustomization.headline,
            footer: this.mySettingsLogo.selectedCustomization.footer,
            color: this.mySettingsLogo.selectedCustomization.color || this.lfs.appDefaultColor,
            show_enc_help: this.mySettingsLogo.selectedCustomization.show_enc_help,
			template_always_emphasize: this.mySettingsLogo.selectedCustomization.template_always_emphasize,
			logo: this.mySettingsLogo.selectedCustomization.logo,
			logo_template_size: this.mySettingsLogo.selectedCustomization.logo_template_size,
            company: this.mySettingsLogo.selectedCustomization.company,
            template_enabled: this.mySettingsLogo.lf.template_enabled || this.mySettingsLogo.selectedCustomization.name !== this.dic.CONSTANTS.lfMainCustomizationName
        };

        let message = {
            recipients_display_only: {to: [], cc: []},
            attachments: [],
            methods: {
                secureSend: true,
                encryptContent: true
            },
            logo: updateLogoData
        };

        this.previewEmailInProgress = true;

        this.rs.previewEmail(message).then((response) => {
            this.updateEmailFrame(response.html);
			this.previewEmailInProgress = false;
        }, (err) => {
            this.updateEmailFrame('');
			this.previewEmailInProgress = false;
        });
    };

    selectSubCustomization = (subCustomization) => {
        this.addCustomizationInProcess = false;
        this.mySettingsLogo.selectedCustomization = subCustomization;
        this.userLogoDiff = true;
        this.initChanges(true);
    };

    addSubCustomization = () => {
        this.addCustomizationInProcess = true;
        this.mySettingsLogo.selectedCustomization = {
            name: '',
            color: this.mySettingsLogo.sub_customizations[0].color,
            headline: this.mySettingsLogo.sub_customizations[0].headline,
            footer: this.mySettingsLogo.sub_customizations[0].footer,
            show_enc_help: true,
			logo_template_size: this.logoSizes.medium,
			template_always_emphasize: false
        };
    };

    saveSubCustomizationDisabled = () => {
        return !this.mySettingsLogo.selectedCustomization ||
            !this.mySettingsLogo.selectedCustomization.name ||
            !this.mySettingsLogo.selectedCustomization.color;
    };

    cancelSubCustomization = () => {
        this.addCustomizationInProcess = false;
        this.mySettingsLogo.selectedCustomization = this.mySettingsLogo.sub_customizations[0];
        this.userLogoDiff = true;
        this.initChanges(true);
    };

    saveSubCustomization = (isUpdate) => {
        if (!isUpdate && this.mySettingsLogo.sub_customizations.length > 1 &&
            _.find<any>(this.mySettingsLogo.sub_customizations, c => c.name === this.mySettingsLogo.selectedCustomization.name)) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.customizationAlreadyExist, this.mySettingsLogo.selectedCustomization.name));
        }

        if (!this.mySettingsLogo.selectedCustomization.color.includes("#")) {
            this.mySettingsLogo.selectedCustomization.color = "#" + this.mySettingsLogo.selectedCustomization.color;
        }

        let updateLogoData = {
            action: this.dic.CONSTANTS.lfUpdateAction.setSubCustomization,
            template: this.mySettingsLogo.selectedCustomization,
            isUpdate: isUpdate
        };

        this.headlineError = false;
        this.footerError = false;
        this.rs.updateCustomizationDetails(updateLogoData).then(() => {
            if (isUpdate) {
                this.ns.showInfoMessage(this.dic.MESSAGES.customizationUpdated);
            }
            else {
                this.mySettingsLogo.sub_customizations.push(updateLogoData.template);
                this.ns.showInfoMessage(this.dic.MESSAGES.customizationCreated);
            }

            this.addCustomizationInProcess = false;
        }, (err) => {
            if (err.data) {
                if (err.data.header && err.data.newhtml) {
                    this.headlineError = true;
                    this.headline_fix = err.data.newhtml;
                }

                if (err.data.footer && err.data.newhtml) {
                    this.footerError = true;
                    this.footer_fix = err.data.newhtml;
                }
            }
        });
    };

    deleteSubCustomization = () => {
        this.gs.showPopup({
            title: 'Delete Customization',
            subTitle: `You are about to delete customization ${this.mySettingsLogo.selectedCustomization.name}`,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                const updateLogoData = {
                    action: this.dic.CONSTANTS.lfUpdateAction.deleteSubCustomization,
                    name: this.mySettingsLogo.selectedCustomization.name
                };

                this.rs.updateCustomizationDetails(updateLogoData).then(() => {
                    const index = _.findIndex<any>(this.mySettingsLogo.sub_customizations, c => c.name === this.mySettingsLogo.selectedCustomization.name);
                    this.mySettingsLogo.sub_customizations.splice(index, 1);
                    this.mySettingsLogo.selectedCustomization = this.mySettingsLogo.sub_customizations[0];
                    this.userLogoDiff = true;
                    this.initChanges(true);
                    this.ns.showInfoMessage(this.dic.MESSAGES.customizationDeleted);
                });
            }
        });
    };
}

