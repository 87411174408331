import {RouteService} from '../../../../services/routeService';
import {GeneralService} from '../../../../services/generalService';
import {NotificationService} from '../../../../services/notificationService';
import {DICTIONARY} from '../../../../dictionary';
import html2pdf from 'html2pdf.js';
import _ from "lodash";
import util from "util";
import {Component, NgZone, OnInit} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {LookAndFeelService} from "../../../../services/lookAndFeelService";

@Component({
	selector: 'dmarc-reports-component',
	templateUrl: './dmarc-reports.component.html',
})
export class DmarcReportsComponent implements OnInit {
    dic = DICTIONARY;

    dmarcSettings;
    generatePdfInProcess = false;
    period = this.dic.CONSTANTS.trendsPeriod.last3Days;
    showTrendsCustomPeriod;
    range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)), end: new Date()};
	todayDate = new Date();
    trendsData: any;
    dmarcEmailVolumeGraphOptions: any;
    compliantBySenderGraphOptions: any;
	nonCompliantBySenderGraphOptions: any;
	topAttackedDomainsGraphOptions: any;
    allSenders: any;
	configuredDomains = [];
	selectedDomain;
	addNewDomainPopup;
	dmarcDnsPopup;
	filterData;
	filterDataRecords;
	dmarcSourceRecordsPopup;
	trustifiDmarcRuaDNS = this.gs.isProductionEnv() ? 'mailto:rua@dmarc.trustifi.com' : 'mailto:rua@dmarcstage.trustifi.com';

	doDmarcActionInProcess = false;
    getTrendsDataInProcess = false;
	validateDmarcInProcess = false;

    dashboardPeriod = [
        this.dic.CONSTANTS.trendsPeriod.lastDay,
        this.dic.CONSTANTS.trendsPeriod.last3Days,
        this.dic.CONSTANTS.trendsPeriod.lastWeek,
        this.dic.CONSTANTS.trendsPeriod.lastMonth,
        this.dic.CONSTANTS.trendsPeriod.range
    ];

	analysisColorsDic = {
		unverified: 'darkred',
		forward: 'darkblue',
		authentic: 'darkgreen',
		unknown: 'dimgray'
	};

	dispositionColorsDic = {
		none: 'darkblue',
		reject: 'darkred',
		quarantine: 'darkorange'
	};

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private ngZone:NgZone,
				public lfs:LookAndFeelService,
				private clipboard: ClipboardService) {
    }

	ngOnInit() {
		this.initFilters();
		this.initFiltersRecords();

		this.getTrendsDataInProcess = true;

		this.rs.getDmarcSettings().then((response) => {
            this.dmarcSettings = response;

			this.configuredDomains = _.map(this.dmarcSettings.dmarc_reports.domains, domain => {return {domain}});
			this.selectedDomain = this.dmarcSettings.dmarc_reports.domains[0] || null;

			this.getTrendsData();

        }, () => {
			this.getTrendsDataInProcess = false;
		});
    }

	initFilters = () => {
		this.filterData = {
			filterType: this.dic.CONSTANTS.tableFilters.dmarcAnalyzer,
			filters: {
				analysis: ['authentic', 'unverified', 'forward', 'unknown'],
			},
			initFiltersFunction: this.initFilters,
		};
	};
	initFiltersRecords = () => {
		this.filterDataRecords = {
			filterType: this.dic.CONSTANTS.tableFilters.dmarcRecords,
			filters: {
				analysis: ['authentic', 'unverified', 'forward'],
				disposition: ['delivery','quarantine'],
				'DMARC Results': ['pass', 'fail',],
				'SPF Results': ['pass', 'fail', 'softfail', 'temperror'],
				'DKIM Results': ['pass', 'fail'],
			},
			initFiltersFunction: this.initFiltersRecords,
		};
	};

    changePeriod = (inputPeriod) => {
        this.period = inputPeriod;
		this.getTrendsData();
    };

	changeSelectedDomain(domainObj) {
		if (this.selectedDomain === domainObj.domain) {
			return;
		}

		this.selectedDomain = domainObj.domain;
		this.getTrendsData();
	}

	openAddNewDomainPopup() {
		if (!this.dmarcSettings.allowedDomains.length) {
			return this.ns.showErrorMessage(this.dic.ERRORS.noDomainsForDmarc);
		}
		this.addNewDomainPopup = {
			allowedDomains: _.map(this.dmarcSettings.allowedDomains, domain => {return {domain}}),
			selectedDomain: '',
			show: true,
		};
	}

	addNewDomain() {
		if (this.doDmarcActionInProcess) {
			return;
		}

		if (!this.addNewDomainPopup.selectedDomain) {
			this.ns.showErrorMessage(util.format(this.dic.ERRORS.noSelected, 'domain'));
			return;
		}

		const isAlreadyConfigured = !!_.find(this.configuredDomains, domainObj => {return domainObj.domain === this.addNewDomainPopup.selectedDomain.domain});
		if (isAlreadyConfigured) {
			this.ns.showErrorMessage(this.dic.ERRORS.domainIsAlreadyConfigured);
			return;
		}

		const data = {
			action: this.dic.CONSTANTS.actions.add
		}

		this.doDmarcActionInProcess = true;

		this.rs.doDmarcAction(this.addNewDomainPopup.selectedDomain.domain, data).then((response) => {
			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Domain'));

			this.configuredDomains.push({domain: this.addNewDomainPopup.selectedDomain.domain});
			this.selectedDomain = this.addNewDomainPopup.selectedDomain.domain;
			this.getTrendsData();

			this.doDmarcActionInProcess = false;
			this.addNewDomainPopup = null;
		}, (err) => {
			this.doDmarcActionInProcess = false;
		});
	}

	removeDomain(domain) {
		if (this.doDmarcActionInProcess) {
			return;
		}
		this.gs.showPopup({
			title: 'Remove Domain',
			subTitle: `Click "Remove" below to remove ${domain} from DMARC reports.`,
			body: [`To stop receiving these reports, you will also need to remove the following string from your DMARC DNS record: <b>${this.trustifiDmarcRuaDNS}</b>`],
			type: DICTIONARY.CONSTANTS.popupWarning,
			doneBtnText: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove,
			doneCb: () => {
				const data = {
					action: this.dic.CONSTANTS.actions.delete
				}

				this.doDmarcActionInProcess = true;

				this.rs.doDmarcAction(domain, data).then((response) => {
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemRemoved, 'Domain'));
					_.remove(this.configuredDomains, domainObj => domainObj.domain === domain);
					this.trendsData = null;
					this.selectedDomain = null;
					this.doDmarcActionInProcess = false;
				}, (err) => {
					this.doDmarcActionInProcess = false;
				});
			}
		});
	}

	isDmarcDnsReportValidated(domain, showPopup=true) {
		if (this.validateDmarcInProcess) {
			return;
		}

		this.validateDmarcInProcess = true;
		this.rs.doDmarcAction(domain, {action: 'validateDmarcDNS'}).then((response) => {
			this.dmarcDnsPopup = {
				errMessage: response.connected ? '' : 'The Trustifi email is missing from the "rua" tag in your DMARC record',
				record: response.record,
				connected: response.connected,
				trustifiDmarc: response.connected ? response.record : this.getDmarcDnsValue(response.record),
				show: showPopup
			};
			this.validateDmarcInProcess = false;
		}, (err) => {
			if (err && err.data && err.data.message && !err.data.display_bar) {
				this.dmarcDnsPopup = {
					errMessage: err.data.message,
					record: err.data.record || null,
					connected: err.data.connected || false,
					trustifiDmarc: err.data.connected ? err.data.record : this.getDmarcDnsValue(err.data.record || null),
					show: showPopup
				};
			}
			this.validateDmarcInProcess = false;
		});
	}

	copyToClipboard = (copyTxt) => {
		this.clipboard.copy(copyTxt);
	}

	getDmarcDnsValue = (dmarcRecord) => {
		if (!dmarcRecord) {
			return `v=DMARC1; p=none; pct=100; rua=${this.trustifiDmarcRuaDNS};`;
		}

		// Remove spaces before and after the equals sign
		dmarcRecord = dmarcRecord.replace(/\s*=\s*/g, '=');

		// Remove spaces after mailto:
		dmarcRecord = dmarcRecord.replace(/mailto:\s*/gi, 'mailto:');

		// Remove double spaces
		dmarcRecord = dmarcRecord.replace(/\s\s+/g, ' ');

		const trustifiConnected = /[=,]mailto[:]rua[@]dmarc(stage)?[.]trustifi[.]com[,;]/i.test(dmarcRecord);
		if (!trustifiConnected) {
			const existingRUAConfig = ((dmarcRecord.match(/[\s;]+rua[=]([^;]+)[;]/i) || [])[1] || '').toLowerCase();
			if (existingRUAConfig) {
				dmarcRecord = dmarcRecord.replace(existingRUAConfig, `${existingRUAConfig},${this.trustifiDmarcRuaDNS}`);
			}
			else {
				if (!dmarcRecord.endsWith(';')) {
					dmarcRecord += ';';
				}
				dmarcRecord += ` rua=${this.trustifiDmarcRuaDNS};`;
			}
		}

		return dmarcRecord;
	}

    getTrendsData = () => {
		if (!this.selectedDomain) {
			this.getTrendsDataInProcess = false;
			return;
		}

		const params:any = {
			period: this.period.value
		};

		if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
			params.range = {
				start: this.range.start,
				end:this.range.end
			};

			if (params.range.start > Date.now() || params.range.start > params.range.end) {
				this.ns.showWarnMessage(this.dic.ERRORS.adminTrendsDate);
				return;
			}
			this.showTrendsCustomPeriod = false;
		}

        this.getTrendsDataInProcess = true;
        this.trendsData = null;
        this.rs.getDmarcReport(this.selectedDomain, params).then((response) => {
            this.trendsData = response;

            this.prepareDmarcEmailVolumeGraph();
            this.prepareCompliantBySenderGraph();
			this.prepareTopAttackedDomainsGraph();
			this.allSenders = this.trendsData.senders;

			if (!this.trendsData.total) {
				this.isDmarcDnsReportValidated(this.selectedDomain, false);
			}

            this.getTrendsDataInProcess = false;
        });
    };

	getDmarcRecordsBySenderForDomain = (senderName) => {
		if (!senderName) {
			return;
		}

		let range = null;
		if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
			range = {
				start: this.range.start,
				end: this.range.end
			};

			if (range.start > Date.now() || range.start > range.end) {
				this.ns.showWarnMessage(this.dic.ERRORS.adminTrendsDate);
				return;
			}
		}

		const params = {
			period: this.period.value,
			range: range
		};

		this.dmarcSourceRecordsPopup = {
			senderName: senderName,
			inProcess: true,
			show: true
		};

		this.gs.cleanEmptyParams(params);

		this.rs.getDmarcRecordsBySenderForDomain(this.selectedDomain, senderName, params).then((response) => {
			this.dmarcSourceRecordsPopup.records = response;
			this.dmarcSourceRecordsPopup.inProcess = false;
		}, (err) => {
			this.dmarcSourceRecordsPopup.inProcess = false;
		});
	};

	prepareTopAttackedDomainsGraph = () => {
		const topAttackedDomains = {series: [], labels: []};

		// Convert the object to an array of key-value pairs
		const keyValueArray = Object.entries(this.trendsData.topAttackedDomains);
		// Sort the array based on values (ascending order)
		keyValueArray.sort((a: any, b: any) => b[1] - a[1]);
		// Create a new object from the sorted array
		const sortedObject = Object.fromEntries(keyValueArray);
		for (const [key, value] of Object.entries(sortedObject)) {
			topAttackedDomains.labels.push(key);
			topAttackedDomains.series.push(value);
		}

		this.topAttackedDomainsGraphOptions = {
			series: topAttackedDomains.series,
			chart: {
				type: 'donut',
				height: '100%'
			},
			plotOptions: {
				pie: {
					donut: {
						size: '52%',
					}
				}
			},
			dataLabels: {
				enabled: true,
				formatter: (val, opt) => opt.w.globals.series[opt.seriesIndex]
			},
			labels: topAttackedDomains.labels,
			tooltip: {
				y: {formatter: (val) => val + ' records'}
			}
		};
	}

	prepareCompliantBySenderGraph = () => {
		const compliantSenders = {series: [], labels: []};
		const noncompliantSenders = {series: [], labels: []};

		const totalOther = {
			name: 'Other',
			compliant: 0,
			nonCompliant: 0
		};
		this.trendsData.senders.sort((a, b) => b.count - a.count);
		for (let i = 0; i < this.trendsData.senders.length; i++) {
			const senderObj = this.trendsData.senders[i];
			const compliant = Math.floor((senderObj.dmarcPass / 100) * senderObj.count);
			const nonCompliant = senderObj.count - compliant;

			if (i < 9) {
				if (compliant && !compliantSenders.labels.includes(senderObj.name)) {
					compliantSenders.labels.push(senderObj.name);
					compliantSenders.series.push(compliant);
				}
				if (nonCompliant && !noncompliantSenders.labels.includes(senderObj.name)) {
					noncompliantSenders.labels.push(senderObj.name);
					noncompliantSenders.series.push(nonCompliant);
				}
			}
			else {
				totalOther.compliant += compliant;
				totalOther.nonCompliant += nonCompliant;
			}
		}

		if (totalOther.compliant) {
			compliantSenders.labels.push(totalOther.name);
			compliantSenders.series.push(totalOther.compliant);
		}
		if (totalOther.nonCompliant) {
			noncompliantSenders.labels.push(totalOther.name);
			noncompliantSenders.series.push(totalOther.nonCompliant);
		}

        this.compliantBySenderGraphOptions = {
            series: compliantSenders.series,
            chart: {
                type: 'donut',
                height: '100%'
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '52%',
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val, opt) => opt.w.globals.series[opt.seriesIndex]
            },
            labels: compliantSenders.labels,
            tooltip: {
                y: {formatter: (val) => val + ' records'}
            }
        };

		this.nonCompliantBySenderGraphOptions = {
			series: noncompliantSenders.series,
			chart: {
				type: 'donut',
				height: '100%'
			},
			plotOptions: {
				pie: {
					donut: {
						size: '52%',
					}
				}
			},
			dataLabels: {
				enabled: true,
				formatter: (val, opt) => opt.w.globals.series[opt.seriesIndex]
			},
			labels: noncompliantSenders.labels,
			tooltip: {
				y: {formatter: (val) => val + ' records'}
			}
		};
    }


    prepareDmarcEmailVolumeGraph = () => {
        this.dmarcEmailVolumeGraphOptions = {
            series: this.trendsData.compliance.series,
            chart: {
                height: '100%',
                type: this.trendsData.compliance.categories.length === 1 ? "bar" : "area",
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "straight"
            },
            colors: ['#00CC66', '#FF9900'],

            xaxis: {
				tickAmount: 4, // Set the maximum number of ticks (labels) to be displayed on the x-axis
				categories: this.trendsData.compliance.categories,
            },
            tooltip: {
                x: {
                    format: "dd/MM/yy"
                }
            }
        };
    }

	searchSenders = (searchTerm, activeFilters) => {
		this.allSenders.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			if (activeFilters) {
				// need to match all filter types
				let numFilterToMatch = Object.keys(activeFilters).length;

				if (activeFilters.analysis && activeFilters.analysis.length) {
					if (activeFilters.analysis.includes(record.analysis)) {
						numFilterToMatch--;
					}
				}

				if (numFilterToMatch) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	searchSenderRecords = (searchTerm, activeFilters) => {
		this.dmarcSourceRecordsPopup.records.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			if (activeFilters) {
				// need to match all filter types
				let numFilterToMatch = Object.keys(activeFilters).length;

				if (activeFilters.analysis && activeFilters.analysis.length) {
					if (activeFilters.analysis.includes(record.analysis)) {
						numFilterToMatch--;
					}
				}
				if (activeFilters.disposition && activeFilters.disposition.length) {
					if (activeFilters.disposition.includes(record.policy_evaluated.disposition === 'none' ? 'delivery' : record.policy_evaluated.disposition)) {
						numFilterToMatch--;
					}
				}
				if (activeFilters['DMARC Results'] && activeFilters['DMARC Results'].length) {
					if (activeFilters['DMARC Results'].includes(record.dmarcPass ? 'pass' : 'fail')) {
						numFilterToMatch--;
					}
				}
				if (activeFilters['SPF Results'] && activeFilters['SPF Results'].length) {
					if (activeFilters['SPF Results'].includes(record.spfPass)) {
						numFilterToMatch--;
					}
				}
				if (activeFilters['DKIM Results'] && activeFilters['DKIM Results'].length) {
					if (activeFilters['DKIM Results'].includes(record.dkimPass)) {
						numFilterToMatch--;
					}
				}

				if (numFilterToMatch) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	exportToCsv = (sortBy) => {
		if (!this.allSenders || !this.allSenders.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Sender,Volume,Analysis,Delivery Rate,DMARC,SPF,DKIM,IP Count\n";

		let sortedTable = _.filter(this.allSenders,senderObj => {return !senderObj.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((senderObj) => {
			csvString += `"${senderObj.name}",${senderObj.count},${senderObj.analysis},${senderObj.deliveryRate}%,${senderObj.dmarcPass}%,${senderObj.spfPass}%,${senderObj.dkimPass}%,${senderObj.ipCount}\n`;
		});

		this.gs.exportCsv(csvString, `senders.csv`);
	}

	exportSenderRecordsToCsv = (sortBy) => {
		if (!this.dmarcSourceRecordsPopup.records || !this.dmarcSourceRecordsPopup.records.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Report Created,Sender IP,Sender Domains,Volume,Analysis,Disposition,Reporter,Recipient Domain,DMARC Result,SPF Result,DKIM Result\n";

		let sortedTable = _.filter(this.dmarcSourceRecordsPopup.records,senderObj => {return !senderObj.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((senderObj) => {
			const disposition = senderObj.policy_evaluated.disposition === 'none' ? 'Delivery' : senderObj.policy_evaluated.disposition || 'Unknown';
			csvString += `"${senderObj.created}","${senderObj.sender_ip}","${senderObj.sender_header_from},${senderObj.sender_header_from}",${senderObj.count},${senderObj.analysis},${disposition},"${senderObj.reporter_org_name}","${senderObj.recipient_domain || ''}",${senderObj.dmarcPass ? 'Pass' : 'Fail'},${senderObj.spfPass},${senderObj.dkimPass}\n`;
		});

		this.gs.exportCsv(csvString, `sender_records.csv`);
	}


    exportChartsToPdf = () => {
        if (!this.trendsData) {
            return;
        }
        this.generatePdfInProcess = true;

        // set the relevant elements' visual properties to be ideal for the PDF page before copying the HTML
        let areaForPdf:any = document.getElementById('trendsChartsContainer');
        areaForPdf.classList.add('trends-pdf-layout');

        // timeout is needed to let the css enough time to update on screen and for the responsive charts to resize
        setTimeout(() => {
            const element = document.getElementById('trendsChartsContainer');
            const opt = {
                margin:       40,
                filename:     'dmarc_report.pdf',
                image:        { type: 'jpeg', quality: 1 },
                html2canvas:  { scale: 2 , width: 1300, windowWidth: 1550},
                jsPDF:        { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling']}
            };

            // New Promise-based usage:
            html2pdf().set(opt).from(element).then(() => {
                setTimeout(() => {
                    this.exitFromPdfGeneration();
                })
            }).save();
        },800);
    }

	exitFromPdfGeneration = () => {
		this.ns.showInfoMessage(util.format(this.dic.MESSAGES.downloadSuccess, 'DMARC report'));

		document.getElementById('trendsChartsContainer').classList.remove('trends-pdf-layout');
		this.generatePdfInProcess = false;
		// rerender charts so they'll fit their container's size
		this.getTrendsDataInProcess = true;

		setTimeout(() => {
			this.ngZone.run(() => {
				this.getTrendsDataInProcess = false;
			});
		});
	}
}

function searchTextExecute(senderObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((senderObj.domain && senderObj.domain.toLowerCase().indexOf(searchTerm) > -1) ||
		(senderObj.name && senderObj.name.toLowerCase().indexOf(searchTerm) > -1) ||
		(senderObj.sender_ip && senderObj.sender_ip.toLowerCase().indexOf(searchTerm) > -1) ||
		(senderObj.recipient_domain && senderObj.recipient_domain.toLowerCase().indexOf(searchTerm) > -1) ||
		(senderObj.reporter_org_name && senderObj.reporter_org_name.toLowerCase().indexOf(searchTerm) > -1));
}
