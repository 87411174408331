import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import util from "util";
import {Component, OnInit, ViewChild} from "@angular/core";
import {ArchiveService} from "../../../services/archiveService";
import {Router} from "@angular/router";
import {ArchiveCasesOperationsComponent} from "../archiveCasesOperations/archive-cases-operations.component";

@Component({
	selector: 'archive-cases-component',
	templateUrl: './archive-cases.component.html',
})
export class ArchiveCasesComponent implements OnInit {

	@ViewChild('caseOperationsEl') caseOperationsEl: ArchiveCasesOperationsComponent;

	constructor (private router:Router,
				 private rs:RouteService,
				 private ns:NotificationService,
				 public gs:GeneralService,
				 public archiveService:ArchiveService) {
    }

    dic = DICTIONARY;
    loadingCasesInProcess = true;
    cases;
    defaultRange = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()}; // (past one day);
    createEditCasePopup;
    originalCaseBeforeEdit;
    actionInProcess;
    searchCaseTxt;
    workingOnIt;
	showNewCaseAdvanced;
	_=_;

	compliances: any = [{name: "GDPR"}, {name: "FERPA"}, {name: "HIPAA"}, {name: "PCI"},
		{name: "CCPA"}, {name: "POPI"}, {name: "LGPD"}, {name: "PDPO"}, {name: "GLBA"}];

	casesPeriods = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.range,
		this.dic.CONSTANTS.trendsPeriod.all
	];

	ngOnInit() {
        this.getArchiveCases();
    };

    getArchiveCases = () => {
        this.cases = [];
        this.loadingCasesInProcess = true;
        this.rs.getArchiveCases().then(response => {
            this.cases = response;
            this.cases.forEach((caseObj) => {
                caseObj.sharing.is_sharing_expired = this.isSharingExpired(caseObj);
            });

            this.loadingCasesInProcess = false;
        }, (err) => {
            this.loadingCasesInProcess = false;
        });
    };

    showCaseActions = (incidentObj) => {
        return Object.values(this.dic.CONSTANTS.archiveCaseActions);
    }

    selectCaseAction = (caseObj, action) => {
        switch (action) {
            case this.dic.CONSTANTS.archiveCaseActions.run:
                this.runCase(caseObj.name);
                break;

            case this.dic.CONSTANTS.archiveCaseActions.manageSharing:
                this.showSharingManagement(caseObj);
                break;

            case this.dic.CONSTANTS.archiveCaseActions.showLogs:
                this.showLogsForCase(caseObj);
                break;

            case this.dic.CONSTANTS.archiveCaseActions.edit:
                this.openEditCasePopup(caseObj);
                break;

            case this.dic.CONSTANTS.archiveCaseActions.delete:
                this.showDeleteCasePopup([caseObj]);
                break;

            default:
                this.workingOnIt();
        }
    }

    showLogsForCase = (caseObj) => {
        this.archiveService.setCurrentCase(caseObj);
		this.caseOperationsEl.showLogsForCase(this.archiveService.currentCaseObj, null)
    }

    openNewCasePopup = () => {
        const currentNewOrEditCase = {
            query: {
				hasAttachments: false,
                period: this.dic.CONSTANTS.trendsPeriod.lastDay.value,
                range: _.cloneDeep(this.defaultRange)
            }
        };

		this.compliances.forEach((comp) => {
			comp.selected = false;
		});

        // show popup in 'new case' mode
        this.createEditCasePopup = {
            currentCase: currentNewOrEditCase,
            isEditCase: false,
            show: true
        };
    }

    openEditCasePopup = (caseToEdit) => {
        this.originalCaseBeforeEdit = caseToEdit; // to compare changes after edit only
        const currentNewOrEditCase = _.cloneDeep(caseToEdit); // the new model to manipulate

		currentNewOrEditCase.query['range'] = {
			start: currentNewOrEditCase.query.range?.start || this.defaultRange.start,
			end: currentNewOrEditCase.query.range?.end || this.defaultRange.end
		};

		currentNewOrEditCase.query['hasAttachments'] = currentNewOrEditCase.query.hasAttachments || false;

		this.compliances.forEach((comp) => {
			comp.selected = false;
		});

		if (currentNewOrEditCase.query.compliance?.length) {
			this.compliances.forEach((comp) => {
				if (currentNewOrEditCase.query.compliance.includes(comp.name)) {
					comp.selected = true;
				}
			});
		}

		// show popup in 'edit case' mode
        this.createEditCasePopup = {
            currentCase: currentNewOrEditCase,
            isEditCase: true,
            show: true
        };
    }

    applyCreateEditCasePopup = () => {
        if (this.createEditCasePopup.isEditCase) {
            this.editCase(this.createEditCasePopup.currentCase);
        }
        else {
            if (!this.createEditCasePopup.currentCase.name) {
                this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'Case name field'))
                return;
            }

            this.createNewCase();
        }
    }

    editCase = (caseObj) => {
        if (this.actionInProcess) {
            return;
        }

        this.actionInProcess = true;

        const newValues = {
            action: 'query',
            name: caseObj.name,
            query: caseObj.query
        };
		newValues.query.compliance = [];
		this.compliances.forEach((comp) => {
			if (comp.selected) {
				newValues.query.compliance.push(comp.name);
			}
		});

        this.rs.updateArchiveCase(caseObj._id, newValues).then(res => {
            this.createEditCasePopup = null;
            const existingCaseObj = _.find<any>(this.cases, {_id: caseObj._id});
            if (existingCaseObj) {
                existingCaseObj.name = caseObj.name;
                existingCaseObj.query = caseObj.query;
            }

            this.actionInProcess = false;
            this.ns.showInfoMessage("Case was updated successfully");
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.actionInProcess = false;
        });
    }

    createNewCase = () => {
        if (this.actionInProcess) {
            return;
        }

        this.actionInProcess = true;

        const newValues = {
            name: this.createEditCasePopup.currentCase.name,
            query: this.createEditCasePopup.currentCase.query
        };
		newValues.query.compliance = [];
		this.compliances.forEach((comp) => {
			if (comp.selected) {
				newValues.query.compliance.push(comp.name);
			}
		});
;
        this.rs.addArchiveCase(newValues).then(response => {
            this.createEditCasePopup = null;

            this.cases.push(response.case);
            this.searchCaseTxt = _.clone(this.searchCaseTxt); // fire a search
            this.actionInProcess = false;
            this.ns.showInfoMessage("Case was created successfully");
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.actionInProcess = false;
        });
    }

    showDeleteCasePopup = (cases) => {
        this.gs.showPopup({
            title: cases.length > 1 ? `Delete ${cases.length} Cases` : 'Delete Case',
            subTitle: cases.length > 1 ? `Are you sure you want to delete ${cases.length} cases`:  `Are you sure you want to delete ${cases[0].name}?`,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: "Delete",
            doneCb: () => {
				cases.forEach((caseObj) => {
					this.rs.deleteArchiveCase(caseObj._id.toString()).then(res => {
						_.remove<any>(this.cases, caseItem => caseItem._id.toString() === caseObj._id.toString());
						this.ns.showInfoMessage("Case was successfully deleted");
					});
				});
            }
        });
    }

	showCaseBulkActions = () => {
		const actions = [
			this.dic.CONSTANTS.archiveCaseActions.delete
		];

		return actions;
	}

	selectMultipleCaseAction = (cases, action) => {
		switch (action) {
			case this.dic.CONSTANTS.archiveCaseActions.delete:
				this.showDeleteCasePopup(cases);
				break;
		}
	}

    showSharingManagement = (caseObj) => {
		this.archiveService.showSharingCasePopup(caseObj);
    }

    runCase = (caseName) => {
		this.router.navigate([this.dic.CONSTANTS.appStates.archive, this.dic.CONSTANTS.archivePages.archive], {state: {caseName: caseName}});
    }

    searchCases = (searchTerm, activeFilters) => {
        this.cases.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            if (activeFilters) {
                // currently no filters for cases table
            }

            record.hide = false;
        });
    };

    exportToCsv = (sortBy) => {
        if (!this.cases || !this.cases.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Date,Name,Expiration,Query\n";

        let sortedTable = _.filter(this.cases,incident => {return !incident.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        sortedTable.forEach((caseObj) => {
            let queryStr = '';
            if (caseObj.query.period) {
                queryStr += `Period: ${caseObj.query.period}`;
                if (caseObj.query.period === this.dic.CONSTANTS.trendsPeriod.range.value) {
                    queryStr += `, Start: ${caseObj.query.range.start}`;
                    queryStr += `, End: ${caseObj.query.range.end}`;
                }
            }
            if (caseObj.query.from) {
                queryStr += `${queryStr ? ', ': ''}From: ${caseObj.query.from}`;
            }
            if (caseObj.query.rcptTo) {
                queryStr += `${queryStr ? ', ': ''}Recipients: ${caseObj.query.rcptTo}`;
            }
            if (caseObj.query.subject) {
                queryStr += `${queryStr ? ', ': ''}Subject: ${caseObj.query.subject}`;
            }
            if (caseObj.query.text) {
                queryStr += `${queryStr ? ', ': ''}Contains: ${caseObj.query.text}`;
            }
            csvString += `${caseObj.created},"${caseObj.name}","${caseObj.sharing.expiration || 'N/A'}","${queryStr}"\n`;
        });

        this.gs.exportCsv(csvString, `archive_cases.csv`);
    };

    isSharingExpired(caseObj) {
        if (caseObj.sharing && caseObj.sharing.expiration) {
            if (Date.now() > Date.parse(caseObj.sharing.expiration)) {
                return true;
            }
        }
        return false;
    }

}
function searchTextExecute(caseObj, searchTerm) {
    return (caseObj.name.toLowerCase().indexOf(searchTerm) > -1) ||
        (caseObj.query.subject && caseObj.query.subject.toLowerCase().indexOf(searchTerm) > -1) ||
        (caseObj.query.from && caseObj.query.from.toLowerCase().indexOf(searchTerm) > -1) ||
        (caseObj.query.rcptTo && caseObj.query.rcptTo.toLowerCase().indexOf(searchTerm) > -1) ||
        (caseObj.query.text && caseObj.query.text.toLowerCase().indexOf(searchTerm) > -1);
}

