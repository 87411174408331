import {Component, EventEmitter, Input, Output} from "@angular/core";
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {ClipboardService} from "ngx-clipboard";
import {DICTIONARY} from "../../dictionary";

@Component({
	selector: 'sensitivity-details',
	templateUrl: './sensitivity-details.component.html',
})
export class SensitivityDetailsComponent {
	@Output() cancelCb = new EventEmitter<any>;
	@Input() sensitivity;
	@Input() scanTitle;
	@Input() scanItemObj;
	@Input() type;
	@Input() origin;

	constructor(private rs:RouteService,
				private ns:NotificationService,
				private clipboard: ClipboardService) {
	}

	dic = DICTIONARY;
	reportWrongSensitivityInProcess = false;
	checkSensitivityInProcess;
	reportWrongSensitivityPopup;

	checkSensitivity = () => {
		this.checkSensitivityInProcess = true;

		if (this.type === 'email') {
			this.checkEmailSensitivityExecute(this.scanItemObj, this.origin, (err, updatedSensitivity) => {
				this.sensitivity = updatedSensitivity;
				this.checkSensitivityInProcess = false;
				this.ns.showInfoMessage(this.dic.MESSAGES.attachSensitivityUpdate);
			});
		}
		else {
			this.rs.checkSensitivityAttachment(this.scanItemObj._id).then(updatedSensitivity => {
				this.sensitivity = updatedSensitivity;
				this.checkSensitivityInProcess = false;
				this.ns.showInfoMessage(this.dic.MESSAGES.attachSensitivityUpdate);
			}, () => {
				this.checkSensitivityInProcess = false;
			});
		}
	};

	checkEmailSensitivityExecute = (scanItemObj, scanOrigin, cb) => {
		if (scanOrigin === 'rules') {
			const ruleAction = {
				action: this.dic.CONSTANTS.rules.actions.sensitivityScan,
				userEmail: scanItemObj.from?.email,
				emailId: scanItemObj._id.toString()
			};

			this.rs.updateRule(scanItemObj._id, ruleAction).then(updatedSensitivity => {
				cb(null, updatedSensitivity);
			}, () => {
				this.checkSensitivityInProcess = false;
			});
		}
		else {
			this.rs.checkEmailSensitivity(scanItemObj._id).then(updatedSensitivity => {
				cb(null, updatedSensitivity);
			}, () => {
				this.checkSensitivityInProcess = false;
			});
		}
	}

	openReportWrongSensitivityPopup = () => {
		this.reportWrongSensitivityInProcess = false;
		this.reportWrongSensitivityPopup = {
			reportText: '',
			show: true
		};
	};

	reportSensitivity = () => {
		if (!this.reportWrongSensitivityPopup.reportText || this.reportWrongSensitivityInProcess) return;
		this.reportWrongSensitivityInProcess = true;

		if (this.type === 'email') {
			this.reportSensitivityExecute(this.scanItemObj, this.origin, (err) => {
				this.reportWrongSensitivityPopup = false;
				this.reportWrongSensitivityInProcess = false;
				this.ns.showInfoMessage(this.dic.MESSAGES.resultsReported);
			});
		}
		else {
			this.rs.sendReport({
				type: this.dic.CONSTANTS.generalReportType.sensitivity,
				id: this.scanItemObj._id,
				//	userEmail: this.scanItemObj.from?.email,
				action: 'Attachment',
				name: this.scanTitle,
				reason: this.reportWrongSensitivityPopup.reportText
			}).then(() => {
				this.reportWrongSensitivityInProcess = false;
				this.reportWrongSensitivityPopup = false;
				this.ns.showInfoMessage(this.dic.MESSAGES.resultsReported);
			});
		}
	};

	reportSensitivityExecute = (scanItemObj, scanOrigin, cb) => {
		const reportAction: any = {
			type: this.dic.CONSTANTS.generalReportType.sensitivity,
			id: scanItemObj._id,
			action: 'Email',
			reason: this.reportWrongSensitivityPopup.reportText,
			name: this.scanTitle
		};

		if (scanOrigin === 'rules') {
			reportAction.action = this.dic.CONSTANTS.rules.actions.sensitivityReport;
			reportAction.userEmail = scanItemObj.from?.email;
			this.rs.updateRule(scanItemObj._id, reportAction).then(updatedSensitivity => {
				cb(null, updatedSensitivity)
			}, () => {
				this.reportWrongSensitivityInProcess = false;
			});
		}
		else {
			this.rs.sendReport(reportAction).then(updatedSensitivity => {
				cb(null, updatedSensitivity)
			}, () => {
				this.reportWrongSensitivityInProcess = false;
			});
		}
	};

	closeReportWrongSensitivityPopup = () => {
		this.reportWrongSensitivityPopup = null;
	}

	closeCb = () => {
		this.cancelCb.emit();
	}

	copyToClipboard = (copyTxt) => {
		this.clipboard.copy(copyTxt);
	}
}
