import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, Input, OnInit} from "@angular/core";
import {ArchiveService} from "../../../services/archiveService";
import {ClipboardService} from "ngx-clipboard";


@Component({
	selector: 'archive-email-quarantined-component',
	templateUrl: './archive-email-quarantined.component.html',
})
export class ArchiveEmailQuarantinedComponent implements OnInit {
    constructor(private rs:RouteService,
				public gs:GeneralService,
				public archiveService:ArchiveService,
				private clipboard: ClipboardService){
    }
	@Input() origin: string;

    dic = DICTIONARY;
    getThreatsDataInProcess = false;

    statusPriority = {
        'Malicious': 1,
        'Suspicious': 2,
        'Spam': 3,
        'Graymail': 4,
        'Unsecured': 5,
        'External': 6,
        'Pending': 7,
        'Safe': 8
    };

    curEmail;
    curTPResults;

	ngOnInit() {
        this.getEmailThreats(this.archiveService.currentMessage);
    }

    getEmailThreats(currentMessage) {
        if (currentMessage.threatsData && currentMessage.threatsData.length) {
            this.changeMailboxInfo(currentMessage.threatsData[0]);
            return;
        }

        this.getThreatsDataInProcess = true;
		if (this.origin === 'user') {
			this.getUserQuarantinedThreats(currentMessage, 0);
		}
		else {
			this.rs.getEmailThreats(currentMessage._source.quarantineIds.join(',')).then((response) => {
				currentMessage.threatsData = response;
				this.getThreatsDataInProcess = false;

				if (currentMessage.threatsData.length) {
					this.changeMailboxInfo(currentMessage.threatsData[0]);
				}
			}, (err) => {
				this.getThreatsDataInProcess = false;
			});
		}
    };

	getUserQuarantinedThreats(currentMessage, idx) {
		this.rs.getUserQuarantinedThreats(currentMessage._source.quarantineIds[idx]).then((response) => {
			currentMessage.threatsData = [response];
			this.getThreatsDataInProcess = false;

			if (currentMessage.threatsData.length) {
				this.changeMailboxInfo(currentMessage.threatsData[0]);
			}
		}, (err) => {
			if (idx < currentMessage._source.quarantineIds.length - 1) {
				this.getUserQuarantinedThreats(currentMessage, idx+1);
				return;
			}
			this.getThreatsDataInProcess = false;
		});
	}

    changeMailboxInfo = (mailboxInfo) => {
        this.curEmail = mailboxInfo
        this.curTPResults = mailboxInfo.malicious.tpResults;

        if (this.curTPResults) {
            if (this.curTPResults.resHead && this.curTPResults.resHead.aiConfidence) {
                this.curTPResults.resHead.aiConfidence = parseFloat(this.curTPResults.resHead.aiConfidence.toFixed(2))
            }
            if (this.curTPResults.resUrl && this.curTPResults.resUrl.list && this.curTPResults.resUrl.list.length) {
                this.curTPResults.resUrl.list = this.curTPResults.resUrl.list.sort((a, b) => this.statusPriority[a.status] - this.statusPriority[b.status]);
                this.filterDuplicateSafeLinks();
            }
            else {
                if (!this.curTPResults.resUrl) {
                    this.curTPResults.resUrl = {};
                }
                this.curTPResults.urlStatus = this.dic.CONSTANTS.threatProtection.status.safe;
                if (!this.curTPResults.resUrl.message) {
                    this.curTPResults.resUrl.message = 'No malicious links detected.';
                }
            }
            if (this.curTPResults.resHash && this.curTPResults.resHash.list && this.curTPResults.resHash.list.length) {
                this.curTPResults.resHash.list = this.curTPResults.resHash.list.sort((a, b) => this.statusPriority[a.status] - this.statusPriority[b.status]);
            }
            else {
                if (!this.curTPResults.resHash) {
                    this.curTPResults.resHash = {};
                }
                this.curTPResults.hashStatus = this.dic.CONSTANTS.threatProtection.status.safe;
                if (!this.curTPResults.resHash.message) {
                    this.curTPResults.resHash.message = 'No malicious attachments detected.';
                }
            }
        }
    };

    filterDuplicateSafeLinks = () => {
        let linksMap = {};
        this.curTPResults.resUrl.list = _.filter(this.curTPResults.resUrl.list, link => {
            if (link.name) {
                if (linksMap[link.name]) {
                    if (link.status === this.dic.CONSTANTS.threatProtection.status.safe) {
                        return false;
                    }
                }
                else {
                    if (link.status !== this.dic.CONSTANTS.threatProtection.status.safe) {
                        linksMap[link.name] = link.url;
                    }
                    // safe duplicates
                    else {
                        if (link.name === link.url &&
                            _.find<any>(this.curTPResults.resUrl.list, l => l.status === this.dic.CONSTANTS.threatProtection.status.safe && l.name === link.name && l.url !== link.url)) {
                            return false;
                        }
                    }
                }
            }
            return true;
        });
    };

    setInboundStatusStyles = (status) => {
        switch (status) {
            case this.dic.CONSTANTS.threatProtection.status.malicious:
                return {color: 'darkred', icon: 'fa-bug', loading: false};
            case this.dic.CONSTANTS.threatProtection.status.suspicious:
                return {color: 'darkred', icon: 'fa-bug', loading: false};
            case this.dic.CONSTANTS.threatProtection.status.error:
                return {color: 'darkred', icon: 'fa-exclamation-circle', loading: false};
            case this.dic.CONSTANTS.threatProtection.status.spam:
                return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false};
            case this.dic.CONSTANTS.threatProtection.status.grayMail:
                return {color: 'dimgray', icon: 'fa-exclamation-circle', loading: false};
            case this.dic.CONSTANTS.threatProtection.status.unsecured:
                return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false};
            case this.dic.CONSTANTS.threatProtection.status.pending:
            case this.dic.CONSTANTS.threatProtection.status.analyzing:
            case this.dic.CONSTANTS.threatProtection.status.analyzingLinks:
                return {color: 'darkorange', icon: 'fa-clock', loading: true};
            case this.dic.CONSTANTS.threatProtection.status.safe:
                return {color: 'darkgreen', icon: 'fa-check-circle', loading: false};
            case 'External':
                return {color: 'darkorange', icon: 'fa-external-link-alt', loading: false}
            default:
                return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false};
        }
    };

    copyItemToClipboard = (item) => {
        this.clipboard.copy(item);
    };

}
