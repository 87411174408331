<div class="flxClmn">
	<div class="page-title-container flxRow">
		<div class="flxClmn-center f-1-0">
			<div class="page-title-inner-container flxRow">
				<span class="page-title">Look and Feel</span>
				<!--Information modal-->
				<more-info-c guideTitle="Look and Feel Customization" guideUrl="https://docs.trustifi.com/docs/look-feel-customization">
					<div class="flxClmn vSpace05" style="flex: 1 0;">
						<span style="font-weight: bold;">Email template customization</span>
						<br/>
						<ul class="vSpace1" style="margin-top: 0;">
							<li style="color:#484848;">
								<span>Eeach part of the encrypted email template can be customized</span>
							</li>
							<li style="color:#484848;">
								<span>Logo image may be in PNG format only and up to 200KB in size</span>
							</li>
							<li style="color:#484848;">
								<span>For the email headline and footer you may use any combination of text, HTML, and dynamic fields</span>
							</li>
							<li style="color:#484848;">
								<span>Manual token input: when enabled, the template will contain instructions on how to access encrypted emails manually using the encrypted token</span>
							</li>
						</ul>
					</div>
					<div class="flxClmn vSpace05" style="flex: 1 0;">
						<span style="font-weight: bold;">Apply your own Look & Feel</span>
						<br/>
						<ul class="vSpace1" style="margin-top: 0;">
							<li style="color:#484848;">
								<span>You may add your company's name and URL to automatically add these values to your email templates</span>
							</li>
							<li style="color:#484848;">
								<span>When this is enabled, your logo and color customizations will apply also to the admin portal and to the encrypted email portal</span>
							</li>
						</ul>
					</div>

				</more-info-c>
			</div>
			<div class="page-subtitle-container">
				<span class="page-subtitle">Customize your Logo/Name/Color across {{corpname}}'s system</span>
			</div>
		</div>
		<div class="control-and-info-icons flxRow"></div>
	</div>
	<div class="page-inner-content-holder flxClmn" style="min-height: calc(100vh - 170px);">

		<div class="flxRow hSpace3" style="flex: 1 0;">
			<div class="flxClmn vSpace1 lf-settings-container" style="flex: 1 0;">
				<!--select customization-->
				<div class="flxClmn vSpace1" style="background: rgba(255,255,255,0.75); padding: 15px;">
					<div class="flxRow hSpace2" style="align-items: center;">
						<span style="font-size: 1rem;">Environment & Email Customization</span>
					</div>
					<div class="flxRow animated fadeIn gap-1" style="gap: 5px; flex-wrap: wrap; align-items: center; position: relative; z-index: 50;" *ngIf="!addCustomizationInProcess && mySettingsLogo?.selectedCustomization">
						<span>
							Select customization preset:
							<i style="font-weight: 400;;" class="fas fa-question-circle"
							   tooltip='The "Main" customization will be used by default. You can add more customizations that can be applied according to rules'
							   placement="top">
							</i>
						</span>
						<app-dropdown-c style="width: 120px; height: 25px;" [showTitle]="true"
									  text="{{addCustomizationInProcess ? '' : mySettingsLogo.selectedCustomization.name}}">
							<li *ngFor="let subCustomization of mySettingsLogo.sub_customizations"
								(click)="selectSubCustomization(subCustomization);">
								{{subCustomization.name}}
							</li>
						</app-dropdown-c>
						<btn-c style="margin-left: 20px; min-width: 0;"
							 *ngIf="!addCustomizationInProcess"
							 btnTooltip="Add new customization preset"
							 variant="outlined" endIcon="fa fa-plus"
							 (action)="addSubCustomization();">
							Add
						</btn-c>
						<btn-c class="animated fadeIn"
							 *ngIf="mySettingsLogo.selectedCustomization && mySettingsLogo.selectedCustomization.name === dic.CONSTANTS.lfMainCustomizationName"
							 btnTooltip='Reset "Main" preset to default settings'
							 variant="text" color="goldenrod"
							 (action)="openDeleteLogoPopup();">
							Reset to default
						</btn-c>
						<btn-c class="animated fadeIn" style="margin-left: 20px;"
							 *ngIf="mySettingsLogo.selectedCustomization && mySettingsLogo.selectedCustomization.name !== dic.CONSTANTS.lfMainCustomizationName"
							 variant="text" color="danger"
							 (action)="deleteSubCustomization();">
							Delete customization
						</btn-c>
					</div>
					<!--new customization name input-->
					<div class="flxRow hSpace2 animated fadeIn" style="align-items: center;" *ngIf="addCustomizationInProcess">
						<span>New customization's name:</span>
						<input type="text" placeholder="Name" style="width: 180px; margin: 0;"
							   [(ngModel)]="mySettingsLogo.selectedCustomization.name"/>
						<btn-c variant="outlined"
							 (action)="cancelSubCustomization();">
							Cancel
						</btn-c>
					</div>
				</div>

				<!--Email Template-->
				<div class="flxRow hSpace3 lf-settings-accordion"
					 [ngClass]="'expanded'">
					<div class="vSpace2" style="flex: 1 0;">
						<div class="flxClmn vSpace1">
							<span style="font-size: 1rem; font-weight: bold;">Email Template</span>
							<span style="font-size: 0.8rem; opacity: 0.85;">Apply your custom template to all outgoing Trustifi emails</span>
						</div>

						<div class="flxRow-start hSpace1 md-checkbox animated fadeIn" style="padding: 0 10px; align-items: center;">
							<input id="template_always_emphasize" required="false" type="checkbox"
								   [(ngModel)]="mySettingsLogo.selectedCustomization.template_always_emphasize"
								   (ngModelChange)="initChanges(true)"/>
							<label style="margin: 0;" for="template_always_emphasize">Always place decryption button on top</label>
							<i style="font-weight: 400; margin-left: 5px;" class="fas fa-question-circle"
							   tooltip="The button to decrypt the email or attachments will always be placed at the top of the email body"
							   placement="right">
							</i>
						</div>

						<div class="flxRow-start hSpace1 md-checkbox animated fadeIn" style="padding: 0 10px; align-items: center;"
							 *ngIf="(mySettingsLogo?.selectedCustomization?.name === dic.CONSTANTS.lfMainCustomizationName) && mySettingsLogo?.lf">
							<input id="emailTemplate" required="false" type="checkbox"
								   [(ngModel)]="mySettingsLogo.lf.template_enabled"
								   (ngModelChange)="initChanges(true)"/>
							<label style="margin: 0;" for="emailTemplate">Enable email template</label>
						</div>

						<div class="flxClmn vSpace1" style="padding: 0 20px;"
							 [ngClass]="{'disabled' : mySettingsLogo?.selectedCustomization?.name === dic.CONSTANTS.lfMainCustomizationName && !mySettingsLogo?.lf?.template_enabled}">
							<div class="flxRow hSpace2" style="padding-top: 5px;">
								<div class="flxRow-start hSpace1" style="padding-top: 5px;">
									<span style="margin: 0; padding-left: 1.75em; " >Custom HTML:</span>
								</div>
								<div class="flxClmn vSpace05" >
									<div class="flxRow hSpace1" style="min-height: 25px; align-items: center;">
										<span class="a-link" (click)="openCustomHtmlPopup(dic.CONSTANTS.lfCustomHtmlScopes.header);">Customize header</span>
										<span style="opacity: 0.65;" *ngIf="!mySettingsLogo?.selectedCustomization?.headline">
											(empty. click to edit)
										</span>
										<span style="font-size: 0.75rem; color: red;" *ngIf="headlineError">
											HTML code invalid
										</span>
										<input type="button" value="Fix"
											   *ngIf="headlineError"
											   tooltip="Fix HTML code" class="redborder fa"
											   (click)="openCustomHtmlPopup(dic.CONSTANTS.lfCustomHtmlScopes.header)"
											   style="width:50px; margin-top:0;">
									</div>
									<div class="flxRow hSpace1" style="min-height: 25px;">
										<span class="a-link" (click)="openCustomHtmlPopup(dic.CONSTANTS.lfCustomHtmlScopes.footer);">Customize footer</span>
										<span style="opacity: 0.65;" *ngIf="!mySettingsLogo?.selectedCustomization?.footer">
											(empty. click to edit)
										</span>
										<span style="font-size: 0.75rem; color: red;" *ngIf="footerError">
											HTML code invalid
										</span>
										<input type="button" value="Fix"
											   *ngIf="footerError"
											   tooltip="Fix HTML code" class="redborder fa"
											   (click)="openCustomHtmlPopup(dic.CONSTANTS.lfCustomHtmlScopes.footer)"
											   style="width:50px; margin-top:0;">
									</div>
								</div>
							</div>
							<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;" *ngIf="mySettingsLogo?.selectedCustomization">
								<input id="encToken" type="checkbox"
									   [(ngModel)]="mySettingsLogo.selectedCustomization.show_enc_help"
									   (ngModelChange)="initChanges(true)"/>
								<label style="margin: 0;" for="encToken">Show manual token input section</label>
								<i style="font-weight: 400; margin-left: 5px;" class="fas fa-question-circle"
								   tooltip="When enabled, the template includes instructions on how to access encrypted emails manually using the encrypted token"
								   placement="right">
								</i>
							</div>
						</div>
					</div>
				</div>

				<!--Logo & Color-->
				<div class="flxClmn vSpace2" style="background: rgba(255,255,255,0.75); padding: 15px; min-height: 350px;">
					<div class="flxRow-between" style="align-items: center;">
						<span style="font-size: 1rem; font-weight: bold;">Logo & Color</span>
					</div>
					<div class="flxRow hSpace4 w100" style="flex: 1 0;">
						<div class="flxClmn vSpace2" style="flex: 3 0;">
							<div class="flxRow hSpace2" style="min-height: 55px;">
								<span style="font-weight: bold; font-size: 0.85rem;">
									Logo:
								</span>
								<span style="font-size: 0.75rem; flex: 1 0; opacity: 0.8;">
									The selected logo will appear at the top part of your encrypted emails
								</span>
							</div>
							<div class="flxClmn-center vSpace3" style="flex: 3 0; padding: 0 10px; align-items: center;" *ngIf="mySettingsLogo?.selectedCustomization">
								<a ngfSelect accept=".png"
								   [(file)]="logoFile"
								   (fileChange)="uploadLogo()"
								   class="flxClmn vSpace1"
								   style="flex: 1 0; align-items: center; width: 120px; min-height: 120px;">
									<div class="flxClmn-center hvr-opacity" style="position: relative; cursor: pointer; align-items: center; border: solid 1px #e3e4e4; background: #fafafa; width: 120px; height: 120px;">
										<img [src]="'data:image/png;base64,' + mySettingsLogo?.selectedCustomization?.logo"
											 style="width: auto; height: auto; max-width: 275px; max-height: 50px;"
											 id="upload"
											 *ngIf="mySettingsLogo.selectedCustomization.logo" />
										<i *ngIf="!mySettingsLogo.selectedCustomization.logo;"
										   style="font-size: 2.5rem; opacity: 0.1;" class="fas fa-image"></i>
										<i *ngIf="mySettingsLogo.selectedCustomization.logo"
										   (click)="$event.stopPropagation(); mySettingsLogo.selectedCustomization.logo = null; initChanges(true);"
										   style="position: absolute; top: 0; padding: 5px; right: 0;" class="fa fa-times"></i>
									</div>
									<span style="text-align: center; white-space: nowrap;" class="a-link animated fadeIn"
										*ngIf="mySettingsLogo.selectedCustomization.logo">
										change logo
									</span>
									<span class="a-link" style="opacity: 0.75; text-align: center; white-space: nowrap;"
										  *ngIf="!mySettingsLogo.selectedCustomization.logo">
										click to upload your logo
									</span>
								</a>
								<span style="text-align: center; font-size: 0.75rem;" class="animated fadeIn">
									<b>PNG only</b> | Optimal size (275x50px)
								</span>

								<div class="flxRow hSpace2 bs-enabled" style="align-items: center">
									<span>Logo size:</span>
									<div class="flxClmn vSpace05">
										<label class="radio-container ps-4" style="display: block;">
											Medium
											<input type="radio"
												   name="logoSizeInput"
												   [(ngModel)]="mySettingsLogo.selectedCustomization.logo_template_size"
												   (ngModelChange)="initChanges(true);"
												   [value]="logoSizes.medium">
											<span class="checkmark"></span>
										</label>
										<label class="radio-container ps-4" style="display: block; width: fit-content;">
											Large
											<input type="radio"
												   name="logoSizeInput"
												   [(ngModel)]="mySettingsLogo.selectedCustomization.logo_template_size"
												   (ngModelChange)="initChanges(true);"
												   [value]="logoSizes.large">
											<span class="checkmark"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="vertical-separator" style="opacity: 0.2;"></div>
						<div class="flxClmn vSpace2" style="flex: 4 0;">
							<div class="flxRow hSpace2" style="min-height: 55px;">
								<span style="font-weight: bold; font-size: 0.85rem; flex-shrink: 0;">
									Theme color:
								</span>
								<span style="font-size: 0.75rem; opacity: 0.8;">
									The selected color will be applied to the encrypted email buttons
								</span>
							</div>
							<div class="flxClmn-between vSpace2" style="flex: 1 0; align-items: center;">
								<div class="flxRow hSpace1" style="align-items: baseline; margin-bottom: 15px; color: var(--bs-warning);" *ngIf="gs.userInfo?.dark_mode">
									<i class="fa fa-exclamation-triangle "></i>
									<span>While in "Dark Mode", color picker will show inaccurate colors.</span>
								</div>
								<div class="flxRow-center w100 hSpace3 color-picker-css" style="align-items: center; z-index: 20;">
									<div class="flxClmn-center vSpace2" style="align-items: center;" *ngIf="mySettingsLogo?.selectedCustomization">
										<div style="width: 40px; height: 40px;">
											<div class="w100 h100 static-color" style="border-radius: 50%;"
												 [ngStyle]="{'background' : '#' + mySettingsLogo.selectedCustomization.color.replace('#','')}"
												 *ngIf="mySettingsLogo.selectedCustomization.color.replace('#','').length === 6">
											</div>
										</div>
										<div class="flxClmn vSpace05" style="">
											<input type="text" style="width: 90px; margin: 0;"
												   [(ngModel)]="mySettingsLogo.selectedCustomization.color"
												   [ngClass]="{'validation-error' : hexInputError}"
												   (keyup)="hexInputChanged();"
												   [value]="'#' + mySettingsLogo.selectedCustomization.color.replace('#','')">
											<span style="opacity: 0.65; font-family: 'Roboto Condensed', sans-serif; letter-spacing: 0.5px;">Color Hex<br/>(6 digits only)</span>
										</div>
									</div>
									<span [(colorPicker)]="mySettingsLogo.selectedCustomization.color"
										  [cpPosition]="'bottom'" [cpPositionOffset]="'50%'" [cpWidth]="'175px'"
										  [cpOutputFormat]="'hex'"
										  [cpDialogDisplay]="'inline'"
										  [cpDisableInput]="true"
										  [cpToggle]="true"
										  (cpSliderDragEnd)="colorChangedHandler()"
										  [style.position]="'relative'"></span>
								</div>
								<div class="flxRow hSpace1">
									<i style="font-size: 1rem; opacity: 0.5;" class="fas fa-info-circle"></i>
									<span style="text-align: center; font-size: 0.75rem;" class="animated fadeIn">
										When "Environment Look and Feel" is enabled, color will also be used across your Trustifi web application and encrypted email portal
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="w100" style="height: 15px;">
						<div class="flxRow hSpace05 animated fadeIn" style="color: darkgoldenrod;"
							 *ngIf="mySettingsLogo?.selectedCustomization?.name === dic.CONSTANTS.lfMainCustomizationName && !mySettingsLogo?.lf?.template_enabled && mySettingsLogo?.selectedCustomization?.color && mySettingsLogo?.selectedCustomization?.logo">
							<i style="font-size: 1rem; opacity: 0.5;" class="fas fa-info-circle"></i>
							<span>Logo and color will apply only when <b>email template</b> is enabled</span>
						</div>
					</div>

				</div>

				<!--Environment Look and Feel-->
				<div class="flxRow hSpace3 lf-settings-accordion"
					 [ngClass]="'expanded'">
					<div class="vSpace2" style="flex: 1 0;">
						<div class="flxClmn vSpace1">
							<span style="font-size: 1rem; font-weight: bold;">Environment Look and Feel</span>
							<span style="font-size: 0.8rem; opacity: 0.85;">Apply your custom settings to Trustifi's web application, encrypted email portal and notification emails</span>
						</div>

						<div class="flxRow-start hSpace1 md-checkbox animated fadeIn" style="padding: 0 10px; align-items: center;"
							 *ngIf="(mySettingsLogo?.selectedCustomization?.name === dic.CONSTANTS.lfMainCustomizationName) && mySettingsLogo?.lf">
							<input id="environmentLF" required="false" type="checkbox"
								   [(ngModel)]="mySettingsLogo.lf.enabled"
								   (ngModelChange)="initChanges()"/>
							<label style="margin: 0;" for="environmentLF">Enable environment look and feel</label>
						</div>

						<div class="flxClmn vSpace05" style="flex: 1 0; padding: 0 20px; margin-top: 25px;"
							 [ngClass]="{'disabled' : mySettingsLogo?.selectedCustomization?.name === dic.CONSTANTS.lfMainCustomizationName && !mySettingsLogo?.lf?.enabled}">
							<div class="flxRow hSpace1" style="align-items: center;" *ngIf="mySettingsLogo?.lf && mySettingsLogo?.selectedCustomization">
								<span style="flex-basis: 45px;">Name:</span>
								<input type="text" id="lfCompanyName"
									   style="margin: 0; width: 250px; height: 25px;"
									   *ngIf="mySettingsLogo.selectedCustomization.name === dic.CONSTANTS.lfMainCustomizationName"
									   [(ngModel)]="mySettingsLogo.lf.company"
									   (ngModelChange)="initChanges();"
									   placeholder="Company name"
									   [ngClass]="{'validation-error' : wasSubmitted && mySettingsLogo.lf.enabled && !mySettingsLogo.lf.company}">
								<input type="text" id="CompanyName"
									   style="margin: 0; width: 250px; height: 25px;"
									   *ngIf="mySettingsLogo.selectedCustomization.name !== dic.CONSTANTS.lfMainCustomizationName"
									   [(ngModel)]="mySettingsLogo.selectedCustomization.company"
									   (ngModelChange)="initChanges();"
									   placeholder="Company name (optional)">
							</div>
							<div class="flxRow hSpace1" style="align-items: center;"
								 *ngIf="(mySettingsLogo?.selectedCustomization?.name === dic.CONSTANTS.lfMainCustomizationName) && mySettingsLogo?.lf">
								<span style="flex-basis: 45px;">URL:</span>
								<input type="text" id="lfCompanyUrl" style="margin: 0; width: 250px; height: 25px;"
									   [(ngModel)]="mySettingsLogo.lf.url"
									   (ngModelChange)="initChanges();"
									   placeholder="Company's URL"
									   [ngClass]="{'validation-error' : wasSubmitted && mySettingsLogo.lf.enabled && !mySettingsLogo.lf.url}">
							</div>
						</div>
					</div>
				</div>

				<div class="flxRow-center hSpace2" style="margin-top: 20px; flex: 1 0; align-items: flex-end;" *ngIf="!addCustomizationInProcess">
					<btn-c [isDisabled]="userLogoDiff"
						 (action)="openUpdateLogoDetailsPopup(); wasSubmitted = true;">
						Apply
					</btn-c>
				</div>
				<div class="flxRow-center hSpace2" style="margin-top: 20px; flex: 1 0; align-items: flex-end;" *ngIf="addCustomizationInProcess">
					<btn-c [isDisabled]="saveSubCustomizationDisabled()"
						 (action)="saveSubCustomization(false); wasSubmitted = true;">
						Save
					</btn-c>
				</div>
			</div>
			<div class="flxClmn" style="flex: 1 0; align-items: center; background: radial-gradient(#486db51f, transparent 65%, transparent); margin-right: 20px;">
				<div class="flxClmn w100 vSpace1" style="flex: 1 0; padding: 1vw; max-width: 700px; max-height: 700px; background: #f5f5f5; width: 100%; box-shadow: 0 0 10px darkgrey; border-radius: 5px;">
					<div class="flxRow">
						<span style="font-size: 1rem;">Email Template Preview</span>
					</div>

					<div class="flxClmn w100" style="flex: 1 0; background: #f5f5f5; position: relative; width: 100%;">
						<div class="flxClmn w100" style="flex: 1 0; background: white; padding: 1vw;">
							<div class="w100" style="flex:1 0; overflow: auto;">
								<email-viewer [content]="previewEmailContent" [prioritizeClasses]="true" style="min-height: 100%;"></email-viewer>
							</div>
							<div class="flxClmn-center animated fadeIn" style="align-items: center; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: rgba(255,255,255,0.7);"
								 *ngIf="previewEmailInProgress">
								<loader height="100" width="100" style="opacity: 0.8;"></loader>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--Customize HTML popup-->
	<popup-c class="no-content-padding" *ngIf="customHtmlPopup?.show"
		   head="Customize Email {{customHtmlPopup.scope === dic.CONSTANTS.lfCustomHtmlScopes.header ? 'header' : 'footer'}} HTML"
		   (closeCb)="customHtmlPopup = null"
		   size="medium">
		<div class="flxClmn f-1-0 vSpace2" style="overflow: auto; padding: 25px;">
			<span style="margin-top: 0;" *ngIf="customHtmlPopup.scope === dic.CONSTANTS.lfCustomHtmlScopes.header">Add your own custom HTML that will appear below your logo in the encrypted email template</span>
			<span style="margin-top: 0;" *ngIf="customHtmlPopup.scope === dic.CONSTANTS.lfCustomHtmlScopes.footer">Add your own custom HTML that will appear at the bottom of the encrypted email template</span>

			<textarea class="f-1-0" style="resize: none;"
					  placeholder="Insert HTML code here"
					  [(ngModel)]="customHtmlPopup.customHtmlString"
					  (ngModelChange)="HTMLcodeChanged = true;">
			</textarea>
			<div class="flxRow-end hSpace2" style="align-items: flex-end;">
				<btn-c class="popup-action-btn"
					 *ngIf="(customHtmlPopup.scope === dic.CONSTANTS.lfCustomHtmlScopes.header ? headlineError : footerError )
						 		&& HTMLcodeChanged === false"
					 variant="outlined" endIcon="fas fa-magic"
					 (action)="fixHtmlCode();">
					Fix code
				</btn-c>
				<btn-c class="popup-action-btn"
					 (action)="applyCustomHtmlPopup()">
					Confirm
				</btn-c>
			</div>
		</div>
	</popup-c>

</div>


