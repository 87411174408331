<div class="flex-column gap-4">
	<span>Integrate {{gs.corpname}} with various services and providers to gain advanced capabilities</span>

	<div class="flxRow-center" *ngIf="!isLoaded">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div class="flex-row flex-wrap gap-3 justify-content-start animated fadeIn" *ngIf="isLoaded">
		<ng-container *ngFor="let integration of dic.CONSTANTS.integrationType | keyvalue: gs.returnZero; trackBy: gs.trackByIndex;">
			<ng-container *ngIf="!(integration.value === dic.CONSTANTS.integrationType.checkpoint && !isInbound)">
				<ng-container *ngTemplateOutlet="integrationCardTemplate; context: {$implicit: integration}"></ng-container>
			</ng-container>
		</ng-container>

		<ng-template #integrationCardTemplate let-integration>
			<div class="flex-column gap-2 px-3 py-2 border border-1 rounded-2 bg-white cursor-pointer integration-service-card" style="width: 330px; height: 150px; transition: all 0.25s;"
				 [ngClass]="{'border-primary shadow' : selectedIntegration === integration.value}"
				 [ngStyle]="{'order' : integrationsData[integration.value]?.enabled ? '0' : '1'}"
				 (click)="selectIntegration(integration.value)">
				<div class="flex-row justify-content-between align-items-center gap-3">
					<div class="d-flex centralize rounded-3 border border-1 bg-light p-1" style="width: 40px; height: 40px;">
						<img class="w-100" [src]="'images/integrationsLogos/' + integration.key + '-logo.png'" />
					</div>
					<span class="px-2 py-1 border border-1 rounded-2 text-center" style="font-size: 12px; width: 76px; transition: all 0.25s;"
						  [ngClass]="integrationsData[integration.value]?.enabled ? 'border-success bg-success text-white text-success cursor-default' : 'text-muted cursor-pointer hvr-darken'">
						{{integrationsData[integration.value]?.enabled ? 'Connected' : 'Connect'}}
					</span>
				</div>
				<span class="fw-500 mt-1 integration-name" style="font-size: 15px;" [ngClass]="{'text-success' : integrationsData[integration.value]?.enabled}">{{integration.value}}</span>
				<span style="font-size: 12px; color: #232323;">{{integrationsData[integration.value]?.desc}}</span>
			</div>
		</ng-template>
	</div>

	<popup-c *ngIf="selectedIntegration"
			 head="{{selectedIntegration}} Integration"
			 size="dynamic" width="750px"
			 (closeCb)="selectedIntegration = null;">
		<ng-container [ngSwitch]="selectedIntegration">

			<!--Open ID popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.openid">
				<ng-container *ngTemplateOutlet="connectSectionTemplate; context: {text: integrationsData[selectedIntegration].enabled ? 'You are now integrated with authentication service provider using OIDC' : 'Allow us to establish a secure connection to your authentication service provider using OIDC'}"></ng-container>

				<ng-container *ngIf="integrationsData[selectedIntegration].enabled">
					<div class="flex-row gap-4">
						<div class="w-50 flex-column gap-2">
							<span style="font-weight: 500;">Discovery Document ("well-known") Endpoint</span>
							<span style="font-size: 12px; max-width: 300px;">Integrated to the following OIDC Authorization Server</span>
						</div>
						<div class="w-50">
							<span>{{planInfo?.integrations.openid.metadata}}</span>
						</div>
					</div>
					<div class="flex-row gap-4">
						<div class="w-50 flex-column gap-2">
							<span style="font-weight: 500;">User Info Endpoint</span>
							<span style="font-size: 12px; max-width: 300px;">Integrated to the following OIDC Authorization Server</span>
						</div>
						<div class="w-50">
							<span>{{planInfo?.integrations.openid.userinfo_endpoint}}</span>
						</div>
					</div>
					<div class="flex-row gap-4">
						<div class="w-50 flex-column gap-2">
							<span style="font-weight: 500;">Authorization Endpoint</span>
							<span style="font-size: 12px; max-width: 300px;">Integrated to the following OIDC Authorization Server</span>
						</div>
						<div class="w-50">
							<span>{{planInfo?.integrations.openid.auth_endpoint}}</span>
						</div>
					</div>
					<div class="flex-row gap-4" *ngIf="planInfo?.integrations.openid.logout_endpoint">
						<div class="w-50 flex-column gap-2">
							<span style="font-weight: 500;">End Session Endpoint</span>
							<span style="font-size: 12px; max-width: 300px;">Integrated to the following OIDC Authorization Server</span>
						</div>
						<div class="w-50">
							<span>{{planInfo?.integrations.openid.logout_endpoint}}</span>
						</div>
					</div>
					<div class="flex-row gap-4">
						<div class="w-50 flex-column gap-2">
							<span style="font-weight: 500;">Client ID</span>
							<span style="font-size: 12px; max-width: 300px;">OIDC remote application Client ID</span>
						</div>
						<div class="w-50">
							<span>{{planInfo?.integrations.openid.clientId}}</span>
						</div>
					</div>
				</ng-container>

				<ng-container *ngTemplateOutlet="domainSectionTemplate; context: {domainsList: planInfo.integrations.openid?.domains || []}"></ng-container>

				<ng-container *ngTemplateOutlet="verifyConnectionSectionTemplate; context: {subtitle: 'Check if your OIDC connection\'s credentials are still active and valid'}"></ng-container>
			</div>

			<!--Saml popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.saml">

				<ng-container *ngTemplateOutlet="connectSectionTemplate; context: {text: integrationsData[selectedIntegration].enabled ? 'You are now integrated with authentication service provider using SAML' : 'Allow us to establish a secure connection to your authentication service provider using SAML'}"></ng-container>

				<ng-container *ngIf="integrationsData[selectedIntegration].enabled">
					<div class="flex-row gap-4">
						<div class="w-50 flex-column gap-2">
							<span style="font-weight: 500;">Sign On URL</span>
							<span style="font-size: 12px; max-width: 300px;">Integrated to the following SAML Authorization Server</span>
						</div>
						<div class="w-50">
							<span>{{planInfo?.integrations.saml.signOnURL}}</span>
						</div>
					</div>
					<div class="flex-row gap-4" *ngIf="planInfo?.integrations.saml.logoutURL">
						<div class="w-50 flex-column gap-2">
							<span style="font-weight: 500;">Logout URL</span>
							<span style="font-size: 12px; max-width: 300px;">Integrated to the following SAML Authorization Server</span>
						</div>
						<div class="w-50">
							<span>{{planInfo?.integrations.saml.logoutURL}}</span>
						</div>
					</div>
					<div class="flex-row gap-4">
						<div class="w-50 flex-column gap-2">
							<span style="font-weight: 500;">Entity ID</span>
							<span style="font-size: 12px; max-width: 300px;">Integrated to the following SAML Authorization Server</span>
						</div>
						<div class="w-50">
							<span>{{planInfo?.integrations.saml.entityID}}</span>
						</div>
					</div>
				</ng-container>

				<ng-container *ngTemplateOutlet="domainSectionTemplate; context: {domainsList: planInfo.integrations.saml?.domains || []}"></ng-container>

				<ng-container *ngTemplateOutlet="verifyConnectionSectionTemplate; context: {subtitle: 'Check if your SAML connection\'s credentials are still active and valid'}"></ng-container>
			</div>

			<!--Splunk popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.splunk">
				<div class="flex-column info-banner fw-normal gap-2">
					<span>This integration will be used to send logs for the following event types:</span>
					<ul>
						<li>Inbound emails being quarantined.</li>
						<li>Account Takeover Protection incidents.</li>
						<li>Audit logs entries.</li>
					</ul>
				</div>

				<ng-container *ngTemplateOutlet="connectSectionTemplate; context: {text: integrationsData[selectedIntegration].enabled ? 'Your SIEM is now connected to ' + gs.corpname + ' via Splunk' : 'Click on &quot;Connect&quot; to establish a secure connection with your SIEM instance'}" ></ng-container>

				<ng-container *ngTemplateOutlet="verifyConnectionSectionTemplate; context: {subtitle: 'Click to verify your Splunk integration is configured correctly'}"></ng-container>
			</div>

			<!--Azure EventHub popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.eventhub">
				<div class="flex-column info-banner fw-normal gap-2">
					<span>This integration will be used to send logs for the following event types:</span>
					<ul>
						<li>Inbound emails being quarantined.</li>
						<li>Account Takeover Protection incidents.</li>
						<li>Audit logs entries.</li>
					</ul>
				</div>

				<ng-container *ngTemplateOutlet="connectSectionTemplate; context: {text: integrationsData[selectedIntegration].enabled ? 'Your SIEM is now connected to ' + gs.corpname + ' via Azure EventHub' : 'Click on &quot;Connect&quot; to establish a secure connection with your SIEM instance'}" ></ng-container>

				<ng-container *ngTemplateOutlet="verifyConnectionSectionTemplate; context: {subtitle: 'Click to verify your Azure EventHub integration is configured correctly'}"></ng-container>
			</div>

			<!--Syslog popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.syslog">
				<div class="flex-column info-banner fw-normal gap-2">
					<span>This integration will be used to send logs for the following event types:</span>
					<ul>
						<li>Inbound emails being quarantined.</li>
						<li>Account Takeover Protection incidents.</li>
						<li>Audit logs entries.</li>
					</ul>
				</div>

				<ng-container *ngTemplateOutlet="connectSectionTemplate; context: {text: integrationsData[selectedIntegration].enabled ? 'Your SIEM is now connected to ' + gs.corpname + ' via Syslog' : 'Allow us to establish a secure Syslog connection to your SIEM'}"></ng-container>

				<div class="flex-row gap-4" *ngIf="integrationsData[selectedIntegration].enabled">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;">Connection Details</span>
						<span style="font-size: 12px; max-width: 300px;">Syslog server connection information</span>
					</div>
					<div class="w-50 flex-column vSpace2">
						<span><b>Host:&nbsp;</b>{{planInfo?.integrations.syslog.host}}</span>
						<span><b>Port:&nbsp;</b>{{planInfo?.integrations.syslog.port}}</span>
						<div>
							<span><b>Protocol:&nbsp;</b><span style="text-transform: uppercase;">{{planInfo?.integrations.syslog.protocol}}</span></span>
							<span *ngIf="planInfo?.integrations.syslog.protocol === 'tcp'" >
								<br/>(Messages will be sent from static IPs: 3.227.182.193, 54.161.96.109)
							</span>
						</div>
					</div>
				</div>

				<ng-container *ngTemplateOutlet="verifyConnectionSectionTemplate; context: {subtitle: gs.corpname + ' will send you a test log to your Syslog server'}"></ng-container>
			</div>

			<!--Powershell popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.powershell">

				<ng-container *ngTemplateOutlet="connectSectionTemplate; context: {text: integrationsData[selectedIntegration].enabled ? 'You are now logged in to Exchange Server PowerShell' :
																						planInfo?.powershell?.enabled ? 'Your PowerShell integration has been disconnected due to an expired or invalid access token. Please reconnect to re-enable PowerShell functionality':
																						'Log in with your Exchange global admin credentials to create a PowerShell integration with ' + gs.corpname}"></ng-container>

				<ng-container *ngTemplateOutlet="verifyConnectionSectionTemplate; context: {subtitle: 'Verify your Powershell integration with ' + gs.corpname}"></ng-container>

				<div class="flex-row gap-4">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;" >Set Exchange Connector & Rules</span>
						<span style="font-size: 12px; max-width: 300px;">
							Set up connectors and rules in Exchange to integrate your mail flow with {{gs.corpname}}.
						</span>
					</div>
					<div class="w-50">
						<btn-c (action)="openExchangePowershellPopup(exchangePowershellCommands.rules)"
							   endIcon="fas fa-magic"
							   [isDisabled]="!integrationsData[selectedIntegration].enabled || integrationActionInProcess"
							   style="width: 135px;">
							Configure
						</btn-c>
					</div>
				</div>

				<div class="flex-row gap-4" *ngIf="isInbound">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;" >Disable Exchange Protection</span>
						<span style="font-size: 12px; max-width: 300px;">Disable the default security policies in Exchange.
								</span>
					</div>
					<div class="w-50">
						<btn-c (action)="openExchangePowershellPopup(exchangePowershellCommands.protection)"
							   endIcon="fas fa-times-circle"
							   [isDisabled]="!integrationsData[selectedIntegration].enabled || integrationActionInProcess"
							   style="width: 135px;">
							Configure
						</btn-c>
					</div>
				</div>

				<div class="flex-row gap-4">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;" >View Exchange Server Configuration</span>
						<span style="font-size: 12px; max-width: 300px;">Retrieve information from your Exchange server that can be used to validate your configurations.</span>
					</div>
					<div class="w-50">
						<btn-c (action)="openExchangePowershellPopup(exchangePowershellCommands.configuration)"
							   endIcon="fas fa-search"
							   [isDisabled]="!integrationsData[selectedIntegration].enabled || integrationActionInProcess"
							   style="width: 135px;">
							View
						</btn-c>
					</div>
				</div>

				<div class="flex-row gap-4" *ngIf="isInbound">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;" >Sync Quarantined Emails from Exchange</span>
						<span style="font-size: 12px; max-width: 300px;">
									Allow syncing quarantined emails from your Exchange server into {{gs.corpname}}.<br/>
									You can view these emails in the Inbound Quarantine page by selecting the "Microsoft Exchange" source parameter.
								</span>
					</div>
					<div class="w-50 flex-column gap-4">
						<toggle-switch-c id="enableSyncQuarantine"
										 [(model)]="planInfo.powershell.sync_quarantine"
										 [hideText]="true"
										 [isDisabled]="!integrationsData[selectedIntegration].enabled || integrationActionInProcess"
										 (onChange)="openExchangePowershellPopup(exchangePowershellCommands.enableSyncQuarantine)">
						</toggle-switch-c>

						<btn-c 	[isDisabled]="!planInfo.powershell.sync_quarantine || !integrationsData[selectedIntegration].enabled || integrationActionInProcess"
								  (action)="openPowershellAutoReleasePopup();">
							Release quarantined emails
						</btn-c>
					</div>
				</div>

				<div class="flex-row gap-4" *ngIf="isInbound">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;" >Import Exchange Quarantine to Trustifi</span>
						<span style="font-size: 12px; max-width: 300px;">
							Automatically moves emails from the Exchange quarantine into the Trustifi quarantine for centralized management.
						</span>
					</div>
					<div class="w-50 flex-column gap-4">
						<toggle-switch-c id="enableAutomaticRelease"
										 [(model)]="planInfo.powershell.automatic_release"
										 [hideText]="true"
										 [isDisabled]="!integrationsData[selectedIntegration].enabled || !planInfo.powershell.sync_quarantine || integrationActionInProcess"
										 (onChange)="openExchangePowershellPopup(exchangePowershellCommands.enableAutomaticRelease)">
						</toggle-switch-c>

						<span *ngIf="planInfo.powershell.automatic_release_date">
							Last release check at: {{planInfo.powershell.automatic_release_date | date:'MM/dd/yyyy HH:mm'}}
						</span>
					</div>
				</div>

				<div class="flex-row gap-4" *ngIf="isInbound">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;" >Scan & Release Safe Emails</span>
						<span style="font-size: 12px; max-width: 300px;">
							Scans emails from the Exchange quarantine and releases them if no threats are found.
						</span>
					</div>
					<div class="w-50 flex-column gap-4">
						<toggle-switch-c id="autoReleaseForReScan"
										 [(model)]="planInfo.powershell.rescan_after_exchange_release"
										 [hideText]="true"
										 [isDisabled]="!integrationsData[selectedIntegration].enabled || !planInfo.powershell.automatic_release || integrationActionInProcess"
										 (onChange)="openExchangePowershellPopup(exchangePowershellCommands.autoReleaseForReScan)">
						</toggle-switch-c>
					</div>
				</div>
			</div>

			<!--Exchange popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.exchange">
				<ng-container *ngTemplateOutlet="connectSectionTemplate"></ng-container>

				<div class="flex-row gap-4">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;">Advanced Permissions</span>
						<span style="font-size: 12px; max-width: 300px;">Required Permissions for Disabling User Accounts and Resetting User Passwords in Exchange via Account Takeover Protection</span>
					</div>
					<div class="w-50">
						<btn-c style="width: 135px;" endIcon="fa fa-plug" *ngIf="!planInfo.exchange_server.advanced_enabled"
							   [isDisabled]="integrationActionInProcess || !integrationsData[selectedIntegration].enabled"
							   [loading]="integrationActionInProcess"
							   (action)="connectToExchangeAdvanced();">
							Grant Advanced Permissions
						</btn-c>

						<div class="flex-column gap-2" *ngIf="planInfo.exchange_server.advanced_enabled">
							<span style="color: green;">Granted</span>
							<btn-c style="width: 135px;" endIcon="fa fa-redo"
								   [isDisabled]="integrationActionInProcess || !integrationsData[selectedIntegration].enabled"
								   [loading]="integrationActionInProcess"
								   (action)="connectToExchangeAdvanced();">
								Update Permissions
							</btn-c>
						</div>
					</div>
				</div>

				<div class="flex-row gap-4">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;">Cloud API endpoint</span>
						<span style="font-size: 12px; max-width: 300px;">You can select an endpoint with a specific geographic location for the Microsoft API</span>
					</div>
					<div class="w-50">
						<app-dropdown-c style="width: 210px; --bs-dropdown-max-height: 180px; --bs-dropdown-overflow: auto; display: block;" [text]="selectedServerEndpoint.name">
							<li *ngFor="let entry of dic.CONSTANTS.exchangeServerEndpoints | keyvalue"
								(click)="changeEndpoint(entry.value)">
								{{ entry.value.name }}
							</li>
						</app-dropdown-c>
					</div>
				</div>

				<ng-container *ngTemplateOutlet="domainSectionTemplate; context: {domainsList: planInfo.exchange_server.verified_domains || []}"></ng-container>

				<ng-container *ngTemplateOutlet="groupsAndAliasesSectionTemplate"></ng-container>
			</div>

			<!--Gsuite popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.gsuite">
				<ng-container *ngTemplateOutlet="connectSectionTemplate"></ng-container>

				<ng-container *ngTemplateOutlet="domainSectionTemplate; context: {domainsList: planInfo.gsuite_server.verified_domains || []}"></ng-container>

				<ng-container *ngTemplateOutlet="groupsAndAliasesSectionTemplate"></ng-container>
			</div>

			<!--Checkpoint popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.checkpoint">
				<ng-container *ngTemplateOutlet="connectSectionTemplate"></ng-container>

				<div class="flex-row gap-4" *ngIf="integrationsData[selectedIntegration].enabled">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;">Connection details</span>
					</div>
					<div class="w-50">
						<table class="simpleDataTableShrink middle">
							<tr>
								<td><b>Client ID:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.checkpoint.clientId}}</span></td>
							</tr>
						</table>
					</div>
				</div>

				<ng-container *ngTemplateOutlet="verifyConnectionSectionTemplate; context: {subtitle: 'Check if your Check Point connection\'s credentials are still active and valid'}"></ng-container>
			</div>

			<!--S3 popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.s3">
				<ng-container *ngTemplateOutlet="connectSectionTemplate"></ng-container>

				<div class="flex-row gap-4" *ngIf="integrationsData[selectedIntegration].enabled">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;">Connection details</span>
						<!--<span style="font-size: 12px; max-width: 300px;">...DESCRIPTION...</span>-->
					</div>
					<div class="w-50">
						<table class="simpleDataTableShrink middle">
							<tr>
								<td><b>Remote Account ID:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.s3.remote_account_id}}</span></td>
							</tr>
							<tr>
								<td><b>Remote Role:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.s3.remote_role}}</span></td>
							</tr>
							<tr>
								<td><b>Use your own server-side encryption:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.s3.use_predefined_sse}}</span></td>
							</tr>
							<tr *ngIf="planInfo?.integrations.s3.attachments">
								<td><b>Attachments Bucket:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.s3.attachments}}</span></td>
							</tr>
							<tr *ngIf="planInfo?.integrations.s3.content">
								<td><b>Content Bucket:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.s3.content}}</span></td>
							</tr>
							<tr *ngIf="planInfo?.integrations.s3.quarantinedEmail">
								<td><b>Quarantined Bucket:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.s3.quarantinedEmail}}</span></td>
							</tr>
							<tr *ngIf="planInfo?.integrations.s3.archiving">
								<td><b>Archiving Bucket:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.s3.archiving}}</span></td>
							</tr>
						</table>
					</div>
				</div>

				<ng-container *ngTemplateOutlet="verifyConnectionSectionTemplate; context: {subtitle: 'Check if your Amazon S3 connection\'s credentials and buckets are still active and valid'}"></ng-container>
			</div>

			<!--Ldap popup-->
			<div class="flex-column f-1-0 overflow-auto gap-4" *ngSwitchCase="dic.CONSTANTS.integrationType.ldap">
				<ng-container *ngTemplateOutlet="connectSectionTemplate"></ng-container>

				<div class="flex-row gap-4" *ngIf="integrationsData[selectedIntegration].enabled">
					<div class="w-50 flex-column gap-2">
						<span style="font-weight: 500;">Connection details</span>
						<!--<span style="font-size: 12px; max-width: 300px;">...DESCRIPTION...</span>-->
					</div>
					<div class="w-50">
						<table class="simpleDataTableShrink middle">
							<tr>
								<td><b>Username:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.ldap.username}}</span></td>
							</tr>
							<tr>
								<td><b>Endpoint:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.ldap.endpoint}}</span></td>
							</tr>
							<tr>
								<td><b>Base DN:&nbsp;</b></td>
								<td><span>{{planInfo?.integrations.ldap.baseDN}}</span></td>
							</tr>
						</table>
					</div>
				</div>

				<ng-container *ngTemplateOutlet="groupsAndAliasesSectionTemplate"></ng-container>
			</div>
		</ng-container>
	</popup-c>

	<!--HTML Templates-->
	<ng-template #verifyConnectionSectionTemplate let-subtitle="subtitle">
		<div class="flex-row gap-4">
			<div class="w-50 flex-column gap-2">
				<span style="font-weight: 500;" >Verify connection</span>
				<span style="font-size: 12px; max-width: 300px;">{{subtitle}}</span>
			</div>
			<div class="w-50">
				<btn-c style="width: 135px;"
					   [isDisabled]="integrationActionInProcess || !integrationsData[selectedIntegration].enabled"
					   (action)="verifyIntegrationConnection(selectedIntegration);">
					Verify
				</btn-c>
			</div>
		</div>
	</ng-template>

	<ng-template #connectSectionTemplate let-text="text">
		<div class="flex-row gap-4">
			<div class="w-50 flex-column gap-2">
				<span style="font-weight: 500;" [ngClass]="integrationsData[selectedIntegration].enabled ? 'text-success' : 'text-warning'">{{integrationsData[selectedIntegration].enabled ? 'Connected' : 'Disconnected'}}</span>
				<span style="font-size: 12px; max-width: 300px;" *ngIf="text">{{text}}</span>
				<span style="font-size: 12px; max-width: 300px;" *ngIf="!text">{{integrationsData[selectedIntegration].enabled ? 'Your mail server is now integrated with ' + gs.corpname : 'Allow us to establish a secure connection to your mail server using admin-credentials.'}}</span>
			</div>
			<div class="w-50">
				<btn-c style="width: 135px;"
					   endIcon="fa fa-plug"
					   [loading]="integrationActionInProcess"
					   (action)="connectToIntegration(selectedIntegration);">
					{{integrationsData[selectedIntegration].enabled ? 'Disconnect' : selectedIntegration === dic.CONSTANTS.integrationType.powershell && planInfo?.powershell?.enabled ? 'Reconnect':'Connect'}}
				</btn-c>
			</div>
		</div>
	</ng-template>

	<ng-template #domainSectionTemplate let-domainsList="domainsList">
		<div class="flex-row gap-4">
			<div class="w-50 flex-column gap-2">
				<span style="font-weight: 500;">Domains</span>
				<span style="font-size: 12px; max-width: 300px;">Domains managed by your {{selectedIntegration}} server</span>
			</div>
			<div class="w-50">
				<div *ngFor="let domain of domainsList">
					- {{domain}}
				</div>
				<span class="text-muted flex-row align-items-center h-100" *ngIf="!domainsList?.length">No verified domains</span>
			</div>
		</div>
	</ng-template>

	<ng-template #groupsAndAliasesSectionTemplate>
		<div class="flex-row gap-4">
			<div class="w-50 flex-column gap-2">
				<span style="font-weight: 500;">Groups/Aliases</span>
				<span style="font-size: 12px; max-width: 300px;">knowing your groups/aliases will allow us to protect you better</span>
			</div>
			<div class="w-50 flxRow" style="flex-wrap: wrap;">
				<btn-c style="width: 135px; margin: 0 5px 7px 0;"
					   endIcon="fa fa-users"
					   (action)="getGroups(true);">
					Show Groups
				</btn-c>
				<btn-c style="width: 135px; margin: 0 5px 7px 0;"
					   endIcon="fa fa-users"
					   (action)="getMailboxesAliases(true);">
					Show Aliases
				</btn-c>
				<btn-c style="width: 135px; margin: 0 5px 7px 0;"
					   [loading]="integrationActionInProcess"
					   endIcon="{{integImportGroupsSummary ? 'fa fa-sync': 'fa fa-users-cog'}}"
					   [isDisabled]="!integrationsData[selectedIntegration].enabled || integrationActionInProcess"
					   (action)="integImportGroups();">
					{{integImportGroupsSummary ? 'Update ': 'Sync'}}
				</btn-c>
			</div>
		</div>
	</ng-template>
</div>



<groups-popup-c *ngIf="showGroups"
			(cancelCb)="getGroups(false)"
			popupTitle="Groups"
			[showActions]="true">
</groups-popup-c>

<aliases-popup-c *ngIf="showAliases"
				 (cancelCb)="getMailboxesAliases(false)"
				 popupTitle="Aliases"
				 [showActions]="true">
</aliases-popup-c>

<!--Integration popup: Gsuite-->
<popup-c *ngIf="gsuiteConfigPopup?.show"
		 head="Configure Google Workspace" size="large"
		 (closeCb)="gsuiteConfigPopup = null;"
		 (confirmCb)="connectGSuite();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Connect', loading: integrationActionInProcess}}">
	<div class=" flxClmn f-1-0" style="overflow: auto;">
		<span style="font-size: 16px;">Manually configure a connection with Google Workspace&nbsp;&nbsp;*</span>
		<br/><br/>
		<div class="flxClmn f-1-0" style="overflow: auto; background: rgba(0,0,65,0.007);">
			<p style="line-height: 1.45rem; margin-block-end: 0;">
				Please follow instructions below :<br/>
				1. Log in to your  <a style="color: cornflowerblue; font-weight: bold;" href="https://admin.google.com/">Google Admin Console</a>. <br/>
				2. Click on <strong>Security</strong> <span style="font-weight: 700; margin: 0 2px; color: lightslategrey;">❯</span> <strong>API Controls</strong>.<br/>
				3. Click on the link <strong>Manage Domain Wide Delegation</strong> at the bottom of the page.<br/>
				4. In <strong>API Clients</strong> table, Click on <strong>Add New</strong>.<br/>
				5. Copy the following code and paste it in <strong>Client ID</strong> input area :
			</p>
			<div style="align-items: center; margin:7px 15px;" class="flxRow-start hSpace2">
				<div style="font-size: 16px; width: 45%; background: #bac9d642; height: 30px; align-items: center;" class="flxClmn-center">
					<span style="font-weight:500; overflow: auto; width: 100%; text-align: center;" class="hide-scrollbar click-select-all">
						{{planInfo.googleAdminAppId}}
					</span>
				</div>
				<a (click)="copyText(planInfo.googleAdminAppId);">
					<i class="far fa-copy copy-link" tooltip="Copy client ID"></i>
				</a>
			</div>
			<p style="line-height: 1.45rem; margin: 0;">
				6. Copy the following text and paste it in <strong>OAuth scopes</strong> input area :
			</p>
			<div style="align-items: center; margin:7px 15px;" class="flxRow-start hSpace2">
				<div style="font-size: 16px; width: 45%; background: #bac9d642; height: 30px; align-items: flex-start; padding: 0 10px;" class="flxClmn-center">
					<span style="font-weight:500; overflow: auto; width: 100%;" class="hide-scrollbar click-select-all">
						{{googleScopesLine}}
					</span>
				</div>
				<a (click)="copyText(googleScopes);">
					<i class="far fa-copy copy-link" tooltip="Copy scopes"></i>
				</a>
			</div>
			<p style="line-height: 1.45rem; margin: 0;">
				7. Click <strong>Authorize</strong>.<br/><br/>
				To get your Entity ID :<br/>
				1. Go back again to <a style="color: cornflowerblue; font-weight: bold;" href="https://admin.google.com/">Google Admin Console</a> home page.<br/>
				2. Click on <strong>Security</strong> <span style="font-weight: 700; margin: 0 2px; color: lightslategrey;">❯</span> <strong>Set up single sign-on (SSO) for SAML applications</strong>.<br/>
				3. Copy the last part of the <strong>Entity ID</strong> URL.<br/>
				Example:
				<span style="background: #bac9d642; align-items: center; display: inline-block; margin: 5px; padding: 2px 8px;" class="flxClmn-center">
					<span style="" class="hide-scrollbar">https://accounts.google.com/o/saml2?idpid=</span>
					<span style="color: #ec407a; font-weight: 600;" class="hide-scrollbar">XXXXXXXXX</span>
					<span style="opacity: 0.6; font-size: 12px;" class="hide-scrollbar">&nbsp;&nbsp;⬅ your client ID</span>
				</span>
			</p>
		</div>
		<hr style="margin:0;"/>
		<div class="flxRow"></div>
		<a target="_blank" rel="noopener noreferrer" href="https://docs.trustifi.com/docs/importing-groups">
			<span style="float: right; color: cornflowerblue; margin: 6px 5px;">
				For a full guide with screenshots click here
			</span>
			<span style="font-size: 16px; color: cornflowerblue; float: right; margin-top: 6px;">*</span>
		</a>
		<br/>
		<span>Enter your email and the Entity ID you copied before and click <b>Connect</b></span>
		<br/>
		<div>
			<table class="simpleDataTableShrink middle">
				<tr>
					<td>
						<span>Admin Email :</span>
					</td>
					<td>
						<input style="width:200px;" type="text"
							   [ngClass]="{'validation-error' : gsuiteConfigPopup.inputErrors.gsuiteAdminEmail}"
							   [(ngModel)]="gsuiteConfigPopup.gsuiteAdminEmail">
					</td>
				</tr>
				<tr>
					<td>
						<span>Entity ID :</span>
					</td>
					<td>
						<input style="width:200px;" type="text"
							   [ngClass]="{'validation-error' : gsuiteConfigPopup.inputErrors.idInput}"
							   [(ngModel)]="gsuiteConfigPopup.gsuiteCustomerId">
					</td>
				</tr>
			</table>
		</div>
		<hr/>
	</div>
</popup-c>

<!--Integration popup: Open ID-->
<popup-c *ngIf="openIDConfigPopup?.show"
		 head="Configure Integration with OIDC" size="large" height="auto"
		 (closeCb)="openIDConfigPopup = null;"
		 (confirmCb)="connectOpenIDExecute();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Connect', loading: integrationActionInProcess}}">
	<div class="flxClmn">
		<span style="font-size: 16px;">Integrate your identity provider with {{gs.corpname}} using OIDC by following the instructions below</span>
		<!--                    <span style="font-size: 16px; margin-left: 3px; color: cornflowerblue" class="">*</span>-->
		<br/><br/>
		<div class="list-group list-group-flush">
			<div class="list-group-item">
				<p style="line-height: 1.45rem; margin-block-end: 0;">
					1. Enter the inputs below in your identity provider's configuration:
				</p>
				<div *ngFor="let redirect of openIDRedirectUrl; index as index;" style="align-items: center; margin:7px 15px;" class="flxRow-start hSpace2">
					<i class="fas fa-circle" style="font-size: 0.5em;"></i>
					<div style="font-size: 16px; width: auto; background: #bac9d642; height: 30px; align-items: center;" class="flxClmn-center">
						<span style="font-weight:500; overflow: auto; width: 100%; text-align: center;" class="hide-scrollbar click-select-all">{{redirect.url}}</span>
					</div>
					<a (click)="copyText(openIDRedirectUrl[index].url); $event.stopPropagation();"><i class="far fa-copy copy-link" tooltip="Copy URI"></i></a>
					<span style="font-size:0.9em;">({{redirect.description}})</span>
				</div>
			</div>
			<div class="list-group-item">
				<p style="line-height: 1.45rem; margin-block-end: 0;">
					2. Save the app integration. The new app integration should appear in your admin dashboard.
				</p>
			</div>
			<div class="list-group-item flex-column gap-2">
				<p style="line-height: 1.45rem; margin-block-end: 0;">
					3. Enter below the required information from your authentication service provider and click <b>Confirm</b>
				</p>
				<div class="align-items-center d-flex gap-2">
					<div class="col-4">
						<span>Discovery Document ("well-known") Endpoint: </span>
					</div>
					<input style="width:350px; margin: 0;" type="text" [(ngModel)]="openIDConfigPopup.openIDMetadata">
				</div>
				<div class="align-items-center d-flex gap-2">
					<div class="col-4">
						<span>Client ID: </span>
					</div>
					<input style="width:350px; margin: 0;" type="text" [(ngModel)]="openIDConfigPopup.openIDClientId">
				</div>
				<div class="align-items-center d-flex gap-2">
					<div class="col-4">
						<span>Domains: </span>
					</div>
					<div class="flxRow hSpace2" style="align-items: center;">
						<input style="width:350px; margin: 0;" type="text" [(ngModel)]="openIDConfigPopup.openIDDomainsStr" placeholder="domains seperated by comma">
						<i style="font-weight: 400; font-size: medium; position: relative;" class="fas fa-question-circle"
						   tooltip="Only users from these domains will be able to log in using OIDC" placement="right"></i>
					</div>
				</div>
			</div>
			<div class="list-group-item">
				<span>Full integration guides: </span>
				<a target="_blank" href="https://docs.trustifi.com/docs/open-id-integration-with-okta" style="color: cornflowerblue;">Okta</a>
				,
				<a target="_blank" href="https://docs.trustifi.com/docs/open-id-integration-with-azure" style="color: cornflowerblue;">Azure</a>
			</div>
		</div>
	</div>
</popup-c>

<!--Integration popup: SAML-->
<popup-c *ngIf="samlConfigPopup?.show"
		 head="Configure Integration with SAML" size="large" height="auto"
		 (closeCb)="samlConfigPopup = null;"
		 (confirmCb)="connectSamlExecute();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Connect', loading: integrationActionInProcess}}">
	<div class="flxClmn">
		<span style="font-size: 16px;">Integrate your identity provider with {{gs.corpname}} using SAML by following the instructions below</span>
		<!--                    <span style="font-size: 16px; margin-left: 3px; color: cornflowerblue" class="">*</span>-->
		<br/><br/>
		<div class="list-group list-group-flush">
			<div class="list-group-item">
				<p style="line-height: 1.45rem; margin-block-end: 0;">
					1. Enter the inputs below in your identity provider's configuration:
				</p>
				<div style="align-items: center; margin:7px 15px;" class="flxRow-start hSpace2">
					Audience URI (Entity ID):
					<div style="font-size: 16px; width: 45%; background: #bac9d642; height: 30px; align-items: center;" class="flxClmn-center">
						<span style="font-weight:500; overflow: auto; width: 100%; text-align: center;" class="hide-scrollbar click-select-all">{{samlAudience}}</span>
					</div>
					<a (click)="copyText(samlAudience);">
						<i class="far fa-copy copy-link" tooltip="Copy Audience"></i>
					</a>
				</div>
				<div style="align-items: center; margin:7px 15px;" class="flxRow-start hSpace2">
					Reply URL (Assertion Consumer Service URL):
					<div style="font-size: 16px; width: 45%; background: #bac9d642; height: 30px; align-items: center;" class="flxClmn-center">
						<span style="font-weight:500; overflow: auto; width: 100%; text-align: center;" class="hide-scrollbar click-select-all">{{samlRedirectUrl}}</span>
					</div>
					<a (click)="copyText(samlRedirectUrl);">
						<i class="far fa-copy copy-link" tooltip="Copy URI"></i>
					</a>
				</div>
				<div style="align-items: center; margin:7px 15px;" class="flxRow-start hSpace2">
					Logout URL (Optional):
					<div style="font-size: 16px; width: 45%; background: #bac9d642; height: 30px; align-items: center;" class="flxClmn-center">
						<span style="font-weight:500; overflow: auto; width: 100%; text-align: center;" class="hide-scrollbar click-select-all">{{samlLogoutUrl}}</span>
					</div>
					<a (click)="copyText(samlLogoutUrl);">
						<i class="far fa-copy copy-link" tooltip="Copy URI"></i>
					</a>
				</div>
			</div>
			<div class="list-group-item">
				<p style="line-height: 1.45rem; margin: 0;">
					2. After the inputs above have been added, export/download the identity provider's metadata XML.
				</p>
			</div>
			<div class="list-group-item">
				<span>3. Upload the metadata XML below.</span>
				<div class="align-items-center d-flex gap-2" style="margin: 0.8rem 0;">
					<span style="line-height: 1;">Identity Provider Metadata XML: </span>
					<div class="link-btn" style="color: #4F77BC; cursor: pointer;"
						 accept="xml"
						 ngfSelect
						 (fileChange)="readSamlXml()"
						 [(file)]="samlConfigPopup.samlMetadataFile">
						Upload file
						<i class="fas fa-file-upload"></i>
					</div>
					<span>{{samlConfigPopup.samlMetadataFile.name}}</span>
				</div>
			</div>
			<div class="list-group-item">
				<span>4. Enter your domains and click <b>Confirm</b></span>
				<div class="align-items-center d-flex gap-2" style="margin: 0.8rem 0;">
					<span>Domains: </span>
					<div class="flxRow hSpace2" style="align-items: center;">
						<input style="width:350px; margin: 0;" type="text" [(ngModel)]="samlConfigPopup.samlDomainsStr" placeholder="domains seperated by comma">
						<i style="font-weight: 400; font-size: medium; position: relative;" class="fas fa-question-circle" placement="right"
						   tooltip="Only users from these domains will be able to log in using SAML"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="list-group-item">
			<span>Full integration guides: </span>
			<a target="_blank" href="https://docs.trustifi.com/docs/saml-integration-with-okta" style="color: cornflowerblue;">Okta</a>
			,
			<a target="_blank" href="https://docs.trustifi.com/docs/saml-integration-with-azure" style="color: cornflowerblue;">Azure</a>
		</div>
	</div>
</popup-c>

<!--Integration popup: LDAP-->
<popup-c *ngIf="ldapConnectPopup?.show"
		 head="Connect to LDAP" size="dynamic"
		 (closeCb)="ldapConnectPopup = null;"
		 (confirmCb)="connectLdapExecute();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Connect',
											 disabled: !ldapConnectPopup.ldapUsername || !ldapConnectPopup.ldapPassword || !ldapConnectPopup.ldapEndpoint || !ldapConnectPopup.ldapBaseDN || !ldapConnectPopup.ldapPort,
											 loading: integrationActionInProcess}}">
	<div class="flxClmn">
		<span>Enter the following information in order to connect {{gs.corpname}} with your LDAP</span>
		<br/><br/>
		<table class="simpleDataTableShrink middle">
			<tr>
				<td><b>Username:&nbsp;</b></td>
				<td>
					<input style="width:100%; margin: 0;"
						   type="text"
						   [(ngModel)]="ldapConnectPopup.ldapUsername">
				</td>
			</tr>
			<tr>
				<td><b>Password:&nbsp;</b></td>
				<td>
					<div class="flxRow-start">
						<input style="width:100%; margin: 0;"
							   type="text"
							   [(ngModel)]="ldapConnectPopup.ldapPassword"
							   [ngClass]="{'nofill': ldapConnectPopup.ldapPassword && !showPassword}">
						<label style="opacity: 0.2; display: flex; cursor: pointer; align-items: center; margin: 0 0 0 -20px;" class="hidepwd">
							<input type="checkbox" [(ngModel)]="showPassword"/>
							<i class="fa fa-eye unchecked"></i>
							<i class="fa fa-eye-slash checked"></i>
						</label>
					</div>
				</td>
			</tr>
			<tr>
				<td><b>Base DN:&nbsp;</b></td>
				<td>
					<input style="width:100%; margin: 0;"
						   type="text"
						   [(ngModel)]="ldapConnectPopup.ldapBaseDN">
				</td>
			</tr>
			<tr>
				<td><b>Host:&nbsp;</b></td>
				<td>
					<input style="width:100%; margin: 0;"
						   type="text"
						   [(ngModel)]="ldapConnectPopup.ldapEndpoint">
				</td>
			</tr>
			<tr>
				<td><b>Port:&nbsp;</b></td>
				<td>
					<input style="width:100%; margin: 0;"
						   type="number"
						   [(ngModel)]="ldapConnectPopup.ldapPort">
				</td>
			</tr>
		</table>
		<br/>
		<div class="flxRow-start hSpace1 md-checkbox" style="padding: 0; align-items: center;">
			<input id="securedConnectionOnly" required="false" type="checkbox"
				   [(ngModel)]="ldapConnectPopup.ldapSecure"/>
			<label style="margin: 0;" for="securedConnectionOnly">Allow secure connection only</label>
		</div>
	</div>
</popup-c>

<!--Integration popup: Syslog-->
<popup-c *ngIf="syslogConnectPopup?.show" height="550px"
		 head="Connect to Syslog Server" size="dynamic"
		 (closeCb)="syslogConnectPopup = null;"
		 (confirmCb)="connectSyslogExecute();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Connect',
											 disabled: !syslogConnectPopup.host || !syslogConnectPopup.port,
											 loading: integrationActionInProcess}}">
	<div class="flxClmn">
		<span>Enter the following information in order to create a Syslog connection with {{gs.corpname}}</span>
		<br/>
		<span>We send messages from static IPs (3.227.182.193, 54.161.96.109) when Syslog is connected to TCP protocol.</span>
		<br/>
		<br/>
		<table class="simpleDataTableShrink middle">
			<tr>
				<td><b>Host:&nbsp;</b></td>
				<td>
					<input style="width:100%; margin: 0;"
						   type="text"
						   [(ngModel)]="syslogConnectPopup.host">
				</td>
			</tr>
			<tr>
				<td><b>Port:&nbsp;</b></td>
				<td>
					<input style="width:100%; margin: 0;"
						   type="number"
						   [(ngModel)]="syslogConnectPopup.port">
				</td>
			</tr>
			<tr>
				<td><b>Protocol:&nbsp;</b></td>
				<td>
					<app-dropdown-c style="width:210px; max-height:150px; display:block; text-transform: uppercase;" [text]="syslogConnectPopup.protocol">
						<li *ngFor="let entry of syslogProtocols" style="text-transform: uppercase;"
							 (click)="syslogConnectPopup.protocol = entry">{{ entry }}</li>
					</app-dropdown-c>
				</td>
			</tr>
		</table>
	</div>
</popup-c>

<!--Integration popup: Splunk-->
<popup-c *ngIf="splunkConnectPopup?.show"
		 head="Connect to Splunk Server" size="dynamic"
		 (closeCb)="splunkConnectPopup = null;"
		 (confirmCb)="connectSplunkExecute();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Connect',
											 disabled: !splunkConnectPopup.host || !splunkConnectPopup.token,
											 loading: integrationActionInProcess}}">
	<div class="flxClmn">
		<span>Enter the following information in order to create a Splunk connection with {{gs.corpname}}</span>
		<br/>
		<br/>
		<table class="simpleDataTableShrink middle">
			<tr>
				<td><b>Host:&nbsp;</b></td>
				<td>
					<input style="width:100%; margin: 0;"
						   type="text"
						   [(ngModel)]="splunkConnectPopup.host">
				</td>
			</tr>

			<tr>
				<td><b>Token:&nbsp;</b></td>
				<td>
					<input style="width:100%; margin: 0;"
						   type="text"
						   [(ngModel)]="splunkConnectPopup.token">
				</td>
			</tr>
		</table>
	</div>
</popup-c>

<!--Integration popup: Azure EventHub-->
<popup-c *ngIf="azureEventHubConnectPopup?.show"
		 head="Connect to Azure EventHub Server" size="dynamic"
		 (closeCb)="azureEventHubConnectPopup = null;"
		 (confirmCb)="connectToAzureEventHubExecute();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Connect', disabled: !azureEventHubConnectPopup.connection, loading: integrationActionInProcess}}">
	<div class="flxClmn gap-2">
		<span>Enter the following information in order to create an Azure connection with {{gs.corpname}}</span>
		<br/>
		<br/>

		<span>Paste your Azure connection string:</span>
		<input style="width:100%; margin: 0;" placeholder="Endpoint=..." type="text" [(ngModel)]="azureEventHubConnectPopup.connection">

	</div>
</popup-c>

<!--Integration popup: CheckPoint-->
<popup-c *ngIf="checkpointConnectPopup?.show"
		 head="Connect to Check Point" size="dynamic"
		 (closeCb)="checkpointConnectPopup = null;"
		 (confirmCb)="connectCheckpointExecute();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Connect',
											 disabled: !checkpointConnectPopup.checkpointAccessKey || !checkpointConnectPopup.checkpointClientID,
											 loading: integrationActionInProcess}}">
	<div class="flxClmn vSpace2">
		<span>Enter the following information in order to connect {{gs.corpname}} with Check Point Harmony Threat Hunting</span>
		<table class="simpleDataTableShrink middle">
			<tr>
				<td><b>Client ID:&nbsp;</b></td>
				<td>
					<input style="width: 100%; margin: 0;"
						   type="text"
						   [(ngModel)]="checkpointConnectPopup.checkpointClientID">
				</td>
			</tr>
			<tr>
				<td><b>Access Key:&nbsp;</b></td>
				<td>
					<input style="width: 100%; margin: 0;"
						   type="text"
						   [(ngModel)]="checkpointConnectPopup.checkpointAccessKey">
				</td>
			</tr>
		</table>
	</div>
</popup-c>

<!--Integration popup: Amazon S3-->
<popup-c *ngIf="S3ConnectPopup?.show"
		 head="Connect to Amazon S3" size="dynamic"
		 (closeCb)="S3ConnectPopup = null;"
		 (confirmCb)="connectS3Execute();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Connect',
											 disabled: !validateS3(),
											 loading: integrationActionInProcess}}">
	<div class="flxClmn">
		<div class="vSpace05" style="line-height: 1.6;">
			<span>To integrate Trustifi with your own {{gs.corpname}} S3 buckets you need to:</span>
			<ol>
				<li>Create 4 buckets with no public access and enable SSE encryption</li>
				<li>Delegate access to our AWS account: <b>775278833801</b>. <br/>(you can follow the first step from this guide:
					<a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/tutorial_cross-account-with-roles.html" target="_blank" rel="tutorial-cross-account-with-role">tutorial-cross-account-with-role</a>)</li>
				<li>Enter the relevant information here</li>
			</ol>
		</div>
		<br/><br/>
		<table class="simpleDataTableShrink middle">
			<tr>
				<td style="width: 175px;"><b>Remote Account ID:&nbsp;</b></td>
				<td>
					<input type="text" style="width:100%; margin: 0;" [(ngModel)]="S3ConnectPopup.remote_account_id">
				</td>
			</tr>
			<tr>
				<td><b>Remote Role:&nbsp;</b></td>
				<td>
					<input type="text" style="width:100%; margin: 0;" [(ngModel)]="S3ConnectPopup.remote_role">
				</td>
			</tr>
			<tr>
				<td><b>Use your own server-side encryption:&nbsp;</b></td>
				<td>
					<div class="flxRow-start md-checkbox">
						<input id="use_predefined_sse" type="checkbox" style="height: auto;"
							   [(ngModel)]="S3ConnectPopup.use_predefined_sse"/>
						<label for="use_predefined_sse"></label>
					</div>
				</td>
			</tr>
			<tr>
				<td><b>Attachments Bucket:&nbsp;</b></td>
				<td><input type="text" style="width:100%; margin: 0;" [(ngModel)]="S3ConnectPopup.attachments"/></td>
			</tr>
			<tr>
				<td><b>Content Bucket:&nbsp;</b></td>
				<td><input type="text" style="width:100%; margin: 0;" [(ngModel)]="S3ConnectPopup.content"/></td>
			</tr>
			<tr>
				<td><b>Quarantined Bucket:&nbsp;</b></td>
				<td><input type="text" style="width:100%; margin: 0;" [(ngModel)]="S3ConnectPopup.quarantinedEmail"/></td>
			</tr>
			<tr>
				<td><b>Archiving Bucket:&nbsp;</b></td>
				<td><input type="text" style="width:100%; margin: 0;" [(ngModel)]="S3ConnectPopup.archiving"/></td>
			</tr>
		</table>
	</div>
</popup-c>

<popup-c class="bs-enabled" *ngIf="changeEndPointPopup?.show"
		 head="Change cloud API endpoint" size="dynamic"
		 (closeCb)="changeEndPointPopup.show = false;"
		 (confirmCb)="changeEndPointPopup?.doneCb()"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Change', disabled: changeEndPointPopup.applyInProcess}}">
	<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
		<div class="d-flex gap-1 text-danger pt-2" style="font-size: 14px;">
			<i class="zmdi zmdi-alert-circle-o" style="font-size: 1.5rem; margin-top: -6px;"></i>
			{{changeEndPointPopup.subTitle}}
		</div>

		<ul>
			<li>Make sure you have selected the correct endpoint. Selecting an incorrect endpoint can cause communication failures with the cloud API.</li>
		</ul>

		<!--will show only when selecting exchangeServerEndpoints.Hybrid-->
		<div class="align-items-center flex-row gap-3" *ngIf="changeEndPointPopup.azureADInput !== undefined">
			<div style="min-width: 120px;">Azure AD</div>
			<input type="text" placeholder="Azure AD endpoint"
				   style="width:330px;" [required]="true"
				   [(ngModel)]="changeEndPointPopup.azureADInput"/>
		</div>
		<div class="align-items-center flex-row gap-3" *ngIf="changeEndPointPopup.microsoftGraphAPIInput !== undefined">
			<div style="min-width: 120px;">Microsoft Graph API</div>
			<input type="text" placeholder="Microsoft Graph API endpoint"
				   style="width:330px;" [required]="true"
				   [(ngModel)]="changeEndPointPopup.microsoftGraphAPIInput"/>
		</div>
	</div>

</popup-c>

<!--exchange powershell login wizard popup-->
<exchange-login-wizard-c
	*ngIf="exchangePowershellPopup[exchangePowershellCommands.login]?.show"
	[planInfo]="planInfo"
	(closePopupFn)="closeExchangePowershellPopup(exchangePowershellCommands.login, $event.success, $event.disconnected)">
</exchange-login-wizard-c>

<!--exchange get quarantined emails wizard popup-->
<!-- exchange-quarantined-emails-wizard-c
	*ngIf="exchangePowershellPopup[exchangePowershellCommands.quarantine]?.show"
	(closePopupFn)="closeExchangePowershellPopup(exchangePowershellCommands.quarantine, $event.success, $event.disconnected)"
	[isInboundConfiguration]="isInbound">
</exchange-quarantined-emails-wizard-c -->

<!--exchange rules wizard popup-->
<exchange-rules-wizard-c
	*ngIf="exchangePowershellPopup[exchangePowershellCommands.rules]?.show"
	(closePopupFn)="closeExchangePowershellPopup(exchangePowershellCommands.rules, $event.success, $event.disconnected)"
	[domains]="exchangePowershellPopup[exchangePowershellCommands.rules]?.domains"
	[isInboundConfiguration]="isInbound">
</exchange-rules-wizard-c>

<!--review exchange cfg wizard popup-->
<view-exchange-cfg-wizard-c
	*ngIf="exchangePowershellPopup[exchangePowershellCommands.configuration]?.show"
	(closePopupFn)="closeExchangePowershellPopup(exchangePowershellCommands.configuration, $event.success, $event.disconnected)"
	[isInboundConfiguration]="isInbound">
</view-exchange-cfg-wizard-c>

<!--update exchange rules wizard popup-->
<exchange-update-rules-wizard-c
	*ngIf="exchangePowershellPopup[exchangePowershellCommands.updateRules]?.show"
	(closePopupFn)="closeExchangePowershellPopup(exchangePowershellCommands.updateRules, $event.success, $event.disconnected)"
	[isInboundConfiguration]="isInbound">
</exchange-update-rules-wizard-c>

<!--disable exchange protection wizard popup-->
<exchange-protection-wizard-c
	*ngIf="exchangePowershellPopup[exchangePowershellCommands.protection]?.show"
	(closePopupFn)="closeExchangePowershellPopup(exchangePowershellCommands.protection, $event.success, $event.disconnected)">
</exchange-protection-wizard-c>
