import {DICTIONARY} from '../../../../dictionary';
import {AuthService} from '../../../../services/authService';
import {GeneralService} from '../../../../services/generalService';
import {NotificationService} from '../../../../services/notificationService';
import {RouteService} from '../../../../services/routeService';
import {Component, OnInit} from "@angular/core";
import _ from "lodash";

@Component({
	selector: 'my-profile-component',
	templateUrl: './my-profile.component.html',
})
export class MyProfileComponent implements OnInit {
    country_code = this.gs.country_code;
    dic = DICTIONARY;
    corpName = this.gs.corpname;
    userInfo: any;
    editProfile;
	timeZones = this.gs.getAllTimeZones();
	selectedTimeZone;
    updateAccountDetailsInProcess: any;
	deleteAccountPopup;

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private authService:AuthService,
				private ns:NotificationService,) {

    }

    ngOnInit() {
        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
			if (this.userInfo.timezone) {
				this.selectedTimeZone = this.timeZones.find(itm => itm.zone === this.userInfo.timezone);
			}
        });
	}

	updateUserTimezone = (timezoneObj) => {
		this.selectedTimeZone = timezoneObj;
		const actionInfo = {action: this.dic.CONSTANTS.userProfileActions.timezone, timezone: timezoneObj.zone};
		this.rs.updateUserSettings(actionInfo).then(() => {
			this.userInfo.timezone = timezoneObj.zone
			this.ns.showInfoMessage(this.dic.MESSAGES.userDetailsAccount);
		});
	}


    editProfileDetails = () => {
		const nameParts = this.userInfo.name?.split(' ') || [];
        this.editProfile = {
            firstName: nameParts[0] || '',
            lastName: nameParts[1] || '',
            email: this.userInfo.email || '',
            phone: _.clone(this.userInfo.from[0].phone) || {country_code: '', phone_number: ''}
        };
    }

    confirmEditAccountDetails = (isApproved) => {
        if (this.updateAccountDetailsInProcess) {
            return;
        }

        // edit was cancelled
        if (!isApproved) {
            this.editProfile = null;
            return;
        }

        if (!this.editProfile.firstName || !this.editProfile.lastName) {
            this.ns.showErrorMessage(this.dic.ERRORS.profileNeededInfo);
            return;
        }

        const editData = {
            action: this.dic.CONSTANTS.fromActions.edit,
            fromDetails: this.editProfile
        };

		this.updateAccountDetailsInProcess = true;

        this.rs.updateAccountEmail(this.userInfo.email, editData).then((response) => {
			this.gs.primaryNameChangeSubj.next(this.userInfo);
            this.userInfo.from[0].phone = editData.fromDetails.phone;
			if (this.userInfo.from[0].phone.phone_number) {
				this.userInfo.from[0].phone.phone_number = this.userInfo.from[0].phone.phone_number.replace(/^0+/, '');
			}
            this.userInfo.name = `${this.editProfile.firstName} ${this.editProfile.lastName}`;

            this.updateAccountDetailsInProcess = false;
            this.editProfile = null;

            this.ns.showInfoMessage(this.dic.MESSAGES.userDetailsAccount);
        },(err) => {
            this.updateAccountDetailsInProcess = false;
        });
    }

    openDeleteAccountPopup = () => {
		this.deleteAccountPopup = {
			show: true,
			applyInProcess: false,
			verifyEmail: '',
			doneCb: () => {
				this.deleteAccountPopup.applyInProcess = true;
				this.rs.deleteUserAccount(this.deleteAccountPopup.verifyEmail).then((response) => {
					this.deleteAccountPopup.applyInProcess = false;
					this.authService.logout();
				}, (err) => {
					this.deleteAccountPopup.applyInProcess = false;
				});
			}
		};
    }
}
