import {Injectable} from "@angular/core";
import _ from "lodash";
import {GeneralService} from "./generalService";
import {DICTIONARY} from "../dictionary";

@Injectable({
	providedIn: 'root'
})
export class QuickAccessService {

	constructor(
		private gs: GeneralService
	) {}

	_ = _;
	dic = DICTIONARY;
	appPagesNaviData;

	defaultSystemPages = {
		adminPro: [
			"inbound/inboundQuarantined",
			"inbound/emailTrace",
			"inbound/inboundShield/threatPreventionRules",
			"outbound/emailTrace",
			"outbound/adminPolicyManagement/rules",
			"archive/archive",
		],
		userPro: [],
		free: [
			"personalPages/composeMessage",
			"personalPages/mailbox",
			"personalPages/inbox",
			"personalPages/storage",
			"accountDetails/myAccount/myProfile",
			"accountDetails/myPlan"
		]
	}
	userDefaultPages;

	initializeQuickAccessPages() {
		this.setDefaultPages(this.gs.userInfo);

		const topVisitedPages = [];

		try {
			// Retrieve page visit counts from localStorage
			const userVisitedPages = this.getPagesFromLocalStorage();


			if (userVisitedPages['recent']?.length) {
				userVisitedPages['recent'].forEach((page) => {
					topVisitedPages.push(page);
				});
			}

			// Extract and sort the popular pages based on visit count in descending order
			const popularPagesSorted = Object.entries(userVisitedPages['popular'])
				.sort((a, b) => Number(b[1]) - Number(a[1])) // Sort by visit count (highest first)
				.map(([page]) => page); // Extract only the page names

			if (popularPagesSorted?.length) {
				popularPagesSorted.forEach((page) => {
					if (!_.includes(topVisitedPages, page)) {
						topVisitedPages.push(page);
					}
				});
			}

			this.userDefaultPages.forEach((page) => {
				if (!_.includes(topVisitedPages, page)) {
					topVisitedPages.push(page);
				}
			});
		}
		catch (ex) {
			console.error(ex);
		}

		return topVisitedPages.slice(0, 6);
	}

	countVisitPages(url) {
		if (url === 'home' || url === 'dummy') {
			return;
		}

		const visitedPages = this.getPagesFromLocalStorage();

		// Increment visit count for the current page
		visitedPages['popular'][url] = (visitedPages['popular'][url] || 0) + 1;

		if (!_.includes(visitedPages['recent'], url)) {
			visitedPages['recent'] = _.chain([url])
				.concat(visitedPages['recent'])
				.take(2)
				.value();
		}

		// Save updated visit counts back to localStorage
		this.updatePagesInLocalStorage(visitedPages);
	}

	getQuickAccessPages = () => {
		const quickAccessPages = this.initializeQuickAccessPages();

		return _.chain(quickAccessPages)
			.map((page) => {
				const pageSegments = page.split('/'); // Split the URL into segments
				const section = _.find(this.appPagesNaviData, { state: pageSegments[0] });

				if (!section) return null; // If section not found, return null

				const pageObj = _.find(section.pages, { page: pageSegments[1] });
				const tabObj = pageObj?.innerTabs ? _.find(pageObj.innerTabs, { tab: pageSegments[2] }) : null;

				return {
					sectionTitle: section.title,
					pageTitle: pageObj?.title || null,
					tabTitle: tabObj?.title || null,
					iconClass: section.iconClass || null,
					url: page, // Keep original URL
				};
			})
			.compact() // Removes null or undefined values
			.value();
	};

	getPagesFromLocalStorage() {
		const visitedPages = JSON.parse(localStorage.getItem('quickAccessPages') || '{}');
		if (!_.has(visitedPages, 'popular')) {
			visitedPages['popular'] = {};
		}
		if (!_.has(visitedPages, 'recent')) {
			visitedPages['recent'] = [];
		}

		return visitedPages;
	}

	updatePagesInLocalStorage(pages) {
		localStorage['quickAccessPages'] = JSON.stringify(pages);
	}

	setDefaultPages(userInfo) {
		if (userInfo.user_role === this.dic.CONSTANTS.userRole.subAdmin || userInfo.user_role === this.dic.CONSTANTS.userRole.admin) {
			this.userDefaultPages = this.defaultSystemPages['adminPro'];
			return;
		}

		if (userInfo.plan?.plan === this.dic.CONSTANTS.planTypePro && userInfo.user_role === this.dic.CONSTANTS.userRole.user) {
			this.userDefaultPages = this.defaultSystemPages['userPro'];
			if (userInfo.isInboundReviewer) {
				this.userDefaultPages = this.userDefaultPages.concat([
					'inbound/inboundQuarantined',
					'inbound/emailTrace',
				]);
			}
			else {
				this.userDefaultPages = this.userDefaultPages.concat([
					'inbound/userQuarantinedInboundConf',
					'inbound/userInboundConf/senderLists',
					'inbound/userInboundConf/linkLists',
					'inbound/userInboundConf/attachmentLists'
				]);
			}
			if (userInfo.isOutboundReviewer) {
				this.userDefaultPages = this.userDefaultPages.concat([
					"outbound/outboundQuarantined",
					'outbound/emailTrace'
				]);
			}
			if (userInfo.isArchiveReviewer) {
				this.userDefaultPages = this.userDefaultPages.concat([
					'archive/archive'
				]);
			}

			this.userDefaultPages = this.userDefaultPages.concat([
				"personalPages/inbox",
				"personalPages/mailbox",
				"accountDetails/myAccount/myProfile",
				"accountDetails/myPlan",
			]);
		}
		else {
			this.userDefaultPages = this.defaultSystemPages['free'];
		}
	}
}
