import { GeneralService } from "./generalService";
import {Injectable} from "@angular/core";


const cardFilter = /\b(((4\d{3})|(5[1-5]\d{2})|(\d{4})|(34\d{1})|(37\d{1}))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7][\d\s-]{15}|(?:3[47][0-9]{13}))\b/; // Credit Cards
const keywordFilter = /\b(?:password|classified|top secret|non disclosure|sobpoena|billing|bank (?:account|information)|pin code|social security|ssn|passport|visa|master(?:\s|)card|american express|card(?:\s|)holder(?:s|)|credit(?:\s|)card|product key|payment(?:\s|)card|card(?:\s|)number|confidential|iban)\b/; // Keywords
const ssnFilter = /\b(?:ss|sn|ssn|ssns|social security|immigration|visa|petition).*\d{3}-\d{2}-\d{4}\b/; // Social Security Number
const uspFilter = /\b(?:passport).*([0-9]{9})\b/; // Passports (US/IL)
const hipaaFilter = /\b(?:blood|phi|patient|health|medical|hospital|medicine|doctor|accident|prescription|nino|health(?:\s|)care|nurse|drug(?:s|)|prescription(?:s|)|pharmacy|health(?:care|)|npi|insurance|hipaa|dea)\b/; // HIPAA
const chiFilter = /\b([A-CEGHJ-NOPR-TW-Z]{2}([?\s|-])([0-9]{6}([?\s|-])|[0-9]{2}([?\s|-])[0-9]{2}([?\s|-])[0-9]{2})([?\s|-])[ABCD\s]|[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z][0-9]{6}[A-DFM]?)\b/; // United Kingdom national insurance number
const ibanFilter = /\b[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}\b/; // IBAN Number (International Bank Account Number)
const npiFilter = /\b80840\d{10}\b/; // NPI Number (Health Identification Card Number)
const prodkeyFilter = /\b([A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5})\b/; // Product key

@Injectable({
    providedIn: 'root'
})
export class ComposeMessageService {
    clio = {enabled: false};
    dropbox = {enabled: false};

    constructor(private gs:GeneralService) {}

	autoSaveMessage;


    stripCSSStyles(html) {
        if (!html) return {html: '', css: []};

        let styles = [];
        let htmlNoCSS = html.replace(/<script[^<>]*?>[\s\S]+?<\/script>/gi, '');
        htmlNoCSS = htmlNoCSS.replace(/<style[^<>]*?>[\s\S]+?<\/style>/gi, function (match) {
            styles.push(match);
            return '';
        });

        return {html: htmlNoCSS, css: styles};
    }

    checkHIPAA(html) {
        if (!html) return false;
        let hipaa = html.toLowerCase();
        let hipaaHTML = hipaa.replace(/(\r\n|\n|\r|&nbsp;|\s+|<\/?[^>]+>)/gm, " ").replace(/(\s+)/gm, " ");
        return !!(hipaaHTML.match(keywordFilter) || hipaaHTML.match(cardFilter) || hipaaHTML.match(ssnFilter) || hipaaHTML.match(uspFilter) || hipaaHTML.match(hipaaFilter) || hipaaHTML.match(chiFilter) || hipaaHTML.match(ibanFilter) || hipaaHTML.match(npiFilter) || hipaaHTML.match(prodkeyFilter));
    }

    updateSummernoteContent(html, resetBackColor=false) {
        let summernote:any = this.gs.getSummernote();
        if (summernote && summernote.summernote('codeview.isActivated')) {
            summernote.summernote('codeview.deactivate');
        }

        let match;
        let res = this.stripCSSStyles(html);
        if (res.css.length) {
            for(let str of res.css) {
                match = str.match(/\s*body\s*{[^}]*background-color:\s*([^;]+);/i);
                if(match && match[1]) {
                    summernote.parent().find('.note-editable').css('background-color', match[1]);
                    break;
                }
            }
        }

        if (resetBackColor && (!match || !match[1])) {
            summernote.parent().find('.note-editable').css('background-color', this.gs.summernoteOptions.defaultBackColor);
        }

        return res;
    }

    getSummernoteContent(message) {
        let summernote:any = this.gs.getSummernote();
        let bodyBackColor = summernote && summernote.parent().find('.note-editable').css('background-color') || '';
        let html = (bodyBackColor ?
                '<style>body {background-color:'+bodyBackColor+';}</style>' : '') +
                (message.css ? message.css.join('') : '')
            + message.html;

        return html;
    };
}

