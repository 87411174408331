import _ from 'lodash';
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {GeneralService} from "../../services/generalService";
import {DICTIONARY} from "../../dictionary";
import {Component} from "@angular/core";


@Component({
	selector: 'apply-policy-component',
	templateUrl: './apply-policy.component.html',
})
export class ApplyPolicyComponent {
    constructor(private rs:RouteService,
				private gs:GeneralService,
				private ns:NotificationService){
    }

    dic = DICTIONARY;
    adminSelectionType = {all: 'all', specific: 'specific'};
    installPolicyInProcess;
    planAdmins;
    adminSelectionTypeSelection;
    installPolicyData;
    showApplyPolicyPopup;
    searchAdminTxt;
	_=_;


    installPolicy = (data) => {
        this.installPolicyInProcess = false;
        this.planAdmins = [];
        data.planAdmins.forEach((adminObj) => {
            if (adminObj.email !== data.controlAdmin.email) {
                this.planAdmins.push({email: adminObj.email, name: adminObj.user_id && adminObj.user_id.name, enabled: false});
            }
        });

        this.adminSelectionTypeSelection = this.adminSelectionType.specific;

        this.installPolicyData = {
            step: this.dic.CONSTANTS.installPolicySteps.selectAdmins,
            fromAdmin: data.controlAdmin.email,
            admins: [],
            policy: {
                inbound: {
                    rules: false,
                    inbound_email_rules: false,
					phishing_bypass: false,

                    configuration: {
                        senders_whitelist: false,
						senders_whitelist_advanced: false,
                        senders_blacklist: false,
                        links_whitelist: false,
                        links_blacklist: false,
                        attachments_whitelist: false,
                        attachments_blacklist: false,
                        block_files_types: false,
                        block_files_types_extensions: false
                    }
                },
                outbound: {
                    rules: false,
                    policy: false,
					enforce_domain: false,
					send_welcome_email: false
                }
            }
        };

        this.showApplyPolicyPopup = true;
    };

    installPolicyExecute = () => {
        if (this.installPolicyInProcess) {
            return;
        }

        this.installPolicyInProcess = true;
        if (this.adminSelectionTypeSelection === this.adminSelectionType.all) {
            this.installPolicyData.admins = this.planAdmins.map(itm => itm.email);
        }

        this.rs.installPolicy(this.installPolicyData).then((response) => {
            this.ns.showInfoMessage('Install policy completed successfully');
            this.showApplyPolicyPopup = false;
            this.installPolicyInProcess = false;
        }, (err) => {
            this.installPolicyInProcess = false;
        });
    }

    chooseInstallPolicyAdmins = () => {
        this.installPolicyData.admins = this.planAdmins.filter(itm => itm.selected).map(itm => itm.email);
    };

    checkPolicySelected = () => {
        return (this.installPolicyData.policy.inbound.inbound_email_rules ||
			this.installPolicyData.policy.inbound.phishing_bypass ||
            this.installPolicyData.policy.inbound.rules ||
            this.installPolicyData.policy.inbound.configuration.senders_whitelist ||
            this.installPolicyData.policy.inbound.configuration.senders_blacklist ||
            this.installPolicyData.policy.inbound.configuration.links_whitelist ||
            this.installPolicyData.policy.inbound.configuration.links_blacklist ||
            this.installPolicyData.policy.inbound.configuration.attachments_whitelist ||
            this.installPolicyData.policy.inbound.configuration.attachments_blacklist ||
            this.installPolicyData.policy.inbound.configuration.block_files_types ||
            this.installPolicyData.policy.inbound.configuration.block_files_types_extensions ||
			this.installPolicyData.policy.inbound.configuration.senders_whitelist_advanced ||
			this.installPolicyData.policy.outbound.policy ||
			this.installPolicyData.policy.outbound.enforce_domain ||
			this.installPolicyData.policy.outbound.send_welcome_email ||
			this.installPolicyData.policy.outbound.rules);
    }

    installPolicyNextStep = () => {
        this.installPolicyData.error = '';
        if (this.installPolicyData.step === this.dic.CONSTANTS.installPolicySteps.selectAdmins) {
            if (this.adminSelectionTypeSelection === this.adminSelectionType.specific && !this.installPolicyData.admins.length) {
                this.installPolicyData.error = 'You must select at least one admin';
                return;
            }
            this.installPolicyData.step = this.dic.CONSTANTS.installPolicySteps.selectPolicy;
            return;
        }

        if (this.installPolicyData.step === this.dic.CONSTANTS.installPolicySteps.selectPolicy) {
            if (!this.checkPolicySelected()) {
                this.installPolicyData.error = 'You must select at least one policy';
                return;
            }
            this.installPolicyData.step = this.dic.CONSTANTS.installPolicySteps.summary;
        }
    };

    installPolicyBackStep = () => {
        this.installPolicyData.error = '';
        this.searchAdminTxt = '';
        if (this.installPolicyData.step === this.dic.CONSTANTS.installPolicySteps.selectPolicy) {
            this.installPolicyData.step = this.dic.CONSTANTS.installPolicySteps.selectAdmins;
            return;
        }

        if (this.installPolicyData.step === this.dic.CONSTANTS.installPolicySteps.summary) {
            this.installPolicyData.step = this.dic.CONSTANTS.installPolicySteps.selectPolicy;
            return;
        }
    };

    searchAdmin = (searchTerm, activeFilters) => {
        this.planAdmins.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = this.searchAdminTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    searchAdminTextExecute = (admin, searchTerm) => {
        searchTerm = searchTerm.toLowerCase();
        return ((admin.name && admin.name.toLowerCase().indexOf(searchTerm) > -1) ||
            (admin.email && admin.email.toLowerCase().indexOf(searchTerm) > -1));
    }

}
