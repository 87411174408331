<div class="flex-column f-1-0">
	<ng-container *ngFor="let condition of conditionArr; let first = first; let orIndex = index">
		<span class="fw-bold opacity-75" style="color: #486db5; margin: 8px 5px;" *ngIf="!first">OR</span>
		<ng-container *ngTemplateOutlet="conditionHtml; context: {$implicit: condition, orIndex}"></ng-container>
	</ng-container>
</div>

<ng-template #conditionHtml let-condition let-orIndex="orIndex">
	<div class="flex-row gap-4 align-items-center f-1-0">

		<!--Select condition type-->
		<div class="position-relative">
			<app-dropdown-c style="width: 235px; --dropdown-menu-width: 630px;"  id="{{'rulesPoliciesDropdownType' + conditionIndex}}"
							[isDisabled]="condition.type === dic.CONSTANTS.rules.types.keywords.name && condition._id && condition.data && condition.data.file"
							text="{{rulesOptions.types[condition.type].label}}">
				<ul style="columns: 3;">
					<li *ngFor=" let item of rulesOptions.types| keyvalue: gs.returnZero; let index = index"
						id="{{'rulesPoliciesTypeValue' + rulesOptions.types[item.key].label + conditionIndex.index}}"
						[explanationC]="dic.CONSTANTS.rules.types[item.key].tooltip"
						(click)="changeConditionType(condition, item.key)">{{rulesOptions.types[item.key].label}}</li>
				</ul>
			</app-dropdown-c>
			<div class="redborder fa"
				 *ngIf="condition.type === dic.CONSTANTS.rules.types.sensitivity_score.name || condition.type === dic.CONSTANTS.rules.types.sensitivity_type.name"
				 style="position: absolute; cursor: pointer; right: 30px; top: 50%; padding: 0; transform: translate(0 , -55%); font-style: italic; color: black; border: none; width: fit-content; margin: 0; z-index: 50;"
				 (click)="openTestSensitivityPopup(); $event.stopPropagation();">
				Test it now!
			</div>
		</div>


		<span class="text-nowrap" *ngIf="condition.type !== rulesOptions.types.mta.name">
			detected in
		</span>

		<!--Select condition subtype (single)-->
		<div *ngIf="condition.singleSubType">
			<ng-container *ngIf="_.isObject(rulesOptions.types[condition.type].subTypes) && _.every(rulesOptions.types[condition.type].subTypes, _.isBoolean)">
				<!--selection-->
				<app-dropdown-c style="width: 150px;" id="{{'rulesPoliciesDropdownSubType' + conditionIndex}}"
								[error]="condition.invalidSubTypes"
								text="{{condition?.subTypeShow?.join(', ') || 'Choose'}}">
					<ul>
						<li *ngFor="let item of rulesOptions.types[condition.type].subTypes | keyvalue: gs.returnZero"
							(click)="selectSingleSubType(condition, item.key); subtypeChanged(condition);"
							[id]="'rulesPoliciesSubTypeValue' + item.key + conditionIndex">
							{{item.key}}
						</li>
					</ul>
				</app-dropdown-c>
			</ng-container>

			<ng-container *ngIf="_.isArray(rulesOptions.types[condition.type].subTypes)">
				<!--box-->
				<app-dropdown-c class="animated fadeIn" style="width: 150px;"
								*ngIf="condition.subType[0] !== dic.CONSTANTS.rules.subTypes.freeText"
								text="{{condition.subType[0]}}"></app-dropdown-c>
				<!--text input-->
				<input type="text" class="lowerPlaceholder" maxlength="100"
					   style="width: 150px;"
					   *ngIf="condition.subType[0] === dic.CONSTANTS.rules.subTypes.freeText"
					   placeholder="{{condition.placeholder}}"
					   [(ngModel)]="condition.values.key"
					   (blur)="isInvalidRuleData = false"
					   [ngClass]="condition.invalidHeaderKey && 'validation-error'"/>
			</ng-container>
		</div>
		<!--Select condition subtype (multiple)-->
		<div *ngIf="!condition.singleSubType">
			<app-dropdown-c style="width: 150px;" [id]="'rulesPoliciesDropdownSubType' + conditionIndex"
							[error]="condition.invalidSubTypes"
							text="{{condition?.subTypeShow?.join(', ') || 'Choose'}}">
				<ul>
					<li *ngFor="let item of rulesOptions.types[condition.type].subTypes | keyvalue: gs.returnZero"
						[explanationC]="condition.type === dic.CONSTANTS.rules.types.keywords.name ? item.key === 'body' ? 'Email body - including file names and sensitive files content' : 'Email subject' : ''"
						(click)="condition.subType[item.key] = !condition.subType[item.key]; subtypeChanged(condition); $event.stopPropagation();"
						id="{{'rulesPoliciesSubTypeValue' + item.key + conditionIndex}}">
						<div class="gap-1 md-checkbox">
							<input type="checkbox" onclick="return false;" [checked]="condition.subType[item.key]"/>
							<label><span>{{item.key}}</span></label>
						</div>
					</li>
				</ul>
			</app-dropdown-c>
		</div>

		<!----><!---->
		<!--connection word(s)-->
		<a class="flex-row text-nowrap blue-link text-decoration-underline"
		   [ngClass]="{'text-danger' : condition.is_negate}"
		   (click)="condition.is_negate = !condition.is_negate; checkRemoveKeywords();">
			<span>
				{{rulesOptions.types[condition.type].isPlural ? 'are' : 'is'}}
			</span>
			<span class="fw-500" *ngIf="condition.is_negate">&nbsp;NOT</span>
			<span *ngIf="condition.type === rulesOptions.types.sensitivity_score.name || condition.type ===  rulesOptions.types.difference.name || condition.type ===  rulesOptions.types.total_unique_domains.name">
				&nbsp;equal or above
			</span>
			<span *ngIf="condition.type === rulesOptions.types.sensitivity_type.name">
				&nbsp;{{condition.is_negate ? 'at least one of' : 'one of'}}
			</span>
			<span *ngIf="condition.type === rulesOptions.types.mta.name">
				&nbsp;one of
			</span>
		</a>


		<!--match type dropdown-->
		<app-dropdown-c *ngIf="condition.type === rulesOptions?.types?.addresses?.name || condition.type === rulesOptions?.types?.keywords?.name ||
							   condition.type === rulesOptions?.types?.domains?.name || condition.type === rulesOptions?.types?.all_domains?.name ||
							   condition.type === rulesOptions?.types?.email_header?.name"
						style="width: 150px;"
						(click)="showMatchActions(condition);"
						text="{{condition?.data?.matchType}}">
			<ul>
				<li *ngFor="let matchTypeVal of condition.actions"
					[explanationC]="dic.CONSTANTS.ruleMatchTypeTooltip[matchTypeVal]"
					(click)="changeMatchType(matchTypeVal, condition)">
					{{matchTypeVal}}
				</li>
			</ul>
		</app-dropdown-c>
		<!----><!---->

		<!--select values-->
		<ng-container [ngSwitch]="condition.type">

			<!--sensitivity score-->
			<input *ngSwitchCase="rulesOptions.types.sensitivity_score.name"
				   type="number" min="1" max="5" step="0.1"
				   id="{{'rulesPoliciesSensitivityScore' + conditionIndex}}"
				   pattern="/^[1-5]\.?[0-9]?$/"
				   (ngInit)="condition.data.score_more = condition.data.score_more || 4"
				   [(ngModel)]="condition.data.score_more" />

			<!--difference in domain groups-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.difference.name"
							style="width: 70px;" text="{{condition.data.diff}}"
							id="{{'rulesPoliciesDropdownDifference' + conditionIndex}}">
				<ul>
					<li *ngFor="let diff of rulesOptions.types.difference.result"
						id="{{'rulesPoliciesDifferenceValue' + diff + 'index' + conditionIndex}}"
						(click)="condition.data.diff = diff">
						{{diff}}
					</li>
				</ul>
			</app-dropdown-c>

			<!--compliance-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.compliance.name"
							style="width: 150px;"
							text="{{condition?.data?.complianceShow?.join(', ') || 'Choose'}}"
							[error]="condition?.invalidCompliance"
							id="{{'rulesPoliciesDropdownCompliance' + conditionIndex}}">
				<ul>
					<li *ngFor="let item of condition.data.compliance| keyvalue: gs.returnZero"
						(click)="condition.data.compliance[item.key].enabled = !condition.data.compliance[item.key].enabled; condition.invalidCompliance = false; updateComplianceShow(condition.data); $event.stopPropagation()"
						id="{{'rulesPoliciesComplianceValue' + item.key + conditionIndex}}">
						<div class="gap-1 md-checkbox">
							<input type="checkbox" onclick="return false;" [checked]="condition.data.compliance[item.key].enabled"/>
							<label><span>{{item.key}}</span></label>
						</div>
					</li>
				</ul>
			</app-dropdown-c>

			<!--email methods-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.email_methods.name"
							style="width: 200px;"
							text="{{condition?.data.methods?.emailMethodsShow?.join(', ') || 'Choose'}}"
							[error]="condition?.invalidEmailMethods"
							id="{{'emailMethodsDropdown' + conditionIndex}}">
				<ul>
					<li *ngFor="let item of condition.data.methods; let i = index"
						(click)="item.enable = !item.enable; updateEmailMethodsShow(condition.data.methods); $event.stopPropagation()"
						id="{{'emailMethodsValue' + item.key + conditionIndex}}">
						<div class="gap-1 md-checkbox">
							<input type="checkbox" onclick="return false;" [checked]="item.enable"/>
							<label><span>{{item.key}}</span></label>
						</div>
					</li>
				</ul>
			</app-dropdown-c>

			<!--keywords-->
			<app-dropdown-c *ngIf="condition.type === rulesOptions.types.keywords.name && (condition?.data?.matchType === dic.CONSTANTS.ruleMatchType.exactMatch || condition?.data?.matchType === dic.CONSTANTS.ruleMatchType.contains)"
							style="width: 200px;" [ngStyle]="{'color' : condition?.data?.keywords?.length ? '' : 'darkgrey', 'font-size': condition?.data?.keywords?.length ? '' : '11.4px'}"
							(click)="showPopup(condition);" tooltip="Click to view and edit"
							[isDisabled]="condition.type === dic.CONSTANTS.rules.types.keywords.name && condition?._id && condition?.data && condition?.data?.file"
							text="{{condition?.data?.keywords?.join(' , ') || 'Click to add Keywords'}}"
							[error]="condition?.invalidKeywords">
			</app-dropdown-c>

			<!--addresses-->
			<app-dropdown-c *ngIf="condition.type === rulesOptions.types.addresses.name &&
								   [dic.CONSTANTS.ruleMatchType.startsWith, dic.CONSTANTS.ruleMatchType.endsWith, dic.CONSTANTS.ruleMatchType.belongsTo, dic.CONSTANTS.ruleMatchType.exactMatch, dic.CONSTANTS.ruleMatchType.contains].indexOf(condition?.data?.matchType) > -1"
							style="width: 200px;" tooltip="Click to view and edit"
							[ngStyle]="{'color' : (condition?.data?.matchType !== dic.CONSTANTS.ruleMatchType.belongsTo && condition?.data?.emails?.length) || (condition?.data?.matchType === dic.CONSTANTS.ruleMatchType.belongsTo && getDataGroupsNames(condition).length) ? '' : 'darkgrey',
										 'font-size': (condition?.data?.matchType !== dic.CONSTANTS.ruleMatchType.belongsTo && condition?.data?.emails?.length) || (condition?.data?.matchType === dic.CONSTANTS.ruleMatchType.belongsTo && getDataGroupsNames(condition).length) ? '' : '11.4px'}"
							(click)="condition.data.matchType !== dic.CONSTANTS.ruleMatchType.belongsTo ? showPopup(condition) : openGroupsPopup(true, condition)"
							text="{{condition?.data?.matchType !== dic.CONSTANTS.ruleMatchType.belongsTo ? (condition?.data?.emails?.join(' , ') || 'Click to add email addresses') : (getDataGroupsNames(condition).join(', ') || 'Click to select groups')}}"
							[error]="condition.invalidEmails"></app-dropdown-c>

			<!--domains-->
			<app-dropdown-c *ngIf="(condition.type === rulesOptions.types.domains.name || condition.type === rulesOptions.types.all_domains.name) &&
								   (condition.data.matchType === dic.CONSTANTS.ruleMatchType.exactMatch)"
							style="width: 200px;" [ngStyle]="{'color' : condition?.data?.domains?.length ? '' : 'darkgrey', 'font-size': condition?.data?.domains?.length ? '' : '11.4px'}"
							(click)="showPopup(condition);" tooltip="Click to view and edit"
							text="{{condition?.data?.domains?.length ? condition?.data?.domains?.join(' , ') : 'Click to add domains'}}"
							[error]="condition.invalidDomains"></app-dropdown-c>

			<!--email header-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.email_header.name"
							style="width: 200px;" [ngStyle]="{'color' : condition?.values?.value?.length ? '' : 'darkgrey', 'font-size': condition?.values?.value?.length ? '' : '11.4px'}"
							(click)="showPopup(condition);" tooltip="Click to view and edit"
							text="{{condition?.values?.value?.length ? condition?.values?.value?.join(' , ') : 'Click to add header values'}}"
							[error]="condition?.invalidHeaderValue">
			</app-dropdown-c>

			<!--mta-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.mta.name"
							style="width: 200px;" [ngStyle]="{'color' : condition?.data?.value?.length ? '' : 'darkgrey', 'font-size': condition?.data?.value?.length ? '' : '11.4px'}"
							(click)="showPopup(condition);" tooltip="Click to view and edit"
							text="{{condition?.data?.value?.join(' , ') || 'Click to add ' + (condition.subType['IP'] ? 'IP addresses' : condition.subType['CIDR'] ? 'CIDR addresses' : 'hostnames')}}"
							[error]="condition?.invalidValues">
			</app-dropdown-c>

			<div *ngSwitchCase="rulesOptions.types.received_time.name" class="flxRow gap-3" style="align-items: center;">
				<span>between</span>
				<app-dropdown-c style="width: 120px; --bs-dropdown-max-height: 210px; --bs-dropdown-overflow: auto;"
								[error]="condition.invalidReceivedTime && (!condition.data.received_time_start || _.toNumber(condition.data.received_time_start) > _.toNumber(condition.data.received_time_end || 24))"
								[text]="condition.data.received_time_start">
					<li *ngFor="let hour of hours"
						(click)="condition.data.received_time_start = hour; condition.invalidReceivedTime = false;"
						id="fromHour_{{hour}}">
						{{hour}}
					</li>
				</app-dropdown-c>
				<span>and</span>
				<app-dropdown-c style="width: 120px; --bs-dropdown-max-height: 210px; --bs-dropdown-overflow: auto;"
								[error]="condition.invalidReceivedTime && (!condition.data.received_time_end || _.toNumber(condition.data.received_time_start || 0) > _.toNumber(condition.data.received_time_end))"
								[text]="condition.data.received_time_end">
					<li *ngFor="let hour of hours"
						(click)="condition.data.received_time_end = hour; condition.invalidReceivedTime = false;"
						id="fromHour_{{hour}}">
						{{hour}}
					</li>
				</app-dropdown-c>
			</div>

			<!--received_day-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.received_day.name"
							style="width: 200px;"
							[items]="condition.data.received_day"
							(onItemClicked)="condition.invalidReceivedDay = false;"
							[isMultipleSelection]="true"
							itemDisplayKey="name"
							[error]="condition?.invalidReceivedDay">
			</app-dropdown-c>

			<!--attachment types-->
			<app-dropdown-c *ngSwitchCase="rulesOptions.types.attachment_types.name"
							style="width: 200px;" [ngStyle]="{'color' : condition?.data?.attachment_types?.length ? '' : 'darkgrey', 'font-size': condition?.data?.attachment_types?.length ? '' : '11.4px'}"
							(click)="showAttachmentTypesPopup(condition);" tooltip="Click to view and edit"
							text="{{condition?.data?.all_types ? 'any type' : condition?.data?.attachment_types?.length ? condition?.data?.attachment_types?.join(' , ') : 'Click to add attachment types'}}"
							[error]="condition?.invalidAttachmentsTypes">
			</app-dropdown-c>

			<!--groups-->
			<a *ngSwitchCase="rulesOptions.types.groups.name"
			   class="blue-link"
			   (click)="openGroupsPopup(true, condition)"
			   [ngClass]="{'validation-error': condition.invalidGroups}">
				{{condition.data.groups && condition.data.groups.length ? condition.data.groups.length + ' groups selected (click to edit)' : 'Select groups'}}
			</a>

			<!--sensitivity_type-->
			<a *ngSwitchCase="rulesOptions.types.sensitivity_type.name"
			   class="blue-link"
			   (click)="openSensitivityTypesPopup(condition)"
			   [tooltip]="condition?.data?.sensitivity_types && condition?.data?.sensitivity_types?.length ? condition?.data?.sensitivity_types?.join(', ') : ''"
			   [ngClass]="{'validation-error': condition.invalidSensitivityType}">
				{{condition.data.sensitivity_types && condition?.data?.sensitivity_types?.length ? condition?.data?.sensitivity_types?.length + ' types selected (click to edit)' : 'Select sensitivity types'}}
			</a>

			<!--total unique domains-->
			<input *ngSwitchCase="rulesOptions?.types?.total_unique_domains?.name"
				   type="number" min="1" max="50" step="1" style="width: 50px;"
				   id="{{'rulesPoliciesTotalUniqueDomains' + conditionIndex}}"
				   pattern="/^(?:[1-9]|[1-4][0-9]|50)$/"
				   [(ngModel)]="condition.data.total_unique_domains" />


		</ng-container>

		<i class="zmdi zmdi-close text-danger hvr-darken cursor-pointer px-3 py-2 animated fadeIn" tooltip="Remove condition"
		   *ngIf="(condition.isException ? true : newRule.conditions.length > 1) || conditionArr.length > 1"
		   (click)="cancelNewRuleCondition(orIndex, conditionIndex)"></i>
	</div>
</ng-template>

<sensitivity-type-c
	*ngIf="modalData?.openSensitivityTypesPopup"
	[data]="modalData"
	[allTypes]="sensitivity_types"
	[doNotDeleteTypes]="doNotDeleteTypes"
	[selectedTypes]="conditionInEdit.data.sensitivity_types"
	(updateCb)="updateTypes($event.types, $event.newTypes)"
	(cancelCb)="cancelSensitivityTypesPopup()">

</sensitivity-type-c>

<groups-popup-c
	*ngIf="showGroups"
	(cancelCb)="showGroups = false"
	(doneCb)="applyGroupsPopup($event.groups)"
	[doneBtnTxt]="'Apply'"
	[selectedGroups]="selectedGroupsRule"
	[multipleSelection]="true"
	[popupTitle]="'Select Groups'"
	popupSubTitle="The rule will be applied according to the selected groups">
</groups-popup-c>

<!--condition values popup-->
<popup-c *ngIf="valuesPopup?.show"
	   head="Add or edit {{valuesPopup.name}}"
	   [subTitle]="valuesPopup.subtitle"
	   (closeCb)="valuesPopup.show = false;"
	   (confirmCb)="applyPopupCondition(conditionInEdit)"
	   [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}" size="medium">
	<div class="flex-column f-1-0 vSpace2 " style="overflow: auto;">
		<form novalidate >
			<div class="flxRow-between w100" style="align-items: center;">
				<div class="flxRow-between w100 hSpace2" style="align-items: baseline;">
					<input class="emailType" style="margin: 0; width: 90%;" required
						   [(ngModel)]="valuesPopup.input"
						   [ngModelOptions]="{standalone: true}"
						   (ngModelChange)="isValueChanged(conditionInEdit);"
						   type="text"
						   placeholder="{{valuesPopup.placeholder}}"
						   (keypress)="handleKeypress($event, conditionInEdit)"
						   [ngClass]="{'disabled': conditionInEdit.type === dic.CONSTANTS.rules.types.keywords.name && conditionInEdit._id && conditionInEdit.data && conditionInEdit.data.file}"
					/>
					<btn-c [isDisabled]="!valuesPopup.input || conditionInEdit.invalidInput"
						 (action)="isValueAddedToList(conditionInEdit);">
						Add
					</btn-c>
				</div>
			</div>
		</form>
		<div class="listTable alternated flxClmn f-1-0">
			<table>
				<thead>
				<tr>
					<th>
						{{ _.startCase(valuesPopup.singular) }}
					</th>
					<th style="width:50px;">
					</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let record of valuesPopup.currentList">
					<td>
						<div class="name ellipsis">
							<div class="name ellipsis">
								<div tooltip="{{record}}" placement="top">
									{{record}}
								</div>
							</div>
						</div>
					</td>
					<td>
						<a (click)="isValueRemoved(conditionInEdit, record)" class="opener closeRed" style="padding-left: 10px;">
							<span style="padding: 0;">
								<i class="zmdi zmdi-close" style="position: relative;"></i>
							</span>
						</a>
					</td>
				</tr>
				</tbody>
			</table>
			<!-- placeholder for empty table: -->
			<div class="w100 flxClmn-center" style="flex:1 0; text-align: center; color: darkgrey;"
				 *ngIf="!valuesPopup.currentList.length">
				<span>No {{conditionInEdit.data.matchType === dic.CONSTANTS.ruleMatchType.exactMatch ? valuesPopup.name : "values"}} in list</span>
			</div>
			<!-- end -->
		</div>
		<div class="flxRow-start hSpace1 list-csv-action">
			<a style="margin: 0 8px 0 0; padding : 0;" class="hSpace05" (mouseover)="mouseOverAttachment = true;"
               ngfDrop
               ngfSelect
               [(file)]="valuesPopup.file"
               (fileChange)=" importConditionListFromCsv(valuesPopup.file,conditionInEdit)"
               [(validDrag)]="validDrag"
               [(invalidDrag)]="invalidDrag"
               [ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag, 'hover': mouseOverAttachment}"
               accept='.csv'
			>
				<i class="fas fa-file-import"></i>
				<span class="action-name" tooltip='A column named "{{valuesPopup.singular}}" should contain the values'>Import from CSV</span>
			</a>
			<a class="hSpace05" style="margin: 0 0 0 8px; padding : 0;"
			   (click)="exportConditionListToCsv(conditionInEdit)"
			   [ngClass]="{'disabled': !valuesPopup.currentList.length}">
				<i class="fas fa-file-export"></i>
				<span class="action-name">Export to CSV</span>
			</a>
		</div>
	</div>
</popup-c>

<!--attachment type popup-->
<popup-c *ngIf="attachmentTypesPopup?.show"
	   head="Select Attachment Types"
	   width="700px" height="auto"
	   (closeCb)="attachmentTypesPopup = null"
	   (confirmCb)="applyAttachmentTypesPopup()"
	   [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">

	<div class="flex-column f-1-0 vSpace2 danger-message">
		<div style="margin-bottom: 20px;">Rule will be triggered if at least one of the selected attachment types is found in the email's attached files</div>
		<div class="flxRow-between">
			<!--Search-->
			<search-box [(model)]="attachmentTypesPopup.attachmentTypesSearchTxt"
						(modelChange)="searchAttachmentTypes(attachmentTypesPopup.attachmentTypesSearchTxt,conditionInEdit)"
						[isDisabled]="attachmentTypesPopup.all_types"
						placeholder="Search type"
						style="min-width: 350px;">
			</search-box>
			<!--select all types checkbox-->
			<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
				<input id="aiiAttachmentTypes" type="checkbox"
					   [(ngModel)]="attachmentTypesPopup.all_types"/>
				<label class="m-0" for="aiiAttachmentTypes">All attachment types</label>
			</div>
		</div>

		<div style=" position: relative; display: flex; flex-wrap: wrap; font-size: 15px; max-height: 415px; overflow-y: auto; overflow-x: hidden;">
			<div *ngFor="let item of attachmentTypesPopup.fileTypesList| keyvalue: gs.returnZero;"
				 style="width: 50%;">
				<label class="policyCheckbox"
					   (click)="$event.stopPropagation()"
					   style="margin-bottom: 5px;">
					<input type="checkbox"
						   [(ngModel)]="attachmentTypesPopup.attachmentTypesList[item.key].enabled"/>
					<span style="position: relative; font-size: 15px;">&nbsp;</span>
				</label>
				<span>{{item.value.display}}</span>
			</div>
			<div *ngIf="attachmentTypesPopup.all_types" style="position: absolute; top: -15px; left: -15px; width: calc(100% + 15px); height: calc(100% + 15px); background: white; opacity: 0.9; display: flex; justify-content: center; align-items: center;">
				<span style="font-weight: bold;">all attachment types are enabled</span>
			</div>
		</div>
	</div>
</popup-c>

<!--Test sensitivity engine popup-->
<popup-c *ngIf="testSensitivityPopup?.show"
		 head="Test Our Sensitivity Engine" size="medium" height="auto"
		 subTitle="Upload a file or enter text below to see the results from our sensitivity engine"
		 (closeCb)="testSensitivityPopup = null;"
		 (confirmCb)="confirmTestSensitivity()"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Perform Test', loading: testSensitivityInProcess}}">
	<div class="flex-column f-1-0 gap-3">
		<div class="flxRow">
			<label class="radio-container" style="flex-basis: 80px;">
				File
				<input type="radio" name="sensitivityType" [value]="dic.CONSTANTS.sensitivityAction.file"
					   [(ngModel)]="testSensitivityPopup.type"
					   [checked]="testSensitivityPopup.type === dic.CONSTANTS.sensitivityAction.file">
				<span class="checkmark"></span>
			</label>
			<div class="f-1-0 hSpace2">
				<input type="button" class="redborder fa" value="Attach file" style="margin: 0;"
					   [ngClass]="{'disabled': testSensitivityPopup.type !== dic.CONSTANTS.sensitivityAction.file}"
					   ngfSelect
					   [(file)]="testSensitivityPopup.file">
				<span *ngIf="testSensitivityPopup.file">{{testSensitivityPopup.file.name}}</span>
			</div>
		</div>

		<div class="flxRow f-1-0">
			<label class="radio-container" style="flex-basis: 80px;">
				Text
				<input type="radio" name="sensitivityType" [value]="dic.CONSTANTS.sensitivityAction.text"
					   [(ngModel)]="testSensitivityPopup.type"
					   [checked]="testSensitivityPopup.type === dic.CONSTANTS.sensitivityAction.text">
				<span class="checkmark"></span>
			</label>
			<div class="f-1-0">
				<textarea style="width: 100%; height: 100%; border:1px solid #ccc; resize: none;"
						  [(ngModel)]="testSensitivityPopup.text"
						  placeholder="Enter text here"
						  [ngClass]="{'disabled': testSensitivityPopup.type !== dic.CONSTANTS.sensitivityAction.text}">
				</textarea>
			</div>
		</div>


		<div *ngIf="testSensitivityPopup?.result" class="flex-column gap-2">
			<trustifi-table-c
				class="trustifi-table" style="flex: 1 0; z-index: 5; min-height: 300px;"
				[list]="testSensitivityPopup.result.sensitive_types"
				[headers]="[
					{text: 'Type', sortKey: 'sensitive_type'},
					{text: 'Score', sortKey: 'score', width: '70px'},
					{text: 'Content'},
					{text: 'Compliance'},
				]"
				[cellsPrototype]="[
					{textKey: 'sensitive_type'},
					{textKey: 'score'},
					{html: 'contentCell'},
					{html: 'complianceCell'},
				]"
				[options]="{
					isEllipsis: false,
					itemsNameSingular: 'type',
				}"
			>
				<ng-template #customCells let-item let-cellId="cellId">
					<div *ngIf="cellId === 'contentCell'">
						<div class="px-2 py-1 border rounded-1 flex-row gap-2">
								<span class="f-1-0 break-word ellipsis">
									{{item.content}}
								</span>
							<i tooltip="Copy" class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;" (click)="clipboard.copy(item.content);"></i>
						</div>
					</div>

					<div *ngIf="cellId === 'complianceCell'">
						<div class="value flex-row gap-2 justify-content-start flex-wrap">
						<span class="p-1 rounded-1 bg-dark bg-opacity-10"
							  *ngFor="let compliance of item.compliance" >
							{{compliance}}
						</span>
						</div>
					</div>
				</ng-template>

				<div tableButtons>
					<div class="flex-row p-1 rounded-1 align-items-center gap-1" style="border: 1px solid; width: 150px;"
						 [ngStyle]="testSensitivityPopup.result.score === 0 && {'background-color':'#32cd324d', 'border-color':'limegreen'} || testSensitivityPopup.result.score < 4 && {'background-color':'#ff8c004d', 'border-color':'darkorange'} || testSensitivityPopup.result.score < 5 && {'background-color':'#FF851B4d', 'border-color':'#FF851B'} || testSensitivityPopup.result.score === 5 && {'background-color':'#FF41364d', 'border-color':'#FF4136' }">
						<div class="header">Sensitivity Score:</div>
						<input type="button" value="{{testSensitivityPopup.result.score}}"
							   [ngStyle]="testSensitivityPopup.result.score === 0 && {'background':'limegreen'} || testSensitivityPopup.result.score < 4 && {'background':'darkorange'} || testSensitivityPopup.result.score < 5 && {'background':'#FF851B'} || testSensitivityPopup.result.score === 5 && {'background':'#FF4136'}"
							   class="redborder fa" style="cursor: unset; width: 25px; margin: 0; padding-top: 4px; padding-bottom: 4px; border: 0; color: white !important;"
							   tooltip="Reflects how sensitive the content found in the email is on a scale of 0 (not sensitive) to 5 (extremely sensitive)" placement="right">
					</div>
				</div>
			</trustifi-table-c>
		</div>
	</div>
</popup-c>
