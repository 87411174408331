<div class="flxClmn bs-enabled">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container">
			<span class="page-title">Configurations</span>
		</div>
		<div class="page-subtitle-container">
			<span class="page-subtitle"></span>
		</div>
	</div>
	<div class="page-inner-content-holder gap-3 pt-0 flex-column">
		<!--Tabs-->
		<div class="md-tabs" [ngStyle]="{'--md-tabs-width': tabWidth + 'px'}">
			<div *ngFor="let tab of dic.CONSTANTS.accountCompromisedCfgPageTabs | keyvalue: gs.returnZero"
				 [ngClass]="{'selected' : gs.getTabName() === tab.value}"
				 [routerLink]="tab.value">
				<input type="radio" name="tabs" id="{{tab.key}}Toggle" [checked]="gs.getTabName() === tab.value">
				<label for="{{tab.key}}Toggle">{{tab.value | camelCaseToTitleCase}}</label>
			</div>

			<div class="slide" [ngStyle]="{'left' : tabWidth * _.values(dic.CONSTANTS.accountCompromisedCfgPageTabs).indexOf(gs.getTabName()) + 'px' || 0}"></div>
		</div>
		<!---->

		<router-outlet></router-outlet>
	</div>
</div>
