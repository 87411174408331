<div class="position-relative support-menu-container">
    <button type="button" class="bs-btn gap-2 border-rounded btn-in-header">
        <span>Support</span>
		<i class="fa fa-question"></i>
    </button>
    <div class="drop-menu">
        <ul>
            <li class="flex-row gap-2 align-items-center"
                (click)="goToKnowledgeBase(dic.CONSTANTS.supportPages.faq);">
                <i class="fas fa-comments"></i>
                <span>FAQ</span>
            </li>
			<li class="flex-row gap-2 align-items-center expandable">
				<i class="fas fa-network-wired"></i>
				<span>Technical Documentation</span>
				<div class="drop-menu sub-menu">
					<ul>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/general"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>General</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/inbound-shield"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>Inbound Shield</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/outbound-shield"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>Outbound Shield</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/dlp-rules-policies-guide"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>Data Loss Prevention</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/account-takeover-protection"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>Account Takeover Protection</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/archive-1"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>Archive</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/email-add-ins"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>Email Add-ins</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/threat-simulation"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>Threat Simulation</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/dmarc-analyzer-1"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>DMARC Analyzer</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
						<li>
							<a class="flex-row gap-2 align-items-center"
							   href="https://docs.trustifi.com/docs/single-sign-on-integrations"
							   target="_blank" rel="noopener noreferrer nofollow">
								<span>Single Sign-On Integration</span>
								<i class="fas fa-external-link-alt"></i>
							</a>
						</li>
					</ul>
				</div>
			</li>

            <li class="flex-row gap-2 align-items-center"
				(click)="goToKnowledgeBase(dic.CONSTANTS.supportPages.whatsNew)">
                <i class="fas fa-bullhorn"></i>
                <span>What's New</span>
            </li>
            <li class="flex-row gap-2 align-items-center"
				(click)="goToKnowledgeBase(dic.CONSTANTS.supportPages.knowledgeBase, 'Sensitivity_Engine')">
                <i class="fas fa-shield-alt"></i>
                <span>Sensitivity Engine</span>
            </li>
            <li class="flex-row gap-2 align-items-center">
                <i class="fas fa-stamp"></i>
                <span><a style="text-decoration: underline;" href="https://trustifi.com/iso27001.pdf" target="_blank">International Standards</a></span>
            </li>
            <li class="flex-row gap-2 align-items-center"
                *ngIf="isPro"
				(click)="goToKnowledgeBase(dic.CONSTANTS.supportPages.knowledgeBase, 'Technical_Constraints')">
                <i class="fas fa-compress-alt"></i>
                <span>Technical Constraints</span>
            </li>
            <hr/>
            <li class="flex-row gap-2 align-items-center expandable">
                <i class="fas fa-gavel"></i>
                <span>Compliance and Regulation</span>
                <div class="drop-menu sub-menu">
                    <ul>
                        <li>
                            <a class="flex-row gap-2 align-items-center"
                               href="{{gs.hipaa}}"
                               target="_blank" rel="noopener noreferrer nofollow">
                                <span>HIPAA</span>
                                <i class="fas fa-external-link-alt"></i>
                            </a>
                        </li>
                        <li>
                            <a class="flex-row gap-2 align-items-center"
                               href="{{gs.gdpr}}"
                               target="_blank" rel="noopener noreferrer nofollow">
                                <span>GDPR</span>
                                <i class="fas fa-external-link-alt"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <hr/>
            <li>
                <a class="flex-row gap-2 align-items-center"
                   href="{{gs.sla}}"
                   target="_blank" rel="noopener noreferrer nofollow">
                    <i class="fas fa-handshake"></i>
                    <span>SLA</span>
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </li>
            <li *ngIf="isEmdrSla">
                <a class="flex-row gap-2 align-items-center"
                   href="{{gs.emdrSla}}"
                   target="_blank" rel="noopener noreferrer nofollow">
                    <i class="fas fa-handshake"></i>
                    <span>EMDR SLA</span>
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </li>
            <hr/>
            <li class="flex-row gap-2 align-items-center"
                (click)="showContactUsForm = true">
                <i class="fa fa-headset"></i>
                <span>Open a Ticket</span>
            </li>
        </ul>
    </div>
</div>

<popup-c *ngIf="showContactUsForm"
       head="Contact Our Support Team" size="small" height="auto"
       (confirmCb)="sendMessageToSupport();"
       (closeCb)="showContactUsForm = false;"
       [buttons]="{cancel: 'Cancel', confirm: 'Send'}">
    <div class="flxClmn f-1-0 vSpace2">
        <span>Our team is happy to answer your questions. Fill out the form, and we will reply as soon as possible!</span>
        <!--contact form-->
        <div class="f-1-0 flxClmn">
            <div style="flex: 1 0;">
                <table class="simpleDataTableShrink middle w100 h100">
                    <tr>
                        <td style="height: 35px; width: 1px; white-space: nowrap; padding-right: 1vw;">
                            Operating system
                        </td>
                        <td>
                            <app-dropdown-c style="width:100%;" [text]="ddsOSSelectedType">
                                <ul style="overflow: auto; max-height: 180px;">
                                    <li *ngFor="let option of dssOSTypes"
                                        (click)="ddsOSSelectedType = option"
                                        id="{{option}}">
                                        {{option}}
                                    </li>
                                </ul>
                            </app-dropdown-c>
                        </td>
                    </tr>
                    <tr *ngIf="ddsOSSelectedType === 'Other'">
                        <td style="height: 35px; width: 1px; white-space: nowrap; padding-right: 1vw;"></td>
                        <td>
                            <input type="text" style="margin: 0 0 5px 0; height: 35px; width: 100%;"
                                   placeholder="Enter operating system name"
								   [autofocus]="true"
                                   [(ngModel)]="ddsOtherOS">
                        </td>
                    </tr>
                    <tr>
                        <td style="height: 35px; width: 1px; white-space: nowrap;">
                            Browser
                        </td>
                        <td>
                            <app-dropdown-c style="width:100%;" [text]="ddsBrowserSelected">
                                <ul style="overflow: auto; max-height: 180px;">
                                    <li *ngFor="let option of dssBrowsers"
                                        (click)="ddsBrowserSelected = option"
                                        id="{{option}}">
                                        {{option}}
                                    </li>
                                </ul>
                            </app-dropdown-c>
                        </td>
                    </tr>
                    <tr *ngIf="ddsBrowserSelected === 'Other'">
                        <td style="height: 35px; width: 1px; white-space: nowrap; padding-right: 1vw;"></td>
                        <td>
                            <input type="text" style="margin: 0 0 5px 0; height: 35px; width: 100%;"
                                   placeholder="Enter browser name"
                                   [autofocus]="true"
                                   [(ngModel)]="ddsOtherBrowser">
                        </td>
                    </tr>
                    <tr>
                        <td style="height: 30px; width: 1px; white-space: nowrap;">
                            Issue type
                        </td>
                        <td>
                            <app-dropdown-c style="width:100%;" [text]="ddsReasonSelected || 'Select from list'">
                                <ul style="overflow: auto; max-height: 180px;">
                                    <li *ngFor="let option of dssReason"
                                        (click)="ddsReasonSelected = option"
                                        id="{{option}}">
                                        {{option}}
                                    </li>
                                </ul>
                            </app-dropdown-c>
                        </td>
                    </tr>
                    <tr>
                        <td style="height: 35px; width: 1px;  white-space: nowrap;">
                            Title
                        </td>
                        <td>
                            <input type="text" style="margin: 0 0 5px 0; height: 35px; width: 100%;"
                                   placeholder="Summarize your issue"
                                   [autofocus]="true"
                                   [(ngModel)]="messageTitle">
                        </td>
                    </tr>
                    <tr>
                        <td style="vertical-align: top;">
                            Message
                        </td>
                        <td>
                            <textarea class="color-equalized" style="width: 100%; height:100%; min-height: 150px; resize: none;"
                                      [ngClass]="{'validation-error' : supportTicketTextErr}"
                                      (keyup)="supportTicketTextErr = false;"
                                      placeholder="Describe your issue"
                                      [(ngModel)]="messageText"></textarea>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="info-banner">
            <span style="color: #c55c35;"> Note: if you need to upload files, you can do so in reply to the email that will be sent to you after you open the ticket.</span>
        </div>
    </div>
</popup-c>
