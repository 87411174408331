<!--exchange connector/rules popup-->
<popup-c class="no-content-padding bs-enabled" size="large"
	   head="Disable Default Exchange Security Policies"
	   subTitle="Use this tool to disable the default security policies in Exchange"
	   (closeCb)="closePopup();">
	<div class="flex-column overflow-auto f-1-0 pt-2 gap-2" style="padding: 20px;">
		<!--steps progress-->
		<div class="steps-container" style="font-size: 0.8rem;">
			<div class="step-tab"
				 *ngFor="let stepKey of validSteps"
				 [ngClass]="{'active' : step === stepKey}">
				{{stepKey}}
			</div>
		</div>

		<!--Steps content:-->
		<!--STEP: General instructions-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-2 pt-3" style="line-height: 2;"
			 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation">
			<div class="flxClmn">
				<span style="position: relative; padding-left: 15px;" class="diamond-indent">
					This wizard will disable the default security policies in Microsoft Exchange.<br/>
					Disabling these policies is recommended if you want all threats to be quarantined in Trustifi only.
				</span>
				<br/>
				<div class="flex-row gap-2 align-items-baseline">
					<i class="fa fa-exclamation-triangle opacity-25"></i>
					<span class="fw-bold">Before taking the next step:</span>
				</div>

				<div class="bg-light p-2">
					<ul style="list-style-type: number;">
						<li>
							<span>Make sure your PowerShell integration with Trustifi is active and valid</span>
						</li>
						<li>
							<span>Make sure remote PowerShell access is enabled in your Exchange server</span>
						</li>
					</ul>
				</div>
			</div>

			<div class="flxClmn centralize" style="flex: 1 0;" *ngIf="isLicenseLoading">
				<loader height="100" width="100" style="opacity: 0.8;"></loader>
				<span class="text-primary mt-1 fw-500">Retrieving Exchange Server licence</span>
				<span class="text-primary">Please wait. Process may take a while...</span>
			</div>
		</div>

		<!--STEP: Configuration-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-2 pt-3"
			 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.configuration">
			<div class="list-group list-group-flush settings-list-container overflow-auto">
				<div class="flex-row mx-2 align-items-baseline gap-2">
					<i class="fa fa-info-circle text-info"></i>
					<span>Select at least one option</span>
				</div>

				<div *ngIf="disableContentFilter.visible" class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Disable Content Filter Agent</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Disable the Content Filter agent - content filter configuration on a Mailbox server or an Edge Transport server</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="disableContentFilter" type="checkbox"
								   [(ngModel)]="disableContentFilter.enabled"/>
							<label for="disableContentFilter" style="margin: 0;"></label>
						</div>
					</div>
				</div>

				<div *ngIf="disableAntiSpam.visible" class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Disable Anti-Spam Inbound Protection</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Disables any custom policies and the default anti-spam policies in Exchange</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="disableAntiSpam" type="checkbox"
								   [(ngModel)]="disableAntiSpam.enabled"/>
							<label for="disableAntiSpam" style="margin: 0;"></label>
						</div>
					</div>
				</div>

				<div *ngIf="disableAntiPhish.visible" class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Disable Anti-Phishing Protection</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Disables any custom policies and the default anti-phishing policies in Exchange</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="disableAntiPhish" type="checkbox"
								   [(ngModel)]="disableAntiPhish.enabled"/>
							<label for="disableAntiPhish" style="margin: 0;"></label>
						</div>
					</div>
				</div>

				<div *ngIf="disableAntiMalware.visible" class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Disable Anti-Malware Protection</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Disables any custom policies and the default anti-malware policies in Exchange</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="disableAntiMalware" type="checkbox"
								   [(ngModel)]="disableAntiMalware.enabled"/>
							<label for="disableAntiMalware" style="margin: 0;"></label>
						</div>
					</div>
				</div>

				<div *ngIf="disableSafeAttachments.visible" class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Disable Safe Attachments Protection</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Disables any custom policies and the default Safe Attachment policies in Exchange</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="disableSafeAttachments" type="checkbox"
								   [(ngModel)]="disableSafeAttachments.enabled"/>
							<label for="disableSafeAttachments" style="margin: 0;"></label>
						</div>
					</div>
				</div>

				<div *ngIf="disableSafeLinks.visible" class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Disable Safe Links Protection</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Disables any custom policies and the default Safe Links policies in Exchange</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="disableSafeLinks" type="checkbox"
								   [(ngModel)]="disableSafeLinks.enabled"/>
							<label for="disableSafeLinks" style="margin: 0;"></label>
						</div>
					</div>
				</div>

				<div *ngIf="advancedDelivery.visible" class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Enable Advanced Delivery</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Enables advanced delivery from Trustifi by bypassing Exchange’s custom and default security protections</span>
						<span class="text-muted lh-base" style="font-size: 0.9em;"><span style="color: orange;">NOTE:</span> Changing the region will require to run this again.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="advancedDelivery" type="checkbox"
								   [(ngModel)]="advancedDelivery.enabled"/>
							<label for="advancedDelivery" style="margin: 0;"></label>
						</div>
					</div>
				</div>
			</div>

			<div class="flex-column gap-2 px-2">
				<hr class="mt-0"/>
				<span class="diamond-indent position-relative ps-3">After clicking <b>Configure</b>, Trustifi will disable the selected Exchange protection policies.</span>
			</div>
		</div>

		<!--STEP: Summary-->
		<div class="flxClmn f-1-0 animated fadeIn gap-3 p-2 pt-3" *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.summary">
			<div>
				<div>
					<span>The following Exchange security policies will be disabled:</span>
					<ul>
						<li style="padding: 5px;" *ngIf="disableContentFilter.enabled">Content Filter Agent</li>
						<li style="padding: 5px;" *ngIf="disableAntiSpam.enabled">Anti-Spam Inbound Protection</li>
						<li style="padding: 5px;" *ngIf="disableAntiPhish.enabled">Anti-Phishing Protection</li>
						<li style="padding: 5px;" *ngIf="disableAntiMalware.enabled">Anti-Malware Protection</li>
						<li style="padding: 5px;" *ngIf="disableSafeAttachments.enabled">Safe Attachments Protection</li>
						<li style="padding: 5px;" *ngIf="disableSafeLinks.enabled">Safe Links Protection</li>
						<li style="padding: 5px;" *ngIf="advancedDelivery.enabled">Advanced Delivery</li>
					</ul>
				</div>
				<span style="font-size: 13px;"><u>Note</u>: this process may take a couple of minutes to finish.</span>
			</div>

			<br/><br/>
			<div class="border w-100 p-4 flex-column gap-3" style="max-width: 520px; background: white;"
				 [ngClass]="{'text-danger border-danger' : rulesStatus.status === 'failure', 'text-success border-success' : rulesStatus.status === 'success'}"
				 *ngIf="rulesStatus && rulesStatus.status">
                        <span class="fw-bold">
                            {{rulesStatus.status === 'failure' ? 'Configuration process failed' :
							rulesStatus.status === 'success' ? 'Configuration process finished successfully' : 'Looks like the configuration process hasn\'t finished yet.'}}
                        </span>
				<span *ngIf="rulesStatus.message">{{rulesStatus.message}}</span>
			</div>

			<div class="flxClmn centralize" style="flex: 1 0;" *ngIf="generateTokenInProcess">
				<loader height="100" width="100" style="opacity: 0.8;"></loader>
				<span class="text-primary mt-1 fw-500">Disabling Exchange Server protection</span>
				<span class="text-primary">Please wait. Process may take a while...</span>
			</div>
		</div>

		<!--error message and buttons-->
		<div class="flex-row justify-content-between align-items-end mb-3 px-4 w-100">
			<span class="f-1-0 pe-3 text-danger" style="min-height: 40px;">
				{{errorMsg || ' '}}
			</span>

			<div class="flex-row gap-3" style="font-size: 13px;">
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					 variant="outlined"
					 (action)="closePopup();">
					Cancel
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step !== dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					 variant="outlined"
					 (action)="backStep();">
					Back
				</btn-c>
				<btn-c class="popup-action-btn"
					*ngIf="step !== dic.CONSTANTS.exchangeRulesSteps.summary && step !== dic.CONSTANTS.exchangeRulesSteps.configuration"
					(action)="nextStep();">
					Next
				</btn-c>
				<btn-c class="popup-action-btn"
					*ngIf="step === dic.CONSTANTS.exchangeRulesSteps.configuration"
					(action)="nextStep();">
					Configure
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.summary"
					 (action)="closePopup();">
					OK
				</btn-c>
			</div>
		</div>
	</div>
</popup-c>
