<div class="flxClmn bs-enabled">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container flxRow hSpace1">
			<span class="page-title">Campaigns</span>
			<div style="position: relative;">
				<btn-c color="warning" variant="outlined"
					   id="comingSoonModalToggler"
					   startIcon="fas fa-bullhorn"
					   (action)="showComingSoonModal = !showComingSoonModal">
					See what's coming
				</btn-c>
				<div class="flxClmn page-info-window" style="right: -10px; top: 5px; left: auto; transform: translateX(100%); width: auto;"
					 (clickOutside)="showComingSoonModal = false;"
					 [exclude]="'#comingSoonModalToggler'"
					 [ngClass]="{'shown' : showComingSoonModal}">
					<div class="flxClmn f-1-0 vSpace05" style="width: 900px; max-width: 55vw; height: auto; line-height: 1.5; font-size: 14px; padding: 25px;">
						<span style="font-weight: bold;">Heads up! More features will be deployed soon to Threat Simulation:</span>
						<br/>
						<div class="flxClmn vSpace1" style="padding-left: 10px;">
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>A variety of new templates simulating prevalent phishing email types, tailored to suit various company departments.</span>
							</div>
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>Simulate new types of attacks: Business Email Compromise (BEC), malware, and more.</span>
							</div>
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>Collect data from new types of user engagement: replies, downloaded files, credentials entered into fake landing pages, and more.</span>
							</div>
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>Access new training materials, including security awareness questionnaires that could be scheduled for at-risk users.</span>
							</div>
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>Improved reports and graphs.</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page-subtitle-container">
			<span class="page-subtitle">
				Threat Simulation campaigns allow security teams to test user security awareness and improve security practices in the organization
			</span>
		</div>
	</div>
	<div class="page-inner-content-holder no-scroll gap-3 pt-0 flex-column">
		<!--Tabs-->
		<div class="md-tabs" [ngStyle]="{'--md-tabs-width': tabWidth + 'px'}">
			<div *ngFor="let tab of dic.CONSTANTS.threatSimulationCampaignsPageTabs | keyvalue: gs.returnZero"
				 [ngClass]="{'selected' : gs.getTabName() === tab.value}"
				 [routerLink]="tab.value">
				<input type="radio" name="tabs" id="{{tab.key}}Toggle" [checked]="gs.getTabName() === tab.value">
				<label for="{{tab.key}}Toggle">{{tab.value | camelCaseToTitleCase}}</label>
			</div>

			<div class="slide" [ngStyle]="{'left' : tabWidth * _.values(dic.CONSTANTS.threatSimulationCampaignsPageTabs).indexOf(gs.getTabName()) + 'px' || 0}"></div>
		</div>
		<!---->

		<router-outlet></router-outlet>
	</div>
</div>


