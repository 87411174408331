import _ from 'lodash';
import {DICTIONARY} from '../../../dictionary';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {GeneralService} from '../../../services/generalService';
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'account-details-notifications-component',
	templateUrl: './account-details-notifications.component.html',
})
export class AccountDetailsNotificationsComponent implements OnInit {
    dic = DICTIONARY;

    actions = [
        this.dic.CONSTANTS.configListsActions.remove
    ]

    bulkActions = [
        this.dic.CONSTANTS.configListsActions.remove
    ]

    notifications: any[];
    loadingNotificationInProcess: boolean;
	userAlerts: any = {};
    partner_plan_id: any;
    filterData;

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,) {

    }

	ngOnInit() {
        this.initFilters();

        this.notifications = [];

        this.loadingNotificationInProcess = true;
        this.rs.getUserNotifications().then((response) => {
            this.notifications = response.notifications.data;
            this.notifications = this.ns.parseNotificationsUI(this.notifications);
            this.userAlerts = response.alerts;
            this.partner_plan_id = response.partner_plan_id;
            this.loadingNotificationInProcess = false;
        }, (err) => {
            this.loadingNotificationInProcess = false;
        });
    }

    showNotificationBulkActions = () => {
        return this.bulkActions;
    }

    showNotificationActions = () => {
        return this.actions;
    }

    selectMultipleNotificationsAction = (selectedRecords,action) => {
        switch (action) {
            case this.dic.CONSTANTS.configListsActions.remove:
                this.deleteNotifications(selectedRecords);
                break;
        }
    }

    selectNotificationAction = (record ,action) => {
        switch (action) {
            case this.dic.CONSTANTS.configListsActions.remove:
                this.deleteNotifications([record]);
                break;
        }
    }

    deleteNotifications = (notifications) => {
        if (!notifications) {
            return;
        }

        const notificationsIds = _.map(notifications, "_id");
        notificationsIds.forEach((id,index) => {
            this.rs.deleteNotification(id).then(() => {
                _.remove<any>(this.notifications, n => {return n._id === id})
                this.ns.showInfoMessage(this.dic.MESSAGES.notificationDeleted);
            });
        })
    };

    initFilters = () => {
        this.filterData = {
            filterType: this.dic.CONSTANTS.tableFilters.notifications,
            filters: {
                type: ['info', 'warning']
            },
            initFiltersFunction: this.initFilters,
        };
    };

    searchNotification = (event) => {
        this.notifications.forEach(record => {
            // search
            if (event.searchTerm) {
                const isFound = this.searchTextExecute(record, event.searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            if (event.activeFilters) {
                // need to match all filter types
                let numFilterToMatch = Object.keys(event.activeFilters).length;

                if (event.activeFilters.type && event.activeFilters.type.length) {
                    if (record.type && event.activeFilters.type.includes(record.type === 'warn' ? 'warning' : record.type)) {
                        numFilterToMatch--;
                    }
                }

                if (numFilterToMatch) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    setUserAlerts = () => {
        const alertObj = {
            success_email: this.userAlerts.success_email,
            plan_renewal: this.userAlerts.plan_renewal,
            plan_access_by_partner: this.userAlerts.plan_access_by_partner,
        };
        this.rs.updateAlerts(alertObj).then((response) => {
            this.ns.showInfoMessage(this.dic.MESSAGES.userAlertsUpdated);
        });
    };

    searchTextExecute(notificationObj, searchTerm) {
        searchTerm = searchTerm.toLowerCase();
        const messageTextOnly = notificationObj.text.replace(/<[^>]*>/g, ' ').replace(/\s{2,}/g, ' ').trim(); // remove html tags
        return (messageTextOnly.toLowerCase().indexOf(searchTerm) > -1);
    }
}

