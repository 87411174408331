<div class="flex-column gap-4">
	<span>Reviewers will receive notifications about quarantined email threats sent to your protected mailboxes and will be able to take immediate actions.</span>
	<div class="list-group list-group-flush settings-list-container" *ngIf="config">
		<div class="flex-column" style="z-index: 1;">
			<!--reviewers table-->
			<trustifi-table-c
				class="trustifi-table" style="flex: 0 0 350px; z-index: 5; overflow: auto;"
				[list]="config?.threat_protection.reviewers"
				[headers]="[
					{text:'Reviewer', sortKey:'email'},
					{text:'View Content', sortKey:'allow_view_content', tooltip: 'Allow reviewer to view the email content for quarantined emails'},
					{text:'Release Malicious', sortKey:'reviewer_allow_release_malicious', tooltip: 'Allow reviewer to release malicious emails'},
					{text:'Change Configuration', sortKey:'allow_change_configuration', tooltip: 'Allow reviewer to change configurations and settings. If disabled, the reviewer may only review and control emails in quarantine'},
					{text:'Threat Response', sortKey:'threat_response_permission', tooltip: 'Allow reviewer to access the Threat Response functionality'},
					{text:'Defang Malicious URLs', sortKey:'client_defang_malicious_url', tooltip: 'URLs that have been detected as malicious will be defanged and become unclickable in the Threat Analysis view for increased security'}
				]"
				[cellsPrototype]="[
					{html: 'reviewerCell', edit: {modelKey:'email', placeholder:'Reviewer email'}},
					{html: 'viewContentCell'},
					{html: 'releaseMaliciousCell'},
					{html: 'changeConfigCell'},
					{html: 'threatResponseCell'},
					{html: 'defangMaliciousUrlsCell'},
			]"
				[bulkActions]="{
					enable: true,
					selectBulkActionCb:	selectMultipleReviewerAction,
					initBulkActionsFunction: showReviewerBulkActions,
					showInRed: {enable: true, terms: ['Remove']}
				}"
				[cellActions]="{
					enable: true,
					selectItemActionCb:	selectReviewerAction,
					initActionsFunction: showReviewerActions,
					showInRed: {enable: true, terms: ['Remove']}
				}"
				(searchFunction)="searchReviewers($event)"
				(confirmEditFunction)="confirmAddReviewer($event.item, $event.isApproved)"
				[options]="{
					exportToCsvFunction: exportReviewersListToCsv,
					loadingTableFlag: loadingReviewersInProcess,
					itemsNameSingular: 'reviewer',
					defaultSortKey: '-email',
				}"
			>
				<ng-template #customCells let-item let-cellId="cellId">
					<!--reviewer cells-->
					<div *ngIf="cellId === 'reviewerCell'" class="flxClmn gap-2 ellipsis">
						<div>
							<span>{{item.email}}</span>
						</div>
						<span *ngIf="item.is_primary"><b>[Primary reviewer]</b></span>
						<div style="display: inline-block; max-width: 70%;"
							 *ngIf="item.groups?.length"
							 title="Assigned to groups: {{_.map(item.groups, 'name').join(', ')}}">
							<i class="fa fa-users"></i>
							{{_.map(item.groups, 'name').join(', ')}}
						</div>
						<div style="display: inline-block; max-width: 70%;"
							 *ngIf="item.mailboxes?.length"
							 title="Assigned to mailboxes: {{item.mailboxes.join(', ')}}">
							<i class="fa fa-user"></i>
							{{item.mailboxes.join(', ')}}
						</div>
					</div>
					<div *ngIf="cellId === 'viewContentCell'">
						<span [ngStyle]="{color: item.allow_view_content || item.isNew ? 'green' : 'red'}">{{item.allow_view_content || item.isNew ? 'Enabled' : 'Disabled'}}</span>
					</div>
					<div *ngIf="cellId === 'releaseMaliciousCell'">
						<span [ngStyle]="{color: item.reviewer_allow_release_malicious || item.isNew ? 'green' : 'red'}">{{item.reviewer_allow_release_malicious || item.isNew ? 'Enabled' : 'Disabled'}}</span>
					</div>
					<div *ngIf="cellId === 'changeConfigCell'">
						<span [ngStyle]="{color: item.allow_change_configuration || item.isNew ? 'green' : 'red'}">{{item.allow_change_configuration || item.isNew ? 'Enabled' : 'Disabled'}}</span>
					</div>
					<div *ngIf="cellId === 'threatResponseCell'">
						<span [ngStyle]="{color: item.threat_response_permission || item.isNew ? 'green' : 'red'}">{{item.threat_response_permission || item.isNew ? 'Enabled' : 'Disabled'}}</span>
					</div>
					<div *ngIf="cellId === 'defangMaliciousUrlsCell'">
						<span [ngStyle]="{color: item.client_defang_malicious_url ? 'green' : 'red'}">{{item.client_defang_malicious_url ? 'Enabled' : 'Disabled'}}</span>
					</div>
				</ng-template>
				<!---->
				<div tableButtons>
					<div class="btn-with-dropdown">
						<btn-c (action)="startAddReviewer();"
							   endIcon="fa fa-plus"
							   [isDisabled]="addReviewerInProcess">
							Add Reviewer
						</btn-c>
						<app-dropdown-c [isLf]="true">
							<li (click)="openUsersSelection()">Add from my users</li>
						</app-dropdown-c>
					</div>
				</div>
			</trustifi-table-c>
		</div>

		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
				<b>Notify about email content being viewed</b>
				<span style="font-size: 0.9em;">
                    The plan admins will receive an automated notification whenever a reviewer views a quarantined email's content
                </span>
			</div>
			<div class="content-container flex-column gap-4" style="max-width: 630px;">
				<toggle-switch-c id="notify_reviewer_view_content"
								 [(model)]="config?.threat_protection.notify_reviewer_view_content"
								 (onChange)="updateNotifyReviewerViewContent();"
								 [isDisabled]="actionInProcess"></toggle-switch-c>
			</div>
		</div>

		<!--Domains/addresses ignore list -->
		<div class="list-group-item settings-list-item" *ngIf="config?.threat_protection?.security_reviewer">
			<div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
				<b>Recipients for EMDR Incident Notifications (optional)</b>
				<span style="font-size: 0.9em;">
					Send notification about incidents identified by EMDR team <b>only</b> to these recipients.<br/>
					If no address is provided, the information will be sent to the reviewers listed above.
                </span>
			</div>
			<div class="content-container flex-column gap-2">
				<div class="flex-row hSpace05">
					<input type="text" style="width: 80%; max-width: 300px; margin-bottom: unset;"
						   placeholder="Add email address here"
						   (keyup.enter)="securityReviewerRecipientModel && !actionInProcess && updateSecurityReviewerRecipients(dic.CONSTANTS.actions.add);"
						   [(ngModel)]="securityReviewerRecipientModel">
					<btn-c style="min-width: 0;" endIcon="zmdi zmdi-check"
						   [isDisabled]="!securityReviewerRecipientModel || actionInProcess"
						   (action)="updateSecurityReviewerRecipients(dic.CONSTANTS.actions.add);">
					</btn-c>
				</div>
				<div class="flex-column gap-1" style="max-width: 400px;" *ngIf="config?.threat_protection?.security_reviewer_recipients?.length">
					<div *ngFor="let recipient of config?.threat_protection.security_reviewer_recipients;"
						 style="">
						<div class="name ellipsis"
							 style="width: 95%; display: inline-block; max-width: 800px;">{{recipient}}</div>
						<a class="closeRed action"
						   (click)="updateSecurityReviewerRecipients(dic.CONSTANTS.actions.delete, recipient)"
						   [disabled]="actionInProcess"
						   [ngClass]="actionInProcess ? 'closeRed disableAction' : 'approveGreen'">
							<i class="fa fa-times"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<groups-popup-c
		*ngIf="assignReviewerToGroupsPopup"
		(cancelCb)="openAssignReviewerToGroupsPopup(false)"
		(doneCb)="applyReviewerToGroups($event.groups)"
		doneBtnTxt="Apply"
		[selectedGroups]="assignReviewerSelectedGroups"
		[multipleSelection]="true"
		popupTitle="Assign reviewer to groups">
	</groups-popup-c>
</div>

<users-selection-component
	*ngIf="usersSelectionPopup?.show"
	[data]="usersSelectionPopup"
	[selectedUsers]="selectedUsers"
	(addCb)="startAddFromUsersSelection($event.users)"
	(removeCb)="startRemoveFromUsersSelection($event.users)">
</users-selection-component>

<popup-c *ngIf="reviewerPermissionPopup?.show"
		 head="Modify Permissions"
		 class="bs-enabled"
		 subTitle="Configure permissions for {{reviewerPermissionPopup.reviewerObj.email}}"
		 (closeCb)="reviewerPermissionPopup = null;"
		 size="dynamic">

	<div class="flxClmn gap-4">
		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>View Content</b>
				<span>Allow reviewer to view the email content for quarantined emails</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="isOutboundReviewer"
								 [hideText]="true"
								 [(model)]="reviewerPermissionPopup.reviewerObj.allow_view_content"
								 (onChange)="reviewerViewContentChangeExecute()"></toggle-switch-c>
			</div>
		</div>

		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>Release Malicious</b>
				<span>Allow reviewer to release malicious emails</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="reviewer_allow_release_malicious"
								 [hideText]="true"
								 [(model)]="reviewerPermissionPopup.reviewerObj.reviewer_allow_release_malicious"
								 (onChange)="reviewerReleaseMaliciousEmailExecute()"></toggle-switch-c>
			</div>
		</div>

		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>Change Configuration</b>
				<span>Allow reviewer to change configurations and settings. If disabled, the reviewer may only review and control emails in quarantine</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="allow_change_configuration"
								 [hideText]="true"
								 [(model)]="reviewerPermissionPopup.reviewerObj.allow_change_configuration"
								 (onChange)="reviewerChangeConfigurationExecute()"></toggle-switch-c>
			</div>
		</div>

		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>Threat Response</b>
				<span>Allow reviewer to access the "Threat Response" functionality</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="threat_response_permission"
								 [hideText]="true"
								 [(model)]="reviewerPermissionPopup.reviewerObj.threat_response_permission"
								 (onChange)="reviewerThreatResponsePermissionExecute()"></toggle-switch-c>
			</div>
		</div>

		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>Defang Malicious URLs in Threat Analysis</b>
				<span>URLs that have been detected as malicious will be defanged and become unclickable in the "Threat Analysis" view for increased security</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="client_defang_malicious_url"
								 [hideText]="true"
								 [(model)]="reviewerPermissionPopup.reviewerObj.client_defang_malicious_url"
								 (onChange)="reviewerDefangMaliciousUrl()"></toggle-switch-c>
			</div>
		</div>

	</div>
</popup-c>

<popup-c *ngIf="assignReviewerToMailboxesPopup?.show"
		 head="Select Mailboxes" class="overflownV"
		 subTitle="Only reviewers assigned to specific mailboxes will receive quarantine notifications for those mailboxes"
		 (closeCb)="assignReviewerToMailboxesPopup = null;"
		 (confirmCb)="applyReviewerToMailboxes();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', disable: assignReviewerToMailboxesPopup.actionInProcess}}" size="medium">
	<div class="flxClmn vSpace3 f-1-0">
		<trustifi-table-c
			class="trustifi-table f-1-0" style="z-index: 5;"
			[list]="assignReviewerToMailboxesPopup.mailboxes"
			[headers]="[
				{text: 'Email', sortKey: 'email'},
				{text: 'Name', sortKey: 'name'},
			  ]"
			[cellsPrototype]="[
				{textKey: 'email'},
				{textKey: 'name'},
			  ]"
			(searchFunction)="searchMailbox($event)"
			[options]="{
				itemsNameSingular: 'mailbox',
				loadingTableFlag: assignReviewerToMailboxesPopup.mailboxesInProcess,
				showRadioButtons: true,
			}"
		>
		</trustifi-table-c>
		<div class="flxRow-start md-checkbox">
			<input id="only_selected_mailboxes" type="checkbox"
				   [(ngModel)]="assignReviewerToMailboxesPopup.only_selected_mailboxes"/>
			<label for="only_selected_mailboxes">Receive quarantine notifications only for the selected mailboxes</label>
		</div>
	</div>
</popup-c>
