<div class="flxClmn">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Cases</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Manage and control archive cases and queries</span>
        </div>
    </div>
    <div class="page-inner-content-holder no-scroll flxClmn">
        <!--cases table-->
        <trustifi-table-c
            class="trustifi-table" style="flex: 1 0; z-index: 5;"
            [list]="cases"
            [headers]="[
                {text:'Date', sortKey:'created', width: '160px'},
                {text:'Name', sortKey:'name', width: '15%'},
                {text:'Query', width: '25%'},
                {text:'Shared With'},
                {text:'Sharing Expiration', sortKey:'sharing.expiration', width: '180px'},
            ]"
            [cellsPrototype]="[
                {textKey: 'created', textType: 'dateAndTime'},
                {textKey: 'name'},
                {html: 'caseQueryCell'},
                {html: 'sharedWithCell'},
                {html: 'sharingExpCell'},
            ]"
			[bulkActions] = "{
				enable: true,
				selectBulkActionCb:	selectMultipleCaseAction,
				initBulkActionsFunction: showCaseBulkActions,
				showInRed: {enable: true, terms: ['Remove']}
			}"
            [cellActions] = "{
                enable: true,
                selectItemActionCb:	selectCaseAction,
                initActionsFunction: showCaseActions
            }"
            (searchFunction)="searchCases($event.searchTerm, $event.activeFilters)"
            [options]="{
                exportToCsvFunction: exportToCsv,
                loadingTableFlag: loadingCasesInProcess,
                refreshFunction: getArchiveCases,
                itemsNameSingular: 'case',
                defaultSortKey: '-created'
            }"
        >
			<ng-template #customCells let-item let-cellId="cellId">
				<!--case query cells-->
				<div *ngIf="cellId === 'caseQueryCell'">
					<div class="flxRow hSpace2 " style="flex:1 0;">
						<div class="flxClmn ellipsis" style=" flex: 1.5 0;">
							<div *ngIf="item.query.from" class="ellipsis flxRow hSpace05" style="margin: 1px 0;">
								<b>From:</b>
								<span [tooltip]="item.query.from" style="display: block;">{{item.query.from}}</span>
							</div>
							<div *ngIf="item.query.rcptTo" class="ellipsis flxRow hSpace05" style="margin: 1px 0;">
								<b>Recipients:</b>
								<span [tooltip]="item.query.rcptTo" style="display: block;">{{item.query.rcptTo}}</span>
							</div>
							<div *ngIf="item.query.subject" class="ellipsis flxRow hSpace05" style="margin: 1px 0;">
								<b>Subject:</b>
								<span [tooltip]="item.query.subject" style="display: block;">{{item.query.subject}}</span>
							</div>
							<div class="ellipsis flxRow hSpace05" style="margin: 1px 0;">
								<b>Period:</b>
								<span style="display: block;">{{dic.CONSTANTS.trendsPeriod[item.query.period]?.display}}</span>
							</div>
							<div *ngIf="item.query.hasAttachments" class="ellipsis flxRow hSpace05" style="margin: 1px 0;">
								<b>Has Attachments:</b>
								<span style="display: block;">Yes</span>
							</div>
							<div *ngIf="item.query.text" class="ellipsis flxRow hSpace05" style="margin: 1px 0;">
								<b>Contains:</b>
								<span [tooltip]="item.query.text" style="display: block;">{{item.query.text}}</span>
							</div>
							<div *ngIf="item.query.compliance?.length" class="ellipsis flxRow hSpace05" style="margin: 1px 0;">
								<b>Compliance:</b>
								<span style="display: block;" [tooltip]="item.query.compliance.join(', ')">{{item.query.compliance.join(', ')}}</span>
							</div>
							<div *ngIf="item.query.scanStatus" class="flxRow hSpace05" style="margin: 1px 0; max-width: 260px;">
								<b>Scan Status:</b>
								<span class="ellipsis">{{item.query.scanStatus}}</span>
							</div>
						</div>
					</div>
				</div>
				<!--shared with cells-->
				<div *ngIf="cellId === 'sharedWithCell'">
					<span [ngStyle]="{'text-align':'center'}"
						  [tooltip]="item.sharing?.reviewers?.length ? _.map(item.sharing.reviewers, 'email').join(', ') : 'this case hasn\'t been shared'">
                    {{item.sharing?.reviewers?.length ? _.map(item.sharing.reviewers, 'email').join(', ') : 'N/A'}}
                </span>
				</div>
				<!--sharing expiration cells-->
				<div *ngIf="cellId === 'sharingExpCell'">
					<span [ngStyle]="item.sharing && item.sharing.is_sharing_expired && {'color' : 'red'}">
                    {{item.sharing && item.sharing.expiration ? (item.sharing.expiration | date:'MM/dd/yyyy') : 'N/A'}}
                </span>
				</div>
			</ng-template>
            <div tableButtons>
                <div class="flxRow hSpace1" style="align-items: flex-end;">
                    <btn-c endIcon="fas fa-plus" (action)="openNewCasePopup();">
                        Create New Case
                    </btn-c>
                </div>
            </div>
        </trustifi-table-c>
    </div>

    <popup-c class="overflownV" *ngIf="createEditCasePopup?.show"
           head="{{createEditCasePopup.isEditCase ? 'Edit Case' : 'Create a new case for archive'}}"
           size="dynamic" width="650px"
           (closeCb)="createEditCasePopup = null;"
           (confirmCb)="applyCreateEditCasePopup()"
           [buttons]="{cancel: 'Cancel', confirm: {text: createEditCasePopup.isEditCase ? 'Confirm' : 'Create case'}}">
        <div class="flxClmn vSpace3 animated fadeIn" style="flex: 1 0; position: relative; z-index: 20; overflow: visible;">
            <div class="flxClmn vSpace1">
                <span style="font-size: 1.1rem; flex-shrink: 0; font-family: 'Roboto Condensed', sans-serif;">Case Name:</span>
                <div style=" background: #f9f9f9; padding: 10px 5px;">
                    <input class="one-lined-input w100" style="margin: 0; font-size: 0.9rem; max-width: 250px; height: auto; background: transparent;"
                           id="caseNameInput"
                           [ngStyle]="createEditCasePopup.isEditCase && {'border' : 'none !important', 'cursor' : 'not-allowed'}"
                           [(ngModel)]="createEditCasePopup.currentCase.name" maxlength="320"
						   [readonly]="createEditCasePopup.isEditCase"
                           type="text">
                </div>
            </div>

            <div class="flxClmn vSpace1">
                <span style="font-size: 1.1rem; font-family: 'Roboto Condensed', sans-serif;">Archive Search Parameters:</span>
                <div style="flex: 1 0; padding: 0 10px 15px; background: #f9f9f9;" class="flxClmn-around">
                    <div class="flxClmn" style="max-width: 500px;">
                        <div class="vSpace2" style="flex: 1 0;">
                            <table style="border-collapse: unset; table-layout: fixed; border-spacing: 0 10px; width: 100%; color: #292929; z-index: 50; position: relative;">
                                <tbody>
                                <tr>
                                    <td style="width: 135px;"><span>From:</span></td>
                                    <td><input
                                            id="caseFromInput"
                                            class="one-lined-input w100"
                                            style="margin: 0; font-size: 0.9rem; background: transparent;"
                                            [(ngModel)]="createEditCasePopup.currentCase.query.from" maxlength="320" type="text">
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Recipients:</span></td>
                                    <td><input
                                            id="caseRecipientsInput"
                                            class="one-lined-input w100"
                                            style="margin: 0; font-size: 0.9rem; background: transparent;"
                                            [(ngModel)]="createEditCasePopup.currentCase.query.rcptTo"
                                            maxlength="320"
                                            type="text">
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Subject:</span></td>
                                    <td><input
                                            id="caseSubjectInput"
                                            class="one-lined-input w100"
                                            style="margin: 0; font-size: 0.9rem; background: transparent;"
                                            [(ngModel)]="createEditCasePopup.currentCase.query.subject" maxlength="320"
                                            type="text">
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 1px; white-space: nowrap; padding-right: 15px;"><span>Contains the words:</span></td>
                                    <td>
                                        <input
                                                id="caseContainWordsInput"
                                                class="one-lined-input w100"
                                                style="margin: 0; font-size: 0.9rem; background: transparent;"
                                                [(ngModel)]="createEditCasePopup.currentCase.query.text" maxlength="320"
                                                type="text">
                                    </td>
                                </tr>

                                <tr>
                                    <td><span>Date:</span></td>
                                    <td>
										<app-dropdown-c id="caseDateDropdownInput" style="width: 100%; height: auto;" [text]="dic.CONSTANTS.trendsPeriod[createEditCasePopup.currentCase.query.period].display">
                                            <ul style="max-height: 140px; overflow: auto;">
                                                <li *ngFor="let periodObj of casesPeriods | keyvalue: gs.returnZero"
													(click)="createEditCasePopup.currentCase.query.period = periodObj.value.value;">
                                                    {{periodObj.value.display}}
                                                </li>
                                            </ul>
                                        </app-dropdown-c>
                                    </td>
                                </tr>
								<tr *ngIf="createEditCasePopup.currentCase.query.period === dic.CONSTANTS.trendsPeriod.range.value" style="position: relative; z-index: 50;">
									<td>
									</td>
									<td>
										<div class="flxRow hSpace1" style="align-items: center;">
											<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
												<span>From:</span>
												<date-picker-c class="f-1-0"
															   [(dateModel)]="createEditCasePopup.currentCase.query.range.start"
															   [dateMaxLimit]="createEditCasePopup.currentCase.query.range.end"></date-picker-c>
											</div>
											<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
												<span>Until:</span>
												<date-picker-c class="f-1-0"
															   [(dateModel)]="createEditCasePopup.currentCase.query.range.end"
															   [dateMinLimit]="createEditCasePopup.currentCase.query.range.start"></date-picker-c>
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="flxRow-start md-checkbox" style="align-items: center;">
											<input id="hasAttachments" type="checkbox" [(ngModel)]="createEditCasePopup.currentCase.query.hasAttachments"/>
											<label for="hasAttachments">Has attached files</label>
										</div>
									</td>
								</tr>
								<tr>
									<td style="padding-top: 15px; padding-bottom: 10px;">
										<b class="cursor-pointer" (click)="showNewCaseAdvanced = !showNewCaseAdvanced;">
											Advanced
											<i class="fa fa-chevron-down" style="font-size: 9px;" *ngIf="!showNewCaseAdvanced"></i>
											<i class="fa fa-chevron-up" style="font-size: 9px;" *ngIf="showNewCaseAdvanced"></i>
										</b>
									</td>
								</tr>
								<ng-container *ngIf="showNewCaseAdvanced">
									<tr class="animated fadeIn">
										<td>
											<span>Compliance:</span>
										</td>
										<td>
											<app-dropdown-c id="compliance" style="width: 100%; height: auto;"
															[items]="compliances"
															[isMultipleSelection]="true"
															itemDisplayKey="name"></app-dropdown-c>
										</td>
									</tr>
									<tr>
										<td style="font-size: 12px;">Will apply only to outbound emails that were sent with Trustifi and scanned for sensitive content</td>
									</tr>
<!--									<tr class="animated fadeIn">
										<td style="padding-top: 15px;">
											<span>Scan Status:</span>
										</td>
										<td>
											<app-dropdown-c style="width: 100%;" text="{{createEditCasePopup.currentCase.query.scanStatus}}">
												<li *ngFor="let category of dic.CONSTANTS.quarantinedCategories"
													(click)="createEditCasePopup.currentCase.query.scanStatus = category;"
													id="{{category}}">
													{{category}}
												</li>
											</app-dropdown-c>
										</td>
									</tr>
									<tr>
										<td style="font-size: 12px;"  colspan="2">Will apply only to inbound emails that were scanned by Trustifi Inbound Shield</td>
									</tr>-->
								</ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </popup-c>

	<archive-cases-operations-component #caseOperationsEl></archive-cases-operations-component>
</div>
