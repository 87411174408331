<!--loader-->
<div class="f-1-0 flex-column centralize" *ngIf="!isThisFeatureSupported">
	<loader height="100" width="100" style="opacity: 0.8;" *ngIf="_.isUndefined(isThisFeatureSupported)"></loader>
	<threat-simulation-not-supported style="display: contents;" *ngIf="isThisFeatureSupported === false"></threat-simulation-not-supported>
</div>

<div class="flex-column gap-4 animated fadeIn" *ngIf="isThisFeatureSupported">
	<span>Configure the basic settings of your Threat Simulation environment.</span>

	<div class="flex-row align-items-center bg-warning border border-warning gap-4 p-2" style="max-width: 1200px; font-size: 14px; line-height: 1.7; --bs-bg-opacity: .15;">
		<i class="fas fa-exclamation-circle text-warning ms-3" style="font-size: 1.5rem;"></i>
		<div class="flex-column">
			<div class="flex-row gap-2 align-items-center">
				<i class="fas fa-circle" style="font-size: 5px;"></i>
				<span>Make sure to configure these settings before launching Threat Simulation campaigns.</span>
			</div>
			<div class="flex-row gap-2 align-items-center">
				<i class="fas fa-circle" style="font-size: 5px;"></i>
				<span>This configuration will make sure your campaigns will not be flagged as spam and your account will not be flagged as a spammer by your Mail Server.</span>
			</div>
		</div>
	</div>
	<div class="list-group list-group-flush settings-list-container">
		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Mail Server Configuration</b>
				<span class="lh-base" style="font-size: 0.9em;">Preconfigure your Mail Server environment so that your Threat Simulation campaigns will be delivered without interruption</span>
			</div>

			<div class="content-container flex-column gap-4">
				<span style="font-weight: 500;"><u>Instructions:</u></span>
				<div class="flex-row gap-4">
					<a class="flex-column align-items-center gap-2 p-3 border border-secondary-subtle border-2 rounded-2 bg-white hvr-darken-light"
					   style="text-decoration: unset; width: 140px;" href="https://docs.trustifi.com/docs/defender-settings-in-office365"
					   target="_blank" rel="noopener noreferrer nofollow">
						<i class="fab fa-windows"
						   style="cursor: pointer; color: #00a1f1; font-size: 25px;">
						</i>
						<span style="font-size: 0.9em;">Microsoft 365</span>
					</a>

					<a class="flex-column align-items-center gap-2 p-3 border border-secondary-subtle border-2 rounded-2 bg-white hvr-darken-light"
					   style="text-decoration: unset; width: 140px;" href="https://docs.trustifi.com/docs/allowlisting-in-google-workspace "
					   target="_blank" rel="noopener noreferrer nofollow">
						<img src="images/integrationsLogos/gsuite-logo.png" width="25px" style="cursor: pointer;" alt="google logo">
						<span style="font-size: 0.9em;">Google Workspace</span>
					</a>
				</div>
			</div>
		</div>
	</div>

	<span class="info-banner border border-warning mt-5" style="font-size: 15px; max-width: 1200px;"
		  *ngIf="!threatSimulationConfig?.template_fields?.company">
		You need to configure your <a class="blue-link text-decoration-underline" [routerLink]="['/' + dic.CONSTANTS.appStates.threatSimulation, dic.CONSTANTS.adminPages.threatSimulation.configuration, dic.CONSTANTS.threatSimulationConfigPageTabs.templateFields]">Template Parameters</a> in order to send customized threat simulation campaigns
	</span>
</div>


