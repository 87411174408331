<div id="wrapper" class="signup-page flxClmn top-level-page" style="background-color: #f5f5f5; display: flex !important;">

	<notification-toaster-component></notification-toaster-component>
    <main id="main" style="margin: 0; padding: 2vh; min-height: calc(100vh - 105px);">
        <div class="content-holder h100 flxClmn-center" style="z-index: 20; padding: 0; align-items: center;">
            <div class="flxRow form-container" style="max-width: 1550px; width: 98%; z-index: 20; background: #ffffff94; border-radius: 10px; backdrop-filter: blur(20px); box-shadow: 0 0 10px lightgrey;">

                <!--commercial section (left side)-->
                <div class="flxClmn commercial-content-container"
                     style="flex: 1 0; background: linear-gradient(135deg, rgb(87,165,215), rgba(132,109,209,0.7)); padding: 2vw 1vw; position: relative; color: white; overflow: hidden; border-bottom-left-radius: 10px; border-top-left-radius: 10px;">
                    <div class="title-box">
                        <div class="title" style="font-weight: 300; font-size: calc(1rem + 1.7vw); text-shadow: 0 0 1px grey; color: white;">
                            <span>
                                Welcome to <b style="color: #324670; text-shadow: 0 0 5px white;">Trustifi</b>
                            </span>
                        </div>
                    </div>

                    <span class="subtitle" style="font-size: 18px; font-family: 'Roboto Condensed', sans-serif; font-weight: 400; margin: 2% 0 5%;">Get control over your organization protection</span>

                    <div class="flxClmn-end vSpace1 commercial-text-blocks-wrapper" style="flex: 1 0;">
                        <div class="flxRow hSpace3" *ngFor="let feature of trustifiFeatures; let index = index;"
                             [ngClass]="{'hSpace3': index % 2 !== 0 }"
                             style="padding: 1.3vw 1vw;">
                            <div class="flxClmn h100 vSpace1" style="min-width: 170px; width: 35%;"
                                 [ngStyle]="index % 2 === 0 && {'margin-left' : '15px'}">
                                <span style="font-size: 20px; font-family: 'Roboto Condensed', sans-serif;">{{feature.name}}</span>
                                <span style="font-size: 14px;" [innerHTML]="feature.description">{{feature.description}}</span>
                            </div>
                            <div class="flxClmn-center vSpace05 h100" style="font-size: 14px; font-weight: 300;">
                                <span class="flxRow hSpace1" *ngFor="let subFeature of feature.subFeatures">
                                    <i class="fas fa-check" style="color: #B8FF9F;"></i>
                                    <span>{{subFeature}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Form (right side)-->
                <div class="flxClmn-center" style="align-items: center; flex:1 0; height: 795px; overflow: auto; padding: 2vw; background: #ffffff66; border-bottom-right-radius: 10px; border-top-right-radius: 10px;"
                     [ngStyle]="partner && {'justify-content' : 'flex-start'}">
                    <div class="f-1-0 vSpace2 login-box" [ngClass]="{'flxClmn' : signUpState === 'finalState'}">
                        <div class="flxClmn vSpace05" style="text-align: center;">
                            <span *ngIf="!partner" class="title" style="font-size: 2rem; font-weight: bold;">Sign Up</span>
                            <div *ngIf="partner">
                                <img *ngIf="partner.logo"
                                     [src]="partner.logo"
                                     style="max-height: 50px; margin-bottom: 10px;"
                                     [alt]="partner.id" />
                                <span class="title" style="font-size: 1.3rem; font-weight: bold;">
                                    Purchase <b>Trustifi</b> licenses {{partner.id === 'trustifi' ? '' : 'via ' + partner.displayName}}
                                </span>
                            </div>
                            <span style="font-size: 0.9rem;">Please fill in the form</span>
							<br/>
                        </div>

						<signup-form-component class="bs-enabled" style="background: #ffffffa1; box-shadow: 0 0 40px white;"
											   [ngClass]="{'flxClmn f-1-0' : signUpState === 'finalState'}"
											   [partner]="partner"
											   [signupData]="signupData"
											   (doneCb)="signUpState = 'finalState'"
											   [isTrialSignupPage]="true">
						</signup-form-component>
						<br/>
                        <div [ngStyle]="partner && {'min-height': '150px'}" *ngIf="signUpState !== 'finalState'">
                            <div class="paypal-wrapper"
								 [ngClass]="{'disabled-overlay' : !(signupData.accountDetails.firstName && signupData.accountDetails.lastName &&
                                                signupData.accountDetails.email && validateEmail(signupData.accountDetails.email) &&
                                                signupData.accountDetails.password  && signupData.accountDetails.reTypePassword  &&
                                                signupData.accountDetails.reTypePassword === signupData.accountDetails.password && numUsers)}">
								<paypal-buttons-component
									[hasAccessToken]="false"
									[type]="dic.CONSTANTS.paypalPaymentType.newUser"
									[partner]="partner"
									[numUsers]="numUsers"
									[accountDetails]="signupData.accountDetails"
									(onComplete)="paypalComplete($event.err, $event.res)">
								</paypal-buttons-component>
                            </div>
                        </div>

                    </div>
                    <div style="align-self: flex-start; text-align: left; font-size: 14px; color:#333333; margin: 45px 10% 5px; padding: 0;">
                        By signing up, you agree to our <a style="color:#486db5; font-weight: bold;" target="_blank" rel="noopener noreferrer nofollow" href="{{eula}}">Terms
                        of Use</a>, <a style="color:#486db5; font-weight: bold;" target="_blank" rel="noopener noreferrer nofollow" href="{{privacy}}">Privacy</a>, and <a
                            style="color:#486db5;" target="_blank" rel="noopener noreferrer nofollow" href="{{antiSpam}}">Anti-Spam Policy</a>
                    </div>
                    <div style="align-self: flex-start; text-align: left; font-size: 14px; color:#333333;  margin: 0 10% 5px; padding: 0;">
                        Already have an account? <a style="color:#486db5; font-weight: bold;" [routerLink]="['/' + dic.CONSTANTS.appStates.login]">Login</a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
