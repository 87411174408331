<div class="flxClmn" style="position: relative;"
     [ngStyle]="generatePdfInProcess && {'overflow' : 'hidden'}" >
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Trends and Insights</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Review your Threat Simulation Campaign trends and get insights and action recommendations</span>
        </div>
    </div>

	<!--loader-->
	<div class="f-1-0 flex-column centralize" *ngIf="_.isUndefined(isThisFeatureSupported)">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

    <div class="page-inner-content-holder dashboard-page bs-enabled flxClmn" *ngIf="isThisFeatureSupported"
		 style="padding-top: 0;"
         [ngClass]="{'no-scroll' : generatePdfInProcess}"
         [ngStyle]="{'overflow' : generatePdfInProcess ? 'hidden' : ''}">
        <!--generate pdf loader-->
        <div *ngIf="generatePdfInProcess" class="pdf-generate-loader">
            <div style=" position: absolute; top: calc(50vh - 200px - 40px); left: calc(50vw - 70px); transform: translate(-50%,0); font-size: 4.3rem; opacity: 0.5; align-items: center;"
                 class="animated flxClmn vSpace2 infinite">
                <div class="animated bounce infinite">
                    <i class="far fa-file-pdf grey-to-redFX" style="filter: contrast(1.5) drop-shadow(2px -1px 1px lightgrey);"></i>
                </div>
                <span style="position: relative; font-size: 1.3rem;" class="loading-dots sss">Generating PDF. Please wait</span>
            </div>
        </div>
        <!---->

        <div class="flxRow-between align-items-center gap-2 mt-0 animated fadeIn" style="position: relative; z-index: 45; align-items: center; margin: 20px 0;">
            <div class="period-buttons-group bs-enabled flex-row gap-2" style="height: 30px;">
					<button class="period-button bs-btn bs-btn-light h-100"
							*ngFor="let item of dashboardPeriod | keyvalue: gs.returnZero;"
							[ngClass]="{'chosen' : period === item.value, 'd-none' : item.value.display === dic.CONSTANTS.trendsPeriod.range.display}"
							(click)="changePeriod(item.value);">
                    	{{item.value.display}}
					</button>

                <div class="position-relative range-button">
                    <button class="period-button hSpace05 bs-btn bs-btn-light h-100" id="customPeriodBtn"
                            [ngClass]="{'chosen' : period.display === dic.CONSTANTS.trendsPeriod.range.display}"
                            (click)="showTrendsCustomPeriod = !showTrendsCustomPeriod;">
                        <span *ngIf="period?.display === dic.CONSTANTS.trendsPeriod.range.display" style="font-weight: 300;">
                            From <b class="px-2">{{range?.start | date:'MM/dd/yyyy'}}</b> Until <b class="px-2">{{range.end | date:'MM/dd/yyyy'}}</b>
                        </span>
                        <span *ngIf="period?.display !== dic.CONSTANTS.trendsPeriod.range.display">
                            Custom
                        </span>
                        <i class="fa fa-sort-down" style="margin-top: -5px;"></i>
                    </button>
                    <!--custom period modal-->
                    <div class="drop-menu mt-1 p-2" style="width: fit-content; max-width: none; min-width: 300px; overflow: visible; min-height: 0;"
                         [ngClass]="{'active' : showTrendsCustomPeriod}">
                        <div class="p-3 text-start m-0 flex-row align-items-center gap-2" style="min-width: 490px;"
							 (clickOutside)="showTrendsCustomPeriod = false;" [exclude]="'#customPeriodBtn'">
							<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
								<span>From:</span>
								<date-picker-c class="f-1-0"
											   [(dateModel)]="range.start"
											   [dateMaxLimit]="range.end"></date-picker-c>
							</div>
							<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
								<span>Until:</span>
								<date-picker-c class="f-1-0"
											   [(dateModel)]="range.end"
											   [dateMinLimit]="range.start"></date-picker-c>
							</div>
                            <btn-c endIcon="zmdi zmdi-check"
                                 [loading]="getTrendsDataInProcess"
                                 (action)="changePeriod(dic.CONSTANTS.trendsPeriod.range);
                                         showTrendsCustomPeriod = false;">
                                Get Trends
                            </btn-c>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flxRow hSpace1" style="align-items: center;">
                <btn-c endIcon="fas fa-filter" style="min-width: 0;"
					   [variant]="activeSpecificUsers ? '' : 'outlined'"
					   [isDisabled]="!users || getTrendsDataInProcess"
					   (action)="openUsersFilterPopup();">
					Filter Users/Groups
				</btn-c>

				<btn-c endIcon="fas fa-file-download" variant="outlined"
					   [loading]="generatePdfInProcess"
					   [isDisabled]="!trendsData || getTrendsDataInProcess"
					   (action)="exportChartsToPdf();">
					Export to PDF
				</btn-c>
            </div>
        </div>

        <div class="bs-enabled mt-3" id="trendsChartsContainer">
            <div class="bs-container w-100" style="max-width: none !important;">
                <div class="bs-row trends-pdf-page-title" style="display: none;">
                    <div class="bs-col-12 mb-4 px-4" style="background: #fdfdfd; padding: 10px;">
                        <div class="flxClmn vSpace05">
                            <div class="flxRow-between" style="align-items: flex-end;">
                                <span style="font-weight: bold; font-size: 1.6rem; white-space: nowrap;">Account Takeover Protection Trends and Insights</span>
                                <span style="font-size: 1.25rem; white-space: nowrap;">
                                Date printed: &nbsp;
                                <time>{{todayDate | date:'MM/dd/yyyy'}}</time>
                            </span>
                            </div>
                            <div class="flxRow hSpace05" style="font-size: 1.25rem;">
                                <span>Period:&nbsp;</span>
                                <span *ngIf="period !== dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">{{period.display}}</span>
                                <span *ngIf="period === dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">
							<time>{{range.start | date:'MM/dd/yyyy'}}</time>
							&nbsp;-&nbsp;
							<time>{{range.end | date:'MM/dd/yyyy'}}
							</time>
						</span>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                </div>

                <div class="bs-row g-4">
					<div class="bs-col-6 bs-col-xxl-4" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">

							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold">Campaign Metrics</h6>
									<i class="fas fa-arrow-to-left opacity-50"></i>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;">A consolidated view of key user engagement metrics across all sent campaigns</span>
							</div>
							<apx-chart class="trends-radial-pie-chart pe-4 w-100" style="max-width: 400px; margin: auto;"
									   *ngIf="generalEventsChartOptions?.maxValue && !getTrendsDataInProcess"
									   [series]="generalEventsChartOptions?.series"
									   [chart]="generalEventsChartOptions?.chart"
									   [plotOptions]="generalEventsChartOptions?.plotOptions"
									   [colors]="generalEventsChartOptions?.colors"
									   [legend]="generalEventsChartOptions?.legend"
									   [labels]="generalEventsChartOptions?.labels"></apx-chart>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!generalEventsChartOptions?.maxValue || getTrendsDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Phishing Campaign Success Trends-->
					<div class="bs-col-6 bs-col-xxl-8" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold">Phishing Campaign Success Trends</h6>
									<i class="fas fa-arrow-to-left opacity-50"></i>
								</div>
								<span class="opacity-75 fw-500 text-start">A successful campaign is when recipients avoid interacting with malicious content, like clicking on links or sharing sensitive information</span>
							</div>

							<apx-chart class="f-1-0 animated fadeIn"
									   *ngIf="campaignsSuccessRateChart?.xaxis?.categories?.length && !getTrendsDataInProcess"
									   [series]="campaignsSuccessRateChart.series"
									   [chart]="campaignsSuccessRateChart.chart"
									   [colors]="campaignsSuccessRateChart.colors"
									   [dataLabels]="campaignsSuccessRateChart.dataLabels"
									   [plotOptions]="campaignsSuccessRateChart.plotOptions"
									   [xaxis]="campaignsSuccessRateChart.xaxis"
									   [yaxis]="campaignsSuccessRateChart.yaxis"></apx-chart>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!campaignsSuccessRateChart?.xaxis?.categories?.length || getTrendsDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Top risky users-->
					<div class="bs-col-6" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold">Top Risky Users</h6>
									<i class="fas fa-arrow-to-left opacity-50"></i>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 25px;">Highlights users who most often fail threat simulation campaigns, enabling targeted training to improve security awareness.</span>
							</div>

							<apx-chart class="f-1-0 animated fadeIn"
									   *ngIf="topCompromisedUsersChart?.series[0]?.data.length && !getTrendsDataInProcess"
									   [series]="topCompromisedUsersChart.series"
									   [chart]="topCompromisedUsersChart.chart"
									   [colors]="topCompromisedUsersChart.colors"
									   [dataLabels]="topCompromisedUsersChart.dataLabels"
									   [plotOptions]="topCompromisedUsersChart.plotOptions"
									   [xaxis]="topCompromisedUsersChart.xaxis"></apx-chart>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!topCompromisedUsersChart?.series[0]?.data.length || getTrendsDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Top risky groups-->
					<div class="bs-col-6" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold">Top Risky Groups</h6>
									<i class="fas fa-arrow-to-left opacity-50"></i>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 25px; max-width: 85%;">Highlights groups with the highest percentages of users failing threat simulation campaigns, enabling targeted training to boost security awareness.</span>
							</div>

							<apx-chart class="f-1-0 animated fadeIn clickable-chart"
									   *ngIf="topCompromisedGroupsChart?.series[0]?.data.length && !getTrendsDataInProcess"
									   [series]="topCompromisedGroupsChart.series"
									   [chart]="topCompromisedGroupsChart.chart"
									   [colors]="topCompromisedGroupsChart.colors"
									   [dataLabels]="topCompromisedGroupsChart.dataLabels"
									   [tooltip]="topCompromisedGroupsChart.tooltip" [display]="false"
									   [plotOptions]="topCompromisedGroupsChart.plotOptions"
									   [xaxis]="topCompromisedGroupsChart.xaxis"></apx-chart>

							<!--tips modal-->
							<div class="trends-card-actions-menu tips"
								 [ngClass]="{'active' : showRiskyGroupsTips}">
								<i class="fa fa-lightbulb" id="riskyGroupsTipsTrigger" tooltip="Tips"
								   (click)="showRiskyGroupsTips = !showRiskyGroupsTips"></i>
								<div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
									 [ngClass]="{'active' : showRiskyGroupsTips}">
									<ul style="cursor: default;" (clickOutside)="showRiskyGroupsTips = false;"
										[exclude]="'#riskyGroupsTipsTrigger'">
										<div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
											<i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
											<span class="fw-500">Click on any bar to view members distribution inside the group.</span>
										</div>
									</ul>
								</div>
							</div>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!topCompromisedGroupsChart?.series[0]?.data.length || getTrendsDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Phishing Campaign table display-->
					<div class="bs-col-12" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold">Phishing Campaign Table</h6>
									<i class="fas fa-arrow-to-left opacity-50"></i>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;">Track the trends of campaign success rates over time, providing valuable insights into the effectiveness of your phishing simulation campaigns</span>
							</div>

							<trustifi-table-c
								class="trustifi-table phishing-campaign-table" style="flex: 1 0; overflow: auto; z-index: 5; background: #fdfdfd; padding: 5px;"
								[list]="campaignTrends"
								[headers]="[
									{text:'Created', sortKey: 'campaign.created', width: '130px'},
									{text:'Name', sortKey: 'campaign.name'},
									{text:'Template Name', sortKey: 'campaign.name'},
									{text:'Attack Vector'},
									{text:'Attack Techniques'},
									{text:'Recipients', sortKey: 'campaign.recipients_count', width: '110px'},
									{text:'Success Rate', sortKey: 'successRate', tooltip: 'A successful campaign is when recipients avoid interacting with malicious content, like clicking on links or sharing sensitive information'}
								]"
								[cellsPrototype]="[
									{textKey: 'campaign.created', textType: 'dateAndTime'},
									{textKey:'campaign.name'},
									{textKey:'campaign.template_name'},
									{html:'attackVectorCell'},
									{html:'techniquesCell'},
									{textKey:'campaign.recipients_count'},
									{html:'campaignSuccessRate'},
								]"
								(searchFunction)="searchCampaign($event.searchTerm, $event.activeFilters)"
								[options]="{
								    exportToCsvFunction: exportCampaignCsv,
								    loadingTableFlag: getTrendsDataInProcess,
									defaultSortKey: '-campaign.created',
									itemsNameSingular: 'Campaign',
								}"
							>
								<ng-template #customCells let-item let-cellId="cellId">
									<span *ngIf="cellId === 'attackVectorCell'">
										<div class="flxRow gap-1" style="align-items: center;" *ngIf="item.campaign.templateInfo?.attack_vector">
											<div class="flxRow threat-tag not-clickable darkred hvr-opacity hSpace05 m-0" style="align-items: center; color: darkred;">
												<div class="threat-tag-BG"></div>
												<i class="fa fa-bug threat-tag-icon"></i>
												<span class="threat-tag-text">{{item.campaign.templateInfo.attack_vector.name}}</span>
											</div>
											<i style="font-weight: 400;" class="fas fa-question-circle" [tooltip]="item.campaign.templateInfo.attack_vector.description"></i>
										</div>
									</span>
									<span *ngIf="cellId === 'techniquesCell'">
										<div class="flxRow gap-2" style=" align-items: center; flex-wrap: wrap;" *ngIf="item.campaign.templateInfo?.techniques">
											<div class="flxRow gap-1" style="align-items: center;" *ngFor="let technique of item.campaign.templateInfo?.techniques">
												<div class="flxRow threat-tag not-clickable dimgray hvr-opacity hSpace05 m-0" style="align-items: center; color: dimgray;">
													<div class="threat-tag-BG"></div>
													<span class="threat-tag-text">{{technique.name}}</span>
												</div>
												<i style="font-weight: 400;" class="fas fa-question-circle" [tooltip]="technique.description"></i>
											</div>
										</div>
									</span>
									<span *ngIf="cellId === 'campaignSuccessRate'">
										{{item.successRate}}%
									</span>
								</ng-template>

							</trustifi-table-c>
						</div>
					</div>


					<!--Mailboxes-->
					<div class="bs-col-12" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold">Campaign Latest Activity</h6>
									<i class="fas fa-arrow-to-left opacity-50"></i>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;">Track the latest threat simulation campaigns received by each user, and which template was used in the latest campaign</span>
							</div>

							<trustifi-table-c
								class="trustifi-table phishing-campaign-table" style="flex: 1 0; overflow: auto; z-index: 5; background: #fdfdfd; padding: 5px;"
								[list]="campaignLatestActvitiyMailboxes"
								[headers]="[
									{text:'Last Sent', sortKey: 'last_campaign.campaign.created', width: '130px'},
									{text:'Mailbox', sortKey: 'email', width: '250px'},
									{text:'Campaign Name', sortKey: 'last_campaign.campaign.name'},
									{text:'Template Name', sortKey: 'last_campaign.campaign.template_name'},
									{text:'Success', tooltip: 'Indicates whether the user has passed the threat simulation campaign (true) or failed it (false)'}
								]"
								[cellsPrototype]="[
									{html: 'lastCreatedCell', textType: 'dateAndTime', placeHolder: '-'},
									{textKey: 'email'},
									{html:'campaignNameCell', placeHolder: 'N/A'},
									{textKey:'last_campaign.campaign.template_name', placeHolder: 'N/A'},
									{html:'campaignSuccessRate'},
								]"
								(searchFunction)="searchMailbox($event.searchTerm, $event.activeFilters)"
								[options]="{
								    exportToCsvFunction: exportMailboxCsv,
								    loadingTableFlag: getTrendsDataInProcess,
									defaultSortKey: '-last_campaign.campaign.created',
									itemsNameSingular: 'Campaign',
								}"
							>
								<ng-template #customCells let-item let-cellId="cellId">
									<span *ngIf="cellId === 'lastCreatedCell'">
										{{ (item.last_campaign.campaign.created | date:'MM/dd/yyyy') === '01/01/1970' ? 'Not Sent' : (item.last_campaign.campaign.created | date:'MM/dd/yyyy HH:mm') }}
									</span>
									<span *ngIf="cellId === 'campaignNameCell'">
										{{item.last_campaign?.campaign?.name ? (item.last_campaign?.campaign?.group_name ? '('+item.last_campaign?.campaign?.group_name+')' + ' ' + item.last_campaign?.campaign?.name : item.last_campaign?.campaign?.name) : 'N/A'}}
									</span>
									<span *ngIf="cellId === 'campaignSuccessRate'" [ngClass]="item.last_campaign?.campaign?._id ? (item.success ? 'text-success' : 'text-danger') : ''">
										{{item.last_campaign?.campaign?._id ? (item.success ? 'True' : 'False') : 'N/A'}}
									</span>
								</ng-template>

							</trustifi-table-c>
						</div>
					</div>

                </div>
            </div>
        </div>
    </div>

	<!--feature not supported-->
	<threat-simulation-not-supported style="display: contents;" *ngIf="isThisFeatureSupported === false"></threat-simulation-not-supported>
	<!---->
</div>

<popup-c class="bs-enabled" *ngIf="usersPopup?.show"
		 head="Select Users for Trends" size="large"
		 (closeCb)="usersPopup = null;"
		 (confirmCb)="applyUsersFilter()"
		 [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">
	<div class="flxClmn gap-3 f-1-0" style="overflow: auto;">
		<span>Select one or more users to get trends information for:</span>
		<div class="flxClmn f-1-0 gap-2" style="overflow: auto;">
			<label class="radio-container m-0" style="display: block;">
				All users
				<input type="radio"
					   [(ngModel)]="usersPopup.type"
					   [value]="usersFilterTypes.all">
				<span class="checkmark"></span>
			</label>
			<div class="flex-row gap-1 align-items-center">
				<label class="radio-container m-0" style="display: block; width: fit-content;">
					Specific users or groups:
					<input type="radio"
						   [(ngModel)]="usersPopup.type"
						   [value]="usersFilterTypes.specific">
					<span class="checkmark"></span>
				</label>
			</div>
			<div class="position-relative f-1-0 flex-column overflow-auto gap-3">
				<!--table overlay when disabled-->
				<div class="w-100 h-100" style="background-color: white; position: absolute; top: 0; left: 0; z-index: 20; opacity: 0.4;"
					 *ngIf="usersPopup.type === usersFilterTypes.all">
				</div>
				<trustifi-table-c
					#specificUsersOrGroupsTableEl
					class="trustifi-table f-1-0" style="z-index: 5;"
					[list]="usersPopup.users"
					[headers]="[
						{text:'Name', sortKey: 'name'},
						{text:'Email', sortKey: 'email'},
					]"
					[cellsPrototype]="[
					  {html: 'nameCell'},
					  {textKey: 'email'},
					]"
					(searchFunction)="searchUser($event.searchTerm, $event.activeFilters)"
					[subRowOptions]="{
							recursionKey: 'members',
							allowActions: false
						}"
					[options]="{
						defaultSortKey: '-name',
						itemUniqueIdentifier: '_id',
						showRadioButtons: true,
					}"
				>
					<ng-template #customCells let-item let-cellId="cellId">
						<!--name cell-->
						<span *ngIf="cellId === 'nameCell'">
							<div class="flxRow hSpace05" style="align-items: center; cursor: pointer;"
								 (click)="toggleExpandItem(item, $event); specificUsersOrGroupsTableEl.searchItem();">
								<i class="fa fa-chevron-right" style="color: royalblue; transition: transform 0.15s ease-out; padding: 5px;"
								   *ngIf="item.members?.length"
								   [ngStyle]="{'transform' : item.isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}"></i>
								<span style="padding: 7px 0;" [ngStyle]="{'margin-left' : item.members?.length || !item.depthLevel ? '' : '30px'}">
									{{item.name || item.email}}
								</span>
							</div>
						</span>
					</ng-template>
				</trustifi-table-c>
				<div class="flex-row flex-wrap flex-shrink-0 overflow-auto justify-content-start gap-2 align-items-baseline" style="height: 62px; padding-left: 0.3rem; padding-right: 0.3rem;">
					<div *ngFor="let recipient of _.filter(usersPopup.users, 'selected'); trackBy: gs.trackByIndex"
						 class="p-1 d-flex hSpace05 align-items-center rounded-1 bg-dark"
						 (click)="recipient.selected = false; specificUsersOrGroupsTableEl.searchItem();"
						 style="cursor: pointer; --bs-bg-opacity: .07; line-height: 16px;">
						<span [ngStyle]="{'font-weight' : !recipient.members?.length ? '' : '500'}">{{recipient.email}}</span>
						<span style="font-weight: 300; font-size: 12px;" *ngIf="recipient.members?.length"> (group)</span>
						<i class="zmdi zmdi-close"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</popup-c>

<popup-c class="bs-enabled" *ngIf="groupMembersComperisonPopup?.show"
		 head="Distributon in Risky Group"
		 subTitle="Review the distribution in the group between members who failed the threat simulation campaign and members who passed"
		 size="large"
		 (closeCb)="groupMembersComperisonPopup = null;"
		 [buttons]="{cancel: 'OK'}">
	<div class="flex-column f-1-0 gap-3 overflow-auto">
		<search-box style="width: 250px;"
					[(model)]="groupMembersComperisonPopup.searchMemberTxt"
					(modelChange)="groupMembersComperisonPopup.searchMemberFn();"
					placeholder="Search member">
		</search-box>

		<div class="flex-row gap-4 f-1-0">
			<ng-container *ngTemplateOutlet="membersTableTemplate; context: {label: 'Failed', list: groupMembersComperisonPopup.clickingUsers}"></ng-container>
			<ng-container *ngTemplateOutlet="membersTableTemplate; context: {label: 'Passed', list: groupMembersComperisonPopup.notClickingUsers}"></ng-container>

			<ng-template #membersTableTemplate let-label="label" let-list="list">
				<div class="flex-column f-1-0 gap-3 border border-secondary-subtle rounded-2 p-3">
					<trustifi-table-c
						#membersTableEl
						class="trustifi-table f-1-0" style="z-index: 5;"
						[list]="list"
						[cellsPrototype]="[
									  {html: 'nameCell'},
								  ]"
						[options]="{
										itemsNameSingular: 'member',
										noHeader: true
									}"

					>
						<ng-template #customCells let-item let-cellId="cellId">
							<!--name cell-->
							<span *ngIf="cellId === 'nameCell'" class="h100">
									{{item.name ? item.name === item.email ? item.name : item.name + " (" + item.email + ")" : item.email}}
								</span>
						</ng-template>
						<div tableButtons>
							<div class="fw-bold" style="font-size: 1rem; min-height: 27px;"
								  [ngClass]="label === 'Failed' ? 'text-danger' : 'text-success'">
								{{label}}
							</div>
						</div>
					</trustifi-table-c>
				</div>
			</ng-template>
		</div>
	</div>
</popup-c>
