'use strict';

import _ from 'lodash';
import {SERVERCONF} from "../constants";
import {Component} from "@angular/core";
import {RouteService} from "../services/routeService";
import {GeneralService} from "../services/generalService";

@Component({
	selector: 'cloudsponge-component',
	template: '<ng-content></ng-content>',
})
export class CloudspongeComponent{

	constructor(private rs:RouteService,
				private gs:GeneralService) {
		this.gs.cloudspongeLaunch = () => {
			const ApiSource = 'https://api.cloudsponge.com/widget/' + SERVERCONF.cloudspongeId + '.js';
			if (this.isMyScriptLoaded(ApiSource)){
				window['cloudsponge'].launch();
				return;
			}

			let script:any = document.createElement('script');
			script.async = true;
			script.src = ApiSource;
			script.onload = () => {
				window['cloudsponge'].init({
					// configure the widget for a friendlier mobile interface
					mobile_render: true,
					inlineOauth: 'mobile',
					alwaysShowCTA: true,
					afterInit: this.afterInit,

					//sources: [gmail, yahoo, windowslive, csv, linkedin, aol, icloud, outlook, addressbook, plaxo, mail_ru, uol, bol, terra, rediff, mail126, mail163, mail_yeah_net, gmx, qip_ru, sapo, mailcom, yandex_ru],

					// pass the full address book
					/*beforeDisplayContacts:function(contacts, source, owner) {
					 // obtain access to the ContactListCtrl's scope
					 let scope = angular.element(document.getElementById('ContactListCtrl')).scope();
					 scope.$apply(function() {
					 scope.fullAddressbook = contacts;
					 });
					 },*/

					// pass only the selected contacts
					afterSubmitContacts: this.afterSubmitContacts
				});
			};

			document.head.appendChild(script);
		}
	}

	afterInit = () => {
		window['cloudsponge'].launch();
	}

	afterSubmitContacts = (contacts, source) => {
		contacts = _.map(contacts, (itm) => {
			let contact:any = {
				email: itm.selectedEmail(),
				name: itm.first_name || itm.last_name ? itm.fullName() : ''
			};
			if (itm.__selectedPhone__) {
				contact.phone = this.parsePhoneNumber(itm.__selectedPhone__);
			}
			return contact;

		});

		this.rs.addNewContact({
			contacts: contacts,
			skipPhoneVerify: true
		}).then((response) => {
			this.gs.cloudspongeUpdated && this.gs.cloudspongeUpdated();
		}, (err) => {
			console.error(err.data);
		});
	}

	parsePhoneNumber = (phoneInput) => {
		let phoneData = {
			country_code: "",
			phone_number: ""
		};

		phoneInput = phoneInput.replace(/[-+()]/g, '');

		if (phoneInput.startsWith("1")) {
			phoneData.country_code = "1";
			phoneData.phone_number = phoneInput.substring(1);
		}
		else {
			if (phoneInput.startsWith("972")) {
				phoneData.country_code = "972";
				phoneData.phone_number = phoneInput.substring(3);
			}
			else {
				if (phoneInput.startsWith("61")) {
					phoneData.country_code = "61";
					phoneData.phone_number = phoneInput.substring(2);
				}
				else {
					if (phoneInput.startsWith("55")) {
						phoneData.country_code = "55";
						phoneData.phone_number = phoneInput.substring(2);
					}
					else {
						if (phoneInput.startsWith("91")) {
							phoneData.country_code = "91";
							phoneData.phone_number = phoneInput.substring(2);
						}
					}

				}
			}
		}

		return phoneData;
	}

	isMyScriptLoaded = (src) => {
		const scripts = document.head.getElementsByTagName('script');
		for (let i = scripts.length; i--;) {
			if (scripts[i].src == src) {
				return true;
			}
		}
		return false;
	}
}
