import * as util from 'util';
import _ from 'lodash';
import {RouteService} from '../../../../../services/routeService';
import {NotificationService} from '../../../../../services/notificationService';
import {GeneralService} from '../../../../../services/generalService';
import {DICTIONARY} from '../../../../../dictionary';
import {Component, OnInit} from "@angular/core";


@Component({
	selector: 'inbound-block-file-types-component',
	templateUrl: './inbound-block-file-types.component.html',
})
export class InboundBlockFileTypesComponent implements OnInit {
    dic = DICTIONARY;
    getTPConfigInProcess = true;
    userInfo: any;
    block_files: any;
    extensionActionInProcess: boolean;
    updateFiltersConfigurationInProcess: boolean;
    isAddRecordActive: any;
	updateFileDescPopup: any;

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
    }

	ngOnInit() {
        this.getTpBlockFilesConfig();

        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
        });
    }

    getTpBlockFilesConfig = () => {
        this.getTPConfigInProcess = true;
        this.rs.getTpBlockFilesConfig().then((response) => {
            this.block_files = response.block_files;
            this.getTPConfigInProcess = false;

            if (this.block_files.uncommon_file_extensions && this.block_files.uncommon_file_extensions.length) {
                this.block_files.uncommon_file_extensions.forEach((extensionObj) => {
                    extensionObj.counter = extensionObj.counter || 0;
                });
            }
        }, (err) => {
            this.getTPConfigInProcess = false;
        });
    }

    showExtensionActions = (extension) => {
        let actions = [];

        if (extension.block) {
            actions.push(DICTIONARY.CONSTANTS.configUncommonTypesAction.allowType);
        }
        else {
            actions.push(DICTIONARY.CONSTANTS.configUncommonTypesAction.blockType);
        }

		actions.push(DICTIONARY.CONSTANTS.configUncommonTypesAction.description, DICTIONARY.CONSTANTS.configUncommonTypesAction.remove);

        return actions;
    }

    showExtensionBulkActions = () => {
        return [
            {
                display: 'Block/Allow',
                submenu: [
                    DICTIONARY.CONSTANTS.configUncommonTypesAction.allowType,
                    DICTIONARY.CONSTANTS.configUncommonTypesAction.blockType
                ]
            },
            DICTIONARY.CONSTANTS.configUncommonTypesAction.remove
        ]
    }

    selectExtensionAction = (extension, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.allowType:
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.blockType:
                this.updateUncommonTypeStatus([extension]);
                break;

			case DICTIONARY.CONSTANTS.configUncommonTypesAction.description:
				this.updateUncommonTypeDescription(extension);
				break;

            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.deleteUncommonType([extension]);
                break;
        }
    };

    selectMultipleExtensionAction = (selectedItems, action) => {
        switch (action) {
            case DICTIONARY.CONSTANTS.configUncommonTypesAction.allowType:
                this.updateUncommonTypeStatus(selectedItems, DICTIONARY.CONSTANTS.configUncommonTypesAction.allowType);
                break;

            case DICTIONARY.CONSTANTS.configUncommonTypesAction.blockType:
                this.updateUncommonTypeStatus(selectedItems, DICTIONARY.CONSTANTS.configUncommonTypesAction.blockType);
                break;

            case DICTIONARY.CONSTANTS.configUncommonTypesAction.remove:
                this.deleteUncommonType(selectedItems);
                break;
        }
    };


    updateBlockFileTypeStatus = (key, blockActionStr) => {
        if (blockActionStr === "only_external" || blockActionStr === "only_untrusted_contacts") {
            this.updateBlockFileTypeStatusExecute(key);
            return;
        }

        this.gs.showPopup({
            title: `Block ${blockActionStr}`,
            subTitle: `You are about to ${this.block_files[key].enabled ? 'enable' : 'disable'} automatic blocking of ${blockActionStr}`,
            body: [`Emails containing this file type will ${this.block_files[key].enabled ? 'be' : 'not be'} labeled as malicious`],
            type: DICTIONARY.CONSTANTS.popupWarning,
            doneBtnText: 'Confirm',
            doneCb: () =>{
                this.updateBlockFileTypeStatusExecute(key);
            },
            cancelCb: () => {
                this.block_files[key].enabled = !this.block_files[key].enabled;
            }
        });
    };

    updateBlockFileTypeStatusExecute = (key) => {
        const data = {
            type: DICTIONARY.CONSTANTS.configTpAction.blockAttachmentType,
            blockKey: key,
            enabled: this.block_files[key].enabled,
            only_external: this.block_files[key].only_external || false,
            only_untrusted_contacts: this.block_files[key].only_untrusted_contacts || false
        };

        this.updateFiltersConfigurationInProcess = true;
        this.rs.updateBlockFilesConfig(data).then(() => {
            this.updateFiltersConfigurationInProcess = false;
            this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
        }, err => {
            this.updateFiltersConfigurationInProcess = false;
        });
    };

    updateUncommonTypeStatus = (typeObjects, action=null) => { // action: allow/block
        this.extensionActionInProcess = true;
        let counter = 0; // indicator to know all tasks are done
        typeObjects.forEach((typeObj) => {
            let data = {
                type: DICTIONARY.CONSTANTS.configTpAction.uncommonType,
                action: DICTIONARY.CONSTANTS.actions.update,
                extension: typeObj.extension,
				block: !typeObj.block,
				description: typeObj.description
            };

            // change block state for file type only if desired "block" state is different from current
            if (!action || (action === DICTIONARY.CONSTANTS.configUncommonTypesAction.allowType && typeObj.block === true) || (action === DICTIONARY.CONSTANTS.configUncommonTypesAction.blockType && typeObj.block === false)) {
                this.rs.updateBlockFilesConfig(data).then(() => {
                    typeObj.block = !typeObj.block;
                    counter++;
                    if (counter === typeObjects.length) {
                        this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
                        this.extensionActionInProcess = false;
                    }
                });
            } else {
                counter++;
                if (counter === typeObjects.length) {
                    this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
                    this.extensionActionInProcess = false;
                }
            }
        });
    }

	updateUncommonTypeDescription = (typeObj) => {
		this.updateFileDescPopup = {
			show: true,
			desc: typeObj.description,
			doneCb: () => {
				let data = {
					type: DICTIONARY.CONSTANTS.configTpAction.uncommonType,
					action: DICTIONARY.CONSTANTS.actions.update,
					extension: typeObj.extension,
					block: typeObj.block,
					description: this.updateFileDescPopup.desc
				};

				this.rs.updateBlockFilesConfig(data).then(() => {
					typeObj.description = this.updateFileDescPopup.desc;
					this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
					this.updateFileDescPopup = null;
				});
			}
		};
	}

    deleteUncommonType = (typeObjects) => {
        this.gs.showPopup({
            title: `Delete ${typeObjects.length === 1 ? typeObjects[0].extension : `${typeObjects.length} extensions`}`,
            subTitle: `You are about to delete ${typeObjects.length === 1 ? typeObjects[0].extension : `${typeObjects.length} extensions`} from uncommon file extensions`,
            body: [],
            type: DICTIONARY.CONSTANTS.popupWarning,
            doneBtnText: DICTIONARY.CONSTANTS.inboundConfigurationsActions.remove,
            doneCb: () => {
                let counter = 0;
                this.extensionActionInProcess = true;
                typeObjects.forEach((typeObj) => {
                    let data = {
                        type: DICTIONARY.CONSTANTS.configTpAction.uncommonType,
                        action: DICTIONARY.CONSTANTS.actions.delete,
                        extension: typeObj.extension
                    };

                    this.rs.updateBlockFilesConfig(data).then(() => {
                        _.remove<any>(this.block_files.uncommon_file_extensions, itm => itm.extension === typeObj.extension);
                        counter++;
                        if (counter === typeObjects.length) {
                            this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
                            this.extensionActionInProcess = false;
                        }
                    });
                });
            }
        });
    };

    startAddRecordNewExtension = () => {
        if (this.isAddRecordActive) {
            return;
        }

        this.isAddRecordActive = true;

        const newRecord = {
            isEditMode: true,
            isNew: true,
            edit: {
				extension: '',
				description: '',
				block: true
            }
        };

        this.block_files.uncommon_file_extensions.unshift(newRecord);
    };

    confirmNewUncommonType = (uncommonTypeObj, isApproved) => {
        // new domain process was declined
        if (!isApproved) {
            _.remove<any>(this.block_files.uncommon_file_extensions, uncommonTypeObj);
            this.isAddRecordActive = false;
            return;
        }
        //

        // new domain process approved
        // validation
        if (!uncommonTypeObj.edit.extension) {
            return this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.accountEmailMissingName, 'Extension field'));
        }
		uncommonTypeObj.confirmEditInProcess = true;
        uncommonTypeObj.extension = uncommonTypeObj.edit.extension;
		uncommonTypeObj.description = uncommonTypeObj.edit.description;
		uncommonTypeObj.block = uncommonTypeObj.edit.block;
		// removing '.'
		if (uncommonTypeObj.extension.startsWith('.')) {
			uncommonTypeObj.extension = uncommonTypeObj.extension.slice(1);
		}

        const data = {
            type: DICTIONARY.CONSTANTS.configTpAction.uncommonType,
            action: DICTIONARY.CONSTANTS.actions.add,
            extension: uncommonTypeObj.extension,
			block: uncommonTypeObj.block,
			description: uncommonTypeObj.edit.description
        };

        this.rs.updateBlockFilesConfig(data).then((response) => {
            uncommonTypeObj.edit = null;
			uncommonTypeObj.confirmEditInProcess = false;
            uncommonTypeObj.isEditMode = false;
            uncommonTypeObj.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit
            uncommonTypeObj.counter = 0;

            this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.itemAdded, uncommonTypeObj.extension));
            this.isAddRecordActive = false;
        }, (err) => {
			uncommonTypeObj.confirmEditInProcess = false;
            this.isAddRecordActive = false;
        });
    };

    searchExtension = (searchTerm, activeFilters) => {
        this.block_files.uncommon_file_extensions.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = (record.extension && record.extension.toLowerCase().indexOf(searchTerm) >= 0);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            // // no filters for uncommon file types table

            record.hide = false;
        });
    };
}
