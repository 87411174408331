
import * as util from 'util';
import {DICTIONARY} from '../../../../../dictionary';
import {RouteService} from '../../../../../services/routeService';
import {NotificationService} from '../../../../../services/notificationService';
import {GeneralService} from '../../../../../services/generalService';
import {ENV_CONSTS} from '../../../../../constants';
import {Component, OnInit} from "@angular/core";
import { ClipboardService } from 'ngx-clipboard';

@Component({
	selector: 'shared-plan-settings-component',
	templateUrl: './shared-plan-settings.component.html',
})
export class SharedPlanSettingsComponent implements OnInit {
    dic = DICTIONARY;
    corpname = this.gs.corpname;
    originalGroups: any;
    planInfo: any;
    userInfo: any;
    customerId: any;
    isPartnerAdmin: any;
    sharedPlanInfo: any;
    genNewKey: any;
    regenerateInProcess: boolean;
    disableSharedPlanEmail: any;
    updatedAPI: boolean;

    constructor(private gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private clipboard: ClipboardService) {
        window['reactivePlanKey'] = this.reactivePlanKey.bind(this);
    }

	ngOnInit() {
        this.originalGroups = null;

        this.rs.getOutboundPlanSettings().then((planInfo) => {
            this.planInfo = planInfo;

            this.gs.getUserInfo(false, (userInfo) => {
                this.userInfo = userInfo;
                this.customerId = userInfo.plan.customer_id;
                this.isPartnerAdmin = this.gs.isPartnerAdmin(this.userInfo);

                this.sharedPlanInfo = this.planInfo;
                if (this.userInfo.user_role === this.dic.CONSTANTS.userRole.admin
                    || this.userInfo.user_role === this.dic.CONSTANTS.userRole.subAdmin) {
                    let now = new Date();
                    if (now > new Date(this.sharedPlanInfo.shared_plan.key_expires)) {
                        this.sharedPlanInfo.isExpired = true;
                    }

                    if (this.sharedPlanInfo.shared_plan.key_bad_attempts > this.dic.CONSTANTS.planMaxKeyAttempts) {
                        this.sharedPlanInfo.isExpired = true;
                    }
                }
            });
        }, (err) => {

		});
    };

    reactivePlanKey = () => {
        this.activateSharedPlan(true);
    };

    activateSharedPlan(genNewKey, enforceDomain=false) {
        let title = "", button = "", subTitle = "", body = [], bodyDetails = [];
        this.genNewKey = genNewKey;

        let popupType = this.dic.CONSTANTS.popupWarning;
        if (genNewKey) {
            button = "Regenerate";
            title = "Regenerate Shared Plan Key";
            subTitle = "You are about to change the plan key!";
            body = ["Users who have the old key and have not signed up yet - will not be able to join.",
                "Users who have already joined the plan - will not be affected."
            ];
        }

        if (enforceDomain) {
            if (this.sharedPlanInfo.shared_plan.enforce_domain) {
                button = "Enable";
                title = "Enable Domain Enforcement";
                subTitle = "You are about to enable domain enforcement!";
                body = ["Only users under the plan admin's domain may join the plan."];
                popupType = this.dic.CONSTANTS.popupInfo;
            }
            else {
                button = "Disable";
                title = "Disable Domain Enforcement";
                subTitle = "You are about to disable domain enforcement!";
                body = ["Any user from any domain may join the plan."];
            }
        }

        setTimeout(() => {
            this.gs.showPopup({
                title: title,
                subTitle: subTitle,
                body: body,
                bodyDetails: bodyDetails,
                type: popupType,
                doneBtnText: button,
                doneCb: (body) => this.changeSharedPlanStatus(body),
                cancelCb: () => {
                    if (enforceDomain) {
                        this.sharedPlanInfo.shared_plan.enforce_domain = !this.sharedPlanInfo.shared_plan.enforce_domain;
                    }
                }
            });
        });
    };

    changeSharedPlanStatus(body) {
        this.regenerateInProcess = true;
        if (body) {
            this.disableSharedPlanEmail = body[0].model;
        }
        this.updatedAPI = false;
        this.rs.doActionOnOutboundPlan({
            action: this.dic.CONSTANTS.planOutboundAction.sharedPlanKey,
            enabled: true,
            regenerate: this.genNewKey,
            enforce_domain: this.sharedPlanInfo.shared_plan.enforce_domain,
            email: this.disableSharedPlanEmail
        }).then((response) => {
            this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
            this.ngOnInit();
        });
    };

    copyKeyToClipboard(onlyKey = false) {
        if (onlyKey) {
            this.clipboard.copy(this.sharedPlanInfo.shared_plan.key);
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.copyClipboard, "Plan key"));
        }
        else {
            this.clipboard.copy(ENV_CONSTS.webAppUrl + "/signup/" + this.sharedPlanInfo.shared_plan.key);
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.copyClipboard, "Plan sign-up link"));
        }
    };
}
