<div class="flxClmn animated fadeIn message-tab-content" style="flex: 1 0; overflow: auto; padding: 0 15px;">
	<div class="actionRow flex-container" style="position: sticky; top: 0; background: white; z-index: 50;">
		<div class="actionLabel flex-item">Actions</div>
		<div class="actionItem flex-item"
			 *ngIf="allowPolicyRecall && (myEmailsService.currentMessage.is_draft || (myEmailsService.currentMessage.methods.encrypt_content || (!myEmailsService.currentMessage.start_sending_time && myEmailsService.currentMessage.scheduled_time)) && !myEmailsService.currentMessage.methods.secure_mass  && !myEmailsService.currentMessage.reply_to)"
			 (click)="doEmailAction('updateContent');">
			<a id="updateMessage">Update message</a>
		</div>
		<div class="actionItem flex-item"
			 id="detailsBlockEmail"
			 *ngIf="!myEmailsService.currentMessage.methods.secure_mass && (myEmailsService.currentMessage.methods.encrypt_content || myEmailsService.currentMessage.methods.secure_send || (myEmailsService.currentMessage.sent.attachments.length && !myEmailsService.currentMessage.skip_attachments_encryption)) && myEmailsService.currentMessage.start_sending_time "
			 [ngStyle]="myEmailsService.currentMessage.numberRecipientsBlocked === myEmailsService.currentMessage.recipients_count && {'opacity':'0.5','cursor':'not-allowed'}"
			 (click)="doEmailAction('blockEmail');">
			<a [ngClass]="myEmailsService.currentMessage.numberRecipientsBlocked === myEmailsService.currentMessage.recipients_count ? 'disabled' : ''">Block email</a>
		</div>
		<div class="actionItem flex-item"
			 id="detailsUnblockEmail"
			 *ngIf="!myEmailsService.currentMessage.methods.secure_mass && (myEmailsService.currentMessage.methods.encrypt_content || myEmailsService.currentMessage.methods.secure_reply || myEmailsService.currentMessage.methods.secure_send || (myEmailsService.currentMessage.sent.attachments.length && !myEmailsService.currentMessage.skip_attachments_encryption)) && myEmailsService.currentMessage.start_sending_time "
			 [ngStyle]="myEmailsService.currentMessage.numberRecipientsBlocked === 0 && {'opacity':'0.5','cursor':'not-allowed'}"
			 (click)="doEmailAction('unblockEmail');">
			<a [ngClass]="myEmailsService.currentMessage.numberRecipientsBlocked === 0 ? 'disabled' : ''">Unblock email</a>
		</div>
	</div>

    <div class="row alt">
        <div style="padding: 0 0 0 15px; width: 90%;">
            <div class="flex-wrap" style="justify-content: flex-start; margin-top: 2px;">
                <div style="margin-right: 50px; min-width: 450px;" [ngClass]="{'disabled input-disabled': allow_admin_policy_only}">
                    <div class="checkbox-block">
                        <span class="title"><b>Email Security</b></span>


                        <div class="holder flxRow hSpace1" style="position: relative; align-items: center;"
                             [ngClass]="{'disabled': !myEmailsService.currentMessage.methods.secure_send}">
                            <label style="display: inline-block;">Method</label>
                            <app-dropdown-c style="width: 95px; height: 22px;"
                                          [text]="myEmailsService.currentMessage.advanced.secure.method_2factor"
                                          id="advancedSecureMethods">
                                <li *ngFor="let method of dic.CONSTANTS.secureMethods"
                                    (click)="updateAuthMethod(method, $event);"
                                    [ngClass]="{'disabled': !secureMethods.includes(method)}"
                                    id="{{method}}">
									{{method}}
                                    <i *ngIf="!secureMethods.includes(method)"
                                       style="color:#818181; padding-left:1px; position: relative;"
                                       class="zmdi zmdi-lock-outline"
                                       tooltip="This option is blocked by your plan admin"
                                       placement="top-right">
                                    </i>
                                </li>
                            </app-dropdown-c>
							<div style="position: relative;">
								<input style="font-size:0.77rem; padding-right: 24px; width: 132px; height: inherit; margin-bottom:0;"
									   *ngIf="myEmailsService.currentMessage.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.password"
									   type="{{!showpassword && !hasWKTextSecurity ? 'password':'text'}}"
									   id="method_2factor_password"
									   (blur)="updateAdvancedMessage();"
									   [ngClass]="{nofill: !showpassword}"
									   [(ngModel)]="myEmailsService.currentMessage.advanced.secure.method_2factor_password"
									   placeholder="Default password" size="15">
								<label style="position: absolute; transform: translateY(-50%); top: 50%; right: 5px; opacity: 0.2;"
									   *ngIf="myEmailsService.currentMessage.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.password"
									   class="hidepwd">
									<input type="checkbox" [(ngModel)]="showpassword"/>
									<i class="fa fa-eye unchecked"></i>
									<i class="fa fa-eye-slash checked"></i>
								</label>
							</div>
                           <input style="width: 132px; height: inherit; margin-bottom:0; margin-left: 7px;"
                                  *ngIf="myEmailsService.currentMessage.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.password && !'(max-width: 1280px)'"
                                  type="text" (change)="updateAdvancedMessage();"
                                  [(ngModel)]="myEmailsService.currentMessage.advanced.secure.method_2factor_password_hint"
                                  placeholder="Hint" size="15">
                            <input style="width: 132px; height: inherit; margin-bottom:0;"
                                  *ngIf="myEmailsService.currentMessage.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.phone
                                           || myEmailsService.currentMessage.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.email"
                                  type="text" id="method_2factor_phone"
                                  placeholder="PIN Code" size="15" disabled>
							<div style="margin-left: auto">
								<i *ngIf="!myEmailsService.currentMessage.methods.secure_send" tooltip='Option can be enabled only for emails with "Require Authentication"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>


                        <div class="holder flxRow hSpace1" style="padding-left: 10px; align-items: center;" [ngClass]="{'disabled': !myEmailsService.currentMessage.methods.secure_send}"
                             *ngIf="'(max-width: 1280px)' && myEmailsService.currentMessage.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.password">
                            <label style="display: inline-block;">Hint</label>
                            <input style="width: 237px; margin-bottom: 0; margin-left: 12px; height: inherit;"
                                   *ngIf="myEmailsService.currentMessage.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.password"
                                   type="text"
                                   (blur)="updateAdvancedMessage();"
                                   [(ngModel)]="myEmailsService.currentMessage.advanced.secure.method_2factor_password_hint"
                                   placeholder="Hint" size="15">
                        </div>

                        <div class="holder flxRow hSpace1" style="padding-left: 10px; align-items: center;" *ngIf="myEmailsService.currentMessage.advanced.secure.method_2factor === dic.CONSTANTS.authMethods.password">
                            <toggle-switch-c id="useContactPassword"
                                           [(model)]="myEmailsService.currentMessage.advanced.secure.use_contact_2factor_password"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Use contact's password</label>
							<div style="margin-left: auto">
								<i class="fas fa-question-circle" style="font-weight: 400; position: relative;" tooltip="Use the contact's personal MFA password if one exists"></i>
							</div>
                        </div>

                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': !myEmailsService.currentMessage.methods.secure_send}">
                            <toggle-switch-c id="smartAuthentication"
                                           [(model)]="myEmailsService.currentMessage.advanced.secure.enable_smart_authentication"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Enable Smart Authentication</label>
							<div style="margin-left: auto">
								<i *ngIf="!myEmailsService.currentMessage.methods.secure_send" tooltip='Option can be enabled only for emails with "Require Authentication"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>

                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': (!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content) || userInfo.plan.policy.expired_enable.strict}">
                            <toggle-switch-c id="expiresInXdays"
                                           [(model)]="myEmailsService.currentMessage.advanced.secure.expired_enable"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Expires in</label>
                            <input [required]="myEmailsService.currentMessage.advanced.secure.expired_days"
                                   style="width: inherit; height: inherit; margin-bottom:0; box-sizing: border-box; border-radius: 4px; border: 1px solid #ccc; padding: 2px 8px;"
                                   type="number" min="1" max="365" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
                                   onkeydown="return event.keyCode !== 69 && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 187 && event.keyCode !== 190;"
                                   id="expired_days" name="expired_days"
                                   (blur)="updateAdvancedMessage();"
                                   [(ngModel)]="myEmailsService.currentMessage.advanced.secure.expired_days" size="5"
                                   placeholder="Days">
                            <label>Days</label>
							<div style="margin-left: auto">
								<i *ngIf="!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content" tooltip='Option can be enabled only for emails with "Encrypt Message Content" or "Require Authentication"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>


                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': !myEmailsService.currentMessage.sent.attachments.length || userInfo.plan.policy.delete_attachment_enable.strict}">
                            <toggle-switch-c id="deleteAttachmentsInXdays"
                                           [(model)]="myEmailsService.currentMessage.advanced.secure.delete_attachment_enable"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Delete attachments in</label>
                            <input [required]="myEmailsService.currentMessage.advanced.secure.delete_attachment_days"
                                   style="width: inherit; height: inherit; margin-bottom:0; box-sizing: border-box; border-radius: 4px; border: 1px solid #ccc; padding: 2px 8px;"
                                   type="number" min="1" max="365" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
                                   onkeydown="return event.keyCode !== 69 && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 187 && event.keyCode !== 190;"
                                   id="delete_attachment_days" name="delete_attachment_days"
                                   (blur)="updateAdvancedMessage();"
                                   [(ngModel)]="myEmailsService.currentMessage.advanced.secure.delete_attachment_days" size="5"
                                   placeholder="Days">
                            <label>Days</label>
							<div style="margin-left: auto">
								<i *ngIf="!myEmailsService.currentMessage.sent.attachments.length" tooltip='Option can be enabled only for emails with attachments' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>

						<div class="holder flxRow hSpace1 disabled" style="align-items: center;">
							<toggle-switch-c id="enforceAttachmentEncryption"
										   [isDisabled]="true"
										   [(model)]="myEmailsService.currentMessage.advanced.secure.enforce_attachment_encryption"
										   [hideText]="true"
										   (change)="updateAdvancedMessage()"></toggle-switch-c>
							<label>Enforce attachment encryption</label>
							<div style="margin-left: auto">
								<i tooltip='Cannot be changed after the email has been sent' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
						</div>

						<div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': (!myEmailsService.currentMessage.sent.attachments.length || (!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content))}">
							<toggle-switch-c id="attachments_preview_mode"
											 [isDisabled]="!myEmailsService.currentMessage.sent.attachments.length || (!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content)"
											 [(model)]="myEmailsService.currentMessage.advanced.secure.attachments_preview_mode"
											 [hideText]="true"
											 (change)="updateAdvancedMessage()"></toggle-switch-c>
							<label>Attachments preview only mode</label>
							<div style="margin-left: auto">
								<i *ngIf="!myEmailsService.currentMessage.sent.attachments.length || (!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content)" tooltip='Option can be enabled only for emails with "Encrypt Message Content" or "Require Authentication"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
						</div>

                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': (!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content)}">
                            <toggle-switch-c id="allowPrint"
                                           [(model)]="myEmailsService.currentMessage.advanced.secure.enable_print"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Allow print</label>
							<div style="margin-left: auto">
                            	<i *ngIf="!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content" tooltip='Option can be enabled only for emails with "Encrypt Message Content" or "Require Authentication"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>

                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': userInfo.plan.policy.secure_received.strict || (!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content)}">
                            <toggle-switch-c id="authOnReplies"
                                           [(model)]="myEmailsService.currentMessage.advanced.secure.secure_received"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Require Authentication on replies</label>
							<div style="margin-left: auto">
                            	<i *ngIf="!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content" tooltip='Option can be enabled only for emails with "Encrypt Message Content" or "Require Authentication"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>
                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': !myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content && (!myEmailsService.currentMessage.sent.attachments || !myEmailsService.currentMessage.sent.attachments.length)}">
                            <toggle-switch-c id="accessOnlyOnce"
                                           [(model)]="myEmailsService.currentMessage.advanced.secure.open_only_once"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Allow email access only once</label>
							<div style="margin-left: auto">
                            	<i *ngIf="!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content && (!myEmailsService.currentMessage.sent.attachments || !myEmailsService.currentMessage.sent.attachments.length)" tooltip='Option can be enabled only for emails with "Encrypt Message Content", "Require Authentication" or attachments' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>
                    </div>
                    <div class="checkbox-block">
                        <span class="title"><b>General</b></span>
                        <div class="holder flxRow hSpace1" style="align-items: center;">
                            <toggle-switch-c id="notifyEmailOpened"
                                           [(model)]="myEmailsService.currentMessage.advanced.email_me.on_any_opened"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Notify me about emails opened</label>
                        </div>
                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': !myEmailsService.currentMessage.advanced.general.track_links}">
                            <toggle-switch-c id="notifyLinksClicked"
                                           [(model)]="myEmailsService.currentMessage.advanced.email_me.on_link_clicked"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Notify me about links clicked</label>
                            <i *ngIf="!myEmailsService.currentMessage.advanced.general.track_links" tooltip='Option can be enabled only for emails with "Track Links"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
                        </div>
                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': !myEmailsService.currentMessage.sent.attachments.length}">
                            <toggle-switch-c id="notifyFilesDownloaded"
                                           [(model)]="myEmailsService.currentMessage.advanced.email_me.on_attachment_download"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Notify me about files downloaded</label>
							<div style="margin-left: auto">
								<i *ngIf="!myEmailsService.currentMessage.sent.attachments.length" tooltip='Option can be enabled only for emails with attachments' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>
                        <div class="holder flxRow hSpace1 disabled" style="align-items: center;">
                            <toggle-switch-c id="replyToEmail"
                                           [(model)]="myEmailsService.currentMessage.advanced.general.reply_to_enable"
                                           [isDisabled]="true"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Reply to</label>
                            <input style="width: inherit; height: inherit; margin-bottom:0;"
                                   *ngIf="myEmailsService.currentMessage.advanced.general.reply_to_enable"
                                   type="text" id="reply_to_email" name="reply_to_email" placeholder="My Email"
                                   (blur)="updateAdvancedMessage();"
                                   [ngClass]="{'validation-error' : myEmailsService.currentMessage.advanced.general.reply_to_email && !gs.validateEmail(myEmailsService.currentMessage.advanced.general.reply_to_email)}"
                                   [(ngModel)]="myEmailsService.currentMessage.advanced.general.reply_to_email" size="19">
                            <input style="width: inherit; height: inherit; margin-bottom:0;"
                                   *ngIf="!myEmailsService.currentMessage.advanced.general.reply_to_enable"
                                   type="text" id="reply_to_email_disable" name="reply_to_email"
                                   placeholder="{{'do-not-reply@' + domain}}"
                                   [ngClass]="{'validation-error' : myEmailsService.currentMessage.advanced.general.reply_to_email && !gs.validateEmail(myEmailsService.currentMessage.advanced.general.reply_to_email)}"
                                   size="19">
							<div style="margin-left: auto; margin-right: 0;">
								<i tooltip='Cannot be changed after the email has been sent' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>

                        <div class="holder flxRow hSpace1 disabled" style="align-items: center;">
                            <toggle-switch-c id="useTemplate"
                                           [isDisabled]="true"
                                           [(model)]="myEmailsService.currentMessage.advanced.general.trustifi_wrapper"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Use Default Template</label>
							<div style="margin-left: auto">
								<i tooltip='Cannot be changed after the email has been sent' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>
                        <div class="holder flxRow hSpace1 disabled" style="align-items: center;">
                            <toggle-switch-c id="trackLinks"
                                           [(model)]="myEmailsService.currentMessage.advanced.general.track_links"
                                           [isDisabled]="true"
                                           [hideText]="true"></toggle-switch-c>
                            <label>Track links</label>
							<div style="margin-left: auto">
								<i tooltip='Cannot be changed after the email has been sent' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>

                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': !myEmailsService.currentMessage.methods.secure_reply}">
                            <toggle-switch-c id="unlimitedReplies"
                                           [(model)]="myEmailsService.currentMessage.advanced.general.multi_replies"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Unlimited replies</label>
							<div style="margin-left: auto">
                            	<i *ngIf="!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content" tooltip='Option can be enabled only for emails with "Encrypt Message Content" or "Require Authentication"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>

						<div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': !myEmailsService.currentMessage.methods.secure_reply}">
							<toggle-switch-c id="enforceReplyAll"
										   [(model)]="myEmailsService.currentMessage.advanced.general.enforce_reply_all"
										   [hideText]="true"
										   (change)="updateAdvancedMessage()"></toggle-switch-c>
							<label>Enforce reply all</label>
							<div style="margin-left: auto">
								<i *ngIf="!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content" tooltip='Option can be enabled only for emails with "Encrypt Message Content" or "Require Authentication"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
						</div>

                        <div class="holder flxRow hSpace1" style="align-items: center;" [ngClass]="{'disabled': userInfo.plan.policy.allow_download_as_eml.strict || (!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content)}">
                            <toggle-switch-c id="allowDownloadEml"
                                           [(model)]="myEmailsService.currentMessage.advanced.general.allow_download_as_eml"
                                           [hideText]="true"
                                           (change)="updateAdvancedMessage()"></toggle-switch-c>
                            <label>Allow downloading as EML</label>
							<div style="margin-left: auto">
                            	<i *ngIf="!myEmailsService.currentMessage.methods.secure_send && !myEmailsService.currentMessage.methods.encrypt_content" tooltip='Option can be enabled only for emails with "Encrypt Message Content" or "Require Authentication"' style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
