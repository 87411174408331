import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {myEmailsService} from "../../../services/myEmailsService";


@Component({
	selector: 'sent-email-summary-component',
	templateUrl: './sent-email-summary.component.html',
})
export class SentEmailSummaryComponent implements OnInit{

    constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService,
				public myEmailsService:myEmailsService) {
    }

    dic = DICTIONARY;

    center = {
        lat: 36.505,
        lng: -115.09,
        zoom: 6
    };
    markers;
    enableRefresh;
    tab;
    maxCountRefresh;
    getSummaryDataInProcess;
	prepareFullReportPopup;


    ngOnInit() {
        this.doEmailAction('prepareSummary', {reload: true, isFirst: true});
    };

    doEmailAction = (action, params=null) => {
        switch (action) {
            case 'exportSummaryCsv':
                this.exportSummaryCsv(this.myEmailsService.currentMessage);
                break;

            case 'prepareSummary':
                this.getSummaryData(this.myEmailsService.currentMessage, params);
                break;

            case 'prepareFullReport':
                this.prepareFullReport(this.myEmailsService.currentMessage);
                break;

            default:
                this.myEmailsService.doEmailAction(action);
        }
    }

    exportSummaryCsv = (currentMessage) => {
        let csvString = "name,value\n";
        csvString += `Status,${currentMessage.summaryData.final_status_text}\n`;
        if (currentMessage.summaryData.general) {
            const gmtTime = this.gs.getGMT();
            let value;
            currentMessage.summaryData.general.forEach((item) => {
                if (item) {
                    if (item.type === 'Date') {
                        let date = new Date(item.value);
                        date.setHours(date.getHours() + gmtTime);
                        value = date.toISOString();
                    }
                    else {
                        value = item.value;
                    }
                    csvString += `${item.name},${value}\n`;
                }
            });
        }

        if (currentMessage.summaryData.events && currentMessage.summaryData.events.length) {
            csvString += '\nEvents\n';
            currentMessage.summaryData.events.forEach((item) => {
                if (item) {
                    csvString += `${item.name},${item.value}\n`;
                }
            });
        }

        if (currentMessage.summaryData.links && currentMessage.summaryData.links.length) {
            csvString += '\nLinks\n';
            currentMessage.summaryData.links.forEach((item) => {
                if (item) {
                    csvString += `${item.name},${item.value}\n`;
                }
            });
        }

        this.gs.exportCsv(csvString, `summary_report_ ${currentMessage._id}.csv`);
    };

    prepareFullReport = (currentMessage) => {
        if (currentMessage.recipients_count > 3600000) {
            this.ns.showInfoMessage(this.dic.MESSAGES.summaryBig);
            return;
        }
        currentMessage.addRawData = false;
        currentMessage.notifyReady = true;
        // toggle popup for report
		this.prepareFullReportPopup = {
			show: true,
			applyInProcess: false,
			addDataToggle: currentMessage.addRawData,
			notifyReadyToggle: currentMessage.notifyReady,
			doneCb: () => {
				this.prepareFullReportPopup.applyInProcess = true;
				currentMessage.addRawData = this.prepareFullReportPopup.addDataToggle;
				currentMessage.notifyReady = this.prepareFullReportPopup.notifyReadyToggle;
				this.rs.prepareFullReport(currentMessage._id, {
					notifyReady: currentMessage.notifyReady,
					addRawData: currentMessage.addRawData,
				}).then( (response) => {
					this.prepareFullReportPopup.applyInProcess = false;
					this.prepareFullReportPopup.show = false;
				}, (err) => {
					this.prepareFullReportPopup.applyInProcess = false;
				});
			}
		};
    };

    downloadFile = (url, fileName, attachmentsSize) => {
		const fileObj = {name: fileName, type: 'application/csv', size: attachmentsSize};
        this.gs.downloadFile(url, fileObj, true,  (err) => {});
    };

    getEventDescriptions = (event) => {
        let evFromDic = _.find<any>(this.dic.EVENTS,  (ev) => {
            return (ev.name == event);
        });
        if (evFromDic)
            return evFromDic.desc;
        return 'No description';
    };

    setMarker = (lat, lng, title) => {
        const marker = {
            icon: this.gs.leafletDefaultIcon,
            lat: lat,
            lng: lng,
            message: title,
            title: title,
            draggable: false
        };

        this.markers.push(marker);
    }

    parseLocations = (locations) => {
        if (!locations || locations.length === 0) return;
        const centerPosition = [locations[0].ll[0], locations[0].ll[1]];

        this.center = {
            lat: centerPosition[0],
            lng: centerPosition[1],
            zoom: 6
        };

        this.markers = [];
        // limit to 10000 points
        for (let idx = 0; idx < locations.length && idx < 10000; idx++) {
            let location = locations[idx];
            if (location && location.ll.length === 2) {
                this.setMarker(location.ll[0], location.ll[1], location.email);
            }
        }
    };

    getOpenSummary = () => {

        if (this.myEmailsService.currentMessage.openSummaryReady) {
            this.myEmailsService.currentMessage.openSummaryReady = false;
            return;
        }

        this.rs.getOpenSummary(this.myEmailsService.currentMessage._id, {mass: this.myEmailsService.currentMessage.methods.secure_mass}).then( (response) => {
            this.myEmailsService.currentMessage.openSummary = response;
            this.parseLocations(this.myEmailsService.currentMessage.openSummary);
            this.myEmailsService.currentMessage.openSummaryReady = true;
        });
    };

    getGroupSummary =(currentMessage, reload, isFirst) => {
        if (!currentMessage.emailIds) {
            return;
        }

        let queryParams = {arr: currentMessage.emailIds.join(',')};

        if (isFirst) {
            this.rs.getEmailGroupSummary(queryParams).then((response) => {
                currentMessage.summaryData = response;
                if (response.final_status) {
                    currentMessage.final_status = response.final_status;
                }

                currentMessage.show_open_map = response.show_open_map;

                // allow refresh only if user on mailbox page, summary tab and up to 15 times
                if (this.enableRefresh && this.tab === this.dic.CONSTANTS.mailBoxTabs.summary && currentMessage.methods.secure_mass) {
                    this.enableRefresh = this.maxCountRefresh-- > 0 ? true : false;
                    setTimeout( (params) => {
                        if (this.tab === this.dic.CONSTANTS.mailBoxTabs.summary)
                            this.getGroupSummary(currentMessage, params.reload, false);
                    }, 8000, {reload: true, isFirst: true});
                }
            });
        }
        else {
            this.rs.getEmailGroupSummaryReload(queryParams).then((response) => {
                currentMessage.summaryData = response;
                if (response.final_status) {
                    currentMessage.final_status = response.final_status;
                }

                currentMessage.show_open_map = response.show_open_map;
            });
        }
    };

    getSummaryData =  (currentMessage, params) => {
        if (!params.reload && currentMessage.summaryData) {
            return;
        }

        // check if it's group type - call another function to group
        if (currentMessage.isCampaignTitle) {
            this.getGroupSummary(currentMessage, params.reload, params.isFirst);
            return;
        }

        this.getSummaryDataInProcess = true;

        if (params.isFirst) {
            this.rs.getEmailSummary(currentMessage._id, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then((response) => {
                if (response.final_status) {
                    currentMessage.final_status = response.final_status;
                }

                currentMessage.summaryData = response;
                this.checkForOpenEvent(currentMessage);
                this.getSummaryDataInProcess = false;

                if (response.final_report_key) {
                    currentMessage.final_report_key = response.final_report_key;
                }

                currentMessage.show_open_map = response.show_open_map;

                // allow refresh only if user on mailbox page, summary tab and up to 15 times
                if (this.enableRefresh && this.tab === this.dic.CONSTANTS.mailBoxTabs.summary && currentMessage.methods.secure_mass) {
                    this.enableRefresh = this.maxCountRefresh-- > 0;
                    setTimeout( (params) => {
                        if (this.tab === this.dic.CONSTANTS.mailBoxTabs.summary)
                            this.getSummaryData(currentMessage, params.reload);
                    }, 8000, {reload: true, isFirst: true});
                }

                // for mass list if no summary then request the first one
                if (currentMessage.methods.secure_mass && currentMessage.summaryData.general[0].value === "--/--/-- --:--") {
                    this.getSummaryData(currentMessage, {reload: true, isFirst: false});
                }

            }, (err) => {
                this.getSummaryDataInProcess = false;
            });
        }
        else {
            this.rs.getEmailSummaryWithInitMass(currentMessage._id, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then((response) => {
                if (response.final_status) {
                    currentMessage.final_status = response.final_status;
                }

                if (!currentMessage.secure_mass && currentMessage.summaryData && response && response.events) {
                    if (currentMessage.summaryData.events && response.events) {
                        response.events.forEach((event) => {
                            let eventObj = _.find<any>(currentMessage.summaryData.events, (ev) => {
                                return (ev.name === event.name);
                            });
                            if (eventObj) {
                                event.opened = eventObj.opened;
                            }
                        });
                    }

                    if (currentMessage.summaryData.links && response.links) {
                        response.links.forEach((link) => {
                            let linkObj = _.find<any>(currentMessage.summaryData.links, (ev) => {
                                return (ev.name === link.name);
                            });
                            if (linkObj) {
                                link.opened = linkObj.opened;
                            }
                        });
                    }
                }

                currentMessage.summaryData = response;
                this.checkForOpenEvent(currentMessage);

                this.getSummaryDataInProcess = false;

                if (response.final_report_key) {
                    currentMessage.final_report_key = response.final_report_key;
                }
            }, (err) => {
                this.getSummaryDataInProcess = false;
            });
        }
    };

    checkForOpenEvent = (currentMessage) => {
        currentMessage.summaryData
        if (currentMessage.summaryData.events && currentMessage.summaryData.events.length) {
            currentMessage.summaryData.events.forEach((eventObj) => {
                if (eventObj.name === this.dic.EVENTS.eventOpened.name) {
                    currentMessage.summaryData.emailOpened = true;
                }
            });
        }
    }

}

