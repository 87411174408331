import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SecurityContext,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {LookAndFeelService} from "../../services/lookAndFeelService";
import {DICTIONARY} from "../../dictionary";
import {NotificationService} from "../../services/notificationService";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
	selector: 'email-viewer',
	template: '<iframe #emailFrameEl src="about:blank" style="border: none; width: 100%;"></iframe>',
})
export class EmailViewerComponent implements OnChanges{

	@Input() content;
	@Input() prioritizeClasses;
	@ViewChild('emailFrameEl') emailFrameEl: ElementRef<HTMLIFrameElement>;
	@Output() onLoad = new EventEmitter<any>;

	constructor(private lfs:LookAndFeelService,
				private ns:NotificationService,
				private sanitizer: DomSanitizer) {
	}

	dic = DICTIONARY;
	modifiedContent;

	scrollBarCss = `::-webkit-scrollbar {
                      width: 10px;
                      height: 10px;
                    }
                    ::-webkit-scrollbar-button {
                        width: 0px;
                        height: 0px;
                    }
                    ::-webkit-scrollbar-thumb {
                        background: ${this.lfs.color};
                        border: 1px solid rgba(0, 0, 0, 0.5);
                        border-radius: 50px;
                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background: ${this.lfs.hoverColor};
                    }
                    ::-webkit-scrollbar-thumb:active {
                        background: ${this.lfs.color};
                    }
                    ::-webkit-scrollbar-track {
                        background: #ffffff;
                        border: 1px solid rgba(0,0,0,0.15);
                        border-radius: 50px;
                    }
                    ::-webkit-scrollbar-track:hover {
                        background: #ffffff;
                    }
                    ::-webkit-scrollbar-track:active {
                        background: #ffffff;
                    }
                    ::-webkit-scrollbar-corner {
                        background: transparent;
                    }`;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.content) {
			this.modifiedContent = this.content?.html || this.content || ''; // this.sanitizeHTML(this.content?.html || this.content || '') || '';

			setTimeout(() => {
				this.updateContent();
			});
		}
	}

	updateContent = () => {
		// add styles
		let style:any = document.createElement('style');
		style.type = 'text/css';
		style.appendChild(document.createTextNode(this.content?.css || this.scrollBarCss));

		// update frame
		var iframeDoc = this.emailFrameEl.nativeElement.contentDocument || this.emailFrameEl.nativeElement.contentWindow && this.emailFrameEl.nativeElement.contentWindow.document;
		if (iframeDoc) {
			iframeDoc.open();
			iframeDoc.write(this.modifiedContent);
			iframeDoc.close();

			iframeDoc.head.appendChild(style);

			this.emailFrameEl.nativeElement.style['height'] = '100%';

			setTimeout(() => {
				this.updateIframeHeight(iframeDoc);
				this.onLoad.emit({iframeDoc});
			});
		} else {
			this.ns.showWarnMessage(this.dic.ERRORS.browserNotSupported);
		}
	}

	updateIframeHeight = (iframeDoc) => {
		const body = iframeDoc.body;
		const documentEl = iframeDoc.documentElement;
		const htmlEl = iframeDoc.getElementsByTagName('HTML')[0] as HTMLHtmlElement;

		body.style['overflowY'] = 'hidden';

		let fixHeight = Math.max( body.scrollHeight, body.offsetHeight, htmlEl.clientHeight, htmlEl.scrollHeight, htmlEl.offsetHeight, documentEl.clientHeight, documentEl.scrollHeight, documentEl.offsetHeight );

		this.emailFrameEl.nativeElement.style['height'] = fixHeight.toString() + 'px';
	}

	sanitizeHTML(htmlString) {
		// Create a DOM parser to parse the input HTML string
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');

		// separate existing <style> tags from html
		const styleTags = doc.querySelectorAll('style');
		const originalStyles = Array.from(styleTags).map(styleTag => styleTag.outerHTML);
		styleTags.forEach(tag => tag.remove());

		// turn inline styles into classes in html
		const { styleTag, html } = inlineStylesToClasses(htmlString, this.prioritizeClasses);

		const combinedStyles = originalStyles.join('') + styleTag;

		// sanitize styles and html
		const sanitizedStyles = this.sanitizer.sanitize(SecurityContext.STYLE, combinedStyles);
		const sanitizedHTML = this.sanitizer.sanitize(SecurityContext.HTML, html);

		// combine them to one html
		return (sanitizedStyles || '') + '\n' + (sanitizedHTML || '');
	}
}


function inlineStylesToClasses(htmlString, prioritizeClasses) {
	// Create a DOM parser to parse the input HTML string
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, 'text/html');

	// To store the generated CSS classes and their associated styles
	const styleMap = new Map();
	let classCounter = 0; // Used for unique class names
	const random = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 8);

	// Helper function to convert inline styles to a CSS class
	function createClassFromStyle(element) {
		const inlineStyle = element.getAttribute('style');

		if (inlineStyle) {
			// Generate a unique class name
			const className = random + `-${classCounter++}`;

			// Append the new class to the element's existing classes (if any)
			let currentClasses = element.getAttribute('class') || '';
			element.setAttribute('class', currentClasses + ` ${className}`);

			// Append `!important` to every style property
			const importantStyle = inlineStyle.split(';').map(rule => {
				// Ensure the rule is not empty and append !important
				return rule.trim() ? prioritizeClasses ? `${rule.trim()}` : `${rule.trim()} !important` : '';
			}).join('; ');

			// Add the style to the styleMap (unique)
			styleMap.set(className, importantStyle);

			// Remove the inline style from the element
			element.removeAttribute('style');
		}
	}

	// Traverse all elements in the document and process their inline styles
	const allElements = doc.body.querySelectorAll('*');
	allElements.forEach(createClassFromStyle);

	// Generate the style tag content from the styleMap
	let styleTagContent = '<style>\n';
	styleMap.forEach((style, className) => {
		styleTagContent += `.${className} { ${style} }`;
	});
	styleTagContent += '</style>';

	// Return the updated HTML and the generated <style> tag
	return {
		html: doc.body.innerHTML,
		styleTag: styleTagContent
	};
}
