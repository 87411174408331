import util from "util";
import {DICTIONARY} from '../../../../dictionary';
import {GeneralService} from '../../../../services/generalService';
import {RouteService} from '../../../../services/routeService';
import {NotificationService} from '../../../../services/notificationService';
import _ from "lodash";
import {Component, OnInit} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
	selector: 'url-tracking-component',
	templateUrl: './url-tracking.component.html',
})
export class UrlTrackingComponent implements OnInit {

    constructor(public gs:GeneralService,
				private sanitizer:DomSanitizer,
				private rs:RouteService,
				private ns:NotificationService,
				private clipboard: ClipboardService) {
    }

	dic = DICTIONARY;
    urlInfoList = [];
    getUrlTrackingInProcess;
    displayEmailPopup: any;
    isUrlTrackingEnabled: any;
    getTrackingEnabledInfoInProcess;
    showQueryModal = false;
	openPreviewLinkPopup: any;
	allowReviewerShowContent: boolean;
	isQueryError;
    queryValidationErrors;
    queryTypes = {
        lastUrls: 'lastUrls',
        url: 'url',
        domain: 'domain'
    }
    queryType = this.queryTypes.lastUrls;
    queryDisplay = [];
    query = {
        url: '',
        domain: '',
        partialUrl: '',
        sender: '',
        subject: '',
        mailbox: '',
        messageId: '',
    }

	ngOnInit() {
        this.getTrackingEnabledInfoInProcess = true;
        this.rs.getQuarantinedPlanInfo().then((response) => {
            this.isUrlTrackingEnabled = response.urlTracking;
			this.allowReviewerShowContent = response.allowReviewerShowContent;
			this.getTrackingEnabledInfoInProcess = false;
        }, (err) => {
			this.getTrackingEnabledInfoInProcess = false;
		});
        this.calculateQueryDisplay();
    }

    updateUrlHunting = () => {
        const tpData:any = {
            action: this.dic.CONSTANTS.inboundUrlRewriteActions.urlHunting,
            type: this.dic.CONSTANTS.rulesTpAction.urlRewrite,
            data: {url_tracking: true}
        };

        this.rs.updateTpRules('', tpData).then(() => {
            this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
            this.isUrlTrackingEnabled = true;
        });
    };

    getUrlTracking() {
        const data = {
			url: this.queryType === this.queryTypes.url ? this.query.url : '',
			domain: this.queryType === this.queryTypes.domain ? this.query.domain : '',
			partialUrl: this.query.partialUrl,
			sender: this.query.sender,
			subject: this.query.subject,
			mailbox: this.query.mailbox,
			messageId: this.query.messageId,
		};

        this.getUrlTrackingInProcess = true;
        this.urlInfoList = [];

        if (data.url) {
            this.getUrlTrackingByUrl(data);
        }
        else {
            this.getUrlTrackingByDomain(data);
        }
    }

    getUrlTrackingByUrl(data) {
        this.rs.getUrlTracking(data).then((urlTrackingObj) => {
            prepareUrlTrackingObjects(urlTrackingObj, this.urlInfoList);
            this.getUrlTrackingInProcess = false;
        }, err => {
            this.getUrlTrackingInProcess = false;
            this.ns.showWarnMessage(util.format(this.dic.MESSAGES.noUrlTrackingFound, 'URL'));
        });
    }

	previewLink = (url) => {
		if (!url) {
			this.openPreviewLinkPopup = null;
			return;
		}

		this.openPreviewLinkPopup = {
			show: true,
			url: url
		};
	}

    getUrlTrackingByDomain(data) {
        this.rs.getUrlTracking(data).then((urlTrackingObjs) => {
            if (!urlTrackingObjs || !urlTrackingObjs.length) {
                this.getUrlTrackingInProcess = false;
                this.ns.showWarnMessage(util.format(this.dic.MESSAGES.noUrlTrackingFound, 'domain'));
                return;
            }

            urlTrackingObjs.forEach((urlTrackingObj) => {
                prepareUrlTrackingObjects(urlTrackingObj, this.urlInfoList);
            });
            this.getUrlTrackingInProcess = false;
        }, err => {
            this.getUrlTrackingInProcess = false;
        });
    }

    removeQueryError(key) {
        _.remove(this.queryValidationErrors, errorKey => errorKey === key);
    }

    // 1. send query to validation. 2. create query string for query box
    applyUrlTrackingQuery() {
        if (!this.validateQuery()) {
            return;
        }

        this.query.sender =  this.query.sender && this.query.sender.toLowerCase();
        this.query.mailbox = this.query.mailbox && this.query.mailbox.toLowerCase();

        this.calculateQueryDisplay();

        this.showQueryModal = false;
        this.isQueryError = false;

        this.getUrlTracking();
    }

    validateQuery() {
        this.queryValidationErrors = [];

        if (this.queryType === this.queryTypes.url) {
            if (!this.query.url || !this.gs.isValidUrl(this.query.url)) {
                this.queryValidationErrors.push('url');
                return false;
            }
        }
        if (this.queryType === this.queryTypes.domain) {
            if (!this.query.domain || !this.gs.isValidDomain(this.query.domain)) {
                this.queryValidationErrors.push('domain');
                return false;
            }
        }

        return true;
    }

    // determine how query properties look in the UI, in the query box
    calculateQueryDisplay() {

        this.queryDisplay = [];

        if (this.queryType === this.queryTypes.lastUrls) {
            this.queryDisplay.push({title: 'Last URL Clicks', tooltip: null});
            return;
        }

        _.mapValues<any>(this.query,(value, queryKey) => {
            if (value) {
                let title = queryKey;
                let tooltip = value;

                if ((this.queryType === this.queryTypes.url && (title === 'domain' || title === 'partialUrl')) || (this.queryType === this.queryTypes.domain && title === 'url')) {
                    return;
                }

                if (title === 'url') {
                    title = 'URL';
                }
                if (title === 'messageId') {
                    title = 'Message ID';
                }
                if (title === 'partialUrl') {
                    title = 'Partial URL';
                }

                this.queryDisplay.push({title: title, tooltip: tooltip});
            }
        });
    }

	showUrlBulkActions = () => {
		return [
			this.dic.CONSTANTS.urlTrackingActions.removeEmail,
			this.dic.CONSTANTS.urlTrackingActions.deleteTracking,
		];
	}

    showUrlActions = (urlClickObj) => {
        const actions = [
            this.dic.CONSTANTS.urlTrackingActions.blockListLink,
            this.dic.CONSTANTS.urlTrackingActions.blockListSender,
			this.dic.CONSTANTS.urlTrackingActions.safePreviewUrl,
            this.dic.CONSTANTS.urlTrackingActions.removeEmail,
			this.dic.CONSTANTS.urlTrackingActions.deleteTracking,
        ];

		if (this.allowReviewerShowContent) {
			actions.unshift(this.dic.CONSTANTS.urlTrackingActions.showContent);
		}

        return actions;
    }

	selectBulkAction = (selectedRecords,action) => {
		switch (action) {
			case this.dic.CONSTANTS.urlTrackingActions.removeEmail:
				this.removeEmail(selectedRecords);
				break;

			case this.dic.CONSTANTS.urlTrackingActions.deleteTracking:
				this.deleteUrlTracking(selectedRecords);
				break;
		}
	}

    selectAction = (urlClickObj, action) => {
        if (!urlClickObj) {
            return;
        }

        switch (action) {
            case this.dic.CONSTANTS.urlTrackingActions.showContent:
                this.getEmailContent(urlClickObj);
                break;

            case this.dic.CONSTANTS.urlTrackingActions.blockListSender:
                this.blockListSender(urlClickObj);
                break;

            case this.dic.CONSTANTS.urlTrackingActions.blockListLink:
                this.blockListLink(urlClickObj);
                break;

			case this.dic.CONSTANTS.urlTrackingActions.safePreviewUrl:
				this.previewLink(urlClickObj.long_url);
				break;

            case this.dic.CONSTANTS.urlTrackingActions.removeEmail:
                this.removeEmail([urlClickObj]);
                break;

			case this.dic.CONSTANTS.urlTrackingActions.deleteTracking:
				this.deleteUrlTracking([urlClickObj]);
				break;
        }
    }

	deleteUrlTracking = (urlClickObjects) => {
		this.gs.showPopup({
			title: 'You are about to delete tracking information',
			subTitle: `Data for URL will be deleted`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				urlClickObjects.forEach((urlClickObj) => {
					const params = {
						mailbox: urlClickObj.mailbox,
						deviceId: urlClickObj.device._id,
						messageId: urlClickObj.message_id
					};
					this.rs.deleteUrlTracking(urlClickObj._id, params).then(() => {
						this.ns.showInfoMessage(`Operation completed successfully`);
						_.remove<any>(this.urlInfoList, (trackingObj:any) => trackingObj.uuid === urlClickObj.uuid);
					}, (err) => {
					});
				});
			}
		});
	}

    blockListSender = (urlClickObj) => {
        this.gs.showPopup({
            title: 'Blocklist Sender',
            subTitle: `You are about to add sender ${urlClickObj.sender} to the global blocklist`,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Block',
            doneCb: () => {
                const updateData = {
                    type: this.dic.CONSTANTS.configTpAction.blacklist,
                    description: '',
                    action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
                    items: [{type: 'email', value: urlClickObj.sender}]
                };

                this.rs.updateTpConfig(updateData).then((response) => {
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, urlClickObj.sender));
                }, err => {
                });
            }
        });
    }

    removeEmail = (urlClickObjects) => {
		const subTitle = urlClickObjects.length === 1 ?
			`You are about to remove email ${urlClickObjects[0].subject} from mailbox ${urlClickObjects[0].mailbox}` :
			`You are about to remove ${urlClickObjects.length} emails`;
        this.gs.showPopup({
            title: 'Remove Email',
            subTitle: subTitle,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Remove',
            doneCb: () => {
				urlClickObjects.forEach((urlClickObj) => {

					const updateData = {
						action: this.dic.CONSTANTS.urlTrackingActions.removeEmail,
						url: urlClickObj.url,
						mailbox: urlClickObj.mailbox,
						messageId: urlClickObj.message_id
					};

					this.rs.urlTrackingAction(updateData).then((response) => {
						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemRemoved, 'Email'));
					}, err => {
					});
				});
            }
        });
    }

    blockListLink = (urlClickObj) => {
        this.gs.showPopup({
            title: 'Blocklist Link',
            subTitle: `You are about to add link ${urlClickObj.long_url} to the global blocklist`,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Block',
            doneCb: () => {
                const updateData = {
                    type: this.dic.CONSTANTS.configTpAction.linksBlacklist,
                    description: '',
                    action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
                    links: [{link: urlClickObj.long_url}]
                };

                this.rs.updateTpConfig(updateData).then((response) => {
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, urlClickObj.long_url));
                }, err => {
                });
            }
        });
    }

    getEmailContent = (urlClickObj) => {
		if (this.displayEmailPopup && this.displayEmailPopup.inProcess) {
			return;
		}

        const actionInfo = {
            action: this.dic.CONSTANTS.urlTrackingActions.showContent,
            url: urlClickObj.url,
            mailbox: urlClickObj.mailbox,
            messageId: urlClickObj.message_id
        };

        this.displayEmailPopup = {
            mailbox: urlClickObj.mailbox,
            subject: urlClickObj.subject,
            messageId: urlClickObj.message_id,
			inProcess: true,
            show: true
        };

        this.rs.urlTrackingAction(actionInfo).then(res => {
			if (this.displayEmailPopup) {
				this.displayEmailPopup.inProcess = false;
				this.displayEmailPopup.content = res.content || res.html;
				this.displayEmailPopup.content = this.sanitizer.bypassSecurityTrustHtml(this.displayEmailPopup.content);
			}
        }, () => {
			this.displayEmailPopup.inProcess = false;
        });
    };

	exportToCsv = (sortBy) => {
		if (!this.urlInfoList?.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Click Time,URL,Mailbox,Device,Location,IP Address,Subject,Sender\n";

		let sortedTable = _.filter(this.urlInfoList,user => {return !user.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((trackingObj) => {
			const device = trackingObj.device.agent?.raw ? JSON.parse(trackingObj.device.agent.raw).ua : '';
			csvString += `${trackingObj.created},${trackingObj.long_url},${trackingObj.mailbox},"${device}",${trackingObj.device.location?.country},${trackingObj.device.ip},"${trackingObj.subject}",${trackingObj.sender}\n`;
		});

		this.gs.exportCsv(csvString, `url_hunting.csv`);
	}


    searchUrlClickIncident = (searchTerm, activeFilters) => {
        this.urlInfoList.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    }

    openLocation = (ll) => {
        this.gs.openLocation(ll)
    }

    copyToClipboard = (textToCopy) => {
        this.clipboard.copy(textToCopy);
    }
}

function prepareUrlTrackingObjects(urlTrackingObj, urlInfoList) {
	let count = 0;
    if (urlTrackingObj.emails?.length) {
        urlTrackingObj.emails.forEach(email => {
            email.mailboxes.forEach(mailbox => {
                mailbox.devices.forEach(device => {
                    urlInfoList.push({
						_id: urlTrackingObj._id,
						uuid: `${urlTrackingObj._id}${++count}`,
						created: device.created,
                        mailbox: mailbox.email,
                        device: device, // contains also 'location'
                        message_id: email.message_id,
                        subject: email.subject,
                        sender: email.sender,
                        url: urlTrackingObj.url,
                        long_url: urlTrackingObj.long_url
                    });
                });
            });
        });
    }
}


function searchTextExecute(incidentData, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return ((incidentData.long_url && incidentData.long_url.toLowerCase().indexOf(searchTerm) > -1) ||
        (incidentData.email && incidentData.email.toLowerCase().indexOf(searchTerm) > -1) ||
        (incidentData.message_id && incidentData.message_id.toLowerCase().indexOf(searchTerm) > -1) ||
        (incidentData.subject && incidentData.subject.toLowerCase().indexOf(searchTerm) > -1) ||
        (incidentData.sender && incidentData.sender.toLowerCase().indexOf(searchTerm) > -1));
}
