/*
* Generic popup: Implemented to create popup in this app, and avoid code repetition.
* Types: 1.popupWarning: used to warn the user before some action like: deleting attachment, list etc.
*        Usage: GeneralService.showPopup({
                title: 'Delete Template',
                subTitle: 'Please note - the selected template will be deleted.',
                body: ['You cannot recover a deleted template.', ...],
                type: templatesCtrl.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Delete',
                isFile: false,
                doneCb: templatesCtrl.deleteTemplate
            });
*        2.popupInfoConfirming, popupWarningConfirming: used to perform actions with inputs from user like inviting a friend.
*        Usage: GeneralService.showPopup({
                title: 'Invite a Friend',
                subTitle: "Enter your friend's details:",
                body: [
                    {
                        labelName: 'Name',
                        placeholder: "Full name (required)",
                        required: true,
                        model: contentCtrl.inviteFriendName
                    },
                    {
                        labelName: 'Email',
                        placeholder: "Email address (required)",
                        required: true,
                        model: contentCtrl.inviteFriendEmail
                    },
                    ...
                ],
                type: contentCtrl.dic.CONSTANTS.popupInfoConfirming,
                doneBtnText: 'Invite!',
                doneCb: function (body) {
                    //do something with returned data
                }
            });
        3. togglePopup - for popup using toggels, e.g. prepare full report
        Usage is similar to popupInfoConfirming but for each toggle we have:
            {
                labelName: 'Label name',
                model: mailBoxCtrl.bindedData
            }
*/

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DICTIONARY} from '../../dictionary';
import {GeneralService} from '../../services/generalService';
import {Subscription} from 'rxjs';

@Component({
    selector: 'popup-drv-component',
    templateUrl: './popup-drv.component.html'
})
export class PopupDrvComponent implements OnInit, OnDestroy {
    isFormValid;
    globalData:any = {};
    randomstr;
    files;
    dic = DICTIONARY;

    private globalDataSubscription: Subscription;
    @Input() globalDataInput:any;

    constructor(public gs: GeneralService) {
    }

    ngOnInit() {
		this.isFormValid = false;
		this.randomstr = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
		this.globalData = this.globalDataInput || {};
		this.isFormValid = false;
    }

    ngOnDestroy() {
		if (this.globalDataSubscription) {
			this.globalDataSubscription.unsubscribe();
		}
    }

    close() {
        this.isFormValid = false;
        this.gs.hidePopup();
    }

    cancel() {
        if (this.globalData.cancelCb) {
            this.globalData.cancelCb();
        }
        this.close();
    }

    done(data=null) {
        // validate that form fields are not empty
        if ((this.globalData.type === this.dic.CONSTANTS.popupWarningConfirming || this.globalData.type === this.dic.CONSTANTS.popupInfoConfirming)
            && !this.isFormValid && !this.globalData.formAlwaysValid) return;

        this.globalData.doneCb(data);
        this.close();
    }

	validateForm(input, index) {
		if (this.globalData.type === this.dic.CONSTANTS.popupWarningConfirming && this.globalData.body[index].equalsTo) {
			this.isFormValid = (this.globalData.body[index].equalsTo === input);
		} else if (this.globalData.type === this.dic.CONSTANTS.popupWarningConfirming) {
			this.isFormValid = this.checkAllModels();
		} else {
			this.isFormValid = (input.length !== 0);
		}
	}

	checkAllModels() {
		return this.globalData.body.every(item => item.model && item.model.length > 0);
	}


	getSubTitleStyle() {
		if (this.globalData.type === this.dic.CONSTANTS.popupWarningConfirming ||
			this.globalData.type === this.dic.CONSTANTS.popupWarning) {
			return {
				'color': '#e84634',
				'word-break': 'break-word'
			};
		} else if (this.globalData.type === this.dic.CONSTANTS.popupInfo ||
			this.globalData.type === this.dic.CONSTANTS.popupToggle ||
			this.globalData.type === this.dic.CONSTANTS.popupInfoConfirming) {
			return {
				'color': 'black',
				'margin-bottom': '20px'
			};
		}
		return {};
	}

	handleConfirm() {
		if (!this.globalData.isFile && (this.globalData.type !== this.dic.CONSTANTS.popupWarning && this.globalData.type !== this.dic.CONSTANTS.popupInfo)) {
			this.done(this.globalData.body);
		} else if (!this.globalData.isFile && (this.globalData.type === this.dic.CONSTANTS.popupWarning || this.globalData.type === this.dic.CONSTANTS.popupInfo)) {
			this.done();
		}
	}

	haveDropdown() {
		return this.globalData.body?.some(input => input?.type === this.dic.CONSTANTS.popupBodytypes.dropdown);
	}

	getPopupButtons() {
		let buttonsConfig: any = {};
		if (!this.globalData.isFile) {
			// Configure the Cancel button
			if (this.globalData.type === this.dic.CONSTANTS.popupWarning ||
				this.globalData.type === this.dic.CONSTANTS.popupWarningConfirming ||
				(this.globalData.type === this.dic.CONSTANTS.popupInfo && !this.globalData.img)) {
				buttonsConfig.cancel = {
					text: this.globalData.cancelBtnText || 'Cancel'
				};
			}

			// Configure the Done button
			if (this.globalData.type !== this.dic.CONSTANTS.popupWarning &&
				this.globalData.type !== this.dic.CONSTANTS.popupInfo) {
				buttonsConfig.confirm = {
					text: this.globalData.doneBtnText || 'Done', disabled: !this.globalData.formAlwaysValid && !this.isFormValid && this.globalData.type !== this.dic.CONSTANTS.popupToggle,
				};
			} else {
				buttonsConfig.confirm = {
					text: this.globalData.doneBtnText || 'Done',
				};
			}


		}

		return buttonsConfig;

	}

}
