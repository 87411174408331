<div class="flxClmn animated fadeIn" style="margin-bottom: 10px; overflow: auto; flex: 1 0; padding: 0 15px;">

    <div class="flxRow-between" style=" align-items: center; padding: 10px; height: 57px; z-index: 5;">
        <span class="title" style=" margin: 0; font-size: 1.5rem; color: dimgrey;">Inbound Analysis</span>
        <div class="flxRow animated fadeIn" style=" align-items: center;"
             *ngIf="!getThreatsDataInProcess && archiveService.currentMessage.threatsData?.length">
            <span style=" margin-top: -5px; font-weight: 500;">View threats for: </span>
            <div style=" margin-left: 10px; height: 37px;">
                <app-dropdown-c style="width: 260px;"
                              [items]="archiveService.currentMessage?.threatsData"
                              itemDisplayKey="mailbox_email" [enableSearch]="true"
                              (onItemClicked)="changeMailboxInfo($event.item)"
                              [text]="curEmail?.mailbox_email"></app-dropdown-c>
            </div>
        </div>
    </div>

    <hr style="margin-top: 0;">

    <!--loader-->
    <div class="flxClmn-center" style="flex: 1 0; align-items: center;" *ngIf="getThreatsDataInProcess">
        <div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 0.7;"></div>
    </div>
    <!--empty data placeholder-->
    <div class="flxClmn-center" style="flex: 1 0; align-items: center;"
         *ngIf="!getThreatsDataInProcess && (!archiveService.currentMessage.threatsData || !archiveService.currentMessage.threatsData.length)">
        <span style="opacity: 0.7; font-size: 1rem;">Information is not available for this email</span>
    </div>

    <div class="vSpace3" style="font-size: 0.9rem; flex: 1 0; color: black; overflow: auto;"
         *ngIf="!getThreatsDataInProcess && (archiveService.currentMessage.threatsData && archiveService.currentMessage.threatsData.length)">
        <div *ngIf="curEmail?.auth_sender && curEmail?.auth_sender.status" style="padding-bottom: 4px;">
            <i class="fas fa-exclamation-circle" style="padding-right: 3px;"
               [ngStyle]="{'color': curEmail.auth_sender.status === 'request' ? 'grey' : curEmail.auth_sender.status === 'approve' ? 'green' : 'red'}"></i>
            <label style="margin-bottom: 0;">Sender has {{curEmail.auth_sender.status === 'request' ? 'not yet responded to' : curEmail.auth_sender.status === 'approve' ? 'approved': 'denied'}} the authentication request</label>
        </div>
        <!--Analysis Summary-->
        <div class="flxClmn vSpace1" style="padding: 10px; padding-left: 20px; border-left: solid 3px var(--bs-primary); background: linear-gradient(90deg, #f9f9f96b, transparent);">

            <span style="font-family: 'Roboto Condensed', sans-serif; opacity: 0.7; font-size: 1.1rem; margin-bottom: min(15px,1vh);">Summary</span>

            <div class="flxRow hSpace1" style="align-items: flex-start;"
                 *ngIf="curTPResults?.resHead.message"
                 [ngStyle]="{'color': setInboundStatusStyles(curTPResults.headerStatus).color}">
                <i class="fa fa-user" style="color: inherit; font-size: 0.9rem; flex-basis: 15px;"></i>
                <div class="flxClmn vSpace05" style="color: initial;">
                    <span>{{curTPResults.resHead.message}}</span>
                    <span style="word-break: break-word;"
                          *ngIf="curTPResults.resHead.from && curTPResults.resHead.from.highlight"
                          [innerHTML]="curTPResults.resHead.from.highlight"></span>
                </div>
            </div>
            <div class="flxRow hSpace1" style="align-items: flex-start;"
                 *ngIf="curTPResults.resUrl.message"
                 [ngStyle]="{'color': setInboundStatusStyles(curTPResults.urlStatus).color}">
                <i class="fa fa-link" style="color: inherit; font-size: 0.9rem; flex-basis: 15px;"></i>
                <div class="flxClmn vSpace05" style="color: initial;">
                    <span>{{curTPResults.resUrl.message}}</span>
                    <span style="word-break: break-word;"
                          *ngIf="curTPResults.resUrl && curTPResults.resUrl.highlight"
                          [innerHTML]="curTPResults.resUrl.highlight"></span>
                </div>
            </div>
            <div class="flxRow hSpace1" style="align-items: flex-start;"
                 *ngIf="curTPResults.resHash.message"
                 [ngStyle]="{'color': setInboundStatusStyles(curTPResults.hashStatus).color}">
                <i class="fa fa-file" style="color: inherit; font-size: 0.9rem; flex-basis: 15px;"></i>
                <div class="flxClmn vSpace05" style="color: initial;">
                    <span>{{curTPResults.resHash.message}}</span>
                    <span style="word-break: break-word;"
                          *ngIf="curTPResults.resHash && curTPResults.resHash.highlight"
                          [innerHTML]="curTPResults.resHash.highlight"></span>
                </div>
            </div>
        </div>

        <div class="threat-protection-grid" style="flex: 1 0; overflow: auto;">
            <!--Message Analysis Data Card-->
            <div class="threat-protection-card flxClmn ro2 co12 md-co24" style="min-height: 755px;">
                <div class="card-header flxRow-between" style="align-items: center;">
                    <span class="card-title" style="font-size: inherit;" [ngStyle]="{'color' : setInboundStatusStyles(curTPResults.resHead.status).color === 'darkred' ? 'darkred' : ''}">
                        Message Analysis
                    </span>
                    <div class="flxRow hSpace1">
                        <i class="fa hvr-opacity animated fadeIn" style="font-size: 1rem;" *ngIf="curTPResults"
                           [ngClass]="setInboundStatusStyles(curTPResults.resHead.status).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(curTPResults.headerStatus).icon"
                           [ngStyle]="{'color' : setInboundStatusStyles(curTPResults.resHead.status).color}"
                           tooltip="{{curTPResults.resHead.message || 'Some headers were found malicious or suspicious'}}"></i>
                        <i class="fas fa-spinner fa-spin animated fadeIn" style="opacity: 0.4; font-size: 1rem; animation-duration: 1s;" *ngIf="!curTPResults"></i>
                    </div>
                </div>
                <div class="flxClmn animated fadeIn" style="flex: 1 0; overflow: auto;" *ngIf="curTPResults">
                    <div class="flxClmn vSpace1" style="padding: 15px 10px;">
                        <span style="text-decoration: underline;">Sender Identities</span>
                        <table class="simpleDataTable w100 message-analysis-table middle" style="table-layout: fixed;">
                            <tr>
                                <th style="width: 110px; padding: 0;"></th>
                                <th style="padding: 0;"></th>
                                <th style="width: 30px; padding: 0;"></th>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.from.senderMail && curTPResults.resHead.from.senderMail !== curTPResults.resHead.from.senderMailFrom">
                                <td>
                                    <span><b>Appears As</b></span>
                                </td>
                                <td>
                                    <span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{curTPResults.resHead.from.senderMail}}</span>
                                </td>
                                <td>
                                    <a tooltip="Sender email address" tooltipDirection="right" class="fas" ng-class="curTPResults.resHead.from.senderMailFromFlag ? 'fa-times-circle' : 'fa-check'"></a>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.from.senderMailFrom">
                                <td>
                                    <span><b>Sender</b></span>
                                </td>
                                <td>
                                    <span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{curTPResults.resHead.from.senderMailFrom}}</span>
                                </td>
                                <td>
									<a tooltip="Sender email address" tooltipDirection="right" class="fas" ng-class="curTPResults.resHead.from.senderMailFromFlag ? 'fa-times-circle' : 'fa-check'"></a>
								</td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead && curTPResults.resHead.relationshipLevel">
                                <td><b>Relationship</b></td>
                                <td>
                                    <div class="flxRow hSpace1">
                                        <div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
                                            <div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%;"
                                                 [ngStyle]="{ 'width': (curTPResults.resHead.relationshipLevel && curTPResults.resHead.relationshipLevel.total ? (curTPResults.resHead.relationshipLevel.total * 0.95 + 5)  : 5) + '%', 'background-color': curTPResults.resHead.relationshipLevel && curTPResults.resHead.relationshipLevel.total >= 70 ? 'darkgreen' : curTPResults.resHead.relationshipLevel && curTPResults.resHead.relationshipLevel.total >= 50 ? 'darkorange' : 'darkred'}"></div>
                                        </div>
                                        <span>{{curTPResults.resHead.relationshipLevel && curTPResults.resHead.relationshipLevel.total || 0}}%</span>
                                    </div>
                                </td>
                                <td>
                                    <i style="font-weight: 400;" class="fas fa-question-circle checkmark" tooltip="Sender's relationship level with the user and organisation.<br/>This score is calculated at the time the email was received and does not reflect changes that were made after that time."></i>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.from.replyTo">
                                <td>
                                    <span><b>Reply To</b></span>
                                </td>
                                <td>
                                    <span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{curTPResults.resHead.from.replyTo}}</span>
                                </td>
                                <td>
									<a tooltip="Reply-To address" tooltipDirection="right" class="fas" ng-class="curTPResults.resHead.from.replyToFlag ? 'fa-times-circle' : 'fa-check'"></a>
								</td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.from.returnPath">
                                <td>
                                    <span><b>Return Path</b></span>
                                </td>
                                <td>
                                    <span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{curTPResults.resHead.from.returnPath}}</span>
                                </td>
                                <td>
									<a tooltip="Return-Path address" tooltipDirection="right" class="fas" ng-class="curTPResults.resHead.from.returnPathFlag ? 'fa-times-circle' : 'fa-check'"></a>
								</td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.from.messageID">
                                <td>
                                    <span><b>Message ID</b></span>
                                </td>
                                <td>
                                    <span style="word-break: break-all;">{{curTPResults.resHead.from.messageID}}</span>
                                </td>
                                <td>
									<a tooltip="Message-ID address" tooltipDirection="right" class="fas" ng-class="curTPResults.resHead.from.messageIDFlag ? 'fa-times-circle' : 'fa-check'"></a>
								</td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.from.sendingMTA.domain">
                                <td>
                                    <span><b>MTA Domain</b></span>
                                </td>
                                <td>
                                    <span style="padding: 0; white-space: pre-wrap; word-break: break-word;">{{curTPResults.resHead.from.sendingMTAFull || curTPResults.resHead.from.sendingMTA.domain}}{{curTPResults.resHead.from.sendingMTAIP ? ' (' + curTPResults.resHead.from.sendingMTAIP + ')' : ''}}</span>
                                </td>
                                <td>
									<a tooltip="MTA domain" tooltipDirection="right" class="fas" ng-class="curTPResults.resHead.from.senderEmailServerFlag ? 'fa-times-circle' : 'fa-check'"></a>
								</td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.from.ipAddresses && curTPResults.resHead.from.ipAddresses.length">
                                <td>
                                    <span><b>IP Addresses</b></span>
                                </td>
                                <td>
                                    <span>{{curTPResults.resHead.from.ipAddresses.join(', ')}}</span>
                                </td>
                                <td>
									<a tooltip="IP address" tooltipDirection="right" class="fas" ng-class="curTPResults.resHead.from.senderIPAddressFlag ? 'fa-times-circle' : 'fa-check'"></a>
								</td>
                            </tr>
                        </table>
                    </div>
                    <div class="flxClmn vSpace1" style="padding: 15px 10px;">
                        <span style="text-decoration: underline;">AI Confidence</span>
                        <table class="simpleDataTable w100 message-analysis-table middle" style="table-layout: fixed;">
                            <tr>
                                <th style="width: 110px; padding: 0;"></th>
                                <th style="padding: 0;"></th>
                                <th style="width: 30px; padding: 0;"></th>
                            </tr>
                            <tr>
                                <td>
                                    <span><b>AI Decision</b></span>
                                </td>
                                <td>
                                   <span style="text-transform: capitalize;"
                                         *ngIf="!curTPResults.resHead.AIresults.skip">
                                       {{!curTPResults.resHead.AIresults.decision ? 'Safe' : curTPResults.resHead.AIresults.decision === 'threat' ? 'Business Email Compromise (BEC)' : curTPResults.resHead.AIresults.decision}}
                                   </span>
                                    <span style="text-transform: capitalize;" *ngIf="curTPResults.resHead.AIresults.skip">
                                       {{curTPResults.resHead.AIresults.decision ? curTPResults.resHead.AIresults.decision : 'Internal metrics decision'}}
                                   </span>
                                </td>
                                <td>
                                    <i style="font-weight: 400;" class="fas fa-question-circle checkmark" tooltip="The final AI decision for this email, based on the AI score for BEC, Spam, and Graymail.<br/> The final email status may differ from the AI decision, due to link and attachment analysis."></i>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.AIresults">
                                <td>
                                    <span><b>- BEC AI</b></span>
                                </td>
                                <td>
                                    <div class="flxRow hSpace1" (ngInit)="threat_body_conf_precentages = curTPResults.resHead.AIresults.threat_body_conf ? curTPResults.resHead.AIresults.threat_body_conf > 1 ? 100 : (curTPResults.resHead.AIresults.threat_body_conf * 100) : 0">
                                        <div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
                                            <div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%;"
                                                 [ngStyle]="{ 'width': threat_body_conf_precentages + '%', 'background-color' : threat_body_conf_precentages <= 50 ? 'darkgreen' : threat_body_conf_precentages <= 70 ? 'darkorange' : 'darkred'}"></div>
                                        </div>
                                        <span style="flex-basis: 32px;">{{threat_body_conf_precentages | number: 0}}%</span>
                                    </div>
                                </td>
                                <td>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.AIresults">
                                <td>
                                    <span><b>- Spam AI</b></span>
                                </td>
                                <td>
                                    <div class="flxRow hSpace1" (ngInit)="spam_body_conf_precentages = curTPResults.resHead.AIresults.spam_body_conf ? curTPResults.resHead.AIresults.spam_body_conf > 1 ? 100 : (curTPResults.resHead.AIresults.spam_body_conf * 100) : 0">
                                        <div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
                                            <div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%;"
                                                 [ngStyle]="{ 'width': spam_body_conf_precentages + '%', 'background-color' : spam_body_conf_precentages <= 50 ? 'darkgreen' : spam_body_conf_precentages <= 70 ? 'darkorange' : 'darkred'}"></div>
                                        </div>
                                        <span style="flex-basis: 32px;">{{spam_body_conf_precentages | number: 0}}%</span>
                                    </div>
                                </td>
                                <td>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.AIresults">
                                <td>
                                    <span><b>- Graymail AI</b></span>
                                </td>
                                <td>
                                    <div class="flxRow hSpace1" (ngInit)="graymail_body_conf_precentages = curTPResults.resHead.AIresults.graymail_body_conf ? curTPResults.resHead.AIresults.graymail_body_conf > 1 ? 100 : (curTPResults.resHead.AIresults.graymail_body_conf * 100) : 0">
                                        <div style="flex: 1 0; background-color: #f1f1f1; border: 1px solid #ccc; border-radius: 5px;">
                                            <div class="widthAnimation" style="border-radius: 3px; text-align: center; word-break: keep-all; height: 100%;"
                                                 [ngStyle]="{ 'width': graymail_body_conf_precentages + '%', 'background-color' : graymail_body_conf_precentages <= 50 ? 'darkgreen' : graymail_body_conf_precentages <= 70 ? 'darkorange' : 'darkred'}"></div>
                                        </div>
                                        <span style="flex-basis: 32px;">{{graymail_body_conf_precentages | number: 0}}%</span>
                                    </div>
                                </td>
                                <td>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="flxClmn vSpace1" style="padding: 15px 10px;">
                        <span style="text-decoration: underline;">Authentication Standards</span>
                        <table class="simpleDataTable w100 message-analysis-table middle" style="table-layout: fixed;">
                            <tr>
                                <th style="width: 110px; padding: 0;"></th>
                                <th style="padding: 0;"></th>
                                <th style="width: 30px; padding: 0;"></th>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.spf">
                                <td>
                                    <span><b>SPF</b></span>
                                </td>
                                <td>
                                    <span class="ellipsis" style="padding: 0;">{{curTPResults.resHead.spf.message ? curTPResults.resHead.spf.message : (curTPResults.resHead.from.isInternal ? 'SPF is Internal' : 'No SPF was sent.')}}</span>
                                </td>
                                <td>
                                    <i *ngIf="!curTPResults.resHead.spf.message" class="fas" [ngClass]="curTPResults.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
                                    <i class="fas" *ngIf="curTPResults.resHead.spf.message" [ngClass]="curTPResults.resHead.spf.score === 2 ? 'fa-exclamation-circle' : curTPResults.resHead.spf.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.arc">
                                <td>
                                    <span><b>ARC</b></span>
                                </td>
                                <td>
                                    <span class="ellipsis" style="padding: 0;">{{curTPResults.resHead.arc.message ? curTPResults.resHead.arc.message : (curTPResults.resHead.from.isInternal ? 'ARC is Internal' : 'No ARC was sent.')}}</span>
                                </td>
                                <td>
                                    <i *ngIf="!curTPResults.resHead.arc.message" class="fas" [ngClass]="curTPResults.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
                                    <i class="fas" *ngIf="curTPResults.resHead.arc.message" [ngClass]="curTPResults.resHead.arc.score === 2 ? 'fa-exclamation-circle' : curTPResults.resHead.arc.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.dkim">
                                <td><b>DKIM</b></td>
                                <td>
                                    <span class="ellipsis" style="padding: 0;">{{curTPResults.resHead.dkim.message ? curTPResults.resHead.dkim.message : (curTPResults.resHead.from.isInternal ? 'DKIM is Internal' : 'No DKIM was sent')}}</span>
                                </td>
                                <td>
                                    <i *ngIf="!curTPResults.resHead.dkim.message" class="fas" [ngClass]="curTPResults.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
                                    <i class="fas" *ngIf="curTPResults.resHead.dkim.message" [ngClass]="curTPResults.resHead.dkim.score === 2 ? 'fa-exclamation-circle' : curTPResults.resHead.dkim.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.dmarc">
                                <td><b>DMARC</b></td>
                                <td>
                                    <span class="ellipsis" style="padding: 0;">{{curTPResults.resHead.dmarc.message ? curTPResults.resHead.dmarc.message : (curTPResults.resHead.from.isInternal ? 'DMARC is Internal' : 'No DMARC was sent.')}}</span>
                                </td>
                                <td>
                                    <i *ngIf="!curTPResults.resHead.dmarc.message" class="fas" [ngClass]="curTPResults.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
                                    <i class="fas" *ngIf="curTPResults.resHead.dmarc.message" [ngClass]="curTPResults.resHead.dmarc.score === 2 ? 'fa-exclamation-circle' : curTPResults.resHead.dmarc.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.compauth">
                                <td><b>CompAuth</b></td>
                                <td>
                                    <span class="ellipsis" style="padding: 0;">{{curTPResults.resHead.compauth.message ? curTPResults.resHead.compauth.message : (curTPResults.resHead.from.isInternal ? 'CompAuth is Internal' : 'No CompAuth was sent.')}}</span>
                                </td>
                                <td>
                                    <i *ngIf="!curTPResults.resHead.compauth.message" class="fas" [ngClass]="curTPResults.resHead.from.isInternal ? 'fa-check' : 'fa-exclamation-circle'"></i>
                                    <i class="fas" *ngIf="curTPResults.resHead.compauth.message" [ngClass]="curTPResults.resHead.compauth.score === 2 ? 'fa-exclamation-circle' : curTPResults.resHead.compauth.score > 1 ? 'fa-check' : 'fa-times-circle'"></i>
                                </td>
                            </tr>
                            <tr *ngIf="curTPResults.resHead.from && curTPResults.resHead.from.tls && (curTPResults.resHead.from.tls.message || curTPResults.resHead.from.tls.recipient.message || curTPResults.resHead.from.tls.external.message || curTPResults.resHead.from.tls.sender.message)">
                                <td>
                                    <span><b>TLS</b></span>
                                </td>
                                <td>
                                    <div class="flxClmn vSpace05">
                                        <span>{{curTPResults.resHead.from.tls.message}}</span>
                                        <span *ngIf="curTPResults.resHead.from.tls.recipient.message">{{curTPResults.resHead.from.tls.recipient.message}}</span>
                                        <span *ngIf="curTPResults.resHead.from.tls.external.message">{{curTPResults.resHead.from.tls.external.message}}</span>
                                        <span *ngIf="curTPResults.resHead.from.tls.sender.message">{{curTPResults.resHead.from.tls.sender.message}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="flxClmn" style="align-items: flex-start;">
                                        <i class="fas" [ngClass]="curTPResults.resHead.from.tls.status === false ? 'fa-times-circle' : 'fa-check'"></i>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="flxClmn-center" style="align-items: center; flex: 1 0;" *ngIf="!curTPResults">
                    <span style="opacity: 0.5;">Loading</span>
                </div>
            </div>
            <div class="threat-protection-card flxClmn ro1 co12 md-co24" style="flex: 1 0;">
                <div class="card-header flxRow-between" style="align-items: center;">
                    <span class="card-title" style="font-size: inherit;" [ngStyle]="{'color' : setInboundStatusStyles(curTPResults.urlStatus).color === 'darkred' ? 'darkred' : ''}">
                        Links Analysis {{curTPResults.resUrl.list?.length ? '(' + curTPResults.resUrl.list?.length + ')' : ''}}
                    </span>
                    <div class="flxRow hSpace1">
                        <i class="fa hvr-opacity" style="font-size: 1rem;" *ngIf="curTPResults"
                           tooltip="{{curTPResults.urlStatus}}"
                           [ngClass]="setInboundStatusStyles(curTPResults.urlStatus).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(curTPResults.urlStatus).icon"
                           [ngStyle]="{'color' : setInboundStatusStyles(curTPResults.urlStatus).color}">
                        </i>
                        <i class="fas fa-spinner fa-spin animated fadeIn" style="opacity: 0.4; font-size: 1rem; animation-duration: 1s;" *ngIf="!curTPResults"></i>
                    </div>
                </div>
                <div class="flxClmn animated fadeIn" style="align-items: center; flex: 1 0; padding: 10px; overflow: auto; min-height: 140px;"
                     *ngIf="curTPResults"
                     [ngStyle]="{'justify-content' : !curTPResults.resUrl.list || !curTPResults.resUrl.list.length ? 'center' : ''}">
                    <div class="w100" style="flex: 1 0;" *ngIf="curTPResults.resUrl.list && curTPResults.resUrl.list.length">
                        <table class="simpleDataTable w100 message-analysis-table middle" style="table-layout: fixed;">
                            <tr>
                                <th style="padding: 0;"></th>
                            </tr>
                            <tr *ngFor="let url of curTPResults.resUrl.list">
                                <td>
                                    <div class="flxClmn vSpace1 ellipsis" style="padding: 10px 0;">
                                        <div class="flxRow" style="align-items: center;">
                                            <div style="flex: 1 0; padding-right: 15px;" class="ellipsis flxRow hSpace05">
                                                <b style="flex-basis: 40px;">Name:</b>
                                                <span class="ellipsis" style="flex: 1 0;">{{url.name ? url.name : url.domain ? url.domain : url.url}}</span>
                                            </div>
                                            <div class="flxRow" style="align-items: center;" (ngInit)="url.icon = setInboundStatusStyles(url.status).icon;">
                                                <i class="fas" *ngIf="!url.category" [ngClass]="url.icon === 'fa-check-circle' ? 'fa-check' : url.icon === 'fa-bug' ? 'fa-times-circle' : url.icon"
                                                   [ngStyle]="{'color': setInboundStatusStyles(url.status).color}" tooltipDirection="right" tooltip="{{url.status}}"></i>
                                                <div class="flxRow hSpace05" style="background: white; width: fit-content; border: solid 2px; border-radius: 3px; padding: 2px 7px; align-items: center;"
                                                     *ngIf="url.category"
                                                     [ngStyle]="{'border-color': setInboundStatusStyles(url.status).color,
                                                                                    'color': setInboundStatusStyles(url.status).color}">
                                                    <i class="fa" style="color: inherit; font-size: 10px;" [ngClass]="setInboundStatusStyles(url.status).icon"></i>
                                                    <span style="color: inherit; font-size: 11px;">{{url.category}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flxRow" style="align-items: flex-end;" *ngIf="url.original_url || url.url">
                                            <div class="flxRow hSpace05 ellipsis" style="padding-right: 45px; font-weight: 300; flex: 1 0;">
                                                <b style="flex-basis: 40px;">URL:</b>
                                                <span class="ellipsis" style="flex: 1 0;">{{url.original_url || url.url}}</span>
                                            </div>
                                            <a (click)="copyItemToClipboard(url.original_url || url.url);" style="text-align: right;">
                                                <i class="fas fa-copy" style="opacity: 0.7;" tooltip="Copy full URL"></i>
                                            </a>
                                        </div>
                                        <div class="flxRow" style="align-items: flex-end;" *ngIf="url.url && url.original_url && (url.url !== url.original_url || url.redirect_url && url.original_url !== url.redirect_url)">
                                            <div class="flxRow hSpace05 ellipsis" style="padding-right: 45px; font-weight: 300; flex: 1 0;">
                                                <b style="flex-basis: 40px;">Redirect:</b>
                                                <span class="ellipsis" style="flex: 1 0;">{{url.redirect_url || url.url}}</span>
                                            </div>
                                            <a (click)="copyItemToClipboard(url.redirect_url || url.url);" style="text-align: right;">
                                                <i class="fas fa-copy" style="opacity: 0.7;" tooltip="Copy full redirect URL"></i>
                                            </a>
                                        </div>
                                        <div class="flxRow" style="align-items: flex-end;" *ngIf="url.malware_type">
                                            <div class="flxRow hSpace05 ellipsis" style="padding-right: 45px; font-weight: 300; flex: 1 0;">
                                                <b style="flex-basis: 40px;">Malware Type:</b>
                                                <span class="ellipsis" style="flex: 1 0;">{{url.malware_type}}</span>
                                            </div>
                                            <a (click)="copyItemToClipboard(url.malware_type);" style="text-align: right;">
                                                <i class="fas fa-copy" style="opacity: 0.7;" tooltip="Copy malware type"></i>
                                            </a>
                                        </div>
										<div class="flxRow" style="align-items: flex-end;" *ngIf="url?.domain_age">
											<div class="flxRow hSpace05 ellipsis" style="padding-right: 45px; font-weight: 300; flex: 1 0;">
												<b style="flex-basis: 40px;">Domain Age:</b>
												<span class="ellipsis" style="flex: 1 0;">{{url.domain_age | date:'MM/dd/yyyy'}}</span>
											</div>
										</div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <span *ngIf="!curTPResults.resUrl.list || !curTPResults.resUrl.list.length">No links in email</span>
                </div>
                <div class="flxClmn-center" style="align-items: center; flex: 1 0;" *ngIf="!curTPResults">
                    <span style="opacity: 0.5;">Loading</span>
                </div>
            </div>
            <div class="threat-protection-card flxClmn ro1 co12 md-co24" style="flex: 1 0;">
                <div class="card-header flxRow-between" style="align-items: center;">
                        <span class="card-title" style="font-size: inherit;" [ngStyle]="{'color' : setInboundStatusStyles(curTPResults.hashStatus).color === 'darkred' ? 'darkred' : ''}">
                            Attachments Analysis {{curTPResults.resHash && curTPResults.resHash.list && curTPResults.resHash.list.length ? '(' + curTPResults.resHash.list.length + ')' : ''}}
                        </span>
                    <div class="flxRow hSpace1">
                        <i class="fa hvr-opacity" style="font-size: 1rem;" *ngIf="curTPResults"
                           tooltip="{{curTPResults.hashStatus}}"
                           [ngClass]="setInboundStatusStyles(curTPResults.hashStatus).icon === 'fa-bug' ? 'fa-times-circle' : setInboundStatusStyles(curTPResults.hashStatus).icon"
                           [ngStyle]="{'color' : setInboundStatusStyles(curTPResults.hashStatus).color}">
                        </i>
                        <i class="fas fa-spinner fa-spin animated fadeIn" style="opacity: 0.4; font-size: 1rem; animation-duration: 1s;" *ngIf="!curTPResults"></i>
                    </div>
                </div>
                <div class="flxClmn animated fadeIn" style="align-items: center; flex: 1 0; padding: 10px; overflow: auto; min-height: 140px;"
                     *ngIf="curTPResults"
                     [ngStyle]="{'justify-content' : !curTPResults.resHash.list || !curTPResults.resHash.list.length ? 'center' : ''}">
                    <div style="flex: 1 0;" *ngIf="curTPResults.resHash.list && curTPResults.resHash.list.length">
                        <table class="w100 simpleDataTable message-analysis-table middle" style="table-layout: fixed;">
                            <tr>
                                <th style="padding: 0;"></th>
                            </tr>
                            <tr *ngFor="let file of curTPResults.resHash.list">
                                <td>
                                    <div class="flxClmn vSpace1 ellipsis" style="padding: 10px 0;">
                                        <div class="flxRow" style="align-items: center;">
                                                <span style="font-weight: 500; flex: 1 0; padding-right: 15px;" class="ellipsis">
                                                    {{file.name}}
                                                </span>
                                            <div class="flxRow" style="align-items: center;" (ngInit)="file.icon = setInboundStatusStyles(file.status).icon;">
                                                <i class="fas" *ngIf="!file.category" [ngClass]="file.icon === 'fa-check-circle' ? 'fa-check' : file.icon === 'fa-bug' ? 'fa-times-circle' : file.icon"
                                                   [ngStyle]="{'color': setInboundStatusStyles(file.status).color}" tooltipDirection="right" tooltip="{{file.status}}"></i>
                                                <div class="flxRow hSpace05" style="background: white; width: fit-content; border: solid 2px; border-radius: 3px; padding: 2px 7px; align-items: center;"
                                                     *ngIf="file.category"
                                                     [ngStyle]="{'border-color': setInboundStatusStyles(file.status).color,
                                                                                    'color': setInboundStatusStyles(file.status).color}">
                                                    <i class="fa" style="color: inherit; font-size: 10px;" [ngClass]="setInboundStatusStyles(file.status).icon"></i>
                                                    <span style="color: inherit; font-size: 11px;">{{file.category}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flxRow" style="align-items: flex-end;">
                                                <span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" *ngIf="file.type">
                                                                        <b>Type</b>: {{file.type}} ({{file.name && file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase()}})
                                                </span>
                                        </div>
                                        <div class="flxRow" style="align-items: flex-end;">
                                                <span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" *ngIf="file.hash">
                                                    <b>Hash</b>: {{file.hash}}
                                                </span>
                                            <a *ngIf="file.hash" (click)="copyItemToClipboard(file.hash);" style="text-align: right;">
                                                <i class="fas fa-copy copy-link" style="opacity: 0.7;" tooltip="Copy file hash"></i>
                                            </a>
                                        </div>
                                        <div class="flxRow" style="align-items: flex-end;" *ngIf="file.malware_type">
                                                    <span class="ellipsis" style="display: block; font-weight: 300; padding-right: 45px; flex: 1 0;" >
                                                        <b>Malware Type</b>: <span tooltip="{{file.malware_type}}">{{file.malware_type}}</span>
                                                    </span>
                                            <a (click)="copyItemToClipboard(file.malware_type);" style="text-align: right;">
                                                <i class="fas fa-copy" style="opacity: 0.7;" tooltip="Copy malware type"></i>
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <span *ngIf="!curTPResults.resHash.list || !curTPResults.resHash.list.length">No attachments in email</span>
                </div>
                <div class="flxClmn-center" style="align-items: center; flex: 1 0;" *ngIf="!curTPResults">
                    <span style="opacity: 0.5;">Loading</span>
                </div>
            </div>
        </div>
    </div>
</div>
