<popup-c class="bs-enabled no-content-padding"
		 head="Preview Attachment"
		 [size]="isFullScreen ? 'fullscreen' : 'medium'"
		 *ngIf="popupData"
		 [subTitle]="popupData.name"
		 (closeCb)="onClose.emit();">
	<div class="flex-column f-1-0 w-100 p-3 overflow-auto position-relative">
		<!--loader / error message-->
		<div class="d-flex centralize f-1-0" *ngIf="popupData.loading || popupData.error">
			<loader *ngIf="popupData.loading" height="100" width="100" style="opacity: 0.8;"></loader>
			<span *ngIf="popupData.error" class="flxClmn centralize text-danger">{{popupData.error}}</span>
		</div>

		<ng-container *ngIf="!popupData.loading && !popupData.error">
			<!--pdf-->
			<pdf-viewer *ngIf="fileExt === 'pdf'" class="animated fadeIn f-1-0 m-auto shadow h-100" style="width: 50%; max-width: 55vh;" [src]="popupData.pdfSrc"  [render-text]="true" [original-size]="false" [autoresize]="isFullScreen ? true : false"></pdf-viewer>

			<!--word doc-->
			<ngx-doc-viewer *ngIf="fileExt === 'docx'" class="animated fadeIn f-1-0 m-auto p-4 shadow overflow-auto" style="width: 50%; max-width: 55vh;" viewer="mammoth" [url]="popupData.docUrl"></ngx-doc-viewer>

			<!--sheet-->
			<virtual-scroller *ngIf="popupData.xslRows"
							  class="animated fadeIn f-1-0"
							  #attachmentPreviewScroller [items]="popupData.xslRows"
							  [ngClass]="{'is-loading-or-empty' : !popupData.xslRows?.length}">
				<table class="simpleDataTable w-100 overflow-auto" style="table-layout: fixed; word-break: break-word;">
					<tr *ngFor="let row of attachmentPreviewScroller.viewPortItems">
						<td *ngFor="let cell of row">{{cell}}</td>
					</tr>
				</table>
			</virtual-scroller>

			<!--text-->
			<span class="animated fadeIn break-word w-100 f-1-0"
				 *ngIf="popupData.text">
				{{popupData.text}}
			</span>

			<!--image-->
			<img class="animated fadeIn mx-auto w-100" *ngIf="popupData.image"
				 [src]="popupData.image"
				 onload="this.style.maxWidth = `${this.naturalWidth}px`"
				 alt="Attachment" [ngStyle]="{'max-width' : popupData.imgNaturalWidth}">

			<!--html/eml-->
			<div *ngIf="popupData.eml" class="w-100" style="flex:1 0; overflow: auto;">
				<email-viewer [content]="popupData.eml" style="min-height: 100%;"></email-viewer>
			</div>
		</ng-container>
	</div>
	<div class="d-flex centralize p-2 bg-white cursor-pointer hvr-darken-light opacity-75 border border-secondary-subtle"
		 *ngIf="!popupData.loading && !popupData.error"
		 style="position: absolute; left: 0; bottom: 0;"
		 (click)="isFullScreen = !isFullScreen;">
		<i [ngClass]="isFullScreen ? 'fa fa-compress' : 'fa fa-expand'" style="font-size: 1.5rem;"></i>
	</div>
</popup-c>
