import {eachLimit} from 'async';
import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {AuthService} from "../../../../services/authService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, NgZone, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import util from "util";

const MULT_PART_SIZE = 1024*1024*6;		 // 6MB
const MAX_FAILED_PARTS = 10;
const PARALLEL_UPLOADS = 5;

@Component({
	selector: 'archive-migration-component',
	templateUrl: './archive-migration.component.html',
})
export class ArchiveMigrationComponent implements OnInit {
    constructor(private http:HttpClient,
				private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService,
				private authService:AuthService,
				private ngZone:NgZone){
    }

    dic = DICTIONARY;
    emailsArchiveFiles: any = null;
	_ = _;

	isLoaded = false;
    config;
	apiMigrationData: any = {};
	awsMigrationData: any = {};
    userInfo;
	planInfo;

	startApiMigrationPopup: any;
	startAWSMigrationPopup: any = {};

	ngOnInit() {
        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
        });

		this.rs.getArchiveConfiguration().then(response => {
            this.config = response.archive;
			this.planInfo = response.planInfo;

			this.isLoaded = true;

			this.checkRunningApiMigration();
			this.checkRunningAWSMigration();
        }, (err) => {
			this.isLoaded = true;
		});
    };

	startAWSMigration() {
		if (this.startAWSMigrationPopup.migrationInProcess) {
			return;
		}

		if (!this.startAWSMigrationPopup.trustifiBucket) {
			if (!this.startAWSMigrationPopup.bucket || !this.startAWSMigrationPopup.accessKeyId || !this.startAWSMigrationPopup.secretAccessKey) {
				this.ns.showErrorMessage('Please fill AWS S3: bucket, key and secret');
				return;
			}
		}

		this.startAWSMigrationPopup.migrationInProcess = true;

		const migrationInfo = {
			type: this.startAWSMigrationPopup.type,
			bucket: this.startAWSMigrationPopup.bucket,
			accessKeyId: this.startAWSMigrationPopup.accessKeyId,
			secretAccessKey: this.startAWSMigrationPopup.secretAccessKey,
			prefix: this.startAWSMigrationPopup.prefix
		};

		this.rs.startAWSMigration(migrationInfo).then(res => {
			this.startAWSMigrationPopup = {};
			this.checkRunningAWSMigration();
			this.ns.showInfoMessage('Migration from AWS successfully started. You can review progress in the tracking table below');
		}, err => {
			console.error(err);
			this.startAWSMigrationPopup.migrationInProcess = false;
		});
	}

	checkRunningAWSMigration = () => {
		this.awsMigrationData.getDataInProcess = true;

		this.rs.watchAWSMigration().then(res => {
			this.awsMigrationData.getDataInProcess = false;
			this.awsMigrationData.data = res || [];
			for (let obj of this.awsMigrationData.data) {
				obj.status = obj.failed ? 'Failure' : obj.completed ? 'Success':'In Process';
				if (obj.key?.startsWith(this.userInfo.plan._id+'/')) {
					obj.key = obj.key.substring(this.userInfo.plan._id.length+1);
				}
				if (!obj.failed_emails) obj.failed_emails = '';
				if (!obj.migrated_emails) obj.migrated_emails = '';
			}
		}, err => {
			console.error(err);
			this.awsMigrationData.getDataInProcess = false;
		});
	}

	deleteAWSMigration() {
		this.gs.showPopup({
			title: 'Abort Archive AWS Migration Process',
			subTitle: `The migration process will be stopped`,
			body: ['Emails that have already been successfully migrated to the archive will remain accessible there.'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				this.rs.deleteAWSMigration().then(res => {
					this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
					this.checkRunningAWSMigration();
				}, err => {
					console.error(err);
					this.ns.showErrorMessage('Failed to delete AWS Migration. '+(err.data && err.data.message || 'Error'));
					this.awsMigrationData.data = [];
				});
			}
		});

	}

	openStartAWSMigrationPopup = () => {
		this.startAWSMigrationPopup = {
			type: 'eml',
			prefix: '',
			show: true
		};

		if (this.userInfo.plan._id === '650cc08cd74b1462095f319b') {
			this.startAWSMigrationPopup.trustifiBucket = true;
		}
	}

	startAPIMigration() {
		if (this.startApiMigrationPopup.migrationInProcess) {
			return;
		}

		const allowedPlans = [
			'650cc08cd74b1462095f319b', // minhazul.huq@bkash.com
			'675fd357701f87f8f48fdbda', // cloud-admin@trustifitest.com
			'676963d0533247a3ec445410', // cloud-admin@trustifitest.com (stage)
			'678381bb40248ca4899afb85', // mbtest85@gmail.com (stage)
			'67530a0c978a0d406e5493a7',
			'66ba22e271941b9780ba1e7a',
			'67d8864e4caea4bda08ed670'
		];
		if (!allowedPlans.includes(this.userInfo.plan._id)) {
			this.ns.showInfoMessage('Please contact our support to use this feature');
			return;
		}

		// run migration from BE
		const runMigration = () => {
			this.planInfo.exchange_server.archive_enabled = true;
			this.startApiMigrationPopup.migrationInProcess = true;

			//maxProcesses - should be less or equal to the number of running GeneralOperationWorkers
			//DEBUG: startNewMigration:false, maxProcesses:5, maxUsers:6,
			this.rs.startAPIMigration({archiveYears: this.startApiMigrationPopup.archiveYears}).then(res => {
				this.apiMigrationData.processingEvents = res || [];
				this.startApiMigrationPopup = null;
				this.checkRunningApiMigration();
			}, err => {
				console.error(err);
				this.ns.showErrorMessage('Failed to run API Migration. '+(err.data && err.data.message || 'Error'));
				this.startApiMigrationPopup.migrationInProcess = false;
			});
		}

		if (this.planInfo.exchange_server.archive_enabled) {
			runMigration();
			return;
		}

		// Microsoft login and gain access
		const azEndpoint = this.planInfo.exchange_server.azuread_endpoint || DICTIONARY.CONSTANTS.exchangeServerEndpoints.Global.AzureAD;
		this.authService.authMicrosoftExchangeAdmin(this.userInfo.email, azEndpoint, this.planInfo.exchangeArchiveAppId, 'archive', (err) => {
			if (err) {
				if (err !== true) {
					err = err.replace('Sign in failed', 'Exchange API authentication failed');
					this.ns.showErrorMessage('Failed to run API Migration. '+(err.data && err.data.message || 'Error'));
					this.startApiMigrationPopup.migrationInProcess = false;
				}
				return;
			}

			runMigration();
		});
	}

	checkRunningApiMigration() {
		this.apiMigrationData.getDataInProcess = true;

		this.rs.getAPIMigrationReport().then(res => {
			this.apiMigrationData.processingEvents = res.processingEvents;
			this.apiMigrationData.completedUsers = res.completedUsers;
			this.apiMigrationData.migratedEmails = res.migratedEmails;
			this.apiMigrationData.failedEmails = res.failedEmails;
			this.apiMigrationData.started = res.started;
			this.apiMigrationData.completed = res.completed;

			if (res.processingEvents?.length) {
				//id,failedEmails,migratedEmails,skippedEmails,lastDate,skip,completed
				this.rs.watchAPIMigration(this.apiMigrationData.processingEvents.join(',')).then(res => {
					this.ngZone.run(() => {
						this.apiMigrationData.archivedUsers = res;
						this.apiMigrationData.completedUsers = this.apiMigrationData.archivedUsers.filter(itm => itm.completed).map(itm => itm.id);
						this.apiMigrationData.migratedEmails = _.sumBy(this.apiMigrationData.archivedUsers, 'migratedEmails');
						this.apiMigrationData.failedEmails = _.sumBy(this.apiMigrationData.failedEmails, 'migratedEmails');
						this.apiMigrationData.getDataInProcess = false;
					});
				}, err => {
					this.apiMigrationData.getDataInProcess = false;
				});
			}
			else {
				this.apiMigrationData.getDataInProcess = false;
			}
		}, err => {
			this.apiMigrationData.getDataInProcess = false;
		});
	}

	deleteAPIMigration() {
		this.gs.showPopup({
			title: 'Abort Archive API Migration Process',
			subTitle: `The migration process will be stopped`,
			body: ['Emails that have already been successfully migrated to the archive will remain accessible there.'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				this.rs.deleteAPIMigration('').then(res => {
					this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
					this.checkRunningApiMigration();
				}, err => {
					console.error(err);
					this.ns.showErrorMessage('Failed to delete API Migration. '+(err.data && err.data.message || 'Error'));
					this.apiMigrationData.archivedUsers = [];
				});
			}
		});

	}

	openStartAPIMigrationPopup = () => {
		const allowedPlans = [
			'66ba22e271941b9780ba1e7a', // chriswilson@nutriquest.com
			'676963d0533247a3ec445410', // cloud-admin@trustifitest.com (stage)
			'67530a0c978a0d406e5493a7',
			'67d8864e4caea4bda08ed670',
		];
		if (!allowedPlans.includes(this.userInfo.plan._id)) {
			this.ns.showInfoMessage('Please contact our support to use this feature');
			return;
		}

		if (!this.planInfo.exchange_server?.enabled) {
			this.ns.showErrorMessage('Microsoft Exchange integration must be enabled to perform API archive migration');
			return;
		}

		this.startApiMigrationPopup = {
			archiveYears: 1,
			show: true
		};
	}

	searchMigrationMailbox = (searchTerm, activeFilters) => {
		this.apiMigrationData.archivedUsers.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchMigrationMailboxTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	searchS3Bucket = (searchTerm, activeFilters) => {
		this.awsMigrationData.data.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchS3PrefixTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	uploadFileWrapper = (files) => {
		if (!files?.length) {
			return;
		}

		this.gs.showPopup({
			title: 'Upload Archive Migration File',
			subTitle: `You are about migrate archived emails for ${this.gs.controlAdmin?.email ? this.gs.controlAdmin?.email : 'your plan'}`,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				let parallelUploads = files.length > 1 ? 1 : PARALLEL_UPLOADS;
				let failedUploads = [];

				eachLimit(files, PARALLEL_UPLOADS, (file, next) => {
					this.uploadFile(file, parallelUploads, 0, err => {
						if (err) {
							failedUploads.push(err);
							//when we have "err.error" we can stop further files processing
						}

						next();
					});
				}, err => {
					if (!failedUploads.length) {
						this.ns.showInfoMessage('All files were successfully uploaded. Migrated archive data will be available to query and view after 24 hours');
					}
					else {
						let uploadedFiles = files.length - failedUploads.length;
						this.ns.showErrorMessage(`Successfully uploaded ${uploadedFiles} files and failed to upload files: ${failedUploads.map(itm => itm.filename).join(', ')}.`);
					}
				});
			},
			cancelCb: () => {
				this.emailsArchiveFiles = [];
			}
		});
	}

	uploadFile = (file, parallelUploads=PARALLEL_UPLOADS, missingParts=0, cb) => {
		if (!file || !file.name || !file.size) {
			return cb();
		}

		const notIdx = this.ns.showInfoMessage(this.dic.MESSAGES.uploadArchiveFile, { timeout: 0 });

		let failedParts = 0,  partSize = MULT_PART_SIZE, numParts = 1, uploadId = 0;

		if (file.size > partSize) {
			numParts = file.size / partSize | 0;
			if (file.size % partSize) {
				numParts++;
			}

			if (numParts > 9999) {
				numParts = 9999;
				partSize = file.size / numParts | 0;
				if (file.size % partSize) {
					numParts++;
				}
			}
		}
		else {
			partSize = file.size;
		}

		//create new or resume existing multipart upload
		this.rs.createMultipartUpload(file.name, {fileSize: file.size, numParts, partSize}).then(response => {
			//returned: key, uploadId, size, parts, completed

			uploadId = response.uploadId;
			let uploadedSize = response.size;
			if (response.completed) {
				//multipart upload is completed
				this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.uploadArchiveFileDone, file.name));
				if (uploadedSize !== file.size) {
					//potential bug - we have to monitor
					console.error('Archive upload completed with different content sizes, the difference is in '+(file.size-uploadedSize)+' bytes.');
				}
				return cb();
			}

			//no improvement after previous recursive call
			if (missingParts === response.parts.length) {
				//DEBUG: uploadId && this.rs.abortMultipartUpload(file.name, file.size, uploadId);
				this.ns.showErrorMessage('Archive upload failed. Failed to load '+missingParts+' parts. You can try to upload again after a few minutes or contact support.');
				return cb({message:'Failed to load '+missingParts+' parts', filename:file.name, uploadId});
			}

			this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.uploadArchiveFileProgress, uploadedSize / file.size * 100 | 0, file.name));

			eachLimit(response.parts, parallelUploads, (part, next) => {
				const end = part.key * partSize;		//part numbers start with "1"
				const start = end - partSize;
				const blob = file.slice(start, end);

				if (blob && blob.size) {
					//lastValueFrom(this.http.put(part.url, blob).pipe(map(res=>res)).then(res => {}, err => {);
					this.http.put(part.url, blob).subscribe({next: event => {
							uploadedSize += blob.size;
							this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.uploadArchiveFileProgress, uploadedSize / file.size * 100 | 0, file.name));
							next();
						}, error: error => {
							setTimeout(() => {
								//lastValueFrom(this.http.put(part.url, blob).pipe(map(res=>res)).then(res => {}, err => {);
								this.http.put(part.url, blob).subscribe({next: event => {
										uploadedSize += blob.size;
										this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.uploadArchiveFileProgress, uploadedSize / file.size * 100 | 0, file.name));
										next();
									}, error: error => {
										if (failedParts >= MAX_FAILED_PARTS) {
											next(error.data || error);
										}

										failedParts++;
										next();
									}});
							}, 30000);
						}});
				}
				else {
					console.error('empty part with start index '+start);
					next();
				}
			}, err => {
				if (err) {
					console.error(err);
					this.ns.showErrorMessage('Archive upload failed. Error: '+err.message+' parts. You can try to upload again later, or contact support.');
					return cb({message:err.message, filename:file.name, uploadId});
				}

				//run function again to validate if there remained missing parts
				this.uploadFile(file, parallelUploads, response.parts.length, cb);
			});
		}).catch(err => {
			//DEBUG: uploadId && this.rs.abortMultipartUpload(file.name, file.size, uploadId);
			console.error(err);
			this.ns.showErrorMessage('Archive upload failed. '+(err.data && err.data.message || 'Error')+'. You can try to upload again later, or contact support.');
			cb({message:err.data && err.data.message || err.message || 'Error', filename:file.name, uploadId});
		});
	};
}

function searchS3PrefixTextExecute(reviewer, searchTerm) {
    return (reviewer.key && reviewer.key.toLowerCase().indexOf(searchTerm) > -1);
}

function searchMigrationMailboxTextExecute(mailbox, searchTerm) {
    return (mailbox.id && mailbox.id.toLowerCase().indexOf(searchTerm) > -1);
}
