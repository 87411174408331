<div class="flxClmn dashboard-page" style="position: relative;"
     [ngStyle]="generatePdfInProcess && {'overflow' : 'hidden'}">
    <div class="page-title-container flxClmn-center" style="z-index: 45;">
        <div class="page-title-inner-container">
            <span class="page-title">Trends and Insights</span>
        </div>
		<div class="page-subtitle-container flxRow-between hSpace4">
            <span class="page-subtitle f-1-0">Review your outbound security trends and get insights and action recommendations</span>
			<div class="flxRow hSpace1" style="border: solid 1px #ffdca5; padding: 3px 10px;" *ngIf="trendsData?.created">
				<span style="color: #b67000; font-weight: 500;">Data updated as of {{trendsData.created | date: 'MM/dd/yyyy HH:mm'}}</span>
				<a class="blue-link" style="font-weight: bold;" (click)="getTrendsData(true);">Reload</a>
			</div>
        </div>
    </div>

    <div class="page-inner-content-holder bs-enabled" style="background: white; padding-top: 0; position: relative;"
         [ngClass]="{'no-scroll' : generatePdfInProcess}"
         [ngStyle]="{'overflow' : generatePdfInProcess ? 'hidden' : ''}">
        <!--generate pdf loader-->
        <div *ngIf="generatePdfInProcess" class="pdf-generate-loader">
            <div style=" position: absolute; top: calc(50vh - 200px - 40px); left: calc(50vw - 70px); transform: translate(-50%,0); font-size: 4.3rem; opacity: 0.5; align-items: center;"
                 class="animated flxClmn vSpace2 infinite">
                <div class="animated bounce infinite">
                    <i class="far fa-file-pdf grey-to-redFX" style="filter: contrast(1.5) drop-shadow(2px -1px 1px lightgrey);"></i>
                </div>
                <span style="position: relative; font-size: 1.3rem;" class="loading-dots sss">Generating PDF. Please wait</span>
            </div>
        </div>
        <!---->

        <div class="flxRow-between gap-2 mt-0 animated fadeIn" style="position: relative; z-index: 45; align-items: center; margin: 20px 0;">
            <div class="period-buttons-group bs-enabled flxRow hSpace1" style="height: 30px;">
                <button class="period-button bs-btn bs-btn-light h-100"
                        *ngFor="let item of dashboardPeriod | keyvalue: gs.returnZero"
						[ngClass]="{'chosen' : period === item.value, 'd-none' : item.value.display === dic.CONSTANTS.trendsPeriod.range.display, 'disabled': batchInProcess}"
                        (click)="changePeriod(item.value);">
                    {{item.value.display}}
                </button>
                <div class="position-relative range-button">
                    <button class="period-button hSpace05 bs-btn bs-btn-light h-100" id="customPeriodBtn"
							[ngClass]="{'chosen' : period.display === dic.CONSTANTS.trendsPeriod.range.display, 'disabled': batchInProcess}"
                            (click)="showTrendsCustomPeriod = !showTrendsCustomPeriod;">
                        <span *ngIf="period?.display === dic.CONSTANTS.trendsPeriod.range.display" style="font-weight: 300;">
                            From <b class="px-2">{{range.start | date:'MM/dd/yyyy'}}</b> Until <b class="px-2">{{range.end | date:'MM/dd/yyyy'}}</b>
                        </span>
                        <span *ngIf="period?.display !== dic.CONSTANTS.trendsPeriod.range.display">
                            Custom
                        </span>
                        <i class="fa fa-sort-down" style="margin-top: -5px;"></i>
                    </button>
                    <!--custom period modal-->
                    <div class="drop-menu mt-1 p-2" style="width: fit-content; max-width: none; min-width: 300px; overflow: visible; min-height: 0;"
                         [ngClass]="{'active' : showTrendsCustomPeriod}">
                        <div class="p-3 text-start m-0 flex-row align-items-center gap-2" (clickOutside)="showTrendsCustomPeriod = false;"
							 style="min-width: 490px;"
                             [exclude]="'#customPeriodBtn'">
							<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
								<span>Date Range:</span>
								<date-picker-c class="f-1-0"
											   [(dateModel)]="range"
											   [isRange]="true"
											   [dateFormat]="'mm/dd/yyyy'">
								</date-picker-c>
							</div>
                            <btn-c endIcon="zmdi zmdi-check"
                                 [loading]="getDataInProcess"
                                 (action)="changePeriod(dic.CONSTANTS.trendsPeriod.range);">
                                Get Trends
                            </btn-c>
                        </div>
                    </div>
                </div>

				<div class="flxRow hSpace1" style="align-items: center; margin: 0 10px;">
				<span style="margin-left: auto;" *ngIf="totalEmails">
					<b style="font-size: 14px;">
						Total Records: {{totalEmails}}
					</b>
					<i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
					   tooltip='The trends report presented below has been generated based on this total number of sent emails'></i>
					<i class="fas fa-arrow-to-left opacity-50"></i>
				</span>
					<div class="flxRow hSpace1" style="align-items: center;" *ngIf="(batchInProcess && outboundEmails?.length)">
						<span>|</span>
						<span class="loading-dots" style="font-size: 15px; font-weight: bold; opacity: 0.65;">Processing</span>
						<span style="font-size: 15px; font-weight: bold; opacity: 0.65;">{{getPercentage(outboundEmails.length, totalEmails)}}%</span>
						<btn-c variant="text" color="danger"
							   style="min-width: 0;"
							   *ngIf="showAbortLoadingEmailsLabel"
							   (action)="abortLoadingEmails();">
							Abort
						</btn-c>
					</div>
				</div>
            </div>

            <btn-c endIcon="fas fa-file-download" variant="outlined"
                 [isDisabled]="!trendsData || generatePdfInProcess || getTrendsDataInProcess || batchInProcess"
                 (action)="exportChartsToPdf();">
                Export to PDF
            </btn-c>
        </div>

        <div class="bs-enabled" id="trendsChartsContainer">
            <div class="bs-container outbound-trends-cards text-center" style="max-width: none !important;"
                 [ngClass]="{'w-100' : !generatePdfInProcess}">
				<div class="bs-row trends-pdf-page-title" style="display: none;">
					<img class="mb-3" [src]="lfs.logo" style="width: 200px;"/>
				</div>
                <div class="bs-row trends-pdf-page-title" style="display: none;">
                    <div class="bs-col-12 mb-4 px-4" style="background: #fdfdfd; padding: 10px;">
                        <div class="flxClmn vSpace05">
                            <div class="flxRow-between" style="align-items: flex-end;">
                                <span style="font-weight: bold; font-size: 1.6rem; white-space: nowrap;">Outbound Trends and Insights</span>
                                <span style="font-size: 1.25rem; white-space: nowrap;">
                                Date printed: &nbsp;
                                <time>{{todayDate | date:'MM/dd/yyyy'}}</time>
                            </span>
                            </div>
                            <div class="flxRow hSpace05" style="font-size: 1.25rem;">
                                <span>Period:&nbsp;</span>
                                <span *ngIf="period !== dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">{{period.display}}</span>
                                <span *ngIf="period === dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">
							<time>{{range.start | date:'MM/dd/yyyy'}}</time>
							&nbsp;-&nbsp;
							<time>{{range.end | date:'MM/dd/yyyy'}}
							</time>
						</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bs-row g-4">
                    <!--Outbound Trends-->
                    <div class="bs-col-6 bs-col-large-4" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
                            <div class="flex-column align-items-start gap-1">
                                <div class="flex-row align-items-center gap-1">
                                    <h6 class="m-0 fw-bold text-nowrap">Outbound Trends</h6>
                                    <i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
                                       tooltip='Shows how many secure emails (which use "Encrypt Message Content" and/or "Require Authentication") were sent in selected time period out of the total emails sent using Trustifi'></i>
                                    <i class="fas fa-arrow-to-left opacity-50"></i>
                                </div>
                                <span class="opacity-75 fw-500 text-start" style="height: 16px;">{{trendsData?.general?.total}} emails sent in the {{period.display.toLowerCase()}}</span>
                            </div>
							<apx-chart style="width: 100%;"
									   *ngIf="outboundEmailsChartOptions && trendsData?.general?.total && !getTrendsDataInProcess"
									   #outboundEmailsChart
									   class="f-1-0 animated fadeIn clickable-chart"
									   [series]="outboundEmailsChartOptions.series"
									   [plotOptions]="outboundEmailsChartOptions.plotOptions"
									   [chart]="outboundEmailsChartOptions.chart"
									   [dataLabels]="outboundEmailsChartOptions.dataLabels"
									   [colors]="outboundEmailsChartOptions.colors"
									   [xaxis]="outboundEmailsChartOptions.xaxis"
									   [yaxis]="outboundEmailsChartOptions.yaxis"
									   [fill]="outboundEmailsChartOptions.fill"
									   [stroke]="outboundEmailsChartOptions.stroke">
							</apx-chart>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.general?.total || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Outbound rules usage-->
                    <div class="bs-col-6 bs-col-large-4" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
                            <div class="flex-row justify-content-between">
                                <div class="flex-column align-items-start gap-1">
                                    <div class="flex-row align-items-center gap-1">
                                        <h6 class="m-0 fw-bold">Outbound Rules Usage</h6>
                                        <i class="fas fa-arrow-to-left opacity-50"></i>
                                    </div>
                                    <span *ngIf="!getTrendsDataInProcess && trendsData?.rules?.total" class="opacity-75 fw-500 text-start" style="height: 16px;">{{trendsData.rules.total}} emails caught by outbound rules in the {{period.display.toLowerCase()}}</span>
                                </div>
                            </div>
                            <div class="flex-column f-1-0 centralize animated fadeIn position-relative"
                                 *ngIf="outboundRulesChartOptions?.labels?.length && !getTrendsDataInProcess">
								<apx-chart style="width: 100%; max-width: 400px;"
										   #outboundRulesChart
										   class="pie-chart clickable-chart"
										   [series]="outboundRulesChartOptions.series"
										   [plotOptions]="outboundRulesChartOptions.plotOptions"
										   [chart]="outboundRulesChartOptions.chart"
										   [dataLabels]="outboundRulesChartOptions.dataLabels"
										   [labels]="outboundRulesChartOptions.labels">
								</apx-chart>
                            </div>
                            <!--actions menu-->
                            <div class="trends-card-actions-menu" (ngInit)="showOutboundRulesMenu = false;">
                                <i class="fas fa-ellipsis-h" id="outboundRulesMenuTrigger" tooltip="Menu"
                                   (click)="showOutboundRulesMenu = !showOutboundRulesMenu"></i>
                                <div class="drop-menu" [ngClass]="{'active' : showOutboundRulesMenu}">
                                    <ul (clickOutside)="showOutboundRulesMenu = false;"
                                        [exclude]="'#outboundRulesMenuTrigger'">
                                        <li class="flex-row align-items-center"
                                            [routerLink]="['/' + dic.CONSTANTS.appStates.adminOutbound, dic.CONSTANTS.adminPages.outbound.policyManagement, dic.CONSTANTS.outboundRulesAndPoliciesPageTabs.rules]">
                                            <span>Go to Outbound Rules</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--tips modal-->
                            <div class="trends-card-actions-menu tips"
                                 *ngIf="outboundRulesChartOptions"
                                 [ngClass]="{'active' : showOutboundRulesTips}">
                                <i class="fa fa-lightbulb" id="outboundRulesTipsTrigger" tooltip="Tips"
                                   (click)="showOutboundRulesTips = !showOutboundRulesTips"></i>
                                <div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
                                     [ngClass]="{'active' : showOutboundRulesTips}">
                                    <ul style="cursor: default;" (clickOutside)="showOutboundRulesTips = false;"
                                        [exclude]="'#outboundRulesTipsTrigger'" >
                                        <div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
                                            <i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
                                            <span class="fw-500">Click on any pie slice to view the usage of the rule.</span>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!outboundRulesChartOptions?.labels?.length || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Email Events Rate-->
                    <div class="bs-col-6 bs-col-large-4">
                        <div class="trends-data-card flex-column gap-1 p-3 animated fadeIn h-100 w-100">
                            <div class="flex-row justify-content-between">
                                <div class="flex-column align-items-start gap-1">
                                    <div class="flex-row align-items-center gap-1">
                                        <h6 class="m-0 fw-bold">Deliverability to Recipients</h6>
                                        <i class="fas fa-arrow-to-left opacity-50"></i>
                                    </div>
                                    <span class="opacity-75 fw-500 text-start" style="height: 16px;">Displays the percentage of emails opened, bounced and complaints out of the total email recipients in the {{period.display.toLowerCase()}}</span>
                                </div>
                            </div>

                            <div class="flex-column f-1-0 centralize animated fadeIn"
                                 *ngIf="eventRateChartOptions && trendsData?.general?.total && !getTrendsDataInProcess">
								<apx-chart style="flex: 1 0; max-height: 270px; width: 100%; max-width: 700px;"
										   #eventRateChart
										   class="pie-chart"
										   [series]="eventRateChartOptions.series"
										   [plotOptions]="eventRateChartOptions.plotOptions"
										   [chart]="eventRateChartOptions.chart"
										   [colors]="eventRateChartOptions.colors"
										   [legend]="eventRateChartOptions.legend"
										   [labels]="eventRateChartOptions.labels">
								</apx-chart>
                            </div>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.general?.total || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bs-col-4 mt-4 bs-container large-only" [ngStyle]="{'display' : generatePdfInProcess ? 'contents' : ''}">
                        <div class="bs-row bs-row-cols-1 g-4 large-only" style="gap: 3px;" [ngStyle]="{'display' : generatePdfInProcess ? 'contents' : ''}">
                            <!--Top Senders Domains-->
                            <div class="bs-col-6 bs-col-large-12" style="height: clamp(350px,50vh,405px);">
                                <div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
                                    <div class="flex-column align-items-start gap-1">
                                        <div class="flex-row align-items-center gap-1">
                                            <h6 class="m-0 fw-bold text-nowrap">Top Senders</h6>
                                            <i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
                                               tooltip="Displays the top 10 users within your organization who send the highest number of emails."></i>
                                            <i class="fas fa-arrow-to-left opacity-50"></i>
                                        </div>
                                    </div>
									<apx-chart class="f-1-0 w-100 animated fadeIn"
											   *ngIf="topSendersChartOptions?.xaxis?.categories?.length && !getTrendsDataInProcess"
											   #topSendersChart
											   [series]="topSendersChartOptions.series"
											   [plotOptions]="topSendersChartOptions.plotOptions"
											   [chart]="topSendersChartOptions.chart"
											   [colors]="topSendersChartOptions.colors"
											   [dataLabels]="topSendersChartOptions.dataLabels"
											   [xaxis]="topSendersChartOptions.xaxis">
									</apx-chart>

                                    <!--actions menu-->
                                    <div class="trends-card-actions-menu">
                                        <i class="fas fa-ellipsis-h" id="topSenderMenuTrigger" tooltip="Menu"
                                           (click)="showTopSenderMenu = !showTopSenderMenu"></i>
                                        <div class="drop-menu" [ngClass]="{'active' : showTopSenderMenu}">
                                            <ul (clickOutside)="showTopSenderMenu = false;"
                                                [exclude]="'#topSenderMenuTrigger'">
                                                <li class="flex-row align-items-center"
                                                    (click)="exportSendersToCsv();">
                                                    <span>Export to CSV</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!--loader / placeholder-->
                                    <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!topSendersChartOptions?.xaxis?.categories?.length || getTrendsDataInProcess">
                                        <div class="flex-column text-center centralize f-1-0">
                                            <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                            <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Top Recipients Domains-->
                            <div class="bs-col-6 bs-col-large-12" style="min-height: clamp(350px,50vh,405px);">
                                <div class="trends-data-card flex-column gap-1 p-3 animated fadeIn h-100 w-100">
                                    <div class="flex-row justify-content-between">
                                        <div class="flex-column align-items-start gap-1">
                                            <div class="flex-row align-items-center gap-1">
                                                <h6 class="m-0 fw-bold">Top External Recipient Domains</h6>
                                                <i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
                                                   tooltip="Displays the top 10 external recipient domains that receive the highest number of emails from your organization."></i>
                                                <i class="fas fa-arrow-to-left opacity-50"></i>
                                            </div>
                                        </div>
                                    </div>

									<apx-chart class="f-1-0 animated fadeIn"
											   *ngIf="topRecipientsDomainsChartOptions && trendsData?.general?.total && !getTrendsDataInProcess"
											   #topRecipientsDomainsChart
											   [series]="topRecipientsDomainsChartOptions.series"
											   [plotOptions]="topRecipientsDomainsChartOptions.plotOptions"
											   [chart]="topRecipientsDomainsChartOptions.chart"
											   [colors]="topRecipientsDomainsChartOptions.colors"
											   [dataLabels]="topRecipientsDomainsChartOptions.dataLabels"
											   [xaxis]="topRecipientsDomainsChartOptions.xaxis">
									</apx-chart>


                                    <!--actions menu-->
                                    <div class="trends-card-actions-menu">
                                        <i class="fas fa-ellipsis-h" id="topRecipientsMenuTrigger" tooltip="Menu"
                                           (click)="showTopRecipientsMenu = !showTopRecipientsMenu"></i>
                                        <div class="drop-menu" [ngClass]="{'active' : showTopRecipientsMenu}">
                                            <ul (clickOutside)="showTopRecipientsMenu = false;"
                                                [exclude]="'#topRecipientsMenuTrigger'">
                                                <li class="flex-row align-items-center"
                                                    (click)="exportRecipientsToCsv();">
                                                    <span>Export to CSV</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!--loader / placeholder-->
                                    <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.general?.total || getTrendsDataInProcess">
                                        <div class="flex-column text-center centralize f-1-0">
                                            <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                            <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Connections Graph-->
                    <div class="bs-col-6 bs-col-large-8 connections-graph-card" [ngStyle]="{'height' : generatePdfInProcess ? '650px' : ''}">
                        <div class="trends-data-card flex-column gap-3 p-3 animated fadeIn h-100 w-100">
                            <h6 class="m-0 fw-bold align-self-start">Connections Graph</h6>
                            <div class="opacity-75 fw-500 text-start">
                                Visualizes the connections between the <b style="color: green;">top 5 senders</b> in your organization and the <b style="color: darkblue;">recipient domains</b> of their emails.<br/>
                                The thickness of a box around a recipient domain corresponds to the number of emails they received.
                            </div>

							<connection-graph class="flxClmn f-1-0"
											  *ngIf="!batchInProcess && !getTrendsDataInProcess"
											  #connectionsGraphEl
											  [nodeOptionsFn]="connectionsGraphData?.nodeOptionsFn"
											  [rawGraphData]="connectionsGraphData?.graphData"></connection-graph>

                            <!--actions menu-->
                            <div class="trends-card-actions-menu">
                                <i class="fas fa-ellipsis-h" id="connectionsGraphMenuTrigger" tooltip="Menu"
                                   (click)="showConnectionsGraphMenu = !showConnectionsGraphMenu"></i>
                                <div class="drop-menu" [ngClass]="{'active' : showConnectionsGraphMenu}">
                                    <ul (clickOutside)="showConnectionsGraphMenu = false;"
                                        [exclude]="'#connectionsGraphMenuTrigger'">
                                        <li class="flex-row align-items-center"
                                            (click)="goToReportsPage();">
                                            <span>Configure reports</span>
                                        </li>
                                        <li class="flex-row align-items-center"
                                            (click)="connectionsGraphAllUsers();">
                                            <span>Show all users</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.general?.total || getTrendsDataInProcess || batchInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess && !batchInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess || batchInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--rule usage popup-->
    <outbound-rule-usage-c
            *ngIf="ruleUsagePopupData && ruleUsagePopupData?.show"
            [data]="ruleUsagePopupData">
    </outbound-rule-usage-c>

    <popup-c class="bs-enabled" *ngIf="connectionsGraphPopup?.show"
           head="Connections Graph for All Users" size="large"
           (closeCb)="connectionsGraphPopup = null;">
		<div class="flxClmn f-1-0" *ngIf="!connectionsGraphPopup.loading"
			 [ngClass]="{'bg-light flxRow centralize' : _.isEmpty(connectionsGraphPopup.graphData)}">
			<span class="animated fadeIn" *ngIf="_.isEmpty(connectionsGraphPopup.graphData)">
				No emails were sent from your plan's users
			</span>

			<div class="flxClmn f-1-0 vSpace2 animated fadeIn" *ngIf="!_.isEmpty(connectionsGraphPopup.graphData)">
				<span class="fw-500">Data period: {{period.display.toLowerCase()}}</span>
				<connection-graph class="flxClmn f-1-0"
								  [nodeOptionsFn]="connectionsGraphPopup.nodeOptionsFn"
								  [focusedNodesNames] = "connectionsGraphPopup.nodeSearchResults"
								  [rawGraphData]="connectionsGraphPopup.graphData"></connection-graph>
			</div>
		</div>
		<!--Loader-->
		<div class="flxClmn centralize f-1-0" *ngIf="connectionsGraphPopup.loading">
			<loader class="opacity-75" height="80" width="80"></loader>
		</div>
		<!---->
    </popup-c>
</div>
