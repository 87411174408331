<div class="flxClmn vSpace2" style="flex: 1 0; overflow: auto;">
	<span>Review operations performed by the plan admins</span>
    <!--logs table-->
    <trustifi-table-c
            class="trustifi-table" style="flex: 1 0; z-index: 5;"
            [list]="logs"
            [headers]="[
                    {text:'Date', sortKey:'created', width: '13%'},
                    {text:'Category', sortKey:'category', width: '13%'},
                    {text:'User', sortKey:'admin', width: '19%'},
                    {text:'Action'},
                ]"
            [cellsPrototype]="[
                    {textKey: 'created', textType: 'dateAndTime'},
                    {textKey: 'category'},
                    {textKey: 'admin', tooltip: true},
                    {html: 'actionCell'},
                ]"
            (searchFunction)="searchLogs($event.searchTerm, $event.activeFilters)"
            [filterData]="filterAuditLogData"
            [options]="{
                    exportToCsvFunction: exportToCsv,
                    loadingTableFlag: loadingLogsInProcess,
                    itemsNameSingular: 'log',
                    refreshFunction: getAdminLogs,
                    defaultSortKey: '-created',
                    itemUniqueIdentifier: '_id',
                    isEllipsis: false,
                    customButtons: [{icon: 'fas fa-calendar-day', function: scheduleReport, tooltip: 'Schedule Report'}],
                }"
    >
		<ng-template #customCells let-item let-cellId="cellId">
    	    <!--action cells-->
			<div *ngIf="cellId === 'actionCell'">
				<div class="flxRow-start hSpace1 expandable-record-row" style="max-width : 850px; " #expandableRecord
                     [ngStyle]="{'cursor': item.data ? 'pointer !important' : ''}"
                     (click)="item.fullMode = !item.fullMode">
					<div class="ellipsis" style="word-break : break-word;"
						 [ngStyle]="{ 'white-space' : item.fullMode ? 'normal' : ''}"
						 (isElps)="gs.setExpandableRecord(expandableRecord, $event)">
						<span>{{item.action}}</span>
					</div>
					<i *ngIf="!item.fullMode" class="fa fa-caret-down fa-sm"></i>
				</div>
			</div>

		</ng-template>
		<div tableButtons>
			<div class="flxRow" style="align-items: center; justify-content: space-between">
				<div class="period-buttons-group bs-enabled flxRow hSpace1">
					<btn-c
						variant="{{period.value !== periodObj.value ? 'outlined' : ''}}"
						*ngFor="let periodObj of auditLogsPeriod"
						[isDisabled]="loadingLogsInProcess"
						(action)="changePeriod(periodObj);">
					{{periodObj.display}}
					</btn-c>
				</div>
			</div>
		</div>
    </trustifi-table-c>
</div>
<audit-log-schedule-report-component [(show)]="scheduleReportShow"></audit-log-schedule-report-component>
