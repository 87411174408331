<div class="flex-column f-1-0 w-100 centralize gap-3" (ngInit)="htmlRetrieved = true;">
    <span class="fw-bold" style="font-size: 1rem;">Log in with your email address</span>
    <br/>
    <div class="position-relative w-100">
        <input type="text" class="form-control" placeholder="Email address" aria-label="Email address"
               [ngClass]="{'validation-error' : initialEmailError}"
               (keydown)="initialEmailError = null;"
               (keydown.enter)="confirmInitialEmail(userEmailInput);"
               [(ngModel)]="userEmailInput">
        <span style="position: absolute; bottom: 1px; right: 0; transform: translateY(calc(100% + 3px)); font-size: 11px; color: red;"
              *ngIf="initialEmailError">
            {{initialEmailError}}
        </span>
    </div>
    <btn-c class="w-100"
         [loading]="sendInitialEmailInProcess"
         (action)="confirmInitialEmail(userEmailInput);">
        Continue
    </btn-c>
    <div class="OR-seperator-line my-4 w-100 opacity-50"></div>
    <div class="flex-column centralize gap-1 w-100">
        <btn-c class="login-sso-button w-75"
             variant="outlined"
             startIcon="fab fa-microsoft fa-microsoft-colorful"
             (action)="socialLogin('trustifi-microsoft');">
            Continue with Microsoft
        </btn-c>
        <btn-c class="login-sso-button w-75"
             variant="outlined"
             startIcon="fab fa-google fa-google-colorful"
             (action)="socialLogin('google-oauth2');">
            Continue with Google
        </btn-c>
    </div>
    <br/>
    <div class="flex-column gap-1 centralize">
        <span>Don't have an account?</span>
        <a class="blue-link no-underline"
           (click)="updateLoginStatus(loginStatuses.signupForm)">
            Create an account
        </a>
    </div>
</div>
