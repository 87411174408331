<popup-c class="no-content-padding onboarding-popup animated fadeIn" size="fullscreen" style="position: fixed; z-index: 1050;"
		 tabindex="0" [appAutoFocus]="true" (keydown.escape)="closeCb.emit();">
    <div class="flxClmn f-1-0" style="width: 100%; overflow: hidden;">
        <div class="flxClmn f-1-0" style="overflow: auto;">
            <div class="flxRow w100 hSpace3" style="align-items: center; min-height: 35px; padding: 20px; background: linear-gradient(90deg, white,white 58%, #e9e9e9ad), url('images/popup-header-bg.png'); background-size: auto; background-repeat: round;">
                <span style="font-size: 24px;">
                    Trustifi Installation
                </span>
                <div class="flxRow hSpace1" style="align-items: center;"
                     *ngIf="state === dic.CONSTANTS.onboardingSteps.state.steps && userOnboardingInfo.inbound_steps.length && userOnboardingInfo.outbound_steps.length">
                    <btn-c variant="{{currentSetupScope?.name !== dic.CONSTANTS.onboardingSetupScopes.outbound ? 'outlined' : ''}}"
                         (action)="updateSetupScope(dic.CONSTANTS.onboardingSetupScopes.outbound);">
                        Outbound setup
                    </btn-c>
                    <btn-c variant="{{currentSetupScope?.name !== dic.CONSTANTS.onboardingSetupScopes.inbound ? 'outlined' : ''}}"
                         (action)="updateSetupScope(dic.CONSTANTS.onboardingSetupScopes.inbound);">
                        Inbound setup
                    </btn-c>
                </div>
                <btn-c class="animated fadeIn" style="margin-left: 25px; font-weight: 500;"
                     *ngIf="state === dic.CONSTANTS.onboardingSteps.state.welcome"
                     variant="text"
                     (action)="updateCurrentState(dic.CONSTANTS.onboardingSteps.state.questionnaireIntro);">
                    Edit questionnaire
                </btn-c>
                <!--<span *ngIf="currentStep === dic.CONSTANTS.onboardingSteps.questionnaire"
                      class="title animated fadeIn" style="margin-bottom: 0;">&nbsp;- Questionnaire</span>-->
                <i class="zmdi zmdi-close hvr-darken cursor-pointer" style="position: absolute; top: 10px; bottom: auto; right: 10px; left: auto; font-size: 1.5rem; color: dimgrey; padding: 5px 10px; z-index: 15;"
                   (click)="closeCb.emit();"></i>
            </div>

            <hr style="margin-top: 0;"/>

            <div class="flxClmn f-1-0" style="overflow: auto; padding: 25px;">
                <div class="flxClmn onboarding-inner-holder" style="flex: 1 0; overflow: auto;"
                     [ngStyle]="state !== dic.CONSTANTS.onboardingSteps.state.questionnaireIntro && state !== dic.CONSTANTS.onboardingSteps.state.questionnaire && state !== dic.CONSTANTS.onboardingSteps.state.stepsDone
                               && {'border' : 'solid 1px #e3e4e4', 'background' : '#f9f9f9'}">

                    <!--SCREEN: welcome screen (questionnare intro)-->
                    <div class="flxClmn-evenly w100 onboarding-intro" style="z-index: 15; flex: 1 0; overflow: auto; align-items: center; position: relative;"
                         *ngIf="state === dic.CONSTANTS.onboardingSteps.state.questionnaireNew">
                        <div class="flxClmn vSpace2" style="text-align: center;">
                            <span style=" font-size: 2.2rem; font-weight: 400; font-family: 'Roboto Condensed', sans-serif;">
                                Trustifi Installation Wizard
                            </span>
                            <span style="max-width: 575px;">This wizard will guide you through the process of integrating your email environment with Trustifi for outbound and/or inbound protection.</span>
                        </div>

                        <div class="flxRow-center hSpace5 w100" style="align-items: center; position: relative; margin: 1vh 0;">
                            <div style="width: 34vw; min-width: 350px; max-width: 650px;">
                                <img src="images/onboard.png" style="opacity: 0.4; max-width: min(575px,60vh); width: 34vw; min-width: 350px;">
                            </div>
                            <div class="flxClmn-center" style="flex: 1 0; align-items: center; max-width: 490px; min-height: max(290px,46vh);">
                                <span style="font-weight: 500; text-align: center; padding: 0 1vw;">As a first step, you will need to fill out a questionnaire<br/>to provide details about your email environment.</span>
                                <div class="flxRow hSpace4" style="align-items: center; margin: 2vw 0;" >
                                    <div class="flxClmn-center" style="padding: 15px; background: #bbdbee; border-radius: 15px;">
                                        <i style="color: white; font-size: 2rem;" class="fas fa-pencil-alt"></i>
                                    </div>
                                    <span  style="flex: 1 0; ;">Please make sure to fill out every question as accurately as possible, otherwise there may be delays or complications during the integration process.</span>
                                </div>
                                <div class="flxRow hSpace4" style="align-items: center;">
                                    <div class="flxClmn-center" style="padding: 15px; background: #bbdbee; border-radius: 15px;">
                                        <i style="color: white; font-size: 2rem;" class="fas fa-headset"></i>
                                    </div>
                                    <span style="flex: 1 0; ;">Once you submit this questionnaire, our support department will upgrade your plan and reach out to you to provide information about the next steps.</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="btn-block">
                                <btn-c class="popup-action-btn"
                                     (action)="updateCurrentState(dic.CONSTANTS.onboardingSteps.state.questionnaire);">
                                    Go To Questionnaire
                                </btn-c>
                            </div>
                        </div>
                    </div>

                    <!--SCREEN: onboarding process COMPLETED screen-->
                    <div class="flxClmn-evenly w100 onboarding-intro" style="z-index: 15; flex: 1 0; overflow: auto; align-items: center; position: relative; max-width: none; background: white;"
                         *ngIf="state === dic.CONSTANTS.onboardingSteps.state.stepsDone">
                        <div class="flxClmn vSpace2" style="text-align: center;">
                            <span style=" font-size: 2.2rem; font-weight: 400; font-family: 'Roboto Condensed', sans-serif;">
                                WELCOME ABOARD!
                            </span>
                            <span>You have completed the onboarding process and successfully integrated with Trustifi email security!<br/>Our support team is available 24/7 and will assist you whenever you need us.</span>
                        </div>

                        <div class="flxRow-center hSpace5 w100" style="align-items: center; position: relative; margin: 1vh 0;">
                            <div class="flxRow-center" style="width: 34vw; min-width: 350px; max-width: 650px;">
                                <img src="images/welcomeAboardRocket.png" style="opacity: 0.7; max-width: min(575px,60vh); width: 34vw; min-width: 350px;">
                            </div>
                            <div class="flxClmn-center" style="flex: 1 0; align-items: center; max-width: 490px; min-height: max(290px,46vh);">
                                <span style="font-weight: 500; text-align: center; padding: 0 1vw;">Find plenty of useful videos about how to use our applications, in Trustifi official website</span>
                                <div class="flxRow-center hvr-scale" style="align-items: center; margin: 2vw 0 20px; transition: all 0.2s;">
                                    <a class="link-photo" href="https://www.trustifi.com/how-to-videos/" target="_blank">
                                        <img src="images/videosPage.jpg" style="box-shadow: 0 0 5px grey; border: solid 2px darkgrey; height: 165px;">
                                    </a>
                                </div>
                                <span style="font-size: 0.8rem; opacity: 0.6;">Tutorial videos (Trustifi website)</span>
                            </div>
                        </div>
                        <div class="flxRow hSpace1" style="padding: 25px 0; align-items: center; margin-top: 0;">
                            <btn-c class="popup-action-btn"
                                 variant="text"
                                 (action)="state = dic.CONSTANTS.onboardingSteps.state.welcome;">
                                Review Installation
                            </btn-c>
                            <btn-c class="popup-action-btn"
                                 (action)="closeCb.emit();">
                                OK
                            </btn-c>
                        </div>
                    </div>

                    <!--SCREEN: questionnare edit intro screen-->
                    <div class="flxClmn onboarding-steps-container vSpace5" style="z-index: 15; padding: max(2vh,15px) 15px; flex: 1 0 auto; margin: 0 auto; align-items: center;"
                         *ngIf="state === dic.CONSTANTS.onboardingSteps.state.questionnaireIntro">

                        <div class="flxClmn vSpace2" style="text-align: center;">
                            <span style=" font-size: 2.2rem; font-weight: 400; font-family: 'Roboto Condensed', sans-serif;">
                                Trustifi Installation Wizard
                            </span>
                        </div>

                        <!--instructions-->
                        <div class="flxClmn vSpace4" style="flex: 1 0; max-width: 655px;">
                            <span style="font-weight: 500; text-align: center; padding: 0 1vw;">
                                Our support team has reviewed your questionnaire and configured your account according to the details you provided.<br/><br/> You can edit the questionnaire and send it to our support team for reconfiguration
                            </span>
                            <div style="line-height: 1.4; text-align: center; padding: 0 1vw; flex: 1 0; font-size: 0.9rem; max-width: 560px; margin: auto;" class="flxClmn-center">
                                <span>
                                    Once you complete the edit and send the questionnaire back, Installation Wizard will be paused and not reachable.<br/>You could resume the Installation Wizard right after our support team review your questionnaire again, and reconfigure your application's environment settings.
                                </span>
                            </div>
                        </div>

                        <div class="flxRow hSpace1" style="padding: 25px 0; align-items: center; margin-top: 0;">
                            <btn-c class="popup-action-btn"
                                 variant="outlined"
                                 (action)="state = dic.CONSTANTS.onboardingSteps.state.welcome;">
                                Cancel
                            </btn-c>
                            <btn-c class="popup-action-btn"
                                 (action)="updateCurrentState(this.dic.CONSTANTS.onboardingSteps.state.questionnaire);">
                                Edit questionnaire
                            </btn-c>
                        </div>
                    </div>

                    <!--SCREEN: questionnaire screen-->
					<onboarding-questionnaire-component style="display: contents;"
											  *ngIf="state === dic.CONSTANTS.onboardingSteps.state.questionnaire"
											  [userOnboardingInfo]="userOnboardingInfo"
											  (doneCb)="$event?.onboardingInfo ? this.questionnaireDone($event.onboardingInfo) : closeCb.emit()"
											  (cancelCb)="userOnboardingInfo.questionnaire_step === dic.CONSTANTS.onboardingProcessQuestionnaireStep.completed ?
                         								  updateCurrentState(dic.CONSTANTS.onboardingSteps.state.welcome) : closeCb.emit();">
					</onboarding-questionnaire-component>


                    <!--SCREEN: setup steps intro screen-->
                    <div class="flxClmn onboarding-steps-container vSpace5 animated fadeIn" style=" z-index: 15; max-width: 1200px; padding: max(2vh,15px) 15px; flex: 1 0 auto; margin: 0 auto; align-items: center;"
                         *ngIf="state === dic.CONSTANTS.onboardingSteps.state.welcome">
                        <div class="flxClmn vSpace2" style="text-align: center;">
                            <span style=" font-size: 2.2rem; font-weight: 400; font-family: 'Roboto Condensed', sans-serif;">
                                Trustifi Installation Wizard
                            </span>
                            <span style="max-width: 575px;">
                                This wizard will guide you through the process of integrating your email environment with Trustifi for
                                {{userOnboardingInfo.inbound_steps && userOnboardingInfo.inbound_steps.length && userOnboardingInfo.outbound_steps &&  userOnboardingInfo.outbound_steps.length ? 'outbound and inbound'
                                    : userOnboardingInfo.inbound_steps && userOnboardingInfo.inbound_steps.length ? 'inbound' : 'outbound'}} protection
                            </span>
                        </div>
                        <div class="flxRow-center hSpace5 animated fadeIn" style=" flex: 1 0; max-height: 400px;" >
                            <div class="flxClmn-center vSpace5" style="align-items: center;" *ngIf="userOnboardingInfo.outbound_steps && userOnboardingInfo.outbound_steps.length">
                                <div class="flxClmn-center onboarding-setup-icon-design" style="align-items: center; cursor: pointer; width: max(100px,15vh); height: max(100px,15vh); border-radius: 50%; background: linear-gradient( 45deg , #d2f7ff, #d2f7ff00); font-size: 10vh; box-shadow: 0 0 10px #a7a7a7;"
                                     (click)="updateSetupScope(dic.CONSTANTS.onboardingSetupScopes.outbound)">
                                    <i style="color: white; filter: drop-shadow(0 2px 3px #8bbcd8) drop-shadow(2px 3px 3px #8bbcd8);" class="zmdi zmdi-shield-security"></i>
                                </div>
                                <span style="font-size: 1.5rem; font-weight: bold; margin-top: 0;">Outbound setup</span>
                                <span>Step-by-step integration guide for Trustifi outbound protection</span>
                                <btn-c class="popup-action-btn"
                                     (action)="updateSetupScope(dic.CONSTANTS.onboardingSetupScopes.outbound);">
                                    Start Outbound Setup
                                </btn-c>
                            </div>
                            <div class="flxClmn-center vSpace5" style="align-items: center;" *ngIf="userOnboardingInfo.inbound_steps && userOnboardingInfo.inbound_steps.length">
                                <div class="flxClmn-center onboarding-setup-icon-design" style="align-items: center; cursor: pointer; width: max(100px,15vh); height: max(100px,15vh); border-radius: 50%; background: linear-gradient( 45deg , #d2f7ff, #d2f7ff00); font-size: 7.8vh; box-shadow: 0 0 10px #a7a7a7;"
                                     (click)="updateSetupScope(dic.CONSTANTS.onboardingSetupScopes.inbound)">
                                    <i style="color: white; filter: drop-shadow(0 2px 3px #8bbcd8) drop-shadow(2px 3px 3px #8bbcd8);" class="fas fa-user-shield"></i>
                                </div>
                                <span style="font-size: 1.5rem; font-weight: bold; margin-top: 0;">Inbound setup</span>
                                <span>Step-by-step integration guide for Trustifi inbound protection</span>
                                <btn-c class="popup-action-btn"
                                     (action)="updateSetupScope(dic.CONSTANTS.onboardingSetupScopes.inbound);">
                                    Start Inbound Setup
                                </btn-c>
                            </div>
                        </div>
                    </div>




                    <!--SCREEN: setup steps screen (inbound / outbound)-->
					<onboarding-wizard-steps style="display: contents;"
											 *ngIf="state === dic.CONSTANTS.onboardingSteps.state.steps"
											 [userOnboardingInfo]="userOnboardingInfo"
											 [(currentSetupScope)]="currentSetupScope"
											 [navigateToStep]="navigateToStep"
											 (doneCb)="updateCurrentState(this.dic.CONSTANTS.onboardingSteps.state.stepsDone);">
					</onboarding-wizard-steps>


                </div>
                <!--<div class="flxRow hSpace1" style="align-items: center; padding: 10px 0 0; color: darkorange; font-size: 0.85rem; font-weight: 400;">
                    <i style="font-size: 1.05rem" class="zmdi zmdi-alert-circle-o" *ngIf="currentStep?.message && state === dic.CONSTANTS.onboardingSteps.state.steps"></i>
                    <span *ngIf="currentStep?.message">
                        {{currentStep.message}}
                    </span>
                    &lt;!&ndash; <span *ngIf="state === dic.CONSTANTS.onboardingSteps.state.stepsDone && undoneErrorMessage">
                            One or more installation steps are marked as "done" but our system found that they might be not fully completed. For more information
                            <span class="a-link" (click)="updateCurrentState(dic.CONSTANTS.onboardingSteps.state.welcome)">review installation again</span>
                          </span>&ndash;&gt;
                </div>-->
            </div>
        </div>
    </div>
</popup-c>
