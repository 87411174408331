import {GeneralService} from "../../../../../services/generalService";
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import _ from "lodash";
import {DICTIONARY} from "../../../../../dictionary";
import util from "util";
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'outbound-policies-component',
	templateUrl: './outbound-policies.component.html',
})
export class OutboundPoliciesComponent implements OnInit {
    constructor(public gs: GeneralService,
				private rs: RouteService,
				private ns: NotificationService) {
    }

    dic = DICTIONARY;
    userPolicy;
    allowedAuthMethods;
	blockFileTypes;
    methodInRule;
    getPoliciesDataInProcess;
	notifyOptionsPopup: any;

	releaseRequestSubjectDesc = 'Dynamic fields like {{SENDER_EMAIL}}, {{EMAIL_SUBJECT}} and {{COMPANY_NAME}} can be used to show information about the email.\n' +
		'If this field is left empty, the default Trustifi subject will be used.';
	releaseRequestSubjectPlaceholder = '({{COMPANY_NAME}}) {{SENDER_EMAIL}} release request for email "{{EMAIL_SUBJECT}}"';
	retentionType;

	ngOnInit() {
        this.getPoliciesDataInProcess = true;

        this.rs.getRulesAndPolicy().then((response) => {
            this.userPolicy = response.plan.policy;
			this.userPolicy.malicious_outbound = {enabled: true};

			this.userPolicy.retentionValue = this.userPolicy.retention.value;
            this.userPolicy.retentionType = this.userPolicy.retention.type;

            this.allowedAuthMethods = _.map(this.dic.CONSTANTS.secureMethods, method => {
                return {
                    name: method,
                    selected: this.userPolicy.allowed_auth_methods.includes(method)
                }
            });

			this.blockFileTypes = _.map(['Macros', 'Scripts', 'Executables'], method => {
				return {
					name: method,
					selected: this.userPolicy.block_emails_by_attachment_types?.includes(method)
				}
			});

            this.checkAuthMethodsInRules(response.plan.rules);
            this.getPoliciesDataInProcess = false;
        }, (err) => {
			this.getPoliciesDataInProcess = false;
		});
    }

    checkAuthMethodsInRules(rules) {
        this.methodInRule = {
            email: '',
            password: '',
            phone: '',
            SSO: ''
        };

        let method;
        for (let i = 0; i < rules.length; i++) {
            if (rules[i].result && rules[i].result.secure_send) {
                method = rules[i].result.default_authentication_method;
                if (method && method in this.methodInRule && !this.methodInRule[method]) {
                    this.methodInRule[method] = rules[i].name;
                }
            }
        }
    };

    updatePolicy(policyKey, policyValue) {
		switch (policyKey) {
			case 'expired_days':
			case 'delete_attachment_days':
				if (policyValue.value < 1 || policyValue.value > 365) {
					this.ns.showErrorMessage(this.dic.ERRORS.expiredInputInvalid);
					return;
				}
				break;
		}

        this.rs.updatePolicy({action: this.dic.CONSTANTS.updatePolicyActions.policy, data: {key: policyKey, val: policyValue}}).then(() => {});
    };

	handlePolicyEmailRecallMaxTimeChange() {
		const actionInfo = {
			action: this.dic.CONSTANTS.updatePolicyActions.emailRecallMaxTime,
			data: {max_hours: this.userPolicy.allow_recall_email.max_hours}
		};
		this.rs.updatePolicy(actionInfo).then(() => {});
	};


	updateRetention() {
        if (!this.userPolicy.retentionValue) return;
        if (this.userPolicy.retention.enabled) {
            if (this.userPolicy.retentionValue < 1) {
                return this.ns.showWarnMessage(util.format(this.dic.ERRORS.retentionValuesInvalid, this.userPolicy.retentionType, this.dic.CONSTANTS.retentionMaxDays[this.userPolicy.retentionType]));
            }

            let isErr = false;

            switch (this.userPolicy.retentionType) {
                case this.dic.CONSTANTS.timePeriod.day:
                    if (this.userPolicy.retentionValue > this.dic.CONSTANTS.retentionMaxDays.day) {
                        isErr = true;
                    }
                    break;

                case this.dic.CONSTANTS.timePeriod.month:
                    if (this.userPolicy.retentionValue > this.dic.CONSTANTS.retentionMaxDays.month) {
                        isErr = true;
                    }
                    break;

                case this.dic.CONSTANTS.timePeriod.year:
                    if (this.userPolicy.retentionValue > this.dic.CONSTANTS.retentionMaxDays.year) {
                        isErr = true;
                    }
                    break;
            }
            if (isErr) {
                return this.ns.showWarnMessage(util.format(this.dic.ERRORS.retentionValuesInvalid, this.userPolicy.retentionType, this.dic.CONSTANTS.retentionMaxDays[this.userPolicy.retentionType]));
            }
            this.userPolicy.retention.value = this.userPolicy.retentionValue;
            this.userPolicy.retention.type = this.userPolicy.retentionType;
        }

        this.rs.updatePolicy({action: this.dic.CONSTANTS.updatePolicyActions.retention, data: this.userPolicy.retention}).then(() => {});
    };

    updateAuthMethods(method) {
        if (this.methodInRule[method.name] && !method.selected) {
            method.selected = !method.selected;
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.policyDisableMethodInRule, method.name, this.methodInRule[method.name]));
        }
        this.userPolicy.allowed_auth_methods = _.map(_.filter(this.allowedAuthMethods, 'selected'), 'name');
        if (!this.userPolicy.allowed_auth_methods.length) {
            method.selected = !method.selected;
            return this.ns.showErrorMessage(this.dic.ERRORS.atLeastOneAuthMethod);
        }

        this.rs.updatePolicy({action: this.dic.CONSTANTS.updatePolicyActions.authenticationMethods, data: {allowed_auth_methods: this.userPolicy.allowed_auth_methods}}).then(() => {});
    };

	updateAttachmentEncryptPolicy() {
		if (this.userPolicy.attachment_encryption.threshold > 5 || this.userPolicy.attachment_encryption.threshold < 1) {
			this.ns.showErrorMessage(DICTIONARY.ERRORS.attachmentsScoreValues);
			return;
		}

		const actionInfo = {
			action: this.dic.CONSTANTS.updatePolicyActions.attachmentEncryption,
			data: {
				type: this.userPolicy.attachment_encryption.value,
				threshold: this.userPolicy.attachment_encryption.threshold
			}
		};

		this.rs.updatePolicy(actionInfo).then(() => {});
	};

	updateBlockFileTypes() {
		this.userPolicy.block_emails_by_attachment_types = _.map(_.filter(this.blockFileTypes, 'selected'), 'name');
		this.rs.updatePolicy({action: this.dic.CONSTANTS.updatePolicyActions.blockFileTypes, data: {block_emails_by_attachment_types: this.userPolicy.block_emails_by_attachment_types}}).then(() => {});
	};

    updateInappropriateContentCategory() {
        const updateData = {
            action: this.dic.CONSTANTS.updatePolicyActions.inappropriateContentCategory,
            data: {
                block_inappropriate_content: this.userPolicy.block_inappropriate_content
            }
        };
        this.rs.updatePolicy(updateData).then(() => {});
    };

	openNotifyOptionsPopup() {
		this.notifyOptionsPopup = {
			show: true,
			emails: this.userPolicy.notify_sender_outbound_quarantined.notify_email_for_release_request,
			notifyAllowRequestSubject: this.userPolicy.notify_sender_outbound_quarantined.notify_release_request_subject
		};
	};

    updatePolicyOutboundQuarantinedSenderNotify() {
		if (this.notifyOptionsPopup) {
			if (this.notifyOptionsPopup.emails && !_.every(this.notifyOptionsPopup.emails.split(','), email => this.gs.validateEmail(email.trim())) ) {
				this.ns.showWarnMessage(this.dic.ERRORS.oneOfEmailsInvalid);
				return;
			}
			this.userPolicy.notify_sender_outbound_quarantined.notify_email_for_release_request = this.notifyOptionsPopup.emails ? this.notifyOptionsPopup.emails.replace(/ /g, '') : '';
			this.userPolicy.notify_sender_outbound_quarantined.notify_release_request_subject = this.notifyOptionsPopup.notifyAllowRequestSubject;
		}
        const updateData = {
            action: this.dic.CONSTANTS.updatePolicyActions.outboundQuarantinedSenderNotify,
            data: {
				notify_sender_outbound_quarantined: this.userPolicy.notify_sender_outbound_quarantined
            }
        };
        this.rs.updatePolicy(updateData).then(() => {
			this.notifyOptionsPopup = null
		});
    };

	handleKeydown(event: KeyboardEvent): boolean {
		const blockedKeyCodes = [69, 109, 189, 187, 190];
		if (blockedKeyCodes.includes(event.keyCode)) {
			event.preventDefault();
			return false;
		}
		return true;
	}
}
