import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {GeneralService} from "src/app/services/generalService";
import _ from "lodash";
import {AuthService} from "../../services/authService";
import {DICTIONARY} from "../../dictionary";
import {RouteService} from "../../services/routeService";


@Component({
	selector: 'email-and-sso-component',
	templateUrl: './email-and-sso.component.html',
})

export class EmailAndSsoComponent implements OnInit  {
	@Input() userEmailInput;
	@Input() plan;
	@Input() loginStatuses;
	@Input() error;
	@Output() loginStatusChange: EventEmitter<any> = new EventEmitter();

	constructor(private rs:RouteService,
				public gs:GeneralService,
				private authService:AuthService) {
	}

	ngOnInit(): void {
        this.initialEmailError = this.error;
    }

	dic = DICTIONARY;
	_=_;
	htmlRetrieved = false;
	sendInitialEmailInProcess;
	loading;
	loginStatus;
	successMessage;
	initialEmailError;

	updateLoginStatus = (newStatus) => {
		this.error = false;
		this.successMessage = false;
		this.loginStatus = newStatus;
		this.loginStatusChange.emit({ loginStatus: this.loginStatus,
			userEmailInput: this.userEmailInput,
			successMessage: this.successMessage,
			error: this.error});
	}

	//Available providers: trustifi-microsoft, trustifi-yahoo, google-oauth2
	//Alternative provider: trustificorp-azuread
	socialLogin = (provider) => {
		this.loading = true;
		this.authService.socialLogin(provider, this.plan, window.history.state, err => {
			this.loading = false;
		});
	};

	confirmInitialEmail = (email) => {
		if (!email || !this.gs.validateEmail(email.trim())) {
			this.initialEmailError = 'Invalid email address';
			return;
		}

		this.sendInitialEmailInProcess = true;

		this.rs.userLogin(email.trim()).then(response => {
			if (response && response.isIdp) {
				// okta or saml
				this.initialEmailError = false;
				this.authService.IDPLogin(email, true);
			}
			else {
				this.updateLoginStatus(this.loginStatuses.passwordLoginForm);
			}
			this.sendInitialEmailInProcess = false;
		}, (err) => {
			this.sendInitialEmailInProcess = false;
		});
	}
}
