import _ from 'lodash';
import {DICTIONARY} from '../../../dictionary';
import {GeneralService} from '../../../services/generalService';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'account-compromised-audit-log-component',
	templateUrl: './account-compromised-audit-log.component.html',
})
export class AccountCompromisedAuditLogComponent implements OnInit {
    dic = DICTIONARY;
    loadLogsInProcess = true;
    logs;
    filterData;

	auditLogsPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.all
	];
	period = this.dic.CONSTANTS.trendsPeriod.last3Days;
	scheduleReportShow = false;

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
    }

	ngOnInit() {
        this.initFilters();
        this.getAuditLogs();
    }

	changePeriod = (inputPeriod) => {
		this.period = inputPeriod;
		this.getAuditLogs();
	};

	showScheduleReport = () => {
		this.scheduleReportShow = true;
	}

    getAuditLogs = () => {
        this.loadLogsInProcess = true;
		const params = {period: this.period.value};
		this.rs.getAccountCompromisedAuditLogs(params).then((response) => {
            this.logs = response;
            this.loadLogsInProcess = false;
        }, (err) => {
            this.loadLogsInProcess = false;
        });
    }

    initFilters = () => {
        this.filterData = {
            filterType: this.dic.CONSTANTS.tableFilters.auditLogAccountCompromised,
            filters: {
                category: ['Configuration', 'Account Management', 'Suspicious Activity']
            },
            initFiltersFunction: this.initFilters,
        };
	}

    searchLog = (event) => {
        this.logs.forEach(record => {
            // search
            if (event.searchTerm) {
                const isFound = this.searchTextExecute(record, event.searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            if (event.activeFilters) {
                // need to match all filter types
                let numFilterToMatch = Object.keys(event.activeFilters).length;

                if (event.activeFilters['category'] && event.activeFilters['category'].length) {
                    if (event.activeFilters['category'].includes(record.category)) {
                        numFilterToMatch--;
                    }
                }

                if (numFilterToMatch) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    }

    exportToCsv = (sortBy) => {
        if (!this.logs || !this.logs.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Date,Category,Admin,Action\n";

        let sortedTable = _.filter(this.logs,user => {return !user.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        sortedTable.forEach((log) => {
            csvString += `${log.created},"${log.category}",${log.admin},"${log.action}"\n`;
        });

        this.gs.exportCsv(csvString, `audit_logs.csv`);
    }

    searchTextExecute(log, searchTerm) {
        searchTerm = searchTerm.toLowerCase();
        return (log.action && log.action.toLowerCase().indexOf(searchTerm) > -1) ||
            (log.admin && log.admin.toLowerCase().indexOf(searchTerm) > -1) ||
            (log.category && log.category.toLowerCase().indexOf(searchTerm) > -1);
    }
}


