<div class="flex-column gap-4">
	<span>Integrate your inbound mail flow with Trustifi to protect your mailboxes against phishing, spam, spoofing, graymail, and more.</span>
	<div class="d-flex w-100 centralize" *ngIf="!planInfo">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>
	<ng-container *ngIf="planInfo">
		<div class="list-group list-group-flush settings-list-container">
			<!--Settings: Inbound relay architecture-->
			<div class="list-group-item settings-list-item column-style gap-3">
				<div class="title-section-container flex-column gap-2">
					<div class="flex-row gap-3 align-items-center">
						<b>Email Flow Architecture</b>
						<div class="content-container flex-row gap-1 align-items-center" [ngClass]="planInfo?.inbound_relay?.is_monitoring ? 'text-warning' : planInfo?.inbound_relay?.is_connected ? 'text-success' : 'text-danger'">
							<i class="fas fa-circle" style="font-size: 0.7em;"></i>
							<span>{{planInfo?.inbound_relay?.is_monitoring ? 'Monitor Mode' : planInfo?.inbound_relay?.is_connected ? 'Active' : 'Inactive'}}</span>
							<i style="font-weight: 400; color: initial;" class="fas fa-question-circle" *ngIf="!planInfo?.inbound_relay?.is_connected"
							   tooltip='<b>NOTE:</b> The status will change on the first email that will be routed through our servers.'>
							</i>
						</div>
					</div>
					<span class="lh-base" style="font-size: 0.9em;">Configure how Trustifi should be integrated into your <b>inbound</b> mail flow.</span>
				</div>

				<div class="content-container flex-column gap-4">
					<div class="flex-row mb-3 gap-4">
						<div class="flex-row justify-content-around gap-4 f-1-0 px-4 py-3 w-100">
							<ng-container *ngFor="let architecture of sendingArchitecturesData | keyvalue: gs.returnZero">
								<ng-container *ngTemplateOutlet="integrationArchitectureCard; context: {$implicit: architecture}"></ng-container>
							</ng-container>

							<ng-template #integrationArchitectureCard let-architecture>
								<div class="flex-column align-items-center position-relative" style="width: 33%; transition: all 0.15s;">
									<div class="flex-column f-1-0 gap-3 border border-2 rounded p-4 hvr-darken-light cursor-pointer position-relative text-center w-100"
										 (click)="selectArchitecture(architecture.key)"
										 [ngClass]="{'border-primary shadow' : (architecture.key === selectedArchitecture || architecture.key === planInfo?.inbound_relay?.sending_architecture),
										 			'border-secondary-subtle' : (architecture.key !== selectedArchitecture && architecture.key !== planInfo?.inbound_relay?.sending_architecture)}">
										<h5 class="fw-bold flex-row gap-2 centralize" style="transition: all 0.15s;" [ngClass]="{'text-primary' : (architecture.key === selectedArchitecture || architecture.key === planInfo?.inbound_relay?.sending_architecture)}">
											<i class="text-primary opacity-50" [ngClass]="architecture.value.icon"></i>
											<span>Via {{architecture.value.name}}</span>
										</h5>
										<div class="d-flex centralize f-1-0">
											<span style="transition: all 0.15s;" [ngClass]="{'text-muted' : (architecture.key !== selectedArchitecture && architecture.key !== planInfo?.inbound_relay?.sending_architecture)}">
												{{architecture.value.description}}
											</span>
										</div>
										<i class="fa fa-check-circle text-primary" style="position: absolute; top: 8px; left: 8px; font-size: 1rem;" *ngIf="architecture.key === planInfo?.inbound_relay?.sending_architecture"></i>
									</div>
									<div class="w-100 position-absolute" style="height: 32px; bottom: -16px;">
										<btn-c *ngIf="architecture.key === selectedArchitecture.key && architecture.key !== planInfo?.inbound_relay?.sending_architecture" class="animated fadeInDown w-100"
											   (click)="updateSendingArchitecture(architecture.key)">
											Confirm
										</btn-c>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>

			<!--Domain and MTA -->
			<div class="list-group-item settings-list-item" *ngIf="selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.connector || selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.mx">
				<div class="title-section-container flex-column gap-2">
					<b>Domain and MTA</b>
					<span class="lh-base" style="font-size: 0.9em;">Add the domain, MTA and port which will be used to send your secure emails.<br/>
									You can also add additional MTAs to each domain to be used as a fallback.<br/>Use drag&drop to change the priority of the fallback MTAs.</span>
					<span class="lh-base" style="font-size: 0.9em;"><span style="color: orange;">NOTE:</span> Ensure that the MTA is the final destination for email delivery, rather than a SEG, unless it has been correctly configured for routing.</span>
				</div>
				<div class="content-container flex-column gap-2">
					<div *ngIf="planInfo?.inbound_relay" class="flex-row hSpace05">
						<div style="position: relative; display: inline; flex: 1 0; max-width: 40%;">
							<input type="text" style="margin-bottom:0; padding-right: 75px; width: 100%;"
								   placeholder="Domain"
								   [(ngModel)]="planInfo.inbound_relay.domain"
								   [ngClass]="inboundRelayDomain && 'validation-error'"
								   (ngModelChange)="inboundRelayDomain = false;">
							<div class="redborder fa"
								 style="width: 80px; position: absolute; right: 5px; top: 5px; font-style: italic; margin: 0; border: none; cursor: pointer; font-weight: bold;"
								 [ngClass]="{'disabled': !planInfo.inbound_relay.domain || updateEmailRelayConfigInProcess}"
								 (click)="updateEmailRelayConfig(dic.CONSTANTS.inboundRelayActions.domainMta)">
								Resolve host
							</div>
						</div>
						<input type="text" style="width: 40%;"
							   placeholder="MTA"
							   [(ngModel)]="planInfo.inbound_relay.host">
						<input type="number" style="width: 45px; margin:0 2px;"
							   placeholder="Port" max="65535" min="1"
							   [(ngModel)]="planInfo.inbound_relay.newPort">
						<btn-c style="min-width: 0;"
							   endIcon="zmdi zmdi-check"
							   [isDisabled]="!planInfo.inbound_relay.host || !planInfo.inbound_relay.newPort || !planInfo.inbound_relay.domain || updateEmailRelayConfigInProcess"
							   (action)="updateEmailRelayConfig(dic.CONSTANTS.inboundRelayActions.mta, 'add');">
						</btn-c>
					</div>
					<div cdkDropList class="w100"
						 (cdkDropListDropped)="onDropMta($event)">
						<div *ngFor="let mta of planInfo?.inbound_relay?.mtas; let $index = index;"
							 cdkDrag
							 [cdkDragDisabled]="mta.editMode"
							 (cdkDragStarted)="onDragStartMta(mta)"
							 class="w100">
							<div class="flxRow w100" style="align-items: center; width: calc(233px + 40% + 45px + 33px + 40px); position: relative; max-width: calc(100% - 3px);" *ngIf="!mta.editMode">
								<i class="fa animated flash" style="position: absolute; left: -5px; transform: translateX(-100%);"
								   *ngIf="mta.verified !== undefined"
								   [ngClass]="{'fa-check-circle' : mta.verified, 'fa-exclamation-triangle' : !mta.verified}"
								   [ngStyle]="{'color' : mta.verified ? 'darkgreen' : 'darkorange'}"
								   tooltip="{{mta.verified ? 'MTA connection is verified' : 'MTA connection verification failed'}}">
								</i>
								<i class="fa animated flash" style="position: absolute; left: -5px; transform: translateX(-100%);"
								   *ngIf="mta.sts_unrestricted !== undefined"
								   [ngClass]="{'fa-check-circle' : mta.sts_unrestricted, 'fa-exclamation-triangle' : !mta.sts_unrestricted}"
								   [ngStyle]="{'color' : mta.sts_unrestricted ? 'darkgreen' : 'darkred'}"
								   tooltip="{{mta.sts_unrestricted ? 'No MTA-STS restriction' : 'MTA-STS restricted'}}">
								</i>
								<div class="name ellipsis" style="flex: 1 0;" [ngStyle]="{'color' : (mta.sts_unrestricted === true || mta.verified === true) ? 'darkgreen' : ''}">
									{{mta.domain}}, {{mta.host}}: {{mta.port}}
								</div>
								<app-dropdown-c style="width: 156px; height: 28px; margin: 1px;" text="Actions">
									<li (click)="setMTAForEdit(mta)"
										[ngClass]="{'disabled' : updateEmailRelayConfigInProcess}"
										id="editMtaConnection">
										Edit
									</li>
									<li (click)="updateEmailRelayConfig(dic.CONSTANTS.erSettingsActions.mta, 'delete', $index)"
										[ngClass]="{'disabled' : updateEmailRelayConfigInProcess}"
										id="deleteMtaConnection">
										Delete
									</li>
									<li (click)="verifyMta(mta)"
										[ngClass]="{'disabled' : updateEmailRelayConfigInProcess}"
										id="checkMtaConnection">
										Check MTA Connection
									</li>
									<li (click)="mtaStsLookup(mta)"
										*ngIf="planInfo?.inbound_relay?.sending_architecture === dic.CONSTANTS.inboundSendingArchitecture.mx"
										[ngClass]="{'disabled' : updateEmailRelayConfigInProcess}"
										id="checkMtaSts">
										Check MTA-STS Restriction
									</li>
								</app-dropdown-c>
							</div>
							<div *ngIf="mta.editMode" class="flxRow hSpace05">
								<div style="position: relative; display: inline; margin-right: 2px; flex: 1 0; max-width: 40%;">
									<input type="text" style="width: 100%; margin-bottom: 0;"
										   placeholder="Domain"
										   [(ngModel)]="mtaInEdit.domain"
										   [ngClass]="inboundRelayDomain && 'validation-error'"
										   (ngModelChange)="inboundRelayDomain = false;">
								</div>
								<input type="text" style="width: 40%; margin-bottom: 0;"
									   placeholder="MTA"
									   [(ngModel)]="mtaInEdit.host">
								<input type="number" style="width: 45px; margin: 0 2px;"
									   placeholder="Port" max="65535" min="1"
									   [(ngModel)]="mtaInEdit.port">
								<btn-c style="min-width: 0;"
									   endIcon="zmdi zmdi-check"
									   [isDisabled]="!mtaInEdit.host || !mtaInEdit.port || !mtaInEdit.domain || updateEmailRelayConfigInProcess"
									   (action)="updateEmailRelayConfig(dic.CONSTANTS.erSettingsActions.mta, 'edit');">
								</btn-c>
								<btn-c style="min-width: 0;"
									   endIcon="zmdi zmdi-close" color="danger"
									   variant="text"
									   btnTooltip="Cancel edit"
									   (action)="mtaInEdit.editMode = false; mtaInEdit = null;">
								</btn-c>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--Enable Relay & key (When Connector/Rule) -->
			<div class="list-group-item settings-list-item animated fadeIn" *ngIf="selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.connector">
				<div class="title-section-container flex-column gap-2">
					<b>Enable</b>
					<span class="lh-base" style="font-size: 0.9em;">Enable the Inbound Shield integration with your mail server and generate your secret key.</span>
				</div>

				<div class="content-container flex-column gap-4">
					<toggle-switch-c id="enableEmailRelayToggle"
									 [(model)]="planInfo.inbound_relay.enabled"
									 textTrue="Email Relay is enabled" textFalse="Email Relay is disabled"
									 (onChange)="activateInboundEmailRelay()"></toggle-switch-c>

					<div class="flex-row gap-2 align-items-end p-2 border border-1 border-info">
						<i class="fa fa-info-circle text-info"></i>
						<span>
							<b>Exchange Server Powershell</b> wizards have been moved to the
							<a class="fw-bold blue-link"
							   [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.plan, dic.CONSTANTS.inboundPlanSettingsPageTabs.integrations]"
							   [state]="{integrationType: dic.CONSTANTS.integrationType.powershell}"
							   tooltip="Exchange Server Powershell">
								Integrations tab
							</a>
						</span>
					</div>

					<!--Email Relay header & key-->
					<div class="p-3 flex-column gap-4 rounded border" *ngIf="planInfo.inbound_relay.enabled">
						<div class="flex-row justify-content-between">
							<span class="fw-bold" style="font-size: 0.9rem;">Email Relay Credentials</span>

							<a class="blue-link copy-link hSpace05" style="font-size: 11px;"
							   (click)="activateInboundEmailRelay(true);"
							   tooltip="Generate a new key for the Email Relay">
								<i style="cursor: pointer;" [ngClass]="regenerateInProcessER ? 'fa fa-spinner fa-spin' : 'fas fa-sync-alt'" ></i>
								<span>Regenerate Key</span>
							</a>
						</div>
						<div class="flex-column gap-2">
							<div class="flex-row justify-content-between align-items-center">
								<div class="flex-row gap-3 align-items-center f-1-0">
									<span class="fw-bold" style="flex-basis: 55px; font-size: 0.9em;">Header:</span>
									<span>{{emailRelayHeader}}</span>
								</div>

								<a style="font-size: 1rem;" (click)="copyEmailRelayHeaderToClipboard();"
								   tooltip="Copy Email Relay Header">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>
							<div class="flex-row align-items-center gap-3" *ngIf="planInfo.inbound_relay.keyAndSecret">
								<span class="fw-bold" style="flex-basis: 55px; font-size: 0.9em;">Key:</span>

								<input class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
									   type="{{!showEmailRelayKey ? 'password':'text'}}"
									   [ngStyle]="{'letter-spacing' : showEmailRelayKey ? 'normal' : '2.4px'}"
									   [ngClass]="{'nofill': !showEmailRelayKey}"
									   [(ngModel)]="planInfo.inbound_relay.keyAndSecret" disabled>

								<label class="hidepwd hvr-opacity" style="opacity: 0.4; margin-bottom: 0; font-size: 1.2rem; cursor: pointer;"
									   tooltip="{{showEmailRelayKey ? 'Hide key' : 'Show key'}}">
									<input type="checkbox" [(ngModel)]="showEmailRelayKey"/>
									<i class="fa fa-eye unchecked" ></i>
									<i class="fa fa-eye-slash checked"></i>
								</label>

								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayKeyToClipboard();"
								   tooltip="Copy Email Relay Key">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>
						</div>

						<div class="line-container">
							<hr class="custom-line">
							<div class="line-text">OR</div>
						</div>

						<div class="flex-row justify-content-between">
							<span class="fw-bold" style="font-size: 0.9rem;">SMTP Credentials Authentication</span>
						</div>
						<div class="flex-column gap-1">
							<div class="flex-row align-items-center gap-3" style="height: 30px;">
								<span class="fw-bold" style="flex-basis: 60px; font-size: 0.9em;">Host:</span>
								<span class="f-1-0">inbound-smtp.trustifi.com</span>
								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayHostToClipboard();"
								   tooltip="Copy Email Relay Host">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>

							<div class="flex-row align-items-center gap-3" style="height: 30px;">
								<span class="fw-bold" style="flex-basis: 60px; font-size: 0.9em;">Port:</span>
								<span class="f-1-0">25<sup style="margin-left: 5px; color: green;">(StartTLS)</sup></span>
								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayPortToClipboard();"
								   tooltip="Copy Email Relay Port">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>

							<div class="flex-row align-items-center gap-3">
								<span class="fw-bold" style="flex-basis: 60px; font-size: 0.9em;">Username:</span>

								<input class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
									   type="{{!showEmailRelayUsername ? 'password' : 'text'}}"
									   [ngStyle]="{'letter-spacing' : showEmailRelayUsername ? 'normal' : '2.4px'}"
									   [ngClass]="{'nofill': !showEmailRelayUsername}"
									   [(ngModel)]="planInfo.inbound_relay.key" disabled>

								<label class="hidepwd hvr-opacity" style="opacity: 0.4; margin-bottom: 0; font-size: 1.2rem; cursor: pointer;"
									   tooltip="{{showEmailRelayUsername ? 'Hide username' : 'Show username'}}">
									<input type="checkbox" [(ngModel)]="showEmailRelayUsername"/>
									<i class="fa fa-eye unchecked" ></i>
									<i class="fa fa-eye-slash checked"></i>
								</label>

								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayUsernameToClipboard();"
								   tooltip="Copy Email Relay Username">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>

							<div class="flex-row align-items-center gap-3">
								<span class="fw-bold" style="flex-basis: 60px; font-size: 0.9em;">Password:</span>

								<input class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
									   type="{{!showEmailRelayPassword ? 'password' : 'text'}}"
									   [ngStyle]="{'letter-spacing' : showEmailRelayPassword ? 'normal' : '2.4px'}"
									   [ngClass]="{'nofill': !showEmailRelayPassword}"
									   [(ngModel)]="planInfo.inbound_relay.secret" disabled>

								<label class="hidepwd hvr-opacity" style="opacity: 0.4; margin-bottom: 0; font-size: 1.2rem; cursor: pointer;"
									   tooltip="{{showEmailRelayPassword ? 'Hide password' : 'Show password'}}">
									<input type="checkbox" [(ngModel)]="showEmailRelayPassword"/>
									<i class="fa fa-eye unchecked" ></i>
									<i class="fa fa-eye-slash checked"></i>
								</label>

								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayPasswordToClipboard();"
								   tooltip="Copy Email Relay Password">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="list-group-item settings-list-item py-5" *ngIf="selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.journal">
				<div class="title-section-container flex-column gap-2">
					<b>Journal Rule Settings</b>
					<span class="lh-base" style="font-size: 0.9em;">
						Configure the Journal Rule settings in <a class="blue-link" href="https://compliance.microsoft.com/exchangeinformationgovernance?viewid=exoJournalRule" target="_blank">Exchange</a> or Google Workspace to scan your journaled emails in Trustifi.
						<br/><br/>
						*To scan journaled emails to domains other than the primary domain ({{gs.getEmailDomain(userInfo.email)}}), verify the <span style="cursor: pointer; color: blue;" [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.plan, dic.CONSTANTS.inboundPlanSettingsPageTabs.domains]">domain</span> or connect to the Exchange/Google API integration.
					</span>
				</div>
				<div class="content-container flex-column gap-5">
					<div class="flex-row align-items-center gap-3">
						<span class="fw-bold" style="flex-basis: 55px; font-size: 0.9em;">Email:</span>

						<input class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
							   type="{{!showJournalAddress ? 'password' : 'text'}}"
							   [ngStyle]="{'letter-spacing' : showJournalAddress ? 'normal' : '2.4px'}"
							   [ngClass]="{'nofill': !showJournalAddress}"
							   [(ngModel)]="planInfo.journalEmailAddress" disabled>

						<label class="hidepwd hvr-opacity" style="opacity: 0.4; margin-bottom: 0; font-size: 1.2rem; cursor: pointer;"
							   tooltip="{{showJournalAddress ? 'Hide' : 'Show'}}">
							<input type="checkbox" [(ngModel)]="showJournalAddress"/>
							<i class="fa fa-eye unchecked" ></i>
							<i class="fa fa-eye-slash checked"></i>
						</label>

						<a style="font-size: 1rem;"
						   (click)="clipboard.copy(planInfo.journalEmailAddress);" tooltip="Copy">
							<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
						</a>
					</div>
					<div class="flex-row gap-2">
						<div>
							<span style="font-weight: 500;"><u>Instructions:</u></span>
						</div>
						<a href="https://docs.trustifi.com/docs/configuration-in-office365" target="_blank" rel="noopener noreferrer nofollow"
						   style="text-decoration: unset;" class="flex-column align-items-center gap-2">
							<i class="fab fa-windows"
							   style="cursor: pointer; color: #00a1f1; font-size: 25px;">
							</i>
							<span style="font-size: 0.9em;">Microsoft 365</span>
						</a>
						<a href="https://docs.trustifi.com/docs/configuration-in-google-workspace-1" target="_blank" rel="noopener noreferrer nofollow"
						   style="text-decoration: unset;" class="flex-column align-items-center gap-2">
							<img src="images/integrationsLogos/gsuite-logo.png" width="25px" style="cursor: pointer;" alt="google logo">
							<span style="font-size: 0.9em;">Google Workspace</span>
						</a>
					</div>
				</div>
			</div>

			<div class="list-group-item settings-list-item py-5" *ngIf="selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.journal">
				<div class="title-section-container flex-column gap-2">
					<b>Exchange/Google API Integrations</b>
					<span class="lh-base" style="font-size: 0.9em;">Enable the relevant API integration to take actions (e.g. release or quarantine) on emails while using journal architecture.</span>
				</div>
				<div class="content-container">
					<div class="flxRow-start hSpace2 integration-icons" id="intgIconsContainer">
						<div [ngClass]="{'connected' : planInfo?.exchange_server?.enabled, 'not-connected' : !planInfo?.exchange_server?.enabled}"
							 [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.plan, dic.CONSTANTS.inboundPlanSettingsPageTabs.integrations]"
							 [state]="{integrationType: dic.CONSTANTS.integrationType.exchange}"
							 tooltip="{{'Microsoft Exchange' + (planInfo?.exchange_server?.enabled ? ' - Connected' : ' - Not Connected')}}">
							<img src="images/integrationsLogos/exchange-logo.png">
							<span style="transform: translate(150%,150%);" class="led-indicator"></span>
						</div>
						<div [ngClass]="{'connected' : planInfo?.gsuite_server?.enabled, 'not-connected' : !planInfo?.gsuite_server?.enabled}"
							 [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.plan, dic.CONSTANTS.inboundPlanSettingsPageTabs.integrations]"
							 [state]="{integrationType: dic.CONSTANTS.integrationType.gsuite}"
							 tooltip="{{'Google Workspace' + (planInfo?.gsuite_server.enabled ? ' - Connected' : ' - Not Connected')}}">
							<img src="images/integrationsLogos/gsuite-logo.png">
							<span style="transform: translate(150%,150%);" class="led-indicator"></span>
						</div>
					</div>
				</div>
			</div>

			<div class="list-group-item settings-list-item py-5" *ngIf="selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.graph">
				<div class="title-section-container flex-column gap-2">
					<b>Exchange API Integrations</b>
					<span class="lh-base" style="font-size: 0.9em;">To scan emails for threats and perform actions on them (e.g. quarantine) you will need to connect to the Exchange API integration.</span>
				</div>
				<div class="content-container">
					<div class="flxRow-start hSpace2 integration-icons">
						<div [ngClass]="{'connected' : planInfo?.exchange_server?.enabled, 'not-connected' : !planInfo?.exchange_server?.enabled}"
							 [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.plan, dic.CONSTANTS.inboundPlanSettingsPageTabs.integrations]"
							 [state]="{integrationType: dic.CONSTANTS.integrationType.exchange}"
							 tooltip="{{'Microsoft Exchange' + (planInfo?.exchange_server?.enabled ? ' - Connected' : ' - Not Connected')}}">
							<img src="images/integrationsLogos/exchange-logo.png">
							<span style="transform: translate(150%,150%);" class="led-indicator"></span>
						</div>

						<p>
							<span>Status: <b [ngStyle]="{'color' : planInfo?.exchange_server?.enabled ? 'darkgreen' : 'red'}">{{planInfo?.exchange_server?.enabled ? 'Connected' : 'Disconnected'}}</b></span><br/>
							<span *ngIf="planInfo?.exchange_server?.enabled">Exchange API is active to scan emails and block threats</span>
							<span *ngIf="!planInfo?.exchange_server?.enabled">Exchange API is disconnected. Inbound Shield is currently unable to scan emails and block threats.</span>
						</p>
					</div>
				</div>
			</div>

			<div class="list-group-item settings-list-item py-5" *ngIf="selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.graph">
				<div class="title-section-container flex-column gap-2">
					<b>Enable Protection on Mailboxes</b>
					<span class="lh-base" style="font-size: 0.9em;">After connecting to the Exchange API, you will also need to enable protection on your mailboxes from the <a class="blue-link text-nowrap" [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.mailboxes]">Mailbox Management page</a>.</span>
				</div>
			</div>

			<!--MX Instructions (when MX record) -->
			<div class="list-group-item settings-list-item animated fadeIn" *ngIf="selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.mx">
				<div class="title-section-container flex-column gap-2">
					<b>Configure MX connection</b>
					<span class="lh-base" style="font-size: 0.9em;">Follow the steps detailed here to configure your inbound mail flow with Trustifi via MX record.</span>
				</div>
				<div class="content-container flex-column gap-3">
					<ul class="ps-3 mb-0" style="list-style-type: circle; line-height: 1.4;">
						<li>
							<span>All integrated domains must first be verified with Trustifi. You can start this process in <a class="blue-link text-nowrap" [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.plan, dic.CONSTANTS.inboundPlanSettingsPageTabs.domains]">"Domains" tab</a>.<br/>
								You can view our <a class="blue-link text-nowrap" target="_blank" href="https://docs.trustifi.com/docs/domain-verification">Domain Verification Guide</a> for detailed instructions.
							</span>
						</li>
						<li>
							<span>To start routing your inbound mail flow to Trustifi, update your existing MX record to the Trustifi MX below in your DNS management system.</span>
						</li>
					</ul>
					<div class="flex-row align-items-baseline gap-2">
						<i class="fa fa-info-circle text-info"></i>
						<span>For more information and instructions about MX integration please refer to our <a class="blue-link text-nowrap" href="https://docs.trustifi.com/docs/configuration-6" target="_blank">MX integration guide</a>.</span>
					</div>
					<br/>
					<div class="p-3 rounded border flex-row gap-2 align-items-center">
						<span><b>Trustifi MX record</b>: {{planInfo.mx}}</span>
						<!--copy btn-->
						<a style="font-size: 0.9rem;"
						   (click)="copyMxRecord();"
						   tooltip="Copy the MX record">
							<i class="copy-link fas fa-copy"
							   style="cursor: pointer;"></i>
						</a>
					</div>
				</div>
			</div>

			<!--IP set -->
			<div class="list-group-item settings-list-item" *ngIf="planInfo?.inbound_relay?.static_ip_set_ips?.length && (selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.connector || selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.mx)">
				<div class="title-section-container flex-column gap-2">
					<b>IP Address Set (Region: {{planInfo.inbound_relay.static_ip_region || 'US default'}} | Skip Certificate Validation: {{!!planInfo.inbound_relay.static_ip_skip_unauthorized}})</b>
					<span class="lh-base" style="font-size: 0.9em;">IP address that will be used delivering emails back to your MTA.</span>
					<span class="lh-base" style="font-size: 0.9em;"><span style="color: orange;">NOTE:</span> For the assurance of email delivery, it is <b>recommended</b> to add these IPs to your Firewall/SEG appliances.</span>
				</div>
				<div class="content-container flex-column gap-2">
					<span *ngFor="let ip of planInfo.inbound_relay.static_ip_set_ips">
						- {{ip}} <a style="font-size: 0.9rem;"
									(click)="copyItem(ip, 'IP');">
							<i class="copy-link fas fa-copy"
							   style="cursor: pointer;"></i>
						</a>
					</span>
				</div>
			</div>

			<!--Addresses -->
			<div class="list-group-item settings-list-item" *ngIf="selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.connector || selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.mx || selectedArchitecture.key === dic.CONSTANTS.inboundSendingArchitecture.journal">
				<div class="title-section-container flex-column gap-2">
					<b>Selective Mailbox Protection (optional)</b>
					<span class="lh-base" style="font-size: 0.9em;">
						Enter specific addresses here if you want to enable protection for <b>these mailboxes only</b>. If this field is empty, <b>all</b> mailboxes under your mail server will be protected.
					</span>

					<div class="flex-row gap-2">
						<span>Skip sending email to unlisted mailboxes</span>
						<toggle-switch-c id="addresses_only_skip_mode"
										 [hideText]="true"
										 [(model)]="planInfo.inbound_relay.addresses_only_skip_mode"
										 (onChange)="updateEmailRelayConfig(dic.CONSTANTS.inboundRelayActions.addressSkipMode);"
										 [isDisabled]="!planInfo.inbound_relay.addresses_only?.length || updateEmailRelayConfigInProcess"></toggle-switch-c>
					</div>
				</div>
				<div class="content-container flex-column gap-2">
					<div class="flex-row gap-1">
						<input type="text" style="width: 52%; margin: 0;"
							   placeholder="Mailbox address"
							   (keyup.enter)="addAddress(planInfo.inbound_relay.address);"
							   [(ngModel)]="planInfo.inbound_relay.address">
						<btn-c style="min-width: 0;"
							   endIcon="zmdi zmdi-check"
							   [isDisabled]="!planInfo.inbound_relay.address || updateEmailRelayConfigInProcess"
							   (action)="addAddress(planInfo.inbound_relay.address);">
						</btn-c>
						<btn-c (action)="addAddressFromFile();" endIcon="fas fa-file-import" style="min-width: 0;"
							   tooltip="Import addresses from csv">
						</btn-c>
					</div>
					<div style="padding-right: 6px; overflow: auto; max-height: 200px; max-width: 420px;">
						<div *ngFor="let address of planInfo.inbound_relay.addresses_only; let $index = index;"
							 class="flxRow"
							 style="margin-bottom: 10px; justify-content: space-between;">
							<div class="name ellipsis"
								 style="display: inline-block;">{{address}}</div>
							<a class="closeRed action"
							   (click)="removeAddress($index)"
							   [ngClass]="updateEmailRelayConfigInProcess ? 'closeRed disableAction' : 'approveGreen'">
								<i class="fa fa-times"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>
