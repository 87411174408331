<div>
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">My Plan</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle"> </span>
        </div>
    </div>
    <div class="page-inner-content-holder flxClmn vSpace4 bs-enabled">
        <div class="flex-row">
            <div class="flex-column align-items-center border" style="width: 200px;">
                <span class="text-muted pt-3 pb-2 fw-500">Current Plan</span>
                <span class="p-2 text-capitalize fw-bold" *ngIf="isLoaded"
                      [ngClass]="{'text-success' : accountInfo.plan.plan === dic.CONSTANTS.planTypePro}"
                      style="font-size: 1rem;">
                    {{accountInfo.plan.plan}}
                </span>
                <loader [spinnerWidth]="1" *ngIf="!isLoaded" height="35" width="35"></loader>
                <div class="p-2"></div>
            </div>
            <div class="flex-column align-items-center border" style="width: 200px; margin-left: -1px;">
                <span class="text-muted pt-3 pb-2 fw-500">Level</span>
                <span class="p-2 text-capitalize fw-bold" style="font-size: 1rem;" *ngIf="isLoaded">
                    {{accountInfo.user_role === dic.CONSTANTS.userRole.subAdmin ? dic.CONSTANTS.userRole.admin : accountInfo.user_role}}
                </span>
                <loader spinnerWidth="1" *ngIf="!isLoaded" height="35" width="35"></loader>
                <div class="p-2"></div>
            </div>
            <div class="flex-column align-items-center border" style="width: 200px; margin-left: -1px;">
                <span class="text-muted pt-3 pb-2 fw-500">Plan Expiration Date</span>
                <span class="p-2 text-capitalize fw-bold position-relative" *ngIf="isLoaded"
                      [ngClass]="{'text-danger' : daysLeftUntilExpiration < 365/4}"
                      style="font-size: 1rem;">
                    {{accountInfo.plan.expired | date:'MM/dd/yyyy'}}
                    <i class="fa fa-exclamation-triangle opacity-50 cursor-pointer" style="position: absolute; right: 0; top: 7px; transform: translateX(50%); font-size: 0.6rem;"
                       *ngIf="daysLeftUntilExpiration < 365/4"
                       tooltip="{{daysLeftUntilExpiration > 0 ? 'Plan expires in ' + daysLeftUntilExpiration + ' days. Consider extending' : 'Plan is expired. Consider renewing'}} your plan by the form below"></i>
                </span>
                <loader spinnerWidth="1" *ngIf="!isLoaded" height="35" width="35"></loader>
                <div class="p-2"></div>
            </div>
        </div>

        <!--join with key-->
        <div class="flex-row gap-2 align-items-center mt-0 animated fadeIn" *ngIf="isLoaded && accountInfo.user_role === dic.CONSTANTS.userRole.user && !user_joined && accountInfo.type !== dic.CONSTANTS.userTypes.heroku">
            <span>Did you get a key from your admin? You can enter this key here to join their plan:</span>
            <input style="width: 275px; text-overflow: clip;" type="text"
                   id="planJoinKey"
                   [(ngModel)]="sharedPlanInfo.shared_plan.key"
                   (keyup.enter)="joinSharedPlan()"
                   [name]="'pKey' + random"
                   placeholder="Enter key here">
            <btn-c style="min-width: 0;"
                 endIcon="zmdi zmdi-check"
                 (action)="joinSharedPlan();">
            </btn-c>
        </div>
        <!--already joined with key-->
        <div class="row ready hSpace05 animated fadeIn"
             *ngIf="isLoaded && (accountInfo.user_role === dic.CONSTANTS.userRole.subAdmin || accountInfo.user_role === dic.CONSTANTS.userRole.user) && user_joined">
            <label style="color: black; display: inline-block; width:220px; vertical-align: unset; margin:0 auto;">
                You joined a plan with a plan key
                <i style="font-weight: 400;" class="fas fa-question-circle" tooltip="<b>Plan key</b> is the key for the shared plan that you have joined" title-direction="right"></i>
            </label>
            <span>
				<input style="width: 175px; margin: 0 auto; text-overflow: clip;" type="text"
                         [(ngModel)]="sharedPlanInfo.shared_plan.key" disabled>
			</span>

			<a tooltip="Copy the shared plan key" (click)="copyKeyToClipboard(sharedPlanInfo.shared_plan.key);">
				<i class="fa fa-copy copy-link"></i>
				<span style="margin-left: 3px;">Copy</span>
			</a>
            <a tooltip="Leave this plan and go back to your previous plan" class="closeRed" style="color: red;"
               (click)="leaveSharedPlan()">
                <i class="zmdi zmdi-close zmdi-hc-lg"
                   style="cursor: pointer; margin-left:5px;">
                </i>
                <span style="font-size: 11px; padding-left:3px;">Leave</span>
            </a>
        </div>
        <!---->

        <br/><br/>
        <h6 class="flex-row align-items-center gap-2 mb-0 animated fadeIn" *ngIf="showUpgradeOrExpandPlan">
            <i class="fa fa-arrow-circle-up"></i>
            <span>{{accountInfo.plan.plan === dic.CONSTANTS.planTypePro ? 'Extend License' : 'Upgrade plan'}}</span>
        </h6>
        <div class="flex-row animated fadeIn" style="max-width: 1100px;" *ngIf="showUpgradeOrExpandPlan">
            <!--Pro plan details-->
            <div class="flex-column border py-4 px-5" style="flex: 1 0;">
                <div class="flex-column centralize gap-2 align-self-center">
                    <div class="rounded-circle" style="width: 35px; padding: 5px; border: solid 2px #00ca5d;">
                        <img src="images/favicon/favicon-32x32.png">
                    </div>
                    <span class="fw-bold" style="font-size: 1.1rem;">Pro Plan</span>
                </div>
                <div class="flex-column">
                    <span class="fw-bold" style="font-size: 1.5rem;">${{accountInfo.plan.proYearlyPrice}}</span>
                    <span class="fw-500 text-muted">Per Year / Per User</span>
                </div>
                <br/>
                <ul class="check-list-style">
                    <li>
                        <a class="blue-link text-decoration-underline fw-bold cursor-default"
                           placement="right"
                           tooltip="<div class='flxClmn bs-enabled' style='list-style-type: none; margin: 0; padding: 10px; font-size: 12px;'>
                                          <span class='p-1 pt-2'>End-to-end secure environment for your most important data.<span/><br/><br/>
                                          <span class='px-1'>Encrypt & Secure your Emails.<span/><br/><br/>
                                          <span class='px-1'>Require Multi-Factor-Authentication from your recipients.<span/><br/><br/>
                                          <span class='px-1'>Enable your recipients to securely reply to you.<span/><br/><br/>
                                          <span class='px-1'>Comprehensive tracking capabilities for your Emails.<span/><br/><br/>
                                          <span class='p-1 pb-2'>Compliant under GDPR, FERPA, HIPAA, PCI, CCPA, PDPO, POPI, LGPD and GLBA regulations.<span/>
                                      </div>">
                            Includes all core features
                        </a>
                    </li>
                    <li>Full scan of all email components (headers, links, attachments), using our sophisticated engines that are updated daily to keep you safe from the latest email attacks</li>
                    <li>Enhanced management system - A web-based platform to manage users/mailboxes, configure preferences, manage global and personal allowlists and blocklists, view and control quarantined emails and more solutions to customize your threat protection system</li>
                    <li>Friendly reporting system - Users can report suspicious emails using the add-in or directly from the email itself</li>
                    <li>Upload files/attachments up to {{ subscriptions.max_file }}GB</li>
                    <li>Integrate with 3rd party applications</li>
                </ul>
            </div>
            <!---->

            <div class="flex-column border" style="margin-left: -1px; flex: 1 0;">
                <div class="md-tabs" [ngStyle]="{'--md-tabs-width': tabWidth + 'px'}"
                     *ngIf="accountInfo.plan.plan === dic.CONSTANTS.planTypePro">
                    <div *ngFor="let tab of upgradePlanMethods | keyvalue; trackBy: gs.trackByIndex"
                         [ngClass]="{'selected' : selectedUpgradePlanMethod === tab.value}"
                         (click)="selectedUpgradePlanMethod = tab.value">
                        <input type="radio" name="tabs" id="{{tab.key}}Toggle" [checked]="selectedUpgradePlanMethod === tab.value">
                        <label for="{{tab.key}}Toggle">{{tab.value.display}}</label>
                    </div>
                    <div class="slide" [ngStyle]="{'left' : tabWidth * _.values(upgradePlanMethods).indexOf(selectedUpgradePlanMethod) + 'px' || 0}"></div>
                </div>

                <!--pro user extends plan-->
                <div class="flex-column f-1-0 gap-5 p-5 pt-4" *ngIf="accountInfo.plan.plan === dic.CONSTANTS.planTypePro">
                    <div class="flex-column gap-1 align-items-center flex-shrink-0" style="min-height: fit-content;" *ngIf="partner && partner.displayName">
                        <img *ngIf="partner.displayName && partner.logo" [src]="partner.logo" style="max-width: 130px;">
                        <span *ngIf="partner.displayName">Via Partner <b>{{partner.displayName}}</b></span>
                    </div>
                    <div class="flex-row align-items-baseline gap-2">
                        <i class="fa fa-info-circle text-info"></i>
                        <span *ngIf="selectedUpgradePlanMethod === upgradePlanMethods.oneYear">Please note: the following will keep the current license count of your plan, and will extend the expiration period for your plan.</span>
                        <span *ngIf="selectedUpgradePlanMethod === upgradePlanMethods.licenses">Please note: the following will keep the current expiration period for your plan, and will add new licenses within your current expiration date ({{accountInfo.plan.expired | date:'MM/dd/yyyy'}}).</span>
                    </div>
                    <!--add one year-->
                    <div class="flex-column gap-4 f-1-0" style="min-height: fit-content;" *ngIf="selectedUpgradePlanMethod === upgradePlanMethods.oneYear">
                        <div class="flex-row align-items-center flex-shrink-0">
                            <span class="f-1-0">Your plan’s current total licenses:</span>
                            <b style="font-size: 0.95rem;">{{currentLicenses}}</b>
                        </div>
                        <div class="flex-row align-items-center flex-shrink-0">
                            <span class="f-1-0">Your plan’s current expiration date:</span>
                            <b style="font-size: 0.95rem;">{{accountInfo.plan.expired | date:'MM/dd/yyyy'}}</b>
                        </div>
                        <div class="flex-row align-items-center flex-shrink-0">
                            <span class="f-1-0">Your plan's new expiration date will be:</span>
                            <b class="text-success" style="font-size: 0.95rem;">{{oneYearAheadOfExpiration | date:'MM/dd/yyyy'}}</b>
                        </div>
                        <div class="flex-row align-items-center flex-shrink-0">
                            <span class="f-1-0">Total amount to pay:</span>
                            <b style="font-size: 0.95rem;" [ngClass]="{'text-success' : isCouponApplied}">${{(accountInfo.plan.proYearlyPrice * currentLicenses) | number : '1.2-2'}}</b>
                        </div>
						<ng-container *ngTemplateOutlet="couponInput"></ng-container>
                    </div>
                    <!--add licenses-->
                    <div class="flex-column gap-4 f-1-0" style="min-height: fit-content;" *ngIf="selectedUpgradePlanMethod === upgradePlanMethods.licenses">
                        <div class="flex-row align-items-center flex-shrink-0">
                            <span class="f-1-0">Your plan’s current total licenses:</span>
                            <b style="font-size: 0.95rem;">{{currentLicenses}}</b>
                        </div>
                        <div class="flex-row align-items-center flex-shrink-0" style="height: 18.23px;">
                            <span class="f-1-0">Select how many licenses to add for this period:</span>
                            <input type="number" min="1" pattern="^([1-9][0-9]*)$"
                                   [(ngModel)]="amountOfUsersToAdd" class="ps-2" style="flex-basis: 90px; min-width: 0;">
                        </div>
                        <div class="flex-row align-items-center flex-shrink-0">
                            <span class="f-1-0">Your plan’s current expiration date:</span>
                            <b style="font-size: 0.95rem;">{{accountInfo.plan.expired | date:'MM/dd/yyyy'}}</b>
                        </div>
                        <div class="flex-row align-items-center flex-shrink-0">
                            <span class="f-1-0">Total amount to pay:</span>
                            <b style="font-size: 0.95rem;" [ngClass]="{'text-success' : isCouponApplied}">${{(priceUntilExpiration * amountOfUsersToAdd) | number :'1.2-2'}}</b>
                        </div>
						<ng-container *ngTemplateOutlet="couponInput"></ng-container>
                    </div>

                    <div class="paypal-wrapper py-4 w-100 flex-column justify-content-center" style="flex: 1.8 0;">
						<paypal-buttons-component
							[hasAccessToken]="true"
							[type]="selectedUpgradePlanMethod.paypalPaymentType"
							[partner]="partner"
							[couponCode]="couponCode"
							[numUsers]="amountOfUsersToAdd"
							[accountDetails]="userInfo"
							(onComplete)="paypalComplete($event.err, $event.res)">
						</paypal-buttons-component>
                    </div>
                </div>

                <!--free user buys plan-->
                <div class="flex-column f-1-0 gap-5 p-5" *ngIf="accountInfo.plan.plan === dic.CONSTANTS.planTypeFree">
                    <div class="flex-column gap-1 align-items-center flex-shrink-0" style="min-height: fit-content;" *ngIf="partner && partner.displayName">
                        <img *ngIf="partner.logo" [src]="partner.logo" style="max-width: 130px;">
                        <span>Via Partner <b>{{partner.displayName}}</b></span>
                    </div>
                    <div class="flex-column gap-4" style="min-height: fit-content;">
                        <div class="flex-row align-items-center flex-shrink-0" style="height: 18.23px;">
                            <span class="f-1-0">Select how many new licenses to buy per year (up to {{partner.maxUsers || 20}}):</span>
                            <input type="number" min="1" pattern="^([1-9][0-9]*)$"
                                   [(ngModel)]="amountOfUsersToAdd" (keyup)="correctFreeUserAmountToAddInput()" class="ps-2" style="min-width: 60px; flex: 0 1;">
                        </div>
                        <div class="flex-row align-items-center flex-shrink-0">
                            <span class="f-1-0">Total amount to pay:</span>
                            <b style="font-size: 0.95rem;" [ngClass]="{'text-success' : isCouponApplied}">${{(accountInfo.plan.proYearlyPrice * amountOfUsersToAdd) | number : '1.2-2'}}</b>
                        </div>
						<ng-container *ngTemplateOutlet="couponInput"></ng-container>
                    </div>

                    <div class="paypal-wrapper py-4 w-100 f-1-0 flex-column justify-content-center">
						<paypal-buttons-component
							[hasAccessToken]="true"
							[type]="dic.CONSTANTS.paypalPaymentType.upgradePro"
							[partner]="partner"
							[couponCode]="couponCode"
							[numUsers]="amountOfUsersToAdd"
							[accountDetails]="userInfo"
							(onComplete)="paypalComplete($event.err, $event.res)">
						</paypal-buttons-component>
                    </div>
                    <btn-c class="align-self-center my-2" variant="text"
                         *ngIf="payments.trustifi"
                         (action)="redirectToCustomerPlan(true);">
                        {{partner ? 'Need more users? Get a quote now!': 'Want to upgrade? Get a quote now!'}}
                    </btn-c>
                    <btn-c class="align-self-center my-2" variant="text"
                         *ngIf="!payments.trustifi && payments.buttonName && payments.redirectUrl"
                         (action)="redirectToCustomerPlan();">
                        {{payments.buttonName}}
                    </btn-c>
                </div>
            </div>
        </div>
    </div>

	<!--((coupon template))-->
	<ng-template #couponInput>
		<div (ngInit)="showCouponInput = false;" class="flex-row justify-content-end">
			<a class="blue-link fw-500 text-decoration-underline"
			   *ngIf="!showCouponInput && !isCouponApplied"
			   (click)="showCouponInput = true;">I have a coupon</a>
			<div class="flex-row gap-2 border-top pt-3 align-items-center f-1-0" *ngIf="showCouponInput || isCouponApplied">
				<span>Enter coupon code:</span>
				<input type="text"
					   [disabled]="isCouponApplied"
					   (keyup.enter)="applyCoupon()"
					   [ngClass]="{'disabled bg-transparent fw-bold border-0 opacity-100 f-1-0' : isCouponApplied}"
					   [(ngModel)]="couponCode" style="width: 100px;">
				<btn-c style="min-width: 0;"
					   *ngIf="!isCouponApplied"
					   endIcon="zmdi zmdi-check"
					   (action)="applyCoupon()">
				</btn-c>
				<span class="text-success fw-500" *ngIf="isCouponApplied">${{priceSaved}} off per license</span>
			</div>
		</div>
	</ng-template>
	<!---->
</div>
