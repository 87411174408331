<div class="flxClmn animated fadeIn message-tab-content" style="flex: 1 0; padding: 0 15px;">
    <div class="actionRow flex-container" style="position: sticky; top: 0; background: white; z-index: 50;"
         *ngIf="((!myEmailsService.currentMessage.recipients_deleted && (myEmailsService.currentMessage.mass_lists.length || myEmailsService.currentMessage.methods.secure_mass))) && (myEmailsService.currentMessage.methods.secure_mass && !myEmailsService.currentMessage.isCampaignTitle && !myEmailsService.currentMessage.recipients_deleted)">
        <div class="actionLabel flex-item">Actions</div>

        <div class="actionItem flex-item" *ngIf="(!myEmailsService.currentMessage.recipients_deleted && (myEmailsService.currentMessage.mass_lists.length || myEmailsService.currentMessage.methods.secure_mass))"
             (click)="doEmailAction('prepareSummary', {reload: true, isFirst: false});">
            <a>Prepare summary report</a>
        </div>
        <div class="actionItem flex-item" *ngIf="myEmailsService.currentMessage.methods.secure_mass && !myEmailsService.currentMessage.isCampaignTitle && !myEmailsService.currentMessage.recipients_deleted"
             (click)="doEmailAction('prepareFullReport');">
            <a>Prepare full report</a>
        </div>
        <div class="actionItem flex-item">
            <a class="hSpace05" (click)="doEmailAction('exportSummaryCsv');"><i class="fas fa-file-export">
            </i> <span>Export summary report</span>
            </a>
        </div>
        <div class="actionItem flex-item" *ngIf="myEmailsService.currentMessage.final_report_key" style="height: 18px;">
            <a (click)="downloadFile('/email/display/summary/recipients/csv/download/' + myEmailsService.currentMessage._id, 'full_report_' + myEmailsService.currentMessage._id + '.csv', 0);">
            <i class="fas fa-file-export"></i> <span>Export full report</span>
            </a>
        </div>
    </div>

    <div class="summary-status flxRow-between" *ngIf="myEmailsService.currentMessage.summaryData">
        <div class="flxRow gap-1" style="align-items: center; gap: 5px;">
            <span class="status-text">Status: {{myEmailsService.currentMessage.summaryData.final_status_text}}</span>
            <i [ngClass]="{'fa-check-circle' : myEmailsService.currentMessage.summaryData.final_status == 'Completed Successfully',
                          'fa-exclamation-circle' : myEmailsService.currentMessage.summaryData.final_status == 'Failed',
                          'fa-history' : myEmailsService.currentMessage.summaryData.final_status == 'In Process',
                          'fas' : true}"
            ></i>
        </div>
        <btn-c endIcon="fas fa-sync-alt"
             variant="outlined"
             (action)="doEmailAction('prepareSummary', {reload: true, isFirst: false});">
            Refresh Summary
        </btn-c>
    </div>
    <div class="flxClmn-center" style="align-items: center; min-height: 200px;" *ngIf="getSummaryDataInProcess">
        <loader height="100" width="100" style="opacity: 0.4;" ></loader>
    </div>
    <div class="summary-section flxClmn gap-3" style="gap: 15px; flex: 1 0;" *ngIf="!getSummaryDataInProcess && myEmailsService.currentMessage.summaryData">
<!--        Boxes Container -->
        <div class="summary-boxes-container flxRow flex-wrap gap-4" style="column-gap: 30px; row-gap: 15px; flex-wrap: wrap;">
<!--            box - container - 1-->
            <div class="box-summary-container flxClmn vSpace1">
                <div class="summary-box flxClmn alt" style="max-height: 235px; border-radius: 10px;"
                     *ngIf="myEmailsService.currentMessage.summaryData.general && myEmailsService.currentMessage.summaryData.general.length > 0">
                    <div class="summary-box-title flxRow-center color-text" style="align-items: center; padding: 5px 0;">
                        <h5 style="margin-bottom: 0;">General</h5>
                    </div>
                    <div class="summary-box-tab-container" style="flex-grow: 1; overflow: auto;">
                        <div class="flxRow-between summary-box-tab" style="padding: 8px 14px; max-width: unset; border-bottom: unset;"
							 *ngFor="let report of myEmailsService.currentMessage.summaryData.general; trackBy: gs.trackByIndex">
                        <span class="title" *ngIf="report.name === 'Summary report created at'">{{report.name}}</span>
                        <span class="title" *ngIf="report.name !== 'Summary report created at'">{{report.name}}</span>

                        <span class="info" *ngIf="report.type == 'Date' && report.name === 'Summary report created at'"><time>{{ report.value | date:'MM/dd/yyyy HH:mm' }}</time></span>
                        <span class="info" *ngIf="report.type == 'Date' && report.name !== 'Summary report created at'"><time>{{ report.value | date:'MM/dd/yyyy HH:mm' }}</time></span>
                        <span class="info" *ngIf="report.type != 'Date'">{{report.value}}</span>
                    </div>
                        <div style="margin-left: 12px; font-style: italic; font-size: 12px; margin-top: 10px;" *ngIf="myEmailsService.currentMessage.methods.secure_mass && myEmailsService.currentMessage.mass_lists && myEmailsService.currentMessage.mass_lists.length > 1">Note: Each recipient will receive the email only once, even if they are duplicated in the list(s).</div>
                    </div>
                </div>
            </div>
<!--            box - container - 2-->
            <div class="box-summary-container flxClmn vSpace1">
                <div class="summary-box flxClmn alt" style="max-height: 235px; border-radius: 10px;"
                     *ngIf="myEmailsService.currentMessage.summaryData.events?.length">
                    <div class="summary-box-title flxRow-center color-text" style="align-items: center; padding: 5px 0;">
                        <h5 style="display: inline-block; margin-bottom: 0;">Events Summary (Distinct)</h5>
                        <i style="font-weight: 400; padding-left: 2px;" class="fas fa-question-circle" tooltip="Only 1 event per recipient is counted" placement="right"></i>
                    </div>
                    <div class="summary-box-tab-container" style="flex-grow: 1; overflow: auto">
						<ng-container *ngIf="myEmailsService.currentMessage.methods.secure_mass">
							<div class="flxRow-between summary-box-tab" style="padding: 8px 14px; max-width: unset; border-bottom: unset;"
								 *ngFor="let event of myEmailsService.currentMessage.summaryData.events">
								<span id="emailSummaryTitle-{{event.name}}" class="title">{{event.name}}
										<i style="font-weight: 400;" class="fas fa-question-circle" [tooltip]="getEventDescriptions(event.name)" placement="right"></i>
								</span>
								<ng-container *ngIf="myEmailsService.currentMessage.summaryData?.recipientsEvents && myEmailsService.currentMessage.summaryData?.recipientsEvents[event.name]">
									<div *ngFor="let recipient of myEmailsService.currentMessage.summaryData.recipientsEvents[event.name]">
										{{recipient}}
									</div>
								</ng-container>

								<span id="emailSummaryInfo-{{event.name}}" class="info">{{event.value}}</span>
							</div>
						</ng-container>
						<ng-container *ngIf="!myEmailsService.currentMessage.methods.secure_mass">
							<div class="event-and-recipients summary-box-tab"
								 *ngFor="let event of myEmailsService.currentMessage.summaryData.events"
								 [ngClass]="event.opened ? 'event-and-recipients-margin' : ''"
								 (ngInit)="event.opened = event.opened || false">
								<div class="flxRow-between summary-box-tab" style="padding: 8px 14px; max-width: unset; border-bottom: unset;">
									<span class="title"
										  (click)="event.opened = !event.opened">
										<a>
											<i class="zmdi" style="cursor: pointer;"
											   [ngClass]="event.opened ? 'zmdi-chevron-up': 'zmdi-chevron-down'">
											</i>
											{{event.name}}
										</a>
										<i style="font-weight: 400;" class="fas fa-question-circle" [tooltip]="getEventDescriptions(event.name)" placement="right"></i>
									</span>
									<span class="info">{{event.value}}</span>
								</div>
								<div class="summary-recipients" style="padding-top: 8px;" *ngIf="event.opened">
									{{myEmailsService.currentMessage.summaryData.recipientsEvents[event.name].join(', ')}}
								</div>
							</div>
						</ng-container>
                    </div>
                </div>
            </div>
<!--            box - container - 3-->
            <div class="box-summary-container flxClmn vSpace1">
                <div class="summary-box flxClmn alt" style="max-height: 235px; border-radius: 10px;"
                     *ngIf="myEmailsService.currentMessage.summaryData.links && myEmailsService.currentMessage.summaryData.links.length > 0">
                    <div class="summary-box-title flxRow-center color-text" style="align-items: center; padding: 5px 0;">
                        <h5 style="display: inline-block; margin-bottom: 0;">Links Summary (Distinct)</h5>
                        <i style="font-weight: 400; padding-left: 2px;" class="fas fa-question-circle" tooltip="Only 1 event per recipient is counted" placement="right"></i>
                    </div>
                    <div class="summary-box-tab-container" style="flex-grow: 1; overflow:auto;">
						<ng-container *ngIf="myEmailsService.currentMessage.methods.secure_mass">
							<div class="flxRow-between summary-box-tab" style="padding: 8px 14px; max-width: unset; border-bottom: unset;"
								 *ngFor="let link of myEmailsService.currentMessage.summaryData.links">
								<span class="title">{{link.name}}</span>
								<span class="info">{{link.value}}</span>
							</div>
						</ng-container>
						<ng-container *ngIf="!myEmailsService.currentMessage.methods.secure_mass">
							<div class="event-and-recipients"
								 *ngFor="let link of myEmailsService.currentMessage.summaryData.links"
								 [ngClass]="link.opened ? 'event-and-recipients-margin' : ''"
								 (ngInit)="link.opened = link.opened || false">
								<div class="flxRow-between summary-box-tab" style="padding: 8px 14px; max-width: unset; border-bottom: unset;">
									<span class="title"
										  (click)="link.opened = !link.opened">
										<a>
											<i class="zmdi" style="cursor: pointer;"
											   [ngClass]="link.opened ? 'zmdi-chevron-up': 'zmdi-chevron-down'">
											</i>
											{{link.name}}
										</a>
									</span>
									<span class="info">{{link.value}}</span>
								</div>
								<div class="summary-recipients" style="padding-top: 8px;" *ngIf="link.opened">
									{{myEmailsService.currentMessage.summaryData.recipientsEvents[link.name].join(', ')}}
								</div>
							</div>
						</ng-container>
                    </div>
                </div>
            </div>
        </div>

    <!-- Map container-->
        <div class="summary-map-container flxClmn vSpace05" style="align-items: center; flex: 1 0; min-height: min(50vh, 300px);"
             *ngIf="myEmailsService.currentMessage.summaryData.emailOpened">
            <div class="summary-map-title" style="padding: 10px 0;">
                <h5 style="margin-bottom: 0; font-weight: 600;">Map of Open events</h5>
            </div>
            <div class="btn-map-container flxClmn centralize" style="background-color: white; flex: 1 0; width: 100%;">
                <btn-c *ngIf="!myEmailsService.currentMessage.openSummaryReady"
                     variant="text"
                     (action)="getOpenSummary(); myEmailsService.currentMessage.openSummaryReady = true;">
                    Show map
                </btn-c>
            </div>
            <div class="summary-map h100 w100" *ngIf="myEmailsService.currentMessage.openSummaryReady">
				<leaflet-c *ngIf="markers" class="flxClmn f-1-0 w100 h100" [leafletCenter]="center" [markers]="markers"></leaflet-c>
            </div>
        </div>
    </div>
</div>

<popup-c class="bs-enabled" *ngIf="prepareFullReportPopup?.show"
		 head="Preparing Full Report" size="dynamic"
		 (closeCb)="prepareFullReportPopup.show = false;"
		 (confirmCb)="prepareFullReportPopup?.doneCb()"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Prepare', disabled: prepareFullReportPopup.applyInProcess}}">
	<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
		<p style="font-size: 14px;">Please note - it can take some time to prepare the full report.</p>

		<div class="vSpace3 py-2">
			<div class="flexRow-start hSpace1 md-checkbox">
				<input id="checkbox-addDataToggle" type="checkbox" [(ngModel)]="prepareFullReportPopup.addDataToggle">
				<label for="checkbox-addDataToggle">
					<span style="vertical-align: middle;">Add all original fields to the report</span>
				</label>
			</div>
			<div class="flexRow-start hSpace1 md-checkbox" style="margin-top: 1rem;">
				<input id="checkbox-notifyReadyToggle" type="checkbox" [(ngModel)]="prepareFullReportPopup.notifyReadyToggle">
				<label for="checkbox-notifyReadyToggle">
					<span style="vertical-align: middle;">Send me an email when the report is ready</span>
				</label>
			</div>
		</div>
	</div>
</popup-c>
