<div
     class="flxClmn animated fadeIn message-tab-content" style="flex: 1 0; padding: 0 15px;">
    <div class="actionRow flex-container" *ngIf="origin !== 'user'">
        <div class="actionLabel flex-item">Actions</div>
        <div class="actionItem flex-item"
             *ngIf="(archiveService.currentMessage.emailInfo?.methods?.encrypt_content || archiveService.currentMessage.emailInfo?.methods.secure_reply || archiveService.currentMessage.emailInfo?.methods.secure_send || (archiveService.currentMessage.emailInfo?.sent.attachments.length && !archiveService.currentMessage.emailInfo.skip_attachments_encryption))"
             (click)="doEmailAction(recipientTrack.is_blocked || recipientTrack.is_access_blocked ? 'unblockRecipient' : 'blockRecipient');"
             id="archiveBlockRecipient">
            <a [ngStyle]="changeMessageInProcess && {'opacity':'0.5','cursor':'not-allowed'}">
                {{recipientTrack.is_blocked || recipientTrack.is_access_blocked ?
                'Unblock' : 'Block'}} recipient
            </a>
        </div>
        <div class="actionItem flex-item"
             *ngIf="(archiveService.currentMessage.emailInfo?.methods.encrypt_content || archiveService.currentMessage.emailInfo?.methods.secure_reply || archiveService.currentMessage.emailInfo?.methods.secure_send || (archiveService.currentMessage.emailInfo?.sent.attachments.length && !archiveService.currentMessage.emailInfo.skip_attachments_encryption))"
             (click)="doEmailAction('blockEmail');"
             id="archiveBlockEmail">
            <a [ngStyle]="changeMessageInProcess && {'opacity':'0.5','cursor':'not-allowed'}">
                Block email
            </a>
        </div>
        <div class="actionItem flex-item"
             *ngIf="(archiveService.currentMessage.emailInfo?.methods.encrypt_content || archiveService.currentMessage.emailInfo?.methods.secure_reply || archiveService.currentMessage.emailInfo?.methods.secure_send || (archiveService.currentMessage.emailInfo?.sent.attachments.length && !archiveService.currentMessage.emailInfo.skip_attachments_encryption))"
             (click)="doEmailAction('unblockEmail');"
             id="archiveUnBlockEmail">
            <a [ngStyle]="changeMessageInProcess && {'opacity':'0.5','cursor':'not-allowed'}">
                Unblock email
            </a>
        </div>
    </div>

    <div class="flxRow-between" style=" align-items: center; padding: 10px; height: 57px; z-index: 5;">
        <span class="title" style=" margin: 0; font-size: 1.5rem; color: dimgrey;">Outbound Tracking</span>
        <div class="flxRow animated fadeIn" style=" align-items: center;" *ngIf="recipientTrack">
            <span style=" margin-top: -5px; font-weight: 500;">View tracking for: </span>
            <div *ngIf="archiveService.currentMessage?.trackingData.length > 0" style=" margin-left: 10px; height: 37px;">
                <app-dropdown-c style="width: 260px;"
                              [items]="archiveService.currentMessage?.trackingData"
                              itemDisplayKey="email" [enableSearch]="true"
                              (onItemClicked)="changeTrackingRecipient($event.item)"
                              [text]="recipientTrack.email"></app-dropdown-c>
            </div>
        </div>
    </div>

    <hr style="margin-top: 0;">

    <!--loader-->
    <div class="flxClmn-center" style="flex: 1 0; align-items: center;" *ngIf="!recipientTrack && getTrackingDataInProcess">
        <div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 0.7;"></div>
    </div>
    <!--loader-->
    <div class="flxClmn-center" style="flex: 1 0; align-items: center;" *ngIf="isEmailNotFound">
        <span style="opacity: 0.7; font-size: 1rem;">Information is not available for this email</span>
    </div>

	<tracking-events *ngIf="recipientTrack" style="margin-top: 1rem;"
					 [recipientTrack]="recipientTrack"
					 [methods]="archiveService.currentMessage.emailInfo.methods"
					 class="f-1-0">
	</tracking-events>
</div>
