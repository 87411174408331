import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {GeneralService} from "src/app/services/generalService";
import _ from "lodash";
import {AuthService} from "../../services/authService";
import {DICTIONARY} from "../../dictionary";
import {RouteService} from "../../services/routeService";
import {Router} from "@angular/router";

@Component({
	selector: 'mfa-component',
	templateUrl: './mfa.component.html',
})

export class MfaComponent implements OnInit {
	@Input() data2Fa;
	@Input() enableResend;
	@Input() disableConfirmAuth;
	@Input() loginStatuses;
	@Input() error;
	@Output() loginStatusChange: EventEmitter<any> = new EventEmitter();


	constructor(private rs:RouteService,
				private router:Router,
				public gs:GeneralService,
				private authService:AuthService) {
	}

	ngOnInit(): void {
		if (this.error) {
			this.data2Fa = this.data2Fa || {};
			this.data2Fa.message = {
				text: this.error,
				type: DICTIONARY.CONSTANTS.msgTypeWarn
			};
		}
    }

	dic = DICTIONARY;
	_=_;
	isPhone;
	isEmail;
	htmlRetrieved;
	loginStatus;

	getPinCode = (resend) => {
		if ((!resend && (!this.data2Fa.phone.phone_number || this.disableConfirmAuth)) || (resend && !this.enableResend)) {
			return;
		}

		// check if the phone number contains letters or longer than 16 digits
		if (!(Math.floor(Number(this.data2Fa.phone.phone_number)) === Number(this.data2Fa.phone.phone_number)) || this.data2Fa.phone.phone_number.length > 16) {
			this.data2Fa.message.text = this.dic.ERRORS.invalidPhoneNumber;
			this.data2Fa.message.type = this.dic.CONSTANTS.msgTypeWarn;
			return;
		}
		this.clearMessage();
		this.disableConfirmAuth = true;
		const type = resend ? this.data2Fa.resendType : this.data2Fa.type;

		this.rs.do2FaAuthentication({
			action: this.dic.CONSTANTS.authenticationStatus.phone,
			phone: this.data2Fa.phone,
			resend: resend,
			codeType: type
		}).then(response => {
				this.data2Fa.status = response.status;
				this.data2Fa.method = response.auth;
				this.gs.formatPhone(this.data2Fa);
				setTimeout(() => {
					this.enableResend = true;
				}, this.dic.CONSTANTS.RESEND_TIMEOUT);

				this.disableConfirmAuth = false;
				this.enableResend = false;
				this.data2Fa.message.type = this.dic.CONSTANTS.msgTypeInfo;

				if (resend) {
					this.data2Fa.message.text = this.dic.MESSAGES.resendPinCode;
					this.data2Fa.type = this.data2Fa.resendType;
				}
				else {
					this.data2Fa.message.text = response.data;
				}

			},
			error => {
				this.confirmFailure(error, this.dic.CONSTANTS.authenticationStatus.phone);
			});
	};

	confirmEmail = (resend) => {
		this.data2Fa.invalidEmail = false;
		if ((!resend && (!this.data2Fa.emailAuth || this.disableConfirmAuth)) || (resend && !this.enableResend)) {
			return;
		}
		if (!this.gs.validateEmail(this.data2Fa.emailAuth.trim())) {
			this.data2Fa.invalidEmail = true;
			this.data2Fa.message.type = this.dic.CONSTANTS.msgTypeWarn;
			this.data2Fa.message.text = 'Invalid email address';
			return;
		}

		this.clearMessage();
		this.disableConfirmAuth = true;
		this.rs.do2FaAuthentication({
			action: this.dic.CONSTANTS.authenticationStatus.email,
			email: this.data2Fa.emailAuth.trim()
		}).then(response => {
			this.data2Fa.status = response.status;
			this.data2Fa.method = response.auth;
			this.disableConfirmAuth = false;

			setTimeout(() => {
				this.enableResend = true;
			}, this.dic.CONSTANTS.RESEND_TIMEOUT);
			this.enableResend = false;

			if (resend) {
				this.data2Fa.message.type = this.dic.CONSTANTS.msgTypeInfo;
				this.data2Fa.message.text = this.dic.MESSAGES.resendPinCode;
				this.data2Fa.type = this.data2Fa.resendType;
			}
		}, error => {
			this.confirmFailure(error, this.dic.CONSTANTS.authenticationStatus.email);
		});
	};

	confirmTotp = () => {
		if (this.disableConfirmAuth || !this.data2Fa.totpCode) {
			return;
		}

		this.disableConfirmAuth = true;

		this.rs.do2FaAuthentication({
			action: this.data2Fa.status,
			password: this.data2Fa.totpCode
		}).then(res => {
			this.confirmMfaSuccess(res);
		}, error => {
			this.confirmFailure(error, this.dic.CONSTANTS.authenticationStatus.totp);
		});
	};

	confirmMfaSuccess(response) {
		this.data2Fa.status = this.dic.CONSTANTS.authenticationStatus.authenticated;

		if (response.fingerprint) {
			this.authService.addFingerprint(response.fingerprint);
		}

		// redirections after successful login:
		// global
		if (window.history.state.transitionName) {
			this.router.navigate([window.history.state.transitionName], {state: window.history.state.transitionParams});
		}
		// go to inbound quarantined -apply query by message id
		else if (window.history.state.quarantinedMessageId) {
			this.router.navigate([this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.quarantined], {state: window.history.state});
		}
		// My plan page
		else if (window.history.state.company) {
			this.router.navigate([this.dic.CONSTANTS.appStates.accountDetails, this.dic.CONSTANTS.accountDetailsPages.myPlan], {state: window.history.state});
		}
		// default
		else {
			this.router.navigate([this.dic.CONSTANTS.appStates.home]);
		}
	}

	confirmPinCode = () => {
		if (this.disableConfirmAuth || !this.data2Fa.pinCode) {
			return;
		}
		this.clearMessage();
		this.disableConfirmAuth = true;

		this.rs.do2FaAuthentication({
			action: this.dic.CONSTANTS.authenticationStatus.code,
			code: parseInt(this.data2Fa.pinCode)
		}).then(response => {
			this.confirmMfaSuccess(response);
		}, error => {
			this.confirmFailure(error, this.dic.CONSTANTS.authenticationStatus.code);
		});
	};

	clearMessage() {
		if (this.data2Fa && this.data2Fa.message) {
			this.data2Fa.message.text = '';
			this.data2Fa.message.type = '';
		}
	}

	navigateToLogin = () => {
		this.clearMessage();
		this.authService.logout(false);
		this.updateLoginStatus(this.loginStatuses.emailOrSSOForm);
	};

	confirmFailure(error, currentStatus) {
		this.disableConfirmAuth = false;
		if (error.data.status === currentStatus) {
			if (error.data.remain_attempts) {
				let msg = error.data.remain_attempts > 1 ? "You have "+error.data.remain_attempts+" attempts remaining" : "You have one attempt remaining";
				this.data2Fa.message.text = msg;
				this.data2Fa.message.type = this.dic.CONSTANTS.msgTypeWarn;
				return;
			}
		}
		else {
			if (error.data.status) {
				this.data2Fa.status = error.data.status;
			}
			this.data2Fa.flagIcon = this.gs.getCountryCodeFlag(this.data2Fa.country_code);
			this.data2Fa.pinCode = '';
		}
		if (!error.data.display_bar) {
			this.data2Fa.message.text = error.data.blockNote || error.data.message;
			this.data2Fa.message.type = this.dic.CONSTANTS.msgTypeWarn;
		}
	}

	updateLoginStatus = (newStatus) => {
		this.loginStatus = newStatus;
		this.loginStatusChange.emit({ loginStatus: this.loginStatus});
	}
}
