import {GeneralService} from "../../../../../services/generalService";
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import _ from "lodash";
import util from "util";
import {DICTIONARY} from "../../../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {eachLimit} from 'async';


@Component({
	selector: 'outbound-rules-component',
	templateUrl: './outbound-rules.component.html',
})
export class OutboundRulesComponent implements OnInit {
    constructor(public gs: GeneralService,
				private rs: RouteService,
				private ns: NotificationService) {
    }

    format = util.format;
    dic = DICTIONARY;
    rulesOptions = this.dic.CONSTANTS.rules;
    userRules = [];
    showRuleResultOptions = false;
    newRule;
    userPolicy;
    getRulesDataInProcess;
    createNewRulesInProcess;
    prepareRuleInProcess;
    invalidRuleName;
    invalidResults = false;
    invalidExpirationDays;
    invalidSubjectPrefix;
    invalidResultFooter;
    invalidResultHeader;
    invalidCcEmails;
    invalidBccEmails;
    ruleUsagePopupData;
    assignRuleToAdminPopup;
    assignToAdminInProcess;
    preferTokenization;
    doNotDeleteTypes;
    showGroups;
	isPartnerAdmin: boolean
    fileTypes;
    subCustomizationNames;
    sensitivity_types;
    dynamicFieldExample = '{{SENDER_EMAIL}}';
	userInfo;
	quickRulesPopup: any;
	selectedQuickRule = this.dic.CONSTANTS.outboundQuickRules.global;
	quickRulesBody: any;
	quickRules = [
		{
			type: 'global',
			name: 'Global Quick Rules',
			description: 'Global rules are designed to protect sensitive information.',
			rules: [
				{
					name: "Sensitive Data Protection (External)",
					description: "Ensures secure handling of sensitive data when sent to external recipients",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA",
									"HIPAA",
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "external"
							},
						},
						{
							type: "sensitivity_score",
							sub_type: [
								"body"
							],
							data: {
								score_more: 3
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						add_footer: "Placeholder",
					},
				},
				{
					name: "Enhanced Security Measures (External)",
					description: "Implements advanced security protocols for highly sensitive external.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA",
									"HIPAA",
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "external"
							},
						},
						{
							type: "sensitivity_score",
							sub_type: [
								"body"
							],
							data: {
								score_more: 4
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						email_expiration_days: 30,
						default_authentication_method: "email"
					},
				},
				{
					name: "Max Security for Critical Data (External)",
					description: "Provides the highest level of security for critically sensitive data sent externally.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA",
									"HIPAA",
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "external"
							},
						},
						{
							type: "sensitivity_score",
							sub_type: [
								"body"
							],
							data: {
								score_more: 5
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						enforce_tls: true,
						disable_print: true,
						email_expiration_days: 7,
						add_header: "Placeholder",
						default_authentication_method: "email"
					},
				},
				{
					name: "Attachment Data Exfiltration Protection (External)",
					description: "Applies stringent security measures for sensitive attachments to protect data integrity and to avoid data exfiltration.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA",
									"HIPAA",
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "external"
							},
						},
						{
							type: "sensitivity_score",
							sub_type: [
								"body",
								"attachment"
							],
							data: {
								score_more: 5
							},
						}
					],
					exceptions: [],
					result: {
						block: true,
						keep_record: true,
						notify_admin: true,
					},
				},
				{
					name: "Internal Data Protection",
					description: "Secures sensitive information shared within the organization.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA",
									"HIPAA",
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc",
								"from"
							],
							data: {
								matchType: "internal"
							},
						},
						{
							type: "sensitivity_score",
							sub_type: [
								"body"
							],
							data: {
								score_more: 4
							},
						}
					],
					exceptions: [],
					result: {
						add_footer: "Placeholder",
					},
				},
				{
					name: "High Sensitivity Data Security (Internal)",
					description: "Enforces robust security practices for highly sensitive internal communications.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA",
									"HIPAA",
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc",
								"from"
							],
							data: {
								matchType: "internal"
							},
						},
						{
							type: "sensitivity_score",
							sub_type: [
								"body"
							],
							data: {
								score_more: 5
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						disable_print: true,
						email_expiration_days: 7,
						add_header: "Placeholder",
						default_authentication_method: "email"
					},
				},
			],
		},
		{
			type: 'healthcare',
			name: 'Healthcare Quick Rules',
			description: 'Healthcare rules are designed to protect sensitive healthcare information.',
			rules: [
				{
					name: "Secure Medical Data Transmission",
					description: "Implements strict security measures for emails containing highly sensitive medical data, including medical files, digital images of medicine, and patient information lists.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"HIPAA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Medical Digital Image",
									"Patient Information",
									"Medical File",
									"Medicare Information"
								]
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						enforce_tls: true,
						disable_print: true,
						email_expiration_days: 7,
						default_authentication_method: "email"
					},
				},
				{
					name: "Quarantine Medical Social Security Information Lists (Free-email domain)",
					description: "Enforces stringent security measures for emails containing social security information lists when sent to recipients using free-email domains, ensuring the data is not sent externally without review.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"HIPAA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Social Security Information List"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "free email domain"
							},
						}
					],
					exceptions: [],
					result: {
						block: true,
						keep_record: true,
						stop_rules: true,
						notify_admin: true,
					},
				},
				{
					name: "Secure Medical Social Security Information Lists (Except free-email domain)",
					description: "Applies stringent security measures for emails containing social security information lists when sent to recipients who are not using free-email domains.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"HIPAA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Social Security Information List"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "free email domain"
							},
							is_negate: true
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						enforce_tls: true,
						disable_print: true,
						email_expiration_days: 7,
						default_authentication_method: "email"
					},
				},
				{
					name: "Secure Personal Social Security Data Transmission",
					description: "Implements strict security measures for emails containing sensitive social security information, and individual social security numbers.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"HIPAA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Social Security Information",
									"United States - Social Security Information"
								]
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						enforce_tls: true,
						disable_print: true,
						email_expiration_days: 14,
						default_authentication_method: "email"
					},
				},
				{
					name: "HIPAA Compliance for High Sensitivity Data",
					description: "Implements HIPAA-compliant security measures for emails with a sensitivity score of 4 or above, ensuring the protection of highly sensitive healthcare information.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"HIPAA"
								]
							},
						},
						{
							type: "sensitivity_score",
							sub_type: [
								"body"
							],
							data: {
								score_more: 4
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						enforce_tls: true,
						disable_print: true,
					},
				}
			],
		},
		{
			type: 'finance',
			name: 'Finance Quick Rules',
			description: 'Finance rules are designed to protect sensitive financial information.',
			rules: [
				{
					name: "Quarantine Financial Information Lists (Free-email domain)",
					description: "Ensures rigorous handling of emails containing sensitive financial data, including credit card information lists and payment information lists, when sent to recipients using free-email domains, ensuring the data is not sent externally without review.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Credit Card Information",
									"Payment Information List"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "free email domain"
							},
						}
					],
					exceptions: [],
					result: {
						block: true,
						keep_record: true,
						stop_rules: true,
						notify_admin: true,
					},
				},
				{
					name: "Secure Financial Information Lists (Except free-email domain)",
					description: "Implements strict security measures for emails containing sensitive financial data, including credit card information lists and payment information lists, when sent to recipients who are not using free-email domains.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Credit Card Information",
									"Payment Information List"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "free email domain"
							},
							is_negate: true
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						enforce_tls: true,
						disable_print: true,
						email_expiration_days: 7,
						default_authentication_method: "email"
					},
				},
				{
					name: "Quarantine Credit Card Magnetic Stripe Information",
					description: "Implements quarantine measures for emails containing credit card magnetic stripe information to prevent unauthorized transmission and ensure compliance with security standards.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Credit Card Magnetic Stripe"
								]
							},
						}
					],
					exceptions: [],
					result: {
						block: true,
						keep_record: true,
						stop_rules: true,
						notify_admin: true,
					},
				},
				{
					name: "Secure Transmission for Government and Financial Data",
					description: "Implements robust security measures for emails containing sensitive government purchase card (SmartPay) information, credit card details, taxpayer identification numbers, and related financial data.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Government Purchase Card (SmartPay)",
									"Credit Card Information",
									"Credit Card Number",
									"Credit Card",
									"Individual Taxpayer Identification Number"
								]
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						enforce_tls: true,
						disable_print: true,
						email_expiration_days: 14,
						default_authentication_method: "email"
					},
				},
				{
					name: "Quarantine Sensitive Financial API Tokens (Free-email domain)",
					description: "Implements quarantine measures for emails containing sensitive API tokens (Stripe API Key, Amazon Marketplace Token, Square Secret Token, PayPal/Braintree Access Token) when sent to recipients using free email domains.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Stripe API Key",
									"Amazon Marketplace Token",
									"Square Secret Token",
									"PayPal/Braintree Access Token"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "free email domain"
							},
						}
					],
					exceptions: [],
					result: {
						block: true,
						keep_record: true,
						stop_rules: true,
						notify_admin: true,
					},
				},
				{
					name: "Secure Sensitive Financial API Tokens (Except free-email domain)",
					description: "Implements secure handling of emails containing sensitive API tokens (Stripe API Key, Amazon Marketplace Token, Square Secret Token, PayPal/Braintree Access Token) when sent to recipients using non-free email domains.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Stripe API Key",
									"Amazon Marketplace Token",
									"Square Secret Token",
									"PayPal/Braintree Access Token"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "free email domain"
							},
							is_negate: true
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						enforce_tls: true,
						disable_print: true,
						email_expiration_days: 14,
						default_authentication_method: "email"
					},
				},
				{
					name: "PCI and GLBA Data Protection for High Sensitivity Emails",
					description: "Implements stringent PCI and GLBA-related security measures for emails with a sensitivity score of 4 or above, ensuring the protection of highly sensitive information.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"PCI",
									"GLBA"
								]
							},
						},
						{
							type: "sensitivity_score",
							sub_type: [
								"body"
							],
							data: {
								score_more: 4
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						enforce_tls: true,
						disable_print: true,
					},
				},
			],
		},
		{
			type: 'education',
			name: 'Education Quick Rules',
			description: 'Education rules are designed to protect sensitive educational information.',
			rules: [
				{
					name: "Quarantine Students Social Security Information Lists (Free-email domain)",
					description: "Ensures rigorous handling of emails containing sensitive students social security lists when sent to recipients using free-email domains, ensuring the data is not sent externally without review.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Social Security Information List"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "free email domain"
							},
						}
					],
					exceptions: [],
					result: {
						block: true,
						keep_record: true,
						stop_rules: true,
						notify_admin: true,
					},
				},
				{
					name: "Secure Students Social Security Information Lists (Except free-email domain)",
					description: "Implements strict security measures for emails containing sensitive student social security information lists data when sent to recipients who are not using free-email domains.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Social Security Information List"
								]
							},
						},
						{
							type: "domains",
							sub_type: [
								"to",
								"cc"
							],
							data: {
								matchType: "free email domain"
							},
							is_negate: true
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						enforce_tls: true,
						disable_print: true,
						email_expiration_days: 7,
						default_authentication_method: "email"
					},
				},
				{
					name: "Secure Transmission for Student and Employer Information",
					description: "Implements comprehensive security measures for emails containing Student and Employer Information, including Social Security Information and Employer Identification Number, to ensure data protection.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA"
								]
							},
						},
						{
							type: "sensitivity_type",
							sub_type: [
								"body"
							],
							data: {
								sensitivity_types: [
									"Social Security Information",
									"International Machine-Readable Passport",
									"United States - Passport Number",
									"United Kingdom - Passport Number",
									"Canadian - Passport Number",
									"Employer Identification Number",
									"Passport Information",
									"United States - Social Security Information"
								]
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						secure_send: true,
						enforce_tls: true,
						disable_print: true,
						email_expiration_days: 14,
						default_authentication_method: "email"
					},
				},
				{
					name: "FERPA Compliance for High Sensitivity Data",
					description: "Implements FERPA-compliant security measures for emails with a sensitivity score of 4 or above, ensuring the protection of highly sensitive educational information.",
					conditions: [
						{
							type: "compliance",
							sub_type: [
								"email"
							],
							data: {
								compliance: [
									"FERPA"
								]
							},
						},
						{
							type: "sensitivity_score",
							sub_type: [
								"body"
							],
							data: {
								score_more: 4
							},
						}
					],
					exceptions: [],
					result: {
						encrypt_content: true,
						enforce_tls: true,
						disable_print: true,
					}
				}
			],
		}
	];

    ngOnInit() {
        this.getRulesDataInProcess = true;

        this.gs.getUserInfo(false, (userInfo) => {
			this.userInfo = userInfo;
             this.rs.getRulesAndPolicy().then((response) => {
				this.fileTypes = response.fileTypes;
				this.userPolicy = response.plan.policy;
				this.preferTokenization = response.plan.prefer_tokenization;
				this.subCustomizationNames = _.map(response.plan.customization.sub_customizations, 'name');
				this.userRules = response.plan.rules;

				this.prepareSensitivityTypes(response.sensitivityTypes, response.plan.personal_sensitivity_types);

				this.isPartnerAdmin = this.gs.isPartnerAdmin(userInfo);

				this.parseRulesResultAndExceptions(this.userRules);
				this.getRulesDataInProcess = false;
            }, (err) => {
				 this.getRulesDataInProcess = false;
			 });
        });
    }

	prepareSensitivityTypes = (sensitivityTypes, personalSensitivityTypes) => {
		this.sensitivity_types = [];
		sensitivityTypes.forEach((sensObj) => {
			this.sensitivity_types.push(sensObj);
			if (sensObj.sub_detector?.length) {
				sensObj.sub_detector.forEach((subDetector) => {
					this.sensitivity_types.push(subDetector);
				});
			}
		});

		if (personalSensitivityTypes?.length) {
			personalSensitivityTypes.forEach((sensObj) => {
				sensObj.type = 'Custom';
				sensObj.min_score = sensObj.sensitivity;
				sensObj.max_score = sensObj.sensitivity;
				this.sensitivity_types.push(sensObj);
			});
		}
	}

    createNewRule = () => {
        this.newRule = {
            conditions: [
				[this.getNewCondition()]
			],
            exceptions: [],
            result: _.cloneDeep(this.rulesOptions.defaultResult),
            resultShow: []
        };

        if (!this.userPolicy.allowed_auth_methods.includes(this.newRule.result.default_authentication_method.value)) {
            this.newRule.result.default_authentication_method.value = this.userPolicy.allowed_auth_methods[0];
        }

        this.createNewRulesInProcess = true;
    };

    addExceptionToRule() {
        if (!this.newRule.exceptions) {
            this.newRule.exceptions = [];
        }
        this.newRule.exceptions.push([this.getNewException()]);
    };

    addConditionToRule() {
        this.newRule.conditions.push([this.getNewCondition()]);
    };

    cancelNewRule() {
        this.resetNewRuleData();
    };

	addORtoCondition = (condition) => {
		condition.push(this.getNewCondition());
	}

	getNewCondition = () => {
		const defaultSubtype = _.cloneDeep(this.rulesOptions.types[this.rulesOptions.defaultCondition.type].subTypes);

		return _.cloneDeep({
			...this.rulesOptions.defaultCondition,
			subType: defaultSubtype,
			subTypeShow: this.parseSubType(defaultSubtype),
		})
	}

	getNewException = () => {
		const defaultSubtype = _.cloneDeep(this.rulesOptions.types[this.rulesOptions.defaultExcept.type].subTypes);

		return _.cloneDeep({
			...this.rulesOptions.defaultExcept,
			subType: defaultSubtype,
			subTypeShow: this.parseSubType(defaultSubtype),
		})
	}

    parseSubType(subType) {
        let res = [];
        _.map(subType, (value, key) => {
            if (value) {
                res.push(key);
            }
        });
        return res;
    };

    prepareRuleConditions(conditions) {
        let conditionError = false;

        let parsedConditions = _.map(conditions, conditionArr => {
            let data:any = {};

			// handle condition with "OR" connector(s)
			if (conditionArr.length > 1) {
				const orConditionBackendFormat = this.buildOrConditionforBE(conditionArr);
				const parsedOrConditions = this.prepareRuleConditions(orConditionBackendFormat.or_conditions);

				if (parsedOrConditions.error) {
					conditionError = true;
					return;
				}
				else {
					return {
						type: 'or_condition',
						sub_type: 'email',
						data: null,
						is_negate: false,
						or_conditions: parsedOrConditions.conditions
					}
				}
			}
			//

			const c = conditionArr[0] || conditionArr;

            switch (c.type) {
                case this.rulesOptions.types.sensitivity_score.name:
                    data.score_more = c.data.score_more;
                    break;

                case this.rulesOptions.types.sensitivity_type.name:
                    if (!c.data.sensitivity_types || !c.data.sensitivity_types.length) {
                         this.ns.showErrorMessage(this.dic.ERRORS.ruleSensitivityTypeEmpty);
                        conditionError = true;
                        c.invalidSensitivityType = true;
                        return;
                    }
                    c.invalidSensitivityType = false;
                    data.sensitivity_types = c.data.sensitivity_types;
                    break;

                case this.rulesOptions.types.compliance.name:
                    if (_.every(c.data.compliance, ['enabled', false])) {
                         this.ns.showErrorMessage(this.dic.ERRORS.ruleComplianceEmpty);
                        conditionError = true;
                        c.invalidCompliance = true;
                        return;
                    }
                    data.compliance = [];
                    _.each(c.data.compliance, (compVal, compKey) => {
                        if (compVal.enabled) {
                            data.compliance.push(compKey);
                        }
                    });
                    break;

                case this.rulesOptions.types.mta.name:
					if (!c.data.value?.length) {
						this.ns.showErrorMessage(util.format(this.dic.ERRORS.ruleResultEmptyAutoForward, (c.subType['IP'] ? 'IP addresses' : c.subType['CIDR'] ? 'CIDR addresses' : 'Hostnames')));
						conditionError = true;
						c.invalidValues = true;
						return;
					}

/*					if (c.subType['IP'] && !_.every(c.data.value, value => this.gs.isValidIPAddress(value))) {
						this.ns.showErrorMessage(this.dic.ERRORS.invalidIpAddress);
						conditionError = true;
						c.invalidValues = true;
						return;
					}
					if (c.subType['CIDR'] && !_.every(c.data.value, value => this.gs.isCIDRAddress(value, 24))) {
						this.ns.showErrorMessage(this.dic.ERRORS.invalidCidrAddress);
						conditionError = true;
						c.invalidValues = true;
						return;
					}*/

					data.value = c.data.value;
                    break;

				case this.rulesOptions.types.email_methods.name:
					if (_.every(c.data.methods, ['enable', false])) {
						this.ns.showErrorMessage(this.dic.ERRORS.ruleEmailMethodsEmpty);
						conditionError = true;
						c.invalidEmailMethods = true;
						return;
					}
					data.methods = [];
					c.data.methods.forEach((emailMethod) => {
						if (emailMethod.enable) {
							data.methods.push(emailMethod.key);
						}
					});
					break;

                case this.rulesOptions.types.keywords.name:
                    if (!c.data.keywords) {
                         this.ns.showErrorMessage(this.dic.ERRORS.ruleKeywordEmpty);
                        conditionError = true;
                        c.invalidKeywords = true;
                        return;
                    }
                    if (!(c.data.keywords instanceof Array)) {
                        data.keywords = c.data.keywords.split(',');
                        data.keywords = _.map(data.keywords, k => k.trim());
                    }
                    else {
                        data.keywords = c.data.keywords;
                        data.file = c.data.file;
                    }
                    data.matchType = c.data.matchType;
                    break;

                case this.rulesOptions.types.attachment_types.name:
                    if (!c.data.attachment_types && !c.data.all_types) {
                         this.ns.showErrorMessage(this.dic.ERRORS.ruleAttachmentsTypeEmpty);
                        conditionError = true;
                        c.invalidRuleEmailsTypes = true;
                        return;
                    }
                    else {
                        data.attachment_types = c.data.attachment_types;
                        data.all_types = c.data.all_types || undefined;
                    }
                    break;

                case this.rulesOptions.types.addresses.name:
                    data.matchType = c.data.matchType;
                    if (data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch ||
                        data.matchType === this.dic.CONSTANTS.ruleMatchType.startsWith ||
                        data.matchType === this.dic.CONSTANTS.ruleMatchType.endsWith ||
                        data.matchType === this.dic.CONSTANTS.ruleMatchType.contains) {
                        if (!c.data.emails) {
                             this.ns.showErrorMessage(this.dic.ERRORS.ruleEmailEmpty);
                            conditionError = true;
                            c.invalidEmails = true;
                            return;
                        }
                        if (!(c.data.emails instanceof Array)) {
                            data.emails = c.data.emails.split(',');
                            data.emails = _.map(data.emails, k => k.trim().toLowerCase());
                        }
                        else {
                            data.emails = c.data.emails;
                        }
                        if (data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
                            const emailsValid = this.gs.validateRuleEmails(data.emails);
                            if (!emailsValid) {
                                conditionError = true;
                                c.invalidEmails = true;
                                return;
                            }
                        }
                    }
                    else if (data.matchType === this.dic.CONSTANTS.ruleMatchType.belongsTo) {
                        if (!c.data.groups) {
                             this.ns.showErrorMessage(this.dic.ERRORS.ruleEmailEmpty);
                            conditionError = true;
                            return;
                        }
                        else {
                            data.groups = c.data.groups;
                        }
                    }
                    break;

                case this.rulesOptions.types.domains.name:
                case this.rulesOptions.types.all_domains.name:
                    data.matchType = c.data.matchType;
                    if (data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
                        if (!c.data.domains) {
                             this.ns.showErrorMessage(this.dic.ERRORS.ruleDomainEmpty);
                            conditionError = true;
                            c.invalidDomains = true;
                            return;
                        }
                        if (!(c.data.domains instanceof Array)) {
                            data.domains = c.data.domains.split(',');
                            data.domains = _.map(data.domains, k => k.trim());
                        }
                        else {
                            data.domains = c.data.domains;
                        }
                        if (data.matchType === c) {
                            const domainsValid = this.gs.validateRuleDomains(data.domains);
                            if (!domainsValid) {
                                conditionError = true;
                                c.invalidDomains = true;
                                return;
                            }
                        }
                    }
                    break;
                case this.rulesOptions.types.difference.name:
                    data.diff = c.data.diff;
                    break;
                case this.rulesOptions.types.groups.name:
                    if (!c.data.groups || !c.data.groups.length) {
                         this.ns.showErrorMessage(this.dic.ERRORS.ruleGroupsEmpty);
                        conditionError = true;
                        c.invalidGroups = true;
                        return;
                    }
                    c.invalidGroups = false;
                    data.groups = c.data.groups;
                    break;
                case this.rulesOptions.types.email_header.name:
                    if (!c.values || !c.values.key || !c.values.value) {
                         this.ns.showErrorMessage(this.dic.ERRORS.ruleEmailHeaderEmpty);
                        conditionError = true;
                        if (c.values && !c.values.key) {
                            c.invalidHeaderKey = true;
                        }
                        if (c.values && !c.values.value) {
                            c.invalidHeaderValue = true;
                        }
                        return;
                    }
                    c.invalidHeaderKey = false;
                    c.invalidHeaderValue = false;

                    if (!(c.values.value instanceof Array)) {
                        c.values.value = c.values.value.split(',');
                        c.values.value = _.map(c.values.value, k => k.trim());
                    }

                    data.matchType = c.data.matchType;
                    data.header = {
                        key: c.values.key,
                        value: c.values.value
                    };
                    break;
                case this.rulesOptions.types.total_unique_domains.name:
                    data.total_unique_domains = c.data.total_unique_domains;
                    break;
            }
            let subType;
            if (c.singleSubType) {
				if (c.type === this.rulesOptions.types.mta.name) {
					subType = (c.subType['IP'] ? 'ip' : c.subType['CIDR'] ? 'cidr' : 'freeText');
				}
				else {
					subType = c.subType;
				}
            }
            else {
                subType = this.parseSubType(c.subType);
                if (!subType || !subType.length) {
                     this.ns.showWarnMessage(this.dic.ERRORS.invalidConditionSubType);
                    conditionError = true;
                    c.invalidSubTypes = true;
                }
            }

            return {
                type: c.type,
                sub_type: subType,
                data: data,
				is_negate: c.is_negate || false
            };
        });
        return {
            error: conditionError,
            conditions: parsedConditions
        };
    };

	// convert or condition from UI structure to BE structure
	buildOrConditionforBE = (conditionArr) => {
		return {
			type: 'or_condition',
			sub_type: 'email',
			data: null,
			is_negate: false,

			or_conditions: conditionArr
		}
	}

    prepareRuleError() {
        this.prepareRuleInProcess = false;
    };

	selectMultipleRulesAction = (selectedRules, action) => {
		switch(action) {
			case this.dic.CONSTANTS.rulesActions.delete:
				this.openDeleteRulesPopup(selectedRules);
				break;

			case this.dic.CONSTANTS.rulesActions.enable:
				this.updateRuleState(selectedRules, true);
				break;

			case this.dic.CONSTANTS.rulesActions.disable:
				this.updateRuleState(selectedRules, false);
				break;

		}
	}
	showMultipleRulesActions = () => {
		return [
			this.dic.CONSTANTS.rulesActions.enable,
			this.dic.CONSTANTS.rulesActions.disable,
			this.dic.CONSTANTS.rulesActions.delete
		];
	}

    prepareRule() {
        if (!this.newRule) {
            return;
        }

        if (!this.newRule.name || this.newRule.name.length > 100) {
             this.ns.showErrorMessage(this.dic.ERRORS.ruleNameInvalid);
            this.prepareRuleError();
            this.invalidRuleName = true;
            return;
        }

        let ruleData:any = {};
        let conditionError;
        this.prepareRuleInProcess = true;

        let parsedConditions = this.prepareRuleConditions(this.newRule.conditions);
        conditionError = parsedConditions.error;

        if (conditionError) {
            this.prepareRuleError();
            return;
        }

        ruleData.conditions = parsedConditions.conditions;

        parsedConditions = this.prepareRuleConditions(this.newRule.exceptions);
        conditionError = parsedConditions.error;

        if (conditionError) {
            this.prepareRuleError();
            return;
        }

        ruleData.exceptions = parsedConditions.conditions;

        if (this.newRule.resultShow.length === 0) {
			this.prepareRuleError();
			this.invalidResults = true;
			this.ns.showErrorMessage(this.dic.ERRORS.ruleResultNotSelected);
            return;
        }

        if (this.newRule.result.email_expiration_days.enabled && (!this.newRule.result.email_expiration_days.value || this.newRule.result.email_expiration_days.value <= 0 || this.newRule.result.email_expiration_days.value > 365)) {
			this.prepareRuleError();
			this.invalidExpirationDays = true;
			this.ns.showErrorMessage(this.dic.ERRORS.expiredInputInvalid);
            return;
        }

        if (this.newRule.result.add_header && this.newRule.result.add_header.enabled && (!this.newRule.result.add_header.value || this.newRule.result.add_header.value.length > 10000)) {
			const msg = !this.newRule.result.add_header.value ? this.dic.ERRORS.ruleResultEmptyHeader : this.dic.ERRORS.ruleResultTooLongHeader;
			this.prepareRuleError();
			this.invalidResultHeader = true;
			this.ns.showErrorMessage(msg);
            return;
        }

        if (this.newRule.result.add_footer && this.newRule.result.add_footer.enabled && (!this.newRule.result.add_footer.value || this.newRule.result.add_footer.value.length > 10000)) {
			const msg = !this.newRule.result.add_footer.value ? this.dic.ERRORS.ruleResultEmptyFooter : this.dic.ERRORS.ruleResultTooLongFooter;
			this.prepareRuleError();
			this.invalidResultFooter = true;
			this.ns.showErrorMessage(msg);
            return;
        }

        if (this.newRule.result.subject_prefix && this.newRule.result.subject_prefix.enabled && (!this.newRule.result.subject_prefix.value || this.newRule.result.subject_prefix.value.length > 200)) {
			const msg = !this.newRule.result.subject_prefix.value ? this.dic.ERRORS.ruleResultEmptySubject : this.dic.ERRORS.ruleResultTooLongSubject;
			this.prepareRuleError();
			this.invalidSubjectPrefix = true;
			this.ns.showErrorMessage(msg);
            return;
        }

		if (this.newRule.result.allow_sending_email.enabled && this.newRule.result.block_allow_release_request.enabled) {
			this.prepareRuleInProcess = false;
			this.ns.showErrorMessage(this.dic.ERRORS.ruleResultBlockAllowAndRelease);
			return;
		}

        ruleData.result = {
            encrypt_content: this.newRule.result.encrypt_content.enabled,
            prefer_tokenization: this.newRule.result.prefer_tokenization.enabled,
            remove_email_sent_items: this.newRule.result.remove_email_sent_items.enabled,
            encrypt_attachments_only: this.newRule.result.encrypt_attachments_only.enabled,
            secure_send: this.newRule.result.secure_send.enabled,
            postmark: this.newRule.result.postmark.enabled,
            block: this.newRule.result.block.enabled,
			keep_record: this.newRule.result.keep_record.enabled,
			allow_sending_email: this.newRule.result.allow_sending_email.enabled,
			block_allow_release_request: this.newRule.result.block_allow_release_request.enabled,
			stop_rules: this.newRule.result.stop_rules.enabled,
            enforce_tls: this.newRule.result.enforce_tls.enabled,
            send_cc: this.newRule.result.send_cc,
            send_bcc: this.newRule.result.send_bcc,
            disable_email_wrapper: this.newRule.result.disable_email_wrapper.enabled,
            open_only_once: this.newRule.result.open_only_once.enabled,
            disable_print: this.newRule.result.disable_print.enabled,
            notify_admin: this.newRule.result.notify_admin.enabled,
            notify_sender: this.newRule.result.notify_sender.enabled,
            notify_sender_message: (this.newRule.result.notify_sender.enabled && this.newRule.result.notify_sender.message) || '',
            email_expiration_days: (this.newRule.result.email_expiration_days.enabled && this.newRule.result.email_expiration_days.value) || 0,
            add_header: (this.newRule.result.add_header.enabled && this.newRule.result.add_header.value) || '',
            add_footer: (this.newRule.result.add_footer.enabled && this.newRule.result.add_footer.value) || '',
            subject_prefix: (this.newRule.result.subject_prefix.enabled && this.newRule.result.subject_prefix.value) || '',
            apply_lf: (this.newRule.result.apply_lf.enabled && this.newRule.result.apply_lf.value) || '',
            remove_keyword: this.newRule.result.remove_keyword.enabled || false,
            skip_remove_enc_content: this.newRule.result.skip_remove_enc_content.enabled || false,
			disable_secure_reply: this.newRule.result.disable_secure_reply.enabled || false,
			is_monitor: this.newRule.result.is_monitor.enabled || false,
            default_authentication_method: this.newRule.result.secure_send.enabled ? this.newRule.result.default_authentication_method.value : ''
        };

        if (ruleData.result.encrypt_content && ruleData.result.encrypt_attachments_only) {
            this.prepareRuleError();
			this.invalidResults = true;
			this.ns.showErrorMessage(this.dic.ERRORS.ruleResultConflict);
            return;
        }

        if (ruleData.result.send_cc.enabled) {
            if (!(ruleData.result.send_cc.emails instanceof Array)) {
                ruleData.result.send_cc.emails = ruleData.result.send_cc.emails.split(',');
                ruleData.result.send_cc.emails = _.map(ruleData.result.send_cc.emails, e => e.trim());
            }

            if (!ruleData.result.send_cc.emails.length) {
				this.ns.showErrorMessage(this.dic.ERRORS.ruleResultEmptyCc);
				this.prepareRuleError();
				this.invalidCcEmails = true;
				return;
            }

            const isValid = this.gs.validateRuleEmails(ruleData.result.send_cc.emails);
            if (!isValid) {
                this.prepareRuleError();
                this.invalidCcEmails = true;
                return;
            }
            ruleData.result.send_cc = ruleData.result.send_cc.emails;
        }
        else {
            ruleData.result.send_cc = [];
        }

        if (ruleData.result.send_bcc.enabled) {
            if (!(ruleData.result.send_bcc.emails instanceof Array)) {
                ruleData.result.send_bcc.emails = ruleData.result.send_bcc.emails.split(',');
                ruleData.result.send_bcc.emails = _.map(ruleData.result.send_bcc.emails, e => e.trim());
            }
            if (!ruleData.result.send_bcc.emails.length) {
				this.ns.showErrorMessage(this.dic.ERRORS.ruleResultEmptyBcc);
				this.prepareRuleError();
				this.invalidBccEmails = true;
                return;
            }

            const isValid = this.gs.validateRuleEmails(ruleData.result.send_bcc.emails);
            if (!isValid) {
                this.prepareRuleError();
                this.invalidBccEmails = true;
                return;
            }
            ruleData.result.send_bcc = ruleData.result.send_bcc.emails;
        }
        else {
            ruleData.result.send_bcc = [];
        }

        ruleData.name = this.newRule.name;
        ruleData.strict = this.newRule.strict;

        if (!!this.newRule._id) {
            this.editRule(ruleData);
        }
        else {
            this.addRule(ruleData, null);
        }
    };

	closeQuickRulePopup = () => {
		this.quickRulesPopup = null;
		this.selectedQuickRule = this.dic.CONSTANTS.outboundQuickRules.global;
		this.quickRulesBody = null;
	}

	openQuickRulesPopup = (rulesType = this.selectedQuickRule) => {
		if (!this.quickRulesBody) this.generateQuickRuleBody(rulesType);

		this.quickRulesPopup = {
			title: 'Create Industry Rules',
			inProcess: false,
			show: true,
			doneCb: () => {
				const rulesData = this.quickRules.find(itm => itm.type === this.selectedQuickRule);

				this.closeQuickRulePopup();

				eachLimit(rulesData.rules, 1, (ruleData, callback) => {
					if (this.userRules.find(r => r.name === ruleData.name)) {
						this.ns.showErrorMessage("A rule with the same name already exists")
						callback();
						return;
					}

					const data = {
						name: ruleData.name,
						conditions: ruleData.conditions,
						exceptions: ruleData.exceptions,
						result: ruleData.result
					};

					this.addRule(data, callback);
				}, (err) => {
				});
			}
		}
	}

	generateQuickRuleBody = (rulesType) => {
		this.selectedQuickRule = rulesType;

		const rulesData = this.quickRules.find(itm => itm.type === rulesType);
		if (!rulesData.rules.length) {
			this.ns.showErrorMessage(`${rulesData.name} are currently not available`);
			return;
		}

		const body = [];
		rulesData.rules.forEach(rule => {
			body.push({name: rule.name, description: rule.description})
		})

		this.quickRulesBody = body;
	}

    addRule(ruleData, cb) {
		 this.rs.addRule(ruleData).then(response => {
			this.userRules = response.rules;
			this.resetNewRuleData();
			this.parseRulesResultAndExceptions(this.userRules);
			cb && cb();
		}, err => {
			this.prepareRuleInProcess = false;
		});
    };

	cloneRule(rule) {
		this.newRule = _.cloneDeep(rule);
		this.setRuleForEdit();
		this.newRule._id = null;
		this.newRule.name = `Clone of ${rule.name}`;
		this.createNewRulesInProcess = true;
	}

    startEditRule(rule) {
        this.newRule = _.cloneDeep(rule);
        this.setRuleForEdit();
        this.createNewRulesInProcess = true;
    };

    editRule(ruleData) {
        ruleData.action = this.rulesOptions.actions.update;

		 this.rs.updateRule(this.newRule._id, ruleData).then(response => {
			this.userRules = response.rules;
			this.resetNewRuleData();
			this.parseRulesResultAndExceptions(this.userRules);
		}, err => {
			this.prepareRuleInProcess = false;
		});
    };

    getRuleUsage(ruleObj) {
        this.ruleUsagePopupData = {
            show: true,
            ruleObj: ruleObj
        };
    };

	showRuleActions = () => {
		const actions = this.isPartnerAdmin ? [this.dic.CONSTANTS.outboundRulesActions.assignAdmin] : [];
		actions.push(this.dic.CONSTANTS.outboundRulesActions.clone);
		actions.push(this.dic.CONSTANTS.outboundRulesActions.edit);
		actions.push(this.dic.CONSTANTS.outboundRulesActions.delete);

		return actions;
	}

	selectRuleAction = (rule, action) => {
		switch (action) {
			case this.dic.CONSTANTS.outboundRulesActions.assignAdmin:
				this.openAssignRuleToAdminPopup(rule);
				break;

			case this.dic.CONSTANTS.outboundRulesActions.edit:
				this.startEditRule(rule);
				break;

			case this.dic.CONSTANTS.outboundRulesActions.clone:
				this.cloneRule(rule);
				break;

			case this.dic.CONSTANTS.outboundRulesActions.delete:
				this.openDeleteRulePopup(rule);
				break;
		}
	}

	updateRulePriority = (dragItem, draggedOnTopItem=null) => {
		if (!dragItem) {
			return;
		}

		const isMoveForward = draggedOnTopItem && this.userRules.indexOf(draggedOnTopItem) > this.userRules.indexOf(dragItem);

		const moveToIndex = draggedOnTopItem ? this.userRules.indexOf(draggedOnTopItem) - (isMoveForward ? 1 : 0) : this.userRules.length - 1;

		const ruleData:any = {
			action: this.rulesOptions.actions.move,
			idx: moveToIndex
		};

		this.rs.updateRule(dragItem._id, ruleData).then(response => {
			this.userRules = response.rules;
			this.parseRulesResultAndExceptions(this.userRules);
			this.ns.overwriteMessage(null, this.dic.MESSAGES.rulePriorityUpdated);
		});
	}

	updateRuleState = (rules, action) => {
		rules.forEach(rule => {
			const ruleData = {
				action: this.rulesOptions.actions.toggleState,
				enabled: action
			};

			this.rs.updateRule(rule._id, ruleData).then(response => {
				rule.enabled = ruleData.enabled;
			});
		});
	};


	openAssignRuleToAdminPopup(rule) {
		const userAdmins = _.cloneDeep(this.gs.planAdmins);
		userAdmins.shift(); // remove partner admin

        if (!userAdmins || !userAdmins.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.rulesNoOtherAdmins);
            return;
        }

        rule.strict = false;
        this.assignRuleToAdminPopup = {
            ruleToAssign: rule,
			isStrict: rule.strict,
            adminsList: userAdmins,
            selectedAdmin: null,
            show: true
        };
    };

    assignRuleToAdmin() {
        if (this.assignToAdminInProcess) {
            return;
        }
        if (!this.assignRuleToAdminPopup.selectedAdmin) {
             this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'admin'));
            return;
        }

        this.assignToAdminInProcess = true;
        const ruleToAssign = this.assignRuleToAdminPopup.ruleToAssign;

        const ruleData = {
            type: 'add',
			ruleId: ruleToAssign._id,
            strict: this.assignRuleToAdminPopup.isStrict
        };
         this.rs.configureRuleOnAdmin(this.assignRuleToAdminPopup.selectedAdmin.email, ruleData).then(response => {
            this.assignRuleToAdminPopup = null;
            this.assignToAdminInProcess = false;
        }, err => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                 this.ns.showErrorMessage(err.data.message);
            }
            this.assignToAdminInProcess = false;
        });
    };

    resetNewRuleData() {
        this.newRule = null;
        this.createNewRulesInProcess = false;
        this.prepareRuleInProcess = false;
    };

	openDeleteRulesPopup(rules) {
		this.gs.showPopup({
			title: `Delete ${rules.length} Rules`,
			subTitle: 'Please note - the selected rules will be deleted.',
			body: ["Rule will not be available for all future emails"],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				this.deleteRules(rules);
			}
		});
	};

    openDeleteRulePopup(rule) {
        this.gs.showPopup({
            title: `Delete Rule: ${rule.name}`,
            subTitle: 'Please note - the selected rule will be deleted.',
            body: ["Rule will not be available for all future emails"],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                this.deleteRule(rule);
            }
        });
    };

	deleteRules(rules) {
		let errDelete = 0;
		let deletePromises = [];

		rules.forEach(rule => {
			for (let i = 0; i < rule.conditions.length; i++) {
				if (rule.conditions[i].type === this.rulesOptions.types.keywords.name && rule.conditions[i].data.file) {
					errDelete++;
					return; // Skip deleting this rule
				}
			}

			// Push the delete promise to the array if the rule can be deleted
			let deletePromise = this.rs.deleteRule(rule._id).then(res => {
				_.remove(this.userRules, {_id: rule._id});
				return true; // Return true to indicate success
			}).catch(err => {
				console.error('Failed to delete rule:', err);
				return false; // Return false on failure
			});

			deletePromises.push(deletePromise);
		});

		Promise.all(deletePromises).then(results => {
			let successDelete = results.filter(result => result).length;
			if (errDelete > 0) {
				this.ns.showErrorMessage(`${errDelete} rules cannot be deleted`);
			}
			if (successDelete > 0) {
				this.ns.showInfoMessage(`${successDelete} rules deleted successfully`);
			}
		}).catch(error => {
			console.error('Error in processing rule deletions:', error);
		});
	}


	deleteRule(rule) {
        for (let i = 0; i < rule.conditions.length; i++) {
            if (rule.conditions[i].type === this.rulesOptions.types.keywords.name && rule.conditions[i].data.file) {
                 this.ns.showErrorMessage('Rule cannot be deleted');
                return;
            }
        }

		this.rs.deleteRule(rule._id).then(res => {
			_.remove(this.userRules, {_id: rule._id});
			this.ns.showInfoMessage(this.dic.MESSAGES.ruleDeleted);
		});
    };

    isNameChanged() {
         this.ns.closeMessage();
        this.invalidRuleName = false;
    };

    isResultChanged(resultKey = null) {
		this.ns.closeMessage();
        this.showRuleResultOptions = _.find<any>(this.newRule.result, itm => itm.enabled);

		console.log(resultKey);
        if (resultKey === this.rulesOptions.defaultResult.postmark.name && this.newRule.result.postmark.enabled) {
             this.ns.showWarnMessage(this.dic.MESSAGES.rulePostmarkResultWarning);
        }
        else if (resultKey === this.rulesOptions.defaultResult.apply_lf.name &&
            this.newRule.result.apply_lf.enabled) {
            if (!this.subCustomizationNames.length) {
				this.newRule.result.apply_lf.enabled = false;
				this.ns.showWarnMessage(this.dic.MESSAGES.ruleLFResultWarning);
				return;
            }
            this.newRule.result.apply_lf.value = this.subCustomizationNames[0];
        }

        this.parseNewRuleResult(resultKey);
        this.invalidResults = false;
    };

    isCcChanged() {
		this.ns.closeMessage();
        this.invalidCcEmails = false;
    };

    isBccChanged() {
		this.ns.closeMessage();
        this.invalidBccEmails = false;
    };

    isEmailExpirationDaysChanged() {
		this.ns.closeMessage();
        this.invalidExpirationDays = false;
    };

    isHeaderChanged() {
		this.ns.closeMessage();
        this.invalidResultHeader = false;
    };

    isFooterChanged() {
		this.ns.closeMessage();
        this.invalidResultFooter = false;
    };

    setRuleForEdit() {
        let result:any = _.cloneDeep(this.rulesOptions.defaultResult);
        result.encrypt_content.enabled = this.newRule.result.encrypt_content;
        result.prefer_tokenization.enabled = this.newRule.result.prefer_tokenization;
        result.remove_email_sent_items.enabled = this.newRule.result.remove_email_sent_items;
        result.encrypt_attachments_only.enabled = this.newRule.result.encrypt_attachments_only;
        result.secure_send.enabled = this.newRule.result.secure_send;
        result.postmark.enabled = this.newRule.result.postmark;
        result.block.enabled = this.newRule.result.block;
		result.keep_record.enabled = this.newRule.result.keep_record;
		result.stop_rules.enabled = this.newRule.result.stop_rules;
        result.enforce_tls.enabled = this.newRule.result.enforce_tls;
        result.allow_sending_email = {
            enabled: this.newRule.result.allow_sending_email
        };
		result.block_allow_release_request = {
			enabled: this.newRule.result.block_allow_release_request
		};

        result.send_cc = {
            enabled: this.newRule.result.send_cc && this.newRule.result.send_cc.length ? true : false,
            emails: this.newRule.result.send_cc
        };
        result.send_bcc = {
            enabled: this.newRule.result.send_bcc && this.newRule.result.send_bcc.length ? true : false,
            emails: this.newRule.result.send_bcc
        };
        result.disable_email_wrapper.enabled = this.newRule.result.disable_email_wrapper;
        result.open_only_once.enabled = this.newRule.result.open_only_once;
        result.disable_print.enabled = this.newRule.result.disable_print;
        result.notify_admin.enabled = this.newRule.result.notify_admin;
        result.notify_sender.enabled = this.newRule.result.notify_sender;
        result.notify_sender.message = this.newRule.result.notify_sender_message || '';
        result.email_expiration_days.enabled = this.newRule.result.email_expiration_days > 0;
        result.email_expiration_days.value = this.newRule.result.email_expiration_days || 1;
        result.add_header.enabled = typeof this.newRule.result.add_header === 'string' && this.newRule.result.add_header !== '';
        result.add_header.value = this.newRule.result.add_header || '';
        result.add_footer.enabled = typeof this.newRule.result.add_footer === 'string' && this.newRule.result.add_footer !== '';
        result.add_footer.value = this.newRule.result.add_footer || '';
        result.subject_prefix.enabled = typeof this.newRule.result.subject_prefix === 'string' && this.newRule.result.subject_prefix !== '';
        result.subject_prefix.value = this.newRule.result.subject_prefix || ''
        result.apply_lf.enabled = typeof this.newRule.result.apply_lf === 'string' && this.newRule.result.apply_lf !== '';
        result.apply_lf.value = this.newRule.result.apply_lf || '';
        result.remove_keyword.enabled = this.newRule.result.remove_keyword;
        result.skip_remove_enc_content.enabled = this.newRule.result.skip_remove_enc_content;
		result.disable_secure_reply.enabled = this.newRule.result.disable_secure_reply;
		result.is_monitor.enabled = this.newRule.result.is_monitor;
        result.default_authentication_method.value = this.newRule.result.default_authentication_method;

        this.newRule.result = result;

		this.newRule.conditions = this.parseEditRuleConditions(this.newRule.conditions, false);

        this.checkRemoveKeywords();

		this.newRule.exceptions = this.parseEditRuleConditions(this.newRule.exceptions, true);
    };

	parseEditRuleConditions = (conditions, isException) => {
		return _.map(conditions, conditionArr => {
			conditionArr.forEach(c => {
				c.subType = _.cloneDeep(this.rulesOptions.types[c.type].subTypes);
				c.singleSubType = c.subType instanceof Array && c.subType.length === 1;
				if (!c.singleSubType) {
					c.subType = _.mapValues(c.subType, (subTypeEnabled, subTypeKey) => {
						return c.sub_type.includes(subTypeKey);
					});
				}
				c.isException = isException;


				if (c.data && c.data.compliance) {
					let compliance = _.cloneDeep(this.rulesOptions.defaultCondition.data.compliance);
					compliance = _.mapValues(compliance, (complianceVal, complianceKey) => {
						complianceVal.enabled = c.data.compliance.includes(complianceKey);
						return complianceVal;
					});
					c.data.compliance = compliance;
					this.updateComplianceShow(c.data);
				}
				if (c.data?.methods) {
					let emailMethods = _.cloneDeep(this.rulesOptions.defaultCondition.data.methods);
					emailMethods = _.map(emailMethods, (method) => {
						method.enable = c.data.methods.includes(method.key);
						return method;
					});
					c.data.methods = emailMethods;
					this.updateEmailMethodsShow(c.data.methods);
				}
				if (c.data && c.data.header) {
					c.values = c.data.header;
					c.placeholder = this.rulesOptions.types.email_header.placeholder;
				}
				if (c.type === this.dic.CONSTANTS.rules.types.mta.name) {
					c.subType['IP'] = c.sub_type.includes('ip');
					c.subType['CIDR'] = c.sub_type.includes('cidr');
					c.subType['Hostname'] = c.sub_type.includes('freeText');
					c.singleSubType = true;
				}

				delete c.sub_type;
			});

			return conditionArr;
		});
	}

    parseRulesResultAndExceptions(rules) {
        this.doNotDeleteTypes = [];

        for (let i = 0; i < rules.length; i++) {
			rules[i].priority = i; // starts from 0
			rules[i].priorityDisplay = this.gs.addOrdinalNumberSuffix(i + 1);

            if (rules[i].conditions?.length) {
				// make all conditions arrays of conditions
				rules[i].conditions = _.map(rules[i].conditions, condition => {
					if (condition.or_conditions?.length) {
						return condition.or_conditions;
					}
					else {
						return [condition];
					}
				});

				rules[i].conditions.forEach(conditionArr => {
					conditionArr.forEach(cond =>{
						if (cond.type === this.rulesOptions.types.sensitivity_type.name) {
							cond.data.sensitivity_types.forEach(type => {
								if (_.find<any>(this.sensitivity_types, t => t.name === type && t.type === 'Custom')) {
									this.doNotDeleteTypes.push(type);
								}
							});
						}
						this.getRuleConditionText(cond);
					});
                });
            }

			if (rules[i].exceptions?.length) {
				// make all conditions arrays of conditions
				rules[i].exceptions = _.map(rules[i].exceptions, condition => {
					if (condition.or_conditions?.length) {
						return condition.or_conditions;
					}
					else {
						return [condition];
					}
				});

				rules[i].exceptions.forEach(conditionArr => {
					conditionArr.forEach(cond =>{
						if (cond.type === this.rulesOptions.types.sensitivity_type.name) {
							cond.data.sensitivity_types.forEach(type => {
								if (_.find<any>(this.sensitivity_types, t => t.name === type && t.type === 'Custom')) {
									this.doNotDeleteTypes.push(type);
								}
							});
						}
						this.getRuleConditionText(cond);
					});
				});
			}

            if (!rules[i].result) continue;
            rules[i].resultShow = [];
            let label;
            _.each(Object.keys(rules[i].result), (key) => {
                if (key === this.rulesOptions.defaultResult.notify_sender.notify_sender_message ||
                    key === this.rulesOptions.defaultResult.default_authentication_method.name) {
                    return;
                }
                if (key === this.rulesOptions.defaultResult.send_cc.name ||
                    key === this.rulesOptions.defaultResult.send_bcc.name) {
                    if (rules[i].result[key].length) {
                        rules[i].resultShow.push(this.rulesOptions.defaultResult[key].label);
                    }
                }
                else if (rules[i].result[key] && this.rulesOptions.defaultResult[key]) {
                    label = this.rulesOptions.defaultResult[key].label;
                    if (key === this.rulesOptions.defaultResult.secure_send.name) {
                        label += ` (${rules[i].result.default_authentication_method})`;
                    }
                    rules[i].resultShow.push(label);
                }
            });
        }
    };

    parseNewRuleResult(lastResultKey) {
        if (!this.newRule?.result) return;
		this.newRule.resultShow = [];

		switch (lastResultKey) {
			case this.rulesOptions.defaultResult.encrypt_content.name:
				if (this.newRule.result.encrypt_content.enabled) {
					this.newRule.result.encrypt_attachments_only.enabled = false;
				}
				else {
					this.newRule.result.prefer_tokenization.enabled = false;
				}
				break;

			case this.rulesOptions.defaultResult.encrypt_attachments_only.name:
				if (this.newRule.result.encrypt_attachments_only.enabled) {
					this.newRule.result.encrypt_content.enabled = false;
				}
				break;

			case this.rulesOptions.defaultResult.block.name:
				if (this.newRule.result.block.enabled) {
					this.newRule.result.keep_record.enabled = true;
				}
				else {
					this.newRule.result.allow_sending_email.enabled = false;
					this.newRule.result.block_allow_release_request.enabled = false;
					this.newRule.result.keep_record.enabled = false;
				}
				break;

			case this.rulesOptions.defaultResult.keep_record.name:
				if (!this.newRule.result.keep_record.enabled) {
					this.newRule.result.allow_sending_email.enabled = false;
					this.newRule.result.block_allow_release_request.enabled = false;
				}
				break;
		}

        _.each(Object.keys(this.newRule.result), key => {
            if (this.newRule.result[key] && this.newRule.result[key].enabled) {
                this.newRule.resultShow.push(this.rulesOptions.defaultResult[key].label);
            }
        });
    };

	updateEmailMethodsShow(emailMethods) {
		emailMethods.emailMethodsShow = [];
		emailMethods.emailMethodsShow = _.chain(emailMethods)
			.filter(method => method.enable)
			.map(method => {

				return method.key;
			})
			.value();
	}

    updateComplianceShow(conditionData) {
        conditionData.complianceShow = [];
        _.each(Object.keys(conditionData.compliance), (key) => {
            if (conditionData.compliance[key] && conditionData.compliance[key].enabled) {
                conditionData.complianceShow.push(key);
            }
        });
    };

    checkRemoveKeywords() {
        this.newRule.showRemoveKeywords = _.some(this.newRule.conditions, conditionArr => {
			return _.some(conditionArr, c => c.type === this.rulesOptions.types.keywords.name && c.subType?.subject && !c.is_negate)
		});

        if (!this.newRule.showRemoveKeywords && this.newRule.result.remove_keyword.enabled) {
            this.newRule.result.remove_keyword.enabled = false;
        }
    };

    changeAuthenticationMethodForRule(method) {
        if (!this.userPolicy.allowed_auth_methods.includes(method)) {
            return;
        }
        this.newRule.result.default_authentication_method.value = method;
    };

    getRuleConditionText(condition) {
        if (!condition) {
            return '';
        }
        condition.text = '';
		if (condition.type === this.rulesOptions.types.mta.name) {
			condition.text += `<span class="fw-500">${this.rulesOptions.types[condition.type].label}</span> <span class="fw-500">${condition.sub_type[0] === 'freeText' ? 'hostname' : _.upperCase(condition.sub_type[0])}</span>`;
		}
        else if (condition.sub_type && condition.sub_type[0] !== this.rulesOptions.subTypes.freeText) {
            condition.text += `<span class="fw-500">${this.rulesOptions.types[condition.type].label}</span> detected in <span class="fw-500">${condition.sub_type.join(', ')}</span>`;
        }
        else if (condition.sub_type && condition.sub_type[0] === this.rulesOptions.subTypes.freeText) {
            condition.text += `<span class="fw-500">${this.rulesOptions.types[condition.type].label}</span> detected in <span class="fw-500">${condition.data.header.key}</span>`;
        }
        if (condition.type === this.rulesOptions.types.sensitivity_score.name && condition.data.score_more !== undefined) {
            condition.text += ` is equal to or above <span class="fw-500">${condition.data.score_more}</span>`;
        }
        else if (condition.type === this.rulesOptions.types.sensitivity_type.name && condition.data.sensitivity_types) {
            condition.text += ` contains <span class="fw-500">${condition.data.sensitivity_types.join(', ')}</span>`;
        }
        else if (condition.type === this.rulesOptions.types.difference.name && condition.data.diff !== undefined) {
            condition.text += ` is equal to or above <span class="fw-500">${condition.data.diff}</span>`;
        }
        else if (condition.type === this.rulesOptions.types.compliance.name && condition.data.compliance) {
            condition.text += ` is <span class="fw-500">${condition.data.compliance.join(', ')}</span>`;
        }
        else if (condition.type === this.rulesOptions.types.mta.name && condition.data.value) {
            condition.text += ` is ${condition.data.value.length > 1 ? 'one of: ' : ''}<span class="fw-500">${condition.data.value.join(', ')}</span>`;
        }
		else if (condition.type === this.rulesOptions.types.email_methods.name && condition.data.methods) {
			condition.text += ` are <span class="fw-500">${condition.data.methods.join(', ')}</span>`;
		}
        else if (condition.type === this.rulesOptions.types.email_header.name && condition.data.header) {
			condition.text += ` ${this.parseResultToDisplay(condition, condition.data.header.value)}`;
        }
        else if (condition.type === this.rulesOptions.types.attachment_types.name) {
            if (condition.data.all_types)
                condition.text += ` are of any type`;
            else
                condition.text += ` contain <span class="fw-500">${condition.data.attachment_types.join(', ')}</span>`;
        }
        else if (condition.type === this.rulesOptions.types.groups.name && condition.data.groups) {
            condition.text += ` are `;
            for (let idx = 0; idx < condition.data.groups.length; idx++) {
                if (idx > 0) {
                    condition.text += `, `;
                }
                condition.text += `<span class="fw-500">${condition.data.groups[idx].displayName}</span>`;
            }
        }
        else if (condition.type === this.rulesOptions.types.keywords.name) {
			condition.text += ` ${this.parseResultToDisplay(condition, condition.data.keywords)}`;
        }
        else if (condition.type === this.rulesOptions.types.addresses.name) {
			if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.belongsTo) {
				condition.text += ` belongs to group/s: <span class="fw-500">${this.getDataGroupsNames(condition).join(', ')}</span>`;
			}
			else {
				condition.text += ` ${this.parseResultToDisplay(condition, condition.data.emails)}`;
			}
        }
        else if (condition.type === this.rulesOptions.types.domains.name || condition.type === this.rulesOptions.types.all_domains.name) {
			condition.text += ` ${this.parseResultToDisplay(condition, condition.data.domains)}`;
        }
        else if (condition.type === this.rulesOptions.types.total_unique_domains.name && condition.data.total_unique_domains !== undefined) {
            condition.text += ` is equal to or above <span class="fw-500">${condition.data.total_unique_domains}</span>`;
        }

		// add connection words to negative condition
		if (condition.is_negate) {
			this.editNegativeConditionText(condition);
		}

		// shorten very long text
		if (condition.text.length > 400) {
			condition.text = condition.text.substring(0,400);
			if (condition.text.lastIndexOf(', ') > -1) {
				condition.text = condition.text.substring(0,condition.text.lastIndexOf(', '));
			}
			condition.text += '...';
		}
		else {
			condition.text += '.';
		}
	};

	parseResultToDisplay(condition, values) {
		switch (condition.data.matchType) {
			case this.dic.CONSTANTS.ruleMatchType.exactMatch:
				return `are <span class="fw-500">${values.join(', ')}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.startsWith:
				return `starts with <span class="fw-500">${values.join(', ')}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.endsWith:
				return `ends with <span class="fw-500">${values.join(', ')}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.contains:
				return `contains <span class="fw-500">${values.join(', ')}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.regex:
				return `match regex <span class="fw-500">${condition.data.regex}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.internal:
				return `are <span class="fw-500">internal</span>`;

			case this.dic.CONSTANTS.ruleMatchType.external:
				return `are <span class="fw-500">external</span>`;

			default:
				return `are <span class="fw-500">${condition.data.matchType}</span>`;
		}
	}


	editNegativeConditionText(condition) {
		const connectionWords = ['is', 'are', ..._.values(this.dic.CONSTANTS.ruleMatchType), 'contain'];
		const firstConnectionWord = findFirstOccurrence(connectionWords, condition.text);

		if (firstConnectionWord) {
			const index = condition.text.indexOf(firstConnectionWord);

			if (firstConnectionWord === 'is' || firstConnectionWord === 'are') {
				condition.text = condition.text.slice(0, index + firstConnectionWord.length) + ` <span class="fw-bold text-danger">NOT</span>` + condition.text.slice(index + firstConnectionWord.length);
			}
			else {
				condition.text = condition.text.slice(0, index - 1) + ' <span class="fw-bold text-danger">' + (this.rulesOptions.types[condition.type].isPlural ? 'DO' : 'DOES') + ' NOT</span> ' + condition.text.slice(index);
				if (firstConnectionWord.endsWith('s')) {
					condition.text = condition.text.replace(firstConnectionWord, firstConnectionWord.slice(0, -1));
				}
			}
		}
	}

    getDataGroupsNames(condition) {
        return _.map(condition.data.groups, g => {return g.displayName});
    }

}

function findFirstOccurrence(words, text) {
	return words.reduce((first, word) => {
		const index = text.indexOf(word);
		return index !== -1 && (first.index === -1 || index < first.index) ? { word, index } : first;
	}, { word: null, index: -1 }).word;
}
