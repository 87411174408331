import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() model: any;
  @Output() modelChange = new EventEmitter();
  @Input() isDisabled: boolean;
  @Input() description: string;
  @HostListener('click', ['$event']) click(event) {
    event.preventDefault();
    this.fireClick();
  }

  fireClick() {
    if (this.isDisabled) {
      return;
    }

    this.modelChange.emit(!this.model);
  }
}
