import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import {GeneralService} from "../../../../../services/generalService";
import {DICTIONARY} from "../../../../../dictionary";
import {Component, OnInit, ViewChild} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {LookAndFeelService} from "../../../../../services/lookAndFeelService";
import {ChartComponent} from "ng-apexcharts";


@Component({
	selector: 'inbound-email-rules-component',
	templateUrl: './inbound-email-rules.component.html',
})
export class InboundEmailRulesComponent implements OnInit {
    constructor(public gs:GeneralService,
				private rs:RouteService,
				private lfs:LookAndFeelService,
				private ns:NotificationService,
				private clipboard: ClipboardService){
    }

	@ViewChild('emailsDetectedChart', {static: false}) emailsDetectedChart: ChartComponent;
	@ViewChild('topSendersChart', {static: false}) topSendersChart: ChartComponent;
	@ViewChild('topRecipientMailboxesChart', {static: false}) topRecipientMailboxesChart: ChartComponent;

	format = util.format;
    dic = DICTIONARY;

    viewTypes = {chart: 'Chart', list: 'List'};

    trendsPeriod = [
        this.dic.CONSTANTS.trendsPeriod.lastDay,
        this.dic.CONSTANTS.trendsPeriod.last3Days,
        this.dic.CONSTANTS.trendsPeriod.lastWeek,
        this.dic.CONSTANTS.trendsPeriod.lastMonth,
    ];

    userInfo;
    config;
    allowInboundRules;
    fileTypes;
	tpMetrics;
    newRule;
	getRulesDataInProcess
    createNewRulesInProcess;
    prepareRuleInProcess;
    ruleUsagePopup;
    getRuleUsageInProcess;
    emailDetectedByRuleChartOptions;
    senderGraphOptions;
    recipientGraphOptions;
    ruleEmailIdCopied;
    invalidRuleName;
    invalidResults = false;
    invalidAutoForwardEmails;
    invalidRedirectEmails;
    invalidAutoReply;
	invalidSubjectText;
	invalidFooterText;
	invalidSubRuleName;
    addMailboxReplyPopup;
	warningLabelPopup;
	previewWarningLabelPopup;
	subCustomizationNames;

	summernoteConfig = {
		height: 220,
		codemirror: {
			theme: 'monokai'
		},
		callbacks: {
			onKeydown: (e) => {
				if (e.keyCode !== 8 && e.keyCode !== 127 && document.querySelector(".note-editable").textContent.length >= 500){
					e.preventDefault();
				}
			}
		},
		placeholder: 'HTML/text'
	};

	summernoteConfigWarningLabel = {
		height: 100,
		toolbar: [
			['style', ['bold', 'italic', 'underline']],
			['fontsize', ['fontsize']],
			['view', ['codeview']]
		],
		codemirror: {
			theme: 'monokai'
		},
		callbacks: {
			onKeydown: (e) => {
				if (e.keyCode !== 8 && e.keyCode !== 127 && document.querySelector(".note-editable").textContent.length >= 500){
					e.preventDefault();
				}
			}
		},
		placeholder: 'HTML/text'
	};

    ngOnInit() {
		this.getInboundEmailRules();

        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
			this.subCustomizationNames = _.map(userInfo.plan.customization.sub_customizations, 'name');
        });
    };

    getInboundEmailRules = () => {
		this.getRulesDataInProcess = true;
		this.rs.getInboundEmailRules().then( (response) => {
            this.config = response;

            this.allowInboundRules = (this.config.inbound_relay && (
                this.config.inbound_relay.enabled || this.config.inbound_relay.sending_architecture === 'mx'));

            this.fileTypes = response.fileTypes;

			this.tpMetrics = [];
			Object.keys(response.tpMetrics).forEach(key => {
				if (!this.tpMetrics.find(itm => itm.sub_status === response.tpMetrics[key].sub_status)) {
					this.tpMetrics.push(response.tpMetrics[key]);
				}
			});

			if (!this.config.inbound_email_rules) {
                this.config.inbound_email_rules = [];
            }

            this.parseRulesResultAndExceptions(this.config.inbound_email_rules);
			this.getRulesDataInProcess = false;
		}, (err) => {
			this.getRulesDataInProcess = false;
		});
    };

    createNewRule = () => {
        this.newRule = {
			conditions: [
				[this.getNewCondition()]
			],
			exceptions: [],
            result: _.cloneDeep(this.dic.CONSTANTS.inboundEmailRule.defaultResult),
            resultShow: []
        };

        this.createNewRulesInProcess = true;
    };

	addExceptionToRule() {
		if (!this.newRule.exceptions) {
			this.newRule.exceptions = [];
		}
		this.newRule.exceptions.push([this.getNewException()]);
	};

	addConditionToRule() {
		this.newRule.conditions.push([this.getNewCondition()]);
	};

	addORtoCondition = (condition) => {
		condition.push(this.getNewCondition());
	}

	getNewCondition = () => {
		const defaultSubtype = _.cloneDeep(this.dic.CONSTANTS.inboundEmailRule.types[this.dic.CONSTANTS.inboundEmailRule.defaultCondition.type].subTypes);

		return _.cloneDeep({
			...this.dic.CONSTANTS.inboundEmailRule.defaultCondition,
			subType: defaultSubtype,
			subTypeShow: this.parseSubType(defaultSubtype),
		});
	}

	getNewException = () => {
		const defaultSubtype = _.cloneDeep(this.dic.CONSTANTS.inboundEmailRule.types[this.dic.CONSTANTS.inboundEmailRule.defaultExcept.type].subTypes);

		return _.cloneDeep({
			...this.dic.CONSTANTS.inboundEmailRule.defaultExcept,
			subType: defaultSubtype,
			subTypeShow: this.parseSubType(defaultSubtype),
		});
	}

	showRuleActions = () => {
		return [
			this.dic.CONSTANTS.inboundRulesActions.edit,
			this.dic.CONSTANTS.inboundRulesActions.clone,
			this.dic.CONSTANTS.inboundRulesActions.delete
		];
	}

	selectRuleAction = (rule, action) => {
		switch (action) {
			case this.dic.CONSTANTS.inboundRulesActions.edit:
				this.startEditRule(rule);
				break;

			case this.dic.CONSTANTS.inboundRulesActions.clone:
				this.cloneRule(rule);
				break;

			case this.dic.CONSTANTS.inboundRulesActions.delete:
				this.openDeleteRulePopup(rule);
				break;
		}
	}

	selectMultipleRulesAction = (selectedRules, action) => {
		switch(action) {
			case this.dic.CONSTANTS.rulesActions.delete:
				this.openDeleteRulesPopup(selectedRules);
				break;

			case this.dic.CONSTANTS.rulesActions.enable:
				this.updateRuleState(selectedRules, true);
				break;

			case this.dic.CONSTANTS.rulesActions.disable:
				this.updateRuleState(selectedRules, false);
				break;

		}
	}
	showMultipleRulesActions = () => {
		return [
			this.dic.CONSTANTS.rulesActions.enable,
			this.dic.CONSTANTS.rulesActions.disable,
			this.dic.CONSTANTS.rulesActions.delete
		];
	}

	updateRulePriority = (dragItem, draggedOnTopItem=null) => {
		if (!dragItem) {
			return;
		}

		const isMoveForward = draggedOnTopItem && this.config.inbound_email_rules.indexOf(draggedOnTopItem) > this.config.inbound_email_rules.indexOf(dragItem);

		const moveToIndex = draggedOnTopItem ? this.config.inbound_email_rules.indexOf(draggedOnTopItem) - (isMoveForward ? 1 : 0) : this.config.inbound_email_rules.length - 1;

		const ruleData:any = {
			action: this.dic.CONSTANTS.inboundEmailRule.actions.move,
			idx: moveToIndex
		};

		this.rs.updateInboundEmailRule(dragItem._id, ruleData).then(response => {
			this.config.inbound_email_rules = response.rules;
			this.parseRulesResultAndExceptions(this.config.inbound_email_rules);
			this.ns.overwriteMessage(null, this.dic.MESSAGES.rulePriorityUpdated);
		});
	}

	updateRuleState = (rules, action) => {
		rules.forEach(rule => {
			const ruleData = {
				action: this.dic.CONSTANTS.inboundEmailRule.actions.toggleState,
				enabled: action
			};

			this.rs.updateInboundEmailRule(rule._id, ruleData).then(response => {
				rule.enabled = ruleData.enabled;
			});
		});
	};

    parseRulesResultAndExceptions = (rules) => {
        for (let i = 0; i < rules.length; i++) {
			rules[i].priority = i; // starts from 0
			rules[i].priorityDisplay = this.gs.addOrdinalNumberSuffix(i + 1);

            if (rules[i].conditions?.length) {
				// make all conditions arrays of conditions
				rules[i].conditions = _.map(rules[i].conditions, condition => {
					if (condition.or_conditions?.length) {
						return condition.or_conditions
					}
					else {
						return [condition]
					}
				});

				rules[i].conditions.forEach(conditionArr => {
					conditionArr.forEach(cond =>{
						this.getRuleConditionText(cond);
					});
				});
            }

			if (rules[i].exceptions?.length) {
				// make all conditions arrays of conditions
				rules[i].exceptions = _.map(rules[i].exceptions, condition => {
					if (condition.or_conditions?.length) {
						return condition.or_conditions
					}
					else {
						return [condition]
					}
				});

				rules[i].exceptions.forEach(conditionArr => {
					conditionArr.forEach(cond =>{
						this.getRuleConditionText(cond);
					});
				});
			}

            if (!rules[i].result) continue;
            rules[i].resultShow = [];
            _.each(Object.keys(rules[i].result), (key) => {
                if (key === this.dic.CONSTANTS.inboundEmailRule.defaultResult.notify_recipient.notify_recipient_message) {
					return;
				}
				if (key === 'send_cc' || key === 'send_bcc' || key === 'auto_forward' || key === 'redirect') {
                    if (rules[i].result[key].length) {
                        rules[i].resultShow.push(this.dic.CONSTANTS.inboundEmailRule.defaultResult[key].label);
                    }
                }
                else if (key === 'auto_reply') {
                    if (rules[i].result[key].subject && rules[i].result[key].body) {
                        rules[i].resultShow.push(this.dic.CONSTANTS.inboundEmailRule.defaultResult[key].label);
                    }
                }
				else if (key === 'add_warning_label') {
					if (rules[i].result[key].text) {
						rules[i].resultShow.push(this.dic.CONSTANTS.inboundEmailRule.defaultResult[key].label);
					}
				}
				else if (key === 'apply_sub_rules') {
					rules[i].resultShow.push(this.dic.CONSTANTS.inboundEmailRule.defaultResult[key].label + ": " + "<b class='break-word'>" + rules[i].result.apply_sub_rules + "</b>");
				}
                else if (rules[i].result[key]) {
                    rules[i].resultShow.push(this.dic.CONSTANTS.inboundEmailRule.defaultResult[key].label);
                }
            });
        }
    };

    resetNewRuleData = () => {
        this.newRule = null;
        this.createNewRulesInProcess = false;
        this.prepareRuleInProcess = false;
    };

	cancelNewRule() {
		this.resetNewRuleData();
	};

	cloneRule(rule) {
		this.newRule = _.cloneDeep(rule);
		this.setRuleForEdit();
		this.newRule._id = null;
		this.newRule.name = `Clone of ${rule.name}`;
		this.createNewRulesInProcess = true;
	}

	startEditRule(rule) {
		this.newRule = _.cloneDeep(rule);
		this.setRuleForEdit();
		this.createNewRulesInProcess = true;
	};

    showRulesUsage = (ruleObj) => {
        this.ruleUsagePopup = {
            ruleObj,
            usagePeriod: DICTIONARY.CONSTANTS.trendsPeriod.lastWeek,
            viewType: this.viewTypes.chart,
            emails: [],
            show: true
        };
        this.getRuleUsage(ruleObj);
    };

    getRuleUsage = (ruleObj) => {
        this.getRuleUsageInProcess = true;

        const ruleData = {
            action: this.dic.CONSTANTS.inboundEmailRule.actions.usage,
            period: this.ruleUsagePopup.usagePeriod.value,
			size: this.dic.CONSTANTS.quarantinedEmailsFirstBatchSize,
		};

        this.rs.updateInboundEmailRule(ruleObj._id.toString(), ruleData).then(response => {
            this.ruleUsagePopup.emails = response?.emails || [];

			this.prepareGraphInfo(false);
			this.getNextEmailsBatch(ruleObj, response, () => {
				this.getRuleUsageInProcess = false;
			});

        }, (err) => {
            this.getRuleUsageInProcess = false;
        });
    };

	prepareGraphInfo = (isUpdate) => {
		this.prepareEmailsRuleChart(this.ruleUsagePopup.ruleObj, this.ruleUsagePopup.emails, isUpdate);
		this.getEmailSenderChartInfo(this.ruleUsagePopup.emails, isUpdate);
		this.getEmailRecipientChartInfo(this.ruleUsagePopup.emails, isUpdate);
	}

	getNextEmailsBatch = (ruleObj, quarantineInfo, cb) => {
		if (!quarantineInfo.lastCreated) {
			return cb();
		}

		const ruleData = {
			action: this.dic.CONSTANTS.inboundEmailRule.actions.usage,
			period: this.ruleUsagePopup.usagePeriod.value,
			size: this.dic.CONSTANTS.quarantinedEmailsBatchSize,
			lastCreated: quarantineInfo.lastCreated
		};

		this.rs.updateInboundEmailRule(ruleObj._id.toString(), ruleData).then(response => {
			if (response.emails?.length) {
				this.ruleUsagePopup.emails = this.ruleUsagePopup.emails.concat(response.emails);
				this.prepareGraphInfo(true);
			}

			this.getNextEmailsBatch(ruleObj, response, cb);
		});
	}

    prepareEmailsRuleChart = (ruleObj, emails, isUpdate) => {

		const ruleUsage = {
			total: emails.length,
			labels: [],
			data: [[]],
			series: [ruleObj.name]
		};

		// @ts-ignore
		emails.sort((a, b) => new Date(a.created) - new Date(b.created));

		let rulesSourcePerWeek = {
			dateStart: null,
			dateEnd: null,
			total: 0,
		};

		for (let i = 0; i < emails.length; i++) {
			let currentSource = emails[i];

			if (!rulesSourcePerWeek.dateStart) {
				rulesSourcePerWeek = this.gs.calcDatesForPeriod(currentSource, this.ruleUsagePopup.usagePeriod.value);
				rulesSourcePerWeek.total = 0;
			}

			if (this.gs.isSamePeriod(this.ruleUsagePopup.usagePeriod.value, currentSource.created, rulesSourcePerWeek.dateEnd)) {
				rulesSourcePerWeek.total += 1;
			}
			else {
				this.addInboundRuleUsageData(ruleUsage, rulesSourcePerWeek, this.ruleUsagePopup.usagePeriod.value);

				rulesSourcePerWeek = this.gs.calcDatesForPeriod(currentSource, this.ruleUsagePopup.usagePeriod.value);
				rulesSourcePerWeek.total = 0;

				rulesSourcePerWeek.total += 1;
			}
		}
		this.addInboundRuleUsageData(ruleUsage, rulesSourcePerWeek, this.ruleUsagePopup.usagePeriod.value);


        const series = [];
        ruleUsage.series.forEach((categoryName, index) => {
            series.push({
                name: 'Emails',
                data: ruleUsage.data[index]
            });
        });

		if (isUpdate && this.emailDetectedByRuleChartOptions?.series?.length &&
			this.gs.areArraysEqual(ruleUsage.labels, this.emailDetectedByRuleChartOptions.xaxis.categories)) {
			this.emailsDetectedChart.updateSeries(series, true);
			return;
		}

        this.emailDetectedByRuleChartOptions = {
			series: series,
            chart: {
                type: ruleUsage.labels?.length === 1 ? 'bar' : 'area',
                height: '100%',
                toolbar: {
                    show: false
                },
                animations: {
                    animateGradually: {
                        enabled: false,
                    },
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    columnWidth: this.gs.getOptimalBarWidth(ruleUsage.labels.length) - 20 + '%',
                },
            },
            colors: ['#8177a3'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 4,
                colors: ['transparent']
            },
            xaxis: {
                categories: ruleUsage.labels,
            },
            yaxis: {
                title: {
                    text: 'Emails'
                },
                min: 0,
                labels: {
                    formatter: (val) => {
                        return val.toFixed(0);
                    }
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter:  (val) => {
                        return val.toFixed(0);
                    }
                }
            }
        };
    }

	addInboundRuleUsageData(ruleUsage, rulesSourcePerWeek, period) {
		if (rulesSourcePerWeek.dateStart) {
			const dateLabel = this.gs.getPeriodLabel(period, rulesSourcePerWeek);

			ruleUsage.labels.push(dateLabel);
			ruleUsage.data[0].push(rulesSourcePerWeek.total);
		}
	}

    getEmailSenderChartInfo = (emails, isUpdate) => {
        const senderMap = {};
        emails.forEach((emailObj) => {
            if (!senderMap[emailObj.sender]) {
                senderMap[emailObj.sender] = 0;
            }
            senderMap[emailObj.sender]++;
        });
        // @ts-ignore
        const sortedSenders = Object.entries(senderMap).sort((a, b) => b[1] - a[1]);
        const senderGraph = {
            data: [],
            labels: []
        };

        for (let i = 0; i < sortedSenders.length && i < 10; i++) {
            senderGraph.labels.push(sortedSenders[i][0]);
            senderGraph.data.push(sortedSenders[i][1]);
        }

		const series = [{name: "Emails", data: senderGraph.data}];

		if (isUpdate && this.senderGraphOptions?.xaxis?.categories?.length &&
			this.gs.areArraysEqual(senderGraph.labels, this.senderGraphOptions.xaxis.categories)) {
			this.topSendersChart.updateSeries(series, true);
			return;
		}

        this.senderGraphOptions = {
            series: series,
            chart: {
                type: 'bar',
                height: '100%',
                toolbar: {
                    show: false
                },
                animations: {
                    animateGradually: {
                        enabled: false,
                    },
                }
            },
            colors: [
                this.lfs.color
            ],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    barHeight: this.gs.getOptimalBarWidth(senderGraph.labels.length) + '%',
                }
            },
            dataLabels: {
                total: {
                    enabled: true,
                    offsetX: 0,
                    style: {
                        fontSize: '13px',
                        fontWeight: 900
                    }
                }
            },
            xaxis: {
                categories: senderGraph.labels,
            },
            yaxis: {
                labels: {
                    align: 'left',
                    minWidth: 140,
                    maxWidth: 140,
                }
            }
        }
    }

    getEmailRecipientChartInfo = (emails, isUpdate) => {
        const recipientMap = {};
        emails.forEach((emailObj) => {
            if (!recipientMap[emailObj.mailbox_email]) {
                recipientMap[emailObj.mailbox_email] = 0;
            }
            recipientMap[emailObj.mailbox_email]++;
        });
        // @ts-ignore
        const sortedRecipients = Object.entries(recipientMap).sort((a, b) => b[1] - a[1]);
        const recipientGraph = {
            data: [],
            labels: []
        };

        for (let i = 0; i < sortedRecipients.length && i < 10; i++) {
            recipientGraph.labels.push(sortedRecipients[i][0]);
            recipientGraph.data.push(sortedRecipients[i][1]);
        }

		const series = [{
			name: 'Emails',
			data: recipientGraph.data
		}];

		if (isUpdate && this.recipientGraphOptions?.labels?.length &&
			this.gs.areArraysEqual(recipientGraph.labels, this.recipientGraphOptions.xaxis.categories)) {
			this.topRecipientMailboxesChart.updateSeries(series, true);
			return;
		}

        this.recipientGraphOptions = {
            series: series,
            chart: {
                type: 'bar',
                height: '100%',
                toolbar: {
                    show: false
                },
                animations: {
                    animateGradually: {
                        enabled: false,
                    },
                }
            },
            colors: [
				this.lfs.color
            ],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    barHeight: this.gs.getOptimalBarWidth(recipientGraph.labels.length) + '%',
                }
            },
            dataLabels: {
                total: {
                    enabled: true,
                    offsetX: 0,
                    style: {
                        fontSize: '13px',
                        fontWeight: 900
                    }
                }
            },
            xaxis: {
                categories: recipientGraph.labels,
            },
            yaxis: {
                labels: {
                    align: 'left',
                    minWidth: 140,
                    maxWidth: 140,
                }
            },
            tooltip: {
                y: {
                    formatter:  (val) => {
                        return val.toFixed(0);
                    }
                }
            }
        }
    }



    changeViewType = (type) => {
        this.ruleUsagePopup.viewType = type;
    }

    changePeriod = (period) => {
        if (this.ruleUsagePopup.usagePeriod === period) {
            return;
        }
        this.ruleUsagePopup.usagePeriod = period;
        this.getRuleUsage(this.ruleUsagePopup.ruleObj);
    };

    searchRuleEmail =  (searchTxt) => {
        this.ruleUsagePopup.emails.forEach(record => {
            // search
            if (searchTxt) {
                const isFound = searchRuleUsageTextExecute(record, searchTxt);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    }

    copyEmailIdToClipboard =  (email) => {
        this.clipboard.copy(email.message_id);
        this.ruleEmailIdCopied = email.message_id;
    };


    exportRuleEmailsToCsv = (sortBy) => {
        if (!this.ruleUsagePopup.emails || !this.ruleUsagePopup.emails.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }
        let csvString = "Subject,From,Mailbox,Sent,Message ID\n";

        let sortedTable = _.filter(this.ruleUsagePopup.emails,log => {return !log.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        sortedTable.forEach((email) => {
            csvString += `"${email.subject}","${email.sender}",${email.mailbox_email},${email.created},${email.message_id || ''}\n`;
        });

        this.gs.exportCsv(csvString, `inbound_rule_emails.csv`);
    };

    setRuleForEdit = () => {
        let result:any = _.cloneDeep(this.dic.CONSTANTS.inboundEmailRule.defaultResult);
        result.auto_reply = {
            enabled: this.newRule.result.auto_reply.subject && this.newRule.result.auto_reply.body,
            subject: this.newRule.result.auto_reply.subject,
            body: this.newRule.result.auto_reply.body
        };
        result.auto_forward = {
            enabled: this.newRule.result.auto_forward?.length,
            emails: this.newRule.result.auto_forward
        };
        result.redirect = {
            enabled: this.newRule.result.redirect?.length,
            emails: this.newRule.result.redirect
        };
        result.quarantine.enabled = this.newRule.result.quarantine;
		result.release.enabled = this.newRule.result.release;
		result.keep_record.enabled = this.newRule.result.keep_record;
        result.notify_recipient.enabled = this.newRule.result.notify_recipient;
		result.notify_recipient.message = this.newRule.result.notify_recipient_message || '';
		result.recipient_allow_release_notification.enabled = this.newRule.result.recipient_allow_release_notification;
		result.add_auto_forward_subject_prefix.enabled = this.newRule.result.add_auto_forward_subject_prefix;
		result.auto_forward_change_sender_recipient.enabled = this.newRule.result.auto_forward_change_sender_recipient;
		result.notify_reviewer.enabled = this.newRule.result.notify_reviewer;
        result.stop_rules.enabled = this.newRule.result.stop_rules;
		result.skip_notify.enabled = this.newRule.result.skip_notify;
		result.apply_lf.enabled = typeof this.newRule.result.apply_lf === 'string' && this.newRule.result.apply_lf !== '';
		result.apply_lf.value = this.newRule.result.apply_lf || '';
		result.ignore_tp_rules.enabled = this.newRule.result.ignore_tp_rules;
		result.add_subject_text = {
			enabled: this.newRule.result.add_subject_text,
			text: this.newRule.result.add_subject_text
		};

		result.add_footer_text = {
			enabled: this.newRule.result.add_footer_text,
			text: this.newRule.result.add_footer_text
		};

		result.add_warning_label = {
			enabled: this.newRule.result.add_warning_label?.text,
			color: this.newRule.result.add_warning_label?.color,
			text: this.newRule.result.add_warning_label?.text,
		};

		result.apply_sub_rules = {
			enabled: !!this.newRule.result.apply_sub_rules,
			ruleName: this.newRule.result.apply_sub_rules,
		};

		this.newRule.result = result;

        this.newRule.conditions = this.parseEditRuleConditions(this.newRule.conditions, false);

        this.newRule.exceptions = this.parseEditRuleConditions(this.newRule.exceptions, true);
    };

	parseEditRuleConditions = (conditions, isException) => {
		return _.map(conditions, conditionArr => {
			conditionArr.forEach(c => {
				c.subType = _.cloneDeep(this.dic.CONSTANTS.inboundEmailRule.types[c.type].subTypes);
				c.singleSubType = c.subType instanceof Array && c.subType.length === 1;
				if (!c.singleSubType) {
					c.subType = _.mapValues(c.subType, (subTypeEnabled, subTypeKey) => {
						return c.sub_type.includes(subTypeKey);
					});
				}
				c.isException = isException;
				delete c.sub_type;

				if (c.data && c.data.header) {
					c.values = c.data.header;
					c.placeholder = this.dic.CONSTANTS.inboundEmailRule.types.email_header.placeholder;
				}

				if (c.data?.start) {
					c.data.received_time_start =  c.data.start.toString().padStart(2, '0');
					c.data.received_time_end = c.data.end.toString().padStart(2, '0');
				}

				if (c.data?.days) {
					c.data.received_day = _.map(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], dayOfTheWeek => {
						return {
							name: dayOfTheWeek,
							selected: c.data.days.includes(dayOfTheWeek)
						}
					});
				}
			});

			return conditionArr;
		});
	}

	openDeleteRulesPopup(rules) {
		this.gs.showPopup({
			title: `Delete ${rules.length} Rules`,
			subTitle: 'Please note - the selected rules will be deleted.',
			body: ["Rule will not be available for all future emails"],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				this.deleteRules(rules);
			}
		});
	};

    openDeleteRulePopup = (rule) => {
        this.gs.showPopup({
            title: `Delete Rule: ${rule.name}`,
            subTitle: 'Please note - the selected rule will be deleted.',
            body: ["Rule will not be available for all future emails"],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                this.deleteRule(rule);
            }
        });
    };

	deleteRules(rules) {
		let errDelete = 0;
		let deletePromises = [];

		rules.forEach(rule => {
			for (let idx = 0; idx < rule.conditions.length; idx++) {
				let cond = rule.conditions[idx];
				if (cond.type === this.dic.CONSTANTS.inboundEmailRule.types.keywords.name && cond.data.file) {
					errDelete++;
					return; // Skip deleting this rule
				}
			}

			// Push the delete promise to the array if the rule can be deleted
			let deletePromise = this.rs.deleteInboundEmailRule(rule._id).then(res => {
				_.remove(this.config.inbound_email_rules, {_id: rule._id});
				return true; // Return true to indicate success
			}).catch(err => {
				console.error('Failed to delete rule:', err);
				return false; // Return false on failure
			});

			deletePromises.push(deletePromise);
		});

		Promise.all(deletePromises).then(results => {
			let successDelete = results.filter(result => result).length;
			if (errDelete > 0) {
				this.ns.showErrorMessage(`${errDelete} rules cannot be deleted`);
			}
			if (successDelete > 0) {
				this.ns.showInfoMessage(`${successDelete} rules deleted successfully`);
			}
		}).catch(error => {
			console.error('Error in processing rule deletions:', error);
		});
	}

    deleteRule = (rule) => {
		for (let idx = 0; idx < rule.conditions.length; idx++) {
			let cond = rule.conditions[idx];
			if (cond.type === this.dic.CONSTANTS.inboundEmailRule.types.keywords.name && cond.data.file) {
				this.ns.showErrorMessage('Rule cannot be deleted');
				return;
			}
		}

        this.rs.deleteInboundEmailRule(rule._id).then(res => {
			_.remove(this.config.inbound_email_rules, {_id: rule._id});
            this.ns.showInfoMessage(this.dic.MESSAGES.ruleDeleted);
        });
    };

    isNameChanged = () => {
        this.ns.closeMessage();
        this.invalidRuleName = false;
    };

    parseNewRuleResult = (lastResult=null) => {
        if (!this.newRule?.result) return;
        this.newRule.resultShow = [];

		switch (lastResult) {
			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.auto_forward.name:
				if (this.newRule.result.auto_forward.enabled) {
					this.newRule.result.redirect.enabled = false;
				}
				else {
					this.newRule.result.add_auto_forward_subject_prefix.enabled = false;
					this.newRule.result.auto_forward_change_sender_recipient.enabled = false;
				}
				break;

			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.redirect.name:
				if (this.newRule.result.redirect.enabled) {
					this.newRule.result.auto_forward.enabled = false;
				}
				break;

			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.quarantine.name:
				if ((this.newRule.result.notify_recipient.enabled || this.newRule.result.notify_reviewer.enabled
						|| this.newRule.result.recipient_allow_release_notification.enabled)) {
					this.newRule.result.notify_recipient.enabled = false;
					this.newRule.result.recipient_allow_release_notification.enabled = false;
					this.newRule.result.notify_reviewer.enabled = false;
				}
				this.newRule.result.release.enabled = false;
				if (this.newRule.result.quarantine.enabled) {
					this.newRule.result.keep_record.enabled = true;
				}
				break;

			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.release.name:
				this.newRule.result.quarantine.enabled = false;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.notify_recipient.name:
				this.newRule.result.recipient_allow_release_notification.enabled = false;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.ignore_tp_rules.name:
				this.newRule.result.add_warning_label.enabled = false;
				this.newRule.result.add_footer_text.enabled = false;
				this.newRule.result.add_subject_text.enabled = false;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.add_warning_label.name:
			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.add_footer_text.name:
			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.add_subject_text.name:
				if (this.newRule.result.ignore_tp_rules.enabled) {
					this.newRule.result.add_warning_label.enabled = false;
					this.newRule.result.add_footer_text.enabled = false;
					this.newRule.result.add_subject_text.enabled = false;
					this.ns.showErrorMessage('Cannot change email content when \'Ignore Threat Prevention Rules\' is enabled');
				}
				break;

			case this.dic.CONSTANTS.inboundEmailRule.defaultResult.apply_lf.name:
				if (this.newRule.result.apply_lf.enabled) {
					if (!this.subCustomizationNames.length) {
						this.newRule.result.apply_lf.enabled = false;
						this.ns.showWarnMessage(this.dic.MESSAGES.ruleLFResultWarning);
						return;
					}
					this.newRule.result.apply_lf.value = this.subCustomizationNames[0];
				}
		}

        _.each(Object.keys(this.newRule.result), key => {
            if (this.newRule.result[key] && this.newRule.result[key].enabled) {
                this.newRule.resultShow.push(this.dic.CONSTANTS.inboundEmailRule.defaultResult[key].label);
            }
        });
    };

    isResultChanged = (result=null) => {
        this.ns.closeMessage();
		const resultKey = result?.key || result?.name;
        this.parseNewRuleResult(resultKey);
        this.invalidResults = false;
    };


    isAutoForwardChanged = () => {
        this.ns.closeMessage();
		this.isResultChanged(this.dic.CONSTANTS.inboundEmailRule.defaultResult.auto_forward);
        this.invalidAutoForwardEmails = false;
    };

    isRedirectChanged = () => {
        this.ns.closeMessage();
		this.isResultChanged(this.dic.CONSTANTS.inboundEmailRule.defaultResult.redirect);
        this.invalidRedirectEmails = false;
    };

    isAutoReplyChanged = () => {
        this.ns.closeMessage();
		this.isResultChanged(this.dic.CONSTANTS.inboundEmailRule.defaultResult.auto_reply);
        this.invalidAutoReply = false;
    };

	isSubjectChanged = () => {
        this.ns.closeMessage();
		this.isResultChanged(this.dic.CONSTANTS.inboundEmailRule.defaultResult.add_subject_text);
        this.invalidSubjectText = false;
    };

	isFooterChanged = () => {
        this.ns.closeMessage();
		this.isResultChanged(this.dic.CONSTANTS.inboundEmailRule.defaultResult.add_footer_text);
        this.invalidFooterText = false;
    };

    parseSubType = (subType) => {
        let res = [];
        _.map(subType, (value, key) => {
            if (value) {
                res.push(key);
            }
        });
        return res;
    };

    prepareRuleConditions = (conditions) => {
        let conditionError = false;
        this.newRule.conditionAddresses = [];

		let parsedConditions = _.map(conditions, conditionArr => {
            let data:any = {};

			// handle condition with "OR" connector(s)
			if (conditionArr.length > 1) {
				const orConditionBackendFormat = this.buildOrConditionforBE(conditionArr);
				const parsedOrConditions = this.prepareRuleConditions(orConditionBackendFormat.or_conditions);

				if (parsedOrConditions.error) {
					conditionError = true;
					return;
				}
				else {
					return {
						type: 'or_condition',
						sub_type: 'email',
						data: null,
						is_negate: false,
						or_conditions: parsedOrConditions.conditions
					}
				}
			}
			//

			const c = conditionArr[0] || conditionArr;

			switch (c.type) {
                case this.dic.CONSTANTS.inboundEmailRule.types.keywords.name:
					data.matchType = c.data.matchType;
					switch (data.matchType) {
						case this.dic.CONSTANTS.ruleMatchType.regex:
							if (!c.data.regex) {
								this.ns.showErrorMessage(this.dic.ERRORS.ruleKeywordEmpty);
								conditionError = true;
								c.invalidEmails = true;
								return;
							}
							data.regex = c.data.regex;
							break;

						case this.dic.CONSTANTS.ruleMatchType.exactMatch:
						case this.dic.CONSTANTS.ruleMatchType.contains:
							if (!c.data.keywords) {
								this.ns.showErrorMessage(this.dic.ERRORS.ruleKeywordEmpty);
								conditionError = true;
								c.invalidKeywords = true;
								return;
							}
							if (!(c.data.keywords instanceof Array)) {
								data.keywords = c.data.keywords.split(',');
								data.keywords = _.map(data.keywords, k => k.trim());
							} else {
								data.keywords = c.data.keywords;
								data.file = c.data.file;
							}
							break;

						case this.dic.CONSTANTS.ruleMatchType.empty:
							data.keywords = null;
					}
                    break;

                case this.dic.CONSTANTS.inboundEmailRule.types.attachment_types.name:
                    if (!c.data.attachment_types && !c.data.all_types) {
                        this.ns.showErrorMessage(this.dic.ERRORS.ruleAttachmentsTypeEmpty);
                        conditionError = true;
                        c.invalidAttachmentsTypes = true;
                        return;
                    }
                    else {
                        data.attachment_types = c.data.attachment_types;
                        data.all_types = c.data.all_types || undefined;
                    }
                    break;

                case this.dic.CONSTANTS.inboundEmailRule.types.sender_country_tld.name:
                    if (!c.data.tld) {
                        this.ns.showErrorMessage(this.dic.ERRORS.ruleSenderCountryTypeEmpty);
                        conditionError = true;
                        c.invalidSenderCountryTld = true;
                        return;
                    }
                    else {
                        data.tld = c.data.tld;
                    }
                    break;

				case this.dic.CONSTANTS.inboundEmailRule.types.email_scan_status.name:
					if (!c.data.matchType) {
						this.ns.showErrorMessage(this.dic.ERRORS.ruleEmailScanTypeEmpty);
						conditionError = true;
						c.invalidSenderCountryTld = true;
						return;
					}
					else {
						data.matchType = c.data.matchType;
					}
					break;

				case this.dic.CONSTANTS.inboundEmailRule.types.email_scan_sub_status.name:
					if (!c.data.tpMetrics) {
						this.ns.showErrorMessage(this.dic.ERRORS.ruleSenderCountryTypeEmpty);
						conditionError = true;
						c.invalidScanSubStatusTypes = true;
						return;
					}
					else {
						data.tpMetrics = c.data.tpMetrics;
					}
					break;

				case this.dic.CONSTANTS.inboundEmailRule.types.email_size.name:
					if (!c.data.size || c.data.size < 0) {
						this.ns.showErrorMessage(this.dic.ERRORS.ruleEmailSize);
						conditionError = true;
						c.invalidScanSubStatusTypes = true;
						return;
					}
					else {
						data.size = c.data.size;
					}
					break;

                case this.dic.CONSTANTS.inboundEmailRule.types.addresses.name:
                    data.matchType = c.data.matchType;
                    if (data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch ||
                        data.matchType === this.dic.CONSTANTS.ruleMatchType.startsWith ||
                        data.matchType === this.dic.CONSTANTS.ruleMatchType.endsWith ||
                        data.matchType === this.dic.CONSTANTS.ruleMatchType.contains) {
                        if (!c.data.emails) {
                            this.ns.showErrorMessage(this.dic.ERRORS.ruleEmailEmpty);
                            conditionError = true;
                            c.invalidEmails = true;
                            return;
                        }
                        if (!(c.data.emails instanceof Array)) {
                            data.emails = c.data.emails.split(',');
                            data.emails = _.map(data.emails, k => k.trim().toLowerCase());
                        }
                        else {
                            data.emails = c.data.emails;
                        }
                        if (data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
                            const emailsValid = this.gs.validateRuleEmails(data.emails);
                            if (!emailsValid) {
                                conditionError = true;
                                c.invalidEmails = true;
                                return;
                            }
                        }
                        this.newRule.conditionAddresses = this.newRule.conditionAddresses.concat(data.emails);
                    }
					if (data.matchType === this.dic.CONSTANTS.ruleMatchType.regex) {
						if (!c.data.regex) {
							this.ns.showErrorMessage(this.dic.ERRORS.ruleEmailEmpty);
							conditionError = true;
							c.invalidEmails = true;
							return;
						}
						data.regex = c.data.regex;
					}
                    break;

				case this.dic.CONSTANTS.inboundEmailRule.types.names.name:
					data.matchType = c.data.matchType;
					if (data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch ||
						data.matchType === this.dic.CONSTANTS.ruleMatchType.startsWith ||
						data.matchType === this.dic.CONSTANTS.ruleMatchType.endsWith ||
						data.matchType === this.dic.CONSTANTS.ruleMatchType.contains) {
						if (!c.data.names) {
							this.ns.showErrorMessage(this.dic.ERRORS.ruleNameEmpty);
							conditionError = true;
							c.invalidNames = true;
							return;
						}
						if (!(c.data.names instanceof Array)) {
							data.names = c.data.names.split(',');
							data.names = _.map(data.names, k => k.trim().toLowerCase());
						}
						else {
							data.names = c.data.names;
						}
						this.newRule.conditionAddresses = this.newRule.conditionAddresses.concat(data.names);
					}
					break;

                case this.dic.CONSTANTS.inboundEmailRule.types.domains.name:
                    data.matchType = c.data.matchType;
                    if (data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
                        if (!c.data.domains) {
                            this.ns.showErrorMessage(this.dic.ERRORS.ruleDomainEmpty);
                            conditionError = true;
                            c.invalidDomains = true;
                            return;
                        }
                        if (!(c.data.domains instanceof Array)) {
                            data.domains = c.data.domains.split(',');
                            data.domains = _.map(data.domains, k => k.trim());
                        }
                        else {
                            data.domains = c.data.domains;
                        }

                        if (data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
                            const domainsValid = this.gs.validateRuleDomains(data.domains);
                            if (!domainsValid) {
                                conditionError = true;
                                c.invalidDomains = true;
                                return;
                            }
                        }
                    }
                    break;

                case this.dic.CONSTANTS.inboundEmailRule.types.groups.name:
                    if (!c.data.groups || !c.data.groups.length) {
                        this.ns.showErrorMessage(this.dic.ERRORS.ruleGroupsEmpty);
                        conditionError = true;
                        c.invalidGroups = true;
                        return;
                    }
                    c.invalidGroups = false;
                    data.groups = c.data.groups;
                    break;

				case this.dic.CONSTANTS.inboundEmailRule.types.received_time.name:
					if (!c.data.received_time_start || !c.data.received_time_end || Number(c.data.received_time_start) > Number(c.data.received_time_end)
						|| (Number(c.data.received_time_start) === Number(c.data.received_time_end))) {
						this.ns.showErrorMessage(this.dic.ERRORS.ruleReceivedTimeInvalid);
						conditionError = true;
						c.invalidReceivedTime = true;
						return;
					}
					c.invalidReceivedTime = false;
					data.start = Number(c.data.received_time_start);
					data.end = Number(c.data.received_time_end);
					break;

				case this.dic.CONSTANTS.inboundEmailRule.types.received_day.name:
					if (!_.some(c.data.received_day, 'selected')) {
						this.ns.showErrorMessage(util.format(this.dic.ERRORS.noSelected, 'receiving day'));
						conditionError = true;
						c.invalidReceivedDay = true;
						return;
					}
					c.invalidReceivedDay = false;
					data.days = _.map(_.filter( c.data.received_day, 'selected'), 'name');
					break;

                case this.dic.CONSTANTS.inboundEmailRule.types.email_header.name:
                    if (!c.values || !c.values.key || !c.values.value) {
                        this.ns.showErrorMessage(this.dic.ERRORS.ruleEmailHeaderEmpty);
                        conditionError = true;
                        if (c.values && !c.values.key) {
                            c.invalidHeaderKey = true;
                        }
                        if (c.values && !c.values.value) {
                            c.invalidHeaderValue = true;
                        }
                        return;
                    }
                    c.invalidHeaderKey = false;
                    c.invalidHeaderValue = false;

                    if (!(c.values.value instanceof Array)) {
                        c.values.value = c.values.value.split(',');
                        c.values.value = _.map(c.values.value, k => k.trim());
                    }

                    data.matchType = c.data.matchType;
                    data.header = {
                        key: c.values.key,
                        value: c.values.value
                    };
                    break;
            }
            let subType;
            if (c.singleSubType) {
                subType = c.subType;
            }
            else {
                subType = this.parseSubType(c.subType);
                if (!subType || !subType.length) {
                    this.ns.showWarnMessage(this.dic.ERRORS.invalidConditionSubType);
                    conditionError = true;
                    c.invalidSubTypes = true;
                }
            }

            return {
                type: c.type,
                sub_type: subType,
                data: data,
				is_negate: c.is_negate || false
			};
        });
        return {
            error: conditionError,
            conditions: parsedConditions
        };
    };

	// convert or condition from UI structure to BE structure
	buildOrConditionforBE = (conditionArr) => {
		return {
			type: 'or_condition',
			sub_type: 'email',
			data: null,
			is_negate: false,

			or_conditions: conditionArr
		}
	}

    prepareRuleError = () => {
        this.prepareRuleInProcess = false;
    };

    prepareRule = () => {
        if (!this.newRule) {
            return;
        }

        if (!this.newRule.name || this.newRule.name.length > 100) {
            this.ns.showErrorMessage(this.dic.ERRORS.ruleNameInvalid);
            this.prepareRuleError();
            this.invalidRuleName = true;
            return;
        }

        let ruleData:any = {};
        let conditionError;

        let parsedConditions = this.prepareRuleConditions(this.newRule.conditions);
        conditionError = parsedConditions.error;

        if (conditionError) {
            this.prepareRuleError();
            return;
        }

        ruleData.conditions = parsedConditions.conditions;

        parsedConditions = this.prepareRuleConditions(this.newRule.exceptions);
        conditionError = parsedConditions.error;

        if (conditionError) {
            this.prepareRuleError();
            return;
        }

        ruleData.exceptions = parsedConditions.conditions;

        if (this.newRule.resultShow.length === 0) {
            this.prepareRuleError();
            this.invalidResults = true;
            this.ns.showErrorMessage(this.dic.ERRORS.ruleResultNotSelected);
            return;
        }

        ruleData.result = {
            auto_forward: this.newRule.result.auto_forward,
            redirect: this.newRule.result.redirect,
            auto_reply: this.newRule.result.auto_reply,
            quarantine: this.newRule.result.quarantine.enabled,
			release: this.newRule.result.release.enabled,
			keep_record: this.newRule.result.keep_record.enabled,
            notify_recipient: this.newRule.result.notify_recipient.enabled,
			notify_recipient_message: (this.newRule.result.notify_recipient.enabled && this.newRule.result.notify_recipient.message) || '',
			recipient_allow_release_notification: this.newRule.result.recipient_allow_release_notification.enabled,
			add_auto_forward_subject_prefix: this.newRule.result.add_auto_forward_subject_prefix.enabled,
			auto_forward_change_sender_recipient: this.newRule.result.auto_forward_change_sender_recipient.enabled,
			notify_reviewer: this.newRule.result.notify_reviewer.enabled,
            stop_rules: this.newRule.result.stop_rules.enabled,
			skip_notify: this.newRule.result.skip_notify.enabled,
			ignore_tp_rules: this.newRule.result.ignore_tp_rules.enabled,
			apply_lf: (this.newRule.result.apply_lf.enabled && this.newRule.result.apply_lf.value) || '',
			add_subject_text: this.newRule.result.add_subject_text.text,
			add_footer_text: this.newRule.result.add_footer_text.text,
			apply_sub_rules: this.newRule.result.apply_sub_rules.enabled && this.newRule.result.apply_sub_rules.ruleName || '',
			add_warning_label: this.newRule.result.add_warning_label,
		};

        if (ruleData.result.auto_forward.enabled) {
            if (!(ruleData.result.auto_forward.emails instanceof Array)) {
                ruleData.result.auto_forward.emails = ruleData.result.auto_forward.emails.split(',');
                ruleData.result.auto_forward.emails = _.map(ruleData.result.auto_forward.emails, e => e.trim());
            }
            if (!this.validateForwardRedirectRule(true, ruleData.result.auto_forward.emails)) {
                return;
            }
            ruleData.result.auto_forward = ruleData.result.auto_forward.emails;
        }
        else {
            ruleData.result.auto_forward = [];
        }

        if (ruleData.result.redirect.enabled) {
            if (!(ruleData.result.redirect.emails instanceof Array)) {
                ruleData.result.redirect.emails = ruleData.result.redirect.emails.split(',');
                ruleData.result.redirect.emails = _.map(ruleData.result.redirect.emails, e => e.trim());
            }
            if (!this.validateForwardRedirectRule(false, ruleData.result.redirect.emails)) {
                return;
            }
            ruleData.result.redirect = ruleData.result.redirect.emails;
        }
        else {
            ruleData.result.redirect = [];
        }

        if (ruleData.result.auto_reply.enabled) {
            if (!ruleData.result.auto_reply.subject || !ruleData.result.auto_reply.body) {
                this.ns.showErrorMessage(this.dic.ERRORS.ruleResultEmptyAutoReply);
                this.prepareRuleError();
                this.invalidAutoReply = true;
                return;
            }
        }
        else {
            ruleData.result.auto_reply.subject = '';
            ruleData.result.auto_reply.body = '';
        }

		if (this.newRule.result.add_subject_text.enabled) {
			if (!this.newRule.result.add_subject_text.text) {
				this.ns.showErrorMessage(this.dic.ERRORS.ruleResultEmptySubject);
				this.invalidSubjectText = true;
				return;
			}
		}
		else {
			ruleData.result.add_subject_text = '';
		}

		if (this.newRule.result.add_footer_text.enabled) {
			if (!this.newRule.result.add_footer_text.text) {
				this.ns.showErrorMessage(this.dic.ERRORS.ruleResultEmptyFooter);
				this.invalidFooterText = true;
				return;
			}
		}
		else {
			ruleData.result.add_footer_text = '';
		}

		if (this.newRule.result.apply_sub_rules.enabled) {
			if (!this.newRule.result.apply_sub_rules.ruleName) {
				this.ns.showErrorMessage(util.format(this.dic.ERRORS.noSelected, 'custom rule'));
				this.invalidSubRuleName = true;
				return;
			}
		}
		else {
			ruleData.result.add_footer_text = '';
		}

		if (this.newRule.result.add_warning_label.enabled) {
			if (!this.newRule.result.add_warning_label.text) {
				this.ns.showErrorMessage(this.dic.ERRORS.ruleResultEmptyWarningLabel);
				return;
			}
		}
		else {
			this.newRule.result.add_warning_label.text = '';
		}

		if (this.newRule.result.apply_sub_rules.enabled) {
			if (!this.newRule.result.apply_sub_rules.ruleName) {
				this.ns.showErrorMessage(util.format(this.dic.ERRORS.noSelected, 'custom Threat Prevention rules preset'));
				return;
			}
		}
		else {
			this.newRule.result.apply_sub_rules.ruleName = '';
		}

        ruleData.name = this.newRule.name;

		this.prepareRuleInProcess = true;

		if (!!this.newRule._id) {
            this.editRule(ruleData);
        }
        else {
            this.addRule(ruleData);
        }
    };

    validateForwardRedirectRule = (isAutoForward, emails) => {
        if (!emails.length) {
            this.ns.showErrorMessage(util.format(this.dic.ERRORS.ruleResultEmptyAutoForward, isAutoForward ? 'Auto Forward' : 'Redirect'));
            this.prepareRuleError();
            isAutoForward ? this.invalidAutoForwardEmails = true : this.invalidRedirectEmails = true;
            return false;
        }

        if (emails.length > 5) {
            this.ns.showErrorMessage(util.format(this.dic.ERRORS.mailboxRuleTooManyForward, isAutoForward ? 'forward' : 'redirect'));
            this.prepareRuleError();
            isAutoForward ? this.invalidAutoForwardEmails = true : this.invalidRedirectEmails = true;
            return false;
        }

        const isValid = this.gs.validateRuleEmails(emails);
        if (!isValid) {
            this.prepareRuleError();
            isAutoForward ? this.invalidAutoForwardEmails = true : this.invalidRedirectEmails = true;
            return false;
        }

        if (this.gs.hasDuplicates(emails)) {
            this.ns.showErrorMessage(util.format(this.dic.ERRORS.mailboxRuleDuplicateForward, isAutoForward ? 'Forward' : 'Redirect'));
            this.prepareRuleError();
            isAutoForward ? this.invalidAutoForwardEmails = true : this.invalidRedirectEmails = true;
            return false;
        }

        if (this.newRule.conditionAddresses && this.newRule.conditionAddresses.length) {
            if(_.intersection(this.newRule.conditionAddresses, emails).length > 0) {
                this.ns.showErrorMessage(this.dic.ERRORS.mailboxRuleConditionResultIntersection);
                this.prepareRuleError();
                isAutoForward ? this.invalidAutoForwardEmails = true : this.invalidRedirectEmails = true;
                return false;
            }
        }

        return true;
    };

    editRule = (ruleData) => {
        ruleData.action = this.dic.CONSTANTS.inboundEmailRule.actions.update;

        this.rs.updateInboundEmailRule(this.newRule._id, ruleData).then(response => {
            this.config.inbound_email_rules = response.rules;
            this.resetNewRuleData();
            this.parseRulesResultAndExceptions(this.config.inbound_email_rules);
        }, err => {
            this.prepareRuleInProcess = false;
        });
    };

    addRule = (ruleData) => {
        this.rs.addInboundEmailRule(ruleData).then(response => {
            this.config.inbound_email_rules = response.rules;
            this.resetNewRuleData();
            this.parseRulesResultAndExceptions(this.config.inbound_email_rules);
        }, err => {
            this.prepareRuleInProcess = false;
        });
    };

    editMailboxReplySettings = () => {
        this.addMailboxReplyPopup = {
            replyMailbox: {
                subject: this.newRule.result.auto_reply.subject || 'This is an automated reply',
                body: this.newRule.result.auto_reply.body || ''
            },
            show: true
        }
    };

    applyMailboxReplySettings = () => {
        if (!this.addMailboxReplyPopup.replyMailbox.subject || !this.addMailboxReplyPopup.replyMailbox.body) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'Subject or body fields'));
            return;
        }

        this.newRule.result.auto_reply.subject = this.addMailboxReplyPopup.replyMailbox.subject;
        this.newRule.result.auto_reply.body = this.addMailboxReplyPopup.replyMailbox.body;
        this.addMailboxReplyPopup = null;
    };

	showWarningPreview = () => {
		this.previewWarningLabelPopup = {
			show: true,
			borderColor: this.warningLabelPopup.color,
			backgroundColor: this.warningLabelPopup.color,
			text: this.warningLabelPopup.text,
		};
	};

	showWarningLabelPopup = () => {
		this.warningLabelPopup = {
			show: true,
			color: this.newRule.result.add_warning_label?.color || '#ff8c00',
			text: this.newRule.result.add_warning_label?.text || ''
		};
	};

	applyWarningLabel = () => {
		this.newRule.result.add_warning_label = {
			enabled: true,
			color: this.warningLabelPopup.color,
			text: this.warningLabelPopup.text,
		};

		this.warningLabelPopup = null;
	}

    getRuleConditionText = (condition) => {
        if (!condition) {
            return '';
        }

        condition.text = `<span class="fw-500">${this.dic.CONSTANTS.inboundEmailRule.types[condition.type].label}</span>`;
		condition.text += ` detected in <span class="fw-500">${condition.data.header?.key || condition.sub_type?.join(', ')}</span>`;

		switch (condition.type) {
			case this.dic.CONSTANTS.inboundEmailRule.types.keywords.name:
				condition.text += ` ${this.parseResultToDisplay(condition, condition.data.keywords)}`;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.addresses.name:
				condition.text += ` ${this.parseResultToDisplay(condition, condition.data.emails)}`;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.names.name:
				condition.text += ` ${this.parseResultToDisplay(condition, condition.data.names)}`;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.domains.name:
				condition.text += ` ${this.parseResultToDisplay(condition, condition.data.domains)}`;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.attachment_types.name:
				if (condition.data.all_types) {
					condition.text += ` are of any type`;
				}
				else {
					condition.text += ` contain <span class="fw-500">${condition.data.attachment_types.join(', ')}</span>`;
				}
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.sender_country_tld.name:
				condition.text += ` are <span class="fw-500">${condition.data.tld.join(', ')}</span>`;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.email_scan_status.name:
				condition.text += ` is <span class="fw-500">${condition.data.matchType}</span>`;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.email_scan_sub_status.name:
				condition.text += ` is <span class="fw-500">${condition.data.tpMetrics.join(', ')}</span>`;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.email_size.name:
				condition.text += ` is bigger than <span class="fw-500">${condition.data.size} KB</span>`;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.received_time.name:
				condition.text += ` is between <span class="fw-500">${condition.data.start.toString().padStart(2, '0') + ':00'}</span> and <span class="fw-500">${condition.data.end.toString().padStart(2, '0') + ':00'}</span>`;
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.received_day.name:
				if (condition.data.days.length > 1) {
					condition.text += ` is <span class="fw-500">${condition.data.days.slice(0, -1).join(', ')}</span>`;
					condition.text += ` or <span class="fw-500">${_.last(condition.data.days)}</span>`;
				}
				else {
					condition.text += ` is <span class="fw-500">${condition.data.days[0]}</span>`;
				}
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.groups.name:
				condition.text += ` are `;
				for (let idx = 0; idx < condition.data.groups.length; idx++) {
					if (idx > 0) {
						condition.text += `, `;
					}
					condition.text += `<span class="fw-500">${condition.data.groups[idx].displayName}</span>`;
				}
				break;

			case this.dic.CONSTANTS.inboundEmailRule.types.email_header.name:
				condition.text += ` ${this.parseResultToDisplay(condition, condition.data.header.value)}`;
				break;
		}

		// add connection words to negative condition
		if (condition.is_negate) {
			this.editNegativeConditionText(condition);
		}

		// shorten very long text
		if (condition.text.length > 400) {
			condition.text = condition.text.substring(0,400);
			if (condition.text.lastIndexOf(', ') > -1) {
				condition.text = condition.text.substring(0,condition.text.lastIndexOf(', '));
			}
			condition.text += '...';
		}
		else {
			condition.text += '.';
		}
    };

	parseResultToDisplay(condition, values) {
		switch (condition.data.matchType) {
			case this.dic.CONSTANTS.ruleMatchType.exactMatch:
				return `are <span class="fw-500">${values.join(', ')}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.startsWith:
				return `starts with <span class="fw-500">${values.join(', ')}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.endsWith:
				return `ends with <span class="fw-500">${values.join(', ')}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.contains:
				return `contains <span class="fw-500">${values.join(', ')}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.regex:
				return `match regex <span class="fw-500">${condition.data.regex}</span>`;

			case this.dic.CONSTANTS.ruleMatchType.internal:
				return `are <span class="fw-500">internal</span>`;

			case this.dic.CONSTANTS.ruleMatchType.external:
				return `are <span class="fw-500">external</span>`;

			default:
				return `are <span class="fw-500">${condition.data.matchType}</span>`;
		}
	}

	editNegativeConditionText(condition) {
		const connectionWords = ['is', 'are', ..._.values(this.dic.CONSTANTS.ruleMatchType), 'contain'];
		const firstConnectionWord = findFirstOccurrence(connectionWords, condition.text);

		if (firstConnectionWord) {
			const index = condition.text.indexOf(firstConnectionWord);

			if (firstConnectionWord === 'is' || firstConnectionWord === 'are') {
				condition.text = condition.text.slice(0, index + firstConnectionWord.length) + ` <span class="fw-bold text-danger">NOT</span>` + condition.text.slice(index + firstConnectionWord.length);
			}
			else {
				condition.text = condition.text.slice(0, index - 1) + ' <span class="fw-bold text-danger">' + (this.dic.CONSTANTS.inboundEmailRule.types[condition.type].isPlural ? 'DO' : 'DOES') + ' NOT</span> ' + condition.text.slice(index);
				if (firstConnectionWord.endsWith('s')) {
					condition.text = condition.text.replace(firstConnectionWord, firstConnectionWord.slice(0, -1));
				}
			}
		}
	}
}

function searchRuleUsageTextExecute(emailObj, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return ((emailObj.sender.toLowerCase().indexOf(searchTerm) > -1) ||
        (emailObj.mailbox_email.toLowerCase().indexOf(searchTerm) > -1) ||
        (emailObj.subject.toLowerCase().indexOf(searchTerm) > -1));
}


function findFirstOccurrence(words, text) {
	return words.reduce((first, word) => {
		const index = text.indexOf(word);
		return index !== -1 && (first.index === -1 || index < first.index) ? { word, index } : first;
	}, { word: null, index: -1 }).word;
}
