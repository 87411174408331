<div class="flxClmn bs-enabled">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container flxRow">
			<span class="page-title">Inbound Shield</span>
			<!--Information modal-->
			<more-info-c guideTitle="Threat Prevention Rules" guideUrl="https://docs.trustifi.com/docs/threat-prevention-rules">
				<div class="flxClmn vSpace05" style="flex: 1 0;">
					<span style="font-weight: bold;">Threat Prevention Rules</span>
					<ul class="vSpace1" style="margin-top: 0;">
						<li style="color:#484848;">
							<span>Choose automatic actions for malicious/suspicious/spam/external emails</span>
						</li>
						<li style="color:#484848;">
							<span>All potential threats are automatically quarantined</span>
						</li>
						<li style="color:#484848;">
							<span>You can choose to automatically remove quarantined emails or to release them</span>
						</li>
						<li style="color:#484848;">
							<span>Reviewers : email addresses in this list will receive a report when one of your users receives a malicious email</span>
						</li>
						<li style="color:#484848;">
							<span>When "Notify Reviewer" is selected, an email report will be sent to the relevant reviewers in the "Reviewers" list</span>
						</li>
					</ul>
				</div>
				<div class="flxClmn vSpace05" style="flex: 1 0;">
					<span style="font-weight: bold;">Inbound Email Rules</span>
					<ul class="vSpace1" style="margin-top: 0;">
						<li style="color:#484848;">
							<span>Set up for auto-forwarding of incoming emails or automatic replies</span>
						</li>
						<li style="color:#484848;">
							<span>Available only if Inbound Email Relay is enabled</span>
						</li>
						<li style="color:#484848;">
							<span>Rules are based on a priority and can be turned on or off</span>
						</li>
					</ul>
				</div>
			</more-info-c>
		</div>
		<div class="page-subtitle-container">
			<span class="page-subtitle">Set up rules and configure behavior for handling incoming emails</span>
		</div>
	</div>
	<div class="page-inner-content-holder gap-3 pt-0 flex-column" [ngClass]="{'no-scroll' : gs.getTabName() === dic.CONSTANTS.inboundShieldSettingsPageTabs.inboundEmailRules}">
		<!--Tabs-->
		<div class="md-tabs" [ngStyle]="{'--md-tabs-width': tabWidth + 'px'}">
			<div *ngFor="let tab of dic.CONSTANTS.inboundShieldSettingsPageTabs | keyvalue: gs.returnZero"
				 [ngClass]="{'selected' : gs.getTabName() === tab.value}"
				 [routerLink]="tab.value">
				<input type="radio" name="tabs" id="{{tab.key}}Toggle" [checked]="gs.getTabName() === tab.value">
				<label for="{{tab.key}}Toggle">
					<span>{{tab.value | camelCaseToTitleCase}}</span>
				</label>
			</div>

			<div class="slide" [ngStyle]="{'left' : tabWidth * _.values(dic.CONSTANTS.inboundShieldSettingsPageTabs).indexOf(gs.getTabName()) + 'px' || 0}"></div>
		</div>
		<!---->

		<router-outlet></router-outlet>
	</div>
</div>



