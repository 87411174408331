import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {Router} from "@angular/router";


@Component({
	selector: 'mass-list-page-component',
	templateUrl: './mass-list-page.component.html',
})
export class MassListPageComponent implements OnInit{
    constructor (private router:Router,
				 private clipboard: ClipboardService,
				 private rs:RouteService,
				 private ns:NotificationService,
				 public gs:GeneralService) {
    }

    dic = DICTIONARY;
    lists = [];
    loadingListsInProcess = true;
    listIndex = 0;
    corpName = this.gs.corpname;
    listsHeight = window.innerHeight - 250;
    blockSize = 55;
    filteredContactLists;
    activeMassList;
    searchListTxt;
    listToDelete;
    file;
	_ = _;



    ngOnInit() {
        this.loadingListsInProcess = true;
        this.rs.getAllLists().then((response) => {
            this.lists = _.filter(response, c => c.type === this.dic.CONSTANTS.listType.mass);

            if (this.lists.length > 0 && !window.history.state.list) {
                this.previewList(this.lists[0]);
                this.filteredContactLists = this.lists;
            }
            else if (window.history.state.list) {
                for (let idx = 0; idx < this.lists.length; idx++) {
                    if (window.history.state.list === this.lists[idx]._id) {
                        this.previewList(this.lists[idx]);
                        break;
                    }
                }

                this.filteredContactLists = this.lists;
            }

            this.loadingListsInProcess = false;
        }, (err) => {
			this.loadingListsInProcess = false;
		});
    };


    setCurrentListByIndex = (index, isArrowKeyOpen, isKeyDown) => {
        if (index < 0 || index > this.filteredContactLists.length) {
            return;
        }
        if (index === this.filteredContactLists.length) {
            index = 0;
        }
        this.listIndex = index;
        this.setCurrentList(this.filteredContactLists[index], isArrowKeyOpen, isKeyDown);
    };

    previewList = (list) => {
		if (this.activeMassList) {
			// dispose possible big data from memory. from previous active list
			this.activeMassList.previewList = null;
		}

        this.activeMassList = list;
        this.activeMassList.selected = true;
        this.activeMassList.soloSelected = true;
    }

    showListActions = (list) => {
        if (list.verification_status === this.dic.CONSTANTS.listVerStatus.unverified) {
            return [
                this.dic.CONSTANTS.massListsActions.rename,
                this.dic.CONSTANTS.massListsActions.applyVerificationRequest,
                this.dic.CONSTANTS.massListsActions.download,
                this.dic.CONSTANTS.massListsActions.delete,
            ]
        }
        else {
            return [
                this.dic.CONSTANTS.massListsActions.rename,
                this.dic.CONSTANTS.massListsActions.sendEmail,
                this.dic.CONSTANTS.massListsActions.download,
                this.dic.CONSTANTS.massListsActions.delete,
            ]
        }
    }

    selectAction = (list,action) => {
        switch (action) {
            case this.dic.CONSTANTS.massListsActions.rename:
                this.startEditList(list);
                break;
            case this.dic.CONSTANTS.massListsActions.sendEmail:
                this.sendMessageToList(list);
                break;
            case this.dic.CONSTANTS.massListsActions.applyVerificationRequest:
                this.sendVerificationRequest(list);
                break;
            case this.dic.CONSTANTS.massListsActions.download:
                this.downloadList(list);
                break;
            case this.dic.CONSTANTS.massListsActions.delete:
                this.openDeleteListPopup(list);
                break;
        }
    }

    startEditList = (list) => {
        list.edit = {
            name: list.name,
        };
        list.isEditMode = true;
    }

    confirmEdit = (list,isApproved) => {
        // edit declined
        if (!isApproved) {
            // empty the 'edit' property which contains the temp edit values and then close edit mode
            list.edit = null;
            list.isEditMode = false;
            return;
        }
        //

        // edit approved
        if (list.name === list.edit.name) { // if no changes made
            list.edit = null;
            list.isEditMode = false;
            return;
        }

        const err = this.validateEditedList(list);
        if (err) {
            return;
        }
		list.confirmEditInProcess = true;
        this.rs.updateList(list._id, {name: list.edit.name}).then( () => {
            list = _.merge(list,list.edit);
            list.edit = null;
			list.confirmEditInProcess = false;
            list.isEditMode = false;
        }, err => {
			list.confirmEditInProcess = false;
		});
    };

    validateEditedList = (list) => {
        if (!list.edit.name) {
            return this.ns.showWarnMessage(this.dic.ERRORS.listNameEmpty);
        }
    }

    setCurrentList = (list, isArrowKeyOpen, isKeyDown) => {
        if (!list) {
            this.activeMassList = null;
            return;
        }

        this.activeMassList = list;

        if (isArrowKeyOpen) {
            if (isKeyDown && this.listIndex >= (this.listsHeight / this.blockSize)) {
                document.getElementById('lists-list').scrollBy(0, this.blockSize);
            }
            else if (isKeyDown && this.listIndex === 0) {
                document.getElementById('lists-list').scrollTo(0, 0);
            }
            else if (!isKeyDown) {
                document.getElementById('lists-list').scrollBy(0, -this.blockSize);
            }
        }
    };

	// since the search input is outside the trustifi-table component, we should trigger the directive's inner search mechanism from the outside.
	// we do it by changing the list so that the directive will detect changes and fire a search
	triggerSearch = () => {
		this.lists = _.cloneDeep(this.lists);
	}

    searchLists = () => {
        this.lists.forEach(record => {
            // search
            if (this.searchListTxt) {
                const isFound = this.searchTextExecute(record, this.searchListTxt);
                if (!isFound && !record.isNew) { // ignore contact in creation state
                    record.hide = true;
                    return;
                }
            }

            // filter
            // // currently no filters for lists

            record.hide = false;
        });
    }

    uploadListExecute = (url, file, cb) => {
        if (!file) return;

        if (file.name && !file.name.endsWith(".csv") && !file.name.endsWith(".CSV")) {
            this.ns.showErrorMessage("Expected .csv file");
            return;
        }

        let notIdx;

		notIdx = this.ns.showInfoMessage(this.dic.MESSAGES.contactUploadStart, {timeout: 0});

        this.gs.uploadFile(url, {file: file}, notIdx, true).then((response:any) => {
			if (response?.data?.error) {
				this.ns.closeMessage(notIdx);
				this.ns.showErrorMessage(response.data.message);
			}
			else {
				this.ns.overwriteMessage(notIdx, this.dic.MESSAGES.contactUpload);

				cb();
			}
        });
    };

    previewMassEmail = (list, rows) => {
        if (rows > 100 || rows < 1) {
            this.ns.showWarnMessage(this.dic.ERRORS.previewMassLimist);
            return;
        }

        this.rs.previewMassEmail(list._id, {num_rows: rows}).then((response) => {
            this.activeMassList.previewList = response;
        });
    };

    copyKeyToClipboard = (value) => {
        this.clipboard.copy(value);
        this.ns.showInfoMessage(util.format(this.dic.MESSAGES.copyClipboard, "Value"));
    };

    sendVerificationRequest = (list) => {
        this.rs.sendMassListVerificationRequest(list._id).then((response) => {
            if (!response.error) {
                list.verification_status = this.dic.CONSTANTS.listVerStatus.pending;
                list.status_timestamp = Date.now();
            }
        });
    };

    sendMessageToList = (list) => {
        const options = {
            contacts: list
        }

		this.router.navigate([this.dic.CONSTANTS.appStates.personalPages, this.dic.CONSTANTS.personalPages.composeMessage], {state: {data: options}});
    };

    deleteList = () => {
        this.rs.deleteList(this.listToDelete._id).then(() => {

            this.removeObjectFromArray(this.lists, this.listToDelete);
            if (this.filteredContactLists) {
                this.removeObjectFromArray(this.filteredContactLists, this.listToDelete);
            }

            this.setCurrentListByIndex(0, false, false);
            this.ns.showInfoMessage(this.dic.MESSAGES.listDeleted);
        });
    };


    openDeleteListPopup = list => {
        this.listToDelete = list;
        let body = ['If you upload this list again in the future, it will have to be re-verified (for lists over 1000 emails).'];

        this.gs.showPopup({
            title: 'Delete List',
            subTitle: 'Please note - the selected list will be deleted.',
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: this.deleteList
        });
    };

    openUploadListPopup = () => {
        const url = '/list/mass';
        let body : any[] = [
            'File type must be a Comma Separated Value (.csv)',
            'File size up to 100 MB.',
            'The first row must contain the field names',
            'Email addresses should appear in the Email column and only there',
            'Complex text fields (that include spaces or other symbols) must be surrounded with double quotation marks.',
        ];
        let popupType = this.dic.CONSTANTS.popupInfo;

        this.gs.showPopup({
            title: 'Import List',
            subTitle: 'To ensure ' + this.corpName + ' can successfully handle your list, it should meet the following conditions:',
            body: body,
            type: popupType,
            doneBtnText: 'OK',
            isFile: true,
            doneCb: (file, options) => {
                if (!file) return;
                this.file = file;

                this.uploadList(url);
            }
        });
    };

    uploadList = (url) => {
        if (!this.file || !this.file.length) {
            console.error('No file provided');
            return;
        }

        let filesProcessed = 0;
        for (let i = 0; i < this.file.length; i++) {
            this.uploadListExecute(url , this.file[i], () => {
                filesProcessed++;
                if (filesProcessed === this.file.length) {
                    this.ngOnInit();
                }
            });
        }
    };

    downloadList = (list) => {
        let url = '/list/mass/', fileName = list.name || 'mass_list.csv';
        if (list._id) {
            url += list._id;
        }
        else {
            url += "all";
        }
        // fix filename before send to download
        if (!fileName.endsWith(".csv") && !fileName.endsWith(".xlsx")) {
            fileName += ".csv";
        }

		const fileObj = {name: fileName, type: 'application/csv', size: list.mass_list ? list.mass_list.size : 0};

        this.gs.downloadFile(url, fileObj, true, (err) => {});
    };

    removeObjectFromArray = (objectArray, object) => {
        if (!objectArray) {
            return;
        }
        for (let contactIdx = 0; contactIdx < objectArray.length; contactIdx++) {
            if (objectArray[contactIdx]._id === object._id) {
                return objectArray.splice(contactIdx, 1);
            }
        }
        return null;
    }

    searchTextExecute = (list, searchTerm) => {
        searchTerm = searchTerm.toLowerCase();
        return list.name && list.name.toLowerCase().indexOf(searchTerm) > -1;
    }
}

