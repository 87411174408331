<div class="flxClmn bs-enabled">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container flxRow hSpace1">
			<span class="page-title">Campaigns</span>
			<div style="position: relative;" *ngIf="isThisFeatureSupported">
				<btn-c color="warning" variant="outlined"
					   id="comingSoonModalToggler"
					   startIcon="fas fa-bullhorn"
					   (action)="showComingSoonModal = !showComingSoonModal">
					See what's coming
				</btn-c>
				<div class="flxClmn page-info-window" style="right: -10px; top: 5px; left: auto; transform: translateX(100%); width: auto;"
					 (clickOutside)="showComingSoonModal = false;"
					 [exclude]="'#comingSoonModalToggler'"
					 [ngClass]="{'shown' : showComingSoonModal}">
					<div class="flxClmn f-1-0 vSpace05" style="width: 900px; max-width: 55vw; height: auto; line-height: 1.5; font-size: 14px; padding: 25px;">
						<span style="font-weight: bold;">Heads up! More features will be deployed soon to Threat Simulation:</span>
						<br/>
						<div class="flxClmn vSpace1" style="padding-left: 10px;">
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>A variety of new templates simulating prevalent phishing email types, tailored to suit various company departments.</span>
							</div>
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>Simulate new types of attacks: Business Email Compromise (BEC), malware, and more.</span>
							</div>
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>Collect data from new types of user engagement: replies, downloaded files, credentials entered into fake landing pages, and more.</span>
							</div>
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>Access new training materials, including security awareness questionnaires that could be scheduled for at-risk users.</span>
							</div>
							<div class="flxRow hSpace1" style="align-items: baseline;">
								<i class="far fa-star text-primary"></i>
								<span>Improved reports and graphs.</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page-subtitle-container">
			<span class="page-subtitle">
				Threat Simulation campaigns allow security teams to test user security awareness and improve security practices in the organization
			</span>
		</div>
	</div>

	<!--loader-->
	<div class="f-1-0 flex-column centralize" *ngIf="_.isUndefined(isThisFeatureSupported)">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div class="page-inner-content-holder no-scroll flxClmn vSpace2" *ngIf="isThisFeatureSupported">

		<!--main content area-->
		<div class="flxRow animated fadeIn" style="flex: 1 0; border-top: solid 1px #e3e4e4; padding-top: 10px; overflow: auto;" *ngIf="campaigns?.length">
			<!--list of campaigns (left side)-->
			<div class="flxClmn" style="flex: 1.1 0; margin: 0 7px; padding: 5px; min-width: 450px; min-height: 350px; background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent);">
				<div class="flxClmn" style="flex: 1 0; overflow: auto;" >
					<div class="w100 flxRow-between" style="background: #677184; padding: calc(3px + 0.4vw); align-items: center; z-index: 1; min-height: 42px;">
						<btn-c style="background: white;"
							   variant="outlined" endIcon="fas fa-plus"
							   (action)="newCampaignWizard();">
							Create Campaign
						</btn-c>
						<div class="flxRow hSpace1" style="align-items: center;">
							<span *ngIf="_.reject(campaigns, 'hide').length" style="color: white; padding: 5px;">
                                {{_.reject(campaigns, 'hide').length}} record{{_.reject(campaigns, 'hide').length === 1 ? '' : 's'}}
                            </span>
							<!--Search-->
							<search-box [(model)]="searchCampaignTxt"
										(modelChange)="triggerSearch()"
										placeholder="Search campaign"
										style="width: 30%; min-width: 200px;">
							</search-box>
						</div>
					</div>

					<trustifi-table-c
						class="trustifi-table" style="flex: 1 0; z-index: 5; background: white;"
						[list]="campaigns"
						[headers]="[
							{text: 'Name', sortKey: 'name'},
							{text: 'Created', sortKey: 'created', width: '120px'},
						]"
						[cellsPrototype]="[
							{html: 'nameCell'},
							{textKey: 'created', textType: 'dateAndTime'},
						]"
						[bulkActions] = "{
							enable: true,
							selectBulkActionCb:	selectMultipleCampaignAction,
							initBulkActionsFunction: showBulkCampaignsActions,
							showInRed: {enable: true, terms: ['Delete']}
						}"
						[subRowOptions]="{
							recursionKey: 'campaigns',
							allowActions: true
						}"
						[cellActions]="{
							enable: true,
							selectItemActionCb:	selectCampaignAction,
							initActionsFunction: showCampaignActions,
							showInRed: {enable: true, terms: ['Delete']}
						}"
						(onAnyItemSelected)="!$event.isItemSelectCheckboxClicked && setCurrentCampaign($event.item)"
						(searchFunction)="searchCampaign($event.searchTerm, $event.activeFilters)"
						[options]="{
							loadingTableFlag: getCampaignsInProcess,
							soloSelected: true,
							hideContentAboveTable: true,
							itemsNameSingular: 'campaign',
							defaultSortKey: '-created'
						}"
					>
						<!--log action cells-->
						<ng-template #customCells let-item let-cellId="cellId">
							<span *ngIf="cellId === 'nameCell'">
								<div class="flxRow hSpace05" style="align-items: center; cursor: pointer;"
									 *ngIf="!item.isCompanyTitleRecord"
									 (click)="toggleExpandItem(item, $event);"
									 [ngStyle]="{'padding-left' : item.depthLevel ? 15 + item.depthLevel * 15 + 'px' : ''}">
									<i class="fa fa-chevron-right" style="color: royalblue; transition: transform 0.15s ease-out; padding: 5px; padding-left: 0;"
									   *ngIf="item.campaigns?.length"
									   [ngStyle]="{'transform' : item.isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}"></i>
									<span>{{item.name}}</span>
								</div>
							</span>
						</ng-template>
						<!---->
					</trustifi-table-c>
				</div>
			</div>

			<!--Email display area (right side)-->
			<div class="vertical-separator" style="border-color: #e3e4e4; border-left: none;"></div>
			<div class="flxClmn" style="flex: 2 0; align-items: center; margin-left: 20px; position: relative; overflow: auto;"
				 [ngStyle]="{'background': currentCampaign ? 'transparent' : '#f7f7f7'} ">
				<!--loading overlay-->
				<div class="flxClmn-center animated fadeIn" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 5; background: rgba(255,255,255,0.6); align-items: center;"
					 *ngIf="getCampaignDataInProcess">
					<div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 1;"></div>
				</div>
				<!----->
				<div class="flxClmn-center vSpace2 w100" style="flex:1 0; overflow: auto;" *ngIf="currentCampaign">
					<!--tabs-->
					<div class="simple-tab-row w100 flxRow">
						<ng-container *ngFor="let tab of dic.CONSTANTS.threatSimulationCampaignTabs | keyvalue: gs.returnZero">
							<div class="tab-header"
								 *ngIf="!(currentCampaign.campaigns && tab.value === dic.CONSTANTS.threatSimulationCampaignTabs.emailDetails)
              								&& (tab.value !== dic.CONSTANTS.threatSimulationCampaignTabs.configuration || currentCampaign.configuration)"
								 [ngClass]="{'chosen' : activeCampaignTab === tab.value}"
								 (click)="activeCampaignTab = tab.value;">
								<span class="hvr-opacity">{{tab.value}}</span>
							</div>
						</ng-container>
					</div>

					<div class="flxClmn" style="flex: 1 0; overflow: auto;">
						<threat-simulation-campaign-details-component *ngIf="!currentCampaign.campaigns && activeCampaignTab === dic.CONSTANTS.threatSimulationCampaignTabs.emailDetails"
																	  [currentCampaign]="currentCampaign"
																	  (deleteCampaign)="openDeleteCampaignPopup([currentCampaign]);"
																	  class="flxClmn f-1-0" style="overflow: auto;" ></threat-simulation-campaign-details-component>
						<threat-simulation-campaign-summary-component *ngIf="activeCampaignTab === dic.CONSTANTS.threatSimulationCampaignTabs.summary"
																	  [currentCampaign]="currentCampaign"
																	  class="flxClmn f-1-0" style="overflow: auto;" ></threat-simulation-campaign-summary-component>
						<threat-simulation-campaign-configuration-component *ngIf="!currentCampaign.campaigns && currentCampaign.configuration && activeCampaignTab === dic.CONSTANTS.threatSimulationCampaignTabs.configuration"
																	  [currentCampaign]="currentCampaign" [configuration]="currentCampaign.configuration"
																	  class="flxClmn f-1-0" style="overflow: auto;" ></threat-simulation-campaign-configuration-component>
					</div>
				</div>

				<div *ngIf="!currentCampaign && !getCampaignDataInProcess" class="flxClmn-center"
					 style="align-items: center; flex: 1 0;">
					<i class="fas hvr-opacity fa-envelope-open-text" style="font-size: 4vw;  opacity: 0.1;"></i>
				</div>
			</div>
		</div>

		<!--Create First Campaign screen-->
		<div class="f-1-0 flex-column centralize" style="background: linear-gradient(45deg, #ffffff99 , #ffffff00,  #ffffff), linear-gradient(-45deg, #ffffff , #ffffff00,  #ffffff), linear-gradient(#163f5d0d, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff , #ffffff00 51%, transparent);"
			 *ngIf="!campaigns?.length">
			<div class="flex-column centralize bg-white create-new-box gap-3" style="width: 90vh; height: 60vh; box-shadow: 0 2px 16px lightgrey;">
				<img src="images/newTsCampaign.png" style="margin-top: -8vh; width: 45vh; margin-bottom: 5vh;">
				<ng-container *ngIf="!threatSimulationConfig?.template_fields?.company">
					<span style="font-size: clamp(14px, 3vh, 25px);">Getting started!</span>
					<span style="font-size: clamp(13px, 2vh, 20px); margin-bottom: 2vh; max-width: 70%;">Before creating your first campaign, you need to set your <a class="blue-link" [routerLink]="['/' + dic.CONSTANTS.appStates.threatSimulation, dic.CONSTANTS.adminPages.threatSimulation.configuration, dic.CONSTANTS.threatSimulationConfigPageTabs.templateFields]">Template Parameters</a> and configure <a class="blue-link" [routerLink]="['/' + dic.CONSTANTS.appStates.threatSimulation, dic.CONSTANTS.adminPages.threatSimulation.configuration, dic.CONSTANTS.threatSimulationConfigPageTabs.configuration]">Settings</a> on your email server</span>
				</ng-container>
				<ng-container *ngIf="threatSimulationConfig?.template_fields?.company">
					<span style="font-size: clamp(14px, 3vh, 25px); margin-bottom: 2vh;">Create your first Threat Simulation Campaign</span>
					<btn-c class="popup-action-btn"
						   endIcon="fas fa-plus"
						   (action)="newCampaignWizard();">
						Create Campaign
					</btn-c>
				</ng-container>
			</div>
		</div>
		<!---->
	</div>

	<!--feature not supported-->
	<threat-simulation-not-supported style="display: contents;" *ngIf="isThisFeatureSupported === false"></threat-simulation-not-supported>
	<!---->

	<threat-simulation-campaign-wizard
		*ngIf="showNewCampaignWizard"
		[allCampaignGroups]="_.filter(campaigns, 'campaigns')"
		[verifiedDomains]="verifiedDomains"
		[relaunchCampaign]="campaignForRelaunch ? campaignForRelaunch : null"
		(cancelCb)="showNewCampaignWizard = false;"
		(doneCb)="showNewCampaignWizard = false; getThreatSimulationCampaigns($event.newCampaignId);"
	></threat-simulation-campaign-wizard>

	<!--Assign item to admin popup-->
	<popup-c class="overflownV" *ngIf="updateCampaignGroupPopup?.show"
			 head="Assign Campaign to Group"
			 (closeCb)="updateCampaignGroupPopup = null;"
			 (confirmCb)="updateCampaignGroupPopup?.doneCb()"
			 [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}" size="dynamic">
		<div class="flxClmn vSpace1" style="overflow: visible;">
			<span>Choose which group the selected campaign should be added to</span>
			<span>Campaign: <b>{{updateCampaignGroupPopup.campaignToInsert.name}}</b></span>
			<br/>
			<div class="flxClmn vSpace1">
				<div class="flex-row align-items-center w100">
					<label class="radio-container" style="flex-basis: 130px; margin: 0;">
						Existing group:
						<input type="radio" name="isNewGroupInput" [value]="false"
							   [disabled]="!updateCampaignGroupPopup.groups.length"
							   [(ngModel)]="updateCampaignGroupPopup.isNewGroup"
							   [checked]="updateCampaignGroupPopup.isNewGroup === false">
						<span class="checkmark"></span>
					</label>
					<span class="text-muted" *ngIf="!updateCampaignGroupPopup.groups.length">No groups</span>
					<app-dropdown-c style="width: 200px;"
									[alwaysDrop]="true"
									[text]="updateCampaignGroupPopup.selectedGroup?.name || ''"
									*ngIf="updateCampaignGroupPopup.groups.length"
									[items]="updateCampaignGroupPopup.groups"
									[isDisabled]="updateCampaignGroupPopup.isNewGroup"
									itemDisplayKey="name" [enableSearch]="true"
									[error]="updateCampaignGroupPopup.error && updateCampaignGroupPopup.isNewGroup === false"
									(onItemClicked)="updateCampaignGroupPopup.selectedGroup = $event.item; updateCampaignGroupPopup.error = false;">
					</app-dropdown-c>
				</div>

				<div class="flex-row align-items-center w100">
					<label class="radio-container" style="margin-bottom: 0; flex-basis: 130px;">
						New group:
						<input type="radio" name="isNewGroupInput" [value]="true"
							   [(ngModel)]="updateCampaignGroupPopup.isNewGroup"
							   [checked]="updateCampaignGroupPopup.isNewGroup === true">
						<span class="checkmark"></span>
					</label>
					<div class="flex-row align-items-center gap-2 f-1-0">
						<input type="text" style="width: 200px;"
							   (keyup)="updateCampaignGroupPopup.error = false;"
							   [ngClass]="{'disabled' : !updateCampaignGroupPopup.isNewGroup, 'validation-error' : updateCampaignGroupPopup.error && updateCampaignGroupPopup.isNewGroup === true}"
							   [(ngModel)]="updateCampaignGroupPopup.newGroupName">
					</div>
				</div>
			</div>
		</div>
	</popup-c>

	<!--Add recipients popup-->
	<popup-c class="bs-enabled" *ngIf="addCampaignRecipientsPopup?.show"
			 head="Add Recipients to Campaign" size="large"
			 subTitle="Select the recipients you want to add to Threat Simulation campaign"
			 (closeCb)="addCampaignRecipientsPopup = null;"
			 (confirmCb)="addRecipientsToCampaignExecute();"
			 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: addCampaignRecipientsPopup.executeInProcess, disabled: addCampaignRecipientsPopup.loading}}">
		<div class="flex-column overflow-auto f-1-0 gap-2 p-3 pt-4">
			<div class="flex-column f-1-0 gap-3 overflow-auto">
				<trustifi-table-c
					#addRecipientsTableEl
					class="trustifi-table f-1-0" style="z-index: 5;"
					[list]="addCampaignRecipientsPopup.allPossibleRecipients"
					[headers]="[
						{text:'Name', sortKey: 'name'},
						{text:'Email', sortKey: 'email'},
					]"
					[cellsPrototype]="[
					  {html: 'nameCell'},
					  {textKey: 'email'},
					]"
					(searchFunction)="searchCampaignRecipients($event.searchTerm, $event.activeFilters)"
					[subRowOptions]="{
							recursionKey: 'members',
							allowActions: false
						}"
					[options]="{
						loadingTableFlag: addCampaignRecipientsPopup.loading,
						defaultSortKey: '-name',
						itemUniqueIdentifier: '_id',
						showRadioButtons: true,
					}"
				>
					<ng-template #customCells let-item let-cellId="cellId">
						<!--name cell-->
						<span *ngIf="cellId === 'nameCell'">
							<div class="flxRow hSpace05" style="align-items: center; cursor: pointer;"
								 (click)="toggleExpandItemCampaignRecipients(item, addRecipientsTableEl, $event);">
								<i class="fa fa-chevron-right" style="color: royalblue; transition: transform 0.15s ease-out; padding: 5px;"
								   *ngIf="item.members?.length"
								   [ngStyle]="{'transform' : item.isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}"></i>
								<span style="padding: 7px 0;" [ngClass]="{'text-primary' : item.alreadySent}"
									  [ngStyle]="{'margin-left' : item.members?.length || !item.depthLevel ? '' : '30px'}">
									{{item.name || item.email}} <b class="text-primary" *ngIf="item.alreadySent">(Sent)</b>
								</span>
							</div>
						</span>
					</ng-template>

					<div tableButtons>
						<div class="flex-row gap-2">
							<div class="flex-row align-items-center p-2 cursor-pointer hvr-opacity" style="border: solid 1px #cccccc; align-self: flex-end;"
								 (click)="addCampaignRecipientsPopup.showMailboxes = !addCampaignRecipientsPopup.showMailboxes; addRecipientsTableEl.searchItem();"
								 [ngClass]="{'color-text bg-primary' : addCampaignRecipientsPopup.showMailboxes}">
								<div class="gap-1 md-checkbox">
									<input type="checkbox" style="height: 0;" onclick="return false;" [checked]="addCampaignRecipientsPopup.showMailboxes"/>
									<label><span>Show mailboxes</span></label>
								</div>
							</div>
							<div class="flex-row align-items-center p-2 cursor-pointer hvr-opacity" style="border: solid 1px #cccccc; align-self: flex-end;"
								 (click)="addCampaignRecipientsPopup.showGroups = !addCampaignRecipientsPopup.showGroups; addRecipientsTableEl.searchItem();"
								 [ngClass]="{'color-text bg-primary' : addCampaignRecipientsPopup.showGroups}">
								<div class="gap-1 md-checkbox">
									<input type="checkbox" style="height: 0;" onclick="return false;" [checked]="addCampaignRecipientsPopup.showGroups"/>
									<label><span>Show groups</span></label>
								</div>
							</div>
						</div>
					</div>
				</trustifi-table-c>
				<div class="flex-row flex-wrap flex-shrink-0 overflow-auto justify-content-start gap-2 align-items-baseline" style="height: 62px; padding-left: 0.3rem; padding-right: 0.3rem;">
					<div *ngFor="let recipient of _.filter(addCampaignRecipientsPopup.allPossibleRecipients, 'selected'); trackBy: gs.trackByIndex"
						 class="p-1 d-flex hSpace05 align-items-center rounded-1 bg-dark"
						 (click)="recipient.selected = false; addRecipientsTableEl.searchItem();"
						 style="cursor: pointer; --bs-bg-opacity: .07; line-height: 16px;">
						<span [ngStyle]="{'font-weight' : !recipient.members?.length ? '' : '500'}">{{recipient.email}}</span>
						<span style="font-weight: 300; font-size: 12px;" *ngIf="recipient.members?.length"> (group)</span>
						<i class="zmdi zmdi-close"></i>
					</div>
				</div>
			</div>
		</div>
	</popup-c>
</div>

