import {Component, Input} from '@angular/core';

@Component({
	selector: 'more-info-c',
	template: `
		<div style="position:relative; overflow: visible; margin: 0 7px;">
			<i class="fas fa-question-circle" tooltip="More info" style="cursor: pointer; color: dimgrey; font-size: 1.2rem; font-weight: 300; margin-left: -3px; transform: translateY(-2px);" (click)="toggleInstructions()"></i>
			<div class="flxClmn page-info-window" [ngClass]="{'shown' : showInstructions}">
				<div class="flxRow-between title-row w100" style="position: sticky; top: 0;">
					<div class="flxRow hSpace05" style="align-items: center">
						<i style="font-size: 0.95rem; opacity: 0.5;" class="fa fa-info-circle"></i>
						<span style="font-size: 0.95rem;">Information</span>
					</div>
					<a style="font-size: 1.2rem; padding: 0 7px;" class="close1 closeRed" (click)="toggleInstructions()">
						<i class="zmdi zmdi-close"></i>
					</a>
				</div>
				<div class="flxRow hSpace2 content-wrapper" style="flex: 1 0;">
					<ng-content></ng-content>
				</div>
				<div *ngIf="guideTitle" class="guide-link-wrapper flxRow hSpace05" style="align-self: flex-end; font-weight: 500;">
					<span>Full guide:</span>
					<a class="a-link" [href]="guideUrl" target="_blank">{{guideTitle}}</a>
				</div>
			</div>
		</div>
    `
})
export class MoreInfoComponent {
	@Input() guideTitle: string;
	@Input() guideUrl: string;

	showInstructions = false;

	toggleInstructions() {
		this.showInstructions = !this.showInstructions;
	}
}
