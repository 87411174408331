<div class="flxClmn bs-enabled">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Threat Response</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">
                A sophisticated tool to search and retrieve specific potential threats in existing emails, and take actions on them
            </span>
        </div>
    </div>

	<div class="d-flex w-100 centralize" *ngIf="!threatResponsePermission && loadingItemsInProcess">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

    <div class="page-inner-content-holder no-scroll flxClmn">
		<trustifi-table-c *ngIf="threatResponsePermission"
			class="trustifi-table" style="flex: 1 0; z-index: 5; --table-filter-min-width: 500px;"
			[list]="tpItems"
			[headers]="[
				{text: 'Created', sortKey: 'created', width: '160px'},
				{text: 'Action', sortKey: 'sub_type'},
				{text: 'Result', sortKey: 'scan_result'},
				{text: 'Mailboxes'},
				{text: 'Emails Scanned', sortKey: 'scanResult.emailsScanned', centered: true},
				{text: 'Completed', sortKey: 'finished'},
			]"
			[cellsPrototype]="[
				{textKey: 'created', textType: 'dateAndTime'},
				{textKey: 'sub_type'},
				{html: 'results'},
				{html: 'mailboxes'},
				{html: 'emailsScanned'},
				{html: 'completed'},
			]"
			[bulkActions]="{
				enable: true,
				selectBulkActionCb:	selectMultipleRecordsAction,
				initBulkActionsFunction: showMultipleRecordsActions,
			}"
			[cellActions] = "{
				enable: true,
				isSmaller: true,
				selectItemActionCb:	selectRecordAction,
				initActionsFunction: showRecordActions
			}"
			(searchFunction)="searchThreatResponse($event.searchTerm, $event.activeFilters)"
			[options]="{
				refreshFunction: getThreatResponses,
				loadingTableFlag: loadingItemsInProcess,
				defaultSortKey: '-created',
			}"
		>
			<ng-template #customCells let-item let-cellId="cellId">
				<!--status cells-->
				<div *ngIf="cellId === 'results'">
					<span>{{item.scan_result || 'According to your rules'}}</span>
				</div>

				<!--mailboxes cells-->
				<div *ngIf="cellId === 'mailboxes'" tooltip="{{item.scanInfo.mailboxes.join(', ')}}">
					<span>{{item.scanInfo.mailboxes.join(', ')}}</span>
				</div>

				<!--Emails Scanned cells-->
				<div *ngIf="cellId === 'emailsScanned'" class="flxRow-center" style="align-items: center;">
					<a>
						{{item.scanResult.emailsScanned}}
					</a>
				</div>

				<!--Completed cell-->
				<div *ngIf="cellId === 'completed'" class="flxRow hSpace1" style="align-items: center">
					<i [ngClass]="item.finished ? 'fa fa-check-circle' : 'fa fa-exclamation-triangle'"
					   tooltip="{{item.finished ? 'Completed' : 'In process'}}"
					   [ngStyle]="{'color' : item.finished ? 'darkgreen' : 'orange'}"></i>
					<span *ngIf="item.finished">
                                {{item.finished | date:'MM/dd/yyyy HH:mm'}}
					</span>
					<span *ngIf="!item.finished" style="color: orange;">
                                In process
					</span>
				</div>
			</ng-template>

			<ng-template #subRow let-item>
				<tr style="border-bottom: none;" *ngIf="item.isExpanded">
					<td class="overflownV" colspan="8" style="background: white; border-bottom: solid 1px #e3e4e4; ">
						<div class="flxClmn gap-4" style="gap: 25px;">
							<div class="flxClmn vSpace05" style="padding: 10px 20px; cursor: default;">
								<b style="margin-right: 3px;">Scan Results:</b>
								<table class="threat-response-inner-table">
									<thead>
									<tr style="cursor: unset;">
										<th>Threat Type</th>
										<th>Threat Match</th>
									</tr>
									</thead>
									<tbody *ngFor="let itemObj of item.scanResult.data">
									<tr style="cursor: unset;">
										<td>{{itemObj.name}}</td>
										<td>{{itemObj.count}}</td>
									</tr>
									</tbody>
								</table>
							</div>

							<div class="flxClmn vSpace05" style="padding: 10px 20px; cursor: default;" *ngIf="item.scanResult.matched_emails && item.scanResult.matched_emails.length">
								<trustifi-table-c
									class="trustifi-table threat-response-inner-table" style="height: 300px; z-index: 5;"
									[list]="item.scanResult.matched_emails"
									[headers]="[
                                                    {text:'Mailbox', sortKey:'mailbox'},
                                                    {text:'Subject', sortKey:'subject'},
                                                    {text:'Matched Item', sortKey:'matched_item'},
                                                    {text:'Message ID', width: '25%', sortKey:'message_id'},
                                                    {text:'Sent Date', width: '10%', sortKey:'created'},
                                                ]"
									[cellsPrototype]="[
                                                    {textKey:'mailbox'},
                                                    {textKey:'subject'},
                                                    {textKey:'matched_item'},
                                                    {textKey:'message_id', placeHolder: 'N/A', style: {'word-break' : 'break-word', 'white-space': 'pre-wrap'}},
                                                    {textKey:'created', textType: 'dateAndTime'},
                                                ]"
									[bulkActions]= "{
                                                    enable: true,
                                                    selectBulkActionCb:	selectMultipleMatchedEmailsAction,
                                                    initBulkActionsFunction: showMatchedEmailBulkActions
                                                }"
									[cellActions] = "{
                                                    enable: true,
                                                    selectItemActionCb:	selectMatchedEmailAction,
                                                    initActionsFunction: showMatchedEmailActions
                                                }"
									[options]="{
                                                    itemsNameSingular: 'email',
                                                    defaultSortKey: '-created',
                                                    isShortRows: true,
                                                }"
									(searchFunction)="searchMatchedEmail(item, $event.searchTerm, $event.activeFilters)"
								>
									<!--status cells-->
									<div tableButtons>
										<span class="fw-bold">Matched Emails:</span>
									</div>
								</trustifi-table-c>
							</div>
						</div>
					</td>
				</tr>
			</ng-template>

			<div tableButtons>
				<btn-c (action)="threatResponseAction()"
					   endIcon="fas fa-plus">
					Create New Threat Response
				</btn-c>
			</div>
		</trustifi-table-c>
    </div>

    <!--email content popup-->
    <popup-c *ngIf="displayEmailPopup?.show"
           head="Email Details" size="medium"
           (closeCb)="displayEmailPopup = null;">
        <div class="flxClmn vSpace1 f-1-0" style="overflow: auto;">
            <span><b>Mailbox:</b> {{displayEmailPopup.mailbox}}</span>
            <span><b>Subject:</b> {{displayEmailPopup.subject}}</span>
            <span><b>Content:</b></span>
            <div class="content-popup-container flxClmn centralize f-1-0" *ngIf="displayEmailPopup.inProcess">
                <loader height="100" width="100" style="opacity: 0.8;"></loader>
            </div>
            <div *ngIf="displayEmailPopup.content"
                 class="content-popup-container f-1-0" style="word-break: break-word; min-height: min(250px,30vh);" [innerHTML]="displayEmailPopup.content">
            </div>
        </div>
    </popup-c>

    <!--email reviewers activity popup-->
    <popup-c *ngIf="emailReviewersActivityPopup?.show"
           head="Reviewers Activity" size="medium"
           (closeCb)="emailReviewersActivityPopup.show = null;"
           [buttons]="{cancel: 'OK'}">
        <div class="flxClmn vSpace1 f-1-0" style="overflow: auto;">
            <span><b>Mailbox:</b> {{emailReviewersActivityPopup.email.mailbox}}</span>
            <span><b>Subject:</b> {{emailReviewersActivityPopup.email.subject}}</span>
            <trustifi-table-c
                    class="trustifi-table" style="flex: 1 0; z-index: 5;"
                    [list]="emailReviewersActivityPopup.email.reviewer_actions"
                    [headers]="[
                        {text:'Date', sortKey:'created'},
                        {text:'Reviewer', sortKey:'reviewer'},
                        {text:'Action', sortKey:'action'},
                    ]"
                    [cellsPrototype]="[
                                {textKey: 'created', width: '150px', textType: 'dateAndTime'},
                                {textKey: 'reviewer'},
                                {textKey: 'action'},
                            ]"
                    [options]="{
                        itemsNameSingular: 'action',
                        defaultSortKey: '-created'
                    }"
            >
                <div tableButtons>
                    <b>Reviewers Activity</b>
                </div>
            </trustifi-table-c>
        </div>
    </popup-c>

    <!--new threat response popup-->
    <popup-c class="no-content-padding" *ngIf="showThreatResponsePopup"
           head="Threat Response" size="large"
           subTitle="Use the Threat Response tool to find and resolve existing threats in your mailboxes"
           (closeCb)="showThreatResponsePopup = false;">
        <div class="flex-column overflow-auto f-1-0 pt-2 gap-2" style="padding: 20px;">

            <!--steps progress-->
            <div class="steps-container">
                <div class="step-tab step-tab-start fw-bold"
                     [ngClass]="{'active' : threatResponseData.step === dic.CONSTANTS.threatResponseSteps.selectMailboxes }">
                    Select mailboxes
                </div>
                <div class="step-tab step-tab-middle fw-bold"
                     [ngClass]="{'active' : threatResponseData.step === dic.CONSTANTS.threatResponseSteps.selectAction }">
                    Select action
                </div>
                <div class="step-tab step-tab-middle fw-bold"
                     [ngClass]="{'active' : threatResponseData.step === dic.CONSTANTS.threatResponseSteps.configData }">
                    Select conditions
                </div>
                <div class="step-tab step-tab-end fw-bold"
                     [ngClass]="{'active' : threatResponseData.step === dic.CONSTANTS.threatResponseSteps.summary }">
                    Summary & Execution
                </div>
            </div>

            <!--Steps content:-->

            <!--STEP: Select mailboxes-->
            <div class="flex-column overflow-auto animated fadeIn f-1-0 gap-2 p-2 pt-3"
                 *ngIf="threatResponseData.step === dic.CONSTANTS.threatResponseSteps.selectMailboxes">
                <span>Select on which mailboxes to run threat response.</span>

                <div class="flex-column f-1-0 overflow-auto gap-2 p-2">
					<div class="flex-row gap-3 align-items-center">
						<label class="radio-container m-0">
							All mailboxes{{isPartnerAdmin ? ' of' : ''}}
							<input type="radio" name="threatResponseMailboxes"
								   [(ngModel)]="mailboxesTypeSelection"
								   [value]="mailboxesType.all"
								   [checked]="mailboxesTypeSelection === mailboxesType.all">
							<span class="checkmark"></span>
						</label>
						<app-dropdown-c class="limit-height" style="width: 250px; z-index: 50;" *ngIf="isPartnerAdmin"
									  [isDisabled]="mailboxesTypeSelection !== mailboxesType.all"
									  text="{{selectedPlansToApplyTo.length > 1 ? _.map(selectedPlansToApplyTo, 'display_email').join(', ') : selectedPlansToApplyTo[0].email === userInfo.email ? 'current plan only' : selectedPlansToApplyTo[0].display_email}}">
							<li class="static-item" style="height: 40px; padding: 6px 5px; position: sticky; top: 0; z-index: 50;">
								<div class="position-relative h-100">
									<input class="w-100 h-100 pe-4" type="search"
										   (click)="$event.stopPropagation();"
										   [focusMe]="{focus: true}"
										   [selectAllText]="selectAllPlanAdmins"
										   [(ngModel)]="searchPlanAdminTxt"
										   placeholder="Search"
										   (ngModelChange)="searchPlanAdmin();">
									<i class="fa fa-times text-muted animated fadeIn cursor-pointer hvr-darken"
									   style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); padding: 4px 5px 3px"
									   *ngIf="searchPlanAdminTxt"
									   (click)="searchPlanAdminTxt = ''; searchPlanAdmin(); $event.stopPropagation();"></i>
								</div>
							</li>
							<li style="height: 36px; position: sticky; top: 40px; z-index: 50; background-color: white;" (click)="selectPlanAdmins(null, false); $event.stopPropagation();">
								Current plan only
							</li>
							<li style="height: 36px; position: sticky; top: calc(40px + 36px); z-index: 50; background-color: white;" (click)="selectPlanAdmins(null, true); $event.stopPropagation();">
								All plans
							</li>
							<li class="static-item d-flex centralize" style="position: sticky; top: calc(40px + 36px + 36px); z-index: 50; background: white !important; min-height: 0;" (click)="$event.stopPropagation();">
								<hr class="w-100" style="margin: 0;"/>
							</li>
							<li *ngFor="let planAdmin of planAdmins | orderBy: 'toString()' | filter: {hide : '!true'}"
								(click)="selectPlanAdmins(planAdmin, null); $event.stopPropagation();">
								<div class="gap-1 md-checkbox">
									<input type="checkbox" (click)="$event.preventDefault()" [checked]="planAdmin.selected"/>
									<label><span>{{planAdmin.display_email}}</span></label>
								</div>
							</li>
						</app-dropdown-c>
					</div>
                    <div class="flex-row justify-content-between align-items-center">
                        <div class="flex-row gap-1 align-items-center">
                            <label class="radio-container m-0" style="display: block; width: fit-content;">
                                Specific mailboxes:
                                <input type="radio" name="threatResponseMailboxes"
                                       [(ngModel)]="mailboxesTypeSelection"
                                       [value]="mailboxesType.specific"
                                       [checked]="mailboxesTypeSelection === mailboxesType.specific">
                                <span class="checkmark"></span>
                            </label>
                            <span class="animated fadeIn" *ngIf="mailboxesTypeSelection === mailboxesType.specific">
                                    ({{_.filter(tpMailboxes, 'selected').length || '0'}} selected)
                                </span>
                        </div>
                    </div>
                    <div class="position-relative f-1-0 flex-column overflow-auto">
                        <!--table overlay when disabled-->
                        <div class="w-100 h-100" style="background-color: white; position: absolute; top: 0; left: 0; z-index: 20; opacity: 0.6;"
                             *ngIf="mailboxesTypeSelection === mailboxesType.all">
                        </div>
                        <trustifi-table-c
                                class="trustifi-table f-1-0" style="z-index: 5;"
                                [list]="tpMailboxes"
                                [cellsPrototype]="[
                                      {textKey: 'email'},
                                    ]"
                                (searchFunction)="searchMailbox($event.searchTerm, $event.activeFilters)"
                                [options]="{
                                        itemsNameSingular: 'mailbox',
                                        showRadioButtons: true,
                                        noHeader: true
                                    }"
                        >
                        </trustifi-table-c>
                    </div>
                </div>
            </div>

            <!--STEP: Choose actions-->
            <div class="flex-column f-1-0 gap-3 p-2 pt-3 animated fadeIn"
                 *ngIf="threatResponseData.step === dic.CONSTANTS.threatResponseSteps.selectAction">
                <span>You can search for threats by links, senders, files, headers, keywords or just perform a general scan.</span>
                <div class="item">
                    <span style="position: relative;">Action:</span>
                    <app-dropdown-c style="width: 200px; margin-left: 10px; display: inline-flex;" text="{{threatResponseData.action}}">
                        <li *ngFor="let threatKey of allowedInboundThreatResponse | keyvalue: gs.returnZero"
                            (click)="changeThreatResponseAction(threatKey.value.type)"
                            id="{{threatKey.value.action}}">
							<div class="d-flex gap-2 align-items-center">
                            	{{threatKey.value.action}}
								<i class="fa fa-question-circle" style="font-weight: 400;" [tooltip]="threatKey.value.tooltip" placement="right"></i>
							</div>
                        </li>
                    </app-dropdown-c>
                    <div *ngIf="threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.rescan.type"
                         class="mt-2"
                         style="font-size: 0.9em;">
                        Emails will go through a full threat scan and actions will be taken according to your "Threat Prevention Rules"
                    </div>
                </div>
                <!--config table-->
                <div class="flex-column f-1-0 animated fadeIn" *ngIf="threatResponseData.type !== dic.CONSTANTS.inboundThreatResponse.rescan.type">
					<div class="flex-column gap-3 f-1-0">
						<span style="line-height: 1.6;" *ngIf="threatResponseData.type !== dic.CONSTANTS.inboundThreatResponse.file.type">
							Add {{threatResponseData.type}}s to the list below or upload from a CSV file.<br/>
                            <span *ngIf="threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.keyword.type">The keywords will be searched in the email subject, body and file names.<br/></span>
                            If any of the provided {{threatResponseData.type}}s is found during the scan, the selected action will be performed
						</span>

						<span *ngIf="threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.file.type">
							Upload files to the list below.<br/> If any of the provided files is found (by name and hash) during the scan, the selected action will be performed
						</span>

						<div class="flex-column f-1-0"
							 ngfDrop
							 multiple="1"
							 [fileDropDisabled]="threatResponseData.type !== dic.CONSTANTS.inboundThreatResponse.file.type"
							 [(files)]="threatResponseData.file"
							 (filesChange)="uploadThreatFiles()"
							 [(validDrag)]="validDrag"
							 [(invalidDrag)]="invalidDrag"
							 [ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag}">
							<trustifi-table-c [list]="threatResponseData.items"
								  class="trustifi-table" style="flex: 1 0; z-index: 5; height: 330px;"
								  [headers]="_.compact([
									  {text: 'Name'},
									  threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.file.type && { text: 'Hash',},
									  {text: '', width: '75px'}
								  ])"
								  [cellsPrototype]="_.compact([
									  {html: 'nameCell'},
									  threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.file.type &&  {textKey: 'hash'},
									  {html: 'removeFileCell'},
								  ])"
								  [options]="{
									  itemsNameSingular: 'item',
									  defaultSortKey: 'name'
								  }">
								<ng-template #customCells let-item let-cellId="cellId">
									<span *ngIf="cellId === 'nameCell' && threatResponseData.type !== dic.CONSTANTS.inboundThreatResponse.file.type" tooltip="{{item.key && item.value ? item.key + ':'+ item.value : item.name}}">
										{{item.key && item.value ? item.key + ':'+ item.value : item.name}}
									</span>
									<span *ngIf="cellId === 'nameCell' && threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.file.type">
										{{item.name}}
									</span>
									<span *ngIf="cellId === 'removeFileCell'">
										<btn-c style="min-width: 0;"
											   color="danger" variant="text"
											   endIcon="fa fa-trash-alt"
											   (action)="removeThreatItem(item)">
										</btn-c>
									</span>
								</ng-template>
								<div tableButtons>
									<div class="flex-row gap-1" *ngIf="threatResponseData.type !== dic.CONSTANTS.inboundThreatResponse.file.type">
										<input type="text" style="margin: 0; width: 300px;"
											   [ngModelOptions]="{standalone: true}"
											   [placeholder]="'Add ' + threatResponseData.type + (threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.header.type ? ' (key:value)': '')"
											   [(ngModel)]="threatResponseData.item" (keydown.enter)="addThreatItem(threatResponseData.item)" />
										<btn-c [isDisabled]="!threatResponseData.item"
											   (action)="addThreatItem(threatResponseData.item);">
											Add
										</btn-c>
									</div>
									<div class="flex-row align-items-center gap-2" *ngIf="threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.file.type">
										<btn-c ngfSelect
											   multiple="1"
											   [(files)]="threatResponseData.file"
											   (fileChange)="uploadThreatFiles()">
											Upload Files
										</btn-c>
										<span class="text-muted">(or drag and drop on table)</span>
									</div>
								</div>
							</trustifi-table-c>
						</div>

						<div class="hSpace1 list-csv-action">
							<a ngfDrop *ngIf="threatResponseData.type !== dic.CONSTANTS.inboundThreatResponse.file.type"
							   ngfSelect
							   accept='.csv'
							   [(file)]="threatResponseData.file"
							   (fileChange)="uploadItemsFromCSV()"
							   [(validDrag)]="validDrag"
							   [(invalidDrag)]="invalidDrag"
							   [ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag}"
							   style="text-decoration: unset;" class="hSpace05">
								<i class="fas fa-file-import"></i>
								<span class="action-name" tooltip='A column named "{{threatResponseData.type}}" should contain the links'>Import from CSV</span>
							</a>
							<a (click)="exportThreatResponseListToCsv(threatResponseData.type)"
							   [ngClass]="{'disabled': !threatResponseData.items.length}"
							   style="text-decoration: unset;" class="hSpace05">
								<i class="fas fa-file-export"></i>
								<span class="action-name">Export to CSV</span>
							</a>
						</div>
					</div>
                </div>
            </div>

            <!--STEP: Scan Conditions-->
            <div class="flxClmn f-1-0 animated fadeIn gap-4 p-2" style="z-index: 50;" *ngIf="threatResponseData.step === dic.CONSTANTS.threatResponseSteps.configData">
                <div class="list-group p-2 list-group-flush">
                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px">
                        <div class="bs-col-4 flex-column gap-1">
                            <span>Result:</span>
                            <span *ngIf="threatResponseData.type !== dic.CONSTANTS.inboundThreatResponse.rescan.type"
                                  class="text-muted"
                                  style="font-size: 0.9em;">
                                    Choose what to do if a match is found
                                </span>
                        </div>
                        <div class="bs-col-6 offset-md-2">
                            <app-dropdown-c style="width: 150px;"
                                          *ngIf="threatResponseData.type !== dic.CONSTANTS.inboundThreatResponse.rescan.type"
                                          text="{{threatResponseData.result}}">
                                <li *ngFor="let resultKey of dic.CONSTANTS.inboundThreatResponseResults | keyvalue"
                                    (click)="threatResponseData.result = resultKey.value.name;"
                                    [explanationC]="resultKey.value.tooltip"
                                    [id]="resultKey.value.name">
                                    {{resultKey.value.name}}
                                </li>
                            </app-dropdown-c>

                            <span *ngIf="threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.rescan.type">
                            Will be based on your threat protection configurations and rules
                        </span>
                        </div>
                    </div>
                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px">
                        <div class="bs-col-4 flex-column gap-1">
                            <span>Number of last emails to scan for each mailbox:</span>
                            <span class="text-muted" style="font-size: 0.9em;">Select how many emails to scan, starting from the most recent emails in the mailbox</span>
                        </div>
                        <div class="bs-col-6 offset-md-2 flex-row align-items-center gap-2">
                            <input type="number" min="1" max="1000" style="width: 60px; padding-left: 5px;"
                                   pattern="/^[1-9][0-9]?[0-9]?$|^1000$/"
                                   [(ngModel)]="threatResponseData.emailsCount" />
                            <span class="text-muted">(up to 1000)</span>
                        </div>
                    </div>
                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px">
                        <div class="bs-col-4 flex-column gap-1">
                            <span>Perform Threat Response for this date range:</span>
                        </div>
                        <div class="bs-col-6 offset-md-2">
							<div class="d-flex gap-2" style="max-width: 370px;">
								<div class="flxRow hSpace05 f-1-0" style="align-items: center">
									<span>Date Range:</span>
									<date-picker-c class="f-1-0"
												   [(dateModel)]="threatResponseData.range"
												   [isRange]="true"
												   [dateFormat]="'mm/dd/yyyy'">
									</date-picker-c>
								</div>
							</div>
                        </div>
                    </div>

                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px">
                        <div class="bs-col-4 flex-column gap-1">
                            <span>Notify me</span>
                            <span class="text-muted" style="font-size: 0.9em;">Notify me by email when operation is finished</span>
                        </div>
                        <div class="bs-col-6 offset-md-2 d-flex align-items-center">
                            <input type="checkbox" style="accent-color: var(--bs-primary); width: 1.2rem;"
                                   [(ngModel)]="threatResponseData.notifyOnFinish">
                        </div>
                    </div>

					<div class="list-group-item flex-row align-items-center" style="min-height: 65px">
						<div class="bs-col-4 flex-column gap-1">
							<span>Scan Sent Items Folder</span>
							<span class="text-muted" style="font-size: 0.9em;">Sent items folder will be scanned</span>
						</div>
						<div class="bs-col-6 offset-md-2 d-flex align-items-center">
							<input type="checkbox" style="accent-color: var(--bs-primary); width: 1.2rem;"
								   [(ngModel)]="threatResponseData.scanSentItems">
						</div>
					</div>

                </div>
            </div>

            <!--STEP: Summary-->
            <div class="flxClmn f-1-0 animated fadeIn gap-3 p-2" *ngIf="threatResponseData.step === dic.CONSTANTS.threatResponseSteps.summary">
                <div class="list-group p-2 list-group-flush">
                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px">
                        <div class="bs-col-4">
                            <span>Mailboxes:</span>
                        </div>
                        <div class="bs-col-5 offset-md-1">
                            <span *ngIf="mailboxesTypeSelection === mailboxesType.all"><b>All mailboxes</b></span>
                            <div class="flex-column gap-2" *ngIf="mailboxesTypeSelection === mailboxesType.specific"
                                 style="max-height: 85px; overflow: auto;">
                                <span *ngFor="let mailbox of threatResponseData.mailboxes"><b>{{mailbox}}</b></span>
                            </div>
                        </div>
                    </div>
					<div class="list-group-item flex-row align-items-center" style="min-height: 65px"
						*ngIf="mailboxesTypeSelection === mailboxesType.all && selectedPlansToApplyTo.length">
						<div class="bs-col-4 flex-column gap-1">
							<span>Plans:</span>
							<span class="text-muted" style="font-size: 0.9em;">Results of Threat Response will be displayed under each individual plan's "Threat Response" page</span>
						</div>
						<div class="bs-col-5 offset-md-1">
							<div class="flex-column gap-2"
								 style="max-height: 85px; overflow: auto;">
								<span *ngFor="let adminObj of selectedPlansToApplyTo"><b>{{adminObj.display_email}}</b></span>
							</div>
						</div>
					</div>
                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px">
                        <div class="bs-col-4">
                            <span>Action: </span>
                        </div>
                        <div class="bs-col-5 offset-md-1">
                            <span>{{threatResponseData.action}}</span>
                        </div>
                    </div>
                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px">
                        <div class="bs-col-4">
                            <span>Result: </span>
                        </div>
                        <div class="bs-col-5 offset-md-1">
                            <span>{{threatResponseData.type === dic.CONSTANTS.inboundThreatResponse.rescan.type ? 'Based on your threat protection configurations and rules' : threatResponseData.result}}</span>
                        </div>
                    </div>
                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px">
                        <div class="bs-col-4">
                            <span>Number of last emails to scan for each mailbox: </span>
                        </div>
                        <div class="bs-col-5 offset-md-1">
                            <span>{{threatResponseData.emailsCount}}</span>
                        </div>
                    </div>
                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px" *ngIf="threatResponseData.items && threatResponseData.items.length">
                        <div class="bs-col-4">
                            <span>Inbound Shield will look for the following:</span>
                        </div>
                        <div class="bs-col-5 offset-md-1">
                            <div class="flex-column gap-2" style="max-height: 90px; overflow: auto;">
                                <div *ngFor="let item of threatResponseData.items">
                                    <div class="ellipsis" *ngIf="!item.key && !item.hash">{{item.name}}</div>
                                    <div class="ellipsis" *ngIf="item.name && item.hash">{{item.name}}</div>
                                    <div class="ellipsis" *ngIf="item.key && item.value">{{item.key + ':' + item.value}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list-group-item flex-row align-items-center" style="min-height: 65px">
                        <div class="bs-col-4">
                            <span>Date range: </span>
                        </div>
                        <div class="bs-col-5 offset-md-1">
                            <div>
                                <span>From: {{dateToString(threatResponseData.range.start)}}</span><br/>
                                <span>Until: {{dateToString(threatResponseData.range.end)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--error message and buttons-->
            <div class="flex-row justify-content-between align-items-end mb-3 px-4 w-100">
                <div style="max-height:48px; overflow: auto;">
					 <p class="f-1-0 pe-3 text-danger m-0" style="max-width: 600px; word-wrap: break-word" *ngIf="threatResponseData.error">
						{{threatResponseData.error}}
					</p>
                    <div class="flex-row align-items-center bg-warning border border-warning gap-3 p-2 animated fadeIn" style="--bs-bg-opacity: .15;"
                         *ngIf="threatResponseData.step === dic.CONSTANTS.installPolicySteps.summary">
                        <i class="fas fa-exclamation-circle text-warning" style="font-size: 1rem;"></i>
                        <div class="flex-column">
                            <div class="flex-row gap-1 align-items-center">
                                <i class="fas fa-circle" style="font-size:0.3em;"></i>
                                <span>These new rules will override the exiting rules in the selected sections.</span>
                            </div>
                            <div class="flex-row gap-1 align-items-center">
                                <i class="fas fa-circle" style="font-size:0.3em;"></i>
                                <span>This action is ireversible.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-row gap-2">
                    <btn-c class="popup-action-btn" variant="outlined"
                         *ngIf="threatResponseData.step === dic.CONSTANTS.threatResponseSteps.selectMailboxes"
                         (action)="showThreatResponsePopup = false;">
                        Cancel
                    </btn-c>
                    <btn-c class="popup-action-btn" variant="outlined"
                         *ngIf="threatResponseData.step !== dic.CONSTANTS.threatResponseSteps.selectMailboxes"
                         (action)="threatResponseBackStep();">
                        Back
                    </btn-c>
                    <btn-c class="popup-action-btn h-100"
                         [isDisabled]="!threatResponseData.action || !threatResponseData.result || !threatResponseData.emailsCount || (threatResponseData.step === dic.CONSTANTS.threatResponseSteps.selectAction && (threatResponseData.type !== dic.CONSTANTS.inboundThreatResponse.rescan.type) && !threatResponseData.items.length)"
                         *ngIf="threatResponseData.step !== dic.CONSTANTS.threatResponseSteps.summary"
                         (action)="selectedMailboxes(); threatResponseNextStep();">
                        Next
                    </btn-c>
                    <btn-c class="popup-action-btn h-100"
                         [isDisabled]="!threatResponseData.action || !threatResponseData.result || !threatResponseData.emailsCount || threatResponseInProcess"
                         *ngIf="threatResponseData.step === dic.CONSTANTS.threatResponseSteps.summary"
                         (action)="threatResponseExecute();">
                        Run
                    </btn-c>
                </div>
            </div>
        </div>
    </popup-c>
</div>
