<div class="flxClmn" style="position: relative;"
     [ngStyle]="generatePdfInProcess && {'overflow' : 'hidden'}" >
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Trends and Insights</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Review your account compromise detection trends and get insights and action recommendations</span>
        </div>
    </div>
    <div class="page-inner-content-holder dashboard-page bs-enabled flxClmn" style="padding-top: 0;"
         [ngClass]="{'no-scroll' : generatePdfInProcess}"
         [ngStyle]="{'overflow' : generatePdfInProcess ? 'hidden' : ''}">
        <!--generate pdf loader-->
        <div *ngIf="generatePdfInProcess" class="pdf-generate-loader">
            <div style=" position: absolute; top: calc(50vh - 200px - 40px); left: calc(50vw - 70px); transform: translate(-50%,0); font-size: 4.3rem; opacity: 0.5; align-items: center;"
                 class="animated flxClmn vSpace2 infinite">
                <div class="animated bounce infinite">
                    <i class="far fa-file-pdf grey-to-redFX" style="filter: contrast(1.5) drop-shadow(2px -1px 1px lightgrey);"></i>
                </div>
                <span style="position: relative; font-size: 1.3rem;" class="loading-dots sss">Generating PDF. Please wait</span>
            </div>
        </div>
        <!---->

        <div class="flxRow-between align-items-center gap-2 mt-0 animated fadeIn" style="position: relative; z-index: 45; align-items: center; margin: 20px 0;">
            <div class="period-buttons-group bs-enabled flex-row gap-2">
					<button class="period-button bs-btn bs-btn-light h-100"
							*ngFor="let item of dashboardPeriod | keyvalue: gs.returnZero;"
							[ngClass]="{'chosen' : period === item.value, 'd-none' : item.value.display === dic.CONSTANTS.trendsPeriod.range.display}"
							(click)="changePeriod(item.value);">
                    	{{item.value.display}}
					</button>

                <div class="position-relative range-button">
                    <button class="period-button hSpace05 bs-btn bs-btn-light h-100" id="customPeriodBtn"
                            [ngClass]="{'chosen' : period.display === dic.CONSTANTS.trendsPeriod.range.display}"
                            (click)="showTrendsCustomPeriod = !showTrendsCustomPeriod;">
                        <span *ngIf="period?.display === dic.CONSTANTS.trendsPeriod.range.display" style="font-weight: 300;">
                            From <b class="px-2">{{range?.start | date:'MM/dd/yyyy'}}</b> Until <b class="px-2">{{range.end | date:'MM/dd/yyyy'}}</b>
                        </span>
                        <span *ngIf="period?.display !== dic.CONSTANTS.trendsPeriod.range.display">
                            Custom
                        </span>
                        <i class="fa fa-sort-down" style="margin-top: -5px;"></i>
                    </button>
                    <!--custom period modal-->
                    <div class="drop-menu mt-1 p-2" style="width: fit-content; max-width: none; min-width: 300px; overflow: visible; min-height: 0;"
                         [ngClass]="{'active' : showTrendsCustomPeriod}">
                        <div class="p-3 text-start m-0 flex-row align-items-center gap-2" style="min-width: 490px;"
							 (clickOutside)="showTrendsCustomPeriod = false;" [exclude]="'#customPeriodBtn'">
							<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
								<span>Date Range:</span>
								<date-picker-c class="f-1-0"
											   [(dateModel)]="range"
											   [isRange]="true"
											   [dateFormat]="'mm/dd/yyyy'">
								</date-picker-c>
							</div>
                            <btn-c endIcon="zmdi zmdi-check"
                                 [loading]="getDataInProcess"
                                 (action)="changePeriod(dic.CONSTANTS.trendsPeriod.range);
                                         getTrendsByRangeDates();
                                         showTrendsCustomPeriod = false;">
                                Get Trends
                            </btn-c>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flxRow hSpace1" style="align-items: center;">
                <btn-c endIcon="fas fa-filter" variant="outlined" style="min-width: 0;"
                     [isDisabled]="!users || getTrendsDataInProcess"
                     (action)="openUsersFilterPopup();">
                    Filter Users
                </btn-c>

                <btn-c endIcon="fas fa-file-download" variant="outlined"
                     [loading]="generatePdfInProcess"
                     [isDisabled]="!trendsData || getTrendsDataInProcess"
                     (action)="exportChartsToPdf();">
                    Export to PDF
                </btn-c>
            </div>
        </div>

        <div class="bs-enabled mt-3" id="trendsChartsContainer">
            <div class="bs-container w-100" style="max-width: none !important;">
				<div class="bs-row trends-pdf-page-title" style="display: none;">
					<img class="mb-3" [src]="lfs.logo" style="width: 200px;"/>
				</div>
                <div class="bs-row trends-pdf-page-title" style="display: none;">
                    <div class="bs-col-12 mb-4 px-4" style="background: #fdfdfd; padding: 10px;">
                        <div class="flxClmn vSpace05">
                            <div class="flxRow-between" style="align-items: flex-end;">
                                <span style="font-weight: bold; font-size: 1.6rem; white-space: nowrap;">Account Takeover Protection Trends and Insights</span>
                                <span style="font-size: 1.25rem; white-space: nowrap;">
                                Date printed: &nbsp;
                                <time>{{todayDate | date:'MM/dd/yyyy'}}</time>
                            </span>
                            </div>
                            <div class="flxRow hSpace05" style="font-size: 1.25rem;">
                                <span>Period:&nbsp;</span>
                                <span *ngIf="period !== dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">{{period.display}}</span>
                                <span *ngIf="period === dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">
							<time>{{range.start | date:'MM/dd/yyyy'}}</time>
							&nbsp;-&nbsp;
							<time>{{range.end | date:'MM/dd/yyyy'}}
							</time>
						</span>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                </div>

                <div class="bs-row g-4">
                    <!--Users Location-->
                    <div class="bs-col-6 bs-col-large-4 pdf-excluded" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card overflow-hidden flex-column justify-content-between animated fadeIn h-100 w-100"
                             [ngClass]="{'p-3' : !locations}">
                            <div class="flex-row justify-content-between" style="z-index: 500; transition: all 0.2s;"
                                 [ngClass]="{'fading-title p-3' : locations}">
                                <div class="flex-column align-items-start gap-1">
                                    <div class="flex-row align-items-center gap-1">
                                        <h6 class="m-0 fw-bold">User Activity Locations</h6>
                                        <i class="fas fa-arrow-to-left opacity-50"></i>
                                    </div>
                                    <span class="opacity-75 fw-500 text-start" style="height: 16px;" *ngIf="locations"><b class="text-capitalize">{{locations.type || 'last'}}</b> locations where users have been active</span>
                                </div>
                                <div class="period-buttons-group bs-enabled align-self-center flex-row gap-2 animated fadeIn" *ngIf="locations">
                                    <button class="period-button bs-btn bs-btn-light text-capitalize" style="width: 60px;"
                                            *ngFor="let MapDataType of ['last','all']"
                                            [ngClass]="{'chosen' : MapDataType === locations.type}"
                                            (click)="locations.type = MapDataType;"
                                            tooltip="{{MapDataType == 'last' ? 'Shows the latest locations where users have been active' : 'Shows all locations where users have been active'}}">
                                        <span class="w-100 text-center" >{{MapDataType}}</span>
                                    </button>
                                </div>
                            </div>
                            <div class="f-1-0" *ngIf="locations && !getTrendsDataInProcess">

								<leaflet-c id="lastLocationsMap" *ngIf="locations.type === 'last'"
										   [markers]="locations.last"
										   [heatmapData]="layers.last.overlays.heatmap"
										   [leafletCenter]="leafletCenter.last"
                                         height="100%" width="100%"></leaflet-c>
								<leaflet-c id="allLocationsMap" *ngIf="locations.type === 'all'"
										   [markers]="locations.all"
										   [heatmapData]="layers.all.overlays.heatmap"
										   [leafletCenter]="leafletCenter.all"
										 height="100%" width="100%"></leaflet-c>
                            </div>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!locations || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Devices list-->
                    <div class="bs-col-6 bs-col-large-8" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card flex-column gap-1 p-3 animated fadeIn h-100 w-100">
                            <div class="flex-row justify-content-between">
                                <div class="flex-column align-items-start gap-1">
                                    <div class="flex-row align-items-center gap-1">
                                        <h6 class="m-0 fw-bold">User Devices</h6>
                                        <i class="fas fa-arrow-to-left opacity-50"></i>
                                    </div>
                                    <span class="opacity-75 fw-500 text-start" style="height: 16px;">List of devices from which users have connected to their accounts</span>
                                </div>
                                <div class="period-buttons-group bs-enabled align-self-center flex-row gap-2 animated fadeIn pdf-excluded"
                                     *ngIf="trendsData?.allDevices?.length">
                                    <button class="period-button bs-btn bs-btn-light text-capitalize"
                                            *ngFor="let viewType of ['list','chart']"
                                            [ngClass]="{'chosen' : viewType === devicesViewType}"
                                            (click)="devicesViewType = viewType;">
                                        <i [ngClass]="viewType === 'list' ? 'fas fa-list' : 'fas fa-chart-pie'" style="height: 20px; width: 20px; font-size: 1.1rem; margin-left: 2px;"
                                           tooltip="{{viewType === 'list' ? 'List' : 'Chart'}}"></i>
                                    </button>
                                </div>
                            </div>


                            <div class="flex-column f-1-0 mt-2 p-2 pt-0 animated fadeIn overflow-auto"
                                 *ngIf="trendsData?.allDevices?.length && devicesViewType === 'list' && !getTrendsDataInProcess">
                                <table class="w-100 table table-hover text-start" style="background: #fdfdfd;">
                                    <thead><tr>
                                        <th style="width:25%;">Client</th>
                                        <th style="width:75%;">Users</th>
                                        <th style="width:100px;">
                                            Count
                                            <a class="area" *ngIf="sortCount === '-count'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
                                               (click)="sortCount='count'; devicesOrderBy='count'">
                                                <i class="zmdi zmdi-chevron-down" style="width:10px;"></i>
                                            </a>
                                            <a class="area" *ngIf="sortCount === 'count'" style="margin-top:1px; padding-left:5px;" tooltip="Sort"
                                               (click)="sortCount='-count'; devicesOrderBy='-count'">
                                                <i class="zmdi zmdi-chevron-up" style="width:13px;"></i>
                                            </a>
                                        </th>
                                    </tr></thead>
                                    <tbody>
                                    <tr *ngFor="let deviceObj of trendsData.allDevices | orderBy: devicesOrderBy">
                                        <td>
                                            <span *ngIf="deviceObj.agent.browser">{{deviceObj.agent.browser.name + ', ' + deviceObj.agent.os.name + ' '+ deviceObj.agent.os.version}}</span>
                                            <span *ngIf="deviceObj.agent.edittedRaw && !deviceObj.agent.browser">{{deviceObj.agent.edittedRaw.ua || 'N/A'}}</span>
                                        </td>
                                        <td class="ellipsis" tooltip="{{deviceObj.users.join(', ')}}">
                                            {{deviceObj.users.join(', ')}}
                                        </td>
                                        <td>
                                            {{deviceObj.count}}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="flex-column f-1-0 centralize animated fadeIn" *ngIf="trendsData?.allDevices?.length && devicesViewType === 'chart' && !getTrendsDataInProcess">
								<apx-chart style="width: 100%; height: 100%;"
										   [ngClass]="{'pie-chart-left' : generatePdfInProcess}"
										   [series]="deviceListChartOptions.series"
										   [chart]="deviceListChartOptions.chart"
										   [dataLabels]="deviceListChartOptions.dataLabels"
										   [plotOptions]="deviceListChartOptions.plotOptions"
										   [labels]="deviceListChartOptions.labels"></apx-chart>
                            </div>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.allDevices?.length || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Common Incident Types-->
                    <div class="bs-col-6 bs-col-large-4 order-large-1 inbound-trends" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
                            <div class="flex-row align-items-center gap-1">
                                <h6 class="m-0 fw-bold">Most Common Incident Types</h6>
                                <i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
                                   tooltip="The numbers indicate the total number of suspicious activity incidents of each type across all users."></i>
                                <i class="fas fa-arrow-to-left opacity-50"></i>
                            </div>
							<apx-chart class="f-1-0 animated fadeIn clickable-chart"
									   *ngIf="incidentsTypesChartOptions && !getTrendsDataInProcess"
									   [series]="incidentsTypesChartOptions.series"
									   [chart]="incidentsTypesChartOptions.chart"
									   [colors]="incidentsTypesChartOptions.colors"
									   [dataLabels]="incidentsTypesChartOptions.dataLabels"
									   [plotOptions]="incidentsTypesChartOptions.plotOptions"
									   [xaxis]="incidentsTypesChartOptions.xaxis"></apx-chart>

							<!--actions menu-->
                            <div class="trends-card-actions-menu">
                                <i class="fas fa-ellipsis-h" id="incidentsTypesMenuTrigger" tooltip="Menu"
                                   (click)="showIncidentTypesMenu = !showIncidentTypesMenu"></i>
                                <div class="drop-menu" [ngClass]="{'active' : showIncidentTypesMenu}">
                                    <ul (clickOutside)="showIncidentTypesMenu = false;"
                                        [exclude]="'#incidentsTypesMenuTrigger'">
                                        <li class="flex-row align-items-center" (click)="incidentTypeAction('configurationTab');">
                                            <span>View configurations</span>
                                        </li>
                                        <li class="flex-row align-items-center" (click)="incidentTypeAction('csv');">
                                            <span>Export to CSV</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--tips modal-->
                            <div class="trends-card-actions-menu tips"
                                 [ngClass]="{'active' : showIncidentTypesTips}">
                                <i class="fa fa-lightbulb" id="IncidentTypesTipsTrigger" tooltip="Tips"
                                   (click)="showIncidentTypesTips = !showIncidentTypesTips"></i>
                                <div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
                                     [ngClass]="{'active' : showIncidentTypesTips}">
                                    <ul style="cursor: default;" (clickOutside)="showIncidentTypesTips = false;"
										[exclude]="'#IncidentTypesTipsTrigger'">
                                        <div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
                                            <i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
                                            <span class="fw-500">Click on any bar to view the list of incidents matching that type.</span>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!incidentsTypesChartOptions || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Common Mailboxes-->
                    <div class="bs-col-6 bs-col-large-4 order-large-1 inbound-trends" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
                            <div class="flex-row align-items-center gap-1">
                                <h6 class="m-0 fw-bold">Most Incidents per Mailbox</h6>
                                <i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
                                   tooltip="Shows the mailboxes which had the largest number of suspicious activity incidents. The numbers indicate the incidents across all types."></i>
                                <i class="fas fa-arrow-to-left opacity-50"></i>
                            </div>

							<apx-chart class="f-1-0 animated fadeIn clickable-chart"
									   *ngIf="trendsData?.incidentsPerMailboxGraph?.data?.length && !getTrendsDataInProcess"
									   [series]="incidentsPerMailboxTopChartOptions.series"
									   [chart]="incidentsPerMailboxTopChartOptions.chart"
									   [colors]="incidentsPerMailboxTopChartOptions.colors"
									   [dataLabels]="incidentsPerMailboxTopChartOptions.dataLabels"
									   [plotOptions]="incidentsPerMailboxTopChartOptions.plotOptions"
									   [xaxis]="incidentsPerMailboxTopChartOptions.xaxis"></apx-chart>

                            <!--actions menu-->
                            <div class="trends-card-actions-menu">
                                <i class="fas fa-ellipsis-h" id="topMailboxesMenuTrigger" tooltip="Menu"
                                   (click)="showTopMailboxesMenu = !showTopMailboxesMenu"></i>
                                <div class="drop-menu" [ngClass]="{'active' : showTopMailboxesMenu}">
                                    <ul (clickOutside)="showTopMailboxesMenu = false;"
										[exclude]="'#topMailboxesMenuTrigger'">
                                        <li class="flex-row align-items-center" (click)="mailboxIncidentsAction('incidentsTab');">
                                            <span>View all incidents</span>
                                        </li>
                                        <li class="flex-row align-items-center" (click)="mailboxIncidentsAction('csvAll');">
                                            <span>Export to CSV</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--tips modal-->
                            <div class="trends-card-actions-menu tips"
                                 [ngClass]="{'active' : showTopMailboxesTips}">
                                <i class="fa fa-lightbulb" id="topMailboxesTipsTrigger" tooltip="Tips"
                                   (click)="showTopMailboxesTips = !showTopMailboxesTips"></i>
                                <div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
                                     [ngClass]="{'active' : showTopMailboxesTips}">
                                    <ul style="cursor: default;" (clickOutside)="showTopMailboxesTips = false;"
										[exclude]="'#topMailboxesTipsTrigger'">
                                        <div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
                                            <i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
                                            <span class="fw-500">Click on any bar to view the list of incidents matching that mailbox.</span>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.incidentsPerMailboxGraph?.data?.length || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Common Domains-->
                    <div class="bs-col-6 bs-col-large-4 order-large-1 inbound-trends" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card flex-column gap-1 p-3 animated fadeIn h-100 w-100">
                            <div class="flex-row align-items-center gap-1">
                                <h6 class="m-0 fw-bold">Sensitive Emails per Domain</h6>
                                <i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
                                   tooltip="Shows the domains which were the recipients of most suspicious activity incidents related to sensitive content being sent."></i>
                                <i class="fas fa-arrow-to-left opacity-50"></i>
                            </div>
							<apx-chart class="f-1-0 animated fadeIn clickable-chart"
									   *ngIf="trendsData?.sensitivityDomainsGraph?.data?.length && !getTrendsDataInProcess"
									   [series]="sensitivityDomainsChartOptions.series"
									   [chart]="sensitivityDomainsChartOptions.chart"
									   [colors]="sensitivityDomainsChartOptions.colors"
									   [dataLabels]="sensitivityDomainsChartOptions.dataLabels"
									   [plotOptions]="sensitivityDomainsChartOptions.plotOptions"
									   [xaxis]="sensitivityDomainsChartOptions.xaxis"></apx-chart>
                            <!--actions menu-->
                            <div class="trends-card-actions-menu">
                                <i class="fas fa-ellipsis-h" id="topDomainsMenuTrigger" tooltip="Menu"
                                   (click)="showTopDomainsMenu = !showTopDomainsMenu"></i>
                                <div class="drop-menu" [ngClass]="{'active' : showTopDomainsMenu}">
                                    <ul (clickOutside)="showTopDomainsMenu = false;"
										[exclude]="'#topDomainsMenuTrigger'">

                                        <li class="flex-row align-items-center" (click)="sensitivityDomainIncidentsAction('csvAll');">
                                            <span>Export to CSV</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--tips modal-->
                            <div class="trends-card-actions-menu tips"
                                 [ngClass]="{'active' : showTopDomainsTips}">
                                <i class="fa fa-lightbulb" id="topDomainsTipsTrigger" tooltip="Tips"
                                   (click)="showTopDomainsTips = !showTopDomainsTips"></i>
                                <div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
                                     [ngClass]="{'active' : showTopDomainsTips}">
                                    <ul style="cursor: default;" (clickOutside)="showTopDomainsTips = false;"
										[exclude]="'#topDomainsTipsTrigger'">
                                        <div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
                                            <i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
                                            <span class="fw-500">Click on any bar to view the list of incidents matching that domain.</span>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!sensitivityDomainsChartOptions || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <popup-c *ngIf="incidentsPopup?.show"
           head="Suspicious Activity Information" size="large"
           (closeCb)="incidentsPopup = null;"
           [buttons]="{cancel: 'OK'}">
        <div class="flxClmn f-1-0" style="overflow: auto;">
            <!--incidents info table-->
            <trustifi-table-c
                    class="trustifi-table" style="flex: 1 0; z-index: 5;"
                    [list]="incidentsPopup.incidents"
                    [headers]="[
                        {text: 'Created', sortKey: 'created', width: '25%'},
                        {text: 'Type', sortKey: 'incident_type'},
                    ]"
                    [cellsPrototype]="[
                        {textKey: 'created', textType: 'dateAndTime'},
                        {html: 'incidentTypeCell'},
                    ]"
                    [cellActions] = "{
                        enable: true,
                        selectItemActionCb:	selectIncidentAction,
                        initActionsFunction: showIncidentInfoActions
                    }"
                    [options]="{
                        itemsNameSingular: 'incident',
                        defaultSortKey: '-created'
                    }"
            >
				<ng-template #customCells let-item let-cellId="cellId">
					<!--incident type cells-->
					<div *ngIf="cellId === 'incidentTypeCell'">
						<span style="color: darkred;">
                            {{dic.CONSTANTS.accountCompromisedIncidentTypes[item.incident_type].name}}
                        </span>
					</div>
				</ng-template>
            </trustifi-table-c>
        </div>
    </popup-c>

    <popup-c class="bs-enabled" *ngIf="usersPopup?.show"
           head="Select Users for Trends" size="medium"
           (closeCb)="usersPopup.show = false;"
           (confirmCb)="applyUsersFilter()"
           [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">
        <div class="flxClmn gap-3 f-1-0" style="overflow: auto;">
            <span>Select one or more users to get trends information for:</span>
            <div class="flxClmn f-1-0 gap-2" style="overflow: auto;">
                <label class="radio-container m-0" style="display: block;">
                    All users
                    <input type="radio"
                           [(ngModel)]="usersPopup.type"
                           [value]="usersFilterTypes.all">
                    <span class="checkmark"></span>
                </label>
                <div class="flex-row gap-1 align-items-center">
                    <label class="radio-container m-0" style="display: block; width: fit-content;">
                        Specific users:
                        <input type="radio"
                               [(ngModel)]="usersPopup.type"
                               [value]="usersFilterTypes.specific">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="position-relative f-1-0 flex-column overflow-auto">
                    <!--table overlay when disabled-->
                    <div class="w-100 h-100" style="background-color: white; position: absolute; top: 0; left: 0; z-index: 20; opacity: 0.4;"
                         *ngIf="usersPopup.type === usersFilterTypes.all">
                    </div>
                    <trustifi-table-c
                            class="trustifi-table f-1-0" style="z-index: 5;"
                            [list]="usersPopup.users"
                            [cellsPrototype]="[
                                      {textKey: 'email'},
                                  ]"
                            (searchFunction)="searchUser($event.searchTerm, $event.activeFilters)"
                            [options]="{
                                    itemsNameSingular: 'user',
                                    showRadioButtons: true,
                                    noHeader: true,
                                    showCounter: true,
                                }"
                    >
                    </trustifi-table-c>
                </div>
            </div>
        </div>
    </popup-c>

	<user-incidents-info-component></user-incidents-info-component>
</div>


