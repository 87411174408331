import _ from 'lodash';
import {DICTIONARY} from '../../dictionary';
import {GeneralService} from '../../services/generalService';
import {RouteService} from '../../services/routeService';
import {Component, NgZone, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";


@Component({
	selector: 'knowledge-component',
	templateUrl: './knowledge.component.html',
})
export class KnowledgeComponent implements OnInit, OnDestroy{
    constructor(private rs:RouteService,
				public router:Router,
				public activatedRoute:ActivatedRoute,
				public gs:GeneralService,
				private ngZone:NgZone) {
    }

    dic = DICTIONARY;
    searchQuery = '';
    searchResultsList = [];
    showResultModal = false;
    highlightSearchResults = false;
    highlightSearchText = '';
    infoLibraries;
    selectedLibrary;
    showScrollToTop;
    searchInProcess;
    sensitivityEngineList;
	parentScrollableElement;
    // set to info page by default (and not to main) since the access to knowledge main page is only through the info pages currently :
    pageModule = this.dic.CONSTANTS.supportPageModules.info;
	_=_;

	whatsNewData = [
		{
			date: Date.parse("12/12/2024"),
			features: [
				"<b>Threat Simulation Training Campaigns</b> - A new addition to Trustifi's security awareness module. You may now <a class='blue-link' target='_self' href='threatSimulation/campaigns/trainingCampaigns'>create training campaigns</a> that are meant to distribute security awareness materials to users and help educate against security threats. You can also upload your own training materials in the form of PDF files and links in the new <a class='blue-link' target='_self' href='threatSimulation/trainingMaterials'>Training Materials page</a>.",
				"<b>Outbound Quarantine Reviewer - View Content Permission</b> - Configure settings to enable each individual reviewer to access the content of quarantined emails by navigating to the <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/reviewers'>Outbound Reviewers page</a>.",
			]
		},
		{
			date: Date.parse("12/02/2024"),
			features: [
				"<b>Inbound Reports Mailbox Filter</b> - In the inbound reports that can be generated or scheduled in the <a class='blue-link' target='_self' href='inbound/trends'>Trends & Insights page</a>, you can now filter the results of the report include data for specific mailboxes only."
			]
		},
		{
			date: Date.parse("11/28/2024"),
			features: [
				"<b>Threat Simulation: Attachment Campaign</b> - A new kind of <a class='blue-link' target='_self' href='threatSimulation/campaigns'>Threat Simulation campaign</a> is now available to be used with an attachment as the attack vector. Currently available using the template named \"IT Department Policy Update\"."
			]
		},
		{
			date: Date.parse("11/25/2024"),
			features: [
				"<b>Inbound and Outbound Rules Week Day Conditions</b> - In both <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Rules</a> and <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>Outbound Rules</a>, you can now create rules based on week day an email has been sent or received.",
			]
		},
		{
			date: Date.parse("11/24/2024"),
			features: [
				"<b>Inbound and Outbound Rules Time-Based Conditions</b> - In both <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Rules</a> and <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>Outbound Rules</a>, you can now create rules based on what time of the day an email has been sent or received.",
			]
		},
		{
			date: Date.parse("11/13/2024"),
			features: [
				"<b>Threat Simulation Trends and Insights</b> - Some new additions were made to the <a class='blue-link' target='_self' href='threatSimulation/trends'>Trends and Insights page</a> in the Threat Simulation module: a campaign activity section that shows when each user has last received a Threat Simulation campaign, and an option to view some metrics based on groups instead of based on users.",
			]
		},{
			date: Date.parse("11/11/2024"),
			features: [
				"<b>Threat Simulation: Add Recipients</b> - You can now add new recipients to a Threat Simulation campaign that has already been sent or scheduled. This function is available from the \"Actions\" menu on existing campaigns in the <a class='blue-link' target='_self' href='threatSimulation/campaigns'>Threat Simulation Campaigns page</a>.",
			]
		},
		{
			date: Date.parse("11/05/2024"),
			features: [
				"<b>Inbound Email Rules: \"Names\" Condition</b> - You can now create inbound email rules based on the display names of the sender or the recipients. This can be found in the <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Email Rules page</a>.",
				"<b>Inbound Quarantine: Connections Graph</b> - You can now view a visual diagram of the connections between a sender and your organization's users in the \"Relationship\" section of the Threat Analysis view for emails in the <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Inbound Quarantine</a>."
			]
		},
		{
			date: Date.parse("10/28/2024"),
			features: [
				"<b>Threat Simulation Relaunch Campaign</b> - A new feature has been added for <a class='blue-link' target='_self' href='threatSimulation/campaigns'>Threat Simulation Campaigns</a> - \"Relaunch Campaign\". This feature allows to re-launch a previous campaign with all of the existing configurations, which can be edited prior to launching."
			]
		},
		{
			date: Date.parse("10/15/2024"),
			features: [
				"<b>Outbound Rules MTA Condition</b> - In the <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>Outbound Rules</a> you may now create conditions based on the sending MTA. The MTA field can include the sender's IP, CIDR, or hostname.",
				"<b>Outbound Quarantine \"Keep Record\" Setting</b> - When creating <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>Outbound Rules</a> to quarantine sent emails, you can now select whether you want to keep a record of the quarantined email in your outbound quarantine or not.",
			]
		},
		{
			date: Date.parse("10/10/2024"),
			features: [
				"<b>Send Account Takeover Incident Notifications to Additional Recipients</b> - You can now configure additional recipients to receive notifications about suspicious activity in the account takeover protection module, these recipients do not have to be part of your Trustifi plan. Can be configured from the \"Add Notification Recipients\" section in the <a class='blue-link' target='_self' href='accountCompromised/configurations/reviewers'>Reviewers tab</a>.",
			]
		},
		{
			date: Date.parse("10/08/2024"),
			features: [
				"<b>New Feature: Domain Impersonation Detection</b> - The new <a class='blue-link' target='_self' href='domainProtection/impersonation'>Domain Impersonation</a> detection feature scans the internet for domains that are suspected of impersonating your internal domains. If any suspected impersonation attempt is found, Trustifi will display all available information about the domain including indications of phishing activity.",
				"<b>Look & Feel Modify Logo Size</b> - In the <a class='blue-link' target='_self' href='outbound/adminLF'>Look & Feel page</a>, you can now modify the size of your custom uploaded logo to better fit your encrypted emails and notifications.",
			]
		},
		{
			date: Date.parse("10/01/2024"),
			features: [
				"<b>Integrations page new UI</b> - The Integrations page has undergone an overall UI improvement to better display and explain the different integrations available in Trustifi. Find it in \"Plan Settings\" page for <a class='blue-link' target='_self' href='inbound/plan/integrations'>Inbound</a> or <a class='blue-link' target='_self' href='outbound/adminSharedPlan/integrations'>Outbound</a>",
				"<b>Splunk Integration</b> - You can now easily integrate Trustifi with your Splunk instance to automatically receive logs and reports for quarantined emails and account takeover protection events. This is available from the <a class='blue-link' target='_self' href='outbound/adminSharedPlan/integrations'>Integrations page</a>",
				"<b>Account Takeover View Email Content</b> - You can now view the content of emails suspected to be actions of Account Takeover incidents. This can be done from the \"Actions\" menu in the <a class='blue-link' target='_self' href='accountCompromised/incidents'>Incidents and Activity page</a>. Note that reviewers must be assigned permissions to view email content first in the <a class='blue-link' target='_self' href='accountCompromised/configurations/reviewers'>Reviewers page</a>."
			]
		},
		{
			date: Date.parse("09/24/2024"),
			features: [
				"<b>Threat Simulation Multiple Campaign Templates</b> -  It is now possible to use multiple different templates when creating a new <a class='blue-link' target='_self' href='threatSimulation/campaigns'>Threat Simulation campaign</a>. The templates will be used randomly between the campaign recipients.",
			]
		},
		{
			date: Date.parse("09/12/2024"),
			features: [
				"<b>Assign Reviewers to Individual Mailboxes</b> - Inbound reviewers can now be assigned to individual mailboxes, in addition to groups. This can be done from the <a class='blue-link' target='_self' href='inbound/inboundShield/reviewers'>Inbound Reviewers tab</a>.",
				"<b>Allowed Message Recall Period</b> - Admins can now set the allowed recall period for recalling sent emails in the <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/policies'>Outbound Policies page</a>. When this is configured, sent emails can only be recalled during the set amount of time after sending."
			]
		},
		{
			date: Date.parse("09/11/2024"),
			features: [
				"<b>Modify User Notification Schedule</b> - Admins can now view and modify the quarantine notification schedule of their users. This can be done using the \"Notification Schedule\" in the \"Actions\" menu of the <a class='blue-link' target='_self' href='inbound/mailboxes'>Mailbox management page</a>",
				"<b>Threat Simulation Resend Training Materials</b> - After sending a Threat Simulation campaign, you can now re-send the training materials to all users who have not yet acknowledged participation in the campaign. This can be done using the \"Resend training materials\" action in the <a class='blue-link' target='_self' href='threatSimulation/campaigns'>Campaigns page</a>"
			]
		},
		{
			date: Date.parse("09/04/2024"),
			features: [
				"<b>Outbound Journal Architecture</b> - A new type of outbound architecture can now be configured from the <a class='blue-link' target='_self' href='outbound/adminSharedPlan/emailFlowIntegration'>Email Flow Integration page</a>: <b>Journal</b>. With this architecture, all outbound emails will be delivered directly to recipients, while Trustifi will scan a journaled copy for sensitivity."
			]
		},
		{
			date: Date.parse("08/28/2024"),
			features: [
				"<b>Inbound Email Rules: Recipient Messages</b> - You can now add custom messages to recipients when creating <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Email Rules</a> that have \"Notify Recipient\" action. These messages can be used to explain to users why their received emails were flagged by inbound rules."
			]
		},
		{
			date: Date.parse("08/21/2024"),
			features: [
				"<b>Data Classification Trends</b> - We've added the <a class='blue-link' target='_self' href='outbound/dataClassification/trends'>Data Classification Trends page</a> which gives an overview of the most common email classification types, including sensitive emails by sender and domain, along with compliance trends within your organization."
			]
		},
		{
			date: Date.parse("08/11/2024"),
			features: [
				"<b>Inbound Journal Architecture</b> - A new type of inbound architecture can now be configured from the <a class='blue-link' target='_self' href='inbound/plan/emailFlowIntegration'>Email Flow Integration page</a>: <b>Journal</b>. With this architecture, all inbound emails will be delivered directly to recipients and Inbound Shield will scan a journaled copy of emails. With the Exchange/Google API integration active, actions (like quarantine) can also be automatically taken on emails if threats are found."
			]
		},
		{
			date: Date.parse("08/04/2024"),
			features: [
				"<b>New Page: Data Classification</b> - We've added the <a class='blue-link' target='_self' href='outbound/dataClassification'>Data Classification page</a> under the \"Outbound Management\" section. From this page admins and reviewers can view and manage DLP sensitivity types, create custom sensitivity types that can be used in DLP rules, and test the sensitivity engine."
			]
		},
		{
			date: Date.parse("07/17/2024"),
			features: [
				"<b>Configuration for Viewing Email Content per Mailbox</b> - You may now configure content view permissions for each user mailbox in the <a class='blue-link' target='_self' href='inbound/mailboxes'>Mailbox Management page</a>. For each mailbox, content view permissions can be enabled or disabled for reviewers and for admins separately.",
				"<b>Outbound Rules Industry Presets</b> - You can now create automated rule presets in the <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>Outbound Rules page</a>. These automated presets are customized and useful for specific industries like healthcare, finance, and more.",
				"<b>View Content Notification</b> - You can now set up automated notifications to be sent whenever an inbound reviewer views the content of a quarantined email. This can be enabled from the <a class='blue-link' target='_self' href='inbound/inboundShield/reviewers'>Inbound Reviewers page</a>.",
			]
		},
		{
			date: Date.parse("07/14/2024"),
			features: [
				"<b>Outbound Rules: New Condition for Free Email Domains</b> - A new condition has been added to the <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>Outbound DLP rules</a>: allowing you to create rules based on emails being sent to free email domains such as Gmail or Yahoo mail.",
			]
		},
		{
			date: Date.parse("07/11/2024"),
			features: [
				"<b>Custom Threat Prevention Rules Presets</b> - You are now able to create custom presets of <a class='blue-link' target='_self' href='inbound/inboundShield/threatPreventionRules'>Threat Prevention Rules</a> for added flexibility of handling different types of threats for different users/groups. These custom rule presets can be used by creating an <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Email rule</a> with the action \"Apply Custom Threat Prevention Rules\".",
				"<b>Inbound Email Rules: Email Size Condition</b> - A new condition has been added to the <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Email Rules</a>, which allows to perform actions on inbound emails based on the received email's total size.",
			]
		},
		{
			date: Date.parse("07/03/2024"),
			features: [
				"<b>New Condition for Outbound Rules</b> - A new condition has been added to the <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>outbound DLP rules</a>: you can now set rules based on if (or if not) a sent email contains security methods like \"Encrypt Message Content\" or \"Require Authentication\".",
			]
		},
		{
			date: Date.parse("07/02/2024"),
			features: [
				"<b>Audit Log Scheduled Reports</b> - Admins and reviewers can now subscribe to scheduled reports in all audit log pages.",
			]
		},
		{
			date: Date.parse("07/01/2024"),
			features: [
				"<b>Selective Protection for Smart Banners</b> - Smart Banners can now be enabled on specific user mailboxes from the <a class='blue-link' target='_self' href='inbound/inboundShield/securityAwareness'>Smart Banner page</a>.",
			]
		},
		{
			date: Date.parse("06/20/2024"),
			features: [
				"<b>Defang Malicious URLs in Threat Analysis</b> - A new feature added in the <a class='blue-link' target='_self' href='inbound/inboundShield/configuration'>Inbound Shield Configuration page</a> allows URLs that were detected as malicious to be automatically defanged and become unclickable when viewing the \"Threat Analysis\" for increased security.",
			]
		},
		{
			date: Date.parse("06/06/2024"),
			features: [
				"<b>Recall Emails</b> - A new functionality in the <a class='blue-link' target='_self' href='inbound/personalPages/mailbox'>Sent Emails</a> page that allows users to recall sent emails and remove them from mailboxes of internal recipients. This feature is available for Trustifi plans that are connected to either Office365 or Google Workspace API integrations.<br/>To use this feature you need to enable \"Allow Recalling Messages\" from <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/policies'>Outbound Policy page</a>.",
			]
		},
		{
			date: Date.parse("06/05/2024"),
			features: [
				"<b>Attachments Preview Only Mode</b> - When sending an encrypted email with attachments, you may now select the \"Preview only mode\" from the \"Advanced\" menu. When this mode is selected, recipients will only be able to preview attachments and will not be able to download them.",
			]
		},
		{
			date: Date.parse("06/03/2024"),
			features: [
				"<b>\"Threat Sub-Category\" Condition for Inbound Email Rules</b> - <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Email Rules</a> can now be created based on the specific threat sub-category which was found during the email scan.",
			]
		},
		{
			date: Date.parse("06/02/2024"),
			features: [
				"<b>File Preview</b> - Various file types that have been sent or received via Trustifi can now be safely previewed from the portal. Files can be previewed in these sections: Secure Storage, Sent Emails, Backup Inbox, Inbound Quarantine, Outbound Quarantine, and Archive.",
			]
		},
		{
			date: Date.parse("05/21/2024"),
			features: [
				"<b>Inbound Quarantine Emails - Rescan link</b> - We've introduced a new feature that allows you to re-scan a specific link directly from the Threat Analysis view at <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Quarantined Emails page</a>.",
				"<b>Inbound Quarantine Emails - Rescan attachment</b> - We've introduced a new feature that allows you to re-scan a specific attachment directly from the Threat Analysis view at <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Quarantined Emails page</a>.",
				"<b>Inbound / Outbound Rules</b> - We've added a new feature for <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>Outbound rules</a> and <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound rules</a>: the ability to negate rule conditions. This enhancement allows you to create rules that can exclude specific conditions. For example, instead of only specifying which traffic to allow or block, you can now define rules to exclude certain types of traffic from being allowed or blocked, providing greater flexibility and control over your network traffic management.",
			]
		},
		{
			date: Date.parse("05/07/2024"),
			features: [
				"<b>Inbound Shield: Phishing Simulation Bypass</b> - A new option was added at <a class='blue-link' target='_self' href='inbound/inboundShield/configuration'>Inbound Shield Configuration page</a> to add MTA/IPs in order to bypass scans and quarantines.",
			]
		},
		{
			date: Date.parse("05/05/2024"),
			features: [
				"<b>Threat Simulation Templates</b> - New <a class='blue-link' target='_self' href='threatSimulation/templates'>templates</a> have been added for use in threat simulation campaigns. These templates enhance the range of scenarios available for simulating potential threats, enabling more comprehensive testing and preparedness.",
			]
		},
		{
			date: Date.parse("04/16/2024"),
			features: [
				"<b>Block user login by location</b> - A new feature added to the <a class='blue-link' target='_self' href='outbound/adminUserManagement'>User Management page</a>: Block access to the application according to the location from which the login is performed. This can be configured at More Actions -> Log in options",
			]
		},
		{
			date: Date.parse("03/31/2024"),
			features: [
				"<b>Threat Simulation Training and Awareness program</b> - Innovative solution emerges from the pressing need for a more effective way to educate users about the complexities of email threats.<br/><b>Key Features:</b><br/><ul><li>Real-World Templates: Dive into an extensive library of sophisticated templates, meticulously crafted from actual cyber-attacks. These scenarios provide an immersive learning experience, simulating real-world threats with unparalleled accuracy.</li><li>Smart Banners for Immediate Learning: In a unique approach to reinforcing cybersecurity best practices, our program introduces smart-banners. These intelligent prompts can be activated when a user fails a simulation, offering immediate, targeted guidance. It's not just about identifying mistakes; it's about transforming them into teachable moments, enabling users to recognize and react to malicious emails effectively.</li></ul>",
			]
		},
		{
			date: Date.parse("03/28/2024"),
			features: [
				"<b>Restore emails to recipient mailbox</b> - A new feature added to the <a class='blue-link' target='_self' href='archive/archive'>Archive</a> and <a class='blue-link' target='_self' href='personalPages/inbox'>Backup Inbox</a> pages. You can now use this feature to restore emails from the archive into recipient mailboxes from your internal and verified domains.",
			]
		},
		{
			date: Date.parse("03/26/2024"),
			features: [
				"<b>Automatically Release Quarantined Emails from Exchange</b> - New feature added in the <a class='blue-link' target='_self' href='inbound/plan/integrations'>Integrations page</a>. You can now have emails automatically released from the Exchange quarantine and into Trustifi, so you can automatically consolidate both quarantine flows into one.",
			]
		},
		{
			date: Date.parse("03/19/2024"),
			features: [
				"<b>Threat Simulation on Google</b> - Threat Simulation is now supported for Google Workspace users. We've updated our <a class='blue-link' target='_self' href='threatSimulation/configuration/configuration'>configurations page</a> to provide instructions for Google environments.",
				"<b>New Threat Simulation Templates</b> - We've added more Threat Simulation campaign templates like Paypal invoices, LinkedIn messages, Teams meeting and more.",
				"<b>Threat Simulation Login Campaigns</b> - When creating a Threat Simulation campaign you may now select which landing page users should be directed to if they click on the campaign link. A new type of landing page was added - \"Login\", which will test if users who fail the simulation will also enter their credentials.",
			]
		},
		{
			date: Date.parse("03/12/2024"),
			features: [
				"<b>Reviewer notifications for user reports</b> - Inbound reviewers can now be automatically notified whenever a user reports an email as malicious/spam/safe, and take actions on the reported email. You can enable this option from the <a class='blue-link' target='_self' href='inbound/inboundShield/threatPreventionRules'>Threat Prevention Rules page</a>.",
			]
		},
		{
			date: Date.parse("03/05/2024"),
			features: [
				"<b>Global Reviewer Permissions</b> - The global reviewer permission management, which can be performed in the <a class='blue-link' target='_self' href='outbound/adminUserManagement'>User Management page</a> has been expanded. Global reviewers can now be assigned to all Trustifi modules: inbound, outbound, account takeover protection, archiving, and threat simulation.",
			]
		},
		{
			date: Date.parse("02/26/2024"),
			features: [
				"<b>Exchange quarantine synchronization</b> - Exchange users will now be able to sync their quarantined emails from Exchange into Trustifi. The synchronization can be enabled from the PowerShell integration section of the integrations page, and the quarantined emails from Exchange can be viewed in the inbound quarantine page by selecting the \"Microsoft Exchange\" source. Emails from the Exchange quarantine can also be released from the Trustifi portal.",
				"<b>User quarantine notification settings</b> - Admins may now allow users to set their own notification timing settings for quarantined emails. The new admin setting can be found under the \"Notification Options\" window in the \"Threat Prevention Rules\" page, and when enabled users can set their notification timing in the Inbound Quarantine page."
			]
		},
		{
			date: Date.parse("02/22/2024"),
			features: [
				"<b>Ability to save filter templates</b> - In the <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Inbound Quarantine</a> and <a class='blue-link' target='_self' href='outbound/adminUserManagement'>User Management</a> pages, you are now able to save and edit multiple templates of filters. This will allow you to easily switch between different data displays on these pages.",
				"<b>Dark Mode</b> - Dark Mode is now available for use in the Outlook add-in, as well as the encrypted email portal.",
			]
		},
		{
			date: Date.parse("02/19/2024"),
			features: [
				"<b>Exchange PowerShell integration</b> - A new type of integration has been added to the outbound and inbound <a class='blue-link' target='_self' href='inbound/plan/integrations'>Integrations page</a>: Exchange Server PowerShell. Connecting to this integration will allow performing actions like setting up Trustifi mail flow in Exchange, modifying Microsoft Defender settings, and more.",
			]
		},
		{
			date: Date.parse("02/07/2024"),
			features: [
				"<b>User time zone selection</b> - Users can now select their local time zone in the <a class='blue-link' target='_self' href='accountDetails/myAccount/myProfile'>\"My Profile\"</a> page. This time zone will be used to show dates and times for reports sent by the system, such as quarantined email reports.",
				"<b>Personal reviewer custom notification times</b> - It is now possible to select custom notification times for personal reviewers, so that inbound quarantine reports will be sent at the specific selected hours.",
			]
		},
		{
			date: Date.parse("01/23/2024"),
			features: [
				"<b>Rules support for \"OR\" condition</b> - The <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>Outbound DLP rules</a> and <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound email rules</a> now support the addition of \"OR\" conditions to allow more complex and specific configurations.",
			]
		},
		{
			date: Date.parse("01/18/2024"),
			features: [
				"<b>Rules UI improvement</b> - Our <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/rules'>Outbound rules</a> and <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound rules</a> sections have received a UI upgrade for improved usability.",
				"<b>Inbound Reviewer - Configuration Permission</b> - It is now possible to control the ability of <a class='blue-link' target='_self' href='inbound/inboundShield/configuration'>Inbound Reviewers</a> to change configurations. If the \"Change configuration\" flag is disabled, reviewers may only view and control emails in quarantine.",
				"<b>New AWS region: Australia</b> - This new region is available to be selected from the <a class='blue-link' target='_self' href='inbound/plan/region'>Regions page</a>. Encrypted data will be stored in the selected AWS region.",
				"<b>Senders Lists - Match Types</b> - In the <a class='blue-link' target='_self' href='inbound/configuration/senderLists'>inbound senders allow and block lists</a>, you may now use different match types: \"Starts with\", \"Ends with\", and \"Contains\".",
				"<b>Improvement for release requests</b> - There is now a setting in the inbound notification options that changes will display release requests as been sent from the original recipient of the quarantined email, instead of the default Trustifi Service sender.",
			]
		},
		{
			date: Date.parse("01/10/2024"),
			features: [
				"<b>Inbound Email Rules - New Functionality</b> - There are 3 new available actions in the <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Email Rules</a>: adding text to the email subject, adding an email footer, and adding a warning label to the email.",
				"<b>Domain Spoofing Control</b> - New information has been added in the \"Analyze Domain\" section. We will now also display information about the domain's MX record and the MTA-STS policy.",
			]
		},
		{
			date: Date.parse("12/04/2023"),
			features: [
				"<b>Outbound Policy - Attachments Encryption Policy</b> - The Attachments Encryption Policy has been extended to encompass all outgoing emails, including those originating from ER, Outlook Client, Chrome Extension, and the Web application. Users now have the option to override this policy through their advanced settings, facilitated by the introduction of a new flag. Can be configured at <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/policies'>Policies page</a>.",
			]
		},
		{
			date: Date.parse("11/16/2023"),
			features: [
				"<b>User Management - View All Reviewers</b> - We've implemented a new feature for viewing all reviewer types, including Inbound, Outbound, Archive, and Account Takeover. You now have the flexibility to configure these settings for individual users using the 'Modify Permissions' action. Additionally, a new filter for sorting reviewers by type has been introduced, enhancing the overall user experience and system functionality. Can be configured at <a class='blue-link' target='_self' href='outbound/adminUserManagement'>User Management page</a>.",
				"<b>Outbound Quarantine Emails - Reviewers</b> - We've implemented a dedicated \"Reviewers\" section within the Outbound Quarantine feature. Reviewers will now have specialized access to information related to Outbound Quarantined emails. Can be configured at <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/reviewers'>Reviewers page</a>.",
				"<b>Outbound Email - Enforce Reply All</b> - A new advanced setting that ensures that when someone receives an encrypted email and chooses to reply, their response is sent to all the original recipients of the email.",
			]
		},
		{
			date: Date.parse("11/14/2023"),
			features: [
				"<b>User Management - Read Only Admin Permission</b> - We have introduced a new feature that allows administrators to have read-only permissions. With this setting, administrators will be able to view configurations but will not have the ability to make any changes. Can be configured at <a class='blue-link' target='_self' href='outbound/adminUserManagement'>User Management page</a>.",
				"<b>Account Takeover Protection - Reviewers</b> - We've implemented a dedicated \"Reviewers\" section within the Account Takeover Protection feature. Reviewers will now have specialized access to information related to Account Takeover incidents. Can be configured at <a class='blue-link' target='_self' href='accountCompromised/configurations/reviewers'>Reviewers Configurations page</a>.",
			]
		},
		{
			date: Date.parse("11/11/2023"),
			features: [
				"<b>Archive Reviewer - View Content Permission</b> - Configure access for individual reviewers to view the content of archived emails by navigating to the <a class='blue-link' target='_self' href='archive/configurations'>Archive Configurations page</a>.",
				"<b>Inbound Shield Reviewer - View Content Permission</b> - Configure settings to enable each individual reviewer to access the content of quarantined emails by navigating to the <a class='blue-link' target='_self' href='inbound/inboundShield/configuration'>Inbound Shield Configurations page</a>.",
				"<b>Inbound Email Rules - Regex Match Type</b> - Introduce a new match type for regular expressions tailored specifically for Address conditions and Keywords. This addition aims to enhance the versatility and precision of matching patterns within addresses and keywords, allowing for more nuanced and accurate searches or validations. This improvement ensures a more robust and adaptable system for handling diverse sets of data. Check it out at <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Email Rules page</a>.",
			]
		},
		{
			date: Date.parse("11/07/2023"),
			features: [
				"<b>Plan Settings (Inbound/Outbound)</b> - We've implemented a wizard that facilitates the seamless switching of your Microsoft Exchange connector type between \"Your organization's email server\" and \"Partner organization\".",
				"<b>Reports (Inbound/Outbound)</b> - We have integrated the Trends & Insights graphs into both the scheduled Outbound and Inbound reports.",
				"<b>Inbound Sender Allowlist Advanced Settings</b> - We've broadened the scope of the advanced settings for individual sender allowlist, allowing for the release of emails containing potentially malicious links or files. See changes at <a class='blue-link' target='_self' href='inbound/configuration/senderLists'>Sender Lists page</a>.",
			]
		},
		{
			date: Date.parse("11/02/2023"),
			features: [
				"<b>Inbound Quarantine Emails</b> - We have enhanced our services for partners by introducing a new feature. Now, partners can conveniently access and review quarantined records from all managed plans through a centralized view on the <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Quarantined Emails page</a>.",
				"<b>Inbound Quarantine Emails - Reject Release Request</b> - We've incorporated a new option for rejecting the release request of an email. In this scenario, an email notification will be sent to the requester, indicating that the email has been thoroughly reviewed by our IT team. They have determined that the email poses a potential threat and therefore cannot be released.",
				"<b>Inbound Quarantine Emails - Rescan Email</b> - We've introduced a new feature that allows you to re-scan email content directly from the Threat Analysis view at <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Quarantined Emails page</a>.",
				"<b>Inbound Email Rules - New Condition</b> - We've implemented a new condition within the inbound rule, enabling the matching of email scan category by type: Malicious, Suspicious, Spam, Graymail, External. This new feature facilitates exceptions to the threat prevention rules, and you can access it directly on the <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Email Rules page</a>.",
				"<b>Inbound Email Rules - New Action</b> - We've introduced a new feature that allows you to release emails by adding an action to the inbound rule. This functionality grants you the ability to make exceptions to the threat prevention rules. You can access this feature directly on the <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>Inbound Email Rules page</a>.",
			]
		},
		{
			date: Date.parse("10/25/2023"),
			features: [
				"<b>Policy | Block outbound emails for attachments types</b> - The recently added feature on the <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/policies'>Outbound Policies page</a>, \"Block outbound emails for attachment types,\" enables the blocking of emails that contain attachment types such as Macros, Scripts, or Executables.",
				"<b>Phone Authentication Method - new supported countries</b> - We now support phone authentication via SMS in Greece and Mauritius.",
			]
		},
		{
			date: Date.parse("10/03/2023"),
			features: [
				"<b>Backup Mail</b> - Clients of the archiving module will now have access to the <a class='blue-link' target='_self' href='personalPages/inbox'>Backup Inbox</a>: a new page where each user can have access to their own sent and received emails, even if their primary email client is unavailable.",
				"<b>Threat Response capabilities for partners</b> - Partners who manage multiple clients can now run <a class='blue-link' target='_self' href='inbound/threatResponse'>Threat Response</a> for multiple Trustifi clients/plans at the same time from one place.",
				"<b>Improved detection for QR phishing emails</b> - Using advanced methods and AI technology, Trustifi can now successfully detect 99% of QR-based phishing (Quishing) emails.",
				"<b>Integration for spam and phishing reports</b> - A new section has been added under the <a class='blue-link' target='_self' href='inbound/inboundShield/configuration'>Inbound Shield – Configuration page</a>: \"Report from third-party client\". This integration allows Trustifi to update the classification of quarantined emails based on reports from the Microsoft spam/phishing reports. We’ve also added a new quick view for reported emails in the <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Inbound Shield – Quarantined Emails page</a>.",
				"<b>DMARC Analyzer update</b> - A new section has been added to the <a class='blue-link' target='_self' href='domain/dmarcReports'>DMARC Analyzer page</a>: “Recipients Spoofed Attacks”. This section shows recipient domains which have received spoofed emails originating from your domain, based on our analysis."
			]
		},
		{
			date: Date.parse("9/10/2023"),
			features: [
				"<b>Outbound quarantine notification customization</b> - The new setting (\"Notify Sender About Blocked Emails\") in the <a class='blue-link' target='_self' href='outbound/adminPolicyManagement/policies'>Outbound Policies page</a> allows enabling release requests for blocked outbound emails, as well as customizing the recipient and subject of these requests.",
			]
		},
		{
			date: Date.parse("9/07/2023"),
			features: [
				"<b>Custom Inbound Notification Times</b> - The \"Notification options\" section under the <a class='blue-link' target='_self' href='inbound/inboundShield/threatPreventionRules'>Threat Prevention Rules page</a> now allows selecting custom times to receives notifications of quarantined emails for reviewers and recipients.",
				"<b>Removing Items From Allow/Block Lists For All Admins</b> - Partners who manage multiple admins/plans can now select the \"Remove for all admins\" option when removing an item from any of the allow/block lists. Selecting this setting will remove the item(s) from any list it may exist in for the plans they manage.",
			]
		},
		{
			date: Date.parse("8/21/2023"),
			features: [
				"<b>DMARC Analyzer</b> - Introducing our enhanced offering: the <a class='blue-link' target='_self' href='domain/dmarcReports'>DMARC Analyzer</a>! Stay informed and protected with the power of our DMARC aggregated reports analysis tool. Detect DMARC failures, unmask spoofing risks, and identify authentication gaps to ensure safe email delivery and prevent cyber threats.",
				"<b>Domain Spoofing Control new location</b> - The Domain Spoofing Control page has been moved under the <a class='blue-link' target='_self' href='domain/spoofingControl'>Domain Protection</a> section from the previous location in the Inbound Configuration page.",
			]
		},
		{
			date: Date.parse("8/14/2023"),
			features: [
				"<b>Quarantined Emails Safe Link Preview</b> - In the <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Inbound Quarantine page</a>, you may now view a safe, sandboxed version of the links inside an email. This function can be accessed from the \"Link Analysis\" section of the \"Threat Analysis\" window.",
			]
		},
		{
			date: Date.parse("7/30/2023"),
			features: [
				"<b>Account Takeover Protection new timeline</b> - The <a class='blue-link' target='_self' href='accountCompromised/incidents'>Incidents and Activity page</a> will now display an informative timeline of events related to suspicious activity, for the entire organization or for selected users.",
				"<b>New section: Domain Protection</b> - This section will include pages and features meant to keep your domain safe and reputable. Important note: the <a class='blue-link' target='_self' href='domain/spoofingControl'>Domain Spoofing Control page</a> has been moved to this section from the Inbound Configuration page."
			]
		},
		{
			date: Date.parse("7/20/2023"),
			features: [
				"<b>Account Takeover Protection Trends</b> - We've added the ability to filter results by users in the <a class='blue-link' target='_self' href='accountCompromised/trends'>Account Takeover Protection Trends and Insights page</a>. This allows admins to get a clear security overview of individual users who may be at risk.",
				"<b>Email Trace</b> - tracking logs: In the <a class='blue-link' target='_self' href='outbound/auditLog'>Email Trace for outbound</a>, you may now also see all of the tracking information for outbound emails. For example, you may see when recipients open the email, clicked on links, or downloaded encrypted files.",
				"<b>View Exchange server configuration</b> - Admins may now connect using their Exchange admin credentials and view a report of their Exchange server configurations. This could be useful pre or post deployment of Trustifi relays to verify everything is working correctly. The feature is available in the \"Plan settings\" page of both <a class='blue-link' target='_self' href='inbound/plan/emailFlowIntegration'>inbound</a>, and <a class='blue-link' target='_self' href='outbound/adminSharedPlan/emailFlowIntegration'>outbound</a>, management.",
			]
		},
		{
			date: Date.parse("7/11/2023"),
			features: [
				"<b>Inbound Quarantine Data Loading Improvements</b> - We've added an option in the <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Inbound Quarantine page</a> to auto-refresh the data every 30 or 60 seconds to get new emails. Also, you can now abort the process of getting new emails if you want to start a new search.",
				"<b>Inbound Quarantine New Filters</b> - New filters have been added to the Inbound Quarantine page for sender types like internal/external, free email domains, and top email domains.",
				"<b>Account Takeover Protection User Activity Map</b> - You can now see exactly which users have been active in which locations by hovering over the pins on the map. This map can be found in the <a class='blue-link' target='_self' href='accountCompromised/trends'>Account Takeover Protection Trends and Insights page</a>.",
			]
		},
		{
			date: Date.parse("7/09/2023"),
			features: [
				"<b>URL Hunting Tool</b> - Allows security teams to track and identify users who clicked on scanned links, and provide additional details like the specific location and browser/device that was used when the link was clicked. Can be found at <a class='blue-link' target='_self' href='inbound/urlTracking'>URL Hunting</a>.",
				"<b>Enforce Log in Methods</b> - Admins can now control exactly which log in methods their Trustifi users are allowed to use. This can be found in <a class='blue-link' target='_self' href='outbound/adminUserManagement'>User Management</a> under \"More Actions\" - \"Log in Options\".",
				"<b>Improved Global Reviewer Settings</b> - It is now possible to edit a user's \"Global Reviewer\" status and configure if they should be able to release malicious emails. Can be done in <a class='blue-link' target='_self' href='outbound/adminUserManagement'>User Management</a> under \"Edit Global Reviewer\".",
			]
		},
		{
			date: Date.parse("6/19/2023"),
			features: [
				"<b>Outbound Trends & Insights page</b> - Review your outbound security trends and get insights and action recommendations at <a class='blue-link' target='_self' href='outbound/trends'>Outbound Trends</a>.",
				"<b>Archive usage statistics</b> - Users of Trustifi's archiving module can now see an overview of how many archived emails they have, and how much storage space these archived emails use. This can be found in the at  <a class='blue-link' target='_self' href='archive/configurations'>Archive Configuration page</a>.",
				"<b>Suspicious email category configuration</b> - you may now change the default category of emails flagged for \"Contact impersonation\" to \"Suspicious\" instead of \"Malicious\". This configuration can be found at  <a class='blue-link' target='_self' href='inbound/inboundShield/threatPreventionRules'>Threat Prevention Rules page</a>.",
				"<b>Ability to skip Smart Banners for domains/senders</b> - The configuration for \"Skip warning label for email/domain\" which can be found at <a class='blue-link' target='_self' href='inbound/inboundShield/threatPreventionRules'>Threat Prevention Rules page</a> will now also apply to Smart Banners.",
				"<b>CIDR for sender allowlisting/blocklisting</b> - Trustifi now supports allowlisting or blocklisting sender IP ranges by using CIDR. This can be used at  <a class='blue-link' target='_self' href='inbound/configuration/senderLists'>Senders lists page</a>.",
			]
		},
		{
			date: Date.parse("5/3/2023"),
			features: [
				"<b>Customizable inbound footer</b> - New ability to customize the footer added to scanned inbound emails and add any text you like. Available in the \"Threat Prevention Rules\" tab.",
				"<b>Threat analysis \"Code View\"</b> - You may now view the source code/HTML of quarantined emails with one click directly from the \"Threat Analysis\" window",
				"<b>Skip \"Blocked file types\" for specific senders</b> - In the \"Senders Lists\" tab, it is now possible to allow specific blocked file types (macros, scripts, etc.) for specific allowlisted senders, by clicking on the \"Actions\" menu next to an allowlisted sender"
			]
		},
		{
			date: Date.parse("3/23/2023"),
			features: [
				"<b>Smart Banner</b> - Educate your users on the potential dangers of emails. At the top of each incoming email, a banner will appear that highlights positive and negative security aspects of that email and/or sender<br/>Enable this \"Smart Banner\" feature in the new <a class='blue-link' target='_self' href='inbound/inboundShield/securityAwareness'>Security Awareness page</a>.",
				"<b>Advanced rule usage metrics</b> - The \"Rule usage\" window for <a class='blue-link' target='_self' href='outbound/adminPolicyManagement'>\"Outbound Rules\"</a> and <a class='blue-link' target='_self' href='inbound/inboundShield/inboundEmailRules'>\"Inbound Email Rules\"</a> has been upgraded to show more advanced metrics and allow viewing data for different time periods."
			]
		},
		{
			date: Date.parse("3/10/2023"),
			features: [
				"<b>Customized retention options</b> - admins and reviewers can now set different retention settings for inbound quarantined emails of different categories (malicious/suspicious/spam/graymail/external). If \"External\" emails are set to keep a record, it is recommended to set the retention period to be no higher than 14 days.<br/>The retention settings can be accessed under the <a class='blue-link' target='_self' href='/inbound/inboundShield'>Inbound Shield page</a>.",
				"<b>Navigation panel improvement</b> - for your convenience, the navigation panel on the left side of the portal has been re-ordered to show the most commonly used sections at the top.",
			]
		},
		{
			date: Date.parse("3/7/2023"),
			features: [
				"<b>Domain Spoofing Control</b> - general improvements were made to this page, and a \"Strength\" graph has been added for all domains and signatures to indicate how secure the domain sources are.<br/>We recommend visiting the <a class='blue-link' target='_self' href='/inbound/configuration'>\"Domain Spoofing Control\"</a> tab under \"Inbound Management\" > \"Configuration\" to make sure your secure domain signatures are optimized.",
				"<b>Auto-sync for Exchange blocklists</b> - Trustifi can now automatically sync your existing blocklists from Exchange using the integration wizard. Currently, this feature is supported only for blocklists of senders (emails and domains) and files.",
			]
		},
		{
			date: Date.parse("1/20/2023"),
			features: [
				"<b>Integration via MX record</b> - Trustifi now supports inbound integration via MX record change for a quick and easy configuration that works for both cloud-based and on-premises email systems.",
				"<b>Partners usage</b> - The <a class='blue-link' target='_self' href='partner/usage'>\"Usage\" page</a> under the \"Partners\" section has been improved, and now features all of the information Trustifi partners need to view and manage their license usage.",
			]
		},
		{
			date: Date.parse("1/13/2023"),
			features: [
				"<b>Spanish translation feature</b> - The Trustifi add-ins for Outlook and Chrome, as well as the secure email portal, can now be completely translated into Spanish in a click of a button.",
				"<b>Threat Response</b> - The Threat Response tool has been updated with improved UI and functionality, and now has its <a class='blue-link' target='_self' href='inbound/threatResponse'>own page</a> under \"Inbound Management\". Reviewing the results of Threat Response is now also much easier and accessible.",
			]
		},
		{
			date: Date.parse("12/29/2022"),
			features: [
				"Google API integration now supports additional functionality such as Threat Response and syncing mailbox aliases.",
				"<b>Outbound rules</b> - New capabilities added for <a class='blue-link' target='_self' href='outbound/adminPolicyManagement'>outbound rules</a>: \"Prepend subject\" and \"Skip removing encrypted content\".",
				"<b>Trustifi Website</b> - The <a class='blue-link' href='https://trustifi.com' target='_blank'>Trustifi web portal</a> has received a major UI update for a new, modern design which looks better and makes the navigation experience better for users"
			]
		},
		{
			date: Date.parse("12/21/2022"),
			features: [
				"<b>Quarantined emails</b> - The <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Quarantined Emails page</a> has undergone a major UX and performance improvement.",
				"Admins and reviewers can now allow uncommon file types in quarantined emails using a new quick action button. Visit the <a class='blue-link' target='_self' href='inbound/inboundQuarantined'>Quarantined Emails page</a>.",
			]
		},
		{
			date: Date.parse("12/12/2022"),
			features: [
				"<b>Release Requests</b> - Users can now request the release of a quarantined email from the web portal as well as from the email notification.",
				"<b>Scheduled quarantine notifications</b> - support has been added for selecting multiple notification times.",
			]
		},
	];

	faqLib = [
        {
            title: "Why Trustifi",
            infoBlocks: [
                {
                    Q: "Why do I need my e-mails encrypted?",
                    A: ["Trustifi encrypt every e-mail you send to ensure the protection of data inside the e-mail from outside threats.","Encrypted e-mails can only be opened by the recipient and sender.  We do not have access to the data stored inside each e-mail."],
                },
                {
                    Q: "Can I use Trustifi with my existing e-mail accounts?",
                    A: ["<strong>Yes</strong>, no new e-mail account or additional software is required to use our services.  Trustifi has extensions for Gmail and Outlook that integrate the Trustifi features with your current e-mail addresses, all you have to do is sign-up."],
                },
                {
                    Q: "Do recipients of my e-mails also need to have Trustifi?",
                    A: ["<strong>No.</strong> Only the sender needs to have a Trustifi account.  In addition, recipients can reply to Trustifi users in a secure way by using our ENCRYPTED-REPLY™ option."],
                },
                {
                    Q: "Who has access to the information inside my e-mails?",
                    A: ["Only the sender and the recipient. We don’t have access to any of the text or attachments inside the e-mail."],
                },
                {
                    Q: "Will Trustifi encrypt all my e-mails?",
                    A: ["All attachments are encrypted by default, and e-mail content encryption is configurable - the sender can choose whether to encrypt the content or not."],
                },
            ]
        },
        {
            title: "What do Trustifi do",
            infoBlocks: [
                {
                    Q: "What is a Trustifi encrypted e-mail?",
                    A: ["Trustifi has <strong>military grade encryption</strong> with an AES256 algorithm to make sure your e-mails stay safe and every Trustifi e-mail is always sent encrypted.",
                        "We separate all the important information from the e-mail (attachments and content), classify the sensitivity of the e-mail/attachment and encrypt it with the unique private key of the user (we keep the key on a separate server that no one can access, only the user)"],
                },
                {
                    Q: "What is secure e-mail?",
                    A: ["Secure is an encrypted e-mail which also requires the recipient to pass a two-factor authentication before opening the e-mail. This is the highest level of e-mail security."],
                },
                {
                    Q: "What is two-factor authentication?",
                    A: ["Two-factor authentication requires the recipient to either insert a correct password (determined by the sender) or retrieve a PIN either from SMS/phone call or from an email. This PIN is the only way to access your e-mail."],
                },
                {
                    Q: "What is a Postmarked e-mail?",
                    A: ["Postmark is a patented technology of Trustifi that gives the sender <strong>legal proof</strong> the e-mail was sent, and the time the e-mail is opened."],
                },
                {
                    Q: "What tracking data does Trustifi provide?",
                    A: ["Trustifi informs you about the time of sending, delivery, and opening of the e-mail as well as a mapped location of where the e-mail was opened."],
                },
                {
                    Q: "Do the recipients of my e-mails know the e-mail is being tracked or postmarked?",
                    A: ["<strong>Depends on the sender's preference.</strong> You can send an email using Trustifi's email wrapper or without.  If sent without the wrapper the format is the same as a regular e-mail would look like."],
                },
                {
                    Q: "Can my recipient send me an ENCRYPTED-REPLY™ with authentication?",
                    A: ["<strong>Yes</strong>, you can “force” the recipient to send you a reply that will require two-factor authentication from you in order to open it."],
                },
                {
                    Q: "Can I use multiple email address under my Trustifi account to send e-mails?",
                    A: ["<strong>Yes</strong>, you can add additional e-mails to your account, but you need to verify each one separately first by clicking the link sent to you."],
                },
            ]
        },
        {
            title: "How to use Trustifi",
            infoBlocks: [
                {
                    Q: "How to send an e-mail from the Trustifi Website?",
                    A: ["First click the compose button on the left panel of the screen","Then type in the e-mails addresses you wish to send the Trustified e-mail to in the To box.  Add the necessary information, including phone number and contact name. (if you have already added their contact just click the red lettered version of their e-mail from the drop box.)",
                        "Compose your e-mail, apply the different options: Encrypt Message Content, Require Authentication and other settings.  Click send, and you’re done!"],
                },
                {
                    Q: "Why do I need to supply my recipient's phone number?",
                    A: ["To complete two-factor authentication of the e-mail to ensure only the recipient is opening your e-mail a PIN is either texted or phoned in to them.  This PIN is used to open your e-mail. "],
                },
                {
                    Q: "How to send an email from Outlook or Gmail?",
                    A: ["First download the Trustifi add-in for the e-mail application you wish to use.","Click the “New E-mail” button and then click the blue envelope for Trustifi services.  Instead of adding the recipients' addresses into the To box, add the recipients into the \"Type new or saved e-mail addresses box\" in the add-on.",
                        "After adding all the desired e-mails and e-mail options, click apply and compose the e-mail as you normally would."],
                },
                {
                    Q: "What if the recipient of my e-mail forgets their PIN for a secure e-mail?",
                    A: ["The recipient can either ask the sender for a new access code, or the sender can give the original code again."],
                },
            ]
        },
        {
            title: "TroubleShooting",
            infoBlocks: [
                {
                    Q: "Why did the emails I have sent via the Email Relay arrive without any styles?",
                    A: ["This is usually caused by Rich Text Format (RTF) being configured on your Exchange server. Please see the documentation for <a href=\"https://docs.trustifi.com/docs/email-relay-office365-microsoft-exchange-online/\" style=\"font-weight:bold\" title=\"Office365 Email Relay Documentation\" rel=\"noopener\" target=\"_blank\">Office365</a> Email Relay implementation to disable RTF."],
                },
            ]
        },
    ];

    kBase = [
        {
            title: "Sensitivity Engine",
            infoBlocks: [
                {
                    title: "Purpose and usage",
                    info: ["Sensitive data is defined as data in an email containing personal, business or confidential information that could cause a security leak; this information is essential to keep classified within the organization/business. The sensitivity engine is best used by clients to make sure sensitive data is either being sent within the business safely or to manage sensitive data. According to the Sensitivity <strong>Score</strong> Mechanism of each email, rules can be set regarding authentication and other security requirements on the email. Using these capabilities can help organizations protect their data, their user’s privacy, as well as meet the different compliance's that exist today."]
                },
                {
                    subtitle: "Data Loss Prevention (DLP)",
                    info: ["Data loss prevention is used to make sure sensitive information is controlled within and outside a company. Without control, personal or business security would be at risk, and endless data could be stolen. The sensitivity engine, combined with the rule’s engine, works as DLP to control email security options on sent emails. Our sensitivity engine allows the admin to automatically and easily monitor end-user usage of sensitive documents. The admin of the company can set certain rules and policies to ensure that sensitive emails are sent with the correct security options enabled to prevent the risk of private data."]
                },
                {
                    title: "Compliance and Regulations",
                    info: ["We are compliant with regulations that target specific types of personal and business security to classify levels of sensitivity. Different types of private information are classified under different types of worldwide security protocols, and the sensitivity engine tags and scores sensitive information under the following regulations."]
                },
                {
                    subtitle: "General Data Protection Regulation (GDPR)",
                    info: ["GDPR aims to protect personal data. This personal data can be as non-sensitive as an email address or business phone number to highly sensitive information as a social security number. Sensitivity scores for GDPR-based information may vary based on the level of personal privacy."]
                },
                {
                    subtitle: "Family Educational Rights and Privacy Act (FERPA)",
                    info: ["FERPA is a federal law that affords parents the right to have access to their children’s education records, the right to seek to have the records amended, and the right to have some control over the disclosure of personally identifiable information from the education records."]
                },
                {
                    subtitle: "Health Insurance Portability and Accountability Act (HIPAA)",
                    info: ["HIPAA is the regulation regarding all health-related and medical information. This information can be classified as sensitive, as patient identification or historical data leaks could put a person’s privacy at risk."]
                },
                {
                    subtitle: "Payment Card Industry Data Security Standard (PCI DSS)",
                    info: ["PCI DSS refers to all information regarding finance and credit/debit cards. As this information involves personal and business bank accounts and financials, emails containing this type of information will be tagged as having a higher level of sensitivity. The sensitivity engine uses combinations of found phrases in correlation to the three listed compliance regulations to create a sensitivity score."]
                },
                {
                    subtitle: "California Consumer Privacy Act (CCPA)",
                    info: ["The CCPA is a law designed to protect the data privacy rights of citizens living in California, similar to GDPR, but aims to encompass all of the sensitive and personal information related to a consumer or household."]
                },
                {
                    subtitle: "Protection of Personal Information (POPI) Act",
                    info: ["POPI refers to South Africa’s Protection of Personal Information Act which seeks to regulate the Processing of Personal Information."]
                },
                {
                    subtitle: "General Data Protection Law (LGPD)",
                    info: ["Like GDPR and CCPA, LGPD is the Brazilian data protection law focused on Brazilian related sensitive information or documents."]
                },
                {
                    subtitle: "Personal Data (Privacy) Ordinance (PDPO)",
                    info: ["PDPO is one of Asia’s longest standing comprehensive data protection laws which protects the privacy of individuals in relation to personal data."]
                },
                {
                    subtitle: "Gramm–Leach–Bliley Act (GLBA)",
                    info: ["The Gramm-Leach-Bliley Act requires financial institutions – companies that offer consumers financial products or services like loans, financial or investment advice, or insurance – to explain their information-sharing practices to their customers and to safeguard sensitive data."]
                },
                {
                    title: "How the engine works",
                    info: ["The engine works by matching tagged words, sentence parts, numbers, complex patterns with those in our database as well as Machine Learning. We do not read or receive the information in the email; the system scans the contents of the email such as the subject, body, files, file names, file types, and extensions and assigns a Sensitivity Score, Category and Compliance-based on the patterns that were matched. Examples of patterns in the database include keywords and number formats that would be contained in a sensitive document. If the engine detects certain patterns that could be classified as a credit card category, the email will be tagged as sensitive, with the appropriate Sensitivity-Scores and relevant Compliance/s (GDPR, FERPA, HIPAA, PCI, CCPA, POPI, PDPO, LGPD and GLBA)."]
                },
                {
                    title: "Sensitivity categories",
                    info: ["Each pattern or detection function in a system is associated with a particular category of sensitivity such as “Employee Information”, “Bank Account Information”, “Credit Card”, and many more. Each category is associated with the relevant compliance/s (GDPR, FERPA, HIPAA, PCI, CCPA, POPI, PDPO, LGPD and GLBA)."]
                },
                {
                    title: "Sensitivity Score Mechanism",
                    info: ["Sensitivity detection and classification is something that is very difficult, subjective, and every company/business/individual can define it differently. Therefore, we created the Sensitivity-Score-Mechanism, based on in-depth research and questioning from many clients from different fields.",
                        "The score values range from 0 (not sensitive) to 5 (highly sensitive), where Each value is derived from a combination of:\n" +
                        "  - Sensitivity severity - How severe the finding we suspect we found based on its Sensitivity Category\n" +
                        "  - Reliability level - How confident we are that what we found is true",
                        "Each of these parameters is calculated in a sophisticated method based on many studies and analysis of millions of documents of any type.",
                        "After the system determines the highest final Sensitivity Score (we can detect many sensitivity categories but only the one with the highest Sensitivity-Score is counted), it combines all the sensitivity data together: Sensitivity Score, Sensitivity Category and Compliance/s."]
                },


            ]
        },
        {
            title: "Technical Constraints",
            infoBlocks:[
				{
					title: "Group emails can cause duplicates in certain conditions",
					info: ["When using the \"Trustifi MTA\" outbound email architecture, if an email is sent to a group and members of the group are also added to the email as separate recipients in To/Cc, these recipients may receive the email twice.<br/>" +
					"This happens because the \"Trustifi MTA\" outbound email architecture changes the Message-ID for each recipient, and the receiving server of the recipient cannot merge these emails.",
						"<strong>Suggestions</strong>: If you are on Office365/Exchange, you can switch to the \"Client MTA\" outbound email architecture. This issue does not occur in the \"Client MTA\" architecture."]
				},
                {
                    title: "Attachments over 40MB will always be encrypted",
                    info: ["Searching for sensitive content in large files can take time. To avoid performance issues while not compromising on security, large attachments will always be encrypted.",
                        "<strong>Suggestions</strong>: Some media files (images/videos) can be compressed to reduce their size. Saving a file in zip/rar format can also help reduce the file size."]
                },
                {
                    title: "Voting options in Outlook are not supported",
                    info: ["Emails that are sent or received using Trustifi's outbound or inbound relays, will be stripped of any existing voting options. This is due to the email body and headers being slightly modified when being sent with Trustifi.",
                        "<strong>Suggestions</strong>: If you are using Trustifi for inbound only (without outbound), you may switch to the API-based protection instead of the inbound relay. Voting options work in Trustifi for emails that were scanned using the API. You may contact support@trustificorp.com for assistance in setting up the API protection."]
                },
                {
                    title: "Specific file types will always be encrypted",
                    info: ["The file types are: 'ade', 'adp', 'src', 'asp', 'bas', 'bat', 'cer', 'chm', 'cmd', 'com', 'cpl', 'crt', 'csh', 'der', 'exe', 'fxp', 'gadget', 'hlp', 'hta', 'inf', 'ins', 'isp', 'its', 'app', 'jse', 'ksh', 'lib', 'lnk', 'mad', 'maf', 'mag', 'mam', 'maq', 'mar', 'mas', 'mat', 'mau', 'mav', 'maw', 'mda', 'mdb', 'mde', 'msc', 'msh', 'msh1', 'msh2', 'mshxml', 'msh1xml', 'msh2xml', 'msi', 'msp', 'mst', 'ops', 'pcd', 'pif', 'plg', 'prf', 'prg', 'reg', 'scf', 'scr', 'mdt', 'mdw', 'mdz', 'sct', 'shb', 'shs', 'sys', 'ps1', 'ps1xml', 'ps2', 'ps2xml', 'psc1', 'psc2', 'tmp', 'url', 'vb', 'vbe', 'vbs', 'vps', 'vsmacros', 'vss', 'vst', 'vsw', 'vxd', 'ws', 'wsc', 'wsf', 'wsh', 'xnk'",
                        "These file types are often not supported by many email systems. Sending them in encrypted forms allows these files to go through."]
                },
                {
                    title: "If an email has more than 50 total recipients in “To” and “Cc”, recipients will not be able to see who else received the email",
                    info: ["Due to a technical limitation in the AWS SES service.",
                        "Additionally, viewing more than 50 recipients in a single email thread is not readable by the recipient and most email client interfaces have trouble displaying more than 50 addresses.",
						"There is a risk of a <strong>reply-all storm</strong>, which is when a recipient replies to all the To and Cc addresses from the original message, and then those people reply back asking everyone to stop replying.",
						"<strong>Suggestions</strong>: Switching to <strong>Via your MTA</strong> architecture in the Outbound Management. Please contact support before making the change."
					]
                },
                {
                    title: "If an emails body content is over ~13mb in size – a compression algorithm will apply",
                    info: ["If the email’s overall size is deemed too large to be sent with Trustifi, the compression algorithm will first try to minimize the email’s size as much as possible (mostly by compressing inline images). If the email is still too large, all attached files will be encrypted. If the email is still too large, it will fail to be sent (this is a very rare case).",
                        "<strong>Suggestions</strong>: an email’s size can be reduced by removing unnecessary images in the body or removing old parts of an email thread when sending a reply (if it’s no longer relevant)."]
                },
                {
                    title: "DLP rules for addresses/domains cannot be created for “Bcc” recipients",
                    info: ["Bcc is often used as archiving or copying the original message, so it most likely will never need to be encrypted for bcc recipients."]
                },
                {
                    title: "OCR detection applies only to image and PDF files",
                    info: ["For improved performance, detection of sensitive content with OCR (Optical Character Recognition) is applied only to attachments",
                        "<strong>Suggestions</strong>: when sending images which contain sensitive information, either encrypt the email content manually or send the images as attachments."]
                },
                {
                    title: "OCR detection applies to the first 3 pages only for long PDF documents",
                    info: ["This is done to improve performance and avoid long sending times when sending long PDF documents.",
                        "<strong>Suggestions</strong>: Use PDF/Word documents with regular text instead of scanned documents whenever possible."]
                },
                {
                    title: "Spam and BEC detection applies only to English text",
                    info: ["The Machine Learning system which is used to analyze text for spam and BEC (Business Email Compromise) content is currently only able to handle emails in English."]
                },
                {
                    title: "Rooms cannot be reserved using Outlook calendar",
                    info: ["When sending calendar invitations using Trustifi, you will not be able to reserve resource rooms. This is due to the email body and headers being slightly modified when being sent with Trustifi.",
                        "<strong>Suggestion 1</strong>: Create an exception to skip all calendar emails from being sent using the Trustifi relay. This will require minimum maintenance (no need to update exceptions based on rooms), but it means all calendar emails will not be protected by Trustifi.",
                        "<strong>Suggestion 2</strong>: Create an exception to skip all emails to resource rooms from being sent using the Trustifi relay. This means that only calendar emails with resource rooms will not have the Trustifi protection, but it will require setting up a more detailed exception that includes all rooms.",
                        "Our support team can assist in setting up these exceptions."]
                },
                {
                    title: "Rules based on \"Attachment type\" have some limitations",
                    info: ["Currently, rules can be configured based on a number of specific file types only. This is because the actual file type is checked, rather then only checking the file extension.",
                        "An attachment's type cannot be accurately detected if the file is located inside a compressed folder like .zip or .rar."]
                },
                {
                    title: "The \"Recall\" function in O365 is not supported",
                    info: ["Emails that have been sent or received using the Trustifi outbound or inbound SMTP connectors cannot be recalled using this function in O365. The O365 recall function works under very specific conditions only, and cannot be used for any email that was sent using an SMTP connector",
                        "<strong>Suggestion 1</strong>: Using the \"Encrypt Message Content\" feature in Trustifi supports a more robust and specific level of recalling/blocking emails. This includes the ability to block specific senders only, block emails that have already been read by the recipient, and the option to update the content or attachments of sent emails.",
						"<strong>Suggestion 2</strong>: Office 365 users can use the \"Recall\" function in Trustifi via the web portal or Outlook add-in to recall sent emails from internal recipients, even if the email was not encrypted. To support this function, the Trustifi plan must be connected to the O365 API in the \"Integrations\" page."]
                },
            ]
        }
    ];


	ngOnInit() {

        this.getSensitivityEngineList();

		this.parentScrollableElement = document.getElementById('main')
		this.parentScrollableElement.addEventListener('scroll', this.scrollToTopBtn);

        this.infoLibraries = {
            FAQ: {
                title: "Frequently Asked Questions",
                libraryObj: this.faqLib
            },
            knowledgeBase: {
                title: "Knowledge Base",
                libraryObj: this.kBase
            }
        }

        // There are 3 page modules in knowledge base: main | info | timeScale
        // main module used for the "welcome" page of the support section. info page is used in FAQ and Knowledge Base. timeScale is used in What's New page
		const pageModel = this.router.url.substring(1).split('/')[1];
		const urlQuery = this.activatedRoute.snapshot.paramMap;
		const anchorId = window.history.state.anchorId || urlQuery.get('anchorId');

		switch (pageModel) {
			case this.dic.CONSTANTS.supportPages.knowledgeBase:
				this.goToInfoPage(this.infoLibraries.knowledgeBase, anchorId);
				break;

			case this.dic.CONSTANTS.supportPages.faq:
				this.goToInfoPage(this.infoLibraries.FAQ, anchorId);
				break;

			case this.dic.CONSTANTS.supportPages.whatsNew:
				this.pageModule = this.dic.CONSTANTS.supportPageModules.timeScale;
				break;
			default:
				this.pageModule = this.dic.CONSTANTS.supportPageModules.main;
		}
    };

	ngOnDestroy() {
		this.parentScrollableElement.removeEventListener('scroll', this.scrollToTopBtn);
	}

	goToInfoPage(infoLibrary, anchorText) {
        // reset search text highlight on navigation:
        this.highlightSearchResults = false;
        this.highlightSearchText = '';
        //

        this.selectedLibrary = infoLibrary;
        this.pageModule = this.dic.CONSTANTS.supportPageModules.info;

        if (anchorText) {
            setTimeout(()=>{
                this.scrollToAnchor(anchorText.replace(/ /g, "_"));
            },350);
        }
        else {
            this.scrollToTop();
        }

    };

    getSensitivityEngineList() {
        this.rs.getSensitivityEngineList().then((response) => {
            this.sensitivityEngineList = response;
            let table = '<div class="w-100 knowledge-custom-table flex-column border"><div class="flex-row align-items-center bg-opacity-10 bg-primary py-2 f-1-0 knowledge-ct-first-row">' +
            '<div class="f-1-0 px-2 knowledge-fBasis-30"><b>Name</b></div>' +
            '<div class="f-1-0 px-2 knowledge-fBasis-5"><b>Type</b></div>' +
            '<div class="f-1-0 px-2 knowledge-fBasis-5"><b>Min score</b></div>' +
            '<div class="f-1-0 px-2 knowledge-fBasis-5"><b>Max score</b></div>' +
            '<div class="f-1-0 px-2 knowledge-fBasis-30"><b>Compliances/Regulations</b></div>' +
            '</div>';
            for (let i = 0; i < this.sensitivityEngineList.length; i++) {
                if (i % 2 !== 0) { table += '<div class="flex-row py-2 f-1-0 border align-items-center bg-primary bg-opacity-10 border-bottom-0">'; } else {
                    table += '<div class="flex-row py-2 f-1-0 border align-items-center border-bottom-0" style="border-bottom: unset!important;">'
                }
                table += `<div class="f-1-0 px-2 knowledge-fBasis-30">${this.sensitivityEngineList[i].name}</div>`;
                table += `<div class="f-1-0 px-2 knowledge-fBasis-5">${this.sensitivityEngineList[i].type}</div>`;
                table += `<div class="f-1-0 px-2 knowledge-fBasis-5">${this.sensitivityEngineList[i].min_score}</div>`;
                table += `<div class="f-1-0 px-2 knowledge-fBasis-5">${this.sensitivityEngineList[i].max_score}</div>`;
                table += `<div class="f-1-0 px-2 knowledge-fBasis-30">${this.sensitivityEngineList[i].compliance.join(', ') || 'N/A'}</div></div>`;
                if (this.sensitivityEngineList[i].sub_detector) {
                    if (i % 2 !== 0) { table += `<div class="flex-column bg-primary bg-opacity-10 knowledge-ct-subDetector">`; } else {
                        table += `<div class="flex-column knowledge-ct-subDetector">`;
                    }
                    for (let j = 0; j < this.sensitivityEngineList[i].sub_detector.length; j++) {
                        table += `<div class="flex-row w-100 py-1 align-items-center">`;
                        table += `<div class="flex-column ps-4 f-1-0 knowledge-fBasis-30">`;
                        // table += `<b>Name:</b>`;
                        table += `<span>${this.sensitivityEngineList[i].sub_detector[j].name}</span>`;
                        table += `</div>`;
                        table += `<div class="flex-column px-2 f-1-0 knowledge-fBasis-5">`;
                        // table += `<b>Type:</b>`;
                        table += `<span>${this.sensitivityEngineList[i].sub_detector[j].type}</span>`;
                        table += `</div>`;
                        table += `<div class="flex-column px-2 f-1-0 knowledge-fBasis-5">`;
                        // table += `<b>Min score:</b>`;
                        table += `<span>${this.sensitivityEngineList[i].sub_detector[j].min_score}</span>`;
                        table += `</div>`;
                        table += `<div class="flex-column px-2 f-1-0 knowledge-fBasis-5">`;
                        // table += `<b>Max score:</b>`;
                        table += `<span>${this.sensitivityEngineList[i].sub_detector[j].max_score}</span>`;
                        table += `</div>`;
                        table += `<div class="flex-column px-2 f-1-0 knowledge-fBasis-30">`;
                        // table += `<b>Compliances:</b>`;
                        table += `<span>${this.sensitivityEngineList[i].sub_detector[j].compliance.join(', ') || 'N/A'}</span>`;
                        table += `</div>`;
                        table += `</div>`;

                    }
                    table += `</div>`;

                }
                table += `<tr>`;
            }

            table += '</div>';

            this.kBase[0].infoBlocks.push(
                {
                title: "Examples",
				// actions: [{name: "Export to CSV"}],
                info: ["Working with compliance regulations and levels of privacy, the table below summarizes different types of sensitive information and their respective score ranges, types, and compliance's and/or regulations",
                    table,
                    "We are always researching, improving and upgrading the sensitivity-engine, so there may be changes in the table from time to time. We recommend staying up to date from our web-portal."]
                }
            );

            // rescroll to anchor since the pushed table changed the page's scroll position
            if (window.history.state.anchorId) {
                this.scrollToAnchor(window.history.state.anchorId);
            }
        });
    }

    scrollToAnchor(id) {
        // 'id' can be sent from within controller (as normal string) or by the URL address from outside the src (as encoded URL string)
        // (question mark in the end of the URL is automatically removed by browser, but can still be in id so we check it too)
        const targetElement = document.getElementById(id) || document.getElementById(id + '?') || document.getElementById(decodeURIComponent(id));
        if (!targetElement) {
            return;
        }

        // timeout to let time for the search query upper section to minimize before scrolling
        setTimeout(() => {
            this.parentScrollableElement.scroll({
                top: targetElement.offsetTop - this.parentScrollableElement.offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }, 150);

    }

    scrollToTopBtn = () => {
		this.ngZone.run(() => {
			this.showScrollToTop = this.parentScrollableElement.scrollTop > 200 && this.pageModule !== this.dic.CONSTANTS.supportPageModules.main
		})
    }

    scrollToTop() {
		this.parentScrollableElement.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    searchInLibraries(searchTerm) {

        // reset highlight when search query is changed
        this.highlightSearchResults = false;
        this.highlightSearchText = '';
        //

        if (!searchTerm || searchTerm.length < 2) {
            this.searchResultsList = [];
            this.searchInProcess = false;
            this.showResultModal = false;
            return;
        }

        this.showResultModal = true;
        this.searchInProcess = true;

        searchTerm = searchTerm.toLowerCase();

        // single resultObj format: {SLO: source_library_object , SCT: second_closest_title , CT: closest_title , EC: end_content}
        let results = [];

        for (const LibraryKey in this.infoLibraries) {
            const LibraryContent = this.infoLibraries[LibraryKey];
            LibraryContent.libraryObj.forEach(subLibrary => {
                // search in sub-library title:
                if (subLibrary.title.toLowerCase().includes(searchTerm)) {
                    results.push({
                        sourceLibrary : LibraryContent , // source library object
                        secondClosestTitle: subLibrary.title , // second_closest_title
                    })
                }
                // search in sub-library infoBlocks:
                subLibrary.infoBlocks.forEach(infoBlock => {
                    if (JSON.stringify(infoBlock).toLowerCase().includes(searchTerm)) {
                        results.push({
                            sourceLibrary : LibraryContent , // source library object
                            secondClosestTitle: subLibrary.title , // second_closest_title
                            closestTitle: Object.values(infoBlock)[0] , // closest_title
                            endContent: Object.values(infoBlock)[1] // end_content
                        })
                    }
                })
            })
        }

        // search in "what's new" library (forEach twice BUT this library is not big at all and should never be)
        this.whatsNewData.forEach(newFeaturesBundle => {
            newFeaturesBundle.features.forEach(newFeature => {
                if (newFeature.toLowerCase().includes(searchTerm)) {
                    results.push({
                        sourceLibrary : "What's New" , // source library name
                        closestTitle: (new Date(newFeaturesBundle.date)).toLocaleDateString('en-US', { day: "2-digit" , month: '2-digit', year: 'numeric'})  , // closest_title
                        endContent: [newFeature] // end_content
                    })
                }
            })
        })

        this.searchInProcess = false;
        this.searchResultsList = results;
    }

    clickOnSearchResult(result) {
        this.showResultModal = false;

        if (result.sourceLibrary === this.infoLibraries.knowledgeBase) {
            this.goToInfoPage(result.sourceLibrary , result.closestTitle ?  result.closestTitle : result.secondClosestTitle);
        }
        if (result.sourceLibrary === this.infoLibraries.FAQ) {
            this.goToInfoPage(result.sourceLibrary , result.closestTitle ?  result.closestTitle : result.secondClosestTitle);
        }
        else if (result.sourceLibrary === "What's New") {
            this.pageModule = this.dic.CONSTANTS.supportPageModules.timeScale;
            setTimeout(() => {
                this.scrollToAnchor(result.closestTitle)
            })
        }


        // highlight search text in info page content text:
        this.highlightSearchResults = true;
        this.highlightSearchText = this.searchQuery;
        //

        // this.searchQuery = '';
    }
}
