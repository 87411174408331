<popup-c
		[head]="globalData.title"
		size="dynamic"
		class="bs-enabled"
		[ngClass]="{'overflownV': haveDropdown()}"
		(closeCb)="cancel();"
		(confirmCb)="handleConfirm()"
		[buttons]="getPopupButtons()">
		<div class="flex-column f-1-0 gap-2" [ngStyle]="{'overflow' : !haveDropdown() ? 'auto' : ''}">
			<div class="pb-2 pt-1" style="overflow: auto;">
			<span *ngIf="globalData.type === dic.CONSTANTS.popupInfoConfirming ||
							  globalData.type === dic.CONSTANTS.popupWarningConfirming ||
							  globalData.type === dic.CONSTANTS.popupWarning ||
							  globalData.type === dic.CONSTANTS.popupInfo ||
							  globalData.type === dic.CONSTANTS.popupToggle"
				  class="d-flex gap-1 m-0"
				  style="font-size: 14px;"
				  [ngStyle]="getSubTitleStyle()">

				<!-- Include warning icon if necessary -->
				<i *ngIf="globalData.type === dic.CONSTANTS.popupWarningConfirming || globalData.type === dic.CONSTANTS.popupWarning" class="zmdi zmdi-alert-circle-o" style="font-size: 1.5rem; margin-top: -6px;"></i>
				{{globalData.subTitle}}
			</span>
			</div>

			<div>
				<!-- For popupInfoConfirming and popupWarningConfirming -->
				<ng-container *ngIf="globalData.type === dic.CONSTANTS.popupInfoConfirming || globalData.type === dic.CONSTANTS.popupWarningConfirming">
					<ul [hidden]="!globalData.bodyDetails">
						<li *ngFor="let text of globalData.bodyDetails; trackBy: gs.trackByIndex" [innerHTML]="text"></li>
					</ul>
					<div class="d-flex flex-column gap-2">
						<div class="align-items-center flex-row gap-3" *ngFor="let input of globalData.body; trackBy: gs.trackByIndex; let index = index;">
							<div [ngStyle]="{'min-width': input?.labelMinWidth ? input?.labelMinWidth : null}">{{input.labelName}}:</div>
							<input *ngIf="input?.type !== this.dic.CONSTANTS.popupBodytypes.dropdown"
								   type="text" placeholder={{input.placeholder}}
								   style="width:330px;" required={{input.required}}
								   [(ngModel)]="input.model"
								   [ngStyle]="input.model && !this.isFormValid ? {'border-color':'red'} : {}"
								   (ngModelChange)="validateForm(input.model, index)"/>

							<app-dropdown-c *ngIf="input?.type === this.dic.CONSTANTS.popupBodytypes.dropdown"
											style="width:330px;" text="{{input.model}}">
								<ul style="overflow: auto; max-height: 330px;">
									<li *ngFor="let item of input.dropdownObjects"
										(click)="input.model = item.name; input.selected = item.selected">
										{{item.name}}
									</li>
								</ul>
							</app-dropdown-c>
						</div>
					</div>
					<div class="text-danger mt-2" *ngIf="globalData.errorMessage">{{globalData.errorMessage}}</div>
					<div class="mt-2" *ngIf="!globalData.errorMessage">{{globalData.additionalInfo}}</div>
				</ng-container>

				<!-- For popupWarning -->
				<ng-container *ngIf="globalData.type === dic.CONSTANTS.popupWarning">
					<ul>
						<li *ngFor="let text of globalData.body; trackBy: gs.trackByIndex" [ngStyle]="text.startsWith('Note:') && {'font-weight':'bolder'}" [innerHTML]="text"></li>
					</ul>
				</ng-container>

				<!-- For popupInfo -->
				<ng-container *ngIf="globalData.type === dic.CONSTANTS.popupInfo">
					<ul>
						<li *ngFor="let text of globalData.body; trackBy: gs.trackByIndex" [innerHtml]="text"></li>
					</ul>
					<img [hidden]="!globalData.img" [src]="globalData.img">
				</ng-container>

				<!-- For popupToggle -->
				<ng-container *ngIf="globalData.type === dic.CONSTANTS.popupToggle">
					<ul class="py-2" *ngIf="globalData.details && globalData.details.length">
						<li *ngFor="let text of globalData.details" [innerHTML]="text"></li>
					</ul>
					<div class="vSpace3 py-2">
						<div *ngFor="let item of globalData.body; trackBy: gs.trackByIndex; let index = index">
							<div class="flexRow-start hSpace1 md-checkbox">
								<input id="checkbox-{{index}}" type="checkbox" [(ngModel)]="item.toggle">
								<label for="checkbox-{{index}}">
									<span style="vertical-align: middle;">{{item.labelName}}</span>
									<i style="font-weight: 400;" class="fas fa-question-circle ms-1" *ngIf="item.tooltip"
									   tooltip="{{item.tooltip}}"></i>
									<input class="toggle-input" type="{{item.input.type}}" maxlength="3"
										   [appMaskPattern]="'^([1-9]+)([0-9]*)$'"
										   *ngIf="item.input" [(ngModel)]="item.input.value"/>
									<span style="margin-left: 5px;" *ngIf="item.labelNameEnding">{{item.labelNameEnding}}</span>
								</label>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<!--special done button for files upload picker-->
			<div class="flxRow-end gap-2 mt-5" *ngIf="globalData.isFile">
				<btn-c id="popupCancel" class="popup-action-btn"
					   (action)="cancel()" variant="outlined"
					   *ngIf="globalData.type === dic.CONSTANTS.popupWarning || globalData.type === dic.CONSTANTS.popupWarningConfirming || (globalData.type === dic.CONSTANTS.popupInfo && !globalData.img)">
					{{globalData.cancelBtnText || 'Cancel'}}
				</btn-c>
				<btn-c
					class="popup-action-btn"
					*ngIf="globalData.isFile"
					ngfSelect multiple="1"
					ngfDrop
					accept=".csv"
					[(files)]="files"
					(fileChange)="done(files)">
					Ok
				</btn-c>
			</div>
		</div>
	</popup-c>
