import _ from "lodash";
import * as util from 'util';
import {GeneralService} from '../../../services/generalService';
import {DICTIONARY} from '../../../dictionary';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {AuthService} from '../../../services/authService';
import {ENV_CONSTS} from '../../../constants';
import {Component, NgZone, OnInit} from "@angular/core";
import {Location} from '@angular/common';
import {ClipboardService} from "ngx-clipboard";
import {ActivatedRoute} from "@angular/router";


@Component({
	selector: 'my-plan-component',
	templateUrl: './my-plan.component.html',
})
export class MyPlanComponent implements OnInit {
    dic = DICTIONARY;
    payments = this.gs.payments;
    corpname = this.gs.corpname;
    isLoaded: boolean;
    accountInfo: any;
    showUpgradeOrExpandPlan: boolean;
    sharedPlanInfo: any;
    user_joined: boolean;
    userInfo: any;
    partner: any;
	showCouponInput = false;
    daysLeftUntilExpiration;
    priceUntilExpiration;
    couponCode = '';
    oneYearAheadOfExpiration;
    isCouponApplied = false;
    currentLicenses: any;
    priceSaved: number;
    amountOfUsersToAdd = 1;
	tabWidth = 130
    upgradePlanMethods = {
		licenses: {display: 'Add Licenses', value: 'licenses', paypalPaymentType: this.dic.CONSTANTS.paypalPaymentType.extendPlanLicenses},
        oneYear: {display: 'Add One Year', value: 'oneYear', paypalPaymentType: this.dic.CONSTANTS.paypalPaymentType.extendPlanExpiration},
    }
    selectedUpgradePlanMethod = this.upgradePlanMethods.oneYear;
	_ = _;


    random = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5);
    subscriptions: any = {};

    constructor(private ngZone:NgZone,
				public gs:GeneralService,
				private authService: AuthService,
				private activatedRoute: ActivatedRoute,
				private location: Location,
				private rs:RouteService,
				private ns:NotificationService,
				private clipboard: ClipboardService) {
	}

	ngOnInit() {
		let partner = this.activatedRoute.snapshot.paramMap.get('company') || window.history.state.company;

		this.rs.getAccountInfo().then((accountInfo) => {
			this.isLoaded = true;

			this.accountInfo = accountInfo;
			this.showUpgradeOrExpandPlan = (this.accountInfo.user_role === this.dic.CONSTANTS.userRole.admin && this.accountInfo.plan.plan === this.dic.CONSTANTS.planTypePro) || this.accountInfo.plan.plan === this.dic.CONSTANTS.planTypeFree;
			if (this.accountInfo.plan.partner_plan_id) {
				this.showUpgradeOrExpandPlan = false;
			}

			this.currentLicenses = Math.max(this.accountInfo.plan.threat_protection.allowed_users, this.accountInfo.plan.shared_plan.allowed_users);
			this.sharedPlanInfo = accountInfo.plan;
			if (accountInfo.user_role === this.dic.CONSTANTS.userRole.admin
				|| accountInfo.user_role === this.dic.CONSTANTS.userRole.subAdmin) {
				this.accountInfo.plan.newExpiration = new Date(new Date().setFullYear(new Date(this.accountInfo.plan.expired).getFullYear() + 1));
			}
			else {
				// paste key and join to the plan (if not exist already)
				// check if company field is a shared plan key

				if (partner?.length > 60 && !this.sharedPlanInfo.shared_plan.key) {
					this.sharedPlanInfo.shared_plan.key = partner;
					this.joinSharedPlan();
				}
			}

			this.calculateDaysUntilExpiration();
			this.calculatePriceUntilExpiration();

			if (this.sharedPlanInfo.shared_plan.key) {
				this.user_joined = true;
			}
		});

		this.rs.getPlanDetails().then((response) => {
			this.subscriptions = response;
		});

		this.gs.getUserInfo(false, (userInfo) => {
			this.userInfo = userInfo;
		});

		partner = partner || 'trustifi';

		this.partner = this.dic.PAYPAL_PARTNERS[partner.toLowerCase()];
		if (this.partner) {
			this.partner.id = partner;
		}
	}

    calculateDaysUntilExpiration = () => {
        const today:any = new Date();
        const expirationDate:any = Date.parse(this.accountInfo.plan.expired);

        if (today > expirationDate) {
            this.daysLeftUntilExpiration = 0;
            this.oneYearAheadOfExpiration = (new Date()).setFullYear(new Date().getFullYear() + 1);
        }
        else {
            this.daysLeftUntilExpiration = Math.round(Math.abs((expirationDate - today) / this.dic.CONSTANTS.oneDay));
            let expirationAsDate = new Date(Date.parse(this.accountInfo.plan.expired));
            this.oneYearAheadOfExpiration = expirationAsDate.setFullYear(expirationAsDate.getFullYear() + 1);
        }
    }

    calculatePriceUntilExpiration = () => {
        if (this.daysLeftUntilExpiration > 0) {
            this.priceUntilExpiration = this.accountInfo.plan.proYearlyPrice * this.daysLeftUntilExpiration / 365;
        }
        else {
            this.priceUntilExpiration = this.accountInfo.plan.proYearlyPrice;
        }
    }

    copyKeyToClipboard = (value) => {
        this.clipboard.copy(value);
        this.ns.showInfoMessage(util.format(this.dic.MESSAGES.copyClipboard, "Key"));
    };

    paypalComplete = (err, res) => {
        if (err) {
            return;
        }

        this.ns.showInfoMessage(this.dic.MESSAGES.planPaymentCompleted);
        setTimeout(() => {
			this.ngZone.run(() => {
				window.location.replace(ENV_CONSTS.webAppUrl + this.dic.CONSTANTS.planUrl);
				window.location.reload();
			})
        }, 2500);
    };

    redirectToCustomerPlan = (isTrustifi) => {
        let finalUrl;

        if (isTrustifi) {
            finalUrl = 'https://trustifi.com/get-a-quote/';
        }
        else {
            finalUrl = this.payments.redirectUrl || '';

            if (this.accountInfo) {
                Object.keys(this.accountInfo).forEach((key) => {
                    let toReplace = new RegExp('{{' + key + '}}', 'g');
                    finalUrl = finalUrl.replace(toReplace, this.accountInfo[key]);
                });
            }
        }

        window.open(finalUrl, "_blank");
    };

	leaveSharedPlan = () => {
		this.gs.showPopup({
			title: 'Leave Current Plan',
			subTitle: 'Please note - You are about to leave the plan!',
			body: ['You will not be able to join the plan if the key changes.'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Leave',
			doneCb: () => {
				this.rs.updateUserSharedPlanStatus({action: this.dic.CONSTANTS.planActions.leave}).then((response) => {
					this.ns.showInfoMessage(this.dic.MESSAGES.planLeave);
					this.authService.logout();
				});
			}
		});
	}

    joinSharedPlan = () => {
		if (!this.sharedPlanInfo.shared_plan.key || this.sharedPlanInfo.shared_plan.key.length !== 64) {
			this.ns.showErrorMessage(this.dic.ERRORS.invalidKey);
			return;
		}

		this.rs.updateUserSharedPlanStatus({
			key: this.sharedPlanInfo.shared_plan.key,
			action: this.dic.CONSTANTS.planActions.join
		}).then((response) => {
			const url = ENV_CONSTS.webAppUrl + this.dic.CONSTANTS.planUrl;
			window.location.replace(url);
			window.location.reload();
		});
    };

    correctFreeUserAmountToAddInput = () => {
        const maxUsers = (this.partner && this.partner.maxUsers) || 20;
        if (this.amountOfUsersToAdd > maxUsers) {
            this.amountOfUsersToAdd = maxUsers;
        }
    }

    applyCoupon = () => {
        if (!this.couponCode) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'Coupon'));
            return;
        }

        this.rs.checkCoupon(this.couponCode).then((newPrice) => {
            this.isCouponApplied = true;
            this.priceSaved = this.accountInfo.plan.proYearlyPrice - newPrice;
            this.accountInfo.plan.proYearlyPrice = newPrice;
            this.calculatePriceUntilExpiration();
        });
    }
}

