import * as util from 'util';
import {DICTIONARY} from '../../../../dictionary';
import {RouteService} from '../../../../services/routeService';
import {NotificationService} from '../../../../services/notificationService';
import {GeneralService} from '../../../../services/generalService';
import {Component, OnInit} from "@angular/core";
import {ClipboardService} from 'ngx-clipboard';


@Component({
	selector: 'account-api-component',
	templateUrl: './account-api.component.html',
})
export class AccountApiComponent implements OnInit {
    dic = DICTIONARY;
    showApiDocemntation = this.gs.showApiDocemntation;
    hasWKTextSecurity = this.gs.hasWKTextSecurity();
    myAccount: {};
    myAccountApi: any;
    userInfo: any;
    regenerateSecretInProcess: boolean;
    updatedAPI: boolean;
    showsecret = false;

    constructor(private gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private clipboard: ClipboardService) {

    }

	ngOnInit() {
        this.myAccount = {};

        this.rs.getAccountInfo().then((accountInfo) => {
            this.myAccountApi = accountInfo.api;
        });


        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
        });
    }

    regenerateApiKey = () => {
        this.regenerateSecretInProcess = true;

        if (this.userInfo.type === this.dic.CONSTANTS.userTypes.heroku) {
            this.regenerateSecretInProcess = false;
            return this.ns.showErrorMessage(this.dic.ERRORS.herokuInvalidAction);
        }

        this.gs.showPopup({
            title: 'Regenerate API Secret',
            subTitle: 'Please note - You are about to regenerate API secret',
            body: ['Previous API requests with old secret will not work.'],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Regenerate',
            doneCb: () => this.regenerateApiKeyExecute()
        });
    };

    createApiKeyExecute = () => {
        this.updatedAPI = false;
        this.rs.createApiKey({enabled: true}).then((response) => {
            this.myAccountApi = response;
            this.updatedAPI = true;
        });
    };

    regenerateApiKeyExecute = () => {
        this.updatedAPI = false;
        this.rs.createApiKey({enabled: true, regenerate: true}).then((response) => {
            this.myAccountApi = response;
            this.updatedAPI = true;
            this.regenerateSecretInProcess = false;
        }, (err) => {
            this.regenerateSecretInProcess = false;
        });
    };

    updateApiKeyStatus = () => {
        if (this.userInfo.type === this.dic.CONSTANTS.userTypes.heroku) {
            this.myAccountApi.enabled = true;
            return this.ns.showErrorMessage(this.dic.ERRORS.herokuInvalidAction);
        }

        if (this.userInfo.user_role === this.dic.CONSTANTS.userRole.admin && this.userInfo.plan.threat_protection && this.userInfo.plan.threat_protection.enabled_inbound) {
            this.myAccountApi.enabled = true;
            return this.ns.showErrorMessage(this.dic.ERRORS.tpCannotDisableApiKey);
        }

        let title, subTitle, body, doneBtnText, type;

        if (this.myAccountApi.enabled) {
            title = 'Enable API';
            subTitle = 'Please note - You are about to Enable API';
            body = [
                'Your API key and secret will be enabled and usable for API requests',
                'Multi-Factor Authentication (MFA) will not be enforced when using API requests'
            ];
            doneBtnText = 'Enable';
            type = this.dic.CONSTANTS.popupInfo;
        }
        else {
            title = 'Disable API';
            subTitle = 'Please note - You are about to disable API';
            body = ['Your API key and secret will be disabled and not usable for API requests'];
            doneBtnText = 'Disable';
            type = this.dic.CONSTANTS.popupWarning;
        }

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: body,
            type: type,
            doneBtnText: doneBtnText,
            doneCb: () => {
                this.rs.createApiKey({enabled: this.myAccountApi.enabled}).catch((err) => {
                    this.myAccountApi.enabled = !this.myAccountApi.enabled;
                });
            },
            cancelCb: () => {
                this.myAccountApi.enabled = !this.myAccountApi.enabled;
            }
        });
    };

    copyApiKeyToClipboard = (apiKey) => {
        if (apiKey) {
            this.clipboard.copy(this.myAccountApi.key);
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.copyClipboard, "Key"));
        }
        else {
            this.clipboard.copy(this.myAccountApi.secret);
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.copyClipboard, "Secret"));
        }
    };
}
