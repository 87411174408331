import {GeneralService} from "../../../services/generalService";
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'partner-contact-info-component',
  templateUrl: './partner-contact-info.component.html',
})
export class PartnerContactInfoComponent implements OnInit {
    constructor(private gs:GeneralService, private rs:RouteService, private ns:NotificationService) {
    }

    partnerInfo:any;
    dic = DICTIONARY;
    updatePartnerInfoInProcess;
    getPartnerInfoInProcess;

    ngOnInit() {
        this.getPartnerInfo();
    }

    getPartnerInfo() {
        this.getPartnerInfoInProcess = true;
        this.rs.getPartnerInfo().then((response) => {
            this.partnerInfo = response;
            if (!this.partnerInfo.partners.partner_contact_info) {
                this.partnerInfo.partners.partner_contact_info = {};
            }
            this.getPartnerInfoInProcess = false;
        }, err => {
            this.getPartnerInfoInProcess = false;
        });
    };

    updatePartnerContactInfo() {
        this.updatePartnerInfoInProcess = true;

        const registrationInputs = {
            action: this.dic.CONSTANTS.partnerAction.contactInfo,
            email: this.partnerInfo.partners.partner_contact_info.edit.email,
            phone: this.partnerInfo.partners.partner_contact_info.edit.phone
        };

        this.rs.doActionOnPartner(registrationInputs).then(() => {
            this.ns.showInfoMessage('Contact information updated successfully');
			Object.assign(this.partnerInfo.partners.partner_contact_info, this.partnerInfo.partners.partner_contact_info.edit);
			if (this.partnerInfo.partners.partner_contact_info.phone.phone_number) {
				this.partnerInfo.partners.partner_contact_info.phone.phone_number = this.partnerInfo.partners.partner_contact_info.phone.phone_number.replace(/^0+/, '');
			}

            this.partnerInfo.partners.partner_contact_info.edit = null;
            this.updatePartnerInfoInProcess = false;
        }, (err) => {
            this.updatePartnerInfoInProcess = false;
        });
    };

    startEditPartnerInfo() {
        this.partnerInfo.partners.partner_contact_info.edit = {
            email: this.partnerInfo.partners.partner_contact_info.email || '',
            phone: _.clone(this.partnerInfo.partners.partner_contact_info.phone) || {country_code: '', phone_number: ''}
        };
    }

    confirmEditPartnerInfo = (isApproved) => {
        // edit was cancelled
        if (!isApproved) {
            this.partnerInfo.partners.partner_contact_info.edit = null;
            return;
        }

        // edit approved
        this.updatePartnerContactInfo();
    }
}
