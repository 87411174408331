import L from 'leaflet';
import _ from 'lodash';
import {DICTIONARY} from '../../../dictionary';
import {AccountCompromisedService} from '../../../services/accountCompromisedService';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {GeneralService} from '../../../services/generalService';
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'account-management-component',
	templateUrl: './account-management.component.html',
})
export class AccountManagementComponent implements OnInit {
	loadAccountsInProcess = false;
	dic = DICTIONARY;

	multipleUsersActions = [
		this.dic.CONSTANTS.accountCompromisedUsersActions.enable,
		this.dic.CONSTANTS.accountCompromisedUsersActions.disable
	];

	users;
	filterData;
	accountInfo;
	showAccountInfoPopup = false;

	constructor(private accountCompromisedService:AccountCompromisedService,
				private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService) {
	}

	ngOnInit(): void {
		this.initFilters();
		this.getAccountCompromisedUsers();
    }

	getAccountCompromisedUsers = () => {
		this.loadAccountsInProcess = true;
		this.rs.getAccountCompromisedUsers('').then(response => {
			this.users = response;
			this.users = _.map(this.users, user => {
				user.status = user.misc.account_compromised.enabled ? 'enabled' : 'disabled'
				return user;
			});

			this.loadAccountsInProcess = false;
		}, (err) => {
			this.loadAccountsInProcess = false;
		});
	}

	showUserBulkActions = () => {
		return this.multipleUsersActions;
	}

	showMailboxActions = (mailboxObj) => {
		let actions;

		if (mailboxObj.misc.account_compromised.enabled) {
			actions = [this.dic.CONSTANTS.accountCompromisedUsersActions.disable];
		}
		else {
			actions = [this.dic.CONSTANTS.accountCompromisedUsersActions.enable];
		}
		actions.push(
			this.dic.CONSTANTS.accountCompromisedUsersActions.accountInfo,
			this.dic.CONSTANTS.accountCompromisedUsersActions.suspiciousInfo
		);

		return actions;
	}

	selectAction = (mailbox, action) => {
		if (!mailbox) {
			return;
		}

		switch (action) {
			case this.dic.CONSTANTS.accountCompromisedUsersActions.enable:
				this.updateStatus([mailbox], true);
				break;

			case this.dic.CONSTANTS.accountCompromisedUsersActions.disable:
				this.updateStatus([mailbox], false);
				break;

			case this.dic.CONSTANTS.accountCompromisedUsersActions.accountInfo:
				this.getAccountCompromisedInfo(mailbox);
				break;

			case this.dic.CONSTANTS.accountCompromisedUsersActions.suspiciousInfo:
				this.accountCompromisedService.showUserIncidentsInfo(mailbox);
				break;
		}
	}

	selectMultipleAction = (selectedUsers,action) => {
		switch (action) {
			case this.dic.CONSTANTS.accountCompromisedUsersActions.enable:
				this.updateStatus(selectedUsers, true);
				break;

			case this.dic.CONSTANTS.accountCompromisedUsersActions.disable:
				this.updateStatus(selectedUsers, false);
				break;
		}
	}

	showDeviceAction = (deviceObj) => {
		return ['Delete'];
	}

	selectDeviceAction = (deviceObj, action) => {
		switch (action) {
			case 'Delete':
				this.rs.deleteUserDevice(this.accountInfo.email, deviceObj._id).then(() => {
					this.accountInfo.devices = this.accountInfo.devices.filter(itm => itm._id.toString() !== deviceObj._id.toString());
					this.prepareDevicesLocationInfo(this.accountInfo.devices);
					this.ns.showInfoMessage(this.dic.MESSAGES.operationCalled);
				}, () => {

				});
				break;
		}
	}

	updateStatus(users, enable) {
		const updateData = {
			action: this.dic.CONSTANTS.accountCompromisedUsersActions.status,
			enable: enable,
			emails: _.map(users, 'email')
		};
		this.rs.updateAccountCompromisedUsers(updateData).then(() => {
			_.map(users, u => {
				u.misc.account_compromised.enabled = enable;
				u.status = u.misc.account_compromised.enabled ? 'enabled' : 'disabled'
				u.selected = false;
				return u;
			})
			//this.selectedAll = false;
			//this.toggleAllUsers();
			users.length = 0;
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		});
	}

	initFilters = () => {
		this.filterData = {
			filterType: this.dic.CONSTANTS.tableFilters.accountManagement,
			filters: {
				status: ['enabled', 'disabled']
			},
			initFiltersFunction: this.initFilters
		};
	}

	searchUsers = (event) => {
		this.users.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = this.searchTextExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			if (event.activeFilters) {
				// need to match all filter types
				let numFilterToMatch = Object.keys(event.activeFilters).length;

				if (event.activeFilters.status && event.activeFilters.status.length) {
					if (event.activeFilters.status.includes(record.status)) {
						numFilterToMatch--;
					}
				}
				if (numFilterToMatch) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	exportToCsv = (sortBy) => {
		if (!this.users || !this.users.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Name,Email,Status\n";

		let sortedTable = _.filter(this.users,user => {return !user.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((user) => {
			csvString += `"${user.name}",${user.email},"${user.status}"\n`;
		});

		this.gs.exportCsv(csvString, `user_management.csv`);
	}

	getAccountCompromisedInfo(user) {
		if (!user.misc.account_compromised.devices.length) {
			return this.ns.showWarnMessage(this.dic.ERRORS.noDevicesData);
		}
		this.accountInfo = {
			email: user.email,
			devices: user.misc.account_compromised.devices
		};
		if (!user.isParsed) {
			this.accountInfo.devices = _.map(this.accountInfo.devices, device => {
				device.agent = device.agent || {};
				if (device.agent.raw) {
					device.agent.raw = JSON.parse(device.agent.raw);
				}
				return device;
			});
			user.isParsed = true;
		}
		if (this.accountInfo.devices){
			this.prepareDevicesLocationInfo(this.accountInfo.devices);
		}
		this.showAccountInfoPopup = true;
	}

	prepareDevicesLocationInfo(deviceList) {
		this.accountInfo.locations = [];
		let bounds = [];
		deviceList.forEach((deviceObj) => {
			deviceObj.location.sort((a, b) => new Date(b.last_used || b.created).getTime() - new Date(a.last_used || a.created).getTime());
			deviceObj.location.forEach((locationObj) => {
				if (locationObj.ll.length === 2) {
					const marker = {
						icon: this.gs.leafletDefaultIcon,
						lat: locationObj.ll[0],
						lng: locationObj.ll[1],
						title: this.parseDeviceClient(deviceObj),
						draggable: false
					};

					bounds.push([locationObj.ll[0],locationObj.ll[1]]);

					this.accountInfo.locations.push(marker);
				}
			})
		});

		const centroid = new L.LatLngBounds(bounds).getCenter();

		this.accountInfo.center_location = {
			lat: centroid.lat,
			lng: centroid.lng,
			zoom: 2
		};
	}

	parseDeviceClient(device) {
		if (device.agent.browser) {
			return `${device.agent.browser.name} (${device.agent.browser.version}), ${device.agent.os.name} (${device.agent.os.version})`;
		}
		if (device.agent.raw) {
			return `${device.agent.raw.ua || 'N/A'}`;
		}

		return 'N/A';
	}


	openLocation(ll) {
		this.gs.openLocation(ll);
	}

	searchTextExecute(userObj, searchTerm) {
		searchTerm = searchTerm.toLowerCase();
		return ((userObj.name && userObj.name.toLowerCase().indexOf(searchTerm) > -1) ||
			(userObj.email.toLowerCase().indexOf(searchTerm) > -1));
	}
}
