import _ from 'lodash';
import {GeneralService} from '../../../services/generalService';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {DICTIONARY} from '../../../dictionary';
import {Component, OnInit} from "@angular/core";
import util from "util";
import {Router} from "@angular/router";

@Component({
	selector: 'threat-simulation-campaigns-component',
	templateUrl: './threat-simulation-campaigns.component.html',
})
export class ThreatSimulationCampaignsComponent implements OnInit {

	dic = DICTIONARY;
	_ = _;
	threatSimulationConfig;
	getCampaignsInProcess;
	getCampaignDataInProcess;
	activeCampaignTab = this.dic.CONSTANTS.threatSimulationCampaignTabs.emailDetails;
	currentCampaign;
	searchCampaignTxt;
	campaigns: any;
	showNewCampaignWizard;
	isThisFeatureSupported;
	doNotChangeIsExpandedStates;
	updateCampaignGroupPopup;
	addCampaignRecipientsPopup;
	verifiedDomains;
	materials;
	campaignForRelaunch;
	isTrainingCampaigns;

	constructor(public gs:GeneralService,
				private rs:RouteService,
				private router:Router,
				private ns:NotificationService) {
		const currentUrl = this.router.url.split('?')[0].split('#')[0];
		this.isTrainingCampaigns = _.last(currentUrl.split('/')) === this.dic.CONSTANTS.threatSimulationCampaignsPageTabs.trainingCampaigns;
	}

	ngOnInit() {
		this.getThreatSimulationCampaigns();
		this.getThreatSimulationConfiguration();

		if (this.isTrainingCampaigns) {
			this.getMaterials();
		}
	};

	getThreatSimulationConfiguration() {
		this.rs.getThreatSimulationConfiguration().then((response) => {
			this.threatSimulationConfig = response.threat_simulation;
			this.verifiedDomains = response.domains;
		}, (err) => {
		});
	}

	getThreatSimulationCampaigns = (newCampaignId=null) => {
		this.getCampaignsInProcess = true;
		this.rs.getThreatSimulationCampaigns().then((response) => {
			this.isThisFeatureSupported = true;

			response = _.filter(response || [], campaignObj => {
				if (this.isTrainingCampaigns) {
					return campaignObj.ts_type === this.dic.CONSTANTS.threatSimulationType.materials.value;
				}
				else {
					return campaignObj.ts_type !== this.dic.CONSTANTS.threatSimulationType.materials.value;
				}
			});

			this.campaigns = this.createCampaignsGroupView(response);

			if (newCampaignId) {
				this.selectCampaign(newCampaignId);
			}

			this.getCampaignsInProcess = false;
		}, (err) => {
			if (err?.data?.message && err.data.message.includes('This feature is not supported')) {
				this.isThisFeatureSupported = false;
			}

			this.getCampaignsInProcess = false;
		});
	};

	selectCampaign = (selectCampaignId) => {
		if (selectCampaignId && this.campaigns?.length) {
			let campaignToSelect = _.find(this.campaigns, {_id: selectCampaignId});
			if (campaignToSelect) {
				this.setCurrentCampaign(campaignToSelect);
			}
			// not found - must be inside another group with different name
			else {
				this.campaigns.forEach(campaign => {
					const campaignToSelect = campaign.campaigns && _.find(campaign.campaigns, {_id: selectCampaignId});
					if (campaignToSelect) {
						this.setCurrentCampaign(campaignToSelect);

						// in timeout since it changes the "campaigns" list. we save it to the end
						setTimeout(() => {
							!campaign.isExpanded && this.toggleExpandItem(campaign);
						});
					}
				});
			}
		}
	}

	createCampaignsGroupView = (campaignsRawList) => {
		const campaignsList = [];

		// @ts-ignore
		campaignsRawList.sort((a, b) => new Date(a.created) - new Date(b.created));

		campaignsRawList.forEach(campaign => {
			campaign.isSelectable = true;

			if (campaign.group_name) {
				const groupObj = _.find(campaignsList, groupOrCampaign => groupOrCampaign.campaigns && groupOrCampaign.group_name === campaign.group_name);
				if (groupObj) {
					groupObj.campaigns.push(campaign);
				}
				else {
					campaignsList.push({
						created: campaign.created,
						name: campaign.group_name,
						group_name: campaign.group_name,
						isSelectable: true,
						campaigns: [campaign]
					});
				}
			}
			else {
				campaignsList.push(campaign);
			}
		});

		return campaignsList;
	};

	toggleExpandItem = (item, event=null) => {
		if (!item.campaigns?.length) {
			return;
		}

		item.isExpanded = !item.isExpanded;
		this.doNotChangeIsExpandedStates = true;
		event && event.stopPropagation();

		if (item.isExpanded) {
			this.setCurrentCampaign(item)
		}

		// refresh the trustifi-table by changing the list. The directive detects changes and fires an update
		this.campaigns = _.cloneDeep(this.campaigns);
	}

	showCampaignActions = (campaignObj) => {
		const actions:any = _.compact([
			!campaignObj.campaigns && this.dic.CONSTANTS.threatSimulationNewCampaignActions.groupCampaign,
			campaignObj.configuration && this.dic.CONSTANTS.threatSimulationNewCampaignActions.relaunch,
			campaignObj.ts_type !== this.dic.CONSTANTS.threatSimulationType.materials.value && this.dic.CONSTANTS.threatSimulationNewCampaignActions.sendCampaignTraining,
			!campaignObj.campaigns && this.dic.CONSTANTS.threatSimulationNewCampaignActions.addRecipients,
			this.dic.CONSTANTS.threatSimulationNewCampaignActions.delete
		]);

		return actions;
	}

	showBulkCampaignsActions = () => {
		return [
			this.dic.CONSTANTS.threatSimulationNewCampaignActions.delete
		];
	}

	selectCampaignAction = (campaignObj, action) => {
		switch (action) {
			case this.dic.CONSTANTS.threatSimulationNewCampaignActions.delete:
				this.openDeleteCampaignPopup([campaignObj]);
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignActions.groupCampaign:
				this.openUpdateCampaignGroupPopup(campaignObj);
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignActions.sendCampaignTraining:
				this.sendCampaignTraining(campaignObj);
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignActions.relaunch:
				this.relaunchCampaign(campaignObj);
				break;

			case this.dic.CONSTANTS.threatSimulationNewCampaignActions.addRecipients:
				this.addRecipientsToCampaign(campaignObj);
				break;
		}
	}

	selectMultipleCampaignAction = (selectedCampaigns, action) => {
		switch (action) {
			case this.dic.CONSTANTS.threatSimulationNewCampaignActions.delete:
				this.openDeleteCampaignPopup(selectedCampaigns);
				return;
		}
	}

	openDeleteCampaignPopup = (campaigns) => {
		let title, subTitle;
		if (campaigns.length > 1) {
			title = 'Delete Campaigns';
			subTitle = `Please note - you are about to delete ${campaigns.length} campaigns`;
		}
		else {
			title = 'Delete Campaign';
			subTitle = `Please note - you are about to delete ${campaigns[0].name}`;
		}

		this.gs.showPopup({
			title: title,
			subTitle: subTitle,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				campaigns.forEach((campaignObj) => {
					this.deleteCampaignExecute(campaignObj);
				});
			}
		});
	};

	deleteCampaignExecute(campaignObj) {
		// remove group
		if (campaignObj.campaigns) {
			campaignObj.campaigns.forEach((campaignChildObj) => {
				this.deleteCampaignExecute(campaignChildObj);
			});
			return;
		}


		this.rs.deleteThreatSimulationCampaign(campaignObj._id).then(() => {
			this.removeCampaignFromList(campaignObj);

			if (this.currentCampaign?._id === campaignObj._id) {
				this.currentCampaign = null;
			}
			this.ns.showInfoMessage(this.dic.MESSAGES.campaignDeleted);
		});
	};

	removeCampaignFromList = (campaignObj) => {
		// remove regular campaign
		const removedCampaign = _.find(this.campaigns, {_id: campaignObj._id});
		if (removedCampaign) {
			_.remove<any>(this.campaigns, removedCampaign);
		}
		// if not regular - search in child campaigns
		else {
			this.campaigns.forEach(campaign => {
				if (campaign.campaigns) {
					const campaignToRemove = _.find<any>(campaign.campaigns, {_id: campaignObj._id});

					if (campaignToRemove) {
						_.remove<any>(campaign.campaigns, campaignToRemove);

						// remove empty group
						if (!campaign.campaigns?.length) {
							_.remove(this.campaigns, {name: campaign.name, group_name: campaign.group_name});
							return;
						}
						else {
							this.triggerSearch();
						}
					}
				}
			});
		}
	}

	openUpdateCampaignGroupPopup = (campaignToInsert) => {
		this.updateCampaignGroupPopup = {
			campaignToInsert,
			isNewGroup: false,
			newGroupName: '',
			selectedGroup: '',
			groups: _.filter(this.campaigns, 'campaigns'),
			error: false,
			show: true,
			doneCb: () => {
				// insert to new campaigns group
				if (this.updateCampaignGroupPopup.isNewGroup) {
					if (!this.updateCampaignGroupPopup.newGroupName) {
						this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'New group name'));
						this.updateCampaignGroupPopup.error = true;
						return;
					}
					if (_.some(this.campaigns, campaignOrGroup => campaignOrGroup.campaigns && campaignOrGroup.name === this.updateCampaignGroupPopup.newGroupName)) {
						this.ns.showWarnMessage(util.format(this.dic.ERRORS.itemAlreadyExist, 'Group with same name'));
						this.updateCampaignGroupPopup.error = true;
						return;
					}

					this.updateCampaignGroupExecute(campaignToInsert, this.updateCampaignGroupPopup.newGroupName)
				}
				// insert to existing campaigns group OR group with another existing campaign
				else {
					if (!this.updateCampaignGroupPopup.selectedGroup) {
						this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'group'));
						this.updateCampaignGroupPopup.error = true;
						return;
					}

					if (this.updateCampaignGroupPopup.selectedGroup.campaigns && _.some(this.updateCampaignGroupPopup.selectedGroup.campaigns, {_id: campaignToInsert._id})) {
						this.ns.showWarnMessage(util.format(this.dic.ERRORS.XAlreadyExistInX, 'Campaign', 'the selected campaign group'));
						this.updateCampaignGroupPopup.error = true;
						return;
					}

					this.updateCampaignGroupExecute(campaignToInsert, this.updateCampaignGroupPopup.selectedGroup.group_name)
				}
			}
		}
	}

	updateCampaignGroupExecute = (campaignObj, groupName) => {
		if (campaignObj.group_name === groupName) {
			return
		}

		const data = {
			action: 'campaignGroup',
			groupName: groupName
		};

		this.rs.updateThreatSimulationCampaign(campaignObj._id, data).then(() => {
			this.removeCampaignFromList(campaignObj);
			campaignObj.group_name = groupName;

			const existingGroup = _.find(this.campaigns, campaignOrGroup => campaignOrGroup.campaigns && campaignOrGroup.group_name === groupName);

			// add to existing group
			if (existingGroup) {
				existingGroup.campaigns.push(campaignObj);
			}
			// create new group and add item
			else {
				this.campaigns.unshift({
					name: groupName,
					group_name: groupName,
					isSelectable: true,
					campaigns: [campaignObj]
				});
			}

			this.updateCampaignGroupPopup = null;
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
		});
	}

	sendCampaignTraining = (campaignObj) => {
		this.gs.showPopup({
			title: 'Resend Training Materials',
			subTitle: 'All recipients who have not acknowledged the training material will receive it again',
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const data = {
					action: 'sendCampaignTraining',
				};

				this.rs.updateThreatSimulationCampaign(campaignObj._id, data).then(() => {
					this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
				});
			}
		});
	}

	// since the search input is outside the trustifi-table component, we should trigger the directive's inner search mechanism from the outside.
	// we do it by changing the list so that the directive will detect changes and fire a search
	triggerSearch = () => {
		this.campaigns = _.cloneDeep(this.campaigns);
	}

	searchCampaign = (searchTerm, activeFilters) => {
		this.campaigns.forEach(campaignObj => {
			// search
			if (this.searchCampaignTxt) {
				let isFound = searchTextExecute(campaignObj, this.searchCampaignTxt);
				if (!isFound && campaignObj.campaigns?.length) {
					for (let i = 0; i < campaignObj.campaigns.length; i++) {
						isFound = searchTextExecute(campaignObj.campaigns[i], this.searchCampaignTxt);
						if (isFound) {
							break;
						}
					}
				}
				if (!isFound) {
					campaignObj.hide = true;
					return;
				}
			}

			// filter
			// no filters in this table

			campaignObj.hide = false;
		});
	};

	setCurrentCampaign = (campaignOrCampaignsGroup) => {
		if (campaignOrCampaignsGroup.name === this.currentCampaign?.name && campaignOrCampaignsGroup.created === this.currentCampaign?.created) {
			return;
		}

		this.setAsSoloSelected(campaignOrCampaignsGroup);

		if (campaignOrCampaignsGroup.campaigns) {
			this.currentCampaign = campaignOrCampaignsGroup;
			this.currentCampaign.recipients_count = _.sumBy(this.currentCampaign.campaigns, 'recipients_count'); // for PDF
			this.activeCampaignTab = ''; // set to nothing to update the <threat-simulation-campaign-summary-component>
			setTimeout(() => {
				this.activeCampaignTab = this.dic.CONSTANTS.threatSimulationCampaignTabs.summary;
			});
			return;
		}

		this.getCampaignDataInProcess = true;

		this.rs.getCampaignData(campaignOrCampaignsGroup._id).then((response) => {
			this.currentCampaign = response;

			if (this.isTrainingCampaigns) {
				this.currentCampaign.material = _.find(this.materials, {_id: this.currentCampaign.material_id});
			}

			this.activeCampaignTab = ''; // set to nothing to update the <threat-simulation-campaign-details-component>

			setTimeout(() => {
				this.activeCampaignTab = this.dic.CONSTANTS.threatSimulationCampaignTabs.emailDetails;
				this.getCampaignDataInProcess = false;
			});
		}, (err) => {
			if (this.currentCampaign) {
				this.setAsSoloSelected(this.currentCampaign);
			}
			this.getCampaignDataInProcess = false;
		});
	}

	setAsSoloSelected = (campaignRecord) => {
		this.campaigns.forEach(campaignOrCampaignGroup => {

			campaignOrCampaignGroup.soloSelected = false;

			if (campaignOrCampaignGroup.campaigns?.length) {
				campaignOrCampaignGroup.campaigns.forEach(campaignInGroup => {
					campaignInGroup.soloSelected = false;
				});
			}
		});

		campaignRecord.soloSelected = true;
	}

	newCampaignWizard() {
		this.campaignForRelaunch = null;
		this.showNewCampaignWizard = true;
	};

	relaunchCampaign = (campaignObj) => {
		this.campaignForRelaunch = campaignObj;
		this.showNewCampaignWizard = true;
	}

	addRecipientsToCampaign = (campaignObj) => {
		this.addCampaignRecipientsPopup = {
			campaignObj,
			existingRecipients: [],
			allPossibleRecipients: [],
			showGroups: true,
			showMailboxes: true,
			executeInProcess: false,
			loading: true,
			show: true
		};
		const promises = [this.getCampaignSummary(campaignObj), this.getCampaignRecipients()];

		Promise.all(promises)
			.then(results => {

				const existingEmails = _.map(this.addCampaignRecipientsPopup.existingRecipients, 'email');

				this.addCampaignRecipientsPopup.allPossibleRecipients.forEach(recipientOrGroup => {
					const isGroupSent = recipientOrGroup.members?.length && _.every(recipientOrGroup.members, member => existingEmails.includes(member.email));
					const isRecipientSent = !recipientOrGroup.members?.length && existingEmails.includes(recipientOrGroup.email);

					recipientOrGroup.alreadySent = isGroupSent || isRecipientSent;
					recipientOrGroup.isSelectable = !recipientOrGroup.alreadySent;
				});

				this.addCampaignRecipientsPopup.loading = false;
			})
			.catch(error => {
				this.addCampaignRecipientsPopup.loading = false;
			});

	}

	addRecipientsToCampaignExecute = () => {
		let recipientsToSend = _.union(_.flatMapDeep(this.addCampaignRecipientsPopup.allPossibleRecipients, recipientOrGroup => {
			if (recipientOrGroup.selected) {
				return recipientOrGroup.members?.length ? [...recipientOrGroup.members] : [recipientOrGroup];
			}
		}));

		recipientsToSend = _.reject(recipientsToSend, recipient => !recipient || !!_.find(this.addCampaignRecipientsPopup.existingRecipients, {email: recipient.email}));
		if (!recipientsToSend?.length) {
			this.ns.showErrorMessage('You need to select at least one recipient');
			return;
		}

		const data = {
			action: 'addRecipients',
			rcptTo: recipientsToSend
		};
		this.addCampaignRecipientsPopup.executeInProcess = true;

		this.rs.updateThreatSimulationCampaign(this.addCampaignRecipientsPopup.campaignObj._id, data).then(() => {
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
			this.addCampaignRecipientsPopup = null;
		}, err => {
			this.addCampaignRecipientsPopup.executeInProcess = false;
		});
	}

	toggleExpandItemCampaignRecipients = (item, tableEl, event=null) => {
		if (!item.members?.length) {
			return;
		}

		item.isExpanded = !item.isExpanded;
		event && event.stopPropagation();

		tableEl.searchItem();
	}

	searchCampaignRecipients = (searchTerm, activeFilters) => {
		this.addCampaignRecipientsPopup.allPossibleRecipients.forEach(record => {
			if ((record.members?.length && !this.addCampaignRecipientsPopup.showGroups) || (!record.members?.length && !this.addCampaignRecipientsPopup.showMailboxes)) {
				record.isExpanded = false;
				record.hide = true;
				return;
			}

			// search
			let isFound = searchRecipientTextExecute(record, searchTerm);
			if (!isFound) {
				record.hide = true;
				return;
			}

			record.hide = false;
		});
	}

	getCampaignSummary = (campaignObj) => {
		return new Promise(resolve => {
			this.rs.getCampaignSummary(campaignObj._id).then((response) => {
				this.addCampaignRecipientsPopup.existingRecipients = response.recipients;
				resolve(response.recipients);
			}, (err) => {
			});
		});
	}

	getCampaignRecipients = (): Promise<any> => {
		return new Promise(resolve => {
			this.rs.getCampaignRecipients().then((recipients) => {
				this.addCampaignRecipientsPopup.allPossibleRecipients = recipients.groups.concat(recipients.mailboxes);
				resolve(recipients);
			}, (err) => {
			});
		});
	}

	getMaterials = (): Promise<any> => {
		return new Promise(resolve => {
			this.rs.getThreatSimulationMaterials().then((materials) => {
				this.materials = materials;
				resolve(materials);
			}, (err) => {
			});
		});
	}
}

function searchTextExecute(campaign, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((campaign.name?.toLowerCase().indexOf(searchTerm) > -1) ||
		(campaign.group_name?.toLowerCase().indexOf(searchTerm) > -1) ||
		(campaign.description?.toLowerCase().indexOf(searchTerm) > -1));
}

function searchRecipientTextExecute(campaign, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((campaign.name?.toLowerCase().indexOf(searchTerm) > -1) ||
		(campaign.email?.toLowerCase().indexOf(searchTerm) > -1));
}
