<div class="list-group list-group-flush settings-list-container f-1-0 overflow-auto">
	<!--Notify me when campaign is over-->
	<div class="list-group-item settings-list-item py-2 disabled-overlay">
		<div class="title-section-container flex-column gap-2">
			<b>Campaign Duration</b>
			<span class="lh-base" style="font-size: 0.9em;">Run this campaign for a certain amount of days. Actions performed by recipients will be registered only while the campaign is active.</span>
		</div>
		<div class="content-container flex-column gap-2">
			<div class="flex-row gap-2 align-items-center">
				<input type="number" min="1" max="365" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
					   style="width: 50px;"
					   (keyup)="errMessage = ''"
					   [ngClass]="{'validation-error' : errMessage && !configurationSettings.expired_days || configurationSettings.expired_days < 1 || configurationSettings.expired_days > 365}"
					   [(ngModel)]="configurationSettings.expired_days">
				<span>days</span>
			</div>
		</div>
		<i class="zmdi zmdi-lock-outline align-self-center" style="font-size: 1rem; z-index: 1001;" tooltip="Cannot be changed after campaign has started"></i>
	</div>

	<!--Campaign pace-->
	<div class="list-group-item settings-list-item py-2 disabled-overlay">
		<div class="title-section-container flex-column gap-2">
			<b>Progressive Dispatch</b>
			<span class="lh-base" style="font-size: 0.9em;">Spreads out the campaign delivery over several hours, optimizing recipient engagement by delivering emails at intervals for better impact</span>
		</div>
		<div class="content-container flex-column gap-2">
			<div class="flxRow-start gap-2" style="align-items: center;">
				<toggle-switch-c id="enable_rate_email_delivery_hours_durationConfiguration"
								 [(model)]="configurationSettings.enable_rate_email_delivery_hours_duration"
								 [hideText]="true"></toggle-switch-c>
				<label for="enable_rate_email_delivery_hours_durationConfiguration" style="margin: 0;">
					<div class="flxRow hSpace2" style="align-items: center; height: 16px;">
						<span>Enable for a duration of</span>
						<input style="text-align: center;" type="number" min="1" max="365" step="1" pattern="^\d*(\.\d{0,3})?$"
							   [(ngModel)]="configurationSettings.rate_email_delivery_hours_duration"
							   (change)="configurationSettings.enable_rate_email_delivery_hours_duration = true;">
						<span>hours</span>
					</div>
				</label>
			</div>
		</div>
		<i class="zmdi zmdi-lock-outline align-self-center" style="font-size: 1rem; z-index: 1001;" tooltip="Cannot be changed after campaign has started"></i>
	</div>

	<!--schedule-->
	<div class="list-group-item settings-list-item py-2 disabled-overlay">
		<div class="title-section-container flex-column gap-2">
			<b>Scheduled Campaign Launch</b>
			<span class="lh-base" style="font-size: 0.9em;">Send the campaign to all recipients in a scheduled future time, instead of immediately.</span>
		</div>
		<div class="content-container flex-column gap-2">
			<div class="flxRow-start gap-2" style="align-items: center;">
				<toggle-switch-c id="enableDefaultConfiguration"
								 [(model)]="configurationSettings.scheduled_time_enabled"
								 [hideText]="true"></toggle-switch-c>
				<label for="enableDefaultConfiguration" style="margin: 0;">Enable</label>
			</div>
			<div class="flex-row align-items-center gap-1" [ngClass]="{'disabled-overlay' : !configurationSettings.scheduled_time_enabled}">
				<input type="datetime-local" [(ngModel)]="configurationSettings.scheduled_time">
			</div>
		</div>
		<i class="zmdi zmdi-lock-outline align-self-center" style="font-size: 1rem; z-index: 1001;" tooltip="Cannot be changed after campaign has started"></i>
	</div>

	<!--Notify me when link is clicked-->
	<div class="list-group-item settings-list-item py-2">
		<div class="title-section-container flex-column gap-2">
			<b>Notify Me When Link Is Clicked</b>
			<span class="lh-base" style="font-size: 0.9em;">Receive a notification email whenever any recipient clicks on a tracked link in the simulation email.</span>
		</div>
		<div class="content-container flex-column gap-2">
			<div class="flxRow-start gap-2" style="align-items: center;">
				<toggle-switch-c id="notifyOnLinkClickedConfiguration"
								 [(model)]="configurationSettings.notify_on_link_clicked"
								 [hideText]="true"
								 (change)="updateConfigurationTypeStatus('notify_on_link_clicked')"></toggle-switch-c>
				<label for="notifyOnLinkClickedConfiguration" style="margin: 0;">Enable</label>
			</div>
		</div>
	</div>

	<!--Enable smart banner for mailbox-->
	<div class="list-group-item settings-list-item py-2">
		<div class="title-section-container flex-column gap-2">
			<b>Auto-Enable Smart Banner</b>
			<span class="lh-base" style="font-size: 0.9em;">For users who fail the campaign and click on the tracked links, Smart Banners will be applied to all of their incoming emails for a set duration of time.</span>
		</div>
		<div class="content-container flex-column gap-2">
			<div class="flxRow-start gap-2" style="align-items: center;">
				<toggle-switch-c id="smartBannerClickConfiguration"
								 [(model)]="configurationSettings.enable_security_awareness_for_mailbox"
								 [hideText]="true"
								 (change)="updateConfigurationTypeStatus('enable_security_awareness_for_mailbox')"></toggle-switch-c>

				<div class="flxRow hSpace2" style="align-items: center; height: 16px;">
					<span>Enable for a duration of</span>
					<div [ngClass]="{'disabled-overlay' : !configurationSettings.enable_security_awareness_for_mailbox}">
						<input style="text-align: center;" type="number" min="1" max="365" step="1" pattern="^\d*(\.\d{0,3})?$"
							   [ngClass]="{
									'validation-error' : (errMessage && configurationSettings.enable_security_awareness_for_mailbox) && !configurationSettings.security_awareness_for_mailbox_expire_days || configurationSettings.security_awareness_for_mailbox_expire_days < 1 || configurationSettings.security_awareness_for_mailbox_expire_days > 365}"
							   [(ngModel)]="configurationSettings.security_awareness_for_mailbox_expire_days"
							   (change)="updateConfigurationTypeStatus('security_awareness_for_mailbox_expire_days')">
					</div>
					<span>days</span>
				</div>
			</div>
		</div>
	</div>

	<!--Notify me when campaign is over-->
	<div class="list-group-item settings-list-item py-2">
		<div class="title-section-container flex-column gap-2">
			<b>Send Campaign Summary</b>
			<span class="lh-base" style="font-size: 0.9em;">Receive a an email with a summary of the campaign results automatically when the campaign duration has ended.</span>
		</div>
		<div class="content-container flex-column gap-2">
			<div class="flxRow-start gap-2" style="align-items: center;">
				<toggle-switch-c id="notifyOnCampaignEndConfiguration"
								 [(model)]="configurationSettings.send_report_when_completed"
								 [hideText]="true"
								 (change)="updateConfigurationTypeStatus('send_report_when_completed')"></toggle-switch-c>
				<label for="notifyOnCampaignEndConfiguration" style="margin: 0;">Enable</label>
			</div>
		</div>
	</div>

	<!--Training-->
	<div class="list-group-item settings-list-item py-2">
		<div class="title-section-container flex-column gap-2">
			<b>Distribute Training Materials</b>
			<span class="lh-base" style="font-size: 0.9em;">Following the completion of the campaign, automatically distribute the training page link to individuals who have not yet opened it.</span>
		</div>
		<div class="content-container flex-column gap-2">
			<div class="flxRow-start gap-2" style="align-items: center;">
				<toggle-switch-c id="send_training_when_completed_Configuration"
								 [(model)]="configurationSettings.send_training_when_completed"
								 [hideText]="true"
								 (change)="updateConfigurationTypeStatus('send_training_when_completed')"></toggle-switch-c>
				<label for="send_training_when_completed_Configuration" style="margin: 0;">Enable</label>
			</div>
		</div>
	</div>
</div>
