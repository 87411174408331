<popup-c head="Sensitivity Details"
       (closeCb)="closeCb()"
       (confirmCb)="checkSensitivity()"
       [buttons]="{confirm: {text: 'Rescan Sensitivity', loading: checkSensitivityInProcess, hide: !scanItemObj}}"
       size="medium">
    <div class="flxClmn f-1-0 vSpace2 bs-enabled" style="overflow: auto; min-height: 18rem;">
		<div class="flex-row gap-1" style="max-width: 340px;">
			<div class="value ellipsis" title="{{scanTitle}}">{{scanTitle}}</div>
		</div>
		<div *ngIf="sensitivity && !sensitivity.sensitive_types?.length">
			<div class="flex-row align-items-center mt-2">
				<div class="flex-row p-1 rounded-1 align-items-center gap-1" style="border: 1px solid; margin-left: auto;"
					 [ngStyle]="sensitivity.score === 0 && {'background-color':'#32cd324d', 'border-color':'limegreen'} || sensitivity.score < 4 && {'background-color':'#ff8c004d', 'border-color':'darkorange'} || sensitivity.score < 5 && {'background-color':'#FF851B4d', 'border-color':'#FF851B'} || sensitivity.score === 5 && {'background-color':'#FF41364d', 'border-color':'#FF4136' }">
					<div class="header">Sensitivity Score:</div>
					<input type="button" value="{{sensitivity.score}}"
						   [ngStyle]="sensitivity.score === 0 && {'background':'limegreen'} || sensitivity.score < 4 && {'background':'darkorange'} || sensitivity.score < 5 && {'background':'#FF851B'} || sensitivity.score === 5 && {'background':'#FF4136'}"
						   class="redborder fa" style="cursor: unset; width: 25px; margin: 0; padding-top: 4px; padding-bottom: 4px; border: 0; color: white !important;"
						   title="Reflects how sensitive the content found in the email is on a scale of 0 (not sensitive) to 5 (extremely sensitive)" title-direction="right">
				</div>
			</div>
			<div class="flex-column" *ngIf="sensitivity.score">
				<div class="flex-column gap-4 hspace">
					<div class="flex-row gap-1" *ngIf="sensitivity.sensitive_sub_type && (!sensitivity.sensitive_sub_types || !sensitivity.sensitive_sub_types.length)">
						<div class="header" style="flex-shrink: 0; flex-basis: 112px;"><b>Sub Type:</b></div>
						<div class="value">
                        <span class="p-1 rounded-1 bg-dark bg-opacity-10">
                            {{sensitivity.sensitive_sub_type}}
                        </span>
						</div>
					</div>
					<div class="flex-row gap-1" *ngIf="sensitivity.sensitive_sub_types?.length">
						<div class="header" style="flex-shrink: 0; flex-basis: 112px;"><b>Sub Types:</b></div>
						<div class="value flex-row gap-2 justify-content-start flex-wrap">
                        <span class="p-1 rounded-1 bg-dark bg-opacity-10"
							  *ngFor="let sensitive_sub_type of sensitivity.sensitive_sub_types">
                            {{sensitive_sub_type}}
                        </span>
						</div>
					</div>
					<div class="flex-row gap-1" *ngIf="sensitivity.compliance?.length">
						<div class="header" style="flex-shrink: 0; flex-basis: 112px;"><b>Compliance:</b></div>
						<div class="value flex-row gap-2 justify-content-start flex-wrap">
                        <span class="p-1 rounded-1 bg-dark bg-opacity-10"
							  *ngFor="let compliance of sensitivity.compliance" >
                            {{compliance}}
                        </span>
						</div>
					</div>
					<div class="flex-row no-height gap-1" *ngIf="sensitivity.sensitive_found">
						<div class="header" style="flex-shrink: 0; flex-basis: 112px;"><b>Sensitive Content:</b></div>
						<div class="value px-2 py-1 border rounded-1">
                        <span style="word-break: break-word;">
                            {{sensitivity.sensitive_found}}
							<i title="Copy" class="fas fa-copy copy-link ms-2" style="cursor: pointer; color: #999999;" (click)="copyToClipboard(sensitivity.sensitive_found)"></i>
                        </span>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div *ngIf="sensitivity && sensitivity.sensitive_types?.length" class="flex-column gap-2">
			<trustifi-table-c
					class="trustifi-table" style="flex: 1 0; z-index: 5; min-height: 300px;"
					[list]="sensitivity.sensitive_types"
					[headers]="[
					{text: 'Type', sortKey: 'sensitive_type'},
					{text: 'Score', sortKey: 'score', width: '70px'},
					{text: 'Content'},
					{text: 'Compliance'},
				]"
				[cellsPrototype]="[
					{textKey: 'sensitive_type'},
					{textKey: 'score'},
					{html: 'contentCell'},
					{html: 'complianceCell'},
				]"
				[options]="{
					isEllipsis: false,
					itemsNameSingular: 'type',
				}"
				>
					<ng-template #customCells let-item let-cellId="cellId">
						<div *ngIf="cellId === 'contentCell'">
							<div class="px-2 py-1 border rounded-1 flex-row gap-2">
								<span class="f-1-0 break-word ellipsis">
									{{item.content}}
								</span>
								<i tooltip="Copy" class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;" (click)="clipboard.copy(item.content);"></i>
							</div>
						</div>

						<div *ngIf="cellId === 'complianceCell'">
							<div class="value flex-row gap-2 justify-content-start flex-wrap">
						<span class="p-1 rounded-1 bg-dark bg-opacity-10"
							  *ngFor="let compliance of item.compliance" >
							{{compliance}}
						</span>
							</div>
						</div>
					</ng-template>

					<div tableButtons>
						<div class="flex-row p-1 rounded-1 align-items-center gap-1" style="border: 1px solid; width: 150px;"
							 [ngStyle]="sensitivity.score === 0 && {'background-color':'#32cd324d', 'border-color':'limegreen'} || sensitivity.score < 4 && {'background-color':'#ff8c004d', 'border-color':'darkorange'} || sensitivity.score < 5 && {'background-color':'#FF851B4d', 'border-color':'#FF851B'} || sensitivity.score === 5 && {'background-color':'#FF41364d', 'border-color':'#FF4136' }">
							<div class="header">Sensitivity Score:</div>
							<input type="button" value="{{sensitivity.score}}"
								   [ngStyle]="sensitivity.score === 0 && {'background':'limegreen'} || sensitivity.score < 4 && {'background':'darkorange'} || sensitivity.score < 5 && {'background':'#FF851B'} || sensitivity.score === 5 && {'background':'#FF4136'}"
								   class="redborder fa" style="cursor: unset; width: 25px; margin: 0; padding-top: 4px; padding-bottom: 4px; border: 0; color: white !important;"
								   tooltip="Reflects how sensitive the content found in the email is on a scale of 0 (not sensitive) to 5 (extremely sensitive)" placement="right">
						</div>
					</div>
				</trustifi-table-c>
		</div>

        <div *ngIf="scanItemObj">
            <a (click)="openReportWrongSensitivityPopup()">Seems wrong? Click here to report</a>
        </div>
    </div>
</popup-c>


<popup-c *ngIf="reportWrongSensitivityPopup"
       head="Report Sensitivity Details"
       (closeCb)="closeReportWrongSensitivityPopup()"
       (confirmCb)="reportSensitivity()"
       [buttons]="{confirm: {text: 'Report', loading: reportWrongSensitivityInProcess}}"
       size="dynamic">
    <div class="flxClmn f-1-0 vSpace2 bs-enabled" style="overflow: auto;">
        <div class="flex-row align-items-center mt-2">
            If you believe the sensitivity score or type is incorrect, you can let us know below.
        </div>

        <div>
            <textarea style="width: 90%; height: 150px; margin-left: 5px;"
                      placeholder="Specify the result you received and what you believe the score/type should be"
                      [(ngModel)]="reportWrongSensitivityPopup.reportText">
            </textarea>
        </div>
    </div>
</popup-c>


