<div class="flxClmn bs-enabled">
	<div class="page-title-container flxClmn-center">
		<div class="page-title-inner-container flxRow hSpace1">
			<span class="page-title">Training Materials</span>
		</div>
		<div class="page-subtitle-container">
			<span class="page-subtitle">
				Store and manage training materials focused on security awareness. These materials can be utilized as landing pages in your Threat Simulation campaigns.
			</span>
		</div>
	</div>

	<!--loader-->
	<div class="f-1-0 flex-column centralize " *ngIf="_.isUndefined(isThisFeatureSupported)">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div class="page-inner-content-holder no-scroll flxClmn vSpace2" *ngIf="isThisFeatureSupported">

		<!--main content area-->
		<div class="flxRow animated fadeIn" style="flex: 1 0; border-top: solid 1px #e3e4e4; padding-top: 10px; overflow: auto;">
			<!--list of materials (left side)-->
			<div class="flxClmn" style="flex: 1.8 0; margin: 0 7px; padding: 5px; min-width: 450px; min-height: 350px; background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent);">
				<div class="flxClmn" style="flex: 1 0; overflow: auto;">
					<div class="w100 flxRow-between" style="background: #677184; padding: calc(3px + 0.4vw); align-items: center; z-index: 1; min-height: 42px;">
						<btn-c [isDisabled]="getMaterialsInProcess"
							   (action)="openNewMaterialPopup();"
							   style="background: white; width: 140px;"
							   variant="outlined"
							   endIcon="fa fa-lg fa-file-medical ms-2">
							Add Materials
						</btn-c>
						<div class="flxRow hSpace1" style="align-items: center;">
							<span *ngIf="_.reject(materials, 'hide').length" style="color: white; padding: 5px;">
                                {{_.reject(materials, 'hide').length}} record{{_.reject(materials, 'hide').length === 1 ? '' : 's'}}
                            </span>
							<!--Search-->
							<search-box [(model)]="searchMaterialTxt"
										(modelChange)="materialsListEl.searchItem();"
										placeholder="Search material"
										style="width: 30%; min-width: 200px;">
							</search-box>
						</div>
					</div>

					<trustifi-table-c
						#materialsListEl
						class="trustifi-table" style="flex: 1 0; z-index: 5; background: white;"
						[list]="materials"
						[headers]="[
							{text: '', width: '25px'},
							{text: 'Name', sortKey: 'name'},
							{text: 'Description', width: '95px', centered: true},
						]"
						[cellsPrototype]="[
							{html: 'iconCell'},
							{html: 'nameCell'},
							{html: 'descriptionCell'},
						]"
						[bulkActions] = "{
							enable: true,
							selectBulkActionCb:	selectMultipleMaterialAction,
							initBulkActionsFunction: showBulkMaterialsActions,
							showInRed: {enable: true, terms: ['Delete']}
						}"
						[cellActions]="{
							enable: true,
							selectItemActionCb:	selectMaterialAction,
							initActionsFunction: showMaterialActions,
							showInRed: {enable: true, terms: ['Delete']}
						}"
						(onAnyItemSelected)="!$event.isItemSelectCheckboxClicked && setCurrentMaterial($event.item)"
						(searchFunction)="searchMaterial($event.searchTerm, $event.activeFilters)"
						[options]="{
							loadingTableFlag: getMaterialsInProcess,
							soloSelected: true,
							hideContentAboveTable: true,
							itemsNameSingular: 'material',
							defaultSortKey: '-created'
						}"
					>
						<ng-template #customCells let-item let-cellId="cellId">
							<span *ngIf="cellId === 'iconCell'">
								<i [ngClass]="item.link_url ? 'fa fa-link' : 'far fa-file-pdf text-danger'"></i>
							</span>

							<div *ngIf="cellId === 'nameCell'"
								 class="flex-row gap-1 align-items-end">
								<span>{{item.name}}</span>
								<span class="text-muted" style="font-size: 11px;" *ngIf="item.system">(System)</span>
							</div>

							<div class="d-flex centralize" *ngIf="cellId === 'descriptionCell'">
								<i class="fa fa-info-circle text-info opacity-75" style="font-size: 16px;"
								   *ngIf="item.description" [tooltip]="item.description">
								</i>
							</div>
						</ng-template>
					</trustifi-table-c>
				</div>
			</div>

			<!--Email display area (right side)-->
			<div class="vertical-separator" style="border-color: #e3e4e4; border-left: none;"></div>
			<div class="flxClmn" style="flex: 2 0; margin-left: 20px; position: relative; overflow: auto; align-items: center;"
				 [ngStyle]="{'background': currentMaterial ? 'transparent' : '#f7f7f7'} ">
				<!--loading overlay-->
				<div class="flxClmn-center animated fadeIn" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 5; background: rgba(255,255,255,0.6); align-items: center;"
					 *ngIf="getMaterialFileDataInProcess">
					<div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 1;"></div>
				</div>
				<!----->
				<div class="flex-column gap-2 f-1-0 w-100 animated fadeIn" *ngIf="currentMaterial">
					<div class="flex-column border border-secondary-subtle p-4 gap-3">
						<ng-container *ngIf="currentMaterial.type === 'link'">
							<ng-container *ngTemplateOutlet="currentMaterialField; context: {$implicit: 'name'}"></ng-container>
						</ng-container>
						<ng-container *ngIf="currentMaterial.type === 'link'">
							<ng-container *ngTemplateOutlet="currentMaterialField; context: {$implicit: 'link_url'}"></ng-container>
						</ng-container>
						<ng-container *ngTemplateOutlet="currentMaterialField; context: {$implicit: 'description'}"></ng-container>


						<ng-template #currentMaterialField let-fieldName>
							<div class="flex-row gap-2 break-word" style="font-size: 14px; min-height: 30px;">
								<span class="flex-shrink-0" style="flex-basis: 75px;">{{fieldName === 'link_url' ? 'URL' : _.capitalize(fieldName)}}:</span>
								<span class="fw-500 animated fadeIn" *ngIf="!currentMaterial.edit || _.isUndefined(currentMaterial.edit[fieldName])">
									{{currentMaterial[fieldName] || '-'}}
									<i class="fa fa-edit cursor-pointer hvr-opacity opacity-75 ms-3" style="font-size: 13px;"
									   *ngIf="!currentMaterial.system"
									   tooltip="Edit {{fieldName === 'link_url' ? 'link URL' : fieldName}}"
									   (click)="startEditCurrentMaterial(fieldName);"></i>
								</span>
								<ng-container *ngIf="currentMaterial.edit && !_.isUndefined(currentMaterial.edit[fieldName])">
									<input class="f-1-0" style="margin-top: -7px;" type="text"
										   (keyup.enter)="confirmEditCurrentMaterial(fieldName);"
										   [(ngModel)]="currentMaterial.edit[fieldName]" />
									<btn-c style="min-width: 0; margin-top: -7px; align-self: baseline;"
										   endIcon="zmdi zmdi-check"
										   [isDisabled]="editMaterialInProcess || (!currentMaterial.edit[fieldName] && fieldName !== 'description')"
										   (action)="confirmEditCurrentMaterial(fieldName);">
									</btn-c>
									<btn-c style="min-width: 0; margin-top: -7px; align-self: baseline;"
										   endIcon="zmdi zmdi-close" color="danger"
										   variant="text"
										   (action)="currentMaterial.edit[fieldName] = undefined;">
									</btn-c>
								</ng-container>
							</div>
						</ng-template>
					</div>

					<div class="flxClmn-center w100 f-1-0 overflow-auto">
						<attachment-preview
							*ngIf="currentMaterial.content"
							[attachment]="currentMaterial"
							[isHeadless]="true">
						</attachment-preview>

						<iframe
							*ngIf="currentMaterial.link_url"
							[src]="previewLinkMaterial"
							width="100%"
							height="500"
							frameborder="0"
							allowfullscreen>
						</iframe>
					</div>
				</div>


				<div *ngIf="!currentMaterial && !getMaterialFileDataInProcess" class="flxClmn-center"
					 style="align-items: center; flex: 1 0;">
					<i class="fas hvr-opacity fa-file" style="font-size: 4vw;  opacity: 0.1;"></i>
				</div>
			</div>
		</div>
	</div>

	<!--feature not supported-->
	<threat-simulation-not-supported style="display: contents;" *ngIf="isThisFeatureSupported === false"></threat-simulation-not-supported>
	<!---->
</div>

<popup-c class="bs-enabled" *ngIf="newMaterialPopup?.show"
		 head="Add Training Material" size="medium"
		 (closeCb)="newMaterialPopup = null;"
		 (confirmCb)="confirmNewMaterialPopup();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: newMaterialPopup.addMaterialsInProcess}}">
	<div class="flex-column f-1-0 gap-5">
		<span>Add a PDF file, or a link to a video/website:</span>
		<div class="flex-row gap-2" style="min-height: 150px;">
			<label class="radio-container m-0 mt-1 d-block" style="flex-basis: 90px;">
				PDF file:
				<input type="radio"
					   [(ngModel)]="newMaterialPopup.type"
					   (ngModelChange)="newMaterialPopup.error = false;"
					   [value]="'file'">
				<span class="checkmark"></span>
			</label>
			<div class="d-flex f-1-0 centralize bg-light"
				 ngfDrop
				 ngfSelect
				 [(file)]="newMaterialPopup.uploadFile"
				 [fileDropDisabled]="newMaterialPopup.type !== 'file'"
				 (fileChange)="newMaterialPopup.error = false;"
				 [(validDrag)]="validDrag"
				 [(invalidDrag)]="invalidDrag"
				 [ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag,
					 			 'validation-error': newMaterialPopup.error && newMaterialPopup.type === 'file' && !newMaterialPopup.uploadFile,
					 			 'border border-2 border-secondary-subtle': !newMaterialPopup.error || newMaterialPopup.type !== 'file' || newMaterialPopup.uploadFile,
					 			 'disabled-overlay': newMaterialPopup.type !== 'file', 'cursor-pointer hvr-opacity': newMaterialPopup.type === 'file'}"
				 accept='application/pdf'>
				<div class="flex-column gap-2 text-center" style="font-size: 14px;" *ngIf="newMaterialPopup.uploadFile">
					<span class="fw-500 ellipsis">{{newMaterialPopup.uploadFile.name}}</span>
				</div>
				<span class="fw-500 text-muted" style="font-size: 14px;" *ngIf="!newMaterialPopup.uploadFile">
					Click here to upload or drag & drop
				</span>
			</div>
		</div>

		<div class="flex-row gap-2">
			<label class="radio-container m-0 mt-2 d-block" style="flex-basis: 90px;">
				Link:
				<input type="radio"
					   [(ngModel)]="newMaterialPopup.type"
					   [value]="'link'">
				<span class="checkmark"></span>
			</label>
			<div class="flex-column f-1-0 gap-2" [ngClass]="{'disabled-overlay' : newMaterialPopup.type !== 'link'}">
				<div class="flex-row gap-2 align-items-center">
					<span class="flex-shrink-0" style="flex-basis: 130px;">Name:</span>
					<input type="text" class="f-1-0" [(ngModel)]="newMaterialPopup.link.name"
						   (ngModelChange)="newMaterialPopup.error = false;"
						   [ngClass]="{'validation-error' : newMaterialPopup.error && newMaterialPopup.type === 'link' && (!newMaterialPopup.link.name || newMaterialPopup.link.url)}"/>
				</div>
				<div class="flex-row gap-2 align-items-center">
					<span class="flex-shrink-0" style="flex-basis: 130px;">URL:</span>
					<input type="text" class="f-1-0" [(ngModel)]="newMaterialPopup.link.url"
						   (ngModelChange)="newMaterialPopup.error = false;"
						   [ngClass]="{'validation-error' : newMaterialPopup.error && newMaterialPopup.type === 'link' && newMaterialPopup.link.name && !newMaterialPopup.link.url}"/>
				</div>
				<div class="flex-row gap-2 align-items-center">
					<span class="flex-shrink-0" style="flex-basis: 130px;">Description <span style="font-weight: 300;">(optional)</span>:</span>
					<input type="text" class="f-1-0" [(ngModel)]="newMaterialPopup.link.description"
						   [readonly]="newMaterialPopup.type !== 'link'"/>
				</div>
			</div>
		</div>
	</div>
</popup-c>
