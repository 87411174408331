import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as _ from 'lodash';
import moment from 'moment';

@Pipe({name: 'SafeHtml'})
export class SafeHtml implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(style) {
		return this.sanitizer.bypassSecurityTrustHtml(style);
	}
}

@Pipe({name: 'FormatBytes'})
export class FormatBytes implements PipeTransform {
	transform(bytes, decimals) {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
}

@Pipe({name: 'camelCase'})
export class CamelCasePipe implements PipeTransform {
	transform(value: string): string {
		if (!value) return '';

		return value.replace(/\s(.)/g, function(match) {
			return match.toUpperCase();
		}).replace(/\s/g, '').replace(/^(.)/, function(match) {
			return match.toLowerCase();
		});
	}
}

@Pipe({
	name: 'camelCaseToTitleCase'
})
export class CamelCaseToTitleCasePipe implements PipeTransform {

	transform(value: string): string {
		if (!value) return '';

		return value ? _.startCase(value) : '';
	}

}

@Pipe({
	name: 'orderBy',
	pure: false
})
export class OrderArrayBy implements PipeTransform {
	transform(array, propertyName : string | string[]) {
		// sanity
		if (!Array.isArray(array) || !array.length) {
			return array;
		}

		let sortKeys = propertyName;
		if (typeof propertyName === 'string') {
			sortKeys = [propertyName];
		}
		sortKeys = _.without(sortKeys, undefined, null ,'');

		// create ascending/descending array for lodash orderBy
		// Syntax: _.orderBy(array, ['sortKey1', 'sortKey2'], ['asc', 'desc'])
		const ascOrDesArr = [];
		(sortKeys as string[]).forEach((key, index, arr) => {
			if (key && key.startsWith('-')) {
				arr[index] = key.substring(1);
				ascOrDesArr.push('desc');
			}
			else {
				ascOrDesArr.push('asc');
			}
		})

		// sort
		return _.orderBy(array, sortKeys, ascOrDesArr);
	}
}

// filter items array (for *ngFor), items which match and return true will be kept, false will be filtered out
@Pipe({
	name: 'filter',
	pure: false
})
export class NgForFilterPipe implements PipeTransform {
	transform(items: any[], filterObj: object): any {
		if (!items || !filterObj || _.isEmpty(filterObj)) {
			return items;
		}

		const filteredList = items.filter(item => {
			let isPassed = true;
			_.forEach(filterObj, (value:any, key) => {
				if (value.startsWith('!')) { // negative - filter out
					value = value.substring(1);

					if (value === 'true') { value = true; }
					if (value === 'false') { value = false; }
					if (item[key] === value) {
						isPassed = false;
					}
				}
				else { // positive - filter in
					if (item[key] !== value) {
						isPassed = false;
					}
				}
			});
			return isPassed;
		});

		return filteredList;
	}
}
@Pipe({
	name: 'amDateFormat'
})
export class AmDateFormatPipe implements PipeTransform {

	transform(value: any, format: string = 'MMMM Do YYYY, h:mm:ss a'): string {
		if (!value) {
			return '';
		}
		return moment(value).format(format);
	}

}

@Pipe({
	name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {
	transform(value: any[], limit: number): any[] {
		if (!Array.isArray(value)) {
			return value;
		}
		return value.slice(0, limit);
	}
}

@Pipe({
	name: 'unique'
})
export class UniquePipe implements PipeTransform {

	transform(value: any[], propertyName: string): any[] {
		if (!Array.isArray(value) || !propertyName) {
			return value;
		}

		const uniqueItems = new Map<any, any>();
		value.forEach(item => {
			const keyValue = item[propertyName];
			if (!uniqueItems.has(keyValue)) {
				uniqueItems.set(keyValue, item);
			}
		});

		return Array.from(uniqueItems.values());
	}
}

@Pipe({
	name: 'Titleize'
})
export class TitleizePipe implements PipeTransform {
	transform(value: string): string {
		if (!value) {
			return value;
		}
		return value.replace(/\w\S*/g, (txt) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}
}

@Pipe({ name: 'sanitizeText' })
export class SanitizeTextPipe implements PipeTransform {
	transform(value: string): string {
		const div = document.createElement('div');
		div.textContent = value;
		return div.innerHTML;
	}
}
