<div class="flex-column gap-4">
    <span>Upload a file or enter a link below to safely scan it and check for any existing threats.</span>
    <div class="list-group list-group-flush settings-list-container">
        <div class="list-group-item settings-list-item">
            <div class="title-section-container flex-column gap-2" style="flex: 0 0; flex-basis: 250px;">
                <b>Scan a link</b>
            </div>
            <div class="content-container flex-column gap-3">
                <div class="flex-row align-items-center gap-2">
                    <input type="text" style="border:1px solid #ccc; flex-basis: 400px;"
						   [(ngModel)]="linkToScan"
                           placeholder="Enter link here"
						   (keyup.enter)="scanLinksExecute(); scanLinkErr = false;"
                           [ngClass]="{'validation-error' : scanLinkErr, 'disabled' : scanLinksInProcess}" />
                    <btn-c style="width: 120px;"
                         [loading]="{flag: scanLinksInProcess, text: 'Scanning'}"
						 [isDisabled]="!linkToScan || scanLinksInProcess"
                         (action)="scanLinksExecute();">
                        Scan Link
                    </btn-c>
                </div>

                <div class="flex-column gap-3 w-100 border p-4" style="word-break: break-word; max-width: 1200px; height: 30vh; font-size: 1rem; max-height: 200px; overflow: auto; opacity: 0; transition: all 0.15s;"
                     [ngStyle]="{'opacity' : scanLinkResults ? '1' : '0'}">
                    <div class="flex-row gap-2 animated fadeIn" *ngIf="scanLinkResults">
                        <b class="text-capitalize text-nowrap">Link:</b>
                        <span>{{scanLinkResults.url}}</span>
                    </div>
                    <div class="flex-column gap-3 animated fadeIn" *ngIf="scanLinkResults">
                        <div class="flex-row gap-1 align-items-center" [ngClass]="{'text-danger' : scanLinkResults.status === 'Malicious'}">
                            <b style="color: initial;">Status:</b>
                            <div>
                                <i *ngIf="scanLinkResults?.status === 'Malicious'" class="fas fa-exclamation-triangle text-danger" style="font-size: 1rem;"></i>
                                <i *ngIf="scanLinkResults?.status === 'Safe'" class="fas fa-check text-success" style="font-size: 1rem;"></i>
                            </div>
                            <span>{{scanLinkResults.status}}</span>
                            <span *ngIf="scanLinkResults?.status === 'Malicious'">({{scanLinkResults.category}})</span>
                        </div>
                        <span *ngIf="scanLinkResults?.status === 'Malicious'">{{scanLinkResults.list[0].sub_status[0].message}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="list-group-item settings-list-item border-bottom-0">
            <div class="title-section-container flex-column gap-2" style="flex: 0 0; flex-basis: 250px;">
                <b>Scan a file</b>
            </div>
            <div class="content-container flex-column gap-3">
                <div class="flex-row align-items-start gap-2">
                    <input type="button" class="flex-column centralize" style="height: 75px; flex-basis: 400px; background: white; border: dashed 1px #cccccc;"
                           [value]="testManualScanFile?.name && testManualScanFile?.name !== scanFileResults?.name ? testManualScanFile?.name : 'Drag and drop here or click to select file'"
                           [ngStyle]="testManualScanFile?.name && testManualScanFile?.name !== scanFileResults?.name ?  {'color' : 'black' , 'font-weight' : 'normal' , 'font-size' : ''}: {'color' : 'gray' , 'font-weight' : '500' , 'font-size' : '1rem'}"
						   [(file)]="testManualScanFile"
						   ngfDrop
                           ngfSelect
						   [(validDrag)]="validDrag"
						   [(invalidDrag)]="invalidDrag"
						   [ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag}"
						   />
                    <btn-c style="width: 120px;"
                         [loading]="{flag: scanFileInProcess, text: 'Scanning'}"
						 [isDisabled]="!testManualScanFile || scanFileInProcess"
                         (action)="scanFilesExecute();">
                        Scan File
                    </btn-c>
                </div>

                <div class="flex-column gap-3 w-100 border p-4" style="word-break: break-word; max-width: 1200px; height: 30vh; font-size: 1rem; max-height: 200px; overflow: auto; opacity: 0; transition: all 0.15s;"
                     [ngStyle]="{'opacity' : scanFileResults ? '1' : '0'}">
                    <div class="flex-row gap-2 animated fadeIn" *ngIf="scanFileResults">
                        <b class="text-capitalize text-nowrap">File:</b>
                        <span>{{scanFileResults.name}}</span>
                    </div>
                    <div class="flex-column gap-3 animated fadeIn" *ngIf="scanFileResults">
                        <div class="flex-row gap-1 align-items-center" [ngClass]="{'text-danger' : scanFileResults.status === 'Malicious'}">
                            <b style="color: initial;">Status:</b>
                            <div>
                                <i *ngIf="scanFileResults?.status === 'Malicious'" class="fas fa-exclamation-triangle text-danger" style="font-size: 1rem;"></i>
                                <i *ngIf="scanFileResults?.status === 'Safe'" class="fas fa-check text-success" style="font-size: 1rem;"></i>
                            </div>
                            <span>{{scanFileResults?.status}}</span>
                            <span *ngIf="scanFileResults?.status === 'Malicious'">({{scanFileResults.category}})</span>
                        </div>
                        <span *ngIf="scanFileResults?.status === 'Malicious'">{{scanFileResults.list[0].sub_status[0].message}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
