import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import {DICTIONARY} from "../../../../../dictionary";
import {Component, OnInit} from "@angular/core";
import _ from "lodash";
import * as util from 'util';


@Component({
	selector: 'security-awareness-component',
	templateUrl: './security-awareness.component.html',
})
export class SecurityAwarenessComponent implements OnInit {
    constructor(private rs:RouteService,
				private ns:NotificationService) {
    }
    dic = DICTIONARY;
    planInfo;
	smartBannerStatus;
	mailboxes = [];
	mailboxesOriginal;
	getSecurityAwarenessDataInProcess;
	updateSecurityAwarenessInProcess;
	_ = _;

	ngOnInit() {
		this.getSecurityAwarenessDataInProcess = true;
		Promise.all([this.getSecurityAwarenessInfo(), this.getMailboxesForSecurityAwareness()])
			.then(results => {
				this.getSecurityAwarenessDataInProcess = false;
			})
			.catch(error => {
				this.getSecurityAwarenessDataInProcess = false;
			});
    };

	getSecurityAwarenessInfo = () => {
		return new Promise(resolve => {
			this.rs.getSecurityAwarenessInfo().then(response => {
				this.planInfo = response;
				this.smartBannerStatus = this.planInfo?.security_awareness?.smart_banner?.enable_status || null;
				resolve({});
			}, (err) => {});
			resolve({});
		});
	}

	getMailboxesForSecurityAwareness = () => {
		return new Promise(resolve => {
			this.rs.getMailboxesForSecurityAwareness().then(response => {
				response = response || [];

				this.mailboxes = [];
				response.forEach(mailbox => {
					this.mailboxes.push({
						email: mailbox.email,
						selected: mailbox.security_awareness?.enabled || false
					});
				});
				this.mailboxesOriginal = _.cloneDeep(this.mailboxes);
				resolve({});
			}, (err) => {
				resolve({});
			});
		});

	}

    updateSecurityAwarenessEnable = () => {
		if (this.updateSecurityAwarenessInProcess) {
			return;
		}

		const actionInfo: any = {
			action: this.dic.CONSTANTS.securityAwarenessActions.status,
			enable_status: this.smartBannerStatus
		};

		if (this.smartBannerStatus === this.dic.CONSTANTS.securityAwarenessStatus.specific) {
			const selectedMailboxes = _.filter(this.mailboxes, 'selected');

			if (!selectedMailboxes.length) {
				this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.selectAtLeastOneX, 'mailbox'));
				return;
			}

			actionInfo.mailboxes = _.map(selectedMailboxes, 'email');
		}

		this.updateSecurityAwarenessInProcess = true;

        this.rs.updateSecurityAwarenessInfo(actionInfo).then(response => {
			this.planInfo.security_awareness.smart_banner.enable_status = this.smartBannerStatus;
            this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
			this.updateSecurityAwarenessInProcess = false;

			if (this.smartBannerStatus === this.dic.CONSTANTS.securityAwarenessStatus.specific) {
				this.mailboxesOriginal = _.cloneDeep(this.mailboxes);
			}
        }, err => {
			this.updateSecurityAwarenessInProcess = false;
		});
    };

    updateSecurityAwarenessAllowReport = () => {

        const actionInfo = {
            action: this.dic.CONSTANTS.securityAwarenessActions.allowReport,
            enabled: this.planInfo.security_awareness.smart_banner.allow_reporting
        };

        if (actionInfo.enabled && !this.planInfo.outbound_relay.enabled) {
            this.planInfo.security_awareness.smart_banner.allow_reporting = false;
            this.ns.showErrorMessage(this.dic.ERRORS.allowReportNoOutboundER);
            return;
        }

        this.rs.updateSecurityAwarenessInfo(actionInfo).then(response => {
            this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
        });
    };

    updateSecurityAwarenessExternal = () => {
        const actionInfo = {
            action: this.dic.CONSTANTS.securityAwarenessActions.addExternalNote,
            enabled: this.planInfo.security_awareness.smart_banner.add_external_note
        };
        this.rs.updateSecurityAwarenessInfo(actionInfo).then(response => {
            this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
        });
    };

    updateSecurityAwarenessSkipInternal = () => {
        const actionInfo = {
            action: this.dic.CONSTANTS.securityAwarenessActions.skipInternal,
            enabled: this.planInfo.security_awareness.smart_banner.skip_internal
        };
        this.rs.updateSecurityAwarenessInfo(actionInfo).then(response => {
            this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
        });
    };

    updateSecurityAwarenessSkipContact = () => {
        const actionInfo = {
            action: this.dic.CONSTANTS.securityAwarenessActions.skipContact,
            enabled: this.planInfo.security_awareness.smart_banner.skip_contact
        };
        this.rs.updateSecurityAwarenessInfo(actionInfo).then(response => {
            this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
        });
    };

    updateSecurityAwarenessPositiveFeedback = () => {
        const actionInfo = {
            action: this.dic.CONSTANTS.securityAwarenessActions.positiveFeedback,
            enabled: this.planInfo.security_awareness.smart_banner.add_positive_feedback
        };
        this.rs.updateSecurityAwarenessInfo(actionInfo).then(response => {
            this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
        });
    };

}
