import * as util from 'util';
import html2pdf from 'html2pdf.js';
import _ from "lodash";
import {RouteService} from "../../../../services/routeService";
import {InboundTrendsService} from "../../../../services/inboundTrendsService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, NgZone, OnInit, ViewChild} from "@angular/core";
import {ChartComponent} from "ng-apexcharts";
import {Router} from "@angular/router";
import {LookAndFeelService} from "../../../../services/lookAndFeelService";

@Component({
	selector: 'inbound-trends-component',
	templateUrl: './inbound-trends.component.html',
})
export class InboundTrendsComponent implements OnInit {
    constructor(public gs:GeneralService,
				private rs:RouteService,
				private inboundTrendsService:InboundTrendsService,
				private ns:NotificationService,
				public lfs:LookAndFeelService,
				private ngZone:NgZone,
				private router:Router) {
    }

	@ViewChild('generalTrendsChart', {static: false}) generalTrendsChart: ChartComponent;
	@ViewChild('inboundTrendsChart', {static: false}) inboundTrendsChart: ChartComponent;
	@ViewChild('threatStatusChart', {static: false}) threatStatusChart: ChartComponent;
	@ViewChild('threatDetectionTypeChart', {static: false}) threatDetectionTypeChart: ChartComponent;
	@ViewChild('topAttackedMailboxesChart', {static: false}) topAttackedMailboxesChart: ChartComponent;
	@ViewChild('topSenderDomainsChart', {static: false}) topSenderDomainsChart: ChartComponent;


	dic = DICTIONARY;
	_=_;
    period = this.dic.CONSTANTS.trendsPeriod.last3Days;
    range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)), end: new Date()};
    todayDate = new Date();

    dashboardPeriod = [
        this.dic.CONSTANTS.trendsPeriod.lastDay,
        this.dic.CONSTANTS.trendsPeriod.last3Days,
        this.dic.CONSTANTS.trendsPeriod.lastWeek,
        this.dic.CONSTANTS.trendsPeriod.lastMonth,
        this.dic.CONSTANTS.trendsPeriod.last3Months,
        this.dic.CONSTANTS.trendsPeriod.range
    ];

	userInfo;
	emails: any[];
	totalEmails: number;
	emailsScanned: number;
	private currentQuery: any  = {};
	threatProtectionData;
	batchInProcess;
	abortLoadingFlag;
	showAbortLoadingEmailsLabel;
	trendsData;
	allowBlockData;
    whitelistChartOptions;
    blacklistChartOptions;
    inboundTrendsChartOptions;
    quarantinedChartOptions;
	generalChartOptions;
    detectionTypeChartOptions;
    domainChartOptions;
    getTrendsDataInProcess;
    topMailboxesChartOptions;
    addDomainToBlacklistInProcess;
    blockDomainsPopup;
    addAddressesToAllowlistPopup;
    addAddressToWhitelistInProcess;
    addDomainsToStrictCheckPopup;
    addDomainToStrictCheckDomainsInProcess;
    generatePdfInProcess;
	showTrendsCustomPeriod;
	showInboundTrendsMenu;
	showThreatsStatusMenu;
	showGeneralTips;
	showThreatTypeMenu;
	showGeneralMenu
	showTopSenderMenu;
	showTopSenderTips;
	showTopMailboxesMenu;
	showTopMailboxesTips;
	getDataInProcess;

	scheduledQuarantinedReports;
	showGenerateReportPopup;
	csvFields
	csvFieldsCopy;
	showReportColumnsPopup;
	selectedAllFields;
	reportAddresses = '';
	updateReportAddressesInProcess;

	batchSize = this.gs.isProductionEnv() ? 3000 : 100;

	ngOnInit() {

		this.gs.getUserInfo(false, (userInfo) => {
			this.userInfo = userInfo;
		});

		this.rs.getTPConfig().then((response) => {
			this.threatProtectionData = response.threat_protection;

			this.countWhiteBlacklistItems();

			this.prepareWhitelistPieGraph();
			this.prepareBlackListPieGraph();

		}, (err) => {});

		this.rs.getQuarantinedPlanInfo().then((response) => {
			this.scheduledQuarantinedReports = response.scheduledQuarantinedReports;
		}, () => {});

		this.getTrendsData(false);
    };

	getTrendsData = (isReload) => {
		// @ts-ignore
		this.currentQuery = {period: this.period.value, size: this.batchSize};
		if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
			// @ts-ignore
			this.currentQuery.range = this.range;
		}

		this.emails = [];
		this.totalEmails = 0;
		this.emailsScanned = 0;
		this.getTrendsDataInProcess = true;
		this.batchInProcess = true;
		this.trendsData = null;

		if (isReload) {
			this.getInboundTrendsBatch();
			return;
		}

		this.rs.getInboundTrends(this.currentQuery).then((response) => {
			// first check if data is in cache
			if (response.created) {
				this.trendsData = response;
				this.totalEmails = response.totalEmails;
				this.emailsScanned = response.emailsScanned;
				this.prepareData(false);
				this.initTrendsProcessParams();
				return;
			}

			this.getInboundTrendsBatch();

		}, () => {
			this.initTrendsProcessParams();
		});
	};

	getInboundTrendsBatch = () => {
		this.rs.getInboundTrendsBatch(this.currentQuery).then((response) => {
			this.totalEmails = response.total;
			this.emailsScanned = response.emailsScanned;
			this.emails = response.emails;

			this.getTrendsDataInProcess = false;

			this.showAbortLoadingEmailsLabel = true;
			this.trendsData = this.inboundTrendsService.parseTrendsData(this.userInfo, this.emails, this.currentQuery);

			this.prepareData(false);

			this.getNextEmailsBatch(response.lastCreated, () => {});

		}, (err) => {
			this.initTrendsProcessParams();
		});
	}

	initTrendsProcessParams = () => {
		this.batchInProcess = false;
		this.abortLoadingFlag = false;
		this.getTrendsDataInProcess = false;
		this.showAbortLoadingEmailsLabel = false;
	}

	getNextEmailsBatch = (lastCreated, cb) => {
		if (!lastCreated) {
			this.initTrendsProcessParams();
			return cb();
		}

		if (this.abortLoadingFlag) {
			this.initTrendsProcessParams();
			return cb();
		}

		this.currentQuery.lastCreated = lastCreated;

		this.rs.getInboundTrendsBatch(this.currentQuery).then((response) => {
			if (response.emails?.length) {
				this.emails = this.emails.concat(response.emails);
				this.trendsData = this.inboundTrendsService.parseTrendsData(this.userInfo, this.emails, this.currentQuery);
				this.prepareData(true);
			}

			this.getNextEmailsBatch(response.lastCreated, cb);
		}, (err) => {
			console.error(err);
			this.getNextEmailsBatch(lastCreated, cb);
		});
	}

	abortLoadingEmails = () => {
		this.abortLoadingFlag = true;
		this.showAbortLoadingEmailsLabel = false;
		this.getTrendsDataInProcess = false;
	}


	countWhiteBlacklistItems() {
		const whitelist = {
			address: 0,
			domain: 0,
			ip: 0,
			file: this.threatProtectionData.attachments_whitelist?.length || 0,
			link: this.threatProtectionData.links_whitelist?.length || 0
		};

		this.threatProtectionData.senders_whitelist.forEach(item => {
			if (item.email) {
				if (this.gs.validateIPAddress(item.email)) {
					whitelist.ip++;
				}
				else if (this.gs.isValidDomain(item.email)) {
					whitelist.domain++;
				}
				else if (this.gs.validateEmail(item.email)) {
					whitelist.address++;
				}
			}
		});

		const blacklist = {
			address: 0,
			domain: 0,
			ip: 0,
			file: this.threatProtectionData.attachments_blacklist?.length || 0,
			link: this.threatProtectionData.links_blacklist?.length || 0
		};

		this.threatProtectionData.senders_blacklist.forEach(item => {
			if (item.email) {
				if (this.gs.validateIPAddress(item.email)) {
					blacklist.ip++;
				}
				else if (this.gs.isValidDomain(item.email)) {
					blacklist.domain++;
				}
				else if (this.gs.validateEmail(item.email)) {
					blacklist.address++;
				}
			}
		});

		this.allowBlockData = {
			whitelist: {data: [], labels: ['Address', 'Domain', 'IP Address', 'File', 'Link'], total: 0},
			blacklist: {data: [], labels: ['Address', 'Domain', 'IP Address', 'File', 'Link'], total: 0}
		};
		this.allowBlockData.whitelist.data = Object.values(whitelist);
		this.allowBlockData.whitelist.total = _.sum(this.allowBlockData.whitelist.data);
		this.allowBlockData.blacklist.data = Object.values(blacklist);
		this.allowBlockData.blacklist.total = _.sum(this.allowBlockData.blacklist.data);
	}


    prepareData = (isUpdate) => {
		this.prepareGeneralStatusOverviewGraph(isUpdate);

		this.prepareInboundTrendsGraph(isUpdate);

        this.prepareQuarantineOverviewGraph(isUpdate);

        this.prepareDetectionTypeThreatsGraph(isUpdate);

        this.prepareTopSenderDomainsGraph(isUpdate);

        this.prepareTopAttackedMailboxesGraph(isUpdate);
	}

	prepareInboundTrendsGraph = (isUpdate) => {

		const series = [];

		if (this.trendsData.inboundStats.labels.length) {
			this.trendsData.inboundStats?.series?.forEach((categoryName, index) => {
				series.push({
					name: categoryName,
					data: this.trendsData.inboundStats.data[index]
				});
			});
		}

		if (isUpdate && this.inboundTrendsChartOptions?.series?.length &&
			this.gs.areArraysEqual(this.trendsData.inboundStats.labels, this.inboundTrendsChartOptions.xaxis.categories)) {
			this.inboundTrendsChart.updateSeries(series, true);
			return;
		}

		this.inboundTrendsChartOptions = {
			series: series,
			chart: {
				type: this.trendsData.inboundStats.labels?.length === 1 ? "bar" : "area",
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					enabled: true,
				},
			},
			colors: ['#f1122d', '#e56f39', '#f5cb0d', '#bbbbbb', '#bbbbbb'],
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['transparent']
			},
			xaxis: {
				tickAmount: 4, // Set the maximum number of ticks (labels) to be displayed on the x-axis
				categories: this.trendsData.inboundStats.labels,
			},
			yaxis: {
				title: {
					text: 'Threats'
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val) => {
						return val + ' threats';
					}
				}
			}
		};
	}

	prepareGeneralStatusOverviewGraph = (isUpdate) => {
		if (!this.trendsData.general) {
			return;
		}

		const data = [
			{label: 'Malicious', value: this.trendsData.general.malicious, color: '#f1122d'},
			{label: 'Suspicious', value: this.trendsData.general.suspicious, color: '#e56f39'},
			{label: 'Spam', value: this.trendsData.general.spam, color: '#e7bf04'},
			{label: 'Graymail', value: this.trendsData.general.graymail, color: '#737373'},
			// {label: 'External', value: this.trendsData.general.external, color: '#056daf'}
		];

		data.sort((a, b) => b.value - a.value);

		const labels = data.map(item => item.label);
		const colors = data.map(item => item.color);
		const values = data.map(item => item.value);


		let statusHighest = Math.max(...values);
		if (statusHighest < 15) { // minimum range
			statusHighest = 15;
		}
		const series = _.map(values, value => {
			return value * 60 / statusHighest;
		});

		this.trendsData.overallGeneralData = {labels, values, series};

		if (isUpdate && this.gs.areArraysEqual(this.trendsData.overallGeneralData.labels, this.generalChartOptions.labels)) {
			this.generalTrendsChart.updateSeries(series, true);
			return;
		}

		this.generalChartOptions = {
			series: series,
			chart: {
				height: '100%',
				type: 'radialBar',
				animations: {
					enabled: true,
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						if (!event || !config) {
							return;
						}

						const category = [this.trendsData.overallGeneralData.labels[config.dataPointIndex]];
						const options = {
							category: category
						};
						this.goToQuarantineTab(options);
					}
				},
			},
			plotOptions: {
				radialBar: {
					endAngle: 270,
					hollow: {
						size: '30%',
						background: 'transparent',
					},
					dataLabels: {
						name: {
							show: true
						},
						value: {
							show: true,
							formatter: (seriesValue) => {
								seriesValue = Number(seriesValue);
								let seriesRealValue;
								for (let i = 0; i < this.trendsData.overallGeneralData.series.length; i++) {
									if (this.trendsData.overallGeneralData.series[i] === seriesValue) {
										seriesRealValue = this.trendsData.overallGeneralData.values[i];
									}
								}
								if (seriesRealValue && this.emailsScanned) {
									return `${((seriesRealValue / this.emailsScanned) * 100).toFixed(1)}%`;
								}
								return seriesValue;
							},
						}
					}
				}
			},
			colors: colors,
			labels: labels,
			legend: {
				show: true,
				floating: true,
				fontSize: '12px',
				position: 'left',
				offsetX: 0,
				offsetY: 0,
				labels: {
					useSeriesColors: true,
				},
				markers: {
					size: 8
				},
				formatter: (seriesName, opts) => {
					return seriesName + ":  " + this.trendsData.overallGeneralData.values[opts.seriesIndex]
				},
				itemMargin: {
					vertical: 3
				}
			}
		};
	}

	prepareQuarantineOverviewGraph = (isUpdate) => {
		this.trendsData.quarantined.total = _.sum(this.trendsData.quarantined.data.flat(1));

		const series = [];
		if (this.trendsData.quarantined.total) {
			this.trendsData.quarantined?.series?.forEach((categoryName, index) => {
				series.push({
					name: categoryName,
					data: this.trendsData.quarantined.data[index]
				});
			});
		}

		if (isUpdate && this.quarantinedChartOptions?.series?.length &&
			this.gs.areArraysEqual(this.trendsData.quarantined.labels, this.quarantinedChartOptions.xaxis.categories)) {
			this.threatStatusChart.updateSeries(series, true);
			return;
		}

		this.quarantinedChartOptions = {
			series: series,
			chart: {
				type: this.trendsData.quarantined.labels?.length < 8 ? "bar" : "area",
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					enabled: true,
				},
			},
			plotOptions: {
				bar: {
					borderRadius: 5,
					horizontal: false,
				},
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['transparent']
			},
			xaxis: {
				tickAmount: 4, // Set the maximum number of ticks (labels) to be displayed on the x-axis
				categories: this.trendsData.quarantined.labels,
			},
			yaxis: {
				title: {
					text: 'Threats'
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val) => {
						return val + ' threats';
					}
				}
			}
		};
	}

	prepareDetectionTypeThreatsGraph = (isUpdate) => {
		this.trendsData.detectionType.total = _.sum(this.trendsData.detectionType.data);

		if (isUpdate && this.detectionTypeChartOptions?.series?.length &&
			this.gs.areArraysEqual(this.trendsData.detectionType.labels, this.detectionTypeChartOptions.labels)) {
			this.threatDetectionTypeChart.updateSeries(this.trendsData.detectionType.data, true);
			return;
		}

		this.detectionTypeChartOptions = {
			series: this.trendsData.detectionType.data,
			chart: {
				type: 'donut',
				animations: {
					enabled: true,
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						if (!event || !config) {
							return;
						}

						const subCategory = this.trendsData.detectionType.labels[config.dataPointIndex];
						if (subCategory === 'Misc') {
							return;
						}
						const options = {
							sub_category: [subCategory]
						};
						this.goToQuarantineTab(options);
					}
				},
			},
			plotOptions: {
				pie: {
					donut: {
						size: '52%',
					}
				}
			},
			dataLabels: {
				enabled: true,
				formatter:  (val, opt) => {
					return opt.w.globals.series[opt.seriesIndex];
				}
			},
			colors: ['#d92e1d', '#e09096', '#a94caa', '#ff9f67', '#fad07d', '#77a380'],
			labels: this.trendsData.detectionType.labels,
			tooltip: {
				y: {
					formatter:  (val) => {
						return val + ' threats';
					}
				}
			}
		};
	}

	prepareTopSenderDomainsGraph = (isUpdate) => {
		if (this.trendsData.domain.data?.length) {
			this.trendsData.domain.data = this.trendsData.domain.data.slice(0, 10);
			this.trendsData.domain.labels = this.trendsData.domain.labels.slice(0, 10);

			this.trendsData.domain.total = _.sum(this.trendsData.domain.data);
		}

		const series = [{
			name: 'Threats',
			data: this.trendsData.domain.data
		}];

		if (isUpdate && this.domainChartOptions?.xaxis?.categories?.length &&
			this.gs.areArraysEqual(this.trendsData.domain.labels, this.domainChartOptions.xaxis.categories)) {
			this.topSenderDomainsChart.updateSeries(series, true);
			return;
		}

		this.domainChartOptions = {
			series: series,
			chart: {
				type: 'bar',
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					enabled: true,
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						if (!event || !config) {
							return;
						}

						const domainName = this.trendsData.domain.labels[config.dataPointIndex];
						const options = {
							sender: domainName
						};
						this.goToQuarantineTab(options);
					}
				}
			},
			colors: [
				"#696461"
			],
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
					barHeight: this.gs.getOptimalBarWidth(this.trendsData.domain.labels.length) + '%',
				}
			},
			dataLabels: {
				total: {
					enabled: true,
					offsetX: 0,
					style: {
						fontSize: '13px',
						fontWeight: 900
					}
				}
			},
			xaxis: {
				categories: this.trendsData.domain.labels,
			},
		};
	}

	prepareTopAttackedMailboxesGraph = (isUpdate) => {
		const series = [];

		if (this.trendsData.mailbox.labels.length) {
			this.trendsData.mailbox.data[0] = this.trendsData.mailbox.data[0].slice(0, 10);
			this.trendsData.mailbox.data[1] = this.trendsData.mailbox.data[1].slice(0, 10);
			this.trendsData.mailbox.data[2] = this.trendsData.mailbox.data[2].slice(0, 10);
			this.trendsData.mailbox.labels = this.trendsData.mailbox.labels.slice(0, 10);

			this.trendsData.mailbox.total = _.sum(this.trendsData.mailbox.data.flat(1));

			this.trendsData.mailbox.series.forEach((categoryName, index) => {
				series.push({
					name: categoryName,
					data: this.trendsData.mailbox.data[index]
				});
			});
		}

		if (isUpdate && this.topMailboxesChartOptions?.labels?.length &&
			this.gs.areArraysEqual(this.trendsData.mailbox.labels, this.topMailboxesChartOptions.xaxis.categories)) {
			this.topAttackedMailboxesChart.updateSeries(series, true);
			return;
		}

		this.topMailboxesChartOptions = {
			series: series,
			chart: {
				type: 'bar',
				height: '100%',
				stacked: true,
				toolbar: {
					show: false
				},
				animations: {
					enabled: true,
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						if (!event || !config) {
							return;
						}

						const mailbox = this.trendsData.mailbox.labels[config.dataPointIndex];
						if (!this.gs.validateEmail(mailbox)) {
							this.ns.showWarnMessage(util.format(this.dic.ERRORS.invalidEmail, mailbox));
							return;
						}

						const category = this.trendsData.mailbox.series[config.seriesIndex] || this.dic.CONSTANTS.threatProtection.status.malicious;
						const options = {
							mailbox,
							category: [category]
						};
						this.goToQuarantineTab(options);
					}
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: this.gs.getOptimalBarWidth(this.trendsData.mailbox.labels.length) + '%',
					dataLabels: {
						total: {
							enabled: true,
							offsetX: 0,
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			colors: ['#de0208', '#ff5c00', '#ffc504', '#b3b1b0'],
			stroke: {
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				categories: this.trendsData.mailbox.labels,
			},
			yaxis: {
				title: {
					text: undefined
				},
			},
			tooltip: {
				y: {
					formatter:  (val) => {
						return val + " threats"
					}
				}
			},
			fill: {
				opacity: 1
			},
			legend: {
				position: 'bottom',
				horizontalAlign: 'center',
			}
		};
	}

	prepareWhitelistPieGraph = () => {
		if (this.allowBlockData.whitelist.data.length) {
			// highest value should get to the 75% of the visual radial bar's range. the ratio of the rest of the values are according to it
			let whitelistHighest = Math.max(...this.allowBlockData.whitelist.data);
			if (whitelistHighest < 15) { // minimum range
				whitelistHighest = 15;
			}
			const dataToDisplay = _.map(this.allowBlockData.whitelist.data, value => {
				return value * 75 / whitelistHighest;
			});
			//

			this.whitelistChartOptions = {
				series: dataToDisplay,
				chart: {
					height: '100%',
					type: 'radialBar',
					toolbar: {
						show: false
					},
					animations: {
						dynamicAnimation: {
							enabled: false
						}
					}
				},
				plotOptions: {
					radialBar: {
						endAngle: 270,
						hollow: {
							size: '20%',
							background: 'transparent',
							image: undefined,
						},
						dataLabels: {
							name: {
								show: false,
							},
							value: {
								show: false,
							}
						}
					}
				},
				colors: ['#1ab7ea', '#905e2c', '#00c129', '#3c4b48', '#0016f0'],
				labels: this.allowBlockData.whitelist.labels,
				legend: {
					show: true,
					floating: true,
					fontSize: '12px',
					position: 'left',
					offsetX: 0,
					offsetY: 0,
					labels: {
						useSeriesColors: true,
					},
					markers: {
						size: 8
					},
					formatter: (seriesName, opts) => {
						return seriesName + ":  " + this.allowBlockData.whitelist.data[opts.seriesIndex]
					},
					itemMargin: {
						vertical: 3
					}
				},

			};
		}
	}

	prepareBlackListPieGraph = () => {
		// blacklist (pie)
		if (this.allowBlockData.blacklist.data.length) {
			// highest value should get to the 75% of the radial bar's range. the ratio of the rest of the values are according to it
			let blacklistHighest = Math.max(...this.allowBlockData.blacklist.data);
			if (blacklistHighest < 15) { // minimum range
				blacklistHighest = 15;
			}
			const dataToDisplay = _.map(this.allowBlockData.blacklist.data, value => {
				return value * 75 / blacklistHighest;
			});
			//
			this.blacklistChartOptions = {
				series: dataToDisplay,
				chart: {
					height: '100%',
					type: 'radialBar',
					toolbar: {
						show: false
					},
					animations: {
						dynamicAnimation: {
							enabled: false
						}
					}
				},
				plotOptions: {
					radialBar: {
						endAngle: 270,
						hollow: {
							size: '20%',
							background: 'transparent',
							image: undefined,
						},
						dataLabels: {
							name: {
								show: false,
							},
							value: {
								show: false,
							}
						}
					}
				},
				colors: ['#1ab7ea', '#905e2c', '#00c129', '#3c4b48', '#0016f0'],
				labels: this.allowBlockData.blacklist.labels,
				legend: {
					show: true,
					floating: true,
					fontSize: '12px',
					position: 'left',
					offsetX: 0,
					offsetY: 0,
					labels: {
						useSeriesColors: true,
					},
					markers: {
						size: 8
					},
					formatter:  (seriesName, opts) => {
						return seriesName + ":  " + this.allowBlockData.blacklist.data[opts.seriesIndex]
					},
					itemMargin: {
						vertical: 3
					}
				},
			}
		}
	}

    changePeriod = (inputPeriod) => {
		if (this.batchInProcess) {
			return;
		}
        this.period = inputPeriod;
        if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
            return;
        }
        this.getTrendsData(false);
    };

    getTrendsByRangeDates = () => {
        if (this.period.value !== this.dic.CONSTANTS.trendsPeriod.range.value) {
            return;
        }
        let range: any = {
            start: this.range.start,
            end: this.range.end
        };

        if (range.start > Date.now() || range.start > range.end) {
            this.ns.showWarnMessage(this.dic.ERRORS.adminTrendsDate);
            return;
        }
        this.getTrendsData(false);
    };

    addDomainToBlacklist = (domains) => {
        if (!domains || !domains.length || this.addDomainToBlacklistInProcess) return;
        let data = {
            type: this.dic.CONSTANTS.configTpAction.blacklist,
            action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
            items: []
        };
        domains.forEach((domain) => {
            data.items.push({value: domain});
        });

        this.addDomainToBlacklistInProcess = true;
        this.rs.updateTpConfig(data).then( (response) => {
            this.addDomainToBlacklistInProcess = false;
            this.blockDomainsPopup = null;
            this.domainChartOptions.xaxis.categories = _.map(this.trendsData.domain.labels, maliciousDomain => {
                if (_.find(domains, d => d === maliciousDomain)) {
                    return maliciousDomain + ' (Blocklist)';
                }
                return maliciousDomain;
            })
            this.trendsData.domain.labels = this.domainChartOptions.xaxis.categories;
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.addBlacklistDomains, domains.join(', ')));
        }, err => {
            this.addDomainToBlacklistInProcess = false;
        });
    };

    openBlockDomainsPopup = () => {
        if (!this.trendsData.domain.labels.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noMaliciousDomains);
            return;
        }

        const nonBlockedMaliciousDomains = [];
        _.map(this.trendsData.domain.labels, (domain, index) => {
            if (domain.endsWith(' (Blocklist)')) {
                return;
            }
            nonBlockedMaliciousDomains.push({
                name: domain,
                threatsCount: this.trendsData.domain.data[index],
                selected: false
            })
        });

        if (!nonBlockedMaliciousDomains.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.allDomainsBlocked);
            return;
        }

        this.blockDomainsPopup = {
            domains: nonBlockedMaliciousDomains,
            show: true
        };
    };

    confirmBlockDomainsPopup = () => {
        const selectedDomains = _.filter(this.blockDomainsPopup.domains, 'selected');
        if (!selectedDomains.length) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'domains'));
            return;
        }

        this.addDomainToBlacklist(_.map(selectedDomains, 'name'));
    }

    mailboxesAction = (action) => {
        switch (action) {
            case 'exportCsv':
                this.exportMailboxToCsv();
                break;
        }

        this.closeAction(this.trendsData.mailbox);
    };


    goToQuarantineTab = (options) => {
        options.period = this.period;
        options.range = this.range;
		this.router.navigate([this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.quarantined], {state: {data: options}});
	}

    goToInboundTab = (page, tab = null, options = null) => {
		this.router.navigate(_.compact([this.dic.CONSTANTS.appStates.adminInbound, page, tab]), {state: {data: options}});
	};

    closeAction = (item) => {
        if (item) {
            item.showActions = false;
        }
    };

    confirmAddAddressesToAllowlistPopup = () => {
        if (this.addAddressToWhitelistInProcess) return;

        const selectedAddresses = _.filter(this.addAddressesToAllowlistPopup.addresses, 'selected');
        if (!selectedAddresses.length) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'addresses'));
            return;
        }

        let data = {
            type: this.dic.CONSTANTS.configTpAction.whitelist,
            action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
            items: _.map(selectedAddresses, a => {return {value: a.name}})
        };

        this.addAddressToWhitelistInProcess = true;

        this.rs.updateTpConfig(data).then( (response) => {
            this.addAddressToWhitelistInProcess = false;
            this.addAddressesToAllowlistPopup = null;
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.addWhitelistAddresses, _.map(selectedAddresses, 'name').join(', ')));
        }, err => {
            this.addAddressToWhitelistInProcess = false;
        });
    };

    confirmAddDomainsToStrictCheckPopup = () => {
        if (this.addDomainToStrictCheckDomainsInProcess) return;

        const selectedDomains = _.filter(this.addDomainsToStrictCheckPopup.domains, 'selected');
        if (!selectedDomains.length) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'domains'));
            return;
        }

        let data = {
            type: this.dic.CONSTANTS.configTpAction.knownDomain,
            action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
            domains: _.map(selectedDomains, 'name')
        };

        this.addDomainToStrictCheckDomainsInProcess = true;


        this.rs.updateTpConfig(data).then( (response) => {
            this.addDomainToStrictCheckDomainsInProcess = false;
            this.addDomainsToStrictCheckPopup = null;
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.addStrictCheckDomains, _.map(selectedDomains, 'name').join(', ')));
        }, err => {
            this.addDomainToStrictCheckDomainsInProcess = false;
        });
    };

    exportQuarantinedToCsv = () => {
        let csvString = "Date, Quarantined\n";

        for (let i = 0 ; i < this.trendsData.quarantined.labels.length; i++) {
            csvString += `${this.trendsData.quarantined.labels[i]},${this.trendsData.quarantined.data[0][0]}\n`;
        }

        this.gs.exportCsv(csvString, `quarantined_emails.csv`);
    };

	exportGeneralToCsv = () => {
		let csvString = "Scanned Emails, Malicious, Suspicious, Spam, Graymail, External\n";
		csvString += `${this.emailsScanned || 'N/A'},${this.trendsData.general.malicious},${this.trendsData.general.suspicious},${this.trendsData.general.spam},${this.trendsData.general.graymail},${this.trendsData.general.external}\n`;

		this.gs.exportCsv(csvString, `general_trends.csv`);
	}

    exportMailboxToCsv = () => {
        let csvString = "Mailbox, Malicious, Suspicious, Spam\n";

        const data = this.trendsData.mailbox;
        for (let i = 0 ; i < data.labels.length; i++) {
            csvString += `${data.labels[i]},${data.data[0][i]},${data.data[1][i]},${data.data[2][i]}\n`;
        }

        this.gs.exportCsv(csvString, `attacked_mailboxes.csv`);
    };

    exportDomainsToCsv = () => {
        let csvString = "Domain, Threats Count\n";

        for (let i = 0 ; i < this.trendsData.domain.labels.length; i++) {
            csvString += `${this.trendsData.domain.labels[i]},${this.trendsData.domain.data[i]}\n`;
        }

        this.gs.exportCsv(csvString, `malicious_domains.csv`);
    };

    exportDetectionTypesToCsv = () => {
        let csvString = "Type, Threats Count\n";

        for (let i = 0 ; i < this.trendsData.detectionType.labels.length; i++) {
            csvString += `${this.trendsData.detectionType.labels[i]},`;
            csvString += `${this.trendsData.detectionType.data[i]}\n`;
        }

        this.gs.exportCsv(csvString, `detection_types.csv`);
    };

	generateReportPopup = () => {
		this.showGenerateReportPopup = {
			show: true,
			selected_mailboxes: this.scheduledQuarantinedReports.selected_mailboxes,
			range: {start: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)), end: new Date() }
		};

		this.rs.getMailboxesInfo().then(data => {
			this.showGenerateReportPopup.mailboxes = data;
			this.showGenerateReportPopup.mailboxes?.forEach((mailboxObj) => {
				mailboxObj.selected = this.scheduledQuarantinedReports.selected_mailboxes?.includes(mailboxObj.email);
			});
		});

		this.csvFields = _.map(this.dic.CONSTANTS.inboundReportCsvFieldNames, f => {
			return {
				selected: this.scheduledQuarantinedReports.selected_fields?.length ? this.scheduledQuarantinedReports.selected_fields.includes(f) : true,
				name: f
			};
		});
	};

	searchMailbox = (event) => {
		this.showGenerateReportPopup.mailboxes.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = searchMailboxExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	applyReportMailboxes = () => {
		const mailboxesSelected = _.map(_.filter(this.showGenerateReportPopup.mailboxes, 'selected'), mailbox => {
			return mailbox.email;
		});

		this.showGenerateReportPopup.updateMailboxesInProcess = true;
		this.rs.updateInboundReportSettings({action: this.dic.CONSTANTS.reportActions.mailboxes, data: mailboxesSelected}).then(() => {
			this.showGenerateReportPopup.selected_mailboxes = mailboxesSelected;
			this.showGenerateReportPopup.updateMailboxesInProcess = false;
			this.showGenerateReportPopup.showMailboxes = null;
		}, () => {
			this.showGenerateReportPopup.updateMailboxesInProcess = false;
		});
	}

	getReport = () => {
		if (this.showGenerateReportPopup.range.start > Date.now() || this.showGenerateReportPopup.range.start > this.showGenerateReportPopup.range.end) {
			this.ns.showErrorMessage(this.dic.ERRORS.adminReportDate)
			return;
		}

		this.gs.showPopup({
			title: 'Generate Report',
			subTitle: 'You are about to generate a report. Once this is finished, the report will be sent to your email address.',
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'Generate',
			doneCb: () => {
				this.showGenerateReportPopup.inProcess = true;
				this.rs.getInboundReport({
					from: new Date(this.showGenerateReportPopup.range.start).toISOString(),
					until: new Date(this.showGenerateReportPopup.range.end).toISOString(),
				}).then(() => {
					this.ns.showInfoMessage('Report operation started successfully');
					this.showGenerateReportPopup.inProcess = false;
				}, (err) => {
					this.showGenerateReportPopup.inProcess = false;
				});
			}
		});
	};

	openReportColumnsPopup = () => {
		this.csvFieldsCopy = _.cloneDeep(this.csvFields);
		this.checkSelectAllFields();
		this.showReportColumnsPopup = true;
	};

	setReportFields = () => {
		const fields = this.csvFieldsCopy.filter(itm => itm.selected).map(itm => itm.name);
		if (!fields.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.atLeastOneReportFields);
			return;
		}

		this.rs.updateInboundReportSettings({action: this.dic.CONSTANTS.reportActions.fields, data: fields}).then(() => {
			this.scheduledQuarantinedReports.selected_fields = fields;
			this.csvFields = this.csvFieldsCopy;
			this.showReportColumnsPopup = false;
		});
	};

	checkSelectAllFields = () => {
		this.selectedAllFields = _.filter(this.csvFieldsCopy, 'selected').length === this.csvFieldsCopy.length;
	};

	toggleAllFields = () => {
		this.selectedAllFields = !this.selectedAllFields;
		_.map(this.csvFieldsCopy, f => {
			f.selected = this.selectedAllFields;
			return f;
		});
	};

	clearReportColumns = () => {
		this.showReportColumnsPopup = false;
	};

	updateReportSettings = () => {
		this.scheduledQuarantinedReports.selected_fields = this.csvFields.filter(itm => itm.selected).map(itm => itm.name);
		this.rs.updateInboundReportSettings({action: this.dic.CONSTANTS.reportActions.settings, data: this.scheduledQuarantinedReports}).then(() => {
			this.updateReportAddressesInProcess = false;
		}, err => {
			this.updateReportAddressesInProcess = false;
		});
	};

	addReportAddress(address) {
		if (!address) {
			return;
		}

		address = address.toLowerCase();
		if (this.scheduledQuarantinedReports.addresses.find(itm => itm === address)) {
			this.ns.showErrorMessage(util.format(this.dic.ERRORS.itemAlreadyExist, address));
			return;
		}

		const actionInfo = {
			action: this.dic.CONSTANTS.reportActions.emails,
			data: {
				email: address,
				type: this.dic.CONSTANTS.actions.add
			}
		};
		this.rs.updateInboundReportSettings(actionInfo).then(() => {
			this.scheduledQuarantinedReports.addresses.push(address);
			this.reportAddresses = '';
		});
	}

	deleteReportAddress(address) {
		if (!address) {
			return;
		}

		address = address.toLowerCase();
		if (!this.scheduledQuarantinedReports.addresses.find(itm => itm === address)) {
			return;
		}

		const actionInfo = {
			action: this.dic.CONSTANTS.reportActions.emails,
			data: {
				email: address,
				type: this.dic.CONSTANTS.actions.delete
			}
		}
		this.rs.updateInboundReportSettings(actionInfo).then(() => {
			this.scheduledQuarantinedReports.addresses = this.scheduledQuarantinedReports.addresses.filter(item => item !== address);
		});
	}


    exportChartsToPdf =  () => {
        if (!this.trendsData) {
            return;
        }
        this.generatePdfInProcess = true;

        // set the relevant elements' visual properties to be ideal for the PDF page before copying the HTML
        let areaForPdf:any = document.getElementById('trendsChartsContainer');
        areaForPdf.classList.add('trends-pdf-layout');

        // timeout is needed to let the css enough time to update on screen and for the responsive charts to resize
        setTimeout(() => {
            const element = document.getElementById('trendsChartsContainer');
            const opt = {
                margin:       40,
                filename:     'trends_and_insights.pdf',
                image:        { type: 'jpeg', quality: 1 },
                html2canvas:  { scale: 2 , width: 1300, windowWidth: 1550},
                jsPDF:        { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling']}
            };

            // New Promise-based usage:
            html2pdf().set(opt).from(element).then(() => {
                setTimeout(() => {
                    this.exitFromPdfGeneration();
                })
            }).save();
        },800);
    }

    exitFromPdfGeneration = () => {
        this.ns.showInfoMessage(util.format(this.dic.MESSAGES.downloadSuccess, 'Trends and insights data'));

        document.getElementById('trendsChartsContainer').classList.remove('trends-pdf-layout');
        this.generatePdfInProcess = false;
        // rerender charts so they'll fit their container's size
        this.getTrendsDataInProcess = true;

        setTimeout(() => {
			this.ngZone.run(() => {
				this.getTrendsDataInProcess = false;
			});
        });
    }

	getPercentage = (partialNumber, totalRecords) => {
		return totalRecords ? Math.floor(100 * partialNumber / totalRecords) : 0;
	}
}

function searchMailboxExecute(reviewerObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return (reviewerObj.email?.toLowerCase().includes(searchTerm) || reviewerObj.name?.toLowerCase().includes(searchTerm));
}
