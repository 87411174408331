import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GeneralService} from "../../../services/generalService";
import _ from 'lodash';
import {DropdownComponent} from "../dropdown/dropdown.component";

@Component({
	selector: 'country-code-select',
	templateUrl: './country-code-select.component.html',
})
export class CountryCodeSelectComponent implements OnInit{
	@Input() model;
	@Output() modelChange = new EventEmitter<any>;
	@Input() allowedCountries = this.gs.availableCountries;
	@Input() isDisabled;
	@ViewChild(DropdownComponent) dropdownEl:DropdownComponent;

	constructor(private gs: GeneralService) {
		this.initCountries();
	}

	countries = [];
	selectedCountry;

	ngOnInit() {
		setTimeout(() => {
			const selectedCountry = this.model ? _.find(this.countries, {dialCode: this.model.replace(/\+/g, '')}) : _.find(this.countries, {iso2: this.gs.defaultCountry});
			this.selectCountryCode(selectedCountry);
		});
	}

	initCountries = () => {
		this.countries = _.cloneDeep($.fn['intlTelInput'].getCountryData());
		this.countries = _.filter(this.countries, country => this.allowedCountries.includes(country.iso2));
		this.countries.forEach(countryObj => {
			countryObj.icon = 'flag-icon flag-' + countryObj.iso2;
			if (countryObj.name.indexOf(' (') > 1) {
				countryObj.name = countryObj.name.substring(0, countryObj.name.indexOf(' ('));
			}
		});
	}

	selectCountryCode = (selectedCountry) => {
		if (!selectedCountry) {
			return;
		}

		this.selectedCountry = selectedCountry;
		this.modelChange.emit(selectedCountry.dialCode);
	}
}
