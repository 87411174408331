<!--loader-->
<div class="f-1-0 flex-column centralize" *ngIf="!isThisFeatureSupported">
	<loader height="100" width="100" style="opacity: 0.8;" *ngIf="_.isUndefined(isThisFeatureSupported)"></loader>
	<threat-simulation-not-supported style="display: contents;" *ngIf="isThisFeatureSupported === false"></threat-simulation-not-supported>
</div>

<div class="flex-column gap-3 animated fadeIn" *ngIf="isThisFeatureSupported">
	<span>Reviewers will have access to Threat Simulation campaigns management.</span>

	<!--reviewers table-->
	<trustifi-table-c
		class="trustifi-table" style="flex: 0 0 300px; max-width: 800px; z-index: 5; overflow: auto;"
		[list]="reviewers"
		[headers]="[
			{text:'Reviewer', sortKey:'email'},
		]"
		[cellsPrototype]="[
			{textKey: 'email', edit: {modelKey: 'email', placeholder: 'Reviewer email'}},
		]"
		[bulkActions]="{
			enable: true,
			selectBulkActionCb:	selectMultipleReviewerAction,
			initBulkActionsFunction: showReviewerBulkActions,
			showInRed: {enable: true, terms: ['Remove']}
		}"
		[cellActions] = "{
			enable: true,
			selectItemActionCb:	selectReviewerAction,
			initActionsFunction: showReviewerActions,
			showInRed: {enable: true, terms: ['Remove']}
		}"
		(searchFunction)="searchReviewer($event.searchTerm, $event.activeFilters)"
		(confirmEditFunction)="confirmAddReviewer($event.item, $event.isApproved)"
		[options]="{
			itemsNameSingular: 'reviewer',
			defaultSortKey: '-email',
		}"
	>
		<div tableButtons>
			<div class="btn-with-dropdown">
				<btn-c (action)="startAddReviewer();"
					   endIcon="fa fa-plus"
					   [isDisabled]="addReviewerInProcess">
					Add Reviewer
				</btn-c>
				<app-dropdown-c [isLf]="true">
					<li (click)="openUsersSelection()">
						Add from my users
					</li>
				</app-dropdown-c>
			</div>
		</div>
	</trustifi-table-c>
</div>

<users-selection-component
	*ngIf="usersSelectionPopup?.show"
	[data]="usersSelectionPopup"
	[selectedUsers]="selectedUsers"
	(addCb)="startAddFromUsersSelection($event.users)"
	(removeCb)="startRemoveFromUsersSelection($event.users)">
</users-selection-component>
