import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import * as L from 'leaflet';
import _ from "lodash";
import 'leaflet.heat';

@Component({
	selector: 'leaflet-c',
	template: '<div class="leaflet-map-container w100 h100" #map></div>',
})
export class LeafletComponent implements AfterViewInit {

	@ViewChild('map') private mapContainer: ElementRef<HTMLElement>;
	@Input() leafletOptions;
	@Input() leafletCenter;
	@Input() markers = [];
	@Input() heatmapData;

	constructor() { }

	ngAfterViewInit() {
		const initialCenter = this.leafletCenter || {lng: 16, lat: 549, zoom: 4};

		const map = new L.Map(this.mapContainer.nativeElement).setView([initialCenter.lat, initialCenter.lng], initialCenter.zoom);

		const mapLinkElement = '<a href="http://openstreetmap.org">OpenStreetMap</a>';
		const layer = new L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: '&copy; ' + mapLinkElement + ' Contributors'});

		map.addLayer(layer);

		this.markers.forEach(marker => {
			let markerOptions:any = {
				icon: marker.icon ? L.icon(marker.icon) : null,
				title: marker.title || null,
				draggable: marker.draggable || null
			}
			markerOptions = _.omitBy(markerOptions,_.isNull);

			new L.marker([marker.lat, marker.lng], markerOptions).addTo(map);
		})

		if (this.heatmapData) {
			const heatmapLayer = this.createHeatmapLayer(this.heatmapData);
			map.addLayer(heatmapLayer); // Add the heatmap layer to the map
		}

		setTimeout(() => {
			map.invalidateSize()
		});
	}

	createHeatmapLayer(heatmapData: any): L.Layer {
		return L.heatLayer(heatmapData.data, {
			//gradient: {0.4: 'blue', 0.65: 'lime', 1: 'red'},
			blur: 20,
			maxZoom: 1
		});
	}
}
