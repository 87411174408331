<div class="flex-column f-1-0 gap-4">
	<span>Choose what to do with sent emails according to conditions you set up. Rules can be turned on or off.</span>

	<div class="flex-column f-1-0">
		<!--Page loader-->
		<div class="flex-row justify-content-center f-1-0" *ngIf="getRulesDataInProcess">
			<loader height="100" width="100" style="opacity: 0.8;"></loader>
		</div>

		<!--Create first rule-->
		<div class="flex-row justify-content-center f-1-0" *ngIf="!getRulesDataInProcess && !createNewRulesInProcess && !userRules?.length">
			<div class="flex-column w-100 align-items-center gap-5 text-center px-4 py-5 bg-light" style="align-self: flex-start; border: 1px solid #E0E0E0; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border-radius: 8px;">
				<div class="flex-column gap-2 align-items-center">
					<h1 class="mb-0" style="font-size: 32px; color: #333333;">Create your first rule</h1>
					<span style="font-size: 16px; color: #666666">Rules choose what to do with sent emails according to conditions you set up.<br/> Rules can be turned off.</span>
					<btn-c id="newRule" class="mt-2"
						   [isDisabled]="!userRules || !sensitivity_types"
						   endIcon="fa fa-plus"
						   (action)="createNewRule();">
						Create New Rule
					</btn-c>
				</div>
				<div class="OR-seperator-line centered-line my-4 w-100 opacity-50"></div>
				<div class="flex-column gap-3 align-items-center">
					<h2 class="mb-0" style="font-size: 24px; color: #333333;">Choose a configuration recommended<br/>for your specific industry</h2>
					<div class="flex-row align-items-center gap-3">
						<btn-c *ngFor="let item of dic.CONSTANTS.outboundQuickRules | keyvalue: gs.returnZero"
							   [isDisabled]="quickRulesPopup?.show"
							   (action)="openQuickRulesPopup(item.key);">
							<span style="text-transform: capitalize;" >{{item.value}}</span>
						</btn-c>
					</div>
				</div>
				<span style="max-width: 730px; font-size: 12px;"><b class="text-danger">Disclaimer:</b> The DLP templates provided by Trustifi are intended as a suggested starting point for creating
					your own unique Data Loss Prevention (DLP) plan. While these templates incorporate industry trends,
					an effective DLP strategy should be tailored to your specific organizational needs and take into account
					a wide range of factors beyond industry standards.
				</span>
			</div>
		</div>

		<div class="flex-column gap-1 f-1-0 animated fadeIn" *ngIf="!getRulesDataInProcess && !createNewRulesInProcess && userRules?.length">
			<div class="flex-row hSpace05 align-items-center">
				<btn-c id="newRule"
					   [isDisabled]="!userRules || !sensitivity_types"
					   endIcon="fa fa-plus"
					   (action)="createNewRule();">
					Create New Rule
				</btn-c>
				<btn-c [isDisabled]="quickRulesPopup?.show"
					   endIcon="fa fa-plus"
					   (action)="openQuickRulesPopup();">
					Create Industry Rules
				</btn-c>
			</div>

			<!--rules-->
			<trustifi-table-c
				class="trustifi-table" style="flex: 1 0; z-index: 5; background: white;"
				[list]="userRules"
				[headers]="[
					{text: 'Priority', width: '80px'},
					{text: 'Name', width: '15%' },
					{text: 'Active', width: '140px' },
					{text: 'Condition', width: '30%'},
					{text: 'Action to perform', width: '20%'},
					{text: 'Last Modified', width: '135px'},
					{text: '', width: '70px'},
				]"
				[cellsPrototype]="[
					{textKey: 'priorityDisplay'},
					{html: 'nameCell'},
					{html: 'statusCell', overflowOutside: true},
					{html: 'conditionsCell'},
					{html: 'actionCell'},
					{textKey: 'created', textType: 'dateAndTime'},
					{html: 'usageCell'},
				]"
				[bulkActions]="{
					enable: true,
					selectBulkActionCb:	selectMultipleRulesAction,
					initBulkActionsFunction: showMultipleRulesActions,
				}"
				[cellActions]="{
					enable: true,
					selectItemActionCb:	selectRuleAction,
					initActionsFunction: showRuleActions,
				}"
				[options]="{
					hideContentAboveTable: true,
					itemsNameSingular: 'rule',
					itemUniqueIdentifier: '_id',
					isEllipsis: false
				}"
				(onDragAndDrop)="!$event.isDroppedOnNextItem && updateRulePriority($event.draggedItem, $event.draggedOntopItem)"
			>
				<ng-template #customCells let-item let-cellId="cellId">
					<!--name cells-->
					<span *ngIf="cellId === 'nameCell'">
						<i *ngIf="item.strict" style="color:#818181; margin-right: 5px;" class="zmdi zmdi-lock-outline"
						   [tooltip]="dic.MESSAGES.ruleStrict"></i>
						<span>{{item.name}}</span>
					</span>
					<!--active cells-->
					<div *ngIf="cellId === 'statusCell'">
						<toggle-switch-c [(model)]="item.enabled"
										 textTrue="Active" textFalse="Inactive"
										 (onChange)="updateRuleState([item], item.enabled)">
						</toggle-switch-c>
					</div>
					<!--condition details cells-->
					<span class="flex-column gap-2" *ngIf="cellId === 'conditionsCell'">
						<div class="flex-row w100 gap-2" *ngFor="let conditionArr of item.conditions; let first = first;">
							<span class="fw-bold flex-shrink-0" style="flex-basis: 65px; color: #486db5;">{{first ? 'IF' : 'AND'}}</span>
							<div class="flex-column gap-1" style="word-break: break-word;">
								<ng-container *ngFor="let condition of conditionArr; let last = last;">
									<span [innerHTML]="condition.text"></span>
									<span class="fw-bold opacity-75" style="color: #486db5; margin: -4px 0;" *ngIf="!last">OR</span>
								</ng-container>
							</div>
						</div>
						<ng-container *ngIf="item.exceptions?.length">
						<div class="flex-row w100 gap-1" *ngFor="let exceptionArr of item.exceptions; let first = first;">
							<span class="fw-bold flex-shrink-0" style="flex-basis: 65px; color: #906b24;">{{first ? 'EXCEPT IF' : 'AND'}}</span>
							<div class="flex-column gap-1" style="word-break: break-word;">
								<ng-container *ngFor="let exception of exceptionArr; let last = last;">
									<span [innerHTML]="exception.text"></span>
									<span class="fw-bold opacity-75" style="color: #486db5; margin: -4px 0;" *ngIf="!last">OR</span>
								</ng-container>
							</div>
						</div>
						</ng-container>
					</span>
					<!--action details cells-->
					<div class="flex-column gap-1" style="font-size: 12px;" *ngIf="cellId === 'actionCell'">
						<span [innerHTML]="item.resultShow.join('<br/>')"></span>
					</div>
					<!--usage cells-->
					<span *ngIf="cellId === 'usageCell'">
						<div class="flxRow-center app-dropdown" style="padding: 2px min(1vw, 15px); height: 30px; cursor: pointer; margin-left: 3px; align-items: center;"
							 (click)="getRuleUsage(item); $event.stopPropagation();" tooltip="Usage data">
							<i class="fas hvr-opacity fa-chart-pie"
							   style="font-size: 1.5rem; background-image: conic-gradient(green 0deg 90deg, purple 90deg 135deg, blue 162deg 365deg); color: transparent; background-clip: text; -webkit-background-clip: text;"></i>
						</div>
					</span>
				</ng-template>
			</trustifi-table-c>
		</div>

		<div class="flex-column w-100 gap-2 py-2 pe-2" *ngIf="createNewRulesInProcess">
			<b>{{!!newRule._id ? 'Edit Rule' : 'Create New Rule'}}</b>

			<!--Rule name-->
			<div class="flex-row w-100 gap-3 align-items-center my-3">
				<span class="flex-shrink-0" style="flex-basis: 100px; text-align: right;">Rule name:</span>
				<div class="f-1-0 flex-row gap-4">
					<input id="outboundRuleName" type="text" placeholder="Rule name" style="width: 235px;"
						   autocomplete="off"
						   [appAutoFocus]="true"
						   [ngClass]="(invalidRuleName) && 'validation-error'"
						   [(ngModel)]="newRule.name"
						   (ngModelChange)="isNameChanged();"/>
					<div class="flxRow-start hSpace1 md-checkbox" style="padding: 0; align-items: center;" *ngIf="gs.reviewerInfo?.email !== userInfo?.email">
						<input id="newRulestrictRule" required="false" type="checkbox"
							   [(ngModel)]="newRule.strict"/>
						<label class="m-0" for="newRulestrictRule">Strict</label>
						<i style="font-weight: 400;" class="fas fa-question-circle"
						   tooltip="The plan admin will not be able to edit or delete this rule" placement="right">
						</i>
					</div>
				</div>
			</div>

			<!--Rule conditions-->
			<div class="flex-column gap-2">
				<div class="flex-row w-100 gap-3" *ngFor="let condition of newRule.conditions; let index = index; let first = first">
					<span class="fw-bold flex-shrink-0" style="flex-basis: 100px; text-align: right; font-size: 17px; color: #486db5; padding-top: 20px;">
						{{first ? 'IF' : 'AND'}}
					</span>
					<div class="flex-row gap-2 f-1-0 px-4 flex-wrap align-items-end" style="border: dashed 1px #ccc; max-width: 1200px; padding-top: 15px; padding-bottom: 15px;">
						<new-rule-condition style="display: contents;"
											[conditionIndex]="index" [condition]="condition"
											[newRule]="newRule"
											[attachmentsTypesData]="{fileTypes}"
											[sensitivityTypesData]="{sensitivity_types, doNotDeleteTypes}"
											[subCustomizationNames]="subCustomizationNames">
						</new-rule-condition>
						<a class="blue-link fw-bold flex-row align-items-center gap-1 flex-shrink-0" style="color: #486db5; padding: 8px 15px; margin: 8px -10px -15px auto;"
						   (click)="addORtoCondition(condition)">
							<i class="fa fa-plus" style="font-size: 9px;"></i>
							<span>Add 'OR' to condition</span>
						</a>
					</div>
				</div>
				<div class="flex-row w-100 gap-3 mt-2">
					<div class="flex-shrink-0" style="flex-basis: 100px;"></div><!--padder-->
					<div class="flex-row gap-2">
						<btn-c id="addRuleConditionBtn" class="rules-add-condition-btn" style="width: 130px;"
							   variant="outlined-dashed" endIcon="fa fa-plus"
							   (action)="addConditionToRule()">
							Add Condition
						</btn-c>
						<btn-c id="addRuleExceptionBtn" class="rules-add-condition-btn" style="width: 130px;"
							   *ngIf="!newRule.exceptions?.length"
							   variant="outlined-dashed" endIcon="fa fa-plus"
							   color="#906b24"
							   (action)="addExceptionToRule()">
							Add Exception
						</btn-c>
					</div>
				</div>
			</div>


			<!--Rule exceptions-->
			<div class="flex-column gap-2 mt-3" *ngIf="newRule.exceptions?.length" >
				<div class="flex-row w-100 gap-3" *ngFor="let condition of newRule.exceptions; let index = index; let first = first">
					<span class="fw-bold flex-shrink-0" style="flex-basis: 100px; text-align: right; font-size: 17px; color: #906b24; padding-top: 20px;">
						{{first ? 'EXCEPT IF' : 'AND'}}
					</span>
					<div class="flex-row gap-2 f-1-0 px-4 flex-wrap align-items-end" style="border: dashed 1px #ccc; max-width: 1200px; padding-top: 15px; padding-bottom: 15px;">
						<new-rule-condition style="display: contents;"
											[conditionIndex]="index" [condition]="condition"
											[newRule]="newRule"
											[attachmentsTypesData]="{fileTypes}"
											[sensitivityTypesData]="{sensitivity_types, doNotDeleteTypes}"
											[subCustomizationNames]="subCustomizationNames">
						</new-rule-condition>
						<a class="blue-link fw-bold flex-row align-items-center gap-1 flex-shrink-0" style="color: #486db5; padding: 8px 15px; margin: 8px -10px -15px auto;"
						   (click)="addORtoCondition(condition)">
							<i class="fa fa-plus" style="font-size: 9px;"></i>
							<span>Add 'OR' to condition</span>
						</a>
					</div>
				</div>
				<div class="flex-row w-100 gap-3 mt-2">
					<div class="flex-shrink-0" style="flex-basis: 100px;"></div><!--padder-->
					<div class="flex-row gap-2">
						<btn-c id="addRuleExceptionBtn" class="rules-add-condition-btn" style="width: 130px;"
							   variant="outlined-dashed" endIcon="fa fa-plus"
							   color="#906b24"
							   (action)="addExceptionToRule()">
							Add Exception
						</btn-c>
					</div>
				</div>
			</div>

			<div class="flex-row w-100 gap-3">
				<div class="flex-shrink-0" style="flex-basis: 100px;"></div><!--padder-->
				<hr class="f-1-0"/>
			</div>


			<!--results-->
			<div class="flex-column gap-2">
				<div class="flex-row w-100 gap-3">
					<span class="fw-bold flex-shrink-0" style="flex-basis: 100px; text-align: right; font-size: 17px; color: #486db5; padding-top: 20px;">
						THEN
					</span>
					<!--main result-->
					<div class="f-1-0 flex-row gap-2 px-4 align-items-center" style="border: dashed 1px #ccc; max-width: 1200px; padding-top: 10px; padding-bottom: 10px;">
						<app-dropdown-c style="width: 280px; --dropdown-menu-width: 820px;" id="{{'rulesPoliciesDropdownResult'}}"
										(ngInit)="isResultChanged()"
										[showTitle]="true" [error]="invalidResults"
										text="{{newRule.resultShow.join(', ') || 'Choose'}}">
							<ul style="columns: 3;">
								<li *ngFor=" let result of dic.CONSTANTS.rules.defaultResult | keyvalue: gs.returnZero; let $index = index;"
									[ngClass]="{ 'd-none' : result.value.hideInResultList}"
									[explanationC]="dic.CONSTANTS.rules.defaultResult[result.key].explanation"
									(click)="newRule.result[result.key].enabled = !newRule.result[result.key].enabled; isResultChanged(result.key); $event.stopPropagation()"
									id="{{'rulesPoliciesResultValue' + result.key + $index}}">
									<div class="gap-1 md-checkbox">
										<input type="checkbox" onclick="return false;" [checked]="newRule.result[result.key].enabled"/>
										<label><span>{{result.value.label}}</span></label>
									</div>
								</li>
							</ul>
						</app-dropdown-c>
					</div>
				</div>

				<!--other options-->

				<div class="flex-row w-100 gap-3">
					<div class="flex-shrink-0" style="flex-basis: 100px;"></div><!--padder-->
					<div class="flex-column f-1-0 gap-2 py-1 hide-when-empty-force" style="border: dashed 1px #ccc; max-width: 1200px;">

						<!-- keep_record toggle-->
						<div class="f-1-0 flex-row gap-2 px-4 py-1 align-items-center" *ngIf="newRule?.result?.block?.enabled">
							<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
								<input id="keep_record" required="false" type="checkbox" [(ngModel)]="newRule.result.keep_record.enabled"
									   (ngModelChange)="parseNewRuleResult(dic.CONSTANTS.rules.defaultResult.keep_record.name)"
									   name="check"/>
								<label for="keep_record" class="m-0">Keep Record</label>
								<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
								   tooltip="The email information will be saved in the 'Quarantined Emails' page">
								</i>
							</div>

							<span style="color: darkorange;" *ngIf="!newRule.result.keep_record?.enabled">
								<i class="fas fa-info-circle"></i> Enable setting if you want to see quarantine records of emails blocked by this rule
							</span>
						</div>

						<!-- allow_sending_email toggle-->
						<div class="f-1-0 flex-row gap-2 px-4 py-1 align-items-center" *ngIf="newRule?.result?.block?.enabled">
							<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
								<input id="allowSenderSend" required="false" type="checkbox" [disabled]="!newRule.result.keep_record.enabled"
									   [(ngModel)]="newRule.result.allow_sending_email.enabled"
									   name="check"/>
								<label for="allowSenderSend" class="m-0">Allow sender to send the email</label>
								<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
								   tooltip="Email will be initially blocked, but the sender will have the option to send the email">
								</i>
							</div>
						</div>

						<!-- block_allow_release_request-->
						<div class="f-1-0 flex-row gap-2 px-4 py-1 align-items-center" *ngIf="newRule?.result?.block?.enabled">
							<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
								<input id="block_allow_release_request" required="false" type="checkbox" [disabled]="!newRule.result.keep_record.enabled"
									   [(ngModel)]="newRule.result.block_allow_release_request.enabled"
									   name="check"/>
								<label for="block_allow_release_request" class="m-0">Allow sender to request release</label>
								<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
								   tooltip="Sender quarantine notifications will have a button to automatically send a request to the admin/reviewers to release the email">
								</i>
							</div>
						</div>

						<!-- Cc recipients input-->
						<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule.result.send_cc.enabled">
							<span>Cc recipients:</span>
							<input type="text" class="m-0" placeholder="Cc emails separated by commas" style="width: 250px;"
								   [(ngModel)]="newRule.result.send_cc.emails"
								   [ngClass]="invalidCcEmails && 'validation-error'"
								   (ngModelChange)="isCcChanged();"/>
						</div>

						<!-- Bcc recipients input-->
						<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule.result.send_bcc.enabled">
							<span>Bcc recipients:</span>
							<input type="text" class="m-0" placeholder="Bcc emails separated by commas" style="width: 250px;"
								   [(ngModel)]="newRule.result.send_bcc.emails"
								   [ngClass]="invalidBccEmails && 'validation-error'"
								   (ngModelChange)="isBccChanged();"/>
						</div>

						<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule?.result?.enforce_tls?.enabled">
							<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
								<input id="tls_encrypt_fallback" type="checkbox"
									   [(ngModel)]="newRule.result.tls_encrypt_fallback.enabled"/>
								<label for="tls_encrypt_fallback" class="m-0">Encrypt email content when enforce TLS fails</label>
								<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
								   tooltip="{{newRule.result.tls_encrypt_fallback.explanation}}">
								</i>
							</div>
						</div>

						<div class="flex-column gap-2" *ngIf="showRuleResultOptions">
							<!-- Prefer tokenization checkbox -->
							<div class="f-1-0 flex-row gap-2 px-4 py-1 align-items-center" *ngIf="newRule?.result?.encrypt_content?.enabled && preferTokenization">
								<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
									<input id="preferTokenization" type="checkbox"
										   [(ngModel)]="newRule.result.prefer_tokenization.enabled"/>
									<label id="preferTokenizationLabel" for="preferTokenization" class="m-0">Prefer tokenization</label>
									<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
									   tooltip="{{newRule.result.prefer_tokenization.explanation}}">
									</i>
								</div>
							</div>

							<!-- Notify sender checkbox -->
							<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule?.result?.notify_sender">
								<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
									<input id="NotifySender" type="checkbox"
										   [(ngModel)]="newRule.result.notify_sender.enabled"/>
									<label id="outboundRulesResultNotifySender" for="NotifySender" class="m-0">Notify sender</label>
									<i style="font-weight: 400; margin-top: -5px;" class="fas fa-question-circle"
									   tooltip="{{newRule.result.notify_sender.explanation}}">
									</i>
									<input id="outboundRulesResultAdminMessage" type="text" placeholder="Admin message (optional)" style="margin-left: 10px; width: 200px;"
										   [(ngModel)]="newRule.result.notify_sender.message" />
								</div>
							</div>

							<!-- Authentication methods  -->
							<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule?.result?.secure_send?.enabled">
								<span>Authentication method:</span>
								<app-dropdown-c style="width: 110px;" id="{{'rulesResultAuthenticationMethods'}}"
												text="{{newRule.result.default_authentication_method?.value}}">
									<ul>
										<li *ngFor="let method of dic.CONSTANTS.secureMethods"
											[ngClass]="{'disabled': !userPolicy.allowed_auth_methods.includes(method)}"
											[explanationC]="dic.CONSTANTS.rules.defaultResult.default_authentication_method.methodExplanations[method]"
											(click)="changeAuthenticationMethodForRule(method)"
											id="{{'rulesPoliciesResultAuthMethodValue' + method}}">
											{{method}}
											<i *ngIf="!userPolicy.allowed_auth_methods.includes(method)"
											   style="color:#818181; padding-left:1px;"
											   class="zmdi zmdi-lock-outline"
											   tooltip="This method must be enabled first in your policy"
											   placement="right">
											</i>
										</li>
									</ul>
								</app-dropdown-c>
							</div>

							<!-- email_expiration_days value -->
							<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule?.result?.email_expiration_days?.enabled">
								<span>Email expiration:</span>
								<div class="flex-row align-items-center gap-2">
									<input type="number" placeholder="Days" style="width: 60px;"
										   [(ngModel)]="newRule.result.email_expiration_days.value" min="1" max="365"
										   pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
										   [ngClass]="invalidExpirationDays && 'validation-error'"
										   (ngModelChange)="isEmailExpirationDaysChanged()" />
									<span>Days</span>
								</div>
							</div>

							<!-- Remove keywords checkbox -->
							<div class="f-1-0 flex-row gap-2 px-4 py-1 align-items-center" *ngIf="newRule?.showRemoveKeywords">
								<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
									<input id="block_encrypted_only_external" required="false" type="checkbox"
										   [(ngModel)]="newRule.result.remove_keyword.enabled"/>
									<label for="block_encrypted_only_external" class="m-0">Remove keywords from subject</label>
									<i style="font-weight: 400;" class="fas fa-question-circle"
									   tooltip="{{newRule.result.remove_keyword.explanation}}">
									</i>
								</div>
							</div>
						</div>

						<!-- Look & Feel customization -->
						<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule?.result?.apply_lf?.enabled">
							<span>Look & Feel customization:</span>
							<app-dropdown-c style="width: 110px;" id="{{'rulesResultSubCustomizations'}}"
											text="{{newRule.result.apply_lf.value}}">
								<ul>
									<li *ngFor="let customization of subCustomizationNames"
										(click)="newRule.result.apply_lf.value = customization">
										{{customization}}
									</li>
								</ul>
							</app-dropdown-c>
						</div>

						<!-- add_header -->
						<div class="f-1-0 flex-row gap-2 px-4 pt-2 pb-4 align-items-center" *ngIf="newRule?.result?.add_header?.enabled">
							<span class="flex-shrink-0" style="flex-basis: 55px;">Headline:</span>
							<div class="f-1-0 position-relative">
								<input type="text" placeholder="HTML/text here" style="width: 100%;"
									   [(ngModel)]="newRule.result.add_header.value"
									   [ngClass]="invalidResultHeader && 'validation-error'"
									   (ngModelChange)="isHeaderChanged()"/>
								<span style="position: absolute; bottom: -17px; font-size: 11px; left: 0;">
									Note: You may also use dynamic fields - for example {{dynamicFieldExample}} will display the sender's email address.
								</span>
							</div>
						</div>

						<!-- add_footer -->
						<div class="f-1-0 flex-row gap-2 px-4 pt-2 pb-4 align-items-center" *ngIf="newRule?.result?.add_footer?.enabled">
							<span class="flex-shrink-0" style="flex-basis: 55px;">Footer:</span>
							<div class="f-1-0 position-relative">
								<input type="text" placeholder="HTML/text here" style="width: 100%;"
									   [(ngModel)]="newRule.result.add_footer.value"
									   [ngClass]="invalidResultFooter && 'validation-error'"
									   (ngModelChange)="isFooterChanged()"/>
								<span style="position: absolute; bottom: -17px; font-size: 11px; left: 0;">
									Note: You may also use dynamic fields - for example {{dynamicFieldExample}} will display the sender's email address.
								</span>
							</div>
						</div>

						<!-- subject prefix -->
						<div class="f-1-0 flex-row gap-2 px-4 py-2 align-items-center" *ngIf="newRule.result.subject_prefix.enabled">
							<span>Prepend subject</span>
							<input type="text" placeholder="Subject text here" style="width: 60%;"
								   [(ngModel)]="newRule.result.subject_prefix.value"
								   [ngClass]="invalidSubjectPrefix && 'validation-error'"/>
						</div>
					</div>
				</div>

			</div>

			<br/>

			<div class="flex-row gap-2">
				<btn-c style="width: 120px;" id="outboundRulesCancelNewRule"
					   endIcon="fa fa-ban" variant="outlined"
					   (action)="cancelNewRule();">
					Cancel
				</btn-c>
				<btn-c style="width: 120px;" id="outboundRulesPrepareRule"
					   [isDisabled]="prepareRuleInProcess"
					   endIcon="{{!!newRule._id ? 'fa fa-check' : 'fa fa-plus'}}"
					   (action)="prepareRule();">
					{{!!newRule._id ? 'Update Rule' : 'Add Rule'}}
				</btn-c>
			</div>
		</div>
	</div>

	<!--rule usage popup-->
	<outbound-rule-usage-c
		*ngIf="ruleUsagePopupData && ruleUsagePopupData?.show"
		[data]="ruleUsagePopupData">
	</outbound-rule-usage-c>

	<popup-c class="overflownV" *ngIf="assignRuleToAdminPopup?.show"
			 head="Assign Rule to Admin"
			 size="dynamic"
			 (closeCb)="assignRuleToAdminPopup = null"
			 (confirmCb)="assignRuleToAdmin()"
			 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: assignToAdminInProcess}}">
		<div class="flex-column f-1-0 gap-1" style="overflow: visible;">
			<span>Assign the rule <b>{{assignRuleToAdminPopup?.ruleToAssign?.name}}</b> to another admin plan</span>
			<br/>
			<!-- Strict rule for PA under CA-->
			<div class="flxRow-start hSpace1 md-checkbox" style="padding: 0; align-items: center;" *ngIf="assignRuleToAdminPopup">
				<input id="strictRule" required="false" type="checkbox" [(ngModel)]="assignRuleToAdminPopup.isStrict"/>
				<label class="m-0" style="position: relative;" for="strictRule">Strict</label>
				<i style="font-weight: 400;" class="fas fa-question-circle"
				   tooltip="The plan admin will not be able to edit or delete this rule" placement="right">
				</i>
			</div>
			<br/>
			<div class="flex-column gap-2">
				<span style="color: black;">Select the new admin from the following list:</span>
				<app-dropdown-c style="width: 320px;"
								text="{{assignRuleToAdminPopup?.selectedAdmin?.email || 'Select'}}"
								[items]="assignRuleToAdminPopup?.adminsList"
								itemDisplayKey="email" [enableSearch]="true"
								(onItemClicked)="assignRuleToAdminPopup.selectedAdmin = $event.item;">
				</app-dropdown-c>
			</div>
		</div>
	</popup-c>
</div>


<popup-c *ngIf="quickRulesPopup?.show"
		 [head]="quickRulesPopup.title" size="dynamic" width="600px"
		 (closeCb)="closeQuickRulePopup()"
		 (confirmCb)="quickRulesPopup.doneCb()"
		 [buttons]="{confirm: 'Apply'}">
	<div class="flxClmn f-1-0 vSpace2">
		<div class="flex-row align-items-center hSpace1">
			<span>Choose which industry rules you want to create: </span>
			<app-dropdown-c text="{{selectedQuickRule}}"
							style="text-transform: capitalize; width: 135px;">
				<ul>
					<li *ngFor= "let quickRule of dic.CONSTANTS.outboundQuickRules | keyvalue: gs.returnZero"
						(click)="generateQuickRuleBody(quickRule.value)"
						style="text-transform: capitalize">
						{{quickRule.value}}
					</li>
				</ul>
			</app-dropdown-c>
		</div>
		<span style="font-size: 14px;">Please review the rule below and then click "Apply" to create them</span>
		<ul class="flex-column gap-2" *ngIf="selectedQuickRule">
			<li *ngFor="let item of quickRulesBody">
				<b>{{item.name}}</b><br><span>{{item.description}}</span>
			</li>
		</ul>
	</div>
</popup-c>
