<div class="flxClmn bs-enabled">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Information</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle"></span>
        </div>
    </div>
    <div class="page-inner-content-holder gap-3 pt-0 flex-column">
        <div class="list-group list-group-flush settings-list-container">
            <div class="list-group-item settings-list-item">
                <div class="title-section-container flex-column gap-2">
                    <b>Partner Contact Information</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;">Contact information which will be used for emails and notifications regarding plan expiration and renewal</span>
                </div>
                <!--Loader-->
                <div class="content-container flex-column gap-4" *ngIf="getPartnerInfoInProcess">
                    <loader class="opacity-75" height="80" width="80"></loader>
                </div>
                <!---->
                <div class="content-container flex-column gap-4" *ngIf="partnerInfo">
                    <div class="flex-row align-items-center" style="min-height: 30px; max-width: 340px;">
                        <span style="flex-basis: 65px; flex-shrink: 0;">Email:</span>
                        <span style="font-weight: bold;" class="animated fadeIn"
                              *ngIf="!partnerInfo?.partners.partner_contact_info.edit">
                                {{partnerInfo?.partners.partner_contact_info.email}}
                        </span>
                        <span style="font-weight: bold;" class="animated fadeIn"
                              *ngIf="!partnerInfo?.partners.partner_contact_info.email && !partnerInfo?.partners.partner_contact_info.edit">
                                -
                        </span>
                        <input class="animated fadeIn"
                               *ngIf="partnerInfo?.partners.partner_contact_info.edit"
                               placeholder="Email Address"
                               style="margin: 0; background: white; flex: 1 0;"
                               [(ngModel)]="partnerInfo?.partners.partner_contact_info.edit.email" maxlength="100"
                               (keyup.enter)="confirmEditPartnerInfo(true)" type="text">
                    </div>
                    <div class="flex-row align-items-center" style="min-height: 30px; max-width: 340px; z-index: 50;" *ngIf="partnerInfo">
                        <span style="flex-basis: 65px; flex-shrink: 0;">Phone:</span>
                        <span style="font-weight: bold;" class="animated fadeIn"
                              *ngIf="partnerInfo.partners.partner_contact_info.phone && partnerInfo.partners.partner_contact_info.phone.phone_number && !partnerInfo.partners.partner_contact_info.edit">
                                +{{ partnerInfo.partners.partner_contact_info.phone.country_code }}-{{partnerInfo.partners.partner_contact_info.phone.phone_number}}
                        </span>
                        <span style="font-weight: bold;" class="animated fadeIn"
                              *ngIf="(!partnerInfo.partners.partner_contact_info.phone || !partnerInfo.partners.partner_contact_info.phone.phone_number) && !partnerInfo.partners.partner_contact_info.edit">
                                -
                        </span>
                        <!--edit phone-->
                        <span class="flxRow hSpace05 h100 animated fadeIn" style="overflow: visible; flex: 1 0;" *ngIf="partnerInfo.partners.partner_contact_info.edit">
							<country-code-select [(model)]="partnerInfo.partners.partner_contact_info.edit.phone.country_code" ></country-code-select>
                            <input id="partnerEditPhone"
                                   type="text" placeholder="Phone number"
                                   style="flex: 1 0; margin-right: 0; margin-bottom: 0; padding-right: 5px;"
                                   maxlength="11"
                                   (keyup.enter)="confirmEditPartnerInfo(true)"
                                   [(ngModel)]="partnerInfo.partners.partner_contact_info.edit.phone.phone_number">
                        </span>
                    </div>
                    <div class="flex-row align-items-center gap-2" [ngClass]="{'justify-content-end' : partnerInfo?.partners.partner_contact_info.edit}" style="max-width: 340px;">
                        <btn-c class="animated fadeIn"
                             *ngIf="!partnerInfo.partners.partner_contact_info.edit"
                             (action)="startEditPartnerInfo();">
                            Edit
                        </btn-c>
                        <btn-c class="animated fadeIn"
                             variant="outlined"
                             *ngIf="partnerInfo.partners.partner_contact_info.edit"
                             (action)="confirmEditPartnerInfo(false);">
                            Cancel
                        </btn-c>
                        <btn-c class="animated fadeIn"
                             *ngIf="partnerInfo.partners.partner_contact_info.edit"
                             [loading]="updatePartnerInfoInProcess"
                             (action)="confirmEditPartnerInfo(true);">
                            Apply
                        </btn-c>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
