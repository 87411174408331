import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import util from "util";
import {RouteService} from "../../../../services/routeService";
import {GeneralService} from "../../../../services/generalService";
import {NotificationService} from "../../../../services/notificationService";
import _ from "lodash";
import {DICTIONARY} from "../../../../dictionary";

@Component({
	selector: 'threat-simulation-template-fields-component',
	templateUrl: './threat-simulation-template-fields.component.html',
})
export class ThreatSimulationTemplateFieldsComponent implements OnInit {

	@Input() missingFields;
	@Output() onSuccessfulUpdate = new EventEmitter<any>;

	constructor(private rs: RouteService,
				public gs: GeneralService,
				private ns:NotificationService,
				private ngZone: NgZone
	) {}

	dic = DICTIONARY;
	_ = _;


	isLoaded;
	isThisFeatureSupported;
	addReviewerInProcess;
	threatSimulationConfig;
	logoFile;
	showMoreFields = false;
	isValidationError = false;
	fieldNamesMap = {
		logo: 'COMPANY_LOGO',
		company: 'COMPANY_NAME',
		company_domain: 'COMPANY_DOMAIN',
		url: 'COMPANY_URL'
	};

	ngOnInit() {
		this.rs.getThreatSimulationConfiguration().then((response) => {
			this.isThisFeatureSupported = true;
			this.isLoaded = true;
			this.threatSimulationConfig = response.threat_simulation;

			this.threatSimulationConfig.template_fields = this.threatSimulationConfig.template_fields || {};
			if (!this.threatSimulationConfig.template_fields.ceo) {
				this.threatSimulationConfig.template_fields.ceo = {email: '', first_name: '', last_name: ''};
			}
			if (!this.threatSimulationConfig.template_fields.cto) {
				this.threatSimulationConfig.template_fields.cto = {email: '', first_name: '', last_name: ''};
			}
			if (!this.threatSimulationConfig.template_fields.cfo) {
				this.threatSimulationConfig.template_fields.cfo = {email: '', first_name: '', last_name: ''};
			}
			if (!this.threatSimulationConfig.template_fields.marketing) {
				this.threatSimulationConfig.template_fields.marketing = {email: '', first_name: '', last_name: ''};
			}
			if (!this.threatSimulationConfig.template_fields.sales) {
				this.threatSimulationConfig.template_fields.sales = {email: '', first_name: '', last_name: ''};
			}
			if (!this.threatSimulationConfig.template_fields.hr) {
				this.threatSimulationConfig.template_fields.hr = {email: '', first_name: '', last_name: ''};
			}
			if (!this.threatSimulationConfig.template_fields.security_team) {
				this.threatSimulationConfig.template_fields.security_team = {email: '', first_name: '', last_name: ''};
			}
			if (!this.threatSimulationConfig.template_fields.it_team) {
				this.threatSimulationConfig.template_fields.it_team = {email: '', first_name: '', last_name: ''};
			}

			if (this.threatSimulationConfig.template_fields) {
				for (const [key, value] of Object.entries(this.threatSimulationConfig.template_fields)) {
					if (!_.keys(this.fieldNamesMap).includes(key)) {
						this.fieldNamesMap[key] = {
							email: key.toUpperCase() + '_EMAIL',
							first_name: key.toUpperCase() + '_FIRST_NAME',
							last_name: key.toUpperCase() + '_LAST_NAME',
						}
					}
				}
			}
		}, (err) => {
			this.isLoaded = true;
			if (err?.data?.message && err.data.message.includes('This feature is not supported')) {
				this.isThisFeatureSupported = false;
			}
		});
	}

	updateThreatSimulationTemplateFields = () => {
		this.validateAllFields();
		if (this.isValidationError) {
			return;
		}

		this.rs.updateThreatSimulationTemplateFields(this.threatSimulationConfig.template_fields).then((response) => {
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);

			if (this.onSuccessfulUpdate.observed) {
				this.onSuccessfulUpdate.emit({});
			}
		}, (err) => {
		});
	}

	validateAllFields = () => {
		const invalidEmails = _.filter(_.map(this.threatSimulationConfig.template_fields, 'email'), email => {
			if (email && !this.gs.validateEmail(email)) {
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.invalidEmail, email));
				return true;
			}
		});

		this.isValidationError = !!invalidEmails.length;
	}

	uploadLogo = () => {
		if (!this.logoFile || !this.logoFile.name) {
			return;
		}

		if (this.logoFile.type !== "image/png") {
			this.ns.showWarnMessage(this.dic.ERRORS.logoInvalidType);
			return;
		}

		//200 KB
		if (this.logoFile.size > 200000) {
			this.ns.showWarnMessage(this.dic.ERRORS.logoMaxSize);
			return;
		}


		const reader = new FileReader();
		reader.addEventListener("load", () => {
			if (reader.result) {
				this.ngZone.run(() => {
					this.threatSimulationConfig.template_fields.logo = reader.result.toString().split(',')[1];
				});
			}
		}, false);

		reader.readAsDataURL(this.logoFile);
	};
}
