import '../../plugins/rawdeflate.js';
import auth0 from 'auth0-js';
import * as util from 'util';
import {DICTIONARY} from '../dictionary';
import {AUTH0_CONSTS, ENV_CONSTS} from '../constants';
import {RouteService} from './routeService';
import {GeneralService} from './generalService';
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {NotificationService} from "./notificationService";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

	Auth0Client;

	samlAudience = DICTIONARY.CONSTANTS.samlAudience;

	public appTimeoutSubj = new BehaviorSubject<any>(null);

	constructor(private rs:RouteService,
				private gs:GeneralService,
				private router:Router,
				private ns:NotificationService) {


		this.Auth0Client = new auth0.WebAuth({
			domain: AUTH0_CONSTS.domain,
			clientID: this.gs.auth0ClientId || AUTH0_CONSTS.clientId,
			redirectUri: AUTH0_CONSTS.auth0RedirectUrl,
			//scope: AUTH0_CONSTS.scope,
			//audience: AUTH0_CONSTS.audience,
			responseType: 'token',
			//responseMode: AUTH0_CONSTS.responseMode,
			//_disableDeprecationWarnings: AUTH0_CONSTS._disableDeprecationWarnings,
			leeway : 60
		});

		this.isAuthenticated().then(isAuthenticated => {
			if (isAuthenticated) {
				this.rs.setDefaultHeaders(this.getHeaders());
			}
			else {
				let urlQuery = Object.fromEntries(new URLSearchParams(window.location.search || window.location.hash)) || {};
				window.history.replaceState({}, document.title, window.location.pathname);

				for(let key in urlQuery) {
					if (key[0] === '#') {
						urlQuery[key.substring(1)] = urlQuery[key];
					}
				}

				if (urlQuery['error']) {
					localStorage.idpLogin = '';
					localStorage.logoutUrl = '';
					console.error('IDP', urlQuery['error']);

					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigate([DICTIONARY.CONSTANTS.appStates.login], {state: {gothrough: true, error: urlQuery['error']}});
					});
				}
				else if (urlQuery['email'] && urlQuery['saml_token'] || urlQuery['id_token']) {
					this.parseIDPResponse(urlQuery['id_token'], urlQuery['access_token'], urlQuery['saml_token'], urlQuery['logout'], urlQuery['email'], err => {
						if (err) {
							console.error('IDP', err);
							this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
								this.router.navigate([DICTIONARY.CONSTANTS.appStates.login], {state: {gothrough: true, error: err}});
							});
						}
					});
				}
				else if (localStorage.access_token && localStorage.idpLogin) {
					const idpLogin = localStorage.idpLogin;
					localStorage.idpLogin = '';
					localStorage.logoutUrl = '';

					//do auto-login to SSO
					this.IDPLogin(idpLogin);
				}
			}
		});
	}

    parseAuthenticationResponse(authResult, stateParams) {
        let connection = authResult.connection || authResult.idTokenPayload && authResult.idTokenPayload.identities && authResult.idTokenPayload.identities[0].connection;

        if (authResult.accessToken && connection) {
            this.rs.setDefaultHeaders({"x-access-token": authResult.accessToken, "x-trustifi-source": 'webapp'});
            localStorage.access_token = authResult.accessToken;
            localStorage.expires_at = authResult.expiresIn * 1000 + Date.now();
            localStorage.connection = connection;

            this.gs.getUserInfo(true, (userInfo, err) => {
                if (userInfo) {
                    this.setUser(authResult, false, () => {
                        // redirections after successful login:
                        // go to inbound quarantined -apply query by message id
                        if (stateParams && stateParams.quarantinedMessageId) {
							this.router.navigate([DICTIONARY.CONSTANTS.appStates.adminInbound, DICTIONARY.CONSTANTS.adminPages.inbound.quarantined], {state: {quarantinedMessageId : stateParams.quarantinedMessageId}})
                        }
                        // My plan page
                        else if (stateParams && stateParams.company) {
							this.router.navigate([DICTIONARY.CONSTANTS.appStates.accountDetails], {state: stateParams})
                        }
                        // global
                        else if (stateParams && stateParams.transitionName) {
							this.router.navigate([stateParams.transitionName], {state: stateParams})
                        }
                        // default
                        else {
							this.router.navigate([DICTIONARY.CONSTANTS.appStates.home])
                        }
                    });
                }
                else {
                    if (err?.status === 406) {
                        // user exists but requires MFA
                        return;
                    }
					if (err?.status === 401) {
						// user exists but unauthorized
						return;
					}

                    this.rs.setDefaultHeaders({"x-access-token": authResult.accessToken, "x-trustifi-source": 'webapp'});
                    this.rs.signupUser({
                        connection: connection,
                        company: this.gs.corpname
                    }).then((response) => {
                        if (response.error) {
                            console.error(connection, response);
							this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
								this.router.navigate([DICTIONARY.CONSTANTS.appStates.login], {state: {gothrough: true, error: response.error}});
							});
                        }
                        else {
                            this.setUser(authResult, true, () => {
								this.router.navigate([DICTIONARY.CONSTANTS.appStates.home], {state: {gothrough: true}})
                            });
                        }
                    }, err => {
						this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
							this.router.navigate([DICTIONARY.CONSTANTS.appStates.login], {state: {gothrough: true, error: err.message}});
						});
                    });
                }
            });
        }
        else {
			this.isAuthenticated().then(isAuthenticated => {
				if (isAuthenticated) {
					this.gs.getUserInfo(false, (userInfo) => {
						if (userInfo) {
							this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
								this.router.navigate([DICTIONARY.CONSTANTS.appStates.home], {state: {gothrough: true}});
							});
						}
					});
				}
			});
        }
    };

    setUser(authResult, reload, cb) {
        localStorage.expires_at = authResult.expiresIn * 1000 + Date.now();
        localStorage.access_token = authResult.accessToken || '';
        localStorage.id_token = authResult.idToken || '';
        localStorage.email = authResult.email || '';
		localStorage.logoutUrl = authResult.logoutUrl || '';
		localStorage.idpLogin = localStorage.connection === 'saml' || localStorage.connection === 'openid' ? localStorage.email : '';

		this.rs.setDefaultHeaders(this.getHeaders());

        this.gs.getUserInfo(reload, (userInfo, err) => {
            this.gs.initUserInApp(userInfo, () => {
                //server is not responding
                if (err && err.status === -1) {
                    this.gs.cleanSession();
                }
                cb && cb(err, userInfo);
            });
        });
    }

    authenticateWithPopup(url, callback) {
        let authwnd = window.open(url, '_blank', 'location=no,width=750,height=600,scrollbars=no,resizable=no');

        try {
            authwnd.focus();
            authwnd.onunload = () => callback(true);

			//[MARKB]: We should add fallback for redirect.html: "window.localStorage.setItem('trustifi_authwnd_handler', message)"
			//[MARKB]: The "authwnd.onunload" throws error and should be replaced with:
			/*closeInterval = setInterval(function() {
				if (authwnd && authwnd.closed) {
					let message = window.localStorage.getItem('trustifi_authwnd_handler');
					if (message) {
						window.localStorage.removeItem('trustifi_authwnd_handler');
						return parseAuthResponse(message, callback);
					}
					callback(true);
				}
			}, 1000);*/
        }
        catch (e) {
            console.error(e);
            return callback(DICTIONARY.ERRORS.popupblocker);
        }

        window['trustifi_authwnd_handler'] = (event) => {
            let eqSign = event.data.indexOf('=');
            let apiparam = eqSign < 0 ? event.data : event.data.substring(0, eqSign);
            let apivalue = eqSign < 0 ? '' : event.data.substring(eqSign+1);

            try {authwnd.close()} catch(e) {}

            if (apiparam.indexOf('error') >= 0 || apivalue && apivalue.indexOf('error_description=') >= 0) {
                if (apivalue && apivalue !== 'undefined') {
                    apivalue = decodeURIComponent(apivalue.replace('}}', ''));
                    if (apivalue === 'cancel' || apivalue.indexOf('AADSTS65004') >= 0) {
                        apivalue = true;
                    }
                    else if (apivalue.indexOf('ServerError') === 0) {
                        apivalue = util.format(DICTIONARY.ERRORS.adminConnectError, parseFloat(apivalue.substring(11)));
                    }
                    else if (apivalue.match(/^AADSTS\d+:/)) {
                        apivalue = util.format(DICTIONARY.ERRORS.ssoError, apivalue.split(':')[0]);
                    }
                    else {
                        if (apivalue.indexOf('error_description=') >= 0) {
                            apivalue = apivalue.substring(apivalue.indexOf('error_description=') + 18).replace(/\+/g, ' ');
                        }
                        apivalue = 'Sign in failed due to: ' + apivalue;
                    }
                }
                else {
                    console.error(apiparam);
                }

                return callback(apivalue || DICTIONARY.ERRORS.authSignInError);
            }

            let apires = apivalue.split(':');
            callback(null, apires[0], event.data);
        };
    }

    login(credentials, callback) {
        this.Auth0Client.client.login({
            realm: AUTH0_CONSTS.connection,
            username: credentials.username,
            password: credentials.password
        }, (err, authResult) => {
            if (err) {
                let errorMsg;
                if (err.code === 'invalid_grant' || err.code === 'access_denied' || err.code === 'too_many_attempts') {
                    errorMsg = DICTIONARY.ERRORS.loginError;
                }
                else {
                    if (err.code === 'unauthorized' && err.description === "please change your password") {
                        errorMsg = DICTIONARY.ERRORS.authPassExp;
                    }
                    else {
                        errorMsg = DICTIONARY.ERRORS.authReqTimeout;
                    }
                }
                console.error(err);
                callback(errorMsg);
            }
            else if (authResult && authResult.accessToken) {
                this.gs.userInfo = null;
                this.setUser(authResult, false, callback);
            }
            else {
                callback(DICTIONARY.ERRORS.authSignInError);
            }
        });
    }

    remoteLogin(authResult, cb) {
        this.gs.userInfo = null;
        this.setUser(authResult, false, cb);
    };

    IDPLogin(username, forceAuthenticate=false) {
        let url = `${ENV_CONSTS.paypalUrl}/callback/idp?origin=${encodeURIComponent(window.location.origin)}&username=${username}${forceAuthenticate ? '&auth=1' : ''}`;
		window.location.assign(url);
    }

	parseIDPResponse(id_token, access_token, saml_token, logoutUrl, email, callback) {
		try {
			if (id_token) {
				const payload = JSON.parse(atob(id_token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
				const email = payload.email || payload.sub || payload.preferred_username || '';

				this.rs.exchangeTokenOpenID({token: access_token}).then(res => {
					const authResult = {
						idToken: id_token,
						accessToken: res.oToken,
						logoutUrl: res.logoutUrl,
						email: email,
						connection: 'openid',
						expiresIn: 7200, //payload.exp - Math.floor(Date.now() / 1000),
						state: ''
					};

					this.parseAuthenticationResponse(authResult, window.history.state);
					callback();
				}).catch(err => {
					callback(DICTIONARY.ERRORS.authSignInError);
				});
			}
			else if (saml_token) {
				const authResult = {
					accessToken: saml_token,
					email: email,
					connection: 'saml',
					logoutUrl: logoutUrl,
					expiresIn: 7200,
					state: ''
				};

				this.parseAuthenticationResponse(authResult, window.history.state);
				callback();
			}
		}
		catch(ex) {
			callback(ex.message);
		}
	}

    parseOpenIDMetadata(openIDData, cb) {
        if (!openIDData.metadata) {
            return cb(DICTIONARY.ERRORS.authDiscoveryMissing);
        }

        window.fetch(openIDData.metadata).then(res => res.json()).then(res => {
            openIDData.userinfo_endpoint = res.userinfo_endpoint;
            openIDData.auth_endpoint = res.authorization_endpoint;
            openIDData.logout_endpoint = res.end_session_endpoint || '';

            if (!openIDData.auth_endpoint) {
                return cb(DICTIONARY.ERRORS.authDiscoveryInvalid);
            }

            cb();
        }).catch(err => {
            console.error(err.message);
            cb(DICTIONARY.ERRORS.authDiscoveryInvalid);
        });
    }

    validateOpenID(openIDData, username, callback) {
        const randomString = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 8);
        let url = `${openIDData.auth_endpoint}?client_id=${openIDData.clientId}&state=${window.location.origin}&login_hint=${username}&prompt=login&max_age=30
                            &response_type=token+id_token&scope=openid+email+profile&nonce=${randomString}
                            &redirect_uri=${encodeURIComponent(window.location.origin+'/redirect.html')}`.replace(/\s/g, '');

        this.authenticateWithPopup(url, (err, data, tokens) => {
            //the result can be returned twice because of the behavior of "window.open"
            if (!tokens) {
                return callback(err);
            }

            const access_token = (tokens.split('&').find(itm => itm.includes('access_token')) || '').split('=')[1];
            const id_token = (tokens.split('&').find(itm => itm.includes('id_token')) || '').split('=')[1];
            if (access_token && id_token.split('.')[1]) {
                try {
                    const payload = JSON.parse(atob(id_token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
                    if (!payload.exp || payload.exp * 1000 < Date.now()) {
                        return callback(DICTIONARY.ERRORS.authOIDCTokenExpired);
                    }

                    const email = payload.email || payload.upn || payload.unique_name || payload.preferred_username || payload.sub || '';
                    if (!openIDData.domains.some(itm => email.includes(itm))) {
                        return callback(DICTIONARY.ERRORS.authDomainIvalid);
                    }
                }
                catch(ex) {
                    console.error(ex);
                    return callback(DICTIONARY.ERRORS.authOIDCTokenInvalid);
                }
            }
            else {
                return callback(DICTIONARY.ERRORS.authOIDCTokenInvalid);
            }

            window.fetch(openIDData.userinfo_endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + access_token
                }
            }).then(res => res.json()).then(res => {
                callback();
            }).catch(err => {
                callback(err.message);
            });
        });
    }

    openIDLogout(logoutUrl, id_token) {
        let url = `${logoutUrl}?id_token_hint=${id_token}&post_logout_redirect_uri=${window.location.origin}/redirect.html`;

        //NOTE: we can do "window.fetch" instead of the "window.open" but in this case user will have to set FE url in "Trusted Origins" in SP.
        //window.open(url, '_blank', 'location=no,width=750,height=600,scrollbars=no,resizable=no');

        this.authenticateWithPopup(url, err => {
            if (err && err !== true) {
                console.error(err);
            }
        });
    }

    parseSamlMetadata(samlData, cb) {
        if (!samlData.metadata) {
            return cb(DICTIONARY.ERRORS.authSAMLMissing);
        }

        try {
            const metadata = $($.parseXML(samlData.metadata));
            let xmlNS = metadata.children()[0].nodeName.includes(':') && (metadata.children()[0].nodeName.toLowerCase().split(':')[0] + '\\:') || '';

            //EntityDescriptor.IDPSSODescriptor[WantAuthnRequestsSigned] === false
            //EntityDescriptor.IDPSSODescriptor.NameIDFormat.text() === 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress'

            if (metadata.find(xmlNS + 'IDPSSODescriptor[WantAuthnRequestsSigned="true"]').length) {
                return cb(DICTIONARY.ERRORS.authSAMLMissing);
            }

            if (metadata.find(xmlNS + 'IDPSSODescriptor ' + xmlNS + 'NameIDFormat').length && !metadata.find(xmlNS + 'IDPSSODescriptor ' + xmlNS + 'NameIDFormat').text().includes('emailAddress')) {
                //NameIDFormat should be "emailAddress"
				return cb(DICTIONARY.ERRORS.authSAMLMissing);
            }

            //samlData.signOnURL = 'https://dev-7398107.okta.com/app/dev-7398107_trustifisaml_2/exk5v96uh7DkM3jyV5d7/sso/saml';
            //samlData.signOnURL = 'https://login.microsoftonline.com/1f83480d-fb06-4902-bff0-407a70a3bd6a/saml2';

            samlData.signOnURL = metadata.find(xmlNS + 'SingleSignOnService[Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST"]').attr('Location');
            samlData.logoutURL = metadata.find(xmlNS + 'SingleLogoutService').attr('Location');
            samlData.entityID = metadata.find(xmlNS + 'EntityDescriptor').attr('entityID');
            samlData.publicKey = metadata.find(xmlNS + 'KeyDescriptor *').filter((i, itm) => itm.tagName.includes('X509Certificate')).text().replace(/[\n\r\s]/g, '').trim();

            if (!samlData.signOnURL || !samlData.entityID || !samlData.publicKey) {
                return cb(DICTIONARY.ERRORS.authSAMLMissing);
            }

            cb();
        }
        catch(ex) {
            console.error(ex);
            cb(DICTIONARY.ERRORS.authSAMLMissing);
        }
    }

    validateSaml(samlData, username, callback) {
        samlData.origin = window.location.origin+'/redirect.html';		//we redirect to this URL inside popup
		samlData.userEmail = username;
		samlData.forceAuthenticate = true;

        this.rs.authorizeSaml(samlData).then(res => {
            this.authenticateWithPopup(res.url, (err, data, tokens) => {
                //the result can be returned twice because of the behavior of "window.open"
                if (!tokens) {
                    return callback(err);
                }

                const email = (tokens.split('&').find(itm => itm.includes('email')) || '').split('=')[1];
                if (email && !samlData.domains.some(itm => email.includes(itm))) {
                    return callback(DICTIONARY.ERRORS.authDomainIvalid);
                }

                callback();
            });
        }, err => {
            callback(err);
        });
    }

    samlLogout(logoutUrl, email) {
        const randomString = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 16);
        let logoutRequest = `
<samlp:LogoutRequest xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion"
                ID="_${randomString}" Version="2.0"
                IssueInstant="${new Date().toISOString()}"
                Destination="${logoutUrl}">
<saml:Issuer>${this.samlAudience}</saml:Issuer>
<saml:NameID Format="urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress">${email}</saml:NameID>
</samlp:LogoutRequest>`.replace(/\s+/g, ' ').trim();

        logoutRequest = encodeURIComponent(btoa(window['RawDeflate'].deflate(logoutRequest)));
        let url = `${logoutUrl}?SAMLRequest=${logoutRequest}&RelayState=${encodeURIComponent(window.location.origin+'/redirect.html')}`;

        this.authenticateWithPopup(url, err => {
            if (err && err !== true) {
                console.error(err);
            }
        });
    }

    socialLogin(provider, plan, stateParams, callback) {
        this.Auth0Client.popup.authorize({
            owp: true,
            connection: provider,
            prompt: provider.includes('yahoo') ? 'login' : 'select_account',
            state: plan ? 'plan=' + plan : ''
        }, (err, res) => {
            if (err) {
                console.error(err);
                return callback();
            }

            this.Auth0Client.validateAuthenticationResponse({},{
                id_token: res.id_token,
                access_token: res.access_token,
                state: res.state,
                expires_in: res.expiresIn
            }, (err, authResult) => {
                if (err) {
                    console.error(err);
                    return callback();
                }

                if (authResult.idTokenPayload) {
                    authResult.sub = authResult.idTokenPayload.sub.split('|')[0];
                    authResult.iss = authResult.idTokenPayload.iss;
                    authResult.email = authResult.idTokenPayload.email;
					//NOTE: it works when "identities" array is absent - auth is done by auth0.com without "profile" scope
					//authResult.connection = authResult.idTokenPayload.identities && authResult.idTokenPayload.identities[0].connection || authResult.idTokenPayload.sub.split('|')[1];
				}

                this.parseAuthenticationResponse(authResult, stateParams);
                callback();
            })
        });
    }

    /*this.authMicrosoftExchangePowershell = function(userEmail, callback) {
        if (!this.gs.userInfo || !this.gs.userInfo.exchangeAdminAppId)
            return callback(util.format(DICTIONARY.ERRORS.serverError, 333));

        let url = `https://login.microsoftonline.com/common/oauth2/authorize
                    ?resource=https://outlook.office365.com&prompt=login&response_type=code
                    &client_id=fb78d390-0c51-40cd-8e17-fdbfab77341b
                    &redirect_uri=https://login.microsoftonline.com/common/oauth2/nativeclient`.replace(/\s/g, '');

        const authwnd = window.open(url, '_blank', 'location=no,width=750,height=600,scrollbars=yes,resizable=yes');
    };*/

    authMicrosoftExchangeAdmin(userEmail, azureadEndpoint, exchangeAdminAppId, clientType, callback) {
        if (!this.gs.userInfo || !exchangeAdminAppId)
            return callback(util.format(DICTIONARY.ERRORS.serverError, 333));

        const url = `https://${azureadEndpoint}/common/adminconsent
                    ?response_type=code&state=${window.location.origin}|${this.gs.userInfo.email}|${this.gs.corpname}|${userEmail}|${clientType}
                    &client_id=${exchangeAdminAppId}
                    &redirect_uri=${ENV_CONSTS.beBaseGraphUrl}/api/o/v1/outlook/callbackadmin`.replace(/\s/g, '');

        this.authenticateWithPopup(url, callback);
    }

    authMicrosoftExchangeContacts(userEmail, tenant, azureadEndpoint, exchangeContactAppId, callback) {
        if (!this.gs.userInfo || !exchangeContactAppId || !tenant) {
            return callback(util.format(DICTIONARY.ERRORS.serverError, 333));
        }
        if (!azureadEndpoint) {
            azureadEndpoint = DICTIONARY.CONSTANTS.exchangeServerEndpoints.Global.AzureAD;
        }

        //&domain_hint=${this.gs.userInfo.email.split('@')[1]} - should refer to a verified domain
		const url = `https://${azureadEndpoint}/${tenant}/adminconsent
                    ?response_type=code&state=${window.location.origin}|${this.gs.userInfo.email}|${this.gs.corpname}|${userEmail}|contact
                    &client_id=${exchangeContactAppId}
                    &redirect_uri=${ENV_CONSTS.beBaseGraphUrl}/api/o/v1/outlook/callbackadmin`.replace(/\s/g, '');

        this.authenticateWithPopup(url, callback);
    }

    changePassword(email,callback) {
        this.Auth0Client.changePassword({
            connection: AUTH0_CONSTS.connection,
            email: email
        }, callback);
    }

    isAuthenticated() {
		return new Promise<Boolean>((resolve, reject) => {
			// already authenticated (normal)
			if (localStorage.access_token && Date.now() < localStorage.expires_at) {
				return resolve(true);
			}

			// already authenticated (API key)
			if (this.gs.apiToken) {
				return resolve(true);
			}

			const urlQuery =  new URLSearchParams(location.search.substring(1) || '');
			const apiToken = urlQuery.get('token');
			if (apiToken) {
				this.rs.verifyApiAccessToken(apiToken).then(authResult => {
					if (authResult) {
						this.gs.apiToken = apiToken;
						this.rs.setDefaultHeaders({"x-trustifi-api-token": apiToken, "x-trustifi-source": 'webapp'});
						return resolve(true);
					}

					this.gs.apiToken = null;
					return resolve(false);
				}, err => {
					this.gs.apiToken = null;
					return resolve(false);
				});
			}
			else {
				return resolve(false);
			}
		});
    }

    logout(federated=true) {
        if (federated) {
            if (localStorage.connection) {
                /*this.Auth0Client.logout({
                    //federated: true,
                    //continue: 'https://appengine.google.com/_ah/logout?continue=' + AUTH0_CONSTS.auth0RedirectUrl
                    returnTo: AUTH0_CONSTS.auth0RedirectUrl
                });*/

				if (localStorage.logoutUrl && localStorage.email) {
					if (localStorage.connection === 'openid') {
						this.openIDLogout(localStorage.logoutUrl, localStorage.id_token);
					}
					else if (localStorage.connection === 'saml') {
						this.samlLogout(localStorage.logoutUrl, localStorage.email);
					}
				}
            }

            this.gs.cleanSession();

			this.router.navigate([DICTIONARY.CONSTANTS.appStates.login]);
        }
        else {
            this.gs.cleanSession();
        }

        this.gs.isVerified = true;
        this.gs.controlAdmin = null;
        this.gs.planAdmins = null;
    }

    getHeaders() {
        let headers = {"x-trustifi-source": 'webapp'};
        if (localStorage[DICTIONARY.HEADERS.x2FAFingerprint]) {
            headers['x-trustifi-2fa-fingerprint'] = localStorage[DICTIONARY.HEADERS.x2FAFingerprint];
        }

		if (this.gs.apiToken) {
			headers['x-trustifi-api-token'] = this.gs.apiToken;
		}
		else {
			if (localStorage.access_token) {
				headers['x-access-token'] = localStorage.access_token;
			}
		}

        if (this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.adminOutbound) ||
            this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.accountCompromised) ||
            this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.archive) ||
            this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.partner) ||
            this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.adminInbound)) {
            if (this.gs.controlAdmin && this.gs.userInfo && this.gs.controlAdmin.email !== this.gs.userInfo.email) {
                headers['x-trustifi-managed-admin'] = this.gs.controlAdmin.email;
            }
        }
        return headers;
    }

    addFingerprint(headerValue) {
        localStorage["x-trustifi-2fa-fingerprint"] = headerValue;
        this.rs.setDefaultHeaders(this.getHeaders());
    }

    deleteFingerprint() {
        localStorage["x-trustifi-2fa-fingerprint"] = null;
        this.rs.setDefaultHeaders(this.getHeaders());
    }

    setManagedAdmin(adminEmail) {
        let headers = this.getHeaders();
        headers['x-trustifi-managed-admin'] = adminEmail;
        this.rs.setDefaultHeaders(headers);
    }

	userNotAuthenticated(data) {
		this.logout();
		if (data.data?.message) {
			setTimeout(() => {
				this.ns.showErrorMessage(data.data.message);
			}, 500);
		}
	}

	userRequireMfa(data) {
		// failed target page and parameters (to restore after login)
		const transitionName = this.router.url && this.router.url.substring(1).split('/');
		const transitionParams = window.history.state.transitionParams || window.history.state;

		// "purchase" acts as a dummy here (we don't want to fall into the content route resolver again)
		this.router.navigateByUrl('/purchase', { skipLocationChange: true }).then(() => {
			this.router.navigate([DICTIONARY.CONSTANTS.appStates.login], {state: {show2FaCodeForm: true, data2Fa: data.data, transitionName, transitionParams}})
		});
	}
}
