import * as util from 'util';
import Papa from 'papaparse';
import shaJs from 'sha.js';
import {Buffer} from 'safe-buffer';
import _ from 'lodash';

import {DICTIONARY} from '../dictionary';
import {ENV_CONSTS} from '../constants';
import {RouteService} from './routeService';
import {NotificationService} from './notificationService';
import {Injectable} from "@angular/core";
import * as moment from "moment-timezone";
import {ClipboardService} from "ngx-clipboard";
import {Router} from "@angular/router";
import {HttpClient, HttpEventType} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {LookAndFeelService} from "./lookAndFeelService";

const IP_REGEX = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;


@Injectable({
    providedIn: 'root'
})
export class GeneralService {
    dic = DICTIONARY;
    isVerified = true;
    herokuapp = {name:'', url:''};
    trendsData;
	apiToken;

	public primaryNameChangeSubj = new BehaviorSubject<any>(null);
	public onboardingQuestionnaireStatusSubj = new BehaviorSubject<any>(null);
	public navigatedThroughOnboardingSubj = new BehaviorSubject<any>(null);
	public cancelOnboardingActiveStepSubj = new BehaviorSubject<any>(null);
	public updatePlanAdminsSubj = new BehaviorSubject<any>(null);
	public triggerContactUsFormSubj = new BehaviorSubject<any>(null);

	popupData:any = {
        isShow: false
    };

    leafletDefaultIcon = {
        iconUrl: 'css/images/marker-icon.png',
        shadowUrl: 'css/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    };

	leafletDangerIcon = {
		iconUrl: '/images/danger-marker.png',
		shadowUrl: 'css/images/marker-shadow.png',
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		shadowSize: [41, 41],
	};


    faviconName;
    faviconSrc;
    controlAdmin;
    userInfo;
    reviewerInfo;
    whatsNewAlert;
    summernoteOptions;
    adminInfoMap = {};
    planAdmins;
    defaultCode;
    verificationEmail;
    country_code;
    availableCountries = ['us', 'il', 'br', 'es', 'au', 'gb', 'ch', 'nl', 'hk', 'no', 'nz', 'za', 'gr', 'mu', 'pa', 'in'];
    defaultCountry;
    domain;
    userSettings;
    corpname;
    paypalMerchantId;
    signupWelcome;
    signupCompany;
    company;
    showComodo;
    payments;
    url;
    showApiDocemntation;
    showDropboxIntegration;
    importContactsFromAddressBook;
    auth0ClientId;
    gdpr;
    hipaa;
    chrome_ug;
    outlook_ug;
    faq;
    sensitivityEngine;
    about;
    useCases;
    eula;
    privacy;
    antiSpam;
    contactUs;
    sla;
    emdrSla;
    footerCompanyName;
    cloudspongeLaunch;
    cloudspongeUpdated;

	chartColors = [
		"#4CAF50",  // Green
		"#2196F3",  // Blue
		"#FF9800",  // Orange
		"#9C27B0",  // Purple
		"#FFC107",  // Amber
		"#E91E63",  // Pink
		"#00BCD4",  // Cyan
		"#FF5722",  // Deep Orange
		"#607D8B",  // Blue Grey
		"#8BC34A",  // Light Green
		"#3F51B5",  // Indigo
		"#FFEB3B",  // Yellow
		"#795548",  // Brown
		"#009688",  // Teal
		"#673AB7"   // Deep Purple
	];


    constructor(private router:Router,
				private http:HttpClient,
				private rs:RouteService,
                private ns:NotificationService,
                private lfs:LookAndFeelService,
				private clipboard: ClipboardService) {
        this.initSummernote();

		// default values
        this.setDefaultVariables();
        this.updateDefaultCountry();

		// app Title
        document.head.querySelector('title').innerText = this.faviconName;
        this.changeFavicon(this.faviconSrc);

		this.setUpdatePlanAdminSubscription();
    }

	setUpdatePlanAdminSubscription() {
		this.updatePlanAdminsSubj.subscribe((params) => {
			if (!params) {return;}

			this.planAdmins = params.planAdmins || [];
			if (!this.planAdmins.length) {
				return;
			}

			this.planAdmins.forEach((adminObj) => {
				if (adminObj.email.endsWith('@trustifi.com')) {
					adminObj.display_email = adminObj.email.split('@')[0];
				}
				else {
					const isSameDomainExist = this.planAdmins.find(itm => itm.email !== adminObj.email &&
						this.getEmailDomain(itm.email) === this.getEmailDomain(adminObj.email));
					adminObj.display_email = isSameDomainExist ? adminObj.email : this.getEmailDomain(adminObj.email);
				}
			});

			this.adminInfoMap[this.userInfo.email] = this.userInfo;
			this.controlAdmin = this.planAdmins[0];

			// let the BE know which admin is actively managed now. (not needed when broadcast for a change in admin list)
			if (params.init) {
				// 'x-trustifi-managed-admin' header need to be changed, so:
				let headers = this.getHeaders();
				this.rs.setDefaultHeaders(headers);
			}
		});
	}

    //TODO: fix duplicated code in authService
    private getHeaders() {
		let headers = {"x-trustifi-source": 'webapp'};

		if (this.apiToken) {
			headers['x-trustifi-api-token'] = this.apiToken;
		}
		else {
			if (localStorage.access_token) {
				headers['x-access-token'] = localStorage.access_token;
			}
		}

        if (localStorage[DICTIONARY.HEADERS.x2FAFingerprint]) {
            headers['x-trustifi-2fa-fingerprint'] = localStorage[DICTIONARY.HEADERS.x2FAFingerprint];
        }
        if (this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.adminOutbound) ||
            this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.accountCompromised) ||
            this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.archive) ||
            this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.partner) ||
            this.router.url.startsWith('/' + DICTIONARY.CONSTANTS.appStates.adminInbound)) {
            if (this.controlAdmin && this.userInfo && this.controlAdmin.email !== this.userInfo.email) {
                headers['x-trustifi-managed-admin'] = this.controlAdmin.email;
            }
        }
        return headers;
    }

    initUserInApp = (userInfo, cb) => {
        if (userInfo) {
            this.reviewerInfo = userInfo;
            this.getUserNotifications();
            this.checkPartnerAdminPlans();
            if (userInfo.whats_new_alert) {
                this.whatsNewAlert = true;
            }
            return cb();
        }

        this.getUserInfo(false, (userInfo, err) => {
            if (userInfo) {
                this.reviewerInfo = userInfo;
                this.getUserNotifications();
                this.checkPartnerAdminPlans();
                if (userInfo.whats_new_alert) {
                    this.whatsNewAlert = true;
                }
				cb();
            }
            else {
				cb(true);
			}
        });
    }

	setDarkMode(enable) {
		document.body.setAttribute('data-theme', enable ? 'dark' : 'light');

		if (this.userInfo) {
			this.userInfo.dark_mode = enable;
		}

		setTimeout(() => {
			// reduce saturation for main color since saturation change in DM
			this.lfs.applyLf(this.lfs.color, this.lfs.logo);
		});
	}

    isProductionEnv() {
        return (ENV_CONSTS.beBaseUrl === 'https://be.trustifi.com/api/i/v1');
    }

	// set default variables depending on domain and subdomain value
	setDefaultVariables() {
		// subDomain is the original subdomain only for the domain name: 'trustifi'
		let subDomainName = this.lfs.getDomainAndSubdomain().domain === 'trustifi' ? this.lfs.getDomainAndSubdomain().subdomain :  this.lfs.getDomainAndSubdomain().domain;

        let subDomainObj = DICTIONARY.SUB_DOMAINS[subDomainName];
        if (subDomainObj) {
            this.corpname = subDomainObj.company;
            this.paypalMerchantId = subDomainObj.paypalMerchantId;
            this.country_code = subDomainObj.country_code || DICTIONARY.CONSTANTS.trustifiDefault.country_code;
            this.signupWelcome = subDomainObj.signupWelcome || DICTIONARY.CONSTANTS.trustifiDefault.signupWelcome;
            this.signupCompany = subDomainObj.signupCompany || DICTIONARY.CONSTANTS.trustifiDefault.name;
            this.showComodo = subDomainObj.showComodo;
            this.faviconName = subDomainObj.faviconName || DICTIONARY.CONSTANTS.trustifiDefault.faviconName;
            this.payments = subDomainObj.payments;
            this.url = subDomainObj.url || DICTIONARY.CONSTANTS.trustifiDefault.url;
            this.showApiDocemntation = subDomainObj.showApiDocemntation;
            this.showDropboxIntegration = subDomainObj.showDropboxIntegration;
            this.importContactsFromAddressBook = subDomainObj.importContactsFromAddressBook;

            if (subDomainObj.auth0ClientId) {
                this.auth0ClientId =  subDomainObj.auth0ClientId;
            }

            if (subDomainObj.resources) {
                this.gdpr = subDomainObj.resources.gdpr || DICTIONARY.CONSTANTS.trustifiDefault.resources.gdpr;
                this.hipaa = subDomainObj.resources.hipaa || DICTIONARY.CONSTANTS.trustifiDefault.resources.hipaa;
                this.chrome_ug = subDomainObj.resources.chrome_ug || DICTIONARY.CONSTANTS.trustifiDefault.resources.chrome_ug;
                this.outlook_ug = subDomainObj.resources.outlook_ug || DICTIONARY.CONSTANTS.trustifiDefault.resources.outlook_ug;
                this.faq = subDomainObj.resources.faq || DICTIONARY.CONSTANTS.trustifiDefault.resources.faq;
                this.sensitivityEngine = subDomainObj.resources.sensitivityEngine || DICTIONARY.CONSTANTS.trustifiDefault.resources.sensitivityEngine;

                this.about = subDomainObj.resources.about || DICTIONARY.CONSTANTS.trustifiDefault.resources.about;
                this.useCases = subDomainObj.resources.useCases || DICTIONARY.CONSTANTS.trustifiDefault.resources.useCases;
                this.eula = subDomainObj.resources.eula || DICTIONARY.CONSTANTS.trustifiDefault.resources.eula;
                this.privacy = subDomainObj.resources.privacy || DICTIONARY.CONSTANTS.trustifiDefault.resources.privacy;
                this.antiSpam = subDomainObj.resources.antiSpam || DICTIONARY.CONSTANTS.trustifiDefault.resources.antiSpam;
                this.contactUs = subDomainObj.resources.contactUs || DICTIONARY.CONSTANTS.trustifiDefault.resources.contactUs;

                this.sla = subDomainObj.resources.antiSpam || DICTIONARY.CONSTANTS.trustifiDefault.resources.sla;
                this.emdrSla = subDomainObj.resources.contactUs || DICTIONARY.CONSTANTS.trustifiDefault.resources.emdrSla;
            }
            this.footerCompanyName = subDomainObj.footerCompanyName || DICTIONARY.CONSTANTS.trustifiDefault.footerCompanyName;

            if (subDomainObj.hasFavicon) {
                this.faviconSrc = `images/favicon/${subDomainName.toLowerCase()}/favicon.png`;
            }
            else {
                this.faviconSrc = 'images/favicon/favicon.ico';
            }
            this.domain = subDomainObj.domain || "trustifi.com";
        }
        else {
            // default Trustifi settings
            this.corpname = DICTIONARY.CONSTANTS.trustifiDefault.name;
            this.signupWelcome = DICTIONARY.CONSTANTS.trustifiDefault.signupWelcome;
            this.signupCompany = DICTIONARY.CONSTANTS.trustifiDefault.name;
            this.paypalMerchantId = DICTIONARY.CONSTANTS.trustifiDefault.paypalMerchantId;
            this.showComodo = true;
            this.payments = {trustifi: true};
            this.url = DICTIONARY.CONSTANTS.trustifiDefault.url;
            this.faviconName = DICTIONARY.CONSTANTS.trustifiDefault.faviconName;
            this.faviconSrc = 'images/favicon/favicon.ico';
            this.country_code = DICTIONARY.CONSTANTS.trustifiDefault.country_code;
            this.showApiDocemntation = true;
            this.showDropboxIntegration = true;
            this.importContactsFromAddressBook = true;
            this.domain = "trustifi.com";

            this.gdpr = DICTIONARY.CONSTANTS.trustifiDefault.resources.gdpr;
            this.hipaa = DICTIONARY.CONSTANTS.trustifiDefault.resources.hipaa;
            this.chrome_ug = DICTIONARY.CONSTANTS.trustifiDefault.resources.chrome_ug;
            this.outlook_ug = DICTIONARY.CONSTANTS.trustifiDefault.resources.outlook_ug;
            this.faq = DICTIONARY.CONSTANTS.trustifiDefault.resources.faq;
            this.sensitivityEngine = DICTIONARY.CONSTANTS.trustifiDefault.resources.sensitivityEngine;

            this.sla = DICTIONARY.CONSTANTS.trustifiDefault.resources.sla;
            this.emdrSla = DICTIONARY.CONSTANTS.trustifiDefault.resources.emdrSla;

            this.footerCompanyName = DICTIONARY.CONSTANTS.trustifiDefault.footerCompanyName;

            this.about = DICTIONARY.CONSTANTS.trustifiDefault.resources.about;
            this.useCases = DICTIONARY.CONSTANTS.trustifiDefault.resources.useCases;
            this.eula = DICTIONARY.CONSTANTS.trustifiDefault.resources.eula;
            this.privacy = DICTIONARY.CONSTANTS.trustifiDefault.resources.privacy;
            this.antiSpam = DICTIONARY.CONSTANTS.trustifiDefault.resources.antiSpam;
            this.contactUs = DICTIONARY.CONSTANTS.trustifiDefault.resources.contactUs;
        }
    };

    cleanSession() {
        this.rs.setDefaultHeaders({});

		this.apiToken = null;
		localStorage.removeItem('access_token');
		localStorage.removeItem('x-trustifi-2fa-fingerprint');
		localStorage.removeItem('id_token');
		localStorage.removeItem('logoutUrl');
		localStorage.removeItem('email');
		localStorage.removeItem('connection');
		localStorage.removeItem('expires_at');
		localStorage.removeItem('idpLogin');

        this.userInfo = null;
        this.userSettings = null;
        this.controlAdmin = null;

		this.lfs.resetLfToDefault();
		this.setDarkMode(false);
    };

    getDomain(email) {
        try {
            if (!email) {
                return '';
            }
            let domainObj = email.split('@');
            if (domainObj.length !== 2) {
                return '';
            }
            return domainObj[1].toLowerCase() || '';
        }
        catch(ex) {
            return '';
        }
    };

    checkPartnerAdminPlans() {
        this.getUserInfo(false, (userInfo) => {
            if (this.isPartnerAdmin(userInfo)) {
                this.rs.getUsersOfSharedPlan({onlyPlanAdmins: true}).then((response) => {
                    this.planAdmins = response.shared_plan.admins || [];
					this.planAdmins = this.planAdmins.filter(itm => !itm.hide_partner_access);
                    this.planAdmins.unshift({email: userInfo.email});
					this.updatePlanAdminsSubj.next({planAdmins: this.planAdmins, init: true});
                }, (err) => {

				});
            }
        });
    }

    isPartnerAdmin(userInfo) {
		return userInfo.plan.customer_id &&
			(userInfo.user_role === DICTIONARY.CONSTANTS.userRole.admin || userInfo.user_role === DICTIONARY.CONSTANTS.userRole.subAdmin ||
				userInfo.global_reviewer?.inbound?.enabled || userInfo.global_reviewer?.outbound?.enabled || userInfo.global_reviewer?.account_compromised?.enabled
				|| userInfo.global_reviewer?.archive?.enabled || userInfo.global_reviewer?.threat_simulation?.enabled);
    }

    getUserInfo(reload, cb) {
        if (this.router.url &&
            (this.router.url.startsWith('/' + this.dic.CONSTANTS.appStates.adminOutbound) ||
            this.router.url.startsWith('/' + this.dic.CONSTANTS.appStates.accountCompromised) ||
            this.router.url.startsWith('/' + this.dic.CONSTANTS.appStates.archive) ||
            this.router.url.startsWith('/' + this.dic.CONSTANTS.appStates.partner) ||
            this.router.url.startsWith('/' + this.dic.CONSTANTS.appStates.adminInbound))) {
            if (this.controlAdmin && this.userInfo && this.controlAdmin.email !== this.userInfo.email) {
                return this.getAdminInfo(reload, cb);
            }
        }

        if (reload || !this.userInfo) {
            this.rs.getUserInformation().then((response) => {
                this.userInfo = response;
                this.isVerified = this.userInfo.email_verified;

                if (this.userInfo.plan.heroku_app && this.userInfo.plan.heroku_app.app) {
                    this.herokuapp.name = this.userInfo.plan.heroku_app.app.name;
                    this.herokuapp.url = this.userInfo.plan.heroku_app.app.url;
                }

                this.verificationEmail = this.userInfo.email || 'your email account.';
                if (this.dic.CONSTANTS.trustifiDefault.name !== 'Trustifi') {
                    this.domain = this.getDomain(this.userInfo.email);
                }

				this.getReviewerInfo(() => { // check if non-admin user is reviewer

					if (!reload && !this.isVerified) {
						this.rs.getUserVerified().then((response) => { // check if user's email is verified
							this.isVerified = response && response.email_verified;
							cb(this.userInfo);
						});
					}
					else {
						cb(this.userInfo);
					}

				});
            }, err => {
                cb(null, err);
            });
        }
        else {
            cb(this.userInfo);
        }
    };

	getReviewerInfo = (cb) => {
		if (this.userInfo.isInboundReviewer !== undefined || this.userInfo.user_role !== this.dic.CONSTANTS.userRole.user) {
			return cb();
		}

		this.rs.checkUserReviewer().then(response => {
			this.userInfo.isInboundReviewer = response.isInboundReviewer || false;
			this.userInfo.inboundAllowChangeConfiguration = response.inboundAllowChangeConfiguration || false;
			this.userInfo.isAccountCompromisedReviewer = response.isAccountCompromisedReviewer || false;
			this.userInfo.isOutboundReviewer = response.isOutboundReviewer || false;
			this.userInfo.isArchiveReviewer = response.isArchiveReviewer || false;
			this.userInfo.isPartnerReviewer = response.isPartnerReviewer || false;
			this.userInfo.isThreatSimulationReviewer = response.isThreatSimulationReviewer || false;
			return cb();
		}, err => {
			return cb();
		});
	}

    getAdminInfo(reload, cb) {
        const isSecurityReviewer = this.reviewerInfo && this.reviewerInfo.is_security_reviewer;

        if (!reload && this.adminInfoMap[this.controlAdmin.email]) {
            if (this.adminInfoMap[this.controlAdmin.email].plan.is_silent_mode && isSecurityReviewer) {
                this.ns.showInfoMessage(`Silent mode enabled for ${this.adminInfoMap[this.controlAdmin.email].email}`);
            }
            return cb(this.adminInfoMap[this.controlAdmin.email]);
        }

        const params = {notify: !this.adminInfoMap[this.controlAdmin.email]};
        if (isSecurityReviewer) {
            params.notify = false;
        }
        this.rs.getPlanAdminInfo(this.controlAdmin.email, params).then((adminInfo) => {
            this.adminInfoMap[this.controlAdmin.email] = adminInfo;
            if (adminInfo.plan.is_silent_mode && isSecurityReviewer) {
                this.ns.showInfoMessage(`Silent mode enabled for ${adminInfo.email}`);
            }
            return cb(this.adminInfoMap[this.controlAdmin.email]);
        });
    };

    getUserNotifications () {
        this.rs.getUserNotifications().then((response) => {
            if (response.newNotifications) {
                for (let idx = 0; idx < response.newNotifications.length; idx++) {
                    let notificationObj = response.newNotifications[idx];
                    if (notificationObj.type === 'info') {
                        this.ns.showInfoMessage(notificationObj.text);
                    }
                    else {
                        this.ns.showWarnMessage(notificationObj.text);
                    }
                }
            }
        });
    };

    checkUploadFileSize (fileSize, fileName) {
        if (fileSize <= 0) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.emptyFile, fileName));
            return false;
        }

        const fileSizeInMB = fileSize * 0.000001;
        let filePlanMaxSize = this.userInfo.plan && this.userInfo.plan.max_file*1000 || 0;
        if (fileSizeInMB > filePlanMaxSize) {
            const accountHref = '<a target="_self" href="/' + this.dic.CONSTANTS.appStates.accountDetails + '/' + this.dic.CONSTANTS.accountDetailsPages.myPlan + '"><b><i>My Plan</i></b></a>';
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.uploadSizeLargerThanAllowed, filePlanMaxSize, accountHref));
            return false;
        }

        return true;
    };

    checkVerificationEmail (cb) {
        this.rs.getUserVerified().then(response => {
            this.isVerified = response && response.email_verified;
            cb && cb(this.isVerified, response);
        });
    };

    showPopup = (popupData) => {
        this.popupData.options = popupData;
        this.popupData.isShow = true;
        this.popupData.allowCancel = false;
        this.popupData.page = 1;

        if (popupData.dialog) {
            Object.assign(popupData, popupData.dialog[0]);
        }

        // setTimeout( () => {
        //     let popupDiv = document.getElementById('app-popup-container');
        //     if (popupDiv) {
        //         popupDiv.click();
        //         popupDiv.focus();
        //     }
		//
        //     this.popupData.allowCancel = true;
        // }, 500);
    };

    hidePopup() {
        this.popupData.isShow = false;
    };

	getFileContent = (url, cb) => {
		url = ENV_CONSTS.attachmentUrl + url;

		this.http.get(url,{
			responseType: 'arraybuffer',
			headers: this.getHeaders(),
			observe: 'response'
		}).subscribe((response) => {
			if (response && response.body.byteLength === 0) {
				return cb(this.dic.ERRORS.fileDownloadFailed);
			}

			cb(null, response);
		}, err => {
			console.error(err);
			cb(this.dic.ERRORS.fileDownloadFailed);
		});
	}

    downloadFile = (url, fileObj, showProgressInNotification, cb) => {
        let notIdx = this.ns.showInfoMessage(this.dic.MESSAGES.downloadFile, {timeout: 0});

        let downloadProgress = 0, dots = '', count = 0, dotsProgress = 0;
        url = ENV_CONSTS.attachmentUrl + url;
		fileObj.inProcess = true;
		this.http.get(url,{
			responseType: 'arraybuffer',
			headers: this.getHeaders(),
			observe: 'events',
			reportProgress: true
		}).subscribe((event) => {
			// show progress
			if (event.type === HttpEventType.DownloadProgress) {
				downloadProgress = Math.trunc(event.loaded / (event.total || fileObj.size ) * 100);
				fileObj.downloadProgressPrecentage = downloadProgress;
				if (showProgressInNotification) {
					if (downloadProgress < 100){
						this.ns.overwriteMessage(notIdx,
							util.format(this.dic.MESSAGES.downloadFileProgress, downloadProgress, fileObj.name));
					}
				}
				else {
					//overwrite notification every 5 rounds
					if (count % 5 === 0){
						dots = '';
						for (let i = 0; i <= dotsProgress ; i++) dots += '.';
						dotsProgress += 1;
						// %3 for displaying ...
						dotsProgress = dotsProgress % 3;
						if (dots !== '') {
							this.ns.overwriteMessage(notIdx,
								util.format(this.dic.MESSAGES.downloadDotsProgress, fileObj.name, dots));
						}
					}
					count++;
				}
			}

			// download completed
			if (event.type === HttpEventType.Response) {
				const response = event;
				if (!showProgressInNotification && response.body && response.body.byteLength === 0) {
					/*
					TODO: maybe should pass notification data to this function to be more generic, currently there is no
						usage for other  notifications
					 */
					this.ns.overwriteMessage(notIdx, this.dic.MESSAGES.emptyReport, 'warn');
					return cb();
				}
				let contentType = response.headers['content-type'];
				if (!contentType || contentType === 'bytes') {
					contentType = fileObj.type;
				}
				const blob = new Blob([response.body], { type: contentType });

				if (this.download(blob, fileObj.name, contentType)) {
					this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.downloadSuccess, fileObj.name));
					fileObj.downloadProgressPrecentage = 100;
				}
				else {
					this.ns.overwriteMessage(notIdx, this.dic.ERRORS.downloadFailed);
				}
				fileObj.inProcess = false;
				cb();
			}
		}, err => {
			console.error(err);
			this.ns.closeMessage(notIdx);
			this.ns.showWarnMessage(this.dic.ERRORS.fileDownloadFailed);
			fileObj.inProcess = false;
			cb(err);
		});
    };

    downloadData = (data, fileName, mimeType) => {
        return this.download(data, fileName, mimeType);
    };

    uploadFile = (url, data, notIdx, showInUI) => {
      let uploadProgress = 0;

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();

        for (const [key, value] of Object.entries(data) as any) {
          formData.append(key, value);
        }

        xhr.responseType = 'json';
        xhr.addEventListener('load', event => resolve({data: (event.currentTarget as any).response}), false);
        xhr.addEventListener('error', event => reject({data: (event.currentTarget as any).response}), false);
        xhr.addEventListener('abort', event => reject({data: (event.currentTarget as any).response}), false);
        xhr.open('POST', ENV_CONSTS.attachmentUrl + url, true);
        xhr.upload.onprogress = e => {
          if (e.lengthComputable) {
            uploadProgress = Math.trunc(e.loaded / (e.total || data.file.size) * 100);
          }
          if (showInUI) {
            if (uploadProgress >= 100) {
              this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.fileUploadToBackendDone, data.file.name));
            }
            else {
              this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.uploadProgress, uploadProgress, data.file.name));
            }
          }
        };
        for (const [key, value] of Object.entries(this.getHeaders())) {
          xhr.setRequestHeader(key, value);
        }

        xhr.send(formData);
      });
    };

    uploadMultipleFiles = (url, files, notIdx, showPercentages) => {
      let uploadProgress = 0;

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();

        formData.append('file', files[0]);

        xhr.responseType = 'json';
        xhr.addEventListener('load', event => resolve({data: (event.currentTarget as any).response}), false);
        xhr.addEventListener('error', event => reject({data: (event.currentTarget as any).response}), false);
        xhr.addEventListener('abort', event => reject({data: (event.currentTarget as any).response}), false);
        xhr.open('POST', ENV_CONSTS.attachmentUrl + url, true);
        xhr.upload.onprogress = e => {
          if (e.lengthComputable) {
            uploadProgress = Math.trunc(e.loaded / e.total * 100);
          }
          if (showPercentages) {
            if (uploadProgress >= 100) {
              this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.fileUploadToBackendDone, 'Attachments'));
            }
            else {
              this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.uploadProgress, uploadProgress, 'Attachments'));
            }
          }
        };
        for (const [key, value] of Object.entries(this.getHeaders())) {
          xhr.setRequestHeader(key, value);
        }

        xhr.send(formData);
      });
    };

	getFileIcon = (attachmentName) => {
		const fileExt = attachmentName?.substring(attachmentName?.lastIndexOf('.')+1).toLowerCase() || '';
		switch (fileExt) {
			case 'csv':
			case 'txt':
			case 'pdf':
			case 'png':
			case 'svg':
			case 'html':
			case 'zip':
			case 'json':
			case 'gif':
			case 'xml':
			case 'eml':
				return `images/file-icons/${fileExt}.png`;

			case 'xls':
			case 'xlsx':
				return 'images/file-icons/excel.png';

			case 'jpg':
			case 'jpeg':
				return 'images/file-icons/jpg.png';
				break;

			case 'doc':
			case 'docx':
				return 'images/file-icons/word.png';
				break;

			case 'ppt':
			case 'pptx':
				return 'images/file-icons/pptx.png';
				break;

			default:
				return 'images/file-icons/file.png';
				break;
		}
	}

    getEmailDomain = (email) => {
        if (!email) {
            return '';
        }
        let domainObj = email.split('@');
        if (domainObj.length !== 2) {
            return '';
        }
        return domainObj[1].toLowerCase() || '';
    };

    validateEmail = email => {
        return this.dic.CONSTANTS.EMAIL_REGEX.test(email);
    };

    isValidDomain = domain => {
        if (!domain) {
            return false;
        }

        let re = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
        return re.test(domain);
    };

	validateIPAddress(ip) {
		try {
			let res = IP_REGEX.test(ip);
			if (res && ip.indexOf(':') < 0) {
				ip = ip.split('.').map(itm => parseInt(itm, 10));
				if (ip[0] === 10 ||
					ip[0] === 172 && ip[1] > 15 && ip[1] < 32 ||
					ip[0] === 192 && ip[1] === 168)
					return false;
			}

			return res;
		}
		catch (ex) {
			return false;
		}
	}

    isValidHash = (hash) => {
        return hash.length === 64 || hash.length === 32;
    }

    validateRuleEmails = (emails) => {
        if (!emails) return;

        for (let i = 0; i < emails.length; i++) {
            if (!this.validateEmail(emails[i])) {
                this.ns.showWarnMessage(util.format(this.dic.ERRORS.invalidEmail, emails[i]));
                return false;
            }
        }
        return true;
    };

    isValidUrl = (url) => {
        if (!url) {
            return false;
        }

        try { return Boolean(new URL(url)); }
        catch(e){ return false; }
    }

    validateRuleDomains = (domains) => {
        for (let i = 0; i < domains.length; i++) {
            if (!this.isValidDomain(domains[i])) {
                this.ns.showErrorMessage(util.format(this.dic.ERRORS.invalidDomain, domains[i]));
                return false;
            }
        }
        return true;
    };

    isCIDRAddress = (cidrValue, subnetMinLimit) => {
        try {
            const cidrPattern = /^(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/;

            if (!cidrPattern.test(cidrValue)) {
                return false;
            }

            const [network, subnet] = cidrValue.split('/');

            if (subnet === '0' && network !== '0.0.0.0') {
                return false;
            }
            if (subnetMinLimit && subnet < subnetMinLimit) {
                return false;
            }

            const octets = network.split('.');
            const isValidOctet = (octet: string) => octet !== '' && Number(octet) >= 0 && Number(octet) <= 255;

            if (octets.length !== 4 || !octets.every(isValidOctet)) {
                return false;
            }

            return true;
        }
        catch (ex) {
            console.error('CIDR validation has been failed');
            return false;
        }
    }

    isValidIPAddress = (ip) => {
        try {
            let res = this.dic.CONSTANTS.IP_REGEX.test(ip);
            if (res && ip.indexOf(':') < 0) {
                ip = ip.split('.').map(itm => parseInt(itm, 10));
                if (ip[0] === 10 ||
                    ip[0] === 172 && ip[1] > 15 && ip[1] < 32 ||
                    ip[0] === 192 && ip[1] === 168)
                    return false;
            }

            return res;
        }
        catch (ex) {
            console.error('IP validation has been failed');
            return false;
        }
    }

	isValidPhoneNumber = (numberStr) => {
		const phoneNumberPattern = /^(?:(?:\+\d{1,3}\s*)?\d{1,16}|\d{1,4}-?\d{1,4}-?\d{1,4}-?\d{1,4})$/;

		return phoneNumberPattern.test(numberStr);
	}

	defangeMaliciousLinksFromHtml = (html, maliciousLinks) => {
		// Create a temporary DOM element to parse the HTML string
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = html;

		// Iterate through each malicious link
		maliciousLinks.forEach((maliciousLink) => {
			// Defang the malicious link
			const defangedLink = maliciousLink
				.replace(/http/g, 'hxxp')
				.replace(/\./g, '[.]')
				.replace(/\//g, '[/]');

			// Find all `a` elements with the malicious link as href
			const anchorElements = tempDiv.querySelectorAll(`a[href="${maliciousLink}"]`);

			anchorElements.forEach((anchor) => {
				if (anchor.textContent.includes(maliciousLink)) {
					//replace the malicious link with the defanged link using regex
					anchor.textContent = anchor.textContent.replace(new RegExp(maliciousLink, 'g'), defangedLink);
					// Replace the `a` element with a `span` element
					const span = document.createElement('span');
					span.textContent = defangedLink;
					anchor.parentNode.replaceChild(span, anchor);
				}
				else {
					// If the text content is different, just remove the href attribute and change to span
					const span = document.createElement('span');
					span.textContent = anchor.textContent;
					anchor.parentNode.replaceChild(span, anchor);
				}
			});
		});

		return tempDiv.innerHTML;
	}

	defangeMaliciousLinksFromText = (text, maliciousLinks) => {
		// Iterate through each malicious link
		maliciousLinks.forEach((maliciousLink) => {
			// Defang the malicious link
			const defangedLink = maliciousLink
				.replace(/http/g, 'hxxp')
				.replace(/\./g, '[.]')
				.replace(/\//g, '[/]');

			// Replace the malicious link with the defanged link using regex
			text = text.replace(new RegExp(maliciousLink, 'g'), defangedLink);
		});

		return text;
	}

	separateCountryCodeAndNumber(phoneNumber) {
		// Define a regex pattern to match various country code formats
		const countryCodePattern = /^\s*\+?(\d{1,3})[\s-]*(\d+)/;

		// Use the regex pattern to extract the country code and the rest of the number
		const match = phoneNumber.match(countryCodePattern);

		if (match) {
			// Extracted country code is in match[1], and the rest of the number is in match[2]
			return [match[1], match[2]];
		} else {
			// No country code found, return the original number as the second item
			return [null, phoneNumber];
		}
	}

    hasWKTextSecurity() {
        let x:any = document.getElementsByTagName("input")[0];
        let style:any = x && window.getComputedStyle(x);
        return !!style?.webkitTextSecurity;
    };

    toCapitalize(val) {
        return val.replace(/^./, str => str.toUpperCase());
    };


    dateDiffInDays(date1, date2) {
        return Math.round((date1 - date2) / (1000 *60*60*24));
    };

    dateDiffInMinutes(date1, date2) {
        if (date2 < date1) {
            date2.setDate(date2.getDate() + 1);
        }
        return Math.round((date2 - date1) / (1000 *60));
    };

	sortObjectByValue(obj, isReverse) {
		if (isReverse) {
			return Object.keys(obj)
				.sort(function(a, b){return obj[b] - obj[a]})
				.reduce((a, v) => {
					a[v] = obj[v];
					return a;
				}, {});
		}
		else {
			return Object.keys(obj)
				.sort(function(a, b){return obj[a] - obj[b]})
				.reduce((a, v) => {
					a[v] = obj[v];
					return a;
				}, {});
		}
	}

	getPeriodLabel(period, emailSourcePerPeriod) {
		let dateLabel;
		switch (period) {
			case this.dic.CONSTANTS.trendsPeriod.lastDay.value:
			case this.dic.CONSTANTS.trendsPeriod.last3Days.value:
			case this.dic.CONSTANTS.trendsPeriod.lastWeek.value:
			case this.dic.CONSTANTS.trendsPeriod.lastMonth.value:
			case this.dic.CONSTANTS.trendsPeriod.last3Months.value:
				dateLabel = `${this.dic.CONSTANTS.dayNames[new Date(emailSourcePerPeriod.dateStart).getDay()]} ${new Date(emailSourcePerPeriod.dateStart).getMonth() + 1}/${new Date(emailSourcePerPeriod.dateStart).getDate()}`;
				break;

/*			case this.dic.CONSTANTS.trendsPeriod.lastMonth.value:
				dateLabel = `${this.dic.CONSTANTS.monthNames[new Date(emailSourcePerPeriod.dateStart).getMonth()]} ${new Date(emailSourcePerPeriod.dateStart).getDate()}`;
				const dateEndLabel = `${this.dic.CONSTANTS.monthNames[new Date(emailSourcePerPeriod.dateEnd).getMonth()]} ${new Date(emailSourcePerPeriod.dateEnd).getDate()}`;
				if (dateLabel !== dateEndLabel) {
					dateLabel += ` - ${dateEndLabel}`;
				}
				break;*/

			case this.dic.CONSTANTS.trendsPeriod.lastMonths.value:
				dateLabel = `${this.dic.CONSTANTS.monthNames[new Date(emailSourcePerPeriod.dateStart).getMonth()]} ${new Date(emailSourcePerPeriod.dateStart).getFullYear()}`;
				break;

			default:
				dateLabel = `${this.dic.CONSTANTS.dayNames[new Date(emailSourcePerPeriod.dateStart).getDay()]} ${new Date(emailSourcePerPeriod.dateStart).getMonth() + 1}/${new Date(emailSourcePerPeriod.dateStart).getDate()}`;
				break;
		}
		return dateLabel;
	}

	calcDatesForPeriod(currentSource, period) {
		let sourcePerWeek: any = {};
		sourcePerWeek.dateStart = currentSource.created;

		switch (period) {
			case this.dic.CONSTANTS.trendsPeriod.lastDay.value:
			case this.dic.CONSTANTS.trendsPeriod.last3Days.value:
			case this.dic.CONSTANTS.trendsPeriod.lastWeek.value:
			case this.dic.CONSTANTS.trendsPeriod.lastMonth.value:
			case this.dic.CONSTANTS.trendsPeriod.last3Months.value:
				sourcePerWeek.dateEnd = currentSource.created;
				break;

/*			case this.dic.CONSTANTS.trendsPeriod.lastMonth.value:
				let dayWeekStart = new Date(currentSource.created).getDay();
				sourcePerWeek.dateStart = new Date(sourcePerWeek.dateStart).setDate(new Date(sourcePerWeek.dateStart).getDate() - dayWeekStart + 1);
				sourcePerWeek.dateStart = new Date(sourcePerWeek.dateStart).toISOString();
				sourcePerWeek.dateEnd = new Date(sourcePerWeek.dateStart);
				sourcePerWeek.dateEnd = new Date(sourcePerWeek.dateEnd).setDate(new Date(sourcePerWeek.dateEnd).getDate() + 6);
				sourcePerWeek.dateEnd = new Date(sourcePerWeek.dateEnd).toISOString();
				break;*/

			case this.dic.CONSTANTS.trendsPeriod.lastMonths.value:
				sourcePerWeek.dateEnd = new Date(sourcePerWeek.dateStart);
				sourcePerWeek.dateEnd = new Date(sourcePerWeek.dateEnd).setDate(new Date(sourcePerWeek.dateEnd).getMonth() + 1);
				sourcePerWeek.dateEnd = new Date(sourcePerWeek.dateEnd).toISOString();
				break;

			default:
				sourcePerWeek.dateEnd = currentSource.created;
				break;
		}

		return sourcePerWeek;
	}

	isSamePeriod(period, date1, date2) {
		switch (period) {
			case this.dic.CONSTANTS.trendsPeriod.lastDay.value:
			case this.dic.CONSTANTS.trendsPeriod.last3Days.value:
			case this.dic.CONSTANTS.trendsPeriod.lastWeek.value:
			case this.dic.CONSTANTS.trendsPeriod.lastMonth.value:
			case this.dic.CONSTANTS.trendsPeriod.last3Months.value:
				return this.checkDatesInSameDay(date1, date2);

/*			case this.dic.CONSTANTS.trendsPeriod.lastMonth.value:
				return this.checkDatesInSameDay(date1, date2);*/

			case this.dic.CONSTANTS.trendsPeriod.lastMonths.value:
				return this.checkDatesInSameMonth(date1, date2);

			default:
				return this.checkDatesInSameDay(date1, date2);
		}
	}

	checkDatesInSameMonth(date1, date2) {
		return new Date(date1).getMonth() === new Date(date2).getMonth();
	}

	checkDatesInSameWeek(date1, date2) {
		if (!date2) {
			return false;
		}
		if (new Date(date1).getDay() === new Date(date2).getDay() && new Date(date1).getMonth() === new Date(date2).getMonth()) {
			return true;
		}
		return date1 < date2;
	}

	checkDatesInSameDay(date1, date2) {
		return new Date(date1).getDate() === new Date(date2).getDate() && new Date(date1).getMonth() === new Date(date2).getMonth();
	}


	parseDate = (dateStr) => {
        const month = parseInt(dateStr.substring(0, 2));
        const day = parseInt(dateStr.substring(3, 5));
        const year = parseInt(dateStr.substring(6, 10));

        //convert to UTC time: new Date(Date.UTC(year, month-1, day))
        return new Date(year, month-1, day);
    };

	areArraysEqual(array1, array2) {
		// Check if arrays have the same length
		if (array1.length !== array2.length) {
			return false;
		}

		// Check if each element is equal
		for (let i = 0; i < array1.length; i++) {
			if (array1[i] !== array2[i]) {
				return false;
			}
		}

		// Arrays are equal
		return true;
	}

    sortTable(table, orderBy) {
        let sign = orderBy.charAt(0);
        let field = orderBy;
        if (sign === '-') {
            field = orderBy.substring(1);
        }
        table.sort((a, b) => {
            if (b[field] === a[field]) {
                return 0;
            }
            if (sign === '-') {
                return b[field] > a[field] ? 1 : -1;
            }
            return a[field] > b[field] ? 1 : -1;
        });
        return table;
    };

    saveTableFilter (tableName, filters) {
        localStorage['flt.'+tableName] = JSON.stringify(filters);
    };

    getTableFilter (tableName) {
        let filters = localStorage['flt.'+tableName];
        return filters && JSON.parse(filters);
    };

    clearTableFilter (tableName) {
        localStorage.removeItem('flt.'+tableName);
    };

    equals(a, b) {
        //angular.equals
        //_.isEqual(_.omit(a, ['$$hashKey']), _.omit(b, ['$$hashKey']));
        //_.isMatchWith(a, b, (a,b,key) => key === '$$hashKey' || undefined);
        return _.isEqual(a,b);
    };

    exportCsv(csvString, fileName) {
        if (!csvString) {
            return;
        }

        try {
            const blob = new Blob([csvString], {type: 'text/csv'});
            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, fileName || 'data.csv');
            }
            else {
                let a = $('<a/>', {
                    style: 'display:none',
                    href: 'data:application/octet-stream;base64,' + btoa(unescape(encodeURIComponent(csvString))),
                    download: fileName || 'data.csv',
                }).appendTo('body');
                a[0].click();
                a.remove();
            }
        }
        catch(ex) {
            console.error(ex);
        }
    };

    getCountryCodeFlag = (countryCode) => {
        let flagIcon;
        if (!countryCode) {
            flagIcon = 'us.png';
            return flagIcon;
        }

        switch (countryCode) {
            case '1':
                flagIcon = 'us.png';
                break;
            case '31':
                flagIcon = 'nl.png';
                break;
            case '41':
                flagIcon = 'ch.png';
                break;
            case '34':
                flagIcon = 'es.png';
                break;
            case '44':
                flagIcon = 'uk.png';
                break;
            case '47':
                flagIcon = 'no.png';
                break;
            case '55':
                flagIcon = 'br.png';
                break;
            case '61':
                flagIcon = 'au.png';
                break;
            case '852':
                flagIcon = 'hk.png';
                break;
            case '972':
                flagIcon = 'il.png';
                break;
            case '91':
                flagIcon = 'in.png';
                break;
            case '64':
                flagIcon = 'nz.png';
                break;
            case '27':
                flagIcon = 'za.png';
                break;
        }
        return flagIcon;
    };

    formatPhone = (pinCodeData) => {
        pinCodeData.phone.phone_number_after = pinCodeData.phone.phone_number.replace(/-/g, '');

        let indexNotZero = 0;
        for (; indexNotZero < pinCodeData.phone.phone_number_after.length && pinCodeData.phone.phone_number_after[indexNotZero] === "0"; indexNotZero++) ;
        pinCodeData.phone.phone_number_after = pinCodeData.phone.phone_number_after.substring(indexNotZero);

        switch (pinCodeData.phone.country_code) {
            case '1':
                pinCodeData.phone_number_after = '(' + pinCodeData.phone.phone_number_after.substring(0, 3) + ') ' + pinCodeData.phone.phone_number_after.substring(3, 6) + '-' + pinCodeData.phone.phone_number_after.substring(6, 10);
                break;
            case '972':
                pinCodeData.phone_number_after = '(' + pinCodeData.phone.phone_number_after.substring(0, 3) + ') ' + pinCodeData.phone.phone_number_after.substring(3, 5) + '-' + pinCodeData.phone.phone_number_after.substring(5, 9);
                break;

            default:
                pinCodeData.phone_number_after = pinCodeData.phone.phone_number_after;
        }
    };

    downloadClientFile (data, strFileName, strMimeType) {
        this.download(data, strFileName, strMimeType);
    };

    /**
     *
     * @param file - CSV file
     * @param headersToSearch - list of headers to search in file. note: validation and duplicates will be applied only to the first header
     * @param optionalHeaders - list of optional headers to search in file.
     * @param regex - e.g. dic.CONSTANTS.EMAIL_REGEX
     * @param validationFunctions - list of validation functions e.g. generalthis.validateEmail, generalthis.isValidDomain
     * @param cb
     * @returns {*} - returns an object(headers as keys) of arrays
     */
    readCsv (file, headersToSearch, optionalHeaders, regex, validationFunctions, cb) {
        if (!file.name || !file.name.endsWith('.csv')) {
            this.ns.showWarnMessage(this.dic.ERRORS.onlyCsvAllowed);
            return cb(this.dic.ERRORS.onlyCsvAllowed);
        }
        let isValid = this.checkUploadFileSize(file.size, file.name);
        if (isValid) {
            Papa.parse(file, {
                complete: (results) => {
                    if (!results || !results.data) {
                        this.ns.showWarnMessage(this.dic.ERRORS.onlyCsvAllowed);
                        return cb(this.dic.ERRORS.onlyCsvAllowed);
                    }

                    const csvHeaders = (results.data[0] || []).map(itm => (itm || '').trim());
                    let skipFirstRow = true, optionalHeaderExist = false;
                    let res = {}, headerIndex, headersIndices = [], optionalHeadersIndices = [];

                    for (let i = 0; i < headersToSearch.length; i++) {
                        headerIndex = _.findIndex<any>(csvHeaders, h => h.toLowerCase() === headersToSearch[i]);
                        if (headerIndex < 0) {
                            //retest by regex only for the first header
                            if (i === 0) {
                                skipFirstRow = false;
                                if (regex) {
                                    headerIndex = _.findIndex<any>(csvHeaders, h => regex.test(h));
                                }
                            }
                            if (headerIndex < 0) {
                                const message = util.format(this.dic.ERRORS.invalidDomainsCsv, headersToSearch[i]);
                                this.ns.showWarnMessage(message);
                                return cb(message);
                            }
                        }
                        //save the index for this header and init this header array in result. e.g. res['email'] = []
                        headersIndices.push(headerIndex);
                        res[headersToSearch[i]] = [];
                    }

                    if (optionalHeaders && optionalHeaders.length) {
                        for (let i = 0; i < optionalHeaders.length; i++) {
                            headerIndex = _.findIndex<any>(csvHeaders, h => h.toLowerCase() === optionalHeaders[i].toLowerCase());
                            if (headerIndex < 0) continue;
                            //save the index for this header and init this header array in result. e.g. res['email'] = []
                            optionalHeadersIndices.push(headerIndex);
                            res[optionalHeaders[i]] = [];
                            optionalHeaderExist = true;
                        }
                    }

                    // Iterate over results from file
                    for (let i = skipFirstRow && 1 || 0; i < results.data.length; i++) {
                        // Iterate over all needed headers
                        for (let j = 0; j < headersIndices.length; j++) {
                            if (results.data[i] && results.data[i][headersIndices[j]]) {
                                // for first header(j:0), apply all validation functions
                                if (j === 0) {
                                    let allValidationsInvalid = true;
                                    for (let k = 0; k < validationFunctions.length; k++) {
                                        //case one of the validation functions is vaild for this value - stop validations check
                                        if (validationFunctions[k](results.data[i][headersIndices[j]])) {
                                            allValidationsInvalid = false;
                                            k = validationFunctions.length;
                                        }
                                    }
                                    // case all validation functions are invaild for this value - skip this value
                                    if (allValidationsInvalid) {
                                        continue;
                                    }
                                }
                                if (res[headersToSearch[j]]) {
                                    res[headersToSearch[j]].push(results.data[i][headersIndices[j]]);
                                }
                            }
                        }
                        if (optionalHeaderExist) {
                            //Iterate over all optional headers
                            for (let j = 0; j < optionalHeadersIndices.length; j++) {
                                //Dont change != here. it is in purpose to allow "" to pass the condition
                                if (results.data[i] && results.data[i][optionalHeadersIndices[j]] != null) {
                                    if (res[optionalHeaders[j]]) {
                                        res[optionalHeaders[j]].push(results.data[i][optionalHeadersIndices[j]]);
                                    }
                                }
                            }
                        }
                    }
                    cb(null, res);
                }
            });
        }
    };

	stringToSha256(string: string) {
		if (!string) {
			return '';
		}
		return shaJs('sha256').update(string).digest('hex')
	};

    fileHash (file, cb) {
        const reader = new FileReader();

        reader.onload = (e) => {
            const rawData = Buffer.from(<any>e.target.result);
            cb(shaJs('sha256').update(rawData).digest('hex'));
        };

        reader.readAsArrayBuffer(file);
    };

    getGMT() {
        return new Date().getTimezoneOffset() / -60;
    };

    hasDuplicates = (array) => {
        return (new Set(array)).size !== array.length;
    };

    addOrdinalNumberSuffix = (n) => {
        return String(n + ["st","nd","rd"][((n+90)%100-10)%10-1]||n + "th");
    };

    openLocation (ll) {
        let link = "https://www.google.com/maps/search/?api=1&query=" + ll[0] + "," + ll[1];
        window.open(link, '_blank');
    };

    getSummernote() {
        return $('#summernote');
    };

    private updateDefaultCountry() {
        // the first item has wildcard: "Australia/*"
        const timeZonesByCountry = {
            'au': ['Australia'],
            'br': ['Brazil','America/Fortaleza','America/Sao_Paulo','America/Rio_Branco','America/Noronha','America/Manaus'],
            'il': ['','Israel','Asia/Jerusalem'],
            'gb': ['','GMT','GMT0','GMT+0','GMT-0','Etc/GMT','Etc/GMT0','Etc/GMT-0','Etc/GMT+0','GB','GB-Eire','Greenwich','Europe/Belfast','Europe/London'],
            'ch': ['','Europe/Zurich'],
            'nl': ['','Europe/Amsterdam','Europe/Berlin'],
            'hk': ['','Asia/Hong_Kong','PRC','Asia/Shanghai','Asia/Urumqi','Asia/Kashgar','Asia/Harbin','Asia/Chungking','Asia/Chongqing'],
            'no': ['','Europe/Oslo'],
            'in': ['','Asia/Kolkata'],
            'es': ['','Europe/Madrid','Atlantic/Canary','Africa/Ceuta'],
            'nz': ['','NZ','NZ-CHAT','Antarctica/McMurdo','Antarctica/South_Pole','Pacific/Auckland','Pacific/Chatham'],
            'za': ['','Africa/Johannesburg','Africa/Mbabane','Africa/Maseru']
        };

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const countryCode = Object.entries(timeZonesByCountry).find(([key, value]) => {
            return value[0] && timeZone && timeZone.includes(value[0]) || value.includes(timeZone);
        });

        this.defaultCountry = countryCode && countryCode[0] || 'us';

        let countryData = $.fn['intlTelInput'].getCountryData();
        this.defaultCode = countryData.find(itm => itm.iso2 === this.defaultCountry);
        if (this.defaultCode && this.defaultCode.dialCode) {
            this.defaultCode = this.defaultCode && this.defaultCode.dialCode;
            this.country_code = '+'+this.defaultCode;
        }
        else {
            this.defaultCode = this.country_code.substring(1);
        }
    }

    private download(data, strFileName, strMimeType) {

        let self:any = window, // this script is only for browsers anyway...
            defaultMime = "application/octet-stream", // this default mime also triggers iframe downloads
            mimeType = strMimeType || defaultMime,
            payload = data,
            url = !strFileName && !strMimeType && payload,
            anchor:any = document.createElement("a"),
            toString = function(a){return String(a);},
            myBlob = (self.Blob || self.MozBlob || self.WebKitBlob || toString),
            fileName = strFileName || "download",
            blob,
            reader;

        myBlob= myBlob.call ? myBlob.bind(self) : Blob ;

        if (String(this)==="true"){ //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
            payload=[payload, mimeType];
            mimeType=payload[0];
            payload=payload[1];
        }


        if (url && url.length < 2048){ // if no filename and no mime, assume a url was passed as the only argument
            fileName = url.split("/").pop().split("?")[0];
            anchor.href = url; // assign href prop to temp anchor
            if (anchor.href.indexOf(url) !== -1){ // if the browser determines that it's a potentially valid url path:
                var ajax=new XMLHttpRequest();
                ajax.open( "GET", url, true);
                ajax.responseType = 'blob';
                ajax.onload= (e) => {
                    this.download((<any>e.target).response, fileName, defaultMime);
                };
                setTimeout(function(){ ajax.send();}, 0); // allows setting custom ajax headers using the return:
                return ajax;
            } // end if valid url?
        } // end if url?

        //go ahead and download dataURLs right away
        if (/^data\:[\w+\-]+\/[\w+\-]+[,;]/.test(payload)) {

            if (payload.length > (1024*1024*1.999) && myBlob !== toString ){
                payload=dataUrlToBlob(payload);
                mimeType=payload.type || defaultMime;
            } else{
                return navigator['msSaveBlob'] ?  // IE10 can't do a[download], only Blobs:
                    navigator['msSaveBlob'](dataUrlToBlob(payload), fileName) :
                    saver(payload) ; // everyone else can save dataURLs un-processed
            }

        }//end if dataURL passed?

        blob = payload instanceof myBlob ?
            payload :
            new myBlob([payload], {type: mimeType}) ;


        function dataUrlToBlob(strUrl) {
            var parts= strUrl.split(/[:;,]/),
                type= parts[1],
                decoder= parts[2] === "base64" ? atob : decodeURIComponent,
                binData= decoder( parts.pop() ),
                mx= binData.length,
                i= 0,
                uiArr= new Uint8Array(mx);

            for(i;i<mx;++i) uiArr[i]= binData.charCodeAt(i);

            return new myBlob([uiArr], {type: type});
        }

        function saver(url, winMode=false){

            if ('download' in anchor) { //html5 A[download]
                anchor.href = url;
                if (fileName[0] === '.') {
                    fileName = `file${fileName}`;
                }
                anchor.setAttribute("download", fileName);
                anchor.className = "download-app-link";
                anchor.innerHTML = "downloading...";
                anchor.style.display = "none";
                document.body.appendChild(anchor);
                setTimeout(function() {
                    anchor.click();
                    document.body.removeChild(anchor);
                    if (winMode===true){setTimeout(function(){ self.URL.revokeObjectURL(anchor.href);}, 250 );}
                }, 66);
                return true;
            }

            // handle non-a[download] safari as best we can:
            if (/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
                url=url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
                if (!window.open(url)){ // popup blocked, offer direct download:
                    if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")){ location.href=url; }
                }
                return true;
            }

            //do iframe dataURL download (old ch+FF):
            var f:any = document.createElement("iframe");
            document.body.appendChild(f);

            if (!winMode){ // force a mime that will download:
                url="data:"+url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
            }
            f.src=url;
            setTimeout(function(){ document.body.removeChild(f); }, 333);

        }//end saver


        if (navigator['msSaveBlob']) { // IE10+ : (has Blob, but not a[download] or URL)
            return navigator['msSaveBlob'](blob, fileName);
        }

        if (self.URL){ // simple fast and modern way using Blob and URL:
            saver(self.URL.createObjectURL(blob), true);
        }else{
            // handle non-Blob()+non-URL browsers:
            if (typeof blob === "string" || blob.constructor===toString ){
                try{
                    return saver( "data:" +  mimeType   + ";base64,"  +  self.btoa(blob)  );
                }catch(y){
                    return saver( "data:" +  mimeType   + "," + encodeURIComponent(blob)  );
                }
            }

            // Blob but not URL support:
            reader=new FileReader();
            reader.onload=function(e){
                saver(this.result);
            };
            reader.readAsDataURL(blob);
        }
        return true;
    }

    private changeFavicon(src) {
        //random here avoids caching of old favicon
        src = src+'?='+Math.random();
        let link:any = document.createElement('link'), oldLink:any = document.getElementById('dynamic-favicon');
        link.id = 'dynamic-favicon';
        link.rel = 'icon';
        link.href = src;

        if (oldLink) {
            document.head.removeChild(oldLink);
        }

        document.head.appendChild(link);
    }

    private initSummernote() {
        //https://jsfiddle.net/8fm5vq50/17/
        let ProtectButton = (context) => {
            let ui = (<any>$).summernote.ui;
            let button = ui.button({
                contents: '<i class="fa fa-lock"/> Protect',
                tooltip: 'Hide the content in the mailbox',
                container: 'body',
                click: () => {
                    function createCustomLink(object) {
                        let linkNode = $('<code class="protect">' + (object || 'CHANGE ME') + '</code>');
                        linkNode.attr('data-attribute-value', object['code']);
                        return linkNode[0]
                    }

                    let selected = window.getSelection().toString();
                    context.invoke('editor.insertNode', createCustomLink(selected));
                    context.invoke('editor.pasteHTML', '&zwnj;')
                }
            });
            return button.render();
        };

        let RTLButton = (direction, context) => {
            let ui = (<any>$).summernote.ui;
            let button = ui.button({
                contents: '<i class="fa fa-toggle-'+(direction === 'rtl' ? 'right' : 'left')+'"/> '+direction,
                tooltip: direction === 'rtl' ? 'Right-to-Left' : 'Left-to-Right',
                container: 'body',
                click: (e) => {
                    context.invoke('editor.justify' + (direction === 'rtl' ? 'Right' : 'Left'));

                    let range = window.getSelection().getRangeAt(0);
                    let startContainer:any = range.startContainer;
                    let endContainer:any = range.endContainer;

                    if (startContainer === range.commonAncestorContainer) {
                        while (startContainer && (!startContainer.style || !startContainer.style.textAlign)) {
                            startContainer = startContainer.parentNode;
                        }
                        if (startContainer) {
                            startContainer.style.direction = direction;
                        }
                    }
                    else {
                        let commons = Array.prototype.slice.call(range.commonAncestorContainer.childNodes);
                        while (commons.indexOf(startContainer) <= 0) startContainer = startContainer.parentNode;
                        while (commons.indexOf(endContainer) <= 0) endContainer = endContainer.parentNode;

                        do {
                            startContainer.style.direction = direction;
                            startContainer = startContainer === endContainer ? null : startContainer.nextSibling;
                        } while (startContainer);
                    }
                }
            });

            return button.render();
        };

        let BodyBackgroundButton = (context) => {
            let ui = (<any>$).summernote.ui;
            let button = ui.button({
                contents: '<i class="fa fa-paw"/> Background',
                tooltip: 'Select the body background color',
                container: 'body',
                data: {
                    toggle: 'dropdown',
                }
            });

            let items = `<div class="note-palette">
                            <div class="note-palette-title">Body Background Color</div>
                            <div>
                                <button type="button" class="note-color-reset btn btn-light btn-default" data-event="backColor" data-value="transparent">Transparent</button>
                            </div>
                            <div class="note-holder" data-event="backColor"><!-- back colors --></div>
                        </div>`;

            let dropDown = ui.dropdown({
                items: items,
                callback: ($dropdown) => {
                    $dropdown.find('.note-holder').each((idx, item) => {
                        const $holder = $(item);
                        $holder.append(ui.palette({
                            colors: context.options.colors,
                            colorsName: context.options.colorsName,
                            eventName: $holder.data('event'),
                            container: context.options.container,
                            tooltip: context.options.tooltip,
                        }).render());
                    });
                },
                click: (event) => {
                    event.stopPropagation();
                    let $button = $(event.target);
                    let eventName = $button.data('event');
                    let value = $button.attr('data-value');

                    if (eventName === 'backColor') {
                        if (value === 'transparent') {
                            value = this.summernoteOptions.defaultBackColor;
                        }

                        context.layoutInfo.editable.css('background-color', value);
                        //context.memo('custom.backColor', value);
                    }
                },
            });

            return ui.buttonGroup({
                className: 'note-color',
                children: [button, dropDown]
            }).render();
        };

        let linkPopover = (<any>$).summernote.options.modules.linkPopover;
        let link_init = linkPopover.prototype.initialize;
        linkPopover.prototype.initialize = function() {
            let self = this;

            this.events = {
                'summernote.keyup summernote.mouseup summernote.change': () => {
                    self.update();

                    if (self.$popover[0].style.display === 'block') {
                        let rect = self.context.layoutInfo.editor[0].getBoundingClientRect();
                        let top = self.$popover.offset().top;
                        if (top < rect.top + 40 || top > rect.bottom - 40) {
                            self.hide();
                        }
                    }
                },
                'summernote.disable summernote.dialog.shown summernote.scroll': () => {
                    self.hide();
                },
            };

            link_init.apply(this);
        };

        let imagePopover = (<any>$).summernote.options.modules.imagePopover;
        let image_init = imagePopover.prototype.initialize;
        imagePopover.prototype.initialize = function() {
            let editor = this.context.modules.editor;
            editor.resize = editor.wrapCommand((value) => {
                const $target = $(editor.restoreTarget());
                value = parseFloat(value);

                $target.css({height: '', width: ''});
                $target.removeAttr('height');
                $target.removeAttr('width');

                if (value && value < 1) {
                    $target.attr('width', value * $target.width());
                }

                /*let srcCanvas:any = document.createElement('canvas');
                srcCanvas.width = width;
                srcCanvas.height = height;
                let ctx = srcCanvas.getContext('2d');
                ctx.drawImage($target[0], 0, 0, width, height);

                let destCanvas:any = document.createElement('canvas');
                destCanvas.width = value * width;
                destCanvas.height = value * height;

                pica.resize(srcCanvas, destCanvas, {
                    width: width,
                    height: height,
                    toWidth: value * width,
                    toHeight: value * height
                }).then(result => {
                    let base64Canvas = result.toDataURL("image/png");
                    $target.attr('src', base64Canvas);
                    $target.css({height: '', width: ''});
                }, err => {
                    console.error(err);
                });*/
            });

            image_init.apply(this);
        };

        this.summernoteOptions = {
            height: 225,
            buttons: {
                background: BodyBackgroundButton,
                protect: ProtectButton,
                rtl: RTLButton.bind(this, 'ltr'),
                ltr: RTLButton.bind(this, 'rtl')
            },
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['strikethrough', 'superscript', 'subscript']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture']],
                ['custom', ['protect', 'rtl', 'ltr']],
                ['view', ['fullscreen', 'codeview']],
                ['name', ['placeholder']],
                ['style2', ['style']],
                ['fontsize', ['fontname', 'fontsize']],
                ['color', ['forecolor','backcolor']],
                ['custom2', ['background']],
            ],
            /*codemirror: {
                theme: 'monokai'
            },*/
            defaultBackColor: 'white',
            placeholder: 'Your email body here...',
            /*callbacks: {
                onCustomBackColor(value) {
                    this.layoutInfo.editable.css('background-color', value);
                    this.memo('custom.backColor', value);
                }
            }*/
        };

        //summernote.summernote('memo', 'custom.backColor', value);
        //summernote.summernote('triggerEvent', 'custom.back.color', '#ffff');

        $(document).on('focusout', '.note-codable', function() {
            if ((<string>$(this).val()).match(/<script[^<>]*?>[\s\S]+?<\/script>/gi)) {
                $(this).val((<string>$(this).val()).replace(/<script[^<>]*?>[\s\S]+?<\/script>/gi, ''));
            }
        });

        $(document).on('mouseup', '.note-color-reset', (evt) => {
            setTimeout(() => {
                $(evt.target).closest('.note-btn-group').find('.note-recent-color').css('background-color', this.summernoteOptions.defaultBackColor);
            });
        })

        //make new line to be <p><br/></p>
        //$.summernote.dom.emptyPara = "<div><br/></div>";
    }

    // a calculation to avoid too fat bars in charts
    getOptimalBarWidth(seriesLength) {
        // f(x) = c / (1 + a*exp(-x*b)) -> LOGISTIC GROWTH MODEL
        //
        // 20: minimum width should be close to 20 (when only one item)
        // 20+60: maximum width should be close 80
        // 40 and 3: the a and b from the function, selected after testing some cenarios from seriesLength from 1 to 12
        if (seriesLength < 1) {
            return 0;
        }
        return (25 + (60 / (1 + 30*Math.exp(-seriesLength))));
    }

	trackByIndex(index, item) {
		return index;
	}
	returnZero() {
		return 0
	}

	// checks if the text in a div or any other text-containing element overflows the container
	setExpandableRecord = (elem:HTMLElement, isExpandable) => {
		if (isExpandable && !elem.classList.contains('can-expand')) {
			elem.classList.add('can-expand');
		}
		else if (!isExpandable && elem.classList.contains('can-expand')) {
			elem.classList.remove('can-expand');
		}
	}

	getAllTimeZones() {
		return moment.tz.names().map(zone => ({
			label: `(${moment.tz(zone).format('Z')}) ${zone}`,
			zone
		}));
	}

	roundToNearestFiveMinutes(date) {
		const roundedDate = new Date(date);

		roundedDate.setSeconds(0, 0); // Set seconds and milliseconds to zero for precise calculations

		const currentMinutes = roundedDate.getMinutes();

		roundedDate.setMinutes(currentMinutes + 5 - (currentMinutes % 5));

		return roundedDate;
	}

	copyToClipboard = (copyTxt) => {
		this.clipboard.copy(copyTxt);
	}

	triggerContactUsForm = () => {
		this.triggerContactUsFormSubj.next({});
	}

	cleanEmptyParams = (paramsObj) => {
		if (typeof paramsObj === 'object') {
			for (let key in paramsObj) {
				if (paramsObj.hasOwnProperty(key) && (_.isNil(paramsObj[key]) || paramsObj[key] === '')) {
					delete paramsObj[key];
				}
			}
		}
	}

	// get "table-filter" filter values sorted by positive and negative filters
	splitTableFilters = (filters) => {
		return {
			positiveFilters: Object.fromEntries(Object.entries(filters).map(([key, value]:any) => [key, value.filter(str => typeof str !== 'string' || !str.startsWith('-'))]).filter(([, value]) => value.length)),
			negativeFilters: Object.fromEntries(Object.entries(filters).map(([key, value]:any) => [key, value.filter(str => typeof str === 'string' && str.startsWith('-')).map(str => str.replace('-', ''))]).filter(([, value]) => value.length))
		}
	}

	// utility:
	multiplyArrayRecordsForPerformanceTest = (array, multiplier) => {
		const newArray = [];
		for (let i = 0; i < multiplier ; i++) {
			array.forEach((record, index) => {
				newArray.push({...record, pTestIndex: index.toString() + multiplier.toString()})
			});
		}

		return newArray;
	}

	toggleItemInArray = (array, item) => {
		return !!_.find(array, i => _.isEqual(i, item)) ? _.without(array, item) : _.concat(array, item);
	}

	// get country flag for international-phone-number
    getUserCountryCodeStr(userCountryCode=null) {
		const countryData = $.fn['intlTelInput'].getCountryData();
		if (userCountryCode) {
			const countriesOfCode = _.filter(countryData, itm => itm.dialCode === userCountryCode.replace(/^\++/, ''));
			return countriesOfCode ? _.find(countriesOfCode, country => this.availableCountries.includes(country.iso2))?.iso2 : this.defaultCountry;
		}
		else {
			return this.defaultCountry;
		}
	}

	getUserCountryCodeNumber(defaultCountry) {
		const countryData = $.fn['intlTelInput'].getCountryData();
		return (countryData.find(itm => itm.iso2 === defaultCountry)?.dialCode)?.replace(/^\++/, '');
	}

	getTabName = () => {
		return _.last(this.router.url.split('/')).split('?')[0];
	}
}
