import {DICTIONARY} from '../dictionary';
import {GeneralService} from "./generalService";
import {Injectable} from "@angular/core";
import _ from "lodash";


@Injectable({
	providedIn: 'root'
})
export class InboundTrendsService {
	dic = DICTIONARY;

	constructor(public gs:GeneralService) {}

	parseTrendsData(userInfo, emails, currentQuery) {
		let trendsPeriod: any = this.getTrendsPeriodForGraphs(currentQuery);

		const trendsData = {
			quarantined: {
				labels: [],
				data: [[]],
				series: ["Quarantined"],
			},
			general: {
				malicious: 0,
				suspicious: 0,
				spam: 0,
				graymail: 0,
				external: 0
			},
			detectionType: {
				labels: [],
				data: []
			},
			domain: {
				labels: [],
				data: []
			},
			mailbox: {
				labels: [],
				data: [[], [], []],
				series: ['Malicious', 'Suspicious', 'Spam']
			},
			inboundStats: {
				total: 0,
				labels: [],
				data: [[], [], [], []],
				series: ['Malicious', 'Suspicious', 'Spam', 'Graymail'],
			}
		};

		// @ts-ignore
		emails.sort((a, b) => new Date(a.created) - new Date(b.created));

		const generalEmailSource = {
			detectionType: {},
			domain: {},
			mailbox: {},
		};

		let emailSourcePerPeriod = {quarantined: {}, dateStart: false, dateEnd: undefined};

		emails.forEach((currentSource) => {
			const tpStatus = (currentSource.malicious.status_user_reported || currentSource.malicious.status).toLowerCase();
			currentSource.malicious.status = tpStatus;
			currentSource.malicious.tpResults.status = tpStatus; // TODO: remove


			this.addGeneralCategory(currentSource.malicious, trendsData);
			if (currentSource.malicious.status === 'external') {
				return;
			}

			trendsData.inboundStats.total += 1;

			this.getTrendsGeneralResult(userInfo, currentSource, currentSource.malicious.tpResults, generalEmailSource);

			if (!emailSourcePerPeriod.dateStart) {
				emailSourcePerPeriod = this.initEmailSourcePerPeriod(currentSource, trendsPeriod);
				emailSourcePerPeriod.quarantined = {
					stillQuarantined: 0
				};
			}

			if (this.gs.isSamePeriod(trendsPeriod, currentSource.created, emailSourcePerPeriod.dateEnd)) {
				this.getTrendsPeriodResults(currentSource, emailSourcePerPeriod);
				this.getQuarantinedEmailAdminResult(currentSource.malicious, emailSourcePerPeriod);
			}
			else {
				this.addThreatStatusData(trendsData, emailSourcePerPeriod, trendsPeriod);
				this.addThreatsCategoryData(trendsData, emailSourcePerPeriod, trendsPeriod);

				emailSourcePerPeriod = this.initEmailSourcePerPeriod(currentSource, trendsPeriod);
				emailSourcePerPeriod.quarantined = {
					stillQuarantined: 0
				};
				this.getTrendsPeriodResults(currentSource, emailSourcePerPeriod);
				this.getQuarantinedEmailAdminResult(currentSource.malicious, emailSourcePerPeriod);
			}
		});

		this.addThreatStatusData(trendsData, emailSourcePerPeriod, trendsPeriod);
		this.addThreatsCategoryData(trendsData, emailSourcePerPeriod, trendsPeriod);

		this.addGeneralTrendsGraphData(trendsData, generalEmailSource);

		this.sortMailboxes(trendsData);
		this.sortDomains(trendsData);

		return trendsData;
	}

	getTrendsPeriodForGraphs(currentQuery) {
		let trendsPeriod = currentQuery.period;
		if (currentQuery.period === this.dic.CONSTANTS.trendsPeriod.range.value && currentQuery.range) {
			const days = this.gs.dateDiffInDays(currentQuery.range.end, currentQuery.range.start);
			//35 = 7 days * 5 weeks - limit graph to 5 weeks
			if (days >= 90) {
				trendsPeriod = this.dic.CONSTANTS.trendsPeriod.lastMonths.value;
			}
			else if (days >= 7) {
				trendsPeriod = this.dic.CONSTANTS.trendsPeriod.lastWeek.value;
			}
			else {
				trendsPeriod = this.dic.CONSTANTS.trendsPeriod.lastDay.value;
			}
		}
		return trendsPeriod;
	}

	initEmailSourcePerPeriod(currentSource, period) {
		const emailSourcePerPeriod = this.gs.calcDatesForPeriod(currentSource, period);
		emailSourcePerPeriod.malicious = 0;
		emailSourcePerPeriod.suspicious = 0;
		emailSourcePerPeriod.spam = 0;
		emailSourcePerPeriod.graymail = 0;

		emailSourcePerPeriod.detectionType = {};

		return emailSourcePerPeriod;
	}

	getQuarantinedEmailAdminResult(maliciousObj, emailsStats) {
		switch (maliciousObj.status) {
			case this.dic.CONSTANTS.tpRule.name.malicious:
				emailsStats.malicious++;
				break;

			case this.dic.CONSTANTS.tpRule.name.suspicious:
				emailsStats.suspicious++;
				break;

			case this.dic.CONSTANTS.tpRule.name.spam:
				emailsStats.spam++;
				break;

			case this.dic.CONSTANTS.tpRule.name.graymail:
				emailsStats.graymail++;
				break;
		}
	}

	sortMailboxes(trendsData) {
		if (!trendsData.mailbox.labels.length) {
			return;
		}

		let mailboxesAttackMap = [];
		for (let i = 0; i < trendsData.mailbox.labels.length; i++) {
			mailboxesAttackMap[i] = {mailbox: trendsData.mailbox.labels[i], counter: 0, index: i};
			for (let j = 0; j < trendsData.mailbox.series.length; j++) {
				mailboxesAttackMap[i].counter += trendsData.mailbox.data[j][i];
			}
		}

		mailboxesAttackMap = mailboxesAttackMap.sort((a, b) => b.counter - a.counter);
		let sortMailboxesInfo = {data: [[],[],[]], labels: []};
		// return top 100 mailboxes
		const numberOfMailboxes = Math.min(trendsData.mailbox.labels.length, 100);
		for (let i = 0; i < numberOfMailboxes; i++) {
			sortMailboxesInfo.labels.push(mailboxesAttackMap[i].mailbox);
			sortMailboxesInfo.data[0].push(trendsData.mailbox.data[0][mailboxesAttackMap[i].index]);
			sortMailboxesInfo.data[1].push(trendsData.mailbox.data[1][mailboxesAttackMap[i].index]);
			sortMailboxesInfo.data[2].push(trendsData.mailbox.data[2][mailboxesAttackMap[i].index]);
		}
		trendsData.mailbox.data = sortMailboxesInfo.data;
		trendsData.mailbox.labels = sortMailboxesInfo.labels;
	}

	sortDomains(trendsData) {
		if (!trendsData.domain.labels.length) {
			return;
		}

		let domainsAttackMap = [];
		for (let i = 0; i < trendsData.domain.labels.length; i++) {
			domainsAttackMap[i] = {domain: trendsData.domain.labels[i], counter: trendsData.domain.data[i], index: i};
		}

		domainsAttackMap = domainsAttackMap.sort((a, b) => b.counter - a.counter);
		const sortDomains = {data: [], labels: []};
		for (let i = 0; i < trendsData.domain.labels.length; i++) {
			sortDomains.labels.push(domainsAttackMap[i].domain);
			sortDomains.data.push(trendsData.domain.data[domainsAttackMap[i].index]);
		}

		trendsData.domain.data = sortDomains.data;
		trendsData.domain.labels = sortDomains.labels;
	}

	addGeneralTrendsGraphData(trendsData, generalEmailSource) {

		generalEmailSource.detectionType = this.gs.sortObjectByValue(generalEmailSource.detectionType, true);
		this.addParsedResult(trendsData.detectionType, generalEmailSource.detectionType);

		// this.markBlacklistDomains(generalEmailSource.domain);
		generalEmailSource.domain = this.gs.sortObjectByValue(generalEmailSource.domain, true);
		this.addParsedResult(trendsData.domain, generalEmailSource.domain);

		let totalThreatsPerMailbox = _.mapValues(generalEmailSource.mailbox, m => m.malicious + m.suspicious + m.spam);
		totalThreatsPerMailbox = this.gs.sortObjectByValue(totalThreatsPerMailbox, true);
		Object.keys(totalThreatsPerMailbox).forEach(mailbox => {
			totalThreatsPerMailbox[mailbox] = generalEmailSource.mailbox[mailbox];
		});
		this.addMailboxResult(trendsData.mailbox, totalThreatsPerMailbox);
	}

	addThreatStatusData(trendsData, emailSourcePerPeriod, period) {
		if (emailSourcePerPeriod.dateStart && emailSourcePerPeriod.quarantined) {
			const dateLabel = this.gs.getPeriodLabel(period, emailSourcePerPeriod);

			trendsData.quarantined.labels.push(dateLabel);
			trendsData.quarantined.data[0].push(emailSourcePerPeriod.quarantined.stillQuarantined);
		}
	}

	addThreatsCategoryData(inboundData, emailSourcePerPeriod, period) {
		if (emailSourcePerPeriod.dateStart && (emailSourcePerPeriod.malicious || emailSourcePerPeriod.suspicious || emailSourcePerPeriod.spam || emailSourcePerPeriod.graymail)) {
			const dateLabel = this.gs.getPeriodLabel(period, emailSourcePerPeriod);

			inboundData.inboundStats.labels.push(dateLabel);
			inboundData.inboundStats.data[0].push(emailSourcePerPeriod.malicious);
			inboundData.inboundStats.data[1].push(emailSourcePerPeriod.suspicious);
			inboundData.inboundStats.data[2].push(emailSourcePerPeriod.spam);
			inboundData.inboundStats.data[3].push(emailSourcePerPeriod.graymail);
		}
	}

	addParsedResult(data, resultObj) {
		let keys = Object.keys(resultObj);
		if (keys.length > 0) {
			for (let i = 0; i < keys.length; i++) {
				data.labels.push(keys[i]);
				data.data.push(resultObj[keys[i]]);
			}
		}
	}

	addMailboxResult(data, mailboxesObj) {
		let mailboxesNames = Object.keys(mailboxesObj);
		if (mailboxesNames.length > 0) {
			mailboxesNames = mailboxesNames.filter((k) => {
				return k !== '';
			});

			for (let i = 0; i < mailboxesNames.length; i++) {
				if (mailboxesObj[mailboxesNames[i]].malicious || mailboxesObj[mailboxesNames[i]].suspicious || mailboxesObj[mailboxesNames[i]].spam) {
					data.labels.push(mailboxesNames[i]);
					data.data[0].push(mailboxesObj[mailboxesNames[i]].malicious);
					data.data[1].push(mailboxesObj[mailboxesNames[i]].suspicious);
					data.data[2].push(mailboxesObj[mailboxesNames[i]].spam);
				}
			}
		}
	}

/*	markBlacklistDomains(domains) {
		if (!this.threatProtectionData.senders_blacklist || !this.threatProtectionData.senders_blacklist.length) return;
		const blacklistDomainNames = this.threatProtectionData.senders_blacklist.map(d => d.email);
		const domainNames = Object.keys(domains);
		let oldDomainName, newDomainName;
		for (let i = 0; i < domainNames.length; i++) {
			oldDomainName = domainNames[i];
			if (oldDomainName && blacklistDomainNames.includes(oldDomainName)) {
				newDomainName = `${oldDomainName} (Blocklist)`;
				domains[newDomainName] = domains[oldDomainName];
				delete domains[oldDomainName];
			}
		}
	}*/

	addGeneralCategory(maliciousObj, trendsData) {
		switch (maliciousObj.status) {
			case this.dic.CONSTANTS.tpRule.name.malicious:
				trendsData.general.malicious++
				break;

			case this.dic.CONSTANTS.tpRule.name.suspicious:
				trendsData.general.suspicious++
				break;

			case this.dic.CONSTANTS.tpRule.name.spam:
				trendsData.general.spam++
				break;

			case this.dic.CONSTANTS.tpRule.name.graymail:
				trendsData.general.graymail++
				break;

			case 'external':
				trendsData.general.external++
		}
	}

	getTrendsGeneralResult(userInfo, currentSource, tpResults, generalEmailSource) {

		// do not count manually released emails (but do count journal (monitor) mode))
		if (currentSource.status === this.dic.CONSTANTS.tpRule.status.released &&
			currentSource.reason !== this.dic.CONSTANTS.releaseReason.rule && currentSource.reason !== 'Monitor mode' &&
			currentSource.reason !== this.dic.CONSTANTS.releaseReason.whitelistedByAdmin) {
			return;
		}

		if (tpResults.status === this.dic.CONSTANTS.tpRule.name.malicious ||
			tpResults.status === this.dic.CONSTANTS.tpRule.name.spam) {
			const theSpfDomain = currentSource.malicious?.tpResults?.resHead?.from?.receivedSPF?.domain ||
				currentSource.malicious?.tpResults?.resHead?.from?.sendingMTAFull ||
				currentSource.malicious?.tpResults?.resHead?.from?.sendingMTA?.domain;

			if (theSpfDomain && !currentSource.malicious.tpResults.resHead.isTopDomain && !currentSource.malicious.tpResults.resHead.isCertainContact &&
				theSpfDomain !== this.gs.getEmailDomain(userInfo.email) &&
				!['gmail.com', 'google.com', 'yahoo.com', 'outlook.com', 'amazonses.com', 'hotmail.com', 'salesforce.com'].includes(theSpfDomain)) {
				this.addToTrendsCount(generalEmailSource.domain, theSpfDomain);
			}
		}

		if (currentSource.mailbox_email) {
			this.addMailboxToTrendsCount(tpResults, generalEmailSource.mailbox, currentSource.mailbox_email);
		}

		this.getTrendsDetectionType(tpResults, generalEmailSource);
	}

	getTrendsDetectionType(tpResults, generalEmailSource) {
		let allEmailSubStatus = [];

		if (tpResults.resHead.sub_status) {
			if (tpResults.resHead.sub_status instanceof Array && tpResults.resHead.sub_status.length) {
				allEmailSubStatus = allEmailSubStatus.concat(tpResults.resHead.sub_status);
			}
			else {
				allEmailSubStatus.push(tpResults.resHead.sub_status);
			}
		}

		if (tpResults.resUrl?.sub_status) {
			if (tpResults.resUrl.sub_status instanceof Array && tpResults.resUrl.sub_status.length) {
				allEmailSubStatus = allEmailSubStatus.concat(tpResults.resUrl.sub_status);
			}
			else {
				allEmailSubStatus.push(tpResults.resUrl.sub_status);
			}
		}

		if (tpResults.resHash?.sub_status) {
			if (tpResults.resHash.sub_status instanceof Array && tpResults.resHash.sub_status.length) {
				allEmailSubStatus = allEmailSubStatus.concat(tpResults.resHash.sub_status);
			}
			else {
				allEmailSubStatus.push(tpResults.resHash.sub_status);
			}
		}

		const emailSubStatusCategories = [];
		allEmailSubStatus.forEach((subStatus) => {
			const category = this.getMetricCategoryBySubStatus(subStatus);
			if (!category || ['Graymail', 'Blocklist', 'Allowlist', 'User Preferences'].includes(category)) {
				return;
			}
			if (!emailSubStatusCategories.includes(category)) {
				emailSubStatusCategories.push(category);
				if (!generalEmailSource.detectionType[category]) {
					generalEmailSource.detectionType[category] = 0;
				}
				generalEmailSource.detectionType[category]++;
			}
		});
	}

	getTrendsPeriodResults(currentSource, emailsStats) {
		if (currentSource.status === this.dic.CONSTANTS.tpRule.status.quarantined) {
			emailsStats.quarantined.stillQuarantined++;
		}
	}

	addMailboxToTrendsCount(tpResults, mailboxesEmailsStats, mailboxEmail) {
		if (!mailboxesEmailsStats[mailboxEmail]) {
			mailboxesEmailsStats[mailboxEmail] = {
				malicious: 0,
				suspicious: 0,
				spam: 0,
				graymail: 0
			};
		}

		switch (tpResults.status) {
			case this.dic.CONSTANTS.tpRule.name.malicious:
				mailboxesEmailsStats[mailboxEmail].malicious++;
				break;

			case this.dic.CONSTANTS.tpRule.name.suspicious:
				mailboxesEmailsStats[mailboxEmail].suspicious++;
				break;

			case this.dic.CONSTANTS.tpRule.name.spam:
				mailboxesEmailsStats[mailboxEmail].spam++;
				break;

			case this.dic.CONSTANTS.tpRule.name.graymail:
				mailboxesEmailsStats[mailboxEmail].graymail++;
				break;
		}
	}

	addToTrendsCount(emailsStats, key) {
		if (!key) return;
		if (emailsStats[key]) {
			emailsStats[key]++;
		}
		else {
			emailsStats[key] = 1;
		}
	}

	getMetricCategoryBySubStatus(subStatus) {

		const TP_METRICS: any = Object.values(this.dic.CONSTANTS.threatProtection.metrics);
		for (let i = 0; i < TP_METRICS.length; i++) {
			if (TP_METRICS[i].sub_status === subStatus) {
				return TP_METRICS[i].category;
			}
		}

	}
}
