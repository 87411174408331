import _ from 'lodash';
import {DICTIONARY} from '../dictionary';
import {RouteService} from './routeService';
import {Injectable} from "@angular/core";
import {LookAndFeelService} from "./lookAndFeelService";

@Injectable({
    providedIn: 'root'
})
export class ArchiveService {
    dic = DICTIONARY;
    tab = DICTIONARY.CONSTANTS.archiveEmailViewPages.content;
    currentEmailsList = [];
	currentFilteredEmailsList = [];
    currentMessage:any = {};
    currentCaseObj;
	showSharingConfiguration: boolean;
	emailContent;

    constructor(private lfs:LookAndFeelService,
                private rs:RouteService) {
    }

     setCurrentEmail(emailObj) {
        this.currentMessage = emailObj;
		if (emailObj?.content?.parsedContent) {
			this.getRecipientsToCcBccStr(emailObj.content.parsedContent);
		}
        this.tab = this.dic.CONSTANTS.archiveEmailViewPages.content;
    }

    setCurrentCase(caseObj) {
        this.currentCaseObj = caseObj;
    }

	showSharingCasePopup(caseObj) {
		caseObj.edit = null;
		caseObj.edit = _.cloneDeep(caseObj);
		this.currentCaseObj = caseObj;
		this.setCurrentCase(this.currentCaseObj);
		if (this.currentCaseObj.edit.sharing.expiration) {
			this.currentCaseObj.edit.sharing.expiration = new Date(this.currentCaseObj.edit.sharing.expiration);
		}
		else {
			const nextWeekDate = new Date();
			nextWeekDate.setDate(nextWeekDate.getDate() + 7);
			this.currentCaseObj.edit.sharing.expiration = nextWeekDate;
		}
		this.showSharingConfiguration = true;
	}

    deleteEmailFromArchive(emailObj, cb) {
        this.rs.deleteEmailFromArchive(emailObj._id).then((response)=>{
            _.remove<any>(this.currentEmailsList, (emailItem) => {return emailItem._id === emailObj._id});
            this.currentFilteredEmailsList = _.reject(this.currentEmailsList, 'hide');

            this.currentMessage = {};
            cb();
        });
    }

    updateEmailFrame() {
        /*let iframeDocument:any = document.getElementById('activeEmailFrame');
        if (!iframeDocument || !iframeDocument.contentWindow) return;

        iframeDocument = iframeDocument.contentWindow.document;*/
        let content = this.currentMessage.content.parsedContent.html || this.currentMessage.content.parsedContent.text || '';
        let protectStyle = '';
        if (!content) {
            content = "";
        }
        if (content.includes('<code class="protect">')) {
            protectStyle = `<style>
                            code.protect { color: darkred; visibility: hidden; }
                            code.protect:before { visibility: visible; content: '[PROTECTED] ' }
                            code.protect:hover { visibility: visible; color:limegreen; content: unset; }
                        </style>`;
        }
        let marginStyles = `<style>p {margin:0;padding:0;} html { padding: 0 3% !important;}</style>`;

        const color = this.lfs.color;
        const hoverColor = this.lfs.hoverColor;
        const boldColor = this.lfs.boldColor;

        let scrollBarStyle = `<style>
                            ::-webkit-scrollbar  { width: 10px; height: 10px; }
                            ::-webkit-scrollbar-button { width: 0; height: 0; }
                            ::-webkit-scrollbar-thumb { border: 1px solid rgba(0, 0, 0, 0.5); border-radius: 50px;}
                            ::-webkit-scrollbar-track { background: #ffffff;  border: 1px solid rgba(0,0,0,0.15); border-radius: 50px; }
                            ::-webkit-scrollbar-track:hover { background: #ffffff; }
                            ::-webkit-scrollbar-track:active { background: #ffffff; }
                            ::-webkit-scrollbar-corner {  background: transparent; }
                            ::-webkit-scrollbar-thumb {  background-color: ${color}; }
                            ::-webkit-scrollbar-thumb:hover { background-color: ${hoverColor}; }
                            ::-webkit-scrollbar-thumb:active { background: ${boldColor}; }
                          </style>`;

		this.emailContent = marginStyles + scrollBarStyle + protectStyle + content;
        /*iframeDocument.open('text/html', 'replace');
        iframeDocument.write(marginStyles + scrollBarStyle + protectStyle + content);
        iframeDocument.close();*/
    }

	getRecipientsToCcBccStr = (message) => {
		['To','Cc','Bcc'].forEach(field => {
			const fieldStr = 'recipients' + field + 'Str'; // for generic property name (the output string)
			const fieldStrFull = 'recipients' + field + 'StrFull'; // for generic property name (the FULL output string)

			if (message[fieldStrFull]) {
				return message[fieldStrFull].length > 350;
			}

			let recipientsStr = '';
			let recipientsStrFull = '';

			if (message[field.toLowerCase()]?.value?.length) {
				message[field.toLowerCase()].value.forEach(recipient => {
					if (recipient.name && recipient.address && recipient.name !== recipient.address) {
						recipientsStrFull += `${recipient.name} <${recipient.address}>, `;
					}
					else if (recipient.name || recipient.address || recipient.name === recipient.address) {
						recipientsStrFull += `${recipient.name || recipient.address}, `;
					}
				});
			}

			recipientsStrFull = recipientsStrFull.length ? recipientsStrFull.substring(0, recipientsStrFull.length - 2) : ''; // cut last comma and space
			if (recipientsStrFull.length > 350) {
				recipientsStr = recipientsStrFull.substring(0, 350);
				recipientsStr = recipientsStr.substring(0, recipientsStr.lastIndexOf(',')) + '...';

				message[fieldStr] = recipientsStr;
			}
			else {
				message[fieldStr] = recipientsStrFull;
			}

			message[fieldStrFull] = recipientsStrFull;
		});
	}
}
