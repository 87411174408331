import html2pdf from 'html2pdf.js'
import {RouteService} from "../../../services/routeService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {myEmailsService} from "../../../services/myEmailsService";


@Component({
	selector: 'sent-email-postmark-component',
	templateUrl: './sent-email-postmark.component.html',
})
export class SentEmailPostmarkComponent implements OnInit{
    constructor(private rs:RouteService,
				public gs:GeneralService,
				public myEmailsService:myEmailsService) {
    }
    dic = DICTIONARY;
	corpName = this.gs.corpname;
    userLogoDetails;
    logo;
    recipientPostmark;


    ngOnInit() {
        this.getPostmarkData(this.myEmailsService.currentMessage, true);

        this.gs.getUserInfo(false, userInfo => {
            this.userLogoDetails = userInfo.plan.customization.lf;

            if (this.userLogoDetails && this.userLogoDetails.enabled && this.userLogoDetails.logo) {
                this.logo = "data:image/png;base64," + this.userLogoDetails.logo;
            }
            else {
                this.logo = this.dic.CONSTANTS.trustifiDefault.logo.svg;
            }
        });
    };

    doEmailAction = (action) => {
        switch (action) {
            case 'downloadPdf':
                this.downloadAsPdf(this.myEmailsService.currentMessage);
                break;

            case 'verifyPostmark':
                this.verifyPostmarkReceipt(this.myEmailsService.currentMessage);
                break;

            default:
                this.myEmailsService.doEmailAction(action);
        }
    }

    downloadFile = (url, fileName, attachmentsSize) => {
		const fileObj = {name: fileName, type: 'application/zip', size: attachmentsSize};
        this.gs.downloadFile(url, fileObj, true, (err) => {});
    };

    getPostmarkData = (currentMessage, reload) => {
        if (!reload && currentMessage.postmarkData && currentMessage.postmarkData[0] && currentMessage.postmarkData[0].recipient) {
            this.recipientPostmark = currentMessage.postmarkData[0] || {};
            this.setGlobalTimes();
            return;
        }

        this.recipientPostmark = null;
        this.rs.getEmailPostmark(currentMessage._id).then( (response) => {
			if (response?.length) {
				response.forEach(singlePostmarkDataObj => {
					singlePostmarkDataObj.recipientDisaplyName = singlePostmarkDataObj.recipient?.name;
				});
			}
            currentMessage.postmarkData = response;
            this.recipientPostmark = currentMessage.postmarkData[0] || {};
            this.setGlobalTimes();
        });
    };

    downloadAsPdf = (currentMessage) => {
        let element:any = document.getElementById('postmarksrc').cloneNode(true);

        let stamp1 = element.querySelector('#stamp1');
        if (stamp1) {
            stamp1.style['margin-top'] = '80px';
            stamp1.style['margin-right'] = '-225px';
            stamp1.style['font-size'] = '10px';
        }
        let stamp2 = element.querySelector('#stamp2');
        if (stamp2) {
            stamp2.style['margin-top'] = '80px';
            stamp2.style['margin-right'] = '-225px';
            stamp2.style['font-size'] = '10px';
        }
        let htmldata = element.querySelector('#htmldata');
        if (htmldata && currentMessage.sent && currentMessage.sent.html) {
            htmldata.style['display'] = 'block';
            htmldata.innerHTML = currentMessage.sent.html;
        }

        let options = {
            margin: 10,
            filename: 'postmark.pdf',
            enableLinks: false,
            image: {type: 'jpeg', quality: 1},
            html2canvas: {dpi: 384, letterRendering: true, logging: false},
            jsPDF: {unit: 'pt', format: 'b5', orientation: 'p', pagesplit: false}
        };

        html2pdf().from(element).set(options).save();
    };

    setGlobalTimes = () => {
        if (!this.recipientPostmark || !this.recipientPostmark.timestamp) return;
        let postmarkDate = new Date(this.recipientPostmark.timestamp);
        const summerWinterOffset = this.summerWinterOffset(postmarkDate);
        //calculate offset from utc, summerWinterOffset, and -5 for EST
        postmarkDate.setHours(postmarkDate.getHours() + (postmarkDate.getTimezoneOffset() / 60) - 5 + summerWinterOffset);
        this.recipientPostmark.est = postmarkDate.toISOString();
        //cst is -1 hour from est
        postmarkDate.setHours(postmarkDate.getHours() - 1);
        this.recipientPostmark.cst = postmarkDate.toISOString();
        postmarkDate.setHours(postmarkDate.getHours() - 1);
        this.recipientPostmark.mst = postmarkDate.toISOString();
        postmarkDate.setHours(postmarkDate.getHours() - 1);
        this.recipientPostmark.pst = postmarkDate.toISOString();
    };

    summerWinterOffset = (postmarkDate) => {
        const jan = new Date(postmarkDate.getFullYear(), 0, 1);
        const jul = new Date(postmarkDate.getFullYear(), 6, 1);
        const stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
        return (stdTimezoneOffset - postmarkDate.getTimezoneOffset()) / 60
    }

    changePostmarkRecipient = (newRecipient) => {
        if (this.recipientPostmark.recipient.id === newRecipient.recipient.id) return;
        this.recipientPostmark = newRecipient;
        this.setGlobalTimes();
    };

    verifyPostmarkReceipt = (currentMessage) => {
        this.rs.verifyReceiptPostmark(currentMessage._id, this.recipientPostmark.recipient.id);
    };

}


