import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeneralService} from "../../services/generalService";

@Component({
	selector: 'attachment-c',
	templateUrl: './attachment-download.component.html',
})
export class AttachmentComponent implements OnInit {

	@Input() attName;
	@Input() attSize;
	@Input() removeIcon;
	@Input() downloadProgress;
	@Output() removeFunc: EventEmitter<void> = new EventEmitter<void>();
	@Output() download: EventEmitter<void> = new EventEmitter<void>();
	@Output() preview: EventEmitter<void> = new EventEmitter<void>();

	fileIcon;

	constructor(public gs:GeneralService) {
	}

	ngOnInit() {
		this.fileIcon = this.gs.getFileIcon(this.attName);
	}

	onDownload() {
		this.download.emit();
	}

	previewAttachment() {
		this.preview.emit();
	}

	remove() {
		this.removeFunc.emit();
	}
}
