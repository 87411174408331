import {Component} from "@angular/core";
import {TabsPageContainer} from "../../uiComponents/tabsPageContainer";

@Component({
	selector: 'audit-logs-component',
	templateUrl: './audit-logs.component.html',
})
export class AuditLogsComponent extends TabsPageContainer {
	tabWidth = 200;
}
