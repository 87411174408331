import {
	Component,
	DoCheck,
	ElementRef,
	EventEmitter,
	Input,
	IterableDiffers,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import {DICTIONARY} from '../../../dictionary';

@Component({
	selector: 'app-dropdown-c',
	templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements OnInit, OnDestroy, DoCheck {
	constructor(private elementRef: ElementRef, private iterableDiffers: IterableDiffers) {
		this.iterableDiffer = iterableDiffers.find([]).create(null);
	}

	@Input() text: string;
	@Input('items') itemsOriginal;
	@Input() itemDisplayKey: string;
	@Input() itemIcon: string;
	@Output() onItemClicked = new EventEmitter();
	@Output() onItemDelete = new EventEmitter();
	@Input() isMultipleSelection;
	@Input() hideCounter;
	@Input() enableSearch;
	@Input() showTitle;
	@Input() focusOut;
	@Input() isDisabled;
	@Input() isEllipsis;
	@Input() alwaysDrop; // drop the menu also when there's only one item to select
	@Input() error;
	@Input() isLf;
	@ViewChild('searchInputElement') searchBox;

	iterableDiffer;
	items;
	dic = DICTIONARY;
	_ = _;
	search = {text: '', selectAll: false}
	dropdownActive = false;
	totalItemsDisplayed;

	ngOnInit(): void {
		this.refreshList();
	}

	ngOnDestroy() {
		document.removeEventListener('click', this.clickOutsideListener);
	}

	ngDoCheck() {
		const changes = this.iterableDiffer.diff(this.itemsOriginal);
		if (changes && changes.collection) {
			this.refreshList();
		}
	}

	refreshList() {
		this.items = _.clone(this.itemsOriginal);

		if (this.items && typeof this.items === 'object') {
			this.items = _.values(this.items);
		}

		if (this.isMultipleSelection) {
			this.items.forEach(item => {
				if (!item.id) { // id is needed for connecting the label to the input in HTML
					item.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 10);
				}

				item.selected = item.selected || false; // (preserve predefined 'selected' state for items if there is)
			});
		}

		this.totalItemsDisplayed = this.items && this.items.length || 0;

		if (this.search.text) {
			this.searchItem();
		}
	}

	clickOutsideListener = (e) => {
		if (this.elementRef !== e.target && !this.elementRef.nativeElement.contains(e.target)) {
			this.dropdownActive = false;
		}
		else {
			// assign the listener again since it is "once" and is shut off on any click (even inside the dropdown)
			setTimeout(() => {
				document.addEventListener('click', this.clickOutsideListener, {once : true, capture: true});
			})
		}
	}

	clearSearchItem() {
		this.search.text = '';
		this.items = _.clone(this.itemsOriginal);
		this.totalItemsDisplayed = this.items.length;
	};

	searchItem() {
		this.search.selectAll = false
		if (!this.items) {
			return;
		}
		if (!this.search.text) {
			this.clearSearchItem();
			return;
		}

		this.items = _.filter(this.itemsOriginal, item => {
			if (this.itemDisplayKey) {
				return item[this.itemDisplayKey].toLowerCase().includes(this.search.text.toLowerCase());
			}
			else {
				return item.text.toLowerCase().includes(this.search.text.toLowerCase());
			}
		})

		this.totalItemsDisplayed = this.items.length;
	};

	itemClicked(item, isItemSelectCheckboxClicked=false) {
		if (this.isDisabled || item.disabled) {
			return;
		}

		if (this.isMultipleSelection) {
			this.selectItem(item, isItemSelectCheckboxClicked);
			return;
		} else if (this.onItemClicked) {
			this.onItemClicked.emit({item});
		} else if (item.action) {
			item.action({item});
		}

		this.dropdownActive = false;
	}

	selectItem(item, isItemSelectCheckboxClicked=false) {
		item.selected = !item.selected;
		this.search.selectAll = true;
		if (this.onItemClicked) {
			this.onItemClicked.emit({item, isItemSelectCheckboxClicked});
		}
	}

	dropdownClicked() {
		if (this.dropdownActive) {
			this.closeDropdown();
		}
		else {
			if (this.focusOut !== false) { // default is true (even when undefined)
				// creates click-outside event listener
				document.addEventListener('click', this.clickOutsideListener, {once: true, capture: true});
			}
			this.dropdownActive = true;
			setTimeout(() => {
				if (this.searchBox && this.searchBox.nativeElement) {
					this.searchBox.nativeElement.focus();
				}
			}, 100)
		}
	}

	closeDropdown() {
		this.dropdownActive = false;
	}
}
