<div class="ntf-toaster-outer-container bs-enabled" *ngIf="ntfData?.length">
	<div class="ntf-toaster-inner-container align-items-stretch flex-column gap-2">
		<div class="ntf-toaster flex-row align-items-center gap-3 p-2 ps-3"
			 *ngFor=" let notification of ntfData"
			 id="{{'notification_'+notification.index}}"
			 [ngStyle]="{'--toaster-color' : !notification.error.type ? '#d73333' : notification.error.type === 'info' ? '#00a414' : notification.error.type === 'warn' ? '#d38800' : ''}">
			<i [ngClass]="{'zmdi' : true, 'zmdi-alert-circle' : notification.error.type !== 'info' , 'zmdi-check-circle' : notification.error.type === 'info'}"></i>
			<div class="ntf-counter animated fadeIn" *ngIf="notification.counter > 1">{{notification.counter}}</div>
			<i class="fa fa-spinner fa-spin text-muted" *ngIf="notification.error.showSpinner"></i>
			<!--progress bar?--><!---->
			<span class="f-1-0" [innerHTML]="notification.error.message" *ngIf="!notification.angular"></span>
			<span class="f-1-0" dynamic="notification.error.message" *ngIf="notification.angular"></span>
			<i class="zmdi zmdi-close p-2 text-dark hvr-opacity cursor-pointer" id="closeNotification" (click)="closeNotification(notification.index); $event.stopPropagation();"></i>
		</div>
	</div>
</div>
