import {Component, OnInit, ViewChild} from '@angular/core';
import {RouteService} from "./services/routeService";
import {AuthService} from "./services/authService";
import {GeneralService} from "./services/generalService";
import {NotificationService} from "./services/notificationService";
import {DICTIONARY} from "./dictionary";
import {ActivatedRoute, Router} from "@angular/router";
import _ from 'lodash';
import {AUTH_EVENTS, ERROR_EVENTS} from "./constants";
import {NgProgressComponent} from "ngx-progressbar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

	@ViewChild('progressBar') private progressBar: NgProgressComponent;

	constructor(private activatedRoute:ActivatedRoute,
				private router:Router,
				private rs:RouteService,
				private authService:AuthService,
				public gs:GeneralService,
				private ns:NotificationService) {
		this.setResponseInterceptorActions();
	}


	ERROR_EVENTS = ERROR_EVENTS;
	dic = DICTIONARY;
	_ = _;
	verificationSent = false;
	isVerified = () => this.gs.isVerified;

	popupData;

	showTimeoutPopup = false;
	verifyEmailPopupMessage;

	ngOnInit() {
		this.popupData = this.gs.popupData;

		this.authService.appTimeoutSubj.subscribe((isTimeout) => {
			if (isTimeout) {
				this.verifyEmailPopupMessage = '';
				this.showTimeoutPopup = true;
				this.authService.logout();
			}
		});
	};

	sendVerificationEmail = () => {
		if (this.verificationSent) {
			return;
		}
		this.verificationSent = true;
		this.rs.sendVerficationMail().then( (res) => {
			if (res.meta){
				this.verifyEmailPopupMessage = res.meta.message;
			}
			setTimeout(() => {
				this.verificationSent = false;
				this.verifyEmailPopupMessage = '';
			}, 30000); // Resets after 30 seconds
		}, (err) => {
			this.verificationSent = false;
		});
	};

	logout() {
		this.authService.logout();
	};

	checkVerificationEmail = () => {
		this.gs.checkVerificationEmail((isVerified, response) => {
			if (isVerified) {
				this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
					this.router.navigate([this.dic.CONSTANTS.appStates.home]);
				});
			}
			this.verifyEmailPopupMessage = response.meta.message;
		});
	};

	setResponseInterceptorActions = () => {
		// responses: 200, 201
		this.rs.restangular.configuration.responseSubjects[this.ERROR_EVENTS.success].subscribe((data) => {
			if (!data) {return;}

			this.ns.showMessage(data);
		});

		// response: 400
		this.rs.restangular.configuration.responseSubjects[this.ERROR_EVENTS.clientError].subscribe((data) => {
			if (!data) {return;}

			this.ns.showMessage(data.data);

			if (data.data?.blocked) {
				this.authService.userNotAuthenticated(data.data)
			}
		});

		// response: 429
		this.rs.restangular.configuration.responseSubjects[this.ERROR_EVENTS.tooManyReq].subscribe((data) => {
			if (!data) {return;}

			this.ns.showMessage( { meta : { display_bar : true, message : "Too many requests in this time-frame", type : "warn" }});
		});

		// responses: 500
		this.rs.restangular.configuration.responseSubjects[this.ERROR_EVENTS.serverError].subscribe((data) => {
			if (!data) {return;}

			if (data.data && data.data instanceof ArrayBuffer && data.data.byteLength !== undefined){
				// NotificationService.showMessage(JSON.parse(String.fromCharCode.apply(null, new Uint8Array(data.data))));
			}
			else{
				this.ns.showMessage(data.data || data);
			}
		});

		// response: 401
		this.rs.restangular.configuration.responseSubjects[AUTH_EVENTS.notAuthenticated].subscribe((data) => {
			if (!data) {return;}

			this.authService.userNotAuthenticated(data);
		});

		// response: 406
		this.rs.restangular.configuration.responseSubjects[AUTH_EVENTS.twoFactorRequiredError].subscribe((data) => {
			if (!data) {return;}

			this.authService.userRequireMfa(data);
		});


		this.rs.restangular.configuration.ongoingHttpRequestSubj.subscribe(data => {
			if (data) {
				this.progressBar && this.progressBar.inc(20);
			}
			else {
				this.progressBar && this.progressBar.complete();
			}
		})
	}
}
