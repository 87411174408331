import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {ArchiveService} from "../../../services/archiveService";

@Component({
	selector: 'archive-audit-log-component',
	templateUrl: './archive-audit-log.component.html',
})
export class ArchiveAuditLogComponent implements OnInit {
    constructor(public gs:GeneralService,
				private archiveService:ArchiveService,
				private rs:RouteService,
				private ns:NotificationService) {
    }

    dic = DICTIONARY;
    loadingLogsInProcess = true;
    filterData;
    logs;

	auditLogsPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.all
	];
	period = this.dic.CONSTANTS.trendsPeriod.last3Days;
	scheduleReportShow = false;

	ngOnInit() {
        this.initFilters();
        this.getArchiveLogs();
    };

	changePeriod = (inputPeriod) => {
		this.period = inputPeriod;
		this.getArchiveLogs();
	};

	showScheduleReport = () => {
		this.scheduleReportShow = true;
	}

    getArchiveLogs = () => {
        this.loadingLogsInProcess = true;
		const params = {period: this.period.value};
		this.rs.getArchiveAuditLogs(params).then((response) => {
            this.logs = response;
            this.loadingLogsInProcess = false;
        }, (err) => {
            this.loadingLogsInProcess = false;
        });
    };

    searchLogs = (searchTerm, activeFilters) => {
        this.logs.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            if (activeFilters) {
                // need to match all filter types
                let numFilterToMatch = Object.keys(activeFilters).length;

                if (activeFilters.category && activeFilters.category.length) {
                    if (activeFilters.category.includes(record.category)) {
                        numFilterToMatch--;
                    }
                }

                if (numFilterToMatch) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    exportToCsv = (sortBy) => {
        if (!this.logs || !this.logs.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Date,Category,Admin,Action\n";

        let sortedTable = _.filter(this.logs,incident => {return !incident.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        sortedTable.forEach((log) => {
            csvString += `${log.created},"${log.category}",${log.admin},"${log.action}"\n`;
        });

        this.gs.exportCsv(csvString, `audit_logs.csv`);
    };

    initFilters = () => {
        this.filterData = {
            filterType : this.dic.CONSTANTS.tableFilters.auditLogArchive,
            filters: {
                category: ['Configuration', 'Search', 'Cases']
            },
            initFiltersFunction: this.initFilters
        };
    };

}
function searchTextExecute(log, searchTerm) {
    return ((log.action && log.action.toLowerCase().indexOf(searchTerm) > -1) ||
        (log.admin && log.admin.toLowerCase().indexOf(searchTerm) > -1) ||
        (log.category && log.category.toLowerCase().indexOf(searchTerm) > -1));
}
