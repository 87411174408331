import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {GeneralService} from "../../services/generalService";
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {DICTIONARY} from "../../dictionary";

@Component({
	selector: 'audit-log-schedule-report-component',
	templateUrl: './audit-log-schedule-report.component.html',
})

export class AuditLogScheduleReportComponent implements OnInit {
	@Input() show;
	@Output() showChange = new EventEmitter();

	dic = DICTIONARY;

	constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
	}

	scheduledReports;

	ngOnInit() {
		this.rs.getAuditLogScheduled().then((res) => {
			this.scheduledReports = res.scheduled_audit_logs;
		})
	}

	updateReportSettings = () => {
		this.rs.updateAuditLogScheduled({data: this.scheduledReports}).then(() => {});
	};

	setShow(value: boolean) {
		this.show = value;
		this.showChange.emit(this.show);
	}
}
