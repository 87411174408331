<div class="flex-column gap-4">
    <span>Select which region should be used to store your data using secure S3 storage</span>

    <div class="list-group list-group-flush settings-list-container">
        <div class="list-group-item border-bottom settings-list-item">
            <div class="title-section-container flex-column gap-2">
                <b>Region</b>
                <span style="font-size: 0.9em;">Selecting a new region will change where new secure data is stored, but will not affect existing data.</span>
            </div>
            <div class="content-container flex-column justify-content-center">
                <div class="dropdown-wrapper w100" style="padding: 0; ">
                    <app-dropdown-c style="width: 210px;" [text]="userInfo?.plan.use_region">
                        <li *ngFor="let region of regions"
                            (click)="changeRegion(region)">
                            {{region}}
                        </li>
                    </app-dropdown-c>
                </div>
            </div>
        </div>
    </div>
</div>
