import {DICTIONARY} from '../dictionary';
import _ from 'lodash';
import {GeneralService} from './generalService';
import {RouteService} from './routeService';
import {NotificationService} from './notificationService';
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AccountCompromisedService {
    page = '';
    dic = DICTIONARY;
    userIncidentsPopup;
    incidentInfoPopup;
	setStatusPopup;
    accountInfo;
	reviewerNotePopup;
	incidentPopup;

    constructor(private gs:GeneralService,
                private rs:RouteService,
                private ns:NotificationService) {
    }

    showUserIncidentsInfo(user) {
        if (!user.misc.account_compromised.user_incidents.length) {
            return this.ns.showWarnMessage(this.dic.ERRORS.noSuspiciousActivity);
        }
        const userIncidentsInfo = user.misc.account_compromised.user_incidents;
        userIncidentsInfo.forEach((incidentObj) => {
            incidentObj.user_email = user.email;
        });
        this.userIncidentsPopup = {
            userIncidentsInfo: userIncidentsInfo,
            show: true
        };
    }

    getUserIncidentInfo(incidentObj, isUserAllowedToViewEmailContent=false) {
		if (['suspicious_location_change', 'suspicious_device_change', 'email_breach', 'new_forward_redirect_rules'].includes(incidentObj.incident_type)) {
			isUserAllowedToViewEmailContent = false;
		}

        if (incidentObj.userInfo) {
            this.incidentInfoPopup = {
                IncidentInfo: incidentObj,
				incidentDesc: this.getIncidentDescription(incidentObj),
				isUserAllowedToViewEmailContent,
                show: true
            };
            return;
        }

        const incidentInfo = {incidentId: incidentObj._id, action: this.dic.CONSTANTS.accountCompromisedIncidentActions.info.value};
        this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
            incidentObj.incidentInfo = response;

            if (incidentObj.incident_type === 'suspicious_location_change') {
                this.prepareIncidentLocationInfo(incidentObj);
            }

			if (incidentObj.incident_type === 'suspicious_device_change') {
				this.prepareIncidentDeviceInfo(incidentObj);
			}

            if (incidentObj.incident_type === 'sending_unusual_amount_of_emails') {
                if (incidentObj.incidentInfo.emails) {
                    incidentObj.incidentInfo.emails.forEach((emailObj) => {
                        emailObj.recipients = emailObj.recipientsIDs.map(itm => itm.email).join(', ');
                    });
                }
            }

            this.incidentInfoPopup = {
                IncidentInfo: incidentObj,
				incidentDesc: this.getIncidentDescription(incidentObj),
				isUserAllowedToViewEmailContent,
                show: true
            };
        });
    }

	changeIncidentStatus(incidentObj) {
		const curIncident = incidentObj;
		curIncident.curStatus = incidentObj.status[0].toUpperCase() + incidentObj.status.slice(1); // capitalize
		this.setStatusPopup =  {
			incident: curIncident,
			show: true
		};
	}

    deleteIncident(incidentObj, cb) {
        const title = 'Delete incident';
        const subTitle = `You are about to delete incident for ${incidentObj.user_email}`;
        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                const incidentInfo = {incidentId: incidentObj._id, action: this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.value};
                this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
                    this.ns.showInfoMessage('Incident deleted successfully');
                    cb();
                });
            }
        });
    }

	prepareIncidentDeviceInfo(incidentObj) {
		incidentObj.incidentInfo.devices.forEach((deviceObj) => {
			deviceObj.agent = deviceObj.agent || {};
			if (deviceObj.agent.raw) {
				deviceObj.agent.raw = JSON.parse(deviceObj.agent.raw);
			}
		});
	}

	removeRestrictionsUser = (incidentObj) => {
		this.gs.showPopup({
			title: 'Remove Restrictions for User',
			subTitle: `Remove all restrictions for User ${incidentObj.user_email} - user will be unblocked and will not consider as risky`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Remove Restrictions',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.removeRestrictions.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(() => {
					this.ns.showInfoMessage(`removed restrictions successfully for ${incidentObj.user_email} `);

					this.addIncidentAction('removeRestrictions', incidentObj);
				}, (err) => {
				});
			}
		});
	}

	openReviewerNotePopup = (incidentObj) => {
		this.reviewerNotePopup = {
			show: true,
			incidentObj,
			note: '',
			doneCb: () => {
				const note = this.reviewerNotePopup.note?.trim();
				if (!note) {
					this.ns.showErrorMessage('Missing reviewer comment');
					return;
				}

				if (this.reviewerNotePopup.inProcess) {
					return;
				}

				this.reviewerNotePopup.inProcess = true;

				const incidentInfo = {
					incidentId: incidentObj._id,
					note: note,
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.reviewerNote.value
				};
				this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
					this.ns.showInfoMessage('Note added successfully');
					this.addIncidentAction('note', incidentObj, this.reviewerNotePopup.note);
					this.reviewerNotePopup = null;
				}, (err) => {
					this.reviewerNotePopup.inProcess = false
				});
			}
		};
	}

	createIncidentPopup = (incidentObj) => {
		this.incidentPopup = {
			show: true,
			securityTeamComments: '',
			applyInProcess: false,
			doneCb: () => {
				this.incidentPopup.applyInProcess = true;

				const data = {
					incidentId: incidentObj._id,
					action: this.dic.CONSTANTS.quarantinedActions.createIncident.value,
					securityTeamComments: this.incidentPopup.securityTeamComments
				};

				this.rs.incidentAction(incidentObj.user_email, data).then((response) => {
					const newAction = {
						created: new Date(),
						name: 'securityReviewerActivity',
						admin: this.gs.reviewerInfo.email,
						action: response.reviewerActivity
					};
					incidentObj.actions = incidentObj.actions || [];
					incidentObj.actions.unshift(newAction);
					this.ns.showInfoMessage(`Incident created successfully`);
					this.incidentPopup.applyInProcess = false;
					this.incidentPopup.show = false;
				}, (err) => {
					this.incidentPopup.show = false;
					this.incidentPopup.applyInProcess = false;
				});
			}
		};
	};

	blockUser24hoursExecute = (incidentObj) => {
		this.gs.showPopup({
			title: 'Block User',
			subTitle: `Block User ${incidentObj.user_email} - user will not be able to access their account or send emails for 24 hours`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser24hours.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(() => {
					this.ns.showInfoMessage(`${incidentObj.user_email} blocked for 24 hours successfully`);

					this.addIncidentAction('block24', incidentObj);
				}, (err) => {
				});
			}
		});
	}

	riskyUser24hoursExecute = (incidentObj) => {
		this.gs.showPopup({
			title: 'Set User as Risky for 24 Hours',
			subTitle: `For 24 hours, emails sent from this user will undergo an extensive threat scan`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.riskyUser24hours.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(() => {
					this.ns.showInfoMessage(`${incidentObj.user_email} set as risky successfully`);

					this.addIncidentAction('risky', incidentObj);
				}, (err) => {
				});
			}
		});
	}

	disableUserMailServerExecute = (incidentObj) => {
		this.gs.showPopup({
			title: 'Disable User Email Account',
			subTitle: `Disable user ${incidentObj.user_email}`,
			body: ['User will not be able to login to their account on Exchange'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.disableUserMailServer.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(data => {
					this.ns.showInfoMessage(`${incidentObj.user_email} account disabled successfully`);
				}, (err) => {});
			}
		});
	}

	resetPasswordMailServerExecute = (incidentObj) => {
		this.gs.showPopup({
			title: 'Reset User Email Password',
			subTitle: `Reset password for user ${incidentObj.user_email}`,
			body: ['User will have to reset their password on the next login to Exchange'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.resetPasswordMailServer.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(data => {
					this.ns.showInfoMessage(`${incidentObj.user_email} password reset successfully. New temporary password: "${data.data.password}". Change password on next login.`);
				}, (err) => {});
			}
		});
	}

	blockUser = (incidentObj) => {
		this.gs.showPopup({
			title: 'Block User',
			subTitle: `Block User ${incidentObj.user_email} - user will not be able to access their account or send emails`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Block',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.accountCompromisedIncidentActions.blockUser.display,
					incidentId: incidentObj._id
				};
				this.rs.incidentAction(incidentObj.user_email, actionInfo).then(() => {
					this.ns.showInfoMessage(`${incidentObj.user_email} blocked successfully`);

					this.addIncidentAction('block', incidentObj);
				}, (err) => {
				});
			}
		});
	}

    prepareIncidentLocationInfo(incidentObj) {
        incidentObj.incident_info.locations = [];
        incidentObj.incidentInfo.devices.forEach((deviceObj) => {
			deviceObj.agent = deviceObj.agent || {};
			if (deviceObj.agent.raw) {
				deviceObj.agent.raw = JSON.parse(deviceObj.agent.raw);
			}
            deviceObj.location.forEach((locationObj) => {
                if (locationObj.ll.length === 2) {
                    const marker = {
                        icon: _.isEqual(locationObj.ll, incidentObj.incident_info.location[0].ll) ? this.gs.leafletDangerIcon : this.gs.leafletDefaultIcon,
                        lat: locationObj.ll[0],
                        lng: locationObj.ll[1],
                        //message: title,
                        //title: title,
                        draggable: false
                    };

                    incidentObj.incident_info.locations.push(marker);
                }
            });
        });

        incidentObj.incident_info.center_location = {
            lat: incidentObj.incident_info.location[0].ll[0],
            lng: incidentObj.incident_info.location[0].ll[1],
            zoom: 6
        };
    }

	addIncidentAction(actionName, incidentObj, action = '') {
		const actionData = {
			name: actionName,
			created: (new Date()).toISOString(),
			admin: this.gs.userInfo.email,
			action
		};
		if (incidentObj.actions) {
			incidentObj.actions.unshift(actionData)
		}
		else {
			incidentObj.actions = [actionData];
		}
	}

	getIncidentDescription = (incidentInfo) => {
		switch (incidentInfo.incident_type) {
			case 'email_breach':
				return `Trustifi has detected that a recent data breach contains personal information for ${incidentInfo.user_name || incidentInfo.user_email}\'s mailbox.`;

			case 'high_recipient_compliant_rate':
				return `${incidentInfo.user_name || incidentInfo.user_email}'s emails have been reported multiple times as spam or phishing by recipients.`;

			case 'high_recipient_hard_bounce_rate':
				return `${incidentInfo.user_name || incidentInfo.user_email}'s emails have been bounced on high rate lately.`;

			case 'sending_sensitive_to_new_domains':
				return `Sensitive data was sent from ${incidentInfo.user_name || incidentInfo.user_email} to new domain.`;

			case 'sending_unusual_amount_of_emails':
				return `${incidentInfo.user_name || incidentInfo.user_email} was sending an unusual amount of emails.`;

			case 'sending_sensitive_to_personal_email':
				return `Sensitive data was sent from ${incidentInfo.user_name || incidentInfo.user_email} to free email domain.`;

			case 'suspicious_device_change':
				return incidentInfo.incident_info.new_login ?
					`${incidentInfo.user_name || incidentInfo.user_email} opened an email from a new suspicious device.` :
					`${incidentInfo.user_name || incidentInfo.user_email} logged into Exchange from a new suspicious device.`;

			case 'suspicious_location_change':
				return `${incidentInfo.user_name || incidentInfo.user_email} opened an email from a new suspicious location.`;

			case 'new_forward_redirect_rules':
				return `Rule '${incidentInfo.incident_info.ruleObj.displayName}' is ${incidentInfo.incident_info.redirect ? 'redirecting' : 'forwarding'} emails to ${incidentInfo.incident_info.recipient_free_email_domain ? 'free email' : 'suspicious'} domain: ${incidentInfo.incident_info.redirect || incidentInfo.incident_info.forward}.`

		}
	}
}

