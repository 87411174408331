<div class="flex-column gap-4">
  <span>Schedule automatic reports that will be sent to admins on a daily, weekly, or monthly basis</span>
  <div class="list-group list-group-flush settings-list-container">

    <!--Settings: Scheduled reports -->
    <div class="list-group-item settings-list-item">
      <div class="title-section-container flex-column gap-2" style="max-width: 17vw;">
        <b>Frequency basis</b>
        <span class="text-muted lh-base" style="font-size: 0.9em;">Configure how often reports should be sent to admins</span>
      </div>
      <div class="content-container">
        <div class="list-group list-group-flush" *ngIf="userReport">
          <div class="list-group-item flex-row py-3" *ngFor="let period of ['daily','weekly','monthly']; let $index = index;" [ngClass]="{'pb-3 pt-0' : $index === 0}">
            <div class="flex-column gap-2" style="flex-basis: 315px; flex-shrink: 0;" *ngIf="userReport?.daily && userReport?.weekly && userReport?.monthly">
              <b style="text-transform: capitalize;">{{period}} report</b>
              <div class="flex-column gap-2">
                <span *ngIf="period" [ngClass]="{'text-success' : userReport[period]?.value}">Report will be sent {{period === 'daily' ? 'each day' : period === 'weekly' ? 'each Monday' : period === "monthly" ? 'on the 1st of each month' : ''}}.</span>
                <span *ngIf="userReport[period].last_report" [ngClass]="{'text-success' : userReport[period]?.value}">(Last report: {{userReport[period]?.last_report | date:'MM/dd/yyyy HH:mm'}})</span>
              </div>
            </div>
            <toggle-switch-c id="{{period}}BasisToggle"
							 [(model)]="userReport[period].value"
							 [hideText]="true"
							 (onChange)="updateReportSettings()"></toggle-switch-c>
          </div>
        </div>
      </div>
    </div>

	  <!--Addresses -->
	  <div class="list-group-item settings-list-item">
		  <div class="title-section-container flex-column gap-2" style="max-width: 17vw;">
			  <b>Add Report Recipients (optional)</b>
			  <span class="text-muted lh-base" style="font-size: 0.9em;">Add the specific addresses to which you would like the report to be sent, in addition to reviewers.</span>
		  </div>
		  <div class="content-container flex-column gap-2" *ngIf="userReport">
			  <div class="flex-row gap-1">
				  <input type="text" style="width: 300px; margin: 0;"
						 placeholder="Add"
						 (keyup.enter)="addReportAddress(userReport.address);"
						 [(ngModel)]="userReport.address">
				  <btn-c style="min-width: 0;"
						 [endIcon]="'zmdi zmdi-check'"
						 [isDisabled]="!userReport.address"
					   (action)="addReportAddress(userReport.address);">
				  </btn-c>
			  </div>
			  <div *ngFor="let address of userReport.report_to_emails; trackBy: gs.trackByIndex" style="margin-bottom: 10px;">
				  <div class="name ellipsis"
					   style="display: inline-block; width: 310px;">{{address}}</div>
				  <a class="closeRed action"
					 (click)="deleteReportAddress(address)"
					 [ngClass]="actionInProcess ? 'closeRed disableAction' : 'approveGreen'">
					  <i class="fa fa-times"></i>
				  </a>
			  </div>
		  </div>
	  </div>

    <!--Settings: Fields -->
    <div class="list-group-item settings-list-item" style="overflow: auto;">
      <div class="title-section-container flex-column gap-2" style="max-width: 17vw;">
        <b>Data In Scheduled Reports</b>
        <span class="text-muted lh-base" style="font-size: 0.9em;">The selected fields will appear as data columns in your scheduled reports</span>
        <btn-c style="font-size: 13px;"
             [isDisabled]="!_.filter(csvFieldsCopy, 'selected').length || !fieldsChanged"
             (action)="applyScheduleReportChanges();">
          Apply Changes
        </btn-c>
      </div>
      <div class="content-container flex-row gap-4" style="max-height: 600px;">
        <div class="flex-grow-1 flex-row centralize" *ngIf="getFieldsInProgress">
			<loader height="100" width="100" style="opacity: 0.8;"></loader>
        </div>
        <div class="flex-column gap-3" *ngIf="!getFieldsInProgress">
          <span class="hover-link-color" style="cursor: pointer; width: max-content; align-self: end; color: var(--bs-primary); text-decoration: underline;" (click)="toggleAll()">{{selectedAll ? "Deselect" : "Select"}} all</span>
          <div class="copy-policy-options gap-3 flex-row flex-wrap justify-content-start" style="max-width: 700px;">
            <div class="policy-option gap-2 f-1-0"
                 *ngFor="let field of csvFieldsCopy; trackBy: gs.trackByIndex"
                 (click)="field.selected = !field.selected; checkSelectAll(); checkIfScheduleFieldChanges();"
                 [ngClass]="{'active' : field.selected}"
                 style="flex-basis: 140px; cursor: pointer; max-width: 165px; font-size: 12px;">
              <input id="scheduleFieldSelection" type="checkbox"
                     [(ngModel)]="field.selected"
                     style="accent-color: white; width: 15px;"/>
              <span class="form-check-label m-0" style="cursor: pointer;">{{field.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
