<popup-c
	head="Select Attachments"
	size="medium"
	class="bs-enabled"
	(closeCb)="closeCb.emit();"
	(confirmCb)="doneCb.emit({attachments: attachments});"
	[buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">
	<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
		<trustifi-table-c class="trustifi-table" style="flex: 1 0; overflow: auto; z-index: 5; background: #fdfdfd; padding: 5px;"
						  [list]="attachments"
						  [headers]="[
									{text:'Name', sortKey: 'name', width: '60%'},
									{text:'Sent', sortKey: 'last_sent'},
									{text:'Size', sortKey: 'size'},
								]"
						  [cellsPrototype]="[
									{html:'attachmentCell'},
									{textKey:'last_sent', textType: 'dateAndTime'},
									{html:'sizeCell'},
								]"
						  (searchFunction)="searchAttachment($event.searchTerm, $event.activeFilters)"
						  [options]="{
                                    loadingTableFlag: getAttachmentsInProcess,
					                itemsNameSingular: 'attachment',
									defaultSortKey: 'last_sent',
									showRadioButtons: true,
									hideCounter: true,
								}"
		>
			<ng-template #customCells let-item let-cellId="cellId">
				<!--attachment cells-->
				<span *ngIf="cellId === 'attachmentCell'">
					<span>{{item[item.is_dropbox ? 'path' : 'name']}}</span>
				</span>
				<!--size cells-->
				<span *ngIf="cellId === 'sizeCell'">
					<span style="color: rgb(138, 142, 143);" *ngIf="item.size">{{item.size | FormatBytes: 2 }}</span>
				</span>
			</ng-template>
		</trustifi-table-c>
		<div class="flex-row flex-wrap flex-shrink-0 overflow-auto justify-content-start gap-2 align-items-baseline" style="padding-left: 0.3rem; padding-right: 0.3rem; height: 62px;">
			<div *ngFor="let attachment of _.filter(attachments, 'selected')"
				 class="flex-row gap-3 align-items-center p-1 rounded-1 bg-dark" style="cursor: pointer; --bs-bg-opacity: 0.07;"
				 (click)="deselectSingleAttachment(attachment);">
				<span>{{attachment.name}}</span>
				<i class="zmdi zmdi-close"></i>
			</div>
		</div>
	</div>
</popup-c>
