import * as util from 'util';
import {Buffer} from 'safe-buffer';
import _ from 'lodash';
import {GeneralService} from '../../../../services/generalService';
import {AuthService} from '../../../../services/authService';
import {DICTIONARY} from '../../../../dictionary';
import {RouteService} from '../../../../services/routeService';
import {NotificationService} from '../../../../services/notificationService';
import {ENV_CONSTS} from '../../../../constants';
import {Component, NgZone, OnInit} from "@angular/core";
import {ClipboardService} from 'ngx-clipboard';
import {Router} from "@angular/router";


@Component({
	selector: 'integrations-component',
	templateUrl: './integrations.component.html',
})
export class IntegrationsComponent implements OnInit {
    dic = DICTIONARY;
    isInbound;
    openIDRedirectUrl = [
        {url: window.location.origin, description: 'Used to log into the Trustifi web app'},
		{url: window.location.origin+'/redirect.html', description: 'Used to test the Trustifi web app'},
        {url: location.origin.includes('stage') || location.origin.includes('localhost') ? 'https://stageoaddin.trustifi.com/redirect.html' : 'https://oaddin.trustifi.com/redirect.html', description: "Used to log into the Trustifi Outlook add-in"},
        {url: location.origin.includes('stage') || location.origin.includes('localhost') ? 'https://stageea.trustifi.com/redirect.html' : 'https://ea.trustifi.com/redirect.html', description: "Used to authenticate recipients in encrypted emails"},
        {url: 'https://nkjiffgankhjcemjlikehngfajkdgfgd.chromiumapp.org/oauth2/redirect.html', description: "Used to log into the Trustifi Chrome extension"},
    ];
    samlRedirectUrl = ENV_CONSTS.paypalUrl + "/integrations/saml/callback";
    samlLogoutUrl = ENV_CONSTS.paypalUrl + "/integrations/saml/logout";
    samlAudience = DICTIONARY.CONSTANTS.samlAudience;

    planInfo: any;
    userInfo: any;
    inboundRelay: any;
    selectedServerEndpoint: { name: string; AzureAD: string; MSGraphAPI: string; };
    selectedIntegration: any;
    integrationActionInProcess: boolean;
    gsuiteConfigPopup: any;
    checkpointConnectPopup: any;
    openIDConfigPopup: any;
    samlConfigPopup: any;
    S3ConnectPopup: any;
    ldapConnectPopup: any;
	syslogConnectPopup: any;
	splunkConnectPopup: any;
	azureEventHubConnectPopup: any;
	hasWKTextSecurity = this.gs.hasWKTextSecurity();
	syslogProtocols = ['udp', 'tcp', 'tls', 'unix', 'unix-connect'];
	updateCloudAPIEndpointInProcess: boolean;
	showPassword: boolean;
	showAliases: boolean;
	showGroups: boolean;
	integImportGroupsSummary: boolean;
	changeEndPointPopup;
	isLoaded;

	exchangePowershellCommands = {
		login:'login',
		verify:'verify',
		disconnect: 'disconnect',
		enableSyncQuarantine: 'enableSyncQuarantine',
		enableAutomaticRelease: 'enableAutomaticRelease',
		quarantine:'quarantine',
		rules:'rules',
		updateRules:'updateRules',
		protection:'protection',
		configuration:'configuration'
	};

	exchangePowershellPopup = {};

	googleScopes = `https://www.googleapis.com/auth/admin.directory.group.readonly,
					https://www.googleapis.com/auth/admin.directory.user.readonly,
					https://www.googleapis.com/auth/admin.directory.domain.readonly,
					https://www.googleapis.com/auth/admin.reports.audit.readonly,
					https://www.googleapis.com/auth/contacts.readonly,
					https://www.googleapis.com/auth/contacts.other.readonly,
					https://www.googleapis.com/auth/gmail.modify`;

	googleScopesLine = this.googleScopes.split('\n').join('');

	integrationsData:any = {
		[this.dic.CONSTANTS.integrationType.exchange]: 	{desc: 'Synchronize groups, distribution lists, and aliases from Exchange. Enhance security by providing access to user contacts and mailboxes.'},
		[this.dic.CONSTANTS.integrationType.gsuite]: 	{desc: 'Synchronize groups, distribution lists, and aliases from Google. Enhance security by providing access to user contacts and mailboxes.'},
		[this.dic.CONSTANTS.integrationType.checkpoint]: {desc: 'Enables a two-way exchange of threat information, receiving details on emerging endpoint threats and sending back insights on new email-based threats.'},
		[this.dic.CONSTANTS.integrationType.s3]: 		{desc: 'Provide access to your own secure S3 buckets to store your encrypted and private data generated in Trustifi.'},
		[this.dic.CONSTANTS.integrationType.openid]: 	{desc: 'Enables users to sign in and verify their identity using the OIDC protocol, providing secure and streamlined access to the application.'},
		[this.dic.CONSTANTS.integrationType.saml]: 		{desc: 'Enables users to sign in and verify their identity using the SAML protocol, providing secure and streamlined access to the application.'},
		[this.dic.CONSTANTS.integrationType.syslog]: 	{desc: 'Receive real-time event and security logs to a centralized SIEM via Syslog. Enables receiving reports for quarantined emails and account takeover incidents.'},
		[this.dic.CONSTANTS.integrationType.splunk]: 	{desc: 'Receive real-time event and security logs directly to Splunk. Enables receiving reports for quarantined emails and account takeover incidents.'},
		[this.dic.CONSTANTS.integrationType.eventhub]: 	{desc: 'Receive real-time event and security logs directly to Azure EventHub. Enables receiving reports for quarantined emails and account takeover incidents.'},
		[this.dic.CONSTANTS.integrationType.powershell]: {desc: 'Execute advanced actions through automated PowerShell scripts - modify security policies, create connectors and rules, and more.'},
		[this.dic.CONSTANTS.integrationType.ldap]: 		{desc: 'Synchronize information of groups, distribution lists, and aliases. Ensures seamless management and up-to-date data across systems through LDAP.'},
	};

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private authService:AuthService,
				private router:Router,
				private ngZone:NgZone,
				private clipboard : ClipboardService) {
		this.isInbound = this.router.url.includes(this.dic.CONSTANTS.appStates.adminInbound);
    }

	ngOnInit(): void {
        this.gs.getUserInfo(false, userInfo => {
            this.userInfo = userInfo;

			if (window.history.state?.integrationType) {
				this.selectIntegration(window.history.state.integrationType);
			}
        });

		this.getIntegrations();
	};

	getIntegrations = () => {
		this.rs.getIntegrations().then((planInfo) => {
			this.planInfo = planInfo;
			this.planInfo.integrations = this.planInfo.integrations || {};
			this.planInfo.integrations.s3 = this.planInfo.integrations.s3 || {};
			this.planInfo.integrations.openid = this.planInfo.integrations.openid || {};
			this.planInfo.integrations.saml = this.planInfo.integrations.saml || {};

			this.inboundRelay = planInfo.inbound_relay;

			this.selectedServerEndpoint = _.clone(this.dic.CONSTANTS.exchangeServerEndpoints.Global);
			if (this.planInfo.exchange_server?.endpoint_name) {
				this.selectedServerEndpoint.name = this.planInfo.exchange_server.endpoint_name;
				this.selectedServerEndpoint.AzureAD = this.planInfo.exchange_server.azuread_endpoint;
				this.selectedServerEndpoint.MSGraphAPI = this.planInfo.exchange_server.msgraph_endpoint;
			}

			if (!this.selectedServerEndpoint.AzureAD) {
				this.selectedServerEndpoint.AzureAD = DICTIONARY.CONSTANTS.exchangeServerEndpoints.Global.AzureAD;
			}

			_.values(this.dic.CONSTANTS.integrationType).forEach(integration => {
				this.integrationsData[integration]['enabled'] = this.isIntegrationConnected(integration);
			});

			this.isLoaded = true;

		}, (err) => {

		});
	}

	copyText(text) {
		this.clipboard.copy(text);
	};

	readSamlXml() {
		setTimeout(() => {
			if (!this.samlConfigPopup.samlMetadataFile) return;

			const reader = new FileReader();
			reader.onload = (e) => {
				this.samlConfigPopup.samlMetadata = Buffer.from(<any>e.target.result).toString();
			};
			reader.readAsArrayBuffer(this.samlConfigPopup.samlMetadataFile);
		}, 1000);
	};

	selectIntegration(integration) {
        this.selectedIntegration = integration;
    }

	isIntegrationConnected = (integration) => {
		switch (integration) {
			case this.dic.CONSTANTS.integrationType.exchange:
				return !!(this.planInfo.exchange_server?.tid && this.planInfo.exchange_server?.enabled);

			case this.dic.CONSTANTS.integrationType.powershell:
				return !!(this.planInfo.powershell?.enabled && (this.planInfo.powershell?.refresh_token || this.planInfo.exchange_server?.exoadmin_enabled));

			case this.dic.CONSTANTS.integrationType.gsuite:
				return !!this.planInfo.gsuite_server?.customer_id && this.planInfo.gsuite_server?.enabled;

			case this.dic.CONSTANTS.integrationType.checkpoint:
				return !!this.planInfo.integrations.checkpoint?.enabled;

			case this.dic.CONSTANTS.integrationType.s3:
				return !!this.planInfo.integrations.s3?.enabled;

			case this.dic.CONSTANTS.integrationType.openid:
				return !!this.planInfo.integrations.openid?.enabled;

			case this.dic.CONSTANTS.integrationType.saml:
				return !!this.planInfo.integrations.saml?.enabled;

			case this.dic.CONSTANTS.integrationType.splunk:
				return !!this.planInfo.integrations.splunk?.enabled;

			case this.dic.CONSTANTS.integrationType.syslog:
				return !!this.planInfo.integrations.syslog?.enabled;

			case this.dic.CONSTANTS.integrationType.ldap:
				return !!this.planInfo.integrations.ldap?.enabled;

			case this.dic.CONSTANTS.integrationType.eventhub:
				return !!this.planInfo.integrations.eventhub?.enabled;

			default:
				return false;
		}
	}

	verifyIntegrationConnection = (integration) => {
		if (!this.integrationsData[integration].enabled) {
			return;
		}

		switch (integration) {
			case this.dic.CONSTANTS.integrationType.openid:
				this.verifyOpenIDIntegration();
				break;

			case this.dic.CONSTANTS.integrationType.saml:
				this.verifySamlIntegration();
				break;

			case this.dic.CONSTANTS.integrationType.syslog:
				this.verifySyslogIntegration();
				break;

			case this.dic.CONSTANTS.integrationType.splunk:
				this.verifySplunkIntegration();
				break;

			case this.dic.CONSTANTS.integrationType.eventhub:
				this.verifyAzureEventHubIntegration();
				break;

			case this.dic.CONSTANTS.integrationType.powershell:
				this.openExchangePowershellPopup(this.exchangePowershellCommands.verify);
				break;

			case this.dic.CONSTANTS.integrationType.checkpoint:
				this.verifyCheckPointIntegration(this.planInfo?.integrations.checkpoint.clientId, this.planInfo?.integrations.checkpoint.accessKey);
				break;

			case this.dic.CONSTANTS.integrationType.s3:
				this.verifyS3Integration();
				break;
		}
	}

	verifySyslogIntegration() {
		this.integrationActionInProcess = true;
		const syslogInfo = {
			action: this.dic.CONSTANTS.integrationActions.verifyToken,
		};
		this.rs.configureSyslogIntegration(syslogInfo).then(() => {
			this.integrationActionInProcess = false;
			this.ns.showInfoMessage( 'Test log sent successfully, check your Syslog to verify the log was received');
		},(err) => {
			this.integrationActionInProcess = false;
		});
	}

	verifySplunkIntegration() {
		this.integrationActionInProcess = true;
		const syslogInfo = {
			action: this.dic.CONSTANTS.integrationActions.verifyToken,
		};
		this.rs.configureSplunkIntegration(syslogInfo).then(() => {
			this.integrationActionInProcess = false;
			this.ns.showInfoMessage( 'Test log sent successfully, check your Splunk to verify the log was received');
		},(err) => {
			this.integrationActionInProcess = false;
		});
	}

	verifyAzureEventHubIntegration() {
		this.integrationActionInProcess = true;
		const syslogInfo = {
			action: this.dic.CONSTANTS.integrationActions.verifyToken,
		};
		this.rs.configureAzureEventHubIntegration(syslogInfo).then(() => {
			this.integrationActionInProcess = false;
			this.ns.showInfoMessage( 'Test log sent successfully, check your Azure EventHub to verify the log was received');
		},(err) => {
			this.integrationActionInProcess = false;
		});
	}

    connectToIntegration(integration) {
        switch (integration) {
            case this.dic.CONSTANTS.integrationType.exchange:
                this.connectToExchange();
                break;

            case this.dic.CONSTANTS.integrationType.gsuite:
                this.connectToGsuite();
                break;

            case this.dic.CONSTANTS.integrationType.checkpoint:
                this.connectToCheckpoint();
                break;

            case this.dic.CONSTANTS.integrationType.s3:
                this.connectToS3();
                break;

            case this.dic.CONSTANTS.integrationType.openid:
                this.connectOpenID();
                break;

            case this.dic.CONSTANTS.integrationType.saml:
                this.connectSaml();
                break;

            case this.dic.CONSTANTS.integrationType.ldap:
                this.connectToLdap();
                break;

			case this.dic.CONSTANTS.integrationType.syslog:
				this.connectToSyslog();
				break;

			case this.dic.CONSTANTS.integrationType.splunk:
				this.connectToSplunk();
				break;

			case this.dic.CONSTANTS.integrationType.eventhub:
				this.connectToAzureEventHub();
				break;

			case this.dic.CONSTANTS.integrationType.powershell:
				this.connectToPowershell();
				break;
        }
    };

    connectToExchange() {
        if (this.integrationsData[this.dic.CONSTANTS.integrationType.exchange].enabled) {
            this.gs.showPopup({
                title: 'Disconnect from mail server',
                subTitle: `Please note - you are about to cancel the connection to your mail server`,
                body: ["Groups will not be automatically updated", "Inbound Shield will be disabled for all mailboxes"],
                type: this.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Disconnect',
                doneCb: () => {
                    this.integrationActionInProcess = true;
                    let notIdx = this.ns.showInfoMessage('Disconnecting from Exchange server...', {timeout: 0});
                    this.rs.disconnectExchange().then(() => {
                        this.ns.overwriteMessage(notIdx, 'Successfully disconnected from Exchange server');
						this.integrationsData[this.dic.CONSTANTS.integrationType.exchange].enabled = false;
                        this.integrationActionInProcess = false;
                        this.getIntegrations();
                    },(err) => {
                        this.integrationActionInProcess = false;
                    });
                }
            });
        }
        else {
            if (this.planInfo.gsuite_server && this.planInfo.gsuite_server.customer_id && this.planInfo.gsuite_server.enabled) {
                this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.authDisconnectIntegration, 'Google Workspace'));
                return;
            }

            this.integrationActionInProcess = true;
            this.authService.authMicrosoftExchangeAdmin(this.userInfo.email, this.selectedServerEndpoint.AzureAD, this.planInfo.exchangeAdminAppId, 'admin', (err, onPremises) => {
                if (err) {
                    if (err !== true) this.ns.showWarnMessage(err);
                }
                else {
                    if (onPremises) {
                        this.ns.showWarnMessage(this.dic.MESSAGES.exchangeServerOnPremises);
                    }

                    this.ns.showInfoMessage('Successfully connected to Microsoft Exchange');
                }

				this.ngZone.run(() => {
					this.integrationActionInProcess = false;
					this.getIntegrations();
				});
            });
        }
    };

	connectToExchangeAdvanced() {
		this.gs.showPopup({
			title: 'Grant Advanced Permissions',
			subTitle: `You are about to grant advanced permissions to Trustifi for resetting and disabling user accounts in your Exchange`,
			type: this.dic.CONSTANTS.popupWarning,
			body: [],
			doneBtnText: 'Grant',
			doneCb: () => {
				this.integrationActionInProcess = true;
				this.authService.authMicrosoftExchangeAdmin(this.userInfo.email, this.selectedServerEndpoint.AzureAD, this.planInfo.exchangeAdminAdvancedAppId, 'advanced', (err, onPremises) => {
					if (err) {
						if (err !== true) this.ns.showWarnMessage(err);
					}
					else {
						if (onPremises) {
							this.ns.showWarnMessage(this.dic.MESSAGES.exchangeServerOnPremises);
						}

						this.ns.showInfoMessage('Successfully connected to Microsoft Exchange');
					}

					this.ngZone.run(() => {
						this.integrationActionInProcess = false;
						this.getIntegrations();
					});
				});
			}
		});
	};

    connectToGsuite() {
        if (this.integrationsData[this.dic.CONSTANTS.integrationType.gsuite].enabled) {
            this.gs.showPopup({
                title: 'Disconnect from Google Workspace',
                subTitle: `Please note - you are about to cancel the connection to your Google Workspace`,
                body: ["Groups will not be automatically updated", "Inbound Shield will be disabled for all mailboxes"],
                type: this.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Disconnect',
                doneCb: () => {
                    this.integrationActionInProcess = true;
                    let notIdx = this.ns.showInfoMessage('Disconnecting from Google Workspace server...', {timeout: 0});

                    this.rs.disconnectGSuite().then(() => {
                        this.integrationActionInProcess = false;
						this.integrationsData[this.dic.CONSTANTS.integrationType.gsuite].enabled = false;
                        this.ns.overwriteMessage(notIdx, 'Successfully disconnected from Google Workspace server');
                        this.getIntegrations();
                    },(err) => {
                        this.integrationActionInProcess = false;
                    });
                }
            });
        }
        else {
            if (this.planInfo.exchange_server && this.planInfo.exchange_server.tid
                && this.planInfo.exchange_server.enabled) {
                this.ns.showErrorMessage(util.format(this.dic.ERRORS.authDisconnectIntegration, 'Exchange Server'));
                return;
            }

            this.gsuiteConfigPopup = {
                gsuiteAdminEmail: '',
                gsuiteCustomerId: '',
                inputErrors: {
                    emailInput: false,
                    idInput: false
                },
                show: true
            };
        }
    };

    connectGSuite() {
        this.gsuiteConfigPopup.inputErrors = {emailInput: false, idInput: false}

        if (!this.gsuiteConfigPopup.gsuiteCustomerId || !this.gsuiteConfigPopup.gsuiteAdminEmail) {
            this.ns.showWarnMessage(this.dic.ERRORS.fillAllRequired);
            this.gsuiteConfigPopup.inputErrors.emailInput = !!!this.gsuiteConfigPopup.gsuiteAdminEmail;
            this.gsuiteConfigPopup.inputErrors.idInput = !!!this.gsuiteConfigPopup.gsuiteCustomerId;
            return;
        }
        if (!this.gs.validateEmail(this.gsuiteConfigPopup.gsuiteAdminEmail)) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.invalidEmail, this.gsuiteConfigPopup.gsuiteAdminEmail));
            this.gsuiteConfigPopup.inputErrors.emailInput = true;
            return;
        }

        this.integrationActionInProcess = true;

        const connectInfo = {
            adminEmail: this.gsuiteConfigPopup.gsuiteAdminEmail,
            customerId: this.gsuiteConfigPopup.gsuiteCustomerId
        };
        this.rs.connectGSuite(connectInfo).then(response => {
            this.gsuiteConfigPopup = null;
            this.ns.showInfoMessage('Successfully connected to Google Workspace server');
            this.ngOnInit();

			this.ngZone.run(() => {
				this.integrationActionInProcess = false;
			});
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.integrationActionInProcess = false;
        });
    };

    connectToCheckpoint() {
        if (this.integrationsData[this.dic.CONSTANTS.integrationType.checkpoint].enabled) {
            this.gs.showPopup({
                title: 'Disconnect from Check Point',
                subTitle: `Please note - you are about to cancel the connection to your Check Point client`,
                body: ["Groups will not be automatically updated"],
                type: this.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Disconnect',
                doneCb: () => {
                    this.integrationActionInProcess = true;

                    const actionInfo = {
                        action: this.dic.CONSTANTS.integrationActions.disable
                    }
                    this.integrationActionInProcess = true;
                    this.rs.configureCheckpoint(actionInfo).then((response) => {
                        this.ns.showInfoMessage( 'Successfully disconnected from Check Point server');
                        this.integrationActionInProcess = false;
                        this.planInfo.integrations.checkpoint = {enabled: false};
						this.integrationsData[this.dic.CONSTANTS.integrationType.gsuite].enabled = false;
                    }, err => {
                        this.integrationActionInProcess = false;
                    });
                }
            });
        }
        else {
            this.checkpointConnectPopup = {
                checkpointClientID: '',
                checkpointAccessKey: '',
                show: true
            };
        }
    };

    connectCheckpointExecute() {
        if (!this.checkpointConnectPopup.checkpointAccessKey || !this.checkpointConnectPopup.checkpointClientID) {
            return;
        }
        //  client ID '2fc0b7d9b9a04dfda76e1a95a307bd74' | accessKey '23bb31afc8ed442190d2b64bb6b06aaa'

        this.integrationActionInProcess = true;

        const actionInfo = {
            action: this.dic.CONSTANTS.integrationActions.enable,
            clientId: this.checkpointConnectPopup.checkpointClientID,
            accessKey: this.checkpointConnectPopup.checkpointAccessKey
        }
        this.integrationActionInProcess = true;
        this.rs.configureCheckpoint(actionInfo).then((response) => {
            this.checkpointConnectPopup = null;
            this.ns.showInfoMessage('Successfully enabled Check Point integration');
            this.planInfo.integrations.checkpoint = response.checkpoint;
			this.integrationsData[this.dic.CONSTANTS.integrationType.checkpoint].enabled = true;
            this.integrationActionInProcess = false;
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.integrationActionInProcess = false;
        });
    };

    connectOpenID() {
        if (this.integrationsData[this.dic.CONSTANTS.integrationType.openid].enabled) {
            this.gs.showPopup({
                title: 'Disconnect OIDC Integration',
                subTitle: 'Please note - your OIDC integration will be disconnected',
                body: [],
                type: this.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Disconnect',
                doneCb: () => {
                    this.integrationActionInProcess = true;

                    this.rs.disconnectOpenID().then(response => {
						localStorage.idpLogin = '';
						localStorage.logoutUrl = '';
                        this.planInfo.integrations.openid = this.planInfo.integrations.openid || {};
                        this.planInfo.integrations.openid.enabled = false;
						this.integrationsData[this.dic.CONSTANTS.integrationType.openid].enabled = false;
                        this.integrationActionInProcess = false;
                        this.ns.showInfoMessage('Successfully disconnected from OIDC');
                    }, (err) => {
                        this.integrationActionInProcess = false;
                    });
                }
            });
        }
        else {
            if (this.planInfo.integrations.saml && this.planInfo.integrations.saml.enabled) {
                this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.authDisconnectIntegration, 'SAML'));
                return;
            }

            this.openIDConfigPopup = {
                openIDMetadata: '',
                openIDClientId: '',
                openIDDomainsStr: '',
                show: true
            };
        }
    };

    connectSaml() {
        if (this.integrationsData[this.dic.CONSTANTS.integrationType.saml].enabled) {
            this.gs.showPopup({
                title: 'Disconnect SAML Integration',
                subTitle: 'Please note - your SAML integration will be disconnected',
                body: [],
                type: this.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Disconnect',
                doneCb: () => {
                    this.integrationActionInProcess = true;

                    this.rs.disconnectSaml().then(response => {
						localStorage.idpLogin = '';
						localStorage.logoutUrl = '';
                        this.planInfo.integrations.saml = this.planInfo.integrations.saml || {};
                        this.planInfo.integrations.saml.enabled = false;
						this.integrationsData[this.dic.CONSTANTS.integrationType.saml].enabled = false;
                        this.integrationActionInProcess = false;
                        this.ns.showInfoMessage('Successfully disconnected from SAML');
                    }, (err) => {
                        this.integrationActionInProcess = false;
                    });
                }
            });
        }
        else {
            if (this.planInfo.integrations.openid && this.planInfo.integrations.openid.enabled) {
                this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.authDisconnectIntegration, 'OIDC'));
                return;
            }

            this.samlConfigPopup = {
                samlMetadata: '',
                samlMetadataFile: '',
                samlDomainsStr: '',
                show: true
            };
        }
    };

    connectOpenIDExecute() {
        /*validation*/
        if (!this.openIDConfigPopup.openIDMetadata) { this.ns.showWarnMessage(util.format(this.dic.ERRORS.emptyField, "Discovery Document Endpoint")); return;}
        if (!this.openIDConfigPopup.openIDClientId) { this.ns.showWarnMessage(util.format(this.dic.ERRORS.emptyField, "Client ID")); return;}
        if (!this.openIDConfigPopup.openIDDomainsStr) { this.ns.showWarnMessage(util.format(this.dic.ERRORS.emptyField, "Domains")); return;}

        const openIDData = {
            metadata: this.openIDConfigPopup.openIDMetadata,
            clientId: this.openIDConfigPopup.openIDClientId,
            domains: this.openIDConfigPopup.openIDDomainsStr.split(',').map(itm => itm.trim())
        };

        if (openIDData.domains.some(itm => !itm.match(DICTIONARY.CONSTANTS.DOMAIN_REGEX))) {
            this.ns.showWarnMessage(this.dic.ERRORS.authDomainFieldInvalid);
            return;
        }

        //this.inboundRelay.mtas.map(d => d.domain)
        let domains = this.planInfo.domains && this.planInfo.domains.filter(d => d.verified).map(d => d.domain) || [];
        if (this.planInfo.exchange_server.enabled && this.planInfo.exchange_server.verified_domains) {
            domains = domains.concat(this.planInfo.exchange_server.verified_domains);
        }
        if (this.planInfo.gsuite_server.enabled && this.planInfo.gsuite_server.verified_domains) {
            domains = domains.concat(this.planInfo.gsuite_server.verified_domains);
        }
        if (domains.length && !openIDData.domains.every(d => domains.includes(d))) {
            this.ns.showWarnMessage(this.dic.ERRORS.authValidDomains);
            return;
        }

        this.integrationActionInProcess = true;

        this.authService.parseOpenIDMetadata(openIDData, err => {
            if (err) {
				this.ngZone.run(() => {
					this.integrationActionInProcess = false;
					this.ns.showErrorMessage('OIDC integration is invalid. '+err);
					return;
				});
            }

            this.authService.validateOpenID(openIDData, this.userInfo.email, err => {
                if (err) {
                    this.integrationActionInProcess = false;
                    if (err !== true) {
						this.ngZone.run(() => {
							this.ns.showErrorMessage('OIDC integration is invalid. '+err);
						});
                    }
                    return;
                }

                this.rs.connectOpenID(openIDData).then(response => {
                    this.planInfo.integrations = this.planInfo.integrations || {};
                    this.planInfo.integrations.openid = openIDData;
                    this.planInfo.integrations.openid.enabled = true;
					this.integrationsData[this.dic.CONSTANTS.integrationType.openid].enabled = true;
                    this.integrationActionInProcess = false;

					this.ngZone.run(() => {
						this.ns.showInfoMessage('Successfully connected to OIDC');
						this.openIDConfigPopup = null;
					});
                }, (err) => {
                    this.integrationActionInProcess = false;

					this.ngZone.run(() => {
						this.ns.showErrorMessage('OIDC integration is invalid. ' + err.data.message);
					});
                });
            });
        });
    }

    connectSamlExecute() {
        /*validation*/
        if (!this.samlConfigPopup.samlMetadata) { this.ns.showWarnMessage(this.dic.ERRORS.authSAMLMissing); return;}
        if (!this.samlConfigPopup.samlDomainsStr) { this.ns.showWarnMessage(util.format(this.dic.ERRORS.emptyField, "Domains")); return;}

        const samlData = {
            metadata: this.samlConfigPopup.samlMetadata,
            domains: this.samlConfigPopup.samlDomainsStr.split(',').map(itm => itm.trim())
        };

        if (samlData.domains.some(itm => !itm.match(DICTIONARY.CONSTANTS.DOMAIN_REGEX))) {
            this.ns.showWarnMessage(this.dic.ERRORS.authDomainFieldInvalid);
            return;
        }
        let domains = this.planInfo.domains && this.planInfo.domains.filter(d => d.verified).map(d => d.domain) || [];
        if (this.planInfo.exchange_server.enabled && this.planInfo.exchange_server.verified_domains) {
            domains = domains.concat(this.planInfo.exchange_server.verified_domains);
        }
        if (this.planInfo.gsuite_server.enabled && this.planInfo.gsuite_server.verified_domains) {
            domains = domains.concat(this.planInfo.gsuite_server.verified_domains);
        }
        if (domains.length && !samlData.domains.every(d => domains.includes(d))) {
            this.ns.showWarnMessage(this.dic.ERRORS.authValidDomains);
            return;
        }

        this.integrationActionInProcess = true;

        this.authService.parseSamlMetadata(samlData, err => {
            if (err) {
				this.ngZone.run(() => {
					this.integrationActionInProcess = false;
					this.ns.showErrorMessage('SAML integration is invalid. ' + err);
				});
                return;
            }

            this.authService.validateSaml(samlData, this.userInfo.email, err => {
                if (err) {
                    this.integrationActionInProcess = false;
                    if (err !== true) {
						this.ngZone.run(() => {
							this.ns.showErrorMessage('SAML integration is invalid. ' + err);
						});
                    }
                    return;
                }

                this.rs.connectSaml(samlData).then(response => {
                    this.planInfo.integrations = this.planInfo.integrations || {};
                    this.planInfo.integrations.saml = samlData;
                    this.planInfo.integrations.saml.enabled = true;
					this.integrationsData[this.dic.CONSTANTS.integrationType.saml].enabled = true;
                    this.integrationActionInProcess = false;

					this.ngZone.run(() => {
						this.samlConfigPopup = null;
						this.ns.showInfoMessage('Successfully connected to SAML');
					});
                }, (err) => {
                    this.integrationActionInProcess = false;

					this.ngZone.run(() => {
						this.ns.showErrorMessage('SAML integration is invalid. ' + err.data.message);
					});
                });
            });
        });
    }

    connectToS3() {
        if (this.integrationsData[this.dic.CONSTANTS.integrationType.s3].enabled) {
            this.gs.showPopup({
                title: 'Disconnect from Amazon S3',
                subTitle: `Please note - you are about to cancel the connection to your Amazon S3 client`,
                body: [],
                type: this.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Disconnect',
                doneCb: () => {
                    this.integrationActionInProcess = true;

                    const actionInfo = {
                        action: this.dic.CONSTANTS.integrationActions.disable
                    }
                    this.integrationActionInProcess = true;
                    this.rs.configureS3Integration(actionInfo).then((response) => {
                        this.ns.showInfoMessage( 'Successfully disconnected from Amazon S3');
                        this.integrationActionInProcess = false;
                        this.planInfo.integrations.s3 = {enabled: false};
						this.integrationsData[this.dic.CONSTANTS.integrationType.gsuite].enabled = false;
                    }, err => {
                        this.integrationActionInProcess = false;
                    });
                }
            });
        }
        else {
            this.S3ConnectPopup = {
                remote_account_id: '',
                remote_role: '',
                use_predefined_sse: '',
                attachments: '',
                content: '',
                archiving: '',
                show: true
            };
        }
    };

    validateS3() {
        return this.S3ConnectPopup.remote_account_id && this.S3ConnectPopup.remote_role;
    }

    connectS3Execute() {
        if (!this.validateS3()) {
            return;
        }

        this.integrationActionInProcess = true;

        const actionInfo = {
            action: this.dic.CONSTANTS.integrationActions.enable,
            remote_account_id: this.S3ConnectPopup.remote_account_id,
            remote_role: this.S3ConnectPopup.remote_role,
            use_predefined_sse: this.S3ConnectPopup.use_predefined_sse,
            attachments: this.S3ConnectPopup.attachments,
            content: this.S3ConnectPopup.content,
            archiving: this.S3ConnectPopup.archiving,
        }
        this.integrationActionInProcess = true;
        this.rs.configureS3Integration(actionInfo).then((response) => {
            this.S3ConnectPopup = null;
            this.ns.showInfoMessage('Successfully enabled Amazon S3 integration');
            this.planInfo.integrations.s3 = response.s3;
			this.integrationsData[this.dic.CONSTANTS.integrationType.s3].enabled = true;
            this.integrationActionInProcess = false;
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.integrationActionInProcess = false;
        });
    };

	connectToSyslog() {
		if (this.integrationsData[this.dic.CONSTANTS.integrationType.syslog].enabled) {
			this.gs.showPopup({
				title: 'Disconnect from Syslog',
				subTitle: `Please note - you are about to cancel the connection to your Syslog server`,
				body: ["Logs will not be sent to your syslog"],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Disconnect',
				doneCb: () => {
					this.integrationActionInProcess = true;
					const syslogInfo = {
						action: this.dic.CONSTANTS.integrationActions.disable,
					};
					this.rs.configureSyslogIntegration(syslogInfo).then(() => {
						this.integrationActionInProcess = false;
						this.integrationsData[this.dic.CONSTANTS.integrationType.syslog].enabled = false;
						this.ns.showInfoMessage( 'Successfully disconnected from Syslog server');

						this.planInfo.integrations.syslog = {
							enabled: false
						};
					},(err) => {
						this.integrationActionInProcess = false;
					});
				}
			});
		}
		else {
			this.syslogConnectPopup = {
				host: '',
				port: 514,
				protocol: 'tcp',
				show: true
			};
		}
	}

	connectSyslogExecute() {
		if (!this.syslogConnectPopup.host || !this.syslogConnectPopup.port) {
			return;
		}

		this.integrationActionInProcess = true;

		const syslogInfo = {
			action: this.dic.CONSTANTS.integrationActions.enable,
			host: this.syslogConnectPopup.host.trim(),
			port: this.syslogConnectPopup.port,
			protocol: this.syslogConnectPopup.protocol,
		};
		this.rs.configureSyslogIntegration(syslogInfo).then(response => {
			this.syslogConnectPopup = false;
			this.ns.showInfoMessage('Successfully connected to Syslog server');

			this.planInfo.integrations.syslog = syslogInfo;
			this.planInfo.integrations.syslog.enabled = true;
			this.integrationsData[this.dic.CONSTANTS.integrationType.syslog].enabled = true;

			this.integrationActionInProcess = false;
		}, (err) => {
			if (err && err.data && err.data.message && !err.data.display_bar) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.integrationActionInProcess = false;
		});
	};


	connectToSplunk() {
		if (this.planInfo.integrations.splunk?.enabled) {
			this.gs.showPopup({
				title: 'Disconnect from Splunk',
				subTitle: `Please note - you are about to cancel the connection to your Splunk server`,
				body: ["Logs will not be sent to your Splunk"],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Disconnect',
				doneCb: () => {
					this.integrationActionInProcess = true;
					const syslogInfo = {
						action: this.dic.CONSTANTS.integrationActions.disable,
					};
					this.rs.configureSplunkIntegration(syslogInfo).then(() => {
						this.integrationActionInProcess = false;
						this.ns.showInfoMessage( 'Successfully disconnected from Splunk server');

						this.planInfo.integrations.splunk = {
							enabled: false
						};
						this.integrationsData[this.dic.CONSTANTS.integrationType.splunk].enabled = false;
					},(err) => {
						this.integrationActionInProcess = false;
					});
				}
			});
		}
		else {
			this.splunkConnectPopup = {
				host: '',
				token: '',
				show: true
			};
		}
	}

	connectSplunkExecute() {
		if (!this.splunkConnectPopup.host || !this.splunkConnectPopup.token) {
			return;
		}

		this.integrationActionInProcess = true;

		const syslogInfo = {
			action: this.dic.CONSTANTS.integrationActions.enable,
			host: this.splunkConnectPopup.host.trim(),
			token: this.splunkConnectPopup.token.trim()
		};
		this.rs.configureSplunkIntegration(syslogInfo).then(response => {
			this.splunkConnectPopup = false;
			this.ns.showInfoMessage('Successfully connected to Splunk server');

			this.planInfo.integrations.splunk = syslogInfo;
			this.planInfo.integrations.splunk.enabled = true;
			this.integrationsData[this.dic.CONSTANTS.integrationType.splunk].enabled = this.planInfo.integrations.splunk.enabled;

			this.integrationActionInProcess = false;
		}, (err) => {
			if (err && err.data && err.data.message && !err.data.display_bar) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.integrationActionInProcess = false;
		});
	};

	connectToAzureEventHub() {
		if (this.planInfo.integrations.eventhub?.enabled) {
			this.gs.showPopup({
				title: 'Disconnect from Azure EventHub',
				subTitle: `Please note - you are about to cancel the connection to your Azure EventHub server`,
				body: ["Logs will not be sent to your Azure EventHub"],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Disconnect',
				doneCb: () => {
					this.integrationActionInProcess = true;
					const syslogInfo = {
						action: this.dic.CONSTANTS.integrationActions.disable,
					};
					this.rs.configureAzureEventHubIntegration(syslogInfo).then(() => {
						this.integrationActionInProcess = false;
						this.ns.showInfoMessage( 'Successfully disconnected from Azure EventHub server');

						this.planInfo.integrations.eventhub = {
							enabled: false
						};
						this.integrationsData[this.dic.CONSTANTS.integrationType.eventhub].enabled = false;
					},(err) => {
						this.integrationActionInProcess = false;
					});
				}
			});
		}
		else {
			this.azureEventHubConnectPopup = {
				connection: '',
				namespace: '',
				hub_name: '',
				key_name: '',
				key_secret: '',
				show: true
			};
		}
	}

	connectToAzureEventHubExecute() {
		if (this.azureEventHubConnectPopup.connection) {
			this.azureEventHubConnectPopup.connection = decodeURIComponent(this.azureEventHubConnectPopup.connection);

			//"Endpoint=sb://<YourNamespace>.servicebus.windows.net/;SharedAccessKeyName=<KeyName>;SharedAccessKey=<Key>;EntityPath=<YourEventHub>"
			const match = this.azureEventHubConnectPopup.connection.match(/Endpoint=sb:\/\/(.+)\.servicebus\.windows\.net\/?;SharedAccessKeyName=(.+);SharedAccessKey=(.+);EntityPath=(.+)/i);
			if (match) {
				this.azureEventHubConnectPopup.namespace = match[1];
				this.azureEventHubConnectPopup.key_name = match[2];
				this.azureEventHubConnectPopup.key_secret = match[3];
				this.azureEventHubConnectPopup.hub_name = match[4];
			}
		}

		if (!this.azureEventHubConnectPopup.namespace || !this.azureEventHubConnectPopup.hub_name || !this.azureEventHubConnectPopup.key_name || !this.azureEventHubConnectPopup.key_secret) {
			this.ns.showErrorMessage('Azure EventHub verification failed: Could not establish connection, please verify the provided connection string');
			return;
		}

		this.integrationActionInProcess = true;

		const syslogInfo = {
			action: this.dic.CONSTANTS.integrationActions.enable,
			namespace: this.azureEventHubConnectPopup.namespace.trim(),
			hub_name: this.azureEventHubConnectPopup.hub_name.trim(),
			key_name: this.azureEventHubConnectPopup.key_name.trim(),
			key_secret: this.azureEventHubConnectPopup.key_secret.trim()
		};
		this.rs.configureAzureEventHubIntegration(syslogInfo).then(response => {
			this.azureEventHubConnectPopup = false;
			this.ns.showInfoMessage('Successfully connected to Azure EventHub');

			this.planInfo.integrations.eventhub = syslogInfo;
			this.planInfo.integrations.eventhub.enabled = true;
			this.integrationsData[this.dic.CONSTANTS.integrationType.eventhub].enabled = this.planInfo.integrations.eventhub.enabled;

			this.integrationActionInProcess = false;
		}, (err) => {
			if (err && err.data && err.data.message && !err.data.display_bar) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.integrationActionInProcess = false;
		});
	};


	connectToLdap() {
        if (this.integrationsData[this.dic.CONSTANTS.integrationType.ldap].enabled) {
            this.gs.showPopup({
                title: 'Disconnect from LDAP',
                subTitle: `Please note - you are about to cancel the connection to your LDAP`,
                body: ["Groups will not be automatically updated"],
                type: this.dic.CONSTANTS.popupWarning,
                doneBtnText: 'Disconnect',
                doneCb: () => {
                    this.integrationActionInProcess = true;
                    this.rs.disconnectLDAP().then(() => {
                        this.integrationActionInProcess = false;
						this.integrationsData[this.dic.CONSTANTS.integrationType.ldap].enabled = false;
                        this.ns.showInfoMessage( 'Successfully disconnected from LDAP server');

                        this.planInfo.integrations.ldap = {
                            enabled: false
                        }
                    },(err) => {
                        this.integrationActionInProcess = false;
                    });
                }
            });
        }
        else {
            this.ldapConnectPopup = {
                ldapUsername: '',
                ldapPassword: '',
                ldapEndpoint: '',
                ldapBaseDN: '',
                ldapSecure: true,
                ldapPort: 636,
                show: true
            };
        }
    };

    connectLdapExecute() {
        if (!this.ldapConnectPopup.ldapUsername || !this.ldapConnectPopup.ldapPassword || !this.ldapConnectPopup.ldapEndpoint) {
            return;
        }

        this.integrationActionInProcess = true;

        const endpoint = `ldap${this.ldapConnectPopup.ldapSecure ? 's' : ''}://${this.ldapConnectPopup.ldapEndpoint}:${this.ldapConnectPopup.ldapPort}`;

        const ldapInfo = {
            username: this.ldapConnectPopup.ldapUsername,
            password: this.ldapConnectPopup.ldapPassword,
            endpoint: endpoint,
            baseDN: this.ldapConnectPopup.ldapBaseDN,
        }
        this.rs.connectLDAP(ldapInfo).then(response => {
            this.ldapConnectPopup = false;
            this.ns.showInfoMessage('Successfully connected to LDAP');

            this.planInfo.integrations.ldap = ldapInfo;
            this.planInfo.integrations.ldap.enabled = true;
			this.integrationsData[this.dic.CONSTANTS.integrationType.ldap].enabled = true;
            this.integrationActionInProcess = false;
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.integrationActionInProcess = false;
        });
    };

    integImportGroups() {
        if (!this.integrationsData[this.selectedIntegration].enabled) {
            this.integrationActionInProcess = false;
            return;
        }
        this.integrationActionInProcess = true;

        let promise;
        switch (this.selectedIntegration) {
            case this.dic.CONSTANTS.integrationType.exchange:
                promise = this.rs.createGroupSubscription();
                break;

            case this.dic.CONSTANTS.integrationType.gsuite:
                promise = this.rs.createGSuiteGroups();
                break;

            case this.dic.CONSTANTS.integrationType.ldap:
                promise = this.rs.createLdapGroups();
                break;
        }

        promise.then(groups => {
            this.ns.showInfoMessage('We are updating information from your mail server, this process may take several minutes to finish');
            this.integrationActionInProcess = false;
        },(err) => {
            this.integrationActionInProcess = false;
        });
    };

    getMailboxesAliases = (show) => {
        this.showAliases = show;
    };

    getGroups = (show) => {
        this.showGroups = show;
    };

    changeEndpoint = (endpoint) => {
        if (this.selectedServerEndpoint.name === endpoint.name) return;

		let azureADInput:any;
		let microsoftGraphAPIInput:any;
        if (endpoint.name === this.dic.CONSTANTS.exchangeServerEndpoints.Hybrid.name) {
			azureADInput = '';
			microsoftGraphAPIInput = '';
        }

		this.changeEndPointPopup = {
			show: true,
			applyInProcess: false,
			subTitle: `Please note - you are about to change the cloud API endpoint of your mail server to ${endpoint.name}`,
			azureADInput: azureADInput,
			microsoftGraphAPIInput: microsoftGraphAPIInput,
			doneCb: () => {
				this.changeEndPointPopup.applyInProcess = true;
				if (this.changeEndPointPopup.azureADInput !== undefined && this.changeEndPointPopup.microsoftGraphAPIInput !== undefined) {
					if (this.changeEndPointPopup.azureADInput === ""|| this.changeEndPointPopup.microsoftGraphAPIInput === "") {
						this.changeEndPointPopup.applyInProcess = false;
						return this.ns.showErrorMessage("Please enter a valid endpoint");
					}

					if (this.changeEndPointPopup.azureADInput.indexOf(' ') > -1 || this.changeEndPointPopup.microsoftGraphAPIInput.indexOf(' ') > -1) {
						this.changeEndPointPopup.applyInProcess = false;
						return this.ns.showErrorMessage(this.dic.ERRORS.invalidServerEndpoint);
					}
					this.selectedServerEndpoint.AzureAD = this.changeEndPointPopup.azureADInput;
					this.selectedServerEndpoint.MSGraphAPI = this.changeEndPointPopup.microsoftGraphAPIInput;
				}
				this.selectedServerEndpoint.name = endpoint.name;
				this.updateCloudAPIEndpoint();
				this.changeEndPointPopup.applyInProcess = false;
			}
		};
    };

    updateCloudAPIEndpoint = () => {
        if (!this.selectedServerEndpoint.name || !this.selectedServerEndpoint.AzureAD || !this.selectedServerEndpoint.MSGraphAPI) return;

        this.updateCloudAPIEndpointInProcess = true;
        this.rs.activateInboundEmailRelayAndGenKey({
            action: this.dic.CONSTANTS.inboundRelayActions.cloudAPIEndpoint,
            endpoint: this.selectedServerEndpoint
        }).then(() => {
            this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
            this.planInfo.exchange_server.endpoint_name = this.selectedServerEndpoint.name;
            this.updateCloudAPIEndpointInProcess = false;
			this.changeEndPointPopup.show = false;
        });
    };

    verifyCheckPointIntegration = (clientId, accessKey) => {
        const actionInfo = {
            action: this.dic.CONSTANTS.integrationActions.verifyToken,
            clientId: clientId,
            accessKey: accessKey
        }
        this.integrationActionInProcess = true;
        this.rs.configureCheckpoint(actionInfo).then((response) => {
            this.integrationActionInProcess = false;
        }, err => {
            this.integrationActionInProcess = false;
        });
    };

    verifyS3Integration = () => {
        const actionInfo = {
            action: this.dic.CONSTANTS.integrationActions.verifyToken,
            use_predefined_sse: this.planInfo.integrations.s3.use_predefined_sse,
            remote_account_id: this.planInfo.integrations.s3.remote_account_id,
            remote_role: this.planInfo.integrations.s3.remote_role
        }
        this.integrationActionInProcess = true;
        this.rs.configureS3Integration(actionInfo).then((response) => {
            this.integrationActionInProcess = false;
        }, err => {
            this.integrationActionInProcess = false;
        });
    };

    verifyOpenIDIntegration = () => {
        this.integrationActionInProcess = true;
        this.authService.validateOpenID(this.planInfo.integrations.openid, this.userInfo.email, err => {
            if (err) {
                if (err !== true) {
                    this.ns.showErrorMessage('OIDC integration is invalid: ' + err);
                }
            }
            else {
                this.ns.showInfoMessage('Successfully connected to OIDC');
            }

            this.integrationActionInProcess = false;
        });
    };

    verifySamlIntegration = () => {
        this.integrationActionInProcess = true;
        this.authService.validateSaml(this.planInfo.integrations.saml, this.userInfo.email, err => {
            if (err) {
                if (err !== true) {
                    this.ns.showErrorMessage('SAML integration is invalid: ' + err);
                }
            }
            else {
                this.ns.showInfoMessage('Successfully connected to SAML');
            }

			this.ngZone.run(() => {
				this.integrationActionInProcess = false;
			});
        });
    };

	connectToPowershell() {
		if (this.integrationsData[this.dic.CONSTANTS.integrationType.powershell].enabled) {
			this.gs.showPopup({
				title: 'Disconnect from PowerShell',
				subTitle: `Please note - you are about to cancel the connection to your Exchange Server PowerShell`,
				body: ["You will not be able to do any powershell related operations"],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Disconnect',
				doneCb: () => {
					this.integrationActionInProcess = true;
					let disconnectFlow = this.planInfo.exchange_server?.exoadmin_enabled ? this.rs.disconnectExchange({onlyExoadmin:true}) : this.rs.exchangeDeviceDisconnect();
					disconnectFlow.then(() => {
						this.integrationActionInProcess = false;
						this.integrationsData[this.dic.CONSTANTS.integrationType.powershell].enabled = false;
						this.ns.showInfoMessage( 'Successfully disconnected from Exchange Server PowerShell');

						this.planInfo.powershell = {enabled: false};
						this.exchangePowershellPopup[this.exchangePowershellCommands.login] = null;
					},(err) => {
						this.integrationActionInProcess = false;
					});
				}
			});
		}
		else {
			this.openExchangePowershellPopup(this.exchangePowershellCommands.login);
		}
	}

	closeExchangePowershellPopup(type:string, success:boolean, disconnected:boolean) {
		if (type === this.exchangePowershellCommands.login && success) {
			this.integrationsData[this.dic.CONSTANTS.integrationType.powershell].enabled = true;

			if (!this.planInfo.powershell.enabled) {
				this.planInfo.powershell = {enabled: true, refresh_token: true};
			}
			else {
				this.planInfo.powershell.refresh_token = true;
			}
		}

		if (disconnected) {
			this.planInfo.powershell.refresh_token = false;
			this.integrationsData[this.dic.CONSTANTS.integrationType.powershell].enabled = false;
		}

		this.exchangePowershellPopup[type] = null;
	}

	openPowershellAutoReleasePopup = () => {
		this.gs.showPopup({
			title: 'Release Quarantined Emails',
			subTitle: `All quarantined emails in Exchange will be released. This operation can take a few minutes.`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Confirm',
			doneCb: () => {
				this.rs.powershellQuarantineAction('autoReleaseExecute', {enabled: this.planInfo.powershell.automatic_release}).then(res => {
					this.integrationActionInProcess = false;
					this.ns.showInfoMessage( 'Successfully called for release of quarantine emails');
				}, err => {
					this.integrationActionInProcess = false;
				});
			}
		});
	}

	openExchangePowershellPopup(type:string) {
		if (type === this.exchangePowershellCommands.verify) {
			this.integrationActionInProcess = true;
			this.rs.exchangeVerifyLogin().then(res => {
				this.integrationActionInProcess = false;
				this.ns.showInfoMessage( 'Successfully connected to Exchange Server PowerShell');
			}, err => {
				if (err.data && err.data.disconnected) {
					this.planInfo.powershell.refresh_token = false;
					this.integrationsData[this.dic.CONSTANTS.integrationType.powershell].enabled = false;
				}
				this.integrationActionInProcess = false;
			});
			return;
		}

		if (type === this.exchangePowershellCommands.enableSyncQuarantine) {
			this.integrationActionInProcess = true;
			this.rs.powershellQuarantineAction('sync', {enabled: this.planInfo.powershell.sync_quarantine}).then(res => {
				this.integrationActionInProcess = false;
				this.ns.showInfoMessage( 'Successfully enabled synchronization to Exchange Server quarantined emails');
			}, err => {
				this.integrationActionInProcess = false;
			});
			return;
		}

		if (type === this.exchangePowershellCommands.enableAutomaticRelease) {
			this.integrationActionInProcess = true;
			this.rs.powershellQuarantineAction('autoRelease', {enabled: this.planInfo.powershell.automatic_release}).then(res => {
				this.integrationActionInProcess = false;
				this.ns.showInfoMessage( `Successfully ${this.planInfo.powershell.automatic_release ? 'enabled' : 'disabled'} Automatic Release`);
			}, err => {
				this.integrationActionInProcess = false;
			});
			return;
		}

		this.exchangePowershellPopup[type] = {show: true};

		if (type === this.exchangePowershellCommands.rules) {
			let verifiedDomains = [];
			if (this.planInfo.exchange_server.enabled && this.planInfo.exchange_server.verified_domains && this.planInfo.exchange_server.verified_domains.length) {
				verifiedDomains = this.planInfo.exchange_server.verified_domains;
			}
			else {
				verifiedDomains = this.planInfo.domains.filter(d => d.verified).map(d => d.domain);
			}

			this.exchangePowershellPopup[type].domains = verifiedDomains;
		}
	}
}
