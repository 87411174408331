<div class="flxClmn onboarding-steps-container vSpace5" style="position: relative; z-index: 15; padding: max(2vh,15px) 15px; flex: 1 0 auto; margin: 0 auto; align-items: center;">

	<div class="flxClmn vSpace2" style="text-align: center;">
		<span style=" font-size: 2.2rem; font-weight: 400; font-family: 'Roboto Condensed', sans-serif;">
			Trustifi Installation Wizard
		</span>
	</div>

	<!--Progress navigation (grey/green circles)-->
	<div class="flxRow-center w100 onboarding-steps-nav"
		 *ngIf="currentSetupScope"
		 [ngStyle]="{'max-width' : 'max(calc(' + 7 * currentSetupScope.userStepsInfo.length + 'vw),calc(' + 120 * currentSetupScope.userStepsInfo.length + 'px))'}">
		<div class="flxClmn vSpace05 step-nav-item" style="width: max(7vw,120px);"
			 *ngFor="let step of currentSetupScope.userStepsInfo"
			 [ngClass]="{'finished' : step.status === 'done', 'active' : step.name === currentStep.name}"
			 [ngStyle]="{'cursor' : step.name === currentStep.name ? 'default' : 'pointer'}"
			 (click)="updateStepInfo(step)">
			<div class="flxClmn-center circled-icon" style="position: relative; align-items: center; width: max(50px,3vw); height: max(50px,3vw);">
				<i *ngIf="step.iconClass" [ngClass]="step.iconClass"
				   [ngStyle]="step.iconClass === 'fas fa-puzzle-piece' && {'transform': 'translate(11%, -7%)'}"></i>
				<span style="font-size: calc(0.45rem + 0.4vw); font-weight: bold;" *ngIf="step.name === dic.CONSTANTS.onboardingSteps.domainVerification">WWW</span>
				<span style="font-size: calc(0.6rem + 0.5vw); font-weight: bold;" *ngIf="step.name === dic.CONSTANTS.onboardingSteps.outboundApiIntegrations || step.name === dic.CONSTANTS.onboardingSteps.inboundApiIntegrations">API</span>
				<div class="flxClmn-center warning-sign-circle animated fadeIn" style="position: absolute; align-items: center;"
					 *ngIf="step.message" title="{{step.message}}">
					<i class="fas fa-exclamation" style="font-size: 0.9rem;"></i>
				</div>
			</div>
			<span class="flxClmn-center step-label">{{step.label}}</span>
		</div>
	</div>

	<!--instructions-->
	<div class="flxClmn vSpace4" style="flex: 1 0; max-width: 655px;" *ngIf="currentStep">
		<div class="flxClmn vSpace2">
			<span style="font-weight: 500; text-align: center; padding: 0 1vw;">{{currentStep.title}}</span>
			<span style="color: dimgrey; font-size: 0.85rem; font-weight: 400; text-align: center;">{{currentStep.subtitle}}</span>
		</div>

		<ul class="vSpace3" style="line-height: 1.5;">

			<li style="height: auto;" class="animated fadeIn"
				*ngFor="let instructionStep of currentStep.instructions"
				[ngClass]="instructionStep.link && 'instruction-step-link'">
				<!--regular text-->
				<span *ngIf="instructionStep.text && !instructionStep.link" [innerHTML]="instructionStep.text"></span>
				<!--text with external link(s)-->
				<div class="flxClmn" style="float: left; min-width: 90%; padding: 8px 10px; background: #f9f9f9; margin-top: -10px; margin-left: 10px;" *ngIf="instructionStep.link">
					<div class="hSpace2">
						<a style="color: cornflowerblue; text-decoration: underline; font-family: 'Roboto Condensed', sans-serif; font-size: 1.1rem;" href="{{instructionStep.link.url}}" target="_blank">
							{{instructionStep.link.text}}
						</a>
						<span style="font-size: 0.7rem;">(From Trustifi Docs)</span>
					</div>
				</div>
				<!--internal navigation link-->
				<span *ngIf="instructionStep.navigation">
					Navigate to
					<a style="color: cornflowerblue; text-decoration: underline;"
					   *ngIf="instructionStep.navigation.state"
					   (click)="navigateToPage();"
					   [routerLink]="instructionStep.navigation.state">
						{{instructionStep.navigation.text}}
					</a>
					<a style="color: cornflowerblue; text-decoration: underline;"
					   *ngIf="instructionStep.navigation.url"
					   (click)="navigateToPage();"
					   href="{{instructionStep.navigation.url}}">
						{{instructionStep.navigation.text}}
					</a>
				</span>
			</li>
		</ul>
	</div>

	<div class="flxRow hSpace1" style="padding: 25px 0; align-items: center; margin-top: 0;" *ngIf="currentStep">
		<btn-c class="popup-action-btn animated fadeIn"
			   *ngIf="!currentStep.last && !allStepsAreDone"
			   variant="outlined"
			   (action)="skipStep();">
			Skip this step
		</btn-c>
		<btn-c class="popup-action-btn animated fadeIn"
			   *ngIf="!allStepsAreDone || currentStep?.message"
			   [isDisabled]="completeStepInProcess"
			   (action)="completeStep();">
			All done! {{!currentStep.last ? 'Next step' : ''}}
		</btn-c>

		<!--when all steps are done. navigate to the next step if there is-->
		<btn-c class="popup-action-btn animated fadeIn"
			   *ngIf="allStepsAreDone && !currentStep.last"
			   variant="outlined"
			   (action)="skipStep();">
			Next step
		</btn-c>
		<!---->
	</div>
</div>
