<div class="flxClmn bs-enabled">
	<div class="page-title-container flex-row">
		<div class="flex-column justify-content-center f-1-0">
			<div class="page-title-inner-container">
				<span class="page-title">Campaign Templates</span>
			</div>
			<div class="page-subtitle-container">
				<span class="page-subtitle">View, edit, and create email templates to be used in Threat Simulation campaigns</span>
			</div>
		</div>
	</div>

	<!--loader-->
	<div class="f-1-0 flex-column centralize" *ngIf="_.isUndefined(isThisFeatureSupported)">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div class="page-inner-content-holder flex-column" style="min-height: calc(100vh - 50px - 94px);"
		 *ngIf="isThisFeatureSupported">
		<div class="flex-row" style="flex: 1 0; flex-wrap: wrap; overflow: auto;">
			<!--list of templates (left side)-->
			<div class="flex-column ms-0" style="flex: 3 0; margin: 7px; padding: 5px; min-width: 625px; min-height: 350px; background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent);">
				<div class="flex-column" style="flex: 1 0; overflow: auto;" >
					<div class="w100 flex-row justify-content-between" style="background: #677184; padding: calc(3px + 0.4vw); align-items: center; z-index: 1; min-height: 42px;">
						<btn-c style="background: white;"
							   variant="outlined" endIcon="fas fa-plus"
							   [isDisabled]="getTemplatesInProcess"
							   (action)="startAddNewTemplate();">
							Create Template
						</btn-c>
						<div class="flex-row align-items-center justify-content-end gap-2" style="padding-left: 20px;">
							<span *ngIf="_.reject(templates, 'hide').length" style="color: white; padding: 5px;">
                                {{_.reject(templates, 'hide').length}} record{{_.reject(templates, 'hide').length === 1 ? '' : 's'}}
                            </span>
							<search-box [(model)]="searchTemplateTxt" placeholder="Search template"
										(modelChange)="triggerSearch();"
										style="min-width: 200px;">
							</search-box>
						</div>
					</div>
					<!--lists-->
					<trustifi-table-c
						class="trustifi-table short-action-column" style="flex: 1 0; z-index: 5; background: white;"
						[list]="templates"
						[headers]="[
							{text: 'Name', sortKey: 'name'},
							{text: 'Category', sortKey: 'category', width: '125px'},
						]"
						[cellsPrototype]="[
							{html: 'nameCell'},
							{textKey: 'category'}
						]"
						[bulkActions]="{
							enable: true,
							selectBulkActionCb:	selectMultipleTemplatesAction,
							initBulkActionsFunction: showTemplateBulkActions
						}"
						[cellActions]="{
							enable: true,
							selectItemActionCb:	selectTemplateAction,
							initActionsFunction: showTemplateActions
						}"
						(searchFunction)="searchTemplates()"
						(onAnyItemSelected)="!$event.isItemSelectCheckboxClicked && selectActiveTemplate($event.item)"
						[options]="{
							loadingTableFlag: getTemplatesInProcess,
							soloSelected: true,
							defaultSortKey: '-role',
							hideContentAboveTable: true,
							itemsNameSingular: 'template',
						}"
					>
						<!--log action cells-->
						<ng-template #customCells let-item let-cellId="cellId">
							<div *ngIf="cellId === 'nameCell'"
								 class="flex-row gap-1 align-items-end">
								<i class="fa fa-calendar-day text-warning align-self-center me-1" style="font-size: 16px;" tooltip="Upcoming template" *ngIf="item.upcoming"></i>
								<span>{{item.name}}</span>
								<span class="text-muted" style="font-size: 11px;" *ngIf="item.isSystemTemplate">(System)</span>
							</div>
						</ng-template>
						<!---->
					</trustifi-table-c>
				</div>
			</div>

			<!--list details area (right side)-->
			<div class="flex-column me-0" style="flex: 7 0; margin: 7px; min-width: 600px; align-items: center; background: #f7f7f7; position: relative; overflow: auto; padding: 10px;">
				<div class="flex-row gap-2 px-3 mb-2 bg-light align-self-stretch align-items-center justify-content-between" style="height: 36px;"
					 *ngIf="activeTemplate">
					<span class="fw-500 text-muted m-0" style="font-size: 16px;">{{activeTemplate.isSystemTemplate ? 'System' : 'Custom'}} Template</span>

					<div class="flex-row gap-2" *ngIf="activeTemplate.role !== dic.CONSTANTS.threatSimulationTemplateTypes.default">
						<btn-c class="popup-action-btn" id="templatesSaveTemplate"
							   [loading]="updateTemplateInProcess"
							   (action)="saveTemplate()">
							{{activeTemplate._id ? "Update Changes": "Save Template"}}
						</btn-c>
						<btn-c class="popup-action-btn" id="templatesCancelTemplate" variant="text"
							   *ngIf="!activeTemplate._id"
							   [loading]="updateTemplateInProcess"
							   (action)="cancelAddNewTemplate()">
							Cancel
						</btn-c>
					</div>
				</div>
				<div class="flex-column gap-3 w100" style="flex:1 0; overflow: auto; background: white; padding: 15px;" *ngIf="activeTemplate">
					<div class="flex-row">
						<div class="flex-row gap-1 align-items-center flex-shrink-0" style="flex-basis: 140px;">
							<span>Template Name</span>
						</div>
						<input type="text" id="templateNameInput" class="f-1-0"
							   (keyup)="isMissingName = false;"
							   [ngClass]="{'validation-error' : isMissingName, 'bg-light' : activeTemplate.isSystemTemplate, 'bg-white': !activeTemplate.isSystemTemplate}"
							   [(ngModel)]="activeTemplate.name"
							   [disabled]="activeTemplate.isSystemTemplate">
					</div>
					<div class="flex-row">
						<span class="mt-1 flex-shrink-0" style="flex-basis: 140px;">Template Description</span>
						<textarea style="height: auto; min-height: 65px; resize: none;" type="text" id="templateDescriptionInput" class="f-1-0 "
								  [(ngModel)]="activeTemplate.description"
								  [ngClass]="activeTemplate.isSystemTemplate ? 'bg-light' : 'bg-white'"
								  [disabled]="activeTemplate.isSystemTemplate">
						</textarea>
					</div>
					<div class="flex-row align-items-center" style="height: 30px;" *ngIf="!activeTemplate.isSystemTemplate">
						<span class="flex-shrink-0" style="flex-basis: 140px;">Template Category</span>
						<app-dropdown-c style="width: 250px;" [text]="activeTemplate.category"
										*ngIf="!activeTemplate.isSystemTemplate">
							<li *ngFor="let action of dic.CONSTANTS.threatSimulationTemplateCategories | keyvalue"
								[explanationC]="action.value.tooltip"
								(click)="activeTemplate.category = action.value.name;">
								<span>{{action.value.name}}</span>
							</li>
						</app-dropdown-c>
					</div>
					<div class="flex-row">
						<span class="flex-shrink-0" style="flex-basis: 140px;"></span><!--padder-->
						<hr class="f-1-0" />
					</div>
					<div class="flex-row">
						<span class="mt-1 flex-shrink-0" style="flex-basis: 140px;">Sender Details</span>
						<div class="flex-column gap-1 f-1-0">
							<div class="flex-row gap-2 align-items-center">
								<span class="flex-shrink-0" style="flex-basis: 60px;"><b>From</b>:</span>
								<input type="text" id="templateEmailFromNameInput" class="f-1-0" style="max-width: 250px;"
									   placeholder="Name"
									   [(ngModel)]="activeTemplate.from.name"
									   [ngClass]="activeTemplate.isSystemTemplate ? 'bg-light' : 'bg-white'"
									   [disabled]="activeTemplate.isSystemTemplate">
								<input type="text" id="templateEmailFromEmailInput" class="f-1-0" style="max-width: 350px;"
									   placeholder="Email Address"
									   [(ngModel)]="activeTemplate.from.address"
									   [ngClass]="activeTemplate.isSystemTemplate ? 'bg-light' : 'bg-white'"
									   [disabled]="activeTemplate.isSystemTemplate">
							</div>
							<div class="flex-row gap-2 align-items-center">
								<span class="flex-shrink-0" style="flex-basis: 60px;"><b>Reply To</b>:</span>
								<input type="text" id="templateEmailReplyToNameInput" class="f-1-0" style="max-width: 250px;"
									   placeholder="Name"
									   [(ngModel)]="activeTemplate.reply_to.name"
									   [ngClass]="activeTemplate.isSystemTemplate ? 'bg-light' : 'bg-white'"
									   [disabled]="activeTemplate.isSystemTemplate">
								<input type="text" id="templateEmailReplyToEmailInput" class="f-1-0" style="max-width: 350px;"
									   placeholder="Email Address"
									   [(ngModel)]="activeTemplate.reply_to.address"
									   [ngClass]="activeTemplate.isSystemTemplate ? 'bg-light' : 'bg-white'"
									   [disabled]="activeTemplate.isSystemTemplate">
							</div>
						</div>
					</div>
					<div class="flex-row align-items-center">
						<span class="flex-shrink-0" style="flex-basis: 140px;">Email Subject</span>
						<input type="text" id="templateSubjectInput" class="f-1-0"
							   [(ngModel)]="activeTemplate.title"
							   [ngClass]="activeTemplate.isSystemTemplate ? 'bg-light' : 'bg-white'"
							   [disabled]="activeTemplate.isSystemTemplate">
					</div>
					<div class="flex-row align-items-center">
						<div class="flex-row gap-2 align-items-center flex-shrink-0" style="flex-basis: 140px;">
							<span>Tracked Links</span>
							<i style="font-weight: 400;" class="fas fa-question-circle"
							   tooltip="These links will be tracked to detect which users have clicked on them. The links must be added to the template below, and only the links specified here will be tracked"></i>
						</div>
						<div class="flex-column gap-2 p-1 bg-light f-1-0 ellipsis" style="border: solid 1px #cccccc; min-height: 30px; border-radius: 4px;">
							<div class="flex-row align-items-center gap-2" *ngFor="let trackedLink of activeTemplate.lookup_links; let index = index">
								<ng-container *ngIf="!trackedLink.isEditMode">
									<span class="fw-bold px-2 py-1 bg-white rounded-pill">{{trackedLink.name}}</span>
									<a class="fw-light ellipsis hvr-underline" [href]="trackedLink.link" target="_blank" style="font-size: 12px;">{{trackedLink.link}}</a>
									<div class="flex-row align-items-center gap-1">
										<btn-c class="shrunk"
											   *ngIf="!activeTemplate.isSystemTemplate"
											   variant="text"
											   endIcon="fa fa-edit"
											   (action)="startEditTrackedLink(trackedLink)">
										</btn-c>
										<btn-c class="shrunk"
											   *ngIf="!activeTemplate.isSystemTemplate"
											   variant="text" color="danger"
											   endIcon="fa fa-trash-alt"
											   (action)="removeTrackedLink(trackedLink)">
										</btn-c>
									</div>
								</ng-container>

								<!--Edit link mode-->
								<ng-container *ngIf="trackedLink.isEditMode">
									<div class="flex-row gap-1 boder border-light p-1 w-100">
										<input type="text" id="trackedLinkEditNameInput{{index}}" class="f-1-0" style="max-width: 130px;"
											   placeholder="Name"
											   [ngClass]="{'validation-error' : trackedLink.nameError}"
											   (keyup)="trackedLink.nameError = ''"
											   (keyup.enter)="confirmEditTrackedLink(trackedLink, true)"
											   [(ngModel)]="trackedLink.edit.name">
										<input type="text" id="trackedLinkEditUrlInput{{index}}" class="f-1-0" style="max-width: 550px;"
											   placeholder="URL"
											   [ngClass]="{'validation-error' : trackedLink.linkError}"
											   (keyup)="trackedLink.linkError = ''"
											   (keyup.enter)="confirmEditTrackedLink(trackedLink, true)"
											   [(ngModel)]="trackedLink.edit.link">
										<btn-c style="min-width: 0;"
											   endIcon="zmdi zmdi-check"
											   (action)="confirmEditTrackedLink(trackedLink, true)">
										</btn-c>
										<btn-c style="min-width: 0;"
											   endIcon="zmdi zmdi-close" color="danger"
											   variant="text"
											   btnTooltip="Cancel edit"
											   (action)="confirmEditTrackedLink(trackedLink, false, index);">
										</btn-c>
									</div>
								</ng-container>
							</div>
							<btn-c [hidden]="activeTemplate.isSystemTemplate || _.some(activeTemplate.lookup_links, 'isEditMode')"
								   style="min-width: 0;"
								   endIcon="fa fa-plus"
								   variant="text"
								   (action)="addNewTrackedLink();">
								Add
							</btn-c>
						</div>
					</div>
					<div class="flex-row show-on-hover-container-1">
						<div class="flex-column gap-4 flex-shrink-0" style="flex-basis: 140px;">
							<span>Message</span>
							<div class="flex-column gap-2 w-100 p-2 ps-0 align-items-stretch">
								<div *ngIf="activeTemplate.role !== dic.CONSTANTS.threatSimulationTemplateTypes.default" class="w100" id="templatesLoadHTML"
									 [(file)]="htmlFile"
									 ngfSelect
									 accept=".txt, .htm, .html, text/plain, text/html"
									 (fileChange)="loadHtml()">
									<btn-c class="w100" variant="outlined" color="#595959">Load HTML</btn-c>
								</div>
								<btn-c class="w100" id="templatesPreview" variant="outlined" color="#595959" (action)="previewTemplate()">Preview</btn-c>
								<btn-c *ngIf="activeTemplate.role !== dic.CONSTANTS.threatSimulationTemplateTypes.default" class="w100" id="addPhishingLink" variant="outlined" color="#595959" (action)="addPhishingLink()">Add Phishing Link</btn-c>
								<btn-c *ngIf="activeTemplate._id || activeTemplate.isSystemTemplate" class="w100" id="templatesClone" variant="outlined" color="#595959" (action)="cloneTemplate(activeTemplate)">Clone</btn-c>
								<btn-c class="w100" id="templatesExport" variant="outlined" color="#595959" (action)="exportTemplate()">Export</btn-c>
							</div>
						</div>
						<div class="f-1-0 flxClmn" id="summernoteContainer"
							 (dragenter)="toggleSummernoteDragState(true, $event)"
							 (dragleave)="toggleSummernoteDragState(false, $event)"
							 (dragend)="toggleSummernoteDragState(false, $event)">
							<div class="f-1-0 summernote-editor" id="summernote" style="min-height: 400px;"
								 [ngxSummernote]="summernoteOptions"
								 [ngxSummernoteDisabled]="activeTemplate.isSystemTemplate"
								 [(ngModel)]="activeTemplate.html">
							</div>
						</div>
					</div>
					<div class="flex-row">
						<!--padder-->
						<div style="flex-basis: 140px;"></div>
						<!---->

						<!--System templates parameters-->
						<div class="template-dynamic-fields-wrapper mt-3"
							 (ngInit)="showDynamicFields = false">
							<div class="template-dynamic-fields-general-description">
								<div class="header" style="margin-bottom: 5px;">Templates dynamic fields</div>
								<ul>
									<li>Words surrounded by double curly brackets (i.e. - {{dynamicFieldExample}} ) are dynamic fields.</li>
									<li>When the email is sent, the content inside these fields will be changed according to your settings.</li>
								</ul>
								<a class="more-information" style="cursor: pointer;"
								   (click)="showDynamicFields = !showDynamicFields">
									More information
									<i class="zmdi" style="margin-left: 2px;"
									   [ngClass]="showDynamicFields ? 'zmdi-chevron-up': 'zmdi-chevron-down'"></i>
								</a>
							</div>
							<div class="template-dynamic-fields" *ngIf="showDynamicFields" style="max-height: 200px;">
								<div class="template-dynamic-field"
									 *ngFor="let field of dic.CONSTANTS.templateDynamicFieldsForThreatSimulation | keyvalue: gs.returnZero">
									<span class="name">{{field.key}}</span>:
									<span>{{field.value}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="!activeTemplate" class="flex-column justify-content-center" style="align-items: center; flex: 1 0;">
					<i class="fas hvr-opacity fa-list-alt" style="font-size: 4vw;  opacity: 0.1;"></i>
				</div>
			</div>
		</div>
	</div>

	<!--feature not supported-->
	<threat-simulation-not-supported style="display: contents;" *ngIf="isThisFeatureSupported === false"></threat-simulation-not-supported>
	<!---->

	<popup-c *ngIf="previewTemplatePopup?.show"
			 head="Preview Email"
			 (closeCb)="previewTemplatePopup = null"
			 [buttons]="{cancel: 'OK'}" size="large">
		<div class="flex-column f-1-0" style="overflow: auto;">
			<email-viewer [content]="previewTemplatePopup.emailContent" style="overflow: auto; flex: 1 0; width: 100%; min-width: 220px; border: 1px solid #ccc;"></email-viewer>
		</div>
	</popup-c>
</div>
