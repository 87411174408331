import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import _ from "lodash";
import {DICTIONARY} from "../../dictionary";
import {RouteService} from "../../services/routeService";
import {GeneralService} from "../../services/generalService";

@Component({
	selector: 'onboarding-wizard-steps',
	templateUrl: './onboarding-wizard-steps.component.html',
})
export class OnboardingWizardStepsComponent implements OnInit,OnChanges{
	@Input() currentSetupScope: any;
	@Input() navigateToStep: any;
	@Input() userOnboardingInfo: any;
	@Output() currentSetupScopeChange = new EventEmitter();
	@Output() doneCb = new EventEmitter();

	constructor(
		private gs:GeneralService,
		private rs:RouteService) {
	}

	dic = DICTIONARY;
	rawStepsData;
	completeStepInProcess;
	currentStep;
	allStepsAreDone;

	ngOnInit() {
		this.initStepsRawData();
		this.unifyStepsInfo();

		// check if user navigated to page through onboarding step and now is opening it at the same page, expecting to continue with the same step
		if (this.navigateToStep) {
			this.updateSetupScope(this.navigateToStep.scope);
			const step = _.find<any>(this.currentSetupScope.userStepsInfo, {name: this.navigateToStep.stepName});
			this.updateStepInfo(step);
			this.gs.cancelOnboardingActiveStepSubj.next({});
		}
		else {
			this.updateStepInfo(this.currentSetupScope.userStepsInfo[0]);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.currentSetupScope && this.currentSetupScope) {
			this.updateStepInfo(this.currentSetupScope.userStepsInfo[0]);
		}
	}

	updateSetupScope = (scope) => {
		this.currentSetupScope = {
			name: scope,
			userStepsInfo: scope === this.dic.CONSTANTS.onboardingSetupScopes.outbound ?
				this.userOnboardingInfo.outbound_steps :
				this.userOnboardingInfo.inbound_steps
		};
		this.updateStepInfo(this.currentSetupScope.userStepsInfo[0]);
	}

	completeStep = () => {
		this.completeStepInProcess = true;
		const data = {
			action: 'updateStepStatus',
			name: this.currentStep.name,
			type: this.currentSetupScope.name,
			status: this.dic.CONSTANTS.onboardingStepStatus.done
		}
		this.rs.updateOnboardingQuestionnaire(data).then(response => {
			this.currentStep.status = this.dic.CONSTANTS.onboardingStepStatus.done;
			this.currentStep.message = response.message || '';

			this.completeStepInProcess = false;

			this.goToClosestFirstPendingStep(true);
		});
	}

	skipStep = () => {
		const stepInUserInfoObj = _.find<any>(this.currentSetupScope.userStepsInfo, {name: this.currentStep.name});
		const nextStep = this.currentSetupScope.userStepsInfo[this.currentSetupScope.userStepsInfo.indexOf(stepInUserInfoObj) + 1];
		if (nextStep) {
			this.updateStepInfo(nextStep);
		}
	}

	updateStepInfo = (step) => {
		this.currentStep = step;
	}

	goToClosestFirstPendingStep = (enableCompletedScreen) => {
		let nextStep;

		// Find "pending" step in scope -> if not, check if there is another scope -> find "pending" step in other scope -> if not, send to "completed" screen
		if (this.currentSetupScope.name === this.dic.CONSTANTS.onboardingSetupScopes.outbound) {
			nextStep = _.find<any>(this.userOnboardingInfo.outbound_steps, step => {return step.name !== (this.currentStep && this.currentStep.name) && step.status === this.dic.CONSTANTS.onboardingStepStatus.pending});
			if (nextStep) {
				this.allStepsAreDone = false;
				this.updateStepInfo(nextStep);
			}
			else {
				this.allStepsAreDone = true;

				nextStep = _.find<any>(this.userOnboardingInfo.inbound_steps, step => {return step.name !== (this.currentStep && this.currentStep.name) && step.status === this.dic.CONSTANTS.onboardingStepStatus.pending});
				if (nextStep) {
					this.currentSetupScope = {
						name: this.dic.CONSTANTS.onboardingSetupScopes.inbound,
						userStepsInfo: this.userOnboardingInfo.inbound_steps
					};
					this.allStepsAreDone = false;
					this.updateStepInfo(nextStep);
				}
				else {
					if (!enableCompletedScreen) {
						this.updateStepInfo(this.userOnboardingInfo.outbound_steps[0]);
						return;
					}
					this.doneCb.emit();
				}
			}
		}
		else {
			nextStep = _.find<any>(this.userOnboardingInfo.inbound_steps, step => {return step.name !== (this.currentStep && this.currentStep.name) && step.status === this.dic.CONSTANTS.onboardingStepStatus.pending});
			if (nextStep) {
				this.allStepsAreDone = false;
				this.updateStepInfo(nextStep);
			}
			else {
				this.allStepsAreDone = true;

				nextStep = _.find<any>(this.userOnboardingInfo.outbound_steps, step => {return step.name !== (this.currentStep && this.currentStep.name) && step.status === this.dic.CONSTANTS.onboardingStepStatus.pending});
				if (nextStep) {
					this.currentSetupScope = {
						name: this.dic.CONSTANTS.onboardingSetupScopes.outbound,
						userStepsInfo: this.userOnboardingInfo.outbound_steps
					};
					this.allStepsAreDone = false;
					this.updateStepInfo(nextStep);
				}
				else {
					if (!enableCompletedScreen) {
						this.updateStepInfo(this.userOnboardingInfo.outbound_steps[0]);
						return;
					}
					this.doneCb.emit();
				}
			}
		}
	}

	navigateToPage = () => {
		this.gs.navigatedThroughOnboardingSubj.next({scope: this.currentSetupScope.name, stepName: this.currentStep.name});
	}

	initStepsRawData = () => {
		// should contain only the setup steps with their related information
		const isGoogle = this.userOnboardingInfo.questionnaire.setup.environment === this.dic.CONSTANTS.onboardingFormValues.currentEnv.gSuite;
		const isOnPermises = this.userOnboardingInfo.questionnaire.setup.environment === this.dic.CONSTANTS.onboardingFormValues.currentEnv.onPermises;
		const isInboundAndOutbound = this.userOnboardingInfo.questionnaire.organization.scope.indexOf('outbound') > -1 &&
			this.userOnboardingInfo.questionnaire.organization.scope.indexOf('inbound') > -1

		this.rawStepsData = {
			outbound: [
				{
					name: this.dic.CONSTANTS.onboardingSteps.payment,
					label: 'Payment',
					title: `Upgrade your Trustifi plan`,
					subtitle : 'Upgrade to a "Pro" plan and acquire licenses for your users',
					iconClass: 'zmdi zmdi-paypal',
					instructions: [
						{ navigation: {
								text: 'My Plan page',
								state: ['/' + this.dic.CONSTANTS.appStates.accountDetails, this.dic.CONSTANTS.accountDetailsPages.myPlan],
							},
						},
						{ text: `Fill in the amount of licenses you need and your payment info.`},
						{ text: `Once the process is completed, the page will be refreshed and you will have access to admin features.`},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.domainVerification,
					label: 'Domains settings',
					title: `Add and verify your organization’s domain(s)`,
					subtitle : 'Domain verification is important to make sure your emails are delivered successfully and securely, while conforming with your domain\'s SPF and DKIM policies',
					instructions: [
						{ navigation: {
								text: `Outbound Plan Settings page > ${this.dic.CONSTANTS.outboundPlanSettingsPageTabs.domains} tab`,
								state: ['/' + this.dic.CONSTANTS.appStates.adminOutbound, this.dic.CONSTANTS.adminPages.outbound.sharedPlan, this.dic.CONSTANTS.outboundPlanSettingsPageTabs.domains],
							},
						},
						{ text: `Add each of your organization's domains from which you send emails to the table.`},
						{ text: `Follow the instructions in the document below to verify the domains.`},
						{ link: {
								text: `How to verify my domains`,
								url: 'https://docs.trustifi.com/docs/domain-verification'
							}},
						{ text: `The process is successful when the domains are listed as "Verified".`},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.outboundErConfiguration,
					label: 'Email relay settings',
					title: `Connect to the Outbound Email Relay to protect your outgoing email traffic`,
					iconClass: 'zmdi zmdi-shield-security',
					instructions: [
						{ navigation: {
								text: `Outbound Plan Settings page > ${this.dic.CONSTANTS.outboundPlanSettingsPageTabs.emailFlowIntegration} tab`,
								state: ['/' + this.dic.CONSTANTS.appStates.adminOutbound, this.dic.CONSTANTS.adminPages.outbound.sharedPlan, this.dic.CONSTANTS.outboundPlanSettingsPageTabs.emailFlowIntegration],
							},
						},
						{ text: `Enable the Email Relay (your secret key will be automatically generated).`},
						{ text: 'Follow the instructions in the document below to configure the Email Relay on ' + (isGoogle ? 'Google Workspace.' : 'Microsoft Exchange.') + ' This should be done together with a Trustifi support person or engineer.'},
						{ link: {
								text: `How to set up the Outbound Email Relay` ,
								url: isGoogle ? 'https://docs.trustifi.com/docs/configuration-2' : isOnPermises ? 'https://docs.trustifi.com/docs/configuration-7' : 'https://docs.trustifi.com/docs/configuration-using-trustifi-mta'
							}},
						{ text: `Follow the testing instructions in the document below to make sure mail flow is working properly.`},
						{ link: {
								text: `Testing the Outbound Email Relay` ,
								url: isGoogle ? 'https://docs.trustifi.com/docs/testing' : 'https://docs.trustifi.com/docs/testing-2'
							}},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.outboundApiIntegrations,
					label: 'API integration',
					title: `Integrate Trustifi with your mail server API`,
					subtitle : 'This will allow Trustifi to sync your contacts and groups, as well as providing additional functionality',
					instructions: [
						{ navigation: {
								text: `Outbound Plan Settings page > ${this.dic.CONSTANTS.outboundPlanSettingsPageTabs.integrations} tab`,
								state: ['/' + this.dic.CONSTANTS.appStates.adminOutbound, this.dic.CONSTANTS.adminPages.outbound.sharedPlan, this.dic.CONSTANTS.outboundPlanSettingsPageTabs.integrations],
							},
						},
						{ text: `Open the "Integrations" tab.`},
						{ text: 'Click on the ' + (isGoogle ? 'Google' : 'Exchange') + ' integration button and click on "Connect".'},
						{ text: isGoogle ? 'Follow the instructions in the pop-up to create a connection with your Google server.' : 'Enter your Exchange admin credentials (for single use, we never keep them) and approve.'},
						{ text: `Once the integration status is changed to "Connected", click on the "Sync" button to sync your groups from your mail server. This may take a few minutes depending on how many groups you have.`},
						{ text: `When the sync process is finished, you can click on "Show groups" to view them.`},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.lfConfigurations,
					label: 'Look and Feel',
					title: 'Customize your Look & Feel',
					subtitle : 'Configure your encrypted emails and web portal UI to match your organization\'s branding',
					iconClass: 'fas fa-palette',
					instructions: [
						{ navigation: {
								text: 'Look & Feel page',
								state: ['/' + this.dic.CONSTANTS.appStates.adminOutbound, this.dic.CONSTANTS.adminPages.outbound.lf],
							},
						},
						{ text: `Customize your email template, UI colors, and logo using the provided options.`},
						{ text: `Review the Look & Feel customization guide for full information and steps.`},
						{ link: {
								text: `Look & Feel customization guide` ,
								url: 'https://docs.trustifi.com/docs/look-feel-customization'
							}},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.dlpRules,
					label: 'DLP rules',
					title: 'Review and configure your DLP rules and policies',
					subtitle : 'Trustifi allows creating highly customized DLP rules and policies to protect your outgoing email traffic',
					iconClass: 'zmdi zmdi-lock-outline',
					instructions: [
						{ navigation: {
								text: `Rules & Policies page > ${this.dic.CONSTANTS.outboundRulesAndPoliciesPageTabs.rules} tab`,
								state: ['/' + this.dic.CONSTANTS.appStates.adminOutbound, this.dic.CONSTANTS.adminPages.outbound.policyManagement, this.dic.CONSTANTS.outboundRulesAndPoliciesPageTabs.rules],
							},
						},
						{ text: `You can create customized DLP rules by clicking on "Create New Rule".`},
						{ text: `view the Rules and Policies guide to get more information on how rules and policies work.`},
						{ link: {
								text: `Rules and Policies guide` ,
								url: 'https://docs.trustifi.com/docs/dlp-rules-policies-guide'
							}},
						{ text: `Review the default policies by scrolling down to the "Policies" section. You may change these policies at any time to fit your organization's needs.`},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.outboundAddinGuide,
					label: 'Trustifi add-in',
					title: 'Protect your outbound emails with the Trustifi add-in',
					subtitle : 'Install and use the Trustifi add-in to encrypt and fully control your outbound secure emails',
					iconClass: 'fas fa-puzzle-piece',
					instructions: [
						{ text: `Install the Trustifi add-in. View our installation guide for help.${isInboundAndOutbound ? '<br>If you already installed the add-in from the inbound flow then you are good.' : ''}`},
						{ link: {
								text: `${isGoogle ? 'Gmail add-in' : 'Outlook add-in'} installation` ,
								url: `${isGoogle ? 'https://docs.trustifi.com/docs/installation-2': 'https://docs.trustifi.com/docs/installation-windows-outlook'}`
							}},
						{ text: `Use the Trustifi add-in to apply encryption, MFA, and many other security features to your emails. View our usage guide for help.`},
						{ link: {
								text: `${isGoogle ? 'Gmail add-in' : 'Outlook add-in'} usage` ,
								url: `${isGoogle ? 'https://docs.trustifi.com/docs/usage-2': 'https://docs.trustifi.com/docs/usage-1'}`
							}},

						{ text: `View our How-To Videos for more information`},
						{ link: {
								text: `How-To Videos` ,
								url: `https://www.trustifi.com/how-to-videos/`
							}},
						{ text: `To add more users to your Trustifi plan, follow the instructions in the user onboarding guide.`},
						{ link: {
								text: `User onboarding guide` ,
								url: `https://docs.trustifi.com/docs/user-onboarding-guide`
							}},
						{ text: 'If you need any help, you can always contact support@trustificorp.com'}
					]
				},
			],

			inbound: [
				{
					name: this.dic.CONSTANTS.onboardingSteps.payment,
					label: 'Payment',
					title: `Upgrade your Trustifi plan`,
					subtitle : 'Upgrade to a "Pro" plan and acquire licenses for your users',
					iconClass: 'zmdi zmdi-paypal',
					instructions: [
						{ navigation: {
								text: 'My Plan page',
								state: ['/' + this.dic.CONSTANTS.appStates.accountDetails, this.dic.CONSTANTS.accountDetailsPages.myPlan],
							},
						},
						{ text: `Fill in the amount of licenses you need and your payment info.`},
						{ text: `Once the process is completed, the page will be refreshed and you will have access to admin features.`},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.inboundAddinGuide,
					label: 'Trustifi add-in',
					title: 'Protect your inbound emails with the Trustifi add-in',
					subtitle : 'Install and use the Trustifi add-in to protect your inbox from threats and spam',
					iconClass: 'fas fa-puzzle-piece',
					instructions: [
						{ text: `Install the Trustifi add-in. View our installation guide for help.${isInboundAndOutbound ? '<br>If you already installed the add-in from the outbound flow then you are good.' : ''}`},
						{ link: {
								text: `${isGoogle ? 'Gmail add-in' : 'Outlook add-in'} installation` ,
								url: `${isGoogle ? 'https://docs.trustifi.com/docs/installation-2': 'https://docs.trustifi.com/docs/installation-windows-outlook'}`
							}},
						{ text: `Use the Trustifi add-in to scan emails in your inbox. Trustifi can detect many different threats including phishing, impersonation, spam, spoofing, fraud, and many more.`},
						{ text: `View our How-To Videos for more information`},
						{ link: {
								text: `How-To Videos` ,
								url: `https://www.trustifi.com/how-to-videos/`
							}},
						{ text: 'If you need any help, you can always contact support@trustificorp.com'}
					]
				},

				{
					name: this.dic.CONSTANTS.onboardingSteps.inboundErConfiguration,
					label: 'Email relay settings',
					title: `Connect to the Inbound Email Relay to protect your mailboxes against threats`,
					iconClass: 'fas fa-user-shield',
					instructions: [
						{ navigation: {
								text: `Inbound Plan Settings page > ${this.dic.CONSTANTS.inboundPlanSettingsPageTabs.emailFlowIntegration} tab`,
								state: ['/' + this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.plan, this.dic.CONSTANTS.inboundPlanSettingsPageTabs.emailFlowIntegration],
							},
						},
						{ text: `Add the domain, MTA, and port for each of your organization's domains. <br/><span style="font-size: 0.85rem">Click "Resolve host" to automatically add the MTA. The default port is 25.</span>`},
						{ text: `Enable the Email Relay (your secret key will be automatically generated).`},
						{ text: 'Follow the instructions in the document below to configure the Email Relay on ' + (isGoogle ? 'Google Workspace.' : 'Microsoft Exchange.') + ' This should be done together with a Trustifi support person or engineer.'},
						{ link: {
								text: `How to set up the Inbound Email Relay` ,
								url: isGoogle ? 'https://docs.trustifi.com/docs/configuration-5' : 'https://docs.trustifi.com/docs/configuration-4'
							}},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.inboundShieldGraph,
					label: 'Inbound Shield',
					title: 'Connect to Inbound Shield to protect your mailboxes against threats',
					subtitle : 'Follow the steps below to integrate Inbound Shield using an API',
					iconClass: 'fas fa-user-shield',
					instructions: [
						{ navigation: {
								text: 'Inbound Shield page',
								state: ['/' + this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.inboundShield],
							},
						},
						{ text: `Click on the Exchange integration button and click on "Connect".`},
						{ text: `Enter your Exchange admin credentials and approve.`},
						{ text: `Open the "Mailbox Management" page and wait until all mailboxes are loaded.`},
						{ text: `Select which mailboxes you want to protect, or click on "Protect All".`},
						{ text: `Review the Inbound Shield integration guide for full information.`},
						{ link: {
								text: `Inbound Shield integration guide` ,
								url: 'https://docs.trustifi.com/docs/api-integration-guide'
							}},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.inboundApiIntegrations,
					label: 'API integration',
					title: 'Integrate Trustifi with your mail server API',
					subtitle : 'This will allow Trustifi to sync your contacts and groups, as well as providing additional functionality',
					instructions: [
						{ navigation: {
								text: `Inbound Plan Settings page > ${this.dic.CONSTANTS.inboundPlanSettingsPageTabs.integrations} tab`,
								state: ['/' + this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.plan, this.dic.CONSTANTS.inboundPlanSettingsPageTabs.integrations],
							},
						},
						{ text: 'Click on the ' + (isGoogle ? 'Google' : 'Exchange') + ' integration button and click on "Connect".'},
						{ text: isGoogle ? 'Follow the instructions in the pop-up to create a connection with your Google server.' : 'Enter your Exchange admin credentials and approve.'},
						{ text: `Once the integration status is changed to "Connected", click on the "Sync" button to sync your groups from your mail server. This may take a few minutes depending on how many groups you have.`},
						{ text: `When the sync process is finished, you can click on "Show groups" to view them.`},
					]
				},
				{
					name: this.dic.CONSTANTS.onboardingSteps.tpRules,
					label: 'Threat Prevention Rules',
					title: 'Configure your threat protection rules',
					subtitle : 'Decide how different types of emails should be handled - which emails should be quarantined, and who should be notified',
					iconClass: 'fas fa-shield-virus',
					instructions: [
						{ navigation: {
								text: `Inbound Shield page > ${this.dic.CONSTANTS.inboundShieldSettingsPageTabs.threatPreventionRules} tab`,
								state: ['/' + this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.inboundShield, this.dic.CONSTANTS.inboundShieldSettingsPageTabs.threatPreventionRules],
							}
						},
						{ text: `If you want to start blocking threats right away, review the default configuration for each threat type and make changes as needed.`},
						{ text: `If you want to scan emails, but not to start blocking them yet, click on "Monitor Mode" to automatically set the configurations for monitoring/or security reviewers, append a warning label to the email, and more.`},
						{ text: `Review the Threat Prevention configuration guide.`},
						{ link: {
								text: `Threat Prevention configuration guide`,
								url: 'https://docs.trustifi.com/docs/configuration-3#rules-for-threats'
							}},

					]
				},
			]
		}
	}

	unifyStepsInfo = () => {
		if (this.userOnboardingInfo.outbound_steps && this.userOnboardingInfo.outbound_steps.length) {
			for (let idx = 0; idx < this.userOnboardingInfo.outbound_steps.length; idx++) {
				const stepMetaInfo = _.find<any>(this.rawStepsData.outbound, {name: this.userOnboardingInfo.outbound_steps[idx].name});
				if (stepMetaInfo) {
					this.userOnboardingInfo.outbound_steps[idx] = {...this.userOnboardingInfo.outbound_steps[idx], ...stepMetaInfo};
				}
			}
			this.userOnboardingInfo.outbound_steps[this.userOnboardingInfo.outbound_steps.length-1].last = true;
		}

		if (this.userOnboardingInfo.inbound_steps && this.userOnboardingInfo.inbound_steps.length) {
			for (let idx = 0; idx < this.userOnboardingInfo.inbound_steps.length; idx++) {
				const stepMetaInfo = _.find<any>(this.rawStepsData.inbound, {name: this.userOnboardingInfo.inbound_steps[idx].name});
				if (stepMetaInfo) {
					this.userOnboardingInfo.inbound_steps[idx] = {...this.userOnboardingInfo.inbound_steps[idx], ...stepMetaInfo};
				}
			}
			this.userOnboardingInfo.inbound_steps[this.userOnboardingInfo.inbound_steps.length-1].last = true;
		}
	};
}
