import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {ArchiveService} from "../../../../services/archiveService";

@Component({
	selector: 'outbound-quarantined-component',
	templateUrl: './outbound-quarantined.component.html',
})
export class OutboundQuarantinedComponent implements OnInit{
    constructor(public gs:GeneralService,
				private ns:NotificationService,
				private rs:RouteService,
				public archiveService:ArchiveService) {
    }
    dic = DICTIONARY;
    corpname = this.gs.corpname;


    loadingEmailsInProcess = false;
    actionInProcess = false;
	previewAttachment;

    orderBy = '-created';

    showEmailInfoPopup: any;
	allowReviewerShowContent;

    messageInInfoPopup = {
        type: '',
        message: '',
        infoMessage: ''
    };

    multipleEmailsActions = [
        this.dic.CONSTANTS.quarantinedOutboundActions.release,
        this.dic.CONSTANTS.quarantinedOutboundActions.remove,
        this.dic.CONSTANTS.quarantinedOutboundActions.delete
    ];
    userInfo;
    emails;
    retentionPeriod;
    filterData;
    trackEventShow = -2;
    encryptContent;
    secureSend;
	notifySender;
    reportToTrustifi;

	incidentPopup;


	ngOnInit() {
        this.initFilters();
        this.getQuarantinedEmails();

        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
        });
    };

    getQuarantinedEmails = () => {
        this.loadingEmailsInProcess = true;
        this.rs.getQuarantinedOutboundEmails().then((response) => {
            this.emails = response.emails;
			this.allowReviewerShowContent = response.allowReviewerShowContent;
            this.retentionPeriod = response.outbound_retention_period;
            this.loadingEmailsInProcess = false;
        }, (err) => {
            this.loadingEmailsInProcess = false;
        });
    };

    showQuarantinedActions = (emailObj) => {
        const actions = [
            this.dic.CONSTANTS.quarantinedOutboundActions.info,
            this.dic.CONSTANTS.quarantinedOutboundActions.delete
        ];

        if (emailObj.status === this.dic.CONSTANTS.quarantinedOutboundStatus.quarantined.value) {
            actions.push(this.dic.CONSTANTS.quarantinedOutboundActions.release,
                this.dic.CONSTANTS.quarantinedOutboundActions.remove);
        }

        return actions;
    };

    showBulkQuarantinedActions = () => {
        return this.multipleEmailsActions;
    }

    exportToCsv = (sortBy) => {
        if (!this.emails || !this.emails.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Created,Mailbox,Subject,Status,Category\n";

        let sortedTable = _.filter(this.emails,email => {return !email.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        sortedTable.forEach((email) => {
			const created = new Date(email.created);
			const createdStr = `${created.toLocaleDateString()} ${created.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })}`;
            csvString += `${createdStr},"${email.user_email}",${email.email_subject},"${email.status}",${email.type}\n`;
        });

        this.gs.exportCsv(csvString, `outbound_quarantined.csv`);
    };

    changeRetentionPeriod = (period) => {
        if (!period || period < 1 || period > 365) {
            this.ns.showErrorMessage(this.dic.ERRORS.retentionPeriodRange);
            return;
        }
        const data = {type: 'retention', period: period};
        this.rs.updateQuarantineSettings(data).then(() => {
            this.ns.showInfoMessage(`Retention period updated to ${period} days`);
        });
    };

    searchEmail = (searchTerm, activeFilters) => {
        this.emails.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = this.searchTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            if (activeFilters) {
                // need to match all filter types
                let numFilterToMatch = Object.keys(activeFilters).length;

                if (activeFilters.status && activeFilters.status.length) {
                    if (activeFilters.status.includes('Pending release') && record.status === 'pending') {
                        numFilterToMatch--;
                    }
					if (activeFilters.status.includes('Requested Release') && record.status === 'requested release') {
						numFilterToMatch--;
					}
                    else if (activeFilters.status.includes(record.status)) {
                        numFilterToMatch--;
                    }
                }

                if (activeFilters.type && activeFilters.type.length) {
                    if (activeFilters.type.includes(record.type)) {
                        numFilterToMatch--;
                    }
                }

                if (numFilterToMatch) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    initFilters = () => {
        this.filterData = {
            filterType: this.dic.CONSTANTS.tableFilters.outboundQuarantined,
            filters: {
                status: ['quarantined', 'released', 'removed', 'Pending release', 'Requested Release'],
                type: ['Outbound Rules', 'Inappropriate Content', 'Malicious Links', 'Malicious Files', 'Recipient Failure', 'Blocklisted Recipient', 'Blocklisted Attachments Policy']
            },
            initFiltersFunction: this.initFilters,
        };
    };

    selectEmailAction = (email, action) => {
        this.resetMessageInInfoPopup();
        switch (action) {
            case this.dic.CONSTANTS.quarantinedOutboundActions.remove:
                this.removeQuarantinedEmailPopup([email]);
                break;

            case this.dic.CONSTANTS.quarantinedOutboundActions.delete:
                this.openDeleteEmailPopup([email]);
                break;

            case this.dic.CONSTANTS.quarantinedOutboundActions.release:
                this.releaseQuarantinedEmailPopup([email]);
                break;

            case this.dic.CONSTANTS.quarantinedOutboundActions.info:
                this.openInfoPopup(email);
                break;

            case this.dic.CONSTANTS.quarantinedOutboundActions.handled:
                this.setHandledStatus(email);
				break;

			case this.dic.CONSTANTS.quarantinedActions.createIncident.display:
				this.createIncidentPopup(email);
				break;
        }
    }


    selectMultipleEmailsAction = (selectedEmails,action) => {
        switch (action) {
            case this.dic.CONSTANTS.quarantinedOutboundActions.remove:
                this.removeQuarantinedEmailPopup(selectedEmails);
                return;

            case this.dic.CONSTANTS.quarantinedOutboundActions.delete:
                this.openDeleteEmailPopup(selectedEmails);
                break;

            case this.dic.CONSTANTS.quarantinedOutboundActions.release:
                this.releaseQuarantinedEmailPopup(selectedEmails);
                break;
        }
    }

    setHandledStatus = (email) => {
        if (this.actionInProcess) {
            return;
        }

        this.actionInProcess = true;
        this.resetMessageInInfoPopup();
        const data = {
            action: this.dic.CONSTANTS.quarantinedOutboundActions.handled.value,
            enabled: !email.reviewed_completed
        };
        this.rs.doActionOnQuarantinedOutboundEmail(email._id, data).then((response) => {
            this.actionInProcess = false;
            this.showInfoPopupMessage('success',`Email was set as ${data.enabled ? '' : 'not '}handled`);
            email.reviewed_completed = data.enabled;
            email.reviewer_actions.unshift({created: new Date(), reviewer: this.userInfo.email, action: `Set email as ${data.enabled ? '' : 'not '}handled`});
        });
    }

	createIncidentPopup = (email) => {
		this.incidentPopup = {
			show: true,
			securityTeamComments: '',
			applyInProcess: false,
			doneCb: () => {
				this.incidentPopup.applyInProcess = true;
				this.actionInProcess = true;

				const data = {
					action: this.dic.CONSTANTS.quarantinedActions.createIncident.value,
					securityTeamComments: this.incidentPopup.securityTeamComments
				};

				this.rs.doActionOnQuarantinedOutboundEmail(email._id, data).then((response) => {
					email.reviewed_completed = false;
					const newAction = {
						created: new Date(),
						reviewer: this.gs.reviewerInfo.email,
						action: response.reviewerActivity
					};
					email.reviewer_actions.unshift(newAction);
					this.actionInProcess = false;
					this.ns.showInfoMessage(`Incident created successfully`);
					this.incidentPopup.applyInProcess = false;
					this.incidentPopup.show = false;
				}, (err) => {
					this.incidentPopup.show = false;
					this.incidentPopup.applyInProcess = false;
					this.actionInProcess = false;
				});
			}
		};
	};

    openInfoPopup = (email) => {
		this.showEmailInfoPopup = {
			show: true,
			email,
		};

        this.showEmailInfoPopup.email = email;
        this.getQuarantinedEmailTracking(email);
        this.getQuarantinedEmailInfo(email);
    }

    showInfoPopupMessage = (type,message) => {
        switch (type) {
            case 'error':
            case 'success':
                this.messageInInfoPopup.type = type
                this.messageInInfoPopup.message = message;
                break;

            case 'info':
                this.messageInInfoPopup.infoMessage = message;
                break;
        }
    }

    resetMessageInInfoPopup = () => {
        this.messageInInfoPopup = {
            type: '',
            message: '',
            infoMessage: this.messageInInfoPopup.infoMessage || ''
        };
    }


    openDeleteEmailPopup = emails => {
        let title, subTitle, body;
        if (emails.length > 1) {
            title = 'Delete Quarantined Email Records';
            subTitle = `Please note - you are about to delete ${emails.length} quarantined email records`;
            body = ['You will no longer be able to apply actions to these emails'];
        }
        else {
            title = 'Delete Quarantined Email Record';
            subTitle = `Please note - you are about to delete ${emails[0].email_subject}`;
            body = ['You will no longer be able to apply actions to this email'];
        }

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                emails.forEach((email) => {
                    this.rs.deleteQuarantinedOutboundEmail(email._id).then( () => {
                        _.remove<any>(this.emails, innerEmail => innerEmail._id === email._id);
                        this.ns.showInfoMessage('Quarantined email record was deleted successfully');
                    });
                });
            }
        });
    };

    getQuarantinedEmailInfo = (email) => {
		const data = {
            action: this.dic.CONSTANTS.quarantinedOutboundActions.info.value,
        };
        this.rs.doActionOnQuarantinedOutboundEmail(email._id, data).then((response) => {
            email.contentObj = response;
        });
    };

	getQuarantinedEmailContent = (email, counter=2) => {
		this.showEmailInfoPopup.loadingEmailContent = true;

		const data = {
			action: this.dic.CONSTANTS.quarantinedOutboundActions.content.value,
		};
		this.rs.doActionOnQuarantinedOutboundEmail(email._id, data).then((response) => {
			email.content = addTargetBlankToLinks(response);
			this.showEmailInfoPopup.loadingEmailContent = false;
		}, err => {
			if (err.data?.message && !err.data?.display_bar) {
				this.ns.showErrorMessage(err.data?.message);
			}
			if (err.data?.retry && counter > 0) {
				setTimeout(() => {
					this.getQuarantinedEmailContent(email, --counter);
				}, 1000);
			}
			else {
				this.showEmailInfoPopup.loadingEmailContent = false;
			}
		});
	};

    getQuarantinedEmailTracking = (emailObj) => {
        if (!emailObj) {
            return;
        }

        if (!this.showEmailInfoPopup.email.recipientTrack) {
            this.showEmailInfoPopup.email.loadTrackingData = true;
        }
        this.rs.getQuarantinedEmailTracking(emailObj._id).then((response) => {
            this.showEmailInfoPopup.email.trackingData = response.tracking;

            if (emailObj.type === this.dic.CONSTANTS.quarantinedOutboundType.recipientFailure) {
                this.showEmailInfoPopup.email.recipientTrack = _.find<any>(response.tracking, {email: emailObj.reason});
            }
            else {
                this.showEmailInfoPopup.email.recipientTrack = response.tracking[0];
            }

            this.showEmailInfoPopup.email.loadTrackingData = false;
        },(err) => {
            this.showEmailInfoPopup.email.loadTrackingData = false;
        });
    };

    changeTrackingRecipient = (newRecipient) => {
        this.showEmailInfoPopup.email.recipientTrack = newRecipient;
        this.trackEventShow = -2;
    };

    openLocation = (ll) => {
        this.gs.openLocation(ll);
    };

    showReadEvent = (events) => {
        return _.find<any>(events,{name : 'Read'});
    };

    expandAllTrackEvents = () => {
        this.trackEventShow = -1;
        for (let i = 0; i < this.showEmailInfoPopup.email.recipientTrack.track_events.length; i++) {
            this.showEmailInfoPopup.email.recipientTrack.track_events[i].opened = true;
        }
    };

    collapseAllTrackEvents = () => {
        this.trackEventShow = -2;
        for (let i = 0; i < this.showEmailInfoPopup.email.recipientTrack.track_events.length; i++) {
            this.showEmailInfoPopup.email.recipientTrack.track_events[i].opened = false;
        }
    };

    downloadAttachment = (fileObj) => {
   		if (fileObj.inProcess) return;

		const url = `/admin/outbound/quarantined/attachment/${this.showEmailInfoPopup.email._id}/${fileObj._id}`;

        this.gs.downloadFile(url, fileObj, false, () => {});
    };

    removeQuarantinedEmailPopup = (emails) => {
        if (!emails.length) {
            return;
        }
        let title, subTitle, body;
        if (emails.length > 1) {
            title = 'Remove Quarantined Emails';
            subTitle = `Please note - you are about to remove ${emails.length} emails from the senders' sent items`;
            body = ['Emails will be removed'];
        }
        else {
            title = 'Remove Quarantined Email';
            subTitle = `Please note - you are about to remove this email from the sender's sent items`;
            body = [`Email ${emails[0].email_subject} will be removed`];
        }

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Remove',
            doneCb: () => {
                this.actionInProcess = true;
                this.removeMultipleQuarantinedEmail(emails, 0, () => {
                    this.actionInProcess = false;
                    if (emails.length > 1) {
                        this.ns.showInfoMessage(`Operation completed successfully for ${emails.length} emails`);
                    }
                    if (this.showEmailInfoPopup && this.ns.getCurrentMessage()){
                        this.showInfoPopupMessage(this.ns.getCurrentMessage().error.type === 'info' ? 'success' : 'error', this.ns.getCurrentMessage().error.message);
                    }
                });
            }
        });
    };

    removeMultipleQuarantinedEmail = (emails, idx, cb) => {
        if (idx < emails.length) {
            this.removeQuarantinedEmailExecute(emails[idx], () => {
                this.removeMultipleQuarantinedEmail(emails, idx + 1, cb);
            });
        }
        else {
            cb();
        }
    };

    removeQuarantinedEmailExecute = (email, cb) => {
        if (email.status !== this.dic.CONSTANTS.quarantinedActions.quarantine.value && email.status !== this.dic.CONSTANTS.quarantinedActions.requestRelease.value) {
            this.ns.showErrorMessage('This action can only be performed on email with "Quarantined" status');
            return cb();
        }

        const data = {action: this.dic.CONSTANTS.quarantinedActions.remove.value};
        this.rs.doActionOnQuarantinedOutboundEmail(email._id, data).then((response) => {
            email.status = response.status;
            email.reviewed_completed = true;
            email.reviewer_actions.push({created: new Date(), reviewer: this.userInfo.email, action: `Removed email from sender's sent items`});

            this.addQuarantineStatusCheck(email);
            cb();
        }, err => {
            cb();
        });
    };

    releaseQuarantinedEmailPopup = (emails) => {
        if (!emails.length) {
            return;
        }

        let subTitle, details;
        if (emails.length > 1) {
            subTitle = `Please note - you are about to release ${emails.length} emails from quarantine`;
            details = [`Emails will be sent to all intended recipients`, 'Release this email only if you are sure it is safe and complies with your organization\'s policies'];
        }
        else {
            subTitle = `Please note - you are about to release this email from quarantine`;
            details = [`Email ${emails[0].email_subject} will be sent to all intended recipients`, 'Release this email only if you are sure it is safe and complies with your organization\'s policies'];
        }

        this.encryptContent = emails[0].email_id.methods.encrypt_content;
        this.secureSend = emails[0].email_id.methods.secure_send;
		this.notifySender = false;
        this.reportToTrustifi = true;
        const isTlsFailure = emails.find(itm => itm.type === this.dic.CONSTANTS.quarantinedOutboundType.recipientFailure);

        const popupBody = [{
            labelName: 'Encrypt Message Content',
            toggle: this.encryptContent
        },
		{
            labelName: 'Require Authentication',
            toggle: this.secureSend
        },
		{
			labelName: 'Notify Sender',
			toggle: this.notifySender
		},
        {
            labelName: 'Report to Trustifi (recommended)',
            tooltip: 'Our dedicated security team review each report daily and takes necessary actions to adjust our security features accordingly.',
            toggle: this.reportToTrustifi
        }];

        if (isTlsFailure) {
            popupBody.push({
                labelName: 'Send with TLS',
                toggle: true
            });
        }

        this.gs.showPopup({
            title: 'Release Quarantined Email',
            subTitle: subTitle,
            body: popupBody,
            details: details,
            type: this.dic.CONSTANTS.popupToggle,
            doneBtnText: 'Release',
            doneCb: (options) => {
                const releaseOptions:any = {
                    methods: {
                        encryptContent: options[0].toggle,
                        secureSend: options[1].toggle
                    },
					notifySender: options[2].toggle,
                    reportToTrustifi: options[3].toggle
                };
                if (isTlsFailure) {
                    releaseOptions.sendWithTls = options[4].toggle;
                }

                this.actionInProcess = true;
                this.resetMessageInInfoPopup();
                this.releaseMultipleQuarantinedEmail(emails, 0, releaseOptions, () => {
                    this.actionInProcess = false;
                    if (emails.length > 1) {
                        this.ns.showInfoMessage(`Operation completed successfully for ${emails.length} emails`);
                    }
                    if (this.showEmailInfoPopup && this.ns.getCurrentMessage()){
                        this.showInfoPopupMessage(this.ns.getCurrentMessage().error.type === 'info' ? 'success' : 'error', this.ns.getCurrentMessage().error.message);
                    }
                });
            }
        });
    }

    releaseMultipleQuarantinedEmail = (emails, idx, releaseOptions, cb) => {
        if (idx < emails.length) {
            this.releaseQuarantinedEmailExecute(emails[idx], releaseOptions, () => {
                this.releaseMultipleQuarantinedEmail(emails, idx + 1, releaseOptions, cb);
            });
        }
        else {
            cb();
        }
    };

    releaseQuarantinedEmailExecute = (email, releaseOptions, cb) => {
        if (email.status !== this.dic.CONSTANTS.quarantinedActions.quarantine.value && email.status !== this.dic.CONSTANTS.quarantinedActions.requestRelease.value) {
            this.ns.showErrorMessage('This action can only be performed on email with "Quarantined" status');
            return cb();
        }

        const data = {
            action: this.dic.CONSTANTS.quarantinedOutboundActions.release.value,
            options: releaseOptions
        };
        this.rs.doActionOnQuarantinedOutboundEmail(email._id, data).then((response) => {
            email.status = response.status;
            email.reviewed_completed = true;
            email.reviewer_actions.push({created: new Date(), reviewer: this.userInfo.email, action: 'Released email from quarantine'});
            this.addQuarantineStatusCheck(email);
            cb();
        }, err => {
            cb();
        });
    };

    pendingQuarantines = [];
    checkStatusTimeOut;
    addQuarantineStatusCheck = (email) => {
        this.pendingQuarantines.push(email._id.toString());

        if (this.checkStatusTimeOut) {
            return;
        }

        this.checkStatusTimeOut = setTimeout(() => {
            this.checkQuarantineStatus();
        }, 20000);
    };

    checkQuarantineStatus = () => {
        const slicedArray = this.pendingQuarantines.slice(0, 30); // limit the IDs in the query
        this.rs.checkQuarantineOutboundStatus(slicedArray.join(',')).then( (response) => {
            response.forEach((statusObj) => {
                if (statusObj.status !== this.dic.CONSTANTS.quarantinedOutboundStatus.pending.value) {
                    let emailObj = _.find<any>(this.emails, {_id: statusObj._id});
                    if (emailObj) {
                        emailObj.status = statusObj.status;
                    }
                    const index = this.pendingQuarantines.indexOf(statusObj._id);
                    if (index > -1) {
                        this.pendingQuarantines.splice(index, 1);
                    }
                }
            });

            if (this.pendingQuarantines.length) {
                this.checkStatusTimeOut = setTimeout(() => {
                    this.checkQuarantineStatus();
                }, 30000);
            }
            else {
                this.checkStatusTimeOut = null;
            }
        });
    }

    searchTextExecute = (email, searchTerm) => {
        searchTerm = searchTerm.toLowerCase();
        return ((email.user_email && email.user_email.toLowerCase().indexOf(searchTerm) > -1) ||
            (email.status && email.status.toLowerCase().indexOf(searchTerm) > -1) ||
            (email.type && email.type.toLowerCase().indexOf(searchTerm) > -1) ||
            (email.email_subject && email.email_subject.toLowerCase().indexOf(searchTerm) > -1));
    }
}

function addTargetBlankToLinks(emailContent: string): string {
	try {
		const parser = new DOMParser();
		const doc = parser.parseFromString(emailContent, "text/html");

		const anchorElements = doc.querySelectorAll("a");

		anchorElements.forEach((anchor) => {
			anchor.setAttribute("target", "_blank");
		});

		return doc.body.innerHTML;
	}
	catch (ex) {
		console.error(ex);
		return emailContent;
	}
}
