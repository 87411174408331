import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {GeneralService} from "../../../../services/generalService";
import {NotificationService} from "../../../../services/notificationService";
import {DICTIONARY} from "../../../../dictionary";
import {LookAndFeelService} from "../../../../services/lookAndFeelService";

@Component({
	selector: 'threat-simulation-campaign-details-component',
	templateUrl: './threat-simulation-campaign-details.component.html',
})
export class ThreatSimulationCampaignDetailsComponent implements OnInit {

	@Input() currentCampaign;
	@Output() deleteCampaign = new EventEmitter<any>;
	userLogoDetails;
	userInfo;
	sendTime;
	dic = DICTIONARY;

	campaignStatus;
	campaignStatuses = {
		completed: 'completed',
		scheduled: 'scheduled',
		sent: 'sent'
	};

	constructor(public gs:GeneralService,
				private lfs:LookAndFeelService,
				private ns:NotificationService) {
	}

	ngOnInit() {
		this.gs.getUserInfo(false,  (userInfo) => {
			this.userInfo = userInfo;
			this.userLogoDetails = userInfo.plan.customization.lf;
		});

		this.sendTime = new Date(this.currentCampaign.email_id.start_sending_time || this.currentCampaign.email_id.scheduled_time);
		if (this.sendTime) {
			if (new Date() < this.sendTime) {
				this.campaignStatus = this.campaignStatuses.scheduled;
			}

			if (new Date() > this.sendTime) {
				this.campaignStatus = this.campaignStatuses.sent;

				const expirationTime = new Date(this.currentCampaign.email_id.start_sending_time);
				expirationTime.setDate(this.sendTime.getDate() + (this.currentCampaign.email_id.advanced?.secure?.expired_days || 7));
				if (new Date() > expirationTime) {
					this.campaignStatus = this.campaignStatuses.completed;
				}
			}
		}
	}
}
