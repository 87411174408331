import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'discovered-services-component',
	templateUrl: './discovered-services.component.html',
})
export class DiscoveredServicesComponent implements OnInit {
    constructor(private gs:GeneralService,
				 private rs:RouteService,
				 private ns:NotificationService) {
    }

    dic = DICTIONARY;
    filterData;
    userInfo;
    getServicesInProcess;
    discoveredServices;
    showMailboxesByServicePopup;

	ngOnInit() {

		this.initFilters();
		this.gs.getUserInfo(false, (userInfo) => {
			this.userInfo = userInfo;

			this.getServicesInProcess = true;

			this.rs.getDiscoveredServices().then(response => {
				this.discoveredServices = response;
				if (this.discoveredServices) {
					this.discoveredServices.forEach((serviceObj) => {
						serviceObj.name = serviceObj.data.name;
						if (serviceObj.data.name !== serviceObj.data.service) {
							serviceObj.name += `: ${serviceObj.data.service}`;
						}
						serviceObj.website = serviceObj.data.website;
						serviceObj.mailboxesCounter = serviceObj.mailboxes.length;

						const domain = (new URL(serviceObj.website));
						if (domain.hostname) {
							serviceObj.domain = domain.hostname.replace('www.', '');
						}

						this.createRiskyChartOptions(serviceObj);
					});
				}
				this.getServicesInProcess = false;
			}, (err) => {
				this.getServicesInProcess = false;
			});
		});
	}

    initFilters = () => {
        this.filterData = {
            filterType : this.dic.CONSTANTS.tableFilters.discoveredServices,
            filters: {
                'Risk Level': ['Very low', 'Low', 'Moderate', 'Elevated', 'Significant']
            },
            initFiltersFunction: this.initFilters
        };
    };

    createRiskyChartOptions = (serviceObj) => {
        serviceObj.riskyChartOptions = {
			series: [
				{name: '', data: [serviceObj.data.risk]}
			],
			chart: {
				type: 'bar',
				height: '20px',
				width: '100%',
				stacked: true,
				sparkline: {
					enabled: true
				},
				toolbar: {
					show: false
				},
				animations: {
					enabled: false
				}
			},
			grid: {
				show: false
			},
			plotOptions: {
				bar: {
					borderRadius: 0,
					horizontal: true,
					barHeight: '100%',
					colors: {
						backgroundBarColors: ['#f1f1f1'],
						backgroundBarRadius: 2,
						ranges: [
							{from: 1, to: 2, color: 'rgba(32,107,204,0.17)'},
							{from: 2, to: 3, color: 'rgba(32,107,204,0.27)'},
							{from: 3, to: 4, color: 'rgba(32,107,204,0.4)'},
							{from: 4, to: 5, color: 'rgba(32,107,204,0.56)'},
							{from: 5, to: 6, color: 'rgba(32,107,204,0.81)'},
						],
					}
				}
			},
			fill: {
				/*type: "pattern",
				pattern: {
					style: 'verticalLines',
					width: 8,
					strokeWidth: 6,
				}*/
			},
			yaxis: {
				type: 'numeric',
				min: 0,
				max: 5,
				tickAmount: 5,
			},
			dataLabels: {
				enabled: false,
				formatter: (val, opt) => val + ' / 5'
			},
			tooltip: {
				enabled: false
			}
        };
    }

    exportToCsv = (sortBy) => {
        if (!this.discoveredServices || !this.discoveredServices.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Name,Exposure Level,# Mailboxes,Last Seen\n";

        let sortedTable = _.filter(this.discoveredServices,itm => {return !itm.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        sortedTable.forEach((serviceObj) => {
            csvString += `"${serviceObj.name}","${this.riskyToString(serviceObj.data.risk)}",${serviceObj.mailboxesCounter},${serviceObj.updated}\n`;
        });

        this.gs.exportCsv(csvString, `services.csv`);
    };

    showServicesActions = () => {
        return [this.dic.CONSTANTS.discoveredServicesActions.showMailboxes];
    }

    selectAction = (serviceObj, action) => {
        switch (action) {
            case this.dic.CONSTANTS.discoveredServicesActions.showMailboxes:
                this.showMailboxesByService(serviceObj);
                break;
        }
    }

    showMailboxesByService = (serviceObj) => {
		serviceObj.mailboxes.forEach(record => {
			record.hide = false;
		});

        this.showMailboxesByServicePopup = {
            show: true,
            serviceObj: serviceObj
        };
    }

    showMailboxesByServiceFromCell = (serviceObj) => {
		this.showMailboxesByService(serviceObj);
    }

    searchUser =  (event) => {
        this.showMailboxesByServicePopup.serviceObj.mailboxes.forEach(record => {
            // search
            if (event.searchTerm) {
                const isFound = this.searchServiceTextExecute(record, event.searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    searchService = (event) => {
        this.discoveredServices.forEach(record => {
            // search
            if (event.searchTerm) {
                const isFound = this.searchServiceTextExecute(record, event.searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            if (event.activeFilters) {
                // need to match all filter types
                let numFilterToMatch = Object.keys(event.activeFilters).length;

                if (event.activeFilters['Risk Level'] && event.activeFilters['Risk Level'].length) {
                    if (event.activeFilters['Risk Level'].includes(this.riskyToString(record.data.risk))) {
                        numFilterToMatch--;
                    }
                }

                if (numFilterToMatch) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    searchServiceTextExecute = (serviceObj, searchTerm) => {
        searchTerm = searchTerm.toLowerCase();
        return ((serviceObj.service && serviceObj.service.toLowerCase().indexOf(searchTerm) > -1) ||
            ((serviceObj.email && serviceObj.email.toLowerCase().indexOf(searchTerm) > -1)) ||
            (serviceObj.website && serviceObj.website.toLowerCase().indexOf(searchTerm) > -1));
    }

	riskyToString(riskyNumber) {
		switch (riskyNumber) {
			case 5:
				return 'Significant';

			case 4:
				return 'Elevated';

			case 3:
				return 'Moderate';

			case 2:
				return 'Low';

			case 1:
				return 'Very low';
		}
	}

	riskyTooltip(riskyIdentifier) {
		switch (riskyIdentifier) {
			case 5:
				return 'The app handles very highly sensitivity data and has a very level of usage, making it a prime target for malicious actors.';

			case 4:
				return 'The app handles highly sensitive data and has a high level of usage.';

			case 3:
				return 'The app handles very moderatly sensitive data and has a moderate level of usage, making it more likely to be targeted by malicious actors.';

			case 2:
				return 'The app handles low sensitivity data and has a low level of usage.';

			case 1:
				return 'The app handles very low sensitivity data (such as basic personal information) and has a low level of usage, making it less likely to be targeted by malicious actors.';

			case 'all':
				let combinedTxt = '';
				for (let i = 1; i <=5; i++) {
					combinedTxt += `<b>${this.riskyToString(i)}</b>: ${this.riskyTooltip(i)}<br/><br/>`;
				}
				return combinedTxt;
		}
	}
}


