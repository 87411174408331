<div class="flex-column gap-4">
    <span>Configure how users can join your Trustifi plan, and manage your plan key settings</span>
    <div class="list-group list-group-flush settings-list-container">

        <!--Settings: Plan key-->
        <div class="list-group-item settings-list-item">
            <div class="title-section-container flex-column gap-2">
                <b>Plan key</b>
                <span class="lh-base" style="font-size: 0.9em;">The key for your shared plan. You are able to generate a new key and share it by copying the key or by sending an invitation link.</span>
            </div>
            <div class="content-container">
                <div class="p-3 flex-column gap-3 rounded border">
                    <div class="flex-row justify-content-between">
                        <b style="font-size: 0.9em;">Plan Key:</b>
                        <a class="blue-link hSpace05" style="font-size: 11px;"
                           (click)="activateSharedPlan(true);"
                           tooltip="Regenerate a new key for the shared plan">
                            <i class="fas fa-sync-alt" style="cursor: pointer;"></i>
							<span>Regenerate Key</span>
                        </a>
                    </div>
                    <div class="flex-row gap-3 align-items-center">
                        <!--key box-->
                        <input type="text" class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
                               [(ngModel)]="sharedPlanInfo?.shared_plan.key">

                        <!--copy btn-->
                        <a style="font-size: 1rem;"
                           id="adminSharedPlanCopyKey"
                           (click)="copyKeyToClipboard(true);"
                           tooltip="Copy the shared plan key">
                            <i class="fas fa-copy copy-link"
                               style="cursor: pointer; color: rgb(153, 153, 153);"></i>
                        </a>

                        <!--copy btn-->
                        <a style="font-size: 1rem;"
                           id="adminSharedPlanCopyLink"
                           (click)="copyKeyToClipboard();"
                           tooltip="Copy link for signing up new user with key for the shared plan">
                            <i class="fas fa-link copy-link" style="cursor: pointer; color: rgb(153, 153, 153);"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!--Settings: Key status-->
        <div class="list-group-item settings-list-item" *ngIf="sharedPlanInfo?.isExpired">
            <div class="title-section-container flex-column gap-2">
                <b>Key status</b>
                <span class="lh-base" style="font-size: 0.9em;">Your key has been expired, generate a new key in order to allow new users to register to your plan.</span>
            </div>
            <div class="content-container flex-column gap-2">
                <div>
                    <span><label style="color: red; margin:0 auto;">Expired</label></span>
                    <a tooltip="Reactivate and generate a new key for the shared plan"
                       (click)="activateSharedPlan(true);"
                       style="font-size: 11px; margin-left:5px;" class="hSpace05">
                        <i class="fas fa-power-off"
                           style="cursor: pointer;">
                        </i>
						<span>Reactivate</span>
                    </a>
                </div>
                <span class="text-muted">Users will still be able to join your plan via the Email Relay. To add new users manually via invitations you will need to regenerate this key.</span>
            </div>
        </div>
        <div class="list-group-item settings-list-item" *ngIf="!sharedPlanInfo?.isExpired">
            <div class="title-section-container flex-column gap-2">
                <b>Key status</b>
                <span class="lh-base" style="font-size: 0.9em;">Your key is active and new users can register with it until the expiration date below.</span>
            </div>
            <div class="content-container">
                <div class="flex-row gap-1 align-items-center">
                    <i class="fas fa-circle text-success" style="font-size: 0.7em;"></i>
                    <span class="text-success">Active</span>
                </div>
            </div>
        </div>
        <div class="list-group-item settings-list-item" *ngIf="!sharedPlanInfo?.isExpired">
            <div class="title-section-container flex-column gap-2">
                <b>Key expiration date</b>
                <span class="lh-base" style="font-size: 0.9em;">Your shared plan key will expire after this date.</span>
            </div>
            <div class="content-container">
                <div style="white-space: normal; padding-right: 7%; color: darkgrey; font-size: 12px;">
                    <span><label style="margin:0 auto;"><time>{{ sharedPlanInfo?.shared_plan.key_expires | date:'MM/dd/yyyy HH:mm' }}</time></label></span>
                </div>
            </div>
        </div>

        <!--Settings: Enforce domain-->
        <div class="list-group-item settings-list-item">
            <div class="title-section-container flex-column gap-2">
                <b>Enforce domain</b>
                <span class="lh-base" style="font-size: 0.9em;">Only users under your email address domain would be able to join the plan.</span>
            </div>
            <div class="content-container">
                <toggle-switch-c id="enforceDomainToggle"
                               [(model)]="sharedPlanInfo?.shared_plan.enforce_domain"
                               (onChange)="activateSharedPlan(false, true)"></toggle-switch-c>
            </div>
        </div>
    </div>
</div>
