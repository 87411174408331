<div class="flxClmn animated fadeIn" style="flex: 1 0; overflow: auto; padding: 0 15px;">
	<div class="actionRow flex-container" style="position: sticky; top: 0; background: white; z-index: 50;">
		<div class="actionLabel flex-item">Actions</div>
		<div class="actionItem flex-item"
			 (click)="doEmailAction('forward');">
			<a>Forward</a>
		</div>
		<div class="actionItem flex-item"
			 *ngIf="allowPolicyRecall && myEmailsService.currentMessage.source !== 'journal' && (myEmailsService.currentMessage.is_draft || (myEmailsService.currentMessage.methods.encrypt_content || (!myEmailsService.currentMessage.start_sending_time && myEmailsService.currentMessage.scheduled_time)) && !myEmailsService.currentMessage.methods.secure_mass && !myEmailsService.currentMessage.reply_to)"
			 (click)="doEmailAction('updateContent');">
			<a id="updateMessage">Update message</a>
		</div>
		<div class="actionItem flex-item"
			 *ngIf="userInfo.plan.policy?.allow_recall_email?.value && myEmailsService.currentMessage.source !== 'journal'"
			 (click)="doEmailAction('recallMessage');">
			<a id="recallMessage">Recall message</a>
		</div>
		<div class="actionItem flex-item"
			 id="detailsBlockEmail"
			 *ngIf="myEmailsService.currentMessage.source !== 'journal' && !myEmailsService.currentMessage.methods.secure_mass && (myEmailsService.currentMessage.methods.encrypt_content || myEmailsService.currentMessage.methods.secure_send || (myEmailsService.currentMessage.sent.attachments.length && !myEmailsService.currentMessage.skip_attachments_encryption)) && myEmailsService.currentMessage.start_sending_time"
			 [ngStyle]="myEmailsService.currentMessage.numberRecipientsBlocked === myEmailsService.currentMessage.recipients_count && {'opacity':'0.5','cursor':'not-allowed'}"
			 (click)="doEmailAction('blockEmail');">
			<a [ngClass]="myEmailsService.currentMessage.numberRecipientsBlocked === myEmailsService.currentMessage.recipients_count ? 'disabled' : ''">Block email</a>
		</div>
		<div class="actionItem flex-item"
			 id="detailsUnblockEmail"
			 *ngIf="myEmailsService.currentMessage.source !== 'journal' && !myEmailsService.currentMessage.methods.secure_mass && (myEmailsService.currentMessage.methods.encrypt_content || myEmailsService.currentMessage.methods.secure_reply || myEmailsService.currentMessage.methods.secure_send || (myEmailsService.currentMessage.sent.attachments.length && !myEmailsService.currentMessage.skip_attachments_encryption)) && myEmailsService.currentMessage.start_sending_time"
			 [ngStyle]="myEmailsService.currentMessage.numberRecipientsBlocked === 0 && {'opacity':'0.5','cursor':'not-allowed'}"
			 (click)="doEmailAction('unblockEmail');">
			<a [ngClass]="myEmailsService.currentMessage.numberRecipientsBlocked === 0 ? 'disabled' : ''">Unblock email</a>
		</div>
		<div class="actionItem flex-item" *ngIf="!myEmailsService.currentMessage.isCampaignTitle && myEmailsService.currentMessage.methods.secure_mass && !myEmailsService.currentMessage.recipients_deleted"
			 (click)="doEmailAction('deleteMassRecipients');">
			<a>Delete Campaign Records</a>
		</div>
		<div class="actionItem flex-item" (click)="doEmailAction('print')">
			<a>Print</a>
		</div>
		<div class="actionItem flex-item" *ngIf="myEmailsService.currentMessage.sensitivity"
			 (click)="doEmailAction('sensitivity')">
			<a>Sensitivity Details</a>
		</div>
		<div class="actionItem actionRed flex-item"
			 *ngIf="!myEmailsService.currentMessage.isCampaignTitle && !myEmailsService.currentMessage.depth"
			 (click)="deleteMessages.emit({messages: [myEmailsService.currentMessage]})">
			<a>Delete</a>
		</div>
	</div>
	<br/>

	<div class="flxClmn vSpace1">
		<h4 style="color: black; margin-bottom: 15px;">
			{{myEmailsService.currentMessage.sent.title}}
		</h4>

		<!--From-->
		<div class="flxRow hSpace05">
			<span style="flex-basis: 72px; flex-shrink: 0;">From: </span>
			<div *ngIf="myEmailsService.currentMessage.from">
				<span *ngIf="myEmailsService.currentMessage.from.name && myEmailsService.currentMessage.from.email && myEmailsService.currentMessage.from.name !== myEmailsService.currentMessage.from.email">{{myEmailsService.currentMessage.from.name}} <{{myEmailsService.currentMessage.from.email}}></span>
				<span *ngIf="!myEmailsService.currentMessage.from.name || !myEmailsService.currentMessage.from.email || myEmailsService.currentMessage.from.name === myEmailsService.currentMessage.from.email">{{myEmailsService.currentMessage.from.name || myEmailsService.currentMessage.from.email}}</span>
			</div>
			<span *ngIf="myEmailsService.currentMessage._id && !myEmailsService.currentMessage.from">{{userInfo?.email}}</span>
		</div>

		<!--To-->
		<div class="flxRow hSpace05">
			<span style="flex-basis: 72px; flex-shrink: 0;">To: </span>
			<span *ngIf="!myEmailsService.currentMessage.methods?.secure_mass">
				{{showFullToStr ? myEmailsService.currentMessage.recipientsToStrFull : myEmailsService.currentMessage.recipientsToStr}}&nbsp;
				<a class="blue-link" style="text-decoration: underline;"
				   *ngIf="myEmailsService.currentMessage.recipientsToStr && myEmailsService.currentMessage.recipientsToStrFull !== myEmailsService.currentMessage.recipientsToStr"
				   (click)="showFullToStr = !showFullToStr">
					{{showFullToStr ? 'Close' : 'Show all (' + myEmailsService.currentMessage.recipients_display_only.to.length + ')'}}
				</a>
			</span>
			<span *ngIf="myEmailsService.currentMessage.methods?.secure_mass" style="width: auto;">{{myEmailsService.currentMessage.total_recipients_count || myEmailsService.currentMessage.recipients_count}} recipients</span>
		</div>

		<!--Cc-->
		<div class="flxRow hSpace05" *ngIf="myEmailsService.currentMessage.recipients_display_only.cc?.length">
			<span style="flex-basis: 72px; flex-shrink: 0;">Cc: </span>
			<span>
				{{showFullCcStr ? myEmailsService.currentMessage.recipientsCcStrFull : myEmailsService.currentMessage.recipientsCcStr}}&nbsp;
				<a class="blue-link" style="text-decoration: underline;"
				   *ngIf="myEmailsService.currentMessage.recipientsCcStr && myEmailsService.currentMessage.recipientsCcStrFull !== myEmailsService.currentMessage.recipientsCcStr"
				   (click)="showFullCcStr = !showFullCcStr">
					{{showFullToStr ? 'Close' : 'Show all (' + myEmailsService.currentMessage.recipients_display_only.cc.length + ')'}}
				</a>
			</span>
		</div>

		<!--Bcc-->
		<div class="flxRow hSpace05" *ngIf="myEmailsService.currentMessage.recipients_display_only.bcc?.length">
			<span style="flex-basis: 72px; flex-shrink: 0;">Bcc: </span>
			<span>
				{{showFullBccStr ? myEmailsService.currentMessage.recipientsBccStrFull : myEmailsService.currentMessage.recipientsBccStr}}&nbsp;
				<a class="blue-link" style="text-decoration: underline;"
				   *ngIf="myEmailsService.currentMessage.recipientsBccStr && myEmailsService.currentMessage.recipientsBccStrFull !== myEmailsService.currentMessage.recipientsBccStr"
				   (click)="showFullBccStr = !showFullBccStr">
					{{showFullBccStr ? 'Hide' : 'Show all (' + myEmailsService.currentMessage.recipients_display_only.bcc.length + ')'}}
				</a>
			</span>
		</div>

		<!--Lists-->
		<div class="flxRow hSpace05" *ngIf="myEmailsService.currentMessage.mass_lists?.length">
			<span style="flex-basis: 72px; flex-shrink: 0;">Lists: </span>
			<ng-container *ngFor="let massList of myEmailsService.currentMessage.mass_lists; trackBy: gs.trackByIndex; let last = last;">
				<a class="blue-link" (click)="navToList(massList)">{{massList.name}}</a>
				{{!last ? ', ' : ''}}
			</ng-container>
		</div>

		<!--Date-->
		<div class="flxRow hSpace05">
			<span style="flex-basis: 72px; flex-shrink: 0;">Sent at: </span>
			<span>{{(myEmailsService.currentMessage.start_sending_time | date:'MM/dd/yyyy HH:mm') || 'Not sent yet'}}</span>
		</div>

		<!--Updated at-->
		<div class="flxRow hSpace05" *ngIf="myEmailsService.currentMessage.sent.edited_timestamp">
			<span style="flex-basis: 72px; flex-shrink: 0;">Updated at: </span>
			<div>{{myEmailsService.currentMessage.sent.edited_timestamp | date:'MM/dd/yyyy HH:mm'}}</div>
		</div>

		<!--Scheduled-->
		<div class="flxRow hSpace05" style="align-items: center; margin-bottom: 15px;" *ngIf="!myEmailsService.currentMessage.start_sending_time && myEmailsService.currentMessage.scheduled_time">
			<span style="flex-basis: 72px; flex-shrink: 0;">Scheduled: </span>
			<span *ngIf="!showScheduler">
				{{myEmailsService.currentMessage.scheduled_time | date:'MM/dd/yyyy HH:mm'}}
			</span>
			<div class="actionRow flex-container f-1-0" *ngIf="!showScheduler" style="border: none; padding: 0; margin: 0; margin-left: 5px;">
				<div class="actionItem flex-item" style="background: white; border: solid 1px black;"
					 tooltip="Cancel scheduling for this email"
					 (click)="deleteMessages.emit({messages: [myEmailsService.currentMessage]})">
					<a>Cancel</a>
				</div>
				<div class="actionItem flex-item" style="background: white; border: solid 1px black;"
					 tooltip="Send this email now"
					 (click)="openSchedSendNowPopup('email')">
					<a>Send Now</a>
				</div>
				<div class="actionItem flex-item" style="background: white; border: solid 1px black;"
					 tooltip="Change the schedule time for this email"
					 (click)="startEditScheduleTime();">
					<a>Reshcedule</a>
				</div>
			</div>
			<div class="flxRow hSpace2" style="align-items: center;" *ngIf="showScheduler">
				<div class="flxRow hSpace05" style="align-items: center;">
					<input type="datetime-local" [min]="minScheduleTime" [(ngModel)]="editedScheduledTimeModel">
				</div>
				<div class="flxRow hSpace05" style="align-items: center;">
					<btn-c style="min-width: 0;"
						   endIcon="zmdi zmdi-check"
						   (action)="openUpdateScheduledTimeConfirmPopup();">
					</btn-c>
					<btn-c style="min-width: 0;"
						   endIcon="zmdi zmdi-close" variant="text" color="danger"
						   (action)="showScheduler = false;">
					</btn-c>
				</div>
			</div>
		</div>

		<!--Reply to-->
		<div class="flxRow hSpace05" *ngIf="(myEmailsService.currentMessage.advanced?.general?.reply_to_email && myEmailsService.currentMessage.advanced?.general?.reply_to_email !== myEmailsService.currentMessage.from.email_display) || !myEmailsService.currentMessage.advanced?.general?.reply_to_enable">
			<span style="flex-basis: 72px; flex-shrink: 0;">Reply to: </span>
			<div>{{myEmailsService.currentMessage.advanced?.general?.reply_to_enable ? myEmailsService.currentMessage.advanced.general.reply_to_email : "do-not-reply@" + domain}}</div>
		</div>

		<!--Sensitivity-->
		<div class="flxRow hSpace05" *ngIf="myEmailsService.currentMessage.sensitivity">
			<span style="flex-basis: 72px; flex-shrink: 0;">Sensitivity: </span>
			<div class="flxRow hSpace2" style="flex: 1 0;">
				<span class="hvr-underline" style="cursor: pointer; white-space: nowrap;"
					  (click)="openSensitiveInfoPopup()"
					  tooltip="Click here to review sensitivity score">
						{{myEmailsService.currentMessage.sensitivity.sensitive_types?.length ? myEmailsService.currentMessage.sensitivity.sensitive_types[0].sensitive_type : myEmailsService.currentMessage.sensitivity.sensitive_sub_type || 'Not sensitive'}}
					</span>
				<input type="button" value="{{myEmailsService.currentMessage.sensitivity.score}}"
					   [ngStyle]="myEmailsService.currentMessage.sensitivity.score === 0 && {'background':'limegreen'} || myEmailsService.currentMessage.sensitivity.score < 4 && {'background':'darkorange'} || myEmailsService.currentMessage.sensitivity.score < 5 && {'background':'#FF851B'} || myEmailsService.currentMessage.sensitivity.score === 5 && {'background':'#FF4136'}"
					   (click)="openSensitiveInfoPopup()"
					   class="redborder fa" style="background: orange; width: 25px; height: 25px; color: white !important; border: 0; margin-left: 3px; margin-top: -4px;"
					   placement="right"
					   tooltip="Sensitivity score is {{myEmailsService.currentMessage.sensitivity.score}}. Click to review sensitivity details">
				<div style="color: #486cb5; opacity: 0.95; max-width: 612px; white-space: break-spaces; flex: 1 0;" class="flxRow hSpace1" *ngIf="myEmailsService.currentMessage.sensitivity.score === 0">
					<i class="fas fa-exclamation-triangle"></i>
					<span>Email was scanned for matches to your DLP rules and policies, not for all sensitive data types. You can click on "Sensitivity Details" > "Scan email sensitivity" to get full results.</span>
				</div>
			</div>
		</div>

		<!--Message-->
		<div class="flxRow hSpace05">
			<span style="flex-basis: 72px; flex-shrink: 0;">Message: </span>
			<i class="fa fa-edit hvr-opacity cursor-pointer" tooltip="Update message" (click)="doEmailAction('updateContent');"
			   *ngIf="allowPolicyRecall && (myEmailsService.currentMessage.is_draft || (!myEmailsService.currentMessage.start_sending_time && myEmailsService.currentMessage.scheduled_time && !myEmailsService.currentMessage.methods.secure_mass) || (myEmailsService.currentMessage.methods.encrypt_content && !myEmailsService.currentMessage.methods.secure_mass && !myEmailsService.currentMessage.reply_to))"></i>
		</div>
	</div>
	<br/>
	<div class="flxClmn" style="flex:1 0; margin:0; overflow: auto; min-height: 250px; border: solid 1px #cccccc;">
		<div style="flex:1 0; overflow: auto; max-height: 100%;"
			 *ngIf="myEmailsService.currentMessage.decrypted || myEmailsService.currentMessage.methods.secure_mass">
			<email-viewer [content]="myEmailsService.currentMessage.sent?.html ? myEmailsService.additionalCss + myEmailsService.currentMessage.sent.html : ''" style="min-height: 100%;"></email-viewer>
		</div>
		<div *ngIf="!myEmailsService.currentMessage.decrypted && !myEmailsService.currentMessage.methods.secure_mass" class="f-1-0 flxClmn vSpace2 centralize">
			<div class="animated infinite pulse color-primary">
				<svg xmlns="http://www.w3.org/2000/svg" style="isolation: isolate;" viewBox="0 0 175 175" width="175" height="175">
					<defs><clipPath id="_clipPath_MsIBpX8VoDKCS77dL1mgFIQQnD6QIQFQ"><rect width="175" height="175"/></clipPath></defs>
					<g clip-path="url(#_clipPath_MsIBpX8VoDKCS77dL1mgFIQQnD6QIQFQ)">
						<g style="fill: var(--bs-primary)">
							<path d=" M 86.82 81.975 L 163.643 35.136 L 163.643 24.645 L 87.5 11.169 L 11.357 24.645 L 11.357 35.696 L 86.82 81.975 Z " />
							<path d=" M 163.643 56.01 L 119.502 82.924 L 152.178 112.394 C 159.549 102.348 163.643 90.325 163.643 77.828 L 163.643 56.01 Z " />
							<path d=" M 106.036 95.038 L 116.423 84.801 L 86.792 102.868 L 58.869 85.743 L 68.3 95.038 L 35.137 124.947 C 35.414 125.159 35.677 125.383 35.959 125.592 L 87.5 163.831 L 139.042 125.592 C 139.202 125.474 139.35 125.345 139.509 125.225 L 106.036 95.038 Z " />
							<path d=" M 11.357 56.607 L 11.357 77.828 C 11.357 90.174 15.358 102.057 22.561 112.03 L 54.606 83.129 L 11.357 56.607 Z " />
						</g>
					</g>
				</svg>
			</div>
			<h1 class="loading-dots">Decrypting message</h1>
		</div>
	</div>
	<br/>
	<!--Attachments-->
	<div class="flxRow hSpace1" style="margin: 0;">
		<span style="margin-top: 5px; font-weight: 500; flex-basis: 127px;">Attachments:</span>
		<div class="flxClmn f-1-0">
			<div class="actionRow flex-container hide-when-empty" style="border: none; padding: 0; margin: 0;">
				<span *ngIf="!myEmailsService.currentMessage.sent.attachments?.length" style="margin-top: 5px; margin-right: 10px; white-space: nowrap;">No attachments</span>
				<div class="actionItem flex-item" style="background: white; border: solid 1px black;"
					 *ngIf="myEmailsService.currentMessage.source !== 'journal' && (myEmailsService.currentMessage.methods.encrypt_content || myEmailsService.currentMessage.methods.secure_send || myEmailsService.currentMessage.sent.attachments.length) && allowPolicyRecall && !myEmailsService.currentMessage.skip_attachments_encryption"
					 ngfSelect
					 multiple="1"
					 [(files)]="attachmentFiles"
					 (fileChange)="uploadAttachment()">
					<a>Add Attachments</a>
				</div>
				<div class="actionItem flex-item" style="background: white; border: solid 1px black;"
					 tooltip="Download all attachments compressed in a ZIP file"
					 *ngIf="myEmailsService.currentMessage.sent.attachments?.length"
					 (click)="downloadAllAttachments()">
					<div class="flxRow hSpace05" style="align-items: center;">
						<i class="fa fa-download"></i>
						<a>Download All</a>
					</div>
				</div>
			</div>
			<div class="flex-row gap-1" style="flex:1 0; margin-top: 7px; align-items: flex-start; flex-wrap: wrap;" *ngIf="myEmailsService.currentMessage.sent.attachments?.length">
				<attachment-c *ngFor="let file of myEmailsService.currentMessage.sent.attachments"
					[attName]="file.name"
					[attSize]="file.size" removeIcon="fas fa-times"
					[downloadProgress]="file.downloadProgressPrecentage"
					(removeFunc)="deleteAttachment(file)"
					(preview)="setPreviewAttachment(file);"
					(download)="downloadAttachment(file)">
				</attachment-c>
			</div>
		</div>
	</div>

	<!--Delete Attachments-->
	<div class="flxRow hSpace2 animated fadeIn" style="margin-top: 10px; height: 30px; align-items: center;" *ngIf="myEmailsService.currentMessage.sent.attachments?.length">
		<span style="font-weight: 500;">Delete Attachments: </span>
		<div class="flxRow hSpace05">
			<div class="flxRow hSpace05" style="align-items: center;" *ngIf="!myEmailsService.currentMessage.modifyExpirationMode">
				<span *ngIf="myEmailsService.currentMessage.advanced.secure.delete_attachment_enable">
					In {{myEmailsService.currentMessage.advanced.secure.delete_attachment_days}} days
				</span>
				<span *ngIf="!myEmailsService.currentMessage.advanced.secure.delete_attachment_enable">
					Never
				</span>
				<a *ngIf="!userInfo.plan.policy.delete_attachment_enable.strict">
					<i class="zmdi zmdi-edit" title="Modify expiration" (click)="myEmailsService.currentMessage.deleteAttachmentDays = myEmailsService.currentMessage.advanced.secure.delete_attachment_days; myEmailsService.currentMessage.modifyExpirationMode = true;"></i>
				</a>
				<a *ngIf="myEmailsService.currentMessage.advanced.secure.delete_attachment_enable && !userInfo.plan.policy.delete_attachment_enable.strict">
					<i class="zmdi zmdi-close" title="Cancel expiration" (click)="cancelAttachmentExpiration()"></i>
				</a>
			</div>
			<div class="flxRow hSpace05" style="align-items: center;" *ngIf="myEmailsService.currentMessage.modifyExpirationMode">
				<label style="width: inherit;">In</label>
				<input (ngInit)="myEmailsService.currentMessage.deleteAttachmentDays = myEmailsService.currentMessage.advanced.secure.delete_attachment_days"
					   style="width: inherit; height: inherit; margin-bottom:0; box-sizing: border-box; border-radius: 4px; border: 1px solid #ccc; padding: 2px 8px;"
					   type="number" min="1" max="365" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
					   onkeydown="return event.keyCode !== 69 && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 187 && event.keyCode !== 190;"
					   id="delete_attachment_days" name="delete_attachment_days"
					   [(ngModel)]="myEmailsService.currentMessage.deleteAttachmentDays" size="5"
					   placeholder="Days">
				<label>days</label>
				<span class="flxRow hSpace05" style="margin-left: 10px;">
                    <btn-c style="min-width: 0;"
						   id="modifyAttachmentConfirm"
						   endIcon="zmdi zmdi-check"
						   (action)="modifyAttachmentExpiration();">
                    </btn-c>
                    <btn-c style="min-width: 0;"
						   id="modifyAttachmentCancel"
						   endIcon="zmdi zmdi-close" variant="text" color="danger"
						   (action)="myEmailsService.currentMessage.modifyExpirationMode = false;">
                    </btn-c>
                </span>
			</div>
		</div>
	</div>

	<!--Email protections-->
	<div class="flxRow hSpace2 animated fadeIn" style="margin-top: 10px;" *ngIf="myEmailsService.currentMessage.source !== 'journal'">
		<span style="font-weight: 500;">Email protections: </span>
		<ul class="vSpace05" style="list-style-type: none; padding-left: 10px;">
			<li *ngIf="myEmailsService.currentMessage.methods.track">
				<span>Track</span>
			</li>
			<li *ngIf="myEmailsService.currentMessage.methods.postmark">
				<span>Postmark™</span>
			</li>
			<li *ngIf="myEmailsService.currentMessage.methods.secure_reply">
				<span>Enable Encrypted Reply</span>
			</li>
			<li *ngIf="myEmailsService.currentMessage.methods.secure_send">
				<span>Require Authentication</span>
			</li>
			<li *ngIf="myEmailsService.currentMessage.methods.encrypt_content">
				<span>Encrypt Message Content</span>
			</li>
		</ul>
	</div>
</div>

<sensitivity-details
	*ngIf="showSensitiveInfoPopup"
	[type]="'email'"
	(cancelCb)="showSensitiveInfoPopup = null"
	[scanItemObj]="myEmailsService.currentMessage"
	[scanTitle]="myEmailsService.currentMessage.sent.title"
	[sensitivity]="myEmailsService.currentMessage.sensitivity">
</sensitivity-details>

<attachment-preview
	*ngIf="previewAttachment"
	[attachment]="previewAttachment"
	(onClose)="previewAttachment = null;"
	[url]="previewAttachment.url">
</attachment-preview>
