import {DICTIONARY} from '../dictionary';
import _ from 'lodash';
import {GeneralService} from './generalService';
import {RouteService} from './routeService';
import {NotificationService} from './notificationService';
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AccountCompromisedService {
    page = '';
    dic = DICTIONARY;
    userIncidentsPopup;
    incidentInfoPopup;
	setStatusPopup;
    accountInfo;

    constructor(private gs:GeneralService,
                private rs:RouteService,
                private ns:NotificationService) {
    }

    showUserIncidentsInfo(user) {
        if (!user.misc.account_compromised.user_incidents.length) {
            return this.ns.showWarnMessage(this.dic.ERRORS.noSuspiciousActivity);
        }
        const userIncidentsInfo = user.misc.account_compromised.user_incidents;
        userIncidentsInfo.forEach((incidentObj) => {
            incidentObj.user_email = user.email;
        });
        this.userIncidentsPopup = {
            userIncidentsInfo: userIncidentsInfo,
            show: true
        };
    }

    getUserIncidentInfo(incidentObj, isUserAllowedToViewEmailContent=false) {
		if (['suspicious_location_change', 'suspicious_device_change', 'email_breach'].includes(incidentObj.incident_type)) {
			isUserAllowedToViewEmailContent = false;
		}

        if (incidentObj.userInfo) {
            this.incidentInfoPopup = {
                IncidentInfo: incidentObj,
				incidentDesc: this.getIncidentDescription(incidentObj),
				isUserAllowedToViewEmailContent,
                show: true
            }
            return;
        }

        const incidentInfo = {incidentId: incidentObj._id, action: this.dic.CONSTANTS.accountCompromisedIncidentActions.info.value};
        this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
            incidentObj.incidentInfo = response;

            if (incidentObj.incident_type === 'suspicious_location_change') {
                this.prepareIncidentLocationInfo(incidentObj);
            }

			if (incidentObj.incident_type === 'suspicious_device_change') {
				this.prepareIncidentDeviceInfo(incidentObj);
			}

            if (incidentObj.incident_type === 'sending_unusual_amount_of_emails') {
                if (incidentObj.incidentInfo.emails) {
                    incidentObj.incidentInfo.emails.forEach((emailObj) => {
                        emailObj.recipients = emailObj.recipientsIDs.map(itm => itm.email).join(', ');
                    });
                }
            }

            this.incidentInfoPopup = {
                IncidentInfo: incidentObj,
				incidentDesc: this.getIncidentDescription(incidentObj),
				isUserAllowedToViewEmailContent,
                show: true
            }
        });
    }

	changeIncidentStatus(incidentObj) {
		const curIncident = incidentObj;
		curIncident.curStatus = incidentObj.status[0].toUpperCase() + incidentObj.status.slice(1); // capitalize
		this.setStatusPopup =  {
			incident: curIncident,
			show: true
		};
	}

    deleteIncident(incidentObj, cb) {
        const title = 'Delete incident';
        const subTitle = `You are about to delete incident for ${incidentObj.user_email}`;
        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                const incidentInfo = {incidentId: incidentObj._id, action: this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.value};
                this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
                    this.ns.showInfoMessage('Incident deleted successfully');
                    cb();
                });
            }
        });
    }

	prepareIncidentDeviceInfo(incidentObj) {
		incidentObj.incidentInfo.devices.forEach((deviceObj) => {
			deviceObj.agent = deviceObj.agent || {};
			if (deviceObj.agent.raw) {
				deviceObj.agent.raw = JSON.parse(deviceObj.agent.raw);
			}
		});
	}

    prepareIncidentLocationInfo(incidentObj) {
        incidentObj.incident_info.locations = [];
        incidentObj.incidentInfo.devices.forEach((deviceObj) => {
			deviceObj.agent = deviceObj.agent || {};
			if (deviceObj.agent.raw) {
				deviceObj.agent.raw = JSON.parse(deviceObj.agent.raw);
			}
            deviceObj.location.forEach((locationObj) => {
                if (locationObj.ll.length === 2) {
                    const marker = {
                        icon: _.isEqual(locationObj.ll, incidentObj.incident_info.location[0].ll) ? this.gs.leafletDangerIcon : this.gs.leafletDefaultIcon,
                        lat: locationObj.ll[0],
                        lng: locationObj.ll[1],
                        //message: title,
                        //title: title,
                        draggable: false
                    };

                    incidentObj.incident_info.locations.push(marker);
                }
            });
        });

        incidentObj.incident_info.center_location = {
            lat: incidentObj.incident_info.location[0].ll[0],
            lng: incidentObj.incident_info.location[0].ll[1],
            zoom: 6
        };
    }


	getIncidentActionsDataFromLocalStorage() {

		// structure of data in localStorage:
		// localStorage.compromisedIncidentsActions = {
		// 		lastTimeStamp: Date,
		// 		actions: [
		// 			{
		//				incidentInfo: {created: Date, incident_type: string, user_email: string},
		//				actionInfo: {created: Date, name: string, admin: string},
		// 			}
		// 		]
		// }

		this.checkIncidentActionsLocalStorageTimestamp();
		if (!localStorage.compromisedIncidentsActions) {
			return null;
		}

		return JSON.parse(localStorage.compromisedIncidentsActions);
	}

	checkIncidentActionsLocalStorageTimestamp() {
		if (!localStorage.compromisedIncidentsActions) {
			return;
		}

		const actionsData = JSON.parse(localStorage.compromisedIncidentsActions);

		const lastTimestamp = actionsData.lastTimestamp;
		const now = Date.now();
		const secondsDiff = (now - lastTimestamp) / 1000;

		if (secondsDiff > 60) { // data is already updated in server and retrieved from there
			localStorage.removeItem('compromisedIncidentsActions');
			return;
		}
	}

	addIncidentAction(actionName, incidentObj) {
		const actionData = {
			name: actionName,
			created: (new Date()).toISOString(),
			admin: this.gs.userInfo.email
		};
		if (incidentObj.actions) {
			incidentObj.actions.push(actionData)
		}
		else {
			incidentObj.actions = [actionData];
		}
		this.saveActionDataToLocalStorage(actionData ,incidentObj);
	}

	saveActionDataToLocalStorage(action, parentIncident) {
		this.checkIncidentActionsLocalStorageTimestamp();

		let actionsData:any = {};

		actionsData.lastTimestamp = Date.now();

		const currentActionData = {
			incidentInfo: {created: parentIncident.created, incident_type: parentIncident.incident_type, user_email: parentIncident.user_email},
			actionInfo: {created: action.created, name: action.name, admin: action.admin},
		}
		if (localStorage.compromisedIncidentsActions) {
			actionsData.actions = JSON.parse(localStorage.compromisedIncidentsActions).actions;
			actionsData.actions.push(currentActionData);
		}
		else {
			actionsData.actions = [currentActionData]
		}

		localStorage.compromisedIncidentsActions = JSON.stringify(actionsData);

		setTimeout(() => {
			this.checkIncidentActionsLocalStorageTimestamp();
		}, 60000);
	}

	getIncidentDescription = (incidentInfo) => {
		switch (incidentInfo.incident_type) {
			case 'email_breach':
				return `Trustifi has detected that a recent data breach contains personal information for ${incidentInfo.user_name}\'s mailbox.`;
			case 'high_recipient_compliant_rate':
				return `${incidentInfo.user_name}'s emails have been reported multiple times as spam or phishing by recipients.`;
			case 'high_recipient_hard_bounce_rate':
				return `${incidentInfo.user_name}'s emails have been bounced on high rate lately.`;
			case 'sending_sensitive_to_new_domains':
				return `Sensitive data was sent from ${incidentInfo.user_name} to new domain.`;
			case 'sending_unusual_amount_of_emails':
				return `${incidentInfo.user_name} was sending an unusual amount of emails.`;
			case 'sending_sensitive_to_personal_email':
				return `Sensitive data was sent from ${incidentInfo.user_name} to free email domain.`;
			case 'suspicious_device_change':
				return incidentInfo.incident_info.new_login ?
					`${incidentInfo.user_name} opened an email from a new suspicious device.` :
					`${incidentInfo.user_name} logged into Exchange from a new suspicious device.`;
			case 'suspicious_location_change':
				return `${incidentInfo.user_name} opened an email from a new suspicious location.`;
		}
	}
}

