import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import {GeneralService} from "../../../../../services/generalService";
import {DICTIONARY} from "../../../../../dictionary";
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'reviewers-component',
	templateUrl: './reviewers.component.html',
})

export class ReviewersComponent implements OnInit {
	constructor(public gs: GeneralService,
		private rs: RouteService,
		private ns: NotificationService) {
	}
	dic = DICTIONARY;
	_=_;

	selectedUsers: any[];
	actionInProcess;
	removeAEmailInProcess;
	getConfigDataInProcess;
	config;
	assignReviewerToGroupsPopup;
	assignReviewerToMailboxesPopup;
	assignReviewer;
	assignReviewerSelectedGroups;
	loadingReviewersInProcess: boolean;
	reviewerPermissionPopup: any;
	addReviewerInProcess;
	contacts;
	allContacts;

	usersSelectionPopup;

	ngOnInit() {
		this.getTPRules();
	}

	getTPRules = () => {
		this.getConfigDataInProcess = true;
		this.rs.getTPRules().then((response) => {
			this.config = response.config;
			this.getConfigDataInProcess = false;
		}, (err) => {
			this.getConfigDataInProcess = false;
		});
	};

	selectMultipleReviewerAction = (selectedItems,action) => {
		switch (action) {
			case this.dic.CONSTANTS.inboundConfigurationsActions.remove:
				this.removeReviewers(selectedItems);
				break;
		}
	};

	startAddReviewer =  () => {
		if (this.addReviewerInProcess) {
			return;
		}

		this.addReviewerInProcess = true;

		const newReviewer = {
			isEditMode: true,
			isNew: true,
			edit: {
				email: '',
				allow_view_content: true,
				reviewer_allow_release_malicious: true,
				allow_change_configuration: true,
				client_defang_malicious_url: false
			}
		};

		this.config.threat_protection.reviewers.unshift(newReviewer);
	};

	startAddFromUsersSelection = (users) => {
		// Step 1: Filter out existing reviewers
		const existingReviewerEmails = this.config.threat_protection.reviewers.map(reviewer => reviewer.email);
		const filteredUsers = users.filter(user => !existingReviewerEmails.includes(user));

		// Step 2: Map filtered users to new reviewers structure
		const newReviewers = filteredUsers.map(user => ({
			email: user,
			isEditMode: false,
			isNew: true,
			edit: {
				email: user,
				allow_view_content: true
			}
		}));

		// Step 3: Add new reviewers if any
		if (newReviewers.length > 0) {
			this.config.threat_protection.reviewers.unshift(...newReviewers);
			this.addReviewers(newReviewers);
		}
	}

	startRemoveFromUsersSelection = (users) => {
		const reviewersToRemove = this.config.threat_protection.reviewers.filter(reviewer => users.includes(reviewer.email));

		if (reviewersToRemove.length > 0) {
			this.removeReviewers(reviewersToRemove);
		}
	}

	openUsersSelection = () => {
		this.selectedUsers = _.map(this.config.threat_protection.reviewers, 'email');
		this.usersSelectionPopup = {
			show: true
		};
	}

	removeReviewers = (emails) => {
		if (!emails || !emails.length) {
			return;
		}

		let body = [];
		const isPrimary = _.find<any>(this.config.threat_protection.reviewers, reviewer => reviewer.is_primary);
		if (isPrimary) {
			body.push('The "primary" status will be assigned to the next unassigned reviewer in your list');
		}

		emails = _.map(emails, 'email');
		const emailsRemovedText = emails.length === 1 ? emails[0] : emails.length + ' emails';

		this.gs.showPopup({
			title: 'Remove reviewer',
			subTitle: `Are you sure you want to remove ${emailsRemovedText}?`,
			body: body,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: this.dic.CONSTANTS.inboundConfigurationsActions.remove,
			doneCb: () =>{
				let data = {
					type: this.dic.CONSTANTS.configTpAction.reviewers,
					action: this.dic.CONSTANTS.inboundConfigurationsActions.remove,
					emails: []
				};

				emails.forEach(email => {
					if (this.gs.validateEmail(email)) {
						data.emails.push(email);
					}
				});

				this.removeAEmailInProcess = true;

				this.rs.updateTpConfig(data).then( () => {
					const deletedReviewers = _.remove<any>(this.config.threat_protection.reviewers, e => emails.includes(e.email));
					//primary reviewer was deleted
					if (deletedReviewers && _.find<any>(deletedReviewers, r => r.is_primary) && this.config.threat_protection.reviewers.length) {
						let newPrimaryReviewer = this.config.threat_protection.reviewers[0];
						newPrimaryReviewer.is_primary = true;
						const reviewerIndex = _.findIndex<any>(this.config.threat_protection.reviewers, r => r.email === newPrimaryReviewer.email);
						if (reviewerIndex > -1) {
							this.config.threat_protection.reviewers[reviewerIndex].is_primary = true;
						}
					}

					this.removeAEmailInProcess = false;
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemRemoved, emailsRemovedText));
				});
			}
		});
	};

	updateNotifyReviewerViewContent = () => {
		const tpData = {
			type: this.dic.CONSTANTS.rulesTpAction.notifyReviewerViewContent,
			enabled: this.config.threat_protection.notify_reviewer_view_content,
		};

		this.actionInProcess = true;
		this.rs.updateTpRules('', tpData).then(() => {
			this.actionInProcess = false;
		});
	};

	setReviewerAsPrimary = (reviewer, index) => {
		this.gs.showPopup({
			title: "Set reviewer as primary",
			subTitle: `You are about to set ${reviewer.email} as a primary reviewer`,
			body: [
				'The primary reviewer will receive notifications for all groups/mailboxes for which no reviewer is specified',
				'The primary reviewer can also be assigned as a group reviewer'
			],
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'Set',
			doneCb: () =>{
				let data = {
					type: this.dic.CONSTANTS.configTpAction.reviewers,
					action: this.dic.CONSTANTS.reviewerActions.setAsPrimary,
					emails: [reviewer.email]
				};

				this.rs.updateTpConfig(data).then(() => {
					this.config.threat_protection.reviewers.forEach(r => {
						r.is_primary = false;
					});
					this.config.threat_protection.reviewers[index].is_primary = true;
					this.ns.showInfoMessage(this.dic.MESSAGES.setReviewerAsPrimary);
				},  (error) => {
				});
			}
		});
	};

	showReviewerBulkActions = () => {
		return [
			this.dic.CONSTANTS.inboundConfigurationsActions.remove
		]
	}

	showReviewerActions = (reviewerObj) => {
		const actions = [
			this.dic.CONSTANTS.reviewerActions.assignGroups,
			this.dic.CONSTANTS.reviewerActions.assignMailboxes,
			this.dic.CONSTANTS.reviewerActions.modifyPermissions,
			this.dic.CONSTANTS.inboundConfigurationsActions.remove
		];

		if (!reviewerObj.is_primary) {
			actions.unshift(this.dic.CONSTANTS.reviewerActions.setAsPrimary)
		}

		return actions;
	}

	openAssignReviewerToMailboxesPopup = (show, reviewerObj = null) => {
		this.assignReviewerToMailboxesPopup = {
			show,
			reviewerObj,
			mailboxesInProcess: true,
			only_selected_mailboxes: this.config.threat_protection.reviewers.only_selected_mailboxes
		}

		this.rs.getMailboxesInfo().then(data => {
			this.assignReviewerToMailboxesPopup.mailboxes = data;
			this.assignReviewerToMailboxesPopup.mailboxes.forEach((mailboxObj) => {
				if (reviewerObj.mailboxes?.includes(mailboxObj.email)) {
					mailboxObj.selected = true;
				}
			});
			this.assignReviewerToMailboxesPopup.mailboxesInProcess = false;
		});
	}

	applyReviewerToMailboxes = () => {
		this.assignReviewerToMailboxesPopup.actionInProcess = true;

		const mailboxesSelected = _.map(_.filter(this.assignReviewerToMailboxesPopup.mailboxes, 'selected'), mailbox => {
			return mailbox.email;
		});

		const data = {
			type: this.dic.CONSTANTS.configTpAction.reviewers,
			action: this.dic.CONSTANTS.reviewerActions.assignMailboxes,
			emails: [this.assignReviewerToMailboxesPopup.reviewerObj.email],
			only_selected_mailboxes: this.assignReviewerToMailboxesPopup.only_selected_mailboxes,
			mailboxes: mailboxesSelected
		};

		this.rs.updateTpConfig(data).then( (response) => {
			this.assignReviewerToMailboxesPopup.reviewerObj.mailboxes = mailboxesSelected;
			this.config.threat_protection.reviewers.only_selected_mailboxes = this.assignReviewerToMailboxesPopup.only_selected_mailboxes;
			this.assignReviewerToMailboxesPopup = false;
			this.ns.showInfoMessage(this.dic.MESSAGES.mailboxesAssigned);
		},  (error) => {
		});
	};

	searchMailbox = (event) => {
		this.assignReviewerToMailboxesPopup.mailboxes.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = searchMailboxExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}


	openAssignReviewerToGroupsPopup = (show, reviewerObj=null) => {
		if (show) {
			this.assignReviewer = reviewerObj;
			this.assignReviewerSelectedGroups = reviewerObj.groups ? reviewerObj.groups.map(itm => itm.email) : null;
		}
		this.assignReviewerToGroupsPopup = show;
	};

	applyReviewerToGroups = (groups) => {
		const groupsSelected = _.map(_.filter(groups, 'selected'), g => {
			return {
				email: g.email,
				name: g.displayName,
				group_id: g._id
			}
		});

		const data = {
			type: this.dic.CONSTANTS.configTpAction.reviewers,
			action: this.dic.CONSTANTS.reviewerActions.assignGroups,
			emails: [this.assignReviewer.email],
			groups: groupsSelected
		};

		this.rs.updateTpConfig(data).then( (response) => {
			this.assignReviewerToGroupsPopup = false;
			this.assignReviewer.groups = groupsSelected;
			this.ns.showInfoMessage(this.dic.MESSAGES.groupsAssigned);
		},  (error) => {
		});
	};

	reviewerViewContentChangeExecute = () => {
		const data = {
			type: this.dic.CONSTANTS.configTpAction.reviewers,
			action: 'viewContent',
			emails: [this.reviewerPermissionPopup.reviewerObj.email],
			enabled: this.reviewerPermissionPopup.reviewerObj.allow_view_content
		};

		this.rs.updateTpConfig(data).then(() => {
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
		},  (error) => {
		});
	};

	reviewerReleaseMaliciousEmailExecute = () => {
		const data = {
			type: this.dic.CONSTANTS.configTpAction.reviewers,
			action: 'maliciousEmails',
			emails: [this.reviewerPermissionPopup.reviewerObj.email],
			enabled: this.reviewerPermissionPopup.reviewerObj.reviewer_allow_release_malicious
		};

		this.rs.updateTpConfig(data).then(() => {
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
		},  (error) => {
		});
	};

	reviewerDefangMaliciousUrl = () => {
		const data = {
			type: this.dic.CONSTANTS.configTpAction.reviewers,
			action: 'Defang Malicious URL',
			emails: [this.reviewerPermissionPopup.reviewerObj.email],
			enabled: this.reviewerPermissionPopup.reviewerObj.client_defang_malicious_url
		};

		this.rs.updateTpConfig(data).then(() => {
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
		},  (error) => {
		});
	};

	reviewerChangeConfigurationExecute = () => {
		const data = {
			type: this.dic.CONSTANTS.configTpAction.reviewers,
			action: 'changeConfiguration',
			emails: [this.reviewerPermissionPopup.reviewerObj.email],
			enabled: this.reviewerPermissionPopup.reviewerObj.allow_change_configuration
		};

		this.rs.updateTpConfig(data).then(() => {
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
		},  (error) => {
		});
	};

	openReviewerPermissionPopup = (reviewerObj) => {
		this.reviewerPermissionPopup = {
			show: true,
			reviewerObj: reviewerObj
		};
	};

	selectReviewerAction = (reviewer, action) => {
		const index = _.findIndex(this.config.threat_protection.reviewers,reviewer);
		switch (action) {
			case this.dic.CONSTANTS.reviewerActions.setAsPrimary:
				this.setReviewerAsPrimary(reviewer, index);
				break;

			case this.dic.CONSTANTS.reviewerActions.assignGroups:
				this.openAssignReviewerToGroupsPopup(true, reviewer);
				break;

			case this.dic.CONSTANTS.reviewerActions.assignMailboxes:
				this.openAssignReviewerToMailboxesPopup(true, reviewer);
				break;

			case this.dic.CONSTANTS.inboundConfigurationsActions.remove:
				this.removeReviewers([reviewer]);
				break;

			case this.dic.CONSTANTS.reviewerActions.modifyPermissions:
				this.openReviewerPermissionPopup(reviewer);
				break;
		}
	};

	addReviewers = (reviewers) => {
		// check if reviewers already exist
		const existingEmails = _.map(this.config.threat_protection.reviewers, 'email');
		if (existingEmails.length) {
			reviewers.forEach(reviewer => {
				if (existingEmails.includes(reviewer.email) && !reviewer.isNew) {
					this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailAlreadyExist, reviewer.email));
					_.remove<any>(reviewers, reviewer);
				}
			});
		}
		if (!reviewers.length) {
			return;
		}
		//

		const emailsOnly = _.map(reviewers, 'email');
		const data = {
			type: this.dic.CONSTANTS.configTpAction.reviewers,
			action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
			emails: emailsOnly
		};

		this.rs.updateTpConfig(data).then( (response) => {
			// determine fields for reviewers
			reviewers.forEach((reviewer) => {
				const reviewerObj = reviewer.isNew ? reviewer : {};
				const isPrimary = !_.find(this.config.threat_protection.reviewers, rev => {return !rev.isNew}); // check if reviewers list empty or occupied only by the new editted item.
				reviewerObj.is_primary = isPrimary;
				reviewerObj.email = reviewer.email;
				reviewerObj.allow_view_content = true;
				reviewerObj.reviewer_allow_release_malicious = true;
				reviewerObj.allow_change_configuration = true;
				reviewerObj.client_defang_malicious_url = false;
				reviewerObj._id = reviewer._id || null;
				reviewerObj.checked = true;

				if (!reviewer.isNew) {
					this.config.threat_protection.reviewers.push(reviewerObj);
				}
				else { // (already in list)
					reviewer.edit = null;
					reviewer.isEditMode = false;
					reviewer.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit
				}
			});

			if (!_.find(this.config.threat_protection.reviewers,'isNew')) {
				this.addReviewerInProcess = false;
			}
			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, reviewers.length > 1 ? reviewers.length + ' reviewers' : reviewers[0].email));

		},  (error) => {
			if (!_.find(this.config.threat_protection.reviewers,'isNew')) {
				this.addReviewerInProcess = false;
			}

			if (error.data.userNotInPlan) {
				const message = util.format(this.dic.ERRORS.tpUserNotPartOfPlan,
					'<a target="_self" href="/'+ this.dic.CONSTANTS.appStates.adminOutbound + '/' + this.dic.CONSTANTS.adminPages.outbound.userManagement + '"><b><i>User Management</i></b></a>');

				this.ns.showErrorMessage(message);
			}
		});
	};

	searchReviewers =  (event) => {
		this.config.threat_protection.reviewers.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = searchTextExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			// // no filters for reviewers table

			record.hide = false;
		});
	};

	confirmAddReviewer =  (reviewer ,isApproved) => {
		// new reviewer process was declined
		if (!isApproved) {
			_.remove<any>(this.config.threat_protection.reviewers, reviewer);
			this.addReviewerInProcess = false;
			return;
		}
		//

		// new reviewer approved
		if (!reviewer.edit.email) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Reviewer email field'));
		}
		if (!this.gs.validateEmail(reviewer.edit.email)) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.invalidEmail, reviewer.edit.email));
		}
		if (_.find(this.config.threat_protection.reviewers, rev => {return !rev.isNew && rev.email && rev.email === reviewer.edit.email})) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailAlreadyExist, reviewer.edit.email));
		}

		reviewer.email = reviewer.edit.email;

		this.addReviewers([reviewer])
	};

	exportReviewersListToCsv = (sortBy) => {
		let csvString = "email, view content, release malicious, change configuration, defang malicious URLs\n";

		let sortedTable = _.filter(this.config.threat_protection.reviewers,reviewer => {return !reviewer.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((reviewer) => {
			csvString += `${reviewer.email}${reviewer.is_primary ? ' (Primary)' : ''},${reviewer.allow_view_content},${reviewer.reviewer_allow_release_malicious},${reviewer.allow_change_configuration},${reviewer.client_defang_malicious_url}\n`;
		});
		this.gs.exportCsv(csvString, `configuration_reviewers.csv`);
	}
}

function searchTextExecute(reviewerObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return (reviewerObj.email?.toLowerCase().includes(searchTerm));
}

function searchMailboxExecute(reviewerObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return (reviewerObj.email?.toLowerCase().includes(searchTerm) || reviewerObj.name?.toLowerCase().includes(searchTerm));
}
