import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {GeneralService} from "../../services/generalService";
import {AuthService} from "../../services/authService";
import {DICTIONARY} from "../../dictionary";
import {AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ResolveEnd, Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {LookAndFeelService} from "../../services/lookAndFeelService";
import {UserPermissionService} from "../../services/userPermissionService";

@Component({
	selector: 'content-component',
	templateUrl: './content.component.html',
})
export class ContentComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private router:Router,
				private ngZone:NgZone,
				private authService:AuthService,
				private rs:RouteService,
				public gs:GeneralService,
				public userPermissionService:UserPermissionService,
				public lfs:LookAndFeelService,
				private ns:NotificationService) {
    }

    dic = DICTIONARY;
	_ = _;
	showAdminDropdown = false;
    showComodo = this.gs.showComodo;
    appPagesNaviData = [];
    url = this.gs.url;
    about = this.gs.about;
    useCases = this.gs.useCases;
    eula = this.gs.eula;
    privacy = this.gs.privacy;
    antiSpam = this.gs.antiSpam;
    contactUs = this.gs.contactUs;
    footerCompanyName = this.gs.footerCompanyName;
    isSidebarCollapsed = (localStorage.isSidebarCollapsed === 'true');
    showOnboardingPopup = false;
    onboardingInfo;
    onboardingActiveStep;
    activeItemTitle;
    currentSection;
    currentPage;
    stateTitle;
    stateIcon;
	stateIconSvgName;
    searchResults;
    showGlobalSearchResults;
    searchText;
    searchAdminTxt;
    showOnboardingButton;
    onboardingStatusTxt;
    onboardingInstallationDone;
	showPersonalPages;
	showSupportMenu;
	appLoaded;
	isTimeout;
	timeoutIdle;
	planAdmins = this.gs.planAdmins;

	userInfo;

	private unsubscribeAll: Subject<any> = new Subject<any>();

	@ViewChild('appContainer') appContainer: ElementRef;


    ngOnInit() {
		this.resetTimer();

		this.gs.getUserInfo(false,  (userInfo, err) => {
			this.userInfo = userInfo;
			this.appLoaded = true;
			this.gs.setDarkMode(this.userInfo.dark_mode);
			if (this.userInfo.plan?.customization?.lf?.enabled) {
				this.lfs.applyLf(this.userInfo.plan?.customization?.lf?.color || '', this.userInfo.plan?.customization?.lf?.logo ? "data:image/png;base64," + this.userInfo.plan.customization.lf.logo : '')
			}

			this.shouldShowPersonalPages(userInfo);
		});

		this.setNavigationData();

		this.updateNavigationState(this.router.url)


        this.gs.adminInfoMap = {};
        this.getOnboardingInfo();

		this.setEventSubscribers();
    }

	ngOnDestroy() {
		this.unsubscribeAll.next({});
	}

	ngAfterViewInit() {
		this.ngZone.runOutsideAngular(() => {
			// run outside Angular to avoid change detection every mouse move in all descendents
			this.appContainer.nativeElement.addEventListener('mousemove',this.resetTimer);
			this.appContainer.nativeElement.addEventListener('keypress',this.resetTimer);
		});
	}

	setEventSubscribers = () => {
		this.router.events.pipe(takeUntil(this.unsubscribeAll)).subscribe((routerData) => {
			if (routerData instanceof ResolveEnd){
				this.updateNavigationState(routerData.url);
			}
		});

		this.gs.primaryNameChangeSubj.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
			if (!data) {return;}

			this.gs.userInfo.firstName = data.firstName;
			this.gs.userInfo.lastName = data.lastName;
		});

		this.gs.onboardingQuestionnaireStatusSubj.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
			if (!data) {return;}

			this.onboardingInfo = data;
			this.setBoardingInfo(data);
		});

		this.gs.navigatedThroughOnboardingSubj.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
			if (!data) {return;}

			this.onboardingActiveStep = data; // {scope: inbound/outbound, stepName: stepName}
			this.showOnboardingPopup = false;
		});

		this.gs.cancelOnboardingActiveStepSubj.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
			if (!data) {return;}

			this.onboardingActiveStep = null;
		});

		this.gs.cancelOnboardingActiveStepSubj.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
			if (!data) {return;}

			this.onboardingActiveStep = null;
		});

		this.gs.updatePlanAdminsSubj.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
			if (!data) {return;}

			setTimeout(() => { // wait for main subscriber in generalService to end his job
				this.planAdmins = this.gs.planAdmins;
				this.searchAdmin(this.searchAdminTxt);
			}, 100);
		});
	}

	resetTimer = () => {
		this.authService.isAuthenticated().then(isAuthenticated => {
			if (!isAuthenticated || this.isTimeout) {
				return;
			}
			clearTimeout(this.timeoutIdle);
			this.timeoutIdle = setTimeout(() => {
				// back to Angular
				this.ngZone.run(() => {
					this.authService.appTimeoutSubj.next(true);
					this.isTimeout = true;
				});
			},  3 * 60 * 60 * 1000); // 3 hours
		});
	}

	setNavigationData = () => {
		const setRouteData = (routeObj, level, fullPath) => { // level: 0-section. 1-page. 2-tab.

			// pick navigation data fields + 'state'/'page'/'tab' field
			const edittedRoute = _.pick(routeObj.data || {}, ['title', 'tag', 'iconClass', 'isNewFeature', 'isBetaFeature', 'iconSvgName', 'showInSidebar', 'keywords']);
			edittedRoute['tag'] = level === 0 ? (edittedRoute.tag || edittedRoute.title) : null;
			const pathName =  ['state', 'page', 'tab'][level];
			edittedRoute[pathName] = routeObj.path;
			fullPath += '/' + routeObj.path;

			edittedRoute['show'] = this.userPermissionService.getPermission(_.trim(fullPath, '/')) === true;

			Object.assign(routeObj, edittedRoute);

			// set 'pages'/innerTabs' fields
			if (routeObj.children) {
				const childrenName = level === 0 ? 'pages' : 'innerTabs';
				routeObj[childrenName] = _.cloneDeep(_.filter(routeObj.children, 'path'));
				routeObj[childrenName].forEach(childRoute => {
					setRouteData(childRoute, level + 1, fullPath);
				});
			}

			// Delete redundunt fields
			const keysToDelete = ['path', 'component', 'children', 'data'];
			keysToDelete.forEach(key => delete routeObj[key]);

			if (level === 0) {
				this.appPagesNaviData.push(routeObj);
			}
		}

		////

		const contentRoute:any = _.cloneDeep(_.find(this.router.config, route => !route.path && route.children));
		contentRoute.children.forEach(route => {
			if (route.path && route.path !== 'dummy') {
				setRouteData(route, 0, '');
			}
		});

		this.setLinksForGlobalSearch();
	}

	setLinksForGlobalSearch = () => {
		const linksForSearch = [
			{
				title: 'Authentication',
				url: 'https://docs.trustifi.com/docs/authentication',
				show: true,
				showInSidebar: false,
				keywords: ['Authentication']
			},
			{
				title: 'Sensitivity Threshold',
				url: 'https://docs.trustifi.com/docs/sensitivity-threshold',
				show: true,
				showInSidebar: false,
				keywords: ['Sensitivity Threshold']
			}
		];

		this.appPagesNaviData = this.appPagesNaviData.concat(linksForSearch);
	}

    logout() {
        this.authService.logout();
    };

    toggleSidebarWidth() {
        this.activeItemTitle = null;
        this.isSidebarCollapsed = !this.isSidebarCollapsed;
        localStorage.isSidebarCollapsed = this.isSidebarCollapsed.toString();
    }

    updateNavigationState(fullStateString) {
        const statesHierarchyArr = fullStateString.substring(1).split('/');

		this.currentSection = statesHierarchyArr[0];
        this.currentPage = (statesHierarchyArr[1] || '').split('?')[0]  || '';

        if (!statesHierarchyArr[2]) { // close all notifications when navigating to a new section or page, but not when navigating to a new tab
            this.ns.closeAll();
        }

        if (this.appPagesNaviData.length) {
            const currentPageObj = this.getTitleAndIcon(this.currentSection);
            if (currentPageObj) {
                this.stateTitle = currentPageObj.title;
                this.activeItemTitle = currentPageObj.title;
                this.stateIcon = currentPageObj.icon;
                this.stateIconSvgName = currentPageObj.iconSvgName;
            }
        }
    }

    getTitleAndIcon(stateName) {
        // state's title & icon:
        let targetPage;
        targetPage = _.find(this.appPagesNaviData, pageDataObj => {
            // search in page
            if (pageDataObj.state && pageDataObj.state === stateName) {
                return true;
            }
            // search in sub-pages
            else if (pageDataObj.pages) {
                const isSubPage = _.find(pageDataObj.pages, subPageDataObj => {
                    if (subPageDataObj.state && subPageDataObj.state === stateName) {
                        return true;
                    }
                });
                if (isSubPage) {
                    return true; // return the parent
                }
            }
        });

        if (targetPage) {
            return {title: targetPage.title, icon: targetPage.iconClass, iconSvgName: targetPage.iconSvgName};
        }
    }

    searchGlobal(searchText) {
        this.searchResults = [];
        if (!searchText || searchText.length < 2) {
            this.showGlobalSearchResults = false;
            return;
        }
        this.showGlobalSearchResults = true;
        searchText = searchText.toLowerCase();
        // clone the pages data object first, because some changes to items are applied before releasing results
        const appPagesNaviDataCopy = _.cloneDeep(this.appPagesNaviData);

        // search syntax function:
        // Reminder. Hirarchy: Section ----> page -----> tab
        const searchInSinglePageObj = (contentObj, parentSection, parentPage) => {
            if (contentObj.show) {
                contentObj['parentSection'] = parentSection || null; // used for showing the parent section 'tag' in search results box, and for using the parent 'state' property when navigating
                contentObj['parentPage'] = parentPage || null; // used for showing the parent page 'title' in search results box, and for using the parent 'page' property when navigating

                if (contentObj.keywords) {
                    // flatten the keywords array of string to one long string and keep the place the search string is found in it for results rating
                    let flattenKeywords = contentObj.keywords.join(" ");
                    flattenKeywords = flattenKeywords.toLowerCase();
                    const foundInStringIdx = flattenKeywords.indexOf(searchText);
                    // regex check if the search input is found in the start of a word
                    const isStartOfWord = (new RegExp('\\b' + searchText, 'i')).test(flattenKeywords);

                    // page approved to results:
                    if (foundInStringIdx > -1 && isStartOfWord) {
                        contentObj['rate'] = foundInStringIdx; // used for sorting the results by relevance
                        this.searchResults.push(contentObj);
                    }
                }

                // recursion to search in sub-pages (second level) or inner tabs (third level)
                const pageChildren = contentObj.pages || contentObj.innerTabs;
                if (pageChildren) {
                    pageChildren.forEach(childPageObj => {
                        searchInSinglePageObj(childPageObj, parentSection || contentObj, contentObj.innerTabs && contentObj)
                    });
                }
            }
        }

        // perform search syntax for each page
        appPagesNaviDataCopy.forEach(pageObj => {
            searchInSinglePageObj(pageObj, null, null);
        });
    }

    navigateFromSearch(resultPageObj) {
        // result redirect to external link
        if (resultPageObj.url) {
            return;
        }
        // result redirect to a section
        if (!resultPageObj.page && !resultPageObj.tab) {
            this.navigateToPage(resultPageObj.state);
        }
        // result redirect to a page
        else if (resultPageObj.page) {
            this.navigateToPage(resultPageObj.parentSection.state, resultPageObj.page);
        }
        // result redirect to a page's inner tab
        else if (resultPageObj.tab) {
            this.navigateToPage(resultPageObj.parentSection.state, resultPageObj.parentPage.page, resultPageObj.tab);
        }

        this.searchText = '';
        this.showGlobalSearchResults = false;
    }

    navigateToPage(state, page=null, tab=null) {
		this.router.navigate(_.compact([state,page,tab]))

        if (page) {
            this.currentPage = page;
        }
    };

    searchAdmin(searchTerm) {
        searchTerm = (searchTerm || '').toLowerCase();
        this.planAdmins = _.filter(this.gs.planAdmins, admin => admin.display_email.includes(searchTerm));
    }

    clearSearchAdmin() {
        this.searchAdminTxt = '';
		this.planAdmins = this.gs.planAdmins;
    }

    changeControlAdmin(admin) {
        if (this.gs.controlAdmin && this.gs.controlAdmin.email === admin.email) {
            return;
        }
        this.gs.controlAdmin = admin;
        this.authService.setManagedAdmin(this.gs.controlAdmin.email);

		// reload views and controllers for new admin (reloads the last level of state)
		// we route to a dummy here (we don't want to fall into the content route adminGuard guard again)
		const activeRoutePath = this.router.url;
		this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
			this.router.navigateByUrl(activeRoutePath);
		});

		this.ns.showInfoMessage(util.format(this.dic.MESSAGES.controlAdminChanged, this.gs.controlAdmin.display_email));

		if (this.gs.controlAdmin.email !== this.gs.userInfo.email) {
			if (this.gs.adminInfoMap[this.gs.controlAdmin.email]?.lf) {
				this.applyLF(this.gs.adminInfoMap[this.gs.controlAdmin.email].lf);
			}
			else {
				this.rs.getCustomization().then(plan => {
					if (this.gs.adminInfoMap[this.gs.controlAdmin.email]) {
						this.gs.adminInfoMap[this.gs.controlAdmin.email].plan.customization.lf = plan.customization.lf;
					}
					this.applyLF(plan.customization.lf);
				});
			}
		}
		else {
			this.applyLF(this.gs.userInfo.plan.customization.lf);
		}
    };

	applyLF = (lfData) => {
		if (lfData.enabled) {
			this.lfs.applyLf(lfData.color, lfData.logo ? "data:image/png;base64," + lfData.logo : '');
		}
		else {
			this.lfs.resetLfToDefault();
		}
	}

    getOnboardingInfo() {
        if (!this.userInfo || this.userInfo.type === this.dic.CONSTANTS.userTypes.heroku
            || (this.userInfo.user_role === this.dic.CONSTANTS.userRole.user && this.userInfo.plan.plan === this.dic.CONSTANTS.planTypePro)) {
            return;
        }

        this.rs.getOnboardingProcessInfo().then((response) => {
            this.setBoardingInfo(response.onboarding_process);
        }, (err) => {
        });
    }

    setBoardingInfo(onboardingProcessObj) {
        this.onboardingInfo = onboardingProcessObj;
        this.showOnboardingButton = true;

        this.onboardingStatusTxt = '';
        switch (this.onboardingInfo?.questionnaire_step) {
            case this.dic.CONSTANTS.onboardingProcessQuestionnaireStep.init:
                this.onboardingStatusTxt = "Install Trustifi easily";
                break;

            case this.dic.CONSTANTS.onboardingProcessQuestionnaireStep.pendingSupport:
                this.onboardingStatusTxt = "Pending review";
                break;

            case this.dic.CONSTANTS.onboardingProcessQuestionnaireStep.completed:
                // BC for users who do not have questionnaire at all
                if (!this.onboardingInfo.questionnaire) {
                    this.showOnboardingButton = false;
                    return;
                }

                this.onboardingStatusTxt = "Review installation";
                // check if all steps are done
                if (this.onboardingInfo.outbound_steps && this.onboardingInfo.outbound_steps.length && this.onboardingInfo.outbound_steps.find(step => step.status !== this.dic.CONSTANTS.onboardingStepStatus.done)) {
                    this.onboardingStatusTxt = 'Continue Installation';
                }
                else if (this.onboardingInfo.inbound_steps && this.onboardingInfo.inbound_steps.length && this.onboardingInfo.inbound_steps.find(step => step.status !== this.dic.CONSTANTS.onboardingStepStatus.done)) {
                    this.onboardingStatusTxt = 'Continue Installation';
                }
                else {
                    this.onboardingInstallationDone = true;
                }
                break;

            default:
                this.onboardingStatusTxt = 'Review Installation';
        }
    }

	shouldShowPersonalPages(userInfo) {
		if (userInfo?.type === this.dic.CONSTANTS.userTypes.dlp) {
			this.showPersonalPages = false;
			this.showSupportMenu = false;
			return;
		}

		const hidePersonalPagesPlans = ["650cc08cd74b1462095f319b"]; // bkash :(

		if (hidePersonalPagesPlans.includes(userInfo.plan._id)) {
			if (userInfo?.user_role === this.dic.CONSTANTS.userRole.admin || userInfo?.user_role === this.dic.CONSTANTS.userRole.subAdmin) {
				this.showSupportMenu = true;
				this.showPersonalPages = true;
				return;
			}

			if (isUserReviewer(userInfo)) {
				this.showSupportMenu = true;
			}
			this.showPersonalPages = false;

			return;
		}

		this.showSupportMenu = true;
		this.showPersonalPages = true;
	}

	darkModeToggle = () => {
		this.userInfo.dark_mode = !this.userInfo.dark_mode;
		this.gs.setDarkMode(this.userInfo.dark_mode);

		const actionInfo = {
			action: this.dic.CONSTANTS.userProfileActions.darkmode,
			enabled: this.userInfo.dark_mode
		};
		this.rs.updateUserSettings(actionInfo).then(() => {});
	}
}

function isUserReviewer(userInfo) {
	return userInfo.isAccountCompromisedReviewer || userInfo.isArchiveReviewer || userInfo.isInboundReviewer
		|| userInfo.isOutboundReviewer || userInfo.isPartnerReviewer || userInfo.isThreatSimulationReviewer;
}
