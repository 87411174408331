import {Component, OnInit} from '@angular/core';
import {RouteService} from "../../../../services/routeService";
import {GeneralService} from "../../../../services/generalService";
import {NotificationService} from "../../../../services/notificationService";
import {DICTIONARY} from '../../../../dictionary';
import _ from "lodash";
import moment from "moment";


@Component({
  selector: 'app-domain-impersonation',
  templateUrl: './domain-impersonation.component.html',
  styleUrls: ['./domain-impersonation.component.scss']
})
export class DomainImpersonationComponent implements OnInit {

	constructor(private gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
	}

	dic = DICTIONARY;

	viewTypes = {
		cards: 'cards',
		list: 'list'
	};
	selectedViewType = this.viewTypes.cards;

	setDomainStatusPopup;
	reportAbusePopup;
	domainImpersonations: any = [];
	incidentCards: any;
	domainsInIncidentsList: any;
	selectedDomainsForCards: any;
	_ = _;

	domainConfigurations: any = {};

	loadingIncidentsInProcess;

	ngOnInit() {
		this.getDomainImpersonations();
	}

	getDomainImpersonations = () => {
		this.loadingIncidentsInProcess = true;
		this.rs.getDomainImpersonations().then(response => {
			this.domainImpersonations = response.domain_impersonations || [];
			this.domainConfigurations = response.domain_impersonation;

			this.domainImpersonations.forEach((domainObj) => {
				this.setIncidentColor(domainObj);
				domainObj.scoreChartOptions = this.createScoreChartOptions(domainObj);
				domainObj.displayName = domainObj.original_domain;
			});

			this.domainImpersonations = _.orderBy(this.domainImpersonations, ['created'], ['desc']);

			// create domains for the dropdown
			this.domainsInIncidentsList = _.unionBy([{displayName: 'All Domains'}, ...this.domainImpersonations], 'displayName');
			this.selectedDomainsForCards = this.domainsInIncidentsList[0];
			//

			this.changeViewType(this.selectedViewType);
			this.loadingIncidentsInProcess = false;
		}, (err) => {
			this.loadingIncidentsInProcess = false;
		});
	}

	setIncidentColor = (incidentObj) => {
		incidentObj.color = incidentObj.status === this.dic.CONSTANTS.domainImpersonationStatus.safe.value ? 'var(--bs-success)' :
			incidentObj.status === this.dic.CONSTANTS.domainImpersonationStatus.suspicious.value ? 'var(--bs-warning)' : 'var(--bs-danger)';
	}

	showDomainActions = () => {
		return [
			DICTIONARY.CONSTANTS.domainImpersonationsObjActions.status.display,
			DICTIONARY.CONSTANTS.domainImpersonationsObjActions.retest.display,
			DICTIONARY.CONSTANTS.domainImpersonationsObjActions.report.display
		];
	}


	selectDomainAction = (domainObj, action) => {
		switch (action) {
			case DICTIONARY.CONSTANTS.domainImpersonationsObjActions.status.display:
				this.updateDomainObjStatus(domainObj);
				break;

			case DICTIONARY.CONSTANTS.domainImpersonationsObjActions.retest.display:
				this.retestDomainObjMetrics(domainObj);
				break;

			case DICTIONARY.CONSTANTS.domainImpersonationsObjActions.report.display:
				this.reportDomainAbuse(domainObj);
				break;
		}
	};

	updateDomainObjStatus = (domainObj) => {
		this.setDomainStatusPopup = {
			show: true,
			domainObj,
			newStatus: domainObj.status
		};
	}

	updateDomainObjStatusExecute = () => {
		const actionInfo = {
			action: this.dic.CONSTANTS.domainImpersonationsObjActions.status.value,
			status: this.setDomainStatusPopup.newStatus
		};

		this.setDomainStatusPopup.actionInProcess = true;
		this.rs.updateDomainImpersonationObj(this.setDomainStatusPopup.domainObj._id, actionInfo).then(response => {
			this.setDomainStatusPopup.domainObj.status = this.setDomainStatusPopup.newStatus;
			this.setIncidentColor(this.setDomainStatusPopup.domainObj);
			this.setDomainStatusPopup = null;
			this.ns.showInfoMessage(`Operation completed successfully`);
		}, (err) => {
			this.setDomainStatusPopup.actionInProcess = false;
		});
	}

	retestDomainObjMetrics = (domainObj) => {
		this.gs.showPopup({
			title: 'Retest Metrics',
			subTitle: 'The following metrics will be retested:',
			body: ['Domain website', 'Domain email', 'Phishing analysis'],
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'Confirm',
			doneCb: () => {
				const actionInfo = {
					action: this.dic.CONSTANTS.domainImpersonationsObjActions.retest.value,
				};

				domainObj.retestMetricsInProcess = true;

				this.rs.updateDomainImpersonationObj(domainObj._id, actionInfo).then(response => {
					domainObj.hosting_record = response.hostingRecord;
					domainObj.mail_exchange = response.mxRecord;
					domainObj.phishing_heuristics = response.phishingHeuristics;

					this.ns.showInfoMessage(`Operation completed successfully`);
					domainObj.retestMetricsInProcess = false;
				}, (err) => {
					domainObj.retestMetricsInProcess = false;
				});
			}
		});
	}

	reportDomainAbuse = (domainObj) => {
		this.reportAbusePopup = {
			show: true,
			domainObj,
			reason: '',
			doneCb: () => {
				if (!this.reportAbusePopup.reason) {
					this.ns.showErrorMessage(`Missing report reason`);
					return;
				}

				const actionInfo = {
					action: this.dic.CONSTANTS.domainImpersonationsObjActions.report.value,
					report_reason: this.reportAbusePopup.reason
				};

				this.reportAbusePopup.actionInProcess = true;
				this.rs.updateDomainImpersonationObj(domainObj._id, actionInfo).then(response => {
					this.reportAbusePopup = null;
					this.ns.showInfoMessage(`Operation completed successfully`);
				}, (err) => {
					this.ns.showErrorMessage(`Unable to retrieve WHOIS information. Please contact the domain registrar manually.`);
					this.reportAbusePopup.actionInProcess = false;
				});
			}
		};
	}

	changeViewType(selectedViewType, selectedDomain=null) {
		switch (selectedViewType) {
			case this.viewTypes.cards:
				this.selectDomainForCards(this.domainsInIncidentsList[0]);
				this.selectedViewType = this.viewTypes.cards;
				this.loadingIncidentsInProcess = false;
				break;

			case this.viewTypes.list:
				this.searchDomains('', null);
				this.selectedViewType = this.viewTypes.list;
				this.loadingIncidentsInProcess = false;
				break;
		}
	}

	selectDomainForCards = (domainObj) => {
		this.selectedDomainsForCards = domainObj;

		if (domainObj.original_domain) {
			this.incidentCards = _.filter(this.domainImpersonations, {original_domain: domainObj.original_domain});
		}
		// "All Domains" options selected
		else {
			this.incidentCards = this.domainImpersonations;
		}
	}

	getPhishingHeuristicDesc = (heuristic) => {
		if (!heuristic) {
			return '';
		}

		switch (heuristic.toLowerCase()) {
			case 'domain pattern':
				return 'The domain shows patterns commonly used by phishing sites to impersonate the user\'s domain, making it highly likely to be used for phishing or malicious activity.';
			case 'domain homoglyph':
				return 'The domain uses visually similar characters (homoglyphs) to closely mimic the user\'s domain, making it likely to deceive users and lead to phishing or fraudulent activities.';
			case 'log-in page':
				return 'The domain resembles a login page, prompting users to enter credentials, which increases the risk of phishing attempts and the capture of sensitive information.';
			case 'email form':
				return 'The domain features an email form designed to collect user information, often under the guise of legitimate communication, raising concerns about phishing.';
			case 'trademark title':
				return 'The title associated with the domain closely resembles the user\'s brand name, potentially confusing users and increasing the risk of phishing schemes.';
			case 'suspicious title':
				return 'The domain title raises red flags due to its resemblance the user\'s brand name, making it likely to deceive users and facilitate phishing or fraudulent actions.';
			case 'none':
				return 'The heuristic scan found no immediate threats, but continued vigilance is recommended to monitor the domain for potential future risks.';
		}
	}

	createScoreChartOptions = (domainObj) => {
		const minValue = 0;
		const maxValue = 100;

		const domainSimilarity = domainObj.domain_similarity || 0;
		domainObj.domainSimilarityColor = domainSimilarity <= 30 ? '#4be330' : domainSimilarity <= 70 ? '#d59400' : '#ff0000';

		const valueToPercent = (val) => ( (val - minValue) * 100 ) / (maxValue - minValue);

		return {
			series: [valueToPercent(domainSimilarity)],
			chart: {
				type: "radialBar",
				animations: {
					enabled: false
				}
			},
			plotOptions: {
				radialBar: {
					startAngle: -90,
					endAngle: 90,
					dataLabels: {
						name: {
							show: false
						},
						value: {
							formatter: (val) => scoreToString(domainSimilarity),
							fontSize: "13px",
							offsetY: -5
						}
					}
				}
			},
			fill: {
				colors: [domainObj.domainSimilarityColor],
			},
			stroke: {
				dashArray: 4
			},
			labels: ["risk"]
		};
	}


	updateDomainImpersonationStatus = () => {
		const actionInfo = {
			action: this.dic.CONSTANTS.domainImpersonationsActions.status,
			enabled: this.domainConfigurations.enabled
		};

		this.rs.updateDomainImpersonationConfiguration(actionInfo).then((response) => {
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		}, (err) => {
		});
	}

	searchDomains = (searchTerm, activeFilters) => {
		this.domainImpersonations.forEach(record => {
			if (!searchDomainTextExecute(record, searchTerm)) {
				record.hide = true;
				return;
			}

			record.hide = false;
		});
	}

	exportDomainsToCsv = (sortBy) => {
		let csvString = "Detection Date,Your Domain,Suspected Domain,Domain Creation Date,Status,Certificate Issuer,Hosting Website,Receives Email,Similarity\n";

		let sortedTable = _.filter(this.domainImpersonations, record => { return !record.hide; });
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((item) => {
			csvString += `${item.created},${item.original_domain},${item.impersonation_domain},${item.domain_creation_date || 'N/A'},${item.status},"${item.cert_issuer || 'N/A'}","${item.hosting_record?.length ? item.hosting_record.join(', ') : 'N/A'}","${item.mail_exchange?.length ? item.mail_exchange.join(', ') : 'N/A'}",${item.domain_similarity}\n`;
		});

		this.gs.exportCsv(csvString, `domain_impersonations.csv`);
	};
	protected readonly moment = moment;
}

function searchDomainTextExecute(domainObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((domainObj.original_domain?.toLowerCase().indexOf(searchTerm) > -1) ||
		(domainObj.impersonation_domain?.toLowerCase().indexOf(searchTerm) > -1) ||
		(domainObj.cert_issuer?.toLowerCase().indexOf(searchTerm) > -1));
}


function scoreToString(score) {
	return score < 10 ? 'Distinct' :
		score <= 30 ? 'Vaguely' :
			score <= 70 ? 'Similar' : 'Equivalent';
}
