import {Component, Input, OnInit} from '@angular/core';
import _ from "lodash";
import {DICTIONARY} from "../../dictionary";
import {RouteService} from "../../services/routeService";
import {GeneralService} from "../../services/generalService";
import {NotificationService} from "../../services/notificationService";
import {ClipboardService} from "ngx-clipboard";
import {LookAndFeelService} from "../../services/lookAndFeelService";

// Import any other necessary modules or services

@Component({
	selector: 'outbound-rule-usage-c',
	templateUrl: './outbound-rule-usage.component.html',
})
export class OutboundRuleUsageComponent implements OnInit {

	@Input() data: any;

	constructor(private clipboard: ClipboardService,
				private rs:RouteService,
				public gs:GeneralService,
				public lfs:LookAndFeelService,
				private ns:NotificationService) { }

	ngOnInit(): void {
		this.getRuleUsage(this.data.ruleObj, this.data.period);
		this.scopeFunctions = {
			openSensitiveInfoPopup: this.openSensitiveInfoPopup,
			copyEmailIdToClipboard: this.copyEmailIdToClipboard
		};
	}

	dic = DICTIONARY;
	usagePeriod = DICTIONARY.CONSTANTS.trendsPeriod.lastWeek;
	viewTypes = {chart: 'Chart', list: 'List'};
	viewType = this.viewTypes.chart;
	getRuleUsageInProcess;
	ruleUsage;
	sensitivityGraphOptions;
	senderGraphOptions;
	ruleChartOptions;
	ruleEmailIdCopied;
	showSensitiveInfoPopup;
	scopeFunctions;

	trendsPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
	];

	closeModal = () => {
		this.data.show = false;
	};

	getRuleUsage = (ruleObj, period = null) => {
		ruleObj.actions = false;
		this.getRuleUsageInProcess = true;
		if (period && period.value !== this.dic.CONSTANTS.trendsPeriod.range.value) {
			this.usagePeriod = period;
		}
		const ruleAction:any = {
			action: this.dic.CONSTANTS.rules.actions.usage,
			period: {period: this.usagePeriod.value}
		};

		this.rs.updateRule(ruleObj._id, ruleAction).then(response => {
			this.ruleUsage = response;
			this.getEmailCountChartInfo(this.ruleUsage);
			this.getEmailSenderChartInfo(this.ruleUsage?.emails);
			this.getEmailSensitivityChartInfo(this.ruleUsage?.emails);
			this.getRuleUsageInProcess = false;
		}, (err) => {
			this.getRuleUsageInProcess = false;
		});
	};

	getEmailSensitivityChartInfo = (emails) => {
		const sensitivityMap = {};
		emails?.forEach((emailObj) => {
			const sensitiveType = emailObj.sensitivity.sensitive_types?.length ?
				emailObj.sensitivity.sensitive_types[0].sensitive_type : emailObj.sensitivity.sensitive_sub_type;

			if (!sensitiveType) {
				return;
			}
			if (!sensitivityMap[sensitiveType]) {
				sensitivityMap[sensitiveType] = 0;
			}
			sensitivityMap[sensitiveType]++;
		});

		const sensitivityGraph = {
			data: [],
			labels: []
		};

		for (const [key, value] of Object.entries(sensitivityMap)) {
			sensitivityGraph.data.push(key);
			sensitivityGraph.labels.push(value);
		}

		this.sensitivityGraphOptions = {
			series: sensitivityGraph.labels,
			chart: {
				type: 'donut',
				height: '100%',
			},
			plotOptions: {
				pie: {
					donut: {
						size: '45%',
					}
				}
			},
			dataLabels: {
				enabled: true,
				formatter: (val, opt) => {
					return opt.w.globals.series[opt.seriesIndex];
				}
			},
			legend: {
				width: 235
			},
			labels: sensitivityGraph.data,
			tooltip: {
				y: {
					formatter: (val) => {
						return val.toFixed(0);
					}
				}
			}
		}
	}


	getEmailSenderChartInfo = (emails) => {
		const senderMap = {};
		emails?.forEach((emailObj) => {
			if (!senderMap[emailObj.from.email]) {
				senderMap[emailObj.from.email] = 0;
			}
			senderMap[emailObj.from.email]++;
		});
		// @ts-ignore
		const sortedSenders = Object.entries(senderMap).sort((a, b) => b[1] - a[1]);
		const senderGraph = {
			data: [],
			labels: []
		};

		for (let i = 0; i < sortedSenders.length && i < 10; i++) {
			senderGraph.labels.push(sortedSenders[i][0]);
			senderGraph.data.push(sortedSenders[i][1]);
		}
		this.senderGraphOptions = {
			series: [{
				name: 'Emails',
				data: senderGraph.data
			}],
			chart: {
				type: 'bar',
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					animateGradually: {
						enabled: false,
					},
				}
			},
			colors: [
				this.lfs.color
			],
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
					barHeight: this.gs.getOptimalBarWidth(senderGraph.labels.length) + '%',
				}
			},
			dataLabels: {
				total: {
					enabled: true,
					offsetX: 0,
					style: {
						fontSize: '13px',
						fontWeight: 900
					}
				}
			},
			xaxis: {
				categories: senderGraph.labels,
			},
			yaxis: {
				labels: {
					align: 'left',
					minWidth: 140,
					maxWidth: 140,
				}
			}
		}
	}

	getEmailCountChartInfo = (ruleUsage) => {
		const series = [];
		ruleUsage?.series?.forEach((categoryName, index) => {
			series.push({
				name: 'Emails',
				data: ruleUsage.data[index]
			});
		});
		this.ruleChartOptions = {
			series: series,
			chart: {
				type: ruleUsage.labels?.length === 1 ? 'bar' : 'area',
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					animateGradually: {
						enabled: false,
					},
				}
			},
			plotOptions: {
				bar: {
					borderRadius: 5,
					horizontal: false,
					columnWidth: this.gs.getOptimalBarWidth(ruleUsage.labels?.length) - 20 + '%',
				},
			},
			colors: ['#8177a3'],
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['transparent']
			},
			xaxis: {
				categories: ruleUsage.labels,
			},
			yaxis: {
				title: {
					text: 'Emails'
				},
				labels: {
					formatter: (val) => {
						return val.toFixed(0);
					}
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val) => {
						return val.toFixed(0);
					}
				}
			}
		};
	}

	changePeriod = (period) => {
		if (this.usagePeriod === period) {
			return;
		}
		this.usagePeriod = period;
		this.getRuleUsage(this.data.ruleObj);
	};

	changeViewType = (type) => {
		this.viewType = type;
	}

	copyEmailIdToClipboard = (email) => {
		this.clipboard.copy(email.o_message_id);
		this.ruleEmailIdCopied = email.o_message_id;
	};

	searchRuleEmail = (searchTerm) => {
		this.ruleUsage.emails.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	exportRuleEmailsToCsv = (sortBy) => {
		if (!this.ruleUsage.emails || !this.ruleUsage.emails.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}
		let csvString = "Subject,From,Sent,Sensitivity Score,Message ID\n";

		let sortedTable = _.filter(this.ruleUsage.emails,log => {return !log.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((email) => {
			csvString += `"${(email.sent && email.sent.title) ? email.sent.title : ''}","${(email.from && email.from.email) ? email.from.email : ''}",${email.created || ''},${email.sensitivity && email.sensitivity.score || '0'},${email.o_message_id || ''}\n`;
		});

		this.gs.exportCsv(csvString, `outbound_rule_emails.csv`);
	};


	openSensitiveInfoPopup = (emailObj) => {
		this.showSensitiveInfoPopup = {
			email: emailObj,
			sensitivity: emailObj.sensitivity
		};
	};

	closeSensitiveInfoPopup = () => {
		this.showSensitiveInfoPopup = null;
	}
}

function searchTextExecute(ruleObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((ruleObj.sent.title && ruleObj.sent.title.toLowerCase().indexOf(searchTerm) > -1) ||
		(ruleObj.from.email && ruleObj.from.email.toLowerCase().indexOf(searchTerm) > -1));
}
