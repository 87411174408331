<div class="flex-column gap-4">
	<span>Integrate your outbound mail flow with Trustifi to support features like tracking, automated DLP, encryption, and more.</span>
	<div class="d-flex w-100 centralize" *ngIf="!planInfo">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>
	<ng-container *ngIf="planInfo">
		<div class="list-group list-group-flush settings-list-container">
			<!--Sending Architecture -->
			<div class="list-group-item settings-list-item column-style gap-3">
				<div class="title-section-container flex-column gap-2">
					<div class="flex-row gap-1 align-items-center">
						<b>Email Flow Architecture</b>
						<div class="content-container flex-row gap-1 align-items-center" [ngClass]="planInfo?.outbound_relay?.is_connected ? 'text-success' : 'text-danger'">
							<i class="fas fa-circle" style="font-size: 0.7em;"></i>
							<span>{{planInfo?.outbound_relay?.is_connected ? 'Active' : 'Inactive'}}</span>
							<i style="font-weight: 400; color: initial;" class="fas fa-question-circle" *ngIf="!planInfo?.outbound_relay?.is_connected"
							   tooltip='<b>NOTE:</b> The status will change on the first email that will be routed through our servers.'>
							</i>
						</div>
					</div>

					<span class="lh-base" style="font-size: 0.9em;">Configure how Trustifi should be integrated into your <b>outbound</b> mail flow.</span>
				</div>

				<div class="content-container flex-column gap-4">
					<div class="flex-row mb-3 gap-4">
						<div class="flex-row justify-content-around gap-4 f-1-0 px-4 py-3 w-100">
							<ng-container *ngFor="let architecture of sendingArchitecturesData | keyvalue: gs.returnZero">
								<ng-container *ngTemplateOutlet="integrationArchitectureCard; context: {$implicit: architecture}"></ng-container>
							</ng-container>

							<ng-template #integrationArchitectureCard let-architecture>
								<div class="flex-column align-items-center position-relative" style="width: 33%; transition: all 0.15s;">
									<div class="flex-column f-1-0 gap-3 border border-2 rounded p-4 hvr-darken-light cursor-pointer position-relative text-center w-100"
										 (click)="selectArchitecture(architecture.key)"
										 [ngClass]="{'border-primary shadow' : (architecture.key === selectedArchitecture || architecture.key === planInfo?.outbound_relay?.sending_architecture),
										 			'border-secondary-subtle' : (architecture.key !== selectedArchitecture && architecture.key !== planInfo?.outbound_relay?.sending_architecture)}">
										<h5 class="fw-bold flex-row gap-2 centralize" style="transition: all 0.15s;" [ngClass]="{'text-primary' : (architecture.key === selectedArchitecture || architecture.key === planInfo?.outbound_relay?.sending_architecture)}">
											<i class="text-primary opacity-50" [ngClass]="architecture.value.icon"></i>
											<span>Via {{architecture.value.name}}</span>
										</h5>
										<div class="d-flex centralize f-1-0">
											<span style="transition: all 0.15s;" [ngClass]="{'text-muted' : (architecture.key !== selectedArchitecture && architecture.key !== planInfo?.outbound_relay?.sending_architecture)}">
												{{architecture.value.description}}
											</span>
										</div>
										<i class="fa fa-check-circle text-primary" style="position: absolute; top: 8px; left: 8px; font-size: 1rem;" *ngIf="architecture.key === planInfo?.outbound_relay?.sending_architecture"></i>
									</div>
									<div class="w-100 position-absolute" style="height: 32px; bottom: -16px;">
										<btn-c *ngIf="architecture.key === selectedArchitecture.key && architecture.key !== planInfo?.outbound_relay?.sending_architecture" class="animated fadeInDown w-100"
											   (click)="updateSendingArchitecture(architecture.key)">
											Confirm
										</btn-c>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>

			</div>

			<!--Domain and MTA -->
			<div class="list-group-item settings-list-item" *ngIf="selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.client">
				<div class="title-section-container flex-column gap-2">
					<b>Domain and MTA</b>
					<span class="lh-base" style="font-size: 0.9em;">Add the domain, MTA and port which will be used to send your secure emails.<br/>
										You can also add additional MTAs to each domain to be used as a fallback.<br/>Use drag&drop to change the priority of the fallback MTAs.</span>
				</div>
				<div class="content-container flex-column gap-2" *ngIf="planInfo?.outbound_relay">
					<div class="flex-row hSpace05">
						<div style="position: relative; display: inline; flex: 1 0; max-width: 40%;">
							<input type="text" style="margin-bottom:0; padding-right: 75px; width: 100%;"
								   placeholder="Domain"
								   [(ngModel)]="planInfo.outbound_relay.domain"
								   [ngClass]="outboundRelayDomainErr && 'validation-error'"
								   (ngModelChange)="outboundRelayDomainErr = false;">
							<a class="redborder fa"
							   style="width: 80px; position: absolute; right: 5px; top: 5px; font-style: italic; margin: 0; border: none; cursor: pointer; font-weight: bold;"
							   [ngClass]="{'disabled': !planInfo.outbound_relay.domain || updateMtaInProcess}"
							   (click)="updateEmailRelayConfig(dic.CONSTANTS.erSettingsActions.domainMta)">
								Resolve host
							</a>
						</div>
						<input type="text" style="width: 40%; margin-bottom:0; "
							   placeholder="MTA"
							   [(ngModel)]="planInfo.outbound_relay.host">
						<input type="text" style="width: 45px; margin-bottom:0; margin-left: 0.1rem;"
							   placeholder="Port"
							   [(ngModel)]="planInfo.outbound_relay.newPort">
						<btn-c style="min-width: 0;"
							   endIcon="zmdi zmdi-check"
							   [isDisabled]="!planInfo.outbound_relay.host || !planInfo.outbound_relay.newPort || !planInfo.outbound_relay.domain || updateMtaInProcess"
							   (action)="updateEmailRelayConfig(dic.CONSTANTS.erSettingsActions.mta, 'add');">
						</btn-c>
					</div>
					<div cdkDropList class="w100"
						 (cdkDropListDropped)="onDropMta($event)">

						<!-- Draggable Item -->
						<div cdkDrag
							 [cdkDragDisabled]="mta.editMode"
							 *ngFor="let mta of planInfo.outbound_relay.mtas; let $index = index;"
							 class="w100"
							 (cdkDragStarted)="onDragStartMta(mta)">

							<div class="w100">
								<div class="flxRow w100" style="align-items: center; width: calc(233px + 40% + 45px + 33px + 35px); position: relative; max-width: calc(100% - 3px);"
									 *ngIf="!mta.editMode">
									<i class="fa fa-check-circle animated flash" style="position: absolute; left: -5px; transform: translateX(-100%);"
									   *ngIf="mta.verified !== undefined"
									   [ngClass]="{'fa-check-circle' : mta.verified, 'fa-exclamation-triangle' : !mta.verified}"
									   [ngStyle]="{'color' : mta.verified ? 'darkgreen' : 'darkorange'}"
									   tooltip="{{mta.verified ? 'MTA connection is verified' : 'MTA connection verification failed'}}">
									</i>
									<div class="name ellipsis" style="flex: 1 0;" [ngStyle]="{'color' : mta.verified === true ? 'darkgreen' : ''}">
										{{mta.domain}}, {{mta.host}}: {{mta.port}}
									</div>
									<app-dropdown-c style="width: 156px; height: 28px; margin: 1px;" text="Actions">
										<li (click)="setMTAForEdit(mta)"
											[ngClass]="{'disabled' : updateMtaInProcess}"
											id="editMtaConnection">
											Edit
										</li>
										<li (click)="updateEmailRelayConfig(dic.CONSTANTS.erSettingsActions.mta, 'delete', $index)"
											[ngClass]="{'disabled' : updateMtaInProcess}"
											id="deleteMtaConnection">
											Delete
										</li>
										<li (click)="verifyMta(mta)"
											[ngClass]="{'disabled' : updateMtaInProcess}"
											id="checkMtaConnection">
											Check MTA Connection
										</li>
									</app-dropdown-c>
								</div>
								<div *ngIf="mta.editMode" class="flxRow hSpace05">
									<div style="position: relative; display: inline; margin-right: 2px; flex: 1 0; max-width: 40%;">
										<input type="text" style="width: 100%; margin-bottom: 0;"
											   placeholder="Domain"
											   [(ngModel)]="mtaInEdit.domain"
											   [ngClass]="outboundRelayDomainErr && 'validation-error'"
											   (ngModelChange)="outboundRelayDomainErr = false;">
									</div>
									<input type="text" style="width: 40%; margin-bottom: 0;"
										   placeholder="MTA"
										   [(ngModel)]="mtaInEdit.host">
									<input type="text" style="width: 45px; margin: 0 2px;"
										   placeholder="Port"
										   [(ngModel)]="mtaInEdit.port">
									<btn-c style="min-width: 0;" endIcon="zmdi zmdi-check"
										   [isDisabled]="!mtaInEdit.host || !mtaInEdit.port || !mtaInEdit.domain || updateMtaInProcess"
										   (action)="updateEmailRelayConfig(dic.CONSTANTS.erSettingsActions.mta, 'edit');">
									</btn-c>
									<btn-c style="min-width: 0;"
										   endIcon="zmdi zmdi-close" color="danger"
										   variant="text"
										   btnTooltip="Cancel edit"
										   (action)="mtaInEdit.editMode = false; mtaInEdit = null;">
									</btn-c>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>

			<!--Enable Relay & key -->
			<div class="list-group-item settings-list-item" *ngIf="selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.client || selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.trustifi">
				<div class="title-section-container flex-column gap-2">
					<b>Enable Relay</b>
					<span class="lh-base" style="font-size: 0.9em;">Enable in order to allow the Email Relay for all users linked to this plan.</span>
				</div>
				<div class="content-container flex-column gap-4" *ngIf="planInfo?.outbound_relay">
					<toggle-switch-c id="enableRelayToggle"
									 [(model)]="planInfo.outbound_relay.enabled"
									 (onChange)="activateEmailRelay()"></toggle-switch-c>

					<div class="flex-row gap-2 align-items-end p-2 border border-1 border-info">
						<i class="fa fa-info-circle text-info"></i>
						<span>
								<b>Exchange Server Powershell</b> wizards have been moved to the
								<a class="fw-bold blue-link"
								   [routerLink]="['/' + dic.CONSTANTS.appStates.adminOutbound, dic.CONSTANTS.adminPages.outbound.sharedPlan, dic.CONSTANTS.outboundPlanSettingsPageTabs.integrations]"
								   [state]="{integrationType: dic.CONSTANTS.integrationType.powershell}"
								   tooltip="Exchange Server Powershell">
									Integrations tab
								</a>
							</span>
					</div>

					<!--Email Relay header & key-->
					<div class="p-3 flex-column gap-4 rounded border" *ngIf="planInfo?.outbound_relay?.enabled">
						<div class="flex-row justify-content-between">
							<span class="fw-bold" style="font-size: 0.9rem;">Email Relay Credentials</span>

							<a class="blue-link copy-link hSpace05" style="font-size: 11px;"
							   (click)="activateEmailRelay(true);"
							   tooltip="Generate a new key for the Email Relay">
								<i style="cursor: pointer;" [ngClass]="regenerateInProcessER ? 'fa fa-spinner fa-spin' : 'fas fa-sync-alt'" ></i>
								<span>Regenerate Key</span>
							</a>
						</div>
						<div class="flex-column gap-2">
							<div class="flex-row justify-content-between align-items-center">
								<div class="flex-row gap-3 align-items-center f-1-0">
									<span class="fw-bold" style="flex-basis: 55px; font-size: 0.9em;">Header:</span>
									<span>{{emailRelayHeader}}</span>
								</div>

								<a style="font-size: 1rem;" (click)="copyEmailRelayHeaderToClipboard();"
								   tooltip="Copy Email Relay Header">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>
							<div class="flex-row align-items-center gap-3">
								<span class="fw-bold" style="flex-basis: 55px; font-size: 0.9em;">Key:</span>

								<input class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
									   type="{{!showEmailRelayKey ? 'password':'text'}}"
									   [ngStyle]="{'letter-spacing' : showEmailRelayKey ? 'normal' : '2.4px'}"
									   [ngClass]="{'nofill': !showEmailRelayKey}"
									   [(ngModel)]="planInfo.outbound_relay.keyAndSecret" disabled>

								<label class="hidepwd hvr-opacity" style="opacity: 0.4; margin-bottom: 0; font-size: 1.2rem; cursor: pointer;"
									   tooltip="{{showEmailRelayKey ? 'Hide key' : 'Show key'}}">
									<input type="checkbox" [(ngModel)]="showEmailRelayKey"/>
									<i class="fa fa-eye unchecked" ></i>
									<i class="fa fa-eye-slash checked"></i>
								</label>

								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayKeyToClipboard();"
								   tooltip="Copy Email Relay Key">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>
						</div>

						<div class="line-container">
							<hr class="custom-line">
							<div class="line-text">OR</div>
						</div>

						<div class="flex-row justify-content-between">
							<span class="fw-bold" style="font-size: 0.9rem;">SMTP Credentials Authentication</span>
						</div>
						<div class="flex-column gap-1">
							<div class="flex-row align-items-center gap-3" style="height: 30px;">
								<span class="fw-bold" style="flex-basis: 60px; font-size: 0.9em;">Host:</span>
								<span class="f-1-0">smtp.trustifi.com</span>
								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayHostToClipboard();"
								   tooltip="Copy Email Relay Host">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>

							<div class="flex-row align-items-center gap-3" style="height: 30px;">
								<span class="fw-bold" style="flex-basis: 60px; font-size: 0.9em;">Port:</span>
								<span class="f-1-0">25<sup style="margin-left: 5px; color: green;">(StartTLS)</sup></span>
								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayPortToClipboard();"
								   tooltip="Copy Email Relay Port">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>
							<div class="flex-row align-items-center gap-3">
								<span class="fw-bold" style="flex-basis: 55px; font-size: 0.9em;">Username:</span>

								<input class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
									   type="{{!showEmailRelayUsername ? 'password' : 'text'}}"
									   [ngStyle]="{'letter-spacing' : showEmailRelayUsername ? 'normal' : '2.4px'}"
									   [ngClass]="{'nofill': !showEmailRelayUsername}"
									   [(ngModel)]="planInfo.outbound_relay.key" disabled>

								<label class="hidepwd hvr-opacity" style="opacity: 0.4; margin-bottom: 0; font-size: 1.2rem; cursor: pointer;"
									   tooltip="{{showEmailRelayUsername ? 'Hide username' : 'Show username'}}">
									<input type="checkbox" [(ngModel)]="showEmailRelayUsername"/>
									<i class="fa fa-eye unchecked" ></i>
									<i class="fa fa-eye-slash checked"></i>
								</label>

								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayUsernameToClipboard();"
								   tooltip="Copy Email Relay Username">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>

							<div class="flex-row align-items-center gap-3">
								<span class="fw-bold" style="flex-basis: 55px; font-size: 0.9em;">Password:</span>

								<input class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
									   type="{{!showEmailRelayPassword ? 'password' : 'text'}}"
									   [ngStyle]="{'letter-spacing' : showEmailRelayPassword ? 'normal' : '2.4px'}"
									   [ngClass]="{'nofill': !showEmailRelayPassword}"
									   [(ngModel)]="planInfo.outbound_relay.secret" disabled>

								<label class="hidepwd hvr-opacity" style="opacity: 0.4; margin-bottom: 0; font-size: 1.2rem; cursor: pointer;"
									   tooltip="{{showEmailRelayPassword ? 'Hide password' : 'Show password'}}">
									<input type="checkbox" [(ngModel)]="showEmailRelayPassword"/>
									<i class="fa fa-eye unchecked" ></i>
									<i class="fa fa-eye-slash checked"></i>
								</label>

								<a style="font-size: 1rem;"
								   (click)="copyEmailRelayPasswordToClipboard();"
								   tooltip="Copy Email Relay Password">
									<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="list-group-item settings-list-item py-5" *ngIf="selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.journal">
				<div class="title-section-container flex-column gap-2">
					<span class="lh-base" style="font-size: 0.9em;">
						Configure the Journal Rule settings in <a class="blue-link" href="https://compliance.microsoft.com/exchangeinformationgovernance?viewid=exoJournalRule" target="_blank">Exchange</a> or Google Workspace to scan your journaled emails in Trustifi.
						<br/><br/>
						*To scan journaled emails from domains other than the primary domain ({{gs.getEmailDomain(userInfo.email)}}), verify the <span style="cursor: pointer; color: blue;" [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.plan, dic.CONSTANTS.inboundPlanSettingsPageTabs.domains]">domain</span> or connect to the Exchange/Google API integration.
					</span>
				</div>
				<div class="content-container flex-column gap-5">
					<div class="flex-row align-items-center gap-3">
						<span class="fw-bold" style="flex-basis: 55px; font-size: 0.9em;">Email:</span>

						<input class="f-1-0 border-0 p-0" style="font-size: 12px; background: inherit;"
							   type="{{!showJournalAddress ? 'password' : 'text'}}"
							   [ngStyle]="{'letter-spacing' : showJournalAddress ? 'normal' : '2.4px'}"
							   [ngClass]="{'nofill': !showJournalAddress}"
							   [(ngModel)]="planInfo.journalEmailAddress" disabled>

						<label class="hidepwd hvr-opacity" style="opacity: 0.4; margin-bottom: 0; font-size: 1.2rem; cursor: pointer;"
							   tooltip="{{showJournalAddress ? 'Hide' : 'Show'}}">
							<input type="checkbox" [(ngModel)]="showJournalAddress"/>
							<i class="fa fa-eye unchecked" ></i>
							<i class="fa fa-eye-slash checked"></i>
						</label>

						<a style="font-size: 1rem;"
						   (click)="clipboard.copy(planInfo.journalEmailAddress);" tooltip="Copy">
							<i class="fas fa-copy copy-link" style="cursor: pointer; color: #999999;"></i>
						</a>
					</div>
					<div class="flex-row gap-4">
						<div>
							<span style="font-weight: 500;"><u>Instructions:</u></span>
						</div>
						<a href="https://docs.trustifi.com/docs/configuration-in-office365-1" target="_blank" rel="noopener noreferrer nofollow"
						   style="text-decoration: unset;" class="flex-column align-items-center gap-2">
							<i class="fab fa-windows"
							   style="cursor: pointer; color: #00a1f1; font-size: 25px;">
							</i>
							<span style="font-size: 0.9em;">Microsoft 365</span>
						</a>
						<a href="https://docs.trustifi.com/docs/configuration-in-google-workspace" target="_blank" rel="noopener noreferrer nofollow"
						   style="text-decoration: unset;" class="flex-column align-items-center gap-2">
							<img src="images/integrationsLogos/gsuite-logo.png" width="25px" style="cursor: pointer;" alt="google logo">
							<span style="font-size: 0.9em;">Google Workspace</span>
						</a>
					</div>
				</div>
			</div>

			<!--IP set -->
			<div class="list-group-item settings-list-item" *ngIf="(selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.client || selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.trustifi) && planInfo?.outbound_relay?.static_ip_set_ips?.length">
				<div class="title-section-container flex-column gap-2">
					<b>IP Address Set (Region: {{planInfo.outbound_relay.static_ip_region || 'US default'}} | Skip Certificate Validation: {{!!planInfo.outbound_relay.static_ip_skip_unauthorized}})</b>
					<span class="lh-base" style="font-size: 0.9em;">IP address that will be used delivering emails back to your MTA.</span>
				</div>
				<div class="content-container flex-column gap-2">
					<span *ngFor="let ip of planInfo.outbound_relay.static_ip_set_ips">
						- {{ip}} <a style="font-size: 0.9rem;"
									(click)="copyItem(ip, 'IP');">
							<i class="copy-link fas fa-copy"
							   style="cursor: pointer;"></i>
						</a>
					</span>
				</div>
			</div>

			<!--Send welcome email to new users -->
			<div class="list-group-item settings-list-item" *ngIf="(selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.client || selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.trustifi) && planInfo?.outbound_relay?.enabled">
				<div class="title-section-container flex-column gap-2">
					<b>Send welcome email to new users</b>
					<span style="font-size: 0.9em;">
							Users who join your plan via the Email Relay will receive a "welcome" email and admins will be notified.
						</span>
				</div>
				<div class="content-container flex-column gap-2">
					<toggle-switch-c id="sendWelcomeEmailToggle"
									 [(model)]="planInfo.outbound_relay.send_welcome_email"
									 (onChange)="updateErSettings()"></toggle-switch-c>
				</div>
			</div>

			<!--Domains/addresses ignore list -->
			<div class="list-group-item settings-list-item" *ngIf="selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.client || selectedArchitecture.key === dic.CONSTANTS.sendingArchitecture.trustifi">
				<div class="title-section-container flex-column gap-2">
					<b>Domains/addresses ignore list (optional)</b>
					<span style="font-size: 0.9em;">All emails sent to those domains and/or addresses will be <b>ignored</b> by our Email Relay.</span>
					<span style="font-size: 0.9em;">This feature should generally be used only for <b>on-prem</b> configurations. Please consult our support before using this feature.</span>
				</div>
				<div class="content-container flex-column gap-2">
					<div class="flex-row hSpace05">
						<input type="text" style="width: 80%; max-width: 300px; margin-bottom: unset;"
							   placeholder="Add domain and/or address here"
							   [(ngModel)]="ignoreDomain">
						<btn-c style="min-width: 0;" endIcon="zmdi zmdi-check"
							   [isDisabled]="!ignoreDomain || updateIgnoreDomainInProcess"
							   (action)="updateIgnoreDomains('add');">
						</btn-c>
					</div>
					<div class="flex-column gap-1" style="max-width: 400px;" *ngIf="planInfo">
						<div *ngFor="let domain of planInfo.ignore_send_domains; let $index = index;"
							 style="">
							<div class="name ellipsis"
								 style="width: 95%; display: inline-block; max-width: 800px;">{{domain}}</div>
							<a class="closeRed action"
							   (click)="updateIgnoreDomains('remove', $index)"
							   [disabled]="updateIgnoreDomainInProcess"
							   [ngClass]="updateIgnoreDomainInProcess ? 'closeRed disableAction' : 'approveGreen'">
								<i class="fa fa-times"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>


<popup-c *ngIf="changeArchPopup?.show"
		 head="Change Sending Architecture" size="dynamic"
		 subTitle="You are about to change your Email Relay sending architecture"
		 (closeCb)="changeArchPopup = null;"
		 (confirmCb)="updateSendingArchitectureExecute()"
		 [buttons]="{confirm: 'Confirm'}">
	<div class="flxClmn f-1-0" style="overflow: auto;">

		<div class="flex-column vSpace1">

			<ul>
				<li><span>Emails will now be sent from your mail server to Trustifi's MTA (all email protections will be applied here), <b>and back into your MTA</b> from which it will be sent to your recipients</span></li>
				<li><span>To avoid issues with email delivery, make sure your mail-flow connectors and rules are created according to the instructions <b><a style="font-weight: bold;" href="https://docs.trustifi.com/docs/configuration-using-your-mta" target="_blank">here</a></b></span></li>
			</ul>
			<hr/>

			<span>Add the domain, MTA and port which will be used to send your secure emails:</span>
			<div class="flxRow gap-1" style="align-items: center;">
				<span style="min-width: 70px;"><b>Domain:</b></span>
				<input style="min-width: 250px;" type="text" [(ngModel)]="changeArchPopup.domain" placeholder="Domain">
			</div>

			<div class="flxRow gap-1" style="align-items: center;">
				<span style="min-width: 70px;"><b>MTA:</b></span>
				<input style="min-width: 250px;" type="text" [(ngModel)]="changeArchPopup.mta" placeholder="MTA">
			</div>

			<div class="flxRow gap-1" style="align-items: center;">
				<span style="min-width: 70px;"><b>Port:</b></span>
				<input type="number" min="1" max="65535" [(ngModel)]="changeArchPopup.port" placeholder="Port">
			</div>

		</div>
	</div>
</popup-c>
