import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DICTIONARY} from "../../dictionary";
import _ from "lodash";
import {RouteService} from "../../services/routeService";
import {GeneralService} from "../../services/generalService";

@Component({
	selector: 'onboarding-questionnaire-component',
	templateUrl: './onboarding-questionnaire.component.html',
})
export class OnboardingQuestionnaireComponent implements OnInit{

	@Input() userOnboardingInfo;
	@Output() doneCb = new EventEmitter<any>;
	@Output() cancelCb = new EventEmitter<any>;

	dic = DICTIONARY;
	wasSubmitted = false;
	questionnaireSubmitInProcess;
	organizationName;
	domainsNum;
	integratedMailboxesMode;
	questionnaireError;
	numOfEmployees;
	mailboxesNum;
	onPermisesVersion;
	otherEnvDesc;
	DNSprovider;
	flowRules;
	flowRulesDesc;
	flowConnectors;
	flowConnectorsDesc;
	integratedMailboxesNum;
	thirdPartyExist;
	thirdPartysDesc;
	autoReportSys;
	isFormFilledSuccessfully;
	canStartWizard;
	isPoc;
	integratedMailboxesNumUltimately;
	otherNotes;
	accountManager;
	currentEnv;
	questionnaireStatusAfterSubmit;
	// questionnaire default values:
	integrationScope = {outbound: false, inbound: false, archiving: false, threat_simulation: false};

	//


	constructor(
		public gs:GeneralService,
		private rs:RouteService) {
	}

	ngOnInit() {
		this.prepareQuestionnaireData();
	}

	prepareQuestionnaireData = () => {
		if (this.userOnboardingInfo.questionnaire) {
			// import previous form values
			this.organizationName = this.userOnboardingInfo.questionnaire.organization.company;
			this.numOfEmployees = this.userOnboardingInfo.questionnaire.organization.number_employees;

			// parse scopes
			this.integrationScope.outbound = this.userOnboardingInfo.questionnaire.organization.scope.includes('outbound');
			this.integrationScope.inbound = this.userOnboardingInfo.questionnaire.organization.scope.includes('inbound');
			this.integrationScope.archiving = this.userOnboardingInfo.questionnaire.organization.scope.includes('archiving');
			this.integrationScope.threat_simulation = this.userOnboardingInfo.questionnaire.organization.scope.includes('threat simulation');

			// if user primarily chose 'other' then the value of currentEnv is the free description he gave
			this.currentEnv = _.find<any>(this.dic.CONSTANTS.onboardingFormValues.currentEnv , env => {return env === this.userOnboardingInfo.questionnaire.setup.environment}) ? this.userOnboardingInfo.questionnaire.setup.environment : this.dic.CONSTANTS.onboardingFormValues.currentEnv.other;
			this.otherEnvDesc = _.find<any>(this.dic.CONSTANTS.onboardingFormValues.currentEnv , env => {return env === this.userOnboardingInfo.questionnaire.setup.environment}) ? '' : this.userOnboardingInfo.questionnaire.setup.environment;
			this.onPermisesVersion = this.userOnboardingInfo.questionnaire.onperm_version;
			this.DNSprovider = this.userOnboardingInfo.questionnaire.setup.dns_provider;
			this.domainsNum = this.userOnboardingInfo.questionnaire.setup.domains_in_email_server;
			this.mailboxesNum = this.userOnboardingInfo.questionnaire.setup.active_mailboxes;
			this.integratedMailboxesNum = this.userOnboardingInfo.questionnaire.setup.number_mailboxes_email_relay;
			this.integratedMailboxesNumUltimately = this.userOnboardingInfo.questionnaire.setup.number_mailboxes_ultimately;
			this.integratedMailboxesMode = this.integratedMailboxesNum === this.mailboxesNum ? this.dic.CONSTANTS.onboardingFormValues.integratedMailboxes.all : this.dic.CONSTANTS.onboardingFormValues.integratedMailboxes.specific;
			this.isPoc = this.userOnboardingInfo.questionnaire.setup.is_poc;
			this.flowConnectors = this.userOnboardingInfo.questionnaire.setup.mail_flow_connectors_enabled;
			this.flowConnectorsDesc = this.userOnboardingInfo.questionnaire.setup.mail_flow_connectors_desc;
			this.flowRules = this.userOnboardingInfo.questionnaire.setup.mail_flow_rules_enabled;
			this.flowRulesDesc = this.userOnboardingInfo.questionnaire.setup.mail_flow_rules_desc;
			this.thirdPartyExist = this.userOnboardingInfo.questionnaire.setup.third_party_integrations_enabled;
			this.thirdPartysDesc = this.userOnboardingInfo.questionnaire.setup.third_party_integrations_desc;
			this.autoReportSys = this.userOnboardingInfo.questionnaire.setup.auto_reporting_system_enabled;
			this.otherNotes = this.userOnboardingInfo.questionnaire.notes;
			this.accountManager = _.find<any>(this.dic.CONSTANTS.onboardingFormValues.accountManagers, manager => {return manager.email === this.userOnboardingInfo.questionnaire.setup.account_manager});
		}
	}

	submitQuestionnaire = () => {

		if (!this.organizationName || !this.numOfEmployees || !this.integrationScope || !this.currentEnv ||
			(this.currentEnv === this.dic.CONSTANTS.onboardingFormValues.currentEnv.onPermises && !this.onPermisesVersion) ||
			(this.currentEnv === this.dic.CONSTANTS.onboardingFormValues.currentEnv.other && !this.otherEnvDesc) ||
			!this.domainsNum || !this.mailboxesNum || !this.DNSprovider || !this.integratedMailboxesMode ||
			!this.integratedMailboxesMode ||
			(this.integratedMailboxesMode === this.dic.CONSTANTS.onboardingFormValues.integratedMailboxes.specific && !this.integratedMailboxesNum) ||
			this.isNullUndefined(this.flowConnectors) || (this.flowConnectors && !this.flowConnectorsDesc) ||
			this.isNullUndefined(this.flowRules) || (this.flowRules && !this.flowRulesDesc) ||
			this.isNullUndefined(this.thirdPartyExist) || (this.thirdPartyExist && !this.thirdPartysDesc) ||
			this.isNullUndefined(this.autoReportSys)) {
			this.questionnaireError = this.dic.ERRORS.fillRequiredInForm;
			return;
		}

		if (!this.integrationScope.outbound && !this.integrationScope.inbound && !this.integrationScope.archiving && !this.integrationScope.threat_simulation) {
			this.questionnaireError = "At least one option has to be selected for the integration scope";
			return;
		}

		const data = {
			action: 'questionnaireInfo',
			organization: {
				company: this.organizationName,
				number_employees: this.numOfEmployees,
				scope : [
					this.integrationScope.outbound && 'outbound',
					this.integrationScope.inbound && 'inbound',
					this.integrationScope.archiving && 'archiving',
					this.integrationScope.threat_simulation && 'threat simulation'
				].filter(Boolean).join(' + ')
			},

			setup: {
				environment: this.currentEnv !== this.dic.CONSTANTS.onboardingFormValues.currentEnv.other ? this.currentEnv : this.otherEnvDesc,
				onperm_version: this.onPermisesVersion,
				dns_provider: this.DNSprovider,
				domains_in_email_server: this.domainsNum,
				active_mailboxes: this.mailboxesNum,

				is_poc: this.isPoc,
				number_mailboxes_ultimately: this.integratedMailboxesMode === this.dic.CONSTANTS.onboardingFormValues.integratedMailboxes.all ? this.mailboxesNum : this.integratedMailboxesNumUltimately,
				number_mailboxes_email_relay: this.integratedMailboxesMode === this.dic.CONSTANTS.onboardingFormValues.integratedMailboxes.all ? this.mailboxesNum : this.integratedMailboxesNum,

				mail_flow_connectors_enabled: this.flowConnectors,
				mail_flow_connectors_desc: this.flowConnectorsDesc,

				mail_flow_rules_enabled: this.flowRules,
				mail_flow_rules_desc: this.flowRulesDesc,

				third_party_integrations_enabled: this.thirdPartyExist,
				third_party_integrations_desc: this.thirdPartysDesc,

				auto_reporting_system_enabled: this.autoReportSys,

				notes: this.otherNotes,
				account_manager: this.accountManager && this.accountManager.email
			}
		}

		this.questionnaireSubmitInProcess = true;

		this.rs.updateOnboardingQuestionnaire(data).then(response => {
			this.isFormFilledSuccessfully = true;
			this.canStartWizard = data.setup.number_mailboxes_ultimately < 10;
			this.gs.onboardingQuestionnaireStatusSubj.next(response.onboarding_process);
			this.questionnaireStatusAfterSubmit = response.onboarding_process;
			this.questionnaireSubmitInProcess = false;
		},(err) => {
			this.questionnaireError = err.message;
			this.questionnaireSubmitInProcess = false;
			return;
		});
	}

	isNullUndefined = (variable) => {
		return variable === null || variable === undefined;
	}
}

