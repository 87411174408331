import _ from 'lodash';
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {GeneralService} from "../../services/generalService";
import {DICTIONARY} from "../../dictionary";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {myEmailsService} from "../../services/myEmailsService";
import util from "util";
import {ActivatedRoute} from "@angular/router";


@Component({
	selector: 'sent-emails-component',
	templateUrl: './sent-emails.component.html',
})
export class SentEmailsComponent implements OnInit, OnDestroy {
	constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService,
				private activatedRoute:ActivatedRoute,
				public myEmailsService:myEmailsService) {
	}

	dic = DICTIONARY;

	isLoaded = false;
	_ = _;
	userInfo;
	getEmailsInProcess = false;
	showAbortLoadingEmailsLabel: boolean;
	getAllEmailsInPlan = false;
	abortLoadingFlag = false;
	activeEmailTab = this.dic.CONSTANTS.mailBoxTabs.details;
	selectedFilter;
	searchSentEmailTxt;
	summernoteOptions = {
		...this.gs.summernoteOptions,
		height: 250
	};

	searchPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.range
	];
	emailQueryDisplay;
	queryType;
	queryTypes = {
		normal: 'normal',
		messageIdOnly: 'messageIdOnly'
	};
	resultsPeriod = this.dic.CONSTANTS.trendsPeriod.lastDay;
	range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()}; // (past one day)
	isQueryError;
	showQueryModal;
	emailQuery;
	queryValidationErrors = [];

	bulkEmailActionInProcess;
	selectedEmails;
	isSelectedAll;
	visibleCampaigns = []; // array of titles of expanded campaign records
	previewEmailInProcess;
	downloadEml;

	ngOnInit() {
		this.myEmailsService.currentEmailsList = null;
		this.myEmailsService.currentFilteredEmailsList = null;
		this.myEmailsService.currentMessage = null;

		this.initEmailQuery();

		this.gs.getUserInfo(false, (userInfo) => {
			this.userInfo = userInfo;
		});

		const urlQuery = this.activatedRoute.snapshot.paramMap;
		const ids = window.history.state.ids || window.history.state.data?.ids || urlQuery.get('ids');

		if (ids) {
			this.getEmailsByIds(ids);
			return;
		}
	};

	ngOnDestroy(): void {
		this.abortLoadingFlag = true;
	}

	initEmailQuery = () => {
		this.queryType = this.queryTypes.normal;
		this.resultsPeriod = this.dic.CONSTANTS.trendsPeriod.lastDay;
		this.range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()}; // (past one day)

		this.emailQuery = {
			subject: '',
			message_id: '',
		};

		this.emailQueryDisplay = [
			{
				title: this.gs.toCapitalize(this.resultsPeriod.display),
				tooltip: this.gs.toCapitalize(this.resultsPeriod.display)
			}
		];
	}

	validateQuery = () => {
		this.queryValidationErrors = [];

		// validation for 'message ID only' query
		if (this.queryType === this.queryTypes.messageIdOnly) {
			if (!this.emailQuery.message_id) {
				this.queryValidationErrors.push('message_id');
				return false;
			}
			else {
				return true;
			}
		}
		//

		// period (range) check
		if (this.gs.equals(this.resultsPeriod, this.dic.CONSTANTS.trendsPeriod.range)) {
			const range:any = {
				start: this.range.start,
				end: this.range.end
			};
			if (range.start > Date.now() || range.start > range.end) {
				this.ns.showWarnMessage(this.dic.ERRORS.adminQuarantinedDate);
				return  false;
			}
		}

		return true;
	}

	changePeriod = (period) => {
		this.resultsPeriod = period;
	};

	applyQuery = () => {
		if (this.getEmailsInProcess || !this.validateQuery()) {
			return;
		}
		this.getEmailsInProcess = true;
		this.abortLoadingFlag = false;
		this.selectedFilter = this.dic.CONSTANTS.mailBoxFilters.All;

		this.calculateQueryDisplay();

		this.showQueryModal = false;
		this.isQueryError = false;

		this.myEmailsService.currentEmailsList = [];
		this.myEmailsService.currentFilteredEmailsList = [];
		this.selectedEmails = [];
		this.isSelectedAll = false;

		this.myEmailsService.currentMessage = null;
		this.showAbortLoadingEmailsLabel = true;
		this.getSentEmailsBatch();
	}

	// there is no "emailsInfo" in first batch, but only from the second
	getSentEmailsBatch = (emailsInfo=null) => {
		if (this.abortLoadingFlag) {
			this.getEmailsInProcess = false;
			return;
		}

		let params:any = this.getQueryForEmail();
		// first batch is 20 so that it will be quick
		params.limit = emailsInfo ? this.dic.CONSTANTS.emailFetchSize : 20;
		params.lastId = emailsInfo?.lastId;

		this.gs.cleanEmptyParams(params);

		this.rs.getAllEmails(params).then((response) => {
			this.isLoaded = true;

			const emails = (this.myEmailsService.currentEmailsList || []).concat((response.emails || []));

			this.myEmailsService.currentEmailsList = _.orderBy(emails, ['created'], ['desc']);
			this.parseEmailsDisplayData();
			this.searchEmailInResults(this.searchSentEmailTxt);

			if (response.lastId) {
				this.getSentEmailsBatch(response);
			}
			else {
				this.getEmailsInProcess = false;
				this.showAbortLoadingEmailsLabel = false;
			}
		}, err => {
			this.getEmailsInProcess = false;
			this.showAbortLoadingEmailsLabel = false;
		});
	}

	getQueryForEmail = () => {
		const queryObj: any = {};
		if (this.queryType ===  this.queryTypes.messageIdOnly) {
			queryObj.message_id = this.emailQuery.message_id;
		}
		else {
			queryObj.size = this.dic.CONSTANTS.quarantinedEmailsFirstBatchSize;
			queryObj.period = this.resultsPeriod.value;
			if (this.resultsPeriod.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
				queryObj.range = this.range;
			}
			if (this.emailQuery.subject) {
				queryObj.subject = this.emailQuery.subject;
			}
			if (this.emailQuery.recipient) {
				queryObj.recipient = this.emailQuery.recipient;
			}
		}

		if (this.getAllEmailsInPlan) {
			queryObj.allUsers = true;
		}

		return queryObj;
	}

	abortLoadingEmails = () => {
		this.abortLoadingFlag = true;
		this.getEmailsInProcess = false;
		this.showAbortLoadingEmailsLabel = false;
	}

	// determine how query properties look in the UI, in the query box
	calculateQueryDisplay = () => {
		// when query is through message ID only
		if (this.queryType ===  this.queryTypes.messageIdOnly) {
			this.emailQueryDisplay = [
				{
					title: 'message ID',
					tooltip: this.emailQuery.message_id
				}
			];
		}
		// when normal query
		else {
			this.emailQueryDisplay = [];

			// calculate period title and tooltip
			let periodTooltip = this.gs.toCapitalize(this.resultsPeriod.display);
			if (this.gs.equals(this.resultsPeriod, this.dic.CONSTANTS.trendsPeriod.range)) {
				periodTooltip = 'From ' + this.range.start + ' until ' + this.range.end;
			}
			this.emailQueryDisplay.push(
				{
					title: this.gs.toCapitalize(this.resultsPeriod.display),
					tooltip: periodTooltip
				}
			);
			//

			_.mapValues<any>(this.emailQuery,(value, queryKey) => {
				if (value) {
					let title = queryKey;
					let tooltip = value;

					if (queryKey === 'message_id') { // not relevant if not 'message-id-only' query type
						return;
					}

					this.emailQueryDisplay.push({title: title, tooltip: tooltip});
				}
			});
		}
	}

	getEmailsByIds = (emailIds) => {
		const params = {ids: emailIds};
		this.getEmailsInProcess = true;

		this.rs.getAllEmails(params).then((response) => {
			this.isLoaded = true;

			const emails = (this.myEmailsService.currentEmailsList || []).concat((response.emails || []));

			this.myEmailsService.currentEmailsList = _.orderBy(emails, ['created'], ['desc']);
			this.parseEmailsDisplayData();
			this.showAbortLoadingEmailsLabel = true;
			this.searchEmailInResults(this.searchSentEmailTxt);

			if (response.lastId) {
				this.getSentEmailsBatch(response);
			}
			else {
				this.getEmailsInProcess = false;
			}
		}, err => {
			this.getEmailsInProcess = false;
		});
	}

	getSentEmailInfo = (email) => {
		/*if (this.myEmailsService.currentMessage?._id === email._id) {
			return;
		}*/

		if (this.myEmailsService.currentMessage) {
			this.myEmailsService.currentMessage.show = false;
		}
		email.show = true;

		this.myEmailsService.currentMessage = email;

		//reset map details
		this.myEmailsService.currentMessage.openSummaryReady = false;

		this.activeEmailTab = this.dic.CONSTANTS.mailBoxTabs.details;

		// this.fixBccDisplay();

		this.enforcePolicy(this.userInfo.plan.policy);
		this.decryptMsg();
	};

	enforcePolicy = (policy) => {
		if (!policy) return;
		if (policy.delete_attachment_enable && policy.delete_attachment_enable.strict) {
			this.myEmailsService.currentMessage.advanced.secure.delete_attachment_enable = policy.delete_attachment_enable.value;
		}
		if (policy.expired_enable && policy.expired_enable.strict) {
			this.myEmailsService.currentMessage.advanced.secure.expired_enable = policy.expired_enable.value;
		}
	};

	decryptMsg = () => {
		if (!this.myEmailsService.currentMessage || _.isEmpty(this.myEmailsService.currentMessage)) {
			return;
		}

		if (this.myEmailsService.currentMessage.methods && this.myEmailsService.currentMessage.methods.secure_mass) {
			this.myEmailsService.currentMessage.decrypted = true;
			return;
		}

		if (!this.myEmailsService.currentMessage.sent.html && !this.myEmailsService.currentMessage.decrypted) {
			const messageObj = this.myEmailsService.currentMessage;
			this.rs.decryptEmailContent(messageObj._id, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then((response) => {
				messageObj.sent.html = response;
				setTimeout( () => {
					messageObj.decrypted = true;
				}, 500, true);
			}, () => {
				messageObj.decrypted = true;
			});
		}
		else {
			this.myEmailsService.currentMessage.decrypted = true;
		}
	};

	clearSearchEmail = () => {
		this.myEmailsService.currentEmailsList.forEach(email => {
			email.hide = false;
		});
		// this.searchInboxEmailTxt = null;
		this.myEmailsService.currentFilteredEmailsList = _.reject(this.myEmailsService.currentEmailsList, 'hide');
		this.checkSelectAllEmails();
	};

	selectFilter = (selectedNewFilter) => {
		if (selectedNewFilter === this.dic.CONSTANTS.mailBoxFilters.Campaign) {
			this.createCampaignGroups();
		}
		else {
			// remove all inserted campaign title records (if exist)
			_.remove(this.myEmailsService.currentEmailsList, 'isCampaignTitle');
			this.myEmailsService.currentEmailsList = _.orderBy(this.myEmailsService.currentEmailsList, ['created'], ['desc']);
		}

		this.selectedFilter = selectedNewFilter;
		this.searchEmailInResults(this.searchSentEmailTxt);
	}

	createCampaignGroups = () => {
		// remove previous campaign title records if there are
		_.remove(this.myEmailsService.currentEmailsList, 'isCampaignTitle');

		// create an array that contains only mass campaign emails
		const massCampaignsEmails = _.filter(this.myEmailsService.currentEmailsList, 'methods.secure_mass');

		// get all campaign titles and the corresponding email arrays
		const campaignTitleGroups = _.groupBy(massCampaignsEmails, 'sent.title'); // (this is an object of {campaignTitle: array of emails with same title}[]);

		// add one record to the main list for each title, with the data of the latest email of the campaign, a special flag and total counters
		Object.keys(campaignTitleGroups).forEach(campaignTitle => {
			if (campaignTitleGroups[campaignTitle][0]) {
				this.myEmailsService.currentEmailsList.push({
					...campaignTitleGroups[campaignTitle][0],
					isCampaignTitle: true,
					sensitivity: null,
					mass_lists: _.uniqBy(_.flatten(_.map(campaignTitleGroups[campaignTitle], 'mass_lists')), '_id'),
					emailIds: _.map(campaignTitleGroups[campaignTitle], '_id'),
					total_emails_count: campaignTitleGroups[campaignTitle].length,
					total_recipients_count: _.sumBy(campaignTitleGroups[campaignTitle], 'recipients_count')
				});
			}
		});

		// show the special campaign title record on top of its emails records
		this.myEmailsService.currentEmailsList = _.orderBy(this.myEmailsService.currentEmailsList, ['sent.title', 'isCampaignTitle', 'created'], ['desc', 'asc', 'desc']);
	};

	showOrHideCampaignItems = (campaignTitleObj) => {
		if (this.visibleCampaigns.includes(campaignTitleObj.sent.title)) {
			_.remove(this.visibleCampaigns, campaignTitle => campaignTitle === campaignTitleObj.sent.title);
			campaignTitleObj.isExpanded = false;
		}
		else {
			this.visibleCampaigns.push(campaignTitleObj.sent.title);
			campaignTitleObj.isExpanded = true;
		}

		this.searchEmailInResults(this.searchSentEmailTxt);
	}

	searchEmailInResults = (searchTerm) => {
		if (!searchTerm && this.selectedFilter === this.dic.CONSTANTS.mailBoxFilters.All) {
			this.clearSearchEmail();
			return;
		}

		this.myEmailsService.currentEmailsList.forEach(email => {
			// search
			const isFound = !searchTerm || searchTextExecute(email, searchTerm);
			email.hide = !isFound;

			// filter
			if (!email.hide) {
				switch (this.selectedFilter) {
					case this.dic.CONSTANTS.mailBoxFilters.All:
						return;

					case this.dic.CONSTANTS.mailBoxFilters.Sent:
						return email.hide = !email.start_sending_time;

					case this.dic.CONSTANTS.mailBoxFilters.Campaign:
						return email.hide = !email.isCampaignTitle && !this.visibleCampaigns.includes(email.sent.title);

					case this.dic.CONSTANTS.mailBoxFilters.Draft:
						return email.hide = !email.is_draft;

					case this.dic.CONSTANTS.mailBoxFilters.Scheduled:
						return email.hide = email.start_sending_time || !email.scheduled_time;
				}
			}
		});


		this.myEmailsService.currentFilteredEmailsList = _.reject(this.myEmailsService.currentEmailsList, 'hide');
		this.checkSelectAllEmails();
	};

	toggleAllEmails = () => {
		this.myEmailsService.currentFilteredEmailsList.forEach(email => {
			email.selected = this.isSelectedAll;
		});
		this.filterSelectedEmails();
	};


	filterSelectedEmails = () => {
		this.selectedEmails = _.filter(this.myEmailsService.currentFilteredEmailsList, 'selected');
	};

	checkSelectAllEmails = () => {
		this.filterSelectedEmails();
		this.isSelectedAll = this.selectedEmails.length === _.reject(this.myEmailsService.currentFilteredEmailsList, 'isCampaignTitleRecord');
	};

	parseEmailsDisplayData = (specificMessage=null) => {
		if (specificMessage) {
			this.getMessageRecipientsNamesStr(specificMessage);
			this.fixBccDisplay(specificMessage);
			this.getRecipientsToCcBccStr(specificMessage);
			specificMessage.numberRecipientsBlocked = this.getNumOfBlockedRecipients(specificMessage);
			this.isEmailExpired(specificMessage);
		}
		else {
			this.myEmailsService.currentEmailsList.forEach(message => {
				this.getMessageRecipientsNamesStr(message);
				this.fixBccDisplay(message);
				this.getRecipientsToCcBccStr(message);
				message.numberRecipientsBlocked = this.getNumOfBlockedRecipients(message);
				this.isEmailExpired(message);
			});
		}
	}

	getMessageRecipientsNamesStr = (message) => {
		if (message.recipientsNamesStr) {
			return;
		}

		let namesStr = '';

		if (message.recipientsIDs?.length) {
			namesStr = (_.map(message.recipientsIDs, 'name')).join(", ");
			if (namesStr.length > 65){
				namesStr = namesStr.slice(0,65);
				namesStr = namesStr + "..." + " (" + message.recipients_count +")";
			}
		}
		message.recipientsNamesStr = namesStr;
	}

	getRecipientsToCcBccStr = (message) => {
		['To','Cc','Bcc'].forEach(field => {
			const fieldStr = 'recipients' + field + 'Str'; // for generic property name (the output string)
			const fieldStrFull = 'recipients' + field + 'StrFull'; // for generic property name (the FULL output string)

			if (message[fieldStrFull]) {
				return message[fieldStrFull].length > 350;
			}

			let recipientsStr = '';
			let recipientsStrFull = '';

			if (message.recipients_display_only[field.toLowerCase()]?.length) {
				message.recipients_display_only[field.toLowerCase()].forEach(recipient => {
					if (recipient.name && recipient.address && recipient.name !== recipient.address) {
						recipientsStrFull += `${recipient.name} <${recipient.address}>, `;
					}
					else if (recipient.name || recipient.address || recipient.name === recipient.address) {
						recipientsStrFull += `${recipient.name || recipient.address}, `;
					}
				});
			}

			recipientsStrFull = recipientsStrFull.length ? recipientsStrFull.substring(0, recipientsStrFull.length - 2) : ''; // cut last comma and space
			if (recipientsStrFull.length > 350) {
				recipientsStr = recipientsStrFull.substring(0, 350);
				recipientsStr = recipientsStr.substring(0, recipientsStr.lastIndexOf(',')) + '...';

				message[fieldStr] = recipientsStr;
			}
			else {
				message[fieldStr] = recipientsStrFull;
			}

			message[fieldStrFull] = recipientsStrFull;
		});
	}

	getNumOfBlockedRecipients = (emailObj) => {
		let blockCount = 0;
		if (emailObj.recipientsIDs && emailObj.recipientsIDs.length) {
			for (let i = 0; i < emailObj.recipientsIDs.length; i++) {
				if (emailObj.recipientsIDs[i].is_blocked) {
					blockCount++;
				}
			}
		}

		return blockCount;
	};

	isEmailExpired = (emailObj) => {
		if (!emailObj.advanced) return;
		if ((!emailObj.advanced.secure || !emailObj.advanced.secure.expired_enable) || (!emailObj.methods.encrypt_content && !emailObj.methods.secure_send && !emailObj.sent.attachments && !emailObj.sent.attachments.length)) {
			emailObj.isExpired = false;
			return;
		}
		let expiredDate = new Date(emailObj.created);
		expiredDate.setDate(expiredDate.getDate() + emailObj.advanced.secure.expired_days);
		let now = new Date();
		emailObj.isExpired = now > expiredDate;
	};

	fixBccDisplay = (message) => {
		message.recipients_display_only.bcc = [];

		message.recipientsIDs?.forEach((recipientObj) => {
			if ((!_.find<any>(message.recipients_display_only.to, displayObj => displayObj.address && displayObj.address.toLowerCase() === recipientObj.email)) &&
				(!_.find<any>(message.recipients_display_only.cc, displayObj => displayObj.address && displayObj.address.toLowerCase() === recipientObj.email))) {
				message.recipients_display_only.bcc.push({address: recipientObj.email, name: recipientObj.name});
			}
		});
	}

	getAndToggleReplies = (message) => {
		if (message.replies || message.isExpanded) {
			message.isExpanded = !message.isExpanded;
			return;
		}

		if (!message.replies) {
			message.getRepliesInProcess = true;
			this.rs.getEmailFullReplies(message._id).then(allReplies => {
				this.orderRepliesOfMessage(message, allReplies);
				message.getRepliesInProcess = false;
				message.isExpanded = !message.isExpanded;
			}, err => {
				message.getRepliesInProcess = false;
			});
		}
	};

	deleteMessages = (messagesToDelete) => {
		if (!messagesToDelete?.length) {
			return;
		}

		const body = [
			`You will lose control over the message${messagesToDelete.length === 1 ? '' : 's'}.`,
			`Access to ${messagesToDelete.length === 1 ? 'this' : 'these'} email${messagesToDelete.length === 1 ? '' : 's'} will be blocked.`
		];

		// Delete scheduled message(s) specifications
		const scheduledMessagesToDelete = _.filter(messagesToDelete, message => !message.start_sending_time && message.scheduled_time);
		if (scheduledMessagesToDelete.length) {
			//// If all messages to delete are scheduled message, then turn to another popup
			if (scheduledMessagesToDelete.length === messagesToDelete.length) {
				this.openCancelScheduledTimePopup(messagesToDelete);
				return;
			}
			else {
				body.push('<b>Pay attention:</b> One or more of the emails you chose to delete is a scheduled email. Scheduled sending time for this email will be canceled and <b>the email will not be sent</b>.')
			}
		}

		// Delete campaign message(s) specifications
		const MassCampaignMessagesToDelete = _.filter(messagesToDelete, message => !!message.methods?.secure_mass);
		if (MassCampaignMessagesToDelete.length) {
			if (MassCampaignMessagesToDelete.length === messagesToDelete.length) {
				body.push('<b>Pay attention:</b> The Emails you chose to delete are campaign emails. <b>We recommend using the "Delete Campaign Records" options for campaign emails</b>.')
			}
			else {
				body.push('<b>Pay attention:</b> One or more of the emails you chose to delete is a campaign email. <b>We recommend using the "Delete Campaign Records" options for campaign emails</b>.')
			}
		}

		this.gs.showPopup({
			title: `Delete Email${messagesToDelete.length === 1 ? '' : 's'}`,
			subTitle: `Please note - ${messagesToDelete.length === 1 ? '' : messagesToDelete.length + ' '}selected email${messagesToDelete.length === 1 ? '' : 's'} will be deleted.`,
			body: body,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				this.deleteMessagesExecute(messagesToDelete);
			}
		});
	};


	deleteMessagesExecute = (messagesToDelete, deletedEmailCount = 0) => {
		if (!messagesToDelete?.length) {
			return;
		}
		this.bulkEmailActionInProcess = true;

		// Divide the messages-to-delete call into pieces
		let EmailsToDeleteBatch = messagesToDelete;
		EmailsToDeleteBatch = messagesToDelete.slice(0, this.dic.CONSTANTS.deleteIdsMax);
		messagesToDelete = messagesToDelete.slice(this.dic.CONSTANTS.deleteIdsMax);

		const emailsIds =  _.map(EmailsToDeleteBatch, '_id').join(',');

		this.rs.deleteMessage(emailsIds, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then((response) => {
			this.myEmailsService.currentEmailsList = _.difference(this.myEmailsService.currentEmailsList, EmailsToDeleteBatch);
			this.myEmailsService.currentFilteredEmailsList = _.reject(this.myEmailsService.currentEmailsList, 'hide');
			this.checkSelectAllEmails();

			if (this.myEmailsService.currentMessage && emailsIds.includes(this.myEmailsService.currentMessage._id)) {
				this.myEmailsService.currentMessage = null;
			}

			if (this.selectedFilter === this.dic.CONSTANTS.mailBoxFilters.Campaign) {
				this.createCampaignGroups(); // calculate again total_recipients_count
				this.searchEmailInResults(this.searchSentEmailTxt);
			}

			deletedEmailCount += EmailsToDeleteBatch.length;

			if (messagesToDelete.length) {
				this.deleteMessagesExecute(messagesToDelete, deletedEmailCount);
				return;
			}
			else {
				if (deletedEmailCount > 1) {
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.emailDeleteRecoverMultiple, deletedEmailCount));
				}
				else {
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.emailDeleted, EmailsToDeleteBatch[0].sent.title));
				}

				this.bulkEmailActionInProcess = false;
			}

			/*if (this.deleteCurrent) {
				this.selectedAll = false;
				this.toggleAll();


				// delete from relevant group, in case of campaign
				if (this.myEmailsService.chosenFilter && this.myEmailsService.chosenFilter === this.dic.CONSTANTS.mailBoxFilters.Campaign) {
					this.campaignGroups = this.filterCampaignGroup(this.campaignGroups);
					this.filterCampaignGroups = this.filterCampaignGroup(this.filterCampaignGroups);
					// update selected message after delete
					const campaignKeys = Object.keys(this.filterCampaignGroups);
					if (campaignKeys && campaignKeys.length > 0) {
						const firstCampaignName = campaignKeys[0];
						this.myEmailsService.currentMessage =  this.filterCampaignGroups[firstCampaignName] || {};
						this.selectedCampaign = firstCampaignName;
					}
					else {
						this.myEmailsService.currentMessage =  {};
						this.selectedCampaign = null;
					}
				}
				else {
					let idx;
					for (idx = 0; idx < this.filteredMessages.length; idx++) {
						if (this.myEmailsService.currentMessage._id === this.filteredMessages[idx]._id) {
							if (this.filteredMessages[idx].has_replies && this.filteredMessages[idx].open){
								this.getAndToggleReplies(this.filteredMessages[idx], idx);
							}
							this.filteredMessages.splice(idx, 1);
							break;
						}
					}
					if (this.originalFilteredMessages) {
						for (let i = 0; i < this.originalFilteredMessages.length; i++) {
							if (this.myEmailsService.currentMessage._id === this.originalFilteredMessages[i]._id) {
								if (this.originalFilteredMessages[i].has_replies && this.originalFilteredMessages[i].open){
									this.getAndToggleReplies(this.originalFilteredMessages[i], i);
								}
								this.originalFilteredMessages.splice(i, 1);
								break;
							}
						}
					}

					if (idx === 0) {
						this.myEmailsService.currentMessage = this.filteredMessages[idx] || {};
					}
					else {
						this.myEmailsService.currentMessage = this.filteredMessages[idx - 1] || {};
					}
					this.decryptMsg();
				}
			}
			else {
				this.clearUiMessages();
			}
			this.deleteInProcess = false;
			this.updateTabAndFrame();*/
		});
	};

	openCancelScheduledTimePopup = (messagesToDelete) => {
		this.gs.showPopup({
			title: `Cancel Scheduled Time`,
			subTitle: `Please note - the scheduled time for ${messagesToDelete.length === 1 ? 'this email' : 'these emails'} will be canceled.`,
			body: [`Your email${messagesToDelete.length === 1 ? '' : 's'} will not be sent`, `Your email${messagesToDelete.length === 1 ? '' : 's'} will be deleted from your mailbox.`],
			type: this.dic.CONSTANTS.popupWarning,
			cancelBtnText: 'Cancel',
			doneBtnText: `Cancel scheduling`,
			doneCb: () => {
				this.deleteMessagesExecute(messagesToDelete);
			}
		});
	};

	selectAction = (emailObj, action) => {
		switch (action) {
			case this.dic.CONSTANTS.archiveEmailActions.download:
				this.downloadEml(emailObj);
				break;
		}
	};

	orderRepliesOfMessage = (message, allRepliesArr, currentDepth=0) => {
		message.replies = _.filter(allRepliesArr, {reply_to: message._id});

		if (currentDepth > 0) {
			message.depth = Math.min(currentDepth, 7);
			this.parseEmailsDisplayData(message); // display data for newly brought replies
		}
		currentDepth++;
		message.replies.forEach(reply => this.orderRepliesOfMessage(reply, allRepliesArr, currentDepth));
	}
}

function searchTextExecute(email, searchTerm) {
	searchTerm = searchTerm.toLowerCase();

	return email.sent.title.toLowerCase().indexOf(searchTerm) >= 0 ||
		findInRecipients(email.recipientsIDs, searchTerm);
}

function findInRecipients(recipients, searchTerm) {
	return recipients?.length && !!_.find<any>(recipients, (recipient) =>
		(recipient.email && recipient.email.toLowerCase().indexOf(searchTerm) >= 0) ||
		(recipient.name && recipient.name.toLowerCase().indexOf(searchTerm) >= 0) ||
		(recipient.phone && recipient.phone.toLowerCase().indexOf(searchTerm) >= 0)
	);
}
