<div class="flex-row gap-2 md-checkbox align-items-center">
    <input type="checkbox"
           onclick="return false;"
           [checked]="model"
           (change)="fireClick()"/>
    <label>
        <ng-content></ng-content>
    </label>
    <i style="font-weight: 400;" class="fas fa-question-circle"
       *ngIf="description" title="{{description}}"></i>
</div>
