<div class="flxClmn" style="flex: 1 0; overflow: auto;">
    <div class="flxRow-between table-upper-row-container" style="z-index: 100; padding: 0 4px 7px 0; align-items: center;  border-bottom: none;"
         *ngIf="(options.menu || options.exportToCsvFunction || options.refreshFunction || searchItemExecute.observed || !options.hideCounter) && !options.hideContentAboveTable">
        <!--buttons above table-->
        <div class="f-1-0">
            <ng-content select="[tableButtons]"></ng-content>
        </div>
        <!--table actions & filtering-->
        <div class="flxRow gap-3 table-actions-container" style="gap: 1rem; align-items: flex-end; align-self: flex-end;">
            <!--counter-->
            <span *ngIf="!options.hideCounter && counter" style="color: darkgrey; padding: 5px;">
                 {{counter}} record{{counter === 1 ? '' : 's'}}
            </span>
            <!--<span *ngIf="!options.hideCounter && !dataList.length" style="color: darkgrey; padding: 5px;">
                 0 records
            </span>-->
            <div class="flxRow hide-when-empty" style="align-items: center; border: solid 1px #dedede; position: relative; height: fit-content; min-height: 28px;">
                <!--search-->
                <div class="flxRow-center inner-search-input" style="flex: 1 0; min-width: 300px; padding: 5px 7px; transition: all 0.1s; position: relative; width: 37px; overflow: hidden; align-self: stretch;"
                     *ngIf="searchItemExecute.observed">
                    <div class="flxRow w100" style="position: absolute; left: 0; top: 0; height: 100%;">
                        <i class="fa fa-search" style="opacity: 0.5; font-size: 16px; position: absolute; left: 5px; top: 50%; transform: translateY(-50%);"></i>
                        <input type="search" style="padding: 0 30px; margin: 0; height: 100%; flex: 1 0; border: none;"
                               [(ngModel)]="search.text"
                               maxlength="{{dic.CONSTANTS.maxCharsInSearch}}"
                               placeholder="Find in results"
                               (keyup)="searchItem()">
                        <a class="closeRed animated fadeIn" style="font-size: 21px; color: #cccccc; position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
                           *ngIf="search.text"
                           (click)="search.text = ''; searchItem();">
                            <i class="zmdi zmdi-close" style="font-size: 15px;"></i>
                        </a>
                    </div>
                </div>
				<!--change table view-->
				<div class="h100" style="position: relative;" *ngIf="options.changeTableViewMenu">
					<a class="flxRow-center" style="align-items: center;  padding: 5px 7px; font-size: 16px;"
					   id="tableViewDropdownMenu"
					   tooltip="Change table view"
					   (ngInit)="showChangeTableViewMenu = false;"
					   (click)="showChangeTableViewMenu = !showChangeTableViewMenu;">
						<i class="fa fa-stream flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
					</a>
					<ul style="z-index: 1006; position: absolute; top: 36px; right: 0; background: white; box-shadow: 0 1px 4px grey; margin: 0; list-style: none; padding: 0; width: 180px;"
						*ngIf="showChangeTableViewMenu"
						(clickOutside)="showChangeTableViewMenu = false;" [exclude]="'#tableViewDropdownMenu'">
						<li style="height:35px; border:0; text-transform: capitalize;"
							*ngFor="let menuItem of options.changeTableViewMenu | keyvalue; trackBy: gs.trackByIndex"
							id="{{menuItem.key}}TableViewMenuButton"
							(click)="menuItem.value.action(menuItem.key); showChangeTableViewMenu = false;">
							<a class="flxRow hSpace1 h100 w100" style="align-items: center; padding: 0 15px;">
								<i [ngClass]="menuItem.value.iconClass" *ngIf="menuItem.value.iconClass"></i>
								<span>{{menuItem.value.label}}</span>
							</a>
						</li>
					</ul>
				</div>
                <!--filter-->
                <a style="align-items: center; font-size: 16px; padding: 5px 7px; position: relative;"
                   *ngIf="filterData"
                   tooltip="{{activeFilters ? 'Filter results - Active' : 'Filter results'}}"
                   (click)="toggleFilter()"
                   [ngStyle]="{'color': activeFilters ? 'var(--bs-primary)' : ''}">
                    <i class="fas fa-filter flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
                    <i class="fas fa-plus" style="position: absolute; bottom: 9px; right: 7px; font-size: 8px;"></i>
                    <i class="animated fadeIn fas fa-circle" style="position: absolute; top: 4px; right: 7px; font-size: 8px; filter: drop-shadow(-2px 1px 1px white); color: #00d100;"
                       *ngIf="activeFilters"></i>
                </a>
                <filter-table-c
                        style="position: absolute; bottom: -3px; transform: translateY(100%); right: 0;"
                        *ngIf="filterData"
                        [filterData]="filterData"
                        (applyCb)="applyFilters($event.activeFilters)"
                        (clearCb)="clearFilters()">
                </filter-table-c>
				<!--auto refresh-->
				<a *ngIf="options?.autoRefreshFunction"
				   style="align-items: center; font-size: 17px; padding: 5px 7px; position: relative;"
				   tooltip="Refresh data automatically every 60 seconds"
				   (click)="initAutoRefresh();"
				   [ngStyle]="{'color': autoRefresh ? 'var(--bs-primary)' : ''}">
					<i class="fa fa-history flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
					<i class="animated fadeIn fas fa-circle" style="position: absolute; top: 4px; right: 7px; font-size: 8px; filter: drop-shadow(-2px 1px 1px white); color: #00d100;"
					   *ngIf="autoRefresh"></i>
				</a>
                <!--export csv-->
                <a style="align-items: center;  padding: 5px 7px; font-size: 16px;"
                   [ngClass]="{'disabled' : dataList.length === 0}"
                   (click)="options.exportToCsvFunction(orderTableBy)"
                   *ngIf="options.exportToCsvFunction"
                   tooltip="Export results to CSV">
                    <i class="fa fa-file-export flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
                </a>
                <!--import from CSV-->
                <a style="align-items: center;  padding: 5px 7px; font-size: 16px;"
                   *ngIf="options.importFromCsv?.cbFunction"
                   ngfSelect
                   [(file)]="importFromCsvFileModel"
                   (fileChange)="importFromCsv(importFromCsvFileModel);"
                   accept=".csv"
                   tooltip="{{options.importFromCsv.tooltip ? ('Import from CSV.<br/>' + options.importFromCsv.tooltip) : 'Import from CSV'}}"
                >
                    <i class="fa fa-file-import flxClmn-center" style="width: 23px; height: 23px; align-items: center;" ></i>
                </a>
				<!-- Custom Buttons -->
				<ng-container *ngIf="options.customButtons">
					<a *ngFor="let btn of options.customButtons"
					   style="align-items: center; padding: 5px 7px; font-size: 16px;"
					   (click)="btn.function()"
					   [tooltip]="btn.tooltip"
					   [ngClass]="{'disabled': btn.disabled}">
						<i *ngIf="btn.icon" [ngClass]="btn.icon" class="flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
					</a>
				</ng-container>
                <!--refresh-->
                <a style="align-items: center;  padding: 5px 7px; font-size: 16px;"
                   [ngClass]="{'disabled' : options.loadingTableFlag}"
                   (click)="options.refreshFunction()"
                   *ngIf="options.refreshFunction"
                   tooltip="Refresh table">
                    <i class="fa fa-sync flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
                </a>
                <!--menu-->
                <a style="align-items: center;  padding: 5px 7px; font-size: 16px;"
				   id="tableActionsDropdownMenuTrigger"
                   *ngIf="options.menu"
                   tooltip="More actions"
                   (click)="showTableMenu.show = !showTableMenu.show;">
                    <i class="fa fa-ellipsis-v flxClmn-center" style="width: 23px; height: 23px; align-items: center;"></i>
                </a>
                <ul style="z-index: 1006; position: absolute; top: 36px; right: 0; background: white; box-shadow: 0 1px 4px grey; margin: 0; list-style: none; padding: 0; width: 180px;"
                    id="tableActionsDropdownMenu"
                    *ngIf="showTableMenu.show"
                    (clickOutside)="showTableMenu.show = false;"
					[exclude]="'#tableActionsDropdownMenuTrigger'">
                    <li style="height:35px; border:0; text-transform: capitalize;"
                        *ngFor="let menuItem of options.menu | keyvalue; trackBy: gs.trackByIndex"
                        id="{{menuItem.key}}MenuButton"
                        (click)="menuItem.value.action(); showTableMenu.show = false;">
                        <a class="flxRow hSpace1 h100 w100" style="align-items: center; padding: 0 15px;">
                            <i [ngClass]="menuItem.value.iconClass" *ngIf="menuItem.value.iconClass"></i>
                            <span>{{menuItem.value.label}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div #listTable [ngClass]="{'ellips' : isEllipsis,
                'alternated' : isAlternated,
                'no-header' : options.noHeader,
                'no-index' : !onAnyItemSelected.observed && !options.showRadioButtons && !(bulkActions && bulkActions.enable)}"
		 class="listTable-c flxClmn"
		 [(scrollToTopFlag)]="scrollToTopFlag"
		 style="flex: 1 0; overflow: auto; z-index: 90;">
		<!--sticky header clone (fixed)-->
		<table><ng-template [ngTemplateOutlet]="headerContainer"></ng-template></table>
		<!---->
        <virtual-scroller class="f-1-0" #scroll [items]="dataList" [scrollAnimationTime]="0"  [parentScroll]="listTable" [enableUnequalChildrenSizes]="true" [ngClass]="{'is-loading-or-empty' : (hideRecordsOnReload && options.loadingTableFlag) || !dataList.length}">
        <table>
			<!--original header (hidden)-->
			<ng-template [ngTemplateOutlet]="headerContainer"></ng-template>
			<!---->
			<ng-template #headerContainer>
				<thead #header>
				<tr>
					<!--(Radio button)-->
					<th style="padding-left: 5px; width: 20px; min-width: 20px;" *ngIf="(bulkActions && bulkActions.enable) || options.showRadioButtons">
						<div class="flxRow-start md-checkbox table-select-radio" style="max-height: 15px; position: relative;"
							 tooltip="{{selectedAllItems ? 'Deselect all' : 'Select all'}}"
							 (click)="toggleAllItems();"
							 [hidden]="!dataList.length || (hideRecordsOnReload && options.loadingTableFlag)">
							<input type="checkbox" [checked]="selectedAllItems"/>
							<label style="position: absolute; left: 0; top: 0;"></label>
						</div>
					</th>
					<!--all header cells-->
					<th *ngFor="let header of headers; trackBy: gs.trackByIndex"
						[ngStyle]="header.style"
						[style.width]="header.width"
						[style.min-width]="header.width">
						<div class="flxRow hSpace05" style="align-items: center;"
							 [ngStyle]="{'cursor' : header.sortKey ? 'pointer' : 'default', 'justify-content' : header.centered ? 'center' : ''}"
							 (click)="headerSortClicked(header)"
							 (ngInit)="(options.defaultSortKey && options.defaultSortKey === header.sortKey) ? header.sortDescending = false : header.sortDescending = true;">
							<!--html header-->
							<div *ngIf="header.html">
								<ng-container
									[ngTemplateOutlet]="customCellsTemplate"
									[ngTemplateOutletContext]="{$implicit: {}, cellId: header.html}">
								</ng-container>
							</div>
							<!--normal text header-->
							<span *ngIf="!header.html" style="transition: color 0.2s; white-space: normal;"
								  [ngClass]="(orderTableBy === header.sortKey || orderTableBy === '-' + header.sortKey) && 'active-sort'">
								{{header.text}}
							</span>

							<!--header's tooltip-->
							<i *ngIf="header.tooltip" tooltip="{{header.toolTipHtml ? header.tooltip : header.tooltip}}" style="font-weight: 400;" class="fas fa-question-circle"></i>

							<!--sort-->
							<a *ngIf="header.sortKey && header.sortDescending" style="margin-top:1px; padding-left:5px;" tooltip="Sort">
								<i class="zmdi zmdi-chevron-down" style="width:10px; transition: color 0.2s;"
								   [ngClass]="orderTableBy === '-' + header.sortKey && 'active-sort'"></i>
							</a>
							<a *ngIf="header.sortKey && !header.sortDescending" style="margin-top:1px; padding-left:5px;" tooltip="Sort">
								<i class="zmdi zmdi-chevron-up" style="width:10px; transition: color 0.2s;"
								   [ngClass]="orderTableBy === header.sortKey && 'active-sort'"></i>
							</a>
							<!---->
						</div>
					</th>
					<!--Bulk actions header cell-->
					<th class="overflownV actions-column-header" style="padding: 0 10px; width: clamp(170px,10vw,210px);" *ngIf="bulkActions && bulkActions.enable">
						<app-dropdown-c class="animated fadeIn" style="width: 100%;"
									  (click)="bulkActionsDropdownClicked();"
									  *ngIf="selectedItems.length > 0"
									  [isLf]="true"
									  text="Actions for {{selectedItems.length}} item{{selectedItems.length > 1 ? 's' : ''}}">
							<li *ngFor="let action of bulkActionsArr; trackBy: gs.trackByIndex"
								[ngClass]="{'has-submenu' : action.submenu}"
								[ngStyle]="bulkActions.showInRed && bulkActions.showInRed.enable && (bulkActions.showInRed.terms.includes(action) || bulkActions.showInRed.terms.includes(action.display)) && {'color' : 'red'}"
								(click)="bulkActions.selectBulkActionCb(selectedItems,action);"
								id="{{(action.display || action) + ' bulk'}}">
								<span style="color: inherit;" *ngIf="!action.html">{{action.display || action}}</span>
								<div *ngIf="action.html" [innerHTML]="action.html"></div>
								<!--submenu-->
								<ul class="submenu" *ngIf="action.submenu"
									[ngClass]="(action.submenu.direction && action.submenu.direction === 'right') ? 'drop-right' : 'drop-left'">
									<li *ngFor="let subAction of action.submenu; trackBy: gs.trackByIndex"
										(click)="bulkActions.selectBulkActionCb(selectedItems,subAction);">
										<a style="color: inherit;" *ngIf="!subAction.html">{{subAction.display || subAction}}</a>
										<div *ngIf="subAction.html" [innerHTML]="subAction.html"></div>
									</li>
								</ul>
							</li>
							<!--DUMMY. let the dropdown directive know that this dropdown has custom content (the above elements are inside condition (ngFor))-->
							<div style="display: none;"></div>
							<!---->
						</app-dropdown-c>
					</th>
					<!--no Bulk actions placeholder-->
					<th class="overflownV actions-column-header" style="padding: 0 10px; width: clamp(170px,10vw,210px);"
						*ngIf="(cellActions && cellActions.enable) && (!bulkActions || !bulkActions.enable)">
					</th>
				</tr>
				</thead>
			</ng-template>
                <tbody #container [hidden]="hideRecordsOnReload && options.loadingTableFlag">
				<ng-container *ngFor="let item of scroll.viewPortItems; trackBy: options.itemUniqueIdentifier ? trackByUniqueIdentifier : gs.trackByIndex">
						<tr [ngClass]="{'chosen' : ((bulkActions && bulkActions.enable) || options.showRadioButtons) && item.selected, 'chosen-solo' : options.soloSelected && item.soloSelected, 'is-dragged' : item === draggedItem, 'is-dragged-over' : item.isDraggedOver && item !== draggedItem}"
							(click)="!item.isEditMode && !options.soloSelected && (item.selected = !item.selected); item.soloSelected = true; selectItem(item);"

							[draggable]="onDragAndDrop.observed"
							(dragstart)="draggedItem = item;"
							(drop)="dropItem(item);"
							(dragover)="dragOverItem($event, item)"
							(dragleave)="$event.preventDefault(); item.isDraggedOver = false;">
							<!--|(Radio button)|-->
							<td style="padding-left: 5px; width: 20px;"
								*ngIf="(bulkActions && bulkActions.enable) || options.showRadioButtons"
								(click)="!item.isEditMode && (item.selected = !item.selected); selectItem(item, true); $event.stopPropagation();">
								<div class="flxRow-start md-checkbox table-select-radio" style="max-height: 15px;" *ngIf="(!item.depthLevel && item.isSelectable !== false) || item.isSelectable === true">
									<input type="checkbox"
										   [(ngModel)]="item.selected"/>
									<label style="position: absolute; left: 0; top: 0;"></label>
								</div>
							</td>
							<!--|body generic cells|-->
							<td *ngFor="let cellProps of cellsPrototype; let first = first; trackBy: gs.trackByIndex" (click)="cellProps.onClick && cellProps.onClick(item);"
								[ngStyle]="{'cursor' : cellProps.onClick ? 'pointer' : ''}"
								[ngClass]="{'overflownV' : cellProps.overflowOutside}">
								<!--html cell-->
								<div *ngIf="cellProps.html && !(item.isEditMode && cellProps.edit)" class="external-html-cell">
									<ng-container
											[ngTemplateOutlet]="customCellsTemplate"
											[ngTemplateOutletContext]="{$implicit: item, cellId: cellProps.html}">
									</ng-container>
								</div>

								<!--text cell (with tooltip)-->
								<span *ngIf="cellProps.tooltip && cellProps.tooltip && !cellProps.html && !(item.isEditMode && cellProps.edit)"
									  tooltip="{{cellProps.tooltip === true ? _.get(item, cellProps.textKey) : cellProps.tooltip}}" [ngStyle]="cellProps.style">
									{{_.get(item, cellProps.textKey)}}
								</span>
								<!--text cell (without tooltip)-->
								<!--text cell (without tooltip)-->
								<span
									*ngIf="!cellProps.tooltip && !cellProps.html && !(item.isEditMode && cellProps.edit)"
									[ngStyle]="cellProps.style">
									{{
										cellProps.textType === 'dateAndTime' ?
											(_.get(item, cellProps.textKey) ? (_.get(item, cellProps.textKey) | date:'MM/dd/yyyy HH:mm') : (cellProps.placeHolder || ''))
											: (_.get(item, cellProps.textKey) != null ? _.get(item, cellProps.textKey) : (cellProps.placeHolder || ''))
									}}
								</span>

								<!--Edit Mode-->
								<div class="w100 h100"
									 *ngIf="item.isEditMode && cellProps.edit && !cellProps.edit.html">
									<input class="w100 h100"
										   placeholder="{{cellProps.edit.placeholder}}"
										   style="margin: 0; background: white;"
										   [appAutoFocus]="!!cellProps.edit.autoFocus"
										   [(ngModel)]="item.edit[cellProps.edit.modelKey]"
										   (keydown.enter)="confirmEdit.emit({item: item,isApproved: true})" type="text">
								</div>
								<!--Edit Mode - HTML-->
								<div class="w100 h100" *ngIf="cellProps.edit && cellProps.edit.html && item.isEditMode" (ngInit)="item.isNew;">
									<ng-container
										[ngTemplateOutlet]="customCellsTemplate"
										[ngTemplateOutletContext]="{$implicit: item, cellId: cellProps.edit.html}">
									</ng-container>
								</div>
							</td>
							<!--|item actions cell|-->
							<td (click)="!item.hideActions && $event.stopPropagation()" class="overflownV" style="padding: 0 10px;" *ngIf="cellActions && cellActions.enable && !item.isEditMode">
								<app-dropdown-c style="width: 100%;"
											  [ngClass]="{'show-above' : item.actionsDirection === 'above'}"
											  [isDisabled]="bulkActions && bulkActions.enable && item.selected"
											  *ngIf="!item.hideActions && (!item.depthLevel || subRowOptions?.allowActions)"
											  (click)="actionsDropdownClicked($event, item);"
											  text="Actions">
									<li *ngFor="let action of item.tTableActions; trackBy: gs.trackByIndex"
										[ngClass]="{'has-submenu' : action.submenu}"
										[ngStyle]="cellActions.showInRed && cellActions.showInRed.enable && (cellActions.showInRed.terms.includes(action) || cellActions.showInRed.terms.includes(action.display)) && {'color' : 'red'}"
										(click)="cellActions.selectItemActionCb(item, action);"
										id="{{action.display || action}}">
										<span style="color: inherit;" *ngIf="!action.html">{{action.display || action}}</span>
										<div *ngIf="action.html" [innerHTML]="action.html"></div>
										<!--submenu-->
										<ul class="submenu" *ngIf="action.submenu"
											[ngClass]="(action.submenu.direction && action.submenu.direction === 'right') ? 'drop-right' : 'drop-left'">
											<li *ngFor="let subAction of action.submenu; trackBy: gs.trackByIndex"
												(click)="cellActions.selectItemActionCb(item, subAction);">
												<a style="color: inherit;" *ngIf="!subAction.html">{{subAction.display || subAction}}</a>
												<div *ngIf="subAction.html" [innerHTML]="subAction.html"></div>
											</li>
										</ul>
									</li>
									<!--DUMMY. let the dropdown directive know that this dropdown has custom content (the above elements are inside condition (ngFor))-->
									<div style="display: none;"></div>
									<!---->
								</app-dropdown-c>
							</td>
							<!--edit item buttons-->
							<!--(only shows when "actions" is enabled for table. if not - please add the buttons locally with transclution)-->
							<td *ngIf="cellActions && cellActions.enable && item.isEditMode" (click)="$event.stopPropagation()" class="overflownV">
								<div class="flxRow hSpace05" style="padding-left: 20px;">
									<btn-c style="min-width: 0;"
										   [loading]="item.confirmEditInProcess"
										   endIcon="zmdi zmdi-check"
										   (click)="$event.stopPropagation();"
										   (action)="confirmEdit.emit({item: item,isApproved: true});">
									</btn-c>
									<btn-c style="min-width: 0;"
										   [isDisabled]="item.confirmEditInProcess"
										   id="contactsCloseAdd" color="danger"
										   variant="text" endIcon="zmdi zmdi-close"
										   (click)="$event.stopPropagation();"
										   (action)="confirmEdit.emit({item: item,isApproved: false});">
									</btn-c>
								</div>
							</td>
						</tr>
						<ng-container
							*ngIf="subRowTemplate && item.isExpanded"
							[ngTemplateOutlet]="subRowTemplate"
							[ngTemplateOutletContext]="{$implicit: item}">
						</ng-container>
				</ng-container>
                </tbody>
			<!--DUMMY row for drag and drop for after last item-->
			<tr class="dummy-dnd-row" *ngIf="draggedItem" style="background: transparent;"
				[ngClass]="{'is-dragged-over' : isDraggedToLast && draggedItem !== _.last(scroll.viewPortItems)}"
				(drop)="dropItem(null);"
				(dragover)="dragOverItem($event, null); "
				(dragleave)="isDraggedToLast = false;">
				<td [colSpan]="columnsCount"></td>
			</tr>
			<!---->
        </table>
        </virtual-scroller>
        <!-- placeholders for empty table or loading-->
        <div class="w100 flxClmn-center" style="flex:1 0; text-align: center; background: #fdfdfd;"
             *ngIf="!dataList.length || (hideRecordsOnReload && options.loadingTableFlag)">
            <!--No items in list-->
			<span *ngIf="(!dataListInput || !dataListInput.length) && !options.loadingTableFlag" style="opacity: 0.7;">
				No {{options.itemsNameSingular ? options.itemsNameSingular + 's' : 'records'}} in list
			</span>

			<!--Loading-->
            <div class="flxClmn vSpace05" style="align-items: center; opacity: 0.85;" *ngIf="(!dataList.length && options.loadingTableFlag) || (hideRecordsOnReload && options.loadingTableFlag)">
                <loader height="100" width="100" style="opacity: 0.8;"></loader>
                <!--                <span class="color-primary" style="font-size: 0.75rem;">-->
                <!--                    Loading {{options.itemsNameSingular ? options.itemsNameSingular + 's' : 'table'}}-->
                <!--                </span>-->
            </div>
            <!--search/filter no results-->
            <span style="opacity: 0.7;" *ngIf="dataListInput && dataListInput.length && !(hideRecordsOnReload && options.loadingTableFlag)">
                No {{options.itemsNameSingular ? options.itemsNameSingular + 's' : 'records'}} found in this search or filter
            </span>
        </div>
        <!---->
    </div>
</div>
