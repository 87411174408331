import * as util from 'util';
import _ from 'lodash';
import {GeneralService} from '../../../../../services/generalService';
import {RouteService} from '../../../../../services/routeService';
import {DICTIONARY} from '../../../../../dictionary';
import {NotificationService} from '../../../../../services/notificationService';
import {Component, OnInit} from "@angular/core";
import {ClipboardService} from 'ngx-clipboard';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';


@Component({
	selector: 'email-relay-integration-component',
	templateUrl: './email-relay-integration.component.html',
})
export class EmailRelayIntegrationComponent implements OnInit {
    dic = DICTIONARY;
    verifiedDomainExist = false;
    corpname = this.gs.corpname;
    genNewKey = false;
    emailRelayHeader = 'x-trustifi-creds';
    planInfo: any;
    selectedServerEndpoint: { name: string; AzureAD: string; MSGraphAPI: string; };
	showJournalAddress: Boolean;
	userInfo: any;
    relayConnectedStatus: string;
    updateMtaInProcess: boolean;
    outboundRelayDomainErr: boolean;
    mtaToMove: any;
    mtaInEdit: any;
    updateIgnoreDomainInProcess: boolean;
    ignoreDomain: any;
	showEmailRelayUsername;
	showEmailRelayPassword;
	showEmailRelayKey;
	regenerateInProcessER: boolean;
	changeArchPopup: any
	selectedArchitecture;

	sendingArchitecturesData = {
		[this.dic.CONSTANTS.sendingArchitecture.trustifi]: {
			name: 'Trustifi MTA',
			description: 'Emails will be sent from your mail server to Trustifi\'s MTA (all email protections will be applied here), from which it will be sent to your recipients.',
			icon: 'fa fa-project-diagram',
		},
		[this.dic.CONSTANTS.sendingArchitecture.client]: {
			name: 'Your MTA',
			description: 'Emails will be sent from your mail server to Trustifi\'s MTA (all email protections will be applied here), and back into your MTA from which it will be sent to your recipients.',
			icon: 'fa fa-code-branch',
		},
		[this.dic.CONSTANTS.sendingArchitecture.journal]: {
			name: 'Journal',
			description: 'Outbound mail flow will be configured using a journal rule. Trustifi will scan journaled copies of your received emails for sensitivity.',
			icon: 'fa fa-exchange-alt',
		}
	}

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private clipboard: ClipboardService) {
    }

	ngOnInit() {

		this.rs.getOutboundPlanSettings().then((planInfo) => {
            this.planInfo = planInfo;

            this.gs.getUserInfo(false, (userInfo) => {
                this.userInfo = userInfo;
                this.relayConnectedStatus = planInfo.outbound_relay && planInfo.outbound_relay.is_connected ? DICTIONARY.CONSTANTS.relayStatus.active : DICTIONARY.CONSTANTS.relayStatus.inactive;

                this.selectedServerEndpoint = _.clone(DICTIONARY.CONSTANTS.exchangeServerEndpoints.Global);
                if (this.planInfo.exchange_server && this.planInfo.exchange_server.endpoint_name) {
                    this.selectedServerEndpoint.name = this.planInfo.exchange_server.endpoint_name;
                    this.selectedServerEndpoint.AzureAD = this.planInfo.exchange_server.azuread_endpoint;
                    this.selectedServerEndpoint.MSGraphAPI = this.planInfo.exchange_server.msgraph_endpoint;
                }

                if (this.planInfo.domains && this.planInfo.domains.length) {
                    this.planInfo.domains.forEach((domainObj) => {
                        if (domainObj.verified) {
                            this.verifiedDomainExist = true;
                        }
                    });
                }

                if (this.planInfo.outbound_relay) {
                    this.planInfo.outbound_relay.keyAndSecret = this.planInfo.outbound_relay.key + ':' + this.planInfo.outbound_relay.secret;
                }

				this.selectArchitecture(this.planInfo.outbound_relay.sending_architecture);
            });
        }, (err) => {

		});
    };

	onDragStartMta(mta: any) {
		this.mtaToMove = mta;
	}

	onDropMta(event: CdkDragDrop<any[]>) {
		if (!this.mtaToMove) return;

		const prevIndex = this.planInfo.outbound_relay.mtas.findIndex(m => m === this.mtaToMove);
		const currIndex = event.currentIndex;

		// Update local array for UI
		moveItemInArray(this.planInfo.outbound_relay.mtas, prevIndex, currIndex);

		this.updateEmailRelayConfig(DICTIONARY.CONSTANTS.erSettingsActions.mta, 'move', currIndex);
	}

    activateEmailRelay(genNewKey = null) {
        this.genNewKey = genNewKey;
        let title = "", button = "", subTitle = "", body = [], type = DICTIONARY.CONSTANTS.popupWarning;

        if (this.planInfo.outbound_relay.sending_architecture === DICTIONARY.CONSTANTS.sendingArchitecture.client && (!this.planInfo.outbound_relay.mtas ||
            !this.planInfo.outbound_relay.mtas.length)) {
            this.planInfo.outbound_relay.enabled = !this.planInfo.outbound_relay.enabled;
            return this.ns.showWarnMessage(DICTIONARY.ERRORS.adminMtaMissing);
        }
        else if (this.planInfo.outbound_relay.sending_architecture === DICTIONARY.CONSTANTS.sendingArchitecture.trustifi && !this.verifiedDomainExist) {
            const message = (util.format(DICTIONARY.ERRORS.noDomainsToVerify,
                '<a target="_self" href="/' + DICTIONARY.CONSTANTS.appStates.adminOutbound + '/' + DICTIONARY.CONSTANTS.adminPages.outbound.sharedPlan + '/' + DICTIONARY.CONSTANTS.outboundPlanSettingsPageTabs.domains + '"><b><i>Domains</i></b></a>'));
            this.planInfo.outbound_relay.enabled = !this.planInfo.outbound_relay.enabled;
            return this.ns.showWarnMessage(message);
        }

		if (genNewKey) {
			button = "Regenerate";
			title = "Regenerate Email Relay Key";
			subTitle = "You are about to change your Email Relay key!";
			body = ["All users under Email-Server that configured this key will not be able to send emails through Email Relay."];
			this.regenerateInProcessER = true;
		}
		else {
			if (this.planInfo.outbound_relay.enabled) {
				button = "Enable";
				title = "Enable Email-Relay";
				subTitle = "You are about to enable Email Relay.";
				body = ["All users under Email-Server that configured this key may join your plan and send emails through the Email Relay."];
				type = DICTIONARY.CONSTANTS.popupInfo;
			}
			else {
				button = "Disable";
				title = "Disable Email-Relay";
				subTitle = "You are about to disable Email Relay!";
				body = ["Disabling Email-Relay will stop all users from sending emails through Email-Relay. Please make sure you have configured your Email Server to stop using our Email-Relay first"];
			}
		}

		setTimeout(() => {
			this.gs.showPopup({
				title: title,
				subTitle: subTitle,
				body: body,
				type: type,
				doneBtnText: button,
				doneCb: () => {
					this.rs.activateEmailRelayAndGenKey({action: DICTIONARY.CONSTANTS.erSettingsActions.key, enabled: this.planInfo.outbound_relay.enabled, regenerate: this.genNewKey}).then((response) => {
						this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
						this.planInfo.outbound_relay = response || {};
						if (this.planInfo.outbound_relay) {
							this.planInfo.outbound_relay.keyAndSecret = this.planInfo.outbound_relay.key+':'+this.planInfo.outbound_relay.secret;
						}
						if (!this.planInfo.outbound_relay.enabled) {
							this.relayConnectedStatus = DICTIONARY.CONSTANTS.relayStatus.inactive;
						}
						this.planInfo.outbound_relay.sensitivity_threshold = this.planInfo.outbound_relay.sensitivity_threshold || 3;

						this.regenerateInProcessER = false;
					});
				},
				cancelCb: () => {
					if (!this.genNewKey) {
						this.planInfo.outbound_relay.enabled = !this.planInfo.outbound_relay.enabled;
					}
				}
			});
		});
    };


    updateErSettings() {
        const updateData = {
            action: DICTIONARY.CONSTANTS.erSettingsActions.welcomeEmail,
            send_welcome_email: this.planInfo.outbound_relay.send_welcome_email
        }

        this.rs.activateEmailRelayAndGenKey(updateData).then(() => {
            this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
        });
    };

	selectArchitecture = (architectureName) => {
		this.selectedArchitecture = this.sendingArchitecturesData[architectureName];
		this.selectedArchitecture.key = architectureName;
	}

    updateSendingArchitecture = (architectureName) => {
        if (this.planInfo.outbound_relay.sending_architecture === architectureName) {
			return;
		}

        let bodyDetails = [];

		switch (architectureName) {
			case DICTIONARY.CONSTANTS.sendingArchitecture.trustifi:
				bodyDetails.push(`Emails will now sent from your mail server to Trustifi's MTA (all email protections will be applied here), from which it will be sent to your recipients`,
					`To avoid issues with email delivery, make sure your mail-flow connectors and rules are created according to the instructions <b><a href="https://docs.trustifi.com/docs/configuration-using-trustifi-mta" target="_blank">here</a></b>`);
				break;

			case DICTIONARY.CONSTANTS.sendingArchitecture.journal:
				bodyDetails.push('Configure the Journal Rule settings in Exchange/Google to scan your journaled emails in Trustifi.',
					'With the journal architecture, functions related to modification of the email body are not available. These functions include applying footers/headers to emails, encryption, and tracking.');
				break;

			case DICTIONARY.CONSTANTS.sendingArchitecture.client:
				if (!this.planInfo.outbound_relay.mtas?.length) {
					this.changeArchPopup = {
						show: true,
						port: 25,
						domain: '',
						mta: '',
						architectureName
					};
					return;
				}
				break;
		}

        this.gs.showPopup({
            title: 'Change Sending Architecture',
            subTitle: `Please note - you are about to change your Email Relay sending architecture`,
            body: bodyDetails,
            type: DICTIONARY.CONSTANTS.popupWarning,
            doneBtnText: 'Approve',
            doneCb: () => {
                const updateData = {
                    action: DICTIONARY.CONSTANTS.erSettingsActions.sendingArchitecture,
                    sendingArchitecture: architectureName
                };

                this.rs.activateEmailRelayAndGenKey(updateData).then((outboundRelay) => {
                    this.ngOnInit();
                    this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
                });
            }
        });
    };

	updateSendingArchitectureExecute = () => {
		if (!this.changeArchPopup.mta && !this.changeArchPopup.domain && !this.changeArchPopup.port) {
			this.ns.showErrorMessage('You must enter a valid domain, MTA, and port number');
			return;
		}
		const updateData = {
			action: DICTIONARY.CONSTANTS.erSettingsActions.sendingArchitecture,
			sendingArchitecture: this.changeArchPopup.architectureName,
			domain: this.changeArchPopup.domain,
			mta: this.changeArchPopup.mta,
			port: this.changeArchPopup.port
		};

		this.rs.activateEmailRelayAndGenKey(updateData).then((outboundRelay) => {
			this.ngOnInit();
			this.changeArchPopup = null;
			this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
		});
	}

    updateEmailRelayConfig = (action, type = "", index = null) => {
        this.updateMtaInProcess = true;

        let updateData:any = {action: action};
        switch (action) {
            case DICTIONARY.CONSTANTS.erSettingsActions.mta:
                if (type === 'add') {
                    if (!this.gs.isValidDomain(this.planInfo.outbound_relay.domain)) {
                        this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.invalidDomain, this.planInfo.outbound_relay.domain));
                        this.outboundRelayDomainErr = true;
                        this.updateMtaInProcess = false;
                        return;
                    }
                    this.planInfo.outbound_relay.newPort = parseInt(this.planInfo.outbound_relay.newPort);
                    if (this.planInfo.outbound_relay.mtas.length) {
                        for (let i = 0; i < this.planInfo.outbound_relay.mtas.length; i++) {
                            if (this.planInfo.outbound_relay.mtas[i].host === this.planInfo.outbound_relay.host &&
                                this.planInfo.outbound_relay.mtas[i].domain === this.planInfo.outbound_relay.domain &&
                                this.planInfo.outbound_relay.mtas[i].port === this.planInfo.outbound_relay.newPort) {
                                this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.mtaAlreadyExist, this.planInfo.outbound_relay.host));
                                this.updateMtaInProcess = false;
                                return;
                            }
                        }
                        const recordsCountForDomain = _.sumBy<any>(this.planInfo.outbound_relay.mtas, m => m.domain === this.planInfo.outbound_relay.domain ? 1:0);
                        if (recordsCountForDomain >= 3) {
                            this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.CannotAddMta, this.planInfo.outbound_relay.domain));
                            this.updateMtaInProcess = false;
                            return;
                        }
                    }
                    updateData.host = this.planInfo.outbound_relay.host;
                    updateData.port = this.planInfo.outbound_relay.newPort;
                    updateData.domain = this.planInfo.outbound_relay.domain;
                }
                else if (type === 'move') {
                    updateData._id = this.mtaToMove._id;
                    updateData.idx = index;
                }
                else if (type === 'edit') {
                    updateData._id = this.mtaInEdit._id;
                    updateData.host = this.mtaInEdit.host;
                    updateData.port = parseInt(this.mtaInEdit.port);
                    updateData.domain = this.mtaInEdit.domain;
                }
                else {
                    if (this.planInfo.outbound_relay.enabled && this.planInfo.outbound_relay.mtas.length === 1) {
                        this.updateMtaInProcess = false;
                        return this.ns.showWarnMessage(DICTIONARY.ERRORS.CannotRemoveMta);
                    }
                    updateData._id = this.planInfo.outbound_relay.mtas[index]._id;
                    updateData.domain = this.planInfo.outbound_relay.mtas[index].domain;
                }
                updateData.type = type;
                break;

            case DICTIONARY.CONSTANTS.erSettingsActions.domainMta:
                if (!this.gs.isValidDomain(this.planInfo.outbound_relay.domain)) {
                    this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.invalidDomain, this.planInfo.outbound_relay.domain));
                    this.outboundRelayDomainErr = true;
                    this.updateMtaInProcess = false;
                    return;
                }
                updateData.domain = this.planInfo.outbound_relay.domain;
                break;
        }

        this.rs.activateEmailRelayAndGenKey(updateData).then((response) => {
            switch (action) {
                case DICTIONARY.CONSTANTS.erSettingsActions.mta:
                    for (let i = 0; i < response.mtas.length; i++) {
                        if (this.planInfo.outbound_relay.mtas[i] && this.planInfo.outbound_relay.mtas[i].editMode) {
                            response.mtas[i].editMode = true;
                            this.mtaInEdit = response.mtas[i];
                        }
                    }
                    this.planInfo.outbound_relay.mtas = response.mtas;
                    if (type === 'add') {
                        this.planInfo.outbound_relay.host = '';
                        this.planInfo.outbound_relay.newPort = '';
                        this.planInfo.outbound_relay.domain = '';
                    }
                    else if (type === 'move') {
                        this.mtaToMove = null;
                    }
                    else if (type === 'edit') {
                        this.mtaInEdit.editMode = false;
                        this.mtaInEdit = null;
                    }
                    this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
                    break;

                case DICTIONARY.CONSTANTS.erSettingsActions.domainMta:
                    this.planInfo.outbound_relay.host = response.exchange;
                    break;
            }

            this.updateMtaInProcess = false;
        }, err => {
            this.updateMtaInProcess = false;
        });
    };

    setMTAForEdit = (mta) => {
        this.planInfo.outbound_relay.mtas = _.map(this.planInfo.outbound_relay.mtas, mta => {
            mta.editMode = false;
            return mta;
        });
        mta.editMode = true;
        this.mtaInEdit = mta;
    };

    verifyMta = (mtaObj) => {
        if (this.updateMtaInProcess) {
            return;
        }

        this.updateMtaInProcess = true;
        const actionInfo = {
            action: DICTIONARY.CONSTANTS.inboundRelayActions.mta,
            type: 'verify',
            host: mtaObj.host,
            port: mtaObj.port,
        }

        this.rs.activateEmailRelayAndGenKey(actionInfo).then((response) => {
			mtaObj['verified'] = response.meta && response.meta.type === 'info';

            this.updateMtaInProcess = false;
        }, (err) => {
            this.updateMtaInProcess = false;
            mtaObj['verified'] = false;
        });
    };

	copyEmailRelayHostToClipboard() {
		this.clipboard.copy('smtp.trustifi.com');
		this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay host"));
	};

	copyEmailRelayPortToClipboard() {
		this.clipboard.copy('25');
		this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay port"));
	};

    copyEmailRelayUsernameToClipboard() {
        this.clipboard.copy(this.planInfo.outbound_relay.key);
        this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay username"));
    };

    copyEmailRelayPasswordToClipboard() {
        this.clipboard.copy(this.planInfo.outbound_relay.secret);
        this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay password"));
    };

    copyEmailRelayKeyToClipboard() {
        this.clipboard.copy(this.planInfo.outbound_relay.key+':'+this.planInfo.outbound_relay.secret);
        this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay key"));
    };

    copyEmailRelayHeaderToClipboard() {
        this.clipboard.copy(this.emailRelayHeader);
        this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay header"));
    };

	copyItem(item, name) {
		this.clipboard.copy(item);
		this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, name));
	}

    updateIgnoreDomains = (type, removedDomainIndex = null) => {
        let domain;
        switch (type) {
            case 'add':
                if (!this.ignoreDomain)
                    return;
                if (!this.gs.isValidDomain(this.ignoreDomain) && !this.gs.validateEmail(this.ignoreDomain)) {
                    return this.ns.showErrorMessage(util.format(DICTIONARY.ERRORS.invalidDomainOrAddress, this.ignoreDomain));
                }
                if (this.planInfo.ignore_send_domains.includes(this.ignoreDomain)) {
                    return this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.domainAlreadyExist, this.ignoreDomain));
                }
                domain = this.ignoreDomain;
                break;
            case 'remove':
                if (!this.planInfo.ignore_send_domains[removedDomainIndex]) return;
                domain = this.planInfo.ignore_send_domains[removedDomainIndex];
                break;
        }
        const updateData = {
            type: type,
            action: DICTIONARY.CONSTANTS.erSettingsActions.ignoreSendDomains,
            domain: domain
        };
        this.updateIgnoreDomainInProcess = true;

        this.rs.activateEmailRelayAndGenKey(updateData).then(() => {
            switch (type) {
                case 'add':
                    this.planInfo.ignore_send_domains.push(this.ignoreDomain);
                    this.ignoreDomain = '';
                    break;
                case 'remove':
                    this.planInfo.ignore_send_domains.splice(removedDomainIndex, 1);
                    break;
            }
            this.updateIgnoreDomainInProcess = false;
            this.ns.showInfoMessage(DICTIONARY.MESSAGES.ignoreSendDomainsUpdated);
        });
    };
}
