import {DICTIONARY} from '../../../dictionary';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {GeneralService} from '../../../services/generalService';
import {AuthService} from "../../../services/authService";
import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";

@Component({
	selector: 'purchase-page-component',
	templateUrl: './purchase-page.component.html',
})
export class PurchasePageComponent implements OnInit{

    constructor(private rs:RouteService,
				private gs:GeneralService,
				private ns:NotificationService,
				private router:Router,
				private authService:AuthService) {
	}

    dic = DICTIONARY;
    validateEmail = this.gs.validateEmail;
    purchaseStepsChosenIdx = 0;
    numUsers = 1;
    signupData:any = {
        accountDetails: {
            phone: {country_code: this.gs.country_code}
        }
    };
    partner;

    price_yearly = 99.99;   //TODO: receive price from backend

    ngOnInit() {
		this.partner = this.dic.PAYPAL_PARTNERS[this.dic.CONSTANTS.trustifiDefault.name.toLowerCase()];
		this.partner.id = this.dic.CONSTANTS.trustifiDefault.name.toLowerCase();
    }

    navigateBack() {
        window.history.back();
    }

    loginAndPurchase() {
		this.router.navigate([this.dic.CONSTANTS.appStates.login],{state: {transitionName: [this.dic.CONSTANTS.appStates.accountDetails, this.dic.CONSTANTS.accountDetailsPages.myPlan]}});
	}

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    login(email, access_token, expires_in) {
        if(access_token) {
            this.authService.remoteLogin({
                expiresIn: expires_in,
                accessToken: access_token
            }, err => {
                if(!err) {
                    this.router.navigate([this.dic.CONSTANTS.appStates.home]);
                }
            });
        }
        else {
			this.router.navigate([this.dic.CONSTANTS.appStates.login], {state: {email: email, gothrough: true}});
		}
    };

    paypalComplete(err, res) {
        if(err) {
        }
        else {
            this.login(res.email, res.access_token, res.expires_in);
        }
    };

    purchaseStepsInfo = [
        {
            text: `We will send you a verification email.<br> Click the link inside the email to verify your email address.`,
            subText: "If you can't find the email, check your spam/junk folders as well.",
            iconClass: "fas fa-user-check"
        },
        {
            text: "Once you are verified, we will send you a \"Welcome\" email with details about your Trustifi plan and instructions on how to easily install the Trustifi add-ins for Outlook and Gmail.",
            iconClass: "fas fa-envelope-open-text"
        },
        {
            text: "In the Trustifi portal you will be able to add users, manage them, create policies, view reports and perform many more actions.",
            iconClass: "fas fa-globe"
        },
        {
            text: `Our support team is always available and happy to help with anything. If you need us, please open a ticket or send us an email to<br><a style="opacity: 0.7 !important; font-size: calc(15px + 0.17vw)" href="mailto:support@trustificorp.com">support@trustificorp.com</a>.`,
            subText: "If our service did not meet your expectations - we will offer you a full refund.",
            iconClass: "fas fa-headset"
        }
    ]

    navigatePurchaseSteps(direction) {
        switch (direction) {
            case 'previous':
                if (this.purchaseStepsChosenIdx > 0) {
                    this.purchaseStepsChosenIdx--;
                }
                break;
            case 'next':
                if (this.purchaseStepsChosenIdx < 3) {
                    this.purchaseStepsChosenIdx++;
                }
                break;
            default:
                return;
        }
    }

    // number of licenses input in purchase page - validation function
    validateMinMaxUsers(newValue) {
        if (newValue > this.partner.maxUsers) {
            this.numUsers = this.partner.maxUsers;
            this.partner.showMaxUsersErrorMsg = true;
        }
        else if (newValue < 1) {
            this.numUsers = 1;
        }
        else {
            this.partner.showMaxUsersErrorMsg = false;
        }
    }
}
