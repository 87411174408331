<div class="flxClmn bs-enabled">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Notifications</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Manage your notification preferences and view notifications history</span>
        </div>
    </div>
    <div class="page-inner-content-holder gap-3 pt-0 flex-column no-scroll" id="alertsTab" style="min-height: 650px;">
        <div class="flex-column f-1-0 gap-4 animated fadeIn overflow-auto">
            <div class="list-group f-1-0 list-group-flush settings-list-container overflow-auto">
                <div class="list-group-item settings-list-item py-3" style="max-width: unset;">
                    <div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
                        <b>Preferences</b>
                        <span class="text-muted lh-base" style="font-size: 0.9em;">Enable or disable notifications for specific system events</span>
                    </div>
                    <div class="content-container flex-column gap-4" style="max-width: 630px;">
                        <div class="list-group list-group-flush">
                            <div class="list-group-item flex-row gap-3 py-0 pb-2 align-items-center">
                                <div style="flex-shrink: 0; flex-basis: 350px;">
                                    <span>Notify me about emails successfully sent from Add-ins</span>
                                </div>
                                <toggle-switch-c id="MFAToggle"
                                               [(model)]="userAlerts.success_email"
                                               (onChange)="setUserAlerts();"></toggle-switch-c>
                            </div>
                            <div class="list-group-item flex-row gap-3 py-2 align-items-center">
                                <div style="flex-shrink: 0; flex-basis: 350px;">
                                    <span>Notify me about plan renewal</span>
                                </div>
                                <toggle-switch-c id="notifyPlanRenewalToggle"
												 [(model)]="userAlerts.plan_renewal"
                                                 (onChange)="setUserAlerts();"></toggle-switch-c>
                            </div>
                            <div class="list-group-item flex-row gap-3 py-2 align-items-center" *ngIf="partner_plan_id">
                                <div style="flex-shrink: 0; flex-basis: 350px;">
                                    <span>Notify me when my plan is accessed by a partner admin</span>
                                </div>
                                <toggle-switch-c id="notifyAccessByPartnerToggle"
												 [(model)]="userAlerts.plan_access_by_partner"
												 (onChange)="setUserAlerts();"></toggle-switch-c>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-group-item f-1-0 pt-4 flex-column overflow-auto">

                    <!--notifications table-->
                    <trustifi-table-c
                            class="trustifi-table" style="flex: 1 0; z-index: 5;"
                            [list]="notifications"
                            [headers]="[
                                        {text:'Date', width: '200px', sortKey:'created'},
                                        {text:'Message'},
                                        {text:'Type', sortKey: 'type', width: 'min(160px,8vw)'},
                                    ]"
                            [cellsPrototype]="[
                                                {textKey: 'created', textType: 'dateAndTime'},
                                                {html: 'notificationMessageCell'},
                                                {html: 'notificationTypeCell'},
                                            ]"
                            [bulkActions] = "{
                                                enable: true,
                                                selectBulkActionCb:	selectMultipleNotificationsAction,
                                                initBulkActionsFunction: showNotificationBulkActions,
                                            }"
                            [cellActions] = "{
                                                enable: true,
                                                selectItemActionCb:	selectNotificationAction,
                                                initActionsFunction: showNotificationActions,
                                            }"
                            (searchFunction)="searchNotification($event)"
                            [filterData]="filterData"
                            [options]="{
                                        itemsNameSingular: 'notification',
                                        defaultSortKey: '-created',
                                        loadingTableFlag: loadingNotificationInProcess,
                                        isEllipsis: false
                                    }"
                    >
						<ng-template #customCells let-item let-cellId="cellId">
							<!--notification message cells-->
							<span *ngIf="cellId === 'notificationMessageCell'">
								<span style="display: block; padding-right: max(1vw,10px);" [innerHTML]="item.text"></span>
							</span>
							<!--inbound license cell-->
							<div *ngIf="cellId === 'notificationTypeCell'">
								<span style="text-transform: capitalize;" [ngStyle]="{'color' : item.type === 'warn' ? 'darkorange' : 'darkgreen'}">
                                	{{item.type === "warn" ? "Warning" : item.type}}
                            	</span>
							</div>
						</ng-template>
                    </trustifi-table-c>
                </div>
            </div>
        </div>
    </div>
</div>
