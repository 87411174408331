

<div class="flex-column gap-2">
	<span>Select which automatic actions should be taken for each type of detected threat.</span>

	<!--loader-->
	<div class="f-1-0 flex-column centralize" *ngIf="!config && getThreatPreventionRulesInProcess">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div class="list-group list-group-flush settings-list-container" *ngIf="config">
		<!--Settings: Email actions-->
		<div class="list-group-item column-style settings-list-item gap-3">
			<div class="title-section-container flex-column gap-2">
				<b>Email actions</b> <!--dummy-->
				<span class="lh-base" style="font-size: 0.9em;">
                        Default actions for emails detected by Inbound Shield as malicious, suspicious, spam, graymail and external<br/>
                        <span class="fw-500">NOTE: Currently full AI-based detection of Spam, Gray and BEC emails is supported for English only.</span>
                    </span>
			</div>
			<div class="content-container flex-column gap-1 p-2 bg-light mb-3" style="z-index: 50;">
				<div class="flex-row align-items-center bg-white p-2 gap-2">
					<div class="flex-row align-items-center gap-1">
						<span>Select rules preset:</span>
						<i style="font-weight: 400;" class="fas fa-question-circle" tooltip='The "Main" rule preset will be used by default. You can custom presets here that can be used according to inbound email rules.'></i>
					</div>
					<app-dropdown-c style="width: 250px;" [isEllipsis]="true"
									[text]="selectedRulePreset?.name"
									[items]="rulePresets" [alwaysDrop]="true"
									itemDisplayKey="name" [enableSearch]="true"
									(onItemClicked)="selectedRulePreset = $event.item;">
					</app-dropdown-c>
					<div></div><!--padder-->
					<btn-c style="min-width: 0;"
						   btnTooltip="Add new rules preset"
						   endIcon="fa fa-plus"
						   (action)="openAddSubRulePopup();">
						Add
					</btn-c>
					<btn-c style="width: 100px; font-size: 12px;"
						   btnTooltip='Set threat prevention rules to "Monitor mode"'
						   variant="outlined" color="darkgoldenrod"
						   (action)="monitorInboundRules();">
						Monitor mode
					</btn-c>
					<btn-c style="min-width: 0;"
						   *ngIf="selectedRulePreset?.name === dic.CONSTANTS.lfMainCustomizationName"
						   btnTooltip="Reset the threat prevention rules to the default settings, in selected preset"
						   variant="outlined" color="danger"
						   (action)="resetInboundRules();">
						Reset
					</btn-c>
					<btn-c class="animated fadeIn" style="min-width: 0;"
						   *ngIf="selectedRulePreset?.name !== dic.CONSTANTS.lfMainCustomizationName"
						   btnTooltip="Delete selected threat prevention rules preset"
						   variant="outlined" color="danger"
						   (action)="deleteSubRule();">
						Delete
					</btn-c>
				</div>

				<div class="bg-white py-2 ps-3 gap-2">
					<table class="simpleDataTable w-100">
						<thead>
						<tr>
							<th style="width: 320px;"></th>
							<th style="width: 200px;">Action</th>
							<th style="width: 510px;">Secondary Actions</th>
							<th style="width: 124px;" >
								<ng-container *ngIf="!selectedRulePreset || selectedRulePreset?.name === dic.CONSTANTS.lfMainCustomizationName">
									Retention Period
								</ng-container>
							</th>
						</tr>
						</thead>
						<tbody>
						<ng-container *ngIf="selectedRulePreset">
							<tr *ngFor="let ruleType of dic.CONSTANTS.tpRule.name | keyvalue: gs.returnZero" >
								<td>
									When an email is detected as
									<a class="blue-link text-decoration-underline fw-bold cursor-default"
									   [tooltip]="ruleTypesTooltips[ruleType.value]">
										{{ruleType.value === dic.CONSTANTS.tpRule.name.external ? 'external/unfamiliar': ruleType.value}}
									</a>
								</td>
								<!--|Action|-->
								<td>
									<app-dropdown-c style="width: 90%;" text="{{selectedRulePreset[ruleType.key].actions.includes(rulesMainActions.release) ? rulesMainActions.release : rulesMainActions.quarantine}}">
										<li *ngFor="let actionKey of rulesMainActions | keyvalue: gs.returnZero"
											[explanationC]="dic.CONSTANTS.tpRule.actionTooltip[actionKey.key]"
											(click)="updateRulesActions(ruleType.key, actionKey.value);">
											{{actionKey.value}}
										</li>
									</app-dropdown-c>
								</td>
								<!--|Secondary Action|-->
								<td *ngIf="selectedRulePreset[ruleType.key]">
									<div class="flex-row align-items-center">
										<app-dropdown-c style="width: 240px;" text="{{_.intersection(selectedRulePreset[ruleType.key].actions, _.values(rulesSubActions)).join(', ') || 'Do nothing (Choose)'}}">
											<li *ngFor="let actionKey of rulesSubActions | keyvalue: gs.returnZero"
												(click)="updateRulesActions(ruleType.key, actionKey.value); $event.stopPropagation()"
												[hidden]="(ruleType.value === dic.CONSTANTS.tpRule.name.malicious && actionKey.value === dic.CONSTANTS.tpRule.action.recipientControl) ||
                                                          (ruleType.value === dic.CONSTANTS.tpRule.name.external && (actionKey.value === dic.CONSTANTS.tpRule.action.notifyRecipient || actionKey.value === dic.CONSTANTS.tpRule.action.notifyReviewer))"
												[ngClass]="{'disabled': (enforceKeepRecord || ruleType.value === dic.CONSTANTS.tpRule.name.malicious || selectedRulePreset[ruleType.key].actions.includes(rulesMainActions.quarantine)) &&
                                                                        actionKey.value === dic.CONSTANTS.tpRule.action.keepRecord}"
												[explanationC]="dic.CONSTANTS.tpRule.actionTooltip[actionKey.key]"
												placement="left"
												[tooltip]="(ruleType.value === dic.CONSTANTS.tpRule.name.malicious || selectedRulePreset[ruleType.key].actions.includes(rulesMainActions.quarantine)) && actionKey.value === dic.CONSTANTS.tpRule.action.keepRecord ? '&quot;' + dic.CONSTANTS.tpRule.action.keepRecord + '&quot; cannot be disabled for quarantined emails' : null">
												<div class="gap-1 md-checkbox">
													<input type="checkbox" onclick="return false;" [checked]="selectedRulePreset[ruleType.key].actions.includes(rulesSubActions[actionKey.key])"/>
													<label><span>{{actionKey.value}}</span></label>
												</div>
											</li>
										</app-dropdown-c>
										<btn-c class="ms-3 me-0 animated fadeIn"
											   *ngIf="selectedRulePreset[ruleType.key].actions.includes(rulesSubActions.addWarningLabel)"
											   variant="outlined"
											   (action)="openModifyWarning(ruleType.key);">
											Modify Label
										</btn-c>
										<btn-c class="ms-3 me-0 animated fadeIn"
											   *ngIf="selectedRulePreset[ruleType.key].actions.includes(rulesSubActions.addSubjectText)"
											   variant="outlined"
											   (action)="openModifySubject(ruleType.key);">
											Modify Subject
										</btn-c>
									</div>
								</td>
								<!--|Retention Period|-->
								<td style="text-align: center;" >
									<a class="blue-link" style="text-decoration: underline;" (click)="openRetentionPeriodPopup(ruleType.key)"
									   *ngIf="selectedRulePreset?.name === dic.CONSTANTS.lfMainCustomizationName">
										{{selectedRulePreset[ruleType.key].retention_period || '14'}} days
									</a>
								</td>
							</tr>
						</ng-container>
						</tbody>
					</table>

					<div class="flex-row mt-4 mb-2 align-items-baseline gap-2" *ngIf="enforceKeepRecord">
						<i class="fa fa-exclamation-triangle text-warning"></i>
						<span>{{enforceKeepRecord}}</span>
					</div>
				</div>
			</div>
		</div>

		<!--Settings: Configure time-based notifications for received threats-->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2">
				<b>Configure time-based notifications for received threats</b>
				<span class="lh-base" style="font-size: 0.9em;">Configure when reviewers and recipients should receive email notifications for received threats.</span>
			</div>
			<div class="content-container flex-column gap-2">
				<btn-c style="width: 150px;"
					   (action)="openNotifyOptions();">
					Notification Options
				</btn-c>
			</div>
		</div>

		<!--Settings: Skip warning label for email/domain-->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2">
				<b>Skip warning label for email/domain</b>
				<span class="lh-base" style="font-size: 0.9em;">Warning labels will never be applied to emails and domains from this list.</span>
			</div>
			<div class="content-container flex-column gap-2">
				<btn-c style="width: 150px;"
					   (action)="openExcludeDomainsEmailsPopup();">
					Configure
				</btn-c>
			</div>
		</div>

		<!--Contact Impersonation-->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2">
				<b>Notify reviewers about user reports</b>
				<span class="lh-base" style="font-size: 0.9em;">Reviewers will receive an automated notification when a user reports an email as malicious/spam/safe. Reviewers will be able to review the reported email and take actions.</span>
			</div>
			<div class="content-container" *ngIf="mainRulePreset">
				<toggle-switch-c id="send_user_reports_to_reviewers"
								 [(model)]="mainRulePreset.send_user_reports_to_reviewers"
								 (onChange)="updateSendUserReportsToReviewers()">
				</toggle-switch-c>
			</div>
		</div>

		<!--Settings: Apply "Scanned by Inbound Shield" footer-->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2">
				<b>Apply "Scanned by Inbound Shield" footer</b>
				<span class="lh-base" style="font-size: 0.9em;">When enabled, a footer will be applied to all emails to indicate the email has been scanned by Inbound Shield.</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="applyFooterToggle"
								 [(model)]="config.threat_protection.add_footer"
								 (onChange)="updateFooterStatus()">
				</toggle-switch-c>
				<div class="flex-row align-items-center gap-2" [ngClass]="{'disabled-overlay' : !config.threat_protection.add_footer}"
					 (ngInit)="newCustomizedFooterStr = config.threat_protection.customization_footer;">
					<span>Customized footer:</span>
					<input type="text" class="f-1-0"
						   [disabled]="!config.threat_protection.add_footer"
						   placeholder='Default: "This email has been scanned by Inbound Shield"'
						   [(ngModel)]="newCustomizedFooterStr"/>
					<btn-c (action)="updateFooterContent();">
						Update
					</btn-c>
				</div>
			</div>
		</div>

		<!--Contact Impersonation-->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2">
				<b>Set "Contact impersonation" emails as "Suspicious"</b>
				<span class="lh-base" style="font-size: 0.9em;">Emails detected as "Contact impersonation" will be flagged as "Suspicious" instead of "Malicious".</span>
			</div>
			<div class="content-container">
				<toggle-switch-c *ngIf="mainRulePreset" id="applyContactImpersonationToggle"
								 [(model)]="mainRulePreset.contact_impersonation_suspicious"
								 (onChange)="updateContactImpersonationSuspicious()">
				</toggle-switch-c>
			</div>
		</div>
	</div>
</div>

<!--Modify warning label popup-->
<popup-c class="no-content-padding" *ngIf="modifyWarningLabelPopup?.show"
		 head="Modify Warning Label for {{modifyWarningLabelPopup.ruleType}} source"
		 (closeCb)="modifyWarningLabelPopup = null"
		 size="large">
	<div class="flxClmn f-1-0 vSpace2" style="overflow: auto; padding: 25px;">
		<div class="flxRow">
			<div class="flxClmn vSpace1" style="flex-basis: 160px;">
				<label style="font-weight: bold;">
					Warning Text
				</label>
				<btn-c variant="outlined"
					   (action)="showWarningPreview(modifyWarningLabelPopup.ruleType, '#' + modifyWarningLabelPopup.color.replace('#',''), modifyWarningLabelPopup.text, allowReport)">
					Preview
				</btn-c>
			</div>
			<div class="flex-column gap-1">
				<div style="flex:1 0; overflow: auto; box-sizing: border-box; border: 1px solid #ccc; vertical-align: top; resize: none;"
					 [ngxSummernote]="summernoteConfig" [(ngModel)]="modifyWarningLabelPopup.text"></div>
				<div class="flex-row gap-1">
					<i class="fa fa-info-circle text-info"></i>
					<span>You can enhance your warning label by incorporating dynamic fields such as {{ '{{SENDER_EMAIL}}' }}, {{ '{{SENDER_NAME}}' }} and {{ '{{REPLY_TO_EMAIL}}' }}.</span>
				</div>
			</div>
		</div>

		<div class="flxRow" style="padding-top: 20px;">
			<div style="overflow: hidden; float: left; flex-basis:160px;">
				<label style="font-weight: bold;">
					Warning Color
				</label>
			</div>
			<div style="flex: 1 0;">
				<div class="flxRow hSpace1" style="align-items: baseline; margin-bottom: 15px; color: var(--bs-warning);" *ngIf="gs.userInfo?.dark_mode">
					<i class="fa fa-exclamation-triangle "></i>
					<span>While in "Dark Mode", color picker will show inaccurate colors.</span>
				</div>
				<div class="flxRow w100 hSpace3 color-picker-css" style="align-items: center;">
					<div class="flxClmn-center vSpace2" style="align-items: center;">
						<div style="width: 40px; height: 40px;">
							<div class="w100 h100 static-color" style="border-radius: 50%;"
								 [ngStyle]="{'background' : '#' + modifyWarningLabelPopup.color.replace('#','')}"
								 *ngIf="modifyWarningLabelPopup.color.replace('#','').length === 6">
							</div>
						</div>
						<div class="flxClmn vSpace05">
							<input type="text" style="width: 90px; margin: 0;"
								   [ngClass]="{'validation-error' : hexInputError}"
								   (keyup)="hexInputChanged();"
								   [value]="'#' + modifyWarningLabelPopup.color.replace('#','')"
								   [(ngModel)]="modifyWarningLabelPopup.color">
							<span style="opacity: 0.65; font-family: 'Roboto Condensed', sans-serif; letter-spacing: 0.5px;">Color Hex<br/>(6 digits only)</span>
						</div>
					</div>
					<span [(colorPicker)]="modifyWarningLabelPopup.color"
						  [cpPosition]="'bottom'" [cpPositionOffset]="'50%'" [cpWidth]="'175px'"
						  [cpOutputFormat]="'hex'"
						  [cpDialogDisplay]="'inline'"
						  [cpDisableInput]="true"
						  [cpToggle]="true"
						  (cpSliderDragEnd)="hexInputError = false;"
						  [style.position]="'relative'"></span>
				</div>
			</div>
		</div>

		<div class="flxRow" style="padding-top: 20px;"
			 *ngIf="config?.outbound_relay && config?.outbound_relay?.enabled">
			<div style="overflow: hidden; float: left; flex-basis:160px;">
				<label style="font-weight: bold;">
					Allow reporting
				</label>
			</div>
			<div class="flxClmn vSpace1" style="flex: 1 0;">
				<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
					<input id="allowReportingSwitch" required="false" type="checkbox"
						   [(ngModel)]="allowReport"/>
					<label class="m-0" for="allowReportingSwitch">Allow recipients to report emails as safe/malicious directly from the warning label</label>
				</div>
				<div class="flex-row mx-2 align-items-baseline gap-2" *ngIf="config.outbound_relay && config.outbound_relay.enabled">
					<i class="fa fa-info-circle text-info"></i>
					<span>If "Allow reporting" is enabled, records will be kept for this type of email</span>
				</div>
			</div>
		</div>
	</div>
	<div class="flxRow-end hSpace1" style="padding: 25px;">
		<btn-c class="popup-action-btn"
			   variant="text" color="goldenrod"
			   [isDisabled]="modifyWarningLabelPopup.modifyWarningInProcess"
			   (action)="modifyWarning(true);">
			Reset to default
		</btn-c>
		<btn-c class="popup-action-btn"
			   variant="outlined"
			   [isDisabled]="modifyWarningLabelPopup.modifyWarningInProcess"
			   (action)="modifyWarningLabelPopup = null;">
			Cancel
		</btn-c>
		<btn-c class="popup-action-btn"
			   [isDisabled]="!modifyWarningLabelPopup.text || !modifyWarningLabelPopup.color || modifyWarningLabelPopup.modifyWarningInProcess"
			   (action)="modifyWarning(false)">
			Apply
		</btn-c>
	</div>
</popup-c>

<!--Preview warning label popup-->
<popup-c *ngIf="previewWarningLabelPopup?.show"
		 head="Preview Warning Label for: {{previewWarningLabelPopup.ruleType}}"
		 (closeCb)="previewWarningLabelPopup = null"
		 size="dynamic">
	<div class="flxClmn f-1-0" style="overflow: auto;">
		<div class="flex-column gap-3 py-2"
			 style="border: 0 solid white; border-left: 5px solid darkorange; vertical-align: middle; padding-right: 10px;"
			 [ngStyle]="{'background-color': previewWarningLabelPopup.backgroundColor, 'border-left-color': previewWarningLabelPopup.borderColor}">
			<div class="mb-1" [innerHTML]="previewWarningLabelPopup.header"></div>
			<div class="flex-row gap-2">
				<div *ngIf="allowReport">
					<btn-c style="width: 140px; margin-left: 5px; font-weight: 600;" variant="outlined">I trust this email</btn-c>
				</div>
				<div *ngIf="allowReport && previewWarningLabelPopup.ruleType !== dic.CONSTANTS.tpRule.name.malicious">
					<btn-c style="width: 140px; margin-left: 5px; font-weight: 600;" variant="outlined">Report as malicious</btn-c>
				</div>
			</div>
		</div>
	</div>
</popup-c>

<!--Modify subject popup-->
<popup-c *ngIf="modifySubjectPopup?.show"
		 head="Modify Subject Text for {{modifySubjectPopup.ruleType}}"
		 (closeCb)="modifySubjectPopup = null"
		 (confirmCb)="modifySubject()"
		 [buttons]="{confirm: {text: 'Confirm', loading: modifySubjectPopup.modifySubjectInProcess}}"
		 size="dynamic">
	<div class="flxClmn f-1-0 vSpace1" style="overflow: auto;">
		<span>The relevant text will be added to the beginning of email subject when it is released</span>
		<br/>
		<input type="text" style="width: 100%;" [(ngModel)]="modifySubjectPopup.text" placeholder="Subject">
	</div>
</popup-c>

<!--Change notification options popup-->
<popup-c *ngIf="notifyOptionsPopup?.show"
		 head="Change Notification Options"
		 subTitle="Changes will apply to all Threat Prevention rules types"
		 (closeCb)="notifyOptionsPopup = null"
		 (confirmCb)="changeNotifyPeriod()"
		 [buttons]="{confirm: {text: 'Confirm', loading: notifyOptionsPopup.changeNotifyPeriodInProcess}}"
		 size="large">
	<div class="flxClmn vSpace1 f-1-0">
		<span style="font-weight: bold;">Notify Reviewer</span>
		<div class="flxClmn vSpace2" style="padding: 0 15px;">
			<div class="flxRow hSpace1" style="align-items: center;">
				<span style="flex-basis: 150px;">Select notification timing:</span>
				<div style="flex: 1 0; position: relative;">
					<app-dropdown-c style="width: 100%;"
									[error]="notifyOptionsPopup.isNotifyReviewerPeriodsError"
									text="{{notifyOptionsPopup.notifyReviewerPeriods && _.keys(_.pickBy(notifyOptionsPopup.notifyReviewerPeriods)).join(', ').replace('Notify every day', 'Custom notification times') || 'Select'}}">
						<li *ngFor="let periodKey of dic.CONSTANTS.tpRule.notifyOptions | keyvalue: gs.returnZero"
							(click)="notifyOptionsPopup.notifyReviewerPeriods[periodKey.value] = !notifyOptionsPopup.notifyReviewerPeriods[periodKey.value]; notifyOptionsPopup.isNotifyReviewerPeriodsError = false; $event.stopPropagation();"
							id="{{'notifyReviewerPeriod_' + periodKey.key}}">
							<div class="gap-1 md-checkbox">
								<input type="checkbox" onclick="return false;" [checked]="notifyOptionsPopup.notifyReviewerPeriods[periodKey.value]"/>
								<label><span>{{periodKey.value === dic.CONSTANTS.tpRule.notifyOptions.everyDay ? 'Custom notification times' : periodKey.value}}</span></label>
							</div>
						</li>
					</app-dropdown-c>
					<span style="position: absolute; bottom: -20px; right: 0;  font-size: 12px; color: red;"
						  *ngIf="notifyOptionsPopup.isNotifyReviewerPeriodsError">
                            required field
					</span>
				</div>
			</div>
			<div class="flxRow hSpace1 animated fadeIn" style="align-items: center; margin-top: 10px; position: relative; z-index: 50;" *ngIf="notifyOptionsPopup.notifyReviewerPeriods[dic.CONSTANTS.tpRule.notifyOptions.everyDay]">
				<span style="flex-basis: 150px;">Select notification hours:</span>
				<div style="position: relative;">
					<app-dropdown-c class="limit-height" style="width: 200px;"
									[error]="notifyOptionsPopup.isNotifyReviewerDailyHoursError"
									text="{{getDailyHoursText(notifyOptionsPopup.notifyReviewerDailyHours).join(', ') || 'Select'}}">
						<li *ngFor="let twoDigitHour of hoursArr"
							(click)="toggleNotificationDailyHour(notifyOptionsPopup.notifyReviewerDailyHours, twoDigitHour); $event.stopPropagation();"
							id="{{'notifyReviewerDailyHour_' + twoDigitHour}}">
							<div class="gap-1 md-checkbox">
								<input type="checkbox" onclick="return false;" [checked]="notifyOptionsPopup.notifyReviewerDailyHours.includes(twoDigitHour)"/>
								<label><span>{{twoDigitHour + ':00'}}</span></label>
							</div>
						</li>
					</app-dropdown-c>
					<span style="position: absolute; bottom: -17px; right: 0;  font-size: 12px; color: red;"
						  *ngIf="notifyOptionsPopup.isNotifyReviewerDailyHoursError">
                            required field
                        </span>
				</div>
			</div>
			<div class="flxRow hSpace1 animated fadeIn" style="align-items: center; margin-top: 10px; position: relative; z-index: 49;" *ngIf="notifyOptionsPopup.notifyReviewerPeriods[dic.CONSTANTS.tpRule.notifyOptions.everyDay]">
				<span style="flex-basis: 150px;">Select notification days:</span>
				<div style="position: relative;">
					<app-dropdown-c class="limit-height" style="width: 200px;"
									[error]="notifyOptionsPopup.isNotifyReviewerDaysError"
									[items]="notifyOptionsPopup.notifyReviewerDays"
									[isMultipleSelection]="true"
									[hideCounter]="true"
									itemDisplayKey="day">
					</app-dropdown-c>
					<span style="position: absolute; bottom: -17px; right: 0;  font-size: 12px; color: red;"
						  *ngIf="notifyOptionsPopup.isNotifyReviewerDaysError">
                            required field
					</span>
				</div>
			</div>
		</div>
		<hr style="margin: 20px 0;"/>
		<div class="flxRow hSpace05" style="align-items: center;">
			<span style="font-weight: bold;">Notify Recipient</span>
			<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="This setting applies to personal reviewers as well" placement="right"></i>
		</div>
		<div class="flxClmn vSpace2" style="padding: 0 15px;">
			<div class="flxRow hSpace1" style="align-items: center;">
				<span style="flex-basis: 150px;">Select notification timing:</span>
				<div style="flex: 1 0; position: relative;">
					<app-dropdown-c style="width: 100%;"
									[error]="notifyOptionsPopup.isNotifyRecipientPeriodsError"
									text="{{notifyOptionsPopup.notifyRecipientPeriods && _.keys(_.pickBy(notifyOptionsPopup.notifyRecipientPeriods)).join(', ').replace('Notify every day', 'Custom notification times') || 'Select'}}">
						<li *ngFor="let periodKey of dic.CONSTANTS.tpRule.notifyOptions | keyvalue: gs.returnZero"
							(click)="notifyOptionsPopup.notifyRecipientPeriods[periodKey.value] = !notifyOptionsPopup.notifyRecipientPeriods[periodKey.value]; notifyOptionsPopup.isNotifyRecipientPeriodsError = false; $event.stopPropagation();"
							id="{{'notifyRecipientPeriods_' + periodKey.key}}">
							<div class="gap-1 md-checkbox">
								<input type="checkbox" onclick="return false;" [checked]="notifyOptionsPopup.notifyRecipientPeriods[periodKey.value]"/>
								<label><span>{{periodKey.value === dic.CONSTANTS.tpRule.notifyOptions.everyDay ? 'Custom notification times' : periodKey.value}}</span></label>
							</div>
						</li>
					</app-dropdown-c>
					<span style="position: absolute; bottom: -20px; right: 0;  font-size: 12px; color: red;"
						  *ngIf="notifyOptionsPopup.isNotifyRecipientPeriodsError">
                            required field
					</span>
				</div>
			</div>
			<div class="flxRow hSpace1 animated fadeIn" style="align-items: center; margin-top: 10px; z-index: 45;" *ngIf="notifyOptionsPopup.notifyRecipientPeriods[dic.CONSTANTS.tpRule.notifyOptions.everyDay]">
				<span style="flex-basis: 150px;">Select notification hours:</span>
				<div style="position: relative;">
					<app-dropdown-c class="limit-height" style="width: 200px;"
									[error]="notifyOptionsPopup.isNotifyRecipientDailyHoursError"
									text="{{getDailyHoursText(notifyOptionsPopup.notifyRecipientDailyHours).join(', ') || 'Select'}}">
						<li *ngFor="let twoDigitHour of hoursArr"
							(click)="toggleNotificationDailyHour(notifyOptionsPopup.notifyRecipientDailyHours, twoDigitHour); $event.stopPropagation();"
							id="{{'notifyRecipientDailyHour_' + twoDigitHour}}">
							<div class="gap-1 md-checkbox">
								<input type="checkbox" onclick="return false;" [checked]="notifyOptionsPopup.notifyRecipientDailyHours.includes(twoDigitHour)"/>
								<label><span>{{twoDigitHour + ':00'}}</span></label>
							</div>
						</li>
					</app-dropdown-c>
					<span style="position: absolute; bottom: -17px; right: 0;  font-size: 12px; color: red;"
						  *ngIf="notifyOptionsPopup.isNotifyRecipientDailyHoursError">
                            required field
					</span>
				</div>
			</div>
			<div class="flxRow hSpace1 animated fadeIn" style="align-items: center; margin-top: 10px; z-index: 44;" *ngIf="notifyOptionsPopup.notifyRecipientPeriods[dic.CONSTANTS.tpRule.notifyOptions.everyDay]">
				<span style="flex-basis: 150px;">Select notification days:</span>
				<div style="position: relative;">
					<app-dropdown-c class="limit-height" style="width: 200px;"
									[error]="notifyOptionsPopup.isNotifyRecipientDaysError"
									[items]="notifyOptionsPopup.notifyRecipientDays"
									[isMultipleSelection]="true"
									[hideCounter]="true"
									itemDisplayKey="day">
					</app-dropdown-c>
					<span style="position: absolute; bottom: -17px; right: 0;  font-size: 12px; color: red;"
						  *ngIf="notifyOptionsPopup.isNotifyRecipientDaysError">
                            required field
					</span>
				</div>
			</div>
			<div class="flxRow hSpace1">
				<div class="flxClmn" style="align-self: baseline; flex-basis: 155px;">
					<div class="flxRow hSpace05" style="align-items: center;">
						<span>Add message:</span>
						<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Here you can add an optional message in HTML format that will be added to the notification sent to recipients"></i>
					</div>
					<span style="font-size: 12px; color: lightslategrey;">optional</span>
				</div>

				<textarea placeholder="Add HTML here" style="height: 100px; flex: 1 0;"
						  [(ngModel)]="notifyOptionsPopup.notifyRecipientPeriodText">
                                </textarea>
			</div>

			<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
				<input id="notify_recipient_allow_change_time" type="checkbox"
					   [(ngModel)]="notifyOptionsPopup.notifyRecipientAllowChangeTime"/>
				<label style="margin: 0;" for="notify_recipient_allow_change_time">Allow users to set their own custom notification times</label>
			</div>

			<br/>
			<div class="flxRow hSpace05" style="align-items: center;">
				<span style="font-weight: bold;">Recipient Release Request Notification</span>
			</div>
			<div class="flxClmn vSpace1">
				<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
					<input id="notifyRecipientAllowRequestRelease" type="checkbox"
						   [(ngModel)]="notifyOptionsPopup.notifyRecipientAllowRequestRelease"/>
					<label style="margin: 0;" for="notifyRecipientAllowRequestRelease">Allow recipient to request release</label>
					<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Recipient quarantine notifications will have a button to automatically send a request to the admin/reviewers to release the email" placement="right"></i>
				</div>
				<div class="flxRow-start hSpace2" style="align-items: center;">
					<div class="flxRow hSpace05" style="align-items: center; flex-basis: 190px;">
						<span style="margin: 0; min-width: 165px;">Send the release request to:</span>
						<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Optional. If no address is provided here, the request will be sent to the primary reviewer." placement="right"></i>
					</div>
					<input type="text" class="lowerPlaceholder" placeholder="Email addresses separated by commas (optional)" style="margin: 0; width: 100%; box-sizing: inherit; flex: 1 0;"
						   [ngClass]="{'disable': !notifyOptionsPopup.notifyRecipientAllowRequestRelease}"
						   [disabled]="!notifyOptionsPopup.notifyRecipientAllowRequestRelease"
						   [(ngModel)]="notifyOptionsPopup.notifyRecipientAllowRequestReleaseEmail"/>
				</div>
				<div class="flxRow-start hSpace2">
					<span style="flex-basis: 190px; padding-top: 0.3rem;">Release request email title:</span>
					<div class="flxClmn vSpace05" style="flex: 1 0; margin: 0;">
						<input type="text" class="lowerPlaceholder" placeholder="{{releaseRequestSubjectPlaceholder}}" style="margin: 0; width: 100%; box-sizing: inherit;"
							   [ngClass]="{'disable': !notifyOptionsPopup.notifyRecipientAllowRequestRelease}"
							   [disabled]="!notifyOptionsPopup.notifyRecipientAllowRequestRelease"
							   [(ngModel)]="notifyOptionsPopup.notifyRecipientAllowRequestSubject"/>
						<span class="text-muted" style="font-size: 0.8rem; line-height: 1.2; max-width: 92%;">{{releaseRequestSubjectDesc}}</span>
					</div>
				</div>
				<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
					<input id="notify_recipient_allow_request_release_email_change_from" type="checkbox"
						   [disabled]="!notifyOptionsPopup.notifyRecipientAllowRequestRelease"
						   [(ngModel)]="notifyOptionsPopup.notifyRecipientAllowRequestReleaseFrom"/>
					<label style="margin: 0;" for="notify_recipient_allow_request_release_email_change_from">Use "from" address of the recipient</label>
				</div>
			</div>
		</div>
	</div>
</popup-c>

<!--Skip warning label popup-->
<popup-c *ngIf="excludeDomainsEmailsPopup?.show"
		 head="Skip Warning Label for Email/Domain"
		 (closeCb)="excludeDomainsEmailsPopup = null"
		 (confirmCb)="changeExcludeDomainsEmails()"
		 [buttons]="{confirm: {text: 'Confirm', loading: excludeDomainsEmailsPopup.changeExcludeDomainsEmailsInProcess}}"
		 size="medium">
	<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
		<span>Create a list of emails and domains that the warning label will not appear for in incoming emails:</span>
		<div class="flex-column f-1-0 overflow-auto gap-2 p-2">
			<div class="position-relative f-1-0 mt-3  flex-column overflow-auto">
				<trustifi-table-c
					class="trustifi-table f-1-0" style="z-index: 5;"
					[list]="excludeDomainsEmailsPopup.emails"
					[headers]="[
                                    {text: 'Email/Domain', sortKey: 'value'}
                                ]"
					[cellsPrototype]="[
                                          {textKey: 'value', edit: {modelKey: 'value', placeholder: 'Email/Domain'}},
                                      ]"
					[cellActions] = "{
                                        enable: true,
                                        selectItemActionCb:	selectExcludedEmailAction,
                                        initActionsFunction: showExcludedEmailActions
                                    }"
					(searchFunction)="searchExcludedEmail($event.searchTerm, $event.activeFilters)"
					(confirmEditFunction)="confirmAddExcludedEmail($event.item ,$event.isApproved)"
					[options]="{
                                    defaultSortKey: '-value'
                                }"
				>
					<div tableButtons>
						<div class="flxRow hSpace05">
							<btn-c [isDisabled]="addExcludedEmailActive"
								   endIcon="fa fa-plus"
								   (action)="startAddExcludedEmail();">
								Add Email/Domain
							</btn-c>
						</div>
					</div>
				</trustifi-table-c>
			</div>
		</div>
	</div>
</popup-c>

<!--Change Retention period popup-->
<popup-c *ngIf="retentionPeriodPopup?.show"
		 head="Change Retention Period"
		 (closeCb)="retentionPeriodPopup.show = false"
		 (confirmCb)="changeRetentionPeriod(retentionPeriodPopup.newRetentionPeriod)"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: retentionPeriodPopup.retentionPeriodInProcess}}"
		 size="dynamic">
	<div class="flxClmn f-1-0 vSpace3">
		<span>Determine how long quarantined emails of the type <b style="text-transform: capitalize;">{{retentionPeriodPopup.type}}</b>, should be kept by {{corpname}}.</span>

		<div class="flxRow hSpace2" style="align-items: center; flex: 1 0;">
			<span><b>Change retention period to</b>:</span>
			<input style="text-align: center;" type="number" min="1" max="365" step="1" pattern="^\d*(\.\d{0,3})?$" placeholder="Days"
				   [(ngModel)]="retentionPeriodPopup.newRetentionPeriod">
			<span>days.</span>
		</div>

		<span>You can choose to preserve specific emails by selecting "Preserve record" for an email in the <a class="blue-link" [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.quarantined]">"Quarantined emails"</a> page.</span>
	</div>
</popup-c>

<popup-c class="bs-enabled" *ngIf="deleteQuarantinedEmailRecPopup?.show"
		 head="Delete Quarantined Email Records" size="dynamic"
		 (closeCb)="deleteQuarantinedEmailRecPopup.show = false;"
		 (confirmCb)="deleteQuarantinedEmailRecPopup?.doneCb()"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Delete', disabled: deleteQuarantinedEmailRecPopup.applyInProcess || deleteQuarantinedEmailRecPopup.verifyEmail !== userInfo.email}}">
	<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
		<div class="d-flex gap-1 text-danger pt-2" style="font-size: 14px;">
			<i class="zmdi zmdi-alert-circle-o" style="font-size: 1.5rem; margin-top: -6px;"></i>
			{{deleteQuarantinedEmailRecPopup.subTitle}}
		</div>

		<ul>
			<li>Deleted quarantined emails records can not be restored</li>
		</ul>

		<div class="align-items-center flex-row gap-3">
			<div>Email:</div>
			<input type="text" placeholder="Please enter your email address for further verification"
				   style="width:330px;" [required]="true"
				   [(ngModel)]="deleteQuarantinedEmailRecPopup.verifyEmail"
				   [ngStyle]="deleteQuarantinedEmailRecPopup.verifyEmail && deleteQuarantinedEmailRecPopup.verifyEmail !== userInfo.email ? {'border-color':'red'} : {}"/>
		</div>
	</div>
</popup-c>

<popup-c *ngIf="addSubRulePopup?.show"
		 head="Add Threat Prevention Rules Preset" size="dynamic"
		 (closeCb)="addSubRulePopup = null"
		 (confirmCb)="addSubRule();"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: addSubRulePopup.addSubRuleInProcess}}">
	<div class="flex-column gap-3 f-1-0">
		<span>Enter a name for the new Threat Prevention rules preset:</span>
		<input type="text" style="margin: 0; width: 250px;" placeholder="Name"
			   (keyup)="addSubRulePopup.error = false;"
			   [(ngModel)]="addSubRulePopup.name"
			   [ngClass]="{'validation-error' : addSubRulePopup.error}"/>
		<br/>
		<span>Custom Threat Prevention rules presets can be used by creating an Inbound Email rule with the action "Apply Custom Threat Prevention Rules"</span>
	</div>
</popup-c>
