import {Component, OnInit} from "@angular/core";
import {RouteService} from "../../../../../services/routeService";
import {GeneralService} from "../../../../../services/generalService";
import {NotificationService} from "../../../../../services/notificationService";
import {DICTIONARY} from "../../../../../dictionary";
import _ from "lodash";

@Component({
	selector: 'outbound-sensitivity-types',
	templateUrl: './outbound-sensitivity-types.component.html',
})

export class OutboundSensitivityTypesComponent implements OnInit {

	constructor(private rs:RouteService,
				private gs:GeneralService,
				private ns:NotificationService) {
	}

	dic = DICTIONARY;
	sensitivityTypes  = [];
	getSensitivityTypesInProcess;
	filterData;
	_ = _;

	ngOnInit() {
		this.getSensitivityTypesInProcess = true;

		this.initFilters();

		this.rs.getSensitivityTypes().then((response) => {
			this.sensitivityTypes = _.flatMap(response.sensitivityTypes, type => {return [type, ...(type.sub_detector || [])]});

			this.getSensitivityTypesInProcess = false;
		}, (err) => {
			this.getSensitivityTypesInProcess = false;
		});

	}

	initFilters = () => {
		this.filterData = {
			filterType: this.dic.CONSTANTS.tableFilters.outboundSensitivityTypes,
			filters: {
				type: ['Text', 'OCR', 'Image', 'File'],
				compliance: _.keys(this.dic.CONSTANTS.rules.defaultCondition.data.compliance)
			},
			initFiltersFunction: this.initFilters,
		};
	}

	searchType = (searchTerm, activeFilters) => {
		this.sensitivityTypes.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			if (activeFilters) {
				// need to match all filter types
				let numFilterToMatch = Object.keys(activeFilters).length;

				if (activeFilters.type?.length) {
					if (activeFilters.type.includes(record.type)) {
						numFilterToMatch--;
					}
				}
				if (activeFilters.compliance?.length) {
					if (_.intersection(activeFilters.compliance, record.compliance).length) {
						numFilterToMatch--;
					}
				}

				if (numFilterToMatch) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	};

	exportToCsv = (sortBy) => {
		if (!this.sensitivityTypes?.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Name,Type,Min Score,Max Score,Compliance\n";

		let sortedTable = _.filter(this.sensitivityTypes,incident => {return !incident.hide});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		sortedTable.forEach((record) => {
			csvString += `"${record.name}","${record.type}",${record.min_score || 'N/A'},${record.max_score || 'N/A'},"${record.compliance?.length ? record.compliance.join(', ') : 'N/A'}"\n`;
		});

		this.gs.exportCsv(csvString, `sensitivity_types.csv`);
	}
}

function searchTextExecute(sensObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();

	return ((sensObj.name?.toLowerCase().indexOf(searchTerm) > -1) ||
		(sensObj.type?.toLowerCase().indexOf(searchTerm) > -1) ||
		(sensObj.compliance.join(',')?.includes(searchTerm.toUpperCase())));
}
