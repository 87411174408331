<div class="flxClmn bs-enabled" id="sharedplanactiveMobile">
	<div class="page-title-container flxRow">
		<div class="flxClmn-center f-1-0">
			<div class="page-title-inner-container flxRow">
				<span class="page-title">Lists Management</span>
				<!--Information modal-->
				<more-info-c>
					<div class="flxClmn vSpace1" style="flex: 1 0;">
						<span><b>Senders Allowlist</b> - emails arriving from allowlisted addresses {{currentUser === dic.CONSTANTS.planDefaultUser ? '/ domains': ''}} will not be scanned by Inbound Shield</span>
						<span><b>Senders Blocklist</b> - emails arriving from blocklisted addresses {{currentUser === dic.CONSTANTS.planDefaultUser ? '/ domains': ''}} will be automatically removed from the recipient's inbox</span>
						<span><b>Links Allowlist</b> - links from this list will always be considered as safe for your users</span>
						<span><b>Links Blocklist</b> - links from this list will always be considered as malicious for your users</span>
						<span><b>Attachments Allowlist</b> - attachments from this list will always be considered as safe</span>
						<span><b>Attachments Blocklist</b> - attachments from this list will always be considered as malicious</span>
						<span><b>Note</b> - emails/domains are checked against the allowlist before being checked against the blocklist</span>
					</div>
				</more-info-c>
			</div>
			<div class="page-subtitle-container">
				<span class="page-subtitle">Manage allow/block lists for senders, links and attachments</span>
			</div>
		</div>
		<div class="control-and-info-icons flxRow"></div>
	</div>
	<div class="page-inner-content-holder">
		<!--Tabs-->
		<div class="md-tabs" [ngStyle]="{'--md-tabs-width': tabWidth + 'px'}">
			<div *ngFor="let item of dic.CONSTANTS.userInboundConfigurationPageTabs | keyvalue : gs.returnZero"
				 [ngClass]="{'selected' : gs.getTabName() === item.value}"
				 [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.userInboundConf, item.value]">
				<input type="radio" name="tabs" id="{{item.key}}Toggle" [checked]="gs.getTabName() === item.value">
				<label for="{{item.key}}Toggle">{{item.value | camelCaseToTitleCase}}</label>
			</div>
			<div class="slide" [ngStyle]="{'left' : tabWidth * _.values(dic.CONSTANTS.userInboundConfigurationPageTabs).indexOf(gs.getTabName()) + 'px' || 0}"></div>
		</div>
		<div class="flex-column gap-4">
			<!--<span class="fw-500 diamond-indent ps-3 position-relative">Manage allow/block lists for senders, links and files.</span>-->
			<div class="list-group list-group-flush settings-list-container">

				<!--Settings: Block/Allow Senders -->
				<div class="list-group-item mw-100 settings-list-item column-style" *ngIf="gs.getTabName() === dic.CONSTANTS.userInboundConfigurationPageTabs.senderLists">
					<div *ngIf="!threats" class="tab-content-loader-container">
						<loader height="100" width="100" style="opacity: 0.8;"></loader>
					</div>
					<div class="content-container flex-column gap-3" *ngIf="threats">
						<!--Senders Whitelist-->
						<div class="flex-column gap-3">
							<div class="flex-column gap-2">
								<div class="flex-row align-items-center gap-1">
									<i class="fa fa-check-circle text-success" style="font-size: 0.7rem;"></i>
									<b style="font-size: 0.845rem;">Senders Allowlist</b>
								</div>
								<span class="text-muted lh-base" style="font-size: 0.9em;">Emails from senders in this list will always be considered safe</span>
							</div>
							<div class="flxClmn vSpace1" style="background-color: white; height: max(330px,25vw);">
								<trustifi-table-c
										class="trustifi-table" style="z-index: 5; height: max(330px,25vw);"
										[list]="threats?.senders_whitelist"
										[headers]="[
                                            {text: 'Sender', sortKey: 'email',  width: '33%'},
                                            {text: 'Description', width: '37%' },
                                            {text: 'Updated by', width: '20%' },
                                            {text: 'Last Update', sortKey: 'created', width: '20%' },
                                            {text: 'Last Used', sortKey: 'last_used', width: '20%' },
                                        ]"
										[cellsPrototype]="[
											{textKey: 'email', tooltip: true, edit: {modelKey:'email', placeholder:'Email'}},
											{textKey: 'description', tooltip: true, edit: {modelKey: 'description', placeholder: 'Description'}},
											{textKey: 'updated_by'},
											{textKey: 'created', textType: 'dateAndTime'},
											{textKey: 'last_used', textType: 'dateAndTime'}
										]"
										[bulkActions] = "{
											enable: true,
											selectBulkActionCb:	selectMultipleWhitelistAction,
											initBulkActionsFunction: showWhitelistBulkActions,
											showInRed: {enable: true, terms: ['Remove']}
										}"
										[cellActions] = "{
											enable: true,
											isSmaller: true,
											selectItemActionCb:	selectWhitelistAction,
											initActionsFunction: showWhitelistActions,
											showInRed: {enable: true, terms: ['Remove']}
										}"
										(searchFunction)="searchSendersWhitelist($event.searchTerm, $event.activeFilters)"
										(confirmEditFunction)="confirmEditSendersWhitelist($event.item, $event.isApproved)"
										[options]="{
                                            importFromCsv : {cbFunction : uploadSendersWhitelistFromFile, tooltip: 'A column named \'sender\' should contain the senders'},
                                            exportToCsvFunction: exportSendersWhitelistToCsv,
                                            itemsNameSingular: 'sender',
                                            defaultSortKey: '-created',
                                        }"
								>
									<div tableButtons>
										<div class="w100">
											<btn-c [isDisabled]="threats?.senders_whitelist?.isAddRecordActive"
												 (action)="startAddRecord(threats?.senders_whitelist);"
												 endIcon="fa fa-plus">
												Add Allowlisted Sender
											</btn-c>
										</div>
									</div>
								</trustifi-table-c>
							</div>
						</div>
						<br/>
						<!--Senders Blocklist-->
						<div class="flex-column gap-3">
							<div class="flex-column gap-2">
								<div class="flex-row align-items-center gap-1">
									<i class="fa fa-times-circle text-danger" style="font-size: 0.7rem;"></i>
									<b style="font-size: 0.845rem;">Senders Blocklist</b>
								</div>
								<span class="text-muted lh-base" style="font-size: 0.9em;">Emails from senders in this list will always be considered malicious</span>
							</div>
							<div class="flxClmn vSpace1" style="background-color: white; height: max(330px,25vw);">
								<trustifi-table-c
										class="trustifi-table" style="z-index: 5; height: max(330px,25vw);"
										[list]="threats?.senders_blacklist"
										[headers]="[
											{text:'Sender', sortKey:'email',  width: '33%'},
											{text:'Description', width: '37%' },
											{text: 'Updated by', width: '20%' },
											{text:'Last Update', sortKey:'created', width: '20%' },
											{text: 'Last Used', sortKey: 'last_used', width: '20%' },
										]"
										[cellsPrototype]="[
											{textKey: 'email', tooltip: true, edit: {modelKey:'email', placeholder:'Email'}},
											{textKey: 'description', tooltip: true, edit: {modelKey:'description', placeholder:'Description'}},
											{textKey: 'updated_by'},
											{textKey: 'created', textType: 'dateAndTime'},
											{textKey: 'last_used', textType: 'dateAndTime'}
										]"
										[bulkActions] = "{
											enable: true,
											selectBulkActionCb:	selectMultipleBlacklistAction,
											initBulkActionsFunction: showBlacklistBulkActions,
											showInRed: {enable: true, terms: ['Remove']}
										}"
										[cellActions] = "{
											enable: true,
											isSmaller: true,
											loadingTableFlag: loadingAllTablesInProcess,
											selectItemActionCb:	selectBlacklistAction,
											initActionsFunction: showBlacklistActions,
											showInRed: {enable: true, terms: ['Remove']}
										}"
										(searchFunction)="searchSendersBlacklist($event.searchTerm, $event.activeFilters)"
										(confirmEditFunction)="confirmEditSendersBlacklist($event.item, $event.isApproved)"
										[options]="{
											importFromCsv : {cbFunction : uploadSendersBlacklistFromFile, tooltip: 'A column named \'sender\' should contain the senders'},
											exportToCsvFunction: exportSendersBlacklistToCsv,
											itemsNameSingular: 'sender',
											defaultSortKey: '-created',
										}"
								>
									<div tableButtons>
										<div class="w100">
											<btn-c [isDisabled]="threats?.senders_blacklist?.isAddRecordActive"
												 (action)="startAddRecord(threats?.senders_blacklist);"
												 endIcon="fa fa-plus">
												Add Blocklisted Sender
											</btn-c>
										</div>
									</div>
								</trustifi-table-c>
							</div>
						</div>
					</div>
				</div>

				<!--Settings: Block/Allow Links -->
				<div class="list-group-item mw-100 settings-list-item column-style" *ngIf="gs.getTabName() === dic.CONSTANTS.userInboundConfigurationPageTabs.linkLists">
					<div *ngIf="!threats" class="tab-content-loader-container">
						<loader height="100" width="100" style="opacity: 0.8;"></loader>
					</div>
					<div class="content-container flex-column gap-3" *ngIf="threats">
						<!--Links Whitelist-->
						<div class="flex-column gap-3">
							<div class="flex-column gap-2">
								<div class="flex-row align-items-center gap-1">
									<i class="fa fa-check-circle text-success" style="font-size: 0.7rem;"></i>
									<b style="font-size: 0.845rem;">Links Allowlist</b>
								</div>
								<span class="text-muted lh-base" style="font-size: 0.9em;">Links from this list will always be considered safe</span>
							</div>
							<div class="flxClmn vSpace1" style="background-color: white; height: max(330px,25vw);">
								<trustifi-table-c
										class="trustifi-table" style="z-index: 5; height: max(330px,25vw);"
										[list]="threats?.links_whitelist"
										[headers]="[
											{text:'Link', sortKey:'link',  width: '33%'},
											{text: 'Match Type', width: '130px', sortKey: 'matchType'},
											{text:'Description', width: '37%' },
											{text: 'Updated by', width: '20%' },
											{text:'Last Update', sortKey:'created', width: '20%' },
										]"
										[cellsPrototype]="[
											{textKey: 'link', tooltip: true, edit: {modelKey:'link', placeholder:'Link'}},
											{textKey: 'matchType', overflowOutside: true, edit: {html: 'linksWhitelistMatchTypeCellEdit'}, style: {'text-transform' : 'capitalize'}},
											{textKey: 'description', tooltip: true, edit: {modelKey:'description', placeholder:'Description'}},
											{textKey: 'updated_by'},
											{textKey: 'created', textType: 'dateAndTime'},
										]"
										[bulkActions] = "{
											enable: true,
											selectBulkActionCb:	selectMultipleLinksWhitelistAction,
											initBulkActionsFunction: showLinksWhitelistBulkActions,
											showInRed: {enable: true, terms: ['Remove']}
										}"
										[cellActions] = "{
											enable: true,
											isSmaller: true,
											loadingTableFlag: loadingAllTablesInProcess,
											selectItemActionCb:	selectLinksWhitelistAction,
											initActionsFunction: showLinksWhitelistActions,
											showInRed: {enable: true, terms: ['Remove']}
										}"
										(searchFunction)="searchLinksWhitelist($event.searchTerm, $event.activeFilters)"
										(confirmEditFunction)="confirmEditLinksWhitelist($event.item, $event.isApproved)"
										[options]="{
											importFromCsv : {cbFunction : uploadLinksWhitelistFromFile, tooltip: 'A column named \'link\' should contain the senders'},
											exportToCsvFunction: exportLinksWhitelistToCsv,
											itemsNameSingular: 'link',
											defaultSortKey: '-created',
										}"
								>
									<!--link match type EDIT-->
									<ng-template #customCells let-item let-cellId="cellId">
										<!--action cells-->
										<div *ngIf="cellId === 'linksWhitelistMatchTypeCellEdit'">
											<app-dropdown-c class="w100 h100" text="{{item.edit.matchType}}">
												<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.exactMatch"
													explanationC="{{util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.exactMatch], 'URL')}}">
													{{dic.CONSTANTS.ruleMatchType.exactMatch}}
												</li>
												<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.startsWith"
													explanationC="{{util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.startsWith], 'URL')}}">
													{{dic.CONSTANTS.ruleMatchType.startsWith}}
												</li>
												<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.endsWith"
													explanationC="{{util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.endsWith], 'text')}}">
													{{dic.CONSTANTS.ruleMatchType.endsWith}}
												</li>
												<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.contains"
													explanationC="{{util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.contains], 'text')}}">
													{{dic.CONSTANTS.ruleMatchType.contains}}
												</li>
											</app-dropdown-c>
										</div>
									</ng-template>

									<!---->
									<div tableButtons>
										<div class="w100">
											<btn-c [isDisabled]="threats?.links_whitelist?.isAddRecordActive"
												 (action)="startAddRecord(threats?.links_whitelist);"
												 endIcon="fa fa-plus">
												Add Allowlisted Link
											</btn-c>
										</div>
									</div>
								</trustifi-table-c>
							</div>
						</div>
						<br/>
						<!--Links Blocklist-->
						<div class="flex-column gap-3">
							<div class="flex-column gap-2">
								<div class="flex-row align-items-center gap-1">
									<i class="fa fa-times-circle text-danger" style="font-size: 0.7rem;"></i>
									<b style="font-size: 0.845rem;">Links Blocklist</b>
								</div>
								<span class="text-muted lh-base" style="font-size: 0.9em;">Links from this list will always be considered malicious</span>
							</div>
							<div class="flxClmn vSpace1" style="background-color: white; height: max(330px,25vw);">
								<trustifi-table-c
										class="trustifi-table" style="z-index: 5; height: max(330px,25vw);"
										[list]="threats?.links_blacklist"
										[headers]="[
											{text:'Link', sortKey:'link',  width: '33%'},
											{text: 'Match Type', width: '130px', sortKey: 'matchType'},
											{text:'Description', width: '37%' },
											{text: 'Updated by', width: '20%' },
											{text:'Last Update', sortKey:'created', width: '20%' },
										]"
										[cellsPrototype]="[
											{textKey: 'link', tooltip: true, edit: {modelKey:'link', placeholder:'Link'}},
											{textKey: 'matchType', overflowOutside: true, edit: {html: 'linksBlacklistMatchTypeCellEdit'}, style: {'text-transform' : 'capitalize'}},
											{textKey: 'description', tooltip: true, edit: {modelKey:'description', placeholder:'Description'}},
											{textKey: 'updated_by'},
											{textKey: 'created', textType: 'dateAndTime'},
										]"
										[bulkActions] = "{
											enable: true,
											selectBulkActionCb:	selectMultipleLinksBlacklistAction,
											initBulkActionsFunction: showLinksBlacklistBulkActions,
											showInRed: {enable: true, terms: ['Remove']}
										}"
										[cellActions] = "{
											enable: true,
											isSmaller: true,
											loadingTableFlag: loadingAllTablesInProcess,
											selectItemActionCb:	selectLinksBlacklistAction,
											initActionsFunction: showLinksBlacklistActions,
											showInRed: {enable: true, terms: ['Remove']}
										}"
										(searchFunction)="searchLinksBlacklist($event.searchTerm, $event.activeFilters)"
										(confirmEditFunction)="confirmEditLinksBlacklist($event.item, $event.isApproved)"
										[options]="{
											importFromCsv : {cbFunction : uploadLinksBlacklistFromFile, tooltip: 'A column named \'link\' should contain the senders'},
											exportToCsvFunction: exportLinksBlacklistToCsv,
											itemsNameSingular: 'link',
											defaultSortKey: '-created',
										}"
								>
									<!--link match type EDIT-->
									<ng-template #customCells let-item let-cellId="cellId">
										<!--action cells-->
										<div *ngIf="cellId === 'linksBlacklistMatchTypeCellEdit'">
											<app-dropdown-c class="w100 h100" text="{{item.edit.matchType}}">
												<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.exactMatch"
													explanationC="{{util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.exactMatch], 'URL')}}">
													{{dic.CONSTANTS.ruleMatchType.exactMatch}}
												</li>
												<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.startsWith"
													explanationC="{{util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.startsWith], 'URL')}}">
													{{dic.CONSTANTS.ruleMatchType.startsWith}}
												</li>
												<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.endsWith"
													explanationC="{{util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.endsWith], 'text')}}">
													{{dic.CONSTANTS.ruleMatchType.endsWith}}
												</li>
												<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.contains"
													explanationC="{{util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.contains], 'text')}}">
													{{dic.CONSTANTS.ruleMatchType.contains}}
												</li>
											</app-dropdown-c>
										</div>
									</ng-template>
									<!---->
									<div tableButtons>
										<div class="w100">
											<btn-c [isDisabled]="threats?.links_blacklist?.isAddRecordActive"
												 (action)="startAddRecord(threats?.links_blacklist);"
												 endIcon="fa fa-plus">
												Add Blocklisted Link
											</btn-c>
										</div>
									</div>
								</trustifi-table-c>
							</div>
						</div>
					</div>
				</div>

				<!--Settings: Block/Allow Attachments -->
				<div class="list-group-item mw-100 settings-list-item column-style" *ngIf="gs.getTabName() === dic.CONSTANTS.userInboundConfigurationPageTabs.attachmentLists">
					<div *ngIf="!threats" class="tab-content-loader-container">
						<loader height="100" width="100" style="opacity: 0.8;"></loader>
					</div>
					<div class="content-container flex-column gap-3" *ngIf="threats">
						<!--Attachments Whitelist-->
						<div class="flex-column gap-3">
							<div class="flex-column gap-2">
								<div class="flex-row align-items-center gap-1">
									<i class="fa fa-check-circle text-success" style="font-size: 0.7rem;"></i>
									<b style="font-size: 0.845rem;">Attachments Allowlist</b>
								</div>
								<span class="text-muted lh-base" style="font-size: 0.9em;">Files from this list will always be considered safe</span>
							</div>
							<trustifi-table-c
									ngfDrop
									multiple="1"
									[(files)]="whitelistFiles"
									(fileChange)="uploads(whitelistFiles, true)"
									[(validDrag)]="validDrag"
									[(invalidDrag)]="invalidDrag"
									[ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag}"
									class="trustifi-table" style="z-index: 5; height: max(330px,25vw);"
									[list]="threats?.attachments_whitelist"
									[headers]="[
										{text: 'Name', sortKey: 'name'},
										{text: 'Hash' },
										{text: 'Updated by', width: '20%' },
										{text:'Created', sortKey:'created', width: '130px'},
									]"
									[cellsPrototype]="[
										{html: 'whitelistAttachmentNameCell', edit: {html: 'whitelistAttachmentNameCellEdit'}},
										{textKey: 'hash'},
										{textKey: 'updated_by'},
										{textKey: 'created', textType: 'dateAndTime'},
									]"
									[bulkActions] = "{
										enable: true,
										selectBulkActionCb:	selectMultipleAttachmentsWhitelistAction,
										initBulkActionsFunction: showAttachmentsWhitelistBulkActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
									[cellActions] = "{
										enable: true,
										isSmaller: true,
										loadingTableFlag: loadingAllTablesInProcess,
										selectItemActionCb:	selectAttachmentsWhitelistAction,
										initActionsFunction: showAttachmentsWhitelistActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
									(searchFunction)="searchAttachmentsWhitelist($event.searchTerm, $event.activeFilters)"
									(confirmEditFunction)="confirmNewWhitelistAttachment($event.item, $event.isApproved)"

									[options]="{
									    importFromCsv : {cbFunction : uploadAttachmentsWhitelistFromFile, tooltip: 'A column named \'hash\' should contain the hashes'},
										exportToCsvFunction: exportAttachmentsWhitelistToCsv,
										itemsNameSingular: 'attachment',
										defaultSortKey: '-created',
									}"
							>
								<!--attachment name cells-->
								<ng-template #customCells let-item let-cellId="cellId">
									<!--action cells-->
									<div *ngIf="cellId === 'whitelistAttachmentNameCell'">
										<div class="flxRow hSpace05 ellipsis">
											<span style="display: block;" tooltip="{{item.name}}">{{item.name}}</span>
											<span class="ellipsis" style="min-width: 80px;" *ngIf="item.size">({{item.size | FormatBytes: 0}})</span>
										</div>
									</div>
									<div *ngIf="cellId === 'whitelistAttachmentNameCellEdit'" class="flxRow hSpace05 h100" style="overflow: visible;">
										 <input type="text"
												[(ngModel)]="item.edit['name']"
												placeholder="Attachment Name"
												style="margin-bottom: 0; height: 100%; flex: 1 0;"
												(keydown.enter) = "confirmNewWhitelistAttachment($event.item, $event.isApproved)">
										<input type="text"
											   [(ngModel)]="item.edit['hash']"
											   placeholder="SHA-256 or MD5"
											   style="margin-bottom: 0; margin-left: 5px; height: 100%; flex: 1 0;"
											   (keydown.enter) = "confirmNewWhitelistAttachment($event.item, $event.isApproved)">
									</div>
								</ng-template>
								<!---->
								<div tableButtons>
									<div class="btn-with-dropdown" style="width: 245px;">
										<btn-c (action)="startAddRecord(threats?.attachments_whitelist);"
											   endIcon="fa fa-plus"
											   [isDisabled]="threats?.attachments_whitelist?.isAddRecordActive">
											Add Allowlisted Attachment
										</btn-c>
										<app-dropdown-c [isLf]="true">
											<li class="ellipsis" style="text-transform: capitalize; text-align: left;"
												ngfSelect
												multiple="1"
												[(files)]="whitelistFiles"
												(fileChange)="uploads(whitelistFiles, true)">
												Add from my computer
											</li>
										</app-dropdown-c>
									</div>
								</div>
							</trustifi-table-c>
						</div>
						<br/>
						<!--Attachments Blocklist-->
						<div class="flex-column gap-3">
							<div class="flex-column gap-2">
								<div class="flex-row align-items-center gap-1">
									<i class="fa fa-times-circle text-danger" style="font-size: 0.7rem;"></i>
									<b style="font-size: 0.845rem;">Attachments Blocklist</b>
								</div>
								<span class="text-muted lh-base" style="font-size: 0.9em;">Files from this list will always be considered malicious</span>
							</div>
							<trustifi-table-c
									ngfDrop
									multiple="1"
									[(files)]="blacklistFiles"
									(fileChange)="uploads(blacklistFiles, false)"
									[(validDrag)]="validDrag2"
									[(invalidDrag)]="invalidDrag2"
									[ngClass]="{'drag-and-drop-hover': validDrag2, 'dragOverClassObj': validDrag2}"

									class="trustifi-table" style="z-index: 5; height: max(330px,25vw);"
									[list]="threats?.attachments_blacklist"
									[headers]="[
										{text: 'Name', sortKey: 'name'},
										{text: 'Hash' },
										{text: 'Updated by', width: '20%' },
										{text:'Created', sortKey:'created', width: '130px'},
									]"
									[cellsPrototype]="[
										{html: 'blacklistAttachmentNameCell', edit: {html:'blacklistAttachmentNameCellEdit'}},
										{textKey: 'hash'},
										{textKey: 'updated_by'},
										{textKey: 'created', textType: 'dateAndTime'},
									]"
									[bulkActions] = "{
										enable: true,
										selectBulkActionCb:	selectMultipleAttachmentsBlacklistAction,
										initBulkActionsFunction: showAttachmentsBlacklistBulkActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
									[cellActions] = "{
										enable: true,
										isSmaller: true,
										loadingTableFlag: loadingAllTablesInProcess,
										selectItemActionCb:	selectAttachmentsBlacklistAction,
										initActionsFunction: showAttachmentsBlacklistActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
									(searchFunction)="searchAttachmentsBlacklist($event.searchTerm, $event.activeFilters)"
									(confirmEditFunction)="confirmNewBlacklistAttachment($event.item, $event.isApproved)"
									[options]="{
									    importFromCsv : {cbFunction : uploadAttachmentsBlacklistFromFile, tooltip: 'A column named \'hash\' should contain the hashes'},
										exportToCsvFunction: exportAttachmentsBlacklistToCsv,
										itemsNameSingular: 'attachment',
										defaultSortKey: '-created',
								}"
							>
								<ng-template #customCells let-item let-cellId="cellId">
									<!--action cells-->
									<div *ngIf="cellId === 'blacklistAttachmentNameCell'">
										<div class="flxRow hSpace05 ellipsis">
											<span style="display: block;" tooltip="{{item.name}}">{{item.name}}</span>
											<span class="ellipsis" style="min-width: 80px;" *ngIf="item.size">({{item.size | FormatBytes: 0}})</span>
										</div>
									</div>
									<div *ngIf="cellId === 'blacklistAttachmentNameCellEdit'" class="flxRow hSpace05 h100" style="overflow: visible;">
										 <input type="text"
												[(ngModel)]="item.edit['name']"
												placeholder="Attachment Name"
												style="margin-bottom: 0; height: 100%; flex: 1 0;"
												(keydown.enter) = "confirmNewBlacklistAttachment($event.item, $event.isApproved)">
										<input type="text"
											   [(ngModel)]="item.edit['hash']"
											   placeholder="SHA-256 or MD5"
											   style="margin-bottom: 0; margin-left: 5px; height: 100%; flex: 1 0;"
											   (keydown.enter) = "confirmNewBlacklistAttachment($event.item, $event.isApproved)">
									</div>
								</ng-template>
								<!---->
								<div tableButtons>
									<div class="btn-with-dropdown" style="width: 245px;">
										<btn-c (action)="startAddRecord(threats?.attachments_blacklist);"
											   endIcon="fa fa-plus"
											   [isDisabled]="threats?.attachments_blacklist?.isAddRecordActive">
											Add Blocklisted Attachment
										</btn-c>
										<app-dropdown-c [isLf]="true">
											<li class="ellipsis" style="text-transform: capitalize; text-align: left;"
												ngfSelect
												multiple="1"
												[(files)]="blacklistFiles"
												(fileChange)="uploads(blacklistFiles, false)">
												Add from my computer
											</li>
										</app-dropdown-c>
									</div>
								</div>
							</trustifi-table-c>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
