import {
	AfterContentInit,
	AfterViewInit,
	Component,
	ContentChild,
	Directive,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';
import {NgControl} from '@angular/forms';
import _ from 'lodash';
import {GeneralService} from "../services/generalService";


// [style.display]="showThis ? 'block' : 'none'"
// [style.visibility]="showThis ? 'visible' : 'hidden'"
@Directive({ selector: '[shown]' })
export class ShownDirective {
    @Input() public shown: boolean;

    @HostBinding('attr.hidden')
    public get attrHidden(): string | null {
        return this.shown ? null : 'hidden';
    }
}

@Directive({
    selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {

    @Input() appAutoFocus: boolean;
    @Input() focusDelay: number;

    public constructor(private el: ElementRef) {}

    public ngAfterContentInit(): void {
        this.checkFocus(this.appAutoFocus);
    }

    public checkFocus(focus): void {
        if (focus) {
            setTimeout(() => {
                this.el.nativeElement.focus();
            }, this.focusDelay || 1);
        }
    }
}

// Select all text in input (or element) when the given flag turns on
@Directive({
    selector: '[selectAllText]'
})
export class SelectAllText implements OnChanges, OnInit {

    @Input() selectAllText: boolean;

    public constructor(private element: ElementRef) {}

    ngOnInit() {
        if (this.selectAllText) {
            this.selectAll();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.selectAllText) {
            this.selectAll();
        }
    }

    selectAll(): void {
		setTimeout(() => {
			this.element.nativeElement.focus();
			this.element.nativeElement.select();
		});
    }
}

/////
@Component({
	selector: 'explanation-c',
	template: '<i class="fa fa-question-circle" style="font-weight: 400; margin-left: 4px;" [tooltip]="tooltipText" [placement]="tooltipDirection"></i>',
})
export class ExplanationComponent {
	@Input() tooltipText;
	@Input() tooltipDirection?;
}

@Directive({
    selector: '[explanationC]'
})
export class Explanation implements OnInit {

    @Input('explanationC') explanation: string;
    @Input() explanationDirection: string;

	constructor(private element: ElementRef,
				private renderer: Renderer2,
				private viewContainerRef: ViewContainerRef) {
	}

    ngOnInit() {
        if (!this.explanation) {
            return;
        }

		const questionMarkRef = this.viewContainerRef.createComponent(ExplanationComponent);
		questionMarkRef.instance.tooltipText = this.explanation;
		questionMarkRef.instance.tooltipDirection = this.explanationDirection || 'top';

        this.renderer.appendChild(this.element.nativeElement, questionMarkRef.location.nativeElement);

        this.element.nativeElement.removeAttribute('ng-reflect-explanation'); // remove attribution for cleaner and readable rendered DOM
    }
}
/////

// check if label has overflown its container and shown with ellipsis and do action
// is-elps attribute should be given to the first child element of the closest static-width parent container
@Directive({
	selector: '[isElps]',
})
export class IsElps implements AfterViewInit {

	constructor(private elem:ElementRef) {
	}

	@Output() isElps = new EventEmitter<any>;

	ngAfterViewInit() {
		setTimeout(() => {
			this.isElps.emit(this.elem.nativeElement.offsetWidth < this.elem.nativeElement.scrollWidth);
		})
	}
}

@Directive({
    selector: '[ngInit]',
})
export class NgInit implements OnInit{
    @Output() ngInit = new EventEmitter();

    ngOnInit() {
        if(this.ngInit) {
            this.ngInit.emit();
        }
    }
}

@Directive({
    selector: '[appMaskPattern]'
})
export class MaskDirective {
    @Input()
    set appMaskPattern(value) {
        this.regExpr = new RegExp(value);
    }

    private _oldvalue = '';
    private regExpr: any;
    private control: NgControl;

    constructor(injector: Injector) {
        // this make sure that not error if not applied to a NgControl
        try {
            this.control = injector.get(NgControl)
        }
        catch (e) {
        }
    }

    @HostListener('input', ['$event'])
    change($event) {

        const item = $event.target
        const value = item.value;
        let pos = item.selectionStart; // get the position of the cursor
        const noMatch: boolean = (value && !(this.regExpr.test(value)));
        if (noMatch) {
            item.selectionStart = item.selectionEnd = pos - 1;
            if (item.value.length < this._oldvalue.length && pos === 0)
                pos = 2;
            if (this.control)
                this.control.control.setValue(this._oldvalue, { emit: false });

            item.value = this._oldvalue;
            item.selectionStart = item.selectionEnd = pos - 1; // recover the position
        }
        else
            this._oldvalue = value;
    }
}

@Directive({
    selector: '[scrollToTopFlag]'
})
export class ScrollToTopFlagDirective implements OnChanges{

    @Input() scrollToTopFlag;
    @Output() scrollToTopFlagChange = new EventEmitter();

    constructor(public el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges) {
		setTimeout(() => {
			if (this.scrollToTopFlag === true) {
				this.el.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
				this.scrollToTopFlagChange.emit(false);
			}
		});
    }
}

@Directive({
	selector: '[highlightText]'
})
export class HighlightTextDirective implements OnChanges {
	@Input() highlightText: string | undefined;
	@Input() isHighlightOn: boolean | undefined;

	private originalHtml: string = '';

	constructor(private el: ElementRef) {}

	ngOnChanges(): void {
		setTimeout(() => {
			this.highlight(this.el.nativeElement.innerHTML);
		});
	}

	private highlight(originalHTML: string): void {
		try {
			if (this.isHighlightOn && this.highlightText) {
				let text = originalHTML;

				// use the current innerHTML if originalHtml contains template bindings
				if (this.originalHtml.includes('{{')) {
					this.originalHtml = this.el.nativeElement.innerHTML;
					text = this.originalHtml;
				}

				const regex = new RegExp('(?![^<]*>|[^<>]*</)' + this.highlightText, 'ig');
				text = text.replace(regex, (replacedStr) => `<span class="highlight-text">${replacedStr}</span>`);
				this.el.nativeElement.innerHTML = text;
			} else {
				this.el.nativeElement.innerHTML = this.originalHtml || originalHTML;
			}
		}
		catch {}
	}
}

@Directive({
	selector: '[focusMe]'
})
export class FocusMeDirective implements OnChanges {
	@Input() focusMe: { focus: boolean, scroll?: boolean };

	constructor(private el: ElementRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.focusMe && this.focusMe) {
			setTimeout(() => {
				if (this.focusMe.focus) {
					this.el.nativeElement.focus();
				}

				if (this.focusMe.scroll) {
					const scrollOffset = window.pageYOffset || (document.documentElement && document.documentElement.scrollTop) >= 120 ? 120 : 0;
					window.scrollTo(0, this.el.nativeElement.offsetTop - scrollOffset);
				}
			});
		}
	}
}

////
@Component({
	selector: 'content-tooltip-template',
	template: '<div class="Ctooltip"><ng-container *ngTemplateOutlet="content"></ng-container></div>'
})
export class ContentTooltipComponent {
	@Input() content: TemplateRef<any>;
}
@Directive({
	selector: '[contentTooltip]'
})
export class ContentTooltipDirective implements OnDestroy{

	isMouseOverTooltip;
	tooltipElement;

	@ContentChild('contentToolTipTemplate', { static: true }) contentTemplate: TemplateRef<any>;

	constructor(private elementRef: ElementRef,
				private renderer: Renderer2,
				private viewContainerRef: ViewContainerRef) {

		// Listen to tooltip's parent mouse events
		const parentElement = this.elementRef.nativeElement.parentElement;

		this.renderer.listen(parentElement, 'mouseenter', () => {
			if (!this.tooltipElement) {
				this.showTooltip();
			}
		});

		this.renderer.listen(parentElement, 'mouseleave', () => {
			setTimeout(() => {
				if (!this.isMouseOverTooltip) {
					this.hideTooltip();
				}
			}, 90); // Delay can be adjusted
		});
	}

	showTooltip = () => {
		const tooltipComponentRef = this.viewContainerRef.createComponent(ContentTooltipComponent);
		tooltipComponentRef.instance.content = this.contentTemplate;

		this.tooltipElement = tooltipComponentRef.location.nativeElement.firstChild;

		// Position the tooltip element atop it's parent element
		const hostPos = this.elementRef.nativeElement.getBoundingClientRect();
		this.renderer.setStyle(this.tooltipElement, 'top', `${hostPos.top}px`);
		this.renderer.setStyle(this.tooltipElement, 'left', `${hostPos.left}px`);

		// Listen to mouse leave/enter to the tooltip itself
		this.renderer.listen(this.tooltipElement, 'mouseenter', () => {
			this.isMouseOverTooltip = true;
		});
		this.renderer.listen(this.tooltipElement, 'mouseleave', () => {
			this.hideTooltip();
			this.isMouseOverTooltip = false;
		});

		document.body.appendChild(this.tooltipElement);
	}


	hideTooltip = () => {
		if (this.tooltipElement) {
			document.body.removeChild(this.tooltipElement)
			this.tooltipElement = null;
		}
	}

	ngOnDestroy() {
		this.hideTooltip();
	}
}
////


////
@Component({
	selector: 'auto-complete-menu',
	template: `<div class="autocomplete-drop-menu" [ngClass]="{'active' : showMenu}">
                    <ul (clickOutside)="showMenu = false;"
						(keyup.arrowDown)="navigateInAutoCompleteResults('down')"
						(keyup.arrowUp)="navigateInAutoCompleteResults('up')">
						<!--list-->
						<ng-container *ngIf="!customListTemplate">
							<li class="flex-row align-items-center"
								tabindex="0"
								*ngFor="let item of filteredItems; trackBy: gs.trackByIndex"
								[highlightText]="searchTerm"
								[isHighlightOn]="true"
								(keyup.enter)="showMenu = false; onItemClick.emit({item});"
								(click)="showMenu = false; onItemClick.emit({item});">
								<span>{{item[displayKey] || item}}</span>
							</li>
						</ng-container>
						<!--custom list-->
						<ng-container
							*ngIf="customListTemplate"
							[ngTemplateOutlet]="customListTemplate"
							[ngTemplateOutletContext]="{$implicit: filteredItems}">
						</ng-container>

						<!--add new item-->
						<li class="focused" style="font-weight: bold;" tabindex="0"
							*ngIf="newItem"
							(click)="showMenu = false; onItemClick.emit({item: newItem});"
							(keyup.enter)="showMenu = false; onItemClick.emit({item: newItem});">
							<span>{{newItem}}</span>
						</li>
                    </ul>
                </div>`
})
export class AutoCompleteMenuComponent implements OnChanges, OnInit{
	@Input() autoCompleteItems;
	@Input() searchByKeys;
	@Input() displayKey;
	@Input() searchTerm;
	@Input() inputElementRef:HTMLInputElement;
	@ContentChild('customListTemplate', {static: false}) customListTemplate: TemplateRef<any>;
	@Output() onItemClick = new EventEmitter<any>;

	// Even though the search term was not found in any item, show it as is in results IF SUCCEED IN VALIDATION
	// showInputTextAsNewItemWhen is a validation function that returns boolean)
	@Input() showInputTextAsNewItemWhen;

	filteredItems = [];
	newItem = '';
	showMenu = false;

	constructor(private el: ElementRef, public gs:GeneralService) {
	}

	ngOnInit() {
		if (this.inputElementRef) {
			this.inputElementRef.addEventListener("keyup", (event) => {
				switch (event.key) {
					case 'ArrowUp':
						this.navigateInAutoCompleteResults('up');
						break;
					case 'ArrowDown':
						this.navigateInAutoCompleteResults('down');
						break;
					case 'Enter':
						if (this.showMenu) {
							event.preventDefault();
							this.onItemClick.emit({item: this.filteredItems[0]});
						}
						break;
					case 'Escape':
						this.showMenu = false;
						break;
				}
			});
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.filterItems();

		this.showMenu = this.searchTerm && (!!this.filteredItems.length || !!this.newItem);
	}

	filterItems = () => {
		if (!this.searchTerm) {
			this.filteredItems = [];
			this.newItem = null;
			return;
		}

		if (this.searchByKeys) {
			let objectKeysToSearchIn = this.searchByKeys.split(',');
			objectKeysToSearchIn = _.map(objectKeysToSearchIn, key => key.trim());

			this.filteredItems = _.filter(_.cloneDeep(this.autoCompleteItems), item => {
				const relevantValues = _.values(_.pick(item, objectKeysToSearchIn));
				return _.some(relevantValues, val => val && val.toLowerCase().includes((this.searchTerm || '').toLowerCase()));
			});
		}
		else {
			this.filteredItems = _.filter(this.autoCompleteItems, item => item && item.toLowerCase().includes((this.searchTerm || '').toLowerCase()));
		}

		if (!this.filteredItems.length && this.showInputTextAsNewItemWhen) {
			if (this.showInputTextAsNewItemWhen(this.searchTerm)) {
				this.newItem = this.searchTerm;
			}
		}
		else {
			this.newItem = null;
		}

		if (this.filteredItems.length) {
			setTimeout(() => {
				const itemsInDOM = this.el.nativeElement.querySelectorAll('li');
				itemsInDOM.forEach((listItemEl, index) => {
					if (index === 0 && !listItemEl.classList.contains('focused')) {
						listItemEl.classList.add('focused');
						return;
					}
					else if (index !== 0) {
						listItemEl.classList.remove('focused');
					}
				});
			});
		}
	}

	// done by document.getElement because
	navigateInAutoCompleteResults = (direction) => {
		const currentFocusedListItem = _.find(this.el.nativeElement.querySelectorAll('li'), itemEl => itemEl.classList.contains('focused'));
		let nextItem, previousItem;

		if (currentFocusedListItem) {
			nextItem = currentFocusedListItem.nextElementSibling || currentFocusedListItem;
			previousItem = currentFocusedListItem.previousElementSibling || currentFocusedListItem;
		}

		const navigateToItem = direction === 'up' ? previousItem : nextItem;

		currentFocusedListItem.classList.remove('focused');
		navigateToItem.classList.add('focused');
		navigateToItem.focus();

	}

}

@Directive({
	selector: '[type="number"]' // Apply directive to all elements with type="number"
})
export class inputNumberDirective {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('input', ['$event']) onInput(event: Event) {
		const input = event.target as HTMLInputElement;
		input.value = input.value.replace(/[^0-9.]/g, '');// Remove all characters except numbers;

		if (this.isValidNumber(input.value, input.min, input.max)) {
			this.renderer.removeClass(this.el.nativeElement, 'validation-error');
		}
		else {
			this.renderer.addClass(this.el.nativeElement, 'validation-error');
		}
	}
	private isValidNumber(value: string, min, max): boolean {
		const number = parseFloat(value);
		if (isNaN(number)) return false;
		if (min && number < parseFloat(min)) return false;
		if (max && number > parseFloat(max)) return false;
		return true;
	}
}

@Directive({
	selector: '[appDropdownPosition]'
})
export class DropdownPositionDirective {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('click', ['$event'])
	onClick(event: Event) {
		const dropdownEl = event.currentTarget as HTMLElement;
		setTimeout(() => {
			const dropdownMenu = dropdownEl.querySelector('.app-dropdown-menu-container') as HTMLElement;
			if (dropdownMenu) {
				const menuSize = dropdownMenu.offsetHeight || this.calculateMenuSize(dropdownMenu);
				const direction = this.determineDropdownDirection(dropdownEl, menuSize);
				if (direction === 'above') {
					this.renderer.addClass(this.el.nativeElement, 'show-above');
				} else {
					this.renderer.removeClass(this.el.nativeElement, 'show-above');
				}
			}
		});
	}

	calculateMenuSize(dropdownMenu: HTMLElement): number {
		const itemElements = dropdownMenu.querySelectorAll('li');
		const itemHeight = itemElements.length ? itemElements[0].offsetHeight : 36;
		return itemElements.length * itemHeight;
	}

	determineDropdownDirection(triggerButton: HTMLElement, dropdownSize: number): string {
		const boundaryElement = triggerButton.closest('.listTable-c');
		if (!boundaryElement) {
			return 'below';
		}

		const boundaryElementPosition = boundaryElement.getBoundingClientRect();
		const triggerButtonPosition = triggerButton.getBoundingClientRect();

		let distanceFromTopBoundary = triggerButtonPosition.top - (boundaryElementPosition.top + 55);
		if (boundaryElementPosition.top + 55 < 0) {
			distanceFromTopBoundary = triggerButtonPosition.top;
		}

		const distanceFromBottomBoundary = boundaryElementPosition.bottom - triggerButtonPosition.bottom;

		if (distanceFromBottomBoundary >= dropdownSize) {
			return 'below';
		} else if (distanceFromTopBoundary >= dropdownSize) {
			return 'above';
		}

		return 'below';
	}

}
@Directive({
	selector: 'input, textarea' // Apply directive to all input elements
})

export class DisableAutoCOmpleteDirective implements OnInit {

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	random = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 12);

	ngOnInit(): void {
		/*if (!this.el.nativeElement.getAttribute('autocomplete')) {
			this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', this.random);
		}*/

		this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', 'off');
	}
}
