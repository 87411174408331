<popup-c class="bs-enabled" width="1200px"
	   head="Outbound Rule Usage Data" size="large"
	   subTitle="<b>Rule name:</b> {{data.ruleObj.name}}"
	   (closeCb)="closeModal();">
	<div class="flex-column f-1-0 gap-4" style="overflow: auto;">
		<div class="flex-row gap-4 align-items-center">
			<!--period selection-->
			<div class="period-buttons-group flxRow hSpace1">
				<btn-c
					variant="{{usagePeriod.value !== periodObj.value ? 'outlined' : ''}}"
					*ngFor="let periodObj of trendsPeriod"
					[isDisabled]="getRuleUsageInProcess"
					(action)="changePeriod(periodObj);">
					{{periodObj.display}}
				</btn-c>
			</div>
			<div class="vertical-separator opacity-25"></div>
			<!--list/graph selection-->
			<div class="period-buttons-group align-self-center flex-row gap-2 animated fadeIn">
				<button class="period-button bs-btn bs-btn-light text-capitalize"
						*ngFor="let type of viewTypes | keyvalue: gs.returnZero;"
						[ngClass]="{'chosen' : type.value === viewType}"
						(click)="changeViewType(type.value);"
						tooltip="{{type.value}}">
					<i style="height: 20px; width: 20px; font-size: 1.1rem; margin-left: 2px;"
					   [ngClass]="type.value === viewTypes.list ? 'fas fa-list' : 'fas fa-chart-pie'"></i>
				</button>
			</div>

			<div *ngIf="getRuleUsageInProcess" class="loading-spinner" style="width: 20px; height: 20px; border-width: 3px;"></div>

		</div>
		<div class="flex-column f-1-0 animated fadeIn" style="overflow: auto;" *ngIf="viewType === viewTypes.list">
			<trustifi-table-c
				class="trustifi-table" style="flex: 1 0; z-index: 5;"
				[list]="emails"
				[headers]="[
					{text:'Subject', sortKey:'sent.title'},
					{text:'From', sortKey:'from.email'},
					{text:'Sent', sortKey:'created'},
					{text:'Sensitivity', sortKey:'sensitivity.score'},
					{text:'Message ID'},
				]"
				[cellsPrototype]="[
					{textKey: 'sent.title'},
					{textKey: 'from.email'},
					{textKey: 'created', textType: 'dateAndTime'},
					{html: 'sensitivityCell'},
					{html: 'messageIdCell'},
				]"
				[scopeInstances]="[{scopeFunctions: scopeFunctions}]"
				(searchFunction)="searchRuleEmail($event.searchTerm)"
				[options]="{
					exportToCsvFunction: exportRuleEmailsToCsv,
					defaultSortKey: '-created',
					itemsNameSingular: 'email',
				}"
			>
				<ng-template #customCells let-item let-cellId="cellId">
					<div *ngIf="cellId === 'sensitivityCell'">
						<span>
						<input *ngIf="item.sensitivity && item.sensitivity.score"
							   (click)="scopeFunctions.openSensitiveInfoPopup(item)" type="button"
							   [value]="item.sensitivity.score"
							   [ngStyle]="item.sensitivity.score < 4 && {'background':'darkorange'} || item.sensitivity.score < 5 && {'background':'#FF851B'} || item.sensitivity.score === 5 && {'background':'#FF4136'}"
							   class="redborder fa"
							   style="background: orange; height: 25px; width: 25px; margin: 0 auto; color: white !important; border: 0;"
							   tooltip="{{item.sensitivity.sensitive_sub_type}}">
						<input *ngIf="!item.sensitivity || item.sensitivity.score === 0" type="button" value="0"
							   (click)="scopeFunctions.openSensitiveInfoPopup(item)" type="button"
							   class="redborder fa"
							   style="background: limegreen; height: 25px; width: 25px; margin: 0 auto; color: white !important; border: 0;"
							   tooltip="Not sensitive">
					</span>
					</div>

					<div *ngIf="cellId === 'messageIdCell'">
						<div class="flex-row align-items-center cursor-pointer gap-1 copy-link"
							 (click)="scopeFunctions.copyEmailIdToClipboard(item)">
							<i class="fas fa-copy" style="cursor: pointer;"></i>
							<span style="font-size: 11px;">Copy ID</span>
						</div>
					</div>
				</ng-template>
				<!---->

				<div tableButtons>
					<span style="font-size: 0.95rem;">List of the emails detected by this rule in the {{usagePeriod.display | lowercase}}</span>
				</div>
			</trustifi-table-c>
		</div>

		<div class="flex-column f-1-0 position-relative animated fadeIn bg-light overflow-auto" *ngIf="viewType === viewTypes.chart">

			<div class="bs-container f-1-0 flex-column bg-light border overflow-auto">
				<div class="bs-row h-100 f-1-0 overflow-auto">
					<!--Emails-->
					<div class="bs-col-6 p-2 h-100">
						<div class="trends-data-card flex-column gap-1 p-3 w-100">
							<div class="flex-column align-items-start gap-1">
								<h6 class="m-0 fw-bold">Total amount of emails detected by this rule</h6>
							</div>
							<div class="flex-column centralize f-1-0 p-3">
								<apx-chart class="f-1-0 animated fadeIn align-self-stretch"
										   style="z-index: 40;"
										   *ngIf="emailDetectedByRuleChartOptions?.xaxis?.categories?.length"
										   #emailsDetectedChart
										   [series]="emailDetectedByRuleChartOptions.series"
										   [chart]="emailDetectedByRuleChartOptions.chart"
										   [dataLabels]="emailDetectedByRuleChartOptions.dataLabels"
										   [colors]="emailDetectedByRuleChartOptions.colors"
										   [plotOptions]="emailDetectedByRuleChartOptions.plotOptions"
										   [fill]="emailDetectedByRuleChartOptions.fill"
										   [stroke]="emailDetectedByRuleChartOptions.stroke"
										   [xaxis]="emailDetectedByRuleChartOptions.xaxis"
										   [yaxis]="emailDetectedByRuleChartOptions.yaxis"></apx-chart>
								<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!emails?.length">
									<div class="flex-column text-center centralize f-1-0">
										<span class="fw-500 opacity-75" *ngIf="!getRuleUsageInProcess">No data for selected time period</span>
										<loader class="opacity-75 align-self-center" *ngIf="getRuleUsageInProcess" height="100" width="100"></loader>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="bs-col-6 p-2 flex-column gap-2 h-100">
						<!--Senders-->
						<div class="trends-data-card flex-column gap-1 p-3 w-100 f-1-0">
							<div class="flex-column align-items-start gap-1">
								<h6 class="m-0 fw-bold">Results sorted by top senders</h6>
							</div>
							<div class="flex-column centralize f-1-0 p-3">
								<apx-chart class="f-1-0 animated fadeIn align-self-stretch"
										   *ngIf="senderGraphOptions?.xaxis?.categories?.length"
										   #topSendersChart
										   [series]="senderGraphOptions.series"
										   [chart]="senderGraphOptions.chart"
										   [dataLabels]="senderGraphOptions.dataLabels"
										   [colors]="senderGraphOptions.colors"
										   [plotOptions]="senderGraphOptions.plotOptions"
										   [xaxis]="senderGraphOptions.xaxis"
										   [yaxis]="senderGraphOptions.yaxis"></apx-chart>
								<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!emails?.length">
									<div class="flex-column text-center centralize f-1-0">
										<span class="fw-500 opacity-75" *ngIf="!getRuleUsageInProcess">No data for selected time period</span>
										<loader class="opacity-75 align-self-center" *ngIf="getRuleUsageInProcess" height="100" width="100"></loader>
									</div>
								</div>
							</div>
						</div>

						<!--Sensitivity-->
						<div class="trends-data-card flex-column gap-1 p-3 w-100 f-1-0">
							<div class="flex-column align-items-start gap-1">
								<h6 class="m-0 fw-bold">Results sorted by sensitivity types</h6>
							</div>
							<div class="flex-column centralize f-1-0 p-3">
								<apx-chart class="pie-chart f-1-0 animated fadeIn align-self-stretch"
										   style="max-height: 215px;"
										   *ngIf="sensitivityGraphOptions?.labels?.length"
										   #topSensitivityChart
										   [series]="sensitivityGraphOptions.series"
										   [chart]="sensitivityGraphOptions.chart"
										   [dataLabels]="sensitivityGraphOptions.dataLabels"
										   [plotOptions]="sensitivityGraphOptions.plotOptions"
										   [legend]="sensitivityGraphOptions.legend"
										   [labels]="sensitivityGraphOptions.labels"></apx-chart>
								<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!sensitivityGraphOptions?.labels?.length">
									<div class="flex-column text-center centralize f-1-0">
										<span class="fw-500 opacity-75" *ngIf="!getRuleUsageInProcess">No data for selected time period</span>
										<loader class="opacity-75 align-self-center" *ngIf="getRuleUsageInProcess" height="100" width="100"></loader>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</popup-c>

<sensitivity-details
	*ngIf="showSensitiveInfoPopup"
	[type]="'email'"
	[origin]="'rules'"
	[scanItemObj]="showSensitiveInfoPopup.email"
	(cancelCb)="closeSensitiveInfoPopup()"
	[scanTitle]="showSensitiveInfoPopup.email.sent.title"
	[sensitivity]="showSensitiveInfoPopup.sensitivity">
</sensitivity-details>
