import {Component} from "@angular/core";
import {TabsPageContainer} from "../../../uiComponents/tabsPageContainer";

@Component({
	selector: 'outbound-plan-tabs',
	templateUrl: './outbound-plan-tabs.component.html',
})
export class OutboundPlanTabsComponent extends TabsPageContainer {
	tabWidth = 180;
}
