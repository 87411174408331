import _ from 'lodash';
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {RouteService} from "../../services/routeService";
import {ClioService} from "../../services/clioService";
import {DropboxService} from "../../services/dropboxService";
import {GeneralService} from "../../services/generalService";
import {NotificationService} from "../../services/notificationService";

@Component({
	selector: 'attachments-manager-c',
	templateUrl: './attachments-manager.component.html',
})
export class AttachmentsManagerComponent implements OnInit {
	@Input() preselectedAttachments;
	@Output() doneCb = new EventEmitter<any>;
	@Output() closeCb = new EventEmitter<any>;

	_ = _;
	attachments = [];
	clio:any = {};
	dropbox:any = {};
	userInfo;
	getAttachmentsInProcess;

	constructor(private rs:RouteService,
				private gs:GeneralService,
				private ns:NotificationService,
				private clioService:ClioService,
				private dropboxService:DropboxService) {}

	ngOnInit() {
		this.gs.getUserInfo(false, (userInfo) => {
			this.userInfo = userInfo;
		});

		// clio and dropbox
		if (this.userInfo.integrations && this.userInfo.integrations.clio && this.userInfo.integrations.clio.enabled) {
			this.clio = this.userInfo.integrations.clio;
		}

		if (this.userInfo.integrations && this.userInfo.integrations.dropbox && this.userInfo.integrations.dropbox.enabled) {
			this.dropbox = this.userInfo.integrations.dropbox;
		}

		this.getAttachments();
	}

	getAttachments = () =>{
		this.getAttachmentsInProcess = true;

		this.rs.getAttachment('').then( (response) => {
			response.forEach(itm => {
				try {
					itm.name = decodeURIComponent(itm.name);
				} catch (e) {
					console.log('Could not decode: ' + itm.name);
				}
			});

			this.attachments = response;

			if (this.clio.enabled) {
				this.clioService.getalldocs( (err, data) => {
					if (!err && data) {
						data.forEach(itm => {
							try {
								itm.name = decodeURIComponent(itm.name);
							} catch (e) {
								console.log('Could not decode: ' + itm.name);
							}
							itm.is_clio = true;
						});
						this.attachments = this.attachments.concat(data);
					}
					else {
						this.clio.error = err;
						//this.clio.error.display_bar = true;
					}
				});
			}

			if (this.dropbox.enabled) {
				this.dropboxService.getalldocs( (err, data) => {
					if (!err && data) {
						data.forEach(itm => {
							try {
								itm.name = decodeURIComponent(itm.name);
							} catch (e) {
								console.log('Could not decode: ' + itm.name);
							}
							itm.is_dropbox = true;
						});
						this.attachments = this.attachments.concat(data);
					}
					else {
						this.dropbox.error = err;
						//this.dropbox.error.display_bar = true;
					}
				});
			}

			// preselected attachments
			this.attachments.forEach(attachment => {
				attachment.selected = !!_.find(this.preselectedAttachments, {_id: attachment._id});
			});

			this.getAttachmentsInProcess = false;

		}, (err) => {
			this.getAttachmentsInProcess = false;

			if (err && err.data && err.data.message && !err.data.display_bar) {
				this.ns.showErrorMessage(err.data.message);
			}
		});
	};

	searchAttachment = (searchTerm, activeFilters) => {
		this.attachments.forEach(attachment => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(attachment, searchTerm);
				if (!isFound) {
					attachment.hide = true;
					return;
				}
			}

			attachment.hide = false;
		});
	}

	deselectSingleAttachment = (attachmentObj) => {
		attachmentObj.selected = false;

		// trigger the trustifi-table search by changing the list. The directive detects changes and fires a search
		// needed to update the "select all" checkbox state
		this.attachments = _.cloneDeep(this.attachments);
	}
}

function searchTextExecute(attachment, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((attachment.name.toLowerCase().indexOf(searchTerm) > -1));
}


