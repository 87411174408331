import {Component, Input} from '@angular/core';

@Component({
  selector: 'settings-list-item',
  templateUrl: './settings-list-item.component.html',
  styleUrls: ['./settings-list-item.component.scss']
})
export class SettingsListItemComponent {

  @Input() mainTitle: string;
  @Input() subTitle?: string;

}
