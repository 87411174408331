import * as _ from 'lodash';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'popup-c',
  templateUrl: './popup-frame.component.html',
  styleUrls: ['./popup-frame.component.scss']
})
export class PopupFrameComponent implements OnInit{
  @Input() head: string;
  @Input() subTitle: string;
  @Input() width: string;
  @Input() maxWidth: string;
  @Input() maxHeight: string;
  @Input() height: string;
  @Input() size: string; // 'fullscreen' | 'large' | 'medium' | 'small' | dynamic
  @Output() closeCb = new EventEmitter();
  @Output() confirmCb = new EventEmitter();
  @Input() buttons; // button text | {text : string, loading : boolean, isDisabled : boolean}

  _ = _;
  contentMaxHeight = 'none';
  initiated = false;

  ngOnInit() {
    // in any case that the 'height' of the popup is 'auto' - give the content-container max height (responsiveness in short monitors)
    if ((this.size && this.size === 'dynamic') || (this.height && this.height === 'auto')) {
      // paddings before popup: 5vh*2 + 10px*2 /// inner padding: 25px*2 /// title: 51px /// subtitle: 22px /// buttons section: 35px
      this.contentMaxHeight = 'calc(100vh - 5vh - 5vh - 10px - 10px - 25px - 25px' + (this.head ? this.subTitle ? ' - 73px' : ' - 51px' : '') + (this.buttons ? ' - 35px' : '') + ')'
    }

	this.initiated = true;
  }
}
