import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, Input, OnInit} from "@angular/core";
import {ArchiveService} from "../../../services/archiveService";

@Component({
	selector: 'archive-email-tracking-component',
	templateUrl: './archive-email-tracking.component.html',
})
export class ArchiveEmailTrackingComponent implements OnInit {
    constructor(private rs:RouteService,
				public gs:GeneralService,
				public archiveService:ArchiveService){
    }

	@Input() origin: string;

    dic = DICTIONARY;
    getTrackingDataInProcess = false;
    isLoaded = false;
    isEmailNotFound;
    recipientTrack;
    changeMessageInProcess;
    emailActionState;
    trackEventShow;

	ngOnInit() {
        this.getTrackingData(this.archiveService.currentMessage, () => {});
    };

    getTrackingData(currentMessage, cb) {
        this.isEmailNotFound = false;
        this.getTrackingDataInProcess = true;

		if (this.origin === 'user') {
			this.rs.getEmailEvents(currentMessage._source.emailId).then((response) => {
				currentMessage.trackingData = response.tracking;
				currentMessage.emailInfo = response.email;
				this.getTrackingDataInProcess = false;
				if (currentMessage.trackingData.length) {
					this.changeTrackingRecipient(currentMessage.trackingData[0]);
				}
				cb();
			}, (err) => {
				this.getTrackingDataInProcess = false;
				if (err.data.message === "email not found") {
					this.isEmailNotFound = true;
				}
			});
		}
		else {
			this.rs.getArchiveEmailTracking(currentMessage._source.mailbox, {emailId: currentMessage._source.emailId}).then((response) => {
				currentMessage.trackingData = response.tracking;
				currentMessage.emailInfo = response.email;
				this.getTrackingDataInProcess = false;
				if (currentMessage.trackingData.length) {
					this.changeTrackingRecipient(currentMessage.trackingData[0]);
				}
				cb();
			}, (err) => {
				this.getTrackingDataInProcess = false;
				if (err.data.message === "email not found") {
					this.isEmailNotFound = true;
				}
			});
		}
    };

    doEmailAction(action) {
        switch (action) {
            case 'unblockRecipient':
            case 'blockRecipient':
                this.changeRecipientStatus(this.archiveService.currentMessage);
                break;

            case 'blockEmail':
                this.changeEmailStatus(this.archiveService.currentMessage, this.dic.CONSTANTS.recipientStatus.block);
                break;

            case "unblockEmail":
                this.changeEmailStatus(this.archiveService.currentMessage, this.dic.CONSTANTS.recipientStatus.unblock);
                break;

        }
    }

    changeRecipientStatus(currentMessage) {
        let actionButton = "";
        let title = "";
        let subtitle = "";
        if (this.recipientTrack.is_blocked || this.recipientTrack.is_access_blocked) {
            title = "Unblock Recipient";
            subtitle = "Please note - The following operations will be available for the recipient:";
            actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.unblock);
        }
        else {
            title = "Block Recipient";
            subtitle = "Please note - The following operations will be blocked for the recipient:";
            actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.block);
        }

        const body = emailOperations(currentMessage.emailInfo);

        this.gs.showPopup({
            title: title,
            subTitle: subtitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: actionButton,
            doneCb: () => {
                this.changeRecipientStatusExecute(currentMessage);
            }
        });
    };

    changeRecipientStatusExecute(currentMessage) {
        if (this.changeMessageInProcess) return;
        this.changeMessageInProcess = true;

        let action = this.recipientTrack.is_blocked || this.recipientTrack.is_access_blocked ? this.dic.CONSTANTS.recipientStatus.unblock : this.dic.CONSTANTS.recipientStatus.block;
        let prevRecipient = this.recipientTrack;

        this.rs.changeRecipientStatus(currentMessage._source.mailbox, currentMessage._source.emailId, this.recipientTrack.id, {action: action}).then((response) => {
            if (action === this.dic.CONSTANTS.recipientStatus.block) {
                this.recipientTrack.is_blocked = true;
            }
            else {
                this.recipientTrack.is_access_blocked = false;
                this.recipientTrack.is_blocked = false;
            }

            this.getTrackingData(currentMessage, () => {
                for (let idx = 0; idx < currentMessage.trackingData.length; idx++) {
                    if (prevRecipient.id === currentMessage.trackingData[idx].id) {
                        this.recipientTrack = currentMessage.trackingData[idx];
                        break;
                    }
                }
                this.changeMessageInProcess = false;
            });
        });
    };

    changeEmailStatus(currentMessage, action) {
        let actionButton = "";
        let title = "";
        let subtitle = "";
        if (action === this.dic.CONSTANTS.recipientStatus.unblock) {
            title = "Unblock Email's Recipients";
            subtitle = "Please note - The following operations will be available for all recipients:";
            actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.unblock);
        }
        else {
            title = "Block Email's Recipients";
            subtitle = "Please note - The following operations will be blocked for all recipients:";
            actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.block);
        }
        this.emailActionState = action;

        const body = emailOperations(currentMessage.emailInfo);

        this.gs.showPopup({
            title: title,
            subTitle: subtitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: actionButton,
            doneCb: () => {
                this.rs.changeRecipientStatus(currentMessage._source.mailbox, currentMessage._source.emailId, '', {action: action}).then((response) => {
                    this.getTrackingData(currentMessage, () => {
/*                        for (let idx = 0; idx < currentMessage.trackingData.length; idx++) {
                            if (prevRecipient.id === currentMessage.trackingData[idx].id) {
                                this.recipientTrack = currentMessage.trackingData[idx];
                                break;
                            }
                        }*/
                        this.changeMessageInProcess = false;
                    });
                });
            }
        });
    };

    changeTrackingRecipient = (newRecipient) => {
        this.recipientTrack = newRecipient;
        this.trackEventShow = -2;
    };
}

function emailOperations(email) {
    let actions = [];
    if (email.methods.secure_reply) {
        actions.push('Encrypted reply');
    }
    if (email.sent.attachments && email.sent.attachments.length && !email.skip_attachments_encryption) {
        actions.push('Download attachments');
    }
    if (email.methods.encrypt_content) {
        actions.push('Read email content');
    }
    return actions;
}

