import {Component, Input, OnInit} from "@angular/core";
import {GeneralService} from "../../../../services/generalService";
import {NotificationService} from "../../../../services/notificationService";
import { RouteService } from '../../../../services/routeService';
import _ from "lodash";


@Component({
	selector: 'threat-simulation-campaign-configuration-component',
	templateUrl: './threat-simulation-campaign-configuration.component.html',
})

export class ThreatSimulationCampaignConfigurationComponent implements OnInit {

	@Input() currentCampaign;
	@Input() configuration;

	constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
	}

	_=_;
	today = new Date();
	hours = ['00','01','02','03','04','05','06','07','08','09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
	minutes = ['00', '05','10','15','20','25','30','35','40','45','50','55'];

	configurationSettings: any = {};

	errMessage;
	updateInProcess = false;


	ngOnInit() {
		this.configurationSettings.expired_days = this.configuration.expired_days;
		this.configurationSettings.notify_on_link_clicked = this.configuration.notify_on_link_clicked;
		this.configurationSettings.enable_security_awareness_for_mailbox = this.configuration.enable_security_awareness_for_mailbox;
		this.configurationSettings.security_awareness_for_mailbox_expire_days = this.configuration.security_awareness_for_mailbox_expire_days;
		this.configurationSettings.send_report_when_completed = this.configuration.send_report_when_completed;
		this.configurationSettings.send_training_when_completed = this.configuration.send_training_when_completed;
		this.configurationSettings.scheduled_time_enabled = this.configuration.scheduled_time_enabled;

		if (this.configuration.scheduled_time) {
			const date = new Date(this.configuration.scheduled_time);
			this.configurationSettings.scheduled_time = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
		}

		// set Campaign pace
		if (this.configuration.rate_email_delivery_hours_duration) {
			this.configurationSettings.enable_rate_email_delivery_hours_duration = true;
			this.configurationSettings.rate_email_delivery_hours_duration = this.configuration.rate_email_delivery_hours_duration;
		}
	}

	updateConfigurationTypeStatus = (key) => {
		if (this.updateInProcess) return;
		if ((key === 'security_awareness_for_mailbox_expire_days' && !this.configurationSettings.enable_security_awareness_for_mailbox)
			|| this.configurationSettings.security_awareness_for_mailbox_expire_days > 365) return;

		const data = {
			action:'configurationUpdate',
			configuration_key: key,
			value: this.configurationSettings[key]
		};

		this.updateInProcess = true;

		this.rs.updateThreatSimulationCampaign(this.currentCampaign._id, data).then(() => {
			this.currentCampaign.configuration[key] = this.configurationSettings[key];

			this.updateInProcess = false;
			this.ns.showInfoMessage('Configuration settings saved successfully');
		}, (err) => {
			this.updateInProcess = false;
		});
	};

}
