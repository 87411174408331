<div class="flex-column gap-4">
	<div class="flex-column">
		<span>Choose which settings will be enabled by default for all emails (users can still modify them).</span>
		<span>Users can still choose to disable or modify these settings, unless "Strict" is applied.</span>
	</div>

	<div class="flxRow-center center-block" *ngIf="getPoliciesDataInProcess">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div class="list-group list-group-flush settings-list-container" *ngIf="userPolicy">
		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Encrypt Message Content</b>
				<span class="lh-base" style="font-size: 0.9em;">Encryption will be enabled for the content and attachments of sent emails</span>
			</div>
			<div class="content-container flex-column gap-3">
                <toggle-switch-c id="encryptMessageContentToggle"
                               [(model)]="userPolicy.encrypt_content.value"
                               [isDisabled]="userPolicy.encrypt_content.strict"
                               (onChange)="updatePolicy('encrypt_content', userPolicy.encrypt_content)"></toggle-switch-c>

				<div class="flex-column gap-2">
					<div class="flxRow-start md-checkbox align-items-center">
						<input id="encryptStrict" required="false" type="checkbox"
							   [(ngModel)]="userPolicy.encrypt_content.strict"
							   (ngModelChange)="updatePolicy('encrypt_content', userPolicy.encrypt_content)"/>
						<label class="m-0" for="encryptStrict" id="encryptStrictTrigger">Strict</label>
						<span *ngIf="userPolicy.encrypt_content.value && userPolicy.encrypt_content.strict" style="margin-left: 5px; color: #ff8e00;">(All outgoing emails will be encrypted)</span>
					</div>
					<div class="flxRow-start md-checkbox align-items-center" [ngClass]="{'disabled' : !userPolicy.encrypt_content.strict}">
						<input id="encryptAllowOverride" required="false" type="checkbox"
							   [disabled]="!userPolicy.encrypt_content.strict"
							   [(ngModel)]="userPolicy.encrypt_content.allow_user_revert"
							   (ngModelChange)="updatePolicy('encrypt_content', userPolicy.encrypt_content)"/>
						<label class="m-0" for="encryptAllowOverride" id="encryptStrictTrigger">Allow override in add-ins</label>
						<i class="fa fa-question-circle" style="margin-left: 5px; font-weight: 400;" tooltip="Outgoing emails will{{userPolicy.encrypt_content.value ? '' : ' not'}} be encrypted by policy. Allow users to override the strict encryption policy in Gmail/Outlook add-ins."></i>
					</div>
				</div>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Require Authentication</b>
				<span class="lh-base" style="font-size: 0.9em;">Recipients will need to authenticate their identity before they can access the email</span>
			</div>
			<div class="content-container flex-column gap-3">
                <toggle-switch-c id="requireAuthenticationToggle"
                               [(model)]="userPolicy.secure_send.value"
                               [isDisabled]="userPolicy.secure_send.strict"
                               (onChange)="updatePolicy('secure_send', userPolicy.secure_send)"></toggle-switch-c>
				<div class="flex-row align-items-center gap-4">
					<div class="flxRow-start md-checkbox align-items-center">
						<input id="requireAuthStrict" required="false" type="checkbox"
							   [(ngModel)]="userPolicy.secure_send.strict"
							   (ngModelChange)="updatePolicy('secure_send', userPolicy.secure_send)"/>
						<label class="m-0" for="requireAuthStrict" id="requireAuthStrictTrigger">Strict</label>
						<span *ngIf="userPolicy.secure_send.value && userPolicy.secure_send.strict" style="margin-left: 5px; color: #ff8e00;">(All outgoing emails will require authentication)</span>
					</div>
				</div>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Authentication Methods Allowed</b>
				<span class="lh-base" style="font-size: 0.9em;">Authentication for encrypted emails will be allowed only using the selected methods</span>
			</div>
			<div class="content-container flex-column gap-2">
				<label class="policyCheckbox" style="display: inline-flex; margin-bottom: 0;">
					<div *ngFor="let method of allowedAuthMethods">
						<label class="policyCheckbox" style="margin-right: 30px;">
							<input type="checkbox"
								   [(ngModel)]="method.selected"
								   (ngModelChange)="updateAuthMethods(method)"/>
							<span style="text-transform: capitalize;">{{method.name}}</span>
						</label>
					</div>
				</label>
				<label *ngIf="userPolicy?.allowed_auth_methods && !userPolicy?.allowed_auth_methods?.includes('email')" style="width: 350px; top: -10px; color: darkorange;">
					Warning: The "Email" method is used as a default in cases where the recipient does not have a registered phone number. If this method is disabled then on those cases, the email will fail to send, and the sender will be notified.
				</label>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Notify sender about blocked recipients</b>
				<span class="lh-base" style="font-size: 0.9em;">Senders will be notified when a recipient has been blocked from accessing an encrypted email</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="notify_sender_email_blocked"
								 [(model)]="userPolicy.notify_sender_email_blocked.value"
								 (onChange)="updatePolicy('notify_sender_email_blocked', userPolicy.notify_sender_email_blocked)"></toggle-switch-c>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Attachments Encryption Policy</b>
				<span class="lh-base" style="font-size: 0.9em;">To improve performance, all attachments larger than 40mb will automatically be encrypted. Opening encrypted attachments will not require any additional steps from the recipient.</span>
			</div>
			<div class="content-container flex-column gap-2">
				<label class="radio-container mb-0" style="display: block; width: fit-content;">Encrypt from sensitivity threshold
					<input type="radio" name="attachmentEncryption" [value]="dic.CONSTANTS.attachmentEncryption.sensitivity"
						   [(ngModel)]="userPolicy.attachment_encryption.value"
						   [checked]="userPolicy.attachment_encryption.value === dic.CONSTANTS.attachmentEncryption.sensitivity"
							(ngModelChange)="updateAttachmentEncryptPolicy()">
					<span class="checkmark" style="top: 7px;"></span>
					<input type="number" min="1" max="5" step="0.1"
						   pattern="/^[1-5]\.?[0-9]?$/"
						   style="opacity: 1; border: 1px solid #ccc; border-radius: 4px; padding: 5px; margin-left: 5px; position: relative;"
						   [(ngModel)]="userPolicy.attachment_encryption.threshold"
						   [disabled]="userPolicy.attachment_encryption.value !== dic.CONSTANTS.attachmentEncryption.sensitivity"
						   (ngModelChange)="updateAttachmentEncryptPolicy()"/>
					<i style="font-weight: 400; margin-left: 3px;" class="fas fa-question-circle" tooltip="Only attachments with sensitivity score equal or above the threshold will be encrypted automatically unless if an admin rule is triggered, or if an encryption method has been applied" placement="right"></i>
					<div style="font-size: 11px;">
						<span>
							For more information about the sensitivity engine click <a class="blue-link" [routerLink]="['/' + dic.CONSTANTS.appStates.knowledge, dic.CONSTANTS.supportPages.knowledgeBase]" [state]="{anchorId: 'Sensitivity_Engine'}">here</a>.
						</span>
					</div>
				</label>
				<label class="radio-container mb-0" style="display: block; width: fit-content;">Always encrypt attachments
					<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Attachments will always be encrypted regardless of the email's sensitivity score" placement="right"></i>
					<input type="radio" name="attachmentEncryption" [value]="dic.CONSTANTS.attachmentEncryption.always"
						   [(ngModel)]="userPolicy.attachment_encryption.value"
						   [checked]="userPolicy.attachment_encryption.value === dic.CONSTANTS.attachmentEncryption.always"
						   (ngModelChange)="updateAttachmentEncryptPolicy()">
					<span class="checkmark"></span>
				</label>
				<label class="radio-container mb-0" style="display: inline-block;">Never encrypt attachments
					<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Attachments will not be encrypted unless if an admin rule is triggered, or if an encryption method has been applied" placement="right"></i>
					<input type="radio" name="attachmentEncryption" [value]="dic.CONSTANTS.attachmentEncryption.never"
						   [(ngModel)]="userPolicy.attachment_encryption.value"
						   [checked]="userPolicy.attachment_encryption.value === dic.CONSTANTS.attachmentEncryption.never"
						   (ngModelChange)="updateAttachmentEncryptPolicy()">
					<span class="checkmark"></span>
				</label>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Allow Admin Policies Only</b>
				<span class="lh-base" style="font-size: 0.9em;">Users will not be able to set any protection methods or advanced settings. Only admin policies will apply</span>
			</div>
			<div class="content-container flex-row gap-2 align-items-center">
                <toggle-switch-c id="allow_admin_policy_only"
                               [(model)]="userPolicy.allow_admin_policy_only.value"
                               [isDisabled]="userPolicy.allow_admin_policy_only.strict"
                               (onChange)="updatePolicy('allow_admin_policy_only', userPolicy.allow_admin_policy_only)"></toggle-switch-c>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Allow Updating Messages</b>
				<span class="lh-base" style="font-size: 0.9em;">Users will be able to update the content and attachments of sent encrypted emails</span>
			</div>
			<div class="content-container flex-row gap-2 align-items-center">
				<toggle-switch-c id="allowUpdatingMessagesToggle"
								 [(model)]="userPolicy.allow_recall_message.value"
								 [isDisabled]="userPolicy.allow_recall_message.strict"
								 (onChange)="updatePolicy('allow_recall_message', userPolicy.allow_recall_message)"></toggle-switch-c>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Allow Recalling Messages</b>
				<span class="lh-base" style="font-size: 0.9em;">Users will be able to recall emails sent to internal recipients</span>
			</div>

			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="allow_recall_email"
								 [(model)]="userPolicy.allow_recall_email.value"
								 (onChange)="updatePolicy('allow_recall_email', userPolicy.allow_recall_email)"></toggle-switch-c>
				<div class="flex-row gap-2">
					<span style="line-height: 20px;">Select allowed recall period:</span>
					<input [(ngModel)]="userPolicy.allow_recall_email.max_hours"
						   [disabled]="!userPolicy.allow_recall_email.value"
						   style="width:100px; height:22px; margin-bottom:0; box-sizing: border-box; border-radius: 4px; border: 1px solid #ccc; padding: 2px 8px;"
						   (ngModelChange)="handlePolicyEmailRecallMaxTimeChange()" type="number"
						   (keydown)="handleKeydown($event)"
						   id="allow_recall_email_max_hours" name="allow_recall_email_max_hours"
						   size="5" placeholder="Unlimited">
					<span style="line-height: 20px;">
						Hours
					</span>
				</div>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Allow downloading encrypted emails as EML</b>
				<span class="lh-base" style="font-size: 0.9em;">Recipients will be able to download encrypted emails as EML files</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="allow_download_as_emlToggle"
								 [(model)]="userPolicy.allow_download_as_eml.value"
								 [isDisabled]="userPolicy.allow_download_as_eml.strict"
								 (onChange)="updatePolicy('allow_download_as_eml', userPolicy.allow_download_as_eml)"></toggle-switch-c>

				<div class="flex-row align-items-center gap-4">
					<div class="flxRow-start md-checkbox align-items-center">
						<input id="allow_download_as_emlStrict" required="false" type="checkbox"
							   [(ngModel)]="userPolicy.allow_download_as_eml.strict"
							   (ngModelChange)="updatePolicy('allow_download_as_eml', userPolicy.allow_download_as_eml)"/>
						<label class="m-0" for="allow_download_as_emlStrict">Strict</label>
						<span *ngIf="userPolicy.allow_download_as_eml.value && userPolicy.allow_download_as_eml.strict" style="margin-left: 5px; color: #ff8e00;">(Downloading EML will be allowed for all outgoing emails)</span>
					</div>
				</div>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Send encrypted replies as regular emails</b>
				<span class="lh-base" style="font-size: 0.9em;">Replies to encrypted emails will be received as regular, unencrypted emails</span>
			</div>
			<div class="content-container flex-row gap-2 align-items-center">
				<toggle-switch-c id="secure_reply_send_regular"
								 [(model)]="userPolicy.secure_reply_send_regular.value"
								 [isDisabled]="userPolicy.secure_reply_send_regular.strict"
								 (onChange)="updatePolicy('secure_reply_send_regular', userPolicy.secure_reply_send_regular)"></toggle-switch-c>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Require authentication on replies</b>
				<span class="lh-base" style="font-size: 0.9em;">All encrypted replies will require the recipient to undergo Multi-Factor Authentication</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="secure_received"
								 [(model)]="userPolicy.secure_received.value"
								 [isDisabled]="userPolicy.secure_received.strict"
								 (onChange)="updatePolicy('secure_received', userPolicy.secure_received)"></toggle-switch-c>

				<div class="flex-row align-items-center gap-4">
					<div class="flxRow-start md-checkbox align-items-center">
						<input id="secure_receivedStrict" required="false" type="checkbox"
							   [(ngModel)]="userPolicy.secure_received.strict"
							   (ngModelChange)="updatePolicy('secure_received', userPolicy.secure_received)"/>
						<label class="m-0" for="secure_receivedStrict">Strict</label>
					</div>
				</div>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Allow recipients to switch authentication from SSO to email</b>
				<span class="lh-base" style="font-size: 0.9em;">Users can switch their authentication method from SSO to email.</span>
			</div>
			<div class="content-container flex-row gap-2 align-items-center">
				<toggle-switch-c id="allow_auth_sso_to_email"
								 [(model)]="userPolicy.allow_auth_sso_to_email.value"
								 [isDisabled]="userPolicy.allow_auth_sso_to_email.strict"
								 (onChange)="updatePolicy('allow_auth_sso_to_email', userPolicy.allow_auth_sso_to_email)"></toggle-switch-c>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Block outbound emails to blocklisted recipients</b>
				<span class="lh-base" style="font-size: 0.9em;">Outbound emails sent to addresses or domains in your inbound global blocklist will be automatically blocked</span>
			</div>
			<div class="content-container flex-row gap-2 align-items-center">
				<toggle-switch-c id="blockOutboundEmailsBlocklist"
							   [(model)]="userPolicy.block_emails_for_blacklist_senders.value"
							   [isDisabled]="userPolicy.block_emails_for_blacklist_senders.strict"
							   (onChange)="updatePolicy('block_emails_for_blacklist_senders', userPolicy.block_emails_for_blacklist_senders)"></toggle-switch-c>
			</div>
		</div>
		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Block outbound emails for attachments types</b>
				<span class="lh-base" style="font-size: 0.9em;">Emails containing those attachments types will be automatically blocked</span>
			</div>
			<div class="content-container flex-column gap-2">
				<label class="policyCheckbox" style="display: inline-flex; margin-bottom: 0;">
					<div *ngFor="let method of blockFileTypes">
						<label class="policyCheckbox" style="margin-right: 30px;">
							<input type="checkbox"
								   [(ngModel)]="method.selected"
								   (ngModelChange)="updateBlockFileTypes()"/>
							<span style="text-transform: capitalize;">{{method.name}}</span>
						</label>
					</div>
				</label>
			</div>
		</div>
		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Block Inappropriate Content</b>
				<span class="lh-base" style="font-size: 0.9em;">Block inappropriate content such as nudity, violence and drug use</span>
			</div>
			<div class="content-container flex-column gap-3">
                <toggle-switch-c id="blockInappropriateContentToggle"
                               [(model)]="userPolicy.block_inappropriate_content.enabled"
                               (onChange)="updateInappropriateContentCategory()"></toggle-switch-c>
			</div>
		</div>
		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Block Malicious Content</b>
				<span class="lh-base" style="font-size: 0.9em;">Block sent emails containing malicious content such as links and attachments</span>
			</div>
			<div class="content-container flex-column gap-3">
				<div class="flex-row gap-2 align-items-center">
                    <toggle-switch-c id="blockMaliciousContent"
                                   [(model)]="userPolicy.malicious_outbound.enabled"
                                   [isDisabled]="true"
                                   [hideText]="true"></toggle-switch-c>
					<span style="font-size:0.9em; font-style: italic;">(This feature cannot be disabled)</span>
				</div>
			</div>
		</div>
		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Notify Sender About Blocked Emails</b>
				<span class="lh-base" style="font-size: 0.9em;">Senders will be notified when their outbound emails are blocked or quarantined for any reason</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="notifySenderQuarantinedOutbound"
							   [(model)]="userPolicy.notify_sender_outbound_quarantined.enabled"
							   (onChange)="updatePolicyOutboundQuarantinedSenderNotify()"></toggle-switch-c>

				<a class="blue-link" style="text-decoration: underline;" (click)="openNotifyOptionsPopup()">
					Notification Options
				</a>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Retention Policy</b>
				<span class="lh-base" style="font-size: 0.9em;">Your emails and attachments will be permanently deleted after the selected amount of time</span>
			</div>
			<div class="content-container flex-column gap-3">
                <toggle-switch-c id="retentionPolicyToggle"
                               [(model)]="userPolicy.retention.enabled"
                               (onChange)="updateRetention(true)"></toggle-switch-c>
				<div class="flex-row gap-2">
					<span style="line-height: 20px;">Select period:</span>
					<input required
						   [(ngModel)]="userPolicy.retentionValue"
						   [required]="userPolicy.retention.value"
						   [disabled]="!userPolicy.retention.enabled"
						   style="width:80px; height:22px; margin-bottom:0; box-sizing: border-box; border-radius: 4px; border: 1px solid #ccc; padding: 2px 8px;"
						   (ngModelChange)="updateRetention(false)" type="number"
						   (keydown)="handleKeydown($event)"
						   id="retention_value" name="retention_value"
						   size="5" placeholder="Number">
					<label style="margin-bottom: 0;">
						<app-dropdown-c style="width: 90px; height: 22px;"
									  (click)="retentionType = !retentionType; $event.stopPropagation()"
									  [isDisabled]="!userPolicy.retention.enabled"
									  text="{{userPolicy.retentionType}}s">
							<li *ngFor="let item of dic.CONSTANTS.timePeriod | keyvalue: gs.returnZero"
								(click)="userPolicy.retentionType = item.value; updateRetention(true);"
								id="{{item.value}}">
								{{item.value}}s
							</li>
						</app-dropdown-c>
					</label>
				</div>
				<span style="font-style: italic; font-size: 11px;">Highest allowed values: 7 years/84 months/2520 days</span>
			</div>
		</div>
		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Email Expiration</b>
				<span class="lh-base" style="font-size: 0.9em;">Encrypted emails will expire after the selected amount of days. Expired emails cannot be opened by recipients</span>
			</div>
			<div class="content-container flex-column gap-3">
                <toggle-switch-c id="emailExpirationToggle"
                               [(model)]="userPolicy.expired_enable.value"
                               [isDisabled]="userPolicy.expired_enable.strict"
                               (onChange)="updatePolicy('expired_enable', userPolicy.expired_enable)"></toggle-switch-c>
				<div class="flex-row gap-2 align-items-center">
					<span>Select period:</span>
					<div class="flex-row gap-2 align-items-center">
						<input required
							   [(ngModel)]="userPolicy.expired_days.value"
							   [required]="userPolicy.expired_days"
							   style="width:60px; height: inherit; margin-bottom:0; box-sizing: border-box; border-radius: 4px; border: 1px solid #ccc; padding: 2px 8px;"
							   [disabled]="!userPolicy.expired_enable.value || userPolicy.expired_enable.strict"
							   (ngModelChange)="updatePolicy('expired_days', userPolicy.expired_days)" type="number"
							   min="1" max="365" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
							   (keydown)="handleKeydown($event)"
							   id="expired_days" name="expired_days"
							   size="5" placeholder="Days">
						<span>days</span>
					</div>
				</div>
				<div class="flex-row align-items-center gap-4">
					<div class="flxRow-start md-checkbox align-items-center">
						<input id="expiresInStrict" required="false" type="checkbox"
							   [(ngModel)]="userPolicy.expired_enable.strict"
							   (ngModelChange)="updatePolicy('expired_enable', userPolicy.expired_enable)"/>
						<label class="m-0" for="expiresInStrict">Strict</label>
					</div>
				</div>
			</div>
		</div>
		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Attachment Expiration</b>
				<span class="lh-base" style="font-size: 0.9em;">Attachments will be automatically deleted after the selected amount of days. Deleted attachments cannot be downloaded by recipients</span>
			</div>
			<div class="content-container flex-column gap-3">
                <toggle-switch-c id="attachmentExpirationToggle"
                               [(model)]="userPolicy.delete_attachment_enable.value"
                               [isDisabled]="userPolicy.delete_attachment_enable.strict"
                               (onChange)="updatePolicy('delete_attachment_enable', userPolicy.delete_attachment_enable)"></toggle-switch-c>
				<div class="flex-row align-items-centerm gap-2">
					<span style="line-height: 20px;">Select period:</span>
					<div class="flex-row align-items-center gap-2">
						<input required
							   [(ngModel)]="userPolicy.delete_attachment_days.value"
							   [required]="userPolicy.delete_attachment_days.value"
							   style="width:60px; height: inherit; margin-bottom:0; box-sizing: border-box; border-radius: 4px; border: 1px solid #ccc; padding: 2px 8px;"
							   [disabled]="!userPolicy.delete_attachment_enable.value || userPolicy.delete_attachment_enable.strict"
							   (ngModelChange)="updatePolicy('delete_attachment_days', userPolicy.delete_attachment_days)" type="number"
							   min="1" max="365" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$"
							   (keydown)="handleKeydown($event)"
							   id="delete_attachment_days" name="delete_attachment_days"
							   size="5" placeholder="Days">
						<span>days</span>
					</div>
				</div>
				<div class="flex-row align-items-center gap-4">
					<div class="flxRow-start md-checkbox align-items-center">
						<input id="deleteAttachmentStrict" required="false" type="checkbox"
							   [(ngModel)]="userPolicy.delete_attachment_enable.strict"
							   (ngModelChange)="updatePolicy('delete_attachment_enable', userPolicy.delete_attachment_enable)"/>
						<label class="m-0" for="deleteAttachmentStrict">Strict</label>
					</div>
				</div>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Track Links</b>
				<span class="lh-base" style="font-size: 0.9em;">Links will be tracked to notify when recipients have clicked on them</span>
			</div>
			<div class="content-container flex-column gap-3">
                <toggle-switch-c id="trackLinksToggle"
                               [(model)]="userPolicy.track_links.value"
                               [isDisabled]="userPolicy.track_links.strict"
                               (onChange)="updatePolicy('track_links', userPolicy.track_links)"></toggle-switch-c>
				<div class="flex-row align-items-center gap-4">
					<div class="flxRow-start md-checkbox align-items-center">
						<input id="trackLinksStrict" required="false" type="checkbox"
							   [(ngModel)]="userPolicy.track_links.strict"
							   (ngModelChange)="updatePolicy('track_links', userPolicy.track_links)"/>
						<label class="m-0" for="trackLinksStrict">Strict</label>
					</div>
				</div>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Email Open Tracking HTML</b>
				<span class="lh-base" style="font-size: 0.9em;">Add a tracking pixel to monitor when recipients open and read standard emails</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="track_open_html"
								 [(model)]="userPolicy.track_open_html.value"
								 [isDisabled]="userPolicy.track_open_html.strict"
								 (onChange)="updatePolicy('track_open_html', userPolicy.track_open_html)"></toggle-switch-c>
			</div>
		</div>

		<div class="list-group-item settings-list-item py-4">
			<div class="title-section-container flex-column gap-2">
				<b>Allow Printing</b>
				<span class="lh-base" style="font-size: 0.9em;">Recipients will be able to print the content of encrypted emails</span>
			</div>
			<div class="content-container">
                <toggle-switch-c id="allowViewingQuarantinedEmailsToggle"
                               [(model)]="userPolicy.enable_print.value"
                               (onChange)="updatePolicy('enable_print', userPolicy.enable_print)"></toggle-switch-c>
			</div>
		</div>
	</div>


	<!--Change notification options popup-->
	<popup-c *ngIf="notifyOptionsPopup?.show"
		   head="Sender Release Request Notification"
		   (closeCb)="notifyOptionsPopup = null"
		   (confirmCb)="updatePolicyOutboundQuarantinedSenderNotify()"
		   [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm'}}"
		   size="medium">
		<div class="flxClmn vSpace2 f-1-0">
			<div class="flxClmn vSpace2" style="padding: 0 15px;">
				<div class="flxClmn vSpace1" *ngIf="notifyOptionsPopup">
					<div class="flxRow-start hSpace1 md-checkbox" style="align-items: center;">
						<input id="notifyRecipientAllowRequestRelease" type="checkbox"
							   [(ngModel)]="userPolicy.notify_sender_outbound_quarantined.block_allow_release_request"/>
						<label style="margin: 0;" for="notifyRecipientAllowRequestRelease">Allow sender to request release</label>
						<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Recipient quarantine notifications will have a button to automatically send a request to the admin/reviewers to release the email" placement="right"></i>
					</div>
					<div class="flxRow-start hSpace2" style="align-items: center;">
						<div class="flxRow hSpace05" style="align-items: center; flex-basis: 190px;">
							<span style="margin: 0; min-width: 165px;">Send the release request to:</span>
							<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="Optional. If no address is provided here, the request will be sent to the primary reviewer." placement="right"></i>
						</div>
						<input type="text" class="lowerPlaceholder" placeholder="Email addresses separated by commas (optional)" style="margin: 0; width: 100%; box-sizing: inherit; flex: 1 0;"
							   [ngClass]="{'disable': !userPolicy.notify_sender_outbound_quarantined.block_allow_release_request}"
							   [(ngModel)]="notifyOptionsPopup.emails"
							   [disabled]="!userPolicy.notify_sender_outbound_quarantined.block_allow_release_request"/>
					</div>
					<div class="flxRow-start hSpace2">
						<span style="flex-basis: 190px; padding-top: 0.3rem;">Release request email title:</span>
						<div class="flxClmn vSpace05" style="flex: 1 0; margin: 0;">
							<input type="text" class="lowerPlaceholder" placeholder="{{releaseRequestSubjectPlaceholder}}" style="margin: 0; width: 100%; box-sizing: inherit;"
								   [ngClass]="{'disable': !userPolicy?.notify_sender_outbound_quarantined?.block_allow_release_request}"
								   [(ngModel)]="notifyOptionsPopup.notifyAllowRequestSubject"
								   [disabled]="!userPolicy?.notify_sender_outbound_quarantined?.block_allow_release_request"/>
							<span class="text-muted" style="font-size: 0.8rem; line-height: 1.2; max-width: 92%;">{{releaseRequestSubjectDesc}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</popup-c>
</div>

