import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {AuthService} from "../../../services/authService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {ENV_CONSTS} from "../../../constants";
import {ComposeMessageService} from "../../../services/composeMessageService";
import {ClioService} from "../../../services/clioService";
import {DropboxService} from "../../../services/dropboxService";
import {HttpClient, HttpEventType, HttpResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {LookAndFeelService} from "../../../services/lookAndFeelService";


@Component({
	selector: 'templates-page-component',
	templateUrl: './templates-page.component.html',
})
export class TemplatesPageComponent implements OnInit {
	constructor(private rs:RouteService,
				private http:HttpClient,
				private authService:AuthService,
				private composeMessageService:ComposeMessageService,
				private ns:NotificationService,
				public gs:GeneralService,
				public lfs:LookAndFeelService,
				private router:Router,
				private clioService:ClioService,
				private dropboxService:DropboxService) {
	}

	util = util;
	getTemplatesInProcess;
	deleteTemplatesInProcess;
	updateTemplateInProcess;
	dic = DICTIONARY;
	summernoteOptions = this.gs.summernoteOptions;
	corpname = this.gs.corpname;
	previewTemplatePopup;
	showAttachmentsManager = false;
	lfPageUrl = `${ENV_CONSTS.webAppUrl}/${this.dic.CONSTANTS.appStates.adminOutbound}/${this.dic.CONSTANTS.adminPages.outbound.lf}`;
	dynamicFieldExample = '{{COMPANY_NAME}}';
	uploading;
	attachmentsUploadingInProcessCount;
	addUnsubscribe;
	activeTemplate;
	userInfo;
	dynamicFields;
	selectedAll;
	searchTemplateTxt;
	showDynamicFields;
	templates;
	isMissingName;
	htmlFile;
	attachmentFiles; // the model for ngfSelect
	validDrag;
	invalidDrag;
	showAttachmentFileSourcesModal;
	clio;
	dropbox;
	_ = _;

	ngOnInit() {
		this.uploading = false;
		this.attachmentsUploadingInProcessCount = 0;

		this.addUnsubscribe = () => {
			if (!this.activeTemplate.html) {
				this.ns.showWarnMessage(this.dic.ERRORS.contentEmpty);
				return;
			}

			let summernote:any = this.gs.getSummernote();
			if (summernote && summernote.summernote('codeview.isActivated')) {
				summernote.summernote('codeview.deactivate');
			}

			let unsubscribeText = "<br/><br/><br/><div><span lang=\"EN\" style=\"font-size:11px;line-height:20px;font-family:Helvetica, Arial, sans-serif !important;color:#818181 !important;\">To unsubscribe from this list, please click on the following link: <a href=\"https://gss.trustifi.com/unsubscribe.html?e={{REC.email}}\" style=\"color:#818181\" title=\"Unsubscribe\">Unsubscribe</a></span></div>";
			this.activeTemplate.html = this.activeTemplate.html + unsubscribeText;
		};

		this.gs.getUserInfo(false, userInfo => {
			this.userInfo = userInfo;

			if (userInfo.user_role === this.dic.CONSTANTS.userRole.user) {
				this.dynamicFields = this.dic.CONSTANTS.templateDynamicFields;
			}
			else {
				this.dynamicFields = this.dic.CONSTANTS.templateDynamicFieldsForAdmin;
			}
		});

		this.getTemplatesInProcess = true;
		this.rs.getTemplates().then( (response) => {
			this.templates = response;

			this.templates.forEach(template => {
				template.hideActions = template.role && template.role === this.dic.CONSTANTS.templateTypes.system;
			})

			let inviteCustomizeTemplate = _.find(this.templates, t => t.name === 'Invite to Plan Customized');
			if (inviteCustomizeTemplate) {
				inviteCustomizeTemplate.html = inviteCustomizeTemplate.html.replace('{{COMPANY_COLOR}}', this.lfs.color);
			}
			/*if (this.templates?.length > 0) {
				this.selectActiveTemplate(this.templates[0]);
			}*/
			this.getTemplatesInProcess = false;
		}, (err) => {
			if (err && err.data && err.data.message && !err.data.display_bar) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.getTemplatesInProcess = false;
		});
	};

	selectMultipleTemplatesAction = (selectedTemplates, action) => {
		switch (action) {
			case this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete):
				this.deleteTemplates(selectedTemplates);
				break;
		}
	}

	showTemplateBulkActions = () => {
		return [
			this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete)
		];
	}

	selectTemplateAction = (templateObj, action) => {
		switch (action) {
			case this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete):
				this.deleteTemplates([templateObj]);
				break;
		}
	}

	showTemplateActions = () => {
		return [
			this.gs.toCapitalize(this.dic.CONSTANTS.actions.delete)
		];
	}

	selectActiveTemplate = (template) => {
		template.soloSelected = true;
		this.activeTemplate = _.cloneDeep(template);
		this.isMissingName = false;

		const res = this.composeMessageService.updateSummernoteContent(this.activeTemplate.html, true);
		this.activeTemplate.html = res.html;
		this.activeTemplate.css = res.css;
	};

	startAddNewTemplate = () => {
		this.activeTemplate = {
			name : '',
			title : '',
			attachments : [],
			html : ''
		};

		this.templates.forEach(template => {
			template.soloSelected = false;
		});

		setTimeout(() => {
			document.getElementById('templateNameInput').focus();
		});
	};

	cancelAddNewTemplate = () =>{
		this.activeTemplate = null;
	};

	saveTemplate = () => {
		if (!this.activeTemplate.name){
			this.isMissingName = true;
			return;
		}

		// EDIT
		if (this.activeTemplate._id) {
			this.updateTemplate();
		}
		// NEW
		else {
			let template = _.cloneDeep(this.activeTemplate);
			template.html = this.composeMessageService.getSummernoteContent(template);
			delete template.css;

			if (template.attachments?.length) {
				template.attachments = _.map(template.attachments, '_id');
			}

			this.updateTemplateInProcess = true;

			this.rs.addNewTemplate(template).then((response) => {
				// information about attachments (name, size)
				const templateToAdd = response;
				templateToAdd.attachments = this.activeTemplate.attachments;
				this.templates.push(templateToAdd);
				this.selectActiveTemplate(templateToAdd);

				this.updateTemplateInProcess = false;
				this.ns.showInfoMessage(this.dic.MESSAGES.templateCreated);
			}, (err) => {
				if (err && err.data && err.data.message && !err.data.display_bar) {
					this.ns.showErrorMessage(err.data.message);
				}
				this.updateTemplateInProcess = false;
			});
		}
	};

	updateTemplate = () => {
		if (this.activeTemplate.role === this.dic.CONSTANTS.templateTypes.system) {
			this.gs.showPopup({
				title: `Update ${this.activeTemplate.name}`,
				subTitle: `Please note - you are about to update a system template`,
				body: ['This will affect all system emails that use this template.'],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Update',
				isFile: false,
				doneCb: this.updateTemplateExecute
			});
		}
		else {
			this.updateTemplateExecute();
		}
	};

	updateTemplateExecute = () => {
		let template = _.cloneDeep(this.activeTemplate);
		template.html = this.composeMessageService.getSummernoteContent(template);
		delete template.css;

		if (template.attachments?.length) {
			template.attachments = _.map(template.attachments, '_id');
		}

		this.updateTemplateInProcess = true;

		this.rs.updateTemplate(template._id, template).then( (response) => {
			let res = this.composeMessageService.updateSummernoteContent(this.activeTemplate.html);
			this.activeTemplate.html = res.html;
			this.activeTemplate.css = res.css;

			const templateIdxInList = _.findIndex(this.templates, {_id: response._id});
			if (templateIdxInList > -1) {
				this.templates.splice(templateIdxInList, 1 , this.activeTemplate);
			}

			this.updateTemplateInProcess = false;
			this.ns.showInfoMessage(this.dic.MESSAGES.templateUpdated);
		}, (err) => {
			if (err && err.data && err.data.message && !err.data.display_bar) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.updateTemplateInProcess = false;
		});
	};

	// since the search input is outside the trustifi-table component, we should trigger the directive's inner search mechanism from the outside.
	// we do it by changing the list so that the directive will detect changes and fire a search
	triggerSearch = () => {
		this.templates = _.cloneDeep(this.templates);
	}

	searchTemplates = () => {
		this.templates.forEach(record => {
			// search
			if (this.searchTemplateTxt) {
				const isFound = searchTextExecute(record, this.searchTemplateTxt);
				if (!isFound && !record.isNew) { // ignore contact in creation state
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	deleteTemplates = (templates) => {
		const isMultiple = templates.length > 1;

		this.gs.showPopup({
			title: `Delete Template${isMultiple ? 's' : ''}`,
			subTitle: isMultiple ? `Are you sure you want to delete ${templates.length} templates?` : `Are you sure you want to delete the selected template?`,
			body: ['You cannot recover a deleted template.'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			isFile: false,
			doneCb: () => this.deleteTemplatesExecute(templates)
		});
	}

	deleteTemplatesExecute = (templates) => {
		if (this.deleteTemplatesInProcess) {
			return;
		}
		const systemTemplates = _.filter(templates, {role: this.dic.CONSTANTS.templateTypes.system});
		if (systemTemplates.length) {
			systemTemplates.forEach(systemTemplate => {
				this.ns.showErrorMessage(util.format(this.dic.ERRORS.systemTemplateDeleteNotAllowed, systemTemplate.name));
			});
			_.remove(templates, {role: this.dic.CONSTANTS.templateTypes.system});
			if (!templates.length) {
				return;
			}
		}

		// NOTE: templates are deleted by comma-separated IDs string input to the BE
		const templateIds = _.map(templates, '_id');

		this.rs.deleteTemplate(templateIds.join(',')).then(response =>{
			_.remove<any>(this.templates, template => templateIds.includes(template._id));

			if (this.activeTemplate?._id && templateIds.includes(this.activeTemplate._id)) {
				this.activeTemplate = null;
			}

			if (templates.length === 1) {
				this.ns.showInfoMessage(this.dic.MESSAGES.templateDeleted);
			}
			else {
				this.ns.showInfoMessage(this.dic.MESSAGES.templatesDeleted);
			}
		});
	};

	loadHtml = () => {
		if (!this.htmlFile) {
			console.error('No file provided');
			return;
		}

		const reader = new FileReader();
		reader.addEventListener("load",  () => {
			if (reader.result) {
				const res = this.composeMessageService.updateSummernoteContent(reader.result);
				this.activeTemplate.html = res.html;
				this.activeTemplate.css = res.css;
			}
		}, false);

		reader.readAsText(this.htmlFile, 'uft8');
	};

	openComposeMessage = () => {
		if (!this.activeTemplate) {
			return;
		}

		const options = {
			template: {
				html: (this.activeTemplate.css || '') + this.activeTemplate.html, // in the page "compose message" the "css" is extracted from the "html"
				...this.activeTemplate
			}
		}

		this.router.navigate([this.dic.CONSTANTS.appStates.personalPages, this.dic.CONSTANTS.personalPages.composeMessage], {state: { data: options }});
	};

	previewTemplate = () => {
		if (!this.activeTemplate) {
			return;
		}

		let previewData = {
			html: this.composeMessageService.getSummernoteContent(this.activeTemplate),
			attachments: this.activeTemplate.attachments,
			role: this.activeTemplate.role,
			methods: {},
			recipients_display_only: {to: [], cc: []}
		};

		this.previewTemplatePopup = {
			getTemplatePreviewDataInProcess: true,
			emailContent: '',
			show: false
		}

		this.rs.previewEmail(previewData).then((response) => {
			this.previewTemplatePopup.show = true;
			setTimeout(() => {
				this.previewTemplatePopup.emailContent = response.html;
			})
		}, (err) => {
			this.previewTemplatePopup = null;
		});
	};

	exportTemplate = () => {
		let html = this.composeMessageService.getSummernoteContent(this.activeTemplate);
		this.gs.downloadClientFile(html, this.activeTemplate.name, 'text/html');
	};

	resetSystemTemplate =() => {
		if (!this.activeTemplate || this.activeTemplate.role !== this.dic.CONSTANTS.templateTypes.system) {
			return;
		}

		this.gs.showPopup({
			title: `Reset ${this.activeTemplate.name}`,
			subTitle: `Please note - you are about to reset a system template`,
			body: ['The system template will be reset to default settings.', 'This will affect all system emails that use this template.'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Reset',
			isFile: false,
			doneCb: this.resetSystemTemplateExecute
		});
	};

	resetSystemTemplateExecute =() => {
		if (!this.activeTemplate) {
			return;
		}

		this.rs.resetSystemTemplate(this.activeTemplate._id).then( (response) => {
			if (response.name === 'Invite to Plan Customized') {
				response.html = response.html.replace('{{COMPANY_COLOR}}', this.lfs.color);
			}
			this.activeTemplate.html = response.html;
		});
	};

	uploadAttachment = () => {
		this.attachmentsUploadingInProcessCount += this.attachmentFiles.length;
		let isValid;
		for (let i = 0; i < this.attachmentFiles.length; i++) {
			isValid = this.gs.checkUploadFileSize(this.attachmentFiles[i].size, this.attachmentFiles[i].name);
			if (isValid) {
				this.uploadAttachmentExecute(this.attachmentFiles[i]);
			}
			else {
				this.attachmentsUploadingInProcessCount--;
			}
		}

		// empty the model after the iteration so it won't stack
		this.attachmentFiles = [];
	};

	uploadAttachmentExecute = (file) => {
		if (!file) {
			return;
		}
		if (_.find(this.activeTemplate.attachments, {name: file.name, size: file.size})) {
			this.attachmentsUploadingInProcessCount--;
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.attachmentExists, file.name));
			return;
		}

		const currentAttachment = {
			name: file.name,
			size: file.size,
			finished: false,
			progressPercentage: 0,
			upload: {} as any,
			abortUploadFlag: false
		};

		this.uploading = true;
		this.activeTemplate.attachments.push(currentAttachment);

		const formData: FormData = new FormData();
		formData.append('file', file, file.name);

		currentAttachment.upload = this.http.post(ENV_CONSTS.attachmentUrl + '/attachment', formData, {
			headers: this.authService.getHeaders(),
			reportProgress: true,
			observe: 'events'
		});

		currentAttachment.upload.subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

				const fileInAttachmentsListObj = _.find(this.activeTemplate.attachments, {name: currentAttachment.name});

				if (fileInAttachmentsListObj) {
					const uploadProgress = Math.trunc((event.loaded / event.total!) * 100);
					// file upload completed
					if (uploadProgress >= 100) {
						this.attachmentsUploadingInProcessCount--;
						if (this.attachmentsUploadingInProcessCount === 0) {
							this.uploading = false;
						}

						fileInAttachmentsListObj.finished = true;
					}
					// file upload in process
					//// abort upload
					else if (currentAttachment.abortUploadFlag) {
						_.remove(this.activeTemplate.attachments, {name: currentAttachment.name});

						this.attachmentsUploadingInProcessCount--;
						if (this.attachmentsUploadingInProcessCount === 0) {
							this.uploading = false;
						}
					}
					// continue upload
					else {
						fileInAttachmentsListObj.progressPercentage = uploadProgress;
					}
				}
			} else if (event instanceof HttpResponse) {
				if (event.body) {
					const fileInAttachmentsListObj = _.find(this.activeTemplate.attachments, {name: currentAttachment.name});
					fileInAttachmentsListObj._id = event.body[0];
				}
			}
		}, (error) => {
			const isFileToRemoveInUploadingList = _.find(this.activeTemplate.attachments, {name: currentAttachment.name});

			_.remove(this.activeTemplate.attachments, {name: currentAttachment.name});

			if (isFileToRemoveInUploadingList) {
				this.attachmentsUploadingInProcessCount--;
				if (this.attachmentsUploadingInProcessCount === 0) {
					this.uploading = false;
				}
			}

			console.error(error.message);
			this.ns.showErrorMessage('File upload failed');
		});

	};

	deleteAttachment = (attachment) => {
		_.remove(this.activeTemplate.attachments, {name: attachment.name});
	};


	attachmentFilterDone = (attachmentsOutput) =>{
		const selectedAttachments = _.filter(attachmentsOutput, 'selected');
		this.activeTemplate.attachments = [];

		if (selectedAttachments.length) {

			selectedAttachments.forEach(attachmentObj => {
				attachmentObj.finished = !attachmentObj.is_clio && !attachmentObj.is_dropbox;
			});

			this.activeTemplate.attachments = selectedAttachments;

			const clioAttachments = _.uniq(_.map(_.filter(selectedAttachments, 'is_clio'), '_id'));
			if (clioAttachments.length) {
				this.clioService.attachdoc(clioAttachments, (err, data) => {
					if (err)
						console.error(err);
					else if (!data || !data[0])
						console.error('no files were uploaded from clio to Trustifi');
					else {
						data.forEach(itm => {
							itm.finished = true;
							itm.is_clio = true;
							try {
								itm.name = decodeURIComponent(itm.name);
							} catch (e) {
								console.log('Could not decode: ' + itm.name);
							}
						});
						this.activeTemplate.attachments = this.activeTemplate.attachments.concat(data);
					}
				});
			}

			const dropboxAttachments = _.uniq(_.map(_.filter(selectedAttachments, 'is_dropbox'), '_id'));
			if (dropboxAttachments.length) {
				this.dropboxService.attachdoc(dropboxAttachments, (err, data) => {
					if (err)
						console.error(err);
					else if (!data || !data[0])
						console.error('no files were uploaded from dropbox to Trustifi');
					else {
						data.forEach(itm => {
							itm.finished = true;
							itm.is_dropbox = true;
							try {
								itm.name = decodeURIComponent(itm.name);
							} catch (e) {
								console.log('Could not decode: ' + itm.name);
							}
						});
						this.activeTemplate.attachments = this.activeTemplate.attachments.concat(data);
					}
				});
			}
		}

		this.showAttachmentsManager = false;
		this.ns.closeMessage();
	};

	// BUG FIX : ngfDrop must be overriding the "dragover" event listener of summernote. so we handle it manually
	toggleSummernoteDragState = (setState, event) => {
		const summernoteContainer = document.getElementById('summernoteContainer');

		if (setState && event.target.className === 'note-editable') {
			summernoteContainer.classList.add('summernote-hovered');
		}

		// (a new layer is now inserted because of the last class change so the "drag" event now passes to this new layer)
		// in case of a drag leave without dropping
		else if (!setState && (event.target.className === 'note-dropzone-message')) {
			summernoteContainer.classList.remove('summernote-hovered');
		}
	}
	//
}

function searchTextExecute(templateObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return (templateObj.name && templateObj.name.toLowerCase().indexOf(searchTerm) > -1);
}
