import {Component} from "@angular/core";
import {DICTIONARY} from "../../../../../dictionary";
import _ from 'lodash';
import util from "util";
import {NotificationService} from "../../../../../services/notificationService";
import {RouteService} from "../../../../../services/routeService";
import {GeneralService} from "../../../../../services/generalService";
import {ClipboardService} from "ngx-clipboard";

@Component({
	selector: 'outbound-test-sensitivity',
	templateUrl: './outbound-test-sensitivity.component.html',
})

export class OutboundTestSensitivityComponent {

	constructor(private ns: NotificationService,
				private rs: RouteService,
				public clipboard: ClipboardService,
				private gs: GeneralService) {
	}

	dic = DICTIONARY;

	file;
	type = this.dic.CONSTANTS.sensitivityAction.file;
	textToScan;
	scanTextInProcess;
	scanFileInProcess;
	scanTextErr;
	scanFileErr;
	result;
	fileData;
	activeTestedContent:any = {};
	scanFileResults;
	_ = _;

	validDrag;
	invalidDrag;

	scanTextExecute = () => {
		if (this.scanTextInProcess) {
			return;
		}

		if (!this.textToScan) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.emptyField, 'Text'));
			this.scanTextErr = true;
			return;
		}

		this.scanTextInProcess = true;
		this.activeTestedContent.type = this.dic.CONSTANTS.sensitivityAction.text;
		this.activeTestedContent.text = this.textToScan;
		this.scanTextErr = '';

		this.rs.testTextSensitivity({
			text: this.textToScan
		}).then(response => {
			this.result = response;

			this.scanTextInProcess = false;
			this.scanFileInProcess = false;
		}, (err) => {
			this.scanTextInProcess = false;
			this.scanFileInProcess = false;
		});	}

	scanFileExecute = () => {
		if (this.scanFileInProcess) {
			return;
		}

		if (!this.file) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.mailboxesEmailScanNoItems, 'file'));
			return;
		}

		this.scanFileInProcess = true;
		this.activeTestedContent.type = this.dic.CONSTANTS.sensitivityAction.file;
		this.activeTestedContent.fileName = this.file.name;
		this.scanFileErr = '';

		this.gs.uploadFile('/admin/sensitivity/test/file', {file: this.file}, null, false).then((res:any) => {
			if (res && res.data) {
				this.fileData = {
					name: this.file.name,
					isScanned: true
				};
				this.result = res.data;

				this.scanFileInProcess = false;
			}
		}, err => {
			this.scanFileInProcess = false;
		});
	}
}
