import {Component, OnInit} from '@angular/core';
import {DICTIONARY} from '../../../../../dictionary';
import {GeneralService} from '../../../../../services/generalService';
import {NotificationService} from '../../../../../services/notificationService';
import {RouteService} from '../../../../../services/routeService';
import _ from 'lodash';
import util from "util";

@Component({
	selector: 'outbound-reviewers-component',
	templateUrl: './outbound-reviewers.component.html',
})
export class OutboundReviewersComponent implements OnInit {
	dic = DICTIONARY;

	reviewers = [];
	addReviewerInProcess: boolean;
	reviewerPermissionPopup: any;
	selectedUsers: any[];
	usersSelectionPopup: any;

	constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService) {
	}

	ngOnInit() {
		this.rs.getOutboundReviewers().then(response => {
			this.reviewers = response.outbound_reviewers || [];
		}, (err) => {

		});
	}

	selectMultipleReviewerAction = (selectedItems, action) => {
		switch (action) {
			case this.dic.CONSTANTS.inboundConfigurationsActions.remove:
				this.removeReviewers(selectedItems);
				break;
		}
	};

	showReviewerBulkActions = () => {
		return [
			this.dic.CONSTANTS.inboundConfigurationsActions.remove
		]
	}

	showReviewerActions = (reviewerObj) => {
		let actions = [
			this.dic.CONSTANTS.reviewerActions.modifyPermissions,
			this.dic.CONSTANTS.inboundConfigurationsActions.remove
		];

		return actions;
	}

	selectReviewerAction = (reviewerObj, action) => {
		reviewerObj.showActions = false;
		switch (action) {
			case this.dic.CONSTANTS.inboundConfigurationsActions.remove:
				this.removeReviewers([reviewerObj]);
				break;

			case this.dic.CONSTANTS.reviewerActions.modifyPermissions:
				this.openReviewerPermissionPopup(reviewerObj);
				break;
		}
	};

	startAddFromUsersSelection = (users) => {
		// Step 1: Filter out existing reviewers
		const existingReviewerEmails = this.reviewers.map(reviewer => reviewer.email);
		const filteredUsers = users.filter(user => !existingReviewerEmails.includes(user));

		// Step 2: Map filtered users to new reviewers structure
		const newReviewers = filteredUsers.map(user => ({
			email: user,
			isEditMode: false,
			isNew: true,
			edit: {
				email: user,
				allow_view_content: true
			}
		}));

		// Step 3: Add new reviewers if any
		if (newReviewers.length > 0) {
			newReviewers.forEach(reviewer => {
				this.reviewers.unshift(reviewer)
				this.confirmAddReviewer(reviewer, true);
			});
			// this.reviewers.unshift(...newReviewers);
			// this.confirmAddReviewer(newReviewers, true);
		}
	}

	startRemoveFromUsersSelection = (users) => {
		const reviewersToRemove = this.reviewers.filter(reviewer => users.includes(reviewer.email));

		if (reviewersToRemove.length > 0) {
			this.removeReviewers(reviewersToRemove);
		}
	}

	openUsersSelection = () => {
		this.selectedUsers = _.map(this.reviewers, 'email');
		this.usersSelectionPopup = {
			show: true
		};
	}

	removeReviewers = (reviewers) => {
		if (!reviewers || !reviewers.length) {
			return;
		}

		let title, subTitle, body;
		if (reviewers.length === 1) {
			body = ['User will not have permission to review quarantines emails anymore.'];
			title = 'Remove reviewing permission';
			subTitle = `Remove ${reviewers[0].email} permission as a reviewer`;
		}
		else {
			body = [`Review permission of ${reviewers.length} users will be removed from this case.`];
			title = `Remove reviewing permission`;
			subTitle = `Remove reviewing permission - ${reviewers.length} users`;
		}

		this.gs.showPopup({
			title: title,
			subTitle: subTitle,
			body: body,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Remove',
			doneCb: () => {
				_.map(reviewers, reviewer => {
					const _reviewerObj = _.find<any>(this.reviewers, itm => itm.email === reviewer.email);
					if (_reviewerObj) {
						this.rs.deleteOutboundReviewer(reviewer.email).then(res => {
							_.remove<any>(this.reviewers, _reviewerObj);

							this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', reviewer.email));
						});
					}
				});
			}
		});
	};

	searchReviewer = (searchTerm, activeFilters) => {
		this.reviewers.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	};

	startAddReviewer() {
		if (this.addReviewerInProcess) {
			return;
		}

		this.addReviewerInProcess = true;

		const newReviewer = {
			isEditMode: true,
			isNew: true,
			edit: {
				email: '',
				allow_view_content: true,
			}
		};

		this.reviewers.unshift(newReviewer);
	};

	confirmAddReviewer = (reviewer ,isApproved) => {
		// new reviewer process was declined
		if (!isApproved) {
			_.remove<any>(this.reviewers, reviewer);
			this.addReviewerInProcess = false;
			return;
		}
		//

		// new reviewer approved
		if (!reviewer.edit.email) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Reviewer email field'));
		}
		if (!this.gs.validateEmail(reviewer.edit.email)) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.invalidEmail, reviewer.edit.email));
		}
		if (_.find(this.reviewers, rev => {return !rev.isNew && rev.email && rev.email === reviewer.edit.email})) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailAlreadyExist, reviewer.edit.email));
		}

		const emailFormatted = reviewer.edit.email.toLowerCase()
		reviewer.confirmEditInProcess = true;
		this.rs.addOutboundReviewer(emailFormatted).then(res => {
			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));

			reviewer.email = emailFormatted;
			reviewer.allow_view_content = true;
			reviewer.edit = null;
			reviewer.confirmEditInProcess = false;
			reviewer.isEditMode = false;
			reviewer.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit

			this.addReviewerInProcess = false;
		}, (err) => {
			reviewer.confirmEditInProcess = false;
			this.addReviewerInProcess = false;
		});
	}

	openReviewerPermissionPopup = (reviewerObj) => {
		this.reviewerPermissionPopup = {
			show: true,
			reviewerObj: reviewerObj
		};
	};

	reviewerViewContentChangeExecute = () => {
		const data = {
			action: 'viewContent',
			enabled: this.reviewerPermissionPopup.reviewerObj.allow_view_content
		};

		this.rs.updateOutboundReviewer(this.reviewerPermissionPopup.reviewerObj.email, data).then(() => {
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
		},  (error) => {
		});
	};
}


function searchTextExecute(reviewer, searchTerm) {
	return (reviewer.email?.toLowerCase().indexOf(searchTerm) > -1);
}
