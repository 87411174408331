<popup-c head="Select Sensitivity Types"
	   (closeCb)="closeModal();"
	   (confirmCb)="updateTypes();"
	   class="bs-enabled"
	   [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}" size="large">
	<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
		<trustifi-table-c
			class="trustifi-table" style="flex: 1 0; overflow: auto; z-index: 5; background: #fdfdfd; padding: 5px;"
			[list]="types"
			[headers]="[
				{text:'Name', sortKey:'name', width: '50%'},
				{text:'Type', sortKey:'type', width: '10%'},
				{text:'Min Score', sortKey:'min_score', width: '15%', tooltip: 'The lowest sensitivity score this type can reach when sent without additional sensitive data'},
				{text:'Max Score', sortKey:'max_score', width: '15%', tooltip: 'The highest sensitivity score this type can reach when sent along with additional sensitive data'},
				{text:'Compliance', width: '25%'}
			]"
			[cellsPrototype]="[
				{textKey:'name', overflowOutside: true, edit: {modelKey:'test', html:'nameAndRegex'}},
				{textKey: 'type'},
				{textKey: 'min_score'},
				{textKey: 'max_score'},
				{html: 'complianceCell'},
			]"
			[cellActions] = "{
				enable: true,
				selectItemActionCb:	selectSensitivityTypeActions,
				initActionsFunction: showSensitivityTypeActions
			}"
			(searchFunction)="searchType($event.searchTerm, $event.activeFilters)"
			(confirmEditFunction)="confirmAddType($event.item, $event.isApproved)"
			[options]="{
				defaultSortKey: '-name',
				itemsNameSingular: 'sensitivity type',
				exportToCsvFunction: exportToCsv,
				showRadioButtons: true,
			}"
		>
			<ng-template #customCells let-item let-cellId="cellId">
				<!--action cells-->
				<div *ngIf="cellId === 'nameAndRegex' && item.edit">
					<div style="position: relative;" class="flex-column gap-2">
						<div class="flex-row gap-2" style="align-items: center;">
							<span style="min-width: 40px;">Name:</span>
                            <input type="text" [(ngModel)]="item.edit.name" [disabled]="!item.isNew" [ngClass]="{'disabled': item.editing}"
								   [ngStyle]="!item.isNew && {'color' : 'lightgray'}" [value]=item.edit.name
								   name="typeName" placeholder="Name">
						</div>
						<div class="flex-row gap-2" style="align-items: center;">
							<span style="min-width: 40px;">Regex:</span>
                            <input type="text" [(ngModel)]="item.edit.regex"
								   [value]=item.edit.regex name="typeRegex" placeholder="Regex">
						</div>
						<div class="flex-row gap-2" style="align-items: center;">
							<span style="min-width: 40px;">Score:</span>
							<app-dropdown-c style="width: 45px;" text="{{item.edit.sensitivity}}">
								<li *ngFor="let sen of item.sensitivityOptions"
									(click)="item.edit.sensitivity = sen">
									{{sen}}
								</li>
							</app-dropdown-c>
						</div>
					</div>
				</div>

				<div *ngIf="cellId === 'complianceCell'">
					<span style="text-overflow: unset; white-space: normal;">
						{{item.compliance?.length ? item.compliance.join(', ') : 'N/A'}}
					</span>
				</div>
			</ng-template>
			<div tableButtons>
				<!--Add Type button-->
				<div class="w100">
					<btn-c endIcon="fa fa-plus"
						 [isDisabled]="addTypeInProcess"
						 (action)="startAddType();">
						Add Sensitivity Type
					</btn-c>
				</div>
			</div>

		</trustifi-table-c>
		<div class="flex-row flex-wrap flex-shrink: 0; justify-content-start gap-2 align-items-baseline" style="overflow: auto; height: 86px;">
			<span *ngFor="let type of _.filter(types, 'selected') | unique:'name'"
				  class="p-1 d-flex gap-3 align-items-center rounded-1 bg-dark"
				  (click)="deselectType(type);"
				  style="cursor: pointer; --bs-bg-opacity: .07;">
				{{type.name}} <i class="zmdi zmdi-close"></i>
			</span>
		</div>
	</div>
</popup-c>
