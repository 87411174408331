<!--exchange connector/rules popup-->
<popup-c class="no-content-padding bs-enabled" size="large"
	   head="Set <b>{{isInboundConfiguration ? 'Inbound' : 'Outbound'}}</b> Connector & Rules on Exchange"
	   subTitle="Use this tool to configure connectors and rules to integrate Trustifi in Microsoft Exchange"
	   (closeCb)="closePopup();">
	<div class="flex-column overflow-auto f-1-0 pt-2 gap-2" style="padding: 20px;">
		<!--steps progress-->
		<div class="steps-container" style="font-size: 0.8rem;">
			<div class="step-tab"
				 *ngFor="let stepKey of validSteps | keyvalue: gs.returnZero"
				 [ngClass]="{'active' : step === stepKey.value}">
				{{stepKey.value}}
			</div>
		</div>

		<!--Steps content:-->
		<!--STEP: General instructions-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-2 pt-3"
			 style="line-height: 2;"
			 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation">
                    <span style="position: relative; padding-left: 15px;" class="diamond-indent">
                        This wizard will set up connectors and rules in Microsoft Exchange to integrate your mail flow with Trustifi.
                    </span>
					<span style="position: relative; padding-left: 15px;" class="diamond-indent">
                        After the wizard is finished, the mail flow rule created for Trustifi will be disabled by default.<br/>
                        To integrate your mail flow with Trustifi you will need to enable this rule.
                    </span>
			<br/>
			<div class="flex-row gap-2 align-items-baseline">
				<i class="fa fa-exclamation-triangle opacity-25"></i>
				<span class="fw-bold">Before taking the next step:</span>
			</div>

			<div class="bg-light p-2">
				<ul style="list-style-type: number;">
					<li>
						<span>Make sure your PowerShell integration with Trustifi is active and valid</span>
					</li>
					<li>
						<span>Make sure remote PowerShell access is enabled in your Exchange server</span>
					</li>
				</ul>
			</div>
		</div>

		<!--STEP: Select domains-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-2 pt-3"
			 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.domains">
			<span>Select one or more domains to configure connector or rules for:</span>
			<div class="flex-column f-1-0 overflow-auto gap-2 px-2">
				<label class="radio-container m-0" style="display: block;">
					All current domains
					<input type="radio"
						   [(ngModel)]="domainSelectionType.type"
						   [value]="domainSelectionTypes.all">
					<span class="checkmark"></span>
				</label>
				<div class="flex-row gap-1 align-items-center">
					<label class="radio-container m-0" style="display: block; width: fit-content;">
						Specific domains:
						<input type="radio"
							   [(ngModel)]="domainSelectionType.type"
							   [value]="domainSelectionTypes.specific">
						<span class="checkmark"></span>
					</label>
					<span class="animated fadeIn" *ngIf="domainSelectionType.type === domainSelectionTypes.specific">
                                ({{selectedDomains.length || '0'}} selected)
                            </span>
				</div>
				<div class="position-relative f-1-0 flex-column overflow-auto">
					<!--table overlay when disabled-->
					<div class="w-100 h-100" style="background-color: white; position: absolute; top: 0; left: 0; z-index: 20; opacity: 0.4;"
						 *ngIf="domainSelectionType.type === domainSelectionTypes.all">
					</div>
					<trustifi-table-c
						class="trustifi-table f-1-0" style="z-index: 5;"
						[list]="domainsArr"
						[cellsPrototype]="[
							  {textKey: 'domain'},
						  ]"
						(searchFunction)="searchDomain($event.searchTerm, $event.activeFilters)"
						(onAnyItemSelected)="filterSelectedDomains($event.item)"
						[options]="{
							itemsNameSingular: 'domain',
							showRadioButtons: true,
							noHeader: true,
							showCounter: true,
						}"
					>
					</trustifi-table-c>
				</div>
			</div>
		</div>

		<!--STEP: Configuration-->
		<div class="flex-column overflow-auto animated fadeIn f-1-0 gap-3 p-2 pt-3"
			 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.configuration">
			<div class="list-group list-group-flush settings-list-container overflow-auto">

				<div class="flex-row mx-2 align-items-baseline gap-2">
					<i class="fa fa-info-circle text-info"></i>
					<span>All inputs below are optional</span>
				</div>

				<div class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Enable mail flow rule</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">New mail flow rules are disabled by default. Choose this option to automatically enable the new mail flow rule after it's created.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="enableDefault" type="checkbox"
								   [(ngModel)]="isRuleEnabledByDefault.enabled"/>
							<label for="enableDefault" style="margin: 0;">Enable</label>
						</div>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-4" *ngIf="isInboundConfiguration">
					<div class="title-section-container flex-column gap-2">
						<b>Scan internal emails</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">By default, only external emails will be scanned. Enable this option to scan internal emails as well</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="scanInternal" type="checkbox"
								   [(ngModel)]="scanInternal.enabled"/>
							<label for="scanInternal" style="margin: 0;">Enable</label>
						</div>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-4" *ngIf="isInboundConfiguration">
					<div class="title-section-container flex-column gap-2">
						<b>Sync blocklists</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Blocklists will be synced from Exchange and imported into Trustifi</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="internalAllowBlockList" type="checkbox"
								   [(ngModel)]="allowBlockList.enabled"/>
							<label for="internalAllowBlockList" style="margin: 0;">Enable</label>
						</div>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-4">
					<div class="title-section-container flex-column gap-2">
						<b>Configure using "Your organization" connectors</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">
                                    By default, connectors are created using the "Partner organization" type which is supported by all Exchange tenants.<br/>
                                    Select this option will use the "Your organization" type connectors, which may not be supported by newer Exchange tenants.
                                </span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="useOldMethod" type="checkbox"
								   [(ngModel)]="useOldMethod.enabled"/>
							<label for="useOldMethod" style="margin: 0;">Enable</label>
						</div>
					</div>
				</div>

				<div class="list-group-item settings-list-item py-4" *ngIf="!isInboundConfiguration">
					<div class="title-section-container flex-column gap-2">
						<b>External recipients only</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Apply rule to external recipients only.</span>
					</div>
					<div class="content-container flex-column gap-2">
						<div class="flxRow-start md-checkbox" style="align-items: center;">
							<input id="externalRecipientsOnly" type="checkbox"
								   [(ngModel)]="externalRecipientsOnly.enabled"/>
							<label for="externalRecipientsOnly" style="margin: 0;">Enable</label>
						</div>
					</div>
				</div>

				<!--targetType = sender/recipient/header
				----valueType = email/domain/IP/group-->
				<div style="display: contents;">
					<h5 class="px-3 pt-4 text-capitalize">Conditions</h5>
					<div class="list-group-item settings-list-item py-4" style="border-width: 0 0 var(--bs-list-group-border-width);"
						 *ngFor="let targetType of targetTypes | keyvalue: gs.returnZero">
						<div class="title-section-container flex-column gap-2">
							<b>Apply rule for specific {{targetType.value + (targetType.value === targetTypes.header ? '' : 's')}} only</b>
							<span class="text-muted lh-base" style="font-size: 0.9em;">If you want to use this rule with specific {{targetType.value + (targetType.value === targetTypes.header ? ' only, add it here' : 's only, add them here')}} </span>
							<div class="buttons-group flex-row gap-2" *ngIf="targetType.value !== targetTypes.header">
								<button class="bs-btn bs-btn-light h-100"
										style="min-width: 75px;"
										*ngFor="let valueType of valueTypes | keyvalue: gs.returnZero"
										[ngClass]="{'chosen': conditionArrays[targetType.value]['selectedValueType'].toString() === valueType.value.toString(),
													'd-none': targetType.value === targetTypes.recipient && valueType.value === valueTypes.IP
													}"
										(click)="conditionArrays[targetType.value]['selectedValueType'] = valueType.value; conditionInputs[targetType.value] = ''; errorMsg = '';">
									<span class="w-100 text-center">{{valueType.value}}</span>
								</button>
							</div>
						</div>
						<div class="content-container flex-column gap-2 animated fadeIn" *ngIf="targetType.value !== targetTypes.header && conditionArrays[targetType.value]">
							<span>Specify <b>{{targetType.value}}s</b> by {{conditionArrays[targetType.value]['selectedValueType'] === valueTypes.group ? 'group email' : conditionArrays[targetType.value]['selectedValueType'] === valueTypes.domain ? conditionArrays[targetType.value]['selectedValueType'] : conditionArrays[targetType.value]['selectedValueType'] + ' address'}}:</span>

							<div class="flex-row align-items-center">
								<input class="f-1-0" type="text" style="max-width: 300px; margin-bottom: 0; border-top-right-radius: 0; border-bottom-right-radius: 0;"
									   placeholder="{{'Add ' + (conditionArrays[targetType.value]['selectedValueType'] === valueTypes.group ? 'group email' : conditionArrays[targetType.value]['selectedValueType'] === valueTypes.domain ? conditionArrays[targetType.value]['selectedValueType'] : conditionArrays[targetType.value]['selectedValueType'] + ' address') + ' here'}}"
									   (keydown.enter) = "addConditionOrExceptionValue(conditionArrays[targetType.value][conditionArrays[targetType.value]['selectedValueType']], targetType.value, conditionArrays[targetType.value]['selectedValueType'], conditionInputs[targetType.value], conditionInputs)"
									   [(ngModel)]="conditionInputs[targetType.value]">
								<btn-c class="connected-to-left" style="height: 30px; min-width: 0;"
									 (action)="addConditionOrExceptionValue(conditionArrays[targetType.value][conditionArrays[targetType.value]['selectedValueType']], targetType.value, conditionArrays[targetType.value]['selectedValueType'], conditionInputs[targetType.value], conditionInputs);">
									Add
								</btn-c>
							</div>
							<div class="overflow-auto flex-column border px-1 py-2" style="min-height: 200px;"
								 [ngClass]="{'centralize' : !conditionArrays[targetType.value][conditionArrays[targetType.value]['selectedValueType']].length}">
								<table class="simpleDataTableShrink w-100 align-self-start animated fadeIn" style="font-size: 0.8rem;"
									   *ngIf="conditionArrays[targetType.value][conditionArrays[targetType.value]['selectedValueType']].length">
									<tbody>
									<tr *ngFor="let record of conditionArrays[targetType.value][conditionArrays[targetType.value]['selectedValueType']]">
										<td><span>{{record}}</span></td>
										<td style="width: 35px;">
											<a class="closeRed" (click)="removeConditionOrExceptionValue(conditionArrays[targetType.value][conditionArrays[targetType.value]['selectedValueType']], record)">
												<i class="fa fa-times"></i>
											</a>
										</td>
									</tr>
									</tbody>
								</table>
								<span class="animated fadeIn" *ngIf="!conditionArrays[targetType.value][conditionArrays[targetType.value]['selectedValueType']].length">No records</span>
							</div>
						</div>
						<div class="content-container flex-column gap-2 animated fadeIn" *ngIf="targetType.value === targetTypes.header">
							<span>Specify <b>header</b> to apply this rule to:</span>
							<span>Header name:</span>
							<input type="text" class="w-100"
								   [ngClass]="{'validation-error' : errorMsg && ((conditionArrays.header.value && !conditionArrays.header.key) || conditionArrays.header.key.trim().includes(' '))}"
								   [(ngModel)]="conditionArrays.header.key">
							<span>Header value:</span>
							<input type="text" class="w-100"
								   [ngClass]="{'validation-error' : errorMsg && conditionArrays.header.key && !conditionArrays.header.value}"
								   [(ngModel)]="conditionArrays.header.value">
						</div>
					</div>
				</div>

				<div style="display: contents;">
					<h5 class="px-3 pt-4 text-capitalize">Exceptions</h5>
					<div class="list-group-item settings-list-item py-4" style="border-width: 0 0 var(--bs-list-group-border-width);"
						 *ngFor="let targetType of targetTypes | keyvalue: gs.returnZero">
						<div class="title-section-container flex-column gap-2">
							<b>Skip rule for specific {{targetType.value + (targetType.value === targetTypes.header ? '' : 's')}} only</b>
							<span class="text-muted lh-base" style="font-size: 0.9em;">If you want to skip this rule with specific {{targetType.value + (targetType.value === targetTypes.header ? ' only, add it here' : 's only, add them here')}} </span>
							<div class="buttons-group flex-row gap-2" *ngIf="targetType.value !== targetTypes.header">
								<button class="bs-btn bs-btn-light h-100"
										style="min-width: 75px;"
										*ngFor="let valueType of valueTypes | keyvalue: gs.returnZero"
										[ngClass]="{'chosen': exceptionArrays[targetType.value]['selectedValueType'].toString() === valueType.value.toString(),
													'd-none': targetType.value === targetTypes.recipient && valueType.value === valueTypes.IP
													}"
										(click)="exceptionArrays[targetType.value]['selectedValueType'] = valueType.value; exceptionInputs[targetType.value] = ''; errorMsg = '';">
									<span class="w-100 text-center">{{valueType.value}}</span>
								</button>
							</div>
						</div>
						<div class="content-container flex-column gap-2 animated fadeIn" *ngIf="targetType.value !== targetTypes.header && exceptionArrays[targetType.value]">
							<span>Specify <b>{{targetType.value}}s</b> by {{exceptionArrays[targetType.value]['selectedValueType'] === valueTypes.group ? 'group email' : exceptionArrays[targetType.value]['selectedValueType'] === valueTypes.domain ? exceptionArrays[targetType.value]['selectedValueType'] : exceptionArrays[targetType.value]['selectedValueType'] + ' address'}}:</span>

							<div class="flex-row align-items-center">
								<input class="f-1-0" type="text" style="max-width: 300px; margin-bottom: 0; border-top-right-radius: 0; border-bottom-right-radius: 0;"
									   placeholder="{{'Add ' + (exceptionArrays[targetType.value]['selectedValueType'] === valueTypes.group ? 'group email' : exceptionArrays[targetType.value]['selectedValueType'] === valueTypes.domain ? exceptionArrays[targetType.value]['selectedValueType'] : exceptionArrays[targetType.value]['selectedValueType'] + ' address') + ' here'}}"
									   (keydown.enter) = "addConditionOrExceptionValue(exceptionArrays[targetType.value][exceptionArrays[targetType.value]['selectedValueType']], targetType.value, exceptionArrays[targetType.value]['selectedValueType'], exceptionInputs[targetType.value], exceptionInputs)"
									   [(ngModel)]="exceptionInputs[targetType.value]">
								<btn-c class="connected-to-left" style="height: 30px; min-width: 0;"
									   (action)="addConditionOrExceptionValue(exceptionArrays[targetType.value][exceptionArrays[targetType.value]['selectedValueType']], targetType.value, exceptionArrays[targetType.value]['selectedValueType'], exceptionInputs[targetType.value], exceptionInputs);">
									Add
								</btn-c>
							</div>
							<div class="overflow-auto flex-column border px-1 py-2" style="min-height: 200px;"
								 [ngClass]="{'centralize' : !exceptionArrays[targetType.value][exceptionArrays[targetType.value]['selectedValueType']].length}">
								<table class="simpleDataTableShrink w-100 align-self-start animated fadeIn" style="font-size: 0.8rem;"
									   *ngIf="exceptionArrays[targetType.value][exceptionArrays[targetType.value]['selectedValueType']].length">
									<tbody>
									<tr *ngFor="let record of exceptionArrays[targetType.value][exceptionArrays[targetType.value]['selectedValueType']]">
										<td><span>{{record}}</span></td>
										<td style="width: 35px;">
											<a class="closeRed" (click)="removeConditionOrExceptionValue(exceptionArrays[targetType.value][exceptionArrays[targetType.value]['selectedValueType']], record)">
												<i class="fa fa-times"></i>
											</a>
										</td>
									</tr>
									</tbody>
								</table>
								<span class="animated fadeIn" *ngIf="!exceptionArrays[targetType.value][exceptionArrays[targetType.value]['selectedValueType']].length">No records</span>
							</div>
						</div>
						<div class="content-container flex-column gap-2 animated fadeIn" *ngIf="targetType.value === targetTypes.header">
							<span>Specify <b>header</b> to exclude from this rule:</span>
							<span>Header name:</span>
							<input type="text" class="w-100"
								   [ngClass]="{'validation-error' : errorMsg && ((exceptionArrays.header.value && !exceptionArrays.header.key) || exceptionArrays.header.key.trim().includes(' '))}"
								   [(ngModel)]="exceptionArrays.header.key">
							<span>Header value:</span>
							<input type="text" class="w-100"
								   [ngClass]="{'validation-error' : errorMsg && exceptionArrays.header.key && !exceptionArrays.header.value}"
								   [(ngModel)]="exceptionArrays.header.value">
						</div>
					</div>
				</div>
			</div>

			<div class="flex-column gap-2 px-2">
				<hr class="mt-0"/>
				<span>After clicking <b>Configure</b>, Trustifi will create the following connectors and rule in Exchange:</span>
				<ul style="list-style-type: circle;" *ngIf="isInboundConfiguration">
					<li>
						<span>Connector 1: <b>Trustifi Inbound Connector (Receive)</b></span>
					</li>
					<li>
						<span>Connector 2: <b>Trustifi Inbound Connector (Send)</b></span>
					</li>
					<li>
						<span>Rule: <b>Trustifi Inbound Relay</b></span>
					</li>
				</ul>
				<ul style="list-style-type: circle;" *ngIf="!isInboundConfiguration">
					<li>
						<span>Connector: <b>Trustifi Outbound Connector (Send)</b></span>
					</li>
					<li>
						<span>Rule: <b>Trustifi Outbound Relay</b></span>
					</li>
				</ul>
			</div>
		</div>

		<!--STEP: Summary-->
		<div class="flxClmn f-1-0 animated fadeIn gap-3 p-2 pt-3" *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.summary">
                    <span>
                        The Trustifi connectors and rules are being configured on Exchange.
                        <br/><br/>
                        <span style="font-size: 13px;"><u>Note</u>: this process may take a couple of minutes to finish.</span>
                    </span>
			<br/><br/>
			<br/>
			<br/>
			<div class="border w-100 p-4 flex-column gap-3" style="max-width: 520px; background: white;"
				 [ngClass]="{'text-danger border-danger' : rulesStatus.status === 'failure', 'text-success border-success' : rulesStatus.status === 'success'}"
				 *ngIf="rulesStatus && rulesStatus.status">
                        <span class="fw-bold">
                            {{rulesStatus.status === 'failure' ? 'Configuration process failed' :
							rulesStatus.status === 'success' ? 'Configuration process finished successfully' : 'Looks like the configuration process hasn\'t finished yet.'}}
                        </span>
				<span *ngIf="rulesStatus.message">{{rulesStatus.message}}</span>
			</div>

			<div class="flxClmn centralize" style="flex: 1 0;" *ngIf="generateTokenInProcess">
				<loader height="100" width="100" style="opacity: 0.8;"></loader>
				<span class="text-primary mt-1 fw-500">Creating Trustifi connectors and rules</span>
				<span class="text-primary">Please wait. Process may take a while...</span>
			</div>
		</div>

		<!--error message and buttons-->
		<div class="flex-row justify-content-between align-items-end mb-3 px-4 w-100">
                    <span class="f-1-0 pe-3 text-danger" style="min-height: 40px;">
                        {{errorMsg || ' '}}
                    </span>

			<div class="flex-row gap-3" style="font-size: 13px;">
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					 variant="outlined"
					 (action)="closePopup();">
					Cancel
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step !== dic.CONSTANTS.exchangeRulesSteps.generalInformation"
					 variant="outlined"
					 (action)="backStep();">
					Back
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step !== dic.CONSTANTS.exchangeRulesSteps.summary && step !== dic.CONSTANTS.exchangeRulesSteps.configuration"
					 (action)="nextStep();">
					Next
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.configuration"
					 (action)="nextStep();">
					Configure
				</btn-c>
				<btn-c class="popup-action-btn"
					 *ngIf="step === dic.CONSTANTS.exchangeRulesSteps.summary"
					 (action)="closePopup();">
					OK
				</btn-c>
			</div>
		</div>
	</div>
</popup-c>
