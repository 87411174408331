import _ from "lodash";
import util from "util";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DICTIONARY} from "../../dictionary";
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {GeneralService} from "../../services/generalService";

@Component({
	selector: 'aliases-popup-c',
	templateUrl: './aliases-popup.component.html',
})
export class AliasesPopupComponent implements OnInit{

	@Output() cancelCb? = new EventEmitter<any>;
	@Output() doneCb? = new EventEmitter<any>;
	@Input() doneBtnTxt;
	@Input() selectedGroups;
	@Input() popupTitle;
	@Input() popupSubTitle;
	@Input() multipleSelection;
	@Input() showActions;

	dic = DICTIONARY;
	initiated = false;
	getAliasesInProcess = false;
	aliases = [];
	groups = [];
	uploadAliasesInProcess = false;

	constructor(private rs:RouteService, private gs:GeneralService, private ns:NotificationService) {

	}

	ngOnInit() {
		this.getAliases();
		this.initiated = true;
	}

	getAliases() {
		this.getAliasesInProcess = true;
		this.rs.getMailboxesAliases().then((response) => {
			this.aliases = _.map(response.mailboxes_aliases, (value, key) => {
				return {alias: key, email: value};
			});

			this.getAliasesInProcess = false;
		});
	}

	showAliasActions = (aliasObj) => {
		return [
			this.gs.toCapitalize(this.dic.CONSTANTS.groupsActions.delete)
		];
	}

	selectAliasAction = (aliasObj, action) => {
		switch (action) {
			case this.gs.toCapitalize(this.dic.CONSTANTS.groupsActions.delete):
				this.openDeleteAliasPopup(aliasObj);
				break;
		}
	}

	openDeleteAliasPopup(aliasObj) {
		this.gs.showPopup({
			title: 'Delete Alias',
			subTitle: `Please note - alias ${aliasObj.alias} will be deleted`,
			body: ['Any rule created specifically for this alias will no longer be relevant'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				this.deleteAlias(aliasObj)
			}
		});
	}

	deleteAlias(aliasObj) {
		this.rs.deleteAlias(aliasObj.alias).then(res => {
			_.remove<any>(this.aliases, g => g.alias === aliasObj.alias);
			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.aliasDeleted, aliasObj.alias))
		});
	}

	searchAlias = (event) => {
		this.aliases.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = searchAliasTextExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	openUploadAliasesPopup() {
		const body = [
			'File type must be a Comma Separated Value (.csv)',
			'The first row must contain these headers: email, alias'
		];
		this.gs.showPopup({
			title: 'Import List',
			subTitle: `To ensure ${this.gs.corpname} can successfully handle your list, it should meet the following conditions:`,
			body: body,
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'OK',
			isFile: true,
			doneCb: (files) => {
				if (!files || !files.length) return;
				this.uploadAliasesFromCsv(files[0]);
			}
		});
	}

	uploadAliasesFromCsv(file) {
		if (!file) return;
		const validationFunctions = [this.gs.validateEmail];
		this.gs.readCsv(file, ['email', 'alias'], null, this.dic.CONSTANTS.EMAIL_REGEX, validationFunctions, (err, result) => {
			if (err) {
				return;
			}
			if (!result.email || !result.email.length || !result.alias || !result.alias.length) {
				this.ns.showWarnMessage(this.dic.ERRORS.groupsInvalidCsv)
				return;
			}

			let aliasData = [];
			for (let i = 0; i < result.email.length; i++) {
				if (result.email[i] && result.alias[i]) {
					aliasData.push({email: result.email[i].trim(), alias: result.alias[i].trim()});
				}
			}

			this.uploadAliasesInProcess = true;
			this.rs.uploadMailboxesAliases(aliasData).then(() => {
				this.ns.showInfoMessage(util.format(this.dic.MESSAGES.aliasesUploaded, aliasData.length))
				this.getAliases();
				this.uploadAliasesInProcess = false;
			}, (err) => {
				this.uploadAliasesInProcess = false;
			});
		});
	}
}

function searchAliasTextExecute(alias, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return ((alias.alias && alias.alias.toLowerCase().indexOf(searchTerm) >= 0) ||
        (alias.email && alias.email.toLowerCase().indexOf(searchTerm) >= 0));
}
