<div id="wrapper" class="purchase-page top-level-page " >

	<notification-toaster-component></notification-toaster-component>

    <!-- header -->
    <header class="header" id="header">
        <div class="header-wrap">
            <div class="header-logo-container">
                <a href="https://trustifi.com/" class="header-logo">
                    <img src="images/logo.png" alt="logo" class="template-image color-logo-image" style="max-width: 160px;">
                </a>
            </div>
            <div class="header-items-container">
                <div class="request-demo-btn-container">
                    <a href="https://trustifi.com/demo/" class="link-btn small-btn">
                        Get A Quote
                    </a>
                </div>
                <div class="request-demo-btn-container">
                    <a class="link-btn small-btn" style="background: white; box-shadow: none; border: solid 2px dimgrey;"
                       (click)="navigateBack()">
                    Go Back
                    </a>
                </div>
            </div>
        </div>
    </header>

    <!-- content -->
    <div class="total-wrap">
        <div class="text-page-template block-page purchase-licenses-page">
            <!-- start inner intro section -->
            <div class="intro-top-holder">
                <div class="inner-top-section">
                    <div class="main-wrap">
                        <div class="breadcrumb-row">
                            <a href="" class="breadcrumb-link">Home</a>
                            <span class="breadcrumb-separator">/</span>
                        </div>
                    </div>
                    <div class="block-page-into-top">
                        <div class="main-wrap z-1">
                            <div class="inner-page-title-wrap">
                                <h1 class="main-title">
                                    Purchase Licenses Now
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- start inner intro text section -->
                <div class="block-intro-section">
                    <div class="main-wrap">
                        <div class="template-grid fz-0">
                            <div class="template-grid-item demo-text-col col col-m-100 col-t-100 col-d-50">
                                <div class="template-grid-item-inner">

                                    <div class="two-col-text-item commercial-section">
                                        <div class="title-wrap">
                                            <h2 class="regular-title">
                                                Everything you need to keep your organization protected:
                                            </h2>
                                        </div>
                                        <div class="text-page-block mt-60">
                                            <h3>Outbound Shield™</h3>
                                            <ul>
                                                <li>
                                                    Send unlimited encrypted emails
                                                </li>
                                                <li>
                                                    Multifactor Authentication
                                                </li>
                                                <li>
                                                    Upload files/attachments up to 1GB
                                                </li>
                                                <li>
                                                    20GB of Fully-Encrypted-Storage for your data
                                                </li>
                                                <li>
                                                    Tracking and Postmark Proof
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="text-page-block mt-40">
                                            <h3>Inbound Shield™</h3>
                                            <ul>
                                                <li>
                                                    Threat free mailboxes - Full scan of all email components (headers, links, attachments), using Trustifi’s sophisticated engines that are updated daily to keep you safe from the latest email attacks like:
                                                    <ul>
                                                        <li>
                                                            Phishing
                                                        </li>
                                                        <li>
                                                            Spoofing
                                                        </li>
                                                        <li>
                                                            Impersonation
                                                        </li>
                                                        <li>
                                                            BEC
                                                        </li>
                                                        <li>
                                                            Malware
                                                        </li>
                                                        <li>
                                                            New threats like Zero-Day
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Threat response system

                                                </li>
                                                <li>
                                                    Advanced allowlist and blocklisting capabilities
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="text-page-block mt-40">
                                            <h3>More Features:</h3>
                                            <ul>
                                                <li>
                                                    Easily deployed with Microsoft Outlook, Google Workspace
                                                <li>
                                                    Suspicious activity detection
                                                </li>
                                                <li>
                                                    Friendly reporting system - Users can report suspicious emails using the add-in or directly from the email itself
                                                </li>
                                                <li>
                                                    Integrations with 3rd party applications
                                                </li>
                                                <li>
                                                    Real time reports
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="template-grid-item demo-form-col col col-m-100 col-t-100 col-d-50">
                                <div class="licenses-row" style="align-items: center;">
                                    <div class="flxClmn vSpace1" style="background: white; border-radius: 5px; border: solid 1px lightgrey;">
                                        <span style="font-size: 1rem; padding: 25px 0 0; text-align: center;">Please fill in the form</span>
										<signup-form-component class="bs-enabled f-1-0"
															   #signupForm
															   style="width: 80%; margin: auto; min-height: 360px; padding: 35px 0; font-size: initial;"
															   [signupData]="signupData"
															   [isPurchasePage]="true">
										</signup-form-component>
                                        <span style="font-size: 0.85rem; padding: 0 0 20px; text-align: center; color: darkslategrey;">
                                            Already registered to Trustifi? Log-in and purchase licenses
                                            <span style="text-decoration: underline; cursor: pointer; color: royalblue;" (click)="loginAndPurchase()">here</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="top-price-row">
                                    <span class="top-price-large-text">
                                        PRO
                                    </span>
                                    <span class="top-price">
                                        <span class="top-price-currency">$</span>
                                        <span class="top-price-value">{{price_yearly}}</span>
                                    </span>
                                    <span class="top-price-small-text">
                                        Per User Annually
                                    </span>
                                </div>
                                <div class="licenses-row">
                                    <form action='' class="quantity-form" autocomplete="off">
                                        <div class="quantity-form-row">
                                            <div class="quantity-form-row-item" (clickOutside)="partner.showMaxUsersErrorMsg = false;">
                                                <label for="quantity-input" class="quantity-input-label">Number of licenses per year</label>
                                                <input class="quantity-input" id="quantity-input"
                                                       name="quantity" type="number"
                                                       [(ngModel)]="numUsers"
                                                       (change)="validateMinMaxUsers(numUsers)"
                                                       pattern="/^[1-9]$|^1[0-9]$|^20$/"
                                                       min="1" [max]="partner.maxUsers"/>
                                            </div>
                                            <span class="quantity-total-text" >
                                                (up to {{partner.maxUsers || 10}})
                                            </span>
                                        </div>
                                    </form>
                                    <span style=" color: red; font-size: 0.8rem; position: absolute; bottom: -18px; right: 10px; font-family: 'Roboto', sans-serif; text-shadow: 0 0 black;"
                                          *ngIf="partner.showMaxUsersErrorMsg">
                                        * maximum of 10 users
                                    </span>
                                </div>
                                <div class="underline-row">
                                    <span class="price-row-text">
                                        <strong>Need more users?</strong>
                                    </span>
                                    <a href="https://www.trustifi.com/get-a-quote/" class="underline-link-btn">
                                        <span class="btn-line-text">
                                            Get a quote now
                                        </span>
                                        <span class="line-wrap">
                                            <span class="line"></span>
                                        </span>
                                    </a>
                                </div>
                                <div class="underline-row amount-row">
                                    <div class="amount-row-inner">
                                        <span class="price-row-text">
                                            <strong>Total amount to pay:</strong>
                                        </span>

                                        <span class="amount-price">
                                            <span class="top-price-currency">$</span>
                                            <span class="top-price-value">{{(price_yearly * numUsers) | number : '1.2-2'}}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="flxRow-center modal-container show-on-hover-container-1" style="margin: 25px auto; width: fit-content; position: relative;">
                                    <span style="width: 100%; font-size: 1rem; color: #7fb7dc; text-align: center; cursor: pointer;">
                                        What will happen after I sign up and purchase?
                                    </span>
                                    <div class="modal-info show-on-hover-item-1" style=" height: 500px; width: 700px; max-width: 95vw; max-height: 61vh; transform: translate(0, calc(-100% - 10px)); ">
                                        <ng-container *ngFor="let step of purchaseStepsInfo; let index = index;">
											<div class="flxClmn-around purchase-step-container" style="height: 100%; /*opacity: 0;*/ align-items: center;"
												 *ngIf="purchaseStepsChosenIdx === index">
												<div class="flxRow-center" style="align-items: center; border-radius: 50%; background: #7fb7dc; height: calc(65px + 2vw); width: calc(65px + 2vw);">
													<i style="font-size: calc(28px + 1.1vw); color: white;" [ngClass]="step.iconClass"></i>
												</div>
												<span style="text-align: center; font-size: 19px; color: #7fb7dc;">Step {{index + 1}}</span>
												<div class="flxClmn-center vSpace2" style="flex: 1 0; max-height: 35%;">
                                                <span style="color: #5687a7; text-align: center; font-size: calc(15px + 0.3vw); line-height: 1.3;"
													  [innerHTML]="step.text">
                                                </span>
													<span style="text-align: center; font-size: 16px; color: #7fb7dc;"
														  [innerHTML]="step.subText">
                                                </span>
												</div>
												<div class="flxRow-center hSpace1">
													<div *ngFor="let purchaseStep of purchaseStepsInfo; let index = index;"
														 style="background: #7fb7dc; border-radius: 50%; cursor: pointer; width: 12px; height: 12px;"
														 (click)="purchaseStepsChosenIdx = index"
														 [ngStyle]="{'opacity' : purchaseStepsChosenIdx === index ? '1' : '0.5'}"></div>
												</div>
												<div class="flxRow-around" style="width: 100%; font-size: 16px; color: #5687a7; cursor: pointer;">
													<div class="flxRow hSpace1" style="transition: opacity 0.15s;"
														 [ngClass]="{'disabled-overlay' : purchaseStepsChosenIdx === 0}"
														 (click)="navigatePurchaseSteps('previous')">
														<i class="fas fa-chevron-left" ></i>
														<span>previous</span>
													</div>
													<div class="flxRow hSpace1" style="transition: opacity 0.15s;"
														 (click)="navigatePurchaseSteps('next')"
														 [ngClass]="{'disabled-overlay' : purchaseStepsChosenIdx === purchaseStepsInfo.length - 1}">
														<span>next</span>
														<i class="fas fa-chevron-right"></i>
													</div>
												</div>
											</div>
										</ng-container>
                                    </div>
                                </div>
                                <div class="payment-btns-container" style="padding: 0; position: relative; z-index: 20;">
                                    <span class="hvr-darken" style="cursor: pointer; font-size: 13px; margin-top: -20px; margin-bottom: 20px; color: red; opacity: 0.7; text-decoration: underline;"
                                          *ngIf="!(signupData.accountDetails.firstName && signupData.accountDetails.lastName &&
                                                signupData.accountDetails.email && validateEmail(signupData.accountDetails.email) &&
                                                signupData.accountDetails.password  && signupData.accountDetails.reTypePassword  &&
                                                signupData.accountDetails.reTypePassword === signupData.accountDetails.password && numUsers)"
                                          (click)="signupForm.validateSignUpForm(); scrollToTop();">
                                        Complete the above sign up form before payment
                                    </span>
									<div class="paypal-wrapper" style="width: 100%;"
										 [ngClass]="{'disabled-overlay' : !(signupData.accountDetails.firstName && signupData.accountDetails.lastName &&
                                                signupData.accountDetails.email && validateEmail(signupData.accountDetails.email) &&
                                                signupData.accountDetails.password  && signupData.accountDetails.reTypePassword  &&
                                                signupData.accountDetails.reTypePassword === signupData.accountDetails.password && numUsers)}">
										<paypal-buttons-component
											[hasAccessToken]="false"
											[type]="dic.CONSTANTS.paypalPaymentType.newUser"
											[partner]="partner"
											[numUsers]="numUsers"
											[accountDetails]="signupData.accountDetails"
											(onComplete)="paypalComplete($event.err, $event.res)">
										</paypal-buttons-component>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
