<div>
    <div class="tab-content-loader-container" *ngIf="getTPConfigInProcess">
        <loader height="100" width="100" style="opacity: 0.8;"></loader>
    </div>
    <div class="flex-column gap-4" *ngIf="!getTPConfigInProcess && planUsers && planUsers?.length">
        <div class="list-group list-group-flush settings-list-container">
            <!--Choose user for lists banner-->
            <div class="list-group-item mw-100 settings-list-item pt-0 pb-2 animated fadeIn" style="z-index: 20; background: linear-gradient(0deg, #f7f7f7, #f7f7f700) !important; height: 50px;">
                <div class="flex-row w-100 gap-5 align-items-center" style="max-width: 1200px;">
                    <div class="flxRow hSpace2" style="align-items: center;">
                        <div>View lists for:</div>
                        <app-dropdown-c style="width: 260px; height: 32px;"
                                      [enableSearch]="true"
                                      [items]="planUsers"
                                      itemDisplayKey="email"
                                      (onItemClicked)="changeUser($event.item)"
                                      text="{{currentUser}}"></app-dropdown-c>
                    </div>
                    <div class="flex-column gap-2">
                        <a class="flex-row gap-1 align-items-center position-relative"
                           [ngClass]="{'disabled' : exportAllListsToCsvInProcess}"
                           (click)="exportAllListsToCsv()">
                            <i class="fas fa-file-export fa-sm"></i>
                            <span style="white-space: nowrap;">Generate user lists report</span>
                            <div class="loading-spinner" style="left: -20px; transform: translate(50%, -100%); top: 1px; width: 11px; height: 11px; border-width: 1px;"
                                 tooltip="Generating report... Might take a while"
                                 *ngIf="exportAllListsToCsvInProcess"></div>
                        </a>
                        <a class="flex-row gap-1 align-items-center" (click)="showRecipientConfig()">
                            <i class="fa fa-cog"></i>
                            <span style="white-space: nowrap;">Settings</span>
                        </a>
                    </div>
                </div>
            </div>

            <!--Settings: Block/Allow Senders -->
            <div class="list-group-item mw-100 settings-list-item column-style"
				 style="padding-top: 10px !important;"
				 *ngIf="gs.getTabName() === dic.CONSTANTS.inboundConfigurationPageTabs.senderLists">
                <div class="content-container flex-column gap-3">
                    <!--Senders Whitelist-->
                    <div class="flex-column gap-3">
                        <div class="flex-column gap-2">
                            <div class="flex-row align-items-center gap-1">
                                <i class="fa fa-check-circle text-success" style="font-size: 0.7rem;"></i>
                                <b style="font-size: 0.845rem;">Senders Allowlist</b>
                            </div>
                            <span class="text-muted lh-base" style="font-size: 0.9em;">Emails from senders in this list will always be considered safe</span>
                        </div>
                        <div class="flxClmn vSpace1 animated fadeIn" style="background-color: white; height: max(330px,25vw);">
                            <trustifi-table-c
                                    class="trustifi-table" style="flex: 1 0; z-index: 5; height: 330px;"
                                    [list]="currentLists.senders_whitelist"
                                    [headers]="[
										{text: 'Sender', sortKey: 'email',  width: '33%'},
										{text: 'Match Type', width: '130px', sortKey: 'matchType', tooltip: 'For domains, IP, and CIDR only the Exact Match type is fully supported'},
										{text: 'Description', width: '37%' },
										{text: 'Updated by', width: '37%' },
										{text: 'Last Update', sortKey: 'created', width: '20%' },
										{text: 'Last Used', sortKey: 'last_used', width: '20%' },
									]"
                                    [cellsPrototype]="[
										{html: 'whitelistSenderEmailCell', edit: {modelKey: 'email', placeholder: 'Email/Domain/IP/CIDR'}},
										{textKey: 'matchType', overflowOutside: true, edit: {html: 'senderWhitelistMatchTypeCellEdit'}, style: {'text-transform' : 'capitalize'}},
										{textKey: 'description', tooltip: true, edit: {modelKey: 'description', placeholder: 'Description'}},
										{textKey: 'updated_by'},
										{textKey: 'created', textType: 'dateAndTime', style: {'word-break': 'break-word', 'white-space': 'normal'}},
										{textKey: 'last_used', textType: 'dateAndTime', style: {'word-break': 'break-word', 'white-space': 'normal'}},
									]"
                                    [bulkActions] = "{
										enable: true,
										selectBulkActionCb:	selectMultipleWhitelistAction,
										initBulkActionsFunction: showWhitelistBulkActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
                                    [cellActions] = "{
										enable: true,
										isSmaller: true,
										selectItemActionCb:	selectWhitelistAction,
										initActionsFunction: showWhitelistActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
                                    (searchFunction)="searchSendersWhitelist($event.searchTerm, $event.activeFilters)"
                                    (confirmEditFunction)="confirmEditSendersWhitelist($event.item, $event.isApproved)"
                                    [options]="{
										importFromCsv : {cbFunction : uploadSendersWhitelistFromFile, tooltip: 'A column named \'sender\' should contain the senders'},
										exportToCsvFunction: exportSendersWhitelistToCsv,
										itemsNameSingular: 'sender',
										defaultSortKey: '-created',
									}"
                            >
                                <!--sender email cells-->
								<ng-template #customCells let-item let-cellId="cellId">
									<div *ngIf="cellId === 'whitelistSenderEmailCell'">
										<i class="far fa-clipboard"
										   style="float: left;"
										   [ngStyle]="!item.skip_keep_record ? {'color': 'green','margin' : '0 3px'} : {'color': '#b1b1b1','margin' : '0 3px'}"
										   tooltip="Record of quarantined emails will{{item.skip_keep_record ? ' not' : ''}} be kept for this sender"
										>
										</i>
										<div style="width: fit-content; max-width: calc(100% - 20px); white-space: nowrap;" tooltip="{{item.email}}" placement="top" >{{item.email}}</div>
									</div>
									<div *ngIf="cellId === 'senderWhitelistMatchTypeCellEdit'">
										<app-dropdown-c class="w100 h100" text="{{item.edit.matchType}}">
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.exactMatch"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.exactMatch], 'Sender')">
												{{dic.CONSTANTS.ruleMatchType.exactMatch}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.startsWith"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.startsWith], 'Sender')">
												{{dic.CONSTANTS.ruleMatchType.startsWith}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.endsWith"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.endsWith], 'Sender')">
												{{dic.CONSTANTS.ruleMatchType.endsWith}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.contains"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.contains], 'Sender')">
												{{dic.CONSTANTS.ruleMatchType.contains}}
											</li>
										</app-dropdown-c>
									</div>
								</ng-template>
                                <!---->
                                <div tableButtons>
                                    <div class="w100 flex-row hSpace05">
                                        <btn-c endIcon="fa fa-plus"
                                             [isDisabled]="currentLists?.senders_whitelist?.isAddRecordActive"
                                             (action)="startAddRecord(currentLists?.senders_whitelist);">
                                            Add Allowlisted Sender
                                        </btn-c>
                                        <btn-c class="animated fadeIn"
                                             *ngIf="currentUser === dic.CONSTANTS.planDefaultUser"
                                             variant="outlined"
                                             (action)="showWhiteListConfig();">
                                            Advanced
                                        </btn-c>
                                    </div>
                                </div>
                            </trustifi-table-c>
                        </div>
                    </div>
                    <br/>
                    <!--Senders Blocklist-->
                    <div class="flex-column gap-3">
                        <div class="flex-column gap-2">
                            <div class="flex-row align-items-center gap-1">
                                <i class="fa fa-times-circle text-danger" style="font-size: 0.7rem;"></i>
                                <b style="font-size: 0.845rem;">Senders Blocklist</b>
                            </div>
                            <span class="text-muted lh-base" style="font-size: 0.9em;">Emails from senders in this list will always be considered malicious</span>
                        </div>
                        <div class="flxClmn vSpace1 animated fadeIn" style="background-color: white; height: max(330px,25vw);">
                            <trustifi-table-c
                                    class="trustifi-table" style="flex: 1 0; z-index: 5; height: 330px;"
                                    [list]="currentLists.senders_blacklist"
                                    [headers]="[
										{text: 'Sender', sortKey: 'email',  width: '33%'},
										{text: 'Match Type', width: '130px', sortKey: 'matchType', tooltip: 'For domains, IP, and CIDR only the Exact Match type is fully supported'},
										{text: 'Description', width: '37%' },
										{text: 'Updated by', width: '37%' },
										{text: 'Last Update', sortKey: 'created', width: '20%' },
										{text: 'Last Used', sortKey: 'last_used', width: '20%' },
									]"
                                    [cellsPrototype]="[
										{html: 'blacklistSenderEmailCell', edit: {modelKey: 'email', placeholder: 'Email/Domain/IP/CIDR'}},
										{textKey: 'matchType', overflowOutside: true, edit: {html: 'senderBlacklistMatchTypeCellEdit'}, style: {'text-transform' : 'capitalize'}},
										{textKey: 'description', tooltip: true, edit: {modelKey: 'description', placeholder: 'Description'}},
										{textKey: 'updated_by'},
										{textKey: 'created', textType: 'dateAndTime', style: {'word-break': 'break-word', 'white-space': 'normal'}},
										{textKey: 'last_used', textType: 'dateAndTime', style: {'word-break': 'break-word', 'white-space': 'normal'}},
									]"
                                    [bulkActions] = "{
										enable: true,
										selectBulkActionCb:	selectMultipleBlacklistAction,
										initBulkActionsFunction: showBlacklistBulkActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
                                    [cellActions] = "{
										enable: true,
										isSmaller: true,
										selectItemActionCb:	selectBlacklistAction,
										initActionsFunction: showBlacklistActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
                                    (searchFunction)="searchSendersBlacklist($event.searchTerm, $event.activeFilters)"
                                    (confirmEditFunction)="confirmEditSendersBlacklist($event.item, $event.isApproved)"
                                    [options]="{
										importFromCsv : {cbFunction : uploadSendersBlacklistFromFile, tooltip: 'A column named \'sender\' should contain the senders'},
										exportToCsvFunction: exportSendersBlacklistToCsv,
										itemsNameSingular: 'sender',
										defaultSortKey: '-created',
									}"
                            >
                                <!--sender email cells-->
								<ng-template #customCells let-item let-cellId="cellId">
									<div *ngIf="cellId === 'blacklistSenderEmailCell'">
										<i class="far fa-clipboard"
										   style="float: left;"
										   [ngStyle]="!item.skip_keep_record ? {'color': 'green','margin' : '0 3px'} : {'color': '#b1b1b1','margin' : '0 3px'}"
										   tooltip="Record of quarantined emails will{{item.skip_keep_record ? ' not' : ''}} be kept for this sender"
										>
										</i>
										<div style="width: fit-content; max-width: calc(100% - 20px); white-space: nowrap;" tooltip="{{item.email}}" placement="top" >{{item.email}}</div>
									</div>
									<div *ngIf="cellId === 'senderBlacklistMatchTypeCellEdit'">
										<app-dropdown-c class="w100 h100" text="{{item.edit.matchType}}">
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.exactMatch"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.exactMatch], 'Sender')">
												{{dic.CONSTANTS.ruleMatchType.exactMatch}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.startsWith"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.startsWith], 'Sender')">
												{{dic.CONSTANTS.ruleMatchType.startsWith}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.endsWith"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.endsWith], 'Sender')">
												{{dic.CONSTANTS.ruleMatchType.endsWith}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.contains"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.contains], 'Sender')">
												{{dic.CONSTANTS.ruleMatchType.contains}}
											</li>
										</app-dropdown-c>
									</div>
								</ng-template>
                                <!---->
                                <div tableButtons>
                                    <div class="w100 flex-row">
                                        <btn-c endIcon="fa fa-plus"
                                             [isDisabled]="currentLists?.senders_blacklist?.isAddRecordActive"
                                             (action)="startAddRecord(currentLists?.senders_blacklist);">
                                            Add Blocklisted Sender
                                        </btn-c>
                                    </div>
                                </div>
                            </trustifi-table-c>
                        </div>
                    </div>
                </div>
            </div>

            <!--Settings: Block/Allow Links -->
            <div class="list-group-item mw-100 settings-list-item column-style"
				 style="padding-top: 10px !important;"
				 *ngIf="gs.getTabName() === dic.CONSTANTS.inboundConfigurationPageTabs.linkLists">
                <div class="content-container flex-column gap-3">
                    <!--Links Whitelist-->
                    <div class="flex-column gap-3">
                        <div class="flex-column gap-2">
                            <div class="flex-row align-items-center gap-1">
                                <i class="fa fa-check-circle text-success" style="font-size: 0.7rem;"></i>
                                <b style="font-size: 0.845rem;">Links Allowlist</b>
                            </div>
                            <span class="text-muted lh-base" style="font-size: 0.9em;">Links from this list will always be considered safe</span>
                        </div>
                        <div class="flxClmn vSpace1 animated fadeIn" style="background-color: white; height: max(330px,25vw);">
                            <trustifi-table-c
                                    class="trustifi-table" style="flex: 1 0; z-index: 5; height: 330px;"
                                    [list]="currentLists?.links_whitelist"
                                    [headers]="[
										{text: 'Allowlisted URL', sortKey: 'link',  width: '33%'},
										{text: 'Match Type', width: '130px', sortKey: 'matchType'},
										{text: 'Description', width: '37%' },
										{text: 'Updated by', width: '20%' },
										{text: 'Last Update', sortKey: 'created', width: '20%' },
									]"
                                    [cellsPrototype]="[
										{textKey: 'link', edit: {modelKey: 'link', placeholder: 'Link URL'}},
										{textKey: 'matchType', overflowOutside: true, edit: {html: 'linksWhitelistMatchTypeCellEdit'}, style: {'text-transform' : 'capitalize'}},
										{textKey: 'description', tooltip: true, edit: {modelKey: 'description', placeholder: 'Description'}},
										{textKey: 'updated_by'},
										{textKey: 'created', textType: 'dateAndTime'},
									]"
                                    [bulkActions] = "{
										enable: true,
										selectBulkActionCb:	selectMultipleLinksWhitelistAction,
										initBulkActionsFunction: showLinksWhitelistBulkActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
                                    [cellActions] = "{
										enable: true,
										isSmaller: true,
										selectItemActionCb:	selectLinksWhitelistAction,
										initActionsFunction: showLinksWhitelistActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
                                    (searchFunction)="searchLinksWhitelist($event.searchTerm, $event.activeFilters)"
                                    (confirmEditFunction)="confirmEditLinksWhitelist($event.item, $event.isApproved)"
                                    [options]="{
										importFromCsv : {cbFunction : uploadLinksWhitelistFromFile, tooltip: 'A column named \'link\' should contain the links'},
										exportToCsvFunction: exportLinksWhitelistToCsv,
										itemsNameSingular: 'link',
										defaultSortKey: '-created',
									}"
                            >
                                <!--link match type EDIT-->
								<ng-template #customCells let-item let-cellId="cellId">
									<!--action cells-->
									<div *ngIf="cellId === 'linksWhitelistMatchTypeCellEdit'">
										<app-dropdown-c class="w100 h100" text="{{item.edit.matchType}}">
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.exactMatch"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.exactMatch], 'URL')">
												{{dic.CONSTANTS.ruleMatchType.exactMatch}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.startsWith"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.startsWith], 'URL')">
												{{dic.CONSTANTS.ruleMatchType.startsWith}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.endsWith"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.endsWith], 'text')">
												{{dic.CONSTANTS.ruleMatchType.endsWith}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.contains"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.contains], 'text')">
												{{dic.CONSTANTS.ruleMatchType.contains}}
											</li>
										</app-dropdown-c>
									</div>
								</ng-template>
                                <!---->
                                <div tableButtons>
                                    <div class="w100 flex-row">
                                        <btn-c endIcon="fa fa-plus"
                                             [isDisabled]="currentLists?.links_whitelist?.isAddRecordActive"
                                             (action)="startAddRecord(currentLists?.links_whitelist);">
                                            Add Allowlisted Link
                                        </btn-c>
                                    </div>
                                </div>
                            </trustifi-table-c>
                        </div>
                    </div>
                    <br/>
                    <!--Links Blocklist-->
                    <div class="flex-column gap-3">
                        <div class="flex-column gap-2">
                            <div class="flex-row align-items-center gap-1">
                                <i class="fa fa-times-circle text-danger" style="font-size: 0.7rem;"></i>
                                <b style="font-size: 0.845rem;">Links Blocklist</b>
                            </div>
                            <span class="text-muted lh-base" style="font-size: 0.9em;">Links from this list will always be considered malicious</span>
                        </div>
                        <div class="flxClmn vSpace1 animated fadeIn" style="background-color: white; height: max(330px,25vw);">
                            <trustifi-table-c
                                    class="trustifi-table" style="flex: 1 0; z-index: 5; height: 330px;"
                                    [list]="currentLists?.links_blacklist"
                                    [headers]="[
										{text: 'Blocklisted URL', sortKey: 'link',  width: '33%'},
										{text: 'Match Type', width: '130px', sortKey: 'matchType'},
										{text: 'Description', width: '37%' },
										{text: 'Updated by', width: '20%' },
										{text: 'Last Update', sortKey: 'created', width: '20%' },
									]"
                                    [cellsPrototype]="[
										{textKey: 'link', edit: {modelKey: 'link', placeholder: 'Link'}},
										{textKey: 'matchType', overflowOutside: true, edit: {html: 'linksBlacklistMatchTypeCellEdit'}, style: {'text-transform' : 'capitalize'}},
										{textKey: 'description', tooltip: true, edit: {modelKey: 'description', placeholder: 'Description'}},
										{textKey: 'updated_by'},
										{textKey: 'created', textType: 'dateAndTime'},
									]"
                                    [bulkActions] = "{
										enable: true,
										selectBulkActionCb:	selectMultipleLinksBlacklistAction,
										initBulkActionsFunction: showLinksBlacklistBulkActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
                                    [cellActions] = "{
										enable: true,
										isSmaller: true,
										selectItemActionCb:	selectLinksBlacklistAction,
										initActionsFunction: showLinksBlacklistActions,
										showInRed: {enable: true, terms: ['Remove']}
									}"
                                    (searchFunction)="searchLinksBlacklist($event.searchTerm, $event.activeFilters)"
                                    (confirmEditFunction)="confirmEditLinksBlacklist($event.item, $event.isApproved)"
                                    [options]="{
										importFromCsv : {cbFunction : uploadLinksBlacklistFromFile, tooltip: 'A column named \'link\' should contain the links'},
										exportToCsvFunction: exportLinksBlacklistToCsv,
										itemsNameSingular: 'link',
										defaultSortKey: '-created',
									}"
                            >
                                <!--link match type EDIT-->
								<ng-template #customCells let-item let-cellId="cellId">
									<!--action cells-->
									<div *ngIf="cellId === 'linksBlacklistMatchTypeCellEdit'">
										<app-dropdown-c class="w100 h100" text="{{item.edit.matchType}}">
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.exactMatch"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.exactMatch], 'URL')">
												{{dic.CONSTANTS.ruleMatchType.exactMatch}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.startsWith"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.startsWith], 'URL')">
												{{dic.CONSTANTS.ruleMatchType.startsWith}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.endsWith"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.endsWith], 'text')">
												{{dic.CONSTANTS.ruleMatchType.endsWith}}
											</li>
											<li (click)="item.edit.matchType = dic.CONSTANTS.ruleMatchType.contains"
												[explanationC]="util.format(dic.CONSTANTS.ruleMatchTypeTooltip[dic.CONSTANTS.ruleMatchType.contains], 'text')">
												{{dic.CONSTANTS.ruleMatchType.contains}}
											</li>
										</app-dropdown-c>
									</div>
								</ng-template>
                                <!---->
                                <div tableButtons>
                                    <div class="w100 flex-row">
                                        <btn-c endIcon="fa fa-plus"
                                             [isDisabled]="currentLists?.links_blacklist?.isAddRecordActive"
                                             (action)="startAddRecord(currentLists?.links_blacklist);">
                                            Add Blocklisted Link
                                        </btn-c>
                                    </div>
                                </div>
                            </trustifi-table-c>
                        </div>
                    </div>
                </div>
            </div>

            <!--Settings: Block/Allow Attachments -->
            <div class="list-group-item mw-100 settings-list-item column-style"
				 style="padding-top: 10px !important;"
				 *ngIf="gs.getTabName() === dic.CONSTANTS.inboundConfigurationPageTabs.attachmentLists">
                <div class="content-container flex-column gap-3">
                    <!--Attachments Whitelist-->
                    <div class="flex-column gap-3">
                        <div class="flex-column gap-2">
                            <div class="flex-row align-items-center gap-1">
                                <i class="fa fa-check-circle text-success" style="font-size: 0.7rem;"></i>
                                <b style="font-size: 0.845rem;">Attachments Allowlist</b>
                            </div>
                            <span class="text-muted lh-base" style="font-size: 0.9em;">Files from this list will always be considered safe</span>
                        </div>
                        <trustifi-table-c
                                (ngInit)="whitelistFiles = null;"
								ngfDrop
                                multiple="1"
                                [(files)]="whitelistFiles"
                                (fileChange)="uploads(whitelistFiles, true)"
								[(validDrag)]="validDrag2"
								[(invalidDrag)]="invalidDrag2"
								[ngClass]="{'drag-and-drop-hover': validDrag2, 'dragOverClassObj': validDrag2}"
                                class="trustifi-table" style="z-index: 5; height: max(330px,25vw);"

                                [list]="currentLists?.attachments_whitelist"
                                [headers]="[
									{text: 'Allowlisted File', sortKey: 'name'},
									{text: 'Hash' },
									{text: 'Updated by' },
									{text: 'Created', sortKey: 'created', width: '130px'},
								]"
                                [cellsPrototype]="[
									{html: 'whitelistAttachmentNameCell', edit: {html: 'whitelistAttachmentNameCellEdit'}},
									{textKey: 'hash'},
									{textKey: 'updated_by'},
									{textKey: 'created', textType: 'dateAndTime'},
								]"
                                [bulkActions] = "{
									enable: true,
									selectBulkActionCb:	selectMultipleAttachmentsWhitelistAction,
									initBulkActionsFunction: showAttachmentsWhitelistBulkActions,
									showInRed: {enable: true, terms: ['Remove']}
								}"
                                [cellActions] = "{
									enable: true,
									isSmaller: true,
									selectItemActionCb:	selectAttachmentsWhitelistAction,
									initActionsFunction: showAttachmentsWhitelistActions,
									showInRed: {enable: true, terms: ['Remove']}
								}"
                                (searchFunction)="searchAttachmentsWhitelist($event.searchTerm, $event.activeFilters)"
                                (confirmEditFunction)="confirmNewWhitelistAttachment($event.item, $event.isApproved)"
                                [options]="{
									importFromCsv : {cbFunction : uploadAttachmentsWhitelistFromFile, tooltip: 'A column named \'hash\' should contain the hashes'},
									exportToCsvFunction: exportAttachmentsWhitelistToCsv,
									itemsNameSingular: 'attachment',
									defaultSortKey: '-created',
								}"
                        >
                            <!--attachment name cells-->
							<ng-template #customCells let-item let-cellId="cellId">
								<!--action cells-->
								<div *ngIf="cellId === 'whitelistAttachmentNameCell'">
									<div class="flxRow hSpace05 ellipsis">
										<span style="display: block;" tooltip="{{item.name}}">{{item.name}}</span>
										<span class="ellipsis" style="min-width: 80px;" *ngIf="item.size">({{item.size | FormatBytes:0}})</span>
									</div>
								</div>
								<!--whitelist attachment - EDIT MODE-->
								<div class="flxRow hSpace05 h100" style="overflow: visible;" *ngIf="cellId === 'whitelistAttachmentNameCellEdit'">
									<input type="text"
										   [(ngModel)]="item.edit['name']"
										   placeholder="Attachment Name"
										   style="margin-bottom: 0; height: 100%; flex: 1 0;"
										   (keydown.enter) = "confirmNewWhitelistAttachment($event.item, $event.true)">
									<input type="text"
										   [(ngModel)]="item.edit['hash']"
										   placeholder="SHA-256 or MD5"
										   style="margin-bottom: 0; margin-left: 5px; height: 100%; flex: 1 0;"
										   (keydown.enter) = "confirmNewWhitelistAttachment($event.item, $event.true)">
								</div>
							</ng-template>
                            <!---->
                            <div tableButtons>
                                <div class="flxRow hSpace2" style="align-items: baseline;">
									<div class="btn-with-dropdown" style="width: 245px;">
										<btn-c (action)="startAddRecord(currentLists?.attachments_whitelist);"
											   endIcon="fa fa-plus"
											   [isDisabled]="currentLists?.attachments_whitelist?.isAddRecordActive">
											Add Allowlisted Attachment
										</btn-c>
										<app-dropdown-c [isLf]="true">
											<li	ngfSelect
												   multiple="1"
												   [(files)]="whitelistFiles"
												   (fileChange)="uploads(whitelistFiles, true)">
												Add from my computer
											</li>
										</app-dropdown-c>
									</div>
                                    <span class="text-muted" style="font-size: 0.8rem; font-style: italic;">or drag and drop the file into the table</span>
                                </div>
                            </div>
                        </trustifi-table-c>
                    </div>
                    <br/>
                    <!--Attachments Blocklist-->
                    <div class="flex-column gap-3">
                        <div class="flex-column gap-2">
                            <div class="flex-row align-items-center gap-1">
                                <i class="fa fa-times-circle text-danger" style="font-size: 0.7rem;"></i>
                                <b style="font-size: 0.845rem;">Attachments Blocklist</b>
                            </div>
                            <span class="text-muted lh-base" style="font-size: 0.9em;">Files from this list will always be considered malicious</span>
                        </div>
                        <trustifi-table-c
                                (ngInit)="blacklistFiles = null;"
								ngfDrop
                                multiple="1"
                                [(files)]="blacklistFiles"
                                (fileChange)="uploads(blacklistFiles, false)"
								[(validDrag)]="validDrag"
								[(invalidDrag)]="invalidDrag"
								[ngClass]="{'drag-and-drop-hover': validDrag, 'dragOverClassObj': validDrag}"

                                class="trustifi-table" style="z-index: 5; height: max(330px,25vw);"
                                [list]="currentLists?.attachments_blacklist"
                                [headers]="[
									{text: 'Blocklisted File', sortKey: 'name'},
									{text: 'Hash' },
									{text: 'Updated by' },
									{text: 'Created', sortKey: 'created', width: '130px'},
								]"
                                [cellsPrototype]="[
									{html: 'blacklistAttachmentNameCell', edit: {html:'blacklistAttachmentNameCellEdit'}},
									{textKey: 'hash'},
									{textKey: 'updated_by'},
									{textKey: 'created', textType: 'dateAndTime'},
								]"
                                [bulkActions] = "{
									enable: true,
									selectBulkActionCb:	selectMultipleAttachmentsBlacklistAction,
									initBulkActionsFunction: showAttachmentsBlacklistBulkActions,
									showInRed: {enable: true, terms: ['Remove']}
								}"
                                [cellActions] = "{
									enable: true,
									isSmaller: true,
									selectItemActionCb:	selectAttachmentsBlacklistAction,
									initActionsFunction: showAttachmentsBlacklistActions,
									showInRed: {enable: true, terms: ['Remove']}
								}"
                                (searchFunction)="searchAttachmentsBlacklist($event.searchTerm, $event.activeFilters)"
                                (confirmEditFunction)="confirmNewBlacklistAttachment($event.item, $event.isApproved)"
                                [options]="{
									importFromCsv : {cbFunction : uploadAttachmentsBlacklistFromFile, tooltip: 'A column named \'hash\' should contain the hashes'},
									exportToCsvFunction: exportAttachmentsBlacklistToCsv,
									itemsNameSingular: 'attachment',
									defaultSortKey: '-created',
								}"
                        >
							<ng-template #customCells let-item let-cellId="cellId">
								<!--action cells-->
								<div *ngIf="cellId === 'blacklistAttachmentNameCell'">
									<div class="flxRow hSpace05 ellipsis">
										<span style="display: block;" tooltip="{{item.name}}">{{item.name}}</span>
										<span class="ellipsis" style="min-width: 80px;" *ngIf="item.size">({{item.size | FormatBytes:0}})</span>
									</div>
								</div>
								<!--blacklist attachment - EDIT MODE-->
								<div class="flxRow hSpace05 h100" style="overflow: visible;" *ngIf="cellId === 'blacklistAttachmentNameCellEdit'">
									<input type="text"
										   [(ngModel)]="item.edit['name']"
										   placeholder="Attachment Name"
										   style="margin-bottom: 0; height: 100%; flex: 1 0;"
										   (keydown.enter) = "confirmNewBlacklistAttachment($event.item,$event.isApproved)">
									<input type="text"
										   [(ngModel)]="item.edit['hash']"
										   placeholder="SHA-256 or MD5"
										   style="margin-bottom: 0; margin-left: 5px; height: 100%; flex: 1 0;"
										   (keydown.enter) = "confirmNewBlacklistAttachment($event.item,$event.isApproved)">
								</div>
							</ng-template>

                            <!---->
                            <div tableButtons>
                                <div class="flxRow hSpace2" style="align-items: baseline;">
									<div class="btn-with-dropdown" style="width: 245px;">
										<btn-c (action)="startAddRecord(currentLists?.attachments_blacklist);"
											   endIcon="fa fa-plus"
											   [isDisabled]="currentLists?.attachments_blacklist?.isAddRecordActive">
											Add Blocklisted Attachment
										</btn-c>
										<app-dropdown-c [isLf]="true">
											<li	ngfSelect
												multiple="1"
												[(files)]="blacklistFiles"
												(fileChange)="uploads(blacklistFiles, false)">
												Add from my computer
											</li>
										</app-dropdown-c>
									</div>
                                    <span class="text-muted" style="font-size: 0.8rem; font-style: italic;">or drag and drop the file into the table</span>
                                </div>
                            </div>
                        </trustifi-table-c>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Assign item to admin popup-->
    <popup-c class="overflownV" *ngIf="assignItemToAdminsInfo?.show"
           head="Assign Configuration"
           (closeCb)="assignItemToAdminsInfo.show = false;"
           (confirmCb)="addItemToAdminsExecute()"
           [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}" size="dynamic">
        <div class="flxClmn vSpace2" style="overflow: visible;">
            <span>Choose which plans the selected configuration should be assigned to</span>
            <div class="flxClmn vSpace1">
                <div class="flxRow hSpace2" style="align-items: center;">
                    <label class="radio-container" style="display: block; width: fit-content; margin: 0;">Choose specific admins:
                        <input type="radio" name="installPolicyAdmins" [value]="adminSelectionType?.specific"
                               [(ngModel)]="adminSelectionTypeSelection"
                               [checked]="adminSelectionTypeSelection === adminSelectionType?.specific">
                        <span class="checkmark"></span>
                    </label>
                    <app-dropdown-c style="width: 260px; height: 35px;"
                                  [items]="assignItemToAdminsInfo?.planAdmins"
                                  [isDisabled]="adminSelectionTypeSelection === adminSelectionType?.all"
                                  [isMultipleSelection]="true"
                                  itemDisplayKey="email" [enableSearch]="true"
                                  (onItemClicked)="chooseInstallPolicyAdmins()">
                    </app-dropdown-c>
                </div>

                <label class="radio-container" style="display: inline-block; height: 30px;">All admins
                    <input type="radio" name="installPolicyAdmins" [value]="adminSelectionType?.all"
                           [(ngModel)]="adminSelectionTypeSelection"
                           [checked]="adminSelectionTypeSelection === adminSelectionType?.all">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </popup-c>

    <!--senders advanced popup-->
    <popup-c *ngIf="sendersAllowlistAdvancedInfoPopup?.show"
           head="Advanced Configuration"
		   subTitle="Manage configurations for sender {{sendersAllowlistAdvancedInfoPopup?.item?.email}}"
           (closeCb)="sendersAllowlistAdvancedInfoPopup.show = false;"
           size="dynamic">
        <div class="flxClmn vSpace1">

			<div class="flxClmn gap-1">
				<div class="flxRow gap-1">
					<h6>Override Advanced Settings</h6>
					<toggle-switch-c id="overrideAdvSettings"
								   [hideText]="true"
								   [(model)]="sendersAllowlistAdvancedInfoPopup.item.override_advanced_settings"
								   (onChange)="updateTpSendersWhiteListAdvanced('override_advanced_settings')">
					</toggle-switch-c>
				</div>
				<span>Bypass the customized advanced settings set on the senders allowlist</span>
			</div>

            <table class="simpleDataTable w100" style="table-layout: fixed;">
                <tr>
                    <td>
						Block Malicious Links
						<i style="font-weight: 400; margin-left: 5px;" class="fas fa-question-circle" tooltip="Emails from this sender will be quarantine if malicious links are found"></i>
					</td>
                    <td>
                        <toggle-switch-c id="blockMaliciousLinksToggle"
                                       [hideText]="true"
									   [isDisabled]="!sendersAllowlistAdvancedInfoPopup.item.override_advanced_settings"
                                       [(model)]="sendersAllowlistAdvancedInfoPopup.item.whitelist_block_malicious_links"
                                       (onChange)="updateTpSendersWhiteListAdvanced('whitelist_block_malicious_links')">
                        </toggle-switch-c>
					</td>
                </tr>

                <tr>
                    <td>
						Block Malicious Files
						<i style="font-weight: 400; margin-left: 5px;" class="fas fa-question-circle" tooltip="Emails from this sender will be quarantine if malicious files are found"></i>
					</td>
                    <td>
                        <toggle-switch-c id="blockMaliciousFiles"
                                       [hideText]="true"
									   [isDisabled]="!sendersAllowlistAdvancedInfoPopup.item.override_advanced_settings"
									   [(model)]="sendersAllowlistAdvancedInfoPopup.item.whitelist_block_malicious_files"
                                       (onChange)="updateTpSendersWhiteListAdvanced('whitelist_block_malicious_files')">
                        </toggle-switch-c>
                    </td>
                </tr>

				<tr>
					<td>
						Allow All Sources
						<i style="font-weight: 400; margin-left: 5px;" class="fas fa-question-circle" tooltip="All sources will be accepted for emails from this sender"></i>
					</td>
					<td>
						<toggle-switch-c id="allowAllSources"
									   [hideText]="true"
									   [isDisabled]="!sendersAllowlistAdvancedInfoPopup.item.override_advanced_settings"
									   [(model)]="sendersAllowlistAdvancedInfoPopup.item.allow_all_sources_whitelist"
									   (onChange)="updateTpSendersWhiteListAdvanced('allow_all_sources_whitelist')">
						</toggle-switch-c>
					</td>
				</tr>

                <tr>
                    <td>
						Notify Recipient Immediately For Quarantined Emails
						<i style="font-weight: 400; margin-left: 5px;" class="fas fa-question-circle" tooltip="If an email sent from this sender or domain is quarantined, then the recipient will receive an immediate notification"></i>
					</td>
                    <td>
                        <toggle-switch-c id="notifyRecipientToggle"
                                       [hideText]="true"
									   [isDisabled]="!sendersAllowlistAdvancedInfoPopup.item.override_advanced_settings"
									   [(model)]="sendersAllowlistAdvancedInfoPopup.item.whitelist_notify_recipient_email_quarantined"
                                       (onChange)="updateTpSendersWhiteListAdvanced('whitelist_notify_recipient_email_quarantined')">
                        </toggle-switch-c>
                    </td>
                </tr>
            </table>
        </div>

		<hr/>
		<br/>
		<div class="flxClmn vSpace1">
			<div>
				<h6>Blocked File Types Exceptions</h6>
				<span>Choose to allow any of the file types below for this sender</span>
			</div>
			<table class="simpleDataTable w100" style="table-layout: fixed;">
				<tr>
					<td style="width: 250px;">Allow Macros</td>
					<td>
						<toggle-switch-c id="blockMacrosToggle"
									   [hideText]="true"
									   [(model)]="sendersAllowlistAdvancedInfoPopup.item.file_types.allow_macros"
									   (onChange)="updateBlockFileTypeStatus('allow_macros')">
						</toggle-switch-c>
					</td>
				</tr>

				<tr>
					<td>Allow Scripts</td>
					<td>
						<toggle-switch-c id="blockScriptToggle"
									   [hideText]="true"
									   [(model)]="sendersAllowlistAdvancedInfoPopup.item.file_types.allow_scripts"
									   (onChange)="updateBlockFileTypeStatus('allow_scripts')">
						</toggle-switch-c>
					</td>
				</tr>

				<tr>
					<td>Allow Executables</td>
					<td>
						<toggle-switch-c id="blockExecutablesToggle"
									   [hideText]="true"
									   [(model)]="sendersAllowlistAdvancedInfoPopup.item.file_types.allow_executables"
									   (onChange)="updateBlockFileTypeStatus('allow_executables')">
						</toggle-switch-c>
					</td>
				</tr>

				<tr>
					<td>Allow Encrypted Attachments</td>
					<td>
						<toggle-switch-c id="blockEncryptedToggle"
									   [hideText]="true"
									   [(model)]="sendersAllowlistAdvancedInfoPopup.item.file_types.allow_encrypted_attachments"
									   (onChange)="updateBlockFileTypeStatus('allow_encrypted_attachments')">
						</toggle-switch-c>
					</td>
				</tr>

				<tr>
					<td>Allow Encrypted Archives</td>
					<td>
						<toggle-switch-c id="blockArchivesToggle"
									   [hideText]="true"
									   [(model)]="sendersAllowlistAdvancedInfoPopup.item.file_types.allow_encrypted_archives"
									   (onChange)="updateBlockFileTypeStatus('allow_encrypted_archives')">
						</toggle-switch-c>
					</td>
				</tr>

				<tr>
					<td>Allow Uncommon File Extensions</td>
					<td>
						<toggle-switch-c id="blockUncommonToggle"
									   [hideText]="true"
									   [(model)]="sendersAllowlistAdvancedInfoPopup.item.file_types.allow_uncommon"
									   (onChange)="updateBlockFileTypeStatus('allow_uncommon')">
						</toggle-switch-c>
					</td>
				</tr>
			</table>
		</div>
    </popup-c>

	<!--Advanced Settings-->
	<popup-c class="bs-enabled" *ngIf="recipientConfigPopup?.show"
			 head="Advanced Settings" size="dynamic"
			 subTitle="Manage configurations for allow/block lists"
			 (closeCb)="recipientConfigPopup.show = false;"
			 (confirmCb)="recipientConfigPopup?.doneCb()"
			 [buttons]="{cancel: 'Cancel', confirm: {text: 'Done', disabled: recipientConfigPopup.applyInProcess}}">
		<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
			<div class="flexRow-start hSpace1 md-checkbox">
				<input id="checkbox-1" type="checkbox" [(ngModel)]="recipientConfigPopup.allowRecipientToggle">
				<label for="checkbox-1">
					<span style="vertical-align: middle;">Allow recipient personal allow/block lists</span>
					<i style="font-weight: 400;" class="fas fa-question-circle ms-1"
					   tooltip="Allows recipients to add senders, links, and attachments to their personal lists"></i>
				</label>
			</div>
		</div>
	</popup-c>

	<!--Senders Allowlist Advanced Settings-->
	<popup-c class="bs-enabled" *ngIf="whiteListConfigPopup?.show"
			 head="Senders Allowlist Advanced Settings" size="dynamic"
			 subTitle="Manage configurations for allowlist senders below"
			 (closeCb)="whiteListConfigPopup.show = false;"
			 (confirmCb)="whiteListConfigPopup?.doneCb()"
			 [buttons]="{cancel: 'Cancel', confirm: {text: 'Done', disabled: whiteListConfigPopup.applyInProcess}}">
		<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
			<div class="flexRow-start hSpace1 md-checkbox">
				<input id="checkbox-blockMaliciousLinks" type="checkbox" [(ngModel)]="whiteListConfigPopup.blockMaliciousLinks">
				<label for="checkbox-blockMaliciousLinks">
					<span style="vertical-align: middle;">Block malicious links</span>
					<i style="font-weight: 400;" class="fas fa-question-circle ms-1"
					   tooltip="Emails from allowlist senders will be blocked if malicious links are found"></i>
				</label>
			</div>
			<div class="flexRow-start hSpace1 md-checkbox">
				<input id="checkbox-blockMaliciousFiles" type="checkbox" [(ngModel)]="whiteListConfigPopup.blockMaliciousFiles">
				<label for="checkbox-blockMaliciousFiles">
					<span style="vertical-align: middle;">Block malicious files</span>
					<i style="font-weight: 400;" class="fas fa-question-circle ms-1"
					   tooltip="Emails from allowlist senders will be blocked if malicious files are found"></i>
				</label>
			</div>
			<div class="flexRow-start hSpace1 md-checkbox">
				<input id="checkbox-blockFilesUserPreferences" type="checkbox" [(ngModel)]="whiteListConfigPopup.blockFilesUserPreferences">
				<label for="checkbox-blockFilesUserPreferences">
					<span style="vertical-align: middle;">Block files according to admin preferences</span>
					<i style="font-weight: 400;" class="fas fa-question-circle ms-1"
					   tooltip='Emails from allowlist senders will be blocked if they contain a file type which is set to be blocked (according to the "Block file types" settings)'></i>
				</label>
			</div>
			<div class="flexRow-start hSpace1 md-checkbox">
				<input id="checkbox-allSourcesWhitelist" type="checkbox" [(ngModel)]="whiteListConfigPopup.allSourcesWhitelist">
				<label for="checkbox-allSourcesWhitelist">
					<span style="vertical-align: middle;">Allow all sources</span>
					<i style="font-weight: 400;" class="fas fa-question-circle ms-1"
					   tooltip="All sources will be accepted for emails from allowlist senders"></i>
				</label>
			</div>
			<div class="flexRow-start hSpace1 md-checkbox">
				<input id="checkbox-recipientEmailQuarantined" type="checkbox" [(ngModel)]="whiteListConfigPopup.recipientEmailQuarantined">
				<label for="checkbox-recipientEmailQuarantined">
					<span style="vertical-align: middle;">Notify recipient immediately for quarantined emails</span>
					<i style="font-weight: 400;" class="fas fa-question-circle ms-1"
					   tooltip="If an email send from a allowlist sender or domain is quarantined, the recipient will receive an immediate notification"></i>
				</label>
			</div>
		</div>
	</popup-c>
</div>
