import {GeneralService} from "../../../services/generalService";
import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import _ from "lodash";

@Component({
	selector: 'tracking-events',
	templateUrl: './tracking.component.html',
	styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnChanges{
	constructor(public gs:GeneralService){
	}

	@Input() recipientTrack: any;
	@Input() methods: any;

	trackingEvents: any[] = [];

	ngOnChanges(changes: SimpleChanges) {
		if (changes.recipientTrack) {
			this.trackingEvents = [];
			this.initEvents();
		}
	}

	initEvents = () => {
		this.addEvent('Sent', 'fas fa-envelope', 'Sent');

		if (this.methods?.postmark) {
			this.addEvent('Postmarked', 'fas fa-stamp', 'Postmark');
		}

		this.addEvent('Delivery', 'fas fa-check-circle', 'Delivered');

		this.addEvent('Opened', 'fas fa-envelope-open', !_.find<any>(this.recipientTrack.events_status,{name : 'Read'}) && this.methods?.encrypt_content ? 'Opened' : 'Read');

		if (this.methods?.secure_reply) {
			this.addEvent('Reply Received', 'fas fa-reply', 'Replied');
		}

		if (this.recipientTrack.is_blocked) {
			this.addEvent('Blocked', 'fas fa-ban', 'Blocked');
		}

		_.first(this.trackingEvents)['isFirst'] = true;
		_.last(this.trackingEvents)['isLast'] = true;
	}

	addEvent = (name, icon, label) => {
		this.trackingEvents.push({ name, icon, label});
	}

	getTrackingStatus = (eventName: string) => {
		const status = this.recipientTrack.events_status.find(event => event.name === eventName)?.status;
		return status || 'none'; // Return 'none' if status is not found
	}

	openLocation = (ll) => {
		this.gs.openLocation(ll);
	};
}
