import * as util from 'util';
import _ from 'lodash';
import {GeneralService} from '../../../../services/generalService';
import {RouteService} from '../../../../services/routeService';
import {NotificationService} from '../../../../services/notificationService';
import {DICTIONARY} from '../../../../dictionary';
import {Component, OnInit} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";

@Component({
	selector: 'plan-settings-domain-component',
	templateUrl: './plan-settings-domain.component.html',
})
export class PlanSettingsDomainComponent implements OnInit {
    dic = DICTIONARY;
    stopActiveDomainDelete = false;
    loadingDomainsInProcess = true;
    assignAdmins = ['your plan'];
    userInfo: any;
    isPartnerAdmin: any;
    planAdmins: any;
    userDomains: any;
    domain: any;
    amazonVerificationPopup: any;
    dnsDetailsPopup: { domainObj: any; show: boolean; };
    assignDomainToAdminPopup: any;
    assignToAdminInProcess: any;
    filterData;
	addActiveDomainPopup;
	unassignFromAdminPopup;

    constructor(private gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private clipboard: ClipboardService) {
    }

	ngOnInit() {
        this.gs.getUserInfo(false,  (userInfo) => {
            this.userInfo = userInfo;
            this.isPartnerAdmin = this.gs.isPartnerAdmin(this.userInfo);
        });
        this.initFilters();
        this.getDomains();
    };

    getDomains = () => {
        this.loadingDomainsInProcess = true;
        this.rs.getPlanDomains().then((response) => {
            this.userDomains = response;

            this.userDomains.forEach((domainObj) => {
                if (!domainObj.verified) {
                    this.isDomainIdentityVerified(domainObj);
                }
                if (!domainObj.dkim_verified) {
                    this.isDomainDkimVerified(domainObj);
                }
                if (!domainObj.mail_from_verified) {
                    this.isDomainMailFromVerified(domainObj);
                }
				if (!domainObj.spf_verified) {
					this.isDomainSpfVerified(domainObj);
				}

                if (domainObj.assigned_to && !this.assignAdmins.includes(domainObj.assigned_to)) {
                    this.assignAdmins.push(domainObj.assigned_to);
                }
            });

            this.loadingDomainsInProcess = false;
        }, (err) => {
			this.loadingDomainsInProcess = false;
		});
    };

    copyText(copy) {
        this.clipboard.copy(copy);
    };

    addActiveDomain(domainName = "", err = "") {
        this.domain = domainName || '';

		this.addActiveDomainPopup = {
			show: true,
			applyInProcess: false,
			domainsName: this.domain,
			errMsg: err,
		};
    };

    showDomainActions = (domainObj) => {
        let actions:any = [
            this.dic.CONSTANTS.domainsActions.showRecords,
            this.dic.CONSTANTS.domainsActions.downloadRecordsCsv,
            this.dic.CONSTANTS.domainsActions.checkIdentityVerified,
            this.dic.CONSTANTS.domainsActions.checkDkimVerified,
            this.dic.CONSTANTS.domainsActions.checkMailFromVerified,
			this.dic.CONSTANTS.domainsActions.checkSpfVerified
        ];

        const verifyDomainAction = {
            display: 'Verify Domain',
            submenu: [this.dic.CONSTANTS.domainsActions.amazonVerifyInstructions]
        }
        if (!domainObj.verified && domainObj.data.auto_update) {
            // add html
            const autoVerifyActionObj = this.dic.CONSTANTS.domainsActions.autoVerify;
            autoVerifyActionObj.display = `${domainObj.data.dns_provider}`;
            verifyDomainAction.submenu.unshift(autoVerifyActionObj);
        }
        verifyDomainAction.submenu.push({display: 'Other', value: this.dic.CONSTANTS.domainsActions.showRecords.value});
        actions.push(verifyDomainAction);

        if (domainObj.email_feedback_forwarding) {
            actions.push(this.dic.CONSTANTS.domainsActions.emailForwardingDisable);
        }
        else {
            actions.push(this.dic.CONSTANTS.domainsActions.emailForwardingEnable);
        }

        if (this.isPartnerAdmin && domainObj.verified && !domainObj.assigned_to) {
            actions.push(this.dic.CONSTANTS.domainsActions.assignDomain);
        }
        if (this.isPartnerAdmin && domainObj.assigned_to) {
            actions.push(this.dic.CONSTANTS.domainsActions.unassignDomain);
        }

        actions.push(this.dic.CONSTANTS.domainsActions.delete);

        return actions;
    }

    showBulkDomainsActions = () => {
        return [
            this.dic.CONSTANTS.domainsActions.delete
        ]
    }

    selectDomainAction = (domainObj, action) => {
        switch (action.value) {
            case this.dic.CONSTANTS.domainsActions.showRecords.value:
                this.openDnsDetailsPopup(domainObj);
                break;

            case this.dic.CONSTANTS.domainsActions.amazonVerifyInstructions.value:
                this.openAmazonVerificationPopup(domainObj);
                break;

            case this.dic.CONSTANTS.domainsActions.downloadRecordsCsv.value:
                this.getDomainDkimAndTokenCsv(domainObj);
                break;

            case this.dic.CONSTANTS.domainsActions.checkIdentityVerified.value:
                this.isDomainIdentityVerified(domainObj);
                break;

            case this.dic.CONSTANTS.domainsActions.checkDkimVerified.value:
                this.isDomainDkimVerified(domainObj);
                break;

			case this.dic.CONSTANTS.domainsActions.checkSpfVerified.value:
				this.isDomainSpfVerified(domainObj);
				break;

            case this.dic.CONSTANTS.domainsActions.checkMailFromVerified.value:
                this.isDomainMailFromVerified(domainObj);
                break;

            case this.dic.CONSTANTS.domainsActions.emailForwardingEnable.value:
            case this.dic.CONSTANTS.domainsActions.emailForwardingDisable.value:
                this.emailForwardingChange(domainObj);
                break;

            case this.dic.CONSTANTS.domainsActions.autoVerify.value:
                this.connectDomain(domainObj);
                break;

            case this.dic.CONSTANTS.domainsActions.assignDomain.value:
                this.openAssignDomainToAdminPopup(domainObj);
                break;

            case this.dic.CONSTANTS.domainsActions.unassignDomain.value:
                this.unassignFromAdmin(domainObj);
                break;

            case this.dic.CONSTANTS.domainsActions.delete.value:
                this.openDeleteDomainPopup([domainObj]);
                break;
        }
    }

    selectMultipleDomainsAction = (selectedDomains,action) => {
        switch (action) {
            case this.dic.CONSTANTS.domainsActions.delete:
                this.openDeleteDomainPopup(selectedDomains);
                return;
        }
    }

    openDeleteDomainPopup = domains => {
        let title, subTitle, body;
        if (domains.length > 1) {
            title = 'Delete Domains';
            subTitle = `Please note - you are about to delete ${domains.length} domains`;
            body = ['Selected domains will be no longer verified', 'Users under the plan of these domains will become unverified',`Domains that are assigned to other plans will not be deleted from those plan`];
        }
        else {
            title = 'Delete Domain';
            subTitle = `Please note - you are about to delete ${domains[0].domain}`;
            body = ['Domain will be no longer verified', 'Users under the plan of this domain will become unverified'];
            if (domains[0].assigned_to) {
                body.push(`Domain will not be deleted from ${domains[0].assigned_to}'s plan`);
            }
        }

        this.gs.showPopup({
            title: title,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                domains.forEach((domainObj) => {
                    this.stopActiveDomainDelete = true;
                    this.rs.deleteDomain(domainObj.domain).then(() => {
                        _.remove<any>(this.userDomains, domainItem => domainItem.domain === domainObj.domain);
                        this.ns.showInfoMessage(this.dic.MESSAGES.domainDeleted);
                    });
                });

                this.stopActiveDomainDelete = false;
            }
        });
    };

    connectDomain = (domainObj) => {
        if (!domainObj || !domainObj.domain) {
            return;
        }

        const assignData = {
            action: this.dic.CONSTANTS.domainsActions.autoVerify.value
        };

        this.rs.updateDomain(domainObj.domain, assignData).then(data => {
            window.open(data.url, '_blank', 'location=no,width=750,height=600,scrollbars=no,resizable=no');
        });
    };


    isDomainIdentityVerified(domainObj) {
        this.rs.isDomainVerified(domainObj.domain, {type: this.dic.CONSTANTS.domainVerify.identity}).then(isVerified => {
            if (isVerified === true) {
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.domainVerified, domainObj.domain));
            }
            else {
                this.ns.showWarnMessage(util.format(this.dic.ERRORS.domainNotVerified, domainObj.domain));
            }

            domainObj.verified = isVerified === true;
        });
    };

    isDomainDkimVerified(domainObj) {
        this.rs.isDomainVerified(domainObj.domain, {type: this.dic.CONSTANTS.domainVerify.dkim}).then(isVerified => {
            if (isVerified === true) {
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.domainDkimVerified, domainObj.domain));
            }
            else {
                this.ns.showWarnMessage(util.format(this.dic.ERRORS.domainDkimNotVerified, domainObj.domain));
            }

            domainObj.dkim_verified = isVerified === true;
        });
    };

	isDomainSpfVerified(domainObj) {
		this.rs.isDomainVerified(domainObj.domain, {type: this.dic.CONSTANTS.domainVerify.spf}).then(isVerified => {
			if (isVerified === true) {
				this.ns.showInfoMessage(util.format(this.dic.MESSAGES.domainSpfVerified, domainObj.domain));
			}
			else {
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.domainSpfNotVerified, domainObj.domain));
			}

			domainObj.spf_verified = isVerified === true;
		});
	};

    isDomainMailFromVerified(domainObj) {
        this.rs.isDomainVerified(domainObj.domain, {type: this.dic.CONSTANTS.domainVerify.mailFrom}).then(isVerified => {
            if (isVerified === true) {
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.domainMailFromVerified, domainObj.domain));
            }
            else {
                this.ns.showWarnMessage(util.format(this.dic.ERRORS.domainMailFromNotVerified, domainObj.domain));
            }

            domainObj.mail_from_verified = isVerified === true;
        });
    };

    addDomain(domain) {
        if (!domain) {
            return;
        }
		this.addActiveDomainPopup.applyInProcess = true;
        let duplicateDomain = _.find<any>(this.userDomains, d => d.domain === domain);
        this.rs.addDomain(domain).then((response) => {
            this.userDomains = response;
			this.addActiveDomainPopup.applyInProcess = false;
			this.addActiveDomainPopup.show = false;
        }, err => {
            if (err.data && err.data) {
                if (!duplicateDomain && this.userDomains.length >= 5) {
					this.addActiveDomainPopup.applyInProcess = false;
					return;
                } else {
                    this.addActiveDomain(this.domain, err.data.message);
					this.addActiveDomainPopup.applyInProcess = false;
                }
            }
        });
    };

    getAmazonDomainConfigString(domainObj) {
        let data = domainObj.data;
        let records = [
            `trustifi.trustificorp.info 3600 MX 10 feedback-smtp.us-east-1.amazonses.com`,
            `_amazonses.trustificorp.info 3600 TXT "${data.verify_token}"`,
            `trustifi.trustificorp.info 3600 TXT "v=spf1 include:amazonses.com ~all"`,
            `${data.dkim[0]}._domainkey.trustificorp.info 3600 CNAME ${data.dkim[0]}.dkim.amazonses.com`,
            `${data.dkim[1]}._domainkey.trustificorp.info 3600 CNAME ${data.dkim[1]}.dkim.amazonses.com`,
            `${data.dkim[2]}._domainkey.trustificorp.info 3600 CNAME ${data.dkim[2]}.dkim.amazonses.com`
        ]

        return records.join('\n');
    };

    copyAmazonDomainConfigString() {
        this.clipboard.copy(this.amazonVerificationPopup.configString);
    }

    openAmazonVerificationPopup(domainObj) {
        this.amazonVerificationPopup =  {
            domainObj,
            configString: this.getAmazonDomainConfigString(domainObj),
            show: true
        };
    };

    openDnsDetailsPopup(domainObj) {
        this.dnsDetailsPopup =  {
            domainObj,
            show: true
        };
    };

    getDomainDkimAndTokenCsv(domain) {
        if (!domain) {
            return;
        }

        let csvString = "TXT Records:\n";
        csvString += 'Host,Type,Value\n';
        csvString += `_amazonses,TXT,${domain.data.verify_token}\n`;
        csvString += `trustifi,TXT,"v=spf1 include:amazonses.com ~all"\n`;
        csvString += '\n';

        csvString += 'CNAME Records:\n';
        csvString += 'Host,Type,Pointer\n';
        domain.data.dkim.forEach((dkim) => {
            csvString += `${dkim}._domainkey,CNAME,${dkim}.dkim.amazonses.com\n`;
        });
        csvString += '\n';

        csvString += 'MX Records:\n';
        csvString += 'Host,Type,Pointer,Priority\n';
        csvString += `trustifi,MX,feedback-smtp.us-east-1.amazonses.com,10\n`;

        this.gs.exportCsv(csvString, `domain_ ${domain.domain}_data.csv`);
    };

    emailForwardingChange = (domainObj) => {
        this.gs.showPopup({
            title: 'Email feedback forwarding',
            subTitle: `You are about to ${domainObj.email_feedback_forwarding ? 'disable' : 'enable'} email feedback forwarding for ${domainObj.domain}`,
            body: [
            ],
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Apply',
            doneCb: () => {
                const updateData = {
                    enable: !domainObj.email_feedback_forwarding,
                    action: this.dic.CONSTANTS.domainsActions.emailForwardingEnable.value
                };
                this.rs.updateDomain(domainObj.domain, updateData).then(res => {
                    domainObj.email_feedback_forwarding = !domainObj.email_feedback_forwarding;
                    this.ns.showInfoMessage(this.dic.MESSAGES.domainUpdate);
                }, err => {
                });
            }
        });
    };

    openAssignDomainToAdminPopup(domainObj) {
		const planAdmins = _.cloneDeep(this.gs.planAdmins);
		planAdmins.shift(); // remove partner admin
        if (!planAdmins || !planAdmins.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.domainsNoOtherAdmins);
            return;
        }

        planAdmins.forEach(admin => {
            admin['formattedDisplay'] = admin.user_id.name + ' (' + admin.email + ')';
            admin.hide = false;
        })

        this.assignDomainToAdminPopup = {
            domainToAssign: domainObj,
            adminsList: planAdmins,
            selectedAdmin: null,
            show: true
        };
    };

    assignDomainToAdmin() {
        if (this.assignToAdminInProcess) {
            return;
        }
        if (!this.assignDomainToAdminPopup.selectedAdmin) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'admin'));
            return;
        }

        this.assignToAdminInProcess = true;
        const selectedAdmin = this.assignDomainToAdminPopup.selectedAdmin;

        const assignData = {
            email: selectedAdmin.user_id.email,
            action: this.dic.CONSTANTS.domainsActions.assignDomain.value
        };

        this.rs.updateDomain(this.assignDomainToAdminPopup.domainToAssign.domain, assignData).then(response => {
            this.assignDomainToAdminPopup.domainToAssign.assigned_to = selectedAdmin.user_id.email;

            if (!this.assignAdmins.includes(selectedAdmin.user_id.email)) {
                this.assignAdmins.push(selectedAdmin.user_id.email);
            }
            this.initFilters();

            this.assignDomainToAdminPopup = null;
            this.assignToAdminInProcess = false;
        }, err => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            this.assignToAdminInProcess = false;
        });
    };

    unassignFromAdmin = (domainObj) => {
        if (this.assignToAdminInProcess) return;
		this.unassignFromAdminPopup = {
			show: true,
			subTitle: `Please note - you are about to unassign a domain from ${domainObj.assigned_to}`,
			verifyDomain: {
					equalsTo: domainObj.domain,
					model: ''
				},
			bodyDetails: [
				`All email accounts under this domain will not be verified for admin ${domainObj.assigned_to}`,
				`In case ${domainObj.assigned_to} is connected to Email Relay, they will no longer be able to use it`
			],
			doneCb: () => {
				this.assignToAdminInProcess = true;

				const assignData = {
					email: domainObj.assigned_to,
					action: this.dic.CONSTANTS.domainsActions.unassignDomain.value
				};
				this.rs.updateDomain(domainObj.domain, assignData).then(res => {
					domainObj.assigned_to = '';
					this.assignToAdminInProcess = false;
					this.unassignFromAdminPopup.show = false;
				}, err => {
					this.assignToAdminInProcess = false;
				});
			}
		}
    };

    exportToCsv = (sortBy) => {
        if (!this.userDomains || !this.userDomains.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Domain,Status,DKIM,MAIL FROM, Email feedback forwarding, SPF, Created,Assigned To\n";

        let sortedTable = _.filter(this.userDomains,domain => {return !domain.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        sortedTable.forEach((domain) => {
            csvString += `${domain.domain},"${domain.verified ? 'Can send' : 'Cannot send'}","${domain.dkim_verified ? 'Verified' : 'Unverified'}","${domain.mail_from_verified ? 'Verified' : 'Unverified'}","${domain.email_feedback_forwarding ? 'Enabled' : 'Disabled'}","${domain.spf_verified ? 'Verified' : 'Unverified'}",${domain.created},"${domain.assigned_to || 'Your plan'}"\n`;
        });

        this.gs.exportCsv(csvString, `domains.csv`);
    };

    searchDomain = (searchTerm, activeFilters) => {
        this.userDomains.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            if (activeFilters) {
                // need to match all filter types
                let numFilterToMatch = Object.keys(activeFilters).length;

                if (activeFilters.status && activeFilters.status.length) {
                    if (activeFilters.status.includes(record.verified ? 'can send' : 'cannot send')) {
                        numFilterToMatch--;
                    }
                }

                if (activeFilters.dkim && activeFilters.dkim.length) {
                    if (activeFilters.dkim.includes(record.dkim_verified ? 'verified' : 'unverified')) {
                        numFilterToMatch--;
                    }
                }
				if (activeFilters.spf && activeFilters.spf.length) {
					if (activeFilters.spf.includes(record.spf_verified ? 'verified' : 'unverified')) {
						numFilterToMatch--;
					}
				}
                if (activeFilters['mail from'] && activeFilters['mail from'].length) {
                    if (activeFilters['mail from'].includes(record.mail_from_verified ? 'verified' : 'unverified')) {
                        numFilterToMatch--;
                    }
                }
                if (activeFilters['Email feedback forwarding'] && activeFilters['Email feedback forwarding'].length) {
                    if (activeFilters['Email feedback forwarding'].includes(record.email_feedback_forwarding ? 'enabled' : 'disabled')) {
                        numFilterToMatch--;
                    }
                }
                if (activeFilters['assigned to'] && activeFilters['assigned to'].length) {
                    if (activeFilters['assigned to'].includes(record.assigned_to ? record.assigned_to : 'your plan')) {
                        numFilterToMatch--;
                    }
                }
                if (numFilterToMatch) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    initFilters = () => {
        this.filterData = {
            filterType: this.dic.CONSTANTS.tableFilters.domains,
            filters: {
                status: ['can send', 'cannot send'],
                dkim: ['verified', 'unverified'],
				spf: ['verified', 'unverified'],
				'mail from': ['verified', 'unverified'],
                'Email feedback forwarding': ['enabled', 'disabled'],
                'assigned to': this.assignAdmins
            },
            initFiltersFunction: this.initFilters,
        };
    }
}

function searchTextExecute(domainObj, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return ((domainObj.domain && domainObj.domain.toLowerCase().indexOf(searchTerm) > -1) ||
        (domainObj.assigned_to && domainObj.assigned_to.toLowerCase().indexOf(searchTerm) > -1) ||
        (!domainObj.assigned_to && "Your plan".toLowerCase().indexOf(searchTerm) > -1));
}
