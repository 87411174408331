import {DICTIONARY} from '../../dictionary';
import {RouteService} from '../../services/routeService';
import {NotificationService} from '../../services/notificationService';
import {GeneralService} from '../../services/generalService';
import {AuthService} from "../../services/authService";
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";


@Component({
	selector: 'signup-component',
	templateUrl: './signup.component.html',
})
export class SignupComponent implements OnInit{

    constructor(private rs:RouteService,
				private gs:GeneralService,
				private ns:NotificationService,
				private router:Router,
				private activatedRoute:ActivatedRoute,
				private authService:AuthService,) {
	}

    dic = DICTIONARY;
    signupWelcome = this.gs.signupWelcome;
    signupCompany = this.gs.signupCompany;
    validateEmail = this.gs.validateEmail;
    numUsers = 1;
    successMessage = false;
    signupData:any = {
        accountDetails: {
            phone: {country_code: this.gs.country_code}
        }
    };

	states = ['accountDetails', 'additionalInformation', 'plan', 'paymentDetails', 'finalState'];
	signUpState = 'accountDetails';
    partner;

    eula = this.gs.eula;
    privacy = this.gs.privacy;
    antiSpam = this.gs.antiSpam;
    errMsg;

    ngOnInit() {
		// signup with plan key
        if (this.activatedRoute.snapshot.paramMap.has('key')) {
            this.signupData.accountDetails.key = this.activatedRoute.snapshot.paramMap.get('key');

			const urlQuery = new URLSearchParams(location.search.substring(1) || '');
            this.signupData.accountDetails.email = urlQuery.get('email');
            if (urlQuery.has('name')) {
				const nameArr = urlQuery.get('name').split(' ');
				this.signupData.accountDetails.firstName = nameArr[0];
				this.signupData.accountDetails.lastName = nameArr[1] || '';
            }
        }
		// signup with partner (company) name
        else if (this.activatedRoute.snapshot.paramMap.has('company') || window.history.state.company) {
			const companyName = this.activatedRoute.snapshot.paramMap.get('company') || window.history.state.company;
            this.partner = this.dic.PAYPAL_PARTNERS[companyName];
            if (this.partner) {
                this.partner.id = companyName;
                if (this.partner.maxUsers) {
                    this.numUsers = Math.round(0.25 * this.partner.maxUsers) || 1;
                }
            }
            else {
				this.router.navigate([this.dic.CONSTANTS.appStates.signup], {state: {company: this.dic.CONSTANTS.trustifiDefault.name.toLowerCase()}});
            }
        }
    }

    login(email, access_token, expires_in) {
        if(access_token) {
            this.authService.remoteLogin({
                expiresIn: expires_in,
                accessToken: access_token
            }, err => {
                if(!err) {
					this.router.navigate([this.dic.CONSTANTS.appStates.home]);
				}
            });
        }
        else {
			this.router.navigate([this.dic.CONSTANTS.appStates.login], {state: {email: email, gothrough: true}});
        }
    };

    paypalComplete(err, res) {
        if(err) {
        }
        else {
            this.login(res.email, res.access_token, res.expires_in);
        }
    };

    trustifiFeatures = [
        {
            name: "OUTBOUND SHIELD™",
            description: "Advanced protection for\noutgoing data",
            subFeatures: [
                "Send unlimited encrypted emails",
                "Multifactor Authentication",
                "Upload files/attachments up to 1GB",
                "20GB of Fully-Encrypted-Storage for your data",
                "Tracking and Postmark Proof"
            ]
        },
        {
            name: "INBOUND SHIELD™",
            description: "High-end protection from\nthreats and attacks",
            subFeatures: [
                "Exclusive modern mail protection engines",
                "No phishing, spoofing, impersonation, BEC, Malware and other modern threats",
                "Full scan of all email components (headers, links, attachments)",
                "Threat response system",
                "Advanced whitelist and blocklisting capabilities"
            ]
        },
        {
            name: "OTHER FEATURES",
            description: "",
            subFeatures: [
                "Easily deployed with Microsoft Outlook and Google Workspace",
                "Suspicious activity detection",
                "Report suspicious emails using the add-in or directly from the email itself",
                "Integrations with 3rd party applications",
                "Real time reports"
            ]
        },
    ]
}
