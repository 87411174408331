<div class="flex-column gap-4 animated fadeIn">
    <span>API for sending and receiving email information</span>

    <div *ngIf="myAccountApi">
        <!--where no key ("generate key")-->
        <btn-c *ngIf="!myAccountApi.key"
             (action)="createApiKeyExecute();">
            Generate API Key
        </btn-c>
        <!--------------------------------->
        <div class="list-group list-group-flush settings-list-container" *ngIf="myAccountApi.key">
            <!--Settings: Active -->
            <div class="list-group-item settings-list-item">
                <div class="title-section-container flex-column gap-2">
                    <b>Active</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;">You can use the Trustifi API using your API key and secret</span>
                </div>

                <div class="content-container flex-row gap-2">
                    <toggle-switch-c id="activateApiToggle"
                                   [(model)]="myAccountApi.enabled"
                                   (onChange)="updateApiKeyStatus()"></toggle-switch-c>
                </div>
            </div>

            <!--Settings: Key -->
            <div class="list-group-item settings-list-item" *ngIf="myAccountApi.enabled">
                <div class="title-section-container flex-column gap-2">
                    <b>Key</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;">Key to be used in header x-trustifi-key</span>
                </div>
                <div class="content-container flex-column gap-2">
                    <div class="flex-row align-items-center gap-2">
                        <input style="width: 370px; margin: 0;"
                               [(ngModel)]="myAccountApi.key"
                               disabled type="text">
                        <span (click)="copyApiKeyToClipboard(true);" tooltip="Copy the API key"
                           style="font-size: 11px; margin-left:10px;" class="hSpace05 copy-link">
                            <i class="fas fa-copy"
                               style="cursor: pointer;"></i>
                            <span id="adminApiCopyKey">Copy</span>
                        </span>
                    </div>
                    <div class="flex-row align-items-center gap-2">
                        <span>Key creation date:</span>
                        <time>{{ myAccountApi.created | date:'MM/dd/yyyy HH:mm' }}</time>
                    </div>
                </div>
            </div>

            <!--Settings: Secret -->
            <div class="list-group-item settings-list-item" *ngIf="myAccountApi.enabled">
                <div class="title-section-container flex-column gap-2">
                    <b>Secret</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;">Secret to be used in header x-trustifi-secret</span>
                </div>
                <div class="content-container flex-column gap-2">
                    <div class="flex-row align-items-center gap-2">
                        <input style="width: 370px; margin: 0;" disabled
                               [(ngModel)]="myAccountApi.secret"
                               [ngClass]="{'animated flash': updatedAPI, nofill: !showsecret}"
                               type="{{!showsecret && !hasWKTextSecurity ? 'password':'text'}}">
                        <label style="position: relative; left:-30px; opacity : 0.2;"
                               *ngIf="myAccountApi.secret"
                               class="hidepwd m-0">
                            <input type="checkbox" [(ngModel)]="showsecret"/>
                            <i class="fa fa-eye unchecked"></i>
                            <i class="fa fa-eye-slash checked"></i>
                        </label>
                        <div class="flex-row gap-2" style="margin-left: -10px;">
                            <span (click)="copyApiKeyToClipboard(false);" tooltip="Copy the API secret"
                               style="font-size: 11px;" class="hSpace05 copy-link">
                                <i class="fas fa-copy"
                                   style="cursor: pointer;"></i>
                                <span id="adminApiCopySecret">Copy</span>
                            </span>
                            <span (click)="regenerateApiKey();"
                               *ngIf="userInfo.type !== dic.CONSTANTS.userTypes.heroku"
                               tooltip="Generate a new secret for the API" style="font-size: 11px;" class="hSpace05 copy-link">
                                <i [ngClass]="regenerateSecretInProcess ? 'fa fa-spinner fa-spin' : 'fas fa-sync-alt'" style="cursor: pointer;"></i>
                                <span>Regenerate</span>
                            </span>
                        </div>
                    </div>
                    <div class="flex-row align-items-center gap-2">
                        <span>Secret last update date:</span>
                        <time>{{ myAccountApi.updated | date:'MM/dd/yyyy HH:mm' }}</time>
                    </div>
                </div>
            </div>

            <!--Settings: Secret -->
            <div class="list-group-item settings-list-item" *ngIf="showApiDocemntation">
                <div style="text-align: left;" >
                    <a href="https://api.trustifi.com/" style="font-size:11px;"
					   target="_blank" rel="noopener noreferrer nofollow">
                        <p class="link-primary" style="margin: 0;">
                            <i class="fa fa-question-circle" style="padding-right:3px;"></i>
                            Need help? Click here for Trustifi's API documentation
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
