<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
	<span>Create, view, and manage your own DLP sensitivity types.</span>

	<trustifi-table-c
		class="trustifi-table short-action-column" style="flex: 1 0; overflow: auto; z-index: 5;"
		[list]="personalTypes"
		[headers]="[
			{text:'Name', sortKey:'name', width: 'clamp(200px,50vw,300px)'},
			{text:'Regex', width: '25%'},
			{text:'Score', sortKey:'sensitivity', width: '100px'},
		]"
		[cellsPrototype]="[
			{textKey:'name', edit: {html: 'nameCellEdit'}},
			{textKey: 'regex', edit: {modelKey: 'regex', placeholder: 'Regex'}},
			{textKey: 'sensitivity', overflowOutside: true, edit: {html: 'scoreCellEdit'}},
		]"
		[cellActions] = "{
			enable: true,
			selectItemActionCb:	selectSensitivityTypeActions,
			initActionsFunction: showSensitivityTypeActions,
			showInRed: {enable: true, terms: ['Delete']}
		}"
		[bulkActions] = "{
			enable: true,
			selectBulkActionCb:	selectMultipleSensitivityTypeAction,
			initBulkActionsFunction: showSensitivityTypeBulkActions,
			showInRed: {enable: true, terms: ['Delete']}
		}"
		(searchFunction)="searchType($event.searchTerm, $event.activeFilters)"
		(confirmEditFunction)="confirmEditType($event.item, $event.isApproved)"
		[options]="{
			defaultSortKey: '-name',
			loadingTableFlag: getSensitivityTypesInProcess,
			itemsNameSingular: 'sensitivity type',
			exportToCsvFunction: exportToCsv,
		}"
	>
		<ng-template #customCells let-item let-cellId="cellId">
			<!--name cells - EDIT-->
			<span class="h-100 flex-column justify-content-center" *ngIf="cellId === 'nameCellEdit'">
				<input *ngIf="item.isNew"
					   id="personalTypeNameEditInput" type="text"
					   class="h-100" placeholder="Name"
					   [(ngModel)]="item.edit.name">
				<span *ngIf="!item.isNew">{{item.name}}</span>
			</span>
			<!--score cells - EDIT-->
			<span *ngIf="cellId === 'scoreCellEdit'">
				<app-dropdown-c style="width: 45px; height: 100%;" text="{{item.edit.sensitivity}}">
					<li *ngFor="let sen of [1,2,3,4,5]"
						(click)="item.edit.sensitivity = sen">
						{{sen}}
					</li>
				</app-dropdown-c>
			</span>
		</ng-template>

		<div tableButtons>
			<!--Add Type button-->
			<div class="w100">
				<btn-c endIcon="fa fa-plus"
					   [isDisabled]="addTypeInProcess"
					   (action)="startAddType();">
					Add Sensitivity Type
				</btn-c>
			</div>
		</div>
	</trustifi-table-c>
</div>

