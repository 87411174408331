import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DICTIONARY} from "../dictionary";
import {SERVERCONF} from "../constants"
import {GeneralService} from "../services/generalService";
import {RouteService} from "../services/routeService";
import {NotificationService} from "../services/notificationService";

@Component({
	selector: 'paypal-buttons-component',
	template: `<div id="paypal-button-container"></div>`
})
export class PaypalButtonsComponent implements OnInit{

	@Input() type;
	@Input() partner;
	@Input() numUsers;
	@Input() accountDetails;
	@Input() hasAccessToken;
	@Input() couponCode;
	@Output() onComplete = new EventEmitter<any>;

	constructor(private gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
	}

	dic = DICTIONARY;

	debug = false;
	paypalMode;
	paypalClientId;
	checkout_config = {
		style: {
			label: 'buynow', // checkout | credit (color:creditblue) | pay | buynow (fundingicons:true, branding:true)
			fundingicons: true,
			size:  'small',    // small | medium | large | responsive
			shape: 'pill',     // pill | rect
			color: 'blue',      // gold | blue | silver | black
			branding: true
		},
		env: null,
		commit: true, // Optional: show a 'Pay Now' button in the checkout flow

		// onInit is called when the button first renders
		onInit: (data, actions) => {

			// Disable the buttons
			//actions.disable();
		},
		// onClick is called when the button is clicked
		//onClick: function() {},

		//alias for "payment"
		createOrder: () => {
			/*return actions.order.create({
				purchase_units: [{
					amount: {
						value: '0.01'
					}
				}]
			});*/

			if ((this.type === this.dic.CONSTANTS.paypalPaymentType.newUser || this.type === this.dic.CONSTANTS.paypalPaymentType.upgradePro)
				&& (this.numUsers < 1 || this.numUsers > 20)) {
				this.ns.showErrorMessage('Number of users should be between 1-20');
				return;
			}
			if (this.type === this.dic.CONSTANTS.paypalPaymentType.extendPlanLicenses && !this.numUsers) {
				this.ns.showErrorMessage('Number of users must be bigger than 1');
				return;
			}

			if (!this.gs.validateEmail(this.accountDetails.email)) {
				this.ns.showErrorMessage('Email is invalid');
				return;
			}

			return this.rs[this.hasAccessToken ? 'paypalCreateOrderI':'paypalCreateOrder']({
				type: this.type,
				sandbox: this.partner.paypalSandbox,
				customerId: this.partner.id,
				coupon: this.couponCode || '',
				email: this.accountDetails.email,
				phone: this.accountDetails.phone,
				numUsers: this.numUsers || 0,
			}).then(res => {
				console.log('Paypal: create', res);
				return res.order_id;    //data.orderID
			}, err => {
				console.error('Paypal: create', err);
			});
		},

		//alias for "onAuthorize"
		onApprove: (data, actions) => {
			//console.log('Paypal: onApprove', data);

			//take the payment and buyer details
			//actions.order.get().then(orderDetails => {...})

			//capture the funds from the transaction.
			/*return actions.order.capture().then(function(details) {
				// This function shows a transaction success message to your buyer.
				alert('Transaction completed by ' + details.payer.name.given_name);
			});*/

			return this.rs[this.hasAccessToken ? 'paypalExecuteOrderI':'paypalExecuteOrder']({
				type: this.type,
				sandbox: this.partner.paypalSandbox,
				accountDetails: this.accountDetails,
				customerId: this.partner.id,
				orderId: data.orderID,
				payerId: data.payerID,
				numUsers: this.numUsers,
			}).then(res => {
				console.log('Paypal: execute', data);
				this.onComplete.emit({res});
			}, err => {
				console.error('Paypal: execute', err);
				this.onComplete.emit({err});
			});
		},
		onCancel: (data) => {

		},
		onError: (err) => {
			console.error('Paypal: The payment error', err);
			this.ns.showMessage(err.data);
		},
		validate: () => {
			//event right after clicking the button
		}
	};

	loadScript = (cb) => {
		const script:any = document.createElement('script');
		script.src = 'https://www.paypal.com/sdk/js?debug='+this.debug+'&client-id='+this.paypalClientId+'&disable-funding=credit';    //&locale=language&merchant-id=paypalMerchantId&currency=EUR
		script.onload = cb;

		document.body.appendChild(script);
	};

	ngOnInit() {
		this.paypalMode = this.partner && this.partner.paypalSandbox && 'sandbox' || SERVERCONF.paypalMode;
		this.paypalClientId = this.paypalMode === 'sandbox' ? SERVERCONF.paypalSandboxClientId : SERVERCONF.paypalLiveClientId;

		if (!this.partner || !this.paypalClientId) return;

		((cb) => {
			if (!(window as any).paypal) {
				this.loadScript(cb);
			}
			else
				cb();
		})(() => {
			if (window !== window.parent && !(window as any).paypal['allowIframe'] && !(window as any).paypal['allowIframe']()) {
				console.error('Paypal: frames are not allowed!');
			}

			this.checkout_config.env = this.paypalMode;
			(window as any).paypal.Buttons(this.checkout_config)
				.render('#paypal-button-container');
		})
	}
}
