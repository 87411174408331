<link rel="preload" href="images/loginPageBg.jpg" as="image">
<div class="home static-color bs-enabled top-level-page"
	 style="background-image: url('images/loginPageBg.jpg'); width: 100vw; height: 100vh;"
	 [ngStyle]="{'display' : htmlRetrieved ? 'block' : 'none'}">
    <div id="wrapper" class="flxClmn centralize" style="min-height: clamp(603px, 88vh, 2500px);">
		<div class="flex-column bg-white p-5 pt-3 login-box" style="width: 425px; height: 600px; box-shadow: 0 0 12px black !important;">
			<div class="align-self-center d-flex centralize p-2" style="flex-basis: 95px; flex-shrink: 0;">
				<img [src]="logo" style="max-height: 45px;" alt="{{corpname}}"
					 [ngStyle]="logo === dic.CONSTANTS.trustifiDefault.logo.svg && {'width': '180px'}">
			</div>

			<!--FORM: email or SSO (initial)-->
			<email-and-sso-component class="flex-column f-1-0 w-100 animated fadeIn"
									 *ngIf="loginStatus === loginStatuses.emailOrSSOForm"
									 [plan]="plan"
									 [error]="error"
									 [userEmailInput]="userEmailInput"
									 [loginStatuses]="loginStatuses"
									 (loginStatusChange)="handleLoginStatusChange($event)">
			</email-and-sso-component>

			<!--FORM: login with password-->
			<password-login-component *ngIf="loginStatus === loginStatuses.passwordLoginForm"
									  class="flex-column f-1-0 w-100 animated fadeIn"
									  [userEmailInput]="userEmailInput"
									  [loginStatuses]="loginStatuses"
									  [error]="error"
									  (loginStatusChange)="handleLoginStatusChange($event)">
			</password-login-component>


			<!--FORM: signup-->
			<signup-form-component class="flex-column f-1-0 w-100 animated fadeIn"
								   *ngIf="loginStatus === loginStatuses.signupForm"
								   [error]="error"
								   (loginStatusChange)="handleLoginStatusChange($event)">
			</signup-form-component>

			<!--FORM: MFA code-->
			<mfa-component class="flex-column f-1-0 w-100 animated fadeIn"
						   *ngIf="loginStatus === loginStatuses.mfaCodeForm"
						   [data2Fa]="data2Fa"
						   [error]="error"
						   (loginStatusChange)="handleLoginStatusChange($event)"
						   [enableResend]="enableResend" [loginStatuses]="loginStatuses"
						   [disableConfirmAuth]="disableConfirmAuth">
			</mfa-component>
		</div>
		<notification-toaster-component></notification-toaster-component>
    </div>
</div>
