import _ from 'lodash';
import {Component, inject, NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, Router, RouterModule, Routes,} from '@angular/router';
import {AuthService} from "./services/authService";
import {LoginComponent} from "./components/login/login.component";
import {DICTIONARY} from "./dictionary";
import {SignupComponent} from "./components/signup/signup.component";
import {PurchasePageComponent} from "./components/signup/purchase/purchase-page.component";
import {HomeComponent} from "./components/home/home.component";
import {
	EmailRelayIntegrationComponent
} from "./components/admin/outbound/planSettings/outboundEmailRelay/email-relay-integration.component";
import {PlanSettingsDomainComponent} from "./components/admin/planSettings/domains/plan-settings-domain.component";
import {RegionComponent} from "./components/admin/planSettings/region/region.component";
import {IntegrationsComponent} from "./components/admin/planSettings/integrations/integrations.component";
import {
	SharedPlanSettingsComponent
} from "./components/admin/outbound/planSettings/sharedPlan/shared-plan-settings.component";
import {UserManagementComponent} from "./components/admin/outbound/userManagement/user-management.component";
import {
	OutboundPoliciesComponent
} from "./components/admin/outbound/rulesAndPolicies/policies/outbound-policies.component";
import {OutboundRulesComponent} from "./components/admin/outbound/rulesAndPolicies/rules/outbound-rules.component";
import {
	OutboundReviewersComponent
} from "./components/admin/outbound/rulesAndPolicies/outbound-reviewers/outbound-reviewers.component";
import {OutboundTrendsComponent} from "./components/admin/outbound/outboundTrends/outbound-trends.component";
import {
	OutboundQuarantinedComponent
} from "./components/admin/outbound/outboundQuarantined/outbound-quarantined.component";
import {GenerateReportComponent} from "./components/admin/outbound/reports/generateReport/generate-report.component";
import {ScheduleReportComponent} from "./components/admin/outbound/reports/scheduleReport/schedule-report.component";
import {LookAndFeelComponent} from "./components/admin/outbound/lookAndFeel/look-and-feel.component";
import {
	InboundEmailRulesComponent
} from "./components/admin/inbound/inboundShield/inboundEmailRules/inbound-email-rules.component";
import {
	InboundEmailRelayIntegrationComponent
} from "./components/admin/inbound/planSettings/inboundEmailRelay/inbound-email-relay-integration.component";
import {InboundMailboxesComponent} from "./components/admin/inbound/mailboxes/inbound-mailboxes.component";
import {
	InboundConfigurationComponent
} from "./components/admin/inbound/inboundShield/inboundConfiguration/inbound-configuration.componenet";
import {
	ThreatPreventionComponent
} from "./components/admin/inbound/inboundShield/threatPrevention/threat-prevention.component";
import {ScanThreatsComponent} from "./components/admin/inbound/inboundShield/scanThreats/scan-threats.component";
import {
	SecurityAwarenessComponent
} from "./components/admin/inbound/inboundShield/securityAwareness/security-awareness.component";
import {
	InboundBlockFileTypesComponent
} from "./components/admin/inbound/configuration/blockFileTypes/inbound-block-file-types.component";
import {
	InboundAllowBlockListsComponent
} from "./components/admin/inbound/configuration/allowBlockLists/inbound-allow-block-lists.component";
import {InboundQuarantinedComponent} from "./components/admin/inbound/quarantined/inbound-quarantined.component";
import {UrlTrackingComponent} from "./components/admin/inbound/urlTracking/url-tracking.component";
import {InboundTrendsComponent} from "./components/admin/inbound/trends/inbound-trends.component";
import {ThreatResponseComponent} from "./components/admin/inbound/threatResponse/threat-response.component";
import {DiscoveredServicesComponent} from "./components/admin/inbound/discoveredServices/discovered-services.component";
import {UserInboundConfComponent} from "./components/admin/inbound/userInboundConf/user-inbound-conf.component";
import {UserQuarantinedComponent} from "./components/admin/inbound/userQuarantined/user-quarantined.component";
import {
	ThreatSimulationCampaignsComponent
} from "./components/threatSimulation/threatSimulationCampaigns/threat-simulation-campaigns.component";
import {
	ThreatSimulationConfigComponent
} from "./components/threatSimulation/threatSimulationConfig/configuration/threat-simulation-config.component";
import {
	ThreatSimulationTemplateFieldsComponent
} from "./components/threatSimulation/threatSimulationConfig/threat-simulation-template-fields/threat-simulation-template-fields.component";
import {
	ThreatSimulationReviewersComponent
} from "./components/threatSimulation/threatSimulationConfig/reviewers/threat-simulation-reviewers.component";
import {
	ThreatSimulationTemplatesComponent
} from "./components/threatSimulation/threatSimulationTemplates/threat-simulation-templates.component";
import {
	ThreatSimulationTrendsComponent
} from "./components/threatSimulation/ThreatSimulationTrends/threat-simulation-trends.component";
import {
	ThreatSimulationAuditLogsComponent
} from "./components/threatSimulation/threatSimulationAuditLogs/threat-simulation-audit-logs.component";
import {DomainSpoofingComponent} from "./components/admin/domainProtection/domainSpoofing/domain-spoofing.component";
import {
	DomainImpersonationComponent
} from './components/admin/domainProtection/domain-impersonation/domain-impersonation.component';
import {DmarcReportsComponent} from "./components/admin/domainProtection/dmarcReports/dmarc-reports.component";
import {
	DomainProtectionAuditLogsComponent
} from "./components/admin/domainProtection/auditLog/domain-protection-audit-logs.component";
import {
	AccountManagementComponent
} from "./components/accountCompromised/accountManagement/account-management.component";
import {
	AccountCompromisedConfigurationsComponent
} from "./components/accountCompromised/configurations/incidentsConfigurations/account-compromised-configurations.component";
import {
	AccountCompromisedReviewerConfigurationsComponent
} from "./components/accountCompromised/configurations/reviewerConfigurations/account-compromised-reviewer-configurations.component";
import {
	AccountCompromisedIncidentsComponent
} from "./components/accountCompromised/incidents/account-compromised-incidents.component";
import {
	AccountCompromisedTrendsComponent
} from "./components/accountCompromised/accountCompromisedTrends/account-compromised-trends.component";
import {
	AccountCompromisedAuditLogComponent
} from "./components/accountCompromised/accountCompromisedAuditLog/account-compromised-audit-log.component";
import {PartnerUsageComponent} from "./components/partners/partnerUsage/partner-usage.component";
import {PartnerRegistrationComponent} from "./components/partners/partnerRegistration/partner-registration.component";
import {PartnerResourcesComponent} from "./components/partners/partnerResources/partner-resources.component";
import {PartnerContactInfoComponent} from "./components/partners/partnerContactInfo/partner-contact-info.component";
import {ArchiveSearchComponent} from "./components/archive/archiveSearch/archive-search.component";
import {ArchiveCasesComponent} from "./components/archive/archiveCases/archive-cases.component";
import {ArchiveAuditLogComponent} from "./components/archive/archiveAuditLog/archive-audit-log.component";
import {SentEmailsComponent} from "./components/sentEmails/sent-emails.component";
import {InboxComponent} from "./components/inbox/inbox.component";
import {ContactsComponent} from "./components/contactsAndTemplates/contacts/contacts.component";
import {TemplatesPageComponent} from "./components/contactsAndTemplates/templates/templates-page.component";
import {MassListPageComponent} from "./components/contactsAndTemplates/massList/mass-list-page.component";
import {ComposeMessageComponent} from "./components/composeMessage/compose-message.component";
import {AttachmentsComponent} from "./components/attachments/attachments.component";
import {MyProfileComponent} from "./components/accountDetails/accountSettings/accountProfile/my-profile.component";
import {
	AccountSecurityComponent
} from "./components/accountDetails/accountSettings/accountSecurity/account-security.component";
import {AccountApiComponent} from "./components/accountDetails/accountSettings/accountApi/account-api.component";
import {
	AccountAddressesComponent
} from "./components/accountDetails/accountSettings/accountAddresses/account-addresses.component";
import {
	AccountIntegrationsComponent
} from "./components/accountDetails/accountSettings/accountIntegrations/account-integrations.component";
import {MyPlanComponent} from "./components/accountDetails/myPlan/my-plan.component";
import {
	AccountDetailsNotificationsComponent
} from "./components/accountDetails/notifications/account-details-notifications.component";
import {KnowledgeComponent} from "./components/knowledge/knowledge.component";
import {OutboundPlanTabsComponent} from "./components/admin/outbound/planSettings/outbound-plan-tabs.component";
import {
	RulesAndPoliciesTabsComponent
} from "./components/admin/outbound/rulesAndPolicies/rules-and-policies-tabs.component";
import {OutboundReportTabsComponent} from "./components/admin/outbound/reports/outbound-report-tabs.component";
import {
	InboundPlanSettingsTabsComponent
} from "./components/admin/inbound/planSettings/plan/inbound-plan-settings-tabs.component";
import {InboundShieldTabsComponent} from "./components/admin/inbound/inboundShield/inbound-shield-tabs.component";
import {
	InboundConfigurationTabsComponent
} from "./components/admin/inbound/configuration/inbound-configuration-tabs.component";
import {
	ThreatSimulationsConfigTabsComponent
} from "./components/threatSimulation/threatSimulationConfig/threat-simulation-cfg-tabs.component";
import {
	accountCompromisedCfgTabsComponent
} from "./components/accountCompromised/configurations/account-compromised-configurations-tabs.component";
import {MyAccountTabsComponent} from "./components/accountDetails/accountSettings/myAccount/my-account-tabs.component";
import {ContentComponent} from "./components/content/content.component";
import {GeneralService} from "./services/generalService";
import {ReviewersComponent} from "./components/admin/inbound/inboundShield/reviewers/reviewers.component";
import {LookAndFeelService} from "./services/lookAndFeelService";
import {DataClassificationTabs} from "./components/admin/outbound/dataClassification/data-classification-tabs";
import {
	OutboundSensitivityTypesComponent
} from "./components/admin/outbound/dataClassification/sensitivityTypes/outbound-sensitivity-types.component";
import {
	OutboundPersonalTypesComponent
} from "./components/admin/outbound/dataClassification/personalTypes/outbound-personal-types.component";
import {
	OutboundTestSensitivityComponent
} from "./components/admin/outbound/dataClassification/testSensitivity/outbound-test-sensitivity.component";
import {
	ClassificationTrendsComponent
} from "./components/admin/outbound/dataClassification/classification-trends/classification-trends.component";
import {EmailTraceComponent} from "./components/admin/auditLog/emailTrace/email-trace.component";
import {ActivityLogsComponent} from "./components/admin/auditLog/activityLogs/activity-logs.component";
import {UserPermissionService} from "./services/userPermissionService";
import {
	ArchiveConfigurationsGeneralComponent
} from "./components/archive/archiveConfigurations/archiveGeneral/archive-configurations-general.component";
import {
	ArchiveMigrationComponent
} from "./components/archive/archiveConfigurations/archiveMigration/archive-migration.component";
import {
	ArchiveConfigurationTabsComponent
} from "./components/archive/archiveConfigurations/archive-configuration-tabs.component";
import {
	ThreatSimulationTrainingMaterialsComponent
} from "./components/threatSimulation/threatSimulationTrainingMaterials/threat-simulation-training-materials.component";
import {
	ThreatSimulationCampaignsTabsComponent
} from "./components/threatSimulation/threatSimulationCampaigns/threat-simulation-campaigns-tabs.component";
import {QuickAccessService} from "./services/quickAccessService";
import {NotificationService} from "./services/notificationService";
import util from "util";

const dic = DICTIONARY;

async function userInfoGuard (route, state) {
	const gs = inject(GeneralService);
	if (gs.userInfo) {
		return true;
	}

	const authService = inject(AuthService);
	const requireMfa = !!inject(Router).getCurrentNavigation().extras.state?.data2Fa;

	return new Promise((resolve) => {
		authService.isAuthenticated().then(isAuthenticated => {
			if (!isAuthenticated || requireMfa) {
				return resolve(false);
			}

			// the only way to get to this part of the code is when the user is authenticated and just refreshed the app
			gs.initUserInApp(null, err => {
				if (err) {
					resolve(false);
					inject(AuthService).logout(false);
				} else {
					resolve(true);
				}
			});
		});
	});
}

async function authGuard (route, state) {
	const router = inject(Router);
	const authService = inject(AuthService);
	const requireMfa = !!router.getCurrentNavigation().extras.state?.data2Fa;

	return authService.isAuthenticated().then(isAuthenticated => {
		if (isAuthenticated && !requireMfa) {
			return true;
		}

		// navigated to any content page but not logged in
		else {
			const urlQuery = new URLSearchParams(location.search.substring(1) || '');
			const urlSegments = state.url.substring(1).split('/');

			// preserve the 'message_id' / 'company' query to after the login
			let message_id:any = '';
			if (urlSegments[0] === dic.CONSTANTS.appStates.adminInbound && urlQuery.has('message_id')) {
				message_id = urlQuery.get('message_id');
			}

			authService.logout(false); // "false" here means: do not redirect to login, after the logout action (because we want to preserve the stateParams for after the login)
			router.navigate([dic.CONSTANTS.appStates.login], {state: {transitionName: urlSegments, message_id: message_id}});
			return false;
		}
	});
}

async function loginGuard () {
	const router = inject(Router);
	const stateParams = router.getCurrentNavigation().extras.state;
	const requireMfa = !!stateParams?.data2Fa;

	return inject(AuthService).isAuthenticated().then(isAuthenticated => {
		// navigated to login but already logged in
		if (isAuthenticated && !requireMfa) {
			// go to inbound quarantined -apply query by message id
			if (stateParams?.quarantinedMessageId) {
				return router.createUrlTree([dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.quarantined]);
			}
			// My plan page
			else if (stateParams?.company) {
				return router.createUrlTree([dic.CONSTANTS.appStates.accountDetails, dic.CONSTANTS.accountDetailsPages.myPlan]);
			}
			// global
			else if (stateParams?.transitionName) {
				return router.createUrlTree(stateParams.transitionName);
			}
			// default
			else {
				return router.createUrlTree([dic.CONSTANTS.appStates.home]);
			}
		}
		else {
			return true;
		}
	});
}

// change controlAdmin to main admin if user selected another tenant and then navigated to page without multi-tenant selection, and vice versa
// isMultiTenantSupported is the flag to a page with multi-tenant (such as Outbound).
function controlAdminGuard(route, state) {
	const gs = inject(GeneralService);
	const ns = inject(NotificationService);
	if (!gs.planAdmins?.length) {
		return true;
	}

	try {
		const contentObj = _.find(appRoutes, {path: ''}).children;

		const currentSectionPath = inject(Router).url.split('/')[1];
		const currentSectionData = _.find(contentObj, {path: currentSectionPath})?.data;
		const targetSectionPath = state.url.split('/')[1];
		const targetSectionData = _.find(contentObj, {path: targetSectionPath})?.data;

		if (!currentSectionData || !targetSectionData || gs.controlAdmin.email === gs.planAdmins[0].email || targetSectionData.isMultiTenantSupported === currentSectionData.isMultiTenantSupported) {
			return true;
		}

		let targetAdmin, lfData;

		// navigated from a main-admin-only page to a multi-tenant page
		if (targetSectionData.isMultiTenantSupported) {
			if (gs.adminInfoMap[gs.controlAdmin.email]?.plan?.customization?.lf?.enabled) {
				lfData = gs.adminInfoMap[gs.controlAdmin.email].plan.customization.lf;
			}
			targetAdmin = gs.controlAdmin;
		}

		// navigated from a multi-tenant page to a main-admin-only page
		else {
			lfData = gs.userInfo.plan.customization?.lf;
			targetAdmin = gs.planAdmins[0];
		}

		setTimeout(() => {
			const message = targetAdmin === gs.planAdmins[0] ? dic.MESSAGES.controlAdminChangedToMain : dic.MESSAGES.controlAdminChanged;
			ns.showInfoMessage(util.format(message, targetAdmin.display_email));
		});

		// change look and feel and http headers
		inject(LookAndFeelService).applyLf(lfData?.color || '', lfData?.logo ? "data:image/png;base64," + lfData?.logo : '');
		inject(AuthService).setManagedAdmin(targetAdmin.email);
	}
	catch (err) {
		console.error(err);
	}

	return true;
}

function permissionGuard(route, state) {
	let url = _.trim(state?.url, '/'); // without first slash
	url = url.split('?')[0]; // without query

	if (!url) {
		return true;
	}

	const urlSegments = url.split('/');

	// since the canActivateChild runs for every layer (for route, then for route child, then for child of child...), we call the getPermission function for every given layer by its "full path"
	const routePath = route.routeConfig.path; // section OR page OR tab (e.g 'page')
	const routeFullPath = _.take(urlSegments, urlSegments.indexOf(routePath) + 1).join( '/'); // section WITH page WITH tab (e.g 'section/page')

	const isPermitted = inject(UserPermissionService).getPermission(routeFullPath);

	if (isPermitted) {
		if (urlSegments[urlSegments.length - 1] === routePath) {
			inject(QuickAccessService).countVisitPages(url);
		}
		return true;
	}

	return inject(Router).createUrlTree([dic.CONSTANTS.appStates.home]);
}

// used for route refreshing
@Component({
	selector: 'dummy-component',
	template: '<ng-container></ng-container>',
})
export class DummyComponent {

}

const appRoutes: Routes = [
	{path: dic.CONSTANTS.appStates.login, component: LoginComponent, runGuardsAndResolvers: 'always', canActivate: [loginGuard]},
	{path: dic.CONSTANTS.appStates.purchase, component: PurchasePageComponent},
	{path: dic.CONSTANTS.appStates.signup, children: [
			{path: ':key', component: SignupComponent},
			{path: 'company/:company', component: SignupComponent},
			{path: '', component: SignupComponent, pathMatch: 'full'},
		]},
	{path: 'upgrade/company/:company', redirectTo: '/' + dic.CONSTANTS.appStates.accountDetails + '/' + dic.CONSTANTS.accountDetailsPages.myPlan + '/:company'},

	// Content
	{path: '', component: ContentComponent, runGuardsAndResolvers: 'always', canActivate: [authGuard, controlAdminGuard, userInfoGuard], canActivateChild: [permissionGuard],
		children: [
			// Home
			{path: '', redirectTo: dic.CONSTANTS.appStates.home, pathMatch: 'full'},
			{
				path: dic.CONSTANTS.appStates.home,
				component: HomeComponent,
				data: {
					isMultiTenantSupported: false,
					title: 'Home',
					iconClass: 'fa fa-home',
					keywords: ['home', 'stats', 'statistics']
				}
			},

			// Outbound
			{
				path: dic.CONSTANTS.appStates.adminOutbound,
				data: {
					isMultiTenantSupported: true,
					title: 'Outbound Management',
					iconClass: 'zmdi zmdi-shield-security',
				},
				children: [
					// Plan Settings
					{path: '', redirectTo: dic.CONSTANTS.adminPages.outbound.sharedPlan, pathMatch: 'full'},
					{
						path: dic.CONSTANTS.adminPages.outbound.sharedPlan,
						component: OutboundPlanTabsComponent,
						data: {
							title: 'Plan Settings',
							keywords: ['Plan Settings', 'Outbound', 'Email Server API Connection (Outbound)'],
						},
						children: [
							// Email Flow Integration
							{path: '', redirectTo: dic.CONSTANTS.outboundPlanSettingsPageTabs.emailFlowIntegration, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.outboundPlanSettingsPageTabs.emailFlowIntegration,
								component: EmailRelayIntegrationComponent,
								data: {
									title: 'Email Relay Integration',
									keywords: ['Email Relay Integration', 'Outbound']
								}
							},
							// Domains
							{
								path: dic.CONSTANTS.outboundPlanSettingsPageTabs.domains,
								component: PlanSettingsDomainComponent,
								data: {
									title: 'Domains',
									keywords: ['Domains', 'Outbound']
								}
							},
							// Region
							{path: dic.CONSTANTS.outboundPlanSettingsPageTabs.region,
								component: RegionComponent,
								data: {
									title: 'Region',
									keywords: ['Region', 'Outbound']
								}
							},
							// Integrations
							{
								path: dic.CONSTANTS.outboundPlanSettingsPageTabs.integrations,
								component: IntegrationsComponent,
								data: {
									title: 'Region',
									keywords: ['Region', 'Outbound']
								}
							},
							// Shared Plan Settings
							{
								path: dic.CONSTANTS.outboundPlanSettingsPageTabs.sharedPlanSettings,
								component: SharedPlanSettingsComponent,
								data: {
									title: 'Shared Plan Settings',
									keywords: ['Shared Plan Settings', 'Outbound']
								}
							},
						]
					},
					// User Management
					{
						path: dic.CONSTANTS.adminPages.outbound.userManagement,
						component: UserManagementComponent,
						data: {
							title: 'User Management',
							keywords: ['User Management', 'Outbound', 'users', 'invite', 'Status']
						}
					},
					// Rules & Policies
					{
						path: dic.CONSTANTS.adminPages.outbound.policyManagement,
						component: RulesAndPoliciesTabsComponent,
						data: {
							title: 'Rules & Policies',
							keywords: ['Rules & Policies', 'Outbound', 'policy']
						},
						children: [
							// Rules
							{path: '', redirectTo: dic.CONSTANTS.outboundRulesAndPoliciesPageTabs.rules, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.outboundRulesAndPoliciesPageTabs.rules,
								component: OutboundRulesComponent,
								data: {
									title: 'Rules',
									keywords: ['Rules', 'Outbound']
								}
							},
							// Policies
							{
								path: dic.CONSTANTS.outboundRulesAndPoliciesPageTabs.policies,
								component: OutboundPoliciesComponent,
								data: {
									title: 'Policies',
									keywords: ['Policies', 'Outbound']
								}
							},
							// Reviewers
							{
								path: dic.CONSTANTS.outboundRulesAndPoliciesPageTabs.reviewers,
								component: OutboundReviewersComponent,
								data: {
									title: 'Reviewers',
									keywords: ['Reviewers', 'Outbound']
								}
							},
						]
					},
					// Data Classification
					{
						path: dic.CONSTANTS.adminPages.outbound.dataClassification,
						component: DataClassificationTabs,
						data: {
							title: 'Data Classification',
							keywords: ['Data Classification', 'Data', 'Classification'],
						},
						children: [
							// Trends
							{path: '', redirectTo: dic.CONSTANTS.dataClassificationPageTabs.trends, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.dataClassificationPageTabs.trends,
								component: ClassificationTrendsComponent,
								data: {
									title: 'Trends',
									keywords: ['Classification', 'Trends']
								}
							},
							// Sensitivity Types
							{
								path: dic.CONSTANTS.dataClassificationPageTabs.sensitivityTypes,
								component: OutboundSensitivityTypesComponent,
								data: {
									title: 'Sensitivity Types',
									keywords: ['Sensitivity', 'Types', 'Data', 'Classification']
								}
							},
							// Personal Types
							{
								path: dic.CONSTANTS.dataClassificationPageTabs.personalTypes,
								component: OutboundPersonalTypesComponent,
								data: {
									title: 'Personal Types',
									keywords: ['Personal', 'Types', 'Data', 'Classification']
								}
							},
							// Test Sensitivity
							{
								path: dic.CONSTANTS.dataClassificationPageTabs.testSensitivity,
								component: OutboundTestSensitivityComponent,
								data: {
									title: 'Test Sensitivity',
									keywords: ['Test', 'Sensitivity', 'Data', 'Classification']
								}
							},
						]
					},
					// Trends and Insights
					{
						path: dic.CONSTANTS.adminPages.outbound.trends,
						component: OutboundTrendsComponent,
						data: {
							title: 'Trends and Insights',
							keywords: ['Trends', 'Insights', 'Trends and Insights', 'statistics']
						}
					},
					// Quarantined Emails
					{
						path: dic.CONSTANTS.adminPages.outbound.quarantined,
						component: OutboundQuarantinedComponent,
						data: {
							title: 'Quarantined Emails',
							keywords: ['Quarantined Emails', 'Outbound']
						}
					},
					// Reports
					{
						path: dic.CONSTANTS.adminPages.outbound.report,
						component: OutboundReportTabsComponent,
						data: {
							title: 'Reports',
							keywords: [],
						},
						children: [
							// Generate Report
							{path: '', redirectTo: dic.CONSTANTS.reportPageTabs.generateReport, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.reportPageTabs.generateReport,
								component: GenerateReportComponent,
								data: {
									title: 'Generate Report',
									keywords: ['Generate Report', 'Outbound']
								}
							},
							// Schedule Report
							{
								path: dic.CONSTANTS.reportPageTabs.scheduleReport,
								component: ScheduleReportComponent,
								data: {
									title: 'Schedule Report',
									keywords: ['Schedule Report', 'Outbound']
								}
							},
						]
					},
					// Look and Feel
					{
						path: dic.CONSTANTS.adminPages.outbound.lf,
						component: LookAndFeelComponent,
						data: {
							title: 'Look and Feel',
							keywords: ['Look And Feel', 'Outbound', 'Color']
						}
					},
					// email trace
					{
						path: dic.CONSTANTS.adminPages.outbound.emailTrace,
						component: EmailTraceComponent,
						data: {
							title: 'Email Trace',
							keywords: ['Email Trace', 'Outbound']
						},
					},
					// audit log
					{
						path: dic.CONSTANTS.adminPages.outbound.auditLog,
						component: ActivityLogsComponent,
						data: {
							title: 'Audit Log',
							keywords: ['Audit Log', 'Outbound']
						},
					},
				]
			},
			// Inbound
			{
				path: dic.CONSTANTS.appStates.adminInbound,
				data: {
					isMultiTenantSupported: true,
					title: 'Inbound Management',
					iconClass: 'fas fa-user-shield',
				},
				children: [
					// Plan Settings
					{
						path: dic.CONSTANTS.adminPages.inbound.plan,
						component: InboundPlanSettingsTabsComponent,
						data: {
							title: 'Plan Settings',
							keywords: ['Plan Settings', 'Inbound', 'Email Server API Connection (Inbound)'],

						},
						children: [
							// Email Flow Integration
							{path: '', redirectTo: dic.CONSTANTS.inboundPlanSettingsPageTabs.emailFlowIntegration, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.inboundPlanSettingsPageTabs.emailFlowIntegration,
								component: InboundEmailRelayIntegrationComponent,
								data: {
									title: 'Email Flow Integration',
									keywords: ['Email Relay Integration', 'Inbound']
								}
							},
							// Domains
							{
								path: dic.CONSTANTS.inboundPlanSettingsPageTabs.domains,
								component: PlanSettingsDomainComponent,
								data: {
									title: 'Domains',
									keywords: ['Domains', 'Inbound']
								}
							},
							// Region
							{
								path: dic.CONSTANTS.inboundPlanSettingsPageTabs.region,
								component: RegionComponent,
								data: {
									title: 'Region',
									keywords: ['Region', 'Inbound']
								}
							},
							// Integrations
							{
								path: dic.CONSTANTS.inboundPlanSettingsPageTabs.integrations,
								component: IntegrationsComponent,
								data: {
									title: 'Integrations',
									keywords: ['Integrations', 'Inbound']
								}
							},
						]
					},
					// Mailbox Management
					{
						path: dic.CONSTANTS.adminPages.inbound.mailboxes,
						component: InboundMailboxesComponent,
						data: {
							title: 'Mailbox Management',
							keywords: ['Mailbox Management', 'Inbound', 'mailboxes', 'Connect']
						}
					},
					// Inbound Shield
					{
						path: dic.CONSTANTS.adminPages.inbound.inboundShield,
						component: InboundShieldTabsComponent,
						data: {
							title: 'Inbound Shield',
							keywords: ['Inbound Shield', 'Inbound', 'rules', 'Detection Mode'],
						},
						children: [
							// Configuration
							{path: '', redirectTo: dic.CONSTANTS.inboundShieldSettingsPageTabs.configuration, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.inboundShieldSettingsPageTabs.configuration,
								component: InboundConfigurationComponent,
								data: {
									title: 'Configuration',
									keywords: ['Configuration', 'Inbound']
								}
							},
							// Threat Prevention Rules
							{
								path: dic.CONSTANTS.inboundShieldSettingsPageTabs.threatPreventionRules,
								component: ThreatPreventionComponent,
								data: {
									title: 'Threat Prevention Rules',
									keywords: ['Threat Prevention Rules', 'Inbound']
								}
							},
							// Inbound Email Rules
							{
								path: dic.CONSTANTS.inboundShieldSettingsPageTabs.inboundEmailRules,
								component: InboundEmailRulesComponent,
								data: {
									title: 'Inbound Email Rules',
									keywords: ['Inbound Email Rules', 'Inbound']
								}
							},
							// Reviewers
							{
								path: dic.CONSTANTS.inboundShieldSettingsPageTabs.reviewers,
								component: ReviewersComponent,
								data: {
									title: 'Reviewers',
									keywords: ['Reviewers', 'Inbound']
								}
							},
							// Scan Threats
							{
								path: dic.CONSTANTS.inboundShieldSettingsPageTabs.scanThreats,
								component: ScanThreatsComponent,
								data: {
									title: 'Scan Threats',
									keywords: ['Scan Threats', 'Inbound']
								}
							},
							// Security Awareness
							{
								path: dic.CONSTANTS.inboundShieldSettingsPageTabs.securityAwareness,
								component: SecurityAwarenessComponent,
								data: {
									title: 'Security Awareness',
									keywords: ['Security Awarness', 'Inbound']
								}
							},
						]
					},
					// Allow/Block Lists
					{
						path: dic.CONSTANTS.adminPages.inbound.configuration,
						component: InboundConfigurationTabsComponent,
						data: {
							title: 'Allow/Block Lists',
							keywords: ['links', 'senders', 'allowlist', 'blocklist', 'Inbound'],
						},
						children: [
							// Block File Types
							{path: '', redirectTo: dic.CONSTANTS.inboundConfigurationPageTabs.blockFileTypes, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.inboundConfigurationPageTabs.blockFileTypes,
								component: InboundBlockFileTypesComponent,
								data: {
									title: 'Block File Types',
									keywords: ['Block File Types','Block', 'File', 'Types', 'Inbound']
								},
							},
							// Sender Lists
							{
								path: dic.CONSTANTS.inboundConfigurationPageTabs.senderLists,
								component: InboundAllowBlockListsComponent,
								data: {
									title: 'Sender Lists',
									keywords: ['Sender Lists', 'Lists', 'Inbound']
								},
							},
							// Link Lists
							{
								path: dic.CONSTANTS.inboundConfigurationPageTabs.linkLists,
								component: InboundAllowBlockListsComponent,
								data: {
									title: 'Link Lists',
									keywords: ['Link Lists', 'Lists', 'Inbound']
								},
							},
							// Attachment Lists
							{
								path: dic.CONSTANTS.inboundConfigurationPageTabs.attachmentLists,
								component: InboundAllowBlockListsComponent,
								data: {
									title: 'Attachment Lists',
									keywords: ['Attachment Lists', 'Lists', 'Inbound']
								},
							},
						]
					},
					// Quarantined Emails
					{
						path: dic.CONSTANTS.adminPages.inbound.quarantined,
						component: InboundQuarantinedComponent,
						data: {
							title: 'Quarantined Emails',
							keywords: ['Quarantined Emails', 'Inbound']
						},
					},
					// URL Hunting
					{
						path: dic.CONSTANTS.adminPages.inbound.urlTracking,
						component: UrlTrackingComponent,
						data: {
							title: 'URL Hunting',
							keywords: ['URL Hunting', 'Inbound']
						},
					},
					// Trends and Insights
					{
						path: dic.CONSTANTS.adminPages.inbound.trends,
						component: InboundTrendsComponent,
						data: {
							title: 'Trends and Insights',
							keywords: ['Trends', 'Insights', 'Trends and Insights', 'Reports']
						},
					},
					// Threat Response
					{
						path: dic.CONSTANTS.adminPages.inbound.threatResponse,
						component: ThreatResponseComponent,
						data: {
							title: 'Threat Response',
							keywords: ['Threat Response', 'Inbound']
						},
					},
					// Discovered Services
					{
						path: dic.CONSTANTS.adminPages.inbound.discoveredServices,
						component: DiscoveredServicesComponent,
						data: {
							title: 'Discovered Services',
							keywords: ['Discovered Services', 'Services']
						},
					},
					// email trace
					{
						path: dic.CONSTANTS.adminPages.inbound.emailTrace,
						component: EmailTraceComponent,
						data: {
							title: 'Email Trace',
							keywords: ['Email Trace', 'Outbound']
						},
					},
					// audit log
					{
						path: dic.CONSTANTS.adminPages.inbound.auditLog,
						component: ActivityLogsComponent,
						data: {
							title: 'Audit Log',
							keywords: ['Audit Log', 'Outbound']
						},
					},
					// Lists Management
					{
						path: dic.CONSTANTS.adminPages.inbound.userInboundConf,
						data: {
							title: 'Lists Management',
							keywords: ['Lists Management', 'Inbound', 'links', 'senders', 'whitelist', 'blacklist', 'Configuration'],
						},
						children: [
							// Sender Lists
							{path: '', redirectTo: dic.CONSTANTS.inboundConfigurationPageTabs.senderLists, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.inboundConfigurationPageTabs.senderLists,
								component: UserInboundConfComponent,
								data: {
									title: 'Sender Lists',
									keywords: ['Sender Lists', 'Lists', 'Inbound']
								}
							},
							// Link Lists
							{
								path: dic.CONSTANTS.inboundConfigurationPageTabs.linkLists,
								component: UserInboundConfComponent,
								data: {
									title: 'Link Lists',
									keywords: ['Link Lists', 'Lists', 'Inbound']
								}
							},
							// Attachment Lists
							{
								path: dic.CONSTANTS.inboundConfigurationPageTabs.attachmentLists,
								component: UserInboundConfComponent,
								data: {
									title: 'Attachment Lists',
									keywords: ['Attachment Lists', 'Lists', 'Inbound']
								}
							},
						]
					},
					{
						path: dic.CONSTANTS.adminPages.inbound.userQuarantinedInboundConf,
						component: UserQuarantinedComponent,
						data: {
							title: 'Quarantined Emails',
							keywords: ['Quarantined Emails', 'Inbound']
						}
					},
				]
			},
			// Threat Simulation
			{
				path: dic.CONSTANTS.appStates.threatSimulation,
				data: {
					isMultiTenantSupported: true,
					title: 'Threat Simulation',
					iconSvgName: 'double-hook.svg',
				},
				children: [
					// Campaigns
					{path: '', redirectTo: dic.CONSTANTS.adminPages.threatSimulation.campaigns, pathMatch: 'full'},
					{
						path: dic.CONSTANTS.adminPages.threatSimulation.campaigns,
						component: ThreatSimulationCampaignsTabsComponent,
						data: {
							title: 'Campaigns',
							keywords: ['threat', 'simulation', 'Campaigns']
						},
						children: [
							// Threat Campaigns
							{path: '', redirectTo: dic.CONSTANTS.threatSimulationCampaignsPageTabs.threatCampaigns, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.threatSimulationCampaignsPageTabs.threatCampaigns,
								component: ThreatSimulationCampaignsComponent,
								data: {
									title: 'Threat Campaigns',
									keywords: ['threat', 'simulation', 'campaigns']
								}
							},
							// Training Campaigns
							{
								path: dic.CONSTANTS.threatSimulationCampaignsPageTabs.trainingCampaigns,
								component: ThreatSimulationCampaignsComponent,
								data: {
									title: 'Training Campaigns',
									keywords: ['threat', 'simulation', 'campaigns', 'training']
								}
							}
						]

					},
					// Configuration
					{
						path: dic.CONSTANTS.adminPages.threatSimulation.configuration,
						component: ThreatSimulationsConfigTabsComponent,
						data: {
							title: 'Configuration',
							keywords: ['threat', 'simulation', 'configuration']
						},
						children: [
							// Configuration (tab)
							{path: '', redirectTo: dic.CONSTANTS.threatSimulationConfigPageTabs.configuration, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.threatSimulationConfigPageTabs.configuration,
								component: ThreatSimulationConfigComponent,
								data: {
									title: 'Configuration',
									keywords: ['threat', 'simulation', 'configuration']
								}
							},
							// Template Fields
							{
								path: dic.CONSTANTS.threatSimulationConfigPageTabs.templateFields,
								component: ThreatSimulationTemplateFieldsComponent,
								data: {
									title: 'Template Fields',
									keywords: ['threat', 'simulation', 'Template Fields']
								}
							},
							// Reviewers
							{
								path: dic.CONSTANTS.threatSimulationConfigPageTabs.reviewers,
								component: ThreatSimulationReviewersComponent,
								data: {
									title: 'Reviewers',
									keywords: ['threat', 'simulation', 'Reviewers']
								}
							},
						]
					},
					// Templates
					{
						path: dic.CONSTANTS.adminPages.threatSimulation.templates,
						component: ThreatSimulationTemplatesComponent,
						data: {
							title: 'Templates',
							keywords: ['threat', 'simulation', 'Templates']
						}
					},
					// Trends and Insights
					{
						path: dic.CONSTANTS.adminPages.threatSimulation.trends,
						component: ThreatSimulationTrendsComponent,
						data: {
							title: 'Trends and Insights',
							keywords: ['threat', 'simulation', 'Trends', 'Insights', 'Trends and Insights', 'statistics']
						}
					},
					// Training Materials
					{
						path: dic.CONSTANTS.adminPages.threatSimulation.trainingMaterials,
						component: ThreatSimulationTrainingMaterialsComponent,
						data: {
							title: 'Training Materials',
							isNewFeature: true,
							keywords: ['threat', 'simulation', 'training', 'materials', 'resources']
						}
					},
					// Audit Log
					{
						path: dic.CONSTANTS.adminPages.threatSimulation.auditLog,
						component: ThreatSimulationAuditLogsComponent,
						data: {
							title: 'Audit Log',
							keywords: ['Audit Log']
						}
					},
				]
			},
			// Domain Protection
			{
				path: dic.CONSTANTS.appStates.domainProtection,
				data: {
					isMultiTenantSupported: true,
					title: 'Domain Protection',
					iconClass: 'fas fa-lock',
				},
				children: [
					// Domain Spoofing Control
					{path: '', redirectTo: dic.CONSTANTS.adminPages.domainProtection.spoofingControl, pathMatch: 'full'},
					{
						path: dic.CONSTANTS.adminPages.domainProtection.spoofingControl,
						component: DomainSpoofingComponent,
						data: {
							title: 'Domain Spoofing Control',
							keywords: ['Domain', 'spoofing', 'control']
						}
					},
					// Domain Impersonation
					{
						path: dic.CONSTANTS.adminPages.domainProtection.impersonation,
						component: DomainImpersonationComponent,
						data: {
							title: 'Domain Metrics',
							keywords: ['Domain', 'impersonation', 'metrics'],
							isBetaFeature: true
						}
					},
					// DMARC Analyzer
					{
						path: dic.CONSTANTS.adminPages.domainProtection.dmarcReports,
						component: DmarcReportsComponent,
						data: {
							title: 'DMARC Analyzer',
							keywords: ['Domain', 'DMARC']
						}
					},
					// Audit Log
					{
						path: dic.CONSTANTS.adminPages.domainProtection.auditLog,
						component: DomainProtectionAuditLogsComponent,
						data: {
							title: 'Audit Log',
							keywords: ['Audit Log']
						}
					},
				]
			},
			// Account Compromised
			{
				path: dic.CONSTANTS.appStates.accountCompromised,
				data: {
					isMultiTenantSupported: true,
					title: 'Account Takeover Protection',
					tag: 'Account Takeover',
					iconClass: 'fa fa-user-secret'
				},
				children: [
					// Configurations
					{
						path: dic.CONSTANTS.accountCompromisedPages.configurations,
						component: accountCompromisedCfgTabsComponent,
						data: {
							title: 'Configurations',
							keywords: ['Configurations', 'Account Takeover']
						},
						children: [
							// Incidents
							{path: '', redirectTo: dic.CONSTANTS.accountCompromisedCfgPageTabs.incidents, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.accountCompromisedCfgPageTabs.incidents,
								component: AccountCompromisedConfigurationsComponent,
								data: {
									title: 'Incidents',
									keywords: ['Incidents']
								}
							},
							// Reviewers
							{
								path: dic.CONSTANTS.accountCompromisedCfgPageTabs.reviewers,
								component: AccountCompromisedReviewerConfigurationsComponent,
								data: {
									title: 'Reviewers',
									keywords: ['Reviewers']
								}
							},
						]
					},
					// Account Management
					{path: '', redirectTo: dic.CONSTANTS.accountCompromisedPages.userManagement, pathMatch: 'full'},
					{
						path: dic.CONSTANTS.accountCompromisedPages.userManagement,
						component: AccountManagementComponent,
						data: {
							title: 'Account Management',
							keywords: ['Management', 'Account Takeover']
						}
					},
					// Incidents and Activity
					{
						path: dic.CONSTANTS.accountCompromisedPages.incidents,
						component: AccountCompromisedIncidentsComponent,
						data: {
							title: 'Incidents and Activity',
							keywords: ['Suspicious', 'Account Takeover', 'Incidents']
						}
					},
					// Trends and Insights
					{
						path: dic.CONSTANTS.accountCompromisedPages.trends,
						component: AccountCompromisedTrendsComponent,
						data: {
							title: 'Trends and Insights',
							keywords: ['Trends', 'Account Takeover']
						}
					},
					// Audit Log
					{
						path: dic.CONSTANTS.accountCompromisedPages.auditLog,
						component: AccountCompromisedAuditLogComponent,
						data: {
							title: 'Audit Log',
							keywords: ['Audit log', 'Account Takeover']
						}
					},
				]
			},
			// Archive
			{
				path: dic.CONSTANTS.appStates.archive,
				data: {
					isMultiTenantSupported: true,
					title: 'Archive',
					iconClass: 'fas fa-archive',
				},
				children: [
					// Configurations
					{path: '', redirectTo: dic.CONSTANTS.archivePages.configurations, pathMatch: 'full'},
					{
						path: dic.CONSTANTS.archivePages.configurations,
						component: ArchiveConfigurationTabsComponent,
						data: {
							title: 'Configurations',
							keywords: ['Configurations', 'Archive', 'Reviewers', 'Migration']
						},
						children: [
							// General
							{path: '', redirectTo: dic.CONSTANTS.archiveConfigurationPageTabs.general, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.archiveConfigurationPageTabs.general,
								component: ArchiveConfigurationsGeneralComponent,
								data: {
									title: 'General',
									keywords: ['Archive', 'Configurations', 'General', 'Journal']
								}
							},
							// Migration
							{
								path: dic.CONSTANTS.archiveConfigurationPageTabs.migration,
								component: ArchiveMigrationComponent,
								data: {
									title: 'Migration',
									keywords: ['Archive', 'Configurations', 'Migration']
								}
							},
						]
					},
					// Archive
					{
						path: dic.CONSTANTS.archivePages.archive,
						component: ArchiveSearchComponent,
						data: {
							title: 'Archive',
							keywords: ['Search', 'Archive']
						}
					},
					// Cases
					{
						path: dic.CONSTANTS.archivePages.cases,
						component: ArchiveCasesComponent,
						data: {
							title: 'Cases',
							keywords: ['Cases', 'Archive']
						}
					},
					// Audit Log
					{
						path: dic.CONSTANTS.archivePages.auditLog,
						component: ArchiveAuditLogComponent,
						data: {
							title: 'Audit Log',
							keywords: ['Audit log', 'Archive']
						}
					},
				]
			},
			// Partners
			{
				path: dic.CONSTANTS.appStates.partner,
				data: {
					isMultiTenantSupported: true,
					title: 'Partners',
					tag: 'Partners',
					iconClass: 'fas fa-handshake',
				},
				children: [
					// Usage
					{path: '', redirectTo: dic.CONSTANTS.partnerPages.usage, pathMatch: 'full'},
					{
						path: dic.CONSTANTS.partnerPages.usage,
						component: PartnerUsageComponent,
						data: {
							title: 'Usage',
							keywords: ['Usage', 'Statistics', 'Partners']
						}
					},
					// Registration
					{
						path: dic.CONSTANTS.partnerPages.registration,
						component: PartnerRegistrationComponent,
						data: {
							title: 'Registration',
							keywords: ['Registration', 'Register', 'New', 'Deal', 'Partners']
						}
					},
					// Resources
					{
						path: dic.CONSTANTS.partnerPages.resources,
						component: PartnerResourcesComponent,
						data: {
							title: 'Resources',
							keywords: ['Resources', 'PDF', 'files', 'Partners']
						}
					},
					// Information
					{
						path: dic.CONSTANTS.partnerPages.information,
						component: PartnerContactInfoComponent,
						data: {
							title: 'Information',
							keywords: ['Information', 'Contact', 'Partners']
						}
					},
				]
			},
			// Personal Pages
			{
				path: dic.CONSTANTS.appStates.personalPages,
				data: {
					isMultiTenantSupported: false,
					title: 'Personal Pages',
					tag: 'Personal Pages',
					iconClass: 'fas fa-user-check',
				},
				children: [
					// New Secure Email
					{path: '', redirectTo: dic.CONSTANTS.personalPages.composeMessage, pathMatch: 'full'},
					{
						path: dic.CONSTANTS.personalPages.composeMessage,
						component: ComposeMessageComponent,
						data: {
							title: 'New Secure Email',
							iconClass: 'fas fa-edit',
							keywords: ['compose', 'email', 'mail', 'message', 'send', 'schedule', 'new mail', 'score']
						}
					},
					// Backup Inbox
					{
						path: dic.CONSTANTS.personalPages.inbox,
						component: InboxComponent,
						data: {
							title: 'Backup Inbox',
							iconClass: 'zmdi zmdi-email',
							keywords:  ['inbox']
						}
					},
					// Sent Emails
					{
						path: dic.CONSTANTS.personalPages.mailbox,
						data: {
							title: 'Sent Emails',
							iconClass: 'zmdi zmdi-email',
							keywords:  ['email', 'mail', 'mailbox', 'message', 'tracking', 'reply', 'block email', 'unblock email', 'tracking', 'advanced', 'summary', 'sent', 'recipient', 'campaign', 'subject', 'print', 'expires', 'details', 'my emails']
						},
						children: [
							{path: '', component: SentEmailsComponent, pathMatch: 'full'},
							{
								path: ':ids',
								component: SentEmailsComponent,
							},
						]
					},
					// Secure Storage
					{
						path: dic.CONSTANTS.personalPages.storage,
						component: AttachmentsComponent,
						data: {
							title: 'Secure Storage',
							iconClass: 'fas fa-folder-open',
							keywords: ['attachments', 'directories', 'folders', 'files', 'new folder', 'upload', 'secure', 'storage', 'my secure storage', 'expires']
						}
					},
					// Contacts
					{
						path: dic.CONSTANTS.personalPages.contacts,
						component: ContactsComponent,
						data: {
							title: 'Contacts',
							iconClass: 'fas fa-users',
							keywords: ['contacts', 'lists', 'address book', 'upload', 'mass', 'csv', 'my contacts']
						}
					},
					// Email Templates
					{
						path: dic.CONSTANTS.personalPages.templates,
						component: TemplatesPageComponent,
						data: {
							title: 'Email Templates',
							iconClass: 'fas fa-envelope-open-text',
							keywords: ['templates', 'my templates']
						}
					},
					// Mass Email Lists
					{
						path: dic.CONSTANTS.personalPages.massList,
						component: MassListPageComponent,
						data: {
							title: 'Mass Email Lists',
							iconClass: 'fas fa-mail-bulk',
							keywords: ['mass lists', 'campaign']
						}
					},
				]
			},
			// Support
			{
				path: dic.CONSTANTS.appStates.knowledge,
				data: {
					isMultiTenantSupported: false,
					showInSidebar: false,
					title: 'Support',
					iconClass: 'fa fa-question-circle',
				},
				children: [
					// Support
					{path: '', redirectTo: dic.CONSTANTS.supportPages.main, pathMatch: 'full'},
					{path: dic.CONSTANTS.supportPages.main,
						component: KnowledgeComponent,
						data: {
							title: 'Support',
							keywords: ['Support', 'Knowledge Base', 'FAQ', 'What\'s new']
						}
					},
					// Knowledge Base
					{
						path: dic.CONSTANTS.supportPages.knowledgeBase,
						data: {
							title: 'Knowledge Base',
							keywords: ['Technical Constraints', 'Sensitivity Engine', 'Support']
						},
						children: [
							{path: '', component: KnowledgeComponent, pathMatch: 'full'},
							{path: ':anchorId', component: KnowledgeComponent},
						]
					},
					// FAQ
					{
						path: dic.CONSTANTS.supportPages.faq,
						data: {
							title: 'FAQ',
							keywords: ['FAQ', 'Frequently Asked Questions', 'Support']
						},
						children: [
							{path: '', component: KnowledgeComponent, pathMatch: 'full'},
							{path: ':anchorId', component: KnowledgeComponent,},
						]
					},
					// What's New
					{
						path: dic.CONSTANTS.supportPages.whatsNew,
						component: KnowledgeComponent,
						data: {
							title: 'What\'s New?',
							keywords: ['What\'s New', 'Release Notes', 'Support']
						}
					},
				]
			},
			// My Account
			{
				path: dic.CONSTANTS.appStates.accountDetails,
				data: {
					isMultiTenantSupported: false,
					title: 'My Account',
					tag: 'Account',
					iconClass: 'fa fa-user',
					showInSidebar: false
				},
				children: [
					// Account Settings
					{path: '', redirectTo: dic.CONSTANTS.accountDetailsPages.myAccount, pathMatch: 'full'},
					{
						path: dic.CONSTANTS.accountDetailsPages.myAccount,
						component: MyAccountTabsComponent,
						data: {
							title: 'Account Settings',
							iconClass: 'fa fa-user',
							keywords: ['Account Settings', 'Integrations', 'Account Addresses', 'security', 'Password', 'Multi-Factor Authentication', 'MFA', 'delete', 'account', 'Settings', 'API Key', ],
						},
						children: [
							// My Profile
							{path: '', redirectTo: dic.CONSTANTS.myProfilePageTabs.myProfile, pathMatch: 'full'},
							{
								path: dic.CONSTANTS.myProfilePageTabs.myProfile,
								component: MyProfileComponent,
								data: {
									title: 'My Profile',
									keywords: ['My Profile', 'Dark mode']
								}
							},
							// Security
							{
								path: dic.CONSTANTS.myProfilePageTabs.security,
								component: AccountSecurityComponent,
								data: {
									title: 'Security',
									keywords: ['Security', 'Password']
								}
							},
							// Account Addresses
							{
								path: dic.CONSTANTS.myProfilePageTabs.accountAddresses,
								component: AccountAddressesComponent,
								data: {
									title: 'Account Addresses',
									keywords: ['Account Addresses']
								}
							},
							// API
							{
								path: dic.CONSTANTS.myProfilePageTabs.api,
								component: AccountApiComponent,
								data: {
									title: 'API',
									keywords: ['API']
								}
							},
							// Integrations
							{
								path: dic.CONSTANTS.myProfilePageTabs.integrations,
								component: AccountIntegrationsComponent,
								data: {
									title: 'Integrations',
									keywords: ['Integrations', 'Clio', 'Dropbox']
								}
							},
						]
					},
					// My Plan
					{
						path: dic.CONSTANTS.accountDetailsPages.myPlan,
						data: {
							title: 'My Plan',
							iconClass: 'fas fa-wallet',
							keywords: ['My Plan', 'Upgrade Plan']
						},
						children: [
							{path: '', component: MyPlanComponent, pathMatch: 'full'},
							{
								path: ':company',
								component: MyPlanComponent,
							},
						]
					},
					// Notifications
					{
						path: dic.CONSTANTS.accountDetailsPages.notifications,
						component: AccountDetailsNotificationsComponent,
						data: {
							title: 'Notifications',
							iconClass: 'fa fa-bell',
							keywords: ['Notifications', 'Alerts']
						}
					},
				]
			},

			{path: 'dummy', component: DummyComponent}
		]
	},
	{path: '**', redirectTo: '/home', pathMatch: 'full'},
];

const routerOptions: ExtraOptions = {
	// enableTracing: true,
	// initialNavigation: 'disabled',
	onSameUrlNavigation: 'reload',
	useHash: false,
	preloadingStrategy: PreloadAllModules
};


@NgModule({
	imports: [RouterModule.forRoot(appRoutes, routerOptions)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
