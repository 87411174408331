<span class="text-center fw-normal" style="font-size: 0.9rem;">Multi Factor Authentication</span>
<br/>

<!--MFA phone-->
<div class="flex-column f-1-0 w-100 justify-content-evenly gap-3 animated fadeIn position-relative"
	 (ngInit)="htmlRetrieved = true"
     *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.phone">
    <div class="flex-column gap-3 align-items-center w-100" style="margin-top: -20px;">
        <span class="fw-bold text-center w-100" style="font-size: 18px;">Enter your phone number</span>
		<span class="text-center text-muted" style="word-break: break-word;" *ngIf="data2Fa.hint">
			(Phone number ends with <b>xxxxxxx{{data2Fa.hint}}</b>)
		</span>
    </div>
    <div class="flex-column gap-3 w-100">
        <div class="flex-row w-100 position-relative">
            <input class="form-control" style="letter-spacing: 1px; background: white; text-align: center; padding: 7px 35px; max-width: 100%; border: 1px solid #ccc; font-size: 14px; min-height: 32px; transition: border 0.2s; margin: 0;"
                   type="tel"
                   (keydown)="data2Fa.message.text = ''"
				   (keydown.enter)="getPinCode(false);"
                   pattern="/^[0-9]*$/"
                   [ngStyle]="{'color' : data2Fa.phone.phone_number && data2Fa.phone.phone_number.length ? 'black' : ''}"
                   [ngClass]="{'validation-error' : data2Fa.message && data2Fa.message.text && data2Fa.message.type === dic.CONSTANTS.msgTypeWarn}"
                   autofocus
                   [focusMe]="{focus: true}"
                   [(ngModel)]="data2Fa.phone.phone_number">
            <div class="flex-row align-items-center gap-1" style="position: absolute; left: 12px; color: dimgrey; top: 50%; transform: translateY(-50%);">
                <img *ngIf="data2Fa.flagIcon" style="width: 22px;" alt="Country code flag"
                     src="images/countryFlags/{{data2Fa.flagIcon}}"
                     [ngClass]="{'ch': data2Fa.flagIcon === 'ch.png'}">
                <span style="font-size: 13px;">+{{data2Fa.country_code}}</span>
            </div>
            <a class="closeRed animated fadeIn" style="position: absolute; right: 0; top: 50%; font-size: 13px; opacity: 0.6; transform: translate(-13px, -47%);"
               *ngIf="data2Fa.phone.phone_number"
               (click)="data2Fa.phone.phone_number = ''">
                <i class="fa fa-times" style="opacity: 0.6;"></i>
            </a>
        </div>
        <btn-c class="w-100"
			 [isDisabled]="disableConfirmAuth || !data2Fa.phone.phone_number"
             [loading]="disableConfirmAuth"
             (action)="getPinCode(false);">
            Confirm
        </btn-c>
    </div>
    <!--choose phone or sms-->
    <div class="flex-row centralize gap-3" *ngIf="data2Fa.isVoiceSupported">
        <div id="smsPIN" class="flex-row centralize" style="color: grey; width: 40%; max-width: 200px;">
            <a class="flex-column align-items-center gap-2 w100 p-1 no-underline"
               [ngStyle]="data2Fa.type === dic.CONSTANTS.pinType.text && {'color': 'var(--bs-bold-color)' , 'text-shadow' : '1px 0 0 var(--bs-bold-color)'}"
               (click)="data2Fa.type = dic.CONSTANTS.pinType.text; data2Fa.resendType = dic.CONSTANTS.pinType.text">
                <i class="far fa-comment-dots" style="font-size: 20px;"></i>
                <span style="text-align: center;">Send PIN code by SMS</span>
            </a>
        </div>
        <span style="text-transform: uppercase; opacity: 0.7;">or</span>
        <div id="callPIN" class="flex-row centralize" style="color: grey; width: 40%; max-width: 200px;">
            <a class="flex-column align-items-center gap-2 w100 p-1 no-underline"
               [ngStyle]="data2Fa.type === dic.CONSTANTS.pinType.voice && {'color': 'var(--bs-bold-color)' , 'text-shadow' : '1px 0 0 var(--bs-bold-color)'}"
               (click)="data2Fa.type = dic.CONSTANTS.pinType.voice; data2Fa.resendType = dic.CONSTANTS.pinType.voice">
                <i class="fas fa-phone-volume" style="font-size: 20px;"></i>
                <span class="w100" style="text-align: center;">Send PIN code by phone call</span>
            </a>
        </div>
    </div>
    <!--description-->
    <div class="flex-column centralize gap-2 mt-4"
         *ngIf="!data2Fa.isVoiceSupported">
        <i class="far fa-comment-dots" style="font-size: 20px;"></i>
        <span style="line-height: 1.4;">A PIN code will be sent to you by SMS</span>
    </div>
    <br/>
    <a class="mx-auto blue-link no-underline hSpace05" (click)="navigateToLogin()">
        <i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
        <span>Change user</span>
    </a>
    <div class="text-danger text-start animated fadeIn w-100" style="position: absolute; bottom: -20px; margin-left: -20px;"
         *ngIf="data2Fa.message && data2Fa.message.text && data2Fa.message.type === dic.CONSTANTS.msgTypeWarn">
        {{data2Fa.message.text}}
    </div>
	<div class="text-success text-start animated fadeIn w-100" style="position: absolute; left: 0; bottom: 0; margin-left: -20px;"
		 *ngIf="data2Fa.message && data2Fa.message.text && data2Fa.message.type === dic.CONSTANTS.msgTypeInfo">
		{{data2Fa.message.text}}
	</div>
</div>

<!--MFA email-->
<div class="flex-column f-1-0 w-100 justify-content-evenly gap-3 animated fadeIn position-relative" *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.email">
    <span class="fw-bold text-center" style="font-size: 18px;">Enter your email address</span>
    <div class="flex-column gap-3 w-100">
        <div class="flex-row w-100 position-relative">
            <input class="form-control" style="letter-spacing: 1px; background: white; text-align: center; padding: 7px 35px; max-width: 100%; border: 1px solid #ccc; font-size: 14px; min-height: 32px; transition: border 0.2s; margin: 0;"
                   type="text"
                   (keydown)="data2Fa.message.text = ''"
				   (keydown.enter)="confirmEmail(false); "
                   placeholder="{{data2Fa.hint}}"
                   [ngClass]="{'validation-error' : ((data2Fa.message && data2Fa.message.text) || data2Fa.invalidEmail) && data2Fa.message.type === dic.CONSTANTS.msgTypeWarn}"
                   autofocus
                   [focusMe]="{focus: true}"
                   [(ngModel)]="data2Fa.emailAuth">
            <a class="closeRed animated fadeIn" style="position: absolute; right: 0; top: 50%; font-size: 13px; opacity: 0.6; transform: translate(-13px, -47%);"
               *ngIf="data2Fa.emailAuth"
               (click)="data2Fa.emailAuth = ''">
                <i class="fa fa-times" style="opacity: 0.6;"></i>
            </a>
        </div>
        <btn-c class="w-100"
             loading="disableConfirmAuth"
			 [isDisabled]="disableConfirmAuth || !data2Fa.emailAuth"
			 (action)="confirmEmail(false);">
            Confirm
        </btn-c>
    </div>
    <!--description-->
    <div class="flex-column gap-2 mt-4" style="align-items: center; color: #748FC3;">
        <div class="flxRow hSpace2" style="font-size: 20px;">
            <i class="fas fa-key"></i>
            <i class="fas fa-arrow-right"></i>
            <i class="far fa-envelope"></i>
        </div>
        <span style="line-height: 1.4;">A PIN code will be sent to your mailbox</span>
    </div>
    <br/>
    <a class="mx-auto blue-link no-underline hSpace05" (click)="navigateToLogin()">
        <i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
        <span>Change user</span>
    </a>
    <div class="text-danger text-start animated fadeIn w-100" style="position: absolute; bottom: -20px; margin-left: -20px;"
         *ngIf="data2Fa.message && data2Fa.message.text && data2Fa.message.type === dic.CONSTANTS.msgTypeWarn">
        {{data2Fa.message.text}}
    </div>
	<div class="text-success text-start animated fadeIn w-100" style="position: absolute; left: 0; bottom: 0; margin-left: -20px;"
		 *ngIf="data2Fa.message && data2Fa.message.text && data2Fa.message.type === dic.CONSTANTS.msgTypeInfo">
		{{data2Fa.message.text}}
	</div>
</div>

<!--MFA ToTp-->
<div class="flex-column f-1-0 w-100 justify-content-evenly gap-3 animated fadeIn position-relative" *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.totp">
    <span class="fw-bold text-center" style="font-size: 18px;">Enter the code from application</span>
    <div class="flex-column align-items-center gap-2 w-100">
        <pin-code style="width: 100%; height: 45px; font-size: 1.3rem;"
                  [(pinCodeModel)]="data2Fa.totpCode"
                  (autoSubmit)="confirmTotp()"
                  [length]="6"
                  [incorrect]="data2Fa.message.text">
        </pin-code>
        <br/>
        <btn-c class="w-100"
             [isDisabled]="!data2Fa.totpCode || data2Fa.totpCode.length < 6 || disableConfirmAuth"
             [loading]="disableConfirmAuth"
             (action)="confirmTotp();">
            Confirm
        </btn-c>
    </div>
    <div class="flex-column gap-2 mt-4" style="align-items: center; color: #748FC3;">
        <!--description-->
        <i style="font-size: 25px;" class="fas fa-key icons"></i>
        <span class="text-center" style="max-width: 60%; line-height: 1.4;">Find the code in your Time-Based One-Time Password application</span>
    </div>
    <br/>
    <a class="mx-auto blue-link no-underline hSpace05" (click)="navigateToLogin()">
        <i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
        <span>Change user</span>
    </a>
    <div class="text-danger text-start animated fadeIn w-100" style="position: absolute; bottom: -20px; margin-left: -20px;"
         *ngIf="data2Fa.message && data2Fa.message.text && data2Fa.message.type === dic.CONSTANTS.msgTypeWarn">
        {{'Invalid code. ' + data2Fa.message.text}}
    </div>
	<div class="text-success text-start animated fadeIn w-100" style="position: absolute; left: 0; bottom: 0; margin-left: -20px;"
		 *ngIf="data2Fa.message && data2Fa.message.text && data2Fa.message.type === dic.CONSTANTS.msgTypeInfo">
		{{data2Fa.message.text}}
	</div>
</div>

<!--PIN code validation (email/phone)-->
<div class="flex-column f-1-0 w-100 justify-content-evenly gap-3 animated fadeIn position-relative"
     (ngInit)="isPhone = data2Fa.method === dic.CONSTANTS.authenticationStatus.phone; isEmail = data2Fa.method === dic.CONSTANTS.authenticationStatus.email;"
     *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.code">
    <div class="flex-column gap-3 align-items-center w-100">
        <span class="fw-bold text-center w-100" style="font-size: 18px;">Enter PIN code to log in</span>
        <span class="text-center text-muted" style="word-break: break-word;" *ngIf="isPhone">
            a PIN code was sent to <b>+{{data2Fa.phone.country_code}} {{data2Fa.phone_number_after}}</b>
        </span>
        <span class="text-center text-muted" style="word-break: break-word;" *ngIf="isEmail">
             a PIN code was sent to <b>{{data2Fa.emailAuth}}</b>
        </span>
    </div>
    <div class="flex-column align-items-center gap-3 w-100">
        <pin-code style="width: 85%; height: 45px; font-size: 1.3rem;"
                  [(pinCodeModel)]="data2Fa.pinCode"
                  (autoSubmit)="confirmPinCode()"
                  [length]="5"
                  [incorrect]="data2Fa.message.text">
        </pin-code>
        <br/>
        <btn-c class="w-100"
             [loading]="disableConfirmAuth"
			 [isDisabled]="disableConfirmAuth || !data2Fa.pinCode || data2Fa.pinCode.length < 5"
			 (action)="confirmPinCode();">
			Validate
        </btn-c>
    </div>
    <div class="flex-column gap-2 w-100 align-items-center">
        <span>Did not receive the code?</span>
        <a *ngIf="isEmail"
           (click)="!disableConfirmAuth && confirmEmail(true)"
           tooltip="{{!enableResend ? 'Please wait 30 seconds before resending the PIN code' : 'Resend code'}}"
           [ngStyle]="!enableResend ? {'cursor': 'not-allowed', 'color': 'gray'} : {'color' : 'rgb(50, 76, 126)'}"
           class="fw-bold no-underline">
            Resend
        </a>
        <div class="flxRow-center hSpace1" *ngIf="isPhone">
            <a (click)="data2Fa.type = dic.CONSTANTS.pinType.text; data2Fa.resendType = dic.CONSTANTS.pinType.text; getPinCode(true)"
               tooltip="{{!enableResend ? 'Please wait 30 seconds before resending the PIN code' : 'Resend code by SMS'}}"
               [ngStyle]="!enableResend ? {'cursor': 'not-allowed', 'color': 'gray'} : {'color' : 'rgb(50, 76, 126)'}"
               class="fw-bold no-underline">
                Resend (SMS)
            </a>
            <span [ngStyle]="!enableResend ? {'color': 'gray'} : {'color' : 'black'}">/</span>
            <a *ngIf="data2Fa.isVoiceSupported"
               (click)="data2Fa.type = dic.CONSTANTS.pinType.voice; data2Fa.resendType = dic.CONSTANTS.pinType.voice; getPinCode(true)"
               tooltip="{{!enableResend ? 'Please wait 30 seconds before resending the PIN code' : 'Resend code by phone call'}}"
               [ngStyle]="!enableResend ? {'cursor': 'not-allowed', 'color': 'gray'} : {'color' : 'rgb(50, 76, 126)'}"
               class="fw-bold no-underline">
                Resend (phone call)
            </a>
        </div>
    </div>
    <br/>
    <a class="mx-auto blue-link no-underline hSpace05" (click)="navigateToLogin()">
        <i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
        <span>Change user</span>
    </a>

    <div class="text-danger text-start animated fadeIn w-100" style="position: absolute; bottom: -20px; margin-left: -20px;"
         *ngIf="data2Fa.message && data2Fa.message.text && data2Fa.message.type === dic.CONSTANTS.msgTypeWarn">
        {{'Invalid PIN code. ' + data2Fa.message.text}}
    </div>
	<div class="text-success text-start animated fadeIn w-100" style="position: absolute; left: 0; bottom: 0; margin-left: -20px;"
		 *ngIf="data2Fa.message && data2Fa.message.text && data2Fa.message.type === dic.CONSTANTS.msgTypeInfo">
		{{data2Fa.message.text}}
	</div>
</div>

<!--PIN code validation (email/phone)-->
<div class="flex-column f-1-0 w-100 centralize gap-3 animated fadeIn position-relative"
     *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.authenticated">
    <div class="flxRow hSpace1" style="align-items: baseline; text-align: center; color: green; opacity: 0.75;">
        <i class="far fa-check-circle" style="font-size: 1.5rem;"></i>
        <h4 style="font-weight: 400; margin: 0;">Authenticated</h4>
    </div>
</div>

<!--account blocked-->
<div class="flex-column justify-content-evenly animated fadeIn" style="align-items: center; flex: 1 0; color: #282828;"
     *ngIf="data2Fa.status === dic.CONSTANTS.authenticationStatus.blocked">
    <i class="fas fa-ban text-danger opacity-25" style="font-size: 6.5rem;"></i>
    <h4 class="text-dark fw-500">Blocked</h4>
    <span class="text-center text-dark" style="font-size: 0.9rem;">{{data2Fa.message.text || ' '}}</span>
    <a class="mx-auto blue-link no-underline" (click)="navigateToLogin()">
        <i class="fa fa-arrow-left" style="font-size: 0.8rem;"></i>
        <span>Back</span>
    </a>
</div>

