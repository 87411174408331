import {Component} from "@angular/core";
import _ from 'lodash';
import {DICTIONARY} from '../../../dictionary';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {GeneralService} from '../../../services/generalService';
import {AccountCompromisedService} from '../../../services/accountCompromisedService';
import {ClipboardService} from "ngx-clipboard";
import {LookAndFeelService} from "../../../services/lookAndFeelService";

@Component({
	selector: 'user-incidents-info-component',
	templateUrl: './user-incidents-info.component.html',
})
export class AccountCompromisedUserIncidentsInfoComponent {

    dic = DICTIONARY;
	incidentInfo;
	_ = _;
	getEmailContentInProcess;
	previewAttachment;
	showMore = {
		to: false,
		cc: false,
		bcc: false
	}

    constructor(
		public accountCompromisedService:AccountCompromisedService,
		private rs:RouteService,
		private clipboard: ClipboardService,
		private ns:NotificationService,
		private lfs:LookAndFeelService,
		private gs:GeneralService) {
    }

    openLocation(ll) {
        this.gs.openLocation(ll);
    }

    selectIncidentAction = (incidentObj, action) => {
        switch (action) {
            case this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display:
                this.accountCompromisedService.getUserIncidentInfo(incidentObj);
                break;

            case this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display:
                this.accountCompromisedService.deleteIncident(incidentObj, () => {
                    _.remove<any>(this.accountCompromisedService.userIncidentsPopup.userIncidentsInfo, (incidentItem:any) => incidentItem._id === incidentObj._id);
                });
                break;
        }
    }

	setStatusConfirm = (incidentObj, newStatus) => {
		const incidentInfo = {
			incidentId: incidentObj._id,
			status: newStatus.toLowerCase(),
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.status.value
		};

		this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
			this.accountCompromisedService.setStatusPopup = null;
			incidentObj.status = incidentInfo.status;
			this.ns.showInfoMessage('Incident updated successfully');

			this.accountCompromisedService.addIncidentAction(newStatus.toLowerCase(), incidentObj);
		});
	}

    showIncidentInfoActions = () => {
        return [
			this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display
		];
    }

    searchIncidents = (searchTerm, activeFilters) => {
		this.accountCompromisedService.userIncidentsPopup.userIncidentsInfo.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = this.searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			// // no filters for this table

			record.hide = false;
		});
    }

    searchTextExecute(incident, searchTerm) {
        searchTerm = searchTerm.toLowerCase();
        return ((incident.incident_type && this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type] &&
                this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name &&
                this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name.toLowerCase().indexOf(searchTerm) > -1));
    }

	getIncidentEmailContent = () => {
		this.getEmailContentInProcess = true;

		const actionInfo = {
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.viewContent.value,
			incidentId: this.accountCompromisedService.incidentInfoPopup.IncidentInfo._id
		};

		this.rs.incidentAction(this.accountCompromisedService.incidentInfoPopup.IncidentInfo.user_email, actionInfo).then(response => {
			this.incidentInfo['emailInfo'] = response;
			this.accountCompromisedService.incidentInfoPopup.emailContent = '<hr/><br/>' + response.content;
			this.getEmailContentInProcess = false;
		}, () => {
			this.getEmailContentInProcess = undefined;
		});
	}

	downloadAttachment = (attachmentObj) => {
		if (attachmentObj.inProcess) return;

		const url = `/admin/compromised/incidents/attachment/${this.accountCompromisedService.incidentInfoPopup.IncidentInfo.user_email}/${this.accountCompromisedService.incidentInfoPopup.IncidentInfo._id}/${attachmentObj._id}`;

		this.gs.downloadFile(url, attachmentObj, false, () => {});
	};
}
