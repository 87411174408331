import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'radio-btn',
  templateUrl: './radio-btn.component.html',
  styleUrls: ['./radio-btn.component.scss']
})
export class RadioBtnComponent {

  random = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 8);

  @Input() model: any;
  @Output() modelChange = new EventEmitter();
  @Input() value: any;
  @Input() isDisabled: boolean;
  @Input() id: string;
}
