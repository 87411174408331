<div class="flxClmn">
	<span>
		Reviewers will receive notifications about quarantined email threats sent by your protected mailboxes and will be able to take immediate actions.<br/>
		Reviewers will have access to the following pages: Quarantined Emails, Rules & Policies, Trends and Insights, Reports, and Audit Log.
	</span>

	<br/>
	<div class="content-container flex-column gap-2" style="z-index: 1; width: 65%;">
		<!--reviewers table-->
		<trustifi-table-c
			class="trustifi-table" style="flex: 0 0 300px; z-index: 5; overflow: auto;"
			[list]="reviewers"
			[headers]="[
				{text:'Reviewer', sortKey:'email'},
				{text:'View Content', sortKey:'allow_view_content', tooltip: 'Allow reviewer to view the email content for quarantined emails'},
			]"
			[cellsPrototype]="[
				{textKey: 'email', edit: {modelKey: 'email', placeholder: 'Reviewer email'}},
				{html: 'viewContentCell'},
			]"
			[bulkActions]="{
				enable: true,
				selectBulkActionCb:	selectMultipleReviewerAction,
				initBulkActionsFunction: showReviewerBulkActions,
				showInRed: {enable: true, terms: ['Remove']}
			}"
			[cellActions] = "{
				enable: true,
				selectItemActionCb:	selectReviewerAction,
				initActionsFunction: showReviewerActions,
				showInRed: {enable: true, terms: ['Remove']}
			}"
			(searchFunction)="searchReviewer($event.searchTerm, $event.activeFilters)"
			(confirmEditFunction)="confirmAddReviewer($event.item, $event.isApproved)"
			[options]="{
				itemsNameSingular: 'reviewer',
				defaultSortKey: '-email',
			}"
		>
			<ng-template #customCells let-item let-cellId="cellId">
				<!--reviewer cells-->

				<div *ngIf="cellId === 'viewContentCell'">
					<span [ngStyle]="{color: item.allow_view_content || item.isNew ? 'green' : 'red'}">{{item.allow_view_content || item.isNew ? 'Enabled' : 'Disabled'}}</span>
				</div>
			</ng-template>
			<div tableButtons>
				<div class="btn-with-dropdown">
					<btn-c (action)="startAddReviewer();"
						   endIcon="fa fa-plus"
						   [isDisabled]="addReviewerInProcess">
						Add Reviewer
					</btn-c>
					<app-dropdown-c [isLf]="true">
						<li style="text-transform: capitalize; height: auto;"
							(click)="openUsersSelection()">
							Add from my users
						</li>
					</app-dropdown-c>
				</div>
			</div>
		</trustifi-table-c>
	</div>
</div>

<users-selection-component
	*ngIf="usersSelectionPopup?.show"
	[data]="usersSelectionPopup"
	[selectedUsers]="selectedUsers"
	(addCb)="startAddFromUsersSelection($event.users)"
	(removeCb)="startRemoveFromUsersSelection($event.users)">
</users-selection-component>

<popup-c *ngIf="reviewerPermissionPopup?.show"
		 head="Modify Permissions"
		 class="bs-enabled"
		 subTitle="Configure permissions for {{reviewerPermissionPopup.reviewerObj.email}}"
		 (closeCb)="reviewerPermissionPopup = null;"
		 size="dynamic">

	<div class="flxClmn gap-4">
		<div class="flxRow">
			<div class="flxClmn gap-1" style="width: 450px;">
				<b>View Content</b>
				<span>Allow reviewer to view the email content for quarantined emails</span>
			</div>
			<div class="content-container flex-column gap-3">
				<toggle-switch-c id="isOutboundReviewer"
								 [hideText]="true"
								 [(model)]="reviewerPermissionPopup.reviewerObj.allow_view_content"
								 (onChange)="reviewerViewContentChangeExecute()"></toggle-switch-c>
			</div>
		</div>

	</div>
</popup-c>

