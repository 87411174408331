import {Component, OnInit} from '@angular/core';
import util from "util";
import {RouteService} from "../../../../services/routeService";
import {GeneralService} from "../../../../services/generalService";
import {NotificationService} from "../../../../services/notificationService";
import _ from "lodash";
import {DICTIONARY} from "../../../../dictionary";

@Component({
  selector: 'threat-simulation-reviewers-component',
  templateUrl: './threat-simulation-reviewers.component.html',
})
export class ThreatSimulationReviewersComponent implements OnInit {

	constructor(private rs: RouteService,
				public gs: GeneralService,
				private ns:NotificationService,) {}

	dic = DICTIONARY;
	_ = _;


	isLoaded;
	isThisFeatureSupported;
	addReviewerInProcess;
	reviewers;
	moreActions: boolean = false;
	usersSelectionPopup:any;
	selectedUsers: any[];

	ngOnInit() {
		this.rs.getThreatSimulationConfiguration().then((response) => {
			this.isThisFeatureSupported = true;
			this.isLoaded = true;
			this.reviewers = response.threat_simulation?.reviewers;
		}, (err) => {
			this.isLoaded = true;
			if (err?.data?.message && err.data.message.includes('This feature is not supported')) {
				this.isThisFeatureSupported = false;
			}
		});
	}

	selectReviewerAction = (reviewerObj, action) => {
		reviewerObj.showActions = false;
		switch (action) {
			case this.dic.CONSTANTS.actions.delete:
				this.removeReviewers([reviewerObj]);
				break;
		}
	};

	showReviewerActions = (reviewerObj) => {
		return [this.dic.CONSTANTS.actions.delete];
	}

	selectMultipleReviewerAction = (selectedItems, action) => {
		switch (action) {
			case this.dic.CONSTANTS.actions.delete:
				this.removeReviewers(selectedItems);
				break;
		}
	};

	showReviewerBulkActions = () => {
		return [
			this.dic.CONSTANTS.actions.delete
		]
	}

	openUsersSelection = () => {
		this.selectedUsers = _.map(this.reviewers, 'email');
		this.usersSelectionPopup = {
			show: true
		};
	}

	searchReviewer = (searchTerm, activeFilters) => {
		this.reviewers.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	};

	startAddFromUsersSelection = (users) => {
		// Step 1: Filter out existing reviewers
		const existingReviewerEmails = this.reviewers.map(reviewer => reviewer.email);
		const filteredUsers = users.filter(user => !existingReviewerEmails.includes(user));

		// Step 2: Map filtered users to new reviewers structure
		const newReviewers = filteredUsers.map(user => ({
			email: user,
			isEditMode: false,
			isNew: true,
			edit: {
				email: user,
				allow_view_content: true
			}
		}));

		// Step 3: Add new reviewers if any
		if (newReviewers.length > 0) {
			newReviewers.forEach(user => {
				this.rs.addThreatSimulationReviewer(user.email).then(res => {
					this.reviewers.push({email: user.email, allow_view_content: true});
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, user.email));
				});
			});
		}
	}

	startRemoveFromUsersSelection = (users) => {
		const reviewersToRemove = this.reviewers.filter(reviewer => users.includes(reviewer.email));

		if (reviewersToRemove.length > 0) {
			this.removeReviewers(reviewersToRemove);
		}
	}

	startAddReviewer =  () => {
		if (this.addReviewerInProcess) {
			return;
		}

		this.addReviewerInProcess = true;

		const newReviewer = {
			isEditMode: true,
			isNew: true,
			edit: {
				email: ''
			}
		};

		this.reviewers.unshift(newReviewer);
	};

	confirmAddReviewer =  (reviewer ,isApproved) => {
		// new reviewer process was declined
		if (!isApproved) {
			_.remove<any>(this.reviewers, reviewer);
			this.addReviewerInProcess = false;
			return;
		}
		//

		// new reviewer approved
		if (!reviewer.edit.email) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Reviewer email field'));
		}
		if (!this.gs.validateEmail(reviewer.edit.email)) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.invalidEmail, reviewer.edit.email));
		}
		if (_.find(this.reviewers, rev => {return !rev.isNew && rev.email && rev.email === reviewer.edit.email})) {
			return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailAlreadyExist, reviewer.edit.email));
		}
		reviewer.confirmEditInProcess = true;
		reviewer.email = reviewer.edit.email;

		const emailFormatted = reviewer.edit.email.toLowerCase()
		this.rs.addThreatSimulationReviewer(emailFormatted).then(res => {
			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Reviewer'));

			reviewer.email = emailFormatted;
			reviewer.edit = null;
			reviewer.confirmEditInProcess = false;
			reviewer.isEditMode = false;
			reviewer.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit

			this.addReviewerInProcess = false;
		}, (err) => {
			reviewer.confirmEditInProcess = false;
			this.addReviewerInProcess = false;
		});
	};

	removeReviewers = (reviewers) => {
		if (!reviewers || !reviewers.length) {
			return;
		}

		let title, subTitle, body;
		if (reviewers.length === 1) {
			body = ['User will not have permission to review Threat Simulation campaigns.'];
			title = 'Remove reviewing permission';
			subTitle = `Remove ${reviewers[0].email} permission as a reviewer from Threat Simulation`;
		}
		else {
			body = [`Review permission of ${reviewers.length} users will be removed from this case.`];
			title = `Remove reviewing permission`;
			subTitle = `Remove reviewing permission - ${reviewers.length} users`;
		}

		this.gs.showPopup({
			title: title,
			subTitle: subTitle,
			body: body,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Remove',
			doneCb: () => {
				reviewers.forEach((reviewerObj) => {
					this.rs.deleteThreatSimulationReviewer(reviewerObj.email).then(res => {
						_.remove<any>(this.reviewers, itm => itm.email === reviewerObj.email);

						this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Reviewer', reviewerObj.email));
					});
				});
			}
		});
	};

}

function searchTextExecute(reviewer, searchTerm) {
	return (reviewer.email?.toLowerCase().indexOf(searchTerm) > -1);
}
