import * as util from 'util';
import html2pdf from 'html2pdf.js';
import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, Input, OnInit} from "@angular/core";
import {myEmailsService} from "../../../services/myEmailsService";

@Component({
	selector: 'sent-email-tracking-component',
	templateUrl: './sent-email-tracking.component.html',
})
export class SentEmailTrackingComponent implements OnInit {
	constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService,
				public myEmailsService:myEmailsService){
	}

	@Input() origin: string;

	dic = DICTIONARY;
	getTrackingDataInProcess = false;
	recipientTrack;
	allowPolicyRecall;
	changeMessageInProcess;
	trackEventShow;
	recipientContactInfoPopup;
	country_code = this.gs.country_code;

	ngOnInit() {
		this.getTrackingData(this.myEmailsService.currentMessage, () => {});

		this.gs.getUserInfo(false,  (userInfo) => {
			this.allowPolicyRecall = userInfo.policy?.allow_recall_message?.value;
		});
	};

	getTrackingData(currentMessage, cb) {
		this.getTrackingDataInProcess = true;

		this.rs.getEmailEvents(currentMessage._id, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then((response) => {
			currentMessage.trackingData = response.tracking;
			currentMessage.emailInfo = response.email;
			this.getTrackingDataInProcess = false;
			if (currentMessage.trackingData.length) {
				this.changeTrackingRecipient(currentMessage.trackingData[0]);
			}
			cb();
		}, (err) => {
			this.getTrackingDataInProcess = false;
		});
	};


	doEmailAction(action) {
		switch (action) {
			case 'exportTrackingPdf':
				this.exportTrackingData(this.myEmailsService.currentMessage);
				break;

			case 'unblockRecipient':
			case 'blockRecipient':
				this.changeMessageForRecipient(this.myEmailsService.currentMessage);
				break;

			default:
				this.myEmailsService.doEmailAction(action);
		}
	}

	changeMessageForRecipient = (currentMessage) => {
		let actionButton = "";
		let title = "";
		let subtitle = "";
		if (this.recipientTrack.is_blocked || this.recipientTrack.is_access_blocked) {
			title = "Unblock Recipient";
			subtitle = "Please note - The following operations will be available for the recipient:";
			actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.unblock);
		}
		else {
			title = "Block Recipient";
			subtitle = "Please note - The following operations will be blocked for the recipient:";
			actionButton = this.gs.toCapitalize(this.dic.CONSTANTS.recipientStatus.block);
		}

		const body = emailOperations(currentMessage.emailInfo);

		this.gs.showPopup({
			title: title,
			subTitle: subtitle,
			body: body,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: actionButton,
			doneCb: () => {
				this.changeMessageForRecipientExecute(currentMessage);
			}
		});
	};

	changeMessageForRecipientExecute = (currentMessage) => {
		if (this.changeMessageInProcess) return;
		this.changeMessageInProcess = true;

		let action = this.recipientTrack.is_blocked || this.recipientTrack.is_access_blocked ? this.dic.CONSTANTS.recipientStatus.unblock : this.dic.CONSTANTS.recipientStatus.block;
		let prevRecipient = this.recipientTrack;

		this.rs.changeMessageForRecipient(currentMessage._id, this.recipientTrack.id, {action: action}, this.myEmailsService.getRouteParams(this.myEmailsService.currentMessage)).then( (response) => {
			if (action === this.dic.CONSTANTS.recipientStatus.block) {
				if (!this.recipientTrack.is_blocked) {
					currentMessage.numberRecipientsBlocked++;
				}
				this.recipientTrack.is_blocked = true;
			}
			else {
				this.recipientTrack.is_access_blocked = false;
				this.recipientTrack.is_blocked = false;
				if (currentMessage.numberRecipientsBlocked > 0) {
					currentMessage.numberRecipientsBlocked--;
				}
			}

			this.getTrackingData(currentMessage,  () => {
				this.recipientTrack = _.find(currentMessage.trackingData, track => prevRecipient.id === track.id);
				this.changeMessageInProcess = false;
			});
		});
	};

	changeTrackingRecipient = (newRecipient) => {
		this.recipientTrack = newRecipient;
		this.trackEventShow = -2;
	};

	openRecipientContactInfoPopup = (recipient) => {
		this.rs.getContactByEmail(recipient.email).then((response) => {
			this.recipientContactInfoPopup = {
				contactInfo: {
					email: response.email || '',
					phone: _.clone(response.phone) || {country_code: '', phone_number: ''},
					name: response.name || '',
					default_password: _.clone(response.default_password) ||  {password: '', hint: ''}
				},
				show: true
			}

		}, (err) => {
			this.recipientContactInfoPopup = {
				contactInfo: {
					email: recipient.email,
					phone: {country_code: '', phone_number: ''},
					name: '',
					default_password: {
						password: '',
						hint: ''
					}
				},
				show: true
			}
		});
	};

	updateRecipientDetails = () => {
		if (this.recipientContactInfoPopup.contactInfo._id) {
			this.rs.updateContact(this.recipientContactInfoPopup.contactInfo._id, this.recipientContactInfoPopup.contactInfo).then((response) => {
				this.recipientContactInfoPopup = null;
				this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
			});
		}
		else {
			this.rs.addNewContact({contacts: [this.recipientContactInfoPopup.contactInfo]}).then((response) => {
				this.recipientContactInfoPopup = null;
				this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
			});
		}
	};

	exportTrackingData = (currentMessage) => {
		if (!currentMessage.trackingData) return;
		let pdfString = "<div><b style='text-decoration: underline; font-size: 20px;'>Tracking Data for email: " + (currentMessage.sent.title || 'No Subject') + "</b></div><br/>";

		currentMessage.trackingData.forEach((recipientObj) => {
			pdfString += `<div><div><b>Recipient:</b> ${recipientObj.email}</div>`;
			recipientObj.track_events.forEach((trackEvent) => {
				let createdData = new Date(trackEvent.created);
				pdfString += `<div><div><b style="font-size: 16px;">&centerdot;</b> <b>${trackEvent.name}:</b> (${createdData ? createdData.toString() : 'N/A'})</div>`;
				trackEvent.info.forEach(e => {
					pdfString += `<div>${e.msg}</div>`;
				});
				pdfString += `</div><br/>`;
			})
			pdfString += `<hr/></div>`;
		});

		let element:any = document.createElement('div');
		element.innerHTML = pdfString;

		let options = {
			margin: [30,30],
			filename: `Tracking_data_${currentMessage.sent.title || 'No Subject'}.pdf`,
			enableLinks: false,
			image:        { type: 'jpeg', quality: 0.98 },
			html2canvas:  { scale: 2, letterRendering: true },
			jsPDF:        { unit: 'pt', format: 'letter', orientation: 'portrait' },
			pagebreak:    { mode: ['avoid-all', 'legacy'] }
		};

		html2pdf().from(element).set(options).save();
		this.ns.showInfoMessage(util.format(this.dic.MESSAGES.downloadSuccess, 'Tracking data'));
	};

}

function emailOperations(email) {
	let actions = [];
	if (email.methods.secure_reply) {
		actions.push('Encrypted reply');
	}
	if (email.sent.attachments && email.sent.attachments.length && !email.skip_attachments_encryption) {
		actions.push('Download attachments');
	}
	if (email.methods.encrypt_content) {
		actions.push('Read email content');
	}
	return actions;
}

