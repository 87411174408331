import L from 'leaflet';
import _ from "lodash";
import {DICTIONARY} from '../../../dictionary';
import {GeneralService} from '../../../services/generalService';
import {AccountCompromisedService} from '../../../services/accountCompromisedService';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import util from "util";
import html2pdf from 'html2pdf.js';
import {Component, NgZone, OnInit} from "@angular/core";
import {Router} from "@angular/router";

@Component({
	selector: 'account-compromised-trends-component',
	templateUrl: './account-compromised-trends.component.html',
})
export class AccountCompromisedTrendsComponent implements OnInit {
    dic = DICTIONARY;
    dashboardPeriod = [
        this.dic.CONSTANTS.trendsPeriod.lastDay,
        this.dic.CONSTANTS.trendsPeriod.last3Days,
        this.dic.CONSTANTS.trendsPeriod.lastWeek,
        this.dic.CONSTANTS.trendsPeriod.lastMonth,
        this.dic.CONSTANTS.trendsPeriod.range
    ];

    incidentActions = [
        this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display,
        this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display
    ];

    period;
    deviceListChartOptions: any;
    sensitivityDomainsChartOptions: any;
    incidentsPerMailboxTopChartOptions: any;
    incidentsTypesChartOptions: any;
	range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()};
    getTrendsDataInProcess = false;
    users: any;
	activeSpecificUsers: any;
    trendsData: any;
    incidentsPopup;
    usersFilterTypes = {all: 'all', specific: 'specific'};
    usersPopup;
    locations;
    generatePdfInProcess: boolean;
    layers;
	isUserAllowedToViewEmailContent;
	incidentsDisplayedCounter = 0;
    devicesViewType;
    devicesOrderBy;
    devicesGraphViewBeforePdf;
    showIncidentTypesMenu = false;
    showIncidentTypesTips = false;
    showTopMailboxesMenu = false;
    showTopMailboxesTips = false;
    showTopDomainsMenu = false;
    showTopDomainsTips = false;
    todayDate = new Date();
	showTrendsCustomPeriod: boolean;
	getDataInProcess;
	leafletCenter = {
		all: {lat: 0, lng: 0, zoom: 2},
		last: {lat: 0, lng: 0, zoom: 2}
	};

    sortCount: string;
    constructor(private router:Router,
				public gs:GeneralService,
				private rs:RouteService,
				private ngZone:NgZone,
				private accountCompromisedService:AccountCompromisedService,
				private ns:NotificationService) {
    }

	ngOnInit() {
      L.TileLayer.WebGLHeatMap = L.WebGLHeatMap;

	//this is copy from the leaflet-webgl-heatmap/src/leaflet-webgl-heatmap.js
	//the difference is in the last line. The default value is "1" which makes point too small for a large scale map
      L.WebGLHeatMap.prototype._scalem = function(latlng) {
        let map = this._map,
          lngRadius = (this.size / 40075017) * 360 / Math.cos((Math.PI / 180) * latlng.lat),
          latlng2 = new L.LatLng(latlng.lat, latlng.lng - lngRadius),
          point = map.latLngToLayerPoint(latlng),
          point2 = map.latLngToLayerPoint(latlng2);

        return Math.max(Math.round(point.x - point2.x), 40);
      };

        this.devicesViewType = 'chart';
        this.devicesOrderBy = '-count';
        this.sortCount='-count';
        this.showIncidentTypesMenu = false;
        this.showIncidentTypesTips = false;
        this.showTopMailboxesMenu = false;
        this.showTopMailboxesTips = false;
        this.showTopDomainsMenu = false;
        this.showTopDomainsTips = false;

        this.period = this.dic.CONSTANTS.trendsPeriod.lastMonth;

        this.deviceListChartOptions = null;
        this.sensitivityDomainsChartOptions = null;
        this.incidentsPerMailboxTopChartOptions = null;
        this.incidentsTypesChartOptions = null;

        this.getTrendsData(null);
        this.getAccountCompromisedUsers();
    }

    getAccountCompromisedUsers = () => {
        this.rs.getAccountCompromisedUsers('').then(response => {
            this.users = response;
        }, (err) => {
        });
    }

    openUsersFilterPopup = () => {
		this.usersPopup = {
			type: this.activeSpecificUsers ? this.usersFilterTypes.specific : this.usersFilterTypes.all,
			users: this.activeSpecificUsers ? _.unionBy(this.activeSpecificUsers, _.cloneDeep(this.users),'email') : _.cloneDeep(this.users),
			show: true
		}
    }

    applyUsersFilter = () => {
		if (this.usersPopup.type === this.usersFilterTypes.specific && !_.find(this.usersPopup.users, 'selected')) {
			this.ns.showErrorMessage('You must select at least one user');
			return;
		}

		if (this.usersPopup.type === this.usersFilterTypes.specific) {
			this.activeSpecificUsers = _.filter(this.usersPopup.users, 'selected');
		}
		else {
			this.activeSpecificUsers = null;
		}

        this.usersPopup = null;

        if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
            this.getTrendsByRangeDates();
        }
        else {
            this.getTrendsData(null);
        }
    }

    searchUser = (searchTerm) => {
        searchTerm = searchTerm.toLowerCase();
        this.usersPopup.users.forEach(record => {
            if (searchTerm) {
                const isFound = (record.email.toLowerCase().indexOf(searchTerm) > -1);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    }

    changePeriod(period) {
        this.period = period;
        if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
            return;
        }

        this.getTrendsData(null);
    }

    getTrendsByRangeDates = () => {
        if (this.period.value !== this.dic.CONSTANTS.trendsPeriod.range.value) {
            return;
        }
        let range = {
            start: this.range.start.toISOString(),
            end: this.range.end.toISOString()
        };

        if (this.range.start.getTime() > Date.now() || this.range.start > this.range.end) {
            this.ns.showWarnMessage(this.dic.ERRORS.adminTrendsDate);
            return;
        }
        this.getTrendsData(range);
    }

    getTrendsData(range) {
        this.getTrendsDataInProcess = true;

        const params: any = {
            period: this.period.value,
            range: range,
			users: this.activeSpecificUsers && _.map(this.activeSpecificUsers, 'email')
        };

		this.gs.cleanEmptyParams(params);

        this.rs.getAccountCompromisedTrends(params).then(response => {
            this.trendsData = response;
			this.isUserAllowedToViewEmailContent = response.allowViewContent;

			this.prepareIncidentsTypesGraph();

            this.prepareMailboxIncidentsGraph();

            this.prepareSensitiveEmailsGraph();

            this.prepareDevicesGraph();

            this.prepareUsersLocationInfo(this.trendsData.locations);

            this.getTrendsDataInProcess = false;
        }, (err)=> {
            this.getTrendsDataInProcess = false;
        });
    }

    prepareIncidentsTypesGraph = () => {
        const sortedIncidentTypes = sortItems(this.trendsData.incidentsTypes);
        this.trendsData.incidentsTypesGraph = {data: [], labels: []};
        sortedIncidentTypes.forEach((type) => {
            this.trendsData.incidentsTypesGraph.data.push(this.trendsData.incidentsTypes[type]);
            this.trendsData.incidentsTypesGraph.labels.push(this.dic.CONSTANTS.accountCompromisedIncidentTypes[type].name);
        });

        this.incidentsTypesChartOptions = null;
        if (this.trendsData.incidentsTypesGraph && this.trendsData.incidentsTypesGraph.data && this.trendsData.incidentsTypesGraph.data.length ) {
            this.incidentsTypesChartOptions = {
                series: [{
                    name: 'Incidents',
                    data: this.trendsData.incidentsTypesGraph.data
                }],
                chart: {
                    type: 'bar',
                    height: '100%',
                    toolbar: {
                        show: false
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            if (!event || !config) {
                                return;
                            }

                            const sortedIncidentTypes = sortItems(this.trendsData.incidentsTypes);
                            const incidentType = sortedIncidentTypes[config.dataPointIndex];
                            if (!incidentType) {
                                return;
                            }

                            const params = {period: this.period.value, range: null};
                            if (this.period === this.dic.CONSTANTS.trendsPeriod.range) {
                                params.range = {
                                    start: this.range.start,
                                    end: this.range.end
                                };
                            }
                            this.rs.getAccountCompromisedIncidentsByType(incidentType, params).then(response => {
								this.ngZone.run(() => {
									this.incidentsPopup = {
										incidents: response.incidents,
										type: incidentType,
										show: true
									}
								});
                            });
                        }
                    }
                },
                colors: ['#a54343'],
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                        barHeight: this.gs.getOptimalBarWidth(this.trendsData.incidentsTypesGraph.labels.length) + '%',
                    }
                },
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                },
                xaxis: {
                    categories: this.trendsData.incidentsTypesGraph.labels,
                }
            };
        }
    }

    prepareSensitiveEmailsGraph = () => {
        // sensitivity domain incidents
        const sortedSensitivityDomains = sortItems(this.trendsData.sensitivityDomains);
        this.trendsData.sensitivityDomainsGraph = {data: [], labels: []};
        sortedSensitivityDomains.forEach((type) => {
            this.trendsData.sensitivityDomainsGraph.data.push(this.trendsData.sensitivityDomains[type]);
            this.trendsData.sensitivityDomainsGraph.labels.push(type);
        });

        // Sensitive Emails Per Domain (3rd box) (bar)
        this.sensitivityDomainsChartOptions = null;
        if (this.trendsData.sensitivityDomainsGraph && this.trendsData.sensitivityDomainsGraph.data && this.trendsData.sensitivityDomainsGraph.data.length ) {
            this.sensitivityDomainsChartOptions = {
                series: [{
                    name: 'Incidents',
                    data: this.trendsData.sensitivityDomainsGraph.data
                }],
                chart: {
                    type: 'bar',
                    height: '100%',
                    toolbar: {
                        show: false
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            if (!event || !config) {
                                return;
                            }

                            const domain = this.trendsData.sensitivityDomainsGraph.labels[config.dataPointIndex];

                            if (!domain) {
                                return;
                            }

                            const params = {domain: domain, period: this.period.value, range: null};
                            if (this.period === this.dic.CONSTANTS.trendsPeriod.range) {
                                params.range = {
                                    start: this.range.start,
                                    end: this.range.end
                                };
                            }
                            this.rs.getAccountCompromisedIncidentsByType('sending_sensitive_to_new_domains', params).then(response => {
								this.ngZone.run(() => {
									this.incidentsPopup = {
										incidents: response.incidents,
										type: domain,
										show: true
									}
								});
                            });
                        }
                    }
                },
                colors: ['#a54343'],
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                        barHeight: this.gs.getOptimalBarWidth(this.trendsData.sensitivityDomainsGraph.labels.length) + '%',
                    }
                },
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                },
                xaxis: {
                    categories: this.trendsData.sensitivityDomainsGraph.labels,
                }
            };
        }

    }

    prepareMailboxIncidentsGraph = () => {
        // incidents per mailbox
        let sortedIncidentsPerMailbox = sortItems(this.trendsData.incidentsPerMailbox);
        this.trendsData.incidentsPerMailboxGraph = {data: [], labels: []};
        sortedIncidentsPerMailbox.forEach((type) => {
            this.trendsData.incidentsPerMailboxGraph.data.push(this.trendsData.incidentsPerMailbox[type]);
            this.trendsData.incidentsPerMailboxGraph.labels.push(type);
        });

        this.incidentsPerMailboxTopChartOptions = null;
        if (this.trendsData.incidentsPerMailboxGraph && this.trendsData.incidentsPerMailboxGraph.data && this.trendsData.incidentsPerMailboxGraph.data.length) {
            this.incidentsPerMailboxTopChartOptions = {
                series: [{
                    name: 'Incidents',
                    data: this.trendsData.incidentsPerMailboxGraph.data.slice(0, 10)
                }],
                chart: {
                    type: 'bar',
                    height: '100%',
                    toolbar: {
                        show: false
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            if (!event || !config) {
                                return;
                            }
                            const userEmail = this.trendsData.incidentsPerMailboxGraph.labels[config.dataPointIndex];
                            if (!userEmail) {
                                return;
                            }

							const sortedIncidentTypes = sortItems(this.trendsData.incidentsTypes);
							const incidentType = sortedIncidentTypes[config.dataPointIndex];
							if (!incidentType) {
								return;
							}

                            const params = {period: this.period.value, range: null};
                            if (this.period === this.dic.CONSTANTS.trendsPeriod.range) {
                                params.range = {
                                    start: this.range.start,
                                    end: this.range.end
                                };
                            }
                            this.rs.getAccountCompromisedIncidents(userEmail, params).then(response => {
								this.ngZone.run(() => {
									this.incidentsPopup = {
										incidents: response.incidents,
										type: incidentType,
										show: true
									}
								});
                            });
                        }
                    }
                },
                colors: ['#a54343'],
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                        barHeight: this.gs.getOptimalBarWidth(Math.min(this.trendsData.incidentsPerMailboxGraph.labels.length, 10)) - 10 + '%',
                    }
                },
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                },
                xaxis: {
                    categories: this.trendsData.incidentsPerMailboxGraph.labels.slice(0, 10),
                }
            };
        }

    }

    prepareDevicesGraph = () => {
        this.trendsData.allDevices = this.trendsData.allDevices.filter(deviceObj => {
			if (deviceObj.agent.raw && typeof deviceObj.agent.raw === 'string') {
				deviceObj.agent.edittedRaw = JSON.parse(deviceObj.agent.raw);
			}

			return deviceObj.agent && (deviceObj.agent.browser || (deviceObj.agent.edittedRaw && deviceObj.agent.edittedRaw.ua));
		});

        if (this.trendsData.allDevices && this.trendsData.allDevices.length) {
            const data = _.map(this.trendsData.allDevices,'count');
            const labels = _.map(this.trendsData.allDevices, deviceObj => {
                if (deviceObj.agent && deviceObj.agent.browser) {
                    let deviceName = deviceObj.agent.browser.name;
                    if (deviceObj.agent.os) {
                        deviceName += `, ${deviceObj.agent.os.name} ${deviceObj.agent.os.version}`;
                    }
                    return deviceName;
                }
                else if (deviceObj.agent && deviceObj.agent.edittedRaw && deviceObj.agent.edittedRaw.ua) {
                    return deviceObj.agent.edittedRaw.ua;
                }
                else {
                    return 'Not recognized';
                }
            });

            this.deviceListChartOptions = {
                series: data,
                chart: {
                    type: 'donut',
                    height: '100%'
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '52%',
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val, opt) => opt.w.globals.series[opt.seriesIndex]
                },
                labels: labels,
                tooltip: {
                    y: {formatter: (val) => val + ' users'}
                }
            }
        }
    }

    prepareUsersLocationInfo(locationsObj) {
        const lastType = this.locations ? this.locations.type : 'last';
        this.locations = {type: lastType, all: [], last: []};
		let boundsLast = [];
		let boundsAll = [];
        let maxCount = 0;
        if (locationsObj.allLastLocations.length) {
            locationsObj.allLastLocations.forEach((locationObj) => {
                if (locationObj.location.ll.length === 2) {
                    const marker = {
                        title: locationObj.email,
                        icon: this.gs.leafletDefaultIcon,
                        lat: locationObj.location.ll[0],
                        lng: locationObj.location.ll[1],
                        count: locationObj.count,
                        draggable: false
                    };

                    if (marker.count > maxCount) {
                        maxCount = marker.count;
                    }
					boundsLast.push([locationObj.location.ll[0],locationObj.location.ll[1]]);

                    this.locations.last.push(marker);
                }
            });

			const centroidLast = boundsLast.length > 0 ? new L.LatLngBounds(boundsLast).getCenter() : { lat: 0, lng: 0 };


			locationsObj.allLocations.forEach((locationObj) => {
                if (locationObj.location.ll.length === 2) {
                    const marker = {
                        title: locationObj.email,
                        icon: this.gs.leafletDefaultIcon,
                        lat: locationObj.location.ll[0],
                        lng: locationObj.location.ll[1],
                        count: locationObj.count,
                        draggable: false
                    };
					boundsAll.push([locationObj.location.ll[0],locationObj.location.ll[1]]);
					this.locations.all.push(marker);
                }
            });
			const centroidAll = boundsAll.length > 0 ? new L.LatLngBounds(boundsAll).getCenter() : { lat: 0, lng: 0 };

			this.leafletCenter.all = {
				lat: centroidAll.lat,
				lng: centroidAll.lng,
				zoom: 2
			};
			this.leafletCenter.last = {
				lat: centroidLast.lat,
				lng: centroidLast.lng,
				zoom: 2
			};
		}

        const baseLayers = {
            osm: {
                name: 'OpenStreetMap',
                //http://otile{s}.mqcdn.com/tiles/1.0.0/map/{z}/{x}/{y}.jpg
                url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                type: 'xyz'
            },
            /*googleTerrain: {
                name: 'Google Terrain',
                layerType: 'TERRAIN',
                type: 'google'
            },
            googleHybrid: {
                name: 'Google Hybrid',
                layerType: 'HYBRID',
                type: 'google'
            },
            googleRoadmap: {
                name: 'Google Streets',
                layerType: 'ROADMAP',
                type: 'google'
            }*/
        };
        this.layers = {all: {baseLayers}, last: {baseLayers}};

		maxCount = Math.max(...this.locations.all.map(itm => itm.count)) + 1;
		this.layers.all.overlays = {
			heatmap: {
				name: 'All Heat Map',
				type: 'webGLHeatmap',
				data: this.locations.all.map(itm => ([itm.lat, itm.lng, 1 - itm.count / maxCount])),  //intense can be always 1
				visible: true
			}
		};

		maxCount = Math.max(...this.locations.last.map(itm => itm.count)) + 1;
		this.layers.last.overlays = {
			heatmap: {
				name: 'Last Heat Map',
				type: 'webGLHeatmap',
				data: this.locations.last.map(itm => ([itm.lat, itm.lng, 1 - itm.count / maxCount])),  //intense can be always 1
				visible: true
			}
		};
    }

    exportChartsToPdf = () => {
        if (!this.trendsData) {
            return;
        }
        this.generatePdfInProcess = true;
        this.devicesGraphViewBeforePdf = this.devicesViewType;
        this.devicesViewType = 'chart';

        // set the relevant elements' visual properties to be ideal for the PDF page before copying the HTML
        let areaForPdf:any = document.getElementById('trendsChartsContainer');
        areaForPdf.classList.add('trends-pdf-layout');

        // timeout is needed to let the css enough time to update on screen and for the responsive charts to resize
        setTimeout(() => {
            const element = document.getElementById('trendsChartsContainer');
            const opt = {
                margin:       40,
                filename:     'trends_and_insights.pdf',
                image:        { type: 'jpeg', quality: 1 },
                html2canvas:  { scale: 2 , width: 1300, windowWidth: 1550},
                jsPDF:        { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling']}
            };

			html2pdf().set(opt).from(element).then(() => {
				setTimeout(() => {
					this.exitFromPdfGeneration();
				});
			}).save();
			},800);
    }

    exitFromPdfGeneration = () => {
        this.devicesViewType = this.devicesGraphViewBeforePdf;
        this.ns.showInfoMessage(util.format(this.dic.MESSAGES.downloadSuccess, 'Trends and insights data'));

        document.getElementById('trendsChartsContainer').classList.remove('trends-pdf-layout');
        this.generatePdfInProcess = false;
        // rerender charts so they'll fit their container's size
        this.getTrendsDataInProcess = true;

		setTimeout(() => {
			this.ngZone.run(() => {
				this.getTrendsDataInProcess = false;
			})
		});
    }



    showIncidentInfoActions = () => {
        return this.incidentActions;
    }

    selectIncidentAction = (incidentObj, action) => {
        switch (action) {
            case this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display:
                this.accountCompromisedService.getUserIncidentInfo(incidentObj, this.isUserAllowedToViewEmailContent);
                break;

            case this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display:
                this.accountCompromisedService.deleteIncident(incidentObj, () => {
                    _.remove<any>(this.incidentsPopup.incidents, (incidentItem:any) => incidentItem._id === incidentObj._id);
                    this.incidentsDisplayedCounter--;
                });
                break;
        }
    };

    mailboxIncidentsAction(action) {
        this.trendsData.incidentsPerMailboxGraph.showActions = false;

        switch (action) {
            case 'incidentsTab':
				this.router.navigate([this.dic.CONSTANTS.appStates.accountCompromised, this.dic.CONSTANTS.accountCompromisedPages.incidents]);
                break;

            case 'csvAll':
                this.exportUserIncidentsToCsv();
                break;
        }
    }

    incidentTypeAction(action) {
        this.trendsData.incidentsTypesGraph.showActions = false;

        switch (action) {
            case 'configurationTab':
				this.router.navigate([this.dic.CONSTANTS.appStates.accountCompromised, this.dic.CONSTANTS.accountCompromisedPages.configurations]);
                break;

            case 'csv':
                this.exportIncidentTypesToCsv();
                break;
        }
    }

    sensitivityDomainIncidentsAction(action) {
        this.trendsData.sensitivityDomainsGraph.showActions = false;

        switch (action) {
            case 'csvAll':
                this.exportIncidentSensitivityToCsv();
                break;
        }
    }

    exportIncidentTypesToCsv() {
        let csvString = "Incident Type, Count\n";

        for (let i = 0 ; i < this.trendsData.incidentsTypesGraph.data.length; i++) {
            csvString += `${this.trendsData.incidentsTypesGraph.labels[i]},`;
            csvString += `${this.trendsData.incidentsTypesGraph.data[i]}\n`;
        }

        this.gs.exportCsv(csvString, `incident_types.csv`);
    };

    exportUserIncidentsToCsv() {
        const list = this.trendsData.incidentsPerMailboxGraph;

        let csvString = "User, Incidents count\n";

        for (let i = 0 ; i < list.data.length; i++) {
            csvString += `${list.labels[i]},`;
            csvString += `${list.data[i]}\n`;
        }

        this.gs.exportCsv(csvString, `mailbox_incidents.csv`);
    };

    exportIncidentSensitivityToCsv() {
        const list = this.trendsData.sensitivityDomainsGraph;

        let csvString = "Domain, Incidents count\n";

        for (let i = 0 ; i < list.data.length; i++) {
            csvString += `${list.labels[i]},`;
            csvString += `${list.data[i]}\n`;
        }

        this.gs.exportCsv(csvString, `sensitivity_email_domains.csv`);
    };
}

function sortItems(list) {
    return Object.keys(list).sort((a,b) => {return list[b] - list[a]});
}

