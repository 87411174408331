<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
	<span>Review the system's DLP sensitivity types and their corresponding scores and compliances.</span>

	<trustifi-table-c
		class="trustifi-table" style="flex: 1 0; overflow: auto; z-index: 5;"
		[list]="sensitivityTypes"
		[headers]="[
			{text:'Name', sortKey:'name', width: 'clamp(200px,50vw,300px)'},
			{text:'Type', sortKey:'type', width: '120px'},
			{text:'Min Score', sortKey:'min_score', width: '120px', tooltip: 'The lowest sensitivity score this type can reach when sent without additional sensitive data'},
			{text:'Max Score', sortKey:'max_score', width: '120px', tooltip: 'The highest sensitivity score this type can reach when sent along with additional sensitive data'},
			{text:'Compliance', width: 'clamp(200px,50vw,300px)'}
		]"
		[cellsPrototype]="[
			{textKey:'name', overflowOutside: true, edit: {modelKey:'test', html:'nameAndRegexEdit'}},
			{textKey: 'type'},
			{textKey: 'min_score'},
			{textKey: 'max_score'},
			{html: 'complianceCell'},
		]"
		[filterData]="filterData"
		(searchFunction)="searchType($event.searchTerm, $event.activeFilters)"
		[options]="{
			defaultSortKey: '-name',
			loadingTableFlag: getSensitivityTypesInProcess,
			itemsNameSingular: 'sensitivity type',
			exportToCsvFunction: exportToCsv,
		}"
	>
		<ng-template #customCells let-item let-cellId="cellId">
			<div *ngIf="cellId === 'complianceCell'">
				<span style="text-overflow: unset; white-space: normal;">
					{{item.compliance?.length ? item.compliance.join(', ') : 'N/A'}}
				</span>
			</div>
		</ng-template>
	</trustifi-table-c>
</div>

