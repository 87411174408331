import {Component, Input, OnInit} from "@angular/core";
import {GeneralService} from "src/app/services/generalService";
import {DICTIONARY} from "../../../../../dictionary";
import {NotificationService} from "../../../../../services/notificationService";
import _ from "lodash";
import util from "util";

@Component({
	selector: 'new-rule-condition-inbound',
	templateUrl: './inbound-new-rule-condition.component.html',
})

export class InboundNewRuleConditionComponent implements OnInit {
	@Input('condition') conditionArr;
	@Input() conditionIndex;
	@Input() newRule;
	@Input() fileTypes;
	@Input() tpMetrics;

	constructor(public gs:GeneralService,
				private ns:NotificationService) {
	}

	dic = DICTIONARY;
	rulesOptions = this.dic.CONSTANTS.inboundEmailRule;
	isInvalidRuleData;
	format = util.format;
	_=_;
	invalidDrag;
	validDrag;
	conditionInEdit;
	selectedGroupsRule;
	showGroups;
	mouseOverAttachment;
	showRuleGroups;
	valuesPopup;
	senderCountryTldPopup;
	emailScanSubStatusPopup;
	attachmentTypesPopup;
	hours = ['00','01','02','03','04','05','06','07','08','09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

	ngOnInit() {
		this.conditionArr.forEach(condition => {
			condition.subTypeShow = this.parseSubType(condition.subType);
		});
	}

	changeConditionType = (condition, type) => {
		if (condition.type === type)
			return;

		condition.type = type;
		condition.isNewTypeSelected = true;
		condition.isNewSubTypeSelected = false;
		condition.is_negate = false;
		condition.subType = _.cloneDeep(this.rulesOptions.types[condition.type].subTypes);
		condition.singleSubType = _.clone(this.rulesOptions.types[condition.type].singleSubType);
		condition.placeholder = _.clone(this.rulesOptions.types[condition.type].placeholder);
		condition.values = _.clone(this.rulesOptions.types[condition.type].values);
		condition.data = {};
		condition.subTypeShow = this.parseSubType(condition.subType);
		if (condition.type === this.rulesOptions.types.addresses.name ||
			condition.type === this.rulesOptions.types.names.name ||
			condition.type === this.rulesOptions.types.domains.name ||
			condition.type === this.rulesOptions.types.keywords.name ||
			condition.type === this.rulesOptions.types.email_header.name) {
			condition.data.matchType = this.dic.CONSTANTS.ruleMatchType.exactMatch;
		}
		if (condition.type === this.rulesOptions.types.received_day.name) {
			condition.data.received_day = _.map(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], dayOfTheWeek => ({name: dayOfTheWeek, selected: false}));
		}
		const dataSrc = condition.isException ? this.newRule.exceptions : this.newRule.conditions;
		this.isConditionDataChanged(dataSrc); // <-- condition validation check
	};

	isConditionDataChanged = (conditions) => {
		this.isInvalidRuleData = false;

		for (let i = 0; i < conditions.length; i++) {
			const conditionArr = conditions[i];

			conditionArr.forEach(condition => {
				switch (condition.type) {
					case this.rulesOptions.types.keywords.name:
						if (!condition.data || !condition.data.keywords || (condition.data.keywords instanceof Array && !condition.data.keywords.length)) {
							condition.invalidKeywords = true;
							this.isInvalidRuleData = true;
							return;
						} else {
							condition.invalidKeywords = false;
						}
						break;

					case this.rulesOptions.types.addresses.name:
						if ((!condition.data || !condition.data.emails || (condition.data.emails instanceof Array && !condition.data.emails.length)) &&
							(condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch ||
								condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.startsWith ||
								condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.endsWith ||
								condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.contains)) {
							condition.invalidEmails = true;
							this.isInvalidRuleData = true;
							return;
						} else {
							condition.invalidEmails = false;
						}
						break;

					case this.rulesOptions.types.names.name:
						if (!condition.data || !condition.data.names || (condition.data.names instanceof Array && !condition.data.names.length)) {
							condition.invalidNames = true;
							this.isInvalidRuleData = true;
							return;
						} else {
							condition.invalidNames = false;
						}
						break;

					case this.rulesOptions.types.domains.name:
						if ((!condition.data || !condition.data.domains || (condition.data.domains instanceof Array && !condition.data.domains.length)) &&
							(condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch)) {
							condition.invalidDomains = true;
							this.isInvalidRuleData = true;
						} else {
							condition.invalidDomains = false;
						}
						break;

					case this.rulesOptions.types.attachment_types.name:
						if (!condition.data || (!condition.data.attachment_types && !condition.data.all_types) || (condition.data.attachment_types instanceof Array && !condition.data.attachment_types.length && !condition.data.all_types)) {
							condition.invalidAttachmentsTypes = true;
							this.isInvalidRuleData = true;
						} else {
							condition.invalidAttachmentsTypes = false;
						}
						break;

					case this.rulesOptions.types.sender_country_tld.name:
						if (!condition.data || !condition.data.tld || (condition.data.tld instanceof Array && !condition.data.tld.length)) {
							condition.invalidSenderCountryTld = true;
							this.isInvalidRuleData = true;
						} else {
							condition.invalidSenderCountryTld = false;
						}
						break;

					case this.rulesOptions.types.email_scan_sub_status.name:
						if (!condition.data || !condition.data.tpMetrics || (condition.data.tpMetrics instanceof Array && !condition.data.tpMetrics.length)) {
							condition.invalidScanSubStatusTypes = true;
							this.isInvalidRuleData = true;
						} else {
							condition.invalidScanSubStatusTypes = false;
						}
						break;

					case this.rulesOptions.types.email_size.name:
						if (!condition.data?.size || condition.data?.size < 0) {
							condition.invalidEmailSize = true;
							this.isInvalidRuleData = true;
						} else {
							condition.invalidEmailSize = false;
						}
						break;

					case this.rulesOptions.types.email_scan_status.name:
						break;
				}
			});
		}
	};

	showSenderCountryTldPopup = (condition) => {
		const senderCountryTlds = _.cloneDeep(this.dic.CONSTANTS.senderCountryTld);
		if (condition.data.tld) {
			condition.data.tld.forEach((tld) => {
				const tldObject:any = senderCountryTlds.find((itm => itm.tld === tld));
				if (tldObject) {
					tldObject.selected = true;
				}
			});
		}
		this.senderCountryTldPopup = {
			show: true,
			senderCountryTlds
		};
		this.conditionInEdit = condition;
	}

	// Attachment types select popup. Lists:
	// 1. fileTypesList - filter-manipulated list. contains only type names.
	// 2. attachmentTypesList - list to hold dynamic "enabled" values.
	// 3. attachmentTypesOriginalList - original list of "enabled" values before popup opened
	showAttachmentTypesPopup = (condition) => {
		const attachmentTypesList = {};
		let all_types;

		_.map(Object.keys(this.fileTypes),type => attachmentTypesList[type] = {enabled : false}); // "enabled" is false by default before all checks

		// if condition is in edit
		if (condition.data.attachment_types && (condition.data.attachment_types.length || condition.data.all_types)){
			if (condition.data.all_types) {
				all_types = true;
				_.map(Object.keys(this.fileTypes),type => attachmentTypesList[type] = {enabled : true});
			}
			else {
				all_types = false;
				_.map(Object.keys(this.fileTypes),type => attachmentTypesList[type] = {enabled : condition.data.attachment_types.includes(type.toString())});
			}
		}
		// if condition is new
		else {
			all_types = false;
		}

		this.conditionInEdit = condition;

		this.attachmentTypesPopup = {
			all_types,
			attachmentTypesList,
			attachmentTypesSearchTxt: '',
			fileTypesList: _.cloneDeep(this.fileTypes),
			show: true
		};
	}

	showEmailSubStatusScanPopup = (condition) => {
		const tpMetrics = _.cloneDeep(this.tpMetrics);
		if (condition.data.tpMetrics) {
			condition.data.tpMetrics.forEach((subStatus) => {
				const metricObj:any = tpMetrics.find((itm => itm.sub_status === subStatus));
				if (metricObj) {
					metricObj.selected = true;
				}
			});
		}
		this.emailScanSubStatusPopup = {
			show: true,
			tpMetrics
		};
		this.conditionInEdit = condition;
	}

	applyEmailScanSubStatusPopup = () => {
		//   const dataSrc = condition.isException ? this.newRule.exceptions : this.newRule.conditions;
		this.conditionInEdit.data.tpMetrics = this.emailScanSubStatusPopup.tpMetrics.filter(itm => itm.selected).map(itm => itm.sub_status);
		this.emailScanSubStatusPopup = null;
	}

	applySenderCountryTldPopup = () => {
		//   const dataSrc = condition.isException ? this.newRule.exceptions : this.newRule.conditions;
		this.conditionInEdit.data.tld = this.senderCountryTldPopup.senderCountryTlds.filter(itm => itm.selected).map(itm => itm.tld);
		this.senderCountryTldPopup = null;
	}

	applyAttachmentTypesPopup = () => {
		const condition = this.conditionInEdit;

		const dataSrc = condition.isException ? this.newRule.exceptions : this.newRule.conditions;
		// get the types that were enabled as an array of strings
		const enabledTypesArr = Object.keys(_.pickBy(this.attachmentTypesPopup.attachmentTypesList,type => type.enabled));
		condition.data["attachment_types"] = enabledTypesArr;
		condition.data["all_types"] = this.attachmentTypesPopup.all_types;

		this.isConditionDataChanged(dataSrc); // <-- validation

		this.attachmentTypesPopup = null;
	}

	searchSenderCountryTld = (searchText, activeFilters) => {
		this.senderCountryTldPopup.senderCountryTlds.forEach(record => {
			// search
			if (searchText) {
				const isFound = searchSenderCountryTextExecute(record, searchText);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter

			record.hide = false;
		});
	}

	searchScanSubStatus = (searchText, activeFilters) => {
		this.emailScanSubStatusPopup.tpMetrics.forEach(record => {
			// search
			if (searchText) {
				const isFound = searchScanSubStatusTextExecute(record, searchText);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter

			record.hide = false;
		});
	}

	clearSearchAttachmentTypes = (condition) => {
		this.conditionInEdit = condition;
		this.attachmentTypesPopup.fileTypesList = _.cloneDeep(this.fileTypes);
		this.attachmentTypesPopup.attachmentTypesSearchTxt = '';
	};

	searchAttachmentTypes = (searchTerm, condition) => {
		if (!searchTerm || searchTerm === '') {
			this.clearSearchAttachmentTypes(condition);
			return;
		}
		searchTerm = searchTerm.toLowerCase();

		// filter:
		this.attachmentTypesPopup.fileTypesList = _.reduce(this.fileTypes,(res,typeVal,typeKey) => {
			if (typeVal.display && typeVal.display.toLowerCase().indexOf(searchTerm) >= 0){
				res[typeKey] = typeVal;
				return res;
			}
			else {
				return res;
			}
		},{});
	};


	openGroupsPopup = (show, condition) => {
		if (show) {
			this.conditionInEdit = condition;
			if (this.conditionInEdit.data && this.conditionInEdit.data.groups) {
				this.selectedGroupsRule = this.conditionInEdit.data.groups.map(itm => itm.email);
			}
		}
		this.showRuleGroups = show;
	};

	applyGroupsPopup = (groups) => {
		let selectedGroups = [];
		_.each(groups, group => {
			if (group.selected) {
				selectedGroups.push({email: group.email, displayName: group.displayName});
			}
		});
		this.conditionInEdit.data.groups = selectedGroups;
		this.showRuleGroups = false;
	};

	cancelNewRuleCondition(conditionOrIndex, conditionArrIndexInRule) {
		if (this.conditionArr.length === 1) {
			const dataSrc = this.conditionArr[0].isException ? this.newRule.exceptions : this.newRule.conditions;
			dataSrc.splice(conditionArrIndexInRule, 1);
		}
		else {
			this.conditionArr.splice(conditionOrIndex, 1);
		}

		if (this.newRule.conditions.length && (this.newRule.conditions.length !== 1 || (this.newRule.conditions.length === 1 && !this.newRule.conditions[0].result))) {
			this.isConditionDataChanged(this.newRule.conditions);
		}
	};

	showPopup = (condition) => {
		this.conditionInEdit = condition;
		this.valuesPopup = {name: '', placeholder: '', subtitle: '', currentList: [], loading: false, error: '', input: '', show: false, importFile: null, lateScroll: 30, loadMoreInProcessTS: null};
		switch (condition.type) {
			case this.rulesOptions.types.addresses.name:
				this.valuesPopup.name = 'emails';
				this.valuesPopup.placeholder = 'email';
				if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
					this.valuesPopup.subtitle = 'This list accepts only valid and complete email addresses';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.startsWith) {
					this.valuesPopup.subtitle = 'This list accepts any value that an email address can start with';
					this.valuesPopup.placeholder = 'email starts with';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.endsWith) {
					this.valuesPopup.subtitle = 'This list accepts any value that an email address can ends with';
					this.valuesPopup.placeholder =' email ends with';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.contains) {
					this.valuesPopup.placeholder = 'email contains';
					this.valuesPopup.subtitle = 'This list accepts any string that can be found in an email address';
				}
				this.valuesPopup.currentList = _.cloneDeep(condition.data.emails) || [];
				break;

			case this.rulesOptions.types.names.name:
				this.valuesPopup.name = 'names';
				this.valuesPopup.placeholder = 'name';
				if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
					this.valuesPopup.subtitle = 'This list accepts only strings';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.startsWith) {
					this.valuesPopup.subtitle = 'This list accepts only strings';
					this.valuesPopup.placeholder = 'name starts with';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.endsWith) {
					this.valuesPopup.subtitle = 'This list accepts only strings';
					this.valuesPopup.placeholder =' name ends with';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.contains) {
					this.valuesPopup.subtitle = 'This list accepts only strings';
					this.valuesPopup.subtitle = 'This list accepts any string that can be found in an email address';
				}
				this.valuesPopup.currentList = _.cloneDeep(condition.data.names) || [];
				break;

			case this.rulesOptions.types.domains.name:
				this.valuesPopup.name = 'domains';
				this.valuesPopup.placeholder ='domain';
				this.valuesPopup.subtitle ='This list accepts only valid domains';
				this.valuesPopup.currentList = _.cloneDeep(condition.data.domains) || [];
				break;

			case this.rulesOptions.types.keywords.name:
				this.valuesPopup.name = 'keywords';
				this.valuesPopup.placeholder = 'keyword';
				this.valuesPopup.subtitle ='';
				this.valuesPopup.currentList = _.cloneDeep(condition.data.keywords) || [];
				break;

			case this.rulesOptions.types.email_header.name:
				this.valuesPopup.name = 'headers';
				this.valuesPopup.placeholder = 'header';
				if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch) {
					this.valuesPopup.subtitle = 'This list accepts full email header values';
				}
				else if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.contains) {
					this.valuesPopup.placeholder ='header contains';
					this.valuesPopup.subtitle ='This list accepts any string that can be found in an email header value';
				}
				this.valuesPopup.currentList = _.cloneDeep(condition.values.value) || [];
				break;
		}

		this.valuesPopup.input = '';
		this.valuesPopup.show = true;
	}

	subtypeChanged = (condition) => {
		this.isInvalidRuleData = false;
		condition.invalidSubTypes = false;
		condition.subTypeShow = this.parseSubType(condition.subType);
	};

	parseSubType = (subType) => {
		let res = [];
		_.map(subType, (value, key) => {
			if (value) {
				res.push(key);
			}
		});
		return res;
	};

	changeMatchType = (matchType, condition) => {
		if (condition.data.matchType === matchType) return;
		condition.data.matchType = matchType;
		this.isInvalidRuleData = false;
	};

	showMatchActions = (conditionObj) => {
		switch (conditionObj.type) {
			case this.rulesOptions.types.addresses.name:
				conditionObj.actions = [
					this.dic.CONSTANTS.ruleMatchType.exactMatch,
					this.dic.CONSTANTS.ruleMatchType.startsWith,
					this.dic.CONSTANTS.ruleMatchType.endsWith,
					this.dic.CONSTANTS.ruleMatchType.internal,
					this.dic.CONSTANTS.ruleMatchType.external,
					this.dic.CONSTANTS.ruleMatchType.freeEmailDomain,
					this.dic.CONSTANTS.ruleMatchType.contains,
					this.dic.CONSTANTS.ruleMatchType.regex
				];
				break;

			case this.rulesOptions.types.names.name:
				conditionObj.actions = [
					this.dic.CONSTANTS.ruleMatchType.exactMatch,
					this.dic.CONSTANTS.ruleMatchType.startsWith,
					this.dic.CONSTANTS.ruleMatchType.endsWith,
					this.dic.CONSTANTS.ruleMatchType.contains
				];
				break;

			case this.rulesOptions.types.domains.name:
				conditionObj.actions = [
					this.dic.CONSTANTS.ruleMatchType.exactMatch,
					this.dic.CONSTANTS.ruleMatchType.internal,
					this.dic.CONSTANTS.ruleMatchType.external,
					this.dic.CONSTANTS.ruleMatchType.freeEmailDomain
				];
				break;

			case this.rulesOptions.types.email_header.name:
				conditionObj.actions = [
					this.dic.CONSTANTS.ruleMatchType.exactMatch,
					this.dic.CONSTANTS.ruleMatchType.contains
				];
				break;

			case this.rulesOptions.types.keywords.name:
				conditionObj.actions = [
					this.dic.CONSTANTS.ruleMatchType.exactMatch,
					this.dic.CONSTANTS.ruleMatchType.contains,
					this.dic.CONSTANTS.ruleMatchType.regex,
					this.dic.CONSTANTS.ruleMatchType.empty
				];
				break;

			case this.rulesOptions.types.email_scan_status.name:
				conditionObj.actions = [
					'Malicious', 'Suspicious', 'Spam', 'Graymail', 'External'
				];
				break;
		}
		conditionObj.showActions = !conditionObj.showActions;
	};

	applyPopupCondition = (condition) => {
		let dataKey;
		switch (condition.type) {
			case this.rulesOptions.types.addresses.name:
				dataKey = 'emails';
				break;

			case this.rulesOptions.types.names.name:
				dataKey = 'names';
				break;

			case this.rulesOptions.types.domains.name:
				dataKey = 'domains';
				break;

			case this.rulesOptions.types.keywords.name:
				dataKey = 'keywords';
				break;
		}

		const dataSrc = condition.isException ? this.newRule.exceptions : this.newRule.conditions;

		if (condition.type !== this.rulesOptions.types.email_header.name){
			condition.data[dataKey] = _.cloneDeep(this.valuesPopup.currentList);
		}
		else {
			condition.values.value = _.cloneDeep(this.valuesPopup.currentList);
		}

		this.isConditionDataChanged(dataSrc); // <-- validation
		this.valuesPopup.show = false;
	}

	isValueChanged = (condition) => {
		this.ns.closeMessage();
		switch (condition.type) {
			case this.rulesOptions.types.addresses.name:
				condition.invalidInput = !this.gs.validateEmail(this.valuesPopup.input) && condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch;
				break;

			case this.rulesOptions.types.domains.name:
				condition.invalidInput = !this.gs.isValidDomain(this.valuesPopup.input);
				break;

			case this.rulesOptions.types.names.name:
			case this.rulesOptions.types.keywords.name:
				condition.invalidInput = false;
				break;
		}
	};

	handleKeypress(event: KeyboardEvent, condition: any) {
		if (event.keyCode === 13 && this.valuesPopup.input && !condition.invalidInput) {
			this.isValueAddedToList(condition);
		}
	}

	// fires when a value is added to the table list in condition popup
	isValueAddedToList = (condition) => {
		// validation:
		if (!this.valuesPopup.input || condition.invalidInput) {
			return;
		}

		this.valuesPopup.input = this.valuesPopup.input.toLowerCase();
		if (_.includes( this.valuesPopup.currentList,this.valuesPopup.input)){
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.XAlreadyExistInX, this.valuesPopup.input , 'list'));
			return;
		}
		// end

		this.valuesPopup.currentList.push(this.valuesPopup.input);
		this.valuesPopup.input = '';
	}

	isValueRemoved = (condition , value) => {
		this.valuesPopup.currentList = _.filter(this.valuesPopup.currentList , v => {return v !== value});
	};

	importConditionListFromCsv =  (file, condition) => {
		if (!file) return;
		this.valuesPopup.loading = true;
		let header, regex, optionalHeaders = null;
		const originalList = this.valuesPopup.currentList;
		const validationFuncAlwaysTrue = () => {return true};
		let validationFunctions = [];
		switch (condition.type) {
			case this.rulesOptions.types.domains.name:
				header = 'domain';
				validationFunctions.push(this.gs.isValidDomain);
				regex = this.dic.CONSTANTS.DOMAIN_REGEX;
				break;

			case this.rulesOptions.types.keywords.name:
				header = 'keyword';
				validationFunctions.push(validationFuncAlwaysTrue);
				break;

			case this.rulesOptions.types.email_header.name:
				header = 'header';
				validationFunctions.push(validationFuncAlwaysTrue);
				break;

			case this.rulesOptions.types.addresses.name:
				header = 'email';
				if (condition.data.matchType === this.dic.CONSTANTS.ruleMatchType.exactMatch)
					validationFunctions.push(this.gs.validateEmail);
				else
					validationFunctions.push(validationFuncAlwaysTrue);
				break;

			case this.rulesOptions.types.names.name:
				header = 'name';
				validationFunctions.push(validationFuncAlwaysTrue);
				break;

		}
		this.gs.readCsv(file, [header], optionalHeaders, regex, validationFunctions, (err, results) => {
			this.valuesPopup.file = null;

			if (err) {
				this.ns.showErrorMessage(err);
				this.valuesPopup.loading = false;
				return;
			}
			if (!results || !results[header] || !results[header].length) {
				this.ns.showErrorMessage(this.dic.ERRORS.noNewResult);
				this.valuesPopup.loading = false;
				return;
			}

			// Add values to list:
			let newList = _.cloneDeep(originalList);

			for (let i = 0; i < results[header].length; i++) {
				if (!_.includes(newList,results[header][i]))
					newList.push(results[header][i])
			}

			if (newList.length === originalList.length){
				this.ns.showErrorMessage(this.dic.ERRORS.noNewResult);
				this.valuesPopup.loading = false;
				return;
			}

			this.valuesPopup.currentList = _.cloneDeep(newList);
			this.valuesPopup.loading = false;
		});
	};

	exportConditionListToCsv = (condition) => {
		let csvString = "";
		const list = this.valuesPopup.currentList;

		switch (condition.type) {
			case this.rulesOptions.types.domains.name:
				csvString = "Domain";
				break;

			case this.rulesOptions.types.keywords.name:
				csvString = "Keyword";
				break;

			case this.rulesOptions.types.addresses.name:
				csvString = "Email";
				break;

			case this.rulesOptions.types.names.name:
				csvString = "Name";
				break;

			case this.rulesOptions.types.email_header.name:
				csvString = "Header";
				break;

			case this.rulesOptions.types.attachment_types.name:
				csvString = "Attachment Type";
				break;

			case this.rulesOptions.types.sender_country_tld.name:
				csvString = "Sender Country (TLD)";
				break;
		}

		if (!list.length) {
			return;
		}

		csvString += "\n";

		list.forEach((item) => {
			csvString += `${item}\n`;
		});

		this.gs.exportCsv(csvString, `Inbound_Rule_Condition_${condition.type}.csv`);
	};
}

function searchSenderCountryTextExecute(record, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((record.tld.toLowerCase().indexOf(searchTerm) > -1) ||
		(record.desc.toLowerCase().indexOf(searchTerm) > -1));
}

function searchScanSubStatusTextExecute(record, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((record.sub_status?.toLowerCase().indexOf(searchTerm) > -1) ||
		(record.status?.toLowerCase().indexOf(searchTerm) > -1) ||
		(record.description?.toLowerCase().indexOf(searchTerm) > -1));
}
