import _ from 'lodash';
import {GeneralService} from "../../../services/generalService";
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnInit} from "@angular/core";
import util from "util";
import {LookAndFeelService} from "../../../services/lookAndFeelService";

@Component({
	selector: 'partner-usage-component',
	templateUrl: './partner-usage.component.html',
})
export class PartnerUsageComponent implements OnInit {
    constructor(public gs:GeneralService,
				private lfs:LookAndFeelService,
				private rs:RouteService,
				private ns:NotificationService) {
    }

    usage;
    inboundUsersMeterChartOptions;
    inboundPlansTypesChartOptions;
    inboundTopPlanUsageChartOptions;
    outboundUsersMeterChartOptions;
    outboundPlansTypesChartOptions;
    outboundTopPlanUsageChartOptions;
    getUsageDataInProcess = false;
	generateReportPopup;
    partnerInfo;
    dic = DICTIONARY;

	ngOnInit() {
		this.getPartnerUsage();
	}

    getPartnerUsage() {
        this.getUsageDataInProcess = true;

        this.rs.getPartnerStats().then((response) => {
            this.usage = response;

            this.usage.outbound.plansUsage.data = this.usage.outbound.plansUsage.data.slice(0, 10);
            this.usage.outbound.plansUsage.labels = this.usage.outbound.plansUsage.labels.slice(0, 10);

            this.usage.inbound.plansUsage.data = this.usage.inbound.plansUsage.data.slice(0, 10);
            this.usage.inbound.plansUsage.labels = this.usage.inbound.plansUsage.labels.slice(0, 10);

            // inbound users usage meter chart
            const inboundUsedUsersRatio = Math.round(this.usage.inbound.used_users / this.usage.inbound.allowed_users * 100);
            const inboundUsersBgColor = inboundUsedUsersRatio > 90 ? '#d9355a' : inboundUsedUsersRatio > 70 ? '#e6cd4f' : '#006400'; // (#006400 = darkgreen)
            this.inboundUsersMeterChartOptions = {
                series: [inboundUsedUsersRatio],
                chart: {
                    type: 'radialBar',
                    width: '220'
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -12,
                                fontSize: '100%'
                            }
                        }
                    }
                },
                colors: [inboundUsersBgColor],

                labels: ['Licenses'],
            };

            this.inboundPlansTypesChartOptions = {
                series: this.usage.inbound.plansStats.plans.data,
                chart: {
                    type: 'donut',
                    height: '100%'
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '52%',
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opt) {
                        return opt.w.globals.series[opt.seriesIndex];
                    }
                },
                colors: [ '#ff9f67', '#fad07d', '#77a380', '#8177a3'],
                labels: this.usage.inbound.plansStats.plans.labels
            };

            this.inboundTopPlanUsageChartOptions = {
                series: [{
                    name: 'Licenses',
                    data: this.usage.inbound.plansUsage.data
                }],
                chart: {
                    type: 'bar',
                    height: '100%',
                    toolbar: {
                        show: false
                    },
                },
                colors: [
                    this.lfs.color
                ],
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                        barHeight: this.gs.getOptimalBarWidth(this.usage.inbound.plansUsage.labels.length) + '%',
                    }
                },
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                },
                xaxis: {
                    categories: this.usage.inbound.plansUsage.labels,
                },
            };

            // inbound users usage meter chart
            const outboundUsedUsersRatio = Math.round(this.usage.outbound.used_users / this.usage.outbound.allowed_users * 100);
            const outboundUsersBgColor = outboundUsedUsersRatio > 90 ? '#d9355a' : outboundUsedUsersRatio > 70 ? '#e6cd4f' : '#006400'; // (#006400 = darkgreen)
            this.outboundUsersMeterChartOptions = {
                series: [outboundUsedUsersRatio],
                chart: {
                    type: 'radialBar',
                    width: '220'
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -12,
                                fontSize: '100%'
                            }
                        }
                    }
                },
                colors: [outboundUsersBgColor],

                labels: ['Licenses'],
            };

            this.outboundPlansTypesChartOptions = {
                series: this.usage.outbound.plansStats.plans.data,
                chart: {
                    type: 'donut',
                    height: '100%'
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '52%',
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opt) {
                        return opt.w.globals.series[opt.seriesIndex];
                    }
                },
                colors: [ '#ff9f67', '#fad07d', '#77a380', '#8177a3'],
                labels: this.usage.outbound.plansStats.plans.labels
            };

            this.outboundTopPlanUsageChartOptions = {
                series: [{
                    name: 'Licenses',
                    data: this.usage.outbound.plansUsage.data
                }],
                chart: {
                    type: 'bar',
                    height: '100%',
                    toolbar: {
                        show: false
                    },
                },
                colors: [
                    this.lfs.color
                ],
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                        barHeight: this.gs.getOptimalBarWidth(this.usage.outbound.plansUsage.labels.length) + '%',
                    }
                },
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                },
                xaxis: {
                    categories: this.usage.outbound.plansUsage.labels,
                },
            };

            const aboutToExpire = 20160; // 2 weeks in minutes
            const now = new Date();
            this.usage.general.forEach((generalObj) => {
                if ((new Date(now)).getTime() > (new Date(generalObj.expired)).getTime()) {
                    generalObj.planExpired = true;
                }
                else if (this.gs.dateDiffInMinutes((new Date()), new Date(generalObj.expired)) < aboutToExpire) {
                    generalObj.aboutToExpire = true;
                }

				generalObj.storageUsageBarOptions = this.getStorageUsageChartOptions(generalObj);
            });

            this.getUsageDataInProcess = false;
        }, (err) => {
			this.getUsageDataInProcess = false;
		});
    }

	getStorageUsageChartOptions(admin) {
		// usage by category bar
		const series = [
			{name: 'Archived Emails', color: '#e0af00', data: [(admin.usage?.archive || 0) * this.dic.CONSTANTS.usageStorageMultipliers.archive * 1024]},
			{name: 'Sent Attachments', color: '#34383f', data: [(admin.usage?.attachments || 0) * this.dic.CONSTANTS.usageStorageMultipliers.attachments * 1024]},
			{name: 'Sent Emails', color: '#cb3e00', data: [(admin.usage?.emails || 0) * this.dic.CONSTANTS.usageStorageMultipliers.emails * 1024]},
			{name: 'Inbound Quarantine', color: '#8300ec', data: [(admin.usage?.quarantines || 0) * this.dic.CONSTANTS.usageStorageMultipliers.quarantines * 1024]},
		];

		const userTotalCapacityBytes = this.dic.CONSTANTS.usageStorageMultipliers.userTotalCapacity * 1024 * 1024 * 1024;
		admin.maxStorageCapacity = Math.max(admin.inbound.allowed_users, admin.outbound.allowed_users) * userTotalCapacityBytes;
		admin.totalStorageUsage = _.sum(_.map(series, value => value.data[0]));

		return {
			series: series,
			chart: {
				type: 'bar',
				height: '60',
				parentHeightOffset: 0,
				stacked: true,
				//offsetX: -25,
				//offsetY: -25,
				toolbar: {
					show: false
				}
			},
			grid: {
				show: false
			},
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: '100%',
					colors: {
						backgroundBarColors: ['#bdbdbd']
					}
				},
			},
			colors: _.map(series, 'color'),
			dataLabels: {
				enabled: false
			},
			xaxis: {
				axisBorder: {
					show: false
				},
				labels: {
					show: false
				},
				axisTicks: {
					show: false
				},
				categories: [''],
				max: (admin.totalStorageUsage / admin.maxStorageCapacity) < 0.10 ? admin.totalStorageUsage * 10 : admin.maxStorageCapacity,
				tickAmount: 1
			},
			yaxis: {
				axisBorder: {
					show: false
				},
				title: {
					text: undefined
				},
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val) => {
						// val is a number that represents KB. Show it as GB when possible (0.01) is the lowest format, or else in MB
						// first 'replace' is to remove unnecessary zeros after dot (XX.00). second 'replace' is to format number with commas (X,XXX)
						if (val > 1000 * 1000 * 1000 * 1000) { // from 1000GB and above - display as 1TB
							return Number(val/(1000 * 1000 * 1000 * 1000)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' TB';
						}
						else if (val > 100 * 1000 * 1000) {  // from 100MB and above - display as 0.1GB
							return Number(val/(1000 * 1000 * 1000)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' GB';
						}
						else { // default - display in MB
							return Number(val/(1000 * 1000)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' MB';
						}
					}
				}
			},
			legend: {
				show: false
			}
		};
	}

    searchLicenseExpiration = (event) => {
        this.usage.general.forEach(record => {
            // search
            if (event.searchTerm) {
                const isFound = searchLicenseTextExecute(record, event.searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    }

    exportLicensesToCsv = (sortBy) => {
        if (!this.usage.general || !this.usage.general.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Admin,Expiration,Inbound Active Licenses,Inbound Allocated licenses, Outbound Active Licenses,Outbound Allocated licenses,Plan Type,Account Manager, Sent Emails, Sent Attachments, Inbound Quarantine, Archives Emails\n";

        let sortedTable = _.filter(this.usage.general,admin => {return !admin.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        sortedTable.forEach((admin) => {
            if (admin.email === 'Your Plan') {
                csvString += `${admin.email},"${admin.expired}","${admin.inbound.used_users}","${this.usage.inbound.allowed_users}","${admin.outbound.used_users}","${this.usage.outbound.allowed_users}","${admin.plan_sub_type}","${admin.account_manager}",${admin.usage.emails || 0},${admin.usage.attachments || 0},${admin.usage.quarantines || 0},${admin.usage.archive || 0}\n`;
            }
            else {
                csvString += `${admin.email},"${admin.expired}","${admin.inbound.used_users}","${admin.inbound.allowed_users}","${admin.outbound.used_users}","${admin.outbound.allowed_users}","${admin.plan_sub_type}","${admin.account_manager}",${admin.usage.emails || 0},${admin.usage.attachments || 0},${admin.usage.quarantines || 0},${admin.usage.archive || 0}\n`;
            }
        });

        this.gs.exportCsv(csvString, `licenses_information.csv`);
    };

    showGenerateReportPopup = () => {
		this.generateReportPopup = {
			address: '',
			getPartnerInfoInProcess: true,
			actionInProcess: false,
			show: true
		};

        this.getPartnerInfo();
    }

    getPartnerInfo() {
        this.rs.getPartnerInfo().then((response) => {
            this.partnerInfo = response;
            if (!this.partnerInfo.partners.partner_contact_info) {
                this.partnerInfo.partners.partner_contact_info = {};
            }

			this.generateReportPopup.getPartnerInfoInProcess = false;
        }, err => {
			this.generateReportPopup.getPartnerInfoInProcess = false;
		});
    };

    updateReportSettings() {
		const data = {
			action: this.dic.CONSTANTS.reportActions.settings,
			data: this.partnerInfo.partners.scheduled_reports
		}
        this.rs.updateReportSettings(data).then((response) => {
            this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
        });
    }

	addReportAddress(address) {
		if (!address || this.generateReportPopup.actionInProcess) {
			return;
		}

		address = address.toLowerCase();
		if (this.partnerInfo.partners.scheduled_reports.report_to_emails.find(itm => itm === address)) {
			this.ns.showErrorMessage(util.format(this.dic.ERRORS.itemAlreadyExist, address));
			return;
		}

		this.generateReportPopup.actionInProcess = true;

		const actionInfo = {
			action: this.dic.CONSTANTS.reportActions.emails,
			data: {
				email: address,
				type: this.dic.CONSTANTS.actions.add
			}
		}
		this.rs.updateReportSettings(actionInfo).then(() => {
			this.partnerInfo.partners.scheduled_reports.report_to_emails.push(address);
			this.generateReportPopup.address = '';
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
			this.generateReportPopup.actionInProcess = false;
		}, err => {
			this.generateReportPopup.actionInProcess = false;
		});
	}

	deleteReportAddress(address) {
		if (!address || this.generateReportPopup.actionInProcess) {
			return;
		}

		address = address.toLowerCase();
		if (!this.partnerInfo.partners.scheduled_reports.report_to_emails.find(itm => itm === address)) {
			return;
		}

		this.generateReportPopup.actionInProcess = true;

		const actionInfo = {
			action: this.dic.CONSTANTS.reportActions.emails,
			data: {
				email: address,
				type: this.dic.CONSTANTS.actions.delete
			}
		}
		this.rs.updateReportSettings(actionInfo).then(() => {
			this.partnerInfo.partners.scheduled_reports.report_to_emails.splice(_.indexOf(this.partnerInfo.partners.scheduled_reports.report_to_emails, address), 1);
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
			this.generateReportPopup.actionInProcess = false;
		}, err => {
			this.generateReportPopup.actionInProcess = false;
		});
	}
}

function searchLicenseTextExecute(license, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return (license.email.toLowerCase().indexOf(searchTerm) > -1);
}
