import {DICTIONARY} from '../dictionary';
import {GeneralService} from "./generalService";
import {Injectable} from "@angular/core";
import _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class OutboundTrendsService {
	dic = DICTIONARY;

	constructor(public gs:GeneralService) {}

	parseEmailSentData(userInfo, emails, currentQuery) {
		let trendsPeriod: any = this.getTrendsPeriodForGraphs(currentQuery);

		const emailsTrends = {
			created: new Date(),
			general: {
				total: emails.length,
				totalRcptTo: 0,
				labels: [],
				data: [[], [], []],
				series: ['Total', 'Secured', 'Quarantined'],
			},

			senders: {},
			recipientsDomains: {},
			rules: {data: [], labels: []},
			events: {
				rate: {opened: 0, bounced: 0, complaint: 0}
			},
			connectionsGraph: []
		};

		if (!emails?.length) {
			emailsTrends.senders = {data: [], labels: []};
			emailsTrends.recipientsDomains = {data: [], labels: []};
			return emailsTrends;
		}

		// @ts-ignore
		emails.sort((a, b) => new Date(a.created) - new Date(b.created));

		const recipientsSent = [];

		let emailSourcePerPeriod: any = {};

		for (let i = 0; i < emails.length; i++) {
			const emailObj = emails[i];
			emailsTrends.general.totalRcptTo += emailObj.recipients_count;

			addSenderMetric(userInfo, emailObj, emailsTrends);
			addRuleMetric(userInfo, emailObj, emailsTrends);

			if (emailObj.recipientsIDs?.length) {
				emailObj.recipientsIDs.forEach((recipientObj) => {
					recipientsSent.push(recipientObj);
				});
			}


			if (!emailSourcePerPeriod.dateStart) {
				emailSourcePerPeriod = this.initEmailSourcePerPeriod(emailObj, trendsPeriod);
			}

			if (this.gs.isSamePeriod(trendsPeriod, emailObj.created, emailSourcePerPeriod.dateEnd)) {
				emailSourcePerPeriod.total += 1;
				if (emailObj.quarantined_id && !emailObj.start_sending_time) {
					emailSourcePerPeriod.quarantined += 1;
				}
				else {
					if (isEmailSecure(emailObj)) {
						emailSourcePerPeriod.secure += 1;
					}
				}
			}
			else {
				this.addOutboundGraphData(emailsTrends, emailSourcePerPeriod, trendsPeriod);
				emailSourcePerPeriod = this.initEmailSourcePerPeriod(emailObj, trendsPeriod);

				emailSourcePerPeriod.total += 1;
				if (emailObj.quarantined_id && !emailObj.start_sending_time) {
					emailSourcePerPeriod.quarantined += 1;
				}
				else {
					if (isEmailSecure(emailObj)) {
						emailSourcePerPeriod.secure += 1;
					}
				}
			}
		}


		this.addOutboundGraphData(emailsTrends, emailSourcePerPeriod, trendsPeriod);

		this.addRecipientExternalDomainMetric(userInfo, recipientsSent, emailsTrends);
		this.addEventsMetric(userInfo, recipientsSent, emailsTrends);

		sortTopSenders(userInfo, emailsTrends);
		parseOutboundRules(userInfo, emailsTrends);

		createConnectionsGraph(userInfo, emailsTrends, emails);
		return emailsTrends;
	}

	addRecipientExternalDomainMetric(userInfo, recipientsSent, emailsTrends) {
		recipientsSent.forEach((recipientObj) => {
			const recipientDomain = this.gs.getEmailDomain(recipientObj.email);
			if (userInfo.internalDomains?.includes(recipientDomain)) {
				return;
			}
			if (!emailsTrends.recipientsDomains[recipientDomain]) {
				emailsTrends.recipientsDomains[recipientDomain] = 0;
			}
			emailsTrends.recipientsDomains[recipientDomain]++;
		});

		sortTopRecipientsDomains(userInfo, emailsTrends);
	}

	getTrendsPeriodForGraphs(options) {
		let trendsPeriod = options.period;
		if (options.period === this.dic.CONSTANTS.trendsPeriod.range && options.range) {
			const days = this.gs.dateDiffInDays(options.range.end, options.range.start);
			//35 = 7 days * 5 weeks - limit graph to 5 weeks
			if (days >= 90) {
				trendsPeriod = this.dic.CONSTANTS.trendsPeriod.lastMonths;
			}
			else if (days >= 7) {
				trendsPeriod = this.dic.CONSTANTS.trendsPeriod.lastWeek;
			}
			else {
				trendsPeriod = this.dic.CONSTANTS.trendsPeriod.lastDay;
			}
		}
		return trendsPeriod;
	}

	addOutboundGraphData(emailsTrends, emailSourcePerPeriod, period) {
		if (emailSourcePerPeriod.dateStart) {
			const dateLabel = this.gs.getPeriodLabel(period, emailSourcePerPeriod);

			emailsTrends.general.labels.push(dateLabel);
			emailsTrends.general.data[0].push(emailSourcePerPeriod.total);
			emailsTrends.general.data[1].push(emailSourcePerPeriod.secure);
			emailsTrends.general.data[2].push(emailSourcePerPeriod.quarantined);
		}
	}

	initEmailSourcePerPeriod(emailObj, period) {
		const emailSourcePerPeriod = this.gs.calcDatesForPeriod(emailObj, period);
		emailSourcePerPeriod.total = 0;
		emailSourcePerPeriod.secure = 0;
		emailSourcePerPeriod.quarantined = 0;

		return emailSourcePerPeriod;
	}

	addEventsMetric(userInfo, recipientsSent, emailsTrends) {
		recipientsSent.forEach((recipientObj) => {
			let opened = false
			recipientObj.track_events.forEach((trackEventObj) => {
				if (!opened && trackEventObj.name === this.dic.EVENTS.eventOpened.name) {
					opened = true;
					emailsTrends.events.rate.opened += 1;
				}
				else if (this.isEventBounce(userInfo, trackEventObj)) {
					emailsTrends.events.rate.bounced += 1;
				}
				else if (trackEventObj.name === this.dic.EVENTS.eventComplaint.name) {
					emailsTrends.events.rate.complaint += 1;
				}
			});
		});

		calcEventsRate(userInfo, emailsTrends);
	}

	isEventBounce(userInfo, trackEventObj) {
		return trackEventObj.name === this.dic.EVENTS.eventHardBounceNoEmail.name || trackEventObj.name === this.dic.EVENTS.eventHardBounceSuppressed.name ||
			trackEventObj.name === this.dic.EVENTS.eventHardBounceGeneral.name || trackEventObj.name === this.dic.EVENTS.eventHardBounceInvalid.name ||
			trackEventObj.name === this.dic.EVENTS.eventSoftBounceMailboxFull.name || trackEventObj.name === this.dic.EVENTS.eventSoftBounceMessageTooLarge.name ||
			trackEventObj.name === this.dic.EVENTS.eventSoftBounceAttachmentRejected.name || trackEventObj.name === this.dic.EVENTS.eventSoftBounceContentRejected.name;
	}
}

function calcEventsRate(userInfo, emailsTrends) {
	emailsTrends.events.rate.opened = Math.floor((emailsTrends.events.rate.opened / emailsTrends.general.totalRcptTo) * 100);
	emailsTrends.events.rate.bounced = Math.floor((emailsTrends.events.rate.bounced / emailsTrends.general.totalRcptTo) * 100);
	emailsTrends.events.rate.complaint = Math.floor((emailsTrends.events.rate.complaint / emailsTrends.general.totalRcptTo) * 100);
}

function addSenderMetric(userInfo, emailObj, emailsTrends) {
	if (!emailObj.from?.email) {
		return;
	}
	if (!emailsTrends.senders[emailObj.from.email]) {
		emailsTrends.senders[emailObj.from.email] = 0;
	}
	emailsTrends.senders[emailObj.from.email]++;
}

function createConnectionsGraph(userInfo, emailsTrends, emailsSent) {
	const top5Senders = emailsTrends.senders.labels.slice(0, 5);

	emailsSent.forEach((emailObj) => {
		if (top5Senders.includes(emailObj.from.email)) {
			if (emailObj.recipients_display_only) {
				if (emailObj.recipients_display_only.to && emailObj.recipients_display_only.to.length) {
					emailObj.recipients_display_only.to.forEach((toObj) => {
						if (toObj.address) {
							emailsTrends.connectionsGraph.push({
								'Sent from': emailObj.from.email,
								'Recipient': toObj.address
							});
						}
					});
				}
				if (emailObj.recipients_display_only.cc && emailObj.recipients_display_only.cc.length) {
					emailObj.recipients_display_only.cc.forEach((ccObj) => {
						if (ccObj.address) {
							emailsTrends.connectionsGraph.push({
								'Sent from': emailObj.from.email,
								'Recipient': ccObj.address
							});
						}
					});
				}
			}
		}
	});
	// recipients_display_only
}


function parseOutboundRules(userInfo, emailsTrends) {
	const outboundRulesData = {
		labels: [],
		rulesIds: [],
		data: [],
	};

	const rulesMapIdToName = {};
	userInfo.rules?.forEach((rule) => {
		rulesMapIdToName[rule._id.toString()] = rule.name;
	});

	const allPlanRulesName = {};
	for (let ruleId in emailsTrends.rules) {
		let ruleName = rulesMapIdToName[ruleId];
		if (ruleName) {
			allPlanRulesName[ruleId] = {name: ruleName, value: emailsTrends.rules[ruleId]};
		}
	}


	const rulesSorted = [];
	for (let ruleId in allPlanRulesName) {
		let ruleData = allPlanRulesName[ruleId];
		if (ruleData.value) {
			rulesSorted.push([ruleData.name, ruleData.value, ruleId]);
		}
	}

	rulesSorted.sort((a, b) => {return b[1] - a[1];});

	for (let idx = 0; idx < rulesSorted.length; idx++) {
		outboundRulesData.labels.push(rulesSorted[idx][0]);
		outboundRulesData.data.push(rulesSorted[idx][1]);
		outboundRulesData.rulesIds.push(rulesSorted[idx][2]);
	}

	emailsTrends.rules = outboundRulesData;
}


function sortTopSenders(userInfo, emailsTrends) {
	let sendersArray = Object.entries(emailsTrends.senders);
	// @ts-ignore
	sendersArray.sort((a, b) => b[1] - a[1]); // Sorting in ascending order of values
	sendersArray = sendersArray.slice(0, 10); // keep top 10
	const sortedObj = Object.fromEntries(sendersArray);
	const sendersMetrics = {
		data: [],
		labels: []
	};
	Object.keys(sortedObj).forEach((senderEmail) => {
		sendersMetrics.data.push(sortedObj[senderEmail]);
		sendersMetrics.labels.push(senderEmail);
	});

	emailsTrends.senders = sendersMetrics;
}

function addRuleMetric(userInfo, emailObj, emailsTrends) {
	if (emailObj.rules_match && emailObj.rules_match.length) {
		emailObj.rules_match.forEach((rule) => {
			if (!emailsTrends.rules[rule.toString()]) {
				emailsTrends.rules[rule.toString()] = 0;
			}
			emailsTrends.rules[rule.toString()]++;
		});
	}
}

function sortTopRecipientsDomains(userInfo, emailsTrends) {
	let recipientsDomainsArray = Object.entries(emailsTrends.recipientsDomains);
	// @ts-ignore
	recipientsDomainsArray.sort((a, b) => b[1] - a[1]); // Sorting in ascending order of values
	recipientsDomainsArray = recipientsDomainsArray.slice(0, 10); // keep top 10
	const sortedObj = Object.fromEntries(recipientsDomainsArray);
	const recipientsMetrics = {
		data: [],
		labels: []
	};

	Object.keys(sortedObj).forEach((senderEmail) => {
		recipientsMetrics.data.push(sortedObj[senderEmail]);
		recipientsMetrics.labels.push(senderEmail);
	});

	emailsTrends.recipientsDomains = recipientsMetrics;
}

function isEmailSecure(emailObj) {
	return emailObj.methods.encrypt_content || emailObj.methods.secure_send || emailObj.methods.secure_reply;
}
