<div class="flxClmn dashboard-page" style="position: relative;"
     [ngStyle]="generatePdfInProcess && {'overflow' : 'hidden'}">
	<div class="page-title-container flxClmn-center" style="z-index: 45;">
		<div class="page-title-inner-container flxRow">
			<span class="page-title">Trends and Insights</span>
		</div>
		<div class="page-subtitle-container flxRow-between hSpace4">
			<span class="page-subtitle f-1-0">Review your inbound security trends and get insights and action recommendations</span>
			<div class="flxRow hSpace1" style="border: solid 1px #ffdca5; padding: 3px 10px;" *ngIf="trendsData?.created">
				<span style="color: #b67000; font-weight: 500;">Data updated as of {{trendsData.created | date: 'MM/dd/yyyy HH:mm'}}</span>
				<a class="blue-link" style="font-weight: bold;" (click)="getTrendsData(true);">Reload</a>
			</div>

		</div>
	</div>

    <div class="page-inner-content-holder bs-enabled" style="background: white; padding-top: 0; position: relative;"
         [ngClass]="{'no-scroll' : generatePdfInProcess}"
         [ngStyle]="{'overflow' : generatePdfInProcess ? 'hidden' : ''}">
        <!--generate pdf loader-->
        <div *ngIf="generatePdfInProcess" class="pdf-generate-loader">
            <div style=" position: absolute; top: calc(50vh - 200px - 40px); left: calc(50vw - 70px); transform: translate(-50%,0); font-size: 4.3rem; opacity: 0.5; align-items: center;"
                 class="animated flxClmn vSpace2 infinite">
                <div class="animated bounce infinite">
                    <i class="far fa-file-pdf grey-to-redFX" style="filter: contrast(1.5) drop-shadow(2px -1px 1px lightgrey);"></i>
                </div>
                <span style="position: relative; font-size: 1.3rem;" class="loading-dots sss">Generating PDF. Please wait</span>
            </div>
        </div>
        <!---->

		<div class="flxRow-between gap-2 mt-0 animated fadeIn" style="position: relative; z-index: 45; align-items: flex-start; margin: 20px 0;">
			<div class="flxRow" style="align-items: center; flex-wrap: wrap;">
				<div class="period-buttons-group bs-enabled flxRow hSpace1" style="height: 30px;">
					<button class="period-button bs-btn bs-btn-light h-100"
							*ngFor="let item of dashboardPeriod | keyvalue: gs.returnZero;"
							[ngClass]="{'chosen' : period === item.value, 'd-none' : item.value.display === dic.CONSTANTS.trendsPeriod.range.display, 'disabled': batchInProcess}"
							(click)="changePeriod(item.value);">
						{{item.value.display}}
					</button>
					<div class="position-relative range-button">
						<button class="period-button hSpace05 bs-btn bs-btn-light h-100" id="customPeriodBtn"
								[ngClass]="{'chosen' : period.display === dic.CONSTANTS.trendsPeriod.range.display, 'disabled': batchInProcess}"
								(click)="showTrendsCustomPeriod = !showTrendsCustomPeriod;">
								<span *ngIf="period.display === dic.CONSTANTS.trendsPeriod.range.display" style="font-weight: 300;">
									From <b class="px-2">{{range.start | date:'MM/dd/yyyy'}}</b> Until <b class="px-2">{{range.end | date:'MM/dd/yyyy'}}</b>
								</span>
								<span *ngIf="period.display !== dic.CONSTANTS.trendsPeriod.range.display">
									Custom
								</span>
							<i class="fa fa-sort-down" style="margin-top: -5px;"></i>
						</button>
						<!--custom period modal-->
						<div class="drop-menu mt-1 p-2" style="width: fit-content; max-width: none; min-width: 300px; overflow: visible; min-height: 0;"
							 [ngClass]="{'active' : showTrendsCustomPeriod}">
							<div class="p-3 text-start m-0 flex-row align-items-center gap-2"
								 style="min-width: 490px;"
								 (clickOutside)="showTrendsCustomPeriod = false;"
								 [exclude]="'#customPeriodBtn'">
								<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
									<span>Date Range:</span>
									<date-picker-c class="f-1-0"
												   [(dateModel)]="range"
												   [isRange]="true"
												   [dateFormat]="'mm/dd/yyyy'"></date-picker-c>
								</div>
								<btn-c endIcon="zmdi zmdi-check"
									   [loading]="getDataInProcess"
									   (action)="changePeriod(dic.CONSTANTS.trendsPeriod.range);
										 getTrendsByRangeDates();
										 showTrendsCustomPeriod = false;">
									Get Trends
								</btn-c>
							</div>
						</div>
					</div>
				</div>

				<div class="flxRow hSpace1" style="align-items: center; margin: 0 10px;">
					<span style="margin-left: auto;" *ngIf="totalEmails">
						<b style="font-size: 14px;">
							Total Records: {{totalEmails}}
						</b>
						<i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
							tooltip='The trends report presented below has been generated based on this total number of records, containing both quarantined and released emails. Note that records are not kept for all emails scanned by Inbound Shield.'></i>
						<i class="fas fa-arrow-to-left opacity-50"></i>
					</span>
					<div class="flxRow hSpace1" style="align-items: center;" *ngIf="(batchInProcess && emails?.length)">
						<span>|</span>
						<span class="loading-dots" style="font-size: 15px; font-weight: bold; opacity: 0.65;">Processing</span>
						<span style="font-size: 15px; font-weight: bold; opacity: 0.65;">{{getPercentage(emails.length, totalEmails)}}%</span>
						<btn-c variant="text" color="danger"
							   style="min-width: 0;"
							   *ngIf="showAbortLoadingEmailsLabel"
							   (action)="abortLoadingEmails();">
							Abort
						</btn-c>
					</div>
				</div>
			</div>

			<div class="flxRow gap-1" style="flex-direction: row-reverse; flex-wrap: wrap;">
				<btn-c endIcon="fas fa-file-download" variant="outlined"
					   [isDisabled]="!trendsData || generatePdfInProcess || getTrendsDataInProcess || batchInProcess"
					   (action)="exportChartsToPdf();">
					Export to PDF
				</btn-c>
				<btn-c endIcon="fas fa-chart-pie" variant="outlined"
					   (action)="generateReportPopup();">
					Generate Report
				</btn-c>
			</div>
		</div>


		<div class="bs-enabled" id="trendsChartsContainer">
            <div class="bs-container text-center" style="max-width: none !important;"
                 [ngClass]="{'w-100' : !generatePdfInProcess}">
				<div class="bs-row trends-pdf-page-title" style="display: none;">
					<img class="mb-3" [src]="lfs.logo" style="width: 200px;"/>
				</div>
                <div class="bs-row trends-pdf-page-title" style="display: none;">
                    <div class="bs-col-12 mb-4 px-4" style="background: #fdfdfd; padding: 10px;">
                        <div class="flxClmn vSpace05">
                            <div class="flxRow-between" style="align-items: flex-end;">
                                <span style="font-weight: bold; font-size: 1.6rem; white-space: nowrap;">Inbound Trends and Insights</span>
                                <span style="font-size: 1.25rem; white-space: nowrap;">
                                Date printed: &nbsp;
                                <time>{{todayDate | date:'MM/dd/yyyy'}}</time>
                            </span>
                            </div>
                            <div class="flxRow hSpace05" style="font-size: 1.25rem;">
                                <span>Period:&nbsp;</span>
                                <span *ngIf="period !== dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">{{period.display}}</span>
                                <span *ngIf="period === dic.CONSTANTS.trendsPeriod.range" style="white-space: nowrap;">
							<time>{{range.start | date:'MM/dd/yyyy'}}</time>
							&nbsp;-&nbsp;
							<time>{{range.end | date:'MM/dd/yyyy'}}
							</time>
						</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bs-row g-4">

					<!--General-->
					<div class="bs-col-6 bs-col-large-4" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold text-nowrap">General Overview</h6>
									<i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
									   tooltip='Shows how many emails were scanned by Inbound Shield, and the distribution of threats for these emails'></i>
									<i class="fas fa-arrow-to-left opacity-50"></i>
								</div>

								<span class="opacity-75 fw-500 text-start" style="height: 16px;" *ngIf="emailsScanned">
                                	<b>{{emailsScanned}} emails</b> were scanned by Inbound Shield
								</span>
							</div>
							<div class="flex-column f-1-0 centralize animated fadeIn">
								<div class="flex-column justify-content-center f-1-0" >
									<apx-chart class="trends-radial-pie-chart pe-4 f-1-0" style="max-height: min(26vw,450px);"
											   *ngIf="generalChartOptions && !getTrendsDataInProcess"
											   #generalTrendsChart
											   [series]="generalChartOptions?.series"
											   [chart]="generalChartOptions?.chart"
											   [plotOptions]="generalChartOptions?.plotOptions"
											   [colors]="generalChartOptions?.colors"
											   [legend]="generalChartOptions?.legend"
											   [labels]="generalChartOptions?.labels"></apx-chart>
								</div>
							</div>
							<!--actions menu-->
							<div class="trends-card-actions-menu">
								<i class="fas fa-ellipsis-h" id="generalMenuTrigger" tooltip="Menu"
								   (click)="showGeneralMenu = !showGeneralMenu"></i>
								<div class="drop-menu" [ngClass]="{'active' : showGeneralMenu}">
									<ul (clickOutside)="showGeneralMenu = false;"
										[exclude]="'#generalMenuTrigger'">
										<li class="flex-row align-items-center"
											(click)="exportGeneralToCsv();">
											<span>Export to CSV</span>
										</li>
									</ul>
								</div>
							</div>
							<!--tips modal-->
							<div class="trends-card-actions-menu tips"
								 *ngIf="generalChartOptions"
								 [ngClass]="{'active' : showGeneralTips}">
								<i class="fa fa-lightbulb" id="generalTipsTrigger" tooltip="Tips"
								   (click)="showGeneralTips = !showGeneralTips"></i>
								<div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 350px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
									 [ngClass]="{'active' : showGeneralTips}">
									<ul style="cursor: default;" (clickOutside)="showGeneralTips = false;"
										[exclude]="'#generalTipsTrigger'">
										<div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
											<i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
											<span class="fw-500">Click on any bar to be view the quarantined emails with this type.</span>
										</div>
									</ul>
								</div>
							</div>
							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!generalChartOptions?.series?.length || getTrendsDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Inbound Trends-->
					<div class="bs-col-6 bs-col-large-4" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold text-nowrap">Inbound Trends</h6>
									<i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
									   tooltip='Shows the distribution of threats that were identified by Inbound Shield™ during the selected time period.'></i>
									<i class="fas fa-arrow-to-left opacity-50"></i>
								</div>
							</div>
							<apx-chart class="f-1-0 animated fadeIn clickable-chart"
									   *ngIf="inboundTrendsChartOptions?.xaxis?.categories?.length && !getTrendsDataInProcess"
									   #inboundTrendsChart
									   [series]="inboundTrendsChartOptions.series"
									   [chart]="inboundTrendsChartOptions.chart"
									   [plotOptions]="inboundTrendsChartOptions.plotOptions"
									   [colors]="inboundTrendsChartOptions.colors"
									   [stroke]="inboundTrendsChartOptions.stroke"
									   [xaxis]="inboundTrendsChartOptions.xaxis"
									   [yaxis]="inboundTrendsChartOptions.yaxis"
									   [fill]="inboundTrendsChartOptions.fill"
									   [dataLabels]="inboundTrendsChartOptions.dataLabels"></apx-chart>
							<!--actions menu-->
							<div class="trends-card-actions-menu">
								<i class="fas fa-ellipsis-h" id="inboundTrendsMenuTrigger" tooltip="Menu"
								   (click)="showInboundTrendsMenu = !showInboundTrendsMenu"></i>
								<div class="drop-menu" [ngClass]="{'active' : showInboundTrendsMenu}">
									<ul (clickOutside)="showInboundTrendsMenu = false;"
										[exclude]="'#inboundTrendsMenuTrigger'">
										<li class="flex-row align-items-center"
											(click)="goToQuarantineTab({});">
											<span>View quarantined emails</span>
										</li>
									</ul>
								</div>
							</div>
							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!inboundTrendsChartOptions?.xaxis?.categories?.length || getTrendsDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Threats Types-->
					<div class="bs-col-6 bs-col-large-4" style="height: clamp(350px,50vh,405px);">
						<div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
							<div class="flex-column align-items-start gap-1">
								<div class="flex-row align-items-center gap-1">
									<h6 class="m-0 fw-bold text-nowrap">Threats Types</h6>
									<i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
									   tooltip="The numbers indicate a total number of emails detected with this threat type. Actual numbers could be even greater since this does not include blocked emails as a result of blocklisting or preferences."></i>
									<i class="fas fa-arrow-to-left opacity-50"></i>
								</div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;" *ngIf="trendsData?.detectionType?.data?.length">
                                {{trendsData.detectionType.total ? trendsData.detectionType.total > 1 ? trendsData.detectionType.total + ' threats listed' : '1 threat listed' : ' '}}
                            </span>
							</div>
							<div class="flex-column f-1-0 centralize animated fadeIn">
								<apx-chart class="pie-chart" style="width: 100%; max-width: 400px;"
										   *ngIf="trendsData?.detectionType?.total && !getTrendsDataInProcess"
										   #threatDetectionTypeChart
										   [series]="detectionTypeChartOptions.series"
										   [chart]="detectionTypeChartOptions.chart"
										   [plotOptions]="detectionTypeChartOptions.plotOptions"
										   [dataLabels]="detectionTypeChartOptions.dataLabels"
										   [colors]="detectionTypeChartOptions.colors"
										   [labels]="detectionTypeChartOptions.labels"></apx-chart>
							</div>
							<!--actions menu-->
							<div class="trends-card-actions-menu">
								<i class="fas fa-ellipsis-h" id="threatsThreatTypeMenuTrigger" tooltip="Menu"
								   (click)="showThreatTypeMenu = !showThreatTypeMenu"></i>
								<div class="drop-menu" [ngClass]="{'active' : showThreatTypeMenu}">
									<ul (clickOutside)="showThreatTypeMenu = false;"
										[exclude]="'#threatsThreatTypeMenuTrigger'">
										<li class="flex-row align-items-center"
											(click)="exportDetectionTypesToCsv();">
											<span>Export to CSV</span>
										</li>
									</ul>
								</div>
							</div>

							<!--loader / placeholder-->
							<div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.detectionType?.total || getTrendsDataInProcess">
								<div class="flex-column text-center centralize f-1-0">
									<span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
									<loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

                    <!--Quarantine Status Overview-->
                    <div class="bs-col-6 bs-col-large-4" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
                            <div class="flex-column align-items-start gap-1">
                                <div class="flex-row align-items-center gap-1">
                                    <h6 class="m-0 fw-bold text-nowrap">Quarantine Emails Overview</h6>
                                    <i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
                                       tooltip="Shows how many emails from the selected time period are still quarantined"></i>
                                    <i class="fas fa-arrow-to-left opacity-50"></i>
                                </div>
								<span class="opacity-75 fw-500 text-start" style="height: 16px;" *ngIf="trendsData?.quarantined?.total">
                                	{{trendsData.quarantined.total}} emails in quarantine
								</span>
                            </div>
							<apx-chart class="f-1-0 animated fadeIn clickable-chart"
									   *ngIf="trendsData?.quarantined?.total && !getTrendsDataInProcess"
									   #threatStatusChart
									   [series]="quarantinedChartOptions.series"
									   [chart]="quarantinedChartOptions.chart"
									   [plotOptions]="quarantinedChartOptions.plotOptions"
									   [xaxis]="quarantinedChartOptions.xaxis"
									   [yaxis]="quarantinedChartOptions.yaxis"
									   [fill]="quarantinedChartOptions.fill"
									   [stroke]="quarantinedChartOptions.stroke"
									   [dataLabels]="quarantinedChartOptions.dataLabels"></apx-chart>
                            <!--actions menu-->
                            <div class="trends-card-actions-menu">
                                <i class="fas fa-ellipsis-h" id="threatsStatusMenuTrigger" tooltip="Menu"
                                   (click)="showThreatsStatusMenu = !showThreatsStatusMenu"></i>
                                <div class="drop-menu" [ngClass]="{'active' : showThreatsStatusMenu}">
                                    <ul (clickOutside)="showThreatsStatusMenu = false;"
                                        [exclude]="'#threatsStatusMenuTrigger'">
                                        <li class="flex-row align-items-center"
                                            (click)="goToQuarantineTab({}); closeAction(trendsData.quarantined)">
                                            <span>View quarantined emails</span>
                                        </li>
                                        <li class="flex-row align-items-center"
                                            (click)="goToInboundTab(dic.CONSTANTS.adminPages.inbound.inboundShield, dic.CONSTANTS.inboundShieldSettingsPageTabs.threatPreventionRules); closeAction(trendsData.quarantined)">
                                            <span>Edit threat prevention rules</span>
                                        </li>
                                        <li class="flex-row align-items-center"
                                            (click)="exportQuarantinedToCsv(); closeAction(trendsData.quarantined)">
                                            <span>Export to CSV</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.quarantined?.total || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Top Malicious Sender Domains-->
                    <div class="bs-col-6 bs-col-large-4" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
                            <div class="flex-column align-items-start gap-1">
                                <div class="flex-row align-items-center gap-1">
                                    <h6 class="m-0 fw-bold text-nowrap">Top Malicious Sender Domains</h6>
                                    <i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
                                       tooltip="Shows which domains send most threats to your mailboxes. The numbers displayed indicate a total number of emails sent from the domain."></i>
                                    <i class="fas fa-arrow-to-left opacity-50"></i>
                                </div>
                                <!--<span class="-75 fw-500 text-start" style="height: 16px;">{{trendsData.quarantined.total ? trendsData.quarantined.total > 1 ? trendsData.quarantined.total + ' threats detected' : '1 threat detected' : ' '}}</span>-->
                            </div>
							<apx-chart class="f-1-0 animated fadeIn clickable-chart" style="flex: 1 0; width: 100%; max-width: 700px; z-index: 5;"
									   *ngIf="domainChartOptions?.xaxis?.categories?.length && !getTrendsDataInProcess"
									   #topSenderDomainsChart
									   [series]="domainChartOptions.series"
									   [chart]="domainChartOptions.chart"
									   [plotOptions]="domainChartOptions.plotOptions"
									   [colors]="domainChartOptions.colors"
									   [xaxis]="domainChartOptions.xaxis"
									   [dataLabels]="domainChartOptions.dataLabels"
									   [labels]="domainChartOptions.labels"></apx-chart>

                            <!--actions menu-->
                            <div class="trends-card-actions-menu">
                                <i class="fas fa-ellipsis-h" id="topSenderMenuTrigger" tooltip="Menu"
                                   (click)="showTopSenderMenu = !showTopSenderMenu"></i>
                                <div class="drop-menu" [ngClass]="{'active' : showTopSenderMenu}">
                                    <ul (clickOutside)="showTopSenderMenu = false;"
                                        [exclude]="'#topSenderMenuTrigger'">
                                        <li class="flex-row align-items-center"
                                            (click)="exportDomainsToCsv();">
                                            <span>Export to CSV</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--tips modal-->
                            <div class="trends-card-actions-menu tips"
                                 *ngIf="trendsData?.domain?.data?.length"
                                 [ngClass]="{'active' : showTopSenderTips}">
                                <i class="fa fa-lightbulb" id="threatsTopSenderTrigger" tooltip="Tips"
                                   (click)="showTopSenderTips = !showTopSenderTips"></i>
                                <div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
                                     [ngClass]="{'active' : showTopSenderTips}">
                                    <ul style="cursor: default;" (clickOutside)="showTopSenderTips = false;"
                                        [exclude]="'#threatsTopSenderTrigger'">
                                        <div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
                                            <i class="fas fa-info" style="flex: 0 0 16px;"></i>
                                            <span class="fw-500">Recommended: add unfamiliar sender domains to blocklist.</span>
                                        </div>
                                        <div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
                                            <i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
                                            <span class="fw-500">Click on any bar to view the quarantined emails for this domain, or
                                            <a class="blue-link no-underline" (click)="openBlockDomainsPopup(); showTopSenderTips = false;">click here</a> to add multiple domains at once.</span>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!domainChartOptions?.xaxis?.categories?.length || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Top Attacked Mailboxes-->
                    <div class="bs-col-6 bs-col-large-4" style="height: clamp(350px,50vh,405px);">
                        <div class="trends-data-card flex-column p-3 animated fadeIn h-100 w-100">
                            <div class="flex-column align-items-start gap-1">
                                <div class="flex-row align-items-center gap-1">
                                    <h6 class="m-0 fw-bold text-nowrap">Top Attacked Mailboxes</h6>
                                    <i style="font-weight: 400; top: -4px; position: relative;" class="fas fa-question-circle opacity-75"
                                       tooltip="Shows which mailboxes in your organization receive the most threats. The bar next to each mailbox represent a distribution of the threat types received by that mailbox."></i>
                                    <i class="fas fa-arrow-to-left opacity-50"></i>
                                </div>
                            </div>
							<apx-chart class="f-1-0 animated fadeIn clickable-chart" style="z-index: 5;"
									   *ngIf="trendsData?.mailbox?.total && !getTrendsDataInProcess"
									   #topAttackedMailboxesChart
									   [series]="topMailboxesChartOptions.series"
									   [chart]="topMailboxesChartOptions.chart"
									   [plotOptions]="topMailboxesChartOptions.plotOptions"
									   [dataLabels]="topMailboxesChartOptions.dataLabels"
									   [colors]="topMailboxesChartOptions.colors"
									   [stroke]="topMailboxesChartOptions.stroke"
									   [xaxis]="topMailboxesChartOptions.xaxis"
									   [yaxis]="topMailboxesChartOptions.yaxis"
									   [fill]="topMailboxesChartOptions.fill"
									   [legend]="topMailboxesChartOptions.legend"
									   [labels]="topMailboxesChartOptions.labels"></apx-chart>

                            <!--actions menu-->
                            <div class="trends-card-actions-menu">
                                <i class="fas fa-ellipsis-h" id="topMailboxesMenuTrigger" tooltip="Menu"
                                   (click)="showTopMailboxesMenu = !showTopMailboxesMenu"></i>
                                <div class="drop-menu" [ngClass]="{'active' : showTopMailboxesMenu}">
                                    <ul (clickOutside)="showTopMailboxesMenu = false;"
                                        [exclude]="'#topMailboxesMenuTrigger'">
                                        <li class="flex-row align-items-center"
                                            (click)="mailboxesAction('exportCsv');">
                                            <span>Export to CSV</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--tips modal-->
                            <div class="trends-card-actions-menu tips"
                                 *ngIf="trendsData?.mailbox?.total"
                                 [ngClass]="{'active' : showTopMailboxesTips}">
                                <i class="fa fa-lightbulb" id="threatsTopMailboxesTrigger" tooltip="Tips"
                                   (click)="showTopMailboxesTips = !showTopMailboxesTips"></i>
                                <div class="drop-menu mt-1" style="width: fit-content; max-width: none; min-width: 300px; background: linear-gradient(90deg, hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.1), white) , linear-gradient(45deg, white, white);"
                                     [ngClass]="{'active' : showTopMailboxesTips}">
                                    <ul style="cursor: default;" (clickOutside)="showTopMailboxesTips = false;"
                                        [exclude]="'#threatsTopMailboxesTrigger'">
                                        <div class="py-3 px-3 text-start m-0 flex-row align-items-center gap-2">
                                            <i class="fas fa-hand-pointer fw-500" style="flex: 0 0 16px;"></i>
                                            <span class="fw-500">Click on any bar to view the quarantined emails related to this mailbox.</span>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <!--loader / placeholder-->
                            <div class="flex-column p-3 animated fadeIn h-100 w-100" *ngIf="!trendsData?.mailbox?.total || getTrendsDataInProcess">
                                <div class="flex-column text-center centralize f-1-0">
                                    <span class="fw-500 opacity-75" *ngIf="!getTrendsDataInProcess">No data for selected time period</span>
                                    <loader class="opacity-75 align-self-center" *ngIf="getTrendsDataInProcess" height="100" width="100"></loader>
                                </div>
                            </div>
                        </div>
                    </div>

					<!--Blocklist configuration-->
					<div class="bs-col" style="min-height: 300px; min-width: 470px;" [ngClass]="{'bs-clo-6' : generatePdfInProcess}">
						<div class="trends-data-card">
							<div class="flex-row gap-4 animated fadeIn h-100 w-100" *ngIf="blacklistChartOptions">
								<div class="flex-column justify-content-between p-3 pe-0">
									<div class="flex-column align-items-start ">
										<h3 class="fw-bold text-success">{{allowBlockData.blacklist.total}}</h3>
										<span class="opacity-75 fw-500 text-start">Globally Blocklisted</span>
									</div>
									<a class="blue-link no-underline text-start text-nowrap pdf-excluded" (click)="goToInboundTab(dic.CONSTANTS.adminPages.inbound.configuration, dic.CONSTANTS.inboundConfigurationPageTabs.senderLists)">
										Blocklist configuration
									</a>
								</div>
								<div class="flex-column justify-content-center f-1-0" style="">
									<apx-chart class="trends-radial-pie-chart pe-4 f-1-0" style="max-height: min(26vw,450px);"
											   *ngIf="blacklistChartOptions"
											   [series]="blacklistChartOptions.series"
											   [chart]="blacklistChartOptions.chart"
											   [plotOptions]="blacklistChartOptions.plotOptions"
											   [colors]="blacklistChartOptions.colors"
											   [legend]="blacklistChartOptions.legend"
											   [labels]="blacklistChartOptions.labels"></apx-chart>
								</div>
							</div>
							<!--loader / placeholder-->
							<div class="flex-column gap-3 p-3 animated fadeIn h-100 w-100" *ngIf="!blacklistChartOptions">
								<div class="flex-column text-center centralize f-1-0">
									<loader class="opacity-75 align-self-center" *ngIf="!allowBlockData" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

					<!--Allowlist-->
					<div class="bs-col" style="min-height: 300px; min-width: 470px;">
						<div class="trends-data-card">
							<div class="flex-row gap-4 animated fadeIn h-100 w-100" *ngIf="whitelistChartOptions">
								<div class="flex-column justify-content-between p-3 pe-0">
									<div class="flex-column align-items-start ">
										<h3 class="fw-bold text-success">{{allowBlockData.whitelist.total}}</h3>
										<span class="opacity-75 fw-500 text-start">Globally Allowlisted</span>
									</div>
									<div class="flex-row align-items-center gap-1">
										<a class="blue-link no-underline text-start text-nowrap pdf-excluded" (click)="goToInboundTab(dic.CONSTANTS.adminPages.inbound.configuration, dic.CONSTANTS.inboundConfigurationPageTabs.senderLists)">
											Allowlist configuration
										</a>
										<a href="https://docs.trustifi.com/docs/smart-allowlisting-process" target="_blank" style="margin-top: -2px;">
											<i class="fas fa-question-circle" style="font-weight: 300;"
											   tooltip="Click here to learn more why is it more secure to use allowlist"></i>
										</a>
									</div>
								</div>
								<div class="flex-column justify-content-center f-1-0" >
									<apx-chart class="trends-radial-pie-chart pe-4 f-1-0" style="max-height: min(26vw,450px);"
											   *ngIf="whitelistChartOptions"
											   [series]="whitelistChartOptions.series"
											   [chart]="whitelistChartOptions.chart"
											   [plotOptions]="whitelistChartOptions.plotOptions"
											   [colors]="whitelistChartOptions.colors"
											   [legend]="whitelistChartOptions.legend"
											   [labels]="whitelistChartOptions.labels"></apx-chart>
								</div>
							</div>
							<!--loader / placeholder-->
							<div class="flex-column gap-3 p-3 animated fadeIn h-100 w-100" *ngIf="!whitelistChartOptions">
								<div class="flex-column text-center centralize f-1-0">
									<loader class="opacity-75 align-self-center" *ngIf="!allowBlockData" height="100" width="100"></loader>
								</div>
							</div>
						</div>
					</div>

                </div>
            </div>
        </div>
    </div>

    <!--Add domains to blocklist popup-->
    <popup-c *ngIf="blockDomainsPopup?.show"
           head="Add Domains to Blocklist" size="medium"
           (closeCb)="blockDomainsPopup = null;"
           (confirmCb)="confirmBlockDomainsPopup()"
           [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: addDomainToBlacklistInProcess}}">
        <div class="flxClmn vSpace2 f-1-0" style="overflow: auto;">
            <span>Select domain(s) to block and then click "Confirm"</span>
            <span style="font-weight: bolder;">Note: You should avoid blocklisting popular free email domains such as Gmail or Yahoo</span>
            <!--malicious domains table-->
            <trustifi-table-c
                    class="trustifi-table" style="flex: 1 0; z-index: 5;"
                    [list]="blockDomainsPopup.domains"
                    [headers]="[
                             {text:'Domain', sortKey:'name'},
                             {text:'Emails Count', sortKey:'threatsCount'},
                         ]"
                    [cellsPrototype]="[
                                    {textKey: 'name'},
                                    {textKey: 'threatsCount'},
                                ]"
                    [options]="{
                            itemsNameSingular: 'domain',
                            defaultSortKey: '-threatsCount',
                            showRadioButtons: true
                        }"
            >
            </trustifi-table-c>

        </div>
    </popup-c>

    <!--Add domains to domain spoofing control popup-->
    <popup-c *ngIf="addDomainsToStrictCheckPopup?.show"
           head="Add Domains to Domain Spoofing Control" size="medium"
           (closeCb)="addDomainsToStrictCheckPopup = null;"
           (confirmCb)="confirmAddDomainsToStrictCheckPopup()"
           [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: addDomainToStrictCheckDomainsInProcess}}">
        <div class="flxClmn vSpace1 f-1-0" style="overflow: auto;">
            <span>Select domain(s) to add and then click "Confirm".</span>
            <span>For the selected domains, additional tests will be performed to prevent advanced Phishing and Spoofing attacks.</span>
            <!--malicious domains table-->
            <trustifi-table-c
                    class="trustifi-table" style="flex: 1 0; z-index: 5;"
                    [list]="addDomainsToStrictCheckPopup.domains"
                    [headers]="[
                             {text:'Domain', sortKey:'name'},
                         ]"
                    [cellsPrototype]="[
                                    {textKey: 'name'},
                                ]"
                    [options]="{
                            itemsNameSingular: 'domain',
                            defaultSortKey: '-name',
                            showRadioButtons: true
                        }"
            >
            </trustifi-table-c>
        </div>
    </popup-c>

    <!--Add addresses to the sender allowlist popup-->
    <popup-c *ngIf="addAddressesToAllowlistPopup?.show"
           head="Add Addresses to the Sender Allowlist" size="medium"
           (closeCb)="addAddressesToAllowlistPopup = null;"
           (confirmCb)="confirmAddAddressesToAllowlistPopup()"
           [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: addAddressToWhitelistInProcess}}">
        <div class="flxClmn vSpace1 f-1-0" style="overflow: auto;">
            <span>Select email address(es) to add and then click "Confirm".</span>
            <span>Emails sent from selected email addresses will always be released and arrive to the recipient's inbox.</span>
            <!--malicious domains table-->
            <trustifi-table-c
                    class="trustifi-table" style="flex: 1 0; z-index: 5;"
                    [list]="addAddressesToAllowlistPopup.addresses"
                    [headers]="[
                             {text:'Address', sortKey:'name'},
                         ]"
                    [cellsPrototype]="[
                                    {textKey: 'name'},
                                ]"
                    [options]="{
                            itemsNameSingular: 'address',
                            defaultSortKey: '-name',
                            showRadioButtons: true
                        }"
            >
            </trustifi-table-c>
        </div>
    </popup-c>


	<!--Quarantine report popup-->
	<popup-c class="bs-enabled" *ngIf="showGenerateReportPopup?.show"
			 head="Quarantine Report" size="dynamic" width="900px"
			 subTitle="Detailed report of your quarantined email records"
			 (closeCb)="showGenerateReportPopup = null; clearReportColumns();">
		<div class="flex-column gap-2 f-1-0">
			<div class="flex-column gap-3 f-1-0">
				<!--Selected fields-->
				<div style="display: flex; align-items: center; margin-bottom: 20px;">
					<div style="font-weight: bold; margin-right: 20px; flex-basis: 130px; flex-shrink: 0;">Selected fields:</div>
					<div class="flxRow hSpace05">
						<div title="{{_.map(_.filter(csvFields, 'selected'), 'name').join(', ') || 'Select fields'}}">
							<app-dropdown-c style="width: 240px; pointer-events: none;"
										  text="{{_.map(_.filter(csvFields, 'selected'), 'name').join(', ') || 'Select fields'}}"></app-dropdown-c>
						</div>
						<btn-c (click)="openReportColumnsPopup();">
							Customize report fields
						</btn-c>
					</div>
				</div>
				<!--Selected mailboxes-->
				<div style="display: flex; align-items: center; margin-bottom: 20px;">
					<div style="font-weight: bold; margin-right: 20px; flex-basis: 130px; flex-shrink: 0;">Selected mailboxes:</div>
					<div class="flxRow hSpace05">
						<div>
							<app-dropdown-c style="width: 240px; pointer-events: none;"
											text="{{showGenerateReportPopup.selected_mailboxes?.length ? showGenerateReportPopup.selected_mailboxes.join(', ') : 'All mailboxes'}}"></app-dropdown-c>
						</div>
						<btn-c (click)="showGenerateReportPopup.showMailboxes = true;">
							Customize mailboxes
						</btn-c>
					</div>
				</div>
				<!--Download report-->
				<div class="flxRow hSpace1" style="align-items: center;">
					<span style="font-weight: bold; margin-right: 20px; flex-basis: 130px; flex-shrink: 0;">Generate report:</span>
						<div class="flxRow hSpace1" style="align-items: center; margin-left: 0;">
							<span>Date Range:</span>
							<date-picker-c class="f-1-0" style="min-width: 220px;"
										   [(dateModel)]="showGenerateReportPopup.range"
										   [isRange]="true"
										   [dateFormat]="'mm/dd/yyyy'">
							</date-picker-c>
						</div>

					<btn-c [isDisabled]="showGenerateReportPopup.inProcess"
						 (click)="getReport();">
						Generate
					</btn-c>
				</div>
			</div>
			<hr/>
			<div class="flex-column gap-5 f-1-0">
				<!--Scheduled reports-->
				<div style="display: flex; align-items: center;">
					<div class="flex-row gap-2 h100" style="flex:1 0; align-items: center;">
						<div style="flex: 3 0; align-items: center;" class="flxRow hSpace05 h100">
							<span style="font-weight: 500;">Scheduled reports</span>
							<i style="font-weight: 400;" class="fas fa-question-circle" title="Scheduled automatic reports that will be sent to reviewers on a daily, weekly, or monthly basis" title-direction="right"></i>
						</div>
						<div style="flex: 5 0; align-items: flex-start; " class="flxClmn-center vSpace2 h100">
							<div *ngFor="let period of ['daily','weekly','monthly']" class="flxRow-start w100 hSpace2" style="align-items: center;">
								<div class="flxRow hSpace1" style="white-space: nowrap; align-items: center; flex-basis: 165px;">
									<toggle-switch-c id="{{period}}ReportToggle"
													 [hideText]="true"
													 [(model)]="scheduledQuarantinedReports[period].value"
												   (onChange)="updateReportSettings()"></toggle-switch-c>
									<span style="vertical-align: middle; text-transform: capitalize;">{{period}} report</span>
								</div>
								<div class="flxRow" style="flex-direction: column;">
									<span [ngClass]="{'text-success' : scheduledQuarantinedReports[period]?.value}">Report will be sent {{period === 'daily' ? 'each day' : period === 'weekly' ? 'each Monday' : period === "monthly" ? 'on the 1st of each month' : ''}}.</span>
									<span *ngIf="scheduledQuarantinedReports[period].last_report" style="color: green;">(Last report: {{scheduledQuarantinedReports[period].last_report | date:'MM/dd/yyyy HH:mm'}})</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--Add Report Recipients-->
				<div class="flex-row gap-2">
					<div class="flex-column gap-2" style="flex: 3 0;">
						<b>Add Report Recipients (optional)</b>
						<span class="text-muted lh-base" style="font-size: 0.9em;">Add the specific addresses to which you would like the report to be sent, in addition to reviewers.</span>
					</div>
					<div class="content-container flex-column gap-2" style="flex: 5 0;">
						<div class="flex-row gap-1">
							<input type="text" style="width: 300px; margin: 0;"
								   placeholder="Add"
								   (keyup.enter)="addReportAddress(reportAddresses);"
								   [(ngModel)]="reportAddresses">
							<btn-c style="min-width: 0;"
								   [endIcon]="'zmdi zmdi-check'"
								   [isDisabled]="updateReportAddressesInProcess || !reportAddresses"
								   (action)="addReportAddress(reportAddresses);">
							</btn-c>
						</div>

						<div *ngFor="let address of scheduledQuarantinedReports.addresses; trackBy: gs.trackByIndex" style="margin-bottom: 10px;">
							<div class="name ellipsis"
								 style="display: inline-block; width: 310px;">{{address}}</div>
							<a class="closeRed action"
							   (click)="deleteReportAddress(address)"
							   [ngClass]="updateReportAddressesInProcess ? 'closeRed disableAction' : 'approveGreen'">
								<i class="fa fa-times"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</popup-c>

	<popup-c *ngIf="showGenerateReportPopup?.showMailboxes"
			 head="Select Mailboxes" class="overflownV"
			 (closeCb)="showGenerateReportPopup.showMailboxes = null;"
			 (confirmCb)="applyReportMailboxes();"
			 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: showGenerateReportPopup.updateMailboxesInProcess}}" size="medium">
		<div class="flxClmn vSpace3 f-1-0">
			<trustifi-table-c
				class="trustifi-table f-1-0" style="z-index: 5;"
				[list]="showGenerateReportPopup.mailboxes"
				[headers]="[
				{text: 'Email', sortKey: 'email'},
				{text: 'Name', sortKey: 'name'},
			  ]"
				[cellsPrototype]="[
				{textKey: 'email'},
				{textKey: 'name'},
			  ]"
				(searchFunction)="searchMailbox($event)"
				[options]="{
				itemsNameSingular: 'mailbox',
				loadingTableFlag: showGenerateReportPopup.mailboxesInProcess,
				showRadioButtons: true,
			}"
			>
			</trustifi-table-c>
		</div>
	</popup-c>

	<!--Select Report Columns popup-->
	<popup-c *ngIf="showReportColumnsPopup"
		   	head="Select Columns" size="dynamic"
			subTitle="The selected fields will appear in your generated email reports"
			(closeCb)="clearReportColumns();"
			(confirmCb)="setReportFields();"
			 [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">
		<div class="flxClmn vSpace3 f-1-0">
			<div class="flxRow" style="flex-wrap: wrap;">
				<div class="flxRow-start md-checkbox" style="width: 50%;"
					 *ngFor="let field of csvFieldsCopy">
					<input id="{{field.name}}" type="checkbox"
						   [(ngModel)]="field.selected"/>
					<label for="{{field.name}}">{{field.name}}</label>
				</div>
			</div>
			<a class="blue-link" style="margin: 0; text-decoration: underline;"
			   (click)="toggleAllFields()">
				{{selectedAllFields ? 'Deselect': 'Select'}} all
			</a>
		</div>
	</popup-c>
</div>
