<div class="flex-column gap-4 f-1-0">
    <span>Generate reports of user email activity and connections based on your preferences</span>
    <div class="list-group list-group-flush settings-list-container">

        <!--Settings: Report Type -->
        <div class="list-group-item settings-list-item py-4" style="border-bottom: unset!important;">
            <div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
                <b>Report Type</b>
                <span class="text-muted lh-base" style="font-size: 0.9em;">Select which type of report to generate or view</span>
            </div>

            <div class="content-container flex-row gap-4 align-items-center">
                <!--Dropdown -->
                <app-dropdown-c style="height: 25px; min-height: 25px; width: 100%; min-width: fit-content; max-width: 170px;"
                              text="{{activeReportOption.text}}">
                    <li *ngFor="let option of reportOptions"
                        (click)="reportOptionSelect(option)">
                        {{option.text}}
						<i style="font-weight: 400;" class="fas fa-question-circle" tooltip="{{option.tooltip}}"></i>
                    </li>
                </app-dropdown-c>
            </div>
        </div>

        <!--Settings: Date selection -->
        <div class="list-group-item settings-list-item py-4" style="border-bottom: unset!important;">
            <div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
                <b>Date Selection</b>
                <span class="text-muted lh-base" style="font-size: 0.9em;">Select which date of report to generate or view</span>
            </div>

            <!--Connections Date-Selection -->
			<div class="content-container flxRow hSpace1 align-items-center gap-4" style="max-width: 470px;">
				<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
					<span>From:</span>
					<date-picker-c class="f-1-0"
								   [(dateModel)]="reportFromDate"
								   [dateMaxLimit]="reportUntilDate"></date-picker-c>
				</div>
				<div class="flxRow hSpace05 f-1-0" style="align-items: center;">
					<span>Until:</span>
					<date-picker-c class="f-1-0"
								   [(dateModel)]="reportUntilDate"
								   [dateMinLimit]="reportFromDate"></date-picker-c>
				</div>
			</div>
        </div>

        <!--Settings: Fields Selection -->
        <div class="list-group-item settings-list-item py-4" style="border-bottom: unset!important;" *ngIf="activeReportOption.key === 'emailData'">
            <div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
                <b>Fields Selection</b>
                <span class="text-muted lh-base" style="font-size: 0.9em;">Select which fields will be included in the report</span>
            </div>
            <div class="content-container">
                <div tooltip="Click to view and edit fields" tooltipdirection="top">
                    <app-dropdown-c style="width: 365px;"
                                  (click)="openFieldsPopup()"
                                  text="{{selectedFields.join(', ') || 'Select fields'}}"></app-dropdown-c>
                </div>
            </div>
        </div>

        <!--Settings: Users Selection -->
        <div class="list-group-item settings-list-item py-4" style="border-bottom: unset!important;" *ngIf="activeReportOption.key === 'connections'">
            <div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
                <b>Users Selection</b>
                <span class="text-muted lh-base" style="font-size: 0.9em;">Select which users will be included in the report</span>
            </div>
            <div class="content-container">
                <div tooltip="Click to view and edit users selections" tooltipdirection="top">
                    <app-dropdown-c style="width: 365px;"
                                  (click)="openUsersPopup()"
                                  text="{{selectedUsers.length ? selectedUsers.join(', ') : 'Select Users'}}"></app-dropdown-c>
                </div>
            </div>
        </div>

        <!--Settings: Generate/View Graph reports -->
        <div class="list-group-item settings-list-item py-4" style="border-bottom: unset!important;">
            <div class="title-section-container flex-column gap-2" *ngIf="activeReportOption.key === 'emailData'" style="max-width: 20vw;">
                <b>Generate Report</b>
                <span class="text-muted lh-base" style="font-size: 0.9em;">Generate report using the information for the selected users and fields</span>
            </div>
            <div class="title-section-container flex-column gap-2" *ngIf="activeReportOption.key === 'connections'" style="max-width: 20vw;">
                <b>View Graph</b>
                <span class="text-muted lh-base" style="font-size: 0.9em;">A visualization of your users' connection network - red circles are your users and green circles are their connections. The number next to each circle represents how many emails were sent to that connection</span>
            </div>
            <div class="content-container">

                <!--Email Data Report buttons -->
                <div class="flex-row gap-3 animated fadeIn" *ngIf="activeReportOption.key === 'emailData'">
                    <btn-c [isDisabled]="userReportFromDateApplied"
                         (action)="getReport();">
                        Generate
                    </btn-c>
                </div>

                <!--Connections report buttons -->
                <div class="flex-row gap-3 animated fadeIn" *ngIf="activeReportOption.key === 'connections'">
                    <btn-c [loading]="userReportFromDateApplied"
                         endIcon="fa fa-download"
                         (action)="generateUserReportGraph();">
                        View Graph
                    </btn-c>
                    <div class="flxRow-start md-checkbox align-items-center" style="height: 30px;">
                        <input id="NotifyReviewer" required="false" type="checkbox"
                               (change)="userReportGraphData=null"
                               [(ngModel)]="userReportGraphAggregatePerDomain"/>
                        <label class="m-0" for="NotifyReviewer">Aggregate per domain</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

	<popup-c *ngIf="reportUsersGraphPopup?.show"
			 head="Sender Connections Graph"
			 subTitle="A visualization of your users' connection network.<br/>Your users are labeled in green and their connections are in blue. The numbers represent how many emails were sent to that connection. <span class='fw-500'>Hover over a node to show its connections.</span>"
			 (closeCb)="reportUsersGraphPopup = null;"
			 size="fullscreen">
		<div class="flxClmn f-1-0" *ngIf="!reportUsersGraphPopup.loading"
			 [ngClass]="{'bg-light flxRow centralize' : _.isEmpty(reportUsersGraphPopup.graphData)}">
					<span class="animated fadeIn" *ngIf="_.isEmpty(reportUsersGraphPopup.graphData)">
						No emails were sent from the selected users
					</span>

			<connection-graph class="flxClmn f-1-0"
							  [hidden]="_.isEmpty(reportUsersGraphPopup.graphData)"
							  [nodeOptionsFn]="reportUsersGraphPopup.nodeOptionsFn"
							  [focusedNodesNames] = "reportUsersGraphPopup.nodeSearchResults"
							  [rawGraphData]="reportUsersGraphPopup.graphData"></connection-graph>
		</div>
		<!--Loader-->
		<div class="flxClmn centralize f-1-0" *ngIf="reportUsersGraphPopup.loading">
			<loader class="opacity-75" height="80" width="80"></loader>
		</div>
		<!---->
	</popup-c>

	<users-selection-component
		*ngIf="showSelectUsersPopup?.show"
		[data]="showSelectUsersPopup"
		[selectedUsers]="selectedUsers"
		(addCb)="addUsersToSelection($event.users)" (removeCb)="removeUsersFromSelection($event.users)">
	</users-selection-component>

	<!--Report Fields Selection popup-->
    <popup-c *ngIf="showFieldsPopup"
           head="Fields Selection"
           subTitle="Select which fields you want to include in the report:"
           (closeCb)="closeFieldsPopup()"
           (confirmCb)="applyFieldsFromPopup()"
           size="medium"
           [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}">
        <div class="flex-column f-1-0 gap-3">
            <span class="hover-link-color" style="cursor: pointer; width: max-content; align-self: end; color: var(--bs-primary); text-decoration: underline;" (click)="toggleAll()">{{selectedAll ? "Deselect" : "Select"}} all</span>
            <div class="copy-policy-options gap-3 flex-row flex-wrap justify-content-start">
                <div class="policy-option gap-2 f-1-0"
                     *ngFor="let field of csvFieldsCopy; trackBy: gs.trackByIndex"
                     (click)="field.selected = !field.selected; checkSelectAll();"
                     [ngClass]="{'active' : field.selected}"
                     style="flex-basis: 150px; cursor: pointer; max-width: 175px;">
                    <input id="generateFieldSelection" type="checkbox"
                           [(ngModel)]="field.selected"
                           style="accent-color: white; width: 15px;"/>
                    <span class="form-check-label m-0" style="cursor: pointer;">{{field.name}}</span>
                </div>
            </div>
        </div>
    </popup-c>
</div>

