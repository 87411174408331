<!--loader-->
<div class="f-1-0 flex-column centralize" *ngIf="!isThisFeatureSupported">
	<loader height="100" width="100" style="opacity: 0.8;" *ngIf="_.isUndefined(isThisFeatureSupported)"></loader>
	<threat-simulation-not-supported style="display: contents;" *ngIf="isThisFeatureSupported === false"></threat-simulation-not-supported>
</div>

<div class="flex-column gap-4 animated fadeIn" *ngIf="isThisFeatureSupported">
	<span class="lh-base" style="font-size: 0.9em;">
		Enter your company's information to personalize your email templates effortlessly using "Dynamic Fields".
	</span>

	<div class="py-4 px-5 flex-column vSpace3" style="z-index: 1;">
		<!--Company Name-->
		<ng-container *ngTemplateOutlet="tsFieldInput; context: {name: 'Company Name', modelKey: 'company'}"></ng-container>

		<!--Company Domain-->
		<ng-container *ngTemplateOutlet="tsFieldInput; context: {name: 'Company Domain', modelKey: 'company_domain'}"></ng-container>

		<!--Company URL-->
		<ng-container *ngTemplateOutlet="tsFieldInput; context: {name: 'Company URL', modelKey: 'url'}"></ng-container>

		<!--Company Logo-->
		<div class="flxRow hSpace1">
			<div class="flex-column gap-2" style="flex-basis: 130px;">
				<span>Company Logo:</span>
				<span style="font-size: 11px; font-weight: 300;">
					PNG only<br/>
					Optimal size (275x50px)
				</span>
			</div>

			<div class="flex-column centralize gap-2" style="align-items: center;">
				<a ngfSelect accept=".png"
				   [(file)]="logoFile"
				   (fileChange)="uploadLogo()"
				   class="flxClmn vSpace05">
					<div class="flxClmn-center hvr-opacity" style="position: relative; cursor: pointer; align-items: center; border: solid 1px #e3e4e4; background: #fafafa; width: 250px; height: 140px; padding: 10px;"
					[ngClass]="{'validation-error' : missingFields?.length && missingFields.includes(fieldNamesMap.logo) && !threatSimulationConfig.template_fields.logo}">
						<div class="d-flex centralize f-1-0">
							<img *ngIf="threatSimulationConfig.template_fields.logo"
								 [src]="'data:image/png;base64,' + threatSimulationConfig.template_fields.logo"
								 style="height: 75px;"
								 id="upload"/>
							<i *ngIf="!threatSimulationConfig.template_fields.logo"
							   style="font-size: 2.5rem; opacity: 0.1;" class="fas fa-image"></i>
							<i *ngIf="threatSimulationConfig.template_fields.logo"
							   (click)="$event.stopPropagation(); threatSimulationConfig.template_fields.logo = null;"
							   style="position: absolute; top: 0; padding: 5px; right: 0;" class="fa fa-times"></i>
						</div>
						<span style="text-align: center; white-space: nowrap;" class="a-link animated fadeIn"
							  *ngIf="threatSimulationConfig.template_fields.logo">
									change logo
								</span>
						<span class="a-link animated fadeIn" style="opacity: 0.75; text-align: center; white-space: nowrap;"
							  *ngIf="!threatSimulationConfig.template_fields.logo">
									click to upload your logo
								</span>
					</div>
					<span style="align-self: flex-end; color: #555555; font-size: 11px;">{{'\{\{COMPANY_LOGO\}\}'}} in template</span>

				</a>
			</div>
		</div>

		<a class="flex-row gap-2 align-items-center" (click)="showMoreFields = !showMoreFields;">
			<span class="fw-bold">Show advanced fields</span>
			<i class="fa fa-sort-down" [ngStyle]="{'transform' : showMoreFields ? '' : 'rotate(180deg)', 'margin-top' : showMoreFields ? '-5px' : '2px'}"></i>
		</a>

		<ng-container *ngIf="showMoreFields">
			<table class="simpleDataTable" style="table-layout: fixed; align-self: flex-start; width: 100%; max-width: 1000px;">
				<thead><tr>
					<td style="width: 100px;"><b>Role</b></td>
					<td><b>Email</b></td>
					<td><b>First Name</b></td>
					<td><b>Last Name</b></td>
				</tr></thead>
				<tbody>
				<tr *ngFor="let modelName of ['CEO','CTO','CFO','Marketing','Sales','HR','IT team','Security team'];">
					<td style="padding-bottom: 29px; padding-top: 25px;"><b>{{modelName}}</b></td>
					<td>
						<ng-container *ngTemplateOutlet="tsFieldInput; context: {name: modelName, suffix: 'email', modelKey: modelName.toLowerCase().replace(' ','_')}"></ng-container>
					</td>
					<td>
						<ng-container *ngTemplateOutlet="tsFieldInput; context: {name: modelName, suffix: 'first_name', modelKey: modelName.toLowerCase().replace(' ','_')}"></ng-container>
					</td>
					<td>
						<ng-container *ngTemplateOutlet="tsFieldInput; context: {name: modelName, suffix: 'last_name', modelKey: modelName.toLowerCase().replace(' ','_')}"></ng-container>
					</td>
				</tr>
				</tbody>
			</table>
		</ng-container>

		<btn-c (action)="updateThreatSimulationTemplateFields()">
			Update
		</btn-c>
	</div>
</div>

<ng-template #tsFieldInput let-name="name" let-modelKey="modelKey" let-suffix="suffix">
	<div class="flex-row gap-2" style="align-items: center;">
		<span style="flex-basis: 130px;" *ngIf="!suffix">{{name}}:</span>
		<div class="position-relative">
			<input type="text" [id]="('lf ' + name?.toLowerCase() + (suffix ? ' ' + suffix : '') | camelCase)"
				   style="margin: 0; width: 250px; height: 25px;"
				   [ngClass]="{'validation-error' : (isValidationError && suffix === 'email' && threatSimulationConfig.template_fields[modelKey].email && !gs.validateEmail(threatSimulationConfig.template_fields[modelKey].email)) || (missingFields?.length && missingFields.includes(suffix ? fieldNamesMap[modelKey][suffix] : fieldNamesMap[modelKey]) && !(suffix ? threatSimulationConfig.template_fields[modelKey][suffix] : threatSimulationConfig.template_fields[modelKey]))}"
				   [ngModel]="suffix ? threatSimulationConfig.template_fields[modelKey][suffix] : threatSimulationConfig.template_fields[modelKey]"
				   (ngModelChange)="suffix ? threatSimulationConfig.template_fields[modelKey][suffix] = $event : threatSimulationConfig.template_fields[modelKey] = $event"
				   [placeholder]="name + (suffix ? ' ' + suffix.replace('_',' ') : '')">
			<span style="position: absolute; bottom: -17px; right: 0; color: #555555; font-size: 11px;">{{'\{\{' + (suffix ? fieldNamesMap[modelKey][suffix] : fieldNamesMap[modelKey]) + '\}\}'}} in template</span>
		</div>
	</div>
</ng-template>



