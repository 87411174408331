import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../services/routeService";
import {NotificationService} from "../../../services/notificationService";
import {GeneralService} from "../../../services/generalService";
import {DICTIONARY} from "../../../dictionary";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ClipboardService} from 'ngx-clipboard';
import {Router} from "@angular/router";

@Component({
	selector: 'contacts-component',
	templateUrl: './contacts.component.html',
})
export class ContactsComponent implements OnInit, OnDestroy {
    constructor(private clipboard: ClipboardService,
				private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService,
				private router:Router) {
    }
    dic = DICTIONARY;
    mode = [];
    processing = true;
    corpname = this.gs.corpname;
    importContactsFromAddressBook = this.gs.importContactsFromAddressBook;
    cloudspongeLaunch = this.gs.cloudspongeLaunch;
    userInfo;
    loadingContactsInProcess;
    contacts;
    isAddContactActive;

	ngOnInit() {
        this.getContacts();
        this.gs.getUserInfo(false, userInfo => {
            this.userInfo = userInfo;
        });
        this.gs.cloudspongeUpdated = () => this.getContacts();
    };

	ngOnDestroy() {
		this.gs.cloudspongeUpdated = null;
	}

	getContacts = () => {
        this.loadingContactsInProcess = true;
        this.rs.getAllContacts().then((response) => {
            this.loadingContactsInProcess = false;
            this.contacts = response;
        }, (err) => {
            this.loadingContactsInProcess = false;
        });
    }

    searchContacts = (event) => {
        this.contacts.forEach(record => {
            // search
            if (event.searchTerm) {
                const isFound = this.searchTextExecute(record, event.searchTerm);
                if (!isFound && !record.isNew) { // ignore contact in creation state
                    record.hide = true;
                    return;
                }
            }

            // filter
            // // currently no filters for contacts

            record.hide = false;
        });
    }


    showContactActions = () => {
        return [
            this.dic.CONSTANTS.contactActions.edit,
            this.dic.CONSTANTS.contactActions.sendEmail,
            this.dic.CONSTANTS.contactActions.delete
        ]
    }

    selectAction = (contact, action) => {
        switch (action) {
            case this.dic.CONSTANTS.contactActions.delete:
                this.deleteContacts([contact]);
                break;
            case this.dic.CONSTANTS.contactActions.sendEmail:
                this.sendMessageToContacts([contact]);
                break;
            case this.dic.CONSTANTS.contactActions.edit:
                this.startEditContact(contact);
                break;
        }
    }

    selectMultipleAction = (selectedUsers ,action) => {
        switch (action) {
            case this.dic.CONSTANTS.contactActions.delete:
                this.deleteContacts(selectedUsers);
                break;

            case this.dic.CONSTANTS.contactActions.sendEmail:
                this.sendMessageToContacts(selectedUsers);
                break;
        }
    };

    showContactsBulkActions = () => {
        return [
            this.dic.CONSTANTS.contactActions.sendEmail,
            this.dic.CONSTANTS.contactActions.delete
        ]
    }

    sendMessageToContacts = (contacts) => {
        if (!contacts) {
            return;
        }

        const options = {
            contacts: contacts
        }
		this.router.navigate(_.compact([this.dic.CONSTANTS.appStates.personalPages, this.dic.CONSTANTS.personalPages.composeMessage]), {state: {data: options}});
    };

    confirmEdit = (event) => {
		const contact = event.item;
		const isApproved = event.isApproved;

        // edit declined
        if (!isApproved) {
            // new contact process was declined
            if (!contact._id) {
                _.remove<any>(this.contacts,contact);
                this.isAddContactActive = false;
            }
            else { // existed contact (not new)
                // empty the 'edit' property which contains the temp edit values and then close edit mode
                contact.edit = null;
                contact.isEditMode = false;
            }
            return;
        }
        //

        // edit approved

        const err = this.validateEditedOrNewContact(contact);
        if (err) {
            return;
        }

        if (contact._id) {
            this.editContact(contact);
        }
        else {
            this.addNewContact(contact)
        }
    };

    startEditContact = (contact) => {
        contact.edit = {
            name: contact.name,
            email: contact.email,
			mfaPassword: contact.default_password?.password || '',
			mfaHint: contact.default_password?.hint || '',
			phone: _.clone(contact.phone) || {country_code: '', phone_number: ''}
        };

        contact.isEditMode = true;
    }

    editContact = (contact) => {
        contact.edit.default_password = {password: contact.edit.mfaPassword, hint: contact.edit.mfaHint};
		contact.confirmEditInProcess = true;
        this.rs.updateContact(
            contact._id,
            contact.edit)
            .then( (response) => {
				Object.assign(contact, contact.edit);
				if (contact.phone.phone_number) {
					contact.phone.phone_number = contact.phone.phone_number.replace(/^0+/, '');
				}

				if (!contact.name) {
					contact.name = contact.email;
				}
				contact.confirmEditInProcess = false;
				contact.isEditMode = false;
                contact.edit = null;
            }, err => {
				contact.confirmEditInProcess = false;
			});
    }

    startAddContact = () => {
        if (this.isAddContactActive) {
            return;
        }

        this.isAddContactActive = true;

        const newContact = {
            isEditMode: true,
            isNew: true,
            edit: {
                name: '',
                email: '',
				mfaPassword: '',
				mfaHint: '',
                phone: {country_code: '', phone_number: ''},
            }
        };

        this.contacts.unshift(newContact);
    };

    addNewContact = (contact) => {
		contact.confirmEditInProcess = true;
		this.rs.addNewContact({contacts: [contact.edit]})
		.then((response) => {
			Object.assign(contact, contact.edit);
			contact.email = contact.email.toLowerCase();
			if (contact.phone.phone_number) {
				contact.phone.phone_number = contact.phone.phone_number.replace(/^0+/, '');
			}

			contact.edit = null;
			contact.confirmEditInProcess = false;
			contact.isEditMode = false;
			contact.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit
			contact._id = response[0];

			// in case of empty name then backend will return email
			if (!contact.name) {
				contact.name = contact.email;
			}

			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.contactAdded, contact.email));
			this.isAddContactActive = false;
		}, err => {
			contact.confirmEditInProcess = false;
		});
    }

    validateEditedOrNewContact = (contact) => {
        if (!contact.edit.email) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Email field'));
        }
        if (!this.gs.validateEmail(contact.edit.email)) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.invalidEmail, contact.edit.email));
        }
        if (_.find(this.contacts, c => {return c !== contact && c.email === contact.edit.email})) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailAlreadyExist, contact.edit.email));
        }
        if (!contact.edit.mfaPassword && contact.edit.mfaHint) {
            return this.ns.showWarnMessage(this.dic.ERRORS.contactHintWithoutPassword);
        }
    }

    deleteContacts = (contacts) => {
        let contactIds = _.map(contacts,'_id');
        const isDeleteMultiple = contactIds.length > 1;

        this.gs.showPopup({
            title: `Delete Contact${isDeleteMultiple ? 's' : ''}`,
            subTitle: `Please note - ${isDeleteMultiple ? contacts.length : 'The'} selected contact${isDeleteMultiple ? 's' : ''} will be deleted.`,
            body: [`You will not be able to recover a deleted contact${isDeleteMultiple ? 's' : ''}.`],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                contactIds = chunk(contactIds, 50);
                contactIds.forEach((chunkObj) => {
                    this.rs.deleteContact(chunkObj).then((response) => {
                        chunkObj.forEach((contactId) => {
                            let idx = this.contacts.findIndex(itm => itm._id.toString() === contactId);
                            if (idx > -1) {
                                this.contacts.splice(idx, 1);
                            }
                        });

                        this.ns.showInfoMessage(this.dic.MESSAGES.contactDeleted);
                    });
                });
            }
        });
    };

    openUploadListPopup = () => {
        let body : any[] = [
            'File type must be a Comma Separated Value (.csv)',
            'File size up to 1 MB.',
            'The first row must contain the field names',
            'Email addresses should appear in the Email column and only there',
            'Complex text fields (that include spaces or other symbols) must be surrounded with double quotation marks.',
            'Optional - To enable proper use of our "Secure" functionality you need to add the columns: "Name", "Phone Number", "MFA Password", "MFA Hint".',
        ];
        let popupType = this.dic.CONSTANTS.popupInfo;
        this.gs.showPopup({
            title: 'Import List',
            subTitle: 'To ensure '+this.corpname+' can successfully handle your list, it should meet the following conditions:',
            body: body,
            type: popupType,
            doneBtnText: 'OK',
            isFile: true,
            doneCb: (files, options) => {
				if (!files || !files.length) return;

				if (files[0].name && !files[0].name.endsWith(".csv") && !files[0].name.endsWith(".CSV")) {
					this.ns.showErrorMessage("Expected .csv file");
					return;
				}

                this.importContactsFromFile(files[0]);
            }
        });
    };

	importContactsFromFile = (csvFile) => {
		if (!csvFile) {
			console.error('No file provided');
			return;
		}

		const requiredHeaders = ['email'];
		const optionalHeaders = ['name', 'phone number', 'mfa password', 'mfa hint'];
		const validationFuncAlwaysTrue = () => {return true};
		const validationFunctions = [validationFuncAlwaysTrue];
		const contactsToAdd = [];
		const contactsThatAlreadyExist = [];

		this.gs.readCsv(csvFile, requiredHeaders, optionalHeaders, null, validationFunctions, (err, results) => {
			if (err) {
				return;
			}
			if (!results || !results['email'] || !results['email'].length) {
				this.ns.showWarnMessage(DICTIONARY.ERRORS.noNewResult);
				return;
			}

			for (let i = 0; i < results['email'].length; i++) {
				let importedContact:any;
				if (this.gs.validateEmail(results['email'][i])) {
					importedContact = {email: results['email'][i]};

					if (results['name'] && results['name'][i]) {
						importedContact.name = results['name'][i];
					}
					else {
						importedContact.name = results['email'][i];
					}

					if (results['phone number'] && results['phone number'][i] && this.gs.isValidPhoneNumber(results['phone number'][i])) {
						importedContact.phone = {
							country_code: this.gs.separateCountryCodeAndNumber(results['phone number'][i])[0],
							phone_number: this.gs.separateCountryCodeAndNumber(results['phone number'][i])[1]
						}
					}

					importedContact.default_password = {
						password: results['mfa password'] && results['mfa password'][i],
						hint: results['mfa hint'] && results['mfa hint'][i]
					};
				}

				if (importedContact) {
					const isAlreadyExist = _.find(this.contacts, contact => contact.email === importedContact.email);
					if (isAlreadyExist) {
						contactsThatAlreadyExist.push(importedContact.name);
					}
					else {
						contactsToAdd.push(importedContact);
					}
				}
			}

			if (!contactsToAdd.length) {
				if (contactsThatAlreadyExist.length) {
					this.ns.showWarnMessage(util.format(this.dic.ERRORS.multipleContactsExistAlready, 'All'));
				}
				else {
					this.ns.showWarnMessage(DICTIONARY.ERRORS.noNewResult);
				}
				return;
			}

			this.rs.addNewContact({contacts: contactsToAdd})
				.then((response) => {
					for (let i=0; i < response.length; i++) {
						contactsToAdd[i]._id = response[i];
						this.contacts.push(contactsToAdd[i]);
					}

					this.ns.showInfoMessage(this.dic.MESSAGES.contactUpload);

					// show the names of rejected contacts from the csv, unless they are many
					if (contactsThatAlreadyExist.length < 6) {
						for (let i=0; i < contactsThatAlreadyExist.length; i++) {
							this.ns.showWarnMessage(util.format(this.dic.ERRORS.contactExistAlready, contactsThatAlreadyExist[i].name));
						}
					}
					else {
						this.ns.showWarnMessage(util.format(this.dic.ERRORS.multipleContactsExistAlready, contactsThatAlreadyExist.length));
					}
				}, (err) => {
					if (err && err.data && err.data.message && !err.data.display_bar) {
						this.ns.showErrorMessage(err.data.message);
					}
				});
		});
	}

    syncContactsWithAllUsers = () => {
        this.gs.showPopup({
            title: `Sync to all users`,
            subTitle: `Please note - all of your contacts will be added to all users in your plan`,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Sync',
            doneCb: () => {
                this.rs.syncContactsWithAllUsers().then((response) => {
                    this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
                });
            }
        });
    };

    exportToCsv = (sortBy) => {
        if (!this.contacts || !this.contacts.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Name,Email,Phone Number,MFA Password,MFA hint\n";

        let sortedTable = _.filter(this.contacts,contact => {return !contact.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        sortedTable.forEach((contact) => {
            if (!contact.email) {
                return;
            }
            csvString += `"${contact.name}","${contact.email}","${contact.phone && contact.phone.phone_number ? ((contact.phone.country_code + '-' || '') + contact.phone.phone_number) : ''}","${contact.default_password && contact.default_password.password ? contact.default_password.password : ''}","${contact.default_password && contact.default_password.hint ? contact.default_password.hint : ''}"\n`;
        });

        this.gs.exportCsv(csvString, `contacts.csv`);
    };

    searchTextExecute = (contact, searchTerm) => {
        searchTerm = searchTerm.toLowerCase();
        // search in phone number as it is shown on screen
        if (contact.phone && contact.phone.country_code && contact.phone.phone_number) {
            const mixedPhone = '+' + contact.phone.country_code + '-' + contact.phone.phone_number;
            if (mixedPhone.indexOf(searchTerm) > -1) {
                return true;
            }
        }
        //

        return ((contact.email && contact.email.toLowerCase().indexOf(searchTerm) > -1) ||
            (contact.name && contact.name.toLowerCase().indexOf(searchTerm) > -1) ||
            (contact.default_password && contact.default_password.hint && contact.default_password.hint.toLowerCase().indexOf(searchTerm) > -1));
    }

}

const chunk = (array, size) =>
    array.reduce((acc, _, i) => {
        if (i % size === 0) acc.push(array.slice(i, i + size))
        return acc
    }, []);



