import * as util from 'util';
import _ from 'lodash';
import {Buffer} from 'safe-buffer';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit, ViewChild} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {eachLimit} from 'async';
import {Router} from "@angular/router";
import {FilterTableComponent} from "../../../../directives/filterTable/filter-table.component";


@Component({
	selector: 'inbound-quarantined-component',
	templateUrl: './inbound-quarantined.component.html',
})
export class InboundQuarantinedComponent implements OnInit {

	@ViewChild('tableFilter') tableFilter: FilterTableComponent;

    constructor(public gs:GeneralService,
				private ns:NotificationService,
				private rs:RouteService,
				private clipboard: ClipboardService,
				private router:Router) {
    }

    dic = DICTIONARY;
	_=_;
	displayViewAllPlansBtn: boolean = false;
	viewAllPlans: boolean = false;
	viewMyPlanOnly: boolean = false;
	viewPlanList;
    sortCreated = "-created";
    sortMailbox = 'mailbox_email';
    sortSender = 'sender';
    sortSubject = 'subject';
    sortStatus = 'status';
    sortCategory = 'category_priority';
    orderBy = this.sortCreated;
    showAbortLoadingEmailsLabel: boolean;
	abortLoadingFlag: any;
	activeAutoRefreshLoop;
	activeAutoRefreshInterval = 'off';
    currentQuery: any;
	showAutoRefreshMenu = false;
    batchesCounter = 0;
    openPreviewLinkPopup: any;
	previewAttachment: any;
	releaseRejectPopup;
	notifyOptionsPopup: any;
	openAiClassifyEmailPopup: any;
	openAiSaveRecordPopup: any;
	isRecipientChangeNotifyTimeAllowed;
	headerSearchTerm;
	reviewerSearchTerm;
	senderConnectionGraphPopup;
	searchPlanTxt;

	hoursArr = [ "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

    searchPeriod = [
        this.dic.CONSTANTS.trendsPeriod.lastDay,
        this.dic.CONSTANTS.trendsPeriod.last3Days,
        this.dic.CONSTANTS.trendsPeriod.lastWeek,
        this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.last3Months,
		this.dic.CONSTANTS.trendsPeriod.range
    ];

    actionForHeaders = {
        senderEmail: true,
        senderEmailAppearsAs: true,
        senderDomain: false,
        replyToEmail: false,
        replyToDomain: false,
        messageID: false,
        returnPath: false,
        returnPathEmail: false,
    };

    statusPriority = {
        'Malicious': 1,
        'Suspicious': 2,
        'Spam': 3,
        'Graymail': 4,
        'Unsecured': 5,
        'External': 6,
        'Pending': 7,
        'Safe': 8
    };

	queryTypes = {
        normal: 'normal',
        messageIdOnly: 'messageIdOnly'
    }

    reviewerInfo = this.gs.reviewerInfo;
    userInfo;
    multipleEmailsActions;
    groupEmails;
    originalGroupEmails;
    emails;
    activeFilters;
    searchMailboxTxt;
    activeTagAsFilter;
    rules;
    allowReviewerShowContent;
    tpMode;
    isUrlTrackingEnabled;
    queryType;
    quarantinedQuery;
    resultsPeriod;
    range;
    quarantinedQueryDisplay;
    queryValidationErrors;
	showQueryModal = false;
    isQueryError;
    getEmailsInProcess;
    selectedAll;
    selectedEmails;
    selectedEmailsCount;
    filterData;
    showingActionsEmail;
    actionInProcess: boolean;
    curTPResults;
    setCategoryPopup;
    allowlistSenderPopup;
    blocklistSenderPopup;
    senderAuthMethod;
    curEmail;
    loadQuarantineData;
    authSenderInProcess;
    senderAuthPhone;
    showAuthSenderPopup;
    popupError;
    curAction;
    blockSenderAuthRequest;
    urlTrackingPopup;
    setCategoryInProcess;
    showHtml;
    emailInfoPopup;
    addWarningLabel;
    addSubjectText;
    deleteRecord;
    addContactToAdmin;
    reportToTrustifi;
    securityTeamComments;
	threat_body_conf_precentages;
	spam_body_conf_precentages;
	graymail_body_conf_precentages;
	releaseQuarantinedEmailPopup;
	incidentPopup;
	securityReviewerPopup;
	powershellQuarantineEnabled;
	isExchangeConnected;
	mailboxInfo;
	emailsSources = {
		trustifi: 'Trustifi',
		exchange: 'Microsoft Exchange'
	};
	removeQuarantinedPopup;
	isParentQuarantined;
	tpMetrics;
	enableDefangeUrl;

	ngOnInit() {
        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
        });

        this.groupEmails = [];
        this.originalGroupEmails = [];
        this.emails = [];

        this.batchesCounter = 0;
        this.activeFilters = null;
        this.searchMailboxTxt = null;
        this.activeTagAsFilter = null;

        this.initFilters();

        this.rs.getQuarantinedPlanInfo().then((response) => {
			this.rules = response.rules;
			this.allowReviewerShowContent = response.allowReviewerShowContent;
			this.tpMode = response.tpMode;
			this.isUrlTrackingEnabled = response.urlTracking;
			this.isRecipientChangeNotifyTimeAllowed = response.isRecipientChangeNotifyTimeAllowed;
			this.enableDefangeUrl = response.client_defang_malicious_url;
			this.powershellQuarantineEnabled = response.powershellQuarantine;
			this.isExchangeConnected = response.isExchangeConnected;

			this.displayViewAllPlansBtn = this.gs.planAdmins?.length > 1 && this.gs.controlAdmin?.email === this.gs.planAdmins[0].email;
			if (this.displayViewAllPlansBtn) {
				this.viewPlanList = _.cloneDeep(this.gs.planAdmins);
				this.viewMyPlanOnly = true;
				this.viewPlanList[0].selected = true;
				if (!this.gs.planAdmins[0].alloc_plan_id) {
					this.viewPlanList[0].alloc_plan_id = this.userInfo.plan?._id;
				}
			}

			this.tpMetrics = [];
			Object.keys(response.tpMetrics).forEach(key => {
				if (response.tpMetrics[key]) {
					this.tpMetrics.push({sub_status: response.tpMetrics[key].sub_status, description: response.tpMetrics[key].description});
				}
			});

			this.prepareFirstQuery();
        }, (err) => {});

		this.rs.getMailboxInfo().then((response) => {
			this.mailboxInfo = response;
		}, (err) => {});
    };

	prepareFirstQuery = () => {
		// default query
		this.initQuarantinedQuery();

		if (this.setQueryFromOutside()) {
			this.applyQuery();
			return;
		}

		// restore last saved query (only if not redirected to this page with predefined query)
		if (localStorage.quarantinedQuery) {
			this.restoreLastQuarantinedQuery();

			if (!this.isExchangeConnected || !this.powershellQuarantineEnabled) {
				this.quarantinedQuery.source = this.emailsSources.trustifi;
			}
		}

		this.calculateQueryDisplay();
	}

	getPlanIdParams = (email) => {
		if (!this.gs.planAdmins?.length) {
			return {};
		}

		const adminObj = this.gs.planAdmins.find(itm => itm.alloc_plan_id === email.plan_id);
		if (adminObj) {
			return {planId: email.plan_id};
		}
		return {};
	}

	getPlanIdParamsForQuarantineAction = (email) => {
		if (!this.gs.planAdmins?.length) {
			return '';
		}
		const adminObj = this.gs.planAdmins.find(itm => itm.alloc_plan_id === email.plan_id);
		if (adminObj) {
			return `?planId=${email.plan_id}`;
		}

		return '';
	}

    restoreLastQuarantinedQuery = () => {
        // check previous query from localStorage and retrieve it
        let previousQuery;

		try { // "try" in case the parameters won't parse correctly
			previousQuery = JSON.parse(localStorage.quarantinedQuery);
		}
		catch {
			return;
		}

		// if last query saved to localstorage then apply the saved query
		this.queryType = previousQuery.queryType;

		if (this.queryType === this.queryTypes.messageIdOnly) {
			this.quarantinedQuery.message_id = previousQuery.message_id;
		}
		else {
			this.resultsPeriod = previousQuery.period;
			this.range = previousQuery.range;

			this.quarantinedQuery.mailbox_email = previousQuery.mailbox_email;
			this.quarantinedQuery.sender = previousQuery.sender;
			this.quarantinedQuery.subject = previousQuery.subject;
			this.quarantinedQuery.source = previousQuery.source || this.emailsSources.trustifi;

			previousQuery.category && previousQuery.category.split(',').forEach(enabledCategory => {
				this.quarantinedQuery.category[enabledCategory] = true;
			});
			previousQuery.sub_category && previousQuery.sub_category.split(',').forEach(enabledSubCategory => {
				this.quarantinedQuery.sub_category[enabledSubCategory] = true;
			});
			previousQuery.status && previousQuery.status.split(',').forEach(enabledStatus => {
				switch (enabledStatus) {
					case this.dic.CONSTANTS.quarantinedActions.remove.value:
						enabledStatus = 'removed';
						break;

					case this.dic.CONSTANTS.quarantinedActions.release.value:
						enabledStatus = 'released';
						break;
				}

				this.quarantinedQuery.status[`${enabledStatus[0].toUpperCase()}${enabledStatus.slice(1)}`] = true;
			});
		}

	}

    initQuarantinedQuery = () => {
        this.queryType = this.queryTypes.normal;
        this.resultsPeriod = this.dic.CONSTANTS.trendsPeriod.lastDay;
        this.range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()}; // (past one day)

        this.quarantinedQuery = {
			source: this.emailsSources.trustifi,
			mailbox_email: '',
            sender: '',
            subject: '',
            message_id: '',
            category: {
                Malicious: true,
                Suspicious: true,
                Spam: true,
                Graymail: true,
                External: false,
                Safe: false
            },
			sub_category: {
				Allowlist: false,
				Blocklist: false,
				'Business Email Compromise': false,
				Graymail: false,
				Impersonation: false,
				Malware: false,
				Phishing: false,
				Spam: false,
				Spoofing: false,
				'User Preferences': false,
			},
			status: {
				Quarantined: false,
				Released: false,
				Removed: false,
				'Requested release': false
			}
        };
	}

    validateQuery = () => {
        this.queryValidationErrors = [];

        // validation for 'message ID only' query
        if (this.queryType === this.queryTypes.messageIdOnly) {
            if (!this.quarantinedQuery.message_id) {
                //this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, 'Message ID'));
                this.queryValidationErrors.push('message_id');
                return false;
            }
            else {
                return true;
            }
        }
        //

        let isPassed = true;

        // period (range) check
        if (this.gs.equals(this.resultsPeriod, this.dic.CONSTANTS.trendsPeriod.range)) {
            const range:any = {
                start: this.range.start,
                end: this.range.end
            };
            if (range.start > Date.now() || range.start > range.end) {
                this.ns.showWarnMessage(this.dic.ERRORS.adminQuarantinedDate);
                isPassed = false;
            }
        }
        // category check
        const categoriesForSearch = this.getCategoriesForQuery();
        if (!categoriesForSearch) {
            // this.ns.showErrorMessage(this.dic.ERRORS.quarantinedCategoryMissing);
            this.queryValidationErrors.push('category');
            isPassed = false;
        }

        return isPassed;
    }

	getReportedEmailsQuickAction = () => {
		if (!this.validateQuery()) {
			return;
		}

		this.initFilters();

		setTimeout(() => {
			const updateFilterData = [
				{
					key: 'reported by',
					option: 'Reviewer',
					newValue: true
				},
				{
					key: 'reported by',
					option: 'Recipient',
					newValue: true
				},
				{
					key: 'reported by',
					option: 'Third party',
					newValue: true
				},
			];

			this.tableFilter.updateOptions(updateFilterData);

			this.applyQuery();
		});
	}

	getRequestReleaseEmailsQuickAction = () => {
		if (!this.validateQuery()) {
			return;
		}

		this.initFilters();

		setTimeout(() => {
			const updateFilterData = [{
				key: 'status',
				option: 'Requested release',
				newValue: true
			}];

			this.tableFilter.updateOptions(updateFilterData);

			this.applyQuery();
		});
	}

	selectViewPlan = (plan) => {
		// Reset view flags initially: start with both flags set to false
		this.viewMyPlanOnly = this.viewAllPlans = false;

		// Helper function to set selection status for all plans
		const toggleSelection = (status) => {
			this.viewPlanList.forEach(p => p.selected = status);
		};

		switch (plan) {
			case 'all':
				// Determine if we need to select or deselect all plans
				const selectAll = !this.viewPlanList.every(p => p.selected);
				toggleSelection(selectAll);
				this.viewAllPlans = selectAll;

				// If all plans are deselected, select only the first plan and set viewMyPlanOnly flag
				if (!selectAll) {
					this.viewPlanList[0].selected = true;
					this.viewMyPlanOnly = true;
				}
				break;

			case 'onlyMyPlan':
				// Deselect all plans first
				toggleSelection(false);
				// Then select only the first plan and set viewMyPlanOnly flag
				this.viewPlanList[0].selected = true;
				this.viewMyPlanOnly = true;
				break;

			default:
				// Toggle the selection status of the specific plan
				plan.selected = !plan.selected;

				// If no plans are selected, default to selecting the first plan and set viewMyPlanOnly flag
				if (!this.viewPlanList.some(p => p.selected)) {
					this.viewPlanList[0].selected = true;
					this.viewMyPlanOnly = true;
				} else {
					// Check if only the first plan is selected to update viewMyPlanOnly
					this.viewMyPlanOnly = this.viewPlanList[0].selected && this.viewPlanList.slice(1).every(p => !p.selected);

					// Check if all plans are selected to update viewAllPlans
					this.viewAllPlans = this.viewPlanList.every(p => p.selected);
				}
				break;
		}
	};


	selectMainSource = (selectedSource) => {
		if (selectedSource === this.emailsSources.exchange && !this.powershellQuarantineEnabled) {
			this.gs.showPopup({
				title: 'Microsoft Exchange Quarantine',
				subTitle: 'Note: Synchronization with Exchange not enabled',
				body: ['To pull and display quarantined email data from Microsoft Exchange, you must first enable the PowerShell integration and the "Synchronize Exchange Server Quarantined Emails" setting'],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Go to integrations page',
				doneCb: () => {
					this.router.navigate([this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.plan, this.dic.CONSTANTS.inboundPlanSettingsPageTabs.integrations], {state: {integrationType: this.dic.CONSTANTS.integrationType.powershell}});
				}
			});
			return;
		}

		this.quarantinedQuery.source = selectedSource;
	}

    // 1. send query to validation. 2. create query string for query box
    applyQuery = () => {
        if (!this.validateQuery()) {
            return;
        }

        this.quarantinedQuery.sender = this.quarantinedQuery.sender && this.quarantinedQuery.sender.toLowerCase();
        this.quarantinedQuery.mailbox_email = this.quarantinedQuery.mailbox_email && this.quarantinedQuery.mailbox_email.toLowerCase();

        this.calculateQueryDisplay();

		this.currentQuery = this.getQueryForQuarantine();

        this.showQueryModal = false;
        this.isQueryError = false;

        this.getQuarantinedEmails();
    }

	setQueryFromOutside = () => {
		// predefined message-id-based query
		const urlQuery = new URLSearchParams(location.search.substring(1) || '');
		let message_id = urlQuery.get('message_id') || window.history.state.quarantinedMessageId;
		if (message_id) {
			// Replace all white spaces with '+'
			message_id = message_id.replace(/\s+/g, '+');
			this.queryType = this.queryTypes.messageIdOnly;
			this.quarantinedQuery.message_id = message_id;
			return true;
		}

		// predefined query from WA
		if (window.history.state.data) {

			this.resultsPeriod = window.history.state.data.period || this.dic.CONSTANTS.trendsPeriod.lastWeek;
			this.range = window.history.state.data.range || this.range;
			this.searchMailboxTxt = window.history.state.data.search || '';
			this.quarantinedQuery.mailbox_email = window.history.state.data.mailbox || this.quarantinedQuery.mailbox_email;
			this.quarantinedQuery.sender = window.history.state.data.sender || '';
			this.quarantinedQuery.subject = window.history.state.data.subject || '';

			if (window.history.state.data.category?.length) {
				initializeValues(this.quarantinedQuery.category);
				window.history.state.data.category.forEach((category) => {
					this.quarantinedQuery.category[category] = true;
				});
			}

			if (window.history.state.data.sub_category?.length) {
				initializeValues(this.quarantinedQuery.sub_category);
				window.history.state.data.sub_category.forEach((sub_category) => {
					this.quarantinedQuery.sub_category[sub_category] = true;
				});
			}

			if (window.history.state.data.status?.length) {
				initializeValues(this.quarantinedQuery.st);
				window.history.state.data.status.forEach((status) => {
					this.quarantinedQuery.status[status] = true;
				});
			}

			return true;
		}
	}

    // determine how query properties look in the UI, in the query box
    calculateQueryDisplay = () => {
		this.quarantinedQueryDisplay = [
			{
				title: this.resultsPeriod.display,
				tooltip: this.resultsPeriod === this.dic.CONSTANTS.trendsPeriod.range ? 'From ' + this.range.start + ' until ' + this.range.end : this.resultsPeriod.display
			},
			..._.map(this.quarantinedQuery, (value, key) => {
				const formattedKey = key.replace('_', ' ').replace('id', 'ID');

				let truthyValuesList;
				if (['category', 'status', 'sub_category'].includes(key)) {
					truthyValuesList = _.keys(_.pickBy(value)).join(', ');
				}

				return {
					title: formattedKey,
					tooltip: _.isUndefined(truthyValuesList) ? value : truthyValuesList
				}
			})
		];


		// messageId or normal query
		if (this.queryType === this.queryTypes.messageIdOnly) {
			this.quarantinedQueryDisplay = _.filter(this.quarantinedQueryDisplay, {title: 'message ID'});
		}
		else {
			this.quarantinedQueryDisplay = _.reject(this.quarantinedQueryDisplay, {title: 'message ID'});
		}

		// clean empty parameters
		this.quarantinedQueryDisplay = _.filter(this.quarantinedQueryDisplay, parameter => !!parameter && !_.isEmpty(parameter.tooltip));
    }

    getQueryForQuarantine = () => {
        if (this.queryType ===  this.queryTypes.messageIdOnly) {
            return {
                message_id: this.quarantinedQuery.message_id || '',
				source: this.quarantinedQuery.source || this.emailsSources.trustifi
            }
        }
        else {
            return {
                size: this.dic.CONSTANTS.quarantinedEmailsFirstBatchSize,
                period: this.resultsPeriod.value,
                range: this.range,
                category: this.getCategoriesForQuery(),
				status: this.getStatusForQuery(),
				sub_category: this.getSubCategoryForQuery(),
				mailbox_email: this.quarantinedQuery.mailbox_email || '',
                sender: this.quarantinedQuery.sender || '',
                subject: this.quarantinedQuery.subject || '',
				source: this.quarantinedQuery.source || this.emailsSources.trustifi
            };
        }
    }

    getQuarantinedEmails = () => {
        if (this.getEmailsInProcess) {
            return;
        }

        this.groupEmails = [];
        this.originalGroupEmails = [];
        this.selectedAll = false;
        this.selectedEmails = [];
        this.selectedEmailsCount = 0;

		if (this.viewAllPlans) {
			this.currentQuery.allPlans = true;
		} else if (!this.viewMyPlanOnly && this.viewPlanList) {
			this.currentQuery.view_plans = this.viewPlanList.filter(p => p.selected).map(p => p.alloc_plan_id);
		}

        const paramsToLocalStorage = _.clone(this.currentQuery);
        paramsToLocalStorage['queryType'] = this.queryType;
        paramsToLocalStorage['period'] = this.resultsPeriod; // (the already existed 'period' property in 'params' is only the value, not the desired period object)
        localStorage['quarantinedQuery'] = JSON.stringify(paramsToLocalStorage); // Object

        this.getEmailsInProcess = true;

		switch (this.currentQuery.source) {
			case this.emailsSources.trustifi:
				this.batchesCounter = 0;

				this.rs.getQuarantinedEmails(this.currentQuery).then((quarantineInfo) => {
					this.batchesCounter++;
					this.emails = quarantineInfo.emails || [];

					this.prepareEmails(quarantineInfo.emails);
					this.showAbortLoadingEmailsLabel = true;

					this.getNextEmailsBatch(quarantineInfo, () => {
						this.getEmailsInProcess = false;
						this.showAbortLoadingEmailsLabel = false;
					});
				}, (err) => {
					this.getEmailsInProcess = false;
				});
				break;

			case this.emailsSources.exchange:
				this.ns.showInfoMessage('Fetching email data from Exchange, this action could take some time');
				this.rs.exchangeGetQuarantinedEmails(this.currentQuery).then(data => {
					this.emails = data.emails || [];
					this.prepareEmails(data.emails);

					this.getEmailsInProcess = false;
				}, () => {
					this.getEmailsInProcess = false;
				});
		}
    };

    getNextEmailsBatch = (quarantineInfo, cb) => {
        if (!quarantineInfo.lastCreated) {
            return cb();
        }

        if (this.abortLoadingFlag) {
            this.abortLoadingFlag = false;
			return;
        }

        this.batchesCounter++;

        this.currentQuery.size = this.dic.CONSTANTS.quarantinedEmailsBatchSize;
        this.currentQuery.lastCreated = quarantineInfo.lastCreated;

        this.rs.getQuarantinedEmails(this.currentQuery).then((quarantineInfo) => {
            if (quarantineInfo.emails && quarantineInfo.emails.length) {
                this.emails = this.emails.concat(quarantineInfo.emails);
                this.prepareEmails(quarantineInfo.emails);
            }

            this.getNextEmailsBatch(quarantineInfo, cb);
        });
    }

    autoRefreshExecute = () => {
        // if emails are in process then do not auto refresh yet
        if (this.getEmailsInProcess || this.currentQuery.source === this.emailsSources.exchange) {
            return true;
        }

        delete this.currentQuery.lastCreated;
        if (this.emails?.length && !this.currentQuery.firstCreated) {
            this.currentQuery.firstCreated = this.emails[0].created;
        }
        this.rs.getQuarantinedEmails(this.currentQuery).then((quarantineInfo) => {
            if (quarantineInfo.emails?.length) {
                this.currentQuery.firstCreated = quarantineInfo.emails[0].created;

                this.emails = this.emails.concat(quarantineInfo.emails);
                this.prepareEmails(quarantineInfo.emails);
				this.sortCreated='-created';
				this.groupEmails = _.orderBy(this.groupEmails, ['created'], ['desc']);
            }
        });
    }

    abortLoadingEmails = () => {
        this.abortLoadingFlag = true;
        this.getEmailsInProcess = false;
        this.showAbortLoadingEmailsLabel = false;
    }

    prepareEmails = (emails) => {
        if (!emails || !emails.length) return;
        _.each(emails, email => {
            this.calculateTags(email);
            this.setEmailCategory(email);
        });
        this.prepareEmailGroups(emails);
    }

    initAutoRefresh = (interval) => {
        this.activeAutoRefreshInterval = interval;
        this.showAutoRefreshMenu = false;

        switch (interval) {
            case '30sec':
                this.activeAutoRefreshLoop = setInterval(() => {
                    this.autoRefreshExecute();
                }, 30000);
                break;

            case '60sec':
                this.activeAutoRefreshLoop = setInterval(() => {
                    this.autoRefreshExecute();
                }, 60000);
                break;

            case 'off':
                clearInterval(this.activeAutoRefreshLoop);
                this.activeAutoRefreshLoop = null;
                break;
        }
    }

    prepareEmailGroups = (emails) => {
        const groupEmailsMessageId = this.currentQuery.source === this.emailsSources.trustifi ? _.groupBy(emails, 'message_id') : _.groupBy(emails, '_id');
        let count = this.originalGroupEmails ? this.originalGroupEmails.length : 0;
        let groupEmailsMessageIdKeys = Object.keys(groupEmailsMessageId);
        for (let i = 0; i < groupEmailsMessageIdKeys.length; i++) {
            let emailsGroup = groupEmailsMessageId[groupEmailsMessageIdKeys[i]];

            let closeGroup = (parentEmailGroup) => {
                if (parentEmailGroup.emails?.length) {
                    // general data for showing the group as message in the UI
                    parentEmailGroup.created = parentEmailGroup.emails[0].created;
                    parentEmailGroup.subject = parentEmailGroup.emails[0].subject;
                    parentEmailGroup.source = parentEmailGroup.emails[0].source;
                    parentEmailGroup.sender = parentEmailGroup.emails[0].sender;
                    parentEmailGroup.attachments = parentEmailGroup.emails[0].attachments;
					parentEmailGroup.links = parentEmailGroup.emails[0].links;
                    parentEmailGroup.message_id = parentEmailGroup.emails[0].message_id;
					parentEmailGroup.plan_id = parentEmailGroup.emails[0].plan_id;

					parentEmailGroup.reviewed_completed = false;
                    calcParentEmailCategory(parentEmailGroup);

                    _.each(parentEmailGroup.emails, emailObj => {
                        emailObj.parent_id = parentEmailGroup._id;
						emailObj.plan_id = parentEmailGroup.plan_id;
						parentEmailGroup.mailbox_email.push(emailObj.mailbox_email);
                        if (emailObj.auth_sender && emailObj.auth_sender.status) {
                            parentEmailGroup.auth_sender = emailObj.auth_sender;
                        }
                        parentEmailGroup.reviewed_completed = parentEmailGroup.reviewed_completed || emailObj.reviewed_completed;
                    });

                    this.calculateTags(parentEmailGroup);

                    this.groupEmails.push(parentEmailGroup);
                    this.originalGroupEmails.push(parentEmailGroup);
                }
            }

            // check if related an existing parent group
            const emailParentObj = this.isEmailParentExist(emailsGroup[0]);
            if (emailParentObj) {
                emailsGroup.forEach((emailObj) => {
                    emailObj.parent_id = emailParentObj._id;
                    emailParentObj.mailbox_email.push(emailObj.mailbox_email);
                    if (emailObj.auth_sender && emailObj.auth_sender.status) {
                        emailParentObj.auth_sender = emailObj.auth_sender;
                    }
                    emailParentObj.reviewed_completed = emailParentObj.reviewed_completed || emailObj.reviewed_completed;
                    emailParentObj.emails.push(emailObj);
                    calcParentEmailCategory(emailParentObj);
                });
            }
            else {
                // not relates - push it and start new group
                const parentEmailGroup = {mailbox_email: [], emails: emailsGroup, isOpen: false, parent: true, _id: ++count};
                closeGroup(parentEmailGroup);
            }
        }

        if (this.activeFilters || this.searchMailboxTxt || this.activeTagAsFilter) {
            window.scrollTo({top: 100, behavior: "smooth"});
            this.searchFilterMailbox(this.searchMailboxTxt);
        }
    }

    isEmailParentExist = (emailObj) => {
		if (emailObj.source === this.emailsSources.exchange) {
			return false;
		}
        return this.groupEmails.find(itm => itm.message_id === emailObj.message_id || (
            itm.sender === emailObj.sender && itm.subject === emailObj.subject
            && diffMinutes(itm.created, emailObj.created) < 3
        ));
    }

    showHideSubMailboxes = (parentEmailObj, forceShow = null) => {
        if (!parentEmailObj.emails || !parentEmailObj.emails.length) {
            return;
        }

        parentEmailObj.showSubMailboxes = forceShow || !parentEmailObj.showSubMailboxes;

        // update the original list to restore "showSubMailboxes" state after a search/filter
        const emailObjInOriginal = _.find(this.originalGroupEmails, emailOriginal => parentEmailObj._id === emailOriginal._id);
        if (emailObjInOriginal) {
            emailObjInOriginal.showSubMailboxes = parentEmailObj.showSubMailboxes;
        }
        //

        const index = this.groupEmails.findIndex((emailObj) => emailObj._id === parentEmailObj._id);
        // show
        if (parentEmailObj.showSubMailboxes) {
            parentEmailObj.emails.forEach(childEmailObj => {
                childEmailObj.selected = parentEmailObj.selected;
            });
            this.groupEmails.splice(index + 1, 0, ...parentEmailObj.emails);
        }
        // hide
        else {
            this.groupEmails.splice(index + 1, parentEmailObj.emails.length);
        }
    }

    changePeriod = (period) => {
        this.resultsPeriod = period;
    };

    getCategoriesForQuery:any = () => {
        let categoriesForSearch = [];
        for (let category in this.quarantinedQuery.category) {
            if (this.quarantinedQuery.category[category]) {
                categoriesForSearch.push(category);
            }
        }

        return categoriesForSearch.join(',');
    }

	closeQuarantinedPopup = () => {
		this.headerSearchTerm = '';
		this.reviewerSearchTerm = '';
		this.searchHeader();
		this.searchReviewer();
		this.emailInfoPopup.show = false;
	}

	getStatusForQuery:any = () => {
		let statusForSearch = [];
		for (let status in this.quarantinedQuery.status) {
			if (this.quarantinedQuery.status[status]) {
				switch (status) {
					case 'Quarantined':
						statusForSearch.push(this.dic.CONSTANTS.quarantinedActions.quarantine.value);
						break;
					case 'Released':
						statusForSearch.push(this.dic.CONSTANTS.quarantinedActions.release.value);
						break;
					case 'Removed':
						statusForSearch.push(this.dic.CONSTANTS.quarantinedActions.remove.value);
						break;
					case 'Requested release':
						statusForSearch.push(this.dic.CONSTANTS.quarantinedActions.requestRelease.value);
						break;
				}
			}
		}

		return statusForSearch.join(',');
	}

	getSubCategoryForQuery:any = () => {
		let subCategoriesForSearch = [];
		for (let category in this.quarantinedQuery.sub_category) {
			if (this.quarantinedQuery.sub_category[category]) {
				subCategoriesForSearch.push(category);
			}
		}

		return subCategoriesForSearch.join(',');
	}


	closeOtherActions = (email) => {
        if (!email) {
            return;
        }

        // close the previous email which currently shows actions
        if (this.showingActionsEmail) {
            if (this.showingActionsEmail._id === email._id) {
                return;
            }
            else {
                this.closeItemActions(this.showingActionsEmail);
            }
        }
        this.showingActionsEmail = email;
    };

    closeItemActions = (currentEmail) => {
        if (!currentEmail) {
            return;
        }
        currentEmail.showActions = false;
        currentEmail.actions = [];
    }

    filterDuplicateSafeLinks = () => {
        let linksMap = {};
        this.curTPResults.resUrl.list = _.filter(this.curTPResults.resUrl.list, link => {
            if (link.name) {
                if (linksMap[link.name]) {
                    if (link.status === this.dic.CONSTANTS.threatProtection.status.safe) {
                        return false;
                    }
                }
                else {
                    if (link.status !== this.dic.CONSTANTS.threatProtection.status.safe) {
                        linksMap[link.name] = link.url;
                    }
                    // safe duplicates
                    else {
                        if (link.name === link.url &&
                            _.find<any>(this.curTPResults.resUrl.list, l => l.status === this.dic.CONSTANTS.threatProtection.status.safe && l.name === link.name && l.url !== link.url)) {
                            return false;
                        }
                    }
                }
            }
            return true;
        });
    };

    isDeleteActionAllowed = () => {
        return this.userInfo.user_role === this.dic.CONSTANTS.userRole.admin ||
            this.userInfo.user_role === this.dic.CONSTANTS.userRole.subAdmin;
    }

	showMultipleQuarantinedActions = () => {
		if (this.currentQuery.source === this.emailsSources.trustifi) {
			return _.compact([
				this.dic.CONSTANTS.quarantinedActions.release.display,
				this.dic.CONSTANTS.quarantinedActions.remove.display,
				this.dic.CONSTANTS.quarantinedActions.setCategory.display,
				this.dic.CONSTANTS.quarantinedActions.trustSender.display,
				this.dic.CONSTANTS.quarantinedActions.toggleKeepForever.display,
				this.dic.CONSTANTS.quarantinedActions.handled.display,
				this.isDeleteActionAllowed() && this.dic.CONSTANTS.quarantinedActions.delete.display,
			]);
		}
		else if (this.currentQuery.source === this.emailsSources.exchange) {
			return _.compact([
				this.dic.CONSTANTS.quarantinedActions.release.display,
				this.isDeleteActionAllowed() && this.dic.CONSTANTS.quarantinedActions.delete.display,
			]);
		}
	}

    showQuarantinedActions = (emailObj) => {
        emailObj.showActions = !emailObj.showActions;
        if (emailObj.showActions) {
			if (emailObj.source === this.emailsSources.exchange) {
				emailObj.actions = _.compact([
					this.dic.CONSTANTS.quarantinedActions.release.display,
					this.allowReviewerShowContent && this.dic.CONSTANTS.quarantinedActions.eml.display,
					this.isDeleteActionAllowed() && this.dic.CONSTANTS.quarantinedActions.delete.display,
				]);
				this.closeOtherActions(emailObj);
				return;
			}

            emailObj.actions = [
                this.dic.CONSTANTS.quarantinedActions.viewInfo.display,
                this.dic.CONSTANTS.quarantinedActions.release.display,
                this.dic.CONSTANTS.quarantinedActions.setCategory.display,
                this.dic.CONSTANTS.quarantinedActions.trustSender.display,
            ];

            if (this.isDeleteActionAllowed()) {
                emailObj.actions.push(this.dic.CONSTANTS.quarantinedActions.delete.display);
            }

            if (this.allowReviewerShowContent) {
                emailObj.actions.push(this.dic.CONSTANTS.quarantinedActions.eml.display);
            }

            switch (emailObj.status) {
                case this.dic.CONSTANTS.quarantinedActions.releaseFailure.value:
                case this.dic.CONSTANTS.quarantinedActions.release.value:
                    emailObj.actions.push(this.dic.CONSTANTS.quarantinedActions.remove.display);
                    break;
            }

            this.closeOtherActions(emailObj);
        }
    };

    selectAction = (email, action) => {
        switch (action) {
            case this.dic.CONSTANTS.quarantinedActions.viewInfo.display:
                this.viewQuarantineInfo(email);
                break;

            case this.dic.CONSTANTS.quarantinedActions.review.display:
                this.previewQuarantinedEmailContent(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.headers.display:
                this.previewQuarantinedEmailHeaders(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.whitelist.display:
                this.addSenderWhiteListPopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.blacklist.display:
                this.addSenderBlackListPopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.linksWhitelist.display:
                this.addLinksWhitelistPopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.eml.display:
                this.downloadEml(email);
                return;

			case this.dic.CONSTANTS.quarantinedActions.requestedReleaseReject.display:
				this.requestedReleaseRejectPopup(email);
				return;

            case this.dic.CONSTANTS.quarantinedActions.release.display:
                this.showReleaseQuarantinedEmailPopup([email]);
                return;

			case this.dic.CONSTANTS.quarantinedActions.requestRelease.display:
				this.requestReleaseQuarantinedEmailPopup(email);
				return;

            case this.dic.CONSTANTS.quarantinedActions.remove.display:
                this.removeQuarantinedEmailPopup([email]);
                return;

            case this.dic.CONSTANTS.quarantinedActions.delete.display:
                this.deleteQuarantinedEmailPopup([email]);
                return;

            case this.dic.CONSTANTS.quarantinedActions.authSender.display:
                this.authSenderPopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.signature.display:
                this.signaturePopup(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.allowFileType.display:
                this.allowBlockedFileType(email);
                return;

            case this.dic.CONSTANTS.quarantinedActions.setCategory.display:
                this.openSetCategoryPopup(email);
                return;

			case this.dic.CONSTANTS.quarantinedActions.rescan.display:
				this.openRescanEmailPopup(email);
				return;

            case this.dic.CONSTANTS.quarantinedActions.toggleKeepForever.display:
                this.keepForeverPopup([email]);
                return;

            case this.dic.CONSTANTS.quarantinedActions.trustSender.display:
                this.addContactToAdminPopup([email]);
                return;

            case this.dic.CONSTANTS.quarantinedActions.handled.display:
                this.setHandledStatusExecute(email, !email.reviewed_completed, () => {});
                return;



            // security reviewer action
            case this.dic.CONSTANTS.quarantinedActions.securityReviewerGlobalSender.display:
                this.createBlockGlobalSenderPopup(email);
                return;

            // security reviewer action
            case this.dic.CONSTANTS.quarantinedActions.createIncident.display:
                this.createIncidentPopup(email);
                return;

            // security reviewer action
            case this.dic.CONSTANTS.quarantinedActions.securityReviewerActivity.display:
                this.createSecurityReviewerActivityPopup(email);
                return;

			case this.dic.CONSTANTS.quarantinedActions.classifyEmail.display:
				this.showOpenAiClassifyEmailPopup(email);
				return;

			// openai action
			case this.dic.CONSTANTS.quarantinedActions.saveRecord.display:

				this.showOpenAiSaveRecordPopup(email);
				return;
        }
    };

    selectMultipleEmailsAction = (action) => {
        if (!this.selectedEmails || !this.selectedEmails.length) {
            return;
        }

        switch (action) {
            case this.dic.CONSTANTS.quarantinedActions.release.display:
                this.showReleaseQuarantinedEmailPopup(this.selectedEmails);
                return;

            case this.dic.CONSTANTS.quarantinedActions.remove.display:
                this.removeQuarantinedEmailPopup(this.selectedEmails);
                return;

            case this.dic.CONSTANTS.quarantinedActions.setCategory.display:
                this.openSetMultipleCategoryPopup();
                return;

            case this.dic.CONSTANTS.quarantinedActions.handled.display:
                const emailsCount = this.selectedEmails.length;
                this.setMultipleHandledStatus(this.selectedEmails, 0, true, () => {
                    this.ns.showInfoMessage(`${emailsCount} emails were set as handled`);
                });
                return;

			case this.dic.CONSTANTS.quarantinedActions.toggleKeepForever.display:
				this.keepForeverPopup(this.selectedEmails);
				return;

            case this.dic.CONSTANTS.quarantinedActions.delete.display:
                this.deleteQuarantinedEmailPopup(this.selectedEmails);
                return;

            case this.dic.CONSTANTS.quarantinedActions.trustSender.display:
                this.addContactToAdminPopup(this.selectedEmails);
                return;
        }

    };

    openSetMultipleCategoryPopup = () => {
        this.setCategoryPopup = {
            newCategory: this.selectedEmails[0].category,
            multiple: true,
            reportToTrustifi: true,
            reason: '',
            show: true
        };
    }

    copyItemToClipboard = (item) => {
        this.clipboard.copy(item);
    };

    setInboundStatusStyles = (status) => {
        switch (status) {
            case this.dic.CONSTANTS.threatProtection.status.malicious:
                return {color: 'darkred', icon: 'fa-bug', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.suspicious:
                return {color: 'darkred', icon: 'fa-bug', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.error:
                return {color: 'darkred', icon: 'fa-exclamation-circle', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.spam:
                return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.grayMail:
                return {color: 'dimgray', icon: 'fa-exclamation-circle', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.unsecured:
                return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false};

            case this.dic.CONSTANTS.threatProtection.status.pending:
            case this.dic.CONSTANTS.threatProtection.status.analyzing:
            case this.dic.CONSTANTS.threatProtection.status.analyzingLinks:
                return {color: 'darkorange', icon: 'fa-clock', loading: true};

            case this.dic.CONSTANTS.threatProtection.status.safe:
                return {color: 'darkgreen', icon: 'fa-check-circle', loading: false};

            case 'External':
                return {color: 'darkorange', icon: 'fa-external-link-alt', loading: false};

            default:
                return {color: 'darkorange', icon: 'fa-exclamation-circle', loading: false};
        }
    };

    getLinkTooltip = (url) => {
        let result = url.status;
        if (url.status !== this.dic.CONSTANTS.threatProtection.status.safe && url.name !== url.url) {
            result += '. This safe URL is hiding an unsafe link';
        }
        return result;
    };

    authenticateSender = () => {
        let actionData:any = {
            action: this.dic.CONSTANTS.quarantinedActions.authSender.value,
            authSenderMethod: this.senderAuthMethod
        };

        let notificationTxt = actionData.authSenderMethod;
        if (actionData.authSenderMethod === this.dic.CONSTANTS.authenticateSenderMethods.phone) {
            actionData.phone = this.senderAuthPhone;
            notificationTxt = 'SMS';
        }
        this.authSenderInProcess = true;
        this.loadQuarantineData = true;
        const emailId = getEmailId(this.curEmail);
        this.rs.doActionOnQuarantinedEmail(emailId, actionData, this.getPlanIdParamsForQuarantineAction(this.curEmail)).then((response) => {
            this.loadQuarantineData = false;
            this.curEmail.auth_sender = {
                updated: new Date(),
                method: this.senderAuthMethod
            };
            if (this.curEmail.auth_sender.method === this.dic.CONSTANTS.authenticateSenderMethods.phone) {
                this.curEmail.auth_sender.phone = this.senderAuthPhone;
            }

            this.authSenderInProcess = false;
            this.showAuthSenderPopup = false;
            this.addReviewerAction(this.curEmail, response.reviewerActivity);
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.quarantinedAuthSender, notificationTxt));
            this.popupError = this.ns.getCurrentMessage();
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            this.authSenderInProcess = false;
            this.loadQuarantineData = false;
        });
    };

    setMultipleHandledStatus = (emails, idx, enabled, cb) => {
        if (idx < emails.length) {
            this.setHandledStatusExecute(emails[idx], enabled, () => {
                this.setMultipleHandledStatus(emails, idx + 1, enabled, cb);
            });
        }
        else {
            cb();
        }
    };

    setHandledStatusExecute = (email, enabled, cb) => {
        if (!email) {
            return;
        }
        if (email.parent) {
            this.setMultipleHandledStatus(email.emails, 0, enabled, () => {
                email.reviewed_completed = enabled;
                cb();
            });
        }
        else {
            const data = {
                action: this.dic.CONSTANTS.quarantinedActions.handled.value,
                enabled: enabled
            };
            const emailId = getEmailId(email);
            this.loadQuarantineData = true;
            this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
                this.addReviewerAction(email, `Set the email as ${data.enabled ? '' : 'not '}handled`);
                email.reviewed_completed = data.enabled;
                this.loadQuarantineData = false;
                cb();
            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                cb();
            });
        }
    };

    addContactToAdminPopup = (emails) => {
        if (!emails.length) {
            return;
        }

        let subTitle;
        if (emails.length > 1) {
            subTitle = `Senders will be trusted for all users in the plan. Emails from trusted senders are less likely to be considered as spam, graymail, or BEC by the AI`;
        }
        else {
            subTitle = `Sender ${emails[0].sender} will be trusted for all users in the plan. Emails from trusted senders are less likely to be considered as spam, graymail, or BEC by the AI`;
        }

        this.gs.showPopup({
            title: `Trust sender`,
            subTitle: subTitle,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Confirm',
            doneCb: () => {
                this.addMultipleContactToAdminExecute(emails, 0, () => {});
            }
        });
    };

    addMultipleContactToAdminExecute = (emails, idx, cb) => {
        if (idx < emails.length) {
            this.addContactToAdminPopupExecute(emails[idx], () => {
                this.addMultipleContactToAdminExecute(emails, idx + 1, cb);
            });
        }
        else {
            cb();
        }
    };

    addContactToAdminPopupExecute = (email, cb) => {
        const data = {
            action: this.dic.CONSTANTS.quarantinedActions.trustSender.value
        };
        const emailId = getEmailId(email);
        this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
            this.addReviewerAction(email, response.reviewerActivity);
			this.ns.showInfoMessage(`Operation completed successfully`);
			cb();
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            cb();
        });
    };

    keepForeverPopup = (emails) => {
		if (emails.length === 1) {
			const email = emails[0];
			let title, subTitle;
			if (email.keep_forever) {
				title = 'Do Not Preserve Quarantined Email Record';
				subTitle = `Please note - this email will be automatically deleted after the ${this.rules[email.malicious.status.toLowerCase()].retention_period}-day retention period.`;
			} else {
				title = 'Preserve Quarantined Email Record';
				subTitle = `Please note - this email will not be automatically deleted after the ${this.rules[email.malicious.status.toLowerCase()].retention_period}-day retention period.`;
			}

			this.gs.showPopup({
				title: title,
				subTitle: subTitle,
				body: [],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Confirm',
				doneCb: () => {
					this.keepForeverPopupExecute(email, !email.keep_forever, () => {});
				}
			});
		}
		else {
			this.gs.showPopup({
				title: 'Preserve Quarantined Email Records',
				subTitle: 'The emails will be kept in the system regardless the retention period',
				body: [],
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Confirm',
				doneCb: () => {
					emails.forEach((emailObj) => {
						if (!emailObj.keep_forever) {
							this.keepForeverPopupExecute(emailObj, true, () => {});
						}
					});
				}
			});
		}
    };

    keepForeverPopupMultipleExecute = (emails, enabled, idx, cb) => {
        if (idx < emails.length) {
            this.keepForeverPopupExecute(emails[idx], enabled, () => {
                this.keepForeverPopupMultipleExecute(emails, enabled, idx + 1, cb);
            });
        }
        else {
            cb();
        }
    };

    keepForeverPopupExecute = (email, enabled, cb) => {
        if (email.parent) {
            this.keepForeverPopupMultipleExecute(email.emails, enabled, 0, () => {
                email.keep_forever = enabled;
                cb();
            });
        }
        else {
            const data = {
                action: this.dic.CONSTANTS.quarantinedActions.toggleKeepForever.value,
				enabled: enabled
            };
            const emailId = getEmailId(email);
            this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
                email.keep_forever = enabled;
                this.addReviewerAction(email, response.reviewerActivity);
                cb();
            });
        }
    };

    authSenderPopup = (email) => {
        this.curAction = this.dic.CONSTANTS.quarantinedActions.authSender.value;
        this.senderAuthMethod = this.dic.CONSTANTS.authenticateSenderMethods.phone;

        this.senderAuthPhone = {phone_number: '', country_code: ''};
        this.blockSenderAuthRequest = false;
        if (email.auth_sender && email.auth_sender.updated) {
            this.senderAuthMethod = email.auth_sender.method;
            if (email.auth_sender.method === this.dic.CONSTANTS.authenticateSenderMethods.phone) {
                this.senderAuthPhone = _.clone(email.auth_sender.phone);
            }
            email.auth_sender.minutes = this.gs.dateDiffInMinutes(new Date(email.auth_sender.updated), new Date());
            if (email.auth_sender.minutes <= this.dic.CONSTANTS.authSenderTtl) {
                this.blockSenderAuthRequest = true;
            }
        }

        this.curEmail = email;
        this.showAuthSenderPopup = true;
    };

    openClickTrackingPopup = (url) => {
        this.urlTrackingPopup = {
            urlAddress: url.redirect_url || url.url,
            clickInfo: url.clickInfo,
            show: true
        };
    }

    openLocation = (ll) => {
        this.gs.openLocation(ll);
    }

	openRescanEmailPopup = (email) => {
		this.gs.showPopup({
			title: 'Rescan Email',
			subTitle: "The process will only take a few moments to update the threat analysis scan results. Allow/Block lists will be ignored.",
			body: [],
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'Rescan',
			doneCb: (options) => {
				const data:any = {action: this.dic.CONSTANTS.quarantinedActions.rescan.value};
				const emailId = getEmailId(email);
				this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
					_.remove<any>(this.curEmail.methods, method => {return method === this.dic.CONSTANTS.quarantinedActions.rescan.value});

					email.malicious.tpResults.rescan_in_process = true;
					this.checkViewThreatsReScanStatus(email);
					this.ns.showInfoMessage(this.dic.MESSAGES.operationCalled);
					this.addReviewerAction(email, response.reviewerActivity);
				}, err => {
					this.popupError = this.ns.getCurrentMessage();
				});
			}
		});
	}

	setModelOpenAiClassifyEmailPopup(model) {
		this.openAiClassifyEmailPopup.embeddedNew = false;
		this.openAiClassifyEmailPopup.embeddedProd = false;
		this.openAiClassifyEmailPopup.finetuneNew = false;
		this.openAiClassifyEmailPopup.finetuneProd = false;
		this.openAiClassifyEmailPopup.baselineNew = false;
		this.openAiClassifyEmailPopup.baselineProd = false;
		this.openAiClassifyEmailPopup.hybridNew = false;
		this.openAiClassifyEmailPopup.hybridProd = false;

		if (model) {
			this.openAiClassifyEmailPopup[model] = true;
		}
	}

	showOpenAiClassifyEmailPopup = (email) => {
		this.openAiClassifyEmailPopup = {
			embeddedNew: false,
			embeddedProd: true,
			finetuneNew: false,
			finetuneProd: false,
			baselineNew: false,
			baselineProd: false,
			hybridNew: false,
			hybridProd: false,
			email: email,
			show: true,
			results: ''
		};
	}

	openAiClassifyEmailPopupExecute() {
		this.openAiClassifyEmailPopup.results = '';

		const email = this.openAiClassifyEmailPopup.email;
		const data = {
			action: this.dic.CONSTANTS.quarantinedActions.classifyEmail.value,
			embedded: this.openAiClassifyEmailPopup.embeddedNew || this.openAiClassifyEmailPopup.embeddedProd,
			finetune: this.openAiClassifyEmailPopup.finetuneNew || this.openAiClassifyEmailPopup.finetuneProd,
			baseline: this.openAiClassifyEmailPopup.baselineNew || this.openAiClassifyEmailPopup.baselineProd,
			hybrid: this.openAiClassifyEmailPopup.hybridNew || this.openAiClassifyEmailPopup.hybridProd,
			newVersion: this.openAiClassifyEmailPopup.embeddedNew || this.openAiClassifyEmailPopup.finetuneNew || this.openAiClassifyEmailPopup.baselineNew || this.openAiClassifyEmailPopup.hybridNew
		};

		this.openAiClassifyEmailPopup.actionInProcess = true;
		const emailId = getEmailId(email);
		this.rs.doHeavyActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
			this.openAiClassifyEmailPopup.results = response.meta && response.meta.message || '';
			this.openAiClassifyEmailPopup.actionInProcess = false;
		}, err => {
			this.openAiClassifyEmailPopup.actionInProcess = false;
			this.popupError = this.ns.getCurrentMessage();
		});
	}

	showOpenAiSaveRecordPopup = (email) => {
		this.openAiSaveRecordPopup = {
			show: true,
			items: [{name: 'Safe'},{name: 'Spam'},{name: 'Graymail'},{name: 'Threat'}],
			selected: {name: 'Spam'},
			email: email
		};
	}

	openAiSaveRecordPopupExecute() {
		const email = this.openAiSaveRecordPopup.email;
		const data = {
			action: this.dic.CONSTANTS.quarantinedActions.saveRecord.value,
			class: this.openAiSaveRecordPopup.selected.name.toLowerCase()
		};

		this.openAiSaveRecordPopup.actionInProcess = true;
		const emailId = getEmailId(email);
		this.rs.doHeavyActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
			this.openAiSaveRecordPopup.show = false;
		}, err => {
			this.popupError = this.ns.getCurrentMessage();
			this.openAiSaveRecordPopup.actionInProcess = false;
		});
	}

	checkViewThreatsReScanStatus = (email, timeoutSeconds = 30) => {
		setTimeout(() => {
			const emailId = getEmailId(email);
			this.rs.getQuarantinedThreats(emailId, this.getPlanIdParams(email)).then((data) => {
				if (data.malicious.tpResults.rescan_in_process) {
					this.checkViewThreatsReScanStatus(email, timeoutSeconds);
				}
				else {
					email.malicious = data.malicious;
					this.setEmailCategoryExecute(email, email.malicious.status);
					this.showEmailThreatsPopup(email);
					if (this.emailInfoPopup) {
						this.emailInfoPopup.category = email.category;
					}
				}
			});
		}, timeoutSeconds * 1000);
	}

    openSetCategoryPopup = (email) => {
        this.setCategoryPopup = {
            email: email,
            newCategory: email.category,
            reportToTrustifi: true,
            reason: '',
            show: true
        };
    };

    setCategoryConfirm = () => {
        // check if changes made for single email
        if (this.setCategoryPopup.email && this.setCategoryPopup.email.category === this.setCategoryPopup.newCategory && !this.setCategoryPopup.reason) {
            this.ns.showWarnMessage(this.dic.ERRORS.noChanges);
            return;
        }
        // check if changes made for multiple emails
        if (this.setCategoryPopup.multiple && !_.filter(_.map(this.selectedEmails, 'category'),c => c !== this.setCategoryPopup.newCategory).length && !this.setCategoryPopup.reason) {
            this.ns.showWarnMessage(this.dic.ERRORS.noChanges);
            return;
        }

        const categoryInfo = {
            category: this.setCategoryPopup.newCategory,
            reason: this.setCategoryPopup.reason,
            reportToTrustifi: this.setCategoryPopup.reportToTrustifi
        }

        this.setCategoryInProcess = true;

        if (this.setCategoryPopup.multiple) {
            this.setCategoryMultipleQuarantinedEmail(this.selectedEmails, 0, categoryInfo, () => {
                if (categoryInfo.reportToTrustifi) {
                    this.ns.showInfoMessage(this.dic.MESSAGES.quarantineReport);
                    this.popupError = {error: {type: 'info', message: this.dic.MESSAGES.quarantineReport}};
                }
                else {
                    this.ns.showInfoMessage('Category was changed successfully');
                    this.popupError = {error: {type: 'info', message: 'Category was changed successfully'}};
                }
                this.setCategoryPopup = null;
                this.setCategoryInProcess = false;
            });
        }
        else {
            this.setCategoryExecute(this.setCategoryPopup.email, categoryInfo, () => {
                if (categoryInfo.reportToTrustifi) {
                    this.ns.showInfoMessage(this.dic.MESSAGES.quarantineReport);
                    this.popupError = {error: {type: 'info', message: this.dic.MESSAGES.quarantineReport}};
                }
                else {
                    this.ns.showInfoMessage('Category was changed successfully');
                    this.popupError = {error: {type: 'info', message: 'Category was changed successfully'}};
                }
                this.setCategoryPopup = null;
                this.setCategoryInProcess = false;
            });
        }
    };

    setCategoryMultipleQuarantinedEmail = (emails, idx, categoryInfo, cb) => {
        if (idx < emails.length) {
            this.setCategoryExecute(emails[idx], categoryInfo, () => {
                this.setCategoryMultipleQuarantinedEmail(emails, idx + 1, categoryInfo, cb);
            });
        }
        else {
            cb();
        }
    };

    setCategoryExecute = (email, categoryInfo, cb) => {
        if (email.parent) {
            this.setCategoryMultipleQuarantinedEmail(email.emails, 0, categoryInfo, () => {
                email.category = categoryInfo.category;
                email.reason = categoryInfo.reason;
                email.malicious.status_user_reported = email.category;

                email.diffCategory = false;
                this.setEmailCategory(email);
                cb();
            })
        }
        else {
            const data = {
                action: this.dic.CONSTANTS.quarantinedActions.setCategory.value,
                category: categoryInfo.category,
                reason: categoryInfo.reason,
                reportToTrustifi: categoryInfo.reportToTrustifi
            };
            const emailId = getEmailId(email);
			this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
                email.category = categoryInfo.category;
                email.reason = categoryInfo.reason;
                email.malicious.status_user_reported = email.category;
                this.setEmailCategory(email);
                this.addReviewerAction(email, response.reviewerActivity);
                cb();


                if (data.reportToTrustifi) {
                    email.report = {report_by: 'reviewer'};
                }
            }, (err) => {
                this.popupError = this.ns.getCurrentMessage();
            });
        }
    };


    allowBlockedFileType = (email) => {
        this.gs.showPopup({
            title: 'Allow File Type',
            subTitle: "You are about to set this uncommon file type as allowed.",
            body:  ['Emails with this type of file will no longer be blocked'],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Allow',
            doneCb: () => {
                this.allowBlockedFileTypeExecute(email, () => {});
            }
        });
    };

    allowBlockedFileTypeExecute = (email, cb) => {
        const data = {action: this.dic.CONSTANTS.quarantinedActions.allowFileType.value};
        const emailId = getEmailId(email);
        this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
            this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
            _.remove<any>(this.curEmail.methods, method => {return method === this.dic.CONSTANTS.quarantinedActions.allowFileType.value});
            this.popupError = this.ns.getCurrentMessage();
            this.addReviewerAction(email, response.reviewerActivity);
            cb();
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            cb();
        });
    };


    signaturePopup = (email) => {
        const body = [`The source will be signed and considered safe and valid for this domain`];
        if (this.curTPResults.resHead.spf.score <= 1 || this.curTPResults.resHead.dmarc.score <= 1) {
            body.push('Note: SPF/DMARC has failed for this source. It is not recommended to sign sources with failed SPF/DMARC as it may indicate the email was spoofed.')
        }

        this.gs.showPopup({
            title: 'Sign Quarantined Email Source',
            subTitle: "Please note - you are about to sign source for this email",
            body:  body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Sign Source',
            doneCb: () => {
                this.signatureExecute(email, () => {});
            }
        });
    };

    signatureExecute = (email, cb) => {
        const data = {action: this.dic.CONSTANTS.quarantinedActions.signature.value};
        const emailId = getEmailId(email);
        this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
            this.ns.showInfoMessage(this.dic.MESSAGES.quarantinedSignSource);
            this.popupError = this.ns.getCurrentMessage();
            this.addReviewerAction(email, response.reviewerActivity);
            cb();
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            cb();
        });
    };

    viewQuarantineInfo = (email) => {
        // remove previous email data
        if (this.curEmail) {
            this.curEmail.content = null;
            this.curEmail.headers = null;
            this.curTPResults = null;
        }

        this.curEmail = email;

		if (this.curEmail.emails?.length) {
			this.curEmail.emails = _.sortBy(this.curEmail.emails, [(email) => { return email.category_priority || 0; }]);
			this.curEmail.reviewer_actions = this.curEmail.emails[0].reviewer_actions;
		}

        this.showHtml = false;
        this.curEmail.isShowingContent = false;
        this.popupError = null;
        this.previewQuarantinedEmailThreats(email);
    };

    previewLink = (url) => {
        if (!url) {
            this.openPreviewLinkPopup = null;
            return;
        }

        this.openPreviewLinkPopup = {
            show: true,
            url: url
        };
    }

    previewQuarantinedEmailContent = (email) => {
        this.popupError = null;

        email.isShowingContent = true;

        if (email.content) {
            return;
        }

        email.loadingEmailContent = true;
        this.loadQuarantineData = true;
		const emailId = email.curRecipient._id;
        this.rs.getQuarantinedEmailData(emailId, this.getPlanIdParams(email)).then( (data) => {
            if (!data) {
                return;
            }

			if (this.enableDefangeUrl) {
				if (data.content.html) {
					data.content.html = this.defangeUrlFromContent(data.content.html, true);
				} else {
					data.content.text = this.defangeUrlFromContent(data.content.text, false);
				}
			}

			const processedEmailContent = this.processEmailContent(data);
			email.content = {
				html: processedEmailContent,
				attachments: data.content?.attachments
			};
            this.addReviewerAction(this.curEmail, data.reviewerActivity);

            this.loadQuarantineData = false;
            email.loadingEmailContent = false;
        }, (err) => {
            this.loadQuarantineData = false;
            email.loadingEmailContent = false;
        });
    };

	defangeUrlFromContent(content, isHtml) {
		const maliciousLinks = _.filter(this.curTPResults.resUrl.list, (url) => {
			return url.status === this.dic.CONSTANTS.threatProtection.status.malicious;
		}).map((maliciousUrl) => {
			return maliciousUrl.original_url || maliciousUrl.url;
		});

		if (maliciousLinks.length) {
			try {

				return isHtml ? this.gs.defangeMaliciousLinksFromHtml(content, maliciousLinks) : this.gs.defangeMaliciousLinksFromText(content, maliciousLinks);
			}
			catch (error) {
				return content;
			}
		} else {
			return content;
		}
	}

    processEmailContent(contentData) {
        let html = `<div>`;

        if (contentData.content) {
            if (contentData.content.from && contentData.content.from.text) {
                contentData.content.from.text = contentData.content.from.text.replaceAll('<', '('); // problem to display <> in HTML
                contentData.content.from.text = contentData.content.from.text.replaceAll('>', ')');
                html += `<b>From:</b>  ${contentData.content.from.text}`;
            }

            if (contentData.content.to && contentData.content.to.text) {
                contentData.content.to.text = contentData.content.to.text.replaceAll('<', '('); // problem to display <> in HTML
                contentData.content.to.text = contentData.content.to.text.replaceAll('>', ')');
                html += `<br><b>To:</b>  ${contentData.content.to.text}`;
            }
            if (contentData.content.cc && contentData.content.cc.text) {
                contentData.content.cc.text = contentData.content.cc.text.replaceAll('<', '(');
                contentData.content.cc.text = contentData.content.cc.text.replaceAll('>', ')');
                html += `<br><b>Cc:</b>  ${contentData.content.cc.text}`;
            }

            html += `<br><b>Subject:</b>  ${this.curEmail.subject}<br><hr><br></div>`;
            html += contentData.content.html || contentData.content.text || '';
        }

        return html;
    }

    previewQuarantinedEmailThreats = (email) => {
        this.emailInfoPopup = {
            show: true,
            category: this.curEmail.category
        };
        this.loadQuarantineData = true;
        const emailId = getEmailId(email);
        this.rs.getQuarantinedThreats(emailId, this.getPlanIdParams(email)).then( (data) => {
            if (emailId === getEmailId(this.curEmail)) {
                email.malicious = data.malicious;
                email.methods = data.methods;
                email.senderMethods = data.senderMethods;
                this.showEmailThreatsPopup(email);
            }
            this.addReviewerAction(email, data.reviewerActivity);
            this.curEmail.curRecipient = this.curEmail.emails ? this.curEmail.emails[0] : this.curEmail;
			this.isParentQuarantined = this.curEmail.emails?.length > 1;
            this.loadQuarantineData = false;
        });
    };

    previewQuarantinedEmailHeaders = (email) => {
        if (this.curEmail.headers) {
            return;
        }
        this.curEmail.loadingHeaders = true;
        const emailId = getEmailId(email);
        this.rs.getQuarantinedEmailHeaders(emailId, this.getPlanIdParams(email)).then( (data) => {
            if (emailId === getEmailId(this.curEmail)) {
                this.curEmail.headers = data.headers;
            }
            this.curEmail.loadingHeaders = false;
        }, (err) => {
            this.curEmail.loadingHeaders = false;
        });
    };

    changeRecipientThreats = (recipient) => {
		this.curEmail.curRecipient = recipient;
        this.loadQuarantineData = true;
		this.rs.getQuarantinedThreats(recipient._id, this.getPlanIdParams(this.curEmail)).then( (data) => {
            if (data) {
                recipient.malicious = data.malicious;
                recipient.methods = data.methods;
                this.curEmail.methods = data.methods;
                this.curEmail.malicious = data.malicious;
				this.curEmail.status = data.status;
				this.curEmail.reason = data.reason;
				this.showEmailThreatsPopup(this.curEmail);
                this.setEmailCategory(data);
                this.emailInfoPopup.category = data.category;
                this.curEmail.reviewer_actions = recipient.reviewer_actions;
            }
            this.loadQuarantineData = false;
        });
    };

    showEmailThreatsPopup = (email) => {
        this.curTPResults = email.malicious.tpResults;
        if (this.curTPResults) {
            if (this.curTPResults.resHead && this.curTPResults.resHead.aiConfidence) {
                this.curTPResults.resHead.aiConfidence = parseFloat(this.curTPResults.resHead.aiConfidence.toFixed(2))
            }
            if (this.curTPResults.resUrl && this.curTPResults.resUrl.list && this.curTPResults.resUrl.list.length) {
                this.curTPResults.resUrl.list = this.curTPResults.resUrl.list.sort((a, b) => this.statusPriority[a.status] - this.statusPriority[b.status]);
                this.filterDuplicateSafeLinks();
            }
            else {
                if (!this.curTPResults.resUrl) {
                    this.curTPResults.resUrl = {};
                }
                this.curTPResults.urlStatus = this.dic.CONSTANTS.threatProtection.status.safe;
                if (!this.curTPResults.resUrl.message) {
                    this.curTPResults.resUrl.message = 'No malicious links detected.';
                }
            }
            if (this.curTPResults.resHash && this.curTPResults.resHash.list && this.curTPResults.resHash.list.length) {
                this.curTPResults.resHash.list = this.curTPResults.resHash.list.sort((a, b) => this.statusPriority[a.status] - this.statusPriority[b.status]);
            }
            else {
                if (!this.curTPResults.resHash) {
                    this.curTPResults.resHash = {};
                }
                this.curTPResults.hashStatus = this.dic.CONSTANTS.threatProtection.status.safe;
                if (!this.curTPResults.resHash.message) {
                    this.curTPResults.resHash.message = 'No malicious attachments detected.';
                }
            }

			if (this.tpMetrics?.length) {
				if (this.curTPResults?.resHead?.sub_status) {
					this.curTPResults.resHead.sub_status_description = this.tpMetrics.find(itm => itm.sub_status && itm.sub_status === this.curTPResults.resHead.sub_status)?.description || '';
				}
				if (this.curTPResults?.resUrl?.sub_status) {
					this.curTPResults.resUrl.sub_status_description = this.tpMetrics.find(itm => itm.sub_status && itm.sub_status === this.curTPResults.resUrl.sub_status)?.description || '';
				}
				if (this.curTPResults?.resHash?.sub_status) {
					this.curTPResults.resHash.sub_status_description = this.tpMetrics.find(itm => itm.sub_status && itm.sub_status === this.curTPResults.resHash.sub_status)?.description || '';
				}
			}
		}
    }

	requestedReleaseRejectPopup = (email) => {
		this.releaseRejectPopup = {
			show: true,
			securityTeamComments: '',
			applyInProcess: false,
			doneCb: () => {
				this.releaseRejectPopup.applyInProcess = true;
				const data = {
					action: this.dic.CONSTANTS.quarantinedActions.requestedReleaseReject.value,
					reason: this.releaseRejectPopup.securityTeamComments
				};
				const emailId = getEmailId(email);
				this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
					email.status = this.dic.CONSTANTS.quarantinedActions.quarantine.value;
					email.reason = 'Release request rejected';
					_.remove<any>(this.curEmail.methods, method => {return method === this.dic.CONSTANTS.quarantinedActions.requestedReleaseReject.value});
					this.addReviewerAction(email, response.reviewerActivity);

					this.ns.showInfoMessage(`Operation completed successfully`);
					this.releaseRejectPopup.applyInProcess = false;
					this.releaseRejectPopup.show = false;
				}, err => {
					this.releaseRejectPopup.applyInProcess = false;
					this.popupError = this.ns.getCurrentMessage();
				});
			}
		};
	}


    showReleaseQuarantinedEmailPopup = (emails) => {
        if (!emails.length) {
            return;
        }

        this.addWarningLabel = false;
        this.addSubjectText = false;
        this.deleteRecord = false;
        this.addContactToAdmin = false;
        this.reportToTrustifi = true;

        let subTitle;
        if (emails.length > 1) {
            subTitle = `Please note - you are about to release ${this.selectedEmailsCount} emails back to the recipient's inbox`;
        }
        else {
            subTitle = `Please note - you are about to release this email back to ${emails[0].parent && emails[0].emails.length > 1 ? `${emails[0].emails.length} recipient inboxes` : 'the recipient\'s inbox'}`;
        }

		if (emails[0].source === this.emailsSources.exchange) {
			this.gs.showPopup({
				title: 'Microsoft Exchange Quarantine Release',
				subTitle: subTitle,
				type: this.dic.CONSTANTS.popupWarning,
				doneBtnText: 'Release',
				doneCb: () => {
					this.ns.showInfoMessage('The selected email is being released, the email status will be updated');
					emails.forEach((emailObj) => {
						const data = {
							action: this.dic.CONSTANTS.quarantinedActions.release.value,
							id: getEmailId(emailObj),
						};
						this.rs.exchangeDoActionOnQuarantinedEmail(data).then((response) => {
							emailObj.status = this.dic.CONSTANTS.quarantinedActions.release.value;
						});
					});
				}
			});
			return;
		}

        const trustSenderObj = {
            labelName: 'Trust sender',
            tooltip: 'The sender will be trusted for all users in the plan. Emails from trusted senders are less likely to be considered spam or as threats.',
            model: this.addContactToAdmin
        };
        if (emails[0].malicious.tpResults.resHead.sub_status) {
            if (['Email Allowlist Signature Mismatch', 'Sender Allowlist Signature Mismatch'].includes(emails[0].malicious.tpResults.resHead.sub_status)) {
                trustSenderObj.labelName += ' (recommended)';
                trustSenderObj.tooltip = 'Select this option if you trust this sender/email to ensure the system does not block future similar emails';
            } else if (['Source Mismatch', 'Global Source Mismatch'].includes(emails[0].malicious.tpResults.resHead.sub_status)) {
                trustSenderObj.labelName += ' (recommended)';
                trustSenderObj.tooltip = `Select this option if this source is allowed for sending emails for domain ${this.gs.getDomain(emails[0].sender)} to ensure the system does not block future similar emails`;
            } else if (['Safe', 'External', 'Graymail'].includes(emails[0].malicious.status)) {
                trustSenderObj.labelName += ' (recommended)';
                trustSenderObj.tooltip = `Select this option if you trust this sender/email to ensure the system does not block future similar emails`;
            }
        }

        // Do not send reports to Trustifi by default on Safe/External
        if (emails.every(emailObj => ['Safe', 'External'].includes(emailObj.malicious.status))) {
            this.reportToTrustifi = false;
        }

        // Do not send reports to Trustifi by default on Blocklist, User Preferences, Spoofing
        if (emails.every(emailObj => ['Blocklist', 'User Preferences', 'Spoofing'].includes(emailObj.malicious.tpResults.resHead.category))) {
            if (!emails.some(emailObj => ['Threat Intelligence'].includes(emailObj.malicious.tpResults.resHead.sub_status))) {
                this.reportToTrustifi = false;
            }
        }

        const releaseOptions = [
            {
                labelName: 'Add warning label',
                tooltip: 'The relevant warning label will be added to the email when it is released.',
                model: this.addWarningLabel
            },
            {
                labelName: 'Add subject text',
                tooltip: 'The relevant text will be added to the email subject when it is released.',
                model: this.addSubjectText
            },
            trustSenderObj,
            {
                labelName: 'Report to Trustifi (recommended)',
                tooltip: 'Our dedicated security team review each report daily and takes necessary actions to adjust our security features accordingly.\n' +
                    'Based on feedback, our team can adjust the AI, add malicious senders to the global blocklist, enhance our security metrics and more.',
                model: this.reportToTrustifi
            }
        ]

        if (this.isDeleteActionAllowed()) {
            releaseOptions.push({
                labelName: 'Delete record',
                tooltip: 'After the email is released, the quarantined email record will be deleted.',
                model: this.deleteRecord
            });
        }
		this.releaseQuarantinedEmailPopup = {
			show: true,
			applyInProcess: false,
			subTitle: subTitle,
			releaseOptionsToggles: releaseOptions,
			doneCb: () => {
				this.releaseQuarantinedEmailPopup.applyInProcess = true;
				let releaseOptions:any = {};
				if (this.releaseQuarantinedEmailPopup.releaseOptionsToggles && this.releaseQuarantinedEmailPopup.releaseOptionsToggles[0] && this.releaseQuarantinedEmailPopup.releaseOptionsToggles[0].model) {
					releaseOptions.addWarningLabel = this.releaseQuarantinedEmailPopup.releaseOptionsToggles[0].model;
				}
				if (this.releaseQuarantinedEmailPopup.releaseOptionsToggles && this.releaseQuarantinedEmailPopup.releaseOptionsToggles[1] && this.releaseQuarantinedEmailPopup.releaseOptionsToggles[1].model) {
					releaseOptions.addSubjectText = this.releaseQuarantinedEmailPopup.releaseOptionsToggles[1].model;
				}
				if (this.releaseQuarantinedEmailPopup.releaseOptionsToggles[2] && this.releaseQuarantinedEmailPopup.releaseOptionsToggles[2].model) {
					releaseOptions.addContactToAdmin = this.releaseQuarantinedEmailPopup.releaseOptionsToggles[2].model;
				}
				if (this.releaseQuarantinedEmailPopup.releaseOptionsToggles && this.releaseQuarantinedEmailPopup.releaseOptionsToggles[3] && this.releaseQuarantinedEmailPopup.releaseOptionsToggles[3].model) {
					releaseOptions.reportToTrustifi = this.releaseQuarantinedEmailPopup.releaseOptionsToggles[3].model;
				}
				if (this.releaseQuarantinedEmailPopup.releaseOptionsToggles && this.releaseQuarantinedEmailPopup.releaseOptionsToggles[4] && this.releaseQuarantinedEmailPopup.releaseOptionsToggles[4].model) {
					releaseOptions.deleteRecord = this.releaseQuarantinedEmailPopup.releaseOptionsToggles[4].model;
				}

				this.releaseQuarantinedEmailPopup.applyInProcess = false;
				this.releaseQuarantinedEmailPopup.show = false;

				this.releaseMultipleQuarantinedEmail(emails, 0, releaseOptions, () => {
					if (emails.length > 1) {
						this.ns.showInfoMessage(`Operation completed successfully for ${emails.length} emails`);
					}

					if (this.curEmail && this.curEmail.methods) {
						_.remove<any>(this.curEmail.methods, method => {return method === this.dic.CONSTANTS.quarantinedActions.release.value});
						if (this.curEmail.status === this.dic.CONSTANTS.quarantinedActions.release.value) {
							this.curEmail.methods.push(this.dic.CONSTANTS.quarantinedActions.remove.value);
						}
						// when deleted we cannot take anymore actions
						if (releaseOptions.deleteRecord) {
							this.curEmail.methods = [];
							this.curEmail.senderMethods = [];
						}
					}

					this.popupError = this.ns.getCurrentMessage();
				});
			}
		};
    };

    releaseQuarantinedEmailExecute = (email, releaseOptions, cb) => {
        if (email.parent) {
            this.releaseMultipleQuarantinedEmail(email.emails, 0, releaseOptions, () => {
                email.status = email.emails[0].status;
                if (releaseOptions.deleteRecord) {
                    // make sure to remove record's visible sub-emails rows first
                    if (email.showSubMailboxes) {
                        this.showHideSubMailboxes(email, false);
                    }
                    _.remove<any>(this.groupEmails, emailObj => emailObj._id === email._id);
                    _.remove<any>(this.originalGroupEmails, emailObj => emailObj._id === email._id);
                }
                cb();
            });
        }
        else {
            if (email.status === this.dic.CONSTANTS.quarantinedActions.pending.value) {
                return cb();
            }

            const data = {
                action: this.dic.CONSTANTS.quarantinedActions.release.value,
                skipWarningLabel: !releaseOptions.addWarningLabel,
                addSubjectText: releaseOptions.addSubjectText,
                addContactToAdmin: releaseOptions.addContactToAdmin,
                reportToTrustifi: releaseOptions.reportToTrustifi,
                deleteRecord: releaseOptions.deleteRecord
            };
            const emailId = getEmailId(email);
            this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
                email.status = response.status;
                email.reason = response.reason || this.reviewerInfo.email;

                if (data.reportToTrustifi) {
                    email.report = {report_by: 'reviewer'};
                }

                this.addReviewerAction(email, `Released email to ${email.mailbox_email}`);
                cb();

                if (email.status === this.dic.CONSTANTS.quarantinedActions.pending.value) {
                    this.addQuarantineStatusCheck(email);
                }
            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                cb();
            });
        }
    };

    pendingQuarantines = [];
    checkStatusTimeOut;
    addQuarantineStatusCheck = (email) => {
        this.pendingQuarantines.push(email._id.toString());

        if (this.checkStatusTimeOut) {
            return;
        }

        this.checkStatusTimeOut = setTimeout(() => {
            this.checkQuarantineStatus();
        }, 30000);
    };

    checkQuarantineStatus = () => {
        const slicedArray = this.pendingQuarantines.slice(0, 30); // limit the IDs in the query
        this.rs.checkQuarantineStatus(slicedArray.join(',')).then((response) => {
            response.forEach((statusObj) => {
                if (statusObj.status !== this.dic.CONSTANTS.quarantinedActions.pending.value) {
                    for (let idx = 0; idx < this.groupEmails.length; idx++) {
                        let emailObj = _.find<any>(this.groupEmails[idx].emails, {_id: statusObj._id});
                        if (emailObj) {
                            emailObj.status = statusObj.status;

                            calcParentEmailCategory(this.groupEmails[idx]);
                            break;
                        }
                    }
                    const index = this.pendingQuarantines.indexOf(statusObj._id);
                    if (index > -1) {
                        this.pendingQuarantines.splice(index, 1);
                    }
                }
            });

            if (this.pendingQuarantines.length) {
                this.checkStatusTimeOut = setTimeout(() => {
                    this.checkQuarantineStatus();
                }, 30000);
            }
            else {
                this.checkStatusTimeOut = null;
            }
        });
    }


    createIncidentPopup = (email) => {
		this.incidentPopup = {
			show: true,
			securityTeamComments: '',
			applyInProcess: false,
			doneCb: () => {
				this.incidentPopup.applyInProcess = true;
				this.loadQuarantineData = true;

				const data = {
					action: this.dic.CONSTANTS.quarantinedActions.createIncident.value,
					securityTeamComments: this.incidentPopup.securityTeamComments
				};

				const emailId = getEmailId(email);
				this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
					this.addReviewerAction(email, response.reviewerActivity);
					email.reviewed_completed = false;
					email.mark_follow = true;
					this.loadQuarantineData = false;
					this.ns.showInfoMessage(`Incident created successfully`);
					this.incidentPopup.applyInProcess = false;
					this.incidentPopup.show = false;
				}, (err) => {
					this.popupError = this.ns.getCurrentMessage();
					this.incidentPopup.applyInProcess = false;
					this.loadQuarantineData = false;
				});
			}
		};
    };

    createSecurityReviewerActivityPopup = (email) => {
        const emdrActions = [];
        for (let key in this.dic.CONSTANTS.emdrActionType) {
            emdrActions.push({name: key, selected: key === this.dic.CONSTANTS.emdrActionType.action});
        }
		this.securityReviewerPopup = {
			show: true,
			applyInProcess: false,
			securityTeamCommentInput: this.securityTeamComments,
			emdrActionInput: {
				model: emdrActions[0].name,
				selected:emdrActions[0].selected
			},
			dropdownObjects: emdrActions,
			doneCb: () => {
				this.securityReviewerPopup.applyInProcess = true;
				if (!this.securityReviewerPopup.securityTeamCommentInput || this.securityReviewerPopup.dropdownObjects[0].selected === this.securityReviewerPopup.dropdownObjects[1].selected) {
					this.ns.showErrorMessage(`You must add description and select only one type of activity`);
					this.securityReviewerPopup.applyInProcess = false;
					return;
				}
				this.loadQuarantineData = true;

				const data = {
					action: this.dic.CONSTANTS.quarantinedActions.securityReviewerActivity.value,
					securityTeamComments: this.securityReviewerPopup.securityTeamCommentInput,
					type: this.securityReviewerPopup.emdrActionInput.model
				};

				const emailId = getEmailId(email);
				this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
					this.addReviewerAction(email, 'Reviewer Activity: '+data.securityTeamComments);
					this.loadQuarantineData = false;
					this.ns.showInfoMessage(`Activity added successfully`);
					this.securityReviewerPopup.applyInProcess = false;
					this.securityReviewerPopup.show = false;
				}, (err) => {
					this.popupError = this.ns.getCurrentMessage();
					this.securityReviewerPopup.applyInProcess = false;
					this.loadQuarantineData = false;
				});
			}
		};
    };


    addLinksWhitelistPopup = (email) => {
        if (!email) {
            return;
        }

        let body = ["All non-safe links contained in this email will now be considered safe."];

        this.gs.showPopup({
            title: 'Add links to allowlist',
            subTitle: "Please note - you are about to add this email's links to allowlist",
            body:  body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Confirm',
            doneCb: () => {
                this.loadQuarantineData = true;
                this.addLinksWhiteListExecute(email, () => {
                    this.loadQuarantineData = false;
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Links'));
                    this.popupError = this.ns.getCurrentMessage();
                });
            }
        });
    };

    addLinksWhiteListExecute = (email, cb) => {
        const data = {
            action: this.dic.CONSTANTS.quarantinedActions.linksWhitelist.value,
            links: []
        };
        email.malicious.tpResults.resUrl.list.forEach((linkObj) => {
            if (linkObj.status !== "Safe") {
                data.links.push({link: linkObj.url});
            }
        });

        const emailId = getEmailId(email);
        this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
            this.addReviewerAction(email, response.reviewerActivity);
            cb();
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
            cb();
        });
    };


    addSenderBlackListPopup = (email, isSecurityReviewer = false) => {
        if (!email) {
            return;
        }

        const blockListOptions = [
            {text : `Sender email: ${email.malicious.tpResults.resHead.from.senderMailFrom}`, model: this.actionForHeaders.senderEmail, value: email.malicious.tpResults.resHead.from.senderMailFrom, type: 'email', sender: true},
            {text : `Sender domain: ${this.gs.getDomain(email.sender)}`, model: this.actionForHeaders.senderDomain, value: this.gs.getDomain(email.sender), type: 'domain'},
            {text : `Reply-to email: ${email.malicious.tpResults.resHead.from.replyTo}`, model: this.actionForHeaders.replyToEmail, value: email.malicious.tpResults.resHead.from.replyTo, type: 'email'},
            {text : `Reply-to domain: ${this.gs.getDomain(email.malicious.tpResults.resHead.from.replyTo)}`, model: this.actionForHeaders.replyToDomain, value: this.gs.getDomain(email.malicious.tpResults.resHead.from.replyTo), type: 'domain'},
            {text : `Message-ID domain: ${this.gs.getDomain(email.malicious.tpResults.resHead.from.messageID)}`, model: this.actionForHeaders.messageID, value: this.gs.getDomain(email.malicious.tpResults.resHead.from.messageID), type: 'domain'},
            {text : `Return path email: ${email.malicious.tpResults.resHead.from.returnPath}`, model: this.actionForHeaders.returnPathEmail, value: email.malicious.tpResults.resHead.from.returnPath, type: 'email'},
            {text : `Return path domain: ${this.gs.getDomain(email.malicious.tpResults.resHead.from.returnPath)}`, model: this.actionForHeaders.returnPath, value: this.gs.getDomain(email.malicious.tpResults.resHead.from.returnPath), type: 'domain'},
        ];
        if (email.malicious.tpResults.resHead.from.senderMail && email.malicious.tpResults.resHead.from.senderMail !== email.malicious.tpResults.resHead.from.senderMailFrom) {
            blockListOptions.unshift({text : `Sender appears as: ${email.malicious.tpResults.resHead.from.senderMail}`, model: this.actionForHeaders.senderEmailAppearsAs, value: email.malicious.tpResults.resHead.from.senderMail, type: 'email'});
        }
        if (email.malicious.tpResults.resHead.from && email.malicious.tpResults.resHead.from.ipAddresses && email.malicious.tpResults.resHead.from.ipAddresses.length) {
            email.malicious.tpResults.resHead.from.ipAddresses.forEach((ipAddr) => {
                this.actionForHeaders[ipAddr] = false;
                blockListOptions.push({text : `Sender IP address: ${ipAddr}`, model: this.actionForHeaders[ipAddr], value: ipAddr, type: 'ip'});
            });
        }

        this.blocklistSenderPopup = {
            show: true,
            items: blockListOptions,
            email: email,
            actionScope: 'global',
			isSecurityReviewer
        };
    };

	createBlockGlobalSenderPopup = (email) => {
		this.addSenderBlackListPopup(email, true);
	};

    addSenderBlacklistPopupExecute = () => {
        this.actionInProcess = true;

        const items = this.blocklistSenderPopup.items.filter(itm => itm.model);
        items.forEach((itemObj) => {
            itemObj.description = this.blocklistSenderPopup.reason || '';
        });
        if (!items.length) {
            this.actionInProcess = false;
            this.ns.showErrorMessage('You must select at least one header');
            return;
        }

		if (this.blocklistSenderPopup.isSecurityReviewer) {
			const data = {
				action: this.dic.CONSTANTS.quarantinedActions.securityReviewerGlobalSender.value,
				items: items
			};

			const emailId = getEmailId(this.blocklistSenderPopup.email);
			this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(this.blocklistSenderPopup.email)).then((response) => {
				this.ns.showInfoMessage('Operation completed successfully');
				this.actionInProcess = false;
				this.blocklistSenderPopup.show = false;
			}, (err) => {
				this.popupError = this.ns.getCurrentMessage();
				this.actionInProcess = false;
			});
			return;
		}

        const mailboxEmail = this.blocklistSenderPopup.actionScope === 'mailbox' ? getCurrentMailbox(this.blocklistSenderPopup.email) : '';

        this.addSenderBlackListExecute(this.blocklistSenderPopup.email, items, mailboxEmail, () => {
            this.actionInProcess = false;
            this.blocklistSenderPopup.show = false;

            const currentMsg = this.ns.getCurrentMessage();
            if (currentMsg && currentMsg.error) {
                this.popupError = currentMsg;
            }
            else {
                if (this.curEmail && this.curEmail.senderMethods) {
                    _.remove<any>(this.curEmail.senderMethods, m => m === this.dic.CONSTANTS.quarantinedActions.blacklist.value);
                    this.curEmail.senderMethods.push(this.dic.CONSTANTS.quarantinedActions.whitelist.value);
                }
                this.popupError = null;
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'sender'));
            }
        });
    };

    addSenderBlackListExecute = (email, items, mailboxEmail, cb) => {
        if (mailboxEmail) {
            const userConfigData = {type: this.dic.CONSTANTS.quarantinedActions.blacklist.value, action: 'Add', items, userEmail: mailboxEmail};
            this.rs.updateTpConfigUsers(userConfigData).then((response) => {
                this.addReviewerAction(email, response && response.reviewerActivity);
                cb();
            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                cb();
            });
        }
        else {
            const planConfigData = {
                action: this.dic.CONSTANTS.quarantinedActions.blacklist.value,
                items: items
            };
            const emailId = getEmailId(email);
            this.rs.doActionOnQuarantinedEmail(emailId, planConfigData, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
                this.addReviewerAction(email, response.reviewerActivity);
                cb();

            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                cb();
            });
        }
    };

    addSenderWhiteListPopup = (email) => {
        if (!email) {
            return;
        }

        const domainWarning = "Do not add domains you own or popular domains (e.g. gmail.com) to the domain allowlist. This creates opportunities for attackers to send you email that would otherwise be blocked";

        const items: any = [
            {text : `Sender email: ${email.sender}`, model: this.actionForHeaders.senderEmail, value: email.sender, type: 'email', sender: true},
            {text : `Sender domain: ${this.gs.getDomain(email.sender)}`, model: this.actionForHeaders.senderDomain, value: this.gs.getDomain(email.sender), type: 'domain', warning: domainWarning},
            {text : `Reply-to email: ${email.malicious.tpResults.resHead.from.replyTo}`, model: this.actionForHeaders.replyToEmail, value: email.malicious.tpResults.resHead.from.replyTo, type: 'email'},
            {text : `Reply-to domain: ${this.gs.getDomain(email.malicious.tpResults.resHead.from.replyTo)}`, model: this.actionForHeaders.replyToDomain, value: this.gs.getDomain(email.malicious.tpResults.resHead.from.replyTo), type: 'domain', warning: domainWarning},
            {text : `Message-ID domain: ${this.gs.getDomain(email.malicious.tpResults.resHead.from.messageID)}`, model: this.actionForHeaders.messageID, value: this.gs.getDomain(email.malicious.tpResults.resHead.from.messageID), type: 'domain'},
            {text : `Return path domain: ${this.gs.getDomain(email.malicious.tpResults.resHead.from.returnPath)}`, model: this.actionForHeaders.returnPath, value: this.gs.getDomain(email.malicious.tpResults.resHead.from.returnPath), type: 'domain', warning: domainWarning},
        ];
        if (email.malicious.tpResults.resHead.from && email.malicious.tpResults.resHead.from.ipAddresses && email.malicious.tpResults.resHead.from.ipAddresses.length) {
            email.malicious.tpResults.resHead.from.ipAddresses.forEach((ipAddr) => {
                this.actionForHeaders[ipAddr] = false;
                items.push({text : `Sender IP address: ${ipAddr}`, model: this.actionForHeaders[ipAddr], value: ipAddr, type: 'ip'});
            });
        }

        this.allowlistSenderPopup = {
            show: true,
            items: items,
            email: email,
            actionScope: 'global'
        };
    };

    addSenderWhiteListPopupExecute = () => {
        this.actionInProcess = true;

        const items = this.allowlistSenderPopup.items.filter(itm => itm.model);
        items.forEach((itemObj) => {
            itemObj.description = this.allowlistSenderPopup.reason || '';
        });
        if (!items.length) {
            this.ns.showErrorMessage('You must select at least one header');
            this.actionInProcess = false;
            return;
        }

        const mailboxEmail = this.allowlistSenderPopup.actionScope === 'mailbox' ? getCurrentMailbox(this.allowlistSenderPopup.email) : '';
        console.log(mailboxEmail);

        this.addSenderWhiteListExecute(this.allowlistSenderPopup.email, items, mailboxEmail, () => {
            this.actionInProcess = false;
            this.allowlistSenderPopup.show = false;

            const currentMsg = this.ns.getCurrentMessage();
            if (currentMsg && currentMsg.error) {
                this.popupError = currentMsg;
            }
            else {
                if (this.curEmail && this.curEmail.senderMethods) {
                    _.remove<any>(this.curEmail.senderMethods, m => m === this.dic.CONSTANTS.quarantinedActions.whitelist.value);
                    this.curEmail.senderMethods.push(this.dic.CONSTANTS.quarantinedActions.blacklist.value);
                }
                this.popupError = null;
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'sender'));
            }
        });
    };

    addSenderWhiteListExecute = (email, items, mailboxEmail, cb) => {
        if (mailboxEmail) {
            const userConfigData = {type: this.dic.CONSTANTS.quarantinedActions.whitelist.value, action: 'Add', items, userEmail: mailboxEmail};
            this.rs.updateTpConfigUsers(userConfigData).then((response) => {
                this.addReviewerAction(email, response && response.reviewerActivity);
                cb();
            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                cb();
            });
        }
        else {
            const planConfigData = {action: this.dic.CONSTANTS.quarantinedActions.whitelist.value, items: items};
            const emailId = getEmailId(email);
            this.rs.doActionOnQuarantinedEmail(emailId, planConfigData, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
                this.addReviewerAction(email, response.reviewerActivity);
                cb();

            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                cb();
            });
        }
    };

    addReviewerAction = (email, action) => {
        email.reviewed_completed = true;
        if (email.parent_id) {
            let parentEmail = _.find<any>(this.groupEmails, {_id: email.parent_id});
            if (parentEmail) {
                parentEmail.reviewed_completed = true;
            }
        }
        email.mark_follow = false;
        if (!action) {
            return;
        }
        if (!email.reviewer_actions) {
            email.reviewer_actions = [];
        }

        const newAction = {
            created: new Date(),
            reviewer: this.reviewerInfo.email,
            action: action
        };
        email.reviewer_actions.unshift(newAction);
    };

    downloadEml = (emailObj) => {
        if (!emailObj) {
            return;
        }

        const data = {action: this.dic.CONSTANTS.quarantinedActions.eml.value};
        const emailId = getEmailId(emailObj);

		let doActionOnQuarantinedEmail;
		if (emailObj.source === this.emailsSources.exchange) {
			data['id'] = emailId;
			doActionOnQuarantinedEmail = this.rs.exchangeDoActionOnQuarantinedEmail(data).then(res => ({message: res.data}));
			this.ns.showInfoMessage('Fetching email data from Exchange, this action could take some time');
		}
		else {
			doActionOnQuarantinedEmail = this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(emailObj))
		}

		doActionOnQuarantinedEmail.then((response) => {
            const attachment = {
                content: response.message,
                name: `${emailObj.subject}.eml`,
                contentType: 'message/rfc822'
            };
            this.gs.downloadData(attachment.content, attachment.name, attachment.contentType);
        }, err => {
            this.popupError = this.ns.getCurrentMessage();
        });
    };

    removeQuarantinedEmailPopup = (emails) => {
        this.reportToTrustifi = true;
		if (!emails.length) {
            return;
        }
        let title, subTitle, body;
        if (emails.length > 1) {
            title = 'Remove Quarantined Emails';
            subTitle = `Please note - you are about to remove ${emails.length} emails from quarantine`;
            body = ['Emails will be removed'];
        }
        else {
            title = 'Remove Quarantined Email';
            subTitle = `Please note - you are about to remove this email from ${emails[0].parent && emails[0].emails.length > 1 ? `${emails[0].emails.length} recipient mailboxes` : 'the recipient\'s inbox'}`;
            body = [`Email ${emails[0].subject} will be removed`];
        }
		this.removeQuarantinedPopup = {
			show: true,
			applyInProcess: false,
			title: title,
			subTitle: subTitle,
			removeOptionsToggles: [{
				labelName: 'Report to Trustifi (recommended)',
				tooltip: 'Our dedicated security team review each report daily and takes necessary actions to adjust our security features accordingly.\n' +
					'Based on feedback, our team can adjust the AI, add malicious senders to the global blocklist, enhance our security metrics and more.',
				model: this.reportToTrustifi
			}],
			body: body,
			doneCb: () => {
				this.removeQuarantinedPopup.applyInProcess = true;
				let releaseOptions:any = {};
				if (this.removeQuarantinedPopup.removeOptionsToggles && this.removeQuarantinedPopup.removeOptionsToggles[0] && this.removeQuarantinedPopup.removeOptionsToggles[0].model) {
					releaseOptions.reportToTrustifi = this.removeQuarantinedPopup.removeOptionsToggles[0].model;
				}
				this.removeQuarantinedPopup.applyInProcess = false;
				this.removeQuarantinedPopup.show = false;
				this.removeMultipleQuarantinedEmail(emails, 0, releaseOptions, () => {
					if (emails.length > 1) {
						this.ns.showInfoMessage(`${emails.length} quarantined email records were removed successfully`);
					}

					if (this.curEmail && this.curEmail.methods) {
						_.remove<any>(this.curEmail.methods, m => m === this.dic.CONSTANTS.quarantinedActions.remove.value);
						this.curEmail.methods.push(this.dic.CONSTANTS.quarantinedActions.release.value);
					}

					this.popupError = this.ns.getCurrentMessage();
				});
			}
		}
    };

    removeQuarantinedEmailExecute = (email, releaseOptions, cb) => {
        if (email.parent) {
            this.removeMultipleQuarantinedEmail(email.emails, 0, releaseOptions, () => {
                // update parent email only if operation succeeded
                if (email.emails[0].status === this.dic.CONSTANTS.quarantinedActions.remove.value) {
                    email.status = this.dic.CONSTANTS.quarantinedActions.remove.value;
                    email.reason = email.emails[0].reason;
                    this.addReviewerAction(email, null);
                }
                cb();
            });
        }
        else {
            if (email.status === this.dic.CONSTANTS.quarantinedActions.pending.value) {
                this.ns.showErrorMessage('Cannot remove email while the status is "Pending release"');
                return cb();
            }
            if (email.status !== this.dic.CONSTANTS.quarantinedActions.release.value) {
                return cb();
            }

            const data = {
				action: this.dic.CONSTANTS.quarantinedActions.remove.value,
				reportToTrustifi: releaseOptions.reportToTrustifi,
			};
            const emailId = getEmailId(email);
            this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(email)).then((response) => {
                email.status = response.status;
                email.reason = response.reason || '';

                this.addReviewerAction(email, response.reviewerActivity);
                cb();
            }, err => {
                this.popupError = this.ns.getCurrentMessage();
                cb();
            });
        }
    };

    removeMultipleQuarantinedEmail = (emails, idx, releaseOptions, cb) => {
        if (idx < emails.length) {
            this.removeQuarantinedEmailExecute(emails[idx], releaseOptions, () => {
                this.removeMultipleQuarantinedEmail(emails, idx + 1, releaseOptions, cb);
            });
        }
        else {
            cb();
        }
    };

    releaseMultipleQuarantinedEmail = (emails, idx, releaseOptions, cb) => {
        if (idx < emails.length) {
            this.releaseQuarantinedEmailExecute(emails[idx], releaseOptions, () => {
                this.releaseMultipleQuarantinedEmail(emails, idx + 1, releaseOptions, cb);
            });
        }
        else {
            cb();
        }
    };

    deleteQuarantinedEmailPopup = (emails) => {
        if (!emails.length) {
            return;
        }

		const body = ['You will no longer be able to apply actions to these emails'];
		if (emails[0].source === this.emailsSources.exchange) {
			body.push('These email records will be removed from the Exchange quarantine');
		}
        this.gs.showPopup({
            title: 'Delete Quarantined Email Records',
            subTitle: `Please note - you are about to delete ${emails.length} quarantined email's records`,
            body:  body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                this.deleteMultipleQuarantinedEmails(emails, () => {
                    if (emails.length > 1) {
                        this.ns.showInfoMessage(`${emails.length} Quarantined email records were deleted successfully`);
                    }
                    else {
                        this.ns.showInfoMessage(`Quarantined email record was deleted successfully`);
                    }

                    this.popupError = this.ns.getCurrentMessage();
                });
            }
        });
    };

	requestReleaseQuarantinedEmailPopup = (email) => {
		if (email.status === this.dic.CONSTANTS.quarantinedActions.pending.value
			|| email.status === this.dic.CONSTANTS.quarantinedActions.release.value) {
			return;
		}

		this.gs.showPopup({
			title: 'Request release of quarantined email',
			body:  ['You are about to send a request to release this email from quarantine'],
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'Request',
			doneCb: () => {
				const data = {
					action: this.dic.CONSTANTS.quarantinedActions.requestRelease.value,
				};
				const emailId = getEmailId(email);
				this.rs.doActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(this.curEmail)).then((response) => {
					email.status = this.dic.CONSTANTS.quarantinedActions.requestRelease.value;
					this.ns.showInfoMessage("Release request has been sent");
				});
			}
		});
	}


	deleteQuarantinedEmailExecute = (email, cb) => {
        if (email.parent) {
            this.deleteMultipleQuarantinedEmails(email.emails, () => {cb(); });

			// make sure to remove record's visible sub-emails rows first
			if (email.showSubMailboxes) {
				this.showHideSubMailboxes(email, false);
			}
			_.remove<any>(this.groupEmails, emailObj => emailObj._id === email._id);
			_.remove<any>(this.originalGroupEmails, emailObj => emailObj._id === email._id);
			this.checkSelectAllEmails();
        }
        else {
            if (email.status === this.dic.CONSTANTS.quarantinedActions.pending.value) {
                return cb();
            }

			const emailId = getEmailId(email);

			if (email.source === this.emailsSources.exchange) {
				const data = {action: this.dic.CONSTANTS.quarantinedActions.remove.value, id: emailId};
				this.rs.exchangeDoActionOnQuarantinedEmail(data).then(() => {cb();});
			}
			else {
				this.rs.deleteQuarantinedEmail(emailId, this.getPlanIdParams(email)).then(() => {cb();});
			}
        }
    };

    deleteMultipleQuarantinedEmails = (emails, cb) => {
		eachLimit(emails, 10, (emailObj, asyncCb) => {
			this.deleteQuarantinedEmailExecute(emailObj, asyncCb);
		}, () => {
			cb();
		});
    };

    downloadAttachment = (data, name, type) => {
        try {
            data = new Buffer(data, 'base64');
            this.gs.downloadData(data, name, type);
        } catch (err) {
            console.error(err);
        }
    };

    clearSearchMailbox = () => {
        this.searchMailboxTxt = null;
        this.groupEmails = _.clone(this.originalGroupEmails);
        // reopen subMailboxes rows if were open
        _.filter(this.groupEmails, 'showSubMailboxes').forEach(email => {
            this.showHideSubMailboxes(email, true);
        })
        if (this.activeFilters || this.activeTagAsFilter) {
            this.searchFilterMailbox(this.searchMailboxTxt);
        }
        this.checkSelectAllEmails();
    };

    searchFilterMailbox = (searchTerm) => {
        if ((!searchTerm || searchTerm === '') && (!this.activeFilters) && (!this.activeTagAsFilter)) {
            this.groupEmails = _.clone(this.originalGroupEmails);
            // reopen subMailboxes rows if were open
            _.filter(this.groupEmails, 'showSubMailboxes').forEach(email => {
                this.showHideSubMailboxes(email, true);
            })
            this.searchMailboxTxt = null;
            return;
        }
        if (searchTerm) {
            searchTerm = searchTerm.toLowerCase();
        }
        let generalMatchTypes = 0;
        if (searchTerm) generalMatchTypes++;
        if (this.activeFilters) generalMatchTypes++;
        if (this.activeTagAsFilter) generalMatchTypes++;

        this.groupEmails = _.filter(this.originalGroupEmails,  (emailsGroup) => {
            for (let i = 0; i < emailsGroup.emails.length; i++) {
                let email = emailsGroup.emails[i];

                let totalEmailMatch = 0;
                // Search
                if (searchTerm) {
                    if (!searchTextExecute(email, searchTerm)) {
                        continue;
                    }
                    totalEmailMatch++;
                }
                // Filter
                if (this.activeFilters) {
					const {positiveFilters, negativeFilters} = this.gs.splitTableFilters(this.activeFilters);
					// positive filters check
					if (searchFilterExecute(this.dic, email, positiveFilters)) {
						continue;
					}
					// negative filters check
					if (!_.isEmpty(negativeFilters) && searchFilterExecute(this.dic, email, negativeFilters) !== Object.keys(negativeFilters).length) {
						continue;
					}

                    totalEmailMatch++;
                }

                // Filter (Tags)
                if (this.activeTagAsFilter) {
                    const tags = [
                        email.malicious && email.malicious.tpResults && email.malicious.tpResults.resHead && email.malicious.tpResults.resHead.sub_status && email.malicious.tpResults.resHead.sub_status.length && email.malicious.tpResults.resHead.status !== this.dic.CONSTANTS.threatProtection.status.safe ? email.malicious.tpResults.resHead.sub_status : null,
                        email.malicious && email.malicious.tpResults && email.malicious.tpResults.resUrl && email.malicious.tpResults.resUrl.sub_status && email.malicious.tpResults.resUrl.sub_status.length && email.malicious.tpResults.resUrl.status !== this.dic.CONSTANTS.threatProtection.status.safe ? email.malicious.tpResults.resUrl.sub_status : null,
                        email.malicious && email.malicious.tpResults && email.malicious.tpResults.resHash && email.malicious.tpResults.resHash.sub_status && email.malicious.tpResults.resHash.sub_status.length && email.malicious.tpResults.resHash.status !== this.dic.CONSTANTS.threatProtection.status.safe ? email.malicious.tpResults.resHash.sub_status : null
                    ]

                    if (tags.includes(this.activeTagAsFilter)) {
                        totalEmailMatch++
                    }
                }

                if (totalEmailMatch === generalMatchTypes) {
                    if (emailsGroup.showSubMailboxes) {
                        this.showHideSubMailboxes(email, true);
                    }
                    return true;
                }
            }
            return false;
        });
    };

    setTagAsFilter = (tagName) => {
        this.closeItemActions(this.curEmail);
        this.activeTagAsFilter = tagName;
        this.searchFilterMailbox(this.searchMailboxTxt);
    };

    selectEmailItem = () => {
        this.closeItemActions(this.curEmail);
        this.checkSelectAllEmails();
    };

    checkSelectAllEmails = () => {
        this.filterSelectedEmails();
        this.selectedAll = this.selectedEmails.length === _.reject(this.groupEmails,'parent_id').length; // _.reject returns array after filtering items out of it
    };

    filterSelectedEmails = () => {
        this.selectedEmails = _.filter(this.groupEmails, email => {
            if (email.parent_id) {
                email.selected = _.find(this.groupEmails,  emailObj => email.parent_id === emailObj._id).selected;
            }
            return email.selected && !email.parent_id;
        });

        this.selectedEmailsCount = this.selectedEmails.length;
    };

    sortUpdate = (sortBy) => {
        this.orderBy = sortBy;
		const orderArrayDirection = this.orderBy.startsWith('-') ? 'desc' : 'asc';
		let orderBy = this.orderBy;

		if (this.orderBy.startsWith('-')) {
			orderBy = orderBy.substring(1);
		}

		this.groupEmails = _.orderBy(this.groupEmails, [orderBy], [orderArrayDirection]);
	};

    toggleAllEmails = () => {
        _.map(this.groupEmails, email => {
            email.selected = this.selectedAll;
            return email;
        });
        this.filterSelectedEmails();

        this.closeItemActions(this.curEmail);
    };

    calculateTags = (email) => {
        let threatTags = [];
        if (email.malicious && email.malicious.tpResults) {
            if (email.malicious.tpResults.resHead && email.malicious.tpResults.resHead.sub_status && email.malicious.tpResults.resHead.sub_status.length && email.malicious.tpResults.resHead.status !== this.dic.CONSTANTS.threatProtection.status.safe) {
                const tagInfo = {
                    status: email.malicious.tpResults.resHead.status,
                    name: email.malicious.tpResults.resHead.sub_status,
                    colorObj: this.setInboundStatusStyles(email.malicious.tpResults.resHead.status)
                };
                threatTags.push(tagInfo);
            }
            if (email.malicious.tpResults.resUrl && email.malicious.tpResults.resUrl.sub_status && email.malicious.tpResults.resUrl.sub_status.length && email.malicious.tpResults.resUrl.status !== this.dic.CONSTANTS.threatProtection.status.safe) {
                const tagInfo = {
                    status: email.malicious.tpResults.resUrl.status,
                    name: email.malicious.tpResults.resUrl.sub_status,
                    colorObj: this.setInboundStatusStyles(email.malicious.tpResults.resUrl.status)
                };
                threatTags.push(tagInfo);
            }
            if (email.malicious.tpResults.resHash && email.malicious.tpResults.resHash.sub_status && email.malicious.tpResults.resHash.sub_status.length && email.malicious.tpResults.resHash.status !== this.dic.CONSTANTS.threatProtection.status.safe) {
                const tagInfo = {
                    status: email.malicious.tpResults.resHash.status,
                    name: email.malicious.tpResults.resHash.sub_status,
                    colorObj: this.setInboundStatusStyles(email.malicious.tpResults.resHash.status)
                };
                threatTags.push(tagInfo);
            }
        }

        // merge duplicates and assign to email object
        threatTags = _.uniqBy(threatTags, tag => {return tag.name;});
        email.threatTags = threatTags;
    }

    setEmailCategory = (email) => {
        if (email.malicious) {
            if (email.malicious.status_user_reported) {
                this.setEmailCategoryExecute(email, email.malicious.status_user_reported);
            }
            else {
                this.setEmailCategoryExecute(email, email.malicious.status);
            }
        }
    };

    setEmailCategoryExecute = (email, status) => {
        if (!email || !status) {
            return;
        }
        email.category = status;
        switch (status.toLowerCase()) {
            case this.dic.CONSTANTS.tpRule.name.malicious:
                email.color = 'darkred';
                email.category_priority = 1;
                break;

            case this.dic.CONSTANTS.tpRule.name.suspicious:
                email.color = 'darkred';
                email.category_priority = 2;
                break;

            case this.dic.CONSTANTS.tpRule.name.spam:
                email.color = 'darkorange';
                email.category_priority = 3;
                break;

            case this.dic.CONSTANTS.tpRule.name.graymail:
                email.color = 'dimgray';
                email.category_priority = 4;
                break;

            case this.dic.CONSTANTS.tpRule.name.external:
                email.color = 'darkorange';
                email.category_priority = 5;
                break;

            // safe
            default:
                email.color = 'darkgreen';
                email.category_priority = 6;
        }
    };


    exportQuarantinedCsv = () => {
        if (!this.groupEmails.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Created,Mailbox,Sender,Subject,Attachments,Status,Category,Tags\n";
        let emails = [];
        this.groupEmails.forEach((groupEmail) => {
            if (groupEmail.parent_id) {
                return;
            }
            emails = emails.concat(groupEmail.emails);
        });

        let sortedTable = this.gs.sortTable(emails, this.orderBy);
        sortedTable.forEach((email) => {
            let tags = '';
            if (email.malicious && email.malicious.tpResults) {
                tags = (email.malicious.tpResults.resHead && email.malicious.tpResults.resHead.sub_status && email.malicious.tpResults.resHead.sub_status.length && email.malicious.tpResults.resHead.status !== this.dic.CONSTANTS.threatProtection.status.safe && email.malicious.tpResults.resHead.sub_status.toString() + '. ' || '') +
                    (email.malicious.tpResults.resUrl && email.malicious.tpResults.resUrl.sub_status && email.malicious.tpResults.resUrl.sub_status.length && email.malicious.tpResults.resUrl.status !== this.dic.CONSTANTS.threatProtection.status.safe && email.malicious.tpResults.resUrl.sub_status.toString() + '. ' || '') +
                    (email.malicious.tpResults.resHash && email.malicious.tpResults.resHash.sub_status && email.malicious.tpResults.resHash.sub_status.length && email.malicious.tpResults.resHash.status !== this.dic.CONSTANTS.threatProtection.status.safe && email.malicious.tpResults.resHash.sub_status.toString()|| '');
            }

			const created = new Date(email.created);
			const createdStr = `${created.toLocaleDateString()} ${created.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })}`;
            csvString += `${createdStr},${email.mailbox_email},${email.sender},"${email.subject}","${email.attachments || 0}","${email.reason ? this.gs.toCapitalize(email.status)+' ('+email.reason+')' : this.gs.toCapitalize(email.status)}","${email.category}","${tags}"\n`;
        });

        this.gs.exportCsv(csvString, `quarantined_emails.csv`);
    };

    exportHeadersText = () => {
        if (!this.curEmail.headers) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "";
        this.curEmail.headers.forEach((header) => {
			if (!header.hide) {
            	csvString += `${header.name || header.key}: ${header.value}\n`;
			}
        });

        this.gs.exportCsv(csvString, `quarantined_email_headers.txt`);
    };

    initFilters = () => {
        this.filterData = {
			filterType: this.dic.CONSTANTS.tableFilters.quarantined,
            filters: {
                status: ['Quarantined', 'Released by rule', 'Released by reviewer/recipient', 'Pending release', 'Requested release', 'Release request rejected', 'Release failure', 'Removed by reviewer'],
                category: ['Malicious', 'Suspicious', 'Spam', 'Graymail', 'External', 'Safe'],
                'sub category': ['Allowlist', 'Blocklist', 'Business Email Compromise', 'Graymail', 'Impersonation', 'Malware', 'Phishing', 'Spam', 'Spoofing', 'User Preferences'],
                'Sender Type': ['External sender', 'Internal sender', 'Free email domain', 'Top domain', 'Unknown sender'],
				'has links': ['Yes', 'No'],
                'has attachments': ['Yes', 'No'],
				'Preserve record': ['Yes', 'No'],
				'reported by': ['Reviewer', 'Recipient', 'Third party'],
				subject: ['Finance', 'Account', 'Notification', 'Validation', 'RFQ'],
                handled: [true, false],
            },
			tableFilterTemplatesRefName: this.dic.CONSTANTS.tableFilterTemplatesRefNames.quarantine,
			enableNegativeOptions: true,
			initFiltersFunction: this.initFilters
        };

        if (this.reviewerInfo.is_security_reviewer) {
            this.filterData.filters.follow = [true, false];
        }

        if (this.filterData.saveAsDefault) {
            this.activeFilters = this.gs.getTableFilter(this.dic.CONSTANTS.tableFilters.quarantined);
        }
    };

	toggleFilter =  () => {
		if (!this.filterData) return;
		this.filterData.show = !this.filterData.show;
	};

	clearFilters =  () => {
		this.activeFilters = null;
		this.searchFilterMailbox(this.searchMailboxTxt);
	};

	applyFilters(activeFilters) {
		if (!activeFilters) return;

		this.activeFilters = activeFilters;
		this.filterData.show = false;

		if (this.originalGroupEmails?.length) {
			this.searchFilterMailbox(this.searchMailboxTxt);
		}
	};

	openNotifyOptionsPopup = () => {
		// create model for notifyReviewerPeriods dropdown selection (initial values from this.config)
		const notifyReviewerPeriods = {};
		_.forEach(this.dic.CONSTANTS.tpRule.notifyOptions,(periodName, periodKey) => {
			notifyReviewerPeriods[periodName] = this.mailboxInfo.notify_reviewer_periods && this.mailboxInfo.notify_reviewer_periods.includes(periodName);
		});
		let notifyReviewerDailyHours = [];
		if (this.mailboxInfo.notify_reviewer_daily_hour) {
			notifyReviewerDailyHours = this.mailboxInfo.notify_reviewer_daily_hour.split(', ');
			for (let i = 0; i < notifyReviewerDailyHours.length; i++) {
				let formattedHour:any = new Date();
				formattedHour.setUTCHours(notifyReviewerDailyHours[i]);
				formattedHour = (formattedHour.getHours() < 10 ? '0' : '') + formattedHour.getHours();
				notifyReviewerDailyHours[i] = formattedHour;
			}
		}

		this.notifyOptionsPopup = {
			periods: notifyReviewerPeriods,
			dailyHours: notifyReviewerDailyHours,
			isNotifyReviewerPeriodsError: false,
			isNotifyReviewerDailyHoursError: false,
			changeNotifyPeriodInProcess: false,
			show: true,
		};
	}

	changeNotifyPeriod = () => {
		if (this.notifyOptionsPopup.changeNotifyPeriodInProcess) {
			return;
		}

		// periods validation
		const isOneReviewerPeriodEnabled = _.some(this.notifyOptionsPopup.periods, period => {return !!period});
		if (!isOneReviewerPeriodEnabled) {
			this.notifyOptionsPopup.isNotifyReviewerPeriodsError = true;
		}

		this.notifyOptionsPopup.isNotifyReviewerDailyHoursError = this.notifyOptionsPopup.periods[this.dic.CONSTANTS.tpRule.notifyOptions.everyDay] && !this.notifyOptionsPopup.dailyHours.length;

		if (!isOneReviewerPeriodEnabled || this.notifyOptionsPopup.isNotifyReviewerDailyHoursError) {
			return;
		}
		//

		// make arrays of strings from the periods objects (pick only enabled periods)
		const notifyReviewerPeriodsArr = _.keys(_.pickBy(this.notifyOptionsPopup.periods));

		// turn the local hours in the "daily_hour" models into UTC hours for the BE
		let notifyReviewerDailyHoursUTC = _.cloneDeep(this.notifyOptionsPopup.dailyHours);
		for (let i =0 ; i < notifyReviewerDailyHoursUTC.length; i++) {
			let formattedHour:any = new Date();
			formattedHour.setHours(notifyReviewerDailyHoursUTC[i]);
			formattedHour = (formattedHour.getUTCHours() < 10 ? '0' : '') + formattedHour.getUTCHours();
			notifyReviewerDailyHoursUTC[i] = formattedHour;
		}
		//

		const data = {
			notifyReviewerPeriods: notifyReviewerPeriodsArr,
			notify_reviewer_daily_hour: notifyReviewerDailyHoursUTC.join(', '),
		};

		this.notifyOptionsPopup.changeNotifyPeriodInProcess = true;

		this.rs.updateMailboxNotification(data).then( (response) => {
			this.mailboxInfo.notify_reviewer_periods = notifyReviewerPeriodsArr;
			this.mailboxInfo.notify_reviewer_daily_hour = notifyReviewerDailyHoursUTC.join(', ');
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);

			this.notifyOptionsPopup = null;
		}, (err) => {
			if (err && err.data && err.data.message) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.notifyOptionsPopup.changeNotifyPeriodInProcess = false;
		});
	};

	searchReviewer = () => {
		this.curEmail?.reviewer_actions?.forEach(actionObj => {
			// search
			if (this.reviewerSearchTerm) {
				const isFound = searchReviewersExecute(actionObj, this.reviewerSearchTerm);
				if (!isFound) {
					actionObj.hide = true;
					return;
				}

			}
			actionObj.hide = false;
		});
	}

	searchHeader = () => {
		this.curEmail?.headers?.forEach(headerObj => {
			// search
			if (this.headerSearchTerm) {
				const isFound = searchHeadersExecute(headerObj, this.headerSearchTerm);
				if (!isFound) {
					headerObj.hide = true;
					return;
				}

			}
			headerObj.hide = false;
		});
	}

	searchPlan(searchTerm) {
		searchTerm = (searchTerm || '').toLowerCase();

		// Go through viewPlanList and update hide property based on match
		this.viewPlanList.forEach(plan => {
			// Check if the plan matches the search term
			plan.hide = !plan.display_email.toLowerCase().includes(searchTerm);
		});
	}

	clearSearchPlan() {
		this.searchPlanTxt = '';
		this.viewPlanList.forEach(plan => {
			plan.hide = false;
		})
	}

	getDailyHoursText = (listOfHours) => {
		return _.map(listOfHours, hourStr => hourStr + ':00');
	}

	toggleNotificationDailyHour = (listOfHours, targetHour) => {
		if (listOfHours.includes(targetHour)) {
			_.remove(listOfHours, hour => hour === targetHour);
		}
		else {
			listOfHours.push(targetHour);
		}
	}

	openRescanLinkPopup = (url) => {
		if (url.rescanLinkInProcess) return;

		this.gs.showPopup({
			title: 'Rescan Link',
			subTitle: "The process will rescan the URL and update the results in the Threat Analysis section. Allow/Block lists will be ignored.",
			doneBtnText: 'Confirm',
			type: this.dic.CONSTANTS.popupInfo,
			doneCb: () => {
				url.rescanLinkInProcess = true;

				const data:any = {
					action: this.dic.CONSTANTS.quarantinedActions.rescanLink.value,
					url: url.url
				};
				const emailId = getEmailId(this.curEmail);
				this.rs.doHeavyActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(this.curEmail)).then((response) => {
					url.rescanLinkInProcess = false;
					this.checkViewThreatsReScanStatus(this.curEmail, 0);
				}, err => {
					url.rescanLinkInProcess = false;
				});
			}
		});
	}

	openRescanFilePopup = (file) => {
		if (file.rescanFileInProcess) return;

		this.gs.showPopup({
			title: 'Rescan File',
			subTitle: 'The process will rescan the file and update the results in the Threat Analysis section. Allow/Block lists will be ignored.',
			doneBtnText: 'Confirm',
			type: this.dic.CONSTANTS.popupInfo,
			doneCb: () => {
				file.rescanFileInProcess = true;
				const data:any = {
					action: this.dic.CONSTANTS.quarantinedActions.rescanFile.value,
					fileHash: file.hash,
				};
				const emailId = getEmailId(this.curEmail);
				this.rs.doHeavyActionOnQuarantinedEmail(emailId, data, this.getPlanIdParamsForQuarantineAction(this.curEmail)).then((response) => {
					file.rescanFileInProcess = false;
					this.checkViewThreatsReScanStatus(this.curEmail, 0);
				}, err => {
					file.rescanLinkInProcess = false;
				});
			}
		});
	}

	updateEmailStatus = () => {
		const statuses = [this.curTPResults.urlStatus, this.curTPResults.hashStatus, this.curTPResults.headerStatus];

		// Determine the status with the minimum priority value
		let highestPriorityStatus = _.minBy(statuses, (status) => this.statusPriority[status]);
		if (highestPriorityStatus === this.dic.CONSTANTS.threatProtection.status.safe && this.curTPResults.resHead.isExternal) {
			highestPriorityStatus = 'External';
		}
		this.curTPResults.status = highestPriorityStatus;
		this.curEmail.malicious.status = highestPriorityStatus;
	}

	showRelationshipConnectionGraph = () => {
		const data = {
			sender: this.curTPResults.resHead.from.senderMailFrom
		};

		this.senderConnectionGraphPopup = {
			rawData: null,
			graphData: [],
			nodeOptionsFn: (node, allLinks) => {
				node.color = _.find(allLinks, {target: node.name}) ? 'green' : '';
			},
			nodeSearchTxt: '',
			nodeSearchResults: null,
			scope: '',
			show: true,
			loading: true
		};

		this.rs.getSenderConnectionGraphData(data).then(response => {
			this.senderConnectionGraphPopup.loading = false;

			// filter out emails that a user sent to himself
			response = _.reject(response, contact => contact.email === contact.user_email);

			this.senderConnectionGraphPopup.rawData = response;

			setTimeout(() => {
				this.updateConnectionGraphScope('sender');
			})
		}, err => {
			this.senderConnectionGraphPopup.loading = false;
		});
	}

	updateConnectionGraphScope = (scope) => {
		this.senderConnectionGraphPopup.scope = scope;

		let data;
		if (scope === 'sender') {
			data = _.filter(this.senderConnectionGraphPopup.rawData, {email: this.curTPResults.resHead.from.senderMailFrom});
		}
		else {
			data = this.senderConnectionGraphPopup.rawData;
		}

		if (!data?.length) {
			this.senderConnectionGraphPopup.graphData = null;
			return;
		}

		this.senderConnectionGraphPopup.graphData = {
			links: _.map(data, contact => ({source: contact.email, target: contact.user_email})),
		};
	}

	searchConnectionEmailAddress = () => {
		let data;
		if (this.senderConnectionGraphPopup.scope === 'sender') {
			data = _.filter(this.senderConnectionGraphPopup.rawData, {email: this.curTPResults.resHead.from.senderMailFrom});
		}
		else {
			data = this.senderConnectionGraphPopup.rawData;
		}

		if (this.senderConnectionGraphPopup.nodeSearchTxt && data?.length) {
			this.senderConnectionGraphPopup.nodeSearchResults = [];

			data.forEach(contact => {
				if (contact.email.includes(this.senderConnectionGraphPopup.nodeSearchTxt)) {
					this.senderConnectionGraphPopup.nodeSearchResults.push(contact.email);
				}
				if (contact.user_email.includes(this.senderConnectionGraphPopup.nodeSearchTxt)) {
					this.senderConnectionGraphPopup.nodeSearchResults.push(contact.user_email);
				}
			});

			this.senderConnectionGraphPopup.nodeSearchResults = _.union(this.senderConnectionGraphPopup.nodeSearchResults);
		}
		else {
			this.senderConnectionGraphPopup.nodeSearchResults = null;
		}
	}
}



function getEmailId(email) {
    return email.parent ? email.emails[0]._id : email._id;
}

function calcParentEmailCategory(parentEmail) {
    if (!parentEmail.emails || !parentEmail.emails.length) {
        return;
    }

    let emailPriority = parentEmail.emails[0];
    parentEmail.keep_forever = parentEmail.emails[0].keep_forever;
    parentEmail.emails.forEach((emailObj) => {
        if (emailObj.category !== emailPriority.category) {
            parentEmail.diffCategory = true;
        }
        if (emailObj.category_priority < emailPriority.category_priority) {
            emailPriority = emailObj;
        }
        parentEmail.keep_forever = parentEmail.keep_forever && emailObj.keep_forever;
    });

    // general data for showing the group as message in the UI
    parentEmail.threatTags = emailPriority.threatTags;
    parentEmail.malicious = emailPriority.malicious;
    parentEmail.reason = emailPriority.reason;
    parentEmail.category = emailPriority.category;
    parentEmail.color = emailPriority.color;
    parentEmail.category_priority = emailPriority.category_priority;
    parentEmail.status = emailPriority.status;
}

function searchTextExecute(email, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return ((email.mailbox_email && email.mailbox_email.toLowerCase().indexOf(searchTerm) > -1) ||
        (email.sender && email.sender.toLowerCase().indexOf(searchTerm) > -1) ||
        (email.subject && ((email.subject.toLowerCase().indexOf(searchTerm) > -1) ||
            (email.malicious.tpResults.resHead?.from?.senderMailFrom.toLowerCase().indexOf(searchTerm) > -1) ||
			(email.malicious.tpResults.resHead?.from?.receivedSPF?.domain.toLowerCase().indexOf(searchTerm) > -1) ||
			(email.malicious.tpResults.resHead?.from?.ipAddresses.includes(searchTerm)))) ||
        (email.message_id && email.message_id.toLowerCase().indexOf(searchTerm) > -1) ||
        (email.threatTags && email.threatTags.length && email.threatTags.find(itm => itm.name.toLowerCase().indexOf(searchTerm) > -1)));
}

function searchFilterExecute(dic, email, filters) {
    // need to match all filter types
    let numFilterToMatch = Object.keys(filters).length;
    if (filters.status && filters.status.length) {
        if (filters.status.includes('Quarantined') && email.status === dic.CONSTANTS.quarantinedActions.quarantine.value) {
            numFilterToMatch--;
        } else if (filters.status.includes('Released by rule') && email.status === dic.CONSTANTS.quarantinedActions.release.value &&
            email.reason === 'Rule') {
            numFilterToMatch--;
        } else if (filters.status.includes('Released by reviewer/recipient') && email.status === dic.CONSTANTS.quarantinedActions.release.value &&
            email.reason !== 'Rule') {
            numFilterToMatch--;
        } else if (filters.status.includes('Removed by reviewer') && email.status === dic.CONSTANTS.quarantinedActions.remove.value &&
            email.reason !== 'Rule') {
            numFilterToMatch--;
        } else if (filters.status.includes('Pending release') && email.status === dic.CONSTANTS.quarantinedActions.pending.value) {
            numFilterToMatch--;
        } else if (filters.status.includes('Release failure') && email.status === dic.CONSTANTS.quarantinedActions.releaseFailure.value) {
            numFilterToMatch--;
        } else if (filters.status.includes('Requested release') && email.status === dic.CONSTANTS.quarantinedActions.requestRelease.value) {
            numFilterToMatch--;
        } else if (filters.status.includes('Release request rejected') && email.reason === 'Release request rejected') {
			numFilterToMatch--;
		}

    }

    if (filters.category && filters.category.length) {
        if (filters.category.includes(email.category)) {
            numFilterToMatch--;
        }
    }

    if (filters['sub category'] && filters['sub category'].length) {
        if ((email.malicious.tpResults.resHead && filters['sub category'].includes(email.malicious.tpResults.resHead.category)) ||
            (email.malicious.tpResults.resHash && filters['sub category'].includes(email.malicious.tpResults.resHash.category)) ||
            (email.malicious.tpResults.resUrl && filters['sub category'].includes(email.malicious.tpResults.resUrl.category))) {
            numFilterToMatch--;
        }
    }

	if (filters['has links'] && filters['has links'].length) {
		if ((filters['has links'].includes('Yes') && email.links) ||
			(filters['has links'].includes('No') && !email.links)) {
			numFilterToMatch--;
		}
	}

    if (filters['has attachments'] && filters['has attachments'].length) {
        if ((filters['has attachments'].includes('Yes') && email.attachments) ||
            (filters['has attachments'].includes('No') && !email.attachments)) {
            numFilterToMatch--;
        }
    }

	if (filters['Preserve record'] && filters['Preserve record'].length) {
		if ((filters['Preserve record'].includes('Yes') && email.keep_forever) ||
			(filters['Preserve record'].includes('No') && !email.keep_forever)) {
			numFilterToMatch--;
		}
	}

    if (filters['reported by'] && filters['reported by'].length) {
        if ((filters['reported by'].includes('Reviewer') && email.report?.report_by === 'reviewer') ||
            (filters['reported by'].includes('Recipient') && email.report?.report_by === 'recipient') ||
			(filters['reported by'].includes('Third party') && email.report?.report_by === 'Microsoft')) {
            numFilterToMatch--;
        }
    }

    if (filters.handled && filters.handled.length) {
        if (filters.handled.includes(email.reviewed_completed)) {
            numFilterToMatch--;
        }
    }

    if (filters.follow && filters.follow.length) {
        if (filters.follow.includes(email.mark_follow)) {
            numFilterToMatch--;
        }
    }

    if (filters['Sender Type'] && filters['Sender Type'].length) {
        if (email.malicious.tpResults.resHead) {
            if ((filters['Sender Type'].includes('External sender') && email.malicious.tpResults.resHead.isExternal) ||
                (filters['Sender Type'].includes('Internal sender') && !email.malicious.tpResults.resHead.isExternal) ||
                (filters['Sender Type'].includes('Free email domain') && email.malicious.tpResults.resHead.isFreeEmail) ||
                (filters['Sender Type'].includes('Top domain') && (email.malicious.tpResults.resHead.isTopDomain || email.malicious.tpResults.resHead.isBrandService)) ||
				(filters['Sender Type'].includes('Unknown sender') && email.malicious.tpResults.resHead.isUnknownSender)) {
                numFilterToMatch--;
            }
        }
    }

    if (filters.subject && filters.subject.length) {
        if (email.subject) {
            let isSubjectFound = false;
            const emailLowerCase = email.subject.toLowerCase();
            for (let i = 0; i < filters.subject.length; i++) {
                if ((filters.subject[i] === 'Finance' && ['invoice', 'receipt', 'payment', 'statement', 'bill', 'fund', 'loan', 'deposit'].some(itm => emailLowerCase.includes(itm))) ||
                    (filters.subject[i] === 'Account' && ['activate', 'activities', 'activation', 'license', 'licensing', 'password'].some(itm => emailLowerCase.includes(itm))) ||
                    (filters.subject[i] === 'Notification' &&  ['notification', 'alert', 'report', 'require'].some(itm => emailLowerCase.includes(itm))) ||
                    (filters.subject[i] === 'Validation' && ['pending', 'verify', 'verified', 'verification','confirm', 'certificate', 'certification'].some(itm => emailLowerCase.includes(itm))) ||
                    (filters.subject[i] === 'RFQ' && ['rfq', 'request', 'quote', 'quotation', 'inquiry'].some(itm => emailLowerCase.includes(itm)))) {
                    isSubjectFound = true;
                }
            }
            if (isSubjectFound) {
                numFilterToMatch--;
            }
        }
    }

    return numFilterToMatch;
}

function getCurrentMailbox(emailObj) {
    if (emailObj.parent && emailObj.emails.length) {
        return emailObj.curRecipient.mailbox_email;
    }
    else {
        return emailObj.mailbox_email.toString();
    }
}

function diffMinutes(dt2, dt1) {
    let diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}


function initializeValues(obj) {
	for (let key in obj) {
		obj[key] = false;
	}
}

function searchHeadersExecute(obj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((obj.name?.toLowerCase().indexOf(searchTerm) > -1) ||
		((obj.key?.toLowerCase().indexOf(searchTerm) > -1)) ||
		(obj.value?.toLowerCase().indexOf(searchTerm) > -1));
}

function searchReviewersExecute(obj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((obj.reviewer?.toLowerCase().indexOf(searchTerm) > -1) ||
		(obj.action?.toLowerCase().indexOf(searchTerm) > -1));
}

