import {Component, EventEmitter, Input, Output} from "@angular/core";
import {GeneralService} from "src/app/services/generalService";
import _ from "lodash";
import {AuthService} from "../../services/authService";
import {DICTIONARY} from "../../dictionary";
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {Router} from "@angular/router";

@Component({
	selector: 'password-login-component',
	templateUrl: './password-login.component.html',
})

export class PasswordLoginComponent {
	@Input() userEmailInput;
	@Input() password;
	@Input() loginStatuses;
	@Input() error;
	@Output() loginStatusChange: EventEmitter<any> = new EventEmitter();


	constructor(private rs:RouteService,
				private router:Router,
				public gs:GeneralService,
				private authService:AuthService,
				private ns:NotificationService) {
	}

	dic = DICTIONARY;
	_=_;
	showPassword;
	forgotPasswordError;
	changePasswordTimer;
	successMessage;
	loading;
	loginStatus;

	continue = () => {
		this.forgotPasswordError = null;
		this.error = false;
		this.successMessage = false;

		if (!this.userEmailInput || !this.password) {
			if (!this.password) {
				this.error = 'Enter your password to log in';
			}
			return;
		}

		let creds = {
			username: this.userEmailInput.trim(),
			password: this.password.trim()
		};

		this.error = false;
		this.loading = true;
		this.authService.login(creds, err => {
			if (err) {
				// MFA
				if (err.status === 406) {
					this.error = false;
					this.loading = false;
				}
				// other errors
				else {
					//server is not responding
					if (err.status === -1) {
						this.error = this.dic.ERRORS.serverNotResponding;
					}
					else {
						if (typeof err !== 'string') {
							err = (err && err.data && err.data.message) || DICTIONARY.ERRORS.authSignInError;
						}

						this.error = err;
					}
					this.loading = false;
				}
			}
			else {
				// redirections after successful login:
				// go to inbound quarantined -apply query by message id
				if (window.history.state.quarantinedMessageId) {
					this.router.navigate([this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.quarantined], {state: window.history.state});
				}
				// My plan page
				else if (window.history.state.company) {
					this.router.navigate([this.dic.CONSTANTS.appStates.accountDetails, this.dic.CONSTANTS.accountDetailsPages.myPlan], {state: window.history.state});
				}
				// global
				else if (window.history.state.transitionName) {
					this.router.navigate([window.history.state.transitionName], {state: window.history.state.transitionParams});
				}
				// default
				else {
					this.router.navigate([this.dic.CONSTANTS.appStates.home]);
				}
			}
		});
	};

	updateLoginStatus = (newStatus) => {
		this.error = false;
		this.successMessage = false;
		this.loginStatus = newStatus;
		this.loginStatusChange.emit({ loginStatus: this.loginStatus,
			userEmailInput: this.userEmailInput,
			successMessage: this.successMessage,
			error: this.error});
	}

	changePassword = () => {
		this.error = false;
		if (!this.userEmailInput || !this.gs.validateEmail(this.userEmailInput)) {
			this.error = this.dic.ERRORS.forgotPassword;
			return;
		}

		if (this.changePasswordDisabled()) return;
		this.changePasswordTimer = Date.now();

		this.authService.changePassword(this.userEmailInput,  (err, message) => {
			if (err) {
				console.log(err);
				this.error = 'Failed to change password';
				return;
			}
			this.ns.showInfoMessage(this.dic.MESSAGES.passwordChange);
			this.successMessage = message;
		});

	};

	changePasswordDisabled = () => {
		return this.changePasswordTimer && Date.now() - this.changePasswordTimer < 10000;
	};
}
