<div>
    <div class="flex-column gap-4 animated fadeIn">
        <span>Enable Multi-Factor Authentication and configure the authentication method</span>
        <div class="list-group list-group-flush settings-list-container">
            <div class="list-group-item settings-list-item" style="border-bottom: unset;">
                <div class="title-section-container flex-column gap-2" style="flex-basis: 45px;">
                    <b>Multi-Factor Authentication for account log in</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;">Make your account more secure by using Multi-Factor authentication for logging in</span>
                </div>
                <div class="content-container flex-row align-items-center gap-2">
                    <i *ngIf="require_2fa.strict"
                       tooltip="Log-in Multi-Factor authentication enforced by your Plan Admin"
                       style="color:#818181; padding-left:5px;" class="zmdi zmdi-lock-outline"></i>
                    <toggle-switch-c id="MFAToggle"
                                   [(model)]="require_2fa.enabled"
                                   (change)="update2FAStatusPopup()"
                                   [isDisabled]="require_2fa.strict"></toggle-switch-c>
                </div>
            </div>
            <div class="list-group-item settings-list-item">
                <div class="title-section-container flex-column gap-2" style="flex-basis: 45px;">
                    <b>Choose Authentication method</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;">Choose which method will be used for log-in authentication</span>
                </div>
                <div class="content-container flex-column gap-3" [ngStyle]="!require_2fa.enabled && {'pointer-events' : 'none', 'opacity' : '0.5'}">
                    <label class="radio-container m-0">PIN code to phone number
                        <input type="radio" name="mfaMethod" [value]="dic.CONSTANTS.secure2faMethods.phone"
                               [(ngModel)]="require_2fa.method"
                               [checked]="require_2fa.method === dic.CONSTANTS.secure2faMethods.phone"
                               (change)="check2FaDiff()">
                        <span class="checkmark"></span>
                    </label>

                    <div class="flex-row align-items-center">
                        <label class="radio-container m-0" style="width: 250px;">
							PIN code to email address
                            <input type="radio" name="mfaMethod" [value]="dic.CONSTANTS.secure2faMethods.email"
                                   [(ngModel)]="require_2fa.method"
                                   [checked]="require_2fa.method === dic.CONSTANTS.secure2faMethods.email"
                                   (change)="check2FaDiff()" style="border-color: gray;">
                            <span class="checkmark"></span>
                            <i style="font-weight: 400;" class="fas fa-question-circle"
                               tooltip='You can use any secondary email address which is verified under your "Account Addresses". Otherwise, your primary email address will be used'>
                            </i>
                        </label>

                        <input [(ngModel)]="require_2fa.email" type="text" style="margin-left: 30px; width: 200px;" [name]="'newEmail'+ random"
                               (change)="check2FaDiff()"
							   placeholder="My Email"
                               [ngClass]="{'disabled': require_2fa.method !== dic.CONSTANTS.secure2faMethods.email || !require_2fa.enabled}">
                    </div>

                    <div class="flex-row gap-3 align-items-start" >
                        <label class="radio-container m-0" style="display: inline-block; width: 264px;">Time-based One-Time Password (TOTP)
                            <input type="radio" name="mfaMethod" [value]="dic.CONSTANTS.secure2faMethods.totp"
                                   [(ngModel)]="require_2fa.method"
                                   [checked]="require_2fa.method === dic.CONSTANTS.secure2faMethods.totp"
                                   (change)="check2FaDiff()">
                            <span class="checkmark"></span>
                            <div style="font-size:11px; color:#818181; max-width: 180px;">
                                This option will require using a separate TOTP application
                            </div>
                        </label>
                        <btn-c id="myAccountGetQrCode"
                             variant="outlined" endIcon="fas fa-qrcode"
                             [isDisabled]="require_2fa.method !== dic.CONSTANTS.secure2faMethods.totp || !require_2fa.enabled"
                             (action)="getQrCodeFor2fa();">
                            Generate QR code
                        </btn-c>
                    </div>
                    <btn-c class="mt-2"
                         [isDisabled]="update2FaDiff"
                         (action)="update2FAMethod();">
                        Update
                    </btn-c>
                </div>
            </div>
            <!--Change password-->
            <div class="list-group-item settings-list-item">
                <div class="title-section-container flex-column gap-2" style="flex-basis: 45px;">
                    <b>Change Account Password</b>
                    <span class="text-muted lh-base" style="font-size: 0.9em;">We will send you an email to reset your Trustifi login password</span>
                </div>
                <div class="content-container flex-column gap-2">
                    <btn-c variant="outlined"
                         [isDisabled]="!changePasswordEnable"
                         (action)="openChangePasswordPopup();">
                        Change my password
                    </btn-c>
                    <b class="text-success animated fadeIn" *ngIf="!changePasswordEnable">We've just sent you an email to reset your password.</b>
                </div>
            </div>
        </div>
    </div>

    <!--"Scan QR Code" popup-->
    <popup-c *ngIf="MfaQrPopup?.show"
           head="Scan QR Code" size="medium"
           (closeCb)="MfaQrPopup = null;"
           (confirmCb)="confirmMfaQrCode()"
           [buttons]="{cancel: {text: 'Close', variant: 'outlined'}, confirm: 'Confirm'}">
        <div class="flxClmn vSpace1 f-1-0">
            <div class="flxRow hSpace2" style="padding: 5px; align-items: center; width: fit-content; background: linear-gradient(90deg, transparent, #f9f9f9 80px, #f9f9f9);">
                <i style="opacity: 0.3; font-size: 1.4rem;" class="fa fa-info-circle"></i>
                <span>If you already scanned the QR code before, just enter the code that is under "Trustifi" in your Authenticator App.</span>
            </div>
            <span>
                Please scan the code below to receive your Time-based One-Time Password
            </span>
            <ul>
                <li>Use this QR code with an authentication app like Google Authenticator.</li>
                <li>After scanning the code, you will receive a one-time password.</li>
                <li>Enter the password in the input below and click on "Confirm".</li>
            </ul>
            <img style="width: 220px;" [src]="MfaQrPopup.qrCodeUrl">
            <div class="flxRow hSpace2" style="align-items: center;">
                <span>Enter code here:</span>
                <input id="qrCodeInput"
                       type="text"
                       style="width: 225px; margin: 0;"
                       pattern="/^[0-9]*$/"
                       maxlength="10"
					   (keydown.enter) = "confirmMfaQrCode();"
                       [(ngModel)]="MfaQrPopup.password"
                       [ngClass]="{'validation-error' : MfaQrPopup.error}">
                <span [ngStyle]="{'color' : MfaQrPopup.success ? 'darkgreen' : 'darkred'}">
                    {{MfaQrPopup.success ? 'Code is valid' : MfaQrPopup.error || ' '}}
                </span>
            </div>
        </div>
    </popup-c>
</div>
