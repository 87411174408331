<div class="flex-column f-1-0 w-100 centralize gap-3 position-relative">
    <span class="fw-500 text-muted" style="font-size: 1rem; margin-top: -135px;">Log in with a password</span>
    <br/>
    <input type="text" id="loginEmail" class="form-control"
           aria-label="Email"
           [value]="userEmailInput"
           [disabled]="true" [readonly]="true">
    <div class="position-relative w-100">
        <input type="{{!showPassword ? 'password' : 'text'}}" id="loginPassword"
               class="w-100" style="padding-right: 27px;"
               placeholder="Password"
               autofocus
               [focusMe]="{focus: true}"
               [(ngModel)]="password"
               [ngClass]="{'validation-error' : error , 'nofill': password && !showPassword}"
			   (keydown.enter)="continue();">
        <label style="opacity: 0.35; position: absolute; top: 50%; right: 0; transform: translate(-60%,-50%);" class="hidepwd cursor-pointer hvr-darken">
            <input type="checkbox" [(ngModel)]="showPassword"/>
            <i class="fa fa-eye unchecked"></i>
            <i class="fa fa-eye-slash checked"></i>
        </label>
    </div>
    <btn-c class="w-100"
         [loading]="loading"
         (action)="continue();">
        Log In
    </btn-c>
    <div class="flex-row align-items-center mt-2 w-100 justify-content-between">
        <a class="flex-row align-items-center gap-1 no-underline blue-link"
           (click)="updateLoginStatus(loginStatuses.emailOrSSOForm)">
            <i class="fa fa-arrow-left" style="font-size: 0.6rem;"></i>
            <span>Change email</span>
        </a>
        <a class="no-underline blue-link"
           (click)="changePassword()"
           [ngClass]="{'disabled': changePasswordDisabled()}">
            Forgot password
        </a>
    </div>
    <br/>
    <br/>
    <div class="text-danger text-start animated fadeIn w-100" style="position: absolute; left: 0; bottom: -20px; margin-left: -20px;" *ngIf="error">
        {{error}}
    </div>
    <div class="text-success text-start animated fadeIn w-100" style="position: absolute; left: 0; bottom: -20px; margin-left: -20px;" *ngIf="successMessage">
        {{successMessage}}
    </div>
</div>

