import {NOTIF_TIMEOUT} from '../constants';
import {Injectable, SecurityContext} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

	ntfData = [];
	private ntfDataSbj = new Subject<any[]>();
	public userDetailsObs = this.ntfDataSbj.asObservable(); // let other components subscribe this and listen to changes in ntfDataSbj

    constructor(private _sanitizer: DomSanitizer) {
    }

	setNotificationData(ntfData) {
		this.ntfData = ntfData;
	}

    private addMessage(msg) {
        const existMsg = this.ntfData.find((itm) => {
            return (itm.error.message === msg.message) || (itm.error.error === 500 && msg.error === 500);
        });

        if (existMsg) {
            existMsg.counter++;
			this.ntfDataSbj.next(this.ntfData);
            return existMsg.index;
        }

        const newIdx = Math.floor(Math.random() * 1000);
        this.ntfData.unshift({
            error: msg,
            counter: 1,
            index: newIdx,
            angular: msg.message.length ? msg.message.indexOf(' ng-') >= 0 : this._sanitizer.sanitize(SecurityContext.HTML, msg.message).indexOf(' ng-') >= 0
        });
		this.ntfDataSbj.next(this.ntfData);

        let timeout = !isNaN(msg.timeout) ? msg.timeout : NOTIF_TIMEOUT[msg.type || msg.error];
        if (timeout && timeout > 0) {
            setTimeout((params) => {
                this.closeMessage(params.index);
            }, timeout, {index: newIdx});
        }

        return newIdx;
    }

    getCurrentMessage() {
        return this.ntfData && this.ntfData[0];
    }

    showMessage(message) {
        if (!message || !this.ntfData) return;

        if (message.meta) {
            if (message.meta instanceof Array) {
                let res = -1;
                message.meta.filter((msg) => msg.display_bar).forEach((msg) => {
                    res = this.addMessage(msg);
                });
                return res;
            }
            else if (message.meta.display_bar) {
                return this.addMessage(message.meta);
            }
        }
        else if (message.display_bar) {
            return this.addMessage(message);
        }
    }

    showInfoMessage(message, options=null) {
        const msgObj = {
            meta: {
                display_bar: true,
                message: message,
                type: 'info'
            }
        };

        if (options) {
            Object.keys(options).forEach((key) => {
                msgObj.meta[key] = options[key];
            });
        }

        return this.showMessage(msgObj);
    }

    showWarnMessage(message, options=null) {
        const msgObj = {
            meta: {
                display_bar: true,
                message: message,
                type: 'warn'
            }
        };

        if (options) {
            Object.keys(options).forEach((key) => {
                msgObj.meta[key] = options[key];
            });
        }

        return this.showMessage(msgObj);
    }

    showErrorMessage(message, options=null) {
        const msgObj = {
            meta: {
                display_bar: true,
                message: message,
                type: ''
            }
        };

        if (options) {
            Object.keys(options).forEach((key) => {
                msgObj.meta[key] = options[key];
            });
        }

        return this.showMessage(msgObj);
    }

    closeMessage(currentIndex=0) {
        //index is undefined
        if (!this.ntfData || (!currentIndex && currentIndex !== 0)) {
            return;
        }

        const index = this.ntfData.findIndex(itm => itm.index === currentIndex);
        if (index > -1) {
            this.ntfData.splice(index, 1);
        }
		this.ntfDataSbj.next(this.ntfData);
    }

    closeAll() {
        this.ntfData && this.ntfData.splice(0, this.ntfData.length);
		this.ntfDataSbj.next(this.ntfData);
    }

    overwriteMessage(currentIndex, text, type=null, options=null) {
        if (!currentIndex) {
            return;
        }

        let msg = this.ntfData.find(itm => itm.index === currentIndex);
        if (msg) {
            msg.error.message = text;
            if (type) {
                msg.error.type = type;
            }
            if (options) {
                Object.keys(options).forEach((key) => {
                    msg.error[key] = options[key];
                });
            }
        }
		this.ntfDataSbj.next(this.ntfData);
    }

    parseNotificationsUI(notifications) {
        notifications = notifications.map(n => {
            n.text = this._sanitizer.sanitize(1, n.text); // context '1' is HTML
            n.text = n.text.replace(/<br\s*[\/]?>/gi, '\n');
            return n;
        });
        return notifications;
    }
}
