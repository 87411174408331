import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import _ from 'lodash';
import util from 'util';
import {GeneralService} from "../../services/generalService";
import {RouteService} from "../../services/routeService";
import {DICTIONARY} from "../../dictionary";


@Component({
	selector: 'exchange-rules-wizard-c',
	templateUrl: './exchange-rules-wizard.component.html',
})
export class ExchangeRulesWizardComponent implements OnInit {

	@Input() isInboundConfiguration: boolean;
	@Input() domains;
	@Output() closePopupFn: EventEmitter<any> = new EventEmitter<any>();

	constructor(private rs: RouteService,
				public gs: GeneralService) {}

	dic = DICTIONARY;
	_ = _;

	domainsArr;
	domainSelectionTypes = {all: 'all', specific: 'specific'};
	domainSelectionType = {type: this.domainSelectionTypes.all}; // Use of non-primitive value for the ng-model module
	selectedDomains = [];

	targetTypes = {
		sender: 'sender',
		recipient: 'recipient',
		header: 'header'
	};
	valueTypes = {
		email: 'email',
		domain: 'domain',
		IP: 'IP',
		group: 'group',
	};

	conditionArrays = {
		sender: {
			email: [],
			domain: [],
			IP: [],
			group: [],
			selectedValueType: 'email'
		},
		recipient: {
			email: [],
			domain: [],
			group: [],
			selectedValueType: 'email'
		},
		header: {
			key: '',
			value: ''
		}
	};
	exceptionArrays = {
		sender: {
			email: [],
			domain: [],
			IP: [],
			group: [],
			selectedValueType: 'email'
		},
		recipient: {
			email: [],
			domain: [],
			group: [],
			selectedValueType: 'email'
		},
		header: {
			key: '',
			value: ''
		}
	}

	// input models
	conditionInputs = {
		sender: '',
		recipient: '',
		header: ''
	};
	exceptionInputs = _.cloneDeep(this.conditionInputs); // same as above initially

	isRuleEnabledByDefault = {enabled: false};
	scanInternal = {enabled: false};
	allowBlockList = {enabled: false};
	useOldMethod = {enabled: false};
	externalRecipientsOnly = {enabled: false};

	// environment
	validSteps = [DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation, DICTIONARY.CONSTANTS.exchangeRulesSteps.domains, DICTIONARY.CONSTANTS.exchangeRulesSteps.configuration, DICTIONARY.CONSTANTS.exchangeRulesSteps.summary];
	step = this.dic.CONSTANTS.exchangeRulesSteps.generalInformation;
	errorMsg = '';
	rulesStatus;
	generateTokenInProcess;
	inputs;
	disconnected

	ngOnInit() {
		this.domainsArr = _.map(this.domains, item => {
			return {domain: item, selected: false};
		});
	}

	closePopup = () => {
		this.domainsArr = null;
		let success = this.rulesStatus && this.rulesStatus.status === 'success';
		this.closePopupFn.emit({success, disconnected:this.disconnected});
	}

	filterSelectedDomains = (item) => {
		this.selectedDomains = _.filter(this.domainsArr, 'selected');
	}

	exchangeSetMTARules = () => {
		let domains;
		if (this.domainSelectionType.type === this.domainSelectionTypes.all) {
			domains = _.map(this.domainsArr, 'domain');
		}
		else {
			domains = [];
			this.domainsArr.forEach((domainObj) => {
				if (domainObj.selected) {
					domains.push(domainObj.domain);
				}
			});
		}
		const data = {
			// domains
			DomainList: domains,

			// condition
			ConditionIfSenderEmailIs: this.conditionArrays.sender.email,
			ConditionIfSenderDomainIs:  this.conditionArrays.sender.domain,
			ConditionIfSenderIPAddressIs:  this.conditionArrays.sender.IP,
			ConditionIfSenderGroupIs:  this.conditionArrays.sender.group,
			ConditionIfRecipientEmailIs: this.conditionArrays.recipient.email,
			ConditionIfRecipientDomainIs:  this.conditionArrays.recipient.domain,
			ConditionIfRecipientGroupIs:  this.conditionArrays.recipient.group,
			ConditionIfEmailHeaderIs: {key: this.conditionArrays.header.key, value: this.conditionArrays.header.value},

			// exception
			ExceptIfSenderEmailIs: this.exceptionArrays.sender.email,
			ExceptIfSenderDomainIs:  this.exceptionArrays.sender.domain,
			ExceptIfSenderIPAddressIs:  this.exceptionArrays.sender.IP,
			ExceptIfSenderGroupIs:  this.exceptionArrays.sender.group,
			ExceptIfRecipientEmailIs: this.exceptionArrays.recipient.email,
			ExceptIfRecipientDomainIs:  this.exceptionArrays.recipient.domain,
			ExceptIfRecipientGroupIs:  this.exceptionArrays.recipient.group,
			ExceptIfEmailHeaderIs: {key: this.exceptionArrays.header.key, value: this.exceptionArrays.header.value},
			// misc
			isInboundConfiguration: this.isInboundConfiguration || false,
			EnableMailFlowRule: this.isRuleEnabledByDefault.enabled,
			InboundScanInternalRecipients: this.scanInternal.enabled,
			isGetAllowBlockListItems: this.allowBlockList.enabled,
			useOldMethod: this.useOldMethod.enabled,
			OutboundExternalRecipientsOnly: this.externalRecipientsOnly.enabled,
		}

		this.rulesStatus = null;
		this.generateTokenInProcess = true;

		this.rs.exchangeSetMTARules(data).then(response => {
			this.rulesStatus = response;
			this.generateTokenInProcess = false;
		}, err => {
			this.rulesStatus = err.data;
			this.generateTokenInProcess = false;
			this.disconnected = err.data.disconnected;
		});
	}

	backStep = () => {
		this.errorMsg = '';
		switch (this.step) {
			case this.dic.CONSTANTS.exchangeRulesSteps.domains:
				this.step = this.dic.CONSTANTS.exchangeRulesSteps.generalInformation;
				break;

			case this.dic.CONSTANTS.exchangeRulesSteps.configuration:
				this.step = this.dic.CONSTANTS.exchangeRulesSteps.domains;
				break;

			case this.dic.CONSTANTS.exchangeRulesSteps.summary:
				if (this.generateTokenInProcess) {
					this.errorMsg = this.dic.ERRORS.waitForTokenGenerate;
					return;
				}

				this.step = this.dic.CONSTANTS.exchangeRulesSteps.configuration;
				break;

			default:
				return;
		}
	}

	nextStep = () => {
		this.errorMsg = '';
		switch (this.step) {
			case this.dic.CONSTANTS.exchangeRulesSteps.generalInformation:
				this.step = this.dic.CONSTANTS.exchangeRulesSteps.domains;
				break;

			case this.dic.CONSTANTS.exchangeRulesSteps.domains:
				if (this.domainSelectionType.type === this.domainSelectionTypes.specific && !this.selectedDomains.length) {
					this.errorMsg = util.format(this.dic.ERRORS.selectAtLeastOneX, 'domain');
					return;
				}
				this.step = this.dic.CONSTANTS.exchangeRulesSteps.configuration;
				break;

			case this.dic.CONSTANTS.exchangeRulesSteps.configuration:
				if (!this.validateHeadersStrings()) {
					return;
				}
				this.exchangeSetMTARules();
				this.step = this.dic.CONSTANTS.exchangeRulesSteps.summary;
				break;

			default:
				return;
		}
	}

	searchDomain = (searchTerm, activeFilters) => {
		this.domainsArr.forEach(record => {
			if (searchTerm) {
				const isFound = this.searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	addConditionOrExceptionValue = (list, targetType, valueType = null, value = null, inputToReset = null) => {
		if (!list || !targetType || !valueType || !value) {
			return;
		}
		this.errorMsg = '';
		// value type validation
		if (targetType === this.targetTypes.sender || targetType === this.targetTypes.recipient) {
			const formattedValue = value.trim().toLowerCase();
			if ((valueType === this.valueTypes.email) && !this.gs.validateEmail(formattedValue)) {
				this.errorMsg = util.format(this.dic.ERRORS.contactEmailInvalid, 'You\'ve entered an');
				return;
			}
			if ((valueType === this.valueTypes.group) && !this.gs.validateEmail(formattedValue)) {
				this.errorMsg = this.dic.ERRORS.groupsIsValidEmail;
				return;
			}
			if (valueType === this.valueTypes.domain && !this.gs.isValidDomain(formattedValue)) {
				this.errorMsg = this.dic.ERRORS.authDomainFieldInvalid;
				return;
			}
			if (valueType === this.valueTypes.IP && !this.gs.isValidIPAddress(formattedValue)) {
				this.errorMsg = this.dic.ERRORS.invalidIpAddress;
				return;
			}
		}
		// already exist validation
		if (list.indexOf(value) > -1) {
			const valueTypeStr =  this.gs.toCapitalize(valueType);
			this.errorMsg = util.format(this.dic.ERRORS.emailDomainAlreadyExist, valueTypeStr);
			return;
		}

		list.unshift(value);
		inputToReset[targetType] = '';
	}

	removeConditionOrExceptionValue = (list, value) => {
		this.errorMsg = '';
		list.splice(list.indexOf(value), 1);
	}

	validateHeadersStrings = () => {
		if ((this.conditionArrays.header.key && !this.conditionArrays.header.value) || (!this.conditionArrays.header.key && this.conditionArrays.header.value) ||
			(this.exceptionArrays.header.key && !this.exceptionArrays.header.value) || (!this.exceptionArrays.header.key && this.exceptionArrays.header.value)) {
			this.errorMsg = util.format(this.dic.ERRORS.accountEmailMissingName, 'Header input');
			return false;
		}

		const keyPattern = /^[a-z0-9-]+$/;
		if ((this.conditionArrays.header.key && !keyPattern.test(this.conditionArrays.header.key)) ||
			(this.exceptionArrays.header.key && !keyPattern.test(this.exceptionArrays.header.key))) {
			this.errorMsg = 'Invalid header key. Must contain only letters, digits, and hyphens (-)';
			return false;
		}

		return true;
	}

	searchTextExecute(domain, searchTerm) {
		searchTerm = searchTerm.toLowerCase();
		return (domain.domain && domain.domain.toLowerCase().indexOf(searchTerm) > -1);
	}

}
