import _ from 'lodash';
import {DICTIONARY} from "../../../../../dictionary";
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import {GeneralService} from "../../../../../services/generalService";
import {Component, OnInit} from "@angular/core";


@Component({
	selector: 'generate-report-component',
	templateUrl: './generate-report.component.html',
})
export class GenerateReportComponent implements OnInit {
    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
    }

    dic = DICTIONARY;
    userInfo;
    userReport;
    reportUsers;
    selectedUsers = [];
    selectedFields = [];
    showFieldsPopup;
    csvFieldsCopy;
    selectedAll = false;
    planUsers = [];
    showSelectUsersPopup: any;
    planUsersOriginal = [];
    reportFromDate;
    reportFromDateString
    reportUntilDate;
    reportUntilDateString;
    csvFields;
    reportUsersGraphPopup;
    userReportGraphData;
    userReportGraphAggregatePerDomain = false;
    getFieldsInProgress;
    userReportFromDateApplied = false;
    reportOptions = [
        {key: 'emailData' ,text: 'Email Data Report' ,tooltip: 'This report contains information about emails sent by your users. You can select what data the report should contain.'},
        {key: 'connections' ,text: 'Connections Report' ,tooltip: 'This report shows which emails or domains your users are in contact with.'}
    ];
    activeReportOption = this.reportOptions[0];
	_ = _;

	ngOnInit() {
        this.reportFromDate = new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)); // last week
        this.reportFromDateString = String(this.reportFromDate);
        this.reportUntilDate = new Date();
        this.reportUntilDateString = String(this.reportUntilDate);

        this.getFieldsInProgress = true;

        this.gs.getUserInfo(false, userInfo => {
            this.userInfo = userInfo;
        });

        this.rs.getAdminReportSettings().then((response) => {
            this.userReport = response.report;

            let fields = this.dic.CONSTANTS.csvFieldNames;
            this.csvFields = _.map(fields, field => {
                return {
                    name: field,
                    selected: this.userReport.selected_fields.includes(field)
                }
            });
            this.selectedFields = _.map(_.filter(this.csvFields, 'selected'), 'name');

            this.checkIfFieldsSelected();
            this.checkSelectAll();
            this.getFieldsInProgress = false;
        }, (err) => {
			this.getFieldsInProgress = false;
		});

        this.rs.getUsersOfSharedPlan({onlyUsersAndSubAdmins: true}).then((users) => {
            this.reportUsers = [];
            this.planUsersOriginal = _.map(users, u => {
                u.selected = false;
                this.reportUsers.push(u.email);
                return u;
            });
        }, (err) => {

		});
    }

    checkIfFieldsSelected() {
        this.csvFieldsCopy = _.cloneDeep(this.csvFields);
        const users = _.cloneDeep(this.selectedUsers);
        if (!users.length) {
            return;
        }
        this.selectedUsers = users;
    }

    reportOptionSelect(optionName) {
        this.activeReportOption = optionName;
    }

    openFieldsPopup() {
        if (!this.selectedFields.length) {
            this.csvFieldsCopy = _.cloneDeep(this.csvFields);
        }
        this.checkSelectAll();
        this.showFieldsPopup = true;
    }

    applyFieldsFromPopup() {
        const fields = _.map(_.filter(this.csvFieldsCopy, 'selected'), 'name');
        if (!fields.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.atLeastOneReportFields);
            return;
        }

        this.selectedFields = fields;
        this.showFieldsPopup = false;
    }

    checkSelectAll() {
        this.selectedAll = _.filter(this.csvFieldsCopy, 'selected').length === this.csvFieldsCopy.length;
    };

    closeFieldsPopup() {
        this.showFieldsPopup = false;
    }

    openUsersPopup() {
        if (!this.selectedUsers.length && this.planUsers) {
            _.map(this.planUsers, f => {
                f.selected = false;
                return f;
            });
        }

        this.showSelectUsersPopup = {
			show: true
		}
    };

	addUsersToSelection = (users) => {
		this.selectedUsers = this.selectedUsers.concat(users);
	}

	removeUsersFromSelection = (users) => {
		this.selectedUsers = _.filter(this.selectedUsers, u => !_.includes(users, u));
	}

    getReport() {
        if (!this.reportFromDate || !this.reportUntilDate) {
            return;
        }

        if (!this.selectedFields.length) {
            this.ns.showErrorMessage(this.dic.ERRORS.atLeastOneReportFields);
            return;
        }

        const fromDate:any = this.reportFromDate;
        const untilDate = this.reportUntilDate;

        if (fromDate > Date.now() || fromDate > untilDate) {
            this.ns.showWarnMessage(this.dic.ERRORS.adminReportDate);
            return;
        }

        this.gs.showPopup({
            title: 'Generate Report',
            subTitle: 'You are about to generate a report. Once this is finished, the report will be sent to your email address.',
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Generate',
            doneCb: () => {
                this.rs.getUsersDetailedReport({
                    from: fromDate.toISOString(),
                    until: untilDate.toISOString(),
                    fields: this.selectedFields.join(',')
                }).then(() => {
                    this.ns.showInfoMessage('Report operation started successfully');
                });
            }
        });
    };

    generateUserReportGraph() {
        if (!this.reportFromDate || !this.reportUntilDate) {
            return;
        }

        if (!this.selectedUsers.length) {
            this.ns.showErrorMessage(this.dic.ERRORS.atLeastOneReportUser);
            return;
        }

        if (this.reportFromDate > Date.now() || this.reportFromDate > this.reportUntilDate) {
            this.ns.showWarnMessage(this.dic.ERRORS.adminReportDate);
            return;
        }

		this.reportUsersGraphPopup = {
			graphData: [],
			nodeOptionsFn: (node, allLinks) => {
				const sentToCounter = _.filter(allLinks, { target: node.name }).length;
				const isUserNode = this.selectedUsers.includes(node.name);
				node.color = isUserNode ? 'green' : '';
				node.name = sentToCounter && !isUserNode ? node.name + ` (${sentToCounter})` : node.name;
				node.lineWidth = sentToCounter && !isUserNode ? Math.log(Math.min(sentToCounter, 20))*2 : 1;

				// hide the tooltip that shows numbers of links of a node (because we show another number in label)
				node.hideTooltip = true;
			},
			nodeSearchTxt: '',
			nodeSearchResults: null,
			show: true,
			loading: true
		};

        this.rs.getUsersDetailedReport({
            from: this.reportFromDate.toISOString(),
            until: this.reportUntilDate.toISOString(),
            fields: 'Sent from,Recipient',
            type: 'graph',
            users: this.selectedUsers.join(',')
        }).then((report) => {

			this.reportUsersGraphPopup.loading = false;

			const links = _.map(report, sentEmail => {
				return {
					source: sentEmail['Sent from'],
					target: this.userReportGraphAggregatePerDomain ? sentEmail['Recipient'].split('@')[1] : sentEmail['Recipient']
				}
			});

			setTimeout(() => {
				this.reportUsersGraphPopup.graphData = {links};
			});

		}, err => {
			this.reportUsersGraphPopup.loading = false;
		});
    };

	searchConnectionEmailAddress = () => {
		if (this.reportUsersGraphPopup.nodeSearchTxt && this.reportUsersGraphPopup.graphData?.length) {
			this.reportUsersGraphPopup.nodeSearchResults = [];

			this.reportUsersGraphPopup.graphData.forEach(connection => {
				if (connection.source.includes(this.reportUsersGraphPopup.nodeSearchTxt)) {
					this.reportUsersGraphPopup.nodeSearchResults.push(connection.source);
				}
				if (connection.target.includes(this.reportUsersGraphPopup.nodeSearchTxt)) {
					this.reportUsersGraphPopup.nodeSearchResults.push(connection.target);
				}
			});

			this.reportUsersGraphPopup.nodeSearchResults = _.union(this.reportUsersGraphPopup.nodeSearchResults);
		}
		else {
			this.reportUsersGraphPopup.nodeSearchResults = null;
		}
	}

    toggleAll() {
        this.selectedAll = !this.selectedAll;
        this.csvFieldsCopy.forEach(field => {
            field.selected = this.selectedAll;
        });
    };
}
