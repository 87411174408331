import * as util from 'util';
import _ from 'lodash';
import {GeneralService} from "../../services/generalService";
import {NotificationService} from "../../services/notificationService";
import {DICTIONARY} from "../../dictionary";
import {RouteService} from "../../services/routeService";
import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {TrustifiTableComponent} from "../uiComponents/trustifi-table/trustifi-table.component";
import {UploadOutput} from 'ngx-uploader';

@Component({
	selector: 'attachments-component',
	templateUrl: './attachments.component.html',
})
export class AttachmentsComponent implements OnInit{

	@ViewChild('previewFrame') previewFrame: ElementRef;
	@ViewChild('attachmentsTableEl') attachmentsTableEl: TrustifiTableComponent;


    constructor(private router:Router,
				private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService) {
    }
	files = [];
    dic = DICTIONARY;
	_=_;
	getStorageDataInProcess;
    showAttachmentUsagePopup = false;
    showNavigationPathsModal = false;
    isUnlimited;
    currentDir:any = {
		sons: [],
		attachments: []
	};
    paths;
    uploadQueue;
    uploadedFiles;
	directoriesTree;
    attachmentUsageData;
    showSensitiveInfoPopup;
	previewAttachment;
    expirationEditPopup;
    addNewDirectoryInProcess;
	validDrag;
	invalidDrag;
	moveDirectoryPopup;
	folderFiles = [];


	ngOnInit() {
        this.getDirectoryData();

        this.gs.getUserInfo(false, userInfo => {
            this.isUnlimited = userInfo.plan.is_unlimited;
        });
	}
	clickOnFolderInput = () => {
		document.getElementById('storageUploadFolder').click();
	}

	onUploadOutput(output: UploadOutput): void {
		switch (output.type) {
			case 'allAddedToQueue':
				if (this.folderFiles.length) {
					this.uploadFolder(this.folderFiles);
					this.folderFiles = [];
				}
				if (this.files.length) {
					this.uploads();
				}
				(document.getElementById('storageUploadFolder') as HTMLInputElement).value = '';
				break;

			case 'addedToQueue':
				if (typeof output.file !== 'undefined') {
					if (output.file.nativeFile.webkitRelativePath === "") {
						this.files.push(output.file.nativeFile);
					}
					else {
						this.folderFiles.push(output.file.nativeFile);
					}
				}
				break;
		}
	}

    getDirectoryData() {
		this.getStorageDataInProcess = true;

        this.rs.getDirectoryData(null).then((response) => {
			if (!this.currentDir) {
				this.ns.showInfoMessage(this.dic.MESSAGES.attachmentDnD);
			}

			if (!response) {
				return;
			}

			this.directoriesTree = response;
            this.currentDir = response;

            this.paths = [{directory: this.currentDir, name: this.currentDir.name}];

            this.setTypePriority();
            this.setExpirationDays();
			this.getStorageDataInProcess = false;

			// adjustment to directories
			this.currentDir.sons.forEach(directory => {
				directory.isAlwaysOnTop = 2;

				if (directory.name === this.dic.CONSTANTS.directoryRecipientUpload) {
					directory.isAlwaysOnTop = 1;
					directory.hideActions = true;
					directory.isSelectable = false;
				}
			});

        }, (err) => {
			this.getStorageDataInProcess = false;
		});
    }

	showAttachmentActions = (attachmentObj) => {
		if (this.isDirectory(attachmentObj)) {
			return _.compact([
				this.dic.CONSTANTS.secureStorageActions.rename,
				this.dic.CONSTANTS.secureStorageActions.move,
				this.dic.CONSTANTS.secureStorageActions.delete
			]);
		}
		else {
			return _.compact([
				this.dic.CONSTANTS.secureStorageActions.download,
				this.dic.CONSTANTS.secureStorageActions.send,
				this.dic.CONSTANTS.secureStorageActions.sensitivityDetails,
				this.dic.CONSTANTS.secureStorageActions.move,
				//TODO: make preview show in dropdown for specific file types
				this.dic.CONSTANTS.secureStorageActions.preview,
				this.dic.CONSTANTS.secureStorageActions.delete
			]);
		}
	}

	selectAttachmentAction = (attachment, action) => {
		switch (action) {
			case this.dic.CONSTANTS.secureStorageActions.download:
				this.download([attachment]);
				break;

			case this.dic.CONSTANTS.secureStorageActions.send:
				this.sendMessageWithAttachments([attachment]);
				break;

			case this.dic.CONSTANTS.secureStorageActions.rename:
				attachment.edit = {
					name: attachment.name
				};
				attachment.isEditMode = true;
				break;

			case this.dic.CONSTANTS.secureStorageActions.move:
				this.openMoveModal([attachment]);
				break;

			case this.dic.CONSTANTS.secureStorageActions.sensitivityDetails:
				this.openSensitiveInfoPopup(attachment);
				break;

			case this.dic.CONSTANTS.secureStorageActions.preview:
				this.previewAttachment = attachment;
				break;

			case this.dic.CONSTANTS.secureStorageActions.delete:
				this.openDeleteItemsPopup([attachment]);
				break;
		}
	}

	showMultipleAttachmentsActions = () => {
		const selectedDirectories = _.filter(this.currentDir.sons, 'selected');

		return _.compact([
			!selectedDirectories.length && this.dic.CONSTANTS.secureStorageActions.download,
			!selectedDirectories.length && this.dic.CONSTANTS.secureStorageActions.send,
			this.dic.CONSTANTS.secureStorageActions.move,
			this.dic.CONSTANTS.secureStorageActions.delete
		]);
	}

	selectMultipleAttachmentsAction = (selectedAttachments, action) => {
		switch (action) {
			case this.dic.CONSTANTS.secureStorageActions.download:
				this.download(selectedAttachments);
				break;

			case this.dic.CONSTANTS.secureStorageActions.send:
				this.sendMessageWithAttachments(selectedAttachments);
				break;

			case this.dic.CONSTANTS.secureStorageActions.move:
				this.openMoveModal(selectedAttachments);
				break;

			case this.dic.CONSTANTS.secureStorageActions.delete:
				this.openDeleteItemsPopup(selectedAttachments);
				break;
		}
	}

	isDirectory = (attachment) => {
		return !attachment.hasOwnProperty('size');
	}

	getFileIcon = (attachment) => {
		return this.gs.getFileIcon(attachment.name);
	}

    setTypePriority() {
        this.currentDir.sons.forEach(fileOrFolder => {
			switch (fileOrFolder.type) {
				case this.dic.CONSTANTS.directoryType.regular:
					fileOrFolder.type_priority = 3;
					break;

				case this.dic.CONSTANTS.directoryType.default:
					fileOrFolder.type_priority = 2;
					break;

				default:
					fileOrFolder.type_priority = 1;
					break;
			}
		});
		this.currentDir.attachments.forEach(fileOrFolder => {
			fileOrFolder.type_priority = 4;
		});
    }

    calcBlockSizeAndValidate(files) {
        let total = 0;
        _.each(files, file => {
            total += file.size;
        });
        return this.gs.checkUploadFileSize(total, 'Current uploading files');
    }

    uploads = () => {
        if (!this.files?.length || this.paths[1] === this.dic.CONSTANTS.directoryRecipientUpload) {
            return;
        }

		let files = _.cloneDeep(this.files);
		this.files = []; // empty array for next possible upload

		// reject folders
		const uploadedFolders = _.reject(files, 'type');
		if (uploadedFolders.length) {
			this.ns.showErrorMessage("Cannot upload folders via drag and drop, please use the \"upload folder\" button instead");
			files = _.filter(files, 'type');
			if (!files.length) {
				return;
			}
		}

        let isValid;
        if (files.length === 1) {
            isValid = this.gs.checkUploadFileSize(files[0].size, files[0].name);
            if (isValid) {
                this.uploadFile(files[0], files.length, this.currentDir._id, true);
            }
            return;
        }
        this.uploadQueue = [];
        isValid = this.calcBlockSizeAndValidate(files);
        if (isValid) {
            let notIdx = this.ns.showInfoMessage(this.dic.MESSAGES.attachBlockUpload, {timeout: 0, showSpinner: true});

            if (files.length < this.dic.CONSTANTS.parallelFilesUploadCount) {
                this.uploadQueue = files;
                this.uploadMultipleFiles(this.currentDir, notIdx, true, true, (err, notIdx, uploadedFiles) => {
                    if (!err) {
                        this.ns.overwriteMessage(notIdx, this.dic.MESSAGES.filesUploaded, null, {showSpinner: false});
                    }
                });
                return;
            }
            this.uploadedFiles = 0;
            if (files?.length) {
                this.uploadBlocks(files, this.currentDir, true, notIdx, files.length, () => {});
            }
        }
    }

    uploadBlocks(files, parentDir, showInUI, notIdx, totalFilesNumber, cb) {
        this.uploadQueue = files.splice(0, this.dic.CONSTANTS.parallelFilesUploadCount);

        this.uploadMultipleFiles(parentDir, notIdx, showInUI, false, (err, notIdx, uploadedFiles) => {
            //error in upload
            if (err) {
                 return cb(err);
            }
            this.uploadedFiles += uploadedFiles;

            //finished current upload
            if (files.length === 0) {
                if (showInUI) {
                    this.ns.overwriteMessage(notIdx, this.dic.MESSAGES.filesUploaded, null, {showSpinner: false});
                }
                return cb(null);
            }
            else {
                this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.uploadMultiBlockProgress, this.uploadedFiles, totalFilesNumber), null, {showSpinner: true});
            }
            //recursive call on the rest of files to upload
            this.uploadBlocks(files, parentDir, showInUI, notIdx, totalFilesNumber, cb);
        });
    }

    uploadMultipleFiles(parentDir, notIdx, showInUI, showPercentages, cb) {
        this.gs.uploadMultipleFiles('/attachment/'+parentDir._id, this.uploadQueue, notIdx, showPercentages).then((resp:any) => {
            if (resp.data && resp.data.length) {
                /*if (resp.data.length < this.dic.CONSTANTS.parallelFilesUploadCount) {
                    this.ns.showWarnMessage('Failed to upload '+(this.dic.CONSTANTS.parallelFilesUploadCount-resp.data.length)+' files');
                }*/

                cb(null, notIdx, resp.data.length);
                const attachmentsExists = _.filter(this.currentDir.attachments, attachment => resp.data.includes(attachment._id));

                if (attachmentsExists && attachmentsExists.length) {
                    const attachmentsExistsIds = _.map(attachmentsExists, '_id');
                    //update the attachments that will updated in the UI
                    resp.data = _.difference(resp.data, attachmentsExistsIds);
                    if (showInUI) {
                        this.ns.closeMessage(notIdx);
                        this.ns.showMessage({meta: {
                            display_bar: true,
                            message: util.format(this.dic.ERRORS.attachmentExists, _.map(attachmentsExists, 'name').join(", "))
                        }});
                    }
                }
                let c = {count: 0};
                for (let i = 0; i < resp.data.length; i++) {
                    this.initSensitivity(resp.data[i], c, showInUI, parentDir);
                }
            }
            else {
                //this.ns.showWarnMessage('Failed to upload '+this.dic.CONSTANTS.parallelFilesUploadCount+' files');
                cb(null, notIdx, 0);
            }
        }, err => {
            console.error(err);
            this.ns.closeMessage(notIdx);
            cb(err);
        });
    }

    uploadFile(file, numberOfFilesToUpload, dirId, showInUI) {
        if (!file) {
            return;
        }

        let notIdx;
        if (showInUI) {
            notIdx = this.ns.showInfoMessage(util.format(this.dic.MESSAGES.attachUpload, file.name), {timeout: 0});
        }

        this.gs.uploadFile('/attachment/'+dirId, {file: file}, notIdx, showInUI).then((resp:any) => {
            if (resp.data && resp.data.length) {
                let attachExists = this.currentDir.attachments.find((itm) => {return itm._id === resp.data[0];});
                if (attachExists) {
                    if (showInUI) {
                        this.ns.closeMessage(notIdx);
                        this.ns.showMessage({meta: {
                            display_bar: true,
                            message: util.format(this.dic.ERRORS.attachmentExists, file.name)
                        }});
                    }
                }
                else {
                    if (showInUI) {
                        this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.fileUploaded, file.name));
                    }
                    let c = {count: 0};
                    this.initSensitivity(resp.data[0], c, showInUI, this.currentDir);
                }
            }
        }, err => {
            console.error(err);
            this.ns.closeMessage(notIdx);
        });
    }

    initSensitivity(attachmentId, c, showInUI, parentDir) {
        if (c.count > 10) {
            return;
        }
        this.rs.getAttachment(attachmentId).then((currentAttachment) => {
            if (currentAttachment && !currentAttachment.error) {
                if (c.count === 0 && showInUI) {

                    parentDir.attachments.unshift(currentAttachment);

                    if (currentAttachment.expiration_delete) {
                        currentAttachment.delete_attachment_days = this.gs.dateDiffInDays(new Date(currentAttachment.expiration_delete), new Date());
                        currentAttachment.deleteAttachmentDays = currentAttachment.delete_attachment_days;
                    }
                }
                if (!currentAttachment.is_sensitive_checked) {
                    setTimeout(() => {
                        c.count++;
                        this.initSensitivity(attachmentId, c, showInUI, parentDir);
                    }, 3000);
                }
                else {
                    let attachExists = parentDir.attachments.find((itm) => {return itm._id === attachmentId;});
                    if (attachExists) {
                        attachExists.is_sensitive_checked = currentAttachment.is_sensitive_checked;
                        attachExists.sensitivity = currentAttachment.sensitivity;
                    }
                }
            }
        });
    }

    sendMessageWithAttachments(attachments) {
		this.router.navigate([this.dic.CONSTANTS.appStates.personalPages, this.dic.CONSTANTS.personalPages.composeMessage], { state:
				{
					data: {attachments: attachments}
				}
		});
    }

    openSensitiveInfoPopup(attachment) {
        this.showSensitiveInfoPopup = {
            attachment: attachment,
            sensitivity: attachment.sensitivity
        };
    }

    navigateToMailBox(email) {
        if (!email) {
            return;
        }

        this.showAttachmentUsagePopup = false;
		this.router.navigate([this.dic.CONSTANTS.appStates.personalPages, this.dic.CONSTANTS.personalPages.mailbox], {state: {ids: [email._id]}});
	};

    deleteAttachments(attachments) {
        const attachmentsIds = _.map(attachments, '_id');

        this.rs.removeAttachmentFromDirectory({attachmentsIds: attachmentsIds, dirId: this.currentDir._id}).then((resp) => {
			this.currentDir.attachments = _.difference(this.currentDir.attachments, attachments);

            if (attachmentsIds.length > this.dic.CONSTANTS.maxItemsInNotification) {
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, attachments.length, attachments.length === 1 ? 'attachment': 'attachments'));
            }
            else {
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, attachments.length === 1 ? 'Attachment': 'Attachments', _.map(attachments, 'name').join(', ')));
            }
        });
    }

	searchAttachment = (searchTerm, activeFilters) => {
		// directories
		this.currentDir.sons.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});

		// attachments
		this.currentDir.attachments.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	};

    download(attachments) {
		if (!attachments) {
			return;
		}

		if (attachments.length === 1) {
			const attachment = attachments[0];

			this.gs.downloadFile('/attachment/file/' + attachment._id, attachment, true, () => {});
		}
		else {
			const fileObj = {name: 'secure_attachments.zip', type: 'application/zip', size: _.sumBy(attachments, 'size')};
			const fileIdsStr = _.map(attachments, '_id').join(',');

			this.gs.downloadFile('/attachment/file/' + fileIdsStr, fileObj, false, () => {});
		}
    }

    openDeleteItemsPopup(attachments) {
		if (!attachments) {
			return;
		}

        let subTitle;
		let body = [];

        if (attachments.length === 1) {
            subTitle = `Please note - you are about to delete a ${this.isDirectory(attachments[0]) ? 'folder' : 'file'}.`;
        }
        else {
            subTitle = `Please note - you are about to delete ${attachments.length} items.`;
        }

		if (_.some(attachments, attachment => this.isDirectory(attachment))) {
			body.push('All sub folders will be deleted.');
			body.push('All attachments inside the directories will be deleted.');
		}

        const affectedEmails = _.uniq(_.flatMapDeep(attachments, 'email_id'));
		if (affectedEmails.length) {
			const isOnlyOneFile = attachments.length === 1 && !this.isDirectory(attachments[0]);

			body.push(`${isOnlyOneFile ? 'File' : 'Items'} will not be available for ${affectedEmails.length} ${affectedEmails.length > 1 ? 'emails' :'email'}.`);
		}

        this.gs.showPopup({
            title: `Delete ${attachments.length === 1 ? this.isDirectory(attachments[0]) ? 'a folder' : 'a file' : 'items'}`,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                if (_.some(attachments, this.isDirectory) && _.some(attachments, attachment => !this.isDirectory(attachment))) {
                    this.deleteAttachmentsAndDirectories(attachments);
                }
                else if (_.some(attachments, this.isDirectory)) {
                    this.deleteDirectories(attachments);
                }
                else {
                    this.deleteAttachments(attachments);
                }
            }
        });
    }

    deleteAttachmentsAndDirectories(filesAndDirectories) {
        const directories = _.filter(filesAndDirectories, this.isDirectory);
		const attachments = _.reject(filesAndDirectories, this.isDirectory);

		if (!directories.length || !attachments.length) {
			return;
		}
		if (_.some(directories, {type: this.dic.CONSTANTS.directoryType.default})) {
			this.ns.showWarnMessage(this.dic.ERRORS.systemDirectoryDeleteNotAllowed);
			return;
		}

        const attachmentsIds = _.map(attachments, '_id');
        const directoriesIds = _.map(directories, '_id');

        const deleteData = {
            dirId: this.currentDir._id,
            attachmentsIds: attachmentsIds,
            directoriesIds: directoriesIds
        };

        this.rs.deleteAttachmentsAndDirectories(deleteData).then(newTree => {
			this.currentDir.attachments = _.difference(this.currentDir.attachments, attachments);
			this.currentDir.sons = _.difference(this.currentDir.sons, directories);

            if (filesAndDirectories.length > this.dic.CONSTANTS.maxItemsInNotification) {
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, filesAndDirectories.length, 'Items'));
            }
            else {
                const itemsNames = _.map(filesAndDirectories, 'name').join(', ');
                this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, 'Items: ', itemsNames));
            }
        });

    }

    deleteDirectories(selectedDirs) {
        if (!selectedDirs?.length) {
			return;
        }
		if (_.some(selectedDirs, {type: this.dic.CONSTANTS.directoryType.default})) {
			this.ns.showWarnMessage(this.dic.ERRORS.systemDirectoryDeleteNotAllowed);
			return;
		}

		const directoriesIds = _.map(selectedDirs, dir => dir._id.toString());

		this.rs.deleteDir(directoriesIds.join(',')).then(newTree => {
			this.currentDir.sons = _.difference(this.currentDir.sons, selectedDirs);

			if (selectedDirs.length > this.dic.CONSTANTS.maxItemsInNotification) {
				this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, selectedDirs.length, selectedDirs.length === 1 ? 'folder': 'folders'));
			}
			else {
				const directoryNames = _.map(selectedDirs, 'name').join(', ');
				this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemDeleted, selectedDirs.length === 1 ? 'Folder: ': 'Folders: ', directoryNames));
			}
		});
    }

    openEditExpirationPopup = attachmentObj => {
        this.expirationEditPopup = {
            expirationEditFile: attachmentObj,
            show: true
        };
    }

    resetTableData() {
		const newDir = _.find(this.currentDir.sons, 'isNew');
		if (newDir) {
			_.remove(this.currentDir.sons, newDir);
		}

		this.currentDir.sons.forEach(attachment => {
			attachment.edit = null;
			attachment.isEditMode = false;
			attachment.selected = false;
		});

		this.addNewDirectoryInProcess = false;
	}

    goIntoDirectory(directory) {
		if (!directory || !directory._id || directory.isEditMode) {
			return;
		}

		this.resetTableData();

        // read directory data from cache
		this.currentDir = _.find(this.currentDir.sons, {_id: directory._id});
		this.currentDir.sons.forEach(dir => {
			dir.isAlwaysOnTop = dir.isAlwaysOnTop || 2;
		});
        this.paths.push({directory: this.currentDir, name:this.currentDir.name});
        this.setExpirationDays();
    }

    navToDirectory(directoryId, pathIndex) {
        if (this.currentDir._id === directoryId) {
			return;
		}

		this.resetTableData();

        this.currentDir = this.paths[pathIndex].directory;
		this.currentDir.sons.forEach(dir => {
			dir.isAlwaysOnTop = dir.isAlwaysOnTop || 2;
		});
        this.paths = this.paths.slice(0, pathIndex + 1);
        this.setExpirationDays();
    }

	startAddNewDirectory() {
        if (this.addNewDirectoryInProcess) {
			return;
		}

		const newDirectory = {
			name: '',
			isEditMode: true,
			isNew: true,
			isAlwaysOnTop: 2,
			edit: {
				name: ''
			}
		};

		this.currentDir.sons.unshift(newDirectory);
		this.addNewDirectoryInProcess = true;
    }

	confirmEditDirectory(directory, isApproved) {
		// new folder process was canceled
		if (!isApproved) {
			if (directory._id) {
				directory.edit = null;
				directory.isEditMode = false;
			}
			else {
				_.remove<any>(this.currentDir.sons, directory);
				this.addNewDirectoryInProcess = false;
			}
			return;
		}
		//

		const normalizedNewName = directory.edit.name.trim().replace(/\s+/g, ' ');

		if (!normalizedNewName) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, 'Folder name'));
			return;
		}

		if (normalizedNewName === directory.name) {
			this.ns.showWarnMessage(this.dic.ERRORS.noChanges);
			return;
		}

		if (_.findIndex<any>(this.currentDir.sons, (dir: any) =>
			dir._id !== directory._id &&
			dir.name &&
			dir.name.trim().replace(/\s+/g, ' ').toLowerCase() === normalizedNewName.toLowerCase()) >= 0) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.newDirNameAlreadyExists, normalizedNewName));
			return;
		}

		if (directory.isNew) {
			this.addDirectory(directory);
		}
		else {
			this.renameDirectory(directory);
		}
	}

    addDirectory(directory) {
		const normalizedNewName = directory.edit.name.trim().replace(/\s+/g, ' ');
		directory.confirmEditInProcess = true;
		this.rs.addDir({name: normalizedNewName, parentDirId: this.currentDir._id}).then((newDirectory) => {
			newDirectory.type_priority = 3;
			newDirectory.attachments = [];

			directory = Object.assign(directory, newDirectory);
			directory.edit = null;
			directory.confirmEditInProcess = false;
			directory.isEditMode = false;
			directory.isNew = undefined;

			this.addNewDirectoryInProcess = false;
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.directoryAddedFinish, newDirectory.name));
        }, err => {
			directory.confirmEditInProcess = false;
            this.addNewDirectoryInProcess = false;
        });
    }

	renameDirectory(directory) {
		const normalizedNewName = directory.edit.name.trim().replace(/\s+/g, ' ');
		directory.confirmEditInProcess = true;
		this.rs.renameDirectory(directory._id, {name: normalizedNewName}).then((newTree) => {
			directory.name = normalizedNewName;
			directory.edit = null;
			directory.confirmEditInProcess = false;
			directory.isEditMode = false;

			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.directoryRenameFinish, normalizedNewName));
		}, err => {
			directory.confirmEditInProcess = false;
		});
	}

    uploadFolder = (folderFiles) => {
        if (!folderFiles) return;

        let paths, folderStructure = {}, currentFolder, totalFilesSize = 0, totalFiles = 0;
        for (let file of folderFiles) {
            paths = file.webkitRelativePath.split('/');
            let item;
            currentFolder = folderStructure;
            for (let i = 0; i < paths.length; i++) {
                item = paths[i];
                // case item isn't already in the structure
                if (!currentFolder[item]) {
                    // folder
                    if (i < paths.length - 1) {
                        currentFolder[item] = {};
                    }
                    // file
                    else {
                        //count only non empty files
                        if (file.size) {
                            currentFolder[item] = file;
                            totalFiles++;
                            totalFilesSize += file.size;
                        }
                    }
                }
                currentFolder = currentFolder[item];
            }
        }

        const dirName = Object.keys(folderStructure)[0];

		if (!this.gs.userInfo.plan.is_unlimited) {
			const totalFilesSizeInGB = totalFilesSize / Math.pow(1024,3);
			if (this.gs.userInfo.plan.attachments_overall_size + totalFilesSizeInGB > this.gs.userInfo.plan.storage) {
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.notEnoughSpaceInStorage, dirName));
				return;
			}
		}

        // directory name already exists in current dir
        if (_.findIndex<any>(this.currentDir.sons, (dir:any) => dir.name.toLowerCase() === dirName.toLowerCase()) >= 0) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.newDirNameAlreadyExists, dirName));
            return;
        }
        const notIdx = this.ns.showInfoMessage(util.format(this.dic.MESSAGES.directoryProcessStart, dirName), {timeout: 0});
        this.uploadedFiles = 0;
        this.addDirectoryStructure(this.currentDir._id, dirName, folderStructure[dirName], true, notIdx, totalFiles);
    }

    addDirectoryStructure(parentId, name, currentDir, isRootUploadDir, notIdx, totalFiles) {
        this.rs.addDir({name: name.trim(), parentDirId: parentId}).then((newDirectory) => {
            newDirectory.attachments = [];
            if (isRootUploadDir) {
                this.currentDir.sons.unshift(newDirectory);
            }
			else {
				const parentDir = _.find(this.currentDir.sons, {_id: parentId});
				parentDir.sons.unshift(newDirectory);
			}

            // filter all files in this level and upload them in a blocks
            let files = _.cloneDeep(_.filter(currentDir, item => item.size !== undefined));
            if (files && files.length) {
                this.uploadBlocks(files, newDirectory, true, notIdx, totalFiles, (err) => {
                    if (err) {
                        return;
                    }
                    this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.uploadMultiBlockProgress, this.uploadedFiles, totalFiles), null, {showSpinner: true});
                    // finishing uploading whole directory structure
                    if (this.uploadedFiles === totalFiles) {
                        this.ns.overwriteMessage(notIdx, this.dic.MESSAGES.directoryProcessFinish, null, {showSpinner: false});
                    }
                });
            }

            _.each(Object.keys(currentDir), key => {
                let item = currentDir[key];
                //sub-directory: recursively add it and it's attachment
                if (!item.size && !item.lastModified) {
                    this.addDirectoryStructure(newDirectory._id, key, item, false, notIdx, totalFiles);
                }
            });
        });
    }

    openEmailsWithAttachment(attachment) {
		this.router.navigate([this.dic.CONSTANTS.appStates.personalPages, this.dic.CONSTANTS.personalPages.mailbox], { state:
				{
					data: {attachmentName: attachment.name, ids: attachment.email_id}
				}
		});
    }

    openMoveModal(selectedAttachments) {
        this.moveDirectoryPopup = {
			itemsToMove: selectedAttachments,
			selectedMoveDir: null,
			moveInProcess: false,
			show: true
		};
    }

    move() {
        if (!this.moveDirectoryPopup.selectedMoveDir || this.moveDirectoryPopup.moveInProcess) {
			return;
		}

        if (this.moveDirectoryPopup.selectedMoveDir._id === this.currentDir._id) {
            this.ns.showWarnMessage(this.dic.ERRORS.cannotMoveDirToItsParent)
            return;
        }
        if (_.find(this.moveDirectoryPopup.itemsToMove, {_id: this.moveDirectoryPopup.selectedMoveDir._id})) {
            this.ns.showWarnMessage(this.dic.ERRORS.illegalDirMoving)
            return;
        }

        const moveData = {
            srcDirId: this.currentDir._id,
            destDirId: this.moveDirectoryPopup.selectedMoveDir._id,
            dirsToMove: _.filter(this.moveDirectoryPopup.itemsToMove, this.isDirectory),
            attachmentsToMove: _.reject(this.moveDirectoryPopup.itemsToMove, this.isDirectory)
        };

        this.moveDirectoryPopup.moveInProcess = true;

        this.rs.move(moveData).then(() => {
			moveData.dirsToMove.concat(moveData.attachmentsToMove).forEach(attachment => {
				attachment.selected = false;
			});

			this.currentDir.sons = _.difference(this.currentDir.sons, moveData.dirsToMove);
			this.currentDir.attachments = _.difference(this.currentDir.attachments, moveData.attachmentsToMove);

			this.moveDirectoryPopup.selectedMoveDir.attachments = this.moveDirectoryPopup.selectedMoveDir.attachments || [];

			moveData.attachmentsToMove.forEach(attachment => {
				if (!_.find(this.moveDirectoryPopup.selectedMoveDir.attachments, {_id: attachment._id})) {
					this.moveDirectoryPopup.selectedMoveDir.attachments.push(attachment);
				}
			});

			this.moveDirectoryPopup.selectedMoveDir.sons = this.moveDirectoryPopup.selectedMoveDir.sons.concat(moveData.dirsToMove);

            this.ns.showInfoMessage("Items moved successfully to " + this.moveDirectoryPopup.selectedMoveDir.name);
			this.moveDirectoryPopup = null;
        }, err => {
            this.moveDirectoryPopup.moveInProcess = false;
            if (err.data && err.data.message) {
				this.ns.showErrorMessage(err.data.message);
            }
        });
    }

    dragToMove(draggedItem, destinyItem) {
		if (!this.isDirectory(destinyItem)) {
			return;
		}

		const draggedItems = this.attachmentsTableEl.selectedItems.length ? this.attachmentsTableEl.selectedItems : [draggedItem];

		// cannot drag dir to itself
		if (_.find(draggedItems, {_id: destinyItem._id})) {
			return;
		}

        const isDraggingSystemFolder = _.some(draggedItems, {type: this.dic.CONSTANTS.directoryType.default});
        if (isDraggingSystemFolder) {
            this.ns.showWarnMessage(this.dic.ERRORS.moveDefaultFolder);
            return;
        }

		const itemsPhrase = draggedItems.length === 1 ? (this.isDirectory(draggedItems[0]) ? 'folder' : 'file') : _.some(draggedItems, this.isDirectory) ? _.some(draggedItems, item => !this.isDirectory(item)) ? 'items' : 'folders' : 'files';

        const subTitle = `Please note - you are about to move ${draggedItems.length} ${itemsPhrase} to folder: ${destinyItem.name}`;

		let body = [];
        if (_.some(draggedItems, this.isDirectory)) {
            body.push('All sub folders and attachments will be moved.');
        }

        this.gs.showPopup({
            title: `Move ${_.startCase(itemsPhrase)}`,
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Move',
            doneCb: () => {
				this.moveDirectoryPopup = {
					itemsToMove: draggedItems,
					selectedMoveDir: destinyItem,
					moveInProcess: false,
					show: false // don't show popup
				};

                this.move();
            }
        });
    }

    setExpirationDays() {
		// files only
		this.currentDir.attachments.forEach(file => {
			if (file.expiration_delete) {
				file.delete_attachment_days = this.gs.dateDiffInDays(new Date(file.expiration_delete), new Date());
				file.deleteAttachmentDays = file.delete_attachment_days;
			}
		});
    }

    modifyExpiration() {
        const attachment = this.expirationEditPopup.expirationEditFile
        if (!attachment.deleteAttachmentDays) {
            this.ns.showErrorMessage(this.dic.ERRORS.expiredInputInvalid);
            return;
        }
        this.rs.modifyAttachmentExpiration(attachment._id, {days: attachment.deleteAttachmentDays}).then((newExpirationDate) => {
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.attachmentsExpirationModified, attachment.name));
            attachment.expiration_delete = newExpirationDate;
            attachment.delete_attachment_days = attachment.deleteAttachmentDays;
            this.expirationEditPopup = null;
        });
    }

    cancelExpiration(attachment) {
        this.gs.showPopup({
            title: 'Cancel expiration',
            subTitle: `Please note, you are about to cancel expiration of ${attachment.name}`,
            body: [`Attachment will not be deleted after the expiration date`],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Confirm',
            doneCb: () => {
                this.rs.cancelAttachmentExpiration(attachment._id).then(() => {
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.attachmentsExpirationCanceled, attachment.name));
                    attachment.expiration_delete = null;
                    attachment.delete_attachment_days = null;
                    attachment.deleteAttachmentDays = null;
                    this.expirationEditPopup = null;
                });
            }
        });
    }

	onKeyDown(event: KeyboardEvent): boolean {
		const input = event.target as HTMLInputElement;
		const currentValue = input.value;
		const blockedKeys = [69, 109, 189, 187, 190]; // Existing blocked keys

		if (blockedKeys.includes(event.keyCode)) {
			event.preventDefault();
			return false;
		}

		let nextValue = currentValue;
		if (event.key >= '0' && event.key <= '9') {
			nextValue += event.key;
			if (parseInt(nextValue, 10) > 365) {
				event.preventDefault();
				return false;
			}
		}

		return true;
	}
}

function searchTextExecute(attachment, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return ((attachment.name && attachment.name.toLowerCase().indexOf(searchTerm) > -1));
}
