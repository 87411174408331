<popup-c head="Safe Preview (Sandbox) Mode"
	   (closeCb)="closeCb()"
	   size="large">
	<div class="flxClmn f-1-0 vSpace2 bs-enabled" style="overflow: auto;">

		<div class="flxRow-center center-block" *ngIf="!isLoaded">
			<loader height="100" width="100" style="opacity: 0.8;"></loader>
		</div>

		<div *ngIf="isLoaded && html" [innerHTML]="html"></div>

		<img *ngIf="isLoaded && screenshotUrl" [src]="screenshotUrl" alt="Screenshot">

	</div>
</popup-c>
