import {AUTH_EVENTS, ENV_CONSTS, ERROR_EVENTS} from '../constants';
import {Injectable} from "@angular/core";
import {Restangular} from "ngx-restangular";
import _ from 'lodash';
import {BehaviorSubject} from "rxjs";

const responseCodesMap = {
	200: ERROR_EVENTS.success,
	201: ERROR_EVENTS.success,
	400: ERROR_EVENTS.clientError,
	401: AUTH_EVENTS.notAuthenticated,
	403: AUTH_EVENTS.notAuthorized,
	406: AUTH_EVENTS.twoFactorRequiredError,
	419: AUTH_EVENTS.sessionTimeout,
	429: ERROR_EVENTS.tooManyReq,
	440: AUTH_EVENTS.sessionTimeout,
	500: ERROR_EVENTS.serverError,
}

@Injectable({
	providedIn: 'root'
})
export class RouteService {
	restangularHerokuBE = this.restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.backendHerokuUrl)
	});
	restangularAttachments = this.restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.attachmentUrl)
	});
	restangularPaypal = this.restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.paypalUrl)
	});
	restangularThreatsLinks = this.restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.scanLinksThreatsUrl)
	});
	restangularThreatsFiles = this.restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ENV_CONSTS.scanFilesThreatsUrl)
	});

	constructor(public restangular: Restangular) {
		this.restangular.configuration.responseSubjects = {};
		_.values(responseCodesMap).forEach(responseCode => {
			this.restangular.configuration.responseSubjects[responseCode] = new BehaviorSubject<any>(null);
		});

		// for loading bar
		this.restangular.configuration['ongoingHttpRequestSubj'] = new BehaviorSubject<any>(null);
	}

	setDefaultHeaders(headers: any) {
		this.restangular.configuration.defaultHeaders = headers;
		this.restangularHerokuBE.configuration.defaultHeaders = headers;
		this.restangularAttachments.configuration.defaultHeaders = headers;
		this.restangularPaypal.configuration.defaultHeaders = headers;
		this.restangularThreatsLinks.configuration.defaultHeaders = headers;
		this.restangularThreatsFiles.configuration.defaultHeaders = headers;
	}

    getGroups = () =>                                        this.restangular.all('admin').all('groups').get('').toPromise();
    uploadGroups = (data) =>                                 this.restangular.all('admin').all('groups').customPUT(data).toPromise();
    deleteGroup = (email) =>                                 this.restangular.all('admin').one('groups', email).customDELETE('').toPromise();
    changeRegion = (data) =>                                 this.restangular.all('admin').all('region').customPUT(data).toPromise();

    clioGetAuthorizationURL = () =>                          this.restangular.all('clio').all('authurl').get('').toPromise();
    clioGetAllDocs = () =>                                   this.restangular.all('clio').get('').toPromise();
    clioUploadDoc = (doc_ids) =>                             this.restangular.all('clio').post(doc_ids).toPromise();

    dropboxGetAuthorizationURL = () =>                       this.restangular.all('dropbox').all('authurl').get('').toPromise();
    dropboxGetAllDocs = () =>                                this.restangular.all('dropbox').get('').toPromise();
    dropboxUploadDoc = (doc_ids) =>                          this.restangular.all('dropbox').post(doc_ids).toPromise();

    checkCoupon = (couponCode) =>                            this.restangular.all('payment').all('paypal').all('coupon').one(couponCode).get('').toPromise();
    paypalCreateOrder = (data) =>                            this.restangularPaypal.all('payment').all('paypal').all('create').post(data).toPromise();
    paypalExecuteOrder = (data) =>                           this.restangularPaypal.all('payment').all('paypal').all('execute').post(data).toPromise();

    paypalCreateOrderI = (data) =>                           this.restangular.all('payment').all('paypal').all('create').post(data).toPromise();
    paypalExecuteOrderI = (data) =>                          this.restangular.all('payment').all('paypal').all('execute').post(data).toPromise();
    getPlanDetails = () =>                                   this.restangular.all('payment').all('paypal').get('').toPromise();

    squareGetConfig = () =>                                  this.restangular.all('payment').all('square').get('').toPromise();

    userLogin = (email) =>                                   this.restangular.all('user').all('login').one(email).get('').toPromise();
    signupUser = (user) =>                                   this.restangular.all('user').post(user).toPromise();
    createApiKey  = (data) =>                                this.restangular.all('user').all('api').post(data).toPromise();
    updateUserSettings  = (data) =>                          this.restangular.all('user').all('settings').customPUT(data).toPromise();

    getAllLists  = () =>                                     this.restangular.all('list').get('').toPromise();
    deleteList = (listId) =>                                 this.restangular.one('list', listId).customDELETE('').toPromise();
    updateList = (listId,data) =>                            this.restangular.one('list',listId).customPUT(data).toPromise();
    sendMassListVerificationRequest = (listId) =>            this.restangular.all('list').all('verification').all(listId).post('').toPromise();
    syncContactsWithAllUsers = () =>                         this.restangular.all('list').one('regular').customPUT('').toPromise();

    getAllContacts = () =>                                   this.restangular.all('contact').get('').toPromise();
    getContactByEmail = (email) =>                           this.restangular.all('contact').all('email').one(email).get('').toPromise();
    updateContact = (contactId, contact) =>                  this.restangular.one('contact', contactId).customPUT(contact).toPromise();
    addNewContact = (contact) =>                             this.restangular.all('contact').post(contact).toPromise();
    deleteContact = (contactId) =>                           this.restangular.one('contact',contactId).customDELETE('').toPromise();


    getAttachment = (attachmentId) =>                        this.restangular.one('attachment', attachmentId).get('').toPromise();
    usageAttachment = (attachmentId) =>                      this.restangular.all('attachment').all('usage').one(attachmentId).get('').toPromise();

    modifyAttachmentExpiration = (attachmentId, data) =>     this.restangular.all('attachment').all('expiration').one(attachmentId).customPUT(data).toPromise();
    cancelAttachmentExpiration = (attachmentId) =>           this.restangular.all('attachment').all('expiration').one(attachmentId).customDELETE('').toPromise();

    getDirectoryData = (dirId) =>                            this.restangular.one('directory', dirId).get('').toPromise();
    addDir = (data) =>                                       this.restangular.all('directory').post(data).toPromise();
    deleteDir = (directoriesIds) =>                          this.restangular.one('directory', directoriesIds).customDELETE('').toPromise();
    renameDirectory = (dirId, newDirName) =>                 this.restangular.one('directory', dirId).customPUT(newDirName).toPromise();
    removeAttachmentFromDirectory = (data) =>                this.restangular.all('directory').all('attachments').customPUT(data).toPromise();
    move = (data) =>                                         this.restangular.all('directory').all('move').customPUT(data).toPromise();
    deleteAttachmentsAndDirectories = (data) =>              this.restangular.all('directory').all('delete').customPUT(data).toPromise();

    sendEmailMessage = (message) =>                          this.restangular.all('email').post(message).toPromise();
    previewEmail = (message) =>                              this.restangular.all('email').all('preview').post(message).toPromise();
    getAllEmails = (data) =>                                 this.restangularAttachments.all('email').all('display').customGET('info', data).toPromise();
    getEmailFullReplies = (emailId) =>                       this.restangular.all('email').all('display').all('reply').one('full',emailId).get('').toPromise();
    prepareFullReport = (emailId, params) =>                 this.restangular.all('email').all('display').all('summary').all('recipients').all('csv').one('prepare',emailId).customGET("", params).toPromise();
    getEmailSummary = (emailId, params = {}) =>              this.restangular.all('email').all('display').one('summary',emailId).customGET('', params).toPromise();
    getEmailSummaryWithInitMass = (emailId, params = {}) =>  this.restangular.all('email').all('display').all('summary').one('initmass',emailId).customGET('', params).toPromise();
    getEmailGroupSummary = (groupEmailIds) =>                this.restangular.all('email').all('display').all('summary').all('group').customGET('', groupEmailIds).toPromise();
    getEmailGroupSummaryReload = (grEmailIds) =>             this.restangular.all('email').all('display').all('summary').all('group').all('reload').customGET('', grEmailIds).toPromise();
    initEmailMassSummary = (emailId) =>                      this.restangular.all('email').all('display').all('summary').one('mass',emailId).get('').toPromise();
    getOpenSummary = (emailId, params) =>                    this.restangular.all('email').all('display').all('summary').one('open',emailId).customGET("", params).toPromise();

    previewMassEmail = (listId, params) =>                   this.restangular.all('list').all('mass').all('preview').all(listId).customGET("", params).toPromise();
    saveMessageAsDraft  = (message) =>                       this.restangular.all('email').all('draft').post(message).toPromise();
    deleteMessage  = (emailIds, params = {}) =>          this.restangular.one('email', emailIds).customDELETE('', params).toPromise();
    deleteMessageRecipients  = (emailId) =>                  this.restangular.all('email').one('recipients',emailId).customDELETE('').toPromise();

	recallEmail  = (emailId, params = {}) =>                this.restangularAttachments.all('email').one('recall', emailId).customPUT('', null, params).toPromise();

	changeMessageStateForAllRecipients = (emailId, data, params = {}) =>   this.restangular.all('email').all('state').all(emailId).customPUT(data, null, params).toPromise();
    changeMessageForRecipient = (emailId, recId, data, params = {}) =>    this.restangular.all('email').all('state').all(emailId).all(recId).customPUT(data,null, params).toPromise();
    updateAdvancedMessage = (emailId, data, params = {}) => this.restangular.all('email').all('advanced').all(emailId).customPUT(data, null, params).toPromise();
    updateEmailContent  = (emailId, data, params = {}) =>                  this.restangular.all('email').all('data').all(emailId).customPUT(data, null, params).toPromise();
    decryptEmailContent  = (emailId, params = {}) =>              this.restangular.all('email').all('data').one(emailId).customGET('', params).toPromise();
    getEmailEvents  = (emailId, params = {}) =>                           this.restangular.one('email').all('tracking').one(emailId).customGET('', params).toPromise();
    checkEmailSensitivity = (emailId) =>                     this.restangularAttachments.all('email').one('sensitivity', emailId).customPUT('').toPromise();

    getEmailPostmark = (emailId) =>                          this.restangular.all('email').all('display').one('postmark',emailId).get('').toPromise();
    verifyReceiptPostmark  = (emailId, recipId) =>           this.restangular.all('postmark').all('verify').one(emailId, recipId).get('').toPromise();

    updateScheduledTime = (data, params = {}) =>                          this.restangular.all('email').all('schedule').all(data.email_id).post(data).toPromise();
    schedSendNow = (emailId, params = {}) =>                              this.restangular.all('email').one('schedule', emailId).customPUT('', null, params).toPromise();

    /* outbound quarantined */
    getQuarantinedOutboundEmails = () =>                     this.restangular.all('admin').all('outbound').all('quarantined').get('').toPromise();
    doActionOnQuarantinedOutboundEmail = (quarantinedId, data) =>    this.restangular.all('admin').all('outbound').all('quarantined').one(quarantinedId).customPUT(data).toPromise();
    deleteQuarantinedOutboundEmail = (quarantinedId) =>      this.restangular.all('admin').all('outbound').all('quarantined').customDELETE(quarantinedId).toPromise();
    checkQuarantineOutboundStatus = (quarantinedId) =>       this.restangular.all('admin').all('outbound').all('quarantined').one('status', quarantinedId).get('').toPromise();
    getQuarantinedEmailTracking = (quarantinedId) =>         this.restangular.all('admin').all('outbound').all('quarantined').one('tracking', quarantinedId).get('').toPromise();
    updateQuarantineSettings = (data) =>                     this.restangular.all('admin').all('outbound').all('quarantined').all('config').customPUT(data).toPromise();

    /* admin quarantined */
    getQuarantinedEmails = (params) =>                       this.restangularAttachments.all('admin').all('inbound').all('quarantined').customGET('', params).toPromise();
    getQuarantinedPlanInfo = () =>                           this.restangular.all('admin').all('inbound').all('quarantined').one('plan').get('').toPromise();
    getQuarantinedEmailData = (quarantinedId, params) =>     this.restangularAttachments.all('admin').all('inbound').all('quarantined').one('preview', quarantinedId).customGET('', params).toPromise();
    getQuarantinedThreats = (quarantinedId, params) =>       this.restangular.all('admin').all('inbound').all('quarantined').one('threats', quarantinedId).customGET('', params).toPromise();
    getQuarantinedEmailHeaders = (quarantinedId, params) =>  this.restangular.all('admin').all('inbound').all('quarantined').one('headers', quarantinedId).customGET('', params).toPromise();
    deleteQuarantinedEmail = (quarantinedId, params) =>      this.restangular.all('admin').all('inbound').all('quarantined').one(quarantinedId).customDELETE('', params).toPromise();
    doActionOnQuarantinedEmail = (quarantinedId, data, params) =>    this.restangular.all('admin').all('inbound').all('quarantined').one(quarantinedId).customPUT(data, params).toPromise();
	doHeavyActionOnQuarantinedEmail = (quarantinedId, data, params) =>    this.restangularAttachments.all('admin').all('inbound').all('quarantined').one(quarantinedId).customPUT(data, params).toPromise();
	checkQuarantineStatus = (quarantinedId) =>               this.restangular.all('admin').all('inbound').all('quarantined').one('status', quarantinedId).get('').toPromise();

    /* user quarantined */
    getUserQuarantinedEmails = (params) =>                       this.restangularAttachments.all('quarantined').customGET('', params).toPromise();
    getUserQuarantinedPlanInfo = () =>                           this.restangularAttachments.all('quarantined').one('plan').get('').toPromise();
	getMailboxInfo = () =>                           			this.restangularAttachments.all('user').one('mailbox').get('').toPromise();
	updateMailboxNotification = (actionInfo) =>                 this.restangularAttachments.all('user').one('mailbox').customPUT(actionInfo).toPromise();


	getUserQuarantinedEmailData = (quarantinedId) =>             this.restangularAttachments.all('quarantined').one('preview', quarantinedId).get('').toPromise();
    getUserQuarantinedThreats = (quarantinedId) =>               this.restangular.all('quarantined').one('threats', quarantinedId).get('').toPromise();
    getUserQuarantinedEmailHeaders = (quarantinedId) =>          this.restangular.all('quarantined').one('headers', quarantinedId).get('').toPromise();
    deleteUserQuarantinedEmail = (quarantinedId, params) =>      this.restangular.all('quarantined').one(quarantinedId).customDELETE('', params).toPromise();
    doActionOnUserQuarantinedEmail = (quarantinedId, data) =>    this.restangular.all('quarantined').one(quarantinedId).customPUT(data).toPromise();
    checkUserQuarantineStatus = (quarantinedId) =>               this.restangular.all('quarantined').one('status', quarantinedId).get('').toPromise();

    getSecurityAwarenessInfo = () =>                         this.restangular.all('admin').all('inbound').all('awareness').get('').toPromise();
	getMailboxesForSecurityAwareness = () =>                 this.restangular.all('admin').all('inbound').all('awareness').all('mailboxes').get('').toPromise();
	updateSecurityAwarenessInfo = (data) =>                  this.restangular.all('admin').all('inbound').all('awareness').customPUT(data).toPromise();

    getInboundTrends  = (params) =>                          this.restangularAttachments.all('admin').all('inbound').all('trends').customGET('', params).toPromise();
	getInboundTrendsBatch  = (params) =>                     this.restangularAttachments.all('admin').all('inbound').all('trends').all('batch').customGET('', params).toPromise();
	getSenderConnectionGraphData  = (params) =>              this.restangularAttachments.all('admin').all('inbound').all('trends').all('connection').customGET('', params).toPromise();

	getUrlTracking = (params) =>                             this.restangular.all('admin').all('inbound').all('tracking').all('url').customGET('', params).toPromise();
    urlTrackingAction = (actionInfo) =>                      this.restangular.all('admin').all('inbound').all('tracking').all('url').customPUT(actionInfo).toPromise();
	deleteUrlTracking = (urlId, params) =>                      	 this.restangular.all('admin').all('inbound').all('tracking').all('url').customDELETE(urlId, params).toPromise();


	getTpDomains = () =>                                     this.restangular.all('admin').all('inbound').all('tp').one('domains').get('').toPromise();
    updateTpDomains = (domain, data) =>                      this.restangular.all('admin').all('inbound').all('tp').one('domains').one(domain).customPUT(data).toPromise();

    getTpBlockFilesConfig = () =>                            this.restangular.all('admin').all('inbound').all('tp').one('files').get('').toPromise();
    updateBlockFilesConfig = (data) =>                       this.restangular.all('admin').all('inbound').all('tp').one('files').customPUT(data).toPromise();
    getTPConfig = () =>                                      this.restangular.all('admin').all('inbound').all('tp').one('config').get('').toPromise();
    updateTpConfig = (data) =>                               this.restangular.all('admin').all('inbound').all('tp').one('config').customPUT(data).toPromise();

    getTPRules = () =>                                       this.restangular.all('admin').all('inbound').all('tp').one('rules').get('').toPromise();
	updateTpRules = (ruleId, data) =>                        this.restangular.all('admin').all('inbound').all('tp').one('rules').one(ruleId).customPUT(data).toPromise();

	getInboundEmailRules = () =>                             this.restangular.all('admin').all('inbound').one('rules').get('').toPromise();
	addInboundEmailRule = (data) =>                          this.restangular.all('admin').all('inbound').all('rules').post(data).toPromise();
	updateInboundEmailRule = (ruleId, data) =>               this.restangular.all('admin').all('inbound').one('rules').one(ruleId).customPUT(data).toPromise();

	deleteInboundEmailRule = (ruleId) =>                     this.restangular.all('admin').all('inbound').all('rules').customDELETE(ruleId).toPromise();

    getMailboxesAliases = () =>                              this.restangular.all('admin').all('aliases').get('').toPromise();
    uploadMailboxesAliases = (data) =>                       this.restangular.all('admin').all('aliases').post(data).toPromise();
    deleteAlias = (alias) =>                                 this.restangular.all('admin').all('aliases').customDELETE(alias).toPromise();
    getTPConfigUsers = (email) =>                            this.restangular.all('admin').all('inbound').all('tp').one('users', email).get('').toPromise();
    updateTpConfigUsers = (data) =>                          this.restangular.all('admin').all('inbound').all('tp').one('users').customPUT(data).toPromise();

	getMailboxesInfo = () =>                                 this.restangular.all('admin').all('inbound').all('mailboxes').get('').toPromise();
	updateMailboxInfo = (data) =>                    		this.restangular.all('admin').all('inbound').all('mailboxes').customPUT(data).toPromise();

	getThreatsForMailbox = (mailbox, params) =>  this.restangular.all('admin').all('inbound').all('tp').all('threats').customGET(mailbox, params).toPromise();
    checkUserReviewer = () =>                                this.restangular.all('user').all('checkUserReviewer').get('').toPromise();
    updateUserThreats = (data) =>                            this.restangular.all('user').all('threats').customPUT(data).toPromise();
    getUserThreats = () =>                                   this.restangular.all('user').all('threats').get('').toPromise();

    addNewTemplate  = (data) =>                              this.restangular.all('template').post(data).toPromise();
    updateTemplate  = (templateId, data) =>                  this.restangular.one('template', templateId).customPUT(data).toPromise();
    resetSystemTemplate  = (templateId) =>                   this.restangular.all('template').one('reset', templateId).customPUT('').toPromise();


    getTemplates  = () =>                                    this.restangular.all('template').get('').toPromise();
    getInviteCustomizedTemplate  = () =>                     this.restangular.all('template').all('invite').get('').toPromise();
    deleteTemplate = (templateIds) =>                        this.restangular.one('template', templateIds).customDELETE('').toPromise();

    getOutboundPlanSettings = () =>                          this.restangular.all('admin').all('outbound').all('plan').get('').toPromise();
    inviteUserToSharedPlan = (data) =>                       this.restangular.all('admin').all('outbound').all('users').post(data).toPromise();
    doActionOnPlanUser  = (userEmail, data) =>               this.restangular.all('admin').all('outbound').all('users').one(userEmail).customPUT(data).toPromise();
    doActionOnPlanAdmin  = (email, data) =>                  this.restangular.all('admin').all('outbound').all('admins').one(email).customPUT(data).toPromise();
    getUsersOfSharedPlan  = (params) =>                      this.restangular.all('admin').all('outbound').all('users').customGET('', params).toPromise();
    doActionOnOutboundPlan = (data) =>                       this.restangular.all('admin').all('outbound').all('plan').customPUT(data).toPromise();
    removeUserFromSharedPlan = (data) =>                     this.restangular.all('admin').all('outbound').all('users').all('status').customPUT(data).toPromise();
    activateEmailRelayAndGenKey = (data) =>                  this.restangular.all('admin').all('outbound').all('relay').post(data).toPromise();
    updateOutboundReportSettings  = (data) =>                this.restangular.all('admin').all('outbound').one('report').customPUT(data).toPromise();
    getUsersDetailedReport  = (params) =>                    this.restangular.all('admin').all('outbound').one('report').customGET('', params).toPromise();
    getAdminReportSettings  = () =>                          this.restangular.all('admin').all('outbound').all('report').one('settings').get('').toPromise();
    getPlanOutboundAuditLogs = (params) =>                   this.restangular.all('admin').all('outbound').all('logs').customGET('', params).toPromise();
    getOutboundTrends  = (params) =>                         this.restangularAttachments.all('admin').all('outbound').all('trends').customGET('', params).toPromise();
	getOutboundTrendsBatch  = (params) =>                    this.restangularAttachments.all('admin').all('outbound').all('trends').all('batch').customGET('', params).toPromise();


	getThreatResponses = () =>                               this.restangular.all('admin').all('inbound').all('tp').all('threatResponse').get('').toPromise();
    getMailboxesForThreatResponse = () =>                    this.restangular.all('admin').all('inbound').all('tp').all('threatResponse').all('mailboxes').get('').toPromise();
    createThreatResponse = (data) =>                         this.restangular.all('admin').all('inbound').all('tp').all('threatResponse').post(data).toPromise();
    threatResponseAction = (id, data) =>                     this.restangular.all('admin').all('inbound').all('tp').all('threatResponse').one(id).customPUT(data).toPromise();

    getDiscoveredServices = () =>                            this.restangular.all('admin').all('inbound').all('services').get('').toPromise();
	getDomainImpersonations = () =>                          this.restangular.all('admin').all('inbound').all('domainImpersonation').get('').toPromise();

	updateDomainImpersonationObj = (domainId, data) =>       this.restangular.all('admin').all('inbound').all('domainImpersonation').one(domainId).customPUT(data).toPromise();
	updateDomainImpersonationConfiguration = (data) =>       this.restangular.all('admin').all('inbound').all('domainImpersonation').post(data).toPromise();


	getInboundReport = (params) =>                           this.restangular.all('admin').all('inbound').all('report').customGET('', params).toPromise();
    activateInboundEmailRelayAndGenKey = (data) =>           this.restangular.all('admin').all('inbound').all('relay').post(data).toPromise();
    getPlanInboundAuditLogs = (params) =>                    this.restangular.all('admin').all('inbound').all('logs').customGET('', params).toPromise();

    getPartnerStats = () =>                                  this.restangular.all('admin').all('partner').all('stats').get('').toPromise();
    updateReportSettings = (data) =>                         this.restangular.all('admin').all('partner').all('report').post(data).toPromise();
    getPartnerInfo = () =>                                   this.restangular.all('admin').all('partner').get('').toPromise();
    doActionOnPartner = (actionInfo) =>                      this.restangular.all('admin').all('partner').post(actionInfo).toPromise();

	addPartnerReviewer = (reviewer) =>            			this.restangular.all('admin').all('partner').all('reviewers').one(reviewer).post().toPromise();
	deletePartnerReviewer = (reviewer) =>         			this.restangular.all('admin').all('partner').all('reviewers').customDELETE(reviewer).toPromise();


    getInboundPlanSettings = () =>                           this.restangular.all('admin').all('inbound').get('').toPromise();

    getEmailTracingLog = (params) =>                         this.restangular.all('admin').all('trace').customGET('', params).toPromise();
    getEmailEventsForRecipient = (recipient, messageId) =>   this.restangular.all('admin').all('trace').all('tracking').all(recipient).one(messageId).get('').toPromise();
	traceChangeRecipientStatus = (userId, emailId, recId, data) =>    this.restangular.all('admin').all('trace').all('recipient').all(userId).all(emailId).all(recId).customPUT(data).toPromise();


	getIntegrations  = () =>                         		this.restangular.all('admin').all('integrations').get('').toPromise();

	getExchangeUsers = (params) =>                           this.restangular.all('admin').all('integrations').all('outlook').all('mailbox').customGET('', params).toPromise();

	createGroupSubscription = () =>                          this.restangular.all('admin').all('integrations').all('outlook').all('groups').post('').toPromise();
	disconnectExchange = (params=null) =>					 this.restangular.all('admin').all('integrations').all('outlook').customDELETE('', params).toPromise();

	createGSuiteGroups = () =>                               this.restangular.all('admin').all('integrations').all('gsuite').all('groups').post('').toPromise();
	getGSuiteUsers = () =>                                   this.restangular.all('admin').all('integrations').all('gsuite').all('mailbox').get('').toPromise();
	connectGSuite = (data) =>                                this.restangular.all('admin').all('integrations').all('gsuite').post(data).toPromise();
	disconnectGSuite = () =>                                 this.restangular.all('admin').all('integrations').all('gsuite').customDELETE('').toPromise();

	connectOpenID = (data) =>                                this.restangular.all('admin').all('integrations').all('openid').post(data).toPromise();
	disconnectOpenID = () =>                                 this.restangular.all('admin').all('integrations').all('openid').customDELETE('').toPromise();
	exchangeTokenOpenID = (data) =>                          this.restangularPaypal.all('openid').all('integrations').all('token').post(data).toPromise();

	authorizeSaml = (data) =>                                this.restangular.all('admin').all('integrations').all('saml').all('authorize').post(data).toPromise();
	connectSaml = (data) =>                                  this.restangular.all('admin').all('integrations').all('saml').post(data).toPromise();
	disconnectSaml = () =>                                   this.restangular.all('admin').all('integrations').all('saml').customDELETE('').toPromise();

	configureCheckpoint  = (data) =>                         this.restangular.all('admin').all('integrations').all('checkpoint').post(data).toPromise();
    configureS3Integration  = (data) =>                      this.restangular.all('admin').all('integrations').all('s3').post(data).toPromise();
	configureSyslogIntegration = (data) =>                   this.restangular.all('admin').all('integrations').all('syslog').post(data).toPromise();

	configureSplunkIntegration = (data) =>                   this.restangular.all('admin').all('integrations').all('splunk').post(data).toPromise();
	configureAzureEventHubIntegration = (data) =>            this.restangular.all('admin').all('integrations').all('eventhub').post(data).toPromise();

	createLdapGroups = () =>                                 this.restangular.all('admin').all('integrations').all('ldap').all('groups').get('').toPromise();
	connectLDAP = (data) =>                                  this.restangular.all('admin').all('integrations').all('ldap').post(data).toPromise();
	disconnectLDAP = () =>                                   this.restangular.all('admin').all('integrations').all('ldap').customDELETE('').toPromise();


	getUsage = (params) =>                                   this.restangularAttachments.all('usage').all('all').customGET('', params).toPromise();
    getSignature = () =>                                     this.restangular.all('template').one('signature').get('').toPromise();
    saveSignature = (data) =>                                this.restangular.all('template').one('signature').customPUT(data).toPromise();
    deleteSignature = () =>                                  this.restangular.all('template').one('signature').customDELETE().toPromise();
    getUserInformation  = () =>                              this.restangular.all('user').get('').toPromise();
    updateInboundReportSettings  = (data) =>                 this.restangular.all('admin').all('inbound').one('report').customPUT(data).toPromise();
    getAccountInfo  = () =>                                  this.restangular.all('user').all('account').get('').toPromise();
    deleteAccountEmail = (accountEmail) =>                   this.restangular.all('user').all('preferences').all('from').customDELETE(accountEmail).toPromise();
    addAccountEmail  = (data) =>                             this.restangular.all('user').all('preferences').all('from').post(data).toPromise();
    updateAccountEmail  = (accountEmail, data) =>            this.restangular.all('user').all('preferences').all('from').all(accountEmail).customPUT(data).toPromise();

    sendVerficationMail  = () =>                             this.restangular.all('user').all('verficationMail').post('').toPromise();
    sendVerficationMailWithEmail = (email) =>                this.restangular.all('user').all('verficationMail').all(email).post('').toPromise();
    getUserVerified  = () =>                                 this.restangular.all('user').all('verficationMail').get('').toPromise();
    getUserVerifiedWithEmail = (email) =>                    this.restangular.all('user').all('verficationMail').one(email).get('').toPromise();
    deleteUserAccount  = (userEmail) =>                      this.restangular.all('user').customDELETE(userEmail).toPromise();

	updateUserSharedPlanStatus = (data) =>                   this.restangular.all('user').all('plan').all('shared').customPUT(data).toPromise();

    getUserNotifications  = () =>                            this.restangular.all('user').all('notifications').get('').toPromise();
    deleteNotification  = (data) =>                          this.restangular.all('user').all('notifications').customDELETE(data).toPromise();
    inviteFriend = (data) =>                                 this.restangular.all('user').all('invite').post(data).toPromise();

    getOnboardingProcessInfo = () =>                         this.restangular.all('general').all('onboarding').get('').toPromise();
    updateOnboardingQuestionnaire = (data) =>                this.restangular.all('general').all('onboarding').post(data).toPromise();
    getSensitivityEngineList = () =>                         this.restangular.all('general').all('sensitivityEngine').get('').toPromise();
    supportContact = (data) =>                               this.restangular.all('general').all('support').all('contact').post(data).toPromise();
    sendReport = (data) =>                                   this.restangular.all('general').all('report').customPUT(data).toPromise();

    updateUserAdvanced = (data) =>                           this.restangular.all('user').all('preferences').all('advanced').one('new').customPUT(data).toPromise();
    resetCustomization = () =>                               this.restangular.all('admin').all('customization').customDELETE('').toPromise();
    updateCustomizationDetails = (data) =>                   this.restangular.all('admin').all('customization').customPUT(data).toPromise();
    getCustomization = () =>                                 this.restangular.all('admin').all('customization').get('').toPromise();
    updateAlerts = (data) =>                                 this.restangular.all('user').all('alerts').customPUT(data).toPromise();

    getPlanDomains = () =>                                   this.restangular.all('admin').one('domains').get('').toPromise();
    getPlanAdminInfo = (admin, params) =>                    this.restangular.all('admin').all('admins').one('info', admin).customGET("", params).toPromise();
    isDomainVerified = (domain, params) =>                   this.restangular.all('admin').all('domains').one('verify', domain).customGET("", params).toPromise();
    deleteDomain = (domain) =>                               this.restangular.all('admin').all('domains').customDELETE(domain).toPromise();
    addDomain = (domain) =>                                  this.restangular.all('admin').one('domains', domain).post('').toPromise();
    updateDomain = (domain, data) =>                         this.restangular.all('admin').all('domains').one(domain).customPUT(data).toPromise();

	updatePolicy = (data) =>                                 this.restangular.all('admin').all('outbound').all('policy').customPUT(data).toPromise();
	installPolicy = (data) =>                                this.restangular.all('admin').all('outbound').all('policy').post(data).toPromise();
    getRulesAndPolicy = () =>                                this.restangular.all('admin').all('outbound').all('rules').get('').toPromise();
    configureRuleOnAdmin = (email, data) =>                  this.restangular.all('admin').all('outbound').all('rules').all('admin').one(email).customPUT(data).toPromise();

	getSensitivityTypes = () =>                              this.restangular.all('admin').all('sensitivity').all('types').get('').toPromise();
	addSensitivityType = (data) =>                           this.restangular.all('admin').all('sensitivity').all('types').post(data).toPromise();
	updateSensitivityType = (data) =>                        this.restangular.all('admin').all('sensitivity').all('types').customPUT(data).toPromise();
	deleteSensitivityType = (typeName) =>                    this.restangular.all('admin').all('sensitivity').all('types').customDELETE(typeName).toPromise();

	getSensitivityConfiguration = () =>						this.restangular.all('admin').all('sensitivity').all('configuration').get('').toPromise();
	updateSensitivityConfiguration = (data) =>              this.restangular.all('admin').all('sensitivity').all('configuration').customPUT(data).toPromise();

	getSensitivityTrends  = (params) =>                     this.restangularAttachments.all('admin').all('sensitivity').all('trends').customGET('', params).toPromise();

	testTextSensitivity = (data) =>                          this.restangularAttachments.all('admin').all('sensitivity').all('test').all('text').post(data).toPromise();

	checkSensitivityAttachment = (attachmentId) =>           this.restangularAttachments.all('attachment').one('sensitivity', attachmentId).customPUT('').toPromise();

	addRule = (data) =>                                      this.restangular.all('admin').all('outbound').all('rules').post(data).toPromise();
    deleteRule = (ruleId) =>                                 this.restangular.all('admin').all('outbound').all('rules').customDELETE(ruleId).toPromise();
    updateRule = (ruleId, data) =>                           this.restangular.all('admin').all('outbound').all('rules').one(ruleId).customPUT(data).toPromise();

	deleteThreatResponseRecord = (recordId) =>			     this.restangular.all('admin').all('inbound').all('tp').all('threatResponse').customDELETE(recordId).toPromise();
    update2FaStatus = (data) =>                              this.restangular.all('user').all('2fa').customPUT(data).toPromise();
    getQrCodeFor2fa = () =>                              	this.restangular.all('user').all('2fa').get('').toPromise();
    do2FaAuthentication = (data) =>                          this.restangular.all('user').all('2fa').post(data).toPromise();
	verifyApiAccessToken = (token) =>                        this.restangular.all('user').all('token').one(token).get('').toPromise();


    getDevices = () =>                                       this.restangular.all('user').all('compromised').get('').toPromise();
    deleteDevice = (deviceId) =>                             this.restangular.all('user').all('compromised').customDELETE(deviceId).toPromise();

    getAccountCompromisedTrends = (params) =>                this.restangularAttachments.all('admin').all('compromised').all('trends').customGET('', params).toPromise();
    getAccountCompromisedUsers = (userEmail) =>              this.restangular.all('admin').all('compromised').all('users').one(userEmail).get('').toPromise();
    updateAccountCompromisedUsers = (data) =>                this.restangular.all('admin').all('compromised').all('users').customPUT(data).toPromise();
    getAccountCompromisedConfiguration = () =>               this.restangular.all('admin').all('compromised').all('config').get('').toPromise();
    updateAccountCompromisedConfig = (data) =>               this.restangular.all('admin').all('compromised').all('config').customPUT(data).toPromise();
    incidentAction = (email, data) =>                        this.restangular.all('admin').all('compromised').all('incidents').one(email).customPUT(data).toPromise();
    getAccountCompromisedIncidents = (email, params) =>      this.restangular.all('admin').all('compromised').all('incidents').one(email).customGET('',params).toPromise();
	getUsersStatuses = () =>      							 this.restangular.all('admin').all('compromised').all('statuses').get('').toPromise();
    getAccountCompromisedIncidentsByType = (type, params) => this.restangular.all('admin').all('compromised').all('incidents').all('type').one(type).customGET('', params).toPromise();
    getAccountCompromisedAuditLogs = (params) =>             this.restangular.all('admin').all('compromised').all('audit').customGET('', params).toPromise();

	getAuditLogScheduled = () =>							 this.restangular.all('admin').all('audit').all('scheduled').get('').toPromise();
	updateAuditLogScheduled = (data) =>					 	 this.restangular.all('admin').all('audit').all('scheduled').customPUT(data).toPromise();
	addAccountCompromisedReviewer = (reviewer) =>            this.restangular.all('admin').all('compromised').all('reviewers').one(reviewer).post().toPromise();
	deleteAccountCompromisedReviewer = (reviewer) =>         this.restangular.all('admin').all('compromised').all('reviewers').customDELETE(reviewer).toPromise();
	updateAccountCompromisedReviewer = (reviewer, data) =>   this.restangular.all('admin').all('compromised').all('reviewers').one(reviewer).customPUT(data).toPromise();

	updateExtraReportReviewers = (reviewer, data) =>            this.restangular.all('admin').all('compromised').all('reviewers').all('extra').one(reviewer).customPOST(data).toPromise();

	getOutboundReviewers = () => 							 this.restangular.all('admin').all('outbound').all('reviewers').get('').toPromise();
	addOutboundReviewer = (reviewer) =>            			 this.restangular.all('admin').all('outbound').all('reviewers').one(reviewer).post().toPromise();
	deleteOutboundReviewer = (reviewer) =>         			 this.restangular.all('admin').all('outbound').all('reviewers').customDELETE(reviewer).toPromise();
	updateOutboundReviewer = (reviewer, data) =>   			 this.restangular.all('admin').all('outbound').all('reviewers').one(reviewer).customPUT(data).toPromise();

    getArchiveConfiguration = () =>                          this.restangular.all('admin').all('archive').all('configurations').get('').toPromise();
    updateArchiveConfigurations = (data) =>                  this.restangular.all('admin').all('archive').all('configurations').customPUT(data).toPromise();
    searchArchive = (caseId) =>                      this.restangular.all('admin').all('archive').one('search', caseId).get('').toPromise();
	getArchiveCaseInfoWithScroll = (caseId, params) =>       this.restangular.all('admin').all('archive').all('search').one(caseId).customPOST(params).toPromise();

	getArchiveEmailInfo = (messageId) =>                     this.restangularAttachments.all('admin').all('archive').all('info').one(messageId).get('').toPromise();
    deleteEmailFromArchive = (messageId) =>                  this.restangular.all('admin').all('archive').all('info').customDELETE(messageId).toPromise();
	restoreEmailFromArchive = (messageId, data) =>                 this.restangularAttachments.all('admin').all('archive').one('restore', messageId).customPOST(data).toPromise();
    getArchiveAuditLogs = (params) =>                        this.restangular.all('admin').all('archive').all('audit').customGET("", params).toPromise();
    addArchiveReviewer = (reviewer) =>                       this.restangular.all('admin').all('archive').all('reviewers').one(reviewer).post().toPromise();
    deleteArchiveReviewer = (reviewer) =>                    this.restangular.all('admin').all('archive').all('reviewers').customDELETE(reviewer).toPromise();
	updateArchiveReviewer = (reviewer, data) =>              this.restangular.all('admin').all('archive').all('reviewers').one(reviewer).customPUT(data).toPromise();

	getInboxSettings = () =>                 				 this.restangular.all('inbox').all('settings').get('').toPromise();
	searchUserArchive = (params) =>                    		 this.restangular.all('inbox').one('search').customGET("", params).toPromise();
	searchUserArchiveWithScroll = (params) =>        		this.restangular.all('inbox').one('search').customPOST(params).toPromise();

	getUserArchiveEmailInfo = (messageId) =>                 this.restangular.all('inbox').all('info').one(messageId).get('').toPromise();

	createMultipartUpload = (key, data) =>               	 this.restangular.all('admin').all('archive').all('migration').one('export', key).customPOST(data).toPromise();
	abortMultipartUpload = (key, fileSize, uploadId) =>      this.restangular.all('admin').all('archive').all('migration').one('export', key).remove({fileSize, uploadId}).toPromise();
	createMigrationArchive = () =>                       	 this.restangular.all('admin').all('archive').all('migration').all('create').post().toPromise();

	startAPIMigration = (data) =>                       	 this.restangular.all('admin').all('archive').all('migration').all('api').post(data).toPromise();
	watchAPIMigration = (events) =>                       	 this.restangular.all('admin').all('archive').all('migration').all('api').customGET('', {events}).toPromise();
	deleteAPIMigration = (events) =>                       	 this.restangular.all('admin').all('archive').all('migration').all('api').customDELETE('', {events}).toPromise();
	getAPIMigrationReport = () =>                       	 this.restangular.all('admin').all('archive').all('migration').all('api').customPUT('').toPromise();

	startAWSMigration = (data) =>                       	 this.restangular.all('admin').all('archive').all('migration').all('aws').post(data).toPromise();
	watchAWSMigration = () =>                       	 	 this.restangular.all('admin').all('archive').all('migration').all('aws').get('').toPromise();
	deleteAWSMigration = () =>                       	     this.restangular.all('admin').all('archive').all('migration').all('aws').customDELETE('').toPromise();

	getUrlScreenshot = (url) =>                              this.restangularThreatsLinks.all('scan').all('url').all('screenshot').post({url}).toPromise();
    scanLinks = (data) =>                                    this.restangularThreatsLinks.all('scan').all('links').post(data).toPromise();
    scanFiles = (data) =>                                    this.restangularThreatsFiles.all('scan').all('files').post(data).toPromise();

    getArchiveCases = () =>                                  this.restangular.all('admin').all('archive').all('cases').get('').toPromise();
    addArchiveCase = (data) =>                               this.restangular.all('admin').all('archive').all('cases').post(data).toPromise();
    updateArchiveCase = (caseId, data) =>                    this.restangular.all('admin').all('archive').all('cases').one(caseId).customPUT(data).toPromise();
    deleteArchiveCase = (caseId) =>                          this.restangular.all('admin').all('archive').all('cases').customDELETE(caseId).toPromise();
    getArchiveEmailTracking = (mailbox, params) =>           this.restangular.all('admin').all('archive').all('tracking').one(mailbox).customGET('', params).toPromise();
    changeRecipientStatus = (mailbox, emailId, recId, data) =>    this.restangular.all('admin').all('archive').all('recipient').all(mailbox).all(emailId).all(recId).customPUT(data).toPromise();
    getEmailThreats = (quarantineIds) =>                     this.restangular.all('admin').all('archive').all('threats').one(quarantineIds).get().toPromise();

	getPlanDomainProtectionAuditLogs = (params) =>            this.restangular.all('admin').all('domain').all('audit').customGET('', params).toPromise();

	getCampaignRecipients = () =>           				this.restangular.all('admin').all('threatSimulation').all('campaign').all('recipients').get('').toPromise();

	getThreatSimulationConfiguration = () =>            	this.restangular.all('admin').all('threatSimulation').all('configuration').get('').toPromise();
	updateThreatSimulationTemplateFields = (data) =>       this.restangular.all('admin').all('threatSimulation').all('templateFields').customPUT(data).toPromise();

	addThreatSimulationReviewer = (reviewer) =>            this.restangular.all('admin').all('threatSimulation').all('reviewers').one(reviewer).post().toPromise();
	deleteThreatSimulationReviewer = (reviewer) =>         this.restangular.all('admin').all('threatSimulation').all('reviewers').customDELETE(reviewer).toPromise();
	updateThreatSimulationReviewer = (reviewer, data) =>   this.restangular.all('admin').all('threatSimulation').all('reviewers').one(reviewer).customPUT(data).toPromise();

	getCampaignTrends = (params) =>            				this.restangularAttachments.all('admin').all('threatSimulation').all('trends').customGET('', params).toPromise();
	getThreatSimulationMaterials = () =>         			this.restangularAttachments.all('admin').all('threatSimulation').all('material').get('').toPromise();
	deleteThreatSimulationMaterials = (materialId) =>       this.restangularAttachments.all('admin').all('threatSimulation').all('material').customDELETE(materialId).toPromise();
	addThreatSimulationMaterialLink = (linkObj) =>       this.restangularAttachments.all('admin').all('threatSimulation').all('material').customPOST(linkObj).toPromise();
	updateThreatSimulationMaterial = (materialId, data) =>       this.restangularAttachments.all('admin').all('threatSimulation').all('material').one(materialId).customPUT(data).toPromise();

	getThreatSimulationCampaigns = () =>            		this.restangular.all('admin').all('threatSimulation').all('campaign').get('').toPromise();
	deleteThreatSimulationCampaign = (campaginId) =>  	  this.restangular.all('admin').all('threatSimulation').all('campaign').customDELETE(campaginId).toPromise();
	createThreatSimulationCampaign = (campaginInfo) =>    this.restangular.all('admin').all('threatSimulation').all('campaign').post(campaginInfo).toPromise();
	updateThreatSimulationCampaign = (campaignId, data) =>    this.restangular.all('admin').all('threatSimulation').all('campaign').one(campaignId).customPUT(data).toPromise();
	getCampaignData = (campaignId) =>            		this.restangular.all('admin').all('threatSimulation').all('campaign').all('info').one(campaignId).get('').toPromise();
	getCampaignSummary = (campaignId) =>            	this.restangular.all('admin').all('threatSimulation').all('campaign').all('summary').one(campaignId).get('').toPromise();
	getCampaignGroupSummary = (groupEmailIds) =>            	this.restangular.all('admin').all('threatSimulation').all('campaign').all('summary').all('group').customGET('', groupEmailIds).toPromise();

	testCampaignTemplate = (campaginInfo) =>    		this.restangular.all('admin').all('threatSimulation').all('campaign').all('test').post(campaginInfo).toPromise();



	getThreatSimulationTemplates = () =>            	this.restangular.all('admin').all('threatSimulation').all('templates').get('').toPromise();
	deleteThreatSimulationTemplate = (templateId) =>    this.restangular.all('admin').all('threatSimulation').all('templates').customDELETE(templateId).toPromise();
	addThreatSimulationTemplate = (data) =>    			this.restangular.all('admin').all('threatSimulation').all('templates').post(data).toPromise();
	updateThreatSimulationTemplate = (templateId, data) =>  this.restangular.all('admin').all('threatSimulation').all('templates').one(templateId).customPUT(data).toPromise();


	getThreatSimulationAuditLogs = (params) =>            	this.restangular.all('admin').all('threatSimulation').all('audit').customGET('', params).toPromise();


	getDmarcSettings = () =>                                 this.restangular.all('admin').all('dmarc').one('settings').get('').toPromise();
	getDmarcRecordsBySenderForDomain = (domain, senderName, params) =>   this.restangularAttachments.all('admin').all('dmarc').one(domain).one(senderName).customGET('', params).toPromise();
	getDmarcReport = (domain, params) =>                     this.restangularAttachments.all('admin').all('dmarc').one(domain).customGET('', params).toPromise();
	doDmarcAction = (domain, data) =>                        this.restangular.all('admin').all('dmarc').one(domain).customPOST(data).toPromise();

	//mtaRulesStatus = (params) =>                             this.restangular.all('admin').all('integrations').all('powershell').all('mtarules').customGET('', params).toPromise();
	exchangeSetMTARules = (data) =>                          this.restangularHerokuBE.all('admin').all('integrations').all('powershell').all('mtarules').post(data).toPromise();
	exchangeSwitchMTARules = (data) =>                       this.restangularHerokuBE.all('admin').all('integrations').all('powershell').all('mtarules').customPUT(data).toPromise();
	exchangeDisableProtection = (data) =>                    this.restangularHerokuBE.all('admin').all('integrations').all('powershell').all('protect').post(data).toPromise();
	exchangeGetConfiguration = (data) =>                     this.restangularHerokuBE.all('admin').all('integrations').all('powershell').all('status').post(data).toPromise();
	exchangeConfigurationStatus = (params) =>                this.restangular.all('admin').all('integrations').all('powershell').all('status').customGET('', params).toPromise();
	validateMicrosoftLicenses = () =>                    	 this.restangular.all('admin').all('integrations').all('powershell').all('license').get('').toPromise();
	exchangeDeviceCodeToken = (data) =>                    	 this.restangular.all('admin').all('integrations').all('powershell').all('login').post(data).toPromise();
	exchangeDeviceCodeAuthenticate = () =>                	 this.restangular.all('admin').all('integrations').all('powershell').all('login').get('').toPromise();
	exchangeDeviceDisconnect = () =>                	 	 this.restangular.all('admin').all('integrations').all('powershell').all('login').customDELETE().toPromise();
	exchangeVerifyLogin = () =>								 this.restangular.all('admin').all('integrations').all('powershell').all('login').all('verify').post().toPromise();
	powershellServiceAuthenticationConnect = () =>			 this.restangularHerokuBE.all('admin').all('integrations').all('powershell').all('login').all('service').post().toPromise();

	/* exchange server quarantined */
	exchangeGetQuarantinedEmails = (params) =>               this.restangularAttachments.all('admin').all('integrations').all('powershell').all('quarantined').customGET('', params).toPromise();
	exchangeDoActionOnQuarantinedEmail = (data) =>  		 this.restangular.all('admin').all('integrations').all('powershell').all('quarantined').post(data).toPromise();
	powershellQuarantineAction = (action, data) =>	     	this.restangular.all('admin').all('integrations').all('powershell').all('quarantined').one(action).customPOST(data).toPromise();


	getTableFilterTemplates = (tableNameRef) =>              this.restangular.all('user').all('tableFilters').one(tableNameRef).get('').toPromise();
	createTableFilterTemplate = (tableNameRef, data) =>      this.restangular.all('user').all('tableFilters').one(tableNameRef).customPOST(data).toPromise();
	deleteTableFilterTemplate = (tableNameRef, params) =>    this.restangular.all('user').all('tableFilters').one(tableNameRef).customDELETE('', params).toPromise();
	updateTableFilterTemplate = (tableNameRef, data) =>      this.restangular.all('user').all('tableFilters').one(tableNameRef).customPUT(data).toPromise();

}

export function RestangularConfigFactory(RestangularProvider) {
	RestangularProvider.setBaseUrl(ENV_CONSTS.beBaseUrl);
	RestangularProvider.setPlainByDefault(true);
	RestangularProvider.setDefaultHttpFields({timeout: 180000, cache: false});

	RestangularProvider.addResponseInterceptor((data, operation, what, url, response) => {
		const responseEvent = responseCodesMap[response.status];
		if (responseEvent) {
			const interceptorSubject = RestangularProvider.configuration.responseSubjects[responseEvent];
			interceptorSubject.next(response.body);
		}

		RestangularProvider.configuration.ongoingHttpRequestSubj.next(false);

		return response.body;
	});

	RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
		const responseEvent = responseCodesMap[response.status];
		if (responseEvent) {
			const interceptorSubject = RestangularProvider.configuration.responseSubjects[responseEvent];
			interceptorSubject.next(response);
		}

		RestangularProvider.configuration.ongoingHttpRequestSubj.next(false);

		// error not handled
		return response;
	});

	RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params)=> {
		RestangularProvider.configuration.ongoingHttpRequestSubj.next(true);

		return {
			params: params,
			headers: headers,
			element: element
		}
	});
}
