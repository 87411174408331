import _ from 'lodash';
import {DICTIONARY} from "../../../../../dictionary";
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import {GeneralService} from "../../../../../services/generalService";
import {Component, OnInit} from "@angular/core";
import util from "util";

@Component({
	selector: 'schedule-report-component',
	templateUrl: './schedule-report.component.html',
})
export class ScheduleReportComponent implements OnInit {
    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
    }

	_=_;
	actionInProcess: boolean = false;
    getFieldsInProgress;
    userReport;
    csvFields = [];
    csvFieldsCopy = [];
    selectedAll = false;
    fieldsChanged;
    dic = DICTIONARY;

	ngOnInit() {
        this.getFieldsInProgress = true;
        let userInfo;

        this.gs.getUserInfo(false, userInfoObj => {
            userInfo = userInfoObj;
        });

        this.rs.getAdminReportSettings().then((response) => {
            this.userReport = response.report;

            let fields = this.dic.CONSTANTS.csvFieldNames;

            this.csvFields = _.map(fields, field => {
                return {
                    name: field,
                    selected: this.userReport.selected_fields.includes(field)
                }
            });
            this.csvFieldsCopy = _.cloneDeep(this.csvFields);

            this.checkSelectAll();
            this.getFieldsInProgress = false;
        }, (err) => {
			this.getFieldsInProgress = false;
		});
    }

	addReportAddress(address) {
		if (!address) {
			return;
		}

		address = address.toLowerCase();
		if (this.userReport.report_to_emails.find(itm => itm === address)) {
			this.ns.showErrorMessage(util.format(this.dic.ERRORS.itemAlreadyExist, address));
			return;
		}

		const actionInfo = {
			action: this.dic.CONSTANTS.reportActions.emails,
			data: {
				email: address,
				type: this.dic.CONSTANTS.actions.add
			}
		}
		this.rs.updateOutboundReportSettings(actionInfo).then(() => {
			this.userReport.report_to_emails.push(address);
			this.userReport.address = '';
		});
	}

	deleteReportAddress(address) {
		if (!address) {
			return;
		}

		address = address.toLowerCase();
		if (!this.userReport.report_to_emails.find(itm => itm === address)) {
			return;
		}

		const actionInfo = {
			action: this.dic.CONSTANTS.reportActions.emails,
			data: {
				email: address,
				type: this.dic.CONSTANTS.actions.delete
			}
		}
		this.rs.updateOutboundReportSettings(actionInfo).then(() => {
			this.userReport.report_to_emails = this.userReport.report_to_emails.filter(item => item !== address);
		});
	}

    checkSelectAll() {
        this.selectedAll = _.filter(this.csvFieldsCopy, 'selected').length === this.csvFieldsCopy.length;
    };

    toggleAll() {
        this.selectedAll = !this.selectedAll;
        this.csvFieldsCopy.forEach(field => {
            field.selected = this.selectedAll;
        });
        this.checkIfScheduleFieldChanges();
    };

    applyScheduleReportChanges() {
        if (!this.fieldsChanged) {
            return;
        }
        this.setFieldsSchedule();
    }

    setFieldsSchedule() {
        const fields = _.map(_.filter(this.csvFieldsCopy, 'selected'), 'name');
        if (!fields.length) {
            this.ns.showErrorMessage(this.dic.ERRORS.atLeastOneReportFields);
            return;
        }

        this.rs.updateOutboundReportSettings({action: this.dic.CONSTANTS.reportActions.fields, data: fields}).then(() => {
            this.csvFields = _.cloneDeep(this.csvFieldsCopy);
            this.checkIfScheduleFieldChanges();
        });
    };

    checkIfScheduleFieldChanges() {
        this.fieldsChanged = JSON.stringify(this.csvFieldsCopy) !== JSON.stringify(this.csvFields);
    }

    updateReportSettings() {
        const data = {
            action: this.dic.CONSTANTS.reportActions.settings,
            data: this.userReport
        }
        this.rs.updateOutboundReportSettings(data).then(() => {});
    };
}
