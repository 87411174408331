import * as util from 'util';
import _ from 'lodash';
import {GeneralService} from '../../../../../services/generalService';
import {RouteService} from '../../../../../services/routeService';
import {NotificationService} from '../../../../../services/notificationService';
import {DICTIONARY} from '../../../../../dictionary';
import {Component, OnInit} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {eachLimit} from 'async';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
	selector: 'inbound-email-relay-integration-component',
	templateUrl: './inbound-email-relay-integration.component.html',
})
export class InboundEmailRelayIntegrationComponent implements OnInit {
    dic = DICTIONARY;
    corpname = this.gs.corpname;
    emailRelayHeader = 'x-trustifi-creds';
    userInfo: any;
    planInfo: any;
    verifiedDomains: any[];
    genNewKey: any;
    updateEmailRelayConfigInProcess: boolean;
    inboundRelayDomain: boolean;
    mtaToMove: any;
    mtaInEdit: any;
	_=_;
	showEmailRelayPassword;
	showEmailRelayUsername;
	showEmailRelayKey;
    regenerateInProcessER;
	showJournalAddress: Boolean;
	selectedArchitecture;
	sendingArchitecturesData = {
		[this.dic.CONSTANTS.inboundSendingArchitecture.connector]: {
			name: 'Email Relay',
			description: 'Inbound mail flow will be configured using mail flow connectors and rules. Suitable for cloud-based or hybrid email architectures.',
			icon: 'fa fa-project-diagram',
		},
		[this.dic.CONSTANTS.inboundSendingArchitecture.mx]: {
			name: 'MX Record',
			description: 'Inbound mail flow will be configured by changing your MX record to route incoming email to Trustifi. Suitable for on-prem email architectures.',
			icon: 'fa fa-at',
		},
		[this.dic.CONSTANTS.inboundSendingArchitecture.graph]: {
			name: 'API',
			description: 'Incoming emails will be scanned post-delivery using the Microsoft Graph API. Suitable only for Office365 email architectures.',
			icon: 'fa fa-code-branch',
		},
		[this.dic.CONSTANTS.inboundSendingArchitecture.journal]: {
			name: 'Journal',
			description: 'Inbound mail flow will be configured using a journal rule. Trustifi will scan journaled copies of your received emails for threats, and actions can be taken on emails using an API.',
			icon: 'fa fa-exchange-alt',
		}
	};

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				public clipboard: ClipboardService) {
    }

	ngOnInit() {
        this.gs.getUserInfo(false, userInfo => {
            this.userInfo = userInfo;
        });

        this.rs.getInboundPlanSettings().then((planInfo) => {
            this.planInfo = planInfo;

            if (!this.planInfo.inbound_relay) {
                this.planInfo.inbound_relay = {port: 25};
            }
            if (!this.planInfo.inbound_relay.addresses_only) {
                this.planInfo.inbound_relay.addresses_only = [];
            }
            if (this.planInfo.inbound_relay.key) {
                this.planInfo.inbound_relay.keyAndSecret = this.planInfo.inbound_relay.key + ':' + this.planInfo.inbound_relay.secret;
            }

            this.verifiedDomains = [];
            if (this.planInfo.exchange_server.verified_domains && this.planInfo.exchange_server.verified_domains.length) {
                this.verifiedDomains = this.planInfo.exchange_server.verified_domains;
            }
            else if (this.planInfo.gsuite_server.verified_domains && this.planInfo.gsuite_server.verified_domains.length) {
                this.verifiedDomains = this.planInfo.gsuite_server.verified_domains;
            }

			this.selectArchitecture(this.planInfo.inbound_relay.sending_architecture);

        }, (err) => {

		});
    }

	onDropMta(event: CdkDragDrop<any[]>) {
		if (!this.mtaToMove) return;

		const prevIndex = this.planInfo.inbound_relay.mtas.findIndex(m => m === this.mtaToMove);
		const currIndex = event.currentIndex;

		// Update local array for UI
		moveItemInArray(this.planInfo.inbound_relay.mtas, prevIndex, currIndex);

		this.updateEmailRelayConfig(DICTIONARY.CONSTANTS.erSettingsActions.mta, 'move', currIndex);
	}

	onDragStartMta(mta: any) {
		this.mtaToMove = mta;
	}

    copyMxRecord() {
        this.clipboard.copy(this.planInfo.mx);
    }

    activateInboundEmailRelay(genNewKey = null) {
        this.genNewKey = genNewKey;
        let title = "", button = "", subTitle = "", body = [], type = DICTIONARY.CONSTANTS.popupWarning;

		if (genNewKey) {
			button = "Regenerate";
			title = "Regenerate Inbound Shield key";
			subTitle = "You are about to change your Inbound Shield key!";
			body = ["You will need to update the new key in your Exchange server to continue being protected by Inbound Shield"];
			this.regenerateInProcessER = true;
		}
		else {
			if (this.planInfo.inbound_relay.enabled) {
				button = "Enable";
				title = "Enable Inbound Email Relay";
				subTitle = "You are about to enable Inbound Email Relay";
				body = ["Incoming emails to your mail server will be scanned to protect against phishing, spoofing attacks, malware, spam and more.",
					"After enabling the Inbound Email Relay, you need to create and enable the relevant connector and mail-flow rule in your Email Server."];
				type = DICTIONARY.CONSTANTS.popupInfo;
			}
			else {
				button = "Disable";
				title = "Disable Inbound Email Relay";
				subTitle = "You are about to disable Inbound Email Relay!";
				body = ["Disabling Inbound Email Relay will remove protection for all mailboxes under your mail server. Please note that this will potentially leave these mailboxes vulnerable to phishing, spoofing attacks, malware, spam and more.",
					"Before disabling the Inbound Email Relay, make sure you have disabled or removed the relevant mail-flow rule in Email Server."];
			}
		}

		setTimeout(() => {
			this.gs.showPopup({
				title: title,
				subTitle: subTitle,
				body: body,
				type: type,
				doneBtnText: button,
				doneCb: () => {
					const actionInfo = {
						action: DICTIONARY.CONSTANTS.inboundRelayActions.key,
						enabled: this.planInfo.inbound_relay.enabled,
						regenerate: this.genNewKey
					};
					this.rs.activateInboundEmailRelayAndGenKey(actionInfo).then((response) => {
						this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);

						this.ngOnInit();
						this.regenerateInProcessER = false;
					}, (err) => {
						console.log(err);
						this.planInfo.inbound_relay.enabled = !this.planInfo.inbound_relay.enabled;
					});
				},
				cancelCb: () => {
					if (!this.genNewKey) {
						this.planInfo.inbound_relay.enabled = !this.planInfo.inbound_relay.enabled;
					}
				}
			});
		});
    };

	copyEmailRelayHostToClipboard() {
		this.clipboard.copy('inbound-smtp.trustifi.com');
		this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay host"));
	};

	copyEmailRelayPortToClipboard() {
		this.clipboard.copy('25');
		this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay port"));
	};

    copyEmailRelayUsernameToClipboard() {
        this.clipboard.copy(this.planInfo.inbound_relay.key);
        this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay username"));
    };

    copyEmailRelayPasswordToClipboard() {
        this.clipboard.copy(this.planInfo.inbound_relay.secret);
        this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay password"));
    };

    copyEmailRelayKeyToClipboard() {
        this.clipboard.copy(this.planInfo.inbound_relay.key+':'+this.planInfo.inbound_relay.secret);
        this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay key"));
    };

    copyEmailRelayHeaderToClipboard() {
        this.clipboard.copy(this.emailRelayHeader);
        this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, "Email relay header"));
    };

	copyItem(item, name) {
		this.clipboard.copy(item);
		this.ns.showInfoMessage(util.format(DICTIONARY.MESSAGES.copyClipboard, name));
	}

	removeAddress = (index = 0) => {
		if (this.planInfo.inbound_relay.addresses_only[index]){
			const updateData = {
				action: DICTIONARY.CONSTANTS.inboundRelayActions.address,
				type: this.dic.CONSTANTS.actions.delete,
				address: this.planInfo.inbound_relay.addresses_only[index]
			}
			this.rs.activateInboundEmailRelayAndGenKey(updateData).then(() => {
				this.planInfo.inbound_relay.addresses_only.splice(index, 1);
				this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
			} , err => {
				this.updateEmailRelayConfigInProcess = false;
			});
		}
	}

	addAddress = (address, cb) => {
		if (!this.gs.validateEmail(address)) {
			this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.invalidEmail, address));
			this.updateEmailRelayConfigInProcess = false;
			cb && cb();
			return;
		}
		if (this.planInfo.inbound_relay.addresses_only.length && this.planInfo.inbound_relay.addresses_only.includes(address.toLowerCase())) {
			this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.emailAlreadyExist, address));
			this.updateEmailRelayConfigInProcess = false;
			cb && cb();
			return;
		}

		const updateData = {
			action: DICTIONARY.CONSTANTS.inboundRelayActions.address,
			type: this.dic.CONSTANTS.actions.add,
			address: address
		};

		this.rs.activateInboundEmailRelayAndGenKey(updateData).then((response) => {
			this.planInfo.inbound_relay.addresses_only = response.addresses_only;
			this.planInfo.inbound_relay.address = '';
			this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
			this.updateEmailRelayConfigInProcess = false;
			cb && cb();
		}, err => {
			this.updateEmailRelayConfigInProcess = false;
			cb && cb(err);
		});
	}

	addAddressFromFile = () => {
		let body: any[] = [
			'File type must be a Comma Separated Value (.csv)',
			'Email addresses should appear in the Emails column and only there',
		];
		let popupType = this.dic.CONSTANTS.popupInfo;
		this.gs.showPopup({
			title: 'Import List',
			subTitle: 'To ensure ' + this.corpname + ' can successfully handle your list, it should meet the following conditions:',
			body: body,
			type: popupType,
			doneBtnText: 'OK',
			isFile: true,
			doneCb: (files) => {
				if (!files || !files.length) return;

				if (files[0].name && !files[0].name.endsWith(".csv") && !files[0].name.endsWith(".CSV")) {
					this.ns.showErrorMessage("Expected .csv file");
					return;
				}

				const file = files[0];
				const headersToSearch = ['emails'];
				const optionalHeaders = [];
				const validationFunctions = [this.gs.validateEmail];

				this.gs.readCsv(file, headersToSearch, optionalHeaders, this.dic.CONSTANTS.EMAIL_REGEX, validationFunctions, (err, res) => {
					if (err) {
						console.error(err);
						return;
					}

					if (res.emails.length) {
						eachLimit(res.emails, 1, (address, callback) => {
							this.addAddress(address, callback);
						}, () => {});
					}
				});
			}
		});
	}

    updateEmailRelayConfig = (action, type = null, index=0) => {
        this.updateEmailRelayConfigInProcess = true;

        let updateData:any = {action: action};
        switch (action) {
            case DICTIONARY.CONSTANTS.inboundRelayActions.sendingArchitecture:
                updateData.sendingArchitecture = type;
                break;

            case DICTIONARY.CONSTANTS.inboundRelayActions.mta:
                if (type === 'add') {
                    if (!this.gs.isValidDomain(this.planInfo.inbound_relay.domain)) {
                        this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.invalidDomain, this.planInfo.inbound_relay.domain));
                        this.inboundRelayDomain = true;
                        this.updateEmailRelayConfigInProcess = false;
                        return;
                    }

					if (!(this.planInfo.inbound_relay.newPort > 0 && this.planInfo.inbound_relay.newPort < 65535)) {
						this.ns.showErrorMessage("Port value should be between 0 and 65535");
						this.updateEmailRelayConfigInProcess = false;
						return;
					}
                    if (this.planInfo.inbound_relay.mtas.length) {
                        for (let i = 0; i < this.planInfo.inbound_relay.mtas.length; i++) {
                            if (this.planInfo.inbound_relay.mtas[i].host === this.planInfo.inbound_relay.host &&
                                this.planInfo.inbound_relay.mtas[i].domain === this.planInfo.inbound_relay.domain &&
                                this.planInfo.inbound_relay.mtas[i].port === this.planInfo.inbound_relay.newPort) {
                                this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.mtaAlreadyExist, this.planInfo.inbound_relay.host));
                                this.updateEmailRelayConfigInProcess = false;
                                return;
                            }
                        }
                        const recordsCountForDomain = _.sumBy<any>(this.planInfo.inbound_relay.mtas, m => m.domain === this.planInfo.inbound_relay.domain ? 1:0);
                        if (recordsCountForDomain >= 3) {
                            this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.CannotAddMta, this.planInfo.inbound_relay.domain));
                            this.updateEmailRelayConfigInProcess = false;
                            return;
                        }
                    }
                    updateData.host = this.planInfo.inbound_relay.host;
                    updateData.port = this.planInfo.inbound_relay.newPort;
                    updateData.domain = this.planInfo.inbound_relay.domain;
                }
                else if (type === 'move') {
                    updateData._id = this.mtaToMove._id;
                    updateData.idx = index;
                }
                else if (type === 'edit') {
                    updateData._id = this.mtaInEdit._id;
                    updateData.host = this.mtaInEdit.host;
					if (!(this.mtaInEdit.port > 0 && this.mtaInEdit.port < 65535)) {
						this.ns.showErrorMessage("Port value should be between 0 and 65535");
						this.updateEmailRelayConfigInProcess = false;
						return;
					}
                    updateData.port = this.mtaInEdit.port;
                    updateData.domain = this.mtaInEdit.domain;
                }
                else {
                    if (this.planInfo.inbound_relay.enabled && this.planInfo.inbound_relay.mtas.length === 1) {
                        this.updateEmailRelayConfigInProcess = false;
                        return this.ns.showWarnMessage(DICTIONARY.ERRORS.CannotRemoveMta);
                    }
                    updateData._id = this.planInfo.inbound_relay.mtas[index]._id;
                    updateData.domain = this.planInfo.inbound_relay.mtas[index].domain;
                }
                updateData.type = type;
                break;

			case DICTIONARY.CONSTANTS.inboundRelayActions.addressSkipMode:
				updateData.enabled = this.planInfo.inbound_relay.addresses_only_skip_mode;
				break;

			case DICTIONARY.CONSTANTS.inboundRelayActions.domainMta:
                if (!this.gs.isValidDomain(this.planInfo.inbound_relay.domain)) {
                    this.ns.showWarnMessage(util.format(DICTIONARY.ERRORS.invalidDomain, this.planInfo.inbound_relay.domain));
                    this.inboundRelayDomain = true;
                    this.updateEmailRelayConfigInProcess = false;
                    return;
                }
                updateData.domain = this.planInfo.inbound_relay.domain;
                break;
        }

        this.rs.activateInboundEmailRelayAndGenKey(updateData).then((response) => {
            switch (action) {
                case DICTIONARY.CONSTANTS.inboundRelayActions.sendingArchitecture:
                    this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
                    this.ngOnInit();
                    break;

                case DICTIONARY.CONSTANTS.inboundRelayActions.mta:
                    for (let i = 0; i < response.mtas.length; i++) {
                        if (this.planInfo.inbound_relay.mtas[i] && this.planInfo.inbound_relay.mtas[i].editMode) {
                            response.mtas[i].editMode = true;
                            this.mtaInEdit = response.mtas[i];
                        }
                    }
                    this.planInfo.inbound_relay.mtas = response.mtas;
                    if (type === 'add') {
                        this.planInfo.inbound_relay.host = '';
                        this.planInfo.inbound_relay.newPort = '';
                        this.planInfo.inbound_relay.domain = '';
                    }
                    else if (type === 'move') {
                        this.mtaToMove = null;
                    }
                    else if (type === 'edit') {
                        this.mtaInEdit.editMode = false;
                        this.mtaInEdit = null;
                    }
                    this.ns.showInfoMessage(DICTIONARY.MESSAGES.changedSuccessfully);
                    break;

                case DICTIONARY.CONSTANTS.inboundRelayActions.domainMta:
                    this.planInfo.inbound_relay.host = response.exchange;
                    break;
            }

            this.updateEmailRelayConfigInProcess = false;
        }, err => {
            this.updateEmailRelayConfigInProcess = false;
        });
    };

	selectArchitecture = (architectureName) => {
		this.selectedArchitecture = this.sendingArchitecturesData[architectureName];
		this.selectedArchitecture.key = architectureName;
	}

    updateSendingArchitecture = (architectureName) => {
        if (this.planInfo.inbound_relay.sending_architecture === architectureName) {
            return;
        }

        let bodyDetails = [];
		switch (architectureName) {
			case this.dic.CONSTANTS.inboundSendingArchitecture.connector:
				bodyDetails.push(`To use this architecture you will need to create mail flow rules and connectors for Trustifi in your email server.`,
					`Follow our integration guides based on your environment:<br/>
                    <a class="blue-link" href="https://docs.trustifi.com/docs/inbound-relay-integration-office365" target="_blank">Exchange integration</a><br/>
                    <a class="blue-link" href="https://docs.trustifi.com/docs/inbound-relay-integration-google-workspace" target="_blank">Google integration</a>`);
				break;

			case this.dic.CONSTANTS.inboundSendingArchitecture.mx:
				bodyDetails.push(`To use this architecture you will need to change your MX record.`,
					'All integrated domains must also be verified with Trustifi. You can start this process in the "Domains" page.');
				break;

			case this.dic.CONSTANTS.inboundSendingArchitecture.journal:
				bodyDetails.push(`Configure the Journal Rule settings in Exchange/Google to scan your journaled emails in Trustifi.`,
					'With the journal architecture, functions related to modification of the email body are not available. These functions include applying footers/headers to emails, Onclick scan, and Smart Banners.',
					'To also perform actions on emails (e.g. quarantine) you will also need to connect to the relevant API integration.');
				break;

			case this.dic.CONSTANTS.inboundSendingArchitecture.graph:
				bodyDetails.push('To scan emails for threats and perform actions on them (e.g. quarantine) you will need to connect to the Exchange API integration',
					'After connecting to the Exchange API, you will also need to enable protection on your mailboxes from the Mailbox Management page');
				break;
		}

        this.gs.showPopup({
            title: 'Change Inbound Mail Flow Architecture',
            subTitle: `Please note - you are about to change your mail flow architecture`,
            body: bodyDetails,
            type: DICTIONARY.CONSTANTS.popupWarning,
            doneBtnText: 'Confirm',
            doneCb: () => {
                this.updateEmailRelayConfig(DICTIONARY.CONSTANTS.inboundRelayActions.sendingArchitecture, architectureName);
            }
        });

    }

    setMTAForEdit = (mta) => {
        this.planInfo.inbound_relay.mtas = _.map(this.planInfo.inbound_relay.mtas, mta => {
            mta.editMode = false;
            return mta;
        });
        mta.editMode = true;
        this.mtaInEdit = mta;
    };

    verifyMta = (mtaObj) => {
        if (this.updateEmailRelayConfigInProcess) {
            return;
        }

        this.updateEmailRelayConfigInProcess = true;
        const actionInfo = {
            action: DICTIONARY.CONSTANTS.inboundRelayActions.mta,
            type: 'verify',
            host: mtaObj.host,
            port: mtaObj.port,
        }

        this.rs.activateInboundEmailRelayAndGenKey(actionInfo).then((response) => {
			mtaObj['sts_unrestricted'] = undefined;
			mtaObj['verified'] = response.meta && response.meta.type && response.meta.type === 'info';

			this.updateEmailRelayConfigInProcess = false;
		}, (err) => {
			mtaObj['sts_unrestricted'] = undefined;
            mtaObj['verified'] = false;
			this.updateEmailRelayConfigInProcess = false;
		});
    };

	mtaStsLookup = (mtaObj) => {
		if (this.updateEmailRelayConfigInProcess) {
			return;
		}

		this.updateEmailRelayConfigInProcess = true;
		const actionInfo = {
			action: DICTIONARY.CONSTANTS.inboundRelayActions.mta,
			type: 'mta_sts',
			domain: mtaObj.domain
		}

		this.rs.activateInboundEmailRelayAndGenKey(actionInfo).then((response) => {
			mtaObj['verified'] = undefined;
			mtaObj['sts_unrestricted'] = true;
			this.updateEmailRelayConfigInProcess = false;
		}, (err) => {
			mtaObj['verified'] = undefined;
			mtaObj['sts_unrestricted'] = false;
			this.updateEmailRelayConfigInProcess = false;
		});
	};
}


