<div class="flex-column gap-4 pt-4 animated fadeIn">
    <div class="flex-column gap-3" style="height: 265px;">
        <div class="flex-row gap-5 align-items-center" style="height: 125px; z-index: 1;">
            <!--Img and information-->
            <div class="border d-flex centralize overflow-hidden" style="border-radius: 50%; width: 110px; height: 110px;">
                <img [src]="userInfo.picture" class="w-100" alt="profile picture">
            </div>

            <!--Information-->
            <div class="flex-column gap-4 animated fadeIn" *ngIf="!editProfile">
                <div class="flex-row gap-3 align-items-center" style="height: 30px;">
                    <div class="flex-row gap-1">
                        <b style="font-size: 1.2rem;">{{userInfo.name}}</b>
                    </div>
                    <!--Edit Icon-->
                    <i class="fas fa-edit link-primary" style="cursor: pointer;" tooltip="Edit Details" (click)="editProfileDetails();"></i>
                </div>
                <div class="flex-column gap-3">
                    <div class="flex-row gap-3 align-items-center" style="height: 30px;">
                        <span style="width: 50px;">Email:</span>
                        <span>{{userInfo.email}}</span>
                    </div>
                    <div class="flex-row gap-3" style="height: 30px;">
                        <span style="width: 50px;">Phone:</span>
                        <span *ngIf="userInfo.from[0].phone && userInfo.from[0].phone.phone_number">
                            +{{ userInfo.from[0].phone.country_code }}-{{userInfo.from[0].phone.phone_number}}
                        </span>
                        <span *ngIf="(!userInfo.from[0].phone || !userInfo.from[0].phone.phone_number)">
                            -
                        </span>
                    </div>
                </div>
            </div>
            <!--information while editing-->
            <div class="flex-column gap-4 animated fadeIn" *ngIf="editProfile">
                <div class="flex-row gap-1" style="height: 30px;">
                    <!--First name-->
                    <input class="animated fadeIn"
                           placeholder="First Name"
                           *ngIf="editProfile"
                           [(ngModel)]="editProfile.firstName" maxlength="100"
						   (keyup.enter)="confirmEditAccountDetails(true);"
                           type="text" style="height: 30px;">
                    <!--Last name-->
                    <input class="animated fadeIn"
                           *ngIf="editProfile"
                           placeholder="Last Name"
                           [(ngModel)]="editProfile.lastName" maxlength="100"
						   (keyup.enter)="confirmEditAccountDetails(true);"
                           type="text" style="height: 30px;">
                </div>
                <div class="flex-column gap-3">
                    <div class="flex-row gap-3 align-items-center">
                        <span style="width: 50px;">Email:</span>
                        <span>{{userInfo.email}}</span>
                    </div>
                    <div class="flex-row gap-3 align-items-center">
                        <span style="width: 50px;">Phone:</span>
                        <span class="flxRow override-li-height hSpace05 h100 animated fadeIn" style="overflow: visible; flex: 1 0;" *ngIf="editProfile">
							<country-code-select [(model)]="editProfile.phone.country_code" ></country-code-select>
							<input id="accountEditPhone"
								   type="text" placeholder="Phone number"
								   style="flex: 1 0; margin-right: 0; margin-bottom: 0; padding-right: 5px;"
								   pattern="^[0-9]*$"
								   maxlength="11"
								   (keyup.enter)="confirmEditAccountDetails(true)"
								   [(ngModel)]="editProfile.phone.phone_number">
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!--Cancel/apply edit-->
        <div class="flex-row gap-2 animated fadeIn" *ngIf="editProfile" style="margin-left: 300px;">
            <btn-c variant="text"
                 (action)="!updateAccountDetailsInProcess && confirmEditAccountDetails(false);">
                Cancel
            </btn-c>
            <btn-c [isDisabled]="editProfile.firstName === userInfo.firstName &&
                              editProfile.lastName === userInfo.lastName &&
                              editProfile.phone === userInfo.from[0].phone"
                 [loading]="updateAccountDetailsInProcess"
                 (action)="confirmEditAccountDetails(true);">
                Apply
            </btn-c>
        </div>
    </div>


	<div class="list-group list-group-flush settings-list-container">
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2">
				<b>Select Time Zone for Reports</b>
				<span>
					This time zone will be used to show dates and times for reports sent by the system, such as quarantined email reports
				</span>
			</div>
			<div class="content-container flex-column gap-2">
				<app-dropdown-c style="width: 250px;"
								text="{{selectedTimeZone?.label || 'Select'}}"
								[enableSearch]="true"
								[alwaysDrop]="true"
								itemDisplayKey="label"
								[items]="timeZones"
								(onItemClicked)="updateUserTimezone($event.item)"></app-dropdown-c>
			</div>
		</div>
	</div>

    <!--Delete account section-->
    <div class="flex-row align-items-center gap-5 border rounded-4 p-3" style="width: max-content;" *ngIf="userInfo.type !== dic.CONSTANTS.userTypes.heroku">
        <b>Delete Account</b>
        <span class="text-danger">WARNING: This action can't be undone! Your account will be deleted from {{corpName}}'s system.</span>
        <btn-c id="deleteAccountBtn" color="danger"
             (action)="openDeleteAccountPopup();">
            Delete Account
        </btn-c>
    </div>
</div>

<popup-c class="bs-enabled" *ngIf="deleteAccountPopup?.show"
		 head="Delete Account" size="dynamic"
		 (closeCb)="deleteAccountPopup.show = false;"
		 (confirmCb)="deleteAccountPopup?.doneCb()"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Delete my account', disabled: deleteAccountPopup.applyInProcess || deleteAccountPopup.verifyEmail !== userInfo.email}}">
	<div class="flex-column f-1-0 gap-2" style="overflow: auto;">
		<div class="d-flex gap-1 text-danger pt-2" style="font-size: 14px;">
			<i class="zmdi zmdi-alert-circle-o" style="font-size: 1.5rem; margin-top: -6px;"></i>
			Please note - your account data will be lost forever.
		</div>

		<ul>
			<li *ngFor="let text of ['All sent emails will be blocked.','All the data will be deleted.']; trackBy: gs.trackByIndex">{{text}}</li>
		</ul>

		<div class="align-items-center flex-row gap-3">
			<div>Email:</div>
			<input type="text" placeholder="Please enter your email address for further verification"
				   style="width:330px;" [required]="true"
				   [(ngModel)]="deleteAccountPopup.verifyEmail"
				   [ngStyle]="deleteAccountPopup.verifyEmail && deleteAccountPopup.verifyEmail !== userInfo.email ? {'border-color':'red'} : {}"/>
		</div>
	</div>

</popup-c>
