<div class="switch-toggle static-color hSpace2 flxRow" style="align-items: center;" [ngClass]="{'disabled': isDisabled}">
    <input type="checkbox" id="{{id || random}}"
           [(ngModel)]="model"
           (change)="fireClick()"
           [disabled]="isDisabled"/>
    <label for="{{id || random}}"></label>
    <span *ngIf="!hideText" [ngClass]="{'text-success' : model , 'text-muted' : !model}">
        {{model ? (textTrue || 'Enabled') : (textFalse || 'Disabled')}}
    </span>
</div>
