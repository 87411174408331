import {Component} from "@angular/core";
import {TabsPageContainer} from "../../../uiComponents/tabsPageContainer";

@Component({
	selector: 'inbound-configuration-tabs',
	templateUrl: './inbound-configuration-tabs.component.html',
})
export class InboundConfigurationTabsComponent extends TabsPageContainer {
	tabWidth = 170;
}
