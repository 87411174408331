<div class="flxClmn">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Registration</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Register a qualified sales opportunity into the Trustifi Partner Management System</span>
        </div>
    </div>
    <div class="page-inner-content-holder flxClmn animated fadeIn" >
        <div class="flxRow" style=" flex: 1 0;">
            <!--Form (left side)-->
            <div class="flxClmn" style="flex: 0 1; align-items: center; min-width: 40vw;">
                <div class="flxClmn" style="overflow: hidden; max-width: 625px; max-height: 625px; align-items: center; min-width: 500px; padding: 25px; box-shadow: 0 0 5px darkgrey; border-radius: 10px;">
                    <h3 class="color-primary">Register Form</h3>
                    <div style="height: 41vh; width: 100%; overflow-y: auto; overflow-x: hidden;">
                        <form name="accountDetails" class="flxRow"
                              style="align-items: center; font-size: 1rem; transition: transform 0.2s ease 0s; width: 300%; transform: translateX(0%);"
                              [ngStyle]="{'width' : 100 * registrationInfo.length + '%' , 'transform' : 'translateX(' + -100 / registrationInfo.length * chosenRegistrationIndex + '%)'}">
                            <div class="flxClmn-start" style="padding: 0 15px; flex: 1 0;"
                                 *ngFor="let section of registrationInfo; trackBy: gs.trackByIndex">
                                <div class="input-wrap" style="display: flex; align-items: center; margin: 1.1vh 0;"
                                     *ngFor="let input of section.inputs; trackBy: gs.trackByIndex">
                                    <span class="hvr-darken" style="cursor: default; flex-basis: 135px; color: dimgrey;">
                                        {{input.text}}
                                        <span *ngIf="section.required" class="required-asterisk">*</span>
                                    </span>
                                    <input type="{{input.type ? input.type : 'text'}}"
                                           [required]="section.required"
                                           [ngClass]="{'validation-error' : registrationInfo.showUndoneFields && !input.model && section.required}"
                                           class="f-1-0" style=" margin: 0;"
										   [name]="input.name"
                                           [(ngModel)]="input.model">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="flxClmn" style="flex-basis: 40%; align-items: center; position: relative;">
                        <div class="flxRow-evenly w100 hSpace3" style=" margin: 2vw 0; max-width: 520px;">
                        <span style="cursor: pointer; text-align: center; font-size: 1rem; flex: 1 0; color: #6598ff; font-weight: 500;"
                              *ngFor="let section of registrationInfo; let i = index; trackBy: gs.trackByIndex"
                              (click)="chosenRegistrationIndex = i"
                              [ngClass]="chosenRegistrationIndex !== i && 'hvr-darken'"
                              [ngStyle]="{'color' : chosenRegistrationIndex === i ? 'var(--bs-hover-color)' : 'darkgrey'}">
                            <span style="font-size: 2rem;">•</span>
                            <br>
                            {{section.title}}
                        </span>
                        </div>
                        <div class="flxRow hSpace2" style="align-items: center;">
                            <btn-c *ngIf="chosenRegistrationIndex > 0"
								   startIcon="fas fa-chevron-left"
								   (action)="chosenRegistrationIndex = chosenRegistrationIndex - 1">
                                Previous
                            </btn-c>
                            <btn-c *ngIf="chosenRegistrationIndex < registrationInfo.length - 1"
								   endIcon="fas fa-chevron-right"
								   (action)="chosenRegistrationIndex = chosenRegistrationIndex + 1">
                                Next
                            </btn-c>
                            <btn-c *ngIf="chosenRegistrationIndex === registrationInfo.length - 1"
								   color="success"
								   (action)="sendRegistration();">
                                Send
                            </btn-c>
                        </div>
                        <span style="align-self: flex-start; position: absolute; bottom: 0;"
                              *ngIf="!registrationInfoError" >* required</span>
                        <span style="align-self: flex-start; color: red; position: absolute; bottom: 0;"
                              *ngIf="registrationInfoError" >{{registrationInfoError}}</span>
                    </div>
                </div>
            </div>

            <!--Image (right side)-->
            <div class="flxClmn-center" style=" flex: 1 0; align-items: center; padding: 3vw;">
                <img src="https://virtualoffice.co.ke/wp-content/uploads/2021/01/tech3.jpeg" style=" width: 35vw; opacity: 0.8; margin-top: -10%; background-size: contain; box-shadow: inset 0 0 80px white;">
                <h3 class="color-primary">Ready to register a deal?</h3>
                <span style="font-size: 0.95rem;" class="color-primary">Great! Please complete the form</span>
                <span style="margin-top: 5%; text-align: justify; font-size: 0.6rem; opacity: 0.7;">DISCLAIMER: Deal Registration: Reseller submits a new, qualified opportunity via the Partner Portal, and Trustifi approves and accepts it as a Deal Registration. Deal Registration entitles the Reseller to the Trustifi Partner Deal Registration Discount, the highest discount available to a Trustifi Reseller.
        Deal Registration Partner: Deal Registration rewards Resellers for identifying new opportunities and for the value that the Reseller delivers to close the opportunity. Resellers with an approved Deal Registration are expected to manage the sales process including but not limited to: meeting with the Customer’s decision makers, working with the Customer to define the Customer’s requirements, performing demos and/or proof of concepts, providing pre-sales technical support and managing all quoting to the Customer.
        Qualified Opportunity: A Reseller may only submit a “Qualified Opportunity” for a Deal Registration – i.e., an opportunity for which the Reseller can demonstrate it has worked with the Customer to scope and qualify as genuine and likely to mature to closure within the Deal Registration Period (as defined below). An opportunity remains a “Qualified Opportunity” from the start of the sales cycle for so long as the Reseller is “actively engaged” on the opportunity, as demonstrated by the following: • Reseller has identified the business qualifications – Customer’s project name and scope, budget, Use Cases and Trustifi product interest, and full Customer details • Reseller has documented a realistic plan with the Customer to close the opportunity within the Deal Registration Period • Reseller has documented the Customer’s Use Case(s) and technical requirements  • Reseller has prepared and delivered Sales Presentations to key Customer stakeholders • Reseller has completed Proof of Concept and/or Demonstrations • Reseller has established a mutual activity plan with Customer champion • Reseller has discussed and agreed all commercial terms with the Customer • Reseller has established the Customer’s decision process and timing for close with the Customer Deal Registration Approval
        Trustifi considers each application for a Deal Registration against the following criteria to approve or deny a Deal Registration: • Reseller is the first partner to attempt a Deal Registration for the opportunity • The Deal Registration submission is complete (no missing information) • It is a Qualified Opportunity (see above section) and the Reseller has been actively engaged in significant qualifying activities. • There is not an existing Deal Registration nor has Trustifi assigned the same opportunity to another Reseller.
        Deal Registration Review: The Trustifi Deal Registration Desk reviews all Deal Registration applications. Trustifi will notify the Reseller applicant within two business days: • Approve: If Trustifi approves a Deal Registration, it is valid for 90 days (the “Deal Registration Period”). • Investigate: Occasionally, Trustifi may need more than two business days to review an application for a Deal Registration. Investigate status may add up to three business days to Trustifi’s response time. • Reject: If Trustifi rejects an attempted Deal Registration, Trustifi will provide the reason for the rejection as part of its notice.
        How to Submit a Deal Registration: The Reseller must log into the Partner Portal and submit a Deal Registration application itself and only for a Qualified Opportunity. The Reseller must complete all required fields on the Deal Registration form; Trustifi will reject any application that is missing any of the requested information or that involves anything other than a Qualified Opportunity.
        Deal Registration Period: If at the end of the Deal Registration Period the Reseller needs additional time to close the opportunity, the Reseller may request an extension. Trustifi may grant an extension requested by the Reseller via the Partner Portal (in 30 day increments) in Trustifi’s sole discretion. There are no limitations on the number of extensions.</span>
            </div>
        </div>
    </div>
</div>
