<popup-c *ngIf="archiveService.showSharingConfiguration"
	   head="Manage Sharing"
	   subTitle="Archive case: {{archiveService.currentCaseObj.name}}"
	   (closeCb)="addReviewerInProcess = false; archiveService.showSharingConfiguration = false;"
	   (confirmCb)="showSharingManagementExecute();"
	   [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}" size="large">
	<div class="flxClmn f-1-0 vSpace2 animated fadeIn" style="overflow: auto;">
		<div class="flxClmn vSpace1">
			<span style="font-size: 1.1rem; font-family: 'Roboto Condensed', sans-serif;">Sharing configuration:</span>
			<div style="flex: 1 0; padding: 0 10px 15px; background: #f9f9f9;" class="flxClmn-around">
				<div class="flxClmn" style="max-width: 500px;" click-outside="showAdvanced = false">
					<div class="vSpace2" style="flex: 1 0;">
						<table style="border-collapse: unset; border-spacing: 0 1rem; width: 100%; color: #292929;">
							<tbody>
							<tr>
								<td style="width: 1px; white-space: nowrap; padding-right: 15px;" class="hSpace05">
									<span>Expiration:</span>
									<i style="font-weight: 400;" class="fas fa-question-circle"
									   tooltip="After expiration date - no reviewer could access the emails of this shared archive case"></i>
								</td>
								<td>
									<date-picker-c
										[(dateModel)]="archiveService.currentCaseObj.edit.sharing.expiration"
										[dateMinLimit]="rangeMinString"
										[allowFuture]="true" style="width: 230px;"></date-picker-c>
								</td>
							</tr>
							<tr>
								<td style="width: 1px; white-space: nowrap; padding-right: 15px;" class="hSpace05">
									<span>Authentication Method:</span>
									<i style="font-weight: 400;" class="fas fa-question-circle"
									   tooltip="Reviewers are required to verify their identity before accessing archive emails. Set the method for the verification"></i>
								</td>
								<td style="display: inline-flex; align-items: center;">
									<app-dropdown-c id="advancedSecureMethods" style="width: 95px;" [text]="archiveService.currentCaseObj.edit.sharing.method_2factor">
										<li *ngFor="let method of dic.CONSTANTS.secureMethods"
											(click)="archiveService.currentCaseObj.edit.sharing.method_2factor = method;"
											id="{{method}}">
											<label style="margin: 0;">{{method}}</label>
										</li>
									</app-dropdown-c>
									<input style="align-self: stretch; margin-left: 3px; font-size:0.77rem; margin-right: -8px; padding-right: 24px; width: 132px; height: inherit; margin-bottom:0;"
										   *ngIf="archiveService.currentCaseObj.edit.sharing.method_2factor === dic.CONSTANTS.authMethods.password"
										   type="{{!showpassword && !hasWKTextSecurity ? 'password':'text'}}"
										   id="method_2factor_password"
										   [ngClass]="{nofill: !showpassword}"
										   [(ngModel)]="archiveService.currentCaseObj.edit.sharing.method_2factor_password"
										   placeholder="Default password" size="15">
									<label style="display: inline-flex; margin: 0; transform: translateX(calc(-4px - 100%)); margin-left: 6px; opacity: 0.2; width: 15px;"
										   *ngIf="archiveService.currentCaseObj.edit.sharing.method_2factor === dic.CONSTANTS.authMethods.password"
										   class="hidepwd">
										<input type="checkbox" [(ngModel)]="showpassword"/>
										<i class="fa fa-eye unchecked"></i>
										<i class="fa fa-eye-slash checked"></i>
									</label>
									<input style="align-self: stretch; width: auto; height: inherit; margin-bottom: 0;"
										   *ngIf="archiveService.currentCaseObj.edit.sharing.method_2factor === dic.CONSTANTS.authMethods.password"
										   [(ngModel)]="archiveService.currentCaseObj.edit.sharing.method_2factor_password_hint"
										   placeholder="Hint" size="15">
									<input style="align-self: stretch; margin-left: 5px; width: 132px; height: inherit; margin-bottom:0;"
										   *ngIf="archiveService.currentCaseObj.edit.sharing.method_2factor === dic.CONSTANTS.authMethods.phone
                                           || archiveService.currentCaseObj.edit.sharing.method_2factor === dic.CONSTANTS.authMethods.email"
										   type="text" id="method_2factor_phone"
										   placeholder="PIN Code" size="15" disabled>
									<i style="font-weight: 400; position: relative; padding-left: 5px;" [ngStyle]="archiveService.currentCaseObj.edit.sharing.method_2factor === dic.CONSTANTS.authMethods.phone && {'left':'0'} || {'left':'0.5px'}" class="fas fa-question-circle" tooltip="<b>Phone</b> authentication via SMS/CALL PIN code (preferred).<br/><b>Password</b> authentication via setting a custom password.<br/><b>Email</b> authentication via email containing a PIN code.<br/><b>SSO</b> authentication using Google, Yahoo or Microsoft" title-direction="right"></i>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="flxClmn f-1-0 vSpace1" style="overflow: auto;">
			<span style="font-size: 1.1rem; font-family: 'Roboto Condensed', sans-serif; margin-top: 15px;">Shared reviewers:</span>
			<div style="overflow: auto; padding: 15px 10px; background: #f9f9f9; height: 430px; " class="flxClmn-around">
				<!--shared reviewers table-->
				<trustifi-table-c
					class="trustifi-table" style="flex: 1 0; z-index: 5; background: #fdfdfd; padding: 5px;"
					[list]="archiveService.currentCaseObj.edit.sharing.reviewers"
					[headers]="[
                                        {text:'Email', sortKey:'email'}
                                    ]"
					[cellsPrototype]="[
                                        {textKey: 'email', edit: {modelKey:'email', placeholder:'Reviewer email'}}
                                    ]"
					[cellActions]="{
                                        enable: true,
                                        selectItemActionCb:	selectReviewerAction,
                                        initActionsFunction: showReviewerActions
                                    }"
					(searchFunction)="searchReviewers($event.searchTerm, $event.activeFilters)"
					(confirmEditFunction)="confirmAddReviewer($event.item, $event.isApproved)"
					[options]="{
							itemsNameSingular: 'shared reviewer',
							defaultSortKey: '-email'
						}"
				>
					<div tableButtons>
						<div class="flxRow hSpace1" style="align-items: center;">
							<btn-c endIcon="fa fa-plus"
								 [isDisabled]="addReviewerInProcess"
								 (action)="startAddReviewer();">
								Add Reviewer
							</btn-c>
						</div>
					</div>
				</trustifi-table-c>
			</div>
		</div>
	</div>
</popup-c>

<popup-c *ngIf="logsPopup?.show"
	   head="Reviewer Actions"
	   (closeCb)="logsPopup = null;"
	   [buttons]="{cancel: 'OK'}" size="medium">
	<div class="flxClmn vSpace3 f-1-0" style="overflow: auto;">
		<div class="flxRow" style="align-items: center; z-index: 90;">
			<span style=" margin-top: -5px; font-weight: 500;">View actions for: </span>
			<div *ngIf="archiveService.currentCaseObj.sharing.reviewers.length > 0" style=" margin-left: 10px; height: 37px; line-height: 1;">
				<app-dropdown-c style="width: 260px;"
								[items]="archiveService.currentCaseObj.sharing.reviewers"
								itemDisplayKey="email"
								[enableSearch]="true"
								(onItemClicked)="selectReviewer($event.item)"
								[text]="logsPopup.selectedReviewer.email"></app-dropdown-c>
			</div>
		</div>

		<div class="flxClmn-start f-1-0" style="overflow: auto; position: relative;">
			<table class="w100 simpleDataTable">
				<thead style="position: sticky; top: 0; background: white; z-index: 50;"><tr>
					<th style="width:160px;">Date</th>
					<th>Type</th>
					<th>Description</th>
				</tr></thead>
				<tbody>
				<tr *ngFor="let event of logsPopup.selectedReviewer.track_events | orderBy: '-created'; trackBy: gs.trackByIndex">
					<td style="width: 150px;">{{event.created | date:'MM/dd/yyyy HH:mm'}}</td>
					<td><span>{{event.name}}</span></td>
					<td><span>{{event.note}}</span></td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</popup-c>
