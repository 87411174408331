<div class="flxClmn animated fadeIn message-tab-content" style="flex: 1 0; overflow: auto; padding: 0 15px;">

	<div class="actionRow flex-container" style="position: sticky; top: 0; background: white; z-index: 50;">
		<div class="actionLabel flex-item">Actions</div>
		<div class="actionItem flex-item"
			 *ngIf="allowPolicyRecall && (myEmailsService.currentMessage.is_draft || (myEmailsService.currentMessage.methods.encrypt_content || (!myEmailsService.currentMessage.start_sending_time && myEmailsService.currentMessage.scheduled_time)) && !myEmailsService.currentMessage.methods.secure_mass && !myEmailsService.currentMessage.reply_to)"
			 (click)="doEmailAction('updateContent');"
			 id="updateMessage">
			<a>
				Update message
			</a>
		</div>
		<div class="actionItem flex-item"
			 *ngIf="recipientTrack && (myEmailsService.currentMessage.methods.encrypt_content || myEmailsService.currentMessage.methods.secure_reply || myEmailsService.currentMessage.methods.secure_send || (myEmailsService.currentMessage.sent.attachments.length && !myEmailsService.currentMessage.skip_attachments_encryption))"
			 (click)="doEmailAction(recipientTrack.is_blocked || recipientTrack.is_access_blocked ? 'unblockRecipient' : 'blockRecipient');"
			 id="trackingBlockRecipient">
			<a [ngStyle]="changeMessageInProcess && {'opacity':'0.5','cursor':'not-allowed'}">
				{{recipientTrack.is_blocked || recipientTrack.is_access_blocked ? 'Unblock' : 'Block'}} recipient
			</a>
		</div>
		<div class="actionItem flex-item"
			 (click)="doEmailAction('exportTrackingPdf');">
			<a>Export to PDF</a>
		</div>
	</div>

	<!--loader-->
	<div class="flxClmn-center" style="flex: 1 0; align-items: center;" *ngIf="!recipientTrack && getTrackingDataInProcess">
		<div class="loading-spinner" style="position: relative; top: 0; left: 0; opacity: 0.7;"></div>
	</div>

	<div class="row alt" style="margin-bottom: 2rem;" *ngIf="recipientTrack && myEmailsService.currentMessage.trackingData?.length > 0">
		<div class="message-heading message-heading-flex" style="border-bottom: 0;" >
			<div style="position: relative;">
				<app-dropdown-c style="width: 260px; height: 35px;"
							  [items]="myEmailsService.currentMessage.trackingData"
							  itemDisplayKey="email" [enableSearch]="true"
							  (onItemClicked)="changeTrackingRecipient($event.item)"
							  [text]="recipientTrack.email"></app-dropdown-c>
				<i class="zmdi zmdi-edit" tooltip="Edit recipient" style="font-size: 15px; position: absolute; top: 50%; transform: translateY(-25%); padding: 8px 5px; cursor: pointer; z-index: 50;"
				   [ngStyle]="{'right' : myEmailsService.currentMessage.trackingData?.length > 1 ? '25px' : '10px'}"
				   (click)="openRecipientContactInfoPopup(recipientTrack)"></i>
			</div>
		</div>
	</div>

	<tracking-events *ngIf="recipientTrack"
					 [recipientTrack]="recipientTrack"
					 [methods]="myEmailsService.currentMessage.methods"
					 class="f-1-0">
	</tracking-events>
</div>

<!--Recipient Details popup-->
<popup-c class="overflownV bs-enabled" *ngIf="recipientContactInfoPopup?.show"
	   head="Recipient Details"
	   (closeCb)="recipientContactInfoPopup = null"
	   (confirmCb)="updateRecipientDetails();"
	   [buttons]="{cancel: 'Cancel', confirm: 'Confirm'}"
	   size="dynamic">
	<div class="flex-column f-1-0 gap-3" style="align-items: unset; overflow: visible;">
		<div class="txt-block">
			<span>Enter recipient details:</span>
		</div>
		<div class="flex-column gap-3" style="padding-left: 20px;">
			<div class="flex-row align-items-center">
				<label style="flex-basis: 75px;">Email:</label>
				<input type="text" style="flex: 1 0;"
					   [(ngModel)]="recipientContactInfoPopup.contactInfo.email" disabled>
			</div>
			<div class="flex-row align-items-center">
				<label style="flex-basis: 75px;">Name:</label>
				<input type="text" placeholder="Name" style="flex: 1 0;"
					   [(ngModel)]="recipientContactInfoPopup.contactInfo.name">
			</div>
			<div class="flex-row align-items-center">
				<label style="flex-basis: 75px;">Password:</label>
				<input type="text" placeholder="MFA password" style="flex: 1 0;"
					   [(ngModel)]="recipientContactInfoPopup.contactInfo.default_password.password">
			</div>
			<div class="flex-row align-items-center">
				<label style="flex-basis: 75px;">Hint:</label>
				<input type="text" placeholder="MFA hint" style="flex: 1 0;"
					   [(ngModel)]="recipientContactInfoPopup.contactInfo.default_password.hint">
			</div>
			<div class="flex-row align-items-center">
				<label style="flex-basis: 75px;">Phone:</label>
				<country-code-select [(model)]="recipientContactInfoPopup.contactInfo.phone.country_code" ></country-code-select>
				<input id="partnerEditPhone"
					   type="text" placeholder="Phone number"
					   style="flex: 1 0; margin-right: 0; margin-bottom: 0; padding-right: 5px;"
					   maxlength="11"
					   (keyup.enter)="updateRecipientDetails()"
					   [(ngModel)]="recipientContactInfoPopup.contactInfo.phone.phone_number">
			</div>
		</div>
	</div>
</popup-c>
