<div class="flxClmn bs-enabled">
    <div class="page-title-container flxClmn-center">
        <div class="page-title-inner-container">
            <span class="page-title">Resources</span>
        </div>
        <div class="page-subtitle-container">
            <span class="page-subtitle">Access Trustifi's marketing and knowledge resources</span>
        </div>
    </div>

    <div class="flxRow flex-wrap page-inner-content-holder no-scroll vSpace1">
        <!--list-->
        <div class="w-50 pe-2" style="font-size: 0.9rem;">
			<p>
				As a valued partner, you have exclusive access to essential marketing materials and knowledge resources to support your efforts and enhance your understanding of Trustifi's email security solutions. Explore the resources below to effectively promote and utilize our products.</p>
			<b class="underline">Marketing Resources</b>
			<ul>
				<li><b>Agreements:</b> Essential agreements and contracts for partners.</li>
				<li><b>Architecture:</b> Detailed architectural documents for technical understanding.</li>
				<li><b>Comparison Docs (Battle Cards):</b> Competitive comparison documents to assist in sales.</li>
<!--				<li><b>Market Guides:</b> In-depth market analysis and guides.</li>-->
<!--				<li><b>Partner Program:</b> Information and resources related to our partner program.</li>-->
<!--				<li><b>SPIFFS:</b> Sales performance incentive fund programs.</li>-->
			</ul>
			<b class="underline">Knowledge Resources</b>
			<ul>
				<li><b>Product Documentation:</b> Comprehensive guides and manuals to help you understand and utilize Trustifi's features.</li>
				<li><b>Email Templates for Sales Rebuttals:</b> Ready-to-use email templates for common sales objections.</li>
				<li><b>Data Sheets:</b> Detailed product data sheets.</li>
<!--				<li><b>Support Guides:</b> Guides to assist with troubleshooting and customer support.</li>-->
<!--				<li><b>Whitepapers:</b> In-depth whitepapers providing insights into the latest trends and best practices in email security.</li>-->
			</ul>
			<b class="underline">Additional Resources</b>
			<ul>
				<li><b>Dashboards Screenshots:</b> Visuals to help you understand the interface.</li>
				<li><b>Trustifi Logos and Website Images: </b> Official branding materials for your marketing efforts.</li>
				<li><b>Link to Trustifi Brand Guidelines:</b> Guidelines to maintain brand consistency.</li>
			</ul>
		</div>
		<div class="w-50 d-flex justify-content-center">
			<div class="d-flex p-4 flex-column border rounded align-items-center hvr-scale cursor-pointer"
				 style="height: fit-content; max-width: 375px;" (click)="navigateToFolder(dropboxLink)">
				<img class="w-50 mb-3" src="images/dropbox-logo.png" alt="dropbox logo">
				<span style="font-size: 1rem; text-align: center;"><a class="color-bold" href="https://www.dropbox.com/scl/fo/h9w71bkazudrgvtr6zmda/ANTDx75bSf4jbAg8wV8Wa0k?rlkey=ltbfoae6tto9wd661w68z8c2i&st=3mzijm4b&dl=0" target="_blank">Click Here</a><br>to access the resources on Dropbox.</span>
			</div>
		</div>
    </div>
</div>
