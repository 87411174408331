<div class="flxRow search-box"
	 style="position: relative; width: 100%; height: 100%; overflow: hidden;">
	<div class="flxRow w100" style="position: absolute; left: 0; top: 0; height: 100%;">
		<i class="fa fa-search"
		   style="font-size: 16px; opacity: .5; position: absolute; left: 5px; top: 50%; transform: translateY(-50%);">
		</i>
		<input type="search"
			   style="margin: 0; flex: 1 0; height: 100%; padding: 0 30px; border: none; border-radius: 0;"
			   [disabled]="isDisabled"
			   [ngClass]="{'disabled': isDisabled}"
			   [(ngModel)]="model"
			   maxlength="{{dic.CONSTANTS.maxCharsInSearch}}"
			   placeholder="{{placeholder ? placeholder : 'Search'}}"
			   (ngModelChange)="modelChange.emit(model);">
		<a class="closeRed animated fadeIn"
		   style="color: #cccccc; font-size: 20px; position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
		   *ngIf="model"
		   (click)="model = ''; modelChange.emit(model);">
			<i class="zmdi zmdi-close" style="vertical-align: middle"></i>
		</a>
	</div>
</div>
