import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import _ from 'lodash';

import {GeneralService} from "../../services/generalService";
import {RouteService} from "../../services/routeService";
import {DICTIONARY} from "../../dictionary";


@Component({
	selector: 'exchange-protection-wizard-c',
	templateUrl: './exchange-protection-wizard.component.html',
})
export class ExchangeProtectionWizardComponent implements OnInit {

	@Output() closePopupFn: EventEmitter<any> = new EventEmitter<any>();

	constructor(private rs: RouteService,
				public gs: GeneralService) {}

	dic = DICTIONARY;
	_ = _;

	isLicenseLoading = false;
	disableContentFilter = {enabled: false, visible:false};
	disableAntiSpam = {enabled: false, visible:true};
	disableAntiPhish = {enabled: false, visible:true};
	disableAntiMalware = {enabled: false, visible:true};
	disableSafeAttachments = {enabled: false, visible:false};
	disableSafeLinks = {enabled: false, visible:false};

	// environment
	validSteps = [DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation, DICTIONARY.CONSTANTS.exchangeRulesSteps.configuration, DICTIONARY.CONSTANTS.exchangeRulesSteps.summary];
	step = DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation;
	errorMsg = '';
	rulesStatus;
	generateTokenInProcess;
	token;
	inputs;
	disconnected;

	ngOnInit() {
		this.isLicenseLoading = true;
		this.rs.validateMicrosoftLicenses().then(res => {
			if (res?.ONPREM) {
				this.disableContentFilter.visible = true;
			}
			else if (res?.THREAT_INTELLIGENCE) {
				this.disableSafeAttachments.visible = true;
				this.disableSafeLinks.visible = true;
			}
			this.isLicenseLoading = false;
		}, err => {
			console.error(err);
			this.isLicenseLoading = false;
		});
	}

	closePopup = () => {
		let success = this.rulesStatus && this.rulesStatus.status === 'success';
		this.closePopupFn.emit({success, disconnected:this.disconnected});
	}

	exchangeDisableProtection = () => {

		const data = {
			disableContentFilter: this.disableContentFilter.enabled,
			disableAntiSpam: this.disableAntiSpam.enabled,
			disableAntiPhish: this.disableAntiPhish.enabled,
			disableAntiMalware: this.disableAntiMalware.enabled,
			disableSafeAttachments: this.disableSafeAttachments.enabled,
			disableSafeLinks: this.disableSafeLinks.enabled
		}

		this.rulesStatus = null;
		this.generateTokenInProcess = true;

		this.rs.exchangeDisableProtection(data).then(response => {
			this.rulesStatus = response;
			this.generateTokenInProcess = false;
		}, err => {
			this.rulesStatus = err.data;
			this.generateTokenInProcess = false;
			this.disconnected = err.data.disconnected;
		});
	}

	backStep = () => {
		this.errorMsg = '';
		switch (this.step) {
			case DICTIONARY.CONSTANTS.exchangeRulesSteps.configuration:
				this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation;
				break;

			case DICTIONARY.CONSTANTS.exchangeRulesSteps.summary:
				if (this.generateTokenInProcess) {
					this.errorMsg = DICTIONARY.ERRORS.waitForTokenGenerate;
					return;
				}

				this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.configuration;
				break;

			default:
				return;
		}
	}

	nextStep = () => {
		this.errorMsg = '';
		switch (this.step) {
			case DICTIONARY.CONSTANTS.exchangeRulesSteps.generalInformation:
				this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.configuration;
				break;

			case DICTIONARY.CONSTANTS.exchangeRulesSteps.configuration:
				if (!this.disableContentFilter.enabled && !this.disableAntiSpam.enabled && !this.disableAntiPhish.enabled && !this.disableAntiMalware.enabled &&
					!this.disableSafeAttachments.enabled && !this.disableSafeLinks.enabled) {
					this.errorMsg = 'You must select at least one option';
					return;
				}
				this.exchangeDisableProtection();
				this.step = DICTIONARY.CONSTANTS.exchangeRulesSteps.summary;
				break;

			default:
				return;
		}
	}
}
